import { Icon } from 'components';

export function SalaryByFundCardGridHelp(): JSX.Element {
  return (
    <>
      <p>Таблица содержит данные загруженные из информационной системы расчёта заработной платы</p>
      <p>
        Запись содержит выплаты по шифру для персоны. Если для таблицы включен фильтр Год, то в столбцах Янв. Начислено,
        Янв.Удержано, Янв.СВ отображаются значения за январь указанного года. Если фильтр Год отключен, то отображается суммарное
        значение за январь по шифру. Аналогично для всех месяцев (январь - декабрь)
      </p>

      <div className="warning">
        <span>
          <p>
            В столбце <strong>Шифр</strong> отображается шифр, который указан в информационной системе расчёта заработной платы.
            Если Шифр не найден в списке <strong>Шифров накладных расходов</strong>, запись подсвечивается{' '}
            <span className="state-danger">красным цветом</span>.
          </p>
          <p>
            <strong className="state-danger">
              Что делать: Добавьте этот шифр в список шифров накладных расходов или перенесите данные на другой шифр
            </strong>
          </p>
        </span>
      </div>

      <p>
        Функциональная Кнопка <strong>Просмотр</strong> <Icon type="view" /> открывает форму с детализацией по видам расчёта
        выплаты
      </p>

      <p>
        Функциональная Кнопка <strong>Перенести на другой шифр</strong> <Icon type="transport" /> открывает форму с детализацией
        по видам расчёта выплаты
      </p>
      <table>
        <thead>
          <tr>
            <th>Вариант выполнения функции</th>
            <th>Описание результата</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>Перенести с шифра Централизованного фонда на шифр Централизованного фонда</p>
            </td>
            <td>
              <p>
                Запись будет перенесена на <strong>Шифр2</strong>
              </p>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра Централизованного фонда на шифр проекта</p>
            </td>
            <td>
              <p>
                Запись будет перенесена на <strong>Шифр</strong>
              </p>
              <p>
                В состав коллектива <strong>Проекта</strong> добавлен исполнитель
              </p>
              <p>Посмотреть состав коллектива можно на вкладке Список участников проекта или в проекте на вкладке «Коллектив»</p>
              <p>Запись будет отображаться на вкладке «Оплата по шифрам проектов»</p>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import { Payment } from 'types/models';

import { useController } from 'features/IncentivePayments/controller';
import { State as PaymentState } from 'features/IncentivePayments';

export type State = object & PaymentState;

export function makeUseCustomController(event: Payment.EventType) {
  return function useCustomController(): State {
    return useController({ event });
  };
}

import { useState, useCallback, useLayoutEffect, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { Form, Author, Document, Award, RntdAuthor, Event, Exponent } from 'types/models';
import { Participation, Department, ParticipationProject } from 'types/models/Participation';
import { useLocalTableStreams } from 'features/Table/hooks';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { EMPTY_TABLE_STATE } from 'features/Form/constants';
import { RecordStatus } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ExponentData } from 'utils/Enums/ExponentData';
import { copyAcademicRank, copyDegree, copyEducation, copyJob, copyRank } from 'utils/Helpers/getHistoryCopy';
import { EventFormat } from 'utils/Enums';
import { EnumValue } from 'types/models/Table';
import { ParticipationProjectType } from 'utils/Enums/ParticipationProjectType';
import { ParticipationFormatType } from 'utils/Enums/ParticipationFormatType';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { useFormContext } from 'features/Form/hooks';
import { validate } from './validate';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { formatPaymentRequestData } from 'features/Form/looks/publication/helpers';
import { showNotification } from 'features/Notifications';
import { getEnumItem } from 'utils/Helpers';

export type CheckboxItem = EnumValue & { isChecked: boolean };

type Props = {
  onClose: () => void;
};

export const useController = ({ onClose }: Props) => {
  const {
    look: { id, relatedTableState, editMode, viewMode, disabledEvent, sourceEvent, isRequestPaymentView = false, arm, onSave },
  } = useFormContext<Form.ParticipationFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });
  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const { currentPerson, enumMap } = useAppDataContext();

  const { methods: getParticipation, state: getParticipationState } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation, state: saveParticipationState } = BackendAPI.useBackendAPI('SaveParticipation');
  const { methods: getEvent, state: getEventState } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: getReferenceElementsAPI, state: getReferenceElementsAPIState } = BackendAPI.useBackendAPI(
    'GetReferenceElements',
  );
  const { methods: getExponent, state: getExponentState } = BackendAPI.useBackendAPI('GetExponent');

  const { isLoading } = useIsLoading({
    callStates: [getParticipationState, saveParticipationState, getEventState, getReferenceElementsAPIState, getExponentState],
  });

  const tabsId = 'ParticipationFormConference';

  // popups
  const [isEventSelectWarningOpen, setIsEventSelectWarningOpen] = useState<boolean>(false);

  // fields
  const [members, setMembers] = useState<Author[]>([]);
  const [memberAuthorRole, setMemberAuthorRole] = useState<ReferenceItem | null>(null);
  const [needClose, setNeedClose] = useState<boolean>(false);
  const [participationMessage, setParticipationMessage] = useState<string>('');
  const [participationMessageType, setParticipationMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isAdminMessagePopupOpen, setIsAdminMessagePopupOpen] = useState<boolean>(false);
  const [currentParticipationId, setCurrentParticipationId] = useState<null | string>(id || null);
  const [isSimilarParticipationOpen, setIsSimilarParticipationOpen] = useState<boolean>(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState<boolean>(false);
  const [isEditDepartmentsToAddedFormOpen, setIsEditDepartmentsToAddedFormOpen] = useState<boolean>(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);
  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [isDocumentsPopupOpened, setIsDocumentsPopupOpened] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);
  const [presentationProject, setPresentationProject] = useState<ParticipationProject | null>(null);
  const [financingProjects, setFinancingProjects] = useState<ParticipationProject[]>([]);
  const [awards, setAwards] = useState<Award[]>([]);
  const [exponent, setExponent] = useState<Exponent | null>(null);
  const [event, setEvent] = useState<Event.Event | null>(null);
  const [paymentString, setPaymentString] = useState('');
  const [presentationProjectFormId, setPresentationProjectFormId] = useState('');
  const [presentationExponentFormId, setPresentationExponentFormId] = useState('');
  const [exposureWorkFormId, setExposureWorkFormId] = useState('');
  const [businessProgrammFormId, setBusinessProgrammFormId] = useState('');
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<Form.Fields>({
    collectiveExhibitName: {
      isValid: true,
      required: true,
      value: '',
      title: 'Экспозиция',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          collectiveExhibitName: { ...prevState.collectiveExhibitName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          collectiveExhibitName: { ...prevState.collectiveExhibitName, isValid: true },
        }));
      },
    },

    isCollectiveExhibit: {
      isValid: true,
      value: false,
      title: 'Коллективная экспозиция',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => {
          const nextValue = !prevState.isCollectiveExhibit.value;
          return {
            ...prevState,
            isCollectiveExhibit: {
              ...prevState.isCollectiveExhibit,
              value: nextValue,
            },
            collectiveExhibitName: {
              ...prevState.collectiveExhibitName,
              required: nextValue,
            },
          };
        });
      },
    },

    note: {
      isValid: true,
      value: '',
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
    exponent: {
      isValid: true,
      required: false,
      title: 'Экспонат',
      value: null,
      onChange: () => {},
    },
    form: {
      isValid: true,
      required: true,
      value: '',
      title: 'Формат участия',
      onChange: (value: ReferenceItem | null) => {
        const preparedValue: ReferenceItem = value || { id: '', label: '' };
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          form: { ...prevState.form, value: preparedValue, isValid: true },
        }));
      },
    },
  });

  const isPresentationProjectFormSelected = useMemo(() => formFields.form.value?.id === presentationProjectFormId, [
    presentationProjectFormId,
    formFields.form.value,
  ]);
  const isPresentationExponentFormSelected = useMemo(() => formFields.form.value?.id === presentationExponentFormId, [
    presentationExponentFormId,
    formFields.form.value,
  ]);
  const isExposureWorkFormIdFormSelected = useMemo(() => formFields.form.value?.id === exposureWorkFormId, [
    exposureWorkFormId,
    formFields.form.value,
  ]);
  const isBusinessProgrammFormIdFormSelected = useMemo(() => formFields.form.value?.id === businessProgrammFormId, [
    businessProgrammFormId,
    formFields.form.value,
  ]);
  const isVisibleExpositionFields = useMemo(() => isExposureWorkFormIdFormSelected || isPresentationExponentFormSelected, [
    isExposureWorkFormIdFormSelected,
    isPresentationExponentFormSelected,
  ]);
  const isVisibleProjectField = useMemo(() => isPresentationProjectFormSelected, [isPresentationProjectFormSelected]);
  const isVisibleExponentField = useMemo(() => isPresentationExponentFormSelected, [isPresentationExponentFormSelected]);
  const isVisibleExponentSection = useMemo(() => !isBusinessProgrammFormIdFormSelected, [isBusinessProgrammFormIdFormSelected]);
  const isVisiblePresentationForm = useMemo(() => isPresentationProjectFormSelected || isPresentationExponentFormSelected, [
    isPresentationProjectFormSelected,
    isPresentationExponentFormSelected,
  ]);
  const [participationPresentationForm, setParticipationPresentationForm] = useState<CheckboxItem[]>([]);
  const handlePresentationFormChange = (val: boolean, ordinal: number) => {
    setParticipationPresentationForm(prevState =>
      prevState.map(item => (item.ordinal === ordinal ? { ...item, isChecked: val } : item)),
    );
  };

  useEffect(() => {
    if (isExposureWorkFormIdFormSelected) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        collectiveExhibitName: { ...prevState.collectiveExhibitName },
      }));
    }
  }, [isExposureWorkFormIdFormSelected]);

  useEffect(() => {
    if (isPresentationProjectFormSelected) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        collectiveExhibitName: { ...prevState.collectiveExhibitName, value: '' },
        isCollectiveExhibit: {
          ...prevState.isCollectiveExhibit,
          value: false,
        },
        exponent: { ...prevState.exponent, value: null, isValid: true },
      }));
      setExponent(null);
    }
  }, [isPresentationProjectFormSelected]);

  useEffect(() => {
    if (isBusinessProgrammFormIdFormSelected) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        collectiveExhibitName: { ...prevState.collectiveExhibitName, value: '' },
        isCollectiveExhibit: {
          ...prevState.isCollectiveExhibit,
          value: false,
        },
        exponent: { ...prevState.exponent, value: null, isValid: true },
      }));
      setParticipationPresentationForm(prevState => prevState.map(item => ({ ...item, isChecked: false })));
      setExponent(null);
    }
  }, [isBusinessProgrammFormIdFormSelected]);

  useEffect(() => {
    if (isPresentationExponentFormSelected) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        collectiveExhibitName: {
          ...prevState.collectiveExhibitName,
        },
      }));
    }
  }, [isPresentationExponentFormSelected]);

  useEffect(() => {
    getReferenceElementsAPI.callAPI(
      {
        filters: [{ key: 'type', values: ['EXPOSITION'] }],
        referenceName: 'RefParticipationForm',
      },
      {
        onSuccessfullCall: ({ data }: { data: ReferenceItem[] }) => {
          const filterDataByParam = (customFieldCode: string) =>
            data.filter(value => value?.customFields?.code === customFieldCode)[0]?.id || '';
          setPresentationProjectFormId(filterDataByParam(ParticipationFormatType.EXPOSITION_PROJECT_PRESENTATION));
          setPresentationExponentFormId(filterDataByParam(ParticipationFormatType.EXPOSITION_EXPONENT_PRESENTATION));
          setExposureWorkFormId(filterDataByParam(ParticipationFormatType.EXPOSITION_EXPOSURE_WORK));
          setBusinessProgrammFormId(filterDataByParam(ParticipationFormatType.EXPOSITION_BUSINESS_PROGRAM_PARTICIPATION));
        },
      },
    );
    setParticipationPresentationForm(
      enumMap.ParticipationPresentationForm.values.map(value => ({
        ...value,
        isChecked: false,
      })),
    );
    // eslint-disable-next-line
  }, []);

  const convertRntdAuthorToAuthor = useCallback(
    (rntdAuthor: RntdAuthor): Author => ({
      ...rntdAuthor,
      job: copyJob(rntdAuthor.job),
      education: copyEducation(rntdAuthor.education),
      degree: copyDegree(rntdAuthor.degree),
      citizenship: rntdAuthor.citizenship,
      rank: copyRank(rntdAuthor.rank),
      academicRank: copyAcademicRank(rntdAuthor.academicRank),
      enterprise: null,
      name: rntdAuthor.name || '',
      category: null,
      affilateCount: null,
      isAffilated: false,
      position: 0,
      historyLabel: '',
      memberAffilations: [],
      role: memberAuthorRole,
    }),
    [memberAuthorRole],
  );

  useLayoutEffect(() => {
    getReferenceElementsAPI.callAPI(
      {
        filters: [
          { key: 'code', values: [ExponentData.ROLE_AUTHOR] },
          { key: 'type', values: [ParticipationTypes.PARTICIPATION_EXPOSITION.eventCode] },
        ],
        referenceName: 'RefParticipationMemberRole',
      },
      {
        onSuccessfullCall: ({ data }: { data: ReferenceItem[] }) => {
          setMemberAuthorRole(data[0] || null);
        },
      },
    );
    // eslint-disable-next-line
  }, []);

  const cleanExponentData = useCallback(() => {
    if (exponent?.authors.length) {
      setMembers(prevValue => prevValue.filter(member => !exponent.authors.some(expAuth => expAuth.id === member.id)));
    }
  }, [exponent?.authors]);

  const loadExponent = useCallback(
    (argExponent: any) => {
      getExponent.callAPI(
        { id: argExponent?.exponentId },
        {
          onSuccessfullCall: ({ data }) => {
            setExponent(data);
          },
        },
      );
    },
    [getExponent],
  );

  const changeCurrentExponent = useCallback(
    (value: any) => {
      cleanExponentData();
      if (value) {
        loadExponent({ exponentId: value.id });
      } else {
        setExponent(null);
      }

      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        exponent: { ...prevState.exponent, value, isValid: true },
      }));
    },
    [cleanExponentData, loadExponent],
  );

  const exponentAuthorsNewToMembers = useMemo(
    () => exponent?.authors.filter(expAuth => !members.some(member => member.id === expAuth.id)),
    [exponent?.authors, members],
  );

  const addExponentAuthorsToMembers = useCallback(() => {
    if (exponentAuthorsNewToMembers?.length) {
      setMembers(prevValue => [
        ...prevValue,
        ...exponentAuthorsNewToMembers.map(rntdAuth => convertRntdAuthorToAuthor(rntdAuth)),
      ]);
    }
  }, [convertRntdAuthorToAuthor, exponentAuthorsNewToMembers]);

  const extraToolbarButtons = useMemo(
    () => [
      {
        icon: { type: 'group', mode: 'add' },
        title: 'Добавить список авторов экспоната (если не содержит)',
        onClick: addExponentAuthorsToMembers,
        isDisabled: !exponentAuthorsNewToMembers?.length,
      },
    ],
    [addExponentAuthorsToMembers, exponentAuthorsNewToMembers],
  );

  const loadParticipation = useCallback(
    (participationId: string | null, callback?: () => void) => {
      getParticipation.callAPI(
        {
          participationId: participationId || undefined,
          modules: [
            'ID',
            'ACCESS_MODE',
            'MAIN',
            'EVENT',
            'DEPARTMENTS',
            'PUBLICATION',
            'PROJECTS',
            'MEMBERS',
            'AWARDS',
            'MOBILE_REQUESTS',
            'DOCUMENTS',
            'INCENTIVE_PAYMENTS',
          ],
          arm,
          eventType: 'EXPOSITION',
          eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
          IsCollectiveExhibit: false,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setParticipationData(data);
            setDocuments(data.documents);
            setMobileRequests(data.mobileRequests);
            setAwards(data.awards);
            setMembers(data.members);
            setEvent(sourceEvent || data.event);
            setPresentationProject(data.presentationProject);
            setFinancingProjects(data.financingProjects);
            setByResponse({ setFormFields, data });
            formFields.form.onChange(data.form);

            if (data.exponent) loadExponent({ exponentId: data.exponent.id });

            setPaymentString(
              data.paymentRequest
                ? data.paymentRequest.map((i: Form.PaymentRequest) => formatPaymentRequestData(i)).join(', ')
                : '',
            );

            setParticipationPresentationForm(prevState =>
              prevState.map(item => (data.presentationForms.includes(item.value) ? { ...item, isChecked: true } : item)),
            );

            if (typeof callback === 'function') {
              callback();
            }
          },
          onFailedCall: () => {
            if (typeof callback === 'function') {
              callback();
            }
          },
        },
      );
    },
    [getParticipation, arm, sourceEvent, formFields.form, loadExponent],
  );

  const handleSetEvent = useCallback(
    (nextEvent: Event.Event | null) => {
      if (nextEvent) {
        getEvent.callAPI(
          {
            id: nextEvent.id,
            simpleFields: {
              type: '',
              domain: 'EVENT',
              isInSummaryPlan: false,
              isCompilationPlanned: true,
              IsMagazineReleasePlanned: false,
              isStudent: false,
              memberCountPlan: 0,
              memberCountFact: 0,
            },
            attrIdFields: {},
            arrayFields: {
              Grntis: { translation: [] },
              MinistryEducations: { translation: [] },
              CriticalTechnologies: { translation: [] },
              Pnmitrs: { translation: [] },
              Pnrs: { translation: [] },
              Pnis: { translation: [] },
              Partners: { translation: [] },
            },
            format: EventFormat.OFFLINE,
          },
          {
            onSuccessfullCall: ({ data }) => {
              if (data.participations.length) {
                setIsSimilarParticipationOpen(true);
              }

              setEvent(data);
              formFields?.publicationReportTheme?.onChange(null);
            },
          },
        );
      } else {
        setEvent(null);
      }
    },
    [formFields.publicationReportTheme, getEvent],
  );

  const updateMembers = (membersArray: Author[]) => {
    setMembers(membersArray);
  };

  const afterSaveAction = useCallback(() => {
    onClose();
    tableStreams.reloadTable.push({});
  }, [onClose, tableStreams.reloadTable]);

  const afterSaveAndContinueAction = useCallback(
    (nextParticipationId: string) => {
      updateWorkModeAfterSaveAndContinue();
      loadParticipation(nextParticipationId, () => {});
    },
    [loadParticipation, updateWorkModeAfterSaveAndContinue],
  );

  const openEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const closeEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const openEditDepartmentsToAddedForm = useCallback(() => {
    setIsEditDepartmentsToAddedFormOpen(true);
  }, []);

  const editDepartmentsAction = useCallback(() => {
    if (needClose) {
      afterSaveAction();
    } else if (currentParticipationId) {
      afterSaveAndContinueAction(currentParticipationId);
    }

    setIsEditDepartmentsToAddedFormOpen(false);
  }, [afterSaveAction, afterSaveAndContinueAction, currentParticipationId, needClose]);

  const closeEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const afterSubmitEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const handleOpenApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const afterSubmitApproveParticipation = useCallback(() => {
    const nextParticipationData = participationData
      ? { ...participationData, status: { value: RecordStatus.APPROVED, label: 'Утверждена' } }
      : null;
    setParticipationData(nextParticipationData);
  }, [participationData]);

  const afterSubmitDepartmentsForm = useCallback(
    (nextDepartments: Department[]) => {
      const nextParticipationData = participationData ? { ...participationData, departments: nextDepartments } : null;
      setParticipationData(nextParticipationData);
    },
    [participationData],
  );

  const saveForm = useCallback(
    (withMessage?: boolean) => {
      setIsAdminMessagePopupOpen(false);

      saveParticipation.callAPI(
        formatToRequest({
          formFields,
          members,
          event,
          documents,
          presentationProject,
          financingProjects,
          participationData,
          participationMessageType,
          participationMessage,
          currentParticipationId,
          withMessage,
          awards,
          mobileRequests,
          participationPresentationForm,
          isVisiblePresentationForm,
        }),
        {
          onSuccessfullCall: ({ data }) => {
            const nextParticipationId = data?.id || '';
            setCurrentParticipationId(nextParticipationId);
            setParticipationMessage('');
            setParticipationMessageType({ id: '', label: '' });
            const isAddMode = !currentParticipationId;

            if (isAddMode) {
              openEditDepartmentsToAddedForm();
            } else if (needClose) {
              afterSaveAction();
            } else {
              afterSaveAndContinueAction(nextParticipationId);
            }
            onSave?.(data);

            showNotification({
              theme: 'success',
              message: nextParticipationId
                ? 'Участие в мероприятии успешно отредактировано!'
                : 'Участие в мероприятии успешно сохранено!',
            });

            tableStreams.reloadTable.push({});
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      saveParticipation,
      formFields,
      members,
      event,
      documents,
      presentationProject,
      financingProjects,
      participationData,
      participationMessageType,
      participationMessage,
      currentParticipationId,
      awards,
      mobileRequests,
      participationPresentationForm,
      isVisiblePresentationForm,
      needClose,
      onSave,
      tableStreams.reloadTable,
      openEditDepartmentsToAddedForm,
      afterSaveAction,
      afterSaveAndContinueAction,
    ],
  );

  const handleFormSubmit = useCallback(
    (needCloseArg: boolean) => {
      setNeedClose(needCloseArg);
      const { isFormValid, invalidFieldKeys, nextNotification } = validate({
        formFields,
        event,
        isProjectRequired: isVisibleProjectField,
        project: presentationProject,
        isVisibleExponentSection,
        isVisibleExpositionFields,
        isVisibleExponentField,
      });

      if (!isFormValid) {
        showNotification(nextNotification);
        if (invalidFieldKeys.length) {
          invalidFieldKeys.forEach((key: string) => {
            const preparedKey = key as keyof typeof formFields;
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              [preparedKey]: { ...prevState[preparedKey], isValid: false },
            }));
          });
        }
      } else if (isProfile && !members.find(x => x.person?.id === currentPerson?.id)) {
        setIsAuthorsPopupOpen(true);
      } else if (!documents.length) {
        setIsDocumentsPopupOpened(true);
      } else if (needCloseArg) {
        setIsAdminMessagePopupOpen(true);
      } else {
        saveForm(false);
      }
    },
    [
      formFields,
      event,
      isVisibleProjectField,
      presentationProject,
      isVisibleExponentSection,
      isVisibleExpositionFields,
      isVisibleExponentField,
      isProfile,
      members,
      documents.length,
      currentPerson?.id,
      saveForm,
    ],
  );

  const onConfirmAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
    if (!documents.length) {
      setIsDocumentsPopupOpened(true);
    } else if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  };

  const onResetAuthorsPopup = () => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 0, tabsId });
    getParticipation.callAPI(
      {
        arm: 'pc',
        eventType: 'EXPOSITION',
        modules: ['MEMBERS'],
        eventModules: ['MAIN'],
      },
      {
        onSuccessfullCall: ({ data }) => {
          const authorMember = data?.members[0];
          if (authorMember) setMembers((prevState: Author[]) => [...prevState, authorMember]);
          setIsAuthorsPopupOpen(false);
          if (!documents.length) {
            setIsDocumentsPopupOpened(true);
          } else if (needClose) {
            setIsAdminMessagePopupOpen(true);
          } else {
            saveForm(false);
          }
        },
      },
    );
  };
  const onCloseAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
  };

  const handleCloseSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
  }, []);

  const acceptSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
    onClose();
  }, [onClose]);

  const goToDocumentsAction = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 3, tabsId });
    setIsDocumentsPopupOpened(false);
  }, [tabsId]); // isDocumentsPopupOpened

  const confirmDocumentPopup = useCallback(() => {
    setIsDocumentsPopupOpened(false);
    if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  }, [needClose, saveForm]);

  useLayoutEffect(() => {
    loadParticipation(currentParticipationId);
  }, []); // eslint-disable-line

  const actualTableState = useMemo(() => relatedTableState || EMPTY_TABLE_STATE, [relatedTableState]);

  const setFinancingProjectsCb = useCallback(
    (projectsData: Array<ParticipationProject>) => {
      setFinancingProjects(
        projectsData.map(projectItem => ({
          ...projectItem,
          type: getEnumItem('ParticipationProjectType', ParticipationProjectType.FINANCING, enumMap),
        })),
      );
    },
    [enumMap],
  );

  const setPresentationProjectCb = useCallback(
    (projectsData: Array<ParticipationProject>) => {
      if (!projectsData[0]) {
        setPresentationProject(null);
      }

      setPresentationProject({
        ...projectsData[0],
        type: getEnumItem('ParticipationProjectType', ParticipationProjectType.PRESENTATION, enumMap),
      });
    },
    [enumMap],
  );

  const setMobileRequestsCb = useCallback((requests: Array<Form.PublicationMobileRequest>) => setMobileRequests(requests), []);

  return {
    isLoading,
    isProfile,
    formFields,
    participationId: currentParticipationId,
    relatedTableState: actualTableState,
    workMode,
    documents,
    presentationProject,
    mobileRequests,
    awards,
    event,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    tabsId,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    exponent,
    disabledEvent,
    isRequestPaymentView,
    isSimilarParticipationOpen,
    isApprovePopupOpen,
    participationData,
    financingProjects,
    setFinancingProjectsCb,
    setMobileRequestsCb,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    setDocuments: (nextDocuments: Document[]) => setDocuments(nextDocuments),
    setAwards,
    setEvent: handleSetEvent,
    setIsEventSelectWarningOpen,
    changeMembers: (val: Author[]) => updateMembers(val),
    handleFormSubmit,
    goToDocumentsAction,
    confirmDocumentPopup,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    acceptSimilarParticipation,
    handleCloseSimilarParticipation,
    paymentString,
    extraToolbarButtons,
    changeCurrentExponent,
    isVisibleProjectField,
    participationPresentationForm,
    handlePresentationFormChange,
    isVisiblePresentationForm,
    isVisibleExponentSection,
    isVisibleExponentField,
    isVisibleExpositionFields,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onResetAuthorsPopup,
    onCloseAuthorsPopup,
    setPresentationProjectCb,
  };
};

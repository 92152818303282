export function FundCardGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Централизованный фонд</strong> предназначен для планирования объёмов накладных расходов по проектам
      </p>
      <p>
        <strong>Шифр накладных расходов</strong> предназначен для консолидации накладных расходов проекта или группы проектов
        (шифров)
      </p>
      <p>Таблица содержит список шифров накладных расходов</p>

      <h3>Особенности отображения информации в столбцах таблицы:</h3>

      <p>
        <strong>Шифр</strong> — Название шифра накладных расходов
      </p>
      <p>
        <strong>Поступления, план</strong> — общая сумма, запланированная в сметах по статье затрат{' '}
        <strong>Накладные расходы</strong> для шифров проектов. При этом, в смете указано, что передавать накладные на конкретный
        шифр накладных расходов
      </p>
      <p>
        <strong>Поступления, факт</strong> — общая сумма, вычисляемая автоматически при получении из бухгалтерии приходного{' '}
        документа по формуле: сумма документа * % НР, расчётный
      </p>
      <p>
        <strong>% НР, расчётный</strong> — расчётный процент накладных расходов <strong>вычисляется в смете шифра</strong> с
        учётом сумм по статьям затрат, которые имеют атрибут <strong>Уменьшает накладные</strong>
      </p>
      <p>
        <strong>Примечание</strong> — Поясняющий текст для шифра накладных расходов
      </p>
      <p>
        <strong>Средний % накладных</strong> — средневзвешенный процент по шифрам проектов
      </p>
      <p>
        <strong>Шифры, с которых переданы накладные</strong> — шифры накладных расходов, которые были указаны в смете
      </p>
    </>
  );
}

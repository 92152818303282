import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { downloadFile } from 'utils/Helpers';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const { userToken: token } = useAppDataContext();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const [selectedRowsAmountSum, setSelectedRowsAmountSum] = useState<number>(0);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCalcSumClick = useCallback(() => {
    setSelectedRowsAmountSum(tableState.selectedRows.map(i => parseFloat(i.Amount)).reduce((acc, value) => acc + value, 0));
    setIsConfirmPopupOpened(true);
  }, [tableState.selectedRows]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'calc' },
        title: 'Вычислить сумму выделенных строк',
        onClick: handleCalcSumClick,
        isDisabled: tableState.selectedRows.length < 2,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр файла',
        onClick: () => downloadFile(tableState.selectedRows[0]?.FileId, token, true),
        isDisabled: tableState.selectedRows.length !== 1 || !tableState.selectedRows[0]?.FileId,
      },
    ],
    [handleEditButtonClick, isEditButtonDisabled, handleCalcSumClick, tableState.selectedRows, token],
  );

  return {
    isHelpFormOpen,
    setIsHelpFormOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    handleEditButtonClick,
    handleTemplateCloseEditForm,
    buttons,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
    selectedRowsAmountSum,
  };
}

import { Icon } from 'components';

export function PersonalContestEventGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Конкурсы</strong> предназначен для поддержки процессов организации и проведения конкурсов и олимпиад.
      </p>

      <h4>В разделе Конкурсы вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись о конкурсе и детальную информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новый конкурс или олимпиаду с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать конкурс в статусе Добавлена с помощью кнопки <Icon type="edit" /> при наличии прав для редактирования
        </li>
        <li>
          Удалить запись о конкурсе, которую вы создавали, если она в статусе Добавлена с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Выгрузить список конкурсов в фаил Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>Внести показатели результативности конкурса на вкладке «Организация мероприятия»</li>
        <li>
          Добавить информацию об участниках конкурса, указать связь с подразделением и информацию о полученных наградах на вкладке
          «Участники»
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи о мероприятии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит мероприятие.
      </p>
      <p>
        Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к заполнению.
      </p>
      <p>
        Для добавления участия в мероприятии, организованного другой организацией, необходимо заполнить обязательные поля на
        вкладке "О мероприятии" и прикрепить подтверждающие документы на вкладке "Документы".
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои конкурсы»</strong> содержит список конкурсов, в которых вы указаны в составе Оргкомитета. Если вы
          создавали запись о конкурсе, но не являетесь участником оргкомитета, то конкурс будет отображаться в другом разделе.
        </li>
        <li>
          <strong>Фильтр «Конкурсы {organizationName}»</strong> содержит список конкурсов, в которых организатором или
          соорганизатором является университет.
        </li>
        <li>
          <strong>Фильтр «Другие конкурсы»</strong> показывает список конкурсов других организаций, в которых принимают участие
          сотрудники, аспиранты и студенты университета.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список конкурсов, сроки проведения которых содержат указанный год. По умолчанию
          выбран текущий год.
        </li>
      </ul>

      <p>Добавленное вами мероприятие проверит и утвердит Администратор данных.</p>

      <div>
        Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору <Icon type="admin" />
      </div>
    </>
  );
}

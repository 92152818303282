import { Rntd, RntdSubject } from 'types/models';
import { useCallback } from 'react';
import { ValueOf } from 'types/helpers';

type Props = {
  rntd?: Rntd | null;
  makeChangeHandler(key: keyof Rntd): (value: ValueOf<Rntd>) => void;
};

export function useController(props: Props) {
  const { makeChangeHandler } = props;

  const handleSubjectsChange = useCallback(
    (subjects: RntdSubject[]) => {
      makeChangeHandler('subjects')(subjects);
    },
    [makeChangeHandler],
  );

  return {
    handleSubjectsChange,
  };
}

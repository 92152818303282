import { useCallback, useLayoutEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Form, ForeignSecurityDocument, ReferenceItem } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';
import { Item } from 'types/models/common';
import { getMockForeignSecurityDocument } from './helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, relatedTableState, arm },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [foreignSecurityDocument, setForeignSecurityDocument] = useState<ForeignSecurityDocument>(
    getMockForeignSecurityDocument(),
  );
  const [documents, setDocuments] = useState<Document[]>([]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    type: {
      value: null,
      isValid: true,
      required: true,
      title: 'Тип Документа',
      onChange: (value: Item | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, value },
          kind: { ...prevState.kind, value: null },
        }));
      },
    },
    number: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          number: { ...prevState.number, value },
        }));
      },
    },
    startDate: {
      value: '',
      isValid: true,
      required: false,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          startDate: { ...prevState.startDate, value },
        }));
      },
    },
    endDate: {
      value: '',
      isValid: true,
      required: false,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, value },
        }));
      },
    },
    kind: {
      value: null,
      isValid: true,
      required: false,
      title: 'Вид ОИС',
      onChange: (value: ReferenceItem | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kind: { ...prevState.kind, value },
        }));
      },
    },
  });

  const { methods: getForeignSecurityDocument } = BackendAPI.useBackendAPI('GetForeignSecurityDocument', {
    onSuccessfullCall: ({ data }) => {
      setForeignSecurityDocument(data);

      setFormFields(prevState => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: data.name,
        },
        number: {
          ...prevState.number,
          value: data.number,
        },
        type: {
          ...prevState.type,
          value: data.type,
        },
        startDate: {
          ...prevState.startDate,
          value: data.startDate,
        },
        endDate: {
          ...prevState.endDate,
          value: data.endDate,
        },
        kind: {
          ...prevState.kind,
          value: data.kind,
        },
      }));

      setDocuments(data.documents);
    },
  });
  const { methods: saveForeignSecurityDocument } = BackendAPI.useBackendAPI(
    arm === 'pc' ? 'SavePersonalForeignSecurityDocument' : 'SaveForeignSecurityDocument',
  );
  const onSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(foreignSecurityDocument, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      saveForeignSecurityDocument.callAPI(
        {
          id: id || null,
          name: formFields.name.value,
          startDate: formFields.startDate.value,
          endDate: formFields.endDate.value,
          kind: formFields.kind.value,
          number: formFields.number.value,
          type: formFields.type.value,
          members: foreignSecurityDocument?.members || [],
          documents,
          applicants: '',
          authors: '',
          documentNumber: '',
          documentStartDate: '',
          intellectualPropertyName: '',
          status: null,
          contract: foreignSecurityDocument?.contract || '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Документ успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              getForeignSecurityDocument.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      id,
      foreignSecurityDocument,
      tableStreams,
      updateWorkModeAfterSaveAndContinue,
      documents,
      onClose,
      formFields,
      getForeignSecurityDocument,
      saveForeignSecurityDocument,
    ],
  );

  useLayoutEffect(() => {
    getForeignSecurityDocument.callAPI({ id, arm });
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    (needClose: boolean) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  return {
    foreignSecurityDocumentId: id ?? null,
    foreignSecurityDocument,
    setForeignSecurityDocument,
    workMode,
    formFields,
    handleFormSubmit,
    documents,
    setDocuments,
    relatedTableState,
  };
}

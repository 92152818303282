import React, { memo } from 'react';
import { Modal } from 'components';
import { Table } from 'types/models';
import { Component } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  articlePublicationIds: string[];
  sourcePublicationId: string;
  relatedTableState?: Table.State;
};

export const Container = memo(({ isOpen, onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Выбор монографии" size="large">
    <Component
      onClose={onClose}
      articlePublicationIds={articlePublicationIds}
      sourcePublicationId={sourcePublicationId}
      relatedTableState={relatedTableState}
    />
  </Modal>
));

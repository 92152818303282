import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Mode } from '../makeUseCustomController';

type DetailProps = {
  mode: Mode | null;
};

const NeighborTableComponent: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  id,
  mode,
}: F.TemplateProps & DetailProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PersonFormLook = {
    apiID: 'GetScientistData',
    template: mode === 'add' ? 'PersonAddForm' : 'PersonForm',
    relatedTableState,
    id: id,
    isOpen,
    viewMode: mode === 'view',
    editMode: mode === 'edit',
    setTitle,
  };

  if (mode === 'add') {
    return <Form look={look} onClose={onClose} />;
  }

  if (!id) {
    return <></>;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" isViewMode={mode === 'view'}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(NeighborTableComponent);

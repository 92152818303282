import React, { useState } from 'react';
import { Person } from 'types/models';
import { FormComponent, ListEditTable } from 'components';

type Props = {
  person: Person.ScientistPerson | null;
};

export function Changes(props: Props) {
  const { person } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  if (!person) {
    return null;
  }

  return (
    <>
      <FormComponent.Description mode="warning">
        История изменений в системе ФИО, даты рождения и гражданства
      </FormComponent.Description>
      <ListEditTable
        columns={[
          { label: 'ФИО', formatValue: x => `${x.firstName} ${x.patronymic} ${x.firstName}` },
          { label: 'Дата рождения', formatValue: x => x.birthDate },
          { label: 'Гражданство', formatValue: x => x.citizenship.label },
          { label: 'Создано', formatValue: x => x.createdBy },
          { label: 'Изменено', formatValue: x => x.modifiedBy },
        ]}
        rows={person.changes}
        selectedRowIndex={selectedRowIndex}
        selectRow={setSelectedRowIndex}
      />
    </>
  );
}

import { useLayoutEffect, useMemo, useState, useCallback, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Tender } from 'types/models/Tender';
import { Item } from 'types/models/common';
import { ReferenceItem } from 'types/models';
import { useStorage } from 'utils/Common';

export const NOTICE_PAGE_SIZE = 10;

export function useController(openTenderId: string, setOpenTenderId: any) {
  const [selectedTender, setSelectedTender] = useState<Tender>({} as Tender);
  const [listTenders, setListTenders] = useState<Tender[]>([]);
  const [isDetailsModalOpen, setIsDetailsModalOpen] = useState<boolean>(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const [enableFavorites, setEnableFavorites] = useState<boolean>(false);
  const [categories, setCategories] = useState<Item[]>([]);
  const [participants, setParticipants] = useState<Item[]>([]);
  const [interests, setInterests] = useState<Item[]>([]);
  const [favorites, setFavorites] = useState<string[]>([]);

  const [filteredList, setFilteredList] = useState<Tender[]>([]);

  // with storage actions
  const storage = useStorage('tenderFavorites');

  useEffect(() => {
    let result: Tender[] = enableFavorites ? [...listTenders].filter(i => favorites.includes(i.id || '')) : [...listTenders];

    if (categories.length) {
      const categoriesMap: string[] = categories.map(i => i.value);
      result = result.filter(i => i.categories.filter(category => categoriesMap.includes(category.id)).length);
    }

    if (participants.length) {
      const participantsMap: string[] = participants.map(i => i.value);
      result = result.filter(i => i.participants.filter(participant => participantsMap.includes(participant.id)).length);
    }

    if (interests.length) {
      const interestsMap: string[] = interests.map(i => i.value);
      result = result.filter(i => i.scienceDomainInterrests.filter(interest => interestsMap.includes(interest.id)).length);
    }

    setFilteredList(
      result.sort((a: Tender, b: Tender) => {
        return Number(b.id) - Number(a.id);
      }),
    );
  }, [categories, enableFavorites, favorites, interests, listTenders, participants]);

  /* Tenders */
  const { methods: getNoticesTenders } = BackendAPI.useBackendAPI('GetNoticeTenders', {
    onSuccessfullCall: ({ data }) => {
      setListTenders(data as Tender[]);

      if (!!openTenderId) {
        const selected = data.find(tender => tender.id === openTenderId);

        if (selected) {
          setSelectedTender(selected);
          setIsDetailsModalOpen(true);
        }
      }
    },
  });

  const getOptions = useCallback(
    (fieldName: keyof Tender): Item[] => {
      const flatted = listTenders.map(i => i[fieldName]).flat() as ReferenceItem[]; // flat childs

      return flatted
        .filter((i, index) => flatted.findIndex(x => x.id === i.id) === index) // filter by unique
        .map(i => ({
          value: i.id,
          label: i.label,
        })) as Item[];
    },
    [listTenders],
  );

  const optionsCategories = useMemo<Item[]>(() => getOptions('categories'), [getOptions]);
  const optionsParticipants = useMemo<Item[]>(() => getOptions('participants'), [getOptions]);
  const optionsInterests = useMemo<Item[]>(() => getOptions('scienceDomainInterrests'), [getOptions]);

  const changeFavorite = useCallback(
    (id: string) => {
      const clone = [...favorites];
      const inFavorites: boolean = clone.includes(id);

      if (inFavorites) {
        clone.splice(clone.indexOf(id), 1);
      } else {
        clone.push(id);
      }

      setFavorites(clone);
      storage.set(clone.filter(Boolean).join());
    },
    [favorites, storage],
  );

  const showDetailsModal = useCallback(
    (id: string) => {
      const selected = listTenders.find(tender => tender.id === id);

      if (selected) {
        setOpenTenderId?.(id);
        setSelectedTender(selected);
        setIsDetailsModalOpen(true);
      }
    },
    [listTenders, setOpenTenderId],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => {
          setIsHelpFormOpen(true);
        },
      },
    ],
    [],
  );

  useEffect(() => {
    setFavorites(storage.get().split(','));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    getNoticesTenders.callAPI({});
    // eslint-disable-next-line
  }, []);

  return {
    selectedTender,
    isDetailsModalOpen,
    setIsDetailsModalOpen,
    showDetailsModal,
    enableFavorites,
    setEnableFavorites,
    interests,
    optionsInterests,
    setInterests,
    categories,
    optionsCategories,
    setCategories,
    participants,
    optionsParticipants,
    setParticipants,
    listTenders,
    filteredList,
    favorites,
    changeFavorite,
    isHelpFormOpen,
    setIsHelpFormOpen,
    buttons,
  };
}

export function SalaryByProjectGridHelp(): JSX.Element {
  return (
    <>
      <p>Таблица содержит данные загруженные из информационной системы расчёта заработной платы</p>
      <p>
        Запись содержит выплаты по шифру для персоны. Если для таблицы включен фильтр Год, то в столбцах Янв. Начислено,
        Янв.Удержано, Янв.СВ отображаются значения за январь указанного года. Если фильтр Год отключен, то отображается суммарное
        значение за январь по шифру. Аналогично для всех месяцев (январь - декабрь)
      </p>
      <p>Статус «На согласование» обозначает, что запись не связана с участником коллектива проекта</p>

      <h3>Функция «Подтвердить в составе коллектива проекта»</h3>

      <ul>
        <li>Переводит выделенную запись в статус «Утверждена»</li>
        <li>
          Для персоны в составе коллектива добавляется запись о наличии выплат в формате:{' '}
          {`<Шифр> <Год выплаты (номера месяцев выплаты)>`}.
        </li>
        <li>
          <p>Добавляет исполнителя проекта на основе данных записи</p>

          <div className="warning">
            <span>
              Автоматическое добавление исполнителя в состав коллектива проекта выполняется по правилам изложенным ниже. Уточнить
              список, период работы для членов коллектива, указать приказы о составе коллектива, и его изменении необходимо на
              форме проекта, вкладка <strong>Коллектив</strong>
            </span>
          </div>
        </li>
      </ul>

      <h3>Правила добавления исполнителя</h3>

      <ul>
        <li>ФИО - из данных выделенной записи</li>
        <li>
          Должность – из списка актуальных для года выплаты работ персоны в справочнике персон. Выбирается должность по группе
          персонала НП, НВП, РНП, ППС, АУП, УВП, ОП (в порядке приоритетности групп)
        </li>
        <li>
          Период работы соответствует срокам этапов проекта, в рамках года выплат на момент выполнения процедуры{' '}
          <strong>Подтвердить в составе коллектива проекта</strong>
        </li>
        <li>
          Для персоны в составе коллектива добавляется запись о наличии выплат в формате:{' '}
          {`<Шифр> <Год выплаты (номера месяцев выплаты)>`}
        </li>
      </ul>

      <h3>Функция Перенести на другой шифр</h3>

      <div className="warning">
        <span>
          Функция позволяет выполнить групповую операцию, но при этом выделенные записи должны иметь одинаковый статус (На
          согласование или Утверждена)
        </span>
      </div>

      <table>
        <thead>
          <tr>
            <th>Вариант выполнения функции</th>
            <th>Описание результата</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>
              <p>Перенести с шифра проекта на шифр проекта</p>
              <p>Статус записи: «На согласование»</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на <strong>Шифр2</strong>
                </li>
                <li>Статус: «На согласование»</li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра проекта на шифр проекта</p>
              <p>Статус записи: «Утверждена»</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на <strong>Шифр2</strong>
                </li>
                <li>
                  Выделенная Персона будет удалена из состава коллектива <strong>Проекта1</strong> (кроме руководителя проекта)
                </li>
                <li>
                  В состав коллектива <strong>Проекта2</strong> добавлен исполнитель
                </li>
                <li>Статус записи: «Утверждена»</li>
                <p>
                  Посмотреть состав коллектива можно на вкладке Список участников проекта или в проекте на вкладке «Коллектив»
                </p>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра проекта на шифр Централизованного фонда</p>
              <p>Статус записи: «На согласование»</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на <strong>Шифр ЦФ</strong> и отображаться в таблице на вкладке «Оплата из накладных»
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра проекта на шифр Централизованного фонда</p>
              <p>Статус записи: «Утверждена»</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на шифр Централизованного фонда и отображаться в таблице на вкладке «Оплата из
                  накладных»
                </li>
                <li>
                  Выделенная Персона будет удалена из состава коллектива <strong>Проекта1</strong> (кроме руководителя проекта)
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра Централизованного фонда на шифр Централизованного фонда</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на <strong>Шифр ЦФ2</strong>
                </li>
              </ul>
            </td>
          </tr>

          <tr>
            <td>
              <p>Перенести с шифра Централизованного фонда на шифр проекта</p>
            </td>
            <td>
              <ul>
                <li>
                  Запись будет перенесена на <strong>Шифр2</strong>
                </li>
                <li>
                  В состав коллектива <strong>Проекта2</strong> добавлен исполнитель
                </li>
                <li>
                  Посмотреть состав коллектива можно на вкладке Список участников проекта или в проекте на вкладке «Коллектив»
                </li>
                <li>Запись будет отображаться на вкладке «Оплата по шифрам проектов»</li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import { ListEdit, SectionTitle } from 'components';

import { showNotification } from 'features/Notifications';
import { NirRequest } from 'types/models';
import { getMockStage } from 'features/Form/looks/nirRequest/NirRequestForm/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  stages: NirRequest.Stage[];
  setStages(stages: NirRequest.Stage[]): void;
  disabled: boolean;
};

export function Stages({ stages, setStages, disabled }: Props) {
  return (
    <>
      <SectionTitle title="Календарный план и финансирование проекта" />

      <ListEdit
        header={{ title: 'Календарный план и финансирование проекта', isRequired: true }}
        rows={stages ?? []}
        onChange={setStages}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Год', formatValue: x => x.year, styles: { width: '15%' } },
          { label: 'Сумма финансирования', formatValue: x => x.amount, styles: { width: '30%' }, dataKind: 'float' },
          { label: 'Название этапа', formatValue: x => x.name, styles: { width: '55%' } },
        ]}
        maxHeight="200px"
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        columnIndexesForSumTotal={[1]}
        specification={{
          mode: 'customComponent',
          renderComponent: (stage, setStage) => <Fields stage={stage || getMockStage()} setStage={setStage} />,
          validation: {
            checkIsValid: (stage, index, list) => validate(stage, list, index).every(x => x.isValid),
            onInvalidate: (stage, mode, index, list) => {
              const info = validate(stage, list, index);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
    </>
  );
}

import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function CompilationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Сборники</h4>

        <p>
          Функциональный блок <strong>Сборники</strong> предназначен для ведения реестра сборников (сборник материалов
          конференций, симпозиумов и др.; сборник научных трудов; справочное издание). Сборники также можно добавить в пункте меню
          Публикации или в процессе добавления публикации, типа «Статья в сборнике».
        </p>
        <p>
          Сборники интерпретируются как публикация университета, если Вид издания = <strong>«Издание Университета»</strong>. Для
          типов издания:
          <strong>Издание других ВУЗов, Другие издания, Зарубежные издания, Издания РАН</strong> — информация о сборнике
          интерпретируется как сведения об издании публикации, размещённой в нём (статья, тезисы).
        </p>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>
          Для более удобной работы или поиска сборника, в столбце <strong>Вид сборника</strong> можно включить фильтр и посмотреть
          список всех сборников только определенного одного типа издания.
        </p>
        <p>Вид сборника:</p>

        <ul>
          <li>Сборник материалов конференций;</li>
          <li>Сборник научных трудов;</li>
          <li>Справочное издание.</li>
        </ul>

        <p>Назначение функциональных элементов интерфейса представлено в таблице.</p>

        <div className="warning">
          <span>Набор элементов интерфейса может быть полным или частичным в зависимости от наличия прав у пользователя.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму просмотра сборника. Кнопка активна при выделении журнала в таблице</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму для добавления сборника</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования журнала. Кнопка активна при выделении сборника в таблице</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>Удаляет выделенный в таблице сборник</td>
            </tr>
            <tr>
              <td>
                Редактировать связь сборника с подразделениями: <Icon type="connection" />
              </td>
              <td>
                Функция доступна только для сборника с видом издания = <strong>Издание Университета</strong>
              </td>
            </tr>
            <tr>
              <td>
                Утвердить: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения</td>
            </tr>
            <tr>
              <td>
                Показатели сборника: <Icon type="statistic" />
              </td>
              <td>Индексация и показатели сборника</td>
            </tr>
            <tr>
              <td>
                Информация о статьях: <Icon type="info" />
              </td>
              <td>Открывает список статей сборника</td>
            </tr>
            <tr>
              <td>
                Открыть сборник во внешнем источнике: <Icon type="link" />
              </td>
              <td>Открывает страницу в новом окне браузера по выбранной ссылке: на сайте, странице базы цитирования, DOI</td>
            </tr>
            <tr>
              <td>
                Скачать файл прикрепленный к сборнику: <Icon type="download" />
              </td>
              <td>
                Скачивает прикреплённый на форму файл скан- копии сборника с выходными данными и помещается в папку{' '}
                <strong>Загрузки</strong> на рабочем столе пользователя
              </td>
            </tr>
            <tr>
              <td>
                Отправить в библиотеку: <Icon type="library" mode="arrowRight" />
              </td>
              <td>
                Отправить публикацию для ведения электронного каталога в библиотеке (при условии, если есть интеграция с
                информационной системой библиотеки)
              </td>
            </tr>
            <tr>
              <td>
                Трансформация: <Icon type="transform" />
              </td>
              <td>
                Запускает процедуру для изменения: тип сборника, вид печатный/электронный, тип статьи в сборнике материалов
                конференции
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список сборников выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Функциональная кнопка открывает форму для добавления сообщения. Все сообщения хранятся в Системе, на странице{' '}
                <strong>Главная, Вопросы от пользователей администраторам</strong>. Список сообщений конкретного пользователя и
                ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
                <strong>Новости, Вопрос - ответ</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

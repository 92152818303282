import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';

import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { useGridContext } from 'features/Table/context';
import { ENUM_DEBOUNCE_TIME } from 'features/Table/helpers';
import { Props as ComponentProps } from './index';
import { getEnum } from 'utils/Helpers';

type Props = object & ComponentProps;

const useController = ({ enumeration, setColumnFilter, value }: Props) => {
  const { enumMap } = useAppDataContext();
  const { specification } = useGridContext();
  const [selectedValues, setSelectedValues] = useState<Item[]>([]);

  const options = useMemo<Item[]>(() => {
    const isFilterActive = specification?.enumFilterValues?.enumName === enumeration;

    return getEnum(enumeration, enumMap).filter(({ value: key }) =>
      isFilterActive ? !specification?.enumFilterValues?.excludeValues.some(excludeValue => excludeValue === key) : true,
    );
  }, [specification?.enumFilterValues?.enumName, specification?.enumFilterValues?.excludeValues, enumeration, enumMap]);

  useEffect(() => {
    const selectedKeys = value?.split(',') || [];
    const preparedSelectedValues = options.filter(({ value: optionValue }) =>
      selectedKeys.some(selectedKey => selectedKey === optionValue),
    );
    setSelectedValues(preparedSelectedValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const enumDebounce = useDebouncedCallback((selectedValuesToUpdate: Item[]) => {
    setColumnFilter(selectedValuesToUpdate.map(selectedValueToUpdate => selectedValueToUpdate.value).join(','));
  }, ENUM_DEBOUNCE_TIME);

  const onFieldChange = useCallback(
    (multiValues: Item<string>[]) => {
      const nextValues = multiValues.map(multiValueItem => ({ value: multiValueItem.value, label: multiValueItem.label }));
      setSelectedValues(nextValues);
      enumDebounce(nextValues);
    },
    [enumDebounce],
  );

  return {
    options,
    selectedValues,
    onFieldChange,
  };
};

export default useController;

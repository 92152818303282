import { useState, useEffect, useMemo, useRef } from 'react';
import { format, parse } from 'date-fns';

import { Form } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { formatStr } from 'utils/Constants';
import { SecurityDocumentType } from 'utils/Enums';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
};

export function useSecurityDocumentFormController({ securityDocument, setSecurityDocument }: Props) {
  const isPatentDocument = useMemo(() => securityDocument?.type?.value === SecurityDocumentType.PATENT, [securityDocument]);
  const isKnowhowDocument = useMemo(() => securityDocument?.type?.value === SecurityDocumentType.KNOW_HOW, [securityDocument]);

  const parseDate = (value: string) => parse(value, formatStr, new Date());

  let calculateFields = useRef((prevState: Form.Fields) => ({ ...prevState }));

  useEffect(() => {
    const calculateAddedDate = (value: string | null, addYear: number): string | null => {
      if (!value) {
        return value;
      }
      const endDate = parseDate(value);
      endDate.setFullYear(endDate.getFullYear() + addYear);
      return format(endDate ?? new Date(), formatStr);
    };

    function setDocumentStartDate(prevState: Form.Fields) {
      if (prevState?.requestDate?.value && prevState?.documentNumber?.value) {
        setSecurityDocument(prev => ({ ...prev, documentStartDate: prevState.requestDate.value }));
      }
    }

    function setDocumentEndDate(prevState: Form.Fields, years: number) {
      setSecurityDocument(prev => ({
        ...prev,
        documentEndDate:
          calculateAddedDate(
            prevState?.documentNumber?.value ? prevState?.requestDate?.value || null : prev?.documentStartDate || null,
            years,
          ) || '',
      }));
    }

    if (securityDocument.type?.value === SecurityDocumentType.KNOW_HOW) {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      calculateFields.current = (prevState: Form.Fields) => ({ ...prevState });
    } else if (securityDocument.type?.value === SecurityDocumentType.EVIDENCE) {
      calculateFields.current = (prevState: Form.Fields) => {
        setDocumentStartDate(prevState);

        setSecurityDocument(prev => ({
          ...prev,
          documentStartDate: prevState?.requestDate?.value,
          documentEndDate: calculateAddedDate(prevState?.requestDate?.value, 5) || '',
        }));

        return { ...prevState };
      };
    } else if (securityDocument.type?.value === SecurityDocumentType.PATENT) {
      if (securityDocument.kind?.label === 'ПМ') {
        calculateFields.current = (prevState: Form.Fields) => {
          setDocumentStartDate(prevState);
          setDocumentEndDate(prevState, 10);

          return { ...prevState };
        };
      } else {
        calculateFields.current = (prevState: Form.Fields) => {
          setDocumentStartDate(prevState);
          setDocumentEndDate(prevState, 20);

          return { ...prevState };
        };
      }
    } else {
      calculateFields.current = (prevState: Form.Fields) => {
        setDocumentStartDate(prevState);
        setDocumentEndDate(prevState, 5);

        return { ...prevState };
      };
    }
  }, [securityDocument, setSecurityDocument]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    requestNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер заявки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestNumber: { ...prevState.requestNumber, value },
        }));
      },
    },
    requestDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата приоритета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestDate: { ...prevState.requestDate, value },
        }));
        if (!!value) {
          setFormFields(calculateFields.current);
        }
      },
    },
    requestSendDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата отправки заявки',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          requestSendDate: { ...prevState.requestSendDate, value },
        }));
      },
    },
    intellectualPropertyName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название ОИС',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          intellectualPropertyName: { ...prevState.intellectualPropertyName, value },
        }));
      },
    },
    referat: {
      value: '',
      isValid: true,
      required: false,
      title: 'Реферат',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          referat: { ...prevState.referat, value },
        }));
      },
    },
    documentNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentNumber: { ...prevState.documentNumber, value },
        }));
        setFormFields(calculateFields.current);
      },
    },
    internalNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Внутренний номер',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            internalNumber: { ...prevState.internalNumber, value },
          };
        });
      },
    },
    documentDate: {
      value: '',
      isValid: true,
      disabled: false,
      title: 'Дата публикации документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            documentDate: {
              ...prevState.documentDate,
              value,
              isValid: prevState?.requestDate?.value && value ? parseDate(prevState.requestDate.value) <= parseDate(value) : true,
            },
          };
        });
        if (isKnowhowDocument) {
          setFormFields(calculateFields.current);
        }
      },
    },
    documentReceiveDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата получения охранного документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          documentReceiveDate: { ...prevState.documentReceiveDate, value },
        }));
      },
    },
    stateNumber: {
      value: '',
      isValid: true,
      required: false,
      tooltipText:
        // eslint-disable-next-line max-len
        'Номер регистрации в государственной информационной системе в соответствии с постановлениями Правительства Российской Федерации от 12 апреля 2013 г. № 327 «О единой государственной информационной системе учета научно-исследовательских, опытно-конструкторских и технологических работ гражданского назначения», от 07 октября 2021 г.№ 1705 «О едином реестре результатов научно-исследовательских, опытно-конструкторских и технологических работ военного, специального или двойного назначения и признании утратившими силу некоторых актов Правительства Российской Федерации и отдельного положения акта Правительства Российской Федерации» и иными нормативными актами.',
      title: 'Номер госрегистрации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          stateNumber: { ...prevState.stateNumber, value },
        }));
      },
    },
    stateRegistrationDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата госрегистрации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          stateRegistrationDate: { ...prevState.stateRegistrationDate, value },
        }));
      },
    },
    isRus: {
      value: false,
      isValid: true,
      title: 'Действует на территории РФ',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isRus: {
            ...prevState.isRus,
            value: !prevState.isRus.value,
          },
          isWorldwide: { ...prevState.isWorldwide, value: false },
        }));
      },
    },
    isWorldwide: {
      value: false,
      isValid: true,
      title: 'Действует по всему миру',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isWorldwide: {
            ...prevState.isWorldwide,
            value: !prevState.isWorldwide.value,
          },
          isRus: { ...prevState.isRus, value: false },
        }));
      },
    },
    balanceOrderNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Номер документа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          balanceOrderNumber: { ...prevState.balanceOrderNumber, value },
        }));
      },
    },
    balancePrice: {
      value: '',
      isValid: true,
      required: false,
      title: 'Стоимость',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          balancePrice: { ...prevState.balancePrice, value },
        }));
      },
    },
    loDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата постановки на баланс',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => {
          return {
            ...prevState,
            loDate: { ...prevState.loDate, value: value },
          };
        });
        setFormFields((prevState: Form.Fields) => {
          if (securityDocument.type?.value !== SecurityDocumentType.NON_REGISTERED) {
            setFormFields(calculateFields.current);
          }
          return {
            ...prevState,
          };
        });
      },
    },
    hiDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата снятия с баланса',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          hiDate: { ...prevState.hiDate, value },
        }));
      },
    },
  });

  useEffect(() => {
    if (!securityDocument?.id) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        isRus: {
          ...prevState.isRus,
          value: isPatentDocument,
        },
        isWorldwide: { ...prevState.isWorldwide, value: !isPatentDocument },
      }));
    }
    // eslint-disable-next-line
  }, []);

  return {
    securityDocumentFormFields: formFields,
    setSecurityDocumentFormFields: setFormFields,
    securityDocument,
  };
}

export function LicenceAgreementContractGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Источник данных для таблицы — сведения из Календарного плана проекта, связанного с договором (модуль{' '}
        <strong>Проекты, на вкладке Заявки</strong> указан договор на использование интеллектуального права на объекты).
      </p>
    </>
  );
}

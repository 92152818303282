import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetScopusWosPublicationProjectList(): Table.Specification {
  return {
    apiID: 'GetScopusWosPublicationProjectList',
    header: {
      firstLevel: {
        title:
          // eslint-disable-next-line max-len
          'Количество публикаций по проектам, которые индексируются в Scopus или WoS (в столбцах Q0-Q4 учитывается максимальное значение квартиля)',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    isWithQna: true,
  };
}

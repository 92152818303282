import React, { memo } from 'react';

import { Document } from 'types/models';
import { Documents } from 'features/Documents';

type Props = {
  documents: Document[];
  setDocuments: (docs: Document[]) => void;
  viewMode?: boolean;
};

const Docs = ({ documents, setDocuments, viewMode = false }: Props) => (
  <Documents
    isCanIsInfoEdit
    isCanIsSharedEdit
    documents={documents}
    setDocuments={setDocuments}
    disabled={viewMode}
    hint="Не забудьте добавить подтверждающий документ о проведении мероприятия"
    // eslint-disable-next-line max-len
    tooltipDocumentName="Подтверждающий документ - это Положение о конкурсе, Каталог участников (в т.ч. на сайте Мероприятия подтверждает официальное участие университета) и проч."
  />
);

export const Component = memo(Docs);

import { useCallback, useMemo, useState } from 'react';
import { useStreamsByApiID } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Project, Report } from 'types/models';
import { showNotification } from 'features/Notifications';
import { streams as globalStreams } from 'features/Table/streams';
import { useReportsHook, Reports } from 'features/BuildReportPopup';

type Arguments = {
  onSave(): void;
  onSaveAndContinue(): void;
  projectId: string | null;
  hidden: boolean;
};

export function useController({ onSave, onSaveAndContinue, projectId, hidden }: Arguments) {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [feedline, setFeedline] = useState<Project.Feedline | null>(null);

  const feedlineListStreams = useStreamsByApiID(globalStreams, 'GetProjectFeedlineList');

  const reloadFeedlineList = useCallback(() => {
    feedlineListStreams.reloadTable.push({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const toggleVisibleCodeChangesModal = useCallback(() => {
    setIsOpenCodeChangesModal(prev => !prev);
  }, []);

  const toggleVisibleMessageModal = useCallback(() => {
    setFeedline(null);
    setIsOpenMessageModal(prev => !prev);
  }, []);

  const { methods: saveFeedlineAPI } = BackendAPI.useBackendAPI('SaveProjectUserFeedline');

  const handleSubmitMessageModal = useCallback(
    (feed: Project.Feedline) => {
      saveFeedlineAPI.callAPI(
        { feedline: feed, projectIds: [projectId ?? '-1'] },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
            reloadFeedlineList();
          },
        },
      );
    },
    [projectId, reloadFeedlineList, saveFeedlineAPI],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.ProjectResults,
      // Reports.ProjectAboutChangesOrder,
      // TODO Удалено в рамках 524 для всех типов проектов
      Reports.ProjectIkrbs,
      Reports.ProjectResultsDetail,
      Reports.ProjectPerformersForCitis,
      Reports.ScienceProjectPerformerCompositionMemo,
      Reports.ProjectTechnicalTask,
      Reports.ProjectDocuments,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: onSave,
        isDisabled: hidden,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: onSaveAndContinue,
        isDisabled: hidden,
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: toggleVisibleMessageModal,
        isHidden: projectId === null || hidden,
      },
      {
        icon: { type: 'clock' },
        title: 'Журнал изменений в проекте',
        onClick: toggleVisibleCodeChangesModal,
        isHidden: projectId === null || hidden,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isHidden: projectId === null,
      },
    ],
    [
      hidden,
      onSave,
      onSaveAndContinue,
      toggleVisibleCodeChangesModal,
      toggleVisibleMessageModal,
      projectId,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    toggleVisibleCodeChangesModal,
    isOpenCodeChangesModal,
    isOpenMessageModal,
    feedline,
    setFeedline,
    toggleVisibleMessageModal,
    handleSubmitMessageModal,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

import React, { memo } from 'react';

import { Modal } from 'components';

import { Table } from 'types/models';
import { ParticipationTypeCode } from 'types/models/Participation';
import { Component as ParticipationFinancicalSupportPopupComponent } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  participationId: string;
  relatedTableState: Table.State | undefined;
  participationTypeCode: ParticipationTypeCode;
  workMode: Table.WorkMode;
  onSuccess: () => void;
};

const ParticipationFinancicalSupportPopupContainer = ({
  isOpen,
  onClose,
  participationId,
  relatedTableState,
  workMode,
  participationTypeCode,
  onSuccess,
}: Props) => {
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактирование связей участия с проектами и программами финансирования (ID: ${participationId})`}
      size="large"
    >
      <ParticipationFinancicalSupportPopupComponent
        participationId={participationId}
        workMode={workMode}
        participationTypeCode={participationTypeCode}
        relatedTableState={relatedTableState}
        onClose={onClose}
        onSuccess={onSuccess}
      />
    </Modal>
  );
};

export const ParticipationFinancicalSupportPopup = memo(ParticipationFinancicalSupportPopupContainer);

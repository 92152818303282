import { Arguments } from '.';

export type State = {
  isPersonalMode: boolean;
};

export function makeUseCustomController({ isPersonalMode = false }: Arguments) {
  return function useCustomController(): State {
    return {
      isPersonalMode,
    };
  };
}

import React, { memo } from 'react';

import { Toolbar } from 'components';

import { Table as T } from 'types/models';
import { useController } from './controller';

type WrapperdProps = {
  roleId?: string;
};

type Props = {
  tableState: T.State;
};

export const LeftPanelForThirdLevel = ({ roleId }: WrapperdProps) =>
  memo(({ tableState }: Props) => {
    const { buttons } = useController({
      tableState,
      roleId,
    });

    return <Toolbar buttons={buttons} />;
  });

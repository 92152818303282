import React, { useCallback, useMemo } from 'react';

import { ButtonProps, FormComponent, Modal, ReferenceItem, Select, SelectMode, Toolbar } from 'components';

import { Item } from 'types/models/common';
import { showNotification } from 'features/Notifications';
import { SelectJob } from './SelectJob/index';
import { SelectEducation } from './SelectEducation/index';
import { Member } from '../SelectPerson';
import { useController } from './controller';
import { PersonHistoryMode } from './model';
import { prepareAcademicRank, prepareDegree, prepareRank } from './helpers';
import { getAcademicRankOption, getOption } from '../helpers';

type Props = {
  isOpen: boolean;
  member: Member | null;
  onClose(): void;
  onSubmit(author: Partial<Member>): void;
  isLoadPersonAfterOpen?: boolean;
  mode?: PersonHistoryMode;
};

function PersonHistoryModal(props: Props) {
  const { isOpen, onClose, member, isLoadPersonAfterOpen, onSubmit, mode = 'add' } = props;

  const scientistPerson = member?.person ?? null;

  const {
    academicRank,
    citizenship,
    degree,
    education,
    job,
    rank,
    setDegree,
    setAcademicRank,
    setCitizenship,
    setEducation,
    setJob,
    setRank,
    onRefreshHistoricalSlice,
    savePerson,
  } = useController({
    isOpen,
    onSubmit,
    member,
    isLoadPersonAfterOpen,
    mode,
  });

  const handleChangeDegree = useCallback(
    (option: Item | null) => {
      const foundDegree = prepareDegree(scientistPerson?.degrees.find(x => x.id === option?.value) ?? null);
      const isActive = foundDegree ? foundDegree.isActive : true;
      const preparedDegree = isActive ? foundDegree : degree;
      if (!isActive) {
        showNotification({ message: 'Нельзя выбрать неактивную ученую степень', theme: 'danger' });
      }
      setDegree(preparedDegree);
    },
    [degree, scientistPerson?.degrees, setDegree],
  );

  const handleChangeRank = useCallback(
    (option: Item | null) => {
      const foundRank = prepareRank(scientistPerson?.ranks.find(x => x.id === option?.value) ?? null);
      const isActive = foundRank ? foundRank.isActive : true;
      const preparedRank = isActive ? foundRank : rank;
      if (!isActive) {
        showNotification({ message: 'Нельзя выбрать неактивное ученое звание', theme: 'danger' });
      }
      setRank(preparedRank);
    },
    [rank, scientistPerson?.ranks, setRank],
  );

  const handleChangeAcademicRank = useCallback(
    (option: Item | null) => {
      const foundRank = prepareAcademicRank(scientistPerson?.academicRanks.find(x => x.id === option?.value) ?? null);
      const isActive = foundRank ? foundRank.isActive : true;

      const preparedRank = isActive ? foundRank : academicRank;
      if (!isActive) {
        showNotification({ message: 'Нельзя выбрать неактивное академическое звание', theme: 'danger' });
      }
      setAcademicRank(preparedRank);
    },
    [academicRank, scientistPerson?.academicRanks, setAcademicRank],
  );

  const handleSubmit = useCallback(() => {
    onSubmit({ citizenship, job, education, degree, rank, academicRank });
    onClose();
  }, [onSubmit, onClose, citizenship, job, education, degree, rank, academicRank]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: handleSubmit,
      },
      {
        icon: { type: 'refresh' },
        title: 'Заполнить актуальными данными',
        onClick: onRefreshHistoricalSlice,
      },
    ],
    [handleSubmit, onRefreshHistoricalSlice],
  );

  if (!scientistPerson) {
    return null;
  }

  const optionsDegrees = scientistPerson.degrees.filter(({ isActive }) => isActive).map(getOption);
  const optionsRanks = scientistPerson.ranks.filter(({ isActive }) => isActive).map(getOption);
  const optionsAcademicRanks = scientistPerson.academicRanks.filter(({ isActive }) => isActive).map(getOption);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Данные персоны: проверьте и сохраните" size="large">
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Wrapper>
        <FormComponent.Description mode="warning">
          Проверьте данные о выбранной персоне, актуальные на момент совершаемого события. Именно эти данные будут храниться в
          истории и использоваться для построения отчётов
        </FormComponent.Description>
        <FormComponent.Description mode="info">
          <strong>{scientistPerson.fullName}</strong> <span>(дата рождения: {scientistPerson.scientist?.dateBirth})</span>
        </FormComponent.Description>

        <FormComponent.Line>
          <FormComponent.Field label="Гражданство">
            <Select
              mode={SelectMode.REFERENCE}
              value={citizenship}
              onChange={(option: ReferenceItem | null) => setCitizenship(option)}
              settings={{ name: 'RefCountry', title: 'Справочник "Страны"', isClearable: true }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {!!scientistPerson.degrees && (
          <FormComponent.Line>
            <FormComponent.Field label="Ученая степень">
              <Select
                value={degree ? getOption(degree) : null}
                options={optionsDegrees}
                settings={{ isClearable: true }}
                onChange={handleChangeDegree}
                placeholder={optionsDegrees.length ? 'Не выбрано' : 'У персоны отсутствуют Ученые степени'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label="Ученое звание">
            <Select
              value={rank ? getOption(rank) : null}
              options={optionsRanks}
              settings={{ isClearable: true }}
              onChange={handleChangeRank}
              placeholder={optionsRanks.length ? 'Не выбрано' : 'У персоны отсутствуют Ученые звания'}
              isDisabled={!optionsRanks.length}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Академическое звание">
            <Select
              value={academicRank ? getAcademicRankOption(academicRank) : null}
              options={optionsAcademicRanks}
              settings={{ isClearable: true }}
              onChange={handleChangeAcademicRank}
              placeholder={optionsAcademicRanks.length ? 'Не выбрано' : 'У персоны отсутствуют Академические звания'}
              isDisabled={!optionsAcademicRanks.length}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Место работы">
            <SelectJob person={scientistPerson} value={job} onChange={setJob} savePerson={savePerson} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Место обучения">
            <SelectEducation person={scientistPerson} value={education} onChange={setEducation} savePerson={savePerson} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { PersonHistoryModal };

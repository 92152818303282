import React, { useState, useCallback } from 'react';
import { Form, Table } from 'types/models';

import {
  ButtonMode,
  FormComponent,
  Modal,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { DataGrid } from 'features/Table';
import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  formFields: Form.Fields;
  workMode: Table.WorkMode;
  editionTypes: Table.EnumValue[];
  onCreateBibliographicRecordClick: () => void;
};

const Source = ({ formFields, workMode, editionTypes, onCreateBibliographicRecordClick }: Props) => {
  const { initialLanguageCode } = useAppDataContext();

  const [isPublisherWarningOpen, setIsPublisherWarningOpen] = useState<boolean>(false);
  const [isRefPublisherModalOpen, setIsRefPublisherModalOpen] = useState<boolean>(false);

  const handleLanguageChange = useCallback(
    (value: ReferenceItem) => {
      if (
        initialLanguageCode &&
        initialLanguageCode?.id !== value.id &&
        !formFields.publisher.value?.foreignName &&
        !!formFields.publisher.value?.id
      )
        setIsPublisherWarningOpen(true);
      formFields.textLanguage.onChange(value);
    },
    [formFields.publisher.value?.foreignName, formFields.publisher.value?.id, formFields.textLanguage, initialLanguageCode],
  );

  return (
    <>
      <SectionTitle title="Издание" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.isbn.title}>
          <TextInput
            mode={TextInputMode.ISBN}
            value={formFields.isbn.value}
            onChange={formFields.isbn.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.eisbn.title}>
          <TextInput
            mode={TextInputMode.ISBN}
            value={formFields.eisbn.value}
            onChange={formFields.eisbn.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Издательство">
          <Select
            mode={SelectMode.REFERENCE}
            value={{
              label:
                formFields.textLanguage.value?.id !== initialLanguageCode?.id
                  ? formFields.publisher.value?.foreignName
                    ? formFields.publisher.value?.foreignName
                    : formFields.publisher.value?.label
                  : formFields.publisher.value?.label,
              id: formFields.publisher.value?.id,
            }}
            onChange={formFields.publisher.onChange}
            settings={{ name: 'RefPublisher', title: 'Справочник "Издательства"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Место издания" isRequired={formFields.address.required}>
          <Select
            mode={SelectMode.SUGGESTION}
            value={{ value: formFields.address.value, label: formFields.address.value }}
            onChange={(option: Item | null) => formFields.address.onChange(option?.value || '')}
            settings={{ name: 'Publication.address', isClearable: !formFields.address.required }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Язык текста" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.textLanguage.value}
            onChange={handleLanguageChange}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.originalLanguage.title}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.originalLanguage.value}
            onChange={formFields.originalLanguage.onChange}
            settings={{ name: 'RefLanguage', title: formFields.originalLanguage.title, isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field isRequired={formFields.pageCount.required} label={formFields.pageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ max: 100000, decimal: false }}
            value={formFields.pageCount.value ?? ''}
            onChange={formFields.pageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.pageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field isRequired={formFields.printPageCount.required} label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.printPageCount.value ?? ''}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.printPageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.tirage.title} isRequired={formFields.tirage.required}>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ decimal: false }}
            value={formFields.tirage.value ?? ''}
            onChange={formFields.tirage.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.tirage.isValid}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.categoryEdition.title} tooltip={formFields.categoryEdition.tooltipText} isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.categoryEdition.value}
            onChange={formFields.categoryEdition.onChange}
            settings={{ name: 'RefCategoryEdition', title: 'Вид издания по целевому назначению' }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.typeEdition.title} isRequired={formFields.typeEdition.required}>
          <Select
            value={({ ...formFields.typeEdition.value, ...{ value: formFields.typeEdition.value.id } } as unknown) as Item}
            options={editionTypes as Item[]}
            onChange={(option: Item) => formFields.typeEdition.onChange(option.label)}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.republishData.title}>
          <TextInput
            value={formFields.republishData.value}
            onChange={formFields.republishData.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.volumeNumber.title}>
          <TextInput
            value={formFields.volumeNumber.value}
            onChange={formFields.volumeNumber.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.seriesTitle.title}>
          <TextInput
            value={formFields.seriesTitle.value}
            onChange={formFields.seriesTitle.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.year.title} isRequired>
          <TextInput
            mode={TextInputMode.YEAR}
            value={formFields.year.value}
            onChange={formFields.year.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.month.title}>
          <TextInput
            mode={TextInputMode.MONTH}
            value={formFields.month.value}
            onChange={formFields.month.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.day.title}>
          <TextInput
            mode={TextInputMode.DAY}
            value={formFields.day.value}
            onChange={formFields.day.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label={formFields.publicationDate.title}>
          <TextDateTime
            value={formFields.publicationDate.value}
            onChange={formFields.publicationDate.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />

      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            settings={{
              rows: 3,
              externalButtons: [
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Построить',
                  onClick: onCreateBibliographicRecordClick,
                  isDisabled: workMode === 'viewMode',
                },
              ],
            }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.grnti.title} tooltip={formFields.grnti.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.grnti.value}
            onChange={formFields.grnti.onChange}
            settings={{ name: 'RefGrnti', title: 'Справочник "ГРНТИ"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.study.title} tooltip={formFields.study.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.study.value}
            onChange={formFields.study.onChange}
            settings={{ name: 'RefStudy', title: 'Справочник "Область науки"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <Modal
        mode="info"
        title="Название издательства на английском языке"
        isOpen={isPublisherWarningOpen}
        onClose={() => setIsPublisherWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Открыть справочник',
            onClick: () => {
              setIsPublisherWarningOpen(false);
              setIsRefPublisherModalOpen(true);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsPublisherWarningOpen(false),
          },
        ]}
        size="small"
      >
        <>
          Для выбранного издательства необходимо заполнить данные в столбце "Наименование на английском" в справочнике
          "Издательства"
        </>
      </Modal>

      <Modal
        isOpen={isRefPublisherModalOpen}
        onClose={() => setIsRefPublisherModalOpen(false)}
        title='Справочник "Издательства"'
        size={['extralarge', 'medium-height']}
      >
        <DataGrid specification={GetReferenceElementList({ requestData: { filters: [], name: 'RefPublisher' } })} />
      </Modal>
    </>
  );
};

export const SourceComponent = React.memo(Source);

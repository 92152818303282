import { Author, FileInfo, Form } from 'types/models';
import { Event } from 'types/models/Event';
import { PublicationProject } from 'types/models/Form';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

type Props = {
  isElectronic: boolean | undefined;
  electronicType: string | undefined;
  formFields: Form.Fields;
  projects: PublicationProject[];
  mobileRequests: Form.PublicationMobileRequest[];
  status: string;
  events: Event[];
  type: string | undefined;
  authors: Author[];
};

const formatToRequest = ({
  isElectronic,
  electronicType,
  formFields,
  projects,
  mobileRequests,
  status,
  type,
  events,
  authors,
}: Props) => ({
  simpleFields: {
    type: type?.toUpperCase() || '',
    status,
    isElectronic: !!isElectronic,
    electronicType: (!!isElectronic && electronicType) || '',
    name: clearWhitespaceCharacters(formFields.name.value),
    year: formFields.year.value as string,
    month: formFields.month.value as string,
    day: formFields.day.value as string,
    tirage: formFields.tirage.value as string,
    seriesTitle: formFields.seriesTitle.value as string,
    issn: formFields.issn.value as string,
    isbn: formFields.isbn.value as string,
    volume: formFields.volume.value as string,
    issue: formFields.issue.value as string,
    printPageCount: formFields.printPageCount.value as string,
    doi: formFields.doi.value as string,
    url: formFields.url.value as string,
    libraryLink: formFields.libraryLink.value as string,
    typeEdition: formFields.typeEdition.value?.id as string,
    pageCount: formFields.pageCount.value as string,
    address: formFields.address.value as string,
    bibliographicRecord: formFields.bibliographicRecord.value as string,
    electronicMediaSource: formFields.electronicMediaSource.value as string,
    electronicSystemRequirements: formFields.electronicSystemRequirements.value as string,
    udk: formFields.udk.value as string,
    urlDate: formFields.date.value as string,
    annotation: formFields.annotation.value as string,
    authorsKeyWords: formFields.authorsKeyWords.value as string,
    authorsCount: formFields.authorsCount.value as string,
    fractionalCount: formFields.fractionalScore.value as string,
    foreignName: formFields.foreignName.value || '',
    foreignAnnotation: formFields.foreignAnnotation.value || '',
    foreignAuthorsKeyWords: formFields.foreignAuthorsKeyWords.value || '',
    foreignAuthors: formFields.foreignAuthors.value || '',
    publicationDate: formFields.publicationDate.value as string,
  },
  arrayFields: {
    translations: { translation: [] },
    events: { event: events },
  },
  attrIdFields: {
    sourceType: formFields.sourceType.value?.id,
    publisher: formFields.publisher.value?.id,
    categoryEdition: formFields.categoryEdition.value?.id,
    textLanguage: formFields.textLanguage.value?.id,
    grnti: formFields.grnti.value?.id,
    study: formFields.study.value?.id,
  },
  projects,
  mobileRequests: { mobileRequest: mobileRequests },
  authors,
  file: formFields.file.value as FileInfo,
});

export { formatToRequest };

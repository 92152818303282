import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import {
  FormComponent,
  Tabs,
  Tab,
  Checkbox,
  TextDateTime,
  Radio,
  SectionTitle,
  Select,
  SelectMode,
  ListEdit,
  TextArea,
  TextInput,
  TextInputMode,
} from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Documents } from 'features/Documents';
import { GetFullScienceProjectList, GetJointEventFeedlineList, GetReferenceElementList } from 'features/Table/specifications';
import { showNotification } from 'features/Notifications';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { DataGrid } from 'features/Table';
import { useController } from './controller';
import { EnterpriseForm } from './EnterpriseForm';
import { getHistoryInfo, convertToEventMemberCountType, convertToEventAmountType, getAwardList } from './helpers';
import { CommitteMemberFields } from './CommitteMembersFields';
import { MemberCountComponent } from 'features/Form/looks/event/views/MemberCount';
import { formatMember } from 'features/Form/looks/event/views/Organization/helpers';
import { ParticipantFields } from './ParticipantFields';
import { formatAuthor } from 'features/Form/looks/participation/views/MembersList/helpers';
import { AwardFields } from './AwardFields';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function JointEventForm({ viewMode, editMode, onClose }: Props) {
  const {
    feedlineMessage,
    feedlineMessageType,
    calculateButton,
    formatList,
    handleFeedlineMessageChange,
    handleFeedlineMessageTypeChange,
    handleAwardsChange,
    handleCommitteMembersChange,
    handleDepartmentsChange,
    handleDescriptionChange,
    handleDocumentsChange,
    handleEnterprisesChange,
    handleFormatChange,
    handleIsStudentChange,
    handleKeyWordsChange,
    handleLevelChange,
    handleMemberCountChange,
    handleMemberCountFactChange,
    handleNameChange,
    handleOrderDateChange,
    handleOrderNumberChange,
    handleParticipantChange,
    handleParticipantsChange,
    handleProjectAccept,
    handleProjectCancelAcceptance,
    handleProjectsChange,
    handleRangeDateChange,
    handleSiteChange,
    handleTypeChange,
    isFeedlineMessagePopupOpen,
    isOtherSelected,
    isTSUSelected,
    jointEvent,
    jointEventId,
    onSubmit,
    participant,
    participantList,
    projectRowConverter,
    relatedTableState,
    handleSaveButtonClick,
    settings,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  return (
    <>
      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleSaveButtonClick(true)}
          handleSaveAndContinue={() => handleSaveButtonClick(false)}
          viewMode={viewMode}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О мероприятии">
              <FormComponent.ColumnWrapper fitContent>
                <FormComponent.Column>
                  <FormComponent.Line>
                    <FormComponent.Field label="Название" isRequired>
                      <TextArea
                        settings={{ rows: 3 }}
                        value={jointEvent.name}
                        onChange={handleNameChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Дата проведения" isRequired>
                      <span>
                        <TextDateTime
                          value={jointEvent.startDate || ''}
                          onChange={(value: string) => handleRangeDateChange('startDate', value)}
                          maxValue={jointEvent.endDate || ''}
                          isDisabled={!!viewMode}
                        />
                      </span>
                      <span>&nbsp;-&nbsp;</span>
                      <span>
                        <TextDateTime
                          value={jointEvent.endDate || ''}
                          onChange={(value: string) => handleRangeDateChange('endDate', value)}
                          minValue={jointEvent.startDate || ''}
                          isDisabled={!!viewMode}
                        />
                      </span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Уровень мероприятия" isRequired>
                      <Select
                        mode={SelectMode.REFERENCE}
                        value={jointEvent.level}
                        onChange={handleLevelChange}
                        settings={{ name: 'RefEventStatus', title: 'Справочник "Уровни мероприятия"' }}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Тип" isRequired>
                      <Select
                        mode={SelectMode.ENUM}
                        value={jointEvent.type}
                        settings={{ name: 'JointEventType' }}
                        onChange={handleTypeChange}
                        isDisabled={viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field label="Формат проведения" isRequired>
                    <Radio
                      isDisabled={viewMode}
                      value={jointEvent.format?.value}
                      list={formatList}
                      onChange={handleFormatChange}
                    />
                  </FormComponent.Field>
                  <FormComponent.Field label="Мероприятие для студентов">
                    <Checkbox checked={jointEvent.isStudent} onChange={handleIsStudentChange} isDisabled={!!viewMode} />
                  </FormComponent.Field>
                  <FormComponent.Line>
                    <FormComponent.Field label="Приказ">
                      <TextInput value={jointEvent.orderNumber} onChange={handleOrderNumberChange} isDisabled={!!viewMode} />
                    </FormComponent.Field>
                    <FormComponent.Field label="Дата приказа">
                      <TextDateTime value={jointEvent.orderDate} onChange={handleOrderDateChange} isDisabled={!!viewMode} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Описание мероприятия">
                      <TextArea
                        settings={{ rows: 3 }}
                        value={jointEvent.description}
                        onChange={handleDescriptionChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Ключевые слова">
                      <TextArea
                        settings={{ rows: 3 }}
                        value={jointEvent.keyWords}
                        onChange={handleKeyWordsChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Field label="Публикация о мероприятии">
                    <TextInput
                      mode={TextInputMode.URL}
                      value={jointEvent.site}
                      onChange={handleSiteChange}
                      isDisabled={!!viewMode}
                    />
                  </FormComponent.Field>

                  <SectionTitle title="Организовано при финансовой поддержке" />

                  <ListEdit
                    rows={jointEvent.projects}
                    onChange={handleProjectsChange}
                    toolbar={[
                      'add',
                      'edit',
                      'delete',
                      {
                        key: 'accept',
                        onClick: e => {
                          if (e?.project?.id) handleProjectAccept(e?.project.id);
                        },
                      },
                      {
                        key: 'decline',
                        onClick: e => {
                          if (e?.project?.id) handleProjectCancelAcceptance(e.project.id);
                        },
                      },
                    ]}
                    columns={[
                      { label: 'Номер проекта', formatValue: row => row.project?.number || '' },
                      { label: 'Название проекта', formatValue: row => row.project?.name || '' },
                      { label: 'Программа/Тип гранта', formatValue: row => row.project?.program || '' },
                      { label: 'Рук-ль, отв. исполнитель', formatValue: row => row.project?.leader || '' },
                      { label: 'Факультет/Институт', formatValue: row => row.project?.governances || '' },
                      { label: 'Финансирование', formatValue: row => row.project?.financings || '' },
                      {
                        label: 'Принят к отчету',
                        formatValue: row => !!row.accepted,
                      },
                    ]}
                    maxHeight="300px"
                    defaultRowsCount={3}
                    specification={{
                      mode: 'relationTableModal',
                      modalTableRowConverter: projectRowConverter,
                      relationTableModalTitle: 'Научные проекты',
                      modalTableSpecification: GetFullScienceProjectList({
                        templatesTableDependencies: relatedTableState && {
                          relatedTableAPIID: 'GetMagazineList',
                          relatedTableState,
                        },
                      }),
                    }}
                    isDisabled={viewMode}
                    isDeleteConfirmEnabled
                    withMessages
                  />
                </FormComponent.Column>

                <FormComponent.Column>
                  <SectionTitle title="Организаторы мероприятия" />

                  <FormComponent.Field>
                    <Radio isDisabled={viewMode} value={participant} list={participantList} onChange={handleParticipantChange} />
                  </FormComponent.Field>
                  <>
                    {isTSUSelected && (
                      <FormComponent.Field>
                        <ListEdit
                          header={{ title: `Подразделения ${settings?.organization?.shortName}`, isRequired: true }}
                          rows={jointEvent.departments}
                          onChange={handleDepartmentsChange}
                          toolbar={['add', 'edit', 'delete']}
                          columns={[{ label: '', formatValue: row => row.element?.label || '' }]}
                          maxHeight="300px"
                          defaultRowsCount={isOtherSelected ? 5 : 12}
                          isDisabled={viewMode}
                          withoutHead
                          specification={{
                            mode: 'loadInstanceTableModal',
                            onStartLoad: (tableRows, onSuccess) => {
                              loadReferenceAPI.callAPI(
                                { filters: [], referenceName: 'RefDepartment', childIds: tableRows.map(x => x.id) },
                                {
                                  onSuccessfullCall: ({ data }) =>
                                    onSuccess(data.map(e => ({ id: null, element: e, position: '', isFinancing: false }))),
                                },
                              );
                            },
                            relationTableModalTitle: `Справочник "Подразделения  ${settings?.organization?.shortName}"`,
                            modalTableSpecification: GetReferenceElementList({
                              requestData: { filters: [], name: 'RefDepartment' },
                            }),
                          }}
                        />
                      </FormComponent.Field>
                    )}
                    {isOtherSelected && (
                      <FormComponent.Field>
                        <ListEdit
                          header={{ title: 'Внешние организации: организаторы и участники мероприятия', isRequired: true }}
                          rows={jointEvent.enterprises}
                          onChange={handleEnterprisesChange}
                          toolbar={['add', 'edit', 'delete']}
                          columns={[{ label: '', formatValue: row => row.element?.label || '' }]}
                          isDisabled={viewMode}
                          maxHeight="300px"
                          defaultRowsCount={isTSUSelected ? 5 : 12}
                          withoutHead
                          specification={{
                            mode: 'customComponent',
                            validation: {
                              checkIsValid: row => !!row?.role?.value && !!row.element?.id,
                              onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                            },
                            renderComponent: (row, onChange) => <EnterpriseForm document={row} onChange={onChange} />,
                          }}
                          withMessages
                          isDeleteConfirmEnabled
                        />
                      </FormComponent.Field>
                    )}
                  </>

                  <SectionTitle title="Ответственные за мероприятие" />

                  <ListEdit
                    rows={jointEvent.committeMembers}
                    onChange={handleCommitteMembersChange}
                    toolbar={['add', 'edit', 'delete']}
                    columns={[
                      { label: 'ФИО', formatValue: x => x.person?.fullName || '' },
                      { label: 'Сведения о работе/обучении', formatValue: x => getHistoryInfo(x) },
                      { label: 'Роль', formatValue: x => x.role?.label || '' },
                      { label: 'Контакты', formatValue: x => getPersonContacts(x.person?.contacts) },
                    ]}
                    maxHeight="300px"
                    defaultRowsCount={3}
                    isDeleteConfirmEnabled
                    isDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: author => !!author?.person?.id && !!author?.role?.id,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => <CommitteMemberFields committeMember={row} onChange={onChange} />,
                    }}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab title="Участники">
              <FormComponent.ColumnWrapper fitContent>
                <FormComponent.Column>
                  <SectionTitle title="Показатели результативности" />
                  <FormComponent.Line hasFreeFormat>
                    <FormComponent.Field label="Всего участников мероприятия:" labelSize="fit" isRequired />
                    <FormComponent.Field label="ФАКТ" labelSize="fit">
                      <TextInput
                        mode={TextInputMode.NUMBER}
                        value={jointEvent.memberCountFact}
                        onChange={handleMemberCountFactChange}
                        externalButtons={[calculateButton]}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <ListEdit
                      header={{ title: 'В том числе по категориям и организациям:' }}
                      rows={jointEvent.memberCounts.map(convertToEventMemberCountType)}
                      onChange={handleMemberCountChange}
                      toolbar={['add', 'edit', 'delete']}
                      columns={[
                        {
                          label: 'Откуда',
                          styles: { width: '80%' },
                          formatValue: row => formatMember(row),
                        },
                        { label: 'Количество участников', styles: { width: '20%' }, formatValue: row => row.amount },
                      ]}
                      defaultRowsCount={6}
                      maxHeight="300px"
                      isDisabled={viewMode}
                      columnIndexesForSumTotal={[1]}
                      columnIndexesForIntegerTotal={[1]}
                      specification={{
                        mode: 'customModalComponent',
                        modalTitle: 'Добавить количество участников по категории:',
                        onPreSubmit: (member, submit) => {
                          submit(member);
                        },
                        renderComponent: (member, onChange, index, mode) => (
                          <MemberCountComponent
                            mode={mode}
                            memberCounts={jointEvent.memberCounts.map(convertToEventMemberCountType)}
                            memberCountsAnother={(member?.amounts || []).map(convertToEventAmountType)}
                            disabled={viewMode}
                            documentMember={member}
                            onChangeMember={onChange as any}
                            hasShortForm
                          />
                        ),
                      }}
                    />
                  </FormComponent.Line>

                  <SectionTitle title="Награды участников" />

                  <ListEdit
                    rows={getAwardList(jointEvent.participants)}
                    onChange={handleAwardsChange}
                    toolbar={['add', 'edit', 'delete', 'move']}
                    columns={[
                      {
                        label: 'Участник',
                        formatValue: x => x.person?.shortName || x.person?.fullName || '',
                      },
                      {
                        label: 'Категория награды',
                        formatValue: x => x.award.category?.label || '',
                      },
                      {
                        label: 'Награда',
                        formatValue: x => x.award.name,
                      },
                    ]}
                    maxHeight="300px"
                    defaultRowsCount={6}
                    isDeleteConfirmEnabled
                    isDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: row => !!row?.person?.id && !!row.award.category?.value && !!row.award.name,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => (
                        <AwardFields row={row} onChange={onChange} participants={jointEvent.participants} />
                      ),
                    }}
                  />
                </FormComponent.Column>

                <FormComponent.Column>
                  <SectionTitle title="Участники мероприятия" />

                  <ListEdit
                    rows={jointEvent.participants}
                    onChange={handleParticipantsChange}
                    toolbar={['add', 'edit', 'delete', 'move']}
                    columns={[
                      {
                        label: '',
                        formatValue: x =>
                          formatAuthor({
                            ...x,
                            category: null,
                            name: '',
                            affilateCount: 0,
                            isAffilated: false,
                            position: 0,
                            enterprise: null,
                            memberAffilations: [],
                          }),
                      },
                    ]}
                    maxHeight="400px"
                    defaultRowsCount={20}
                    withoutHead
                    isDeleteConfirmEnabled
                    isDisabled={viewMode}
                    withMessages
                    specification={{
                      mode: 'customComponent',
                      validation: {
                        checkIsValid: row => !!row?.person?.id,
                        onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                      },
                      renderComponent: (row, onChange) => <ParticipantFields participant={row} onChange={onChange} />,
                    }}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab title="Документы">
              <FormComponent.Wrapper>
                <Documents documents={jointEvent.documents} setDocuments={handleDocumentsChange} disabled={!!viewMode} />
              </FormComponent.Wrapper>
            </Tab>
            <Tab title="Журнал сообщений">
              {relatedTableState && (
                <DataGrid
                  specification={GetJointEventFeedlineList(
                    {
                      relatedTableAPIID: 'GetMagazineList',
                      relatedTableState,
                      relatedRecordId: jointEventId || undefined,
                    },
                    viewMode ? 'viewMode' : editMode ? 'editMode' : 'addMode',
                  )}
                />
              )}
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isFeedlineMessagePopupOpen}
        onConfirm={() => {
          onSubmit(true);
        }}
        publicationMessage={feedlineMessage}
        updatePublicationMessage={handleFeedlineMessageChange}
        publicationMessageType={feedlineMessageType}
        setPublicationMessageType={handleFeedlineMessageTypeChange}
        onConfirmWithoutMessage={() => {
          onSubmit(false);
        }}
        command="RefJointEventFeedlineMessage"
      />
    </>
  );
}

export const Component = React.memo(JointEventForm);

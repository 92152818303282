import { Icon } from 'components';

export function PersonalExpositionParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Участие в выставках</strong> предназначен для добавления, редактирования и удаления участия в выставках и
        информации о разработке (проекте/экспозициях).
      </p>

      <h4>В разделе Участие в выставке вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись об участии в выставке и информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новое участие в выставке с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Добавить новую запись о выставке с помощью кнопки <Icon type="add" /> на вкладке «Описание» при создании участия.
        </li>
        <li>
          Редактировать запись об участии в выставке с помощью кнопки <Icon type="edit" /> Кнопка доступна, если вы являетесь
          участником
        </li>
        <li>
          Удалить запись об участии в выставке, которую вы создавали, если она в статусе Добавлена с помощью кнопки{' '}
          <Icon type="remove" /> Кнопка доступна, если вы являетесь участником
        </li>
        <li>
          Выгрузить список участий в выставках в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>Выбрать формат участия и добавить информацию о разработке для выставки на вкладке «Описание»</li>
        <li>
          Добавить награды при редактировании записи об участии или посмотреть награды участников выставки с помощью кнопки{' '}
          <Icon type="award" />
        </li>
        <li>
          Редактировать связь с подразделением с помощью кнопки <Icon type="connection" />
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи об участии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите документ подтверждающий участие. Ответственное лицо проверит и утвердит
        участие.
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои участия»</strong> содержит список ваших участий. Если вы создавали запись об участии в конкурсе, но
          не являетесь участником, запись будет отображаться в разделе «Все участия».
        </li>
        <li>
          <strong>Фильтр «Все участия»</strong> отображает список всех участий в конференциях.
        </li>
        <li>
          <strong>Фильтр «Год»</strong>формирует список участий в конференциях, сроки проведения которых содержат указанный год.
          По умолчанию выбран текущий год.
        </li>
      </ul>

      <div>
        Если у вас есть вопрос или вы нашли ошибку в Утвержденном участий - напишите Администратору <Icon type="question" />
      </div>
    </>
  );
}

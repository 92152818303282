import { useCallback, useState } from 'react';
import { format, parse } from 'date-fns';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { formatStr } from 'utils/Constants';
import { getEnumItem } from 'utils/Helpers';
import { ProjectReportBlock, ProjectReportType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getMockReport } from 'features/Form/looks/project/ProjectForm/helpers';

type Arguments = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

export function useController(args: Arguments) {
  const { project, setProject } = args;

  const { enumMap } = useAppDataContext();

  const [isOpenAddModal, setIsOpenAddModal] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const handleAddReport = useCallback(
    (stageNumber: string, type: Item, isStateRegistration: boolean) => {
      const foundStage = project.stages.find(x => x.number === stageNumber);
      if (!foundStage) {
        return;
      }
      const newReport: Project.Report = {
        ...getMockReport(),
        stage: foundStage,
        type,
        block: getEnumItem(
          'ProjectReportBlock',
          isStateRegistration ? ProjectReportBlock.STATE_REGISTRATION : ProjectReportBlock.STAGE,
          enumMap,
        ),
      };
      setProject(prevState => ({ ...prevState, reports: [...prevState.reports, newReport] }));
    },
    [enumMap, project.stages, setProject],
  );

  const handleLoadFromStagesData = useCallback(
    (report: Project.Report | null) => {
      if (report) {
        const actualStage = project.stages.find(stage => stage.id === report?.stage?.id);
        if (report && actualStage) {
          const updatedReportsList = project.reports.map(r => (r.id === report?.id ? report : r));
          setProject(prevState => ({ ...prevState, reports: updatedReportsList }));
        }
      } else {
        const daysInMs = 1000 * 60 * 60 * 24 * 30;
        const newReports =
          project.stages
            .map<Project.Report>(stage => {
              const rep = project.reports.find(r => r.stage?.id === stage.id);
              if (!rep) {
                return {
                  ...getMockReport(),
                  stage,
                  type: getEnumItem('ProjectReportType', ProjectReportType.INTERMEDIATE, enumMap),
                  name: stage.name || '',
                  number: stage?.number || '',
                  acceptedDate: format(parse(stage.endDate, formatStr, new Date()).getTime() + daysInMs, formatStr),
                };
              }
              return { ...getMockReport(), id: 'skip' };
            })
            .filter(r => r.id !== 'skip') || [];
        const size = project.reports.length + newReports.length;
        const fixedReports = [...project.reports, ...newReports].map<Project.Report>((r, index) => {
          if (index === size - 1) {
            return { ...r, type: getEnumItem('ProjectReportType', ProjectReportType.TOTAL, enumMap) };
          } else {
            return { ...r, type: getEnumItem('ProjectReportType', ProjectReportType.INTERMEDIATE, enumMap) };
          }
        });
        setProject(prevState => ({ ...prevState, reports: [...fixedReports] }));
      }
    },
    [enumMap, project.reports, project.stages, setProject],
  );

  const onPreSubmit = useCallback(
    (row: Project.Report, submit: (submit: Project.Report) => void) => {
      const matchedStageIndex = project.stages.findIndex(stage => stage.number === row?.stage?.number);
      const matchedStage = matchedStageIndex !== -1 ? project.stages[matchedStageIndex] : null;
      if (matchedStage && (!matchedStage.name || !matchedStage.obtainedResults)) {
        setProject(prevState => ({
          ...prevState,
          stages: (() => {
            const newStages = [...prevState.stages];
            if (!newStages[matchedStageIndex].name) newStages[matchedStageIndex].name = row?.stage?.name || '';
            return newStages;
          })(),
        }));
      }
      submit(row);
    },
    [project.stages, setProject],
  );

  return {
    isOpenAddModal,
    setIsOpenAddModal,
    handleAddReport,
    handleLoadFromStagesData,
    onPreSubmit,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}

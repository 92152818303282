import { useState, useMemo, useCallback } from 'react';

import { ButtonProps } from 'components';

const useController = () => {
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const openAddForm = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const closeAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const toolbarButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: openAddForm,
      },
    ],
    [openAddForm],
  );

  return {
    isAddFormOpen,
    closeAddForm,
    toolbarButtons,
  };
};

export default useController;

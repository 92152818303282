import { ListEdit } from 'components';

import { showErrors } from 'utils/Validators';
import { Publication } from 'types/models';
import { getMockPublication } from 'features/Form/looks/nirRequest/NirRequestForm/helpers';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  title: string;
  publications: Publication[];
  setPublications(publications: Publication[]): void;
  disabled?: boolean;
};

export function NotAffiliatedPublications({ publications, setPublications, title, disabled = false }: Props) {
  return (
    <ListEdit
      header={{ title }}
      rows={publications}
      onChange={setPublications}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Библиографическая запись', formatValue: x => x.bibliographicRecord, styles: { width: '50%' } },
        { label: 'DOI', formatValue: x => x.doi ?? '', styles: { width: '10%' } },
        { label: 'Количество цитирований Scopus', formatValue: x => x.scopusCitations ?? '', styles: { width: '10%' } },
        { label: 'Количество цитирований WoS', formatValue: x => x.wosCitations ?? '', styles: { width: '10%' } },
        { label: 'Квартиль Scopus', formatValue: x => x.scopusQuartile ?? '', styles: { width: '10%' } },
        { label: 'Квартиль WoS', formatValue: x => x.wosQuartile ?? '', styles: { width: '10%' } },
      ]}
      maxHeight="none"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'customComponent',
        renderComponent: (publication, setPublication) => (
          <Fields publication={publication || getMockPublication()} setPublication={setPublication} />
        ),
        validation: {
          checkIsValid: publication => validate(publication).every(x => x.isValid),
          onInvalidate: val => showErrors(validate, val),
        },
      }}
    />
  );
}

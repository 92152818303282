import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export type Arguments = {
  isPersonalMode?: boolean;
};

export function GetPersonalAllSecurityDocumentList({ isPersonalMode = false }: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalAllSecurityDocumentList',
    header: {
      firstLevel: {
        title: isPersonalMode
          ? 'Список объектов интеллектуальной собственности'
          : 'Объекты интеллектуальной собственности университета',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ isPersonalMode }),
    isWithQna: true,
  };
}

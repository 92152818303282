import React from 'react';

import { FormComponent, Select, SelectMode } from 'components';

import { JointEvent } from 'types/models';
import { useController } from './controller';

type Props = {
  document: JointEvent.Enterprise | null;
  onChange(row: JointEvent.Enterprise): void;
};

export function EnterpriseForm({ document, onChange }: Props) {
  const { handleChangeRole, handleChangeOrganization } = useController({ document, onChange });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Роль">
          <Select
            mode={SelectMode.ENUM}
            value={document?.role}
            settings={{ name: 'EventEnterpriseRole' }}
            onChange={handleChangeRole}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Организация">
          <Select
            mode={SelectMode.REFERENCE}
            value={document?.element}
            onChange={handleChangeOrganization}
            settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"', isClearable: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { FormComponent, ListEdit, SectionTitle } from 'components';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { GetReferenceElementList } from 'features/Table/specifications';

import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';
import { TextFields } from './TextFields';
import { ScienceSection } from './ScienceSection';

type Props = {
  disabled: boolean;
  project: Project.Project;
  setProject(project: Project.Project): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function DescriptionProject(props: Props) {
  const { project, makeChangeHandler, setProject, disabled } = props;

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <TextFields project={project} makeChangeHandler={makeChangeHandler} setProject={setProject} disabled={disabled} />

          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'Приоритетные направления развития науки, технологий и техники',
                info: (
                  <p>
                    Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов,
                    1-Мониторинг 2023, 2-Наука 2023, РЕПНИД.
                  </p>
                ),
              }}
              rows={project?.pnis ?? []}
              onChange={makeChangeHandler('pnis')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
              refName="RefPni"
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'Приоритеты Стратегии НТР РФ',
                info: <p>Обязательный параметр для построения отчетов: 1-инжиниринг, 2-Наука 2023.</p>,
              }}
              rows={project?.ntrStrategies ?? []}
              onChange={makeChangeHandler('ntrStrategies')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
              refName="RefNtrStrategy"
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'Критические технологии',
                info: (
                  <p>
                    Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов, 2-Наука
                    2023.
                  </p>
                ),
              }}
              rows={project?.criticalTechnologies ?? []}
              onChange={makeChangeHandler('criticalTechnologies')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "Критические технологии"'
              refName="RefPriorityTechnology"
              isDisabled={disabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'ГРНТИ',
                info: <p>Обязательный параметр для построения отчетов: 1-Мониторинг 2023, 2-Наука 2023, РЕПНИД, 1-инжиниринг.</p>,
              }}
              rows={project?.grntis ?? []}
              onChange={makeChangeHandler('grntis')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "ГРНТИ"'
              refName="RefGrnti"
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'УДК',
                info: (
                  <p>
                    Обязательный параметр для построения отчетов: Сведения для регистрационной карты проекта и отчета НИОКТР (для
                    ЕГИСУ)
                  </p>
                ),
              }}
              rows={project?.udks ?? []}
              onChange={makeChangeHandler('udks')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "УДК"'
              refName="RefUdk"
              isDisabled={disabled}
            />
          </FormComponent.Line>

          <SectionTitle title="2-Наука" />

          <ScienceSection disabled={disabled} makeChangeHandler={makeChangeHandler} project={project} />

          <FormComponent.Line>
            <RefElementList
              header={{
                title: 'Классификатор ОЭСР (ОЕСD)',
                info: <p>Обязательный параметр для построения отчетов: 1-Мониторинг 2023, 2-Наука 2023, 1-инжиниринг.</p>,
              }}
              rows={project?.oecds ?? []}
              onChange={makeChangeHandler('oecds')}
              toolbar={['add', 'delete', 'move']}
              modalTitle='Справочник "Коды международной классификации отраслей наук"'
              refName="RefOecd"
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                header={{ title: 'Работа выполняется в рамках' }}
                rows={project?.workSpaces || []}
                onChange={makeChangeHandler('workSpaces')}
                toolbar={['add', 'edit', 'delete']}
                columns={[
                  {
                    label: 'Наименование',
                    formatValue: el =>
                      [(el?.element?.customFields?.category as string) || '', el?.element?.label || '']
                        .filter(Boolean)
                        .join(': '),
                  },
                ]}
                isDisabled={disabled}
                isDeleteConfirmEnabled
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: 'Атрибуты проекта для регистрации в ЕГИСУ',
                  modalTableRowConverter: row => {
                    return {
                      id: null,
                      element: {
                        label: row.label,
                        id: row.id,
                        customFields: { category: row.category },
                      },
                      isActual: false,
                      isProject: false,
                      createdBy: '',
                      createdDate: '',
                      position: '',
                    };
                  },
                  modalTableSpecification: GetReferenceElementList({
                    requestData: {
                      filters: [],
                      name: 'RefProjectWorkSpace',
                    },
                  }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

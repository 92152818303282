import React, { useCallback, useMemo } from 'react';

import { ListEdit, Modal } from 'components';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { Project } from 'types/models';
import { showNotification } from 'features/Notifications';
import { ProjectReportBlock } from 'utils/Enums';

import { getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
// eslint-disable-next-line max-len
import { ProjectStateRegistrationReportGridHelp } from 'features/Table/specifications/GetProjectStateRegistrationReportList/LeftPanelForThirdLevel/help';
import { ProjectReportFields } from 'features/Form/looks/projectReport/ProjectReportForm/Fields';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

import { AddModal } from './AddModal';
import { useController } from './controller';

type Props = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  visibleBlock?: ProjectReportBlock;
  isFieldsHidden?: boolean;
  withMessages?: boolean;
  disabled: boolean;
};

export function StateReports(props: Props) {
  const { project, isFieldsHidden, setProject, visibleBlock, withMessages = true, disabled } = props;

  const {
    handleAddReport,
    isOpenAddModal,
    setIsOpenAddModal,
    handleLoadFromStagesData,
    onPreSubmit,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    project,
    setProject,
  });

  const token = getAuthToken();

  const isStagesEqualToReports = useMemo(() => project.reports.length === project.stages.length, [
    project.stages.length,
    project.reports.length,
  ]);

  const title = useMemo(() => {
    let blockLabel = '';
    if (visibleBlock === ProjectReportBlock.STATE_REGISTRATION) {
      blockLabel = 'Отчёты для регистрации в ЦИТиС';
    } else if (visibleBlock === ProjectReportBlock.STAGE) {
      blockLabel = 'Список других отчётов по этапам проекта';
    } else {
      blockLabel = 'Отчёты';
    }
    return isFieldsHidden ? blockLabel + '. Зарегистрированные отчёты доступны только для просмотра' : blockLabel;
  }, [isFieldsHidden, visibleBlock]);

  const getIsEditDisabled = useCallback(
    (row: Project.Report | null) => {
      if (!!row && isFieldsHidden) {
        if (row.block?.value === ProjectReportBlock.STATE_REGISTRATION) {
          return !!row.number || !!row.archiveNumber || !!row.archiveSendDate;
        }
        if (row.block?.value === ProjectReportBlock.STAGE) {
          return !!row.archiveNumber || !!row.archiveSendDate;
        }
      }
      return false;
    },
    [isFieldsHidden],
  );

  const rows = useMemo(() => {
    if (visibleBlock === ProjectReportBlock.STATE_REGISTRATION) {
      return project.reports.filter(x => x.block?.value === ProjectReportBlock.STATE_REGISTRATION);
    }
    if (visibleBlock === ProjectReportBlock.STAGE) {
      return project.reports.filter(x => x.block?.value === ProjectReportBlock.STAGE);
    }
    return project.reports;
  }, [project.reports, visibleBlock]);

  const onChange = useCallback(
    (e: Project.Report[]) => {
      if (visibleBlock === ProjectReportBlock.STATE_REGISTRATION)
        setProject(prev => ({
          ...prev,
          reports: [...e, ...prev.reports.filter(x => x.block?.value === ProjectReportBlock.STAGE)],
        }));
      if (visibleBlock === ProjectReportBlock.STAGE)
        setProject(prev => ({
          ...prev,
          reports: [...prev.reports.filter(x => x.block?.value === ProjectReportBlock.STATE_REGISTRATION), ...e],
        }));
      setProject(prev => ({ ...prev, reports: e }));
    },
    [setProject, visibleBlock],
  );

  return (
    <>
      <ListEdit
        header={{ title }}
        rows={rows}
        onChange={onChange}
        toolbar={[
          {
            icon: { type: 'question' },
            title: 'Помощь',
            onClick: () => setIsHelpModalOpen(true),
          },
          'view',
          { key: 'edit', isDisabled: getIsEditDisabled },
          { key: 'delete', isDisabled: getIsEditDisabled },
          {
            icon: { type: 'add' },
            title: 'Добавить',
            onClick: () => setIsOpenAddModal(true),
            isDisabled: disabled || isStagesEqualToReports,
          },
          {
            icon: { type: 'refresh' },
            title: 'Заполнить из Календарного плана',
            onClick: report => handleLoadFromStagesData(report),
            isDisabled: disabled,
          },
          {
            icon: { type: 'download' },
            title: 'Скачать файл отчёта',
            onClick: report => downloadFile(report?.file?.id, token),
            isDisabled: report => !report?.file || disabled,
          },
        ]}
        columns={[
          {
            label: 'Госрегистрация',
            formatValue: x => x.block?.value === ProjectReportBlock.STATE_REGISTRATION,
            styles: { width: '5%' },
            defaultSort: 'desc',
            dataKind: 'boolean',
          },
          { label: 'Этап', formatValue: x => getStageTitle(x.stage), styles: { width: '15%' } },
          { label: 'Название отчёта', formatValue: x => x.name, styles: { width: '25%' } },
          { label: 'Ключевые слова', formatValue: x => x.keyWords, styles: { width: '15%' } },
          { label: 'Инв. номер в архиве', formatValue: x => x.archiveNumber, styles: { width: '10%' } },
          { label: 'Номер отчёта (ИК)', formatValue: x => x.number, styles: { width: '10%' } },
          { label: 'Отчёт отправлен', formatValue: x => x.sendDate, styles: { width: '5%' } },
          { label: 'Поставлен на учёт', formatValue: x => x.acceptedDate, styles: { width: '5%' } },
          {
            label: 'Файл',
            formatValue: x => x.file?.name || '',
            styles: { width: '10%' },
          },
        ]}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={10}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Отчёт по этапу проекта',
          isFixedHeightModal: true,
          renderComponent: (report, setReport, _, mode) => {
            return (
              <ProjectReportFields
                project={project}
                setProject={setProject}
                report={report}
                setReport={setReport}
                disabled={mode === 'view'}
              />
            );
          },
          onPreSubmit: (row, submit) => onPreSubmit(row, submit),
          validation: {
            checkIsValid: row => !row?.stage || (!!row.stage?.name && !!row.stage.annotation && !!row.stage.obtainedResults),
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />

      <AddModal
        isOpen={isOpenAddModal}
        project={project}
        onSubmit={handleAddReport}
        onClose={() => setIsOpenAddModal(false)}
        visibleBlock={visibleBlock}
      />

      <Modal
        mode="help"
        title="Особенности заполнения на вкладке: Отчеты"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        size="large"
      >
        {isFieldsHidden ? ProjectHelp().planReports : ProjectStateRegistrationReportGridHelp()}
      </Modal>
    </>
  );
}

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  programId?: string;
  isClearToolbar?: boolean;
  hasSelectButton?: boolean;
};

export function GetProgramEventList({ programId, isClearToolbar, hasSelectButton }: Props): T.Specification<State> {
  const requestData = { programId };
  return {
    apiID: 'GetProgramEventList',
    requestData,
    header: {
      firstLevel: {
        title: 'Мероприятия программы (виды конкурсов)',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: isClearToolbar ? undefined : LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({ programId }),
  };
}

import React, { useMemo } from 'react';

import { ListEdit, SectionTitle } from 'components';

import { CitationSystem } from 'types/models';
import { showErrors } from 'utils/Validators';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { Fields } from './Fields';
import { getMockCitationSystem } from 'features/EditPartner/helpers';
import { validate } from './validate';

type Props = {
  citationSystems: CitationSystem[];
  setCitationSystems(citationSystems: CitationSystem[]): void;
  disabled: boolean;
};

export function CitationSystems(props: Props) {
  const { citationSystems, setCitationSystems, disabled } = props;

  const extraToolbarButtons = useMemo<ExtraToolbarButton<CitationSystem>[]>(
    () => [
      {
        icon: { type: 'link' },
        title: 'Перейти по ссылке',
        onClick: identifier => {
          if (identifier) window.open(`${identifier.url}`, '_blank');
        },
        isDisabled: doc => doc === null || !doc.url,
      },
    ],
    [],
  );

  return (
    <>
      <SectionTitle title="Идентификаторы организации в базах цитирования" />

      <ListEdit
        rows={citationSystems}
        onChange={setCitationSystems}
        toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
        columns={[
          { label: 'База цитирования', formatValue: x => x.refCitationSystem?.label || '' },
          { label: 'Идентификатор', formatValue: x => x.identifier },
          { label: 'Название', formatValue: x => x.name },
          { label: 'Ссылка', formatValue: x => x.url },
          { label: 'Создано', formatValue: x => x.createdBy },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={6}
        maxHeight="350px"
        specification={{
          mode: 'customComponent',
          renderComponent: (citationSystem, setCitationSystem) => (
            <Fields citationSystem={citationSystem || getMockCitationSystem()} setCitationSystem={setCitationSystem} />
          ),
          validation: {
            checkIsValid: citationSystem => validate(citationSystem).every(x => x.isValid),
            onInvalidate: val => showErrors(validate, val),
          },
        }}
      />
    </>
  );
}

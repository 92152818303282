import React from 'react';

import {
  FormComponent,
  ListEdit,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { ValueOf } from 'types/helpers';

import { useController } from './controller';
import { SelectPerson } from 'features/SelectPerson';
import { PurchaseRequest } from 'types/models/PurchaseRequest';
import { showNotification } from 'features/Notifications';
import { UnitFields } from './UnitFields';
import { PurchaseRequestStatus } from 'utils/Enums';
import { Item } from 'types/models/common';
import { getMockUnit } from '../../helpers/getMockModels';
import { isNumber } from 'utils/Validators';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  disabled: boolean;
  purchaseRequest: PurchaseRequest;
  makeChangeHandler: (key: keyof PurchaseRequest) => (val: ValueOf<PurchaseRequest>) => void;
  setPurchaseRequest: React.Dispatch<React.SetStateAction<PurchaseRequest>>;
  isLess100Products: boolean;
  isLess100Services: boolean;
  isMore100: boolean;
  isFinanceResponsibleRequired: boolean;
};

export function AboutPurchaseRequest(props: Props) {
  const {
    disabled,
    purchaseRequest,
    makeChangeHandler,
    setPurchaseRequest,
    isLess100Products,
    isLess100Services,
    isMore100,
    isFinanceResponsibleRequired,
  } = props;

  const {
    defaultUnit,
    projectPerformerOptions,
    handleApplicantChange,
    purchaseRequestNumberTooltipTxt,
    purchaseRequestSendDateTooltipTxt,
  } = useController({ makeChangeHandler, purchaseRequest });

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Вид договора">
              {purchaseRequest?.contractKind ? purchaseRequest?.contractKind.label : ''}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Источник финансирования (средства проекта)">
              {purchaseRequest?.project ? `${purchaseRequest?.project.number}, ${purchaseRequest?.project.name}` : ''}
            </FormComponent.Field>
          </FormComponent.Line>
          {!!purchaseRequest?.status?.value && purchaseRequest?.status?.value !== PurchaseRequestStatus.DRAFT && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Номер заявки на закупку" tooltip={purchaseRequestNumberTooltipTxt}>
                  <TextInput value={purchaseRequest?.number || ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
                </FormComponent.Field>
                <FormComponent.Field label="Дата регистрации" tooltip={purchaseRequestSendDateTooltipTxt}>
                  <>{purchaseRequest?.sendDate}</>
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          <FormComponent.Line>
            <FormComponent.Field
              label={isLess100Services ? 'Предмет договора' : 'Наименование закупки'}
              isRequired
              // eslint-disable-next-line max-len
              tooltip={`Краткое наименование заявки, как она будет отображаться в системе. Например: Реагенты для спектрофотометра, Просвечивающий электронный микроскоп. ${
                isMore100
                  ? ''
                  : 'Более полные формулировки/спецификации указываются ниже в таблице "Список товаров (работ, услуг)"'
              }
              `}
            >
              <TextArea
                value={purchaseRequest?.name || ''}
                onChange={makeChangeHandler('name')}
                isDisabled={disabled}
                settings={{ rows: 3 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {isLess100Products && (
            <FormComponent.Line>
              <ListEdit
                header={{ title: 'Список товаров' }}
                rows={purchaseRequest.units}
                onChange={e => setPurchaseRequest(prev => ({ ...prev, units: e }))}
                toolbar={['add', 'edit', 'delete', 'move']}
                columns={[
                  { label: 'Тип', formatValue: x => x.type?.label ?? '' },
                  { label: 'Наименование', formatValue: x => x.name ?? '' },
                  { label: 'Количество', formatValue: x => x.quantity ?? '' },
                  { label: 'Единица измерения', formatValue: x => x.unit?.label ?? '' },
                  {
                    label: 'Ориентировочная цена за ед.',
                    formatValue: x => (isNumber(x.perUnitPrice) ? x.perUnitPrice : ''),
                  },
                  {
                    label: 'Итого',
                    formatValue: x => (isNumber(x.perUnitPrice) ? String(Number(x.perUnitPrice) * Number(x.quantity || 0)) : ''),
                  },
                ]}
                defaultRowsCount={5}
                maxHeight="50vh"
                columnIndexesForSumTotal={purchaseRequest.units.every(x => isNumber(x.perUnitPrice)) ? [5] : []}
                specification={{
                  mode: 'customModalComponent',
                  modalTitle: 'Список товаров',
                  renderComponent: (unit, setUnit) => (
                    <UnitFields
                      unit={unit || getMockUnit(defaultUnit)}
                      setUnit={setUnit}
                      isLess100Products={isLess100Products}
                      isLess100Services={isLess100Services}
                      isMore100={isMore100}
                    />
                  ),
                  validation: {
                    checkIsValid: unit => !!unit?.type?.value && !!unit.name && (!isLess100Products || isNumber(unit?.quantity)),
                    onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                  },
                }}
              />
            </FormComponent.Line>
          )}
          {isMore100 && (
            <>
              <UnitFields
                unit={purchaseRequest.units[0] || getMockUnit(defaultUnit)}
                setUnit={e => setPurchaseRequest(prev => ({ ...prev, units: e ? [e] : [] }))}
                isLess100Products={isLess100Products}
                isLess100Services={isLess100Services}
                isMore100={isMore100}
              />
              <FormComponent.Line>
                <FormComponent.Field label="Условия оплаты" isRequired>
                  <Select
                    mode={SelectMode.ENUM}
                    settings={{ name: 'PurchaseRequestPaymentTerm' }}
                    value={purchaseRequest.paymentTerm}
                    onChange={(e: Item | null) => setPurchaseRequest(prev => ({ ...prev, paymentTerm: e }))}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Предполагаемый способ закупки" isRequired>
                  <Select
                    mode={SelectMode.ENUM}
                    settings={{ name: 'PurchaseRequestPurchaseMethod' }}
                    value={purchaseRequest.purchaseMethod}
                    onChange={(e: Item | null) => setPurchaseRequest(prev => ({ ...prev, purchaseMethod: e }))}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          {!isMore100 && (
            <FormComponent.Line>
              <FormComponent.Field
                label={`Предельная сумма закупки${isLess100Products ? ' (опционально)' : ''} `}
                isRequired={isLess100Services}
                tooltip={isLess100Products ? 'Максимальная сумма с учетом изменения цены у поставщика' : ''}
              >
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={purchaseRequest?.limitPrice}
                  onChange={makeChangeHandler('limitPrice')}
                  isDisabled={disabled}
                  placeholder="0.00"
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <FormComponent.Field
              label={
                isLess100Services ? 'Срок оказания услуг' : isMore100 ? 'Срок поставки/исполнения договора' : 'Срок поставки'
              }
              isRequired
            >
              <TextDateTime
                value={purchaseRequest?.deliveryDate || null}
                onChange={makeChangeHandler('deliveryDate')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                mode={TextAreaMode.WYSIWYG}
                settings={{ rows: 5 }}
                value={purchaseRequest?.note || ''}
                onChange={makeChangeHandler('note')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Руководитель проекта" isRequired>
              <Select
                value={projectPerformerOptions.find(x => x.value === purchaseRequest?.applicant?.id)}
                options={projectPerformerOptions}
                onChange={handleApplicantChange}
                isDisabled={disabled}
              />
              {getPersonContacts(purchaseRequest?.applicant?.person?.contacts)}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ответственное лицо по заявке" isRequired>
              <SelectPerson
                member={purchaseRequest?.requestResponsible || null}
                memberLabel={purchaseRequest?.requestResponsible?.person?.fullName}
                onUpdateMember={makeChangeHandler('requestResponsible')}
                withHistory={false}
                disabled={disabled}
              />
              {getPersonContacts(purchaseRequest?.requestResponsible?.person?.contacts)}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Материально-ответственное лицо" isRequired={isFinanceResponsibleRequired}>
              <SelectPerson
                member={purchaseRequest?.financeResponsible || null}
                personId={purchaseRequest?.financeResponsible?.person?.id ?? null}
                onUpdateMember={makeChangeHandler('financeResponsible')}
                withHistory={false}
                disabled={disabled}
              />
              {getPersonContacts(purchaseRequest?.financeResponsible?.person?.contacts)}
            </FormComponent.Field>
          </FormComponent.Line>
          {/* <FormComponent.Line> // Временно закоментировано в рамках задачи 1887
            <FormComponent.Field label="Подразделение" tooltip={purchaseRequestDepartmentTxt}>
              <Select
                mode={SelectMode.REFERENCE}
                value={purchaseRequest?.department}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('department')(option)}
                settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"', isClearable: true }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line> */}
          <FormComponent.Line>
            <FormComponent.Field label="Адрес поставки (оказания услуг)" isRequired>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={purchaseRequest?.deliveryAddress || ''}
                onChange={makeChangeHandler('deliveryAddress')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

import React from 'react';

import { Tabs, Tab } from 'components';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ProjectReportBlock } from 'utils/Enums';

import { StateForm } from './StateForm';
import { StateReports } from './StateReports';

type Props = {
  disabled: boolean;
  project: Project.Project;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
};

export function StateRegistration(props: Props) {
  const { disabled, project, makeChangeHandler, setProject } = props;

  return (
    <>
      <Tabs>
        <Tab title="Регистрация проекта">
          <StateForm disabled={disabled} project={project} makeChangeHandler={makeChangeHandler} />
        </Tab>
        <Tab title="Регистрация отчетов">
          <StateReports
            project={project}
            setProject={setProject}
            visibleBlock={ProjectReportBlock.STATE_REGISTRATION}
            withMessages={false}
            disabled={disabled}
          />
        </Tab>
      </Tabs>
    </>
  );
}

import { useState, useCallback, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { Table } from 'types/models';
import { ChildField, Field, FieldValue, PublicationCitationSystemIndex } from 'types/models/CitationSystemIndex';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Mode, State } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/makeUseCustomController';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';
import { SavedValue } from 'services/BackendAPI/configurations/types';
import { getFieldsFromScientificIndex } from './helpers';
import { isInitialValidate, validate } from './validate';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
  customState: State;
  mode: Mode;
  relatedTableState?: Table.State;
  setTitle: React.Dispatch<string | JSX.Element> | undefined;
};

export function useController({ onClose, customState, mode, relatedTableState, setTitle }: Props) {
  const { citationSystems } = customState;

  const tableStreams = useLocalTableStreams();
  const { scientificIndexes, enumMap } = useAppDataContext();

  const getMock = useCallback(
    () => ({
      publicationCitationSystemIndex: '',
      citationSystem: null,
      scientificIndex: null,
      values: {},
      childs: [],
    }),
    [],
  );

  const resetState = useCallback(() => {
    const { publicationCitationSystemIndex, ...resetted } = getMock();
    return resetted;
  }, [getMock]);

  const reloadTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const [citationSystemIndex, setCitationSystemIndex] = useState<PublicationCitationSystemIndex>(getMock());

  const { methods: getCitationSystemIndex } = BackendAPI.useBackendAPI('GetPublicationCitationSystemIndex', {
    onSuccessfullCall: ({ data: { scientificIndex, publicationCitationSystemIndex: index, savedValues, savedChilds } }) => {
      function provideSavedValuesInFields(savedVals: SavedValue[], fields: Record<string, Field>): Record<string, Field> {
        Object.keys(fields).forEach((fieldIndex: string) => {
          const field: Field = fields[fieldIndex];

          const savedValue = savedVals.find(val => val.valueId === field.valueId);

          if (savedValue) {
            fields[fieldIndex] = {
              ...field,
              savedValueId: savedValue.savedValueId ?? null,
              value: savedValue.value ?? '',
            };
          }
        });

        return fields;
      }

      function getFieldsFromServerChilds() {
        return savedChilds.map<ChildField>(x => ({
          code: x.scientificIndex.code,
          label: x.scientificIndex.label,
          savedChildId: x.savedChildId,
          scientificIndexId: x.scientificIndex.id,
          fields: provideSavedValuesInFields(x.savedValues, getFieldsFromScientificIndex(x.scientificIndex).parentFields),
        }));
      }

      setCitationSystemIndex({
        publicationCitationSystemIndex: index,
        citationSystem: citationSystems.find(x => x.id === scientificIndex.citationSystem.id) ?? null,
        scientificIndex: scientificIndex,
        values: provideSavedValuesInFields(savedValues, getFieldsFromScientificIndex(scientificIndex).parentFields),
        childs: getFieldsFromServerChilds(),
      });
    },
  });

  const { methods: saveCitationSystemIndex } = BackendAPI.useBackendAPI('SavePublicationCitationSystemIndex', {
    onSuccessfullCall: () => {
      reloadTable();

      showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });

      onClose();
    },
  });

  const saveForm = useCallback(() => {
    if (
      !validate(citationSystemIndex.values) ||
      !isInitialValidate({
        citationSystem: citationSystemIndex.citationSystem,
        selectedScientificIndex: citationSystemIndex.scientificIndex,
      })
    ) {
      return;
    }

    saveCitationSystemIndex.callAPI({
      publicationCitationSystemIndex: citationSystemIndex.publicationCitationSystemIndex,
      publicationCitationSystemId: citationSystemIndex.citationSystem?.publicationCitationSystemId || '',
      scientificIndexId: citationSystemIndex.scientificIndex?.id || '',
      values: Object.values(citationSystemIndex.values),
      childs: citationSystemIndex.childs,
    });

    onClose();
  }, [citationSystemIndex, onClose, saveCitationSystemIndex]);

  useEffect(() => {
    const tableStateRow = relatedTableState?.selectedRows[0];

    const actions: Record<Mode, string> = {
      add: 'Создание',
      edit: 'Редактирование',
      view: 'Просмотр',
    };

    setTitle?.(
      getStatusMessage(
        [actions[mode], 'записи "Показатели"'].filter(Boolean).join(' '),
        mode === 'add' ? '' : tableStateRow?.id || '',
      ),
    );

    if (mode !== 'add' && tableStateRow) {
      getCitationSystemIndex.callAPI({
        citationSystemIndexId: tableStateRow.id,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCitationSystem = useCallback(
    (value: ReferenceItem) => {
      setCitationSystemIndex(prevState => ({
        ...prevState,
        ...resetState(),
        citationSystem: citationSystems.find(x => x.id === value.id) ?? null,
      }));
    },
    [citationSystems, resetState],
  );

  const handleChangeScientificIndex = useCallback(
    (value: Item | null) => {
      const scientificIndex = scientificIndexes.find(x => x.id === value?.value) ?? null;

      setCitationSystemIndex(prevState => ({
        ...prevState,
        ...resetState(),
        citationSystem: citationSystemIndex.citationSystem,
        scientificIndex,
        ...(value && scientificIndex ? { values: getFieldsFromScientificIndex(scientificIndex).parentFields } : {}),
      }));
    },
    [citationSystemIndex.citationSystem, resetState, scientificIndexes],
  );

  const handleChangeField = useCallback((fieldId: string, value: FieldValue) => {
    setCitationSystemIndex(prevState => ({
      ...prevState,
      values: { ...prevState.values, [fieldId]: { ...prevState.values[fieldId], value } },
    }));
  }, []);

  const handleChangeChilds = useCallback((childs: ChildField[]) => {
    setCitationSystemIndex(prevState => ({
      ...prevState,
      childs,
    }));
  }, []);

  return {
    enumMap,
    saveForm,
    citationSystemIndex,
    scientificIndexes,
    handleChangeCitationSystem,
    handleChangeScientificIndex,
    handleChangeField,
    handleChangeChilds,
  };
}

import { useMemo } from 'react';

import { Column, Setting, UseCustomControllerProps } from 'types/models/Table';
import { CrudCallbackArguments } from 'features/Table/ThirdLevelHeader/TemplatesPanel/types';

export type State = {
  selectedRow: Record<string, string> | undefined;
  currentSetting: Setting | null;
  gridName: string;
  columns: Column[];
  crudCallback: ({ operation, name, author }: CrudCallbackArguments) => Promise<void>;
};

export type Props = Omit<State, 'selectedRow'>;

export function makeUseCustomController({ currentSetting, gridName, columns, crudCallback }: Props) {
  return function useCustomController({ selectedRows }: UseCustomControllerProps): State {
    const selectedRow = useMemo(() => {
      if (selectedRows.length > 0) {
        return selectedRows[0];
      }
    }, [selectedRows]);

    return {
      selectedRow,
      gridName,
      columns,
      currentSetting,
      crudCallback,
    };
  };
}

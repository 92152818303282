import React, { useCallback, useMemo, useState } from 'react';

import {
  ButtonMode,
  ButtonProps,
  FormComponent,
  Modal,
  RelationTableModal,
  TextArea,
  Toolbar as SharedToolbar,
} from 'components';

import { ReferenceItem, Table } from 'types/models';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { AuthorPaymentRequestStatus, PaymentRequestType } from 'utils/Enums';
import { State, Term } from '../model';
import { SelectInstanceModal } from './SelectInstanceModal';
import { TermPresetModal } from './TermPresetModal';
import { checkPaymentByStatus, mapTitle } from '../helpers';
import { usePermissions } from './usePermissions';
import { getEnum } from 'utils/Helpers';
import { Color } from 'constants/colors';
import { IncentivePaymentsGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Component(props: Props) {
  const { tableState, customState } = props;

  const { enumMap } = useAppDataContext();

  const {
    openSelectInstanceModal,
    closeSelectInstanceModal,
    isOpenSelectInstanceModal,
    instanceValue,
    setInstanceValue,
    loadAuthorPayment,
    setMode,
    comment,
    setComment,
    isOpenCommentForm,
    closeCommentForm,
    changeStatus,
    openCommentForm,
    deletePayment,
    isOpenTermPresetModal,
    setIsOpenTermPresetModal,
    changeTerm,
    termPreset,
    event,
    isReportOpen,
    onReportClose,
    currentReport,
    getReports,
    handleSetCurrentReport,
  } = customState;

  const [selectedStatus, setSelectedStatus] = useState<AuthorPaymentRequestStatus | null>(null);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenMessageLogModal, setIsOpenMessageLogModal] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const [row] = tableState.selectedRows;

  const status = row ? (row['id:status'] as AuthorPaymentRequestStatus) : null;

  const changeStatusTo = useCallback(
    (newStatus: AuthorPaymentRequestStatus) => {
      setSelectedStatus(newStatus);
      if (checkPaymentByStatus(row?.factNomineesMoney ?? null, newStatus)) {
        setIsOpenConfirmModal(true);
      } else {
        openCommentForm();
      }
    },
    [row, openCommentForm, setIsOpenConfirmModal],
  );

  const { settings, userSystemDepartment } = useAppDataContext();

  const defaultRequestType = useMemo(() => {
    const enumValue = getEnum('PaymentRequestType', enumMap).find(({ value }) => value === event);
    if (enumValue) {
      return enumValue;
    }
  }, [enumMap, event]);

  const defaultRequestTypeValue = useMemo(() => {
    if (defaultRequestType) {
      return (defaultRequestType.ordinal || 0).toString();
    }

    return '-1';
  }, [defaultRequestType]);

  const defaultRequestTypeLabel = useMemo(() => {
    if (defaultRequestType) {
      return defaultRequestType.label;
    }

    return '-1';
  }, [defaultRequestType]);

  const permissions = status && usePermissions(event, status);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          setMode('view');
          loadAuthorPayment({ id: row?.id, label: row?.name }, 'view');
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: openSelectInstanceModal,
        permission: {
          name: permissions?.addRequest,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          loadAuthorPayment({ id: row?.id, label: row?.name }, 'edit');
        },
        permission: {
          name: permissions?.editablePermissionName,
        },
        isDisabled: tableState.selectedRows.length !== 1 || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsOpenConfirmDeleteModal(true),
        permission: {
          name: permissions?.deletablePermissionName,
        },
        isDisabled: tableState.selectedRows.length !== 1 || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT),
        permission: {
          name: permissions?.toAgreement,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.AGREEMENT ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
      },
      {
        icon: { type: 'like' },
        title: 'Передать на оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
        permission: {
          name: permissions?.toPaid,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION),
        permission: {
          name: permissions?.toRevision,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAID ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Одобрить оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED),
        permission: {
          name: permissions?.toPayment,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.AGREEMENT ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
      },
      {
        icon: { type: 'clock' },
        title: 'Отложить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
        permission: {
          name: permissions?.toPostpone,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.POSTPONE ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
        permission: {
          name: permissions?.toRejected,
        },
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === AuthorPaymentRequestStatus.REJECTED ||
          status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ||
          status === AuthorPaymentRequestStatus.DRAFT ||
          status === AuthorPaymentRequestStatus.REVISION,
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: () => setIsOpenMessageLogModal(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      tableState.selectedRows.length,
      openSelectInstanceModal,
      permissions?.addRequest,
      permissions?.editablePermissionName,
      permissions?.deletablePermissionName,
      permissions?.toAgreement,
      permissions?.toPaid,
      permissions?.toRevision,
      permissions?.toPayment,
      permissions?.toPostpone,
      permissions?.toRejected,
      status,
      getReports,
      handleSetCurrentReport,
      setMode,
      loadAuthorPayment,
      row?.id,
      row?.name,
      changeStatusTo,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title={IncentivePaymentsGridHelp(event as PaymentRequestType).title}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {IncentivePaymentsGridHelp(event as PaymentRequestType).body}
      </Modal>

      <SelectInstanceModal
        event={event}
        isOpen={isOpenSelectInstanceModal}
        value={instanceValue}
        onClose={closeSelectInstanceModal}
        onChange={setInstanceValue}
        onSelect={(val: ReferenceItem) => loadAuthorPayment(val, 'add')}
      />

      <Modal
        title={(mapTitle as any)[String(selectedStatus)]}
        isOpen={!!isOpenCommentForm}
        onClose={() => closeCommentForm()}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              closeCommentForm();
              if (row && selectedStatus) {
                changeStatus(row?.id, selectedStatus);
                setSelectedStatus(null);
              }
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => closeCommentForm(),
          },
        ]}
        size="large"
      >
        <FormComponent.Field label="Комментарий">
          <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
        </FormComponent.Field>
      </Modal>

      <Modal
        mode="warning"
        title="Изменение статуса заявки"
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmModal(false);
              openCommentForm();
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmModal(false),
          },
        ]}
        size="small"
      >
        <>{checkPaymentByStatus(row?.factNomineesMoney ?? null, selectedStatus)}</>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmDeleteModal(false);
              if (row) {
                deletePayment(row.id);
              }
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDeleteModal(false),
          },
        ]}
        size="small"
      >
        <>
          (дата: {row?.month?.length === 1 ? '0' : ''}
          {row?.month}.{row?.requestYear}, ID публикации: {row?.sourceId}, тип статьи: {row?.articleType}) Удалить заявку?
        </>
      </Modal>

      <RelationTableModal
        specification={GetAuthorPaymentRequestFeedlineList({ paymentId: row?.id ?? '', paymentStatus: status! })}
        modalTitle="Журнал сообщений"
        isOpen={isOpenMessageLogModal}
        onClose={() => setIsOpenMessageLogModal(false)}
      />

      <TermPresetModal
        isOpen={isOpenTermPresetModal}
        onClose={() => setIsOpenTermPresetModal(false)}
        onSubmit={(term: Term) => changeTerm(row.id, term)}
        termPreset={termPreset}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        systemDepartMentLabel={`${userSystemDepartment?.label}` || ''}
        values={{
          requestType: defaultRequestTypeValue,
          requestTypeLabel: defaultRequestTypeLabel,
          userDepartment: settings?.userDepartment,
        }}
      />
    </>
  );
}

export const Toolbar = React.memo(Component);

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { useStreamsByApiID } from 'StreamRx';

import { showNotification } from 'features/Notifications';
import { usePrevious } from 'shared/react/usePrevious';
import { ValueOf } from 'types/helpers';
import { NirRequest } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { streams as globalStreams } from 'features/Table/streams';
import { getMockNirRequest, getPreparedNir } from './helpers';
import { validate } from './validate';
import { useLocalTableStreams } from 'features/Table/hooks';
import { getMockTender, getMockLot } from 'features/Form/looks/tender/TenderForm/helpers';
import { NirRequestStatus } from 'utils/Enums';
import { useFormContext } from 'features/Form/hooks';
import { NirRequestFormLook } from 'types/models/Form';

type Arguments = {
  onClose(): void;
};

export function useController({ onClose }: Arguments) {
  const {
    look: { id: initialId, nirRequest: externalNirRequest },
  } = useFormContext<NirRequestFormLook>();
  const tableStreams = useLocalTableStreams();
  const [nirRequest, setNirRequest] = useState<NirRequest.NirRequest>({
    ...getMockNirRequest(),
    id: initialId || null,
    tender: externalNirRequest?.tenderId ? { ...getMockTender({}), id: externalNirRequest?.tenderId } : null,
    lot: externalNirRequest?.lotId ? { ...getMockLot(), id: externalNirRequest?.lotId } : null,
  });

  const { methods: saveNirAPI } = BackendAPI.useBackendAPI('SaveNirRequest');
  const { methods: sendMessageAPI } = BackendAPI.useBackendAPI('PushNirRequestMessage');
  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeNirRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddNirRequestApprovement');
  const { methods: getNirRequestAPI } = BackendAPI.useBackendAPI('GetNirRequest');

  const feedlineListStreams = useStreamsByApiID(globalStreams, 'GetNirRequestFeedlineList');

  const loadNirRequest = useCallback(
    (id: string | null, specification: string | null, tenderId?: string, lotId?: string) => {
      getNirRequestAPI.callAPI(
        { id, specification, tenderId, lotId },
        {
          onSuccessfullCall: ({ data }) => {
            const readyNir = getPreparedNir(data);
            setNirRequest(readyNir);
          },
        },
      );
    },
    [getNirRequestAPI],
  );
  useEffect(() => {
    loadNirRequest(
      nirRequest.id,
      externalNirRequest?.specification || null,
      externalNirRequest?.tenderId,
      externalNirRequest?.lotId,
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const performers = useMemo(() => nirRequest?.performers ?? [], [nirRequest?.performers]);
  const prevPerformers = usePrevious(performers);
  useEffect(() => {
    if (nirRequest !== null && prevPerformers?.length !== performers.length) {
      const findPerformer = (performerInfo: NirRequest.BasePerformerInfo) => (performer: NirRequest.Performer) =>
        performer.id === performerInfo.performerId ||
        (performer.person?.id === performerInfo.personId && performer.role === performerInfo.role);

      setNirRequest({
        ...nirRequest,
        collectiveForeignPerformers: nirRequest.collectiveForeignPerformers.filter(x => performers.find(findPerformer(x))),
        collectiveYoungPerformers: nirRequest.collectiveYoungPerformers.filter(x => performers.find(findPerformer(x))),
        collectiveRanPerformers: nirRequest.collectiveRanPerformers.filter(x => performers.find(findPerformer(x))),
        collectiveEducationalPerformers: nirRequest.collectiveEducationalPerformers.filter(x =>
          performers.find(findPerformer(x)),
        ),
      });
    }
  }, [nirRequest, prevPerformers, performers]);

  const makeChangeHandler = (key: keyof NirRequest.NirRequest) => (value: ValueOf<NirRequest.NirRequest>) => {
    setNirRequest(prevNir => ({ ...(prevNir || getMockNirRequest()), [key]: value }));
  };

  const saveNir = useCallback(
    (needClose: boolean, onSuccessCb?: () => void) => {
      if (!nirRequest) {
        return;
      }

      const validationInfo = validate(nirRequest);
      const errorMessages = [...validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage)];

      if (errorMessages.length) {
        showErrorsMessages(errorMessages);
        return;
      }

      saveNirAPI.callAPI(
        { nirRequest },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              loadNirRequest(data.id, externalNirRequest?.specification || null);
            }
            onSuccessCb?.();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nirRequest, tableStreams, loadNirRequest],
  );

  const reloadFeedlineList = useCallback(() => {
    feedlineListStreams.reloadTable.push({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendMessage = useCallback(
    (message: string) => {
      if (!nirRequest || !nirRequest.id) {
        return;
      }
      sendMessageAPI.callAPI(
        { nirRequestId: nirRequest.id, message },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщние успешно добавлено', theme: 'success' });
            reloadFeedlineList();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nirRequest, reloadFeedlineList],
  );

  const reloadNirRequest = useCallback((nir: NirRequest.NirRequest) => {
    getNirRequestAPI.callAPI(
      { id: nir.id ?? null, specification: nir.specification },
      {
        onSuccessfullCall: ({ data }) => {
          setNirRequest(getPreparedNir(data));
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const changeStatus = useCallback(
    (message: string, status: NirRequestStatus) => {
      if (nirRequest?.id) {
        changeStatusAPI.callAPI(
          { message, ids: [nirRequest.id], status },
          {
            onSuccessfullCall: () => {
              // onSuccessSave();
              showNotification({ message: 'Статус успешно изменен', theme: 'success' });
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nirRequest?.id],
  );

  const addApprovement = useCallback(
    (message: string, status: string) => {
      if (nirRequest?.id) {
        addApprovementAPI.callAPI(
          { message, nirRequestId: nirRequest.id, status },
          {
            onSuccessfullCall: () => {
              // onSuccessSave>();
              reloadNirRequest(nirRequest);
              showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [nirRequest, reloadNirRequest],
  );

  return {
    nirRequest,
    setNirRequest,
    makeChangeHandler,
    saveNir,
    sendMessage,
    changeStatus,
    addApprovement,
    reloadNirRequest,
  };
}

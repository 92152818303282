import React, { useCallback, useEffect } from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tabs, Tab } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { Toolbar } from './Toolbar';
import { useController } from './controller';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { useFormContext } from 'features/Form/hooks';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { OrganizationComponent } from '../views/Organization';
import { EventTab } from 'utils/Permissions/EventTab';
import { RecordStatus } from 'utils/Enums';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormConference = ({ viewMode, onClose }: Props) => {
  const docHint =
    // eslint-disable-next-line max-len
    'Не забудьте добавить подтверждающий документ: приказ, пригласительное письмо или программу конференции на вкладке «Документы»';

  const classifiersHint =
    'Раздел необходимо обязательно заполнить в случае, если Университет является организатором (соорганизатором) мероприятия';

  const materialHint = 'Необходимо обязательно заполнить, если есть участие в конференции с опубликованным докладом';

  const {
    refs,
    changeCommitteMembers,
    changeCompilations,
    changeDepartments,
    changeEnterprises,
    changeMagazineReleases,
    changeProjects,
    calculateFactMemberCount,
    changeMemberCounts,
    changeSourceMagazine,
    closeAndContinueConfirmPopup,
    closeConfirmPopup,
    committeMembers,
    compilations,
    confirmPopupText,
    departmentOptions,
    departments,
    documents,
    enterprises,
    eventData,
    eventId,
    formFields,
    handleAttractedDepartmentsChange,
    withHeaderId,
    handleFormSubmit,
    isAuthorsPopupOpen,
    isConfirmPopupOpen,
    isDisableSource,
    isDocumentsWarningPopupOpen,
    isIncentivePayments,
    isLoading,
    isSelectedPeriod,
    isTitleWarningPopup,
    magazineReleases,
    memberCounts,
    onCloseAuthorsPopup,
    onCloseDocumentsWarningPopup,
    onCloseTitleWarningPopup,
    onConfirmAuthorsPopup,
    onConfirmDocumentsWarningPopup,
    onConfirmTitleWarningPopup,
    onResetAuthorsPopup,
    participationPublications,
    participations,
    projects,
    relatedTableState,
    setDocuments,
    setEventDataField,
    sourceMagazine,
    tabsId,
    termsList,
    titleWarningText,
    unconnectedParticipationPublications,
    workMode,
    organizationName,
    userPermission,
    reloadEvent,
    sourceEvent,
  } = useController({
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const isVisibleTab = useCallback(
    (tab: EventTab) => {
      if (eventData.status?.value === RecordStatus.APPROVED) {
        const permitBase: string = 'CONFERENCE_EDIT_APPROVED';
        return (
          isHasPermission(userPermission, Permits[`${permitBase}_ALL` as keyof Permits]) ||
          (!!eventData.isUserOwned &&
            isHasPermission(userPermission, Permits[`${permitBase}_MY_${tab.toUpperCase()}` as keyof Permits]))
        );
      }

      return true;
    },
    [eventData.isUserOwned, eventData.status?.value, userPermission],
  );

  const renderAboutInfo = () => (
    <TabsContent.AboutComponent
      formFields={formFields}
      viewMode={!!viewMode}
      termsList={termsList}
      isSelectedPeriod={isSelectedPeriod}
      departments={departments}
      changeDepartments={changeDepartments}
      enterprises={enterprises}
      changeEnterprises={changeEnterprises}
      refs={refs}
      isDisableSource={isDisableSource}
      eventId={eventId}
      withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
      hint={docHint}
    />
  );

  const renderDefault = () => (
    <Tabs id={tabsId}>
      <Tab title="О мероприятии" isVisible={isVisibleTab(EventTab.ABOUT)}>
        {renderAboutInfo()}
      </Tab>
      <Tab title="Классификаторы" isVisible={isVisibleTab(EventTab.CLASSIFICATORS)}>
        <TabsContent.Classifiers
          eventData={eventData}
          setEventDataField={setEventDataField}
          viewMode={!!viewMode}
          hint={classifiersHint}
        />
      </Tab>
      <Tab title="Опубликованные материалы">
        <TabsContent.MaterialComponent
          eventId={eventId}
          viewMode={!!viewMode}
          refs={refs}
          changeSourceMagazine={changeSourceMagazine}
          changeMagazineReleases={changeMagazineReleases}
          sourceMagazine={sourceMagazine}
          magazineReleases={magazineReleases}
          relatedTableState={relatedTableState}
          compilations={compilations}
          changeCompilations={changeCompilations}
          isDisableSource={isDisableSource}
          participationPublications={participationPublications}
          hint={materialHint}
        />
      </Tab>
      <Tab title="Организация мероприятия" isVisible={isVisibleTab(EventTab.ORGANIZATION)}>
        <OrganizationComponent
          calculateFactMemberCount={calculateFactMemberCount}
          changeCommitteMembers={changeCommitteMembers}
          changeMemberCounts={changeMemberCounts}
          committeMembers={committeMembers}
          formFields={formFields}
          memberCounts={memberCounts}
          viewMode={!!viewMode}
        />
      </Tab>
      <Tab title="Участники">
        <TabsContent.Participations
          sourceEvent={sourceEvent || eventData}
          workMode={workMode}
          reloadEvent={reloadEvent}
          isEventChanged={false} // TODO: need refactor inner structures to changing dispatch
          participations={participations}
          unconnectedParticipationPublications={unconnectedParticipationPublications}
        />
      </Tab>
      <Tab title="Финансирование" isVisible={isVisibleTab(EventTab.FINANCING)}>
        <TabsContent.FinancialSupport
          eventData={eventData}
          projects={projects}
          setProjects={changeProjects}
          workMode={workMode}
          relatedTableState={relatedTableState}
          setEventDataField={setEventDataField}
          departments={departments}
          departmentOptions={departmentOptions}
          handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
        />
      </Tab>
      <Tab title="Документы">
        <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={!!viewMode} hint={docHint} />
      </Tab>
      <Tab title="Журнал сообщений">
        <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
      </Tab>
    </Tabs>
  );

  if (isIncentivePayments) {
    return renderDefault();
  }

  useEffect(() => {
    setTitle?.(
      EventHeader({
        workMode,
        departments,
        status: eventData?.status?.label || '',
        eventId: eventId,
        eventTypeCode: EventTypes.EVENT_CONFERENCE.code,
      }),
    );
  }, [departments, eventData?.status?.label, eventId, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <Toolbar
          isDisabled={isLoading}
          onSave={needClose => handleFormSubmit(needClose)}
          {...(workMode === 'addMode' &&
          !isHasPermission(userPermission, [Permits.CONFERENCE_EDIT_ADDED_MY, Permits.CONFERENCE_EDIT_ADDED_ALL])
            ? {}
            : { onSaveDraft: () => handleFormSubmit(false) })}
          viewMode={!!viewMode}
          eventId={eventId || ''}
        />

        <SuchLikeEvent
          mode="edit"
          eventName={formFields.name.value as string}
          startDate={formFields.startDate.value as string}
          endDate={formFields.endDate.value as string}
          handleCloseOuterForm={onClose}
          command="RefEventFeedlineMessage"
          eventTypeCode={EventTypes.EVENT_CONFERENCE.code}
        />

        <FormComponent.Template>
          {!isDisableSource ? (
            renderDefault()
          ) : (
            <Tabs id={tabsId}>
              <Tab title="О мероприятии" isVisible={isVisibleTab(EventTab.ABOUT)}>
                {renderAboutInfo()}
              </Tab>
              <Tab title="Финансирование" isVisible={isVisibleTab(EventTab.FINANCING)}>
                <TabsContent.FinancialSupport
                  eventData={eventData}
                  projects={projects}
                  setProjects={changeProjects}
                  workMode={workMode}
                  relatedTableState={relatedTableState}
                  // incentivePaymentRequest={eventData?.status?.incentivePaymentRequest || ''}
                  setEventDataField={setEventDataField}
                  departments={departments}
                  departmentOptions={departmentOptions}
                  handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
                />
              </Tab>
              <Tab title="Документы">
                <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={!!viewMode} hint={docHint} />
              </Tab>
            </Tabs>
          )}
        </FormComponent.Template>

        <DocumentsWarningPopup
          isOpen={isDocumentsWarningPopupOpen}
          onConfirm={onConfirmDocumentsWarningPopup}
          onClose={onCloseDocumentsWarningPopup}
        />

        <Modal
          mode="info"
          title="Обратите внимание"
          isOpen={isConfirmPopupOpen}
          onClose={closeAndContinueConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Указать',
              onClick: closeConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Пропустить',
              onClick: closeAndContinueConfirmPopup,
            },
          ]}
          size="small"
        >
          <>{confirmPopupText}</>
        </Modal>

        <Modal
          mode="warning"
          title="Внимание"
          isOpen={isTitleWarningPopup}
          onClose={onCloseTitleWarningPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да, верно',
              onClick: onConfirmTitleWarningPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onCloseTitleWarningPopup,
            },
          ]}
          size="small"
        >
          <FormComponent.Text>В наименовании обнаружены латинские символы</FormComponent.Text>
          <FormComponent.Text>
            <strong dangerouslySetInnerHTML={{ __html: titleWarningText }} />
          </FormComponent.Text>
          <FormComponent.Text>Вы уверены, что наименование верно?</FormComponent.Text>
        </Modal>
      </FormComponent.Template>

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={onCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: onConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: onResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <p>
          <strong>В списке «Мои конференции», отображаются только те конференции, в которых вы в составе оргкомитета.</strong>
        </p>
        <p>В списке «Конференции {organizationName}» — организованные Университетом</p>
        <p>В списке «Другие конференции» — организованные другими организациями</p>
        <p>Вашей персоны нет в списке участников оргкомитета. Добавить?</p>
      </Modal>
    </>
  );
};

export const Component = React.memo(EventFormConference);

import React from 'react';

import { FormComponent, Radio, ReferenceItem, SectionTitle, Select, SelectMode, TextDateTime, TextInput } from 'components';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';

import { Customers } from './Customers';
import { Departments } from './Departments';
import { Financings } from './Financings';
import { Stages } from './Stages';
import { FinancingsByYear } from './FinancingsByYear';
import { TaskNirFields } from './TaskNirFields';
import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';

export type Props = {
  project: Project.Project;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  disabled: boolean;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  isEditableLocked: boolean;
};

export function AboutProject(props: Props) {
  const { disabled, project, isEditableLocked, indicators, indices, makeChangeHandler, setProject } = props;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <TaskNirFields
          disabled={disabled}
          project={project}
          makeChangeHandler={makeChangeHandler}
          setProject={setProject}
          indicators={indicators}
          indices={indices}
        />

        <SectionTitle
          isRequired
          title="Проект выполняется на базе"
          info={
            <p>
              Является ключевым для других разделов. Поэтому любые изменения система будет фиксировать в{' '}
              <strong>Журнале изменений проекта</strong>. В разделе необходимо указать подразделение(я), которое является
              ответственным за проект в соответствии с правилами учётной политики.
            </p>
          }
        />

        <FormComponent.Line>
          <Departments
            projectId={project?.id ?? undefined}
            departments={project?.departments ?? []}
            setDepartments={makeChangeHandler('departments')}
            withMessages={false}
            disabled={disabled}
          />
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Статус договора" isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={project?.projectContractStatus}
              settings={{ name: 'ProjectContractStatus' }}
              onChange={(option: Item) => makeChangeHandler('projectContractStatus')(option as ValueOf<Project.Project>)}
              isDisabled={disabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Номер договора">
            <TextInput
              value={project?.contractNumber || ''}
              onChange={makeChangeHandler('contractNumber')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид договора">
            <Select
              mode={SelectMode.REFERENCE}
              value={project?.contractKind}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('contractKind')(option)}
              settings={{ name: 'RefContractKind', title: 'Справочник "Виды договоров"', isClearable: true }}
              isDisabled={disabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата договора">
            <TextDateTime value={project?.contractDate} onChange={makeChangeHandler('contractDate')} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        {!!project?.securityDocumentContract?.conclusionDate && !!project?.securityDocumentContract?.number && (
          <FormComponent.Field label="Регистрация в Роспатенте">
            №{project.securityDocumentContract.number} от {project.securityDocumentContract.conclusionDate}
          </FormComponent.Field>
        )}
        <FormComponent.Line>
          <FormComponent.Field
            label="Уровень конфиденциальности"
            info={
              <p>
                Проекты с уровнем конфиденциальности из категории <strong>«Закрытые данные»</strong> не отображаются в открытых
                списках: в личном кабинете пользователя и в функциональном блоке Поиск, Список научных проектов.
              </p>
            }
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={project?.confidentialityLevel}
              onChange={(e: ReferenceItem | null) => setProject(prev => ({ ...prev, confidentialityLevel: e }))}
              settings={{
                name: 'RefProjectConfidentialityLevel',
                title: 'Справочник "Уровни конфеденциальности"',
                isClearable: true,
              }}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field
            label="Предупреждать в разделе с документами"
            info={
              <>
                <p>
                  Если этот атрибут имеет значение <strong>Да</strong>, то в режиме просмотра или редактирования проекта при
                  переходе <strong>на вкладку Документы</strong> система будет выдавать сообщение о конфиденциальности документов
                  проекта.
                </p>
                <p>
                  Текст сообщения можно отредактировать в справочнике <strong>Уровни конфиденциальности</strong>.
                </p>
              </>
            }
          >
            <Radio
              value={(!!project?.isConfidentialityAlert).toString()}
              list={[
                { label: 'Да', value: 'true' },
                { label: 'Нет', value: 'false' },
              ]}
              onChange={e => setProject(prev => ({ ...prev, isConfidentialityAlert: e === 'true' }))}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle
          title="Заказчики"
          info={
            <p>
              Данные о заказчиках выбирать из одноимённого справочника (этот справочник загружается из системы бухгалтерского
              учёта). Так как в некоторых запросах и статистических отчётах требуется аналитика по типам заказчиков, то
              желательно, обращать внимание на то какую запись из справочника вы выбираете.{' '}
              <strong>Если сектор деятельности и тип организации отсутствует – добавьте</strong>.
            </p>
          }
          isRequired
        />

        <FormComponent.Line>
          <Customers
            customers={project?.customers ?? []}
            setCustomers={makeChangeHandler('customers')}
            withMessages={false}
            disabled={disabled}
          />
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column>
        <SectionTitle
          title="Общий объём финансирования проекта"
          info={
            <>
              <p>
                Обязательный раздел, в котором необходимо указать Вид финансирования, Источник. Проект может иметь несколько
                источников финансирования:
              </p>
              <ul>
                <li>
                  <strong>ОИФ</strong> - основной источник финансирования;
                </li>
                <li>
                  <strong>СФ1</strong> – первый источник софинансирования;
                </li>
                <li>
                  <strong>СФ2</strong> – второй источник софинансирования.
                </li>
              </ul>
              <p>
                <strong>Собственные средства, Индустриальный партнёр</strong> — дополнительные виды финансирования, которые не
                предполагают поступления средств на счёт университета, но обязательны по условиям договора.
              </p>
              <p>
                Значения в столбцах таблицы вычисляются автоматически как <strong>общая сумма из Календарного плана</strong>.
              </p>
            </>
          }
          isRequired
        />

        <FormComponent.Line>
          <Financings
            financings={project?.financings ?? []}
            setFinancingsByYear={makeChangeHandler('financingsByYear')}
            setFinancings={makeChangeHandler('financings')}
            setStages={makeChangeHandler('stages')}
            stages={project?.stages ?? []}
            projectId={project?.id ?? undefined}
            withMessages={false}
            disabled={disabled}
          />
        </FormComponent.Line>

        <SectionTitle
          title="Календарный план финансирования"
          info={
            <>
              <p>
                Информация по этапам проекта заполняется после того как в разделе <strong>Финансирование проекта</strong> указаны
                виды финансирования и источники.
              </p>
              <p>
                Кнопка <strong>Сформировать календарный план</strong> запускает процедуру автоматического формирования
                календарного плана в соответствии с выбранным алгоритмом.
              </p>
              <div className="warning">
                <ul>
                  <li>
                    На форме добавления/редактирования этапа система позволяет внести сумму только по видам финансирования,
                    указанным в разделе Финансирование проекта.
                  </li>
                  <li>
                    На основе календарного плана составляется план государственной регистрации отчётов по этапам проекта, поэтому
                    нельзя удалить этап, по которому уже запланирована госрегистрация.
                  </li>
                  <li>
                    Сроки этапа ограничены финансовым годом, поэтому если по условиям договора этап проекта заканчивается в
                    следующем году (например, 01.06.2023- 1.02.2024), то в календарный план должен содержать 2 этапа: Этап 1а
                    (01.06.2023-31.12.2023) и Этап 1б (01.01.2024 -1.02.2024). сумма этапа – в соответствии с условиями
                    финансирования проекта
                  </li>
                </ul>
              </div>
            </>
          }
        />

        <FormComponent.Line>
          <Stages
            isEditableLocked={isEditableLocked}
            project={project}
            setProject={setProject}
            financings={project?.financings ?? []}
            stages={project?.stages ?? []}
            performers={project?.performers ?? []}
            setPerformers={makeChangeHandler('performers')}
            setStages={makeChangeHandler('stages')}
            setFinancings={makeChangeHandler('financings')}
            setFinancingsByYear={makeChangeHandler('financingsByYear')}
            withMessages={false}
            disabled={disabled}
          />
        </FormComponent.Line>

        <SectionTitle
          title="План финансирования по годам"
          info={
            <p>
              Данные в таблице автоматически формируются в соответствии с <strong>Календарным планом</strong> и содержат итоговые
              суммы по годам.
            </p>
          }
        />

        <FormComponent.Line>
          <FinancingsByYear financingsByYear={project?.financingsByYear ?? []} stages={project?.stages ?? []} />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import React from 'react';

import { ListEdit } from 'components';
import * as BackendAPI from 'services/BackendAPI';

import { Project, ReferenceItem } from 'types/models';

import { GetReferenceElementList } from 'features/Table/specifications';

type Props = {
  customers: Project.Customer[];
  setCustomers(customers: Project.Customer[]): void;
  withMessages?: boolean;
  disabled: boolean;
};

export function Customers(props: Props) {
  const { customers, setCustomers, withMessages = true, disabled } = props;

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  return (
    <ListEdit
      rows={customers}
      onChange={setCustomers}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        {
          label: 'Наименование заказчика',
          formatValue: x => `${x.ref.customFields?.fullName} (${x.ref.label})`,
          styles: { width: '50%' },
        },
        {
          label: 'Тип организации',
          formatValue: x =>
            [
              (x.ref.customFields?.lksdEnterpriseType as ReferenceItem)?.label,
              (() => {
                if (x.ref.customFields?.sectorActivity) {
                  if (x.ref.customFields?.lksdEnterpriseType)
                    return `(${(x.ref.customFields?.sectorActivity as ReferenceItem)?.label})`;
                  return (x.ref.customFields?.sectorActivity as ReferenceItem)?.label;
                }
              })(),
            ]
              .filter(Boolean)
              .join(' '),
          styles: { width: '50%' },
        },
      ]}
      defaultRowsCount={3}
      withMessages={withMessages}
      isDisabled={disabled}
      isDeleteConfirmEnabled
      maxHeight="350px"
      specification={{
        mode: 'loadInstanceTableModal',
        onStartLoad: (tableRows, onSuccess) => {
          loadReferenceAPI.callAPI(
            { filters: [], referenceName: 'RefEnterpriseCustomer', childIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data.map(x => ({ id: null, ref: x }))) },
          );
        },
        relationTableModalTitle: 'Справочник "Заказчики"',
        modalTableSpecification: GetReferenceElementList({
          requestData: { filters: [], name: 'RefEnterpriseCustomer' },
        }),
      }}
    />
  );
}

import { NirRequest, Publication } from 'types/models';
import { parse } from 'date-fns';

import {
  excludeIndicesIDsCodesForIG,
  excludeIndicesIDsCodesForML,
  excludeIndicesIDsCodesForONG,
  excludeIndicesIDsCodesForPD,
  excludeIndicesIDsCodesForPP,
} from './constants';
import { NirRequestStatus, NirTenderType } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { getPersonContacts } from 'utils/Helpers';
import { HirshIndex, Identifier } from 'types/models/Person';
import { formatDateTimeStr, formatStr } from 'utils/Constants/FormatStr';

export function getMockNirRequest(): NirRequest.NirRequest {
  return {
    annotation: '',
    approvements: [],
    collaborationParticipation: '',
    collectiveBaseDissertations: [],
    collectiveConferenceParticipations: [],
    collectiveEducationalPerformers: [],
    collectiveExpositionParticipations: [],
    collectiveForeignPerformers: [],
    collectiveForeignProjects: [],
    collectiveForeignSecurityDocuments: [],
    collectiveProjects: [],
    collectivePublications: [],
    collectiveRanPerformers: [],
    collectiveSecurityDocuments: [],
    collectiveYoungPerformers: [],
    criticalTechnologies: [],
    department: null,
    departmentText: '',
    departmentTxt: '',
    documents: [],
    economicSectorInterraction: '',
    englishAnnotation: '',
    englishDepartmentTxt: '',
    englishGoal: '',
    englishKeyWords: '',
    englishLeader: '',
    englishName: '',
    englishRelevance: '',
    estimateExpencesJustification: '',
    estimatePositions: [],
    expectedResults: [],
    goal: '',
    grntis: [],
    id: null,
    indicators: [],
    indices: [],
    interdisciplinaryAproachJustification: '',
    keyWords: '',
    lot: null,
    name: '',
    notAffiliatedCollectivePublications: [],
    notAffiliatedDescriptionPublications: [],
    ntrStrategies: [],
    oecds: [],
    performers: [],
    pnis: [],
    pnrJustification: '',
    pnrs: [],
    problemResearchState: '',
    publications: [],
    registered: '',
    relevance: '',
    requestNumber: '',
    researchMethods: '',
    researchPracticalSignificance: '',
    scientificGroundwork: '',
    scientificProblem: '',
    specification: 'BASIC_RESEARCH',
    stages: [],
    stageTasks: [],
    status: { label: 'Черновик', value: NirRequestStatus.DRAFT },
    tender: null,
    udks: [],
  };
}

export function getMockStage(): NirRequest.Stage {
  return {
    amount: '',
    id: null,
    name: '',
    year: '',
    universityEffect: '',
    regionalEffect: '',
    nationalEffect: '',
  };
}

export function getMockPerformer(): NirRequest.Performer {
  return {
    id: null,
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    historyLabel: '',
    job: null,
    person: null,
    rank: null,
    role: null,
    collaborationParticipation: '',
    foreignPerformerQ1Q2PublicationQty: '',
    personQ1Q2PublicationQty: '',
    economicContractParticipationExperience: '',

    awards: null,
    keyWords: null,
    note: null,
    primePublications: null,
    projects: null,
    q1PublicationQty: null,
    rids: null,
    scientificGroundwork: null,
    totalPublicationQty: null,
  };
}

export function getResponsiblePerformerName(request: NirRequest.NirRequest | null | undefined) {
  const currentPersonData = request?.performers.find(x => x.role?.value === 'RESPONSIBLE_PERFORMER')?.person;
  return currentPersonData ? currentPersonData.fullName + getPersonContacts(currentPersonData.contacts) : null;
}

export function getHeadName(request: NirRequest.NirRequest | null | undefined) {
  const currentPersonData = request?.performers.find(x => x.role?.value === 'LEADER')?.person;
  return currentPersonData ? currentPersonData.fullName + getPersonContacts(currentPersonData.contacts) : null;
}

export function getHeadNirRequest(nirRequest: NirRequest.NirRequest | null) {
  return nirRequest?.performers.find(x => x.role?.value === 'LEADER') ?? null;
}

export function getLeadScientist(nirRequest: NirRequest.NirRequest | null) {
  return nirRequest?.performers.find(x => x.role?.value === 'LEAD_SCIENTIST') ?? null;
}

export function getMockPublication(): Publication {
  return {
    id: '',
    bibliographicRecord: '',
    departments: [],
    members: [],
    type: { label: 'Статья', value: 'ARTICLE' },
    doi: '',
    scopusCitations: '',
    scopusQuartile: '',
    wosCitations: '',
    wosQuartile: '',
  };
}

export function formatDegreeRank(performer: NirRequest.Performer) {
  return [
    // degreeStr
    performer.degree?.refDegree?.label || '',
    performer.degree?.dateAssign || '',
    // rankStr
    performer.rank?.refRank?.label || '',
    performer.rank?.dateAssign || '',
    // academicRank
    performer.academicRank?.refAcademicRank?.label || '',
    performer.academicRank?.academy?.label || '',
  ]
    .filter(Boolean)
    .join(' ');
}

export function formatIdentifiers(performer: NirRequest.Performer, isOnlyLast?: boolean) {
  let identifiers = performer.person?.identifiers ?? [];

  if (!!isOnlyLast) {
    const parseDate = (value: string) => parse(value, formatDateTimeStr, new Date());

    identifiers = (() => {
      const identifiersObj: Record<string, Identifier> = {};

      identifiers.forEach(identifier => {
        if (!identifier.citationSystem?.id) {
          return;
        }

        if (
          !identifiersObj[identifier.citationSystem.id] ||
          parseDate(identifiersObj[identifier.citationSystem.id].createdDate || '') < parseDate(identifier.createdDate || '')
        ) {
          identifiersObj[identifier.citationSystem.id] = identifier;
        }
      });

      return Object.values(identifiersObj);
    })();
  }

  identifiers = identifiers.sort((a, b) => (a.citationSystem?.label || '').localeCompare(b.citationSystem?.label || ''));

  return identifiers.map(identifier => `${identifier.citationSystem?.label}: ${identifier.identifier}`).join(', ') ?? '';
}

export function formatIndexHirsh(performer: NirRequest.Performer, isOnlyLast?: boolean) {
  let hirshIndexes = performer.person?.hirshIndexes ?? [];

  if (!!isOnlyLast) {
    const parseDate = (value: string) => parse(value, formatStr, new Date());

    hirshIndexes = (() => {
      const identifiersObj: Record<string, HirshIndex> = {};

      hirshIndexes.forEach(hirshIndex => {
        if (!hirshIndex.citationSystem?.id) {
          return;
        }

        if (
          !identifiersObj[hirshIndex.citationSystem.id] ||
          parseDate(identifiersObj[hirshIndex.citationSystem.id].date || '') < parseDate(hirshIndex.date || '')
        ) {
          identifiersObj[hirshIndex.citationSystem.id] = hirshIndex;
        }
      });

      return Object.values(identifiersObj);
    })();
  }

  hirshIndexes = hirshIndexes.sort((a, b) => (a.citationSystem?.label || '').localeCompare(b.citationSystem?.label || ''));

  return (
    hirshIndexes.map(hirshIndex => `${hirshIndex.citationSystem?.label} - ${hirshIndex.value} (${hirshIndex.date})`).join(', ') ??
    ''
  );
}

export function mergeExpectedResult(stages: NirRequest.Stage[], expectedResults: NirRequest.ExpectedResult[]) {
  return stages.map<NirRequest.ExpectedResult>(stage => {
    const foundExpectedResult = expectedResults.find(expectedResult => expectedResult.year === stage.year);
    return foundExpectedResult || { id: null, year: stage.year, result: '' };
  });
}

export function mergeStageTasks(stages: NirRequest.Stage[], stageTasks: NirRequest.StageTask[]) {
  return stages.map<NirRequest.StageTask>(stage => {
    const foundStageTask = stageTasks.find(stageTask => stageTask.year === stage.year);
    return foundStageTask || { id: null, year: stage.year, task: '', englishTask: '' };
  });
}

export function getMaxSymbolsInvalidMessage(fieldName: string) {
  return `Поле "${fieldName}" - превышен лимит по количеству знаков`;
}

export function getPreparedNir(nirRequest: NirRequest.NirRequest) {
  if (!nirRequest) {
    return nirRequest;
  }

  function defaultPerform(request: NirRequest.NirRequest): NirRequest.NirRequest {
    return { ...request, performers: sortPerformers(request.performers) };
  }

  const updatersMap: Record<NirTenderType, (nir: NirRequest.NirRequest) => NirRequest.NirRequest> = {
    IG: (nir: NirRequest.NirRequest) => getFilteredIndicators(excludeIndicesIDsCodesForIG, defaultPerform(nir)),
    LMU: (nir: NirRequest.NirRequest) => defaultPerform(nir),
    ML: (nir: NirRequest.NirRequest) => getFilteredIndicators(excludeIndicesIDsCodesForML, defaultPerform(nir)),
    ONG: (nir: NirRequest.NirRequest) => getFilteredIndicators(excludeIndicesIDsCodesForONG, defaultPerform(nir)),
    PD: (nir: NirRequest.NirRequest) => getFilteredIndicators(excludeIndicesIDsCodesForPD, defaultPerform(nir)),
    PP: (nir: NirRequest.NirRequest) => getFilteredIndicators(excludeIndicesIDsCodesForPP, defaultPerform(nir)),
  };

  if (!nirRequest.lot?.nirTenderType?.value) showNotification({ message: 'Отсутствует тип заявки', theme: 'danger' });

  const updater = updatersMap[nirRequest.lot?.nirTenderType?.value!];

  return updater?.(nirRequest);
}

function getFilteredIndicators(indicesCodes: string[], nir: NirRequest.NirRequest) {
  const indices = nir.indices.filter(x => {
    const code = (x.refResultItem.customFields?.code ?? null) as string | null;
    return !code ? x : !indicesCodes.includes(code);
  });

  return { ...nir, indices };
}

export function sortPerformers(performers: NirRequest.Performer[]) {
  return performers.sort((a, b) => (a.person?.fullName ?? '').localeCompare(b.person?.fullName ?? ''));
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertCashDocument } from './converters';
import { convertCashDocumentToRequest } from './requestConverters';
import { CashDocument } from 'types/models';
import { Settings } from 'types/models/common';

const configurations = {
  GetCashDocument: makeBackendAPIConfiguration({
    id: 'GetCashDocument',
    endpoint: '/msa/service/commands/GetCashDocument',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetCashDocument' },
        CashDocument: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertCashDocument(response.Response.CashDocument, settings),
  }),
  SaveCashDocument: makeBackendAPIConfiguration({
    id: 'SaveCashDocument',
    endpoint: '/msa/service/commands/SaveCashDocument',
    convertInputToXMLElement: (cashDocument: CashDocument.CashDocument) => ({
      Request: {
        _attr: {
          commandName: 'SaveCashDocument',
        },
        CashDocument: { ...convertCashDocumentToRequest(cashDocument) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => ({ id: response.Response.Id?._text as string }),
  }),
  DeleteCashDocument: makeBackendAPIConfiguration({
    id: 'DeleteCashDocument',
    endpoint: '/msa/service/commands/DeleteCashDocument',
    convertInputToXMLElement: (ids: string[]) => ({
      Request: {
        _attr: { commandName: 'DeleteCashDocument' },
        CashDocument: ids.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const cashDocumentConfigurations = { ...configurations };

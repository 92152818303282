import React, { useMemo, useState } from 'react';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { DataGrid } from 'features/Table/container';
import { GetDepartmentEmployeeList } from 'features/Table/specifications';
import { FilterOptions, State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const selectedRow = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] || null, [tableState.selectedRows]);

  const [isEmployeeModalOpen, setIsEmployeeModalOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'list' },
        title: 'Списочный состав сотрудников подразделения',
        onClick: () => setIsEmployeeModalOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [tableState.selectedRows.length],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={`Списочный состав сотрудников подразделения: ${selectedRow?.DepartmentName || ''}`}
        isOpen={isEmployeeModalOpen}
        onClose={() => setIsEmployeeModalOpen(false)}
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid
          specification={GetDepartmentEmployeeList({
            departmentId: selectedRow?.id,
            actual: customState.filter === FilterOptions.ACTUAL,
            year: customState.isYear ? customState.year : '',
          })}
        />
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

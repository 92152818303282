import React, { memo } from 'react';
import { Table as T, Form as F } from 'types/models';
import { DataGrid } from 'features/Table';
import { GetPublicationCitationSystemList } from 'features/Table/specifications';
import { FormComponent, SectionTitle } from 'components';

type Props = {
  relatedTableState?: T.State;
  addCitationSystem: (citationSystem: F.CitationSystem) => void;
  removeCitationSystem: (citationSystemId: string) => void;
  editCitationSystem: (citationSystem: F.CitationSystem) => void;
  publicationType: string;
  sourceId?: string;
  releaseId?: string;
  topMessage: string;
  setTopMessage: (topMessage: string) => void;
  workMode: T.WorkMode;
};

const DatabaseIndexing = ({
  relatedTableState,
  workMode,
  publicationType,
  sourceId,
  releaseId,
  addCitationSystem,
  removeCitationSystem,
  editCitationSystem,
  topMessage,
  setTopMessage,
}: Props) => (
  <>
    {relatedTableState && publicationType && (
      <>
        <FormComponent.Description mode="warning">{topMessage}</FormComponent.Description>

        <SectionTitle
          title="Индексация в базах цитирования"
          // eslint-disable-next-line max-len
          tooltip="Индексация в базах цитирования и показатели издания содержит сведения об индексации публикации и ее источниках в базах цитирования: Столбец Наименование БЦ содержит список баз цитирования в которых индексируется источник публикаций в год издания, в столбце Показатели издания - его наукометрические показатели."
        />
        <DataGrid
          specification={GetPublicationCitationSystemList({
            templatesTableDependencies: {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId:
                workMode === 'addMode' || !(relatedTableState.selectedRows?.length > 0)
                  ? ''
                  : relatedTableState.selectedRows[0]?.id,
              isFiltersHidden: true,
            },
            publicationType,
            addCitationSystem,
            removeCitationSystem,
            editCitationSystem,
            workMode,
            sourceId,
            releaseId,
            setTopMessage,
          })}
        />
      </>
    )}
  </>
);

export const DatabaseIndexingComponentLocal = memo(DatabaseIndexing);

import { Table } from 'types/models';

export function GetMipList(): Table.Specification {
  return {
    apiID: 'GetMipList',
    header: {
      firstLevel: {
        title: 'МИП',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}

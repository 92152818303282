import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { usePermissions } from 'features/Form/looks/securityDocument/SecurityDocumentPaymentRequestForm/usePermissions';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { isNextPaymentStatus } from 'utils/Helpers/authorPayments';
import { Color } from 'constants/colors';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const [securityDocumentId, setSecurityDocumentId] = useState<string | null>(null);
  const [isSelectFormOpen, setIsSelectFormOpen] = useState(false);
  const [isAddPaymentRequestFormOpen, setIsAddPaymentRequestFormOpen] = useState(false);
  const [isViewPaymentRequestFormOpen, setIsViewPaymentRequestFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isOpenCommentForm, setIsOpenCommentForm] = useState<boolean>();
  const [isOpenConfirmStatusForm, setIsOpenConfirmStatusForm] = useState<boolean>();
  const [isOpenStatusForm, setIsOpenStatusForm] = useState<boolean>();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const [comment, setComment] = useState<string | null>();
  const [newStatus, setNewStatus] = useState<string | null>();

  const onSubmitSelectDocument = useCallback((id: string) => {
    setSecurityDocumentId(id);
    setIsSelectFormOpen(false);
    setIsAddPaymentRequestFormOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleOpenDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const { methods: deletePaymentAPI } = BackendAPI.useBackendAPI('DeleteAuthorPaymentRequest');

  const reloadTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const deletePaymentRequest = useCallback(
    (paymentId: string) => {
      deletePaymentAPI.callAPI(
        { paymentId },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            reloadTable();
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reloadTable],
  );

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deletePaymentRequest(tableState.selectedRows[0]?.id);
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, deletePaymentRequest]);

  const handleAddButtonClick = useCallback(() => {
    setIsSelectFormOpen(true);
  }, []);
  const handleViewButtonClick = useCallback(() => {
    setIsViewPaymentRequestFormOpen(true);
  }, []);
  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const reports = useMemo<Report[]>(
    () => [
      Reports.MemorandumAccounting,
      Reports.IncentivePaymentTransferedPatents,
      Reports.IPIssuePatentRegister,
      Reports.IPPatentRegister,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddPaymentRequestFormOpen(false);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewPaymentRequestFormOpen(false);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const onSelectProjectFormClose = useCallback(() => {
    setIsSelectFormOpen(false);
  }, []);

  const statusEditable = useMemo(
    () => tableState.selectedRows[0] && tableState.selectedRows[0]['id:status'] !== 'PAYMENT_APPROVED',
    [tableState],
  );

  const [isFeedlineModalOpen, setIsFeedlineModalOpen] = useState<boolean>();

  const paymentStatus = useMemo(
    () => (tableState.selectedRows[0] ? (tableState.selectedRows[0]['id:status'] as AuthorPaymentRequestStatus) : 'DRAFT'),
    [tableState],
  );

  const permissions = usePermissions(paymentStatus as AuthorPaymentRequestStatus);

  const isAddButtonDisabled = !rowsAvailability.ALWAYS;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !statusEditable || !permissions.hasEditInStatusPermission;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !statusEditable || !permissions.hasDeleteInStatusPermission;

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestStatus');

  const { methods: getAuthorPaymentRequest } = BackendAPI.useBackendAPI('GetAuthorPaymentRequest');

  const handleStatusChange = useCallback(
    (forceStatus?: string) => {
      changeStatusAPI.callAPI(
        { comment: comment || '', status: forceStatus || newStatus || '', paymentId: tableState.selectedRows[0]?.id || '' },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
            getAuthorPaymentRequest.callAPI(
              {
                foundation: { id: tableState.selectedRows[0]?.id || '', label: '' },
                mode: 'edit',
                type: 'SECURITY_DOCUMENT',
              },
              { onSuccessfullCall: () => reloadTable() },
            );
            setComment('');
          },
        },
      );
    },
    [comment, newStatus, setComment, changeStatusAPI, getAuthorPaymentRequest, reloadTable, tableState],
  );

  const onChangeConfirm = useCallback(
    (status: AuthorPaymentRequestStatus | null) => {
      setNewStatus(status);
      setIsOpenConfirmStatusForm(true);
    },
    [setNewStatus, setIsOpenConfirmStatusForm],
  );

  const changeStatusTo = useCallback(
    (x: AuthorPaymentRequestStatus | null) => {
      if (!checkPaymentByStatus(tableState.selectedRows[0]?.factNomineesMoney ?? null, x)) {
        handleStatusChange(x?.toString());
      } else {
        onChangeConfirm(x);
      }
    },
    [handleStatusChange, onChangeConfirm, tableState],
  );

  const statusButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus ||
          !tableState.selectedRows[0]?.id ||
          !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.AGREEMENT),
      },
      {
        icon: { type: 'like' },
        title: 'Одобрить оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus ||
          !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.PAYMENT_APPROVED),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus ||
          !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.REVISION),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Передать на оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus || !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.PAID),
      },
      {
        icon: { type: 'clock' },
        title: 'Отложить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus ||
          !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.POSTPONE),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[paymentStatus],
        },
        isDisabled:
          !paymentStatus ||
          !isNextPaymentStatus(paymentStatus as AuthorPaymentRequestStatus, AuthorPaymentRequestStatus.REJECTED),
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: () => {
          setIsFeedlineModalOpen(true);
        },
      },
    ],
    [changeStatusTo, paymentStatus, permissions, tableState],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        isDisabled: isDeleteButtonDisabled,
        onClick: handleOpenDeleteConfirmPopup,
      },
      ...statusButtons,
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      isViewButtonDisabled,
      handleAddButtonClick,
      isAddButtonDisabled,
      handleEditButtonClick,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      handleOpenDeleteConfirmPopup,
      statusButtons,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    isAddPaymentRequestFormOpen,
    handleTemplateCloseAddForm,
    isViewPaymentRequestFormOpen,
    handleTemplateCloseViewForm,
    isAddButtonDisabled,
    securityDocumentId,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isSelectFormOpen,
    onSelectProjectFormClose,
    onSubmitSelectDocument,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    comment,
    setComment,
    newStatus,
    isOpenCommentForm,
    setIsOpenCommentForm,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    handleStatusChange,
    isFeedlineModalOpen,
    setIsFeedlineModalOpen,
    isHelpOpen,
    setIsHelpOpen,
  };
}

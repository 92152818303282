import React from 'react';

import { FormComponent, Header, Tab, Tabs } from 'components';
import { DataGrid } from 'features/Table';

import { GetQnaFrequentQuestionList } from 'features/Table/specifications/GetQnaFrequentQuestionList';
import { GetNotificationLogList, GetQnaAllQuestionList } from 'features/Table/specifications';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { NotificationEventModule } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';

type Props = {
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

function Questions(props: Props) {
  const { defaultTabId, onTabClick } = props;

  const { userPermission } = useAppDataContext();

  return (
    <FormComponent.Template>
      <Header>Вопросы от пользователей администраторам</Header>

      <FormComponent.Template>
        <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
          <Tab title="Управление часто задаваемыми вопросами" id="frequent">
            <DataGrid specification={GetQnaFrequentQuestionList()} />
          </Tab>
          <Tab title="Вопросы от пользователей и ответы на них" id="all">
            <DataGrid specification={GetQnaAllQuestionList()} />
          </Tab>
          <Tab
            title="Журнал уведомлений"
            id="notifications"
            isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
          >
            <DataGrid specification={GetNotificationLogList({ module: NotificationEventModule.QNA })} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(Questions);

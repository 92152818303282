import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextDateTime } from 'components';

import { PeriodableReferenceItem } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';

type Props = {
  okved: PeriodableReferenceItem;
  setOkved(okved: PeriodableReferenceItem): void;
};

export function Fields(props: Props) {
  const { okved, setOkved } = props;

  const makeChangeHandler = (key: keyof PeriodableReferenceItem) => (value: ValueOf<PeriodableReferenceItem>) => {
    setOkved({ ...okved, [key]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ОКВЭД" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={okved.ref}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('ref')(option)}
            settings={{ name: 'RefOkved2', title: 'Справочник "ОКВЭД"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Срок действия" isRequired>
          <span>
            <TextDateTime
              value={okved.startDate || ''}
              onChange={(value: string) => setOkved({ ...okved, startDate: value || '' })}
              maxValue={okved.endDate || ''}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={okved.endDate || ''}
              onChange={(value: string) => setOkved({ ...okved, endDate: value || '' })}
              minValue={okved.startDate || ''}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={okved.note ?? ''} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

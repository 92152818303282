import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetReceivingDocumentItemList: Table.Specification = {
  apiID: 'GetReceivingDocumentItemList',
  header: {
    firstLevel: {
      title: 'Поступление средств на шифры проектов',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

import React, { useCallback } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextDateTime, UploadFile } from 'components';

import { getCurrentTime } from 'features/EditPartner/helpers';
import { Change } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';
import { FileInfo } from 'types/models';

type Props = {
  change: Change;
  setChange(change: Change): void;
  userName: string;
};

export function Fields(props: Props) {
  const { change, setChange, userName } = props;

  const makeHandler = (key: keyof Change) => (value: ValueOf<Change>) => {
    setChange({
      ...change,
      modifiedBy: userName,
      modifiedDate: getCurrentTime(),
      [key]: value,
    });
  };

  const onChangeFile = useCallback(
    (fileInfo: FileInfo) => {
      setChange({ ...change, file: fileInfo });
    },
    [change, setChange],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column style={{ width: '50%' }}>
            <FormComponent.Line>
              <FormComponent.Field label="Дата изменения" isRequired>
                <TextDateTime value={change.changeDate} onChange={makeHandler('changeDate')} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field label="Категория изменения" isRequired>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={change.category}
                  onChange={(option: ReferenceItem | null) => makeHandler('category')(option)}
                  settings={{ name: 'RefChangeCategory', title: 'Категории изменений' }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Описание">
          <TextArea settings={{ isFixed: true }} value={change.description ?? ''} onChange={makeHandler('description')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Файл">
          <UploadFile file={change?.file} onChange={onChangeFile} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setProjectCodeId(projectCodeId: string): void;
};

export function GetProjectCodeSimpleSelectList({ setProjectCodeId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetProjectCodeSimpleList',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ setProjectCodeId }),
  };
}

import { parse } from 'date-fns';

import { ContestRequest, Performer } from 'types/models/ContestRequest';
import { formatStr } from 'utils/Constants';

export function getMockContestRequest(): ContestRequest {
  return {
    approvements: [],
    code: '',
    id: null,
    indicators: [],
    indices: [],
    isRequestStageHasPatentResearch: false,
    okveds: [],
    project: undefined,
    requestStageAnnotation: '',
    requestStageCollaborations: '',
    requestStageCriticalTechnologies: [],
    requestStageEndDate: '',
    requestStageEstimatePositions: [],
    requestStageGoal: '',
    requestStageIndicators: [],
    requestStageIndices: [],
    requestStageKeyWords: '',
    requestStageKindWork: undefined,
    requestStageName: '',
    requestStageNtrStrategies: [],
    requestStageNumber: 0,
    requestStagePartnerEnterprises: '',
    requestStagePerformers: [],
    requestStagePlanAmount: '',
    requestStagePnis: [],
    requestStagePnmitrs: [],
    requestStagePnrs: [],
    requestStageOecds: [],
    requestStagePreviousYearAnnotation: '',
    requestStageRAN: '',
    requestStageStartDate: '',
    requestStageWorkPlan: '',
    status: undefined,
    documents: [],
    type: null,
  };
}

export function getHead(request: ContestRequest | null | undefined) {
  return request?.requestStagePerformers?.find(x => x.role?.value === 'LEADER') ?? null;
}

export function formatIndexHirsh(performer: Performer) {
  const sorted = (performer.person?.hirshIndexes || []).sort(
    (a, b) => parse(b.date || '', formatStr, new Date()).getTime() - parse(a.date || '', formatStr, new Date()).getTime(),
  );
  return (
    sorted
      .filter((x, index) => index === sorted.findIndex(y => y.citationSystem?.label === x.citationSystem?.label))
      .map(x => `${x.citationSystem?.label} - ${x.value || 0} (${x.date})`)
      .join(', ') ?? ''
  );
}

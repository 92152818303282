import { FormComponent, TextArea, TextAreaMode, TextDateTime, TextInput } from 'components';

import { TenderProtocol } from 'types/models/TenderProtocol';
import { useController } from './controller';
import { ProtocolCommission } from 'types/models/ProtocolCommission';
import { Table, Tender } from 'types/models';

type Props = {
  workMode: Table.WorkMode;
  tenderProtocol: TenderProtocol;
  changeTender(e: Tender.Tender | null): void;
  changeNumber(e: string): void;
  changeProtocolDate(e: string): void;
  changeProtocolCommission(e: ProtocolCommission | null): void;
};

export function ProtocolMainFields({
  workMode,
  tenderProtocol,
  changeTender,
  changeNumber,
  changeProtocolDate,
  changeProtocolCommission,
}: Props) {
  const {
    tenderSpecification,
    tenderModalTableRowConverter,
    protocolCommissionSpecification,
    protocolCommissionModalTableRowConverter,
    changeTenderObject,
  } = useController({ changeTender });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Номер протокола">
          <TextInput value={tenderProtocol.number || ''} onChange={changeNumber} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата протокола">
          <TextDateTime value={tenderProtocol.protocolDate} onChange={changeProtocolDate} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Конкурс" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Конкурс',
              isClearable: false,
              table: {
                specification: tenderSpecification,
                onSelect: (row: Table.Entry | null) => changeTenderObject(row ? tenderModalTableRowConverter(row) : null),
              },
            }}
            value={tenderProtocol?.tender?.name}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Конкурсная комиссия" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Конкурсная комиссия',
              isClearable: false,
              table: {
                specification: protocolCommissionSpecification,
                onSelect: (row: Table.Entry | null) =>
                  changeProtocolCommission(row ? protocolCommissionModalTableRowConverter(row) : null),
              },
            }}
            value={
              tenderProtocol.protocolCommission?.id
                ? // eslint-disable-next-line max-len
                  `${tenderProtocol.protocolCommission.name} (№${tenderProtocol.protocolCommission.orderNumber} от ${tenderProtocol.protocolCommission.orderDate})`
                : ''
            }
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

import { MB, MAX_UPLOAD_SIZE_MB } from 'shared/constants';
import { UploadImageParamsDimensions } from './';

type FileSizeProps = {
  file: File;
  size: number;
};

type FileDimensionsProps = {
  upload: UploadImageParamsDimensions;
  limits: UploadImageParamsDimensions;
};

type FileExtensionsArguments = {
  file: File;
  extensions: string[];
};

export const isSizeError = ({ file, size = MAX_UPLOAD_SIZE_MB }: FileSizeProps) => {
  return file.size >= size * MB;
};

export const isExtensionError = ({ file, extensions }: FileExtensionsArguments) => {
  return !extensions.includes(file.type.split('/').pop() as string);
};

export const isDimensionsError = ({ upload, limits }: FileDimensionsProps) => {
  return (upload.width || 0) > (limits.width || 0) || (upload.height || 0) > (limits.height || 0);
};

import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  articlePublicationIds: string[];
  onClose: () => void;
  sourcePublicationId: string;
  relatedTableState?: Table.State;
};

export const Component = memo(({ onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => {
  const {
    sourceMagazine,
    sourceMagazineTableConfig,
    sourceMagazineRelease,
    sourceMagazineReleaseTableConfig,
    modalTableRowToSourceMagazineReleaseConventer,
    changeSourceMagazineRelease,
    handleSubmit,
    changeSourceMagazine,
    modalTableRowToSourceMagazineConventer,
  } = useController({ articlePublicationIds, sourcePublicationId, onClose, relatedTableState });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Название журнала" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Журналы',
              isClearable: false,
              visibleStatus: getStatusRecordMessage('', sourceMagazine?.id, sourceMagazine?.status),
              table: {
                specification: sourceMagazineTableConfig,
                onSelect: (row: Table.Entry | null) =>
                  changeSourceMagazine(row ? modalTableRowToSourceMagazineConventer(row) : null),
              },
            }}
            value={sourceMagazine?.name}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Выпуск журнала">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Выпуск журнала',
              visibleStatus: getStatusRecordMessage('', sourceMagazineRelease?.id, sourceMagazineRelease?.status),
              table: {
                specification: sourceMagazineReleaseTableConfig,
                onSelect: (row: Table.Entry | null) =>
                  changeSourceMagazineRelease(row ? modalTableRowToSourceMagazineReleaseConventer(row) : null),
              },
            }}
            value={sourceMagazineRelease?.name}
            isDisabled={!sourceMagazine}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text="Продолжить"
          onClick={handleSubmit}
          isDisabled={sourceMagazine === null || sourceMagazineRelease === null}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
});

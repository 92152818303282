import React, { useCallback } from 'react';

import { FormComponent, ListEdit, Select, SelectMode, TextInput } from 'components';
import { showNotification } from 'features/Notifications';
import { Person } from 'types/models';
import { Item } from 'types/models/common';

type Props = {
  contacts?: Person.Contact[];
  setContacts?(contacts: Person.Contact[]): void;
  isDisabled?: boolean;
};

export const PersonContactList = React.memo(({ contacts, setContacts, isDisabled }: Props) => {
  const renderContactFields = useCallback(
    (contact: Person.Contact | null, setContact: React.Dispatch<React.SetStateAction<Person.Contact | null>>) => (
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Тип" labelWidth={100}>
            <Select
              mode={SelectMode.ENUM}
              value={contact?.type}
              onChange={(e: Item | null) => setContact(prev => ({ ...prev!, type: e }))}
              settings={{ name: 'PersonContactType' }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Контакт" labelWidth={100}>
            <TextInput value={contact?.value} onChange={e => setContact(prev => ({ ...prev!, value: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    ),
    [],
  );

  return (
    <ListEdit
      header={{ title: 'Контакты (телефон, e-mail)' }}
      rows={contacts || []}
      onChange={e => setContacts?.(e)}
      toolbar={!isDisabled && ['add', 'edit', 'delete']}
      columns={[
        { label: 'Тип', formatValue: e => e.type?.label || '', defaultSort: 'asc', styles: { width: '20%' } },
        { label: 'Контакт', formatValue: e => e.value || '' },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: renderContactFields,
        validation: {
          checkIsValid: row => !!row?.type?.value && !!row?.value,
          onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
        },
      }}
      withoutHead
    />
  );
});

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { Permits } from 'utils/Permissions/Permits';
import { Publication, Table, Report, Table as T } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { PublicationStatus } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';

type Props = {
  workMode?: T.WorkMode;
  tableState: Table.State;
  hideActionButtons?: boolean;
};

const controller = ({ tableState, workMode, hideActionButtons }: Props) => {
  const SUCH_LIKE_PUBLICATION_ID = 'PREPRINT_PUBLICATION_LIST';

  const token = getAuthToken();

  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const tableStreams = useLocalTableStreams();
  const { settings, userPermission, userSystemDepartment } = useAppDataContext();
  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled =
    !rowsAvailability.SINGLE_SELECTED ||
    workMode === 'viewMode' ||
    (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_DRAFTADDED) &&
      (tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.ADDED ||
        tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.DRAFT)) ||
    (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_APPROVED) &&
      tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.APPROVED);
  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isDownloadPublicationFileDisabled = !(
    rowsAvailability.SINGLE_SELECTED &&
    tableState.selectedRows.length &&
    tableState.selectedRows[0]?.FileId
  );
  const isFinancialSupportDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isDOIDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isLinkButtonDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isEditRelationsDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';
  const isCitationDisabled = !rowsAvailability.SINGLE_SELECTED || workMode === 'viewMode';

  const reports = useMemo<Report[]>(() => [Reports.AvtodissDetail, Reports.Dissertations, Reports.Dissertation], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isDoiPopupOpened, setIsDoiPopupOpened] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const [publication, setPublication] = useState<Publication | null>(null);

  const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById', {
    onSuccessfullCall: ({ data }) => {
      setPublication(data);
    },
  });

  const getPublication = useCallback((publicationId: string) => {
    getPublicationAPI.callAPI({ publicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);

  const getSelectedRow = useCallback(() => {
    if (tableState.selectedRows.length) {
      const [row] = tableState.selectedRows;
      return row;
    }
    return null;
  }, [tableState.selectedRows]);

  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleOpenFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(true);
  }, []);

  const handleCloseFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleApprovePopupOpen = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleApprovePopupClose = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = tableState.selectedRows[0]?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        handleApprovePopupClose();
      }
    },
    [handleApprovePopupClose, tableState.selectedRows, tableStreams.approveRow],
  );

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const handleEditRelations = useCallback(() => {
    const row = getSelectedRow();
    if (row) {
      getPublication(row.id);
      setIsOpenEditRelations(true);
    }
  }, [getSelectedRow, getPublication]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleOpenDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(true);
  }, []);

  const handleCloseDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(false);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = tableState.selectedRows[0]?.id;

    if (publicationId) {
      deletePublication({ publicationId });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, tableState.selectedRows]);

  const submitDoiPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PUBLICATION_ADD },
        isHidden: hideActionButtons,
        isDisabled: workMode === 'viewMode',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PUBLICATION_EDIT },
        isHidden: hideActionButtons,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isHidden: hideActionButtons,
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь публикации с подразделениями',
        onClick: handleEditRelations,
        permission: { name: Permits.PUBLICATION_DEPARTMENTS_EDIT },
        isHidden: hideActionButtons,
        isDisabled: isEditRelationsDisabled,
      },
      {
        icon: { type: 'statistic' },
        title: 'Показатели публикации',
        onClick: handleOpenCitationForm,
        permission: { name: Permits.PUBLICATION_CITATION_SYSTEM_INDEX_EDIT },
        isHidden: hideActionButtons,
        isDisabled: isCitationDisabled,
      },
      {
        icon: { type: 'like' },
        title: 'Утверждение публикации',
        onClick: handleApprovePopupOpen,
        permission: { name: Permits.PUBLICATION_APPROVE },
        isHidden: hideActionButtons,
        isDisabled: isApproveButtonDisabled,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть публикацию во внешнем источнике',
        expandedList: { list: getLinks },
        isHidden: hideActionButtons,
        isDisabled: isLinkButtonDisabled,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл прикрепленный к публикации',
        onClick: handleDownloadPublicationFile,
        isDisabled: isDownloadPublicationFileDisabled,
      },
      {
        icon: { type: 'badge' },
        title: 'Указать DOI статьи',
        onClick: handleOpenDoiPopup,
        isHidden: hideActionButtons,
        isDisabled: isDOIDisabled,
      },
      {
        icon: { type: 'currency' },
        title: 'Финансовая поддержка публикации',
        onClick: handleOpenFinancialSupport,
        permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT },
        isHidden: hideActionButtons,
        isDisabled: isFinancialSupportDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      workMode,
      getLinks,
      getReports,
      handleAddButtonClick,
      handleApprovePopupOpen,
      handleDeleteButtonClick,
      handleDownloadPublicationFile,
      handleEditButtonClick,
      handleEditRelations,
      handleOpenCitationForm,
      handleOpenDoiPopup,
      handleOpenFinancialSupport,
      handleSetCurrentReport,
      handleViewButtonClick,
      isApproveButtonDisabled,
      isCitationDisabled,
      isDOIDisabled,
      isDeleteButtonDisabled,
      isDownloadPublicationFileDisabled,
      isEditButtonDisabled,
      isEditRelationsDisabled,
      isFinancialSupportDisabled,
      isLinkButtonDisabled,
      isViewButtonDisabled,
      hideActionButtons,
    ],
  );

  return {
    SUCH_LIKE_PUBLICATION_ID,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isFinancialSupportDisabled,
    isDeleteButtonDisabled,
    isEditRelationsDisabled,
    isDOIDisabled,
    isApproveButtonDisabled,
    isCitationDisabled,
    nextPublicationName,
    publication,
    buttons,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isOpenEditRelations,
    isDeleteConfirmPopupOpen,
    isCitationFormOpen,
    isApprovePopupOpen,
    isDoiPopupOpened,
    isFinancialSupportOpen,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    handleCloseEditRelationsModal,
    handleConfirmDeleteConfirmPopup,
    handleCloseCitationForm,
    handleApprovePopupClose,
    handleCloseDoiPopup,
    handleCloseFinancialSupport,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    handleCloseDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    onApprove,
    handleContinueStep,
    submitDoiPopupCallback,
    submitFinancialPopupCallback,
    isReportOpen,
    onReportClose,
    currentReport,
    userSystemDepartment,
  };
};

export default controller;

import { convertMaybeArray, getText } from '../commonConverters';

export type AcceptSalaryProjectScientistAnswerType = {
  project: string;
  fundCard: string;
  code: string;
  text: string;
};

export function convertServerAcceptSalary(result: any): AcceptSalaryProjectScientistAnswerType[] {
  return convertMaybeArray(result.Fail ?? [], convertAcceptSalary);
}

export function convertAcceptSalary(fail: any): AcceptSalaryProjectScientistAnswerType {
  return {
    project: fail._attributes.project,
    fundCard: fail._attributes.fundCard,
    code: fail._attributes.code,
    text: getText(fail),
  };
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ReceivingDocumentItemGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Таблица «Реестр приходных документов бухгалтерского учёта» содержит список приходных документов, полученных из 1С:БГУ,
          или если отсутствует интеграция между системами приходных документов, созданных пользователями Системы.
        </p>
        <p>
          Фильтр <strong>Год</strong> – отображает список документов с датой, соответствующей указанному году.
        </p>
        <p>Основные столбцы таблицы содержат данные приходных документов.</p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание значения в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>№</td>
              <td>Порядковый номер записи.</td>
            </tr>
            <tr>
              <td>Номер</td>
              <td>Номер документа.</td>
            </tr>
            <tr>
              <td>Дата</td>
              <td>Дата документа.</td>
            </tr>
            <tr>
              <td>Контрагент (краткое название)</td>
              <td>Краткое название контрагента.</td>
            </tr>
            <tr>
              <td>О контрагенте</td>
              <td>Расширенные данные о контрагенте.</td>
            </tr>
            <tr>
              <td>Сумма документа</td>
              <td>Сумма документа.</td>
            </tr>
            <tr>
              <td>Зачислено на шифры</td>
              <td>Номера шифров и суммы, зачисленные на них.</td>
            </tr>
            <tr>
              <td>Примечание</td>
              <td>Примечание.</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <p>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</p>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с приходным документом. Открывает форму с данными о приходном
                {/*eslint-disable-next-line max-len*/}
                документе для просмотра. Состав формы зависит от наличия у пользователя <strong>
                  прав на просмотр вкладок
                </strong>{' '}
                формы.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>
                <p>Открывает форму добавления приходного документа.</p>
                <div className="warning">
                  Функционал доступен пользователю по правам. Использовать данный функционал только в период отсутствия интеграции
                  с ИС бухгалтерского учёта в соответствии со специально разработанным регламентом работ
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                <p>
                  Кнопка активна, если в таблице выделена запись. Открывает форму с данными о приходном документе для
                  редактирования. Состав формы зависит от наличия у пользователя{' '}
                  <strong>прав на просмотр и редактирования вкладок</strong> формы.
                </p>
                <div className="warning">
                  Функционал доступен пользователю по правам. Использовать данный функционал только в период отсутствия интеграции
                  с ИС бухгалтерского учёта в соответствии со специально разработанным регламентом работ
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                <p>Кнопка активна, если в таблице выделена запись с приходным документом.</p>
                <div className="warning">
                  Функционал доступен пользователю по правам. Использовать данный функционал только в период отсутствия интеграции
                  с ИС бухгалтерского учёта в соответствии со специально разработанным регламентом работ
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import { CommunityType } from 'utils/Enums';
import { DissertationCouncilGridHelp } from './help';

export type CustomProps = {
  communityTypes: CommunityType[];
};

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { communityTypes, isMembersTabHidden } = customState;

  const { settings } = useAppDataContext();

  const {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleCloseApproveConfirmPopup,
    handleConfirmApproveConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isDissertationCouncil,
  } = useController(tableState, communityTypes);

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.CommunityViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.label}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.CommunityEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.label}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
        />
      )}

      <modalTemplates.CommunityAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
        templateName={templateName}
        communityTypes={communityTypes}
        isMembersTabHidden={isMembersTabHidden}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.label}) Удалить сообщество?</>
        </Modal>
      )}

      {!isApproveButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title={isApproved ? 'Перевод в статус "Добавлено"' : 'Утверждение'}
          isOpen={isApproveConfirmPopupOpen}
          onClose={handleCloseApproveConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmApproveConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseApproveConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            {isApproved
              ? `(${tableState.selectedRows[0]?.label}) Перевести в статус "Добавлена"?`
              : `(${tableState.selectedRows[0]?.label}) Утвердить?`}
          </>
        </Modal>
      )}

      <Modal
        mode="help"
        title={`Помощь в ${isDissertationCouncil ? 'Диссертационных советах' : 'Научных сообществах и организациях'}`}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {DissertationCouncilGridHelp(isDissertationCouncil, settings?.organization?.shortName)}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

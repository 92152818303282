import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { PersonalCommunityMemberGridHelp } from './help';

type WrapperProps = {
  communitiesFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ communitiesFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      buttons,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      handleTemplateCloseAddForm,
      handleTemplateCloseEditForm,
      handleTemplateCloseViewForm,
      isAddFormOpen,
      isDeleteButtonDisabled,
      isDeleteConfirmPopupOpen,
      isEditButtonDisabled,
      isEditFormOpen,
      isViewButtonDisabled,
      isViewFormOpen,
      nextPublicationName,
      templateName,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
      communityTypes,
      organizationName,
    } = useController({ tableState, communitiesFilter });

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewButtonDisabled && (
          <modalTemplates.CommunityViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
          />
        )}

        {!isEditButtonDisabled && (
          <modalTemplates.CommunityEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
            memberEditLocked={true}
            communityTypes={communityTypes}
          />
        )}

        <modalTemplates.CommunityAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          templateName={templateName}
          memberEditLocked={true}
          communityTypes={communityTypes}
        />

        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: handleCloseDeleteConfirmPopup,
              },
            ]}
            size="small"
          >
            <>(сообщество: {tableState.selectedRows[0]?.Community || <em>Без названия</em>}) Удалить участие?</>
          </Modal>
        )}

        <Modal
          mode="help"
          title="Помощь в разделе Члены научных сообществ и организаций"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          {PersonalCommunityMemberGridHelp(organizationName)}
        </Modal>
      </>
    );
  });

import React, { memo } from 'react';

import { BuilderProps } from 'features/BuildReportPopup/models';
import { DefaultReference } from '../DefaultReference';

type Props = BuilderProps & object;

const ProjectCodeLetter = ({ parameterName, setParameterValueByName, parameters }: Props) => (
  <DefaultReference
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    referenceName="RefProjectCodeLetter"
    referenceRelationTableModalTitle='Справочник "Буквы шифров"'
    referenceFilter={[]}
  />
);

const Component = memo(ProjectCodeLetter);

export { Component as ProjectCodeLetter };

import React from 'react';

import {
  FormComponent,
  RefList,
  SectionTitle,
  Select,
  SelectMode,
  TextDateTime,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { ForeignProject } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';
import { SelectPerson } from 'features/SelectPerson';
import { SelectProgram } from './views/SelectProgram';
import { SelectProgramEvent } from './views/SelectProgramEvent';
import { FinancingListEdit } from './views/FinancingListEdit';
import { AffiliationSelect } from './views/AffiliationSelect';
import useController from './controller';

type Props = {
  isDisabled: boolean;
  foreignProject: ForeignProject.ForeignProject;
  foreignProjectErrors: Partial<Record<keyof ForeignProject.ForeignProject, boolean>>;
  makeChangeHandler: (key: keyof ForeignProject.ForeignProject) => (value: ValueOf<ForeignProject.ForeignProject>) => void;
  makeResetErrorHandler: (key: keyof ForeignProject.ForeignProject) => () => void;
};

export const AboutProject = ({
  makeChangeHandler,
  makeResetErrorHandler,
  foreignProject,
  foreignProjectErrors,
  isDisabled,
}: Props) => {
  const { onUpdatePerformer, onProgramSelect, onUpdateRangeDate } = useController({
    makeChangeHandler,
    foreignProject,
    makeResetErrorHandler,
  });
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="О проекте" />
        <FormComponent.Line>
          <FormComponent.Field label="Участник проекта" isRequired>
            <SelectPerson
              member={foreignProject.performer}
              onUpdateMember={onUpdatePerformer}
              hiddenButtons={['change', 'delete']}
              disabled
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Роль в проекте" isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={foreignProject.role}
              settings={{ name: 'ForeignProjectPerformerRole' }}
              onChange={(option: Item) => makeChangeHandler('role')(option as ValueOf<ForeignProject.ForeignProject>)}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Тема проекта">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Тема проекта' }}
              value={foreignProject.name ?? ''}
              onChange={makeChangeHandler('name')}
              onFocus={makeResetErrorHandler('name')}
              isDisabled={isDisabled}
              isError={foreignProjectErrors.name}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Номер проекта">
            <TextInput
              value={foreignProject.number}
              onFocus={makeResetErrorHandler('number')}
              onChange={makeChangeHandler('number')}
              isError={foreignProjectErrors.number}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Номер госрегистрации">
            <TextInput
              value={foreignProject.stateRegistrationNumber}
              onChange={makeChangeHandler('stateRegistrationNumber')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата госрегистрации">
            <TextDateTime
              value={foreignProject.stateRegistrationDate}
              onChange={makeChangeHandler('stateRegistrationDate')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Руководитель проекта">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Руководитель проекта' }}
              value={foreignProject.leader}
              onChange={makeChangeHandler('leader')}
              onFocus={makeResetErrorHandler('leader')}
              isDisabled={isDisabled}
              isError={foreignProjectErrors.leader}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата начала/окончания" isRequired>
            <span>
              <TextDateTime
                value={foreignProject.startDate || ''}
                onChange={(value: string) => onUpdateRangeDate('startDate', value)}
                maxValue={foreignProject.endDate || ''}
                isError={foreignProjectErrors.startDate || foreignProjectErrors.endDate}
                isDisabled={isDisabled}
              />
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span>
              <TextDateTime
                value={foreignProject.endDate || ''}
                onChange={(value: string) => onUpdateRangeDate('endDate', value)}
                minValue={foreignProject.startDate || ''}
                isError={foreignProjectErrors.startDate || foreignProjectErrors.endDate}
                isDisabled={isDisabled}
              />
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <AffiliationSelect
            affiliation={foreignProject.enterprise}
            setAffiliation={makeChangeHandler('enterprise')}
            isDisabled={isDisabled}
            modalTitle="Список организаций"
            label="Организация - исполнитель проекта"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <AffiliationSelect
            affiliation={foreignProject.customer}
            setAffiliation={makeChangeHandler('customer')}
            isDisabled={isDisabled}
            modalTitle="Список организаций"
            label="Заказчик"
            tooltip="Организация, которая профинансировала проект"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <SelectProgram program={foreignProject.program} setProgram={onProgramSelect} isDisabled={isDisabled} />
        </FormComponent.Line>
        <FormComponent.Line>
          <SelectProgramEvent
            programEvent={foreignProject.programEvent}
            setProgramEvent={makeChangeHandler('programEvent')}
            isDisabled={isDisabled}
            programId={foreignProject.program?.value}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Общий объём финансирования">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={foreignProject.amount ?? ''}
              onChange={makeChangeHandler('amount')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Примечание">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Примечание' }}
              value={foreignProject.note ?? ''}
              onChange={makeChangeHandler('note')}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefList
            header={{ title: 'ГРНТИ' }}
            rows={foreignProject.grntis}
            onChange={makeChangeHandler('grntis')}
            toolbar={['add', 'edit', 'delete']}
            isDisabled={isDisabled}
            modalTitle="ГРНТИ"
            refName="RefGrnti"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FinancingListEdit
            isDisabled={isDisabled}
            financings={foreignProject.financings}
            setFinancings={makeChangeHandler('financings')}
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
};

import React from 'react';

import { ButtonMode, FormComponent, Modal } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { RefFeedlineCommand } from 'types/models';
import { EventTypeCode } from 'types/models/Event';
import { SuchLikeMode } from 'types/models/SuchLikePublication';
import { useController } from './controller';
import { Component as SuchLikeEventComponent } from './component';
import { eventNameMap } from './helpres';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  eventTypeCode: EventTypeCode;
  eventName?: string;
  title?: string;
  mode?: SuchLikeMode;
  handleCloseOuterForm?: () => void;
  isSearchable?: boolean;
  handleContinueStep?: (searchText: string) => void;
  command: RefFeedlineCommand;
  isStrictSearch?: boolean;
  startDate?: string;
  endDate?: string;
};

export const SuchLikeEventComponentContainer = ({
  eventTypeCode,
  title,
  eventName,
  isSearchable,
  handleContinueStep,
  command,
  mode,
  handleCloseOuterForm,
  isStrictSearch,
  startDate,
  endDate,
}: Props) => {
  const {
    isOpen,
    onClose,
    isMessagePopupOpen,
    suchLikePublicationList,
    onMessagePopupOpen,
    publicationMessage,
    updatePublicationMessage,
    isConfirmPopupOpen,
    updateIsConfirmPopupOpen,
    selectedRowIndex,
    setSelectedRowIndex,
    publicationMessageType,
    setPublicationMessageType,
    onConfirmClick,
    onConfirmWithoutMessageClick,
    isLoading,
    sendEmptyMessageWarning,
    isEqualDuplicatePopupOpen,
    handleCloseEqualDuplicatePopup,
    acceptEqualDuplicate,
  } = useController({
    isStrictSearch,
    startDate,
    endDate,
    eventTypeCode,
    handleCloseOuterForm,
  });

  const { settings } = useAppDataContext();

  const [equalItem] = suchLikePublicationList;

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        title={
          title ||
          (mode === 'add'
            ? `Поиск дубликатов ${eventNameMap[eventTypeCode].genitive}`
            : `Похожие ${eventNameMap[eventTypeCode].genitive}`)
        }
        size="large"
      >
        <FormComponent.Description mode="warning">
          Внимание! Прежде чем добавить новое мероприятие, введите его название для поиска. Возможно, данное мероприятие уже есть
          в базе {settings?.organization?.shortName}.
        </FormComponent.Description>

        <SuchLikeEventComponent
          eventTypeCode={eventTypeCode}
          isSearchableComponent={isSearchable}
          suchLikePublicationList={suchLikePublicationList}
          onClose={onClose}
          onMessagePopupOpen={onMessagePopupOpen}
          isConfirmPopupOpen={isConfirmPopupOpen}
          updateIsConfirmPopupOpen={updateIsConfirmPopupOpen}
          mode={mode}
          selectedRowIndex={selectedRowIndex}
          setSelectedRowIndex={setSelectedRowIndex}
          customContinueHandle={handleContinueStep}
          startDate={startDate}
          eventName={eventName}
          isLoading={isLoading}
          sendEmptyMessageWarning={sendEmptyMessageWarning}
        />
      </Modal>

      <AdministratorMessagePopup
        title={ADD_PUB_FEEDBACK_LABEL}
        isOpen={isMessagePopupOpen}
        publicationMessage={publicationMessage}
        updatePublicationMessage={updatePublicationMessage}
        publicationMessageType={publicationMessageType}
        setPublicationMessageType={setPublicationMessageType}
        onConfirm={onConfirmClick}
        onConfirmWithoutMessage={onConfirmWithoutMessageClick}
        command={command}
      />

      <Modal
        mode="warning"
        title="Попытка сохранить дубликат"
        isOpen={isEqualDuplicatePopupOpen}
        onClose={acceptEqualDuplicate}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Отменить сохранение мероприятия',
            onClick: handleCloseEqualDuplicatePopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: acceptEqualDuplicate,
          },
        ]}
        size="small"
      >
        {equalItem && (
          <>
            <p>Найден точный дубликат</p>
            <p>
              <strong>Мероприятие</strong>:
            </p>

            <ul>
              <li>Тип: ${equalItem.type.label},</li>
              <li>Название: ${equalItem.name},</li>
              <li>
                Период: ${equalItem.startDate} - ${equalItem.endDate},
              </li>
              <li>Создано: ${equalItem.createdBy.name}`;</li>
            </ul>
          </>
        )}
      </Modal>
    </>
  );
};

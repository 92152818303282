import * as XML from 'xml-js';
import * as converters from './converters';

import { ScientificIndex, Table, UserPermission } from 'types/models';
import { filterSystemEnums } from 'utils/Helpers';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';
import { convertReference } from './commonConverters';

function elementCheck(x: any): any {
  if (x.Element?.[0] === undefined) {
    const tmp = x;
    const e = x.Element;
    delete tmp.Element;
    tmp.Element = [];
    tmp.Element.push(e);
    return tmp;
  }
  return x;
}

export const appDataConfigurations = {
  GetCurrentUserSystemDepartment: makeBackendAPIConfiguration({
    id: 'GetCurrentUserSystemDepartment',
    endpoint: '/msa/service/commands/GetCurrentUserSystemDepartment',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetCurrentUserSystemDepartment' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertReference(response.Response.CurrentUserSystemDepartment),
  }),
  GetScientifiIndice: makeBackendAPIConfiguration({
    id: 'GetScientifiIndices',
    endpoint: '/msa/service/commands/GetScientifiIndices',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetScientifiIndices' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ScientificIndex.ScientificIndex[] => {
      const indexes = response.Response.ScientificIndex;
      return Array.isArray(indexes)
        ? indexes.map(converters.convertScientificIndex)
        : [converters.convertScientificIndex(indexes)];
    },
  }),
  GetAllSystemEnum: makeBackendAPIConfiguration({
    id: 'GetAllSystemEnum',
    endpoint: '/msa/service/commands/GetAllSystemEnum',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetAllSystemEnum',
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): Table.EnumMap => {
      const enums: Table.Enum[] = response.Response.Enumerations.Enumeration.map(
        (x: any): Table.Enum => ({
          name: x._attributes.name,
          values: elementCheck(x)?.Element.map(
            (y: any): Table.EnumValue =>
              !!y && y._attributes
                ? {
                    ...y._attributes,
                    value: y._text,
                  }
                : {},
          ),
        }),
      );

      const enumMap = enums.reduce<Table.EnumMap>((acc, x) => ({ ...acc, [x.name]: x }), {});
      const preparedEnumMap = filterSystemEnums({ enumMap });
      return preparedEnumMap;
    },
  }),
  GetCurrentUser: makeBackendAPIConfiguration({
    id: 'GetCurrentUser',
    endpoint: '/msa/service/commands/GetCurrentUser',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetCurrentUser' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): UserPermission => {
      const serverPermissions = response.Response.User.Permission || [];
      const isSuperUser = response.Response.User._attributes.superuser === 'true';
      const userName = response.Response.User._attributes?.name || '';
      const userLogin = response.Response.User._attributes?.login || '';
      const permissions = Array.isArray(serverPermissions)
        ? serverPermissions.map(x => x._attributes.name)
        : [serverPermissions._attributes.name];

      return { isSuperUser, permissions, userName, userLogin };
    },
  }),
  DeleteUser: makeBackendAPIConfiguration({
    id: 'DeleteUser',
    endpoint: '/msa/service/commands/DeleteUser',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'DeleteUser',
          },
          User: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

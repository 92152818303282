import { GetPublicationOtherListRequest } from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

import { Component as LeftPanelForSpecification } from './LeftPanelForSpecification';

type Props = {
  hasSelectButton?: boolean;
};
export const GetPublicationOtherListSpecification = (
  requestData: GetPublicationOtherListRequest & Props,
): Table.Specification => ({
  apiID: 'GetPublicationOtherList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForSpecification,
      hasSelectButton: requestData.hasSelectButton,
    },
  },
});

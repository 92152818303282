import React, { useCallback, useMemo } from 'react';

import {
  ReferenceItem,
  TextArea,
  TextAreaMode,
  TextInput,
  SectionTitle,
  Select,
  SelectMode,
  FormComponent,
  Checkbox,
  TextDateTime,
} from 'components';

import { MipPartner, Table } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetLksdEnterpriseTypeBySectorActivity } from 'features/Table/specifications';
import { PartnerStateCode } from 'utils/Enums/PartnerStateCode';

type Props = {
  mipPartner: MipPartner.MipPartner | null;
  makeChangeHandler: (key: keyof MipPartner.MipPartner) => (value: ValueOf<MipPartner.MipPartner>) => void;
  setMipPartner: React.Dispatch<MipPartner.MipPartner | null>;
  disabled: boolean;
};

export function UpperFields(props: Props) {
  const { setMipPartner, makeChangeHandler, mipPartner, disabled } = props;

  const isMipPartnerStateTerminated = useMemo(() => mipPartner?.state?.customFields?.code === PartnerStateCode.TERMINATED, [
    mipPartner?.state?.customFields?.code,
  ]);

  const onChangePerson = useCallback(
    (member: Member) => {
      makeChangeHandler('manager')(member.person);
    },
    [makeChangeHandler],
  );

  const changeActivitySector = useCallback(
    (nextActivitySector: ReferenceItem | null) => {
      setMipPartner({
        ...mipPartner!,
        activitySector: nextActivitySector,
        lksd: null,
      });
    },
    [mipPartner, setMipPartner],
  );

  return (
    <>
      <SectionTitle title="Ответственные за ведение карточки организации-партнёра" />

      <FormComponent.Line>
        <FormComponent.Field
          label="Отв. менеджер"
          isRequired
          // eslint-disable-next-line
          tooltip="К ответственному менеджеру можно обращаться за дополнительной информацией по организации, например, запросить Соглашение о сотрудничестве"
        >
          <SelectPerson
            member={getMemberWithoutHistory(mipPartner?.manager ?? null)}
            onUpdateMember={onChangePerson}
            hiddenButtons={['delete']}
            withHistory={false}
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          label="Отв. подразделение"
          tooltip="Подразделение несёт ответственность за актуальность ключевых данных об организации- партнёре"
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={mipPartner?.department}
            onChange={option => makeChangeHandler('department')(option as ValueOf<MipPartner.MipPartner>)}
            settings={{ name: 'RefDepartment', title: 'Статусы организаций' }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Об организации" />

      {mipPartner?.parent?.id && (
        <FormComponent.Line>
          <FormComponent.Field label="Головная организация">
            <div>
              {mipPartner.parent.fullName} (ID - {mipPartner.parent.id}. {mipPartner.relation?.label ?? ''})
            </div>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field
          // eslint-disable-next-line max-len
          tooltip='Для иностранных организаций Вводите в формате "Сокращённое название организации на оригинальном языке (Сокращённое название на русском языке)'
          label="Краткое название"
        >
          <TextInput value={mipPartner?.shortName ?? ''} onChange={makeChangeHandler('shortName')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          // eslint-disable-next-line max-len
          tooltip='Для международных организаций Вводите в формате "Полное название организации на оригинальном языке (Полное название на русском языке)'
          label="Полное название"
        >
          <TextArea
            settings={{ isFixed: true }}
            value={mipPartner?.fullName ?? ''}
            onChange={makeChangeHandler('fullName')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сектор деятельности">
          <Select
            mode={SelectMode.REFERENCE}
            value={mipPartner?.activitySector}
            onChange={changeActivitySector}
            settings={{ name: 'RefSectorActivity', title: 'Сектор деятельности', isClearable: true }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип организации">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Типы организаций',
              table: {
                specification: GetLksdEnterpriseTypeBySectorActivity({ sectorActivityId: mipPartner?.activitySector?.id ?? '' }),
                onSelect: (row: Table.Entry | null) =>
                  makeChangeHandler('lksd')({ id: row?.id || '', label: row?.EnterpriseType || '' }),
              },
            }}
            value={mipPartner?.lksd?.label}
            isDisabled={disabled || !mipPartner?.activitySector?.id}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="">
          <Checkbox
            label="по ФЗ 217"
            checked={mipPartner?.isFl217}
            onChange={makeChangeHandler('isFl217')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Checkbox
            label="по ФЗ 273"
            checked={mipPartner?.isFl273}
            onChange={makeChangeHandler('isFl273')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата регистрации">
          <TextDateTime
            value={mipPartner?.registrationDate}
            onChange={makeChangeHandler('registrationDate')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Состояние">
          <Select
            mode={SelectMode.REFERENCE}
            value={mipPartner?.state}
            onChange={option => makeChangeHandler('state')(option as ValueOf<MipPartner.MipPartner>)}
            settings={{ name: 'RefEnterpriseStatus', title: 'Состояния' }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isMipPartnerStateTerminated && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Дата ликвидации" isRequired>
              <TextDateTime
                value={mipPartner?.terminatedDate}
                onChange={makeChangeHandler('terminatedDate')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                settings={{ isFixed: true }}
                value={mipPartner?.terminatedNote ?? ''}
                onChange={makeChangeHandler('terminatedNote')}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </>
  );
}

import React from 'react';

import { FormComponent, ListEdit, TextDateTime, TextInput, TextInputMode } from 'components';

import { getMockFounder, getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { MipPartner } from 'types/models';
import { showErrorsMessages } from 'utils/Common';

import { Fields as FounderFields } from './Fields';
import { validate } from './validate';
import useController from './controller';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  charterCapital: MipPartner.CharterCapital;
  setCharterCapital: (charterCapital: MipPartner.CharterCapital) => void;
};

export const Fields = ({ charterCapital, setCharterCapital }: Props) => {
  const { makeHandler } = useController({ charterCapital, setCharterCapital });

  const { settings } = useAppDataContext();

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Уставной капитал" isRequired>
          <TextInput mode={TextInputMode.NUMBER} value={charterCapital.amount ?? ''} onChange={makeHandler('amount')} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата изменения" isRequired>
          <TextDateTime value={charterCapital.editDate} onChange={makeHandler('editDate')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ставка налога с дивидендов,%" isRequired>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={charterCapital.dividendTaxPercent ?? ''}
            onChange={makeHandler('dividendTaxPercent')}
          />
        </FormComponent.Field>
        <span></span>
      </FormComponent.Line>

      <FormComponent.Line>
        <ListEdit
          header={{ title: 'Учредители', isRequired: true }}
          rows={charterCapital.founders}
          onChange={makeHandler('founders')}
          toolbar={['add', 'edit', 'delete']}
          columns={[
            {
              label: 'ФИО/Название организации',
              formatValue: x =>
                x.isLocal
                  ? settings?.organization?.shortName || ''
                  : (x.scientist?.fullName || x.enterpriseCustomer?.label) ?? <em>Не указано</em>,
              styles: { width: '35%' },
            },
            {
              label: 'Доля в УК, %',
              formatValue: x => getPercentOfAmountByPart({ part: x.amount, amount: charterCapital.amount }),
              styles: { width: '15%' },
              dataKind: 'float',
            },
            { label: 'Сумма, руб.', formatValue: x => x.amount, styles: { width: '15%' }, dataKind: 'float' },
            { label: 'Активы', formatValue: x => x.insertionAssets || <em>Не указано</em>, styles: { width: '35%' } },
          ]}
          withMessages
          isSaveAndAddCustomComponent
          isDeleteConfirmEnabled
          defaultRowsCount={10}
          maxHeight="none"
          columnIndexesForSumTotal={[1, 2]}
          specification={{
            mode: 'customComponent',
            renderComponent: (founder, setFounder) => (
              <FounderFields founder={founder ?? getMockFounder()} setFounder={setFounder} amount={charterCapital.amount} />
            ),
            validation: {
              checkIsValid: (founder, index, list, mode) => validate(founder, index, list, mode).every(x => x.isValid),
              onInvalidate: (founder, mode, index, list) => {
                const validationInfo = validate(founder, index, list, mode);
                showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
              },
            },
          }}
        />
      </FormComponent.Line>
    </FormComponent.Template>
  );
};

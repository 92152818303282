import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { Table, Document, SecurityDocumentContract, Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { IdItem } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { validate } from '../validate';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [endDate, setEndDate] = useState<string>('');
  const [conclusionDate, setConclusionDate] = useState<string>('');
  const [number, setNumber] = useState<string>('');
  const [paymentCondition, setPaymentCondition] = useState<string>('');
  const [price, setPrice] = useState<string>('');
  const [startDate, setStartDate] = useState<string>('');
  const [isTransfered, setIsTransfered] = useState<boolean>(false);
  const [enterpriseCustomer, setEnterpriseCustomer] = useState<ReferenceItem | null>(null);
  const [kind, setKind] = useState<ReferenceItem | null>(null);
  const [paymentKind, setPaymentKind] = useState<ReferenceItem | null>(null);
  const [payments, setPayments] = useState<SecurityDocumentContract.Payment[]>([]);
  const [securityDocuments, setSecurityDocuments] = useState<IdItem<string>[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [registrationDate, setRegistrationDate] = useState<string>('');
  const [registrationNumber, setRegistrationNumber] = useState<string>('');
  const [note, setNote] = useState<string>('');

  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { userToken } = useAppDataContext();

  const { methods: getSecurityDocumentContract } = BackendAPI.useBackendAPI('GetSecurityDocumentContract', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as SecurityDocumentContract.SecurityDocumentContract;
      setPaymentCondition(preparedData.paymentCondition);
      setNumber(preparedData.number);
      setStartDate(preparedData.startDate);
      setEndDate(preparedData.endDate);
      setConclusionDate(preparedData.conclusionDate);
      setSecurityDocuments(
        preparedData.securityDocuments?.map(doc => {
          return { id: doc.id || '', value: doc.label || '' };
        }) || [],
      );
      setPrice(preparedData.price);
      setIsTransfered(preparedData.isTransfered);
      setEnterpriseCustomer(preparedData.enterpriseCustomer);
      setKind(preparedData.kind);
      setPaymentKind(preparedData.paymentKind);
      setPayments(preparedData.payments);
      setDocuments(preparedData.documents);
      if (preparedData.enterpriseCustomer) handleEnterpriseCustomerFieldChange(preparedData.enterpriseCustomer);
      setRegistrationDate(preparedData.registrationDate || '');
      setRegistrationNumber(preparedData.registrationNumber || '');
      setNote(preparedData.note || '');
    },
  });

  const { methods: saveSecurityDocumentContract } = BackendAPI.useBackendAPI('SaveSecurityDocumentContract', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success)
        showNotification({ message: 'Договор на использование ИС успешно сохранен', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const enterpriseCustomerLabel = useMemo(() => {
    return [
      enterpriseCustomer?.customFields?.fullName || '',
      [
        enterpriseCustomer?.customFields?.inn ? `ИНН: ${enterpriseCustomer.customFields?.inn}` : '',
        enterpriseCustomer?.customFields?.kpp ? `КПП: ${enterpriseCustomer.customFields?.kpp}` : '',
      ]
        .filter(Boolean)
        .join(', '),
      [
        (enterpriseCustomer?.customFields?.country as ReferenceItem)?.label,
        enterpriseCustomer?.customFields?.postCode,
        enterpriseCustomer?.customFields?.district,
        enterpriseCustomer?.customFields?.city,
        enterpriseCustomer?.customFields?.street,
      ]
        .filter(Boolean)
        .join(', '),
    ]
      .filter(Boolean)
      .join('\n');
  }, [enterpriseCustomer]);

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      kindId: kind?.id,
      startDate,
      endDate,
      conclusionDate,
      enterpriseCustomerId: enterpriseCustomer?.id,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }

    saveSecurityDocumentContract.callAPI({
      id: id || null,
      documents,
      endDate,
      conclusionDate,
      enterpriseCustomer,
      isTransfered,
      kind,
      number,
      paymentCondition,
      paymentKind,
      startDate,
      price,
      payments,
      registrationDate,
      registrationNumber,
      note,
      securityDocuments,
    });
    return true;
  }, [
    number,
    kind,
    startDate,
    endDate,
    conclusionDate,
    enterpriseCustomer,
    price,
    saveSecurityDocumentContract,
    id,
    documents,
    isTransfered,
    paymentCondition,
    paymentKind,
    payments,
    registrationDate,
    registrationNumber,
    note,
    securityDocuments,
  ]);

  const handlePaymentConditionFieldChange = useCallback((value: string) => {
    setPaymentCondition(value);
  }, []);

  const handleNumberFieldChange = useCallback((value: string) => {
    setNumber(value);
  }, []);

  const handleIsTransferedFieldChange = useCallback((e: boolean) => {
    setIsTransfered(e);
  }, []);

  const handlePriceFieldChange = useCallback((e: string) => {
    setPrice(e);
  }, []);

  const enterpriseCustomerRowConverter = useCallback<(row: T.Entry) => ReferenceItem>(row => {
    return {
      id: row.id,
      name: `${row.Name}`,
      label: `${row.Name}`,
    };
  }, []);

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const handleEnterpriseCustomerFieldChange = useCallback(
    (e: ReferenceItem | null) => {
      if (!e) {
        setEnterpriseCustomer(null);
        return;
      }
      loadReferenceAPI.callAPI(
        { filters: [], referenceName: 'RefEnterpriseCustomer', childIds: [e.id] },
        {
          onSuccessfullCall: ({ data }) => {
            setEnterpriseCustomer(data[0] || null);
          },
        },
      );
    },
    [loadReferenceAPI],
  );

  const handlePaymentKindFieldChange = useCallback((e: ReferenceItem) => {
    setPaymentKind(e);
  }, []);

  const handleKindFieldChange = useCallback((e: ReferenceItem) => {
    setKind(e);
  }, []);

  const handleConclusionDateChange = useCallback((value: string | null) => {
    if (value !== null) {
      setConclusionDate(value);
    } else {
      setConclusionDate('');
    }
  }, []);

  const handleSecurityDocumentsChange = useCallback((value: IdItem[] | null) => {
    setSecurityDocuments(value || []);
  }, []);

  const handlePaymentsFieldChange = useCallback((e: any) => {
    const value = e as SecurityDocumentContract.Payment[];
    setPayments(value);
  }, []);

  const handleDocumentsFieldChange = useCallback((e: any) => {
    const value = e as Document[];
    setDocuments(value);
  }, []);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleRegistrationDateChange = useCallback((value: string | null) => {
    setRegistrationDate(value || '');
  }, []);

  const handleRegistrationNumberChange = useCallback((value: string) => {
    setRegistrationNumber(value);
  }, []);

  const handleNoteChange = useCallback((nextValue: string) => {
    setNote(nextValue || '');
  }, []);

  useLayoutEffect(() => {
    if (id) getSecurityDocumentContract.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    documents,
    endDate,
    handleDocumentsFieldChange,
    handleEnterpriseCustomerFieldChange,
    handleFormSubmit,
    handleIsTransferedFieldChange,
    handleKindFieldChange,
    handleNumberFieldChange,
    handlePaymentConditionFieldChange,
    handlePaymentKindFieldChange,
    handlePriceFieldChange,
    isTransfered,
    kind,
    number,
    paymentCondition,
    paymentKind,
    price,
    startDate,
    workMode,
    payments,
    handlePaymentsFieldChange,
    userToken,
    id,
    conclusionDate,
    handleConclusionDateChange,
    handleRegistrationDateChange,
    handleRegistrationNumberChange,
    registrationDate,
    registrationNumber,
    note,
    handleNoteChange,
    securityDocuments,
    handleSecurityDocumentsChange,
    enterpriseCustomerRowConverter,
    setStartDate,
    setEndDate,
    enterpriseCustomer,
    enterpriseCustomerLabel,
  };
}

import React from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { NotificationLogGridHelp } from 'features/Table/specifications/GetNotificationLogList/LeftPanelForThirdLevel/help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isHelpOpen,
    setIsHelpOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    notificationLog,
    setNotificationLog,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={`Просмотр уведомления от ${notificationLog?.date}: ${notificationLog?.theme}`}
        isOpen={isViewFormOpen}
        onClose={() => {
          setIsViewFormOpen(false);
          setNotificationLog(null);
        }}
        size="medium"
      >
        <div dangerouslySetInnerHTML={{ __html: notificationLog?.body || '' }} />
      </Modal>

      <Modal
        mode="help"
        title={NotificationLogGridHelp('HELP_LK').header}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {NotificationLogGridHelp('HELP_LK').body}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import { ButtonProps, FormComponent, Toolbar } from 'components';

import { InputAttributes } from 'types/models/common';
import MaskedInput from 'react-text-mask';

import { NumberMask, NumberProps } from './masks/NumberMask';
import { YearMask } from './masks/YearMask';
import { IssnMask } from './masks/IssnMask';
import { IsbnMask } from './masks/IsbnMask';
import { SnilsMask } from './masks/SnilsMask';

import { showNotification } from 'features/Notifications';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const w = block('text-input-wrapper');
const b = block('text-input');

const MAX_SYMBOLS = 4096;

type TextProps = {
  /** @property {number} type - (optional) Max length of string */
  maxLength?: number;
};

type LimitsProps = {
  /** @property {number} min - (optional) Min value */
  min?: number;
  /** @property {number} max - (optional) Max value */
  max?: number;
};

export enum TextInputMode {
  /** Any text */
  TEXT = 'text',
  /** Any password */
  PASSWORD = 'password',
  /** Any number (int, float) */
  NUMBER = 'number',
  /** Day only */
  DAY = 'day',
  /** Month only */
  MONTH = 'month',
  /** Year only */
  YEAR = 'year',
  /** Site URL */
  URL = 'url',
  /** ISSN */
  ISSN = 'issn',
  /** ISBN */
  ISBN = 'isbn',
  /** DOI */
  DOI = 'doi',
  /** SNILS */
  SNILS = 'snils',
}

/**
 * Settings for input component
 */
export type Props = InputAttributes & {
  /** @property {TextInputMode} type - (optional) Type of Mask (TextInputMode.TEXT by default) */
  mode?: TextInputMode;
  /** @property {TextProps | NumberProps | LimitsProps} settings - (optional) Settings for type TextInputMode */
  settings?: any;
  /** @property {string} value - (optional) Value for input */
  value?: string;
  /** @property {function} onChange - (optional) Callback on text changed */
  onChange?(val: string): void;
  /** @property {boolean} isDisabled - (optional) Set is disabled input (false by default) */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) Set is error input (false by default) */
  isError?: boolean;
  /** @property {boolean} isClearable - (optional) Show button of clear (false by default) */
  isClearable?: boolean;
  /** @property {ButtonProps[]} externalButtons - (optional) Extra buttons */
  externalButtons?: ButtonProps[];
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
} & (
    | {
        mode?: TextInputMode.TEXT;
        /** @property {TextProps} settings - (optional) Settings for text */
        settings?: TextProps;
      }
    | {
        mode: TextInputMode.NUMBER;
        /** @property {NumberProps} settings - (optional) Settings for number */
        settings?: NumberProps & LimitsProps;
      }
    | {
        mode: TextInputMode.YEAR;
        /** @property {LimitsProps} settings - (optional) Settings for year */
        settings?: LimitsProps;
      }
    | {
        mode:
          | TextInputMode.PASSWORD
          | TextInputMode.DAY
          | TextInputMode.MONTH
          | TextInputMode.URL
          | TextInputMode.ISSN
          | TextInputMode.ISBN
          | TextInputMode.DOI
          | TextInputMode.SNILS;
      }
  );

/**
 * Text input component
 *
 * @param {TextInputMode} mode - (optional) Type of Mask (TextInputMode.TEXT by default)
 * @param {TextProps | NumberProps | YearProps} settings - (optional) Settings for type TextInputMode
 * @param {string} value - (optional) Value for input
 * @param {function} onChange - (optional) Callback on text changed
 * @param {boolean} isDisabled - (optional) Set is disabled input (false by default)
 * @param {boolean} isError - (optional) Set is error input (false by default)
 * @param {boolean} isClearable - (optional) Show button of clear (false by default)
 * @param {ButtonProps[]} externalButtons - (optional) Extra buttons
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const TextInput = React.memo((props: Props) => {
  const {
    mode = TextInputMode.TEXT,
    settings = {},
    onClick,
    onFocus,
    onBlur,
    onChange,
    isDisabled = false,
    isError = false,
    isClearable = false,
    externalButtons = [],
    classMixin,
    value,
    ...restProps
  } = props;

  const [valueState, setValueState] = useState<string>(value || '');

  useLayoutEffect(() => {
    setValueState(value || '');
  }, [value]);

  const className = useMemo(
    () => b({ 'is-disabled': isDisabled, 'is-error': isError, 'is-clickabled': !!onClick }).mix(classMixin),
    [classMixin, isDisabled, isError, onClick],
  );

  const getValue = (e: React.ChangeEvent<HTMLInputElement> | React.FocusEvent<HTMLInputElement>) => e.target.value;

  const [toolbarOffset, setToolbarOffset] = useState<string>('');
  const refInput = useRef<MaskedInput | null>(null);
  const refToolbar = useRef<HTMLDivElement>();

  useEffect(() => {
    setToolbarOffset(`${(refToolbar.current?.clientWidth || 2) + 6}px`);
  }, []);

  const buttons: ButtonProps[] = [
    ...externalButtons,
    {
      icon: { type: 'clear' },
      title: 'Удалить',
      isHidden: !isClearable,
      isDisabled: isDisabled || (!!valueState.trim && !valueState.trim()),
      onClick: () => {
        const val = mode === TextInputMode.NUMBER ? '0' : '';
        setValueState(val);
        onChange?.(val);
      },
    },
  ];

  const calculatedProps = {
    readOnly: isDisabled,
    disabled: isDisabled && !onClick,
    onClick: onClick,
    style: { paddingRight: toolbarOffset },
  };

  // TextInputMode.TEXT | TextInputMode.DOI
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val: string = getValue(e);

    const { maxLength = MAX_SYMBOLS } = settings as TextProps;

    if (maxLength && val.length > maxLength) {
      showNotification({
        message: `Превышено максимально количество символов (${maxLength}), сократите строку`,
        theme: 'danger',
      });
      onChange?.(val.slice(0, maxLength));
      return;
    }

    onChange?.(val);
    setValueState(val);
  };

  // Default focus (Remove value if ['0', '0.0', '0.00', ao])
  let onFocusCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    const val: string = getValue(e);

    if (mode === TextInputMode.NUMBER) {
      const valNumber = val.replaceAll(',', '.').replaceAll(' ', '');

      if (Number(valNumber) === parseFloat(valNumber) && onChange) {
        if (parseFloat(valNumber)) {
          setValueState(Number(valNumber).toString());
          onChange(Number(valNumber).toString());
        } else {
          setValueState('');
          onChange('');
        }
      }
    }

    onFocus?.(e);
  };

  // Default blur
  let onBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
    onBlur?.(e);
  };

  // TextInputMode.NUMBER
  if (mode === TextInputMode.NUMBER) {
    const { min, max = Math.pow(10, 15) } = settings;

    const handleNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      let val: string = getValue(e);

      val = val.replaceAll(',', '.').replaceAll(' ', '');

      if (onChange) {
        if (!!val ? !isNaN(Number(val)) : true) {
          // float bypas of mask
          if (settings.decimal !== false && val.slice(-1) === '.') {
            return;
          }

          if (typeof min === 'number' && Number(val) < min) {
            setValueState(min.toString());
            onChange(min.toString());
            // selection bypas of mask
            setTimeout(() => {
              if (refInput.current) {
                (refInput.current.inputElement as HTMLInputElement).select();
              }
            }, 0);
          } else if (typeof max === 'number' && Number(val) > max) {
            setValueState(max.toString());
            onChange(max.toString());
          } else {
            setValueState(!!val ? Number(val).toString() : val);
            onChange(!!val ? Number(val).toString() : val);
          }

          return;
        } else {
          // natural bypas of mask
          if (settings.isNatural === false && val === '-') {
            setTimeout(() => {
              if (refInput.current) {
                (refInput.current.inputElement as HTMLInputElement).selectionStart = 1;
              }
            }, 0);
          }
        }
      }
      setValueState(val);
    };

    // Hack for recalc of MaskedInput props
    const settedValue = useMemo<boolean>(() => !!valueState, [valueState]);

    return (
      <div className={w({}).mix(classMixin)}>
        <MaskedInput
          ref={refInput}
          className={className}
          onInput={handleNumberChange}
          onFocus={onFocusCustom}
          onBlur={onBlurCustom}
          value={valueState}
          {...NumberMask(settings as NumberProps, settedValue)}
          {...calculatedProps}
          {...(restProps as InputAttributes)}
        />
        <Toolbar refToolbar={refToolbar} buttons={buttons} />
      </div>
    );
  }

  // TextInputMode.DAY | TextInputMode.MONTH
  if (mode === TextInputMode.DAY || mode === TextInputMode.MONTH) {
    const handleDayMonthChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val: string = getValue(e);

      if (onChange && (!!val ? Number(val).toString() === val : true) && (val.length === 0 || val.length === 2)) {
        if (mode === TextInputMode.DAY && Number(val) > 31) {
          onChange('31');
        } else if (mode === TextInputMode.MONTH && Number(val) > 12) {
          onChange('12');
        } else {
          onChange(val);
        }
      }
      setValueState(val);
    };

    // Hack for recalc of MaskedInput props
    const settedValue = useMemo<boolean>(() => !!valueState, [valueState]);

    return (
      <div className={w({}).mix(classMixin)}>
        <MaskedInput
          className={className}
          onChange={handleDayMonthChange}
          onFocus={onFocusCustom}
          onBlur={onBlurCustom}
          value={valueState}
          {...NumberMask({ isThousands: false }, settedValue)}
          {...calculatedProps}
          {...(restProps as InputAttributes)}
        />
        <Toolbar refToolbar={refToolbar} buttons={buttons} />
      </div>
    );
  }

  // TextInputMode.YEAR
  if (mode === TextInputMode.YEAR) {
    const { min, max } = settings;

    const handleYearChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const val: string = getValue(e);

      if (onChange && (!!val ? Number(val).toString() === val : true) && (val.length === 0 || val.length === 4)) {
        if (typeof min === 'number' && Number(val) < min) {
          onChange(min.toString());
        } else if (typeof max === 'number' && Number(val) > max) {
          onChange(max.toString());
        } else {
          onChange(val);
        }
      }
      setValueState(val);
    };

    return (
      <div className={w({}).mix(classMixin)}>
        <MaskedInput
          className={className}
          onChange={handleYearChange}
          onFocus={onFocusCustom}
          onBlur={onBlurCustom}
          value={valueState}
          {...YearMask()}
          {...calculatedProps}
          {...(restProps as InputAttributes)}
        />
        <Toolbar refToolbar={refToolbar} buttons={buttons} />
      </div>
    );
  }

  // TextInputMode.ISSN | TextInputMode.ISBN | TextInputMode.SNILS
  const modeMask = {
    [TextInputMode.ISSN]: IssnMask,
    [TextInputMode.ISBN]: IsbnMask,
    [TextInputMode.SNILS]: SnilsMask,
  };

  if (Object.keys(modeMask).includes(mode)) {
    onBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
      handleChange(e);
      onBlur?.(e);
    };

    return (
      <div className={w({}).mix(classMixin)}>
        <MaskedInput
          className={className}
          onChange={handleChange}
          onBlur={onBlurCustom}
          value={valueState}
          {...Object.values(modeMask)[Object.keys(modeMask).indexOf(mode)]()}
          {...calculatedProps}
          {...(restProps as InputAttributes)}
        />
        <Toolbar refToolbar={refToolbar} buttons={buttons} />
      </div>
    );
  }

  // TextInputMode.URL
  if (mode === TextInputMode.URL) {
    if (isDisabled) {
      return (
        <FormComponent.Link href={valueState}>
          <>{valueState.length > 60 ? `${valueState.slice(0, 60)}...` : valueState}</>
        </FormComponent.Link>
      );
    }

    buttons.unshift({
      icon: { type: 'view' },
      title: 'Перейти',
      isDisabled: !valueState.length,
      onClick: () => {
        window.open(valueState, '_blank');
      },
    });
  }

  // TextInputMode.DOI
  if (mode === TextInputMode.DOI) {
    const DOI_START_SEQUENCE = '10.';
    const DOI_MAX_LENGTH = 100;

    useEffect(() => {
      if (!!valueState && valueState.length >= 3 && valueState.indexOf(DOI_START_SEQUENCE) !== 0) {
        showNotification({
          message: `DOI должен начинаться с "${DOI_START_SEQUENCE}"`,
          theme: 'danger',
        });
        return;
      }

      if (valueState.length > DOI_MAX_LENGTH) {
        showNotification({
          message: 'Максимальная длина DOI 100 символов',
          theme: 'danger',
        });
        onChange?.(valueState.slice(0, DOI_MAX_LENGTH));
        return;
      }
    }, [onChange, valueState]);

    onBlurCustom = (e: React.FocusEvent<HTMLInputElement>) => {
      const val: string = getValue(e);

      if (val.length <= 3) {
        setValueState('');
        onChange?.('');
      }
      onBlur?.(e);
    };
  }

  return (
    <div className={w({}).mix(classMixin)}>
      <input
        type={mode === TextInputMode.PASSWORD ? TextInputMode.PASSWORD : TextInputMode.TEXT}
        className={className}
        onChange={handleChange}
        onFocus={onFocusCustom}
        onBlur={onBlurCustom}
        title={mode === TextInputMode.PASSWORD ? '' : valueState}
        value={valueState}
        {...calculatedProps}
        {...{ style: { paddingRight: toolbarOffset } }}
        {...(restProps as InputAttributes)}
      />
      <Toolbar refToolbar={refToolbar} buttons={buttons} />
    </div>
  );
});

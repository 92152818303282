import React, { useEffect } from 'react';

import { FormComponent, Toolbar, TextArea, TextAreaMode } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { getMockBlock } from './helpers';

import './style.scss';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function NotificationForm({ viewMode, editMode, onClose }: Props) {
  const { notification, setNotification, buttons } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(`Редактирование уведомления "${notification.event?.label || ''}"`);
  }, [notification.event?.label, notification.id, setTitle]);

  return (
    <>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Wrapper>
        <FormComponent.Description>
          <strong>Дополнительная информация для текста уведомления:</strong>
        </FormComponent.Description>
        <TextArea
          mode={TextAreaMode.WYSIWYG}
          settings={{ rows: 10 }}
          value={notification.blocks[0]?.data}
          onChange={e =>
            setNotification(prev => ({
              ...prev,
              blocks: [{ ...(prev.blocks[0] || { ...getMockBlock(), name: prev.event?.label, position: '0' }), data: e }],
            }))
          }
          classMixin="notification_text"
        />
      </FormComponent.Wrapper>
    </>
  );
}

export const Component = React.memo(NotificationForm);

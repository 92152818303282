import { useCallback } from 'react';

import { ReferenceItem } from 'components';

import { Department } from 'types/models/Department';

type Props = {
  department: Department | null;
  onDepartmentChange(row: Department): void;
};

export function useController({ department, onDepartmentChange }: Props) {
  const makeChangeHandler = useCallback(
    (fieldName: keyof Department) => (value: ReferenceItem) => {
      onDepartmentChange({ ...department, [fieldName]: value } as Department);
    },
    [onDepartmentChange, department],
  );

  return {
    makeChangeHandler,
  };
}

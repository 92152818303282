import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetFullUserList(): Table.Specification {
  return {
    apiID: 'GetFullUserList',
    header: {
      firstLevel: {
        title: 'Список пользователей',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    uniqKeys: ['id', 'Login'],
  };
}

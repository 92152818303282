import React from 'react';
import { DataGrid } from 'features/Table';
import { GetMagazineArticleList } from 'features/Table/specifications';
import { FormComponent } from 'components';
import useController from './controller';

const MagazineArticle = () => {
  const { relatedTableState } = useController();

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        {!!relatedTableState && (
          <DataGrid
            specification={GetMagazineArticleList({
              templatesTableDependencies: {
                relatedTableAPIID: 'GetMagazineList',
                relatedTableState,
                relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
              },
              workMode: 'editMode',
            })}
          />
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = React.memo(MagazineArticle);

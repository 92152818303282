import * as BackendAPI from 'services/BackendAPI';

import { Button, ButtonMode, Checkbox, FormComponent, ListEditTable, Modal, Select, SelectMode } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { getStageLabel } from 'features/Form/looks/project/ProjectForm/helpers';
import { useEffect, useState } from 'react';
import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ProjectReportBlock, ProjectReportType } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';

type Props = {
  isOpen: boolean;
  onClose(): void;
  project: Project.Project;
};

type StageLocalType = {
  id: string | null;
  label: string;
  isStateRegistration: boolean;
  type: Item | null;
  isAnyReportExists: boolean;
};

export function ProcessProjectReports({ isOpen, onClose, project }: Props) {
  const { enumMap } = useAppDataContext();

  const [stages, setStages] = useState<StageLocalType[]>([]);

  const { methods: processProjectReports } = BackendAPI.useBackendAPI('ProcessProjectReports', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Сохранено', theme: 'success' });
      onClose();
    },
  });

  useEffect(() => {
    if (isOpen) {
      const initialStages = project.stages
        .sort((a, b) => Number(a.number) - Number(b.number))
        .map<StageLocalType>(projectStage => {
          const reportsWithSameStage = project.reports.filter(
            projectReport => projectReport.stage?.number === projectStage.number,
          );
          return {
            id: projectStage.id,
            label: getStageLabel(projectStage),
            isStateRegistration: reportsWithSameStage.some(
              projectReport => projectReport.block?.value === ProjectReportBlock.STATE_REGISTRATION,
            ),
            type: getEnumItem(
              'ProjectReportType',
              reportsWithSameStage.some(projectReport => projectReport.type?.value === ProjectReportType.TOTAL)
                ? ProjectReportType.TOTAL
                : ProjectReportType.INTERMEDIATE,
              enumMap,
            ),
            isAnyReportExists: !!reportsWithSameStage.length,
          };
        });
      setStages(initialStages);
    } else {
      setStages([]);
    }
  }, [enumMap, isOpen, project.reports, project.stages]);

  return (
    <>
      <Modal title="Составить график госрегистрации отчётов проекта" isOpen={isOpen} onClose={onClose} size="medium">
        <FormComponent.Wrapper>
          <ListEditTable
            rows={stages}
            columns={[
              { label: 'Этап', formatValue: x => x.label },
              {
                label: 'Госрегистрация',
                formatValue: (x, i) => (
                  <Checkbox
                    checked={x.isStateRegistration}
                    onChange={e =>
                      setStages(prev => {
                        const newStages = [...prev];
                        newStages[i].isStateRegistration = e;
                        return newStages;
                      })
                    }
                  />
                ),
                dataKind: 'jsx',
                styles: { width: '5%' },
              },
              {
                label: 'Тип отчёта',
                formatValue: (x, i) => (
                  <Select
                    mode={SelectMode.ENUM}
                    value={x.type}
                    onChange={(e: Item | null) =>
                      setStages(prev => {
                        const newStages = [...prev];
                        newStages[i].type = e;
                        return newStages;
                      })
                    }
                    settings={{ name: 'ProjectReportType' }}
                  />
                ),
              },
              { label: 'Есть в графике', formatValue: x => x.isAnyReportExists, styles: { width: '5%' } },
            ]}
            selectedRowIndex={null}
            selectRow={() => {}}
          />
          <FormComponent.Actions>
            <Button
              mode={ButtonMode.PRIMARY}
              text="Сохранить план по отчёта проекта"
              onClick={() => {
                processProjectReports.callAPI({
                  id: project.id || '',
                  stages: stages.map(x => ({
                    id: x.id || '',
                    block: x.isStateRegistration ? ProjectReportBlock.STATE_REGISTRATION : ProjectReportBlock.STAGE,
                    type: x.type?.value || '',
                  })),
                });
              }}
            />
            <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
          </FormComponent.Actions>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { FileInfo, Form, Document, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Tender, Customer, Lot } from 'types/models/Tender';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validateContractAmounts, validateTender } from 'features/Form/looks/tender/TenderForm/validate';
import { TenderRequestType } from 'utils/Enums';
import { getMockLot, getMockTender } from './helpers';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, requestType: initialRequestType, nirTenderTypes: initialNirTenderTypes },
  } = useFormContext<Form.TenderFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [customers, setCustomers] = useState<Customer[]>([]);
  const [categories, setCategories] = useState<ReferenceItem[]>([]);
  const [participants, setParticipants] = useState<ReferenceItem[]>([]);
  const [scienceDomainInterrests, setScienceDomainInterrests] = useState<ReferenceItem[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [isConfirmSavePopupOpen, setIsConfirmSavePopupOpen] = useState<boolean>(false);
  const [needsClose, setNeedsClose] = useState<boolean>(false);
  const [confirmSavePopupText, setIsConfirmSavePopupText] = useState<string>('');
  const [programId, setProgramId] = useState<string>('');
  const [tenderId, setTenderId] = useState<string>('');
  const [programEventId, setProgramEventId] = useState<string>('');
  const [programEventCode, setProgramEventCode] = useState<string>('');
  const [programShortName, setProgramShortName] = useState<string>('');
  const [tender, setTender] = useState<Tender>(getMockTender({ initialRequestType, initialNirTenderTypes, isSpecial: true }));
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const [formFields, setFormFields] = useState<Form.Fields>({
    type: {
      value: { label: '', value: '' },
      isValid: true,
      required: false,
      title: 'Вид тендера',
      onChange: (value: Table.EnumValue) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, value },
        }));
      },
    },
    collectiveType: {
      value: { label: 'Любой', value: 'ANY' },
      isValid: true,
      required: false,
      title: 'Тип коллектива',
      onChange: (value: Table.EnumValue) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          collectiveType: { ...prevState.collectiveType, value },
        }));
      },
    },
    program: {
      value: null,
      isValid: true,
      required: tender.requestType?.value === TenderRequestType.NIR_REQUEST,
      title: 'Программа',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          program: { ...prevState.program, value },
          programEvent: { ...prevState.programEvent, value: null },
        }));
      },
    },
    programEvent: {
      value: null,
      isValid: true,
      required: tender.requestType?.value === TenderRequestType.NIR_REQUEST,
      title: 'Мероприятие программы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          programEvent: { ...prevState.programEvent, value },
        }));
      },
    },
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название конкурса',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      required: false,
      title: 'Описание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    kindTrade: {
      value: '',
      isValid: true,
      required: false,
      title: 'Форма торгов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kindTrade: { ...prevState.kindTrade, value },
        }));
      },
    },
    url: {
      value: '',
      isValid: true,
      title: 'Ссылка на конкурс',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          url: { ...prevState.url, value },
        }));
      },
    },
    dateUniversity: {
      value: '',
      isValid: true,
      required: !tender.isSpecial,
      title: 'Подать заявку на согласование консультанту до',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          dateUniversity: { ...prevState.dateUniversity, value },
        }));
      },
    },
    announcementDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата объявления',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          announcementDate: { ...prevState.announcementDate, value },
        }));
      },
    },
    isMarketplace: {
      value: false,
      isValid: true,
      required: false,
      title: '',
      onChange: (nextValue: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isMarketplace: { ...prevState.isMarketplace, value: nextValue },
          ...(!nextValue
            ? {
                marketplace: { ...prevState.marketplace, value: null },
                marketplaceNote: { ...prevState.marketplaceNote, value: '' },
                marketplaceAmount: { ...prevState.marketplaceAmount, value: '0.00' },
              }
            : {}),
        }));
      },
    },
    marketplace: {
      value: null,
      isValid: true,
      required: false,
      title: 'Название ЭТП',
      onChange: (nextValue: ReferenceItem | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          marketplace: { ...prevState.marketplace, value: nextValue?.id ? nextValue : null },
        }));
      },
    },
    requestsPlan: {
      value: null,
      isValid: true,
      required: false,
      title: 'План по заявкам',
      onChange: (nextValue: string) => {
        setFormFields((prev: Form.Fields) => ({
          ...prev,
          requestsPlan: { ...prev.requestsPlan, value: nextValue },
        }));
      },
    },
    marketplaceNote: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дополнительная информация',
      onChange: (nextValue: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          marketplaceNote: { ...prevState.marketplaceNote, value: nextValue },
        }));
      },
    },
    marketplaceAmount: {
      value: '',
      isValid: true,
      required: false,
      title: 'Сумма',
      onChange: (nextValue: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          marketplaceAmount: { ...prevState.marketplaceAmount, value: nextValue },
        }));
      },
    },
    dateOpenEnvelope: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата вскрытия конвертов',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          dateOpenEnvelope: { ...prevState.dateOpenEnvelope, value },
        }));
      },
    },
    dateExamenation: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата рассмотрения заявок',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          dateExamenation: { ...prevState.dateExamenation, value },
        }));
      },
    },
    contactFace: {
      value: '',
      isValid: true,
      title: 'Адрес',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contactFace: { ...prevState.contactFace, value },
        }));
      },
    },
    timeOpenEnvelope: {
      value: '',
      isValid: true,
      title: 'Время (МСК)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          timeOpenEnvelope: { ...prevState.timeOpenEnvelope, value },
        }));
      },
    },
    dateSummingUp: {
      value: '',
      isValid: true,
      required: true,
      title: 'Дата подведения итогов',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          dateSummingUp: { ...prevState.dateSummingUp, value },
        }));
      },
    },
    logo: {
      value: EMPTY_FILE,
      isValid: true,
      title: 'Логотип программы',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          logo: {
            ...prevState.logo,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
  });

  const { methods: getTender } = BackendAPI.useBackendAPI('GetTender', {
    onSuccessfullCall: ({ data }) => {
      setTender(data);
      const preparedData = data as Tender;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        type: {
          ...prevState.type,
          value: preparedData.type,
        },
        collectiveType: {
          ...prevState.collectiveType,
          value: preparedData.collectiveType,
        },
        program: {
          ...prevState.program,
          value: preparedData.program,
        },
        programEvent: {
          ...prevState.programEvent,
          value: preparedData.programEvent,
        },
        name: {
          ...prevState.name,
          value: preparedData.name,
        },
        description: {
          ...prevState.description,
          value: preparedData.description,
        },
        kindTrade: {
          ...prevState.kindTrade,
          value: preparedData.kindTrade,
        },
        url: {
          ...prevState.url,
          value: preparedData.url,
        },
        dateUniversity: {
          ...prevState.dateUniversity,
          value: preparedData.dateUniversity,
        },
        contactFace: {
          ...prevState.contactFace,
          value: preparedData.contactFace,
        },
        dateOpenEnvelope: {
          ...prevState.dateOpenEnvelope,
          value: preparedData.dateOpenEnvelope,
        },
        dateExamenation: {
          ...prevState.dateExamenation,
          value: preparedData.dateExamenation,
        },
        timeOpenEnvelope: {
          ...prevState.timeOpenEnvelope,
          value: preparedData.timeOpenEnvelope,
        },
        dateSummingUp: {
          ...prevState.dateSummingUp,
          value: preparedData.dateSummingUp,
        },
        announcementDate: {
          ...prevState.announcementDate,
          value: preparedData.announcementDate,
        },
        isMarketplace: {
          ...prevState.isMarketplace,
          value: preparedData.isMarketplace,
        },
        marketplace: {
          ...prevState.marketplace,
          value: preparedData.marketplace,
        },
        marketplaceNote: {
          ...prevState.marketplaceNote,
          value: preparedData.marketplaceNote,
        },
        marketplaceAmount: {
          ...prevState.marketplaceAmount,
          value: preparedData.marketplaceAmount || '0.00',
        },
        logo: {
          ...prevState.logo,
          value: preparedData.logo,
        },
        requestsPlan: {
          ...prevState.requestsPlan,
          value: preparedData.requestsPlan || '0',
        },
      }));
      setCustomers(preparedData.customers);
      setParticipants(preparedData.participants);
      setCategories(preparedData.categories);
      setScienceDomainInterrests(preparedData.scienceDomainInterrests);
      setDocuments(preparedData.documents);
      setTenderId(preparedData.id || '');
      setProgramId(preparedData.program?.id || '');
      setProgramEventId(preparedData.programEvent?.id || '');
      setProgramEventCode(preparedData.programEvent?.code || '');
      setProgramShortName(preparedData.program?.shortName || '');
    },
  });

  const { methods: saveTender } = BackendAPI.useBackendAPI('SaveTender');

  const handleSave = useCallback(
    (needClose?: boolean) => {
      saveTender.callAPI(
        {
          ...tender,
          id: id || tenderId || null,
          name: formFields.name.value,
          description: formFields.description.value,
          type: formFields.type.value,
          collectiveType: formFields.collectiveType.value,
          program: formFields.program.value,
          programEvent: formFields.programEvent.value,
          customers,
          categories,
          participants,
          scienceDomainInterrests,
          kindTrade: formFields.kindTrade.value,
          dateUniversity: formFields.dateUniversity.value,
          contactFace: formFields.contactFace.value,
          dateOpenEnvelope: formFields.dateOpenEnvelope.value,
          timeOpenEnvelope: formFields.timeOpenEnvelope.value,
          dateExamenation: formFields.dateExamenation.value,
          dateSummingUp: formFields.dateSummingUp.value,
          announcementDate: formFields.announcementDate.value,
          isMarketplace: formFields.isMarketplace.value,
          marketplace: formFields.marketplace.value,
          marketplaceNote: formFields.marketplaceNote.value,
          marketplaceAmount: formFields.marketplaceAmount.value,
          url: formFields.url.value,
          documents,
          logo: formFields.logo.value,
          requestsPlan: formFields.requestsPlan.value,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Конкурс успешно сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              getTender.callAPI({ id: data.id });
              setTenderId(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      saveTender,
      tender,
      id,
      tenderId,
      formFields.name.value,
      formFields.description.value,
      formFields.type.value,
      formFields.collectiveType.value,
      formFields.program.value,
      formFields.programEvent.value,
      formFields.kindTrade.value,
      formFields.dateUniversity.value,
      formFields.contactFace.value,
      formFields.dateOpenEnvelope.value,
      formFields.timeOpenEnvelope.value,
      formFields.dateExamenation.value,
      formFields.dateSummingUp.value,
      formFields.announcementDate.value,
      formFields.isMarketplace.value,
      formFields.marketplace.value,
      formFields.marketplaceNote.value,
      formFields.marketplaceAmount.value,
      formFields.url.value,
      formFields.logo.value,
      formFields.requestsPlan.value,
      customers,
      categories,
      participants,
      scienceDomainInterrests,
      documents,
      tableStreams.reloadTable,
      onClose,
      getTender,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const addDefaultLot = useCallback(
    () =>
      ({
        ...getMockLot(),
        startDate: formFields.dateSummingUp.value || '',
        theme: formFields.name.value || '',
        contractCount: 1,
        number: '1',
      } as Lot),
    [formFields.dateSummingUp.value, formFields.name.value],
  );

  const onSubmit = useCallback(
    (needClose: boolean) => {
      setNeedsClose(needClose);

      if (
        (tender.requestType?.value === TenderRequestType.MOBILE_REQUEST ||
          tender.requestType?.value === TenderRequestType.PROGRAM_REQUEST) &&
        !tender.isSpecial &&
        !tender.lots.length
      ) {
        tender.lots.push(addDefaultLot());
      }

      const validationInfo = validateTender(tender, formFields, tender.requestType?.value!);
      if (validationInfo.some(x => !x.isValid)) {
        validationInfo.forEach(({ isValid, invalidMessage }) => {
          if (!isValid) {
            setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
          }
        });
        return;
      }

      const lotValidationResult = validateContractAmounts(tender.lots);

      if (lotValidationResult.some(x => !x.isValid)) {
        const validationText = lotValidationResult
          .filter(r => !r.isValid)
          .map(m => m.invalidMessage)
          .join(' ');
        setIsConfirmSavePopupText(`${validationText}. Продолжить?`);
        setIsConfirmSavePopupOpen(true);
      } else {
        handleSave(needClose);
      }
    },
    [tender, formFields, addDefaultLot, handleSave],
  );

  useLayoutEffect(() => {
    if (id) {
      getTender.callAPI({ id });
    }
    // eslint-disable-next-line
  }, []);

  useLayoutEffect(() => {
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      dateUniversity: {
        ...prevState.dateUniversity,
        required: !tender.isSpecial,
      },
    }));
  }, [tender.isSpecial]);

  const handleFormSubmit = useCallback(
    (needClose: boolean) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  const handleCustomersFieldChange = useCallback(
    (value: Customer[]) => {
      setCustomers(value);
    },
    [setCustomers],
  );

  const handleDocumentsFieldChange = useCallback(
    (value: Document[]) => {
      setDocuments(value);
    },
    [setDocuments],
  );

  const handleLotsChange = useCallback((value: Lot[]) => {
    setTender(prev => ({ ...prev, lots: value }));
  }, []);

  const handleCategoriesChange = useCallback(
    (value: ReferenceItem[]) => {
      setCategories(value);
    },
    [setCategories],
  );

  const handleParticipantsChange = useCallback(
    (value: ReferenceItem[]) => {
      setParticipants(value);
    },
    [setParticipants],
  );

  const handleScienceDomainInterrestChange = useCallback(
    (value: ReferenceItem[]) => {
      setScienceDomainInterrests(value);
    },
    [setScienceDomainInterrests],
  );

  const handleProgramIdChange = useCallback(
    (value: string) => {
      setProgramId(value);
    },
    [setProgramId],
  );

  const handleProgramEventIdChange = useCallback(
    (value: string) => {
      setProgramEventId(value);
    },
    [setProgramEventId],
  );

  const handleConfirmSaveConfirm = useCallback(
    (needClose: boolean) => {
      setIsConfirmSavePopupOpen(false);
      handleSave(needClose);
    },
    [setIsConfirmSavePopupOpen, handleSave],
  );

  const handleConfirmSaveClose = useCallback(() => {
    setIsConfirmSavePopupOpen(false);
  }, [setIsConfirmSavePopupOpen]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => handleFormSubmit(true),
        isDisabled: !!viewMode,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => handleFormSubmit(false),
        isDisabled: !!viewMode,
      },
    ],
    [handleFormSubmit, viewMode],
  );

  return {
    programId,
    programEventId,
    customers,
    documents,
    categories,
    participants,
    scienceDomainInterrests,
    formFields,
    handleCustomersFieldChange,
    handleDocumentsFieldChange,
    handleParticipantsChange,
    handleScienceDomainInterrestChange,
    handleLotsChange,
    handleCategoriesChange,
    tenderId,
    handleProgramIdChange,
    handleProgramEventIdChange,
    workMode,
    programEventCode,
    programShortName,
    confirmSavePopupText,
    isConfirmSavePopupOpen,
    handleConfirmSaveConfirm,
    handleConfirmSaveClose,
    needsClose,
    tender,
    setTender,
    setCustomers,
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}

import { Table } from 'types/models';

type Args = {
  subjectId: string;
};

type RequestData = {
  subjectId: string;
};

export function GetSubjectSecurityDocumentContractList({ subjectId }: Args): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetSubjectSecurityDocumentContractList',
    header: {
      firstLevel: {
        title: 'Договоры о распоряжении интеллектуальным правом',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { subjectId },
  };
}

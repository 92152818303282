import { Table } from 'types/models';
import { Filters } from './filters';
import { LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

export const GetProjectStateRegistrationReportList: Table.Specification = {
  apiID: 'GetProjectStateRegistrationReportList',
  header: {
    firstLevel: {
      title: 'График госрегистрации отчётов',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: LeftPanelForThirdLevel(),
      withTemplatesPanel: true,
    },
  },
  highlightRow: (row: Table.Entry) => {
    if (row.IsExpired === 'true') return Color.danger;
    if (row.IsExpiring === 'true') return Color.success;
    return '';
  },
};

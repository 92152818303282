import { useMemo, useCallback, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState.selectedRows]);

  const onAddClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const onCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const onViewClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const onCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const onEditClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const onCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const onDeleteClick = useCallback(() => {
    setIsDeleteWarningOpen(true);
  }, []);

  const onCloseDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, []);

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (row?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeleteEntity',
        deletedItemPropName: 'ForeignProject',
      });
    }
  }, [row?.id, tableStreams.deleteRow]);

  const isEditButtonDisabled = tableState.selectedRows.length !== 1;
  const isDeleteButtonDisabled = tableState.selectedRows.length !== 1;
  const isViewButtonDisabled = tableState.selectedRows.length !== 1;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: onViewClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: onAddClick,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: onEditClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: onDeleteClick,
        isDisabled: isDeleteButtonDisabled,
      },
    ],
    [isEditButtonDisabled, isDeleteButtonDisabled, isViewButtonDisabled, onViewClick, onAddClick, onEditClick, onDeleteClick],
  );

  return {
    buttons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    row,
    isViewFormOpen,
    onViewClick,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
  };
};

export default useController;

import { Equip } from 'types/models';

import {
  convertMaybeArray,
  getId,
  getText,
  convertReference,
  convertDocument,
  convertItem,
  convertFile,
  getBoolean,
} from '../commonConverters';
import { convertProgram } from '../program/converters';
import { convertServerPerson } from '../author/converters';
import { Settings } from 'types/models/common';
import { convertServerPartner } from '../partners/converters';
import { sortById } from 'utils/Helpers';

export function convertEquip(equip: any, settings: Settings): Equip.Equip {
  const result: Equip.Equip = {
    id: getId(equip),
    name: getText(equip.Name),
    manufacturerName: getText(equip.ManufacturerName),
    factoryNumber: getText(equip.FactoryNumber),
    stateNumber: getText(equip.StateNumber),
    stateUrl: getText(equip.StateUrl),
    model: getText(equip.Model),
    country: equip.Country ? convertReference(equip.Country) : null,
    manufactureYear: getText(equip.ManufactureYear),
    commissioningDate: getText(equip.CommissioningDate),
    disposeDate: getText(equip.DisposeDate),
    manufacturer: equip.Manufacturer ? convertReference(equip.Manufacturer) : null,
    analog: getText(equip.Analog),
    specifications: getText(equip.Specifications),
    usefulPeriod: getText(equip.UsefulPeriod),
    descriptionNote: getText(equip.DescriptionNote),
    classificator: equip.Classificator ? convertReference(equip.Classificator) : null,
    okved: equip.Okved ? convertReference(equip.Okved) : null,
    okpd: equip.Okpd ? convertReference(equip.Okpd) : null,
    okof: equip.Okof ? convertReference(equip.Okof) : null,
    ktru: equip.Ktru ? convertReference(equip.Ktru) : null,
    ckp: equip.Ckp ? convertServerPartner(equip.Ckp, settings) : null,
    contractNumber: getText(equip.ContractNumber),
    contractDate: getText(equip.ContractDate),
    amount: getText(equip.Amount),
    inventoryNumber: getText(equip.InventoryNumber),
    nfaInventoryCardNumber: getText(equip.NfaInventoryCardNumber),
    eisNumber: getText(equip.EisNumber),
    eisUrl: getText(equip.EisUrl),
    program: equip.Program ? convertProgram(equip.Program) : null,
    purchaseNote: getText(equip.PurchaseNote),
    installLocations: sortById(
      convertMaybeArray(equip.InstallLocations?.InstallLocation || [], e => convertInstallLocation(e, settings)),
    ),
    contacts: sortById(convertMaybeArray(equip.Contacts?.Contact || [], e => convertContact(e, settings))),
    useCategories: sortById(convertMaybeArray(equip.UseCategories?.UseCategory || [], convertReference)),
    documents: sortById([
      ...convertMaybeArray(equip.Documents?.Document || [], e => convertDocument('local', e)),
      ...convertMaybeArray(equip.ForeignDocuments?.Document || [], e => convertDocument('foreign', e)),
    ]),
    status: equip.Status ? convertItem(equip.Status) : null,
    purpose: getText(equip.Purpose),
    employeesProvisionConditions: getText(equip.EmployeesProvisionConditions),
    outsidersProvisionConditions: getText(equip.OutsidersProvisionConditions),
    purchaseYear: getText(equip.PurchaseYear),
    enterprise: equip.Enterprise ? convertReference(equip.Enterprise) : null,
    parentKitContent: equip.ParentKitContent ? convertKitContent(equip.ParentKitContent, settings) : null,
    kitContents: sortById(convertMaybeArray(equip.KitContents?.KitContent || [], e => convertKitContent(e, settings))),
    investmentSources: sortById(convertMaybeArray(equip.InvestmentSources?.InvestmentSource || [], convertInvestmentSource)),
    balances: sortById(convertMaybeArray(equip.Balances?.Balance || [], e => convertBalance(e, settings))),
    photos: sortById(convertMaybeArray(equip.Photos?.Photo || [], convertPhoto)),
    equipKind: equip.EquipKind ? convertReference(equip.EquipKind) : null,
    measureKind: equip.MeasureKind ? convertReference(equip.MeasureKind) : null,
    measureLimit: getText(equip.MeasureLimit),
    measureScaleAmount: getText(equip.MeasureScaleAmount),
    permissibleInaccuracy: getText(equip.PermissibleInaccuracy),
    interval: getText(equip.Interval),
    verificationEnterprise: equip.VerificationEnterprise ? convertServerPartner(equip.VerificationEnterprise, settings) : null,
    price: getText(equip.Price),
    verificationItems: sortById(
      convertMaybeArray(equip.VerificationItems?.VerificationItem || [], e => convertVerificationItem(e, settings)),
    ),
    verificationDocuments: sortById([
      ...convertMaybeArray(equip.VerificationDocuments?.Document || [], e => convertDocument('local', e)),
      ...convertMaybeArray(equip.ForeignVerificationDocuments?.Document || [], e => convertDocument('foreign', e)),
    ]),
    isLocalVerificationLocation: getBoolean(equip.IsLocalVerificationLocation),
    depreciationRate: getText(equip.DepreciationRate),
    responsibleContact: equip.ResponsibleContact ? convertEquipVerificationContact(equip.ResponsibleContact, settings) : null,
    verificationEnterpriseContract: equip.VerificationEnterpriseContract
      ? convertDocument('local', equip.VerificationEnterpriseContract)
      : null,
  };

  return result;
}

export function convertEquipVerificationContact(verficationContact: any, settings: Settings): Equip.EquipVerificationContact {
  return {
    id: getId(verficationContact),
    scientist: verficationContact.Scientist ? convertServerPerson(verficationContact.Scientist, settings) : null,
    department: verficationContact.Department ? convertReference(verficationContact.Department) : null,
    code: getText(verficationContact.Code),
  };
}

function convertVerificationItem(verificationItem: any, settings: Settings): Equip.VerificationItem {
  return {
    id: getId(verificationItem),
    date: getText(verificationItem.Date),
    enterprise: verificationItem.Enterprise ? convertServerPartner(verificationItem.Enterprise, settings) : null,
    price: getText(verificationItem.Price),
    result: verificationItem.Result ? convertReference(verificationItem.Result) : null,
    contractNumber: getText(verificationItem.ContractNumber),
    document: verificationItem.Document ? convertFile(verificationItem.Document) : null,
    documentUrl: getText(verificationItem.DocumentUrl),
    note: getText(verificationItem.Note),
    isLocalVerificationLocation: getBoolean(verificationItem.IsLocalVerificationLocation),
  };
}

function convertPhoto(photo: any): Equip.Photo {
  return {
    id: getId(photo),
    photo: photo.Photo ? convertFile(photo.Photo) : null,
  };
}

function convertBalance(balance: any, settings: Settings): Equip.Balance {
  return {
    id: getId(balance),
    year: getText(balance.Year),
    date: getText(balance.Date),
    accountNumber: getText(balance.AccountNumber),
    amount: getText(balance.Amount),
    alphaAmount: getText(balance.AlphaAmount),
    unmodulatedAlphaAmount: getText(balance.UnmodulatedAlphaAmount),
    residualAmount: getText(balance.ResidualAmount),
    responsible: balance.Responsible ? convertServerPerson(balance.Responsible, settings) : null,
    note: getText(balance.Note),
  };
}

function convertKitContent(kitContent: any, settings: Settings): Equip.KitContent {
  return {
    id: getId(kitContent),
    name: getText(kitContent.Name),
    quantity: getText(kitContent.Quantity),
    unit: kitContent.Unit ? convertReference(kitContent.Unit) : null,
    note: getText(kitContent.Note),
    equip: kitContent.Equip ? convertEquip(kitContent.Equip, settings) : null,
    equipPart: kitContent.EquipPart ? convertEquip(kitContent.EquipPart, settings) : null,
  };
}

function convertInvestmentSource(investmentSource: any): Equip.InvestmentSource {
  return {
    id: getId(investmentSource),
    source: getText(investmentSource.Source),
    amount: getText(investmentSource.Amount),
    projectCode: investmentSource.ProjectCode ? convertProjectCode(investmentSource.ProjectCode) : null,
  };
}

function convertProjectCode(projectCode: any): Equip.ProjectCode {
  return {
    id: getId(projectCode),
    code: getText(projectCode.Code),
    project: projectCode.Project ? convertProject(projectCode.Project) : null,
    investmentSource: projectCode.InvestmentSource ? convertInvestmentSourceItem(projectCode.InvestmentSource) : null,
  };
}

function convertInvestmentSourceItem(investmentSource: any): Equip.InvestmentSourceItem {
  return {
    type: investmentSource.Type ? convertItem(investmentSource.Type) : null,
    source: investmentSource.Reference ? convertReference(investmentSource.Reference) : null,
  };
}

function convertProject(project: any): Equip.Project {
  return {
    id: getId(project),
    type: project.Type ? convertItem(project.Type) : null,
    projectNumber: getText(project.ProjectNumber),
  };
}

function convertInstallLocation(installLocation: any, settings: Settings): Equip.InstallLocation {
  return {
    id: getId(installLocation),
    address: getText(installLocation.Address),
    building: getText(installLocation.Building),
    cabinet: getText(installLocation.Cabinet),
    department: convertReference(installLocation.Department),
    departmentResponsible: installLocation.DepartmentResponsible
      ? convertServerPerson(installLocation.DepartmentResponsible, settings)
      : null,
    faculty: convertReference(installLocation.Faculty),
    facultyResponsible: installLocation.FacultyResponsible
      ? convertServerPerson(installLocation.FacultyResponsible, settings)
      : null,
    installDate: getText(installLocation.InstallDate),
  };
}

function convertContact(contact: any, settings: Settings): Equip.Contact {
  return {
    id: getId(contact),
    contact: contact.Contact ? convertServerPerson(contact.Contact, settings) : null,
    note: getText(contact.Note),
  };
}

import React from 'react';

import { FormComponent, Select, TextArea, TextDateTime } from 'components';

import { Community, Vak, Affiliation, CommunityMember } from 'types/models';
import { Member, SelectPerson } from 'features/SelectPerson';
import { Item } from 'types/models/common';
import { Form } from 'types/models';
import { useController } from './controller';

type Props = {
  target: Community | Form.Publication | null;
  entry: CommunityMember | null;
  onFieldChange(row: CommunityMember): void;
  vaks?: Vak[];
  selectedCommunityType?: Item | null;
  isLocal?: boolean;
  code?: string;
  affiliation?: Affiliation | null;
  roleOptions: Item[];
  cooperationTypeOptions?: Item[];
  isNotDissertationCouncil?: boolean;
  isJoinDateRequired?: boolean;
  isSpecialityHidden?: boolean;
};

function MemberFields({
  target,
  entry,
  onFieldChange,
  selectedCommunityType,
  code,
  roleOptions,
  cooperationTypeOptions,
  isNotDissertationCouncil,
  isJoinDateRequired,
}: Props) {
  const {
    handlePersonFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handleRoleFieldChange,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    memberHeading,
  } = useController({
    target,
    selectedCommunityType,
    code,
    entry,
    onFieldChange,
    roleOptions,
    cooperationTypeOptions,
  });

  const member: Member = {
    person: entry?.person || null,
    job: entry?.job || null,
    education: entry?.education || null,
    citizenship: entry?.citizenship || null,
    degree: entry?.degree || null,
    rank: entry?.rank || null,
    academicRank: entry?.academicRank || null,
    historyLabel: entry?.historyLabel || '',
  };

  return (
    <FormComponent.Wrapper>
      {!!memberHeading && (
        <FormComponent.Line>
          <FormComponent.Field label="">
            <strong>{memberHeading}</strong>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {!!isNotDissertationCouncil && (
        <FormComponent.Line>
          <FormComponent.Field label="Предмет сотрудничества" isRequired>
            <Select
              value={entry?.cooperationType || null}
              options={cooperationTypeOptions || []}
              onChange={handleCooperationTypeFieldChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Персона" isRequired>
          <SelectPerson
            member={member}
            onUpdateMember={handlePersonFieldChange}
            hiddenButtons={['delete']}
            withHistory={true}
            memberLabel={[member.person?.fullName, member.degree?.refDegree?.label, member.rank?.refRank?.label]
              .filter(Boolean)
              .join(', ')}
          />
          <div className="hint-text" style={{ lineHeight: '18px' }}>
            Должность: {member.job?.refAppointment?.label || <em>Не указана</em>}
          </div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select value={entry?.role || { label: '', value: '' }} options={roleOptions} onChange={handleRoleFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата ввода в состав" isRequired={isJoinDateRequired}>
          <TextDateTime value={entry?.joinDate} onChange={handleJoinDateFieldChange} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата вывода из состава">
          <TextDateTime value={entry?.leaveDate} onChange={handleLeaveDateFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Краткое описание"
          tooltip="Укажите специальность участника (по какому направлению специализируется участник)"
        >
          <TextArea settings={{ rows: 3 }} value={entry?.description ?? ''} onChange={handleDescriptionFieldChange} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { MemberFields };

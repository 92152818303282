import { ButtonMode, FormComponent, ListEdit, Modal } from 'components';
import * as BackendAPI from 'services/BackendAPI';

import { formatAuthor } from 'features/Form/views/AboutAuthorship/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { TypeEditionRelationsCode } from 'utils/Enums/TypeEditionRelationsCode';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { GetReferenceElementList } from 'features/Table/specifications';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  isOpen: boolean;
  onClose(): void;
  id: string;
  title: string;
  errorText: string;
  type: string;
  participationType?: string;
};

function EditRelationsProfileModal(props: Props) {
  const { isOpen, onClose, id, title, errorText, type, participationType } = props;
  const { settings } = useAppDataContext();
  const {
    authors,
    departments,
    setDepartments,
    handleSubmit,
    biblioRecord,
    publicationType,
    isErrorModal,
    errorStatus,
    handleCloseErrorModal,
    participationData,
  } = useController({ id, onClose, errorText, type });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const eventTypeCodeLabel: string = (() => {
    if (participationType === ParticipationTypes.PARTICIPATION_CONFERENCE.code) {
      return EventTypes.EVENT_CONFERENCE.label;
    }
    if (participationType === ParticipationTypes.PARTICIPATION_EXPOSITION.code) {
      return EventTypes.EVENT_EXPOSITION.label;
    }
    if (participationType === ParticipationTypes.PARTICIPATION_CONTEST.code) {
      return EventTypes.EVENT_CONTEST.label;
    }
    if (participationType === ParticipationTypes.PARTICIPATION_CONCERT.code) {
      return EventTypes.EVENT_CONCERT.label;
    }
    return '';
  })();

  return isErrorModal ? (
    <Modal
      mode="warning"
      title={errorStatus.type}
      isOpen={isErrorModal}
      onClose={handleCloseErrorModal}
      actions={[
        {
          mode: ButtonMode.SECONDARY,
          text: 'Закрыть',
          onClick: handleCloseErrorModal,
        },
      ]}
      size="small"
    >
      <>{errorStatus.text}</>
    </Modal>
  ) : (
    <Modal title={title} isOpen={isOpen} onClose={onClose} size="large">
      <ControlPanel handleSave={handleSubmit} handleCancel={onClose} />

      <FormComponent.Wrapper>
        {type === TypeEditionRelationsCode.PUBLICATION && (
          <>
            <FormComponent.Field>
              <strong>{['Библиографическая запись', publicationType?.genitiveCaseLabel || ''].filter(Boolean).join(' ')}:</strong>
              {['', biblioRecord || '-'].join(' ')}
            </FormComponent.Field>

            <FormComponent.Field>
              <strong>Авторы:</strong>
            </FormComponent.Field>
            <>
              {authors.map((author, index) => (
                <FormComponent.Field key={index}>
                  <div dangerouslySetInnerHTML={{ __html: formatAuthor(author, settings) }} />
                </FormComponent.Field>
              ))}
            </>
          </>
        )}
        {type === TypeEditionRelationsCode.PARTICIPATION && (
          <>
            <FormComponent.Field>
              <FormComponent.Text>
                <strong>Мероприятие:</strong> {eventTypeCodeLabel}:{' '}
                {getStatusRecordMessage(
                  getEventFullName(participationData?.event),
                  participationData?.event?.id,
                  participationData?.event?.status?.label,
                )}
              </FormComponent.Text>
            </FormComponent.Field>
            {participationType === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
              (participationData?.reportTheme || participationData?.publication?.fields.name) && (
                <FormComponent.Field>
                  <FormComponent.Text>
                    <strong>Тема доклада:</strong> <span>{participationData?.publication?.fields.name || '-'}</span>
                  </FormComponent.Text>
                </FormComponent.Field>
              )}
            <FormComponent.Line>
              <strong>Участники:</strong>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Text>
                {participationData?.members.map((member, index) => (
                  <div key={index} dangerouslySetInnerHTML={{ __html: formatAuthor(member, settings) }} />
                ))}
              </FormComponent.Text>
            </FormComponent.Line>
          </>
        )}

        <ListEdit
          rows={departments}
          onChange={setDepartments}
          toolbar={['add', 'edit', 'delete']}
          columns={[{ label: 'Наименование', formatValue: x => x.ref.label }]}
          isDeleteConfirmEnabled
          withMessages
          specification={{
            mode: 'loadInstanceTableModal',
            onStartLoad: (tableRows, onSuccess) => {
              loadReferenceAPI.callAPI(
                { filters: [], referenceName: 'RefDepartment', childIds: tableRows.map(x => x.id) },
                { onSuccessfullCall: ({ data }) => onSuccess(data.map(e => ({ ref: e, id: null }))) },
              );
            },
            relationTableModalTitle: 'Справочник "Подразделения"',
            modalTableSpecification: GetReferenceElementList({ requestData: { filters: [], name: 'RefDepartment' } }),
          }}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { EditRelationsProfileModal };

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { ProjectPerformerOrder } from 'types/models/Project';
import { convertProjectPerformerOrderToXML } from './requestConverters';
import { convertProjectPerformerOrder } from 'services/BackendAPI/configurations/projectPerformerOrders/converters';

export const projectPerformerOrderConfigurations = {
  SaveProjectPerformerOrder: makeBackendAPIConfiguration({
    id: 'SaveProjectPerformerOrder',
    endpoint: '/msa/service/commands/SaveProjectPerformerOrder',
    convertInputToXMLElement: (inputData: ProjectPerformerOrder) => ({
      Request: {
        _attr: {
          commandName: 'SaveProjectPerformerOrder',
        },
        ProjectAssignment: { ...convertProjectPerformerOrderToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetProjectPerformerOrder: makeBackendAPIConfiguration({
    id: 'GetProjectPerformerOrder',
    endpoint: '/msa/service/commands/GetProjectPerformerOrder',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetProjectPerformerOrder',
          },
          ProjectPerformerOrder: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertProjectPerformerOrder(response.Response.ProjectPerformerOrder),
  }),
};

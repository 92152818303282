export const printYearRegExp = /^[\d]*$/g;
export const finalYearRegExp = /^[12][0-9]{3}$/g;
export const issnRegExp = /^[\d-]*$/g;
export const numberRegExp = /^[\d]*$/g;
export const doiRegExp = /^10\./g;
export const floatRegExp = /^-?\d*(\.\d+)?$/g;
export const monthRegExp = /(^0?[1-9]$)|(^1[0-2]$)/g;
export const dayRegExp = /^(0[1-9]|[1-9]|[12]\d|3[01])$/g;
export const cyrillicWithRomanRecordRegExp = /^[а-яА-ЯIVXLCMЁё]+$/gm;
export const cyrillicRecordRegExp = /^[а-яА-ЯЁё]+$/gm;

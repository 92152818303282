import React from 'react';

import { Tab, Tabs, FormComponent, SectionTitle, Modal } from 'components';

import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { Partner } from 'types/models';
import { AboutOrganization, Contacts } from './Tabs';
import { useController } from './controller';
import { usePermissions } from './usePermissions';

import {
  GetPartnerSecurityDocumentContractList,
  GetPartnerProjectList,
  GetPartnerPublicationList,
  GetPartnerEventList,
  GetPartnerExpositionParticipationList,
  GetPartnerMobileProjectList,
} from '../Table/specifications';

import { getEnterpriseCustomersIds, getEnterpriseIds } from './helpers';
import { EditPartnerPanel } from 'features/EditPartner/EditPartnerPanel';
import { PartnerHelp } from './help';

type Props = {
  partner: Partner | null;
  viewMode: boolean;
  title: string;
  onSuccessSavePartner(): void;
  closeForm(): void;
  isOpenForm: boolean;
};

export function EditPartner(props: Props) {
  const { onSuccessSavePartner, partner: externalPartner, viewMode, closeForm, title, isOpenForm } = props;

  const { partner, makeChangeHandler, onSavePartner, setPartner, onHelp, isHelpFormOpen, setIsHelpFormOpen } = useController({
    externalPartner,
    onSuccessSave: onSuccessSavePartner,
  });

  const enterpriseCustomerIds = getEnterpriseCustomersIds(partner);
  const enterpriseIds = getEnterpriseIds(partner);

  const permissions = usePermissions();

  return (
    <Modal isOpen={isOpenForm} onClose={closeForm} title={title} size="full">
      <FormComponent.Template>
        <EditPartnerPanel
          onSave={() => onSavePartner(true)}
          onSaveAndContinue={onSavePartner}
          onHelp={onHelp}
          viewMode={viewMode}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Об организации" isRequired>
              <AboutOrganization
                setPartner={setPartner}
                disabled={viewMode}
                makeChangeHandler={makeChangeHandler}
                partner={partner}
              />
            </Tab>
            <Tab title="Документы" isVisible={permissions.isViewableDocuments}>
              <Documents
                documents={partner?.documents ?? []}
                setDocuments={makeChangeHandler('documents')}
                disabled={viewMode}
                fieldsType="details"
              />
            </Tab>
            <Tab title="Контактные лица">
              <Contacts
                contacts={partner?.contacts ?? []}
                setContacts={makeChangeHandler('contacts')}
                enterpriseCustomerIds={enterpriseCustomerIds}
                enterpriseIds={enterpriseIds}
                disabled={viewMode}
              />
            </Tab>
            <Tab title="НИОКР">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список НИОКР, в которых организация является заказчиком или соисполнителем, или технологическим партнёром"
              />
              <DataGrid specification={GetPartnerProjectList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Договоры на ИС">
              <SectionTitle title="Список договоров на использование интеллектуальной собственности университета" />
              <DataGrid specification={GetPartnerSecurityDocumentContractList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Публикации">
              <SectionTitle title="Список публикаций, в которых авторы аффилированы с организацией" />
              <DataGrid specification={GetPartnerPublicationList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Мероприятия">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список совместно организованных мероприятий и мероприятий организации, в которых приняли участие сотрудники, аспиранты, студенты университета"
              />
              <DataGrid specification={GetPartnerEventList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Участие в выставках">
              <SectionTitle title="Участие университета в выставках с экспонатами, разработанными совместно с организацией" />
              <DataGrid specification={GetPartnerExpositionParticipationList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Стажировки">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список сотрудников, аспирантов и студентов университета, прошедших стажировку в организации"
              />
              <DataGrid specification={GetPartnerMobileProjectList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
          </Tabs>

          <Modal
            mode="help"
            title="Особенности заполнения  формы Организации-партнёра"
            isOpen={isHelpFormOpen}
            onClose={() => setIsHelpFormOpen(false)}
            size="large"
          >
            {PartnerHelp()}
          </Modal>
        </FormComponent.Template>
      </FormComponent.Template>
    </Modal>
  );
}

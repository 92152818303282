import { CashDocument } from 'types/models';

import { convertMaybeArray, getId, getText, convertReference, getBoolean } from '../commonConverters';
import { Settings } from 'types/models/common';
import { convertProjectCode } from '../projectCode/converters';
import { convertKindConsumption } from '../kindConsumption/converters';

export function convertCashDocument(cashDocument: any, settings: Settings): CashDocument.CashDocument {
  const result: CashDocument.CashDocument = {
    id: getId(cashDocument),
    isExpense: getBoolean(cashDocument.IsExpense),
    number: getText(cashDocument.Number),
    payDate: getText(cashDocument.PayDate),
    enterprise: cashDocument.Enterprise ? convertReference(cashDocument.Enterprise) : null,
    enterpriseValue: getText(cashDocument.EnterpriseValue),
    foundation: cashDocument.Foundation ? convertFoundation(cashDocument.Foundation) : null,
    items: convertMaybeArray(cashDocument.Items?.Item || [], e => convertItem(e, settings)),
  };

  return result;
}

function convertItem(item: any, settings: Settings): CashDocument.Item {
  return {
    id: getId(item),
    projectCode: item.ProjectCode ? convertProjectCode(item.ProjectCode, settings) : null,
    amount: getText(item.Amount),
    kindConsumption: item.KindConsumption ? convertKindConsumption(item.KindConsumption) : null,
    purpose: getText(item.Purpose),
    note: getText(item.Note),
  };
}

function convertFoundation(foundation: any): CashDocument.Foundation {
  return {
    type: getText(foundation.Type),
    number: getText(foundation.Number),
    date: getText(foundation.Date),
    startDate: getText(foundation.StartDate),
    endDate: getText(foundation.EndDate),
  };
}

import React from 'react';

import {
  FormComponent,
  ListEdit,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextDateTime,
  TextArea,
  TextAreaMode,
  TextInput,
} from 'components';

import { Author, Form } from 'types/models';
import { SecurityDocument, SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { Departments } from 'features/Departments';

import { useController } from './controller';

import { validatePatentMember } from '../Document/validate';
import { MemberInputs } from '../Document/MemberInputs';
import { ProjectInput } from '../Document/ProjectInput';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
  formFields: Form.Fields;
  disabled?: boolean;
  handleChangeProject(projectId?: string): void;
};

export function NotRegistredDocument({
  securityDocument,
  formFields,
  disabled,
  setSecurityDocument,
  handleChangeProject,
}: Props) {
  const {
    handleApplicantChange,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    applicantPersonList,
    handleAuthorsChange,
  } = useController({
    securityDocument,
    setSecurityDocument,
  });

  const { settings } = useAppDataContext();

  const applicantsComponent = () => (
    <ListEdit
      header={{ title: `Правообладатели, кроме ${settings?.organization?.shortName}` }}
      rows={applicantPersonList}
      onChange={handleApplicantChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '', styles: { width: '42%' } },
        {
          label: 'Договор',
          formatValue: x =>
            x?.contractDate ? `№ ${x?.contractNumber} от ${x?.contractDate}` : x?.contractNumber ? `№ ${x?.contractNumber}` : '',
          styles: { width: '42%' },
        },
        { label: 'Доля', formatValue: x => x?.contractPortion ?? '', styles: { width: '16%' } },
      ]}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      maxHeight="300px"
      defaultRowsCount={5}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs
            member={row}
            onMemberChange={onChange}
            memberType={SecurityDocumentMemberTypes.APPLICANT}
            enterpriseRef="RefEnterpriseCustomer"
          />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const authorsComponent = () => (
    <ListEdit
      header={{
        title: `Авторы${authorsList.length ? `, всего: ${authorsList.length}` : ''}`,
        isRequired: true,
        info: (
          <>
            <p>
              При заполнении сведений об авторах обязательно указать место работы или обучения автора. Список сотрудников,
              студентов и аспирантов университета — в Справочнике персон, данные в него загружаются из информационных систем
              кадрового учёта университета.
            </p>
            <p>Для добавления не аффилированных с университетом персон оператору необходимо иметь соответствующее право.</p>
          </>
        ),
      }}
      rows={authorsList}
      onChange={handleAuthorsChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '25%' } },
        {
          label: 'Должность',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.rank || result.error || '';
          },
          styles: { width: '25%' },
        },
        {
          label: 'Место работы',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.job || result.error || '';
          },
          styles: { width: '35%' },
        },
        { label: 'Вклад, %', formatValue: x => x?.contribution || '0', styles: { width: '15%' }, dataKind: 'float' },
      ]}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      maxHeight="none"
      columnIndexesForSumTotal={[3]}
      defaultRowsCount={8}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs member={row} onMemberChange={onChange as any} memberType={SecurityDocumentMemberTypes.AUTHOR} />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const convertSecurityDocumentToAuthor = (member: SecurityDocumentMember): Author => ({
    ...member,
    category: null,
    historyLabel: '',
    affilateCount: null,
    isAffilated: false,
    memberAffilations: [],
    position: 0,
    enterprise: null,
  });

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  return (
    <>
      <SectionTitle title="Объект авторского права" />

      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: leftColumnWidth }}>
          <FormComponent.Line>
            <FormComponent.Field label="Вид ОИС" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={securityDocument.kind}
                onChange={(e: ReferenceItem) => setSecurityDocument(prev => ({ ...prev, kind: e }))}
                settings={{
                  name: 'RefSecurityDocumentKind',
                  title: 'Справочник "Объекты авторского права"',
                  ...(securityDocument.type?.value ? { filters: [{ key: 'type', values: [securityDocument.type?.value] }] } : {}),
                  isClearable: false,
                }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.intellectualPropertyName.title}
              isRequired={formFields.intellectualPropertyName.required}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.intellectualPropertyName.title }}
                value={formFields.intellectualPropertyName.value}
                onChange={formFields.intellectualPropertyName.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.referat.title} isRequired={formFields.referat.required}>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.referat.title }}
                value={formFields.referat.value}
                onChange={formFields.referat.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Получен в рамках научного проекта">
              <ProjectInput securityDocument={securityDocument} handleChangeProject={handleChangeProject} disabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column style={{ width: rightColumnWidth }}>
          <FormComponent.Line>
            <FormComponent.Field label="Дата создания">
              <TextDateTime
                value={formFields.requestDate.value}
                onChange={formFields.requestDate.onChange}
                isDisabled={disabled || formFields.requestDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={`Предоставлено в распоряжение ${settings?.organization?.shortName}`}>
              <TextDateTime
                value={formFields.documentDate.value}
                onChange={formFields.documentDate.onChange}
                isDisabled={disabled || formFields.documentDate.disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label="Номер документа"
              isRequired={formFields.documentNumber.required}
              tooltip="Номер документа - обязательный атрибут для оформления заявки на стимулирующие выплаты"
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: 'Номер документа' }}
                value={formFields.documentNumber.value}
                onChange={formFields.documentNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.internalNumber.title}>
              <TextInput
                value={formFields.internalNumber.value}
                onChange={formFields.internalNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата начала действия">
              <TextDateTime
                value={securityDocument.documentStartDate}
                onChange={e => setSecurityDocument(prev => ({ ...prev, documentStartDate: e }))}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата окончания действия">
              <TextDateTime
                value={securityDocument.documentEndDate}
                onChange={e => setSecurityDocument(prev => ({ ...prev, documentEndDate: e }))}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: leftColumnWidth }}>
          <FormComponent.Line>{authorsComponent()}</FormComponent.Line>

          <FormComponent.Line>
            <Departments
              title="Связь с Подразделениями"
              info={
                <>
                  <p>
                    Система автоматически добавляет подразделения авторов. Оператор имеет возможность отредактировать этот список
                    (удалить или добавить подразделение).
                  </p>
                  <p>
                    Для указанных в этом разделе подразделений объект будет включён в отчёты о результатах научной деятельности.
                  </p>
                </>
              }
              securityDocument={securityDocument}
              setSecurityDocument={setSecurityDocument}
              disabled={!!disabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column style={{ width: rightColumnWidth }}>
          <FormComponent.Line>{applicantsComponent()}</FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

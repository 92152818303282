import React, { useEffect } from 'react';

import { ButtonMode, FormComponent, Modal, Radio, Tab, Tabs, TextArea, Toolbar } from 'components';

import { Documents } from 'features/Documents';
import { PerformersListEdit } from 'features/Form/views/PerformersListEdit';
import { RequestProvides } from './Tabs/RequestProvides';
import { useController } from './controller';
import { ProgramRequest } from './Tabs/ProgramRequest';
import { AboutTender } from './Tabs/AboutTender';
import { Classifiers } from './Tabs/Classifiers';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { RequestApprovementResolution } from 'utils/Enums';
import { RequestTypes } from 'utils/Enums/RequestTypes';
import { useFormContext } from 'features/Form/hooks';
import { GetRequestFeedlineList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { Estimate } from 'features/Form/views';
import { Member } from 'types/models/Event';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProgramRequestForm({ viewMode, editMode, onClose }: Props) {
  const {
    programRequest,
    formFields,
    documents,
    departments,
    performers,
    partners,
    finances,
    okveds,
    lksetss,
    scienceBrunches,
    knowledgeBranches,
    handleKnowledgeBranchesChange,
    handleDocumentsChange,
    handleDepartmentsChange,
    handleFinanceChange,
    handlePartnersChange,
    handleOkvedChange,
    handleLksetsChange,
    handleScienceBrunchChange,
    okvedParentLabel,
    lksetsParentLabel,
    scienceBrunchParentLabel,
    makeChangeHandler,
    performersChangeHandler,
    updateManager,
    workMode,
    isStatusChangeModalOpen,
    closeStatusChangeModal,
    changeStatus,
    statusChangeMessage,
    handleStatusChangeMessageChange,
    statusChangeName,
    buttons,
    status,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approvementStatus,
    setApprovementStatus,
    approvementMessage,
    setApprovementMessage,
    approvementOptions,
    saveApprovement,
    requestId,
    handleEstimatePositionsChange,
    setProgramRequest,
    isLotPriceWarningOpen,
    setIsLotPriceWarningOpen,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const { userPermission, settings } = useAppDataContext();

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return `Добавление ${RequestTypes.REQUEST.labelDeclined}`;
        }

        const title = getStatusMessage(
          [
            workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
            RequestTypes.REQUEST.labelDeclined,
            programRequest?.code ? `№ ${programRequest?.code}` : '',
          ]
            .filter(Boolean)
            .join(' '),
          programRequest?.id || '',
          programRequest?.status?.label,
        );

        return (
          <>
            <h3>
              {title}
              {programRequest?.requestDate ? `, подана на конкурс: ${programRequest?.requestDate}` : ''}
            </h3>
            <h4>{programRequest?.tender?.categories.map(c => c.label).join(', ')}</h4>
          </>
        );
      })(),
    );
  }, [
    programRequest?.code,
    programRequest?.id,
    programRequest?.tender?.categories,
    programRequest?.status?.label,
    setTitle,
    workMode,
    programRequest?.requestDate,
  ]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О конкурсе">
            <AboutTender tender={formFields.tender.value} lot={formFields.lot.value} />
          </Tab>
          <Tab
            title="О заявке"
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`PROGRAM_REQUEST_EDIT_${status}_REQUEST`])}
          >
            <ProgramRequest
              formFields={formFields}
              disabled={!!viewMode}
              departments={departments}
              handleDepartmentsChange={handleDepartmentsChange}
              finances={finances}
              handleFinanceChange={handleFinanceChange}
              partners={partners}
              handlePartnersChange={handlePartnersChange}
              manager={performers.find(p => p.role?.value === 'LEADER') as Member}
              updateManager={updateManager}
              programRequest={programRequest}
              setProgramRequest={setProgramRequest}
            />
          </Tab>
          <Tab
            title="Коллектив"
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`PROGRAM_REQUEST_EDIT_${status}_PERFORMERS`])}
          >
            <PerformersListEdit
              rows={performers || []}
              onChange={value => performersChangeHandler(value)}
              disabled={!!viewMode}
              isShowPersonalInfo
              isShowPersonalReport
              isShowHistory
              // eslint-disable-next-line max-len
              nameTooltip={`Справочник содержит список сотрудников и студентов, аспирантов, которые загружаются из информационных систем учёта кадров и студентов ${
                settings?.organization?.shortName || 'университета'
                // eslint-disable-next-line max-len
              } и основные сведения о персоне учёную степень, звание, должность для сотрудника, или обучение для аспиранта на форме данные о персоне`}
              // eslint-disable-next-line max-len
              roleTooltip="Выберите из списка ролей. Пользователь, добавляющий заявку должен быть указан в составе коллектива в роли: «Руководитель проекта» или «Ответственный исполнитель». Только в этом случае после сохранения заявка будет отображаться в списке заявок в личном кабинете пользователя"
            />
          </Tab>
          <Tab
            title="Классификаторы"
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`PROGRAM_REQUEST_EDIT_${status}_CLASSIFICATORS`])}
          >
            <Classifiers
              viewMode={viewMode}
              programRequest={programRequest}
              makeChangeHandler={makeChangeHandler}
              okveds={okveds}
              handleOkvedChange={handleOkvedChange}
              lksetss={lksetss}
              handleLksetsChange={handleLksetsChange}
              scienceBrunches={scienceBrunches}
              handleScienceBrunchChange={handleScienceBrunchChange}
              knowledgeBranches={knowledgeBranches}
              handleKnowledgeBranchesChange={handleKnowledgeBranchesChange}
              okvedParentLabel={okvedParentLabel}
              lksetsParentLabel={lksetsParentLabel}
              scienceBrunchParentLabel={scienceBrunchParentLabel}
            />
          </Tab>
          <Tab title="Обеспечение" isVisible={false}>
            <>
              <RequestProvides viewMode={viewMode} formFields={formFields} />
            </>
          </Tab>
          <Tab title="Смета">
            <Estimate
              disabled={!!viewMode}
              estimatePositions={programRequest.estimatePositions || []}
              handleEstimateChange={handleEstimatePositionsChange}
            />
          </Tab>
          <Tab
            title="Документы"
            isDisabled={!viewMode && !isHasPermission(userPermission, Permits[`PROGRAM_REQUEST_EDIT_${status}_DOCUMENTS`])}
          >
            <Documents
              documents={documents ?? []}
              setDocuments={handleDocumentsChange}
              disabled={!!viewMode}
              isCanIsSharedEdit
              sharedLabel="Отображать на форме редактирования проекта"
              sourceLabelForLocalDocs="Текущая заявка"
              defaultSharedValue
              isShowSource
            />
          </Tab>
          <Tab title="Журнал сообщений" isVisible={isHasPermission(userPermission, Permits.PROGRAM_REQUEST_FEEDLINE_ACCESS)}>
            <DataGrid specification={GetRequestFeedlineList(requestId || '-1')} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        title="Смена статуса"
        isOpen={isStatusChangeModalOpen}
        onClose={closeStatusChangeModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: statusChangeName,
            onClick: changeStatus,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeStatusChangeModal,
          },
        ]}
        size="small"
      >
        <TextArea settings={{ rows: 5 }} value={statusChangeMessage} onChange={handleStatusChangeMessageChange} />
      </Modal>

      <Modal
        title="Экспертная оценка"
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: saveApprovement,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsApprovementModalOpen(false),
          },
        ]}
        size="small"
      >
        <FormComponent.Field label="Виза">
          <Radio
            value={approvementStatus}
            list={approvementOptions}
            onChange={v => setApprovementStatus(v as RequestApprovementResolution)}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Замечания (не более 1000 знаков с пробелами)">
          <TextArea settings={{ rows: 5 }} value={approvementMessage} onChange={setApprovementMessage} />
        </FormComponent.Field>
      </Modal>

      <Modal
        mode="warning"
        title="Внимание! Обнаружено несоответствие"
        isOpen={isLotPriceWarningOpen}
        onClose={() => setIsLotPriceWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsLotPriceWarningOpen(false),
          },
        ]}
        size="small"
      >
        <>Сумма финансирования превышает сумму контракта в конкурсе.</>
      </Modal>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProgramRequestForm);

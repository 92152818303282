import { Parameter, PaymentRequestPeriodValue } from 'types/models';
import * as regexp from 'utils/Regexps';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validatePaymentRequestPeriod = (paymentRequestPeriodParemeter: Parameter): ValidatorResult => {
  const value = paymentRequestPeriodParemeter.value as PaymentRequestPeriodValue;

  if (value.monthRange && value.radioValue === 'month') {
    if (value.monthRange.fromId > value.monthRange.toId) {
      return {
        isValid: false,
        message: 'Месяц "с" должен быть меньше, чем месяц "по"',
      };
    }
  }

  const isYearValid = new RegExp(regexp.finalYearRegExp).test(value.year);
  const isValid = isYearValid;
  return {
    isValid,
    message: isValid ? '' : `Некорректно заполнено поле "Год" в группе полей "${paymentRequestPeriodParemeter.description}"`,
  };
};

export default validatePaymentRequestPeriod;

import React, { useMemo, useState } from 'react';

import { Button, ButtonMode, FormComponent, Select } from 'components';

import { Table as T } from 'types/models';
import { Item } from 'types/models/common';
import compilationTypes from '../compilationTypes';
import compilationConferenceTypes from '../compilationConferenceTypes';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';

type Props = {
  onClose: () => void;
  action: string;
  onSelect: FetchCompilationProcess;
  relatedTableState?: T.State;
  currentCompilationTypeLabel: string;
  id: string;
};

const ChangeCompilationTypes = (props: Props) => {
  const { onClose, relatedTableState, onSelect, action, id, currentCompilationTypeLabel } = props;

  const row = useMemo(() => {
    if (relatedTableState && relatedTableState.selectedRows.length) {
      return relatedTableState.selectedRows[0];
    }
    return null;
  }, [relatedTableState]);

  const [compilationType, setCompilationType] = useState(
    row ? compilationTypes.find(t => t.label === currentCompilationTypeLabel) : compilationTypes[0],
  );

  const [compilationConferenceType, setCompilationConferenceType] = useState(compilationConferenceTypes[0]);

  const isCompilationConference = useMemo(() => compilationType?.code === 'COMPILATION_CONFERENCE', [compilationType]);

  const onChangeCompilationType = (value: string) => {
    const selected = compilationTypes.find(x => x.id === value);
    if (selected) {
      setCompilationType(selected);
    }
  };

  const onChangeCompilationConferenceType = (value: string) => {
    const selected = compilationConferenceTypes.find(x => x.id === value);
    if (selected) {
      setCompilationConferenceType(selected);
    }
  };

  const continueWithSelectedType = () => {
    onSelect(id || '', action, {
      newType: compilationType?.code || '',
      newArticleType: isCompilationConference ? compilationConferenceType.code : '',
    });
    onClose();
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Изменить тип сборника на">
          <Select
            value={{ value: compilationType?.id || '', label: compilationType?.label || '' }}
            options={compilationTypes.map(x => ({
              value: x.id,
              label: x.label,
            }))}
            onChange={(option: Item) => onChangeCompilationType(option.value)}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {isCompilationConference && (
        <FormComponent.Line>
          <FormComponent.Field label="Изменить тип статей на">
            <Select
              value={{ value: compilationConferenceType?.id || '', label: compilationConferenceType?.label || '' }}
              options={compilationConferenceTypes.map(x => ({
                value: x.id,
                label: x.label,
              }))}
              onChange={(option: Item) => onChangeCompilationConferenceType(option.value)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text="Выполнить операцию по изменению типа сборника"
          onClick={continueWithSelectedType}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(ChangeCompilationTypes);

import { convertDocumentToRequest, getMaybeField } from '../commonConverters';
import { PurchaseRequest, Unit } from 'types/models/PurchaseRequest';

export function convertPurchaseRequestToXML(pr: PurchaseRequest) {
  pr.units.forEach((u: Unit, i: number) => {
    u.position = String(i);
  });
  return {
    Project: { _attr: { id: pr.project?.id } },
    ...getMaybeField('Name', pr.name),
    ...getMaybeField('Number', pr.number),
    ...(pr.applicant?.id ? { Applicant: { _attr: { id: pr.applicant?.id } } } : {}),
    ...(pr.department?.id ? { Department: { _attr: { id: pr.department?.id } } } : {}),
    ...getMaybeField('LimitPrice', pr.limitPrice),
    ...(pr.financeResponsible?.person?.id ? { FinanceResponsible: { _attr: { id: pr.financeResponsible?.person?.id } } } : {}),
    ...(pr.requestResponsible?.person?.id ? { RequestResponsible: { _attr: { id: pr.requestResponsible?.person?.id } } } : {}),
    ...getMaybeField('DeliveryAddress', pr.deliveryAddress),
    ...getMaybeField('DeliveryDate', pr.deliveryDate),
    ...getMaybeField('DeliveryNote', pr.deliveryNote),
    ...getMaybeField('ContractKind', pr.contractKind?.value),
    ...getMaybeField('Note', pr.note),
    Units: { Unit: pr.units.map(convertUnitToXML) },
    Documents:
      pr.documents && pr.documents.length
        ? {
            Document: pr.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)),
          }
        : {},
    ...getMaybeField('PaymentTerm', pr.paymentTerm?.value),
    ...getMaybeField('PurchaseMethod', pr.purchaseMethod?.value),
  };
}

function convertUnitToXML(unit: Unit) {
  return {
    ...getMaybeField('Type', unit.type?.value),
    ...getMaybeField('Name', unit.name),
    ...getMaybeField('Quantity', unit.quantity),
    ...(unit.unit?.id ? { Unit: { _attr: { id: unit.unit?.id } } } : {}),
    ...getMaybeField('PerUnitPrice', unit.perUnitPrice),
    ...getMaybeField('Position', unit.position),
  };
}

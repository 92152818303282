import { useMemo } from 'react';

import { Props, SettingsEnumProps } from '.';

import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { getEnum } from 'utils/Helpers';

export function useControllerEnum({ settings }: Pick<Props, 'settings'>) {
  const { enumMap } = useAppDataContext();
  const enumSettings = useMemo<SettingsEnumProps>(() => settings as SettingsEnumProps, [settings]);

  const enumOptions = useMemo<Item[]>(() => {
    return getEnum(enumSettings.name, enumMap).filter(({ value: key }) => {
      if ((enumSettings.includes || []).length) {
        return (enumSettings.includes || []).some(include => include === key);
      }
      return !(enumSettings.excludes || []).some(exclude => exclude === key);
    });
  }, [enumMap, enumSettings.excludes, enumSettings.includes, enumSettings.name]);

  return {
    enumOptions,
  };
}

import React from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { Requests } from 'features/Form/looks/participation/views/Requests';
import { ParticipationTypeCode } from 'types/models/Participation';
import useController from './controller';

type Props = {
  relatedTableState: Table.State | undefined;
  participationTypeCode: ParticipationTypeCode;
  participationId: string;
  workMode: Table.WorkMode;
  onClose: () => void;
  onSuccess: () => void;
};

function FinancicalSupportPopupComponent({
  relatedTableState,
  workMode,
  participationId,
  participationTypeCode,
  onClose,
  onSuccess,
}: Props) {
  const { financingProjects, mobileRequests, submitForm, setFinancingProjectsCb, setMobileRequestsCb } = useController({
    participationId,
    onClose,
    onSuccess,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <Projects
            workMode={workMode}
            participationTypeCode={participationTypeCode}
            projects={financingProjects}
            setProjects={setFinancingProjectsCb}
            relatedTableState={relatedTableState}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <Requests
            workMode={workMode}
            participationTypeCode={participationTypeCode}
            requests={mobileRequests}
            setMobileRequests={setMobileRequestsCb}
            relatedTableState={relatedTableState}
          />
        </FormComponent.Line>

        <FormComponent.Actions>
          <Button mode={ButtonMode.PRIMARY} text="Сохранить" onClick={submitForm} />
          <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(FinancicalSupportPopupComponent);

import * as regexp from 'utils/Regexps';

const SPECIAL_CHARACTERS: number[] = [
  32, // space
  33, // !
  34, // "
  35, // #
  37, // %
  39, // '
  40, // (
  41, // )
  43, // +
  44, // ,
  45, // -
  46, // .
  47, // /
  48, // 0
  49, // 1
  50, // 2
  51, // 3
  52, // 4
  53, // 5
  54, // 6
  55, // 7
  56, // 8
  57, // 9
  58, // :
  59, // ;
  60, // <
  61, // =
  62, // >
  63, // ?
  91, // [
  93, // ]
  8470, // №
  8212, // —
];

const isCommonCharacter = (character: string): boolean => {
  const characterCode = character.charCodeAt(0);
  const isSpecialCharacter = SPECIAL_CHARACTERS.some(specialCharacter => specialCharacter === characterCode);

  return isSpecialCharacter;
};

const isCyrillicCharacter = (character: string): boolean => new RegExp(regexp.cyrillicRecordRegExp).test(character);
const isCyrillicWithRomanCharacter = (character: string): boolean =>
  new RegExp(regexp.cyrillicWithRomanRecordRegExp).test(character);

const validateCyrillicString = (text: string) => {
  const result = {
    text: '',
    isValid: true,
  };

  let isEnglishSequence = false;
  text.split('').forEach((character: string) => {
    const isCyrillic = isCyrillicWithRomanCharacter(character);
    const isCommon = isCommonCharacter(character);

    if (!isCyrillic && !isCommon) {
      if (!isEnglishSequence) {
        result.isValid = false;
        result.text += `<span class="state-danger">`;
      }
      isEnglishSequence = true;
    } else {
      if (isEnglishSequence) {
        result.text += '</span>';
      }
      isEnglishSequence = false;
    }

    result.text += character;
  });

  return result;
};

const validateEnglishString = (text: string) => {
  const result = {
    text: '',
    isValid: true,
  };

  let isCyrillicSequence = false;
  text.split('').forEach((character: string) => {
    const isCyrillic = isCyrillicCharacter(character);
    const isCommon = isCommonCharacter(character);

    if (isCyrillic && !isCommon) {
      if (!isCyrillicSequence) {
        result.isValid = false;
        result.text += `<span class="state-danger">`;
      }
      isCyrillicSequence = true;
    } else {
      if (isCyrillicSequence) {
        result.text += '</span>';
      }
      isCyrillicSequence = false;
    }

    result.text += character;
  });

  return result;
};

type BibliographicRecordProps = {
  textLanguageId: string;
  bibliographicRecord: string;
  cyrillicLanguageCode: string;
};

export const checkBibliographicRecord = ({
  textLanguageId,
  bibliographicRecord,
  cyrillicLanguageCode,
}: BibliographicRecordProps) => {
  const isCyrillic = textLanguageId === cyrillicLanguageCode;
  if (isCyrillic) {
    return validateCyrillicString(bibliographicRecord);
  }

  return validateEnglishString(bibliographicRecord);
};

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Table as T } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: T.State;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const selectedRow = tableState.selectedRows[0];

  const [isViewModalOpen, setIsViewModalOpen] = useState<boolean>(false);

  const { methods: unlockEntityAPIMethods } = BackendAPI.useBackendAPI('ForceUnlockEntity', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись успешно разблокирована', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const unlockEntity = useCallback(() => {
    unlockEntityAPIMethods.callAPI({
      id: selectedRow?.id || '-1',
      type: selectedRow?.type,
    });
  }, [selectedRow?.id, selectedRow?.type, unlockEntityAPIMethods]);

  const handleUnlockButtonClick = useCallback(() => {
    unlockEntity();
  }, [unlockEntity]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewModalOpen(true);
  }, []);

  const handleViewModalClose = useCallback(() => {
    setIsViewModalOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Разблокировать запись',
        onClick: handleUnlockButtonClick,
        isDisabled: !selectedRow,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: !selectedRow,
      },
    ],
    [handleUnlockButtonClick, handleViewButtonClick, selectedRow],
  );

  return { isViewModalOpen, handleViewModalClose, buttons, selectedRow };
}

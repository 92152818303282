import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetSecurityDocumentAnalyticList, GetSecurityDocumentMemberAnalyticList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function IntellectualProperty() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['intellectual-properties', 'intellectual-property'],
    init: ['by_documents'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Анализ по ОИС" id="by_documents">
          <DataGrid specification={GetSecurityDocumentAnalyticList} />
        </Tab>
        <Tab title="Анализ по авторам" id="by_members">
          <DataGrid specification={GetSecurityDocumentMemberAnalyticList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

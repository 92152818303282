import React from 'react';

import { Modal, FormComponent, Toolbar } from 'components';

import { CustomModalComponentSpecification, ExtraToolbarButton, Mode } from '../model';
import { useController } from './controller';

type Props<Row> = {
  isOpen: boolean;
  modalTitle: string | ((row: Row | null, mode: Mode) => string);
  customEditRow: Row | null;
  selectedRowIndex: number | null;
  mode: Mode;
  onClose(): void;
  onChange: React.Dispatch<React.SetStateAction<Row | null>>;
  submitCustomComponent(val?: boolean): void;
  renderComponent: CustomModalComponentSpecification<Row>['renderComponent'];
  isSaveAndAddCustomComponent?: boolean;
  saveAndAddCustomComponent?: () => void;
  isCheck?: boolean;
  isToolbarHidden?: boolean;
  isFullScreenModal?: boolean;
  isFixedHeightModal?: boolean;
  extraFieldsToolbarButtons: ExtraToolbarButton<Row>[];
  rows: Row[];
};

function ModalComponent<Row>(props: Props<Row>) {
  const {
    isOpen,
    onClose,
    onChange,
    modalTitle,
    customEditRow,
    selectedRowIndex,
    submitCustomComponent,
    renderComponent,
    mode,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
    isCheck,
    isToolbarHidden,
    isFullScreenModal,
    isFixedHeightModal,
    extraFieldsToolbarButtons,
    rows,
  } = props;

  const { buttons, modalDialogTitle } = useController({
    mode,
    modalTitle,
    isCheck,
    customEditRow,
    submitCustomComponent,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
    extraFieldsToolbarButtons,
    selectedRowIndex,
    rows,
  });

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={modalDialogTitle}
      size={isFullScreenModal ? 'full' : isFixedHeightModal ? ['large', 'medium-height'] : 'large'}
    >
      <FormComponent.Template>
        {!isToolbarHidden && mode !== 'view' && <Toolbar buttons={buttons} mode="form" />}
        <FormComponent.Wrapper>
          {renderComponent(customEditRow, onChange, selectedRowIndex, mode, submitCustomComponent)}
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}

export { ModalComponent };

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { PersonalDissertationCouncilMemberGridHelp } from './help';

type WrapperProps = {
  communitiesFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ communitiesFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewButtonDisabled,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isEditButtonDisabled,
      isEditFormOpen,
      handleTemplateCloseEditForm,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      isDeleteConfirmPopupOpen,
      isDeleteButtonDisabled,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      buttons,
      member,
      errorStatus,
      handleCloseErrorModal,
      isErrorModal,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
      templateName,
      communityTypes,
      organizationName,
    } = useController({ tableState, communitiesFilter });

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewButtonDisabled && (
          <modalTemplates.CommunityViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
          />
        )}

        {!isEditButtonDisabled && (
          <modalTemplates.CommunityEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
            memberEditLocked={true}
            communityTypes={communityTypes}
          />
        )}

        <modalTemplates.CommunityAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          member={member}
          templateName={templateName}
          memberEditLocked={true}
          communityTypes={communityTypes}
        />

        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: handleCloseDeleteConfirmPopup,
              },
            ]}
            size="small"
          >
            <>(диссовет: {tableState.selectedRows[0]?.Community || <em>Без названия</em>}) Удалить участие?</>
          </Modal>
        )}

        <Modal
          mode="warning"
          title={errorStatus.type}
          isOpen={isErrorModal}
          onClose={handleCloseErrorModal}
          actions={[
            {
              mode: ButtonMode.SECONDARY,
              text: 'Закрыть',
              onClick: handleCloseErrorModal,
            },
          ]}
          size="small"
        >
          <>{errorStatus.text}</>
        </Modal>

        <Modal
          mode="help"
          title="Помощь в разделе Работа в составе диссертационных советов"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          {PersonalDissertationCouncilMemberGridHelp(organizationName)}
        </Modal>
      </>
    );
  });

export class Equip {
  static MODULE: string = 'Equip';

  static DESCRIPTION: string = 'DESCRIPTION';

  static LOCATION: string = 'LOCATION';

  static PURCHASE: string = 'PURCHASE';

  static PROJECTS: string = 'PROJECTS';

  static METROLOGIC: string = 'METROLOGIC';

  static DOCUMENTS: string = 'DOCUMENTS';
}

import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

import { PublicationTypeCode } from 'types/models/Form';
import useController from './controller';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
  publicationTypeCode: PublicationTypeCode;
};

export const PublicationArticlesWarningPopup = memo(({ isOpen, onClose, onConfirm, publicationTypeCode }: Props) => {
  const { title, text } = useController({ publicationTypeCode });

  return (
    <Modal
      mode="warning"
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Ок',
          onClick: onConfirm,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="small"
    >
      <>{text}</>
    </Modal>
  );
});

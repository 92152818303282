import React from 'react';

import { FormComponent, TextArea, TextAreaMode, TextDateTime, Checkbox } from 'components';

import { ValueOf } from 'types/helpers';
import { Form } from 'types/models';
import { ContestRequest } from 'types/models/ContestRequest';
import { formatNumber } from 'utils/Helpers';

type Props = {
  contestRequest?: ContestRequest;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof ContestRequest): (value: ValueOf<ContestRequest>) => void;
  disabled: boolean;
};

export function StageInfo(props: Props) {
  const { formFields, disabled } = props;

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageName.title}
              isRequired
              // eslint-disable-next-line max-len
              tooltip="Изложите идею этапа в одном предложении. Название этапа должно быть связано с названием завершённого НИР и являться его логическим продолжением."
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageName.value}
                onChange={formFields.requestStageName.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сроки этапа проекта" isRequired>
              <span>
                <TextDateTime
                  value={formFields.requestStageStartDate.value || ''}
                  onChange={(value: string) => formFields.requestStageStartDate.onChange(value)}
                  maxValue={formFields.requestStageEndDate.value || ''}
                  isDisabled={disabled}
                />
              </span>
              <span>&nbsp;-&nbsp;</span>
              <span>
                <TextDateTime
                  value={formFields.requestStageEndDate.value || ''}
                  onChange={(value: string) => formFields.requestStageEndDate.onChange(value)}
                  minValue={formFields.requestStageStartDate.value || ''}
                  isDisabled={disabled}
                />
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStagePlanAmount.title}
              tooltip={formFields.requestStagePlanAmount.tooltipText}
            >
              <span>{formatNumber(formFields.requestStagePlanAmount.value || '0', 2)}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageAnnotation.title}
              tooltip={formFields.requestStageAnnotation.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageAnnotation.value}
                onChange={formFields.requestStageAnnotation.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.requestStageGoal.title} tooltip={formFields.requestStageGoal.tooltipText}>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageGoal.value}
                onChange={formFields.requestStageGoal.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageWorkPlan.title}
              tooltip={formFields.requestStageWorkPlan.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageWorkPlan.value}
                onChange={formFields.requestStageWorkPlan.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageExpectedResults.title}
              tooltip={formFields.requestStageExpectedResults.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageExpectedResults.value}
                onChange={formFields.requestStageExpectedResults.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="" tooltip={formFields.isRequestStageHasPatentResearch.tooltipText}>
              <Checkbox
                label={formFields.isRequestStageHasPatentResearch.title}
                checked={!!formFields.isRequestStageHasPatentResearch.value}
                onChange={formFields.isRequestStageHasPatentResearch.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageAdditionalInformation.title}
              tooltip={formFields.requestStageAdditionalInformation.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageAdditionalInformation.value}
                onChange={formFields.requestStageAdditionalInformation.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.requestStageRAN.title} tooltip={formFields.requestStageRAN.tooltipText}>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageRAN.value}
                onChange={formFields.requestStageRAN.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStagePartnerEnterprises.title}
              tooltip={formFields.requestStagePartnerEnterprises.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStagePartnerEnterprises.value}
                onChange={formFields.requestStagePartnerEnterprises.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageCollaborations.title}
              tooltip={formFields.requestStageCollaborations.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageCollaborations.value}
                onChange={formFields.requestStageCollaborations.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageExpectedResultsWorth.title}
              tooltip={formFields.requestStageExpectedResultsWorth.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageExpectedResultsWorth.value}
                onChange={formFields.requestStageExpectedResultsWorth.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageExpectedResultsSuppose.title}
              tooltip={formFields.requestStageExpectedResultsSuppose.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageExpectedResultsSuppose.value}
                onChange={formFields.requestStageExpectedResultsSuppose.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageExpectedResultsEducationalSuppose.title}
              tooltip={formFields.requestStageExpectedResultsEducationalSuppose.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageExpectedResultsEducationalSuppose.value}
                onChange={formFields.requestStageExpectedResultsEducationalSuppose.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageUniversityEffect.title}
              tooltip={formFields.requestStageUniversityEffect.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageUniversityEffect.value}
                onChange={formFields.requestStageUniversityEffect.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageRegionalEffect.title}
              tooltip={formFields.requestStageRegionalEffect.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageRegionalEffect.value}
                onChange={formFields.requestStageRegionalEffect.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestStageNationalEffect.title}
              tooltip={formFields.requestStageNationalEffect.tooltipText}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={formFields.requestStageNationalEffect.value}
                onChange={formFields.requestStageNationalEffect.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

import { ValidationInfo } from 'types/models/common';
import { FieldValue } from 'types/models/Merge';

export enum ColumnType {
  ENTITY = 'entity',
  COMMON = 'common',
}

export type BaseSetValueArguments = {
  fieldName: string;
};

export type SetEntityValueArguments = {
  type: ColumnType.ENTITY;
  entityId: string;
} & BaseSetValueArguments;

export type SetCommonValueArguments = {
  type: ColumnType.COMMON;
} & BaseSetValueArguments;

export type SetValueArguments = SetEntityValueArguments | SetCommonValueArguments;

export enum MergeFieldRadioValue {
  SELECTED = 'SELECTED',
  UNSELECTED = 'UNSELECTED',
}
export type UpdateEntityMergeFieldRadioArguments = { fieldName: string; entityId: string; value?: FieldValue };

export type MergeFieldRadios = Record<string, Record<string, MergeFieldRadioValue>>;

export type ValidateResult = { conflicts: ValidationInfo[]; empty: ValidationInfo[]; invalidOneToMany: ValidationInfo[] };

import * as XML from 'xml-js';
import type { FormSettings } from 'types/models';
import { makeBackendAPIConfiguration } from '../makeBackendAPIConfiguration';
import { convertFormSettings } from './converters';

export const backendFormSettingsAPIConfigurations = {
  GetFormSettings: makeBackendAPIConfiguration({
    id: 'GetFormSettings',
    endpoint: '/msa/service/commands/GetFormSettings',
    convertInputToXMLElement: (inputData: { name: string }) => {
      const { name } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetFormSettings',
          },
          Form: {
            _attr: { name },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): FormSettings.FormSettings => {
      const form = response?.Response?.Form;
      return convertFormSettings(form);
    },
  }),
  SaveFormSettings: makeBackendAPIConfiguration({
    id: 'SaveFormSettings',
    endpoint: '/msa/service/commands/SaveFormSettings',
    convertInputToXMLElement: (inputData: { name: string; component: FormSettings.FormSettingsComponent }) => {
      const { name, component } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'SaveFormSettings',
          },
          Form: {
            _attr: { name },
            Component: {
              _attr: component,
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): FormSettings.FormSettings => {
      const form = response?.Response?.Form;
      return convertFormSettings(form);
    },
  }),
};

import React from 'react';

import { Checkbox, FormComponent, ListEditTable, Select, TextInput, TextInputMode } from 'components';

import { Project } from 'types/models';
import { Item } from 'types/models/common';

type Props = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  isDisabled: boolean;
};

export const RestrictionList = React.memo(({ project, setProject, isDisabled }: Props) => {
  const setValue = (index: number, key: string, value: string | boolean) => {
    const restrictions = [...project.restrictions];
    restrictions[index] = {
      ...restrictions[index],
      [key]: value,
      ...(key === 'isActive' && !value ? { restrictValue: '' } : {}),
    };

    setProject(prevState => ({
      ...prevState,
      restrictions,
    }));
  };

  const booleanOptions: Item[] = [
    { label: 'Да', value: 'true' },
    { label: 'Нет', value: 'false' },
  ];

  return (
    <ListEditTable
      rows={project.restrictions}
      columns={[
        {
          label: 'Статус',
          formatValue: (row, index) => (
            <Checkbox checked={row.isActive} onChange={value => setValue(index, 'isActive', value)} isDisabled={isDisabled} />
          ),
          styles: { width: '10%', verticalAlign: 'top' },
        },
        {
          label: 'Назначение показателя',
          formatValue: row => <FormComponent.Text>{row.type?.label ?? <em>Не указан</em>}</FormComponent.Text>,
          styles: { width: '60%' },
        },
        {
          label: 'Ограничение',
          formatValue: (row, index) => {
            if (row.value.type === 'BOOLEAN') {
              return (
                <Select
                  value={booleanOptions.find(i => i.value === row?.restrictValue)}
                  options={booleanOptions}
                  onChange={(option: Item) => setValue(index, 'restrictValue', option.value)}
                  isError={row.isActive && !row?.restrictValue}
                  isDisabled={!row.isActive || isDisabled}
                />
              );
            }
            if (row.value.type === 'INTEGER') {
              return (
                <TextInput
                  mode={TextInputMode.NUMBER}
                  settings={{
                    min: parseInt(row.value.min),
                    max: parseInt(row.value.max),
                    decimal: false,
                  }}
                  value={row?.restrictValue ?? '0'}
                  onChange={value => setValue(index, 'restrictValue', value)}
                  isError={row.isActive && !row?.restrictValue}
                  isDisabled={!row.isActive || isDisabled}
                />
              );
            }
            if (row.value.type === 'DOUBLE') {
              return (
                <TextInput
                  mode={TextInputMode.NUMBER}
                  settings={{
                    min: parseInt(row.value.min),
                    max: parseInt(row.value.max),
                    decimal: { limit: parseInt(row.value.precision) },
                  }}
                  value={row?.restrictValue ?? '0'}
                  onChange={value => setValue(index, 'restrictValue', value)}
                  isError={row.isActive && !row?.restrictValue}
                  isDisabled={!row.isActive || isDisabled}
                />
              );
            }
            return <em>Не указано</em>;
          },
          styles: { width: '30%', verticalAlign: 'top' },
        },
      ]}
      selectedRowIndex={null}
      selectRow={() => {}}
    />
  );
});

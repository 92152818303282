import React from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

export type Props = {
  handleSave: () => void;
  handleSaveAsDraft: () => void;
  handleSaveAndContinue: () => void;
  viewMode?: boolean;
  isOnlyEditing?: boolean;
  handleOpenPublicationPreprintRelations: () => void;
  isDisabled: boolean;
};

function Toolbar({
  handleSave,
  handleSaveAsDraft,
  handleSaveAndContinue,
  handleOpenPublicationPreprintRelations,
  viewMode: viewModeProp,
  isOnlyEditing,
  isDisabled,
}: Props) {
  const viewMode = isOnlyEditing ? false : viewModeProp;

  const buttons: ButtonProps[] = [
    {
      icon: { type: 'save' },
      title: 'Сохранить',
      onClick: handleSave,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'save', mode: 'add' },
      title: 'Сохранить и продолжить',
      onClick: handleSaveAndContinue,
      isHidden: isOnlyEditing,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'new' },
      title: 'Сохранить как черновик',
      onClick: handleSaveAsDraft,
      isHidden: isOnlyEditing,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'transport' },
      title: 'Указать связь между препринтом и публикацией',
      onClick: handleOpenPublicationPreprintRelations,
      permission: {
        name: 'PublicationArticleTranslationEdit',
      },
      isHidden: isOnlyEditing,
      isDisabled,
    },
  ];

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export const ControlPanel = React.memo(Toolbar);

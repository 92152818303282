import React from 'react';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { AuthorsComponent } from 'features/Form/views/AuthorsComponent';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const PublicationFormArticle = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    authors,
    setAuthors,
    documents,
    setDocuments,
    onCreateBibliographicRecordClick,
    relatedTableState,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    isElectronic,
    electronicType,
    topMessage,
    setTopMessage,
    isRequestPaymentView,
    isShowDetailedTitle,
    paymentString,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
    setProjects,
    setMobileRequests,
  } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />
      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />
      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={handleCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: handleConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: handleResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <div>
          <strong>
            В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
            списке «Все публикации»
          </strong>
        </div>

        <p>Вашей персоны нет в списке авторов. Добавить?</p>
      </Modal>

      {!viewMode && (
        <ControlPanel
          handleSave={handlePublicationSave}
          handleSaveAsDraft={handlePublicationSaveAsDraft}
          handleSaveAndContinue={handlePublicationSaveAndContinue}
          isOnlyEditing={isRequestPaymentView}
          viewMode={viewMode}
          isDisabled={isLoading}
        />
      )}

      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О публикации">
            <TabsContent.About
              formFields={formFields}
              workMode={workMode}
              isElectronic={isElectronic}
              electronicType={electronicType || ''}
              publicationInfo={publicationInfo}
              isShowDetailedTitle={isShowDetailedTitle ?? false}
              userDepartments={userDepartments}
              publicationId={publicationId}
              publicationDepartment={publicationDepartment}
              setPublicationDepartment={setPublicationDepartment}
              arm={arm}
            />
          </Tab>
          <Tab title="Сведения об авторстве">
            <AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
          </Tab>
          <Tab title="Сведения об издании">
            <TabsContent.Info
              formFields={formFields}
              onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
              workMode={workMode}
            />
          </Tab>
          <Tab title="О публикации (анг.)">
            <TabsContent.AboutEnglishComponent formFields={formFields} viewMode={viewMode} />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexing
              citationSystems={publicationInfo?.citationSystems ?? []}
              relatedTableState={relatedTableState}
              publicationId={publicationId || null}
              publicationType={publicationInfo?.type ?? ''}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              workMode={workMode}
              sourceId={publicationInfo?.magazine?.id}
              releaseId={publicationInfo?.magazineRelease?.id}
              topMessage={topMessage}
              setTopMessage={setTopMessage}
            />
          </Tab>
          <Tab title="Финансовая поддержка">
            <TabsContent.FinancialSupport
              workMode={workMode}
              relatedTableState={relatedTableState}
              projects={projects}
              setProjects={setProjects}
              mobileRequests={mobileRequests}
              setMobileRequests={setMobileRequests}
              incentivePaymentRequest={paymentString}
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.Messages relatedTableState={relatedTableState} workMode={workMode} publicationId={publicationId} />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={workMode === 'viewMode'} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
};

export const Component = React.memo(PublicationFormArticle);

import React from 'react';

import { Table } from 'types/models';
import { Filters } from './filters';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { CommunityType } from 'utils/Enums';

export type Props = {
  communitiesFilter: string;
  setCommunitiesFilter: React.Dispatch<string>;
  types: CommunityType[];
};

export function GetPersonalCommunityMemberList({ communitiesFilter, types }: Props): Table.Specification {
  const requestData = { types };
  return {
    apiID: 'GetPersonalCommunityMemberList',
    requestData,
    header: {
      firstLevel: {
        title: 'Члены научных сообществ: Научно-технического совета, Редакции журнала, Коллаборации, Фонда/организации',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel({ communitiesFilter }),
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
  };
}

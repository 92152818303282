export enum Actions {
  VIEW = 'View',
  FULL_VIEW = 'FullView',
  ADD = 'Add',
  EDIT = 'Edit',
  DELETE = 'Delete',
  APPROVE = 'Approve',
  AGREE = 'Agree',
  MERGE = 'Merge',
  INFO_VIEW = 'InfoView',
  EXPORT = 'Export',
  PROCESS = 'Process',
  ACCESS = 'Access',
  TRANSFORM = 'Transform',
  ADMINISTRATE = 'Administrate',
  ACCEPTING = 'Accepting',
  CHANGE_STATUS = 'ChangeStatus',
}

import React from 'react';

import { FormComponent, Info, Modal, Tooltip } from 'components';

import { DataGrid } from 'features/Table';
import { Specification } from 'types/models/Table';

type Props<CustomState extends {}, Deps extends {}> = {
  /** @property {boolean} isOpen - Open modal with content */
  isOpen: boolean;
  /** @property {function} onClose - Callback on close action */
  onClose(): void;
  /** @property {Specification<CustomState, Deps>} specification - DataGrid specification */
  specification: Specification<CustomState, Deps>;
  /** @property {string | React.ReactElement} modalTitle - Title for modal */
  modalTitle: string | React.ReactElement;
  /** @property {string} helpText - (optional) Help text inside Description component */
  helpText?: string;
  /** @property {string} tooltipText - (optional) Tooltip text inside Description component */
  tooltipText?: string;
  /** @property {JSX.Element} info - (optional) Show info with html */
  info?: JSX.Element;
};

/**
 * Preview component for HTML
 *
 * @param {boolean} isOpen - Open modal with content
 * @param {function} onClose - Callback on close action
 * @param {Specification<CustomState, Deps>} specification - DataGrid specification
 * @param {string | React.ReactElement} modalTitle - Title for modal
 * @param {string} helpText - (optional) Help text inside Description component
 * @param {string} tooltipText - (optional) Tooltip text inside Description component
 * @param {JSX.Element} info - (optional) Show info with html
 * @returns JSX
 */
function RelationTableModal<CustomState extends {}, Deps extends {}>(props: Props<CustomState, Deps>) {
  const { isOpen, onClose, specification, modalTitle, helpText, tooltipText, info } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={modalTitle} size={['extralarge', 'large-height']}>
      <FormComponent.Wrapper>
        <FormComponent.Description mode="warning">
          {tooltipText && <Tooltip text={tooltipText} />}
          {info && <Info text={info} />}
          {helpText}
        </FormComponent.Description>

        <DataGrid<CustomState, Deps> specification={specification} />
      </FormComponent.Wrapper>
    </Modal>
  );
}

export { RelationTableModal };

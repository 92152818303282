export function PersonalContestRequestGridHelp(): JSX.Element {
  return (
    <>
      <h3>Руководителю или ответственному исполнителю проекта</h3>

      <p>
        &emsp;Учёт сведений о выполняемых научных проектах в университете ведётся сотрудниками ответственного подразделения.
        Периодически проводятся процедуры продления сроков проекта или актуализации данных о проекте (состав коллектива, смета,
        плановые значения показателей результативности, описание темы проекта и пр.).
      </p>
      <p>&emsp;Сбор сведений о проекте (этапах проекта) осуществляется через оформление заявок двух типов:</p>

      <ul>
        <li>
          <strong>Заявка на продление проекта</strong> – для участия в конкурсе на продление проекта.
        </li>
        <li>
          <strong>Заявка на актуализацию данных этапа</strong> – по требованию сотрудников ответственного за НИР и ОКР
          подразделения.
        </li>
      </ul>

      <div className="warning" data-label="Внимание!">
        <ol>
          <li>
            Добавить заявку имеет право пользователь, у которого роль в проекте — руководитель проекта или ответственный
            исполнитель. Удалить заявку можно только в статусе «Черновик».
          </li>
          <li>
            Заявка будет отображаться в списке на вкладке Актуализация моих научных проектов в личном кабинете всех членов
            коллектива, указанного в заявке, без права редактирования и удаления
          </li>
          <li>
            Подробные инструкции будут размещены в разделе{' '}
            <strong>Новости, на вкладке Справочные материалы и полезные ссылки.</strong>
          </li>
        </ol>
      </div>
    </>
  );
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function KindConsumptionGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Виды расходов из бухгалтерии загружены из информационной системы бухгалтерского учёта университета. Используются в
          сметах шифров проектов.
        </p>
        <p>Для автоматизации процессов формирования сметы рекомендуется добавить следующее:</p>
        <ul>
          <li>Актуализировать список, проставив атрибут «Действующий».</li>
          <li>
            Для вида расходов, подразумевающих выплату налога, (Заработная плата…) добавить атрибут «Рассчитывать налог», указать
            вид расхода с названием налога и процента для расчёта суммы налога. Для этого необходимо открыть вид расхода на
            редактирование, после чего отобразится форма добавления атрибутов для статьи расхода.
          </li>
          <li>
            Для видов расхода, связанных с накладными, добавить атрибут «Передавать накладные», связанных с расчётами с
            соисполнителями, добавить атрибут «Расчёты с соисполнителем» — по этому признаку система будет отображать название
            организации-контрагента на форме проекта, на вкладке «Организации-соисполнители» из документов бухгалтерского учёта и
            учитываться при формировании отчётных форм (например, 2-наука).
          </li>
        </ul>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Действующий</td>
              <td>Атрибут актуальности вида расходов для использования в ИС Бухгалтерского учёта</td>
            </tr>
            <tr>
              <td>Группа вида расхода</td>
              <td>Необязательный атрибут. Используется для аналитики.</td>
            </tr>
            <tr>
              <td>Вид расхода</td>
              <td>Код и название вида расхода «как есть» в ИС бухучёта.</td>
            </tr>
            <tr>
              <td>КВР</td>
              <td>Код видов расходов – характеристика вида расходов.</td>
            </tr>
            <tr>
              <td>КОСГУ</td>
              <td>Код и название статьи затрат в классификации КОСГУ.</td>
            </tr>
            <tr>
              <td>Процент налога</td>
              <td>
                <p>
                  Это процент налога, который будет использоваться как вспомогательный при заполнении формы с атрибутами для
                  статьи расходов.
                </p>
                <p>Его можно указать только для видов расходов, у которых включён атрибут "Рассчитать налог"</p>
              </td>
            </tr>
            <tr>
              <td>Рассчитывать налог</td>
              <td>Для вида расходов, подразумевающих выплату налога, (Заработная плата…) атрибут «Рассчитывать налог = Да».</td>
            </tr>
            <tr>
              <td>Вид расхода налога</td>
              <td>Вид расхода с названием налога.</td>
            </tr>
            <tr>
              <td>Передавать накладные</td>
              <td>
                Отметка вида расходов, который предназначен для обозначения накладных расходов в смете. Используется в
                автоматических расчётах.
              </td>
            </tr>
            <tr>
              <td>Расчёты с соисполнителем</td>
              <td>
                Отметка вида расходов, который предназначен для обозначения расчётов с соисполнителями. Используется в
                автоматических расчётах.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования атрибутов статьи расхода.</td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

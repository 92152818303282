import React, { useMemo, useState, useCallback } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Component as Form } from '../Form';
import { MergePerson } from 'features/MergePerson';
import {
  MAX_DUPLICATES_COUNT,
  MIN_DUPLICATES_COUNT,
} from 'features/Table/specifications/GetScientistDuplicateList/Toolbar/helpers';

import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const { initialCountryCode } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const { openForm, loadScientist, setPerson, setMode, mode, closeForm, isOpenForm } = customState;

  const singleSelected = useMemo(() => tableState.selectedRows.length === 1, [tableState.selectedRows.length]);
  // const multipleSelected = tableState.selectedRows.length >= 1;
  const [row] = tableState.selectedRows;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState(false);

  const handleMergeDuplicatesClick = useCallback(() => {
    setIsMergeDuplicatesOpen(true);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDuplicatesDisabled = useMemo(() => {
    const isMoreOrEqualThanMin = tableState.selectedRows.length >= MIN_DUPLICATES_COUNT;
    const isLessOrEqualThanMax = tableState.selectedRows.length <= MAX_DUPLICATES_COUNT;

    return !isMoreOrEqualThanMin || !isLessOrEqualThanMax;
  }, [tableState.selectedRows.length]);

  const selectedIds = useMemo(() => tableState.selectedRows.map(({ id }) => id), [tableState.selectedRows]);

  const confirmDelete = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: row.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Scientist',
    });
    setIsDeleteModalOpen(false);
  }, [row, tableStreams.deleteRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          loadScientist(row?.id);
          setMode('view');
          openForm();
        },
        permission: { name: Permits.PERSON_VIEW },
        isDisabled: !singleSelected,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          setPerson(getMockPerson({ initialCountryCode }));
          openForm();
        },
        permission: { name: Permits.PERSON_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          loadScientist(row?.id);
          setMode('edit');
          openForm();
        },
        permission: { name: Permits.PERSON_EDIT },
        isDisabled: !singleSelected,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteModalOpen(true),
        permission: { name: Permits.PERSON_DELETE },
        isDisabled: !singleSelected || row?.integrated === 'true',
      },
      {
        icon: { type: 'group' },
        title: 'Объединение дубликатов персон',
        onClick: handleMergeDuplicatesClick,
        permission: { name: Permits.PERSON_MERGE },
        isDisabled: isMergeDuplicatesDisabled,
      },
    ],
    [
      singleSelected,
      row,
      isMergeDuplicatesDisabled,
      handleMergeDuplicatesClick,
      setMode,
      setPerson,
      initialCountryCode,
      openForm,
      loadScientist,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Form isOpen={isOpenForm} onClose={closeForm} id={row?.id} mode={mode} />

      {isDeleteModalOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: confirmDelete,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteModalOpen(false),
            },
          ]}
          size="small"
        >
          <>({row?.FullFIO}) Удалить из справочника?</>
        </Modal>
      )}

      <MergePerson personIds={selectedIds} isOpen={isMergeDuplicatesOpen} onClose={handleCloseMergeDuplicatesForm} />
    </>
  );
}

export { Toolbar };

import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

import { Table } from 'types/models';
import { PublicationTypeCode } from 'types/models/Form';
import { DataGrid } from 'features/Table';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import useController from './controller';

type Props = {
  onClose: () => void;
  publicationTypeCode: PublicationTypeCode;
  publicationId: string;
  tableState: Table.State;
};

export const Component = memo(({ onClose, publicationTypeCode, publicationId, tableState }: Props) => {
  const {
    currentSpecification,
    isDeleteWarningPopupOpen,
    handleCloseDeleteWarningPopup,
    handleSubmitDeleteWarningPopup,
  } = useController({ publicationTypeCode, publicationId, tableState, onClose });

  return (
    <>
      {currentSpecification && <DataGrid specification={currentSpecification} />}

      <Modal
        mode="warning"
        title={`Удаление ${PublicationTypes[publicationTypeCode]?.genitiveCaseLabel || 'публикации'}`}
        isOpen={isDeleteWarningPopupOpen}
        onClose={handleCloseDeleteWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleSubmitDeleteWarningPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseDeleteWarningPopup,
          },
        ]}
        size="small"
      >
        <p>
          {`У ${PublicationTypes[publicationTypeCode]?.genitiveCaseLabel || 'публикации'} (ID: ${publicationId}) нет ${
            publicationTypeCode === PublicationTypes.MONOGRAPH.code ? 'глав' : 'статей'
          }.`}
        </p>
        <p>
          <strong>Удалить?</strong>
        </p>
      </Modal>
    </>
  );
});

import { getText } from 'services/BackendAPI/configurations/commonConverters';
import { Notification, Organization, Settings } from 'types/models/common';

export function convertSettings(settings: any): Settings {
  return {
    userDepartment: getText(settings.UserDepartment),
    notification: convertNotification(settings),
    organization: convertOrganization(settings),
  };
}

function convertNotification(settings: any): Notification {
  return {
    enabled: getText(settings['Notification.Enabled']) === 'true',
  };
}

function convertOrganization(settings: any): Organization {
  return {
    city: getText(settings['Organization.City']),
    country: parseInt(getText(settings['Organization.Country']), 10),
    fullName: getText(settings['Organization.FullName']),
    region: getText(settings['Organization.Region']),
    shortName: getText(settings['Organization.ShortName']),
    street: getText(settings['Organization.Street']),
  };
}

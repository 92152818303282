import React, { useCallback, useEffect, useMemo, useRef } from 'react';
import { setup } from 'bem-cn';

import { Icon, Info, TextInput, Tooltip } from 'components';

import { formatNumber, getArrayFromRange, KEY } from 'utils/Helpers';
import { Column, DataKind } from '../model';
import { BooleanFilter } from './filter/BooleanFilter';
import { Color } from 'constants/colors';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('list-edit-table');

type Props<Row> = {
  isStickyHeader?: boolean;
  columnsFilters?: Record<number, string>;
  setColumnsFilters?: React.Dispatch<Record<number, string>>;
  columnsSorting?: Record<number, SortType>;
  setColumnsSorting?: React.Dispatch<Record<number, SortType>>;
  rows: Row[];
  columns: Column<Row>[];
  selectedRowIndex: number | null;
  withoutHead?: boolean;
  selectRow(index: number | null): void;
  onSelectRows?(indexes: number[]): void;
  onRowDoubleClick?(index: number, row?: Row): void;
  getRowTheme?(row: Row): Color | '';
  defaultRowsCount?: number;
  tableStyles?: React.CSSProperties;
  columnIndexesForSumTotal?: number[];
  columnIndexesForIntegerTotal?: number[];
  selectedRowsIndexes?: number[];
  isVisibleFilters?: boolean;
  isSortEnabled?: boolean;
  isOpenFilters?: boolean;
};

export type SortType = 'desc' | 'asc' | 'none';

type EmptyRowsProps = {
  rowsCount: number;
  columnsCount: number;
};

function EmptyRows({ rowsCount, columnsCount }: EmptyRowsProps) {
  return (
    <>
      {getArrayFromRange({ from: 0, to: rowsCount - 1 }).map((_, index) => (
        <tr key={index} data-mode="empty">
          {getArrayFromRange({ from: 0, to: columnsCount - 1 }).map(columnIndex => (
            <td key={columnIndex} />
          ))}
        </tr>
      ))}
    </>
  );
}

type TotalRowProps<Row> = {
  columns: Column<Row>[];
  rows: Row[];
  columnIndexesForSumTotal: number[];
  columnIndexesForIntegerTotal: number[];
  selectedRowsIndexes: number[];
};

function TotalRow<Row>({
  columns,
  rows,
  columnIndexesForSumTotal,
  columnIndexesForIntegerTotal,
  selectedRowsIndexes,
}: TotalRowProps<Row>) {
  const getTotal = useCallback(
    (column: Column<Row>, columnIndex: number, onlySelected: boolean) =>
      formatNumber(
        rows.reduce((sum, row, rowIndex) => {
          const val = column.formatValue(row, rowIndex);
          const num = Number(val.toString().replaceAll(' ', ''));
          if (isNaN(num) || (onlySelected && !selectedRowsIndexes.includes(rowIndex))) {
            return sum;
          }
          return sum + num;
        }, 0),
        columnIndexesForIntegerTotal.some(num => num === columnIndex) ? 0 : 2,
      ),
    [columnIndexesForIntegerTotal, rows, selectedRowsIndexes],
  );

  return (
    <tfoot>
      <tr key={rows.length + 1} data-mode="total">
        {columns.map((column, i) => (
          <td
            key={`${column.label}-${i}-${rows.length + 1}`.toString()}
            style={{
              width: `${Math.floor(100 / columns.length)}%`,
              ...(column.styles || {}),
            }}
          >
            {columnIndexesForSumTotal.includes(i) ? getTotal(column, i, false) : ''}
          </td>
        ))}
      </tr>
    </tfoot>
  );
}

function Component<Row>(props: Props<Row>) {
  const {
    isStickyHeader = false,
    columnsFilters,
    setColumnsFilters,
    columnsSorting,
    setColumnsSorting,
    rows,
    columns,
    withoutHead = false,
    isSortEnabled = false,
    isOpenFilters = false,
    selectedRowIndex,
    defaultRowsCount,
    getRowTheme,
    selectRow,
    onRowDoubleClick,
    tableStyles,
    onSelectRows,
    columnIndexesForSumTotal = [],
    columnIndexesForIntegerTotal = [],
    selectedRowsIndexes = [],
  } = props;

  const tableRef = useRef<HTMLTableElement | null>(null);

  useEffect(() => {
    const unselectedClass = 'list-edit-table_unselected';
    function onKeyDown(event: KeyboardEvent) {
      if (event.key === KEY.SHIFT) {
        tableRef.current?.classList.add(unselectedClass);
      }
    }

    function onKeyUp(event: KeyboardEvent) {
      if (event.key === KEY.SHIFT) {
        tableRef.current?.classList.remove(unselectedClass);
      }
    }

    window.onkeydown = onKeyDown;
    window.onkeyup = onKeyUp;
    return () => {
      window.onkeydown = null;
      window.onkeyup = null;
    };
  }, []);

  const handleRowClick = (event: React.MouseEvent<HTMLTableRowElement, MouseEvent>, rowIndex: number) => {
    selectRow(selectedRowIndex !== null && rowIndex === selectedRowIndex ? null : rowIndex);

    if (onSelectRows) {
      if (event.detail > 1) {
        return;
      }

      const getSelectedIndexes = () => {
        if (event.ctrlKey) {
          const isNeedAddIndex = !selectedRowsIndexes.includes(rowIndex);
          const indexes = isNeedAddIndex
            ? [...selectedRowsIndexes, rowIndex]
            : selectedRowsIndexes.filter(selectedIndex => selectedIndex !== rowIndex);
          return indexes;
        }

        if (event.shiftKey && selectedRowIndex !== null) {
          const from = selectedRowIndex < rowIndex ? selectedRowIndex : rowIndex;
          const to = selectedRowIndex > rowIndex ? selectedRowIndex : rowIndex;
          const range = getArrayFromRange({ from, to });
          return range;
        }

        if (selectedRowsIndexes.length > 1) {
          return [rowIndex];
        }

        if (!selectedRowsIndexes.length) {
          return [rowIndex];
        }

        return selectedRowsIndexes.includes(rowIndex) ? [] : [rowIndex];
      };

      onSelectRows(getSelectedIndexes());
    }
  };

  const handleOnRowNavigate = useCallback(
    (keyDownEvent: any) => {
      const diff: number = (() => {
        if (keyDownEvent.key === KEY.ARROW_DOWN) return 1;
        if (keyDownEvent.key === KEY.ARROW_UP) return -1;
        return 0;
      })();

      if (!diff || !selectedRowsIndexes.length || !rows[selectedRowsIndexes[0] + diff]) {
        return;
      }

      keyDownEvent.preventDefault();

      onSelectRows?.([selectedRowsIndexes[0] + diff]);
    },
    [onSelectRows, rows, selectedRowsIndexes],
  );

  const makeHandleChangeColumnFilter = (index: number) => (val: string) => {
    if (setColumnsFilters) {
      setColumnsFilters({ ...columnsFilters, [index]: val });
    }
  };

  const changeColumnSorting = useCallback(
    (index: number) => {
      if (columnsSorting && setColumnsSorting) {
        const directionSort: Record<SortType, SortType> = {
          asc: 'desc',
          desc: 'none',
          none: 'asc',
        };

        const nextSortType: SortType = directionSort[columnsSorting[index] || 'none'] || 'asc';

        const nextColumnsSorting = nextSortType === 'none' ? {} : { [index]: nextSortType };
        setColumnsSorting(nextColumnsSorting);
      }
    },
    [columnsSorting, setColumnsSorting],
  );

  const onColumnClick = useCallback(
    (index: number) => () => {
      if (isSortEnabled) {
        changeColumnSorting(index);
      }
    },
    [changeColumnSorting, isSortEnabled],
  );

  const emptyRowCount = useMemo(() => {
    return (defaultRowsCount || 3) - rows.length;
  }, [defaultRowsCount, rows]);

  return (
    <table className={b({}).mix(isStickyHeader ? 'is-sticky' : '')} ref={tableRef} style={tableStyles}>
      {!withoutHead && (
        <thead>
          <tr data-mode="head">
            {columns.map((column, index) => (
              <td key={column.label} data-sortable={isSortEnabled} style={column.styles} onClick={onColumnClick(index)}>
                {column.label}
                {column.tooltip && <Tooltip text={column.tooltip} />}
                {column.info && <Info text={column.info} />}
                {isSortEnabled && Boolean(columnsSorting) && Boolean(columnsSorting![index]) && (
                  <div className={b('sort-icon')}>
                    <Icon type={columnsSorting![index] === 'asc' ? 'chevronUp' : 'chevronDown'} size={14} />
                  </div>
                )}
              </td>
            ))}
          </tr>
          {isOpenFilters && (
            <tr data-mode="filter">
              {columns.map((column, index) => {
                return (
                  <td key={index}>
                    {column.dataKind === DataKind.BOOLEAN ? (
                      <BooleanFilter
                        value={columnsFilters ? columnsFilters[index] : ''}
                        updateValue={makeHandleChangeColumnFilter(index)}
                      />
                    ) : (
                      <TextInput
                        value={columnsFilters ? columnsFilters[index] ?? '' : ''}
                        onChange={makeHandleChangeColumnFilter(index)}
                      />
                    )}
                  </td>
                );
              })}
            </tr>
          )}
        </thead>
      )}
      <tbody onKeyDownCapture={handleOnRowNavigate} tabIndex={-1}>
        {rows.map((row, index) => (
          <tr
            key={index}
            data-mode={
              (selectedRowIndex !== null && selectedRowsIndexes.includes(index)) || index === selectedRowIndex ? 'selected' : ''
            }
            data-hightlight={getRowTheme?.(row)}
            onDoubleClick={() => onRowDoubleClick?.(index, row)}
            onClick={event => handleRowClick(event, index)}
          >
            {columns.map((column, i) => {
              const value = column.formatValue(row, index);
              const valueType = typeof value;
              const isStringOrNumber = ['string', 'number'].includes(valueType);
              const isFloat = isStringOrNumber && column.dataKind === DataKind.FLOAT;
              return (
                <td
                  key={`${column.label}-${i}-${index}`.toString()}
                  style={{
                    width: `${Math.floor(100 / columns.length)}%`,
                    ...(isFloat ? { textAlign: 'right' } : {}),
                    ...(column.dataKind === DataKind.JSX ? { display: 'table', margin: 'auto' } : {}),
                    ...(column.styles || {}),
                  }}
                  dangerouslySetInnerHTML={
                    isFloat
                      ? { __html: value === '' ? '' : formatNumber(value.toString()) }
                      : isStringOrNumber
                      ? { __html: value.toString() }
                      : undefined
                  }
                >
                  {valueType === 'boolean' && value ? (
                    <div className={b('icon-center')}>
                      <Icon type="check" size={16} />
                    </div>
                  ) : !isStringOrNumber ? (
                    value
                  ) : undefined}
                </td>
              );
            })}
          </tr>
        ))}
        {emptyRowCount > 0 && <EmptyRows columnsCount={columns.length} rowsCount={emptyRowCount} />}
      </tbody>
      {columnIndexesForSumTotal.length > 0 && (
        <TotalRow
          columns={columns}
          rows={rows}
          columnIndexesForSumTotal={columnIndexesForSumTotal}
          columnIndexesForIntegerTotal={columnIndexesForIntegerTotal}
          selectedRowsIndexes={selectedRowsIndexes}
        />
      )}
    </table>
  );
}

export const ListEditTable = React.memo(Component) as typeof Component;

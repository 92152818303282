export default [
  {
    id: '1',
    label: 'Статья в сборнике материалов конференций, симпозиумов и др.',
    code: 'ARTICLE_CONFERENCE',
  },
  {
    id: '2',
    label: 'Тезисы',
    code: 'THESIS',
  },
];

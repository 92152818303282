import React from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { Item } from 'types/models/common';
import { GetProgramList } from 'features/Table/specifications';
import { tableRowConverter } from './helpers';
import { Table } from 'types/models';

type Props = {
  program: Item<string> | null;
  setProgram: (program: Item<string> | null) => void;
  isDisabled: boolean;
};

export const SelectProgram = ({ program, setProgram, isDisabled }: Props) => (
  <FormComponent.Field label="Программа\Грант">
    <TextArea
      mode={TextAreaMode.TABLE}
      settings={{
        title: 'Программы',
        table: {
          specification: GetProgramList({ withoutMenu: true }),
          onSelect: (row: Table.Entry | null) => setProgram(row ? tableRowConverter(row) : null),
        },
      }}
      value={program?.label}
      isDisabled={isDisabled}
    />
  </FormComponent.Field>
);

import React, { useState, useEffect } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { ElectronicType } from 'utils/Enums/ElectronicType';

const MagazineAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id, name } = props;
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(ElectronicType.WEB);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.OtherFormLook = {
    id,
    apiID: 'GetPublication',
    type: 'OTHER_BOOK',
    template: 'PublicationFormOther',
    name,
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      setElectronicPublicationType(ElectronicType.WEB);
      setIsElectronic(false);
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MagazineAddTemplate);

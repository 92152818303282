import { Table, Department, Form } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { TypeEditionCode } from 'utils/Enums/TypeEditionCode';
import { Settings } from 'types/models/common';
import { Departments } from 'features/Table/views';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

export enum ModePublicationHeader {
  articles = 'articles',
  citation = 'citation',
  preprint = 'preprint',
  release = 'release',
  default = 'default',
}

type Props = {
  settings: Settings | null | undefined;
  mode: ModePublicationHeader;
  workMode: Table.WorkMode;
  departments: Department[];
  status: string;
  publicationId?: string;
  publicationName?: string;
  publicationTypeCode: string;
  publicationElectronicTypeCode?: string;
  publicationTypeEdition: Form.EditionType;
  isPreprint: boolean;
};

export const PublicationHeader = ({
  settings,
  mode,
  workMode,
  departments,
  status,
  publicationId = '',
  publicationName = '',
  publicationTypeCode,
  publicationElectronicTypeCode = '',
  publicationTypeEdition,
  isPreprint,
}: Props): string | JSX.Element => {
  const publicationType = (() => {
    const publicationTypesArray = Object.entries(PublicationTypes);

    const currentPublicationTypeEntrie = publicationTypesArray.find(([, { code }]) => code === publicationTypeCode);
    if (currentPublicationTypeEntrie) {
      const currentPublicationType: Form.PublicationType = currentPublicationTypeEntrie[1];
      return currentPublicationType;
    }

    return null;
  })();

  const publicationElectronicType = (() => {
    const electroncTypesArray = Object.entries(ElectronicType);
    const currentElectronicTypeEntrie = electroncTypesArray.find(([, { code }]) => code === publicationElectronicTypeCode);

    if (currentElectronicTypeEntrie) {
      const currentElectronicType: Form.ElectronicType = currentElectronicTypeEntrie[1];
      return currentElectronicType;
    }

    return null;
  })();

  const hint = (() => {
    const currentTypeEditionCode = publicationTypeEdition.id;

    const maybeNotLocalPublicationTypes = [
      PublicationTypes.COMPILATION_CONFERENCE.code,
      PublicationTypes.COMPILATION_OTHER.code,
      PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code,
      PublicationTypes.MONOGRAPH.code,
      PublicationTypes.OTHER_BOOK.code,
    ];

    const isLocal = currentTypeEditionCode === TypeEditionCode.LOCAL;

    if (isLocal || !currentTypeEditionCode || !maybeNotLocalPublicationTypes.some(typeCode => publicationTypeCode === typeCode)) {
      // eslint-disable-next-line max-len
      return 'Добавить связь с подразделением с помощью кнопки "Редактировать связь с подразделением". При сохранении публикации автоматически привязывается к подразделению пользователя, добавляющего запись.';
    }
    // eslint-disable-next-line max-len
    return `Публикацию с видом издания "${publicationTypeEdition.label}" нельзя привязать к подразделениям ${
      settings?.organization?.shortName ?? ''
    }`;
  })();

  const baseTitle = (() => {
    return [
      publicationType?.label ? `типа "${publicationType?.label}"` : '',
      publicationName ? `"${publicationName}"` : '',
      getStatusRecordMessage('', publicationId, workMode === 'addMode' ? '' : status),
      publicationElectronicType ? `(${publicationElectronicType.label})` : '',
      isPreprint ? '(База препринтов)' : '',
    ]
      .filter(Boolean)
      .join(' ');
  })();

  const title = (() => {
    const actions = {
      articles: 'Статьи',
      citation: 'Индексация и показатели',
      preprint: 'Редактирование связи',
      release: 'Выпуски',
      default: (() => {
        if (workMode === 'addMode') {
          return 'Добавление';
        }
        if (workMode === 'viewMode') {
          return 'Просмотр';
        }
        return 'Редактирование';
      })(),
    };

    return [actions[mode], baseTitle].filter(Boolean).join(' ');
  })();

  if (departments && publicationTypeCode !== PublicationTypes.MAGAZINE.code) {
    return (
      <>
        <h3>{title}</h3>
        <h4>
          <Departments hint={hint} departments={departments.map(department => department?.ref?.label).join(', ')} />
        </h4>
      </>
    );
  }

  return title;
};

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
  customState: State;
};

const ToolbarTemplate = ({ tableState, onSubmitTable, customState: { programId } }: Props) => {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ProgramEvent',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: handleSelectButtonClick,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PROGRAM_EVENT_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PROGRAM_EVENT_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PROGRAM_EVENT_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.PROGRAM_EVENT_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
    ],
    [
      rowsAvailability,
      isViewButtonDisabled,
      handleSelectButtonClick,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
    ],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.ProgramEventViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          parentRecordId={programId || '-1'}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.ProgramEventEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          parentRecordId={programId || '-1'}
        />
      )}

      <modalTemplates.ProgramEventAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
        parentRecordId={programId || '-1'}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.EventName}) Удалить мероприятие?</>
        </Modal>
      )}
    </>
  );
};

export const Component = React.memo(ToolbarTemplate);

import { useCallback, useState, useMemo, useEffect } from 'react';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Notification } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { getMockNotification } from './helpers';
import { ButtonProps } from 'components';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId },
  } = useFormContext();

  const [notification, setNotification] = useState<Notification.Notification>({
    ...getMockNotification(),
    id: initialId || null,
  });

  const { methods: getNotificationAPI } = BackendAPI.useBackendAPI('GetNotification');
  const { methods: saveNotificationAPI } = BackendAPI.useBackendAPI('SaveNotification');

  const getNotification = useCallback(
    (id: string) => {
      getNotificationAPI.callAPI(
        { ids: [id] },
        {
          onSuccessfullCall: ({ data }) => {
            setNotification(data[0] || null);
          },
        },
      );
    },
    [getNotificationAPI],
  );

  const saveNotification = useCallback(() => {
    saveNotificationAPI.callAPI(
      { notifications: [notification] },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Уведомление сохранено', theme: 'success' });
          tableStreams.reloadTable.push({});
          onClose();
        },
      },
    );
  }, [notification, onClose, saveNotificationAPI, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: saveNotification,
      },
    ],

    [saveNotification],
  );

  useEffect(() => {
    if (initialId) getNotification(initialId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    notification,
    setNotification,
    buttons,
  };
}

export function RequestPerformerGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Функциональный блок <strong>Список участников заявок на конкурсы</strong> содержит список участников заявок и предназначен
        для контроля количества участий одного физлица в нескольких заявках. Для этого выделять в составе коллектива тех людей,
        которые участвуют в нескольких заявках и указывать количество заявок.
      </p>
      <p>
        Пункт меню <strong>Заявки &rarr; Список участников заявок на конкурсы</strong> содержит в табличном виде информацию обо
        всех сотрудниках, которые принимали участие в заявках конкурсов. Информация в столбцах динамически меняется из
        регистрированных в системе заявок, а также при смене статуса заявки:
      </p>

      <h4>Правила отображения данных в столбцах таблицы</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Столбец</th>
            <th style={{ width: '80%' }}>Назначение</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>ID</td>
            <td>ID персоны</td>
          </tr>
          <tr>
            <td>ФИО</td>
            <td>Фамилия Имя Отчество участника</td>
          </tr>
          <tr>
            <td>Всего заявок, шт.</td>
            <td>Общее кол-во заявок = Заявка на конкурс + Заявка на внутренний конкурс + Заявка на мобильность</td>
          </tr>
          <tr>
            <td>Поддержана, шт.</td>
            <td>
              Количество заявок в статусе <strong>Поддержана</strong>
            </td>
          </tr>
          <tr>
            <td>Отклонена, шт.</td>
            <td>
              Количество заявок в статусе <strong>Отклонена</strong>
            </td>
          </tr>
          <tr>
            <td>Оформляются, шт.</td>
            <td>
              Количество заявок в статусах, кроме <strong>Поддержана</strong> и <strong>Отклонена</strong>
            </td>
          </tr>
          <tr>
            <td>ID_Оформляются</td>
            <td>
              Заявки в статусах, кроме <strong>Поддержана</strong> и <strong>Отклонена</strong>, выводить в формате Тип заявки ID:
              &lt;ID&gt;-&lt;роль в заявке&gt;
            </td>
          </tr>
          <tr>
            <td>ID_Поддержаные</td>
            <td>Заявки в статусе Поддержана, выводить в формате Тип заявки ID: &lt;ID&gt;-&lt;роль в заявке&gt;</td>
          </tr>
          <tr>
            <td>Проекты</td>
            <td>Выводить в формате Тип проекта ID: &lt;ID&gt;-&lt;роль в проекте&gt;</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import React, { useEffect } from 'react';

import { Tab, Tabs, FormComponent } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { AboutPurchaseRequest } from 'features/Form/looks/project/PurchaseRequestForm/Tabs';
import { Toolbar } from './Toolbar';

import { Form as F } from 'types/models';
import { DataGrid } from 'features/Table';
import { Documents } from 'features/Documents';
import { GetPurchaseRequestFeedlineList } from 'features/Table/specifications/GetPurchaseRequestFeedlineList';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose(): void;
  viewMode?: boolean;
  editMode?: boolean;
};

function PurchaseRequestForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle, approvementButtons = [] },
  } = useFormContext<F.PurchaseRequestFormLook>();

  const {
    purchaseRequest,
    makeChangeHandler,
    handleSave,
    workMode,
    setPurchaseRequest,
    isLess100Products,
    isLess100Services,
    isMore100,
    isFinanceResponsibleRequired,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  useEffect(() => {
    setTitle?.(
      getStatusMessage(
        [
          workMode === 'viewMode' ? 'Просмотр' : workMode === 'editMode' ? 'Редактирование' : 'Добавление',
          'заявки на закупку',
          purchaseRequest?.number ? `№ ${purchaseRequest?.number}` : '',
        ]
          .filter(Boolean)
          .join(' '),
        '',
        purchaseRequest?.status?.label,
      ),
    );
  }, [purchaseRequest?.contractKind, purchaseRequest?.number, purchaseRequest?.status?.label, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar
        purchaseRequest={purchaseRequest}
        workMode={workMode}
        onSave={handleSave(true)}
        onSaveAndContinue={handleSave(false)}
        approvementButtons={approvementButtons}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Заявка на закупку">
            <AboutPurchaseRequest
              disabled={workMode === 'viewMode'}
              purchaseRequest={purchaseRequest}
              makeChangeHandler={makeChangeHandler}
              setPurchaseRequest={setPurchaseRequest}
              isLess100Products={isLess100Products}
              isLess100Services={isLess100Services}
              isMore100={isMore100}
              isFinanceResponsibleRequired={isFinanceResponsibleRequired}
            />
          </Tab>
          <Tab title="Документы">
            <FormComponent.Description mode="warning">
              {isLess100Products && (
                <p>
                  Прикрепите 1-3 коммерческих предложения. В названии документа укажите краткое наименование организации и номер
                  коммерческого предложения, например: ООО "Альфа" №123 или ООО "Бета" Б/Н (если номер отсутствует)
                </p>
              )}
              {isLess100Services && <p>Необходимо прикрепить проект договора</p>}
              {isMore100 && (
                <p>
                  Прикрепите Техническое задание и 1-3 коммерческих предложения. В названии документа для коммерческого
                  предложения укажите краткое наименование организации и номер коммерческого предложения, например: ООО "Альфа"
                  №123 или ООО "Бета" Б/Н (если номер отсутствует)
                </p>
              )}
            </FormComponent.Description>
            <Documents
              documents={purchaseRequest?.documents ?? []}
              disabled={workMode === 'viewMode'}
              setDocuments={makeChangeHandler('documents')}
              fieldsType="purchaseRequest"
            />
          </Tab>
          {purchaseRequest.id && (
            <Tab title="Журнал сообщений">
              <DataGrid
                specification={GetPurchaseRequestFeedlineList({
                  purchaseRequestId: purchaseRequest.id || '-1',
                  isDisabledToolbar: workMode === 'viewMode',
                })}
              />
            </Tab>
          )}
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PurchaseRequestForm);

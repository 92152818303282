import { Icon } from 'components';

export function PersonalPublicationGridHelp(): JSX.Element {
  return (
    <>
      <h3>Вы можете добавить авторские публикации таких типов как:</h3>

      <ul>
        <li>Статья в сборнике материалов конференций, симпозиумов и др.</li>
        <li>Статья в сборнике научных трудов</li>
        <li>Статья в журнале</li>
        <li>Статья в справочном издании</li>
        <li>Статья в другом издании</li>
        <li>Тезисы</li>
        <li>Автореферат диссертации</li>
        <li>Диссертация</li>
        <li>Монография</li>
        <li>Глава в коллективной монографии</li>
        <li>Другое издание</li>
        <li>Другое учебное издание</li>
        <li>Сборник материалов конференций, симпозиумов и др.</li>
        <li>Сборник научных трудов</li>
        <li>Справочное издание</li>
        <li>Учебник</li>
        <li>Учебное пособие</li>
        <li>Учебно-методическое пособие</li>
        <li>Препринт</li>
      </ul>

      <div className="warning">
        <span>
          Для каждого типа публикации используются форма, учитывающая особенности при описании сведений об издании, в том числе
          электронного или печатного; наукометрических показателях; связях с другими объектами системы: научными проектами,
          конференцией – для материалов конференций.
        </span>
      </div>

      <h4>Перед добавлением публикации необходимо выбрать:</h4>
      <ul className="dashed">
        <li>тип публикации,</li>
        <li>указать является ли издание электронным или печатным,</li>
        <li>проверить наличие этой публикации в базе системы.</li>
      </ul>

      <div className="warning">
        <span>
          Система автоматически добавляет данные о вас в сведения об авторах и привязывает публикацию к вашему подразделению.
        </span>
      </div>

      <p>Добавленную вами публикацию проверит и утвердит ответственное лицо.</p>

      <h4>Назначение функциональных элементов интерфейса</h4>

      <table>
        <thead>
          <tr>
            <th>Элемент</th>
            <th>Описание интерфейса</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Мои публикации</td>
            <td>Фильтр списка публикаций, в которых ваша персона указана в разделе Сведения об авторах</td>
          </tr>
          <tr>
            <td>Все публикации</td>
            <td>Фильтр списка всех публикаций, которые есть в системе</td>
          </tr>
          <tr>
            <td>Год</td>
            <td>
              Фильтр формирует список публикаций, сроки издания которых содержат указанный год, если фильтр отключен, то список
              содержит все публикации базы данных. Фильтр накладывает условия отбора с учётом других фильтров
            </td>
          </tr>
          <tr>
            <td>
              Выбрать столбцы: <Icon type="table" />
            </td>
            <td>Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.</td>
          </tr>
          <tr>
            <td>
              Показать/спрятать фильтры: <Icon type="filter" />
            </td>
            <td>
              Фильтр формирует список публикаций, сроки издания которых содержат указанный год, если фильтр отключен, то список
              содержит все публикации базы данных. Фильтр накладывает условия отбора с учётом других фильтров
            </td>
          </tr>
          <tr>
            <td>
              Очистить все поля фильтрации: <Icon type="filter" mode="remove" />
            </td>
            <td>Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.</td>
          </tr>
          <tr>
            <td>
              Обновить: <Icon type="refresh" />
            </td>
            <td>Очищает установленные в текущей таблице параметры сортировки и поиска данных в столбцах.</td>
          </tr>
          <tr>
            <td>
              Просмотр: <Icon type="view" />
            </td>
            <td>
              Открывает форму просмотра публикации. Кнопка активна при выделении публикации в таблице. Доступна пользователю в
              любом статусе Публикации
            </td>
          </tr>
          <tr>
            <td>
              Добавить: <Icon type="add" />
            </td>
            <td>Открывает форму для добавления публикации</td>
          </tr>
          <tr>
            <td>
              Редактировать: <Icon type="edit" />
            </td>
            <td>
              Открывает форму редактирования публикации. Кнопка активна при выделении публикации в таблице. Пользователь имеет
              {/* eslint-disable-next-line max-len */}
              право редактировать свою публикацию в статусах <strong>Черновик</strong> или <strong>Добавлена.</strong> В статусе{' '}
              <strong>Утверждена</strong> для редактирования доступны только вкладки:{' '}
              <strong>Документы, Журнал сообщений, Финансовая поддержка</strong>
            </td>
          </tr>
          <tr>
            <td>
              Удалить: <Icon type="remove" />
            </td>
            <td>
              Пользователь имеет право удалить только ту публикацию, которую добавил и если она в статусах{' '}
              <strong>Черновик</strong> или
              <strong>Добавлена.</strong> С помощью кнопки удаляет выделенную в таблице публикацию.
            </td>
          </tr>
          <tr>
            <td>
              Редактировать связь публикации с подразделениями: <Icon type="connection" mode="edit" />
            </td>
            <td>
              Публикация может относиться к нескольким подразделениям и использоваться в отчёте каждого из указанных подразделений
            </td>
          </tr>
          <tr>
            <td>
              Показатели публикации: <Icon type="statistic" />
            </td>
            <td>
              Открывает форму с наукометрическими показателями публикации в базах цитирования. Добавить значение показателя
              возможно только при наличии ID публикации в базе цитирования
            </td>
          </tr>
          <tr>
            <td>
              Показатели источника: <Icon type="statistic" mode="view" />
            </td>
            <td>
              Открывает форму с наукометрическими показателями издания публикации (журнал, сборник, база препринтов) в базах
              цитирования.
            </td>
          </tr>
          <tr>
            <td>
              Открыть публикацию во внешнем источнике: <Icon type="link" />
            </td>
            <td>
              Перейти по ссылке на страницу с публикацией в других Интернет-ресурсах (DOI, электронный каталог Научной библиотеки,
              др.). Ссылка открывается в новом окне браузера
            </td>
          </tr>
          <tr>
            <td>
              Скачать файл, прикрепленный к публикации: <Icon type="download" />
            </td>
            <td>
              Скачанный файл помещается в системную папку <strong>Загрузки</strong> на устройстве пользователя
            </td>
          </tr>
          <tr>
            <td>
              Указать статью-оригинал/переводную статью: <Icon type="documents" mode="edit" />
            </td>
            <td>
              Если в систему добавлены 2 варианта статьи в переводном журнале и оригинальном, желательно указать явную связь между
              этими статьями. Функциональная кнопка открывает форму связи между оригинальной и переводной статьёй.
              Библиографическая ссылка связанной статьи будет отображаться на форме публикации, на вкладке{' '}
              <strong>О публикации</strong>
            </td>
          </tr>
          <tr>
            <td>
              Финансовая поддержка публикации: <Icon type="currency" mode="edit" />
            </td>
            <td>
              В случае, если статья является результатом выполнения научного проекта, необходимо выбрать из списка Научных
              проектов. Ответственный по качеству научных отчётов при наличии прав, Принимает к отчёту по проекту указанную
              публикацию. Принятые к отчёту по проекту публикации отображаются на форме проекта на вкладке{' '}
              <strong>Результаты проекта, Публикации</strong>
            </td>
          </tr>
          <tr>
            <td>
              Отчеты: <Icon type="print" />
            </td>
            <td>Открывает список шаблонов для формирования регламентированных отчётов</td>
          </tr>
          <tr>
            <td>
              Выгрузить в файл: <Icon type="excel" />
            </td>
            <td>Список публикаций выгружает из таблицы в файл .xlsx для последующей обработки в Excel</td>
          </tr>
          <tr>
            <td>
              Задать вопрос администратору: <Icon type="admin" />
            </td>
            <td>
              Если вы видите ошибки в утверждённой записи - напишите письмо Администратору. Функциональная кнопка открывает форму
              для добавления сообщения. Все сообщения хранятся в Системе, на странице{' '}
              <strong>Главная, Вопросы от пользователей администраторам.</strong> Список сообщений конкретного пользователя и
              ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
              <strong>Новости, Вопрос - ответ</strong>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import { EmptyToolbar } from 'features/Table/views';
import { Table } from 'types/models';

type Arguments = {
  projectId: string | null;
  projectCodeId?: string | null;
};

export function GetProjectBoAccompliceList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectBoAccompliceList',
    requestData: args,
    header: {
      firstLevel: {
        title: 'Список организаций на основе данных бухгалтерского учёта об оплате по договорам (расчёты с соисполнителем)',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: EmptyToolbar,
      },
    },
    isFiltersHidden: true,
  };
}

import { useState, useEffect, useRef } from 'react';
import { useStream } from 'StreamRx';

import { useLocalLoaderStreams } from './hooks';

export function useController() {
  const LOADER_TIME = 120;
  const streams = useLocalLoaderStreams();

  const [requestsCount, setRequestsCount] = useState<number>(0);
  const [isLoad, setIsLoad] = useState<boolean>(false);
  const [isUpload, setIsUpload] = useState<boolean>(false);
  const [isFinished, setIsFinished] = useState<boolean>(false);

  const timerLoader = useRef<any>();
  const timerFinished = useRef<any>();

  useStream(
    () => streams.updateIsLoading,
    ({ isLoading, isUploading }) => {
      setRequestsCount((prevState: number) => prevState + (!!isLoading || !!isUploading ? 1 : -1));
      setIsUpload(isUploading !== undefined);
    },
    [],
  );

  useEffect(() => {
    if (requestsCount < 0) setRequestsCount(0);

    if (requestsCount > 0) {
      clearTimeout(timerLoader.current);
      setIsFinished(false);
      timerLoader.current = setTimeout(() => {
        setRequestsCount(0);
      }, LOADER_TIME * 1000);
    }

    if (requestsCount === 0) {
      setIsFinished(true);
      timerFinished.current = setTimeout(() => {
        setIsLoad(false);
        setIsFinished(false);
      }, 0.2 * 1000);
    } else {
      clearTimeout(timerFinished.current);
      setIsLoad(true);
    }
  }, [requestsCount, timerLoader, timerFinished]);

  return { isLoading: isLoad, isUploading: isUpload, isFinished };
}

import React, { useCallback } from 'react';

import { FormComponent } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { BaseFields } from './BaseFields';
import { HeadRoleFields } from './HeadRoleFields';
import { CoExecutorFields } from './CoExecutorFields';

type Props = {
  partner: Project.Partner;
  mode: 'view' | 'add' | 'edit' | null;
  setPartner(partner: Project.Partner | null): void;
};

export function Fields(props: Props) {
  const { partner, setPartner, mode } = props;

  const makeChangeHandler = useCallback(
    (key: keyof Project.Partner) => (value: ValueOf<Project.Partner>) => {
      setPartner({ ...partner, [key]: value });
    },
    [partner, setPartner],
  );

  return (
    <FormComponent.Wrapper>
      <BaseFields mode={mode} partner={partner} makeChangeHandler={makeChangeHandler} setPartner={setPartner} />
      {partner.role?.value === 'HEAD_PERFORMER' && <HeadRoleFields partner={partner} makeChangeHandler={makeChangeHandler} />}
      {partner.role?.value === 'PARTNER' && <CoExecutorFields partner={partner} makeChangeHandler={makeChangeHandler} />}
    </FormComponent.Wrapper>
  );
}

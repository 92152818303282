import React from 'react';

import { FormComponent, ListEdit, TextInput } from 'components';

import { TenderProtocol } from 'types/models/TenderProtocol';
import { WorkMode } from 'types/models/Table';
import { ValueOf } from 'types/helpers';
import { formatNumber, getEnumItemLabel } from 'utils/Helpers';
import { GetProtocolCommissionMemberList } from 'features/Table/specifications/GetProtocolCommissionMemberList';
import { getMockCommissionMember } from 'features/Form/looks/protocolCommission/ProtocolCommissionForm/helpers';
import { ProtocolMainFields } from 'features/Form/views';
import { MobileRequestFinancingType, MobileRequestStatus, NirRequestStatus, RequestStatus } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getMockPerson } from 'features/Form/looks/person/helpers';

type Props = {
  tenderProtocol: TenderProtocol;
  workMode: WorkMode;
  makeChangeHandler: (key: keyof TenderProtocol) => (val: ValueOf<TenderProtocol>) => void;
};
export function AboutProtocol(props: Props) {
  const { tenderProtocol, workMode, makeChangeHandler } = props;
  const { enumMap } = useAppDataContext();

  const programRequestsStatuses = [
    RequestStatus.REQUEST,
    RequestStatus.REVISION,
    RequestStatus.REJECTED,
    RequestStatus.RECOMENDED,
  ];
  const nirRequestStatuses = [
    NirRequestStatus.REQUEST,
    NirRequestStatus.REVISION,
    NirRequestStatus.REJECTED,
    NirRequestStatus.AGREED,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestStatus.SUPPORTED,
  ];
  const mobileRequestStatuses = [
    MobileRequestStatus.REQUEST,
    MobileRequestStatus.REVISION,
    MobileRequestStatus.REJECTED,
    MobileRequestStatus.RECOMENDED,
  ];

  const programRequestCount = (tenderProtocol.tender?.programRequests || []).filter(x =>
    programRequestsStatuses.includes(x.status?.value as RequestStatus),
  ).length;
  const nirRequestCount = (tenderProtocol.tender?.nirRequests || []).filter(x =>
    nirRequestStatuses.includes(x.status?.value as NirRequestStatus),
  ).length;
  const mobileRequestCount = (tenderProtocol.tender?.mobileRequests || []).filter(x =>
    mobileRequestStatuses.includes(x.status?.value as MobileRequestStatus),
  ).length;

  const getFilteredProgramRequest = (key: RequestStatus) =>
    (tenderProtocol.tender?.programRequests || []).filter(x => x.status?.value === key);
  const getFilteredNirRequest = (key: NirRequestStatus) =>
    (tenderProtocol.tender?.nirRequests || []).filter(x => x.status?.value === key);
  const getFilteredMobileRequest = (key: MobileRequestStatus) =>
    (tenderProtocol.tender?.mobileRequests || []).filter(x => x.status?.value === key);

  const calcRequestByStatus = (status: RequestStatus, sf?: boolean) =>
    getFilteredProgramRequest(status)
      .reduce((a, b) => a + b.finances.reduce((c, d) => c + Number(!!sf ? d.sfAmount : d.fbAmount), 0), 0)
      .toString() || 0;

  const calcNirRequestByStatus = (status: NirRequestStatus) =>
    getFilteredNirRequest(status)
      .reduce((a, b) => a + b.stages.reduce((c, d) => c + Number(d.amount), 0), 0)
      .toString() || 0;

  const calcMobileRequestByStatus = (status: MobileRequestStatus, types?: MobileRequestFinancingType[]) =>
    getFilteredMobileRequest(status).reduce(
      (a, b) =>
        a +
        b.financings.reduce(
          (c, d) => c + (!types || types.includes(d.type?.value as MobileRequestFinancingType) ? Number(d.amount) : 0),
          0,
        ),
      0,
    ) || 0;

  const amountSum = () => {
    if (!!programRequestCount) {
      return formatNumber(calcRequestByStatus(RequestStatus.RECOMENDED), 2);
    }

    if (!!nirRequestCount) {
      return formatNumber(
        Number(calcNirRequestByStatus(NirRequestStatus.AGREED)) +
          Number(calcNirRequestByStatus(NirRequestStatus.AGREED_WITH_CONDITION)) +
          Number(calcNirRequestByStatus(NirRequestStatus.SUPPORTED)),
        2,
      );
    }

    if (!!mobileRequestCount) {
      return formatNumber(calcMobileRequestByStatus(MobileRequestStatus.RECOMENDED, [MobileRequestFinancingType.MAIN]), 2);
    }

    return formatNumber(0, 2);
  };

  const amountSofinanceSum = (types?: MobileRequestFinancingType[]) => {
    if (!!programRequestCount) {
      return formatNumber(calcRequestByStatus(RequestStatus.RECOMENDED, true), 2);
    }

    if (!!mobileRequestCount) {
      return formatNumber(calcMobileRequestByStatus(MobileRequestStatus.RECOMENDED, types), 2);
    }

    return formatNumber(0, 2);
  };

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '54%' }}>
        <ProtocolMainFields
          workMode={workMode}
          tenderProtocol={tenderProtocol}
          changeTender={makeChangeHandler('tender')}
          changeNumber={makeChangeHandler('number')}
          changeProtocolDate={makeChangeHandler('protocolDate')}
          changeProtocolCommission={makeChangeHandler('protocolCommission')}
        />

        <FormComponent.Field>
          <ListEdit
            header={{ title: 'Присутствовали на заседании' }}
            rows={tenderProtocol.commissionMembers || []}
            onChange={makeChangeHandler('commissionMembers')}
            toolbar={['add', 'edit', 'delete']}
            columns={[
              { label: 'Роль', formatValue: row => row.role?.label || '' },
              { label: 'ФИО', formatValue: row => row?.person?.fullName || '' },
            ]}
            defaultRowsCount={10}
            isDisabled={workMode === 'viewMode'}
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: `Члены комиссии ${tenderProtocol.protocolCommission?.name}`,
              modalTableRowConverter: row => {
                return {
                  ...getMockCommissionMember(),
                  person: {
                    ...getMockPerson({}),
                    fullName: row.Person,
                  },
                  id: row.id,
                  role: { label: row.Role, value: '' },
                  priority: parseInt(row.Priority),
                };
              },
              modalTableSpecification: GetProtocolCommissionMemberList({
                protocolCommissionId: tenderProtocol.protocolCommission?.id,
              }),
            }}
          />
        </FormComponent.Field>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: '40%' }}>
        <FormComponent.Field label="Вид формы заявки">
          <div>{tenderProtocol.tender?.requestType?.label}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Всего заявок">
          <div>{programRequestCount + nirRequestCount + mobileRequestCount}</div>
        </FormComponent.Field>
        <>
          {!!programRequestCount &&
            programRequestsStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat>
                <FormComponent.Field label={getEnumItemLabel('RequestStatus', key, enumMap)}>
                  {getFilteredProgramRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field label="Сумма" isHidden={key !== RequestStatus.RECOMENDED}>
                  {formatNumber(calcRequestByStatus(key), 2)}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          {!!nirRequestCount &&
            nirRequestStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat>
                <FormComponent.Field label={getEnumItemLabel('NirRequestStatus', key, enumMap)}>
                  {getFilteredNirRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field
                  label="Сумма"
                  isHidden={
                    ![NirRequestStatus.AGREED, NirRequestStatus.AGREED_WITH_CONDITION, NirRequestStatus.SUPPORTED].includes(key)
                  }
                >
                  {formatNumber(calcNirRequestByStatus(key), 2)}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          {!!mobileRequestCount &&
            mobileRequestStatuses.map((key, index) => (
              <FormComponent.Line key={index} hasFreeFormat>
                <FormComponent.Field label={getEnumItemLabel('MobileRequestStatus', key, enumMap)}>
                  {getFilteredMobileRequest(key).length.toString()}
                </FormComponent.Field>
                <FormComponent.Field label="Сумма" isHidden={key !== MobileRequestStatus.RECOMENDED}>
                  {formatNumber(calcMobileRequestByStatus(key), 2)}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
        </>
        <FormComponent.Field label="Объём по основному источнику">
          <div>{amountSum()}</div>
        </FormComponent.Field>

        {(!!programRequestCount || !!mobileRequestCount) && (
          <FormComponent.Field label={`Софинансирование${!!mobileRequestCount ? ',<br />в том числе:' : ''}`}>
            <div>{amountSofinanceSum([MobileRequestFinancingType.SF1, MobileRequestFinancingType.SF2])}</div>
          </FormComponent.Field>
        )}
        {!!mobileRequestCount && (
          <>
            <FormComponent.Field label="из Средств подразделений">
              <div>{amountSofinanceSum([MobileRequestFinancingType.SF2])}</div>
            </FormComponent.Field>
            <FormComponent.Field label="из Средств проектов">
              <div>{amountSofinanceSum([MobileRequestFinancingType.SF1])}</div>
            </FormComponent.Field>
          </>
        )}

        <FormComponent.Field
          label="О ходе проведения конкурса на заседании комиссии слушали"
          tooltip="Для оформления протокола введите в родительном падеже Фамилию и инициалы докладчика"
        >
          <TextInput
            value={tenderProtocol.reporter}
            onChange={makeChangeHandler('reporter')}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { Project } from 'types/models';
import { ScientistPerson } from 'types/models/Person';

export function validate(jobPeriod: Project.JobPeriod | null, person: ScientistPerson | null) {
  if (!jobPeriod || !person) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidRole = Boolean(jobPeriod.role);
  const isValidStartDate = Boolean(jobPeriod.startDate);
  const isValidEndDate = Boolean(jobPeriod.endDate);

  return [
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: isValidStartDate, invalidMessage: 'Выберите дату начала периода работы' },
    { isValid: isValidEndDate, invalidMessage: 'Выберите дату окончания периода работы' },
  ];
}

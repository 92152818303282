export enum KEY {
  ENTER = 'Enter',
  SHIFT = 'Shift',
  CONTROL = 'Control',
  ARROW_UP = 'ArrowUp',
  ARROW_DOWN = 'ArrowDown',
}

export const getArrayFromRange = ({ from, to }: { from: number; to: number }) => {
  const result = [];
  const [preparedFrom, preparedTo] = [from, to].sort((a, b) => a - b);

  for (let i = preparedFrom; i <= preparedTo; i += 1) {
    result.push(i);
  }

  return result;
};

import { Table as T, Form as F, Form } from 'types/models';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { CitationSystemType } from 'types/models/CitationSystem';

type RequestData = {
  domain: string;
  sourceId?: string;
  releaseId?: string;
};

type Props = {
  templatesTableDependencies: T.TemplatesTableDependencies;
  publicationType: string;
  addCitationSystem: (citationSystem: F.CitationSystem) => void;
  editCitationSystem: (citationSystem: Form.CitationSystem) => void;
  removeCitationSystem: (citationSystemId: string) => void;
  workMode: T.WorkMode;
  sourceId?: string;
  releaseId?: string;
  setTopMessage?: (topMessage: string) => void;
};

export function GetPublicationCitationSystemList({
  templatesTableDependencies,
  publicationType,
  addCitationSystem,
  editCitationSystem,
  removeCitationSystem,
  workMode,
  sourceId,
  releaseId,
  setTopMessage,
}: Props): T.Specification<State> {
  const requestData: RequestData = {
    domain:
      publicationType === 'MAGAZINE'
        ? 'MAGAZINE'
        : publicationType === 'MONOGRAPH_CHAPTER'
        ? 'MONOGRAPH_CHAPTER'
        : [
            'MONOGRAPH',
            'COMPILATION_ARTICLES',
            'COMPILATION_CONFERENCE',
            'COMPILATION_SCIENTIFIC_WORK',
            'COMPILATION_OTHER',
            'TEXTBOOK',
            'TUTORIAL',
            'METHODICAL_EDITION',
            'METHODICAL_EDITION_OTHER',
            'DISSERTATION',
            'SYNOPSIS',
            PublicationTypes.OTHER_BOOK.code,
          ].includes(publicationType)
        ? 'COMPILATION'
        : [
            'MAGAZINE_ARTICLE',
            'ARTICLE_ARTICLES',
            'ARTICLE_CONFERENCE',
            'ARTICLE_SCIENTIFIC_WORK',
            'ARTICLE_OTHER',
            'THESIS',
            'PREPRINT_ARTICLE',
            PublicationTypes.OTHER_PART.code,
          ].includes(publicationType)
        ? 'ARTICLE'
        : 'UNKNOWN_TYPE',
    sourceId,
    releaseId,
  };

  const citationSystemType: CitationSystemType = [PublicationTypes.MAGAZINE.code].includes(
    publicationType as Form.PublicationTypeCode,
  )
    ? 'MAGAZINE' // 1
    : [
        'COMPILATION_CONFERENCE',
        'COMPILATION_SCIENTIFIC_WORK',
        'COMPILATION_OTHER',
        'METHODICAL_EDITION',
        'TEXTBOOK',
        'TUTORIAL',
        'METHODICAL_EDITION_OTHER',
        'DISSERTATION',
        'SYNOPSIS',
        'MONOGRAPH',
        'OTHER_BOOK',
      ].includes(publicationType)
    ? 'PARENTAL_GRID' // 2
    : [
        'MONOGRAPH_CHAPTER',
        'ARTICLE_CONFERENCE',
        'ARTICLE_SCIENTIFIC_WORK',
        'ARTICLE_OTHER',
        'OTHER_PART',
        PublicationTypes.THESIS.code,
        PublicationTypes.PREPRINT_ARTICLE.code,
      ].includes(publicationType)
    ? 'CHILD_GRID' // 3
    : publicationType === 'MAGAZINE_ARTICLE'
    ? 'MAGAZINE_ARTICLE' // 4
    : null;

  return {
    apiID: 'GetPublicationCitationSystemList',
    isFiltersHidden: templatesTableDependencies.isFiltersHidden,
    templatesTableDependencies,
    requestData,
    header: {
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController(
      templatesTableDependencies,
      requestData.domain,
      publicationType,
      citationSystemType,
      addCitationSystem,
      editCitationSystem,
      removeCitationSystem,
      workMode,
      setTopMessage,
    ),
  };
}

import React, { useCallback } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Author } from 'types/models';
import { SelectPerson, Member } from 'features/SelectPerson';

export type ChangeProps = {
  key: keyof Author;
  value: Author[keyof Author];
};

type Props = {
  author: Author | null;
  onChange(args: ChangeProps[]): void;
};

function Fields(props: Props) {
  const { author, onChange } = props;

  const rolePerson = author?.role ?? null;
  const handleChangeRole = useCallback(
    (value: ReferenceItem) => {
      if (value) {
        onChange([{ key: 'role', value }]);
      } else {
        onChange([{ key: 'role', value: null }]);
      }
    },
    [onChange],
  );
  const updateAuthor = useCallback(
    (updatedMember: Member | null) => {
      if (updatedMember) {
        const changeProps = Object.entries(updatedMember).map<ChangeProps>(([key, value]) => ({
          key: key as keyof Member,
          value,
        }));
        onChange(changeProps);
      }
    },
    [onChange],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson member={author} onUpdateMember={updateAuthor} hiddenButtons={['delete']} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={rolePerson}
            onChange={handleChangeRole}
            settings={{ name: 'RefOrgCommitte', title: 'Роли участников оргкомитета' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { Fields };

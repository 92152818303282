import { useCallback, useMemo, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { ButtonProps } from 'components';

import { Author, Event, Table, Report } from 'types/models';
import { getIsSourceCitationDisabled } from 'utils/Helpers/getIsSourceCitationDisabled';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { PublicationTypeCode } from 'types/models/Form';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { PublicationStatus, PublicationType } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const { currentPerson, userPermission, settings } = useAppDataContext();
  const token = getAuthToken();

  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const tableStreams = useLocalTableStreams();

  const selectedRow = tableState.selectedRows[0] || null;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  const [isSourceCitationOpen, setIsSourceCitationOpen] = useState(false);
  const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isAddFormOpenConferenceParticipation, setIsAddFormOpenConferenceParticipation] = useState(false);
  const [isParticipationModalOpen, setIsParticipationModalOpen] = useState<boolean>(false);
  const [publicationConferenceParticipation, setPublicationConferenceParticipation] = useState<Event.Source | null>(null);
  const [publicationConferenceSource, setPublicationConferenceSource] = useState<Event.Event | null>(null);

  const handleCloseFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(false);
  }, []);
  const handleOpenFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(true);
  }, []);

  const { methods: editPublicationField } = BackendAPI.useBackendAPI('EditPublicationField');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PERSONAL_PUBLICATION_LIST', []);
  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();

  const author = useMemo(
    (): Author => ({
      id: null,
      category: {
        code: '',
        customFields: { code: 'author' },
        deletable: false,
        editable: false,
        foreignName: null,
        id: '1',
        label: 'Автор',
        shortName: null,
      },
      affilateCount: 1,
      historyLabel: '',
      name: currentPerson ? currentPerson.fullName : '',
      job: currentPerson && currentPerson.scientistJobs.length ? currentPerson.scientistJobs[0] : null,
      memberAffilations: [],
      position: 0,
      person: currentPerson || null,
      education: null,
      enterprise: null,
      degree: !(currentPerson && currentPerson.degrees.length) ? null : currentPerson.degrees[0],
      citizenship: null,
      isAffilated: true,
      academicRank: !(currentPerson && currentPerson.academicRanks !== null) ? null : currentPerson.academicRanks[0],
      isReporter: null,
      role: null,
      rank: null,
    }),
    [currentPerson],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.AvtodissDetail,
      Reports.Dissertations,
      Reports.MonographDetail,
      Reports.DepartmentsPublications,
      Reports.PublicationsByCondition,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const isEditPermitted = useMemo(
    () =>
      selectedRow?.['id:PublicationStatus'] === PublicationStatus.APPROVED
        ? isHasPermission(userPermission, Permits.PUBLICATION_STRICTED_EDIT)
        : true,
    [selectedRow, userPermission],
  );

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: selectedRow,
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }

    return links;
  }, [selectedRow, settings]);

  const isSourceCitationDisabled = useMemo(() => {
    if (selectedRow) return getIsSourceCitationDisabled({ row: selectedRow });
    return true;
  }, [selectedRow]);

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = selectedRow?.id;
    const publicationTypeCode = selectedRow['id:PublicationType'];

    if (publicationId && publicationTypeCode) {
      const preparedPublicationTypeCode = publicationTypeCode as PublicationTypeCode;
      deletePublication({ publicationId, publicationTypeCode: preparedPublicationTypeCode });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, selectedRow]);

  const handleEditRelations = useCallback(() => {
    if (selectedRow) setIsOpenEditRelations(true);
  }, [selectedRow]);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleOpenSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(true);
  }, []);

  const handleCloseSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(false);
  }, []);

  const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(true);
  }, []);

  const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(false);
  }, []);

  const submitPublicationOriginalTranslatesRelationsOpen = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleOpenFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(true);
  }, []);

  const handleCloseFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(false);
  }, []);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(selectedRow?.FileId, token);
  }, [selectedRow?.FileId, token]);

  const submitParticipationModal = useCallback(
    ({ selectedRows }: SubmitTable) => {
      editPublicationField.callAPI(
        {
          id: selectedRow?.id,
          parameters: [
            {
              name: 'PARTICIPATION',
              value: selectedRows[0]?.id,
            },
          ],
        },
        {
          onSuccessfullCall: () => {
            setIsParticipationModalOpen(false);
            tableStreams.reloadTable.push({});
            showNotification({
              theme: 'success',
              message: 'Связь с участием в конференции сохранена',
            });
          },
        },
      );
    },
    [editPublicationField, selectedRow?.id, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PUBLICATION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактирование',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PUBLICATION_EDIT },
        isDisabled: tableState.selectedRows.length !== 1 || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_DRAFTADDED) &&
            (tableState.selectedRows[0]?.['id:PublicationStatus'] === PublicationStatus.ADDED ||
              tableState.selectedRows[0]?.['id:PublicationStatus'] === PublicationStatus.DRAFT)) ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_APPROVED) &&
            tableState.selectedRows[0]?.['id:PublicationStatus'] === PublicationStatus.APPROVED),
      },
      {
        icon: { type: 'relation' },
        title: 'Связь публикации с участием в конференции',
        onClick: () => setIsParticipationModalOpen(true),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          ![PublicationType.THESIS, PublicationType.ARTICLE_CONFERENCE, PublicationType.MAGAZINE_ARTICLE].includes(
            selectedRow?.['id:PublicationType'] as PublicationType,
          ),
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь публикации с подразделениями',
        onClick: handleEditRelations,
        permission: { name: Permits.PUBLICATION_DEPARTMENTS_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'currency' },
        title: 'Финансовая поддержка публикации',
        onClick: handleOpenFinancialSupport,
        permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'statistic' },
        title: 'Показатели публикации',
        onClick: handleOpenCitationForm,
        permission: { name: Permits.PUBLICATION_CITATION_SYSTEM_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'statistic', mode: 'view' },
        title: 'Показатели источника',
        onClick: handleOpenSourceCitation,
        permission: { name: Permits.PUBLICATION_CITATION_SYSTEM_INDEX_EDIT },
        isDisabled: isSourceCitationDisabled,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть публикацию во внешнем источнике',
        expandedList: { list: getLinks },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать скан-копию публикации',
        onClick: handleDownloadPublicationFile,
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'lang' },
        title: 'Указать статью-оригинал/переводную статью',
        onClick: handleOpenPublicationOriginalTranslatesRelations,
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleHelpButtonClick,
      handleViewButtonClick,
      tableState.selectedRows,
      handleAddButtonClick,
      handleEditButtonClick,
      isEditPermitted,
      handleDeleteButtonClick,
      userPermission,
      selectedRow,
      handleEditRelations,
      handleOpenFinancialSupport,
      handleOpenCitationForm,
      handleOpenSourceCitation,
      isSourceCitationDisabled,
      getLinks,
      handleDownloadPublicationFile,
      handleOpenPublicationOriginalTranslatesRelations,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    SUCH_LIKE_PUBLICATION_ID,
    isAddFormOpen,
    isViewFormOpen,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isEditFormOpen,
    handleContinueStep,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    buttons,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isCitationFormOpen,
    handleCloseCitationForm,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    author,
    isReportOpen,
    onReportClose,
    currentReport,
    isParticipationModalOpen,
    setIsParticipationModalOpen,
    selectedRow,
    submitParticipationModal,
  };
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Template } from 'types/models/Form';

type Props = {
  tableState: Table.State;
};

export const templateArgs: { apiID: string; template?: Template } = {
  apiID: 'GetMobileKindConsumption',
  template: 'MobileKindConsumptionForm',
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isAddButtonDisabled = !rowsAvailability.ALWAYS;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'MobileKindConsumption',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.MOBILE_KIND_CONSUMPTION_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.MOBILE_KIND_CONSUMPTION_ADD },
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.MOBILE_KIND_CONSUMPTION_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.MOBILE_KIND_CONSUMPTION_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      isAddButtonDisabled,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
    ],
  );

  return {
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleDeleteButtonClick,
    handleViewButtonClick,
    handleTemplateCloseViewForm,
    handleAddButtonClick,
    handleTemplateCloseAddForm,
    handleEditButtonClick,
    handleTemplateCloseEditForm,
    buttons,
  };
}

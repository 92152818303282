import { Form } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { SecurityDocumentType } from 'utils/Enums';

export function validate(p: SecurityDocument, formFields: Form.Fields) {
  const invalidTitles = [];
  if (
    ![SecurityDocumentType.KNOW_HOW, SecurityDocumentType.NON_REGISTERED].includes(p?.type?.value as SecurityDocumentType) &&
    !!formFields.documentNumber.value &&
    !p.documentStartDate
  ) {
    invalidTitles.push('Дата начала');
  }

  if (!p.kind) {
    invalidTitles.push('Вид ОИС');
  }

  const required = Object.keys(formFields)
    .filter((fieldName: string) => {
      if (
        p?.type?.value === SecurityDocumentType.KNOW_HOW &&
        (fieldName === 'requestStatus' || fieldName === 'requestDate' || fieldName === 'requestNumber')
      ) {
        return false;
      }
      if (
        p?.type?.value === SecurityDocumentType.NON_REGISTERED &&
        (fieldName === 'requestNumber' || fieldName === 'requestDate')
      ) {
        return false;
      }
      return formFields[fieldName].required && !formFields[fieldName]?.value;
    })
    .map((fieldName: string) => {
      return formFields[fieldName].title;
    });
  if (!p?.members?.length) {
    invalidTitles.push('Авторы');
  }

  return [...required, ...invalidTitles];
}

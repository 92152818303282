import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { SecurityDocumentType } from 'utils/Enums';

import useController from './controller';
import { getMockDepartment } from './helpers';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  title?: string;
  info?: JSX.Element;
  projectId?: string;
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
  withMessages?: boolean;
  disabled: boolean;
};

export function Departments(props: Props) {
  const { title, info, securityDocument, setSecurityDocument, withMessages = true, disabled, projectId } = props;

  const {
    isDeleteWarningOpen,
    onCloseDeleteWarningPopup,
    deleteWarningText,
    isLoading,
    onPreDelete,
    onPreEdit,
    codes,
    copyFromMembers,
  } = useController({ projectId, securityDocument, setSecurityDocument });

  return (
    <>
      <ListEdit
        header={{ title, isRequired: securityDocument?.type?.value !== SecurityDocumentType.NON_REGISTERED, info }}
        rows={securityDocument.departments}
        onChange={e => setSecurityDocument(prev => ({ ...prev, departments: e }))}
        toolbar={[
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'connection' },
            title: 'Добавить подразделения авторов',
            onClick: () => copyFromMembers(),
            isDisabled: disabled,
          },
        ]}
        columns={[
          { label: 'Подразделение', formatValue: x => `(${x.unit?.customFields?.code}) ${x.unit?.label}` },
          { label: 'Факультет/Институт', formatValue: x => `(${x.governance?.customFields?.code}) ${x.governance?.label}` },
        ]}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled || isLoading}
        defaultRowsCount={3}
        maxHeight="350px"
        specification={{
          mode: 'customComponent',
          onPreDelete,
          onPreEdit,
          renderComponent: (department, setDepartment) => (
            <Fields department={department || getMockDepartment()} setDepartment={setDepartment} codes={codes} />
          ),
          validation: {
            checkIsValid: (row, index, list) => validate(row, list),
          },
        }}
      />

      <Modal
        mode="warning"
        title="Невозможно удалить элемент"
        isOpen={isDeleteWarningOpen}
        onClose={onCloseDeleteWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: onCloseDeleteWarningPopup,
          },
        ]}
        size="small"
      >
        <>{deleteWarningText}</>
      </Modal>
    </>
  );
}

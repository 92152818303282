import React, { useMemo, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
// eslint-disable-next-line max-len
import publicationTypes from 'types/models/Publication/publicationTypes';

const PrintPublicationViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const selectedType =
    relatedTableState && relatedTableState?.selectedRows.length ? relatedTableState.selectedRows[0]['id:PublicationType'] : '';

  const electronicState = useMemo(
    () => ({
      isElectronic: relatedTableState?.selectedRows[0]?.IsElectronic === 'true',
      electronicTypeId: relatedTableState?.selectedRows[0]['id:ElectronicType'] || '',
      electronicType: relatedTableState?.selectedRows[0]?.ElectronicType || '',
    }),
    [relatedTableState],
  );

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: publicationTypes.filter(type => type.code === selectedType)[0]?.template,
    type: selectedType,
    electronicType: electronicState.electronicTypeId,
    isElectronic: electronicState.isElectronic,
    viewMode: true,
    id,
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large" isViewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(PrintPublicationViewTemplate);

import React, { useMemo, useState } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { MessageModal } from '../MessageModal';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const {
    isOpenModal,
    getPaymentFeedline,
    paymentFeedline,
    savePaymentFeedline,
    changeFeedline,
    closeModal,
    openModal,
    deletePaymentFeedline,
    isDisabledToolbar,
  } = customState;

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [row] = tableState.selectedRows;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          openModal();
        },
        isDisabled: isDisabledToolbar,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          if (row) {
            getPaymentFeedline(row.id);
            openModal();
          }
        },
        isDisabled: isEditButtonDisabled || isDisabledToolbar,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsOpenConfirmDeleteModal(true);
        },
        isDisabled: isDeleteButtonDisabled || isDisabledToolbar,
      },
    ],
    [isDisabledToolbar, isEditButtonDisabled, isDeleteButtonDisabled, openModal, row, getPaymentFeedline],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <MessageModal
        isOpen={isOpenModal}
        onClose={closeModal}
        changeFeedline={changeFeedline}
        onSave={savePaymentFeedline}
        paymentFeedline={paymentFeedline}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              deletePaymentFeedline(row.id);
              setIsOpenConfirmDeleteModal(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDeleteModal(false),
          },
        ]}
        size="small"
      >
        <>(тип: {tableState.selectedRows[0]?.description}) Удалить сообщение?</>
      </Modal>
    </>
  );
}

export { Toolbar };

import React, { useCallback } from 'react';

import { Checkbox, FormComponent, TextArea, TextInput } from 'components';

import { Contact } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';

type Props = {
  contact: Contact;
  setContact(contact: Contact): void;
};

export function Fields(props: Props) {
  const { contact, setContact } = props;

  const makeHandler = (key: keyof Contact) => (value: ValueOf<Contact>) => {
    setContact({ ...contact, [key]: value });
  };

  const onChangeMember = useCallback(
    (member: Member) => {
      setContact({ ...contact, person: member.person });
    },
    [contact, setContact],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Персона">
          <SelectPerson
            member={getMemberWithoutHistory(contact.person)}
            onUpdateMember={onChangeMember}
            hiddenButtons={['delete']}
            withHistory={false}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Должность">
          <TextInput value={contact.appointment} onChange={makeHandler('appointment')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение">
          <TextInput value={contact.department} onChange={makeHandler('department')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Коммуникации по вопросам">
          <TextArea settings={{ isFixed: true }} value={contact.communication} onChange={makeHandler('communication')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Доп. контакты">
          <TextArea settings={{ isFixed: true }} value={contact.extraContacts} onChange={makeHandler('extraContacts')} />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Field label="">
        <FormComponent.Line hasFreeFormat>
          <Checkbox label="1 лицо" checked={contact.isHead} onChange={makeHandler('isHead')} />
          <Checkbox label="Актуальный контакт" checked={contact.isActual} onChange={makeHandler('isActual')} />
        </FormComponent.Line>
      </FormComponent.Field>

      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={contact.note} onChange={makeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { Icon } from 'components';

export function PersonalAllProjectGridHelp(): JSX.Element {
  return (
    <>
      <h4>Раздел содержит открытые данные о выполненных и текущих научных проектах Университета</h4>

      <p>
        Используйте строку поиска по словам в столбцах таблицы для отбора группы проектов по тематике, направлению исследований,
        проекты конкретного подразделения
      </p>
      <div>
        Список выбранных проектов вы можете выгрузить в файл с помощью кнопки:
        <Icon type="excel" />
      </div>
    </>
  );
}

import React, { ReactElement } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-column-wrapper');

type Props = {
  /** @property {boolean} fitContent - (optional) Fit content by wrapper */
  fitContent?: boolean;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {ReactElement | false | (ReactElement | false)[]} children - (optional) React inner JSX component */
  children: ReactElement | false | (ReactElement | false)[];
};

/**
 * Wrapper of column elements for content (@media dependent)
 *
 * @param {boolean} fitContent - (optional) Fit content by wrapper
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {ReactElement | false | (ReactElement | false)[]} children - (optional) React inner JSX component
 * @returns JSX
 */
export const ColumnWrapper = React.memo(({ children, fitContent, style, classMixin }: Props) => {
  return (
    <div className={b({ fitContent }).mix(classMixin)} style={style}>
      {children}
    </div>
  );
});

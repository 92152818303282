import React, { useMemo, useEffect } from 'react';
import { block } from 'bem-cn';
import { Route, Switch, useHistory, useLocation } from 'react-router-dom';

import { UserPermission } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import * as MenuTypes from 'features/Menu/types';

import { Component as Topbar } from 'features/Topbar';
import { specifications } from 'features/Table';
import { makeHomeContent } from 'App/makeContent';

import * as TS from './types';

import { LogoComponent } from './Logo';
import { redirectWithPermissions } from 'features/AppData/helpers';
import { EnumMap } from 'types/models/Table';
import { Menu } from 'features/Menu';

import './style.scss';

const b = block('page-layout');

interface Props {
  getEntries(userPermission: UserPermission | null, enums: EnumMap, shortName?: string): TS.Entry[];
}

export const PageLayout = React.memo(({ getEntries }: Props) => {
  const {
    settings,
    userPermission,
    userSystemDepartment,
    userToken,
    isHasPermissionToAccount,
    isHasPermissionToMain,
    enumMap,
  } = useAppDataContext();

  const entries = useMemo(() => getEntries(userPermission, enumMap, settings?.organization?.shortName), [
    getEntries,
    userPermission,
    settings?.organization?.shortName,
    enumMap,
  ]);

  const makeSubentryRenderer = (parentEntry: TS.Entry) => {
    const { routeElement: parentRouteElement } = parentEntry;
    return (subentry: MenuTypes.SubEntry) => {
      const { isAccepted, routeElement: subRouteElement, Content } = subentry;

      if (isAccepted === false) {
        return null;
      }

      const path = `/${parentRouteElement}/${subRouteElement}`;

      return (
        <Route key={path} path={path}>
          <Content />
        </Route>
      );
    };
  };

  const location = useLocation();
  const history = useHistory();
  const isAccountPage = useMemo(() => location.pathname.includes('account'), [location]);

  useEffect(() => {
    redirectWithPermissions(isAccountPage, isHasPermissionToAccount, isHasPermissionToMain, history);
  }, [history, isAccountPage, location, isHasPermissionToAccount, isHasPermissionToMain]);

  const renderEntryRoutes = (entry: TS.Entry) => {
    const { isAccepted, routeElement, kind } = entry;

    if (isAccepted === false) {
      return null;
    }

    switch (kind) {
      case 'terminal':
        const { Content } = entry;
        return (
          <Route key={routeElement} path={`/${routeElement}`}>
            <Content />
          </Route>
        );
      case 'with-subentries':
        const { subEntries = [] } = entry;
        return subEntries.map(makeSubentryRenderer(entry));
    }
  };

  return (
    <>
      {userPermission && userSystemDepartment && userToken && (
        <div className={b()}>
          <header className={b('header')}>
            <div className={b('header-inner')}>
              <div className={b('logo-and-menu')}>
                <LogoComponent />
                <Menu entries={entries} />
                <Topbar />
              </div>
            </div>
          </header>
          <div className={b('content')}>
            <Switch>
              {entries.map(renderEntryRoutes)}
              <Route path="/home">{makeHomeContent(specifications.GetSystemUpdatesList)}</Route>
            </Switch>
          </div>
        </div>
      )}
    </>
  );
});

export { TS };

import React, { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Checkbox, FormComponent, ListEditTable, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { getEnum } from 'utils/Helpers';
import { NirTenderType, TenderRequestType } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { TenderHelp } from './help';
import { NotificationRecipientPreset } from 'features/Form/views/NotificationRecipientPreset';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isTypeModalOpen, setIsTypeModalOpen] = useState<boolean>(false);
  const [selectedRequestType, setSelectedRequestType] = useState<TenderRequestType | null>(null);
  const [tenderRequestTypeIndex, setTenderRequestTypeIndex] = useState<number | null>(null);
  const [selectedNirTenderTypes, setSelectedNirTenderTypes] = useState<NirTenderType[]>([]);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isSendMailOpen, setIsSendMailOpen] = useState(false);

  const { methods: ActivateTenderNotice } = BackendAPI.useBackendAPI('ActivateTenderNotice');

  const tenderRequestTypeEnum = getEnum('TenderRequestType');

  const tenderRequestTypeOptions = useMemo(() => {
    const options = [...(tenderRequestTypeEnum || [])];
    const [nirRequestOption] = options.splice(
      options.findIndex(x => x.value === TenderRequestType.NIR_REQUEST),
      1,
    );
    return options.concat(nirRequestOption);
  }, [tenderRequestTypeEnum]);

  const nirTenderTypeOptions = getEnum('NirTenderType');

  const clearInfo = useCallback(() => {
    setNextPublicationName('');
    setSelectedRequestType(null);
    setTenderRequestTypeIndex(null);
    setSelectedNirTenderTypes([]);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Tender',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleAddButtonClick = useCallback(() => {
    clearInfo();
    setIsTypeModalOpen(true);
  }, [clearInfo]);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);
  const selectType = useCallback(
    (i: number) => {
      const selectedVal = (tenderRequestTypeOptions[i]?.value as TenderRequestType) || null;
      if (selectedVal === TenderRequestType.NIR_REQUEST && !selectedNirTenderTypes.length) {
        showNotification({ message: 'Выберите хотя бы один пункт', theme: 'danger' });
        return;
      }
      setSelectedRequestType(selectedVal);
      setIsTypeModalOpen(false);
      setIsAddFormOpen(true);
    },
    [selectedNirTenderTypes.length, tenderRequestTypeOptions],
  );

  const handleSelectedNirTenderTypeChange = useCallback((val: boolean, type: NirTenderType) => {
    setSelectedNirTenderTypes(prev => {
      let result = [...prev];
      if (!!val) {
        result.push(type);
      } else {
        const i = result.indexOf(type);
        if (i !== -1) {
          result.splice(i, 1);
        }
      }
      return result;
    });
  }, []);

  const handleNoticedButtonClick = useCallback(() => {
    const row = tableState.selectedRows[0];
    ActivateTenderNotice.callAPI(
      { id: row.id, activate: row.IsNoticed !== 'true' },
      {
        onSuccessfullCall: () => {
          showNotification({
            message: `Объявление о конкурсе ${row.IsNoticed !== 'true' ? 'опубликовано' : 'снято с публикации'}`,
            theme: 'success',
          });
          tableStreams.reloadTable.push({});
        },
        onFailedCall: () => {
          showNotification({ message: 'Ошибка при смене статуса "Опубликовать объявление"', theme: 'danger' });
        },
      },
    );
  }, [ActivateTenderNotice, tableState.selectedRows, tableStreams.reloadTable]);

  const activated = useMemo(() => {
    return tableState.selectedRows.length === 1 && tableState.selectedRows[0]?.IsNoticed === 'true';
  }, [tableState.selectedRows]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.TENDER_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.TENDER_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.TENDER_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.TENDER_DELETE },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'report', ...(activated ? { mode: 'remove' } : {}) },
        title: activated ? 'Удалить объявление' : 'Разместить объявление о конкурсе',
        isDisabled: tableState.selectedRows.length !== 1,
        onClick: handleNoticedButtonClick,
      },
      {
        icon: { type: 'mail' },
        title: 'Отправить уведомление',
        onClick: () => setIsSendMailOpen(true),
        permission: { name: Permits.TENDER_DELETE },
        isDisabled: tableState.selectedRows.length > 1,
      },
    ],
    [tableState.selectedRows.length, handleAddButtonClick, handleDeleteButtonClick, activated, handleNoticedButtonClick],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.TenderViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.TenderEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && selectedRequestType && (
        <modalTemplates.TenderAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          requestType={selectedRequestType}
          nirTenderTypes={selectedNirTenderTypes}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.ProgramName || <em>Без названия</em>}) Удалить конкурс?</>
        </Modal>
      )}

      {isTypeModalOpen && (
        <Modal
          title="Выберите вид формы заявки"
          isOpen={isTypeModalOpen}
          onClose={() => {
            setIsTypeModalOpen(false);
            clearInfo();
          }}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: () => selectType(tenderRequestTypeIndex || 0),
              isDisabled: tenderRequestTypeIndex === null,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => {
                setIsTypeModalOpen(false);
                clearInfo();
              },
            },
          ]}
          size="small"
        >
          <FormComponent.Field>
            <FormComponent.Text>
              <strong>Внимание:</strong> выбор вида формы заявки влияет на состав полей формы заявки. Коллекции заявок, собранные
              по видам заявок размещаются в соответствующих пунктах меню
            </FormComponent.Text>
          </FormComponent.Field>

          <ListEditTable
            rows={tenderRequestTypeOptions}
            columns={[{ label: 'Тип заявки', formatValue: row => row?.label }]}
            onRowDoubleClick={selectType}
            selectedRowIndex={tenderRequestTypeIndex}
            selectRow={setTenderRequestTypeIndex}
          />
          {tenderRequestTypeOptions[tenderRequestTypeIndex ?? -1]?.value === TenderRequestType.NIR_REQUEST && (
            <FormComponent.Wrapper>
              {nirTenderTypeOptions.map(type => (
                <Checkbox
                  key={type.value}
                  label={type.label}
                  value={selectedNirTenderTypes.find(y => y === type?.value)}
                  onChange={e => handleSelectedNirTenderTypeChange(e, type?.value as NirTenderType)}
                />
              ))}
            </FormComponent.Wrapper>
          )}
        </Modal>
      )}

      <Modal title="Открыт приём заявок на конкурс" isOpen={isSendMailOpen} onClose={() => setIsSendMailOpen(false)} size="large">
        <NotificationRecipientPreset
          tender={
            tableState.selectedRows[0]
              ? {
                  id: tableState.selectedRows[0].id,
                  name: tableState.selectedRows[0].TenderName,
                }
              : null
          }
          onClose={() => setIsSendMailOpen(false)}
        />
      </Modal>

      <Modal
        mode="help"
        title="Помощь в работе с разделом «Список конкурсов»"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {TenderHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { Dispatch, SetStateAction } from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { useController } from './controller';
import { Community, Table } from 'types/models';

type Props = {
  workMode: Table.WorkMode;
  community: Community;
  setCommunity: Dispatch<SetStateAction<Community>>;
};

export function MagazineAboutFields({ workMode, community, setCommunity }: Props) {
  const { modalTableMagazineConverter, magazineTableConfig, handleMagazineFieldChange, publishingLabel } = useController({
    community,
    setCommunity,
  });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Журнал" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Журналы',
              table: {
                specification: magazineTableConfig,
                onSelect: (row: Table.Entry | null) => handleMagazineFieldChange(row ? modalTableMagazineConverter(row) : null),
              },
            }}
            value={community?.magazine?.name}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Издательство">
          <div>{publishingLabel}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            settings={{ rows: 3 }}
            value={community?.note}
            onChange={e => setCommunity(prev => ({ ...prev, note: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

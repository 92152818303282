import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { MobileRequestStatus } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { Color } from 'constants/colors';

type StatusArgs = {
  rows: { id: string; status: MobileRequestStatus | null }[];
  callback?(): void;
};

export function getMobileRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<MobileRequestStatus>(MobileRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeMobileRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddMobileRequestApprovement');

  const handleButtonClick = useCallback((newStatus: MobileRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push({});
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, mobileRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [addApprovementAPI, rows, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'new' },
        title: 'В черновик',
        onClick: () => handleButtonClick(MobileRequestStatus.DRAFT, 'В черновик'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_DRAFT },
        isDisabled: !rows.length || rows.some(x => !!x.status && [MobileRequestStatus.DRAFT].includes(x.status)),
      },
      // Temporary comment
      // {
      //   icon: { type: 'report' },
      //   title: 'Подать на конкурс',
      //   onClick: () => handleButtonClick(MobileRequestStatus.SENT, 'Подать на конкурс'),
      //   permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_SENT },
      //   isDisabled: !rows.length || !rows.every(x => !!x.status && [MobileRequestStatus.RECOMENDED].includes(x.status)),
      // },
      {
        icon: { type: 'like' },
        title: 'Рекомендовать на конкурс',
        onClick: () => handleButtonClick(MobileRequestStatus.RECOMENDED, 'Рекомендовать на конкурс'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED },
        isDisabled: !rows.length || !rows.every(x => !!x.status && [MobileRequestStatus.REQUEST].includes(x.status)),
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'На согласование',
        onClick: () => handleButtonClick(MobileRequestStatus.REQUEST, 'На согласование'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.DRAFT, MobileRequestStatus.REVISION].includes(x.status)),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать',
        onClick: () => handleButtonClick(MobileRequestStatus.APPROVED, 'Поддержать'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_APPROVED },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.SENT, MobileRequestStatus.RECOMENDED].includes(x.status)),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'На доработку',
        onClick: () => handleButtonClick(MobileRequestStatus.REVISION, 'На доработку'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REVISION },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [MobileRequestStatus.REQUEST, MobileRequestStatus.REJECTED].includes(x.status)),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => handleButtonClick(MobileRequestStatus.REJECTED, 'Отклонить'),
        permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REJECTED },
        isDisabled:
          !rows.length ||
          !rows.every(
            x =>
              !!x.status &&
              [MobileRequestStatus.REQUEST, MobileRequestStatus.SENT, MobileRequestStatus.RECOMENDED].includes(x.status),
          ),
      },
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsApprovementModalOpen(true),
        permission: {
          name: Permits.MOBILE_REQUEST_AGREE,
        },
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === MobileRequestStatus.DRAFT),
      },
    ],
    [rows, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}

import React from 'react';

import { Icon } from 'components';

export function TenderHelp(): JSX.Element {
  return (
    <>
      <p>
        Запись в таблице <strong>Список конкурсов</strong> содержит основные сведения о конкурсе и дополнительную статистическую
        информацию о заявках и протоколе, связанных с конкурсом.
      </p>
      <p>Для формирования списков записей в таблице по условию служат фильтры:</p>

      <ul>
        <li>
          <strong>Все</strong> — для отображения всех конкурсов;
        </li>
        <li>
          <strong>Год окончания приёма заявок</strong> - показывает список конкурсов, у которых Дата окончания приёма заявок
          соответствует указанному году;
        </li>
        <li>
          <strong>Год подведения итогов</strong> — показывает список конкурсов, у которых Дата подведения итогов соответствует
          указанному году;
        </li>
      </ul>

      <p>
        Содержание столбцов с информацией о заявках и протоколе отображается по состоянию на текущую дату и не зависит от
        выбранного фильтра.
      </p>

      <h4>Особенности заполнения информации о конкурсе</h4>

      <p>При добавлении конкурса необходимо вначале указать вид формы заявки, который будет использоваться:</p>

      <ul>
        <li>
          «Заявка по академической мобильности» — для конкурсов из категории: Стажировки, повышение квалификации, Участие в
          научных мероприятиях;
        </li>
        <li>
          «Заявка на конкурс» — для конкурсов из категории: Проведение научных мероприятий, Издание научных трудов, на выполнение
          НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и премии, Прочие проекты;
        </li>
        <li>
          «Заявка на внутренний конкурс» — для участия в конкурсе на выполнение НИР и ОКР, организованном внутри университета.
        </li>
      </ul>

      <p>Форма добавления конкурса содержит две вкладки:</p>

      <ul>
        <li>
          Конкурс содержит основные сведения о конкурсе. Раздел Лоты – заполняется для конкурсов из категории: Проведение научных
          мероприятий, Издание научных трудов, на выполнение НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и
          премии, Прочие проекты.
        </li>
        <li>
          Документы – для прикрепления файлов конкурсной документации. Файлы с атрибутом{' '}
          <strong>«Отображать на формах Заявки и Проекта»</strong> - будут доступны для просмотра на форме заявки, в списке
          документов
        </li>
      </ul>

      <div className="warning">
        <span>
          Для того чтобы пользователь имел возможность оформить заявку на конкурс из личного кабинета необходимо активировать
          чек-бокс «Оформлять заявки через личный кабинет» в разделе Порядок размещения заявок (при этом поле «Подать заявку на
          согласование консультанту до» станет обязательным для заполнения).
        </span>
      </div>

      <div className="warning" data-label="Внимание!">
        <span>Дата подачи заявки на согласование должна быть актуальной.</span>
      </div>

      <h4>Размещение объявления о конкурсе</h4>

      <p>
        После заполнения полной информации о конкурсе можно разместить объявление по кнопке icon: <Icon type="report" /> на
        тулбаре, в столбце таблицы Объявление появится зеленая галочка, которая подтверждает что объявление размещено в списке
        Конкурсы в разделе новостей.
      </p>

      <p>
        Для удаления объявления о конкурсе, необходимо выделить запись о конкурсе в таблице и нажать на кнопку{' '}
        <Icon type="report" mode="remove" /> - при этом в столбце Объявление зеленая галочка не отображается.
      </p>

      <div className="warning">
        <span>
          Если в конкурсе включен атрибут "Оформлять заявки через личный кабинет", на форме объявления доступна кнопка "Подать
          заявку". Кнопка доступна пользователю до даты, указанной в поле Подать заявку на согласование консультанту до
          (включительно).
        </span>
      </div>

      <h4>Уведомления о конкурсе</h4>

      <p>
        В подсистеме уведомлений предусмотрена рассылка на электронную почту и в личный кабинет пользователей системы
        письма-уведомления о конкурсе. Уведомление будет автоматически отправлено после публикации объявления о конкурсе, при
        совпадении с классификаторами <strong>Области научных интересов</strong> в профиле пользователя.
        <br />
        Уведомление может быть отправлено оператором в разделе <strong>Журнал уведомлений о конкурсах</strong>.
      </p>

      <div className="warning">
        <span>
          Все адресные уведомления отображаются в личном кабинете пользователя в разделе <strong>Мои уведомления</strong>
        </span>
      </div>
    </>
  );
}

import { showNotification } from 'features/Notifications';
import { PublicationCitationSystem } from 'types/models/CitationSystem';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Period } from './PeriodInputs';

export function periodIntereSectionCheck(row: { startYear: any; endYear: any }, index: null, list: Period[], mode: string) {
  if (!row) {
    return false;
  }

  return !list.some(
    (record: Period, currentIndex: any) =>
      (mode === 'edit' ? index === null || index !== currentIndex : true) &&
      (row.startYear < record.endYear || !record.endYear) &&
      (record.startYear < row.endYear || !row.endYear),
  );
}

export function periodValidation(row: { startYear: any; endYear: any }, index: any, list: any, mode: any) {
  const currentDate = new Date();
  const startYear = Number(row?.startYear) || 0;
  const endYear = Number(row?.endYear) || 0;
  const currentYear = currentDate.getFullYear();

  if (!row) {
    showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
    return false;
  }

  let errorText = '';

  if (!startYear) {
    errorText = 'Заполните обязательные поля';
  } else if (startYear < 1900 || startYear > currentYear || (endYear && (endYear < 1900 || endYear > currentYear))) {
    errorText = 'Временной промежуток может быть между 1900 и текущим годом';
  } else if (endYear && startYear > endYear) {
    errorText = 'Год окончания не может быть меньше года начала';
  } else if (!periodIntereSectionCheck(row, index, list, mode)) {
    errorText = 'Введенный период пересекается или совпадает с уже существующим';
  }

  if (!!errorText) {
    showNotification({ message: errorText, theme: 'danger' });
  }

  return !errorText;
}

const periodSingleValidation = (startYearString: string, publicationYear: string) => {
  const startYear = Number(startYearString);
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();

  let errorText = '';

  if (!startYear) {
    errorText = 'Заполните обязательные поля';
  } else if (startYear < Number(publicationYear) || startYear > currentYear) {
    errorText = 'Временной промежуток может быть между годом издания и текущим годом';
  }

  if (!!errorText) {
    showNotification({ message: errorText, theme: 'danger' });
  }

  return !errorText;
};

export function validate(data: PublicationCitationSystem, settingPeriodSingle: string, publicationYear: string) {
  if (
    data.citationSystem.id &&
    !!data.identifier &&
    (data.citationSystemType === 'CHILD_GRID' ||
      data.citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code ||
      (data.periods && data.periods[0] && data.citationSystemType === 'MAGAZINE') ||
      (settingPeriodSingle && data.citationSystemType === 'PARENTAL_GRID')) &&
    (data.citationSystemType !== 'PARENTAL_GRID' || periodSingleValidation(settingPeriodSingle, publicationYear))
  ) {
    return true;
  }

  showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
  return false;
}

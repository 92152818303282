export enum NotificationEvent {
  PURCHASE_REQUEST_CONSIDERATION = 'PURCHASE_REQUEST_CONSIDERATION',
  PURCHASE_REQUEST_CHANGE_STATUS = 'PURCHASE_REQUEST_CHANGE_STATUS',
  PURCHASE_REQUEST_VISA = 'PURCHASE_REQUEST_VISA',
  PROJECT_ASSIGNMENT_CREATE = 'PROJECT_ASSIGNMENT_CREATE',
  PROJECT_ASSIGNMENT_CHANGE_STATUS = 'PROJECT_ASSIGNMENT_CHANGE_STATUS',
  PROJECT_ASSIGNMENT_MESSAGE = 'PROJECT_ASSIGNMENT_MESSAGE',
  REQUEST_FEEDLINE_CREATE = 'REQUEST_FEEDLINE_CREATE',
  NIR_REQUEST_FEEDLINE_CREATE = 'NIR_REQUEST_FEEDLINE_CREATE',
  REQUEST_CHANGE_STATUS = 'REQUEST_CHANGE_STATUS',
  NIR_REQUEST_CHANGE_STATUS = 'NIR_REQUEST_CHANGE_STATUS',
  MOBILE_REQUEST_CHANGE_STATUS = 'MOBILE_REQUEST_CHANGE_STATUS',
  REQUEST_PERFORMER_CREATE = 'REQUEST_PERFORMER_CREATE',
  NIR_REQUEST_PERFORMER_CREATE = 'NIR_REQUEST_PERFORMER_CREATE',
  PROJECT_PROJECT_SCIENTIST_CREATE = 'PROJECT_PROJECT_SCIENTIST_CREATE',
  PROJECT_END_APPROACHING_30 = 'PROJECT_END_APPROACHING_30',
  PROJECT_END_APPROACHING_3 = 'PROJECT_END_APPROACHING_3',
  PROJECT_END_APPROACHING_1 = 'PROJECT_END_APPROACHING_1',
  TENDER_NOTICED = 'TENDER_NOTICED',
  PROJECT_FEEDLINE_CREATE = 'PROJECT_FEEDLINE_CREATE',
  PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END = 'PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END',
  PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END_FOR_LEADER = 'PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END_FOR_LEADER',
}

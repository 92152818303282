import { Icon } from 'components';

export function PersonalConferenceParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Участие в конференциях</strong> предназначен для добавления, редактирования и удаления участия и
        сопутствующей информации об участии: докладах, наградах, документах и проч.
      </p>

      <h4>В разделе Участие в конференции вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись об участии в конференции и информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новое участие в конференции с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Добавить новую конференцию с помощью кнопки <Icon type="add" /> на вкладке «Описание» при создании нового участия.
        </li>
        <li>
          Редактировать участие в конференции с помощью кнопки <Icon type="edit" /> Кнопка доступна, если вы являетесь участником
        </li>
        <li>Добавить информацию о типе выступления и участии с докладом в поле «Формат участия»</li>
        <li>Добавить информацию о публикации на вкладке «Описание», если доклад выступления опубликован</li>
        <li>
          Удалить запись об участии в конференции, которую вы создавали, если она в статусе Добавлена с помощью кнопки{' '}
          <Icon type="remove" /> Кнопка доступна, если вы являетесь участником
        </li>
        <li>
          Выгрузить список участий в конференциях в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Посмотреть награды участников конференции с помощью кнопки <Icon type="award" />
        </li>
        <li>
          Редактировать связь с подразделением с помощью кнопки <Icon type="connection" />
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи об участии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите документ подтверждающий участие. Ответственное лицо проверит и утвердит
        участие.
      </p>
      <p>
        Если участник выступает с опубликованным докладом или участие в конференции является одним из результатов выполнения
        проекта, то обязательно должна быть заполнена информация о докладе на вкладке «Описание».
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои участия»</strong> содержит список ваших участий. Если вы создавали запись об участии в конференции,
          но не являетесь участником, запись будет отображаться в разделе «Все участия».
        </li>
        <li>
          <strong>Фильтр «Все участия»</strong> отображает список всех участий в конференциях.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список участий в конкурсах, сроки проведения которых содержат указанный год. По
          умолчанию выбран текущий год.
        </li>
      </ul>

      <div>
        Если у вас есть вопрос или вы нашли ошибку в Утвержденном участии - напишите Администратору <Icon type="question" />
      </div>
    </>
  );
}

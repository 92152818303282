import React from 'react';

import {
  ButtonMode,
  FormComponent,
  ListEdit,
  Modal,
  Select,
  SelectMode,
  Tab,
  Tabs,
  TextArea,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadFile,
  UploadImage,
} from 'components';
import {
  GetProgramEventList,
  GetProgramIndicatorsList,
  GetProgramIndicesList,
  GetProgramKnowledgeBranchList,
} from 'features/Table/specifications';

import { DataGrid } from 'features/Table';
import { showNotification } from 'features/Notifications';
import { useController } from './controller';
import { CustomerInputs } from './CustomerInputs';
import { DirectionInputs } from './DirectionInputs';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';

type HeaderProps = {
  viewMode?: boolean;
  editMode?: boolean;
  id?: string;
};

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

export const ProgramMHeader = ({ viewMode, editMode, id = '' }: HeaderProps) => {
  const result = [];

  if (viewMode) {
    result.push('Просмотр');
  } else if (editMode) {
    result.push('Редактирование');
  } else {
    result.push('Добавление');
  }

  result.push('программы');

  if (viewMode || editMode) {
    result.push(`(ID ${id})`);
  }

  return result.join(' ');
};

function ProgramForm({ viewMode, editMode, onClose }: Props) {
  const {
    customers,
    directions,
    formFields,
    handleCustomersFieldChange,
    handleDirectionsFieldChange,
    handleFormSubmit,
    programId,
    workMode,
    program,
    getProgram,
    saveConfirmText,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
    handleOpenConfirm,
    handleSaveConfirm,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const { userPermission } = useAppDataContext();

  return (
    <>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        {...(workMode === 'addMode' && !isHasPermission(userPermission, Permits.PROGRAM_EDIT)
          ? {}
          : { handleSaveAndContinue: () => handleFormSubmit(false) })}
        viewMode={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О программе">
            <FormComponent.Line>
              <FormComponent.Field label={formFields.name.title} isRequired>
                <TextArea
                  settings={{ rows: 3 }}
                  value={formFields.name.value}
                  onChange={formFields.name.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.shortName.title} isRequired>
                <TextInput value={formFields.shortName.value} onChange={formFields.shortName.onChange} isDisabled={!!viewMode} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.type.title} isRequired={formFields.type.required}>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={formFields.type.value}
                  onChange={formFields.type.onChange}
                  settings={{
                    name: 'RefProgramType',
                    title: 'Справочник "Типы программ"',
                    isClearable: !formFields.type.required,
                  }}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.ColumnWrapper>
                <FormComponent.Column style={{ width: '80%' }}>
                  <FormComponent.Line>
                    <FormComponent.Field label="Сроки действия">
                      <span>
                        <TextDateTime
                          value={formFields.startDate.value || ''}
                          onChange={(value: string) => formFields.startDate.onChange(value)}
                          maxValue={formFields.endDate.value || ''}
                          isDisabled={!!viewMode}
                        />
                      </span>
                      <span>&nbsp;-&nbsp;</span>
                      <span>
                        <TextDateTime
                          value={formFields.endDate.value || ''}
                          onChange={(value: string) => formFields.endDate.onChange(value)}
                          minValue={formFields.startDate.value || ''}
                          isDisabled={!!viewMode}
                        />
                      </span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label={formFields.url.title}>
                      <TextInput
                        mode={TextInputMode.URL}
                        value={formFields.url.value}
                        onChange={formFields.url.onChange}
                        isDisabled={!!viewMode}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label={formFields.file.title}>
                      <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!viewMode} />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </FormComponent.Column>
                <FormComponent.Column style={{ width: '20%', height: '130px' }}>
                  <UploadImage
                    text={formFields.logo.title}
                    id={formFields.logo.value?.id}
                    viewMode={!!viewMode}
                    onChange={formFields.logo.onChange}
                  />
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                header={{ title: 'Заказчики' }}
                rows={customers}
                onChange={handleCustomersFieldChange}
                toolbar={['add', 'edit', 'delete']}
                columns={[
                  { label: 'Организация', formatValue: row => row.refCustomer?.label || '' },
                  { label: 'Тип источника финансирования', formatValue: row => row?.investmentSourceType?.label || '' },
                ]}
                withMessages
                isDeleteConfirmEnabled
                isDisabled={!!viewMode}
                specification={{
                  mode: 'customComponent',
                  renderComponent: (row, onChange) => <CustomerInputs customer={row} onCustomerChange={onChange as any} />,
                  validation: {
                    checkIsValid: row => !!row?.refCustomer?.id,
                    onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                  },
                }}
              />
            </FormComponent.Field>
          </Tab>
          <Tab title="Направления программы">
            <ListEdit
              header={{ title: 'Направления программы' }}
              rows={directions}
              onChange={handleDirectionsFieldChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'Название направления', formatValue: row => row.name },
                { label: 'Краткое название', formatValue: row => row.code },
              ]}
              maxHeight="none"
              withMessages
              isDeleteConfirmEnabled
              isDisabled={!!viewMode}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <DirectionInputs direction={row} onDirectionChange={onChange as any} />,
                validation: {
                  checkIsValid: row => !!(row?.code && row?.name),
                  onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                },
              }}
            />
          </Tab>
          <Tab title="Мероприятия">
            <DataGrid
              key="GetProgramEventList"
              specification={GetProgramEventList({ programId: programId || '-1', isClearToolbar: workMode !== 'editMode' })}
            />
          </Tab>
          <Tab title="Индикаторы">
            <DataGrid
              key="GetProgramIndicatorsList"
              specification={GetProgramIndicatorsList({
                programId: programId || '-1',
                program,
                getProgram,
                handleOpenConfirm,
                isDisabled: workMode === 'viewMode',
              })}
            />
          </Tab>
          <Tab title="Показатели">
            <DataGrid
              key="GetProgramIndicesList"
              specification={GetProgramIndicesList({
                programId: programId || '-1',
                program,
                getProgram,
                handleOpenConfirm,
                isDisabled: workMode === 'viewMode',
              })}
            />
          </Tab>
          <Tab title="Отрасли знаний (РНФ)">
            <DataGrid
              key="GetProgramKnowledgeBranchList"
              specification={GetProgramKnowledgeBranchList({
                programId: programId || '-1',
                program,
                getProgram,
                handleOpenConfirm,
                isDisabled: workMode === 'viewMode',
              })}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpened}
        onClose={() => setIsConfirmPopupOpened(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить и продолжить',
            onClick: handleSaveConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmPopupOpened(false),
          },
        ]}
        size="small"
      >
        <>{saveConfirmText}</>
      </Modal>
    </>
  );
}

export const Component = React.memo(ProgramForm);

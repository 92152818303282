import React from 'react';

import { ButtonMode, FormComponent, Modal, SectionTitle, Tab, Tabs, TextArea, TextInput } from 'components';

import { DataGrid } from 'features/Table';
import { GetRolePermissionList, GetRoleUserList } from 'features/Table/specifications';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RoleForm({ onClose, viewMode, editMode }: Props) {
  const { onSubmit, role, handleNameFieldChange, handleDescriptionFieldChange, roleId, workMode } = useController({
    onClose,
    viewMode,
    editMode,
  });

  return workMode === 'addMode' ? (
    <Modal
      title="Добавление роли пользователей"
      isOpen
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Сохранить и продолжить',
          onClick: () => onSubmit(false),
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="small"
    >
      <SectionTitle title="Роль" />

      <FormComponent.Field label="Название" labelWidth={100} isRequired>
        <TextInput value={role.name} onChange={handleNameFieldChange} />
      </FormComponent.Field>

      <FormComponent.Field label="Описание" labelWidth={100}>
        <TextArea settings={{ rows: 3 }} value={role.description} onChange={handleDescriptionFieldChange} />
      </FormComponent.Field>
    </Modal>
  ) : (
    <Modal
      isOpen
      onClose={onClose}
      title={(() => {
        return [
          workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
          'роли пользователей',
          role.name || '',
          roleId ? `(ID ${roleId})` : '',
        ]
          .filter(Boolean)
          .join(' ');
      })()}
      size="full"
      isViewMode={viewMode}
    >
      <FormComponent.Template>
        <ControlPanel handleSave={() => onSubmit(true)} handleSaveAndContinue={() => onSubmit(false)} viewMode={viewMode} />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Роль">
              <FormComponent.ColumnWrapper style={{ height: '100%' }}>
                <FormComponent.Column style={{ width: '350px' }}>
                  <SectionTitle title="Роль" />
                  <FormComponent.Field label="Название" labelWidth={100} isRequired>
                    <TextInput value={role.name} onChange={handleNameFieldChange} />
                  </FormComponent.Field>

                  <FormComponent.Field label="Описание" labelWidth={100}>
                    <TextArea settings={{ rows: 3 }} value={role.description} onChange={handleDescriptionFieldChange} />
                  </FormComponent.Field>
                </FormComponent.Column>
                <FormComponent.Column style={{ width: 'calc(100% - 350px)' }}>
                  <FormComponent.Template>
                    <SectionTitle title="Настройка прав роли" />
                    <DataGrid specification={GetRolePermissionList({ roleId: roleId || '-1', isWithoutHeader: true })} />
                  </FormComponent.Template>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </Tab>
            <Tab title="Список пользователей">
              <DataGrid specification={GetRoleUserList({ roleId: roleId || '-1', isWithoutHeader: true })} />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </Modal>
  );
}

export const Component = React.memo(RoleForm);

import React, { useMemo, useState } from 'react';

import { ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup, Reports } from 'features/BuildReportPopup';
import { buildPaymentRequestPeriodQuery } from 'features/BuildReportPopup/helpers';
import { getUniqArray } from 'utils/Helpers/getUniqArray';
import { State } from '../makeUseCustomController';
import { FundsGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const {
    customState: { isReportOpen, currentReport, getReports, handleSetCurrentReport, onReportClose },
    tableState,
  } = props;

  const [row] = tableState.selectedRows;

  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: !row,
      },
    ],
    [row, getReports, handleSetCurrentReport],
  );

  const requestTypeOrdinal = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
        case Reports.DepartmentsNominations.name:
        case Reports.ConsolidatedStatement.name:
          const requestTypeOrdinals = getUniqArray(tableState.selectedRows.map(({ typeOrd }) => typeOrd));
          return requestTypeOrdinals.join(',');
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  const userDepartments = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
          const departments = getUniqArray(tableState.selectedRows.map(({ 'id:faculty': idFaculty }) => idFaculty));
          return departments.join(',');
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  const paymentRequestPeriod = useMemo(() => {
    if (row) {
      switch (currentReport?.name) {
        case Reports.MemorandumSummary.name:
        case Reports.DepartmentsNominations.name:
        case Reports.ConsolidatedStatement.name:
          const { year } = row;
          const months = getUniqArray(tableState.selectedRows.map(({ month }) => month));
          return buildPaymentRequestPeriodQuery({ periodKey: 'month', period: months, year });
        default:
      }
    }
  }, [currentReport?.name, row, tableState.selectedRows]);

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Фонд подразделений"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {FundsGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          authorRequestStatus: '-1',
          requestStatus: '-1',
          requestType: requestTypeOrdinal,
          userDepartment: userDepartments,
          paymentRequestPeriod: paymentRequestPeriod,
        }}
      />
    </>
  );
}

export { Toolbar };

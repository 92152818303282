import React from 'react';

import { FormComponent, Modal, Select, SelectMode, TextArea, TextDateTime, TextInput, TextInputMode, Toolbar } from 'components';

import { Person, ReferenceItem } from 'types/models';
import { useController } from './controller';

export type Props = {
  isOpen: boolean;
  onClose(): void;
  title: string;
  scientistId: string;
  hirshIndex: Person.HirshIndex;
};

const ModalTemplate: React.FC<Props> = ({ isOpen, onClose, title, scientistId, hirshIndex: hirshIndexSource }: Props) => {
  const { buttons, hirshIndex, makeHandler } = useController({ scientistId, hirshIndexSource, onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <FormComponent.Template>
        <Toolbar buttons={buttons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Система цитирования" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={hirshIndex.citationSystem}
                onChange={(val: ReferenceItem) => makeHandler('citationSystem')(val)}
                settings={{
                  name: 'RefCitationSystem',
                  title: 'Справочник "Системы цитирования"',
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата рассчета" isRequired>
              <TextDateTime value={hirshIndex.date} onChange={makeHandler('date')} style={{ maxWidth: '100%' }} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Значение">
              <TextInput mode={TextInputMode.NUMBER} value={hirshIndex.value || ''} onChange={makeHandler('value')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Идентификатор">
              <TextInput value={hirshIndex.identifier || ''} onChange={makeHandler('identifier')} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea settings={{ rows: 3 }} value={hirshIndex.note || ''} onChange={makeHandler('note')} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
};

export const Component = React.memo(ModalTemplate);

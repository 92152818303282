import React, { useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

function Toolbar() {
  const buttons = useMemo<ButtonProps[]>(() => [], []);

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };

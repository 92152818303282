import React from 'react';

import { Person, UserPermission } from 'types/models';
import { ValueOf } from 'types/helpers';
import { UpperForm } from './UpperForm';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  viewMode: boolean;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

function Component(props: Props) {
  const { person, userPermission, viewMode, makeSetPerson } = props;

  return <UpperForm viewMode={viewMode} userPermission={userPermission} person={person} makeSetPerson={makeSetPerson} />;
}

export const Main = React.memo(Component);

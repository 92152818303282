import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar, TextArea } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { PersonalRequestGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Toolbar(props: Props) {
  const { tableState, customState } = props;
  const { action } = customState;

  const {
    isHelpFormOpen,
    setIsHelpFormOpen,
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    requestType,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    tenderInfo,
    setTenderInfo,
    tenderRequestType,
    setTenderRequestType,
    statusChangeMessage,
    setStatusChangeMessage,
    isStatusChangeModalOpen,
    setIsStatusChangeModalOpen,
    statusChangeConfirm,
    selectedRow,
  } = useController({
    tableState,
    action,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.RequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          requestType={requestType}
          specification={tableState.selectedRows[0] ? tableState.selectedRows[0]['id:Specification'] : null}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.RequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          requestType={requestType}
          specification={tableState.selectedRows[0] ? tableState.selectedRows[0]['id:Specification'] : null}
        />
      )}

      <modalTemplates.RequestAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        relatedTableState={tableState}
        tenderInfo={tenderInfo}
        setTenderInfo={setTenderInfo}
        tenderRequestType={tenderRequestType}
        setTenderRequestType={setTenderRequestType}
      />

      <Modal
        title={`Подать на согласование заявку "${selectedRow?.Name}"`}
        isOpen={isStatusChangeModalOpen}
        onClose={() => {
          setIsStatusChangeModalOpen(false);
          setStatusChangeMessage('');
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: statusChangeConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => {
              setIsStatusChangeModalOpen(false);
              setStatusChangeMessage('');
            },
          },
        ]}
        size="medium"
      >
        <strong>Сообщение:</strong>
        <TextArea value={statusChangeMessage} onChange={setStatusChangeMessage} />
      </Modal>

      <Modal
        mode="help"
        title="Мои заявки на НИОКР"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {PersonalRequestGridHelp()}
      </Modal>

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>Заявка на конкурс от {tableState.selectedRows[0]?.Leader}. Удалить заявку?</>
        </Modal>
      )}
    </>
  );
}

export { Toolbar };

import React, { useEffect } from 'react';
import { block } from 'bem-cn';

import { FormComponent, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { formatNumber } from 'utils/Helpers';
import { ContestRequestEstimatePosition as EstimatePosition } from 'types/models/ContestRequest';

import './style.scss';

const b = block('cr-estimate');

type Props = {
  estimatePositions: EstimatePosition[];
  handleEstimateChange(estimatePositions: EstimatePosition[]): void;
  disabled: boolean;
  estimateExpencesJustification?: string;
  handleEstimateExpencesJustificationChange?(e: string): void;
  handleTotalChange?(e: number): void;
};

function isSumPosition(position: EstimatePosition) {
  return position.kindConsumption.incrimentKindConsumptions.length > 0;
}

/** is used for contestRequests */
export function ContestRequestEstimate(props: Props) {
  const {
    estimatePositions,
    handleEstimateChange,
    disabled,
    estimateExpencesJustification,
    handleEstimateExpencesJustificationChange,
    handleTotalChange,
  } = props;

  const positions = (estimatePositions ?? []).sort(
    (x, y) => Number(x.kindConsumption.position) - Number(y.kindConsumption.position),
  );

  const makeHandleChangePosition = (position: EstimatePosition, key: keyof EstimatePosition) => (value: string) => {
    const pos = estimatePositions ?? [];
    const index = pos.findIndex(x => x.kindConsumption.id === position.kindConsumption.id);
    const updatedPositions = [...pos];
    updatedPositions[index] = { ...pos[index], [key]: value } as EstimatePosition;
    handleEstimateChange(updatedPositions);
  };

  const renderField = (position: EstimatePosition) => {
    if (isSumPosition(position)) {
      const childKindConsumptionsIds = position.kindConsumption.incrimentKindConsumptions.map(x => x.id);
      const childPositions = positions.filter(x => childKindConsumptionsIds.includes(x.kindConsumption.id));
      const sum = childPositions.reduce((acc, cur) => acc + Number(cur.amount), 0);
      return formatNumber(sum);
    }

    return (
      <TextInput
        mode={TextInputMode.NUMBER}
        value={position.amount}
        onChange={makeHandleChangePosition(position, 'amount')}
        placeholder="0"
        isDisabled={disabled}
      />
    );
  };

  const commonSum = positions.reduce((sum, cur) => sum + Number(cur.amount), 0);

  useEffect(() => handleTotalChange?.(commonSum), [commonSum, handleTotalChange]);

  return (
    <div className={b()}>
      <div className={b('grid')}>
        <div className={b('cell', { bold: true, head: true })}>Статья затрат</div>
        <div className={b('cell', { bold: true, head: true })}>Сумма</div>
        <div className={b('cell', { bold: true, head: true })}>Назначение</div>
        {positions.map((position, index) => {
          const isSum = isSumPosition(position);
          const isBold = position.kindConsumption.incrimentKindConsumptions.length > 0 || !position.kindConsumption.incrimenator;
          return (
            <React.Fragment key={index}>
              <div className={b('cell', { bold: isBold, name: true })}>{position.kindConsumption.name}</div>
              <div className={b('cell', { bold: isBold })}>{renderField(position)}</div>
              <div className={b('cell')}>
                {!isSum && (
                  <div className={b('note')}>
                    <div className={b('label')}>Примечание</div>
                    <FormComponent.Field>
                      <TextArea
                        mode={TextAreaMode.MODAL}
                        settings={{ rows: 3, title: 'Примечание' }}
                        value={position.note || ''}
                        onChange={makeHandleChangePosition(position, 'note')}
                        isDisabled={!!disabled}
                      />
                    </FormComponent.Field>
                  </div>
                )}
              </div>
            </React.Fragment>
          );
        })}
      </div>
      <div className={b('fields')}>
        <FormComponent.Field label="">
          <h2>Итого {formatNumber(commonSum)}</h2>
        </FormComponent.Field>
        {!!handleEstimateExpencesJustificationChange && (
          <FormComponent.Field label="Обоснование планируемых расходов">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Обоснование планируемых расходов' }}
              value={estimateExpencesJustification ?? ''}
              onChange={handleEstimateExpencesJustificationChange}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        )}
      </div>
    </div>
  );
}

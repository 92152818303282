import { ForeignSecurityDocument } from 'types/models';

import { Settings } from 'types/models/common';
import { convertSecurityDocumentMember } from 'services/BackendAPI/configurations/securityDocument/converters';
import { convertMaybeArray, convertReference, getId, getText, convertItem, convertDocument } from '../commonConverters';

export function convertForeignSecurityDocument(secDoc: any, settings: Settings): ForeignSecurityDocument {
  return {
    id: getId(secDoc),
    name: getText(secDoc.Name),
    number: getText(secDoc.Number),
    startDate: getText(secDoc.StartDate),
    endDate: getText(secDoc.EndDate),
    status: secDoc.Status ? convertItem<string>(secDoc.Status) : null,
    kind: secDoc.Kind ? convertReference(secDoc.Kind) : null,
    members: convertMaybeArray(secDoc.Members.Member, m => convertSecurityDocumentMember(m, settings)).sort(
      (a, b) => parseFloat(a?.id || '0') - parseFloat(b?.id || '0'),
    ),
    documents: [
      ...(secDoc.Documents && secDoc.Documents.Document
        ? convertMaybeArray(secDoc.Documents.Document, i => convertDocument('local', i))
        : []),
    ],
    type: secDoc.Type ? convertItem(secDoc.Type) : null,
    authors: getText(secDoc.Authors),
    documentNumber: getText(secDoc.DocumentNumber),
    documentStartDate: getText(secDoc.DocumentStartDate),
    intellectualPropertyName: getText(secDoc.IntellectualPropertyName),
    applicants: getText(secDoc.Applicants),
    contract: getText(secDoc.Contract),
  };
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Props as BaseProps } from './index';
import { Setting } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';
import { getHashObject } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';
import { Operation } from 'features/Table/ThirdLevelHeader/TemplatesPanel/types';

type Props = {} & BaseProps;

const defaultSetting = {
  id: '',
  name: '',
  purpose: '',
  isShared: false,
  author: '',
};

const useController = ({
  mode,
  onClose,
  setting = { ...defaultSetting },
  columns,
  gridName,
  crudCallback,
  successfullCallback,
}: Props) => {
  const { userPermission } = useAppDataContext();

  const [settings, setSettings] = useState<Setting>(setting);

  const { methods: addNewSettingAPIMethods } = BackendAPI.useBackendAPI('SaveGridSetting');

  // hack to deprecate autoreload form
  const hashOutherSettings = useMemo(() => getHashObject(setting || {}), [setting]);

  useEffect(() => {
    if (setting && getHashObject(setting || {}) !== getHashObject(settings)) {
      setSettings(setting);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hashOutherSettings]);

  const onCloseCallback = useCallback(() => {
    setSettings({ ...defaultSetting });
    onClose?.();
  }, [onClose]);

  const onSubmit = useCallback(() => {
    addNewSettingAPIMethods.callAPI(
      {
        gridName,
        columns: mode === 'add' ? columns : undefined,
        ...settings,
      },
      {
        onSuccessfullCall: async () => {
          if (mode === 'add') {
            showNotification({ message: 'Шаблон успешно добавлен', theme: 'success' });
            await crudCallback?.({
              operation: Operation.ADD,
              name: settings.name,
              author: userPermission?.userLogin ?? '',
            });
          } else {
            showNotification({ message: 'Шаблон успешно отредактирован', theme: 'success' });
            await crudCallback?.({
              operation: Operation.EDIT,
              name: settings.name,
              author: userPermission?.userLogin ?? '',
            });
          }

          onCloseCallback();
          successfullCallback?.(settings);
        },
      },
    );
  }, [
    addNewSettingAPIMethods,
    columns,
    crudCallback,
    gridName,
    mode,
    onCloseCallback,
    settings,
    successfullCallback,
    userPermission?.userLogin,
  ]);

  return {
    settings,
    setSettings,
    onSubmit,
    onCloseCallback,
  };
};

export default useController;

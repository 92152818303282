import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { RequestStatus, NirRequestStatus, MobileRequestStatus, TenderRequestType } from 'utils/Enums';
import { getRequestStatusButtons } from 'features/Table/specifications/GetRequestList/helpers';
import { getNirRequestStatusButtons } from 'features/Table/specifications/GetNirRequestList/helpers';
import { getMobileRequestStatusButtons } from 'features/Table/specifications/GetMobileRequestList/helpers';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController(props: Props) {
  const {
    tableState,
    customState: { type, statusChangeCallback },
  } = props;

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const statusChangeInfo =
    type === TenderRequestType.PROGRAM_REQUEST
      ? getRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({ id: x.id, status: (selectedRow?.['id:Status'] as RequestStatus) || null })),
          callback: statusChangeCallback,
        })
      : type === TenderRequestType.NIR_REQUEST
      ? getNirRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({
            id: x.id,
            status: (selectedRow?.['id:Status'] as NirRequestStatus) || null,
          })),
          callback: statusChangeCallback,
        })
      : type === TenderRequestType.MOBILE_REQUEST
      ? getMobileRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({
            id: x.id,
            status: (selectedRow?.['id:Status'] as MobileRequestStatus) || null,
          })),
          callback: statusChangeCallback,
        })
      : null;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: !selectedRow,
      },
      ...(statusChangeInfo?.buttons || []),
    ],
    [selectedRow, statusChangeInfo?.buttons],
  );

  return {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    statusChangeInfo,
  };
}

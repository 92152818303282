import { ReferenceItem, Tabs, Tab } from 'components';

import { EditableMetadataField, Mode } from 'features/Table/specifications/GetReferenceElementList/model';
import { Fields } from 'features/Table/specifications/GetReferenceElementList/Editor/Fields';

type Props = {
  mode: Mode | null;
  fields: EditableMetadataField[];
  handleChangeField(name: string, value: string | ReferenceItem): void;
  isRecordEditable: boolean;
};

function CustomersForms(props: Props) {
  const { handleChangeField, mode, fields, isRecordEditable } = props;

  const mainFields = [
    'isFavorite',
    'name',
    'fullName',
    'ogrn',
    'inn',
    'kpp',
    'sectorActivity',
    'lksdEnterpriseType',
    'details',
    'email',
    'phone',
    'website',
  ];

  return (
    <Tabs>
      <Tab title="Основные">
        <Fields
          mode={mode}
          fields={mainFields.map(mf => fields.find(f => f.name === mf)).filter(Boolean) as EditableMetadataField[]}
          onChange={handleChangeField}
          isRecordEditable={isRecordEditable}
        />
      </Tab>
      <Tab title="Адрес">
        <Fields
          mode={mode}
          fields={fields.filter(x => !mainFields.includes(x.name))}
          onChange={handleChangeField}
          isRecordEditable={isRecordEditable}
        />
      </Tab>
    </Tabs>
  );
}

export { CustomersForms };

import React from 'react';
import { setup } from 'bem-cn';

import { Icon } from 'components';

import { formatNumber } from 'utils/Helpers';
import { ColumnType } from 'types/models/Table';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('grid-cell');

type Props = {
  columnType: ColumnType;
  cellValue: string;
};

export const Cell = ({ columnType, cellValue }: Props) => {
  switch (columnType) {
    case 'MONEY':
      return <div className={b({}).is({ money: true })}>{cellValue ? formatNumber(cellValue) : ''}</div>;
    case 'INTEGER':
      return <div className={b({}).is({ integer: true })}>{cellValue}</div>;
    case 'BOOLEAN':
      return <div className={b({}).is({ boolean: true })}>{cellValue === 'true' ? <Icon type="check" /> : ''}</div>;
    case 'HTML':
      return <div dangerouslySetInnerHTML={{ __html: cellValue }} />;
    default:
      return <div className={b()}>{cellValue}</div>;
  }
};

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { TenderKindConsumptionGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    selectedRow,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
  } = useController({ tableState, customState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.View
        isOpen={isViewFormOpen}
        onClose={() => setIsViewFormOpen(false)}
        id={selectedRow?.id}
        relatedTableState={tableState}
        tenderId={customState.tenderId}
      />

      <modalTemplates.Edit
        isOpen={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        id={selectedRow?.id}
        relatedTableState={tableState}
        tenderId={customState.tenderId}
      />

      <modalTemplates.Add
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        relatedTableState={tableState}
        tenderId={customState.tenderId}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteConfirmPopupOpen}
        onClose={() => setIsDeleteConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleConfirmDeleteConfirmPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsDeleteConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>
          ({selectedRow?.Name}) Удалить вид расхода из списка допустимых в смете заявки?
          {!!selectedRow?.IncrementKindConsumptions && (
            <>
              <br />
              <br />
              <strong>Внимание! Нельзя удалить вид расхода который является общей суммой перечисленных видов расходов.</strong>
            </>
          )}
        </>
      </Modal>

      <Modal
        mode="help"
        title="Помощь: Конкурсы, Шаблон сметы для заявок"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {TenderKindConsumptionGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { Project } from 'types/models/Project';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isSelectedRntd = useMemo(() => tableState.selectedRows[0]?.Source === 'Rntd', [tableState]);

  const isAddButtonDisabled = !rowsAvailability.SINGLE_SELECTED || isSelectedRntd;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !isSelectedRntd;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !isSelectedRntd;
  const isProjectInfoButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !isSelectedRntd;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !isSelectedRntd;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Rntd',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const handleProjectInfoButtonClick = useCallback(() => {
    const projectId = tableState.selectedRows[0]?.ProjectId;
    if (projectId) {
      getProjectAPI.callAPI(
        { id: projectId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject(data);
            setIsProjectInfoModalOpen(true);
          },
        },
      );
    }
  }, [tableState, getProjectAPI]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.RNTD_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.RNTD_ADD },
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.RNTD_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.RNTD_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'info' },
        title: 'Информация о проекте, в рамках которого получен ОИС',
        onClick: handleProjectInfoButtonClick,
        isDisabled: isProjectInfoButtonDisabled,
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      isAddButtonDisabled,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isProjectInfoButtonDisabled,
      handleProjectInfoButtonClick,
    ],
  );

  return {
    isAddButtonDisabled,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  };
}

import React, { useCallback } from 'react';

import { FormComponent, ListEditTable, SectionTitle, Select, SelectMode, TextInput, TextInputMode } from 'components';

import { Item } from 'types/models/common';
import { AuthorPayment } from 'types/models/Payment';

type Props = {
  authorPayment: AuthorPayment;
  disabled: boolean;
  changeField(key: keyof AuthorPayment, value: string | Item): void;
};

const monthOptionsMap: Record<number | string, { label: string; value: string }[]> = {
  FIRST: [
    { label: 'Январь', value: '1' },
    { label: 'Февраль', value: '2' },
    { label: 'Март', value: '3' },
  ],
  SECOND: [
    { label: 'Апрель', value: '4' },
    { label: 'Май', value: '5' },
    { label: 'Июнь', value: '6' },
  ],
  THIRD: [
    { label: 'Июль', value: '7' },
    { label: 'Август', value: '8' },
    { label: 'Сентябрь', value: '9' },
  ],
  FOURTH: [
    { label: 'Октябрь', value: '10' },
    { label: 'Ноябрь', value: '11' },
    { label: 'Декабрь', value: '12' },
  ],
};

export function Info({ authorPayment, disabled, changeField }: Props) {
  if (authorPayment === null) {
    return null;
  }

  const monthOptions = monthOptionsMap[authorPayment?.quarter?.value || ''];

  const changeQuarter = useCallback(
    (quarter: Item | null) => {
      const [monthOption] = monthOptionsMap[quarter?.value ?? 'FIRST'];
      changeField('quarter', quarter ?? { label: '1', value: 'FIRST' });
      changeField('month', monthOption.value);
    },
    [changeField],
  );

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '25%' }}>
        <SectionTitle title="Период оплаты" />
        <FormComponent.Line>
          <FormComponent.Field label="Год" isRequired>
            <TextInput
              mode={TextInputMode.YEAR}
              value={authorPayment.year}
              onChange={val => changeField('year', val)}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Квартал" isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={authorPayment.quarter}
              settings={{ name: 'Quarter' }}
              onChange={changeQuarter}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Месяц" isRequired>
            <Select
              value={monthOptions.find(x => x.value === authorPayment.month)}
              options={monthOptions}
              onChange={(option: Item) => changeField('month', option.value)}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: '75%' }}>
        <SectionTitle title="Условия оплаты" />
        <FormComponent.ColumnWrapper>
          <FormComponent.Column style={{ width: '60%' }}>
            <FormComponent.Line>
              <FormComponent.Field label="Пункт положения" isRequired>
                <TextInput
                  value={authorPayment?.regulationParagraph ?? ''}
                  onChange={val => changeField('regulationParagraph', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Название" isRequired>
                <TextInput
                  value={authorPayment?.regulationName ?? ''}
                  onChange={val => changeField('regulationName', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Фонд номинантов, руб:" isRequired>
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={authorPayment?.maxNomineesMoney ?? ''}
                  onChange={val => changeField('maxNomineesMoney', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
              <FormComponent.Field label="Фонд подразделений, руб:">
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={authorPayment?.maxFundMoney ?? ''}
                  onChange={val => changeField('maxFundMoney', val)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column style={{ width: '40%' }}>
            <FormComponent.Line>
              <ListEditTable
                rows={[
                  {
                    type: 'Максимальная',
                    nominie: authorPayment.maxNomineesMoney,
                    fund: authorPayment.maxFundMoney,
                  },
                  {
                    type: 'Фактическая',
                    nominie: authorPayment.factNomineesMoney,
                    fund: authorPayment.factFundMoney,
                  },
                  {
                    type: 'Остаток',
                    nominie: authorPayment.remainderNomineesMoney,
                    fund: authorPayment.remainderFundMoney,
                  },
                ]}
                columns={[
                  { label: 'Сумма, руб.', formatValue: row => row.type || '', styles: { width: '40%' } },
                  { label: 'Номинанты', formatValue: row => row.nominie || 0, styles: { width: '30%' }, dataKind: 'float' },
                  { label: 'Фонд', formatValue: row => row.fund || 0, styles: { width: '30%' }, dataKind: 'float' },
                ]}
                selectedRowIndex={null}
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                selectRow={function (index: number | null): void {}}
              />
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

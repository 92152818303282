import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { ProgramEvent } from 'types/models';
import { convertProgramEvent } from './converters';
import { convertProgramEventToXML } from './requestConverters';
import { SaveProgramEventRequest } from './types';

export const programEventConfigurations = {
  SaveProgramEvent: makeBackendAPIConfiguration({
    id: 'SaveProgramEvent',
    endpoint: '/msa/service/commands/SaveProgramEvent',
    convertInputToXMLElement: (inputData: SaveProgramEventRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveProgramEvent',
        },
        ...convertProgramEventToXML(inputData),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetProgramEvent: makeBackendAPIConfiguration({
    id: 'GetProgramEvent',
    endpoint: '/msa/service/commands/GetProgramEvent',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetProgramEvent',
          },
          ProgramEvent: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ProgramEvent =>
      convertProgramEvent(response.Response.ProgramEvent),
  }),
};

import { useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';

export type Mode = 'add' | 'edit' | null;

export type State = {
  isOpenEditor: boolean;
  fio: string;
  language: ReferenceItem | null;
  mode: Mode;
  setMode(mode: Mode): void;
  setFIO(val: string): void;
  setLanguage(lang: ReferenceItem | null): void;
  saveBibliographicName(): void;
  setEditedBiblio(): void;
  deleteBibliographicName(): void;
  setDefaultBiblioName(): void;
  openEditor(): void;
  closeEditor(): void;
};

type Arguments = {
  scientistId: string;
};

function getSelectedRow(selectedRows: Table.Entry[]): Table.Entry | null {
  if (selectedRows.length) {
    return selectedRows[0];
  }

  return null;
}

export function makeUseCustomController({ scientistId }: Arguments) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [isOpenEditor, setIsOpenEditor] = useState(false);
    const [fio, setFIO] = useState<string>('');
    const [language, setLanguage] = useState<ReferenceItem | null>(null);
    const [biblioRow, setBiblioRow] = useState<Table.Entry | null>(null);
    const [mode, setMode] = useState<Mode>(null);

    const tableStreams = useLocalTableStreams();

    const { methods: saveBibliographicNameAPI } = BackendAPI.useBackendAPI('SaveScientistBibliographicName', {
      onSuccessfullCall: () => {
        reloadTable();
        resetState();
        showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
      },
    });

    const { methods: setDefaultBibliographicNameAPI } = BackendAPI.useBackendAPI('SetDefaultScientistBibliographicName', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'ФИО по умолчанию успешно установлено', theme: 'success' });
      },
    });

    const { methods: deleteRowAPI } = BackendAPI.useBackendAPI('DeleteModel', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'Элемент успешно удален', theme: 'success' });
      },
    });

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const saveBibliographicName = useCallback(() => {
      if (!language || !fio.trim()) {
        showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
        return;
      }
      saveBibliographicNameAPI.callAPI({
        id: mode === 'edit' ? biblioRow?.id : undefined,
        bibliographicName: fio,
        languageId: language.id,
        isDefault: biblioRow ? biblioRow.isDefault : 'false',
        scientistId,
      });
    }, [biblioRow, fio, language, mode, saveBibliographicNameAPI]);

    const deleteBibliographicName = useCallback(() => {
      const row = getSelectedRow(selectedRows);
      if (row) {
        deleteRowAPI.callAPI({ entityName: 'ScientistBibliographicName', rowId: row.id, commandName: 'DeleteEntity' });
      }
    }, [deleteRowAPI, selectedRows]);

    const setDefaultBiblioName = useCallback(() => {
      const row = getSelectedRow(selectedRows);
      if (row) {
        setDefaultBibliographicNameAPI.callAPI({
          bibliographicName: row.bibliographicName,
          id: row.id,
          languageId: row.languageId,
          scientistId: row.scientistId,
        });
      }
    }, [selectedRows, setDefaultBibliographicNameAPI]);

    const setEditedBiblio = useCallback(() => {
      const row = getSelectedRow(selectedRows);
      if (row) {
        setBiblioRow(row);
        setFIO(row.bibliographicName);
        setLanguage({ id: row.languageId, label: row.languageName });
      }
    }, [selectedRows]);

    const resetState = useCallback(() => {
      setMode(null);
      setFIO('');
      setLanguage(null);
    }, [setMode]);

    const openEditor = useCallback(() => {
      setIsOpenEditor(true);
      tableStreams.toggleSecondLevelPanel.push();
    }, [tableStreams.toggleSecondLevelPanel]);

    const closeEditor = useCallback(() => {
      setIsOpenEditor(false);
      resetState();
      tableStreams.toggleSecondLevelPanel.push();
    }, [resetState, tableStreams.toggleSecondLevelPanel]);

    return {
      fio,
      language,
      isOpenEditor,
      mode,
      setFIO,
      setLanguage,
      saveBibliographicName,
      setEditedBiblio,
      setMode,
      setDefaultBiblioName,
      deleteBibliographicName,
      openEditor,
      closeEditor,
    };
  };
}

import { MobileRequest } from 'types/models';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { findLastestPerformerWithRole } from 'utils/Helpers';
import { Settings } from 'types/models/common';

import { convertPerformer } from '../project/converters';
import {
  convertMaybeArray,
  getId,
  getText,
  convertReference,
  convertItem,
  getBoolean,
  convertDocument,
  getLabel,
} from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { convertLot, convertTender } from '../tenders/converters';
import { convertTenderKindConsumption } from '../kindConsumption/converters';

export function convertMobileRequest(mobileRequest: any, settings: Settings): MobileRequest.MobileRequest {
  const advertisingMaterialTypes = mobileRequest.AdvertisingMaterialTypes?.AdvertisingMaterialType || [];
  const applicantSupervisors = mobileRequest.ApplicantSupervisors?.ApplicantSupervisor || [];
  const estimatePositions = mobileRequest.EstimatePositions?.EstimatePosition || [];
  const financings = mobileRequest.Financings?.Financing || [];
  const pnrs = mobileRequest.Pnrs?.Pnr || [];
  const routes = mobileRequest.Routes?.Route || [];
  const tripPlanes = mobileRequest.TripPlanes?.TripPlan || [];
  const useMaterialObligations = mobileRequest.UseMaterialObligations?.UseMaterialObligation || [];

  const result: MobileRequest.MobileRequest = {
    ...convertPersonHistory(mobileRequest),
    id: getId(mobileRequest),
    visa: mobileRequest.Visa ? convertReference(mobileRequest.Visa) : null,
    activityKind: mobileRequest.ActivityKind ? convertReference(mobileRequest.ActivityKind) : null,
    advertisingMaterialTypes: convertMaybeArray(advertisingMaterialTypes, convertReference),
    applicant: mobileRequest.Applicant ? convertServerPerson(mobileRequest.Applicant, settings) : null,
    applicantContact: getText(mobileRequest.ApplicantContact),
    applicantDepartment: mobileRequest.ApplicantDepartment ? convertReference(mobileRequest.ApplicantDepartment) : null,
    applicantLanguageCompetence: getText(mobileRequest.ApplicantLanguageCompetence),
    applicantNote: getText(mobileRequest.ApplicantNote),
    applicantSupervisors: convertMaybeArray(applicantSupervisors, e => convertApplicantSupervisor(e, settings)),
    businessTripEndDate: getText(mobileRequest.BusinessTripEndDate),
    businessTripStartDate: getText(mobileRequest.BusinessTripStartDate),
    code: getText(mobileRequest.Code),
    description: getText(mobileRequest.Description),
    estimatePositions: convertMaybeArray(estimatePositions, convertEstimatePosition),
    eventMaterialLevel: getText(mobileRequest.EventMaterialLevel),
    eventReportName: getText(mobileRequest.EventReportName),
    financings: convertMaybeArray(financings, x => convertFinancing(x, settings)),
    isEventReportAccepted: getBoolean(mobileRequest.IsEventReportAccepted),
    language: mobileRequest.Language ? convertReference(mobileRequest.Language) : null,
    mobileProtocol: mobileRequest.MobileProtocol ? convertMobileProtocol(mobileRequest.MobileProtocol) : null,
    number: getText(mobileRequest.Number),
    pnrs: convertMaybeArray(pnrs, convertReference),
    purpose: mobileRequest.Purpose ? convertItem(mobileRequest.Purpose) : null,
    routes: convertMaybeArray(routes, convertRoute),
    sendDate: getText(mobileRequest.SendDate),
    status: mobileRequest.Status ? convertItem(mobileRequest.Status) : null,
    theme: getText(mobileRequest.Theme),
    traineeEndDate: getText(mobileRequest.TraineeEndDate),
    traineeNecessityDocument: getText(mobileRequest.TraineeNecessityDocument),
    traineeStartDate: getText(mobileRequest.TraineeStartDate),
    tripPlanes: convertMaybeArray(tripPlanes, convertTripPlan),
    useMaterialObligations: convertMaybeArray(useMaterialObligations, convertUseMaterialObligation),
    documents: [
      ...convertMaybeArray(mobileRequest?.Documents?.Document || [], i => convertDocument('local', i)),
      ...convertMaybeArray(mobileRequest?.ForeignDocuments?.Document || [], i => convertDocument('foreign', i)),
    ],
    specialNote: getText(mobileRequest.SpecialNote),
    tender: mobileRequest.Tender ? convertTender(mobileRequest.Tender, settings) : null,
    lot: mobileRequest.Lot ? convertLot(mobileRequest.Lot, settings) : null,
    applicantEducationYear: mobileRequest.ApplicantEducationYear
      ? parseInt(getText(mobileRequest.ApplicantEducationYear), 10)
      : null,
    applicantGroupNumber: getText(mobileRequest.ApplicantGroupNumber),
    scienceDomainInterrest: mobileRequest.ScienceDomainInterest ? convertReference(mobileRequest.ScienceDomainInterest) : null,
    tenderParticipant: mobileRequest.TenderParticipant ? convertReference(mobileRequest.TenderParticipant) : null,
    destinationSupervisor: getText(mobileRequest.DestinationSupervisor),
    destinationSupervisorSpecifics: getText(mobileRequest.DestinationSupervisorSpecifics),
    participationsReportsThemes: getText(mobileRequest.ParticipationsReportsThemes),
  };

  return result;
}

function convertApplicantSupervisor(applicantSupervisor: any, settings: Settings): MobileRequest.ApplicantSupervisor {
  return {
    ...convertPersonHistory(applicantSupervisor),
    id: getId(applicantSupervisor),
    role: convertItem(applicantSupervisor.Role),
    supervisor: convertServerPerson(applicantSupervisor.Supervisor, settings),
  };
}

function convertEstimatePosition(estimatePosition: any): MobileRequest.MobileRequestEstimatePosition {
  return {
    id: getId(estimatePosition),
    amount: getText(estimatePosition.Amount),
    kindConsumption: convertTenderKindConsumption(estimatePosition.KindConsumption),
    note: getText(estimatePosition.Note),
  };
}

function convertFinancing(financing: any, settings: Settings): MobileRequest.Financing {
  return {
    id: getId(financing),
    amount: getText(financing.Amount),
    type: financing.Type ? convertItem(financing.Type) : null,
    note: getText(financing.Note),
    sf1Source: financing.Sf1Source ? convertSf1Source(financing.Sf1Source, settings) : null,
    department: financing.Department ? convertReference(financing.Department) : null,
    sf3Source: financing.Sf3Source ? convertItem(financing.Sf3Source) : null,
  };
}

function convertSf1Source(sf1Source: any, settings: Settings): MobileRequest.Sf1Source {
  const fundCard = sf1Source.FundCard || null;
  const projectCode = sf1Source.ProjectCode || null;
  const isFundCard = !!fundCard;
  const isProjectCode = !!projectCode;
  const performers = projectCode?.Project?.Performers?.Performer || [];
  const program = projectCode?.Project?.Program || null;
  const programEvent = projectCode?.Project?.ProgramEvent || '';

  return {
    projectCodeId: isProjectCode ? getId(projectCode) : '',
    projectCodeValue: isProjectCode
      ? `${getLabel(projectCode.Project.Type)} № ${getText(projectCode.Project.Number)} (${
          findLastestPerformerWithRole(
            convertMaybeArray(performers, x => convertPerformer(x, settings)),
            'LEADER',
          )?.fio
        })${program ? ` ${getText(program.ShortName)}` : ''}${
          programEvent ? `${`${program ? ': ' : ''}${getText(programEvent.Name)}`}` : ''
        }`
      : '',
    fundCardId: isFundCard ? getId(fundCard) : '',
    fundCardValue: isFundCard ? `ЦФ НУ № ${getText(fundCard.Code)}${fundCard.Note ? ` (${getText(fundCard.Note)})` : ''}` : '',
  };
}

function convertMobileProtocol(mobileProtocol: any): MobileRequest.MobileProtocol {
  return {
    id: getId(mobileProtocol),
    number: getText(mobileProtocol.Number),
    protocolDate: getText(mobileProtocol.ProtocolDate),
    status: mobileProtocol.Status ? convertItem(mobileProtocol.Status) : null,
  };
}

function convertRoute(route: any): MobileRequest.Route {
  return {
    id: getId(route),
    city: getText(route.City),
    country: route.Country ? convertReference(route.Country) : null,
    enterprise: route.Enterprise ? convertReference(route.Enterprise) : null,
  };
}

function convertTripPlan(tripPlan: any): MobileRequest.TripPlan {
  return {
    id: getId(tripPlan),
    question: getText(tripPlan.Question),
    implementationForm: getText(tripPlan.ImplementationForm),
  };
}

function convertUseMaterialObligation(useMaterialObligation: any): MobileRequest.UseMaterialObligation {
  return {
    id: getId(useMaterialObligation),
    result: useMaterialObligation.Result ? convertReference(useMaterialObligation.Result) : null,
    resultDate: getText(useMaterialObligation.ResultDate),
    resultDescription: getText(useMaterialObligation.ResultDescription),
  };
}

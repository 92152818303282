import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistColleagueList } from 'features/Table/specifications';

type Props = {
  personId?: string;
};
export function Colleagues({ personId }: Props) {
  return <DataGrid specification={GetScientistColleagueList({ scientistId: personId })} />;
}

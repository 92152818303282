import { parse } from 'date-fns';

import { MipPartner } from 'types/models';
import { formatStr } from 'utils/Constants';

export function validate(charterCapital: MipPartner.CharterCapital | null, charterCapitals: MipPartner.CharterCapital[]) {
  const isEditDateValid = Boolean(charterCapital?.editDate);
  const isAmountValid = Boolean(charterCapital?.amount);
  const isValidFounders = Boolean(charterCapital?.founders.length);
  const foundersSumm = (charterCapital?.founders || []).reduce<number>((accum, current) => accum + Number(current.amount), 0);

  const isFoundersSummMoreThanAmount = foundersSumm > Number(charterCapital?.amount ?? 0);

  const getYear = (date: string) => parse(date, formatStr, new Date()).getFullYear();

  const isBusyYear = (charterCapital?.id ? charterCapitals.filter(i => i.id !== charterCapital?.id) : charterCapitals)
    .map(i => getYear(i.editDate))
    .includes(getYear(charterCapital?.editDate || ''));

  return [
    { isValid: isEditDateValid, invalidMessage: 'Заполните поле "Дата изменения"' },
    { isValid: isAmountValid, invalidMessage: 'Заполните поле "Уставной капитал"' },
    { isValid: isValidFounders, invalidMessage: 'Укажите хотя бы одного Учредителя' },
    { isValid: !isFoundersSummMoreThanAmount, invalidMessage: 'Сумма по учредителям превышает уставный капитал' },
    { isValid: !isBusyYear, invalidMessage: 'Указанный год уже занят' },
  ];
}

import { Partner } from 'types/models';
import { Change, Contact, PeriodableReferenceItem } from 'types/models/Partner';
import { Settings } from 'types/models/common';
import { convertServerPerson } from '../author/converters';
import { convertDocument, convertMaybeArray, convertReference, getText, convertItem, convertFile } from '../commonConverters';
import { convertServerCitationSystem } from '../payments/converters';

export function convertServerPartner(partner: any, settings: Settings): Partner {
  const serverDocuments = partner.Documents?.Document || [];
  const okveds = partner.Okveds?.PeriodableReferenceElement || [];
  const partnershipTypes = partner.PartnershipTypes?.PeriodableReferenceElement || [];
  const citationSystems = partner.CitationSystems?.CitationSystem || [];
  const contacts = partner.Contacts?.Contact || [];
  const changes = partner.Changes?.Change || [];
  const childs = partner.Childs?.Child || [];

  return {
    id: partner._attributes?.id ?? null,
    relation: partner.Relation ? convertItem(partner.Relation) : null,
    manager: partner.Manager ? convertServerPerson(partner.Manager, settings) : null,
    parent: partner.Parent ? convertServerPartner(partner.Parent, settings) : null,
    department: partner.Department ? convertReference(partner.Department) : null,
    enterprise: partner.Enterprise ? convertReference(partner.Enterprise) : null,
    enterpriseCustomer: partner.EnterpriseCustomer ? convertReference(partner.EnterpriseCustomer) : null,
    activitySector: partner.ActivitySector ? convertReference(partner.ActivitySector) : null,
    country: partner.Country ? convertReference(partner.Country) : null,
    lksd: partner.Lksd ? convertReference(partner.Lksd) : null,
    status: partner.Status ? convertItem(partner.Status) : null,
    state: partner.State ? convertReference(partner.State) : null,
    shortName: getText(partner.ShortName),
    fullName: getText(partner.FullName),
    city: getText(partner.City),
    inn: getText(partner.Inn),
    email: getText(partner.Email),
    createdDate: getText(partner.CreatedDate),
    documents: convertMaybeArray(serverDocuments, i => convertDocument('local', i)),
    house: getText(partner.House),
    isDeleted: getText(partner.IsDeleted) === 'true',
    kpp: getText(partner.Kpp),
    district: partner.District ? getText(partner.District) : '',
    okopf: partner.Okopf ? getText(partner.Okopf) : '',
    office: partner.Office ? getText(partner.Office) : '',
    bankRequisites: partner.BankRequisites ? getText(partner.BankRequisites) : '',
    webSite: partner.WebSite ? getText(partner.WebSite) : '',
    modifiedDate: getText(partner.ModifiedDate),
    ogrn: getText(partner.Ogrn),
    street: getText(partner.Street),
    postCode: getText(partner.PostCode),
    phone: getText(partner.Phone),
    okveds: convertMaybeArray(okveds, convertPeriodableReferenceItem),
    partnershipTypes: convertMaybeArray(partnershipTypes, convertPeriodableReferenceItem),
    citationSystems: convertMaybeArray(citationSystems, convertServerCitationSystem),
    contacts: convertMaybeArray(contacts, c => convertServerContact(c, settings)),
    changes: convertMaybeArray(changes, convertServerChange),
    childs: convertMaybeArray(childs, p => convertServerPartner(p, settings)),
  };
}

export function convertServerChange(change: any): Change {
  return {
    id: change._attributes.id,
    category: convertReference(change.Category),
    changeDate: getText(change.ChangeDate),
    createdBy: getText(change.CreatedBy),
    createdDate: getText(change.CreatedDate),
    description: getText(change.Description),
    modifiedBy: getText(change.ModifiedBy),
    modifiedDate: getText(change.ModifiedDate),
    file: convertFile(change.File),
  };
}

export function convertPeriodableReferenceItem(item: any): PeriodableReferenceItem {
  return {
    id: item._attributes?.id ?? null,
    ref: convertReference(item.ReferenceElement),
    startDate: item.StartDate ? getText(item.StartDate) : null,
    endDate: item.EndDate ? getText(item.EndDate) : null,
    note: item.Note ? getText(item.Note) : null,
  };
}

export function convertServerContact(contact: any, settings: Settings): Contact {
  return {
    person: convertServerPerson(contact.Person, settings),
    appointment: getText(contact.Appointment),
    communication: getText(contact.Communication),
    department: getText(contact.Department),
    extraContacts: getText(contact.ExtraContacts),
    id: contact._attributes.id,
    isActual: getText(contact.IsActual) === 'true',
    isHead: getText(contact.IsHead) === 'true',
    note: getText(contact.Note),
  };
}

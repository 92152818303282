import React, { memo } from 'react';
import { Table as T } from 'types/models';

import { GetMonographChapterList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  relatedTableState?: T.State;
  workMode: T.WorkMode;
  publicationId: string | undefined;
};

const Chapters = ({ relatedTableState, workMode, publicationId }: Props) => (
  <>
    {relatedTableState && (
      <DataGrid
        specification={GetMonographChapterList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetCompilationArticleList',
            relatedTableState,
            relatedRecordId: publicationId || undefined,
          },
          workMode,
          parentId: publicationId,
        })}
      />
    )}
  </>
);

export const ChaptersComponent = memo(Chapters);

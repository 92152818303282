import React, { useCallback } from 'react';

import { FormComponent, SectionTitle, TextInput, TextInputMode, TextDateTime, ListEdit } from 'components';

import { Form } from 'types/models';
import { SecurityDocument, Balance } from 'types/models/SecurityDocument';
import { showNotification } from 'features/Notifications';

type Props = {
  formFields: Form.Fields;
  disabled?: boolean;
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
};

export function Nma({ formFields, disabled, securityDocument, setSecurityDocument }: Props) {
  // eslint-disable-next-line max-len
  const balanceOrderNumberTooltip = `Номер акта/приказа/протокола. Если процессуально используются разные типы распорядительных документов, то рекомендуется указывать какой именно документ, например так: Акт № 1/23`;

  const renderBalanceFields = useCallback(
    (balance: Balance | null, setBalance: React.Dispatch<React.SetStateAction<Balance | null>>) => {
      return (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Дата" isRequired>
              <TextDateTime value={balance?.date} onChange={e => setBalance(prev => ({ ...prev!, date: e }))} />
            </FormComponent.Field>
            <FormComponent.Field label="Учётная стоимость" isRequired>
              <TextInput
                value={balance?.amount}
                onChange={e => setBalance(prev => ({ ...prev!, amount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сумма амортизации" isRequired>
              <TextInput
                value={balance?.alphaAmount}
                onChange={e => setBalance(prev => ({ ...prev!, alphaAmount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Остаточная стоимость" isRequired>
              <TextInput
                value={balance?.residualAmount}
                onChange={e => setBalance(prev => ({ ...prev!, residualAmount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      );
    },
    [],
  );

  return (
    <FormComponent.Wrapper>
      <SectionTitle title="Балансовый учет" />

      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Номер документа" tooltip={balanceOrderNumberTooltip}>
              <TextInput
                value={formFields.balanceOrderNumber.value || ''}
                onChange={formFields.balanceOrderNumber.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Стоимость, руб">
              <TextInput
                mode={TextInputMode.NUMBER}
                value={formFields.balancePrice.value ? formFields.balancePrice.value.toString() : '0'}
                onChange={formFields.balancePrice.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Field label={formFields.loDate.title}>
            <TextDateTime value={formFields.loDate.value} onChange={formFields.loDate.onChange} isDisabled={disabled} />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.hiDate.title}>
            <TextDateTime value={formFields.hiDate.value} onChange={formFields.hiDate.onChange} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <SectionTitle title="Учётная стоимость с учётом переоценки (по данным из бухгалтерии)" />

      <ListEdit
        rows={securityDocument.balances}
        onChange={e => setSecurityDocument(prev => ({ ...prev, balances: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Дата', formatValue: x => x.date, styles: { width: '10%' }, dataKind: 'date', defaultSort: 'desc' },
          { label: 'Учётная стоимость', formatValue: x => x.amount, styles: { width: '30%' }, dataKind: 'float' },
          { label: 'Сумма амортизации', formatValue: x => x.alphaAmount, styles: { width: '30%' }, dataKind: 'float' },
          { label: 'Остаточная стоимость', formatValue: x => x.residualAmount, styles: { width: '30%' }, dataKind: 'float' },
        ]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={5}
        columnIndexesForSumTotal={[1, 2, 3]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Учётная стоимость',
          renderComponent: renderBalanceFields,
          validation: {
            checkIsValid: row => !!row?.date && !!row?.amount && !!row?.alphaAmount && !!row?.residualAmount,
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { Button, ButtonMode, TextArea, TextAreaMode, FormComponent, Select, SelectMode } from 'components';

import useController from './controller';
import { Table } from 'types/models';

import './style.scss';

type Props = {
  onClose(): void;
};

const HandleErrorForm = ({ onClose }: Props) => {
  const {
    setErrorReason,
    handleFormSubmit,
    handleCancelButtonClick,
    errorReason,
    errorReasonDetail,
    setErrorReasonDetail,
    libraryNote,
    setLibraryNote,
  } = useController({ onClose });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Причина ошибки" isRequired>
          <Select
            mode={SelectMode.ENUM}
            settings={{ name: 'PublicationLibraryLogErrorReason' }}
            value={errorReason}
            onChange={option => setErrorReason(option as Table.EnumValue)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Детализация причины ошибки">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Детализация причины ошибки' }}
            value={errorReasonDetail}
            onChange={setErrorReasonDetail}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Комментарий">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Комментарий' }}
            value={libraryNote}
            onChange={setLibraryNote}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button mode={ButtonMode.PRIMARY} text="Отправить" onClick={handleFormSubmit} isDisabled={!errorReason?.value} />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={handleCancelButtonClick} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = React.memo(HandleErrorForm);

import { App as AppEvents } from 'utils/Events';
import { ResponseStatus } from 'utils/Enums/ResponseStatus';
import { useLocalLoaderStreams } from 'features/Loader/hooks';

const URL = '/msa/service/fileup';
// File { name: "1.jpg", lastModified: 1688438347262, webkitRelativePath: "", size: 4413, type: "image/jpeg" }

type Props = {
  file: File;
  onSuccess?: (data: any) => void;
  onFail?: (data: any) => void;
  token?: string | null;
};

export function uploadAPI({ file, onSuccess = () => {}, onFail = () => {}, token = '' }: Props) {
  const loaderStreams = useLocalLoaderStreams();

  const body = new FormData();
  body.append('file', file);

  loaderStreams.updateIsLoading.push({ isUploading: true });

  fetch(URL, {
    headers: {
      Auth: token || '',
      // 'Content-type': 'multipart/form-data',
    },
    body,
    method: 'POST',
  })
    .then(response => {
      const isNotAuth = response.status === ResponseStatus.NOT_AUTH_STATUS;

      if (isNotAuth) {
        document.dispatchEvent(new CustomEvent(AppEvents.NOT_AUTH));
      }

      if (response.status !== 200) {
        throw new Error(response.statusText);
      }

      return response.text();
    })
    .then(onSuccess)
    .catch(onFail)
    .finally(() => {
      loaderStreams.updateIsLoading.push({ isUploading: false });
    });

  return;
}

import { Icon } from 'components';

export function TenderKindConsumptionGridHelp(): JSX.Element {
  return (
    <>
      <h3>Помощь: Конкурсы, Шаблон сметы для заявок</h3>

      <p>
        При добавлении нового конкурса устанавливается связь с типом заявки, форма заявки в свою очередь содержит смету, которая
        формируется на основе соответствующего справочника: Виды расходов из бухгалтерии в конкурсе. В таблице автоматически
        отображаются все виды расходов для заданного типа заявок. Список можно отредактировать, удалив ненужные сметы или добавить
        новую.
      </p>
      <p>
        Для <strong>добавления</strong> новой сметы нажмите кнопку <Icon type="add" /> «Добавить», в открывшемся окне заполните
        поля: Наименование, Позиция (порядковый номер позиции в смете на форме заявки), Описание (при необходимости, поле не
        является обязательным для заполнения), Виды расходов их бухгалтерии и Входит в группу (учитывается в общей сумме для
        выбранного Вида расхода). Нажмите «Сохранить» для добавления новой сметы в таблицу.
      </p>

      <div className="warning">
        <span>Статьи размещаются в смете в порядке возрастания значений «Позиция».</span>
      </div>

      <p>
        Для <strong>редактирования</strong> сметы нажмите <Icon type="edit" /> «Редактировать», в открывшемся модальном окне
        внесите необходимые изменения и нажмите на кнопку «Сохранить».
      </p>
      <p>
        Для <strong>удаления</strong> сметы нажмите <Icon type="remove" /> «Удалить». Выбранная смета будет удалена из списка смет
        для конкурса (при этом не будет удалена из справочника «Виды расходов»).
      </p>

      <div className="warning">
        <span>
          Нельзя удалить смету, если она является суммирующей нескольких второстепенных смет. Для удаления такой сметы необходимо
          сначала удалить все второстепенные.
        </span>
      </div>
    </>
  );
}

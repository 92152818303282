import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

import { FilterOptions, State } from './makeUseCustomController';

type Args = {
  customState: State;
};

export const Filters = React.memo(({ customState: { filter, setFilter, isYear, setIsYear, year, setYear } }: Args) => {
  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: (
        <>
          <p>
            Фильтр <strong>Год</strong> - это вспомогательный для других фильтров.
          </p>
          <p>
            Фильтр <strong>Все</strong> - отображает весь список подразделений (актуальные и неактуальные); в столбцах по
            сотрудникам отображается по актуальным сотрудникам на текущую дату.
          </p>
          <p>
            <strong>Год + Все</strong> - отображает весь список подразделений, у которых были сотрудники в указанном году; в
            столбцах по сотрудникам отображается количество всех сотрудников, работающих в течении указанного года.
          </p>
          <p>
            Фильтр <strong>Актуальные</strong> - отображает список актуальных подразделений; в столбцах по сотрудникам
            отображается текущее значение по актуальным сотрудникам на текущую дату.
          </p>
          <p>
            Фильтр <strong>Актуальные + Год</strong> - отображает список подразделений и количество сотрудников по состоянию на 20
            декабря указанного года.
          </p>
        </>
      ),
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: FilterOptions.ALL },
        { label: 'Актуальные', value: FilterOptions.ACTUAL },
      ],
      value: filter,
      onChange: (e: FilterOptions) => {
        setFilter(e);
      },
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isYear',
      label: 'Год',
      checked: isYear,
      onChange: e => {
        setIsYear(e);
      },
    },
    {
      type: FilterComponentType.input,
      isYearMask: true,
      streamKey: 'year',
      value: year,
      onChange: e => {
        setYear(e);
      },
    },
  ]);
});

import React from 'react';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('divider');

type Props = {
  /** @property {boolean} isVertical - (optional) Vertical orient (horizontal by default) */
  isVertical?: boolean;
  /** @property {boolean} isFlex - (optional) If flexible by full size width offset */
  isFlex?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
};

/**
 * Visible divider component (simple line as hr)
 *
 * @param {boolean} isVertical - (optional) Direction (false by default)
 * @param {boolean} isFlex - (optional) Flexible to full space of parent (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns JSX
 */
export const Divider = React.memo(({ isVertical = false, isFlex = false, classMixin }: Props) => {
  return <hr className={b({ 'is-vertical': isVertical, 'is-flex': isFlex }).mix(classMixin)} />;
});

import { ProjectAssignmentMessage } from 'types/models/Project';
import { EMPTY_FILE } from 'utils/Constants';

export function getMockProjectAssignmentMessage(): ProjectAssignmentMessage {
  return {
    id: null,
    projectAssignment: null,
    message: null,
    file: EMPTY_FILE,
  };
}

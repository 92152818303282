import { Community, CommunityMember } from 'types/models';
import { showNotification } from 'features/Notifications';
import { CommunityType } from 'utils/Enums';

export function validateMember(
  community: Community | null,
  member: CommunityMember | null,
  communityTypes: CommunityType[] | null,
): boolean {
  const errorMessages: string[] = [];

  if (!member?.person?.id) errorMessages.push('Заполните поле "Персона"');
  if (!member?.role?.value) errorMessages.push('Заполните поле "Роль"');
  if (community !== null && community?.type?.value !== CommunityType.MAGAZINE_REDACTION && !member?.joinDate) {
    errorMessages.push('Заполните поле "Дата ввода в состав"');
  }
  if (communityTypes !== null && communityTypes.includes(CommunityType.SCIENTIFIC_COUNCIL) && !member?.cooperationType?.value) {
    errorMessages.push('Заполните поле "Предмет сотрудничества"');
  }
  if (errorMessages.length) {
    errorMessages.forEach(message => showNotification({ message, theme: 'danger' }));
    return false;
  }
  return true;
}
export function validate(community: Community | null) {
  const errorMessages: string[] = [];

  if (!community?.type) errorMessages.push('Заполните поле "Тип сообщества"');

  if (community?.type?.value !== CommunityType.MAGAZINE_REDACTION) {
    if (!community?.code) {
      errorMessages.push('Заполните поле "Полное название (краткое название)"');
    }
  } else {
    if (!community.magazine) {
      errorMessages.push('Заполните поле "Название журнала"');
    }
  }

  if (errorMessages.length) {
    errorMessages.forEach((message: string) => showNotification({ message, theme: 'danger' }));
    return false;
  }
  return true;
}

import * as XML from 'xml-js';

import { Event, Form } from 'types/models';

import {
  PublicationMobileRequest,
  PublicationMobileRequestAcceptPerson,
  PublicationProject,
  PublicationProjectAcceptPerson,
} from 'types/models/Form';

import { Settings } from 'types/models/common';
import { sortByPosition, sortByPositionXML } from 'utils/Helpers/sortByPosition';
import { EventParticipation } from 'types/models/Event';
import { convertMember, convertServerMemberAffilation } from './configurations/author/converters';
import {
  convertDocument,
  convertFile,
  convertIdItem,
  convertMaybeArray,
  getId,
  getLabel,
  getText,
} from './configurations/commonConverters';
import { convertServerDepartment, convertSourceMagazineRelease } from './configurations/publication/converters';
import { convertPaymentRequest } from './configurations/converters';
import { EMPTY_FILE } from 'utils/Constants';
import { convertCommunityMember } from './configurations/communityMember/converters';
import { convertServerEvent } from './configurations/event/converters';
import { convertRefElements } from './configurations/project/converters';

function convertMagazineTranslations(publication: any) {
  if (publication.Translations.Translation) {
    const converter = (translation: any) => {
      // eslint-disable-next-line no-unsafe-optional-chaining
      const { id } = translation?._attributes;
      const name = translation?.Name._text;
      const bibliographic = getText(translation.BibliographicRecord);
      const status = translation.Status?._attributes?.label || '';
      const edition = translation?.Publisher?.name?._text || '';
      return { id, name, edition, status, bibliographic };
    };
    const translation = publication.Translations.Translation;
    return Array.isArray(translation) ? translation.map(converter) : [converter(translation)];
  }
  return [];
}

function convertPrintArticle(publication: any): Form.PrintArticle | null {
  if (publication.PrintArticle) {
    return {
      id: publication.PrintArticle?._attributes.id,
      name: getText(publication.PrintArticle?.BibliographicRecord),
      status: getLabel(publication.PrintArticle?.Status),
    };
  }

  return null;
}

function convertServerCouncil(publication: any): Form.Council | null {
  if (publication.Council) {
    const name = getText(publication.Council?.Name);
    const code = getText(publication.Council?.Code);

    return {
      id: publication.Council?._attributes.id,
      name: [name, code ? (name ? `(${code})` : code) : ''].filter(Boolean).join(' '),
    };
  }
  return null;
}

function convertServerAffiliation(publication: any): Form.Affiliation | null {
  if (publication.Affiliation) {
    const parsedAffiliation = convertServerMemberAffilation(publication, {} as Settings);
    return {
      id: publication.Affiliation?._attributes.id,
      organization: {
        kind: parsedAffiliation.type,
        id: parsedAffiliation.id,
        name: parsedAffiliation.label,
      },
    };
  }
  return null;
}

function convertMagazine(Magazine: any) {
  if (Magazine) {
    const converter = (magazine: any) => {
      const { id } = magazine?._attributes || '';
      const { label } = magazine.Status?._attributes || '';
      const magazineParameter = magazine.Magazine ? magazine.Magazine : magazine;
      const name = magazineParameter.Name?._text || '';
      const foreignName = getText(magazine.ForeignName);

      return { id, name, status: label, foreignName };
    };
    return converter(Magazine);
  }
  return null;
}

function convertCompilation(Compilation: any) {
  if (Compilation) {
    const converter = (compilation: any) => {
      const { id } = compilation?._attributes || '';
      const { label } = compilation?.Status?._attributes || '';
      const name = compilation.Name ? compilation.Name._text : compilation.Part?._text || '';
      const bibliographic = compilation.BibliographicRecord ? compilation.BibliographicRecord._text : '';
      return { id, name, status: label, bibliographic };
    };

    return converter(Compilation);
  }
  return null;
}

function convertPublicationOther(Publication: any) {
  if (Publication) {
    const converter = (publication: any) => {
      const { id } = publication?._attributes || '';
      const { label } = publication?.Status?._attributes || '';
      const name = publication.Name ? publication.Name._text : '';
      const bibliographic = getText(publication.BibliographicRecord);
      return { id, name, status: label, bibliographic };
    };

    return converter(Publication);
  }
  return null;
}

function convertMonograph(Monograph: any) {
  if (Monograph) {
    const converter = (monograph: any) => {
      const { id } = monograph?._attributes || '';
      const { label } = monograph.Status?._attributes || '';
      const name = getText(monograph.Name);
      const bibliographic = getText(monograph.BibliographicRecord);
      return { id, name, status: label, bibliographic };
    };

    return converter(Monograph);
  }
  return null;
}

function convertParticipations(publication: any): Event.EventParticipation[] {
  const participationsXMLArray = publication.Participations?.Participation;
  const convertParticipation = (participation: any): EventParticipation => {
    return {
      id: getId(participation),
      participationStatus: { label: participation.Status?._attributes?.label || '', value: getText(participation.Status) },
      eventFullName: getText(participation.EventFullName),
      reportType: participation.ReportType?._attributes?.label || '',
      reporter: getText(participation.Reporter),
      reportDate: getText(participation.reportDate),
      reportTheme: getText(participation.ReportTheme),
    };
  };

  return participationsXMLArray
    ? Array.isArray(participationsXMLArray)
      ? participationsXMLArray.map(convertParticipation)
      : [convertParticipation(participationsXMLArray)]
    : [];
}

export function convertMobileRequests(publication: any) {
  const requestXML = publication.MobileRequests?.MobileRequest;
  const converter = (request: any): PublicationMobileRequest => {
    const convertedRequest: PublicationMobileRequest = {
      id: getId(request),
      mobileRequest: { id: getId(request.MobileRequest), label: getText(request.MobileRequest.Label) },
      accepted: getText(request.Accepted),
      acceptedBy: request.AcceptedBy ? convertPublicationMobileRequestAcceptPerson(request.AcceptedBy) : null,
    };
    return convertedRequest;
  };

  if (publication.MobileRequests?.MobileRequest) {
    return Array.isArray(requestXML) ? requestXML.map(converter) : [converter(requestXML)];
  }
  return [];
}

const convertPublicationMobileRequestAcceptPerson = (person: any): PublicationMobileRequestAcceptPerson => ({
  id: getId(person),
  fullName: getText(person.FullName),
});

function convertMagazineOriginal(publication: any) {
  if (publication.Original) {
    return {
      id: publication.Original?._attributes?.id || '',
      name: publication.Original.Name?._text || '',
      bibliographic: publication.Original.BibliographicRecord?._text || '',
      status: publication.Status?._attributes?.label || '',
      edition: publication.Original.Publisher?.name?._text || '',
    };
  }
  return null;
}

function convertCitationSystems(publication: any) {
  if (!publication.CitationSystems || !publication.CitationSystems.CitationSystem) {
    return [];
  }

  const converter = (citationSystem: any): Form.CitationSystem => ({
    publicationCitationSystemId: citationSystem?._attributes.id,
    id: citationSystem.CitationSystem?._attributes.id,
    label: citationSystem.CitationSystem?._attributes.label,
    thematicCategories: convertThematicCategories(citationSystem),
  });
  const system = publication.CitationSystems.CitationSystem;

  return Array.isArray(system) ? system.map(converter) : [converter(system)];
}

function convertThematicCategories(citationSystem: any) {
  if (citationSystem.ThematicCategories.ThematicCategory) {
    const category = citationSystem.ThematicCategories.ThematicCategory;

    const catergoryConverter = (x: any) => ({
      id: x.ThematicCategory?._attributes.id,
      label: x.ThematicCategory?._attributes.label,
    });

    return Array.isArray(category) ? category.map(catergoryConverter) : [catergoryConverter(category)];
  }
  return [];
}

export function convertPublication(publication: any, settings: Settings): Form.Publication {
  const type = publication.Type._text;
  const isPreprint = getText(publication.IsPreprint) === 'true';
  const isLocal = getText(publication.IsLocal) === 'true';
  const isAllowEdit = getText(publication.AllowEdit) === 'true';

  const status: Form.DataStatusInfo = {
    typeLabel: publication.Type?._attributes.label || '',
    text: publication.Status?._attributes.label || '',
    name: getText(publication.Name),
    id: publication?._attributes?.id || null,
    status: getText(publication.Status),
    createdBy: getText(publication.CreatedBy.User),
    createdDate: getText(publication.CreatedDate),
    modifiedBy: getText(publication.StatusModifiedBy),
    modifiedDate: getText(publication.StatusModifiedDate),
    libraryId: getText(publication.LibraryId),
    libraryUrl: getText(publication.LibraryUrl),
    sourceEvents: getText(publication.SourceEvents),
    incentivePaymentRequest: getText(publication.IncentivePaymentRequest),
    divisionId: publication.CreatedBy?.Department?.code._text || '',
  };

  const fields: Form.DataStatusInfo = {
    isElectronic: getText(publication.IsElectronic),
    electronicType: getText(publication.ElectronicType),
    electronicTypeLabel: publication.ElectronicType?._attributes?.label || '',
    bibliographicRecord: getText(publication.BibliographicRecord),
    name: getText(publication.Name),
    shortName: getText(publication.ShortName),
    amount: getText(publication.IssuesByYear),
    isOriginal: publication.Translations ? 'true' : '',
    languages: getText(publication.TextLanguages),
    originalLanguageId: publication.OriginalLanguage?._attributes?.id || '',
    originalLanguageLabel: publication.OriginalLanguage?._attributes.label || '',
    ISSN: getText(publication.Issn),
    EISSN: getText(publication.EIssn),
    url: getText(publication.Url),
    seriesTitle: getText(publication.SeriesTitle),
    doi: getText(publication.Doi),
    libraryLink: getText(publication.LibraryLink),
    tirage: getText(publication.Tirage),
    pageCount: getText(publication.PageCount),
    startPage: getText(publication.StartPage),
    endPage: getText(publication.EndPage),
    printPageCount: getText(publication.PrintPageCount),
    year: getText(publication.Year),
    month: getText(publication.Month),
    day: getText(publication.Day),
    volume: getText(publication.Volume),
    articleNumber: getText(publication.ArticleNumber),
    chapterNumber: getText(publication.ChapterNumber),
    issue: getText(publication.Issue),
    address: getText(publication.Address),
    defencePlace: getText(publication.DefencePlace),
    ISBN: getText(publication.Isbn),
    EISBN: getText(publication.EIsbn),
    udk: getText(publication.Udk),
    annotation: getText(publication.Annotation),
    authorsKeyWords: getText(publication.AuthorsKeyWords),
    authorsCount: getText(publication.AuthorsCount),
    urlDate: getText(publication.UrlDate),
    fractionalCount: getText(publication.FractionalCount),
    electronicMediaSource: getText(publication.ElectronicMediaSource),
    electronicSystemRequirements: getText(publication.ElectronicSystemRequirements),
    volumeNumber: getText(publication.VolumeNumber),
    republishData: getText(publication.RepublishData),
    defenceDate: getText(publication.DefenceDate),
    councilCipher: publication.CouncilCipher ? publication.CouncilCipher._text : '',
    typeEditionText: publication.TypeEdition?._attributes.label || '',
    typeEditionCode: getText(publication.TypeEdition),
    categoryEditionLabel: publication.CategoryEdition?._attributes.label || '',
    categoryEditionId: publication.CategoryEdition?._attributes.id || '',
    publisherId: publication.Publisher?._attributes.id || '',
    publisherLabel: publication.Publisher?._attributes.label || '',
    publisherForeignName: publication.Publisher ? publication.Publisher.foreignName?._text : '',
    sourceTypeId: publication.SourceType?._attributes.id || '',
    sourceTypeLabel: publication.SourceType?._attributes.label || '',
    textLanguageId: publication.TextLanguage?._attributes.id || '',
    textLanguageLabel: publication.TextLanguage?._attributes.label || '',
    grntiId: publication.Grnti?._attributes.id || '',
    grntiLabel: publication.Grnti?._attributes.label || '',
    studyId: publication.Study?._attributes.id || '',
    studyLabel: publication.Study?._attributes.label || '',
    grifId: publication.Grif?._attributes.id || '',
    grifLabel: publication.Grif?._attributes.label || '',
    vakSpecialityId: publication.VakSpeciality?._attributes.id || '',
    vakSpecialityLabel: publication.VakSpeciality?._attributes.label || '',
    degreeId: publication.Degree?._attributes.id || '',
    degreeLabel: publication.Degree?._attributes.label || '',
    foreignName: getText(publication.ForeignName),
    foreignAnnotation: getText(publication.ForeignAnnotation),
    foreignAuthorsKeyWords: getText(publication.ForeignAuthorsKeyWords),
    foreignAuthors: getText(publication.ForeignAuthors),
    isVak: publication.IsVak?._text || '',
    publicationDate: getText(publication.PublicationDate),
    note: getText(publication.Note),
  };

  const serverMembers = publication.Members.Member;
  const serverCommunityMembers = publication.CommunityMembers?.CommunityMember;
  const serverPaymentRequest = publication.PaymentRequest;
  const serverMonographPaymentRequest = publication.MonographPaymentRequests?.MonographPaymentRequest;

  const serverDocuments = publication.Documents?.Document || [];
  const serverForeignDocuments = publication.ForeignDocuments?.Document || [];
  const members = sortByPosition(convertMaybeArray(serverMembers, i => convertMember(i, settings)));
  const communityMembers = convertMaybeArray(serverCommunityMembers, i => convertCommunityMember(i, settings));
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));
  const localDocuments = convertMaybeArray(serverDocuments, i => convertDocument('local', i));
  const documents = [...foreignDocuments, ...localDocuments];

  const original = convertMagazineOriginal(publication);
  const translations = convertMagazineTranslations(publication);
  const magazine = convertMagazine(publication.Magazine);
  const compilation = convertCompilation(publication.Compilation);
  const publicationOther = convertPublicationOther(publication.PublicationOther);
  const magazineRelease = convertSourceMagazineRelease(publication.MagazineRelease);
  const citationSystems = convertCitationSystems(publication);
  const file = convertFile(publication.File);
  const events = convertMaybeArray(publication.Events?.Event || [], e => convertServerEvent(e, settings));
  const participations = convertParticipations(publication);
  const projects = convertMaybeArray(publication.Projects?.Project, convertPublicationProject);
  const mobileRequests = convertMobileRequests(publication);
  const monograph = convertMonograph(publication.Monograph);
  const departments = convertMaybeArray(publication.Departments.Department, convertServerDepartment);
  const paymentRequest = serverPaymentRequest ? convertMaybeArray(serverPaymentRequest, convertPaymentRequest) : [];
  const printArticle = convertPrintArticle(publication);
  const council = convertServerCouncil(publication);
  const monographPaymentRequests = convertMaybeArray(serverMonographPaymentRequest, convertPaymentRequest);
  const vaks = convertRefElements(publication.Vaks?.Vak ?? []);
  const affiliations = convertMaybeArray(sortByPositionXML(publication.Affiliations?.Affiliation || []), a =>
    convertServerAffiliation(a),
  ).filter(Boolean) as Form.Affiliation[];

  return {
    isAllowEdit,
    council,
    isLocal,
    isPreprint,
    type,
    departments,
    status,
    fields,
    magazine,
    magazineRelease,
    success: true,
    message: '',
    translations,
    events,
    participations,
    projects,
    members,
    communityMembers,
    documents,
    original,
    citationSystems,
    file,
    mobileRequests,
    compilation,
    monograph,
    publicationOther,
    paymentRequest,
    printArticle,
    monographPaymentRequests,
    affiliations,
    vaks,
  };
}

const convertPublicationProject = (project: any): PublicationProject => ({
  id: getId(project),
  project: convertIdItem(project.Project),
  accepted: getText(project.Accepted),
  acceptedBy: project.AcceptedBy ? convertPublicationProjectAcceptPerson(project.AcceptedBy) : null,
});

const convertPublicationProjectAcceptPerson = (person: any): PublicationProjectAcceptPerson => ({
  id: getId(person),
  fullName: getText(person.FullName),
});

export function convertFormResponseXMLElementToOutput(response: XML.ElementCompact, settings: Settings): Form.Publication {
  const success = response.Response?._attributes.success === 'true';
  if (success) {
    if (response.Response.Id) {
      const newPublicationId = response.Response.Id._text;

      return {
        isAllowEdit: false,
        council: null,
        isLocal: false,
        isPreprint: false,
        departments: [],
        status: { id: newPublicationId },
        fields: {},
        magazine: null,
        magazineRelease: null,
        success,
        message: '',
        translations: [],
        events: [],
        participations: [],
        projects: [],
        mobileRequests: [],
        citationSystems: [],
        original: null,
        members: [],
        communityMembers: [],
        file: EMPTY_FILE,
        compilation: null,
        publicationOther: null,
        documents: [],
        monograph: null,
        printArticle: null,
        monographPaymentRequests: [],
        affiliations: [],
        vaks: [],
      };
    }

    const publication = response.Response.Publication;
    if (publication) {
      return convertPublication(publication, settings);
    }
  }

  const message = response.Response?._attributes.errorMessage || response.Response?._attributes.serverErrorMessage || '';

  return {
    isAllowEdit: false,
    council: null,
    isLocal: false,
    printArticle: null,
    isPreprint: false,
    departments: [],
    status: {},
    fields: {},
    magazine: null,
    magazineRelease: null,
    success,
    message,
    translations: [],
    events: [],
    participations: [],
    projects: [],
    mobileRequests: [],
    citationSystems: [],
    original: null,
    members: [],
    communityMembers: [],
    documents: [],
    file: EMPTY_FILE,
    compilation: null,
    monograph: null,
    publicationOther: null,
    monographPaymentRequests: [],
    affiliations: [],
    vaks: [],
  };
}

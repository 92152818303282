import { useCallback, useEffect, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Item } from 'types/models/common';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import {
  CopyFromNirRequest,
  CopyFromProgramRequest,
  NirRequestCopyOptions,
  ProgramRequestCopyOptions,
} from 'features/Form/looks/project/ProjectForm/types';
import { showNotification } from 'features/Notifications';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';

type Props = {
  disabled: boolean;
  copyFromNirRequest({ id, copyOptions }: CopyFromNirRequest): void;
  copyFromProgramRequest({ id, copyOptions }: CopyFromProgramRequest): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
  project: Project.Project | null;
  saveProject(isSaveLoad?: boolean): void;
};

export function useController(props: Props) {
  const { disabled, copyFromNirRequest, copyFromProgramRequest, makeChangeHandler, project, saveProject } = props;

  const [isNirRequestOptionsPopupOpen, setIsNirRequestOptionsPopupOpen] = useState<boolean>(false);
  const [isProgramRequestOptionsPopupOpen, setIsProgramRequestOptionsPopupOpen] = useState<boolean>(false);

  const [nirRequestItem, setNirRequestItem] = useState<Item | null>(null);
  const [programRequestItem, setProgramRequestItem] = useState<Item | null>(null);

  const [nirRequestCopyOptions, setNirRequestCopyOptions] = useState<NirRequestCopyOptions>({
    isProject: false,
    isStage: false,
    isIndicators: false,
    isPerformers: false,
  });
  const [programRequestCopyOptions, setProgramRequestCopyOptions] = useState<ProgramRequestCopyOptions>({
    isProject: false,
    isClassifiers: false,
    isPerformers: false,
  });

  const confirmNirRequestOptions = useCallback(() => {
    if (
      !nirRequestCopyOptions.isProject &&
      !nirRequestCopyOptions.isStage &&
      !nirRequestCopyOptions.isIndicators &&
      !nirRequestCopyOptions.isPerformers
    ) {
      showNotification({ message: 'Выберите хотя бы одну опцию для копирования', theme: 'danger' });
      return;
    }

    setIsNirRequestOptionsPopupOpen(false);

    if (nirRequestItem) copyFromNirRequest({ id: nirRequestItem.value, copyOptions: nirRequestCopyOptions });
  }, [copyFromNirRequest, nirRequestCopyOptions, nirRequestItem]);

  const confirmProgramRequestOptions = useCallback(() => {
    if (
      !programRequestCopyOptions.isProject &&
      !programRequestCopyOptions.isClassifiers &&
      !programRequestCopyOptions.isPerformers
    ) {
      showNotification({ message: 'Выберите хотя бы одну опцию для копирования', theme: 'danger' });
      return;
    }

    setIsProgramRequestOptionsPopupOpen(false);

    if (programRequestItem) copyFromProgramRequest({ id: programRequestItem.value, copyOptions: programRequestCopyOptions });
  }, [copyFromProgramRequest, programRequestCopyOptions, programRequestItem]);

  const nirRequestExtraButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'document', mode: 'arrowRight' },
        title: 'Скопировать данные из выбранной заявки в проект',
        isDisabled: !project?.nirRequest || disabled,
        onClick: () => {
          setNirRequestItem(project?.nirRequest as Item<string>);
          setIsNirRequestOptionsPopupOpen(true);
        },
      },
    ],
    [disabled, project?.nirRequest],
  );

  const programRequestExtraButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'document', mode: 'arrowRight' },
        title: 'Скопировать данные из выбранной заявки в проект',
        isDisabled: !project?.programRequest || disabled,
        onClick: () => {
          setProgramRequestItem(project?.programRequest as Item<string>);
          setIsProgramRequestOptionsPopupOpen(true);
        },
      },
    ],
    [disabled, project?.programRequest],
  );

  const setProgramRequest = useCallback(
    (programRequest: Item<string> | null) => {
      makeChangeHandler('programRequest')(programRequest);
    },
    [makeChangeHandler],
  );

  const setSecurityDocumentContract = useCallback(
    (securityDocumentContract: SecurityDocumentContract | null) => {
      makeChangeHandler('securityDocumentContract')(securityDocumentContract);
    },
    [makeChangeHandler],
  );

  const setNirRequest = useCallback(
    (nir: Item<string> | null) => {
      makeChangeHandler('nirRequest')(nir);
    },
    [makeChangeHandler],
  );

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [],
  );

  useEffect(() => {
    if (!project?.id) {
      saveProject(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    nirRequestExtraButtons,
    programRequestExtraButtons,
    isNirRequestOptionsPopupOpen,
    setIsNirRequestOptionsPopupOpen,
    confirmNirRequestOptions,
    setProgramRequest,
    setSecurityDocumentContract,
    setNirRequest,
    nirRequestCopyOptions,
    setNirRequestCopyOptions,
    programRequestCopyOptions,
    setProgramRequestCopyOptions,
    isProgramRequestOptionsPopupOpen,
    setIsProgramRequestOptionsPopupOpen,
    confirmProgramRequestOptions,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}

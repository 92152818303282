import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { Modal, FormComponent, Select } from 'components';

import { DataGrid } from 'features/Table';
// eslint-disable-next-line max-len
import { GetDashboardChartExpositionList } from 'features/Table/specifications/GetDashboardChartExpositionList';
// eslint-disable-next-line max-len
import { GetDashboardChartExpositionParticipationList } from 'features/Table/specifications/GetDashboardChartExpositionParticipationList';
// eslint-disable-next-line max-len
import { GetDashboardChartExpositionParticipationAwardList } from 'features/Table/specifications/GetDashboardChartExpositionParticipationAwardList';
import { COLORS, COLORS_BORDERS } from '../сolors';
import { Item } from 'types/models/common';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function ExpositionParticipation() {
  const {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    categories,
    chartData,
    seriesClickHandler,
    actualExpositionType,
    activePeriodFilter,
    actualYear,
    EXP,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { datasetIndex: index, raw: value } = context;
      return ` ${chartData[index].name}: ${value} шт.`;
    };

    const data = {
      labels: categories,
      datasets: chartData.map((x, index) => ({
        label: x.name,
        data: x.data,
        backgroundColor: COLORS[index],
        borderColor: COLORS_BORDERS[index],
        borderWidth: 2,
      })),
    };

    return (
      <Bar
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Год мероприятия',
              },
            },
            y: {
              beginAtZero: true,
              min: 0,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
        onClick={(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
          const element = getElementAtEvent(chartRef.current!, event)[0];
          if (element) {
            seriesClickHandler(chartData[element.datasetIndex], categories[element.index]);
          }
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Участие в выставках</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Тип мероприятия" hasLeftAlign>
              <Select
                value={activeFilter}
                options={periodOpts.current}
                onChange={(option: Item | null) => handleChangeActiveFilter(option)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '260px' }}>
          {Chart()}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={getModalTitle}
        size={['extralarge', 'medium-height']}
      >
        <>
          {actualExpositionType === EXP.EXP && (
            <DataGrid
              specification={GetDashboardChartExpositionList({
                expositionType: activePeriodFilter,
                year: actualYear,
              })}
            />
          )}
          {actualExpositionType === EXP.EXP_PART && (
            <DataGrid
              specification={GetDashboardChartExpositionParticipationList({
                expositionType: activePeriodFilter,
                year: actualYear,
              })}
            />
          )}
          {actualExpositionType === EXP.EXP_AWARD_TYPE && (
            <DataGrid
              specification={GetDashboardChartExpositionParticipationAwardList({
                expositionType: activePeriodFilter,
                year: actualYear,
              })}
            />
          )}
        </>
      </Modal>
    </>
  );
}

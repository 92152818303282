import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { ProjectType } from 'utils/Enums';
import { Color } from 'constants/colors';

type Props = {
  titleDetails?: string;
  projectId?: string | null;
  startProjectDate?: string;
  endProjectDate?: string;
  reloadProject?(): void;
  isProjectChanged?: boolean;
  projectType: ProjectType;
  isDisabled?: boolean;
};

export function GetProjectScientistList(args: Props): Table.Specification<State> {
  return {
    apiID: 'GetProjectScientistList',
    requestData: args,
    header: {
      firstLevel: {
        title: `Участники научного коллектива ${args.titleDetails || ''}`,
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({
      projectId: args.projectId || '-1',
      startProjectDate: args.startProjectDate || '',
      endProjectDate: args.endProjectDate || '',
      reloadProject: args.reloadProject || (() => {}),
      isProjectChanged: args.isProjectChanged,
      projectType: args.projectType,
      isDisabled: args.isDisabled || false,
    }),
    highlightRow: row => (row.IsCurrent === 'false' ? Color.danger : ''),
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isPlanLessFact',
      label: 'Факт превышает план',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isPlanMoreFact',
      label: 'План превышает факт',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isActiveCodes',
      label: 'Активные шифры',
      checked: true,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isWithoutCalendarPlan',
      label: 'Шифры без календарных планов',
      checked: false,
    },
  ]);
});

import React, { memo, useCallback, useMemo } from 'react';

import {
  Checkbox,
  ListEdit,
  Column,
  SectionTitle,
  FormComponent,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  Select,
  SelectMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { Item } from 'types/models/common';
import { GetOriginalsListSpecification, GetTranslatesListSpecification } from 'features/Table/specifications';

type Props = {
  workMode: T.WorkMode;
  formFields: F.Fields;
  publicationOriginal: F.Original | null;
  changeOriginal: (original: F.Original | null) => void;
  changeTranslations: (translations: F.Translation[] | null) => void;
  publicationTranslates: F.Translation[];
  EditionSettings: T.EnumValue[];
  relatedTableState: T.State | undefined;
};

const AboutMagazine = ({
  workMode,
  formFields,
  publicationOriginal,
  changeOriginal,
  changeTranslations,
  publicationTranslates,
  EditionSettings,
  relatedTableState,
}: Props) => {
  const translatesListTableConfig = GetTranslatesListSpecification({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
    },
  });

  const columns = useMemo<Column<F.Translation>[]>(() => [{ label: '', formatValue: row => row.name }], []);
  const modalTableRowConverter = useCallback<(row: T.Entry) => F.Translation>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const modalTableRowToOriginalConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const originalsListTableConfig = GetOriginalsListSpecification({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
    },
  });
  return (
    <>
      <SectionTitle title="Сведения о журнале" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.fullName.title} isRequired>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.fullName.value}
            onChange={formFields.fullName.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.foreignName.title}>
          <TextInput
            value={formFields.foreignName.value}
            onChange={formFields.foreignName.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <FormComponent.Line hasFreeFormat>
            <Checkbox
              label={formFields.isOriginal.title}
              checked={!!formFields.isOriginal.value}
              onChange={formFields.isOriginal.onChange}
              isDisabled={workMode === 'viewMode'}
            />
            <Checkbox
              label={formFields.isTranslate.title}
              checked={!!formFields.isTranslate.value}
              onChange={formFields.isTranslate.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
        </FormComponent.Field>
      </FormComponent.Line>

      {Boolean(formFields.isTranslate.value) && (
        <FormComponent.Line>
          <FormComponent.Field label="Журнал-оригинал" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Справочник "Журналы"',
                table: {
                  specification: originalsListTableConfig,
                  onSelect: (row: T.Entry | null) => changeOriginal(row ? modalTableRowToOriginalConventer(row) : null),
                },
              }}
              value={publicationOriginal?.name}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {Boolean(formFields.isOriginal.value) && (
        <FormComponent.Line>
          <FormComponent.Field label="">
            <ListEdit
              header={{ title: 'Журналы-переводы', isRequired: true }}
              rows={publicationTranslates}
              onChange={changeTranslations}
              toolbar={['add', 'edit', 'delete']}
              columns={columns}
              withoutHead
              specification={{
                mode: 'relationTableModal',
                modalTableRowConverter,
                relationTableModalTitle: 'Справочник "Журналы"',
                modalTableSpecification: translatesListTableConfig,
              }}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label={formFields.ISSN.title}>
          <TextInput
            mode={TextInputMode.ISSN}
            value={formFields.ISSN.value}
            onChange={formFields.ISSN.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.ISSNEl.title}>
          <TextInput
            mode={TextInputMode.ISSN}
            value={formFields.ISSNEl.value}
            onChange={formFields.ISSNEl.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label={formFields.textLanguages.title}>
          <TextInput
            value={formFields.textLanguages.value}
            onChange={formFields.textLanguages.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.amount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.amount.value}
            onChange={formFields.amount.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Идентификаторы журнала" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.site.title}>
          <TextInput
            mode={TextInputMode.URL}
            value={formFields.site.value}
            onChange={formFields.site.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.DOI.title} tooltip={formFields.DOI.tooltipText}>
          <TextInput
            mode={TextInputMode.DOI}
            value={formFields.DOI.value}
            onChange={formFields.DOI.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.connection.title}>
          <TextInput
            value={formFields.connection.value}
            onChange={formFields.connection.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <SectionTitle title="Сведения об издании" />
      <FormComponent.Line>
        <FormComponent.Field label="Вид издания" isRequired={formFields.editionType.required}>
          <Select
            value={formFields.editionType as Item}
            options={EditionSettings as Item[]}
            onChange={(option: Item) => formFields.editionType.onChange(option.label)}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.publisher.title}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.publisher.value}
            onChange={formFields.publisher.onChange}
            settings={{ name: 'RefPublisher', title: 'Издательство', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.note.title}>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: formFields.note.title }}
            value={formFields.note.value}
            onChange={formFields.note.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};

export const AboutMagazineComponent = memo(AboutMagazine);

import { useMemo } from 'react';

import { ButtonProps } from 'components';

import { Report } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController() {
  const { settings } = useAppDataContext();

  const reports = useMemo<Report[]>(() => [Reports.IncomingFundsNiokr, Reports.IncomingFundsTotalFact], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    isReportOpen,
    currentReport,
    onReportClose,
    settings,
  };
}

import React, { useCallback } from 'react';

import { Collapse, ReferenceItem } from 'components';

import { EditableMetadataField, State } from '../model';
import { Fields } from './Fields';
import { CustomersForms, ThematicCategories } from './CustomForms';
import { SecondLevelProps } from 'features/Table/types';
import { ControlPanel } from 'features/Form/views/ControlPanel';

export const Editor = ({ state, customState }: SecondLevelProps<State>) => {
  const { isOpenEditor, fields, mode, referenceMetadata, setFields, saveReference, closeEditor } = customState;

  const isRecordEditable = state.selectedRows[0]?.editable === 'true';

  const save = useCallback(() => {
    saveReference();
  }, [saveReference]);

  const handleChangeField = useCallback(
    (name: string, value: string | boolean | ReferenceItem | null) => {
      const index = fields.findIndex(x => x.name === name);

      if (index > -1) {
        setFields(prevFields => {
          const result = [...prevFields];
          result[index] = { ...prevFields[index], value } as EditableMetadataField;
          return result;
        });
      }
    },
    [setFields, fields],
  );

  const mapCustomForms: Record<string, () => JSX.Element> = {
    RefEnterpriseCustomer: () => (
      <CustomersForms fields={fields} handleChangeField={handleChangeField} mode={mode} isRecordEditable={isRecordEditable} />
    ),
    RefThematicCategory: () => (
      <ThematicCategories isViewMode={mode === 'view'} fields={fields} handleChangeField={handleChangeField} />
    ),
  };

  const renderForm = () => {
    const renderCustomForm = mapCustomForms[referenceMetadata?.name ?? ''];
    if (renderCustomForm) {
      return renderCustomForm();
    }
    return (
      <Fields
        mode={mode}
        fields={fields}
        onChange={handleChangeField}
        isRecordEditable={isRecordEditable}
        isOpenEditor={isOpenEditor}
      />
    );
  };

  return (
    <Collapse isExpanded={isOpenEditor}>
      <ControlPanel handleSave={save} handleCancel={closeEditor} viewMode={mode === 'view'} />
      <div>{renderForm()}</div>
    </Collapse>
  );
};

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Component as LeftPanelForSpecification } from './LeftPanelForThirdLevel/LeftPanelForSpecification';
import { makeUseCustomController } from './makeUseCustomController';

export function GetMagazineReleaseList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
): T.Specification {
  return {
    apiID: 'GetMagazineReleaseList',
    templatesTableDependencies,
    workMode,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({ workMode }),
      },
    },
  };
}

export function GetMagazineReleaseListSpecification({
  deps,
  filterMagazineReleaseId,
  eventId,
  hasSelectButton,
  isDisableEventSource = false,
  isPreprint = undefined,
}: {
  deps: T.Dependencies;
  hasSelectButton?: boolean;
  filterMagazineReleaseId?: string;
  eventId?: string;
  isDisableEventSource?: boolean;
  isPreprint?: boolean;
}): T.Specification {
  return {
    apiID: 'GetMagazineReleaseList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForSpecification,
        hasSelectButton,
      },
    },
    requestData: { filterMagazineReleaseId, isPreprint },
    useCustomController: makeUseCustomController({ eventId, isDisableEventSource }),
  };
}

import { Document, DocType, ReferenceItem } from 'types/models';
import { convertFile } from '.';

export function convertDocument(docType: DocType, document: any): Document {
  const attrs = document._attributes;

  return {
    docType,
    id: attrs.id,
    createdBy: attrs.createdBy || '',
    createdDate: attrs.createdDate || '',
    date: attrs.date || '',
    isShared: attrs.isShared === 'true',
    isCommonAward: attrs.isCommonAward === 'true',
    isInfo: attrs.isInfo === 'true',
    type: attrs.type || '',
    name: attrs.name || '',
    file: convertFile(document.File),
    endDate: attrs.endDate || '',
    startDate: attrs.startDate || '',
    isAutoProlongation: attrs.isAutoProlongation === 'true',
    isUnexpirable: attrs.isUnexpirable === 'true',
    number: attrs.number || '',
    partnerDocumentType: attrs.partnerDocumentType || '',
    responsible: attrs.responsible || '',
    responsibleJob: attrs.responsibleJob || '',
    responsibleFio: attrs.responsibleFio || '',
    responsibleJobAppointment: attrs.responsibleJobAppointment || '',
    documentType: attrs.documentType || '',
    projectDocumentType: {
      id: attrs['id:documentType'] || '',
      label: attrs.documentType || '',
      customFields: {
        code: attrs['code:documentType'] || '',
        note: attrs['note:documentType'] || '',
      },
    } as ReferenceItem,
    detail: attrs.detail || '',
    amount: attrs.amount,
    program: { id: attrs.programId, label: attrs.programLabel },
    isCreator: attrs.isCreator === 'true',
    note: attrs.note || '',
  };
}

import React, { useMemo } from 'react';

import { ButtonMode, Modal } from 'components';

import { useController } from './controller';
import { AppContext, AppDataContext } from './context';

type Props = {
  children: JSX.Element | JSX.Element[];
};

function AppData({ children }: Props) {
  const {
    scientificIndexes,
    enumMap,
    reports,
    userPermission,
    isHasPermissionToMain,
    isHasPermissionToAccount,
    currentPerson,
    userToken,
    userSystemDepartment,
    isUserNotAuthError,
    setUserToken,
    fetchInitialData,
    handleAcceptUserNotAuth,
    isHasNoAnyPermission,
    initialCountryCode,
    initialLanguageCode,
    initialOrganizationCode,
    settings,
  } = useController();

  const context = useMemo<AppContext>(
    () => ({
      scientificIndexes,
      enumMap,
      reports,
      userPermission,
      isHasPermissionToMain,
      isHasPermissionToAccount,
      currentPerson,
      userToken,
      setUserToken,
      fetchInitialData,
      userSystemDepartment,
      initialCountryCode,
      initialLanguageCode,
      initialOrganizationCode,
      settings,
    }),
    [
      scientificIndexes,
      enumMap,
      reports,
      userPermission,
      isHasPermissionToAccount,
      isHasPermissionToMain,
      currentPerson,
      userToken,
      setUserToken,
      fetchInitialData,
      userSystemDepartment,
      initialCountryCode,
      initialLanguageCode,
      initialOrganizationCode,
      settings,
    ],
  );

  return (
    <AppDataContext.Provider value={context}>
      <>
        {children}

        <Modal
          mode="warning"
          title="Аутентификация не пройдена"
          isOpen={isUserNotAuthError}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Вернуться на страницу авторизации',
              onClick: handleAcceptUserNotAuth,
            },
          ]}
          size="small"
        >
          <>Для продолжения работы войдите в систему, используя свой логин и пароль.</>
        </Modal>

        <Modal
          mode="warning"
          title="Доступ к системе запрещен"
          isOpen={isHasNoAnyPermission}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Вернуться на страницу авторизации',
              onClick: handleAcceptUserNotAuth,
            },
          ]}
          size="small"
        >
          <>
            У вас недостаточно прав для доступа к системе.
            <br />
            Обратитесь за помощью в тех поддержку
          </>
        </Modal>
      </>
    </AppDataContext.Provider>
  );
}

export { AppData };

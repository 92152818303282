import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function EstimateGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Таблица «Сметы» содержит список смет для шифров проектов и ключевые параметры сметы по состоянию на текущую дату.</p>
        <p>
          Фильтр <strong>Год</strong> – отображает список смет за указанный в фильтре год.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта.</td>
            </tr>
            <tr>
              <td>Код номера проекта</td>
              <td>Код номера проекта.</td>
            </tr>
            <tr>
              <td>Шифр проекта</td>
              <td>Шифр проекта</td>
            </tr>
            <tr>
              <td>Дата составления</td>
              <td>Дата составления сметы.</td>
            </tr>
            <tr>
              <td>Год сметы</td>
              <td>Год сметы.</td>
            </tr>
            <tr>
              <td>Финансирование, план</td>
              <td>Плановая сумма на год в финансовом плане шифра проекта (на форме шифра проекта, вкладка Финансовый план).</td>
            </tr>
            <tr>
              <td>Поступления, факт</td>
              <td>Общая сумма по приходным документам, связанным с шифром проекта; дата документа соответствует году сметы.</td>
            </tr>
            <tr>
              <td>Расходы, план</td>
              <td>Итоговая сумма, запланированных расходов в смете.</td>
            </tr>
            <tr>
              <td>Бюдж. обязательства</td>
              <td>Общая сумма по расходным документам, связанным с шифром проекта; дата документа соответствует году сметы.</td>
            </tr>
            <tr>
              <td>Накладные (план)</td>
              <td>
                Запланированная сумма накладных расходов, которая указана в смете для вида расходов с атрибутом «Передавать
                накладные».
              </td>
            </tr>
            <tr>
              <td>Накладные (факт)</td>
              <td>
                Сумма рассчитывается автоматически как доля накладных расходов от суммы приходных документов шифра при условии,
                что в смете указан вид расходов с атрибутом «Передавать накладные», а также шифр накладных расходов и
                запланированная сумма накладных расходов.
              </td>
            </tr>
            <tr>
              <td>План-БО-НР, факт</td>
              <td>Разница между плановой и фактической суммами.</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись со сметой. Открывает форму с данными о смете для просмотра.</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму создания сметы шифра.</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования сметы шифра.</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>Удаление сметы.</td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                Инструмент предназначен для построения отчёта по шаблону. При нажатии кнопки появится список доступных
                пользователю отчётов (при условии, что у пользователя есть право на группу отчётов).
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  hasSelectButton?: boolean;
  setKindConsumptionId?(kindConsumptionId: string): void;
};

export function GetKindConsumptionList({ hasSelectButton, setKindConsumptionId }: Props): T.Specification<State> {
  return {
    apiID: 'GetKindConsumptionList',
    header: {
      firstLevel: {
        title: 'Виды расходов из бухгалтерии',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        hasSelectButton: hasSelectButton,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ setKindConsumptionId }),
  };
}

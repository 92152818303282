import React, { useState } from 'react';
import { format } from 'date-fns';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { formatStr } from 'utils/Constants';
import { Item } from 'types/models/common';

export const Filters = React.memo(() => {
  const [period, setPeriod] = useState<string>('all');
  const [notInBalance, setNotInBalance] = useState<boolean>(false);
  const [purchaseYear, setPurchaseYear] = useState<boolean>(false);
  const [periodDate, setPeriodDate] = useState<string>('');

  const options: Item[] = [
    { label: 'Все', value: 'all' },
    { label: '< 1 г.', value: '1' },
    { label: '1-2 г.', value: '2' },
    { label: '3-5 г.', value: '3' },
    { label: '6-10 г.', value: '4' },
    { label: '11-20 г.', value: '5' },
    { label: '> 20 г.', value: '6' },
  ];

  return FiltersComponent([
    {
      type: FilterComponentType.year,
      streamKey: 'balanceYear',
      label: 'На балансе в',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'notOnBalance',
      label: 'Не на балансе',
      checked: notInBalance,
      onChange: e => {
        setNotInBalance(e);
        if (e) {
          setPeriod('all');
          setPeriodDate('');
        }
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'purchaseYear',
      label: 'Год закупки',
      checked: purchaseYear,
      onChange: e => {
        setPurchaseYear(e);
        if (e) {
          setPeriod('all');
          setPeriodDate('');
        }
      },
    },
    {
      type: FilterComponentType.text,
      value: 'Возраст',
    },
    {
      type: FilterComponentType.select,
      streamKey: 'period',
      options,
      value: options[0],
      onChange: (e: Item) => {
        setPeriod(e.value);
        if (e.value === 'all') {
          setPeriodDate('');
        } else {
          setNotInBalance(false);
          setPurchaseYear(false);
          if (!periodDate) setPeriodDate(format(new Date(), formatStr));
        }
      },
      classMixin: 'equip-filter-period',
    },
    {
      type: FilterComponentType.text,
      value: 'на дату',
    },
    {
      type: FilterComponentType.datepicker,
      streamKey: 'periodDate',
      value: periodDate,
      onChange: e => {
        if (!!e && period === 'all') {
          setPeriod('1');
          setNotInBalance(false);
          setPurchaseYear(false);
        }
        if (!e && period !== 'all') setPeriod('all');
        setPeriodDate(e);
      },
    },
  ]);
});

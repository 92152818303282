export type PerformersCopyInfo = {
  isLeaderChecked: boolean;
  isResponsiblePerformerChecked: boolean;
  restPerformers: number[];
  isRestPerformersChecked: boolean;
};

export enum PerformerCheckedRole {
  LEADER = 'LEADER',
  RESPONSIBLE_PERFORMER = 'RESPONSIBLE_PERFORMER',
  REST_PERFORMERS = 'REST_PERFORMERS',
}

export type KeyName = keyof Pick<
  PerformersCopyInfo,
  'isLeaderChecked' | 'isResponsiblePerformerChecked' | 'isRestPerformersChecked'
>;

export enum Period {
  quarter = 'quarter',
  halfYear = 'halfYear',
  year = 'year',
}

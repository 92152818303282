import { NotificationEventModule } from 'utils/Enums';

export type State = {
  module?: NotificationEventModule;
};

export function makeUseCustomController({ module }: State) {
  return function useCustomController(): State {
    return {
      module,
    };
  };
}

import React from 'react';
import * as Blocks from './blocks';

import { FormComponent, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetSystemUpdatesList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { QuestionAndAnswersList } from 'features/PersonCard/Tabs/QuestionsAndAnswers/Tabs';
import { useRouteNavigation } from 'utils/Common';

function Home() {
  const { userPermission } = useAppDataContext();

  const { url, changeUrl } = useRouteNavigation({
    prefix: ['home'],
    init: ['news'],
    defaultUrls: {
      charts: 'publications',
      questions_to_admin: 'frequent',
    },
  });

  return (
    <Tabs id="home" isStrip defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
      <Tab title="Новости" id="news" isVisible={isHasPermission(userPermission, Permits.GENERAL_NEWS_ACCESS)}>
        <Blocks.Notices isHome />
      </Tab>
      <Tab title="О конкурсах" id="tenders" isVisible={isHasPermission(userPermission, Permits.GENERAL_TENDERS_ACCESS)}>
        <Blocks.Tenders openTenderId={url[1] || ''} setOpenTenderId={id => changeUrl(1, id)} />
      </Tab>
      <Tab
        title="Открытые данные"
        id="charts"
        isVisible={isHasPermission(userPermission, [
          Permits.GENERAL_OPEN_DATA_PUBLICATIONS_ACCESS,
          Permits.GENERAL_OPEN_DATA_EVENTS_ACCESS,
          Permits.GENERAL_OPEN_DATA_PROJECTS_ACCESS,
          Permits.GENERAL_OPEN_DATA_SECURITY_DOCUMENTS_ACCESS,
        ])}
      >
        <Blocks.ScienceCharts defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
      </Tab>
      <Tab
        title="Часто задаваемые вопросы"
        id="questions"
        isVisible={isHasPermission(userPermission, Permits.GENERAL_QNA_ACCESS)}
      >
        <QuestionAndAnswersList />
      </Tab>
      <Tab
        title="Справочные материалы и ссылки"
        id="help"
        isVisible={isHasPermission(userPermission, Permits.GENERAL_STUFFS_ACCESS)}
      >
        <FormComponent.Wrapper>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <Blocks.HelpfulStuff />
            </FormComponent.Column>
            <FormComponent.Column>
              <Blocks.UsefulLinks />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </Tab>
      <Tab title="Обновления в системе" id="updates" isVisible={isHasPermission(userPermission, Permits.GENERAL_UPDATES_ACCESS)}>
        <DataGrid specification={GetSystemUpdatesList} />
      </Tab>
      <Tab
        title="Вопросы от пользователей администраторам"
        id="questions_to_admin"
        isVisible={isHasPermission(userPermission, [Permits.QNA_ADMINISTRATE, Permits.GENERAL_QNA_ADMIN_ACCESS])}
      >
        <Blocks.Questions defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
      </Tab>
    </Tabs>
  );
}

export const Component = React.memo(Home);

import React, { Dispatch, useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as BackendAPI from 'services/BackendAPI';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { validateMainFields } from './helpers';
import { getHashObject } from 'utils/Helpers';
import { redirectToHome } from 'features/AppData/helpers';

export type State = {
  localPerson: Person.ScientistPerson | null;
  setLocalPerson: Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
  closeForm(): void;
  submitForm(): void;
  setIsThereChanges: (val: boolean) => void;
  reloadPerson(): void;
  isLoadingPerson: boolean;
  isOpenWelcomeModal: boolean;
  onCloseWelcomeModal: (val: boolean) => void;
  logoutHandler: () => void;
  isOpenLogoutNotify: boolean;
  goOutBySaving: (val: boolean) => void;
  isReloadModeProcess: boolean;
  tabsId: string;
};

export type HonorType = 'degree' | 'rank' | 'academicRank';

export type EditableHonor = {
  type?: HonorType;
} & Honor;

export type Honor = Person.ScientistDegree | Person.ScientistRank | Person.ScientistAcademicRank;

type Arguments = {
  personId: string | null;
  onClose?(): void;
  isOpen: boolean;
};

export function useController({ personId, onClose, isOpen }: Arguments): State {
  const [localPerson, setLocalPerson] = useState<Person.ScientistPerson | null>(null);
  const [oldPerson, setOldPerson] = useState<Person.ScientistPerson | null>(null);
  const [isThereChanges, setIsThereChanges] = useState<boolean>(false);
  const [isOpenWelcomeModal, setIsOpenWelcomeModal] = useState<boolean>(false);
  const [isOpenLogoutNotify, setIsOpenLogoutNotify] = useState(false);
  const [isReloadModeProcess, seIsReloadModeProcess] = useState(false);

  const tabsId = 'newTabsContent';

  const { methods: SaveScientistAPI } = BackendAPI.useBackendAPI('SaveScientist');
  const { methods: savePersonalNotificationAPI } = BackendAPI.useBackendAPI('SavePersonalNotification');
  const { methods: loadScientistAPI, state } = BackendAPI.useBackendAPI('GetScientistData');
  const { methods: getFormSettings } = BackendAPI.useBackendAPI('GetFormSettings');
  const { methods: saveFormSettings } = BackendAPI.useBackendAPI('SaveFormSettings');
  const loadWelcomeWindow = useCallback(() => {
    getFormSettings.callAPI(
      { name: 'WelcomeWindow' },
      {
        onSuccessfullCall: ({ data }) => {
          setIsOpenWelcomeModal(!data.component.value);
        },
      },
    );
  }, [getFormSettings, setIsOpenWelcomeModal]);

  const loadPerson = useCallback(
    (id: string) => {
      loadScientistAPI.callAPI(
        { personId: id },
        {
          onSuccessfullCall: ({ data }) => {
            setLocalPerson(data);
            setOldPerson(data);
            loadWelcomeWindow();
          },
        },
      );
    },
    [loadWelcomeWindow, loadScientistAPI],
  );

  const reloadPerson = useCallback(() => {
    if (personId) {
      seIsReloadModeProcess(true);
      loadScientistAPI.callAPI(
        { personId },
        {
          onSuccessfullCall: ({ data }) => {
            setLocalPerson(data);
            setOldPerson(data);
            seIsReloadModeProcess(false);
          },
          onFailedCall: () => {
            seIsReloadModeProcess(false);
          },
        },
      );
    }
  }, [personId, loadScientistAPI, setOldPerson]);

  useEffect(() => {
    if (isOpen && personId !== null) {
      loadPerson(personId);
    }

    if (!isOpen && personId === null) {
      setLocalPerson(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, setLocalPerson, personId]);

  const closeForm = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const saveScientist = useCallback(() => {
    if (localPerson) {
      SaveScientistAPI.callAPI(
        { person: localPerson },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Персона успешно сохранена', theme: 'success' });
            reloadPerson();
            closeForm();
          },
        },
      );
    }
  }, [localPerson, SaveScientistAPI, reloadPerson, closeForm]);

  const saveNotificationSettings = useCallback(() => {
    if (!localPerson?.scientist) return;
    savePersonalNotificationAPI.callAPI(
      { notificationEmail: localPerson.scientist?.notificationEmail, disnotifications: localPerson.disnotifications },
      {
        onSuccessfullCall: () => {
          saveScientist();
        },
      },
    );
  }, [localPerson, savePersonalNotificationAPI, saveScientist]);

  const submitForm = useCallback(() => {
    if (!localPerson) {
      return;
    }

    const validateInfo = validateMainFields(localPerson);
    const isValidForm = validateInfo.every(x => x.isValid);
    if (!isValidForm) {
      validateInfo.forEach(({ isValid, invalidMessage }) => {
        if (!isValid) {
          setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
        }
      });
      return;
    }

    if (
      getHashObject([localPerson.scientist?.notificationEmail, localPerson.disnotifications]) !==
      getHashObject([oldPerson?.scientist?.notificationEmail, oldPerson?.disnotifications])
    ) {
      saveNotificationSettings();
    } else saveScientist();
  }, [localPerson, oldPerson, saveScientist, saveNotificationSettings]);

  const history = useHistory();

  const goOutBySaving = useCallback(
    (isNeedToSave: boolean) => {
      if (isNeedToSave) submitForm();
      redirectToHome(history);
    },
    [history, submitForm],
  );
  const logoutHandler = useCallback(() => {
    if (isThereChanges) {
      setIsOpenLogoutNotify(true);
    } else {
      goOutBySaving(false);
    }
  }, [isThereChanges, goOutBySaving]);

  const onCloseWelcomeModal = useCallback(
    (isNoMoreShow: boolean) => {
      if (isNoMoreShow) {
        saveFormSettings.callAPI(
          {
            name: 'WelcomeWindow',
            component: {
              name: 'hidden',
              value: true,
            },
          },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Персона успешно сохранена', theme: 'success' });
              closeForm();
            },
          },
        );
      }
      setIsOpenWelcomeModal(false);
    },
    [setIsOpenWelcomeModal, saveFormSettings, closeForm],
  );

  const isLoadingPerson = useMemo(() => state.kind === 'pending' && !isReloadModeProcess, [isReloadModeProcess, state]);

  return {
    localPerson,
    closeForm,
    setLocalPerson,
    submitForm,
    setIsThereChanges,
    reloadPerson,
    isLoadingPerson,
    isOpenWelcomeModal,
    onCloseWelcomeModal,
    logoutHandler,
    isOpenLogoutNotify,
    goOutBySaving,
    tabsId,
    isReloadModeProcess,
  };
}

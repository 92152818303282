import { SystemUpdate } from 'types/models';
import { getId, getText } from '../commonConverters';

export function convertSystemUpdate(systemUpdate: any): SystemUpdate {
  return {
    id: getId(systemUpdate),
    updateDate: getText(systemUpdate.UpdateDate),
    detail: getText(systemUpdate.Detail),
  };
}

import { ProjectPerformerOrder } from 'types/models/Project';

export function validate(performerOrder: ProjectPerformerOrder) {
  if (!performerOrder) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidType = !!performerOrder.type;
  const isValidNumber = Boolean(performerOrder.number);
  const isValidDate = Boolean(performerOrder.date);
  return [
    { isValid: isValidType, invalidMessage: 'Укажите "Тип приказа"' },
    { isValid: isValidNumber, invalidMessage: 'Укажите "Номер приказа"' },
    { isValid: isValidDate, invalidMessage: 'Укажите дату приказа' },
  ];
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { RequestPartner } from 'types/models/ProgramRequest';
import { useController } from './controller';

type Props = {
  partner: RequestPartner | null;
  onPartnerChange(row: RequestPartner): void;
};

function PartnerInputs({ partner, onPartnerChange }: Props) {
  const { handleFieldChange } = useController({
    partner,
    onPartnerChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Организация-партнёр" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={partner?.partner}
            onChange={(option: ReferenceItem | null) => handleFieldChange('partner')(option)}
            settings={{ name: 'RefEnterpriseCustomer', title: 'Список организаций-партнёров' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={partner?.note || ''}
            onChange={handleFieldChange('note')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { PartnerInputs };

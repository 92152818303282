import { Project } from 'types/models';

export function validateAct(act: Project.Act | null) {
  const errors = [];
  if (!act?.council?.id) {
    errors.push({
      isValid: false,
      invalidMessage: `Заполните "Научно-технический совет"`,
    });
  }
  if (!act?.number) {
    errors.push({
      isValid: false,
      invalidMessage: `Заполните "Номер"`,
    });
  }
  if (!act?.stage) {
    errors.push({
      isValid: false,
      invalidMessage: `Заполните "Этап"`,
    });
  }
  return errors;
}

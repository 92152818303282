import { Table } from 'types/models';

type RequestData = {
  enterpriseCustomerIds: string[];
  enterpriseIds: string[];
  scientistId: string;
};

export function GetPartnerAffilatedPersonPublicationList({
  enterpriseCustomerIds,
  enterpriseIds,
  scientistId,
}: RequestData): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetPartnerAffilatedPersonPublicationList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { enterpriseCustomerIds, enterpriseIds, scientistId },
  };
}

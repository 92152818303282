import { DataGrid } from 'features/Table';
import { GetPublicationFeedlineList } from 'features/Table/specifications/GetPublicationFeedlineList';
import { Table as T } from 'types/models';
import React from 'react';

type Props = {
  relatedTableState: T.State | undefined;
  workMode: T.WorkMode;
  publicationId?: string;
};

function Messages({ relatedTableState, workMode, publicationId }: Props) {
  return (
    <>
      {relatedTableState && (
        <DataGrid
          specification={GetPublicationFeedlineList(
            {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId: publicationId,
            },
            workMode,
          )}
        />
      )}
    </>
  );
}

export { Messages };

/*
 * Ищем управляющие юникод символы и символы, на хранение которых требуется более 3х байт,
 * т.к. БД не поддерживает более символы 3х байт
 * Диапазон \uD800\uDC00-\uDBFF\uDFFF - символы более 3х байт
 * \u0001-\u0008\u000B-\u000C\u000E-\u001F\u007F-\u0084\u0086-\u009F - управляющие символы
 */
function compare(source: string, isValidOnly: boolean) {
  return source
    .split('')
    .filter((sym: string) => {
      const code = sym.charCodeAt(0);

      const isValid =
        // \u0001-\u0008
        (1 <= code && code <= 8) ||
        // \u000B-\u000C
        (11 <= code && code <= 12) ||
        // \u000E-\u001F
        (14 <= code && code <= 31) ||
        // \u007F-\u0084
        (127 <= code && code <= 132) ||
        // \u0086-\u009F
        (134 <= code && code <= 159) ||
        // \uD800\uDC00-\uDBFF\uDFFF
        code <= 10000;

      return isValid === isValidOnly;
    })
    .join('');
}

/**
 * Get only valid symbols
 *
 * @param {string} source - String for search of symbols
 * @returns {string}
 */
export function getValid(source?: string) {
  return compare(source || '', true);
}

/**
 * Get only invalid symbols
 *
 * @param {string} source - String for search of symbols
 * @returns {string}
 */
export function getInvalid(source?: string) {
  return compare(source || '', false);
}

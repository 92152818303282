import { useCallback, useState } from 'react';

import { ReferenceItem } from 'components';

import { SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Member } from 'features/SelectPerson';

type Props = {
  member: SecurityDocumentMember | null;
  onMemberChange(row: SecurityDocumentMember | null): void;
  memberType: SecurityDocumentMemberTypes;
};

export function useController({ member, onMemberChange, memberType }: Props) {
  const [isOrg, setIsOrg] = useState<boolean>(!member?.person);

  const handlePersonChange = useCallback(
    (value: Member | null, id: string | null = '') => {
      onMemberChange(
        value?.person
          ? {
              ...member,
              id,
              contribution: member?.contribution || 0,
              type: { label: '', value: memberType },
              person: value?.person || null,
              name: value?.person?.fullName || '',
              job: value?.job || null,
              education: value?.education || null,
              degree: value?.degree || null,
              citizenship: value?.citizenship || null,
              rank: value?.rank || null,
              academicRank: value?.academicRank || null,
              historyLabel: '',
              contractNumber: member?.contractNumber || '',
              contractDate: member?.contractDate || '',
              contractPortion: member?.contractPortion || '',
              contractNote: member?.contractNote || '',
              enterprise: member?.enterprise || null,
            }
          : null,
      );
    },
    [member, onMemberChange, memberType],
  );

  const handleOrgChange = useCallback(
    (value: ReferenceItem | null) => {
      onMemberChange({
        ...member,
        enterprise: value,
        name: value?.label || '',
        type: { label: '', value: memberType },
      } as SecurityDocumentMember);
    },
    [member, memberType, onMemberChange],
  );
  const handleNumberChange = useCallback(
    (fieldName: keyof SecurityDocumentMember) => (value: number) => {
      onMemberChange({ ...member, [fieldName]: value } as SecurityDocumentMember);
    },
    [member, onMemberChange],
  );

  const handleFieldChange = useCallback(
    (fieldName: keyof SecurityDocumentMember) => (value: string | null) => {
      onMemberChange({ ...member, [fieldName]: value } as SecurityDocumentMember);
    },
    [member, onMemberChange],
  );

  const applicantOptions = [
    { label: 'Организация', value: 'ORG' },
    { label: 'Персона', value: 'PERSON' },
  ];

  const onRadioChange = useCallback(
    (nextIsOrg: boolean) => {
      handlePersonChange(null);
      setIsOrg(nextIsOrg);
    },
    [handlePersonChange],
  );

  return {
    isOrg,
    onRadioChange,
    handlePersonChange,
    handleNumberChange,
    handleFieldChange,
    applicantOptions,
    handleOrgChange,
  };
}

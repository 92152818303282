import React from 'react';

import { ButtonMode, FormComponent, ListEditTable, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function Toolbar({ onSubmitTable, tableState, customState }: Props) {
  const {
    buttons,
    isOpenConfirmDeleteModal,
    setIsOpenConfirmDeleteModal,
    confirmDelete,
    historyEntries,
    selectedRowIndex,
    setSelectedRowIndex,
    isOpenHistoryModal,
    setIsOpenHistoryModal,
  } = useController({ onSubmitTable, tableState, customState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDelete,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDeleteModal(false),
          },
        ]}
        size="small"
      >
        <>Удалить выбранный элемент?</>
      </Modal>

      <Modal title="История изменений" isOpen={isOpenHistoryModal} onClose={() => setIsOpenHistoryModal(false)} size="large">
        <FormComponent.Wrapper>
          <ListEditTable
            columns={[
              { label: 'Действие', formatValue: x => x.action },
              { label: 'Изменен', formatValue: x => x.changed },
              { label: 'Изменил', formatValue: x => x.changer },
            ]}
            rows={historyEntries}
            selectedRowIndex={selectedRowIndex}
            selectRow={setSelectedRowIndex}
          />
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export { Toolbar };

import { useMemo, useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { approverKindTitleMap, isNextPurchaseRequestStatus } from 'types/models/PurchaseRequest';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { PurchaseRequestApprovementApprover, PurchaseRequestContractKind, PurchaseRequestStatus } from 'utils/Enums';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Color } from 'constants/colors';

type Props = {
  tableState: Table.State;
  project?: Item | null;
};

const useController = ({ tableState, project }: Props) => {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<PurchaseRequestStatus | null>(null);
  const [approverKind, setApproverKind] = useState<PurchaseRequestApprovementApprover | null>(null);

  const reports = useMemo<Report[]>(() => {
    const serviceNoteList = {
      [PurchaseRequestContractKind.LESS_100_SERVICES]: Reports.ServiceNotePurchaseUpSomeServices,
      [PurchaseRequestContractKind.LESS_100_PRODUCTS]: Reports.ServiceNotePurchaseUpSomeProducts,
      [PurchaseRequestContractKind.MORE_100_SERVICES_AND_PRODUCTS]: Reports.ServiceNotePurchaseUpSomeProductsServices,
    };

    return [...(selectedRow ? [serviceNoteList[selectedRow['id:ContractKind'] as PurchaseRequestContractKind]] : [])];
  }, [selectedRow]);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const { methods: changePurchaseRequestStatus } = BackendAPI.useBackendAPI('ChangePurchaseRequestStatus');

  const { methods: addPurchaseRequestApprovement } = BackendAPI.useBackendAPI('AddPurchaseRequestApprovement');

  const handleMessageModalClose = useCallback(() => {
    tableStreams.reloadTable.push({});
    setIsOpenMessageModal(false);
  }, [tableStreams]);

  const onChangeStatusClick = useCallback(
    (status: PurchaseRequestStatus) => {
      setNewStatus(status);
      setIsOpenMessageModal(true);
    },
    [setNewStatus, setIsOpenMessageModal],
  );

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      if (!newStatus) return;
      changePurchaseRequestStatus.callAPI(
        { status: newStatus, message: message || '', ids: tableState.selectedRows.map(r => r.id) },
        {
          onSuccessfullCall: () => {
            setNewStatus(null);
            setIsOpenMessageModal(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [changePurchaseRequestStatus, tableStreams, newStatus, tableState, setNewStatus, setIsOpenMessageModal],
  );

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (selectedRow?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: selectedRow?.id,
        command: 'DeletePurchaseRequest',
        deletedItemPropName: 'PurchaseRequest',
      });
    }
  }, [selectedRow?.id, tableStreams.deleteRow, setIsDeleteWarningOpen]);

  const isEditPermitted = useMemo(
    () =>
      selectedRow &&
      isHasPermission(userPermission, Permits[`PURCHASE_REQUEST_EDIT_${selectedRow['id:Status'] as PurchaseRequestStatus}`]),
    [selectedRow, userPermission],
  );

  const isDeletePermitted = useMemo(() => selectedRow && selectedRow['id:Status'] === PurchaseRequestStatus.DRAFT, [selectedRow]);

  const isChangeStatusDisabled = useCallback(
    (nextStatus: PurchaseRequestStatus) => {
      if (selectedRow && isNextPurchaseRequestStatus(selectedRow['id:Status'], nextStatus)) {
        return false;
      }
      return true;
    },
    [selectedRow],
  );

  const isApprovementDisabled = useCallback(() => {
    if (selectedRow && selectedRow['id:Status'] == PurchaseRequestStatus.CONSIDERATION) {
      return false;
    }
    return true;
  }, [selectedRow]);

  const handleApprovementSubmit = useCallback(
    (message: string) => {
      addPurchaseRequestApprovement.callAPI(
        { purchaseRequestId: selectedRow?.id, message, approverKind },
        {
          onSuccessfullCall: ({ data }) => {
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push({});
            showNotification({ message: data.message || 'Согласование добавлено', theme: 'success' });
          },
        },
      );
    },
    [addPurchaseRequestApprovement, selectedRow?.id, approverKind, tableStreams.reloadTable],
  );

  const approvementButtons = useMemo<ButtonProps[]>(
    () => [
      {
        mode: ButtonMode.SECONDARY,
        text: 'Комитет',
        title: approverKindTitleMap[PurchaseRequestApprovementApprover.MANAGEMENT_COMMITTEE],
        onClick: () => {
          setApproverKind(PurchaseRequestApprovementApprover.MANAGEMENT_COMMITTEE);
          setIsApprovementModalOpen(true);
        },
        permission: {
          name: Permits.PURCHASE_REQUEST_AGREE_MANAGEMENT_COMMITTEE,
        },
        isDisabled: isApprovementDisabled(),
      },
      {
        mode: ButtonMode.SECONDARY,
        text: 'Комиссия',
        title: approverKindTitleMap[PurchaseRequestApprovementApprover.PRICE_COMMISSION],
        onClick: () => {
          setApproverKind(PurchaseRequestApprovementApprover.PRICE_COMMISSION);
          setIsApprovementModalOpen(true);
        },
        permission: {
          name: Permits.PURCHASE_REQUEST_AGREE_PRICE_COMMISSION,
        },
        isDisabled: isApprovementDisabled(),
      },
      {
        mode: ButtonMode.SECONDARY,
        text: 'Логистика',
        title: approverKindTitleMap[PurchaseRequestApprovementApprover.LOGISTIC_MANAGEMENT],
        onClick: () => {
          setApproverKind(PurchaseRequestApprovementApprover.LOGISTIC_MANAGEMENT);
          setIsApprovementModalOpen(true);
        },
        permission: {
          name: Permits.PURCHASE_REQUEST_AGREE_LOGISTIC_MANAGEMENT,
        },
        isDisabled: isApprovementDisabled(),
      },
    ],
    [isApprovementDisabled, setIsApprovementModalOpen, setApproverKind],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permission: {
          name: Permits.PURCHASE_REQUEST_ADD,
        },
        isHidden: !!project,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: {
          name: Object.values(PurchaseRequestStatus).map(x => Permits[`PURCHASE_REQUEST_EDIT_${x}`]),
        },
        isHidden: !!project,
        isDisabled: !!project || !isEditPermitted || tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteWarningOpen(true),
        permission: {
          name: Permits.PURCHASE_REQUEST_DELETE_DRAFT,
        },
        isHidden: !!project,
        isDisabled: !!project || !isDeletePermitted || tableState.selectedRows.length !== 1,
      },
      ...(!project
        ? [
            {
              icon: { type: 'new' },
              title: 'В черновик',
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.DRAFT),
              permission: {
                name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_DRAFT,
              },
              isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.DRAFT),
            },
          ]
        : []),
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Передать на рассмотрение',
        onClick: () => onChangeStatusClick(PurchaseRequestStatus.CONSIDERATION),
        permission: {
          name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION,
        },
        isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.CONSIDERATION),
        isHidden: !!project,
      },
      ...(!project
        ? [
            {
              icon: { type: 'toBack', color: Color.danger },
              title: 'Возврат на доработку',
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.REVISION),
              permission: {
                name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_REVISION,
              },
              isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.REVISION),
            },
            {
              icon: { type: 'clock' },
              title: 'В работе (поставщик)',
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.IN_ACTION_PROVIDER),
              permission: {
                name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_IN_ACTION_PROVIDER,
              },
              isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.IN_ACTION_PROVIDER),
            },
            {
              icon: { type: 'star', color: Color.warning },
              title: 'Выполнена',
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.COMPLETE),
              permission: {
                name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_COMPLETE,
              },
              isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.COMPLETE),
            },
            {
              icon: { type: 'dislike' },
              title: 'Отклонена',
              onClick: () => onChangeStatusClick(PurchaseRequestStatus.REJECTED),
              permission: {
                name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_REJECTED,
              },
              isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.REJECTED),
            },
            ...approvementButtons,
          ]
        : []),
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: !tableState.selectedRows.length,
      },
    ],
    [
      project,
      isEditPermitted,
      isDeletePermitted,
      isChangeStatusDisabled,
      approvementButtons,
      getReports,
      handleSetCurrentReport,
      tableState.selectedRows.length,
      onChangeStatusClick,
    ],
  );

  return {
    buttons,
    approvementButtons,
    onSubmitDeleteWarning,
    handleSaveStatusClick,
    newStatus,
    handleApprovementSubmit,
    onReportClose,
    currentReport,
    isAddFormOpen,
    setIsAddFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isReportOpen,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    approverKind,
    isOpenMessageModal,
    handleMessageModalClose,
    selectedRow,
  };
};

export default useController;

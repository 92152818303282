import { useCallback } from 'react';

import { MobileRequest } from 'types/models';
import { ReferenceItem } from 'components';

type Props = {
  entry: MobileRequest.Route | null;
  onFieldChange(row: MobileRequest.Route | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleCountryChange = useCallback(
    (e: ReferenceItem) => {
      onFieldChange({ id: entry?.id || null, country: e, city: entry?.city || '', enterprise: entry?.enterprise || null });
    },
    [entry?.city, entry?.enterprise, entry?.id, onFieldChange],
  );

  const handleCityChange = useCallback(
    (value: string) => {
      onFieldChange({
        id: entry?.id || null,
        country: entry?.country || null,
        city: value,
        enterprise: entry?.enterprise || null,
      });
    },
    [entry?.country, entry?.enterprise, entry?.id, onFieldChange],
  );

  const handleEnterpriseChange = useCallback(
    (e: ReferenceItem) => {
      onFieldChange({ id: entry?.id || null, country: entry?.country || null, city: entry?.city || '', enterprise: e });
    },
    [entry?.city, entry?.country, entry?.id, onFieldChange],
  );

  return {
    handleCountryChange,
    handleCityChange,
    handleEnterpriseChange,
  };
}

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  const ALL = 'all';

  // Attention! On GetSecurityDocumentContractList param 'type' is parsed!
  const [type, setType] = useState<string>(ALL);
  const [isYear, setIsYear] = useState<boolean>(false);

  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: (
        <>
          <p>
            Фильтр <strong>Все</strong> - отображаются все договоры без фильтра по году;
          </p>
          <p>
            <strong>Заключенные + Год</strong> - фильтр год применяется к "Дата заключения договора";
          </p>
          <p>
            <strong>Действующие + Год</strong> - фильтр год применяется к периоду между "Дата начала действия" - "Дата окончания
            действия"'.
          </p>
        </>
      ),
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'type',
      list: [
        { label: 'Все', value: ALL },
        { label: 'Заключенные', value: 'isConcluded' },
        { label: 'Действующие', value: 'isActive' },
      ],
      value: type,
      onChange: e => {
        ReactDOM.unstable_batchedUpdates(() => {
          setType(e);
          if (e === ALL) setIsYear(false);
        });
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: isYear,
      onChange: setIsYear,
      isDisabled: type === ALL,
    },
  ]);
});

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertUserActivationSettingsRequest } from './converters';
import { convertUserActivationSettingsToRequest } from './requestConverters';
import { getText } from '../commonConverters';
import { UserActivationSettingValue } from 'types/models/UserActivation';

const configurations = {
  GetUserActivationSettings: makeBackendAPIConfiguration({
    id: 'GetUserActivationSettings',
    endpoint: '/msa/service/commands/GetUserActivationSettings',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetUserActivationSettings' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => {
      return convertUserActivationSettingsRequest(response.Response.Setting);
    },
  }),
  SaveUserActivationSettings: makeBackendAPIConfiguration({
    id: 'SaveUserActivationSettings',
    endpoint: '/msa/service/commands/SaveUserActivationSettings',
    convertInputToXMLElement: (input: UserActivationSettingValue) => {
      return {
        Request: {
          _attr: {
            commandName: 'SaveUserActivationSettings',
          },
          Setting: { ...convertUserActivationSettingsToRequest(input) },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response?.Response?.Id),
  }),
};

export const userActivationConfigurations = { ...configurations };

import React, { useMemo } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { State } from '../makeUseCustomController';
import { getMockFeedline } from '../helpers';
import { MessageModal } from './MessageModal';
import { ProjectCodeChangesInProject } from 'features/Form/views';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ customState, tableState }: Props) {
  const {
    loadProjectFeedline,
    isOpenMessageModal,
    toggleVisibleMessageModal,
    feedline,
    setFeedline,
    deleteFeedline,
    handleSubmitMessageModal,
    closeMessageModal,
    toggleVisibleCodeChangesModal,
    isOpenCodeChangesModal,
    projectId,
    projectNumber,
    isOpenConfirmModal,
    setIsOpenConfirmModal,
    disabled,
  } = customState;

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [row] = tableState.selectedRows;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          toggleVisibleMessageModal();
        },
        isDisabled: disabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          toggleVisibleMessageModal();
          loadProjectFeedline(row.id);
        },
        isDisabled: isEditButtonDisabled || disabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsOpenConfirmModal(true);
        },
        isDisabled: isDeleteButtonDisabled || disabled,
      },
      {
        icon: { type: 'clock' },
        title: 'История',
        onClick: toggleVisibleCodeChangesModal,
        isDisabled: disabled,
      },
    ],
    [
      row,
      setIsOpenConfirmModal,
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      loadProjectFeedline,
      toggleVisibleMessageModal,
      toggleVisibleCodeChangesModal,
      disabled,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={closeMessageModal}
        feedline={feedline || getMockFeedline()}
        changeFeedline={setFeedline}
        onSubmit={handleSubmitMessageModal}
      />

      <ProjectCodeChangesInProject
        isOpen={isOpenCodeChangesModal}
        onClose={toggleVisibleCodeChangesModal}
        projectId={projectId}
        projectNumber={projectNumber}
        isProcessButtonHidden
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              deleteFeedline(row.id);
              setIsOpenConfirmModal(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmModal(false),
          },
        ]}
        size="small"
      >
        <>
          (действие: {tableState.selectedRows[0]?.Type}, сообщение: {tableState.selectedRows[0]?.Message}) Удалить сообщение?
        </>
      </Modal>
    </>
  );
}

export { Toolbar };

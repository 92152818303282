import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { MergeScientistRequest, SaveMergeRequest } from './types';
import { convertMergeScientistToRequest, convertSaveMergeToRequest } from './requestConverters';
import { convertMerge } from './converters';

export const mergeScientistConfigurations = {
  MergeScientist: makeBackendAPIConfiguration({
    id: 'MergeScientist',
    endpoint: '/msa/service/commands/MergeScientist',
    convertInputToXMLElement: (mergeScientistRequest: MergeScientistRequest) => ({
      Request: {
        _attr: { commandName: 'MergeScientist' },
        ...convertMergeScientistToRequest(mergeScientistRequest),
      },
    }),
    convertResponseXMLElementToOutput: convertMerge,
  }),
  SaveMerge: makeBackendAPIConfiguration({
    id: 'SaveMerge',
    endpoint: '/msa/service/commands/MergeScientist',
    convertInputToXMLElement: (mergeScientistRequest: SaveMergeRequest) => ({
      Request: {
        _attr: { commandName: 'MergeScientist' },
        ...convertSaveMergeToRequest(mergeScientistRequest),
      },
    }),
    convertResponseXMLElementToOutput: convertMerge,
  }),
};

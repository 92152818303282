import React, { useCallback, useMemo, useState } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { Report } from 'types/models';
import { ContestRequest } from 'types/models/ContestRequest';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';
import { MessageModal } from 'features/Form/views/MessageModal';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ContestRequestType } from 'utils/Enums';
import { Color } from 'constants/colors';

type Props = {
  isRequestPayments: ContestRequest | undefined;
  disabledEdit: boolean;
  onSave(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  onSendMessage(message: string): void;
  changeStatus(message: string, status: ContestRequestStatus, id: string | null): void;
  addApprovement(message: string, status: string): void;
  requestType: ContestRequestType;
};

enum Message {
  MESSAGE = 'MESSAGE',
}

export type ModalType = ContestRequestStatus | Message;

export const titleTextMap: Record<ModalType, string> = {
  REQUEST: 'Подача заявки на конкурс',
  MESSAGE: 'Добавление сообщения в журнал',
  AGREED_WITH_CONDITION: 'Согласование заявки с условием',
  SUPPORTED: 'Поддержка заявки',
  AGREED: 'Согласование заявки',
  REJECTED: 'Отклонение заявки',
  REVISION: 'Отправка заявки на доработку',
  DRAFT: 'Перевод заявки в черновик',
  ACTUALIZATION: 'Подача заявки на согласование',
};

export const submitTextMap: Record<ModalType, string> = {
  REQUEST: 'Подать заявку на конкурс',
  MESSAGE: 'Добавить',
  AGREED_WITH_CONDITION: 'Согласовать заявку с условием',
  SUPPORTED: 'Поддержать заявку',
  AGREED: 'Согласовать заявку',
  REJECTED: 'Отклонить заявку',
  REVISION: 'Отправить заявку на доработку',
  DRAFT: 'Перевести в черновик',
  ACTUALIZATION: 'Подать на согласование',
};

function Toolbar(props: Props) {
  const { isRequestPayments, disabledEdit, onSave, onSendMessage, changeStatus, addApprovement, requestType } = props;

  const { isProfile } = usePrivatePageContext();

  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [isOpenExpertEstimateModal, setIsOpenExpertEstimateModal] = useState(false);
  const [modalType, setModalType] = useState<ModalType | null>(null);

  const openMessageModal = useCallback((type: ModalType) => {
    setModalType(type);
    setIsOpenMessageModal(true);
  }, []);

  const reports = useMemo<Report[]>(() => {
    if (requestType === ContestRequestType.ACTUALIZATION)
      return [Reports.ContestRequestEstimate, Reports.ContestRequestPerformerCompositionMemo];
    return [
      Reports.ContestRequest,
      Reports.ContestRequestEstimate,
      Reports.ContestRequestPerformerCompositionMemo,
      Reports.ContestRequestTechnicalTask,
      Reports.ContestRequestProjectResults,
    ];
  }, [requestType]);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const status = isRequestPayments?.status?.value as ContestRequestStatus;

  const isActualization = isRequestPayments?.type?.value === ContestRequestType.ACTUALIZATION;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled: disabledEdit,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSave(false),
        isDisabled: disabledEdit,
      },
      {
        icon: { type: 'new' },
        title: 'В черновик',
        onClick: () => openMessageModal(ContestRequestStatus.DRAFT),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_DRAFT },
        isHidden: disabledEdit || isActualization || isProfile || ![ContestRequestStatus.REJECTED].includes(status),
      },
      // Temporary comment
      // {
      //   icon: { type: 'report' },
      //   title: 'Подать на конкурс',
      //   onClick: () => openMessageModal(ContestRequestStatus.REQUEST),
      //   permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST },
      //   isHidden:
      //     disabledEdit || isActualization || ![ContestRequestStatus.DRAFT, ContestRequestStatus.REVISION].includes(status),
      // },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование актуальные данные этапа проекта',
        onClick: () => openMessageModal(ContestRequestStatus.ACTUALIZATION),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION },
        isHidden:
          disabledEdit || !isActualization || ![ContestRequestStatus.DRAFT, ContestRequestStatus.REVISION].includes(status),
      },
      {
        icon: { type: 'like' },
        title: 'Присвоить заявке статус "Согласована"',
        onClick: () => openMessageModal(ContestRequestStatus.AGREED),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED },
        isHidden:
          disabledEdit || isProfile || ![ContestRequestStatus.REQUEST, ContestRequestStatus.ACTUALIZATION].includes(status),
      },
      {
        icon: { type: 'like', color: Color.warning },
        title: 'Присвоить заявке статус "Согласована с условием"',
        onClick: () => openMessageModal(ContestRequestStatus.AGREED_WITH_CONDITION),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION },
        isHidden:
          disabledEdit ||
          isActualization ||
          isProfile ||
          ![ContestRequestStatus.REQUEST, ContestRequestStatus.SUPPORTED, ContestRequestStatus.AGREED].includes(status),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать',
        onClick: () => openMessageModal(ContestRequestStatus.SUPPORTED),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED },
        isHidden:
          disabledEdit ||
          isActualization ||
          isProfile ||
          ![ContestRequestStatus.AGREED_WITH_CONDITION, ContestRequestStatus.AGREED].includes(status),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => openMessageModal(ContestRequestStatus.REVISION),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_REVISION },
        isHidden:
          disabledEdit ||
          isProfile ||
          ![ContestRequestStatus.REJECTED, ContestRequestStatus.REQUEST, ContestRequestStatus.ACTUALIZATION].includes(status),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => openMessageModal(ContestRequestStatus.REJECTED),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_REJECTED },
        isHidden:
          disabledEdit ||
          isActualization ||
          isProfile ||
          ![ContestRequestStatus.REQUEST, ContestRequestStatus.AGREED_WITH_CONDITION].includes(status),
      },
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsOpenExpertEstimateModal(true),
        permission: { name: Permits.CONTEST_REQUEST_AGREE },
        isHidden: disabledEdit || isProfile || status === ContestRequestStatus.DRAFT,
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: () => openMessageModal(Message.MESSAGE),
        permission: { name: Permits.CONTEST_REQUEST_PUSH_FEEDLINE_MESSAGE },
        isHidden: disabledEdit,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [disabledEdit, isActualization, status, isProfile, getReports, handleSetCurrentReport, onSave, openMessageModal],
  );

  const submitsMap: Record<ModalType, (message: string) => void> = {
    MESSAGE: onSendMessage,
    REQUEST: message => onSave(true, id => changeStatus(message, ContestRequestStatus.REQUEST, id)),
    AGREED_WITH_CONDITION: message => onSave(true, id => changeStatus(message, ContestRequestStatus.AGREED_WITH_CONDITION, id)),
    SUPPORTED: message => onSave(true, id => changeStatus(message, ContestRequestStatus.SUPPORTED, id)),
    AGREED: message => onSave(true, id => changeStatus(message, ContestRequestStatus.AGREED, id)),
    REJECTED: message => onSave(true, id => changeStatus(message, ContestRequestStatus.REJECTED, id)),
    REVISION: message => onSave(true, id => changeStatus(message, ContestRequestStatus.REVISION, id)),
    DRAFT: message => onSave(true, id => changeStatus(message, ContestRequestStatus.DRAFT, id)),
    ACTUALIZATION: message => onSave(true, id => changeStatus(message, ContestRequestStatus.ACTUALIZATION, id)),
  };

  const handleCloseMessageModal = useCallback(() => {
    setIsOpenMessageModal(false);
    setModalType(null);
  }, []);

  const handleCloseExpertEstimateModal = useCallback(() => {
    setIsOpenExpertEstimateModal(false);
  }, []);

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={handleCloseMessageModal}
        onSubmit={modalType ? submitsMap[modalType] : undefined}
        titleText={modalType ? titleTextMap[modalType] : ''}
        submitText={modalType ? submitTextMap[modalType] : ''}
      />

      {/* ExpertEstimateModal завязывается на энум NirRequestApprovementResolution вместо ContestRequestApprovementResolution */}
      {/* будет работать до тех пор пока модели совпадают */}
      <ExpertEstimateModal
        isOpen={isOpenExpertEstimateModal}
        onClose={handleCloseExpertEstimateModal}
        onSubmit={addApprovement}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          contestRequestId: isRequestPayments?.id || '',
          projectId: isRequestPayments?.project?.id || '',
        }}
      />
    </>
  );
}

export { Toolbar };

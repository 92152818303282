import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

export function useController() {
  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
    ],
    [],
  );

  return {
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'withApprovement',
      label: 'С визой',
      checked: true,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'withoutApprovement',
      label: 'Без Визы',
      checked: true,
    },
  ]);
});

import { Table } from 'types/models';

import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type RequestData = {
  salaryId: string;
  code: string;
  projectCodeId?: string;
};

export function GetSalaryJobPaymentList({
  salaryId,
  code,
  projectCodeId,
  seletedSalaryRow,
  onSalaryChange,
  salaryChangeMode,
}: RequestData & State): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetSalaryJobPaymentList',
    header: {
      firstLevel: {
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: seletedSalaryRow ? LeftPanelForThirdLevel : undefined,
      },
    },
    requestData: { projectCodeId, salaryId, code },
    useCustomController: makeUseCustomController({ seletedSalaryRow, onSalaryChange, salaryChangeMode }),
  };
}

import { useState, useLayoutEffect, useCallback } from 'react';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { Role } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';

import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { getMockRole } from './helpers';

type Props = {
  onClose(): void;
  viewMode?: boolean;
  editMode?: boolean;
};

export function useController({ onClose, viewMode, editMode }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });
  const [role, setRole] = useState<Role>(getMockRole());

  const { methods: getRole } = BackendAPI.useBackendAPI('GetRole', {
    onSuccessfullCall: ({ data }: { data: any }) => {
      const preparedData = data as Role;
      setRole(preparedData);
    },
  });

  const { methods: saveRole } = BackendAPI.useBackendAPI('SaveRole');

  const handleNameFieldChange = useCallback(
    (value: string) => {
      setRole({ ...role, name: value });
    },
    [role],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      setRole({ ...role, description: value });
    },
    [role],
  );

  const onSubmit = useCallback(
    (needClose?: boolean) => {
      if (!role.name) {
        showNotification({ message: 'Введите название для роли', theme: 'danger' });
        return;
      }
      saveRole.callAPI(role, {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Роль успешно сохранена', theme: 'success' });
          updateWorkModeAfterSaveAndContinue();

          if (!needClose) {
            getRole.callAPI({ roleId: data.Response.Id._text });
            return;
          }

          tableStreams.reloadTable.push({});
          onClose();
        },
      });
    },
    [getRole, onClose, role, saveRole, tableStreams.reloadTable, updateWorkModeAfterSaveAndContinue],
  );

  useLayoutEffect(() => {
    if (id) getRole.callAPI({ roleId: id });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onSubmit,
    role,
    roleId: id ?? role.id ?? null,
    handleNameFieldChange,
    handleDescriptionFieldChange,
    workMode,
    updateWorkModeAfterSaveAndContinue,
  };
}

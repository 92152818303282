export function PersonalDissertationManagementHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>В разделе «Руководство защитой» </strong> отображаются сведения из добавленных публикаций типов
        &quot;Автореферат&quot; и &quot;Диссертация&quot;, где Вы указаны в сведениях об авторстве с ролью «научный руководитель».
      </p>
      <p>Диссертации, защищённые под вашим руководством, вы можете добавить в разделе Публикации.</p>
      <p>
        Сведения об участие в составе диссертационного совета вы можете добавить в разделе Персональные достижения , Работа в
        составе диссертационных советов.
      </p>
    </>
  );
}

import { Button, ButtonMode, Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function RestrictionProjecGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Форма на вкладке «Проекты» содержит список проектов, с ограничениями и без ограничений.</p>
        <p>
          Фильтр <strong>Нет шифра проекта</strong> - если (Сегодня) &gt;= (Дата начала проекта=14 дней) И отсутствует шифр
          проекта.
        </p>
        <p>
          Фильтр <strong>Нет рег. ЕГИСУ</strong> - если (Сегодня) &gt;= (Дата начала проекта= 30 дней)И отсутствует шифр проекта.
        </p>
        <p>
          Фильтр <strong>Нет коллектива</strong> - если (Сегодня) &gt;= (Дата начала проекта=14 дней)И отсутствует список
          коллектива проекта.
        </p>
        <p>
          Фильтр <strong>Год</strong> - отображает список проектов, у которых сроки выполнения пересекаются с указанным годом.
        </p>
        <p>
          Фильтр <strong>Все</strong> - отображает все проекты.
        </p>
        <p>
          Фильтры <strong>Все + Год</strong> - отображает все проекты, у которых сроки выполнения пересекаются с указанным годом.
        </p>
        <p>
          Фильтр <strong>С ограничениями</strong> - отображает все проекты, у которых есть хотя бы одно ограничение.
        </p>
        <p>
          Фильтры <strong>Год + С ограничениями</strong> - отображает проекты с ограничениями, у которых сроки выполнения
          пересекаются с указанным годом.
        </p>
        <p>
          Фильтр <strong>Без ограничений</strong> - отображает все записи, у которых нет ни одного ограничения.
        </p>
        <p>
          Фильтры <strong>Год + Без ограничений</strong> - отображает проекты без ограничений, у которых сроки выполнения
          пересекаются с указанным годом.
        </p>

        <h4>Особенности вывода информации в столбцах таблицы</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Тип проекта</td>
              <td>Тип проекта.</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта.</td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>Дата окончания проекта.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>Краткое название программы.</td>
            </tr>
            <tr>
              <td>Тема проекта</td>
              <td>Тема проекта.</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО и e-mail руководителя проекта.</td>
            </tr>
            <tr>
              <td>Ответственный исполнитель</td>
              <td>ФИО и e-mail ответственного исполнителя проекта.</td>
            </tr>
            <tr>
              <td>Наблюдатель</td>
              <td>Все наблюдатели за проектом.</td>
            </tr>
            <tr>
              <td>Шифры проекта</td>
              <td>Шифры через запятую.</td>
            </tr>
            <tr>
              <td>Состав коллектива</td>
              <td>
                Отображается флаг <Icon type="check" />, если установлено значение для показателя ограничений из группы №1 «Состав
                коллектива». Описание групп показателей и самих показателей см. ниже в таблице «Группы показателей по ограничениям
                проекта».
              </td>
            </tr>
            <tr>
              <td>СМК</td>
              <td>Значения СМК.</td>
            </tr>
            <tr>
              <td>Выплаты коллективу</td>
              <td>
                Отображается флаг <Icon type="check" />, если установлено значение для показателя ограничений из группы №2
                «Выплаты коллективу». Описание групп показателей и самих показателей см. ниже в таблице «Группы показателей по
                ограничениям проекта».
              </td>
            </tr>
            <tr>
              <td>Госрегистрация</td>
              <td>Информация выводится в формате: &lt;Номер госрегистрации&gt; от &lt;Дата госрегистрации проекта&gt;</td>
            </tr>
            <tr>
              <td>Смета и шифр</td>
              <td>
                Отображается флаг <Icon type="check" />, если установлено значение для показателя ограничений из группы №3 «Смета
                и шифр». Описание групп показателей и самих показателей см. ниже в таблице «Группы показателей по ограничениям
                проекта».
              </td>
            </tr>
          </tbody>
        </table>
        <h4>Группы показателей по ограничениям проекта</h4>
        <table>
          <thead>
            <tr>
              <th>Группа показателей</th>
              <th>Показатель</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Группа №1 «Состав коллектива»</td>
              <td>
                <p>Минимальное число членов НК</p>
                <p>Максимальное число членов НК</p>
                <p>Минимальная доля членов НК до 39 лет</p>
                <p>Максимальный средний возраст членов НК</p>
              </td>
            </tr>
            <tr>
              <td>Группа №2 «Выплаты коллективу»</td>
              <td>
                <p>Может ли руководитель проекта работать на дистанте</p>
                <p>Максимальное число дней отпуска руководителя проекта</p>
                <p>Максимальное число дней отпуска руководителя проекта без сохранения заработной платы</p>
                <p>Минимальная доля средств, получаемых членами НК до 39 лет</p>
                <p>Максимальная доля средств, полученных одним членом НК в общем объеме выплат членам НК</p>
              </td>
            </tr>
            <tr>
              <td>Группа №3 «Смета и шифр»</td>
              <td>
                <p>Допускается наличие остатка на конец календарного года</p>
                <p>Максимальный процент отклонения от плана расходования средств</p>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Изменить ограничения проекта: <Button mode={ButtonMode.PRIMARY} text="Ограничения" />
              </td>
              <td>Открывается форма, в которой пользователь может редактировать ограничения для проекта. </td>
            </tr>
            <tr>
              <td>
                Отчеты <Icon type="print" />
              </td>
              <td>
                Служит для формирования отчёта с данными о проектах. На форме <strong>Параметры отчёта</strong> кнопка{' '}
                <strong>Описание отчёта</strong> открывает файл, с описанием правил, по которым формируется отчёт.
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

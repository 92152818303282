import { Form, Document, Author, Event } from 'types/models';
import { Notification } from 'features/Notifications';
import { getError } from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export function validate({
  formFields,
  event,
  members,
}: {
  formFields: Form.Fields;
  event: Event.Event | null;
  members: Author[];
}) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };

  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  if (!event) {
    result.nextNotification = { theme: 'danger', message: 'Поле творческого мероприятия обязательно для заполнения' };
    result.isFormValid = false;
  }

  if (!members.length) {
    result.nextNotification = { theme: 'danger', message: 'В участии должен быть указан хотя бы один участник' };
    result.isFormValid = false;
  }

  return result;
}

export function validateDocuments({ documents }: { documents: Document[] }) {
  const isValidDocuemtns = !!documents.length;

  return isValidDocuemtns;
}

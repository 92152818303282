import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import useController from './controller';
import { Fields } from './Fields';
import { validate } from './validate';
import { Color } from 'constants/colors';
import { MergePersonCollection } from 'features/MergePersonCollection';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';

export type Mode = 'person' | 'lk' | 'history';

type Props = {
  mode?: Mode;
  person: Person.ScientistPerson | null;
  isAddMode?: boolean;
  onSelect?(educations: Person.ScientistEducation): void;
  onChangeEducations(educations: Person.ScientistEducation[]): void;
  onUpdate?: () => void;
  disabled: boolean;
};

function Component(props: Props) {
  const { mode, person, isAddMode, onSelect, onChangeEducations, onUpdate, disabled } = props;

  const {
    userPermission,
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedEducation,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    educationIds,
    selectedEducations,
  } = useController({
    mode,
    person,
    onChangeEducations,
  });

  return (
    <>
      <ListEdit<{}, Person.ScientistEducation>
        header={{ isRequired: true }}
        rows={person?.scientistEducations ?? []}
        onChange={onChangeEducations}
        toolbar={
          (isAddMode || !disabled) && [
            {
              icon: { type: 'check' },
              title: 'Выбрать',
              onClick: (education: Person.ScientistEducation | null) => {
                if (education) {
                  onSelect?.(education);
                }
              },
              isDisabled: (education: Person.ScientistEducation | null) => !education?.id || selectedEducations?.length !== 1,
              isHidden: mode !== 'history',
            },

            'add',
            'edit',
            ...extraToolbarButtons,
          ]
        }
        columns={[
          { label: 'Статус', formatValue: row => row.status.label },
          { label: 'Организация', formatValue: row => row.enterprise },
          { label: 'Специальность', formatValue: row => row.refSpeciality?.label ?? row.speciality ?? '' },
          { label: 'Подразделение', formatValue: row => row.refDepartment?.label ?? row.department ?? '' },
          { label: 'Дата начала', formatValue: row => row.dateReceipt ?? '' },
          { label: 'Дата окончания', formatValue: row => row.dateDismissal ?? '' },
          { label: 'Актуально', formatValue: row => row.isActive, dataKind: 'boolean' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletionDate && deletedBy ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        maxHeight="none"
        withMessages
        isDeleteConfirmEnabled
        {...(mode === 'history'
          ? {
              onRowDoubleClick: (_, row) => {
                if (row) {
                  onSelect?.(row);
                }
              },
            }
          : {})}
        isDisabled={isLinkedHistoryPending}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        onSelectedRowsCallback={handleSelectedRows}
        specification={{
          mode: 'customComponent',
          renderComponent: (education, setEducation, _, modeState) => (
            <Fields
              mode={mode}
              education={education || undefined}
              onChange={setEducation}
              isAdd={modeState === 'add'}
              userPermission={userPermission}
            />
          ),
          validation: {
            checkIsValid: education => !!education?.isReadOnly || validate(education).every(x => x.isValid),
            onInvalidate: education => {
              const info = validate(education);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteReset}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onDeleteConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onDeleteReset,
          },
        ]}
        size="small"
      >
        <>Удалить обучение?</>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteHistoryWarningOpen}
        onClose={closeDeleteHistoryWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDeleteHistoryWarning,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeDeleteHistoryWarning,
          },
        ]}
        size="small"
      >
        <p>Выбранный на удаление элемент используется в историческом срезе.</p>
        <p>
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </p>
      </Modal>

      {mode === 'person' && (
        <>
          <LinkedHistoryEntities
            personId={person?.id}
            isOpen={isEntityRelationOpen}
            onClose={closeEntityRelation}
            id={selectedEducation?.id ?? '-1'}
            type={LinkedHistoryType.EDUCATION}
          />

          <MergePersonCollection
            entity="ScientistEducation"
            entityIds={educationIds}
            isOpen={isMergeDuplicatesOpen}
            onClose={handleCloseMergeDuplicatesForm}
            onUpdate={onUpdate}
          />
        </>
      )}

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
}

export const EditEducations = React.memo(Component);

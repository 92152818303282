import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { Collapse, FormComponent, ListEdit, Radio } from 'components';

import { BuilderProps, TableModalProps, EnumProps } from 'features/BuildReportPopup/models';
import { DefaultSelect } from './DefaultSelect';
import { CustomSelect } from './CustomSelect';
import { ReferenceSelect } from './ReferenceSelect';
import useController from './controller';
import { ALL_RADIO_VALUE, PARTIAL_RADIO_VALUE, USER_RADIO_VALUE, DEPARTMENT_RADIO_VALUE } from './helpers';

import './style.scss';

const block = setup({ mod: '--' });
const parameterList = block('parameter-list');

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = BuilderProps & {
  tableModalProps?: TableModalProps;
  enumProps?: EnumProps<CustomState, RequestData>;
  allRadioLabel?: string;
  partialRadioLabel?: string;
  considerRadioLabel?: string;
  dismissRadioLabel?: string;
};

const DefaultParameterList = <CustomState extends {} = {}, RequestData extends {} = {}>({
  parameters,
  setParameterValueByName,
  parameterName,
  tableModalProps,
  enumProps,
  allRadioLabel = 'Все',
  partialRadioLabel = 'Выборочно',
  considerRadioLabel = 'Все результаты персон',
  dismissRadioLabel = 'Результаты, относящиеся к подразделению',
}: Props<CustomState, RequestData>) => {
  const {
    refs,
    refsRadioValue,
    currentParameter,
    handleChangeRefs,
    handleChangeRefsRadioValue,
    canBuild,
    enumValues,
    handleChangeUserResultRadioValue,
    userResultRadioValue,
  } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
    enumProps,
  });

  return (
    <>
      {canBuild && (
        <div className={parameterList()}>
          <FormComponent.Line>
            <FormComponent.Field label={currentParameter?.description} isRequired={currentParameter?.required}>
              <Radio
                list={[
                  { label: allRadioLabel, value: ALL_RADIO_VALUE },
                  { label: partialRadioLabel, value: PARTIAL_RADIO_VALUE },
                ]}
                onChange={handleChangeRefsRadioValue}
                value={refsRadioValue}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <Collapse isExpanded={refsRadioValue === PARTIAL_RADIO_VALUE}>
            <div className={parameterList('collapse-block')}>
              <span className={parameterList('collapse-block-title')}>Считать</span>
              <FormComponent.Line>
                <FormComponent.Field label={' '}>
                  <Radio
                    list={[
                      { label: considerRadioLabel, value: USER_RADIO_VALUE },
                      { label: dismissRadioLabel, value: DEPARTMENT_RADIO_VALUE },
                    ]}
                    onChange={handleChangeUserResultRadioValue}
                    value={userResultRadioValue}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </div>
          </Collapse>
          {!!tableModalProps && (
            <div className={parameterList('list-edit')}>
              <ListEdit
                header={{ title: tableModalProps?.listEditTitle || '' }}
                rows={refs}
                onChange={handleChangeRefs}
                toolbar={['add', 'edit', 'delete']}
                columns={[{ label: 'Наименование', formatValue: ref => ref.label }]}
                isDisabled={refsRadioValue === ALL_RADIO_VALUE}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: tableModalProps?.relationTableModalTitle || '',
                  modalTableRowConverter: row =>
                    tableModalProps?.modalTableRowConverter?.(row) || { label: row.label, id: row.id },
                  modalTableSpecification: tableModalProps?.modalTableSpecification,
                }}
              />
            </div>
          )}
          {!!enumProps && (
            <div className={parameterList('list-edit')}>
              <ListEdit
                header={{ title: enumProps?.listEditTitle || '' }}
                rows={refs}
                onChange={handleChangeRefs}
                toolbar={['add', 'edit', 'delete']}
                columns={[{ label: 'Наименование', formatValue: ref => ref.label }]}
                isDisabled={refsRadioValue === ALL_RADIO_VALUE}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'customComponent',
                  renderComponent: (ref, setRef) => {
                    let component = null;

                    switch (enumProps.mode.component) {
                      case 'DefaultSelect':
                        component = (
                          <DefaultSelect
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            enumValues={enumValues}
                            fieldLabel={enumProps?.fieldLabel || ''}
                          />
                        );
                        break;
                      case 'CustomSelect':
                        component = (
                          <CustomSelect
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            specification={{
                              modalTableRowConverter: enumProps.mode.specification.modalTableRowConverter,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              modalTableSpecification: enumProps.mode.specification.modalTableSpecification,
                              relationTableModalTitle: enumProps.mode.specification.relationTableModalTitle,
                            }}
                            fieldLabel={enumProps?.fieldLabel || ''}
                          />
                        );
                        break;
                      case 'Reference':
                        component = (
                          <ReferenceSelect
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            fieldLabel={enumProps?.fieldLabel || ''}
                            referenceName={enumProps.mode.referenceName}
                            relationTableModalTitle={enumProps.mode.relationTableModalTitle}
                            referenceFilter={enumProps.mode.referenceFilter}
                          />
                        );
                        break;
                      default:
                        component = <span>Тип компонента не обслуживается</span>;
                        break;
                    }
                    return <FormComponent.Wrapper>{component}</FormComponent.Wrapper>;
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Component = memo(DefaultParameterList);

export { Component as DefaultParameterList };

import React, { useMemo, useState } from 'react';
import { parse } from 'date-fns';
import { block } from 'bem-cn';

import {
  ButtonMode,
  ButtonProps,
  FormComponent,
  Modal,
  RelationTableModal,
  TextArea,
  Toolbar as SharedToolbar,
} from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { PaymentRequestStatus } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { buildPaymentRequestPeriodQuery } from 'features/BuildReportPopup/helpers';
import { Months } from 'utils/Enums/Months';
import { formatDateTimeStr } from 'utils/Constants';
import { RequestForm } from '../RequestForm';
import { State } from '../makeUseCustomController';
import { GetEmployeePaymentRequestFeedlineList } from '../..';
import { Color } from 'constants/colors';

import './style.scss';

const b = block('request-fund-list-toolbar');

type Props = {
  tableState: Table.State;
  customState: State;
};

function getEditablePermission(status: PaymentRequestStatus) {
  return `IncentivePayment:EmployeeRequest:Edit:${status}`;
}

function getDeletablePermission(status: PaymentRequestStatus | null) {
  if (status) {
    return `IncentivePayment:EmployeeRequest:Delete:${status}`;
  }
}

const allEditablePermissions = [
  'IncentivePayment:EmployeeRequest:Edit:DRAFT',
  'IncentivePayment:EmployeeRequest:Edit:REJECTED',
  'IncentivePayment:EmployeeRequest:Edit:SENT',
  'IncentivePayment:EmployeeRequest:Edit:PAID',
  'IncentivePayment:EmployeeRequest:Edit:PAYMENT_APPROVED',
];

const allEditStatusPermissions = [
  'IncentivePayment:EmployeeRequest:Status:ToSent',
  'IncentivePayment:EmployeeRequest:Status:ToPaid',
  'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved',
  'IncentivePayment:EmployeeRequest:Status:ToRejected',
];

const allDeletableStatusPermissions = [
  'IncentivePayment:EmployeeRequest:Delete:DRAFT',
  'IncentivePayment:EmployeeRequest:Delete:REJECTED',
  'IncentivePayment:EmployeeRequest:Delete:SENT',
  'IncentivePayment:EmployeeRequest:Delete:PAID',
  'IncentivePayment:EmployeeRequest:Delete:PAYMENT_APPROVED',
];

export function Toolbar({ tableState, customState }: Props) {
  const [row] = tableState.selectedRows;

  const {
    employeePayment,
    isOpenRequestForm,
    isReportOpen,
    currentReport,
    closeFormRequest,
    openFormRequest,
    setEmployeePayment,
    saveEmployeePayment,
    comment,
    setComment,
    toggleVisibleCommentForm,
    isOpenCommentForm,
    startSaveEmployeePayment,
    isOpenConfirmDelete,
    setIsOpenConfirmDelete,
    deleteEmployeePayment,
    changeEmployeePaymentStatus,
    onPrintClick,
    onReportClose,
    mode,
    selectedFund,
  } = customState;

  const status = row ? (row['id:status'] as PaymentRequestStatus) : null;

  const { userPermission } = useAppDataContext();

  const [isOpenConfirmApprove, setIsOpenConfirmApprove] = useState(false);
  const [isOpenMessageLogModal, setIsOpenMessageLogModal] = useState(false);
  const [isOpenRevisionModal, setIsOpenRevisionModal] = useState(false);
  const [isOpenConfirmPaymentApprovedModal, setIsOpenConfirmPaymentApprovedModal] = useState(false);

  const isUserHasSomeEditablePermission = useMemo(() => isHasPermission(userPermission, allEditablePermissions), [
    userPermission,
  ]);

  const isUserHasSomeStatusEditPermission = useMemo(() => isHasPermission(userPermission, allEditStatusPermissions), [
    userPermission,
  ]);

  const isUserHasSomeStatusDeletePermission = useMemo(() => isHasPermission(userPermission, allDeletableStatusPermissions), [
    userPermission,
  ]);

  const isEditStatusHidden = useMemo(() => !isUserHasSomeStatusEditPermission || !isUserHasSomeEditablePermission, [
    isUserHasSomeEditablePermission,
    isUserHasSomeStatusEditPermission,
  ]);

  const isUserHasStatusEditPermission = useMemo(() => {
    if (status) {
      return isHasPermission(userPermission, getEditablePermission(status));
    }

    return false;
  }, [status, userPermission]);

  const isUserHasStatusDeletePermission = useMemo(() => {
    if (status) {
      return isHasPermission(userPermission, getDeletablePermission(status)!);
    }

    return false;
  }, [status, userPermission]);

  const selectedIds = useMemo(() => {
    if (tableState.selectedRows.length) {
      const selectedRowIds = tableState.selectedRows.map(({ id }) => id);
      return selectedRowIds.join(',');
    }
  }, [tableState.selectedRows]);

  const paymentRequestPeriod = useMemo(() => {
    if (tableState.selectedRows.length) {
      const { year } = row;

      return buildPaymentRequestPeriodQuery({
        periodKey: 'month',
        year,
        period: [
          Months.JANUARY.id,
          Months.FEBRUARY.id,
          Months.MARCH.id,
          Months.APRIL.id,
          Months.MAY.id,
          Months.JUNE.id,
          Months.JULY.id,
          Months.AUGUST.id,
          Months.SEPTEMBER.id,
          Months.OCTOBER.id,
          Months.NOVEMBER.id,
          Months.DECEMBER.id,
        ],
      });
    }
  }, [row, tableState.selectedRows.length]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => openFormRequest('view'),
        permission: { name: 'IncentivePayment:EmployeeRequest:View' },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => openFormRequest('add'),
        permission: { name: 'IncentivePayment:EmployeeRequest:Add' },
        isDisabled: !selectedFund,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => openFormRequest('edit'),
        isHidden: !isUserHasSomeEditablePermission,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusEditPermission,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsOpenConfirmDelete(true),
        isHidden: !isUserHasSomeStatusDeletePermission,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusDeletePermission,
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeEmployeePaymentStatus(PaymentRequestStatus.SENT),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToSent' },
        isHidden: isEditStatusHidden,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.PAID ||
          status === PaymentRequestStatus.SENT ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusEditPermission,
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Передать на оплату',
        onClick: () => changeEmployeePaymentStatus(PaymentRequestStatus.PAID),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToPaid' },
        isHidden: isEditStatusHidden,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.REJECTED ||
          status === PaymentRequestStatus.PAID ||
          status === PaymentRequestStatus.DRAFT ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusEditPermission,
      },
      {
        icon: { type: 'like' },
        title: 'Одобрить оплату',
        onClick: () => setIsOpenConfirmApprove(true),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved' },
        isHidden: isEditStatusHidden,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.REJECTED ||
          status === PaymentRequestStatus.DRAFT ||
          status === PaymentRequestStatus.SENT ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusEditPermission,
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => setIsOpenRevisionModal(true),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToRejected' },
        isHidden: isEditStatusHidden,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          status === PaymentRequestStatus.REJECTED ||
          status === PaymentRequestStatus.DRAFT ||
          status === PaymentRequestStatus.PAYMENT_APPROVED ||
          !isUserHasStatusEditPermission,
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: () => setIsOpenMessageLogModal(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        onClick: onPrintClick,
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [
      tableState.selectedRows.length,
      selectedFund,
      isUserHasSomeEditablePermission,
      status,
      isUserHasStatusEditPermission,
      isUserHasSomeStatusDeletePermission,
      isUserHasStatusDeletePermission,
      isEditStatusHidden,
      onPrintClick,
      openFormRequest,
      setIsOpenConfirmDelete,
      changeEmployeePaymentStatus,
    ],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDelete}
        onClose={() => setIsOpenConfirmDelete(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              deleteEmployeePayment();
              setIsOpenConfirmDelete(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDelete(false),
          },
        ]}
        size="small"
      >
        <>
          (дата: {row?.month?.length === 1 ? '0' : ''}
          {row?.month}.{row?.year}, статус: {row?.status}, основание: {row?.type}) Удалить заявку?
        </>
      </Modal>

      <Modal
        mode="warning"
        title="Одобрение оплаты заявки"
        isOpen={isOpenConfirmApprove}
        onClose={() => setIsOpenConfirmApprove(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmApprove(false);
              if (Number(row.employeesAmount) > Number(selectedFund?.factIncome ?? 0)) {
                showNotification({ message: 'Сумма оплаты не должна превышать фактического прихода фонда', theme: 'danger' });
              } else {
                setIsOpenConfirmPaymentApprovedModal(true);
              }
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmApprove(false),
          },
        ]}
        size="small"
      >
        <>
          Вы уверены, что хотите перевести выбранную заявку в статус "Оплата одобрена"?
          <br />
          Данный статус является конечным, и редактировать заявку в данном статусе невозможно.
        </>
      </Modal>

      <Modal
        title="Одобрение оплаты по заявке"
        isOpen={isOpenConfirmPaymentApprovedModal}
        onClose={() => setIsOpenConfirmPaymentApprovedModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Одобрить',
            onClick: () => {
              changeEmployeePaymentStatus(PaymentRequestStatus.PAYMENT_APPROVED);
              setIsOpenConfirmPaymentApprovedModal(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отменить',
            onClick: () => setIsOpenConfirmPaymentApprovedModal(false),
          },
        ]}
        size="small"
      >
        <div className={b('approve-body')}>
          <div>Квартал {row?.quarter ?? ''}</div>
          <div>Год {parse(row?.createdDate ?? '', formatDateTimeStr, new Date()).getFullYear()}</div>
        </div>
      </Modal>

      <RelationTableModal
        specification={GetEmployeePaymentRequestFeedlineList({ status, employeePaymentId: row?.id ?? '' })}
        modalTitle="Журнал сообщений"
        isOpen={isOpenMessageLogModal}
        onClose={() => setIsOpenMessageLogModal(false)}
      />

      <Modal
        title="Отправка заявки на доработку"
        isOpen={!!isOpenRevisionModal}
        onClose={() => setIsOpenRevisionModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setIsOpenRevisionModal(false);
              changeEmployeePaymentStatus(PaymentRequestStatus.REJECTED);
            },
            isDisabled: !comment,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenRevisionModal(false),
          },
        ]}
        size="large"
      >
        <FormComponent.Field label="Комментарий" isRequired>
          <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
        </FormComponent.Field>
      </Modal>

      <RequestForm
        isOpen={isOpenRequestForm}
        employeePayment={employeePayment}
        setEmployeePayment={setEmployeePayment}
        onClose={closeFormRequest}
        saveEmployeePayment={saveEmployeePayment}
        comment={comment}
        setComment={setComment}
        selectedFund={selectedFund}
        toggleVisibleCommentForm={toggleVisibleCommentForm}
        isOpenCommentForm={isOpenCommentForm}
        startSaveEmployeePayment={startSaveEmployeePayment}
        mode={mode}
        status={status}
        changeEmployeePaymentStatus={changeEmployeePaymentStatus}
        openConfirmApproveStatusModal={() => setIsOpenConfirmApprove(true)}
        openConfirmRevisionStatusModal={() => setIsOpenRevisionModal(true)}
        openConfirmDeleteModal={() => setIsOpenConfirmDelete(true)}
        openMessageLogModal={() => setIsOpenMessageLogModal(true)}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
        values={{
          requestIds: selectedIds,
          department: '-1',
          requestStatus: '-1',
          requestType: '-1',
          regulationParagraph: '-1',
          paymentRequestPeriod: paymentRequestPeriod,
        }}
      />
    </>
  );
}

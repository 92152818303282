import { TenderProtocol } from 'types/models/TenderProtocol';
import { getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertTenderProtocolToXML(p: TenderProtocol) {
  return {
    _attr: p.id ? { id: p.id } : {},
    ...(p.tender ? { Tender: { _attr: { id: p.tender.id } } } : {}),
    ...(p.protocolCommission?.id ? { ProtocolCommission: { _attr: { id: p.protocolCommission.id } } } : {}),
    ...getMaybeField('Number', p.number),
    ...getMaybeField('ProtocolDate', p.protocolDate),
    CommissionMembers: p.commissionMembers?.length
      ? { CommissionMember: p.commissionMembers.map(convertTenderCommissionMemberToRequest) }
      : {},
    ...getMaybeField('Reporter', p.reporter),
    ...(p.documents?.length ? convertDocumentsToRequest(p.documents, 'local') : {}),
  };
}
export function convertTenderCommissionMemberToRequest(m: any) {
  return {
    _attr: m.id ? { id: m.id } : {},
  };
}

import { Change } from 'types/models/Partner';

export function validate(change: Change | null) {
  if (!change) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidChangeDate = Boolean(change.changeDate);
  const isValidCategory = Boolean(change.category && change.category.id);

  return [
    { isValid: isValidChangeDate, invalidMessage: 'Укажите дату изменений' },
    { isValid: isValidCategory, invalidMessage: 'Выберите категорию изменений' },
  ];
}

export function PublicationForParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        В списке отображаются только «Статьи в журнале», «Тезисы» и «Статьи в сборниках конференций», у которых персоны в
        авторском составе пересекаются с составом участников конференции
      </p>
    </>
  );
}

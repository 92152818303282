import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import {
  GetProjectStateRegistrationList,
  GetProjectStateRegistrationReportList,
  GetProjectStateRegistrationReportArchiveList,
} from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function StateRegistration() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['projects', 'state-registration'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Госрегистрация проектов" id="list">
          <DataGrid specification={GetProjectStateRegistrationList} />
        </Tab>
        <Tab title="Госрегистрация отчётов" id="reports">
          <DataGrid specification={GetProjectStateRegistrationReportList} />
        </Tab>
        <Tab title="Архив отчётов" id="archive">
          <DataGrid specification={GetProjectStateRegistrationReportArchiveList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

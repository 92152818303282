import React from 'react';

import { ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import * as BackendAPI from 'services/BackendAPI';

import { GetNirRequestExpositionParticipationList } from 'features/Table/specifications';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function ExpositionParticipations(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  const { methods: loadParticipationsAPI } = BackendAPI.useBackendAPI('GetNirRequestParticipation');

  return (
    <ListEdit
      header={{ title: 'Представление научной продукции на выставках и конкурсах' }}
      rows={nirRequest?.collectiveExpositionParticipations ?? []}
      onChange={makeChangeHandler('collectiveExpositionParticipations')}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        {
          label: 'Экспонат',
          formatValue: x => (x.collectiveExhibitName ? `Коллективная экспозиция: ${x.collectiveExhibitName}` : x.developmentTxt),
        },
        { label: 'Мероприятие', formatValue: x => x.event },
      ]}
      maxHeight="none"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'loadInstanceTableModal',
        relationTableModalTitle: 'Список экспонатов, представленных на выставках',
        modalTableSpecification: GetNirRequestExpositionParticipationList({
          scientistIDs: (nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean),
        }),
        onStartLoad: (tableRows, onSuccess) => {
          loadParticipationsAPI.callAPI(
            { participationsIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data) },
          );
        },
      }}
    />
  );
}

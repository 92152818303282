import React, { useEffect } from 'react';
import { FormComponent } from 'components';
import { useController } from './controller';
import { EventHeader } from 'features/Form/views/ModalHeader';

import { Component as ConferenceDataFormView } from '../views/ConferenceDataFormView';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  viewMode?: boolean;
};

const ConferenceDataForm = ({ viewMode }: Props) => {
  const { compilations, participationPublications, eventTypeCode, departments, statusText, id } = useController();
  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      EventHeader({
        isPublicaton: true,
        departments,
        status: statusText,
        eventId: id || '',
        eventTypeCode,
      }),
    );
  }, [departments, eventTypeCode, id, setTitle, statusText]);

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <ConferenceDataFormView
          viewMode={viewMode}
          compilations={compilations}
          participationPublications={participationPublications}
        />
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = React.memo(ConferenceDataForm);

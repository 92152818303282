import { useMemo, useState, useCallback } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { Permits } from 'utils/Permissions';
import { State, ToolbarButton } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  customState: State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({
  tableState,
  customState: {
    isOpenRelationsForm,
    partner,
    showButtons,
    loadPartner,
    openForm,
    setMode,
    deletePartner,
    openRelationsModal,
    closeRelationsModal,
    setPartnerHead,
  },
}: Props) {
  const [row] = tableState.selectedRows;

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });
  const [isOpenConfirmDeletePartner, setIsOpenConfirmDeletePartner] = useState(false);
  const [isOpenApproveInfoModal, setIsOpenApproveInfoModal] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const getIsButtonShowed = useCallback((buttonRole: ToolbarButton) => (showButtons ? showButtons.includes(buttonRole) : true), [
    showButtons,
  ]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          if (row) {
            setMode('view');
            loadPartner(row.id, openForm, row.Type);
          }
        },
        permission: { name: Permits.PARTNER_VIEW },
        isHidden: !getIsButtonShowed('view'),
        isDisabled: !row,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          loadPartner(null, openForm);
        },
        permission: { name: Permits.PARTNER_ADD },
        isHidden: !getIsButtonShowed('add'),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          if (row) {
            setMode('edit');
            loadPartner(row.id, openForm, row.Type);
          }
        },
        permission: { name: Permits.PARTNER_EDIT },
        isHidden: !getIsButtonShowed('edit'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsOpenConfirmDeletePartner(true),
        permission: { name: Permits.PARTNER_DELETE },
        isHidden: !getIsButtonShowed('remove'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'connection' },
        title: 'Связи',
        onClick: () => {
          if (row) {
            loadPartner(row.id, () => openRelationsModal());
          }
        },
        isHidden: !getIsButtonShowed('relations'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'like' },
        title: 'Подтвердить статус',
        onClick: () => setIsOpenApproveInfoModal(true),
        isHidden: !getIsButtonShowed('approve'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        onClick: () =>
          handleSetCurrentReport({
            name: Reports.PartnersContractsAndAgreements.caption,
            value: Reports.PartnersContractsAndAgreements.name,
          }),
        isHidden: !getIsButtonShowed('print'),
      },
    ],
    [
      handleHelpButtonClick,
      getIsButtonShowed,
      row,
      tableState.selectedRows.length,
      setMode,
      loadPartner,
      openForm,
      openRelationsModal,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    isOpenConfirmDeletePartner,
    setIsOpenConfirmDeletePartner,
    deletePartner,
    row,
    isOpenApproveInfoModal,
    setMode,
    loadPartner,
    setIsOpenApproveInfoModal,
    isOpenRelationsForm,
    closeRelationsModal,
    partner,
    setPartnerHead,
    openForm,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  };
}

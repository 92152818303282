import { GetMagazineReleaseListSpecification, GetSimpleMagazineListSpecification } from 'features/Table/specifications';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form, Table } from 'types/models';
import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  articlePublicationIds: string[];
  sourcePublicationId: string;
  relatedTableState?: Table.State;
  onClose: () => void;
};

const controller = ({ onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => {
  const { methods: getPublicationAPI, state: getPublicationAPIState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: changeMagazineArticleMagazineAPI, state: changeMagazineArticleMagazineAPIState } = BackendAPI.useBackendAPI(
    'ChangeMagazineArticleMagazine',
  );
  const localTableStreams = useLocalTableStreams();
  const isChangeMagazineArticleMagazineLoading = changeMagazineArticleMagazineAPIState.kind === 'pending';
  const isPublicationLoading = getPublicationAPIState.kind === 'pending';

  // новый журнал, к которому прикрепляются статьи
  const [sourceMagazine, setSourceMagazine] = useState<Form.Compilation | null>(null);
  const [sourceMagazineRelease, setSourceMagazineRelease] = useState<Form.Compilation | null>(null);

  // журнал, к которому были прикреплены эти статьи
  const [sourcePublication, setSourcePublication] = useState<Form.Publication | null>(null);

  const isLoading = useMemo(() => isChangeMagazineArticleMagazineLoading || isPublicationLoading, [
    isChangeMagazineArticleMagazineLoading,
    isPublicationLoading,
  ]);

  const getPublication = useCallback(
    ({ publicationId }: { publicationId: string }) =>
      new Promise<Form.Publication | null>(resolve => {
        getPublicationAPI.callAPI(
          {
            id: publicationId,
            simpleFields: {
              status: PublicationStatus.ADDED,
            },
            attrIdFields: {},
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getPublicationAPI],
  );

  const modalTableRowToSourceMagazineReleaseConventer = useCallback<(row: Table.Entry) => Form.Original>(
    row => ({
      id: row.id,
      name: row.label,
      status: row.Status,
      events: row.Events || null,
    }),
    [],
  );

  const sourceMagazineReleaseTableConfig = useMemo(
    () =>
      GetMagazineReleaseListSpecification({
        deps: {
          templatesTableDependencies: relatedTableState && {
            relatedTableAPIID: 'GetMagazineList',
            relatedRecordId: sourceMagazine?.id,
            relatedTableState,
          },
        },
        hasSelectButton: true,
      }),
    [relatedTableState, sourceMagazine?.id],
  );

  const changeSourceMagazineRelease = useCallback((original: Form.Original | null) => {
    setSourceMagazineRelease(original);
  }, []);

  const changeSourceMagazine = useCallback(
    async (magazine: Form.Compilation | null) => {
      if (magazine?.id !== sourcePublicationId) {
        setSourceMagazine(magazine);
        setSourceMagazineRelease(null);
      } else {
        showNotification({
          message: 'Нельзя выбрать тот же журнал к которому выбранные статьи изначально прикреплены',
          theme: 'danger',
        });
      }
    },
    [sourcePublicationId],
  );

  const modalTableRowToSourceMagazineConventer = useCallback<(row: Table.Entry) => Form.Original>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      typeEdition: row.typeEdition,
    }),
    [],
  );

  const sourceMagazineTableConfig = useMemo(
    () =>
      GetSimpleMagazineListSpecification({
        deps: {},
        isPreprint: sourcePublication?.isPreprint,
        articleIds: articlePublicationIds.join(','),
        isElectronic: sourcePublication?.fields?.isElectronic === 'true',
      }),
    [articlePublicationIds, sourcePublication?.fields?.isElectronic, sourcePublication?.isPreprint],
  );

  const handleSubmit = useCallback(() => {
    if (!sourceMagazine || !sourceMagazineRelease) {
      return;
    }

    changeMagazineArticleMagazineAPI.callAPI(
      // todo change to magazine API
      {
        articlePublicationIds,
        sourcePublicationId: sourceMagazine?.id || '',
        sourcePublicationReleaseId: sourceMagazineRelease?.id,
      },
      {
        onSuccessfullCall: () => {
          onClose();
          localTableStreams.reloadTable.push({});
        },
      },
    );
  }, [
    articlePublicationIds,
    changeMagazineArticleMagazineAPI,
    localTableStreams.reloadTable,
    onClose,
    sourceMagazine,
    sourceMagazineRelease,
  ]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      const fetchedSourcePublication = await getPublication({ publicationId: sourcePublicationId });
      setSourcePublication(fetchedSourcePublication);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    sourceMagazine,
    sourceMagazineTableConfig,
    sourceMagazineRelease,
    sourceMagazineReleaseTableConfig,
    modalTableRowToSourceMagazineReleaseConventer,
    changeSourceMagazineRelease,
    handleSubmit,
    changeSourceMagazine,
    modalTableRowToSourceMagazineConventer,
  };
};

export default controller;

import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { Form } from './Form';
import { ToolbarReference } from './ToolbarReference';

import { makeUseCustomController, State, ToolbarButton } from './makeUseCustomController';

type ToolbarMode = 'full' | 'ref' | 'null';

type Arguments = {
  toolbarMode: ToolbarMode;
  parameters?: Parameter[];
  uniqID?: string;
  showButtons?: ToolbarButton[];
  hasSelectButton?: boolean;
  isCanSelectOnlyOneRow?: boolean;
};

type RequestData = {
  parameters: Parameter[];
};

export type Parameter = {
  name: string;
  value: boolean | string;
};

export function GetPartnerList({
  toolbarMode,
  parameters = [],
  showButtons,
  uniqID = 'GetPartnerList',
  hasSelectButton,
  isCanSelectOnlyOneRow,
}: Arguments): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetPartnerList',
    uniqID,
    header: {
      firstLevel:
        toolbarMode === 'full'
          ? {
              title: 'Список организаций',
              Panel: Filters,
            }
          : undefined,
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarMode === 'full' ? Toolbar : toolbarMode === 'ref' ? ToolbarReference : undefined,
        hasSelectButton: hasSelectButton,
      },
    },
    requestData: { parameters },
    useCustomController: makeUseCustomController({ uniqID, showButtons }),
    NeighborTableComponent: Form,
    isCanSelectOnlyOneRow,
  };
}

import React from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-template');

type Props = {
  /** @property {JSX.Element | string | null | false | (JSX.Element | string | null | false)[]} children
   *  - (optional) React inner JSX component */
  children?: JSX.Element | string | null | false | (JSX.Element | string | null | false)[];
};

/**
 * Template component (full size of space with hidden overflow)
 *
 * @param {JSX.Element | string | null | false | (JSX.Element | string | null | false)[]} children
 *  - (optional) React inner JSX component
 * @returns JSX
 */
export const Template = React.memo(({ children }: Props) => {
  return <div className={b()}>{children}</div>;
});

import React from 'react';
import { MobileRequest, Table } from 'types/models';

import { TextArea, TextAreaMode, FormComponent, TextInput, TextInputMode } from 'components';

import { useController } from './controller';
import { GetMobileKindConsumptionForSelect } from 'features/Table/specifications/GetMobileKindConsumptionList';

type Props = {
  entry: MobileRequest.MobileRequestEstimatePosition | null;
  onFieldChange(row: MobileRequest.MobileRequestEstimatePosition | null): void;
  type: string;
};

function EstimatePositionFields({ entry, onFieldChange, type }: Props) {
  const { handleKindConsumptionChange, handleAmountChange, handleNoteChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Статья расходов" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Виды расхода',
              isClearable: false,
              table: {
                specification: GetMobileKindConsumptionForSelect({ type }),
                onSelect: (row: Table.Entry | null) =>
                  handleKindConsumptionChange(
                    row
                      ? { id: row?.id, code: row?.Code, name: row?.Name, types: [], kindConsumption: null, description: '' }
                      : null,
                  ),
              },
            }}
            value={entry?.kindConsumption?.name}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма, руб." isRequired>
          <TextInput mode={TextInputMode.NUMBER} value={entry?.amount} onChange={handleAmountChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={entry?.note || ''}
            onChange={handleNoteChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { EstimatePositionFields };

import { useCallback, useState } from 'react';

import { useLocalTableStreams } from 'features/Table/hooks';

export type State = {
  isOpenForm: boolean;
  toggleVisibleForm(): void;
  reloadTable(): void;
  mode: Mode | null;
  setMode(mode: Mode | null): void;
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);

    const tableStreams = useLocalTableStreams();

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const toggleVisibleForm = useCallback(() => {
      setIsOpenForm(prev => !prev);
    }, []);

    return {
      isOpenForm,
      toggleVisibleForm,
      reloadTable,
      mode,
      setMode,
    };
  };
}

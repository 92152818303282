import React, { useCallback } from 'react';

import { TextInput, FormComponent, SectionTitle, TextArea, TextAreaMode } from 'components';

import { NirRequest } from 'types/models';
import { PartialRecord, ValueOf } from 'types/helpers';
import { NirTenderType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getHeadName } from 'features/Form/looks/nirRequest/NirRequestForm/helpers';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

type TextAreaInfo = {
  key: keyof NirRequest.NirRequest;
  title: string;
  isHidden?: boolean;
  maxLength?: number;
};

export function Description(props: Props) {
  const { settings } = useAppDataContext();
  /* eslint-disable max-len */
  const mapHints: PartialRecord<keyof NirRequest.NirRequest, string> = {
    stageTasks:
      'Задачи исследования формулировать с учетом того, что в дальнейшем они могут стать главами в содержании научного отчета по ГОСТ. Не более 1000 знаков с пробелами',
    keyWords: 'Ключевые слова, словосочетания, описывающие область исследования',
    annotation:
      'Аннотация должна включать характеристику основной темы, проблемы научной работы, ее цели и результаты. В аннотации указывается, что нового несет в себе данное исследование в сравнении с другими, родтсвенными по тематике и целевому назначению. Не более 1500 знаков с пробелами',
    relevance: `Опишите актуальность планируемого исследования и его значение для развития приоритетных направлений в рамках программы развития ${settings?.organization?.shortName} в данной научной области; обязательно включите оценку самой области исследований; новизна и перспективность применяемых в работе методов исследования. Не более 3500 знаков с пробелами`,
    goal: 'Не более 1000 знаков с пробелами',
    researchMethods:
      'Описание методик, методов исследования, включая описание источников информации для проведения исследования. Не более 3000 знаков с пробелами',
    problemResearchState:
      'Современное состояние исследований по данной проблеме, основные направления исследований в мировой науки и научные конкуренты. Не более 3500 знаков с пробелами',
    scientificProblem:
      'Постановка научной проблемы, которую участник конкурса собирается решать, формулировка конкретных гипотез, которые будут проверены. Не более 3000 знаков с пробелами',
    collaborationParticipation: 'Наименование коллаборации, форма участия, страна, город',
    economicSectorInterraction:
      'Полное наименование организации промпартнёра с указанием страны, города и предмета взаимодействия',
    scientificGroundwork:
      'Имеющийся научный задел по теме данного проекта (конкретные научные результаты со ссылкой на научный проект) Не более 7500 знаков с пробелами',
    researchPracticalSignificance:
      'Практическая значимость и научная новизна предполагаемых результатов исследования, их значение для конкретных областей деятельности (указать каких). Не более 3500 знаков с пробелами.',
  };
  /* eslint-enable max-len */

  const { makeChangeHandler, nirRequest, disabled } = props;
  const manager = getHeadName(nirRequest);
  const tenderType = nirRequest?.lot?.nirTenderType?.value ?? null;

  const makeHandleChangeTask = (stageTask: NirRequest.StageTask) => (val: string) => {
    const stageTasks = nirRequest?.stageTasks ?? [];
    const index = stageTasks.findIndex(x => x.year === stageTask.year);

    if (index !== -1) {
      stageTasks[index].task = val;
      makeChangeHandler('stageTasks')(stageTasks);
    }
  };

  const makeHandleChangeExpectedResults = (result: NirRequest.ExpectedResult) => (val: string) => {
    const expectedResults = nirRequest?.expectedResults ?? [];
    const index = expectedResults.findIndex(x => x.year === result.year);

    if (index !== -1) {
      expectedResults[index].result = val;
      makeChangeHandler('expectedResults')(expectedResults);
    }
  };

  const makeHandleChangeStage = (stage: NirRequest.Stage, field: keyof NirRequest.Stage) => (val: string) => {
    const stages = nirRequest?.stages ?? [];
    const index = stages.findIndex(x => x.year === stage.year);
    if (index !== -1) {
      const newStages = [...stages];
      newStages[index][field] = val;
      makeChangeHandler('stages')(newStages);
    }
  };

  const renderTextareaField = (info: TextAreaInfo) => {
    const { key, title, isHidden = false, maxLength } = info;

    if (isHidden) {
      return null;
    }

    const hint =
      key === 'researchPracticalSignificance' && tenderType === NirTenderType.PP
        ? // eslint-disable-next-line max-len
          'Практическая значимость предполагаемых результатов проекта и их значение для реального сектора экономики; (не более 3500 знаков с пробелами)'
        : mapHints[key];

    return (
      <FormComponent.Line key={key}>
        <FormComponent.Field label={title} info={<>{hint}</>}>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, maxLength, title }}
            value={(nirRequest?.[key] as string) ?? ''}
            onChange={makeChangeHandler(key)}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  const renderStageTaskFields = () => {
    const stageTasks = nirRequest?.stageTasks ?? [];

    return stageTasks
      .sort((x, y) => Number(x.year) - Number(y.year))
      .map((stageTask, index) => {
        const baseTitle = `Задачи исследования на ${stageTask.year} год`;
        const titleMap: Record<NirTenderType, string> = {
          PP: `Задачи проекта на ${stageTask.year} год`,
          LMU: baseTitle,
          IG: baseTitle,
          ML: baseTitle,
          ONG: baseTitle,
          PD: baseTitle,
        };

        return (
          <FormComponent.Line key={index}>
            <FormComponent.Field label={titleMap[tenderType!]} tooltip={mapHints.stageTasks}>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: `Задачи исследования на ${stageTask.year} год` }}
                value={stageTask.task}
                onChange={makeHandleChangeTask(stageTask)}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      });
  };

  const renderExpectedResult = () => {
    const expectedResults = nirRequest?.expectedResults ?? [];
    return expectedResults
      .sort((x, y) => Number(x.year) - Number(y.year))
      .map((result, index) => {
        const baseTitle = `Планируемые научные результаты исследования за ${result.year} год`;
        const titleMap: Record<NirTenderType, string> = {
          PP: `Планируемые научные результаты проекта за ${result.year} год`,
          LMU: baseTitle,
          IG: baseTitle,
          ML: baseTitle,
          ONG: baseTitle,
          PD: baseTitle,
        };

        return (
          <FormComponent.Line key={index}>
            <FormComponent.Field
              key={index}
              label={titleMap[tenderType!]}
              info={
                <>
                  Описание планируемых результатов {tenderType === NirTenderType.PP ? 'проекта' : 'исследования'} должно
                  предусматривать их состав, содержание, предназначение и соответствовать поставленным задачам. Результаты должны
                  быть пронумерованы. Не более 3000 знаков с пробелами
                </>
              }
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{
                  rows: 3,
                  title: `Планируемые научные результаты исследования за ${result.year} год`,
                }}
                value={result.result}
                onChange={makeHandleChangeExpectedResults(result)}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      });
  };

  const renderStageEffectFields = () => {
    const stages = nirRequest?.stages ?? [];
    return stages
      .sort((x, y) => Number(x.year) - Number(y.year))
      .map((stage, index) => {
        return (
          <div key={index}>
            <SectionTitle title={`Планируемые эффекты по результатам ${stage.year} года`} />
            <FormComponent.Line>
              <FormComponent.Field label="Эффект на уровне университета">
                <TextArea
                  mode={TextAreaMode.MODAL}
                  settings={{ rows: 3, title: `Планируемый эффект на уровне университета за ${stage.year} год` }}
                  value={stage.universityEffect}
                  onChange={makeHandleChangeStage(stage, 'universityEffect')}
                  isDisabled={!!disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Эффект на уровне региональном">
                <TextArea
                  mode={TextAreaMode.MODAL}
                  settings={{ rows: 3, title: `Планируемый эффект на уровне региональном за ${stage.year} год` }}
                  value={stage.regionalEffect}
                  onChange={makeHandleChangeStage(stage, 'regionalEffect')}
                  isDisabled={!!disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Эффект на уровне национальном">
                <TextArea
                  mode={TextAreaMode.MODAL}
                  settings={{ rows: 3, title: `Планируемый эффект на уровне национальном за ${stage.year} год` }}
                  value={stage.nationalEffect}
                  onChange={makeHandleChangeStage(stage, 'nationalEffect')}
                  isDisabled={!!disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </div>
        );
      });
  };

  const getTitle = useCallback(
    (key: keyof NirRequest.NirRequest) => {
      if (!tenderType) {
        return '';
      }

      const baseAnnotationTitle = 'Аннотация исследования';
      const annontationMap: Record<NirTenderType, string> = {
        PP: 'Аннотация проекта',
        LMU: baseAnnotationTitle,
        IG: baseAnnotationTitle,
        ML: baseAnnotationTitle,
        ONG: baseAnnotationTitle,
        PD: baseAnnotationTitle,
      };

      const baseRelevanceTitle = 'Актуальность и новизна исследования';
      const relevanceMap: Record<NirTenderType, string> = {
        PP: 'Актуальность и новизна проекта',
        LMU: baseRelevanceTitle,
        IG: baseRelevanceTitle,
        ML: baseRelevanceTitle,
        ONG: baseRelevanceTitle,
        PD: baseRelevanceTitle,
      };

      const baseGoalTitle = 'Цель исследования';
      const goalMap: Record<NirTenderType, string> = {
        PP: 'Цель проекта',
        LMU: baseGoalTitle,
        IG: baseGoalTitle,
        ML: baseGoalTitle,
        ONG: baseGoalTitle,
        PD: baseGoalTitle,
      };

      const baseResearchMethodsTitle = 'Методики и методы исследования';
      const researchMethodsMap: Record<NirTenderType, string> = {
        PP: 'Методики и методы',
        LMU: baseResearchMethodsTitle,
        IG: baseResearchMethodsTitle,
        ML: baseResearchMethodsTitle,
        ONG: baseResearchMethodsTitle,
        PD: baseResearchMethodsTitle,
      };

      const baseresearchPracticalSignificanceTitle = 'Практическая значимость и научная новизна результатов';
      const researchPracticalSignificanceMap: Record<NirTenderType, string> = {
        PP: 'Практическая значимость предполагаемых результатов проекта',
        LMU: baseresearchPracticalSignificanceTitle,
        IG: baseresearchPracticalSignificanceTitle,
        ML: baseresearchPracticalSignificanceTitle,
        ONG: baseresearchPracticalSignificanceTitle,
        PD: baseresearchPracticalSignificanceTitle,
      };

      const mapTitles: PartialRecord<keyof NirRequest.NirRequest, PartialRecord<NirTenderType, string>> = {
        annotation: annontationMap,
        relevance: relevanceMap,
        goal: goalMap,
        researchMethods: researchMethodsMap,
        researchPracticalSignificance: researchPracticalSignificanceMap,
      };
      return mapTitles[key]?.[tenderType] ?? '';
    },
    [tenderType],
  );

  const textareasFieldsFirstPart: TextAreaInfo[] = [
    { key: 'keyWords', title: 'Ключевые слова' },
    { key: 'researchMethods', title: getTitle('researchMethods') },
    {
      key: 'problemResearchState',
      title: 'Состояние исследований по проблеме',
      isHidden: tenderType === NirTenderType.PP,
    },
    {
      key: 'scientificProblem',
      title: 'Постановка научной проблемы',
      isHidden: tenderType === NirTenderType.PP,
    },
    { key: 'annotation', title: getTitle('annotation') },
    { key: 'relevance', title: getTitle('relevance') },
    { key: 'goal', title: getTitle('goal') },
  ];

  const textareasFieldsSecondPart: TextAreaInfo[] = [
    { key: 'collaborationParticipation', title: 'Участие в международных коллаборациях' },
    { key: 'economicSectorInterraction', title: 'Взаимодействие с реальным сектором экономики' },
    { key: 'scientificGroundwork', title: 'Имеющийся научный задел по теме' },
    { key: 'researchPracticalSignificance', title: getTitle('researchPracticalSignificance') },
  ];

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <FormComponent.Field label="Название проекта">
            <span>{nirRequest?.name ?? ''}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Руководитель проекта">
            <span>{manager ?? 'Данные о руководителе проекта заполните на вкладке "Коллектив"'}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Название лаборатории"
            tooltip="Отредактируйте название лаборатории: введите полное название без сокращений, с заглавной буквы"
          >
            <TextInput
              value={nirRequest?.departmentTxt ?? ''}
              onChange={makeChangeHandler('departmentTxt')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <>{textareasFieldsFirstPart.map(renderTextareaField)}</>
        <>{renderStageTaskFields()}</>
        <>{renderExpectedResult()}</>
      </FormComponent.Column>
      <FormComponent.Column>
        <>{textareasFieldsSecondPart.map(renderTextareaField)}</>
        <>{renderStageEffectFields()}</>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { isHasPermission } from 'features/AppData';
import * as PageLayout from 'features/PageLayout';
import * as pages from './pages';

import { specifications } from 'features/Table';
import { UserPermission } from 'types/models';
import { Permits } from 'utils/Permissions';
import { CommunityType, NotificationEventModule, ProjectType, SubjectType } from 'utils/Enums';
import { MsaWorkbenches } from 'utils/Common/MsaWorkbenches';
import { makeContent, makeUnimplementedContent } from './makeContent';
import { PrivatePage } from './PrivatePage';
import { makeScientistPage } from './ScientistsPage';
import { getEnumItemLabel } from 'utils/Helpers';
import { EnumMap } from 'types/models/Table';

const govermentContractPermissions = [
  Permits.GOV_CONTRACT_VIEW_FULL_LIST,
  Permits.GOV_CONTRACT_VIEW_BY_DEPARTMENT,
  Permits.GOV_CONTRACT_VIEW_BY_MANAGEMENT,
  Permits.GOV_CONTRACT_RESTRICTIONS_ACCESS,
];

const economicContractPermissions = [
  Permits.ECONOMIC_CONTRACT_VIEW_FULL_LIST,
  Permits.ECONOMIC_CONTRACT_VIEW_BY_DEPARTMENT,
  Permits.ECONOMIC_CONTRACT_VIEW_BY_MANAGEMENT,
  Permits.ECONOMIC_CONTRACT_RESTRICTIONS_ACCESS,
];

const researchJobPermissions = [
  Permits.RESEARCH_JOB_VIEW_FULL_LIST,
  Permits.RESEARCH_JOB_VIEW_BY_DEPARTMENT,
  Permits.RESEARCH_JOB_VIEW_BY_MANAGEMENT,
  Permits.RESEARCH_JOB_RESTRICTIONS_ACCESS,
];

const stateRegistrationReportsPermissions = [
  Permits.PROJECT_STATE_REGISTRATION_VIEW_FULL_LIST,
  Permits.PROJECT_STATE_REGISTRATION_VIEW_BY_DEPARTMENT,
  Permits.PROJECT_STATE_REGISTRATION_VIEW_BY_MANAGEMENT,
];

const projectCodePermissions = [
  Permits.PROJECT_CODE_VIEW_FULL_LIST,
  Permits.PROJECT_CODE_BY_DEPARTMENT,
  Permits.PROJECT_CODE_VIEW_PROJECT_MANAGEMENT,
  Permits.PROJECT_CODE_VIEW_CODE_MANAGEMENT,
  Permits.PROJECT_CODE_VIEW_CURATED_DEPARTMENT,
];

const estimatePermissions = [
  Permits.ESTIMATE_VIEW_FULL_LIST,
  Permits.ESTIMATE_BY_DEPARTMENT,
  Permits.ESTIMATE_VIEW_PROJECT_MANAGEMENT,
  Permits.ESTIMATE_VIEW_CODE_MANAGEMENT,
  Permits.ESTIMATE_VIEW_CURATED_DEPARTMENT,
];

const nirFinancingPermissions = [
  Permits.NIR_FINANCING_VIEW_FULL_LIST,
  Permits.NIR_FINANCING_BY_DEPARTMENT,
  Permits.NIR_FINANCING_VIEW_PROJECT_MANAGEMENT,
  Permits.NIR_FINANCING_VIEW_CODE_MANAGEMENT,
  Permits.NIR_FINANCING_VIEW_CURATED_DEPARTMENT,
];

const grantPermissions = [
  Permits.GRANT_VIEW_FULL_LIST,
  Permits.GRANT_VIEW_BY_DEPARTMENT,
  Permits.GRANT_VIEW_BY_MANAGEMENT,
  Permits.GRANT_RESTRICTIONS_ACCESS,
];

export function getEntries(userPermission: UserPermission | null, enums: EnumMap, shortName: string): PageLayout.TS.Entry[] {
  return [
    {
      kind: 'terminal',
      label: 'Личный кабинет',
      routeElement: 'account',
      Content: PrivatePage,
      isAccepted: isHasPermission(userPermission, MsaWorkbenches.PC),
    },

    {
      kind: 'with-subentries',
      label: 'Публикации',
      routeElement: 'publications',
      isAccepted: isHasPermission(userPermission, [
        Permits.PUBLICATION_INFO_VIEW,
        Permits.MAGAZINE_INFO_VIEW,
        Permits.COMPILATION_INFO_VIEW,
        Permits.VIEW_PUBLICATION_IN_LIBRARY,
        Permits.SCIENTIFIC_INDEX_AND_INDICATOR_EDIT,
      ]),
      subEntries: [
        {
          label: 'Публикации',
          routeElement: 'list',
          Content: makeContent(specifications.GetPublicationExtendedList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Журналы',
          routeElement: 'magazine-list',
          Content: makeContent(specifications.GetMagazineList),
          isAccepted: isHasPermission(userPermission, Permits.MAGAZINE_INFO_VIEW),
        },
        {
          label: 'Сборники',
          routeElement: 'compilation-list',
          Content: makeContent(specifications.GetCompilationList),
          isAccepted: isHasPermission(userPermission, Permits.COMPILATION_INFO_VIEW),
        },
        {
          label: 'Препринты',
          routeElement: 'preprint-list',
          Content: makeContent(specifications.GetPreprintPublicationList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Авторефераты и диссертации',
          routeElement: 'synopsis-dissertation-list',
          Content: makeContent(specifications.GetBaseDissertationPublicationList({})),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Публикации в СМИ',
          routeElement: 'mass-media-publications',
          Content: makeUnimplementedContent('Публикации в СМИ'),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_INFO_VIEW),
        },
        {
          label: 'Анализ эффективности по публикациям',
          routeElement: 'efficiency-analysis',
          Content: pages.EfficiencyAnalysis,
        },
        {
          label: 'Справочник "Наукометрические показатели"',
          routeElement: 'scientificindices',
          Content: makeContent(specifications.GetScientificIndicesList),
          isAccepted: isHasPermission(userPermission, Permits.SCIENTIFIC_INDEX_AND_INDICATOR_EDIT),
        },
        {
          label: 'Аудит передачи публикаций в НБ',
          routeElement: 'library-publication-log-list',
          Content: makeContent(specifications.GetLibraryPublicationLogList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_LIBRARY_LOG_VIEW_LIST),
        },
        {
          label: 'Список публикаций в каталоге Научной библиотеки',
          routeElement: 'publication-library-list',
          Content: makeContent(specifications.GetPublicationLibraryList),
          isAccepted: isHasPermission(userPermission, Permits.PUBLICATION_LIBRARY_VIEW_LIST),
        },
      ],
    },

    {
      kind: 'with-subentries',
      linesPerColumn: 4,
      label: 'Мероприятия',
      routeElement: 'activities',
      isAccepted: isHasPermission(userPermission, [
        Permits.CONTEST_LIST_VIEW,
        Permits.CONFERENCE_LIST_VIEW,
        Permits.EXPOSITION_LIST_VIEW,
        Permits.CONCERT_LIST_VIEW,
        Permits.PARTICIPATION_CONFERENCE_LIST_VIEW,
        Permits.PARTICIPATION_CONTEST_LIST_VIEW,
        Permits.PARTICIPATION_EXPOSITION_LIST_VIEW,
        Permits.PARTICIPATION_CONCERT_LIST_VIEW,
      ]),
      subEntries: [
        {
          label: 'Конференции',
          routeElement: 'conferences',
          Content: makeContent(specifications.GetConferenceEventList({ hasSelectButton: false })),
          isAccepted: isHasPermission(userPermission, Permits.CONFERENCE_LIST_VIEW),
        },
        {
          label: 'Олимпиады и конкурсы',
          routeElement: 'contests',
          isAccepted: isHasPermission(userPermission, Permits.CONTEST_LIST_VIEW),
          Content: makeContent(specifications.GetContestEventList({ hasSelectButton: false })),
        },
        {
          label: 'Выставки',
          routeElement: 'expositions',
          isAccepted: isHasPermission(userPermission, Permits.EXPOSITION_LIST_VIEW),
          Content: makeContent(specifications.GetExpositionEventList({ hasSelectButton: false })),
        },
        {
          label: 'Творческие мероприятия',
          routeElement: 'concerts',
          isAccepted: isHasPermission(userPermission, Permits.CONCERT_LIST_VIEW),
          Content: makeContent(specifications.GetConcertEventList),
        },
        {
          label: 'Участие в конференциях',
          routeElement: 'conferences-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONFERENCE_LIST_VIEW),
          Content: makeContent(specifications.GetConferenceParticipationList),
        },
        {
          label: 'Участие в олимпиадах и конкурсах',
          routeElement: 'contests-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONTEST_LIST_VIEW),
          Content: makeContent(specifications.GetContestParticipationList),
        },
        {
          label: 'Участие в выставках',
          routeElement: 'expositions-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_EXPOSITION_LIST_VIEW),
          Content: makeContent(specifications.GetExpositionParticipationList),
        },
        {
          label: 'Участие в творческих мероприятиях',
          routeElement: 'concert-participation',
          isAccepted: isHasPermission(userPermission, Permits.PARTICIPATION_CONCERT_LIST_VIEW),
          Content: makeContent(specifications.GetConcertParticipationList),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Признания',
      routeElement: 'personal-achievments',
      isAccepted: isHasPermission(userPermission, [
        Permits.RECOGNITION_INFO_VIEW,
        'HirschIndexView',
        Permits.COMMUNITY_INFO_VIEW,
      ]),
      subEntries: [
        {
          label: 'Признания и награды',
          routeElement: 'recognition',
          isAccepted: isHasPermission(userPermission, Permits.RECOGNITION_INFO_VIEW),
          Content: makeContent(specifications.GetRecognitionList),
        },
        {
          routeElement: 'scientist-statistics',
          label: 'Наукометрические показатели персон',
          isAccepted: isHasPermission(userPermission, 'HirschIndexView'),
          Content: makeContent(specifications.GetScientistStatistics({})),
        },
        {
          label: 'Члены научных сообществ',
          routeElement: 'membership',
          Content: makeContent(specifications.GetCommunityMemberList({})),
        },
        {
          routeElement: 'scientific-communities',
          label: 'Научные сообщества и организации',
          isAccepted: isHasPermission(userPermission, [Permits.COMMUNITY_INFO_VIEW]),
          Content: makeContent(
            specifications.GetCommunityList({
              communityTypes: [
                CommunityType.SCIENTIFIC_COUNCIL,
                CommunityType.COLLABORATION,
                CommunityType.FUND,
                CommunityType.MAGAZINE_REDACTION,
              ],
              enumFilterValues: {
                enumName: 'CommunityType',
                excludeValues: [CommunityType.DISSERTATION_COUNCIL],
              },
            }),
          ),
        },
        {
          routeElement: 'community',
          label: 'Диссертационные советы',
          isAccepted: isHasPermission(userPermission, [Permits.COMMUNITY_INFO_VIEW]),
          Content: makeContent(
            specifications.GetDissertationCouncilList({
              communityTypes: [CommunityType.DISSERTATION_COUNCIL],
              title: 'Диссертационные советы',
            }),
          ),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'ОИС',
      routeElement: 'intellectual-properties',
      isAccepted: isHasPermission(userPermission, [
        Permits.PATENT_VIEW,
        Permits.EVIDENCE_VIEW,
        Permits.KNOW_HOW_VIEW,
        Permits.PATENT_RESEARCH_VIEW,
      ]),
      subEntries: [
        {
          label: 'Патенты',
          routeElement: 'patent',
          isAccepted: isHasPermission(userPermission, Permits.PATENT_VIEW),
          Content: makeContent(specifications.GetPatentSecurityDocumentList({})),
        },
        {
          label: 'Свидетельства',
          routeElement: 'evidence',
          isAccepted: isHasPermission(userPermission, Permits.EVIDENCE_VIEW),
          Content: makeContent(specifications.GetEvidenceSecurityDocumentList({})),
        },
        {
          label: 'Ноу-хау',
          routeElement: 'know-how',
          isAccepted: isHasPermission(userPermission, Permits.KNOW_HOW_VIEW),
          Content: makeContent(specifications.GetKnowHowSecurityDocumentList({})),
        },
        {
          label: 'Объекты авторского права',
          routeElement: 'non-registered',
          isAccepted: isHasPermission(userPermission, Permits.NON_REGISTERED_VIEW),
          Content: makeContent(specifications.GetNonRegisteredSecurityDocumentList({})),
        },
        {
          label: 'Аналитика по ОИС',
          routeElement: 'intellectual-property',
          isAccepted: isHasPermission(userPermission, Permits.INTELLECTUAL_PROPERTY_ANALYTICS_VIEW),
          Content: pages.IntellectualProperty,
        },
        {
          label: 'График оплаты пошлин',
          routeElement: 'securitydocument-payments',
          isAccepted: isHasPermission(userPermission, [Permits.PATENT_VIEW, Permits.EVIDENCE_VIEW]),
          Content: makeContent(specifications.GetSecurityDocumentPaymentList()),
        },
        {
          label: 'План на охрану РИД',
          routeElement: 'patent-research',
          isAccepted: isHasPermission(userPermission, Permits.PATENT_RESEARCH_VIEW),
          Content: makeContent(specifications.GetPatentResearchList),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Стимулирующие',
      routeElement: 'payments',
      isAccepted: isHasPermission(userPermission, [
        'IPArticleRequestsViewFullList',
        'IPArticleRequestsViewByDepartment',
        'IPMonographRequestsViewFullList',
        'IPMonographRequestsViewByDepartment',
        'IPTextBookRequestsViewFullList',
        'IPTextBookRequestsViewByDepartment',
        'IPPatentIssueRequestsViewFullList',
        'IPPatentIssueRequestsViewByDepartment',
        'IPPatentRequestsViewFullList', // Объекты ИС
        'IPPatentRequestsViewByDepartment', // Объекты ИС
        // 'IPExhibitRequestsViewFullList', Подготовка экспоната
        // 'IPExhibitRequestsViewByDepartment', Подготовка экспоната
        'IPExhibitionAwardRequestsViewFullList',
        'IPExhibitionAwardRequestsViewByDepartment',
        'IPConferenceRequestsViewFullList',
        'IPConferenceRequestsViewByDepartment',
        'IPFundViewFullList',
        'IPFundViewByDepartment',
        'IPPaymentTermPresetAdd',
        'IPPaymentTermPresetEdit',
        'IPPaymentTermPresetDelete',
      ]),
      subEntries: [
        {
          label: 'Статьи',
          routeElement: 'articles',
          isAccepted: isHasPermission(userPermission, ['IPArticleRequestsViewFullList', 'IPArticleRequestsViewByDepartment']),
          Content: makeContent(specifications.GetArticlePaymentRequests()),
        },
        {
          label: 'Монографии',
          routeElement: 'monograph',
          Content: makeContent(specifications.GetMonographPaymentRequests),
          isAccepted: isHasPermission(userPermission, ['IPMonographRequestsViewFullList', 'IPMonographRequestsViewByDepartment']),
        },
        {
          label: 'Учебники и учебные пособия',
          routeElement: 'textbook',
          isAccepted: isHasPermission(userPermission, ['IPTextBookRequestsViewFullList', 'IPTextBookRequestsViewByDepartment']),
          Content: makeContent(specifications.GetTextBookPaymentRequests),
        },
        {
          label: 'Награды выставки',
          routeElement: 'exhibition-awards',
          isAccepted: isHasPermission(userPermission, [
            'IPExhibitionAwardRequestsViewFullList',
            'IPExhibitionAwardRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetExhibitionAwardsPaymentRequests()),
        },
        {
          label: 'Конференции',
          routeElement: 'conference',
          isAccepted: isHasPermission(userPermission, [
            'IPConferenceRequestsViewFullList',
            'IPConferenceRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetConferencePaymentRequests()),
        },
        {
          label: 'Объекты ИС',
          routeElement: 'inc-payments',
          isAccepted: isHasPermission(userPermission, ['IPPatentRequestsViewFullList', 'IPPatentRequestsViewByDepartment']),
          Content: makeContent(specifications.GetPatentPaymentRequests),
        },
        {
          label: 'Заявки на получение патента',
          routeElement: 'patent-issue',
          isAccepted: isHasPermission(userPermission, [
            'IPPatentIssueRequestsViewFullList',
            'IPPatentIssueRequestsViewByDepartment',
          ]),
          Content: makeContent(specifications.GetPatentIssuePaymentRequests),
        },
        {
          label: 'Фонд подразделений',
          routeElement: 'funds-departments',
          isAccepted: isHasPermission(userPermission, ['IPFundViewFullList', 'IPFundViewByDepartment']),
          Content: pages.FundsDepartments,
        },
        {
          label: 'Настройка',
          routeElement: 'terms',
          isAccepted: isHasPermission(userPermission, [
            'IPPaymentTermPresetAdd',
            'IPPaymentTermPresetEdit',
            'IPPaymentTermPresetDelete',
          ]),
          Content: makeContent(specifications.GetPaymentTermPresets),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Коммерциализация',
      routeElement: 'commercialization',
      isAccepted: isHasPermission(userPermission, [
        Permits.SUBJECT_VIEW,
        Permits.MIP_VIEW,
        Permits.RNTD_VIEW,
        Permits.EXPONENT_VIEW,
        Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST,
        Permits.CONTRACT_VIEW,
        Permits.CONTRACT_PAYMENT_LOG_VIEW,
      ]),
      subEntries: [
        {
          label: 'Коммерциализация РИД',
          routeElement: 'rntd',
          Content: makeContent(specifications.GetRntdList),
          isAccepted: isHasPermission(userPermission, Permits.RNTD_VIEW),
        },
        {
          Content: makeContent(specifications.GetSubjectList({ subjectType: SubjectType.PRODUCT })),
          label: 'Продукты для коммерциализации',
          routeElement: 'product',
          isAccepted: isHasPermission(userPermission, [Permits.SUBJECT_VIEW]),
        },
        {
          Content: makeContent(specifications.GetSubjectList({ subjectType: SubjectType.SUBJECT })),
          label: 'Коллекции РИД по тематике',
          routeElement: 'subject',
          isAccepted: isHasPermission(userPermission, [Permits.SUBJECT_VIEW]),
        },
        {
          label: 'Договоры о распоряжении интеллектуальным правом',
          routeElement: 'security-document-contracts',
          isAccepted: isHasPermission(userPermission, Permits.CONTRACT_VIEW),
          Content: makeContent(specifications.GetSecurityDocumentContractList()),
        },
        {
          label: 'План-график поступления лицензионных платежей',
          routeElement: 'contract-payments',
          isAccepted: isHasPermission(userPermission, Permits.CONTRACT_PAYMENT_LOG_VIEW),
          Content: pages.ContractPayments,
        },
        {
          label: 'МИП',
          routeElement: 'mip',
          Content: pages.Mip,
          isAccepted: isHasPermission(userPermission, Permits.MIP_VIEW),
        },
        {
          label: 'Разработки (выставочные экспонаты)',
          routeElement: 'exponent',
          Content: makeContent(specifications.GetExponentList({})),
          isAccepted: isHasPermission(userPermission, Permits.EXPONENT_VIEW),
        },
        {
          Content: makeContent(specifications.GetForeignSecurityDocumentList({ shortName: shortName })),
          label: `Реестр ОИС не аффилированных с ${shortName}`,
          routeElement: 'foreign-security-document',
          isAccepted: isHasPermission(userPermission, Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Партнёры',
      routeElement: 'partners',
      isAccepted: isHasPermission(userPermission, [
        Permits.PARTNER_VIEW,
        Permits.PARTNER_ADD,
        Permits.PARTNER_EDIT,
        Permits.PARTNER_DELETE,
        Permits.JOINT_EVENT_VIEW,
        Permits.JOINT_EVENT_ADD,
        Permits.JOINT_EVENT_EDIT,
        Permits.JOINT_EVENT_DELETE,
      ]),
      subEntries: [
        {
          label: 'Список организаций',
          routeElement: 'partners',
          Content: makeContent(specifications.GetPartnerList({ toolbarMode: 'full' })),
          isAccepted: isHasPermission(userPermission, [
            Permits.PARTNER_VIEW,
            Permits.PARTNER_ADD,
            Permits.PARTNER_EDIT,
            Permits.PARTNER_DELETE,
          ]),
        },
        {
          label: 'Мероприятия с партнёрами',
          routeElement: 'joint-events',
          Content: makeContent(specifications.GetJointEventList()),
          isAccepted: isHasPermission(userPermission, [
            Permits.JOINT_EVENT_VIEW,
            Permits.JOINT_EVENT_ADD,
            Permits.JOINT_EVENT_EDIT,
            Permits.JOINT_EVENT_DELETE,
          ]),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Поиск',
      routeElement: 'thematic-search',
      isAccepted: isHasPermission(userPermission, [
        Permits.SEARCH_PROJECTS_ACCESS,
        Permits.SEARCH_REPORT_ARCHIVE_ACCESS,
        Permits.SEARCH_SCIENTISTS_ACCESS,
        Permits.SEARCH_SECURITY_DOCUMENTS_ACCESS,
      ]),
      subEntries: [
        {
          Content: makeContent(specifications.GetThematicalSearchProjectStateRegistrationReportList),
          label: 'Архив отчётов по результатам научных исследований',
          routeElement: 'reports-archive',
          isAccepted: isHasPermission(userPermission, Permits.SEARCH_REPORT_ARCHIVE_ACCESS),
        },
        {
          Content: makeContent(specifications.GetPersonalAllProjectList({})),
          label: 'Список научных проектов',
          isAccepted: isHasPermission(userPermission, Permits.SEARCH_PROJECTS_ACCESS),
          routeElement: 'scientist-projects-list',
        },
        {
          Content: makeContent(specifications.GetPersonalAllSecurityDocumentList({})),
          label: 'Объекты интеллектуальной собственности',
          isAccepted: isHasPermission(userPermission, Permits.SEARCH_SECURITY_DOCUMENTS_ACCESS),
          routeElement: 'intellectual-properties-list',
        },
        {
          Content: makeContent(specifications.GetScientistStatistics({ isSearchMode: true })),
          label: 'Поиск персон по научным интересам',
          isAccepted: isHasPermission(userPermission, Permits.SEARCH_SCIENTISTS_ACCESS),
          routeElement: 'scientist-statistics',
        },
        {
          Content: makeContent(specifications.GetEquipList({ mode: 'search' })),
          label: 'Научное оборудование',
          routeElement: 'equip',
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Заявки',
      isAccepted: isHasPermission(userPermission, Permits.REQUEST_ACCESS),
      routeElement: 'requests',
      subEntries: [
        {
          label: 'Заявки на конкурс',
          routeElement: 'program-requests',
          Content: pages.ProgramRequests,
          isAccepted: isHasPermission(userPermission, [
            Permits.PROGRAM_REQUEST_VIEW_ALL,
            Permits.PROGRAM_REQUEST_VIEW_BY_MANAGEMENT,
            Permits.NOTIFICATION_ADMINISTRATE,
          ]),
        },
        {
          label: 'Заявки на внутренний конкурс',
          routeElement: 'nir-requests',
          isAccepted: isHasPermission(userPermission, [
            Permits.NIR_REQUEST_VIEW_ALL,
            Permits.NIR_REQUEST_VIEW_BY_MANAGEMENT,
            Permits.NOTIFICATION_ADMINISTRATE,
          ]),
          Content: pages.NirRequests,
        },
        {
          label: 'Заявки по академической мобильности',
          routeElement: 'mobile-requests',
          isAccepted: isHasPermission(userPermission, [Permits.MOBILE_REQUEST_VIEW, Permits.NOTIFICATION_ADMINISTRATE]),
          Content: pages.MobileRequests,
        },
        {
          routeElement: 'contest-requests',
          label: 'Заявки для актуализации проектов',
          Content: makeContent(specifications.GetContestRequestList()),
          isAccepted: isHasPermission(userPermission, [
            Permits.CONTEST_REQUEST_VIEW_BY_MANAGEMENT,
            Permits.CONTEST_REQUEST_VIEW_ALL,
          ]),
        },
        {
          label: 'Реестр программ',
          routeElement: 'programs',
          isAccepted: isHasPermission(userPermission, Permits.PROGRAM_VIEW),
          Content: makeContent(specifications.GetProgramList({ hasSelectButton: false })),
        },
        {
          label: 'Конкурсы',
          routeElement: 'tenders',
          isAccepted: isHasPermission(userPermission, Permits.TENDER_VIEW),
          Content: pages.Tenders,
        },
        {
          label: 'Список участников заявок на конкурсы',
          routeElement: 'request-performers',
          isAccepted: isHasPermission(userPermission, Permits.TENDER_VIEW),
          Content: makeContent(specifications.GetRequestPerformerList()),
        },
        {
          label: 'Конкурсные комиссии',
          routeElement: 'protocol-commissions',
          isAccepted: isHasPermission(userPermission, Permits.PROTOCOL_COMMISSION_VIEW),
          Content: makeContent(specifications.GetProtocolCommissionList({})),
        },
        {
          label: 'Экспертиза заявок',
          routeElement: 'protocols',
          isAccepted: isHasPermission(userPermission, [
            Permits.TENDER_PROTOCOL_VIEW_FULL_LIST,
            Permits.TENDER_PROTOCOL_VIEW_BY_COMISSION_MEMBERSHIP,
          ]),
          Content: makeContent(specifications.GetTenderProtocolList()),
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Проекты',
      routeElement: 'projects',
      isAccepted: isHasPermission(userPermission, [
        ...researchJobPermissions,
        ...govermentContractPermissions,
        ...economicContractPermissions,
        ...grantPermissions,
        ...stateRegistrationReportsPermissions,
        Permits.PROGRAM_VIEW,
        Permits.FOREIGN_PROJECT_VIEW_FULL_LIST,
        Permits.TOTAL_ANALYTICS_VIEW,
        Permits.NOTIFICATION_ADMINISTRATE,
        Permits.PROJECT_RESTRICTIONS_ACCESS,
      ]),
      subEntries: [
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.NIR, enums)}`,
          routeElement: 'nir-projects',
          isAccepted: isHasPermission(userPermission, researchJobPermissions),
          Content: pages.ProjectNir,
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.GRANT, enums)}`,
          routeElement: 'grants',
          isAccepted: isHasPermission(userPermission, grantPermissions),
          Content: pages.ProjectGrants,
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums)}`,
          routeElement: 'goverment-contracts',
          isAccepted: isHasPermission(userPermission, govermentContractPermissions),
          Content: pages.ProjectGovermentContracts,
        },
        {
          label: `Тип: ${getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums)}`,
          routeElement: 'economic-contracts',
          isAccepted: isHasPermission(userPermission, economicContractPermissions),
          Content: pages.ProjectEconomicContracts,
        },
        {
          label: 'Госрегистрация проектов и отчетов',
          routeElement: 'state-registration',
          isAccepted: isHasPermission(userPermission, stateRegistrationReportsPermissions),
          Content: pages.StateRegistration,
        },
        {
          label: 'Аналитика по всем типам проектов',
          routeElement: 'analytics',
          isAccepted: isHasPermission(userPermission, Permits.TOTAL_ANALYTICS_VIEW),
          Content: pages.Analytics,
        },
        {
          label: 'Списки участников проектов',
          routeElement: 'project-orders',
          isAccepted: isHasPermission(userPermission, Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS),
          Content: pages.ProjectOrders,
        },
        {
          label: 'Проекты сотрудников, выполненные в других организациях',
          routeElement: 'foreign',
          isAccepted: isHasPermission(userPermission, Permits.FOREIGN_PROJECT_VIEW_FULL_LIST),
          Content: makeContent(specifications.GetForeignProjectList()),
        },
        {
          label: 'Журнал уведомлений',
          routeElement: 'notifications',
          isAccepted: isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE),
          Content: makeContent(specifications.GetNotificationLogList({ module: NotificationEventModule.PROJECT })),
        },
        {
          label: 'Реестр программ',
          routeElement: 'programs',
          isAccepted: isHasPermission(userPermission, Permits.PROGRAM_VIEW),
          Content: makeContent(specifications.GetProgramList({ hasSelectButton: false })),
        },
        {
          label: 'Мониторинг ограничений',
          routeElement: 'project-restrictions',
          isAccepted: isHasPermission(userPermission, Permits.PROJECT_RESTRICTIONS_ACCESS),
          Content: pages.ProjectRestrictions,
        },
      ],
    },

    {
      kind: 'with-subentries',
      label: 'Оборудование',
      isAccepted: isHasPermission(userPermission, [Permits.EQUIP_ACCESS, Permits.EQUIP_VERIFICATION_ACCESS]),
      routeElement: 'equip',
      subEntries: [
        {
          label: 'Оборудование',
          routeElement: 'equip',
          Content: makeContent(specifications.GetEquipList({ mode: 'equip' })),
          isAccepted: isHasPermission(userPermission, Permits.EQUIP_ACCESS),
        },
        {
          label: 'Метрологическое сопровождение оборудования',
          routeElement: 'verification',
          Content: makeContent(specifications.GetEquipVerificationList()),
          isAccepted: isHasPermission(userPermission, Permits.EQUIP_VERIFICATION_ACCESS),
        },
        {
          label: 'Анализ и отчетность по оборудованию',
          routeElement: 'analytics',
          Content: makeContent(specifications.GetEquipAnalyticList()),
          isAccepted: isHasPermission(userPermission, Permits.EQUIP_VERIFICATION_ACCESS),
        },
        {
          label: 'Использование в проектах',
          routeElement: 'projects',
          Content: makeContent(specifications.GetProjectEquipList()),
          // isAccepted: isHasPermission(userPermission, Permits.EQUIP_VERIFICATION_ACCESS),
        },
      ],
    },

    {
      kind: 'terminal',
      label: 'Закупки',
      routeElement: 'purchases',
      isAccepted: isHasPermission(userPermission, [Permits.PURCHASE_REQUEST_ACCESS, Permits.NOTIFICATION_ADMINISTRATE]),
      Content: pages.Purchases,
    },

    {
      kind: 'with-subentries',
      label: 'Финансы',
      routeElement: 'finance',
      isAccepted: isHasPermission(userPermission, [
        ...projectCodePermissions,
        ...estimatePermissions,
        ...nirFinancingPermissions,
        Permits.RECEIVING_DOCUMENTS_ACCESS,
        Permits.EXPENSE_DOCUMENTS_ACCESS,
        Permits.KIND_CONSUMPTION_EDIT,
      ]),
      subEntries: [
        {
          label: 'Шифры проектов',
          routeElement: 'project-codes',
          isAccepted: isHasPermission(userPermission, projectCodePermissions),
          Content: pages.ProjectCodes,
        },
        {
          label: 'Поступления',
          routeElement: 'receipts',
          isAccepted: isHasPermission(userPermission, nirFinancingPermissions),
          Content: pages.Receipts,
        },
        {
          label: 'Реестр приходных документов',
          routeElement: 'receive-docs',
          isAccepted: isHasPermission(userPermission, Permits.RECEIVING_DOCUMENTS_ACCESS),
          Content: pages.ReceivingDocuments,
        },
        {
          label: 'Централизованный фонд',
          routeElement: 'fundcards',
          isAccepted: isHasPermission(userPermission, Permits.FUND_CARD_VIEW_LIST),
          Content: pages.Fundcards,
        },
        {
          label: 'Сметы',
          routeElement: 'estimations',
          isAccepted: isHasPermission(userPermission, estimatePermissions),
          Content: makeContent(specifications.GetEstimateList()),
        },
        {
          label: 'Реестр расходных документов',
          routeElement: 'expense-docs',
          isAccepted: isHasPermission(userPermission, Permits.EXPENSE_DOCUMENTS_ACCESS),
          Content: pages.ExpenseDocuments,
        },
        {
          label: 'Оплата коллективам',
          routeElement: 'salary',
          isAccepted: isHasPermission(userPermission, Permits.SALARY_FULL_ACCESS),
          Content: pages.Salary,
        },
        {
          label: 'Виды расходов для заявок на внутренние конкурсы',
          routeElement: 'expense-nir-accounting',
          isAccepted: isHasPermission(userPermission, Permits.NIR_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetNirRequestKindConsumptionList({})),
        },
        {
          label: 'Виды расходов для заявок на конкурсы',
          routeElement: 'expense-accounting',
          isAccepted: isHasPermission(userPermission, Permits.NIR_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetRequestKindConsumptionList({})),
        },
        {
          label: 'Виды расходов для заявок по академической мобильности',
          routeElement: 'expense-mobile',
          isAccepted: isHasPermission(userPermission, Permits.MOBILE_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetMobileKindConsumptionList()),
        },
        {
          label: 'Виды расходов для заявок на актуализацию проекта',
          routeElement: 'expense-contest-request',
          isAccepted: isHasPermission(userPermission, Permits.CONTEST_REQUEST_KIND_CONSUMPTION_VIEW),
          Content: makeContent(specifications.GetContestRequestKindConsumptionList({})),
        },
        {
          label: 'Виды расходов из бухгалтерии',
          routeElement: 'expense-kind-consumption',
          isAccepted: isHasPermission(userPermission, Permits.KIND_CONSUMPTION_EDIT),
          Content: makeContent(specifications.GetKindConsumptionList({})),
        },
      ],
    },

    /* Hidden for all (temporary) makeMultitabContent need rewrite pages... */
    // {
    //   kind: 'with-subentries',
    //   label: 'Аналитика',
    //   routeElement: 'analytics',
    //   isAccepted: isHasPermission(userPermission, [
    //     Permits.TOTAL_ANALYTICS_VIEW,
    //     Permits.INTELLECTUAL_PROPERTY_ANALYTICS_VIEW,
    //     Permits.GOV_CONTRACT_ANALYTICS_VIEW,
    //     Permits.ECONOMIC_CONTRACT_ANALYTICS_VIEW,
    //     Permits.RESEARCH_JOB_ANALYTICS_VIEW,
    //     Permits.GRANT_ANALYTICS_VIEW,
    //   ]),
    //   subEntries: [
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums),
    //       routeElement: 'goverment-contracts',
    //       isAccepted: isHasPermission(userPermission, Permits.GOV_CONTRACT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enums),
    //             specification: specifications.GetGosContractProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetGosContractReportList },
    //           { title: 'Характеристика', specification: specifications.GetGosContactCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetGosContactThematicalSearchList },
    //           // { title: 'Реестр заявок', specification: specifications.GetGosContractRequestList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums),
    //       routeElement: 'economic-agreements',
    //       isAccepted: isHasPermission(userPermission, Permits.ECONOMIC_CONTRACT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enums),
    //             specification: specifications.GetHozDogovorProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetHozDogovorReportList },
    //           { title: 'Характеристика', specification: specifications.GetHozDogovorCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetHozDogovorThematicalSearchList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.NIR, enums),
    //       routeElement: 'nir',
    //       isAccepted: isHasPermission(userPermission, Permits.RESEARCH_JOB_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.NIR, enums),
    //             specification: specifications.GetNirProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetNirReportList },
    //           { title: 'Характеристика', specification: specifications.GetNirCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetNirThematicalSearchList },
    //         ],
    //       }),
    //     },
    //     {
    //       label: getEnumItemLabel('ProjectType', ProjectType.GRANT, enums),
    //       routeElement: 'grants',
    //       isAccepted: isHasPermission(userPermission, Permits.GRANT_ANALYTICS_VIEW),
    //       Content: makeMultitabContent({
    //         specifications: [
    //           {
    //             title: getEnumItemLabel('ProjectType', ProjectType.GRANT, enums),
    //             specification: specifications.GetGrantProjectList,
    //           },
    //           { title: 'Отчетность', specification: specifications.GetGrantReportList },
    //           { title: 'Характеристика', specification: specifications.GetGrantCharacteristicList },
    //           // { title: 'Тематический поиск', specification: specifications.GetGrantThematicalSearchList },
    //         ],
    //       }),
    //     },
    //   ],
    // },

    {
      kind: 'with-subentries',
      label: 'Персоны',
      isAccepted: isHasPermission(userPermission, [
        Permits.PERSON_VIEW,
        Permits.PERSON_ADD,
        Permits.PERSON_EDIT,
        Permits.PERSON_DELETE,
        Permits.PERSON_DUPLICATES_PROCESS,
      ]),
      routeElement: 'scientists',
      subEntries: [
        {
          Content: makeScientistPage(),
          label: 'Справочник "Персоны"',
          isAccepted: isHasPermission(userPermission, [
            Permits.PERSON_VIEW,
            Permits.PERSON_ADD,
            Permits.PERSON_EDIT,
            Permits.PERSON_DELETE,
            Permits.PERSON_DUPLICATES_PROCESS,
          ]),
          routeElement: 'detailed-scientist-list',
        },
        {
          Content: pages.ScientistJobDepartment,
          label: 'Список сотрудников в подразделениях',
          routeElement: 'scientist-job-department',
        },
        {
          Content: makeContent(specifications.GetScientistDuplicateList),
          label: 'Дубликаты персон',
          isAccepted: isHasPermission(userPermission, Permits.PERSON_DUPLICATES_PROCESS),
          routeElement: 'scientist-duplicate-list',
        },
      ],
    },

    {
      kind: 'terminal',
      label: 'Отчёты',
      isAccepted: isHasPermission(userPermission, Permits.REPORT_ACCESS),
      routeElement: 'reports',
      Content: pages.Reports,
    },

    {
      kind: 'terminal',
      label: 'Справочники',
      isAccepted: isHasPermission(userPermission, Permits.REFERENCE_ACCESS),
      routeElement: 'references',
      Content: makeContent(specifications.GetReferences()),
    },

    {
      kind: 'terminal',
      label: 'Администрирование',
      isAccepted: isHasPermission(userPermission, Permits.ADMIN),
      routeElement: 'administration',
      Content: pages.Administration,
    },
  ];
}

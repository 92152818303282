import React from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { EditRelationsModal } from 'features/EditRelationsModal';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { Component as EditDoiPopup } from 'features/Form/views/EditDoiPopup';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import useController from './controller';
import { PreprintGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isSourceCitationDisabled,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isFinancialSupportDisabled,
    isDeleteButtonDisabled,
    isEditRelationsDisabled,
    isDOIDisabled,
    isApproveButtonDisabled,
    isCitationDisabled,
    nextPublicationName,
    publication,
    buttons,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isOpenEditRelations,
    isDeleteConfirmPopupOpen,
    isCitationFormOpen,
    isSourceCitationOpen,
    isApprovePopupOpen,
    isDoiPopupOpened,
    isFinancialSupportOpen,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    handleCloseEditRelationsModal,
    handleConfirmDeleteConfirmPopup,
    handleCloseCitationForm,
    handleCloseSourceCitation,
    handleApprovePopupClose,
    handleCloseDoiPopup,
    handleCloseFinancialSupport,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    handleCloseDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    onApprove,
    handleContinueStep,
    submitDoiPopupCallback,
    submitFinancialPopupCallback,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikePublication
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            componentId={SUCH_LIKE_PUBLICATION_ID}
          />
        </div>
      )}

      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
      />

      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          // handleChangeParticipation={handleOpenFormConferenceParticipation}
          // isHidden={isAddFormOpenConferenceParticipation}
          // setPublication={setPublicationConferenceParticipation}
          // setPublicationConferenceSource={setPublicationConferenceSource}
        />
      )}

      {!isDeleteButtonDisabled && !!tableState.selectedRows.length && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            (ID: {tableState.selectedRows[0]?.id}, название: {tableState.selectedRows[0]?.PublicationName}, статус:{' '}
            {tableState.selectedRows[0]?.Status}) Удалить препринт?
          </>
        </Modal>
      )}

      {!isEditRelationsDisabled && !!tableState.selectedRows.length && (
        <EditRelationsModal
          title="Редактировать связь публикации с подразделениями"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          publication={publication}
        />
      )}

      {!isDOIDisabled && !!tableState.selectedRows.length && (
        <EditDoiPopup
          isOpen={isDoiPopupOpened}
          onClose={handleCloseDoiPopup}
          onSuccess={submitDoiPopupCallback}
          publicationId={tableState.selectedRows[0]?.id as string}
        />
      )}

      {!isApproveButtonDisabled && !!tableState.selectedRows.length && (
        <ApprovePopup
          isOpen={isApprovePopupOpen}
          selectedRowId={tableState.selectedRows[0]?.id}
          onApprove={onApprove}
          onClose={handleApprovePopupClose}
        />
      )}

      {!isCitationDisabled && !!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={tableState.selectedRows[0]?.SourceId}
          relatedTableState={tableState}
        />
      )}

      {!isFinancialSupportDisabled && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={tableState.selectedRows[0]?.id}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}

      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
        />
      )}

      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0]?.id}
          publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
        />
      )}

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {PreprintGridHelp()}
      </Modal>
    </>
  );
};

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useMemo } from 'react';

import { Collapse, FormComponent, ListEditTable } from 'components';

import { Event } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { ParticipationForm } from 'utils/Enums/ParticipationForm';
import { EducationStatus, JobStatus } from 'utils/Enums';
import { EventTypeCode } from 'types/models/Event';

type Props = {
  eventTypeCode: EventTypeCode;
  participations: Event.EventParticipant[];
  showReports?: boolean;
};

const ParticipationsStatistic = ({ eventTypeCode, participations, showReports = false }: Props) => {
  const { settings } = useAppDataContext();

  const stringType: Record<EventTypeCode, string> = {
    CONFERENCE: 'конференции',
    EXPOSITION: 'выставки',
    CONTEST: 'олимпиады/конкурса',
    CONCERT: 'творческого мероприятия',
  };

  type EventParticipantStatistic = {
    index: string;
    all: number;
    unique: number;
    reports: number;
  };

  const columns = [
    { label: 'Показатель', formatValue: (row: EventParticipantStatistic) => row.index },
    {
      label: 'Участники',
      formatValue: (row: EventParticipantStatistic) => row.all,
      styles: { width: '20%', textAlign: 'center' as const },
    },
    {
      label: 'Персоны',
      formatValue: (row: EventParticipantStatistic) => row.unique,
      styles: { width: '20%', textAlign: 'center' as const },
    },
    ...(showReports
      ? [
          {
            label: 'Доклады',
            formatValue: (row: EventParticipantStatistic) => row.reports,
            styles: { width: '20%', textAlign: 'center' as const },
          },
        ]
      : []),
  ];

  const rows = useMemo<EventParticipantStatistic[]>(() => {
    const members = participations.flatMap(i => i.members || []);
    const local = members.filter(member => member.job?.status.value === JobStatus.LOCAL || !!member.education?.isLocal);

    const getUniques = (list: Event.EventParticipationMember[]): Event.EventParticipationMember[] =>
      list.filter((member, index) => list.findIndex(i => i.person?.id === member.person?.id) === index);

    const getReports = (list: Event.EventParticipationMember[]): Event.EventParticipant[] => {
      const listMembersId: string[] = list.map(i => i.person?.id || '').filter(Boolean);

      return participations.filter(i => {
        if (i.form?.code !== ParticipationForm.CONFERENCE_WITH_REPORT_PARTICIPATION) {
          return false;
        }

        const participationMembersId: string[] = (i.members || []).map(m => m.person?.id || '').filter(Boolean);
        return participationMembersId.some(id => listMembersId.includes(id));
      });
    };

    return [
      { index: 'Общее кол-во', value: members },
      { index: `Аффилированные с ${settings?.organization?.shortName}`, value: local },
      { index: 'Сотрудники', value: local.filter(member => member.job?.status.value === JobStatus.LOCAL) },
      {
        index: 'Аспиранты',
        value: local.filter(member => member.education?.status.value === EducationStatus.ASPIRANT && !!member.education?.isLocal),
      },
      {
        index: 'Студенты',
        value: local.filter(member => member.education?.status.value === EducationStatus.STUDENT && !!member.education?.isLocal),
      },
    ].map(i => {
      const { index, value } = i;
      return {
        index,
        all: value.length,
        unique: getUniques(value).length,
        reports: getReports(value).length,
      };
    });
  }, [participations, settings?.organization?.shortName]);

  return (
    <Collapse title={`Статистические показатели ${stringType[eventTypeCode]}`}>
      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <ListEditTable
              columns={columns}
              rows={rows}
              selectedRowIndex={null}
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              selectRow={function (index: number | null): void {}}
            />
          </FormComponent.Column>
          <FormComponent.Column>
            <FormComponent.Text>
              <strong>Участники</strong> – это общее количество записей в разделе «Сведения об участниках{' '}
              {stringType[eventTypeCode]}» с любой ролью и любым форматом участия
            </FormComponent.Text>
            <FormComponent.Text>
              <strong>Персоны</strong> – это количество уникальных участников из общего количества участников мероприятия
            </FormComponent.Text>
            {showReports && (
              <FormComponent.Text>
                <strong>Доклады</strong> – это количество уникальных докладов. Все кто указан в сведениях об участниках,
                учитываются в статистике по сотрудникам, аспирантам, студентам
              </FormComponent.Text>
            )}
            <FormComponent.Text>
              <strong>Аффилированные с {settings?.organization?.shortName}</strong> – это участники, у которых указаны место
              работы или место обучения в {settings?.organization?.shortName}
            </FormComponent.Text>
            <FormComponent.Text>
              <strong>Сотрудники</strong> – это участники, у которых указано место работы в {settings?.organization?.shortName}
            </FormComponent.Text>
            <FormComponent.Text>
              <strong>Аспиранты и Студенты</strong> – это участники, у которых указано место обучения в{' '}
              {settings?.organization?.shortName}
            </FormComponent.Text>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
    </Collapse>
  );
};
export default React.memo(ParticipationsStatistic);

import React from 'react';

import { ButtonMode, Checkbox, FormComponent, ListEdit, Modal, TextDateTime, TextInput, TextInputMode } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

export type Props = {
  isOpen: boolean;
  onClose: () => void;
  viewMode: boolean;
};

const ReleaseForm = ({ onClose, viewMode }: Props) => {
  const {
    formFields,
    conferences,
    conferenceColumns,
    updateConferences,
    relationTableModalState,
    warningPopupText,
    closeWarningPopup,
    submitWarningPopup,
    isWarningPopupOpen,
    eventId,
    onSubmitReleaseClick,
    isLoading,
  } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmitReleaseClick} viewMode={viewMode} isDisabled={isLoading} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.Year.title} isRequired>
            <TextInput mode={TextInputMode.YEAR} value={formFields.Year.value} onChange={formFields.Year.onChange} />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.Month.title}>
            <TextInput value={formFields.Month.value} onChange={formFields.Month.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.Part.title}>
            <TextInput value={formFields.Part.value} onChange={formFields.Part.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.Number.title}>
            <TextInput value={formFields.Number.value} onChange={formFields.Number.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.PublicationDate.title}>
            <TextDateTime value={formFields.PublicationDate.value} onChange={formFields.PublicationDate.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Checkbox
              label={formFields.isConferenceMaterialsPublished.title}
              checked={!!formFields.isConferenceMaterialsPublished.value}
              onChange={formFields.isConferenceMaterialsPublished.onChange}
              isDisabled={Boolean(eventId)}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <ListEdit
          header={{ title: 'Конференции', isRequired: formFields.isConferenceMaterialsPublished.value }}
          rows={conferences}
          onChange={updateConferences}
          toolbar={['add', 'edit', 'delete']}
          columns={conferenceColumns}
          specification={relationTableModalState}
          withMessages
          isDisabled={!formFields.isConferenceMaterialsPublished.value || viewMode || Boolean(eventId)}
          isDeleteConfirmEnabled
        />
      </FormComponent.Wrapper>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isWarningPopupOpen}
        onClose={closeWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Изменить',
            onClick: submitWarningPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeWarningPopup,
          },
        ]}
        size="small"
      >
        <>{warningPopupText}</>
      </Modal>
    </FormComponent.Template>
  );
};

export const Component = React.memo(ReleaseForm);

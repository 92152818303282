import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextAreaMode, TextInput } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function QnaFrequentForm({ onClose }: Props) {
  const { formFields, saveQnaFrequentHandler } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveQnaFrequentHandler} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Тема" isRequired={formFields.questionTheme.required}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.questionTheme.value}
              onChange={formFields.questionTheme.onChange}
              settings={{ name: 'RefQnaQuestionTheme', title: 'Тема', isClearable: !formFields.questionTheme.required }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вопрос" isRequired={formFields.question.required}>
            <TextArea
              settings={{ rows: 5, maxSymbols: 2049 }}
              value={formFields.question.value}
              onChange={formFields.question.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ответ" isRequired={formFields.answer.required}>
            <TextArea
              mode={TextAreaMode.WYSIWYG}
              settings={{ rows: 7, maxSymbols: 2049 }}
              value={formFields.answer.value}
              onChange={formFields.answer.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Хэштеги"
            tooltip="Введите список ключевых слов через пробел, тэг Темы будет добавлен автоматически"
          >
            <TextInput value={formFields.hashtags.value} onChange={formFields.hashtags.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Приоритет">
            <TextInput value={formFields.priority.value} onChange={formFields.priority.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(QnaFrequentForm);

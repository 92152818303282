import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetProjectList, GetProjectRestrictionList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { ProjectType } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

export function ProjectNir() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['projects', 'nir-projects'],
    init: ['list'],
  });

  const { enumMap, userPermission } = useAppDataContext();

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab
          title="Список проектов"
          id="list"
          isVisible={isHasPermission(userPermission, [
            Permits.RESEARCH_JOB_VIEW_FULL_LIST,
            Permits.RESEARCH_JOB_VIEW_BY_DEPARTMENT,
            Permits.RESEARCH_JOB_VIEW_BY_MANAGEMENT,
          ])}
        >
          <DataGrid specification={GetProjectList({ enums: enumMap, type: ProjectType.NIR })} />
        </Tab>
        <Tab
          title="Ограничения для проектов"
          id="restrictions"
          isVisible={isHasPermission(userPermission, Permits.RESEARCH_JOB_RESTRICTIONS_ACCESS)}
        >
          <DataGrid specification={GetProjectRestrictionList({ type: ProjectType.NIR })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import React, { useMemo } from 'react';

import { Tab, Tabs, FormComponent, SectionTitle, Modal } from 'components';

import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { MipPartner } from 'types/models';
import { Contacts } from 'features/EditPartner/Tabs';
import { getEnterpriseCustomersIds, getEnterpriseIds } from 'features/EditPartner/helpers';
import { AboutOrganization, MipDocuments } from './Tabs';
import { useController } from './controller';
import { usePermissions } from './usePermissions';
import {
  GetPartnerEventList,
  GetPartnerExpositionParticipationList,
  GetPartnerMobileProjectList,
  GetPartnerProjectList,
  GetPartnerPublicationList,
} from '../Table/specifications';
import { EditMipPartnerPanel } from 'features/EditMipPartner/EditMipPartnerPanel';
import { MipPartnerHelp } from './help';

type Props = {
  mipPartner: MipPartner.MipPartner | null;
  title: string;
  onSuccessSavePartner(): void;
  closeForm(): void;
  isOpenForm: boolean;
  mode: string | null;
};

export function EditMipPartner(props: Props) {
  const { onSuccessSavePartner, mipPartner: externalMipPartner, closeForm, title, isOpenForm, mode } = props;

  const {
    mipPartner,
    makeChangeHandler,
    onSaveMipPartner,
    setMipPartner,
    isHelpFormOpen,
    setIsHelpFormOpen,
    onHelp,
  } = useController({
    externalMipPartner,
    onSuccessSave: onSuccessSavePartner,
    mode,
  });

  const disabled = useMemo(() => mode === 'view', [mode]);

  const enterpriseCustomerIds = getEnterpriseCustomersIds(mipPartner);
  const enterpriseIds = getEnterpriseIds(mipPartner);

  const permissions = usePermissions();

  return (
    <Modal isOpen={isOpenForm} onClose={closeForm} title={title} size="full">
      <FormComponent.Template>
        <EditMipPartnerPanel
          onSave={() => onSaveMipPartner(true)}
          onSaveAndContinue={() => onSaveMipPartner(false)}
          onHelp={onHelp}
          isDisabled={disabled}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Об организации" isRequired>
              <AboutOrganization
                setMipPartner={setMipPartner}
                disabled={disabled}
                makeChangeHandler={makeChangeHandler}
                mipPartner={mipPartner}
              />
            </Tab>
            <Tab title="Контактные лица">
              <Contacts
                contacts={mipPartner?.contacts ?? []}
                setContacts={makeChangeHandler('contacts')}
                enterpriseCustomerIds={enterpriseCustomerIds}
                enterpriseIds={enterpriseIds}
                disabled={disabled}
              />
            </Tab>
            <Tab title="Документы МИП">
              <MipDocuments disabled={disabled} makeChangeHandler={makeChangeHandler} mipPartner={mipPartner} />
            </Tab>
            <Tab title="НИОКР">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список НИОКР, в которых организация является заказчиком или соисполнителем, или технологическим партнёром"
              />
              <DataGrid specification={GetPartnerProjectList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Публикации">
              <SectionTitle title="Список публикаций, в которых авторы аффилированы с организацией" />
              <DataGrid specification={GetPartnerPublicationList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Мероприятия">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список совместно организованных мероприятий и мероприятий организации, в которых приняли участие сотрудники, аспиранты, студенты университета"
              />
              <DataGrid specification={GetPartnerEventList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Участие в выставках">
              <SectionTitle title="Участие университета в выставках с экспонатами, разработанными совместно с организацией" />
              <DataGrid specification={GetPartnerExpositionParticipationList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Стажировки">
              <SectionTitle
                // eslint-disable-next-line max-len
                title="Список сотрудников, аспирантов и студентов университета, прошедших стажировку в организации"
              />
              <DataGrid specification={GetPartnerMobileProjectList({ enterpriseCustomerIds, enterpriseIds })} />
            </Tab>
            <Tab title="Документы" isVisible={permissions.isViewableDocuments}>
              <Documents
                documents={mipPartner?.documents ?? []}
                setDocuments={makeChangeHandler('documents')}
                disabled={disabled}
                fieldsType="details"
              />
            </Tab>
          </Tabs>

          <Modal
            mode="help"
            title="Особенности заполнения формы МИП"
            isOpen={isHelpFormOpen}
            onClose={() => setIsHelpFormOpen(false)}
            size="large"
          >
            {MipPartnerHelp()}
          </Modal>
        </FormComponent.Template>
      </FormComponent.Template>
    </Modal>
  );
}

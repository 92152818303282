import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  const [isAtYearEnd, setIsAtYearEnd] = useState<boolean>(false);
  const [isYear, setIsYear] = useState<boolean>(true);

  return FiltersComponent([
    {
      type: FilterComponentType.year,
      streamKey: 'atYearEnd',
      label: 'На конец года',
      checked: isAtYearEnd,
      onChange: e => {
        if (e) setIsYear(false);
        setIsAtYearEnd(e);
      },
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: isYear,
      onChange: e => {
        if (e) setIsAtYearEnd(false);
        setIsYear(e);
      },
    },
  ]);
});

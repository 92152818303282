import React, { useEffect, useRef, useState } from 'react';
import { DayPicker } from 'react-day-picker';
import { ru } from 'date-fns/locale';
import { setup } from 'bem-cn';

import { Button, ButtonMode, Popover, Toolbar } from 'components';

import { isValidDate } from './helpers';
import { Mask } from './mask';
import { Timepicker } from './Timepicker';

import useController, { PICKER_MIN_YEAR, PICKER_MAX_YEAR, Tabs } from './controller';

import 'react-day-picker/dist/style.css';
import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('datetime');
const ti = block('text-input');

type PickerType =
  | 'date' // datepicker
  | 'time'; // timepicker

type RangeButton =
  | 'singeDate' // single date range
  | 'toInfinite' // timepicker
  | 'fromInfinite'; // timepicker

type ModeType =
  | 'day' // show days
  | 'month' // show months
  | 'year'; // show years

export type Props = {
  /** @property {boolean} isRange - (optional) Single or range (single by default) */
  isRange?: boolean;
  // eslint-disable-next-line max-len
  /** @property {PickerType | PickerType[]} type - (optional) Type of picker "date" | "time" | {[ "date", "time" ]} ("date" by default) */
  type?: PickerType | PickerType[];
  /** @property {string | null | undefined} value - Value for render */
  value: string | null | undefined;
  /** @property {function} onChange - Callback on date changed */
  onChange(nextValue: string): void;
  /** @property {ModeType[]} mode - (optional) Mode for date only */
  mode?: ModeType[];
  /** @property {boolean} isDisabled - (optional) If disabled needed */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) If show error is needed */
  isError?: boolean;
  /** @property {string} minValue - (optional) Minimal Date as string */
  minValue?: string;
  /** @property {string} maxValue - (optional) Maximal Date as string */
  maxValue?: string;
  /** @property {RangeButton[]} rangeButtons - (optional) List of buttons for visible. Is [] by default */
  rangeButtons?: RangeButton[];
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
};

/**
 * Text input with custom DateTimePicker (date, time or both)
 * Delimiter range of dates is '-'
 *
 * @param isRange - (optional) Single or range. Is single by default
 * @param type - (optional) Type of picker "date" | "time" | {[ "date", "time" ]}. Is "date" by default
 * @param value - Value for render
 * @param onChange - Callback on date changed
 * @param mode - (optional) Mode for date only
 * @param isDisabled - (optional) If disabled needed
 * @param isError - (optional) If show error is needed
 * @param minValue - (optional) Minimal Date as string
 * @param maxValue - (optional) Maximal Date as string
 * @param rangeButton - (optional) List of buttons for visible. Is [] by default
 * @param style - (optional) Custom styles to picker. Is {{ maxWidth: '200px' }} by default
 * @returns JSX
 */
export const TextDateTime = ({
  isRange = false,
  type = 'date',
  value = '',
  onChange,
  mode,
  isDisabled = false,
  isError = false,
  minValue,
  maxValue,
  rangeButtons = ['singeDate', 'toInfinite', 'fromInfinite'],
  style,
}: Props) => {
  const isType = (needle: PickerType) => {
    if (typeof type === 'string') {
      return type === needle;
    }
    return type.includes(needle);
  };

  const isVisibleButton = (needle: RangeButton) => {
    return rangeButtons.includes(needle);
  };

  const [toolbarOffset, setToolbarOffset] = useState<string>('');
  const refToolbar = useRef<HTMLDivElement>();

  useEffect(() => {
    setToolbarOffset(`${(refToolbar.current?.clientWidth || 2) + 6}px`);
  }, []);

  const isWithDate: boolean = isType('date');
  const isWithTime: boolean = isType('time');

  const {
    isOpen,
    dateValue,
    timeValue,
    currentTab,
    dateBorders,
    defaultMonth,
    isErrorTyping,
    dateFilterRef,
    rangeDateValue,
    rangeTimeValue,
    inputDateValue,
    canAcceptRange,
    canAcceptToDate,
    isInputValueEmpty,
    canAcceptFromDate,
    canAcceptSingleRange,
    reset,
    onSubmit,
    submitRange,
    submitFromDateRange,
    submitToDateRange,
    submitSingleRange,
    openCalendar,
    closeCalendar,
    onInputChange,
    setCurrentTab,
    updateTimeValue,
    onMultipleDateChange,
    updateRangeTimeValue,
  } = useController({ isRange, isWithDate, isWithTime, value, onChange, mode, minValue, maxValue });

  const modeHeader = {
    day: 'день',
    month: 'месяц',
    year: 'год',
  };

  const Header = () => {
    const tabs = [
      ...(isWithDate
        ? [
            {
              id: Tabs.DATE,
              label: (mode || []).map(i => modeHeader[i] || 'часть').join(', ') || 'Дата',
            },
          ]
        : []),
      ...(isWithTime ? [{ id: Tabs.TIME, label: 'Время' }] : []),
    ];

    return (
      <header className={b('header')}>
        {tabs.map(tab => (
          <Button
            key={tab.id}
            onClick={() => setCurrentTab(tab.id)}
            classMixin={b('header-tab', { active: currentTab === tab.id })}
            text={tab.label}
          />
        ))}
      </header>
    );
  };

  const Footer = () => (
    <footer className={b('footer').is({ range: isRange })}>
      {isRange ? (
        <>
          {isVisibleButton('singeDate') && (
            <Button
              mode={ButtonMode.PRIMARY}
              text="В рамках одной даты"
              onClick={submitSingleRange}
              isDisabled={mode ? false : !canAcceptSingleRange}
            />
          )}
          {isVisibleButton('toInfinite') && (
            <Button
              mode={ButtonMode.PRIMARY}
              text="От выбранной даты"
              onClick={submitFromDateRange}
              isDisabled={mode ? false : !canAcceptFromDate}
            />
          )}
          {isVisibleButton('fromInfinite') && (
            <Button
              mode={ButtonMode.PRIMARY}
              text="До выбранной даты"
              onClick={submitToDateRange}
              isDisabled={mode ? false : !canAcceptToDate}
            />
          )}
          <Button
            mode={ButtonMode.PRIMARY}
            text="Принять диапазон"
            onClick={submitRange}
            isDisabled={mode ? false : !canAcceptRange}
          />
        </>
      ) : (
        (isWithTime || mode) && (
          <Button
            mode={ButtonMode.PRIMARY}
            text="Применить"
            onClick={() => {
              let date = new Date();
              date.setHours(Number(timeValue.hour || 0));
              date.setMinutes(Number(timeValue.minute || 0));
              date.setSeconds(0);
              onSubmit(date);
            }}
            isDisabled={!timeValue}
          />
        )
      )}
    </footer>
  );

  return (
    <div ref={dateFilterRef} className={b({}).is({ range: isRange, date: isWithDate, time: isWithTime })} style={style}>
      <Mask
        className={ti({ 'is-disabled': isDisabled, 'is-error': isError, 'is-typing-error': isErrorTyping }).toString()}
        isRange={!!isRange}
        isWithDate={!!isWithDate}
        isWithTime={!!isWithTime}
        value={inputDateValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e.target.value || '')}
        onInput={(e: React.ChangeEvent<HTMLInputElement>) => onInputChange(e.target.value || '')}
        style={{ paddingRight: toolbarOffset }}
      />
      <Toolbar
        refToolbar={refToolbar}
        buttons={[
          {
            icon: { type: 'calendar' },
            title: 'Выбрать из календаря',
            isDisabled: !!isDisabled,
            onClick: openCalendar,
          },
          {
            icon: { type: 'clear' },
            title: 'Удалить',
            isDisabled: !!isInputValueEmpty || !!isDisabled,
            onClick: reset,
          },
        ]}
      />

      <Popover
        isOpen={isOpen}
        onClose={closeCalendar}
        anchorRef={dateFilterRef.current}
        position={{
          offset: 4,
          anchor: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          content: {
            vertical: 'top',
            horizontal: 'center',
          },
        }}
      >
        <div className={b('picker')} data-mode={(mode || []).join(' ')}>
          {isRange ? (
            <>
              <Header />
              <div className={b('content')}>
                {isWithDate && (
                  <div className={b('date-wrapper', { visible: currentTab === Tabs.DATE })}>
                    <DayPicker
                      captionLayout="dropdown"
                      mode="range"
                      fromYear={PICKER_MIN_YEAR}
                      toYear={PICKER_MAX_YEAR}
                      pagedNavigation
                      locale={ru}
                      selected={rangeDateValue}
                      onSelect={onMultipleDateChange}
                      defaultMonth={defaultMonth.from}
                      onMonthChange={date => (!!mode ? onMultipleDateChange({ from: date, to: rangeDateValue.to }) : null)}
                    />
                    <DayPicker
                      captionLayout="dropdown"
                      mode="range"
                      fromYear={PICKER_MIN_YEAR}
                      toYear={PICKER_MAX_YEAR}
                      pagedNavigation
                      locale={ru}
                      selected={rangeDateValue}
                      onSelect={onMultipleDateChange}
                      defaultMonth={defaultMonth.to}
                      onMonthChange={date => (!!mode ? onMultipleDateChange({ from: rangeDateValue.from, to: date }) : null)}
                    />
                  </div>
                )}
                {isWithTime && (
                  <div className={b('time-wrapper', { visible: currentTab === Tabs.TIME }, { range: true })}>
                    <Timepicker
                      setHours={updateRangeTimeValue('from', 'hour')}
                      setMinutes={updateRangeTimeValue('from', 'minute')}
                      selectedMinute={rangeTimeValue.from.minute}
                      selectedHour={rangeTimeValue.from.hour}
                    />
                    <span className={b('time-separator', { 'is-range': isRange })}>&ndash;</span>
                    <Timepicker
                      setHours={updateRangeTimeValue('to', 'hour')}
                      setMinutes={updateRangeTimeValue('to', 'minute')}
                      selectedMinute={rangeTimeValue.to.minute}
                      selectedHour={rangeTimeValue.to.hour}
                    />
                  </div>
                )}
              </div>
              <Footer />
            </>
          ) : (
            <>
              <Header />
              <div className={b('content')}>
                {isWithDate && (
                  <div className={b('date-wrapper', { visible: currentTab === Tabs.DATE })}>
                    <DayPicker
                      captionLayout="dropdown"
                      mode="single"
                      fromYear={PICKER_MIN_YEAR}
                      toYear={PICKER_MAX_YEAR}
                      locale={ru}
                      selected={dateValue}
                      onSelect={date => onSubmit(date)}
                      defaultMonth={isValidDate(dateValue) ? dateValue : new Date()}
                      {...dateBorders}
                      showOutsideDays
                      onMonthChange={date => (mode ? onSubmit(date, false) : null)}
                    />
                  </div>
                )}
                {isWithTime && (
                  <div className={b('time-wrapper', { visible: currentTab === Tabs.TIME })}>
                    <Timepicker
                      setHours={updateTimeValue('hour')}
                      setMinutes={updateTimeValue('minute')}
                      selectedMinute={timeValue.minute}
                      selectedHour={timeValue.hour}
                    />
                  </div>
                )}
              </div>
              <Footer />
            </>
          )}
        </div>
      </Popover>
    </div>
  );
};

import React from 'react';

import { FormComponent, Radio, Select, SelectMode, TextArea, TextDateTime } from 'components';

import { Project, ReferenceItem } from 'types/models';
import { Item } from 'types/models/common';
import { ProjectReportApprovementResolution } from 'utils/Enums';
import { approvementResolutions } from '../Fields';

type Props = {
  approvement: Project.Approvement;
  setApprovement: (approvement: Project.Approvement) => void;
};

export const Fields = ({ approvement, setApprovement }: Props) => {
  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="Согласовано" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={approvement?.expertGroup}
            onChange={(option: ReferenceItem | null) => setApprovement({ ...approvement!, expertGroup: option })}
            settings={{
              name: 'RefProjectReportExpertGroup',
              title: 'Справочник "Экспертные группы для согласования научно-технического отчёта"',
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата">
          <TextDateTime value={approvement.date} onChange={date => setApprovement({ ...approvement!, date })} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Виза" isRequired>
          <Radio
            value={approvement.resolution?.value}
            list={approvementResolutions}
            onChange={value =>
              setApprovement({
                ...approvement!,
                resolution: ({ label: '', value } as unknown) as Item<ProjectReportApprovementResolution>,
              })
            }
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Замечания"
          isRequired={approvement.resolution?.value === ProjectReportApprovementResolution.REVISION}
        >
          <TextArea
            settings={{ rows: 5 }}
            value={approvement.note ?? ''}
            onChange={note => setApprovement({ ...approvement!, note })}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
};

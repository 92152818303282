import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { State, ThirdLevelButton } from '../model';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({
  tableState,
  onSubmitTable,
  customState: {
    name,
    openEditor,
    setMode,
    buttonsToHide,
    referenceMetadata,
    loadReferenceFieldsValues,
    setEditableRowId,
    deleteReference,
    loadModificationHistory,
    historyEntries,
  },
}: Props) {
  const tableStreams = useLocalTableStreams();

  const { methods: activeReferenceElement } = BackendAPI.useBackendAPI('ActiveReferenceElement');
  const { methods: favoriteReferenceElement } = BackendAPI.useBackendAPI('FavoriteReferenceElement');

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const confirmDelete = useCallback(() => {
    if (tableState.selectedRows[0]) {
      deleteReference(tableState.selectedRows[0].id);
    }
    setIsOpenConfirmDeleteModal(false);
  }, [tableState.selectedRows, deleteReference]);

  const isNeedHideButton = useCallback(
    (buttonName: ThirdLevelButton) => {
      const isButtonToHideContainButtonName = buttonsToHide?.some(buttonToHideName => buttonToHideName === buttonName);
      return isButtonToHideContainButtonName;
    },
    [buttonsToHide],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: () => {
          if (onSubmitTable) {
            onSubmitTable(tableState);
          } else {
            tableStreams.submitTable.push(tableState);
          }
        },
        isHidden: isNeedHideButton('submit'),
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          if (tableState.selectedRows[0]) {
            loadReferenceFieldsValues(tableState.selectedRows[0].id);
          }
          openEditor();
          setMode('view');
        },
        isHidden: isNeedHideButton('view'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          openEditor();
          setMode('add');
        },
        isHidden: isNeedHideButton('add'),
        isDisabled: !referenceMetadata?.addable,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          if (tableState.selectedRows[0]) {
            setEditableRowId(tableState.selectedRows[0].id);
            loadReferenceFieldsValues(tableState.selectedRows[0].id);
          }
          openEditor();
          setMode('edit');
        },
        isHidden: isNeedHideButton('edit'),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          !referenceMetadata?.editable ||
          !(tableState.selectedRows[0]?.editable === 'true' || !!referenceMetadata?.fields.find(x => x.editable)),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsOpenConfirmDeleteModal(true);
        },
        isHidden: isNeedHideButton('delete'),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          !referenceMetadata?.deletable ||
          tableState.selectedRows[0].deletable === 'false',
      },
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Добавить в актуальные',
        onClick: () => {
          activeReferenceElement.callAPI(
            {
              name,
              ids: tableState.selectedRows.map(i => i.id),
              value: true,
            },
            {
              onSuccessfullCall: () => {
                tableStreams.reloadTable.push({});
              },
            },
          );
        },
        isDisabled: !tableState.selectedRows.length || !tableState.selectedRows.some(i => i.active === 'false'),
      },
      {
        icon: { type: 'activate', mode: 'remove' },
        title: 'Удалить из актуальных',
        onClick: () => {
          activeReferenceElement.callAPI(
            {
              name,
              ids: tableState.selectedRows.map(i => i.id),
              value: false,
            },
            {
              onSuccessfullCall: () => {
                tableStreams.reloadTable.push({});
              },
            },
          );
        },
        isDisabled: !tableState.selectedRows.length || !tableState.selectedRows.some(i => i.active === 'true'),
      },
      {
        icon: { type: 'star', mode: 'check' },
        title: 'Добавить в избранное',
        onClick: () => {
          favoriteReferenceElement.callAPI(
            {
              name,
              ids: tableState.selectedRows.map(i => i.id),
              value: true,
            },
            {
              onSuccessfullCall: () => {
                tableStreams.reloadTable.push({});
              },
            },
          );
        },
        isDisabled: !tableState.selectedRows.length || !tableState.selectedRows.some(i => i.favorite === 'false'),
      },
      {
        icon: { type: 'star', mode: 'remove' },
        title: 'Удалить из избранного',
        onClick: () => {
          favoriteReferenceElement.callAPI(
            {
              name,
              ids: tableState.selectedRows.map(i => i.id),
              value: false,
            },
            {
              onSuccessfullCall: () => {
                tableStreams.reloadTable.push({});
              },
            },
          );
        },
        isDisabled: !tableState.selectedRows.length || !tableState.selectedRows.some(i => i.favorite === 'true'),
      },
      {
        icon: { type: 'messages' },
        title: 'История изменений',
        onClick: () => {
          if (tableState.selectedRows[0]) {
            loadModificationHistory(tableState.selectedRows[0].id);
          }
          setIsOpenHistoryModal(true);
        },
        isHidden: isNeedHideButton('history'),
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [
      isNeedHideButton,
      tableState,
      referenceMetadata?.addable,
      referenceMetadata?.editable,
      referenceMetadata?.fields,
      referenceMetadata?.deletable,
      onSubmitTable,
      tableStreams.submitTable,
      tableStreams.reloadTable,
      openEditor,
      setMode,
      loadReferenceFieldsValues,
      setEditableRowId,
      activeReferenceElement,
      name,
      favoriteReferenceElement,
      loadModificationHistory,
    ],
  );

  return {
    buttons,
    isOpenConfirmDeleteModal,
    setIsOpenConfirmDeleteModal,
    confirmDelete,
    historyEntries,
    selectedRowIndex,
    setSelectedRowIndex,
    isOpenHistoryModal,
    setIsOpenHistoryModal,
  };
}

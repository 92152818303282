import React from 'react';
import * as FormTabs from './Tabs';

import { FormComponent, Tabs, Tab, Toolbar } from 'components';

import { Documents } from 'features/Documents';
import { useController } from './controller';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { DataGrid } from 'features/Table';
import { GetEquipProjectList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function EquipForm({ viewMode, onClose }: Props) {
  const { equip, setEquip, buttons, statusOptions, userPermission } = useController({
    viewMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Об оборудовании" isVisible={isHasPermission(userPermission, Permits.EQUIP_DESCRIPTION_VIEW)}>
            <FormTabs.Description
              equip={equip}
              setEquip={setEquip}
              statusOptions={statusOptions}
              isDisabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_DESCRIPTION_EDIT))}
            />
          </Tab>
          <Tab title="Место установки" isVisible={isHasPermission(userPermission, Permits.EQUIP_LOCATION_VIEW)}>
            <FormTabs.Location
              equip={equip}
              setEquip={setEquip}
              isDisabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_LOCATION_EDIT))}
            />
          </Tab>
          <Tab title="Стоимость" isVisible={isHasPermission(userPermission, Permits.EQUIP_PURCHASE_VIEW)}>
            <FormTabs.Purchase
              equip={equip}
              setEquip={setEquip}
              isDisabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_PURCHASE_EDIT))}
            />
          </Tab>
          <Tab title="Использование в проектах" isVisible={isHasPermission(userPermission, Permits.EQUIP_PROJECTS_VIEW)}>
            <DataGrid specification={GetEquipProjectList({ equipId: equip.id || '-1' })} />
          </Tab>
          <Tab title="Метрологическое сопровождение" isVisible={isHasPermission(userPermission, Permits.EQUIP_METROLOGIC_VIEW)}>
            <FormTabs.Verification equip={equip} />
          </Tab>
          <Tab title="Документы" isVisible={isHasPermission(userPermission, Permits.EQUIP_DOCUMENTS_VIEW)}>
            <Documents
              documents={equip.documents}
              setDocuments={e => setEquip(prev => ({ ...prev, documents: e }))}
              disabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_DOCUMENTS_EDIT))}
              defaultSharedValue
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <em>Не реализовано</em>
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(EquipForm);

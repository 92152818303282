import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { DetailProps } from '..';

type Props = {
  tableState: Table.State;
};

export const useController = ({ tableState }: Props & DetailProps) => {
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: isViewButtonDisabled,
      },
    ],
    [isViewButtonDisabled, handleViewButtonClick],
  );

  return {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    buttons,
  };
};

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from '../../makeBackendAPIConfiguration';

import { convertCommonStatistic } from './converters';

export const statisticConfigurations = {
  GetCommonStatistics: makeBackendAPIConfiguration({
    id: 'GetCommonStatistics',
    endpoint: '/msa/service/commands/GetCommonStatistics',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetCommonStatistics',
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertCommonStatistic(response.Response.CommonStatistics),
  }),
};

import React, { memo } from 'react';

import { Document } from 'types/models';
import { Documents } from 'features/Documents';

type Props = {
  documents: Document[];
  setDocuments: React.Dispatch<Document[]>;
  addCallback: (document: Document, startEdit: (document: Document) => void) => void;
  editCallback: (document: Document, startEdit: () => void) => void;
  deleteCallback: (document: Document, startEdit: () => void) => void;
};

const ReadyReports = ({ documents, setDocuments, addCallback, editCallback, deleteCallback }: Props) => (
  <Documents
    documents={documents}
    setDocuments={setDocuments}
    addCallback={addCallback}
    editCallback={editCallback}
    deleteCallback={deleteCallback}
  />
);

export const Component = memo(ReadyReports);

import React, { useEffect } from 'react';

import { FormComponent } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Fields } from 'features/EditMipPartner/Tabs/Indicators/Fields';
import { getMockIndicator } from 'features/EditMipPartner/helpers';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
};

function MipIndicatorForm({ onClose }: Props) {
  const { indicator, setIndicator, onSubmit, workMode } = useController({
    onClose,
  });
  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.('Показатели результативности МИП по итогам года');
  }, [setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={workMode === 'viewMode'} />
      <FormComponent.Template>
        <Fields indicator={indicator ?? getMockIndicator()} setIndicator={setIndicator} workMode={workMode} isPartnerDisplayed />
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(MipIndicatorForm);

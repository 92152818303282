import React, { memo } from 'react';

import { Toolbar, RelationTableModal } from 'components';

import { Table as T } from 'types/models';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type WrapedProps = {
  handleOpenConfirm?: (confirmText: string) => void;
};

type Props = {
  tableState: T.State;
  customState: State;
};

export const ToolbarWrapper = ({ handleOpenConfirm }: WrapedProps) =>
  memo(({ tableState, customState }: Props) => {
    const { program, getProgram, isDisabled } = customState;

    const { buttons, handleCloseAddForm, isAddFormOpen, handleSubmitAddForm } = useController({
      tableState,
      program,
      getProgram,
      handleOpenConfirm,
      isDisabled,
    });

    return (
      <>
        <Toolbar buttons={buttons} />

        <RelationTableModal
          specification={{
            ...GetReferenceElementList({
              requestData: {
                filters: [],
                name: 'RefResultIndex',
              },
            }),
            onSubmitTable: handleSubmitAddForm,
          }}
          modalTitle="Показатели"
          isOpen={isAddFormOpen}
          onClose={handleCloseAddForm}
        />
      </>
    );
  });

import { Arm, Table } from 'types/models';
import { Filters } from './filters';
import { ProjectType } from 'utils/Enums';
import { makeUseCustomController } from './makeUseCustomController';
import { Component as Toolbar } from './Toolbar';

type Props = {
  type?: ProjectType | null;
  arm?: Arm;
};

type Arguments = {
  type?: ProjectType | null;
  arm?: Arm;
};

export function GetProjectRestrictionList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectRestrictionList',
    requestData: args,
    header: {
      firstLevel: {
        title: 'Контроль выполнения ограничений по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    useCustomController: makeUseCustomController({ arm: args?.arm }),
  };
}

import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectScopusWosPublicationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список публикаций Scopus, WoS по проектам</h4>

        <p>
          Форма <strong>Публикации Scopus, WoS по проектам</strong> содержит в табличном виде информацию о количестве публикаций
          по теме проекта цитируемых в базах Scopus, WoS. Описание столбцов таблицы на форме представлено в таблице.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Название поля</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Программа</td>
              <td>Краткое название программы, указанной в проекте</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>
                Номер проекта (на вкладке <strong>О проекте</strong>)
              </td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО руководителя проекта</td>
            </tr>
            <tr>
              <td>ПНР</td>
              <td>Приоритетные направления развития университета, указанные в проекте</td>
            </tr>
            <tr>
              <td>Количество публикаций Scopus/WoS</td>
              <td>
                {/* eslint-disable-next-line max-len */}
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science и на форме публикации на вкладке{' '}
                <strong>Финансовая поддержка</strong> указана связь с проектом
              </td>
            </tr>
            <tr>
              <td>Q1</td>
              <td>
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science с квартилем Q1 и на форме
                публикации на вкладке <strong>Финансовая поддержка</strong> указана связь с проектом.
              </td>
            </tr>
            <tr>
              <td>Q2</td>
              <td>
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science с квартилем Q2 и на форме
                публикации на вкладке <strong>Финансовая поддержка</strong> указана связь с проектом.
              </td>
            </tr>
            <tr>
              <td>Q3</td>
              <td>
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science с квартилем Q3 и на форме
                публикации на вкладке <strong>Финансовая поддержка</strong> указана связь с проектом.
              </td>
            </tr>
            <tr>
              <td>Q4</td>
              <td>
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science с квартилем Q4 и на форме
                публикации на вкладке <strong>Финансовая поддержка</strong> указана связь с проектом.
              </td>
            </tr>
            <tr>
              <td>Q0</td>
              <td>
                Количество публикаций, которые индексируются в базах Scopus и/или Web of Science с квартилем Q0 и на форме
                публикации на вкладке <strong>Финансовая поддержка</strong> указана связь с проектом.
              </td>
            </tr>
            <tr>
              <td>Фракционный счет по коллективу</td>
              <td>Фракционный счет университета в публикации (на форме публикации, вкладка Сведения об авторах)</td>
            </tr>
            <tr>
              <td>Фракционный счет по коллективу (Q1, Q2)</td>
              <td>
                Фракционный счет университета в публикации с квартилем Q1 и/или Q2 (на форме публикации, вкладка Сведения об
                авторах)
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { Settings } from 'types/models/common';

import { Arm, ForeignSecurityDocument, ResponseData } from 'types/models';
import { convertForeignSecurityDocument } from './converters';
import { convertForeignSecurityDocumentToXML } from './requestConverters';

const configurations = {
  GetForeignSecurityDocument: makeBackendAPIConfiguration({
    id: 'GetForeignSecurityDocument',
    endpoint: '/msa/service/commands/GetForeignSecurityDocument',
    convertInputToXMLElement: ({ id, arm }: { id?: string; arm?: Arm }) => ({
      Request: {
        _attr: { commandName: 'GetForeignSecurityDocument', workbench: arm },
        ForeignSecurityDocument: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertForeignSecurityDocument(response.Response.ForeignSecurityDocument, settings),
  }),
  SaveForeignSecurityDocument: makeBackendAPIConfiguration({
    id: 'SaveForeignSecurityDocument',
    endpoint: '/msa/service/commands/SaveForeignSecurityDocument',
    convertInputToXMLElement: (inputData: ForeignSecurityDocument) => ({
      Request: {
        _attr: {
          commandName: 'SaveForeignSecurityDocument',
        },
        ForeignSecurityDocument: { ...convertForeignSecurityDocumentToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  SavePersonalForeignSecurityDocument: makeBackendAPIConfiguration({
    id: 'SaveForeignSecurityDocument',
    endpoint: '/msa/service/commands/SaveForeignSecurityDocument',
    convertInputToXMLElement: (inputData: ForeignSecurityDocument) => ({
      Request: {
        _attr: {
          commandName: 'SavePersonalForeignSecurityDocument',
        },
        PersonalForeignSecurityDocument: { ...convertForeignSecurityDocumentToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ApproveForeignSecurityDocument: makeBackendAPIConfiguration({
    id: 'ApproveForeignSecurityDocument',
    endpoint: '/msa/service/commands/ApproveForeignSecurityDocument',
    convertInputToXMLElement: (args: { id: string; approve: boolean }) => ({
      Request: {
        _attr: {
          commandName: 'ApproveForeignSecurityDocument',
        },
        ForeignSecurityDocument: { _attr: { id: args?.id, disapprove: !args.approve } },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  SaveForeignSecurityDocumentUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveForeignSecurityDocumentUserFeedline',
    endpoint: '/msa/service/commands/SaveForeignSecurityDocumentUserFeedline',
    convertInputToXMLElement: (props: {
      labelId: string;
      messageId: string;
      message: string;
      foreignSecurityDocumentId: string;
    }) => {
      const feedlineAttr = props.messageId
        ? {
            _attr: {
              id: props.messageId,
            },
          }
        : {};

      return {
        Request: {
          _attr: {
            commandName: 'SaveForeignSecurityDocumentUserFeedline',
          },
          ForeignSecurityDocumentFeedline: {
            ...feedlineAttr,
            ...{
              Message: {
                _attr: {
                  id: props.labelId,
                },
              },
              Detail: props.message,
              ForeignSecurityDocument: {
                _attr: {
                  id: props.foreignSecurityDocumentId,
                },
              },
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): ResponseData => {
      const success = response.Response._attributes.success === 'true';
      const message = success
        ? ''
        : response.Response._attributes.errorMessage ||
          response.Response._attributes.serverErrorMessage ||
          'Произошла ошибка при сохранении';

      return { success, message };
    },
  }),
};

export const foreignSecurityDocumentConfiguration = { ...configurations };

import React, { useEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem, Select, SelectMode } from 'components';

import { getIsPending } from 'utils/Helpers/getIsPending';
import * as M from 'features/Table/specifications/GetReferenceElementList/model';

type Props = {
  field: M.EditableReferenceMetadataField;
  isDisabled: boolean;
  onChange(name: string, value: M.FieldValue): void;
  isRequired: boolean;
  isOpenEditor?: boolean;
};

export const ReferenceField = ({ field, isDisabled, onChange, isRequired, isOpenEditor }: Props) => {
  const { name, value, reference, caption, defaultReferenceFieldValue } = field;
  const { methods: getReferenceElements, state: getReferenceElementsState } = BackendAPI.useBackendAPI('GetReferenceElements');

  const isLoading = useMemo(() => getIsPending(getReferenceElementsState), [getReferenceElementsState]);

  useEffect(() => {
    const hasDefaultValue = defaultReferenceFieldValue && field.name === defaultReferenceFieldValue.fieldName;
    if (isOpenEditor && hasDefaultValue) {
      getReferenceElements.callAPI(
        {
          filters: [{ key: 'id', values: [defaultReferenceFieldValue.id] }],
          referenceName: defaultReferenceFieldValue.referenceName,
        },
        {
          onSuccessfullCall: ({ data }) => {
            onChange(name, data[0] ?? null);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpenEditor]);

  return (
    <Select
      mode={SelectMode.REFERENCE}
      value={value}
      onChange={(option: ReferenceItem | null) => onChange(name, option)}
      settings={{ name: reference, title: caption, isClearable: !isRequired }}
      isDisabled={isDisabled || isLoading || defaultReferenceFieldValue?.isDisabled}
    />
  );
};

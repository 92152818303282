import React, { useMemo } from 'react';

import { FormComponent, TextArea, TextAreaMode, TextDateTime, UploadFile } from 'components';

import { FileInfo, Table } from 'types/models';
import { ChooseProjectScientists } from './ChooseProjectScientists';
import { ProjectAssignment, Project } from 'types/models/Project';
import { Specification } from 'types/models/Table';
import { Original } from 'types/models/Form';

type Props = {
  workMode: Table.WorkMode;
  projectAssignment: ProjectAssignment;
  project: Project | null;
  handleProjectChange(value: Original | null): void;
  projectTableRowConverter(row: Table.Entry): Original;
  makeChangeHandler(key: keyof ProjectAssignment): any;
  projectSpecification: Specification<any>;
  onChangeFile(fileInfo: FileInfo): void;
};

function AboutProjectAssignment({
  workMode,
  projectAssignment,
  project,
  handleProjectChange,
  projectTableRowConverter,
  makeChangeHandler,
  projectSpecification,
  onChangeFile,
}: Props) {
  const disabled = useMemo(() => workMode === 'viewMode', [workMode]);

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Проект" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Проекты',
              table: {
                specification: projectSpecification,
                onSelect: (row: Table.Entry | null) => handleProjectChange(row ? projectTableRowConverter(row) : null),
              },
              isClearable: false,
            }}
            value={project?.id ? `${project?.number} "${project?.name}", (${project.startDate} - ${project.endDate})` : ''}
            isDisabled={!!project}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата поручения" isRequired>
          <TextDateTime value={projectAssignment.date} onChange={val => makeChangeHandler('date')(val)} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Поручение" isRequired>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Поручение' }}
            value={projectAssignment.assignment}
            onChange={makeChangeHandler('assignment')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Выполнить до">
          <TextDateTime
            value={projectAssignment.upToDate}
            onChange={val => makeChangeHandler('upToDate')(val)}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ответственные лица" isRequired>
          <ChooseProjectScientists
            performers={project?.performers || []}
            chosenPerformers={projectAssignment.projectScientists || []}
            disabled={disabled}
            setChosenPerformers={makeChangeHandler('projectScientists')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Прикрепите файл">
          <UploadFile file={projectAssignment?.file} onChange={onChangeFile} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

export const Component = React.memo(AboutProjectAssignment);

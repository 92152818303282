import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  const options = [0, 1, 2, 3].map(x => ({ label: (x + 1).toString(), value: x.toString() }));

  return FiltersComponent([
    {
      type: FilterComponentType.text,
      value: 'Год:',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.input,
      streamKey: 'year',
      isYearMask: true,
    },
    {
      type: FilterComponentType.text,
      value: 'Квартал:',
    },
    {
      type: FilterComponentType.select,
      streamKey: 'quarterFrom',
      options,
      value: options[0],
    },
    {
      type: FilterComponentType.text,
      value: '-',
    },
    {
      type: FilterComponentType.select,
      streamKey: 'quarterTo',
      options,
      value: options[options.length - 1],
    },
  ]);
});

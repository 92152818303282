import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export type DetailProps = {
  isRequestMode?: boolean;
};
export function GetPersonalSecurityDocumentList({ isRequestMode }: DetailProps): T.Specification<State> {
  return {
    apiID: 'GetPersonalSecurityDocumentList',
    header: {
      firstLevel: {
        title: isRequestMode
          ? 'Заявки на получение патента или свидетельства'
          : 'Мои патенты, свидетельства, секреты производства, объекты авторского права',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar({ isRequestMode }),
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
    useCustomController: makeUseCustomController({ isRequests: isRequestMode }),
  };
}

export enum AuthCheckTypeEnum {
  NAME = 'name',
  LOGIN = 'login',
  PASSWORD = 'password',
  EMAIL = 'email',
}

type Rule = {
  regex: RegExp;
  match?: number;
  message: string;
};

const authRegExpList: Record<string, Rule[]> = {};

authRegExpList[AuthCheckTypeEnum.NAME] = [
  {
    regex: /^[а-яa-z]*$/i,
    message: 'Только символы любого регистра русского и латинского алфавита',
  },
  {
    regex: /^.{2,40}$/,
    message: 'Длинна должна быть в диапазоне 2-40 символов',
  },
];

authRegExpList[AuthCheckTypeEnum.LOGIN] = [
  {
    regex: /^[a-z0-9_]*$/i,
    message: 'Только символы любого регистра латинского алфавита и цифры 0-9 и _',
  },
  {
    regex: /^.{4,127}$/,
    message: 'Длинна должна быть в диапазоне 4-127 символов',
  },
];

authRegExpList[AuthCheckTypeEnum.PASSWORD] = [
  {
    regex: /([0-9a-zа-я]+)/gi, // match replace
    match: -1, // replace all matches
    message: 'Содержит недопустимые символы - ',
  },
  {
    regex: /^[0-9a-zа-я]*$/i,
    message: 'Только символы любого регистра латинского алфавита и цифры в диапазоне 0-9',
  },
  {
    regex: /[0-9]/g,
    match: 2,
    message: 'Должен содержать не менее чем 2 цифры в диапазоне 0-9',
  },
  {
    regex: /[a-zа-я]/g,
    match: 2,
    message: 'Должен содержать не менее чем 2 символа нижнего регистра русского и/или латинского алфавита',
  },
  {
    regex: /[A-ZА-Я]/g,
    match: 2,
    message: 'Должен содержать не менее чем 2 символа верхнего регистра русского и/или латинского алфавита',
  },
  {
    regex: /^.{5,255}$/,
    message: 'Длинна должна быть в диапазоне 5-255 символов',
  },
];

authRegExpList[AuthCheckTypeEnum.EMAIL] = [
  {
    regex: /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i,
    message: 'Ошибки в строке email',
  },
];

export { authRegExpList };

import { Icon } from 'components';

export function PersonalConcertEventGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Творческие мероприятия</strong> предназначен для поддержки процессов организационно-технического и
        финансового сопровождения творческих мероприятий университета: концертов, встреч, фестивалей и проч.
      </p>

      <h4>В разделе Творческие мероприятия вы можете:</h4>

      <ul>
        <li>
          Просмотреть информацию о мероприятии, участниках, место и время проведения с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новое мероприятие с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать мероприятие в статусе Добавлена с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Удалить запись о мероприятии, которую вы создавали, если мероприятие в статусе Добавлена с помощью кнопки{' '}
          <Icon type="remove" />
        </li>
        <li>
          Выгрузить список мероприятий в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Добавить информацию об участниках мероприятия, указать связь с подразделением и информацию о полученных наградах на
          вкладке «Участники»
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи о мероприятии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит мероприятие.
      </p>
      <p>
        Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к заполнению.
      </p>
      <p>
        Для добавления участия в мероприятии, организованного другой организацией, необходимо заполнить обязательные поля на
        вкладке "О мероприятии" и прикрепить Программу или информационное письмо на вкладке "Документы".
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои мероприятия»</strong> содержит список мероприятий, в которых вы указаны в составе участников.
        </li>
        <li>
          <strong>Фильтр «Мероприятия {organizationName}»</strong> содержит список мероприятий, в которых организатором или
          соорганизатором является Университет.
        </li>
        <li>
          <strong>Фильтр «Другие мероприятия»</strong> показывает список мероприятий других организаций, в которых принимают
          участие сотрудники, аспиранты и студенты Университета.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список мероприятий, сроки проведения которых содержат указанный год. По
          умолчанию выбран текущий год.
        </li>
      </ul>

      <p>Добавленное вами мероприятие проверит и утвердит Администратор данных.</p>
      <div>
        Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору <Icon type="admin" />
      </div>
    </>
  );
}

import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { GetConferenceEventList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { DefaultCustomSelect } from '../DefaultCustomSelect';
import { BuilderProps } from 'features/BuildReportPopup/models';

const EventCustomSelect = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification = {
    modalTableSpecification: GetConferenceEventList({}),
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      label: row.EventName || '',
      id: row.id || '',
    }),
    relationTableModalTitle: 'Справочник "Мероприятия"',
  };

  return (
    <DefaultCustomSelect
      parameterName={parameterName}
      placeholder="Выберите мероприятие из справочника"
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      modalTableRowConverter={specification.modalTableRowConverter}
      modalTableSpecification={specification.modalTableSpecification}
      relationTableModalTitle={specification.relationTableModalTitle}
    />
  );
};

const Component = memo(EventCustomSelect);

export { Component as EventCustomSelect };

import React from 'react';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';
import { RelationsModal } from '../RelationsModal';
import { useController } from './controller';
import { PartnerGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ customState, tableState }: Props) {
  const {
    buttons,
    isOpenConfirmDeletePartner,
    setIsOpenConfirmDeletePartner,
    deletePartner,
    row,
    isOpenApproveInfoModal,
    setMode,
    loadPartner,
    setIsOpenApproveInfoModal,
    isOpenRelationsForm,
    closeRelationsModal,
    partner,
    openForm,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  } = useController({ tableState, customState });

  const { settings } = useAppDataContext();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDeletePartner}
        onClose={() => setIsOpenConfirmDeletePartner(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmDeletePartner(false);
              deletePartner({ partnerId: row.id, type: row.Type, fullName: row.FullName, shortName: row.ShortName });
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDeletePartner(false),
          },
        ]}
        size="small"
      >
        <>
          ({tableState.selectedRows[0]?.ShortName}) ({tableState.selectedRows[0]?.Relation}) Удалить организацию из списка
          организаций-партнёров?
        </>
      </Modal>

      <Modal
        mode="info"
        title={`Подтверждение статуса организации - "Партнёр ${settings?.organization?.shortName}"`}
        isOpen={isOpenApproveInfoModal}
        onClose={() => setIsOpenApproveInfoModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              if (row) {
                setMode('edit');
                loadPartner(row.id, openForm, row.Type);
                setIsOpenApproveInfoModal(false);
              }
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenApproveInfoModal(false),
          },
        ]}
        size="small"
      >
        <>Для подтверждения статуса необходимо актуализировать данные по организации</>
      </Modal>

      <RelationsModal isOpen={isOpenRelationsForm} onClose={closeRelationsModal} partner={partner} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
      />

      <Modal
        mode="help"
        title={'Помощь в разделе "Cписок организаций"'}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PartnerGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import * as helpers from 'features/Form/looks/project/ProjectForm/helpers';

import { ButtonMode, ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Project, Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Mode } from '.';
import { showNotification } from 'features/Notifications';
import { validate } from './modalTemplate/JobPeriods/validate';
import { Permits } from 'utils/Permissions';
import { ProjectType } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { SaveProjectScientistsJobPeriodType } from '../types';
import { getMockSaveProjectScientistsJobPeriodArgs } from '../helpers';

type Props = {
  projectId: string;
  tableState: Table.State;
  reloadProject(): void;
  isProjectChanged?: boolean;
  projectType: ProjectType;
  isDisabled: boolean;
};

export function useController({ projectId, tableState, reloadProject, isProjectChanged, projectType, isDisabled }: Props) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const selectedRows = tableState.selectedRows;

  const [mode, setMode] = useState<Mode>('add');
  const [performer, setPerformer] = useState<Project.Performer>(helpers.getMockPerformer());
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenDeleteConfirmPopup, setIsOpenDeleteConfirmPopup] = useState(false);
  const [isProjectChangedOld, setIsProjectChangedOld] = useState<boolean>(false);
  const [
    saveProjectScientistsJobPeriodArgs,
    setSaveProjectScientistsJobPeriodArgs,
  ] = useState<SaveProjectScientistsJobPeriodType>(getMockSaveProjectScientistsJobPeriodArgs());
  const [isOpenSaveProjectScientistsJobPeriod, setIsOpenSaveProjectScientistsJobPeriod] = useState<boolean>(false);
  const [invalidScientists, setInvalidScientists] = useState<string[]>([]);
  const [isOpenInvalidScientists, setIsOpenInvalidScientists] = useState<boolean>(false);
  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);

  const { methods: getProjectScientist } = BackendAPI.useBackendAPI('GetProjectScientist');
  const { methods: saveProjectScientist } = BackendAPI.useBackendAPI('SaveProjectScientist');
  const { methods: deleteProjectScientist } = BackendAPI.useBackendAPI('DeleteProjectScientist');
  const { methods: saveProjectScientistsJobPeriodAPI } = BackendAPI.useBackendAPI('SaveProjectScientistsJobPeriod');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsOpenDeleteConfirmPopup(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteProjectScientist.callAPI(
      {
        ids: selectedRows.map(({ id }) => id),
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Выбранные элементы успешно удалены', theme: 'success' });
          tableStreams.reloadTable.push({});
          reloadProject();
        },
      },
    );
    handleCloseDeleteConfirmPopup();
  }, [deleteProjectScientist, handleCloseDeleteConfirmPopup, reloadProject, selectedRows, tableStreams.reloadTable]);

  const reports = useMemo<Report[]>(
    () => [
      Reports.PerformersProject,
      Reports.СompositionScientificProjectPeriod,
      Reports.ScienceProjectPerformerCompositionMemo,
      Reports.ScienceProjectPerformerCompositionMemoBySalary,
      Reports.СompositionBySalary,
      Reports.ProjectsViolateTeamRequirements_3_Months,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleDeleteButtonClick = useCallback(() => {
    setIsOpenDeleteConfirmPopup(true);
  }, []);

  const open = useCallback(
    (modalMode: Mode) => {
      setMode(modalMode);

      if (modalMode === 'add') {
        setPerformer(helpers.getMockPerformer());
      } else {
        getProjectScientist.callAPI(
          {
            id: selectedRows[0]?.id,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setPerformer(data);
            },
          },
        );
      }

      setIsOpen(true);
    },
    [getProjectScientist, selectedRows],
  );

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const saveProjectScientistsJobPeriod = useCallback(() => {
    saveProjectScientistsJobPeriodAPI.callAPI(
      { ...saveProjectScientistsJobPeriodArgs, projectScientists: selectedRows.map(row => row.id) },
      {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Периоды работы по приказу оформлены', theme: 'success' });
          setIsOpenSaveProjectScientistsJobPeriod(false);
          setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());

          if (data.length) {
            setInvalidScientists(data);
            setIsOpenInvalidScientists(true);
          }

          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [saveProjectScientistsJobPeriodAPI, saveProjectScientistsJobPeriodArgs, selectedRows, tableStreams.reloadTable]);

  const viewPermits = useMemo<Record<ProjectType, string>>(
    () => ({
      [ProjectType.ECONOMIC_AGREEMENT]: Permits.ECONOMIC_CONTRACT_EXECUTORS_VIEW,
      [ProjectType.GOVERMENT_CONTRACT]: Permits.GOV_CONTRACT_EXECUTORS_VIEW,
      [ProjectType.GRANT]: Permits.GRANT_EXECUTORS_VIEW,
      [ProjectType.NIR]: Permits.RESEARCH_JOB_EXECUTORS_VIEW,
    }),
    [],
  );

  const editPermits = useMemo<Record<ProjectType, string>>(
    () => ({
      [ProjectType.ECONOMIC_AGREEMENT]: Permits.ECONOMIC_CONTRACT_EXECUTORS_EDIT,
      [ProjectType.GOVERMENT_CONTRACT]: Permits.GOV_CONTRACT_EXECUTORS_EDIT,
      [ProjectType.GRANT]: Permits.GRANT_EXECUTORS_EDIT,
      [ProjectType.NIR]: Permits.RESEARCH_JOB_EXECUTORS_EDIT,
    }),
    [],
  );

  const editApprovedPermits = useMemo<Record<ProjectType, string>>(
    () => ({
      [ProjectType.ECONOMIC_AGREEMENT]: Permits.ECONOMIC_CONTRACT_EXECUTORS_APPROVED_EDIT,
      [ProjectType.GOVERMENT_CONTRACT]: Permits.GOV_CONTRACT_EXECUTORS_APPROVED_EDIT,
      [ProjectType.GRANT]: Permits.GRANT_EXECUTORS_APPROVED_EDIT,
      [ProjectType.NIR]: Permits.RESEARCH_JOB_EXECUTORS_APPROVED_EDIT,
    }),
    [],
  );

  const getIsEditPermitted = useCallback(
    () =>
      selectedRows[0]?.Approvements
        ? isHasPermission(userPermission, editApprovedPermits[projectType])
        : isHasPermission(userPermission, editPermits[projectType]),
    [editApprovedPermits, editPermits, projectType, selectedRows, userPermission],
  );

  const title = useMemo(() => {
    return [
      [({ add: 'Добавление', edit: 'Редактирование', view: 'Просмотр' } as Record<Mode, string>)[mode], 'данных']
        .filter(Boolean)
        .join(' '),
      mode === 'add' ? '' : `: ${performer.fio}`,
    ]
      .filter(Boolean)
      .join('');
  }, [mode, performer]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpModal(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => open('view'),
        permission: { name: viewPermits[projectType] },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => open('add'),
        permission: { name: editPermits[projectType] },
        isDisabled: isDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => open('edit'),
        permission: { name: [editPermits[projectType], editApprovedPermits[projectType]] },
        isDisabled: isDisabled || selectedRows.length !== 1 || !getIsEditPermitted(),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: [editPermits[projectType], editApprovedPermits[projectType]] },
        isDisabled: isDisabled || selectedRows.length !== 1 || !getIsEditPermitted(),
      },
      {
        icon: { type: 'merge', mode: 'info' },
        title: 'Оформить периоды работы по приказу',
        onClick: () => setIsOpenSaveProjectScientistsJobPeriod(true),
        isDisabled: !selectedRows.length,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      viewPermits,
      projectType,
      selectedRows.length,
      editPermits,
      isDisabled,
      editApprovedPermits,
      getIsEditPermitted,
      handleDeleteButtonClick,
      getReports,
      handleSetCurrentReport,
      open,
    ],
  );

  const onSave = useCallback(
    (needClose: boolean) => {
      if (mode === 'add') {
        const validateResult = validate(performer.jobPeriods[0] || null, performer.person || null);

        if (!validateResult.every(x => x.isValid)) {
          validateResult.map(i => {
            showNotification({ message: i.invalidMessage, theme: 'danger' });
          });
          return;
        }
      }

      saveProjectScientist.callAPI(
        {
          id: performer.id || '',
          projectId,
          performer,
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: `Элемент успешно ${mode === 'add' ? 'добавлен' : 'изменен'}`, theme: 'success' });
            if (needClose) {
              onClose();
            } else {
              setPerformer(prev => ({ ...prev, person: null }));
            }
            tableStreams.reloadTable.push({});
            reloadProject();
          },
        },
      );
    },
    [mode, onClose, performer, projectId, reloadProject, saveProjectScientist, tableStreams.reloadTable],
  );

  const modalButtons: ButtonProps[] = [
    {
      text: 'Сохранить',
      onClick: () => onSave(true),
      isHidden: mode === 'view',
      mode: ButtonMode.PRIMARY,
    },
    ...(mode === 'add'
      ? [
          {
            text: 'Сохранить и добавить следующего участника',
            onClick: () => onSave(false),
            mode: ButtonMode.PRIMARY,
          },
        ]
      : [
          {
            text: 'Закрыть форму без сохранения',
            onClick: () => onClose(),
            isHidden: mode === 'view',
            mode: ButtonMode.SECONDARY,
          },
        ]),
  ];

  useLayoutEffect(() => {
    if (!isProjectChanged && !!isProjectChangedOld) tableStreams.reloadTable.push({});
    if (!!isProjectChanged !== !!isProjectChangedOld) setIsProjectChangedOld(!!isProjectChanged);
  }, [isProjectChanged, isProjectChangedOld, tableStreams.reloadTable]);

  return {
    mode,
    title,
    isOpen,
    onClose,
    performer,
    setPerformer,
    isOpenDeleteConfirmPopup,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    modalButtons,
    isReportOpen,
    onReportClose,
    currentReport,
    saveProjectScientistsJobPeriodArgs,
    setSaveProjectScientistsJobPeriodArgs,
    isOpenSaveProjectScientistsJobPeriod,
    setIsOpenSaveProjectScientistsJobPeriod,
    saveProjectScientistsJobPeriod,
    invalidScientists,
    setInvalidScientists,
    isOpenInvalidScientists,
    setIsOpenInvalidScientists,
    isOpenHelpModal,
    setIsOpenHelpModal,
  };
}

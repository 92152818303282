import React from 'react';

import { Modal, Toolbar as SharedToolbar, Tab, Tabs } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { MessageModal } from 'features/Table/specifications/GetProjectFeedlineList/Toolbar/MessageModal';
import { getMockFeedline } from 'features/Table/specifications/GetProjectFeedlineList/helpers';
import { useController } from './controller';
import { ProjectCodeChangesInProject } from 'features/Form/views';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  onSave(): void;
  onSaveAndContinue(): void;
  projectId: string | null;
  projectNumber?: string;
  hidden: boolean;
};

function Toolbar(props: Props) {
  const { onSave, projectId, onSaveAndContinue, hidden, projectNumber } = props;

  const {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isOpenCodeChangesModal,
    toggleVisibleCodeChangesModal,
    toggleVisibleMessageModal,
    feedline,
    isOpenMessageModal,
    setFeedline,
    handleSubmitMessageModal,
    currentReport,
    isReportOpen,
    onReportClose,
  } = useController({ onSave, projectId, onSaveAndContinue, hidden });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <ProjectCodeChangesInProject
        isOpen={isOpenCodeChangesModal}
        onClose={toggleVisibleCodeChangesModal}
        projectId={projectId}
        projectNumber={projectNumber}
        isProcessButtonHidden
      />

      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={toggleVisibleMessageModal}
        feedline={feedline || getMockFeedline()}
        changeFeedline={setFeedline}
        onSubmit={handleSubmitMessageModal}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: projectId || '',
        }}
      />

      <Modal
        mode="help"
        title="Особенности заполнения информации о проекте на вкладках формы"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size={['extralarge', 'large-height']}
      >
        <Tabs>
          <Tab title="Введение">{ProjectHelp().common}</Tab>
          <Tab title="О проекте">{ProjectHelp().about}</Tab>
          <Tab title="Заявки">{ProjectHelp().requests}</Tab>
          <Tab title="Особенности">{ProjectHelp().features}</Tab>
          <Tab title="Описание">{ProjectHelp().description}</Tab>
          <Tab title="Классификаторы">{ProjectHelp().classifiers}</Tab>
          <Tab title="Госрегистрация">
            <h4>Вкладка Госрегистрация проекта</h4>
            {ProjectHelp().registrationProject}
            <h4>Вкладка Госрегистрация отчётов</h4>
            {ProjectHelp().registrationReports}
          </Tab>
          <Tab title="Коллектив">
            <h4>Вкладка Состав коллектива</h4>
            {ProjectHelp().executorsComposition}
            <h4>Вкладка Приказы по коллективу</h4>
            {ProjectHelp().executorsOrders}
          </Tab>
          <Tab title="Партнёры">{ProjectHelp().partners}</Tab>
          <Tab title="Этапы">
            <h4>Вкладка Этапы проекта</h4>
            {ProjectHelp().planStages}
            <h4>Список отчётов проекта</h4>
            {ProjectHelp().planReports}
          </Tab>
          <Tab title="Результаты">
            <h4>Вкладка Индикаторы и показатели</h4>
            {ProjectHelp().resultsIndicators}
            <h4>Вкладка Публикации</h4>
            {ProjectHelp().resultsPublications}
            <h4>Вкладка Диссертации и авторефераты</h4>
            {ProjectHelp().resultsDissertations}
            <h4>Вкладка Мероприятия и участие в мероприятии</h4>
            {ProjectHelp().resultsEvents}
            <h4>Вкладка Интеллектуальная собственность</h4>
            {ProjectHelp().resultsSecurityDocuments}
          </Tab>
          <Tab title="Документы">
            <h4>Вкладка Документы</h4>
            {ProjectHelp().documentsAbout}
            <h4>Вкладка АКТы</h4>
            {ProjectHelp().documentsActs}
          </Tab>
          <Tab title="Поручения">{ProjectHelp().assignments}</Tab>
          <Tab title="Закупки">{ProjectHelp().purchases}</Tab>
        </Tabs>
      </Modal>
    </>
  );
}

export { Toolbar };

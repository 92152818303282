import React, { memo } from 'react';

import { Form as F } from 'types/models';

import { FormComponent, SectionTitle, TextArea, TextAreaMode, TextInput } from 'components';

type Props = {
  formFields: F.Fields;
  viewMode?: boolean;
  foreignSource: string;
};

const AboutEnglish = ({ viewMode, formFields, foreignSource }: Props) => (
  <>
    <SectionTitle title="О публикации на английском языке" />

    <FormComponent.Line>
      <FormComponent.Field label={formFields.foreignName.title}>
        <TextArea
          settings={{ rows: 3 }}
          value={formFields.foreignName.value}
          onChange={formFields.foreignName.onChange}
          isDisabled={!!viewMode}
        />
      </FormComponent.Field>
    </FormComponent.Line>

    <FormComponent.Line>
      <FormComponent.Field label="Источник">
        <span>{foreignSource || 'не указан'}</span>
      </FormComponent.Field>
    </FormComponent.Line>

    <FormComponent.Line>
      <FormComponent.Field label={formFields.foreignAnnotation.title}>
        <TextArea
          mode={TextAreaMode.MODAL}
          settings={{ rows: 3, title: formFields.foreignAnnotation.title }}
          value={formFields.foreignAnnotation.value}
          onChange={formFields.foreignAnnotation.onChange}
          isDisabled={!!viewMode}
        />
      </FormComponent.Field>
    </FormComponent.Line>
    <FormComponent.Line>
      <FormComponent.Field label={formFields.foreignAuthorsKeyWords.title}>
        <TextInput
          value={formFields.foreignAuthorsKeyWords.value}
          onChange={formFields.foreignAuthorsKeyWords.onChange}
          isDisabled={!!viewMode}
        />
      </FormComponent.Field>
    </FormComponent.Line>

    <FormComponent.Line>
      <FormComponent.Field label={formFields.foreignAuthors.title}>
        <TextArea
          settings={{ rows: 3 }}
          value={formFields.foreignAuthors.value}
          onChange={formFields.foreignAuthors.onChange}
          isDisabled={!!viewMode}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  </>
);

export const AboutEnglishComponent = memo(AboutEnglish);

import React, { memo, useCallback } from 'react';

import { FormComponent, ReferenceFilter, ReferenceItem, Select, SelectMode } from 'components';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  referenceName: string;
  relationTableModalTitle: string;
  referenceFilter: ReferenceFilter[];
};

const ReferenceSelect = ({
  referenceItem,
  setReferenceItem,
  fieldLabel,
  referenceName,
  relationTableModalTitle,
  referenceFilter,
}: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      if (nextReferenceItem) {
        setReferenceItem(nextReferenceItem);
      }
    },
    [setReferenceItem],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label={fieldLabel} isRequired>
        <Select
          mode={SelectMode.REFERENCE}
          value={referenceItem}
          onChange={handleSetReferenceItem}
          settings={{ name: referenceName, title: relationTableModalTitle, filters: referenceFilter }}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
};

const Component = memo(ReferenceSelect);

export { Component as ReferenceSelect };

import React from 'react';

import { Tabs, Tab } from 'components';

import { Person, UserPermission } from 'types/models';
import { ValueOf } from 'types/helpers';
import { EditableHonor } from 'features/Form/looks/person/model';
import { HirshIndexes } from './HirshIndexes';
import { ScientistHonors } from './ScientistHonors';
import { Identifiers } from './Identifiers';
import { FIO } from './FIO';
import { Changes } from './Changes';
import { Affiliations } from './Affiliations';
import { EditEducations, EditJobs } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  disabled: boolean;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
  onChangeHonors(item: EditableHonor[]): void;
  onChangeHirshIndexes(indexes: Person.HirshIndex[]): void;
  onUpdate(): void;
};

function Component(props: Props) {
  const { person, userPermission, disabled, onChangeHonors, makeSetPerson, onChangeHirshIndexes, onUpdate } = props;

  return (
    <Tabs>
      <Tab title="Сведения о работе">
        <EditJobs
          mode="person"
          disabled={disabled}
          person={person}
          onChangeJobs={makeSetPerson('scientistJobs')}
          onUpdate={onUpdate}
        />
      </Tab>
      <Tab title="Сведения об обучении">
        <EditEducations
          mode="person"
          disabled={disabled}
          person={person}
          onChangeEducations={makeSetPerson('scientistEducations')}
          onUpdate={onUpdate}
        />
      </Tab>
      <Tab title="Ученые степени и звания">
        <ScientistHonors
          disabled={disabled}
          userPermission={userPermission}
          person={person}
          onChangeHonors={onChangeHonors}
          onUpdate={onUpdate}
        />
      </Tab>
      <Tab title="Идентификаторы в базах цитирования">
        <Identifiers disabled={disabled} person={person} onChangeIdentifiers={makeSetPerson('identifiers')} />
      </Tab>
      <Tab title="Библиографические ФИО">
        <FIO
          disabled={disabled}
          person={person}
          userPermission={userPermission}
          onChangeFIO={makeSetPerson('bibliographicNames')}
        />
      </Tab>
      <Tab title="Аффилиации">
        <Affiliations person={person} />
      </Tab>
      <Tab title="Изменения ФИО" isVisible={!!person?.changes.length}>
        <Changes person={person} />
      </Tab>
      <Tab title="Индекс Хирша">
        <HirshIndexes disabled={disabled} person={person} onChangeHirshIndexes={onChangeHirshIndexes} />
      </Tab>
    </Tabs>
  );
}

export const AdditionalInfo = React.memo(Component);

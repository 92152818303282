import { Form, Event } from 'types/models';
import { Notification } from 'features/Notifications';
import { getError } from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export function validate(formFields: Form.Fields) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };
  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  return result;
}

export function compilationAndReleaseValidate(
  formFields: Form.Fields,
  magazineReleases: Event.Source[],
  compilations: Event.Source[],
) {
  const result = {
    isValid: true,
    helpText: '',
  };
  if (formFields.isMagazinePlanned.value && !magazineReleases.length) {
    result.helpText =
      // eslint-disable-next-line
      'Вы можете добавить выпуск журнала на вкладке "О мероприятии" в разделе "Опубликованные материалы конференции в специальных выпусках журнала"';
    result.isValid = false;
  }
  if (formFields.isCompilationPlanned.value && !compilations.length) {
    // eslint-disable-next-line
    result.helpText = 'Вы можете добавить сборник на вкладке "Опубликованные материалы"';
    result.isValid = false;
  }
  return result;
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Community } from 'types/models';

import { Settings } from 'types/models/common';
import { convertCommunity } from './converters';
import { convertCommunityToXML } from './requestConverters';

const configurations = {
  GetCommunity: makeBackendAPIConfiguration({
    id: 'GetCommunity',
    endpoint: '/msa/service/commands/GetCommunity',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetCommunity' },
        Community: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertCommunity(response.Response.Community, settings),
  }),
  SaveCommunity: makeBackendAPIConfiguration({
    id: 'SaveCommunity',
    endpoint: '/msa/service/commands/SaveCommunity',
    convertInputToXMLElement: (inputData: Community) => ({
      Request: {
        _attr: {
          commandName: 'SaveCommunity',
        },
        Community: { ...convertCommunityToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  CloneCommunity: makeBackendAPIConfiguration({
    id: 'CloneCommunity',
    endpoint: '/msa/service/commands/CloneCommunity',
    convertInputToXMLElement: ({ id, orderNumber, orderDate }: { id: string; orderNumber: string; orderDate: string }) => ({
      Request: {
        _attr: { commandName: 'CloneCommunity' },
        Prototype: { _attr: { id } },
        ...(orderNumber ? { OrderNumber: orderNumber } : {}),
        ...(orderDate ? { OrderDate: orderDate } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertCommunity(response.Response.Community, settings),
  }),
};

export const communitiesConfigurations = { ...configurations };

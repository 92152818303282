import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function NonRegisteredSecurityDocumentGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Объекты авторского права</h4>

        <p>Таблица содержит список объектов авторского права (ОАП).</p>

        <p>
          Таблица содержит столбцы с атрибутами охранного документа и дополнительными данными, идентифицирующими проект, в рамках
          которого был получен РИД.
        </p>

        <div className="warning">
          <span>
            Система фиксирует: кто и когда создал запись, кто и когда редактировал её. В таблице для отображения этой информации
            служат столбцы: Добавлено, Дата добавления, Изменено, Дата изменения.
          </span>
        </div>

        <p>
          <strong>Предоставлено в году</strong> — отображает записи, у которых есть &lt;Номер документа&gt; и значение поля
          &lt;Предоставлено в распоряжение&gt; соответствуют году фильтра;
          <br />
          <strong>Действует в году</strong> — отображает записи, у которых период действия пересекается с указанным годом.
        </p>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись; открывает форму с данными о документе для просмотра. Состав формы
                зависит от наличия у пользователя <strong>прав на просмотр вкладок</strong> формы.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Кнопка активна если у пользователя есть право добавления записи.</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись; открывает форму с данными о документе для редактирования. Состав
                формы зависит от наличия у пользователя <strong>прав на просмотр и редактирования вкладок</strong> формы.
                <br />
                Если запись открыта для редактирования, то система не позволит открыть эту запись для другого пользователя и
                выведет на экран сообщение.
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись.
                <br />
                Запись, у которой есть связи с другими объектами (договором, РИД и пр.) система не позволит удалить.
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                Инструмент предназначен для построения отчёта по шаблону. При нажатии кнопки появится список доступных
                пользователю отчётов (при условии, что у пользователя есть право на группу отчётов).
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import { User } from 'types/models/User';
import * as BackendAPI from 'services/BackendAPI';
import { useCallback } from 'react';
import { useAppDataContext } from 'features/AppData/context';
import { createUserOnPortal, loadPortalRoles } from 'utils/Helpers/portalUserMethods';
import { showNotification } from 'features/Notifications';

export const REG_STATUS_NAME = 'REGISTRATION';
export const USER_WB_NAMES = ['system', 'pc'];

export function useController() {
  const { methods: activateUser } = BackendAPI.useBackendAPI('ActivateUser');

  const { userToken } = useAppDataContext();

  const changeUserStatus = useCallback(
    (selectedUser: User | null, isActive: boolean, onSuccess?: () => void) => {
      if (selectedUser?.status?.value === REG_STATUS_NAME && isActive) {
        loadPortalRoles(userToken)
          .then((roles: any) => {
            createUserOnPortal(
              {
                login: selectedUser?.login,
                name: selectedUser?.userFullName,
                password: selectedUser?.password,
                roles: roles
                  .filter((r: any) => r.workbenches.length === 1 && USER_WB_NAMES.indexOf(r.workbenches[0].workbench) > -1)
                  .map((r: any) => ({ id: r.id })),
              },
              userToken,
            )
              .then(() => {
                activateUser.callAPI(
                  { login: selectedUser?.login || '', activate: isActive },
                  {
                    onSuccessfullCall: () => {
                      onSuccess?.();
                    },
                  },
                );
              })
              .catch(() => {
                showNotification({ message: 'Ошибка при создании пользователя', theme: 'danger' });
              });
          })
          .catch(() => {
            showNotification({ message: 'Ошибка при получении списка ролей', theme: 'danger' });
          });
      } else {
        activateUser.callAPI(
          { login: selectedUser?.login || '', activate: isActive },
          {
            onSuccessfullCall: () => {
              onSuccess?.();
            },
          },
        );
      }
    },
    [activateUser, userToken],
  );

  return {
    changeUserStatus,
  };
}

import { useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form } from 'types/models';

type Props = {
  id: string;
};

const useController = ({ id }: Props) => {
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);

  const { methods: GetPublication } = BackendAPI.useBackendAPI('GetPublication');

  useEffect(() => {
    GetPublication.callAPI(
      {
        simpleFields: {
          status: '',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        mobileRequests: { mobileRequest: [] },
        projects: [],
        id,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setPublicationInfo(data);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    publicationInfo,
  };
};

export default useController;

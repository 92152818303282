import React from 'react';

import { Toolbar as SharedToolbar, RelationTableModal } from 'components';

import { Table } from 'types/models';
import { PortfolioCard } from 'features/Form/looks/person';
import { GetPartnerAffilatedPersonPublicationList } from '../..';
import { State } from '../makeUseCustomController';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
  customState: State;
};
function Toolbar({ tableState, customState }: Props) {
  const {
    buttons,
    isOpenModal,
    closeModal,
    isOpenPersonModal,
    togglePersonModal,
    enterpriseCustomerIds,
    enterpriseIds,
    scientistId,
  } = useController({
    tableState,
    customState,
  });

  const [row] = tableState.selectedRows;

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <PortfolioCard id={row?.id ?? null} isOpen={isOpenPersonModal} onClose={togglePersonModal} />

      <RelationTableModal
        isOpen={isOpenModal}
        onClose={closeModal}
        modalTitle="Публикации"
        specification={GetPartnerAffilatedPersonPublicationList({
          enterpriseCustomerIds,
          enterpriseIds,
          scientistId: scientistId ?? '',
        })}
      />
    </>
  );
}

export { Toolbar };

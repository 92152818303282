import React from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { NotificationHelp } from './help';
import { NotificationEditTemplate } from './modalTemplates';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen, isFormOpen, setIsFormOpen } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Подсистема уведомлений"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size={['large', 'medium-height']}
      >
        {NotificationHelp()}
      </Modal>

      <NotificationEditTemplate
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        relatedTableState={tableState}
        id={tableState.selectedRows[0]?.notificationId}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { ReferenceItem } from 'components';
import { ForeignProject } from 'types/models';
import { Item } from 'types/models/common';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { getMaybeField } from '../commonConverters';
import { convertAffiliationToXML } from '../community/requestConverters';
import { convertDocumentsToRequest } from '../converters';

export const convertForeignProjectToXML = (foreignProject: ForeignProject.ForeignProject) => ({
  _attr: {
    id: foreignProject.id,
  },
  Name: foreignProject.name,
  Number: foreignProject.number,
  StartDate: foreignProject.startDate,
  EndDate: foreignProject.endDate,
  Amount: foreignProject.amount ?? '0.00',
  ...getMaybeField('StateRegistrationNumber', foreignProject.stateRegistrationNumber),
  ...getMaybeField('StateRegistrationDate', foreignProject.stateRegistrationDate),
  ...convertProgramToRequest(foreignProject.program),
  ...convertProgramEventToRequest(foreignProject.programEvent),
  Leader: foreignProject.leader,
  Role: foreignProject.role?.value,
  ...getMaybeField('Enterprise', foreignProject.enterprise && convertAffiliationToXML(foreignProject.enterprise)),
  ...getMaybeField('Customer', foreignProject.customer && convertAffiliationToXML(foreignProject.customer)),
  ...getMaybeField('Note', foreignProject.note),
  ...convertPerformerToRequest(foreignProject.performer),
  ...(foreignProject.documents.length ? convertDocumentsToRequest(foreignProject.documents) : {}),
  ...convertFinancingsToRequest(foreignProject.financings),
  ...convertGrntisToRequest(foreignProject.grntis),
});

const convertProgramToRequest = (program: Item<string> | null) => {
  if (program) {
    return {
      Program: {
        _attr: { id: program.value },
      },
    };
  }

  return {};
};

const convertProgramEventToRequest = (programEvent: Item<string> | null) => {
  if (programEvent) {
    return {
      ProgramEvent: {
        _attr: { id: programEvent.value },
      },
    };
  }

  return {};
};

const convertPerformerToRequest = (performer: ForeignProject.Performer | null) => {
  if (performer) {
    return {
      Performer: {
        _attr: {
          id: performer.person?.id,
        },
      },
      ...getHistoricalSliceXML(performer),
    };
  }

  return {};
};

const convertGrntisToRequest = (grntis: ReferenceItem[]) => {
  if (!grntis.length) {
    return {
      Grntis: {},
    };
  }

  return {
    Grntis: {
      Grnti: grntis.map(({ id }) => ({ _attr: { id } })),
    },
  };
};

const convertFinancingsToRequest = (financings: ForeignProject.Financing[]) => {
  if (!financings.length) {
    return {
      Financings: {},
    };
  }

  return {
    Financings: {
      Financing: financings.map(({ amount, year, id }) => ({
        ...(id ? { _attr: { id } } : {}),
        Year: year,
        Amount: amount,
      })),
    },
  };
};

import { Departments } from 'features/Table/views';
import { EmployeePayment } from 'types/models/Payment';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  employeePayment: EmployeePayment | null;
  subText: string;
};

export const RequestHeader = ({ employeePayment, subText }: Props): string | JSX.Element => {
  const title = getStatusMessage(
    employeePayment?.id
      ? `Заявка на оплату стимулирующих выплат сотрудникам из Фонда подразделения.`
      : 'Заявка по сотрудникам подразделения',
    employeePayment?.id || '',
    employeePayment?.status?.label || '',
  );

  return (
    <>
      <h3>{title}</h3>
      <h4>
        <Departments departments={subText} />
      </h4>
    </>
  );
};

import { Table as T } from 'types/models';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  templatesTableDependencies: T.TemplatesTableDependencies;
  workMode: T.WorkMode;
  parentTypeCode?: string;
  isTransform?: boolean;
  setEntryPages?: React.Dispatch<any>;
  parentId?: string;
  isSetupSourceRelationMode?: boolean;
  uniqID?: string;
};

export function GetCompilationArticleList({
  templatesTableDependencies,
  workMode,
  parentTypeCode,
  setEntryPages,
  parentId,
  uniqID,
  isSetupSourceRelationMode = false,
}: Props): T.Specification {
  return {
    apiID: 'GetCompilationArticleList',
    templatesTableDependencies,
    isFiltersHidden: templatesTableDependencies.isFiltersHidden,
    workMode,
    uniqID,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({
          parentId,
          parentTypeCode,
          isSetupSourceRelationMode,
        }),
      },
    },
    setEntryPages,
  };
}

import { Event } from 'types/models';

export function formatAmountByParam(member: Event.MemberCount, param: 'students' | 'professors') {
  const summary = member.amounts.reduce((previousValue, currentValue) => previousValue + parseInt(currentValue[param], 10), 0);
  return String(summary);
}

export function formatMember(member: Event.MemberCount) {
  const convertAdditionalMember = (amount: Event.MemberCountAnother) =>
    [
      amount.country?.label || '',
      amount.city || '',
      amount.enterprise?.label || '',
      `всего - ${amount.amount}`,
      `профессоров - ${amount.professors}`,
      `студентов - ${amount.students}`,
    ]
      .filter(Boolean)
      .join(', ');

  const getAllAmountString = (amounts: Event.MemberCountAnother[]) => {
    const resultArray: string[] = [];
    amounts.forEach(amount => {
      resultArray.push(convertAdditionalMember(amount));
    });
    return resultArray.join('<br />&nbsp;&nbsp;');
  };
  const additionalMembersString =
    member.amounts.length > 0
      ? `<br /><span class="hint-text">&nbsp;&nbsp;в т.ч. ${getAllAmountString(member.amounts)}</span>`
      : '';
  return `<span>${member.label}</span>${additionalMembersString}`;
}

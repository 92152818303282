import { Customer, PatentResearch, Performer } from 'types/models';
import { Settings } from 'types/models/common';

import { convertMaybeArray, getId, getText, convertDocument } from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { convertProject, convertStage } from '../project/converters';
import { convertServerPerson } from '../author/converters';
import { convertRntd } from '../rntd/converters';

export function convertPatentResearch(patentResearch: any, settings: Settings): PatentResearch {
  const customers = patentResearch.Customers?.Customer ?? [];
  const performers = patentResearch.Performers?.Performer ?? [];
  const documents = patentResearch.Documents?.Document || [];

  return {
    id: getId(patentResearch),
    number: getText(patentResearch.Number),
    name: getText(patentResearch.Name),
    target: getText(patentResearch.Target),
    loDate: getText(patentResearch.LoDate),
    hiDate: getText(patentResearch.HiDate),
    rntd: patentResearch.Rntd ? convertRntd(patentResearch.Rntd, settings) : null,
    project: patentResearch.Project ? convertProject(patentResearch.Project, settings) : null,
    stage: patentResearch.Stage ? convertStage(patentResearch.Stage) : null,
    customers: convertMaybeArray(customers, c => convertCustomer(c, settings)),
    performers: convertMaybeArray(performers, p => convertPerformer(p, settings)),
    documents: convertMaybeArray(documents, i => convertDocument('local', i)),
  };
}

function convertCustomer(customer: any, settings: Settings): Customer {
  return {
    id: getId(customer),
    person: convertServerPerson(customer.Person, settings),
    ...convertPersonHistory(customer),
  };
}

function convertPerformer(performer: any, settings: Settings): Performer {
  return {
    id: getId(performer),
    person: convertServerPerson(performer.Person, settings),
    ...convertPersonHistory(performer),
  };
}

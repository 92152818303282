import { convertDocumentsToRequest } from '../converters';
import { SaveEmployeePaymentRequest } from './types';
import { getMaybeField } from '../commonConverters';

export function convertSaveEmployeePaymentToRequest({
  employeePayment,
  comment,
  departmentId,
  year,
  month,
  quarter,
  type,
}: SaveEmployeePaymentRequest) {
  return {
    EmployeePaymentRequest: {
      _attr: {
        isPrechangeStatusSave: 'false',
        ...(employeePayment.id ? { id: employeePayment.id } : {}),
        ...(comment ? { saveComment: comment } : {}),
      },
      Nominees: {
        Nominee: employeePayment.nominees.map(x => ({
          _attr: { id: x.employee?.id ?? '' },
          Appointment: { _attr: { id: x.job?.id ?? '' } },
          AmountMoney: x.amountMoney,
        })),
      },
      ...(departmentId ? { Department: { _attr: { id: departmentId } } } : {}),
      ...getMaybeField('year', year),
      ...getMaybeField('Month', month),
      ...getMaybeField('Quarter', quarter),
      ...getMaybeField('Type', type),
      ...convertDocumentsToRequest(employeePayment.documents),
    },
  };
}

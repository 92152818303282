import { useCallback, useState, useMemo } from 'react';

import { Item } from 'types/models/common';
import { Project } from 'types/models';
import { showNotification } from 'features/Notifications';
import { ProjectReportBlock } from 'utils/Enums';

import { getStagesOptions } from 'features/Form/looks/project/ProjectForm/helpers';

type Arguments = {
  onClose(): void;
  onSubmit(stageNumber: string, reportType: Item, isStateRegistration: boolean): void;
  project: Project.Project;
  visibleBlock?: ProjectReportBlock;
};

export function useController(args: Arguments) {
  const { onClose, onSubmit, project, visibleBlock } = args;

  const [stageNumberOption, setStageNumberOption] = useState<Item | null>(null);
  const [reportTypeOption, setReportTypeOption] = useState<Item | null>(null);
  const [isStateRegistration, setIsStateRegistration] = useState<boolean>(
    visibleBlock ? visibleBlock === ProjectReportBlock.STATE_REGISTRATION : true,
  );

  const options = useMemo(
    () =>
      getStagesOptions(project.stages).filter(
        option => !project.reports.map(report => report.stage?.number).includes(option.value),
      ),
    [project.stages, project.reports],
  );

  const clearFormData = useCallback(() => {
    setStageNumberOption(null);
    setReportTypeOption(null);
  }, []);

  const handleStageChange = useCallback((value: Item | null) => {
    setStageNumberOption(value);
  }, []);

  const handleSubmit = useCallback(() => {
    if (!stageNumberOption || !reportTypeOption) {
      showNotification({ message: 'Заполните необходимые поля', theme: 'danger' });
      return;
    }
    onSubmit(stageNumberOption.value, reportTypeOption || undefined, isStateRegistration);
    clearFormData();
    onClose();
  }, [stageNumberOption, reportTypeOption, onSubmit, isStateRegistration, clearFormData, onClose]);

  return {
    options,
    stageNumberOption,
    reportTypeOption,
    handleSubmit,
    handleStageChange,
    setReportTypeOption,
    isStateRegistration,
    setIsStateRegistration,
  };
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};
export function useController({ tableState, customState }: Props) {
  const { enterpriseCustomerIds, enterpriseIds } = customState;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [scientistId, setScientistId] = useState<string | null>(null);

  const closeModal = useCallback(() => {
    setScientistId(null);
    setIsOpenModal(false);
  }, []);

  const togglePersonModal = useCallback(() => {
    setIsOpenPersonModal(prev => !prev);
  }, []);

  const [row] = tableState.selectedRows;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'list', mode: 'view' },
        title: 'Просмотр списка публикаций',
        onClick: () => {
          if (row) {
            setScientistId(row.id);
            setIsOpenModal(true);
          }
        },
        isDisabled: !row,
      },
      {
        icon: { type: 'user', mode: 'view' },
        title: 'Просмотр портфолио',
        onClick: () => {
          if (row) {
            togglePersonModal();
          }
        },
        isDisabled: !row,
      },
    ],
    [row, togglePersonModal],
  );

  return {
    buttons,
    isOpenModal,
    isOpenPersonModal,
    closeModal,
    togglePersonModal,
    enterpriseCustomerIds,
    enterpriseIds,
    scientistId,
  };
}

import React, { memo } from 'react';

import { FormComponent, SectionTitle } from 'components';

import { Table as T } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { Projects } from 'features/Form/looks/event/views/Projects';
import { EventProject } from 'types/models/Event';

type Props = {
  projects: EventProject[];
  setProjects: (docs: EventProject[]) => void;
  workMode: T.WorkMode;
  relatedTableState: T.State | undefined;
};

const FinancialSupport = ({ projects, setProjects, workMode, relatedTableState }: Props) => {
  return (
    <FormComponent.Wrapper>
      {/* {!!incentivePaymentRequest && (
        <FormComponent.Description mode="info">
          <SectionTitle title="Стим. выплаты:" />
          <span>{incentivePaymentRequest}</span>
        </FormComponent.Description>
      )} */}

      <SectionTitle
        title="Организовано при финансовой поддержке"
        // eslint-disable-next-line max-len
        tooltip="Если проведение мероприятия поддержано научным проектом, то необходимо выбрать из справочника системы проект (грант на организацию проведения мероприятия), связанный с данной выставкой. Информацию о проектах можно добавить после проведения мероприятия."
      />
      <Projects
        eventType={EventTypes.EVENT_EXPOSITION.code}
        projects={projects}
        workMode={workMode}
        setProjects={setProjects}
        relatedTableState={relatedTableState}
      />
    </FormComponent.Wrapper>
  );
};

export const Component = memo(FinancialSupport);

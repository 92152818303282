import { useState, useLayoutEffect, useCallback } from 'react';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Form, Document } from 'types/models';
import { Item } from 'types/models/common';
import {
  CalendarPlanByStage,
  CalendarPlanByYear,
  FinancingByYear,
  Performer,
  ProjectCode,
  Project,
  JobPeriod,
} from 'types/models/Project';
import { useLocalTableStreams } from 'features/Table/hooks';
import { formatNumber } from 'utils/Helpers';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { showNotification } from 'features/Notifications';
import { showErrorsMessages } from 'utils/Common';
import { getMockProjectCode } from 'features/Form/looks/projectCode/ProjectCodeForm/helpers/getMockModels';
import { formatStr } from 'utils/Constants';
import { useAppDataContext } from 'features/AppData/context';
import { StagesTagnameUndistributed } from 'utils/Enums/StagesTagname';
import { validate } from './validate';
import { calculateNdsSumByYear } from './helpers/calculateNdsValues';
import { ProjectFinancingType } from 'utils/Enums';
import { Form as F } from 'types/models';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId, projectId, replaceMode },
  } = useFormContext<F.ProjectCodeFormLook>();
  const { initialOrganizationCode } = useAppDataContext();
  const isTSU = initialOrganizationCode?.code === 'TSU';

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [projectCode, setProjectCode] = useState<ProjectCode>(getMockProjectCode(null));
  const [documents, setDocuments] = useState<Document[]>([]);
  const [calendarPlansByYear, setCalendarPlansByYear] = useState<CalendarPlanByYear[]>([]);
  const [financingsByYear, setFinancingsByYear] = useState<FinancingByYear[]>([]);
  const [calendarPlansByStages, setCalendarPlansByStages] = useState<CalendarPlanByStage[]>([]);
  const [financingTagnameByProjectType, setFinancingTagnameByProjectType] = useState<string | undefined>(undefined);
  const [isProjectChangesOpen, setIsProjectChangesOpen] = useState<boolean>(false);

  // const fmtManualPart = useCallback((code: string) => {
  //   return code.length <= 1 ? `0${code}` : code;
  // }, []);

  const [formFields, setFormFields] = useState<Form.Fields>({
    department: {
      value: '',
      isValid: true,
      required: true,
      title: 'Подразделение',
      onChange: (option: any) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          department: {
            ...prevState.department,
            value: { id: option?.value || '', label: option.label },
          },
          codeDepartmentPart: {
            ...prevState.codeDepartmentPart,
            value: option?.customFields?.unit?.customFields?.code,
          },
        }));
      },
    },
    financing: {
      value: '',
      isValid: true,
      required: true,
      title: 'Источник финансирования',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          financing: {
            ...prevState.financing,
            value,
          },
        }));
      },
    },
    responsiblePerformer: {
      value: '',
      isValid: true,
      required: true,
      title: 'Ответственный за шифр',
      onChange: (value: Performer) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          responsiblePerformer: {
            ...prevState.responsiblePerformer,
            value,
          },
        }));
      },
    },
    // TSU
    letter: {
      value: '',
      isValid: true,
      required: true,
      title: 'Шифр',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          letter: {
            ...prevState.letter,
            value,
          },
          codeManualPart: {
            ...prevState.codeManualPart,
            value: null,
          },
        }));
      },
    },
    // TSU
    codeDepartmentPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeDepartmentPart: {
            ...prevState.codeDepartmentPart,
            value,
          },
        }));
      },
    },
    // TSU
    codeManualPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (val: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeManualPart: {
            ...prevState.codeManualPart,
            value: val,
          },
        }));
      },
    },
    // TSU
    codeYearPart: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          codeYearPart: {
            ...prevState.codeYearPart,
            value,
          },
        }));
      },
    },
    // SFU
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Шифр',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: {
            ...prevState.code,
            value,
          },
        }));
      },
    },
    finAmount: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          finAmount: {
            ...prevState.finAmount,
            value,
          },
        }));
      },
    },
    isNds: {
      value: false,
      isValid: true,
      required: true,
      title: 'В т.ч. НДС',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isNds: {
            ...prevState.isNds,
            value: !prevState.isNds.value,
          },
        }));
      },
    },
    factAmount: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factAmount: {
            ...prevState.factAmount,
            value,
          },
        }));
      },
    },
    financeProvide: {
      value: '',
      isValid: true,
      required: true,
      title: 'Вид фин. обеспечения',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          financeProvide: {
            ...prevState.financeProvide,
            value,
          },
        }));
      },
    },
    isOutsideBudget: {
      value: false,
      isValid: true,
      required: true,
      title: 'Использовать для ВБ',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isOutsideBudget: {
            ...prevState.isOutsideBudget,
            value: !prevState.isOutsideBudget.value,
          },
        }));
      },
    },
    isRefersToEconomicAgreement: {
      value: false,
      isValid: true,
      required: true,
      title: 'Относится к ХД',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isRefersToEconomicAgreement: {
            ...prevState.isRefersToEconomicAgreement,
            value: !prevState.isRefersToEconomicAgreement.value,
          },
        }));
      },
    },
    fundCard: {
      value: '',
      isValid: true,
      required: true,
      title: '',
      onChange: (value: any) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fundCard: {
            ...prevState.fundCard,
            value: value?.id ? { id: value.id, name: value.name, note: value.note } : null,
          },
        }));
      },
    },
  });

  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode');
  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject');
  const { methods: saveProjectCode } = BackendAPI.useBackendAPI('SaveProjectCode');
  const { methods: replaceProjectCode } = BackendAPI.useBackendAPI('ReplaceProjectCode');
  const { methods: processProjectChangesAPI } = BackendAPI.useBackendAPI('ProcessProjectChanges');

  const calculateNdsSummary = (projectCodeToCalc: ProjectCode) => {
    return (
      projectCodeToCalc?.project?.financingsByYear
        .map(value => formatNumber(calculateNdsSumByYear({ row: value, projectCode: projectCodeToCalc }).amount, 2))
        .reduce((accumulator, currentValue) => accumulator + parseFloat(currentValue.replace(' ', '')), 0)
        .toString() || ''
    );
  };

  const convertFinancingTypeToTagname = (val: string) => {
    switch (val) {
      case ProjectFinancingType.MAIN:
        return StagesTagnameUndistributed.MAIN;
      case ProjectFinancingType.LOCAL:
        return StagesTagnameUndistributed.LOCAL;
      case ProjectFinancingType.COFINANCING_1:
        return StagesTagnameUndistributed.COFINANCING_1;
      case ProjectFinancingType.COFINANCING_2:
        return StagesTagnameUndistributed.COFINANCING_2;
      case ProjectFinancingType.ACCOMPLICE:
        return StagesTagnameUndistributed.ACCOMPLICE;

      default:
        return '';
    }
  };

  const loadProjectCode = useCallback(
    ({ pc, id, isInitialLoad }: { pc?: ProjectCode | null; id?: string | null; isInitialLoad?: boolean }) => {
      getProjectCode.callAPI(
        {
          projectCode: pc,
          id,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setProjectCode(prevState => (prevState?.id ? { ...data, id: prevState.id } : data));

            setFormFields(prevState => ({
              ...prevState,
              department: {
                ...prevState.department,
                value: data.department,
              },
              financing: {
                ...prevState.financing,
                value: {
                  id: data.financing?.id,
                  label: data.financing?.source?.label,
                },
              },
              codeDepartmentPart: {
                ...prevState.codeDepartmentPart,
                value: data.department?.unit?.customFields?.code || '',
              },
              codeYearPart: {
                ...prevState.codeYearPart,
                value: data.project?.startDate
                  ? parse(data.project.startDate, formatStr, new Date()).getFullYear().toString().substring(2, 4)
                  : '',
              },
              isNds: {
                ...prevState.isNds,
                value: data.financing?.isNds,
              },
              factAmount: {
                ...prevState.factAmount,
                value: data ? formatNumber(calculateNdsSummary(data)) : '',
              },
              fundCard: {
                ...prevState.fundCard,
                value: data.fundCard,
              },

              ...(workMode === 'addMode'
                ? {}
                : {
                    responsiblePerformer: {
                      ...prevState.responsiblePerformer,
                      value: {
                        label: data.responsiblePerformer?.fio || data.responsiblePerformer?.person?.fullName,
                        value: data.responsiblePerformer?.id,
                      },
                    },
                    code: {
                      ...prevState.code,
                      value: data.code,
                    },
                    letter: {
                      ...prevState.letter,
                      value: data.letter,
                    },
                    codeManualPart: {
                      ...prevState.codeManualPart,
                      value: data.codeManualPart,
                    },
                    financeProvide: {
                      ...prevState.financeProvide,
                      value: data.financeProvide,
                    },
                    isOutsideBudget: {
                      ...prevState.isOutsideBudget,
                      value: data.isOutsideBudget,
                    },
                    isRefersToEconomicAgreement: {
                      ...prevState.isRefersToEconomicAgreement,
                      value: data.isRefersToEconomicAgreement,
                    },
                  }),
            }));

            setDocuments(data?.documents || []);
            setCalendarPlansByYear(data?.calendarPlansByYears || []);
            setFinancingsByYear(data?.project?.financingsByYear || []);
            setCalendarPlansByStages(data?.calendarPlansByStages || []);
            setFinancingTagnameByProjectType(convertFinancingTypeToTagname(data?.financing?.type?.value ?? ''));

            if (!!isInitialLoad && !viewMode && !!data.changesInProject.filter(x => !x.isProcessed).length) {
              setIsProjectChangesOpen(true);
            }
          },
        },
      );
    },
    [getProjectCode, viewMode, workMode],
  );

  const findPerformerByJobPeriodRole = useCallback(
    (role: string, targetProject?: Project | null) => {
      let primeLastJobPeriod: JobPeriod | null = null;
      let lastPerformerByRole: Performer | null = null;

      const lookupProject = targetProject || projectCode?.project;

      if (lookupProject?.performers && Array.isArray(lookupProject?.performers)) {
        lookupProject.performers.forEach((performer: Performer) => {
          const jobPeriods = performer.jobPeriods.filter(period => period.role?.value === role);
          let lastJobPeriod: JobPeriod | null = null;

          jobPeriods.forEach((period: JobPeriod) => {
            if (
              !lastJobPeriod ||
              parse(period.endDate, formatStr, new Date()).getTime() >
                parse(lastJobPeriod.endDate, formatStr, new Date()).getTime()
            ) {
              lastJobPeriod = period;
            }
          });

          if (lastJobPeriod) {
            if (!primeLastJobPeriod) {
              primeLastJobPeriod = lastJobPeriod;
              lastPerformerByRole = performer;
            } else if (
              parse((lastJobPeriod as JobPeriod).endDate, formatStr, new Date()).getTime() >
              parse(primeLastJobPeriod.endDate, formatStr, new Date()).getTime()
            ) {
              primeLastJobPeriod = lastJobPeriod;
              lastPerformerByRole = performer;
            }
          }
        });
      }

      return lastPerformerByRole;
    },
    [projectCode],
  );

  const loadProject = useCallback(
    (loadProjectId: string) => {
      getProject.callAPI(
        { id: loadProjectId },
        {
          onSuccessfullCall: ({ data }) => {
            setProjectCode(prevState => ({
              ...prevState!,
              project: data,
            }));

            if (!projectCode?.id) {
              setProjectCode({
                ...projectCode,
                ...{ project: data, year: parse(data.startDate, formatStr, new Date()).getFullYear().toString() },
              });

              const responsiblePerformer: Performer | null = findPerformerByJobPeriodRole('RESPONSIBLE_PERFORMER', data);

              setFormFields(prevState => ({
                ...prevState,
                codeYearPart: {
                  ...prevState.codeYearPart,
                  value: parse(data.startDate, formatStr, new Date()).getFullYear().toString().substring(2, 4),
                },
                ...(responsiblePerformer
                  ? {
                      responsiblePerformer: {
                        ...prevState.responsiblePerformer,
                        value: { ...(responsiblePerformer as Performer) },
                      },
                    }
                  : {}),
              }));

              if (data?.documents) {
                setDocuments(data?.documents.map(doc => ({ ...doc, docType: 'foreign' })));
              }
            }
          },
        },
      );
    },
    [getProject, projectCode, findPerformerByJobPeriodRole],
  );

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const validationInfo = validate(formFields, isTSU);
      const errorMessages = [...validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage)];

      if (errorMessages.length) {
        showErrorsMessages(errorMessages);
        return;
      }
      const apiMethod = replaceMode ? replaceProjectCode : saveProjectCode;
      apiMethod.callAPI(
        {
          id: (editMode && initialId) || projectCode?.id || null,
          accompliceAmount: projectCode?.accompliceAmount || '',
          calendarPlansByStages: projectCode?.calendarPlansByStages || [],
          calendarPlansByYears: projectCode?.calendarPlansByYears || [],
          calendarPlansTotalAmount: projectCode?.calendarPlansTotalAmount,
          code: formFields.code?.value || '',
          codeDepartmentPart: formFields.codeDepartmentPart?.value,
          codeManualPart: formFields.codeManualPart?.value,
          codeYearPart: formFields.codeYearPart?.value,
          responsiblePerformer: { ...formFields.responsiblePerformer.value, id: formFields.responsiblePerformer.value?.value },
          department: formFields.department.value,
          documents,
          expenseFactAmount: projectCode?.expenseFactAmount || '',
          expensePlanAmount: projectCode?.expensePlanAmount || '',
          financeProvide: formFields.financeProvide.value,
          financing: {
            ...(projectCode?.financing || { id: formFields.financing?.value?.value }),
            isNds: formFields.isNds.value,
            factAmount: formFields.factAmount.value,
            source: formFields.financing.value,
            amount: formFields.finAmount.value,
          },
          fundCard: formFields.fundCard.value,
          incomeFactAmount: projectCode?.incomeFactAmount || '',
          incomePlanAmount: projectCode?.incomePlanAmount || '',
          isActive: projectCode?.isActive,
          isFundCardUsed: projectCode?.isFundCardUsed,
          isOutsideBudget: formFields.isOutsideBudget.value,
          isRefersToEconomicAgreement: formFields.isRefersToEconomicAgreement.value,
          letter: formFields.letter?.value,
          overheadFactAmount: projectCode?.overheadFactAmount || '',
          overheadPercent: projectCode?.overheadPercent || '',
          project: projectCode?.project,
          receivingDocuments: projectCode?.receivingDocuments,
          year: projectCode?.year || '',
          changesInProject: [],
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Шифр успешно сохранен', theme: 'success' });
            if (needClose || replaceMode) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data?.id) {
              loadProjectCode({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      formFields,
      isTSU,
      replaceMode,
      replaceProjectCode,
      saveProjectCode,
      editMode,
      initialId,
      projectCode?.id,
      projectCode?.accompliceAmount,
      projectCode?.calendarPlansByStages,
      projectCode?.calendarPlansByYears,
      projectCode?.calendarPlansTotalAmount,
      projectCode?.expenseFactAmount,
      projectCode?.expensePlanAmount,
      projectCode?.financing,
      projectCode?.incomeFactAmount,
      projectCode?.incomePlanAmount,
      projectCode?.isActive,
      projectCode?.isFundCardUsed,
      projectCode?.overheadFactAmount,
      projectCode?.overheadPercent,
      projectCode?.project,
      projectCode?.receivingDocuments,
      projectCode?.year,
      documents,
      tableStreams.reloadTable,
      onClose,
      loadProjectCode,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const handleFinPlanChange = useCallback(
    (value: CalendarPlanByYear[]) => {
      if (!formFields.financing.value?.id) {
        showNotification({ message: 'Выберите источник финансирования', theme: 'danger' });
        return;
      }
      loadProjectCode({
        pc: {
          ...projectCode,
          ...{
            calendarPlansByYears: value,
            financing: { ...formFields.financing.value, id: formFields.financing.value?.id },
            codeYearPart: formFields.codeYearPart?.value,
            year: `20${formFields.codeYearPart?.value}`,
          },
          prototypeId: projectCode?.id || '',
        },
      });
    },
    [formFields.financing.value, formFields.codeYearPart?.value, loadProjectCode, projectCode],
  );

  const handleDocumentsChange = useCallback((docs: Document[]) => {
    setDocuments(docs);
  }, []);

  const handleFinancingSourceChange = (val: Item<string> | null) => {
    loadProjectCode({
      pc: {
        ...projectCode,
        ...{
          financing: { ...formFields.financing.value, id: val?.value || null },
          department: formFields.department.value,
        },
      },
    });
  };

  const { methods: generateProjectCodeAPI } = BackendAPI.useBackendAPI('GenerateProjectCode');

  const generateProjectCode = useCallback(() => {
    generateProjectCodeAPI.callAPI(
      {
        departmentCode: formFields.codeDepartmentPart?.value,
        letterId: formFields.letter?.value?.id,
        year: formFields.codeYearPart?.value,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            codeManualPart: {
              ...prevState.codeManualPart,
              value: data.length == 1 ? `0${data.toString()}` : data.toString(),
            },
          }));
        },
      },
    );
  }, [
    formFields.codeDepartmentPart?.value,
    formFields.codeYearPart?.value,
    formFields.letter?.value?.id,
    generateProjectCodeAPI,
  ]);

  const processProjectChanges = useCallback(() => {
    processProjectChangesAPI.callAPI(
      { ids: projectCode.changesInProject.filter(x => !x.isProcessed).map(x => x.id!) },
      {
        onSuccessfullCall: () => {
          setIsProjectChangesOpen(false);
          loadProjectCode({ id: initialId });
        },
      },
    );
  }, [initialId, loadProjectCode, processProjectChangesAPI, projectCode.changesInProject]);

  useLayoutEffect(() => {
    if (initialId) {
      loadProjectCode({ id: initialId, isInitialLoad: true });
    } else if (projectId && workMode === 'addMode') {
      loadProject(projectId);
    }
    // eslint-disable-next-line
  }, []);

  // useEffect(() => {
  //   if (
  //     formFields.letter?.value?.id &&
  //     formFields.codeYearPart?.value &&
  //     formFields.codeDepartmentPart?.value &&
  //     !id &&
  //     !projectCode?.id
  //   )
  //     generateProjectCode();
  // eslint-disable-next-line react-hooks/exhaustive-deps, max-len
  // }, [formFields.codeDepartmentPart?.value, formFields.codeYearPart?.value, formFields.letter?.value?.id, id, projectCode?.id]);

  const { userPermission } = useAppDataContext();

  return {
    formFields,
    workMode,
    onSubmit,
    projectCode,
    replaceMode,
    handleFinPlanChange,
    handleDocumentsChange,
    documents,
    userPermission,
    findPerformerByJobPeriodRole,
    calendarPlansByYear,
    financingsByYear,
    calendarPlansByStages,
    handleFinancingSourceChange,
    financingTagnameByProjectType,
    generateProjectCode,
    isProjectChangesOpen,
    setIsProjectChangesOpen,
    processProjectChanges,
  };
}

import { getDepartmentFromMember } from 'features/Form/looks/securityDocument/SecurityDocumentForm/helpers';
import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Project } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { Department } from 'types/models/Project';

type CheckActiveProjectCodesForDepartmentProps = {
  department: Project.Department;
  continueCallback: () => void;
  mode: 'edit' | 'delete';
};

type Props = {
  projectId?: string;
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
};

const useController = ({ projectId, securityDocument, setSecurityDocument }: Props) => {
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
  const [codes, setCodes] = useState<string>('');

  const {
    methods: CheckActiveProjectCodesForDepartmentAPI,
    state: CheckActiveProjectCodesForDepartmentState,
  } = BackendAPI.useBackendAPI('CheckActiveProjectCodesForDepartment');

  const isLoading = useMemo(() => CheckActiveProjectCodesForDepartmentState.kind === 'pending', [
    CheckActiveProjectCodesForDepartmentState.kind,
  ]);

  const onCloseDeleteWarningPopup = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, []);

  const deleteWarningText = useMemo(
    () => `Выбранное подразделение не может быть удалено, т.к. на него уже заведен шифр <strong>&ldquo;${codes}&rdquo;</strong>`,
    [codes],
  );

  const checkActiveProjectCodesForDepartment = useCallback(
    ({ department, continueCallback, mode }: CheckActiveProjectCodesForDepartmentProps) => {
      const isEdit = mode === 'edit';
      const isDelete = mode === 'delete';

      CheckActiveProjectCodesForDepartmentAPI.callAPI(
        {
          projectId: projectId || '-1',
          projectDepartmentId: department.id || '-1',
        },
        {
          onSuccessfullCall: ({ data: { codes: responseCodes, value } }) => {
            if (value) {
              setCodes(responseCodes);
            }

            if (isEdit) {
              continueCallback();
            } else if (isDelete) {
              if (value) {
                setIsDeleteWarningOpen(true);
              } else {
                continueCallback();
              }
            }
          },
        },
      );
    },
    [CheckActiveProjectCodesForDepartmentAPI, projectId],
  );

  const copyFromMembers = useCallback(() => {
    let newDepartments = securityDocument.members.map(x => getDepartmentFromMember(x)).filter(Boolean) as Department[];
    newDepartments = newDepartments.filter((x, index) => index === newDepartments.findIndex(d => d.unit?.id === x?.unit?.id));

    setSecurityDocument(prev => ({
      ...prev,
      departments: [
        ...prev.departments,
        ...newDepartments.filter(nd => !prev.departments.find(d => d.unit?.id === nd?.unit?.id)),
      ],
    }));
  }, [securityDocument.members, setSecurityDocument]);

  const onPreEdit = useCallback(
    (department: Project.Department | null, continueEdit: () => void) => {
      setCodes('');
      if (department?.id && projectId) {
        checkActiveProjectCodesForDepartment({
          department,
          continueCallback: continueEdit,
          mode: 'edit',
        });
      } else {
        continueEdit();
      }
    },
    [checkActiveProjectCodesForDepartment, projectId],
  );

  const onPreDelete = useCallback(
    (department: Project.Department | null, continueDelete: () => void) => {
      if (department?.id && projectId) {
        checkActiveProjectCodesForDepartment({ department, continueCallback: continueDelete, mode: 'delete' });
      } else {
        continueDelete();
      }
    },
    [checkActiveProjectCodesForDepartment, projectId],
  );

  return {
    isDeleteWarningOpen,
    onCloseDeleteWarningPopup,
    deleteWarningText,
    isLoading,
    onPreDelete,
    onPreEdit,
    codes,
    copyFromMembers,
  };
};

export default useController;

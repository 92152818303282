import { useMemo, useCallback, useState, useLayoutEffect } from 'react';
import { Parameter, RangeValue } from 'types/models';
import { format } from 'date-fns';
import { formatStr } from 'utils/Constants';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const currentYear = new Date().getFullYear();
const firstMonth = 0;
const lastMonth = 11;
const lastMonthLastDay = 31;

const initialFrom = format(new Date(currentYear, firstMonth), formatStr);
const initialTo = format(new Date(currentYear, lastMonth, lastMonthLastDay), formatStr);

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const dateRangeParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!dateRangeParameter, [dateRangeParameter]);

  const [valueFrom, setValueFrom] = useState<string>('');
  const [valueTo, setValueTo] = useState<string>('');

  useLayoutEffect(() => {
    setValueFrom(initialFrom);
    setValueTo(initialTo);
    const nextParameterValue: RangeValue = {
      from: initialFrom,
      to: initialTo,
    };

    setParameterValueByName({ name: parameterName, value: nextParameterValue });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = useCallback(
    (fieldName: 'from' | 'to', value: string) => {
      (fieldName === 'from' ? setValueFrom : setValueTo)(value);
      setParameterValueByName({
        name: parameterName,
        value: { from: fieldName === 'from' ? value : valueFrom, to: fieldName === 'to' ? value : valueTo },
      });
    },
    [parameterName, setParameterValueByName, valueFrom, valueTo],
  );

  return {
    dateRangeParameter,
    canBuild,
    valueFrom,
    valueTo,
    onChange,
  };
};

export default useController;

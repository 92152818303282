import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { ProjectPerformerJobPeriodFiltersHelp } from './LeftPanelForThirdLevel/help';

enum Filter {
  ACTUAL = 'actual',
  ALL = 'all',
}

export const Filters = React.memo(() => {
  const [isYear, setIsYear] = useState<boolean>(true);
  const [filter, setFilter] = useState<Filter>(Filter.ALL);

  return FiltersComponent([
    { type: FilterComponentType.info, text: ProjectPerformerJobPeriodFiltersHelp() },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'withoutJob',
      label: 'Нет должности/ГПХ',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'fired',
      label: 'Уволен досрочно',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'removed',
      label: 'Исключён из состава',
      checked: false,
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'actual',
      list: [
        {
          label: 'Состав на текущую дату',
          value: Filter.ACTUAL,
        },
        {
          label: 'Все',
          value: Filter.ALL,
        },
      ],
      value: filter,
      onChange: (e: Filter) => {
        setFilter(e);
        if (e === Filter.ACTUAL) {
          setIsYear(false);
        }
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: isYear,
      onChange: setIsYear,
      isDisabled: filter === Filter.ACTUAL,
    },
  ]);
});

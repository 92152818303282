import React from 'react';

import { Toolbar as Icons } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function Toolbar({ onSubmitTable, tableState, customState }: Props) {
  const { buttons } = useController({ onSubmitTable, tableState, customState });

  return <Icons buttons={buttons} />;
}

export { Toolbar };

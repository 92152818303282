import { Table } from 'types/models';
import { NotificationEventModule } from 'utils/Enums';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Filters } from './filters';
import { makeUseCustomController, State } from './makeUseCustomController';

type RequestData = {
  module?: NotificationEventModule;
  sourceId?: string;
};

export function GetNotificationLogList(requestData?: RequestData): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetNotificationLogList',
    header: {
      firstLevel: {
        title: 'Журнал отправленных уведомлений',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ module: requestData?.module }),
    requestData,
  };
}

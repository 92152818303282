import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { SecurityDocumentFormLook } from 'types/models/Form/look/securityDocument';
import { SecurityDocumentType } from 'utils/Enums';

const SecurityDocumentAddTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
}: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: SecurityDocumentFormLook = {
    apiID: 'GetSecurityDocument',
    template: 'SecurityDocumentForm',
    documentType: SecurityDocumentType.NON_REGISTERED,
    relatedTableState,
    name,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(SecurityDocumentAddTemplate);

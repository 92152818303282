import React, { useState, useMemo } from 'react';
import { getState } from 'StreamRx';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { DataGrid } from 'features/Table/container';
import { GetProjectScopusWosPublicationList } from 'features/Table/specifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectScopusWosPublicationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isOpenViewForm, setIsOpenViewForm] = useState(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр списка статей',
        onClick: () => setIsOpenViewForm(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [tableState.selectedRows.length],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={`Список статей проекта №${tableState.selectedRows[0]?.ProjectNumber} (руководитель - ${
          tableState.selectedRows[0]?.ProjectLeader || 'Не указан'
        })`}
        isOpen={isOpenViewForm}
        onClose={() => setIsOpenViewForm(false)}
        size="full"
      >
        <DataGrid
          specification={GetProjectScopusWosPublicationList(
            tableState.selectedRows[0]?.id || '',
            getState(tableStreams.getPanelState),
          )}
        />
      </Modal>

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ProjectScopusWosPublicationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { ReferenceItem } from 'components';
import { Author, Person } from 'types/models';

export function formatJob(job: Person.ScientistJob) {
  const { appointment, status, department, refAppointment, refDepartment } = job;
  const jobsArray: string[] = [];
  if (status?.label) jobsArray.push(status.label);
  if (refAppointment?.label) jobsArray.push(refAppointment?.label);
  else if (appointment) jobsArray.push(appointment);
  if (refDepartment?.label) jobsArray.push(refDepartment.label);
  else if (department) jobsArray.push(department);
  return `
  <span>
  ${jobsArray.join(', ')}
  </span>
  `;
}

export function formatEducation(education: Person.ScientistEducation) {
  const { status, refSpeciality, refDepartment } = education;
  const educationArray: string[] = [];
  if (status?.label) educationArray.push(status.label);
  if (refSpeciality?.label) educationArray.push(refSpeciality.label);
  if (refDepartment?.label) educationArray.push(refDepartment.label);
  return `
  <span>
  ${educationArray.join(', ')}
  </span>
  `;
}

export function getBiblioName(scientistPerson: Person.ScientistPerson, language: ReferenceItem) {
  return scientistPerson.bibliographicNames.find(x => x.isDefault && language.id === x.language?.id)?.name ?? '';
}
export function formatRole(author: Author) {
  const { role } = author;

  return `
    <span>${role?.label || ''}</span>
  `;
}
function isCollectiveAuthor(category: Author['category']) {
  return category?.id === '2';
}
export function formatHistory(author: Author) {
  const { historyLabel, job, education } = author;
  return `<span>
  ${historyLabel || ''}
  ${!historyLabel && job ? `${formatJob(job)};` : ''}
  ${!historyLabel && education ? `${formatEducation(education)}.` : ''}
  ${
    !job && !education && !isCollectiveAuthor(author.category)
      ? '<span class="state-danger">Не указаны сведения о работе или обучении</span>.'
      : ''
  }</span>`;
}

export function formatAuthor(author: Author) {
  const { person } = author;

  return `
    <span>${person?.fullName}</span>
  `;
}

export function validate(author: Author | null) {
  if (!author) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  if (!author?.role) {
    return [{ isValid: false, invalidMessage: 'Выберите роль' }];
  }
  const isValid = Boolean(author.person?.fullName && author.person.id);

  return [{ isValid, invalidMessage: 'Заполните необходимые поля' }];
}

export function getMockAuthor(): Author {
  return {
    academicRank: null,
    affilateCount: null,
    category: null,
    citizenship: null,
    degree: null,
    education: null,
    enterprise: null,
    historyLabel: '',
    id: null,
    isAffilated: false,
    job: null,
    memberAffilations: [],
    name: '',
    person: null,
    position: 0,
    rank: null,
  };
}

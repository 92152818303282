import React, { useCallback, useMemo, useState } from 'react';

import { ButtonMode, Modal, FormComponent, TextArea, TextAreaMode, Radio } from 'components';

import { showNotification } from 'features/Notifications';
import {
  GetNirRequestListForProject,
  GetProgramRequestListForProject,
  GetSecurityDocumentContractListForProject,
} from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';
import { Table } from 'types/models';

enum RadioOptions {
  empty = 'empty',
  program = 'program',
  nir = 'nir',
  sdc = 'sdc',
}

export type SubmitProps = {
  programRequestId?: string;
  nirRequestId?: string;
  securityDocumentContractId?: string;
};

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(props: SubmitProps): void;
  projectType: ProjectType;
};

export function SelectRequestModal({ isOpen, onClose, onSubmit, projectType }: Props) {
  const { enumMap } = useAppDataContext();

  const [programRequest, setProgramRequest] = useState<{ id: string; label: string } | null>(null);
  const [nirRequest, setNirRequest] = useState<{ id: string; label: string } | null>(null);
  const [securityDocumentContract, setSecurityDocumentContract] = useState<{ id: string; label: string } | null>(null);
  const [selectedRadio, setSelectedRadio] = useState<RadioOptions>(RadioOptions.empty);

  const handleSubmit = useCallback(() => {
    if (
      (selectedRadio === RadioOptions.program && !programRequest) ||
      (selectedRadio === RadioOptions.nir && !nirRequest) ||
      (selectedRadio === RadioOptions.sdc && !securityDocumentContract)
    ) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    if (selectedRadio === RadioOptions.program && programRequest) onSubmit({ programRequestId: programRequest.id });
    else if (selectedRadio === RadioOptions.nir && nirRequest) onSubmit({ nirRequestId: nirRequest.id });
    else if (selectedRadio === RadioOptions.sdc && securityDocumentContract)
      onSubmit({ securityDocumentContractId: securityDocumentContract.id });
    else onSubmit({});
    onClose();
  }, [selectedRadio, programRequest, nirRequest, securityDocumentContract, onSubmit, onClose]);

  const handleRadioChange = useCallback((e: RadioOptions) => {
    if (e !== RadioOptions.program) setProgramRequest(null);
    if (e !== RadioOptions.nir) setNirRequest(null);
    if (e !== RadioOptions.sdc) setSecurityDocumentContract(null);
    setSelectedRadio(e);
  }, []);

  const isDisabled: boolean = useMemo<boolean>(() => {
    return (
      (selectedRadio === RadioOptions.program && !programRequest) ||
      (selectedRadio === RadioOptions.nir && !nirRequest) ||
      (selectedRadio === RadioOptions.sdc && !securityDocumentContract)
    );
  }, [nirRequest, programRequest, securityDocumentContract, selectedRadio]);

  return (
    <Modal
      title={`Создать проект (${getEnumItemLabel('ProjectType', projectType, enumMap)})`}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Продолжить',
          onClick: handleSubmit,
          isDisabled: isDisabled,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="medium"
    >
      <>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Radio
              value={selectedRadio}
              list={[
                { label: 'Создать проект без заявки', value: RadioOptions.empty },
                { label: 'Создать на основе заявки на конкурс', value: RadioOptions.program },
                { label: 'Создать на основе заявки на внутренний конкурс', value: RadioOptions.nir },
                { label: 'Создать на основе Договора о распоряжении интеллектуальным правом', value: RadioOptions.sdc },
              ]}
              onChange={e => handleRadioChange(e as RadioOptions)}
              isInRow={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {selectedRadio === RadioOptions.program && (
          <FormComponent.Line>
            <FormComponent.Field label="Заявка на конкурс" isRequired>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Заявки на конкурс',
                  isClearable: false,
                  table: {
                    specification: GetProgramRequestListForProject,
                    onSelect: (row: Table.Entry | null) =>
                      setProgramRequest({
                        id: row?.id || '',
                        label: [row?.RequestCode, row?.Leader ? `${row?.Leader}` : '', row?.RequestTheme]
                          .filter(Boolean)
                          .join(' '),
                      }),
                  },
                }}
                value={programRequest?.label}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {selectedRadio === RadioOptions.nir && (
          <FormComponent.Line>
            <FormComponent.Field label="Заявка на внутренний конкурс" isRequired>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Заявки на внутренний конкурс',
                  isClearable: false,
                  table: {
                    specification: GetNirRequestListForProject,
                    onSelect: (row: Table.Entry | null) =>
                      setNirRequest({
                        id: row?.id || '',
                        label: [row?.RequestNumber, row?.Leader ? `${row?.Leader}` : '', row?.RequestName]
                          .filter(Boolean)
                          .join(' '),
                      }),
                  },
                }}
                value={nirRequest?.label}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {selectedRadio === RadioOptions.sdc && (
          <FormComponent.Line>
            <FormComponent.Field label="Договор на ИС" isRequired>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Договоры на ИС',
                  isClearable: false,
                  table: {
                    specification: GetSecurityDocumentContractListForProject,
                    onSelect: (row: Table.Entry | null) =>
                      setSecurityDocumentContract({
                        id: row?.id || '',
                        label: [
                          row?.ContractKind,
                          row?.ContractNumber ? `№${row?.ContractNumber}` : '',
                          row?.EnterpriseName ? `(${row?.EnterpriseName})` : '',
                        ]
                          .filter(Boolean)
                          .join(' '),
                      }),
                  },
                }}
                value={securityDocumentContract?.label}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
      </>
    </Modal>
  );
}

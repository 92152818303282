import React, { ChangeEventHandler } from 'react';
import MaskedInput from 'react-text-mask';

import useController from './controller';
import { Props as TextInputProps } from 'components/TextInput';

type OmittedTextInputProps = Omit<TextInputProps, 'onChange' | 'isDisabled' | 'isError'>;

type Props = OmittedTextInputProps & {
  className: string;
  isRange: boolean;
  isWithDate: boolean;
  isWithTime: boolean;
  onInput?: ChangeEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  style?: React.CSSProperties;
};

export function Mask(props: Props) {
  const { className, isRange, isWithDate, isWithTime, style, ...other } = props;
  const { mask } = useController({ isRange, isWithDate, isWithTime });

  return (
    <MaskedInput
      showMask
      keepCharPositions
      guide
      className={className}
      mask={mask}
      style={style}
      {...(other as OmittedTextInputProps)}
    />
  );
}

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetAnalyticProjectList, GetPaymentOrderList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function Analytics() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['projects', 'analytics'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Проекты" id="list">
          <DataGrid specification={GetAnalyticProjectList} />
        </Tab>
        <Tab title="Зачислено на шифры" id="payments">
          <DataGrid specification={GetPaymentOrderList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

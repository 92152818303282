import React, { useCallback } from 'react';
import { ListEdit } from 'components';
import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { GetAcceptableMobileRequestList } from 'features/Table/specifications/GetAcceptableMobileRequestList';
import { Permits } from 'utils/Permissions';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  workMode: Table.WorkMode;
  relatedTableState: Table.State | undefined;
  requests: PublicationMobileRequest[];
  setMobileRequests: (rows: PublicationMobileRequest[]) => void;
};

function Requests({ workMode, requests, relatedTableState, setMobileRequests }: Props) {
  const { currentPerson } = useAppDataContext();

  const specifications = GetAcceptableMobileRequestList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const getIsEditMobileRequestButtonDisabled = useCallback((row: PublicationMobileRequest | null) => !!row?.accepted, []);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => PublicationMobileRequest>(
    row => ({
      id: null,
      mobileRequest: {
        id: row.id,
        label: row.Label,
      },
      accepted: '',
      acceptedBy: null,
    }),
    [],
  );

  const setProjectAccept = useCallback(
    (projectId: string, isAccept: boolean) => {
      const projectIndex = requests.findIndex(request => request.mobileRequest?.id === projectId);
      requests[projectIndex] = {
        ...requests[projectIndex],
        accepted: isAccept ? new Date().toLocaleString().replace(',', '') : '',
        acceptedBy: isAccept && currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null,
      };
      setMobileRequests([...requests]);
    },
    [currentPerson?.fullName, currentPerson?.id, requests, setMobileRequests],
  );

  return (
    <ListEdit
      header={{ title: 'Заявки по программам академической мобильности' }}
      rows={requests}
      onChange={rows => setMobileRequests(rows)}
      toolbar={[
        { key: 'add', permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT } },
        {
          key: 'delete',
          isDisabled: getIsEditMobileRequestButtonDisabled,
          permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT },
        },
        {
          key: 'accept',
          onClick: row => setProjectAccept(row?.mobileRequest?.id || '', true),
          permission: { name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING },
        },
        {
          key: 'decline',
          onClick: row => setProjectAccept(row?.mobileRequest?.id || '', false),
          permission: { name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING },
        },
      ]}
      columns={[
        {
          label: 'Заявка',
          formatValue: row => row.mobileRequest?.label || '<em>Заявка без номера</em>',
          styles: { width: '75%' },
        },
        { label: 'Принята к отчету', formatValue: getAcceptInfo, styles: { width: '25%' } },
      ]}
      specification={{
        mode: 'relationTableModal',
        modalTableRowConverter,
        relationTableModalTitle: 'Заявки по мобильности',
        modalTableSpecification: specifications,
      }}
      isModalHintHidden
      isDisabled={workMode === 'viewMode'}
    />
  );
}

export { Requests };

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetNirRequestFeedlineList } from 'features/Table/specifications';

type Props = {
  nirRequestId: string;
};

export function FeedlineList({ nirRequestId }: Props) {
  return <DataGrid specification={GetNirRequestFeedlineList({ nirRequestId })} />;
}

import { ReferenceItem } from 'components';

import { PurchaseRequest, Unit } from 'types/models/PurchaseRequest';
import { Item } from 'types/models/common';
import { PurchaseRequestUnitType } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';

export function getMockPurchaseRequest(contractKind?: Item | null): PurchaseRequest {
  return {
    id: null,
    sendDate: '',
    status: null,
    applicant: null,
    contractKind: contractKind || null,
    documents: [],
    name: '',
    project: null,
    units: [],
    paymentTerm: null,
    purchaseMethod: null,
    number: '',
    limitPrice: '',
    department: null,
    financeResponsible: null,
    requestResponsible: null,
    deliveryAddress: '',
    deliveryDate: '',
    deliveryNote: '',
    note: '',
  };
}

export function getMockUnit(defaultUnit?: ReferenceItem): Unit {
  return {
    type: getEnumItem('PurchaseRequestUnitType', PurchaseRequestUnitType.PRODUCT),
    name: '',
    quantity: '1',
    unit: defaultUnit || null,
    perUnitPrice: '',
    position: '',
  };
}

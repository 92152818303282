import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const NirRequestKindConsumptionViewTemplate: React.FC<F.TemplateProps & { tenderId?: string }> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  tenderId,
}: F.TemplateProps & { tenderId?: string }) => {
  const look: F.TenderKindConsumptionFormLook = {
    apiID: 'GetTenderKindConsumption',
    template: 'TenderKindConsumptionForm',
    viewMode: true,
    id,
    relatedTableState,
    tenderId,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Просмотр вида расходов" size="medium" isViewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(NirRequestKindConsumptionViewTemplate);

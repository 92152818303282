import { Mode } from 'components/ListEdit/model';

import { Customer } from 'types/models';

export function validatePatentMember(member: Customer | null, list: Customer[], mode: Mode | null, index: number | null) {
  const members = mode === 'edit' ? list.filter((_, memberIndex) => index !== memberIndex) : list;
  const checkIsTherePersonDublicates = (presonId: string | undefined, membersArray: Customer[]) => {
    return presonId && membersArray.map(m => m.person?.id || '').includes(presonId);
  };

  if (!member) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }
  if (checkIsTherePersonDublicates(member.person?.id, members)) {
    return [{ isValid: false, invalidMessage: 'Выбранная персона уже есть в списке' }];
  }
  return [];
}

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { PaymentRequestStatus } from 'utils/Enums';

export type PaymentFeedlineRequestData = {
  employeePaymentId: string;
  isDisabledToolbar?: boolean;
  status: PaymentRequestStatus | null;
};

export function GetEmployeePaymentRequestFeedlineList(
  deps: PaymentFeedlineRequestData,
): Table.Specification<State, PaymentFeedlineRequestData> {
  return {
    apiID: 'GetEmployeePaymentRequestFeedlineList',
    header: {
      firstLevel: {
        title: 'Журнал сообщений',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: deps,
    useCustomController: makeUseCustomController(deps),
  };
}

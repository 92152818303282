import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { MessageModal } from '../../GetProjectFeedlineList/Toolbar/MessageModal';
import { ProjectEditPartial } from 'features/Table/views';
import { ProjectAnalyticGridHelp } from './help';
import { ProjectCodeChangesInProject } from 'features/Form/views';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ tableState, customState }: Props) {
  const {
    rows,
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isOpenCodeChangesModal,
    setIsOpenCodeChangesModal,
    currentReport,
    isReportOpen,
    onReportClose,
    isFeedlineOpen,
    setIsFeedlineOpen,
    feedline,
    setFeedline,
    submitFeedline,
    paramType,
    isOpenEditPartial,
    setIsOpenEditPartial,
  } = useController({ tableState, customState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <MessageModal
        isOpen={isFeedlineOpen}
        onClose={() => setIsFeedlineOpen(false)}
        feedline={feedline}
        changeFeedline={setFeedline}
        onSubmit={submitFeedline}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: tableState.selectedRows.length > 0 ? tableState.selectedRows[0]?.ProjectID : '',
        }}
      />

      {isOpenEditPartial && (
        <ProjectEditPartial
          isOpen={isOpenEditPartial}
          onClose={() => setIsOpenEditPartial(false)}
          projectIds={rows.map(i => i.ProjectID)}
          paramType={paramType}
        />
      )}

      <ProjectCodeChangesInProject
        isOpen={isOpenCodeChangesModal}
        onClose={() => setIsOpenCodeChangesModal(false)}
        projectId={rows[0]?.id}
        projectNumber={rows[0]?.ProjectNumber}
      />

      <Modal
        mode="help"
        title="Аналитика по всем типам проектов"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ProjectAnalyticGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

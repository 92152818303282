import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectPerformerJobPeriodFiltersHelp() {
  return (
    <>
      <p>
        <strong>Состав на текущую дату</strong> – по фильтру отображаются записи участников проектов, период работы у которых
        актуален на текущую дату
      </p>
      <p>
        <strong>Все</strong> - по фильтру отображаются записи за весь период (если не указан конкретный год в фильтре{' '}
        <strong>Год</strong>)
      </p>
      <p>
        Фильтр <strong>Год</strong> отображает список участников всех проектов, работающих в проекте в течение указанного года
        (т.е. период работы в проекте пересекается с указанным годом)
      </p>
      <p>
        <strong>Нет должности/ГПХ</strong> - отображаются записи тех участников проектов, у которых в столбце Должность
        отсутствует значение
      </p>
      <p>
        <strong>Уволен досрочно</strong> - отображаются записи, если участник проекта был уволен с должности до наступления даты
        исключения из проекта (указанной в периоде работы в проекте)
      </p>
      <p>
        <strong>Исключён из состава</strong> - отображаются записи, тех участников, которые по состоянию на текущую дату не
        числятся в списке коллектива проекта
      </p>
    </>
  );
}

export function ProjectPerformerJobPeriodGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Таблица содержит консолидированный список участников всех проектов и предназначена для:</p>

        <ul>
          <li>
            анализа данных по участникам (по возрасту, нагрузке, гражданству, принадлежности к подразделениям, группам проектов);
          </li>
          <li>контроля своевременности оформления приказов по коллективу и полноту информации о составе коллектива;</li>
          <li>принятия решения о распределения кадровых ресурсов и др.</li>
        </ul>

        <h4>Описание работы фильтров</h4>
        {ProjectPerformerJobPeriodFiltersHelp()}

        <div className="warning">
          <span>
            В таблице подсвечиваются строки с участниками проекта, если <strong>Дата исключения</strong> из проекта меньше{' '}
            <strong>Текущей</strong> и меньше <strong>Даты окончания проекта</strong> (т.е. по состоянию на текущую дату не
            числится в списке коллектива проекта). Это удобно для принятия решения об оплате, контроля по составу и численности
            коллектива.
          </span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название столбца</th>
              <th>Значение в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Тип проекта</td>
              <td>Названия групп проектов (гранты, задания на НИР, и т.д.)</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Значение поля Номер проекта на форме проекта</td>
            </tr>
            <tr>
              <td>Начало проекта</td>
              <td>Дата начала проекта</td>
            </tr>
            <tr>
              <td>Окончание проекта</td>
              <td>Дата окончания проекта</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>
                Краткое название программы, которая указана в проекте. Является дополнительным признаком для анализа данных по
                группам проектов.
              </td>
            </tr>
            <tr>
              <td>ФИО</td>
              <td>
                Персональные данные участника проекта. Если у персоны были изменения ФИО, то предыдущие значения указывается в
                скобках.
              </td>
            </tr>
            <tr>
              <td>Дата рождения</td>
              <td>Персональные данные</td>
            </tr>
            <tr>
              <td>Роль</td>
              <td>Роль в проекте</td>
            </tr>
            <tr>
              <td>Включение в состав коллектива</td>
              <td>Дата начала периода работы в проекте</td>
            </tr>
            <tr>
              <td>Приказ на включение</td>
              <td>Номер и дата приказа, который указан для даты начала периода работы в проекте</td>
            </tr>
            <tr>
              <td>Исключение</td>
              <td>Дата окончания периода работы в проекте</td>
            </tr>
            <tr>
              <td>Приказ на исключение из состава коллектива</td>
              <td>Номер и дата приказа, который указан для даты окончания периода работы в проекте</td>
            </tr>
            <tr>
              <td>Место работы</td>
              <td>Название подразделения, из поля Место работы на форме Персональные данные в период работы проекта.</td>
            </tr>
            <tr>
              <td>Должность</td>
              <td>Должность, указанная на форме Персональные данные в поле Место работы для периода работы проекта.</td>
            </tr>
            <tr>
              <td>Место обучения</td>
              <td>Должность, указанная на форме Персональные данные в период работы проекта</td>
            </tr>
            <tr>
              <td>Гражданство</td>
              <td>Название страны, указанной в поле Гражданство на форме Персональные данные в период работы проекта</td>
            </tr>
            <tr>
              <td>Возраст</td>
              <td>Возраст (полных лет) вычисляется динамически по состоянию на текущую дату</td>
            </tr>
            <tr>
              <td>Степень, звание</td>
              <td>
                Краткое название учёной степени, учёного звания, академического звания указанные на форме Персональные данные в
                период работы проекта
              </td>
            </tr>
            <tr>
              <td>Изменения в периоде</td>
              <td>
                <strong>Список изменений в период проекта персональных данных.</strong> Любое изменение персональных данных
                необходимо оформить приказом по изменению в составе проекта (если это предусмотрено внутренним регламентом учётной
                политики по проектам или условиями договора на проект)
              </td>
            </tr>
            <tr>
              <td>Выплаты</td>
              <td>
                Информация о выплатах за счёт средств проекта (по данным бухгалтерского учёта) в разрезе шифров (финансово-лицевых
                счетов проекта). Форма вывода значения: &lt;Номер шифра проекта&gt;: &lt;Год&gt; (список месяцев с выплатами).
              </td>
            </tr>
            <tr>
              <td>Подразделение</td>
              <td>
                Список подразделений, которые указаны в проекте в разделе: <strong>Проект выполняется на базе.</strong>
              </td>
            </tr>
            <tr>
              <td>Факультет/Институт</td>
              <td>
                Список подразделений на уровне факультета/ института, которые указаны в проекте в разделе:{' '}
                <strong>Проект выполняется на базе.</strong>
              </td>
            </tr>
            <tr>
              <td>Статусы в проекте</td>
              <td>
                Статусы в проекте зависят от данных, которые указаны на форме
                <br />
                Персональные данные на период работы в проекте:
                <ul>
                  <li>Сотрудник - если указано место работы в подразделении университета;</li>
                  <li>Студент - если указано место обучения и специальность обучения в подразделении университета;</li>
                  <li>Аспирант - если указано место обучения и специальность обучения в аспирантуре университета;</li>
                  <li>
                    Сотрудник другой организации – если место работы- это работа по договору ГПХ или работа в другой организации;
                  </li>
                  <li>
                    Студент другой организации и Аспирант другой организации - если указано место обучения и специальность в
                    другом учреждении.
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Все статусы персоны</td>
              <td>
                Перечень всех статусов персоны в период проекта по данным справочника <strong>Персоны</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                <Icon type="print" /> Отчёты
              </td>
              <td>
                Кнопка открывает форму со списком доступных пользователю шаблонов для формирования стандартных документов о
                составе коллектива конкретного проекта.
              </td>
            </tr>
            <tr>
              <td>
                <Icon type="merge" mode="info" /> Оформить периоды работы по приказу
              </td>
              <td>
                Кнопка активна, если в таблице выделена одна или несколько записей с участниками одного проекта; позволяет внести
                информацию о приказах по составу коллектива на период для выделенной группы участников, а также изменить даты
                периода.
              </td>
            </tr>
            <tr>
              <td>
                <Icon type="excel" /> Выгрузить в файл
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';

import { Settings } from 'types/models/common';
import {
  GetForeignProjectRequest,
  SaveForeignProjectRequest,
  ApproveForeignProjectRequest,
  SavePersonalForeignProjectRequest,
} from './types';

import { makeTableRequestXMLConverter, makeParametersXMLElement } from '../commonConverters';
import { convertForeignProject } from './converters';
import { convertForeignProjectToXML } from './requestConverters';
import { GetForeignProjectListRequest } from '../types';

export const foreignProjectGridConfigurations = {
  GetPersonalForeignProjectList: makeBackendTableAPIConfiguration(
    'GetPersonalForeignProjectList',
    makeTableRequestXMLConverter('GetPersonalForeignProjectList', () => makeParametersXMLElement([])),
  ),
  GetForeignProjectList: makeBackendTableAPIConfiguration(
    'GetForeignProjectList',
    makeTableRequestXMLConverter('GetForeignProjectList', ({ panelState }: GetForeignProjectListRequest) =>
      makeParametersXMLElement([...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : [])]),
    ),
  ),
};

export const foreignProjectConfigurations = {
  GetForeignProject: makeBackendAPIConfiguration({
    id: 'GetForeignProject',
    endpoint: '/msa/service/commands/GetForeignProject',
    convertInputToXMLElement: ({ id }: GetForeignProjectRequest) => ({
      Request: {
        _attr: { commandName: 'GetForeignProject' },
        ForeignProject: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertForeignProject(response?.Response?.ForeignProject, settings),
  }),
  SaveForeignProject: makeBackendAPIConfiguration({
    id: 'SaveForeignProject',
    endpoint: '/msa/service/commands/SaveForeignProject',
    convertInputToXMLElement: ({ foreignProject }: SaveForeignProjectRequest) => ({
      Request: {
        _attr: { commandName: 'SaveForeignProject' },
        ForeignProject: convertForeignProjectToXML(foreignProject),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePersonalForeignProject: makeBackendAPIConfiguration({
    id: 'SavePersonalForeignProject',
    endpoint: '/msa/service/commands/SavePersonalForeignProject',
    convertInputToXMLElement: ({ foreignProject }: SavePersonalForeignProjectRequest) => ({
      Request: {
        _attr: { commandName: 'SavePersonalForeignProject' },
        ForeignProject: convertForeignProjectToXML(foreignProject),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ApproveForeignProject: makeBackendAPIConfiguration({
    id: 'ApproveForeignProject',
    endpoint: '/msa/service/commands/ApproveForeignProject',
    convertInputToXMLElement: ({ id, isDisapprove }: ApproveForeignProjectRequest) => ({
      Request: {
        _attr: { commandName: 'ApproveForeignProject' },
        ForeignProject: { _attr: { id, disapprove: isDisapprove } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

import React from 'react';

import { Modal, Toolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { ProcessProjectReports } from 'features/Form/views';
import { ProjectStateRegistrationGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};
function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    projectId,
    isProcessModalOpen,
    setIsProcessModalOpen,
    project,
    setProject,
    isHelpOpen,
    setIsHelpOpen,
  } = useController({ customState, tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!project && (
        <ProcessProjectReports
          isOpen={isProcessModalOpen}
          onClose={() => {
            setIsProcessModalOpen(false);
            setProject(null);
          }}
          project={project}
        />
      )}

      <Modal mode="help" title="Проекты" isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} size="large">
        {ProjectStateRegistrationGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: projectId,
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { CustomProps } from 'features/Table/specifications/GetSubjectList/LeftPanelForThirdLevel/controller';

const SubjectEditTemplate: React.FC<F.TemplateProps & CustomProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  subjectType,
  isSubject,
}: F.TemplateProps & CustomProps) => {
  const { userPermission } = useAppDataContext();
  const editMode = isHasPermission(userPermission, Permits.SUBJECT_EDIT);
  const look: F.SubjectFormLook = {
    apiID: 'GetSubject',
    template: 'SubjectForm',
    editMode,
    viewMode: !editMode,
    id,
    relatedTableState,
    subjectType,
    isSubject,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактирование ${isSubject ? 'тематики' : 'продукта'} "${relatedTableState?.selectedRows[0]?.name || ''}"`}
      size="full"
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(SubjectEditTemplate);

import React, { useEffect } from 'react';

import { ButtonMode, FormComponent, ListEdit, Modal, SectionTitle, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { AuthorInputs } from 'features/Form/looks/rntd/RntdForm/Tabs/AboutRntd/AuthorInputs';
import { validateAuthor } from 'features/Form/looks/rntd/RntdForm/Tabs/AboutRntd/validate';
import { showErrors } from 'utils/Validators';
import { Table } from 'types/models';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { GetPartnerList, GetSubjectForExponentList } from 'features/Table/specifications';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ExponentForm({ viewMode, editMode, onClose }: Props) {
  const {
    exponent,
    formFields,
    workMode,
    handleFormSubmit,
    handleAuthorsChange,
    modalTableSubjectConverter,
    partnerTableRowConverter,
    handlePartnersChange,
    handleSubjectChange,
    securityDocumentsRow,
    presentationFormsRow,
    settings,
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleConfirmPartnerButtonClick,
    checkPartnerStatus,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      null,
    );

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return 'Добавление разработки';
        }
        return [
          workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
          'разработки, представленной на выставке',
          exponent?.id ? `(ID ${exponent?.id})` : '',
        ]
          .filter(Boolean)
          .join(' ');
      })(),
    );
  }, [exponent?.id, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.type.title} isRequired={formFields.type.required}>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={formFields.type.value}
                  onChange={formFields.type.onChange}
                  settings={{
                    name: 'RefExhibitType',
                    title: 'Справочник "Типы разработок"',
                    isClearable: !formFields.type.required,
                  }}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.name.title} isRequired>
                <TextArea
                  mode={TextAreaMode.MODAL}
                  settings={{ rows: 3, title: formFields.name.title }}
                  value={formFields.name.value}
                  onChange={formFields.name.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field
                label="Продукт для коммерциализации (ОИС)"
                tooltip="Поле заполняется, если разработка связана с объектами интеллектуальной собственности"
              >
                <TextArea
                  mode={TextAreaMode.TABLE}
                  settings={{
                    title: 'Продукт для коммерциализации (ОИС)',
                    table: {
                      specification: GetSubjectForExponentList(),
                      onSelect: (row: Table.Entry | null) => handleSubjectChange(row ? modalTableSubjectConverter(row) : null),
                    },
                  }}
                  value={exponent?.subject?.name}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.note.title}>
                <TextArea
                  mode={TextAreaMode.MODAL}
                  settings={{ rows: 3, title: formFields.note.title }}
                  value={formFields.note.value}
                  onChange={formFields.note.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <>
              {securityDocumentsRow && (
                <FormComponent.Field label="Объекты интеллектуальной собственности">
                  <div>{securityDocumentsRow}</div>
                </FormComponent.Field>
              )}
              {presentationFormsRow && (
                <FormComponent.Field label="Формы представления на выставках">
                  <div>{presentationFormsRow}</div>
                </FormComponent.Field>
              )}
            </>
          </FormComponent.Column>

          <FormComponent.Column>
            <SectionTitle title="Контактные лица" />
            <ListEdit
              rows={exponent?.authors || []}
              onChange={handleAuthorsChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'ФИО', formatValue: x => x?.person?.fullName || '', styles: { width: '30%' } },
                {
                  label: 'Должность',
                  formatValue: x => {
                    const result = getSplittedHistoryLabel(x);
                    return result.rank || result.error || '';
                  },
                  styles: { width: '25%' },
                },
                {
                  label: 'Информация о работе/обучении',
                  formatValue: x => {
                    const result = getSplittedHistoryLabel(x);
                    return result.job || result.error || '';
                  },
                  styles: { width: '45%' },
                },
              ]}
              maxHeight="300px"
              defaultRowsCount={10}
              isDisabled={!!viewMode}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
                validation: {
                  checkIsValid: a => validateAuthor(a).every(x => x.isValid),
                  onInvalidate: val => showErrors(validateAuthor, val),
                },
              }}
            />

            <SectionTitle title="Разработка создана совместно с организациями" />

            <ListEdit
              rows={exponent?.partners || []}
              onChange={handlePartnersChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[{ label: 'Название организации', formatValue: x => `${x.name} (${x.address})` }]}
              maxHeight="300px"
              defaultRowsCount={5}
              withoutHead
              isDisabled={!!viewMode}
              specification={{
                mode: 'relationTableModal',
                modalTableRowConverter: partnerTableRowConverter,
                relationTableModalTitle: 'Список организаций',
                onPreSubmit: checkPartnerStatus,
                modalTableSpecification: GetPartnerList({
                  toolbarMode: 'ref',
                  hasSelectButton: true,
                  isCanSelectOnlyOneRow: true,
                }),
              }}
            />

            <Modal
              mode="warning"
              title="Предупреждение"
              isOpen={isConfirmPopupOpen}
              onClose={() => setIsConfirmPopupOpen(false)}
              actions={[
                {
                  mode: ButtonMode.PRIMARY,
                  text: 'Да',
                  onClick: handleConfirmPartnerButtonClick,
                },
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Нет',
                  onClick: () => setIsConfirmPopupOpen(false),
                },
              ]}
              size="small"
            >
              <>
                Выбранная организация не имеет статуса "Партнёр ${settings?.organization?.shortName}". Присвоить статус
                партнёрской организации?
              </>
            </Modal>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ExponentForm);

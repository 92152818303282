import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Таблица содержит список проектов одного типа.</p>

        <p>
          Фильтр <strong>Год</strong> – отображает список из проектов, у которых сроки выполнения пересекаются с указанным годом.
        </p>

        <p>
          Фильтр <strong>Нет номера гос. регистрации</strong> — отображает список из проектов, у которых не заполнено поле номер
          гос. регистрации.
        </p>

        <div className="warning">
          <span>
            Система фиксирует: кто и когда создал и редактировал запись. В таблице для отображения этой информации служат столбцы:
            Добавлено, Дата добавления, Изменено, Дата изменения.
          </span>
        </div>

        <h4>Панель инструментов для настройки таблицы</h4>

        <p>
          Основные столбцы таблицы содержат данные проекта, названия столбцов соответствуют названиям полей на форме проекта.
          Дополнительные столбцы таблицы содержат данные связанных с проектом заявок, шифров, объектов интеллектуальной
          собственности, документов бухгалтерского учёта.
        </p>

        {ProjectAboutGridHelp()}
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>

        <div className="warning">
          <span>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом. Открывает форму с данными о проекте для просмотра.
                Состав формы зависит от наличия у пользователя <strong>прав на просмотр вкладок</strong> формы.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>
                <ol>
                  <li>
                    Кнопка активна если у пользователя есть <strong>право добавления проекта</strong> этого типа. Варианты для
                    добавления нового проекта: Создать проект без заявки — открывает пустую форму для заполнения;
                  </li>
                  <li>Создать на основе заявки на конкурс — открывает форму, с заполненными данными из заявки;</li>
                  <li>Создать на основе заявки на внутренний конкурс — открывает форму, с заполненными данными из заявки;</li>
                  <li>
                    Создать на основе Договора о распоряжении интеллектуальным правом — открывает форму с заполненными данными из
                    договора.
                  </li>
                </ol>

                <p>
                  Варианты 2-4 используются если есть соотвествующие записи в базе данных системы. Это помогает избежать двойного
                  ввода одинаковой информации и сохранить приемственность смысловых связей между объектами.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись в проектом. Открывает форму с данными о проекте для редактирования.
                Состав формы зависит от наличия у пользователя <strong>прав на просмотр и редактирования вкладок</strong> формы.
                <br />
                Если запись открыта для редактирования, то система не позволит открыть эту запись для другого пользователя и
                выведет на экран сообщение: «Сущность заблокирована».
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом.
                <br />
                Проект, у которого есть связи с другими объектами (Шифром проекта, заявкой, договором, РИД и пр.) система не
                позволит удалить.
              </td>
            </tr>
            <tr>
              <td>
                Добавить значение классификатора для выделенной группы проектов: <Icon type="documents" mode="edit" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом. Это групповая функция: можно выделить группу проектов с
                помощью мышки при нажатой клавиши Shift или Ctrl.
                <br />
                При нажатии кнопки открывается список классификаторов. После выбора классификатора откроется дополнительная форма
                для процедуры добавления элементов (одного или нескольких) из классификатора для всх проектов из выделенной
                группы.
                <div className="warning">
                  <span>
                    Настройте таблицу так, чтобы на экране вы видели столбцы с классификаторами. После выполнения фукции вы сразу
                    увидите результат.
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Добавить сообщение: <Icon type="message" mode="add" />
              </td>
              <td>
                Это инструмент предназначен для коммуникации между пользователями, работающими с проектами.
                <br />
                Например, рекомендовано добавлять сообщение с темой «Сведения актуализированы».
                <div className="warning">
                  <span>
                    у таблицы в <strong>режиме для анализа</strong> есть два служебных столбца:
                    <ul>
                      <li>
                        Сообщение – выводиться последнее сообщение из добавленных пользователями (кроме тех, у которых тема =
                        «Сведения актуализированы»)
                      </li>
                      <li>
                        Сведения актуализированы — выводится информация в формате: &lt;Текст сообщения&gt; &lt;Дата&gt; &lt;ФИО
                        пользователя&gt;.
                      </li>
                    </ul>
                  </span>
                </div>
                <p>Все добавленные сообщения хранятся в записи о проекте на вкладке «Журнал сообщений».</p>
              </td>
            </tr>
            <tr>
              <td>
                История изменений: <Icon type="clock" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом; открывает окно{' '}
                <strong>Журнала изменений в проекте, в котором система автоматически</strong> фиксирует изменения в ключевых
                данных проекта: объём финансирования в календарном плане, сроки, источник финансирования и подразделения, на базе
                которого выполняется проект, а также кто и когда это сделал. Журнал носит информативный характер и выполняет
                функцию коммуникаций между сотрудниками, сопровождающими проект и финансовой группой.
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                Инструмент предназначен для построения отчёта по шаблону. При нажатии кнопки появится список доступных
                пользователю отчётов (при условии, что у пользователя есть право на группу отчётов).
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

export function ProjectAboutGridHelp(): JSX.Element {
  return (
    <>
      <h4>Особенности вывода информации в столбцах таблицы:</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Название столбца</th>
            <th>Описание</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Объём финансирования</td>
            <td>
              {/* eslint-disable-next-line max-len */}
              Общая сумма по всем источникам финансирования из <strong>Календарного плана</strong> проекта. При включенном фильтре{' '}
              <strong>Год</strong>, отображается объём финансирования на указанный год (из раздела{' '}
              <strong>Распределение по годам</strong>).
            </td>
          </tr>
          <tr>
            <td>
              ОИФ тип источника,
              <br />
              ОИФ источник
            </td>
            <td>
              Основной источник финансирования (ОИФ), его тип и название источника – данные из раздела Источники финансирования
              проекта.
            </td>
          </tr>
          <tr>
            <td>
              СФ1 тип источника,
              <br />
              СФ1 источник
            </td>
            <td>Первый источник софинансирования (СФ1), его тип и название источника.</td>
          </tr>
          <tr>
            <td>
              СФ2 тип источника,
              <br />
              СФ2 источник
            </td>
            <td>Второй источник софинансирования (СФ2); его тип и название источника.</td>
          </tr>
          <tr>
            <td>Средства &lt;Название университета&gt;</td>
            <td>Дополнительный вид финансирования; из раздела Источники финансирования проекта – Собственные средства</td>
          </tr>
          <tr>
            <td>Средства Партнёра</td>
            <td>
              Вид финансирования, который не предполагает поступления средств на счёт университета, но обязательный по условиям
              договора; из раздела Источники финансирования проекта – Индустриальный партнёр.
            </td>
          </tr>
          <tr>
            <td>Поступление средств, план</td>
            <td>
              Общая сумма по всем источникам финансирования из <strong>Финансового плана Шифров проекта</strong>. При включенном
              фильтре <strong>Год</strong>, отображается объём финансирования на указанный год (из раздела{' '}
              <strong>Распределение по годам</strong>).
            </td>
          </tr>
          <tr>
            <td>ОИФ, план</td>
            <td>Плановая сумма (руб.) по основному источнику финансирования – из раздела Календарный план на форме проекта.</td>
          </tr>
          <tr>
            <td>ОИФ, факт</td>
            <td>
              Сумма приходных документов бухгалтерского учёта по шифру проекта, соответствующего источнику финансирования проекта
              ОИФ – из функционального блока Финансы —&gt; Поступления средств на шифр
            </td>
          </tr>
          <tr>
            <td>СФ1, план</td>
            <td>Плановая сумма (руб.) по источнику софинансирования СФ1– из раздела Календарный план на форме проекта.</td>
          </tr>
          <tr>
            <td>СФ1, факт</td>
            <td>
              Сумма приходных документов бухгалтерского учёта по шифру проекта, соответствующего источнику финансирования проекта
              СФ1 – из функционального <strong>блока Финансы —&gt; Поступления средств на шифр</strong>
            </td>
          </tr>
          <tr>
            <td>СФ2, план</td>
            <td>Плановая сумма (руб.) по источнику софинансирования СФ2– из раздела Календарный план на форме проекта.</td>
          </tr>
          <tr>
            <td>СФ2, факт</td>
            <td>
              Сумма приходных документов бухгалтерского учёта по шифру проекта, соответствующего источнику финансирования проекта
              СФ2– из функционального <strong>блока Финансы —&gt; Поступления средств на шифр</strong>
            </td>
          </tr>
          <tr>
            <td>Сумма соисполнителям</td>
            <td>
              Вычисленная общая сумма расходных документов бухгалтерского учёта по всем шифрам проекта, у которых вид расходов
              имеет <strong>атрибут «Расчёты с соисполнителем»</strong> – из функционального{' '}
              <strong>блока Финансы —&gt; Реестр расходных документов</strong>.
            </td>
          </tr>
          <tr>
            <td>НДС, сумма план</td>
            <td>
              Вычисляется по данным источника финансирования, у которого включен атрибут <strong>«НДС»</strong> (в разделе Общий
              объём финансирования» на форме проекта)
            </td>
          </tr>
          <tr>
            <td>Соисполнители</td>
            <td>
              Список названий организации, указанных в документах бухгалтерского учёта по всем шифрам проекта, у которых вид
              расходов имеет <strong>атрибут «Расчёты с соисполнителем»</strong> – из функционального{' '}
              <strong>блока Финансы —&gt; Реестр расходных документов</strong>.
            </td>
          </tr>
          <tr>
            <td>Адрес соисполнителя</td>
            <td>
              Адрес организации; источник данных – справочник Заказчики, который содержит коллекцию организаций-контрагентов из
              информационной системы бухгалтерского учёта.
            </td>
          </tr>
          <tr>
            <td>О соисполнителях</td>
            <td>
              Название организации-соисполнителя и дополнительные сведения об организации из справочника{' '}
              <strong>Заказчики</strong> (ИНН, КПП, сектор деятельности, тип организации).
            </td>
          </tr>
          <tr>
            <td>Охрана РИД планируется</td>
            <td>
              По данным этапа проекта ( на форме описания этапа проекта). Символ «галка» отображается с учётом фильтра{' '}
              <strong>Год</strong>
            </td>
          </tr>
          <tr>
            <td>Охранные документы</td>
            <td>Список полученных патентов, свидетельств на результаты интеллектуальной деятельности; в блоке ОИС,</td>
          </tr>
          <tr>
            <td>Сведения актуализированы</td>
            <td>
              Служебный столбец для вывода <strong>последнего сообщения с типом: Сведения актуализированы</strong> — является
              инструментом для коммуникаций между пользователями в процессе подготовки к отчётности и актуализации данных о
              проекте. Формат данных: &lt;ФИО пользователя&gt; &lt;Дата добавления сообщения&gt;: &lt;Текст сообщения&gt;. Все
              сообщения, в том числе системные, сохраняются в проекте на вкладке <strong>Журнал сообщений</strong>.
            </td>
          </tr>
          <tr>
            <td>Кол-во исполнителей всего</td>
            <td>
              Вычисляется динамически по данным проекта на вкладке <strong>Коллектив</strong> – общее количество в списке
              участников
            </td>
          </tr>
          <tr>
            <td>Кол-во исполнителей в году всего</td>
            <td>
              Вычисляется динамически по данным проекта на вкладке <strong>Коллектив</strong> – общее количество в списке
              участников, у которых период работы в проекте пересекается с указанным в фильтре годом.
            </td>
          </tr>
          <tr>
            <td>Кол-во с учёной степенью</td>
            <td>
              Вычисляется динамически по данным проекта на вкладке <strong>Коллектив</strong> – общее количество в списке
              участников, имеющих учёную степень в период проекта.
            </td>
          </tr>
          <tr>
            <td>Кол-во молодых</td>
            <td>
              Вычисляется динамически по данным проекта на вкладке <strong>Коллектив</strong> – общее количество в списке
              участников до 39 полных лет по состоянию <strong>на дату начала проекта</strong>.
            </td>
          </tr>
          <tr>
            <td>Номер заявки</td>
            <td>
              Номер заявки на конкурс; в проекте отображается на вкладке <strong>Заявки</strong>
            </td>
          </tr>
          <tr>
            <td>Договоры на использование ИС</td>
            <td>
              Информация о договоре о распоряжении интеллектуальной собственностью университета, на основе которого создан проекта
              характер проекта, как правило, - лицензионный. Сведения о договоре – на вкладке <strong>Заявки</strong> формы
              проекта.
            </td>
          </tr>
          <tr>
            <td>СМК</td>
            <td>
              Информация о наличии требований системы менеджмента качества к госрегистрации проекта и оформлению отчётов по ГОСТу
              РВ (на вкладке Особенности проекта, Требования к проекту).
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

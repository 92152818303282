import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

const useController = () => {
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [],
  );

  return {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;

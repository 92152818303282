import { getText } from 'services/BackendAPI/configurations/commonConverters';

export const sortByPosition = <T extends { position: string | number }>(positionList: T[]): T[] =>
  positionList.sort((a, b) => {
    const aPosition = typeof a.position === 'number' ? a.position : parseInt(a.position, 10) || 0;
    const bPosition = typeof b.position === 'number' ? b.position : parseInt(b.position, 10) || 0;

    return aPosition - bPosition;
  });

export const sortByPositionXML = <T extends { Position: any }>(positionList: T[]): T[] =>
  positionList.sort
    ? positionList.sort((a, b) => {
        const aPosition = parseInt(getText(a.Position), 10) || 0;
        const bPosition = parseInt(getText(b.Position), 10) || 0;

        return aPosition - bPosition;
      })
    : positionList;

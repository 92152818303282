import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

type UserPermissionTemplateProps = {
  login: string;
  connectedPerson: string;
} & F.TemplateProps;

const UserPermissionTemplate: React.FC<UserPermissionTemplateProps> = ({
  isOpen,
  onClose,
  id,
  login,
  connectedPerson,
  relatedTableState,
}: UserPermissionTemplateProps) => {
  const look: F.Look = {
    apiID: 'GetUser',
    template: 'UserPermissionForm',
    viewMode: true,
    id,
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Роли и права пользователя ${login}${connectedPerson ? ` (${connectedPerson})` : ''}`}
      size={['extralarge', 'large-height']}
      isViewMode
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(UserPermissionTemplate);

import React, { memo } from 'react';
import { Modal } from 'components';
import { Component as ApprovePopup } from './component';

type Props = {
  onApprove: (disapprove: boolean) => void;
  isOpen: boolean;
  onClose: () => void;
  selectedRowId: string | null;
};

const ApprovePopupContainer = ({ onApprove, isOpen, onClose, selectedRowId }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Утверждение публикации" size="small">
      <ApprovePopup onClose={onClose} onApprove={onApprove} selectedRowId={selectedRowId} />
    </Modal>
  );
};

export const Component = memo(ApprovePopupContainer);

import * as formats from 'utils/Constants/FormatStr';

const DATE_SEPARATOR = formats.delimiterDate;
const TIME_SEPARATOR = formats.delimiterTime;
const DATE_TIME_SEPARATOR = formats.delimiterDateTime;
export const RANGE_SEPARATOR = formats.delimiterRange;

const rules = {
  day: (value: string = '') => [/[0-3]/, value[0] === '3' ? /[0-1]/ : /[0-9]/],
  month: (value: string = '') => [/[0-1]/, value[0] === '1' ? /[0-2]/ : /[0-9]/],
  year: (value: string = '') => [/[1-2]/, value[0] === '1' ? /[0-9]/ : /[0]/, /[0-9]/, /[0-9]/],
  hour: (value: string = '') => [/[0-2]/, value[0] === '2' ? /[0-3]/ : /[0-9]/],
  minute: () => [/[0-5]/, /[0-9]/],
  second: () => [/[0-5]/, /[0-9]/],
};

const dateMask = (value: string = ''): (string | RegExp)[] => {
  const tails = value.split(DATE_SEPARATOR);
  return [...rules.day(tails[0]), DATE_SEPARATOR, ...rules.month(tails[1]), DATE_SEPARATOR, ...rules.year(tails[2])];
};

const timeMask = (value: string = ''): (string | RegExp)[] => {
  const tails = value.split(TIME_SEPARATOR);
  return [...rules.hour(tails[0]), TIME_SEPARATOR, ...rules.minute(), TIME_SEPARATOR, ...rules.second()];
};

const dateTimeMask = (value: string = ''): (string | RegExp)[] => {
  const tails = value.split(DATE_TIME_SEPARATOR);
  return [...dateMask(tails[0]), DATE_TIME_SEPARATOR, ...timeMask(tails[1])];
};

const rangeMask = (value: string, callback: (value: string) => (string | RegExp)[]): (string | RegExp)[] => {
  const tails = value.split(RANGE_SEPARATOR).map(x => x.trim());
  return [...callback(tails[0]), RANGE_SEPARATOR, ...callback(tails[1])];
};

export const singleDateMask = (value: string): (string | RegExp)[] => dateMask(value);
export const singleTimeMask = (value: string): (string | RegExp)[] => timeMask(value);
export const singleDateTimeMask = (value: string): (string | RegExp)[] => dateTimeMask(value);

export const rangeDateMask = (value: string): (string | RegExp)[] => rangeMask(value, dateMask);
export const rangeTimeMask = (value: string): (string | RegExp)[] => rangeMask(value, timeMask);
export const rangeDateTimeMask = (value: string): (string | RegExp)[] => rangeMask(value, dateTimeMask);

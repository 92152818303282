import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetSystemUpdatesList: Table.Specification = {
  apiID: 'GetSystemUpdatesList',
  isFiltersHidden: true,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

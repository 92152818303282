import React, { useCallback, useMemo } from 'react';

import { FormComponent, Radio, SectionTitle, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { Publication, PublicationType } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';

type Props = {
  publication: Publication;
  setPublication(publication: Publication): void;
};

type ScopusWosProps = {
  title: string;
  publication: Publication;
  makeChangeHandler: (key: keyof Publication) => (value: ValueOf<Publication>) => void;
  scopusKey: keyof Publication;
  wosKey: keyof Publication;
};

function ScopusWos(props: ScopusWosProps) {
  const { title, publication, makeChangeHandler, scopusKey, wosKey } = props;

  return (
    <FormComponent.Wrapper>
      <SectionTitle title={title} />
      <FormComponent.Line>
        <FormComponent.Field label="Scopus" labelSize="fit">
          <TextInput value={(publication[scopusKey] || '') as string} onChange={makeChangeHandler(scopusKey)} />
        </FormComponent.Field>

        <FormComponent.Field label="WoS" labelSize="fit">
          <TextInput value={(publication[wosKey] || '') as string} onChange={makeChangeHandler(wosKey)} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export function Fields(props: Props) {
  const { publication, setPublication } = props;

  const options = useMemo<Item<PublicationType>[]>(
    () => [
      { label: 'Статья', value: 'ARTICLE' },
      { label: 'Монография', value: 'MONOGRAPH' },
      { label: 'Глава в монографии', value: 'MONOGRAPH_CHAPTER' },
    ],
    [],
  );

  const makeChangeHandler = (key: keyof Publication) => (value: ValueOf<Publication>) => {
    setPublication({ ...publication, [key]: value });
  };

  const handleChangeType = useCallback(
    (value: string) => {
      const foundOption = options.find(x => x.value === value);
      if (foundOption) {
        setPublication({ ...publication, type: foundOption });
      }
    },
    [setPublication, publication, options],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Тип публикации">
          <Radio value={publication.type.value} list={options} onChange={handleChangeType} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="DOI">
          <TextInput mode={TextInputMode.DOI} value={publication.doi ?? ''} onChange={makeChangeHandler('doi')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Библиографическая запись" isRequired tooltip="Не более 1024 знаков с пробелами">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, maxLength: 1024, title: 'Библиографическая запись' }}
            value={publication.bibliographicRecord}
            onChange={makeChangeHandler('bibliographicRecord')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <ScopusWos
          title="Цитирование"
          publication={publication}
          makeChangeHandler={makeChangeHandler}
          scopusKey="scopusCitations"
          wosKey="wosCitations"
        />
        <ScopusWos
          title="Квартиль"
          publication={publication}
          makeChangeHandler={makeChangeHandler}
          scopusKey="scopusQuartile"
          wosKey="wosQuartile"
        />
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

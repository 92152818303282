import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

import { Table, Form } from 'types/models';
import { EditPreprintRelation } from 'features/Form/views';

type Props = {
  onClose: () => void;
  printArticle: Form.PrintArticle | null;
  changePrintArticle: (val: Form.PrintArticle | null) => void;
  viewMode: boolean;
  relatedTableState: Table.State | undefined;
  isOpen: boolean;
  sourceRelease: Form.Original | null;
};

const PreprintPublicationsRelations = ({
  isOpen,
  onClose,
  printArticle,
  changePrintArticle,
  relatedTableState,
  sourceRelease,
  viewMode,
}: Props) => (
  <Modal
    title="Редактирование связи препринта с публикацией"
    isOpen={isOpen}
    onClose={onClose}
    actions={[
      {
        mode: ButtonMode.PRIMARY,
        text: 'Продолжить',
        onClick: onClose,
      },
    ]}
    size="medium"
  >
    <EditPreprintRelation
      handleChangePrintArticle={changePrintArticle}
      printArticle={printArticle}
      isDisabled={viewMode}
      releaseId={sourceRelease?.id}
      releaseYear={sourceRelease?.year}
      relatedTableState={relatedTableState}
    />
  </Modal>
);

export const Container = memo(PreprintPublicationsRelations);

import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

export default [
  {
    id: '1',
    label: ParticipationTypes.PARTICIPATION_CONFERENCE.label,
    labelDeclined: ParticipationTypes.PARTICIPATION_CONFERENCE.genitiveCaseLabel,
    code: ParticipationTypes.PARTICIPATION_CONFERENCE.code,
    template: ParticipationTypes.PARTICIPATION_CONFERENCE.template,
  },
  {
    id: '2',
    label: ParticipationTypes.PARTICIPATION_CONTEST.label,
    labelDeclined: ParticipationTypes.PARTICIPATION_CONTEST.genitiveCaseLabel,
    code: ParticipationTypes.PARTICIPATION_CONTEST.code,
    template: ParticipationTypes.PARTICIPATION_CONTEST.template,
  },
  {
    id: '3',
    label: ParticipationTypes.PARTICIPATION_CONCERT.label,
    labelDeclined: ParticipationTypes.PARTICIPATION_CONCERT.genitiveCaseLabel,
    code: ParticipationTypes.PARTICIPATION_CONCERT.code,
    template: ParticipationTypes.PARTICIPATION_CONCERT.template,
  },
  {
    id: '4',
    label: ParticipationTypes.PARTICIPATION_EXPOSITION.label,
    labelDeclined: ParticipationTypes.PARTICIPATION_EXPOSITION.genitiveCaseLabel,
    code: ParticipationTypes.PARTICIPATION_EXPOSITION.code,
    template: ParticipationTypes.PARTICIPATION_EXPOSITION.template,
  },
  {
    id: '5',
    label: EventTypes.EVENT_CONFERENCE.label,
    labelDeclined: EventTypes.EVENT_CONFERENCE.genitiveCaseLabel,
    code: EventTypes.EVENT_CONFERENCE.code,
    template: EventTypes.EVENT_CONFERENCE.template,
  },
  {
    id: '6',
    label: EventTypes.EVENT_CONTEST.label,
    labelDeclined: EventTypes.EVENT_CONTEST.genitiveCaseLabel,
    code: EventTypes.EVENT_CONTEST.code,
    template: EventTypes.EVENT_CONTEST.template,
  },
  {
    id: '7',
    label: EventTypes.EVENT_CONCERT.label,
    labelDeclined: EventTypes.EVENT_CONCERT.genitiveCaseLabel,
    code: EventTypes.EVENT_CONCERT.code,
    template: EventTypes.EVENT_CONCERT.template,
  },
  {
    id: '8',
    label: EventTypes.EVENT_EXPOSITION.label,
    labelDeclined: EventTypes.EVENT_EXPOSITION.genitiveCaseLabel,
    code: EventTypes.EVENT_EXPOSITION.code,
    template: EventTypes.EVENT_EXPOSITION.template,
  },
];

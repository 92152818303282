import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetReceivingDocumentItemList, GetReceivingDocumentList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ReceivingDocuments() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['finance', 'receive-docs'],
    init: ['items'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Зачислено на шифры" id="items">
          <DataGrid specification={GetReceivingDocumentItemList} />
        </Tab>
        <Tab title="Приходные документы" id="list">
          <DataGrid specification={GetReceivingDocumentList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

export type Props = {
  /** @property {string | Permits | (string | Permits)[]} name - Any permission or array of permissions */
  name?: string | Permits | (string | Permits)[];
  /** @property {JSX.Element} children - (optional) Children content */
  children: JSX.Element;
};

/**
 * Check permission(-s) && return children
 *
 * @param name - Any permission or array of permissions
 * @returns JSX
 */
function Permission({ name, children }: Props) {
  const { userPermission } = useAppDataContext();
  const hasAppAccess =
    !(Array.isArray(name) ? name.length : name) || isHasPermission(userPermission, Array.isArray(name) ? name : [name]);

  if (!hasAppAccess) {
    return null;
  }

  return children;
}

export { Permission };

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Permits } from 'utils/Permissions';
import { Table } from 'types/models';
import { DetailProps } from '..';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
};

export const useController = ({ tableState }: Props & Partial<DetailProps>) => {
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const isViewPermitted = useMemo(() => {
    return isHasPermission(userPermission, Permits[`PARTICIPATION_${selectedRow?.['id:typ']}_VIEW` as keyof Permits]);
  }, [selectedRow, userPermission]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: [
            Permits.PARTICIPATION_CONCERT_VIEW,
            Permits.PARTICIPATION_CONFERENCE_VIEW,
            Permits.PARTICIPATION_CONTEST_VIEW,
            Permits.PARTICIPATION_EXPOSITION_VIEW,
          ],
        },
        isDisabled: !selectedRow || !isViewPermitted,
      },
    ],
    [selectedRow, isViewPermitted, handleViewButtonClick],
  );

  return {
    buttons,
    isViewFormOpen,
    handleTemplateCloseViewForm,
  };
};

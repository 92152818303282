import React, { useMemo } from 'react';

import { Checkbox, FormComponent, SectionTitle } from 'components';

import { Event, Form, Table } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { Conferences } from 'features/Form/views/Сonferences';
import { Requests } from 'features/Form/views/Requests';
import { Projects } from 'features/Form/views/Projects';

type Props = {
  workMode: Table.WorkMode;
  relatedTableState: Table.State | undefined;
  events: Event.Event[];
  setEvents(rows: Event.Event[]): void;
  projects: PublicationProject[];
  setProjects(rows: PublicationProject[]): void;
  mobileRequests: PublicationMobileRequest[];
  setMobileRequests(rows: PublicationMobileRequest[]): void;
  formFields: Form.Fields;
  publicationType: string | undefined;
  conferenceEventId?: string;
};

function Events({
  workMode,
  relatedTableState,
  events,
  setEvents,
  projects,
  setProjects,
  mobileRequests,
  setMobileRequests,
  formFields,
  publicationType,
  conferenceEventId,
}: Props) {
  const eventsArray = useMemo(() => Object.values(events), [events]);

  return (
    <>
      {publicationType !== 'OTHER_BOOK' && (
        <>
          <SectionTitle title="Конференции" />

          <FormComponent.Line>
            <Checkbox
              label={formFields.isConfirencesPublished.title}
              checked={!!formFields.isConfirencesPublished.value}
              onChange={formFields.isConfirencesPublished.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <Conferences
              workMode={workMode}
              relatedTableState={relatedTableState}
              formFields={formFields}
              events={eventsArray}
              setEvents={setEvents}
              disabled={Boolean(conferenceEventId)}
            />
          </FormComponent.Line>
        </>
      )}
      <SectionTitle title="Опубликовано при финансовой поддержке" />

      <FormComponent.Line>
        <Projects workMode={workMode} relatedTableState={relatedTableState} projects={projects} setProjects={setProjects} />
      </FormComponent.Line>

      <FormComponent.Line>
        <Requests
          workMode={workMode}
          relatedTableState={relatedTableState}
          requests={mobileRequests}
          setMobileRequests={setMobileRequests}
        />
      </FormComponent.Line>
    </>
  );
}

export const Component = React.memo(Events);

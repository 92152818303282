import React, { useMemo } from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import eventTypes from './eventTypes';
import { PersonalConcertEventGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');
function LeftPanelForThirdLevel({ tableState, customState: { eventType: gridEventType } }: Props) {
  const actualType = useMemo(
    () =>
      eventTypes.filter(event => event.code === gridEventType).length
        ? eventTypes.filter(event => event.code === gridEventType)[0]
        : eventTypes[0],
    [gridEventType],
  );
  const {
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    eventTypeCode,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  } = useController({ tableState, actualType });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={eventTypeCode}
          />
        </div>
      )}

      {isViewFormOpen && (
        <modalTemplates.EventViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.EventEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.EventAddTemplate.Component
          eventTypeCode={eventTypeCode}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.EventName}) Удалить мероприятие?</>
        </Modal>
      )}

      <Modal
        mode="help"
        title="Помощь в работе творческими мероприятиями"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PersonalConcertEventGridHelp(organizationName)}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Arguments = {
  projectId?: string | null;
  projectCodeId?: string | null;
  isDisabled?: boolean;
};

export function GetProjectCodeChangesInProject({
  isDisabled,
  isProcessButtonHidden,
  ...params
}: Arguments & State): Table.Specification<State, Arguments> {
  return {
    apiID: 'GetProjectCodeChangesInProject',
    isFiltersHidden: true,
    requestData: params,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel({ isDisabled }),
      },
    },
    useCustomController: makeUseCustomController({ isProcessButtonHidden }),
  };
}

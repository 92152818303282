import { useMemo, useCallback, useEffect, useState } from 'react';

import { Event } from 'types/models';
import { EnumValue } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';

type Props = {
  memberCountsAnother: Event.MemberCountAnother[] | undefined;
  memberCounts: Event.MemberCount[];
  disabled?: boolean;
  documentMember: Event.MemberCount | null;
  onChangeMember(row: Event.MemberCount): Promise<void>;
};
const useController = ({ documentMember, onChangeMember, memberCountsAnother, memberCounts }: Props) => {
  const emptyRow = useMemo(
    () => ({
      amount: '0',
      type: '',
      label: '',
      amounts: [],
    }),
    [],
  );
  const [isSelectedType, setIsSelectedType] = useState(false);
  const documentHandler = useCallback(
    (value: Event.MemberCountAnother[]) => {
      onChangeMember({ ...(documentMember || emptyRow), amounts: value });
    },
    [onChangeMember, documentMember, emptyRow],
  );

  const throwErrorNotification = useCallback((message: string) => {
    showNotification({ message, theme: 'danger' });
  }, []);

  const { enumMap } = useAppDataContext();
  const initialMemberCountList: Array<EnumValue & { value: string }> = enumMap.EventMemberCountType.values
    .map(params => ({
      ...params,
      value: params.value,
    }))
    .filter(m => !memberCounts.find(mc => mc.type === m.value));

  const initialMemberCountValue = useMemo(
    () =>
      documentMember && documentMember.type
        ? initialMemberCountList.filter(item => item.label === documentMember.type)[0]?.value
        : initialMemberCountList[0]?.value || '',
    [documentMember, initialMemberCountList],
  );
  const onMemberCountChange = useCallback(
    (val: string) => {
      onChangeMember({
        ...(documentMember || emptyRow),
        type: val,
        label: initialMemberCountList.filter(i => i.value === val)[0].label,
      });
    },
    // eslint-disable-next-line
    [onChangeMember, documentMember],
  );
  useEffect(() => {
    if (!documentMember) {
      onChangeMember({
        ...emptyRow,
        type: initialMemberCountValue,
        label: initialMemberCountList.filter(i => i.value === initialMemberCountValue)[0]?.label || '',
      });
    }
    // eslint-disable-next-line
  }, [onChangeMember, documentMember, initialMemberCountValue]);
  const isValidFields = useCallback(
    (row: Event.MemberCountAnother | null) => {
      if (row) {
        const isDepartmentValid = documentMember?.type === 'DEPARTMENT' ? Boolean(row.department?.id) : true;
        const isAmountValid = Number(row.amount) - (Number(row.professors) + Number(row.students)) >= 0;
        if (!isAmountValid) {
          throwErrorNotification('Всего участников не должно быть меньше чем (Профессоров + Студентов)');
          return false;
        }

        const isFormValid = Boolean(row.amount && row.enterprise?.id && row.city && row.country?.id) && isDepartmentValid;
        if (isFormValid) {
          return true;
        }
      }
      throwErrorNotification('Заполните обязательные поля');
      return false;
    },
    [documentMember?.type, throwErrorNotification],
  );

  const checkIsMembersCorrect = (
    identifier: Event.MemberCountAnother | null,
    callback: (updatedRow: Event.MemberCountAnother) => void,
  ) => {
    if (identifier) {
      callback({
        ...identifier,
        students: identifier.students || '0',
        professors: identifier.professors || '0',
      });
    }
  };

  const handleAmountField = useCallback(
    (nextAmount: string) => {
      onChangeMember({ ...(documentMember || emptyRow), amount: nextAmount || '0' });
    },
    [onChangeMember, documentMember, emptyRow],
  );

  const calculateButton = {
    icon: { type: 'calc' },
    title: 'Рассчитать',
    isDisabled: false,
    onClick: (event: any) => {
      event?.currentTarget.blur();
      calculateMembers();
    },
  };

  const calculateMembers = useCallback(() => {
    let counter = 0;
    memberCountsAnother?.forEach(item => {
      counter += parseInt(item.amount, 10);
    });
    onChangeMember({ ...(documentMember || emptyRow), amount: String(counter) });
  }, [onChangeMember, documentMember, emptyRow, memberCountsAnother]);

  const [isOpenedCollapsible, setIsOpenedCollapsible] = useState(false);

  return {
    isOpenedCollapsible,
    changeIsOpenedCollapsible: (val: boolean) => setIsOpenedCollapsible(val),
    isValidFields,
    documentHandler,
    handleAmountField,
    onMemberCountChange,
    calculateButton,
    radioRefs: {
      memberCountVal: documentMember?.type || initialMemberCountValue,
      memberCountDefault: initialMemberCountValue,
      memberCountChange: onMemberCountChange,
      memberCountList: initialMemberCountList,
    },
    isSelectedType,
    selectType: () => setIsSelectedType(true),
    checkIsMembersCorrect,
  };
};
export default useController;

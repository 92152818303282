import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants';
import { getDateYearMonthDayTime } from 'utils/Helpers/getDateYearMonthDayTime';

export const compareDates = (dateFromValue: string, dateToValue: string): { isFromMoreTo: boolean } => {
  const dateFrom = parse(dateFromValue, formatStr, new Date());
  const dateTo = parse(dateToValue, formatStr, new Date());
  const dateFromTime = getDateYearMonthDayTime(dateFrom);
  const dateToTime = getDateYearMonthDayTime(dateTo);

  const isFromMoreTo = dateFromTime > dateToTime;

  return {
    isFromMoreTo,
  };
};

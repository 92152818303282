import { isHasPermission } from 'features/AppData';
import { Event, Table, UserPermission } from 'types/models';
import { EventTypeCode } from 'types/models/Event';
import { RecordStatus } from 'utils/Enums';
import { Permits } from 'utils/Permissions';

export function getEventShortDescription(event?: Event.Event | null): string {
  if (!event) return '';
  const { name, startDate, endDate, country, city } = event;
  const dateString =
    startDate && endDate ? `(${startDate} - ${endDate})` : startDate || endDate ? `(${startDate || endDate})` : '';
  const shortDescription = `${name}. ${country?.label} ${city} ${dateString}`;
  return shortDescription;
}

export function getEventFullName(event?: Event.Event | null): string {
  if (!event) return '';
  const { name, startDate, endDate, country, city } = event;
  const eventDateString = startDate && endDate ? ` ${startDate} - ${endDate}` : ` ${startDate}`;
  const eventCountryString = `${country?.label ? ` ${country.label}${city ? '' : '.'}` : ''}`;
  const eventCityString = `${city ? `, ${city}.` : ''}`;
  const fullName = `${name}.${eventCountryString}${eventCityString}${eventDateString}`;
  return fullName;
}

export function getEventMagazineSource(event?: Event.Event | null) {
  if (!event) return null;
  const { magazineReleases } = event;
  return magazineReleases.length
    ? {
        id: magazineReleases[0].upperSourceId || '',
        status: magazineReleases[0].status || '',
        name: magazineReleases[0].upperSourceName || '',
      }
    : null;
}

export function getEventPermitList(type: EventTypeCode, action: 'EDIT' | 'DELETE', status?: 'ADDED' | 'APPROVED') {
  const addedRule = `${type}_${action}_ADDED`;
  const approvedRule = `${type}_${action}_APPROVED`;

  const result = {
    added: [Permits[`${addedRule}_ALL` as keyof Permits], Permits[`${addedRule}_MY` as keyof Permits]],
    approved: [
      Permits[`${approvedRule}_ALL` as keyof Permits],
      ...(action === 'EDIT'
        ? [
            Permits[`${approvedRule}_MY_ABOUT` as keyof Permits],
            Permits[`${approvedRule}_MY_ORGANIZATION` as keyof Permits],
            Permits[`${approvedRule}_MY_FINANCING` as keyof Permits],
          ]
        : []),
      ...(type === 'CONFERENCE' ? [Permits[`${approvedRule}_MY_CLASSIFICATORS` as keyof Permits]] : []),
    ],
  };

  if (status === 'ADDED') {
    return result.added;
  }

  if (status === 'APPROVED') {
    return result.approved;
  }

  return [...result.added, ...result.approved];
}

export function isEventPermitted(
  userPermission: UserPermission | null,
  selectedRows: Table.Entry[],
  type: EventTypeCode,
  action: 'EDIT' | 'DELETE',
) {
  if (selectedRows.length !== 1) {
    return false;
  }

  const recordStatus = (selectedRows[0]['id:RecordStatus'] ||
    selectedRows[0]['id:EventStatus'] ||
    selectedRows[0]['id:status']) as RecordStatus;

  if (!recordStatus) {
    return false;
  }

  if (recordStatus === RecordStatus.ADDED || recordStatus === RecordStatus.APPROVED) {
    if (isHasPermission(userPermission, Permits[`${type}_${action}_${recordStatus}_ALL` as keyof Permits])) {
      return true;
    }

    return (
      selectedRows[0].IsUserOwned === 'true' && isHasPermission(userPermission, getEventPermitList(type, action, recordStatus))
    );
  }

  return false;
}

export function getMockEvent(): Event.Event {
  return {
    id: '',

    address: '',
    attractedFinancing: '',
    city: '',
    conferenceType: null,
    contract: '',
    country: null,
    description: '',
    endDate: '',
    eventSeries: null,
    eventStatus: null,
    format: null,
    isCompilationPlanned: false,
    isInSummaryPlan: false,
    isMagazineReleasePlanned: false,
    isStudent: false,
    keyWords: '',
    memberCountFact: '',
    memberCountPlan: '',
    name: '',
    orderDate: '',
    orderNumber: '',
    site: '',
    startDate: '',
    status: null,
    type: null,
    isAllowEdit: false,
    isUserOwned: true,
    concertType: null,
    contestType: null,
    expositionType: null,
    contact: '',

    committeMembers: [],
    compilations: [],
    criticalTechnologies: [],
    departments: [],
    documents: [],
    enterprises: [],
    grntis: [],
    magazineReleases: [],
    memberCounts: [],
    ministryEducations: [],
    participations: [],
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    projects: [],
    participationPublications: [],
  };
}

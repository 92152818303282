import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { ValueOf } from 'types/helpers';
import { PurchaseRequestMessage } from 'types/models/PurchaseRequest';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

import { PurchaseRequestData } from '.';

export type State = {
  isOpenModal: boolean;
  message: PurchaseRequestMessage | null;
  isDisabledToolbar: boolean;
  openModal(): void;
  closeModal(): void;
  saveMessage(): void;
  deleteMessage(id: string): void;
  getMessage(id: string): void;
  changeMessage(key: keyof PurchaseRequestMessage, value: ValueOf<PurchaseRequestMessage>): void;
};

export function makeUseCustomController({ purchaseRequestId, isDisabledToolbar = false }: PurchaseRequestData) {
  return function useCustomController(): State {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [message, setMessage] = useState<PurchaseRequestMessage | null>(null);
    const tableStreams = useLocalTableStreams();

    const { methods: getMessageAPI } = BackendAPI.useBackendAPI('GetPurchaseRequestMessage', {
      onSuccessfullCall: ({ data }) => {
        setMessage(data);
      },
    });

    const { methods: saveMessageAPI } = BackendAPI.useBackendAPI('PushPurchaseRequestMessage', {
      onSuccessfullCall: () => {
        closeModal();
        showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
        reloadTable();
      },
    });

    const { methods: deleteMessageAPI } = BackendAPI.useBackendAPI('DeletePurchaseRequestMessage', {
      onSuccessfullCall: () => {
        closeModal();
        showNotification({ message: 'Сообщение успешно удалено', theme: 'success' });
        reloadTable();
      },
    });

    const deleteMessage = useCallback((id: string) => {
      deleteMessageAPI.callAPI({ id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const saveMessage = useCallback(() => {
      if (message) {
        saveMessageAPI.callAPI({
          message: message.message || '',
          purchaseRequestId,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [purchaseRequestId, message]);

    const getMessage = useCallback((id: string) => {
      getMessageAPI.callAPI({ id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
      setMessage(null);
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const changeMessage = useCallback((key: keyof PurchaseRequestMessage, value: ValueOf<PurchaseRequestMessage>) => {
      setMessage(prev => ({ ...prev!, [key]: value }));
    }, []);

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    return {
      isOpenModal,
      message,
      isDisabledToolbar,
      openModal,
      closeModal,
      getMessage,
      changeMessage,
      saveMessage,
      deleteMessage,
    };
  };
}

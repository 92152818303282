import React from 'react';

import { DataGrid } from 'features/Table';
import { GetProjectBoAccompliceList } from 'features/Table/specifications';

export type Props = {
  projectId: string | null;
};

export function CoPerformers(props: Props) {
  const { projectId } = props;

  return <DataGrid specification={GetProjectBoAccompliceList({ projectId: projectId || '-1' })} />;
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { SecurityDocumentAnalyticGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewFormOpen,
    setIsViewFormOpen,
    buttons,
    selectedRow,
    selectedSecurityDocumentType,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!isViewFormOpen && !!selectedSecurityDocumentType && (
        <modalTemplates.SecurityDocumentViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
          selectedSecurityDocumentType={selectedSecurityDocumentType}
        />
      )}

      <Modal
        mode="help"
        title="Объекты интеллектуальной  собственности"
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        size={['large', 'medium-height']}
      >
        {SecurityDocumentAnalyticGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

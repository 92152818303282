import { Person } from 'types/models';
import { Item } from 'types/models/common';

import { Member } from '.';

export function getMockMember(): Member {
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    job: null,
    person: null,
    rank: null,
    historyLabel: '',
  };
}

export function formatJob(job: Person.ScientistJob, isShowAppointment: boolean = true) {
  const { enterpise, appointment, refAppointment, department, refDepartment, status } = job;

  const result = {
    enterpise: enterpise || '',
    status: status?.label || '',
    appointment: isShowAppointment ? appointment || refAppointment?.label || '' : '',
    department: department || refDepartment?.label || '',
  };

  const place = [result.enterpise, result.status].filter(Boolean).join(', ');

  return [[result.appointment, result.department].filter(Boolean).join(', '), place ? `(${place})` : '']
    .filter(Boolean)
    .join(' ');
}

export function formatEducation(education: Person.ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;

  const result = {
    enterprise: enterprise || '',
    status: status?.label || '',
    department: refDepartment?.label || department || '',
    speciality: refSpeciality?.label || speciality || '',
  };

  return [
    [result.status, result.department, result.speciality].filter(Boolean).join(', '),
    result.enterprise ? `(${result.enterprise})` : '',
  ]
    .filter(Boolean)
    .join(' ');
}

export function getMemberWithoutHistory(person: Person.ScientistPerson | null): Member {
  return {
    person,
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    job: null,
    rank: null,
    historyLabel: '',
  };
}

export const getAcademicRankOption = ({ refAcademicRank: academyRank, academy, id }: Person.ScientistAcademicRank) => ({
  value: id || '',
  label: `${academyRank.label} ${academy.label}`,
});

export const getOption = (item: Person.ScientistDegree | Person.ScientistRank | Person.ScientistAcademicRank): Item => {
  const { dateAssign, id, originalId } = item;
  const degree = (() => {
    if (Person.isScientistDegree(item)) {
      return item.refDegree;
    }
    if (Person.isScientistRank(item)) {
      return item.refRank;
    }
    if (Person.isScientistAcademicRank(item)) {
      return item.refAcademicRank;
    }
  })();

  return {
    value: id || originalId || '',
    label: `${degree?.label || ''} (${dateAssign})`,
  } as Item;
};

import React from 'react';

import { FormComponent, Tab, Tabs } from 'components';

import {
  GetPersonalAllProjectList,
  GetPersonalForeignProjectList,
  GetPersonalProjectList,
  GetPersonalContestRequestList,
  GetPersonalProjectAssignmentList,
  GetProjectRestrictionList,
  GetRestrictionProjectList,
} from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { GetPersonalPurchaseRequestList } from 'features/Table/specifications/GetPersonalPurchaseRequestList';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';

type Props = {
  personId: string | null;
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

export function RequestAndProjects(props: Props) {
  const { personId, defaultTabId, onTabClick } = props;

  const { userPermission } = useAppDataContext();

  if (!personId) {
    return null;
  }

  return (
    <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
      <Tab title="Мои научные проекты" id="project" isVisible={isHasPermission(userPermission, Permits.PC_MENU_PROJECTS_ACCESS)}>
        <DataGrid specification={GetPersonalProjectList({ hasToolbarButtonMode: true })} hasPersonalMode />
      </Tab>
      <Tab
        title="Мониторинг ограничений для проектов"
        id="restrictions"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PROJECTRESTRICTIONS_ACCESS)}
      >
        <Tabs>
          <Tab title="Ограничения для проектов">
            <DataGrid specification={GetProjectRestrictionList({ arm: 'pc' })} />
          </Tab>
          <Tab title="Проекты">
            <DataGrid specification={GetRestrictionProjectList({ arm: 'pc' })} />
          </Tab>
        </Tabs>
      </Tab>
      <Tab
        title="Актуализация моих научных проектов"
        id="contest"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_CONTESTREQUESTS_ACCESS)}
      >
        <DataGrid specification={GetPersonalContestRequestList()} />
      </Tab>
      <Tab
        title="Поручения по проектам"
        id="assignment"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PROJECTORDERS_ACCESS)}
      >
        <DataGrid specification={GetPersonalProjectAssignmentList({})} hasPersonalMode />
      </Tab>
      <Tab
        title="Закупки по проектам"
        id="purchase"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PURCHASEREQUESTS_ACCESS)}
      >
        <DataGrid specification={GetPersonalPurchaseRequestList()} hasPersonalMode />
      </Tab>
      <Tab title="Мои проекты в других организациях" id="foreign">
        <FormComponent.Description mode="warning">
          В этом разделе вы можете добавить информацию о своём участии в научном проекте, выполненном в другом университете
          (организации).
        </FormComponent.Description>
        <DataGrid specification={GetPersonalForeignProjectList()} />
      </Tab>
      <Tab
        title="Список научных проектов Университета"
        id="all"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_ALLPROJECTS_ACCESS)}
      >
        <DataGrid
          specification={GetPersonalAllProjectList({ hasToolbarButtonMode: true, defaultYearChecked: false })}
          hasPersonalMode
        />
      </Tab>
    </Tabs>
  );
}

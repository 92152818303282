import { Equip } from 'types/models';

export function getMockEquipVerificationContact(): Equip.EquipVerificationContact {
  return {
    id: null,
    scientist: null,
    department: null,
    code: '',
  };
}

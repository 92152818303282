import { useCallback, useMemo } from 'react';

import { ExtraToolbarButton, ExtraToolbarClickHandler } from 'components/ListEdit/model';

import { showNotification } from 'features/Notifications';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { Award } from 'types/models';

const useController = () => {
  const token = getAuthToken();

  const onClickDownloadFile = useCallback<ExtraToolbarClickHandler<Award>>(
    award => {
      if (award !== null) {
        if (award.file?.id && token) {
          downloadFile(award.file.id, token);
        } else {
          showNotification({ message: 'Загрузка файла невозможна. Для данной записи файл не был выбран. ', theme: 'danger' });
        }
      } else {
        showNotification({ message: 'Выберите награду, для скачивания файла', theme: 'danger' });
      }
    },
    [token],
  );

  const awardsExtraToolbarButtons = useMemo<ExtraToolbarButton<Award>[]>(
    () => [
      {
        icon: { type: 'download' },
        onClick: onClickDownloadFile,
        isDisabled: row => !row || !row?.file?.id,
      },
    ],
    [onClickDownloadFile],
  );

  return { awardsExtraToolbarButtons };
};

export default useController;

import { Arguments } from '.';

export type State = {
  hasToolbarButtonMode: boolean;
};

export function makeUseCustomController({ hasToolbarButtonMode = false }: Arguments) {
  return function useCustomController(): State {
    return {
      hasToolbarButtonMode,
    };
  };
}

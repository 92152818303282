import { Event, ResponseData } from 'types/models';
import { Settings } from 'types/models/common';
import { EventParticipation, EventProjectAcceptPerson } from 'types/models/Event';
import {
  convertDocument,
  convertItem,
  convertMaybeArray,
  convertReference,
  getBoolean,
  getId,
  getLabel,
  getText,
} from '../commonConverters';
import { convertMember } from '../author/converters';
import { convertServerAward } from '../participation/converters';

export function convertServerEvent(event: any, settings: Settings): Event.Event {
  return {
    id: getId(event),
    address: getText(event.Address),
    attractedFinancing: getText(event.AttractedFinancing),
    city: getText(event.City),
    conferenceType: event.ConferenceType ? convertReference(event.ConferenceType) : null,
    concertType: event.ConcertType ? convertReference(event.ConcertType) : null,
    contestType: event.ContestType ? convertReference(event.ContestType) : null,
    contract: getText(event.Contact),
    country: event.Country ? convertReference(event.Country) : null,
    description: getText(event.Description),
    endDate: getText(event.EndDate),
    eventSeries: event.EventSeries ? convertReference(event.EventSeries) : null,
    eventStatus: event.EventStatus ? convertReference(event.EventStatus) : null,
    format: event.Format ? convertItem(event.Format) : null,
    isCompilationPlanned: getBoolean(event.IsCompilationPlanned),
    isInSummaryPlan: getBoolean(event.IsInSummaryPlan),
    isMagazineReleasePlanned: getBoolean(event.IsMagazineReleasePlanned),
    isStudent: getBoolean(event.IsStudent),
    keyWords: getText(event.KeyWords),
    memberCountFact: getText(event.MemberCountFact),
    memberCountPlan: getText(event.MemberCountPlan),
    name: getText(event.Name),
    orderDate: getText(event.OrderDate),
    orderNumber: getText(event.OrderNumber),
    site: getText(event.Site),
    startDate: getText(event.StartDate),
    status: event.Status ? convertItem(event.Status) : null,
    type: event.Type ? convertItem(event.Type) : null,
    expositionType: event.ExpositionType ? convertReference(event.ExpositionType) : null,
    contact: getText(event.Contact),
    isAllowEdit: getBoolean(event.AllowEdit),
    isUserOwned: getBoolean(event.IsUserOwned),
    departments: convertMaybeArray(event.Departments?.Department || [], convertEventDepartment).sort(x => Number(x.position)),
    enterprises: convertMaybeArray(event.Enterprises?.Enterprise || [], convertEventEnterprice).sort(x => Number(x.position)),
    memberCounts: convertEventCountMembers(event),
    documents: convertMaybeArray(event.Documents?.Document || [], i => convertDocument('local', i)),
    magazineReleases: convertMaybeArray(event.MagazineReleases?.MagazineRelease || [], convertSourceMagazineRelease),
    compilations: convertMaybeArray(event.Compilations?.Compilation || [], convertServerCompilation),
    committeMembers: convertMaybeArray(event.CommitteMembers?.CommitteMember || [], m => convertMember(m, settings)),
    projects: convertMaybeArray(event.Projects?.Project || [], convertEventProject),
    participationPublications: convertParticipationPublications(event),
    participations: convertMaybeArray(event.Participations?.Participation, m => convertEventParticipant(m, settings)),
    grntis: convertMaybeArray(event?.Grntis?.Grnti || [], convertReference),
    ministryEducations: convertMaybeArray(event?.MinistryEducations?.MinistryEducation || [], convertReference),
    criticalTechnologies: convertMaybeArray(event?.CriticalTechnologies?.CriticalTechnology || [], convertReference),
    pnrs: convertMaybeArray(event?.Pnrs?.Pnr || [], convertReference),
    pnis: convertMaybeArray(event?.Pnis?.Pni || [], convertReference),
    pnmitrs: convertMaybeArray(event?.Pnmitrs?.Pnmitr || [], convertReference),
  };
}

function convertEventProject(project: any): Event.EventProject {
  return {
    id: getId(project),
    project: project.Project ? convertEventProjectItem(project.Project) : null,
    accepted: getText(project.Accepted),
    acceptedBy: project.AcceptedBy ? convertEventProjectAcceptPerson(project.AcceptedBy) : null,
  };
}

const convertEventProjectAcceptPerson = (person: any): EventProjectAcceptPerson => ({
  id: getId(person),
  fullName: getText(person.FullName),
});

function convertEventProjectItem(project: any): Event.EventProjectItem {
  return {
    id: getId(project),
    number: getText(project.Number),
    name: getText(project.Name),
    program: getText(project.Program),
    leader: getText(project.Leader),
    governances: getText(project.Governances),
    financings: getText(project.Financings),
  };
}

function convertEventCountMembers(event: any): Event.MemberCount[] {
  if (event.MemberCounts?.MemberCount) {
    const convertAmountMembers = (amountItem: any) => {
      const id = getId(amountItem);
      const amount = getText(amountItem.Amount);
      const city = getText(amountItem.City);
      const professors = getText(amountItem.Professors);
      const students = getText(amountItem.Students);
      const country = { id: getId(amountItem.Country), label: getLabel(amountItem.Country) };
      const enterprise = { id: getId(amountItem.Enterprise), label: getLabel(amountItem.Enterprise) };
      const department = { id: getId(amountItem.Department), label: getLabel(amountItem.Department) };
      return { id, amount, city, professors, students, country, enterprise, department };
    };
    const converter = (member: any) => {
      const id = getId(member);
      const amount = getText(member.Amount);
      const type = getText(member.Type);
      const label = getLabel(member.Type);
      const serverAmounts = member.Amounts?.Amount || [];
      const amounts = convertMaybeArray(serverAmounts, convertAmountMembers);
      return { id, amount, label, type, amounts };
    };
    const member = event.MemberCounts.MemberCount;
    return convertMaybeArray(member, converter);
  }
  return [];
}

function convertEventEnterprice(enterprise: any): Event.Enterprise {
  return {
    id: getId(enterprise),
    element: enterprise.Element ? convertReference(enterprise.Element) : null,
    position: getText(enterprise.Position),
    role: enterprise.Role ? convertItem(enterprise.Role) : null,
  };
}

export function convertServerCompilation(compilation: any): Event.Compilations {
  return {
    id: compilation._attributes?.id || '',
    bibliographicRecord: getText(compilation.BibliographicRecord),
    name: getText(compilation.BibliographicRecord),
    typeEdition: compilation.TypeEdition?._attributes?.label || '',
    departments: getText(compilation.Departments),
    status: getLabel(compilation.Status),
    type: getLabel(compilation.Type),
  };
}

export function convertSource(source: any): Event.Compilations {
  return {
    id: source._attributes?.id || '',
    bibliographicRecord: getText(source.BibliographicRecord),
    name: getText(source.BibliographicRecord),
    typeEdition: source.TypeEdition?._attributes?.label || '',
    departments: getText(source.Departments),
    status: getLabel(source.Status),
    type: getLabel(source.Type),
  };
}

export function convertSourceMagazineRelease(source: any) {
  const nameConverter = (m: any) => `
      ${m.Number ? `Выпуск №${m.Number._text}` : ''}
      ${m.Part ? `${m.Number ? ', т' : 'Т'}ом-${m.Part._text}` : ''}
      ${m.Year ? `${m.Number || m.Part ? ', ' : ''}${m.Year._text}г.` : ''}`;
  return {
    id: source._attributes?.id || '',
    name: nameConverter(source),
    year: getText(source.Year),
    number: getText(source.Number),
    part: getText(source.Part),
    status: source.Status._attributes?.label || '',
    upperSourceId: source.Magazine?._attributes.id || '',
    upperSourceName: source.Magazine?.Name._text || '',
    upperSourceStatus: source.Magazine?.Status?._attributes.label || '',
  };
}

function convertEventDepartment(department: any): Event.Department {
  return {
    id: getId(department),
    element: department.Element ? convertReference(department.Element) : null,
    position: getText(department.Position),
    isFinancing: getBoolean(department.IsFinancing),
  };
}

function convertPublicationParticipation(participation: any): EventParticipation {
  return {
    id: getId(participation),
    participationStatus: { label: participation.Status?._attributes?.label || '', value: getText(participation.Status) },
    eventFullName: getText(participation.EventFullName),
    reporter: getText(participation.Reporter),
    reportTheme: getText(participation.ReportTheme),
    reportDate: getText(participation.ReportDate),
    reportType: {
      id: participation.ReportType?._attributes?.id,
      label: getLabel(participation.ReportType),
      code: getText(participation.ReportType?.code),
    },
  };
}

function convertParticipationPublications(event: any) {
  const reportString = (participation: any) => `${getLabel(participation.ReportType)} (${getText(participation.Reporter)})`;
  if (event.Publications?.Publication) {
    const converter = (ParticipationPublication: any) => {
      const id = getId(ParticipationPublication);
      const bibliographicRecord = getText(ParticipationPublication.BibliographicRecord);
      const name = bibliographicRecord;
      const departments = getText(ParticipationPublication.Departments);
      const typePublication = getLabel(ParticipationPublication.Type);
      const status = getLabel(ParticipationPublication.Status);
      const reporters = ParticipationPublication.Participations?.Participation
        ? Array.isArray(ParticipationPublication.Participations.Participation)
          ? ParticipationPublication.Participations.Participation.map(reportString).join('; ')
          : reportString(ParticipationPublication.Participations.Participation)
        : '';
      const participations = convertMaybeArray(
        ParticipationPublication.Participations.Participation,
        convertPublicationParticipation,
      );
      return { id, name, reporters, bibliographicRecord, departments, typePublication, status, participations };
    };
    const ParticipationPublication = event.Publications.Publication;
    return convertMaybeArray(ParticipationPublication, converter);
  }

  return [];
}

export function convertEventParticipant(participation: any, settings: Settings): Event.EventParticipant {
  const convertParticipantMember = (item: any) => {
    const person = item.Person;
    const job = item.Job;
    const education = item.Education;

    return ({
      person: {
        id: person?._attributes.id || '',
        fullName: person?._attributes.fullName || '',
        shortName: person?._attributes.shortName || '',
      },
      job: {
        status: {
          label: job?.Status?._attributes.label,
          value: getText(job?.Status),
        },
      },
      education: {
        isLocal: getText(education?.IsLocal) === 'true',
        status: {
          label: education?.Status?._attributes.label,
          value: getText(education?.Status),
        },
      },
    } as unknown) as Event.EventParticipationMember;
  };

  return {
    id: participation._attributes.id || '',
    members: convertMaybeArray(participation.Members?.Member || [], convertParticipantMember),
    form: convertReference(participation.Form),
    awards: convertMaybeArray(participation.Awards?.Award || [], m => convertServerAward(m, settings)),
  };
}

export const convertApproveEventResponseXMLElementToOutput = (xml: any): ResponseData => ({
  success: xml.Response._attributes.success === 'true',
  message: xml.Response._attributes.errorMessage || xml.Response._attributes.serverErrorMessage || '',
});

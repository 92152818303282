import React, { useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

export type Props = {
  onSave: (needClose: boolean) => void;
  onSaveAndContinue: () => void;
  onHelp: () => void;
  isDisabled?: boolean;
};

function EditMipPartnerPanel({ onSave, onSaveAndContinue, onHelp, isDisabled = false }: Props) {
  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: onHelp,
        isDisabled,
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSaveAndContinue(),
        isDisabled,
      },
    ],
    [onHelp, isDisabled, onSave, onSaveAndContinue],
  );

  return <Toolbar buttons={buttons} mode="form" />;
}

const Component = React.memo(EditMipPartnerPanel);

export { Component as EditMipPartnerPanel };

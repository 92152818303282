import { Form, Document, Author, Event } from 'types/models';
import { Notification } from 'features/Notifications';
import { ParticipationProject } from 'types/models/Participation';
import { getIsDateInPeriod } from 'utils/Validators/getIsDateInPeriod';
import { getIsEqualDates } from 'utils/Validators/getIsEqualDates';
import { getError } from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export const validateReportDate = ({ reportDate, event }: { reportDate: string; event: Event.Event }): Result => {
  const isSingleDateEvent = !!event.startDate && !event.endDate;
  const isPeriodDateEvent = !!event.startDate && !!event.endDate;
  const result: Result = { isFormValid: true, nextNotification: { message: '', theme: 'danger' }, invalidFieldKeys: [] };

  if (isSingleDateEvent) {
    const isEqualDates = getIsEqualDates({ dateString: reportDate, dateStringToCompare: event.startDate });

    result.isFormValid = isEqualDates;
    if (!isEqualDates) {
      result.nextNotification = { theme: 'danger', message: 'Дата доклада должна быть такой же, как в мероприятии' };
      result.isFormValid = false;
      result.invalidFieldKeys = ['reportDate'];
    }
  } else if (isPeriodDateEvent) {
    const isDateInPeriod = getIsDateInPeriod({
      dateString: reportDate,
      dateStringPeriod: { from: event.startDate, to: event.endDate },
    });

    if (!isDateInPeriod) {
      result.nextNotification = {
        theme: 'danger',
        message: `Дата доклада не должна выходить за сроки проекта от ${event.startDate} до ${event.endDate}`,
      };
      result.invalidFieldKeys = ['reportDate'];
      result.isFormValid = false;
    }
  }

  return result;
};

export function validate({
  formFields,
  event,
  members,
}: {
  formFields: Form.Fields;
  event: Event.Event | null;
  members: Author[];
}) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };

  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  if (!event) {
    result.nextNotification = { theme: 'danger', message: 'Поле конференции обязательно для заполнения' };
    result.isFormValid = false;
  }

  if (!members.length) {
    result.nextNotification = { theme: 'danger', message: 'В участии должен быть указан хотя бы один участник' };
    result.isFormValid = false;
  }

  if (!!event && !!formFields.reportDate.value) {
    const reportDateValidateResult = validateReportDate({ reportDate: formFields.reportDate.value, event });
    if (!reportDateValidateResult.isFormValid) {
      result.isFormValid = reportDateValidateResult.isFormValid;
      result.nextNotification = reportDateValidateResult.nextNotification;
      result.invalidFieldKeys.push(...reportDateValidateResult.invalidFieldKeys);
    }
  }

  return result;
}

export function validateReport({ formFields, members }: { formFields: Form.Fields; members: Author[] }) {
  const isSomeMemberReporter = members.some(({ isReporter }) => isReporter);
  if (formFields.isPublished.value === 'true' && formFields.publicationReportTheme.value) {
    const isValidReportPart = isSomeMemberReporter && formFields.reportType.value && formFields.reportDate.value;

    return isValidReportPart;
  }

  return true;
}

export function validateDocuments({ documents }: { documents: Document[] }) {
  const isValidDocuemtns = !!documents.length;

  return isValidDocuemtns;
}

export function validateProjectReport({
  financingProjects,
  formFields,
  members,
}: {
  formFields: Form.Fields;
  financingProjects: ParticipationProject[];
  members: Author[];
}) {
  const isSomeMemberReporter = members.some(({ isReporter }) => isReporter);
  const isProject = Boolean(financingProjects.length);

  if (isProject) {
    const isValidReportPart = isSomeMemberReporter && formFields.reportType.value?.id && formFields.reportDate.value;

    return isValidReportPart;
  }

  return true;
}

export type State = {
  projectId?: string;
  projectSelectable?: boolean;
  isDisabled?: boolean;
};

type Props = {
  projectId?: string;
  projectSelectable?: boolean;
  isDisabled?: boolean;
};

export function makeUseCustomController({ projectId, projectSelectable, isDisabled }: Props) {
  return function useCustomController(): State {
    return {
      projectId,
      projectSelectable,
      isDisabled: isDisabled || false,
    };
  };
}

import React from 'react';

import { Person } from 'types/models';
import { SectionTitle, FormComponent, RefList } from 'components';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';

type Props = {
  person: Person.ScientistPerson | null;
};

export function ScientificInterests(props: Props) {
  const { person } = props;

  if (!person) {
    return null;
  }

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="Научные интересы" />
        <FormComponent.Line>
          <FormComponent.Field label="Направления деятельности">
            <span>{person.scientist?.activities}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Основные научные результаты">
            <span>{person.scientist?.scientificResults}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова на русском языке">
            <span>{person.scientist?.keywordsRus}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова на английском языке">
            <span>{person.scientist?.keywordsEnglish}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column>
        <SectionTitle title="Области научных интересов" />
        <FormComponent.Line>
          <RefList
            header={{ title: 'Области научных интересов' }}
            rows={person.domainInterests}
            maxHeight="220px"
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "Области научных интересов"'
            refName="RefScienceDomainInteres"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Коды ГРНТИ' }}
            rows={person.grntis}
            maxHeight="220px"
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "ГРНТИ"'
            refName="RefGrnti"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Коды УДК' }}
            rows={person.udks}
            maxHeight="220px"
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "УДК"'
            refName="RefUdk"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Коды международной классификации отраслей наук (ОЕСД)' }}
            rows={person.oecds}
            maxHeight="220px"
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "ОЕСД"'
            refName="RefOecd"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Отрасли знаний (РНФ)' }}
            rows={person.knowledgeBranches}
            columns={[
              {
                label: 'Отрасль знаний',
                formatValue: x => x.label || '',
                styles: { width: '50%' },
              },
              {
                label: 'Группа (подгруппа)',
                formatValue: x => getKnowledgeBranchGroupLabel(x),
                styles: { width: '50%' },
              },
            ]}
            maxHeight="220px"
            withoutHead={false}
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "Отрасли знаний (РНФ)"'
            refName="RefKnowledgeBranch"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Специальности ВАК' }}
            rows={person.vakSpecialities}
            maxHeight="220px"
            isDeleteConfirmEnabled
            withMessages
            modalTitle='Справочник "ВАК"'
            refName="RefVakSpeciality"
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';

import { showErrorsMessages } from 'utils/Common';
import { Project } from 'types/models';
import { getMockFinancing } from 'features/Form/looks/project/ProjectForm/helpers';
import useController from './controller';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  financings: Project.Financing[];
  projectId?: string;
  setFinancings(financings: Project.Financing[]): void;
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  stages: Project.Stage[];
  withMessages?: boolean;
  disabled: boolean;
};

export function Financings(props: Props) {
  const { financings, projectId, stages, setFinancings, setStages, setFinancingsByYear, withMessages = true, disabled } = props;

  const {
    isLoading,
    isDeleteWarningPopupOpen,
    ndsPercentFormatter,
    ndsFormatter,
    deleteWarningText,
    onCloseDeleteWarningPopup,
    onPreDelete,
    onPreSubmit,
    setFinancingsCallback,
    onDelete,
  } = useController({
    projectId,
    financings,
    setStages,
    setFinancings,
    setFinancingsByYear,
    stages,
  });

  return (
    <>
      <ListEdit
        rows={financings}
        onChange={setFinancingsCallback}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Вид финансирования', formatValue: x => x.type?.label ?? '', styles: { width: '30%' } },
          { label: 'Источник', formatValue: x => x.source?.label ?? '', styles: { width: '30%' } },
          { label: 'Сумма', formatValue: x => x.amount, styles: { width: '13%' }, dataKind: 'float' },
          { label: 'НДС %', formatValue: ndsPercentFormatter, styles: { width: '13%' }, dataKind: 'float' },
          { label: 'НДС, руб', formatValue: ndsFormatter, styles: { width: '13%' }, dataKind: 'float' },
        ]}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled || isLoading}
        defaultRowsCount={3}
        maxHeight="350px"
        columnIndexesForSumTotal={[2, 4]}
        specification={{
          onPreSubmit,
          onPreDelete,
          mode: 'customComponent',
          renderComponent: (financing, setFinancing, _, mode) => (
            <Fields financing={financing || getMockFinancing()} setFinancing={setFinancing} mode={mode} />
          ),
          validation: {
            checkIsValid: (department, index, list, mode) => validate(department, list, mode).every(x => x.isValid),
            onInvalidate: (department, mode, index, list) => {
              const validationInfo = validate(department, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />

      <Modal
        mode="warning"
        title="Невозможно удалить элемент"
        isOpen={isDeleteWarningPopupOpen}
        onClose={onCloseDeleteWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: onCloseDeleteWarningPopup,
          },
        ]}
        size="small"
      >
        <>{deleteWarningText}</>
      </Modal>

      <Modal
        mode="warning"
        title="Содержимое удаленного элемента"
        isOpen={onDelete.isOpen}
        onClose={onDelete.close}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onDelete.confirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onDelete.close,
          },
        ]}
        size="small"
      >
        <>{onDelete.text}</>
      </Modal>
    </>
  );
}

import { useMemo, useState } from 'react';

import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Change } from 'types/models/Partner';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Change>[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [],
  );

  return {
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
  };
}

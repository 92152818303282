import React, { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, Select, TextArea, TextAreaMode } from 'components';

import { Partner, Table } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';
import { Enum } from 'types/models/Table';
import { GetPartnerList } from 'features/Table/specifications';
import { getEnterpriseCustomersIds, getEnterpriseIds } from 'features/EditPartner/helpers';

type Props = {
  partner: Partner;
  setPartner(partner: Partner | null): void;
  relationValues: Enum;
  isOnlyVariant?: boolean;
  allChildsPartners: Partner[];
  mainPartner: Partner | null;
};

const getParametersIds = (ids: string[]) => (ids.length ? ids.join(',') : '-1');

export function Fields(props: Props) {
  const { partner, setPartner, mainPartner, allChildsPartners, relationValues, isOnlyVariant } = props;

  const makeHandler = (key: keyof Partner) => (value: ValueOf<Partner>) => {
    setPartner({ ...partner, [key]: value });
  };

  const relationOptions = useMemo(
    () =>
      relationValues.values
        .filter(x => (isOnlyVariant ? x.value === 'VARIANT' : x.value !== 'VARIANT'))
        .map(x => ({ label: x.label, value: x.value })),
    [isOnlyVariant, relationValues],
  );

  const changeOrganization = useCallback(
    (updatedPartner: Partner | null) => {
      const [onlyVariantOption] = relationOptions;
      setPartner(updatedPartner ? { ...updatedPartner, relation: isOnlyVariant ? onlyVariantOption : partner.relation } : null);
    },
    [partner, setPartner, isOnlyVariant, relationOptions],
  );

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');

  const partnerIds = [
    ...allChildsPartners.filter(x => x.status?.value === 'PARTNER').map(x => x.id),
    mainPartner?.id ?? null,
  ].filter(Boolean) as string[];

  const childEnterpriseCustomerIds = allChildsPartners
    .filter(x => x.status?.value !== 'PARTNER')
    .flatMap(getEnterpriseCustomersIds);
  const childEnterpriseIds = allChildsPartners.filter(x => x.status?.value !== 'PARTNER').flatMap(getEnterpriseIds);

  const enterpriseIds = Array.from(new Set([...getEnterpriseIds(mainPartner), ...childEnterpriseIds]));
  const enterpriseCustomerIds = Array.from(new Set([...getEnterpriseCustomersIds(mainPartner), ...childEnterpriseCustomerIds]));

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Список организаций',
              isClearable: false,
              table: {
                specification: GetPartnerList({
                  toolbarMode: 'null',
                  uniqID: 'RelationPartnerList',
                  parameters: [
                    { name: 'extended', value: false },
                    { name: 'forChild', value: true },
                    { name: 'excludeIds', value: getParametersIds(partnerIds) },
                    { name: 'excludeEnterpriseIds', value: getParametersIds(enterpriseIds) },
                    { name: 'excludeEnterpriseCustomerIds', value: getParametersIds(enterpriseCustomerIds) },
                  ],
                }),
                onSelect: (row: Table.Entry | null) => {
                  getPartnerAPI.callAPI(
                    {
                      partnerId: row?.id || '',
                      enterpriseCustomerId: row?.Type === 'ENTERPRISE_CUSTOMER' ? row?.id : null,
                      enterpriseId: row?.Type === 'ENTERPRISE' ? row?.id : null,
                      shortName: row?.Type === 'RAW' ? row?.ShortName ?? null : null,
                      fullName: row?.Type === 'RAW' ? row?.FullName : null,
                    },
                    { onSuccessfullCall: ({ data }) => changeOrganization(data) },
                  );
                },
              },
            }}
            value={partner?.fullName}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!isOnlyVariant && (
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Форма отношений">
            <Select
              value={partner.relation}
              options={relationOptions}
              onChange={(option: Item) => makeHandler('relation')(option)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </FormComponent.Wrapper>
  );
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ExpenseDocumentItemGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Таблица «Расходы по шифрам проектов (фактические суммы по видам расходов бухгалтерского учёта)» содержит сведения из
          расходных документов бухгалтерского учёта: фактические суммы затрат по видам расходов бухгалтерского учёта. Записи в
          таблице сформированы на основе данных, полученных из ИС бухгалтерского учёта.
        </p>
        <p>
          Фильтр <strong>Год</strong> – отображает список документов с датой, год которой равен году, установленному в фильтре.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>КВР</td>
              <td>Код вида расхода.</td>
            </tr>
            <tr>
              <td>КОСГУ</td>
              <td>Код и наименование по классификации КОСГУ.</td>
            </tr>
            <tr>
              <td>Вид расхода</td>
              <td>Наименование вида расхода как есть в ИС бухгалтерского учёта.</td>
            </tr>
            <tr>
              <td>Номер исходного документа</td>
              <td>Номер исходного документа.</td>
            </tr>
            <tr>
              <td>Дата исходного документа</td>
              <td>Дата исходного документа.</td>
            </tr>
            <tr>
              <td>Номер последней редакции документа</td>
              <td>Номер последней редакции документа.</td>
            </tr>
            <tr>
              <td>Сумма</td>
              <td>Сумма документа.</td>
            </tr>
            <tr>
              <td>Тип документа основания</td>
              <td>Тип документа основания — договор/счёт/ приказ на выплаты заработной платы.</td>
            </tr>
            <tr>
              <td>Назначение платежа</td>
              <td>Назначение платежа.</td>
            </tr>
            <tr>
              <td>Организация/Источник ЗПЛ</td>
              <td>Название контрагента в документе.</td>
            </tr>
            <tr>
              <td>Адрес организации</td>
              <td>Адрес контрагента.</td>
            </tr>
            <tr>
              <td>Привязан к смете</td>
              <td>Отметка о том, что данный документ будет отображаться в смете шифра на год, соответствующий дате документа.</td>
            </tr>
            <tr>
              <td>Шифр проекта</td>
              <td>Шифр проекта (шифр соответствует финансовому лицевому счёту проекта в ИС бухучёта).</td>
            </tr>
            <tr>
              <td>Вид фин. обеспечения</td>
              <td>Атрибут шифра (на форме шифра проекта, вкладка О шифре).</td>
            </tr>
            <tr>
              <td>Использовать для ВБ</td>
              <td>Атрибут шифра (на форме шифра проекта, вкладка О шифре).</td>
            </tr>
            <tr>
              <td>Вид деятельности</td>
              <td>
                Атрибут проекта (на форме проекта, вкладка Особенности финансирования в разделе Коды целевых статей расходов).
              </td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта, связанного с шифром проекта.</td>
            </tr>
            <tr>
              <td>Код номера проекта</td>
              <td>Код номера проекта – дополнительный атрибут номера проекта.</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО руководителя проекта.</td>
            </tr>
            <tr>
              <td>Ответственный исполнитель по проекту</td>
              <td>ФИО ответственного исполнителя по проекту.</td>
            </tr>
            <tr>
              <td>Факультет/Институт</td>
              <td>
                Список подразделений, которые указаны в проекте в разделе: <strong>Проект выполняется на базе</strong>.
              </td>
            </tr>
            <tr>
              <td>Подразделение</td>
              <td>
                Список подразделений на уровне факультета/ института, которые указаны в проекте в разделе:{' '}
                <strong>Проект выполняется на базе</strong>.
              </td>
            </tr>
            <tr>
              <td>Источник финансирования</td>
              <td>Источник финансирования шифра проекта (на форме шифра проекта, вкладка О шифре).</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>Название программы – атрибут проекта.</td>
            </tr>
            <tr>
              <td>Заказчик</td>
              <td>Полное название Заказчика проекта.</td>
            </tr>
            <tr>
              <td>Характер проекта</td>
              <td>Характер проекта – атрибут проекта.</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { EntityLocker } from 'features/EntityLocker';

const ConferenceMaterialTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.EventFormLook = {
    apiID: 'GetPublication',
    template: 'ConferenceDataForm',
    type: 'magazine',
    viewMode: true,
    id,
    relatedTableState,
    setTitle,
  };

  return (
    <EntityLocker isOpen={isOpen} entity="Event" id={look.id} onClose={onClose}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="large" isViewMode>
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};
export const Component = React.memo(ConferenceMaterialTemplate);

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Template } from 'types/models/Form';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export const templateArgs: { apiID: string; template?: Template } = {
  apiID: 'GetNirRequestKindConsumption',
  template: 'NirRequestKindConsumptionForm',
};

export function useController({ tableState, customState: { tenderId } }: Props) {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'TenderKindConsumption',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [tableState, tableStreams.deleteRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: selectedRowsLength !== 1,
        isHidden: !tenderId,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permission: { name: Permits.TENDER_EDIT },
        isHidden: !tenderId,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.TENDER_EDIT },
        isDisabled: selectedRowsLength !== 1,
        isHidden: !tenderId,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.TENDER_EDIT },
        isDisabled: selectedRowsLength !== 1,
        isHidden: !tenderId,
      },
    ],
    [selectedRowsLength, tenderId],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    selectedRow,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
  };
}

import { SubmitTable } from 'features/Table/streams';
import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setProjectId(projectId: string): void;
  isWorkbenchPc: boolean;
};

export function GetProjectListForContestRequest({ setProjectId, isWorkbenchPc }: Props): Table.Specification<State> {
  return {
    apiID: isWorkbenchPc ? 'GetProjectListForContestRequestPc' : 'GetProjectListForContestRequest',
    header: {
      firstLevel: {
        ...(isWorkbenchPc ? {} : { Panel: Filters }),
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ setProjectId }),
    onSubmitTable: (state: SubmitTable) => {
      const { selectedRows } = state;
      if (selectedRows.length) {
        setProjectId(selectedRows[0]?.id);
      }
    },
  };
}

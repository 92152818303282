import { convertDocument, convertMaybeArray, getId, getText } from '../commonConverters';
import { Settings } from 'types/models/common';
import { TenderProtocol } from 'types/models/TenderProtocol';
import {
  convertCommissionMember,
  convertProtocolCommission,
} from 'services/BackendAPI/configurations/protocolCommission/converters';
import { convertTender } from 'services/BackendAPI/configurations/tenders/converters';

export function convertTenderProtocol(tp: any, settings: Settings): TenderProtocol {
  const members = tp.CommissionMembers?.CommissionMember || [];
  return {
    id: getId(tp),
    tender: tp.Tender ? convertTender(tp.Tender, settings) : null,
    number: getText(tp.Number),
    protocolDate: getText(tp.ProtocolDate),
    protocolCommission: convertProtocolCommission(tp.ProtocolCommission, settings),
    commissionMembers:
      convertMaybeArray(members, m => convertCommissionMember(m, settings)).sort((x1, x2) => {
        return (x1?.priority || 0) - (x2?.priority || 0);
      }) || [],
    documents: [
      ...convertMaybeArray(tp?.Documents?.Document || [], doc => convertDocument('local', doc)),
      ...convertMaybeArray(tp?.ForeignDocuments?.Document || [], doc => convertDocument('foreign', doc)),
    ],
    reporter: getText(tp.Reporter),
  };
}

import { Mode } from 'components/ListEdit/model';

import { Performer } from 'types/models/ProgramRequest';
import { RequestPerformerRole } from 'utils/Enums';

export function validate(member: Performer | null, list: Performer[], mode: Mode, index: number | null) {
  const members = mode === 'edit' ? list.filter((_, memberIndex) => index !== memberIndex) : list;
  const isManagerExist =
    member?.role?.value === RequestPerformerRole.LEADER &&
    !!members.find(existMember => existMember.role?.value === RequestPerformerRole.LEADER);
  const isValidMember = Boolean(member?.person);
  const isValidRole = Boolean(member?.role);

  const hasDublicates = members.some(existMember => {
    const isEqualPerson = existMember?.person?.id === member?.person?.id;
    const isEqualRole = existMember?.role?.value === member?.role?.value;
    return isEqualPerson && isEqualRole;
  });
  return [
    { isValid: isValidMember, invalidMessage: 'Заполните "ФИО"' },
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: !hasDublicates, invalidMessage: 'Исполнитель с такой ролью уже добавлен' },
    { isValid: !isManagerExist, invalidMessage: 'Участник с ролью "Руководитель" уже есть в коллективе' },
  ];
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { ProjectFinancingType } from 'utils/Enums';
import { Project } from 'types/models';
import { BASE_NDS } from 'utils/Constants';
import { calcNdsSumm, formatNumber } from 'utils/Helpers';
import { computeFinancingsByYear, financingTypeMapStageSum } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  projectId?: string;
  financings: Project.Financing[];
  setStages(stages: Project.Stage[]): void;
  setFinancings(financings: Project.Financing[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  stages: Project.Stage[];
};

const useController = ({
  projectId,
  financings,
  setStages,
  setFinancings,
  setFinancingsByYear,
  stages: projectStages,
}: Props) => {
  const {
    methods: CheckActiveProjectCodesForFinancingAPI,
    state: CheckActiveProjectCodesForFinancingState,
  } = BackendAPI.useBackendAPI('CheckActiveProjectCodesForFinancing');

  const isLoading = useMemo(() => CheckActiveProjectCodesForFinancingState.kind === 'pending', [
    CheckActiveProjectCodesForFinancingState.kind,
  ]);

  const [codes, setCodes] = useState<string>('');
  const [isDeleteWarningPopupOpen, setIsDeleteWarningPopupOpen] = useState<boolean>(false);

  const getFinancingNdsProps = (financing: Project.Financing, stages: Project.Stage[]) => {
    let amount = 0;
    let ndsAmount = 0;

    stages.forEach(stageItem => {
      switch (financing.type?.value) {
        case ProjectFinancingType.MAIN:
          amount += Number(stageItem.amountMain);
          ndsAmount += Number(calcNdsSumm(stageItem.amountMain || '0', stageItem.mainNdsPercent || '0'));
          break;
        case ProjectFinancingType.COFINANCING_1:
          amount += Number(stageItem.amountCofinancing1);
          ndsAmount += Number(calcNdsSumm(stageItem.amountCofinancing1 || '0', stageItem.cofinancing1NdsPercent || '0'));
          break;
        case ProjectFinancingType.COFINANCING_2:
          amount += Number(stageItem.amountCofinancing2);
          ndsAmount += Number(calcNdsSumm(stageItem.amountCofinancing2 || '0', stageItem.cofinancing2NdsPercent || '0'));
          break;
        case ProjectFinancingType.LOCAL:
          amount += Number(stageItem.amountLocal);
          ndsAmount += Number(calcNdsSumm(stageItem.amountLocal || '0', stageItem.localNdsPercent || '0'));
          break;
        case ProjectFinancingType.ACCOMPLICE:
          amount += Number(stageItem.amountAccomplice);
          ndsAmount += Number(calcNdsSumm(stageItem.amountAccomplice || '0', stageItem.accompliceNdsPercent || '0'));
          break;
      }
    });

    const divisor = amount - ndsAmount;
    const ndsPercent = divisor !== 0 ? (ndsAmount / divisor) * 100 : 0;
    return {
      nds: ndsAmount.toString(),
      ndsPercent: ndsPercent.toString(),
    };
  };

  const ndsPercentFormatter = useCallback((row: Project.Financing) => getFinancingNdsProps(row, projectStages).ndsPercent, [
    projectStages,
  ]);

  const ndsFormatter = useCallback((row: Project.Financing) => getFinancingNdsProps(row, projectStages).nds, [projectStages]);

  const deleteWarningText = useMemo(
    () => `Выбранный источник финансирования не может быть удален, т.к. на него уже заведен шифр <strong>${codes}</strong>`,
    [codes],
  );

  const [deleteData, setDeleteData] = useState<{
    opened: boolean;
    text: string;
    financing: Project.Financing | null;
    callback(): void;
  }>({
    opened: false,
    text: '',
    financing: null,
    callback: () => {},
  });

  const onDelete = useMemo(() => {
    return {
      isOpen: deleteData.opened,
      text: deleteData.text,
      open: (financing: Project.Financing, callback: () => void) => {
        setDeleteData(prev => ({
          ...prev,
          opened: true,
          text: `В Календарном плане будет удалены суммы для "${financing.type?.label}" будут удалены.`,
          financing,
          callback,
        }));
      },
      confirm: () => {
        setDeleteData(prev => ({
          ...prev,
          opened: false,
        }));

        const nextStages = projectStages.map(stage => {
          const nextStage = { ...stage };

          switch (deleteData.financing?.type?.value) {
            case ProjectFinancingType.MAIN:
              nextStage.amountMain = '0.00';
              nextStage.mainNdsPercent = '0.00';
              break;
            case ProjectFinancingType.COFINANCING_1:
              nextStage.amountCofinancing1 = '0.00';
              nextStage.cofinancing1NdsPercent = '0.00';
              break;
            case ProjectFinancingType.COFINANCING_2:
              nextStage.amountCofinancing2 = '0.00';
              nextStage.cofinancing2NdsPercent = '0.00';
              break;
            case ProjectFinancingType.LOCAL:
              nextStage.amountLocal = '0.00';
              nextStage.localNdsPercent = '0.00';
              break;
            case ProjectFinancingType.ACCOMPLICE:
              nextStage.amountAccomplice = '0.00';
              nextStage.accompliceNdsPercent = '0.00';
              break;
          }

          return nextStage;
        });

        setStages(nextStages);
        setFinancingsByYear(computeFinancingsByYear(nextStages));

        deleteData.callback();
      },
      close: () => {
        setDeleteData(prev => ({
          ...prev,
          opened: false,
        }));
      },
    };
  }, [deleteData, projectStages, setFinancingsByYear, setStages]);

  const onCloseDeleteWarningPopup = useCallback(() => {
    setIsDeleteWarningPopupOpen(false);
  }, []);

  const checkActiveProjectCodesForFinancing = useCallback(
    (financing: Project.Financing, continueCallback: () => void) => {
      CheckActiveProjectCodesForFinancingAPI.callAPI(
        {
          projectId: projectId ?? '-1',
          projectFinancingId: financing.id ?? '-1,',
        },
        {
          onSuccessfullCall: ({ data: { value, codes: responseCodes } }) => {
            if (value) {
              setCodes(responseCodes);
              setIsDeleteWarningPopupOpen(true);
            } else {
              continueCallback();
            }
          },
        },
      );
    },
    [CheckActiveProjectCodesForFinancingAPI, projectId],
  );

  const onPreDelete = useCallback(
    (financing: Project.Financing, continueDelete: () => void) => {
      const financingType = financing?.type?.value;

      if (!financingType) {
        continueDelete();
        return;
      }

      if (financingType === ProjectFinancingType.MAIN) {
        showNotification({ message: `Запрещено удалять "${financing?.type?.label}"`, theme: 'danger' });
        return;
      }

      if (projectStages.find(stage => Number(stage[financingTypeMapStageSum[financingType]]) > 0)) {
        onDelete.open(financing, continueDelete);
      } else if (financing.id && projectId) {
        checkActiveProjectCodesForFinancing(financing, continueDelete);
      } else {
        continueDelete();
      }
    },
    [checkActiveProjectCodesForFinancing, onDelete, projectId, projectStages],
  );

  const onPreSubmit = useCallback(
    (financing: Project.Financing, submit: (financing: Project.Financing) => void) => {
      const stagesToSet = projectStages.map(stage => {
        const nextStage = { ...stage };

        switch (financing.type?.value) {
          case ProjectFinancingType.MAIN:
            nextStage.mainNdsPercent = financing.isNds
              ? Number(nextStage.mainNdsPercent)
                ? nextStage.mainNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.COFINANCING_1:
            nextStage.cofinancing1NdsPercent = financing.isNds
              ? Number(nextStage.cofinancing1NdsPercent)
                ? nextStage.cofinancing1NdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.COFINANCING_2:
            nextStage.cofinancing2NdsPercent = financing.isNds
              ? Number(nextStage.cofinancing2NdsPercent)
                ? nextStage.cofinancing2NdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.LOCAL:
            nextStage.localNdsPercent = financing.isNds
              ? Number(nextStage.localNdsPercent)
                ? nextStage.localNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
          case ProjectFinancingType.ACCOMPLICE:
            nextStage.accompliceNdsPercent = financing.isNds
              ? Number(nextStage.accompliceNdsPercent)
                ? nextStage.accompliceNdsPercent
                : formatNumber(BASE_NDS)
              : '0.00';
            break;
        }
        return nextStage;
      });

      setFinancingsByYear(computeFinancingsByYear(stagesToSet));
      setStages(stagesToSet);

      submit(financing);
    },
    [projectStages, setFinancingsByYear, setStages],
  );

  const setFinancingsCallback = useCallback(
    (list: Project.Financing[]) => {
      const findUniq = (source: Project.Financing[], updated: Project.Financing[]) => {
        const sourceTypes = source.map(x => x.type?.value).filter(Boolean);
        const updatedTypes = updated.map(x => x.type?.value).filter(Boolean);

        const sourceType = sourceTypes.find(srcType => !updatedTypes.find(updType => srcType === updType));

        if (sourceType) {
          return source.find(src => src.type?.value === sourceType);
        }
        return;
      };

      const financingFrom: Project.Financing | undefined = findUniq(financings, list);
      const financingTo: Project.Financing | undefined = findUniq(list, financings);

      setFinancings(list);

      if (!financingFrom && !financingTo) {
        return;
      }

      const nextStages = projectStages.map(stage => {
        const nextStage = { ...stage };

        let dataFrom: string[] = ['0.00', '0.00'];

        switch (financingFrom?.type?.value) {
          case ProjectFinancingType.MAIN:
            dataFrom = [stage.amountMain, stage.mainNdsPercent];
            nextStage.amountMain = '0.00';
            nextStage.mainNdsPercent = '0.00';
            break;
          case ProjectFinancingType.COFINANCING_1:
            dataFrom = [stage.amountCofinancing1, stage.cofinancing1NdsPercent];
            nextStage.amountCofinancing1 = '0.00';
            nextStage.cofinancing1NdsPercent = '0.00';
            break;
          case ProjectFinancingType.COFINANCING_2:
            dataFrom = [stage.amountCofinancing2, stage.cofinancing2NdsPercent];
            nextStage.amountCofinancing2 = '0.00';
            nextStage.cofinancing2NdsPercent = '0.00';
            break;
          case ProjectFinancingType.LOCAL:
            dataFrom = [stage.amountLocal, stage.localNdsPercent];
            nextStage.amountLocal = '0.00';
            nextStage.localNdsPercent = '0.00';
            break;
          case ProjectFinancingType.ACCOMPLICE:
            dataFrom = [stage.amountAccomplice, stage.accompliceNdsPercent];
            nextStage.amountAccomplice = '0.00';
            nextStage.accompliceNdsPercent = '0.00';
            break;
        }

        switch (financingTo?.type?.value) {
          case ProjectFinancingType.MAIN:
            nextStage.amountMain = dataFrom[0];
            nextStage.mainNdsPercent = dataFrom[1];
            break;
          case ProjectFinancingType.COFINANCING_1:
            nextStage.amountCofinancing1 = dataFrom[0];
            nextStage.cofinancing1NdsPercent = dataFrom[1];
            break;
          case ProjectFinancingType.COFINANCING_2:
            nextStage.amountCofinancing2 = dataFrom[0];
            nextStage.cofinancing2NdsPercent = dataFrom[1];
            break;
          case ProjectFinancingType.LOCAL:
            nextStage.amountLocal = dataFrom[0];
            nextStage.localNdsPercent = dataFrom[1];
            break;
          case ProjectFinancingType.ACCOMPLICE:
            nextStage.amountAccomplice = dataFrom[0];
            nextStage.accompliceNdsPercent = dataFrom[1];
            break;
        }

        return nextStage;
      });

      setStages(nextStages);
      setFinancingsByYear(computeFinancingsByYear(nextStages));
    },
    [financings, projectStages, setFinancings, setFinancingsByYear, setStages],
  );

  return {
    isLoading,
    isDeleteWarningPopupOpen,
    ndsPercentFormatter,
    ndsFormatter,
    deleteWarningText,
    onCloseDeleteWarningPopup,
    onPreDelete,
    onPreSubmit,
    setFinancingsCallback,
    onDelete,
  };
};

export default useController;

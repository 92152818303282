import { Icon } from 'components';

export function PersonalExpositionEventGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Выставки</strong> предназначен для поддержки отчетности по научно-техническим, образовательным,
        художественным выставкам и результатам участия сотрудников, студентов и аспирантов в выставках.
      </p>

      <h4>В разделе Выставки вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись о выставке и детальную информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новую выставку с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать выставку в статусе Добавлена с помощью кнопки <Icon type="edit" /> при наличии прав для редактирования
        </li>
        <li>
          Удалить запись о выставке, которую вы создавали, если она в статусе Добавлена с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Выгрузить список выставок в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>Внести показатели результативности выставки на вкладке «Организация мероприятия»</li>
        <li>
          Добавить информацию об участниках выставки, указать связь с подразделением и информацию о полученных наградах на вкладке
          «Участники»
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи о мероприятии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит мероприятие.
      </p>
      <p>
        Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к заполнению.
      </p>
      <p>
        Для добавления участия в мероприятии, организованного другой организацией, необходимо заполнить обязательные поля на
        вкладке "О мероприятии" и прикрепить подтверждающие документы на вкладке "Документы".
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои выставки»</strong> содержит список выставок, в которых вы указаны в составе Оргкомитета или
          являетесь участником выставки. Если вы создавали запись о выставке, но не являетесь участником оргкомитета или
          участником выставки, то выставка будет отображаться в другом разделе.
        </li>
        <li>
          <strong>Фильтр «Выставки {organizationName}»</strong> содержит список конференций, в которых организатором или
          соорганизатором является университет.
        </li>
        <li>
          <strong>Фильтр «Другие выставки»</strong> показывает список выставок других организаций, в которых принимают участие
          сотрудники, аспиранты и студенты университета.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список выставок, сроки проведения которых содержат указанный год. По умолчанию
          выбран текущий год.
        </li>
      </ul>

      <p>Добавленное вами мероприятие проверит и утвердит Администратор данных.</p>

      <div>
        Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору <Icon type="admin" />
      </div>
    </>
  );
}

import React, { memo, useEffect } from 'react';

import { FormComponent } from 'components';

import { Event, Table } from 'types/models';
import { DataGrid } from 'features/Table';
import { GetConcertEventParticipationList } from 'features/Table/specifications';
import ParticipationsStatistic from 'features/Form/looks/event/views/ParticipationsStatistic';
import { EventTypes } from 'utils/Enums/EventTypes';

type Props = {
  sourceEvent?: Event.Event | null;
  workMode: Table.WorkMode;
  reloadEvent?(): void;
  isEventChanged?: boolean;
  participations: Event.EventParticipant[];
  checkIsThereNoConnectedPublications?: () => void;
};

const Participations = ({
  sourceEvent,
  workMode,
  reloadEvent,
  isEventChanged,
  participations,
  checkIsThereNoConnectedPublications,
}: Props) => {
  useEffect(() => {
    checkIsThereNoConnectedPublications?.();
    // eslint-disable-next-line
  }, []);

  return (
    <FormComponent.Template>
      <FormComponent.Description mode="warning">
        Прежде чем редактировать участников, сохраните мероприятие, с помощью кнопки "Сохранить и продолжить". Несохраненные
        данные могут быть утеряны
      </FormComponent.Description>

      <FormComponent.Line>
        <ParticipationsStatistic eventTypeCode={EventTypes.EVENT_CONCERT.code} participations={participations} />
      </FormComponent.Line>

      <DataGrid
        specification={GetConcertEventParticipationList(
          {
            sourceEvent: sourceEvent || null,
            reloadEvent,
            isEventChanged,
          },
          workMode,
        )}
      />
    </FormComponent.Template>
  );
};

export const Component = memo(Participations);

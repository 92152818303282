import * as XML from 'xml-js';

import { Notification, Person } from 'types/models';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';

import { convertNotificationRecipientPreset } from './converters';
import { convertMaybeArray, getMaybeField, getText } from '../commonConverters';
import { convertNotificationLog, convertNotification } from './converters';
import { convertNotificationToXML } from './requestConverters';
import { NotificationDispatchMode, NotificationEvent, NotificationEventModule } from 'utils/Enums';

export const notificationConfigurations = {
  ChangeNotificationRecipientState: makeBackendAPIConfiguration({
    id: 'ChangeNotificationRecipientState',
    endpoint: '/msa/service/commands/ChangeNotificationRecipientState',
    convertInputToXMLElement: ({ enable, ids }: { enable: boolean; ids: string[] }) => ({
      Request: {
        _attr: { commandName: 'ChangeNotificationRecipientState' },
        NotificationRecipients: {
          _attr: { enable },
          ...(ids && ids.length ? { NotificationRecipient: ids.map(id => ({ _attr: { id } })) } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response.Response.ChangeNotificationRecipientState,
  }),
  SavePersonalNotification: makeBackendAPIConfiguration({
    id: 'SavePersonalNotification',
    endpoint: '/msa/service/commands/SavePersonalNotification',
    convertInputToXMLElement: ({
      notificationEmail,
      disnotifications,
    }: Pick<Person.ScientistPerson, 'disnotifications'> & Pick<Person.Scientist, 'notificationEmail'>) => {
      return {
        Request: {
          _attr: { commandName: 'SavePersonalNotification' },
          ...getMaybeField('NotificationEmail', notificationEmail),
          Disnotifications: { Disnotification: disnotifications.map(x => x.value) },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetNotification: makeBackendAPIConfiguration({
    id: 'GetNotification',
    endpoint: '/msa/service/commands/GetNotification',
    convertInputToXMLElement: ({ ids }: { ids?: string[] }) => {
      return {
        Request: {
          _attr: { commandName: 'GetNotification' },
          Notification: (ids || []).map(id => ({ _attr: { id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.Notification || [], convertNotification),
  }),
  SaveNotification: makeBackendAPIConfiguration({
    id: 'SaveNotification',
    endpoint: '/msa/service/commands/SaveNotification',
    convertInputToXMLElement: ({ notifications }: { notifications?: Notification.Notification[] }) => {
      return {
        Request: {
          _attr: { commandName: 'SaveNotification' },
          Notification: (notifications || []).map(convertNotificationToXML),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => ({ id: getText(response.Response.Id) }),
  }),
  GetNotificationLog: makeBackendAPIConfiguration({
    id: 'GetNotificationLog',
    endpoint: '/msa/service/commands/GetNotificationLog',
    convertInputToXMLElement: ({ ids }: { ids?: string[] }) => {
      return {
        Request: {
          _attr: { commandName: 'GetNotificationLog' },
          NotificationLog: (ids || []).map(id => ({ _attr: { id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.NotificationLog || [], convertNotificationLog),
  }),
  ClearPersonalNotificationLog: makeBackendAPIConfiguration({
    id: 'ClearPersonalNotificationLog',
    endpoint: '/msa/service/commands/ClearPersonalNotificationLog',
    convertInputToXMLElement: () => {
      return {
        Request: {
          _attr: { commandName: 'ClearPersonalNotificationLog' },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetNotificationRecipientPreset: makeBackendAPIConfiguration({
    id: 'GetNotificationRecipientPreset',
    endpoint: '/msa/service/commands/GetNotificationRecipientPreset',
    convertInputToXMLElement: ({ id }: { id: string }) => {
      return {
        Request: {
          _attr: { commandName: 'GetNotificationRecipientPreset' },
          NotificationRecipientPreset: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertNotificationRecipientPreset(response.Response.NotificationRecipientPreset.Recipients.Recipient),
  }),
  SaveNotificationRecipientPreset: makeBackendAPIConfiguration({
    id: 'SaveNotificationRecipientPreset',
    endpoint: '/msa/service/commands/SaveNotificationRecipientPreset',
    convertInputToXMLElement: ({ id, preset }: { id: string; preset: Notification.RecipientPreset }) => {
      return {
        Request: {
          _attr: { commandName: 'SaveNotificationRecipientPreset' },
          NotificationRecipientPreset: {
            ...(!!id ? { _attr: { id } } : {}),
            Name: preset.name,
            Recipients: {
              Recipient: (preset.recipients || []).map(i => ({ Scientist: { _attr: { id: i.id } } })),
            },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveNotificationMonitor: makeBackendAPIConfiguration({
    id: 'SaveNotificationMonitor',
    endpoint: '/msa/service/commands/SaveNotificationMonitor',
    convertInputToXMLElement: ({
      module,
      scientistId,
      sourceId,
      note,
    }: {
      module: NotificationEventModule;
      scientistId: string;
      sourceId?: string;
      note: string;
    }) => {
      return {
        Request: {
          _attr: { commandName: 'SaveNotificationMonitor' },
          NotificationMonitor: {
            Module: module,
            Scientist: { _attr: { id: scientistId } },
            ...(!!sourceId ? { Source: { _attr: { id: sourceId } } } : {}),
            Note: note,
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteNotificationRecipientPreset: makeBackendAPIConfiguration({
    id: 'DeleteNotificationRecipientPreset',
    endpoint: '/msa/service/commands/DeleteNotificationRecipientPreset',
    convertInputToXMLElement: ({ id }: { id: string }) => {
      return {
        Request: {
          _attr: { commandName: 'DeleteNotificationRecipientPreset' },
          NotificationRecipientPreset: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DispatchNotification: makeBackendAPIConfiguration({
    id: 'DispatchNotification',
    endpoint: '/msa/service/commands/DispatchNotification',
    convertInputToXMLElement: ({
      dispatchMode,
      eventType,
      eventId,
      text,
      recipients,
    }: {
      dispatchMode: NotificationDispatchMode;
      eventType: NotificationEvent;
      eventId: string;
      text: string;
      recipients: Notification.RecipientItem[];
    }) => {
      return {
        Request: {
          _attr: { commandName: 'DispatchNotification' },
          DispatchMode: dispatchMode,
          Event: eventType,
          Source: { _attr: { id: eventId } },
          Block: text,
          Scientist: (recipients || []).map(i => ({ _attr: { id: i.id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteNotificationMonitor: makeBackendAPIConfiguration({
    id: 'DeleteNotificationMonitor',
    endpoint: '/msa/service/commands/DeleteNotificationMonitor',
    convertInputToXMLElement: ({ ids }: { ids?: string[] }) => {
      return {
        Request: {
          _attr: { commandName: 'DeleteNotificationMonitor' },
          NotificationMonitor: (ids || []).map(id => ({ _attr: { id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const tableUserConfigurations = {
  ...notificationConfigurations,
};

import { useAppDataContext } from 'features/AppData/context';
import { useStorage } from 'utils/Common';

export function getAuthToken(): string | null {
  const { userToken } = useAppDataContext();

  const storage = useStorage('token');

  return userToken || storage.get() || null;
}

import React, { useCallback, useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { PaymentRequestStatus } from 'utils/Enums';
import { Mode } from 'features/Table/specifications/GetRequestFundList/makeUseCustomController';
import { Color } from 'constants/colors';

type Props = {
  onSave(onSuccessCb?: () => void): void;
  mode: Mode | null;
  status: PaymentRequestStatus | null;
  changeEmployeePaymentStatus(status: PaymentRequestStatus): void;
  openConfirmApproveStatusModal(): void;
  openConfirmRevisionStatusModal(): void;
  openConfirmDeleteModal(): void;
  openMessageLogModal(): void;
};

export function Toolbar(props: Props) {
  const {
    onSave,
    mode,
    status,
    changeEmployeePaymentStatus,
    openConfirmApproveStatusModal,
    openConfirmRevisionStatusModal,
    openConfirmDeleteModal,
    openMessageLogModal,
  } = props;

  const changeStatusTo = useCallback(
    (x: PaymentRequestStatus) => {
      onSave(() => changeEmployeePaymentStatus(x));
    },
    [changeEmployeePaymentStatus, onSave],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(),
        isDisabled: mode === 'view',
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: openConfirmDeleteModal,
        isHidden: !status || mode === 'add',
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeStatusTo(PaymentRequestStatus.SENT),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToSent' },
        isHidden: status === PaymentRequestStatus.PAID || status === PaymentRequestStatus.SENT,
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Передать на оплату',
        onClick: () => changeStatusTo(PaymentRequestStatus.PAID),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToPaid' },
        isHidden:
          !status ||
          status === PaymentRequestStatus.REJECTED ||
          status === PaymentRequestStatus.PAID ||
          status === PaymentRequestStatus.DRAFT ||
          mode === 'add',
      },
      {
        icon: { type: 'like' },
        title: 'Одобрить оплату',
        onClick: () => onSave(openConfirmApproveStatusModal),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToPaymentApproved' },
        isHidden:
          !status ||
          status === PaymentRequestStatus.REJECTED ||
          status === PaymentRequestStatus.SENT ||
          status === PaymentRequestStatus.DRAFT ||
          mode === 'add',
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => onSave(openConfirmRevisionStatusModal),
        permission: { name: 'IncentivePayment:EmployeeRequest:Status:ToRejected' },
        isHidden: !status || status === PaymentRequestStatus.REJECTED || status === PaymentRequestStatus.DRAFT || mode === 'add',
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: openMessageLogModal,
        isHidden: !status || mode === 'add',
      },
    ],
    [
      onSave,
      openConfirmApproveStatusModal,
      changeStatusTo,
      mode,
      status,
      openConfirmRevisionStatusModal,
      openConfirmDeleteModal,
      openMessageLogModal,
    ],
  );

  return <SharedToolbar buttons={buttons} mode="form" />;
}

import { useCallback } from 'react';
import { rangeDateMask, rangeDateTimeMask, rangeTimeMask, singleDateMask, singleDateTimeMask, singleTimeMask } from './helpers';

type Props = {
  isRange: boolean;
  isWithDate: boolean;
  isWithTime: boolean;
};

const useController = ({ isRange, isWithDate, isWithTime }: Props) => {
  const mask = useCallback(
    (value: string) => {
      if (isRange) {
        if (isWithDate && isWithTime) {
          return rangeDateTimeMask(value);
        } else if (isWithDate) {
          return rangeDateMask(value);
        } else {
          return rangeTimeMask(value);
        }
      } else {
        if (isWithDate && isWithTime) {
          return singleDateTimeMask(value);
        } else if (isWithDate) {
          return singleDateMask(value);
        } else {
          return singleTimeMask(value);
        }
      }
    },
    [isRange, isWithDate, isWithTime],
  );

  return {
    mask,
  };
};

export default useController;

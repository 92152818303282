import React, { useMemo } from 'react';
import { block } from 'bem-cn';

import { FormComponent, TextDateTime, TextInput, TextInputMode } from 'components';
import { Mode } from 'components/ListEdit/model';

import { Project } from 'types/models';
import { calcNdsSumm, formatNumber } from 'utils/Helpers';
import { InfoDescription, useController } from './controller';

import './style.scss';

const b = block('research-job-stages-fields');

type Props = {
  project: Project.Project;
  stage: Project.Stage;
  setStage(stage: Project.Stage): void;
  financings: Project.Financing[];
  mode: Mode;
};

export function Fields({ stage, setStage, financings, mode, project }: Props) {
  const { makeChangeHandler, sumFields } = useController({ stage, setStage, mode, financings, project });

  const renderSumField = (item: InfoDescription) => {
    const { label, percentKey, sumKey, financingType } = item;
    const financing = financings.find(x => x.type?.value === financingType);
    const isHasFinancing = useMemo(() => Boolean(financing), [financing]);

    const disabled = useMemo(() => !isHasFinancing, [isHasFinancing]);
    const isFinancingWithNds = useMemo(() => Boolean(financing?.isNds), [financing?.isNds]);

    return (
      <FormComponent.Line key={item.label}>
        <FormComponent.Field label={label} isRequired={isHasFinancing}>
          <div className={b('line')}>
            <TextInput
              mode={TextInputMode.NUMBER}
              value={stage[sumKey] as string}
              onChange={makeChangeHandler(sumKey)}
              isDisabled={disabled}
            />
            <div className={b('separator')}>в т. ч. НДС%</div>
            <TextInput
              mode={TextInputMode.NUMBER}
              value={stage[percentKey] as string}
              onChange={makeChangeHandler(percentKey)}
              isDisabled={disabled || !isFinancingWithNds}
            />
            <div className={b('separator')}>НДС, руб.:</div>
            <div className={b('separator', { left: true })}>
              {formatNumber(calcNdsSumm(stage[sumKey] as string, stage[percentKey] as string))}
            </div>
          </div>
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Номер этапа" isRequired>
          <TextInput mode={TextInputMode.NUMBER} value={stage.number} onChange={makeChangeHandler('number')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сроки этапа" isRequired>
          <span>
            <TextDateTime
              value={stage.startDate || ''}
              onChange={(value: string) => setStage({ ...stage, startDate: value })}
              maxValue={stage.endDate || ''}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={stage.endDate || ''}
              onChange={(value: string) => setStage({ ...stage, endDate: value })}
              minValue={stage.startDate || ''}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <>{sumFields.map(renderSumField)}</>
    </FormComponent.Wrapper>
  );
}

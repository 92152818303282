import { Project } from 'types/models';
import { ProjectReportApprovementResolution } from 'utils/Enums';

export const validate = (approvement: Project.Approvement | null) => {
  const isValidExpertGroup = Boolean(approvement?.expertGroup);
  const isValidResolution = Boolean(approvement?.resolution);
  const isValidNote =
    approvement?.resolution?.value === ProjectReportApprovementResolution.REVISION ? Boolean(approvement?.note) : true;

  return [
    { isValid: isValidExpertGroup, invalidMessage: 'Не выбрана Экспертная группа' },
    { isValid: isValidResolution, invalidMessage: 'Не выбран тип визы' },
    { isValid: isValidNote, invalidMessage: 'Не указаны замечания' },
  ];
};

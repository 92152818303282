import { useCallback, useState } from 'react';

import { Column } from 'components/ListEdit/model';

import { Performer } from 'types/models/ContestRequest';
import { showNotification } from 'features/Notifications';
import { useReportsHook } from 'features/BuildReportPopup';
import { Member } from 'features/SelectPerson';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { ProjectScientistRole } from 'utils/Enums';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  rows: Performer[];
  onChange(items: Performer[]): void;
  columns?: Column<Performer>[];
  isMultiRoles?: boolean;
};

export function useController(props: Props) {
  const { rows, onChange, columns } = props;

  const formatJobAndEducation = (performer: Performer) => {
    const tokens = [];
    if (performer.job) {
      tokens.push(formatJob(performer.job, false));
    }
    if (performer.education) {
      tokens.push(formatEducation(performer.education));
    }
    return tokens.join('/');
  };

  const listEditColumns =
    columns && columns.length > 0
      ? columns
      : [
          { label: 'Роль', formatValue: (row: Performer) => row?.role?.label || '' },
          { label: 'ФИО', formatValue: (row: Performer) => row?.person?.fullName || '' },
          { label: 'Дата рождения', formatValue: (row: Performer) => row?.person?.scientist?.dateBirth || '' },
          {
            label: 'Степень, звание',
            formatValue: (row: Performer) =>
              [
                row?.degree?.refDegree?.label || '',
                row?.rank?.refRank?.label || '',
                row?.academicRank?.refAcademicRank?.label || '',
              ]
                .filter(Boolean)
                .join(', '),
          },
          {
            label: 'Место работы/обучения',
            formatValue: formatJobAndEducation,
          },
          {
            label: 'Телефон',
            formatValue: (row: Performer) => getPersonContacts(row?.person?.contacts, 'phones') ?? '',
          },
          {
            label: 'E-mail',
            formatValue: (row: Performer) => getPersonContacts(row?.person?.contacts, 'emails') ?? '',
          },
        ];

  const [scientistId, setScientistId] = useState<string | null>(null);

  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [selectedPerformerIndex, setSelectedPerformerIndex] = useState<number | null>(null);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedPerformerIndex !== null) {
        const updatedPerformers = [...rows];
        updatedPerformers[selectedPerformerIndex] = { ...rows[selectedPerformerIndex], ...member } as Performer;

        onChange(updatedPerformers);
      }
    },
    [selectedPerformerIndex, rows, onChange],
  );

  const handleCloseHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(false);
    setSelectedPerformerIndex(null);
  }, [setIsOpenHistoryPersonModal, setSelectedPerformerIndex]);

  const handleOpenHistoryModal = useCallback(
    (row: Performer | null, idx: number | null) => {
      setSelectedPerformerIndex(idx);
      setIsOpenHistoryPersonModal(true);
    },
    [setIsOpenHistoryPersonModal],
  );

  const selectedPerformer = selectedPerformerIndex !== null ? rows[selectedPerformerIndex] : null;

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
    setSelectedPerformerIndex(null);
  }, []);

  const makePreSubmitCallback = (row: Performer, submit: (row: Performer) => void) => {
    submit(row);
  };

  const makePreCallback = (row: Performer | null, cb: () => void) => {
    if (row?.role?.value === ProjectScientistRole.LEADER)
      showNotification({ message: 'Запрещено редактировать/удалять руководителя', theme: 'danger' });
    else cb();
  };

  return {
    listEditColumns,
    makePreSubmitCallback,
    makePreCallback,
    closePersonModal,
    selectedPerformer,
    isOpenHistoryPersonModal,
    handleOpenHistoryModal,
    submitHistoryPersonModal,
    isReportOpen,
    onReportClose,
    handleSetCurrentReport,
    currentReport,
    isOpenPersonModal,
    setIsOpenPersonModal,
    setSelectedPerformerIndex,
    setScientistId,
    handleCloseHistoryModal,
    scientistId,
  };
}

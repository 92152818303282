import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { PortfolioCard } from 'features/Form/looks/person';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, isOpenPersonModal, setIsOpenPersonModal } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <PortfolioCard
        id={tableState.selectedRows[0]?.id ?? null}
        isOpen={isOpenPersonModal}
        onClose={() => setIsOpenPersonModal(false)}
        shortMode={true}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { useCallback } from 'react';
import { Member } from 'features/SelectPerson';
import { ForeignProject } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Item } from 'types/models/common';

type Props = {
  foreignProject: ForeignProject.ForeignProject;
  makeChangeHandler: (key: keyof ForeignProject.ForeignProject) => (value: ValueOf<ForeignProject.ForeignProject>) => void;
  makeResetErrorHandler: (key: keyof ForeignProject.ForeignProject) => () => void;
};

const useController = ({ makeChangeHandler, makeResetErrorHandler, foreignProject }: Props) => {
  const onProgramSelect = useCallback(
    (nextProgram: Item<string> | null) => {
      const changeProgram = makeChangeHandler('program');
      const changeProgramEvent = makeChangeHandler('programEvent');

      if (nextProgram?.value !== foreignProject.program?.value) {
        changeProgramEvent(null);
      }

      changeProgram(nextProgram);
    },
    [makeChangeHandler, foreignProject.program?.value],
  );

  const onUpdatePerformer = useCallback(
    (member: Member | null) => {
      const perfmerChangeHandler = makeChangeHandler('performer');
      if (member === null) {
        perfmerChangeHandler(member);
        return;
      }

      perfmerChangeHandler({
        id: member.person?.id ?? null,
        person: member.person,
        academicRank: member.academicRank,
        citizenship: member.citizenship,
        degree: member.degree,
        education: member.education,
        historyLabel: member.historyLabel,
        job: member.job,
        rank: member.rank,
      });
    },
    [makeChangeHandler],
  );

  const onUpdateRangeDate = useCallback(
    (fieldName: 'startDate' | 'endDate', value: string) => {
      makeResetErrorHandler(fieldName)();
      makeChangeHandler(fieldName)(value);
    },
    [makeChangeHandler, makeResetErrorHandler],
  );

  return {
    onUpdateRangeDate,
    onUpdatePerformer,
    onProgramSelect,
  };
};

export default useController;

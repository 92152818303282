import { ReferenceItem } from 'components';
import { Article, Document, Participation, Person, Publication } from '..';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { AuthorPaymentRequestStatus, PaymentRequestStatus, PaymentRequestType } from 'utils/Enums';
import { IdItem, Item } from '../common';

export type AuthorPayment = {
  id: string | null;
  type: Item | null;
  year: string;
  quarter: Item | null;
  month: string;
  status: Item<AuthorPaymentRequestStatus> | null;
  statusChangedBy: IdItem | null;
  createdBy: IdItem | null;
  distributionMethodBetweenNominees: Item | null;
  distributionMethodBetweenFunds: Item | null;
  maxFundMoney: string;
  factFundMoney: string;
  remainderFundMoney: string;
  factNomineesMoney: string;
  maxNomineesMoney: string;
  remainderNomineesMoney: string;
  documents: Document[];
  nominees: Nominee[];
  departments: Department[];
  regulationParagraph: string | null;
  regulationName: string | null;
  statusChangedDate: string;
  article: Article | null;
  conference: Event | null;
  publication: Publication | null;
  participation: Participation | null;
  isManualEdit: boolean;
  patent: SecurityDocument | null;
};

export type Nominee = {
  id?: string | null;
  person: Person.ScientistPerson;
  isAffiliated: boolean;
  isPaid: boolean;
  planMoney: string;
  factMoney: string;
  weight: string;
  name: string;
  job: Person.ScientistJob | null;
  education: Person.ScientistEducation | null;
  foundationJob: Person.ScientistJob | null;
  foundationEducation: Person.ScientistEducation | null;
  degree: Person.ScientistDegree | null;
  rank: Person.ScientistRank | null;
  citizenship: ReferenceItem | null;
  topLevelDepartment: ReferenceItem | null;
  note: string;
};

export type Department = {
  amountMoney: string;
  fraction: string;
  nomineeQuantity: string;
  topLevelDepartment: ReferenceItem | null;
};

export type Event = {
  id: string;
  level: string;
  isStudent: boolean;
  organizers: string;
  name: string;
  fullName: string;
  projects: string;
};

export type EventType =
  | `${PaymentRequestType.CONFERENCE}`
  | `${PaymentRequestType.ARTICLE}`
  | `${PaymentRequestType.MONOGRAPH}`
  | `${PaymentRequestType.TEXTBOOK}`
  | `${PaymentRequestType.MEDAL}`;

export class IncentivePaymentRequestType {
  public static ARTICLE: string = 'ARTICLE';

  public static MONOGRAPH: string = 'MONOGRAPH';

  public static SECURITY_DOCUMENT_ISSUE: string = 'SECURITY_DOCUMENT_ISSUE';

  public static SECURITY_DOCUMENT: string = 'SECURITY_DOCUMENT';

  public static EXHIBIT: string = 'EXHIBIT';

  public static CONFERENCE: string = 'CONFERENCE';

  public static MEDAL: string = 'MEDAL';

  public static TEXTBOOK: string = 'TEXTBOOK';
}

export type TermPreset = {
  id: string;
  type: Item | null;
  regulationParagraph: string;
  regulationName: string;
};

export type PaymentFeedline = {
  id: string | null;
  messageCategory: ReferenceItem;
  message: string;
  status: Item;
  isSystem: boolean;
  createdBy: string;
  modifiedBy: string;
  createdDate: string;
  modifiedDate: string;
};

export type EmployeePayment = {
  id: string | null;
  amountMoney: string;
  status: Item<PaymentRequestStatus> | null;
  fund: EmployeeFund | null;
  nominees: EmployeeNominee[];
  documents: Document[];
};

export type EmployeeNominee = {
  id: string | null;
  employee: Person.ScientistPerson | null;
  amountMoney: string;
  job: Person.ScientistJob | null;
};

export type EmployeeFund = {
  month: string;
  quarter: Item;
  type: Item;
  year: string;
  planIncome: string;
  factIncome: string;
  planExpense: string;
  factExpense: string;
  department: ReferenceItem | null;
  nomineePlanMoney: string;
  nomineeFactMoney: string;
  nomineeQuantity: string;
  nominationFraction: string;
  nominationQuantity: string;
};

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';

const configurations = {
  ForceUnlockEntity: makeBackendAPIConfiguration({
    id: 'UnlockEntity',
    endpoint: '/msa/service/commands/UnlockEntity',
    convertInputToXMLElement: ({ id, type }: { id: string; type: string }) => ({
      Request: {
        _attr: { commandName: 'UnlockEntity' },
        Lock: {
          _attr: { id, name: type, forceUnlock: true },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const lockedEntityConfigurations = { ...configurations };

import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem, RelationTableModal, Select } from 'components';

import { Person } from 'types/models';
import { Item } from 'types/models/common';
import { GetScientistBibliographicNameList } from 'features/Table/specifications';
import { BiblioFieldsModal } from './BiblioFieldsModal';
import { SubmitTable } from 'features/Table/streams';
import { showNotification } from 'features/Notifications';

type Props = {
  scientistPerson: Person.ScientistPerson | null;
  selectedBiblioName: string;
  onChangeBiblioName(biblioName: string): void;
  onAfterAddBiblioName(): void;
  isRequired?: boolean;
};

function SelectBiblioName(props: Props) {
  const { scientistPerson, selectedBiblioName, onAfterAddBiblioName, onChangeBiblioName, isRequired = false } = props;
  const [isOpenTableModal, setIsOpenTableModal] = useState(false);
  const [isOpenFieldsModal, setIsOpenFieldsModal] = useState(false);

  const closeTableModal = useCallback(() => {
    setIsOpenTableModal(false);
    onAfterAddBiblioName();
  }, [setIsOpenTableModal, onAfterAddBiblioName]);

  const openTableModal = useCallback(() => {
    setIsOpenTableModal(true);
  }, [setIsOpenTableModal]);

  const isDisabled = scientistPerson === null;
  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'table' },
        title: 'Варианты ФИО для библиографической ссылки',
        isDisabled,
        onClick: event => {
          event?.currentTarget.blur();
          openTableModal();
        },
      },
    ],
    [isDisabled, openTableModal],
  );

  const handleCloseFieldsModal = useCallback(() => {
    setIsOpenFieldsModal(false);
  }, [setIsOpenFieldsModal]);

  const { methods: saveBibliographicNameAPI } = BackendAPI.useBackendAPI('SaveScientistBibliographicName', {
    onSuccessfullCall: () => {
      onAfterAddBiblioName();
      showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
    },
  });

  const onAddBiblioName = useCallback(
    (fio: string, language: ReferenceItem | null) => {
      if (scientistPerson) {
        if (!language || !fio.trim()) {
          showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
          return;
        }
        saveBibliographicNameAPI.callAPI({
          bibliographicName: fio,
          isDefault: 'false',
          languageId: language.id,
          scientistId: scientistPerson.scientist?.id ?? '',
        });
      }
    },
    [saveBibliographicNameAPI, scientistPerson],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (row) {
        onChangeBiblioName(row.bibliographicName);
        closeTableModal();
      }
    },
    [onChangeBiblioName, closeTableModal],
  );

  const specification = GetScientistBibliographicNameList({
    scientistId: scientistPerson?.scientist?.id ?? '',
    onSubmitTable,
  });

  return (
    <>
      <Select
        value={{ label: selectedBiblioName, value: selectedBiblioName }}
        options={scientistPerson?.bibliographicNames.map(x => ({ value: x.name, label: x.name })) ?? []}
        onChange={(option: Item | null) => onChangeBiblioName(option?.value || '')}
        settings={{ isClearable: !isRequired, externalButtons: buttons }}
        isDisabled={isDisabled}
      />
      <RelationTableModal
        specification={specification}
        modalTitle="Варианты ФИО для библиографической ссылки"
        isOpen={isOpenTableModal}
        onClose={closeTableModal}
      />
      <BiblioFieldsModal isOpen={isOpenFieldsModal} onClose={handleCloseFieldsModal} onSubmitModal={onAddBiblioName} />
    </>
  );
}

export { SelectBiblioName };

import React from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { Permits } from 'utils/Permissions';
import { Table } from 'types/models';

export type Props = {
  handleSave: () => void;
  handleSaveAsDraft: () => void;
  handleSaveAndContinue: () => void;
  workMode: Table.WorkMode;
  isOnlyEditing?: boolean;
  handleOpenPublicationOriginalTranslatesRelations: () => void;
  isDisabled: boolean;
};

function Toolbar({
  handleSave,
  handleSaveAsDraft,
  handleSaveAndContinue,
  workMode,
  isOnlyEditing,
  handleOpenPublicationOriginalTranslatesRelations,
  isDisabled,
}: Props) {
  const viewMode = isOnlyEditing ? false : workMode === 'viewMode';

  const buttons: ButtonProps[] = [
    {
      icon: { type: 'save' },
      title: 'Сохранить',
      onClick: handleSave,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'save', mode: 'add' },
      title: 'Сохранить и продолжить',
      onClick: handleSaveAndContinue,
      isHidden: isOnlyEditing,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'new' },
      title: 'Сохранить как черновик',
      onClick: handleSaveAsDraft,
      isHidden: isOnlyEditing,
      isDisabled: !!viewMode || isDisabled,
    },
    {
      icon: { type: 'lang' },
      title: 'Указать статью-оригинал/переводную статью',
      onClick: handleOpenPublicationOriginalTranslatesRelations,
      permission: {
        name: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT,
      },
      isHidden: isOnlyEditing || workMode === 'addMode',
      isDisabled: !!viewMode || isDisabled,
    },
  ];

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export const ControlPanel = React.memo(Toolbar);

import React from 'react';

import { ButtonMode, Modal, TextArea, TextAreaMode } from 'components';

import { Partner, Table } from 'types/models';
import { GetPartnerList } from 'features/Table/specifications';
import useController from './controller';
import { ToolbarButton } from 'features/Table/specifications/GetPartnerList/makeUseCustomController';

type Props = {
  partner: Partner | null;
  setPartner(partner: Partner | null): void;
  label: string;
  showButtons?: ToolbarButton[];
};

export function SelectPartner({ partner, setPartner, label, showButtons }: Props) {
  const { isLoading, isInfoPopupOpen, onSelectRow, onCloseInfoPopup, onConfirmInfoPopup } = useController();

  return (
    <>
      <TextArea
        mode={TextAreaMode.TABLE}
        settings={{
          title: label,
          table: {
            specification: GetPartnerList({
              toolbarMode: 'full',
              showButtons,
              parameters: [{ name: 'extended', value: false }],
              hasSelectButton: true,
            }),
            onSelect: (row: Table.Entry | null) => (row ? onSelectRow(row, setPartner) : setPartner(null)),
          },
        }}
        value={partner?.fullName}
      />

      <Modal
        mode="info"
        title="Выбран элемент справочника"
        isOpen={isInfoPopupOpen}
        onClose={onCloseInfoPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onConfirmInfoPopup,
            isDisabled: isLoading,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onCloseInfoPopup,
            isDisabled: isLoading,
          },
        ]}
        size="small"
      >
        <p>
          Вы выбрали элемент справочника организаций-заказчиков, на данный момент выбранная организация не заведена в системе как
          партнёр.
        </p>

        <p>Создать партнёра из выбранной организации со статусом &quot;Действующий&quot;?</p>
      </Modal>
    </>
  );
}

import { useCallback } from 'react';

import { RequestPartner } from 'types/models/ProgramRequest';
import { ValueOf } from 'types/helpers';

type Props = {
  partner?: RequestPartner | null;
  onPartnerChange(row: RequestPartner): void;
};

export function useController({ partner, onPartnerChange }: Props) {
  const handleFieldChange = useCallback(
    (fieldName: keyof RequestPartner) => (value: ValueOf<RequestPartner>) => {
      onPartnerChange({ ...partner, [fieldName]: value } as RequestPartner);
    },
    [partner, onPartnerChange],
  );

  return {
    handleFieldChange,
  };
}

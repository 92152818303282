import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';
import { ProjectCode } from 'types/models/Project';
import { IdItem } from 'types/models/common';

type EstimateAddTemplateProps = {
  projectCode?: ProjectCode | null;
  setNextPublicationId?: React.Dispatch<string>;
  saveAndEdit?: () => void;
  fundCard?: IdItem;
} & F.TemplateProps;

const EstimateAddTemplate: React.FC<EstimateAddTemplateProps> = ({
  isOpen,
  onClose,
  projectCode,
  relatedTableState,
  name,
  setNextPublicationId,
  saveAndEdit,
  fundCard,
}: EstimateAddTemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.EstimateLook = {
    apiID: 'GetEstimate',
    template: 'EstimateForm',
    projectCode,
    relatedTableState,
    name,
    setTitle,
    setNextPublicationId,
    saveAndEdit,
    fundCard,
  };

  return (
    <Modal isOpen={isOpen} title={title} onClose={onClose} size={'large'}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(EstimateAddTemplate);

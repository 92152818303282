import React from 'react';

import { ButtonMode, Modal, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState: { requestId } }: Props) {
  const {
    buttons,
    handleConfirmDeleteModal,
    confirmModal,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    message,
    setMessage,
    editId,
  } = useController(tableState, requestId);

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={editId ? 'Редактирование сообщения' : 'Добавление сообщения'}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: editId ? 'Сохранить' : 'Добавить',
            onClick: confirmModal,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsModalOpen(false),
          },
        ]}
        size="small"
      >
        <TextArea settings={{ rows: 5 }} value={message} onChange={setMessage} />
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleConfirmDeleteModal,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsDeleteModalOpen(false),
          },
        ]}
        size="small"
      >
        <>
          (действие: {tableState.selectedRows[0]?.Type}, сообщение: {tableState.selectedRows[0]?.Message}) Удалить сообщение?
        </>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

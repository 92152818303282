import React, { useCallback, useMemo, useState } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { NirRequest, Report } from 'types/models';
import { NirRequestStatus } from 'utils/Enums';
import { MessageModal } from 'features/Form/views/MessageModal';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';
import { Permits } from 'utils/Permissions';
import { Color } from 'constants/colors';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  disabledEdit: boolean;
  onCancel(): void;
  onSave(onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onSendMessage(message: string): void;
  changeStatus(message: string, status: NirRequestStatus): void;
  addApprovement(message: string, status: string): void;
};

export type ModalType =
  | 'medal'
  | 'message'
  | 'conditionalApprove'
  | 'expertEstimate'
  | 'support'
  | 'approve'
  | 'reject'
  | 'revision'
  | 'draft';

export const titleTextMap: Record<ModalType, string> = {
  medal: 'Подача заявки на конкурс',
  message: 'Добавление сообщения в журнал',
  conditionalApprove: 'Согласование заявки с условием',
  expertEstimate: 'Экспертная оценка',
  support: 'Поддержка заявки',
  approve: 'Согласование заявки',
  reject: 'Отклонение заявки',
  revision: 'Отправка заявки на доработку',
  draft: 'Перевод заявки в черновик',
};

export const submitTextMap: Record<ModalType, string> = {
  medal: 'Подать заявку на конкурс',
  message: 'Добавить',
  expertEstimate: 'Продолжить',
  conditionalApprove: 'Согласовать заявку с условием',
  support: 'Поддержать заявку',
  approve: 'Согласовать заявку',
  reject: 'Отклонить заявку',
  revision: 'Отправить заявку на доработку',
  draft: 'Перевести в черновик',
};

function Toolbar(props: Props) {
  const { nirRequest, disabledEdit, onCancel, onSave, onSendMessage, changeStatus, addApprovement, onSaveDraft } = props;

  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [isOpenExpertEstimateModal, setIsOpenExpertEstimateModal] = useState(false);
  const [isOpenWarnSaveModal, setIsOpenWarnSaveModal] = useState(false);

  const [modalType, setModalType] = useState<ModalType | null>(null);

  const openMessageModal = useCallback((type: ModalType) => {
    setModalType(type);
    setIsOpenMessageModal(true);
  }, []);

  const reports = useMemo<Report[]>(
    () => [
      Reports.NirRequestAll,
      Reports.NirRequestProjectTechnicalTask,
      Reports.NirRequestMemorandum,
      Reports.NirRequestProjectEstimate,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const status = nirRequest?.status.value ?? null;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(),
        isDisabled: disabledEdit,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: onSaveDraft,
        isDisabled: disabledEdit,
      },
      {
        icon: { type: 'new' },
        title: 'В черновик',
        onClick: () => openMessageModal('draft'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_DRAFT },
        isHidden: disabledEdit || (!!status && [NirRequestStatus.DRAFT].includes(status)),
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'На согласование',
        onClick: () => openMessageModal('medal'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST },
        isHidden: disabledEdit || (!!status && ![NirRequestStatus.DRAFT, NirRequestStatus.REVISION].includes(status)),
      },

      {
        icon: { type: 'like' },
        title: 'Присвоить заявке статус "Согласована"',
        onClick: () => openMessageModal('approve'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED },
        isHidden: disabledEdit || (!!status && ![NirRequestStatus.REQUEST].includes(status)),
      },
      {
        icon: { type: 'like', color: Color.warning },
        title: 'Присвоить заявке статус "Согласована с условием"',
        onClick: () => openMessageModal('conditionalApprove'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION },
        isHidden:
          disabledEdit ||
          (!!status && ![NirRequestStatus.REQUEST, NirRequestStatus.AGREED, NirRequestStatus.SUPPORTED].includes(status)),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать',
        onClick: () => openMessageModal('support'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_SUPPORTED },
        isHidden:
          disabledEdit || (!!status && ![NirRequestStatus.AGREED, NirRequestStatus.AGREED_WITH_CONDITION].includes(status)),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => openMessageModal('revision'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REVISION },
        isHidden: disabledEdit || (!!status && ![NirRequestStatus.REQUEST, NirRequestStatus.REJECTED].includes(status)),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => openMessageModal('reject'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REJECTED },
        isHidden:
          disabledEdit || (!!status && ![NirRequestStatus.REQUEST, NirRequestStatus.AGREED_WITH_CONDITION].includes(status)),
      },
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsOpenExpertEstimateModal(true),
        isHidden:
          disabledEdit ||
          !(
            status === NirRequestStatus.REQUEST ||
            status === NirRequestStatus.SUPPORTED ||
            status === NirRequestStatus.AGREED_WITH_CONDITION ||
            status === NirRequestStatus.REJECTED ||
            status === NirRequestStatus.REVISION
          ),
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: () => openMessageModal('message'),
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [onSave, onSaveDraft, openMessageModal, getReports, handleSetCurrentReport, status, disabledEdit],
  );

  const submitsMap: Record<ModalType, (message: string) => void> = {
    message: onSendMessage,
    medal: message => onSave(() => changeStatus(message, NirRequestStatus.REQUEST)),
    conditionalApprove: message => onSave(() => changeStatus(message, NirRequestStatus.AGREED_WITH_CONDITION)),
    support: message => onSave(() => changeStatus(message, NirRequestStatus.SUPPORTED)),
    approve: message => onSave(() => changeStatus(message, NirRequestStatus.AGREED)),
    reject: message => onSave(() => changeStatus(message, NirRequestStatus.REJECTED)),
    revision: message => onSave(() => changeStatus(message, NirRequestStatus.REVISION)),
    draft: message => onSave(() => changeStatus(message, NirRequestStatus.DRAFT)),
    expertEstimate: () => {},
  };

  const handleCloseMessageModal = useCallback(() => {
    setIsOpenMessageModal(false);
    setModalType(null);
  }, []);

  const handleCloseExpertEstimateModal = useCallback(() => {
    setIsOpenExpertEstimateModal(false);
  }, []);

  const handleCloseWarnSaveModal = useCallback(() => {
    onCancel();
    setIsOpenWarnSaveModal(false);
  }, [onCancel]);

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          nirRequestId: nirRequest?.id ?? '',
        }}
      />

      <MessageModal
        isOpen={isOpenMessageModal}
        onClose={handleCloseMessageModal}
        onSubmit={modalType ? submitsMap[modalType] : undefined}
        titleText={modalType ? titleTextMap[modalType] : ''}
        submitText={modalType ? submitTextMap[modalType] : ''}
      />

      <ExpertEstimateModal
        isOpen={isOpenExpertEstimateModal}
        onClose={handleCloseExpertEstimateModal}
        onSubmit={addApprovement}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenWarnSaveModal}
        onClose={handleCloseWarnSaveModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => onSave?.(),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseWarnSaveModal,
          },
        ]}
        size="small"
      >
        <>В текущей сессии, были сделаны изменения в данных заявки. Сохранить изменения?</>
      </Modal>
    </>
  );
}

export { Toolbar };

import React from 'react';

import { FormComponent, Select, SelectMode } from 'components';

import { Department, Project } from 'types/models';
import { useController } from './controller';
import { ValueOf } from 'types/helpers';

type Props = {
  department: Project.Department;
  codes: string;
  setDepartment(department: Project.Department): void;
};

export function Fields(props: Props) {
  const { department, setDepartment, codes } = props;

  const { makeChangeHandler } = useController({ department, setDepartment });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department.unit}
            onChange={option => makeChangeHandler('unit')(option as ValueOf<Department>)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Факультет/Институт" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department.governance}
            onChange={option => makeChangeHandler('governance')(option as ValueOf<Department>)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"', isFacultyFilterChecked: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!!codes && (
        <FormComponent.Line>
          <>
            Для подразделения создан(ы) шифр(ы) <strong>&ldquo;{codes}&rdquo;</strong> Для изменения подразделения, необходимо
            удалить шифр. Вы можете выбрать другое подразделение с аналогичным кодом.
          </>
        </FormComponent.Line>
      )}
    </FormComponent.Wrapper>
  );
}

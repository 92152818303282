import { Table, Form } from 'types/models';

export const convertAffiliation = (row: Table.Entry): Form.Affiliation => {
  return {
    organization: {
      id: row.id,
      name: row.FullName,
      kind: row.Type,
    },
  };
};

export const convertCouncil = (row: Table.Entry): Form.Council => {
  return {
    id: row.id,
    name: row.Name,
  };
};

export const REQUIRED_CATEGORY_CODE = 'supervisor';

import React, { useCallback } from 'react';

import {
  Checkbox,
  FormComponent,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  Toolbar,
} from 'components';

import { Performer, ProjectCode, Project } from 'types/models/Project';
import { Form, Table } from 'types/models';
import { Item } from 'types/models/common';
import { useController } from './controller';
import { useAppDataContext } from 'features/AppData/context';

import './style.scss';

type Props = {
  projectCode: ProjectCode | null;
  formFields: Form.Fields;
  findPerformerByJobPeriodRole(role: string, targetProject?: Project | null): Performer | null;
  disabled?: boolean;
  handleFinancingSourceChange(value: Item<string> | null): void;
  generateProjectCode(): void;
  replaceMode?: boolean;
};

export function AboutProjectCode({
  projectCode,
  formFields,
  disabled,
  findPerformerByJobPeriodRole,
  handleFinancingSourceChange,
  generateProjectCode,
  replaceMode,
}: Props) {
  const {
    departmentOptions,
    performerOptions,
    financingOptions,
    modalTableAvailableFundCardConverter,
    availableFundCardSpecification,
    projectCodesLabel,
    getNameWithContacts,
    projectCodeFinAmountSum,
    projectCodeNdsAmountSum,
    finSourceEnabled,
    projectCodeTooltipTxt,
  } = useController({
    projectCode,
    formFields,
  });

  const { initialOrganizationCode } = useAppDataContext();

  const getPersonDataByType = useCallback(
    (type: string) => {
      const project: Project | null | undefined = projectCode?.project;
      const performer: Performer | null = findPerformerByJobPeriodRole(type, project);

      return getNameWithContacts(performer);
    },
    [findPerformerByJobPeriodRole, getNameWithContacts, projectCode?.project],
  );

  const getFinancing = useCallback(() => {
    return (projectCode?.project?.financings || [])
      .map(item => {
        return [
          item.source?.label || '',
          (() => {
            const splittedLabel = item.type?.label.split('(');
            if (splittedLabel && splittedLabel[1]) {
              return `(${splittedLabel[1]}`;
            }
            return '';
          })(),
        ]
          .filter(Boolean)
          .join(' ');
      })
      .join(', ');
  }, [projectCode?.project?.financings]);

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '50%' }}>
        <SectionTitle title="Справочная информация" />

        <FormComponent.Line>
          <FormComponent.Field label={formFields.department.title} isRequired={formFields.department.required}>
            <Select
              value={departmentOptions.find(x => x.value === formFields.department?.value?.id) ?? null}
              options={departmentOptions}
              onChange={formFields.department.onChange}
              isDisabled={disabled || !!projectCode?.id}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Факультет/Институт">
            <FormComponent.Text>{formFields?.department?.value?.governance?.label || ''}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.responsiblePerformer.title}
            isRequired={formFields.responsiblePerformer.required}
          >
            <Select
              value={formFields.responsiblePerformer.value}
              options={performerOptions}
              onChange={formFields.responsiblePerformer.onChange}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {initialOrganizationCode?.code === 'TSU' ? (
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field
              label={formFields.letter?.title}
              isRequired={formFields.letter?.required}
              tooltip={projectCodeTooltipTxt}
            >
              <Select
                mode={SelectMode.REFERENCE}
                value={formFields.letter?.value}
                onChange={formFields.letter?.onChange}
                settings={{
                  name: 'RefProjectCodeLetter',
                  title: 'Справочник "Буквы шифров"',
                  isClearable: !formFields.letter?.required,
                }}
                isDisabled={disabled || (Boolean(projectCode?.id) && !replaceMode)}
                placeholder=""
                classMixin="project-code-letter"
              />
            </FormComponent.Field>
            <FormComponent.Field>
              <TextInput
                value={formFields.codeDepartmentPart?.value}
                onChange={formFields.codeDepartmentPart?.onChange}
                isDisabled={disabled || Boolean(projectCode?.id)}
              />
            </FormComponent.Field>
            <FormComponent.Field>
              <TextInput
                value={formFields.codeManualPart?.value}
                onChange={formFields.codeManualPart?.onChange}
                isDisabled={disabled || Boolean(projectCode?.id)}
              />
            </FormComponent.Field>
            <Toolbar
              buttons={[
                {
                  icon: { type: 'calc' },
                  title: 'Получить наименьший свободный номер',
                  onClick: generateProjectCode,
                  isDisabled:
                    disabled ||
                    (Boolean(projectCode?.id) && !replaceMode) ||
                    !formFields.letter?.value?.id ||
                    !formFields.codeYearPart?.value ||
                    !formFields.codeDepartmentPart?.value,
                },
              ]}
            />
            <FormComponent.Field>
              <FormComponent.Text>{formFields.codeYearPart?.value}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
        ) : (
          <FormComponent.Line>
            <FormComponent.Field label="Шифр" isRequired={formFields.code.required} tooltip={projectCodeTooltipTxt}>
              <TextInput
                value={formFields.code.value}
                onChange={formFields.code.onChange}
                isDisabled={disabled || (Boolean(projectCode?.id) && !replaceMode)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label={formFields.financing.title} isRequired={formFields.financing.required}>
            <Select
              value={financingOptions.find(x => x.label === formFields.financing?.value?.label) ?? null}
              options={financingOptions}
              onChange={handleFinancingSourceChange}
              isDisabled={disabled || Boolean(projectCode?.id)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field
            label="Объем финансирования"
            tooltip="Данные отображаются из итоговой строки в таблице Финансирование шифра по годам на вкладке Финансовый план."
          >
            {projectCodeFinAmountSum || '-'}
          </FormComponent.Field>
          {finSourceEnabled ? <FormComponent.Field label="НДС">{projectCodeNdsAmountSum}</FormComponent.Field> : <></>}
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.financeProvide.title} isRequired={formFields.financeProvide.required}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.financeProvide.value}
              onChange={formFields.financeProvide.onChange}
              settings={{
                name: 'RefFinanceProvide',
                title: 'Справочник "Виды финансового обеспечения"',
                isClearable: !formFields.financeProvide.required,
              }}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Checkbox
              label={formFields.isOutsideBudget.title}
              checked={!!formFields.isOutsideBudget.value}
              onChange={formFields.isOutsideBudget.onChange}
            />
            <Checkbox
              label={formFields.isRefersToEconomicAgreement.title}
              checked={!!formFields.isRefersToEconomicAgreement.value}
              onChange={formFields.isRefersToEconomicAgreement.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {!!projectCode?.project?.contractKind && (
          <FormComponent.Line>
            <FormComponent.Field label="Вид договора">
              <FormComponent.Text>{projectCode?.project?.contractKind.label}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {!!projectCode?.project?.nationalProjectExpenseCode && (
          <FormComponent.Line>
            <FormComponent.Field label="Код расходов Нацпроекта">
              <FormComponent.Text>{projectCode?.project?.nationalProjectExpenseCode.label}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label="Передавать накладные расходы на карточку ЦФ">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Список шифров Централизованного фонда (фонд накладных расходов)',
                table: {
                  specification: availableFundCardSpecification,
                  onSelect: (row: Table.Entry | null) =>
                    formFields.fundCard.onChange(row ? modalTableAvailableFundCardConverter(row) : null),
                },
              }}
              value={formFields.fundCard?.value?.name}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: '45%' }}>
        <SectionTitle title="Сведения о проекте" />

        <FormComponent.Line>
          <FormComponent.Field label="Код номера проекта">
            <FormComponent.Text>{projectCode?.project?.numberPrefix?.label}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Номер проекта">
            <FormComponent.Text>{projectCode?.project?.number}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сроки выполнения">
            <FormComponent.Text>
              {projectCode?.project?.startDate} - {projectCode?.project?.endDate}
            </FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Руководитель">
            <FormComponent.Text>{getPersonDataByType('LEADER')}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Отв. исполнитель">
            <FormComponent.Text>{getPersonDataByType('RESPONSIBLE_PERFORMER')}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={(projectCode?.project?.customers.length || 0) > 1 ? 'Заказчики' : 'Заказчик'}>
            <FormComponent.Text>{projectCode?.project?.customers.map(x => x.ref?.label).join(', ')}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Источники финансирования">
            <FormComponent.Text>{getFinancing()}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Нормативный процент накладных">
            <FormComponent.Text>{projectCode?.project?.overheadPercent || 12}%</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сведения о шифрах проекта:">
            <FormComponent.Text>{projectCodesLabel}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetSecurityDocumentMemberAnalyticList: Table.Specification = {
  apiID: 'GetSecurityDocumentMemberAnalyticList',
  header: {
    firstLevel: {
      title: 'Анализ по авторам',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

import { Exponent } from 'utils/Permissions/Exponent';
import {
  MobileRequestStatus,
  PublicationStatus,
  RequestStatus,
  ContestRequestStatus,
  NirRequestStatus,
  PurchaseRequestStatus,
  PurchaseRequestApprovementApprover,
} from 'utils/Enums';
import { ProjectPerformerOrder } from 'utils/Permissions/ProjectPerformerOrder';
import { PurchaseRequest } from 'utils/Permissions/PurchaseRequest';
import { RequestKindConsumption } from 'utils/Permissions/RequestKindConsumption';
import { TenderProtocol, TenderProtocolTabs } from 'utils/Permissions/TenderProtocol';

import { Actions } from './Actions';
import { Community } from './Community';
import { CommunityInfo } from './CommunityInfo';
import { Compilation } from './Compilation';
import { EventTab } from './EventTab';
import { ContestRequest } from './ContestRequest';
import { ContestRequestKindConsumption } from './ContestRequestKindConsumption';
import { ContestRequestTab } from './ContestRequestTab';
import { EconomicContractAnalytics } from './EconomicContractAnalytics';
import { Estimate } from './Estimate';
import { Event } from './Event';
import { Feedline } from './Feedline';
import { Fund } from './Fund';
import { FundCard } from './FundCard';
import { GovContractAnalytics } from './GovContractAnalytics';
import { GrantAnalytics } from './GrantAnalytics';
import { HelpfulStuff } from './HelpfulStuff';
import { IncentivePayment } from './IncentivePayment';
import { IntellectualPropertyAnalytics } from './IntellectualPropertyAnalytics';
import { JointEvent } from './JointEvent';
import { KindConsumption } from './KindConsumption';
import { Lot } from './Lot';
import { Magazine } from './Magazine';
import { Midfixes } from './Midfixes';
import { Mip } from './Mip';
import { MobileExpenseNormative } from './MobileExpenseNormative';
import { MobileKindConsumption } from './MobileKindConsumption';
import { MobileRequest } from './MobileRequest';
import { MobileRequestTab } from './MobileRequestTab';
import { NirFinancing } from './NirFinancing';
import { NirRequest } from './NirRequest';
import { NirRequestKindConsumption } from './NirRequestKindConsumption';
import { NirRequestTab } from './NirRequestTab';
import { Notice } from './Notice';
import { Participation } from './Participation';
import { Partner } from './Partner';
import { PatentResearch } from './PatentResearch';
import { Person } from './Person';
import { PersonalCabinet } from './PersonalCabinet';
import { Program } from './Program';
import { ProgramEvent } from './ProgramEvent';
import { ProgramRequest } from './ProgramRequest';
import { ProgramRequestTab } from './ProgramRequestTab';
import { ProjectCode } from './ProjectCode';
import { ProjectLock } from './ProjectLock';
import { ProjectStateRegistration } from './ProjectStateRegistration';
import { ProtocolCommission } from './ProtocolCommission';
import { Publication } from './Publication';
import { Qna } from './Qna';
import { Recognition } from './Recognition';
import { Report } from './Report';
import { ResearchJobAnalytics } from './ResearchJobAnalytics';
import { Rntd } from './Rntd';
import { Salary } from './Salary';
import { ScientificIndexAndIndicator } from './ScientificIndexAndIndicator';
import { SecurityDocument } from './SecurityDocument';
import { SecurityDocumentContract } from './SecurityDocumentContract';
import { Subject } from './Subject';
import { Tender } from './Tender';
import { TotalAnalytics } from './TotalAnalytics';
import { UsefulLink } from './UsefulLink';
import { User } from './User';

import { MsaWorkbenches } from '../Common/MsaWorkbenches';
import { Reference } from './Reference';
import { Search } from './Search';
import { Equip } from './Equip';
import { EquipVerification } from './EquipVerification';
import { GovContract } from './GovContract';
import { Status } from './Status';
import { EconomicContract } from './EconomicContract';
import { Grant } from './Grant';
import { ResearchJob } from './ResearchJob';
import { Project } from './Project';
import { CashDocument } from './CashDocument';
import { Notification } from './Notification';
import { Level } from './Level';
import { General } from './General';

export class Permits {
  private static SEPARATOR: string = ':';

  /* Личный кабинет **/
  static PERSONAL_CABINET: string = PersonalCabinet.MODULE;

  /* Личный кабинет. Меню **/
  static PC_MENU_PUBLICATIONS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PUBLICATIONS,
    Actions.ACCESS,
  );

  static PC_MENU_EVENTS_CONTEST_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.EVENTS,
    PersonalCabinet.CONTEST,
    Actions.ACCESS,
  );

  static PC_MENU_EVENTS_CONFERENCE_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.EVENTS,
    PersonalCabinet.CONFERENCE,
    Actions.ACCESS,
  );

  static PC_MENU_EVENTS_EXPOSITION_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.EVENTS,
    PersonalCabinet.EXPOSITION,
    Actions.ACCESS,
  );

  static PC_MENU_EVENTS_CONCERT_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.EVENTS,
    PersonalCabinet.CONCERT,
    Actions.ACCESS,
  );

  static PC_MENU_PARTICIPATIONS_CONTEST_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PARTICIPATIONS,
    PersonalCabinet.CONTEST,
    Actions.ACCESS,
  );

  static PC_MENU_PARTICIPATIONS_CONFERENCE_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PARTICIPATIONS,
    PersonalCabinet.CONFERENCE,
    Actions.ACCESS,
  );

  static PC_MENU_PARTICIPATIONS_EXPOSITION_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PARTICIPATIONS,
    PersonalCabinet.EXPOSITION,
    Actions.ACCESS,
  );

  static PC_MENU_PARTICIPATIONS_CONCERT_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PARTICIPATIONS,
    PersonalCabinet.CONCERT,
    Actions.ACCESS,
  );

  static PC_MENU_PERSONALACHIEVEMENTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PERSONALACHIEVEMENTS,
    Actions.ACCESS,
  );

  static PC_MENU_TENDERREQUESTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.TENDERREQUESTS,
    Actions.ACCESS,
  );

  static PC_MENU_PROJECTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PROJECTS,
    Actions.ACCESS,
  );

  static PC_MENU_CONTESTREQUESTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.CONTESTREQUESTS,
    Actions.ACCESS,
  );

  static PC_MENU_PROJECTORDERS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PROJECTORDERS,
    Actions.ACCESS,
  );

  static PC_MENU_PURCHASEREQUESTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PURCHASEREQUESTS,
    Actions.ACCESS,
  );

  static PC_MENU_ALLPROJECTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.ALLPROJECTS,
    Actions.ACCESS,
  );

  static PC_MENU_PROJECTRESTRICTIONS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.PROJECTRESTRICTIONS,
    Actions.ACCESS,
  );

  static PC_MENU_SECURITYDOCUMENTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.SECURITYDOCUMENTS,
    Actions.ACCESS,
  );

  static PC_MENU_ALLSECURITYDOCUMENTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.ALLSECURITYDOCUMENTS,
    Actions.ACCESS,
  );

  static PC_MENU_FOREIGNSECURITYDOCUMENTS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.FOREIGNSECURITYDOCUMENTS,
    Actions.ACCESS,
  );

  static PC_MENU_DISSERTATIONSDEFENCESUPERVISING_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.DISSERTATIONSDEFENCESUPERVISING,
    Actions.ACCESS,
  );

  static PC_MENU_COLLEAGUES_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.COLLEAGUES,
    Actions.ACCESS,
  );

  static PC_MENU_RECOGNITIONS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.RECOGNITIONS,
    Actions.ACCESS,
  );

  static PC_MENU_DISSERTATIONCOMMUNITIESPARTICIPATION_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.DISSERTATIONCOMMUNITIESPARTICIPATION,
    Actions.ACCESS,
  );

  static PC_MENU_COMMUNITIESPARTICIPATION_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.COMMUNITIESPARTICIPATION,
    Actions.ACCESS,
  );

  static PC_MENU_NEWS_NOTICES_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.NEWS,
    PersonalCabinet.NOTICES,
    Actions.ACCESS,
  );

  static PC_MENU_NEWS_TENDERS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.NEWS,
    PersonalCabinet.TENDERS,
    Actions.ACCESS,
  );

  static PC_MENU_NEWS_QNAS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.NEWS,
    PersonalCabinet.QNAS,
    Actions.ACCESS,
  );

  static PC_MENU_NEWS_FQNAS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.NEWS,
    PersonalCabinet.FQNAS,
    Actions.ACCESS,
  );

  static PC_MENU_NEWS_USEFUL_LINKS_AND_HELPFUL_STUFFS_ACCESS: string = this.build(
    PersonalCabinet.MODULE,
    PersonalCabinet.MENU,
    PersonalCabinet.NEWS,
    PersonalCabinet.USEFUL_LINKS_AND_HELPFUL_STUFFS,
    Actions.ACCESS,
  );

  /* Стимулирующие выплаты */
  static getNomineeRequestCRUDPermission(type: string, action: string, status: string | null = null): string {
    if (status) {
      return this.build(IncentivePayment.MODULE, IncentivePayment.NOMINEE_REQUEST_BRANCH, type, action, status);
    }
    return this.build(IncentivePayment.MODULE, IncentivePayment.NOMINEE_REQUEST_BRANCH, type, action);
  }

  static PATENT_REQUEST_STATUS_TO_AGREEMENT: string = 'IPPatentRequestsStatusChangeToAgreement';

  static PATENT_REQUEST_STATUS_TO_PAID: string = 'IPPatentRequestsStatusChangeToPaid';

  static PATENT_REQUEST_STATUS_TO_REJECTED: string = 'IPPatentRequestsStatusChangeToRejected';

  static PATENT_REQUEST_STATUS_TO_REVISION: string = 'IPPatentRequestsStatusChangeToRevision';

  static PATENT_REQUEST_STATUS_TO_POSTPONE: string = 'IPPatentRequestsStatusChangeToPostpone';

  static PATENT_REQUEST_STATUS_TO_PAYMENT_APPROVED: string = 'IPPatentRequestsStatusChangeToPaymentApproved';

  /* Фонды */
  static IP_FUND_VIEW_BY_DEPARTMENT = 'IP' + Fund.MODULE + Actions.VIEW + 'ByDepartment';

  static IP_FUND_VIEW_FULL_LIST = 'IP' + Fund.MODULE + Actions.VIEW + 'FullList';

  /* Пользователи */
  static USER_MERGE: string = this.build(User.MODULE, Actions.MERGE);

  /* Наукометрические показатели */
  static SCIENTIFIC_INDEX_AND_INDICATOR_EDIT: string = this.build(ScientificIndexAndIndicator.MODULE, Actions.EDIT);

  static HIRSH_INDEX_EDIT: string = 'HirschIndexEdit';

  /* Персоны */
  static PERSON_VIEW: string = this.build(Person.MODULE, Actions.VIEW);

  static PERSON_ADD: string = this.build(Person.MODULE, Actions.ADD);

  static PERSON_EDIT: string = this.build(Person.MODULE, Actions.EDIT);

  static PERSON_JOB_EDUCATION_DEGREE_RANK_DELETE: string = this.build(Person.MODULE, 'JobEducationDegreeRank', Actions.DELETE);

  static PERSON_DELETE: string = this.build(Person.MODULE, Actions.DELETE);

  static PERSON_MERGE: string = this.build(Person.MODULE, Actions.MERGE);

  static PERSON_EGLISH_FULL_NAME_EDIT: string = this.build(Person.MODULE, Person.ENGLISH_FULL_NAME_BLOCK, Actions.EDIT);

  static PERSON_IDENTIFIER_AUTHOR_EDIT: string = this.build(Person.MODULE, Person.IDENTIFIER_AUTHOR, Actions.EDIT);

  static PERSON_LOCAL_JOB_CREATE: string = 'PersonLocalJobCreate';

  static PERSON_LOCAL_EDUCATION_CREATE: string = 'PersonLocalEducationCreate';

  static PERSON_DUPLICATES_PROCESS: string = this.build(Person.MODULE, 'Duplicates', 'Process');

  static PERSON_PRIVATE_DATA_SNILS_INN_ACCESS: string = this.build(
    Person.MODULE,
    Person.PRIVATE_DATA,
    Person.SNILS_INN,
    Actions.ACCESS,
  );

  static PERSON_PRIVATE_DATA_CONTACTS_ACCESS: string = this.build(
    Person.MODULE,
    Person.PRIVATE_DATA,
    Person.CONTACTS,
    Actions.ACCESS,
  );

  static PERSON_PRIVATE_DATA_BIRTH_DATE_ACCESS: string = this.build(
    Person.MODULE,
    Person.PRIVATE_DATA,
    Person.BIRTH_DATE,
    Actions.ACCESS,
  );

  /* Мероприятия */
  static CONTEST_LIST_VIEW: string = this.build(Event.CONTEST, Midfixes.LIST, Actions.VIEW);

  static CONTEST_VIEW: string = this.build(Event.CONTEST, Actions.VIEW);

  static CONTEST_ADD: string = this.build(Event.CONTEST, Actions.ADD);

  static CONTEST_DELETE_ADDED_ALL: string = this.build(Event.CONTEST, Actions.DELETE, PublicationStatus.ADDED, Level.ALL);

  static CONTEST_DELETE_ADDED_MY: string = this.build(Event.CONTEST, Actions.DELETE, PublicationStatus.ADDED, Level.MY);

  static CONTEST_DELETE_APPROVED_ALL: string = this.build(Event.CONTEST, Actions.DELETE, PublicationStatus.APPROVED);

  static CONTEST_EDIT_ADDED_ALL: string = this.build(Event.CONTEST, Actions.EDIT, PublicationStatus.ADDED, Level.ALL);

  static CONTEST_EDIT_ADDED_MY: string = this.build(Event.CONTEST, Actions.EDIT, PublicationStatus.ADDED, Level.MY);

  static CONTEST_EDIT_APPROVED_ALL: string = this.build(Event.CONTEST, Actions.EDIT, PublicationStatus.APPROVED, Level.ALL);

  static CONTEST_EDIT_APPROVED_MY_ABOUT: string = this.build(
    Event.CONTEST,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ABOUT,
  );

  static CONTEST_EDIT_APPROVED_MY_ORGANIZATION: string = this.build(
    Event.CONTEST,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ORGANIZATION,
  );

  static CONTEST_EDIT_APPROVED_MY_FINANCING: string = this.build(
    Event.CONTEST,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.FINANCING,
  );

  static CONTEST_APPROVE: string = this.build(Event.CONTEST, Actions.APPROVE);

  static CONTEST_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONTEST, 'ScienceProjectAccepting');

  static CONFERENCE_LIST_VIEW: string = this.build(Event.CONFERENCE, Midfixes.LIST, Actions.VIEW);

  static CONFERENCE_VIEW: string = this.build(Event.CONFERENCE, Actions.VIEW);

  static CONFERENCE_ADD: string = this.build(Event.CONFERENCE, Actions.ADD);

  static CONFERENCE_DELETE_ADDED_ALL: string = this.build(Event.CONFERENCE, Actions.DELETE, PublicationStatus.ADDED, Level.ALL);

  static CONFERENCE_DELETE_ADDED_MY: string = this.build(Event.CONFERENCE, Actions.DELETE, PublicationStatus.ADDED, Level.MY);

  static CONFERENCE_DELETE_APPROVED_ALL: string = this.build(Event.CONFERENCE, Actions.DELETE, PublicationStatus.APPROVED);

  static CONFERENCE_EDIT_ADDED_ALL: string = this.build(Event.CONFERENCE, Actions.EDIT, PublicationStatus.ADDED, Level.ALL);

  static CONFERENCE_EDIT_ADDED_MY: string = this.build(Event.CONFERENCE, Actions.EDIT, PublicationStatus.ADDED, Level.MY);

  static CONFERENCE_EDIT_APPROVED_ALL: string = this.build(Event.CONFERENCE, Actions.EDIT, PublicationStatus.APPROVED, Level.ALL);

  static CONFERENCE_EDIT_APPROVED_MY_ABOUT: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ABOUT,
  );

  static CONFERENCE_EDIT_APPROVED_MY_ORGANIZATION: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ORGANIZATION,
  );

  static CONFERENCE_EDIT_APPROVED_MY_FINANCING: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.FINANCING,
  );

  static CONFERENCE_EDIT_APPROVED_MY_CLASSIFICATORS: string = this.build(
    Event.CONFERENCE,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.CLASSIFICATORS,
  );

  static CONFERENCE_APPROVE: string = this.build(Event.CONFERENCE, Actions.APPROVE);

  static CONFERENCE_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONFERENCE, 'ScienceProjectAccepting');

  static EXPOSITION_LIST_VIEW: string = this.build(Event.EXPOSITION, Midfixes.LIST, Actions.VIEW);

  static EXPOSITION_VIEW: string = this.build(Event.EXPOSITION, Actions.VIEW);

  static EXPOSITION_ADD: string = this.build(Event.EXPOSITION, Actions.ADD);

  static EXPOSITION_DELETE_ADDED_ALL: string = this.build(Event.EXPOSITION, Actions.DELETE, PublicationStatus.ADDED, Level.ALL);

  static EXPOSITION_DELETE_ADDED_MY: string = this.build(Event.EXPOSITION, Actions.DELETE, PublicationStatus.ADDED, Level.MY);

  static EXPOSITION_DELETE_APPROVED_ALL: string = this.build(Event.EXPOSITION, Actions.DELETE, PublicationStatus.APPROVED);

  static EXPOSITION_EDIT_ADDED_ALL: string = this.build(Event.EXPOSITION, Actions.EDIT, PublicationStatus.ADDED, Level.ALL);

  static EXPOSITION_EDIT_ADDED_MY: string = this.build(Event.EXPOSITION, Actions.EDIT, PublicationStatus.ADDED, Level.MY);

  static EXPOSITION_EDIT_APPROVED_ALL: string = this.build(Event.EXPOSITION, Actions.EDIT, PublicationStatus.APPROVED, Level.ALL);

  static EXPOSITION_EDIT_APPROVED_MY_ABOUT: string = this.build(
    Event.EXPOSITION,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ABOUT,
  );

  static EXPOSITION_EDIT_APPROVED_MY_ORGANIZATION: string = this.build(
    Event.EXPOSITION,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ORGANIZATION,
  );

  static EXPOSITION_EDIT_APPROVED_MY_FINANCING: string = this.build(
    Event.EXPOSITION,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.FINANCING,
  );

  static EXPOSITION_APPROVE: string = this.build(Event.EXPOSITION, Actions.APPROVE);

  static EXPOSITION_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.EXPOSITION, 'ScienceProjectAccepting');

  static CONCERT_LIST_VIEW: string = this.build(Event.CONCERT, Midfixes.LIST, Actions.VIEW);

  static CONCERT_VIEW: string = this.build(Event.CONCERT, Actions.VIEW);

  static CONCERT_ADD: string = this.build(Event.CONCERT, Actions.ADD);

  static CONCERT_DELETE_ADDED_ALL: string = this.build(Event.CONCERT, Actions.DELETE, PublicationStatus.ADDED, Level.ALL);

  static CONCERT_DELETE_ADDED_MY: string = this.build(Event.CONCERT, Actions.DELETE, PublicationStatus.ADDED, Level.MY);

  static CONCERT_DELETE_APPROVED_ALL: string = this.build(Event.CONCERT, Actions.DELETE, PublicationStatus.APPROVED);

  static CONCERT_EDIT_ADDED_ALL: string = this.build(Event.CONCERT, Actions.EDIT, PublicationStatus.ADDED, Level.ALL);

  static CONCERT_EDIT_ADDED_MY: string = this.build(Event.CONCERT, Actions.EDIT, PublicationStatus.ADDED, Level.MY);

  static CONCERT_EDIT_APPROVED_ALL: string = this.build(Event.CONCERT, Actions.EDIT, PublicationStatus.APPROVED, Level.ALL);

  static CONCERT_EDIT_APPROVED_MY_ABOUT: string = this.build(
    Event.CONCERT,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ABOUT,
  );

  static CONCERT_EDIT_APPROVED_MY_ORGANIZATION: string = this.build(
    Event.CONCERT,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.ORGANIZATION,
  );

  static CONCERT_EDIT_APPROVED_MY_FINANCING: string = this.build(
    Event.CONCERT,
    Actions.EDIT,
    PublicationStatus.APPROVED,
    Level.MY,
    EventTab.FINANCING,
  );

  static CONCERT_APPROVE: string = this.build(Event.CONCERT, Actions.APPROVE);

  static CONCERT_SCIENCE_PROJECT_ACCEPTING: string = this.build(Event.CONCERT, 'ScienceProjectAccepting');

  /* Признания */
  static RECOGNITION_INFO_VIEW: string = this.build(Recognition.MODULE, Midfixes.INFO, Actions.VIEW);

  static RECOGNITION_ADD: string = this.build(Recognition.MODULE, Actions.ADD);

  static RECOGNITION_ADDED_EDIT: string = this.build(Recognition.MODULE, PublicationStatus.ADDED, Actions.EDIT);

  static RECOGNITION_APPROVED_EDIT: string = this.build(Recognition.MODULE, PublicationStatus.APPROVED, Actions.EDIT);

  static RECOGNITION_ADDED_DELETE: string = this.build(Recognition.MODULE, PublicationStatus.ADDED, Actions.DELETE);

  static RECOGNITION_APPROVED_DELETE: string = this.build(Recognition.MODULE, PublicationStatus.APPROVED, Actions.DELETE);

  static RECOGNITION_APPROVE: string = this.build(Recognition.MODULE, Actions.APPROVE);

  static RECOGNITION_DEPARTMENT_EDIT: string = this.build(Recognition.MODULE, 'DepartmentEdit');

  /* Участия */
  static PARTICIPATION_CONTEST_LIST_VIEW: string = this.build(Participation.MODULE, Event.CONTEST, Midfixes.LIST, Actions.VIEW);

  static PARTICIPATION_CONTEST_VIEW: string = this.build(Participation.MODULE, Event.CONTEST, Actions.VIEW);

  static PARTICIPATION_CONTEST_ADD: string = this.build(Participation.MODULE, Event.CONTEST, Actions.ADD);

  static PARTICIPATION_CONTEST_EDIT_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.EDIT,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONTEST_EDIT_ALL: string = this.build(Participation.MODULE, Event.CONTEST, Actions.EDIT, Level.ALL);

  static PARTICIPATION_CONTEST_DELETE_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONTEST,
    Actions.DELETE,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONTEST_DELETE_ALL: string = this.build(Participation.MODULE, Event.CONTEST, Actions.DELETE, Level.ALL);

  static PARTICIPATION_CONTEST_APPROVE: string = this.build(Participation.MODULE, Event.CONTEST, Actions.APPROVE);

  static PARTICIPATION_CONTEST_BIND_TO_DEPARTMENT: string = this.build(Participation.MODULE, Event.CONTEST, 'BindToDepartment');

  static PARTICIPATION_CONTEST_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONTEST, 'DepartmentEdit');

  static PARTICIPATION_CONFERENCE_LIST_VIEW: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Midfixes.LIST,
    Actions.VIEW,
  );

  static PARTICIPATION_CONFERENCE_VIEW: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.VIEW);

  static PARTICIPATION_CONFERENCE_ADD: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.ADD);

  static PARTICIPATION_CONFERENCE_EDIT_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.EDIT,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONFERENCE_EDIT_ALL: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.EDIT, Level.ALL);

  static PARTICIPATION_CONFERENCE_DELETE_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.DELETE,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONFERENCE_DELETE_ALL: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    Actions.DELETE,
    Level.ALL,
  );

  static PARTICIPATION_CONFERENCE_APPROVE: string = this.build(Participation.MODULE, Event.CONFERENCE, Actions.APPROVE);

  static PARTICIPATION_CONFERENCE_BIND_TO_DEPARTMENT: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'BindToDepartment',
  );

  static PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONFERENCE, 'DepartmentEdit');

  static PARTICIPATION_CONFERENCE_PROJECTS_EDIT: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'Projects',
    Actions.EDIT,
  );

  static PARTICIPATION_CONFERENCE_PROJECTS_ACCEPTING: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'Projects',
    Actions.ACCEPTING,
  );

  static PARTICIPATION_CONFERENCE_PROJECTS_VIEW_ALL: string = this.build(
    Participation.MODULE,
    Event.CONFERENCE,
    'Projects',
    Actions.VIEW,
    Level.ALL,
  );

  static PARTICIPATION_EXPOSITION_LIST_VIEW: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Midfixes.LIST,
    Actions.VIEW,
  );

  static PARTICIPATION_EXPOSITION_VIEW: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.VIEW);

  static PARTICIPATION_EXPOSITION_ADD: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.ADD);

  static PARTICIPATION_EXPOSITION_EDIT_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.EDIT,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_EXPOSITION_EDIT_ALL: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.EDIT, Level.ALL);

  static PARTICIPATION_EXPOSITION_DELETE_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.DELETE,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_EXPOSITION_DELETE_ALL: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    Actions.DELETE,
    Level.ALL,
  );

  static PARTICIPATION_EXPOSITION_APPROVE: string = this.build(Participation.MODULE, Event.EXPOSITION, Actions.APPROVE);

  static PARTICIPATION_EXPOSITION_BIND_TO_DEPARTMENT: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'BindToDepartment',
  );

  static PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.EXPOSITION, 'DepartmentEdit');

  static PARTICIPATION_EXPOSITION_PROJECTS_EDIT: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'Projects',
    Actions.EDIT,
  );

  static PARTICIPATION_EXPOSITION_PROJECTS_ACCEPTING: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'Projects',
    Actions.ACCEPTING,
  );

  static PARTICIPATION_EXPOSITION_PROJECTS_VIEW_ALL: string = this.build(
    Participation.MODULE,
    Event.EXPOSITION,
    'Projects',
    Actions.VIEW,
    Level.ALL,
  );

  static PARTICIPATION_CONCERT_LIST_VIEW: string = this.build(Participation.MODULE, Event.CONCERT, Midfixes.LIST, Actions.VIEW);

  static PARTICIPATION_CONCERT_VIEW: string = this.build(Participation.MODULE, Event.CONCERT, Actions.VIEW);

  static PARTICIPATION_CONCERT_ADD: string = this.build(Participation.MODULE, Event.CONCERT, Actions.ADD);

  static PARTICIPATION_CONCERT_EDIT_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.EDIT,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONCERT_EDIT_ALL: string = this.build(Participation.MODULE, Event.CONCERT, Actions.EDIT, Level.ALL);

  static PARTICIPATION_CONCERT_DELETE_ADDED_MY: string = this.build(
    Participation.MODULE,
    Event.CONCERT,
    Actions.DELETE,
    PublicationStatus.ADDED,
    Level.MY,
  );

  static PARTICIPATION_CONCERT_DELETE_ALL: string = this.build(Participation.MODULE, Event.CONCERT, Actions.DELETE, Level.ALL);

  static PARTICIPATION_CONCERT_APPROVE: string = this.build(Participation.MODULE, Event.CONCERT, Actions.APPROVE);

  static PARTICIPATION_CONCERT_BIND_TO_DEPARTMENT: string = this.build(Participation.MODULE, Event.CONCERT, 'BindToDepartment');

  static PARTICIPATION_CONCERT_DEPARTMENT_EDIT: string = this.build(Participation.MODULE, Event.CONCERT, 'DepartmentEdit');

  /* Программы и мероприятия УНП */
  static PROGRAM_VIEW: string = this.build(Program.MODULE, Actions.VIEW);

  static PROGRAM_ADD: string = this.build(Program.MODULE, Actions.ADD);

  static PROGRAM_EDIT: string = this.build(Program.MODULE, Actions.EDIT);

  static PROGRAM_DELETE: string = this.build(Program.MODULE, Actions.DELETE);

  static PROGRAM_INDICATOR_ADD: string = this.build(Program.MODULE, Program.INDICATORS, Actions.ADD);

  static PROGRAM_INDICATOR_DELETE: string = this.build(Program.MODULE, Program.INDICATORS, Actions.DELETE);

  static PROGRAM_INDEX_ADD: string = this.build(Program.MODULE, Program.INDICES, Actions.ADD);

  static PROGRAM_INDEX_DELETE: string = this.build(Program.MODULE, Program.INDICES, Actions.DELETE);

  static PROGRAM_KNOWLEDGE_BRANCHES_ADD: string = this.build(Program.MODULE, Program.KNOWLEDGE_BRANCHES, Actions.ADD);

  static PROGRAM_KNOWLEDGE_BRANCHES_DELETE: string = this.build(Program.MODULE, Program.KNOWLEDGE_BRANCHES, Actions.DELETE);

  static PROGRAM_EVENT_VIEW: string = this.build(ProgramEvent.MODULE, Actions.VIEW);

  static PROGRAM_EVENT_ADD: string = this.build(ProgramEvent.MODULE, Actions.ADD);

  static PROGRAM_EVENT_EDIT: string = this.build(ProgramEvent.MODULE, Actions.EDIT);

  static PROGRAM_EVENT_DELETE: string = this.build(ProgramEvent.MODULE, Actions.DELETE);

  /* Конкурсы */
  static TENDER_VIEW: string = this.build(Tender.MODULE, Actions.VIEW);

  static TENDER_ADD: string = this.build(Tender.MODULE, Actions.ADD);

  static TENDER_EDIT: string = this.build(Tender.MODULE, Actions.EDIT);

  static TENDER_DELETE: string = this.build(Tender.MODULE, Actions.DELETE);

  static LOT_VIEW: string = this.build(Lot.MODULE, Actions.VIEW);

  static LOT_ADD: string = this.build(Lot.MODULE, Actions.ADD);

  static LOT_EDIT: string = this.build(Lot.MODULE, Actions.EDIT);

  static LOT_DELETE: string = this.build(Lot.MODULE, Actions.DELETE);

  /* Заявки */

  static REQUEST_ACCESS: string = this.build('Request', Actions.ACCESS);

  static PROGRAM_REQUEST_VIEW_ALL: string = this.build(ProgramRequest.MODULE, Actions.VIEW, 'All');

  static PROGRAM_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(ProgramRequest.MODULE, Actions.VIEW, 'ByManagement');

  static PROGRAM_REQUEST_ADD: string = this.build(ProgramRequest.MODULE, Actions.ADD);

  static PROGRAM_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.DRAFT,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REQUEST,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_RECOMENDED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.RECOMENDED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_SENT_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_SENT_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_SENT_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.SENT,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REVISION,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.REJECTED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_APPROVED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.APPROVED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.REQUEST,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_PERFORMERS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.PERFORMERS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_CLASSIFICATORS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.CLASSIFICATORS,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_ESTIMATE: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.ESTIMATE,
  );

  static PROGRAM_REQUEST_EDIT_TENDER_CANCELED_DOCUMENTS: string = this.build(
    ProgramRequest.MODULE,
    Actions.EDIT,
    RequestStatus.TENDER_CANCELED,
    ProgramRequestTab.DOCUMENTS,
  );

  static PROGRAM_REQUEST_DELETE_DRAFT: string = this.build(ProgramRequest.MODULE, Actions.DELETE, RequestStatus.DRAFT);

  static PROGRAM_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.DRAFT,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REQUEST,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.RECOMENDED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_SENT: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.SENT,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REVISION,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.REJECTED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_APPROVED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.APPROVED,
  );

  static PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED: string = this.build(
    ProgramRequest.MODULE,
    Actions.CHANGE_STATUS,
    RequestStatus.TENDER_CANCELED,
  );

  static PROGRAM_REQUEST_AGREE: string = this.build(ProgramRequest.MODULE, Actions.AGREE);

  /* Блокировки проектов */
  static PROJECT_LOCK_VIEW_LIST: string = this.build(ProjectLock.MODULE, Actions.VIEW, Midfixes.LIST);

  static PROJECT_LOCK_ADD: string = this.build(ProjectLock.MODULE, Actions.ADD);

  static PROJECT_LOCK_DELETE: string = this.build(ProjectLock.MODULE, Actions.DELETE);

  /* Госрегистрация проектов */
  static PROJECT_STATE_REGISTRATION_VIEW_FULL_LIST: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    Midfixes.FULL,
    Midfixes.LIST,
  );

  static PROJECT_STATE_REGISTRATION_VIEW_BY_DEPARTMENT: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    'ByDepartment',
  );

  static PROJECT_STATE_REGISTRATION_VIEW_BY_MANAGEMENT: string = this.build(
    ProjectStateRegistration.MODULE,
    Actions.VIEW,
    'ByManagement',
  );

  /* Патентные исследования */
  static PATENT_RESEARCH_VIEW: string = PatentResearch.MODULE + Actions.VIEW;

  static PATENT_RESEARCH_ADD: string = PatentResearch.MODULE + Actions.ADD;

  static PATENT_RESEARCH_EDIT: string = PatentResearch.MODULE + Actions.EDIT;

  static PATENT_RESEARCH_DELETE: string = PatentResearch.MODULE + Actions.DELETE;

  /* Охранные документы */
  static PATENT_VIEW: string = SecurityDocument.MODULE_PATENT + Actions.VIEW;

  static PATENT_ADD: string = SecurityDocument.MODULE_PATENT + Actions.ADD;

  static PATENT_EDIT: string = SecurityDocument.MODULE_PATENT + Actions.EDIT;

  static PATENT_DELETE: string = SecurityDocument.MODULE_PATENT + Actions.DELETE;

  static EVIDENCE_VIEW: string = SecurityDocument.MODULE_EVIDENCE + Actions.VIEW;

  static EVIDENCE_ADD: string = SecurityDocument.MODULE_EVIDENCE + Actions.ADD;

  static EVIDENCE_EDIT: string = SecurityDocument.MODULE_EVIDENCE + Actions.EDIT;

  static EVIDENCE_DELETE: string = SecurityDocument.MODULE_EVIDENCE + Actions.DELETE;

  static KNOW_HOW_VIEW: string = SecurityDocument.MODULE_KNOW_HOW + Actions.VIEW;

  static KNOW_HOW_ADD: string = SecurityDocument.MODULE_KNOW_HOW + Actions.ADD;

  static KNOW_HOW_EDIT: string = SecurityDocument.MODULE_KNOW_HOW + Actions.EDIT;

  static KNOW_HOW_DELETE: string = SecurityDocument.MODULE_KNOW_HOW + Actions.DELETE;

  static NON_REGISTERED_VIEW: string = SecurityDocument.NON_REGISTERED + SecurityDocument.SECURITY_DOCUMENTS + Actions.VIEW;

  static NON_REGISTERED_ADD: string = SecurityDocument.NON_REGISTERED + SecurityDocument.SECURITY_DOCUMENTS + Actions.ADD;

  static NON_REGISTERED_EDIT: string = SecurityDocument.NON_REGISTERED + SecurityDocument.SECURITY_DOCUMENTS + Actions.EDIT;

  static NON_REGISTERED_DELETE: string = SecurityDocument.NON_REGISTERED + SecurityDocument.SECURITY_DOCUMENTS + Actions.DELETE;

  /* ОИС не аффилированные с Университетом */
  static FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST: string = this.build(
    SecurityDocument.FOREIGN_SECURITY_DOCUMENT,
    Actions.VIEW,
    'FullList',
  );

  static FOREIGN_SECURITY_DOCUMENT_APPROVE: string = this.build(SecurityDocument.FOREIGN_SECURITY_DOCUMENT, Actions.APPROVE);

  /* Договоры на использование ИС */
  static CONTRACT_VIEW: string = SecurityDocumentContract.MODULE + Actions.VIEW;

  static CONTRACT_ADD: string = SecurityDocumentContract.MODULE + Actions.ADD;

  static CONTRACT_EDIT: string = SecurityDocumentContract.MODULE + Actions.EDIT;

  static CONTRACT_DELETE: string = SecurityDocumentContract.MODULE + Actions.DELETE;

  static CONTRACT_PAYMENT_LOG_VIEW: string = 'ContractPaymentLogView';

  /* РИД */
  static RNTD_VIEW: string = Rntd.MODULE + Actions.VIEW;

  static RNTD_ADD: string = Rntd.MODULE + Actions.ADD;

  static RNTD_EDIT: string = Rntd.MODULE + Actions.EDIT;

  static RNTD_DELETE: string = Rntd.MODULE + Actions.DELETE;

  /* Объявления */
  static NOTICE_SYSTEM_ADD: string = this.build(Notice.MODULE, MsaWorkbenches.SYSTEM, Actions.ADD);

  static NOTICE_SYSTEM_EDIT: string = this.build(Notice.MODULE, MsaWorkbenches.SYSTEM, Actions.EDIT);

  /* Полезные ссылки */
  static USEFUL_LINK_ADD: string = UsefulLink.MODULE + Actions.ADD;

  static USEFUL_LINK_LIST_EDIT: string = UsefulLink.MODULE + Midfixes.LIST + Actions.EDIT;

  /* Полезные ссылки */
  static HELPFUL_STUFF_ADD: string = HelpfulStuff.MODULE + Actions.ADD;

  static HELPFUL_STUFF_LIST_EDIT: string = HelpfulStuff.MODULE + Midfixes.LIST + Actions.EDIT;

  /*	Журналы */
  static MAGAZINE_VIEW: string = Magazine.MODULE + Actions.VIEW;

  static MAGAZINE_ADD: string = Magazine.MODULE + Actions.ADD;

  static MAGAZINE_EDIT: string = Magazine.MODULE + Actions.EDIT;

  static MAGAZINE_DELETE: string = Magazine.MODULE + Actions.DELETE;

  static MAGAZINE_INFO_VIEW: string = Magazine.MODULE + Actions.INFO_VIEW;

  static MAGAZINE_APPROVE: string = Magazine.MODULE + Actions.APPROVE;

  static MAGAZINE_LIBRARY_FIELD_EDIT: string = this.build(Magazine.MODULE, 'Library', 'Field', Actions.EDIT);

  static MAGAZINE_LIBRARY_ID_EDIT: string = `${Magazine.MODULE}LibraryId${Actions.EDIT}`;

  static MAGAZINE_ARTICLES_EDIT: string = `${Magazine.MODULE}Articles${Actions.EDIT}`;

  static MAGAZINE_RELEASE_EDIT: string = `${Magazine.MODULE}Release${Actions.EDIT}`;

  static MAGAZINE_RELEASE_APPROVE: string = `${Magazine.MODULE}Release${Actions.APPROVE}`;

  static MAGAZINE_CITATION_SYSTEM_EDIT: string = Magazine.MODULE + Magazine.CITATION_SYSTEM + Actions.EDIT;

  static MAGAZINE_CITATION_SYSTEM_INDEX_EDIT: string = Magazine.MODULE + Magazine.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static MAGAZINE_VAK_EDIT: string = Magazine.MODULE + Magazine.VAK + Actions.EDIT;

  /* Сборники */
  static COMPILATION_VIEW: string = Compilation.MODULE + Actions.VIEW;

  static COMPILATION_ADD: string = Compilation.MODULE + Actions.ADD;

  static COMPILATION_EDIT: string = Compilation.MODULE + Actions.EDIT;

  static COMPILATION_DELETE: string = Compilation.MODULE + Actions.DELETE;

  static COMPILATION_APPROVE: string = Compilation.MODULE + Actions.APPROVE;

  static COMPILATION_DEPARTMENTS_EDIT: string = `${Compilation.MODULE}Departments${Actions.EDIT}`;

  static COMPILATION_INFO_VIEW: string = Compilation.MODULE + Actions.INFO_VIEW;

  static COMPILATION_VIEW_IN_LIBRARY: string = `${Compilation.MODULE + Actions.VIEW}InLibrary`;

  static COMPILATION_EXPORT: string = Compilation.MODULE + Actions.EXPORT;

  static COMPILATION_ARTICLES_EDIT: string = `${Compilation.MODULE}Articles${Actions.EDIT}`;

  static COMPILATION_CITATION_SYSTEM_EDIT: string = Compilation.MODULE + Compilation.CITATION_SYSTEM + Actions.EDIT;

  static COMPILATION_CITATION_SYSTEM_INDEX_EDIT: string = Compilation.MODULE + Compilation.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static COMPILATION_VAK_EDIT: string = Compilation.MODULE + Compilation.VAK + Actions.EDIT;

  /* Публикации */
  static PUBLICATION_VIEW: string = Publication.MODULE + Actions.VIEW;

  static PUBLICATION_ADD: string = Publication.MODULE + Actions.ADD;

  static PUBLICATION_EDIT: string = Publication.MODULE + Actions.EDIT;

  static PUBLICATION_TRANSFORM: string = Publication.MODULE + Actions.TRANSFORM;

  static PUBLICATION_DELETE_DRAFTADDED: string = Publication.MODULE + Actions.DELETE + PublicationStatus.DRAFTADDED;

  static PUBLICATION_DELETE_APPROVED: string = Publication.MODULE + Actions.DELETE + PublicationStatus.APPROVED;

  static PUBLICATION_STRICTED_EDIT: string = `${Publication.MODULE}Stricted${Actions.EDIT}`;

  static PUBLICATION_DEPARTMENTS_EDIT: string = `${Publication.MODULE}Departments${Actions.EDIT}`;

  static PUBLICATION_CONFIRM: string = `${Publication.MODULE}Confirm`;

  static PUBLICATION_APPROVE: string = Publication.MODULE + Actions.APPROVE;

  static PUBLICATION_EXPORT: string = Publication.MODULE + Actions.EXPORT;

  static PUBLICATION_ARTICLE_TRANSLATION_EDIT: string = `${Publication.MODULE}ArticleTranslation${Actions.EDIT}`;

  static PUBLICATION_DOI_EDIT: string = `${Publication.MODULE}Doi${Actions.EDIT}`;

  static PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT: string = `${Publication.MODULE}ScienceAndMobileProject${Actions.EDIT}`;

  static PUBLICATION_SCIENCE_PROJECT_ACCEPTING: string = `${Publication.MODULE}ScienceProjectAccepting`;

  static PUBLICATION_CITATION_SYSTEM_EDIT: string = Publication.MODULE + Publication.CITATION_SYSTEM + Actions.EDIT;

  static PUBLICATION_CITATION_SYSTEM_INDEX_EDIT: string = Publication.MODULE + Publication.CITATION_SYSTEM_INDEX + Actions.EDIT;

  static PUBLICATION_INFO_VIEW: string = Publication.MODULE + Midfixes.INFO + Actions.VIEW;

  /* Список публикаций в каталоге Научной библиотеки" */
  static PUBLICATION_LIBRARY_VIEW_LIST: string = this.build(Publication.MODULE, Publication.LIBRARY, Actions.VIEW, 'List');

  static PUBLICATION_LIBRARY_FIELD_EDIT: string = this.build(Publication.MODULE, Publication.LIBRARY, 'Field', Actions.EDIT);

  /* Журнал публикаций, отправленных в библиотеку */
  static PUBLICATION_LIBRARY_LOG_VIEW_LIST: string = this.build(
    Publication.MODULE,
    Publication.LIBRARY,
    Publication.LOG,
    Actions.VIEW,
    'List',
  );

  static VIEW_PUBLICATION_IN_LIBRARY: string = `${Actions.VIEW + Publication.MODULE}InLibrary`;

  static PUBLICATION_LIBRARY_LOG_PROCESS: string = this.build(
    Publication.MODULE,
    Publication.LIBRARY,
    Publication.LOG,
    Actions.PROCESS,
  );

  /* МОБИЛЬНОСТЬ */
  static MOBILE_REQUEST_VIEW: string = this.build(MobileRequest.MODULE, Actions.VIEW);

  static MOBILE_REQUEST_ADD: string = this.build(MobileRequest.MODULE, Actions.ADD);

  static MOBILE_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REVISION_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REVISION,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REJECTED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.REQUEST,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_APPROVED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.APPROVED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_SENT_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_SENT_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_SENT_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_SENT_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.SENT,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_RECOMENDED_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.RECOMENDED,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.REQUEST,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_APPLICANT: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.APPLICANT,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.ESTIMATE,
  );

  static MOBILE_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    MobileRequest.MODULE,
    Actions.EDIT,
    MobileRequestStatus.DRAFT,
    MobileRequestTab.DOCUMENTS,
  );

  static MOBILE_REQUEST_DELETE_DRAFT: string = this.build(MobileRequest.MODULE, Actions.DELETE, MobileRequestStatus.DRAFT);

  static MOBILE_REQUEST_AGREE: string = this.build(MobileRequest.MODULE, Actions.AGREE);

  static MOBILE_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.DRAFT,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REQUEST,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.RECOMENDED,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_SENT: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.SENT,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REVISION,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.REJECTED,
  );

  static MOBILE_REQUEST_CHANGE_STATUS_APPROVED: string = this.build(
    MobileRequest.MODULE,
    Actions.CHANGE_STATUS,
    MobileRequestStatus.APPROVED,
  );

  /* TenderProtocol Start */
  static TENDER_PROTOCOL_ADD: string = this.build(TenderProtocol.MODULE, Actions.ADD);

  /* Global view */
  static TENDER_PROTOCOL_VIEW_FULL_LIST: string = this.build(TenderProtocol.MODULE, Actions.VIEW, 'Full', 'List');

  static TENDER_PROTOCOL_VIEW_BY_COMISSION_MEMBERSHIP: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    'ByComissionMembership',
    'List',
  );

  /* Tabs view */
  static TENDER_PROTOCOL_VIEW_PROTOCOL: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.PROTOCOL);

  static TENDER_PROTOCOL_VIEW_PROTOCOL_REQUESTS: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    TenderProtocolTabs.PROTOCOL_REQUESTS,
  );

  static TENDER_PROTOCOL_VIEW_APPROVER_REQUESTS: string = this.build(
    TenderProtocol.MODULE,
    Actions.VIEW,
    TenderProtocolTabs.APPROVER_REQUESTS,
  );

  static TENDER_PROTOCOL_VIEW_TENDER: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.TENDER);

  static TENDER_PROTOCOL_VIEW_DOCUMENTS: string = this.build(TenderProtocol.MODULE, Actions.VIEW, TenderProtocolTabs.DOCUMENTS);

  /* Tabs edit */
  static TENDER_PROTOCOL_EDIT_PROTOCOL: string = this.build(TenderProtocol.MODULE, Actions.EDIT, TenderProtocolTabs.PROTOCOL);

  static TENDER_PROTOCOL_EDIT_DOCUMENTS: string = this.build(TenderProtocol.MODULE, Actions.EDIT, TenderProtocolTabs.DOCUMENTS);
  /* TenderProtocol End */

  static PROTOCOL_COMMISSION_VIEW: string = this.build(ProtocolCommission.MODULE, Actions.VIEW);

  static PROTOCOL_COMMISSION_ADD: string = this.build(ProtocolCommission.MODULE, Actions.ADD);

  static PROTOCOL_COMMISSION_EDIT: string = this.build(ProtocolCommission.MODULE, Actions.EDIT);

  static PROTOCOL_COMMISSION_DELETE: string = this.build(ProtocolCommission.MODULE, Actions.DELETE);

  static MOBILE_EXPENSE_NORMATIVE_VIEW: string = this.build(MobileExpenseNormative.MODULE, Actions.VIEW);

  static MOBILE_EXPENSE_NORMATIVE_ADD: string = this.build(MobileExpenseNormative.MODULE, Actions.ADD);

  static MOBILE_EXPENSE_NORMATIVE_EDIT: string = this.build(MobileExpenseNormative.MODULE, Actions.EDIT);

  static MOBILE_EXPENSE_NORMATIVE_DELETE: string = this.build(MobileExpenseNormative.MODULE, Actions.DELETE);

  static MOBILE_KIND_CONSUMPTION_VIEW: string = this.build(MobileKindConsumption.MODULE, Actions.VIEW);

  static MOBILE_KIND_CONSUMPTION_ADD: string = this.build(MobileKindConsumption.MODULE, Actions.ADD);

  static MOBILE_KIND_CONSUMPTION_EDIT: string = this.build(MobileKindConsumption.MODULE, Actions.EDIT);

  static MOBILE_KIND_CONSUMPTION_DELETE: string = this.build(MobileKindConsumption.MODULE, Actions.DELETE);

  /* Проекты */

  static PROJECT_RESTRICTIONS_ACCESS: string = this.build(Project.MODULE, Project.RESTRICTIONS, Actions.ACCESS);

  //----------------------------------------------

  static GOV_CONTRACT_VIEW_FULL_LIST: string = GovContract.MODULE + Actions.VIEW + Midfixes.FULL + Midfixes.LIST;

  static GOV_CONTRACT_VIEW_BY_DEPARTMENT: string = GovContract.MODULE + Actions.VIEW + 'ByDepartment';

  static GOV_CONTRACT_VIEW_BY_MANAGEMENT: string = GovContract.MODULE + Actions.VIEW + 'ByManagement';

  static GOV_CONTRACT_EXECUTORS_APPROVED_EDIT: string =
    GovContract.MODULE + GovContract.EXECUTORS + Status.APPROVED + Actions.EDIT;

  static GOV_CONTRACT_VIEW: string = GovContract.MODULE + Actions.VIEW;

  static GOV_CONTRACT_ADD: string = GovContract.MODULE + Actions.ADD;

  static GOV_CONTRACT_EDIT: string = GovContract.MODULE + Actions.EDIT;

  static GOV_CONTRACT_DELETE: string = GovContract.MODULE + Actions.DELETE;

  static GOV_CONTRACT_PROJECT_STATE_REGISTRATION_VIEW: string =
    GovContract.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_STATE_REGISTRATION_EDIT: string =
    GovContract.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_FILES_VIEW: string = GovContract.MODULE + Project.MODULE + Project.FILES + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_FILES_EDIT: string = GovContract.MODULE + Project.MODULE + Project.FILES + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_FILES_EDIT_ALL: string =
    GovContract.MODULE + Project.MODULE + Project.FILES + Actions.EDIT + Level.ALL;

  static GOV_CONTRACT_EXECUTORS_VIEW: string = GovContract.MODULE + Project.EXECUTORS + Actions.VIEW;

  static GOV_CONTRACT_EXECUTORS_EDIT: string = GovContract.MODULE + Project.EXECUTORS + Actions.EDIT;

  static GOV_CONTRACT_PARTNERS_ACCOMPLICES_VIEW: string =
    GovContract.MODULE + Project.PARTNERS + Project.ACCOMPLICES + Actions.VIEW;

  static GOV_CONTRACT_PARTNERS_PARTNERS_VIEW: string = GovContract.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.VIEW;

  static GOV_CONTRACT_PARTNERS_PARTNERS_EDIT: string = GovContract.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.EDIT;

  static GOV_CONTRACT_PARTNERS_INT_COOP_VIEW: string = GovContract.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.VIEW;

  static GOV_CONTRACT_PARTNERS_INT_COOP_EDIT: string = GovContract.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.EDIT;

  static GOV_CONTRACT_CALENDAR_PLAN_VIEW: string = GovContract.MODULE + Project.CALENDAR_PLAN + Actions.VIEW;

  static GOV_CONTRACT_CALENDAR_PLAN_EDIT: string = GovContract.MODULE + Project.CALENDAR_PLAN + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_CLASSIFICATORS_VIEW: string =
    GovContract.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_CLASSIFICATORS_EDIT: string =
    GovContract.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.EDIT;

  static GOV_CONTRACT_ABOUT_PROJECT_VIEW: string = GovContract.MODULE + Project.ABOUT_PROJECT + Actions.VIEW;

  static GOV_CONTRACT_ABOUT_PROJECT_EDIT: string = GovContract.MODULE + Project.ABOUT_PROJECT + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_DESCRIPTION_VIEW: string = GovContract.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_DESCRIPTION_EDIT: string = GovContract.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_FEATURES_VIEW: string = GovContract.MODULE + Project.MODULE + Project.FEATURES + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_FEATURES_EDIT: string = GovContract.MODULE + Project.MODULE + Project.FEATURES + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_RESULTS_VIEW: string = GovContract.MODULE + Project.MODULE + Project.RESULTS + Actions.VIEW;

  static GOV_CONTRACT_PROJECT_RESULTS_EDIT: string = GovContract.MODULE + Project.MODULE + Project.RESULTS + Actions.EDIT;

  static GOV_CONTRACT_PROJECT_PURCHASE_REQUEST_VIEW: string =
    GovContract.MODULE + Project.MODULE + Project.PURCHASE_REQUEST + Actions.VIEW;

  static GOV_CONTRACT_RESTRICTIONS_ACCESS: string = this.build(GovContract.MODULE, Project.RESTRICTIONS, Actions.ACCESS);

  static GOV_CONTRACT_RESTRICTIONS_VIEW: string = GovContract.MODULE + Project.RESTRICTIONS + Actions.VIEW;

  static GOV_CONTRACT_RESTRICTIONS_EDIT: string = GovContract.MODULE + Project.RESTRICTIONS + Actions.EDIT;

  static GOV_CONTRACT_ASSIGNMENTS_VIEW: string = GovContract.MODULE + Project.ASSIGNMENTS + Actions.VIEW;

  static GOV_CONTRACT_ASSIGNMENTS_EDIT: string = GovContract.MODULE + Project.ASSIGNMENTS + Actions.EDIT;

  static GOV_CONTRACT_LOCKED_EDIT: string = GovContract.MODULE + Status.LOCKED + Actions.EDIT;

  //----------------------------------------------

  static ECONOMIC_CONTRACT_VIEW_FULL_LIST: string = EconomicContract.MODULE + Actions.VIEW + Midfixes.FULL + Midfixes.LIST;

  static ECONOMIC_CONTRACT_VIEW_BY_DEPARTMENT: string = EconomicContract.MODULE + Actions.VIEW + 'ByDepartment';

  static ECONOMIC_CONTRACT_VIEW_BY_MANAGEMENT: string = EconomicContract.MODULE + Actions.VIEW + 'ByManagement';

  static ECONOMIC_CONTRACT_EXECUTORS_APPROVED_EDIT: string =
    EconomicContract.MODULE + EconomicContract.EXECUTORS + Status.APPROVED + Actions.EDIT;

  static ECONOMIC_CONTRACT_VIEW: string = EconomicContract.MODULE + Actions.VIEW;

  static ECONOMIC_CONTRACT_ADD: string = EconomicContract.MODULE + Actions.ADD;

  static ECONOMIC_CONTRACT_EDIT: string = EconomicContract.MODULE + Actions.EDIT;

  static ECONOMIC_CONTRACT_DELETE: string = EconomicContract.MODULE + Actions.DELETE;

  static ECONOMIC_CONTRACT_PROJECT_STATE_REGISTRATION_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_STATE_REGISTRATION_EDIT: string =
    EconomicContract.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_FILES_VIEW: string = EconomicContract.MODULE + Project.MODULE + Project.FILES + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_FILES_EDIT: string = EconomicContract.MODULE + Project.MODULE + Project.FILES + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_FILES_EDIT_ALL: string =
    EconomicContract.MODULE + Project.MODULE + Project.FILES + Actions.EDIT + Level.ALL;

  static ECONOMIC_CONTRACT_EXECUTORS_VIEW: string = EconomicContract.MODULE + Project.EXECUTORS + Actions.VIEW;

  static ECONOMIC_CONTRACT_EXECUTORS_EDIT: string = EconomicContract.MODULE + Project.EXECUTORS + Actions.EDIT;

  static ECONOMIC_CONTRACT_PARTNERS_ACCOMPLICES_VIEW: string =
    EconomicContract.MODULE + Project.PARTNERS + Project.ACCOMPLICES + Actions.VIEW;

  static ECONOMIC_CONTRACT_PARTNERS_PARTNERS_VIEW: string =
    EconomicContract.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.VIEW;

  static ECONOMIC_CONTRACT_PARTNERS_PARTNERS_EDIT: string =
    EconomicContract.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.EDIT;

  static ECONOMIC_CONTRACT_PARTNERS_INT_COOP_VIEW: string =
    EconomicContract.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.VIEW;

  static ECONOMIC_CONTRACT_PARTNERS_INT_COOP_EDIT: string =
    EconomicContract.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.EDIT;

  static ECONOMIC_CONTRACT_CALENDAR_PLAN_VIEW: string = EconomicContract.MODULE + Project.CALENDAR_PLAN + Actions.VIEW;

  static ECONOMIC_CONTRACT_CALENDAR_PLAN_EDIT: string = EconomicContract.MODULE + Project.CALENDAR_PLAN + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_CLASSIFICATORS_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_CLASSIFICATORS_EDIT: string =
    EconomicContract.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.EDIT;

  static ECONOMIC_CONTRACT_ABOUT_PROJECT_VIEW: string = EconomicContract.MODULE + Project.ABOUT_PROJECT + Actions.VIEW;

  static ECONOMIC_CONTRACT_ABOUT_PROJECT_EDIT: string = EconomicContract.MODULE + Project.ABOUT_PROJECT + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_DESCRIPTION_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_DESCRIPTION_EDIT: string =
    EconomicContract.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_FEATURES_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.FEATURES + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_FEATURES_EDIT: string =
    EconomicContract.MODULE + Project.MODULE + Project.FEATURES + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_RESULTS_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.RESULTS + Actions.VIEW;

  static ECONOMIC_CONTRACT_PROJECT_RESULTS_EDIT: string =
    EconomicContract.MODULE + Project.MODULE + Project.RESULTS + Actions.EDIT;

  static ECONOMIC_CONTRACT_PROJECT_PURCHASE_REQUEST_VIEW: string =
    EconomicContract.MODULE + Project.MODULE + Project.PURCHASE_REQUEST + Actions.VIEW;

  static ECONOMIC_CONTRACT_RESTRICTIONS_ACCESS: string = this.build(
    EconomicContract.MODULE,
    Project.RESTRICTIONS,
    Actions.ACCESS,
  );

  static ECONOMIC_CONTRACT_RESTRICTIONS_VIEW: string = EconomicContract.MODULE + Project.RESTRICTIONS + Actions.VIEW;

  static ECONOMIC_CONTRACT_RESTRICTIONS_EDIT: string = EconomicContract.MODULE + Project.RESTRICTIONS + Actions.EDIT;

  static ECONOMIC_CONTRACT_ASSIGNMENTS_VIEW: string = EconomicContract.MODULE + Project.ASSIGNMENTS + Actions.VIEW;

  static ECONOMIC_CONTRACT_ASSIGNMENTS_EDIT: string = EconomicContract.MODULE + Project.ASSIGNMENTS + Actions.EDIT;

  static ECONOMIC_CONTRACT_LOCKED_EDIT: string = EconomicContract.MODULE + Status.LOCKED + Actions.EDIT;

  //-----------------------------------------------

  static GRANT_VIEW_FULL_LIST: string = Grant.MODULE + Actions.VIEW + Midfixes.FULL + Midfixes.LIST;

  static GRANT_VIEW_BY_DEPARTMENT: string = Grant.MODULE + Actions.VIEW + 'ByDepartment';

  static GRANT_VIEW_BY_MANAGEMENT: string = Grant.MODULE + Actions.VIEW + 'ByManagement';

  static GRANT_EXECUTORS_APPROVED_EDIT: string = Grant.MODULE + Grant.EXECUTORS + Status.APPROVED + Actions.EDIT;

  static GRANT_VIEW: string = Grant.MODULE + Actions.VIEW;

  static GRANT_ADD: string = Grant.MODULE + Actions.ADD;

  static GRANT_EDIT: string = Grant.MODULE + Actions.EDIT;

  static GRANT_DELETE: string = Grant.MODULE + Actions.DELETE;

  static GRANT_PROJECT_STATE_REGISTRATION_VIEW: string =
    Grant.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.VIEW;

  static GRANT_PROJECT_STATE_REGISTRATION_EDIT: string =
    Grant.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.EDIT;

  static GRANT_PROJECT_FILES_VIEW: string = Grant.MODULE + Project.MODULE + Project.FILES + Actions.VIEW;

  static GRANT_PROJECT_FILES_EDIT: string = Grant.MODULE + Project.MODULE + Project.FILES + Actions.EDIT;

  static GRANT_PROJECT_FILES_EDIT_ALL: string = Grant.MODULE + Project.MODULE + Project.FILES + Actions.EDIT + Level.ALL;

  static GRANT_EXECUTORS_VIEW: string = Grant.MODULE + Project.EXECUTORS + Actions.VIEW;

  static GRANT_EXECUTORS_EDIT: string = Grant.MODULE + Project.EXECUTORS + Actions.EDIT;

  static GRANT_PARTNERS_ACCOMPLICES_VIEW: string = Grant.MODULE + Project.PARTNERS + Project.ACCOMPLICES + Actions.VIEW;

  static GRANT_PARTNERS_PARTNERS_VIEW: string = Grant.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.VIEW;

  static GRANT_PARTNERS_PARTNERS_EDIT: string = Grant.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.EDIT;

  static GRANT_PARTNERS_INT_COOP_VIEW: string = Grant.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.VIEW;

  static GRANT_PARTNERS_INT_COOP_EDIT: string = Grant.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.EDIT;

  static GRANT_CALENDAR_PLAN_VIEW: string = Grant.MODULE + Project.CALENDAR_PLAN + Actions.VIEW;

  static GRANT_CALENDAR_PLAN_EDIT: string = Grant.MODULE + Project.CALENDAR_PLAN + Actions.EDIT;

  static GRANT_PROJECT_CLASSIFICATORS_VIEW: string = Grant.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.VIEW;

  static GRANT_PROJECT_CLASSIFICATORS_EDIT: string = Grant.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.EDIT;

  static GRANT_ABOUT_PROJECT_VIEW: string = Grant.MODULE + Project.ABOUT_PROJECT + Actions.VIEW;

  static GRANT_ABOUT_PROJECT_EDIT: string = Grant.MODULE + Project.ABOUT_PROJECT + Actions.EDIT;

  static GRANT_PROJECT_DESCRIPTION_VIEW: string = Grant.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.VIEW;

  static GRANT_PROJECT_DESCRIPTION_EDIT: string = Grant.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.EDIT;

  static GRANT_PROJECT_FEATURES_VIEW: string = Grant.MODULE + Project.MODULE + Project.FEATURES + Actions.VIEW;

  static GRANT_PROJECT_FEATURES_EDIT: string = Grant.MODULE + Project.MODULE + Project.FEATURES + Actions.EDIT;

  static GRANT_PROJECT_RESULTS_VIEW: string = Grant.MODULE + Project.MODULE + Project.RESULTS + Actions.VIEW;

  static GRANT_PROJECT_RESULTS_EDIT: string = Grant.MODULE + Project.MODULE + Project.RESULTS + Actions.EDIT;

  static GRANT_PROJECT_PURCHASE_REQUEST_VIEW: string = Grant.MODULE + Project.MODULE + Project.PURCHASE_REQUEST + Actions.VIEW;

  static GRANT_RESTRICTIONS_ACCESS: string = this.build(Grant.MODULE, Project.RESTRICTIONS, Actions.ACCESS);

  static GRANT_RESTRICTIONS_VIEW: string = Grant.MODULE + Project.RESTRICTIONS + Actions.VIEW;

  static GRANT_RESTRICTIONS_EDIT: string = Grant.MODULE + Project.RESTRICTIONS + Actions.EDIT;

  static GRANT_ASSIGNMENTS_VIEW: string = Grant.MODULE + Project.ASSIGNMENTS + Actions.VIEW;

  static GRANT_ASSIGNMENTS_EDIT: string = Grant.MODULE + Project.ASSIGNMENTS + Actions.EDIT;

  static GRANT_LOCKED_EDIT: string = Grant.MODULE + Status.LOCKED + Actions.EDIT;

  //--------------------------------------------------

  static RESEARCH_JOB_VIEW_FULL_LIST: string = ResearchJob.MODULE + Actions.VIEW + Midfixes.FULL + Midfixes.LIST;

  static RESEARCH_JOB_VIEW_BY_DEPARTMENT: string = ResearchJob.MODULE + Actions.VIEW + 'ByDepartment';

  static RESEARCH_JOB_VIEW_BY_MANAGEMENT: string = ResearchJob.MODULE + Actions.VIEW + 'ByManagement';

  static RESEARCH_JOB_EXECUTORS_APPROVED_EDIT: string =
    ResearchJob.MODULE + ResearchJob.EXECUTORS + Status.APPROVED + Actions.EDIT;

  static RESEARCH_JOB_VIEW: string = ResearchJob.MODULE + Actions.VIEW;

  static RESEARCH_JOB_ADD: string = ResearchJob.MODULE + Actions.ADD;

  static RESEARCH_JOB_EDIT: string = ResearchJob.MODULE + Actions.EDIT;

  static RESEARCH_JOB_DELETE: string = ResearchJob.MODULE + Actions.DELETE;

  static RESEARCH_JOB_PROJECT_STATE_REGISTRATION_VIEW: string =
    ResearchJob.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_STATE_REGISTRATION_EDIT: string =
    ResearchJob.MODULE + Project.MODULE + Project.STATE_REGISTRATION + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_FILES_VIEW: string = ResearchJob.MODULE + Project.MODULE + Project.FILES + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_FILES_EDIT: string = ResearchJob.MODULE + Project.MODULE + Project.FILES + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_FILES_EDIT_ALL: string =
    ResearchJob.MODULE + Project.MODULE + Project.FILES + Actions.EDIT + Level.ALL;

  static RESEARCH_JOB_EXECUTORS_VIEW: string = ResearchJob.MODULE + Project.EXECUTORS + Actions.VIEW;

  static RESEARCH_JOB_EXECUTORS_EDIT: string = ResearchJob.MODULE + Project.EXECUTORS + Actions.EDIT;

  static RESEARCH_JOB_PARTNERS_ACCOMPLICES_VIEW: string =
    ResearchJob.MODULE + Project.PARTNERS + Project.ACCOMPLICES + Actions.VIEW;

  static RESEARCH_JOB_PARTNERS_PARTNERS_VIEW: string = ResearchJob.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.VIEW;

  static RESEARCH_JOB_PARTNERS_PARTNERS_EDIT: string = ResearchJob.MODULE + Project.PARTNERS + Project.PARTNERS + Actions.EDIT;

  static RESEARCH_JOB_PARTNERS_INT_COOP_VIEW: string = ResearchJob.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.VIEW;

  static RESEARCH_JOB_PARTNERS_INT_COOP_EDIT: string = ResearchJob.MODULE + Project.PARTNERS + Project.INT_COOP + Actions.EDIT;

  static RESEARCH_JOB_CALENDAR_PLAN_VIEW: string = ResearchJob.MODULE + Project.CALENDAR_PLAN + Actions.VIEW;

  static RESEARCH_JOB_CALENDAR_PLAN_EDIT: string = ResearchJob.MODULE + Project.CALENDAR_PLAN + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_CLASSIFICATORS_VIEW: string =
    ResearchJob.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_CLASSIFICATORS_EDIT: string =
    ResearchJob.MODULE + Project.MODULE + Project.CLASSIFICATORS + Actions.EDIT;

  static RESEARCH_JOB_ABOUT_PROJECT_VIEW: string = ResearchJob.MODULE + Project.ABOUT_PROJECT + Actions.VIEW;

  static RESEARCH_JOB_ABOUT_PROJECT_EDIT: string = ResearchJob.MODULE + Project.ABOUT_PROJECT + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_DESCRIPTION_VIEW: string = ResearchJob.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_DESCRIPTION_EDIT: string = ResearchJob.MODULE + Project.MODULE + Project.DESCRIPTION + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_FEATURES_VIEW: string = ResearchJob.MODULE + Project.MODULE + Project.FEATURES + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_FEATURES_EDIT: string = ResearchJob.MODULE + Project.MODULE + Project.FEATURES + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_RESULTS_VIEW: string = ResearchJob.MODULE + Project.MODULE + Project.RESULTS + Actions.VIEW;

  static RESEARCH_JOB_PROJECT_RESULTS_EDIT: string = ResearchJob.MODULE + Project.MODULE + Project.RESULTS + Actions.EDIT;

  static RESEARCH_JOB_PROJECT_PURCHASE_REQUEST_VIEW: string =
    ResearchJob.MODULE + Project.MODULE + Project.PURCHASE_REQUEST + Actions.VIEW;

  static RESEARCH_JOB_RESTRICTIONS_ACCESS: string = this.build(ResearchJob.MODULE, Project.RESTRICTIONS, Actions.ACCESS);

  static RESEARCH_JOB_RESTRICTIONS_VIEW: string = ResearchJob.MODULE + Project.RESTRICTIONS + Actions.VIEW;

  static RESEARCH_JOB_RESTRICTIONS_EDIT: string = ResearchJob.MODULE + Project.RESTRICTIONS + Actions.EDIT;

  static RESEARCH_JOB_ASSIGNMENTS_VIEW: string = ResearchJob.MODULE + Project.ASSIGNMENTS + Actions.VIEW;

  static RESEARCH_JOB_ASSIGNMENTS_EDIT: string = ResearchJob.MODULE + Project.ASSIGNMENTS + Actions.EDIT;

  static RESEARCH_JOB_LOCKED_EDIT: string = ResearchJob.MODULE + Status.LOCKED + Actions.EDIT;

  //----------------------------------------------

  static FOREIGN_PROJECT_VIEW_FULL_LIST: string = 'ForeignProject:View:FullList';

  static FOREIGN_PROJECT_APPROVE: string = 'ForeignProject:Approve';

  /* Финансирование */
  /* Шифры */
  static PROJECT_CODE_VIEW: string = ProjectCode.MODULE + Actions.VIEW;

  static PROJECT_CODE_ADD: string = ProjectCode.MODULE + Actions.ADD;

  static PROJECT_CODE_EDIT: string = ProjectCode.MODULE + Actions.EDIT;

  static PROJECT_CODE_DELETE: string = ProjectCode.MODULE + Actions.DELETE;

  static PROJECT_CODE_VIEW_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}Department`;

  static PROJECT_CODE_BY_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByDepartment`;

  static PROJECT_CODE_VIEW_CURATED_DEPARTMENT: string = `${ProjectCode.MODULE + Actions.VIEW}CuratedDepartment`;

  static PROJECT_CODE_VIEW_FULL_LIST: string = `${ProjectCode.MODULE + Actions.VIEW}FullList`;

  static PROJECT_CODE_VIEW_PROJECT_MANAGEMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByProjectManagement`;

  static PROJECT_CODE_VIEW_CODE_MANAGEMENT: string = `${ProjectCode.MODULE + Actions.VIEW}ByCodeManagement`;

  static PROJECT_CODE_FINANCING: string = `${ProjectCode.MODULE}Financing`;

  static PROJECT_CODE_PROCESSING_EDIT = 'ProjectCodeProcessingEdit';

  /* Сметы */
  static ESTIMATE_BY_DEPARTMENT: string = `${Estimate.MODULE + Actions.VIEW}ByDepartment`;

  static ESTIMATE_VIEW_CURATED_DEPARTMENT: string = `${Estimate.MODULE + Actions.VIEW}CuratedDepartment`;

  static ESTIMATE_VIEW_FULL_LIST: string = `${Estimate.MODULE + Actions.VIEW}FullList`;

  static ESTIMATE_VIEW_PROJECT_MANAGEMENT: string = `${Estimate.MODULE + Actions.VIEW}ByProjectManagement`;

  static ESTIMATE_VIEW_CODE_MANAGEMENT: string = `${Estimate.MODULE + Actions.VIEW}ByCodeManagement`;

  static ESTIMATE_VIEW: string = Estimate.MODULE + Actions.VIEW;

  static ESTIMATE_ADD: string = Estimate.MODULE + Actions.ADD;

  static ESTIMATE_EDIT: string = Estimate.MODULE + Actions.EDIT;

  static ESTIMATE_DELETE: string = Estimate.MODULE + Actions.DELETE;

  static ESTIMATE_CHANGE_STATUS: string = `${Estimate.MODULE}ChangeStatus`;

  static ESTIMATE_EXECUTION_VIEW: string = `${Estimate.MODULE}ExecutionView`;

  static EXPENSE_DOCUMENTS_ACCESS: string = this.build('ExpenseDocuments', Actions.ACCESS);

  static CASH_DOCUMENT_EXPENSE_VIEW = this.build(CashDocument.MODULE, CashDocument.EXPENSE, Actions.VIEW);

  static CASH_DOCUMENT_EXPENSE_ADD = this.build(CashDocument.MODULE, CashDocument.EXPENSE, Actions.ADD);

  static CASH_DOCUMENT_EXPENSE_EDIT = this.build(CashDocument.MODULE, CashDocument.EXPENSE, Actions.EDIT);

  static CASH_DOCUMENT_EXPENSE_DELETE = this.build(CashDocument.MODULE, CashDocument.EXPENSE, Actions.DELETE);

  /* Поступления */
  static NIR_FINANCING_BY_DEPARTMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByDepartment`;

  static NIR_FINANCING_VIEW_CURATED_DEPARTMENT: string = `${NirFinancing.MODULE + Actions.VIEW}CuratedDepartment`;

  static NIR_FINANCING_VIEW_FULL_LIST: string = `${NirFinancing.MODULE + Actions.VIEW}FullList`;

  static NIR_FINANCING_VIEW_PROJECT_MANAGEMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByProjectManagement`;

  static NIR_FINANCING_VIEW_CODE_MANAGEMENT: string = `${NirFinancing.MODULE + Actions.VIEW}ByCodeManagement`;

  static RECEIVING_DOCUMENTS_ACCESS: string = this.build('ReceivingDocuments', Actions.ACCESS);

  static CASH_DOCUMENT_RECEIVING_VIEW = this.build(CashDocument.MODULE, CashDocument.RECEIVING, Actions.VIEW);

  static CASH_DOCUMENT_RECEIVING_ADD = this.build(CashDocument.MODULE, CashDocument.RECEIVING, Actions.ADD);

  static CASH_DOCUMENT_RECEIVING_EDIT = this.build(CashDocument.MODULE, CashDocument.RECEIVING, Actions.EDIT);

  static CASH_DOCUMENT_RECEIVING_DELETE = this.build(CashDocument.MODULE, CashDocument.RECEIVING, Actions.DELETE);

  /* Карточки ЦФ (централизованный фонд) */
  static FUND_CARD_VIEW_LIST: string = FundCard.MODULE + Actions.VIEW + Midfixes.LIST;

  static FUND_CARD_ADD: string = FundCard.MODULE + Actions.ADD;

  static FUND_CARD_EDIT: string = FundCard.MODULE + Actions.EDIT;

  static FUND_CARD_DELETE: string = FundCard.MODULE + Actions.DELETE;

  static FUND_CARD_POSITIONS_VIEW: string = `${FundCard.MODULE}Positions${Actions.VIEW}`;

  /* Партнёры */
  static PARTNER_VIEW: string = this.build(Partner.MODULE, Actions.VIEW);

  static PARTNER_ADD: string = this.build(Partner.MODULE, Actions.ADD);

  static PARTNER_EDIT: string = this.build(Partner.MODULE, Actions.EDIT);

  static PARTNER_DELETE: string = this.build(Partner.MODULE, Actions.DELETE);

  static PARTNER_CONTACT_ACCESS: string = this.build(Partner.MODULE, 'Contact', Actions.ACCESS);

  static PARTNER_DOCUMENT_ACCESS: string = this.build(Partner.MODULE, 'Document', Actions.ACCESS);

  static MIP_VIEW: string = Mip.MODULE + Actions.VIEW;

  static MIP_ADD: string = Mip.MODULE + Actions.ADD;

  static MIP_EDIT: string = Mip.MODULE + Actions.EDIT;

  static MIP_DELETE: string = Mip.MODULE + Actions.DELETE;

  static EXPONENT_VIEW: string = Exponent.MODULE + Actions.VIEW;

  static EXPONENT_ADD: string = Exponent.MODULE + Actions.ADD;

  static EXPONENT_EDIT: string = Exponent.MODULE + Actions.EDIT;

  static EXPONENT_DELETE: string = Exponent.MODULE + Actions.DELETE;

  /* Совместные с партнёрами мероприятия */
  static PARTNER_EVENT_VIEW: string = this.build(Partner.MODULE, Partner.EVENT, Actions.VIEW);

  static PARTNER_EVENT_ADD: string = this.build(Partner.MODULE, Partner.EVENT, Actions.ADD);

  static PARTNER_EVENT_EDIT: string = this.build(Partner.MODULE, Partner.EVENT, Actions.EDIT);

  static PARTNER_EVENT_DELETE: string = this.build(Partner.MODULE, Partner.EVENT, Actions.DELETE);

  /* Сметы мероприятий */
  static PARTNER_EVENT_ESTIMATE_VIEW_FULL_LIST: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'FullList',
  );

  static PARTNER_EVENT_ESTIMATE_VIEW_BY_DEPARTMENT: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'ByDepartment',
  );

  static PARTNER_EVENT_ESTIMATE_VIEW_BY_USER: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Actions.VIEW,
    'ByUser',
  );

  static PARTNER_EVENT_ESTIMATE_ADD: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.ADD);

  static PARTNER_EVENT_ESTIMATE_EDIT: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.EDIT);

  static PARTNER_EVENT_ESTIMATE_DELETE: string = this.build(Partner.MODULE, Partner.EVENT, Partner.ESTIMATE, Actions.DELETE);

  /* Виды расходов для смет мероприятий */
  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_VIEW: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.VIEW,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_ADD: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.ADD,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_EDIT: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.EDIT,
  );

  static PARTNER_EVENT_ESTIMATE_KIND_CONSUMPTION_DELETE: string = this.build(
    Partner.MODULE,
    Partner.EVENT,
    Partner.ESTIMATE,
    Partner.KIND_CONSUMPTION,
    Actions.DELETE,
  );

  /* Заявки на этап проекта по конкурсу */
  static CONTEST_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(ContestRequest.MODULE, Actions.VIEW, 'ByManagement');

  static CONTEST_REQUEST_VIEW_ALL: string = this.build(ContestRequest.MODULE, Actions.VIEW, Level.ALL);

  static CONTEST_REQUEST_ADD: string = this.build(ContestRequest.MODULE, Actions.ADD);

  static CONTEST_REQUEST_EDIT_BASE: string = this.build(ContestRequest.MODULE, Actions.EDIT);

  static CONTEST_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REVISION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REVISION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_ACTUALIZATION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.ACTUALIZATION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REJECTED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.REQUEST,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_SUPPORTED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.SUPPORTED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_AGREED_WITH_CONDITION_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED_WITH_CONDITION,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_AGREED_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_AGREED_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_AGREED_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.AGREED,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.REQUEST,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_INDICATORS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.INDICATORS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.PERFORMERS,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.ESTIMATE,
  );

  static CONTEST_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    ContestRequest.MODULE,
    Actions.EDIT,
    ContestRequestStatus.DRAFT,
    ContestRequestTab.DOCUMENTS,
  );

  static CONTEST_REQUEST_DELETE_DRAFT: string = this.build(ContestRequest.MODULE, Actions.DELETE, ContestRequestStatus.DRAFT);

  static CONTEST_REQUEST_AGREE: string = this.build(ContestRequest.MODULE, Actions.AGREE);

  static CONTEST_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.DRAFT,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REQUEST,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REVISION,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.REJECTED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_AGREED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.AGREED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.AGREED_WITH_CONDITION,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.SUPPORTED,
  );

  static CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION: string = this.build(
    ContestRequest.MODULE,
    'ChangeStatus',
    ContestRequestStatus.ACTUALIZATION,
  );

  static CONTEST_REQUEST_PUSH_FEEDLINE_MESSAGE: string = this.build(ContestRequest.MODULE, 'PushFeedlineMessage');

  /* Виды расходов для бухгалтерии */
  static KIND_CONSUMPTION_VIEW: string = KindConsumption.MODULE + Actions.VIEW;

  static KIND_CONSUMPTION_ADD: string = KindConsumption.MODULE + Actions.ADD;

  static KIND_CONSUMPTION_EDIT: string = KindConsumption.MODULE + Actions.EDIT;

  static KIND_CONSUMPTION_DELETE: string = KindConsumption.MODULE + Actions.DELETE;

  /* Виды расходов для заявок на этап проекта по конкурсу */
  static CONTEST_REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(ContestRequestKindConsumption.MODULE, Actions.VIEW);

  static CONTEST_REQUEST_KIND_CONSUMPTION_ADD: string = this.build(ContestRequestKindConsumption.MODULE, Actions.ADD);

  static CONTEST_REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(ContestRequestKindConsumption.MODULE, Actions.EDIT);

  static CONTEST_REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(ContestRequestKindConsumption.MODULE, Actions.DELETE);

  /* Заявки на НИР и ОКР */
  static NIR_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(NirRequest.MODULE, Actions.VIEW, 'ByManagement');

  static NIR_REQUEST_VIEW_ALL: string = this.build(NirRequest.MODULE, Actions.VIEW, Level.ALL);

  static NIR_REQUEST_ADD: string = this.build(NirRequest.MODULE, Actions.ADD);

  static NIR_REQUEST_EDIT_AGREED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_AGREED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_AGREED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_AGREED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_AGREED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_AGREED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_AGREED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_AGREED_WITH_CONDITION_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.AGREED_WITH_CONDITION,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_DRAFT_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_DRAFT_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_DRAFT_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_DRAFT_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_DRAFT_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_DRAFT_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_DRAFT_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.DRAFT,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REJECTED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REJECTED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REJECTED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REJECTED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REJECTED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REJECTED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REJECTED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REJECTED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REQUEST_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REQUEST_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REQUEST_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REQUEST_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REQUEST_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REQUEST_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REQUEST_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REQUEST,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_REVISION_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_REVISION_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_REVISION_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_REVISION_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_REVISION_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_REVISION_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_REVISION_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.REVISION,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_COLLECTIVE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.COLLECTIVE,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_DESCRIPTION: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.DESCRIPTION,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_DOCUMENTS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.DOCUMENTS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_ESTIMATE: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.ESTIMATE,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_INDICATORS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.INDICATORS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_PERFORMERS: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.PERFORMERS,
  );

  static NIR_REQUEST_EDIT_SUPPORTED_REQUEST: string = this.build(
    NirRequest.MODULE,
    Actions.EDIT,
    NirRequestStatus.SUPPORTED,
    NirRequestTab.REQUEST,
  );

  static NIR_REQUEST_DELETE_DRAFT: string = this.build(NirRequest.MODULE, Actions.DELETE, NirRequestStatus.DRAFT);

  static NIR_REQUEST_AGREE: string = this.build(NirRequest.MODULE, Actions.AGREE);

  static NIR_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.DRAFT);

  static NIR_REQUEST_CHANGE_STATUS_REQUEST: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REQUEST);

  static NIR_REQUEST_CHANGE_STATUS_REVISION: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REVISION);

  static NIR_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.REJECTED);

  static NIR_REQUEST_CHANGE_STATUS_AGREED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.AGREED);

  static NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION: string = this.build(
    NirRequest.MODULE,
    'ChangeStatus',
    NirRequestStatus.AGREED_WITH_CONDITION,
  );

  static NIR_REQUEST_CHANGE_STATUS_SUPPORTED: string = this.build(NirRequest.MODULE, 'ChangeStatus', NirRequestStatus.SUPPORTED);

  static NIR_REQUEST_PUSH_FEEDLINE_MESSAGE: string = this.build(NirRequest.MODULE, 'PushFeedlineMessage');

  /* Виды расходов для заявок НИР и ОКР */
  static NIR_REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(NirRequestKindConsumption.MODULE, Actions.VIEW);

  static NIR_REQUEST_KIND_CONSUMPTION_ADD: string = this.build(NirRequestKindConsumption.MODULE, Actions.ADD);

  static NIR_REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(NirRequestKindConsumption.MODULE, Actions.EDIT);

  static NIR_REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(NirRequestKindConsumption.MODULE, Actions.DELETE);

  static REQUEST_KIND_CONSUMPTION_VIEW: string = this.build(RequestKindConsumption.MODULE, Actions.VIEW);

  static REQUEST_KIND_CONSUMPTION_ADD: string = this.build(RequestKindConsumption.MODULE, Actions.ADD);

  static REQUEST_KIND_CONSUMPTION_EDIT: string = this.build(RequestKindConsumption.MODULE, Actions.EDIT);

  static REQUEST_KIND_CONSUMPTION_DELETE: string = this.build(RequestKindConsumption.MODULE, Actions.DELETE);

  /* Вопросы и ответы */
  static QNA_ADMINISTRATE: string = this.build(Qna.MODULE, Actions.ADMINISTRATE);

  /* Feedline Start */
  static PROGRAM_REQUEST_FEEDLINE_ACCESS: string = this.build(ProgramRequest.MODULE, Feedline.MODULE, Actions.ACCESS);

  static NIR_REQUEST_FEEDLINE_ACCESS: string = this.build(NirRequest.MODULE, Feedline.MODULE, Actions.ACCESS);

  static MOBILE_REQUEST_FEEDLINE_ACCESS: string = this.build(MobileRequest.MODULE, Feedline.MODULE, Actions.ACCESS);

  /* Feedline End */
  /* НПК */
  static COMMUNITY_INFO_VIEW: string = `${CommunityInfo.MODULE}${Actions.VIEW}`;

  static COMMUNITY_APPROVE: string = `${Community.MODULE}${Actions.APPROVE}`;

  static ADMIN: string = 'admin';

  /* Аналитика */
  static TOTAL_ANALYTICS_VIEW: string = TotalAnalytics.MODULE + Actions.VIEW;

  static INTELLECTUAL_PROPERTY_ANALYTICS_VIEW: string = IntellectualPropertyAnalytics.MODULE + Actions.VIEW;

  static GOV_CONTRACT_ANALYTICS_VIEW: string = GovContractAnalytics.MODULE + Actions.VIEW;

  static ECONOMIC_CONTRACT_ANALYTICS_VIEW: string = EconomicContractAnalytics.MODULE + Actions.VIEW;

  static RESEARCH_JOB_ANALYTICS_VIEW: string = ResearchJobAnalytics.MODULE + Actions.VIEW;

  static GRANT_ANALYTICS_VIEW: string = GrantAnalytics.MODULE + Actions.VIEW;

  /* Тематики */
  static SUBJECT_VIEW: string = Subject.MODULE + Actions.VIEW;

  static SUBJECT_ADD: string = Subject.MODULE + Actions.ADD;

  static SUBJECT_EDIT: string = Subject.MODULE + Actions.EDIT;

  static SUBJECT_DELETE: string = Subject.MODULE + Actions.DELETE;

  /* Мероприятия с партнёрами */
  static JOINT_EVENT_VIEW: string = this.build(JointEvent.MODULE, Actions.VIEW);

  static JOINT_EVENT_ADD: string = this.build(JointEvent.MODULE, Actions.ADD);

  static JOINT_EVENT_EDIT: string = this.build(JointEvent.MODULE, Actions.EDIT);

  static JOINT_EVENT_DELETE: string = this.build(JointEvent.MODULE, Actions.DELETE);

  /* Закупки */
  static PURCHASE_REQUEST_ACCESS: string = this.build(PurchaseRequest.MODULE, Actions.ACCESS);

  static PURCHASE_REQUEST_VIEW_BY_MANAGEMENT: string = this.build(PurchaseRequest.MODULE, Actions.VIEW, 'ByManagement');

  static PURCHASE_REQUEST_VIEW_ALL: string = this.build(PurchaseRequest.MODULE, Actions.VIEW, Level.ALL);

  static PURCHASE_REQUEST_ADD: string = this.build(PurchaseRequest.MODULE, Actions.ADD);

  static PURCHASE_REQUEST_EDIT_COMPLETE: string = this.build(
    PurchaseRequest.MODULE,
    Actions.EDIT,
    PurchaseRequestStatus.COMPLETE,
  );

  static PURCHASE_REQUEST_EDIT_CONSIDERATION: string = this.build(
    PurchaseRequest.MODULE,
    Actions.EDIT,
    PurchaseRequestStatus.CONSIDERATION,
  );

  static PURCHASE_REQUEST_EDIT_DRAFT: string = this.build(PurchaseRequest.MODULE, Actions.EDIT, PurchaseRequestStatus.DRAFT);

  static PURCHASE_REQUEST_EDIT_IN_ACTION_PROVIDER: string = this.build(
    PurchaseRequest.MODULE,
    Actions.EDIT,
    PurchaseRequestStatus.IN_ACTION_PROVIDER,
  );

  static PURCHASE_REQUEST_EDIT_REJECTED: string = this.build(
    PurchaseRequest.MODULE,
    Actions.EDIT,
    PurchaseRequestStatus.REJECTED,
  );

  static PURCHASE_REQUEST_EDIT_REVISION: string = this.build(
    PurchaseRequest.MODULE,
    Actions.EDIT,
    PurchaseRequestStatus.REVISION,
  );

  static PURCHASE_REQUEST_DELETE_DRAFT: string = this.build(PurchaseRequest.MODULE, Actions.DELETE, PurchaseRequestStatus.DRAFT);

  static PURCHASE_REQUEST_AGREE_MANAGEMENT_COMMITTEE: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprovementApprover.MANAGEMENT_COMMITTEE,
  );

  static PURCHASE_REQUEST_AGREE_PRICE_COMMISSION: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprovementApprover.PRICE_COMMISSION,
  );

  static PURCHASE_REQUEST_AGREE_LOGISTIC_MANAGEMENT: string = this.build(
    PurchaseRequest.MODULE,
    Actions.AGREE,
    PurchaseRequestApprovementApprover.LOGISTIC_MANAGEMENT,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_DRAFT: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.DRAFT,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.CONSIDERATION,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_REVISION: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.REVISION,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_REJECTED: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.REJECTED,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_IN_ACTION_PROVIDER: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.IN_ACTION_PROVIDER,
  );

  static PURCHASE_REQUEST_CHANGE_STATUS_COMPLETE: string = this.build(
    PurchaseRequest.MODULE,
    'ChangeStatus',
    PurchaseRequestStatus.COMPLETE,
  );

  static PURCHASE_REQUEST_PUSH_FEEDLINE_MESSAGE: string = this.build(PurchaseRequest.MODULE, 'PushFeedlineMessage');

  /* Зарплата коллективу / Зарплата исполнителям проектов */
  static SALARY_FULL_ACCESS: string = this.build(Salary.MODULE, Midfixes.FULL, Actions.ACCESS);

  static ENCODING_SALARY_ACCESS: string = this.build(Salary.ENCODING, Salary.MODULE, Actions.ACCESS);

  static PROJECT_PERFORMER_ORDER_FULL_ACCESS: string = this.build(ProjectPerformerOrder.MODULE, Midfixes.FULL, Actions.ACCESS);

  /* Поиск */
  static SEARCH_REPORT_ARCHIVE_ACCESS: string = this.build(Search.MODULE, Search.REPORTS_ARCHIVE, Actions.ACCESS);

  static SEARCH_PROJECTS_ACCESS: string = this.build(Search.MODULE, Search.PROJECTS, Actions.ACCESS);

  static SEARCH_SECURITY_DOCUMENTS_ACCESS: string = this.build(Search.MODULE, Search.SECURITY_DOCUMENTS, Actions.ACCESS);

  static SEARCH_SCIENTISTS_ACCESS: string = this.build(Search.MODULE, Search.SCIENTISTS, Actions.ACCESS);

  /* Главная страница */

  static GENERAL_OPEN_DATA_PUBLICATIONS_ACCESS: string = this.build(General.OPEN_DATA, General.PUBLICATIONS, Actions.ACCESS);

  static GENERAL_OPEN_DATA_EVENTS_ACCESS: string = this.build(General.OPEN_DATA, General.EVENTS, Actions.ACCESS);

  static GENERAL_OPEN_DATA_PROJECTS_ACCESS: string = this.build(General.OPEN_DATA, General.PROJECTS, Actions.ACCESS);

  static GENERAL_OPEN_DATA_SECURITY_DOCUMENTS_ACCESS: string = this.build(
    General.OPEN_DATA,
    General.SECURITY_DOCUMENTS,
    Actions.ACCESS,
  );

  static GENERAL_NEWS_ACCESS: string = this.build(General.NEWS, Actions.ACCESS);

  static GENERAL_TENDERS_ACCESS: string = this.build(General.TENDERS, Actions.ACCESS);

  static GENERAL_QNA_ACCESS: string = this.build(General.QNA, Actions.ACCESS);

  static GENERAL_STUFFS_ACCESS: string = this.build(General.STUFFS, Actions.ACCESS);

  static GENERAL_UPDATES_ACCESS: string = this.build(General.UPDATES, Actions.ACCESS);

  static GENERAL_QNA_ADMIN_ACCESS: string = this.build(General.QNA_ADMIN, Actions.ACCESS);

  /* Оборудование */
  static EQUIP_ACCESS: string = this.build(Equip.MODULE, Actions.ACCESS);

  static EQUIP_ADD: string = this.build(Equip.MODULE, Actions.ADD);

  static EQUIP_DELETE: string = this.build(Equip.MODULE, Actions.DELETE);

  static EQUIP_DESCRIPTION_VIEW: string = this.build(Equip.MODULE, Equip.DESCRIPTION, Actions.VIEW);

  static EQUIP_LOCATION_VIEW: string = this.build(Equip.MODULE, Equip.LOCATION, Actions.VIEW);

  static EQUIP_PURCHASE_VIEW: string = this.build(Equip.MODULE, Equip.PURCHASE, Actions.VIEW);

  static EQUIP_PROJECTS_VIEW: string = this.build(Equip.MODULE, Equip.PROJECTS, Actions.VIEW);

  static EQUIP_METROLOGIC_VIEW: string = this.build(Equip.MODULE, Equip.METROLOGIC, Actions.VIEW);

  static EQUIP_DOCUMENTS_VIEW: string = this.build(Equip.MODULE, Equip.DOCUMENTS, Actions.VIEW);

  static EQUIP_DESCRIPTION_EDIT: string = this.build(Equip.MODULE, Equip.DESCRIPTION, Actions.EDIT);

  static EQUIP_LOCATION_EDIT: string = this.build(Equip.MODULE, Equip.LOCATION, Actions.EDIT);

  static EQUIP_PURCHASE_EDIT: string = this.build(Equip.MODULE, Equip.PURCHASE, Actions.EDIT);

  static EQUIP_METROLOGIC_EDIT: string = this.build(Equip.MODULE, Equip.METROLOGIC, Actions.EDIT);

  static EQUIP_DOCUMENTS_EDIT: string = this.build(Equip.MODULE, Equip.DOCUMENTS, Actions.EDIT);

  /* Метрологическое сопровождение */
  static EQUIP_VERIFICATION_ACCESS: string = this.build(EquipVerification.MODULE, Actions.ACCESS);

  static EQUIP_VERIFICATION_VIEW: string = this.build(EquipVerification.MODULE, Actions.VIEW);

  static EQUIP_VERIFICATION_EDIT: string = this.build(EquipVerification.MODULE, Actions.EDIT);

  /* Уведомления */
  static NOTIFICATION_ADMINISTRATE: string = this.build(Notification.MODULE, Actions.ADMINISTRATE);

  static NOTIFICATION_MONITOR_ADMINISTRATE: string = this.build(Notification.MONITOR, Actions.ADMINISTRATE);

  /* Отчёты */
  static REPORT_ACCESS: string = this.build(Report.MODULE, Actions.ACCESS);

  static REPORT_TWO_SCIENCE_VIEW: string = this.build(Report.MODULE, Report.TWO_SCIENCE, Actions.VIEW); // 2Science

  static REPORT_YEAR_VIEW: string = this.build(Report.MODULE, Report.YEAR, Actions.VIEW);

  static REPORT_OTHER_VIEW: string = this.build(Report.MODULE, Report.OTHER, Actions.VIEW);

  static REPORT_EGISU_VIEW: string = this.build(Report.MODULE, Report.EGISU, Actions.VIEW);

  static REPORT_NIR_REQUESTS_VIEW: string = this.build(Report.MODULE, Report.NIR_REQUESTS, Actions.VIEW);

  static REPORT_PROJECT_REQUESTS_VIEW: string = this.build(Report.MODULE, Report.PROJECT_REQUESTS, Actions.VIEW);

  static REPORT_MOBILE_REQUEST_VIEW: string = this.build(Report.MODULE, Report.MOBILE_REQUEST, Actions.VIEW);

  static REPORT_INTELLECTUAL_PROPERTY_VIEW: string = this.build(Report.MODULE, Report.INTELLECTUAL_PROPERTY, Actions.VIEW);

  static REPORT_COMMERCIALIZATION_VIEW: string = this.build(Report.MODULE, Report.COMMERCIALIZATION, Actions.VIEW);

  static REPORT_TENDERS_VIEW: string = this.build(Report.MODULE, Report.TENDERS, Actions.VIEW);

  static REPORT_NIU_VIEW: string = this.build(Report.MODULE, Report.NIU, Actions.VIEW);

  static REPORT_PERSONAL_VIEW: string = this.build(Report.MODULE, Report.PERSONAL, Actions.VIEW);

  static REPORT_PROJECT_VIEW: string = this.build(Report.MODULE, Report.PROJECT, Actions.VIEW);

  static REPORT_DEPARTMENTS_VIEW: string = this.build(Report.MODULE, Report.DEPARTMENTS, Actions.VIEW);

  static REPORT_SCIENTISTS_VIEW: string = this.build(Report.MODULE, Report.SCIENTISTS, Actions.VIEW);

  static REPORT_TSU_VIEW: string = this.build(Report.MODULE, Report.TSU, Actions.VIEW);

  static REPORT_PFU_VIEW: string = this.build(Report.MODULE, Report.PFU, Actions.VIEW);

  static REPORT_REPNID_VIEW: string = this.build(Report.MODULE, Report.REPNID, Actions.VIEW);

  static REPORT_INCENTIVE_PAYMENTS_VIEW: string = this.build(Report.MODULE, Report.INCENTIVE_PAYMENTS, Actions.VIEW);

  static REPORT_REQUEST_VIEW: string = this.build(Report.MODULE, Report.REQUEST, Actions.VIEW);

  static REPORT_ONTI_VIEW: string = this.build(Report.MODULE, Report.ONTI, Actions.VIEW);

  static REPORT_FILES_VIEW: string = this.build(Report.MODULE, Report.FILES, Actions.VIEW);

  /* Справочники */
  static REFERENCE_ACCESS: string = this.build(Reference.MODULE_FULL, Actions.ACCESS);

  static REFERENCE_METADATA_EDIT: string = this.build(Reference.MODULE_FULL, Reference.METADATA, Actions.EDIT);

  static REF_ADDRESSES_ADD: string = Reference.MODULE + Reference.ADDRESSES + Actions.ADD;

  static REF_ADDRESSES_EDIT: string = Reference.MODULE + Reference.ADDRESSES + Actions.EDIT;

  static REF_ADDRESSES_DELETE: string = Reference.MODULE + Reference.ADDRESSES + Actions.DELETE;

  static REF_ACADEMY_ADD: string = Reference.MODULE + Reference.ACADEMY + Actions.ADD;

  static REF_ACADEMY_EDIT: string = Reference.MODULE + Reference.ACADEMY + Actions.EDIT;

  static REF_ACADEMY_DELETE: string = Reference.MODULE + Reference.ACADEMY + Actions.DELETE;

  static REF_ACADEMIC_RANK_ADD: string = Reference.MODULE + Reference.ACADEMIC_RANK + Actions.ADD;

  static REF_ACADEMIC_RANK_EDIT: string = Reference.MODULE + Reference.ACADEMIC_RANK + Actions.EDIT;

  static REF_ACADEMIC_RANK_DELETE: string = Reference.MODULE + Reference.ACADEMIC_RANK + Actions.DELETE;

  static REF_PROJECT_WORK_SPACE_ADD: string = Reference.MODULE + Reference.PROJECT_WORK_SPACE + Actions.ADD;

  static REF_PROJECT_WORK_SPACE_EDIT: string = Reference.MODULE + Reference.PROJECT_WORK_SPACE + Actions.EDIT;

  static REF_PROJECT_WORK_SPACE_DELETE: string = Reference.MODULE + Reference.PROJECT_WORK_SPACE + Actions.DELETE;

  static REF_PROJECT_CODE_LETTER_ADD: string = Reference.MODULE + Reference.PROJECT_CODE_LETTER + Actions.ADD;

  static REF_PROJECT_CODE_LETTER_EDIT: string = Reference.MODULE + Reference.PROJECT_CODE_LETTER + Actions.EDIT;

  static REF_PROJECT_CODE_LETTER_DELETE: string = Reference.MODULE + Reference.PROJECT_CODE_LETTER + Actions.DELETE;

  static REF_OWNER_VISA_EDIT: string = Reference.MODULE + Reference.OWNER_VISA + Actions.EDIT;

  static REF_OWNER_VISA_DELETE: string = Reference.MODULE + Reference.OWNER_VISA + Actions.DELETE;

  static REF_KIND_ACADEMY_ADD: string = Reference.MODULE + Reference.KIND_ACADEMY + Actions.ADD;

  static REF_KIND_ACADEMY_EDIT: string = Reference.MODULE + Reference.KIND_ACADEMY + Actions.EDIT;

  static REF_KIND_ACADEMY_DELETE: string = Reference.MODULE + Reference.KIND_ACADEMY + Actions.DELETE;

  static REF_MOBILE_ACTIVITY_KIND_ADD: string = Reference.MODULE + Reference.MOBILE_ACTIVITY_KIND + Actions.ADD;

  static REF_MOBILE_ACTIVITY_KIND_EDIT: string = Reference.MODULE + Reference.MOBILE_ACTIVITY_KIND + Actions.EDIT;

  static REF_MOBILE_ACTIVITY_KIND_DELETE: string = Reference.MODULE + Reference.MOBILE_ACTIVITY_KIND + Actions.DELETE;

  static REF_CONTRACT_KIND_ADD: string = Reference.MODULE + Reference.CONTRACT_KIND + Actions.ADD;

  static REF_CONTRACT_KIND_EDIT: string = Reference.MODULE + Reference.CONTRACT_KIND + Actions.EDIT;

  static REF_CONTRACT_KIND_DELETE: string = Reference.MODULE + Reference.CONTRACT_KIND + Actions.DELETE;

  static REF_CATEGORY_EDITION_ADD: string = Reference.MODULE + Reference.CATEGORY_EDITION + Actions.ADD;

  static REF_CATEGORY_EDITION_EDIT: string = Reference.MODULE + Reference.CATEGORY_EDITION + Actions.EDIT;

  static REF_CATEGORY_EDITION_DELETE: string = Reference.MODULE + Reference.CATEGORY_EDITION + Actions.DELETE;

  static REF_PAYMENT_KIND_ADD: string = Reference.MODULE + Reference.PAYMENT_KIND + Actions.ADD;

  static REF_PAYMENT_KIND_EDIT: string = Reference.MODULE + Reference.PAYMENT_KIND + Actions.EDIT;

  static REF_PAYMENT_KIND_DELETE: string = Reference.MODULE + Reference.PAYMENT_KIND + Actions.DELETE;

  static REF_SECURITY_DOCUMENT_KIND_ADD: string = Reference.MODULE + Reference.SECURITY_DOCUMENT_KIND + Actions.ADD;

  static REF_SECURITY_DOCUMENT_KIND_EDIT: string = Reference.MODULE + Reference.SECURITY_DOCUMENT_KIND + Actions.EDIT;

  static REF_SECURITY_DOCUMENT_KIND_DELETE: string = Reference.MODULE + Reference.SECURITY_DOCUMENT_KIND + Actions.DELETE;

  static REF_OKVED_ADD: string = Reference.MODULE + Reference.OKVED + Actions.ADD;

  static REF_OKVED_EDIT: string = Reference.MODULE + Reference.OKVED + Actions.EDIT;

  static REF_OKVED_DELETE: string = Reference.MODULE + Reference.OKVED + Actions.DELETE;

  static REF_MOBILE_ADVERTISING_MATERIAL_TYPE_ADD: string =
    Reference.MODULE + Reference.MOBILE_ADVERTISING_MATERIAL_TYPE + Actions.ADD;

  static REF_MOBILE_ADVERTISING_MATERIAL_TYPE_EDIT: string =
    Reference.MODULE + Reference.MOBILE_ADVERTISING_MATERIAL_TYPE + Actions.EDIT;

  static REF_MOBILE_ADVERTISING_MATERIAL_TYPE_DELETE: string =
    Reference.MODULE + Reference.MOBILE_ADVERTISING_MATERIAL_TYPE + Actions.DELETE;

  static REF_KIND_RESEARCH_ADD: string = Reference.MODULE + Reference.KIND_RESEARCH + Actions.ADD;

  static REF_KIND_RESEARCH_EDIT: string = Reference.MODULE + Reference.KIND_RESEARCH + Actions.EDIT;

  static REF_KIND_RESEARCH_DELETE: string = Reference.MODULE + Reference.KIND_RESEARCH + Actions.DELETE;

  static REF_KIND_PROJECT_WORK_ADD: string = Reference.MODULE + Reference.KIND_PROJECT_WORK + Actions.ADD;

  static REF_KIND_PROJECT_WORK_EDIT: string = Reference.MODULE + Reference.KIND_PROJECT_WORK + Actions.EDIT;

  static REF_KIND_PROJECT_WORK_DELETE: string = Reference.MODULE + Reference.KIND_PROJECT_WORK + Actions.DELETE;

  static REF_FINANCE_PROVIDE_ADD: string = Reference.MODULE + Reference.FINANCE_PROVIDE + Actions.ADD;

  static REF_FINANCE_PROVIDE_EDIT: string = Reference.MODULE + Reference.FINANCE_PROVIDE + Actions.EDIT;

  static REF_FINANCE_PROVIDE_DELETE: string = Reference.MODULE + Reference.FINANCE_PROVIDE + Actions.DELETE;

  static REF_GRNTI_ADD: string = Reference.MODULE + Reference.GRNTI + Actions.ADD;

  static REF_GRNTI_EDIT: string = Reference.MODULE + Reference.GRNTI + Actions.EDIT;

  static REF_GRNTI_DELETE: string = Reference.MODULE + Reference.GRNTI + Actions.DELETE;

  static REF_GRIF_ADD: string = Reference.MODULE + Reference.GRIF + Actions.ADD;

  static REF_GRIF_EDIT: string = Reference.MODULE + Reference.GRIF + Actions.EDIT;

  static REF_GRIF_DELETE: string = Reference.MODULE + Reference.GRIF + Actions.DELETE;

  static REF_STAFF_GROUP_ADD: string = Reference.MODULE + Reference.STAFF_GROUP + Actions.ADD;

  static REF_STAFF_GROUP_EDIT: string = Reference.MODULE + Reference.STAFF_GROUP + Actions.EDIT;

  static REF_STAFF_GROUP_DELETE: string = Reference.MODULE + Reference.STAFF_GROUP + Actions.DELETE;

  static REF_APPOINTMENT_ADD: string = Reference.MODULE + Reference.APPOINTMENT + Actions.ADD;

  static REF_APPOINTMENT_EDIT: string = Reference.MODULE + Reference.APPOINTMENT + Actions.EDIT;

  static REF_APPOINTMENT_DELETE: string = Reference.MODULE + Reference.APPOINTMENT + Actions.DELETE;

  static REF_SALARY_APPONTMENT_EDIT: string = Reference.MODULE + Reference.SALARY_APPONTMENT + Actions.EDIT;

  static REF_SALARY_APPONTMENT_DELETE: string = Reference.MODULE + Reference.SALARY_APPONTMENT + Actions.DELETE;

  static REF_VISA_ADD: string = Reference.MODULE + Reference.VISA + Actions.ADD;

  static REF_VISA_EDIT: string = Reference.MODULE + Reference.VISA + Actions.EDIT;

  static REF_VISA_DELETE: string = Reference.MODULE + Reference.VISA + Actions.DELETE;

  static REF_MOBILE_REQUEST_VISA_ADD: string = Reference.MODULE + Reference.MOBILE_REQUEST_VISA + Actions.ADD;

  static REF_MOBILE_REQUEST_VISA_EDIT: string = Reference.MODULE + Reference.MOBILE_REQUEST_VISA + Actions.EDIT;

  static REF_MOBILE_REQUEST_VISA_DELETE: string = Reference.MODULE + Reference.MOBILE_REQUEST_VISA + Actions.DELETE;

  static REF_PRIVATE_ACCESS_ADD: string = Reference.MODULE + Reference.PRIVATE_ACCESS + Actions.ADD;

  static REF_PRIVATE_ACCESS_EDIT: string = Reference.MODULE + Reference.PRIVATE_ACCESS + Actions.EDIT;

  static REF_PRIVATE_ACCESS_DELETE: string = Reference.MODULE + Reference.PRIVATE_ACCESS + Actions.DELETE;

  static REF_SALARY_INCOME_EDIT: string = Reference.MODULE + Reference.SALARY_INCOME + Actions.EDIT;

  static REF_SALARY_INCOME_DELETE: string = Reference.MODULE + Reference.SALARY_INCOME + Actions.DELETE;

  static REF_TYPE_CONCERT_ADD: string = Reference.MODULE + Reference.TYPE_CONCERT + Actions.ADD;

  static REF_TYPE_CONCERT_EDIT: string = Reference.MODULE + Reference.TYPE_CONCERT + Actions.EDIT;

  static REF_TYPE_CONCERT_DELETE: string = Reference.MODULE + Reference.TYPE_CONCERT + Actions.DELETE;

  static REF_ENTERPRISE_CUSTOMER_ADD: string = Reference.MODULE + Reference.ENTERPRISE_CUSTOMER + Actions.ADD;

  static REF_ENTERPRISE_CUSTOMER_EDIT: string = Reference.MODULE + Reference.ENTERPRISE_CUSTOMER + Actions.EDIT;

  static REF_ENTERPRISE_CUSTOMER_DELETE: string = Reference.MODULE + Reference.ENTERPRISE_CUSTOMER + Actions.DELETE;

  static REF_PUBLISHER_ADD: string = Reference.MODULE + Reference.PUBLISHER + Actions.ADD;

  static REF_PUBLISHER_EDIT: string = Reference.MODULE + Reference.PUBLISHER + Actions.EDIT;

  static REF_PUBLISHER_DELETE: string = Reference.MODULE + Reference.PUBLISHER + Actions.DELETE;

  static REF_RESULT_INDICATOR_ADD: string = Reference.MODULE + Reference.RESULT_INDICATOR + Actions.ADD;

  static REF_RESULT_INDICATOR_EDIT: string = Reference.MODULE + Reference.RESULT_INDICATOR + Actions.EDIT;

  static REF_RESULT_INDICATOR_DELETE: string = Reference.MODULE + Reference.RESULT_INDICATOR + Actions.DELETE;

  static REF_INVESTMENT_SOURCE_ADD: string = Reference.MODULE + Reference.INVESTMENT_SOURCE + Actions.ADD;

  static REF_INVESTMENT_SOURCE_EDIT: string = Reference.MODULE + Reference.INVESTMENT_SOURCE + Actions.EDIT;

  static REF_INVESTMENT_SOURCE_DELETE: string = Reference.MODULE + Reference.INVESTMENT_SOURCE + Actions.DELETE;

  static REF_KOSGU_ADD: string = Reference.MODULE + Reference.KOSGU + Actions.ADD;

  static REF_KOSGU_EDIT: string = Reference.MODULE + Reference.KOSGU + Actions.EDIT;

  static REF_KOSGU_DELETE: string = Reference.MODULE + Reference.KOSGU + Actions.DELETE;

  static REF_CHANGE_CATEGORY_ADD: string = Reference.MODULE + Reference.CHANGE_CATEGORY + Actions.ADD;

  static REF_CHANGE_CATEGORY_EDIT: string = Reference.MODULE + Reference.CHANGE_CATEGORY + Actions.EDIT;

  static REF_CHANGE_CATEGORY_DELETE: string = Reference.MODULE + Reference.CHANGE_CATEGORY + Actions.DELETE;

  static REF_TENDER_CATEGORY_ADD: string = Reference.MODULE + Reference.TENDER_CATEGORY + Actions.ADD;

  static REF_TENDER_CATEGORY_EDIT: string = Reference.MODULE + Reference.TENDER_CATEGORY + Actions.EDIT;

  static REF_TENDER_CATEGORY_DELETE: string = Reference.MODULE + Reference.TENDER_CATEGORY + Actions.DELETE;

  static REF_RECOGNITION_AWARD_CATEGORY_ADD: string = Reference.MODULE + Reference.RECOGNITION_AWARD_CATEGORY + Actions.ADD;

  static REF_RECOGNITION_AWARD_CATEGORY_EDIT: string = Reference.MODULE + Reference.RECOGNITION_AWARD_CATEGORY + Actions.EDIT;

  static REF_RECOGNITION_AWARD_CATEGORY_DELETE: string = Reference.MODULE + Reference.RECOGNITION_AWARD_CATEGORY + Actions.DELETE;

  static REF_PAYMENT_REQUEST_MESSAGE_CATEGORY_ADD: string =
    Reference.MODULE + Reference.PAYMENT_REQUEST_MESSAGE_CATEGORY + Actions.ADD;

  static REF_PAYMENT_REQUEST_MESSAGE_CATEGORY_EDIT: string =
    Reference.MODULE + Reference.PAYMENT_REQUEST_MESSAGE_CATEGORY + Actions.EDIT;

  static REF_PAYMENT_REQUEST_MESSAGE_CATEGORY_DELETE: string =
    Reference.MODULE + Reference.PAYMENT_REQUEST_MESSAGE_CATEGORY + Actions.DELETE;

  static REF_APPOINTMENT_CATEGORY_ADD: string = Reference.MODULE + Reference.APPOINTMENT_CATEGORY + Actions.ADD;

  static REF_APPOINTMENT_CATEGORY_EDIT: string = Reference.MODULE + Reference.APPOINTMENT_CATEGORY + Actions.EDIT;

  static REF_APPOINTMENT_CATEGORY_DELETE: string = Reference.MODULE + Reference.APPOINTMENT_CATEGORY + Actions.DELETE;

  static REF_CLASSIFICATION_EXPENDITURES_BUDGETS_ADD: string =
    Reference.MODULE + Reference.CLASSIFICATION_EXPENDITURES_BUDGETS + Actions.ADD;

  static REF_CLASSIFICATION_EXPENDITURES_BUDGETS_EDIT: string =
    Reference.MODULE + Reference.CLASSIFICATION_EXPENDITURES_BUDGETS + Actions.EDIT;

  static REF_CLASSIFICATION_EXPENDITURES_BUDGETS_DELETE: string =
    Reference.MODULE + Reference.CLASSIFICATION_EXPENDITURES_BUDGETS + Actions.DELETE;

  static REF_KVR_ADD: string = Reference.MODULE + Reference.KVR + Actions.ADD;

  static REF_KVR_EDIT: string = Reference.MODULE + Reference.KVR + Actions.EDIT;

  static REF_KVR_DELETE: string = Reference.MODULE + Reference.KVR + Actions.DELETE;

  static REF_NATIONAL_PROJECT_EXPENSE_CODE_ADD: string = Reference.MODULE + Reference.NATIONAL_PROJECT_EXPENSE_CODE + Actions.ADD;

  static REF_NATIONAL_PROJECT_EXPENSE_CODE_EDIT: string =
    Reference.MODULE + Reference.NATIONAL_PROJECT_EXPENSE_CODE + Actions.EDIT;

  static REF_NATIONAL_PROJECT_EXPENSE_CODE_DELETE: string =
    Reference.MODULE + Reference.NATIONAL_PROJECT_EXPENSE_CODE + Actions.DELETE;

  static REF_OECD_ADD: string = Reference.MODULE + Reference.OECD + Actions.ADD;

  static REF_OECD_EDIT: string = Reference.MODULE + Reference.OECD + Actions.EDIT;

  static REF_OECD_DELETE: string = Reference.MODULE + Reference.OECD + Actions.DELETE;

  static REF_PRIORITY_TECHNOLOGY_ADD: string = Reference.MODULE + Reference.PRIORITY_TECHNOLOGY + Actions.ADD;

  static REF_PRIORITY_TECHNOLOGY_EDIT: string = Reference.MODULE + Reference.PRIORITY_TECHNOLOGY + Actions.EDIT;

  static REF_PRIORITY_TECHNOLOGY_DELETE: string = Reference.MODULE + Reference.PRIORITY_TECHNOLOGY + Actions.DELETE;

  static REF_LKSETS_ADD: string = Reference.MODULE + Reference.LKSETS + Actions.ADD;

  static REF_LKSETS_EDIT: string = Reference.MODULE + Reference.LKSETS + Actions.EDIT;

  static REF_LKSETS_DELETE: string = Reference.MODULE + Reference.LKSETS + Actions.DELETE;

  static REF_MPK_ADD: string = Reference.MODULE + Reference.MPK + Actions.ADD;

  static REF_MPK_EDIT: string = Reference.MODULE + Reference.MPK + Actions.EDIT;

  static REF_MPK_DELETE: string = Reference.MODULE + Reference.MPK + Actions.DELETE;

  static REF_INTERNATIONAL_PROGRAM_ADD: string = Reference.MODULE + Reference.INTERNATIONAL_PROGRAM + Actions.ADD;

  static REF_INTERNATIONAL_PROGRAM_EDIT: string = Reference.MODULE + Reference.INTERNATIONAL_PROGRAM + Actions.EDIT;

  static REF_INTERNATIONAL_PROGRAM_DELETE: string = Reference.MODULE + Reference.INTERNATIONAL_PROGRAM + Actions.DELETE;

  static REF_RECOGNITION_AWARD_ADD: string = Reference.MODULE + Reference.RECOGNITION_AWARD + Actions.ADD;

  static REF_RECOGNITION_AWARD_EDIT: string = Reference.MODULE + Reference.RECOGNITION_AWARD + Actions.EDIT;

  static REF_RECOGNITION_AWARD_DELETE: string = Reference.MODULE + Reference.RECOGNITION_AWARD + Actions.DELETE;

  static REF_PAYMENT_PURPOSE_ADD: string = Reference.MODULE + Reference.PAYMENT_PURPOSE + Actions.ADD;

  static REF_PAYMENT_PURPOSE_EDIT: string = Reference.MODULE + Reference.PAYMENT_PURPOSE + Actions.EDIT;

  static REF_PAYMENT_PURPOSE_DELETE: string = Reference.MODULE + Reference.PAYMENT_PURPOSE + Actions.DELETE;

  static REF_PAYMENT_PURPOSE_REQUEST_ADD: string = Reference.MODULE + Reference.PAYMENT_PURPOSE_REQUEST + Actions.ADD;

  static REF_PAYMENT_PURPOSE_REQUEST_EDIT: string = Reference.MODULE + Reference.PAYMENT_PURPOSE_REQUEST + Actions.EDIT;

  static REF_PAYMENT_PURPOSE_REQUEST_DELETE: string = Reference.MODULE + Reference.PAYMENT_PURPOSE_REQUEST + Actions.DELETE;

  static REF_MINISTRY_EDUCATION_ADD: string = Reference.MODULE + Reference.MINISTRY_EDUCATION + Actions.ADD;

  static REF_MINISTRY_EDUCATION_EDIT: string = Reference.MODULE + Reference.MINISTRY_EDUCATION + Actions.EDIT;

  static REF_MINISTRY_EDUCATION_DELETE: string = Reference.MODULE + Reference.MINISTRY_EDUCATION + Actions.DELETE;

  static REF_VAK_SPECIALITY_ADD: string = Reference.MODULE + Reference.VAK_SPECIALITY + Actions.ADD;

  static REF_VAK_SPECIALITY_EDIT: string = Reference.MODULE + Reference.VAK_SPECIALITY + Actions.EDIT;

  static REF_VAK_SPECIALITY_DELETE: string = Reference.MODULE + Reference.VAK_SPECIALITY + Actions.DELETE;

  static REF_OKVED_2_ADD: string = Reference.MODULE + Reference.OKVED_2 + Actions.ADD;

  static REF_OKVED_2_EDIT: string = Reference.MODULE + Reference.OKVED_2 + Actions.EDIT;

  static REF_OKVED_2_DELETE: string = Reference.MODULE + Reference.OKVED_2 + Actions.DELETE;

  static REF_OKVED_INCOME_ADD: string = Reference.MODULE + Reference.OKVED_INCOME + Actions.ADD;

  static REF_OKVED_INCOME_EDIT: string = Reference.MODULE + Reference.OKVED_INCOME + Actions.EDIT;

  static REF_OKVED_INCOME_DELETE: string = Reference.MODULE + Reference.OKVED_INCOME + Actions.DELETE;

  static REF_KNOWLEDGE_BRANCH_ADD: string = Reference.MODULE + Reference.KNOWLEDGE_BRANCH + Actions.ADD;

  static REF_KNOWLEDGE_BRANCH_EDIT: string = Reference.MODULE + Reference.KNOWLEDGE_BRANCH + Actions.EDIT;

  static REF_KNOWLEDGE_BRANCH_DELETE: string = Reference.MODULE + Reference.KNOWLEDGE_BRANCH + Actions.DELETE;

  static REF_STUDY_ADD: string = Reference.MODULE + Reference.STUDY + Actions.ADD;

  static REF_STUDY_EDIT: string = Reference.MODULE + Reference.STUDY + Actions.EDIT;

  static REF_STUDY_DELETE: string = Reference.MODULE + Reference.STUDY + Actions.DELETE;

  static REF_SCIENCE_DOMAIN_INTEREST_ADD: string = Reference.MODULE + Reference.SCIENCE_DOMAIN_INTEREST + Actions.ADD;

  static REF_SCIENCE_DOMAIN_INTEREST_EDIT: string = Reference.MODULE + Reference.SCIENCE_DOMAIN_INTEREST + Actions.EDIT;

  static REF_SCIENCE_DOMAIN_INTEREST_DELETE: string = Reference.MODULE + Reference.SCIENCE_DOMAIN_INTEREST + Actions.DELETE;

  static REF_ENTERPRISE_ADD: string = Reference.MODULE + Reference.ENTERPRISE + Actions.ADD;

  static REF_MODULE_EDIT: string = Reference.MODULE + Reference.ENTERPRISE + Actions.EDIT;

  static REF_ENTERPRISE_DELETE: string = Reference.MODULE + Reference.ENTERPRISE + Actions.DELETE;

  static REF_ENTERPRISE_DEPONENT_ADD: string = Reference.MODULE + Reference.ENTERPRISE_DEPONENT + Actions.ADD;

  static REF_ENTERPRISE_DEPONENT_EDIT: string = Reference.MODULE + Reference.ENTERPRISE_DEPONENT + Actions.EDIT;

  static REF_ENTERPRISE_DEPONENT_DELETE: string = Reference.MODULE + Reference.ENTERPRISE_DEPONENT + Actions.DELETE;

  static REF_SCIENCE_BRUNCH_ADD: string = Reference.MODULE + Reference.SCIENCE_BRUNCH + Actions.ADD;

  static REF_SCIENCE_BRUNCH_EDIT: string = Reference.MODULE + Reference.SCIENCE_BRUNCH + Actions.EDIT;

  static REF_SCIENCE_BRUNCH_DELETE: string = Reference.MODULE + Reference.SCIENCE_BRUNCH + Actions.DELETE;

  static REF_PNMITR_ADD: string = Reference.MODULE + Reference.PNMITR + Actions.ADD;

  static REF_PNMITR_EDIT: string = Reference.MODULE + Reference.PNMITR + Actions.EDIT;

  static REF_PNMITR_DELETE: string = Reference.MODULE + Reference.PNMITR + Actions.DELETE;

  static REF_PNI_ADD: string = Reference.MODULE + Reference.PNI + Actions.ADD;

  static REF_PNI_EDIT: string = Reference.MODULE + Reference.PNI + Actions.EDIT;

  static REF_PNI_DELETE: string = Reference.MODULE + Reference.PNI + Actions.DELETE;

  static REF_DEPARTMENT_EDIT: string = Reference.MODULE + Reference.DEPARTMENT + Actions.EDIT;

  static REF_DEPARTMENT_DELETE: string = Reference.MODULE + Reference.DEPARTMENT + Actions.DELETE;

  static REF_RESULT_INDEX_ADD: string = Reference.MODULE + Reference.RESULT_INDEX + Actions.ADD;

  static REF_RESULT_INDEX_EDIT: string = Reference.MODULE + Reference.RESULT_INDEX + Actions.EDIT;

  static REF_RESULT_INDEX_DELETE: string = Reference.MODULE + Reference.RESULT_INDEX + Actions.DELETE;

  static REF_PROJECT_REQUEST_NUMBER_PREFIX_ADD: string = Reference.MODULE + Reference.PROJECT_REQUEST_NUMBER_PREFIX + Actions.ADD;

  static REF_PROJECT_REQUEST_NUMBER_PREFIX_EDIT: string =
    Reference.MODULE + Reference.PROJECT_REQUEST_NUMBER_PREFIX + Actions.EDIT;

  static REF_PROJECT_REQUEST_NUMBER_PREFIX_DELETE: string =
    Reference.MODULE + Reference.PROJECT_REQUEST_NUMBER_PREFIX + Actions.DELETE;

  static REF_PNR_ADD: string = Reference.MODULE + Reference.PNR + Actions.ADD;

  static REF_PNR_EDIT: string = Reference.MODULE + Reference.PNR + Actions.EDIT;

  static REF_PNR_DELETE: string = Reference.MODULE + Reference.PNR + Actions.DELETE;

  static REF_MOBILE_USE_MATERIAL_OBLIGATION_RESULT_ADD: string =
    Reference.MODULE + Reference.MOBILE_USE_MATERIAL_OBLIGATION_RESULT + Actions.ADD;

  static REF_MOBILE_USE_MATERIAL_OBLIGATION_RESULT_EDIT: string =
    Reference.MODULE + Reference.MOBILE_USE_MATERIAL_OBLIGATION_RESULT + Actions.EDIT;

  static REF_MOBILE_USE_MATERIAL_OBLIGATION_RESULT_DELETE: string =
    Reference.MODULE + Reference.MOBILE_USE_MATERIAL_OBLIGATION_RESULT + Actions.DELETE;

  static REF_DECISION_ADD: string = Reference.MODULE + Reference.DECISION + Actions.ADD;

  static REF_DECISION_EDIT: string = Reference.MODULE + Reference.DECISION + Actions.EDIT;

  static REF_DECISION_DELETE: string = Reference.MODULE + Reference.DECISION + Actions.DELETE;

  static REF_PARTICIPATION_MEMBER_ROLE_ADD: string = Reference.MODULE + Reference.PARTICIPATION_MEMBER_ROLE + Actions.ADD;

  static REF_PARTICIPATION_MEMBER_ROLE_EDIT: string = Reference.MODULE + Reference.PARTICIPATION_MEMBER_ROLE + Actions.EDIT;

  static REF_PARTICIPATION_MEMBER_ROLE_DELETE: string = Reference.MODULE + Reference.PARTICIPATION_MEMBER_ROLE + Actions.DELETE;

  static REF_ORG_COMMITTE_ADD: string = Reference.MODULE + Reference.ORG_COMMITTE + Actions.ADD;

  static REF_ORG_COMMITTE_EDIT: string = Reference.MODULE + Reference.ORG_COMMITTE + Actions.EDIT;

  static REF_ORG_COMMITTE_DELETE: string = Reference.MODULE + Reference.ORG_COMMITTE + Actions.DELETE;

  static REF_NTI_MARKET_ADD: string = Reference.MODULE + Reference.NTI_MARKET + Actions.ADD;

  static REF_NTI_MARKET_EDIT: string = Reference.MODULE + Reference.NTI_MARKET + Actions.EDIT;

  static REF_NTI_MARKET_DELETE: string = Reference.MODULE + Reference.NTI_MARKET + Actions.DELETE;

  static REF_SECTOR_ACTIVITY_ADD: string = Reference.MODULE + Reference.SECTOR_ACTIVITY + Actions.ADD;

  static REF_SECTOR_ACTIVITY_EDIT: string = Reference.MODULE + Reference.SECTOR_ACTIVITY + Actions.EDIT;

  static REF_SECTOR_ACTIVITY_DELETE: string = Reference.MODULE + Reference.SECTOR_ACTIVITY + Actions.DELETE;

  static REF_EVENT_SERIES_ADD: string = Reference.MODULE + Reference.EVENT_SERIES + Actions.ADD;

  static REF_EVENT_SERIES_EDIT: string = Reference.MODULE + Reference.EVENT_SERIES + Actions.EDIT;

  static REF_EVENT_SERIES_DELETE: string = Reference.MODULE + Reference.EVENT_SERIES + Actions.DELETE;

  static REF_CITATION_SYSTEM_ADD: string = Reference.MODULE + Reference.CITATION_SYSTEM + Actions.ADD;

  static REF_CITATION_SYSTEM_EDIT: string = Reference.MODULE + Reference.CITATION_SYSTEM + Actions.EDIT;

  static REF_CITATION_SYSTEM_DELETE: string = Reference.MODULE + Reference.CITATION_SYSTEM + Actions.DELETE;

  static REF_NTI_ETE_TECHNOLOGY_ADD: string = Reference.MODULE + Reference.NTI_ETE_TECHNOLOGY + Actions.ADD;

  static REF_NTI_ETE_TECHNOLOGY_EDIT: string = Reference.MODULE + Reference.NTI_ETE_TECHNOLOGY + Actions.EDIT;

  static REF_NTI_ETE_TECHNOLOGY_DELETE: string = Reference.MODULE + Reference.NTI_ETE_TECHNOLOGY + Actions.DELETE;

  static REF_FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE_ADD: string =
    Reference.MODULE + Reference.FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE_EDIT: string =
    Reference.MODULE + Reference.FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE_DELETE: string =
    Reference.MODULE + Reference.FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_EVENT_FEEDLINE_MESSAGE_ADD: string = Reference.MODULE + Reference.EVENT_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_EVENT_FEEDLINE_MESSAGE_EDIT: string = Reference.MODULE + Reference.EVENT_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_EVENT_FEEDLINE_MESSAGE_DELETE: string = Reference.MODULE + Reference.EVENT_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_PROJECT_FEEDLINE_MESSAGE_ADD: string = Reference.MODULE + Reference.PROJECT_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_PROJECT_FEEDLINE_MESSAGE_EDIT: string = Reference.MODULE + Reference.PROJECT_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_PROJECT_FEEDLINE_MESSAGE_DELETE: string = Reference.MODULE + Reference.PROJECT_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_PUBLICATION_FEEDLINE_MESSAGE_ADD: string = Reference.MODULE + Reference.PUBLICATION_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_PUBLICATION_FEEDLINE_MESSAGE_EDIT: string = Reference.MODULE + Reference.PUBLICATION_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_PUBLICATION_FEEDLINE_MESSAGE_DELETE: string =
    Reference.MODULE + Reference.PUBLICATION_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_JOINT_EVENT_FEEDLINE_MESSAGE_ADD: string = Reference.MODULE + Reference.JOINT_EVENT_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_JOINT_EVENT_FEEDLINE_MESSAGE_EDIT: string = Reference.MODULE + Reference.JOINT_EVENT_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_JOINT_EVENT_FEEDLINE_MESSAGE_DELETE: string =
    Reference.MODULE + Reference.JOINT_EVENT_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_PARTICIPATION_FEEDLINE_MESSAGE_ADD: string =
    Reference.MODULE + Reference.PARTICIPATION_FEEDLINE_MESSAGE + Actions.ADD;

  static REF_PARTICIPATION_FEEDLINE_MESSAGE_EDIT: string =
    Reference.MODULE + Reference.PARTICIPATION_FEEDLINE_MESSAGE + Actions.EDIT;

  static REF_PARTICIPATION_FEEDLINE_MESSAGE_DELETE: string =
    Reference.MODULE + Reference.PARTICIPATION_FEEDLINE_MESSAGE + Actions.DELETE;

  static REF_ENTERPRISE_STATUS_ADD: string = Reference.MODULE + Reference.ENTERPRISE_STATUS + Actions.ADD;

  static REF_ENTERPRISE_STATUS_EDIT: string = Reference.MODULE + Reference.ENTERPRISE_STATUS + Actions.EDIT;

  static REF_ENTERPRISE_STATUS_DELETE: string = Reference.MODULE + Reference.ENTERPRISE_STATUS + Actions.DELETE;

  static REF_PUBLISHER_SPECIALTY_ADD: string = Reference.MODULE + Reference.PUBLISHER_SPECIALTY + Actions.ADD;

  static REF_PUBLISHER_SPECIALTY_EDIT: string = Reference.MODULE + Reference.PUBLISHER_SPECIALTY + Actions.EDIT;

  static REF_PUBLISHER_SPECIALTY_DELETE: string = Reference.MODULE + Reference.PUBLISHER_SPECIALTY + Actions.DELETE;

  static REF_EDUCATION_SPECIALITY_EDIT: string = Reference.MODULE + Reference.EDUCATION_SPECIALITY + Actions.EDIT;

  static REF_EDUCATION_SPECIALITY_DELETE: string = Reference.MODULE + Reference.EDUCATION_SPECIALITY + Actions.DELETE;

  static REF_MOBILE_PARTICIPANT_STATUS_ADD: string = Reference.MODULE + Reference.MOBILE_PARTICIPANT_STATUS + Actions.ADD;

  static REF_MOBILE_PARTICIPANT_STATUS_EDIT: string = Reference.MODULE + Reference.MOBILE_PARTICIPANT_STATUS + Actions.EDIT;

  static REF_MOBILE_PARTICIPANT_STATUS_DELETE: string = Reference.MODULE + Reference.MOBILE_PARTICIPANT_STATUS + Actions.DELETE;

  static REF_CATEGORY_ADD: string = Reference.MODULE + Reference.CATEGORY + Actions.ADD;

  static REF_CATEGORY_EDIT: string = Reference.MODULE + Reference.CATEGORY + Actions.EDIT;

  static REF_CATEGORY_DELETE: string = Reference.MODULE + Reference.CATEGORY + Actions.DELETE;

  static REF_COUNTRY_EDIT: string = Reference.MODULE + Reference.COUNTRY + Actions.EDIT;

  static REF_COUNTRY_DELETE: string = Reference.MODULE + Reference.COUNTRY + Actions.DELETE;

  static REF_NTR_STRATEGY_ADD: string = Reference.MODULE + Reference.NTR_STRATEGY + Actions.ADD;

  static REF_NTR_STRATEGY_EDIT: string = Reference.MODULE + Reference.NTR_STRATEGY + Actions.EDIT;

  static REF_NTR_STRATEGY_DELETE: string = Reference.MODULE + Reference.NTR_STRATEGY + Actions.DELETE;

  static REF_THEMATIC_CATEGORY_ADD: string = Reference.MODULE + Reference.THEMATIC_CATEGORY + Actions.ADD;

  static REF_THEMATIC_CATEGORY_EDIT: string = Reference.MODULE + Reference.THEMATIC_CATEGORY + Actions.EDIT;

  static REF_THEMATIC_CATEGORY_DELETE: string = Reference.MODULE + Reference.THEMATIC_CATEGORY + Actions.DELETE;

  static REF_THEMATIC_DOMAIN_ADD: string = Reference.MODULE + Reference.THEMATIC_DOMAIN + Actions.ADD;

  static REF_THEMATIC_DOMAIN_EDIT: string = Reference.MODULE + Reference.THEMATIC_DOMAIN + Actions.EDIT;

  static REF_THEMATIC_DOMAIN_DELETE: string = Reference.MODULE + Reference.THEMATIC_DOMAIN + Actions.DELETE;

  static REF_QNA_QUESTION_THEME_ADD: string = Reference.MODULE + Reference.QNA_QUESTION_THEME + Actions.ADD;

  static REF_QNA_QUESTION_THEME_EDIT: string = Reference.MODULE + Reference.QNA_QUESTION_THEME + Actions.EDIT;

  static REF_QNA_QUESTION_THEME_DELETE: string = Reference.MODULE + Reference.QNA_QUESTION_THEME + Actions.DELETE;

  static REF_TECHNOLOGY_PLATFORM_ADD: string = Reference.MODULE + Reference.TECHNOLOGY_PLATFORM + Actions.ADD;

  static REF_TECHNOLOGY_PLATFORM_EDIT: string = Reference.MODULE + Reference.TECHNOLOGY_PLATFORM + Actions.EDIT;

  static REF_TECHNOLOGY_PLATFORM_DELETE: string = Reference.MODULE + Reference.TECHNOLOGY_PLATFORM + Actions.DELETE;

  static REF_TYPE_RNTD_ADD: string = Reference.MODULE + Reference.TYPE_RNTD + Actions.ADD;

  static REF_TYPE_RNTD_EDIT: string = Reference.MODULE + Reference.TYPE_RNTD + Actions.EDIT;

  static REF_TYPE_RNTD_DELETE: string = Reference.MODULE + Reference.TYPE_RNTD + Actions.DELETE;

  static REF_LKSD_ADD: string = Reference.MODULE + Reference.LKSD + Actions.ADD;

  static REF_LKSD_EDIT: string = Reference.MODULE + Reference.LKSD + Actions.EDIT;

  static REF_LKSD_DELETE: string = Reference.MODULE + Reference.LKSD + Actions.DELETE;

  static REF_TYPE_EXPOSITION_ADD: string = Reference.MODULE + Reference.TYPE_EXPOSITION + Actions.ADD;

  static REF_TYPE_EXPOSITION_EDIT: string = Reference.MODULE + Reference.TYPE_EXPOSITION + Actions.EDIT;

  static REF_TYPE_EXPOSITION_DELETE: string = Reference.MODULE + Reference.TYPE_EXPOSITION + Actions.DELETE;

  static REF_TYPE_REPORT_ADD: string = Reference.MODULE + Reference.TYPE_REPORT + Actions.ADD;

  static REF_TYPE_REPORT_EDIT: string = Reference.MODULE + Reference.TYPE_REPORT + Actions.EDIT;

  static REF_TYPE_REPORT_DELETE: string = Reference.MODULE + Reference.TYPE_REPORT + Actions.DELETE;

  static REF_CITATION_SYSTEM_SOURCE_TYPE_ADD: string = Reference.MODULE + Reference.CITATION_SYSTEM_SOURCE_TYPE + Actions.ADD;

  static REF_CITATION_SYSTEM_SOURCE_TYPE_EDIT: string = Reference.MODULE + Reference.CITATION_SYSTEM_SOURCE_TYPE + Actions.EDIT;

  static REF_CITATION_SYSTEM_SOURCE_TYPE_DELETE: string =
    Reference.MODULE + Reference.CITATION_SYSTEM_SOURCE_TYPE + Actions.DELETE;

  static REF_TYPE_INVESTMENT_SOURCE_ADD: string = Reference.MODULE + Reference.TYPE_INVESTMENT_SOURCE + Actions.ADD;

  static REF_TYPE_INVESTMENT_SOURCE_EDIT: string = Reference.MODULE + Reference.TYPE_INVESTMENT_SOURCE + Actions.EDIT;

  static REF_TYPE_INVESTMENT_SOURCE_DELETE: string = Reference.MODULE + Reference.TYPE_INVESTMENT_SOURCE + Actions.DELETE;

  static REF_TYPE_CONTEST_ADD: string = Reference.MODULE + Reference.TYPE_CONTEST + Actions.ADD;

  static REF_TYPE_CONTEST_EDIT: string = Reference.MODULE + Reference.TYPE_CONTEST + Actions.EDIT;

  static REF_TYPE_CONTEST_DELETE: string = Reference.MODULE + Reference.TYPE_CONTEST + Actions.DELETE;

  static REF_TYPE_CONFERENCE_ADD: string = Reference.MODULE + Reference.TYPE_CONFERENCE + Actions.ADD;

  static REF_TYPE_CONFERENCE_EDIT: string = Reference.MODULE + Reference.TYPE_CONFERENCE + Actions.EDIT;

  static REF_TYPE_CONFERENCE_DELETE: string = Reference.MODULE + Reference.TYPE_CONFERENCE + Actions.DELETE;

  static REF_PARTNERSHIP_TYPE_ADD: string = Reference.MODULE + Reference.PARTNERSHIP_TYPE + Actions.ADD;

  static REF_PARTNERSHIP_TYPE_EDIT: string = Reference.MODULE + Reference.PARTNERSHIP_TYPE + Actions.EDIT;

  static REF_PARTNERSHIP_TYPE_DELETE: string = Reference.MODULE + Reference.PARTNERSHIP_TYPE + Actions.DELETE;

  static REF_COMPOUND_TYPE_ADD: string = Reference.MODULE + Reference.COMPOUND_TYPE + Actions.ADD;

  static REF_COMPOUND_TYPE_EDIT: string = Reference.MODULE + Reference.COMPOUND_TYPE + Actions.EDIT;

  static REF_COMPOUND_TYPE_DELETE: string = Reference.MODULE + Reference.COMPOUND_TYPE + Actions.DELETE;

  static REF_CITATION_SYSTEM_ARTICLE_TYPE_ADD: string = Reference.MODULE + Reference.CITATION_SYSTEM_ARTICLE_TYPE + Actions.ADD;

  static REF_CITATION_SYSTEM_ARTICLE_TYPE_EDIT: string = Reference.MODULE + Reference.CITATION_SYSTEM_ARTICLE_TYPE + Actions.EDIT;

  static REF_CITATION_SYSTEM_ARTICLE_TYPE_DELETE: string =
    Reference.MODULE + Reference.CITATION_SYSTEM_ARTICLE_TYPE + Actions.DELETE;

  static REF_EXHIBIT_TYPE_ADD: string = Reference.MODULE + Reference.EXHIBIT_TYPE + Actions.ADD;

  static REF_EXHIBIT_TYPE_EDIT: string = Reference.MODULE + Reference.EXHIBIT_TYPE + Actions.EDIT;

  static REF_EXHIBIT_TYPE_DELETE: string = Reference.MODULE + Reference.EXHIBIT_TYPE + Actions.DELETE;

  static REF_MARKETPLACE_ADD: string = Reference.MODULE + Reference.MARKETPLACE + Actions.ADD;

  static REF_MARKETPLACE_EDIT: string = Reference.MODULE + Reference.MARKETPLACE + Actions.EDIT;

  static REF_MARKETPLACE_DELETE: string = Reference.MODULE + Reference.MARKETPLACE + Actions.DELETE;

  static REF_UDK_ADD: string = Reference.MODULE + Reference.UDK + Actions.ADD;

  static REF_UDK_EDIT: string = Reference.MODULE + Reference.UDK + Actions.EDIT;

  static REF_UDK_DELETE: string = Reference.MODULE + Reference.UDK + Actions.DELETE;

  static REF_EVENT_STATUS_ADD: string = Reference.MODULE + Reference.EVENT_STATUS + Actions.ADD;

  static REF_EVENT_STATUS_EDIT: string = Reference.MODULE + Reference.EVENT_STATUS + Actions.EDIT;

  static REF_EVENT_STATUS_DELETE: string = Reference.MODULE + Reference.EVENT_STATUS + Actions.DELETE;

  static REF_TENDER_PARTICIPANT_ADD: string = Reference.MODULE + Reference.TENDER_PARTICIPANT + Actions.ADD;

  static REF_TENDER_PARTICIPANT_EDIT: string = Reference.MODULE + Reference.TENDER_PARTICIPANT + Actions.EDIT;

  static REF_TENDER_PARTICIPANT_DELETE: string = Reference.MODULE + Reference.TENDER_PARTICIPANT + Actions.DELETE;

  static REF_SCIENTIFIC_RANK_EDIT: string = Reference.MODULE + Reference.SCIENTIFIC_RANK + Actions.EDIT;

  static REF_SCIENTIFIC_RANK_DELETE: string = Reference.MODULE + Reference.SCIENTIFIC_RANK + Actions.DELETE;

  static REF_DEGREE_EDIT: string = Reference.MODULE + Reference.DEGREE + Actions.EDIT;

  static REF_DEGREE_DELETE: string = Reference.MODULE + Reference.DEGREE + Actions.DELETE;

  static REF_PARTICIPATION_FORM_ADD: string = Reference.MODULE + Reference.PARTICIPATION_FORM + Actions.ADD;

  static REF_PARTICIPATION_FORM_EDIT: string = Reference.MODULE + Reference.PARTICIPATION_FORM + Actions.EDIT;

  static REF_PARTICIPATION_FORM_DELETE: string = Reference.MODULE + Reference.PARTICIPATION_FORM + Actions.DELETE;

  static REF_KIND_TRADE_ADD: string = Reference.MODULE + Reference.KIND_TRADE + Actions.ADD;

  static REF_KIND_TRADE_EDIT: string = Reference.MODULE + Reference.KIND_TRADE + Actions.EDIT;

  static REF_KIND_TRADE_DELETE: string = Reference.MODULE + Reference.KIND_TRADE + Actions.DELETE;

  static REF_PROJECT_SPECIFIC_ADD: string = Reference.MODULE + Reference.PROJECT_SPECIFIC + Actions.ADD;

  static REF_PROJECT_SPECIFIC_EDIT: string = Reference.MODULE + Reference.PROJECT_SPECIFIC + Actions.EDIT;

  static REF_PROJECT_SPECIFIC_DELETE: string = Reference.MODULE + Reference.PROJECT_SPECIFIC + Actions.DELETE;

  static REF_STAFF_STATE_ADD: string = Reference.MODULE + Reference.STAFF_STATE + Actions.ADD;

  static REF_STAFF_STATE_EDIT: string = Reference.MODULE + Reference.STAFF_STATE + Actions.EDIT;

  static REF_STAFF_STATE_DELETE: string = Reference.MODULE + Reference.STAFF_STATE + Actions.DELETE;

  static REF_LANGUAGE_ADD: string = Reference.MODULE + Reference.LANGUAGE + Actions.ADD;

  static REF_LANGUAGE_EDIT: string = Reference.MODULE + Reference.LANGUAGE + Actions.EDIT;

  static REF_LANGUAGE_DELETE: string = Reference.MODULE + Reference.LANGUAGE + Actions.DELETE;

  /* Разблокировка заблокированных записей */
  static FORCE_UNLOCK: string = 'ForceUnlock';

  static build(...parts: string[]): string {
    return parts.join(this.SEPARATOR);
  }
}

import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { DefaultCustomSelect } from '../DefaultCustomSelect';
import { BuilderProps } from 'features/BuildReportPopup/models';

const FullScienceProjectSelect = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification = {
    modalTableSpecification: GetFullScienceProjectList(),
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      label: row.Name || row.name || '',
      id: row.id || '',
    }),
    relationTableModalTitle: 'Справочник "Проекты"',
  };

  return (
    <DefaultCustomSelect
      parameterName={parameterName}
      placeholder="Проект"
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      modalTableRowConverter={specification.modalTableRowConverter}
      modalTableSpecification={specification.modalTableSpecification}
      relationTableModalTitle={specification.relationTableModalTitle}
    />
  );
};

const Component = memo(FullScienceProjectSelect);

export { Component as FullScienceProjectSelect };

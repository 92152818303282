import React from 'react';
import { setup } from 'bem-cn';

import { Info, RequiredBadge, Tooltip } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('section-title');

type Props = {
  /** @property {string} title - (optional) Text for render */
  title?: string;
  /** @property {string} tooltip - (optional) Add badge with tooltip text */
  tooltip?: string;
  /** @property {JSX.Element} info - (optional) Show info with html */
  info?: JSX.Element;
  /** @property {boolean} isRequired - (optional) Add badge `isRequired` (red star) */
  isRequired?: boolean;
  /** @property {boolean} isTableHeader - (optional) Set simple visible title */
  isTableHeader?: boolean;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {JSX.Element | JSX.Element[]} children - (optional) React inner JSX component */
  children?: JSX.Element | JSX.Element[];
};

/**
 * Title component (styled by default)
 *
 * @param {string} title - (optional) Text for render
 * @param {string} tooltip - (optional) Add badge with tooltip text
 * @param {JSX.Element} info - (optional) Show info with html
 * @param {boolean} isRequired - (optional) Add badge `isRequired` (red star)
 * @param {boolean} isTableHeader - (optional) Set simple visible title
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @returns JSX
 */
export const SectionTitle = React.memo(({ title, tooltip, info, isRequired, isTableHeader = false, style, children }: Props) => {
  return (
    <h3 className={b({ table: isTableHeader })} style={style}>
      <span className={b('inner')}>
        {children || title} {isRequired && <RequiredBadge />} {tooltip && <Tooltip text={tooltip} />}{' '}
        {info && <Info text={info} />}
      </span>
    </h3>
  );
});

import { useState, useLayoutEffect, useCallback, useMemo, useRef, useEffect } from 'react';
import { format, parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Form, Table, Estimate, Report } from 'types/models';
import { Item } from 'types/models/common';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { EstimateLook, Original } from 'types/models/Form';
import { EstimatePosition, EstimatePositionItem } from 'types/models/Estimate';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { GetProjectCodeSimpleSelectList } from 'features/Table/specifications/GetProjectCodeSimpleSelectList';
import { CalendarPlanByStage, CalendarPlanByYear } from 'types/models/Project';
import { formatStr } from 'utils/Constants';
import { useLocalTableStreams } from 'features/Table/hooks';
import { KindConsumption } from 'types/models/KindConsumption';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type EstimatePositionItemExtendedKindConsumption = {
  kindConsumption: KindConsumption;
  quarter: number;
} & EstimatePositionItem;

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, projectCode, setNextPublicationId, saveAndEdit, estimate: initialEstimate, amountWarningMessage, fundCard },
  } = useFormContext<EstimateLook>();

  const estimateId = useRef<string>('');

  const [estimate, setEstimate] = useState<Estimate | null>(null);
  const [estimatePositions, setEstimatePositions] = useState<EstimatePosition[] | []>([]);
  const [createdDate, setCreatedDate] = useState<string | null>(format(new Date(), formatStr));
  const [projectCodeId, setProjectCodeId] = useState<string | null>('');
  const [calendarPlans, setCalendarPlans] = useState<CalendarPlanByStage[] | []>([]);
  const [year, setYear] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [projectCodeOriginal, setProjectCodeOriginal] = useState<Form.Original | null>();
  const [calendarPlansByYear, setCalendarPlansByYear] = useState<CalendarPlanByYear[] | []>([]);
  const [isReceivingDocumentModalOpen, setIsReceivingDocumentModalOpen] = useState<boolean>(false);
  const [isEstimateOverheadModalOpen, setIsEstimateOverheadModalOpen] = useState<boolean>(false);
  const [isProjectCodeInfoModalOpen, setIsProjectCodeInfoModalOpen] = useState<boolean>(false);
  // const [selectedEstimatePositionItemIndex, setSelectedEstimatePositionItemIndex] = useState<number | null>(null);
  const [projectCodeEditShown, setProjectCodeEditShown] = useState<boolean>(true);
  const [yearOptions, setYearOptions] = useState<Item[] | undefined>();
  const [isAmountWarningOpen, setIsAmountWarningOpen] = useState<boolean>(false);

  const projectCodeSpecification = GetProjectCodeSimpleSelectList({ setProjectCodeId });

  const loadYearOptions = useCallback(
    (plans?: CalendarPlanByYear[]) => {
      const targetPlans = plans || calendarPlansByYear;
      const options = targetPlans.map<Item>(x => ({ value: x.year.toString(), label: x.year.toString() }));
      setYearOptions(options);
    },
    [calendarPlansByYear],
  );

  const { methods: createEstimate } = BackendAPI.useBackendAPI('CreateEstimate');

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const { methods: getEstimate } = BackendAPI.useBackendAPI('GetEstimate', {
    onSuccessfullCall: ({ data }) => {
      estimateId.current = data?.id || '';
      setEstimate(data as Estimate);
      setEstimatePositions(data.positions);

      if (data?.projectCode?.id) {
        setProjectCodeId(data?.projectCode?.id);
        setCalendarPlansByYear(data.projectCode.calendarPlansByYears || []);
        loadYearOptions();
      }
    },
    onFailedCall: () => {},
  });

  const onCreateSubmit = useCallback(() => {
    const invalidFields: string[] = [];
    if (!createdDate) invalidFields.push('Дата составления');
    if (projectCodeEditShown && !projectCodeOriginal) invalidFields.push('Шифр проекта');

    if (!!invalidFields.length)
      showNotification({ message: `Заполните поля: ${invalidFields.map(x => `"${x}"`)}`, theme: 'danger' });
    else {
      createEstimate.callAPI(
        {
          projectCodeId: projectCodeId || projectCode?.id || '',
          createdDate,
          year: year || new Date().getFullYear().toString(),
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Смета успешно сохранена', theme: 'success' });
            if (data?.id) {
              if (setNextPublicationId && data.id) {
                setNextPublicationId(data.id);
              }
              if (saveAndEdit) {
                saveAndEdit();
              } else {
                onClose();
              }
              getEstimate.callAPI({ id: data.id });
              updateWorkModeAfterSaveAndContinue();
            }
            tableStreams.reloadTable.push({});
          },
        },
      );
    }
  }, [
    projectCodeEditShown,
    projectCodeOriginal,
    createdDate,
    createEstimate,
    projectCodeId,
    projectCode?.id,
    year,
    tableStreams.reloadTable,
    setNextPublicationId,
    saveAndEdit,
    getEstimate,
    updateWorkModeAfterSaveAndContinue,
    onClose,
  ]);

  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode', {
    onSuccessfullCall: ({ data }) => {
      setCalendarPlans(data.calendarPlansByStages || []);
      setCalendarPlansByYear(data.calendarPlansByYears || []);
      loadYearOptions(data.calendarPlansByYears || []);
    },
  });

  const handleProjectCodeChange = useCallback(
    (fieldName: keyof Estimate) => (value: Original | null) => {
      setEstimate(
        prevState =>
          ({
            ...prevState,
            [fieldName]: value,
          } as Estimate),
      );

      if (value?.id) {
        getProjectCode.callAPI({ id: value.id });
        setProjectCodeOriginal(value);
      }
    },
    [getProjectCode],
  );

  const handleCreateDateChange = useCallback(
    (value: string | null) => {
      setCreatedDate(value);
    },
    [setCreatedDate],
  );

  const projectCodeRowConverter = useCallback<(row: Table.Entry) => Original>(
    row => ({
      id: row.id || '',
      name: row.code,
    }),
    [],
  );

  useEffect(() => {
    if (amountWarningMessage) setIsAmountWarningOpen(true);
  }, [amountWarningMessage]);

  useLayoutEffect(() => {
    if (initialEstimate) setEstimate(initialEstimate);
    else {
      if (id) {
        getEstimate.callAPI({ id });
      }
      if (projectCode) {
        setProjectCodeOriginal({ id: projectCode?.id || '', name: projectCode?.code || '' });
        loadYearOptions(projectCode.calendarPlansByYears);
        setProjectCodeEditShown(false);
      }
    }
    // eslint-disable-next-line
  }, [setCalendarPlansByYear]);

  const handleEstimatePositionChange = useCallback((values: EstimatePosition[]) => {
    setEstimatePositions(values);
  }, []);

  const handleYearChange = useCallback((option: Item | null) => {
    setYear(option?.value || '');
  }, []);

  const receivingDocumentsModalTitle = useCallback(
    () => `Поступления на шифр ${estimate?.projectCode?.code} за ${estimate?.year}`,
    [estimate],
  );

  const handleReceivingDocumentsClick = useCallback(() => {
    setIsReceivingDocumentModalOpen(true);
  }, [setIsReceivingDocumentModalOpen]);

  const handleEstimateOverheadClick = useCallback(() => {
    setIsEstimateOverheadModalOpen(true);
  }, [setIsEstimateOverheadModalOpen]);

  const handleProjectCodeInfoClick = useCallback(() => {
    setIsProjectCodeInfoModalOpen(true);
  }, [setIsProjectCodeInfoModalOpen]);

  const refreshEstimate = useCallback(() => {
    if (id) {
      getEstimate.callAPI({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const estimatePositionItems = useMemo(() => {
    let itemList = [] as EstimatePositionItemExtendedKindConsumption[];

    estimatePositions.forEach(position => {
      const items = (position.items || []).map(i => {
        return {
          ...i,
          kindConsumption: position.kindConsumption,
          quarter: Math.floor(parse(i.date || '', formatStr, new Date()).getMonth() / 3 + 1),
        } as EstimatePositionItemExtendedKindConsumption;
      });
      itemList = [...itemList, ...items];
    });

    // sorting by date
    itemList.sort((a, b) => {
      return parse(a.date || '', formatStr, new Date()).getTime() - parse(b.date || '', formatStr, new Date()).getTime();
    });

    // grouping by quarter
    // const result: EstimatePositionItemExtendedKindConsumption[][] = [];

    // itemList.forEach(item => {
    //   result[item.quarter] = [...(result[item.quarter] || []), item];
    // });

    return itemList;
  }, [estimatePositions]);

  const selectEstimateYear = useCallback(() => {
    if (yearOptions !== undefined && yearOptions?.length > 0) {
      const yearToSelect = year || new Date().getFullYear().toString();
      const option = yearOptions?.find(x => x.value === yearToSelect) || yearOptions[0];
      if ((!year && option) || option.value !== year) {
        setYear(option.value);
      }
      return option;
    }
    return { value: '', label: '' } as Item;
  }, [year, setYear, yearOptions]);

  const reports = useMemo<Report[]>(() => [Reports.ProjectEstimateByCodeForViu], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: workMode === 'addMode' ? onCreateSubmit : onClose,
      },
      {
        icon: { type: 'info' },
        title: 'Информация о шифре',
        onClick: handleProjectCodeInfoClick,
        isHidden: workMode === 'addMode',
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleProjectCodeInfoClick, handleSetCurrentReport, onClose, onCreateSubmit, workMode],
  );

  return {
    estimateId: id ?? estimateId.current ?? null,
    refreshEstimate,
    estimate,
    projectCodeId,
    createdDate,
    handleCreateDateChange,
    workMode,
    handleEstimatePositionChange,
    estimatePositions,
    estimatePositionItems,
    projectCodeOriginal,
    setProjectCodeOriginal,
    projectCodeSpecification,
    projectCodeRowConverter,
    handleProjectCodeChange,
    year,
    yearOptions,
    calendarPlans,
    handleYearChange,
    selectedRowIndex,
    setSelectedRowIndex,
    isReceivingDocumentModalOpen,
    setIsReceivingDocumentModalOpen,
    handleReceivingDocumentsClick,
    receivingDocumentsModalTitle,
    isEstimateOverheadModalOpen,
    setIsEstimateOverheadModalOpen,
    handleEstimateOverheadClick,
    isProjectCodeInfoModalOpen,
    setIsProjectCodeInfoModalOpen,
    selectEstimateYear,
    projectCodeEditShown,
    setYear,
    // selectedEstimatePositionItemIndex,
    // setSelectedEstimatePositionItemIndex,
    isAmountWarningOpen,
    setIsAmountWarningOpen,
    amountWarningMessage,
    fundCard,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

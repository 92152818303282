import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function PaymentOrderGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список приходных документов бухгалтерского учёта, дополненный данными о проектах</h4>

        <p>
          Таблица содержит консолидированный список Приходных документов бухгалтерского учёта и дополнительные столбцы с данными
          из шифров и проектов для анализа данных, отчётности по объёмам выполненных НИОКР, в которой необходимо указать сведения
          о документах бухучёта.
        </p>

        <div className="warning">
          <span>
            Функциональная кнопка <strong>Выгрузить в файл</strong> <Icon type="excel" /> формирует файл .xls с таблицей «как
            есть». Пользователь может сохранить файл и использовать его в качестве основы для оформления отчёта по запросу.
          </span>
        </div>

        <p>
          Фильтр <strong>Год</strong> – отображает список документов с датой, соответствующей указанному году.
        </p>

        <p>Основные столбцы таблицы содержат данные документа бухучёта.</p>
        <p>
          Дополнительные столбцы — данные шифра проекта и проекта, названия столбцов соответствуют названиям полей на форме
          проекта.
        </p>

        <h4>Особенности вывода информации в столбцах таблицы:</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название столбца</th>
              <th>Описание значения в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Объём финансирования</td>
              <td>
                Общая сумма по всем источникам финансирования из <strong>Календарного плана проекта</strong>. При включенном
                фильтре <strong>Год</strong>, отображается объём финансирования на указанный год (из раздела{' '}
                <strong>Распределение по годам</strong>).
              </td>
            </tr>
            <tr>
              <td>Дата документа</td>
              <td>Дата документа бухучёта.</td>
            </tr>
            <tr>
              <td>Номер документа</td>
              <td>Номер документа бухучёта.</td>
            </tr>
            <tr>
              <td>Сумма документа</td>
              <td>Сумма (руб.) документа бухучёта</td>
            </tr>
            <tr>
              <td>НДС</td>
              <td>
                Атрибут источника финансирования Шифра проекта, связанного с документом. Заполняется в разделе в разделе
                «Финансирование» на форме проекта
              </td>
            </tr>
            <tr>
              <td>Организация</td>
              <td>
                Название контрагента (юридическое или физическое лицо) в документе. Список контрагентов из информационной системы
                бухгалтерского учёта храниться <strong>в справочнике Заказчики</strong>
              </td>
            </tr>
            <tr>
              <td>Адрес</td>
              <td>
                Адрес контрагента в документе. Значение выбирается из{' '}
                <strong>
                  справочника Заказчики. Если это необходимо, то адрес можно отредактировать/добавить именно в этом справочнике.
                </strong>
              </td>
            </tr>
            <tr>
              <td>ИНН Заказчика</td>
              <td>
                ИНН организации в роли Заказчика проекта. Значение ИНН выбирается из{' '}
                <strong>
                  справочника Заказчики. Если это необходимо, то можно отредактировать/добавить именно в этом справочнике.
                </strong>
              </td>
            </tr>
            <tr>
              <td>Шифр</td>
              <td>Номер шифра проекта, с которым связан документ.</td>
            </tr>
            <tr>
              <td>Источник финансирования</td>
              <td>Источник финансирования шифра проекта, с которым связан документ.</td>
            </tr>
            <tr>
              <td>Тип проекта</td>
              <td>Характеристика проекта шифр, с которым связан документ.</td>
            </tr>
            <tr>
              <td>Тема проекта</td>
              <td>Название темы проекта - из одноимённого поля проекта</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта — из одноимённого поля проекта</td>
            </tr>
            <tr>
              <td>Код номера проекта</td>
              <td>
                Дополнительный код для номера проекта, используется для группировки проектов по аналитическому признаку. Поле не
                обязательное.
              </td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>Дата начала проекта</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>Дата начала проекта</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО руководителя проекта</td>
            </tr>
            <tr>
              <td>Характер проекта</td>
              <td>Характеристика проекта - из одноимённого поля проекта на вкладке</td>
            </tr>
            <tr>
              <td>Факультет/Институт</td>
              <td></td>
            </tr>
            <tr>
              <td>Подразделение</td>
              <td></td>
            </tr>
            <tr>
              <td>Вид договора</td>
              <td>Вид договора — из одноимённого поля проекта на вкладке О проекте</td>
            </tr>
            <tr>
              <td>Номер договора</td>
              <td>Номер договор — из одноимённого поля проекта на вкладке О проекте.</td>
            </tr>
            <tr>
              <td>Дата договора</td>
              <td>Дата договора— из одноимённого поля проекта на вкладке О проекте.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>Краткое название программы — из одноимённого поля проекта на вкладке О проекте. Не обязательное поле.</td>
            </tr>
            <tr>
              <td>Мероприятие</td>
              <td>
                Краткое название мероприятия программы — из одноимённого поля проекта на вкладке О проекте. Не обязательное поле.
              </td>
            </tr>
            <tr>
              <td>ПНР &lt;Университета&gt;</td>
              <td>
                Название приоритетного(ных) направления (ий) развития университета — из одноимённого раздела проекта на вкладке
                Классификаторы.
              </td>
            </tr>
            <tr>
              <td>Критические технологии</td>
              <td>Название критической технологии — из одноимённого раздела проекта на вкладке Описание.</td>
            </tr>
            <tr>
              <td>Приоритеты Стратегии НТР</td>
              <td>Название приоритета Стратегии НТР — из одноимённого раздела проекта на вкладке Описание.</td>
            </tr>
            <tr>
              <td>ГРНТИ</td>
              <td>Список кодов ГРНТИ — из одноимённого раздела проекта на вкладке Описание.</td>
            </tr>
            <tr>
              <td>ОКВЭД</td>
              <td>Код. Название кода внешнеэкономической деятельности — из раздела 2-Наука на вкладке проекта Описание.</td>
            </tr>
            <tr>
              <td>ЛКСЭЦ</td>
              <td>Код. Название кода внешнеэкономической деятельности — из раздела 2-Наука на вкладке проекта Описание.</td>
            </tr>
            <tr>
              <td>Отрасли науки</td>
              <td>Список названий отраслей науки — из одноимённого раздела проекта на вкладке Классификаторы.</td>
            </tr>
            <tr>
              <td>ПНР науки, технологий и техники</td>
              <td>Приоритетные направления науки, технологий и техники — из одноимённого раздела проекта на вкладке Описание.</td>
            </tr>
            <tr>
              <td>Группа отраслей РНФ</td>
              <td>
                Название группы отраслей по классификации РНФ— атрибут отрасли знаний из одноимённого поля проекта на вкладке
                Классификаторы.
              </td>
            </tr>
            <tr>
              <td>Подгруппа отраслей РНФ</td>
              <td>
                Название подгруппы отраслей по классификации РНФ— атрибут отрасли знаний из одноимённого поля проекта на вкладке
                Классификаторы.
              </td>
            </tr>
            <tr>
              <td>Отрасли знаний (РНФ)</td>
              <td>Название отрасли знаний по классификации РНФ—из одноимённого поля проекта на вкладке Классификаторы.</td>
            </tr>
            <tr>
              <td>Технологические платформы</td>
              <td>Название технологической платформы — из одноимённого раздела проекта на вкладке Классификаторы.</td>
            </tr>
            <tr>
              <td>Классификатор ОЭСР (ОЕСD)</td>
              <td>
                Название отрасли наук по международной классификации отраслей наук — из одноимённого раздела проекта на вкладке
                Описание.
              </td>
            </tr>
            <tr>
              <td>Научные специальности</td>
              <td>
                Код. Название научной специальности — из раздела Номенклатура научных специальностей, по которым присуждаются
                ученые степени (Специальности ВАК) на вкладке проекта Классификаторы.
              </td>
            </tr>
            <tr>
              <td>Рынки НТИ</td>
              <td>Название рынка Научно-технической инициативы— из одноимённого раздела проекта на вкладке Классификаторы.</td>
            </tr>
            <tr>
              <td>Технологии НТИ</td>
              <td>
                Название технологии Научно-технической инициативы — из одноимённого раздела проекта на вкладке Классификаторы.
              </td>
            </tr>
            <tr>
              <td>ПНР техразвития экономики РФ</td>
              <td>
                Приоритетные направления развития модернизации и технического развития экономики России — из одноимённого раздела
                проекта на вкладке Классификаторы.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { MobileKindConsumption } from 'types/models/KindConsumption';
import { getMockMobileKindConsumption } from './helpers';

type Props = {
  onClose(): void;
};

export function useController({ onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const [kindConsumption, setKindConsumption] = useState<MobileKindConsumption>(getMockMobileKindConsumption());

  const { methods: getMobileKindConsumption } = BackendAPI.useBackendAPI('GetMobileKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setKindConsumption(data);
    },
  });

  const { methods: saveMobileKindConsumption } = BackendAPI.useBackendAPI('SaveMobileKindConsumption', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись сохранена', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback((): boolean => {
    if (!kindConsumption.name || !kindConsumption.code || !kindConsumption.kindConsumption) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return false;
    }

    saveMobileKindConsumption.callAPI(kindConsumption);
    return true;
  }, [saveMobileKindConsumption, kindConsumption]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  useLayoutEffect(() => {
    if (id) getMobileKindConsumption.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    kindConsumption,
    setKindConsumption,
    handleFormSubmit,
  };
}

export function PersonalSecurityDocumentGridHelp(isRequestMode?: boolean): JSX.Element {
  return (
    <>
      {!!isRequestMode ? (
        <>
          <p>
            Федеральная служба по интеллектуальной собственности (Роспатент) и Федеральный институт промышленной собственности
            (ФИПС) – это госорганы, защищающие интеллектуальные права авторов в России.
          </p>
          <p>
            Раздел содержит список заявок, поданных университетом на изобретения, промышленные образцы, базы данных, программные
            продукты и прочие объекты, в которых вы указаны в составе авторского коллектива.
          </p>
          <p>После получения охранного документа (патента, свидетельства) - заявка в списке не отображается.</p>
          <p>
            Сведения о полученных охранных документах отображаются в разделе <strong>Мои ОИС</strong>. Университет является
            правообладателем охранного документа.
          </p>
        </>
      ) : (
        <>
          <p>
            Раздел содержит список патентов, свидетельств и ноу-хау на изобретения, промышленные образцы, базы данных, программные
            продукты и прочие объекты, в которых вы указаны в составе авторского коллектива. Университет является правообладателем
            охранного документа.
          </p>
        </>
      )}

      <p>
        Сведения о ваших патентах и свидетельствах, в которых университету не принадлежат право собственности, вы можете добавить
        в разделе <strong>ОИС не аффилированные с Университетом</strong>.
      </p>
    </>
  );
}

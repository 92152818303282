import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { Event, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { EventTypes } from 'utils/Enums/EventTypes';
import { EventTypeCode, Participations, TypeControllerIsForm } from 'types/models/Event';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { DetailProps } from '..';
import { EventType } from './eventTypes';
import { getEventPermitList, isEventPermitted } from 'utils/Helpers/event';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
  actualType: EventType;
};

export function useController({ tableState, actualType }: Props & DetailProps) {
  const { settings, userPermission } = useAppDataContext();
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();
  const tableStreams = useLocalTableStreams();

  const organizationName = settings?.organization?.shortName;

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);

  const selectedRowsLength = tableState.selectedRows.length;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [eventTypeCode, setEventTypeCode] = useState<EventTypeCode>(EventTypes.EVENT_EXPOSITION.code);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [sourceEvent, setSourceEvent] = useState<Event.Event | null>(null);
  const [participation, setParticipation] = useState<Participations | null>(null);
  const [sourcePublication, setSourcePublication] = useState<Event.Source | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const { methods: deleteEventAPI } = BackendAPI.useBackendAPI('DeleteEvent', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись удалена', theme: 'success' });
      handleCloseDeleteConfirmPopup();
      tableStreams.reloadTable.push({});
    },
  });

  const onCloseParticipationForm = () => {
    setSourcePublication(null);
  };

  const controllerIsForm: TypeControllerIsForm = {
    sourceEvent,
    participation,
    isView: isViewParticipationFormOpen,
    isEdit: isEditParticipationFormOpen,
    isAdd: isAddParticipationFormOpen,
    isDelete: isDeletePopup,
    setParticipation,
    sourcePublication,
    setSourcePublication,
    onCloseParticipationForm,
    setAdd: setIsAddParticipationFormOpen,
    setEdit: setIsEditParticipationFormOpen,
    setView: setIsViewParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setSourceEvent,
  };

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteEventAPI.callAPI({ eventId: tableState.selectedRows[0].id });
  }, [deleteEventAPI, tableState.selectedRows]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setEventTypeCode(actualType.code);
    setNextPublicationName('');
    openDuplicatesForm();
  }, [setEventTypeCode, actualType, setNextPublicationName, openDuplicatesForm]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.EXPOSITION_VIEW },
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.EXPOSITION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: {
          name: getEventPermitList('EXPOSITION', 'EDIT'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: {
          name: getEventPermitList('EXPOSITION', 'DELETE'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'DELETE'),
      },
    ],
    [
      handleHelpButtonClick,
      handleViewButtonClick,
      selectedRowsLength,
      handleAddButtonClick,
      handleEditButtonClick,
      userPermission,
      tableState.selectedRows,
      handleDeleteButtonClick,
    ],
  );

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    eventTypeCode,
    buttons,
    handleViewButtonClick,
    handleAddButtonClick,
    handleEditButtonClick,
    handleDeleteButtonClick,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    organizationName,
  };
}

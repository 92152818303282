import { PersonHistory } from 'types/models';

export function clonePersonHistory(oldPersonHistory: PersonHistory): PersonHistory {
  const newPersonHistory: PersonHistory = {
    academicRank: oldPersonHistory.academicRank?.originalId ? { ...oldPersonHistory.academicRank, id: null } : null,
    citizenship: oldPersonHistory.citizenship,
    degree: oldPersonHistory.degree?.originalId ? { ...oldPersonHistory.degree, id: null } : null,
    education: oldPersonHistory.education?.originalId ? { ...oldPersonHistory.education, id: null } : null,
    historyLabel: oldPersonHistory.historyLabel,
    job: oldPersonHistory.job?.originalId ? { ...oldPersonHistory.job, id: null } : null,
    rank: oldPersonHistory.rank?.originalId ? { ...oldPersonHistory.rank, id: null } : null,
  };
  return newPersonHistory;
}

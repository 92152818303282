import { useMemo } from 'react';

import { ButtonProps } from 'components';

import { ExtraToolbarButton, Mode } from 'components/ListEdit/model';

type Props<Row> = {
  mode: Mode;
  modalTitle: string | ((row: Row | null, mode: Mode) => string);
  isCheck?: boolean;
  customEditRow: Row | null;
  submitCustomComponent(val?: boolean): void;
  isSaveAndAddCustomComponent?: boolean;
  saveAndAddCustomComponent?: () => void;
  extraFieldsToolbarButtons: ExtraToolbarButton<Row>[];
  selectedRowIndex: number | null;
  rows: Row[];
};

export function useController<Row>(props: Props<Row>) {
  const {
    mode,
    modalTitle,
    isCheck,
    customEditRow,
    submitCustomComponent,
    isSaveAndAddCustomComponent,
    saveAndAddCustomComponent,
    extraFieldsToolbarButtons,
    selectedRowIndex,
    rows,
  } = props;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      ...(isCheck
        ? [
            {
              icon: { type: 'check' },
              title: 'Выбрать',
              onClick: () => {
                submitCustomComponent();
              },
              isDisabled: !customEditRow,
            },
          ]
        : [
            {
              icon: { type: 'save' },
              title: 'Сохранить',
              onClick: () => {
                submitCustomComponent();
              },
            },
          ]),
      ...(isSaveAndAddCustomComponent
        ? [
            {
              icon: { type: 'save', mode: 'add' },
              title: 'Сохранить и продолжить',
              onClick: saveAndAddCustomComponent,
            },
          ]
        : []),
      ...extraFieldsToolbarButtons.map<ButtonProps>(item => ({
        ...item,
        onClick: () => item.onClick(customEditRow, selectedRowIndex, rows),
        isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled?.(customEditRow) : item.isDisabled,
      })),
    ],
    [
      isCheck,
      customEditRow,
      isSaveAndAddCustomComponent,
      saveAndAddCustomComponent,
      extraFieldsToolbarButtons,
      submitCustomComponent,
      selectedRowIndex,
      rows,
    ],
  );

  const modalDialogTitle = useMemo(() => {
    if (typeof modalTitle === 'function') {
      return modalTitle(customEditRow, mode);
    }
    return modalTitle;
  }, [mode, modalTitle, customEditRow]);

  return {
    buttons,
    modalDialogTitle,
  };
}

import { Table as T } from 'types/models';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Toolbar } from './Toolbar';

export type DetailProps = {
  scientistId?: string;
};
export function GetScientistColleagueList({ scientistId }: DetailProps): T.Specification<State> {
  return {
    apiID: 'GetScientistColleagueList',
    isFiltersHidden: true,
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: {
      scientistId,
    },
    useCustomController: makeUseCustomController({ scientistId }),
  };
}

import { Table } from 'types/models';

type Props = {
  isCurrent?: boolean | null;
};

type Arguments = {
  isCurrent?: boolean | null;
};

export function GetPatentForIncentivePayment(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetPatentForIncentivePayment',
    requestData: args,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

import { useMemo, useState, useLayoutEffect, useCallback } from 'react';

import { useAppDataContext } from 'features/AppData/context';
import { Table, Parameter } from 'types/models';
import { Parameters } from 'features/BuildReportPopup/Parameters';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const { enumMap } = useAppDataContext();

  const [values, setValues] = useState<Table.EnumValue[]>([]);
  const [currentValueKey, setCurrentValueKey] = useState<string>('');

  const enumParameter = useMemo<Parameter | null>(() => parameters.find(parameter => parameter.name === parameterName) || null, [
    parameters,
    parameterName,
  ]);
  const canBuild = useMemo<boolean>(() => !!enumParameter, [enumParameter]);

  const currentValue = useMemo(() => values.find(value => value.value === currentValueKey), [values, currentValueKey]);

  const setParameterValue = useCallback(
    ({ nextValueKey, usedValues }: { nextValueKey?: string; usedValues?: Table.EnumValue[] }) => {
      const usedValueKey = nextValueKey || currentValueKey;
      const usedValue = (usedValues || values).find(value => value.value === usedValueKey);

      if (usedValue) {
        const calcValue = () => {
          // FIXME: Тут все плохо.
          // Для публикаций есть 3 статуса 0-черновик, 1-добавлена, 2-утверждена
          // Для мероприятий есть 2 статуса 1-утверждена, 2-добавлена
          // В отчете нужно сделать выбор статуса: "Все", "Добавлена", "Утверждена"
          // Для этого сделан enum "TReportRecordStatus" со значениями 0-все, 1-утверждена, 2-добавлена
          // Используется этот enum и для публикаций и для мероприятий (см. ReportParameters.as)
          // В дальнейшем от этого можно избавиться, разделив enum "TReportRecordStatus" на два
          // "TReportPublicationRecordStatus" и "TReportEventRecordStatus" и
          // выделить два типа параметров PUBLICATION_RECORD_STATUS_ENUM и EVENT_RECORD_STATUS_ENUM
          // и использовать enumParameterCollector, а в отчетах разруливать 0 по другому
          let id = usedValue.ordinal.toString();
          if (parameterName === Parameters.PublicationRecordStatus) {
            if (id === '1') {
              id = '2';
            } else if (id === '2') {
              id = '1';
            }
          }
          if (parameterName === Parameters.PatentRecordStatus) {
            if (id === '0') {
              id = '0,1,2,3';
            } else {
              id = (usedValue.ordinal - 1).toString();
            }
          }
          if (
            parameterName !== Parameters.AuthorRequestStatus &&
            parameterName !== Parameters.EmployeeRequestStatus &&
            parameterName !== Parameters.PatentRecordStatus &&
            parameterName !== Parameters.ReportRecordStatus
          ) {
            if (id === '0') {
              id = '1,2';
            }
          }

          return id;
        };
        const id = calcValue();
        setParameterValueByName({ name: parameterName, value: id });
      }
    },
    [currentValueKey, parameterName, setParameterValueByName, values],
  );

  useLayoutEffect(() => {
    let enumName = '';
    let defaultKey = '';

    switch (parameterName) {
      case Parameters.PublicationRecordStatus:
        enumName = 'ReportRecordStatus';
        defaultKey = 'APPROVED';
        break;
      case Parameters.ReportRecordStatus:
        enumName = 'ReportRecordStatus';
        defaultKey = 'APPROVED';
        break;
      case Parameters.EventRecordStatus:
        enumName = 'ReportRecordStatus';
        defaultKey = 'APPROVED';
        break;
      case Parameters.PatentRecordStatus:
        enumName = 'ReportSecurityDocumentRequestStatus';
        defaultKey = 'REGISTERED';
        break;
      case Parameters.AuthorRequestStatus:
        enumName = 'AuthorPaymentRequestStatus';
        defaultKey = 'PAID';
        break;
      case Parameters.EmployeeRequestStatus:
        enumName = 'PaymentRequestStatus';
        defaultKey = 'PAID';
        break;
    }

    const enumByName = enumMap[enumName];
    if (enumByName) {
      setCurrentValueKey(defaultKey);
      setValues(enumByName.values || []);
      setParameterValue({ nextValueKey: defaultKey, usedValues: enumByName.values || [] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNextValueKey = useCallback(
    (nextValueLabel: string) => {
      const nextValue = values.find(i => i.value === nextValueLabel);

      if (nextValue) {
        setParameterValue({ nextValueKey: nextValue.value });
        setCurrentValueKey(nextValue.value);
      }
    },
    [values, setParameterValue],
  );

  const selectTitle = useMemo<string>(() => enumParameter?.description || 'Статус записи', [enumParameter]);

  return {
    canBuild,
    currentValue,
    setNextValueKey,
    selectTitle,
    enumParameter,
    values,
  };
};

export default useController;

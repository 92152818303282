import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function SalaryInsuranceGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Таблица содержит данные, загруженные из информационной системы расчёта заработной платы по страховым взносам,
          начисленных на выплаты за счёт средств проектов членам коллективов.
        </p>
        <p>
          Фильтр <strong>Год</strong> – отображает список страховых взносов, в указанный в фильтре год начисленных на выплаты за
          счёт средств проектов членам коллективов.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта.</td>
            </tr>
            <tr>
              <td>Дата начала проекта</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Дата окончания проекта</td>
              <td>Дата окончания проекта.</td>
            </tr>
            <tr>
              <td>ФИО</td>
              <td>
                Фамилия, имя и отчество персоны, для которой начислены выплаты из средств проекта (источник выплаты — шифр
                проекта)
              </td>
            </tr>
            <tr>
              <td>Шифр</td>
              <td>Шифр проекта, который указан в качестве источника для выплат</td>
            </tr>
            <tr>
              <td>Статус</td>
              <td>
                Статусы персоны: «Утверждена» — если персона проверена и подтверждена в списках участников коллектива; «На
                согласование» — персону необходимо проверить в составе участников коллектива и утвердить или перенести на другой
                шифр
              </td>
            </tr>
            <tr>
              <td>Назначение для оплаты</td>
              <td>Название должности, доля ставки, которые указаны в ИС бухгалтерского учёта при начислении зарплаты</td>
            </tr>
            <tr>
              <td>Начислено, всего</td>
              <td>Общая сумма по всем видам начисления</td>
            </tr>
            <tr>
              <td>Удержано, всего</td>
              <td>Общая сумма удержаний</td>
            </tr>
            <tr>
              <td>СВ сумма</td>
              <td>Общая сумма страховых взносов</td>
            </tr>
            <tr>
              <td>Начислено + СВ</td>
              <td>Общая сумма по всем видам начисления + Общая сумма страховых взносов за год</td>
            </tr>
            <tr>
              <td>СВ %</td>
              <td>Вычисляется по формуле: СВ_Сумма/Начислено, всего*100%</td>
            </tr>
            <tr>
              <td>Приведённая к ставке</td>
              <td>Вычисляется по формуле:</td>
            </tr>
            <tr>
              <td>Кол. мес.</td>
              <td>Количество месяцев с начислением зпл в году</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <p>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</p>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись со страховым взносом. Открывает форму с данными о проекте для
                данных по страховому взносу. Состав формы зависит от наличия у пользователя{' '}
                <strong>прав на просмотр вкладок</strong> формы.
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

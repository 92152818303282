import { ForeignSecurityDocument } from 'types/models';
import { SecurityDocumentMember } from 'types/models/SecurityDocument';

import { getHistoricalSliceXML } from 'services/BackendAPI/configurations/author/requestConverters';
import { convertDocumentToRequest, getMaybeField } from '../commonConverters';

export function convertForeignSecurityDocumentToXML(secDoc: ForeignSecurityDocument) {
  return {
    _attr: secDoc.id ? { id: secDoc.id } : {},
    ...(secDoc.type?.value ? { Type: secDoc.type?.value } : {}),
    ...(secDoc.name ? { Name: secDoc.name } : {}),
    ...(secDoc.number ? { Number: secDoc.number } : {}),
    ...(secDoc.startDate ? { StartDate: secDoc.startDate } : {}),
    ...(secDoc.endDate ? { EndDate: secDoc.endDate } : {}),
    ...(secDoc.kind?.id ? { Kind: { _attr: { id: secDoc.kind?.id } } } : {}),
    Members: { Member: secDoc.members.map(m => convertMemberToXML(m)) },
    Documents:
      secDoc.documents && secDoc.documents.length
        ? { Document: secDoc.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
    ...getMaybeField('Authors', secDoc.authors),
    ...getMaybeField('DocumentNumber', secDoc.documentNumber),
    ...getMaybeField('DocumentStartDate', secDoc.documentStartDate),
    ...getMaybeField('IntellectualPropertyName', secDoc.intellectualPropertyName),
    ...getMaybeField('Applicants', secDoc.applicants),
    ...getMaybeField('Contract', secDoc.contract),
  };
}
export function convertMemberToXML(m: SecurityDocumentMember) {
  return {
    _attr: {
      ...(m.id ? { id: m.id } : {}),
    },
    Type: m.type?.value,
    ...(!m.enterprise?.id
      ? {
          ...(m.person ? { Scientist: { _attr: { id: m.person.id } } } : {}), // temp
          ...getHistoricalSliceXML(m),
        }
      : { Enterprise: { _attr: { id: m?.enterprise?.id } } }),
    Contribution: m.contribution && m.contribution !== 0 ? m.contribution : '0.0',
  };
}

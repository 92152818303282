import React from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
// eslint-disable-next-line max-len
import { Component as PublicationViewTemplate } from 'features/Table/specifications/GetPublicationExtendedList/LeftPanelForThirdLevel/modalTemplates/PublicationViewTemplate';
import { useController } from './controller';
import { PublicationForParticipationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen, isViewFormOpen, setIsViewFormOpen, selectedRow } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!isViewFormOpen && (
        <PublicationViewTemplate
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
          publicationType={selectedRow?.['id:Type']}
        />
      )}

      <Modal mode="help" title="Помощь" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="medium">
        {PublicationForParticipationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

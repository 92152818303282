import React from 'react';

import { FormComponent, Select, SelectMode } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function RecognitionForm({ onClose }: Props) {
  const { formFields, recognitionInfo, saveFormSubmit } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveFormSubmit} />

      {!!recognitionInfo && (
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Награжден:">
              <FormComponent.Text>
                {recognitionInfo.person?.shortName} {recognitionInfo.historyLabel}
              </FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Награда:">
              <FormComponent.Text>{recognitionInfo.award.label}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.department.title} isRequired={formFields.department.required}>
              <Select
                mode={SelectMode.REFERENCE}
                value={formFields.department.value}
                onChange={formFields.department.onChange}
                settings={{
                  name: 'RefDepartment',
                  title: 'Справочник "Подразделения"',
                  isClearable: !formFields.department.required,
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      )}
    </FormComponent.Template>
  );
}

export const Component = React.memo(RecognitionForm);

import { ReferenceItem } from 'components';

import { Item } from 'types/models/common';

export enum FieldType {
  STRING = 'STRING',
  TEXT = 'TEXT',
  INTEGER = 'INTEGER',
  DATE = 'DATE',
  BOOLEAN = 'BOOLEAN',
  COLLECTION = 'COLLECTION',
  REFERENCE = 'REFERENCE',
  ENUMERATION = 'ENUMERATION',
}

export enum RelationType {
  ONE_TO_MANY = 'ONE_TO_MANY',
  MANY_TO_MANY = 'MANY_TO_MANY',
}

export type CollectionItem = {
  caption: string;
  id: string;
};

export type CollectionValue = {
  analogId: string | null;
  selectedIds: string[];
};

export type EnumerationValue = Item<string>;

export type FieldValue = string | boolean | ReferenceItem | null | CollectionValue | EnumerationValue;

export type Field = {
  name: string;
  type: FieldType;
  caption: string;
  isEditable: boolean;
  isForceEditable: boolean;
  isRequired: boolean;
  isConflicted: boolean;
  value: FieldValue;
  relationType?: RelationType;
  enumeration?: string;
  reference?: string;
  collection?: CollectionItem[];
};

export type Entity = {
  id: string;
  index: number;
  fields: Field[];
};

export type Merge = {
  commonId: string | null;
  isConflicts: boolean;
  metadata: Field[];
  common: Field[];
  entities: Entity[];
};

import { useCallback, useLayoutEffect, useState, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Parameter, ParameterValue } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import validateAbstractBoolean from './builders/AbstractBoolean/validate';
import abstractBooleanQueryBuilder from './builders/AbstractBoolean/queryBuilder';
import validateAbstractInteger from './builders/AbstractInteger/validate';
import abstractIntegerQueryBuilder from './builders/AbstractInteger/queryBuilder';
import validateAbstractString from './builders/AbstractString/validate';
import abstractStringQueryBuilder from './builders/AbstractString/queryBuilder';
import { Parameters } from './Parameters';
import { builders } from './builders/builders';
import { BuilderModel, PropsValues, ValidatorResult } from './models';
import { PARAMETER_TYPE } from './helpers';
import { downloadFile } from 'utils/Helpers';

type Props = {
  reportName: string;
  onClose: () => void;
  values: PropsValues;
};

const useController = ({
  reportName,
  onClose,
  values: {
    scientist,
    scientistId,
    nirRequestId,
    requestId,
    projectId,
    partnerId,
    stageId,
    securityDocumentId,
    requestType,
    defaultRequestStatus,
    requestIds,
    authorRequestStatus,
    requestStatus,
    userDepartment,
    paymentRequestPeriod,
    department,
    stages,
    publicationTypes,
    regulationParagraph,
    event,
    isEventParameterHidden,
    requestTypeLabel,
    mobileRequestId,
    projectActId,
    contestRequestId,
    exhibitionEventId,
    contestEventId,
    conferenceEventId,
    mobileProtocolId,
    tenderProtocolId,
    projectCodeId,
    purchaseRequestId,
    projectPerformerOrderId,
    nirRequestStatus,
    startYear,
    endYear,
  },
}: Props) => {
  const { methods: getReportParameters, state: getReportParametersState } = BackendAPI.useBackendAPI('GetReportParameters');
  const isReportParametersLoading = getReportParametersState.kind === 'pending';

  const { userToken, initialOrganizationCode } = useAppDataContext();
  const [isHasManual, setIsHasManual] = useState<boolean | null>(null);
  const [parameters, setParameters] = useState<Parameter[]>([]);
  const [errorText, setErrorText] = useState<string>('');
  const [isErrorPopupOpen, setIsErrorPopupOpen] = useState<boolean>(false);

  const builderList = useMemo<BuilderModel[]>(
    () => [
      builders[Parameters.PrintFormat],
      builders[Parameters.StartDate],
      builders[Parameters.EndDate],
      builders[Parameters.PaymentRequestPeriod],
      builders[Parameters.DateRange],
      builders[Parameters.Scientist],
      builders[Parameters.ScientistId],
      builders[Parameters.Year],
      builders[Parameters.year],
      builders[Parameters.reportYear],
      builders[Parameters.ReportYear],
      builders[Parameters.LowYear],
      builders[Parameters.HightYear],
      builders[Parameters.YoungScientists],
      builders[Parameters.ProjectTypes],
      builders[Parameters.PublicationRecordStatus],
      builders[Parameters.ReportRecordStatus],
      builders[Parameters.EventRecordStatus],
      builders[Parameters.PatentRecordStatus],
      builders[Parameters.AuthorRequestStatus],
      builders[Parameters.EmployeeRequestStatus],
      builders[Parameters.EventLevelStatus],
      builders[Parameters.NotRequiredDepartmentWithMode],
      builders[Parameters.NotRequiredUserDepartment],
      builders[Parameters.NotRequiredExpositionType],
      builders[Parameters.NotRequiredAuthorRequestStatus],
      builders[Parameters.NotRequiredRequestType],
      builders[Parameters.NotRequiredDepartment],
      builders[Parameters.NotRequiredStages],
      builders[Parameters.NotRequiredPublicationType],
      builders[Parameters.NotRequiredRequestStatus],
      builders[Parameters.NotRequiredNirRequestStatus],
      builders[Parameters.NotRequiredRegulationParagraph],
      builders[Parameters.PersonStatus],
      builders[Parameters.DropdownPresetYear],
      builders[Parameters.RequestIds],
      builders[Parameters.SecurityDocumentId],
      builders[Parameters.EventId],
      builders[Parameters.ContestEventId],
      builders[Parameters.ExhibitionEventId],
      builders[Parameters.ConferenceEventId],
      builders[Parameters.ProjectID],
      builders[Parameters.PartnerID],
      builders[Parameters.ContestRequestId],
      builders[Parameters.TenderProtocolId],
      builders[Parameters.NotRequiredDocumentTypes],
      builders[Parameters.FinTypeList],
      builders[Parameters.ProjectCode],
      builders[Parameters.DepartmentsList],
      builders[Parameters.StartYear],
      builders[Parameters.EndYear],
      builders[Parameters.NotRequeredProgramList],
      builders[Parameters.NotRequeredProgramEventList],
      ...(initialOrganizationCode?.code === 'TSU' ? [builders[Parameters.ProjectCodeLetter]] : []),
      builders[Parameters.IncludeTransitoryBalance],
      builders[Parameters.NirRequestId],
      builders[Parameters.RequestId],
      builders[Parameters.request],
      builders[Parameters.tender],
      builders[Parameters.RequestStatusList],
      builders[Parameters.ProgramsList],
      builders[Parameters.GrantTypeList],
      builders[Parameters.mipId],
      builders[Parameters.MobileRequestId],
      builders[Parameters.MobileProjectId],
      builders[Parameters.ByDissertationCouncilReportType],
      builders[Parameters.program],
      builders[Parameters.IsAccepted],
      builders[Parameters.MobileRequestId],
      builders[Parameters.SingleFaculty],
      builders[Parameters.ProjectActId],
      builders[Parameters.MobileProtocolId],
      builders[Parameters.ProjectCode],
      builders[Parameters.ApproverStamp],
      builders[Parameters.Visa],
      builders[Parameters.PurchaseRequestId],
      builders[Parameters.ProjectPerformerOrderId],
    ],
    [initialOrganizationCode?.code],
  );

  const showManual = useCallback(() => {
    if (!isHasManual) {
      return;
    }

    const query = `${reportName}/manual`;
    downloadFile(`/report/service/report/${query}`, userToken, true);

    console.warn('print params', query);
  }, [isHasManual, reportName, userToken]);

  const startPrint = useCallback(() => {
    const promptingParameters = parameters.filter(({ prompting }) => prompting);
    const filteredParameters = parameters.filter(({ name, prompting }) => name !== Parameters.PrintFormat && prompting);

    const promptingParametersValidatorResult: ValidatorResult[] = promptingParameters.map(parameter => {
      if (parameter.name in builders) {
        const preparedParameterName = parameter.name as keyof typeof builders;
        const currentBuilder = builders[preparedParameterName];
        return currentBuilder.validator(parameter);
      } else if (parameter.isAbstractParameter) {
        switch (parameter.type) {
          case PARAMETER_TYPE.BOOLEAN:
            return validateAbstractBoolean();
          case PARAMETER_TYPE.INTEGER:
            return validateAbstractInteger(parameter);
          case PARAMETER_TYPE.STRING:
            return validateAbstractString(parameter);
          default:
            break;
        }
      }
      return { isValid: false, message: 'Системная ошибка. Параметр не обрабатывается' };
    });

    const isEveryParameterValid = promptingParametersValidatorResult.every(({ isValid }) => isValid);
    if (isEveryParameterValid) {
      let query = '';
      filteredParameters.forEach(parameter => {
        if (parameter.name in builders) {
          const preparedParameterName = parameter.name as keyof typeof builders;
          const currentBuilder = builders[preparedParameterName];
          query += currentBuilder.queryBuilder(parameter);
        } else if (parameter.isAbstractParameter) {
          switch (parameter.type) {
            case PARAMETER_TYPE.BOOLEAN:
              query += abstractBooleanQueryBuilder(parameter);
              break;
            case PARAMETER_TYPE.INTEGER:
              query += abstractIntegerQueryBuilder(parameter);
              break;
            case PARAMETER_TYPE.STRING:
              query += abstractStringQueryBuilder(parameter);
              break;
            default:
              break;
          }
        }
      });

      const printFormatParameter = parameters.find(({ name }) => name === Parameters.PrintFormat);
      const requestIdsParameter = parameters.find(({ name }) => name === Parameters.RequestIds);

      if (scientist) {
        query += `&${Parameters.Scientist}=${scientist}`;
      }

      if (scientistId) {
        query += `&${Parameters.ScientistId}=${scientistId}`;
      }

      if (nirRequestId) {
        query += `&${Parameters.NirRequestId}=${nirRequestId}`;
      }

      if (requestId) {
        query += `&${Parameters.RequestId}=${requestId}`;
      }

      if (projectId) {
        query += `&${Parameters.ProjectID}=${projectId}`;
      }

      if (partnerId) {
        query += `&${Parameters.PartnerID}=${partnerId}`;
      }

      if (stageId) {
        query += `&StageID=${stageId}`;
      }

      if (isEventParameterHidden && event) {
        query += `&${Parameters.EventId}=${event.id}`;
      }

      if (securityDocumentId) {
        query += `&${Parameters.SecurityDocumentId}=${securityDocumentId}`;
      }

      if (requestType) {
        query += `&${Parameters.NotRequiredRequestType}=${requestType}`;
      }

      if (requestIdsParameter && requestIdsParameter.value) {
        query += `&${requestIdsParameter.name}=${requestIdsParameter.value}`;
      }

      if (printFormatParameter && printFormatParameter.value) {
        query = `${printFormatParameter.value}?${query}`;
      }

      if (requestIds) {
        query += `&${Parameters.RequestIds}=${requestIds}`;
      }

      if (authorRequestStatus) {
        query += `&${Parameters.NotRequiredAuthorRequestStatus}=${authorRequestStatus}`;
      }

      if (requestStatus) {
        query += `&${Parameters.NotRequiredRequestStatus}=${requestStatus}`;
      }

      if (nirRequestStatus) {
        query += `&${Parameters.NotRequiredNirRequestStatus}=${nirRequestStatus}`;
      }

      if (userDepartment) {
        query += `&${Parameters.NotRequiredUserDepartment}=${userDepartment}`;
      }

      if (paymentRequestPeriod) {
        query += `&${Parameters.PaymentRequestPeriod}=${paymentRequestPeriod}`;
      }

      if (department) {
        query += `&${Parameters.NotRequiredDepartment}=${department}`;
      }

      if (stages) {
        query += `&${Parameters.NotRequiredStages}=${stages}`;
      }

      if (publicationTypes) {
        query += `&${Parameters.NotRequiredPublicationType}=${publicationTypes}`;
      }

      if (regulationParagraph) {
        query += `&${Parameters.NotRequiredRegulationParagraph}=${regulationParagraph}`;
      }

      if (mobileRequestId) {
        query += `&${Parameters.MobileRequestId}=${mobileRequestId}`;
      }

      if (projectActId) {
        query += `&${Parameters.ProjectActId}=${projectActId}`;
      }

      if (contestRequestId) {
        query += `&${Parameters.ContestRequestId}=${contestRequestId}`;
      }

      if (exhibitionEventId) {
        query += `&${Parameters.ExhibitionEventId}=${exhibitionEventId}`;
      }

      if (contestEventId) {
        query += `&${Parameters.ContestEventId}=${contestEventId}`;
      }

      if (conferenceEventId) {
        query += `&${Parameters.ConferenceEventId}=${conferenceEventId}`;
      }

      if (mobileProtocolId) {
        query += `&${Parameters.MobileProtocolId}=${mobileProtocolId}`;
      }

      if (tenderProtocolId) {
        query += `&${Parameters.TenderProtocolId}=${tenderProtocolId}`;
      }

      if (projectCodeId) {
        query += `&${Parameters.ProjectCode}=${projectCodeId}`;
      }

      if (purchaseRequestId) {
        query += `&${Parameters.PurchaseRequestId}=${purchaseRequestId}`;
      }

      if (projectPerformerOrderId) {
        query += `&${Parameters.ProjectPerformerOrderId}=${projectPerformerOrderId}`;
      }

      if (startYear) {
        query += `&${Parameters.StartYear}=${startYear}`;
      }

      if (endYear) {
        query += `&${Parameters.EndYear}=${endYear}`;
      }

      query = `${reportName}/${query}`;
      downloadFile(`/report/service/report/${query}`, userToken);

      console.warn('print params', query);

      onClose();
    } else {
      const preparedErrorMessage = promptingParametersValidatorResult
        .filter(({ isValid }) => !isValid)
        .map(({ message }) => message)
        .join('. <br />');
      showNotification({ message: preparedErrorMessage, theme: 'danger' });
    }
  }, [
    parameters,
    scientist,
    scientistId,
    nirRequestId,
    requestId,
    projectId,
    partnerId,
    stageId,
    isEventParameterHidden,
    event,
    securityDocumentId,
    requestType,
    requestIds,
    authorRequestStatus,
    requestStatus,
    nirRequestStatus,
    userDepartment,
    paymentRequestPeriod,
    department,
    stages,
    publicationTypes,
    regulationParagraph,
    mobileRequestId,
    projectActId,
    contestRequestId,
    exhibitionEventId,
    contestEventId,
    conferenceEventId,
    mobileProtocolId,
    tenderProtocolId,
    projectCodeId,
    purchaseRequestId,
    projectPerformerOrderId,
    startYear,
    endYear,
    reportName,
    userToken,
    onClose,
  ]);

  const setParameterValueByName = useCallback(
    ({ name, value }: { name: string; value: ParameterValue | null }) => {
      const parameterToUpdateIndex = parameters.findIndex(parameter => parameter.name === name);
      if (parameterToUpdateIndex !== -1) {
        setParameters(prev => {
          const result = [...prev];
          result[parameterToUpdateIndex].value = value;
          return result;
        });
      }
    },
    [parameters],
  );

  const handleConfirmError = useCallback(() => {
    onClose();
    setIsErrorPopupOpen(false);
  }, [onClose]);

  useLayoutEffect(() => {
    getReportParameters.callAPI(
      { reportName },
      {
        onSuccessfullCall: ({ data }) => {
          setIsHasManual(!!data.isHasManual);

          const printFormatIndex = data.parameters.findIndex(parameter => parameter.name === Parameters.PrintFormat);

          const printFormatParameter: Parameter = {
            name: Parameters.PrintFormat,
            type: 'String',
            prompting: true,
            description: 'Формат печати',
            default: data.defaultFormatId,
            startDateRange: false,
            endDateRange: false,
            value: data.defaultFormatId,
            required: true,
            allowedValues: data.allowedFormatIds,
            isAbstractParameter: false,
          };

          const preparedParameters =
            printFormatIndex !== -1
              ? data.parameters.splice(printFormatIndex, 1, printFormatParameter)
              : [printFormatParameter, ...data.parameters];
          const requestIdsIndex = preparedParameters.findIndex(({ name }) => name === Parameters.RequestIds);

          if (requestIdsIndex !== -1) {
            preparedParameters[requestIdsIndex].prompting = false;
            preparedParameters[requestIdsIndex].value = '-1';
          }

          if (defaultRequestStatus) {
            const notRequiredAuthorRequestStatusParameterIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredAuthorRequestStatus,
            );

            if (notRequiredAuthorRequestStatusParameterIndex !== -1) {
              preparedParameters[notRequiredAuthorRequestStatusParameterIndex].default = defaultRequestStatus;
            }
          }

          if (authorRequestStatus) {
            const notRequiredAuthorRequestStatusParameterIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredAuthorRequestStatus,
            );

            if (notRequiredAuthorRequestStatusParameterIndex !== -1) {
              preparedParameters.splice(notRequiredAuthorRequestStatusParameterIndex, 1);
            }
          }

          if (scientist) {
            const scientistParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.Scientist);

            if (scientistParameterIndex !== -1) {
              preparedParameters.splice(scientistParameterIndex, 1);
            }
          }

          if (scientistId) {
            const scientistIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ScientistId || parameter.name === Parameters.Scientist,
            );

            if (scientistIdParameterIndex !== -1) {
              preparedParameters.splice(scientistIdParameterIndex, 1);
            }
          }

          if (event) {
            const eventIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.EventId || parameter.name === Parameters.EventId,
            );

            if (eventIdParameterIndex !== -1) {
              if (isEventParameterHidden) {
                preparedParameters.splice(eventIdParameterIndex, 1);
              } else {
                preparedParameters[eventIdParameterIndex].value = event;
              }
            }
          }

          if (conferenceEventId) {
            const conferenceEventIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ConferenceEventId || parameter.name === Parameters.ConferenceEventId,
            );

            if (conferenceEventIdParameterIndex !== -1) {
              preparedParameters.splice(conferenceEventIdParameterIndex, 1);
            }
          }

          if (contestEventId) {
            const contestEventIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ContestEventId || parameter.name === Parameters.ContestEventId,
            );

            if (contestEventIdParameterIndex !== -1) {
              preparedParameters.splice(contestEventIdParameterIndex, 1);
            }
          }

          if (exhibitionEventId) {
            const exhibitionEventIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ExhibitionEventId || parameter.name === Parameters.ExhibitionEventId,
            );

            if (exhibitionEventIdParameterIndex !== -1) {
              preparedParameters.splice(exhibitionEventIdParameterIndex, 1);
            }
          }

          if (nirRequestId) {
            const nirRequestIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.NirRequestId,
            );

            if (nirRequestIdParameterIndex !== -1) {
              preparedParameters.splice(nirRequestIdParameterIndex, 1);
            }
          }

          if (requestId) {
            const requestIdParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.RequestId);

            if (requestIdParameterIndex !== -1) {
              preparedParameters.splice(requestIdParameterIndex, 1);
            }
          }

          if (securityDocumentId) {
            const securityDocumentIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.SecurityDocumentId,
            );

            if (securityDocumentIdParameterIndex !== -1) {
              preparedParameters.splice(securityDocumentIdParameterIndex, 1);
            }
          }

          if (requestType) {
            const notRequiredRequestTypeIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.NotRequiredRequestType,
            );

            if (notRequiredRequestTypeIndex !== -1) {
              preparedParameters.splice(notRequiredRequestTypeIndex, 1);
            }
          }

          if (requestIds) {
            const requestIdsParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.RequestIds);

            if (requestIdsParameterIndex !== -1) {
              preparedParameters.splice(requestIdsParameterIndex, 1);
            }
          }

          if (requestStatus) {
            const notRequiredRequestStatusIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredRequestStatus,
            );

            if (notRequiredRequestStatusIndex !== -1) {
              preparedParameters.splice(notRequiredRequestStatusIndex, 1);
            }
          }

          if (nirRequestStatus) {
            const notRequiredNirRequestStatusParameterIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredNirRequestStatus,
            );

            if (notRequiredNirRequestStatusParameterIndex !== -1) {
              preparedParameters.splice(notRequiredNirRequestStatusParameterIndex, 1);
            }
          }

          if (userDepartment) {
            const notRequiredUserDepartmentIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredUserDepartment,
            );

            if (notRequiredUserDepartmentIndex !== -1) {
              preparedParameters.splice(notRequiredUserDepartmentIndex, 1);
            }
          }

          if (paymentRequestPeriod) {
            const paymentRequestPeriodIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.PaymentRequestPeriod,
            );

            if (paymentRequestPeriodIndex !== -1) {
              preparedParameters.splice(paymentRequestPeriodIndex, 1);
            }
          }
          if (department) {
            const notRequiredDepartmentIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredDepartment,
            );

            if (notRequiredDepartmentIndex !== -1) {
              preparedParameters.splice(notRequiredDepartmentIndex, 1);
            }
          }
          if (stages) {
            const notRequiredStagesIndex = preparedParameters.findIndex(({ name }) => name === Parameters.NotRequiredStages);

            if (notRequiredStagesIndex !== -1) {
              preparedParameters.splice(notRequiredStagesIndex, 1);
            }
          }
          if (publicationTypes) {
            const notRequiredPublicationTypeIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredPublicationType,
            );

            if (notRequiredPublicationTypeIndex !== -1) {
              preparedParameters.splice(notRequiredPublicationTypeIndex, 1);
            }
          }
          if (regulationParagraph) {
            const notRequiredRegulationParagraphIndex = preparedParameters.findIndex(
              ({ name }) => name === Parameters.NotRequiredRegulationParagraph,
            );

            if (notRequiredRegulationParagraphIndex !== -1) {
              preparedParameters.splice(notRequiredRegulationParagraphIndex, 1);
            }
          }

          if (projectId) {
            const projectIdParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.ProjectID);

            if (projectIdParameterIndex !== -1) {
              preparedParameters.splice(projectIdParameterIndex, 1);
            }
          }

          if (partnerId) {
            const partnerIdParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.PartnerID);

            if (partnerIdParameterIndex !== -1) {
              preparedParameters.splice(partnerIdParameterIndex, 1);
            }
          }

          if (stageId) {
            const stageIdParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.StageId);

            if (stageIdParameterIndex !== -1) {
              preparedParameters.splice(stageIdParameterIndex, 1);
            }
          }
          if (mobileRequestId) {
            const mobileRequestIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.MobileRequestId,
            );

            if (mobileRequestIdParameterIndex !== -1) {
              preparedParameters.splice(mobileRequestIdParameterIndex, 1);
            }
          }
          if (projectActId) {
            const projectActIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ProjectActId,
            );

            if (projectActIdParameterIndex !== -1) {
              preparedParameters.splice(projectActIdParameterIndex, 1);
            }
          }
          if (contestRequestId) {
            const contestRequestParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ContestRequestId,
            );

            if (contestRequestParameterIndex !== -1) {
              preparedParameters.splice(contestRequestParameterIndex, 1);
            }
          }

          if (mobileProtocolId) {
            const mobileProtocolIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.MobileProtocolId || parameter.name === Parameters.MobileProtocolId,
            );

            if (mobileProtocolIdParameterIndex !== -1) {
              preparedParameters.splice(mobileProtocolIdParameterIndex, 1);
            }
          }
          if (tenderProtocolId) {
            const tenderProtocolIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.TenderProtocolId,
            );

            if (tenderProtocolIdParameterIndex !== -1) {
              preparedParameters.splice(tenderProtocolIdParameterIndex, 1);
            }
          }
          if (projectCodeId) {
            const projectCodeParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ProjectCode,
            );

            if (projectCodeParameterIndex !== -1) {
              preparedParameters.splice(projectCodeParameterIndex, 1);
            }
          }
          if (purchaseRequestId) {
            const purchaseRequestIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.PurchaseRequestId,
            );

            if (purchaseRequestIdParameterIndex !== -1) {
              preparedParameters.splice(purchaseRequestIdParameterIndex, 1);
            }
          }
          if (projectPerformerOrderId) {
            const projectPerformerIdParameterIndex = preparedParameters.findIndex(
              parameter => parameter.name === Parameters.ProjectPerformerOrderId,
            );

            if (projectPerformerIdParameterIndex !== -1) {
              preparedParameters.splice(projectPerformerIdParameterIndex, 1);
            }
          }
          if (startYear) {
            const startYearParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.StartYear);

            if (startYearParameterIndex !== -1) {
              preparedParameters.splice(startYearParameterIndex, 1);
            }
          }
          if (endYear) {
            const endYearParameterIndex = preparedParameters.findIndex(parameter => parameter.name === Parameters.EndYear);

            if (endYearParameterIndex !== -1) {
              preparedParameters.splice(endYearParameterIndex, 1);
            }
          }
          setParameters(preparedParameters);
        },
        onFailedCall: data => {
          const errorMessage = data.errorMessage || 'Произошла ошибка при построении отчета';
          setErrorText(errorMessage);
          setIsErrorPopupOpen(true);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const customParameters = useMemo(
    () =>
      parameters.map(parameter => ({
        ...parameter,
        ...([Parameters.NotRequiredAuthorRequestStatus].some(parameterName => parameterName === parameter.name) &&
        requestTypeLabel
          ? { description: `${parameter.description} (тип: ${requestTypeLabel})` }
          : {}),
      })),
    [requestTypeLabel, parameters],
  );

  return {
    isHasManual,
    showManual,
    parameters: customParameters,
    errorText,
    isErrorPopupOpen,
    builderList,
    handleConfirmError,
    startPrint,
    setParameterValueByName,
    isReportParametersLoading,
  };
};

export default useController;

import React, { useCallback, useState } from 'react';

import { Button, ButtonMode, FormComponent, Select, TextDateTime, TextInput } from 'components';

import { Form as F } from 'types/models';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';
import { showNotification } from 'features/Notifications';
import { electronicPublicationTypes } from '../compilationTypes';
import { Item } from 'types/models/common';

type Props = {
  onClose: () => void;
  onSelect: FetchCompilationProcess;
  action: string;
  id: string;
  isElectronic: boolean;
};

const ChangeElectronicTypes = (props: Props) => {
  const { onClose, onSelect, action, id, isElectronic } = props;

  const [date, setDate] = useState<string | null>(null);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(electronicPublicationTypes[0]);

  const [formFields, setFormFields] = useState<F.Fields>({
    path: {
      value: '',
      isValid: true,
      required: false,
      title: 'URL (Режим доступа)',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          path: { ...prevState.path, value },
        }));
      },
    },
    electronicMediaSource: {
      value: '',
      isValid: true,
      required: true,
      title: 'Электронный носитель',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          electronicMediaSource: { ...prevState.electronicMediaSource, value },
        }));
      },
    },
    electronicSystemRequirements: {
      value: '',
      isValid: true,
      title: 'Системные требования',
      onChange: (value: string) => {
        setFormFields((prevState: F.Fields) => ({
          ...prevState,
          electronicSystemRequirements: { ...prevState.electronicSystemRequirements, value },
        }));
      },
    },
  });

  const continueWithSelectedType = () => {
    const isValidWebType = Boolean(date);
    const isValidLocalType = Boolean(formFields.electronicMediaSource.value);
    const isWeb = electronicPublicationType.code === 'WEB';

    const isValid = isElectronic ? true : isWeb ? isValidWebType : isValidLocalType;

    if (!isValid) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
    } else {
      onSelect(id || '', action, {
        electronicType: electronicPublicationType.code,
        url: formFields.path.value,
        urlDate: date ?? '',
        electronicMediaSource: formFields.electronicMediaSource.value,
        electronicSystemRequirements: formFields.electronicSystemRequirements.value,
      });
      onClose();
    }
  };

  const onChangeElectronicPublicationType = useCallback(
    (value: string) => {
      const selected = electronicPublicationTypes.find(x => x.id === value);
      if (selected) {
        setElectronicPublicationType(selected);
      }
    },
    [setElectronicPublicationType],
  );

  const changeDocument = useCallback(
    (value: string | null) => {
      setDate(value);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [document],
  );

  return (
    <FormComponent.Wrapper>
      {!isElectronic && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Тип электронного издания">
              <Select
                value={{ value: electronicPublicationType?.id || '', label: electronicPublicationType?.label || '' }}
                options={electronicPublicationTypes.map(x => ({
                  value: x.id,
                  label: x.label,
                }))}
                onChange={(option: Item) => onChangeElectronicPublicationType(option.value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          {electronicPublicationType.code === 'WEB' && (
            <FormComponent.Line>
              <FormComponent.Field label={formFields.path.title}>
                <TextInput value={formFields.path.value} onChange={formFields.path.onChange} />
              </FormComponent.Field>
              <FormComponent.Field label="Дата документа" isRequired>
                <TextDateTime value={date} onChange={changeDocument} />
              </FormComponent.Field>
            </FormComponent.Line>
          )}

          {electronicPublicationType.code === 'LOCAL' && (
            <FormComponent.Line>
              <FormComponent.Field label={formFields.electronicMediaSource.title}>
                <TextInput value={formFields.electronicMediaSource.value} onChange={formFields.electronicMediaSource.onChange} />
              </FormComponent.Field>
              <FormComponent.Field label={formFields.electronicSystemRequirements.title}>
                <TextInput
                  value={formFields.electronicSystemRequirements.value}
                  onChange={formFields.electronicSystemRequirements.onChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </>
      )}

      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text={`Выполнить операцию по переводу сборника в ${isElectronic ? 'печатный' : 'электронный'} вид`}
          onClick={continueWithSelectedType}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(ChangeElectronicTypes);

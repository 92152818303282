import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewFormOpen,
    isViewButtonDisabled,
    handleTemplateCloseViewForm,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Диссертации и авторефераты"
        size="medium"
      >
        {ProjectHelp().resultsDissertations}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import {
  Checkbox,
  FormComponent,
  ListEdit,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
} from 'components';

import { Table, Form } from 'types/models';
import { GetPartnerList, GetDissertationCouncilList } from 'features/Table/specifications';
import { CommunityType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { convertAffiliation, convertCouncil } from 'features/Form/looks/publication/PublicationFormDissertation/helpers';
import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';
import { checkIsSelectedRowValid } from './helpers';
import { showNotification } from 'features/Notifications';
import { RefElement } from 'types/models/Project';

type Props = {
  workMode: Table.WorkMode;
  formFields: Form.Fields;
};

const Defence = ({ workMode, formFields }: Props) => {
  const { settings } = useAppDataContext();

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '50%' }}>
          <SectionTitle title="Сведения о защите" />

          <FormComponent.Line>
            <FormComponent.Field label={formFields.council.title}>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Диссертационный совет',
                  table: {
                    specification: GetDissertationCouncilList({
                      communityTypes: [CommunityType.DISSERTATION_COUNCIL],
                      hasSelectButton: true,
                    }),
                    onSelect: (row: Table.Entry | null) => formFields.council.onChange(row ? convertCouncil(row) : null),
                  },
                }}
                value={formFields?.council?.value?.name}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <RefElementList
              header={{ title: 'Специальности' }}
              rows={formFields.vaks.value ?? []}
              onChange={formFields.vaks.onChange}
              toolbar={['add', 'delete', 'move']}
              columns={[{ label: 'Наименование', formatValue: (x: RefElement) => x.element?.label ?? '' }]}
              // eslint-disable-next-line max-len
              modalTitle='Справочник "Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)"'
              refName="RefVakSpeciality"
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.degree.title} isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={formFields.degree.value}
                onChange={formFields.degree.onChange}
                settings={{ name: 'RefDegree', title: formFields.degree.title }}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <SectionTitle title="Место защиты" />

          <FormComponent.Line>
            <FormComponent.Field label="Организация, на базе которой выполнена работа">
              <Checkbox
                label={settings?.organization?.shortName}
                checked={formFields.isLocal.value}
                onChange={formFields.isLocal.onChange}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                header={{ title: 'Организации' }}
                rows={formFields.affiliations.value ?? []}
                onChange={formFields.affiliations.onChange}
                toolbar={['add', 'edit', 'delete']}
                columns={[{ label: 'Название организации', formatValue: (x: Form.Affiliation) => x.organization.name }]}
                maxHeight="200px"
                defaultRowsCount={3}
                isModalHintHidden
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'loadInstanceTableModal',
                  relationTableModalTitle: 'Список организаций',
                  modalTableSpecification: GetPartnerList({
                    toolbarMode: 'ref',
                    parameters: [{ name: 'withRaw', value: true }],
                    hasSelectButton: true,
                  }),
                  validation: {
                    checkIsValid: (selectedEntries, selectedIndex, affiliations, mode) =>
                      checkIsSelectedRowValid({
                        selectedEntries,
                        selectedIndex,
                        affiliations: affiliations as Form.Affiliation[],
                        mode,
                      }),
                    onInvalidate: () => {
                      showNotification({ message: 'Нельзя добавлять дубли организаций', theme: 'danger' });
                    },
                  },
                  onStartLoad: (tableRows, onSuccess) => {
                    const [row] = tableRows;
                    getPartnerAPI.callAPI(
                      {
                        partnerId: row.id,
                        enterpriseCustomerId: row.Type === 'ENTERPRISE_CUSTOMER' ? row.id : null,
                        enterpriseId: row.Type === 'ENTERPRISE' ? row.id : null,
                        shortName: row.Type === 'RAW' ? row.ShortName ?? null : null,
                        fullName: row.Type === 'RAW' ? row.FullName : null,
                      },
                      {
                        onSuccessfullCall: () => {
                          onSuccess([convertAffiliation(row)]);
                        },
                      },
                    );
                  },
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.defencePlace.title} isRequired>
              <TextInput
                value={formFields.defencePlace.value}
                onChange={formFields.defencePlace.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.defenceDate.title} isRequired={!!formFields.defenceDate.required}>
              <TextDateTime
                value={formFields.defenceDate.value}
                onChange={formFields.defenceDate.onChange}
                isDisabled={workMode === 'viewMode'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
};

export const Component = React.memo(Defence);

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ParticipationFinancicalSupportPopup } from 'features/Form/views/ParticipationFinancicalSupportPopup';
import useController from './controller';
import { DataGrid } from 'features/Table';
import { GetParticipationUsagesList } from 'features/Table/specifications';
import { ExpositionParticipationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    buttons,
    selectedRow,
    isReportOpen,
    currentReport,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    isConfirmDeleteOpen,
    userSystemDepartment,
    isEditDepartmentsOpen,
    isFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
    handleCloseFinancicalSupportPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    isUsagesListOpen,
    handleCloseUsagesList,
    onReportClose,
    settings,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });
  return (
    <>
      <Toolbar buttons={buttons} />

      <EditParticipationDepartments
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        isOpen={isEditDepartmentsOpen}
        onClose={handleCloseEditDepartments}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitEditDepartments}
      />

      <ApproveParticipation
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
        isOpen={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitApproveParticipation}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConfirmDelete,
          },
        ]}
        size="small"
      >
        <>({tableState.selectedRows[0]?.EventFullName}) Удалить участие?</>
      </Modal>

      <Modal
        mode="warning"
        title="Привязка к подразделению"
        isOpen={isWarningPopupOpen && !!selectedRow}
        onClose={handleCloseWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Ок',
            onClick: handleAttachParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseWarningPopup,
          },
        ]}
        size="medium"
      >
        <>
          <p>Привязать участие к своему подразделению "{userSystemDepartment?.label}"?</p>
          <p>
            <strong>Информация об участии</strong>
          </p>
          <p>
            <strong>Выставка:</strong> {selectedRow?.EventFullName || ''}
          </p>

          {selectedRow?.Participants && (
            <p>
              <strong>Участники:</strong> {selectedRow?.Participants}
            </p>
          )}
        </>
      </Modal>

      {isEditFormOpen && (
        <modalTemplates.ExpositionParticipationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isViewFormOpen && (
        <modalTemplates.ExpositionParticipationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ExpositionParticipationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}

      <Modal
        isOpen={isUsagesListOpen}
        onClose={handleCloseUsagesList}
        title="Связи участия с другими объектами"
        size={['extralarge', 'small-height']}
      >
        <DataGrid specification={GetParticipationUsagesList({ id: selectedRow?.id })} />
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />

      <ParticipationFinancicalSupportPopup
        participationId={tableState.selectedRows[0]?.id ?? '-1'}
        isOpen={isFinancicalSupportPopupOpen}
        onClose={handleCloseFinancicalSupportPopup}
        relatedTableState={tableState}
        onSuccess={submitFinancialPopupCallback}
        workMode="editMode"
        participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
      />

      <Modal
        mode="help"
        title="Участия в выставках"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        size="medium"
      >
        {ExpositionParticipationGridHelp()}
      </Modal>
    </>
  );
};

export const Component = memo(LeftPanelForThirdLevel);

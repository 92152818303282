import React from 'react';

import { Select } from 'components';

import useController from './controller';

import './style.scss';

export type Props = {
  enumeration: string;
  setColumnFilter: (nextValue: string) => void;
  value: string;
};

export const EnumFilter = ({ enumeration, value, setColumnFilter }: Props) => {
  const { options, selectedValues, onFieldChange } = useController({ enumeration, value, setColumnFilter });

  return (
    <Select
      isMulti
      value={selectedValues}
      options={options}
      settings={{ maxMenuHeight: 200 }}
      onChange={onFieldChange}
      placeholder=" "
      classMixin="grid-enum-filter"
    />
  );
};

import React from 'react';
import block from 'bem-cn';
import * as helpers from 'features/MergePerson/helpers/helpers';

import { FormComponent, Modal, Radio, SectionTitle } from 'components';

import { useController } from './controller';
import { Row } from 'react-table';
import { Field } from 'features/MergePerson/Field';
import { ColumnType, MergeFieldRadioValue } from 'features/MergePerson/types';
import { FieldType } from 'types/models/Merge';
import { ControlPanel } from 'features/Form/views/ControlPanel';

import './style.scss';

const b = block('merge-form');

type Props = {
  isOpen: boolean;
  onClose(): void;
  refName: string;
  selectedEntries?: Row[];
};

export function FieldsMapModal(props: Props) {
  const { isOpen, onClose, refName, selectedEntries } = props;

  const {
    merge,
    mergeFieldRadios,
    extendedCommonColumn,
    setIsCollectionConflicResolved,
    onCommonRadioClick,
    getIsConflicted,
    updateEntityMergeFieldRadio,
    setValue,
    saveMerge,
  } = useController({ refName, selectedEntries, onClose });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Объединение дубликатов" size="full">
      <FormComponent.Template>
        <ControlPanel handleSave={saveMerge} />

        <FormComponent.Wrapper style={{ overflowX: 'auto' }}>
          <FormComponent.ColumnWrapper style={{ gap: '20px' }}>
            <FormComponent.Column style={{ width: '500px', flexShrink: 0 }}>
              <>
                <SectionTitle>
                  <div className={b('title')}>
                    <span>Результат объединения</span>
                  </div>
                </SectionTitle>
                {extendedCommonColumn.map(field => (
                  <Field
                    key={`field_${field.name}`}
                    isCommon
                    isLabelShows
                    isConflictedMarkerShows
                    isConflicted={getIsConflicted(field)}
                    field={field}
                    setValue={setValue({ type: ColumnType.COMMON, fieldName: field.name })}
                    isDisabled={!helpers.getIsCommonFieldEditable(field)}
                  />
                ))}
              </>
            </FormComponent.Column>
            <>
              {merge.entities.map((entity, index) => (
                <FormComponent.Column key={`column_${index}`} style={{ width: '400px', flexShrink: 0, paddingLeft: '0px' }}>
                  <>
                    <SectionTitle>
                      <div className={b('title')}>
                        <div className={b('title-radio')}>
                          <Radio
                            value={merge.commonId ?? undefined}
                            list={[{ label: '', value: entity.id }]}
                            onChange={onCommonRadioClick}
                          />
                        </div>
                        <span>Элемент c ID: {entity.id}</span>
                      </div>
                    </SectionTitle>
                    {entity?.fields?.map(field => (
                      <div
                        key={`entity_${field.name}`}
                        className={b('entity-field', { 'without-radio': field.type === FieldType.COLLECTION })}
                      >
                        {field.type !== FieldType.COLLECTION && (
                          <div className={b('entity-radio')}>
                            <Radio
                              isDisabled={!helpers.getIsEntityFieldCanSelected(field)}
                              value={mergeFieldRadios?.[entity.id][field.name]}
                              list={[{ label: '', value: MergeFieldRadioValue.SELECTED }]}
                              onChange={updateEntityMergeFieldRadio({
                                fieldName: field.name,
                                entityId: entity.id,
                                value: field.value,
                              })}
                            />
                          </div>
                        )}
                        <Field
                          field={field}
                          setValue={setValue({ type: ColumnType.ENTITY, entityId: entity.id, fieldName: field.name })}
                          setIsCollectionConflictResolved={setIsCollectionConflicResolved({
                            fieldName: field.name,
                            entityId: entity.id,
                          })}
                          isDisabled
                        />
                      </div>
                    ))}
                  </>
                </FormComponent.Column>
              ))}
            </>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}

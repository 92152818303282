import { useCallback } from 'react';

import { ReferenceItem } from 'components';

import { Project } from 'types/models';

type Arguments = {
  project: Project.Project | null;
  setProject(job: Project.Project): void;
};

export function useController(args: Arguments) {
  const { setProject, project } = args;

  const handleChangeSpecific = useCallback(
    (ref: ReferenceItem) => {
      setProject({ ...project!, specification: ref, kindResearch: null });
    },
    [setProject, project],
  );

  return {
    handleChangeSpecific,
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  const info = (
    <>
      <ul>
        <li>
          Фильтр <strong>Год</strong> служит для отображения списка записей с датой Оплатить до указанного в фильтре года;
        </li>
        <li>
          Фильтр <strong>Неоплачено</strong> служит для отображения списка записей, у которых отсутствует отметка об оплате (дата
          оплаты);
        </li>
        <li>
          Фильтр <strong>Необходимо оплатить пошлину в течении месяца</strong> отображает записи, у которых дата в столбце
          Оплатить до удовлетворяет условию: <strong>Оплатить до – Сегодня &lt;= 30 дней</strong>;
        </li>
        <li>
          Для просмотра <strong>всех</strong> записей в таблице необходимо отключить все фильтры.
        </li>
      </ul>
    </>
  );

  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: info,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'notPaid',
      label: 'Не оплачено',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'needPayment',
      label: 'Необходимо оплатить пошлину в течении месяца',
      checked: false,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const { methods: ChangeNotificationRecipientState } = BackendAPI.useBackendAPI('ChangeNotificationRecipientState');

  const handleActivateButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0) {
      ChangeNotificationRecipientState.callAPI(
        { enable: true, ids: tableState.selectedRows.map(i => i.id) },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Уведомления активированы', theme: 'success' });
            tableStreams.reloadTable.push({});
          },
        },
      );
    } else {
      showNotification({ message: 'Выберите уведомление', theme: 'danger' });
    }
  }, [ChangeNotificationRecipientState, tableState.selectedRows, tableStreams.reloadTable]);

  const handleDeactivateButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0) {
      ChangeNotificationRecipientState.callAPI(
        { enable: false, ids: tableState.selectedRows.map(i => i.id) },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Уведомления деактивированы', theme: 'success' });
            tableStreams.reloadTable.push({});
          },
        },
      );
    } else {
      showNotification({ message: 'Выберите уведомление', theme: 'danger' });
    }
  }, [tableState.selectedRows, ChangeNotificationRecipientState, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Активировать',
        onClick: handleActivateButtonClick,
        isDisabled: !tableState.selectedRows.some(i => i.Enabled === 'false'),
      },
      {
        icon: { type: 'activate', mode: 'remove' },
        title: 'Деактивировать',
        onClick: handleDeactivateButtonClick,
        isDisabled: !tableState.selectedRows.some(i => i.Enabled === 'true'),
      },
    ],
    [tableState.selectedRows, handleActivateButtonClick, handleDeactivateButtonClick],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isFormOpen,
    setIsFormOpen,
  };
}

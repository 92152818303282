import { useEffect } from 'react';

import { FormComponent, Radio, Select, SelectMode, TextDateTime, TextInput, TextInputMode, UploadFile } from 'components';
import { Equip, ReferenceItem } from 'types/models';
import { getMockVerificationItem } from '../../../EquipForm/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { SelectPartner } from 'features/Form/views/SelectPartner';

export function VerificationItemFields({
  row,
  setRow,
  equip,
}: {
  row: Equip.VerificationItem | null;
  setRow: React.Dispatch<React.SetStateAction<Equip.VerificationItem | null>>;
  equip: Equip.Equip;
}) {
  const { settings } = useAppDataContext();

  useEffect(() => {
    if (!row)
      setRow(
        getMockVerificationItem({
          enterprise: equip.verificationEnterprise,
          price: equip.price,
          isLocalVerificationLocation: equip.isLocalVerificationLocation,
        }),
      );
  }, [equip.isLocalVerificationLocation, equip.price, equip.verificationEnterprise, row, setRow]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Дата поверки" isRequired>
          <TextDateTime value={row?.date} onChange={e => setRow(prev => ({ ...prev!, date: e }))} />
        </FormComponent.Field>
        <FormComponent.Field label="Номер акта выполненных работ" isRequired labelSize="fit">
          <TextInput
            value={row?.contractNumber}
            onChange={e => setRow(prev => ({ ...prev!, contractNumber: e }))}
            settings={{ maxLength: 100 }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Результат" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={row?.result}
            onChange={(e: ReferenceItem | null) => setRow(prev => ({ ...prev!, result: e }))}
            settings={{
              title: 'Справочник "Результат поверки"',
              name: 'RefEquipVerificationResult',
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Организация-поверитель">
          <SelectPartner
            partner={row?.enterprise || null}
            setPartner={e => setRow(prev => ({ ...prev!, enterprise: e }))}
            label="Организация-поверитель"
            showButtons={['add']}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Стоимость поверки, руб" contentSize="small">
          <TextInput mode={TextInputMode.NUMBER} value={row?.price} onChange={e => setRow(prev => ({ ...prev!, price: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Место поверки">
          <Radio
            value={String(!!row?.isLocalVerificationLocation)}
            list={[
              { label: `На территории ${settings?.organization?.shortName || 'университета'}`, value: 'true' },
              {
                label: `На территории исполнителя ${row?.enterprise?.shortName || row?.enterprise?.fullName || ''}`,
                value: 'false',
              },
            ]}
            onChange={e => setRow(prev => ({ ...prev!, isLocalVerificationLocation: !!(e === 'true') }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Загрузить файл">
          <UploadFile file={row?.document || undefined} onChange={e => setRow(prev => ({ ...prev!, document: e }))} />
        </FormComponent.Field>
        <FormComponent.Field label="Ссылка на запись в реестре">
          <TextInput
            value={row?.documentUrl}
            onChange={e => setRow(prev => ({ ...prev!, documentUrl: e }))}
            settings={{ maxLength: 1000 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextInput value={row?.note} onChange={e => setRow(prev => ({ ...prev!, note: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

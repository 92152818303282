import React from 'react';

import { FormComponent, SectionTitle, TextInput } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Okveds } from './Okveds';

type Props = {
  partner: Partner | null;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  disabled: boolean;
};

export function CodesFields(props: Props) {
  const { makeChangeHandler, partner, disabled } = props;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '600px' }}>
        <SectionTitle title="Коды организации" />
        <FormComponent.Line>
          <FormComponent.Field label="ИНН">
            <TextInput value={partner?.inn ?? ''} onChange={makeChangeHandler('inn')} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КПП">
            <TextInput value={partner?.kpp ?? ''} onChange={makeChangeHandler('kpp')} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="ОГРН">
            <TextInput value={partner?.ogrn ?? ''} onChange={makeChangeHandler('ogrn')} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="ОКОПФ">
            <TextInput value={partner?.okopf ?? ''} onChange={makeChangeHandler('okopf')} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Банковские реквизиты">
            <TextInput
              value={partner?.bankRequisites ?? ''}
              onChange={makeChangeHandler('bankRequisites')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: 'calc(100% - 600px)' }}>
        <Okveds disabled={disabled} okveds={partner?.okveds ?? []} setOkveds={makeChangeHandler('okveds')} />
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { Person } from 'types/models';
import { EditableHonor } from 'features/PersonCard/model';

export function validate(item: EditableHonor | null) {
  if (!item) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidDegree = item.type === 'degree' ? Boolean((item as Person.ScientistDegree).refDegree) : true;
  const isValidRank = item.type === 'rank' ? Boolean((item as Person.ScientistRank).refRank) : true;
  const isValidAcademicRank =
    item.type === 'academicRank' ? Boolean((item as Person.ScientistAcademicRank).refAcademicRank) : true;
  const isValidAcademy = item.type === 'academicRank' ? Boolean((item as Person.ScientistAcademicRank).academy) : true;

  return [
    { isValid: isValidDegree, invalidMessage: 'Выберете ученую степень' },
    { isValid: isValidRank, invalidMessage: 'Выберете ученое звание' },
    { isValid: isValidAcademicRank, invalidMessage: 'Выберете академическое звание' },
    { isValid: isValidAcademy, invalidMessage: 'Выберете академию' },
  ];
}

import React, { useCallback, useEffect, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';
import { SelectPerson, Member } from 'features/SelectPerson';
import { Author } from 'types/models';
import { MemberAffilation } from 'types/models/Author';
import { AffilationField, SelectBiblioName } from '../Fields';
import { getBiblioName, getMockAuthor, isCollectiveAuthor } from '../helpers';
import { MemberAffilations } from '../MemberAffiliations';

export type ChangeProps = {
  key: keyof Author;
  value: Author[keyof Author];
};

type Props = {
  author: Author | null;
  language: ReferenceItem;
  onChange(args: ChangeProps[]): void;
  filters?: string[];
};

function AuthorshipFields(props: Props) {
  const { author, language, onChange, filters = [] } = props;

  const scientistPerson = author?.person ?? null;

  const { methods: GetScientistData } = BackendAPI.useBackendAPI('GetScientistData');

  useEffect(() => {
    if (author === null) {
      const changeProps = Object.entries(getMockAuthor()).map(([key, val]) => ({ [key]: val })) as ChangeProps[];
      onChange(changeProps);
    }
  }, [author, onChange]);

  const resetPerson = useCallback(() => {
    onChange([
      { key: 'person', value: null },
      { key: 'degree', value: null },
      { key: 'rank', value: null },
      { key: 'academicRank', value: null },
      { key: 'job', value: null },
      { key: 'education', value: null },
      { key: 'citizenship', value: null },
    ]);
  }, [onChange]);

  const reloadBiblioOptions = useCallback(() => {
    if (!scientistPerson) return;
    GetScientistData.callAPI(
      { personId: scientistPerson.id, modules: ['GENERAL', 'BIBLIOGRAPHIC_NAMES'] },
      {
        onSuccessfullCall: ({ data }) => {
          const changeProps: ChangeProps[] = [
            { key: 'person', value: { ...scientistPerson, bibliographicNames: data.bibliographicNames } },
          ];

          const validBiblio = data.bibliographicNames.find(x => x.name === author?.name) ?? null;
          if (!validBiblio) changeProps.push({ key: 'name', value: getBiblioName(data.bibliographicNames, language) });

          onChange(changeProps);
        },
      },
    );
  }, [GetScientistData, author?.name, language, onChange, scientistPerson]);

  const makeChange = (key: keyof Author) => (val: Author[keyof Author]) => onChange([{ key, value: val }]);

  const handleChangeIsAffilation = useCallback(
    (isAffilated: boolean) => {
      const changes = [
        { key: 'isAffilated', value: isAffilated },
        ...(!isAffilated ? [{ key: 'affilateCount', value: null }] : []),
      ] as ChangeProps[];
      onChange(changes);
    },
    [onChange],
  );

  const handleChangeMembersAffilations = useCallback(
    (memberAffilations: MemberAffilation[]) => {
      onChange([{ key: 'memberAffilations', value: memberAffilations }]);
    },
    [onChange],
  );

  const updateAuthor = useCallback(
    (updatedMember: Member | null) => {
      if (updatedMember?.person) {
        const changeProps = Object.entries(updatedMember).map<ChangeProps>(([key, value]) => ({
          key: key as keyof Member,
          value,
        }));

        const validBiblio = updatedMember?.person.bibliographicNames.find(x => x.name === author?.name) ?? null;
        if (!validBiblio)
          changeProps.push({ key: 'name', value: getBiblioName(updatedMember?.person.bibliographicNames, language) });

        onChange(changeProps);
      }
    },
    [author?.name, language, onChange],
  );

  const handleChangeEnterprise = useCallback((ref: ReferenceItem | null) => {
    makeChange('enterprise')(ref);
    makeChange('name')(ref?.shortName ?? '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCategory = (category: ReferenceItem | null) => {
    makeChange('category')(category);
    if (category === null) {
      handleChangeEnterprise(null);
      resetPerson();
      makeChange('name')('');
      return;
    }
    const prevCategory = author?.category ?? null;
    if (!isCollectiveAuthor(prevCategory) && isCollectiveAuthor(category)) {
      resetPerson();
      makeChange('name')('');
    }

    if (isCollectiveAuthor(prevCategory) && !isCollectiveAuthor(category)) {
      makeChange('name')('');
      handleChangeEnterprise(null);
    }
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useLayoutEffect(() => reloadBiblioOptions(), []);

  const renderCollectiveAuthorFields = () => (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={author?.enterprise}
            onChange={handleChangeEnterprise}
            settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Используемое наименование" isRequired>
          <TextInput value={author?.name ?? ''} onChange={makeChange('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  const renderPersonFields = () => (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Персона" isRequired>
          <SelectPerson member={author} onUpdateMember={updateAuthor} hiddenButtons={['delete']} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО в библ. записи" isRequired>
          <SelectBiblioName
            scientistPerson={scientistPerson}
            selectedBiblioName={author?.name ?? ''}
            onAfterAddBiblioName={reloadBiblioOptions}
            onChangeBiblioName={makeChange('name')}
            isRequired
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Категория ответственности" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={author?.category}
            onChange={handleChangeCategory}
            settings={{
              name: 'RefCategory',
              title: 'Справочник "Категории ответственности"',
              filters: [{ key: 'code', values: filters }],
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {author && isCollectiveAuthor(author.category) ? renderCollectiveAuthorFields() : renderPersonFields()}

      <FormComponent.Line>
        <AffilationField
          amountAffilation={String(author?.affilateCount ?? '')}
          isAffilated={author?.isAffilated ?? false}
          onChangeAmountAffilation={makeChange('affilateCount')}
          onChangeIsAffilation={handleChangeIsAffilation}
        />
      </FormComponent.Line>

      {(!author || !isCollectiveAuthor(author.category)) && (
        <MemberAffilations author={author} handleChangeMembersAffilations={handleChangeMembersAffilations} />
      )}
    </FormComponent.Wrapper>
  );
}

export { AuthorshipFields };

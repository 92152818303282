import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const RequestStatusParameterList = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    isWithoutTopFields
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    enumProps={{
      fieldLabel: 'Статус',
      listEditTitle: 'Список статусов заявки',
      mode: { component: 'DefaultSelect', enumName: 'RequestStatus' },
    }}
  />
);

const Component = memo(RequestStatusParameterList);

export { Component as RequestStatusParameterList };

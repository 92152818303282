import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps } from 'components';

import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Project, Table, Report } from 'types/models';
import { getMockProject } from 'features/Form/looks/project/ProjectForm/helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { validateProjectRestrictions } from 'features/Form/looks/project/ProjectForm/helpers/validate';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isOpenRestrictions, setIsOpenRestrictions] = useState<boolean>(false);
  const [project, setProject] = useState<Project.Project>(getMockProject());
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');
  const { methods: saveProjectRestrictionsAPI } = BackendAPI.useBackendAPI('SaveProjectRestrictions');

  const openRestriction = useCallback(() => {
    getProjectAPI.callAPI(
      { id: tableState.selectedRows[0].id },
      {
        onSuccessfullCall: ({ data }) => {
          setProject(data);
        },
      },
    );

    setIsOpenRestrictions(true);
  }, [getProjectAPI, tableState.selectedRows]);

  const onCloseRestrictions = useCallback(() => {
    setProject(getMockProject());
    setIsOpenRestrictions(false);
  }, []);

  const onSaveRestrictions = useCallback(() => {
    const invalidationInfo = validateProjectRestrictions(project).filter(x => !x.isValid);
    if (invalidationInfo.length) {
      showNotification({ message: invalidationInfo.map(x => x.invalidMessage).join('. '), theme: 'danger' });
      return;
    }

    saveProjectRestrictionsAPI.callAPI(
      { project },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Успешно сохранено', theme: 'success' });
          onCloseRestrictions();
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [onCloseRestrictions, project, saveProjectRestrictionsAPI, tableStreams.reloadTable]);

  const reports = useMemo<Report[]>(
    () => [
      Reports.ProjectsHasNotProjectCode,
      Reports.ProjectsHasNotScientist,
      Reports.ProjectsHasNotStateNumber,
      Reports.ListMembersIfHasNotAppointed,
      Reports.ProjectsViolateTeamRequirements_3_Months,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        text: 'Ограничения',
        mode: ButtonMode.PRIMARY,
        title: 'Изменить ограничения проекта',
        onClick: openRestriction,
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport, openRestriction, tableState.selectedRows.length],
  );

  return {
    buttons,
    isOpenRestrictions,
    onCloseRestrictions,
    onSaveRestrictions,
    project,
    setProject,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpOpen,
    setIsHelpOpen,
  };
}

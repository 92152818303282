import React, { useState } from 'react';

import { Icon, ListEdit, Modal } from 'components';

import { Project } from 'types/models';
import { validateAct } from './validate';
import { ActFields } from 'features/Form/looks/project/ProjectForm/views/Acts/ActFields';
import { showNotification } from 'features/Notifications';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { calcStageTotalAmount } from 'features/Form/looks/project/ProjectForm/views/Acts/helpers';
import { useController } from './controller';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  project: Project.Project | null;
  acts: Project.Act[];
  onChange(refs: Project.Act[]): void;
  disabled?: boolean;
};

export function Acts(props: Props) {
  const { project, acts, onChange, disabled } = props;

  const {
    onClickPrint,
    isProjectNotSavedPopupOpen,
    setIsProjectNotSavedPopupOpen,
    projectActId,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController();

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  return (
    <>
      <ListEdit<{}, Project.Act>
        rows={acts ?? []}
        onChange={onChange}
        toolbar={[
          {
            icon: { type: 'question' },
            title: 'Помощь',
            onClick: () => setIsHelpModalOpen(true),
          },
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'print' },
            title: 'Печать АКТа',
            onClick: onClickPrint,
            isDisabled: row => !row,
          },
        ]}
        columns={[
          { label: 'Номер акта', formatValue: x => x.number },
          { label: 'Этап', formatValue: x => `${x?.stage?.label}` },
          { label: 'Подразделение', formatValue: x => `${x?.departments.map(d => d?.department?.unit?.label).join(', ')}` },
          { label: 'Сумма', formatValue: x => calcStageTotalAmount(project, x) },
          { label: 'Совет', formatValue: x => `${x?.council?.code || ''}` },
        ]}
        maxHeight="none"
        isDeleteConfirmEnabled
        isDisabled={disabled}
        specification={{
          mode: 'customModalComponent',
          modalTitle: (row, mode) => (mode === 'edit' ? 'Редактирование АКТа' : 'Добавление АКТа'),
          isFullScreenModal: true,
          renderComponent: (act, setAct) => <ActFields act={act} project={project} onActChange={setAct} />,
          validation: {
            checkIsValid: act => validateAct(act).every(x => x.isValid),
            onInvalidate: act => {
              validateAct(act).forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />

      <Modal
        isOpen={isProjectNotSavedPopupOpen}
        onClose={() => setIsProjectNotSavedPopupOpen(false)}
        title="Проект не сохранен"
        size="small"
      >
        <>
          Выполните команду "Сохранить и продолжить" проект <Icon type="save" mode="check" /> и затем повторите команду Печать
          АКТа
        </>
      </Modal>

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Акты"
        size="large"
      >
        {ProjectHelp().documentsActs}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectActId: projectActId ?? '',
        }}
      />
    </>
  );
}

import { Icon } from 'components';

import { Color } from 'constants/colors';

export function TenderHelp(): JSX.Element {
  return (
    <>
      <p>В ленте объявлений отображается список объявленных конкурсов.</p>
      <p>
        С помощью фильтров можно отсортировать конкурсы: по области, по категории конкурса и по категории участников (можно
        выбрать несколько значений).
      </p>
      <p>
        С помощью кнопки <Icon type="star" color={Color.primary} /> можно пометить объявление как избранное. Для просмотра списка
        избранного нажмите на переключатель "Показать только избранное" сверху страницы - отобразятся все объявления которые были
        отмечены.
      </p>
      <p>
        Для просмотра подробной информации о конкурсе нажмите "подробнее &gt;". В открывшемся модальном окне можно подробно
        ознакомится с конкурсом и просмотреть конкурсную документацию.
      </p>
    </>
  );
}

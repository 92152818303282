import React, { useCallback, useMemo, useState } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const { openEditor, setEditedBiblio, setMode, deleteBibliographicName, setDefaultBiblioName } = customState;

  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Использовать по умолчанию',
        onClick: setDefaultBiblioName,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          openEditor();
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          setEditedBiblio();
          openEditor();
        },
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsConfirmPopupOpened(true);
        },
        isDisabled: !tableState.selectedRows.length,
      },
    ],
    [tableState.selectedRows, openEditor, setEditedBiblio, setMode, setDefaultBiblioName],
  );

  const cancelDelete = useCallback(() => {
    setIsConfirmPopupOpened(false);
  }, []);

  const confirmDelete = useCallback(() => {
    deleteBibliographicName();
    setIsConfirmPopupOpened(false);
  }, [deleteBibliographicName]);

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpened}
        onClose={cancelDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDelete,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: cancelDelete,
          },
        ]}
        size="small"
      >
        <>Удалить вариант ФИО?</>
      </Modal>
    </>
  );
}

export { Toolbar };

import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { GetProjectStageList } from 'features/Table/specifications';
import { ReferenceItem } from 'components';
import { Parameters } from 'features/BuildReportPopup/Parameters';

const NotRequiredStages = ({ parameters, setParameterValueByName, parameterName, propsValues }: BuilderProps) => {
  const projectId =
    propsValues?.projectId || (parameters.find(x => x.name === Parameters.ProjectID)?.value as ReferenceItem)?.id || '';
  return (
    <DefaultParameterList
      parameterName={parameterName}
      setParameterValueByName={setParameterValueByName}
      parameters={parameters}
      disabled={!projectId}
      parentId={projectId}
      tableModalProps={{
        modalTableSpecification: GetProjectStageList({ projectId }),
        modalTableRowConverter: row => ({ id: row.id, label: `№${row.Number} (${row.StartDate} - ${row.EndDate})` }),
        listEditTitle: 'Список этапов',
        relationTableModalTitle: `Список этапов проекта${projectId ? `(ID ${projectId})` : ''}`,
      }}
    />
  );
};

const Component = memo(NotRequiredStages);

export { Component as NotRequiredStages };

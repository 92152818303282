import { Author, FileInfo, Form } from 'types/models';
import { PublicationProject } from 'types/models/Form';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

type Props = {
  isElectronic: boolean | undefined;
  electronicType: string | undefined;
  formFields: Form.Fields;
  projects: PublicationProject[];
  mobileRequests: Form.PublicationMobileRequest[];
  type: string | undefined;
  authors: Author[];
  status: string;
};

const formatToRequest = ({
  isElectronic,
  electronicType,
  formFields,
  projects,
  mobileRequests,
  type,
  authors,
  status,
}: Props) => ({
  simpleFields: {
    type: type?.toUpperCase() || '',
    status,
    isElectronic: !!isElectronic,
    electronicType: (!!isElectronic && electronicType) || '',
    typeEdition: 'LOCAL',
    year: formFields.year.value,
    name: clearWhitespaceCharacters(formFields.name.value || ''),
    udk: formFields.udk.value as string,
    printPageCount: formFields.printPageCount.value || undefined,
    electronicMediaSource: formFields.electronicMediaSource.value as string,
    electronicSystemRequirements: formFields.electronicSystemRequirements.value as string,
    annotation: formFields.annotation.value || '',
    authorsKeyWords: formFields.authorsKeyWords.value || '',
    authorsCount: formFields.authorsCount.value || '',
    fractionalCount: formFields.fractionalScore.value || '',
    isbn: formFields.isbn.value as string,
    eIsbn: formFields.eIsbn.value as string,
    republishData: formFields.republishData.value as string,
    volumeNumber: formFields.volumeNumber.value as string,
    pageCount: formFields.pageCount.value || '',
    bibliographicRecord: formFields.bibliographicRecord.value as string,
    address: formFields.address.value as string,
    url: formFields.url.value as string,
    doi: formFields.doi.value as string,
    libraryLink: formFields.libraryLink.value as string,
    tirage: formFields.tirage.value as string,
    seriesTitle: formFields.seriesTitle.value as string,
    foreignName: formFields.foreignName.value || '',
    foreignAnnotation: formFields.foreignAnnotation.value || '',
    foreignAuthorsKeyWords: formFields.foreignAuthorsKeyWords.value || '',
    foreignAuthors: formFields.foreignAuthors.value || '',
    urlDate: formFields.urlDate.value || '',
    publicationDate: formFields.publicationDate.value as string,
  },
  arrayFields: {
    translations: { translation: [] },
  },
  attrIdFields: {
    grnti: formFields.grnti.value?.id,
    study: formFields.study.value?.id,
    textLanguage: formFields.textLanguage.value?.id,
    originalLanguage: formFields.originalLanguage.value?.id,
    grif: formFields.grif.value?.id,
    publisher: formFields.publisher.value?.id,
    categoryEdition: formFields.categoryEdition.value?.id,
  },
  authors,
  projects,
  mobileRequests: { mobileRequest: mobileRequests },
  file: formFields.file.value as FileInfo,
});

export { formatToRequest };

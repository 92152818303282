import { useCallback, useMemo, useState } from 'react';
import { ProjectScientistRole } from 'utils/Enums';
import { Estimate } from 'types/models';

type Props = {
  estimate: Estimate | null;
};

export function useController({ estimate }: Props) {
  const [calendarPlanRowIndex, setCalendarPlanRowIndex] = useState<number | null>(null);

  const findLeader = useCallback(
    () =>
      estimate?.projectCode?.project?.performers.find(x =>
        x?.jobPeriods ? x?.jobPeriods?.find(jp => jp.role?.value === ProjectScientistRole.LEADER) : false,
      ) ?? null,
    [estimate],
  );

  const projectLeader = useMemo(() => findLeader(), [findLeader]);

  return {
    projectLeader,
    calendarPlanRowIndex,
    setCalendarPlanRowIndex,
  };
}

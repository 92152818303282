export function SecurityDocumentMemberAnalyticGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит авторов всех видов Объектов интеллектуальной собственности (патенты, заявки на патент, свидетельства,
        заявки на получение свидетельства, ноу-хау, объекты авторского права, не аффилированные с университетом).
      </p>

      <div className="warning">
        <span>
          Данные об авторе выводятся в том виде, каком они зафиксированы в документе (ОИС). Для идентификации в моменте добавлены
          столбцы с актуальными на текущую дату персональными данными: статус, ФИО, контакты
        </span>
      </div>

      <table>
        <thead>
          <tr>
            <th>Название столбца</th>
            <th>Содержание</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td colSpan={2}>
              <strong>Источник данных – справочник персон</strong>
            </td>
          </tr>
          <tr>
            <td>Персона</td>
            <td>Текущий статус, ФИО (предыдущие ФИО, если есть)</td>
          </tr>
          <tr>
            <td>Дата рождения</td>
            <td>Дата рождения</td>
          </tr>
          <tr>
            <td>Контакты</td>
            <td>Телефон, E-mail</td>
          </tr>
          <tr>
            <td>Идентификаторы</td>
            <td>ID персоны в базах цитирования публикаций</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Источник данных — Сведения об авторах на форме ОИС</strong>
            </td>
          </tr>
          <tr>
            <td>Автор</td>
            <td>Фамилия Имя Отчество</td>
          </tr>
          <tr>
            <td>Гражданство</td>
            <td>Страна</td>
          </tr>
          <tr>
            <td>Степень</td>
            <td>уч.степень</td>
          </tr>
          <tr>
            <td>Звание</td>
            <td>уч.степень, ак. звание</td>
          </tr>
          <tr>
            <td>Место работы</td>
            <td>Организация, должность, подразделение</td>
          </tr>
          <tr>
            <td>Место обучения</td>
            <td>Организация, Студент/аспирант, специальность, подразделение</td>
          </tr>
          <tr>
            <td>Творческий вклад</td>
            <td>Процент творческого вклада автора</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Источник данных — Сведения об ОИС, вкладка Описание</strong>
            </td>
          </tr>
          <tr>
            <td>Вид документа</td>
            <td>Вид документа</td>
          </tr>
          <tr>
            <td>Вид ОИС</td>
            <td>Вид ОИС</td>
          </tr>
          <tr>
            <td>Название ОИС</td>
            <td>Название ОИС</td>
          </tr>
          <tr>
            <td>Номер документа</td>
            <td>Номер документа</td>
          </tr>
          <tr>
            <td>Номер заявки</td>
            <td>Номер заявки</td>
          </tr>
          <tr>
            <td>Начало действия</td>
            <td>Дата начала действия охранного документа ( для заявки – Дата подачи заявки)</td>
          </tr>
          <tr>
            <td>Окончание действия</td>
            <td>Дата начала действия охранного документа ( для ноу- хау и заявки – отсутствует)</td>
          </tr>
          <tr>
            <td>МПК</td>
            <td></td>
          </tr>
          <tr>
            <td>Правообладатели</td>
            <td>Название организации/ ФИО для физ.лиц</td>
          </tr>
          <tr>
            <td>ОИС связано - выводить</td>
            <td>из раздела Связь с подразделениями в формате: Факультет/институт</td>
          </tr>
          <tr>
            <td>Получен в проекте</td>
            <td>Тип проекта № номер проекта Название проекта (Руководитель проекта)</td>
          </tr>
          <tr>
            <td>Программа</td>
            <td>Программа, связанная с проектом</td>
          </tr>
          <tr>
            <td>Программа</td>
            <td>Программа</td>
          </tr>
          <tr>
            <td colSpan={2}>
              <strong>Источник данных — Сведения об ОИС, вкладка Классификаторы</strong>
            </td>
          </tr>
          <tr>
            <td>ГРНТИ</td>
            <td>Коды ГРНТИ</td>
          </tr>
          <tr>
            <td>OECD</td>
            <td>Классификатор ОЭСР (ОЕСD)</td>
          </tr>
          <tr>
            <td>Рынки НТР</td>
            <td>Рынки Национальной технологической инициатив</td>
          </tr>
          <tr>
            <td>Приоритеты Стратегии НТР РФ</td>
            <td>Стратегии научно-технологического развития Российской Федерации</td>
          </tr>
          <tr>
            <td>Технологии НТИ</td>
            <td>Сквозные технологии Национальной технологической инициативы</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController(tableState: Table.State) {
  const tableStreams = useLocalTableStreams();

  const reports = useMemo<Report[]>(
    () => [
      Reports.SecurityDocumentsIndicators,
      Reports.UsingSecurityDocuments,
      Reports.ResultsSTA,
      Reports.InventoryScienceProtection,
      Reports.InventoryScienceCommercialization,
      Reports.ContractIntellectualProperty,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ProjectSecurityDocumentContract',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpModal(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.CONTRACT_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.CONTRACT_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.CONTRACT_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.CONTRACT_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      isViewButtonDisabled,
      handleAddButtonClick,
      handleEditButtonClick,
      isEditButtonDisabled,
      handleDeleteButtonClick,
      isDeleteButtonDisabled,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetNotificationLogList, GetPurchaseRequestList, GetPurchaseRequestUnitList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { NotificationEventModule } from 'utils/Enums';

export function Purchases() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['purchases'],
    init: ['list'],
  });

  const { userPermission } = useAppDataContext();

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab
          title="Список заявок на закупки"
          id="list"
          isVisible={
            isHasPermission(userPermission, Permits.PURCHASE_REQUEST_ACCESS) &&
            isHasPermission(userPermission, [Permits.PURCHASE_REQUEST_VIEW_ALL, Permits.PURCHASE_REQUEST_VIEW_BY_MANAGEMENT])
          }
        >
          <DataGrid specification={GetPurchaseRequestList({})} />
        </Tab>
        <Tab
          title="Список всех товаров, услуг, работ из заявок"
          id="units"
          isVisible={isHasPermission(userPermission, Permits.PURCHASE_REQUEST_ACCESS)}
        >
          <DataGrid specification={GetPurchaseRequestUnitList({ withTemplatesPanel: true })} />
        </Tab>
        <Tab
          title="Журнал уведомлений"
          id="notifications"
          isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
        >
          <DataGrid specification={GetNotificationLogList({ module: NotificationEventModule.PURCHASE_REQUEST })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

export enum PublicationType {
  MAGAZINE_ARTICLE = 'MAGAZINE_ARTICLE',
  ARTICLE_CONFERENCE = 'ARTICLE_CONFERENCE',
  ARTICLE_SCIENTIFIC_WORK = 'ARTICLE_SCIENTIFIC_WORK',
  ARTICLE_OTHER = 'ARTICLE_OTHER',
  OTHER_PART = 'OTHER_PART',
  THESIS = 'THESIS',
  MONOGRAPH = 'MONOGRAPH',
  MONOGRAPH_CHAPTER = 'MONOGRAPH_CHAPTER',
  COMPILATION_CONFERENCE = 'COMPILATION_CONFERENCE',
  COMPILATION_SCIENTIFIC_WORK = 'COMPILATION_SCIENTIFIC_WORK',
  COMPILATION_OTHER = 'COMPILATION_OTHER',
  OTHER_BOOK = 'OTHER_BOOK',
  SYNOPSIS = 'SYNOPSIS',
  DISSERTATION = 'DISSERTATION',
  PREPRINT_ARTICLE = 'PREPRINT_ARTICLE',
  TEXTBOOK = 'TEXTBOOK',
  METHODICAL_EDITION_OTHER = 'METHODICAL_EDITION_OTHER',
  TUTORIAL = 'TUTORIAL',
  ARTICLE_ARTICLES = 'ARTICLE_ARTICLES',
  COMPILATION_ARTICLES = 'COMPILATION_ARTICLES',
  DEPOSITED = 'DEPOSITED',
  METHODICAL_EDITION = 'METHODICAL_EDITION',
  MAGAZINE = 'MAGAZINE',
}

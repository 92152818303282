import { useCallback } from 'react';

import { MobileRequest } from 'types/models';
import { ReferenceItem } from 'components';

type Props = {
  entry: MobileRequest.UseMaterialObligation | null;
  onFieldChange(row: MobileRequest.UseMaterialObligation | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleResultChange = useCallback(
    (e: ReferenceItem) => {
      onFieldChange({
        id: entry?.id || null,
        result: e,
        resultDate: entry?.resultDate || '',
        resultDescription: entry?.resultDescription || '',
      });
    },
    [entry?.id, entry?.resultDate, entry?.resultDescription, onFieldChange],
  );

  const handleResultDateChange = useCallback(
    (e: string) => {
      onFieldChange({
        id: entry?.id || null,
        result: entry?.result || null,
        resultDate: e,
        resultDescription: entry?.resultDescription || '',
      });
    },
    [entry?.id, entry?.result, entry?.resultDescription, onFieldChange],
  );

  const handleResultDescriptionChange = useCallback(
    (e: string) => {
      onFieldChange({
        id: entry?.id || null,
        result: entry?.result || null,
        resultDate: entry?.resultDate || '',
        resultDescription: e,
      });
    },
    [entry?.id, entry?.result, entry?.resultDate, onFieldChange],
  );

  return {
    handleResultChange,
    handleResultDateChange,
    handleResultDescriptionChange,
  };
}

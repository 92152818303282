import React from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, TextArea, TextAreaMode } from 'components';

import { GetPersonAvailableHistoryElements } from 'features/Table/specifications';
import { GetLinkedHistoryEntitiesRequestData, HistoryEntity } from 'services/BackendAPI/configurations/author/types';
import useController from './controller';
import { Table } from 'types/models';

type Props = {
  isOpen: boolean;
  historyEntities: HistoryEntity[];
  personId?: string;
  onClose: () => void;
} & GetLinkedHistoryEntitiesRequestData;

export const EditRelations = ({ isOpen, personId, onClose, type, id, historyEntities }: Props) => {
  const {
    fields,
    preparedTitle,
    entityLabel,
    tableTitle,
    confirmTextEntity,
    confirmTitle,
    isConfirmPopupOpen,
    closeConfirmPopup,
    onSubmit,
    onContinueClick,
    onIsNeedDeleteRelationChange,
    tableRowConverter,
    makeFieldChangeHandler,
  } = useController({ type, historyEntities, onClose });

  return (
    <Modal
      title={preparedTitle}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Продолжить',
          onClick: onContinueClick,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <FormComponent.Line>
        <FormComponent.Field label="Удалить связь">
          <Checkbox checked={fields.isNeedDeleteRelation} onChange={onIsNeedDeleteRelationChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      {fields.isNeedDeleteRelation && (
        <FormComponent.Line>
          <FormComponent.Field label="Оставить исторический срез">
            <Checkbox
              checked={fields.isNeedHistorySlice}
              onChange={makeFieldChangeHandler('isNeedHistorySlice')}
              isDisabled={!fields.isNeedDeleteRelation}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label={entityLabel} isRequired={!fields.isNeedDeleteRelation}>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: tableTitle,
              isClearable: fields.isNeedDeleteRelation,
              table: {
                specification: GetPersonAvailableHistoryElements({ id, personId, type }),
                onSelect: (row: Table.Entry | null) =>
                  makeFieldChangeHandler('entityToReplace')(row ? tableRowConverter(row) : null),
              },
            }}
            value={fields.entityToReplace?.label}
            isDisabled={fields.isNeedDeleteRelation}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <Modal
        mode="info"
        title={confirmTitle}
        isOpen={isConfirmPopupOpen}
        onClose={closeConfirmPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onSubmit,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeConfirmPopup,
          },
        ]}
        size="small"
      >
        <p>Произвести замену исторического среза {confirmTextEntity} в выбранных элементах?</p>
        <p>Замена будет произведена незамедлительно</p>
        <p>Продолжить?</p>
      </Modal>
    </Modal>
  );
};

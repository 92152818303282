import React, { useMemo } from 'react';
import * as FormTabs from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetProjectFeedlineList } from 'features/Table/specifications';
import { ByPrototypeCopyOption, ProjectType } from 'utils/Enums';
import { getEnumItemLabel } from 'utils/Helpers';
import { usePermissions } from 'utils/Helpers/projects/usePermissions';
import { useAppDataContext } from 'features/AppData/context';

import { Mode } from 'features/IncentivePayments';
import { Toolbar as CommonToolbar } from './Toolbar';
import { useController } from './controller';
import { GetProjectAssignmentList } from 'features/Table/specifications/GetProjectAssignmentList';
import { GetPurchaseRequestList } from 'features/Table/specifications/GetPurchaseRequestList';
import { StateReports } from './Tabs/StateRegistration/StateReports';

type Props = {
  id: string | null;
  isOpen: boolean;
  disabled: boolean;
  onClose(): void;
  onSuccessfullSave?(): void;
  mode: Mode;
  nirRequestId?: string;
  projectType?: ProjectType;
  securityDocumentContractId?: string;
  programRequestId?: string;
  projectCopyOptions?: ByPrototypeCopyOption[];
  prototypeId?: string;
};

const Component: React.FC<Props> = (props: Props) => {
  const {
    id,
    isOpen,
    onClose,
    mode,
    disabled,
    onSuccessfullSave,
    nirRequestId,
    projectType: initialProjectType,
    securityDocumentContractId,
    programRequestId,
    projectCopyOptions,
    prototypeId,
  } = props;

  const {
    enumMap,
    project,
    projectType,
    validateAndSave,
    reloadProject,
    makeChangeHandler,
    setProject,
    handleSaveClick,
    copyFromNirRequest,
    indicators,
    indices,
    handleActsChange,
    acts,
    isEndDateChangeWarningOpen,
    setIsEndDateChangeWarningOpen,
    confirmEndDateChangeWarning,
    isUnsavedWarningOpen,
    setIsUnsavedWarningOpen,
    handleUnsavedWarningConfirm,
    isOptionalValidateModalOpen,
    setIsOptionalValidateModalOpen,
    optionalValidateMessages,
    confirmOptionalValidatePopup,
    resetOptionalValidatePopup,
    tabsId,
    copyFromProgramRequest,
    copyFromContestRequest,
    getIsProjectChanged,
    isDocumentsWarningOpen,
    setIsDocumentWarningOpen,
  } = useController({
    onSuccessfullSave,
    id,
    nirRequestId,
    initialProjectType,
    securityDocumentContractId,
    programRequestId,
    projectCopyOptions,
    prototypeId,
  });

  const { userPermission } = useAppDataContext();

  const permissions = projectType ? usePermissions(projectType, userPermission) : null;

  const title = useMemo(() => {
    const additionalText = (() => {
      const result = [
        project?.specification?.label,
        [project?.id ? `ID ${project?.id}` : '', project?.status ? `статус ${project?.status}` : ''].filter(Boolean).join(', '),
      ]
        .filter(Boolean)
        .join('. ');
      if (result) {
        return `(${result})`;
      }
      return '';
    })();

    return [
      mode === 'view' ? 'Просмотр' : mode === 'edit' ? 'Редактирование' : 'Добавление',
      'проекта',
      project?.number ? `№ ${project?.number}${project.startDate && project.endDate ? ',' : ''}` : '',
      project.startDate && project.endDate ? `${project.startDate} - ${project.endDate}` : '',
      additionalText,
      projectType ? `Тип: ${getEnumItemLabel('ProjectType', projectType, enumMap)}` : '',
    ]
      .filter(Boolean)
      .join(' ');
  }, [
    mode,
    project?.number,
    project.startDate,
    project.endDate,
    project?.specification?.label,
    project?.id,
    project?.status,
    projectType,
    enumMap,
  ]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={!!project?.type?.label ? title : ''} size="full">
      {!!projectType && !!permissions && (
        <FormComponent.Template>
          <CommonToolbar
            projectId={project?.id || null}
            projectNumber={project?.number}
            onSave={handleSaveClick()}
            onSaveAndContinue={handleSaveClick(true)}
            hidden={disabled}
          />

          <FormComponent.Template>
            <Tabs id={tabsId}>
              <Tab title="О проекте" isVisible={permissions.isVisibleAboutProject}>
                <FormTabs.AboutProject
                  isEditableLocked={permissions.isEditableLocked}
                  project={project}
                  makeChangeHandler={makeChangeHandler}
                  setProject={setProject}
                  indicators={indicators}
                  indices={indices}
                  disabled={disabled || !permissions.isEditableAboutProject}
                />
              </Tab>
              <Tab title="Заявки" isVisible={permissions.isVisibleAboutProject} isDisabled={!project?.id}>
                <FormTabs.Requests
                  disabled={disabled || !permissions.isEditableAboutProject}
                  project={project}
                  saveProject={validateAndSave}
                  makeChangeHandler={makeChangeHandler}
                  copyFromNirRequest={copyFromNirRequest}
                  copyFromProgramRequest={copyFromProgramRequest}
                  copyFromContestRequest={copyFromContestRequest}
                />
              </Tab>
              <Tab title="Особенности" isVisible={permissions.isVisibleProjectFeatures || permissions.isVisibleRestrictions}>
                <Tabs>
                  <Tab title="Особенности финансирования" isVisible={permissions.isVisibleProjectFeatures}>
                    <FormTabs.FeaturesProject
                      disabled={disabled || !permissions.isEditableProjectFeatures}
                      project={project}
                      makeChangeHandler={makeChangeHandler}
                      projectType={projectType}
                    />
                  </Tab>
                  <Tab title="Требования к проекту" isVisible={permissions.isVisibleRestrictions}>
                    <FormTabs.Restrictions
                      project={project}
                      setProject={setProject}
                      isDisabled={!permissions.isEditableRestrictions}
                    />
                  </Tab>
                  <Tab title="Использование ресурсов и оборудования" isVisible={permissions.isVisibleProjectFeatures}>
                    <FormTabs.ResourceUsageDepartments project={project} setProject={setProject} disabled={disabled} />
                  </Tab>
                </Tabs>
              </Tab>
              <Tab title="Описание" isVisible={permissions.isVisibleProjectDescription}>
                <FormTabs.DescriptionProject
                  disabled={disabled || !permissions.isEditableProjectDescription}
                  project={project}
                  setProject={setProject}
                  makeChangeHandler={makeChangeHandler}
                />
              </Tab>
              <Tab title="Классификаторы" isVisible={permissions.isVisibleClassifiers}>
                <FormTabs.Classifiers
                  project={project}
                  disabled={disabled || !permissions.isEditableClassifiers}
                  makeChangeHandler={makeChangeHandler}
                />
              </Tab>
              <Tab title="Госрегистрация" isVisible={permissions.isVisibleStateRegistration}>
                <FormTabs.StateRegistration
                  disabled={disabled || !permissions.isEditableStateRegistration}
                  makeChangeHandler={makeChangeHandler}
                  project={project}
                  setProject={setProject}
                />
              </Tab>
              <Tab title="Коллектив" isVisible={permissions.isVisibleExecutors} isDisabled={!project?.id}>
                <FormTabs.Performers
                  project={project}
                  saveProject={validateAndSave}
                  reloadProject={reloadProject}
                  getIsProjectChanged={getIsProjectChanged}
                  projectType={projectType}
                  isDisabled={disabled}
                />
              </Tab>
              <Tab title="Партнёры" isVisible={permissions.isVisiblePartners}>
                <FormTabs.AllPerformers
                  disabled={disabled}
                  projectId={project?.id || null}
                  partners={project?.partners ?? []}
                  setPartners={makeChangeHandler('partners')}
                  internationalProgram={project?.internationalProgram || null}
                  setInternationalProgram={makeChangeHandler('internationalProgram')}
                  foreignEnterprise={project?.foreignEnterprise || null}
                  setForeignEnterprise={makeChangeHandler('foreignEnterprise')}
                  partnerCountries={project?.partnerCountries ?? []}
                  setPartnerCountries={makeChangeHandler('partnerCountries')}
                  projectType={projectType}
                />
              </Tab>
              <Tab title="Этапы" isVisible={permissions.isVisibleCalendarPlan}>
                <Tabs>
                  <Tab title="Этапы проекта">
                    <FormTabs.CalendarPlan
                      project={project}
                      setStages={makeChangeHandler('stages')}
                      withMessages={false}
                      disabled={disabled || !permissions.isEditableCalendarPlan}
                    />
                  </Tab>
                  <Tab title="Отчёты">
                    <StateReports
                      project={project}
                      setProject={setProject}
                      isFieldsHidden
                      withMessages={false}
                      disabled={disabled}
                    />
                  </Tab>
                </Tabs>
              </Tab>
              <Tab title="Результаты" isVisible={permissions.isVisibleResults}>
                <FormTabs.ResultsProject
                  project={project}
                  makeChangeHandler={makeChangeHandler}
                  disabled={disabled || !permissions.isEditableResults}
                  indicators={indicators}
                  indices={indices}
                />
              </Tab>
              <Tab title="Документы" isVisible={permissions.isVisibleDocuments}>
                <FormTabs.Documents
                  project={project}
                  acts={acts}
                  disabled={disabled}
                  handleActsChange={handleActsChange}
                  makeChangeHandler={makeChangeHandler}
                  isDocumentsWarningOpen={isDocumentsWarningOpen}
                  setIsDocumentWarningOpen={setIsDocumentWarningOpen}
                  projectType={projectType}
                />
              </Tab>
              <Tab title="Журнал сообщений" isDisabled={!project?.id}>
                <DataGrid
                  specification={GetProjectFeedlineList({
                    projectId: project?.id || '-1',
                    projectNumber: project?.number || '',
                    disabled,
                  })}
                />
              </Tab>
              <Tab title="Поручения" isVisible={permissions.isVisibleAssignments}>
                <DataGrid
                  specification={GetProjectAssignmentList({
                    projectId: project?.id || '-1',
                    isFiltersHidden: true,
                    disabled: disabled || !permissions.isEditableAssignments,
                  })}
                />
              </Tab>
              {!!project?.id && (
                <Tab title="Закупки" isVisible={permissions.isVisiblePurchaseRequests}>
                  <DataGrid
                    specification={GetPurchaseRequestList({
                      project: { value: project.id, label: [project.number, project.name].filter(Boolean).join(', ') },
                    })}
                  />
                </Tab>
              )}
            </Tabs>
          </FormComponent.Template>

          <Modal
            title="Предупреждение"
            isOpen={isEndDateChangeWarningOpen}
            onClose={() => setIsEndDateChangeWarningOpen(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Сохранить проект',
                onClick: confirmEndDateChangeWarning,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: () => setIsEndDateChangeWarningOpen(false),
              },
            ]}
            size="small"
          >
            <p>Вы изменили сроки проекта:</p>

            <ul>
              <li>Отредактируйте сроки этапов проекта в календарном плане</li>
              <li>Актуализируйте периоды работы для членов коллектива, после сохранения проекта</li>
            </ul>
          </Modal>

          <Modal
            mode="warning"
            title="Необходимо сохранить документ"
            isOpen={isUnsavedWarningOpen}
            onClose={() => setIsUnsavedWarningOpen(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Сохранить и продолжить',
                onClick: handleUnsavedWarningConfirm,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: () => setIsUnsavedWarningOpen(false),
              },
            ]}
            size="small"
          >
            <>Для фиксации изменений необходимо сохранить документ</>
          </Modal>

          <Modal
            mode="warning"
            title="Внимание"
            isOpen={isOptionalValidateModalOpen}
            onClose={() => setIsOptionalValidateModalOpen(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Сохранить',
                onClick: confirmOptionalValidatePopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Исправить',
                onClick: resetOptionalValidatePopup,
              },
            ]}
            size="small"
          >
            <p>
              <strong>Обнаружено несоответствие:</strong>
            </p>

            <ul>
              {optionalValidateMessages.map((x, index) => (
                <li key={index}>
                  <span dangerouslySetInnerHTML={{ __html: x || '' }} />
                </li>
              ))}
            </ul>
          </Modal>
        </FormComponent.Template>
      )}
    </Modal>
  );
};

export const Form = React.memo(Component) as typeof Component;

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { convertServerExponent } from 'services/BackendAPI/configurations/exponents/converters';
import { convertExponentToXml } from 'services/BackendAPI/configurations/exponents/requestConverters';
import { SaveExponentRequest } from 'services/BackendAPI/configurations/exponents/types';
import { convertSaveResponseXMLElementToOutput } from '../commonConverters';

export const crudExponentConfigurations = {
  SaveExponent: makeBackendAPIConfiguration({
    id: 'SaveExponent',
    endpoint: '/msa/service/commands/SaveExponent',
    convertInputToXMLElement: (inputData: SaveExponentRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveExponent',
        },
        Exponent: { ...convertExponentToXml(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetExponent: makeBackendAPIConfiguration({
    id: 'GetExponent',
    endpoint: '/msa/service/commands/GetExponent',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetExponent',
          },
          Exponent: {
            ...(id ? { _attr: { id } } : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertServerExponent(response.Response.Exponent, settings),
  }),
};

export const exponentConfigurations = {
  ...crudExponentConfigurations,
};

import { Program } from 'types/models';

type Props = {
  program: Program.Program | null;
  getProgram: any;
  isDisabled: boolean;
};

export type State = Props;

export function makeUseCustomController({ program, getProgram, isDisabled }: Props) {
  return function useCustomController(): State {
    return {
      program,
      getProgram,
      isDisabled: isDisabled || false,
    };
  };
}

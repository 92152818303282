import { Icon } from 'components';

export function PersonalAllSecurityDocumentGridHelp(): JSX.Element {
  return (
    <>
      <h4>
        Раздел содержит список патентов, свидетельств на изобретения, промышленные образцы, базы данных, программные продукты и
        прочие объекты, в которых университет является правообладателем.
      </h4>

      <p>
        В списке не отображаются ноу-хау (секрет производства — это сведения любого характера, о результатах интеллектуальной
        деятельности в научно-технической сфере и о способах осуществления профессиональной деятельности, имеющие действительную
        или потенциальную коммерческую ценность)
      </p>
      <p>
        Фильтр <strong>Действующие</strong> показывает список охранных документов, у которых на текущую дату не завершён срок
        действия.
      </p>
      <p>Используйте строку поиска по словам в столбцах таблицы для отбора ОИС по тематике, направлениям исследований.</p>
      <div>
        Список выбранных ОИС вы можете выгрузить в файл с помощью кнопки Выгрузить в файл <Icon type="excel" />
      </div>
    </>
  );
}

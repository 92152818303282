import { Author } from 'types/models';

type Props = {
  authors: Author[];
};

const useController = ({ authors }: Props) => {
  const countedCodes = ['author', 'group', 'author-compiler'];

  const affilatedAuthors = authors.filter(item => {
    return countedCodes.includes(item?.category?.customFields?.code?.toString() || '') && item.isAffilated;
  }).length;

  const affilatedCounter = authors.reduce((sum, item) => {
    return (sum = sum + (item.affilateCount || 0));
  }, 0);

  return { affilatedCounter, affilatedAuthors };
};

export default useController;

import React from 'react';

import { FormComponent, RefList } from 'components';

import { Rntd } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';

type Props = {
  rntd?: Rntd | null;
  makeChangeHandler: (key: keyof Rntd) => (val: ValueOf<Rntd>) => void;
  disabled: boolean;
};

export function Classifiers({ rntd, makeChangeHandler, disabled }: Props) {
  const { settings } = useAppDataContext();

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              header={{ title: 'ГРНТИ' }}
              rows={rntd?.grntis ?? []}
              onChange={makeChangeHandler('grntis')}
              refName="RefGrnti"
              modalTitle='Справочник "ГРНТИ"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'УДК' }}
              rows={rntd?.udks ?? []}
              onChange={makeChangeHandler('udks')}
              refName="RefUdk"
              modalTitle='Справочник "УДК"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'ОКВЭД' }}
              rows={rntd?.okveds ?? []}
              onChange={makeChangeHandler('okveds')}
              refName="RefOkved"
              modalTitle='Справочник "ОКВЭД"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'ЛКСЭЦ' }}
              rows={rntd?.lksetss ?? []}
              onChange={makeChangeHandler('lksetss')}
              refName="RefLksetss"
              modalTitle='Справочник "ЛКСЭЦ"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Отрасли науки' }}
              refName="RefScienceBrunch"
              rows={rntd?.scienceBrunches ?? []}
              onChange={makeChangeHandler('scienceBrunches')}
              modalTitle='Справочник "Отрасли науки"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Области научных интересов' }}
              rows={rntd?.scienceDomainInterrests ?? []}
              onChange={makeChangeHandler('scienceDomainInterrests')}
              refName="RefScienceDomainInterest"
              modalTitle='Справочник "Области научных интересов"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <RefList
              header={{ title: `ПНР ${settings?.organization?.shortName}` }}
              rows={rntd?.pnrs ?? []}
              onChange={makeChangeHandler('pnrs')}
              refName="RefPnr"
              modalTitle='Справочник "ПНР"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритетные направления модернизации и технического развития экономики' }}
              rows={rntd?.pnmitrs ?? []}
              onChange={makeChangeHandler('pnmitrs')}
              refName="RefPnr"
              modalTitle='Справочник "ПНР модернизации РФ"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритетные направления науки, технологий и техники' }}
              rows={rntd?.pnis ?? []}
              onChange={makeChangeHandler('pnis')}
              refName="RefPni"
              modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритеты Стратегии НТР РФ' }}
              rows={rntd?.ntrStrategies ?? []}
              onChange={makeChangeHandler('ntrStrategies')}
              refName="RefNtrStrategy"
              modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Технологические платформы' }}
              rows={rntd?.technologyPlatforms ?? []}
              onChange={makeChangeHandler('technologyPlatforms')}
              refName="RefTechnologyPlatform"
              modalTitle='Справочник "Технологические платформы"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Критические технологии' }}
              rows={rntd?.priorityTechnologies ?? []}
              onChange={makeChangeHandler('priorityTechnologies')}
              refName="RefPriorityTechnology"
              modalTitle='Справочник "Критические технологии"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Отрасли знаний (РНФ)' }}
              rows={rntd?.knowledgeBranches ?? []}
              onChange={makeChangeHandler('knowledgeBranches')}
              columns={[
                {
                  label: 'Отрасль знаний',
                  formatValue: x => x.label || '',
                  styles: { width: '50%' },
                },
                {
                  label: 'Группа (подгруппа)',
                  formatValue: x => getKnowledgeBranchGroupLabel(x),
                  styles: { width: '50%' },
                },
              ]}
              refName="RefKnowledgeBranch"
              modalTitle='Справочник "Отрасли знаний (РНФ)"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from '../commonConverters';
import { convertPurchaseRequest, convertPurchaseRequestMessage } from './converters';
import { convertPurchaseRequestToXML } from './requestConverters';
import { Settings } from 'types/models/common';
import { PurchaseRequestApprovementApprover, PurchaseRequestStatus } from 'utils/Enums';

const configurations = {
  GetPurchaseRequest: makeBackendAPIConfiguration({
    id: 'GetPurchaseRequest',
    endpoint: '/msa/service/commands/GetPurchaseRequest',
    convertInputToXMLElement: ({ id }: { id?: string }) => ({
      Request: {
        _attr: { commandName: 'GetPurchaseRequest' },
        PurchaseRequest: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertPurchaseRequest(response.Response.PurchaseRequest, settings),
  }),
  SavePurchaseRequest: makeBackendAPIConfiguration({
    id: 'SavePurchaseRequest',
    endpoint: `/msa/service/commands/SavePurchaseRequest`,
    convertInputToXMLElement: ({ purchaseRequest }: T.SavePurchaseRequest) => ({
      Request: {
        _attr: { commandName: 'SavePurchaseRequest' },
        PurchaseRequest: {
          _attr: purchaseRequest.id ? { id: purchaseRequest.id } : {},
          ...convertPurchaseRequestToXML(purchaseRequest),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangePurchaseRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangePurchaseRequestStatus',
    endpoint: '/msa/service/commands/ChangePurchaseRequestStatus',
    convertInputToXMLElement: ({ message, ids, status }: { message: string; ids: string[]; status: PurchaseRequestStatus }) => ({
      Request: {
        _attr: { commandName: 'ChangePurchaseRequestStatus' },
        PurchaseRequest: ids.map(id => ({
          _attr: { id },
          Status: status,
          ...(message ? { Message: message } : {}),
        })),
      },
    }),
    convertResponseXMLElementToOutput: () => {},
  }),
  PushPurchaseRequestMessage: makeBackendAPIConfiguration({
    id: 'PushPurchaseRequestMessage',
    endpoint: '/msa/service/commands/PushPurchaseRequestMessage',
    convertInputToXMLElement: ({ message, purchaseRequestId }: { message: string; purchaseRequestId: string }) => ({
      Request: {
        _attr: { commandName: 'PushPurchaseRequestMessage' },
        PurchaseRequest: {
          _attr: {
            id: purchaseRequestId,
            message,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  AddPurchaseRequestApprovement: makeBackendAPIConfiguration({
    id: 'AddPurchaseRequestApprovement',
    endpoint: '/msa/service/commands/AddPurchaseRequestApprovement',
    convertInputToXMLElement: ({
      purchaseRequestId,
      message,
      approverKind,
    }: {
      purchaseRequestId?: string;
      message: string;
      approverKind: PurchaseRequestApprovementApprover | null;
    }) => ({
      Request: {
        _attr: { commandName: 'AddPurchaseRequestApprovement' },
        PurchaseRequest: {
          _attr: {
            id: purchaseRequestId,
          },
          Approvement: {
            Message: message,
            Approver: approverKind,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetPurchaseRequestMessage: makeBackendAPIConfiguration({
    id: 'GetPurchaseRequestMessage',
    endpoint: '/msa/service/commands/GetPurchaseRequestMessage',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetPurchaseRequestMessage',
          },
          PurchaseRequestMessage: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertPurchaseRequestMessage(response.Response.PurchaseRequestMessage),
  }),
  DeletePurchaseRequestMessage: makeBackendAPIConfiguration({
    id: 'DeletePurchaseRequestMessage',
    endpoint: '/msa/service/commands/DeletePurchaseRequestMessage',
    convertInputToXMLElement: (inputData: { id?: string | null }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'DeletePurchaseRequestMessage',
          },
          PurchaseRequestMessage: {
            _attr: { id },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertPurchaseRequestMessage(response.Response.PurchaseRequestMessage),
  }),
};

export const purchaseRequestConfigurations = {
  ...configurations,
};

import { Dispatch, SetStateAction } from 'react';

import { Form } from 'types/models';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
};

const setByResponse = ({ setFormFields, preparedData, name }: Props) => {
  const { fields, file } = preparedData;

  setFormFields(prevState => ({
    ...prevState,
    name: {
      ...prevState.name,
      value: fields.name || name,
    },
    annotation: {
      ...prevState.annotation,
      value: fields.annotation,
    },
    authorsKeyWords: {
      ...prevState.authorsKeyWords,
      value: fields.authorsKeyWords,
    },
    doi: {
      ...prevState.doi,
      value: fields.doi,
    },
    affiliations: {
      ...prevState.affiliations,
      value: preparedData.affiliations,
    },
    isLocal: {
      ...prevState.isLocal,
      value: preparedData.isLocal,
    },
    publicationDate: {
      ...prevState.publicationDate,
      value: fields.publicationDate,
    },
    textLanguage: {
      ...prevState.textLanguage,
      value: {
        id: fields.textLanguageId || '',
        label: fields.textLanguageLabel || '',
      },
    },
    printPageCount: {
      ...prevState.printPageCount,
      value: fields.printPageCount || undefined,
    },
    vaks: {
      ...prevState.vaks,
      value: preparedData.vaks,
    },
    degree: {
      ...prevState.degree,
      value: {
        id: fields.degreeId || '',
        label: fields.degreeLabel || '',
      },
    },
    council: {
      ...prevState.council,
      value: preparedData.council,
    },
    address: {
      ...prevState.address,
      value: fields.address,
    },
    defencePlace: {
      ...prevState.defencePlace,
      value: fields.defencePlace,
    },
    year: {
      ...prevState.year,
      value: fields.year || new Date().getFullYear(),
    },
    pageCount: {
      ...prevState.pageCount,
      value: fields.pageCount,
      required: fields.isElectronic === 'false',
    },
    file: {
      ...prevState.file,
      value: file,
    },
    libraryLink: {
      ...prevState.libraryLink,
      value: fields.libraryLink,
    },
    defenceDate: {
      ...prevState.defenceDate,
      value: fields.defenceDate,
    },
    bibliographicRecord: {
      ...prevState.bibliographicRecord,
      value: fields.bibliographicRecord,
    },
    foreignName: {
      ...prevState.foreignName,
      value: fields.foreignName,
    },
    foreignAnnotation: {
      ...prevState.foreignAnnotation,
      value: fields.foreignAnnotation,
    },
    foreignAuthorsKeyWords: {
      ...prevState.foreignAuthorsKeyWords,
      value: fields.foreignAuthorsKeyWords,
    },
    foreignAuthors: {
      ...prevState.foreignAuthors,
      value: fields.foreignAuthors,
    },
  }));
};

export { setByResponse };

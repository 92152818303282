import React, { useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';

import { ButtonProps, FormComponent, ListEditTable, SectionTitle, TextArea, TextInput, Toolbar } from 'components';

// eslint-disable-next-line
import { Component as ScientificIndexComponent } from 'features/Table/specifications/GetScientificIndicesList/LeftPanelForThirdLevel/modalTemplates/ScientificIndexEditTemplate';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ScientificIndexForm({ viewMode, onClose }: Props) {
  const {
    formFields,
    onSubmit,
    selectedValueRowIndex,
    setSelectedValueRowIndex,
    selectedChildRowIndex,
    setSelectedChildRowIndex,
    values,
    childs,
    relatedTableState,
    isEditFormOpen,
    setIsEditFormOpen,
    getScientificIndex,
    scientificIndexId,
  } = useController();

  const handleFormSubmit = useCallback(() => {
    ReactDOM.unstable_batchedUpdates(() => {
      onSubmit();
      onClose();
    });
  }, [onClose, onSubmit]);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
    if (scientificIndexId) getScientificIndex.callAPI({ id: scientificIndexId });
  }, [getScientificIndex, scientificIndexId, setIsEditFormOpen]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          handleEditButtonClick();
        },
        isDisabled: !(selectedChildRowIndex || selectedChildRowIndex === 0),
      },
    ],
    [handleEditButtonClick, selectedChildRowIndex],
  );

  return (
    <FormComponent.Template>
      {!!relatedTableState && !!(selectedChildRowIndex || selectedChildRowIndex === 0) && (
        <ScientificIndexComponent
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={childs[selectedChildRowIndex].id}
          relatedTableState={relatedTableState}
          disableUpdateAfterSubmit
        />
      )}

      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Field label={formFields.citationSystem.title} isRequired>
          <TextInput value={formFields.citationSystem.value} onChange={formFields.citationSystem.onChange} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.domain.title} isRequired>
          <TextInput value={formFields.domain.value} onChange={formFields.domain.onChange} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.code.title} isRequired>
          <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={!!viewMode} />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.label.title} isRequired>
          <TextInput value={formFields.label.value} onChange={formFields.label.onChange} isDisabled={!!viewMode} />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.note.title}>
          <TextArea
            settings={{ rows: 3 }}
            value={formFields.note.value}
            onChange={formFields.note.onChange}
            isDisabled={!!viewMode}
          />
        </FormComponent.Field>

        <FormComponent.Line>
          <FormComponent.Field>
            <SectionTitle title="Доступные значения показателя" isTableHeader />

            <ListEditTable
              rows={values}
              columns={[
                { label: 'Наименование', formatValue: row => row.name || '' },
                { label: 'Код', formatValue: row => row.code || '' },
                { label: 'Тип данных', formatValue: row => row.type },
                { label: 'Метатип данных', formatValue: row => row.metaType || '' },
                { label: 'Обязательный', formatValue: row => (row.required ? 'Да' : 'Нет') },
              ]}
              selectedRowIndex={selectedValueRowIndex}
              selectRow={setSelectedValueRowIndex}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        {!!childs.length && (
          <FormComponent.Line>
            <FormComponent.Field>
              <SectionTitle title="Дочерние показатели" isTableHeader />

              <Toolbar buttons={buttons} mode="list" />

              <ListEditTable
                rows={childs}
                columns={[
                  { label: 'Наименование', formatValue: row => row.name || '' },
                  { label: 'Краткое обозначение', formatValue: row => row.label || '' },
                ]}
                selectedRowIndex={selectedChildRowIndex}
                selectRow={setSelectedChildRowIndex}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ScientificIndexForm);

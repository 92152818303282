import React from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, TextInput } from 'components';

import useController from './controller';
import { Column, Setting } from 'types/models/Table';
import { CrudCallbackArguments } from 'features/Table/ThirdLevelHeader/TemplatesPanel/types';

export type Props = {
  mode: 'add' | 'edit';
  isOpen: boolean;
  onClose(): void;
  setting?: Setting;
  columns: Column[];
  gridName: string;
  crudCallback?({ operation, name, author }: CrudCallbackArguments): void;
  successfullCallback?(setting: Setting): void;
};

const ModalTemplate: React.FC<Props> = (props: Props) => {
  const { mode, isOpen } = props;
  const { settings, setSettings, onSubmit, onCloseCallback } = useController(props);

  return (
    <Modal
      title={`${mode === 'add' ? 'Создание' : 'Редактирование'} шаблона`}
      isOpen={isOpen}
      onClose={onCloseCallback}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Сохранить шаблон',
          onClick: onSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onCloseCallback,
        },
      ]}
      size="medium"
    >
      {mode === 'add' && (
        <FormComponent.Description mode="warning">
          Текущие настройки таблицы, в том числе, порядок размещения столбцов, сортировка и условия фильтрации в столбцах,
          сохранить в шаблоне
        </FormComponent.Description>
      )}
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Название шаблона">
          <TextInput value={settings.name} onChange={e => setSettings(prev => ({ ...prev, name: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Описание шаблона">
          <TextInput value={settings.purpose} onChange={e => setSettings(prev => ({ ...prev, purpose: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <Checkbox
            label="Поделиться со всеми"
            checked={!!settings.isShared}
            onChange={e => setSettings(prev => ({ ...prev, isShared: e }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </Modal>
  );
};

export const Component = React.memo(ModalTemplate);

export function MipDocumentChangesHelp(): JSX.Element {
  return (
    <>
      <p>
        Вкладка Журнал изменений предназначена для фиксирования ключевых атрибутов организации МИП в период его жизненного цикла:
        изменение названия или юридического адреса, выход учредителя, коды ОКВЭД и др. Справочник{' '}
        <strong>Категорий изменений</strong> можно пополнять по мере необходимости и в соответствии с политикой учёта МИП в
        университете. Вкладка доступна для пользователей с правом на Редактирование МИП.
      </p>
    </>
  );
}

import React from 'react';

import { Modal, Toolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { ReportByDepartmentMappingPublicationGridHelp } from './help';

function LeftPanelForThirdLevel() {
  const { buttons, isOpenHelpForm, setIsOpenHelpForm, isReportOpen, onReportClose, currentReport } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ReportByDepartmentMappingPublicationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { memo } from 'react';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Form } from 'features/Form';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { EditCompilationArticleCompilationPopup } from 'features/Form/views';
import useController from './controller';

type WrapperProps = {
  parentId?: string;
  parentTypeCode?: string;
  isSetupSourceRelationMode: boolean;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ parentId, parentTypeCode, isSetupSourceRelationMode }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isEditArticlesRelationPopupOpen,
      handleCloseEditArticlesRelationPopup,
      toolbarButtons,
      isEditFormOpen,
      isAddFormOpen,
      closeAddForm,
      closeEditForm,
      isApproveFormOpen,
      onApprove,
      closeApproveForm,
      closeConfirmDeletePopup,
      confirmDelete,
      isConfirmDeletePopupOpen,
      look,
      title,
      isRowCanDeleted,
    } = useController({
      tableState,
      parentId,
      parentTypeCode,
      isSetupSourceRelationMode,
    });

    return (
      <>
        <Toolbar buttons={toolbarButtons} />

        <Modal isOpen={isAddFormOpen} onClose={closeAddForm} title={title} size={['extralarge', 'medium-height']}>
          <Form look={look} onClose={closeAddForm} />
        </Modal>

        <Modal isOpen={isEditFormOpen} onClose={closeEditForm} title={title} size={['extralarge', 'medium-height']}>
          <Form look={look} onClose={closeEditForm} />
        </Modal>

        <Modal
          mode="warning"
          title={isRowCanDeleted ? 'Удаление статьи' : 'Предупреждение'}
          isOpen={isConfirmDeletePopupOpen}
          onClose={closeConfirmDeletePopup}
          actions={
            isRowCanDeleted
              ? [
                  {
                    mode: ButtonMode.PRIMARY,
                    text: 'Да',
                    onClick: confirmDelete,
                  },
                  {
                    mode: ButtonMode.SECONDARY,
                    text: 'Отмена',
                    onClick: closeConfirmDeletePopup,
                  },
                ]
              : [
                  {
                    mode: ButtonMode.PRIMARY,
                    text: 'Закрыть',
                    onClick: closeConfirmDeletePopup,
                  },
                ]
          }
          size="small"
        >
          <>
            {isRowCanDeleted
              ? `(${tableState.selectedRows[0]?.bibliographic_record}) Удалить статью?`
              : 'Удаление статьи в статусе "Утверждено" не доступно. Для удаления статьи ее нужно перевести в статус "Добавлено"'}
          </>
        </Modal>

        <ApprovePopup
          isOpen={isApproveFormOpen}
          onClose={closeApproveForm}
          onApprove={onApprove}
          selectedRowId={tableState.selectedRows.length ? tableState.selectedRows[0]?.id : null}
        />

        <EditCompilationArticleCompilationPopup
          isOpen={isEditArticlesRelationPopupOpen}
          onClose={handleCloseEditArticlesRelationPopup}
          sourcePublicationId={parentId || ''}
          articlePublicationIds={tableState.selectedRows.map(({ id }) => id as string)}
        />
      </>
    );
  });

export function ParticipationForPublicationGridHelp(): JSX.Element {
  return (
    <>
      <p>В списке отображаются записи участий в конференции, в которых</p>

      <ul>
        <li>формат участия = участие с докладом</li>
        <li>НЕ указана публикация</li>
        <li>список персон авторского состава публикации пересекается со списком персон – участников конференции</li>
      </ul>
    </>
  );
}

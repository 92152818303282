import * as XML from 'xml-js';
import { SuchLikePublication } from 'types/models';

import { makeBackendAPIConfiguration } from './makeBackendAPIConfiguration';

const convertSuchlikePublicationResponseXMLElementToOutput = (
  response: XML.ElementCompact,
): SuchLikePublication.GetSuchlikePublicationListData => {
  const success = response.Response._attributes.success === 'true';
  const message = success
    ? ''
    : response.Response._attributes.serverErrorMessage ||
      response.Response._attributes.errorMessage ||
      'Произошла ошибка при запросе дубликатов';

  const list: SuchLikePublication.State['suchLikePublicationList'] = [];

  if (response.Response.SuchlikeEntity) {
    const { SuchlikeEntity } = response.Response;
    const isArray = SuchlikeEntity instanceof Array;
    const preparedSuchlikeEntityArray: SuchLikePublication.State['suchLikePublicationList'] = [];
    if (isArray) {
      preparedSuchlikeEntityArray.push(...SuchlikeEntity);
    } else {
      preparedSuchlikeEntityArray.push(SuchlikeEntity);
    }

    preparedSuchlikeEntityArray.forEach((item: any) => {
      list.push({
        id: item._attributes?.id || '',
        name: item.Name?._text || '',
        type: {
          label: item.Type?._attributes?.label || '',
          value: item.Type?._text || '',
        },
        status: {
          label: item.Status?._attributes?.label || '',
          value: item.Status?._text || '',
        },
        createdBy: {
          id: item.CreatedBy?._attributes?.id || '',
          name: item.CreatedBy?._text || '',
        },
        eventType: {
          label: item.EventType?._attributes?.label || '',
          value: item.EventType?._text || '',
        },
        eventName: {
          label: item.EventType?._attributes?.label || '',
          value: item.EventType?._text || '',
        },
        participants: item.Participants?._text || '',
        reportTheme: item.ThemeReport?._text || '',
        supervisors: item.Supervisors?._text || '',
        createdDate: item.CreatedDate?._text || '',
        endDate: item.EndDate?._text || '',
        startDate: item.StartDate?._text || '',
        bibliographicRecord: item.BibliographicRecord?._text || '',
        departments: item.Departments?._text || '',
      });
    });
  }

  return { success, message, list };
};

export function makeBackendSuchlikePublicationAPIConfiguration<T extends string, Input>(
  command: T,
  convertInputToXMLElement: (input: Input) => XML.ElementCompact,
) {
  return makeBackendAPIConfiguration({
    id: command,
    endpoint: `/msa/service/commands/${command}`,
    convertInputToXMLElement,
    convertResponseXMLElementToOutput: convertSuchlikePublicationResponseXMLElementToOutput,
  });
}

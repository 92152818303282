import React from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { AuthorsComponent } from 'features/Form/views/AuthorsComponent';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const PublicationThesis = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    onCreateBibliographicRecordClick,
    sourceCompilation,
    changeSourceCompilation,
    onDeleteSourceCompilation,
    compilationType,
    electronicType,
    isElectronic,
    authors,
    documents,
    projects,
    mobileRequests,
    relatedTableState,
    publicationInfo,
    publicationId,
    topMessage,
    sourcePublicationEvents,
    sourcePublicationReports,
    setAuthors,
    setDocuments,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    setTopMessage,
    handleCloseConferenceConnectionModal,
    handleOpenAddConferenceParticipation,
    isConferenceConnection,
    eventId,
    sourcePublicationDate,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
    setProjects,
    setMobileRequests,
  } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={handlePublicationSave}
        handleSaveAsDraft={handlePublicationSaveAsDraft}
        handleSaveAndContinue={handlePublicationSaveAndContinue}
        viewMode={viewMode}
        isDisabled={isLoading}
      />

      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О публикации">
            <TabsContent.BibliographyComponent
              publicationInfo={publicationInfo}
              electronicType={electronicType}
              isElectronic={isElectronic}
              workMode={workMode}
              formFields={formFields}
              userDepartments={userDepartments}
              publicationId={publicationId}
              publicationDepartment={publicationDepartment}
              setPublicationDepartment={setPublicationDepartment}
              arm={arm}
            />
          </Tab>
          <Tab title="Сведения об авторстве">
            <AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
          </Tab>
          <Tab title="Сведения об издании">
            <TabsContent.SourceComponent
              eventId={eventId}
              publicationId={publicationInfo?.status.id || ''}
              formFields={formFields}
              onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
              changeSourceCompilation={changeSourceCompilation}
              onDeleteSourceCompilation={onDeleteSourceCompilation}
              sourceCompilation={sourceCompilation}
              compilationType={compilationType}
              isElectronic={isElectronic}
              sourceParticipationReport={sourcePublicationReports}
              sourceEvents={sourcePublicationEvents}
              workMode={workMode}
              sourcePublicationDate={sourcePublicationDate}
            />
          </Tab>
          <Tab title="О публикации (анг.)">
            <TabsContent.AboutEnglishComponent formFields={formFields} viewMode={viewMode} />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexingComponent
              relatedTableState={relatedTableState}
              citationSystems={publicationInfo?.citationSystems ?? []}
              publicationId={publicationId || null}
              publicationType={publicationInfo?.type ?? ''}
              workMode={workMode}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              sourceId={sourceCompilation?.id}
              topMessage={topMessage}
              setTopMessage={setTopMessage}
            />
          </Tab>
          <Tab title="Финансовая поддержка">
            <TabsContent.EventComponent
              workMode={workMode}
              relatedTableState={relatedTableState}
              projects={projects}
              setProjects={setProjects}
              mobileRequests={mobileRequests}
              setMobileRequests={setMobileRequests}
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationId}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />

      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />

      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />

      <Modal
        mode="info"
        title="Создать запись об участии в конференции на основе метаданных публикации"
        isOpen={isConferenceConnection}
        onClose={handleCloseConferenceConnectionModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Добавить запись об участии в конференции',
            onClick: handleOpenAddConferenceParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConferenceConnectionModal,
          },
        ]}
        size="medium"
      >
        <>
          Для отчётности по научной деятельности (персональная, по подразделению, отчёт по НИР и ОКР, в заявках на внутренний
          конкурс и прочее) показатели Участия в научных мероприятиях (доклады, награды, уровень мероприятия и пр.) рассчитываются
          по данным записей &ldquo;Участия в конференциях&ldquo;
        </>
      </Modal>

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={handleCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: handleConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: handleResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <div>
          <strong>
            В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
            списке «Все публикации»
          </strong>
        </div>

        <p>Вашей персоны нет в списке авторов. Добавить?</p>
      </Modal>
    </FormComponent.Template>
  );
};

export const Component = React.memo(PublicationThesis);

import React, { memo } from 'react';

import { FormComponent, TextInput } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const AbstractString = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { inputValue, currentParameter, onInputChange } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });
  return (
    <FormComponent.Line>
      <FormComponent.Field label={currentParameter?.description ?? 'STRING INPUT'} isRequired={currentParameter?.required}>
        <TextInput value={inputValue} onChange={onInputChange} />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(AbstractString);

export { Component as AbstractString };

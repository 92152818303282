import React from 'react';

import { ListEdit, Modal, SectionTitle } from 'components';

import { getMockCharterCapital } from 'features/EditMipPartner/helpers';
import { MipPartner } from 'types/models';
import { showErrorsMessages } from 'utils/Common';

import { Founders } from './Founders';
import { Fields } from './Fields';
import { validate } from './validate';
import { useController } from './controller';
import { CharterCapitalsHelp } from './help';

type Props = {
  charterCapitals: MipPartner.CharterCapital[];
  setCharterCapitals: (charterCapitals: MipPartner.CharterCapital[]) => void;
  isDisabled: boolean;
};

export const CharterCapitals = ({ charterCapitals, setCharterCapitals, isDisabled }: Props) => {
  const {
    selectedRowIndex,
    setSelectedRowIndex,
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
    formatFounders,
    formatAsserts,
  } = useController();

  return (
    <>
      <SectionTitle title="Уставной капитал" />

      <ListEdit
        rows={charterCapitals}
        onChange={setCharterCapitals}
        toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
        columns={[
          { label: 'Дата изменения', formatValue: x => x.editDate, styles: { width: '20%' } },
          { label: 'Сумма, руб.', formatValue: x => x.amount, styles: { width: '20%' }, dataKind: 'float' },
          { label: 'Учредители', formatValue: formatFounders, styles: { width: '30%' } },
          { label: 'Активы', formatValue: formatAsserts, styles: { width: '30%' } },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={isDisabled}
        defaultRowsCount={5}
        onSelectedRowIndexCallback={setSelectedRowIndex}
        columnIndexesForSumTotal={[1]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Уставной капитал',
          renderComponent: (charterCapital, setCharterCapital) => (
            <Fields charterCapital={charterCapital ?? getMockCharterCapital()} setCharterCapital={setCharterCapital} />
          ),
          validation: {
            checkIsValid: charterCapital => validate(charterCapital, charterCapitals).every(x => x.isValid),
            onInvalidate: charterCapital => {
              const validationInfo = validate(charterCapital, charterCapitals);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />

      <Founders charterCapitals={charterCapitals} selectedRowIndex={selectedRowIndex} />

      <Modal mode="help" title="Уставной капитал" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="medium">
        {CharterCapitalsHelp()}
      </Modal>
    </>
  );
};

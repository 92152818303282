import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { State } from '../makeUseCustomController';
import { useLocalTableStreams } from 'features/Table/hooks';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Mode } from 'features/Table/specifications/GetPublicationCitationSystemList/makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController({ tableState, customState }: Props) {
  const { publicationType, workMode } = customState;

  const tableStreams = useLocalTableStreams();

  const [mode, setMode] = useState<Mode>('add');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const selectedRow = useMemo(() => tableState.selectedRows[0], [tableState.selectedRows]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (selectedRow?.id) {
      tableStreams.deleteRow.push({
        command: 'DeleteEntity',
        deletedItemPropName: 'PublicationCitationSystemIndex',
        deleteRowId: selectedRow?.id,
      });
      setIsDeleteConfirmPopupOpen(false);
    }
  }, [tableStreams.deleteRow, selectedRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const getPermission = useMemo(() => {
    const mapPublicationTypes = {
      [PublicationTypes.COMPILATION_CONFERENCE.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_OTHER.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.MAGAZINE.code]: Permits.MAGAZINE_CITATION_SYSTEM_EDIT,
    };
    return mapPublicationTypes[publicationType as keyof typeof mapPublicationTypes] || Permits.PUBLICATION_CITATION_SYSTEM_EDIT;
  }, [publicationType]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          setIsFormOpen(true);
        },
        permission: { name: getPermission },
        isDisabled: workMode === 'viewMode',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          setIsFormOpen(true);
        },
        permission: { name: getPermission },
        isDisabled: !selectedRow || workMode === 'viewMode',
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: getPermission },
        isDisabled: !selectedRow || workMode === 'viewMode',
      },
    ],
    [workMode, getPermission, selectedRow, handleDeleteButtonClick],
  );

  return {
    mode,
    buttons,
    handleConfirmDeleteConfirmPopup,
    isFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    setIsFormOpen,
  };
}

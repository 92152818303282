import React from 'react';

import { FormComponent, Modal, ReferenceItem, TextArea, TextAreaMode, Select, SelectMode } from 'components';

import { PaymentFeedline } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  isOpen: boolean;
  paymentFeedline: PaymentFeedline | null;
  changeFeedline(key: keyof PaymentFeedline, value: ValueOf<PaymentFeedline>): void;
  onClose(): void;
  onSave(): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, paymentFeedline, onSave, changeFeedline } = props;

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Сохранение заявки" size="large">
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} handleCancel={onClose} />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тема" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={paymentFeedline?.messageCategory}
                onChange={(option: ReferenceItem) => changeFeedline('messageCategory', option)}
                settings={{
                  name: 'RefPaymentRequestMessageCategory',
                  title: 'Справочник "Категории сообщений по стимулирующим выплатам"',
                }}
                isDisabled={paymentFeedline?.isSystem}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сообщение">
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3 }}
                value={paymentFeedline?.message}
                onChange={value => changeFeedline('message', value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}

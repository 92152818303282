import React from 'react';

import { FormComponent, ReferenceItem, SectionTitle, Select, SelectMode, TextInput } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

type Props = {
  partner: Project.Partner;
  makeChangeHandler: (key: keyof Project.Partner) => (value: ValueOf<Project.Partner>) => void;
};

export function CoExecutorFields({ partner, makeChangeHandler }: Props) {
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Название договора">
          <TextInput value={partner.contractName} onChange={makeChangeHandler('contractName')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <SectionTitle title="Руководитель проекта" />
      <FormComponent.Line>
        <FormComponent.Field label="ФИО">
          <TextInput value={partner.leaderFullName} onChange={makeChangeHandler('leaderFullName')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ученая степень">
          <TextInput value={partner.leaderDegree} onChange={makeChangeHandler('leaderDegree')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Звание">
          <TextInput value={partner.leaderRank} onChange={makeChangeHandler('leaderRank')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Место работы">
          <Select
            mode={SelectMode.REFERENCE}
            value={partner.leaderJobEnterprise}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('leaderJobEnterprise')(option)}
            settings={{ name: 'RefEnterpriseCustomer', title: 'Справочник "Организации"', isClearable: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Должность">
          <TextInput value={partner.leaderAppointment} onChange={makeChangeHandler('leaderAppointment')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Контакты">
          <TextInput value={partner.leaderContacts} onChange={makeChangeHandler('leaderContacts')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

import { Project } from 'types/models';

export function validate(partner: Project.Partner | null) {
  if (!partner) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidRole = Boolean(partner.role);
  const isValidPartner = Boolean(partner.partner);

  const isValidContractType = Boolean(partner.contractType);

  return [
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: isValidPartner, invalidMessage: 'Укажите партнёра' },
    { isValid: isValidContractType, invalidMessage: 'Выберите вид договора' },
  ];
}

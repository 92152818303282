import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { Settings } from 'types/models/common';
import {
  convertMaybeArray,
  getId,
  getMaybeField,
  getText,
  makeParametersXMLElement,
  makeTableRequestXMLConverter,
} from '../commonConverters';
import {
  convertProjectFeedline,
  convertProject,
  convertCheckResult,
  getPerformersFromSecurityDocuments,
  convertPerformer,
  convertReport,
} from './converters';
import {
  convertPerformerToRequest,
  convertProjectToRequest,
  convertReportToXML,
  convertRestrictionToRequest,
} from './requestConverters';
import {
  ByPrototypeCopyOption,
  ContestRequestCopyOption,
  NirRequestCopyOption,
  ProjectType,
  RequestCopyOption,
} from 'utils/Enums';
import { Project } from 'types/models';
import { SaveProjectScientistsJobPeriodType } from 'features/Table/specifications/GetProjectScientistList/types';

export type ProjectEditPartialType = {
  projectIds: string[];
  mode: string;
  elementIds: string[];
};

export const tableProjectsConfigurations = {
  GetProjectList: makeBackendTableAPIConfiguration(
    `GetProjectList`,
    makeTableRequestXMLConverter('GetProjectList', ({ panelState, isTableExtended, requestData }: T.GetProjectsRequest) =>
      makeParametersXMLElement(
        [
          { name: 'type', value: requestData?.type },
          { name: 'extended', value: (!!isTableExtended).toString() },
          { name: 'withoutStateRegistration', value: (!!panelState.isWithoutStateRegistration).toString() },
        ].concat(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ),
    ),
  ),

  GetProjectPublicationList: makeBackendTableAPIConfiguration(
    'GetProjectPublicationList',
    makeTableRequestXMLConverter('GetProjectPublicationList', ({ requestData }: T.GetProjectPublicationListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectBaseDissertationList: makeBackendTableAPIConfiguration(
    'GetProjectBaseDissertationList',
    makeTableRequestXMLConverter('GetProjectBaseDissertationList', ({ requestData }: T.GetProjectBaseDissertationListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectParticipationEventList: makeBackendTableAPIConfiguration(
    'GetProjectParticipationEventList',
    makeTableRequestXMLConverter(
      'GetProjectParticipationEventList',
      ({ requestData }: T.GetProjectParticipationEventListRequest) =>
        makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetProjectSecurityDocumentList',
    makeTableRequestXMLConverter('GetProjectSecurityDocumentList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectPatentResearchList: makeBackendTableAPIConfiguration(
    'GetProjectPatentResearchList',
    makeTableRequestXMLConverter('GetProjectPatentResearchList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectFeedlineList: makeBackendTableAPIConfiguration(
    'GetProjectFeedlineList',
    makeTableRequestXMLConverter('GetProjectFeedlineList', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement([{ name: 'projectId', value: requestData?.projectId ?? '-1' }]),
    ),
  ),
  GetProjectCodeChangesInProject: makeBackendTableAPIConfiguration(
    'GetProjectCodeChangesInProject',
    makeTableRequestXMLConverter('GetProjectCodeChangesInProject', ({ requestData }: T.GetProjectParticipationEventListRequest) =>
      makeParametersXMLElement(
        [{ name: 'projectId', value: requestData?.projectId }].concat(
          requestData?.projectCodeId ? [{ name: 'projectCodeId', value: requestData.projectCodeId }] : [],
        ),
      ),
    ),
  ),
  GetProjectBoAccompliceList: makeBackendTableAPIConfiguration(
    'GetProjectBoAccompliceList',
    makeTableRequestXMLConverter('GetProjectBoAccompliceList', ({ requestData }: T.GetProjectBoAccompliceListRequest) =>
      makeParametersXMLElement([
        { name: 'projectId', value: requestData?.projectId ?? '-1' },
        { name: 'projectCodeId', value: requestData?.projectCodeId ?? '-1' },
      ]),
    ),
  ),
  GetProjectScientists: makeBackendAPIConfiguration({
    id: 'GetProjectScientists',
    endpoint: '/msa/service/commands/GetProjectScientists',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'GetProjectScientists' }, Project: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertMaybeArray(response.Response.ProjectScientist, (p: any) => convertPerformer(p, settings)),
  }),
};

const configurations = {
  CreateProject: makeBackendAPIConfiguration({
    id: 'CreateProject',
    endpoint: '/msa/service/commands/CreateProject',
    convertInputToXMLElement: ({
      type,
      programRequestId,
      nirRequestId,
      securityDocumentContractId,
    }: {
      type: ProjectType;
      programRequestId?: string;
      nirRequestId?: string;
      securityDocumentContractId?: string;
    }) => ({
      Request: {
        _attr: { commandName: 'CreateProject' },
        Project: {
          Type: type,
          ...(programRequestId ? { ProgramRequest: { _attr: { id: programRequestId } } } : {}),
          ...(nirRequestId ? { NirRequest: { _attr: { id: nirRequestId } } } : {}),
          ...(securityDocumentContractId ? { SecurityDocumentContract: { _attr: { id: securityDocumentContractId } } } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getId(response.Response.Project),
  }),

  AcceptPublicationProject: makeBackendAPIConfiguration({
    id: 'AcceptPublicationProject',
    endpoint: '/msa/service/commands/AcceptPublicationProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptPublicationProject' }, PublicationProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  AcceptParticipationProject: makeBackendAPIConfiguration({
    id: 'AcceptParticipationProject',
    endpoint: '/msa/service/commands/AcceptParticipationProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptParticipationProject' }, ParticipationProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  AcceptEventProject: makeBackendAPIConfiguration({
    id: 'AcceptEventProject',
    endpoint: '/msa/service/commands/AcceptEventProject',
    convertInputToXMLElement: ({ id }: T.AcceptRequest) => ({
      Request: { _attr: { commandName: 'AcceptEventProject' }, EventProject: { _attr: { id: id } } },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  GetProject: makeBackendAPIConfiguration({
    id: 'GetProject',
    endpoint: '/msa/service/commands/GetProject',
    convertInputToXMLElement: ({
      id,
      modules,
      type,
      nirRequestId,
      securityDocumentContractId,
      programRequestId,
      prototypeId,
      copyOptions,
    }: {
      id?: string;
      modules?: string[];
      type?: ProjectType;
      nirRequestId?: string;
      securityDocumentContractId?: string;
      programRequestId?: string;
      prototypeId?: string;
      copyOptions?: ByPrototypeCopyOption[];
    }) => ({
      Request: {
        _attr: { commandName: 'GetProject' },
        Project: {
          _attr: { ...(id ? { id } : {}), ...(modules ? { modules: modules.join(', ') } : {}) },
          ...getMaybeField('Type', type),
          ...(!!nirRequestId && { NirRequest: { _attr: { id: nirRequestId } } }),
          ...(!!securityDocumentContractId && { SecurityDocumentContract: { _attr: { id: securityDocumentContractId } } }),
          ...(!!programRequestId && { ProgramRequest: { _attr: { id: programRequestId } } }),
          ...(prototypeId ? { Prototype: { _attr: { id: prototypeId } } } : {}),
        },
        ...(copyOptions?.length ? { CopyOptions: { CopyOption: copyOptions.map(x => x) } } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertProject(response.Response.Project, settings),
  }),

  SaveProject: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.NIR}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  SaveProjectRestrictions: makeBackendAPIConfiguration({
    id: 'SaveProjectRestrictions',
    endpoint: `/msa/service/commands/SaveProjectRestrictions`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProjectRestrictions' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          Restrictions: { Restriction: project.restrictions.filter(i => i.isActive).map(convertRestrictionToRequest) },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  CalculateProjectRestrictions: makeBackendAPIConfiguration({
    id: 'CalculateProjectRestrictions',
    endpoint: `/msa/service/commands/CalculateProjectRestrictions`,
    convertInputToXMLElement: ({ projectIds }: T.CalculateProjectRestrictionsType) => ({
      Request: {
        _attr: { commandName: 'CalculateProjectRestrictions' },
        Project: projectIds.map(projectId => ({
          _attr: { id: projectId },
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),

  CheckActiveProjectCodesForFinancing: makeBackendAPIConfiguration({
    id: 'CheckActiveProjectCodesForFinancing',
    endpoint: '/msa/service/commands/CheckActiveProjectCodesForFinancing',
    convertInputToXMLElement: ({ projectId, projectFinancingId }: T.CheckActiveProjectCodesForFinancing) => ({
      Request: {
        _attr: { commandName: 'CheckActiveProjectCodesForFinancing' },
        CheckRequest: {
          _attr: { projectId, projectFinancingId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertCheckResult(response),
  }),
  CheckActiveProjectCodesForDepartment: makeBackendAPIConfiguration({
    id: 'CheckActiveProjectCodesForDepartment',
    endpoint: '/msa/service/commands/CheckActiveProjectCodesForDepartment',
    convertInputToXMLElement: ({ projectId, projectDepartmentId }: T.CheckActiveProjectCodesForDepartment) => ({
      Request: {
        _attr: { commandName: 'CheckActiveProjectCodesForDepartment' },
        CheckRequest: {
          _attr: { projectId, projectDepartmentId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertCheckResult(response),
  }),
  SaveEconomicAgreement: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.ECONOMIC_AGREEMENT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  SaveGovermentContract: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.GOVERMENT_CONTRACT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  SaveGrant: makeBackendAPIConfiguration({
    id: 'SaveProject',
    endpoint: `/msa/service/commands/SaveProject?type=${ProjectType.GRANT}`,
    convertInputToXMLElement: ({ project }: T.SaveProject) => ({
      Request: {
        _attr: { commandName: 'SaveProject' },
        Project: {
          _attr: project.id ? { id: project.id } : {},
          ...convertProjectToRequest(project),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  GetProjectFeedline: makeBackendAPIConfiguration({
    id: 'GetProjectFeedline',
    endpoint: '/msa/service/commands/GetProjectFeedline',
    convertInputToXMLElement: ({ feedlineId }: T.GetProjectFeedline) => ({
      Request: {
        _attr: { commandName: 'GetProjectFeedline' },
        ProjectFeedline: { _attr: { id: feedlineId } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertProjectFeedline(response.Response.ProjectFeedline),
  }),
  SaveProjectUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveProjectUserFeedline',
    endpoint: '/msa/service/commands/SaveProjectUserFeedline',
    convertInputToXMLElement: ({ feedline, projectIds }: T.SaveProjectFeedline) => ({
      Request: {
        _attr: { commandName: 'SaveProjectUserFeedline' },
        ProjectFeedline: projectIds.map(projectId => ({
          ...(feedline.id ? { _attr: { id: feedline.id } } : {}),
          Message: { _attr: { id: feedline.message?.id ?? '' } },
          Project: { _attr: { id: projectId } },
          ...getMaybeField('Detail', feedline.detail),
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPerformersFromSecurityDocuments: makeBackendAPIConfiguration({
    id: 'GetSecurityDocumentAuthors',
    endpoint: '/msa/service/commands/GetSecurityDocumentAuthors',
    convertInputToXMLElement: ({ projectId }: T.GetSecurityDocumentAuthorsRequest) => ({
      Request: {
        _attr: { commandName: 'GetSecurityDocumentAuthors' },
        ScienceProject: {
          _attr: { id: projectId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      getPerformersFromSecurityDocuments(response.Response.SecurityDocuments?.SecurityDocument ?? [], settings),
  }),
  DeleteProject: makeBackendAPIConfiguration({
    id: 'DeleteProject',
    endpoint: '/msa/service/commands/DeleteProject',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'DeleteProject' },
        DeleteProject: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),

  CopySourceDataInProject: makeBackendAPIConfiguration({
    id: 'CopySourceDataInProject',
    endpoint: '/msa/service/commands/CopySourceDataInProject',
    convertInputToXMLElement: ({
      id,
      projectContent,
      copyOptions,
    }: {
      id: string;
      projectContent: Object;
      copyOptions: RequestCopyOption[] | NirRequestCopyOption[] | ContestRequestCopyOption[];
    }) => ({
      Request: {
        _attr: { commandName: 'CopySourceDataInProject' },
        Project: {
          _attr: { id },
          ...projectContent,
        },
        CopyOptions: {
          CopyOption: [...copyOptions.map(i => i)],
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

const projectScientistConfigurations = {
  GetProjectScientist: makeBackendAPIConfiguration({
    id: 'GetProjectScientist',
    endpoint: '/msa/service/commands/GetProjectScientist',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetProjectScientist' },
        ProjectScientist: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertPerformer(response.Response.ProjectScientist, settings),
  }),
  SaveProjectScientist: makeBackendAPIConfiguration({
    id: 'SaveProjectScientist',
    endpoint: '/msa/service/commands/SaveProjectScientist',
    convertInputToXMLElement: ({
      id,
      projectId,
      performer,
    }: {
      id: string;
      projectId: string;
      performer: Project.Performer;
    }) => ({
      Request: {
        _attr: { commandName: 'SaveProjectScientist' },
        ProjectScientist: {
          ...(id ? { _attr: { id } } : {}),
          Project: { _attr: { id: projectId } },
          ...convertPerformerToRequest(performer),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  }),
  DeleteProjectScientist: makeBackendAPIConfiguration({
    id: 'DeleteProjectScientist',
    endpoint: '/msa/service/commands/DeleteProjectScientist',
    convertInputToXMLElement: ({ ids }: { ids: string[] }) => ({
      Request: {
        _attr: { commandName: 'DeleteProjectScientist' },
        ProjectScientist: [...ids.map(id => ({ _attr: { id } }))],
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveProjectScientistsJobPeriod: makeBackendAPIConfiguration({
    id: 'SaveProjectScientistsJobPeriod',
    endpoint: '/msa/service/commands/SaveProjectScientistsJobPeriod',
    convertInputToXMLElement: ({
      jobPeriod: { startDate, endDate, startDateOrder, endDateOrder },
      projectScientists,
    }: SaveProjectScientistsJobPeriodType) => ({
      Request: {
        _attr: { commandName: 'SaveProjectScientistsJobPeriod' },
        JobPeriod: {
          StartDate: startDate,
          EndDate: endDate,
          ...(startDateOrder?.id ? { StartDateOrder: { _attr: { id: startDateOrder.id } } } : {}),
          ...(endDateOrder?.id ? { EndDateOrder: { _attr: { id: endDateOrder.id } } } : {}),
        },
        ProjectScientists: { ProjectScientist: projectScientists.map(x => ({ _attr: { id: x } })) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.ProjectScientist || [], x => getText(x)),
  }),
};

const projectEditConfiguration = (name: string) =>
  makeBackendAPIConfiguration({
    id: name,
    endpoint: `/msa/service/commands/${name}`,
    convertInputToXMLElement: (props: ProjectEditPartialType) => ({
      Request: {
        _attr: {
          commandName: name,
        },
        ProjectsGroup: {
          Projects: {
            Project: props.projectIds.map(x => ({
              _attr: x ? { id: x } : {},
            })),
          },
          Data: {
            Elements: {
              _attr: { type: props.mode },
              Element: props.elementIds.map((x, index) => ({
                Element: {
                  _attr: x ? { id: x } : {},
                },
                Position: index,
              })),
            },
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => getText(response.Response.Id),
  });

const stateRegistrationReportConfigurations = {
  GetProjectReport: makeBackendAPIConfiguration({
    id: 'GetProjectReport',
    endpoint: '/msa/service/commands/GetProjectReport',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetProjectReport' },
        Report: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertReport(response.Response.Report),
  }),
  SaveProjectReport: makeBackendAPIConfiguration({
    id: 'SaveProjectReport',
    endpoint: '/msa/service/commands/SaveProjectReport',
    convertInputToXMLElement: ({ report, projectId }: { report: Project.Report; projectId?: string }) => ({
      Request: {
        _attr: {
          commandName: 'SaveProjectReport',
        },
        Report: { ...convertReportToXML(report, projectId) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeleteProjectReport: makeBackendAPIConfiguration({
    id: 'DeleteProjectReport',
    endpoint: '/msa/service/commands/DeleteProjectReport',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'DeleteProjectReport' },
        ProjectReport: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ResetProjectReportBlock: makeBackendAPIConfiguration({
    id: 'ResetProjectReportBlock',
    endpoint: '/msa/service/commands/ResetProjectReportBlock',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'ResetProjectReportBlock' },
        ProjectReport: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ProcessProjectReports: makeBackendAPIConfiguration({
    id: 'ProcessProjectReports',
    endpoint: '/msa/service/commands/ProcessProjectReports',
    convertInputToXMLElement: ({ id, stages }: { id: string; stages: { id: string; block: string; type: string }[] }) => ({
      Request: {
        _attr: { commandName: 'ProcessProjectReports' },
        Project: {
          _attr: { id },
          Stages: {
            Stage: stages.map(x => ({
              ...(x.id ? { _attr: { id: x.id } } : {}),
              ...getMaybeField('Block', x.block),
              ...getMaybeField('Type', x.type),
            })),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

const groupProjectsConfigurations = {
  EditGroupProjectsCriticalTechnologies: projectEditConfiguration('EditGroupProjectsCriticalTechnologies'),
  EditGroupProjectsGrntis: projectEditConfiguration('EditGroupProjectsGrntis'),
  EditGroupProjectsNtrStrategies: projectEditConfiguration('EditGroupProjectsNtrStrategies'),
  EditGroupProjectsPnis: projectEditConfiguration('EditGroupProjectsPnis'),
  EditGroupProjectsPnmitrs: projectEditConfiguration('EditGroupProjectsPnmitrs'),
  EditGroupProjectsPnrs: projectEditConfiguration('EditGroupProjectsPnrs'),
  EditGroupProjectsScienceDomainInterrests: projectEditConfiguration('EditGroupProjectsScienceDomainInterrests'),
  EditGroupProjectsTechnologyPlatforms: projectEditConfiguration('EditGroupProjectsTechnologyPlatforms'),
  EditGroupProjectsUdks: projectEditConfiguration('EditGroupProjectsUdks'),
  EditGroupProjectsLksetss: projectEditConfiguration('EditGroupProjectsLksetss'),
  EditGroupProjectsOkved: projectEditConfiguration('EditGroupProjectsOkved'),
  EditGroupProjectsScienceBrunches: projectEditConfiguration('EditGroupProjectsScienceBrunches'),
  EditGroupProjectsNationalProjectExpenseCode: projectEditConfiguration('EditGroupProjectsNationalProjectExpenseCode'),
};

export const projectEditPartialConfigurations = {
  ...tableProjectsConfigurations,
  ...configurations,
  ...projectScientistConfigurations,
  ...groupProjectsConfigurations,
  ...stateRegistrationReportConfigurations,
};

import { UserPermission } from 'types/models/User';

export function isHasPermission(
  userPermission: UserPermission | null,
  permission: string | string[],
  mode: 'some' | 'every' = 'some',
) {
  if (!!userPermission?.isSuperUser) return true;

  const isArray = Array.isArray(permission);
  if (!userPermission || (isArray ? !permission.length : !permission)) return false;

  if (!isArray) return userPermission.permissions.includes(permission);

  if (mode === 'some') return permission.some(x => userPermission.permissions.includes(x));

  return permission.every(x => userPermission.permissions.includes(x));
}

export function redirectWithPermissions(
  isAccountPage: boolean,
  isHasPermissionToAccount: boolean | null,
  isHasPermissionToMain: boolean | null,
  history: any,
) {
  if (location.pathname.indexOf('signin') === -1) {
    if (isAccountPage && isHasPermissionToAccount === false) {
      redirectToHome(history);
    }
    if (!isAccountPage && isHasPermissionToMain === false) {
      if (isHasPermissionToAccount) {
        history.push('/account');
      }
    }
  }
}

export function redirectToHome(history: any) {
  history.push('/home');
}

export function redirectTo(history: any, hash: string) {
  history.push(hash);
}

/**
 * Set/Get params to serialize as part of hash
 * Only serialize/deserialize params
 */
export const serialize = {
  DELIMITERS: {
    VALUES: '=',
    PAIRS: '&',
  } as { VALUES: string; PAIRS: string },

  get: function (str: string): Record<string, any> {
    const self = this;

    const result: Record<string, any> = {};

    str.split(self.DELIMITERS.PAIRS).forEach(item => {
      const pair = item.split(self.DELIMITERS.VALUES);
      result[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1]);
    });

    return result;
  },

  set: function (params: Record<string, any>): string {
    const self = this;

    return Object.keys(params)
      .map(function (key) {
        return [encodeURIComponent(key), encodeURIComponent(params[key])].join(self.DELIMITERS.VALUES);
      })
      .join(self.DELIMITERS.PAIRS);
  },
};

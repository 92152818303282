import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

export function GetPersonalDissertationManagementList(): Table.Specification<object, object> {
  return {
    apiID: 'GetPersonalDissertationManagementList',
    header: {
      firstLevel: {
        title: 'Руководство защитой на основе данных публикаций типов "Автореферат" и "Диссертация"',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
  };
}

import { useCallback, useEffect, useMemo } from 'react';

import { Community, CommunityMember, Form } from 'types/models';
import { Member } from 'features/SelectPerson';
import { Item } from 'types/models/common';
import { ReferenceItem } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { CommunityType } from 'utils/Enums';

type Props = {
  target: Community | Form.Publication | null;
  selectedCommunityType?: Item | null;
  code?: string;
  entry: CommunityMember | null;
  onFieldChange(row: CommunityMember): void;
  roleOptions: Item[];
  cooperationTypeOptions?: Item[];
};

type MemberFields = keyof CommunityMember;

export function useController({
  target,
  selectedCommunityType,
  code,
  entry,
  onFieldChange,
  roleOptions,
  cooperationTypeOptions,
}: Props) {
  const { settings } = useAppDataContext();

  const handlePersonFieldChange = useCallback(
    (value: Member) => {
      if (entry) {
        onFieldChange({ ...entry, ...value, person: value.person });
      }
    },
    [onFieldChange, entry],
  );

  const handleJoinDateFieldChange = useCallback(
    (value: string) => {
      onFieldChange({ ...entry, joinDate: value || '' } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const handleLeaveDateFieldChange = useCallback(
    (value: string) => {
      onFieldChange({ ...entry, leaveDate: value || '' } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const handleRoleFieldChange = useCallback(
    (value: Item) => {
      onFieldChange({ ...entry, role: value } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const handleCooperationTypeFieldChange = useCallback(
    (value: Item) => {
      onFieldChange({ ...entry, cooperationType: value } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const handleVakSpecialityFieldChange = useCallback(
    (value: ReferenceItem) => {
      onFieldChange({ ...entry, vakSpeciality: value } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const handleDescriptionFieldChange = useCallback(
    (value: string) => {
      onFieldChange({ ...entry, description: value } as CommunityMember);
    },
    [onFieldChange, entry],
  );

  const memberHeading = useMemo(() => {
    return [
      selectedCommunityType?.label,
      selectedCommunityType?.value === CommunityType.MAGAZINE_REDACTION ? target?.magazine?.name : code,
    ]
      .filter(Boolean)
      .join(' ');
  }, [target, selectedCommunityType, code]);

  useEffect(() => {
    const roleFieldName: MemberFields = 'role';
    const cooperationTypeFieldName: MemberFields = 'cooperationType';
    if (roleOptions && !roleOptions.find(x => x.value === entry?.role?.value)) {
      onFieldChange({ ...entry!, [roleFieldName]: roleOptions[0] });
    }
    if (cooperationTypeOptions?.length && !cooperationTypeOptions.find(x => x.value === entry?.cooperationType?.value)) {
      onFieldChange({ ...entry!, [cooperationTypeFieldName]: cooperationTypeOptions[0] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    cooperationTypeOptions,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handlePersonFieldChange,
    handleRoleFieldChange,
    handleVakSpecialityFieldChange,
    roleOptions,
    settings,
    memberHeading,
  };
}

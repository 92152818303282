import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Person, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  tableState: Table.State;
};

export const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const selectedRow = useMemo(() => tableState.selectedRows[0], [tableState.selectedRows]);

  const [title, setTitle] = useState<string>('');

  const getHirshIndex = (row?: Table.Entry): Person.HirshIndex => {
    if (row) {
      return {
        id: row.id,
        citationSystem: {
          id: row['id:citationSystem'] || '',
          label: row.citationSystem || '',
        },
        value: row.value || '',
        note: row.note || '',
        date: row.date || '',
        identifier: row.identifier || '',
      };
    }
    return {
      id: null,
      citationSystem: null,
      value: '',
      note: '',
      date: '',
      identifier: '',
    };
  };

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [hirshIndex, setHirshIndex] = useState<Person.HirshIndex>(getHirshIndex());

  const { methods: deleteScientistHirshIndexAPIMethods } = BackendAPI.useBackendAPI('DeleteScientistHirshIndex', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись успешно удалена', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const setTitleMode = useCallback(
    (mode: string) => {
      const actions: Record<string, string> = {
        add: 'Создание',
        edit: 'Редактирование',
        view: 'Просмотр',
      };

      setTitle(
        getStatusMessage([actions[mode], 'индекса Хирша'].filter(Boolean).join(' '), mode === 'add' ? '' : hirshIndex.id || ''),
      );
    },
    [hirshIndex.id],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setHirshIndex(getHirshIndex());
          setTitleMode('add');
          setIsFormOpen(true);
        },
        permission: { name: Permits.HIRSH_INDEX_EDIT },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setHirshIndex(getHirshIndex(selectedRow));
          setTitleMode('edit');
          setIsFormOpen(true);
        },
        permission: { name: Permits.HIRSH_INDEX_EDIT },
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => deleteScientistHirshIndexAPIMethods.callAPI({ hirshIndexID: selectedRow?.id }),
        permission: { name: Permits.HIRSH_INDEX_EDIT },
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [rowsAvailability.SINGLE_SELECTED, setTitleMode, selectedRow, deleteScientistHirshIndexAPIMethods],
  );

  return {
    title,
    buttons,
    isFormOpen,
    setIsFormOpen,
    hirshIndex,
  };
};

import React, { useCallback, useEffect, useState } from 'react';

import { ButtonMode, Modal, Radio } from 'components';

import { Event } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  unconnectedParticipationPublications: Event.Source[];
  openParticipationFormByPublication: (val: Event.Source | null) => void;
};

const PublicationConnect = ({
  isOpen,
  onClose,
  unconnectedParticipationPublications,
  openParticipationFormByPublication,
}: Props) => {
  const { settings } = useAppDataContext();
  const [selectedPublication, setSelectedPublication] = useState<Event.Source | null>(null);

  const onChaneSelectedPublication = (id: string) => {
    setSelectedPublication(unconnectedParticipationPublications.find(pub => pub.id === id) || null);
  };

  const setPublicationFirst = useCallback(() => {
    setSelectedPublication(unconnectedParticipationPublications[0]);
  }, [unconnectedParticipationPublications, setSelectedPublication]);

  const openParticipationModal = useCallback(() => {
    openParticipationFormByPublication(selectedPublication);
    onClose();
  }, [selectedPublication, openParticipationFormByPublication, onClose]);

  useEffect(() => {
    if (isOpen) {
      setPublicationFirst();
    } else {
      setSelectedPublication(null);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      mode="help"
      title="Создать запись об участии в конференции на основе метаданных публикации"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Добавить запись об участии в конференции',
          onClick: openParticipationModal,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="medium"
    >
      <div className="warning" data-label="Внимание!">
        <span>
          Для отчётности по научной деятельности (персональная, по подразделению, отчёт по НИР и ОКР, в заявках на внутренний
          конкурс и прочее) показатели Участия в научных мероприятиях (доклады, награды, уровень мероприятия и пр.) рассчитываются
          по данным записей &quot;Участия в конференциях&quot;
        </span>
      </div>

      <p>
        <strong>
          С конференцией связаны аффилированные с {settings?.organization?.shortName} публикации без связи с докладами на
          конференции:
        </strong>
      </p>

      <Radio
        value={selectedPublication?.id}
        list={unconnectedParticipationPublications.map(({ id, bibliographicRecord, status }) => ({
          label: getStatusRecordMessage(bibliographicRecord, id, status),
          value: id,
        }))}
        onChange={onChaneSelectedPublication}
        isInRow={false}
      />

      <p>
        <strong>
          Отметьте публикацию в списке для создания записи об участии в конференции на основе метаданных публикации.
        </strong>
      </p>
    </Modal>
  );
};
export default React.memo(PublicationConnect);

import React, { useCallback, useEffect, useState } from 'react';

import { ButtonMode, TextArea, FormComponent, Modal, UploadFile } from 'components';

import { FileInfo } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  isOpen: boolean;
  submitText: string;
  titleText: string;
  onClose(): void;
  onSubmit?(message: string, file?: FileInfo): void;
  hasFileAttachment?: boolean;
};

export function MessageModal(props: Props) {
  const { isOpen, submitText, titleText, onClose, onSubmit, hasFileAttachment } = props;

  const [message, setMessage] = useState('');

  const [file, setFile] = useState<FileInfo>(EMPTY_FILE);

  useEffect(() => {
    setMessage('');
    setFile(EMPTY_FILE);
  }, [isOpen]);

  const handleSubmit = useCallback(() => {
    onSubmit?.(message, file);
    onClose();
  }, [onClose, onSubmit, message, file]);

  return (
    <Modal
      title={titleText}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: submitText,
          onClick: handleSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="medium"
    >
      <FormComponent.Line>
        <FormComponent.Field label="Сообщение">
          <TextArea settings={{ rows: 4, isFixed: true }} value={message} onChange={setMessage} />
        </FormComponent.Field>
      </FormComponent.Line>

      {!!hasFileAttachment && (
        <FormComponent.Line>
          <FormComponent.Field label="Файл">
            <UploadFile file={file} onChange={setFile} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </Modal>
  );
}

import { useCallback, useLayoutEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import { Table } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  onClose: () => void;
};

const useController = ({ onClose }: Props) => {
  const {
    look: { id },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const [errorReason, setErrorReason] = useState<Table.EnumValue | null>(null);
  const [errorReasonDetail, setErrorReasonDetail] = useState<string>('');
  const [libraryNote, setLibraryNote] = useState<string>('');

  const { methods: getPublicationLibraryLog } = BackendAPI.useBackendAPI('GetPublicationLibraryLog');
  const { methods: processPublicationLibraryLog } = BackendAPI.useBackendAPI('ProcessPublicationLibraryLog');

  const processLog = useCallback(() => {
    if (!errorReason) {
      showNotification({ theme: 'danger', message: 'Не заполнена причина ошибки' });
      return;
    }
    if (!id) {
      showNotification({ theme: 'danger', message: 'Произошла ошибка при загрузке' });
      return;
    }
    processPublicationLibraryLog.callAPI(
      {
        id,
        errorReason,
        errorReasonDetail: errorReasonDetail,
        libraryNote: libraryNote,
      },
      {
        onSuccessfullCall({ data }) {
          if (data.success) {
            onClose();
            tableStreams.reloadTable.push({});
          }
          showNotification({
            theme: data.success ? 'success' : 'danger',
            message: data.success ? 'Успешно сохранено' : data.message,
          });
        },
      },
    );
  }, [errorReason, errorReasonDetail, libraryNote, id, onClose, processPublicationLibraryLog, tableStreams.reloadTable]);

  useLayoutEffect(() => {
    if (id) {
      getPublicationLibraryLog.callAPI(
        { publicationLibraryLogId: id },
        {
          onSuccessfullCall({ data }) {
            const { data: fieldsData, status } = data;
            if (!status.success) {
              showNotification({ theme: 'danger', message: status.errorMessage });
              return;
            }
            ReactDOM.unstable_batchedUpdates(() => {
              setErrorReason({
                value: fieldsData?.errorReason?.key,
                label: fieldsData?.errorReason?.label,
                ordinal: 0,
                position: 0,
              });
              setErrorReasonDetail(fieldsData?.errorReasonDetail);
              setLibraryNote(fieldsData?.libraryNote);
            });
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleFormSubmit = useCallback(() => {
    processLog();
  }, [processLog]);

  const handleCancelButtonClick = useCallback(() => {
    onClose();
  }, [onClose]);

  return {
    setErrorReason,
    handleFormSubmit,
    handleCancelButtonClick,
    errorReason,
    errorReasonDetail,
    setErrorReasonDetail,
    libraryNote,
    setLibraryNote,
  };
};

export default useController;

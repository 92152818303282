import React, { useEffect } from 'react';

import { ButtonMode, FormComponent, Modal, SectionTitle, Tab, Tabs, Toolbar } from 'components';

import { Documents } from 'features/Documents';
import { useController } from './controller';
import { TenderTab as Tender } from './Tabs/Tender';
import { useFormContext } from 'features/Form/hooks';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';
import { DataGrid } from 'features/Table';
import { GetTenderKindConsumptionList } from 'features/Table/specifications';
import { TenderHelp } from './help';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function TenderForm({ viewMode, editMode, onClose }: Props) {
  const {
    customers,
    documents,
    categories,
    participants,
    scienceDomainInterrests,
    formFields,
    handleCustomersFieldChange,
    handleDocumentsFieldChange,
    handleLotsChange,
    handleCategoriesChange,
    handleParticipantsChange,
    handleScienceDomainInterrestChange,
    tenderId,
    workMode,
    handleProgramIdChange,
    handleProgramEventIdChange,
    confirmSavePopupText,
    isConfirmSavePopupOpen,
    handleConfirmSaveConfirm,
    handleConfirmSaveClose,
    needsClose,
    tender,
    setTender,
    setCustomers,
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      getStatusMessage(
        (() => {
          if (workMode === 'addMode') {
            return 'Добавление конкурса';
          }
          return `${workMode === 'viewMode' ? 'Просмотр' : 'Редактирование'} конкурса`;
        })(),
        tenderId,
      ),
    );
  }, [tenderId, setTitle, workMode]);

  return (
    <>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Конкурс">
            <Tender
              viewMode={viewMode}
              workMode={workMode}
              formFields={formFields}
              onProgramIdChange={handleProgramIdChange}
              onProgramEventIdChange={handleProgramEventIdChange}
              customers={customers}
              setCustomers={setCustomers}
              categories={categories}
              participants={participants}
              scienceDomainInterrests={scienceDomainInterrests}
              handleCustomersFieldChange={handleCustomersFieldChange}
              handleCategoriesChange={handleCategoriesChange}
              handleParticipantsChange={handleParticipantsChange}
              handleScienceDomainInterrestChange={handleScienceDomainInterrestChange}
              tender={tender}
              setTender={setTender}
              handleLotsChange={handleLotsChange}
            />
          </Tab>

          <Tab title="Документы">
            <Documents
              documents={documents ?? []}
              setDocuments={handleDocumentsFieldChange}
              disabled={!!viewMode}
              sharedLabel="Отображать на формах Заявки и Проекта"
              isCanIsSharedEdit
              defaultSharedValue
            />
          </Tab>
          <Tab title="Шаблон сметы для заявок">
            <SectionTitle title="Форма заявки для конкурса" />
            <FormComponent.Line>
              <FormComponent.Field label="Тип формы заявки">{tender.requestType?.label}</FormComponent.Field>
              <FormComponent.Field label="На конкурс оформлено заявок" labelSize="fit">
                {tender.nirRequests.length + tender.mobileRequests.length + tender.programRequests.length}
              </FormComponent.Field>
              <></>
            </FormComponent.Line>
            <DataGrid specification={GetTenderKindConsumptionList({ tenderId: tender.id || undefined })} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmSavePopupOpen}
        onClose={handleConfirmSaveClose}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => handleConfirmSaveConfirm(needsClose),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Нет',
            onClick: handleConfirmSaveClose,
          },
        ]}
        size="small"
      >
        <>{confirmSavePopupText}</>
      </Modal>

      <Modal
        mode="help"
        title="Особенности добавления конкурса"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {TenderHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(TenderForm);

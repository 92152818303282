import React from 'react';
import { parse } from 'date-fns';

import { FormComponent, ListEdit, Tabs, Tab, Select, SelectMode } from 'components';

import { Documents } from 'features/Documents';
import { GetBaseDissertationPublicationList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { useController } from './controller';
import { MemberFields } from './MemberFields';
import { validateMember } from './validate';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { CommunityType } from 'utils/Enums';
import { MagazineAboutFields } from './AboutFields/MagazineAboutFields';
import { AboutFields } from './AboutFields/AboutFields';
import { useFormContext } from 'features/Form/hooks';
import { CommunityFormLook } from 'types/models/Form';
import { formatStr } from 'utils/Constants';
import { CommunityMember } from 'types/models';
import { ExtendedRow } from 'components/ListEdit/ListEdit';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function CommunityForm({ viewMode, editMode, onClose }: Props) {
  const {
    community,
    setCommunity,
    communityId,
    communityTypes,
    handleDocumentsFieldChange,
    handleFormSubmit,
    handleMembersFieldChange,
    workMode,
    handleCommunityTypeFieldChange,
    cooperationTypeOptions,
    roleOptions,
    isNotDissertationCouncil,
    settings,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { isMembersTabHidden },
  } = useFormContext<CommunityFormLook>();

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О сообществе">
            {isNotDissertationCouncil && (
              <FormComponent.Line>
                <FormComponent.Field label="Тип сообщества" isRequired>
                  <Select
                    mode={SelectMode.ENUM}
                    value={community?.type}
                    settings={{ name: 'CommunityType', includes: communityTypes }}
                    onChange={handleCommunityTypeFieldChange}
                    isDisabled={workMode !== 'addMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            {community?.type?.value === CommunityType.MAGAZINE_REDACTION ? (
              <MagazineAboutFields workMode={workMode} community={community} setCommunity={setCommunity} />
            ) : (
              <AboutFields
                workMode={workMode}
                community={community}
                setCommunity={setCommunity}
                isNotDissertationCouncil={isNotDissertationCouncil}
                settings={settings}
              />
            )}
          </Tab>

          <Tab title="Члены сообщества" isVisible={!isMembersTabHidden}>
            <ListEdit
              rows={(community?.members || []).sort((a, b) => Number(a.id) - Number(b.id))}
              onChange={handleMembersFieldChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                {
                  label: '№',
                  formatValue: (row: ExtendedRow<CommunityMember>) => row.extendedRowId,
                  styles: { width: '5%', textAlign: 'right' },
                },
                {
                  label: 'ФИО участника',
                  formatValue: (m: CommunityMember) => m.person?.fullName || <em>Не указана</em>,
                  styles: { width: '20%' },
                },
                {
                  label: 'Должность',
                  formatValue: (m: CommunityMember) => {
                    return m.job?.refAppointment?.label || m.job?.appointment || <em>Не указана</em>;
                  },
                  styles: { width: '20%' },
                },
                {
                  label: 'Ученая степень, звание',
                  formatValue: (member: CommunityMember) => {
                    return (
                      [member.rank?.refRank.label, member.degree?.refDegree?.label].filter(Boolean).join(', ') || (
                        <em>Не указано</em>
                      )
                    );
                  },
                  styles: { width: '20%' },
                },
                { label: 'Роль в совете', formatValue: (m: CommunityMember) => m.role?.label || '', styles: { width: '10%' } },
                { label: 'Дата ввода в состав', formatValue: (m: CommunityMember) => m.joinDate || '', styles: { width: '10%' } },
                {
                  label: 'Дата вывода из состава',
                  formatValue: (m: CommunityMember) => m.leaveDate || '',
                  styles: { width: '10%' },
                },
                {
                  label: 'Действующий член',
                  formatValue: (m: CommunityMember) =>
                    !m.leaveDate || parse(m.leaveDate, formatStr, new Date()).getTime() > new Date().getTime(),
                  styles: { width: '5%' },
                },
              ]}
              withMessages
              defaultRowsCount={9}
              maxHeight="none"
              isDeleteConfirmEnabled
              isDisabled={workMode === 'viewMode'}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onFieldChange) => (
                  <MemberFields
                    target={community}
                    entry={row}
                    onFieldChange={onFieldChange}
                    vaks={community?.vaks}
                    selectedCommunityType={community?.type}
                    isLocal={community?.isLocal}
                    code={community?.code || ''}
                    affiliation={community?.affiliation}
                    roleOptions={roleOptions}
                    cooperationTypeOptions={cooperationTypeOptions}
                    isNotDissertationCouncil={isNotDissertationCouncil}
                    isJoinDateRequired={true}
                  />
                ),
                validation: {
                  checkIsValid: member => validateMember(community, member, communityTypes),
                },
              }}
            />
          </Tab>
          <Tab
            title="Авторефераты и диссертации"
            isVisible={community?.type?.value === CommunityType.DISSERTATION_COUNCIL && !isMembersTabHidden}
          >
            <FormComponent.Template>
              <FormComponent.Description mode="warning">
                На данной вкладке отображаются диссертации и авторефераты Диссертационного совета. Добавить диссертацию или
                автореферат диссертации вы можете в пункте меню Публикации.
              </FormComponent.Description>
              <DataGrid
                specification={GetBaseDissertationPublicationList({
                  communityId: communityId || '-2',
                  workMode,
                  hideActionButtons: true,
                  hideFilters: true,
                })}
              />
            </FormComponent.Template>
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={community?.documents || []}
              setDocuments={handleDocumentsFieldChange}
              disabled={workMode === 'viewMode'}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CommunityForm);

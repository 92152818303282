import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { State } from '../makeUseCustomController';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Mode } from 'features/Table/specifications/GetPublicationCitationSystemList/makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController({ tableState, customState }: Props) {
  const { publicationType, citationSystemType, removeCitationSystem, workMode } = customState;

  const tableStreams = useLocalTableStreams();

  const [mode, setMode] = useState<Mode>('add');
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const selectedRow = useMemo(() => tableState.selectedRows[0], [tableState.selectedRows]);

  const { methods: deletePublicationCitationSystem } = BackendAPI.useBackendAPI('DeletePublicationCitationSystem', {
    onSuccessfullCall: data => {
      removeCitationSystem(data.input.publicationCitationSystemID);
      tableStreams.reloadTable.push({});
      showNotification({ message: 'Запись успешно удалена', theme: 'success' });
    },
  });

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (selectedRow?.id) {
      deletePublicationCitationSystem.callAPI({
        publicationCitationSystemID: selectedRow?.id,
      });
      setIsDeleteConfirmPopupOpen(false);
    }
  }, [selectedRow?.id, deletePublicationCitationSystem]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const getPermission = useMemo(() => {
    const mapPublicationTypes = {
      [PublicationTypes.COMPILATION_CONFERENCE.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_OTHER.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.COMPILATION_SCIENTIFIC_WORK.code]: Permits.COMPILATION_CITATION_SYSTEM_EDIT,
      [PublicationTypes.MAGAZINE.code]: Permits.MAGAZINE_CITATION_SYSTEM_EDIT,
    };
    return mapPublicationTypes[publicationType as keyof typeof mapPublicationTypes] || Permits.PUBLICATION_CITATION_SYSTEM_EDIT;
  }, [publicationType]);

  const handleOpenLinkButtonClick = useCallback(() => {
    if (!selectedRow) {
      showNotification({
        message: 'Выберите запись для открытия страницы во внешнем источнике',
        theme: 'danger',
      });
      return;
    }

    if (selectedRow?.Url) {
      window?.open(selectedRow?.Url, '_blank')?.focus();
    } else {
      showNotification({
        message: 'Для выбранной записи не указан URL страницы',
        theme: 'danger',
      });
    }
  }, [selectedRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      ...(citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID'
        ? [
            {
              icon: { type: 'add' },
              title: 'Добавить',
              onClick: () => {
                setMode('add');
                setIsFormOpen(true);
              },
              permission: { name: getPermission },
              isDisabled: workMode === 'viewMode',
            },
          ]
        : []),
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          setIsFormOpen(true);
        },
        permission: { name: getPermission },
        isDisabled: !selectedRow || workMode === 'viewMode',
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: getPermission },
        isDisabled: !selectedRow || workMode === 'viewMode',
      },
      {
        icon: { type: 'link' },
        title: 'Открыть во внешнем источнике',
        onClick: handleOpenLinkButtonClick,
        isDisabled: selectedRow === undefined,
      },
    ],
    [citationSystemType, workMode, getPermission, selectedRow, handleDeleteButtonClick, handleOpenLinkButtonClick],
  );

  return {
    mode,
    buttons,
    handleConfirmDeleteConfirmPopup,
    isFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    setIsFormOpen,
  };
}

import { showNotification } from 'features/Notifications';
import { CommissionMember, ProtocolCommission } from 'types/models/ProtocolCommission';

export function validateContestCommission(commission: ProtocolCommission | null) {
  const emptyFields: string[] = [];

  if (!commission?.name) emptyFields.push('название');
  if (!commission?.orderNumber) emptyFields.push('номер приказа');
  if (!commission?.orderDate) emptyFields.push('дату приказа');

  const emptyFieldsMessage: string | null = emptyFields.length ? `Укажите ${emptyFields.join(', ')}` : null;
  const resultErrorMessage: string = [emptyFieldsMessage].filter(Boolean).join('. ');

  if (resultErrorMessage) {
    showNotification({ message: resultErrorMessage, theme: 'danger' });
    return false;
  }

  return true;
}
export function validateCommissionMember(commissionMember: CommissionMember | null) {
  if (!(commissionMember?.person && commissionMember?.role && commissionMember?.priority)) {
    return [{ isValid: false, invalidMessage: 'Заполните обязательные поля' }];
  }

  const isValidPriority = Boolean(commissionMember?.priority >= 1 && commissionMember?.priority <= 9);

  return [{ isValid: isValidPriority, invalidMessage: 'Укажите категорию роли' }];
}

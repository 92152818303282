import React, { memo } from 'react';

import { FormComponent, ReferenceItem, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  modalTableSpecification: Table.Specification<CustomState, RequestData>;
  modalTableRowConverter(modalTableRow: Table.Entry): ReferenceItem;
  relationTableModalTitle: string;
  placeholder?: string;
  label?: string;
} & BuilderProps;

const DefaultCustomSelect = <CustomState extends {} = {}, RequestData extends {} = {}>({
  modalTableSpecification,
  modalTableRowConverter,
  relationTableModalTitle,
  parameters,
  setParameterValueByName,
  parameterName,
  placeholder,
  label,
}: Props<CustomState, RequestData>) => {
  const { handleSetReferenceItem, referenceItem, customSelectParameter, canBuild } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <FormComponent.Line>
          <FormComponent.Field label={label || customSelectParameter?.description || ''} isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: relationTableModalTitle,
                table: {
                  specification: modalTableSpecification,
                  onSelect: (row: Table.Entry | null) => handleSetReferenceItem(row ? modalTableRowConverter(row) : null),
                },
              }}
              value={referenceItem?.label}
              placeholder={placeholder}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DefaultCustomSelect);

export { Component as DefaultCustomSelect };

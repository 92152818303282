import { useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table, Notification } from 'types/models';
import { Permits } from 'utils/Permissions';
import { NotificationEventModule } from 'utils/Enums';

type Props = {
  tableState: Table.State;
  module?: NotificationEventModule;
};

export function useController(props: Props) {
  const { tableState, module } = props;

  const selectedRows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = selectedRows[0] || null;

  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isReceiversFormOpen, setIsReceiversFormOpen] = useState<boolean>(false);
  const [notificationLog, setNotificationLog] = useState<Notification.NotificationLog | null>(null);
  const [isOpenNotificationMonitor, setIsOpenNotificationMonitor] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: getNotificationLog } = BackendAPI.useBackendAPI('GetNotificationLog', {
    onSuccessfullCall: ({ data }) => {
      setNotificationLog(data[0]);
      setIsViewFormOpen(true);
    },
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Открыть письмо',
        onClick: () => getNotificationLog.callAPI({ ids: [selectedRow?.id] }),
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'group' },
        title: 'Показать список получателей письма',
        onClick: () => setIsReceiversFormOpen(true),
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'group', mode: 'view' },
        title: 'Наблюдатели за модулем',
        onClick: () => setIsOpenNotificationMonitor(true),
        permission: {
          name: Permits.NOTIFICATION_MONITOR_ADMINISTRATE,
        },
        isHidden: !module,
      },
    ],
    [getNotificationLog, module, selectedRow?.id, selectedRows.length],
  );

  return {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isReceiversFormOpen,
    setIsReceiversFormOpen,
    selectedRow,
    notificationLog,
    setNotificationLog,
    isOpenNotificationMonitor,
    setIsOpenNotificationMonitor,
    isHelpOpen,
    setIsHelpOpen,
  };
}

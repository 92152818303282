import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { showNotification } from 'features/Notifications';
import { useReportsHook, Reports } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const { methods: deleteEquipVerification } = BackendAPI.useBackendAPI('DeleteEquipVerification');

  const [selectedRow] = tableState.selectedRows;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isEquipSelectOpen, setIsEquipSelectOpen] = useState(false);
  const [equipId, setEquipId] = useState<string | null>(null);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteEquipVerification.callAPI(
      { ids: tableState.selectedRows.map(row => row.id) },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Запись удалена', theme: 'success' });
          setIsDeleteConfirmPopupOpen(false);
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [deleteEquipVerification, tableState.selectedRows, tableStreams.reloadTable]);

  const reports = useMemo<Report[]>(
    () => [
      Reports.AnnualScheduleOfVerificationCalibrationOfSICertificationIO,
      Reports.WorkScheduleForVerificationCalibrationOfSIAndCertificationOfIO,
      Reports.ApplicationСonclusionСontract,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.EQUIP_VERIFICATION_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsEquipSelectOpen(true),
        permission: { name: Permits.EQUIP_VERIFICATION_EDIT },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.EQUIP_VERIFICATION_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.EQUIP_VERIFICATION_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport, tableState.selectedRows.length],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
    isEquipSelectOpen,
    setIsEquipSelectOpen,
    equipId,
    setEquipId,
    currentReport,
    isReportOpen,
    onReportClose,
  };
}

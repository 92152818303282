import { useCallback, useMemo, useState } from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, ReferenceItem, Select, SelectMode } from 'components';

import { SelectPerson, Member } from 'features/SelectPerson';
import { EventTypeName } from '../eventTypes';
import { ExtendedMember } from '../model';
import { getSupervisorWarningText, USER_ROLES } from '../helpers';

export type ChangeProps = {
  key: keyof ExtendedMember;
  value: ExtendedMember[keyof ExtendedMember];
};

type Props = {
  extendedMember: ExtendedMember;
  extendedMembers: ExtendedMember[];
  onChange(args: ChangeProps[]): void;
  eventType: EventTypeName;
};

function AuthorshipFields(props: Props) {
  const { extendedMember, onChange, eventType, extendedMembers } = props;

  const [isOpenWarning, setIsOpenWarning] = useState<boolean>(false);
  const [isOpenInfo, setIsOpenInfo] = useState<boolean>(false);

  const rolePerson = extendedMember?.role ?? null;
  const isConferenceEvent = useMemo(() => eventType === 'CONFERENCE', [eventType]);

  const updateAuthor = useCallback(
    (updatedMember: Member | null) => {
      if (updatedMember) {
        const changeProps = Object.entries(updatedMember).map<ChangeProps>(([key, value]) => ({
          key: key as keyof Member,
          value,
        }));

        onChange(changeProps);
      }
    },
    [onChange],
  );

  const handleUpdateIsReporter = useCallback(() => {
    onChange([{ key: 'isReporter', value: !extendedMember.isReporter }]);
  }, [extendedMember, onChange]);

  const handleChangeRole = useCallback(
    (value: ReferenceItem) => {
      const isNextRoleSupervisor = value.customFields?.code === USER_ROLES.SUPERVISOR.code;
      const hasSomeSupervisor = extendedMembers.some(
        extendedMemberItem => extendedMemberItem.role?.customFields?.code === USER_ROLES.SUPERVISOR.code,
      );

      if (isNextRoleSupervisor) {
        setIsOpenInfo(true);
        if (hasSomeSupervisor) {
          setIsOpenWarning(true);
        }
      }

      const nextValue = value.id ? value : null;
      onChange([{ key: 'role', value: nextValue }]);
    },
    [extendedMembers, onChange],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson member={extendedMember} onUpdateMember={updateAuthor} hiddenButtons={['delete']} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={rolePerson}
            onChange={handleChangeRole}
            settings={{
              name: 'RefParticipationMemberRole',
              title: 'Справочник "Роли участников мероприятий"',
              filters: [{ key: 'type', values: [eventType] }],
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {isConferenceEvent && (
        <FormComponent.Line>
          <FormComponent.Field label="Докладчик">
            <Checkbox checked={!!extendedMember?.isReporter} onChange={handleUpdateIsReporter} isDisabled={!extendedMember} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenWarning}
        onClose={() => setIsOpenWarning(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsOpenWarning(false),
          },
        ]}
        size="small"
      >
        <>{getSupervisorWarningText(eventType)}</>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenInfo}
        onClose={() => setIsOpenInfo(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsOpenInfo(false),
          },
        ]}
        size="small"
      >
        <>
          Для участников в роли "Участник" система установит связь с руководителем участников. Информация об участниках и
          руководителе будет отображаться в отчётных формах и соответствующих столбцах таблицы с участиями в мероприятиях.
        </>
      </Modal>
    </FormComponent.Wrapper>
  );
}

export { AuthorshipFields };

import { Table } from 'types/models';

export const GetScienceProjectList: Table.Specification = {
  apiID: 'GetScienceProjectList',
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      hasSelectButton: true,
    },
  },
};

import { Event, Table } from 'types/models';

export type State = {
  workMode: Table.WorkMode;
  sourceEvent: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
};

type Props = {
  workMode: Table.WorkMode;
  sourceEvent: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
};

export function makeUseCustomController({ workMode, sourceEvent, reloadEvent, isEventChanged }: Props) {
  return function useCustomController(): State {
    return {
      workMode,
      sourceEvent,
      reloadEvent,
      isEventChanged,
    };
  };
}

import React from 'react';

import { FormComponent, Select, SelectMode, TextInput } from 'components';

import { MobileRequest } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.Route | null;
  onFieldChange(row: MobileRequest.Route | null): void;
};

function RouteFields({ entry, onFieldChange }: Props) {
  const { handleCountryChange, handleCityChange, handleEnterpriseChange } = useController({
    entry,
    onFieldChange,
  });
  const enterpriseTooltip =
    // eslint-disable-next-line max-len
    'Если вам не удалось добавить организацию, обратитесь к организаторам конкурса, которые указаны на вкладке "О конкурсе" в разделе "Консультация и помощь"';
  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Страна" isRequired>
        <Select
          mode={SelectMode.REFERENCE}
          value={entry?.country}
          onChange={handleCountryChange}
          settings={{ name: 'RefCountry', title: 'Справочник "Страны"' }}
        />
      </FormComponent.Field>
      <FormComponent.Line>
        <FormComponent.Field label="Город" isRequired>
          <TextInput value={entry?.city} onChange={handleCityChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Field label="Принимающая организация" tooltip={enterpriseTooltip}>
        <Select
          mode={SelectMode.REFERENCE}
          value={entry?.enterprise}
          onChange={handleEnterpriseChange}
          settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"', isClearable: true }}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { RouteFields };

import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  programId: string;
  handleOpenConfirm?: (confirmText: string) => void;
} & State;

export function GetProgramKnowledgeBranchList({
  programId,
  program,
  getProgram,
  handleOpenConfirm,
  isDisabled,
}: Props): Table.Specification<State> {
  return {
    apiID: 'GetProgramKnowledgeBranchList',
    requestData: { programId },
    header: {
      firstLevel: {
        title: 'Отрасли знаний (РНФ) программы',
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel({ handleOpenConfirm }),
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ program, getProgram, isDisabled }),
  };
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Checkbox, FormComponent, SectionTitle, TextInput } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { Notification, Person } from 'types/models';
import { NotificationEvent } from 'utils/Enums';
import { getEnum } from 'utils/Helpers';

type Props = {
  person: Person.ScientistPerson | null;
  setPerson: React.Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
};

export function NotificationSettings({ person, setPerson }: Props) {
  const { enumMap } = useAppDataContext();

  const [globalNotifications, setGlobalNotifications] = useState<Notification.Notification[]>([]);

  const { methods: getNotification } = BackendAPI.useBackendAPI('GetNotification');

  useEffect(() => {
    getNotification.callAPI(
      {},
      {
        onSuccessfullCall: ({ data }) => {
          setGlobalNotifications(data);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterByGlobalSettings = useCallback(
    (events: NotificationEvent[]) => {
      return events.filter(event => {
        return globalNotifications.some(
          globalNotification =>
            globalNotification.event?.value === event && globalNotification.recipients.some(recipient => recipient.isEnabled),
        );
      });
    },
    [globalNotifications],
  );

  const notificationOptions = useMemo(() => getEnum('NotificationEvent', enumMap), [enumMap]);

  const purchaseFields = filterByGlobalSettings([
    NotificationEvent.PURCHASE_REQUEST_CONSIDERATION,
    NotificationEvent.PURCHASE_REQUEST_CHANGE_STATUS,
    NotificationEvent.PURCHASE_REQUEST_VISA,
  ]);

  const projectAssignmentFields = filterByGlobalSettings([
    NotificationEvent.PROJECT_ASSIGNMENT_CREATE,
    NotificationEvent.PROJECT_ASSIGNMENT_CHANGE_STATUS,
    NotificationEvent.PROJECT_ASSIGNMENT_MESSAGE,
    NotificationEvent.PROJECT_PROJECT_SCIENTIST_CREATE,
    NotificationEvent.PROJECT_FEEDLINE_CREATE,
  ]);

  const projectEndApproachingFields = filterByGlobalSettings([
    NotificationEvent.PROJECT_END_APPROACHING_30,
    NotificationEvent.PROJECT_END_APPROACHING_3,
    NotificationEvent.PROJECT_END_APPROACHING_1,
  ]);

  const otherFields = filterByGlobalSettings([NotificationEvent.TENDER_NOTICED]);

  const requestFeedlineCreateFields = filterByGlobalSettings([
    NotificationEvent.REQUEST_FEEDLINE_CREATE,
    NotificationEvent.NIR_REQUEST_FEEDLINE_CREATE,
  ]);

  const requestChangeStatusFields = filterByGlobalSettings([
    NotificationEvent.REQUEST_CHANGE_STATUS,
    NotificationEvent.NIR_REQUEST_CHANGE_STATUS,
    NotificationEvent.MOBILE_REQUEST_CHANGE_STATUS,
  ]);

  const requestPerformerCreateFields = filterByGlobalSettings([
    NotificationEvent.REQUEST_PERFORMER_CREATE,
    NotificationEvent.NIR_REQUEST_PERFORMER_CREATE,
  ]);

  const projectScientistJobPeriodEndFields = filterByGlobalSettings([
    NotificationEvent.PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END,
    NotificationEvent.PROJECT_PROJECT_SCIENTIST_JOB_PERIOD_END_FOR_LEADER,
  ]);

  const allFields = [
    ...purchaseFields,
    ...projectAssignmentFields,
    ...otherFields,
    ...projectEndApproachingFields,
    ...requestFeedlineCreateFields,
    ...requestChangeStatusFields,
    ...requestPerformerCreateFields,
    ...projectScientistJobPeriodEndFields,
  ];

  const renderRow = useCallback(
    (event: NotificationEvent) => {
      const enumItem = notificationOptions.find(x => x.value === event);

      if (!enumItem) {
        return <em>Элемент не найден</em>;
      }

      return (
        <FormComponent.Line key={event}>
          <FormComponent.Field>
            <Checkbox
              label={enumItem.label}
              checked={!person?.disnotifications.some(x => x.value === event)}
              onChange={e => {
                setPerson(prev => ({
                  ...prev!,
                  disnotifications: (() => {
                    const newNotifications = [...prev!.disnotifications];
                    if (e) {
                      const index = newNotifications.map(x => x.value).indexOf(enumItem.value);
                      if (index !== -1) newNotifications.splice(index, 1);
                    } else {
                      newNotifications.push(enumItem);
                    }
                    return newNotifications;
                  })(),
                }));
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      );
    },
    [notificationOptions, person?.disnotifications, setPerson],
  );

  const renderGeneralField = useCallback(
    (fields: NotificationEvent[], label: string) => {
      if (!fields.length) {
        return <></>;
      }

      return (
        <FormComponent.Line>
          <FormComponent.Field>
            <Checkbox
              label={label}
              checked={fields.every(field => !person?.disnotifications.some(x => x.value === field))}
              onChange={e => {
                setPerson(prev => ({
                  ...prev!,
                  disnotifications: (() => {
                    const newNotifications = [...prev!.disnotifications];
                    if (e) {
                      fields.forEach(field => {
                        const index = newNotifications.map(x => x.value).indexOf(field);
                        if (index !== -1) newNotifications.splice(index, 1);
                      });
                    } else {
                      fields.forEach(field => {
                        const newNotification = notificationOptions.find(x => x.value === field);
                        if (!newNotifications.some(x => x.value === field) && newNotification)
                          newNotifications.push(newNotification);
                      });
                    }
                    return newNotifications;
                  })(),
                }));
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      );
    },
    [notificationOptions, person?.disnotifications, setPerson],
  );

  return (
    <FormComponent.Wrapper>
      <SectionTitle title="Введите адрес электронной почты для уведомлений" />

      <FormComponent.Line>
        <FormComponent.Field label="E-mail" labelSize="fit" style={{ maxWidth: '700px' }}>
          <TextInput
            value={person?.scientist?.notificationEmail}
            onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev?.scientist!, notificationEmail: e } }))}
          />
          <FormComponent.Description mode="warning" classMixin="is-hint">
            Внимание! Для получения уведомлений убедитесь, что адрес электронной почты введен без ошибок.
          </FormComponent.Description>
        </FormComponent.Field>
        {renderGeneralField(allFields, 'Получать ВСЕ уведомления')}
      </FormComponent.Line>

      <SectionTitle title="Выберите разделы для получения уведомлений на электронную почту" />

      <FormComponent.ColumnWrapper>
        {!![...projectAssignmentFields, ...projectEndApproachingFields, ...projectScientistJobPeriodEndFields].length && (
          <FormComponent.Column>
            <FormComponent.Line>
              <strong>Проекты:</strong>
            </FormComponent.Line>
            {projectAssignmentFields.map(renderRow)}
            {renderGeneralField(projectEndApproachingFields, 'Приближение срока окончания этапа проекта')}
            {renderGeneralField(projectScientistJobPeriodEndFields, 'Исключение из коллектива проекта')}
          </FormComponent.Column>
        )}
        {!![...otherFields, ...requestFeedlineCreateFields, ...requestChangeStatusFields, ...requestPerformerCreateFields]
          .length && (
          <FormComponent.Column>
            <FormComponent.Line>
              <strong>Конкурсы:</strong>
            </FormComponent.Line>
            {otherFields.map(renderRow)}
            {renderGeneralField(requestFeedlineCreateFields, 'Получено сообщение по заявке')}
            {renderGeneralField(requestChangeStatusFields, 'Изменился статус моей заявки')}
            {renderGeneralField(requestPerformerCreateFields, 'Уведомление при добавлении в коллектив заявки')}
          </FormComponent.Column>
        )}
        {!!purchaseFields.length && (
          <FormComponent.Column>
            <FormComponent.Line>
              <strong>Закупки:</strong>
            </FormComponent.Line>
            {purchaseFields.map(renderRow)}
          </FormComponent.Column>
        )}
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

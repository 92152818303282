import React, { useCallback, useMemo } from 'react';

import { ButtonProps, FormComponent, ListEdit, TextArea, TextAreaMode } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { PublicationProject } from 'types/models/Form';
import { Permits } from 'utils/Permissions';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';
import { Color } from 'constants/colors';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  workMode: Table.WorkMode;
  relatedTableState: Table.State | undefined;
  projects: PublicationProject[];
  setProjects: (rows: PublicationProject[]) => void;
  isSingle?: boolean;
  isRequired?: boolean;
  selectTitle?: string | null;
  tooltipText?: string | undefined;
};

function Projects({
  workMode,
  relatedTableState,
  projects,
  setProjects,
  isSingle = false,
  isRequired = false,
  selectTitle,
  tooltipText,
}: Props) {
  const { currentPerson } = useAppDataContext();

  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<PublicationProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => PublicationProject>(row => {
    return {
      id: null,
      project: { id: row.id, value: row.completeLabel },
      accepted: '',
      acceptedBy: null,
    };
  }, []);

  const setProjectAccept = useCallback(
    (projectId: string, isAccept: boolean) => {
      const projectIndex = projects.findIndex(projectItem => projectItem.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        accepted: isAccept ? new Date().toLocaleString().replace(',', '') : '',
        acceptedBy: isAccept && currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null,
      };
      setProjects([...projects]);
    },
    [currentPerson?.fullName, currentPerson?.id, projects, setProjects],
  );

  const onChange = useCallback(
    (row: PublicationProject | null) => {
      if (row) {
        setProjects([row]);
      }
    },
    [setProjects],
  );

  const acceptButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'like', color: Color.success },
        title: 'Принять к отчету',
        onClick: () => {
          if (projects.length && !project?.accepted) {
            setProjectAccept(project?.project?.id || '', true);
          }
        },
        permission: { name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING },
        isDisabled: !(projects.length && !project?.accepted) || workMode === 'viewMode',
      },
      {
        icon: { type: 'dislike', color: Color.danger },
        title: 'Отменить принятие к отчету',
        onClick: () => {
          if (projects.length && !project?.accepted) {
            setProjectAccept(project?.project?.id || '', false);
          }
        },
        permission: {
          name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
          profilePermissionName: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING,
        },
        isDisabled: !(projects.length && !!project?.accepted) || workMode === 'viewMode',
      },
    ],
    [project?.accepted, project?.project?.id, projects.length, setProjectAccept, workMode],
  );

  const getIsDeleteProjectButtonDisabled = useCallback((row: PublicationProject | null) => !!row?.accepted, []);

  return (
    <div>
      {isSingle ? (
        <FormComponent.Line>
          <FormComponent.Field label={selectTitle ?? 'Сведения о проекте'} tooltip={tooltipText} isRequired={isRequired}>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Проекты',
                permissionName: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
                externalButtons: acceptButtons,
                visibleStatus: !!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : '',
                table: {
                  specification: specifications,
                  onSelect: (row: Table.Entry | null) => (row ? onChange(modalTableRowConverter(row)) : setProjects([])),
                },
              }}
              value={project?.project?.value}
              isDisabled={workMode === 'viewMode' || !!project?.accepted}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ) : (
        <ListEdit<object, PublicationProject>
          header={{ title: 'Проекты' }}
          rows={projects}
          onChange={rows => setProjects(rows)}
          toolbar={[
            { key: 'add', permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT } },
            { key: 'delete', isDisabled: getIsDeleteProjectButtonDisabled },
            {
              key: 'accept',
              onClick: row => setProjectAccept(row?.project?.id || '', true),
              permission: { name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING },
            },
            {
              key: 'decline',
              onClick: row => setProjectAccept(row?.project?.id || '', false),
              permission: { name: Permits.PUBLICATION_SCIENCE_PROJECT_ACCEPTING },
            },
          ]}
          columns={[
            {
              label: 'Название проекта',
              formatValue: row => row.project?.value || '',
              styles: { width: '75%' },
            },
            { label: 'Принят к отчету', formatValue: getAcceptInfo, styles: { width: '25%' } },
          ]}
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Проекты',
            modalTableSpecification: specifications,
          }}
          isModalHintHidden
          isDisabled={workMode === 'viewMode'}
        />
      )}
    </div>
  );
}

export { Projects };

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'restricted',
      list: [
        { label: 'В избранном', value: 'isFavorite' },
        { label: 'Актуальные', value: 'isActual' },
        { label: 'Все', value: 'All' },
      ],
      value: 'isActual',
    },
  ]);
});

import React, { useCallback } from 'react';

import { EditPartner } from 'features/EditPartner';
import { State, Mode } from '../makeUseCustomController';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  customState: State;
};

export function Form({ customState }: Props) {
  const { partner, isOpenForm, closeForm, mode, reloadTable } = customState;

  const onSuccessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [reloadTable, closeForm]);

  const getTitle = () => {
    const info = getStatusRecordMessage(partner?.shortName, partner?.id || '', partner?.status?.label);

    const map: Record<Mode, string> = {
      add: 'Добавление партнёра',
      edit: `Редактирование ${info}`,
      view: `Просмотр ${info}`,
    };
    return mode ? map[mode] : '';
  };

  return (
    <EditPartner
      isOpenForm={isOpenForm}
      title={getTitle()}
      partner={partner}
      closeForm={closeForm}
      viewMode={mode === 'view'}
      onSuccessSavePartner={onSuccessSave}
    />
  );
}

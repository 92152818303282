import React from 'react';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Form } from 'features/Form';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { EditMonographArticleMonographPopup } from 'features/Form/views';
import useController from './controller';

type WrapperProps = {
  parentId?: string;
  isSetupSourceRelationMode: boolean;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ parentId, isSetupSourceRelationMode }: WrapperProps) => ({ tableState }: Props) => {
  const {
    toolbarButtons,
    isEditArticlesRelationPopupOpen,
    handleCloseEditArticlesRelationPopup,
    isEditFormOpen,
    closeEditForm,
    isViewFormOpen,
    isApproveFormOpen,
    closeViewForm,
    onApprove,
    closeApproveForm,
    closeConfirmDeletePopup,
    confirmDelete,
    isConfirmDeletePopupOpen,
    isAddFormOpen,
    closeAddForm,
    isRowCanDeleted,
    look,
    title,
  } = useController({
    tableState,
    parentId,
    isSetupSourceRelationMode,
  });

  return (
    <>
      <Toolbar buttons={toolbarButtons} />

      <Modal isOpen={isAddFormOpen} onClose={closeAddForm} title={title} size={['extralarge', 'large-height']}>
        <Form look={look} onClose={closeAddForm} />
      </Modal>

      <Modal isOpen={isViewFormOpen} onClose={closeViewForm} title={title} size={['extralarge', 'large-height']}>
        <Form look={look} onClose={closeViewForm} />
      </Modal>

      <Modal isOpen={isEditFormOpen} onClose={closeEditForm} title={title} size={['extralarge', 'large-height']}>
        <Form look={look} onClose={closeEditForm} />
      </Modal>

      <Modal
        mode="warning"
        title={isRowCanDeleted ? 'Удаление главы' : 'Предупреждение'}
        isOpen={isConfirmDeletePopupOpen}
        onClose={closeConfirmDeletePopup}
        actions={
          isRowCanDeleted
            ? [
                {
                  mode: ButtonMode.PRIMARY,
                  text: 'Да',
                  onClick: confirmDelete,
                },
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Отмена',
                  onClick: closeConfirmDeletePopup,
                },
              ]
            : [
                {
                  mode: ButtonMode.PRIMARY,
                  text: 'Закрыть',
                  onClick: closeConfirmDeletePopup,
                },
              ]
        }
        size="small"
      >
        <>
          {isRowCanDeleted
            ? `(${tableState.selectedRows[0]?.bibliographic_record}) Удалить главу?`
            : 'Удаление главы в статусе "Утверждено" не доступно. Для удаления статьи ее нужно перевести в статус "Добавлено"'}
        </>
      </Modal>

      <ApprovePopup
        isOpen={isApproveFormOpen}
        onClose={closeApproveForm}
        onApprove={onApprove}
        selectedRowId={tableState.selectedRows.length ? tableState.selectedRows[0]?.id : null}
      />

      <EditMonographArticleMonographPopup
        relatedTableState={tableState}
        isOpen={isEditArticlesRelationPopupOpen}
        onClose={handleCloseEditArticlesRelationPopup}
        sourcePublicationId={parentId || ''}
        articlePublicationIds={tableState.selectedRows.map(({ id }) => id as string)}
      />
    </>
  );
};

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ParticipationFinancicalSupportPopup } from 'features/Form/views/ParticipationFinancicalSupportPopup';
import useController from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { DataGrid } from 'features/Table';
import { GetParticipationUsagesList, GetPublicationForParticipationList } from 'features/Table/specifications';

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    buttons,
    selectedRow,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    isConfirmDeleteOpen,
    userSystemDepartment,
    isEditDepartmentsOpen,
    isFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
    handleCloseFinancicalSupportPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    isUsagesListOpen,
    handleCloseUsagesList,
    currentReport,
    isReportOpen,
    onReportClose,
    settings,
    isPublicationModalOpen,
    setIsPublicationModalOpen,
    submitPublicationModal,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <EditParticipationDepartments
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        isOpen={isEditDepartmentsOpen}
        onClose={handleCloseEditDepartments}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitEditDepartments}
      />

      <ApproveParticipation
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        isOpen={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitApproveParticipation}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConfirmDelete,
          },
        ]}
        size="small"
      >
        <>({tableState.selectedRows[0]?.EventFullName}) Удалить участие?</>
      </Modal>

      <Modal
        mode="warning"
        title="Привязка к подразделению"
        isOpen={isWarningPopupOpen && !!selectedRow}
        onClose={handleCloseWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Ок',
            onClick: handleAttachParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseWarningPopup,
          },
        ]}
        size="medium"
      >
        <>
          <p>Привязать участие к своему подразделению "{userSystemDepartment?.label}"?</p>
          <p>
            <strong>Информация об участии</strong>
          </p>
          <p>
            <strong>Конференция:</strong> {selectedRow?.EventFullName || ''}
          </p>

          {selectedRow?.ReportTheme && (
            <p>
              <strong>Тема доклада:</strong> {selectedRow?.ReportTheme}
            </p>
          )}

          {selectedRow?.Participants && (
            <p>
              <strong>Участники:</strong> {selectedRow?.Participants}
            </p>
          )}
        </>
      </Modal>

      {isEditFormOpen && (
        <modalTemplates.ConferenceParticipationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isViewFormOpen && (
        <modalTemplates.ConferenceParticipationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ConferenceParticipationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}

      <ParticipationFinancicalSupportPopup
        participationId={tableState.selectedRows[0]?.id ?? '-1'}
        isOpen={isFinancicalSupportPopupOpen}
        onClose={handleCloseFinancicalSupportPopup}
        relatedTableState={tableState}
        onSuccess={submitFinancialPopupCallback}
        workMode="editMode"
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
      />

      <Modal
        isOpen={isUsagesListOpen}
        onClose={handleCloseUsagesList}
        title="Связи участия с другими объектами"
        size={['extralarge', 'small-height']}
      >
        <DataGrid specification={GetParticipationUsagesList({ id: selectedRow?.id })} />
      </Modal>

      <Modal
        title="Список публикаций"
        isOpen={isPublicationModalOpen}
        onClose={() => setIsPublicationModalOpen(false)}
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid
          specification={{
            ...GetPublicationForParticipationList({ participationId: selectedRow?.id }),
            onSubmitTable: submitPublicationModal,
          }}
        />
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />
    </>
  );
};

export const Component = memo(LeftPanelForThirdLevel);

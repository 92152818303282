import { Table } from 'types/models';

type Arguments = {
  protocolCommissionId?: string | null;
};

export function GetProtocolCommissionMemberList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProtocolCommissionMemberList',
    requestData: args,
    header: {
      firstLevel: {},
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

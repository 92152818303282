import React, { useCallback, useEffect } from 'react';
import * as TabsContent from './Tabs';

import { Tabs, Tab, FormComponent } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useFormContext } from 'features/Form/hooks';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { OrganizationComponent } from '../views/Organization';
import { EventTab } from 'utils/Permissions/EventTab';
import { RecordStatus } from 'utils/Enums';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormConcert = ({ viewMode, onClose }: Props) => {
  const {
    refs,
    changeCommitteMembers,
    changeDepartments,
    changeEnterprises,
    changeProjects,
    calculateFactMemberCount,
    changeMemberCounts,
    committeMembers,
    departments,
    documents,
    enterprises,
    eventId,
    formFields,
    withHeaderId,
    handleFormSubmit,
    isDocumentsWarningPopupOpen,
    isLoading,
    isSelectedPeriod,
    memberCounts,
    onCloseDocumentsWarningPopup,
    onConfirmDocumentsWarningPopup,
    participations,
    projects,
    relatedTableState,
    setDocuments,
    tabsId,
    termsList,
    workMode,
    userPermission,
    reloadEvent,
    sourceEvent,
    eventData,
  } = useController({
    onClose,
  });

  const {
    look: { id, setTitle },
  } = useFormContext();

  const isVisibleTab = useCallback(
    (tab: EventTab) => {
      if (eventData.status?.value === RecordStatus.APPROVED) {
        const permitBase: string = 'CONCERT_EDIT_APPROVED';
        return (
          isHasPermission(userPermission, Permits[`${permitBase}_ALL` as keyof Permits]) ||
          (!!eventData.isUserOwned &&
            isHasPermission(userPermission, Permits[`${permitBase}_MY_${tab.toUpperCase()}` as keyof Permits]))
        );
      }

      return true;
    },
    [eventData.isUserOwned, eventData.status?.value, userPermission],
  );

  useEffect(() => {
    setTitle?.(
      EventHeader({
        workMode,
        departments,
        status: eventData.status?.label || '',
        eventId: id || '',
        eventTypeCode: EventTypes.EVENT_CONCERT.code,
      }),
    );
  }, [departments, eventData.status?.label, id, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        {...(workMode === 'addMode' &&
        !isHasPermission(userPermission, [Permits.CONCERT_EDIT_ADDED_MY, Permits.CONCERT_EDIT_ADDED_ALL])
          ? {}
          : { handleSaveAndContinue: () => handleFormSubmit(false) })}
        viewMode={viewMode}
        isDisabled={isLoading}
      />

      <SuchLikeEvent
        mode="edit"
        handleCloseOuterForm={onClose}
        command="RefEventFeedlineMessage"
        eventTypeCode={EventTypes.EVENT_CONCERT.code}
        eventName={formFields.name.value as string}
        startDate={formFields.startDate.value as string}
        endDate={formFields.endDate.value as string}
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О мероприятии" isVisible={isVisibleTab(EventTab.ABOUT)} isDisabled={!!viewMode}>
            <TabsContent.AboutComponent
              eventId={eventId}
              changeDepartments={changeDepartments}
              changeEnterprises={changeEnterprises}
              departments={departments}
              enterprises={enterprises}
              formFields={formFields}
              isSelectedPeriod={isSelectedPeriod}
              refs={refs}
              termsList={termsList}
              viewMode={viewMode}
              withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
            />
          </Tab>
          <Tab title="Организация мероприятия" isVisible={isVisibleTab(EventTab.ORGANIZATION)}>
            <OrganizationComponent
              calculateFactMemberCount={calculateFactMemberCount}
              changeCommitteMembers={changeCommitteMembers}
              changeMemberCounts={changeMemberCounts}
              committeMembers={committeMembers}
              formFields={formFields}
              memberCounts={memberCounts}
              viewMode={!!viewMode}
            />
          </Tab>
          <Tab title="Участники">
            <TabsContent.Participations
              sourceEvent={sourceEvent || eventData}
              workMode={workMode}
              reloadEvent={reloadEvent}
              isEventChanged={false} // TODO: need refactor inner structures to changing dispatch
              participations={participations}
            />
          </Tab>
          <Tab title="Финансовая поддержка" isVisible={isVisibleTab(EventTab.FINANCING)} isDisabled={!!viewMode}>
            <TabsContent.FinancialSupport
              projects={projects}
              relatedTableState={relatedTableState}
              setProjects={changeProjects}
              workMode={workMode}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <DocumentsWarningPopup
        isOpen={isDocumentsWarningPopupOpen}
        onConfirm={onConfirmDocumentsWarningPopup}
        onClose={onCloseDocumentsWarningPopup}
      />
    </FormComponent.Template>
  );
};

export const Component = React.memo(EventFormConcert);

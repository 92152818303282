import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { ScientificIndicesGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
    ],
    [isEditButtonDisabled, handleEditButtonClick],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isEditButtonDisabled && (
        <modalTemplates.ScientificIndexEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ScientificIndicesGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Table } from 'types/models';
import { GetSimpleMagazineListRequest } from 'services/BackendAPI/configurations/types';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  deps: Table.Dependencies;
  hasSelectButton?: boolean;
} & GetSimpleMagazineListRequest;

export function GetSimpleMagazineListSpecification({
  deps,
  hasSelectButton,
  isElectronic,
  isPreprint,
  filterMagazineReleaseId,
  articleIds,
}: Props): Table.Specification<State> {
  return {
    apiID: 'GetSimpleMagazineList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({ isPreprint }),
    requestData: {
      isElectronic,
      isPreprint,
      filterMagazineReleaseId,
      articleIds,
    },
  };
}

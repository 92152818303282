import React from 'react';

import { ButtonMode, FormComponent, ListEdit, Modal, Radio, TextInput, TextInputMode } from 'components';

import { Project } from 'types/models';
import { getEnumItemLabel } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { ProjectFinancingType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getMockStage } from 'features/Form/looks/project/ProjectForm/helpers';

import useController from './controller';
import { Fields } from './Fields';
import { validate } from './validate';
import { Period } from './type';
import { onStagePreSubmit } from 'features/Form/looks/projectReport/helpers';

type Props = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  stages: Project.Stage[];
  financings: Project.Financing[];
  performers: Project.Performer[];
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  setFinancings(financings: Project.Financing[]): void;
  setPerformers(performers: Project.Performer[]): void;
  isEditableLocked: boolean;
  withMessages?: boolean;
  disabled: boolean;
};

export function Stages(props: Props) {
  const {
    stages,
    setStages,
    financings,
    isEditableLocked,
    setFinancingsByYear,
    setFinancings,
    setPerformers,
    project,
    setProject,
    performers,
    withMessages = true,
    disabled,
  } = props;

  const { settings } = useAppDataContext();

  const {
    isWarningPopupOpened,
    isGeneratePlanOpen,
    extraToolbarButtons,
    closeGeneratePlan,
    submitCalendarPlan,
    onStagesChange,
    ndsPercentFormatter,
    totalNdsFormatter,
    openWarningPopup,
    onStagePreDelete,
    closeWarningPopup,
    periodDuration,
    setPeriodDuration,
    financingValues,
    setFinancingValues,
    editableFinancings,
  } = useController({
    project,
    stages,
    financings,
    performers,
    setStages,
    setFinancingsByYear,
    setFinancings,
    setPerformers,
    disabled,
  });

  const { enumMap } = useAppDataContext();

  return (
    <>
      <ListEdit
        rows={stages}
        onChange={onStagesChange}
        toolbar={[
          'add',
          'edit',
          {
            key: 'delete',
            isDisabled: row =>
              project.reports.some(report => (row?.id ? report.stage?.id === row?.id : report.stage?.number === row?.number)),
          },
          ...extraToolbarButtons,
        ]}
        columns={[
          { label: 'Этап', formatValue: x => x.number, styles: { width: '50px', textAlign: 'center' } },
          {
            label: 'Период',
            formatValue: x => `${x.startDate} - ${x.endDate}`,
            styles: { width: '18%' },
            dataKind: 'range',
            defaultSort: 'asc',
          },
          {
            label: 'ОИФ',
            formatValue: x => x.amountMain,
            styles: { width: '12%' },
            dataKind: 'float',
          },
          {
            label: 'СФ1',
            formatValue: x => x.amountCofinancing1,
            styles: { width: '12%' },
            dataKind: 'float',
          },
          {
            label: 'СФ2',
            formatValue: x => x.amountCofinancing2,
            styles: { width: '10%' },
            dataKind: 'float',
          },
          {
            label: settings?.organization?.shortName || '',
            formatValue: x => x.amountLocal,
            styles: { width: '10%' },
            dataKind: 'float',
          },
          {
            label: 'Партнёр',
            formatValue: x => x.amountAccomplice,
            styles: { width: '10%' },
            dataKind: 'float',
          },
          {
            label: 'НДС, %',
            formatValue: ndsPercentFormatter,
            styles: { width: '10%' },
            dataKind: 'float',
          },
          {
            label: 'НДС, руб',
            formatValue: totalNdsFormatter,
            styles: { width: '10%' },
            dataKind: 'float',
          },
        ]}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={3}
        maxHeight="350px"
        columnIndexesForSumTotal={[2, 3, 4, 5, 6, 8]}
        specification={{
          mode: 'customComponent',
          renderComponent: (stage, setStage, _, mode) => (
            <Fields stage={stage || getMockStage()} setStage={setStage} financings={financings} mode={mode} project={project} />
          ),
          onPreSubmit: (row, submit, index) => onStagePreSubmit(row, submit, index, setProject),
          onPreEdit: (row, continueEdit) => {
            const isRowLocked = row?.isLocked;
            if (isRowLocked && !isEditableLocked) {
              openWarningPopup();
            } else {
              continueEdit();
            }
          },
          onPreDelete: onStagePreDelete,
          validation: {
            checkIsValid: (stage, index, list, mode) =>
              validate({ stage, list, index, financings, mode, settings, project }).every(x => x.isValid),
            onInvalidate: (stage, mode, index, list) => {
              const validationInfo = validate({ stage, list, index, financings, mode, settings, project });
              if (validationInfo.some(x => !x.isValid)) {
                showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
              }
            },
          },
        }}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isWarningPopupOpened}
        onClose={closeWarningPopup}
        actions={[
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: closeWarningPopup,
          },
        ]}
        size="small"
      >
        <>Период закрыт, для внесения изменений в данном периоде обратитесь к начальнику отдела сопровождения НИОКР</>
      </Modal>

      <Modal
        title="Процедура формирования календарного плана"
        isOpen={isGeneratePlanOpen}
        onClose={closeGeneratePlan}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: submitCalendarPlan,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeGeneratePlan,
          },
        ]}
        size="small"
      >
        <FormComponent.Line>
          <FormComponent.Field>
            <strong>Выберите длительность этапа и укажите сумму по умолчанию</strong>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Длительность этапа" hasLeftAlign>
            <Radio
              value={periodDuration}
              list={[
                { value: Period.year, label: 'Год' },
                { value: Period.halfYear, label: 'Полгода' },
                { value: Period.quarter, label: 'Квартал' },
              ]}
              onChange={x => setPeriodDuration(x as Period)}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        {!!editableFinancings.length && (
          <>
            <FormComponent.Line>
              <FormComponent.Field>
                {editableFinancings.map((x, index) => (
                  <FormComponent.Field
                    label={getEnumItemLabel('ProjectFinancingType', x, enumMap)}
                    key={index}
                    labelWidth={330}
                    hasLeftAlign
                  >
                    <TextInput
                      mode={TextInputMode.NUMBER}
                      value={String(financingValues[x as ProjectFinancingType])}
                      onChange={e => setFinancingValues(prevState => ({ ...prevState, [x]: e || '0' }))}
                    />
                  </FormComponent.Field>
                ))}
              </FormComponent.Field>
            </FormComponent.Line>
          </>
        )}

        <FormComponent.Line>
          <FormComponent.Field>
            <span>
              <strong>Внимание:</strong> этапы будут добавлены для руководителя проекта
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>
    </>
  );
}

import { Person } from 'types/models';

export function validateMainFields(person: Person.ScientistPerson) {
  const isValidLastName = Boolean(person.scientist?.lastName);
  const isValidFirstName = Boolean(person.scientist?.firstName);
  const isValidDateBirth = Boolean(person.scientist?.dateBirth);
  const isFilledJobsOrEducation = Boolean(person.scientistEducations.length || person.scientistJobs.length);

  return [
    { isValid: isValidLastName, invalidMessage: 'Заполните фамилию' },
    { isValid: isValidFirstName, invalidMessage: 'Заполните имя' },
    { isValid: isValidDateBirth, invalidMessage: 'Заполните дату рождения' },
    { isValid: isFilledJobsOrEducation, invalidMessage: 'Заполните сведения о работе или об обучении' },
  ];
}

import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  setFundCardId?(fundCardId: string): void;
};

export function GetFundCardSelectList({ setFundCardId }: Props): Table.Specification<State> {
  return {
    apiID: 'GetFundCardSelectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ setFundCardId }),
  };
}

export enum PersonModule {
  GENERAL = 'GENERAL',
  JOBS = 'JOBS',
  EDUCATIONS = 'EDUCATIONS',
  DEGREES = 'DEGREES',
  RANKS = 'RANKS',
  ACADEMIC_RANKS = 'ACADEMIC_RANKS',
  INTERRESTS = 'INTERRESTS',
  COMMUNITIES = 'COMMUNITIES',
  HIRSH_INDICES = 'HIRSH_INDICES',
  BIBLIOGRAPHIC_NAMES = 'BIBLIOGRAPHIC_NAMES',
  AFFILIATIONS = 'AFFILIATIONS',
  IDENTIFIERS = 'IDENTIFIERS',
  CHANGES = 'CHANGES',
  NOTIFICATIONS = 'NOTIFICATIONS',
  CALCULATION = 'CALCULATION',
  CONTACTS = 'CONTACTS',
}

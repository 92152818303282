import React, { useCallback } from 'react';

import { ButtonMode, FormComponent, Modal, ReferenceItem, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { showNotification } from 'features/Notifications';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  isOpen: boolean;
  feedline: Project.Feedline;
  onClose(): void;
  changeFeedline(feedline: Project.Feedline): void;
  onSubmit(feedline: Project.Feedline): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, feedline, changeFeedline, onSubmit } = props;

  const makeChangeHandler = (key: keyof Project.Feedline) => (value: ValueOf<Project.Feedline>) => {
    changeFeedline({ ...feedline, [key]: value });
  };

  const handleSubmit = useCallback(() => {
    if (feedline.message === null) {
      showNotification({ message: 'Выберите сообщение', theme: 'danger' });
      return;
    }
    onSubmit(feedline);
    onClose();
  }, [feedline, onClose, onSubmit]);

  return (
    <Modal
      title="Сообщение"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Сохранить',
          onClick: handleSubmit,
          isDisabled: !feedline?.message || !feedline.detail,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <FormComponent.Line>
        <FormComponent.Field>
          <Select
            mode={SelectMode.REFERENCE}
            value={feedline?.message}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('message')(option)}
            settings={{ name: 'RefProjectFeedlineMessage', title: 'Сообщения журнала' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3 }}
            value={feedline.detail}
            onChange={makeChangeHandler('detail')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </Modal>
  );
}

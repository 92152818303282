export type State = {
  projectId?: string;
};

type Props = {
  projectId?: string;
};

export function makeUseCustomController({ projectId }: Props) {
  return function useCustomController(): State {
    return {
      projectId,
    };
  };
}

export function ExpositionParticipationGridHelp(): JSX.Element {
  return (
    <>
      <h3>Алгоритмы заполнения данных в «Участияx в выставках»</h3>

      <h4>Вкладка «Описание»</h4>

      <ul className="dashed">
        <li>
          поле <strong>«Выставка»</strong> - Вручную в поле нельзя ввести данные. Выставка, в которой было принято данное участие
          выбирается из справочника «Выставки» (открывается таблица раздела «Выставки»). Если в таблице нет нужной выставки, то
          можно по кнопке «Добавить» выставку.
        </li>
        <li>
          <strong>«Уровень мероприятия» и «Формат проведения»</strong> после выбора выставки данные автоматически подставляются из
          одноименных полей самой выставки
        </li>
        <li>
          <p>
            поле <strong>«Формат участия»</strong> - Вручную в поле нельзя ввести данные. Необходимо выбрать из справочника
            «Форматы участия» один из четырех вариантов участия.
          </p>
          <strong>Состав полей и данные различаются в зависимости от выбора «Формата участия»:</strong>
          <ul>
            <li>Презентация разработки</li>
            <li>Презентация проекта</li>
            <li>Работа на экспозиции</li>
            <li>Участие в деловой программе</li>
          </ul>
        </li>
        <li>
          <p>
            таблица <strong>«Сведения об участниках выставки»</strong> необходимо выбрать ФИО из справочника «Персон» и указать
            роль персоны, выбрав из справочника «Роли участников мероприятия». Персону в роли Руководитель необходимо добавить на
            форму последним. Во втором столбике таблицы отображается награда участника, если есть награда и данные о ней занесены
            на вкладке «Награды».
          </p>
          <ul>
            <li>Презентация разработки (если выбран данный формат участия, то)</li>
          </ul>
        </li>
        <li>
          чек-бокс <strong>«Коллективная экспозиция»</strong>, если экспозиция является коллективной, то необходимо установить
          отметку в чек-бокс и следующее поле «Экспозиция» становится обязательным к заполнению
        </li>
        <li>
          поле <strong>«Экспозиция»</strong> - данные об экспозиции вносятся в поле вручную
        </li>
        <li>
          <p>
            поле <strong>«Название разработки»</strong> - Необходимо выбрать из справочника «Список разработок, представленных на
            выставках». Если нет разработки в справочнике, то необходимо по кнопке «Добавить» разработку. Для этого на форме
            добавления новой разработки заполняются следующие поля:
          </p>
          <ul>
            <li>поле «Тип» выбрать из справочника «Тип разработок»</li>
            <li>поле «Название разработки»</li>
            <li>
              поле «Продукт для коммерциализации (ОИС)» заполняется, если разработка связана с объектом интеллектуальной
              собственности из справочника «Продукт для коммерциализации (ОИС)»
            </li>
            <li>в таблицу «Контактные лица» добавляется данные выбранной персоны из справочника «Персон»</li>
            <li>
              в таблицу «Организации-партнёры» необходимо выбрать из справочника «Партнёры» организацию, если разработка
              выполняется совместно с организацией
            </li>
          </ul>
        </li>
        <li>
          <p>
            чек-боксы <strong>«Форма представления»</strong> - обязательно необходимо выбрать один или более чек боксов в какой
            форме представлена разработка
          </p>
          <ul>
            <li>Презентация проекта (если выбран данный формат участия, то)</li>
          </ul>
        </li>
        <li>
          чек-бокс <strong>«Продвижение результатов проекта»</strong> обязательно необходимо выбрать проект из справочника
          «Проекты» (открывается таблица списков проектов научного управления: Гранты, Задания на НИР, Хоздоговоры, Контракты)
        </li>
        <li>
          <p>
            чек-боксы <strong>«Форма представления»</strong> - обязательно необходимо выбрать один или более чек боксов в какой
            форме представлена разработка
          </p>
          <ul>
            <li>Работа на экспозиции (если выбран данный формат участия, то)</li>
          </ul>
        </li>
        <li>
          чек-бокс <strong>«Коллективная экспозиция»</strong>, если экспозиция является коллективной, то необходимо установить
          отметку в чек-бокс и следующее поле «Экспозиция» становится обязательным к заполнению
        </li>
        <li>
          поле <strong>«Экспозиция»</strong> - данные об экспозиции вносятся в поле вручную
        </li>
      </ul>

      <h4>Вкладка «Награды» (заполняется после проведения мероприятия)</h4>

      <p>По кнопке «Добавить» можно внести данные о награде.</p>

      <ul>
        <li>
          поле <strong>«Категория награды»</strong> - выбрать категорию награды из выпадающего меню
        </li>
        <li>
          поле <strong>«Название награды»</strong> обязательно указать название награды вручную
        </li>
        <li>
          поле <strong>«Номинации»</strong> вручную внести название номинации
        </li>
        <li>
          <strong>«Файл копия документа»</strong> прикрепить файл подтверждающий наличие награды
        </li>
        <li>
          в таблице <strong>«Номинанты. Выберите из списка участников мероприятия»</strong> выбрать участника или участников,
          удостоенных наград. Если не выбрать конкретного участника, то награда отобразится автоматически у всех участников,
          указанных на вкладке «Описание».
        </li>
      </ul>
    </>
  );
}

export function ExponentGridHelp(): JSX.Element {
  return (
    <>
      <h3>Список разработок, представленных на выставках</h3>

      <p>
        Раздел содержит реестр научно-технических разработок и выставочных экспонатов, добавленных пользователями, при заполнении
        модуля Мероприятия, Участия в выставках.
      </p>

      <h4>Особенности заполнения формы Разработка:</h4>

      <ul>
        <li>
          <strong>Название разработки</strong> – название как оно было представлено на выставке.
        </li>
        <li>
          Поле <strong>Продукт для коммерциализации</strong> заполняется в случае, если на выставке была презентация о продукте.
          Продукт может в разных форматах с разными названиями на разных выставках. Сведения о разработках, связанных с Продуктом
          для коммерциализации отображаются на его форме, на вкладке Популяризация.
        </li>
        <li>
          Источник информации в строке <strong>Формы представления на выставках</strong> — Участие в выставке.
        </li>
        <li>
          В разделе <strong>Контактные лица</strong> рекомендуется добавлять информацию о владельце экспоната (Продукта для
          коммерциализации) – полезная информация при подготовке к участию в других выставках и презентациях.
        </li>
        <li>
          В разделе <strong>Организации-партнёры</strong> рекомендуется указать организацию, которая участвовала в разработке, или
          подготовке экспоната на выставку.
        </li>
      </ul>
    </>
  );
}

import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ScientificIndicesGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Наукометрические показатели</h4>

        <p>
          Функциональный блок <strong>Справочник «Наукометрические показатели»</strong> содержит сформированный системой список
          наукометрических показателей, которые используются в системах цитирования: Scopus, Web of Science, РИНЦ.
        </p>
        <p>Объекты, для которых внесены коллекции наукометрических показателей в справочник:</p>

        <ul>
          <li>Публикации;</li>
          <li>Журналы;</li>
          <li>Персоны;</li>
          <li>Организации.</li>
        </ul>

        <p>
          Для более удобной работы, в столбце <strong>Объект</strong> можно включить фильтр и посмотреть список посмотреть список
          всех показателей только определенного одного объекта.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Столбец</th>
              <th>Назначение</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Система цитирования</td>
              <td>База цитирования</td>
            </tr>
            <tr>
              <td>Наименование</td>
              <td>Наименование наукометрического показателя</td>
            </tr>
            <tr>
              <td>Объект</td>
              <td>Журналы, Публикации, Персоны, Организации</td>
            </tr>
            <tr>
              <td>Краткое обозначение</td>
              <td>Краткое обозначение наукометрического показателя используется в интерфейсах и отчётных формах</td>
            </tr>
            <tr>
              <td>Доступные значения показателя</td>
              <td>Указывается доступное значение показателя, год, URL</td>
            </tr>
            <tr>
              <td>Дочерние показатели</td>
              <td>Указываются относительные показатели по предметной области</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

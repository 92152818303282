import {
  ButtonMode,
  FormComponent,
  ListEdit,
  ListEditTable,
  Modal,
  RefList,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';
import { GetDetailedScientistList, GetEquipList, GetProgramList, GetProjectCodeSimpleList } from 'features/Table/specifications';
import { useCallback, useState } from 'react';
import { Equip, ReferenceItem, Table } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { showNotification } from 'features/Notifications';
import { PersonModule } from 'utils/Enums';
import { kitContentColumns, KitContentFields } from './Description/KitContentFields';
import { getMockKitContent } from '../helpers';

type Props = {
  equip: Equip.Equip;
  setEquip: React.Dispatch<React.SetStateAction<Equip.Equip>>;
  isDisabled: boolean;
};

export function Purchase({ equip, setEquip, isDisabled }: Props) {
  const [parentEquip, setParentEquip] = useState<Equip.Equip | null>(null);
  const [isParentEquipChildSelectOpen, setIsParentEquipChildSelectOpen] = useState<boolean>(false);
  const [parentEquipChildIndex, setParentEquipChildIndex] = useState<number | null>(null);
  const [newParentEquipChild, setNewParentEquipChild] = useState<Equip.KitContent | null>(null);
  const [isKitContentModalOpen, setIsKitContentModalOpen] = useState<boolean>(false);

  const { methods: getProgramAPI } = BackendAPI.useBackendAPI('GetProgram');
  const { methods: getProjectCodeAPI } = BackendAPI.useBackendAPI('GetProjectCode');
  const { methods: getScientistDataAPI } = BackendAPI.useBackendAPI('GetScientistData');
  const { methods: getEquipAPI } = BackendAPI.useBackendAPI('GetEquip');

  const handleProgramSelect = useCallback(
    (row: Table.Entry | null) => {
      if (!row) {
        setEquip(prev => ({ ...prev, program: null }));
        return;
      }
      getProgramAPI.callAPI(
        { id: row.id },
        {
          onSuccessfullCall: ({ data }) => {
            setEquip(prev => ({ ...prev, program: data }));
          },
        },
      );
    },
    [getProgramAPI, setEquip],
  );

  const handleParentKitContentChange = useCallback(
    (row: Table.Entry | null) => {
      if (!row) {
        setEquip(prev => ({ ...prev, parentKitContent: null }));
        return;
      }
      getEquipAPI.callAPI(
        { id: row.id },
        {
          onSuccessfullCall: ({ data }) => {
            setParentEquip(data);
            setIsParentEquipChildSelectOpen(true);
          },
        },
      );
    },
    [getEquipAPI, setEquip],
  );

  const clearParentEquipInfo = useCallback(() => {
    setIsParentEquipChildSelectOpen(false);
    setIsKitContentModalOpen(false);
    setParentEquipChildIndex(null);
    setNewParentEquipChild(null);
    setParentEquip(null);
  }, []);

  const renderInvestmentSourceFields = useCallback(
    (
      investmentSource: Equip.InvestmentSource | null,
      setInvestmentSource: React.Dispatch<React.SetStateAction<Equip.InvestmentSource | null>>,
    ) => {
      function handleProjectCodeSelect(row: Table.Entry | null) {
        if (!row) {
          setInvestmentSource(prev => ({ ...prev!, projectCode: null }));
          return;
        }
        getProjectCodeAPI.callAPI(
          { id: row.id },
          {
            onSuccessfullCall: ({ data }) => {
              const projectCode: Equip.ProjectCode = {
                id: data.id || null,
                code: data.code || '',
                project: !!data.project
                  ? {
                      id: data.project?.id || null,
                      type: data.project?.type || null,
                      projectNumber: data.project?.number || '',
                    }
                  : null,
                investmentSource: !!data.financing
                  ? {
                      type: data.financing?.type || null,
                      source: data.financing?.source || null,
                    }
                  : null,
              };
              setInvestmentSource(prev => ({ ...prev!, projectCode: projectCode, source: '' }));
            },
          },
        );
      }
      return (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Шифр проекта">
              <TextArea
                value={investmentSource?.projectCode?.code}
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Шифры проектов',
                  table: {
                    specification: GetProjectCodeSimpleList(),
                    onSelect: handleProjectCodeSelect,
                  },
                  isClearable: true,
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Источник финансирования">
              <TextInput
                value={investmentSource?.projectCode?.code || investmentSource?.source}
                onChange={e => setInvestmentSource(prev => ({ ...prev!, source: e }))}
                isDisabled={!!investmentSource?.projectCode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сумма">
              <TextInput
                value={investmentSource?.amount}
                onChange={e => setInvestmentSource(prev => ({ ...prev!, amount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      );
    },
    [getProjectCodeAPI],
  );

  const renderBalanceFields = useCallback(
    (balance: Equip.Balance | null, setBalance: React.Dispatch<React.SetStateAction<Equip.Balance | null>>) => {
      function handleResponsibleSelect(row: Table.Entry | null) {
        if (!row) {
          setBalance(prev => ({ ...prev!, responsible: null }));
          return;
        }
        if (!row.id) {
          showNotification({ message: 'У выбранного проекта отсутствует шифр', theme: 'danger' });
          return;
        }
        getScientistDataAPI.callAPI(
          { personId: row.id, modules: [PersonModule.GENERAL] },
          {
            onSuccessfullCall: ({ data }) => {
              setBalance(prev => ({ ...prev!, responsible: data }));
            },
          },
        );
      }
      return (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Полных лет">{balance?.year || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата" isRequired>
              <TextDateTime value={balance?.date} onChange={e => setBalance(prev => ({ ...prev!, date: e }))} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер счета">
              <TextInput value={balance?.accountNumber} onChange={e => setBalance(prev => ({ ...prev!, accountNumber: e }))} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Балансовая стоимость">
              <TextInput
                value={balance?.amount}
                onChange={e => setBalance(prev => ({ ...prev!, amount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Увеличение/Уменьшение">
              <TextInput
                value={balance?.alphaAmount}
                onChange={e => setBalance(prev => ({ ...prev!, alphaAmount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Остаточная стоимость">
              <TextInput
                value={balance?.residualAmount}
                onChange={e => setBalance(prev => ({ ...prev!, residualAmount: e }))}
                mode={TextInputMode.NUMBER}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="МОЛ">
              <TextArea
                value={balance?.responsible?.fullName || ''}
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Персоны',
                  table: {
                    specification: GetDetailedScientistList({}),
                    onSelect: handleResponsibleSelect,
                  },
                  isClearable: true,
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea value={balance?.note} onChange={e => setBalance(prev => ({ ...prev!, note: e }))} />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      );
    },
    [getScientistDataAPI],
  );

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title="Сведения о закупке" />

          <FormComponent.Line>
            <FormComponent.Field label="В составе комплекса">
              <TextArea
                mode={TextAreaMode.TABLE}
                value={[
                  equip.parentKitContent?.equip?.name || '',
                  equip.parentKitContent?.name ? ` (${equip.parentKitContent?.name})` : '',
                ]
                  .filter(Boolean)
                  .join(', ')}
                settings={{
                  title: 'Оборудование',
                  table: {
                    specification: GetEquipList({ mode: 'equip', hasSelectButton: true }),
                    onSelect: handleParentKitContentChange,
                  },
                }}
                isDisabled={isDisabled}
              />
              <FormComponent.Description classMixin="is-small">
                Инв. номер: {equip.parentKitContent?.equip?.inventoryNumber || ''}
              </FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер договора">
              <TextInput
                value={equip.contractNumber}
                onChange={e => setEquip(prev => ({ ...prev, contractNumber: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 100 }}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Дата договора">
              <TextDateTime
                value={equip.contractDate}
                onChange={e => setEquip(prev => ({ ...prev, contractDate: e }))}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сумма">
              <TextInput
                mode={TextInputMode.NUMBER}
                value={equip.amount}
                onChange={e => setEquip(prev => ({ ...prev, amount: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 9 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Организация">
              <Select
                mode={SelectMode.REFERENCE}
                value={equip.enterprise}
                onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, enterprise: e }))}
                settings={{
                  title: 'Справочник "Организации"',
                  name: 'RefEnterpriseCustomer',
                  isClearable: true,
                }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер закупки в ЕИС">
              <TextInput
                value={equip.eisNumber}
                onChange={e => setEquip(prev => ({ ...prev, eisNumber: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 100 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ссылка на ЕИС">
              <TextInput
                mode={TextInputMode.URL}
                value={equip.eisUrl}
                onChange={e => setEquip(prev => ({ ...prev, eisUrl: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 100 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Инвентарный номер">
              <TextInput
                value={equip.inventoryNumber}
                onChange={e => setEquip(prev => ({ ...prev, inventoryNumber: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 100 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер инв. карточки нефинансовых активов">
              <TextInput
                value={equip.nfaInventoryCardNumber}
                onChange={e => setEquip(prev => ({ ...prev, nfaInventoryCardNumber: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 100 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Описание (дополнительная информация)">
              <TextArea
                mode={TextAreaMode.MODAL}
                value={equip.purchaseNote}
                onChange={e => setEquip(prev => ({ ...prev, purchaseNote: e }))}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <SectionTitle title="Источники финансирования" />
          <ListEdit
            rows={equip.investmentSources}
            onChange={e => setEquip(prev => ({ ...prev, investmentSources: e }))}
            toolbar={['add', 'edit', 'delete']}
            columns={[
              { label: 'Шифр проекта', formatValue: row => row.projectCode?.code || '' },
              { label: 'Сумма', formatValue: row => row.amount || '', dataKind: 'float' },
              { label: 'Номер проекта', formatValue: row => row.projectCode?.project?.projectNumber || '' },
              {
                label: 'Источник финансирования',
                formatValue: row => row.source || row.projectCode?.investmentSource?.source?.label || '',
              },
            ]}
            specification={{
              mode: 'customModalComponent',
              modalTitle: 'Источник финансирования',
              renderComponent: (row, onChange) => renderInvestmentSourceFields(row, onChange),
              validation: {
                checkIsValid: row => !!row?.projectCode || !!row?.source,
                onInvalidate: () =>
                  showNotification({ message: 'Укажите шифр проекта или источник финансирования', theme: 'danger' }),
              },
            }}
          />
          <FormComponent.Line>
            <FormComponent.Field label="Приобретено в рамках программы">
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Партнёрские программы',
                  table: {
                    specification: GetProgramList({ withoutMenu: true }),
                    onSelect: handleProgramSelect,
                  },
                }}
                value={equip.program?.name}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <SectionTitle title="Направление использования" />
          <FormComponent.Line>
            <RefList
              rows={equip.useCategories}
              onChange={e => setEquip(prev => ({ ...prev, useCategories: e }))}
              toolbar={['add', 'edit', 'delete']}
              modalTitle='Справочник "Направления использования оборудования"'
              refName="RefEquipUseCategory"
              isDisabled={isDisabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <SectionTitle title="Балансовая стоимость" />
      <FormComponent.Line>
        <ListEdit
          rows={equip.balances}
          onChange={e => setEquip(prev => ({ ...prev, balances: e }))}
          toolbar={['add', 'edit', 'delete']}
          columns={[
            {
              label: 'Полных лет',
              formatValue: row => row.year || '',
              styles: { width: '5%' },
              dataKind: 'int',
            },
            { label: 'Дата', formatValue: row => row.date || '', styles: { width: '5%' }, dataKind: 'date', defaultSort: 'desc' },
            { label: 'Номер счета', formatValue: row => row.accountNumber || '', styles: { width: '15%' } },
            {
              label: 'Балансовая стоимость',
              formatValue: row => Number(row.amount) || '',
              styles: { width: '10%' },
              dataKind: 'float',
            },
            {
              label: 'Увеличение/Уменьшение',
              formatValue: row => row.alphaAmount || '',
              styles: { width: '10%' },
              dataKind: 'float',
            },
            {
              label: 'Остаточная стоимость',
              formatValue: row => row.residualAmount || '',
              styles: { width: '10%' },
              dataKind: 'float',
            },
            { label: 'МОЛ', formatValue: row => row.responsible?.fullName || '', styles: { width: '25%' } },
            { label: 'Примечание', formatValue: row => row.note || '', styles: { width: '20%' } },
          ]}
          specification={{
            mode: 'customModalComponent',
            modalTitle: 'Балансовая стоимость',
            renderComponent: (row, onChange) => renderBalanceFields(row, onChange),
            validation: {
              checkIsValid: row => !!row?.date,
              onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
            },
          }}
        />
      </FormComponent.Line>
      <Modal
        title={`Укажите позицию в составе комплекса оборудования "${parentEquip?.name}" ${
          parentEquip?.inventoryNumber ? ` (${parentEquip?.inventoryNumber})` : ''
        }`}
        isOpen={isParentEquipChildSelectOpen}
        onClose={() => {
          setIsParentEquipChildSelectOpen(false);
          setParentEquipChildIndex(null);
        }}
        size="extralarge"
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Выбрать',
            onClick: () => {
              setEquip(prev => ({
                ...prev,
                parentKitContent: getMockKitContent({
                  equip: parentEquip,
                  id: parentEquip?.kitContents[parentEquipChildIndex ?? -1]?.id,
                  name: parentEquip?.kitContents[parentEquipChildIndex ?? -1]?.name,
                }),
                name: prev.name || parentEquip?.kitContents[parentEquipChildIndex ?? -1]?.name || '',
                specifications: prev.specifications || parentEquip?.kitContents[parentEquipChildIndex ?? -1]?.note || '',
              }));
              setTimeout(clearParentEquipInfo, 0);
            },
            isDisabled: parentEquipChildIndex === null,
          },
          {
            mode: ButtonMode.PRIMARY,
            text: 'Создать новую позицию',
            onClick: () => setIsKitContentModalOpen(true),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: () => {
              setIsParentEquipChildSelectOpen(false);
              setParentEquipChildIndex(null);
            },
          },
        ]}
      >
        <ListEditTable
          rows={parentEquip?.kitContents || []}
          columns={kitContentColumns}
          selectedRowIndex={parentEquipChildIndex}
          selectRow={setParentEquipChildIndex}
        />
      </Modal>
      <Modal
        title={`Создание нового элемента комплекта в составе комплекса "${parentEquip?.name}" ${
          parentEquip?.inventoryNumber ? ` (${parentEquip?.inventoryNumber})` : ''
        }"`}
        isOpen={isKitContentModalOpen}
        onClose={() => {
          setIsKitContentModalOpen(false);
          setNewParentEquipChild(null);
        }}
        size="large"
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setEquip(prev => ({
                ...prev,
                parentKitContent: getMockKitContent({
                  ...newParentEquipChild,
                  equip: parentEquip,
                }),
                name: prev.name || newParentEquipChild?.name || '',
                specifications: prev.specifications || newParentEquipChild?.note || '',
              }));
              setTimeout(clearParentEquipInfo, 0);
            },
            isDisabled: !newParentEquipChild?.name,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: () => {
              setIsKitContentModalOpen(false);
              setNewParentEquipChild(null);
            },
          },
        ]}
      >
        <KitContentFields row={newParentEquipChild} setRow={setNewParentEquipChild} isChildren={true} />
      </Modal>
    </>
  );
}

import { FormComponent, Icon } from 'components';

export function PersonalProjectGridHelp(): JSX.Element {
  return (
    <>
      <h3>
        Раздел Мои научные проекты содержит список научных проектов, в которых вы указаны в составе участников коллектива проекта.
      </h3>

      <p>
        Вы можете выгрузить список проектов в файл с помощью кнопки <Icon type="excel" />
      </p>
      <p>
        Если ваша роль в проекте – «руководитель проекта» или «ответственный исполнитель», то у вас есть дополнительные права:
      </p>

      <ul>
        <li>
          Открыть форму проекта для просмотра с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Заполнить данные о проекте для госрегистрации с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Распечатать отчётные формы проекта с помощью кнопки <Icon type="print" />
        </li>
        <li>
          Задать вопрос администратору с помощью кнопки <Icon type="admin" />
        </li>
      </ul>

      <div>
        Процедуру госрегистрации в <FormComponent.Link href="https://rosrid.ru" label="ЕГИСУ НИОКТР" /> научно-исследовательских,
        опытно-конструкторских и технологических работ гражданского назначения, выполняемых в Университете и научных отчётов по
        этим проектам, осуществляют сотрудники Научного управления. Вам лишь необходимо заполнить поля в карточке проекта:
        Аннотация, Научный задел, Обоснование междисциплинарного подхода. Для заполнения в проекте данных для горегистрации
        проекта откройте форму проекта с помощью кнопки <Icon type="edit" />.
      </div>

      <p>
        Если у вас есть вопросы к сотрудникам Научного управления по вопросам госрегистрации - напишите сообщение с помощью кнопки
        Задать вопрос. Укажите тему сообщения «Госрегистрация». В течение рабочего дня вы получите ответ на ваш вопрос. Все ваши
        вопросы и ответы на них — в разделе Задать вопрос
      </p>
    </>
  );
}

import { numberRegExp } from 'utils/Regexps';
import { EditableMetadataField } from './model';

function getRequiredEmptyFields(fields: EditableMetadataField[]) {
  const emptyRequiredFields = fields.filter(x => x.required && (x.value === null || x.value === ''));
  return emptyRequiredFields.map(x => ({ fieldName: x.name, message: `Заполните обязательное поле ${x.caption}` }));
}

function getInvalidNumberFields(fields: EditableMetadataField[]) {
  const invalidNumberFields = fields.filter(x => x.type === 'INTEGER' && x.value !== null && !isNumberValid(x.value));
  return invalidNumberFields.map(x => ({ fieldName: x.name, message: `Не валидное числовое значение ${x.caption}` }));
}

export function validate(fields: EditableMetadataField[]) {
  const emptyRequiredFields = getRequiredEmptyFields(fields);
  const invalidNumberFields = getInvalidNumberFields(fields);

  return [...emptyRequiredFields, ...invalidNumberFields];
}

const isNumberValid = (value: string): boolean => new RegExp(numberRegExp).test(value);

import { Item } from 'types/models/common';

export type State = {
  project?: Item | null;
};

export function makeUseCustomController(state: State) {
  return function useCustomController(): State {
    return state;
  };
}

import React from 'react';
import { Modal } from 'components';
import { GetLinkedHistoryEntitiesRequestData } from 'services/BackendAPI/configurations/author/types';
import { Component } from './component';

type Props = {
  personId?: string;
  isOpen: boolean;
  onClose: () => void;
} & GetLinkedHistoryEntitiesRequestData;

export const Container = ({ isOpen, id, type, personId, onClose }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Связанные элементы" size={['large', 'small-height']}>
    <Component id={id} type={type} personId={personId} />
  </Modal>
);

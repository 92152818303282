import { ReferenceItem } from 'components';

import { FileInfo, Document, PersonHistory } from 'types/models';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';
import { SecurityDocumentType } from 'utils/Enums';

import { Item } from '../common';
import { Department, RefElement, Project } from '../Project';
import { ScientistPerson } from '../Person';

export type SecurityDocument = {
  id: string | null;
  label: string;
  type: Item | null;
  intellectualPropertyName: string;
  kind: ReferenceItem | null;
  referat: string;
  requestNumber: string;
  requestDate: string;
  requestSendDate: string;
  documentDate: string;
  documentReceiveDate: string;
  balanceOrderNumber?: number;
  loDate: string;
  hiDate: string;
  countries: ReferenceItem[];
  isWorldwide: boolean;
  isRus: boolean;
  balancePrice: number;
  documentNumber: string;
  internalNumber: string;
  documentStartDate: string;
  documentEndDate: string;
  stateNumber: string;
  stateRegistrationDate: string;
  mpks: RefElement[];
  members: SecurityDocumentMember[];
  grntis: RefElement[];
  udks: RefElement[];
  okveds: RefElement[];
  lksetss: RefElement[];
  scienceBrunches: RefElement[];
  scienceDomainInterrests: RefElement[];
  vakSpecialities: RefElement[];
  priorityTechnologies: RefElement[];
  pnrs: RefElement[];
  pnmitrs: RefElement[];
  pnis: RefElement[];
  ntrStrategies: RefElement[];
  technologyPlatforms: RefElement[];
  knowledgeBranches: RefElement[];
  oecds: RefElement[];
  ntiEteTechnologies: RefElement[];
  ntiMarkets: RefElement[];
  contracts: SecurityDocumentContract[];
  payments: SecurityDocumentPayment[];
  project: Project | null;
  documents: Document[];
  departments: Department[];
  isRejected: boolean;
  balances: Balance[];
};

export type Balance = {
  id: string | null;
  date: string;
  amount: string;
  alphaAmount: string;
  residualAmount: string;
};

export enum SecurityDocumentMemberTypes {
  APPLICANT = 'APPLICANT',
  AUTHOR = 'AUTHOR',
  ADMITTED_PERSON = 'ADMITTED_PERSON',
}

export type SecurityDocumentMember = {
  id: string | null;
  contribution: number;
  type: Item | null;
  person: ScientistPerson | null;
  name: string;
  contractNumber: string;
  contractDate: string;
  contractPortion: string;
  contractNote: string;
  enterprise: ReferenceItem | null;
} & PersonHistory;

export type SecurityDocumentPayment = {
  id: string | null;
  securityDocument: SecurityDocumentInfo | null;
  paymentPurposeRequest: ReferenceItem | null;
  paymentPurpose: ReferenceItem | null;
  payDate: string;
  payBeforeDate: string;
  amount: string;
  file?: FileInfo;
};

export type SecurityDocumentInfo = {
  id: string;
  type?: Item<SecurityDocumentType>;
  fullName?: string;
  documentNumber?: string;
  value?: string;
};

export type PaymentRequestPerson = {
  id: string | null;
  fullName: string;
  shortName?: string;
};

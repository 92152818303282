import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetScientistJobDepartmentList: Table.Specification = {
  apiID: 'GetScientistJobDepartmentList',
  header: {
    firstLevel: {
      title: 'Список сотрудников в подразделениях',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { EquipMode, makeUseCustomController, State } from './makeUseCustomController';
import { Filters } from './filters';

type Args = {
  mode: EquipMode;
  hasSelectButton?: boolean;
};

export function GetEquipList({ mode, hasSelectButton }: Args): Table.Specification<State> {
  return {
    apiID: 'GetEquipList',
    header: {
      firstLevel: {
        title: mode === 'search' ? 'Список научного оборудования' : 'Оборудование',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
    useCustomController: makeUseCustomController({ mode }),
  };
}

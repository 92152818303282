import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

type Props = {
  isApproved: boolean;
};

const CommunityEditTemplate: React.FC<F.TemplateProps & Props> = ({
  isOpen,
  onClose,
  id,
  name,
  relatedTableState,
  templateName,
  isApproved,
}: F.TemplateProps & Props) => {
  const look: F.Look = {
    apiID: 'GetCommunity',
    template: templateName,
    editMode: true,
    id,
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Редактирование члена научного сообщества "${name}" (ID: ${id}, Статус: ${isApproved ? 'Утверждено' : 'Добавлено'})`}
      size={['large', 'small-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityEditTemplate);

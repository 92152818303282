import { Table as T } from 'types/models';
import LeftPanelForThirdLevel from './LeftPanelForThirdLevel';

export function GetMagazineReleaseArticleList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  isClearToolbar?: boolean,
): T.Specification {
  return {
    apiID: 'GetMagazineReleaseArticleList',
    templatesTableDependencies,

    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: isClearToolbar ? undefined : LeftPanelForThirdLevel,
      },
    },
  };
}

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Select, SelectMode, TextArea, Toolbar } from 'components';

import { Report, Table } from 'types/models';
import { SelectProjectPopup } from 'features/Form/views/SelectProjectPopup';
import { ContestRequestType } from 'utils/Enums';
import { Item } from 'types/models/common';
import { Permits } from 'utils/Permissions';
import { ContestRequestStatus } from 'utils/Enums/ContestRequestStatus';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ContestRequestTab } from 'utils/Permissions/ContestRequestTab';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { getContestRequestStatusButtons } from '../helpers';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';
import { ContestRequestGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const requestStatus = selectedRow?.['id:Status'] as ContestRequestStatus;

  const reports: Report[] = [
    Reports.ContestRequest,
    Reports.ContestRequestEstimate,
    Reports.ContestRequestPerformerCompositionMemo,
    Reports.ContestRequestTechnicalTask,
    Reports.ContestRequestProjectResults,
    Reports.ProjectIndicators,
    Reports.ContestRequestPerformerCompositionChangeMemo,
  ];

  const [projectId, setProjectId] = useState<string | null>(null);
  const [isSelectFormOpen, setIsSelectFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isTypeFormOpen, setIsTypeFormOpen] = useState<boolean>(false);
  const [requestType, setRequestType] = useState<Item<ContestRequestType> | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const isEditPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
        tabKey => Permits[`CONTEST_REQUEST_EDIT_${requestStatus}_${tabKey}`],
      ),
    );
  }, [requestStatus, userPermission]);

  const onSubmitSelectProject = useCallback((id: string) => {
    setProjectId(id);
    setIsSelectFormOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ContestRequest',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, selectedRow?.id, tableStreams.deleteRow]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const onSelectProjectFormClose = useCallback(() => {
    setIsSelectFormOpen(false);
  }, []);

  const confirmTypeSelect = useCallback(() => {
    if (requestType) {
      setIsTypeFormOpen(false);
      setIsSelectFormOpen(true);
    } else showNotification({ message: 'Укажите тип заявки', theme: 'danger' });
  }, [requestType]);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getContestRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({
      id: x.id,
      status: (x?.['id:Status'] as ContestRequestStatus) || null,
      type: x?.['id:Type'] as ContestRequestType,
    })),
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsTypeFormOpen(true),
        permission: {
          name: Permits.CONTEST_REQUEST_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: {
          name: (Object.keys(ContestRequestStatus) as Array<ContestRequestStatus>).flatMap(statusKey =>
            (Object.keys(ContestRequestTab) as Array<ContestRequestTab>).map(
              tabKey => Permits[`CONTEST_REQUEST_EDIT_${statusKey}_${tabKey}`],
            ),
          ),
        },
        isDisabled: tableState.selectedRows.length !== 1 || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: {
          name: Permits.CONTEST_REQUEST_DELETE_DRAFT,
        },
        isDisabled: tableState.selectedRows.length !== 1 || requestStatus !== 'DRAFT',
      },
      ...statusChangeButtons,
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: tableState.selectedRows.length > 1,
      },
    ],
    [getReports, handleSetCurrentReport, isEditPermitted, requestStatus, statusChangeButtons, tableState.selectedRows.length],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {isAddFormOpen && requestType && (
        <modalTemplates.ContestRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          projectId={projectId || ''}
          relatedTableState={tableState}
          requestType={requestType.value}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.ContestRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isViewFormOpen && (
        <modalTemplates.ContestRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>Заявка на конкурс от {tableState.selectedRows[0]?.Leader}. Удалить заявку?</>
        </Modal>
      )}

      <Modal
        mode="help"
        title="Выберите тип заявки"
        isOpen={isTypeFormOpen}
        onClose={() => setIsTypeFormOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmTypeSelect,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsTypeFormOpen(false),
          },
        ]}
        size="small"
      >
        <p>
          <strong>Добавить заявку может только руководитель проекта или ответственный исполнитель</strong>
        </p>
        <p>Укажите форму заявки:</p>
        <p>
          Для участия в конкурсе на продление завершённого проекта - <strong>Заявка на продление проекта</strong>
        </p>
        <p>
          Для процедуры актуализации данных этапа текущего проекта - <strong>Заявка на актуализацию данных этапа</strong>
        </p>

        <Select
          mode={SelectMode.ENUM}
          value={requestType}
          settings={{ name: 'ContestRequestType' }}
          onChange={option => setRequestType(option as Item<ContestRequestType>)}
        />
      </Modal>

      {isSelectFormOpen && (
        <SelectProjectPopup isOpen={isSelectFormOpen} onClose={onSelectProjectFormClose} onSubmit={onSubmitSelectProject} />
      )}

      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            contestRequestId: selectedRow?.id || '',
            projectId: selectedRow?.ProjectId || '',
          }}
        />
      )}

      <Modal
        mode="help"
        title="Настройки Системы для проведения заявочной компании"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {ContestRequestGridHelp()}
      </Modal>

      {isStatusModalOpen && (
        <Modal
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: statusLabel,
              onClick: changeStatus,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsStatusModalOpen(false),
            },
          ]}
          size="small"
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </Modal>
      )}

      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useCallback, useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

const ToolbarTemplate = ({ tableState, onSubmitTable }: Props) => {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: handleSelectButtonClick,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [rowsAvailability, handleSelectButtonClick],
  );

  return <Toolbar buttons={buttons} />;
};

export const Component = React.memo(ToolbarTemplate);

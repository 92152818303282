import React, { useMemo } from 'react';

import { FormComponent, ReferenceItem, SectionTitle, Select, SelectMode } from 'components';

import { RefElement } from 'types/models/Project';
import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';

export type Props = {
  internationalProgram: ReferenceItem | null;
  setInternationalProgram(internationalProgram: ReferenceItem): void;
  foreignEnterprise: ReferenceItem | null;
  setForeignEnterprise(foreignEnterprise: ReferenceItem): void;
  partnerCountries: RefElement[];
  setPartnerCountries(partnerCountries: RefElement[]): void;
  disabled: boolean;
};

export function InternationalPartnership(props: Props) {
  const {
    internationalProgram,
    setInternationalProgram,
    foreignEnterprise,
    setForeignEnterprise,
    partnerCountries,
    setPartnerCountries,
    disabled,
  } = props;

  const isPartnerInfoEmpty = useMemo(
    () =>
      !(foreignEnterprise?.customFields?.sectorActivity as ReferenceItem)?.id ||
      !(foreignEnterprise?.customFields?.lksdEnterpriseType as ReferenceItem)?.id,
    [foreignEnterprise?.customFields?.lksdEnterpriseType, foreignEnterprise?.customFields?.sectorActivity],
  );

  return (
    <>
      <SectionTitle title="Международное сотрудничество" />
      <FormComponent.Line>
        <FormComponent.Field label="Международная программа">
          <Select
            mode={SelectMode.REFERENCE}
            value={internationalProgram}
            onChange={setInternationalProgram}
            settings={{ name: 'RefInternationalProgram', title: 'Справочник "Научные программы"', isClearable: true }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="В интересах иностранной компании">
          <Select
            mode={SelectMode.REFERENCE}
            value={foreignEnterprise}
            onChange={setForeignEnterprise}
            settings={{ name: 'RefEnterpriseCustomer', title: 'Справочник "Организации"', isClearable: true }}
            isDisabled={disabled}
          />
          {!!foreignEnterprise?.id && isPartnerInfoEmpty && (
            <FormComponent.Description mode="warning" classMixin={'is-hint'}>
              Важно! Укажите для орагнизации Сектор деятельности и Тип организации. Эти данные необходимы для отчетности по
              сотрудничеству с международными организациями
            </FormComponent.Description>
          )}
          {!!foreignEnterprise?.id && !isPartnerInfoEmpty && (
            <FormComponent.Description classMixin={'is-hint'}>
              <div>
                <strong>Сектор деятельности:</strong> {(foreignEnterprise?.customFields?.sectorActivity as ReferenceItem)?.label}
              </div>
              <div>
                <strong>Тип организации:</strong> {(foreignEnterprise?.customFields?.lksdEnterpriseType as ReferenceItem)?.label}
              </div>
            </FormComponent.Description>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <RefElementList
        header={{ title: 'Страны-партнёры' }}
        rows={partnerCountries}
        onChange={setPartnerCountries}
        toolbar={['add', 'delete']}
        modalTitle='Справочник "Cтраны"'
        refName="RefCountry"
        isDisabled={disabled}
      />

      <FormComponent.Line>
        <FormComponent.Description mode="warning">
          Международные организации-партнёры по проекту укажите на вкладке Организации-партнёры
        </FormComponent.Description>
      </FormComponent.Line>
    </>
  );
}

import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

import { Permits } from 'utils/Permissions';

import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { useMemo } from 'react';
import { Actions } from 'utils/Permissions/Actions';
import { IncentivePaymentRequestType } from 'types/models/Payment';

export function usePermissions(status: AuthorPaymentRequestStatus | null) {
  const { userPermission } = useAppDataContext();

  const editInStatusPermission = useMemo<Record<string, string>>(
    () => ({
      [AuthorPaymentRequestStatus.DRAFT]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.DRAFT,
      ),
      [AuthorPaymentRequestStatus.AGREEMENT]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.AGREEMENT,
      ),
      [AuthorPaymentRequestStatus.PAID]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.PAID,
      ),
      [AuthorPaymentRequestStatus.REJECTED]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.REJECTED,
      ),
      [AuthorPaymentRequestStatus.REVISION]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.REVISION,
      ),
      [AuthorPaymentRequestStatus.POSTPONE]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.EDIT,
        AuthorPaymentRequestStatus.POSTPONE,
      ),
    }),
    [],
  );

  const mapStatusPermission = useMemo<Record<string, string>>(
    () => ({
      [AuthorPaymentRequestStatus.AGREEMENT as string]: Permits.PATENT_REQUEST_STATUS_TO_AGREEMENT,
      [AuthorPaymentRequestStatus.PAID as string]: Permits.PATENT_REQUEST_STATUS_TO_PAID,
      [AuthorPaymentRequestStatus.REJECTED as string]: Permits.PATENT_REQUEST_STATUS_TO_REJECTED,
      [AuthorPaymentRequestStatus.REVISION as string]: Permits.PATENT_REQUEST_STATUS_TO_REVISION,
      [AuthorPaymentRequestStatus.POSTPONE as string]: Permits.PATENT_REQUEST_STATUS_TO_POSTPONE,
      [AuthorPaymentRequestStatus.PAYMENT_APPROVED as string]: Permits.PATENT_REQUEST_STATUS_TO_PAYMENT_APPROVED,
    }),
    [],
  );

  const deleteInStatusPermission = useMemo<Record<string, string>>(
    () => ({
      [AuthorPaymentRequestStatus.DRAFT]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.DRAFT,
      ),
      [AuthorPaymentRequestStatus.AGREEMENT]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.AGREEMENT,
      ),
      [AuthorPaymentRequestStatus.PAID]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.PAID,
      ),
      [AuthorPaymentRequestStatus.REJECTED]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.REJECTED,
      ),
      [AuthorPaymentRequestStatus.REVISION]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.REVISION,
      ),
      [AuthorPaymentRequestStatus.POSTPONE]: Permits.getNomineeRequestCRUDPermission(
        IncentivePaymentRequestType.SECURITY_DOCUMENT,
        Actions.DELETE,
        AuthorPaymentRequestStatus.POSTPONE,
      ),
    }),
    [],
  );

  const hasStatusChangePermission = status ? isHasPermission(userPermission, mapStatusPermission[status]) : false;
  const hasEditInStatusPermission = status ? isHasPermission(userPermission, editInStatusPermission[status]) : false;
  const hasDeleteInStatusPermission = status ? isHasPermission(userPermission, editInStatusPermission[status]) : false;
  return {
    hasStatusChangePermission,
    mapStatusPermission,
    editInStatusPermission,
    hasEditInStatusPermission,
    deleteInStatusPermission,
    hasDeleteInStatusPermission,
  };
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { State } from '../makeUseCustomController';
import { NotificationMonitor } from '.';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController({ tableState, customState: { module, sourceId } }: Props) {
  const tableStreams = useLocalTableStreams();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const defaultNotificationMonitor: NotificationMonitor = {
    person: {
      id: '',
      name: '',
    },
    note: '',
  };

  const [notificationMonitor, setNotificationMonitor] = useState<NotificationMonitor>({ ...defaultNotificationMonitor });
  const [isOpenNotificationMonitor, setIsOpenNotificationMonitor] = useState<boolean>(false);
  const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState<boolean>(false);

  const { methods: saveNotificationMonitorAPI } = BackendAPI.useBackendAPI('SaveNotificationMonitor');
  const { methods: deleteNotificationMonitorAPI } = BackendAPI.useBackendAPI('DeleteNotificationMonitor');

  const onSaveNotificationMonitor = useCallback(() => {
    saveNotificationMonitorAPI.callAPI(
      { module, scientistId: notificationMonitor.person.id, sourceId, note: notificationMonitor.note },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Наблюдатель добавлен', theme: 'success' });
          tableStreams.reloadTable.push({});
          setNotificationMonitor({ ...defaultNotificationMonitor });
        },
      },
    );
  }, [
    defaultNotificationMonitor,
    module,
    notificationMonitor.note,
    notificationMonitor.person.id,
    saveNotificationMonitorAPI,
    sourceId,
    tableStreams.reloadTable,
  ]);

  const onDeleteNotificationMonitor = useCallback(() => {
    deleteNotificationMonitorAPI.callAPI(
      { ids: tableState.selectedRows.map(i => i.id) },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Наблюдатели удалены', theme: 'success' });
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [tableState.selectedRows, deleteNotificationMonitorAPI, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsOpenNotificationMonitor(true),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsOpenConfirmDelete(true),
        isDisabled: tableState.selectedRows.length <= 0,
      },
    ],
    [tableState.selectedRows],
  );

  return {
    buttons,
    notificationMonitor,
    setNotificationMonitor,
    isOpenNotificationMonitor,
    setIsOpenNotificationMonitor,
    onSaveNotificationMonitor,
    isOpenConfirmDelete,
    setIsOpenConfirmDelete,
    onDeleteNotificationMonitor,
  };
}

import { Table } from 'types/models';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { CommunityType } from 'utils/Enums';

export type Props = {
  personId?: string;
  types: CommunityType[];
};

export function GetScientistCommunityMemberList({ personId, types }: Props): Table.Specification {
  const requestData = { types, scientistId: personId || '-1' };
  return {
    apiID: 'GetScientistCommunityMemberList',
    isFiltersHidden: true,
    requestData,
    header: {
      firstLevel: {
        title: 'Членство в научных сообществах и организациях',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel(),
      },
    },
  };
}

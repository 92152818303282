import { Event, Table } from 'types/models';

export type State = {
  workMode: Table.WorkMode;
  sourceEvent: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
  unconnectedParticipationPublications: Event.Source[];
};

type Props = {
  workMode: Table.WorkMode;
  sourceEvent: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
  unconnectedParticipationPublications?: Event.Source[];
};

export function makeUseCustomController({
  workMode,
  sourceEvent,
  reloadEvent,
  isEventChanged,
  unconnectedParticipationPublications,
}: Props) {
  return function useCustomController(): State {
    return {
      workMode,
      sourceEvent,
      reloadEvent,
      isEventChanged,
      unconnectedParticipationPublications: unconnectedParticipationPublications || [],
    };
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  withoutMenu?: boolean;
  hasSelectButton?: boolean;
};

export function GetProgramList({ withoutMenu, hasSelectButton = true }: Props): Table.Specification {
  return {
    apiID: 'GetProgramList',
    header: {
      firstLevel: {
        title: 'Список программ поддержки научной деятельности',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: !withoutMenu ? LeftPanelForThirdLevel : undefined,
        hasSelectButton: hasSelectButton,
      },
    },
  };
}

import React from 'react';
import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const RoleEditTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetRole',
    template: 'RoleForm',
    editMode: true,
    id,
    relatedTableState,
  };
  return isOpen ? <Form look={look} onClose={onClose} /> : <></>;
};
export const Component = React.memo(RoleEditTemplate);

import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';
import { GetKindConsumptionList, GetProjectCodeList } from 'features/Table/specifications';
import { CashDocument, Table } from 'types/models';

type Props = {
  item: CashDocument.Item | null;
  setItem: React.Dispatch<React.SetStateAction<CashDocument.Item | null>>;
  formType: 'expense' | 'receive';
};

export function ItemFields({ item, setItem, formType }: Props) {
  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode');
  const { methods: getKindConsumption } = BackendAPI.useBackendAPI('GetKindConsumption');

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Шифр проекта" isRequired>
          <TextArea
            value={item?.projectCode?.code}
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Шифры ЦФ',
              table: {
                specification: GetProjectCodeList,
                onSelect: (row: Table.Entry | null) => {
                  if (row) {
                    getProjectCode.callAPI(
                      { id: row.id },
                      { onSuccessfullCall: ({ data }) => setItem(prev => ({ ...prev!, projectCode: data })) },
                    );
                  } else {
                    setItem(prev => ({ ...prev!, projectCode: null }));
                  }
                },
              },
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма" isRequired>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={item?.amount}
            onChange={e => setItem(prev => ({ ...prev!, amount: e }))}
            settings={{ isNatural: false }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {formType === 'receive' && (
        <FormComponent.Line>
          <FormComponent.Field label="Примечание">
            <TextInput value={item?.note} onChange={e => setItem(prev => ({ ...prev!, note: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {formType === 'expense' && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Вид расхода" isRequired>
              <TextArea
                value={[item?.kindConsumption?.code, item?.kindConsumption?.name].filter(Boolean).join(' ')}
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Виды расхода',
                  table: {
                    specification: GetKindConsumptionList({}),
                    onSelect: (row: Table.Entry | null) => {
                      if (row) {
                        getKindConsumption.callAPI(
                          { id: row.id },
                          { onSuccessfullCall: ({ data }) => setItem(prev => ({ ...prev!, kindConsumption: data })) },
                        );
                      } else {
                        setItem(prev => ({ ...prev!, kindConsumption: null }));
                      }
                    },
                  },
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Назначение платежа">
              <TextInput value={item?.purpose} onChange={e => setItem(prev => ({ ...prev!, purpose: e }))} />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </>
  );
}

import { Tabs, Tab, Icon } from 'components';

export function FundsGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Фонд подразделений по основаниям стимулирующих выплат</h4>
        <p>
          Таблица содержит список записей о текущем состоянии Фондов подразделений в разрезе объектов-основания для заявки
          стимулирующие выплаты (статья, монография, учебник, охранный документ, конференция, выставка) и временных атрибутов. Для
          вычисления значений в столбцах таблицы используются данные заявок номинантам в статусах:{' '}
          <strong>На согласовании, Передана на оплату, Оплата одобрена.</strong>
        </p>
        <ul>
          <li>
            Фильтры <strong>Год</strong> и <strong>Квартал</strong> влияют на значения в столбцах таблицы, которые вычисляются на
            основе данных из заявок с атрибутами год и квартал(ы) в соответствии с указанными в фильтрах.
          </li>
          <li>
            В зависимости от условий, прописанных в Положении о стимулирующих выплатах, заявки могут оформляться 1 раз в год или
            по-квартально, или по-месячно.
          </li>
        </ul>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание значения в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Факультет/институт</td>
              <td>Название подразделения</td>
            </tr>
            <tr>
              <td>Основание</td>
              <td>Категория заявок на стимулирующие выплаты. Фонд подразделения формируется по каждой категории отдельно.</td>
            </tr>
            <tr>
              <td>Квартал</td>
              <td>
                Один или несколько кварталов. В зависимости от выбранного значения для подразделения будут вычислены значения в
                столбцах таблицы.
              </td>
            </tr>
            <tr>
              <td>Месяц</td>
              <td>Номер месяца, столбец актуален, если заявки оформляются ежемесячно.</td>
            </tr>
            <tr>
              <td>год</td>
              <td>В зависимости от выбранного значения для подразделения будут вычислены значения в столбцах таблицы.</td>
            </tr>
            <tr>
              <td>Показатель</td>
              <td>Код показателя из Положения о стимулирующих выплатах.</td>
            </tr>
            <tr>
              <td>Приход (на доработку)</td>
              <td>
                Консолидированная сумма для подразделения из заявок в статусе «на доработку»; источник данных - раздел{' '}
                <strong>Фонд подразделений</strong> на вкладке <strong>Расчёт</strong>.
              </td>
            </tr>
            <tr>
              <td>Приход (на согласовании)</td>
              <td>
                Консолидированная сумма для подразделения из заявок в статусе «на согласовании»; источник данных - раздел Фонд
                подразделений на вкладке Расчёт.
              </td>
            </tr>
            <tr>
              <td>Приход ( Передана на оплату)</td>
              <td>
                Консолидированная сумма для подразделения из заявок в статусе «Передана на оплату»; источник данных - раздел Фонд
                подразделений на вкладке Расчёт.
              </td>
            </tr>
            <tr>
              <td>Приход (Оплата одобрена)</td>
              <td>
                Консолидированная сумма для подразделения из заявок в статусе «Передана на оплату»; источник данных - раздел Фонд
                подразделений на вкладке Расчёт.
              </td>
            </tr>
            <tr>
              <td>Расход (на доработку)</td>
              <td>
                Консолидированная сумма из заявок на оплату сотрудникам из Фонда подразделения в статусе «на доработку». Источник
                данных - значение поля <strong>Сумма из</strong> заявок в статусе «на доработку» из таблицы{' '}
                <strong>Заявки на оплату сотрудникам из Фонда {'<Название подразделения>'}</strong>
              </td>
            </tr>
            <tr>
              <td>Расход (на согласовании)</td>
              <td>
                Консолидированная сумма из заявок на оплату сотрудникам из Фонда подразделения в статусе «на доработку». Источник
                данных - значение поля <strong>Сумма из</strong> заявок в статусе «на согласовании» из таблицы{' '}
                <strong>Заявки на оплату сотрудникам из Фонда {'<Название подразделения>'}</strong>.
              </td>
            </tr>
            <tr>
              <td>Расход ( Передана на оплату)</td>
              <td>
                Консолидированная сумма из заявок на оплату сотрудникам из Фонда подразделения в статусе «на доработку». Источник
                данных - значение поля <strong>Сумма из</strong> заявок в статусе «Передана на оплату» из таблицы{' '}
                <strong>Заявки на оплату сотрудникам из Фонда {'<Название подразделения>'}</strong>.
              </td>
            </tr>
            <tr>
              <td>Расход (Оплата одобрена)</td>
              <td>
                Консолидированная сумма из заявок на оплату сотрудникам из Фонда подразделения в статусе «Оплата одобрена».
                Источник данных - значение поля <strong>Сумма из</strong> заявок в статусе «на доработку» из таблицы{' '}
                <strong>Заявки на оплату сотрудникам из Фонда {'<Название подразделения>'}</strong>.
              </td>
            </tr>
            <tr>
              <td>Количество номинаций (доля)</td>
              <td>
                Отношение количества номинантов подразделения к количеству номинантов всего в заявках (доля). Источник данных —
                заявки номинантам, которые относятся к подразделению в статусах: оплата одобрена, передана на оплату, на
                согласовании, отложена
              </td>
            </tr>
            <tr>
              <td>Единиц номинаций (шт.)</td>
              <td>
                Количество номинантов в заявках, которые относятся к подразделению в статусах: оплата одобрена, передана на
                оплату, на согласовании, отложена
              </td>
            </tr>
            <tr>
              <td>Номинантам передано на оплату</td>
              <td>
                Общая сумма к оплате номинантам. Источник данных — заявки номинантам в статусах: «передана на оплату» + «оплата
                одобрена»
              </td>
            </tr>
            <tr>
              <td>Кол-во номинантов на оплату</td>
              <td>
                Количество номинантов из заявок на стимулирующие выплаты номинантов в статусах: «передана на оплату» + «оплата
                одобрена»
              </td>
            </tr>
            <tr>
              <td>Количество сотрудников</td>
              <td>Общее количество сотрудников из заявок для сотрудников в статусах: «передана на оплату» + «оплата одобрена»</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <>
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Отчёты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта . в котором один из параметров- подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя ( название подразделения отображается в профиле пользователя)
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </>
      </Tab>
    </Tabs>
  );
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions/Permits';
import { Report, Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useBackendAPI } from 'services/BackendAPI';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Color } from 'constants/colors';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRowsAccepted = tableState.selectedRows[0]?.IsAccepted === 'true';
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const { methods: AcceptPublicationProject } = useBackendAPI('AcceptPublicationProject');

  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDownloadPublicationFileDisabled = !(
    rowsAvailability.SINGLE_SELECTED &&
    tableState.selectedRows.length &&
    tableState.selectedRows[0]?.FileId
  );
  const isLinkButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isAcceptButtonDisabled = !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows.length);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const reports = useMemo<Report[]>(
    () => [Reports.EGISUParticipations, Reports.EGISUPublications, Reports.EGISUPublicationsByAllPersons],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const acceptPublicationProject = useCallback(() => {
    AcceptPublicationProject.callAPI(
      { id: tableState.selectedRows[0]?.PublicationProjectId },
      {
        onSuccessfullCall() {
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [AcceptPublicationProject, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть публикацию во внешнем источнике',
        expandedList: { list: getLinks },
        isDisabled: isLinkButtonDisabled,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл прикрепленный к публикации',
        onClick: handleDownloadPublicationFile,
        isDisabled: isDownloadPublicationFileDisabled,
      },
      {
        icon: { type: 'like', color: Color.success },
        title: 'Принять к отчету',
        onClick: acceptPublicationProject,
        isDisabled: isAcceptButtonDisabled || selectedRowsAccepted,
      },
      {
        icon: { type: 'dislike', color: Color.danger },
        title: 'Отменить принятие к отчету',
        onClick: acceptPublicationProject,
        isDisabled: isAcceptButtonDisabled || !selectedRowsAccepted,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      isViewButtonDisabled,
      getLinks,
      isLinkButtonDisabled,
      handleDownloadPublicationFile,
      isDownloadPublicationFileDisabled,
      isAcceptButtonDisabled,
      selectedRowsAccepted,
      getReports,
      handleSetCurrentReport,
      acceptPublicationProject,
    ],
  );

  return {
    isViewFormOpen,
    isViewButtonDisabled,
    handleTemplateCloseViewForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

export function GetPaymentOrderList(): Table.Specification {
  return {
    apiID: 'GetPaymentOrderList',
    header: {
      firstLevel: {
        title: 'Список приходных документов бухгалтерского учёта, дополненный данными о проектах',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

import { Event, Form } from 'types/models';
import { Notification } from 'features/Notifications';
import * as validator from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

const validateEvents = (events: Event.Event[], compilationYear: string, isEventsRequired: boolean) => {
  const result: Result = { isFormValid: true, nextNotification: { theme: 'success', message: '' }, invalidFieldKeys: [] };
  const compilationYearNumber = parseInt(compilationYear, 10) || 0;
  if (!compilationYearNumber) {
    result.nextNotification = { theme: 'danger', message: 'Поле год заполнено некорректно' };
  } else {
    const isValidEventsYear = events.every(
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      ({ startDate }) => new Date(...startDate.split('.')).getFullYear() <= compilationYearNumber,
    );

    result.isFormValid = isEventsRequired ? isValidEventsYear && !!events.length : isValidEventsYear;

    if (!result.isFormValid) {
      const incorrectMessage = 'У всех указанных конференций год начала должен быть меньше или равен году издания сборника';
      const invalidMessage = 'Заполните конференции';
      result.nextNotification.theme = 'danger';
      result.nextNotification.message = !isValidEventsYear ? incorrectMessage : !events.length ? invalidMessage : '';
    }
  }

  return result;
};

export function validate({
  formFields,
  events,
  isDraft,
  isEventsRequired,
}: {
  formFields: Form.Fields;
  events: Event.Event[];
  isDraft: boolean;
  isEventsRequired: boolean;
}) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };
  if (isDraft) {
    const requiredDraftKeys = ['name', 'year', 'typeEdition'];
    const draftResult = validator.getError(formFields, requiredDraftKeys);
    result.nextNotification = draftResult.nextNotification;
    result.isFormValid = draftResult.isFormValid;
    result.invalidFieldKeys = draftResult.invalidFieldKeys;
  } else {
    const defaultResult = validator.getError(formFields);

    result.nextNotification = defaultResult.nextNotification;
    result.isFormValid = defaultResult.isFormValid;
    result.invalidFieldKeys = defaultResult.invalidFieldKeys;

    if (result.isFormValid) {
      const eventsResult = validateEvents(events, formFields.year.value, isEventsRequired);

      result.nextNotification = eventsResult.nextNotification;
      result.isFormValid = eventsResult.isFormValid;
      result.invalidFieldKeys = [];
    }
  }

  return result;
}

import React from 'react';
import { setup } from 'bem-cn';

import { useController } from './controller';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('notifications');

const NotificationsComponent = () => {
  const { notifications, eventClick } = useController();

  return (
    <div className={b()}>
      {notifications.map(({ id, theme, message, isHide }) => (
        <div key={id} className={b('item', { 'is-hide': isHide })}>
          <div
            className={b('content', { theme })}
            key={id}
            dangerouslySetInnerHTML={{ __html: message }}
            onClick={() => eventClick(id)}
          ></div>
        </div>
      ))}
    </div>
  );
};

export const Component = React.memo(NotificationsComponent);

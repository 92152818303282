import { useCallback, useMemo } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const { settings } = useAppDataContext();

  const selectedRow = tableState.selectedRows.length === 1 ? tableState.selectedRows[0] : null;

  const isDownloadPublicationFileDisabled = !(tableState.selectedRows.length === 1 && tableState.selectedRows[0]?.FileId);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(selectedRow?.FileId, token);
  }, [selectedRow?.FileId, token]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'link' },
        title: 'Открыть во внешнем источнике',
        expandedList: { list: getLinks },
        isDisabled: selectedRow === null,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл прикрепленный к публикации',
        onClick: handleDownloadPublicationFile,
        isDisabled: isDownloadPublicationFileDisabled,
      },
    ],
    [selectedRow, getLinks, isDownloadPublicationFileDisabled, handleDownloadPublicationFile],
  );

  return {
    buttons,
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { arm } = customState;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'restriction',
        list: [
          {
            label: 'Все',
            value: 'all',
          },
          {
            label: 'Нет шифра проекта',
            value: 'withoutProjectCode',
          },
          {
            label: 'Нет рег. ЕГИСУ',
            value: 'withoutStateRegistration',
          },
          {
            label: 'Нет коллектива',
            value: 'withoutPerformers',
          },
          {
            label: 'С ограничениями',
            value: 'withRestrictions',
          },
          {
            label: 'Без ограничений',
            value: 'withoutRestrictions',
          },
        ],
        value: 'all',
      },
      {
        type: FilterComponentType.divider,
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Год',
        checked: true,
      },
    ],
    arm === 'pc' ? 'personal' : 'default',
  );
});

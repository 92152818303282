import { CashDocument } from 'types/models';
import { getMaybeField } from 'services/BackendAPI/configurations/commonConverters';

export function convertCashDocumentToRequest({
  id,
  isExpense,
  number,
  payDate,
  enterprise,
  enterpriseValue,
  foundation,
  items,
}: CashDocument.CashDocument) {
  return {
    ...(id ? { _attr: { id } } : {}),
    IsExpense: isExpense,
    ...getMaybeField('Number', number),
    ...getMaybeField('PayDate', payDate),
    ...(enterprise ? { Enterprise: { _attr: { id: enterprise.id } } } : {}),
    ...getMaybeField('EnterpriseValue', enterpriseValue),
    ...(foundation ? { Foundation: convertFoundationToRequest(foundation) } : {}),
    Items: { Item: items.map(convertItemToRequest) },
  };
}

function convertItemToRequest({ id, projectCode, amount, kindConsumption, purpose, note }: CashDocument.Item) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(projectCode ? { ProjectCode: { _attr: { id: projectCode.id } } } : {}),
    ...getMaybeField('Amount', amount),
    ...(kindConsumption ? { KindConsumption: { _attr: { id: kindConsumption.id } } } : {}),
    ...getMaybeField('Purpose', purpose),
    ...getMaybeField('Note', note),
  };
}

function convertFoundationToRequest({ type, number, date, startDate, endDate }: CashDocument.Foundation) {
  return {
    ...getMaybeField('Type', type),
    ...getMaybeField('Number', number),
    ...getMaybeField('Date', date),
    ...getMaybeField('StartDate', startDate),
    ...getMaybeField('EndDate', endDate),
  };
}

export function PartnerHelp(): JSX.Element {
  return (
    <>
      <ol>
        <li>
          Организация – партнёр закрепляется за ответственным менеджером и ответственным подразделением.{' '}
          <strong>Ответственный менеджер</strong> отвечает за полноту и актуальность информации об организации. По запросу
          предоставляет информацию о наличии и сути соглашений, результатам сотрудничества.
        </li>
        <li>
          Поскольку список организаций неизбежно содержит дубликаты организации (причины могут быть разные: смена названия в
          жизненном цикле организации; неполное название организации или ошибки пользователя при добавлении организации в
          справочник и др.), на вкладке <strong>Варианты наименования</strong> необходимо указать все дубликаты организации.
        </li>
        <li>
          Связь с филиалами и дочерними организациями указывать на вкладке <strong>Связи с другими организациями</strong>.
        </li>
        <li>
          <strong>Краткое название:</strong> для иностранных организаций вводите в формате: Сокращённое название организации на
          оригинальном языке (Сокращённое название на русском языке).
        </li>
        <li>
          <strong>Полное название:</strong> для международных организаций вводите в формате: Полное название организации на
          оригинальном языке (Полное название на русском языке).
        </li>
        <li>
          <strong>Сектор деятельности:</strong> Выбор значения из справочника. В справочнике в столбце Примечание даны
          рекомендации пор выбору сектора деятельности организации.
        </li>
        <li>
          <strong>Коды организации:</strong> минимально- необходимое для российских кампаний и организаций – ИНН и КПП.
        </li>
        <li>
          <strong>Адрес и контакты:</strong> минимально необходимые — страна, город, сайт.
        </li>
        <li>
          <strong>Идентификаторы организации в базах цитирования публикаций:</strong> в публикациях авторы указывают
          аффилированные организации, рекомендуется внести идентификаторы организации в РИНЦ, Scopus, Web of Science.
        </li>
        <li>
          <strong>Типы партнёрства:</strong> Выберите один или несколько вариантов сотрудничества. Сведения о договоре/соглашении
          не обязательно вносить в этом пункте. Скан копию договора необходимо прикрепить и описание к нему – на вкладке Документы
        </li>
      </ol>

      <h4>Сведения о результатах сотрудничества университета и организации представлены в виде списков на вкладках:</h4>

      <ol>
        <li>
          <strong>Вкладка «НИОКР»:</strong> список научных проектов, в которых организация является заказчиком или соисполнителем,
          или технологическим партнёром. Источник информации — разделах <strong>Заказчики и Партнёры</strong> на форме
          редактирования научного проекта (модуль <strong>Проекты</strong>
          ).
        </li>
        <li>
          <strong>Вкладка «Договоры на использование ИС»: список</strong> договоров на использование интеллектуального права на
          объекты интеллектуальной собственности университета Источник информации — функциональный модуль Коммерциализация.
        </li>
        <li>
          <strong>Вкладка «Публикации»:</strong> список публикаций, в которых авторы аффилированы с организацией Источник
          информации — раздел <strong>Сведения об авторах</strong> на форме публикации (модуль <strong>Публикации</strong>
          ).
        </li>
        <li>
          <strong>Вкладка «Мероприятия»:</strong> список организованных совместно с университетом мероприятий и мероприятий
          {/* eslint-disable-next-line max-len */}
          организации, в которых приняли участие сотрудники, аспиранты, студенты университета. Источник информации — раздел{' '}
          <strong>Организаторы мероприятия</strong> на форме редактирования мероприятия (конференции, конкурса, олимпиады),
          функционального модуля <strong>Мероприятия</strong>.
        </li>
        <li>
          <strong>Вкладка «Участие в выставках»:</strong> список участий университета в выставках с экспонатами, разработанными
          совместно с организацией. Источник информации — участия в выставке, где указан формат участия{' '}
          <strong>«Презентация разработки»</strong>, в разделе <strong>Организации-партнёры</strong> на форме{' '}
          <strong>Разработка</strong> (функциональный модуль <strong>Мероприятия, Участия в выставке</strong>)
        </li>
        <li>
          <strong>Вкладка «Стажировки»:</strong> список сотрудников, аспирантов и студентов университета, прошедших стажировку в
          организации. Источник данных — <strong>Принимающая сторона</strong> на форме{' '}
          <strong>Заявки по академической мобильности</strong>.
        </li>
      </ol>

      <div className="warning">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextInput, TextDateTime, TextInputMode } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  hirshIndex: Person.HirshIndex | null;
  onChange(hirshIndex: Person.HirshIndex): void;
};

function Fields(props: Props) {
  const { hirshIndex, onChange } = props;

  const makeChangeHandler = (fieldName: keyof Person.HirshIndex) => (value: ValueOf<Person.HirshIndex>) => {
    onChange({ ...hirshIndex!, [fieldName]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Система цитирования" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={hirshIndex?.citationSystem}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('citationSystem')(option)}
            settings={{ name: 'RefCitationSystem', title: 'Справочник "Системы цитирования"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата расчета" isRequired>
          <TextDateTime value={hirshIndex?.date} onChange={date => makeChangeHandler('date')(date ?? '')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Значение">
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ decimal: false }}
            value={hirshIndex?.value ?? ''}
            onChange={makeChangeHandler('value')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={hirshIndex?.note ?? ''} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { Fields };

import React, { useCallback } from 'react';
import { block } from 'bem-cn';

import { TextInput, TextInputMode } from 'components';
import { Months } from 'utils/Constants';

import { isFloatValid } from 'utils/Validators';
import { formatNumber } from 'utils/Helpers';
import { EmployeePayment } from 'types/models/Payment';
import { calcRemainderAmount } from 'features/Table/specifications/GetRequestFundList/helpers';

import './style.scss';

const b = block('request-fund-base-info');

type Props = {
  employeePayment: EmployeePayment;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  disabled: boolean;
};

type InfoProps = {
  label: string;
  value?: string | number;
  children?: JSX.Element;
};

function Block({ label, value, children }: InfoProps) {
  return (
    <div className={b('info')}>
      <div className={b('label')}>{label}</div>
      <div className={b('value')}>{children || value}</div>
    </div>
  );
}

export function BaseInfo(props: Props) {
  const { employeePayment, setEmployeePayment, disabled } = props;

  const onChangeAmount = useCallback(
    (amount: string) => {
      if (isFloatValid(amount)) {
        setEmployeePayment({ ...employeePayment!, amountMoney: amount });
      }
    },
    [employeePayment, setEmployeePayment],
  );

  return (
    <div className={b()}>
      <div className={b('line')}>
        <Block label="Основание:" value={employeePayment?.fund?.type.label ?? ''} />
      </div>
      <div className={b('line')}>
        <Block label="Месяц:" value={Months[employeePayment.fund?.month ?? -1] || ''} />
        <Block label="Квартал:" value={employeePayment.fund?.quarter.label ?? ''} />
        <Block label="Год:" value={employeePayment.fund?.year ?? ''} />
      </div>
      <div className={b('line')}>
        <Block label="Сумма Фонда подразделения, руб:">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={employeePayment.amountMoney}
            onChange={onChangeAmount}
            isDisabled={disabled}
          />
        </Block>
        <Block label="Из них не распределено, руб:" value={formatNumber(calcRemainderAmount(employeePayment), 0)} />
      </div>
    </div>
  );
}

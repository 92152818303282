import React, { useCallback, useState, useEffect } from 'react';

import {
  Select,
  SelectMode,
  FormComponent,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  TextDateTime,
  Checkbox,
  UploadFile,
} from 'components';

import { Document, FileInfo, Person, ReferenceItem, Table as T, Table } from 'types/models';
import { Item } from 'types/models/common';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetProgramList, GetScientistJobs } from 'features/Table/specifications';
import { DocumentProject } from 'types/models/Document';
import { FieldsType } from '../index';
import { ValueOf } from 'types/helpers';
import { documentTypeEnum } from '../helpers';

type Props = {
  document: Document;
  setDocument(doc: Document | null): void;
  isCanIsSharedEdit: boolean;
  isCommonAward: boolean;
  isCanIsInfoEdit: boolean;
  sourceLabelForLocalDocs?: string;
  sharedLabel?: string;
  fieldsType: FieldsType | null;
  isShowSource: boolean;
  isInProgram: boolean;
  setIsInProgram(e: boolean): void;
  tooltip?: string;
};

export function Fields(props: Props) {
  const {
    document,
    sourceLabelForLocalDocs,
    fieldsType,
    isCanIsInfoEdit,
    isCanIsSharedEdit,
    isCommonAward,
    sharedLabel,
    isShowSource,
    setDocument,
    isInProgram,
    setIsInProgram,
    tooltip,
  } = props;

  const [person, setPerson] = useState<Person.ScientistPerson | null>(null);

  const changeDocument = useCallback(
    (key: keyof Document, value: ValueOf<Document>) => {
      setDocument({ ...document, [key]: value });
    },
    [setDocument, document],
  );

  const handleChangeFile = useCallback(
    (fileInfo: FileInfo) => {
      setDocument({ ...document, file: fileInfo });
    },
    [setDocument, document],
  );

  const changePartnerDocumentType = useCallback(
    (option: Item | null) => {
      changeDocument('partnerDocumentType', option?.value ?? null);
    },
    [changeDocument],
  );

  const changeDocumentType = useCallback(
    (option: Item | null) => {
      changeDocument('documentType', option?.value ?? null);
    },
    [changeDocument],
  );

  const changeProjectDocumentType = useCallback(
    (option: ReferenceItem | null) => {
      setDocument({
        ...document,
        projectDocumentType: option ?? null,
        ...(option?.label
          ? {
              name: option?.label,
            }
          : {}),
      });
    },
    [document, setDocument],
  );

  const onChangePerson = useCallback(
    (member: Member) => {
      setDocument({
        ...document,
        responsible: member.person?.id || '',
        responsibleFio: member.person?.fullName || '',
        ...(person ? { responsibleJobAppointment: '', responsibleJob: '' } : {}),
      });
      setPerson(member.person);
    },
    [document, person, setDocument],
  );

  const handleIsInProjectChange = useCallback(
    (e: boolean) => {
      setIsInProgram(e);
      if (e) setDocument({ ...document, startDate: '', endDate: '' });
      if (!e) setDocument({ ...document, program: null });
    },
    [document, setDocument, setIsInProgram],
  );

  const handleProgramChange = useCallback(
    (program: DocumentProject | null) => {
      const value = program?.id ? program : null;
      setDocument({
        ...document,
        program: value,
        startDate: value?.startDate || '',
        endDate: value?.endDate || '',
      });
    },
    [document, setDocument],
  );

  const tableRowConverter = useCallback(
    (row: T.Entry): DocumentProject => ({
      id: row.id,
      label: row.name,
      startDate: row.StartDate,
      endDate: row.EndDate,
    }),
    [],
  );

  useEffect(() => {
    setIsInProgram(!!document.program?.id);
    // eslint-disable-next-line
  }, []);

  const renderTitleField = useCallback(
    () => (
      <FormComponent.Field label="Название документа" tooltip={tooltip} isRequired>
        <TextInput value={document?.name ?? ''} onChange={val => changeDocument('name', val)} />
      </FormComponent.Field>
    ),
    [changeDocument, document?.name, tooltip],
  );

  const renderDateField = useCallback(
    () => (
      <FormComponent.Field label="Дата документа" isRequired>
        <TextDateTime value={document?.date} onChange={date => changeDocument('date', date)} />
      </FormComponent.Field>
    ),
    [changeDocument, document?.date],
  );

  const renderFileField = useCallback(
    () => (
      <FormComponent.Field label="Файл" isRequired>
        <UploadFile file={document?.file} onChange={handleChangeFile} />
      </FormComponent.Field>
    ),
    [document?.file, handleChangeFile],
  );

  const renderDefaultFields = useCallback(
    () => (
      <>
        <FormComponent.Line>{renderFileField()}</FormComponent.Line>
        <FormComponent.Line>
          {renderTitleField()}
          {renderDateField()}
        </FormComponent.Line>
      </>
    ),
    [renderDateField, renderFileField, renderTitleField],
  );

  const renderDetailsFields = useCallback(
    () => (
      <>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="Вид документа">
            <Select
              mode={SelectMode.ENUM}
              value={{ value: document?.partnerDocumentType || '', label: '' }}
              settings={{ name: 'PartnerDocumentType' }}
              onChange={changePartnerDocumentType}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Номер документа">
            <TextInput value={document?.number ?? ''} onChange={val => changeDocument('number', val)} />
          </FormComponent.Field>
          {renderDateField()}
        </FormComponent.Line>
        <FormComponent.Line>{renderTitleField()}</FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field label="В рамках программы">
            <Checkbox checked={isInProgram} onChange={handleIsInProjectChange} />
          </FormComponent.Field>
          <FormComponent.Field label="Название программы" isRequired={isInProgram}>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Партнёрские программы',
                table: {
                  specification: GetProgramList({ withoutMenu: true }),
                  onSelect: (row: Table.Entry | null) => handleProgramChange(row ? tableRowConverter(row) : null),
                },
              }}
              value={document.program?.label}
              isDisabled={!isInProgram}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Период действия">
            <span>
              <TextDateTime
                value={document?.startDate || ''}
                onChange={(value: string) => changeDocument('startDate', value)}
                maxValue={document?.endDate || ''}
                isDisabled={isInProgram}
              />
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span>
              <TextDateTime
                value={document?.endDate || ''}
                onChange={(value: string) => changeDocument('endDate', value)}
                minValue={document?.startDate || ''}
                isDisabled={isInProgram}
              />
            </span>
          </FormComponent.Field>
          <FormComponent.Field label="">
            <FormComponent.Line hasFreeFormat>
              <Checkbox
                label="Бессрочный"
                checked={!!document?.isUnexpirable}
                onChange={val => changeDocument('isUnexpirable', val)}
              />
              <Checkbox
                label="Автоматическое продление"
                checked={!!document?.isAutoProlongation}
                onChange={val => changeDocument('isAutoProlongation', val)}
              />
            </FormComponent.Line>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>{renderFileField()}</FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ответственное лицо">
            <SelectPerson
              member={getMemberWithoutHistory(person)}
              personId={document?.responsible ?? null}
              onUpdateMember={onChangePerson}
              withHistory={false}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Должность">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Должности',
                table: {
                  specification: GetScientistJobs({ personId: person?.id ?? '', hasSelectButton: true }),
                  onSelect: (row: Table.Entry | null) =>
                    setDocument({
                      ...document,
                      responsibleJobAppointment: row?.appointment || '',
                      responsibleJob: row?.id || '',
                    }),
                },
              }}
              value={document?.responsibleJobAppointment || ''}
              isDisabled={person === null}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    ),
    [
      changeDocument,
      changePartnerDocumentType,
      document,
      handleIsInProjectChange,
      handleProgramChange,
      isInProgram,
      onChangePerson,
      person,
      renderDateField,
      renderFileField,
      renderTitleField,
      setDocument,
      tableRowConverter,
    ],
  );

  const renderDocumentTypeField = useCallback(
    () => (
      <FormComponent.Field label="Тип документа" isRequired>
        <Select
          mode={SelectMode.ENUM}
          value={{ value: document?.documentType || '', label: '' }}
          onChange={changeDocumentType}
          settings={{ name: documentTypeEnum[fieldsType!] }}
        />
      </FormComponent.Field>
    ),
    [changeDocumentType, document?.documentType, fieldsType],
  );

  const renderProjectDocumentTypeField = useCallback(
    () => (
      <FormComponent.Field label="Тип документа" isRequired>
        <Select
          mode={SelectMode.REFERENCE}
          value={document?.projectDocumentType || null}
          onChange={changeProjectDocumentType}
          settings={{ name: 'RefProjectDocumentType', title: 'Справочник "Типы документов проекта"' }}
        />
      </FormComponent.Field>
    ),
    [changeProjectDocumentType, document?.projectDocumentType],
  );

  const renderDetailsField = useCallback(
    () => (
      <FormComponent.Field label="Подробности" isRequired={!!document?.projectDocumentType?.customFields?.note}>
        {document?.projectDocumentType?.customFields?.note && (
          <FormComponent.Description mode="warning" classMixin="is-hint">
            <span>{document?.projectDocumentType?.customFields?.note as string}</span>
          </FormComponent.Description>
        )}
        <TextArea
          settings={{
            rows: 3,
            maxLength: 3000,
            isClearable: true,
          }}
          value={document?.detail || ''}
          onChange={val => changeDocument('detail', val)}
        />
      </FormComponent.Field>
    ),
    [changeDocument, document?.detail, document?.projectDocumentType?.customFields?.note],
  );

  const renderAmountField = useCallback(
    () => (
      <FormComponent.Field label="Сумма документа">
        <TextInput mode={TextInputMode.NUMBER} value={document?.amount ?? ''} onChange={val => changeDocument('amount', val)} />
      </FormComponent.Field>
    ),
    [changeDocument, document?.amount],
  );

  const renderNumberField = useCallback(
    () => (
      <FormComponent.Field label="Номер">
        <TextInput value={document?.number} onChange={val => changeDocument('number', val)} />
      </FormComponent.Field>
    ),
    [changeDocument, document?.number],
  );

  const renderNoteField = useCallback(
    () => (
      <FormComponent.Field label="Примечание">
        <TextInput value={document?.note} onChange={val => changeDocument('note', val)} />
      </FormComponent.Field>
    ),
    [changeDocument, document?.note],
  );

  return (
    <FormComponent.Wrapper>
      {isShowSource && (
        <FormComponent.Field label="Источник">
          <span>{(document.docType === 'local' && sourceLabelForLocalDocs) || document.type}</span>
        </FormComponent.Field>
      )}
      {fieldsType === null && renderDefaultFields()}
      {fieldsType === 'details' && renderDetailsFields()}
      {fieldsType === 'projectDocument' && (
        <>
          {renderProjectDocumentTypeField()}
          {renderDefaultFields()}
          {renderDetailsField()}
          {renderAmountField()}
        </>
      )}
      {fieldsType === 'purchaseRequest' && (
        <>
          {renderDefaultFields()}
          {renderDocumentTypeField()}
        </>
      )}
      {['securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(fieldsType || '') && (
        <>
          {renderDefaultFields()}
          {renderDocumentTypeField()}
          {renderNumberField()}
          {renderAmountField()}
          {renderNoteField()}
        </>
      )}
      {isCanIsSharedEdit && (
        <FormComponent.Field label="">
          <Checkbox
            label={sharedLabel ?? 'Отображать на форме редактирования участия'}
            checked={!!document?.isShared}
            onChange={val => changeDocument('isShared', val)}
          />
        </FormComponent.Field>
      )}
      {isCommonAward && (
        <FormComponent.Field label="">
          <Checkbox
            label="Награда университета (отображать в том числе на персональных страницах участников мероприятия)"
            checked={!!document?.isCommonAward}
            onChange={val => changeDocument('isCommonAward', val)}
          />
        </FormComponent.Field>
      )}
      {isCanIsInfoEdit && (
        <FormComponent.Field label="">
          <Checkbox label="Разместить на сайте" checked={!!document?.isInfo} onChange={val => changeDocument('isInfo', val)} />
        </FormComponent.Field>
      )}
    </FormComponent.Wrapper>
  );
}

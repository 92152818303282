import React from 'react';
import * as modalTemplates from '../modalTemplates';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { PersonalDissertationManagementHelp } from './help';

type Props = {
  tableState: Table.State;
};

function Toolbar({ tableState }: Props) {
  const { buttons, isViewFormOpen, handleTemplateCloseViewForm, isHelpFormOpen, handleTemplateCloseHelpForm } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Помощь в работе с разделом Руководство защитой"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PersonalDissertationManagementHelp()}
      </Modal>

      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
    </>
  );
}

export { Toolbar };

import React, { useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, Select, TextArea, TextAreaMode, TextDateTime, TextInput, TextInputMode } from 'components';

import { Project, Table } from 'types/models';
import { GetEquipList } from 'features/Table/specifications';
import { Item } from 'types/models/common';
import { getStageLabel } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  equip: Project.Equip | null;
  setEquip: React.Dispatch<React.SetStateAction<Project.Equip | null>>;
  project: Project.Project;
};

export const EquipFields = ({ equip, setEquip, project }: Props) => {
  const { methods: getEquip } = BackendAPI.useBackendAPI('GetEquip');

  function castStage(stage: Project.Stage) {
    return { ...stage, value: stage.number, label: getStageLabel(stage) } as Item;
  }

  const handleStageChange = useCallback(
    (e: Item | null) => {
      const newStage = (e as unknown) as Project.Stage;
      setEquip(prev => ({
        ...prev!,
        stage: newStage,
        startDate: newStage.startDate,
        endDate: newStage.endDate,
      }));
    },
    [setEquip],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Оборудование" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            value={equip?.equip?.name}
            settings={{
              title: 'Список оборудования',
              table: {
                specification: GetEquipList({ mode: 'equip' }),
                onSelect: (e: Table.Entry | null) => {
                  if (!e) {
                    setEquip(prev => ({ ...prev!, equip: null }));
                    return;
                  }
                  getEquip.callAPI(
                    { id: e.id },
                    { onSuccessfullCall: ({ data }) => setEquip(prev => ({ ...prev!, equip: data })) },
                  );
                },
              },
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Этап проекта" isRequired>
              <Select
                value={equip?.stage ? castStage(equip.stage) : null}
                onChange={(e: Item | null) => handleStageChange(e)}
                options={project.stages.map(castStage)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Начало использования">
              <TextDateTime
                value={equip?.startDate}
                onChange={e => setEquip(prev => ({ ...prev!, startDate: e }))}
                minValue={equip?.stage?.startDate}
                maxValue={equip?.stage?.endDate}
              />
            </FormComponent.Field>

            <FormComponent.Field label="Окончание использования">
              <TextDateTime
                value={equip?.endDate}
                onChange={e => setEquip(prev => ({ ...prev!, endDate: e }))}
                minValue={equip?.stage?.startDate}
                maxValue={equip?.stage?.endDate}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Количество дней">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={equip?.dayCount}
            onChange={e => setEquip(prev => ({ ...prev!, dayCount: e }))}
          />
        </FormComponent.Field>

        <FormComponent.Field label="Количество часов">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={equip?.hourCount}
            onChange={e => setEquip(prev => ({ ...prev!, hourCount: e }))}
          />
        </FormComponent.Field>

        <FormComponent.Field label="Количество человек">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={equip?.personCount}
            onChange={e => setEquip(prev => ({ ...prev!, personCount: e }))}
          />
        </FormComponent.Field>

        <FormComponent.Field label="из них до 39 лет">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={equip?.youngCount}
            onChange={e => setEquip(prev => ({ ...prev!, youngCount: e }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea value={equip?.note} onChange={e => setEquip(prev => ({ ...prev!, note: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};

import React, { useEffect } from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonMode, FormComponent, TextDateTime, TextInput, TextInputMode } from 'components';

import { transliterate } from 'utils/Helpers/transliaterate';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('auth__form__content');

function Register() {
  const {
    writeLogin,
    loginChanged,
    firstname,
    setFirstname,
    secondname,
    setSecondname,
    surname,
    setSurname,
    login,
    handleRegisterSubmit,
    textError,
    fieldErrors,
    setLogin,
    email,
    setEmail,
    birthDate,
    setBirthDate,
    password,
    setPassword,
    passwordRepeat,
    setPasswordRepeat,
    updateErrorField,
    passwordType,
    setPasswordType,
    passwordRepeatType,
    setPasswordRepeatType,
  } = useController();

  useEffect(() => {
    if (!loginChanged) {
      const loginRenerated = transliterate((firstname[0] || '') + (secondname[0] || '') + surname).toLowerCase();
      setLogin(loginRenerated);
      updateErrorField({ login: loginRenerated });
    }
  }, [surname, firstname, secondname, loginChanged, setLogin, updateErrorField]);

  const { settings } = useAppDataContext();

  return (
    <div className={b()}>
      <FormComponent.Line>
        <div className={b('offset').toString()}>
          <strong className="primary">Внимание! </strong>
          <span>
            Регистрация в системе доступна только для сотрудников, студентов и аспирантов
            {settings?.organization?.shortName}
          </span>
        </div>
      </FormComponent.Line>
      <FormComponent.Line>
        <div className={b('offset').toString()}>
          <strong className="primary">Внимание! </strong>
          <span>
            Для восстановления пароля от учетной записи, необходимо{' '}
            <FormComponent.Link href="mailto:t_korneeva@contek.ru" label="Написать в техподдержку" classMixin="footer-right" />
          </span>
        </div>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Фамилия">
          <TextInput
            id="surname"
            value={surname}
            onChange={(value: string) => {
              setSurname(value);
              updateErrorField({ surname: value });
            }}
            placeholder="Введите фамилию"
            isError={!!fieldErrors.surname}
          />
          <div className="bubble-error">{fieldErrors.surname}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Имя">
          <TextInput
            id="firstname"
            value={firstname}
            onChange={(value: string) => {
              setFirstname(value);
              updateErrorField({ firstname: value });
            }}
            placeholder="Введите имя"
            isError={!!fieldErrors.firstname}
          />
          <div className="bubble-error">{fieldErrors.firstname}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Отчество">
          <TextInput
            id="secondname"
            value={secondname}
            onChange={(value: string) => {
              setSecondname(value);
              updateErrorField({ secondname: value });
            }}
            placeholder="Введите отчество"
            isError={!!fieldErrors.secondname}
          />
          <div className="bubble-error">{fieldErrors.secondname}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Логин">
          <TextInput
            id="login"
            value={login}
            onChange={(value: string) => {
              writeLogin(value);
              updateErrorField({ login: value });
            }}
            placeholder="Генерируется автоматически. Можно изменить"
            isError={!!fieldErrors.login}
          />
          <div className="bubble-error">{fieldErrors.login}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="Дата рождения">
          <TextDateTime
            value={birthDate}
            onChange={e => {
              setBirthDate(e);
              updateErrorField({ birthDate: e as string });
            }}
            isError={!!fieldErrors.birthDate}
          />
          <div className="bubble-error">{fieldErrors.birthDate}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Электронная почта">
          <TextInput
            id="email"
            value={email}
            onChange={(value: string) => {
              setEmail(value);
              updateErrorField({ email: value });
            }}
            placeholder="Введите электронную почту"
            isError={!!fieldErrors.email}
          />
          <div className="bubble-error">{fieldErrors.email}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Пароль">
          <TextInput
            id="password"
            mode={passwordType as TextInputMode}
            value={password}
            onChange={(value: string) => {
              setPassword(value);
              updateErrorField({ password: value });
            }}
            placeholder="Не менее 5 символов"
            isError={!!fieldErrors.password}
            externalButtons={[
              {
                icon: { type: 'eye', size: 26 },
                title: 'Показать пароль',
                onMouseEnter: () => setPasswordType(TextInputMode.TEXT),
                onMouseLeave: () => setPasswordType(TextInputMode.PASSWORD),
                onFocus: () => setPasswordType(TextInputMode.TEXT),
                onBlur: () => setPasswordType(TextInputMode.PASSWORD),
              },
            ]}
            classMixin="password-eye"
          />
          <div className="bubble-error">{fieldErrors.password}</div>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Повторите пароль">
          <TextInput
            id="passwordRepeat"
            mode={passwordRepeatType as TextInputMode}
            value={passwordRepeat}
            onChange={(value: string) => {
              setPasswordRepeat(value);
              updateErrorField({ passwordRepeat: value });
            }}
            placeholder="Не менее 5 символов"
            isError={!!fieldErrors.passwordRepeat}
            externalButtons={[
              {
                icon: { type: 'eye', size: 26 },
                title: 'Показать пароль',
                onMouseEnter: () => setPasswordRepeatType(TextInputMode.TEXT),
                onMouseLeave: () => setPasswordRepeatType(TextInputMode.PASSWORD),
                onFocus: () => setPasswordRepeatType(TextInputMode.TEXT),
                onBlur: () => setPasswordRepeatType(TextInputMode.PASSWORD),
              },
            ]}
            classMixin="password-eye"
          />
          <div className="bubble-error">{fieldErrors.passwordRepeat}</div>
        </FormComponent.Field>
      </FormComponent.Line>

      {!!textError && (
        <FormComponent.Line>
          <div className={b('error')}>{textError}</div>
        </FormComponent.Line>
      )}

      <FormComponent.Line>
        <Button mode={ButtonMode.PRIMARY} text="Войти в систему" onClick={handleRegisterSubmit} classMixin="button-auth" />
      </FormComponent.Line>
    </div>
  );
}

export const Component = React.memo(Register);

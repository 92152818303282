import React from 'react';

import { Select } from 'components';

import { options } from './helpers';
import useController from './controller';

import './style.scss';

export type Props = {
  setColumnFilter: (nextValue: string) => void;
  value: string;
};

export const BooleanFilter = ({ value, setColumnFilter }: Props) => {
  const { selectedValue, onFieldChange } = useController({ value, setColumnFilter });

  return (
    <Select
      value={selectedValue}
      options={options}
      settings={{ maxMenuHeight: 200 }}
      onChange={onFieldChange}
      placeholder=" "
      classMixin="grid-boolean-filter"
    />
  );
};

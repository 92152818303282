import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export function GetSalaryByFundCardList(fundCardId?: string): Table.Specification<State, { fundCardId?: string }> {
  return {
    apiID: 'GetSalaryByFundCardList',
    header: {
      firstLevel: {
        title: 'Оплата за счёт накладных расходов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ fundCardId }),
    requestData: { fundCardId: fundCardId },
    highlightRow: (row: Table.Entry) => (!row.FundCardId ? Color.danger : ''),
  };
}

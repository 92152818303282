import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

const NotificationEditTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, relatedTableState, id }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetNotification',
    template: 'NotificationForm',
    relatedTableState,
    setTitle,
    id,
    editMode: true,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="medium">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(NotificationEditTemplate);

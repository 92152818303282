import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function MagazineGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Журналы</h4>

        <p>
          Функциональный блок <strong>Журналы</strong> предназначен для ведения реестра журналов, в которых публиковались статьи;
          для поддержки отчетности Университета по публикациям в журналах сотрудников, студентов и аспирантов Университета.
        </p>
        <p>
          Для более удобной работы или поиска журнала, в столбце <strong>Вид издания</strong> можно включить фильтр и посмотреть
          список всех журналов только определенного одного типа издания.
        </p>

        <p>Вид издания:</p>
        <ul>
          <li>Зарубежные;</li>
          <li>Собственные издания;</li>
          <li>Издания других ВУЗов;</li>
          <li>Другие издания;</li>
          <li>Издания РАН.</li>
        </ul>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>
          Страница пункта меню <strong>Журналы</strong> содержит в табличном виде перечень всех журналов и препринтов, в которых
          публиковались сотрудники, студенты и аспиранты университета. Назначение функциональных элементов интерфейса представлено
          в таблице.
        </p>

        <div className="warning">
          <span>Набор элементов интерфейса может быть полным или частичным в зависимости от наличия прав у пользователя.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму просмотра журнала. Кнопка активна при выделении журнала в таблице</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму для добавления журнала</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования журнала. Кнопка активна при выделении журнала в таблице</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>Удаляет выделенный в таблице журнал</td>
            </tr>
            <tr>
              <td>
                Показатели журнала: <Icon type="statistic" />
              </td>
              <td>
                Открывает форму с информацией об индексировании журнала в базах цитирования и наукометрических показателях
                журнала.
              </td>
            </tr>
            <tr>
              <td>
                Информация о статьях: <Icon type="info" />
              </td>
              <td>
                Открывает форму со списком статей, опубликованных в журнале. В зависимости от прав пользователя функциональное
                меню для утверждения, просмотра и редактирования статьи, скачать файл или перейти по ссылке.
              </td>
            </tr>
            <tr>
              <td>
                Выпуски журнала: <Icon type="magazine" />
              </td>
              <td>
                Открывает форму со списком выпусков журнала. В зависимости от прав пользователя функциональное меню для
                утверждения, просмотра, добавления и редактирования выпуска.
              </td>
            </tr>
            <tr>
              <td>
                Утвердить: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения</td>
            </tr>
            <tr>
              <td>
                Идентификаторы журнала: <Icon type="badge" />
              </td>
              <td>Открывает форму для добавления и редактирования идентификаторов журнала (DOI)</td>
            </tr>
            <tr>
              <td>
                Перейти на страницу журнала по ссылке: <Icon type="link" />
              </td>
              <td>Открывает новое окно в браузере и сайт со страницей журнала или издательства</td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список журналов выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Функциональная кнопка открывает форму для добавления сообщения. Все сообщения хранятся в Системе, на странице
                <strong>Главная, Вопросы от пользователей администраторам</strong>. Список сообщений конкретного пользователя и
                ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
                <strong>Новости, Вопрос - ответ</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

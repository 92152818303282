import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSettings } from 'services/BackendAPI/configurations/settings/converters';

export const settingsConfigurations = {
  // <Request commandName="GetSettings">
  //   <Prefix>Notification</Prefix>
  // </Request>
  GetSettings: makeBackendAPIConfiguration({
    id: 'GetSettings',
    endpoint: '/msa/service/commands/GetSettings',
    convertInputToXMLElement: ({ prefix }: { prefix?: string }) => ({
      Request: {
        _attr: { commandName: 'GetSettings' },
        ...(!!prefix ? { Prefix: { _text: prefix } } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSettings(response.Response.Settings),
  }),

  // <Request commandName="SaveSettings">
  //   <Settings>
  //     <Setting name="Notification.Enabled" type="BOOLEAN">false</Setting>
  //   </Settings>
  // </Request>
  SetSettings: makeBackendAPIConfiguration({
    id: 'SetSetting',
    endpoint: '/msa/service/commands/SaveSettings',
    convertInputToXMLElement: ({ name, type, text }: { name: string; type: string; text: string }) => ({
      Request: {
        _attr: { commandName: 'SaveSettings' },
        Settings: { Setting: { _attr: { name, type }, _text: text } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const tableUserConfigurations = {
  ...settingsConfigurations,
};

import { PeriodableReferenceItem } from 'types/models/Partner';

export function validate(partnershipType: PeriodableReferenceItem | null) {
  if (!partnershipType) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  if (partnershipType.startDate && partnershipType.endDate && partnershipType.startDate > partnershipType.endDate)
    return [{ isValid: false, invalidMessage: 'Дата окончания не может быть меньше даты начала' }];

  const isValidRef = Boolean(partnershipType.ref && partnershipType.ref.id);

  return [{ isValid: isValidRef, invalidMessage: 'Выберите ОКВЭД' }];
}

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const tableStreams = useLocalTableStreams();

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState]);

  const { userPermission } = useAppDataContext();

  const isCreator = useMemo(() => {
    return !!(row !== null && row.IsCreator === 'true');
  }, [row]);

  const isDeleteButtonDisabled = useMemo(() => {
    if (row) {
      if (isCreator || isHasPermission(userPermission, Permits.QNA_ADMINISTRATE)) {
        return false;
      }
    }
    return true;
  }, [row, isCreator, userPermission]);

  const handleAddButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      setIsAddFormOpen(true);
    } else {
      showNotification({ message: 'Выберите один вопрос', theme: 'danger' });
    }
  }, [tableState]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);
  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteQnaQuestion',
      deletedItemPropName: 'QnaQuestion',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Ответить',
        onClick: handleAddButtonClick,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled: isDeleteButtonDisabled,
      },
    ],
    [handleAddButtonClick, handleDeleteButtonClick, isDeleteButtonDisabled],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.QnaAnswerAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>(тема: {tableState.selectedRows[0]?.Theme}) Удалить сообщение?</>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

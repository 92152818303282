import React from 'react';

import { Toolbar } from 'components';

import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';

function LeftPanelForThirdLevel() {
  const { buttons, isReportOpen, currentReport, onReportClose, settings } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import * as T from 'services/BackendAPI/configurations/types';

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

export const GetProgramKnowledgeBranchSelectList = (
  requestData: T.GetProgramKnowledgeBranchListRequest,
): Table.Specification => ({
  apiID: 'GetProgramKnowledgeBranchSelectList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
});

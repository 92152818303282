import React, { useCallback, useEffect } from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { EventTypes } from 'utils/Enums/EventTypes';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { DocumentsWarningPopup } from 'features/Form/looks/event/views/DocumentsWarningPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { EventHeader } from 'features/Form/views/ModalHeader';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { OrganizationComponent } from '../views/Organization';
import { EventTab } from 'utils/Permissions/EventTab';
import { RecordStatus } from 'utils/Enums';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const EventFormExposition = ({ viewMode, onClose }: Props) => {
  const {
    refs,
    changeCommitteMembers,
    changeDepartments,
    changeEnterprises,
    changeProjects,
    calculateFactMemberCount,
    changeMemberCounts,
    committeMembers,
    departments,
    documents,
    enterprises,
    eventData,
    eventId,
    formFields,
    withHeaderId,
    handleFormSubmit,
    isAuthorsPopupOpen,
    isDocumentsWarningPopupOpen,
    isLoading,
    isSelectedPeriod,
    isTitleWarningPopup,
    memberCounts,
    onCloseAuthorsPopup,
    onCloseDocumentsWarningPopup,
    onCloseTitleWarningPopup,
    onConfirmAuthorsPopup,
    onConfirmDocumentsWarningPopup,
    onConfirmTitleWarningPopup,
    onResetAuthorsPopup,
    participations,
    projects,
    relatedTableState,
    setDocuments,
    tabsId,
    termsList,
    titleWarningText,
    workMode,
    organizationName,
    userPermission,
    reloadEvent,
    sourceEvent,
  } = useController({
    onClose,
  });

  const {
    look: { id, setTitle },
  } = useFormContext();

  const isVisibleTab = useCallback(
    (tab: EventTab) => {
      if (eventData.status?.value === RecordStatus.APPROVED) {
        const permitBase: string = 'EXPOSITION_EDIT_APPROVED';
        return (
          isHasPermission(userPermission, Permits[`${permitBase}_ALL` as keyof Permits]) ||
          (!!eventData.isUserOwned &&
            isHasPermission(userPermission, Permits[`${permitBase}_MY_${tab.toUpperCase()}` as keyof Permits]))
        );
      }

      return true;
    },
    [eventData.isUserOwned, eventData.status?.value, userPermission],
  );

  const renderAboutInfo = (props?: { disabled: boolean }) => (
    <TabsContent.AboutComponent
      formFields={formFields}
      viewMode={viewMode || props?.disabled || false}
      termsList={termsList}
      isSelectedPeriod={isSelectedPeriod}
      departments={departments}
      changeDepartments={changeDepartments}
      enterprises={enterprises}
      changeEnterprises={changeEnterprises}
      refs={refs}
      relatedTableState={relatedTableState}
      withHeaderId={workMode !== 'addMode' ? withHeaderId : undefined}
    />
  );

  useEffect(() => {
    setTitle?.(
      EventHeader({
        workMode,
        departments,
        status: eventData.status?.label || '',
        eventId: id || '',
        eventTypeCode: EventTypes.EVENT_EXPOSITION.code,
      }),
    );
  }, [departments, eventData.status?.label, id, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <ControlPanel
          handleSave={() => handleFormSubmit(true)}
          {...(workMode === 'addMode' &&
          !isHasPermission(userPermission, [Permits.EXPOSITION_EDIT_ADDED_MY, Permits.EXPOSITION_EDIT_ADDED_ALL])
            ? {}
            : { handleSaveAndContinue: () => handleFormSubmit(false) })}
          viewMode={viewMode}
          isDisabled={isLoading}
        />

        <SuchLikeEvent
          mode="edit"
          handleCloseOuterForm={onClose}
          command="RefEventFeedlineMessage"
          eventTypeCode={EventTypes.EVENT_EXPOSITION.code}
          eventName={formFields.name.value as string}
          startDate={formFields.startDate.value as string}
          endDate={formFields.endDate.value as string}
        />

        <FormComponent.Template>
          <Tabs id={tabsId}>
            <Tab title="О мероприятии" isVisible={isVisibleTab(EventTab.ABOUT)} isDisabled={!!viewMode}>
              {renderAboutInfo()}
            </Tab>
            <Tab title="Организация мероприятия" isVisible={isVisibleTab(EventTab.ORGANIZATION)}>
              <OrganizationComponent
                calculateFactMemberCount={calculateFactMemberCount}
                changeCommitteMembers={changeCommitteMembers}
                changeMemberCounts={changeMemberCounts}
                committeMembers={committeMembers}
                formFields={formFields}
                memberCounts={memberCounts}
                viewMode={!!viewMode}
              />
            </Tab>
            <Tab title="Участники">
              <TabsContent.Participations
                sourceEvent={sourceEvent || eventData}
                workMode={workMode}
                reloadEvent={reloadEvent}
                isEventChanged={false} // TODO: need refactor inner structures to changing dispatch
                participations={participations}
              />
            </Tab>
            <Tab title="Финансовая поддержка" isVisible={isVisibleTab(EventTab.FINANCING)} isDisabled={!!viewMode}>
              <TabsContent.FinancialSupport
                projects={projects}
                setProjects={changeProjects}
                workMode={workMode}
                relatedTableState={relatedTableState}
              />
            </Tab>
            <Tab title="Документы">
              <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
            </Tab>
            <Tab title="Журнал сообщений">
              <TabsContent.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={eventId} />
            </Tab>
          </Tabs>
        </FormComponent.Template>

        <DocumentsWarningPopup
          isOpen={isDocumentsWarningPopupOpen}
          onConfirm={onConfirmDocumentsWarningPopup}
          onClose={onCloseDocumentsWarningPopup}
        />

        <Modal
          mode="warning"
          title="Внимание"
          isOpen={isTitleWarningPopup}
          onClose={onCloseTitleWarningPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да, верно',
              onClick: onConfirmTitleWarningPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Нет',
              onClick: onCloseTitleWarningPopup,
            },
          ]}
          size="small"
        >
          <FormComponent.Text>В наименовании обнаружены латинские символы</FormComponent.Text>
          <FormComponent.Text>
            <strong dangerouslySetInnerHTML={{ __html: titleWarningText }} />
          </FormComponent.Text>
          <FormComponent.Text>Вы уверены, что наименование верно?</FormComponent.Text>
        </Modal>
      </FormComponent.Template>

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={onCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: onConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: onResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <p>
          <strong>В списке «Мои выставки», отображаются только те выставки, в которых вы в составе оргкомитета.</strong>
        </p>
        <p>В списке «Выставки {organizationName}» — организованные Университетом</p>
        <p>В списке «Другие выставки» — организованные другими организациями</p>
        <p>Вашей персоны нет в списке участников оргкомитета. Добавить?</p>
      </Modal>
    </>
  );
};

export const Component = React.memo(EventFormExposition);

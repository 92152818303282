import { Icon } from 'components';

export function ThematicalSearchProjectStateRegistrationReportGridHelp(): JSX.Element {
  return (
    <>
      <h4>
        Раздел Архив отчётов по результатам научных исследований содержит информацию о научных отчётах выполненных НИОКР с 2006
        года.
      </h4>

      <p>
        Используйте строку поиска по словам в столбцах таблицы для поиска отчётов по тематике проекта, названию отчёта, ключевым
        словам, классификаторам (ГРНТИ,УДК и пр.)
      </p>

      <div>
        Список выбранных отчётов вы можете выгрузить в файл с помощью кнопки: <Icon type="excel" />
      </div>

      <p>С материалами отчётов вы можете ознакомиться в ОНТИ (отдел научно-технической информации)</p>
    </>
  );
}

import { Button, ButtonMode, Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjecRestrictionGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Форма на вкладке «Ограничения для проектов» содержит список ограничений всех проектов.</p>
        <p>
          Фильтр <strong>Год</strong> - отображает список записей, у которых сроки выполнения проекта пересекаются с указанным
          годом.
        </p>
        <p>
          Фильтр <strong>Все ограничения</strong> - показать все записи с ограничениями по проектам.
        </p>
        <p>
          Фильтры <strong>Все + Год</strong> - отображает список записей, у которых сроки выполнения проекта пересекаются с
          указанным годом.
        </p>
        <p>
          Фильтр <strong>Нарушены сегодня</strong> - отображает список записей, у которых значение в столбце{' '}
          <strong>Нарушено</strong> указана дата = Сегодня. Фильтр не зависит от года.
        </p>
        <p>
          Фильтр <strong>Риски нарушения ограничений</strong> - отображает список записей, у которых значение в столбце{' '}
          <strong>Нарушено</strong>
          указана дата.
        </p>
        <p>
          {/* eslint-disable-next-line max-len */}
          Фильтры <strong>Год + Риски нарушения ограничений</strong> - отображает список записей, у которых значение в столбце{' '}
          <strong>Нарушено</strong> указана дата и она пересекается с годом в фильтре.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы «Контроль выполнения ограничений для проектов»</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Тип проекта</td>
              <td>Тип проекта.</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта.</td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>Дата окончания проекта.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>Краткое название программы.</td>
            </tr>
            <tr>
              <td>Тема</td>
              <td>Тема проекта.</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО и e-mail руководителя проекта.</td>
            </tr>
            <tr>
              <td>Отв. исполнитель</td>
              <td>ФИО и e-mail ответственного исполнителя проекта.</td>
            </tr>
            <tr>
              <td>Наблюдатель</td>
              <td>Наблюдатель.</td>
            </tr>
            <tr>
              <td>Показатель</td>
              <td>Наименование показателя.</td>
            </tr>
            <tr>
              <td>Ограничение</td>
              <td>Значение ограничения.</td>
            </tr>
            <tr>
              <td>Значение сегодня</td>
              <td>Значение сегодня.</td>
            </tr>
            <tr>
              <td>Нарушено</td>
              <td>Дата нарушения ограничения. Алгоритмы вычисления нарушений ограничений описаны ниже.</td>
            </tr>
            <tr>
              <td>Примечание</td>
              <td>Примечание</td>
            </tr>
          </tbody>
        </table>
        <h4>Алгоритмы вычисления нарушений ограничений</h4>
        <ol>
          <li>
            <strong>Показатели по составу коллектива проекта</strong>

            <ol>
              <li>
                <strong>Показатель «Минимальное число членов НК»</strong>

                <p>Значение показателя задается в виде трехзначного целого натурального числа.</p>
                <ul>
                  <li>Текущее значение вычисляется по формуле: количество участников проекта на текущую дату.</li>
                  <li>
                    Дата нарушения ограничения – это дата, когда количество участников проекта стало МЕНЬШЕ значения показателя.
                  </li>
                  <li>
                    Примечание - выводится ФИО сотрудника, у которого дата окончания периода работы равна дате нарушения
                    ограничения. Выводится в формате: «Исключение из коллектива» &lt;ФИО&gt;.
                  </li>
                </ul>
                <p>Алгоритм проверки:</p>
                <ul>
                  <li>
                    вычисляется текущее количество участников проекта, сравнивается с ограничением, если нарушилось, то дата
                    нарушения = текущая дата;
                  </li>
                  <li>
                    происходит поиск членов коллектива, у которых дата окончания работы в проекте меньше, чем дата окончания
                    проекта – это потенциальные даты рисков;
                  </li>
                  <li>
                    последовательно на каждую дату риска вычисляется количество участников проекта, сравнивается с ограничением,
                    если нарушилось, то это - дата нарушения. Производится поиск первой Даты нарушения ограничения.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Максимальное число членов НК»</strong>

                <p>Значение показателя задается в виде трехзначного целого натурального числа.</p>
                <ul>
                  <li>Текущее значение вычисляется по формуле: количество участников проекта на текущую дату.</li>
                  <li>
                    Дата нарушения ограничения – это дата, когда количество участников проекта стало БОЛЬШЕ значения показателя.
                  </li>
                  <li>
                    Примечание - выводится ФИО сотрудника, у которого дата начала периода работы равна дате нарушения ограничения.
                    Выводится в формате: «Исключение из коллектива» &lt;ФИО&gt;.
                  </li>
                </ul>
                <p>Алгоритм проверки:</p>
                <ul>
                  <li>
                    вычисляется текущее количество участников проекта, сравнивается с ограничением, если нарушилось, то дата
                    нарушения = текущая дата;
                  </li>
                  <li>
                    происходит поиск членов коллектива, у которых дата начала работы в проекте больше чем дата начала проекта –
                    это потенциальные даты рисков;
                  </li>
                  <li>
                    последовательно на каждую дату риска вычисляется количество участников проекта, сравнивается с ограничением,
                    если нарушилось, то это - дата нарушения. Производится поиск первой Даты нарушения ограничения.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Минимальная доля членов НК до 39 лет»</strong>

                <p>Значение показателя задается в виде значения от 0 до 1, с точностью до сотых долей.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: количество участников проекта до 39 лет на текущую дату/количество
                    участников проекта на текущую дату. Возраст до 39 лет = Год (текущая дата – дата рождения - 1) &lt; 40.
                  </li>
                  <li>
                    Дата нарушения ограничения – это дата, когда доля участников проекта до 39 стала МЕНЬШЕ значения показателя.
                  </li>
                  <li>
                    Примечание - выводится ФИО сотрудников, у которого нарушено ограничение в формате:
                    <ul className="dashed">
                      <li>«40 лет» &lt;ФИО&gt;;</li>
                      <li>«Исключение из коллектива» &lt;ФИО&gt;;</li>
                    </ul>
                  </li>
                </ul>
                <p>Алгоритм проверки:</p>
                <ul>
                  <li>
                    вычисляется текущее количество участников проекта всего, из них до 39 лет; вычисляется значение параметра:
                    количество участников проекта до 39 лет на текущую дату/количество участников проекта на текущую дату;
                  </li>
                  <li>сравнивается с ограничением, если нарушилось, то дата нарушения= текущая дата;</li>
                  <li>
                    если ограничение не нарушено, то:
                    <ul className="dashed">
                      <li>
                        происходит поиск членов коллектива, которым 40 лет исполнится до окончания периода работы в проекте – это
                        потенциальные даты рисков;
                      </li>
                      <li>
                        или происходит поиск членов коллектива, у которых дата окончания периода работы в проекте МЕНЬШЕ, чем дата
                        окончания проекта - это потенциальные даты рисков;
                      </li>
                    </ul>
                  </li>
                  <li>
                    последовательно на каждую дату, начиная с меньшей даты риска, вычисляется значение параметра, сравнивается с
                    ограничением, если нарушилось, то это - дата нарушения. Происходит поиск первой Даты нарушения ограничения.
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Максимальный средний возраст членов НК»</strong>

                <p>Значение показателя задается в виде значения двухзначного натурального числа.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: по состоянию на текущую дату общий возраст членов коллектива
                    (количество полных лет по каждому участнику) / количество участников проекта.
                  </li>
                  <li>Дата нарушения ограничения – это дата, когда средний возраст становится БОЛЬШЕ значения показателя.</li>
                  <li>
                    <p>Примечание - выводить ФИО сотрудников, у которого нарушено ограничение в формате:</p>
                    <ul className="dashed">
                      <li>&lt;&gt; лет ФИО;</li>
                      <li>«Исключение из коллектива» &lt;ФИО&gt;;</li>
                      <li>«Ввод в коллектив» &lt;ФИО&gt;.</li>
                    </ul>
                  </li>
                </ul>
                <p>Алгоритм проверки:</p>
                <ul>
                  <li>вычисляется значение показателя на текущую дату;</li>
                  <li>сравнивается с ограничением, если нарушилось, то дата нарушения = текущая дата;</li>
                  <li>
                    <p>если ограничение не нарушено, то:</p>
                    <ul className="dashed">
                      <li>
                        Определяются потенциальные даты рисков – дата окончания периода работы членов коллектива МЕНЬШЕ чем дата
                        окончания проекта ИЛИ дата начала работы в коллективе БОЛЬШЕ, чем дата начала проекта. Рассчитываются
                        значения показателя последовательно, начиная с меньшей даты, сравниваются с ограничением, если нарушилось,
                        то дата нарушения = дата окончания работы / дата начала работы ФИО.
                      </li>
                      <li>
                        ИЛИ Определяются потенциальные даты рисков по возрасту - даты рождения участников коллектива. Рассчитать
                        значения показателя последовательно, начиная с меньшей даты, сравнить с ограничением, если нарушилось, то
                        дата нарушения – дата рождения ФИО
                      </li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <strong>Показатели по выплатам коллективу (по данным о выплатах заработной платы)</strong>

            <ol>
              <li>
                <strong>Показатель «Может ли руководитель проекта работать на дистанте (да/нет)»</strong>

                <p>Значение показателя задается в виде значения «Да» или «Нет».</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: по данным о зарплате смотреть на «Характер работы», если характер
                    работы не удовлетворяет ограничению, то дата нарушения ограничения равна последней дате месяца расчёта
                    заработной платы.
                  </li>
                  <li>
                    Примечание выводить в формате: «Расчётный месяц — &lt;название месяца расчёта, в котором нарушено ограничение
                    &gt;».
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Максимальное число дней отпуска руководителя проекта»</strong>

                <p>Значение показателя задается в виде двухзначного натурального числа.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: суммарное число дней отпуска руководителя проекта, начиная с начала
                    проекта до текущей даты - по данным о зарплате.
                  </li>
                  <li>
                    Дата нарушения ограничения равна последней дате месяца расчёта заработной платы, т.е. &lt;Суммарное число дней
                    отпуска руководителя проекта, начиная с начала проекта до текущей даты&gt; БОЛЬШЕ значения показателя.
                    Выводится дата, равная последней дате месяца расчёта заработной платы.
                  </li>
                  <li>
                    Примечание выводится в формате: «Расчётный месяц — &lt;название месяца расчёта, в котором нарушено
                    ограничение&gt;».
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Максимальное число дней отпуска руководителя проекта без сохранения заработной платы»</strong>

                <p>Значение показателя задается в виде двухзначного натурального числа.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: суммарное число дней отпуска БЕЗ сохранения заработной платы у
                    руководителя проекта, начиная с начала проекта до текущей даты - по данным о зарплате.
                  </li>
                  <li>
                    Дата нарушения ограничения - Дата нарушения ограничения, т.е. &lt;суммарное число дней отпуска БЕЗ сохранения
                    заработной платы у руководителя проекта, начиная с начала проекта до текущей даты&gt; БОЛЬШЕ значения
                    показателя. Выводится дата, равная последней дате месяца расчёта заработной платы.
                  </li>
                  <li>
                    Примечание выводится в формате: «Расчётный месяц — &lt;название месяца расчёта, в котором нарушено
                    ограничение&gt;».
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Минимальная доля средств, получаемых членами НК до 39 лет»</strong>

                <p>Значение показателя задается в виде значения от 0 до 1, с точностью до сотых долей.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется ежемесячно по формуле: &lbrace;Общая сумма (Начислено по шифру + Начислено по
                    шифру накладных расходов, связанным с шифром) для членов коллектива, у которых возраст в месяц расчёта МЕНЬШЕ
                    40 полных лет / Общая сумма начислено для всех членов коллектива.
                  </li>
                  <li>Дата нарушения ограничения равна последней дате месяца расчёта заработной платы.</li>
                  <li>
                    Примечание выводится в формате: «Расчётный месяц — &lt;название месяца расчёта, в котором нарушено
                    ограничение&gt;».
                  </li>
                </ul>
              </li>
              <li>
                <strong>
                  Показатель «Максимальная доля средств, полученных одним членом НК в общем объеме выплат членам НК»
                </strong>

                <p>Значение показателя задается в виде значения от 0 до 1, с точностью до сотых долей.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: вычисляется доля каждого члена коллектива и сравнивается со значением
                    показателя (БОЛЬШЕ, чем значение показателя).
                  </li>
                  <li>Дата нарушения ограничения равна последней дате месяца расчёта заработной платы.</li>
                  <li>
                    Примечание выводить в формате: «Расчётный месяц — &lt;название месяца расчёта, в котором нарушено
                    ограничение&gt;».
                  </li>
                </ul>
              </li>
            </ol>
          </li>
          <li>
            <strong>Показатели по смете и шифру проекта</strong>

            <ol>
              <li>
                <strong>Показатель «Допускается наличие остатка на конец календарного года (да/нет)»</strong>

                <p>Значение показателя задается в виде значения «Да» или «Нет».</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: Сумма_план в календарном плане проекта — Сумма по расходным
                    документам.
                  </li>
                  <li>Дата нарушения ограничения – дата, при которой текущее значение &gt; 0 И ограничение = «Нет».</li>
                  <li>
                    Примечание выводится в формате: «Остаток: &lt;Сумма_ план в календарном плане проекта — Сумма по расходным
                    документам&gt;».
                  </li>
                </ul>
              </li>
              <li>
                <strong>Показатель «Максимальный процент отклонения от плана расходования средств»</strong>

                <p>Значение показателя задается в виде двухзначного числа с точностью до сотых долей.</p>
                <ul>
                  <li>
                    Текущее значение вычисляется по формуле: Сумма_план в календарном плане проекта — Сумма расходных документов /
                    Сумма_план в календарном плане проекта * 100.
                  </li>
                  <li>Дата нарушения ограничения – дата, при которой текущее значение &gt; 0 И ограничение = «Нет».</li>
                  <li>
                    Примечание выводить в формате: «Отклонение от плана:» &lt;Сумма_ план в календарном плане проекта — Сумма по
                    расходных документов / Сумма_ план в календарном плане проекта * 100&gt; «%».
                  </li>
                </ul>
              </li>
            </ol>
          </li>
        </ol>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Выполнить расчет: <Button mode={ButtonMode.PRIMARY} text="Выполнить расчёт" />
              </td>
              <td>
                При нажатии на кнопку появляется модальное окно с заголовком «Предупреждение», в котором система запрашивает
                согласие пользователя на расчет нарушений по ограничениям проекта.
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { Event, Report, Table } from 'types/models';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { Participations, TypeControllerIsForm } from 'types/models/Event';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { getEventPermitList, isEventPermitted } from 'utils/Helpers/event';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const { settings, userPermission } = useAppDataContext();
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();
  const tableStreams = useLocalTableStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);

  const selectedRowsLength = tableState.selectedRows.length;

  const reports = useMemo<Report[]>(
    () => [
      Reports.DepartmentNTRExpositionsAwards,
      Reports.DepartmentExpositionParticipation,
      Reports.AllExpositionsAwards,
      Reports.DepartmentExpositionConsolidatedList,
      Reports.ExhibitionReport,
      Reports.DepartmentExposition,
      Reports.TotalDepartmentReportAll,
      Reports.MonitoringByEvents,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [sourceEvent, setSourceEvent] = useState<Event.Event | null>(null);
  const [participation, setParticipation] = useState<Participations | null>(null);

  const [isCancelMessagePopupOpen, setIsCancelMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });

  const { methods: deleteEventAPI } = BackendAPI.useBackendAPI('DeleteEvent', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись удалена', theme: 'success' });
      handleCloseDeleteConfirmPopup();
      tableStreams.reloadTable.push({});
    },
  });

  const controllerIsForm: TypeControllerIsForm = {
    sourceEvent,
    participation,
    isView: isViewParticipationFormOpen,
    isEdit: isEditParticipationFormOpen,
    isAdd: isAddParticipationFormOpen,
    isDelete: isDeletePopup,
    setParticipation,
    setAdd: setIsAddParticipationFormOpen,
    setEdit: setIsEditParticipationFormOpen,
    setView: setIsViewParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setSourceEvent,
  };

  const handleCloseApprovePopup = useCallback(() => {
    setIsCancelMessagePopupOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteEventAPI.callAPI({ eventId: tableState.selectedRows[0].id });
  }, [deleteEventAPI, tableState.selectedRows]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleApproveButtonClick = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const { methods: addNewMessage } = BackendAPI.useBackendAPI('SaveEventUserFeedline');

  const onCloseMessageConfirm = useCallback(() => {
    setIsCancelMessagePopupOpen(false);
    setIsApprovePopupOpen(false);
    tableStreams.reloadTable.push({});
  }, [tableStreams]);

  const onCancelMessageConfirmClick = useCallback(() => {
    addNewMessage.callAPI(
      {
        message: publicationMessage,
        messageId: '',
        labelId: publicationMessageType?.id,
        eventId: tableState.selectedRows[0]?.id,
      },
      {
        onSuccessfullCall: () => {
          onCloseMessageConfirm();
        },
      },
    );
  }, [tableState, addNewMessage, publicationMessageType, onCloseMessageConfirm, publicationMessage]);

  const onCancelMessageConfirmWithoutMessageClick = useCallback(() => {
    onCloseMessageConfirm();
  }, [onCloseMessageConfirm]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: Permits.EXPOSITION_VIEW,
        },
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.EXPOSITION_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: {
          name: getEventPermitList('EXPOSITION', 'EDIT'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: {
          name: getEventPermitList('EXPOSITION', 'DELETE'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'DELETE'),
      },
      {
        icon: { type: 'like' },
        title: 'Процедура утверждения',
        onClick: handleApproveButtonClick,
        permission: {
          name: Permits.EXPOSITION_APPROVE,
        },
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      selectedRowsLength,
      handleAddButtonClick,
      handleEditButtonClick,
      userPermission,
      tableState.selectedRows,
      handleDeleteButtonClick,
      handleApproveButtonClick,
      getReports,
      handleSetCurrentReport,
    ],
  );

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    isOpenHelpForm,
    setIsOpenHelpForm,
    isApprovePopupOpen,
    handleCloseApprovePopup,
    handleContinueStep,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    controllerIsForm,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    buttons,
    settings,
  };
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseEditForm,
    handleTemplateClosePermissionForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isPermissionFormOpen,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.UserEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={handleTemplateCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        login={tableState.selectedRows[0]?.Login}
        connectedPerson={tableState.selectedRows[0]?.Person}
        relatedTableState={tableState}
      />

      <modalTemplates.UserPermissionTemplate.Component
        isOpen={isPermissionFormOpen}
        onClose={handleTemplateClosePermissionForm}
        id={tableState.selectedRows[0]?.id}
        login={tableState.selectedRows[0]?.Login}
        connectedPerson={tableState.selectedRows[0]?.Person}
        relatedTableState={tableState}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            ({tableState.selectedRows[0]?.Login}) ({tableState.selectedRows[0]?.FullName}) Удалить пользователя?
          </>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useCallback, useMemo, useState } from 'react';

import { ButtonProps, FormComponent, ListEditTable, SectionTitle, Toolbar } from 'components';

import { NirRequest } from 'types/models';
import { ModalPerformersTable } from './ModalPerformersTable';

type Props = {
  title: string;
  performers: NirRequest.Performer[];
  chosenPerformers: NirRequest.BasePerformerInfo[];
  setChosenPerformers(performersInfo: NirRequest.BasePerformerInfo[]): void;
  disabled: boolean;
};

export function ChoosePerformers(props: Props) {
  const { chosenPerformers, setChosenPerformers, performers, title, disabled } = props;

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [selectedCollectivePerformerIndex, setSelectedCollectivePerformerIndex] = useState<number | null>(null);

  const collectivePerformers = useMemo(() => {
    return performers
      .filter(performer =>
        chosenPerformers.find(
          x =>
            (x.performerId && performer.id && x.performerId === performer.id) ||
            (x.personId &&
              performer.person?.id &&
              x.role?.value &&
              performer.role?.value &&
              x.personId === performer.person?.id &&
              x.role?.value === performer.role?.value),
        ),
      )
      .sort((x, y) => (x.person?.fullName ?? '').localeCompare(y.person?.fullName ?? ''));
  }, [chosenPerformers, performers]);

  const setPerformers = useCallback(
    (сurrentPerformers: NirRequest.Performer[]) => {
      setChosenPerformers(
        сurrentPerformers.map<NirRequest.BasePerformerInfo>(x => ({
          performerId: x.id,
          personId: x.person?.id ?? null,
          role: x.role,
        })),
      );
    },
    [setChosenPerformers],
  );

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
  }, []);

  const submitModal = useCallback(
    (newPerformers: NirRequest.Performer[]) => {
      setPerformers([...collectivePerformers, ...newPerformers]);
    },
    [collectivePerformers, setPerformers],
  );

  const removePerformer = useCallback(() => {
    if (selectedCollectivePerformerIndex !== null) {
      const updatedPerformers = [...collectivePerformers];
      updatedPerformers.splice(selectedCollectivePerformerIndex, 1);
      setPerformers(updatedPerformers);
      if (!updatedPerformers.length) {
        setSelectedCollectivePerformerIndex(null);
      }
    }
  }, [selectedCollectivePerformerIndex, collectivePerformers, setPerformers]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsOpenModal(true),
        isDisabled: disabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: removePerformer,
        isDisabled: disabled || selectedCollectivePerformerIndex === null,
      },
    ],
    [disabled, removePerformer, selectedCollectivePerformerIndex],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field>
        <SectionTitle title={title} isTableHeader />

        <Toolbar buttons={buttons} mode="list" />

        <ListEditTable
          rows={collectivePerformers}
          defaultRowsCount={6}
          columns={[
            { label: 'Роль', formatValue: x => x.role?.label ?? '' },
            { label: 'ФИО исполнителя', formatValue: x => x.person?.fullName ?? '' },
            { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          ]}
          selectRow={setSelectedCollectivePerformerIndex}
          selectedRowIndex={selectedCollectivePerformerIndex}
        />

        <ModalPerformersTable
          isOpen={isOpenModal}
          onClose={closeModal}
          onSubmit={submitModal}
          performers={performers.filter(x => !collectivePerformers.find(y => x === y))}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}

import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { Project, Table } from 'types/models';
import { Item } from 'types/models/common';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showErrorsMessages } from 'utils/Common';
import { getMockReport, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';
import * as BackendAPI from 'services/BackendAPI';
import { ProjectModule, ProjectReportBlock, ProjectReportType } from 'utils/Enums';

import { validate } from './validate';

import { getEnum, getEnumItem } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose: () => void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const { enumMap } = useAppDataContext();
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const [report, setReport] = useState<Project.Report>(
    getMockReport({
      type: getEnumItem('ProjectReportType', ProjectReportType.INTERMEDIATE),
      block: getEnumItem('ProjectReportBlock', ProjectReportBlock.STATE_REGISTRATION),
    }),
  );
  const [projectStages, setProjectStages] = useState<Project.Stage[]>([]);
  const [selectedStageLabel, setSelectedStageLabel] = useState<string>('');
  const [isProjectLoadDone, setIsProjectLoadDone] = useState<boolean>(false);

  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject', {
    onSuccessfullCall: ({ data }) => {
      const freeStages = data.stages.filter(s => !data.reports.some(r => r.stage?.id === s.id));
      setProjectStages(freeStages);
      setReport({ ...report, stage: freeStages[0] ?? null });
      setSelectedStageLabel(freeStages[0] ? getStageTitle(freeStages[0]) : '');
      setIsProjectLoadDone(true);
    },
  });

  const { methods: saveProjectReport } = BackendAPI.useBackendAPI('SaveProjectReport', {
    onSuccessfullCall: () => {
      onClose();
      tableStreams.reloadTable.push({});
      showNotification({ message: 'Отчет успешно создан', theme: 'success' });
    },
  });

  const stageOptions = useMemo(
    () =>
      projectStages
        .sort((a, b) => Number(a.number) - Number(b.number))
        .map<Item>(x => ({ label: getStageTitle(x) ?? '', value: x.id ?? '' })),
    [projectStages],
  );

  const reportTypeOptions = useMemo(() => getEnum('ProjectReportType', enumMap), [enumMap]);

  const handleStageChange = useCallback(
    (newStage: Item) => {
      setSelectedStageLabel(newStage.label);
      setReport({
        ...report,
        stage: projectStages.find(stage => stage.id === newStage.value) || null,
      });
    },
    [projectStages, report],
  );

  const handleReportTypeChange = useCallback(
    (newReportType: Item) => {
      setReport({
        ...report,
        type: newReportType,
      });
    },
    [report],
  );

  const onSubmit = useCallback(() => {
    const validateInfo = validate(report);
    const invalidFields = validateInfo.filter(({ isValid }) => !isValid);
    const isValid = !invalidFields.length;
    if (isValid) {
      saveProjectReport.callAPI({ report, projectId: id });
    } else {
      showErrorsMessages(invalidFields.map(({ invalidMessage }) => invalidMessage));
    }
  }, [id, report, saveProjectReport]);

  useLayoutEffect(() => {
    if (id) {
      getProject.callAPI({ id, modules: [ProjectModule.STAGES, ProjectModule.REPORTS] });
    }
    // eslint-disable-next-line
  }, []);

  return {
    handleStageChange,
    onSubmit,
    stageOptions,
    report,
    setReport,
    workMode,
    reportTypeOptions,
    handleReportTypeChange,
    selectedStageLabel,
    isProjectLoadDone,
  };
}

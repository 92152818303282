import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Filters } from './filters';
import { CommunityType } from 'utils/Enums';

type Props = {
  communityTypes: CommunityType[];
  enumFilterValues?: Table.EnumFilterValues;
  hasSelectButton?: boolean;
  isLocal?: boolean;
  isMembersTabHidden?: boolean;
};

export function GetCommunityList({
  communityTypes,
  enumFilterValues,
  hasSelectButton,
  isLocal,
  isMembersTabHidden,
}: Props): Table.Specification<State> {
  return {
    apiID: 'GetCommunityList',
    header: {
      firstLevel: {
        title: 'Научные сообщества и организации',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
    requestData: { communityTypes, isLocal },
    useCustomController: makeUseCustomController(communityTypes, isMembersTabHidden),
    enumFilterValues,
    isWithQna: true,
  };
}

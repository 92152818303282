import { Color } from 'constants/colors';
import { useLocalNotificationsStreams } from './hooks';

export type Notification = {
  message: string;
  theme: Partial<keyof typeof Color>;
  lifetime?: number;
};

/**
 * Throw notification message
 * @param message - Any message as string
 * @param theme - Type as keyof typeof themes
 * @param lifetime - (optional) Time of showing (on seconds, min is 2)
 */
export function showNotification({ message, theme, lifetime = 10 }: Notification) {
  const notificationsStreams = useLocalNotificationsStreams();
  notificationsStreams.addNotification.push({ message, theme, lifetime });
}

export { Component as Notifications } from './component';

import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { SecurityDocumentRadioOptions } from '../GetPatentSecurityDocumentList/filters';

export const Filters = React.memo(() => {
  const [type, setType] = useState<SecurityDocumentRadioOptions>(SecurityDocumentRadioOptions.ALL);
  const [isActiveYear, setIsActiveYear] = useState<boolean>(false);

  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: (
        <>
          <p>
            Фильтр <strong>Все</strong> - все записи в базе данных (свидетельства и заявки);
          </p>
          <p>
            Фильтр <strong>Заявки</strong> - записи, у которых отсутствует номер документа;
          </p>
          <p>
            Фильтр <strong>Документы</strong> - записи, у которых заполнено поле Номер документа;
          </p>
          <p>
            <strong>Заявки + Поданные за Год</strong> - записи, у которых есть номер заявки, нет номера документа и дата подачи
            или дата приоритета соответствует году фильтра;
          </p>
          <p>
            <strong>Документы + Получен в году</strong> - записи, у которых есть номер документа и дата публикации соответствует
            году фильтра;
          </p>
          <p>
            <strong>Документы + Действуют в году</strong> - записи, у которых есть номер документа и период действия пересекается
            с годом в фильтре;
          </p>
          <p>
            <strong>Все + Получен/подана в году</strong> - полученные документы и поданные заявки (без номера документа).
          </p>
        </>
      ),
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'type',
      list: [
        { label: 'Все', value: SecurityDocumentRadioOptions.ALL },
        { label: 'Заявки', value: SecurityDocumentRadioOptions.REQUESTS },
        { label: 'Документы', value: SecurityDocumentRadioOptions.DOCUMENTS },
      ],
      value: type,
      onChange: e => {
        setType(e as SecurityDocumentRadioOptions);
        if (e !== SecurityDocumentRadioOptions.DOCUMENTS) setIsActiveYear(false);
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'receiveYear',
      label: 'Получен/подана в году',
      checked: false,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'activeYear',
      label: 'Действует в году',
      checked: isActiveYear,
      onChange: setIsActiveYear,
      isDisabled: type !== SecurityDocumentRadioOptions.DOCUMENTS,
    },
  ]);
});

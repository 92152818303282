import React, { useCallback, useEffect, useState } from 'react';

import { ListEditTable } from 'components';

import { Project } from 'types/models';
import { getStagesOptions, sortStages } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  stages: Project.Stage[];
  onSelectStages(stages: Project.Stage[]): void;
  disabled: boolean;
  isSelectOnlyOne?: boolean;
};

export function SelectStages(props: Props) {
  const { stages, disabled, isSelectOnlyOne = false, onSelectStages } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

  const clearSelectedIndexes = useCallback(() => {
    setSelectedRowIndex(null);
    setSelectedRowsIndexes([]);
  }, []);

  useEffect(() => {
    clearSelectedIndexes();
  }, [stages, clearSelectedIndexes]);

  useEffect(() => {
    if (disabled) {
      clearSelectedIndexes();
    }
  }, [disabled, clearSelectedIndexes]);

  const stagesOptions = getStagesOptions(sortStages(stages));

  const handleSelectStages = useCallback(
    (indexes: number[]) => {
      setSelectedRowsIndexes(indexes);
      const selectedStages = indexes.map(index => stages[index]);
      onSelectStages(selectedStages);
    },
    [stages, onSelectStages],
  );

  const handleSelectRow = useCallback(
    (index: number | null) => {
      if (disabled) {
        return;
      }

      if (isSelectOnlyOne) {
        setSelectedRowIndex(index);
        handleSelectStages(index !== null ? [index] : []);
        return;
      }

      setSelectedRowIndex(index);
    },
    [disabled, isSelectOnlyOne, handleSelectStages],
  );

  return (
    <ListEditTable
      rows={stagesOptions}
      defaultRowsCount={4}
      columns={[{ label: 'Этапы', formatValue: x => x.label }]}
      selectedRowIndex={selectedRowIndex}
      selectRow={handleSelectRow}
      selectedRowsIndexes={isSelectOnlyOne ? undefined : selectedRowsIndexes}
      onSelectRows={disabled || isSelectOnlyOne ? undefined : handleSelectStages}
    />
  );
}

import React, { useCallback } from 'react';

import { ButtonMode, FormComponent, Modal, ReferenceItem, TextArea, TextAreaMode } from 'components';

import { Payment, Table } from 'types/models';
import {
  GetArticleForIncentivePayment,
  GetConferenceForIncentivePayment,
  GetMonographForIncentivePayment,
  GetParticipationForAwardPaymentRequest,
  GetTextBookForIncentivePayment,
} from 'features/Table/specifications';

type Props = {
  isOpen: boolean;
  value: ReferenceItem | null;
  onClose(): void;
  onChange(val: ReferenceItem | null): void;
  onSelect(val: ReferenceItem): void;
  event: Payment.EventType;
};

export function SelectInstanceModal(props: Props) {
  const { isOpen, onClose, value, onChange, onSelect, event } = props;

  const onClickButton = useCallback(() => {
    if (value) {
      onSelect(value);
      onClose();
    }
  }, [onSelect, onClose, value]);

  const titleMap: Record<Payment.EventType, string> = {
    ARTICLE: 'Добавление заявки на оплату статьи',
    CONFERENCE: 'Добавление заявки на оплату конференции',
    MONOGRAPH: 'Добавление заявки на оплату монографии (главы монографии, справочного издания, другого издания)',
    TEXTBOOK: 'Добавление заявки на оплату учебника, учебного пособия',
    MEDAL: 'Добавление заявки на оплату участия в выставке',
  };

  const specMap: Record<Payment.EventType, Table.Specification> = {
    ARTICLE: GetArticleForIncentivePayment,
    CONFERENCE: GetConferenceForIncentivePayment,
    MONOGRAPH: GetMonographForIncentivePayment,
    TEXTBOOK: GetTextBookForIncentivePayment,
    MEDAL: GetParticipationForAwardPaymentRequest,
  };

  const convertersMap: Record<Payment.EventType, (row: Table.Entry) => ReferenceItem> = {
    ARTICLE: row => ({ id: row.id, label: row.bibliographicRecord, customFields: { type: row['id:type'] } }),
    CONFERENCE: row => ({ id: row.id, label: row.label }),
    MONOGRAPH: row => ({ id: row.id, label: row.bibliographicRecord }),
    TEXTBOOK: row => ({ id: row.id, label: row.bibliographicRecord }),
    MEDAL: row => ({ id: row.id, label: row.participationName }),
  };

  const labelsMap: Record<Payment.EventType, string> = {
    ARTICLE: 'Статья для оплаты',
    CONFERENCE: 'Конференция',
    MONOGRAPH: 'Выберите публикацию на оплату',
    TEXTBOOK: 'Учебник, учебное пособие',
    MEDAL: 'Участие в выставке',
  };

  return (
    <Modal
      title={titleMap[event]}
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Продолжить',
          onClick: onClickButton,
          isDisabled: value === null,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <FormComponent.Line>
        <FormComponent.Field label={labelsMap[event]} isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: labelsMap[event],
              isClearable: false,
              table: {
                specification: specMap[event],
                onSelect: (row: Table.Entry | null) => onChange(row ? convertersMap[event](row) : null),
              },
            }}
            value={value?.label}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </Modal>
  );
}

import { Specification } from 'types/models/Table';

type Props = {
  scientificIndexCode: string;
  department: string;
  year: number;
  quartile: string;
};
export function GetDashboardChartScopusWosArticleList({ scientificIndexCode, department, year, quartile }: Props): Specification {
  return {
    apiID: 'GetDashboardChartScopusWosArticleList',
    workMode: 'viewMode',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: {
      scientificIndexCode,
      department,
      year: String(year),
      quartile,
    },
  };
}

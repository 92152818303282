import React, { useCallback, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { setup } from 'bem-cn';

import { Button, ButtonProps, FormComponent, Icon } from 'components';

import { Color, ColorBase } from 'constants/colors';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('modal');

/**
 * Mode of visual content
 */
type ModalMode = 'default' | 'help' | 'warning' | 'info';

/**
 * Mode of window sizes
 */
export type ModalSize =
  | 'height' // height: 100%;
  | 'width' // width: 100%;
  | 'small' // max-width: 500px;
  | 'medium' // max-width: 800px;
  | 'large' // max-width: 1000px;
  | 'extralarge' // max-width: 1400px;
  | 'small-height' // max-height: 500px;
  | 'medium-height' // max-height: 800px;
  | 'large-height' // max-height: 1000px;
  | 'extralarge-height' // max-height: 1400px;
  | 'full'; // height: 100%; max-height: 100%; max-width: 100%;

type Props = {
  /** @property {ModalMode} mode - (optional) Mode/preset for content */
  mode?: ModalMode;
  /** @property {string | React.ReactElement} title - (optional) Title for modal */
  title?: string | React.ReactElement;
  /** @property {boolean} isOpen - (optional) Modal is opened */
  isOpen?: boolean;
  /** @property {Function} onClose - (optional) Callback on close component (Show close button) */
  onClose?(): void;
  /** @property {ButtonProps[]} actions - (optional) Buttons or JSX for FormComponent.Actions */
  actions?: ButtonProps[];
  /** @property {ModalSize | ModalSize[]} size - (value or array) Sizes (common, or size, or sizes)
   * [common - 'full']
   * [width - 'width' | 'small' | 'medium' | 'large' | 'extralarge']
   * [height - 'height' | 'small-height' | 'medium-height' | 'large-height' | 'extralarge-height'] */
  size: ModalSize | ModalSize[];
  /** @property {boolean} viewMode - (optional) Show content for view only (false by default) */
  isViewMode?: boolean;
  /** @property {boolean} isFixed - (optional) Is fixed position modal (false by default) */
  isFixed?: boolean;
  /** @property {React.ReactElement[] | React.ReactElement | false} children - (optional) Children content */
  children: React.ReactElement | false | (React.ReactElement | false)[];
};

const MIN_OFFSET = 8;

/**
 * Modal component with custom content
 *
 * @param mode - (optional) Mode/preset for content
 * @param title - (optional) Title for modal
 * @param isOpen - (optional) Modal is opened
 * @param onClose - Callback on close component
 * @param actions - (optional) Buttons or JSX for FormComponent.Actions
 * @param size - (value or array) Sizes (common, or size, or sizes)
 *  [common - 'full']
 *  [width - 'width' | 'small' | 'medium' | 'large' | 'extralarge']
 *  [height - 'height' | 'small-height' | 'medium-height' | 'large-height' | 'extralarge-height']
 * @param isViewMode - (optional) Show content for view only (false by default)
 * @param isFixed - (optional) Is fixed position modal (false by default)
 * @returns JSX
 */
export const Modal = React.memo(
  ({ mode = 'default', title, isOpen = false, onClose, actions = [], size, isViewMode, isFixed = false, children }: Props) => {
    type EventMousePosition = {
      clientX: number;
      clientY: number;
    };
    type MousePosition = {
      x: number;
      y: number;
    };
    type OffsetPosition = {
      minX: number;
      minY: number;
      maxX: number;
      maxY: number;
    };

    const DEFAULT_POSITION: MousePosition = { x: 0, y: 0 };
    const DEFAULT_OFFSET: OffsetPosition = { minX: 0, minY: 0, maxX: 0, maxY: 0 };

    const windowRef = useRef<HTMLDivElement | null>(null);
    const headRef = useRef<HTMLDivElement | null>(null);

    const [isMouseDowned, setIsMouseDowneded] = useState<boolean>(false);
    const [mouseStartPosition, setMouseStartPosition] = useState<MousePosition>(DEFAULT_POSITION);
    const [windowStarPosition, setWindowStartPosition] = useState<MousePosition>(DEFAULT_POSITION);
    const [windowPosition, setWindowPosition] = useState<MousePosition>(DEFAULT_POSITION);

    const [offsetPosition, setOffsetPosition] = useState<OffsetPosition>(DEFAULT_OFFSET);

    const calcOffsets = useCallback(() => {
      const result = {
        minX: 0 + windowPosition.x,
        minY: MIN_OFFSET + windowPosition.y,
        maxX: 0 - windowPosition.x,
        maxY: -MIN_OFFSET + windowPosition.y,
      };

      if (!windowRef.current || !headRef.current) {
        setOffsetPosition(result);
      } else {
        const windowRect: DOMRect = windowRef.current.getBoundingClientRect();
        const headRect: DOMRect = headRef.current.getBoundingClientRect();

        result.minX = result.minX - headRect.left - headRect.width / 2;
        result.minY = result.minY - headRect.top;
        result.maxX = result.maxX + headRect.right - headRect.width / 2;
        result.maxY = result.maxY + windowRect.height - headRect.bottom;

        setOffsetPosition(result);
      }
    }, [windowPosition]);

    const handleMouseDown = (e: EventMousePosition) => {
      if (!isFixed) {
        calcOffsets();
        setIsMouseDowneded(true);
        setMouseStartPosition({ x: e.clientX, y: e.clientY });
        setWindowStartPosition(windowPosition);
      }
    };

    const handleMouseUp = () => {
      if (isMouseDowned) {
        setIsMouseDowneded(false);
      }
    };

    const handleMouseMove = (e: EventMousePosition) => {
      if (isMouseDowned) {
        const position: MousePosition = {
          x: windowStarPosition.x + (e.clientX - mouseStartPosition.x),
          y: windowStarPosition.y + (e.clientY - mouseStartPosition.y),
        };

        setWindowPosition({
          x: Math.max(Math.min(position.x, offsetPosition.maxX), offsetPosition.minX),
          y: Math.max(Math.min(position.y, offsetPosition.maxY), offsetPosition.minY),
        });
      }
    };

    const sizes = (() => {
      if (typeof size === 'string') {
        return size;
      }
      return size.join(' ');
    })();

    const getContent = () => {
      if (mode === 'warning' || mode === 'info') {
        return (
          <div className={b('content')}>
            {mode === 'warning' && <Icon type="danger" size={40} classMixin="icon-danger" />}
            {mode === 'info' && <Icon type="info" color={Color.success} size={36} classMixin="icon-info" />}
            <div>{children}</div>
          </div>
        );
      }
      return children;
    };

    return ReactDOM.createPortal(
      isOpen && (
        <div className={b({ mooved: isMouseDowned })} onMouseMove={handleMouseMove} onMouseUp={handleMouseUp} ref={windowRef}>
          <div
            className={b('window', { 'view-mode': isViewMode, 'no-moving': isFixed })}
            style={{ transform: `translate(${windowPosition.x}px, ${windowPosition.y}px)` }}
            data-size={sizes}
          >
            <div className={b('head')} ref={headRef}>
              <div className={b('head_title')} onMouseDown={handleMouseDown}>
                {typeof title === 'string' ? <h3>{title}</h3> : title}
              </div>
              {!!onClose && (
                <Button
                  icon={{ type: 'remove', color: ColorBase.clear }}
                  onClick={() => {
                    setWindowPosition(DEFAULT_POSITION);
                    onClose();
                  }}
                  classMixin={b('close')}
                />
              )}
            </div>
            <div className={b('body', { mode })}>
              {children &&
                (actions.length ? (
                  <FormComponent.Wrapper>
                    {getContent()}
                    <FormComponent.Actions>
                      {actions.map((x, index) => (
                        <Button key={index} {...x} />
                      ))}
                    </FormComponent.Actions>
                  </FormComponent.Wrapper>
                ) : (
                  getContent()
                ))}
            </div>
          </div>
        </div>
      ),
      document.body,
    );
  },
);

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  hasSelectButton?: boolean;
};

export function GetRequestKindConsumptionList(props: Props): T.Specification {
  return {
    apiID: 'GetRequestKindConsumptionList',
    header: {
      firstLevel: {
        title: 'Виды расходов для заявок на конкурсы',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: !!props?.hasSelectButton,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

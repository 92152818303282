import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: 'MagazineView',
        },
        isDisabled: isViewButtonDisabled,
      },
    ],
    [isViewButtonDisabled, handleViewButtonClick],
  );

  return {
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
  };
};

export default useController;

import React from 'react';

import { FormComponent, Modal, ReferenceItem, Select, SelectMode, TextArea } from 'components';

import { PaymentFeedline } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  isOpen: boolean;
  paymentFeedline: PaymentFeedline | null;
  changeFeedline(key: keyof PaymentFeedline, value: ValueOf<PaymentFeedline>): void;
  onClose(): void;
  onSave(): void;
};

export function MessageModal(props: Props) {
  const { isOpen, onClose, paymentFeedline, onSave, changeFeedline } = props;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={(paymentFeedline?.id ? 'Редактирование' : 'Добавление') + ' записи в журнале сообщений'}
      size="large"
    >
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Тема">
              <Select
                mode={SelectMode.REFERENCE}
                value={paymentFeedline?.messageCategory}
                onChange={(option: ReferenceItem | null) => changeFeedline('messageCategory', option)}
                settings={{
                  name: 'RefPaymentRequestMessageCategory',
                  title: 'Справочник "Категории сообщений по стимулирующим выплатам"',
                }}
                isDisabled={paymentFeedline?.isSystem}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сообщение">
              <TextArea
                settings={{ isFixed: true }}
                value={paymentFeedline?.message ?? ''}
                onChange={val => changeFeedline('message', val)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </Modal>
  );
}

import React, { ReactElement, useMemo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-actions');

type Props = {
  /** @property {ReactElement | false | null | (ReactElement | false | null)[]} children - (optional) React inner JSX component */
  children: ReactElement | false | null | (ReactElement | false | null)[];
};

/**
 * Wrapper for buttons
 *
 * @param {ReactElement | false | null | (ReactElement | false | null)[]} children - (optional) React inner JSX component
 * @returns JSX
 */
export const Actions = React.memo(({ children }: Props) => {
  const fields = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);

  return (
    <div className={b()}>
      {fields.filter(Boolean).map((item, index) => (
        <div key={index} className={b('item')}>
          {item}
        </div>
      ))}
    </div>
  );
});

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { getLinksMap } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';

export function useController(tableState: Table.State) {
  const { settings } = useAppDataContext();

  const isSelectedRowsLength = !tableState.selectedRows.length;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
        isDisabled: isSelectedRowsLength,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть публикацию во внешнем источнике',
        expandedList: { list: getLinks },
        isDisabled: !tableState.selectedRows.length,
      },
    ],
    [isSelectedRowsLength, handleViewButtonClick, tableState.selectedRows.length, getLinks],
  );

  return {
    isViewFormOpen,
    handleTemplateCloseViewForm,
    buttons,
  };
}

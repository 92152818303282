import { Form, Validation } from 'types/models';
import * as validator from 'utils/Validators';

function getInvalidFieldMsg(isValid: boolean, title: string) {
  return !isValid ? `${title} введен в неправильном формате` : '';
}

function getErrorMessageISSNFields(formFields: Form.Fields) {
  const isValidISSN = validator.isISSNValid(formFields.ISSN);
  const isValidElISSN = validator.isISSNValid(formFields.ISSNEl);

  const issnMsg = getInvalidFieldMsg(isValidISSN, formFields.ISSN.title);
  const issnElMsg = getInvalidFieldMsg(isValidElISSN, formFields.ISSNEl.title);

  return {
    errorMessage: [issnMsg, issnElMsg].filter(Boolean).join('<br />') || null,
    invalidFieldNames: [isValidISSN ? null : 'ISSN', isValidElISSN ? null : 'ISSNEl'].filter(Boolean) as string[],
  };
}

function getErrorMessageDOIField(fieldDOI: Form.Field) {
  if (fieldDOI.value) {
    const isValid = (fieldDOI.value as string).startsWith('10.');

    return {
      errorMessage: getInvalidFieldMsg(isValid, fieldDOI.title),
      invalidFieldNames: [isValid ? null : 'DOI'].filter(Boolean) as string[],
    };
  }
  return { errorMessage: null, invalidFieldNames: [] };
}

function getErrorMessageForRequiredFields(
  formFields: Form.Fields,
  translates: Form.Translation[],
  original: Form.Original | null,
) {
  const emptyRequiredFields = Object.fromEntries(
    Object.entries(formFields).filter(([, field]) => field.required && !field.value),
  );

  const isValidTranslations = (() => {
    if (formFields.isTranslate.value) {
      return Boolean(original);
    }
    return true;
  })();

  const isValidOriginal = (() => {
    if (formFields.isOriginal.value) {
      return Boolean(translates.length);
    }
    return true;
  })();

  const requiredTitles = [
    ...Object.values(emptyRequiredFields).map(x => x.title),
    isValidTranslations ? null : 'Переводы',
    isValidOriginal ? null : 'Оригинал',
  ]
    .filter(Boolean)
    .join(', ');

  return {
    errorMessage: requiredTitles.length ? `Заполните обязательные поля: (${requiredTitles})` : null,
    invalidFieldNames: [
      ...Object.keys(emptyRequiredFields),
      isValidTranslations ? null : 'isTranslate',
      isValidOriginal ? null : 'isOriginal',
    ].filter(Boolean) as string[],
  };
}

export function validate({
  formFields,
  translates,
  original,
}: {
  formFields: Form.Fields;
  translates: Form.Translation[];
  original: Form.Original | null;
}): Validation.Result {
  const requiredError = getErrorMessageForRequiredFields(formFields, translates, original);
  const issnError = getErrorMessageISSNFields(formFields);
  const doiError = getErrorMessageDOIField(formFields.DOI);

  const errorMessage = [requiredError.errorMessage, issnError.errorMessage, doiError.errorMessage].filter(Boolean).join('<br />');
  const invalidFieldKeys = [...requiredError.invalidFieldNames, ...issnError.invalidFieldNames, ...doiError.invalidFieldNames];

  const result: Validation.Result = {
    isFormValid: invalidFieldKeys.length === 0,
    nextNotification: { theme: 'danger', message: errorMessage },
    invalidFieldKeys,
  };
  return result;
}

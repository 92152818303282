import React, { memo } from 'react';
import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps } from '../../../models';
import useController from './controller';

const Approve = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { checkboxList } = useController(parameters, parameterName);

  return (
    <DefaultCheckboxList
      initialCheckboxList={checkboxList}
      parameterName={parameterName}
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
    />
  );
};

const Component = memo(Approve);

export { Component as Approve };

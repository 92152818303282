import React from 'react';

import { FormComponent, Select, SelectMode } from 'components';

import { MobileRequest } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.ApplicantSupervisor | null;
  onFieldChange: React.Dispatch<React.SetStateAction<MobileRequest.ApplicantSupervisor | null>>;
};

function ApplicantSupervisorFields({ entry, onFieldChange }: Props) {
  const { handlePersonChange, handleRoleChange, convertApplicantSupervisorToMember } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson
            member={convertApplicantSupervisorToMember(entry)}
            onUpdateMember={handlePersonChange}
            hiddenButtons={['delete']}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={entry?.role}
            settings={{ name: 'MobileRequestApplicantSupervisorRole' }}
            onChange={handleRoleChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { ApplicantSupervisorFields };

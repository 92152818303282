import React, { useState, useCallback, useEffect, useRef } from 'react';
import { setup } from 'bem-cn';

import { Modal, FormComponent } from 'components';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('preview-html');

type Props = {
  /** @property {string} modalTitle - (optional) Title for modal */
  modalTitle?: string;
  /** @property {string} value - Value for preview as HTML */
  value: string;
};

/**
 * Preview component for HTML
 *
 * @param modalTitle - (optional) Title for modal
 * @param value - Value for preview as HTML
 * @returns JSX
 */
export const PreviewHTML = React.memo(({ modalTitle, value }: Props) => {
  const [isExtendedPopupOpen, setIsExtendedPopupOpen] = useState<boolean>(false);
  const [visibleDetails, setVisibleDetails] = useState<boolean>(false);

  const openExtendedPopup = useCallback(() => {
    setIsExtendedPopupOpen(true);
  }, []);
  const closeExtendedPopup = useCallback(() => {
    setIsExtendedPopupOpen(false);
  }, []);

  const container = useRef<HTMLDivElement | null>(null);
  const content = useRef<HTMLDivElement | null>(null);

  const resizeObserver = new ResizeObserver(entries => {
    setVisibleDetails((container.current?.offsetHeight ?? 0) < entries[0].contentRect.height);
  });

  useEffect(() => {
    if (content.current) {
      resizeObserver.observe(content.current);
    }
    return function () {
      if (content.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(content.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={b()} ref={container}>
        <div
          className={[b('content'), 'quill-preview'].join(' ')}
          ref={content}
          dangerouslySetInnerHTML={{ __html: value || '' }}
        ></div>
        <div className={b('details', { show: visibleDetails })}>
          <span onClick={openExtendedPopup}>Подробнее &gt;</span>
        </div>
      </div>

      <Modal onClose={closeExtendedPopup} isOpen={isExtendedPopupOpen} title={modalTitle || 'Просмотр содержимого'} size="large">
        <FormComponent.Wrapper>
          <div className="quill-preview" dangerouslySetInnerHTML={{ __html: value || '' }}></div>
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
});

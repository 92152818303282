import { useCallback, useEffect, useMemo, useRef } from 'react';
import { v4 as uuidv4 } from 'uuid';
import * as BackendAPI from 'services/BackendAPI';

import { Props, SettingsReferenceProps } from '.';

import { GetReferenceElementList } from 'features/Table/specifications';
import { getHashObject } from 'utils/Helpers';
import { ReferenceFilter, ReferenceItem } from 'types/models';
import { FieldVisibleMode } from 'utils/Enums';

export function useControllerReference({
  settings,
  setReferences,
}: Pick<Props, 'settings'> & { setReferences(res: ReferenceItem[]): void }) {
  const generatedKey = useRef<string>(uuidv4());
  const referenceSettings = useMemo<SettingsReferenceProps>(() => settings as SettingsReferenceProps, [settings]);
  const filters = useRef<ReferenceFilter[]>([]);

  useEffect(() => {
    const filtered = (referenceSettings.filters || [])
      .map(i => ({ ...i, values: i.values.filter(Boolean) }))
      .filter(i => i.values.length);

    if (getHashObject(filters.current) !== getHashObject(filtered)) {
      setReferences([]);
      filters.current = filtered;
    }
  }, [referenceSettings.filters, setReferences]);

  const { name, defaultValue, isFacultyFilterChecked } = referenceSettings;
  const specificationReference = GetReferenceElementList(
    { requestData: { filters: filters.current, name }, defaultValue, isFacultyFilterChecked },
    generatedKey.current,
  );

  const { methods: getReferenceElements } = BackendAPI.useBackendAPI('GetReferenceElements');

  const refreshOptions = useCallback(() => {
    getReferenceElements.callAPI(
      {
        referenceName: referenceSettings.name,
        fieldVisibleMode: FieldVisibleMode.SYSTEM,
        filters: [...(filters.current || []), ({ key: 'active', values: [true] } as unknown) as ReferenceFilter],
      },
      {
        onSuccessfullCall: ({ data }) => {
          setReferences(data);
        },
      },
    );
  }, [getReferenceElements, referenceSettings.name, setReferences]);

  return {
    specificationReference,
    refreshOptions,
  };
}

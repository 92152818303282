import { Icon } from 'components';

export function TenderProtocolApproverRequestGridHelp(): JSX.Element {
  return (
    <>
      <p>
        В таблице содержится список всех заявок поданных на конкурс для экспертизы. Фильтр <strong>С визой</strong> отображает
        список рассмотренных заявок. В столбце <strong>Согласование</strong> выводится дата, виза и замечание по заявке.
      </p>

      <h3>Что делать эксперту?</h3>

      <div>
        Для просмотра заявки нажмите на кнопку <strong>Просмотр</strong> <Icon type="view" />
      </div>

      <div>
        С помощью кнопки <strong>Экспертная оценка</strong> <Icon type="message" mode="check" /> добавьте визу: Согласовано,
        Отклонить или На доработку.
      </div>

      <p>
        В поле <strong>Замечания</strong> добавьте текст в соответствии с регламентом проведения конкурса.
      </p>

      <div className="warning">
        <span>
          Исправить экспертную оценку нельзя, но можно добавить новую. Завизированные экспертом заявки передаются на рассмотрение
          конкурсной комиссии. Конкурсная комиссия учитывает последнюю добавленную экспертом визу. По итогам работы конкурсной
          комиссии заявке присваивается следующий статус и заявка исключается из списка заявок для экспертизы.
        </span>
      </div>
    </>
  );
}

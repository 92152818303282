import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { KnowHowSecurityDocumentGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    securityDocumentId,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.SecurityDocumentViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={securityDocumentId}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.SecurityDocumentEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={securityDocumentId}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.SecurityDocumentAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {isDeleteConfirmPopupOpen && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            ({tableState.selectedRows[0]?.Kind}) ({tableState.selectedRows[0]?.IntellectualPropertyName}) Удалить ноу-хау?
          </>
        </Modal>
      )}

      <Modal
        mode="help"
        title="Объекты интеллектуальной  собственности"
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        size={['large', 'medium-height']}
      >
        {KnowHowSecurityDocumentGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          securityDocumentId: securityDocumentId || '',
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

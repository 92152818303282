import React, { useCallback } from 'react';
import * as PersonTabs from 'features/Form/looks/person/Tabs/AdditionalInfo';

import { Tabs, Tab, FormComponent, Modal } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { EditableHonor } from './model';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { EditEducations, EditJobs } from 'features/Form/views';
import { Main } from './Main';
import { useController } from './controller';

type Props = {
  onClose?(): void;
};

function PersonForm(props: Props) {
  const { onClose } = props;
  const { localPerson, setLocalPerson, isOpen, submitForm } = useController({
    onClose,
  });

  const { userPermission } = useAppDataContext();

  const makeChangeHandler = (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => {
    setLocalPerson(prevState => ({ ...prevState, [fieldName]: value } as Person.ScientistPerson));
  };

  const changeHonors = useCallback(
    (honors: EditableHonor[]) => {
      setLocalPerson(
        prevState =>
          ({
            ...prevState,
            academicRanks: honors.filter(i => i.type === 'academicRank'),
            ranks: honors.filter(i => i.type === 'rank'),
            degrees: honors.filter(i => i.type === 'degree'),
          } as Person.ScientistPerson),
      );
    },
    [setLocalPerson],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose!} title="Добавление персоны" size={['extralarge', 'large-height']}>
      <FormComponent.Template>
        <ControlPanel handleSave={submitForm} />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Профиль">
              <Main setPerson={setLocalPerson} person={localPerson} userPermission={userPermission} />
            </Tab>
            <Tab title="Сведения о работе">
              <EditJobs disabled={false} isAddMode person={localPerson} onChangeJobs={makeChangeHandler('scientistJobs')} />
            </Tab>
            <Tab title="Сведения об обучении">
              <EditEducations
                disabled={false}
                isAddMode
                person={localPerson}
                onChangeEducations={makeChangeHandler('scientistEducations')}
              />
            </Tab>

            <Tab title="Ученые степени и звания">
              <PersonTabs.ScientistHonors
                disabled={false}
                isAddMode
                userPermission={userPermission}
                person={localPerson}
                onChangeHonors={changeHonors}
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </Modal>
  );
}

export const Component = React.memo(PersonForm);

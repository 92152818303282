import React, { useCallback, useMemo, useState } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { EventType, AuthorPayment } from 'types/models/Payment';
import { AuthorPaymentRequestStatus, PaymentRequestType } from 'utils/Enums';
import { usePermissions } from 'features/IncentivePayments/Toolbar/usePermissions';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { Color } from 'constants/colors';
import { IncentivePaymentsFormHelp } from './help';

type Props = {
  onSave(): void;
  prepareChangeStatusEvent(status: AuthorPaymentRequestStatus): void;
  authorPayment: AuthorPayment | null;
  isDisabled?: boolean;
  typeEvent: EventType;
};

function Toolbar(props: Props) {
  const { onSave, prepareChangeStatusEvent, authorPayment, isDisabled, typeEvent } = props;

  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [status, setStatus] = useState<AuthorPaymentRequestStatus | null>(null);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const permissions = usePermissions(authorPayment?.type?.value as EventType, status!);

  const closeConfirmModal = () => {
    setIsOpenConfirmModal(false);
    setStatus(null);
  };

  const changeStatusTo = useCallback(
    (x: AuthorPaymentRequestStatus) => {
      setStatus(x);
      if (checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, x)) {
        setIsOpenConfirmModal(true);
      } else {
        prepareChangeStatusEvent(x);
      }
    },
    [prepareChangeStatusEvent, authorPayment],
  );

  const statusButtons = useMemo<ButtonProps[]>(() => {
    if (isDisabled) {
      return [];
    }

    const likeButtons = [
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT),
        permission: {
          name: permissions.toAgreement,
        },
      },
    ];

    const mapButtons: Record<AuthorPaymentRequestStatus, ButtonProps[]> = {
      REVISION: likeButtons,
      DRAFT: likeButtons,
      REJECTED: likeButtons,
      AGREEMENT: [
        {
          icon: { type: 'star', color: Color.warning },
          title: 'Передать на оплату',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
          permission: {
            name: permissions.toPaid,
          },
        },
        {
          icon: { type: 'toBack', color: Color.danger },
          title: 'Отправить на доработку',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION),
          permission: {
            name: permissions.toRevision,
          },
        },
        {
          icon: { type: 'clock' },
          title: 'Отложить',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
          permission: {
            name: permissions.toPostpone,
          },
        },
        {
          icon: { type: 'dislike' },
          title: 'Отклонить',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
          permission: {
            name: permissions.toRejected,
          },
        },
      ],
      PAID: [
        {
          icon: { type: 'like' },
          title: 'Одобрить оплату',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED),
          permission: {
            name: permissions.toPayment,
          },
        },
        {
          icon: { type: 'clock' },
          title: 'Отложить',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE),
          permission: {
            name: permissions.toPostpone,
          },
        },
        {
          icon: { type: 'dislike' },
          title: 'Отклонить',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
          permission: {
            name: permissions.toRejected,
          },
        },
      ],
      POSTPONE: [
        {
          icon: { type: 'star', color: Color.warning },
          title: 'Передать на оплату',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID),
          permission: {
            name: permissions.toPaid,
          },
        },
        {
          icon: { type: 'dislike' },
          title: 'Отклонить',
          onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED),
          permission: {
            name: permissions.toRejected,
          },
        },
      ],
      PAYMENT_APPROVED: [],
    };

    return authorPayment?.status ? mapButtons[authorPayment.status.value] : [];
  }, [
    authorPayment,
    changeStatusTo,
    isDisabled,
    permissions.toAgreement,
    permissions.toPaid,
    permissions.toPayment,
    permissions.toPostpone,
    permissions.toRejected,
    permissions.toRevision,
  ]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: onSave,
        isDisabled: isDisabled,
      },
      ...statusButtons,
    ],
    [isDisabled, onSave, statusButtons],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <Modal
        mode="help"
        title={IncentivePaymentsFormHelp(typeEvent as PaymentRequestType).title}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {IncentivePaymentsFormHelp(typeEvent as PaymentRequestType).body}
      </Modal>

      <Modal
        mode="warning"
        title="Изменение статуса заявки"
        isOpen={isOpenConfirmModal}
        onClose={closeConfirmModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              if (status !== null) {
                prepareChangeStatusEvent(status);
              }
              closeConfirmModal();
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeConfirmModal,
          },
        ]}
        size="small"
      >
        <>{checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, status)}</>
      </Modal>
    </>
  );
}

export { Toolbar };

import React from 'react';

import { Tab, Tabs } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { EnglishDescription } from './EnglishDescription';
import { Description } from './Description';
import { PublicationsList } from './PublicationsList';
import { NirTenderType } from 'utils/Enums';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function ProjectDescription(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  return (
    <Tabs>
      <Tab title="Описание проекта">
        <Description disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="Описание на английском языке">
        <EnglishDescription disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab
        title="Подтверждение научного задела (список публикаций)"
        isVisible={nirRequest?.lot?.nirTenderType?.value !== NirTenderType.PP}
      >
        <PublicationsList disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
    </Tabs>
  );
}

import React, { useCallback, useState } from 'react';

import { ButtonMode, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { Project } from 'types/models';
import { SelectStages } from '../AddIndicatorsModal/SelectStages';
import { getYear } from 'utils/Helpers';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(stage: Project.Stage): void;
  stages: Project.Stage[];
  contestRequest: Project.FullContestRequest | null;
};

export function SelectStageModal(props: Props) {
  const { isOpen, stages, contestRequest, onClose, onSubmit } = props;

  const [selectedStages, setSelectedStages] = useState<Project.Stage[]>([]);
  const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
  const [selectedStage] = selectedStages;

  const handleSubmit = useCallback(() => {
    if (selectedStage) {
      if (getYear(selectedStage.endDate) !== getYear(contestRequest?.endDate ?? '')) {
        setIsOpenConfirmModal(true);
        return;
      }
      onSubmit(selectedStage);
      onClose();
    } else {
      showNotification({ message: 'Выберите этап', theme: 'danger' });
    }
  }, [onSubmit, onClose, selectedStage, contestRequest?.endDate]);

  return (
    <Modal
      title="Этапы"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Выбрать',
          onClick: handleSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <SelectStages isSelectOnlyOne disabled={false} stages={stages} onSelectStages={setSelectedStages} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmModal}
        onClose={() => setIsOpenConfirmModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              onSubmit(selectedStage);
              onClose();
              setIsOpenConfirmModal(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmModal(false),
          },
        ]}
        size="small"
      >
        <>
          {[
            `Период, выбранного этапа (${selectedStage?.startDate} - ${selectedStage?.endDate}) не совпадает с периодом,`,
            `указанным в заявке (${contestRequest?.startDate} - ${contestRequest?.endDate}) Этап выбран верно? Продолжить ?`,
          ].join(' ')}
        </>
      </Modal>
    </Modal>
  );
}

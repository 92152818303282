import { useLayoutEffect, useMemo, useState } from 'react';

import { calcReduceByParameter, groupByParameter } from '../helpers';
import { FormatedDataArray, ResponseData } from '../types';
import * as BackendAPI from 'services/BackendAPI';

type Item = {
  name: string;
  data: number[];
};

export function useController() {
  const [financingByPeriod, setFinancingByPeriod] = useState<Item[]>([]);

  const yearslist = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const [isLoading, setIsLoading] = useState(false);

  const { methods: GetDashboardChartByProgramIndicatorListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByProgramIndicatorListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const receivedData = data as ResponseData;
        const groupedValueArray = groupByParameter(receivedData.Response.Grid.Rows.Row, 'Indicator').map(ind =>
          groupByParameter(ind, 'Program'),
        );
        const constructSummaryParameters = (indicator: FormatedDataArray[], year: number) =>
          calcReduceByParameter(indicator, year, 'FactAmount');
        setFinancingByPeriod(
          groupedValueArray.map(indicator => ({
            name: indicator[0][0]._attributes.Indicator,
            data: yearslist
              .map(yearItem => constructSummaryParameters(indicator, yearItem))
              .map(value => (value ? value / 1000 : 0)),
          })),
        );
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    GetDashboardChartByProgramIndicatorListStatic.callAPI({
      rowQuantity: -1,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    financingByPeriod,
    yearslist,
    isLoading,
  };
}

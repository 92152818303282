export function MipDocumentsHelp(): JSX.Element {
  return (
    <>
      <p>
        Вкладка <strong>Документы МИП</strong> предназначена для хранения специализированных документов МИП (уставные документы,
        приказ, договор и пр.). Вкладка доступна для пользователей с правом на Редактирование МИП.
      </p>
    </>
  );
}

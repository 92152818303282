import { parse } from 'date-fns';
import { getDateYearMonthDayTime } from 'utils/Helpers/getDateYearMonthDayTime';
import { formatStr as defaultFormatStr } from '../Constants/FormatStr';

type DatePeriod = {
  from: string;
  to: string;
};

type Props = {
  dateString: string;
  dateStringPeriod: DatePeriod;
  formatStr?: string;
};

const getIsDateInPeriod = ({ dateString, dateStringPeriod, formatStr = defaultFormatStr }: Props) => {
  const date = parse(dateString, formatStr, new Date());
  const dateFrom = parse(dateStringPeriod.from, formatStr, new Date());
  const dateTo = parse(dateStringPeriod.to, formatStr, new Date());

  const dateTime = getDateYearMonthDayTime(date);

  const dateFromTime = getDateYearMonthDayTime(dateFrom);
  const dateToTime = getDateYearMonthDayTime(dateTo);

  const isDateInPeriod = dateFromTime <= dateTime && dateTime <= dateToTime;

  return isDateInPeriod;
};

export { getIsDateInPeriod };

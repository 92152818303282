import React, { useCallback } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput, TextInputMode } from 'components';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';
import { getYear } from 'utils/Helpers';

import { EditableIndicator, IndicatorKey } from 'features/Form/looks/project/ProjectForm/model';
import { getStagesOptions } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  stages: Project.Stage[];
  indicator: EditableIndicator;
  setIndicator(indicator: EditableIndicator): void;
  programId: string;
  type: IndicatorKey;
};

export function Fields(props: Props) {
  const { type, stages, indicator, setIndicator } = props;

  const makeChangeHandler = (key: keyof EditableIndicator) => (value: ValueOf<EditableIndicator>) => {
    setIndicator({ ...indicator, [key]: value });
  };

  const stagesOptions = getStagesOptions(stages);

  const handleChangeStage = useCallback(
    (option: Item | null) => {
      const stage = stages.find(x => x.number === option?.value ?? '');
      if (stage) {
        setIndicator({ ...indicator, stageNumber: stage.number, year: String(getYear(stage.startDate)) });
      }
    },
    [setIndicator, indicator, stages],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Этап" isRequired>
          <Select
            value={stagesOptions.find(x => x.value === indicator.stageNumber)}
            options={stagesOptions}
            onChange={handleChangeStage}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Год" isRequired labelSize="fit">
          <TextInput mode={TextInputMode.YEAR} value={indicator.year} onChange={makeChangeHandler('year')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={type === 'indicators' ? 'Индикатор' : 'Показатель'} isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={indicator.ref}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('ref')(option)}
            settings={
              type === 'indicators'
                ? {
                    name: 'RefResultIndicator',
                    title: 'Справочник индикаторов эффективности выполнения научных проектов',
                  }
                : {
                    name: 'RefResultIndex',
                    title: 'Справочник показателей результативности выполнения научных проектов',
                  }
            }
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="План">
          <TextInput mode={TextInputMode.NUMBER} value={indicator.plan} onChange={val => makeChangeHandler('plan')(val || '0')} />
        </FormComponent.Field>
        <FormComponent.Field label="Факт" labelSize="fit">
          <TextInput mode={TextInputMode.NUMBER} value={indicator.fact} onChange={val => makeChangeHandler('fact')(val || '0')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextInput value={indicator.note} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { useMemo, useState, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { ReferenceItem, Table as T } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: T.State;
  handleOpenConfirm?: (confirmText: string) => void;
} & State;

export const useController = ({ tableState, program, getProgram, handleOpenConfirm, isDisabled }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;

  const tableStreams = useLocalTableStreams();

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);

  const { methods: getReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { methods: saveProgramAPI } = BackendAPI.useBackendAPI('SaveProgram');

  const handleSubmitAddForm = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (program && row) {
        getReferenceElementsAPI.callAPI(
          { childIds: [row.id], filters: [], referenceName: 'RefKnowledgeBranch' },
          {
            onSuccessfullCall: ({ data }) => {
              const refItem = data[0];
              const newKnowledgeBranch: ReferenceItem = {
                id: refItem.id,
                label: refItem.label,
              };

              const newKnowledgeBranches = program.knowledgeBranches;
              program.knowledgeBranches.push(newKnowledgeBranch);
              const newProgram = { ...program, knowledgeBranches: newKnowledgeBranches };

              saveProgramAPI.callAPI(newProgram, {
                onSuccessfullCall: () => {
                  showNotification({ message: 'Отрасль знаний (РНФ) успешно добавлена', theme: 'success' });
                  getProgram.callAPI({ id: program.id });
                  tableStreams.reloadTable.push({});
                  setIsAddFormOpen(false);
                },
              });
            },
          },
        );
      }
    },
    [getProgram, getReferenceElementsAPI, program, saveProgramAPI, tableStreams.reloadTable],
  );

  const handleAddButtonClick = useCallback(() => {
    if (handleOpenConfirm && !program) {
      handleOpenConfirm('Для добавления отрасли знаний (РНФ) программы необходимо сохранить данные');
    } else {
      setIsAddFormOpen(true);
    }
  }, [handleOpenConfirm, program]);

  const handleCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    if (program && tableState.selectedRows[0]) {
      const selectedKnowledgeBranchId = tableState.selectedRows[0]?.id;
      const newKnowledgeBranches = program.knowledgeBranches.filter(record => record.id !== selectedKnowledgeBranchId);
      const newProgram = { ...program, knowledgeBranches: newKnowledgeBranches };

      saveProgramAPI.callAPI(newProgram, {
        onSuccessfullCall: () => {
          showNotification({ message: 'Отрасль знаний (РНФ) успешно удалена', theme: 'success' });
          getProgram.callAPI({ id: program.id });
          tableStreams.reloadTable.push({});
        },
      });
    }
  }, [getProgram, program, saveProgramAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PROGRAM_ADD },
        isDisabled: isDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.PROGRAM_DELETE },
        isDisabled: isDisabled || !(selectedRowsLength === 1),
      },
    ],
    [handleAddButtonClick, isDisabled, handleDeleteButtonClick, selectedRowsLength],
  );

  return {
    buttons,
    handleCloseAddForm,
    isAddFormOpen,
    handleSubmitAddForm,
  };
};

import React from 'react';
import modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.View
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.Edit
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.Add isOpen={isAddFormOpen} onClose={() => setIsAddFormOpen(false)} relatedTableState={tableState} />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>({selectedRow?.Scientist}) Удалить персону?</>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { createNumberMask } from 'text-mask-addons';

type DecimalNumberProps = {
  isRequired?: boolean;
  limit?: number;
};

export type NumberProps = {
  decimal?: DecimalNumberProps | false;
  isNatural?: boolean;
  isThousands?: boolean;
};

export const NumberMask = (number: NumberProps, settedValue: boolean) => {
  const { decimal = {}, isNatural = true, isThousands = true } = number;

  return {
    // Mask
    mask: settedValue
      ? (rawValue: string) => {
          return createNumberMask({
            prefix: '',
            suffix: '',
            decimalSymbol: rawValue.indexOf(',') > -1 ? ',' : '.',
            thousandsSeparatorSymbol: ' ',
            allowDecimal: !!decimal,
            includeThousandsSeparator: isThousands,
            requireDecimal: !!decimal ? !!decimal?.isRequired : false,
            decimalLimit: !!decimal && decimal?.limit ? decimal?.limit : 2,
            allowNegative: !isNatural,
          })(rawValue);
        }
      : [/\d/],
  };
};

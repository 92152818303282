import React, { useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function LeftPanelForThirdLevel({ tableState, onSubmitTable }: Props) {
  const selectedRowsLength = tableState.selectedRows.length;

  const tableStreams = useLocalTableStreams();

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: () => {
          if (onSubmitTable) {
            onSubmitTable(tableState);
          } else {
            tableStreams.submitTable.push(tableState);
          }
        },
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [onSubmitTable, rowsAvailability.SINGLE_SELECTED, tableStreams.submitTable, tableState],
  );

  return <Toolbar buttons={buttons} />;
}

export const Component = React.memo(LeftPanelForThirdLevel);

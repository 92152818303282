import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetLibraryPublicationLogList: Table.Specification = {
  apiID: 'GetLibraryPublicationLogList',
  header: {
    firstLevel: {
      title: 'Публикации, отправленные в НБ, для которых не получен id НБ',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

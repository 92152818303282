import { Table } from 'types/models';
import { Filters } from './filters';
import { makeUseCustomController } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  setProjectCodeInfo(info: { id: string; year: string }): void;
};

export function GetProjectCodeListByYear({ setProjectCodeInfo }: Props): Table.Specification {
  return {
    apiID: 'GetProjectCodeListByYear',
    header: {
      firstLevel: {
        title: 'Шифры',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    uniqKeys: ['id', 'year'],
    useCustomController: makeUseCustomController({ setProjectCodeInfo }),
  };
}

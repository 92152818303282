import { useCallback, useMemo } from 'react';

import { Form as F, Form, Person, Table as T } from 'types/models';
import { Item } from 'types/models/common';
import { Performer, ProjectCode } from 'types/models/Project';
import { GetFundCardSelectList } from 'features/Table/specifications';
import { formatNumberFieldToCurrency } from 'features/Form/formatters';
import { ProjectScientistRole } from 'utils/Enums';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  projectCode: ProjectCode | null;
  formFields: Form.Fields;
};

export function useController(props: Props) {
  const { projectCode, formFields } = props;
  const projectCodesLabel = useMemo(() => projectCode?.project?.projectCodesLabel || '', [projectCode?.project]);

  const getNameWithContacts = (performer: Performer | null) => {
    const person: Person.ScientistPerson | null = performer?.person || null;

    if (!performer?.fio && !person?.fullName) {
      return '-';
    }

    return [
      performer?.fio || person?.fullName,
      getPersonContacts(person?.contacts) ? `(${getPersonContacts(person?.contacts)})` : '',
    ]
      .filter(Boolean)
      .join(' ');
  };

  const performerOptions =
    projectCode?.project?.performers
      .filter(x =>
        x.jobPeriods.some(
          y => y.role?.value === ProjectScientistRole.LEADER || y.role?.value === ProjectScientistRole.RESPONSIBLE_PERFORMER,
        ),
      )
      .map<Item>(x => ({
        label: getNameWithContacts(x) || '',
        value: x.id || x.person?.id || '',
      })) || [];

  const financingOptions =
    projectCode?.project?.financings.map<Item>(x => ({
      label: x?.source?.label || '',
      value: x.id || '',
    })) || [];

  const departmentOptions =
    projectCode?.project?.departments.map(x => ({
      label: x?.unit?.label || '',
      value: x.id || '',
      customFields: { ...x },
    })) || [];

  const modalTableAvailableFundCardConverter = useCallback<(row: T.Entry) => F.Translation>(
    row => ({ id: row.id, name: row.Name, note: row.Note }),
    [],
  );

  const availableFundCardSpecification = GetFundCardSelectList({});

  const projectCodeFinAmountSum = useMemo(() => {
    return projectCode?.calendarPlansByYears && projectCode?.calendarPlansByYears?.length > 0
      ? formatNumberFieldToCurrency(projectCode?.calendarPlansByYears?.reduce((sum, cc) => sum + cc.amount, 0))
      : '';
  }, [projectCode]);

  const projectCodeNdsAmountSum = useMemo(() => {
    return projectCode?.calendarPlansByYears && projectCode?.calendarPlansByYears?.length > 0
      ? formatNumberFieldToCurrency(projectCode.calendarPlansByYears?.reduce((sum, cc) => sum + cc.ndsAmount, 0) || 0)
      : '';
  }, [projectCode]);

  const finSourceEnabled = useMemo(() => {
    const finSource = projectCode?.project?.financings?.find(f => f.id === formFields.financing?.value?.id);
    return finSource && finSource.isNds;
  }, [formFields, projectCode]);

  // eslint-disable-next-line max-len
  const projectCodeTooltipTxt = `После сохранения записи поле Шифр будет недоступно для редактирования, так как номер Шифра хранится в системе бухгалтерского учета.\nЕсли Шифр связан с документами бухгалтерского учета (приходными или расходными), то рекомендуется заменить на новый с помощью кнопки "Заменить шифр". В этом случае все связанные документы, а так же созданные сметы старого Шифра будут связаны с новым.\nЕсли Шифр не связан с бухгалтерскими документами вы можете его удалить. При удалении шифра запись помечается как "удален" и отображается в списке по фильтру "Удаленные шифры"`;

  return {
    departmentOptions,
    performerOptions,
    financingOptions,
    modalTableAvailableFundCardConverter,
    availableFundCardSpecification,
    projectCodesLabel,
    getNameWithContacts,
    projectCodeFinAmountSum,
    projectCodeNdsAmountSum,
    finSourceEnabled,
    projectCodeTooltipTxt,
  };
}

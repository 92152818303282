import React from 'react';
import * as modalTemplates from '../modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { State } from '../../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    isAddFormOpen,
    isEditFormOpen,
    closeAddForm,
    closeEditForm,
    toolbarButtons,
    closeConfirmDeletePopup,
    isConfirmDeletePopupOpen,
    confirmDeleteRelease,
    relatedTableId,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={toolbarButtons} />

      <modalTemplates.MagazineReleaseAddTemplate
        isOpen={isAddFormOpen}
        onClose={closeAddForm}
        relatedTableId={relatedTableId}
        isDisableEventSource={customState.isDisableEventSource}
        eventId={customState.eventId}
      />

      <modalTemplates.MagazineReleaseEditTemplate
        isOpen={isEditFormOpen}
        onClose={closeEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableId={relatedTableId}
        isDisableEventSource={customState.isDisableEventSource}
        eventId={customState.eventId}
      />

      {!!tableState.selectedRows.length && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isConfirmDeletePopupOpen}
          onClose={closeConfirmDeletePopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: confirmDeleteRelease,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: closeConfirmDeletePopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.label}) Удалить выпуск? </>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

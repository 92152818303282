import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectStateRegistrationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Таблица содержит список всех проектов и предназначена для сопровождения процессов:</p>
        <ul>
          <li>мониторинга списка проектов, подлежащих регистрации в ЕГИСУ</li>
          <li>сбора и согласования информации для регистрации проекта в ЕГИСУ;</li>
          <li>ведения учёта зарегистрированных проектов;</li>
          <li>формирования графика госрегистрации научных отчётов проекта.</li>
        </ul>
        <p>Фильтры для таблицы:</p>
        <ul>
          <li>
            Фильтр <strong>Без номера госрегистрации</strong> – отображает список проектов, у которых отсутствует номер ЕГИСУ.
          </li>
          <li>
            Фильтр <strong>Без отчётов</strong> - отображает список проектов, у которых отсутствуют отчёты с атрибутом
            «госрегистрация».
          </li>
          <li>
            Фильтр <strong>Зарегистрированные</strong> – отображает список проектов, у которых указан номер и дата госрегистрации
            на вкладке <strong>Госрегистрация</strong>.
          </li>
          <li>
            Фильтр <strong>Год</strong> – отображает список проектов, у которого сроки реализации пересекаются с указанным годом.
            Если фильтр отключен, то все проекты
          </li>
          <li>
            <strong>Без номера госрегистрации + Год</strong> — отображает список актуальных для указанного года проектов, у
            которых отсутствует номер ЕГИСУ.
          </li>
          <li>
            <strong>Без отчётов + Год</strong> — отображает список проектов, у которых не запланированы (отсутствуют) отчёты с
            атрибутом «госрегистрация».
          </li>
          <li>
            <strong>Зарегистрированные + Год</strong> — отображает список проектов, у которых дата регистрации проекта
            пересекается с годом в фильтре
          </li>
          <li>
            <strong>Без номера госрегистрации + Без отчётов + Год</strong> — отображает список актуальных для указанного года
            проектов, у которых отсутствует номер ЕГИСУ
          </li>
        </ul>
        <p>Основные столбцы таблицы содержат данные проекта, названия столбцов соответствуют названиям полей на форме проекта.</p>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с проектом. Открывает форму проекта для просмотра.</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с проектом. Открывает форму проекта для редактирования.</td>
            </tr>
            <tr>
              <td>
                Составить график госрегистрации отчётов проекта: <Icon type="magazine" mode="edit" />
              </td>
              <td>
                Функционал для добавления списка этапов проекта в график госрегистрации отчётов: проставить атрибут
                «Госрегистрация» для этапов, в которых отчёты подлежат госрегистрации.
                <div className="warning">
                  <ul>
                    <li>
                      Дата окончания этапа проекта является ключевой для подсистемы мониторинга подготовки отчёта и соблюдения
                      графика регистрации отчётов.
                    </li>
                    <li>
                      За 30 дней, 10 и 3 дня до окончания этапа на электронную почту и в личный кабинет руководителю и
                      ответственному исполнителю автоматически будут направлено уведомление (при условии, если настроен
                      функциональный блок <strong>Уведомления</strong>).
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                Инструмент предназначен для построения отчёта по шаблону. При нажатии кнопки появится список доступных
                пользователю отчётов (при условии, что у пользователя есть право на группу отчётов).
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

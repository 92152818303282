import { Dispatch, SetStateAction } from 'react';

import { Form } from 'types/models';
import { Participation } from 'types/models/Participation';
import { ParticipationForm } from 'utils/Enums/ParticipationForm';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Participation;
};

const setByResponse = ({ setFormFields, data }: Props) => {
  setFormFields((prevState: Form.Fields) => ({
    ...prevState,
    note: { ...prevState.note, value: data.note },
    publicationReportTheme: {
      ...prevState.publicationReportTheme,
      value: data.publication?.status.id
        ? {
            id: data.publication?.status.id || '',
            name: data.publication?.fields.name || '',
          }
        : null,
    },
    reportTheme: {
      ...prevState.reportTheme,
      value: data.reportTheme,
      required: data.form?.customFields?.code === ParticipationForm.CONFERENCE_WITH_REPORT_PARTICIPATION,
    },
    reportDate: {
      ...prevState.reportDate,
      value: data.reportDate,
    },
    reportType: { ...prevState.reportType, value: data.reportType },
    isPublished: { ...prevState.isPublished, value: data.publication?.status.id ? false : data.isPublished },
  }));
};

export { setByResponse };

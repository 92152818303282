import { convertItem, convertReference, getText } from 'services/BackendAPI/configurations/commonConverters';

import { User } from 'types/models/User';

export function convertUser(user: any): User {
  return {
    fullName: getText(user.FullName),
    isActive: getText(user.IsActive) === 'true',
    isReserved: getText(user.IsActive) === 'true',
    isSuper: getText(user.IsActive) === 'true',
    login: getText(user.Login),
    ...(user.Password ? { password: getText(user.Password) } : {}),
    ...(user.BirthDate ? { birthDate: getText(user.BirthDate) } : {}),
    email: getText(user.EMail),
    shortName: getText(user.ShortName),
    status: convertItem<string>(user.Status),
    userFullName: getText(user.UserFullName),
    id: user?._attributes?.id,
    ...(user.Department ? { department: convertReference(user.Department) } : {}),
    ...(user.Person ? { person: { id: user.Person._attributes.id, name: getText(user.Person.FullName) } } : {}),
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Args = {
  subjectId: string;
};

type RequestData = {
  subjectId: string;
};

export function GetSubjectPublicationList({ subjectId }: Args): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetSubjectPublicationList',
    header: {
      firstLevel: {
        title: 'Список публикаций - результатов выполнения проектов, в рамках которых получены РИДы',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: { subjectId },
  };
}

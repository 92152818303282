import React, { useMemo } from 'react';

import { ButtonProps, Collapse, Toolbar } from 'components';

import { CustomComponentSpecification, Mode, ExtraToolbarButton } from '../model';

type Props<Row> = {
  isExpanded: boolean;
  customEditRow: Row | null;
  selectedRowIndex: number | null;
  isSaveAndAddCustomComponent?: boolean;
  mode: Mode;
  onChange: React.Dispatch<React.SetStateAction<Row | null>>;
  submitCustomComponent(): void;
  saveAndAddCustomComponent?: () => void;
  closeCollapsible(): void;
  renderComponent: CustomComponentSpecification<Row>['renderComponent'];
  extraFieldsToolbarButtons: ExtraToolbarButton<Row>[];
  rows: Row[];
};

function CollapsibleComponent<Row>(props: Props<Row>) {
  const {
    isExpanded,
    customEditRow,
    selectedRowIndex,
    mode,
    saveAndAddCustomComponent,
    isSaveAndAddCustomComponent = false,
    onChange,
    submitCustomComponent,
    closeCollapsible,
    renderComponent,
    extraFieldsToolbarButtons,
    rows,
  } = props;

  const collapsibleButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: submitCustomComponent,
      },
      ...(isSaveAndAddCustomComponent
        ? [
            {
              icon: { type: 'save', mode: 'add' },
              title: 'Сохранить и продолжить',
              onClick: saveAndAddCustomComponent,
            },
          ]
        : []),
      {
        icon: { type: 'cancel' },
        title: 'Отмена',
        onClick: closeCollapsible,
      },
      ...extraFieldsToolbarButtons.map<ButtonProps>(item => ({
        ...item,
        onClick: () => item.onClick(customEditRow, selectedRowIndex, rows),
        isDisabled: typeof item.isDisabled === 'function' ? item.isDisabled?.(customEditRow) : item.isDisabled,
      })),
    ],
    [
      submitCustomComponent,
      customEditRow,
      extraFieldsToolbarButtons,
      closeCollapsible,
      isSaveAndAddCustomComponent,
      saveAndAddCustomComponent,
      selectedRowIndex,
      rows,
    ],
  );

  return (
    <Collapse isExpanded={isExpanded}>
      {isExpanded ? (
        <>
          <Toolbar buttons={collapsibleButtons} mode="list" />
          {renderComponent(customEditRow, onChange, selectedRowIndex, mode)}
        </>
      ) : (
        <></>
      )}
    </Collapse>
  );
}

export { CollapsibleComponent };

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { RecordStatus } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const isEditButtonDisabled = useMemo(() => {
    if (!rowsAvailability.SINGLE_SELECTED) {
      return true;
    }

    const [row] = tableState.selectedRows;
    const { 'id:status': status } = row;
    const isApproved = status === RecordStatus.APPROVED;
    const isAdded = status === RecordStatus.ADDED;

    if (isApproved) {
      return !isHasPermission(userPermission, Permits.RECOGNITION_APPROVED_EDIT);
    }

    if (isAdded) {
      return !isHasPermission(userPermission, Permits.RECOGNITION_ADDED_EDIT);
    }
    return true;
  }, [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows, userPermission]);

  const isDeleteButtonDisabled = useMemo(() => {
    if (!rowsAvailability.SINGLE_SELECTED) {
      return true;
    }

    const [row] = tableState.selectedRows;
    const { 'id:status': status } = row;
    const isApproved = status === RecordStatus.APPROVED;
    const isAdded = status === RecordStatus.ADDED;

    if (isApproved) {
      return !isHasPermission(userPermission, Permits.RECOGNITION_APPROVED_DELETE);
    }

    if (isAdded) {
      return !isHasPermission(userPermission, Permits.RECOGNITION_ADDED_DELETE);
    }

    return true;
  }, [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows, userPermission]);

  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDepartmentsButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
  const isDownloadFileDisabled = !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows[0]?.FileId);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDepartmentsFormOpen, setIsDepartmentsFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isApproveConfirmPopupOpen, setIsApproveConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.Personal, Reports.DepartmentRecognition], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteRecognition',
      deletedItemPropName: 'Recognition',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleApproveButtonClick = useCallback(() => {
    setIsApproveConfirmPopupOpen(true);
  }, []);

  const handleCloseApproveConfirmPopup = useCallback(() => {
    setIsApproveConfirmPopupOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleDepartmentsButtonClick = useCallback(() => {
    setIsDepartmentsFormOpen(true);
  }, []);

  const handleTemplateCloseDepartmentsForm = useCallback(() => {
    setIsDepartmentsFormOpen(false);
  }, []);

  const handleDownloadFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.RECOGNITION_INFO_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.RECOGNITION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь с подразделениями',
        onClick: handleDepartmentsButtonClick,
        permission: { name: Permits.RECOGNITION_DEPARTMENT_EDIT },
        isDisabled: isDepartmentsButtonDisabled,
      },
      {
        icon: { type: 'like' },
        title: 'Утвердить',
        onClick: handleApproveButtonClick,
        permission: { name: Permits.RECOGNITION_APPROVE },
        isDisabled: isApproveButtonDisabled || tableState.selectedRows[0]['id:status'] === 'APPROVED',
      },
      {
        icon: { type: 'download' },
        title: 'Скачать прикрепленный файл',
        onClick: handleDownloadFile,
        isDisabled: isDownloadFileDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isApproveButtonDisabled,
      tableState.selectedRows,
      handleApproveButtonClick,
      isDepartmentsButtonDisabled,
      handleDepartmentsButtonClick,
      isDownloadFileDisabled,
      handleDownloadFile,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    handleCloseApproveConfirmPopup,
    isDepartmentsButtonDisabled,
    isDepartmentsFormOpen,
    handleTemplateCloseDepartmentsForm,
    buttons,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

import { SystemUpdate } from 'types/models';
import { getMaybeField } from '../commonConverters';

export function convertSystemUpdateToXML(systemUpdate: SystemUpdate) {
  return {
    _attr: systemUpdate.id ? { id: systemUpdate.id } : {},
    ...getMaybeField('UpdateDate', systemUpdate.updateDate),
    ...getMaybeField('Detail', systemUpdate.detail),
  };
}

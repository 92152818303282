import React from 'react';

import { Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { MergePerson } from 'features/MergePerson';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function Toolbar({ tableState }: Props) {
  const { buttons, selectedIds, isMergeDuplicatesOpen, handleCloseMergeDuplicatesForm } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <MergePerson personIds={selectedIds} isOpen={isMergeDuplicatesOpen} onClose={handleCloseMergeDuplicatesForm} />
    </>
  );
}

export { Toolbar };

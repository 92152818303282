export function ContestRequestGridHelp(): JSX.Element {
  return (
    <ol>
      <li>
        <p>Роли для пользователей:</p>
        <ul>
          <li>
            Конкурсная комиссия по заявкам на продление проекта и заявкам на актуализацию данных: просмотр заявок, согласование и
            визирование
          </li>
          <li>
            Админ Конкурсной комиссии по заявкам: имеет право просматривать все заявки в любом статусе и приложенные к ним
            документы и управлять статусами заявок
          </li>
        </ul>
      </li>
      <li>Объявления о конкурсе: на странице Новости &rarr; Объявления</li>
      <li>
        Подача заявки на продление завершённого проекта: Личный кабинет пользователя Системы &rarr; вкладка НИОКР &rarr;
        Актуализация моих проектов
      </li>
      <li>Добавить заявку на продление проекта имеет право только руководитель или ответственный исполнитель проекта</li>
      <li>
        Показатели и индикаторы результативности в заявке отображаются из списка актуальных показателей и индикаторов Программы,
        указанной в проекте. Добавленные в Программу индикаторы и показатели будут автоматически отображаться в заявках со
        статусом «Черновик», «Подана на конкурс» «На согласование». Порядок размещения показателей и индикаторов в Заявке зависит
        от значения Код (отсортированы от меньшего к большему)
      </li>
      <li>
        Название статьи затрат и порядок размещения на вкладке Смета зависит от справочника Виды расходов для заявок на продление
        проекта
      </li>
      <li>
        Статусы заявки на продление завершённого проекта: Черновик, Подана на конкурс, На доработку, Согласована с условием,
        Поддержана, Отклонена
      </li>
      <li>Статусы заявки на актуализацию данных этапа: Черновик, На согласование, Согласована</li>
    </ol>
  );
}

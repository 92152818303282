import React, { useCallback, useEffect, useMemo } from 'react';

import { ButtonProps, RelationTableModal, Select } from 'components';

import { Person, PersonHistory } from 'types/models';
import { Item } from 'types/models/common';
import { GetReferenceScientistListSpecification } from 'features/Table/specifications';
import { useController } from './controller';
import { getMockMember } from './helpers';
import { PersonHistoryModal } from './PersonHistoryModal';
import { SubmitTable } from 'features/Table/streams';

export type SelectPersonButton = 'change' | 'delete' | 'history';

export type Member = {
  person: Person.ScientistPerson | null;
} & PersonHistory;

type Props = {
  member: Member | null;
  personId?: string | null;
  onUpdateMember(member: Member | null): void;
  disabled?: boolean;
  withHistory?: boolean;
  memberLabel?: string;
  externalButtons?: ButtonProps[];
  hiddenButtons?: SelectPersonButton[];
};

export type PersonRef = {
  reloadPersonBiblographicNames(): void;
};

function SelectPerson(props: Props) {
  const {
    member,
    onUpdateMember,
    personId,
    withHistory = true,
    disabled = false,
    memberLabel,
    externalButtons = [],
    hiddenButtons = [],
  } = props;

  const {
    isLoadingSuggestions,
    suggestions,
    isOpenHistoryModal,
    closeHistoryModal,
    setSearchPersonValue,
    openHistoryModal,
    resetPerson,
    isOpenPersonListModal,
    isLoadPersonAfterHistoryOpen,
    isLoadingScientist,
    openPersonListModal,
    closePersonListModal,
    loadScientist,
    historyMode,
    setHistoryMode,
  } = useController({ member, onUpdateMember });

  useEffect(() => {
    if (personId && !member?.person) {
      loadScientist(personId, withHistory);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [personId]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'table' },
        title: 'Справочник "Персоны"',
        isDisabled: disabled,
        isHidden: hiddenButtons.includes('change'),
        onClick: event => {
          event?.currentTarget.blur();
          openPersonListModal();
        },
      },
      {
        icon: { type: 'clock' },
        title: 'Персональные данные',
        isHidden: hiddenButtons.includes('history') || !withHistory,
        isDisabled: !member?.person || disabled,
        onClick: () => {
          setHistoryMode('edit');
          openHistoryModal(true);
        },
      },
      ...externalButtons,
    ],
    [
      disabled,
      hiddenButtons,
      withHistory,
      member?.person,
      externalButtons,
      openPersonListModal,
      setHistoryMode,
      openHistoryModal,
    ],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      if (row) {
        loadScientist(row.id, withHistory);
      }
      closePersonListModal();
    },
    [loadScientist, closePersonListModal, withHistory],
  );

  const handleChangePersonSelect = useCallback(
    (option: Item | null) => {
      if (option) {
        loadScientist(option.value, withHistory);
      } else {
        resetPerson();
      }
    },
    [loadScientist, resetPerson, withHistory],
  );

  const submitHitoryModal = useCallback(
    (x: Partial<Member>) => {
      onUpdateMember({ ...(member ?? getMockMember()), ...x });
    },
    [member, onUpdateMember],
  );

  return (
    <>
      <Select
        value={{ value: member?.person?.id || '', label: memberLabel || member?.person?.fullName || '' }}
        options={suggestions.map<Item>(x => ({ value: x.id, label: x.label }))}
        settings={{
          externalButtons: buttons,
          isLoading: isLoadingSuggestions || isLoadingScientist,
          isClearable: !hiddenButtons.includes('delete'),
          noOptionsMessage: 'Для обновления списка, введите более 4х символов',
        }}
        onChange={handleChangePersonSelect}
        onInput={setSearchPersonValue}
        isDisabled={disabled}
      />

      <RelationTableModal
        specification={{ ...GetReferenceScientistListSpecification({ hasSelectButton: true }), onSubmitTable }}
        modalTitle='Справочник "Персоны"'
        isOpen={isOpenPersonListModal}
        onClose={closePersonListModal}
      />

      <PersonHistoryModal
        member={member}
        isLoadPersonAfterOpen={isLoadPersonAfterHistoryOpen}
        isOpen={isOpenHistoryModal}
        onClose={closeHistoryModal}
        onSubmit={submitHitoryModal}
        mode={historyMode}
      />
    </>
  );
}

export { SelectPerson };

import { Table as T } from 'types/models';

import { Toolbar } from './Toolbar';

export type DetailProps = {
  personId?: string;
};

type RequestData = {
  scientistId: string;
};

export function GetScientistRecognitionList({ personId }: DetailProps): T.Specification<RequestData> {
  return {
    apiID: 'GetScientistRecognitionList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Признания и награды',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: { scientistId: personId || '-1' },
  };
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Project, Report, Table } from 'types/models';
import { useReportsHook, Reports } from 'features/BuildReportPopup';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectEditPartialList } from 'features/Table/views';

import { State } from '../makeUseCustomController';
import { getMockFeedline } from '../../GetProjectFeedlineList/helpers';
import { Permits } from 'utils/Permissions';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function useController({ customState, tableState }: Props) {
  const { toggleVisibleForm, setMode } = customState;

  const rows = tableState.selectedRows;
  const tableStreams = useLocalTableStreams();

  const [feedline, setFeedline] = useState<Project.Feedline>(getMockFeedline());

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
  const [isFeedlineOpen, setIsFeedlineOpen] = useState<boolean>(false);
  const [paramType, setParamType] = useState<string>('');
  const [isOpenEditPartial, setIsOpenEditPartial] = useState(false);

  const { methods: saveFeedlineAPI } = BackendAPI.useBackendAPI('SaveProjectUserFeedline');
  const submitFeedline = useCallback(
    (fl: Project.Feedline) => {
      saveFeedlineAPI.callAPI(
        { feedline: fl, projectIds: rows.map(row => row.id) },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
            setFeedline(getMockFeedline());
            setIsFeedlineOpen(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [rows, saveFeedlineAPI, tableStreams.reloadTable],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.AmountFunds_G_26_Control,
      Reports.AmountFundsReceivedSelected_G_26_Control,
      Reports.ProjectResultsDetail,
      Reports.ExtendedAnalytic,
      Reports.AppliedScientificResearchCarried,
      Reports.MonitoringTable_3_2_1,
      Reports.MonitoringTable_3_2_2,
      Reports.Table_3_2_3,
      Reports.Table_3_1_InformationProjectsScientificField,
      Reports.Table_5_1,
      Reports.ResearchDevelopmentCostsForm7,
      Reports.Section8,
      Reports.Section9,
      Reports.Section10,
      Reports.Section11,
      Reports.Section12ScienceDevelopSocial_economic,
      Reports.Sector_4_ImplementationPrioritiesScientificTechnologicalDevelopmentRF,
      Reports.Sector_6_ImplementationPrioritiesScientificTechnologicalDevelopmentRF,
      Reports.AnnualDissertationCouncils,
      Reports.NIRPlanByPeriod,
      Reports.NIOKRInvestmentSourceByYearAndDepartment,
      Reports.NIOKRInvestmentSourceByYear,
      Reports.ProjectCoexecutors,
      Reports.ByDissertationCouncil,
      Reports.NiokrListM,
      Reports.NiokrList,
      Reports.InternationalProgramPlan,
      Reports.InternationalProgramFact,
      Reports.ProgramIndicators,
      Reports.Other2030Projects,
      Reports.BankWarranty,
      Reports.Engineering_1_1_EngineeringServices,
      Reports.Engineering_1_2_EngineeringServices,
      Reports.Engineering_1_3_EngineeringServicesProvided,
      Reports.CostPayingOrganizationsInvolved,
      Reports.AppliedScientificResearchCarried,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleViewButtonClick = useCallback(() => {
    setMode('view');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const handleEditButtonClick = useCallback(() => {
    setMode('edit');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        isDisabled: !tableState.selectedRows[0],
        onClick: handleViewButtonClick,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        isDisabled: !tableState.selectedRows[0],
        onClick: handleEditButtonClick,
      },
      {
        icon: { type: 'documents', mode: 'edit' },
        title: 'Редактировать группу проектов',
        expandedList: {
          list: () => ProjectEditPartialList,
          callback: (params: { value: string }) => {
            setParamType(params.value);
            setIsOpenEditPartial(true);
          },
        },
        permission: {
          name: Permits.RESEARCH_JOB_EDIT,
        },
        isDisabled: rows.length < 1,
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: () => setIsFeedlineOpen(true),
        isDisabled: !rows.length,
      },
      {
        icon: { type: 'clock' },
        title: 'История изменений',
        onClick: () => setIsOpenCodeChangesModal(true),
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleEditButtonClick, handleSetCurrentReport, handleViewButtonClick, rows.length, tableState.selectedRows],
  );

  return {
    rows,
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isOpenCodeChangesModal,
    setIsOpenCodeChangesModal,
    currentReport,
    isReportOpen,
    onReportClose,
    isFeedlineOpen,
    setIsFeedlineOpen,
    feedline,
    setFeedline,
    submitFeedline,
    paramType,
    isOpenEditPartial,
    setIsOpenEditPartial,
  };
}

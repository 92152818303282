import { QnaQuestion, Theme, CreatedBy, ModifiedBy } from 'types/models';
import { QnaFrequentQuestion } from 'types/models/Qna';
import { convertFile, convertMaybeArray, getText } from '../commonConverters';

function convertQnaQuestion(serverQnaQuestion: any): QnaQuestion {
  return {
    id: serverQnaQuestion?._attributes?.id ? serverQnaQuestion._attributes.id : null,
    theme: convertTheme(serverQnaQuestion.Theme),
    message: serverQnaQuestion?.Message ? getText(serverQnaQuestion.Message) : '',
    answer: serverQnaQuestion?.Answer ? getText(serverQnaQuestion.Answer) : '',
    questionFile: convertFile(serverQnaQuestion.QuestionFile),
    answerFile: convertFile(serverQnaQuestion.AnswerFile),
    questionCreatedBy: convertCreateBy(serverQnaQuestion.QuestionCreatedBy),
    questionCreatedDate: serverQnaQuestion?.QuestionCreatedDate ? getText(serverQnaQuestion.QuestionCreatedDate) : '',
    questionModifiedBy: convertModifiedBy(serverQnaQuestion.QuestionCreatedBy),
    questionModifiedDate: serverQnaQuestion?.ModifiedDate ? getText(serverQnaQuestion.QuestionModifiedDate) : '',
    answerCreatedBy: convertCreateBy(serverQnaQuestion.AnswerCreatedBy),
    answerCreatedDate: serverQnaQuestion?.QuestionCreatedDate ? getText(serverQnaQuestion.AnswerCreatedDate) : '',
    answerModifiedBy: convertModifiedBy(serverQnaQuestion.AnswerCreatedBy),
    answerModifiedDate: serverQnaQuestion?.ModifiedDate ? getText(serverQnaQuestion.AnswerModifiedDate) : '',
  };
}

function convertTheme(serverTheme: any): Theme {
  return {
    id: serverTheme?._attributes?.id ? serverTheme._attributes.id : null,
    label: serverTheme?._attributes?.label ? serverTheme?._attributes?.label : '',
    editable: serverTheme?._attributes?.editable === 'true',
    deletable: serverTheme?._attributes?.editable === 'false',
  };
}

function convertCreateBy(serverCreatedBy: any): CreatedBy {
  return {
    id: serverCreatedBy?._attributes?.id ? serverCreatedBy._attributes?.id : null,
  };
}

function convertModifiedBy(serverModifiedBy: any): ModifiedBy {
  return {
    id: serverModifiedBy?._attributes?.id ? serverModifiedBy._attributes.id : null,
  };
}

function convertServerQnaTag(serverTag: any): string {
  return serverTag ? getText(serverTag) : '';
}

export function convertQnaFrequentQuestion(serverFrequentQuestion: any): QnaFrequentQuestion {
  return {
    id: serverFrequentQuestion?._attributes?.id ? serverFrequentQuestion._attributes.id : null,
    theme: convertTheme(serverFrequentQuestion.Theme),
    message: serverFrequentQuestion?.Message ? getText(serverFrequentQuestion.Message) : '',
    answer: serverFrequentQuestion?.Answer ? getText(serverFrequentQuestion.Answer) : '',
    priority: serverFrequentQuestion?.Priority ? parseInt(getText(serverFrequentQuestion.Priority), 10) : -1,
    hashtags: convertMaybeArray(serverFrequentQuestion?.Tags?.Tag ?? [], convertServerQnaTag),
    createdBy: convertCreateBy(serverFrequentQuestion.CreatedBy),
    createdDate: serverFrequentQuestion?.CreatedDate ? getText(serverFrequentQuestion.CreatedDate) : '',
    modifiedBy: convertModifiedBy(serverFrequentQuestion.QuestionCreatedBy),
    modifiedDate: serverFrequentQuestion?.ModifiedDate ? getText(serverFrequentQuestion.QuestionModifiedDate) : '',
  };
}

export function convertServerQnaQuestion(serverQnaQuestion: any): QnaQuestion {
  return convertQnaQuestion(serverQnaQuestion);
}

export function convertServerQnaFrequentQuestion(serverQnaFrequentQuestion: any): QnaFrequentQuestion {
  return convertQnaFrequentQuestion(serverQnaFrequentQuestion);
}

export function convertServerQnaFrequents(serverQnaQuestions: any): QnaFrequentQuestion[] {
  return convertMaybeArray(serverQnaQuestions.Response.QnaFrequentQuestion ?? [], convertServerQnaFrequentQuestion);
}

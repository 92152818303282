import { CommissionMember, ProtocolCommission } from 'types/models/ProtocolCommission';
import { getMockPersonHistory } from 'features/Form/looks/mobileRequest/MobileRequestForm/helpers';
import { getMockPerson } from '../../person/helpers';

export function getMockProtocolCommission(): ProtocolCommission {
  return {
    id: null,
    name: null,
    orderNumber: null,
    orderDate: null,
    note: null,
    members: [],
  };
}
export function getMockCommissionMember(): CommissionMember {
  return {
    ...getMockPersonHistory(),
    person: getMockPerson({}),
    id: '',
    role: null,
    priority: 1,
    protocolName: null,
  };
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

type Props = {
  saveForm?(): void;
};

export function useController(props: Props) {
  const { saveForm } = props;
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: saveForm,
        isDisabled: saveForm === undefined,
      },
      {
        icon: { type: 'admin' },
        title: 'Написать сообщение администратору',
        onClick: handleHelpButtonClick,
      },
    ],
    [saveForm, handleHelpButtonClick],
  );

  return {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
  };
}

import { Table } from 'types/models';
import { SubjectType } from 'types/models/Subject';

type RequestData = {
  subjectType?: SubjectType;
};

export function GetSubjectListForSelect({ subjectType }: RequestData): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetSubjectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    requestData: { subjectType },
  };
}

import React, { useMemo } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentType } from 'utils/Enums';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { isPersonalMode } = customState;

  const { enumMap } = useAppDataContext();

  const securityDocumentsList = useMemo(
    () =>
      enumMap.SecurityDocumentType
        ? [
            ...enumMap.SecurityDocumentType.values
              .filter(x => (isPersonalMode ? x.value !== SecurityDocumentType.KNOW_HOW : true))
              .map(doc => ({ label: doc.label, value: doc.value as SecurityDocumentType })),
            ...[{ label: 'Все ОИС', value: '' }],
          ]
        : [],
    [enumMap.SecurityDocumentType, isPersonalMode],
  );

  return FiltersComponent(
    [
      {
        type: FilterComponentType.checkbox,
        streamKey: 'isActive',
        label: 'Действующие',
        checked: false,
      },
      {
        type: FilterComponentType.divider,
      },
      {
        type: FilterComponentType.radio,
        streamKey: 'documentsFilter',
        list: securityDocumentsList,
        value: securityDocumentsList[0]?.value || 'PATENT',
      },
    ],
    isPersonalMode ? 'personal' : 'default',
  );
});

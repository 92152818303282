import { SaveExponentRequest } from 'services/BackendAPI/configurations/exponents/types';
import { convertAuthorToXML } from 'services/BackendAPI/configurations/rntd/requestConverters';

export function convertExponentToXml(exp: SaveExponentRequest) {
  return {
    _attr: exp.id ? { id: exp.id } : {},
    Name: exp.name,
    Type: exp.type?.id ? { _attr: { id: exp.type.id } } : {},
    ...(exp?.subject?.id ? { Subject: { _attr: { id: exp.subject.id } } } : {}),
    Authors: exp.authors && exp.authors?.length ? { Author: exp.authors.map(convertAuthorToXML) } : {},
    Partners: exp.partners && exp.partners?.length ? { Partner: exp.partners.map(convertPartnerToXML) } : {},
    Note: exp.note,
  };
}

export const convertPartnerToXML = (p: any) => ({
  _attr: p.id ? { id: p.id } : {},
});

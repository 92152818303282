import React, { useState } from 'react';

import {
  FormComponent,
  ListEditTable,
  Radio,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';
import useController from './controller';
import { ProjectType } from 'utils/Enums';

import './style.scss';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
  projectType: ProjectType;
};

export function FeaturesProject(props: Props) {
  const { disabled, project, projectType, makeChangeHandler } = props;
  const { onChangeIsRequestProvision, onChangeIsContractProvision } = useController({ makeChangeHandler });

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  return (
    <>
      <SectionTitle
        title="Коды целевых статей расходов"
        info={
          <p>
            Значения из справочников университета, которые служат для планирования расходов хозяйственной деятельности
            университета.
          </p>
        }
      />

      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Вид деятельности">
              <Select
                mode={SelectMode.REFERENCE}
                value={project?.activityKind}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('activityKind')(option)}
                settings={{ name: 'RefOkved', title: 'Справочник "Виды деятельности"', isClearable: true }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="ОКВЭД по доходам">
              <Select
                mode={SelectMode.REFERENCE}
                value={project?.okvedIncome}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('okvedIncome')(option)}
                settings={{ name: 'RefOkvedIncome', title: 'Справочник "ОКВЭД по доходам"', isClearable: true }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Расход бюджета">
              <Select
                mode={SelectMode.REFERENCE}
                value={project?.expendituresBudgets}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('expendituresBudgets')(option)}
                settings={{
                  name: 'RefClassificationExpendituresBudgets',
                  title: 'Справочник "Классификации расходов бюджета"',
                  isClearable: true,
                }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Код расходов Нацпроекта">
              <Select
                mode={SelectMode.REFERENCE}
                value={project?.nationalProjectExpenseCode}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('nationalProjectExpenseCode')(option)}
                settings={{
                  name: 'RefNationalProjectExpenseCode',
                  title: 'Справочник "Коды расходов нацпроекта"',
                  isClearable: true,
                }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <SectionTitle
        title="Нормативный процент накладных расходов"
        info={
          <p>
            Доля накладных расходов от объёма финансирования проекта по условиям договора. Учитывается при составлении сметы и
            расчёта планового и фактического объёма <strong>Фонда накладных расходов</strong>.
          </p>
        }
      />

      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="Нормативный процент накладных расходов">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={project?.overheadPercent}
            onChange={makeChangeHandler('overheadPercent')}
            isDisabled={disabled}
            classMixin="overhead-percent"
          />
        </FormComponent.Field>
        <FormComponent.Field label="Примечание">
          <TextInput value={project?.financialNote} onChange={makeChangeHandler('financialNote')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      {projectType !== ProjectType.NIR && (
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <SectionTitle title="Обеспечение контракта" />

              <FormComponent.Line>
                <FormComponent.Field label="">
                  <Radio
                    isDisabled={disabled}
                    value={String(project?.isContractProvision)}
                    list={[
                      { label: 'Да', value: 'true' },
                      { label: 'Нет', value: 'false' },
                    ]}
                    onChange={(value: string) => onChangeIsContractProvision(value === 'true')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Сумма">
                  <TextInput
                    mode={TextInputMode.NUMBER}
                    value={project?.contractProvisionAmount || '0'}
                    onChange={makeChangeHandler('contractProvisionAmount')}
                    isDisabled={disabled || !project?.isContractProvision}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Вид обеспечения">
                  <Select
                    mode={SelectMode.ENUM}
                    value={project?.contractProvisionType}
                    settings={{ name: 'ProvisionType' }}
                    onChange={(option: Item) => makeChangeHandler('contractProvisionType')(option as ValueOf<Project.Project>)}
                    isDisabled={disabled || !project?.isContractProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.MODAL}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={project?.contractProvisionNote ?? ''}
                    onChange={makeChangeHandler('contractProvisionNote')}
                    isDisabled={disabled || !project?.isContractProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>

            <FormComponent.Column>
              <SectionTitle title="Обеспечение заявки" />

              <FormComponent.Line>
                <FormComponent.Field label="">
                  <Radio
                    isDisabled={disabled}
                    value={String(project?.isRequestProvision)}
                    list={[
                      { label: 'Да', value: 'true' },
                      { label: 'Нет', value: 'false' },
                    ]}
                    onChange={(value: string) => onChangeIsRequestProvision(value === 'true')}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Сумма">
                  <TextInput
                    mode={TextInputMode.NUMBER}
                    value={project?.requestProvisionAmount || '0'}
                    onChange={makeChangeHandler('requestProvisionAmount')}
                    isDisabled={disabled || !project?.isRequestProvision}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Вид обеспечения">
                  <Select
                    mode={SelectMode.ENUM}
                    value={project?.requestProvisionType}
                    settings={{ name: 'ProvisionType' }}
                    onChange={(option: Item) => makeChangeHandler('requestProvisionType')(option as ValueOf<Project.Project>)}
                    isDisabled={disabled || !project?.isRequestProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.MODAL}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={project?.requestProvisionNote ?? ''}
                    onChange={makeChangeHandler('requestProvisionNote')}
                    isDisabled={disabled || !project?.isRequestProvision}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
      )}

      <SectionTitle
        title="Распределение средств проекта по шифрам бухгалтерского учёта"
        info={
          <>
            <p>
              содержит информацию плановом объёме, текущих расходах <strong>шифра проекта</strong> в соответствии с данными
              бухгалтерского учёта.
            </p>
            <p>
              Для проекта в системе бухгалтерского учёта может быть заведено несколько шифров (финансово-лицевых счетов) при
              условии:
            </p>

            <ul>
              <li>если проект имеет софинансирование, то для каждого источника финансирования свой шифр;</li>
              <li>
                если проект выполняется смешанным коллективом (несколько подразделений), то для ведения раздельного учёта для
                каждого подразделения заводится шифр с выделением доли от объёма финансирования.
              </li>
            </ul>
          </>
        }
      />

      <ListEditTable
        rows={project?.projectCodes ?? []}
        defaultRowsCount={10}
        columns={[
          { label: 'Шифр', formatValue: x => x.code ?? '' },
          { label: 'Факультет/Институт', formatValue: x => x.department?.governance?.label ?? '' },
          { label: 'Подразделение', formatValue: x => x.department?.unit?.label ?? '' },
          { label: 'Источник финансирования', formatValue: x => x.financing?.source?.label ?? '' },
          { label: 'Год', formatValue: x => x.year ?? '' },
          {
            label: 'Сумма план',
            formatValue: x => x.incomePlanAmount ?? '0',
            dataKind: 'float',
          },
          {
            label: 'Поступления факт',
            formatValue: x => x.incomeFactAmount ?? '0',
            dataKind: 'float',
          },
          {
            label: 'Расходы, план',
            formatValue: x => x.expensePlanAmount ?? '0',
            dataKind: 'float',
          },
          {
            label: 'Расходы, факт',
            formatValue: x => x.expenseFactAmount ?? '0',
            dataKind: 'float',
          },
          {
            label: 'В т.ч. ЦФ',
            formatValue: x => x.accompliceAmount ?? '0',
            dataKind: 'float',
          },
          {
            label: 'ЦФ %',
            formatValue: x => x.overheadPercent ?? '0',
            dataKind: 'float',
          },
        ]}
        selectedRowIndex={selectedRowIndex}
        selectRow={setSelectedRowIndex}
        columnIndexesForSumTotal={[5, 6, 7, 8, 9, 10]}
      />
    </>
  );
}

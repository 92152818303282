import React, { useEffect } from 'react';

import { FormComponent, Tab, Tabs, Toolbar } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import { GetProjectAssignmentMessageList } from 'features/Table/specifications/GetProjectAssignmentMessageList';
import { Component as AboutProjectAssignment } from './Tabs/AboutProjectAssignment';
import { MessageModal } from 'features/Form/views/MessageModal';
import { submitTextMap, titleTextMap } from 'features/Table/specifications/GetProjectAssignmentList/Toolbar';
import { FileInfo, Form } from 'types/models';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectAssignmentForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle },
  } = useFormContext<Form.Look & { hasPersonalMode: boolean }>();

  const {
    projectAssignment,
    projectAssignmentId,
    project,
    buttons,
    workMode,
    makeChangeHandler,
    projectSpecification,
    projectTableRowConverter,
    handleProjectChange,
    isChangeStatusModalOpen,
    setIsChangeStatusModalOpen,
    handleSaveStatusClick,
    newStatus,
    isMessageListShown,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const submitsMap: Record<string, (message: string, file: FileInfo) => void> = {
    IN_ACTION: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
    CLOSED: message => handleSaveStatusClick(message),
    CANCELED: message => handleSaveStatusClick(message),
  };

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return 'Добавление Поручения';
        }

        return getStatusMessage(
          [
            viewMode ? 'Просмотр' : 'Редактирование',
            'Поручения',
            project ? `по проекту №${project.number}, ${project.startDate} - ${project.endDate}` : '',
          ]
            .filter(Boolean)
            .join(' '),
          projectAssignment?.id || '',
          projectAssignment?.status?.label,
        );
      })(),
    );
  }, [projectAssignment, project, setTitle, workMode, viewMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Поручение">
            <AboutProjectAssignment
              workMode={workMode}
              projectAssignment={projectAssignment}
              project={project}
              handleProjectChange={handleProjectChange}
              projectTableRowConverter={projectTableRowConverter}
              makeChangeHandler={makeChangeHandler}
              projectSpecification={projectSpecification}
              onChangeFile={makeChangeHandler('file')}
            />
          </Tab>
          <Tab title="Сообщения по поручению">
            {isMessageListShown && (
              <DataGrid
                specification={GetProjectAssignmentMessageList({
                  projectAssignmentId,
                  disabled: workMode !== 'editMode',
                })}
              />
            )}
          </Tab>
        </Tabs>
        <MessageModal
          isOpen={isChangeStatusModalOpen}
          onClose={() => setIsChangeStatusModalOpen(false)}
          onSubmit={submitsMap[newStatus.toString()]}
          titleText={titleTextMap[newStatus.toString()]}
          submitText={submitTextMap[newStatus.toString()]}
          hasFileAttachment={newStatus === 'MESSAGE'}
        />
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectAssignmentForm);

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetReferences(): Table.Specification<State, object> {
  return {
    apiID: 'GetReferences',
    isCanSelectOnlyOneRow: true,
    useCustomController: makeUseCustomController(),
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
  };
}

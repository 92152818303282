import React, { useCallback, useEffect } from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ParticipationHeader } from 'features/Form/views/ModalHeader/participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Permits } from 'utils/Permissions/Permits';
import { Component as SimilarParticipations } from '../views/SimilarParticipations';
import { EditParticipationDepartments } from '../views/EditParticipationDepartments';
import { ControlPanel } from '../views/ControlPanel';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { getEventFullName } from 'utils/Helpers/event';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
};

const ParticipationFormConference = ({ onClose }: Props) => {
  const {
    isLoading,
    isProfile,
    mainReporterName,
    participationData,
    formFields,
    participationId,
    relatedTableState,
    workMode,
    documents,
    mobileRequests,
    awards,
    event,
    publication,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    isReportPopupOpened,
    isProjectReportPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    tabsId,
    disabledEvent,
    isSimilarParticipationOpen,
    eventPublications,
    financingProjects,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    setFinancingProjectsCb,
    setMobileRequestsCb,
    onUpdatePublicationReportTheme,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    setDocuments,
    setAwards,
    setEvent,
    onChangeIsPublished,
    setIsEventSelectWarningOpen,
    changeMembers,
    handleFormSubmit,
    goToDocumentsAction,
    goToReportAction,
    confirmDocumentPopup,
    closeReportPopup,
    goToProjectReportAction,
    closeProjectReportAction,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    handleCloseSimilarParticipation,
    acceptSimilarParticipation,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    sourcePublicationId,
    isOpenBySourcePublication,
    onCloseBySourcePublication,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onCloseAuthorsPopup,
    onResetAuthorsPopup,
    isConferenceWithReportParticipation,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();

  const isVisibleTab = useCallback(() => {
    if (!participationData?.isUserOwned) {
      return isHasPermission(userPermission, [
        Permits.PARTICIPATION_CONFERENCE_EDIT_ALL,
        Permits.PARTICIPATION_CONFERENCE_PROJECTS_ACCEPTING,
        Permits.PARTICIPATION_CONFERENCE_PROJECTS_VIEW_ALL,
      ]);
    }

    return true;
  }, [participationData?.isUserOwned, userPermission]);

  useEffect(() => {
    setTitle?.(
      ParticipationHeader({
        workMode,
        departments: participationData?.departments || [],
        status: participationData?.status.label || '',
        participationId: participationId || '',
        participationTypeCode: ParticipationTypes.PARTICIPATION_CONFERENCE.code,
      }),
    );
  }, [participationData?.departments, participationData?.status.label, participationId, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        permits={{
          approve: Permits.PARTICIPATION_CONFERENCE_APPROVE,
          edit: Permits.PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT,
        }}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE}
        isDisabled={isLoading}
        addCallback={handleFormSubmit}
        isProfile={isProfile}
        workMode={workMode}
        id={participationId || ''}
        isApprovePopupOpen={isApprovePopupOpen}
        handleOpenApprovePopup={handleOpenApprovePopup}
        handleCloseApprovePopup={handleCloseApprovePopup}
        isEditDepartmentsOpen={isEditDepartmentsOpen}
        openEditDepartmentsForm={openEditDepartmentsForm}
        closeEditDepartmentsForm={closeEditDepartmentsForm}
        afterSubmitDepartmentsForm={afterSubmitDepartmentsForm}
        afterSubmitApproveParticipation={afterSubmitApproveParticipation}
        isOnlyEditing={
          workMode === 'addMode' &&
          !isHasPermission(userPermission, [
            Permits.PARTICIPATION_CONFERENCE_EDIT_ADDED_MY,
            Permits.PARTICIPATION_CONFERENCE_EDIT_ALL,
          ])
        }
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="Описание">
            <TabsContent.AboutComponent
              onUpdatePublicationReportTheme={onUpdatePublicationReportTheme}
              mainReporterName={mainReporterName}
              workMode={workMode}
              onChangeIsPublished={onChangeIsPublished}
              formFields={formFields}
              event={event}
              publication={publication}
              setEvent={setEvent}
              relatedTableState={relatedTableState}
              members={members}
              changeMembers={changeMembers}
              disabledEvent={disabledEvent}
              eventPublications={eventPublications}
              sourcePublicationId={sourcePublicationId}
              isDisabledPublicationInfo={sourcePublicationId !== null && !!sourcePublicationId}
              isConferenceWithReportParticipation={isConferenceWithReportParticipation}
              awards={awards}
            />
          </Tab>
          <Tab title="Награды">
            <TabsContent.Awards workMode={workMode} members={members} awards={awards} setAwards={setAwards} />
          </Tab>
          <Tab title="Связи с проектами" isVisible={isVisibleTab()}>
            <TabsContent.FinancialSupportComponent
              workMode={workMode}
              relatedTableState={relatedTableState}
              financingProjects={financingProjects}
              setProjects={setFinancingProjectsCb}
              mobileRequests={mobileRequests}
              setMobileRequests={setMobileRequestsCb}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.DocumentsComponent documents={documents} setDocuments={setDocuments} workMode={workMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              participationId={participationId}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <EditParticipationDepartments
        id={participationId || ''}
        isOpen={isEditDepartmentsToAddedFormOpen}
        onClose={closeEditDepartmentsToAddedForm}
        afterSubmit={afterSubmitEditDepartmentsToAddedForm}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        participantsTitle="Список участников:"
        hideToolbarButtons
        hideActionButtons={false}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isEventSelectWarningOpen}
        onClose={() => setIsEventSelectWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsEventSelectWarningOpen(false),
          },
        ]}
        size="small"
      >
        <>Не выбрано мероприятие.</>
      </Modal>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isReportPopupOpened}
        onClose={closeReportPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Вернуться к докладу',
            onClick: goToReportAction,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: closeReportPopup,
          },
        ]}
        size="small"
      >
        <>Сведения о Типе доклада, Дате и Основном докладчике являются обязательными для доклада.</>
      </Modal>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isDocumentsPopupOpened}
        onClose={goToDocumentsAction}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmDocumentPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Перейти на вкладку документы',
            onClick: goToDocumentsAction,
          },
        ]}
        size="small"
      >
        <>
          На вкладке Документы прикрепите скан-копию программы мероприятия или файл с документом подтверждающим участие.
          <br />
          <strong>Наличие прикрепленного документа - это обязательное условие для утверждения участия</strong>
        </>
      </Modal>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isProjectReportPopupOpened}
        onClose={closeProjectReportAction}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Вернуться к докладу',
            onClick: goToProjectReportAction,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: closeProjectReportAction,
          },
        ]}
        size="small"
      >
        <>
          При выбранном научном проекте. Сведения о Типе доклада, Дате и Основном докладчике являются обязательными для доклада.
        </>
      </Modal>

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isAdminMessagePopupOpen}
        onConfirm={() => {
          saveForm(true);
        }}
        publicationMessage={participationMessage}
        updatePublicationMessage={setParticipationMessage}
        publicationMessageType={participationMessageType}
        setPublicationMessageType={setParticipationMessageType}
        onConfirmWithoutMessage={() => {
          saveForm(false);
        }}
        command="RefParticipationFeedlineMessage"
      />

      <SimilarParticipations
        eventId={event?.id || ''}
        eventStatus={event?.status?.label || ''}
        eventName={getEventFullName(event)}
        workMode={workMode}
        type={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        isOpen={isSimilarParticipationOpen}
        onClose={handleCloseSimilarParticipation}
        onAccept={acceptSimilarParticipation}
      />

      <Modal
        mode="info"
        title="Создать запись об участии в конференции на основе метаданных публикации"
        isOpen={isOpenBySourcePublication}
        onClose={onCloseBySourcePublication}
        actions={[
          {
            mode: ButtonMode.SECONDARY,
            text: 'Закрыть',
            onClick: onCloseBySourcePublication,
          },
        ]}
        size="medium"
      >
        <p>
          Уточните список участников конференции, укажите основного докладчика, добавьте сведения о наградах и файл с приглашением
          или программой конференции, в которой есть информация о докладе.
        </p>
        <p>
          Если участие в конференции - в планах работ по научному проекту или при поддержке Программы академической мобильности,
          то укажите связь с проектом на вкладке Финансовая поддержка
        </p>
      </Modal>

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={onCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: onConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: onResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <div>
          <strong>
            В списке «Мои участия», отображаются только те записи об участии, в которых вы указаны в Сведениях об участниках
            конференции, иначе отобразится в списке «Все участия»
          </strong>
        </div>
        <p>Вашей персоны нет в списке участников конференции. Добавить?</p>
      </Modal>
    </FormComponent.Template>
  );
};

export const Component = React.memo(ParticipationFormConference);

import { parse } from 'date-fns';

import { SecurityDocument, SecurityDocumentMember, Balance } from 'types/models/SecurityDocument';
import { formatStr } from 'utils/Constants';
import { Settings } from 'types/models/common';
import { convertSecurityDocumentContract } from 'services/BackendAPI/configurations/securityDocumentContract/converters';
import { convertSecurityDocumentPayment } from 'services/BackendAPI/configurations/securityDocumentPayment/converters';

import {
  convertMaybeArray,
  convertReference,
  getId,
  getText,
  convertItem,
  convertDocument,
  getBoolean,
} from '../commonConverters';
import { convertProject, convertRefElements } from '../project/converters';
import { convertServerPerson } from '../author/converters';
import { convertDepartment, convertPersonHistory } from '../converters';

export function convertSecurityDocument(p: any, settings: Settings): SecurityDocument {
  const type = convertItem<string>(p.Type);
  return {
    id: getId(p),
    label: getText(p.Label),
    contracts: convertMaybeArray(p.Contracts?.Contract, c => convertSecurityDocumentContract(c, settings)),
    balanceOrderNumber: parseFloat(getText(p.BalanceOrderNumber)),
    loDate: getText(p.BalanceLoDate),
    hiDate: getText(p.BalanceHiDate),
    balancePrice: parseFloat(getText(p.BalancePrice)),
    documentNumber: getText(p.DocumentNumber),
    internalNumber: getText(p.InternalNumber),
    documentStartDate: getText(p.DocumentStartDate),
    documentEndDate: getText(p.DocumentEndDate),
    stateNumber: getText(p.StateNumber),
    isRus: getText(p.IsRus) === 'true',
    isWorldwide: getText(p.IsWorldwide) === 'true',
    stateRegistrationDate: getText(p.StateRegistrationDate),
    ...(p.RequestMpk ? { mpk: convertReference(p.RequestMpk) } : {}),
    knowledgeBranches: convertRefElements(p.KnowledgeBranches?.KnowledgeBranch || []),
    grntis: convertRefElements(p.Grntis?.Grnti || []),
    kind: p.Kind ? convertReference(p.Kind) : null,
    intellectualPropertyName: getText(p.IntellectualPropertyName),
    referat: getText(p.Referat),
    lksetss: convertRefElements(p.Lksetss?.Lksets || []),
    members: convertMaybeArray(p.Members?.Member, m => convertSecurityDocumentMember(m, settings)).sort(
      (a, b) => parseFloat(a?.id || '0') - parseFloat(b?.id || '0'),
    ),
    countries: convertMaybeArray(p.Countries?.Country || [], convertReference),
    ntiEteTechnologies: convertRefElements(p.NtiEteTechnologies?.NtiEteTechnology || []),
    ntiMarkets: convertRefElements(p.NtiMarkets?.NtiMarket || []),
    ntrStrategies: convertRefElements(p.NtrStrategies?.NtrStrategy || []),
    oecds: convertRefElements(p.Oecds?.Oecd || []),
    okveds: convertRefElements(p.Okveds?.Okved || []),
    payments: convertMaybeArray(p.Payments?.Payment, convertSecurityDocumentPayment).sort(
      (a, b) =>
        parse(a?.payBeforeDate || '', formatStr, new Date()).getTime() -
        parse(b?.payBeforeDate || '', formatStr, new Date()).getTime(),
    ),
    pnis: convertRefElements(p.Pnis?.Pni || []),
    pnmitrs: convertRefElements(p.Pnmitrs?.Pnmitr || []),
    pnrs: convertRefElements(p.Pnrs?.Pnr || []),
    priorityTechnologies: convertRefElements(p.PriorityTechnologies?.PriorityTechnology || []),
    project: p.Project ? convertProject(p.Project, settings) : null,
    requestDate: getText(p.RequestDate),
    requestSendDate: getText(p.RequestSendDate),
    documentDate: getText(p.DocumentDate),
    documentReceiveDate: getText(p.DocumentReceiveDate),
    requestNumber: getText(p.RequestNumber),
    scienceBrunches: convertRefElements(p.ScienceBrunches?.ScienceBrunch || []),
    scienceDomainInterrests: convertRefElements(p.ScienceDomainInterrests?.ScienceDomainInterrest || []),
    vakSpecialities: convertRefElements(p.Vaks?.Vak || []),
    technologyPlatforms: convertRefElements(p.TechnologyPlatforms?.TechnologyPlatform || []),
    udks: convertRefElements(p.Udks?.Udk || []),
    type,
    departments: [
      ...(p.Departments && p.Departments.Department ? convertMaybeArray(p.Departments.Department, convertDepartment) : []),
    ],
    documents: [
      ...(p.Documents && p.Documents.Document ? convertMaybeArray(p.Documents.Document, i => convertDocument('local', i)) : []),
    ],
    mpks: convertRefElements(p.Mpks?.Mpk || []),
    isRejected: getBoolean(p.IsRejected),
    balances: convertMaybeArray(p.Balances?.Balance || [], convertBalance),
  };
}

function convertBalance(balance: any): Balance {
  return {
    id: getId(balance),
    date: getText(balance.Date),
    amount: getText(balance.Amount),
    alphaAmount: getText(balance.AlphaAmount),
    residualAmount: getText(balance.ResidualAmount),
  };
}

export function convertSecurityDocumentMember(m: any, settings: Settings): SecurityDocumentMember {
  const person = m.Person || m.Scientist ? convertServerPerson(m.Person ?? m.Scientist, settings) : null;

  return {
    id: getId(m),
    contribution: parseFloat(getText(m.Contribution)),
    type: convertItem(m.Type),
    name: getText(m.Name),
    person,
    contractNumber: getText(m.ContractNumber),
    contractDate: getText(m.ContractDate),
    contractPortion: getText(m.ContractPortion),
    contractNote: getText(m.ContractNote),
    enterprise: convertReference(m.Enterprise),
    ...convertPersonHistory(m),
  };
}

import { Table } from 'types/models';

type RequestData = {
  projectId: string;
};

export function GetProjectStageList(requestData: RequestData): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetProjectStageList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        hasSelectButton: true,
      },
    },
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetSecurityDocumentContractList(): Table.Specification {
  return {
    apiID: 'GetSecurityDocumentContractList',
    header: {
      firstLevel: {
        title: 'Договоры о распоряжении интеллектуальным правом',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { Fields } from './Fields';
import useController from './controller';
import { validate } from './validate';
import { Color } from 'constants/colors';
import { JobStatus } from 'utils/Enums';
import { MergePersonCollection } from 'features/MergePersonCollection';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';

export type Mode = 'person' | 'lk' | 'history';

type Props = {
  mode?: Mode;
  person: Person.ScientistPerson | null;
  isAddMode?: boolean;
  onSelect?(jobs: Person.ScientistJob): void;
  onChangeJobs(jobs: Person.ScientistJob[]): void;
  onUpdate?: () => void;
  disabled: boolean;
};

export const EditJobs = React.memo((props: Props) => {
  const { mode, person, isAddMode, onSelect, onChangeJobs, onUpdate, disabled } = props;

  const {
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedJob,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    jobIds,
    selectedJobs,
  } = useController({
    mode,
    person,
    onChangeJobs,
  });

  return (
    <>
      <ListEdit<{}, Person.ScientistJob>
        header={{ isRequired: true }}
        rows={person?.scientistJobs ?? []}
        onChange={onChangeJobs}
        toolbar={
          (isAddMode || !disabled) && [
            {
              icon: { type: 'check' },
              title: 'Выбрать',
              onClick: (job: Person.ScientistJob | null) => {
                if (job) {
                  onSelect?.(job);
                }
              },
              isDisabled: (job: Person.ScientistJob | null) => !job?.id || selectedJobs?.length !== 1,
              isHidden: mode !== 'history',
            },
            'add',
            {
              key: 'edit',
              ...(mode === 'lk' && {
                isDisabled: row => row?.status?.value !== JobStatus.OTHER,
              }),
            },
            ...extraToolbarButtons,
          ]
        }
        columns={[
          {
            label: 'Организация',
            formatValue: row => {
              if (row.status?.value === JobStatus.CIVIL_CONTRACT) {
                return `Договор ГПХ ${row.civilContractNumber ? `№${row.civilContractNumber}` : ''} ${
                  row.civilContractDate ? `от ${row.civilContractDate}` : ''
                }`;
              }
              return row.enterpise;
            },
          },
          { label: 'Должность', formatValue: row => row.refAppointment?.label || row.appointment || '' },
          {
            label: 'Штатное состояние',
            formatValue: row =>
              `${row.status?.label}${row.status?.value === JobStatus.LOCAL ? `: ${row.staffState?.label || ''}` : ''}`,
          },
          { label: 'Подразделение', formatValue: row => (row.refDepartment?.label ?? row.department) || '' },
          { label: 'Табельный номер', formatValue: row => row.employeeNumber ?? '' },
          { label: 'Дата приема', formatValue: row => row.dateReceipt ?? '', dataKind: 'date', defaultSort: 'desc' },
          { label: 'Дата увольнения', formatValue: row => row.dateDismissal ?? '' },
          { label: 'Актуально', formatValue: row => row.isActive, dataKind: 'boolean' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) => (creationSource ? `${creationSource} (${creationDate})` : ''),
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationSource ? `${modificationSource} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletionDate && deletedBy ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        maxHeight="none"
        withMessages
        isDeleteConfirmEnabled
        {...(mode === 'history'
          ? {
              onRowDoubleClick: (_, row) => {
                if (row) {
                  onSelect?.(row);
                }
              },
            }
          : {})}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        isDisabled={isLinkedHistoryPending}
        onSelectedRowsCallback={handleSelectedRows}
        specification={{
          mode: 'customComponent',
          renderComponent: (job, setJob) => <Fields mode={mode} job={job} onChange={setJob} />,
          validation: {
            checkIsValid: job => !!job?.isReadOnly || validate(job).every(x => x.isValid),
            onInvalidate: job => {
              const info = validate(job);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteReset}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onDeleteConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onDeleteReset,
          },
        ]}
        size="small"
      >
        <>Удалить работу?</>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteHistoryWarningOpen}
        onClose={closeDeleteHistoryWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDeleteHistoryWarning,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeDeleteHistoryWarning,
          },
        ]}
        size="small"
      >
        <p>Выбранный на удаление элемент используется в историческом срезе.</p>
        <p>
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </p>
      </Modal>

      {mode === 'person' && (
        <>
          <LinkedHistoryEntities
            personId={person?.id}
            isOpen={isEntityRelationOpen}
            onClose={closeEntityRelation}
            id={selectedJob?.id ?? '-1'}
            type={LinkedHistoryType.JOB}
          />

          <MergePersonCollection
            entity="ScientistJob"
            entityIds={jobIds}
            isOpen={isMergeDuplicatesOpen}
            onClose={handleCloseMergeDuplicatesForm}
            onUpdate={onUpdate}
          />
        </>
      )}

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
});

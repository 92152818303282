import React, { useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { isTSUEdition, EditRelationsModal } from 'features/EditRelationsModal';
import { Permits } from 'utils/Permissions';
import { PublicationTypeCode } from 'types/models/Form';
import { useController } from './controller';
import { CompilationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    publication,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    fetchCompilationProcess,
    getLinks,
    handleSetCurrentReport,
    isChangeModal,
    handleCloseModal,
    currentType,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewButtonDisabled,
    handleEditButtonClick,
    handleAddButtonClick,
    handleViewButtonClick,
    handleDeleteButtonClick,
    handleApprove,
    handleEditRelations,
    handleSendToLibrary,
    getSelectedRow,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isDownloadPublicationFileDisabled,
    handleDownloadPublicationFile,
    isLinkButtonDisabled,
    getLinksToOpen,
    handleOpenCitationForm,
    handleCloseCitationForm,
    isCitationDisabled,
    isCitationFormOpen,
    handleArticleButtonClick,
    handleTemplateCloseArticleForm,
    isArticleButtonDisabled,
    isArticleFormOpen,
  } = useController(tableState);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.COMPILATION_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.COMPILATION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.COMPILATION_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.COMPILATION_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь сборника с подразделениями',
        onClick: handleEditRelations,
        permission: { name: Permits.COMPILATION_DEPARTMENTS_EDIT },
        isDisabled: (() => {
          const row = getSelectedRow();
          if (row) {
            return !isTSUEdition(row);
          }
          return true;
        })(),
      },
      {
        icon: { type: 'like' },
        title: 'Утвердить',
        onClick: handleApprove,
        permission: { name: Permits.COMPILATION_APPROVE },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'statistic' },
        title: 'Показатели сборника',
        onClick: handleOpenCitationForm,
        isDisabled: isCitationDisabled,
      },
      {
        icon: { type: 'info' },
        title: 'Информация о статьях',
        onClick: handleArticleButtonClick,
        isDisabled: isArticleButtonDisabled,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть сборник во внешнем источнике',
        expandedList: { list: getLinksToOpen },
        isDisabled: isLinkButtonDisabled,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл прикрепленный к сборнику',
        onClick: handleDownloadPublicationFile,
        isDisabled: isDownloadPublicationFileDisabled,
      },
      {
        icon: { type: 'library', mode: 'arrowRight' },
        title: 'Отправить в библиотеку',
        onClick: handleSendToLibrary,
        permission: { name: Permits.COMPILATION_EXPORT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'transform' },
        title: 'Трансформация',
        permission: { name: Permits.PUBLICATION_TRANSFORM },
        expandedList: { list: getLinks, callback: handleSetCurrentReport },
        isDisabled: !tableState.selectedRows.length,
      },
    ],
    [
      handleViewButtonClick,
      isViewButtonDisabled,
      handleAddButtonClick,
      handleEditButtonClick,
      isEditButtonDisabled,
      handleDeleteButtonClick,
      isDeleteButtonDisabled,
      handleEditRelations,
      handleApprove,
      handleOpenCitationForm,
      isCitationDisabled,
      handleArticleButtonClick,
      isArticleButtonDisabled,
      getLinksToOpen,
      isLinkButtonDisabled,
      handleDownloadPublicationFile,
      isDownloadPublicationFileDisabled,
      handleSendToLibrary,
      getLinks,
      handleSetCurrentReport,
      tableState.selectedRows.length,
      setIsOpenHelpForm,
      getSelectedRow,
    ],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.CompilationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
      />

      {!isViewButtonDisabled && (
        <modalTemplates.CompilationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isArticleButtonDisabled && (
        <modalTemplates.MagazineArticleTemplate.Component
          isOpen={isArticleFormOpen}
          onClose={handleTemplateCloseArticleForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isCitationDisabled && !!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.CompilationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <EditRelationsModal
        title="Редактирование связи сборника с подразделениями"
        isOpen={isOpenEditRelations}
        onClose={handleCloseEditRelationsModal}
        publication={publication}
      />

      {!isDeleteButtonDisabled && !!tableState.selectedRows.length && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.name}) Удалить сборник?</>
        </Modal>
      )}

      {isChangeModal && (
        <modalTemplates.CompilationTransformationTemplate.Component
          isOpen={isChangeModal}
          onClose={handleCloseModal}
          relatedTableState={tableState}
          onSelect={fetchCompilationProcess}
          type={currentType?.name || ''}
          id={tableState.selectedRows[0]?.id || ''}
        />
      )}

      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={tableState.selectedRows[0]['id:type'] as PublicationTypeCode}
        />
      )}

      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0]?.id}
          publicationTypeCode={tableState.selectedRows[0]['id:type'] as PublicationTypeCode}
        />
      )}

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {CompilationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

export function GetPersonalForeignProjectList(): Table.Specification<object, object> {
  return {
    apiID: 'GetPersonalForeignProjectList',
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
  };
}

import React from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { Item } from 'types/models/common';
import { GetProgramEventList } from 'features/Table/specifications';
import { tableRowConverter } from './helpers';
import { Table } from 'types/models';

type Props = {
  programId?: string;
  programEvent: Item<string> | null;
  setProgramEvent: (programEvent: Item<string> | null) => void;
  isDisabled: boolean;
};

export const SelectProgramEvent = ({ programEvent, setProgramEvent, isDisabled, programId }: Props) => (
  <FormComponent.Field label="Мероприятие\Вид конкурса">
    <TextArea
      mode={TextAreaMode.TABLE}
      settings={{
        title: 'Мероприятия',
        table: {
          specification: GetProgramEventList({ programId: programId || '-1' }),
          onSelect: (row: Table.Entry | null) => setProgramEvent(row ? tableRowConverter(row) : null),
        },
      }}
      value={programEvent?.label}
      isDisabled={isDisabled}
    />
  </FormComponent.Field>
);

import React from 'react';

import { ListEdit } from 'components';

import { Equip, Project, ReferenceItem } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { validate } from './validate';
import { getMockResourceUsageDepartment, getStageLabel } from 'features/Form/looks/project/ProjectForm/helpers';
import { ResourceUsageDepartmentFields } from './ResourceUsageDepartmentFields';
import { EquipFields } from './EquipFields';
import { showNotification } from 'features/Notifications';

type Props = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  disabled: boolean;
};

export const ResourceUsageDepartments = ({ project, setProject, disabled }: Props) => {
  function getEquipLabel(equip: Equip.Equip): string {
    return [
      equip?.classificator?.label,
      equip?.name,
      equip.manufacturer?.label,
      [
        equip.manufactureYear ? `год выпуска: ${equip.manufactureYear}` : '',
        equip.inventoryNumber ? `(инв.№ ${equip.inventoryNumber})` : '',
      ]
        .filter(Boolean)
        .join(' '),
    ]
      .filter(Boolean)
      .join(', ');
  }
  function getPeriodLabel(equip: Project.Equip) {
    return (
      <>
        {equip.startDate || <em>не указано</em>} - {equip.endDate || <em>не указано</em>}
      </>
    );
  }
  return (
    <>
      <ListEdit
        header={{ title: 'Использование оборудования' }}
        rows={project.equips}
        onChange={e => setProject(prev => ({ ...prev, equips: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Этап', formatValue: x => (x.stage ? getStageLabel(x.stage) : ''), styles: { width: '15%' } },
          { label: 'Оборудование', formatValue: x => (x.equip ? getEquipLabel(x.equip) : ''), styles: { width: '30%' } },
          { label: 'Период использования', formatValue: getPeriodLabel, styles: { width: '15%' } },
          {
            label: 'Дней(час.)',
            formatValue: x => [x.dayCount, x.hourCount ? `(${x.hourCount})` : ''].filter(Boolean).join(' '),
            styles: { width: '10%' },
          },
          {
            label: 'Чел./до 39л',
            formatValue: x => [x.personCount, x.youngCount ? `(${x.youngCount})` : ''].filter(Boolean).join(' '),
            styles: { width: '10%' },
          },
          {
            label: 'Примечание',
            formatValue: x => x.note,
            styles: { width: '20%' },
          },
        ]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={10}
        maxHeight="none"
        specification={{
          mode: 'customComponent',
          renderComponent: (equip, setEquip) => <EquipFields equip={equip} setEquip={setEquip} project={project} />,
          validation: {
            checkIsValid: equip => !!equip?.equip && !!equip?.stage,
            onInvalidate: () => {
              showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
            },
          },
        }}
      />

      <ListEdit
        header={{ title: 'Использование ресурсов' }}
        rows={project.resourceUsageDepartments}
        onChange={e => setProject(prev => ({ ...prev, resourceUsageDepartments: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Тип ресурса', formatValue: x => (x.department?.customFields?.kind as ReferenceItem)?.label || '' },
          { label: 'Подразделение', formatValue: x => x.department?.label ?? '' },
          { label: 'Примечание', formatValue: x => x.note ?? '' },
        ]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={10}
        maxHeight="none"
        specification={{
          mode: 'customComponent',
          renderComponent: (resourceUsageDepartment, onResourceUsageDepartmentChange) => (
            <ResourceUsageDepartmentFields
              resourceUsageDepartment={resourceUsageDepartment ?? getMockResourceUsageDepartment()}
              setResourceUsageDepartment={onResourceUsageDepartmentChange}
            />
          ),
          validation: {
            checkIsValid: (resourceUsageDepartment, index, list, mode) =>
              validate(resourceUsageDepartment, list, mode, index).every(x => x.isValid),
            onInvalidate: (resourceUsageDepartment, mode, index, list) => {
              const validationInfo = validate(resourceUsageDepartment, list, mode, index);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
    </>
  );
};

import { Estimate } from 'types/models';

import { ProjectCode, CalendarPlanByYear, CalendarPlanByStage, JobPeriod, Stage } from 'types/models/Project';
import {
  ResponsiblePerformer,
  Financing,
  StageRef,
  Approvement,
  EstimatePosition,
  ReceivingDocument,
  CashDocument,
  Enterprise,
  ExpenseDocument,
  Tax,
  Calculation,
  EstimatePositionItem,
} from 'types/models/Estimate';
import { convertDepartment } from 'services/BackendAPI/configurations/converters';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { Item, Settings } from 'types/models/common';
import { convertChange, convertProject } from 'services/BackendAPI/configurations/project/converters';
import { ScientistDegree, ScientistJob } from 'types/models/Person';
import { convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';
import { ProjectScientistRole, ProjectFinancingType } from 'utils/Enums';
import { convertFundCard } from '../fundCard/converters';
import { convertKindConsumption } from '../kindConsumption/converters';

export function convertEstimate(estimate: any, settings: Settings, isTSU?: boolean): Estimate {
  return {
    id: estimate._attributes.id,
    year: getText(estimate.Year),
    amountByYear: parseFloat(getText(estimate.AmountByYear)),
    amountDisbalance: parseFloat(getText(estimate.AmountDisbalance)),
    amountNdsByYear: parseFloat(getText(estimate.AmountNdsByYear)),
    amountNormativeOverhead: parseFloat(getText(estimate.AmountNormativeOverhead)),
    amountReceivedByYear: parseFloat(getText(estimate.AmountReceivedByYear)),
    amountToTransite: parseFloat(getText(estimate.AmountToTransite)),
    amountTransited: parseFloat(getText(estimate.AmountTransited)),
    amountTransitory: parseFloat(getText(estimate.AmountTransitory)),
    correctedOverheadPercent: parseFloat(getText(estimate.CorrectedOverheadPercent)),
    createdDate: getText(estimate.CreatedDate),
    expenseDocuments: estimate.ExpenseDocuments
      ? convertMaybeArray(estimate.ExpenseDocuments.ExpenseDocument || [], convertExpenseDocument)
      : [],
    ndsPercentByYear: parseFloat(getText(estimate.NdsPercentByYear)),
    normativeOverheadPercent: parseFloat(getText(estimate.NormativeOverheadPercent)),
    positions: estimate.Positions ? convertMaybeArray(estimate.Positions.Position || [], convertEstimatePosition) : [],
    projectCode: convertProjectCode(estimate.ProjectCode, settings, isTSU),
    receivingDocuments: estimate.ReceivingDocuments
      ? convertMaybeArray(estimate.ReceivingDocuments.ReceivingDocument || [], convertReceivingDocument)
      : [],
    status: convertItem(estimate.Status),
    calculation: convertCalculation(estimate.Calculation),
  };
}

function convertCalculation(calc: any): Calculation {
  const expense = {
    fact: parseFloat(getText(calc?.Expense?.FactAmount)),
    plan: parseFloat(getText(calc?.Expense?.PlanAmount)),
  };
  const income = {
    fact: parseFloat(getText(calc?.Income?.FactAmount)),
    plan: parseFloat(getText(calc?.Income?.PlanAmount)),
  };
  const nds = {
    fact: parseFloat(getText(calc?.Nds?.FactAmount)),
    plan: parseFloat(getText(calc?.Nds?.PlanAmount)),
    percent: parseFloat(getText(calc?.Nds?.Percent)),
  };
  const overhead = {
    correctedAmount: parseFloat(getText(calc?.Overhead?.CorrectedAmount)),
    correctedPercent: parseFloat(getText(calc?.Overhead?.CorrectedPercent)),
    normativeAmount: parseFloat(getText(calc?.Overhead?.NormativeAmount)),
    normativePercent: parseFloat(getText(calc?.Overhead?.NormativePercent)),
    factAmount: parseFloat(getText(calc?.Overhead?.FactAmount)),
  };
  const transite = {
    toTransite: parseFloat(getText(calc?.Transit?.ToTransiteAmount)),
    transited: parseFloat(getText(calc?.Transit?.TransitedAmount)),
    transitory: parseFloat(getText(calc?.Transit?.TransitoryAmount)),
  };

  return {
    expense,
    income,
    nds,
    overhead,
    transite,
  };
}

function convertReceivingDocument(rd: any): ReceivingDocument {
  return {
    id: rd._attributes.id,
    amount: parseFloat(rd.Amount),
    cashDocument: convertCashDocument(rd.CashDocument),
    nds: parseFloat(rd.Nds),
  };
}

function convertCashDocument(cd: any): CashDocument {
  return {
    id: cd?._attributes?.id,
    enterprise: cd?.Enterprise ? convertEnterprise(cd.Enterprise) : null,
    isExpense: getText(cd?.IsExpense) === 'true',
    number: getText(cd?.Number),
    payDate: getText(cd?.PayDate),
  };
}

function convertEnterprise(en: any): Enterprise {
  return {
    id: en._attributes.id,
    city: getText(en.city),
    country: convertReference(en.country),
    fullName: getText(en.fullName),
    inn: getText(en.inn),
    kpp: getText(en.kpp),
    phone: getText(en.phone),
    postCode: getText(en.postCode),
    street: getText(en.street),
  };
}

export function convertEstimatePosition(ep: any): EstimatePosition {
  if (ep.Parent) {
    ep = {
      ...ep.Parent,
      Tax: ep,
    };
  }
  return {
    id: ep._attributes.id,
    amount: parseFloat(getText(ep.Amount)),
    fact: parseFloat(getText(ep.Fact)),
    isReduceOverhead: getText(ep.IsReduceOverhead) === 'true',
    kindConsumption: convertKindConsumption(ep.KindConsumption),
    note: getText(ep.Note),
    transitoryBalance: parseFloat(getText(ep.TransitoryBalance)),
    fundCard: ep.FundCard ? convertFundCard(ep.FundCard) : undefined,
    parent: ep.Parent ? convertEstimatePosition(ep.Parent) : undefined,
    tax: ep.Tax ? convertTax(ep.Tax) : undefined,
    items: convertMaybeArray(ep.Items?.Item || [], convertEstimatePositionItems),
  };
}

function convertEstimatePositionItems(item: any): EstimatePositionItem {
  return {
    id: item._attributes?.id,
    date: getText(item.Date),
    amount: parseFloat(getText(item.Amount)),
    note: getText(item.Note),
  };
}

export function convertTax(tax: any): Tax {
  return {
    id: tax._attributes.id,
    code: getText(tax.Code),
    name: getText(tax.Name),
    isAccomplicePayment: getText(tax.IsAccomplicePayment) === 'true',
    isActive: getText(tax.IsActive) === 'true',
    isCalculateTax: getText(tax.IsCalculateTax) === 'true',
    isSendOverhead: getText(tax.IsSendOverhead) === 'true',
    kosgu: convertReference(tax.Kosgu),
    kindConsumption: tax.KindConsumption ? convertKindConsumption(tax.KindConsumption) : undefined,
    kvr: convertReference(tax.Kvr),
    parent: tax.Parent ? convertTax(tax.Parent) : undefined,
    taxPercent: parseFloat(getText(tax.TaxPercent)),
    amount: parseFloat(getText(tax.Amount)),
    isReduceOverhead: getText(tax.IsSendOverhead) === 'true',
    items: convertMaybeArray(tax.Items?.Item || [], convertEstimatePositionItems),
  };
}

/** duplicate converter for estimates */
export function convertProjectCode(pc: any, settings: Settings, isTSU?: boolean): ProjectCode {
  return {
    id: pc._attributes.id,
    accompliceAmount: '',
    documents: [],
    expenseFactAmount: '',
    expensePlanAmount: '',
    incomeFactAmount: '',
    incomePlanAmount: '',
    overheadFactAmount: '',
    overheadPercent: '',
    receivingDocuments: [],
    code: getText(pc.Code),
    ...(!!isTSU
      ? {
          year: getText(pc.Year),
          codeDepartmentPart: getText(pc.CodeDepartmentPart),
          codeManualPart: getText(pc.CodeManualPart),
          codeYearPart: getText(pc.CodeYearPart),
          letter: convertReference(pc.Letter),
        }
      : {}),
    department: convertDepartment(pc.Department),
    financing: convertFinancing(pc.Financing),
    ...(pc.ResponsiblePerformer ? { responsiblePerformer: convertResponsiblePerformer(pc.ResponsiblePerformer, settings) } : {}),
    financeProvide: convertReference(pc.FinanceProvide),
    ...(pc.FundCard ? { fundCard: convertFundCard(pc.FundCard) } : {}),
    isOutsideBudget: getText(pc.IsOutsideBudget) === 'true',
    isRefersToEconomicAgreement: getText(pc.IsRefersToEconomicAgreement) === 'true',
    isActive: getText(pc.IsActive) === 'true',
    isFundCardUsed: getText(pc.IsFundCardUsed) === 'true',
    calendarPlansTotalAmount: parseInt(pc.CalendarPlansByYears._attributes.totalAmount, 10),
    calendarPlansByYears: pc.CalendarPlansByYears
      ? convertMaybeArray(pc.CalendarPlansByYears.CalendarPlanByYear || [], convertCalendarPlanByYear)
      : [],
    calendarPlansByStages: pc.CalendarPlansByStages
      ? convertMaybeArray(pc.CalendarPlansByStages.CalendarPlanByStage || [], convertCalendarPlanByStage).sort(
          (a, b) => parseInt(a.stage.number, 10) - parseInt(b.stage.number, 10),
        )
      : [],
    project: convertProject(pc.Project, settings),
    changesInProject: convertMaybeArray(pc.ChangesInProject?.ChangeInProject || [], e => convertChange(e, settings)),
  };
}

function convertExpenseDocument(ed: any): ExpenseDocument {
  return {
    id: ed._attributes.id,
    amount: parseFloat(getText(ed.Amount)),
    cashDocument: convertCashDocument(ed.CashDocument),
    kindConsumption: convertKindConsumption(ed.KindConsumption),
  };
}

function convertCalendarPlanByYear(cp: any): CalendarPlanByYear {
  return {
    year: getText(cp.Year),
    amount: parseFloat(getText(cp.Amount)),
    factAmount: parseFloat(getText(cp.FactAmount)),
    ndsPercent: parseFloat(getText(cp.NdsPercent)),
    ndsAmount: parseFloat(getText(cp.NdsAmount)),
    accompliceAmount: parseFloat(getText(cp.AccompliceAmount)),
    hasEstimate: getText(cp.HasEstimate) === 'true',
    isInProjectStages: getText(cp.IsInProjectStages) === 'true',
  };
}

function convertCalendarPlanByStage(cp: any): CalendarPlanByStage {
  return {
    stage: convertStage(cp.Stage),
    amount: parseFloat(getText(cp.Amount)),
    ndsPercent: parseFloat(getText(cp.NdsPercent)),
    ndsAmount: parseFloat(getText(cp.NdsAmount)),
  };
}

function convertResponsiblePerformer(rp: any, settings: Settings): ResponsiblePerformer {
  return {
    id: rp._attributes.id,
    person: convertServerPerson(rp.Person, settings),
    fio: getText(rp.Fio),
    jobPeriods: convertMaybeArray(rp.JobPeriods.JobPeriod || [], e => convertJobPeriod(e, settings)),
    stages: convertMaybeArray(rp.Stages?.Stage || [], convertStageRef),
    createdBy: getText(rp.CreatedBy),
    createdDate: getText(rp.CreatedDate),
  };
}

function convertJobPeriod(jp: any, settings: Settings): JobPeriod {
  return {
    academicRank: null,
    education: null,
    rank: null,
    id: jp._attributes.id,
    person: jp.Person ? convertServerPerson(jp.Person, settings) : null,
    job: jp.Job ? convertJob(jp.Job) : null,
    degree: jp.Degree ? convertDegree(jp.Degree) : null,
    citizenship: jp.Citizenship ? convertReference(jp.Citizenship) : null,
    startDate: jp.StartDate,
    startDateOrder: jp.StartDateOrder ? { id: getId(jp.StartDateOrder) } : null,
    endDate: jp.EndDate,
    endDateOrder: jp.EndDateOrder ? { id: getId(jp.EndDateOrder) } : null,
    historyLabel: getText(jp.HistoryLabel),
    role: convertItem<ProjectScientistRole>(jp.Role),
    note: getText(jp.Note),
  };
}

export function convertDegree(d: any): ScientistDegree {
  return {
    deletedBy: '',
    deletionDate: '',
    createdBy: '',
    creationDate: '',
    creationSource: '',
    isActive: false,
    isReadOnly: false,
    modificationDate: '',
    modificationSource: '',
    modifiedBy: '',
    refDegree: null,
    id: d._attributes.id,
    originalId: d._attributes.originalId,
    dateAssign: d.DateAssign,
  };
}

function convertJob(j: any): ScientistJob {
  return {
    deletedBy: '',
    deletionDate: '',
    id: j._attributes.id,
    appointment: null,
    creationDate: '',
    creationSource: '',
    dateDismissal: null,
    dateReceipt: '',
    department: null,
    isActive: false,
    isReadOnly: false,
    modificationDate: '',
    modificationSource: '',
    originalId: j._attributes.originalId,
    status: convertItem(j.Status),
    enterpise: getText(j.Enterprise),
    appointmentCategory: convertReference(j.AppointmentCategory),
    staffGroup: convertReference(j.StaffGroup),
    refAppointment: null,
    refDepartment: null,
    civilContractDate: '',
    civilContractNumber: '',
    civilContractName: '',
    staffState: j.StaffState ? convertReference(j.StaffState) : null,
    compoundType: j.CompoundType ? convertReference(j.CompoundType) : null,
  };
}

function convertStageRef(stRef: any): StageRef {
  return {
    approved: getText(stRef.Approved) === 'true',
    approvements: convertMaybeArray(stRef.Approvements.Approvement || [], convertApprovement),
    id: stRef._attributes.id,
    paymentMethods: getText(stRef.PaymentMethods),
    payments: [],
    stage: convertStage(stRef.Stage),
  };
}

export function convertApprovement(a: any): Approvement {
  return {
    id: a._attributes.is,
    source: getText(a.Source),
    approvedDate: getText(a.ApprovedDate),
  };
}

/** duplicate converter */
function convertStage(st: any): Stage {
  return {
    amountExternalAccomplices: '',
    indicators: [],
    indices: [],
    id: st._attributes.id,
    accompliceNdsPercent: getText(st.AccompliceNdsPercent),
    amountAccomplice: getText(st.AmountAccomplice),
    amountAccompliceUndistributed: getText(st.AmountAccomplice._attributes.undistributed),
    amountCofinancing1: getText(st.AmountCofinancing1),
    amountCofinancing1Undistributed: getText(st.AmountCofinancing1._attributes.undistributed),
    amountCofinancing2: getText(st.AmountCofinancing2),
    amountCofinancing2Undistributed: getText(st.AmountCofinancing2._attributes.undistributed),
    amountLocal: getText(st.AmountLocal),
    amountLocalUndistributed: st.AmountLocal._attributes.undistributed,
    amountMain: getText(st.AmountMain),
    amountFact: getText(st.AmountFact),
    amountMainUndistributed: st.AmountMain._attributes.undistributed,
    cofinancing1NdsPercent: getText(st.Cofinancing1NdsPercent),
    cofinancing2NdsPercent: getText(st.Cofinancing2NdsPercent),
    endDate: getText(st.EndDate),
    isLocked: getText(st.IsLocked) === 'true',
    isPatentResearchPlanned: getText(st.IsPatentResearchPlanned) === 'true',
    localNdsPercent: getText(st.LocalNdsPercent),
    mainNdsPercent: getText(st.MainNdsPercent),
    number: getText(st.Number),
    startDate: getText(st.StartDate),
    interdisciplinaryAproachJustification: getText(st.InterdisciplinaryAproachJustification),
  };
}

function convertFinancing(fn: any): Financing {
  return {
    id: fn._attributes.id,
    type: convertFinType(fn.Type),
    source: convertReference(fn.Source),
    amount: getText(fn.Amount),
    factAmount: getText(fn.Amount),
    isNds: getText(fn.IsNds) === 'true',
  };
}
function convertFinType(ft: any): Item<ProjectFinancingType> {
  return {
    value: ft._attributes.id,
    label: ft._attributes.label,
  };
}

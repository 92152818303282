import { parse } from 'date-fns';

import { Lot } from 'types/models/Tender';
import { formatStr } from 'utils/Constants';
import { roundFloat } from 'utils/Helpers';
import { TenderRequestType } from 'utils/Enums';

export function validateLotDates(lot: Lot | null) {
  const startDate = parse(lot?.startDate ?? '', formatStr, new Date());
  const endDate = parse(lot?.endDate ?? '', formatStr, new Date());
  return startDate.getTime() <= endDate.getTime();
}

export function validateLot(lot: Lot | null, tenderType: TenderRequestType) {
  const numberRequired = lot?.number;
  const themeRequired = lot?.theme;
  const contractCountRequired = lot?.contractCount;
  const contractCountNotNull = lot?.contractCount !== undefined && lot?.contractCount >= 1;

  const contractAmountInt = parseFloat(String(lot?.contractAmount));
  const contractCountInt = parseFloat(String(lot?.contractCount));
  const limitAmountInt = parseFloat(String(lot?.limitAmount));
  const isLotValid =
    (!lot?.contractAmount && !lot?.limitAmount) || roundFloat(contractAmountInt * contractCountInt) === limitAmountInt;
  const isNirTenderTypeValid = tenderType === TenderRequestType.NIR_REQUEST ? !!lot?.nirTenderType?.value : true;

  return [
    { isValid: numberRequired, invalidMessage: 'Заполните поле "Номер лота"' },
    { isValid: themeRequired, invalidMessage: 'Заполните поле "Тема лота"' },
    { isValid: contractCountRequired, invalidMessage: 'Заполните поле "Количество контрактов"' },
    { isValid: contractCountNotNull, invalidMessage: 'Количество контрактов должно быть больше 0' },
    { isValid: isLotValid, invalidMessage: 'Итоговая Сумма всех контрактов не совпадает с Предельной ценой лота' },
    { isValid: isNirTenderTypeValid, invalidMessage: 'Укажите тип конкурса' },
  ];
}

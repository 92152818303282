import { Icon } from 'components';

export function PersonalConferenceEventGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Конференции</strong> предназначен для поддержки процессов организационно-технического и финансового
        сопровождения научных мероприятий университета: формирования Сводного плана научных мероприятий университета на год и
        отчетности по результатам проведения.
      </p>

      <h4>В разделе Конференции вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись о конференции и информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новую конференцию с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать конференцию в статусе Добавлена с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Удалить запись о конференции, которую вы создавали, если она в статусе Добавлена с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Выгрузить список конференций в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Распечатать выбранную конференцию в готовом шаблоне отчета для печати <Icon type="print" />
          <ul>
            <li>Заявка в сводный план на проведение конференции</li>
            <li>Заявка подразделения в сводный план мероприятий университета</li>
            <li>Сводный перечень научных мероприятий</li>
            <li>Отчет по конференции</li>
          </ul>
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении конференции присваивается статус <strong>Добавлена.</strong>
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите подтверждающий документ. Ответственное лицо проверит и утвердит конференцию.
      </p>
      <p>
        Если университет является организатором или соорганизатором мероприятия, то все вкладки на форме обязательны к заполнению.
        Подробнее в Инструкции по конференциям университета в разделе Справочные материалы и ссылки.
      </p>
      <p>
        Для добавления участия в конференции, организованной другой организацией , необходимо внести эту конференцию, заполнив
        обязательные поля на вкладке «О мероприятии» и прикрепить Программу или информационное письмо на вкладке «Документы».
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои конференции»</strong> содержит список конференций, в которых вы указаны в составе Оргкомитета. Если
          вы создавали запись о конференции, но не являетесь участником оргкомитета, конференция будет отображаться в другом
          разделе.
        </li>
        <li>
          <strong>Фильтр «Конференции {organizationName}»</strong> содержит список конференций, в которых организатором или
          соорганизатором является {organizationName}.
        </li>
        <li>
          <strong>Фильтр «Другие конференции»</strong> показывает список конференций других организаций, в которых принимают
          участие сотрудники, аспиранты и студенты {organizationName}.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список конференций, сроки проведения которых содержат указанный год. По
          умолчанию выбран текущий год.
        </li>
      </ul>

      <p>Добавленное вами мероприятие проверит и утвердит Администратор данных.</p>

      <div>
        Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору <Icon type="admin" />
      </div>

      <p>Полное руководство по работе с конференциями вы можете скачать в разделе Справочные материалы и ссылки.</p>
    </>
  );
}

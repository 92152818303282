import React, { useState } from 'react';
import block from 'bem-cn';

import { DataGrid } from 'features/Table';
import { GetProjectCodeListByYear, GetProjectCodeReceivingDocumentList } from 'features/Table/specifications';

import './style.scss';

const b = block('receipts');

export function Receipts() {
  const [projectCodeInfo, setProjectCodeInfo] = useState<{ id: string; year: string }>({ id: '-1', year: '' });

  return (
    <div className={b()}>
      <div className={b('grid-container')}>
        <DataGrid specification={GetProjectCodeListByYear({ setProjectCodeInfo })} />
      </div>
      <div className={b('grid-container')} key={projectCodeInfo.id + projectCodeInfo.year}>
        <DataGrid
          specification={GetProjectCodeReceivingDocumentList({
            projectCodeId: projectCodeInfo.id,
            year: projectCodeInfo.year,
            title: 'Список приходных документов (детализация по сумме в столбце Факт)',
            isFiltersHidden: true,
          })}
        />
      </div>
    </div>
  );
}

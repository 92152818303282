import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextDateTime } from 'components';

import { PeriodableReferenceItem } from 'types/models/Partner';
import { ValueOf } from 'types/helpers';

type Props = {
  partnership: PeriodableReferenceItem;
  setPartnership(partnership: PeriodableReferenceItem): void;
};

export function Fields(props: Props) {
  const { partnership, setPartnership } = props;

  const makeHandler = (key: keyof PeriodableReferenceItem) => (value: ValueOf<PeriodableReferenceItem>) => {
    setPartnership({ ...partnership, [key]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Тип партнёрства">
          <Select
            mode={SelectMode.REFERENCE}
            value={partnership.ref}
            onChange={(option: ReferenceItem | null) => makeHandler('ref')(option)}
            settings={{ name: 'RefPartnershipType', title: 'Справочник "Типы партнёрств"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Срок действия"
          tooltip="Скан-копии договоров о сотрудничестве размещайте на вкладке Документы"
        >
          <span>
            <TextDateTime
              value={partnership.startDate || ''}
              onChange={(value: string) => setPartnership({ ...partnership, startDate: value || '' })}
              maxValue={partnership.endDate || ''}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={partnership.endDate || ''}
              onChange={(value: string) => setPartnership({ ...partnership, endDate: value || '' })}
              minValue={partnership.startDate || ''}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea settings={{ isFixed: true }} value={partnership.note ?? ''} onChange={makeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

import { useController } from './controller';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  setProject(project: Project.Project | null): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function TextFields(props: Props) {
  const { disabled, makeChangeHandler, setProject, project } = props;

  const { handleChangeSpecific } = useController({ project, setProject });
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field
          label="Характер проекта"
          info={
            <p>
              Ключевой параметр для формирования практически всех основных отчётных форм: 1-Мониторинг 2023, 2-Наука 2023, РЕПНИД,
              1-инжиниринг, Диссертационные советы.
            </p>
          }
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={project?.specification}
            onChange={handleChangeSpecific}
            settings={{ name: 'RefProjectSpecific', title: 'Справочник "Характеры проектов"', isClearable: true }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Вид исследования"
          info={
            <p>
              Дополнение к некоторым видам из справочника Характер проекта. Вид исследования используется при регистрации проекта
              в ЕГИСУ.
            </p>
          }
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={project?.kindResearch}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('kindResearch')(option)}
            settings={{
              name: 'RefKindResearch',
              title: 'Справочник "Виды исследований"',
              isClearable: true,
              filters: [{ key: 'projectSpecific', values: [project?.specification?.id ?? ''] }],
              defaultValue: project?.specification?.id
                ? {
                    type: 'REFERENCE',
                    referenceName: 'RefProjectSpecific',
                    id: project.specification.id,
                    fieldName: 'projectSpecific',
                    isDisabled: true,
                  }
                : undefined,
            }}
            isDisabled={!project?.specification?.id || disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Цель">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Цель' }}
            value={project?.goal ?? ''}
            onChange={makeChangeHandler('goal')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Аннотация"
          info={
            <>
              <p>
                В краткой свободной форме опишите планируемые результаты работы, основные ожидаемые характеристики и параметры
                объекта исследования или разработки.
              </p>
              <p>
                Текст должен быть кратким и точным, не должен содержать сложных формул, таблиц, рисунков и аббревиатур без их
                расшифровки(кроме общепринятых), а также сокращенных слов (кроме общепринятых). Рекомендуемый объём до 1300 знаков
              </p>
            </>
          }
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Аннотация' }}
            value={project?.annotation ?? ''}
            onChange={makeChangeHandler('annotation')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Ожидаемые результаты"
          info={
            <>
              <p>
                В краткой свободной форме дается описание нового материала, устройства, продукта и других результатов проекта, а
                также возможного метода использования результатов проекта.
              </p>
            </>
          }
          // eslint-disable-next-line max-len
          tooltip=""
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Ожидаемые результаты' }}
            value={project?.expectedResults ?? ''}
            onChange={makeChangeHandler('expectedResults')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Научный задел"
          info={
            <>
              <p>Укажите основные ранее полученные результаты, связанные непосредственно с темой НИКОТР (за последние 3 года).</p>
              <p>
                А также верифицированные ссылки на публикации, реализованные НИР по теме проекта, РИДы, защищенные диссертации,
                доклады по тематике и другие результаты. Рекомендуемый объём до 7000 знаков
              </p>
            </>
          }
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Научный задел' }}
            value={project?.scientificGroundwork ?? ''}
            onChange={makeChangeHandler('scientificGroundwork')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Обоснование междисциплинарного подхода"
          info={
            <>
              <p>
                Данное поле заполняется только при наличии указания разных тематических рубрик первого уровня (ГРНТИ и Кодов
                Международной Классификации).
              </p>
              <p>
                В краткой свободной форме обосновывается междисциплинарный характер работы, относящийся к разным тематическим
                уровням. Рекомендуемый объём до 3000 знаков
              </p>
            </>
          }
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
            value={project?.interdisciplinaryAproachJustification ?? ''}
            onChange={makeChangeHandler('interdisciplinaryAproachJustification')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Ключевые слова"
          info={
            <p>
              Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов, 1-инжиниринг.
            </p>
          }
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Ключевые слова' }}
            value={project?.keyWords ?? ''}
            onChange={makeChangeHandler('keyWords')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

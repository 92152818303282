import { useMemo, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
  projectAssignmentId?: string | null;
  disabled?: boolean;
};

const useController = ({ customState, tableState, projectAssignmentId, disabled }: Props) => {
  const tableStreams = useLocalTableStreams();

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState.selectedRows]);

  const notCreatedByCurrentUser = useMemo(() => {
    return (!!row && row.IsCreatedByCurrentUser === 'false') || false;
  }, [row]);

  const token = getAuthToken();

  const {
    setIsOpenMessageModal,
    newStatus,
    setNewStatus,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
  } = customState;

  const { methods: changeProjectAssignmentStatus } = BackendAPI.useBackendAPI('ChangeProjectAssignmentStatus');

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED || disabled || notCreatedByCurrentUser;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED || disabled || notCreatedByCurrentUser;

  const onAddClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const onCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const onEditClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const onCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const onDeleteClick = useCallback(() => {
    setIsDeleteWarningOpen(true);
  }, [setIsDeleteWarningOpen]);

  const onCloseDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, [setIsDeleteWarningOpen]);

  const onSubmitDeleteWarning = useCallback(() => {
    if (row?.id) {
      setIsDeleteWarningOpen(false);
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeleteProjectAssignmentMessage',
        deletedItemPropName: 'ProjectAssignmentMessage',
      });
    }
  }, [row?.id, tableStreams, setIsDeleteWarningOpen]);

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      if (projectAssignmentId) {
        changeProjectAssignmentStatus.callAPI(
          { status: newStatus, message: message || '', ids: [projectAssignmentId] },
          {
            onSuccessfullCall: () => {
              setNewStatus('');
              setIsOpenMessageModal(false);
              tableStreams.reloadTable.push({});
            },
          },
        );
      }
    },
    [changeProjectAssignmentStatus, newStatus, tableStreams, projectAssignmentId, setIsOpenMessageModal, setNewStatus],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: onAddClick,
        isDisabled: disabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: onEditClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: onDeleteClick,
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл',
        onClick: () => downloadFile(row?.FileId, token),
        isDisabled: !row?.id || disabled,
      },
    ],
    [token, row, disabled, isEditButtonDisabled, isDeleteButtonDisabled, onAddClick, onEditClick, onDeleteClick],
  );

  return {
    buttons,
    isEditFormOpen,
    onCloseEditForm,
    isAddFormOpen,
    onCloseAddForm,
    isDeleteWarningOpen,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    handleSaveStatusClick,
    newStatus,
  };
};

export default useController;

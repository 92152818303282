import { createContext, useContext } from 'react';

import { ReferenceItem } from 'components';

import { Group, Person, Report, ScientificIndex, Table, UserPermission } from 'types/models';
import { Settings } from 'types/models/common';

export type AppContext = {
  scientificIndexes: ScientificIndex.ScientificIndex[];
  enumMap: Table.EnumMap;
  reports: Group<Report>[];
  userPermission: UserPermission | null;
  isHasPermissionToAccount: boolean | null;
  isHasPermissionToMain: boolean | null;
  currentPerson: Person.ScientistPerson | null;
  userToken: string | null;
  setUserToken: (token: string | null) => void;
  fetchInitialData: (token: string) => void;
  userSystemDepartment: ReferenceItem | null;
  initialCountryCode: ReferenceItem | null;
  initialLanguageCode: ReferenceItem | null;
  initialOrganizationCode: ReferenceItem | null;
  settings: Settings | null;
};

export const AppDataContext = createContext<AppContext>({
  scientificIndexes: [],
  enumMap: {},
  reports: [],
  userPermission: null,
  currentPerson: null,
  isHasPermissionToAccount: null,
  isHasPermissionToMain: null,
  userToken: null,
  setUserToken: () => {},
  fetchInitialData: () => {},
  userSystemDepartment: null,
  initialCountryCode: null,
  initialLanguageCode: null,
  initialOrganizationCode: null,
  settings: null,
});

export function useAppDataContext() {
  return useContext<AppContext>(AppDataContext);
}

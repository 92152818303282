import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';
import { EstimatePositionTemplateProps } from '../EstimatePositionEditTemplate';

const EstimatePositionAddTemplate: React.FC<EstimatePositionTemplateProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  estimateId,
  fundCard,
}: EstimatePositionTemplateProps) => {
  const look: F.EstimatePositionLook = {
    apiID: 'GetEstimatePosition',
    template: 'EstimatePositionForm',
    relatedTableState,
    name,
    estimateId,
    fundCard,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Добавить общую сумму расходов по статье затрат"
      size={['large', 'medium-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(EstimatePositionAddTemplate);

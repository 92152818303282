import React, { useMemo } from 'react';

import { FormComponent, SectionTitle, TextInput, Tooltip, Toolbar, UploadImage } from 'components';

import { Person, UserPermission } from 'types/models';
import { ValueOf } from 'types/helpers';
import { convertDegree, convertAcademicRank, convertRank } from 'features/Form/looks/person/Tabs/Main/UpperForm/helpers';
import { useController } from 'features/Form/looks/person/Tabs/Main/UpperForm/controller';
import UpperFormPublications from 'features/Form/looks/person/Tabs/Main/UpperForm/UpperFormPublications';
import ProfileAchievements from 'features/Charts/ProfileAchievements';
import { LANGUAGE_COMMUNICATION_HINT } from 'utils/Constants';
import { PersonContactList } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  reloadPerson(): void;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};
function Component(props: Props) {
  const { person, reloadPerson, makeSetPerson } = props;
  if (!person) {
    return null;
  }

  const {
    isPublicationsOpened,
    isFiveYear,
    closePublicationWindow,
    openPublicationsWindow,
    getActualJob,
    jobsCombinationList,
    getActualEducation,
    yearInterval,
    hirshIndexes,
    onCustomChange,
  } = useController({ person, makeSetPerson });

  const personalFields = useMemo(
    () => [
      {
        label: 'Дата рождения:',
        value: person.scientist?.dateBirth || '',
      },
      {
        label: 'Ученая степень:',
        value: person.degrees.length > 0 ? convertDegree(person) : '',
      },
      {
        label: 'Ученое звание:',
        value: person.ranks.length > 0 ? convertRank(person) : '',
      },
      {
        label: 'Академическое звание:',
        value: person.academicRanks.length > 0 ? convertAcademicRank(person) : '',
      },
      {
        label: 'Обучение:',
        value: person.scientistEducations.length > 0 ? getActualEducation : '',
      },
      {
        label: 'Гражданство:',
        value: person.scientist?.citizenship?.label || '',
      },
    ],
    [person, getActualEducation],
  );

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: 'calc(100% - 750px)', maxWidth: '950px' }}>
          <FormComponent.Wrapper>
            <SectionTitle
              title="Персональная информация"
              // eslint-disable-next-line max-len
              tooltip="Источником данных является информационная система Управления кадров университета или информационная система Учёта студентов"
            />
            <FormComponent.ColumnWrapper>
              <FormComponent.Column style={{ width: 'calc(100% - 250px)' }} hasNoWrap>
                <>
                  {personalFields.map(
                    (item, index) =>
                      item.value && (
                        <FormComponent.Line key={index}>
                          <FormComponent.Field label={item.label}>
                            <FormComponent.Text>{item.value}</FormComponent.Text>
                          </FormComponent.Field>
                        </FormComponent.Line>
                      ),
                  )}
                  <FormComponent.Line>
                    <FormComponent.Field label="СНИЛС:">
                      <span>{person.scientist?.snils || ''}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="ИНН:">
                      <span>{person.scientist?.inn || ''}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Языковые коммуникации" tooltip={LANGUAGE_COMMUNICATION_HINT}>
                      <TextInput
                        value={person.scientist?.languageCommunication || ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, languageCommunication: e })}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
                <SectionTitle
                  title="Актуальные контакты"
                  // eslint-disable-next-line max-len
                  tooltip="Введите свои контактные данные. Они необходимы для связи с вами и формирования отчетных форм и рассылки уведомлений"
                />
                <FormComponent.Line>
                  <PersonContactList contacts={person.contacts} setContacts={makeSetPerson('contacts')} />
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column style={{ width: '250px', height: '270px' }} hasNoWrap>
                <UploadImage text="Фото профиля" id={person?.avatar?.id} onChange={onCustomChange} />
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>

            <SectionTitle
              title="Место работы"
              // eslint-disable-next-line max-len
              tooltip="Сведения о работе загружаются из информационной системы Управления кадров. В этой системе нет задачи хранить информацию обо всех кадровых перемещениях персоны. Подробнее об имеющейся в базе данных информации смотрите на вкладке Дополнительная информация"
            />
            <>
              {person.scientistJobs.length > 0 && (
                <FormComponent.Line>
                  <FormComponent.Field label="Основное место работы:">
                    <span>{getActualJob}</span>
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
            </>
            <>
              {jobsCombinationList.length > 0 && (
                <FormComponent.Line>
                  <FormComponent.Field label="Совместительство: ">
                    {jobsCombinationList.map((job, index) => (
                      <FormComponent.Line key={index}>
                        <span>{job}</span>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
            </>

            <SectionTitle
              title="Идентификаторы"
              // eslint-disable-next-line max-len
              tooltip="Добавить идентификатор в системе базы цитирования публикации - на вкладке Дополнительные сведения / Идентификаторы в базах цитирования"
            />
            <>
              {person.identifiers.map((item, index) => (
                <FormComponent.Line key={index}>
                  <FormComponent.Field label={`${item.citationSystem?.label}: `}>
                    {item.link ? <FormComponent.Link href={item.link} label={item.identifier} /> : <span>{item.identifier}</span>}
                  </FormComponent.Field>
                </FormComponent.Line>
              ))}
            </>
            <>
              {person.affiliations?.length > 0 && (
                <>
                  <SectionTitle
                    title="Аффилированные организации"
                    tooltip="Список организаций, которые указываются в аффилиации автора публикации"
                  />
                  {person.affiliations.map(affiliation => (
                    <FormComponent.Line key={affiliation.id}>
                      <span>{`${affiliation.partner?.fullName} ${
                        affiliation.partner?.shortName ? ` (${affiliation.partner?.shortName})` : ''
                      }`}</span>
                    </FormComponent.Line>
                  ))}
                </>
              )}
            </>
          </FormComponent.Wrapper>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: '750px' }}>
          <SectionTitle title={`Мои результаты за период: ${yearInterval}`} />

          <FormComponent.Line hasFreeFormat>
            <span>Индекс Хирша:</span>
            <Tooltip text="История изменения индекса хирша - на вкладке Дополнительные сведения / Индекс Хирша" />
          </FormComponent.Line>
          <>
            {hirshIndexes
              .filter((i, index) => hirshIndexes.map(h => h.label).indexOf(i.label) === index)
              .map((item, index) => (
                <FormComponent.Line key={index}>
                  <FormComponent.Field label={`${item.label}`}>
                    {!!item.value && (
                      <span>
                        <strong>{item.value}</strong>
                        {item.date ? ` (дата расчета: ${item.date})` : ''}
                      </span>
                    )}
                  </FormComponent.Field>
                </FormComponent.Line>
              ))}
          </>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field
              label="Совокупный импакт-фактор"
              // eslint-disable-next-line max-len
              tooltip='Рассчитывается на основе наукометрических показателей статей. Подробный список по кнопке "Публикации для расчета совокупного ИФ"'
            >
              {person.scientist?.calculateImpactFactor}
            </FormComponent.Field>
            <Toolbar
              buttons={[
                {
                  icon: { type: 'calc' },
                  title: 'Расчет совокупного ИФ',
                  onClick: reloadPerson,
                },
                {
                  icon: { type: 'list', mode: 'info' },
                  title: 'Публикации для расчета совокупного ИФ',
                  onClick: () => openPublicationsWindow(false),
                },
              ]}
            />
          </FormComponent.Line>
          <>{person.calculations && <ProfileAchievements calculations={person.calculations} reloadPerson={reloadPerson} />}</>
        </FormComponent.Column>

        <UpperFormPublications
          isOpen={isPublicationsOpened}
          onClose={closePublicationWindow}
          id={person.id}
          isFiveYear={isFiveYear}
        />
      </FormComponent.ColumnWrapper>
    </>
  );
}

export const UpperForm = React.memo(Component);

import { Project } from 'types/models';
import { Item, Settings } from 'types/models/common';
import { convertServerPerson } from '../author/converters';
import * as authorConverters from '../author/converters';

import {
  convertDocument,
  convertFile,
  convertItem,
  convertMaybeArray,
  convertReference,
  getBoolean,
  getId,
  getLabel,
  getText,
} from '../commonConverters';
import { convertProgram } from '../program/converters';
import { convertServerPartner } from '../partners/converters';
import { convertPatentResearch } from '../patentResearch/converters';
import { convertSecurityDocumentContract } from '../securityDocumentContract/converters';
import { convertPersonHistory } from 'services/BackendAPI/configurations/converters';
import { JobPeriodOrder } from 'types/models/Project';
import { convertSecurityDocument } from '../securityDocument/converters';
import { convertProjectCode } from '../projectCode/converters';
import { convertEquip as convertEquipModel } from '../equip/converters';

export function convertProject(job: any, settings: Settings): Project.Project {
  const customers = job.Customers?.Customer ?? [];
  const departments = job.Departments?.Department ?? [];
  const financings = job.Financings?.Financing ?? [];
  const financingsByYear = job.FinancingByYears?.FinancingByYear ?? [];
  const stages = job.Stages?.Stage ?? [];
  const scienceInterests = job.ScienceDomainInterrests?.ScienceDomainInterrest ?? [];
  const pnrs = job.Pnrs?.Pnr ?? [];
  const pnmitrs = job.Pnmitrs?.Pnmitr ?? [];
  const pnis = job.Pnis?.Pni ?? [];
  const ntrStrategies = job.NtrStrategies?.NtrStrategy ?? [];
  const criticalTechnologies = job.CriticalTechnologies?.CriticalTechnology ?? [];
  const ntiEteTechnologies = job.NtiEteTechnologies?.NtiEteTechnology ?? [];
  const knowledgeBranches = job.KnowledgeBranches?.KnowledgeBranch ?? [];

  const grntis = job.Grntis?.Grnti ?? [];
  const udks = job.Udks?.Udk ?? [];
  const oecds = job.Oecds?.Oecd ?? [];
  const ntiMarkets = job.NtiMarkets?.NtiMarket ?? [];
  const techPlatforms = job.TechPlatforms?.TechPlatform ?? [];
  const workSpaces = job.WorkSpaces?.WorkSpace ?? [];
  const performers = job.Performers?.Performer ?? [];
  const serverForeignDocuments = job.ForeignDocuments?.Document || [];
  const serverDocuments = job.Documents?.Document || [];
  // const stageRegistrationReports = job.StateRegistrationReports?.StateRegistrationReport || [];
  const contestRequests = job.ContestRequests?.ContestRequest ?? [];
  const projectCodes = job.ProjectCodes?.ProjectCode ?? [];
  const partners = job.Partners?.Partner ?? [];
  const acts = job.Acts?.Act ?? [];
  const patentResearches = job.PatentResearches?.PatentResearch ?? [];
  const resourceUsageDepartments = job.ResourceUsageDepartments?.ResourceUsageDepartment ?? [];
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));
  const localDocuments = convertMaybeArray(serverDocuments, i => convertDocument('local', i));

  const lksetss = job.Lksetss?.Lksets ?? [];
  const scienceBrunches = job.ScienceBrunches?.ScienceBrunch ?? [];
  const partnerCountries = job.PartnerCountries?.PartnerCountry ?? [];
  const vaks = job.Vaks?.Vak ?? [];

  return {
    activityKind: job.ActivityKind ? convertReference(job.ActivityKind) : null,
    acts: convertMaybeArray(acts || [], convertAct).sort((a, b) => parseFloat(b?.id || '0') - parseFloat(a?.id || '0')),
    annotation: getText(job.Annotation),
    contestRequests: convertMaybeArray(contestRequests, convertContestRequest),
    contractDate: getText(job.ContractDate),
    contractKind: job.ContractKind ? convertReference(job.ContractKind) : null,
    contractNumber: getText(job.ContractNumber),
    criticalTechnologies: convertRefElements(criticalTechnologies),
    customers: convertMaybeArray(customers, convertCustomer),
    departments: convertMaybeArray(departments, convertDepartment),
    documents: [...foreignDocuments, ...localDocuments],
    knowledgeBranches: convertRefElements(knowledgeBranches),
    endDate: getText(job.EndDate),
    expectedResults: getText(job.ExpectedResults),
    expendituresBudgets: job.ExpendituresBudgets ? convertReference(job.ExpendituresBudgets) : null,
    financialNote: getText(job.FinancialNote),
    financings: convertMaybeArray(financings, convertFinancing),
    financingsByYear: convertMaybeArray(financingsByYear, convertFinancingByYear),
    foreignEnterprise: convertReference(job.ForeignEnterprise),
    goal: getText(job.Goal),
    grntis: convertRefElements(grntis),
    id: getId(job),
    interdisciplinaryAproachJustification: getText(job.InterdisciplinaryAproachJustification),
    internationalProgram: convertReference(job.InternationalProgram),
    isBuyMaterial: getBoolean(job.IsBuyMaterial),
    isFinancingStopped: getBoolean(job.IsFinancingStopped),
    isNirRequestDataConsumed: getBoolean(job.IsNirRequestDataConsumed),
    isSpecTheme: getBoolean(job.IsSpecTheme),
    isYouth: getBoolean(job.IsYouth),
    keyWords: getText(job.KeyWords),
    kindResearch: job.KindResearch ? convertReference(job.KindResearch) : null,
    lksetss: convertRefElements(lksetss),
    name: getText(job.Name),
    nationalProjectExpenseCode: job.NationalProjectExpenseCode ? convertReference(job.NationalProjectExpenseCode) : null,
    nirRequest: job.NirRequest ? convertNirRequest(job) : null,
    ntiEteTechnologies: convertRefElements(ntiEteTechnologies),
    ntiMarkets: convertRefElements(ntiMarkets),
    ntrStrategies: convertRefElements(ntrStrategies),
    number: getText(job.Number),
    numberPrefix: job.NumberPrefix ? convertReference(job.NumberPrefix) : null,
    oecds: convertRefElements(oecds),
    okved: job.Okved ? convertReference(job.Okved) : null,
    okvedIncome: job.OkvedIncome ? convertReference(job.OkvedIncome) : null,
    overheadPercent: getText(job.OverheadPercent),
    partnerCountries: convertMaybeArray(partnerCountries, convertRefElement),
    partners: convertMaybeArray(partners, p => convertPartner(p, settings)),
    patentResearches: convertMaybeArray(patentResearches, r => convertPatentResearch(r, settings)),
    performers: convertMaybeArray(performers, p => convertPerformer(p, settings)),
    periodNote: getText(job.PeriodNote),
    pnis: convertRefElements(pnis),
    pnmitrs: convertRefElements(pnmitrs),
    pnrs: convertRefElements(pnrs),
    program: job.Program ? convertProgram(job.Program) : null,
    programEvent: job.ProgramEvent ? convertItemProgramEvent(job.ProgramEvent) : null,
    programEventCode: job.ProgramEvent ? getText(job?.ProgramEvent?.Code) : undefined,
    programEventName: job.ProgramEvent ? getText(job?.ProgramEvent?.Name) : undefined,
    programRequest: job.ProgramRequest ? convertProgramRequest(job) : null,
    projectCodes: convertMaybeArray(projectCodes, e => convertProjectCode(e, settings)),
    projectCodesLabel: getText(job.ProjectCodesLabel),
    projectContractStatus: job.ContractStatus ? convertItem(job.ContractStatus) : null,
    resourceUsageDepartments: convertMaybeArray(resourceUsageDepartments, convertResourceUsageDepartment),
    result: getText(job.Result),
    scienceBrunches: convertRefElements(scienceBrunches),
    scienceDomainInterrests: convertRefElements(scienceInterests),
    scientificGroundwork: getText(job.ScientificGroundwork),
    specification: job.Specification ? convertReference(job.Specification) : null,
    stages: convertMaybeArray(stages, convertStage).sort((a, b) => a.number.localeCompare(b.number)),
    startDate: getText(job.StartDate),
    stateNumber: getText(job.StateNumber),
    stateRegistrationAnnotation: getText(job.StateRegistrationAnnotation),
    stateRegistrationDate: getText(job.StateRegistrationDate),
    stateRegistrationInterdisciplinaryAproachJustification: getText(job.StateRegistrationInterdisciplinaryAproachJustification),
    // stateRegistrationReports: convertMaybeArray(stageRegistrationReports, convertStateRegistrationReport),
    stateRegistrationScientificGroundwork: getText(job.StateRegistrationScientificGroundwork),
    status: getText(job.Status),
    stopMoneyDate: getText(job.StopMoneyDate),
    stopMoneyReason: job.StopMoneyReason ? convertStopMoneyReason(job) : null,
    techPlatforms: convertRefElements(techPlatforms),
    workSpaces: convertRefElements(workSpaces),
    type: job.Type ? convertItem(job.Type) : null,
    udks: convertRefElements(udks),

    securityDocumentContract: job.SecurityDocumentContract
      ? convertSecurityDocumentContract(job.SecurityDocumentContract, settings)
      : null,

    isRequestProvision: getText(job.IsRequestProvision) === 'true',
    requestProvisionAmount: getText(job.RequestProvisionAmount) || '0.00',
    requestProvisionType: convertProvisionType(job.RequestProvisionType),
    requestProvisionNote: getText(job.RequestProvisionNote),

    isContractProvision: getText(job.IsContractProvision) === 'true',
    contractProvisionAmount: getText(job.ContractProvisionAmount) || '0.00',
    contractProvisionType: convertProvisionType(job.ContractProvisionType),
    contractProvisionNote: getText(job.ContractProvisionNote),
    vaks: convertRefElements(vaks),
    nirRequestDataCopyDetail: getText(job.NirRequestDataCopyDetail),
    programRequestDataCopyDetail: getText(job.ProgramRequestDataCopyDetail),
    stateRegistrationEgisuUrl: getText(job.StateRegistrationEgisuUrl),
    purchaseRequests: convertMaybeArray(job.PurchaseRequests?.PurchaseRequest || [], convertPurchaseRequests),
    securityDocuments: convertMaybeArray(job.SecurityDocuments?.SecurityDocument || [], e =>
      convertSecurityDocument(e, settings),
    ),
    confidentialityLevel: job.ConfidentialityLevel ? convertReference(job.ConfidentialityLevel) : null,
    okpd: job.Okpd ? convertReference(job.Okpd) : null,
    isConfidentialityAlert: getBoolean(job.IsConfidentialityAlert),
    isRequiredStateRegistration: getBoolean(job.IsRequiredStateRegistration),
    isRequiredMilitaryStandard: getBoolean(job.IsRequiredMilitaryStandard),
    changes: convertMaybeArray(job.Changes?.Change || [], e => convertChange(e, settings)),
    equips: convertMaybeArray(job.Equips?.Equip || [], e => convertEquip(e, settings)),
    reports: convertMaybeArray(job.Reports?.Report || [], convertReport),
    restrictions: convertMaybeArray(job.Restrictions?.Restriction ?? [], convertRestriction),
  };
}

/** converter for Project field */
export function convertRestriction(restriction: any): Project.Restriction {
  const definition = restriction.ValueDefinition;

  const value = {
    type: getText(definition.Type),
    min: getText(definition.MinValue),
    max: getText(definition.MaxValue),
    precision: getText(definition.Precision),
  } as Project.RestrictionValue;

  return {
    id: getId(restriction),
    type: restriction.Type ? convertItem(restriction.Type) : null,
    isActive: getBoolean(restriction.IsActive),
    value,
    restrictValue: getText(restriction.RestrictValue) ?? '',
  };
}

/** converter for Project field */
export function convertReport(report: any): Project.Report {
  return {
    id: getId(report),
    block: report.Block ? convertItem(report.Block) : null,
    stage: report.Stage ? convertStage(report.Stage) : null,
    type: report.Type ? convertItem(report.Type) : null,
    name: getText(report.Name),
    number: getText(report.Number),
    archiveNumber: getText(report.ArchiveNumber),
    sendDate: getText(report.SendDate),
    acceptedDate: getText(report.AcceptedDate),
    customerDate: getText(report.CustomerDate),
    archiveSendDate: getText(report.ArchiveSendDate),
    archiveAcceptedDate: getText(report.ArchiveAcceptedDate),
    keyWords: getText(report.KeyWords),
    referat: getText(report.Referat),
    file: report.File ? convertFile(report.File) : undefined,
    note: getText(report.Note),
    approvements: convertMaybeArray(report.Approvements?.Approvement || [], convertApprovement),
  };
}

/** converter for Project field */
export function convertApprovement(approvement: any): Project.Approvement {
  return {
    expertGroup: convertReference(approvement.ExpertGroup),
    date: getText(approvement.Date),
    resolution: approvement.Resolution ? convertItem(approvement.Resolution) : null,
    note: getText(approvement.Note),
  };
}

/** converter for Project field */
export function convertEquip(equip: any, settings: Settings): Project.Equip {
  return {
    id: getId(equip),
    stage: equip.Stage ? convertStage(equip.Stage) : null,
    equip: equip.Equip ? convertEquipModel(equip.Equip, settings) : null,
    startDate: getText(equip.StartDate),
    endDate: getText(equip.EndDate),
    dayCount: getText(equip.DayCount),
    hourCount: getText(equip.HourCount),
    personCount: getText(equip.PersonCount),
    youngCount: getText(equip.YoungCount),
    note: getText(equip.Note),
  };
}

export function convertChange(change: any, settings: Settings): Project.Change {
  return {
    id: getId(change),
    type: change.Type ? convertItem(change.Type) : null,
    amountNew: getText(change.AmountNew),
    amoundOld: getText(change.AmountOld),
    changedBy: change.ChangedBy ? convertServerPerson(change.ChangedBy, settings) : null,
    isProcessed: getBoolean(change.IsProcessed),
    date: getText(change.Date),
    processedBy: change.ProcessedBy ? convertServerPerson(change.ProcessedBy, settings) : null,
    departmentNew: change.DepartmentNew ? convertReference(change.DepartmentNew) : null,
    departmentOld: change.DepartmentOld ? convertReference(change.DepartmentOld) : null,
    startDateNew: getText(change.StartDateNew),
    startDateOld: getText(change.StartDateOld),
    endDateNew: getText(change.EndDateNew),
    endDateOld: getText(change.EndDateOld),
    description: getText(change.Description),
    sourceNew: change.DepartmentNew ? convertReference(change.SourceNew) : null,
    sourceOld: change.DepartmentOld ? convertReference(change.SourceOld) : null,
    ndsNew: change.NdsNew ? getBoolean(change.NdsNew) : null,
    ndsOld: change.NdsOld ? getBoolean(change.NdsOld) : null,
  };
}

function convertPurchaseRequests(purchaseRequest: any): Project.PurchaseRequest {
  return {
    id: getId(purchaseRequest),
    type: convertItem(purchaseRequest.Type),
    status: convertItem(purchaseRequest.Status),
    name: getText(purchaseRequest.Name),
    sendDate: getText(purchaseRequest.SendDate),
  };
}

const convertProvisionType = (provisionType: any): Item | null => {
  const value = getText(provisionType);
  const label = getLabel(provisionType);
  if (value) {
    return {
      value,
      label,
    };
  }

  return null;
};

const convertResourceUsageDepartment = (resourceUsageDepartment: any): Project.ResourceUsageDepartment => {
  return {
    id: getId(resourceUsageDepartment),
    department: convertReference(resourceUsageDepartment?.Department),
    note: getText(resourceUsageDepartment?.Note),
  };
};

const convertProgramRequest = (project: any): Item<string> => {
  const programShortName = getText(project?.ProgramRequest?.Tender?.Program?.ShortName);
  const kindTradeLabel = getLabel(project?.ProgramRequest?.Tender?.KindTrade);
  const requestCode = getText(project?.ProgramRequest?.Code);
  const preparedLabel = `Заявка № ${requestCode} на конкурс ${programShortName} (${kindTradeLabel})`;

  return { label: preparedLabel, value: project?.ProgramRequest?._attributes?.id };
};

const convertStopMoneyReason = (project: any): Item<string> => ({
  label: getLabel(project.StopMoneyReason),
  value: getText(project.StopMoneyReason),
});

const convertNirRequest = (project: any): Item<string> => {
  const programShortName = getText(project?.NirRequest?.Program?.ShortName);
  const eventCode = getText(project?.NirRequest?.ProgramEvent?.Code);
  const tenderType = getLabel(project?.NirRequest?.TenderType);
  const nirNumber = getText(project?.NirRequest?.RequestNumber);

  const preparedLabel = `Заявка № ${nirNumber} на конкурс ${programShortName} (${eventCode}/${tenderType})`;

  return { label: preparedLabel, value: project?.NirRequest?._attributes?.id };
};

function convertContestRequest(request: any): Project.ContestRequest {
  return {
    id: getId(request),
    createdBy: getText(request.CreatedBy),
    year: getText(request.Year),
    type: request.Type ? convertItem(request.Type) : null,
    requestStageEndDate: getText(request.RequestStageEndDate),
    requestStageStartDate: getText(request.RequestStageStartDate),
    status: request.Status ? convertItem(request.Status) : null,
    projectNote: getText(request.ProjectNote),
    projectStageId: request.ProjectStage ? getId(request.ProjectStage) : null,
    dataCopyDetail: getText(request.DataCopyDetail),
  };
}

function convertItemProgramEvent(programEvent: any): Item {
  return {
    label: `${getText(programEvent.Code)} ${getText(programEvent.Name)}`,
    value: getId(programEvent),
  };
}

export const convertRefElements = (list: any) =>
  convertMaybeArray(list || [], convertRefElement).sort((a, b) => Math.abs(Number(a.position)) - Math.abs(Number(b.position)));

export function convertStage(stage: any): Project.Stage {
  const indicators = stage.Indicators?.Result ?? [];
  const indices = stage.Indices?.Result ?? [];

  return {
    id: getId(stage) || null,
    accompliceNdsPercent: getText(stage.AccompliceNdsPercent),
    amountAccomplice: getText(stage.AmountAccomplice),
    amountAccompliceUndistributed: getText(stage.AmountAccomplice?._attributes?.undistributed),
    amountCofinancing1: getText(stage.AmountCofinancing1),
    amountCofinancing1Undistributed: getText(stage.AmountCofinancing1?._attributes?.undistributed),
    amountCofinancing2: getText(stage.AmountCofinancing2),
    amountCofinancing2Undistributed: getText(stage.AmountCofinancing2?._attributes?.undistributed),
    amountExternalAccomplices: getText(stage.AmountExternalAccomplices),
    amountLocal: getText(stage.AmountLocal),
    amountLocalUndistributed: stage.AmountLocal?._attributes?.undistributed,
    amountMain: getText(stage.AmountMain),
    amountFact: getText(stage.AmountFact),
    amountMainUndistributed: stage.AmountMain?._attributes?.undistributed,
    cofinancing1NdsPercent: getText(stage.Cofinancing1NdsPercent),
    cofinancing2NdsPercent: getText(stage.Cofinancing2NdsPercent),
    endDate: getText(stage.EndDate),
    isLocked: getBoolean(stage.IsLocked),
    isPatentResearchPlanned: getBoolean(stage.IsPatentResearchPlanned),
    localNdsPercent: getText(stage.LocalNdsPercent),
    mainNdsPercent: getText(stage.MainNdsPercent),
    number: getText(stage.Number),
    startDate: getText(stage.StartDate),
    indicators: convertMaybeArray(indicators, convertIndicator),
    indices: convertMaybeArray(indices, convertIndicator),
    annotation: getText(stage.Annotation),
    expectedResults: getText(stage.ExpectedResults),
    goal: getText(stage.Goal),
    kindWork: stage.KindWork ? convertReference(stage.KindWork) : null,
    name: getText(stage.Name),
    task: getText(stage.Task),
    workDescription: getText(stage.WorkDescription),
    obtainedResults: getText(stage.ObtainedResults),
    universityEffect: getText(stage.UniversityEffect),
    regionalEffect: getText(stage.RegionalEffect),
    nationalEffect: getText(stage.NationalEffect),
    interdisciplinaryAproachJustification: getText(stage.InterdisciplinaryAproachJustification),
  };
}

function convertPartner(partner: any, settings: Settings): Project.Partner {
  const financings = partner.Financings?.Financing ?? [];

  return {
    id: getId(partner) || null,
    contractNumber: getText(partner.ContractNumber),
    contractType: partner.ContractType ? convertItem(partner.ContractType) : null,
    endDate: getText(partner.EndDate),
    startDate: getText(partner.StartDate),
    kindWork: getText(partner.KindWork),
    partner: convertServerPartner(partner.Partner, settings),
    role: convertItem(partner.Role),
    financings: convertMaybeArray(financings, convertPartnerFinancing),
    note: getText(partner.Note),
    contractName: getText(partner.ContractName),
    leaderAppointment: getText(partner.LeaderAppointment),
    leaderContacts: getText(partner.LeaderContacts),
    leaderDegree: getText(partner.LeaderDegree),
    leaderFullName: getText(partner.LeaderFullName),
    leaderRank: getText(partner.LeaderRank),
    leaderJobEnterprise: partner.LeaderJobEnterprise ? convertReference(partner.LeaderJobEnterprise) : null,
  };
}

function convertPartnerFinancing(financing: any): Project.PartnerFinancing {
  return {
    id: getId(financing) || null,
    amount: getText(financing.Amount),
    year: getText(financing.Year),
  };
}

function convertIndicator(indicator: any): Project.Indicator {
  return {
    id: getId(indicator) || null,
    fact: getText(indicator.Fact) || '0',
    plan: getText(indicator.Plan) || '0',
    note: getText(indicator.Note),
    year: getText(indicator.Year),
    ref: convertReference(indicator.ResultItem),
  };
}

export function convertCustomer(customer: any): Project.Customer {
  return {
    id: getId(customer) || null,
    ref: convertReference(customer.Element || customer.Customer),
  };
}

function convertDepartment(department: any): Project.Department {
  return {
    id: getId(department) || null,
    governance: convertReference(department.Governance),
    unit: convertReference(department.Unit),
  };
}

export function convertFinancing(financing: any): Project.Financing {
  return {
    id: getId(financing) || null,
    amount: getText(financing.Amount),
    factAmount: getText(financing.FactAmount),
    isNds: getBoolean(financing.IsNds),
    source: convertReference(financing.Source),
    type: convertItem(financing.Type),
  };
}

export function convertFinancingByYear(financing: any): Project.FinancingByYear {
  return {
    amountAccomplice: getText(financing.AmountAccomplice),
    amountExternalAccomplices: getText(financing.AmountExternalAccomplices),
    amountCofinancing1: getText(financing.AmountCofinancing1),
    amountCofinancing2: getText(financing.AmountCofinancing2),
    amountLocal: getText(financing.AmountLocal),
    amountMain: getText(financing.AmountMain),
    undistributedAmount: getText(financing.UndistributedAmount),
    year: getText(financing.Year),
    stages: [],
  };
}

export function convertRefElement(ref: any): Project.RefElement {
  return {
    id: getId(ref) || null,
    createdBy: getText(ref.CreatedBy?.FullName),
    createdDate: getText(ref.CreatedDate),
    element: convertReference(ref.Element),
    position: getText(ref.Position),
    isActual: getText(ref.IsActual) === 'true',
    isProject: getText(ref.IsProject) === 'true',
  };
}

export function convertPerformer(performer: any, settings: Settings): Project.Performer {
  const jobsPeriods = performer.JobPeriods?.JobPeriod ?? [];

  return {
    id: getId(performer) || null,
    fio: getText(performer.Fio),
    payments: performer.Payments ? getText(performer.Payments) : '',
    person: performer.Person ? convertServerPerson(performer.Person, settings) : null,
    jobPeriods: convertMaybeArray(jobsPeriods, j => convertJobPeriod(j, settings)),
    createdBy: getText(performer.CreatedBy?.FullName),
    createdDate: getText(performer.CreatedDate),
    isCurrent: getText(performer.IsCurrent) === 'true',
    isYoung: getText(performer.IsYoung) === 'true',
  };
}

function convertJobPeriod(jobPeriod: any, settings: Settings): Project.JobPeriod {
  return {
    id: getId(jobPeriod) || null,
    academicRank: jobPeriod.AcademicRank ? authorConverters.convertServerScientistAcademicRank(jobPeriod.AcademicRank) : null,
    citizenship: jobPeriod.Citizenship ? convertReference(jobPeriod.Citizenship) : null,
    degree: jobPeriod.Degree ? authorConverters.convertServerScientistDegree(jobPeriod.Degree) : null,
    education: jobPeriod.Education ? authorConverters.convertServerScientistEducation(jobPeriod.Education) : null,
    job: jobPeriod.Job ? authorConverters.convertServerScientistJob(jobPeriod.Job) : null,
    rank: jobPeriod.Rank ? authorConverters.convertServerScientistRank(jobPeriod.Rank) : null,
    role: convertItem(jobPeriod.Role),
    person: jobPeriod.Person ? convertServerPerson(jobPeriod.Person, settings) : null,
    historyLabel: getText(jobPeriod.HistoryLabel),
    endDate: getText(jobPeriod.EndDate),
    endDateOrder: jobPeriod.EndDateOrder ? convertJobPeriodOrder(jobPeriod.EndDateOrder) : null,
    startDate: getText(jobPeriod.StartDate),
    startDateOrder: jobPeriod.StartDateOrder ? convertJobPeriodOrder(jobPeriod.StartDateOrder) : null,
    note: getText(jobPeriod.Note),
  };
}

export function convertJobPeriodOrder(o: any): JobPeriodOrder {
  return {
    id: getId(o),
    number: getText(o.Number),
    date: getText(o.Date),
    type: convertItem<string>(o.Type),
  };
}

export function convertProjectFeedline(feedline: any): Project.Feedline {
  return {
    id: getId(feedline) || null,
    detail: getText(feedline.Detail),
    message: convertReference(feedline.Message),
    operator: convertItem(feedline.Operator),
    type: convertItem(feedline.Type),
  };
}

export function getPerformersFromSecurityDocuments(securityDocuments: any[], settings: Settings): Project.Performer[] {
  const docs = Array.isArray(securityDocuments) ? securityDocuments : [securityDocuments];

  return docs.flatMap((doc: any) => {
    const members: any[] = doc.Members?.Member ?? [];
    return convertMaybeArray(members, m => convertSecurityAuthor(m, settings));
  });
}

function convertSecurityAuthor(author: any, settings: Settings) {
  const person = convertServerPerson(author.Person, settings);
  return {
    id: null,
    fio: person.fullName,
    person,
    createdBy: '',
    createdDate: '',
    jobPeriods: [],
    stages: [],
    paymentMethods: '',
  };
}

export const convertCheckResult = (response: any): Project.CheckResult => {
  const checkResult = response.Response?.CheckResult;

  return {
    value: checkResult?._attributes?.value === 'true',
    codes: checkResult?._attributes?.codes ?? '',
  };
};

function convertAct(act: any): Project.Act {
  return {
    id: getId(act),
    number: getText(act.Number),
    stage: convertActStage(act.Stage),
    departments:
      act.Departments && act.Departments.Department
        ? convertMaybeArray(act.Departments.Department || [], convertActDepartment)
        : [],
    сonsiderationDocuments: getText(act.ConsiderationDocuments),
    defaultBaseProgram: getText(act.DefaultBaseProgram),
    council: act.Council ? convertCouncil(act.Council) : null,
    conclusion: getText(act.Conclusion),
    note: getText(act.Note),
    members: act.Members && act.Members.Member ? convertMaybeArray(act.Members.Member || [], convertMember) : [],
  };
}

function convertCouncil(c: any): Project.Council {
  return {
    id: getId(c),
    isLocal: getText(c.IsLocal) === 'true',
    type: convertItem(c.Type),
    status: convertItem(c.Status),
    code: getText(c.Code),
  };
}

function convertActDepartment(actDepartment: any): Project.ActDepartment {
  return {
    id: getId(actDepartment),
    department: convertDepartment(actDepartment.Department),
  };
}

function convertMember(member: any): Project.ActMember {
  return {
    id: getId(member),
    councilMember: convertCouncilMember(member.CouncilMember),
    ...convertPersonHistory(member),
  };
}

function convertCouncilMember(member: any): Project.CouncilMember {
  return {
    id: getId(member),
    label: member?.Person?._attributes?.fullName || '',
    role: convertItem(member.Role),
  };
}

function convertActStage(stage: any): Project.ActStage {
  return {
    id: stage._attributes.id,
    number: getText(stage.Number),
    label: `${getText(stage.Number)} (${getText(stage.StartDate)} - ${getText(stage.EndDate)})`,
  };
}

import React from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { EditMagazineArticleOriginalTranslatesRelations } from './views/EditMagazineArticleOriginalTranslatesRelations';
import { AuthorsComponent } from 'features/Form/views/AuthorsComponent';
import { ControlPanel } from './views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const PublicationFormArticle = ({ viewMode, onClose }: Props) => {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    eventId,
    workMode,
    formFields,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    onCreateBibliographicRecordClick,
    relatedTableState,
    sourceMagazine,
    sourceMagazineRelease,
    changeSourceMagazine,
    changeSourceMagazineRelease,
    onDeleteSourceMagazine,
    onDeleteSourceMagazineRelease,
    isElectronic,
    electronicType,
    publicationId,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    projects,
    mobileRequests,
    authors,
    setAuthors,
    documents,
    setDocuments,
    topMessage,
    setTopMessage,
    sourceMagazineOriginal,
    sourceMagazineTranslates,
    originalArticle,
    translateArticles,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    handleOpenPublicationOriginalTranslatesRelations,
    isRequestPaymentView,
    isShowDetailedTitle,
    magazineReleaseReport,
    magazineReleaseConference,
    paymentString,
    foreignSource,
    filterMagazineReleaseId,
    sourcePublicationDate,
    isConferenceConnection,
    handleCloseConferenceConnectionModal,
    handleOpenAddConferenceParticipation,
    isSourceWarningPopupOpen,
    handleCloseSourceWarningPopup,
    setOriginalArticle,
    setTranslateArticles,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
    setProjects,
    setMobileRequests,
  } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />
      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />
      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={handleCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: handleConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: handleResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <div>
          <strong>
            В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
            списке «Все публикации»
          </strong>
        </div>

        <p>Вашей персоны нет в списке авторов. Добавить?</p>
      </Modal>

      {!viewMode && (
        <ControlPanel
          handleSave={handlePublicationSave}
          handleSaveAsDraft={handlePublicationSaveAsDraft}
          handleSaveAndContinue={handlePublicationSaveAndContinue}
          workMode={workMode}
          isOnlyEditing={isRequestPaymentView}
          isDisabled={isLoading}
          handleOpenPublicationOriginalTranslatesRelations={handleOpenPublicationOriginalTranslatesRelations}
        />
      )}

      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="О публикации">
            <TabsContent.BibliographyComponent
              formFields={formFields}
              workMode={workMode}
              isElectronic={isElectronic}
              electronicType={electronicType}
              originalArticle={originalArticle}
              translateArticles={translateArticles}
              publicationInfo={publicationInfo}
              isShowDetailedTitle={isShowDetailedTitle ?? false}
              userDepartments={userDepartments}
              publicationId={publicationId}
              publicationDepartment={publicationDepartment}
              setPublicationDepartment={setPublicationDepartment}
              arm={arm}
            />
          </Tab>
          <Tab title="Сведения об авторстве">
            <AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
          </Tab>
          <Tab title="Сведения об издании">
            <TabsContent.SourceComponent
              publicationId={publicationInfo?.status.id || ''}
              isElectronic={isElectronic}
              filterMagazineReleaseId={filterMagazineReleaseId}
              relatedTableState={relatedTableState}
              formFields={formFields}
              onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
              workMode={workMode}
              magazineReleaseConference={magazineReleaseConference}
              changeSourceMagazine={changeSourceMagazine}
              changeSourceMagazineRelease={changeSourceMagazineRelease}
              onDeleteSourceMagazine={onDeleteSourceMagazine}
              onDeleteSourceMagazineRelease={onDeleteSourceMagazineRelease}
              sourceMagazine={sourceMagazine}
              sourceMagazineRelease={sourceMagazineRelease}
              sourceMagazineOriginal={sourceMagazineOriginal}
              sourceMagazineTranslates={sourceMagazineTranslates}
              magazineReleaseReport={magazineReleaseReport}
              sourcePublicationDate={sourcePublicationDate}
              eventId={eventId}
            />
          </Tab>
          <Tab title="О публикации (анг.)">
            <TabsContent.AboutEnglishComponent formFields={formFields} viewMode={viewMode} foreignSource={foreignSource} />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexing.Component
              citationSystems={publicationInfo?.citationSystems ?? []}
              relatedTableState={relatedTableState}
              publicationId={publicationId || null}
              publicationType={publicationInfo?.type ?? ''}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              workMode={workMode}
              sourceId={publicationInfo?.magazine?.id}
              releaseId={publicationInfo?.magazineRelease?.id}
              topMessage={topMessage}
              setTopMessage={setTopMessage}
              isVak={publicationInfo?.fields.isVak === 'true'}
            />
          </Tab>
          <Tab title="Финансовая поддержка">
            <TabsContent.FinancialSupport.Component
              workMode={workMode}
              relatedTableState={relatedTableState}
              projects={projects}
              setProjects={setProjects}
              mobileRequests={mobileRequests}
              setMobileRequests={setMobileRequests}
              incentivePaymentRequest={paymentString}
            />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationId}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.Documents documents={documents} setDocuments={setDocuments} viewMode={viewMode} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <EditMagazineArticleOriginalTranslatesRelations
        isOpen={isPublicationOriginalTranslatesRelationsOpen}
        disabled={workMode !== 'editMode'}
        onClose={handleClosePublicationOriginalTranslatesRelations}
        relatedTableState={relatedTableState}
        magazinePublicationOriginal={sourceMagazineOriginal}
        magazinePublicationTranslates={sourceMagazineTranslates}
        magazineArticlePublicationOriginal={originalArticle}
        magazineArticlePublicationTranslates={translateArticles}
        magazinePublicationName={sourceMagazineOriginal?.name || ''}
        magazineArticlePublication={publicationInfo}
        setMagazineArticlePublicationTranslates={setTranslateArticles}
        setMagazineArticlePublicationOriginal={setOriginalArticle}
        isMagazineOriginal={Boolean(sourceMagazineTranslates.length)}
        isMagazineTranslate={Boolean(sourceMagazineOriginal?.id)}
      />

      <Modal
        mode="info"
        title="Создать запись об участии в конференции на основе метаданных публикации"
        isOpen={isConferenceConnection}
        onClose={handleCloseConferenceConnectionModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Добавить запись об участии в конференции',
            onClick: handleOpenAddConferenceParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConferenceConnectionModal,
          },
        ]}
        size="medium"
      >
        <>
          <strong className="state-danger">Внимание:</strong> Для отчётности по научной деятельности (персональная, по
          подразделению, отчёт по НИР и ОКР, в заявках на внутренний конкурс и прочее) показатели Участия в научных мероприятиях
          (доклады, награды, уровень мероприятия и пр.) рассчитываются по данным записей &ldquo;Участия в конференциях&ldquo;
        </>
      </Modal>

      <Modal
        mode="info"
        title="Предупреждение"
        isOpen={isSourceWarningPopupOpen}
        onClose={handleCloseConferenceConnectionModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: handleCloseSourceWarningPopup,
          },
        ]}
        size="small"
      >
        <>Для установки связи с публикацией заполните поле "Название журнала"</>
      </Modal>
    </FormComponent.Template>
  );
};

export const Component = React.memo(PublicationFormArticle);

import { Person } from 'types/models';

export function validate(item: Person.HirshIndex | null) {
  const isValidCitationSystem = Boolean(item?.citationSystem && item?.citationSystem.id);
  const isValidDate = Boolean(item?.date);

  return [
    { isValid: isValidCitationSystem, invalidMessage: 'Выберите Систему цитирования' },
    { isValid: isValidDate, invalidMessage: 'Укажите Дату расчета' },
  ];
}

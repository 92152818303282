import { getMaybeField } from 'services/BackendAPI/configurations/commonConverters';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { CommissionMember, ProtocolCommission } from 'types/models/ProtocolCommission';

export function convertProtocolCommissionToRequest(comm: ProtocolCommission) {
  return {
    _attr: comm.id ? { id: comm.id } : {},
    Name: comm.name,
    OrderNumber: comm.orderNumber,
    OrderDate: comm.orderDate,
    ...getMaybeField('Note', comm.note),
    Members: comm.members?.length ? { Member: comm.members?.map(convertCommissionMemberToRequest) } : {},
  };
}
export function convertCommissionMemberToRequest(member: CommissionMember) {
  return {
    _attr: member.id ? { id: member.id } : {},
    Role: member.role?.value,
    Priority: member.priority,
    ProtocolName: member.protocolName,
    ...(member.person ? { Person: { _attr: { id: member.person.id } } } : {}), // temp
    ...getHistoricalSliceXML(member),
  };
}

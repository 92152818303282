import React, { useCallback } from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { Project } from 'types/models/Project';
import { Original } from 'types/models/Form';

import { GetFullScienceProjectList } from 'features/Table/specifications';

type ProjectProps = {
  securityDocument: SecurityDocument;
  disabled?: boolean;
  handleChangeProject(projectId?: string): void;
};

export function ProjectInput({ securityDocument, disabled, handleChangeProject }: ProjectProps) {
  const projectOriginal = useCallback((project: Project | null): Original | null => {
    if (!project) return null;
    return {
      id: project.id || '',
      name: `${project.type?.label}, ${project.number}, ${project.startDate} - ${project.endDate}, ${project.name}`,
    };
  }, []);

  return (
    <>
      <TextArea
        mode={TextAreaMode.TABLE}
        settings={{
          title: 'Проекты',
          table: {
            specification: GetFullScienceProjectList({}),
            onSelect: (row: Table.Entry | null) => handleChangeProject(row?.id || undefined),
          },
        }}
        value={projectOriginal(securityDocument.project)?.name}
        isDisabled={disabled}
      />

      <FormComponent.Description mode="info" classMixin="is-hint">
        {(securityDocument.project?.departments || []).map((x, index) => (
          <div key={index}>
            <strong>Подразделение</strong>
            {` (${x.unit?.customFields?.code}) ${x.unit?.customFields?.name}.`} <strong>Факультет</strong>
            {` (${x.governance?.customFields?.code}) ${x.governance?.customFields?.name}`}
          </div>
        ))}
      </FormComponent.Description>
    </>
  );
}

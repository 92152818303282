import { Person } from 'types/models';

export const copyAcademicRank = (academicRank: Person.ScientistAcademicRank | null) => {
  if (academicRank?.originalId) {
    const copy: Person.ScientistAcademicRank = { ...academicRank };
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyJob = (job: Person.ScientistJob | null) => {
  if (job?.originalId) {
    const copy: Person.ScientistJob = { ...job };
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyEducation = (education: Person.ScientistEducation | null) => {
  if (education?.originalId) {
    const copy: Person.ScientistEducation = { ...education };
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyDegree = (degree: Person.ScientistDegree | null) => {
  if (degree?.originalId) {
    const copy: Person.ScientistDegree = { ...degree };
    copy.id = null;
    return copy;
  }

  return null;
};

export const copyRank = (rank: Person.ScientistRank | null) => {
  if (rank?.originalId) {
    const copy: Person.ScientistRank = { ...rank };
    copy.id = null;
    return copy;
  }

  return null;
};

import React, { memo } from 'react';

import { FormComponent, TextDateTime } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const DateRange = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { canBuild, onChange, valueFrom, valueTo, dateRangeParameter } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field label="Период" isRequired={dateRangeParameter?.required}>
            <span>
              <TextDateTime value={valueFrom || ''} onChange={(value: string) => onChange('from', value)} />
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span>
              <TextDateTime value={valueTo || ''} onChange={(value: string) => onChange('to', value)} />
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DateRange);

export { Component as DateRange };

import React from 'react';

import { FormComponent, SectionTitle } from 'components';

import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { Requests } from 'features/Form/looks/participation/views/Requests';
import { ParticipationProject } from 'types/models/Participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  financingProjects: ParticipationProject[];
  setProjects: (projects: ParticipationProject[]) => void;
  mobileRequests: PublicationMobileRequest[];
  setMobileRequests: (requests: PublicationMobileRequest[]) => void;
  paymentString: string;
};

function FinancialSupport({
  relatedTableState,
  workMode,
  financingProjects,
  setProjects,
  mobileRequests,
  setMobileRequests,
  paymentString,
}: Props) {
  return (
    <>
      {paymentString && (
        <>
          <FormComponent.Description mode="info">
            <SectionTitle title="Стим. выплаты:" />
            <span>{paymentString}</span>
          </FormComponent.Description>
          <SectionTitle title="Организовано при финансовой поддержке" />
        </>
      )}

      <FormComponent.Template>
        <Projects
          workMode={workMode}
          participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
          projects={financingProjects}
          setProjects={setProjects}
          relatedTableState={relatedTableState}
          isDisabled={workMode === 'viewMode'}
          isMaximized
          // eslint-disable-next-line max-len
          tooltipProjectText="Отметка о принятии к отчету свидетельствует о том, что участие в выставке является одним из результатов проекта."
          // eslint-disable-next-line max-len
          tooltipText='Указать проект (грант), связанный с участием в выставке. Сведения об участии будет отображаться в карточке проекта на вкладке "Результаты".'
        />
        <Requests
          workMode={workMode}
          participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
          requests={mobileRequests}
          setMobileRequests={setMobileRequests}
          relatedTableState={relatedTableState}
          isMaximized
        />
      </FormComponent.Template>
    </>
  );
}

export const Component = React.memo(FinancialSupport);

import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';

import { showErrors } from 'utils/Validators';
import { Project } from 'types/models';
import useController from './controller';
import { getMockDepartment } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  departments: Project.Department[];
  projectId?: string;
  setDepartments(departments: Project.Department[]): void;
  withMessages?: boolean;
  disabled: boolean;
};

export function Departments(props: Props) {
  const { departments, projectId, setDepartments, withMessages = true, disabled } = props;

  const {
    isDeleteWarningOpen,
    onCloseDeleteWarningPopup,
    deleteWarningText,
    isLoading,
    onPreDelete,
    onPreEdit,
    codes,
  } = useController({
    projectId,
  });

  return (
    <>
      <ListEdit
        rows={departments}
        onChange={setDepartments}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Факультет/Институт', formatValue: x => x.governance?.label || '' },
          { label: 'Подразделение', formatValue: x => x.unit?.label || '' },
        ]}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled || isLoading}
        defaultRowsCount={3}
        maxHeight="350px"
        specification={{
          mode: 'customComponent',
          onPreDelete,
          onPreEdit,
          renderComponent: (department, setDepartment) => (
            <Fields department={department || getMockDepartment()} setDepartment={setDepartment} codes={codes} />
          ),
          validation: {
            checkIsValid: department => validate(department).every(x => x.isValid),
            onInvalidate: val => showErrors(validate, val),
          },
        }}
      />

      <Modal
        mode="warning"
        title="Невозможно удалить элемент"
        isOpen={isDeleteWarningOpen}
        onClose={onCloseDeleteWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: onCloseDeleteWarningPopup,
          },
        ]}
        size="small"
      >
        <>{deleteWarningText}</>
      </Modal>
    </>
  );
}

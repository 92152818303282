import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

const useController = () => {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [setIsHelpFormOpen],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
};

export default useController;

import { useState, useLayoutEffect, useCallback, useEffect } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { validateContestCommission } from './validators';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { ProtocolCommission } from 'types/models/ProtocolCommission';
import { getMockProtocolCommission } from './helpers';
import { ValueOf } from 'types/helpers';
import { showNotification } from 'features/Notifications';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
};

export function useController({ onClose }: Props) {
  const {
    look: { id, viewMode, editMode, setTitle },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const [commission, setCommission] = useState<ProtocolCommission>(getMockProtocolCommission());

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const { methods: saveProtocolCommission } = BackendAPI.useBackendAPI('SaveProtocolCommission');

  const { methods: getProtocolCommission } = BackendAPI.useBackendAPI('GetProtocolCommission', {
    onSuccessfullCall: ({ data }) => {
      setCommission(data);
    },
  });

  const onSubmit = useCallback(
    (needClose?: boolean) => {
      if (!validateContestCommission(commission)) return;
      saveProtocolCommission.callAPI(
        { commission },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Успешно сохранено', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data?.id) {
              getProtocolCommission.callAPI({ id: data?.id });
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [commission, saveProtocolCommission, getProtocolCommission, onClose, tableStreams, updateWorkModeAfterSaveAndContinue],
  );

  const handleFormSubmit = useCallback(
    (needClose?: boolean) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  const makeChangeHandler = (key: keyof ProtocolCommission) => (value: ValueOf<ProtocolCommission>) => {
    setCommission({ ...commission, [key]: value });
  };

  useLayoutEffect(() => {
    if (id) {
      getProtocolCommission.callAPI({ id });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setTitle?.(
      getStatusMessage(
        [workMode === 'viewMode' ? 'Просмотр' : !commission?.id ? 'Добавление' : `Редактирование`, 'Комиссии']
          .filter(Boolean)
          .join(' '),
        commission?.id || '',
      ),
    );
  }, [workMode, commission, setTitle]);

  return {
    id,
    viewMode,
    workMode,
    commission,
    handleFormSubmit,
    makeChangeHandler,
  };
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectCodeGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Форма содержит список шифров проектов.</p>
        <p>
          Фильтр <strong>Изменены условия проекта</strong> — отображает список шифров проектов, у которых в Журнале изменений
          данных зафиксирована информация об изменении в проекте ключевых атрибутов: сроки проекта, сумма финансирования в этапе,
          подразделение, на базе которого выполняется проект.
        </p>
        <p>
          Фильтр <strong>Проекты, не имеющие шифры</strong> — отображает список проектов без связи с финансовым лицевым счётом
          проекта в системе бухгалтерского учёта (шифр проекта).
        </p>
        <p>
          Фильтр <strong>Проекты с нераспределенной суммой</strong> — отображает список проектов, у которых календарный план
          проекта не совпадает с календарными планами шифров проекта. Одна из причин: в шифре проекта не заполнен раздел
          «Финансирование шифра по годам» на вкладке Финансовый план
        </p>
        <p>
          Фильтр <strong>Удаленные шифры</strong> — отображает список шифров проекта с атрибутом «Удалён». Поскольку Шифру проекта
          при удалении присваивается атрибут «Удалён». Поскольку шифры проектов соответствуют финансовые лицевые счёта проекта в
          системе бухгалтерского учёта.
        </p>
        <p>
          Фильтр <strong>Год</strong> – отображает список проектов, у которых сроки выполнения пересекаются с указанным годом.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Тип проекта</td>
              <td>Тип проекта.</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта.</td>
            </tr>
            <tr>
              <td>Код номера проекта</td>
              <td>Код номера проекта.</td>
            </tr>
            <tr>
              <td>Название проекта</td>
              <td>Название проекта.</td>
            </tr>
            <tr>
              <td>Состояние проекта</td>
              <td>
                В зависимости от сроков проекта по состоянию на Сегодня состояние проекта: <strong>Завершён</strong> или{' '}
                <strong>Текущий</strong>.
              </td>
            </tr>
            <tr>
              <td>Руководитель проекта</td>
              <td>Фамилия, имя и отчество руководителя проекта.</td>
            </tr>
            <tr>
              <td>Ответственный исполнитель по проекту</td>
              <td>Фамилия, имя и отчество ответственного исполнителя по проекту.</td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>Дата окончания проекта.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>Название программы.</td>
            </tr>
            <tr>
              <td>Мероприятие</td>
              <td>Название мероприятия.</td>
            </tr>
            <tr>
              <td>Шифр</td>
              <td>Номер шифра.</td>
            </tr>
            <tr>
              <td>Состояние шифра</td>
              <td>
                <p>
                  <strong>Активный</strong> – это значит, что в ИС бухгалтерского учёта этот шифр используется в качестве
                  финансового лицевого счёта;
                </p>
                <p>Вместо &lt;шифр проекта&gt; если шифр проекта был изменён. При изменении шифра:</p>
                <p>
                  В системе сохраняется старое значение шифра и новое значение шифра; поскольку в ИС бухгалтерского учёта к ФЛС
                  «старое значение» могут быть «привязаны» документы доходов или расходов проекта, то шифр с новым значением
                  наследует все эти документы.
                </p>
                <p>
                  <strong>Удалённый</strong> – шифр проекта в базе данных не удаляется физически, поскольку при наличии интеграции
                  с ИС Бухучёта важно синхронизировать процессы двух систем. Процедура физического удаления выполняется по
                  отдельному регламенту.
                </p>
              </td>
            </tr>
            <tr>
              <td>Ответственный исполнитель по шифру</td>
              <td>Фамилия, имя и отчество ответственного исполнителя по шифру.</td>
            </tr>
            <tr>
              <td>Подразделение</td>
              <td>
                Список подразделений, которые указаны в проекте в разделе: <strong>Проект выполняется на базе</strong>.
              </td>
            </tr>
            <tr>
              <td>Факультет/Институт</td>
              <td>
                Список подразделений на уровне факультета/ института, которые указаны в проекте в разделе:{' '}
                <strong>Проект выполняется на базе</strong>.
              </td>
            </tr>
            <tr>
              <td>Источник финансирования</td>
              <td>Наименование источника финансирования.</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <p>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</p>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с шифром. Открывает форму с данными о шифре для просмотра. Состав
                формы зависит от наличия у пользователя <strong>прав на просмотр вкладок</strong> формы.
              </td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с шифром. Открывает форму приказа для редактирования.</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму добавления шифра.</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                <p>Удалить приказ о шифре.</p>
                <p>Кнопка активна, если в таблице выделена запись с шифром.</p>
              </td>
            </tr>
            <tr>
              <td>
                Заменить шифр: <Icon type="documents" mode="add" />
              </td>
              <td>
                <p>При изменении шифра:</p>
                <p>
                  В системе сохраняется старое значение шифра – как шифр, который помечается как «заменён на ….» и новое значение
                  шифра – новый шифр; поскольку в ИС бухгалтерского учёта к ФЛС - «старое значение» могут быть привязаны документы
                  доходов или расходов проекта, то шифр с новым значением наследует все эти документы
                </p>
                <p>
                  Состояние шифра «<strong>Активный</strong>» – это значит, что в ИС бухгалтерского учёта этот шифр используется в
                  качестве финансового лицевого счёта;
                </p>
                <p>
                  Состояние шифра «<strong>Вместо &lt;шифр проекта&gt;</strong>» - если шифр проекта был изменён.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                Создать смету для группы проектов: <Icon type="calc" />
              </td>
              <td>
                <p>
                  Процедура используется для автоматического создания однотипной сметы на год для группы выделенных шифров. В
                  смете автоматически будет добавлена только 1 статья затрат для учёта накладных расходов.
                </p>
                <div className="warning">
                  Накладные расходы для выделенной группы шифров будут учитываться на едином шифре Централизованного фонда
                  накладных расходов
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Доступ подразделений к шифру: <Icon type="relation" />
              </td>
              <td>
                <p>
                  Функционал служит для организации доступа ответственных от подразделения к «своим» шифрам проекта на этой форме.
                </p>
                <p>
                  То есть если у пользователя установлено право "Просмотр списка шифров, введение которых курируется
                  подразделением пользователя", то информация о шифре будет доступна пользователям, которые закреплены за
                  указанными ниже подразделениями.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                Отчеты: <Icon type="print" />
              </td>
              <td>
                Служит для формирования отчётов по ШИФРУ. На форме Параметры отчёта кнопка <strong>Описание отчёта</strong>{' '}
                открывает файл, с описанием правил, по которым формируется отчёт.
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

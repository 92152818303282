import { Table as T } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export type Prop = Omit<State, 'publicationType'> & {
  publicationType: string;
};

export function GetPublicationCitationSystemIndexList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  deps: Prop,
): T.Specification<State> {
  return {
    apiID: 'GetPublicationCitationSystemIndexList',
    isFiltersHidden: templatesTableDependencies.isFiltersHidden,
    templatesTableDependencies,
    header: {
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController(deps),
  };
}

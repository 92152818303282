import { Table as T } from 'types/models';

export type DetailProps = {
  colleagueScientistId: string;
  scientistId: string;
};
export function GetScientistColleagueEventList({ colleagueScientistId, scientistId }: DetailProps): T.Specification<object> {
  return {
    apiID: 'GetScientistColleagueEventList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { colleagueScientistId, scientistId },
  };
}

import React, { Dispatch, SetStateAction } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit, Radio, ReferenceItem, FormComponent, TextArea, TextAreaMode, TextDateTime, TextInput } from 'components';

import { GetPartnerList, GetReferenceElementList } from 'features/Table/specifications';
import { Table, Community } from 'types/models';
import { Settings } from 'types/models/common';
import { useController } from './controller';

type Props = {
  workMode: Table.WorkMode;
  community: Community;
  setCommunity: Dispatch<SetStateAction<Community>>;
  isNotDissertationCouncil: boolean;
  settings: Settings | null;
};

export function AboutFields(props: Props) {
  const { workMode, community, setCommunity, isNotDissertationCouncil, settings } = props;
  const { affiliationRowConverter } = useController({
    community,
    setCommunity,
  });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Полное название (краткое название)" isRequired>
          <TextInput
            value={community?.code}
            onChange={e => setCommunity(prev => ({ ...prev, code: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!isNotDissertationCouncil && (
        <FormComponent.Line>
          <FormComponent.Field label="Дата основания">
            <TextDateTime
              value={community?.foundationDate}
              onChange={e => setCommunity(prev => ({ ...prev, foundationDate: e }))}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="Организация">
          <Radio
            isDisabled={workMode === 'viewMode'}
            value={community?.isLocal ? 'true' : 'false'}
            list={[
              { label: settings?.organization?.shortName, value: 'true' },
              { label: 'Другая организация', value: 'false' },
            ]}
            onChange={e => setCommunity(prev => ({ ...prev, isLocal: e === 'true' }))}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          {!community?.isLocal && (
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Список организаций',
                table: {
                  specification: GetPartnerList({ toolbarMode: 'ref', hasSelectButton: true }),
                  onSelect: (e: Table.Entry | null) =>
                    setCommunity(prev => ({ ...prev, affiliation: e ? affiliationRowConverter(e) : null })),
                },
              }}
              value={
                community?.affiliation?.partner?.shortName ||
                community?.affiliation?.enterprise?.label ||
                community?.affiliation?.enterpriseCustomer?.label
              }
              isDisabled={workMode === 'viewMode' || community?.isLocal}
            />
          )}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Номер приказа">
          <TextInput
            value={community?.orderNumber}
            onChange={e => setCommunity(prev => ({ ...prev, orderNumber: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Дата приказа">
          <TextDateTime
            value={community?.orderDate}
            onChange={e => setCommunity(prev => ({ ...prev, orderDate: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Дата окончания действия сообщества">
          <TextDateTime
            value={community?.endDate}
            onChange={e => setCommunity(prev => ({ ...prev, endDate: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            settings={{ rows: 3 }}
            value={community?.note}
            onChange={e => setCommunity(prev => ({ ...prev, note: e }))}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!isNotDissertationCouncil && (
        <>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                header={{
                  title: 'Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)',
                }}
                rows={community?.vaks || []}
                onChange={e => setCommunity(prev => ({ ...prev, vaks: e }))}
                toolbar={['add', 'edit', 'delete']}
                columns={[
                  { label: 'Код', formatValue: ref => ref.element.code || '', styles: { width: '10%' } },
                  { label: 'Наименование', formatValue: ref => ref.element.label, styles: { width: '40%' } },
                  {
                    label: 'Группа специальностей',
                    formatValue: ref => (ref.element.customFields?.specialityGroup as string) || '',
                    styles: { width: '25%' },
                  },
                  {
                    label: 'Отрасль науки',
                    formatValue: ref => (ref.element.customFields?.specialityBrunch as string) || '',
                    styles: { width: '25%' },
                  },
                ]}
                isDisabled={workMode === 'viewMode'}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'loadInstanceTableModal',
                  onStartLoad: (tableRows, onSuccess) => {
                    loadReferenceAPI.callAPI(
                      { filters: [], referenceName: 'RefVakSpeciality', childIds: tableRows.map(x => x.id) },
                      {
                        onSuccessfullCall: ({ data }) =>
                          onSuccess(
                            data.map(e => ({
                              id: null,
                              element: e,
                            })),
                          ),
                      },
                    );
                  },
                  relationTableModalTitle:
                    // eslint-disable-next-line max-len
                    'Справочник "Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)"',
                  modalTableSpecification: GetReferenceElementList({ requestData: { filters: [], name: 'RefVakSpeciality' } }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field>
              <ListEdit
                header={{ title: 'ГРНТИ' }}
                rows={community?.grntis || []}
                onChange={e => setCommunity(prev => ({ ...prev, grntis: e }))}
                toolbar={['add', 'edit', 'delete']}
                columns={[
                  { label: 'Код', formatValue: ref => ref.element.code || '', styles: { width: '10%' } },
                  { label: 'Наименование', formatValue: ref => ref.element.label, styles: { width: '50%' } },
                  {
                    label: 'Группа',
                    formatValue: ref => (((ref.element.customFields?.parent as ReferenceItem) || '').label as string) || '',
                    styles: { width: '40%' },
                  },
                ]}
                isDisabled={workMode === 'viewMode'}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'loadInstanceTableModal',
                  onStartLoad: (tableRows, onSuccess) => {
                    loadReferenceAPI.callAPI(
                      { filters: [], referenceName: 'RefGrnti', childIds: tableRows.map(x => x.id) },
                      { onSuccessfullCall: ({ data }) => onSuccess(data.map(e => ({ element: e, id: null }))) },
                    );
                  },
                  relationTableModalTitle: 'ГРНТИ',
                  modalTableSpecification: GetReferenceElementList({ requestData: { filters: [], name: 'RefGrnti' } }),
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </>
  );
}

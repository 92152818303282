import { Department } from 'types/models/Payment';

export function validate(department: Department | null) {
  if (!department) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidAmount = Boolean(department.amountMoney);

  return [{ isValid: isValidAmount, invalidMessage: 'Введите сумму' }];
}

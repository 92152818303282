import { Document } from 'types/models';
import { FieldsType } from './index';

export function validate(doc: Document | null, fieldsType: FieldsType | null, isInProgram: boolean) {
  if (!doc) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidName = Boolean(doc.name);
  const isValidDate = Boolean(doc.date);
  const isValidFile = Boolean(doc.file.id);
  const isValidPartnerDocumentType = fieldsType !== 'details' || !!doc.partnerDocumentType;
  const isValidDocumentType = [
    'purchaseRequest',
    'securityDocument',
    'securityDocumentContract',
    'foreignSecurityDocument',
  ].includes(fieldsType || '')
    ? !!doc.documentType
    : fieldsType === 'projectDocument'
    ? !!doc.projectDocumentType?.id
    : true;
  const isValidProgram = !isInProgram || !!doc.program?.id;
  const isValidDetail = !!doc?.projectDocumentType?.customFields?.note ? !!doc?.detail : true;

  return [
    { isValid: isValidName, invalidMessage: 'Заполните название документа' },
    { isValid: isValidDate, invalidMessage: 'Выберите дату документа' },
    { isValid: isValidFile, invalidMessage: 'Выберите файл' },
    { isValid: isValidPartnerDocumentType, invalidMessage: 'Укажите вид документа' },
    { isValid: isValidDocumentType, invalidMessage: 'Выберите тип документа' },
    { isValid: isValidProgram, invalidMessage: 'Выберите программу' },
    { isValid: isValidDetail, invalidMessage: 'Не указаны подробности' },
  ];
}

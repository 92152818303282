import React, { memo } from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { Form } from 'features/Form';
import useController from './controller';
import { DashboardDetailMagazineArticleGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

const ToolbarTemplate = ({ tableState }: Props) => {
  const { isOpenHelpForm, setIsOpenHelpForm, toolbarButtons, isViewFormOpen, closeViewForm, look, title } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={toolbarButtons} />

      <Modal isOpen={isViewFormOpen} onClose={closeViewForm} title={title} size="full" isViewMode>
        <Form look={look} onClose={closeViewForm} />
      </Modal>

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {DashboardDetailMagazineArticleGridHelp()}
      </Modal>
    </>
  );
};

export default memo(ToolbarTemplate);

import { ScientistPerson } from 'types/models/Person';
import {
  getInitCitizenship,
  getInitDegree,
  getInitRank,
  getInitAcademicRank,
  getInitJob,
  getInitialEducation,
} from 'features/SelectPerson/PersonHistoryModal/helpers';
import { PersonHistory } from 'types/models/PersonHistory';

type Props = {
  person: ScientistPerson | null;
};

export const getPersonHistory = ({ person }: Props): PersonHistory => {
  const personHistory: PersonHistory = {
    academicRank: getInitAcademicRank({ person }),
    citizenship: getInitCitizenship({ person }),
    job: getInitJob({ person }),
    education: getInitialEducation({ person }),
    degree: getInitDegree({ person }),
    rank: getInitRank({ person }),
    historyLabel: '',
  };

  return personHistory;
};

import { ProjectPerformerOrder } from 'types/models/Project';

export function convertProjectPerformerOrderToXML(po: ProjectPerformerOrder) {
  return {
    _attr: po.id ? { id: po.id } : {},
    Project: { _attr: { id: po.project?.id } },
    Type: po.type?.value,
    Number: po.number,
    Date: po.date,
    Order: po.order,
  };
}

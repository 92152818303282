import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Report } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController() {
  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.WosScopusPublicationByDepartmentsMapping], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextAreaMode, TextDateTime } from 'components';

import { MobileRequest } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.UseMaterialObligation | null;
  onFieldChange(row: MobileRequest.UseMaterialObligation | null): void;
};

function UseMaterialObligationFields({ entry, onFieldChange }: Props) {
  const { handleResultChange, handleResultDateChange, handleResultDescriptionChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="Результат" isRequired>
        <Select
          mode={SelectMode.REFERENCE}
          value={entry?.result}
          onChange={handleResultChange}
          settings={{
            name: 'RefMobileUseMaterialObligationResult',
            title: 'Справочник "Результаты обязательств использования материалов"',
          }}
        />
      </FormComponent.Field>
      <FormComponent.Field label="Срок исполнения" isRequired>
        <TextDateTime value={entry?.resultDate || ''} onChange={handleResultDateChange} />
      </FormComponent.Field>
      <FormComponent.Field label="Описание результата" isRequired>
        <TextArea
          mode={TextAreaMode.MODAL}
          settings={{ rows: 3, title: 'Описание результата' }}
          value={entry?.resultDescription || ''}
          onChange={handleResultDescriptionChange}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { UseMaterialObligationFields };

import { useCallback, useLayoutEffect, useRef, useState, useMemo } from 'react';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { tabsStreams } from 'components/Tabs/streams';

import { showNotification } from 'features/Notifications';
import { ValueOf } from 'types/helpers';
import { computeFinancingsByYear, getIndicators, getMockProject } from './helpers';
import { validate, optionalValidate } from './helpers/validate';
import { EditableIndicator } from './model';
import { formatStr } from 'utils/Constants';
import {
  ByPrototypeCopyOption,
  ContestRequestCopyOption,
  NirRequestCopyOption,
  ProjectType,
  RequestCopyOption,
} from 'utils/Enums';
import { Project, Act } from 'types/models/Project';
import { useAppDataContext } from 'features/AppData/context';
import {
  ContestRequestCopyOptions,
  CopyFromContestRequest,
  CopyFromNirRequest,
  CopyFromProgramRequest,
  NirRequestCopyOptions,
  ProgramRequestCopyOptions,
} from './types';
import { getHashObject } from 'utils/Helpers';

type Arguments = {
  id: string | null;
  onSuccessfullSave?(): void;
  initialProjectType?: ProjectType;
  nirRequestId?: string;
  securityDocumentContractId?: string;
  programRequestId?: string;
  projectCopyOptions?: ByPrototypeCopyOption[];
  prototypeId?: string;
};

export function useController(args: Arguments) {
  const {
    id: initialId,
    onSuccessfullSave,
    initialProjectType,
    nirRequestId,
    securityDocumentContractId,
    programRequestId,
    projectCopyOptions,
    prototypeId,
  } = args;

  const tabsId = 'ProjectForm';

  const { enumMap } = useAppDataContext();

  const acts = useRef<Act[]>([]);

  const [project, setProject] = useState<Project>(getMockProject({ isRequiredStateRegistration: true }));
  const [oldProject, setOldProject] = useState<Project>(getMockProject({ isRequiredStateRegistration: true }));
  const [oldEndDate, setOldEndDate] = useState<string | null>(null);
  const [oldStartDate, setOldStartDate] = useState<string | null>(null);
  const [isSaveAndContinue, setIsSaveAndContinue] = useState<boolean>(false);
  const [isEndDateChangeWarningOpen, setIsEndDateChangeWarningOpen] = useState<boolean>(false);
  const [isUnsavedWarningOpen, setIsUnsavedWarningOpen] = useState<boolean>(false);
  const [isOptionalValidateModalOpen, setIsOptionalValidateModalOpen] = useState<boolean>(false);
  const [optionalValidateMessages, setOptionalValidateMessages] = useState<string[]>([]);
  const [isDocumentsWarningOpen, setIsDocumentWarningOpen] = useState<boolean>(true);

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');
  const { methods: saveProjectAPI } = BackendAPI.useBackendAPI('SaveProject');
  const { methods: saveGovermentContractAPI } = BackendAPI.useBackendAPI('SaveGovermentContract');
  const { methods: saveEconomicAgreementProjectAPI } = BackendAPI.useBackendAPI('SaveEconomicAgreement');
  const { methods: saveGrantAPI } = BackendAPI.useBackendAPI('SaveGrant');

  const { methods: copySourceDataInProjectAPI } = BackendAPI.useBackendAPI('CopySourceDataInProject');

  const projectType: ProjectType | null = useMemo(() => project?.type?.value || null, [project?.type?.value]);

  const mapSaveProject = useMemo<
    Record<
      ProjectType,
      typeof saveProjectAPI | typeof saveGovermentContractAPI | typeof saveEconomicAgreementProjectAPI | typeof saveGrantAPI
    >
  >(
    () => ({
      NIR: saveProjectAPI,
      GRANT: saveGrantAPI,
      GOVERMENT_CONTRACT: saveGovermentContractAPI,
      ECONOMIC_AGREEMENT: saveEconomicAgreementProjectAPI,
    }),
    [saveEconomicAgreementProjectAPI, saveGovermentContractAPI, saveGrantAPI, saveProjectAPI],
  );

  const indicators: EditableIndicator[] = useMemo(() => getIndicators(project?.stages ?? [], 'indicators'), [project?.stages]);
  const indices: EditableIndicator[] = useMemo(() => getIndicators(project?.stages ?? [], 'indices'), [project?.stages]);

  const makeChangeHandler = (key: keyof Project) => (val: ValueOf<Project>) => {
    setProject(prev => ({ ...prev!, [key]: val }));
  };

  const loadProject = useCallback(
    ({
      id,
      type,
      nirId,
      sdcId,
      prId,
      protId,
      copyOptions,
    }: {
      id?: string;
      type?: ProjectType;
      nirId?: string;
      sdcId?: string;
      prId?: string;
      protId?: string;
      copyOptions?: ByPrototypeCopyOption[];
    }) => {
      getProjectAPI.callAPI(
        {
          id,
          type,
          nirRequestId: nirId,
          securityDocumentContractId: sdcId,
          programRequestId: prId,
          prototypeId: protId,
          copyOptions,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const newProject = {
              ...data,
              reports: data.reports.sort((a, b) => (a.stage?.number || '').localeCompare(b.stage?.number || '')),
              financingsByYear: computeFinancingsByYear(data.stages),
            };
            setProject(newProject);
            setOldProject(newProject);
            acts.current = data.acts ?? [];
            setOldStartDate(data.startDate);
            setOldEndDate(data.endDate);
          },
        },
      );
    },
    [getProjectAPI],
  );

  const saveProject = useCallback(
    (isSaveLoad?: boolean) => {
      mapSaveProject[projectType!].callAPI(
        { project: { ...project, acts: acts.current } },
        {
          onSuccessfullCall: ({ data: projectId }) => {
            showNotification({ message: 'Проект успешно сохранен', theme: 'success' });
            if (isSaveLoad) {
              loadProject({ id: projectId });
            } else {
              onSuccessfullSave?.();
            }
          },
        },
      );
    },
    [mapSaveProject, projectType, project, loadProject, onSuccessfullSave],
  );

  const validateAndSave = useCallback(
    (isSaveLoad?: boolean) => {
      const invalidationInfo = validate(project).filter(x => !x.isValid);
      if (invalidationInfo.length) {
        showNotification({ message: invalidationInfo.map(x => x.invalidMessage).join('. '), theme: 'danger' });
        return;
      }
      const optionalInvalidationInfo = optionalValidate(project).filter(x => !x.isValid);
      if (optionalInvalidationInfo.length) {
        setOptionalValidateMessages(optionalInvalidationInfo.map(x => x.invalidMessage));
        setIsOptionalValidateModalOpen(true);
        return;
      }
      saveProject(isSaveLoad);
    },
    [project, saveProject],
  );

  const confirmOptionalValidatePopup = useCallback(() => {
    saveProject(isSaveAndContinue);
    setIsOptionalValidateModalOpen(false);
  }, [isSaveAndContinue, saveProject]);

  const resetOptionalValidatePopup = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 6, tabsId });
    setIsOptionalValidateModalOpen(false);
  }, []);

  const handleSaveClick = useCallback(
    (isSaveLoad?: boolean) => () => {
      setIsSaveAndContinue(!!isSaveLoad);
      if (
        parse(oldEndDate || '', formatStr, new Date()).getTime() !==
          parse(project?.endDate || '', formatStr, new Date()).getTime() ||
        parse(oldStartDate || '', formatStr, new Date()).getTime() !==
          parse(project?.startDate || '', formatStr, new Date()).getTime()
      ) {
        setIsEndDateChangeWarningOpen(true);
      } else validateAndSave(isSaveLoad);
    },
    [oldEndDate, project?.endDate, project?.startDate, oldStartDate, validateAndSave],
  );

  const confirmEndDateChangeWarning = useCallback(() => {
    setIsEndDateChangeWarningOpen(false);
    validateAndSave(isSaveAndContinue);
  }, [isSaveAndContinue, validateAndSave]);

  const reloadProject = useCallback(() => {
    if (!!initialId || project?.id) {
      loadProject({ id: initialId || project?.id || '' });
    } else if (prototypeId && projectCopyOptions && initialProjectType) {
      loadProject({ type: initialProjectType, protId: prototypeId, copyOptions: projectCopyOptions });
    } else if (initialProjectType) {
      if (nirRequestId || securityDocumentContractId || programRequestId) {
        loadProject({ type: initialProjectType, nirId: nirRequestId, sdcId: securityDocumentContractId, prId: programRequestId });
      } else {
        loadProject({ type: initialProjectType });
      }
    } else console.error('Empty project type');
  }, [
    initialId,
    initialProjectType,
    loadProject,
    nirRequestId,
    programRequestId,
    project?.id,
    projectCopyOptions,
    prototypeId,
    securityDocumentContractId,
  ]);

  const copyFromProgramRequest = useCallback(
    ({ id, copyOptions }: CopyFromProgramRequest) => {
      const options: Record<keyof ProgramRequestCopyOptions, RequestCopyOption> = {
        isProject: RequestCopyOption.DESCRIPTION,
        isClassifiers: RequestCopyOption.CLASSIFICATORS,
        isPerformers: RequestCopyOption.PERFORMERS,
      };

      copySourceDataInProjectAPI.callAPI(
        {
          id: project?.id || '',
          projectContent: { ProgramRequest: { _attr: { id } } },
          copyOptions: (Object.keys(copyOptions)
            .map(key =>
              copyOptions[key as keyof ProgramRequestCopyOptions] ? options[key as keyof ProgramRequestCopyOptions] : '',
            )
            .filter(Boolean) as unknown) as RequestCopyOption[],
        },
        {
          onSuccessfullCall: () => {
            reloadProject();
          },
        },
      );
    },
    [copySourceDataInProjectAPI, project?.id, reloadProject],
  );

  const copyFromNirRequest = useCallback(
    ({ id, copyOptions }: CopyFromNirRequest) => {
      const options: Record<keyof NirRequestCopyOptions, NirRequestCopyOption> = {
        isProject: NirRequestCopyOption.DESCRIPTION,
        isStage: NirRequestCopyOption.STAGES,
        isIndicators: NirRequestCopyOption.INDICATORS,
        isPerformers: NirRequestCopyOption.PERFORMERS,
      };

      copySourceDataInProjectAPI.callAPI(
        {
          id: project?.id || '',
          projectContent: { NirRequest: { _attr: { id } } },
          copyOptions: (Object.keys(copyOptions)
            .map(key => (copyOptions[key as keyof NirRequestCopyOptions] ? options[key as keyof NirRequestCopyOptions] : ''))
            .filter(Boolean) as unknown) as NirRequestCopyOption[],
        },
        {
          onSuccessfullCall: () => {
            reloadProject();
          },
        },
      );
    },
    [copySourceDataInProjectAPI, project?.id, reloadProject],
  );

  const copyFromContestRequest = useCallback(
    ({ id, stageId, copyOptions }: CopyFromContestRequest) => {
      const options: Record<keyof ContestRequestCopyOptions, ContestRequestCopyOption> = {
        isStage: ContestRequestCopyOption.STAGE,
        isIndicators: ContestRequestCopyOption.INDICATORS,
        isPerformers: ContestRequestCopyOption.PERFORMERS,
      };

      copySourceDataInProjectAPI.callAPI(
        {
          id: project?.id || '',
          projectContent: { ContestRequest: { _attr: { id }, ProjectStage: { _attr: { id: stageId } } } },
          copyOptions: (Object.keys(copyOptions)
            .map(key =>
              copyOptions[key as keyof ContestRequestCopyOptions] ? options[key as keyof ContestRequestCopyOptions] : '',
            )
            .filter(Boolean) as unknown) as ContestRequestCopyOption[],
        },
        {
          onSuccessfullCall: () => {
            reloadProject();
          },
        },
      );
    },
    [copySourceDataInProjectAPI, project?.id, reloadProject],
  );

  const handleActsChange = useCallback(
    (value: Act[]) => {
      acts.current = value;
      validateAndSave(true);
    },
    [validateAndSave],
  );

  const handleUnsavedWarningConfirm = useCallback(() => {
    setIsUnsavedWarningOpen(false);
    validateAndSave(true);
  }, [validateAndSave]);

  const getIsProjectChanged = useCallback(() => getHashObject(project) !== getHashObject(oldProject) || !project.id, [
    oldProject,
    project,
  ]);

  useLayoutEffect(() => {
    reloadProject();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    enumMap,
    project,
    projectType,
    validateAndSave,
    reloadProject,
    makeChangeHandler,
    setProject,
    handleSaveClick,
    copyFromNirRequest,
    indicators,
    indices,
    handleActsChange,
    acts: acts.current,
    isEndDateChangeWarningOpen,
    setIsEndDateChangeWarningOpen,
    confirmEndDateChangeWarning,
    isUnsavedWarningOpen,
    setIsUnsavedWarningOpen,
    handleUnsavedWarningConfirm,
    isOptionalValidateModalOpen,
    setIsOptionalValidateModalOpen,
    optionalValidateMessages,
    confirmOptionalValidatePopup,
    resetOptionalValidatePopup,
    tabsId,
    copyFromProgramRequest,
    copyFromContestRequest,
    getIsProjectChanged,
    isDocumentsWarningOpen,
    setIsDocumentWarningOpen,
  };
}

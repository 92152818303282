export function PartnerProjectGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Вкладка <strong>НИОКР:</strong> список научных проектов, в которых организация является заказчиком или соисполнителем, или
        технологическим партнёром. Источник информации — разделах <strong>Заказчики и Партнёры</strong> на форме редактирования
        научного проекта (модуль <strong>Проекты</strong>). Условием отображения данных в таблице является выбор организации на
        форме редактирования научного проекта:
      </p>

      <ul>
        <li>На вкладке «О проекте», раздел Заказчики»</li>
        <li>На вкладке Партнёры, Организации-партнёры, Партнёры, Международное сотрудничество</li>
        <li>На вкладке Организации- соисполнители</li>
      </ul>

      <div className="warning">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';

import { MipPartner } from 'types/models';
import { Settings } from 'types/models/common';
import { getMaybeField, makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';
import { convertServerIndicator, convertServerMipPartner } from './converters';
import { convertIndicatorToRequest, convertMipPartnerToRequest } from './requestConverters';
import { MipIndicator } from 'types/models/MipIndicator';

export const tablePartnersMipConfigurations = {
  GetPartnerMipList: makeBackendTableAPIConfiguration(
    'GetPartnerMipList',
    makeTableRequestXMLConverter(
      'GetPartnerMipList',
      ({ panelState, requestData, isTableExtended }: T.GetPartnersMipListRequest) =>
        makeParametersXMLElement(
          requestData?.parameters.length
            ? requestData.parameters.map(({ name, value }) => ({ name, value: String(value) }))
            : [
                { name: 'extended', value: isTableExtended },
                { name: 'deleted', value: String(panelState.isDeleted) },
                { name: 'curated', value: String(panelState.isCurated) },
                { name: 'forApprovement', value: String(panelState.isForApprovement) },
              ],
        ),
    ),
  ),
  GetEnterpriseCustomerSelectList: makeBackendTableAPIConfiguration(
    'GetEnterpriseCustomerSelectList',
    makeTableRequestXMLConverter('GetEnterpriseCustomerSelectList', () => makeParametersXMLElement([])),
  ),
  GetPartnerMipSecurityDocumentContractList: makeBackendTableAPIConfiguration(
    'GetPartnerMipSecurityDocumentContractList',
    makeTableRequestXMLConverter(
      'GetPartnerMipSecurityDocumentContractList',
      ({ requestData }: T.GetPartnerMipSecurityDocumentContractListRequest) =>
        makeParametersXMLElement([{ name: 'partnerId', value: requestData?.partnerId ?? '-1' }]),
    ),
  ),
  GetPartnerMipPaymentList: makeBackendTableAPIConfiguration(
    'GetPartnerMipPaymentList',
    makeTableRequestXMLConverter('GetPartnerMipPaymentList', ({ requestData, panelState }: T.GetPartnerMipPaymentListRequest) =>
      makeParametersXMLElement([
        { name: 'partnerId', value: requestData?.partnerId ?? '-1' },
        ...(!!panelState.year ? [{ name: 'year', value: panelState.year }] : []),
      ]),
    ),
  ),
};

const partnerMipConfigurations = {
  GetPartnerMip: makeBackendAPIConfiguration({
    id: 'GetPartnerMip',
    endpoint: '/msa/service/commands/GetPartnerMip',
    convertInputToXMLElement: ({
      partnerId,
      enterpriseCustomerId,
      enterpriseId,
      shortName,
      fullName,
    }: T.GetPartnerMipRequest) => ({
      Request: {
        _attr: { commandName: 'GetPartnerMip' },
        PartnerMip: {
          ...(enterpriseCustomerId !== null || enterpriseId !== null || !partnerId || shortName !== null || fullName !== null
            ? {}
            : { _attr: { id: partnerId } }),
          ...(enterpriseCustomerId !== null ? { EnterpriseCustomer: { _attr: { id: enterpriseCustomerId } } } : {}),
          ...(enterpriseId !== null ? { Enterprise: { _attr: { id: enterpriseId } } } : {}),
          ...getMaybeField('ShortName', shortName),
          ...getMaybeField('FullName', fullName),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      response.Response.PartnerMip
        ? convertServerMipPartner(response.Response.PartnerMip, settings)
        : ({} as MipPartner.MipPartner),
  }),
  SavePartnerMip: makeBackendAPIConfiguration({
    id: 'SavePartnerMip',
    endpoint: '/msa/service/commands/SavePartnerMip',
    convertInputToXMLElement: ({ mipPartner }: T.SaveMipPartnerRequest) => ({
      Request: {
        _attr: { commandName: 'SavePartnerMip' },
        ...convertMipPartnerToRequest(mipPartner),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPartnerMipIndicator: makeBackendAPIConfiguration({
    id: 'GetPartnerMipIndicator',
    endpoint: '/msa/service/commands/GetPartnerMipIndicator',
    convertInputToXMLElement: ({ id }) => ({
      Request: {
        _attr: { commandName: 'GetPartnerMipIndicator' },
        PartnerMipIndicator: {
          ...(id !== null ? { _attr: { id } } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      response.Response.PartnerMipIndicator
        ? convertServerIndicator(response.Response.PartnerMipIndicator, settings)
        : ({} as MipIndicator),
  }),
  SavePartnerMipIndicator: makeBackendAPIConfiguration({
    id: 'SavePartnerMipIndicator',
    endpoint: '/msa/service/commands/SavePartnerMipIndicator',
    convertInputToXMLElement: ({ indicator }: T.SaveMipPartnerIndicatorRequest) => ({
      Request: {
        _attr: { commandName: 'SavePartnerMipIndicator' },
        PartnerMipIndicator: {
          ...convertIndicatorToRequest(indicator),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const partnersMipConfigurations = {
  ...tablePartnersMipConfigurations,
  ...partnerMipConfigurations,
};

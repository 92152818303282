import { useCallback, useMemo, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { Publication, Report, Table } from 'types/models';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { PublicationTypeCode } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';

export function useController(tableState: Table.State) {
  const [publication, setPublication] = useState<Publication | null>(null);
  const token = getAuthToken();

  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();

  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isCitationDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isLinkButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isArticleButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const isDownloadPublicationFileDisabled = useMemo(
    () => !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows.length && tableState.selectedRows[0]?.FileId),
    [rowsAvailability.SINGLE_SELECTED, tableState.selectedRows],
  );

  const handleDownloadPublicationFile = useCallback(() => downloadFile(tableState.selectedRows[0]?.FileId, token), [
    tableState.selectedRows,
    token,
  ]);

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isArticleFormOpen, setIsArticleFormOpen] = useState(false);

  const { methods: approvePublicationAPI } = BackendAPI.useBackendAPI('ApprovePublication', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Сборник успешно утвержден', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById', {
    onSuccessfullCall: ({ data }) => {
      setPublication(data);
    },
  });

  const { methods: sendToLibraryAPI } = BackendAPI.useBackendAPI('ExportPublicationToVirtua', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Отправлено в библиотеку', theme: 'success' });
    },
  });

  const { methods: compilationProcess } = BackendAPI.useBackendAPI('CompilationProcess', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Операция успешно выполнена', theme: 'success' });
    },
  });

  const getPublication = useCallback((publicationId: string) => {
    getPublicationAPI.callAPI({ publicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinksToOpen = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const fetchCompilationProcess = useCallback(
    (
      publicationId: string,
      action: string,
      payload?: {
        newType?: string;
        newArticleType?: string;
        electronicType?: string;
        url?: string;
        urlDate?: string;
        electronicMediaSource?: string;
        electronicSystemRequirements?: string;
        articleIds?: string;
      },
    ) => {
      compilationProcess.callAPI(
        {
          publicationId,
          action,
          newType: payload?.newType,
          newArticleType: payload?.newArticleType,
          url: payload?.url,
          urlDate: payload?.urlDate,
          electronicType: payload?.electronicType,
          electronicMediaSource: payload?.electronicMediaSource,
          electronicSystemRequirements: payload?.electronicSystemRequirements,
          articleIds: payload?.articleIds,
        },
        {
          onSuccessfullCall() {
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const approvePublication = useCallback((publicationId: string) => {
    approvePublicationAPI.callAPI({ publicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const sendToLibrary = useCallback((publicationId: string) => {
    sendToLibraryAPI.callAPI({ publicationId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getLinks = useCallback(() => {
    if (!tableState.selectedRows[0]) {
      return new Map();
    }
    const arr: [string, string][] = [
      ['Изменить тип сборника', 'CHANGE_TYPE'],
      ['Изменить вид печатный / электронный', 'SWITCH_ELECTRONIC'],
      ['Изменить тип статьи в сборнике материалов конференции', 'CHANGE_CONFERENCE_ARTICLE_TYPE'],
    ];
    return new Map<string, string>(arr);
  }, [tableState]);

  const [currentType, setCurrentType] = useState<Report | null>(null);
  const [isChangeModal, setIsChangeModal] = useState<boolean>(false);

  const handleSetCurrentReport = useCallback(({ name, value }: { name: string; value: string }) => {
    const nextCurrentReport: Report = { name: value, caption: name };
    if (nextCurrentReport) {
      setCurrentType(nextCurrentReport);
      setIsChangeModal(true);
    }
  }, []);
  const handleCloseModal = () => {
    setIsChangeModal(false);
  };
  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleArticleButtonClick = useCallback(() => {
    setIsArticleFormOpen(true);
  }, []);
  const handleTemplateCloseArticleForm = useCallback(() => {
    setIsArticleFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);
  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const getSelectedRow = useCallback(() => {
    if (tableState.selectedRows.length) {
      const [row] = tableState.selectedRows;
      return row;
    }
    return null;
  }, [tableState.selectedRows]);

  const handleApprove = useCallback(() => {
    const row = getSelectedRow();
    if (row) {
      approvePublication(row.id);
    }
  }, [getSelectedRow, approvePublication]);

  const handleEditRelations = useCallback(() => {
    const row = getSelectedRow();
    if (row) {
      getPublication(row.id);
      setIsOpenEditRelations(true);
    }
  }, [getSelectedRow, getPublication]);

  const handleSendToLibrary = useCallback(() => {
    const row = getSelectedRow();
    if (row) {
      sendToLibrary(row.id);
    }
  }, [getSelectedRow, sendToLibrary]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = tableState.selectedRows[0]?.id;
    const publicationTypeCode = tableState?.selectedRows[0]['id:type'];
    if (publicationId && publicationTypeCode) {
      const preparedPublicationTypeCode = publicationTypeCode as PublicationTypeCode;
      deletePublication({ publicationId, publicationTypeCode: preparedPublicationTypeCode });
    }
    handleCloseDeleteConfirmPopup();
  }, [tableState.selectedRows, handleCloseDeleteConfirmPopup, deletePublication]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  return {
    publication,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    fetchCompilationProcess,
    getLinks,
    handleSetCurrentReport,
    isChangeModal,
    handleCloseModal,
    currentType,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewButtonDisabled,
    handleEditButtonClick,
    handleAddButtonClick,
    handleViewButtonClick,
    handleDeleteButtonClick,
    handleApprove,
    handleEditRelations,
    handleSendToLibrary,
    getSelectedRow,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isDownloadPublicationFileDisabled,
    handleDownloadPublicationFile,
    isLinkButtonDisabled,
    getLinksToOpen,
    handleOpenCitationForm,
    handleCloseCitationForm,
    isCitationDisabled,
    isCitationFormOpen,
    handleArticleButtonClick,
    handleTemplateCloseArticleForm,
    isArticleButtonDisabled,
    isArticleFormOpen,
  };
}

import { useCallback, useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { HistoryEntry, ReferenceMetadata } from 'types/models';
import { State, Mode, EditableMetadataField } from './model';
import { validate } from './validate';

type Arguments = {
  name: string;
};

export function makeUseCustomController({ name }: Arguments) {
  return function useCustomController(): State {
    const { enumMap } = useAppDataContext();
    const tableStreams = useLocalTableStreams();

    const [isOpenEditor, setIsOpenEditor] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const [referenceMetadata, setReferenceMetadata] = useState<ReferenceMetadata | null>(null);
    const [fields, setFields] = useState<EditableMetadataField[]>([]);
    const [editableRowId, setEditableRowId] = useState<string | null>(null);
    const [selectedEntries, setSelectedEntries] = useState<HistoryEntry[]>([]);

    const { methods: getReferenceMetadataAPI } = BackendAPI.useBackendAPI('GetReferenceMetadata', {
      onSuccessfullCall: ({ data }) => {
        setReferenceMetadata(data);
        const dataFields = data.fields.map<EditableMetadataField>(x => {
          return {
            ...x,
            value: x.type === 'BOOLEAN' ? false : null,
          } as any;
        });
        setFields(dataFields);
        setTimeout(() => tableStreams.loadInitialTable.push(), 0);
      },
    });

    const { methods: getReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

    const { methods: getReferenceModificationHistoryAPI } = BackendAPI.useBackendAPI('GetReferenceModificationHistory', {
      onSuccessfullCall: ({ data }) => {
        setSelectedEntries(data);
      },
    });
    const { methods: deleteReferenceElementAPI } = BackendAPI.useBackendAPI('DeleteReferenceElement', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'Элемент справочника успешно удален', theme: 'success' });
      },
    });
    const { methods: saveReferenceElementAPI } = BackendAPI.useBackendAPI('SaveReferenceElement', {
      onSuccessfullCall: () => {
        reloadTable();
        showNotification({ message: 'Элемент справочника успешно сохранен', theme: 'success' });
        closeEditor();
      },
    });

    useEffect(() => {
      getReferenceMetadataAPI.callAPI({ name });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [name]);

    const deleteReference = useCallback(
      (rowId: string) => {
        deleteReferenceElementAPI.callAPI({ name, rowId });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [editableRowId, name],
    );

    const saveReference = useCallback(() => {
      const validateInfo = validate(fields);
      if (validateInfo.length) {
        validateInfo.forEach(x => setTimeout(() => showNotification({ message: x.message, theme: 'danger' }), 0));
        return;
      }

      saveReferenceElementAPI.callAPI({ name, fields, rowId: editableRowId });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fields, name]);

    const loadModificationHistory = useCallback(
      (rowId: string) => {
        getReferenceModificationHistoryAPI.callAPI({ name, rowId });
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [name],
    );

    const loadReferenceFieldsValues = useCallback(
      (rowId: string) => {
        getReferenceElementsAPI.callAPI(
          { filters: [], referenceName: name, childIds: [rowId] },
          {
            onSuccessfullCall: ({ data }) => {
              if (data.length) {
                const [ref] = data;
                if (ref.customFields && Object.keys(ref.customFields).length) {
                  const updatedFields = Object.entries(ref.customFields).reduce((acc, [fieldName, value]) => {
                    const index = acc.findIndex(x => x.name === fieldName);

                    if (index === -1) {
                      return acc;
                    }

                    const result = [...acc];
                    result[index] = { ...acc[index], value } as EditableMetadataField;
                    return result;
                  }, fields);
                  setFields(updatedFields);
                }
              }
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [fields],
    );

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const resetState = useCallback(() => {
      setMode(null);
      setEditableRowId(null);
      setFields(fields.map(x => ({ ...x, value: x.type === 'BOOLEAN' ? false : null } as any)));
    }, [setMode, setEditableRowId, fields, setFields]);

    const openEditor = useCallback(() => {
      setIsOpenEditor(true);
      tableStreams.toggleSecondLevelPanel.push();
    }, [tableStreams.toggleSecondLevelPanel]);

    const closeEditor = useCallback(() => {
      setIsOpenEditor(false);
      resetState();
      tableStreams.toggleSecondLevelPanel.push();
    }, [resetState, tableStreams.toggleSecondLevelPanel]);

    return {
      name,
      mode,
      fields,
      enumMap,
      isOpenEditor,
      referenceMetadata,
      editableRowId,
      selectedEntries,
      setSelectedEntries,
      setEditableRowId,
      setMode,
      setFields,
      saveReference,
      openEditor,
      loadReferenceFieldsValues,
      deleteReference,
      loadModificationHistory,
      closeEditor,
    };
  };
}

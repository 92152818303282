import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export function GetPersonalConcertParticipationList(): T.Specification<State> {
  return {
    apiID: 'GetPersonalConcertParticipationList',
    header: {
      firstLevel: {
        title: '',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController(),
    highlightRow: row => (!!row.LibraryNote ? Color.danger : ''),
    isWithQna: true,
  };
}

import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

type Arguments = {
  personId: string;
};

type RequestData = {
  scientistId: string;
};

export function GetScientistDissertationManagementList({ personId }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetScientistDissertationManagementList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Руководство защитой на основе данных публикаций типов "Автореферат" и "Диссертация"',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: { scientistId: personId },
  };
}

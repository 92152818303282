import { ForeignProject } from 'types/models';
import { Item, Settings } from 'types/models/common';
import { convertServerAffiliation, convertServerPerson } from '../author/converters';
import { convertDocument, convertMaybeArray, convertReference, getId, getLabel, getText } from '../commonConverters';
import { convertPersonHistory } from '../converters';
import { ForeignProjectStatus } from 'utils/Enums';

export const convertForeignProject = (foreignProject: any, settings: Settings): ForeignProject.ForeignProject => {
  const documents = foreignProject?.Documents?.Document || [];
  const grntis = foreignProject?.Grntis?.Grnti || [];
  const financings = foreignProject?.Financings?.Financing || [];

  const customerAffiliation = foreignProject?.Customer ? { Affiliation: foreignProject.Customer } : null;

  const enterpriseAffiliation = foreignProject?.Enterprise ? { Affiliation: foreignProject.Enterprise } : null;

  return {
    id: getId(foreignProject),
    status: convertStatus(foreignProject),
    name: getText(foreignProject?.Name),
    startDate: getText(foreignProject?.StartDate),
    endDate: getText(foreignProject?.EndDate),
    stateRegistrationNumber: getText(foreignProject?.StateRegistrationNumber),
    stateRegistrationDate: getText(foreignProject?.StateRegistrationDate),
    number: getText(foreignProject?.Number),
    program: convertProgram(foreignProject),
    programEvent: convertProgramEvent(foreignProject),
    leader: getText(foreignProject?.Leader),
    role: convertRole(foreignProject),
    customer: customerAffiliation ? convertServerAffiliation(customerAffiliation, settings) : null,
    enterprise: enterpriseAffiliation ? convertServerAffiliation(enterpriseAffiliation, settings) : null,
    amount: getText(foreignProject?.Amount),
    note: getText(foreignProject.Note),
    performer: convertPerformer(foreignProject, settings),
    documents: convertMaybeArray(documents, i => convertDocument('local', i)),
    grntis: convertMaybeArray(grntis, convertReference),
    financings: convertMaybeArray(financings, convertFinancing),
  };
};

const convertProgram = (foreignProject: any): Item<string> | null => {
  if (foreignProject?.Program) {
    return {
      value: getId(foreignProject?.Program),
      label: getText(foreignProject?.Program.ShortName),
    };
  }

  return null;
};

const convertProgramEvent = (foreignProject: any): Item<string> | null => {
  if (foreignProject?.ProgramEvent) {
    return {
      value: getId(foreignProject?.ProgramEvent),
      label: getText(foreignProject?.ProgramEvent.Name),
    };
  }

  return null;
};

const convertPerformer = (foreignProject: any, settings: Settings): ForeignProject.Performer => ({
  id: getId(foreignProject?.Performer),
  person: convertServerPerson(foreignProject?.Performer, settings),
  ...convertPersonHistory(foreignProject),
});

const convertStatus = (foreignProject: any): Item<ForeignProjectStatus> => ({
  value: getText(foreignProject?.Status) as ForeignProjectStatus,
  label: getLabel(foreignProject?.Status),
});

const convertRole = (foreignProject: any): Item<string> => ({
  value: getText(foreignProject?.Role),
  label: getLabel(foreignProject?.Role),
});

const convertFinancing = (financing: any): ForeignProject.Financing => ({
  id: getId(financing),
  year: getText(financing?.Year),
  amount: getText(financing?.Amount),
});

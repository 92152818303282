import { TenderRequestType } from 'utils/Enums';

export type State = {
  type: TenderRequestType | null;
  statusChangeCallback?(): void;
};

export function makeUseCustomController({ type, statusChangeCallback }: State) {
  return function useCustomController(): State {
    return {
      type,
      statusChangeCallback,
    };
  };
}

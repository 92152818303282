import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForSpecification({ tableState }: Props) {
  const {
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isViewButtonDisabled,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    handleCloseEditForm,
    buttons,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
      />

      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForSpecification);

import React from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { PurchaseRequest } from 'types/models/PurchaseRequest';

type Props = {
  purchaseRequest: PurchaseRequest | undefined;
  workMode: string;
  onSave(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  onSaveAndContinue(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  approvementButtons: ButtonProps[];
};

function Toolbar(props: Props) {
  const { purchaseRequest } = props;

  const { buttons, isReportOpen, onReportClose, currentReport } = useController(props);

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          purchaseRequestId: purchaseRequest?.id || undefined,
        }}
      />
    </>
  );
}

export { Toolbar };

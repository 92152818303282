import React, { useCallback } from 'react';

import { ButtonMode, FormComponent, Modal, Tab, Tabs, TextArea } from 'components';

import { Table } from 'types/models';
import { EventType } from 'types/models/Payment';
import { State } from '../model';
import { mapTitle } from '../helpers';
import { Toolbar } from './Toolbar';
import { ViewConference, Payment, MessageLog, ViewArticle, ViewMonograph, ViewTextBook, ViewParticipation } from './Tabs';
import { useController } from './useController';
import { Departments } from 'features/Table/views';
import { Documents } from 'features/Documents';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';
import { Mode } from 'features/Table/specifications/GetReferenceElementList/model';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const { isOpenForm, cancelPayment, mode } = customState;

  const {
    status,
    comment,
    authorPayment,
    isOpenCommentForm,
    typeEvent,
    setIsOpenCommentForm,
    setComment,
    changeField,
    savePayment,
    submitForm,
    prepareChangeStatusEvent,
    changeStatus,
  } = useController(customState);

  const closeCommentForm = useCallback(() => {
    setIsOpenCommentForm(false);
  }, [setIsOpenCommentForm]);

  if (!isOpenForm) {
    return null;
  }

  const isViewMode = mode === 'view';

  const renderElements: Record<
    EventType,
    {
      title: JSX.Element;
      firstTab: JSX.Element;
    }
  > = (() => {
    const generateTitle = (text: string) => {
      const prefixMode: Record<Mode, string> = {
        view: 'Просмотр',
        edit: 'Редактирование',
        add: 'Добавление',
      };

      const title = getStatusMessage(
        `${mode ? prefixMode[mode] : prefixMode.add} заявки типа "${text}"`,
        authorPayment?.id || '',
        authorPayment?.status?.label,
      );

      const hint = (() => {
        // eslint-disable-next-line max-len
        return 'Добавить или удалить связь с подразделением можно на вкладке "Расчет стимулирующих выплат" с помощью таблицы редактирования подразделений';
      })();

      return (
        <>
          <h3>{title}</h3>
          <h4>
            <Departments
              hint={hint}
              departments={
                authorPayment?.departments
                  .map(({ topLevelDepartment }) => topLevelDepartment?.label)
                  .filter(Boolean)
                  .join(', ') || ''
              }
            />
          </h4>
        </>
      );
    };

    return {
      ARTICLE: {
        title: generateTitle('Оплата статьи'),
        firstTab: (
          <Tab title="Просмотр статьи">
            <ViewArticle
              isViewMode={isViewMode}
              article={authorPayment?.article ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      CONFERENCE: {
        title: generateTitle('Оплата конференции'),
        firstTab: (
          <Tab title="Просмотр конференции">
            <ViewConference eventId={authorPayment?.conference?.id ?? null} tableState={tableState} />
          </Tab>
        ),
      },
      MONOGRAPH: {
        title: generateTitle('Оплата монографии'),
        firstTab: (
          <Tab title="Просмотр публикации">
            <ViewMonograph
              isViewMode={isViewMode}
              publiation={authorPayment?.publication ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      TEXTBOOK: {
        title: generateTitle('Оплата учебника, учебного пособия'),
        firstTab: (
          <Tab title="Просмотр публикации">
            <ViewTextBook
              isViewMode={isViewMode}
              publiation={authorPayment?.publication ?? null}
              tableState={tableState}
              status={status}
            />
          </Tab>
        ),
      },
      MEDAL: {
        title: generateTitle('Оплата награды выставки'),
        firstTab: (
          <Tab title="Участие в выставке">
            <ViewParticipation
              participation={authorPayment?.participation ?? null}
              tableState={tableState}
              onClose={cancelPayment}
            />
          </Tab>
        ),
      },
    };
  })();

  return (
    <Modal isOpen={isOpenForm} onClose={cancelPayment} title={renderElements[typeEvent].title} size="full">
      <FormComponent.Template>
        <Toolbar
          authorPayment={authorPayment}
          onSave={submitForm}
          prepareChangeStatusEvent={prepareChangeStatusEvent}
          isDisabled={isViewMode}
          typeEvent={typeEvent}
        />

        <FormComponent.Template>
          <Tabs>
            {renderElements[typeEvent].firstTab}
            <Tab title="Расчет стимулирующих выплат">
              <Payment authorPayment={authorPayment} changeField={changeField} disabled={isViewMode} eventType={typeEvent} />
            </Tab>
            <Tab title="Документы">
              <Documents
                isShowSource
                disabled={isViewMode}
                documents={authorPayment?.documents ?? []}
                setDocuments={val => changeField('documents', val)}
                sourceLabelForLocalDocs="Текущая заявка"
              />
            </Tab>
            {!!authorPayment?.id && (
              <Tab title="Журнал сообщений">
                <MessageLog
                  isDisabledToolbar={isViewMode}
                  authorPaymentId={authorPayment.id}
                  paymentStatus={authorPayment.status?.value}
                />
              </Tab>
            )}
          </Tabs>
        </FormComponent.Template>

        <Modal
          title={(mapTitle as any)[String(status)]}
          isOpen={!!isOpenCommentForm}
          onClose={() => closeCommentForm()}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: () => {
                closeCommentForm();
                if (status !== null) {
                  changeStatus();
                  return;
                }
                savePayment();
              },
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => closeCommentForm(),
            },
          ]}
          size="large"
        >
          <FormComponent.Field label="Комментарий">
            <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
          </FormComponent.Field>
        </Modal>
      </FormComponent.Template>
    </Modal>
  );
}

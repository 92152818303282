import React from 'react';

import {
  FormComponent,
  ListEdit,
  SectionTitle,
  Select,
  SelectMode,
  Checkbox,
  ReferenceItem,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
} from 'components';

import { Author, Form } from 'types/models';
import { SecurityDocument, SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Departments } from 'features/Departments';
import { formatJob } from 'features/SelectPerson/helpers';
import { showErrorsMessages } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { SecurityDocumentType } from 'utils/Enums';

import { validatePatentMember } from './validate';
import { useController } from './controller';
import { MemberInputs } from './MemberInputs';
import { CountryInput } from './CountryInput';
import { ProjectInput } from './ProjectInput';
import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
  formFields: Form.Fields;
  disabled?: boolean;
  handleChangeProject(projectId?: string): void;
};

export function Document({ securityDocument, formFields, disabled, setSecurityDocument, handleChangeProject }: Props) {
  const {
    handleApplicantChange,
    handleAdmittedChange,
    handleCountriesChange,
    cloneAuthorsToAdmittedPersons,
    documentTitles,
    isPatentDocument,
    isKnowHowDocument,
    isEvidenceDocument,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    admittedPersonList,
    declinedTypeTitle,
    applicantPersonList,
    handleAuthorsChange,
  } = useController({
    securityDocument,
    setSecurityDocument,
  });

  const { settings } = useAppDataContext();

  const countriesComponent = () => (
    <FormComponent.Line>
      <ListEdit
        header={{
          title: 'Охрана прав в других странах',
          tooltip:
            // eslint-disable-next-line max-len
            'Для документов, подтверждающие охрану прав на зарубежных рынках, в том числе по процедурам, предусмотренным Patent Cooperation Treaty (договором о патентной кооперации) - PCT, мадридским соглашением.',
        }}
        rows={securityDocument?.countries || []}
        onChange={handleCountriesChange}
        toolbar={['add', 'edit', 'delete']}
        columns={[{ label: '', formatValue: x => x?.label || '' }]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        withoutHead
        maxHeight="300px"
        defaultRowsCount={3}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, onChange) => <CountryInput country={row} onCountryChange={onChange} />,
        }}
      />
    </FormComponent.Line>
  );

  const admittedPersonComponent = () => (
    <FormComponent.Line>
      <ListEdit
        header={{ title: 'Допущенные лица' }}
        rows={admittedPersonList}
        onChange={handleAdmittedChange}
        toolbar={[
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'group', mode: 'arrowRight' },
            title: 'Скопировать авторов',
            onClick: cloneAuthorsToAdmittedPersons,
          },
        ]}
        columns={[
          { label: 'ФИО', formatValue: x => x?.name || '' },
          {
            label: 'Место работы',
            formatValue: x => (x?.person?.scientistJobs[0] && formatJob(x?.person.scientistJobs[0])) || '',
          },
        ]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        maxHeight="300px"
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, onChange) => (
            <MemberInputs
              member={row}
              onMemberChange={onChange as any}
              memberType={SecurityDocumentMemberTypes.ADMITTED_PERSON}
            />
          ),
          validation: {
            checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
            onInvalidate: (row, mode, index, list) => {
              const validationInfo = validatePatentMember(row, list, mode, index);
              showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
    </FormComponent.Line>
  );

  const applicantsComponent = () => (
    <ListEdit
      header={{ title: `Правообладатели, кроме ${settings?.organization?.shortName}` }}
      rows={applicantPersonList}
      onChange={handleApplicantChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '', styles: { width: '42%' } },
        {
          label: 'Договор',
          formatValue: x =>
            x?.contractDate ? `№ ${x?.contractNumber} от ${x?.contractDate}` : x?.contractNumber ? `№ ${x?.contractNumber}` : '',
          styles: { width: '42%' },
        },
        { label: 'Доля', formatValue: x => x?.contractPortion ?? '', styles: { width: '16%' } },
      ]}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      maxHeight="300px"
      defaultRowsCount={5}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs
            member={row}
            onMemberChange={onChange}
            memberType={SecurityDocumentMemberTypes.APPLICANT}
            enterpriseRef="RefEnterpriseCustomer"
          />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const authorsComponent = () => (
    <ListEdit
      header={{
        title: `Авторы${authorsList.length ? `, всего: ${authorsList.length}` : ''}`,
        isRequired: true,
        info: (
          <>
            <p>
              При заполнении сведений об авторах обязательно указать место работы или обучения автора. Список сотрудников,
              студентов и аспирантов университета — в Справочнике персон, данные в него загружаются из информационных систем
              кадрового учёта университета.
            </p>
            <p>Для добавления не аффилированных с университетом персон оператору необходимо иметь соответствующее право.</p>
          </>
        ),
      }}
      rows={authorsList}
      onChange={handleAuthorsChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '25%' } },
        {
          label: 'Должность',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.rank || result.error || '';
          },
          styles: { width: '25%' },
        },
        {
          label: 'Место работы',
          formatValue: x => {
            const result = getSplittedHistoryLabel(convertSecurityDocumentToAuthor(x));
            return result.job || result.error || '';
          },
          styles: { width: '35%' },
        },
        { label: 'Вклад, %', formatValue: x => x?.contribution || '0', styles: { width: '15%' }, dataKind: 'float' },
      ]}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      maxHeight="none"
      columnIndexesForSumTotal={[3]}
      defaultRowsCount={8}
      specification={{
        mode: 'customComponent',
        renderComponent: (row, onChange) => (
          <MemberInputs member={row} onMemberChange={onChange as any} memberType={SecurityDocumentMemberTypes.AUTHOR} />
        ),
        validation: {
          checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
          onInvalidate: (row, mode, index, list) => {
            const validationInfo = validatePatentMember(row, list, mode, index);
            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const convertSecurityDocumentToAuthor = (member: SecurityDocumentMember): Author => ({
    ...member,
    category: null,
    historyLabel: '',
    affilateCount: null,
    isAffilated: false,
    memberAffilations: [],
    position: 0,
    enterprise: null,
  });

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: leftColumnWidth }}>
          {isKnowHowDocument ? (
            <>
              <SectionTitle title={documentTitles[securityDocument?.type?.value ?? SecurityDocumentType.PATENT]} />

              <FormComponent.Line>
                <FormComponent.Field label="Номер приказа">
                  <TextInput
                    value={formFields.documentNumber.value}
                    onChange={formFields.documentNumber.onChange}
                    isDisabled={disabled}
                    placeholder="Номер приказа"
                  />
                </FormComponent.Field>
                <FormComponent.Field label={formFields.internalNumber.title} isRequired={formFields.internalNumber.required}>
                  <TextInput
                    value={formFields.internalNumber.value}
                    onChange={formFields.internalNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата приказа" isRequired={formFields.documentDate.required}>
                  <TextDateTime
                    value={formFields.documentDate.value}
                    onChange={formFields.documentDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          ) : (
            <>
              <SectionTitle title={documentTitles[SecurityDocumentType.PATENT]} />

              <FormComponent.Line>
                <FormComponent.Field label={formFields.internalNumber.title} isRequired={formFields.internalNumber.required}>
                  <TextInput
                    value={formFields.internalNumber.value}
                    onChange={formFields.internalNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label={formFields.requestSendDate.title} isRequired={formFields.requestSendDate.required}>
                  <TextDateTime
                    value={formFields.requestSendDate.value}
                    onChange={formFields.requestSendDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.requestNumber.title} isRequired={formFields.requestNumber.required}>
                  <TextInput
                    value={formFields.requestNumber.value}
                    onChange={formFields.requestNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label={formFields.requestDate.title} isRequired={formFields.requestDate.required}>
                  <TextDateTime
                    value={formFields.requestDate.value}
                    onChange={formFields.requestDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Заявка отклонена">
                  <Checkbox
                    checked={!!securityDocument.isRejected}
                    onChange={e => setSecurityDocument(prev => ({ ...prev, isRejected: !!e }))}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          <FormComponent.Line>
            <FormComponent.Field label="Вид ОИС" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={securityDocument.kind}
                onChange={(e: ReferenceItem | null) => setSecurityDocument(prev => ({ ...prev, kind: e }))}
                settings={{
                  name: 'RefSecurityDocumentKind',
                  title: 'Справочник "Виды ОИС"',
                  ...(securityDocument.type?.value ? { filters: [{ key: 'type', values: [securityDocument.type?.value] }] } : {}),
                  isClearable: false,
                }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.intellectualPropertyName.title}
              isRequired={formFields.intellectualPropertyName.required}
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.intellectualPropertyName.title }}
                value={formFields.intellectualPropertyName.value}
                onChange={formFields.intellectualPropertyName.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.referat.title} isRequired={formFields.referat.required}>
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.referat.title }}
                value={formFields.referat.value}
                onChange={formFields.referat.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {isKnowHowDocument && (
            <FormComponent.Line>
              <FormComponent.Field label="Дата начала действия">
                <TextDateTime
                  value={securityDocument.documentStartDate}
                  onChange={e => setSecurityDocument(prev => ({ ...prev, documentStartDate: e }))}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
              <FormComponent.Field label="Дата окончания действия">
                <TextDateTime
                  value={securityDocument.documentEndDate}
                  onChange={e => setSecurityDocument(prev => ({ ...prev, documentEndDate: e }))}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <FormComponent.Field label="Получен в рамках научного проекта">
              <ProjectInput securityDocument={securityDocument} handleChangeProject={handleChangeProject} disabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: rightColumnWidth }}>
          {isPatentDocument && <SectionTitle title="Патент" />}
          {isEvidenceDocument && <SectionTitle title="Свидетельство" />}

          {!!securityDocument?.kind?.label && (
            <FormComponent.Line>
              <FormComponent.Field label="На охрану ОИС">
                <FormComponent.Description mode="info">
                  {securityDocument?.kind?.label}: {securityDocument?.intellectualPropertyName}
                </FormComponent.Description>
              </FormComponent.Field>
            </FormComponent.Line>
          )}

          {isKnowHowDocument ? (
            admittedPersonComponent()
          ) : (
            <>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.documentNumber.title} isRequired={formFields.documentNumber.required}>
                  <TextInput
                    value={formFields.documentNumber.value}
                    onChange={formFields.documentNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label={formFields.documentDate.title} isRequired={formFields.documentDate.required}>
                  <TextDateTime
                    value={formFields.documentDate.value}
                    onChange={formFields.documentDate.onChange}
                    isDisabled={disabled || formFields.documentDate.disabled}
                    isError={!formFields.documentDate.isValid}
                  />
                </FormComponent.Field>
                <FormComponent.Field
                  label={formFields.documentReceiveDate.title}
                  isRequired={formFields.documentReceiveDate.required}
                >
                  <TextDateTime
                    value={formFields.documentReceiveDate.value}
                    onChange={formFields.documentReceiveDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата начала действия" isRequired={!!formFields.documentNumber.value}>
                  <TextDateTime
                    value={securityDocument.documentStartDate}
                    onChange={e => setSecurityDocument(prev => ({ ...prev, documentStartDate: e }))}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата окончания действия">
                  <TextDateTime
                    value={securityDocument.documentEndDate}
                    onChange={e => setSecurityDocument(prev => ({ ...prev, documentEndDate: e }))}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <RefElementList
                  header={{ title: 'МПК' }}
                  rows={securityDocument?.mpks ?? []}
                  onChange={e => setSecurityDocument(prev => ({ ...prev, mpks: e }))}
                  toolbar={['add', 'delete', 'move']}
                  modalTitle='Справочник "МПК"'
                  refName="RefMpk"
                  isDisabled={disabled}
                  isActual={false}
                  isProject={false}
                  columns={[
                    {
                      label: 'Название',
                      formatValue: row =>
                        [
                          row.element?.customFields?.code ? `${row.element?.customFields?.code}:` : '',
                          row.element?.label,
                          row.element?.customFields?.parent
                            ? `(${(row.element?.customFields?.parent as ReferenceItem).label})`
                            : '',
                        ]
                          .filter(Boolean)
                          .join(' '),
                    },
                  ]}
                />
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label={formFields.stateNumber.title}
                  isRequired={formFields.stateNumber.required}
                  info={<>{formFields.stateNumber.tooltipText}</>}
                >
                  <TextInput
                    value={formFields.stateNumber.value}
                    onChange={formFields.stateNumber.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label={formFields.stateRegistrationDate.title}
                  isRequired={formFields.stateRegistrationDate.required}
                >
                  <TextDateTime
                    value={formFields.stateRegistrationDate.value}
                    onChange={formFields.stateRegistrationDate.onChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: leftColumnWidth }}>
          <FormComponent.Line>{authorsComponent()}</FormComponent.Line>

          <FormComponent.Line>
            <Departments
              title="Связь с Подразделениями"
              info={
                <>
                  <p>
                    Система автоматически добавляет подразделения авторов. Оператор имеет возможность отредактировать этот список
                    (удалить или добавить подразделение).
                  </p>
                  <p>
                    Для указанных в этом разделе подразделений объект будет включён в отчёты о результатах научной деятельности.
                  </p>
                </>
              }
              securityDocument={securityDocument}
              setSecurityDocument={setSecurityDocument}
              withMessages={false}
              disabled={!!disabled}
            />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: rightColumnWidth }}>
          <SectionTitle title={`Территория действия ${declinedTypeTitle}`} />

          <FormComponent.Line>
            <FormComponent.Field>
              {!isKnowHowDocument && (
                <Checkbox
                  label={formFields.isRus.title}
                  checked={!!formFields.isRus.value}
                  onChange={formFields.isRus.onChange}
                  isDisabled={disabled}
                />
              )}
              <Checkbox
                label={formFields.isWorldwide.title}
                checked={isKnowHowDocument || !!formFields.isWorldwide.value}
                onChange={formFields.isWorldwide.onChange}
                isDisabled={disabled || isKnowHowDocument}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>{!isKnowHowDocument && countriesComponent()}</FormComponent.Line>
          <FormComponent.Line>{!isKnowHowDocument && applicantsComponent()}</FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

import React from 'react';

import { ListEdit } from 'components';

import { showNotification } from 'features/Notifications';
import { Person, UserPermission } from 'types/models';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  disabled: boolean;
  onChangeFIO(fio: Person.BibliographicName[]): void;
};

export function FIO(props: Props) {
  const { person, userPermission, disabled, onChangeFIO } = props;

  return (
    <ListEdit
      rows={person?.bibliographicNames ?? []}
      onChange={onChangeFIO}
      toolbar={!disabled && ['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО', formatValue: row => row.name },
        { label: 'Язык', formatValue: row => row.language?.label || '' },
        { label: 'Использовать по умолчанию', formatValue: row => (row.isDefault ? 'Да' : 'Нет') },
        {
          label: 'Создано',
          formatValue: ({ createdBy, createdDate }) =>
            createdDate ? `${createdBy ?? userPermission?.userName ?? ''} (${createdDate})` : '',
        },
        {
          label: 'Изменено',
          formatValue: ({ modifiedBy, modifiedDate }) =>
            modifiedDate ? `${modifiedBy ?? userPermission?.userName ?? ''} (${modifiedDate})` : '',
        },
      ]}
      maxHeight="none"
      withMessages
      isDeleteConfirmEnabled
      specification={{
        mode: 'customComponent',
        renderComponent: (biblioName, setBiblioName) => <Fields biblioName={biblioName} onChange={setBiblioName} />,
        validation: {
          checkIsValid: biblioName => validate(biblioName).every(x => x.isValid),
          onInvalidate: biblioName => {
            const info = validate(biblioName);
            info.forEach(({ isValid, invalidMessage }) => {
              if (!isValid) {
                setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
              }
            });
          },
        },
      }}
    />
  );
}

import { useCallback, useLayoutEffect, useMemo, useState } from 'react';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { ValueOf } from 'types/helpers';
import { Form as F } from 'types/models';
import { getMockPurchaseRequest } from './helpers/getMockModels';
import { Performer, Project } from 'types/models/Project';
import { useAppDataContext } from 'features/AppData/context';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './helpers/validate';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectScientistRole, PurchaseRequestContractKind, PurchaseRequestUnitType } from 'utils/Enums';
import { PurchaseRequest } from 'types/models/PurchaseRequest';
import { formatStr } from 'utils/Constants';

type Arguments = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController(args: Arguments) {
  const { viewMode, editMode, onClose } = args;

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const {
    look: { id, projectId, contractKind },
  } = useFormContext<F.PurchaseRequestFormLook>();

  const tableStreams = useLocalTableStreams();

  const { enumMap } = useAppDataContext();

  const [purchaseRequest, setPurchaseRequest] = useState<PurchaseRequest>(getMockPurchaseRequest(contractKind));

  const isLess100Products = purchaseRequest.contractKind?.value === PurchaseRequestContractKind.LESS_100_PRODUCTS;
  const isLess100Services = purchaseRequest.contractKind?.value === PurchaseRequestContractKind.LESS_100_SERVICES;
  const isMore100 = purchaseRequest.contractKind?.value === PurchaseRequestContractKind.MORE_100_SERVICES_AND_PRODUCTS;

  const { methods: getPurchaseRequestAPI } = BackendAPI.useBackendAPI('GetPurchaseRequest');
  const { methods: savePurchaseRequestAPI } = BackendAPI.useBackendAPI('SavePurchaseRequest');
  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');
  const { methods: getReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const isFinanceResponsibleRequired = useMemo(
    () =>
      purchaseRequest.contractKind?.value === PurchaseRequestContractKind.LESS_100_PRODUCTS ||
      (purchaseRequest.contractKind?.value === PurchaseRequestContractKind.MORE_100_SERVICES_AND_PRODUCTS &&
        purchaseRequest.units[0]?.type?.value === PurchaseRequestUnitType.PRODUCT),
    [purchaseRequest.contractKind?.value, purchaseRequest.units],
  );

  const makeChangeHandler = (key: keyof PurchaseRequest) => (val: ValueOf<PurchaseRequest>) => {
    setPurchaseRequest(prev => ({ ...prev!, [key]: val }));
    if (key === 'project') {
      loadProject((val as Project)?.id);
    }
  };

  const loadProject = useCallback(
    (projId?: string | null, applicant?: Performer | null) => {
      if (!projId) return;
      getProjectAPI.callAPI(
        { id: projId },
        {
          onSuccessfullCall: ({ data }) => {
            setPurchaseRequest(prev => ({ ...prev, project: data }));
            if (applicant === undefined) {
              const now = new Date(new Date().setHours(0, 0, 0, 0));

              const findByRole = (role: ProjectScientistRole): Performer | undefined => {
                return data?.performers.find(x =>
                  x?.jobPeriods
                    ? x?.jobPeriods?.find(
                        jp =>
                          jp.role?.value === role &&
                          parse(jp.startDate, formatStr, new Date()) < now &&
                          parse(jp.endDate, formatStr, new Date()) > now,
                      )
                    : false,
                );
              };

              const projectLeader =
                findByRole(ProjectScientistRole.LEADER) ?? findByRole(ProjectScientistRole.RESPONSIBLE_PERFORMER) ?? null;

              setPurchaseRequest(prev => ({ ...prev!, applicant: projectLeader }));
            }
          },
        },
      );
    },
    [getProjectAPI],
  );

  const loadPurchaseRequest = useCallback(
    (requestId: string) => {
      getPurchaseRequestAPI.callAPI(
        { id: requestId },
        {
          onSuccessfullCall: ({ data }) => {
            setPurchaseRequest({ ...data });
            if (data.project?.id) {
              loadProject(data.project?.id, data.applicant);
            }
          },
        },
      );
    },
    [getPurchaseRequestAPI, loadProject],
  );

  const handleSave = useCallback(
    (needClose?: boolean) => {
      return () => {
        const errors = validate({ purchaseRequest, isFinanceResponsibleRequired, isLess100Services, isMore100 });

        if (errors && errors.length > 0) {
          const message = `Заполните обязательные поля: ${errors.join('; ')}`;
          showNotification({ message, theme: 'danger' });
          return false;
        }

        savePurchaseRequestAPI.callAPI(
          { purchaseRequest },
          {
            onSuccessfullCall: ({ data }) => {
              showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
              if (needClose) {
                tableStreams.reloadTable.push({});
                onClose();
              } else if (data.id) {
                loadPurchaseRequest(data.id);
                updateWorkModeAfterSaveAndContinue();
              }
            },
          },
        );
      };
    },
    [
      purchaseRequest,
      isFinanceResponsibleRequired,
      isLess100Services,
      isMore100,
      savePurchaseRequestAPI,
      tableStreams.reloadTable,
      onClose,
      loadPurchaseRequest,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      loadPurchaseRequest(id);
    } else {
      if (projectId) {
        loadProject(projectId);
      }
      getReferenceElementsAPI.callAPI(
        // hardcode, breaks if reference element code is changed. Works only with SFU. Located in "develop" branch
        { filters: [{ key: 'code', values: ['$f50'] }], referenceName: 'RefDepartment' },
        { onSuccessfullCall: ({ data }) => setPurchaseRequest(prev => ({ ...prev, department: data[0] || null })) },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    enumMap,
    purchaseRequest,
    setPurchaseRequest,
    makeChangeHandler,
    handleSave,
    workMode,
    isLess100Products,
    isLess100Services,
    isMore100,
    isFinanceResponsibleRequired,
  };
}

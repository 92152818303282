import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { Form, Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { validate } from './validate';
import { NirRequestKindConsumption } from 'types/models/KindConsumption';
import { ValueOf } from 'types/helpers';
import { Original } from 'types/models/Form';
import { GetKindConsumptionList } from 'features/Table/specifications';
import { GetNirRequestKindConsumptionList } from 'features/Table/specifications';
import { WorkMode } from 'types/models/Table';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const workMode = useMemo((): WorkMode => (editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode'), [editMode, viewMode]);

  const [nirRequestKindConsumption, setNirRequestKindConsumption] = useState<NirRequestKindConsumption>();

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Наименование',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    position: {
      value: '',
      isValid: true,
      required: true,
      title: 'Позиция',
      tooltipText:
        'Порядковый номер позиции в смете на форме заявки. Статьи размещаются в смете в порядке возрастания значений "Позиция"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          position: { ...prevState.position, value },
        }));
      },
    },
    isActive: {
      value: '',
      isValid: true,
      required: false,
      title: 'Активный',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isActive: { ...prevState.isActive, value: !prevState.isActive.value },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      required: false,
      title: 'Описание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    kindConsumption: {
      value: '',
      isValid: true,
      required: false,
      title: 'Виды расходов из бухгалтерии',
      tooltipText:
        // eslint-disable-next-line max-len
        'Используется при формировании консолидированной сметы затрат по всем заявкам на год; важно, если данные по смете передаются в бухгалтерию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kindConsumption: { ...prevState.kindConsumption, value },
        }));
      },
    },
    incrimenator: {
      value: '',
      isValid: true,
      required: false,
      title: 'Входит в группу',
      tooltipText: 'Учитывается в общей сумме для выбранного Вида расхода',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          incrimenator: { ...prevState.incrimenator, value },
        }));
      },
    },
  });

  const { methods: getNirRequestKindConsumption } = BackendAPI.useBackendAPI('GetNirRequestKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setNirRequestKindConsumption(data);

      setFormFields(prevState => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: data.name,
        },
        description: {
          ...prevState.description,
          value: data.description,
        },
        position: {
          ...prevState.position,
          value: data.position,
        },
        isActive: {
          ...prevState.isActive,
          value: data.isActive,
        },
        kindConsumption: {
          ...prevState.kindConsumption,
          value: data.kindConsumption,
        },
        incrimenator: {
          ...prevState.incrimenator,
          value: data.incrimenator,
        },
      }));
    },
  });
  const { methods: saveNirRequestKindConsumption } = BackendAPI.useBackendAPI('SaveNirRequestKindConsumption', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись сохранена', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback((): boolean => {
    const errors: { isValid: boolean; invalidMessage: string }[] = validate({
      formFields,
      nirRequestKindConsumption,
    });
    if (errors && errors.length > 0) {
      errors.forEach(error => {
        showNotification({ message: error.invalidMessage, theme: 'danger' });
      });
      return false;
    }

    saveNirRequestKindConsumption.callAPI({
      id: id || nirRequestKindConsumption?.id || null,
      name: formFields.name.value,
      position: formFields.position.value,
      isActive: formFields.isActive.value,
      description: formFields.description.value,
      kindConsumption: formFields.kindConsumption.value,
      incrimenator: formFields.incrimenator.value,
      incrimentKindConsumptions: nirRequestKindConsumption?.incrimentKindConsumptions || [],
    });
    return true;
  }, [saveNirRequestKindConsumption, id, formFields, nirRequestKindConsumption]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const makeChangeHandler = useCallback(
    (key: keyof NirRequestKindConsumption) => (value: ValueOf<NirRequestKindConsumption>) => {
      if (nirRequestKindConsumption) {
        setNirRequestKindConsumption({ ...nirRequestKindConsumption, [key]: value });
      }
    },
    [nirRequestKindConsumption, setNirRequestKindConsumption],
  );

  const modalTableRowKindConsumptionConverter = useCallback<(row: T.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.name,
    };
  }, []);

  const kindConsumptionTableConfig = GetKindConsumptionList({ hasSelectButton: true });

  const nirRequestKindConsumptionTableConfig = GetNirRequestKindConsumptionList({ hasSelectButton: true });

  const modalTableRowNirRequestKindConsumptionConverter = useCallback<(row: T.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.Name,
    };
  }, []);

  const incrimentKindConsumptionsTooltipTxt =
    // eslint-disable-next-line max-len
    'Сумма по виду расхода рассчитывается автоматически как общая сумма перечисленных видов расходов';

  useLayoutEffect(() => {
    if (id) getNirRequestKindConsumption.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    id,
    viewMode,
    editMode,
    workMode,
    handleFormSubmit,
    formFields,
    nirRequestKindConsumption,
    makeChangeHandler,
    modalTableRowKindConsumptionConverter,
    kindConsumptionTableConfig,
    nirRequestKindConsumptionTableConfig,
    incrimentKindConsumptionsTooltipTxt,
    modalTableRowNirRequestKindConsumptionConverter,
  };
}

import { Notification } from 'types/models';
import { convertItem, convertMaybeArray, getBoolean, getId, getText } from '../commonConverters';

export function convertNotification(notification: any): Notification.Notification {
  return {
    id: getId(notification),
    event: notification.Event ? convertItem(notification.Event) : null,
    recipients: convertMaybeArray(notification.Recipients.Recipient || [], convertRecipient),
    blocks: convertMaybeArray(notification.Blocks.Block || [], covnertBlock),
  };
}

function covnertBlock(block: any): Notification.Block {
  return {
    id: getId(block),
    name: getText(block.Name),
    data: getText(block.Data),
    position: getText(block.Position),
  };
}

function convertRecipient(recipient: any): Notification.Recipient {
  return {
    id: getId(recipient),
    code: getText(recipient.Code),
    isEnabled: getBoolean(recipient.IsEnabled),
  };
}

export function convertNotificationRecipientPreset(notificationRecipientPresets: any[]): Notification.RecipientItem[] {
  return convertMaybeArray(notificationRecipientPresets, recipient => ({
    id: getId(recipient.Scientist),
    fio: recipient.Scientist?._attributes?.fullName,
    email: getText(recipient.Email),
  }));
}

export function convertNotificationLog(notificationLog: any): Notification.NotificationLog {
  return {
    id: getId(notificationLog),
    date: getText(notificationLog.Date),
    theme: getText(notificationLog.Theme),
    body: getText(notificationLog.Body),
  };
}

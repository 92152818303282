import { ProjectType } from 'utils/Enums';

export type State = {
  projectId: string;
  startProjectDate: string;
  endProjectDate: string;
  reloadProject(): void;
  isProjectChanged?: boolean;
  projectType: ProjectType;
  isDisabled: boolean;
};

type Props = {
  projectId: string;
  startProjectDate: string;
  endProjectDate: string;
  reloadProject(): void;
  isProjectChanged?: boolean;
  projectType: ProjectType;
  isDisabled: boolean;
};

export function makeUseCustomController({
  projectId,
  startProjectDate,
  endProjectDate,
  reloadProject,
  isProjectChanged,
  projectType,
  isDisabled,
}: Props) {
  return function useCustomController(): State {
    return {
      projectId,
      startProjectDate,
      endProjectDate,
      reloadProject,
      isProjectChanged,
      projectType,
      isDisabled,
    };
  };
}

import { Author, Person, PersonHistory } from 'types/models';
import { MemberAffilation } from 'types/models/Author';
import { JobStatus } from 'utils/Enums';
import { getMaybeField } from '../commonConverters';
import { ChangePersonHistoryElementLinkRequestData } from './types';

export function convertJobsToRequest(jobs: Person.ScientistJob[]) {
  if (!jobs.length) {
    return {};
  }

  return {
    ScientistJob: jobs.map(job => {
      const {
        id,
        status,
        dateDismissal,
        dateReceipt,
        enterpise,
        isActive,
        isReadOnly,
        refAppointment,
        refDepartment,
        department,
        appointmentCategory,
        staffGroup,
        civilContractName,
        civilContractNumber,
        civilContractDate,
        staffState,
        compoundType,
      } = job;

      const getAppointment = () => {
        if (!refAppointment?.id && status?.value !== JobStatus.CIVIL_CONTRACT) {
          return {};
        }
        return {
          Appointment: { _attr: { id: refAppointment?.id } },
        };
      };

      const getDepartment = () => {
        if (!department && !refDepartment?.id) {
          return {};
        }
        return {
          Department: status?.value !== JobStatus.OTHER ? { _attr: { id: refDepartment?.id } } : department,
        };
      };

      const getAppointmentCategory = () => {
        if (!appointmentCategory?.id || status?.value !== JobStatus.LOCAL) {
          return {};
        }

        return {
          AppointmentCategory: { _attr: { id: appointmentCategory.id } },
        };
      };

      const getStaffGroup = () => {
        if (!staffGroup?.id || status?.value !== JobStatus.LOCAL) {
          return {};
        }

        return {
          StaffGroup: { _attr: { id: staffGroup.id } },
        };
      };

      const getCivilContractName = () => {
        if (status?.value === JobStatus.CIVIL_CONTRACT && civilContractName) {
          return { CivilContractName: civilContractName };
        }
        return {};
      };

      const getCivilContractNumber = () => {
        if (status?.value === JobStatus.CIVIL_CONTRACT && civilContractNumber) {
          return { CivilContractNumber: civilContractNumber };
        }
        return {};
      };

      const getCivilContractDate = () => {
        if (status?.value === JobStatus.CIVIL_CONTRACT && civilContractDate) {
          return { CivilContractDate: civilContractDate };
        }
        return {};
      };

      const getEnterPrise = () => {
        if (status?.value !== JobStatus.CIVIL_CONTRACT) {
          return { Enterprise: enterpise };
        }
        return {};
      };

      return {
        _attr: id ? { id: id } : {},
        Status: status?.value,
        ...getEnterPrise(),
        ReadOnly: String(isReadOnly ?? false),
        Active: String(isActive ?? true),
        ...getAppointment(),
        ...getDepartment(),
        ...getAppointmentCategory(),
        ...getStaffGroup(),
        ...getCivilContractName(),
        ...getCivilContractNumber(),
        ...getCivilContractDate(),
        ...(status?.value !== JobStatus.OTHER && dateReceipt ? { LoDate: dateReceipt } : {}),
        ...(status?.value !== JobStatus.OTHER && dateDismissal ? { HiDate: dateDismissal } : {}),
        ...(staffState?.id ? { StaffState: { _attr: { id: staffState.id } } } : null),
        ...(compoundType?.id ? { CompoundType: { _attr: { id: compoundType.id } } } : null),
      };
    }),
  };
}

export function convertScientistToRequest(scientist: Person.Scientist) {
  return {
    LastName: scientist.lastName,
    FirstName: scientist.firstName,
    DateBirth: scientist.dateBirth,

    Gender: scientist.gender,
    ...(scientist.citizenship?.id ? { Citizenship: { _attr: { id: scientist.citizenship?.id } } } : {}),
    FromBus: scientist.fromBus,
    ...(scientist?.activities ? { Activities: scientist.activities } : {}),
    ...(scientist?.scientificResults ? { ScientificResults: scientist.scientificResults } : {}),
    ...(scientist?.keywordsRus ? { KeyWordRu: scientist.keywordsRus } : {}),
    ...(scientist?.keywordsEnglish ? { KeyWordEng: scientist.keywordsEnglish } : {}),

    ...(scientist.patronymic ? { Patronymic: scientist.patronymic } : {}),
    ...(scientist.englishLastName ? { EnglishLastName: scientist.englishLastName } : {}),
    ...(scientist.englishFirstName ? { EnglishFirstName: scientist.englishFirstName } : {}),
    ...(scientist.englishPatronymic ? { EnglishPatronymic: scientist.englishPatronymic } : {}),
    ...(scientist.note ? { Note: scientist.note } : {}),
    ...(scientist.snils ? { Snils: scientist.snils } : {}),
    ...(scientist.inn ? { Inn: scientist.inn } : {}),
    ...(scientist.languageCommunication ? { LanguageCommunication: scientist.languageCommunication } : {}),
  };
}

export function convertContactToXML({ id, type, value }: Person.Contact) {
  return {
    ...(id ? { _attr: { id: id } } : {}),
    ...getMaybeField('Type', type?.value),
    ...getMaybeField('Value', value),
  };
}

export function convertEducationsToRequest(educations: Person.ScientistEducation[]) {
  if (!educations.length) {
    return {};
  }

  return {
    ScientistEducation: educations.map(education => {
      const {
        id,
        isTSU,
        status,
        enterprise,
        isReadOnly,
        isActive,
        speciality,
        refSpeciality,
        department,
        refDepartment,
        dateDismissal,
        dateReceipt,
      } = education;

      const getSpeciality = () => {
        if (isTSU && refSpeciality) {
          return { Speciality: { _attr: { id: refSpeciality.id } } };
        }

        if (!isTSU && speciality) {
          return { Speciality: speciality };
        }

        return {};
      };

      const getDepartment = () => {
        if (isTSU && refDepartment) {
          return { Department: { _attr: { id: refDepartment.id } } };
        }

        if (!isTSU && department) {
          return { Department: department };
        }

        return {};
      };

      return {
        _attr: id ? { id: id } : {},
        IsLocal: String(isTSU),
        Status: status.value,
        ReadOnly: String(isReadOnly ?? false),
        Active: String(isActive ?? true),
        Enterprise: enterprise,
        ...getSpeciality(),
        ...getDepartment(),
        ...(dateReceipt ? { LoDate: dateReceipt } : {}),
        ...(dateDismissal ? { HiDate: dateDismissal } : {}),
      };
    }),
  };
}

export function convertDegreesToRequest(degrees: Person.ScientistDegree[]) {
  if (!degrees.length) {
    return {};
  }

  return {
    ScientistDegree: degrees.map(degree => {
      const { id, refDegree, dateAssign, isReadOnly, isActive } = degree;
      return {
        _attr: id ? { id: id } : {},
        Degree: { _attr: { id: refDegree?.id } },
        ...(dateAssign ? { DateAssign: dateAssign } : {}),
        ReadOnly: String(isReadOnly ?? false),
        Active: String(isActive ?? true),
      };
    }),
  };
}

export function convertRanksToRequest(ranks: Person.ScientistRank[]) {
  if (!ranks?.length) {
    return {};
  }

  return {
    ScientistRank: ranks.map(rank => {
      const { refRank, id, dateAssign, isActive, isReadOnly } = rank;
      return {
        _attr: id ? { id: id } : {},
        Rank: { _attr: { id: refRank.id } },
        ...(dateAssign ? { DateAssign: dateAssign } : {}),
        ReadOnly: String(isReadOnly ?? false),
        Active: String(isActive ?? true),
      };
    }),
  };
}

export function convertAcademicRanksToRequest(ranks: Person.ScientistAcademicRank[]) {
  if (!ranks?.length) {
    return {};
  }

  return {
    ScientistAcademicRank: ranks.map(rank => {
      const { refAcademicRank, academy, id, dateAssign, isActive, isReadOnly } = rank;
      return {
        _attr: id ? { id: id } : {},
        Rank: { _attr: { id: refAcademicRank.id } },
        Academy: { _attr: { id: academy.id } },
        ReadOnly: String(isReadOnly ?? false),
        Active: String(isActive ?? true),
        ...(dateAssign ? { DateAssign: dateAssign } : {}),
      };
    }),
  };
}

export function convertBiblioNamesToRequest(names: Person.BibliographicName[]) {
  if (!names.length) {
    return {};
  }

  return {
    ScientistBibliographicName: names.map(name => ({
      _attr: name.id ? { id: name.id } : {},
      Language: { _attr: { id: name.language?.id } },
      BibliographicName: name.name,
      IsDefault: String(name.isDefault),
    })),
  };
}

export function convertIdentifiersToRequest(identifiers: Person.Identifier[]) {
  if (!identifiers.length) {
    return {};
  }

  return {
    Identifier: identifiers.map(identifier => ({
      _attr: identifier.id ? { id: identifier.id } : {},
      CitationSystem: { _attr: { id: identifier.citationSystem?.id } },
      Identifier: identifier.identifier,
      ...(identifier.authorName ? { AuthorName: identifier.authorName } : {}),
      ...(identifier.link ? { Link: identifier.link } : {}),
    })),
  };
}

export function convertHirshIndexesToRequest(hirshIndexes: Person.HirshIndex[]) {
  if (!hirshIndexes.length) {
    return {};
  }

  return {
    HirshIndex: hirshIndexes.map(index => ({
      _attr: index.id ? { id: index.id } : {},
      CitationSystem: { _attr: { id: index.citationSystem?.id } },
      Date: index.date,
      ...(index.value ? { Value: index.value } : {}),
      ...(index.note ? { Note: index.note } : {}),
    })),
  };
}

export function convertCommunityMembersToRequest(communityMembers: Person.CommunityMember[], id?: string) {
  if (!communityMembers.length) {
    return {};
  }
  return {
    CommunityMember: communityMembers.map(member => {
      let resultObj: any = {
        _attr: member.id ? { id: member.id } : {},
        Community: { _attr: { id: member.community.id } },
        Role: member.role.value,
      };
      if (member.startDate) resultObj = { ...resultObj, JoinDate: member.startDate };
      if (member.endDate) resultObj = { ...resultObj, LeaveDate: member.endDate };
      if (member.joinDate) resultObj = { ...resultObj, JoinDate: member.joinDate };
      if (member.leaveDate) resultObj = { ...resultObj, LeaveDate: member.leaveDate };
      if (member.cooperationType && member.cooperationType.value)
        resultObj = { ...resultObj, CooperationType: member.cooperationType.value };
      if (member.vakSpecialty && member.vakSpecialty.id)
        resultObj = { ...resultObj, VakSpeciality: { _attr: { id: member.vakSpecialty.id } } };
      if (id) resultObj = { ...resultObj, Scientist: { _attr: { id } } };
      return resultObj;
    }),
  };
}

export function getOrignalIdField(originalField: Person.OriginalIdField | null, field: string) {
  if (!originalField) {
    return {};
  }
  const { id, originalId } = originalField;
  return { [field]: { _attr: { [id ? 'id' : 'originalId']: id || originalId } } };
}

export function getHistoricalSliceXML(personHistory: PersonHistory) {
  const citizenship = personHistory.citizenship ? { Citizenship: { _attr: { id: personHistory.citizenship.id } } } : {};
  const job = getOrignalIdField(personHistory.job ?? null, 'Job');
  const education = getOrignalIdField(personHistory.education, 'Education');
  const degree = getOrignalIdField(personHistory.degree, 'Degree');
  const rank = getOrignalIdField(personHistory.rank, 'Rank');
  const academicRank = getOrignalIdField(personHistory.academicRank ?? null, 'AcademicRank');

  return { ...citizenship, ...job, ...education, ...degree, ...rank, ...academicRank };
}

export function fillPersonToRequest(author: Author, position: number) {
  const person = author.person ? { Person: { _attr: { id: author.person.id } } } : {};
  const scientist = author.person ? { Scientist: { _attr: { id: author.person.id } } } : {};
  const category = author.category ? { Category: { _attr: { id: author.category.id } } } : {};

  const enterprise = author.enterprise ? { Enterprise: { _attr: { id: author.enterprise.id } } } : {};
  const historicalSlice = getHistoricalSliceXML(author);

  return {
    _attr: author.id ? { id: author.id } : {},
    ...(author.role?.id
      ? {
          Role: {
            _attr: author.role ? { id: author.role.id } : {},
          },
        }
      : {}),
    ...(typeof author.isReporter === 'boolean'
      ? {
          IsReporter: author.isReporter,
        }
      : {}),
    ...(author.supervisorId ? { Supervisor: { _attr: { id: author.supervisorId } } } : {}),
    Name: author.name,
    Position: position,
    IsAffilated: String(Boolean(author.isAffilated)),
    AffilateCount: author.affilateCount ?? 0,
    ...(author.person ? { Person: { _attr: { id: author.person.id } } } : {}), // temp
    ...historicalSlice,
    ...person,
    ...scientist,
    ...category,
    ...enterprise,
    ...getMemberAffilationsToRequest(author.memberAffilations),
  };
}

export function convertAuthorsToRequest(authors: Author[], withEmptyCollection?: boolean) {
  if (!authors.length) {
    if (withEmptyCollection) {
      return { Members: {} };
    }
    return {};
  }

  return {
    Members: {
      Member: authors.map((author, position) => fillPersonToRequest(author, position)),
    },
  };
}

export function getMemberAffilationsToRequest(memberAffilations: MemberAffilation[]) {
  if (!memberAffilations || !memberAffilations.length) {
    return { MemberAffiliations: '' };
  }

  return {
    MemberAffiliations: {
      MemberAffiliation: memberAffilations.map((x, index) => {
        function getChild(memberAffilation: MemberAffilation) {
          if (memberAffilation.type === 'ENTERPRISE') {
            return { Enterprise: { _attr: { id: x.id } } };
          }

          if (memberAffilation.type === 'ENTERPRISE_CUSTOMER') {
            return { EnterpriseCustomer: { _attr: { id: x.id } } };
          }

          if (memberAffilation.type === 'PARTNER') {
            return { Partner: { _attr: { id: memberAffilation.partner?.id ?? '' } } };
          }

          return {};
        }

        return {
          _attr: x.memberAffilationId !== null ? { id: x.memberAffilationId } : {},
          Affiliation: {
            _attr: x.affilationId !== null ? { id: x.affilationId } : {},
            ...getChild(x),
          },
          Position: index,
        };
      }),
    },
  };
}

export const convertChangeChangePersonHistoryElementLinkToRequest = ({
  historyEntities,
  id,
  nullify,
  type,
  keepHistory,
}: ChangePersonHistoryElementLinkRequestData) => ({
  ChangePersonHistoryElementLink: {
    _attr: {
      nullify,
      ...(nullify ? { keepHistory } : {}),
      type,
      ...getMaybeField('id', id),
    },
    HistoryEntity: historyEntities.map(historyEntity => ({
      _attr: {
        id: historyEntity.id,
        type: historyEntity.type,
      },
    })),
  },
});

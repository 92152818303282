import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Table as T } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  userId: string;
  tableState: T.State;
};

export function useController({ userId, tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState<boolean>(false);
  const [isRoleFormOpen, setIsRoleFormOpen] = useState<boolean>(false);

  const { methods: deleteUserRoleAPIMethods } = BackendAPI.useBackendAPI('DeleteUserRole', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Роль успешно удалена у пользователя', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const deleteUserRole = useCallback(
    (roleId: string) => {
      deleteUserRoleAPIMethods.callAPI({
        userId,
        roleId,
      });
    },
    [deleteUserRoleAPIMethods, userId],
  );
  const selectedRow = tableState.selectedRows[0];

  const openDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsRoleFormOpen(true);
  }, [setIsRoleFormOpen]);

  const handleCloseRoleForm = useCallback(() => {
    setIsRoleFormOpen(false);
  }, [setIsRoleFormOpen]);

  const { methods: addNewUserRoleAPIMethods } = BackendAPI.useBackendAPI('AddUserRole', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Роль успешно добавлена пользователю', theme: 'success' });
      tableStreams.reloadTable.push({});
      handleCloseRoleForm();
    },
  });

  const addNewUserRole = useCallback(
    (roleId: string) => {
      addNewUserRoleAPIMethods.callAPI({
        userId,
        roleId,
      });
    },
    [addNewUserRoleAPIMethods, userId],
  );

  const saveForm = useCallback(
    (id: string) => {
      if (id) {
        addNewUserRole(id);
      } else showNotification({ message: 'Выберите роль', theme: 'danger' });
    },
    [addNewUserRole],
  );

  const submitCallback = useCallback(
    (state: SubmitTable) => {
      if (state.selectedRows.length) {
        const [row] = state.selectedRows;
        saveForm(row.id);
        setIsRoleFormOpen(false);
      }
    },
    [saveForm, setIsRoleFormOpen],
  );

  const handleDeleteButtonClick = useCallback(() => {
    openDeleteConfirmPopup();
  }, [openDeleteConfirmPopup]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (selectedRow) {
      deleteUserRole(selectedRow?.id);
      setIsDeleteConfirmPopupOpen(false);
    }
  }, [deleteUserRole, selectedRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [handleAddButtonClick, handleDeleteButtonClick, tableState.selectedRows.length],
  );

  return {
    buttons,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleCloseRoleForm,
    isRoleFormOpen,
    submitCallback,
  };
}

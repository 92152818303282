import React, { useState, useMemo } from 'react';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { DataGrid } from 'features/Table/container';
import { GetSalaryJobPaymentList } from 'features/Table/specifications';
import { State } from '../makeUseCustomController';
import { SalaryVacationGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const selectedRows = tableState.selectedRows;
  const [selectedRow] = selectedRows;

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewOpen(true),
        isDisabled: selectedRows.length !== 1,
      },
    ],
    [selectedRows.length],
  );

  const title = useMemo(
    () =>
      [
        `${[selectedRow?.Fio, selectedRow?.Role].filter(Boolean).join(': ')},`,
        'в проекте',
        `${[selectedRow?.ProjectNumber, selectedRow?.ProjectJob].filter(Boolean).join('; ')}.`,
        'Оплачено по источнику',
        selectedRow?.ProjectCodeCode,
      ]
        .filter(Boolean)
        .join(' '),
    [selectedRow],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal title={title} isOpen={isViewOpen} onClose={() => setIsViewOpen(false)} size={['extralarge', 'large-height']}>
        <DataGrid
          specification={GetSalaryJobPaymentList({
            salaryId: selectedRow?.SalaryId,
            code: selectedRow?.Code,
            projectCodeId: customState.projectCodeId,
          })}
        />
      </Modal>

      <Modal title="Отпуска и характер работы" isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} mode="help" size="large">
        {SalaryVacationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import {
  ButtonMode,
  FormComponent,
  Modal,
  RefList,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  Toolbar,
} from 'components';

import { Table } from 'types/models';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { GetKindConsumptionList } from '../..';
import { ProjectCodeGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    projectId,
    departments,
    setIsDepartmentsModalOpen,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isReplaceButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isDepartmentsModalOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleDepartmentsChange,
    buttons,
    departmentModalDisclaimerText,
    isReplaceConfirmPopupOpen,
    handleCloseReplaceConfirmPopup,
    handleConfirmReplaceConfirmPopup,
    isInReplaceMode,
    projectValidationModalTxt,
    isProjectValidationModalOpen,
    setIsProjectValidationModalModalOpen,
    handleSaveDepartmentsClick,
    isReportOpen,
    currentReport,
    onReportClose,
    settings,
    isCreateModalOpen,
    closeCreateModal,
    confirmCreateModal,
    createInfo,
    setCreateInfo,
    isHelpOpen,
    setIsHelpOpen,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.ProjectCodeViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.ProjectCodeEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          replaceMode={isInReplaceMode}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.ProjectCodeAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        projectId={projectId || ''}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      <Modal
        isOpen={isDepartmentsModalOpen}
        onClose={() => setIsDepartmentsModalOpen(false)}
        title="Определение доступа к шифру по подразеделениям"
        size="medium"
      >
        <FormComponent.Template>
          <ControlPanel handleSave={handleSaveDepartmentsClick} />

          <FormComponent.Wrapper>
            <FormComponent.Description mode="info">{departmentModalDisclaimerText}</FormComponent.Description>

            <RefList
              rows={departments}
              onChange={handleDepartmentsChange}
              toolbar={['add', 'edit', 'delete']}
              withMessages
              isDeleteConfirmEnabled
              modalTitle="Список подразделений"
              refName="RefDepartment"
            />
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.ProjectName}) Удалить шифр?</>
        </Modal>
      )}

      {!isReplaceButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isReplaceConfirmPopupOpen}
          onClose={handleCloseReplaceConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: handleConfirmReplaceConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseReplaceConfirmPopup,
            },
          ]}
          size="small"
        >
          <p>
            Выбранный шифр будет удален. Вместо него будет создан новый с сохранением смет и документов, связанных ранее с
            удаленным шифром. Создать шифр с тем же номером, как и удаленный в дальнейшем будет невозможно.
          </p>
          <p>Заменить шифр на новый?</p>
        </Modal>
      )}

      <Modal
        mode="warning"
        title="Внимание"
        isOpen={isProjectValidationModalOpen}
        onClose={() => setIsProjectValidationModalModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsProjectValidationModalModalOpen(false),
          },
        ]}
        size="small"
      >
        <>{projectValidationModalTxt}</>
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />

      <Modal
        title="Создать смету для шифра проекта с передачей накладных на шифр накладных расходов"
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmCreateModal,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeCreateModal,
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Год для сметы" isRequired>
            <TextInput
              value={createInfo.year}
              onChange={e => setCreateInfo(prev => ({ ...prev, year: e }))}
              mode={TextInputMode.YEAR}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата создания">
            <TextDateTime value={createInfo.createdDate} onChange={e => setCreateInfo(prev => ({ ...prev, createdDate: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расходов для передачи накладных">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Виды расходов',
                isClearable: false,
                table: {
                  specification: GetKindConsumptionList({}),
                  onSelect: (row: Table.Entry | null) =>
                    setCreateInfo(prev => ({
                      ...prev,
                      kindConsumption: row ? { id: row.id, value: row.kindConsumption } : null,
                    })),
                },
              }}
              value={createInfo?.kindConsumption?.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Процент для рассчета накладных" contentSize="small">
            <TextInput
              value={createInfo.overheadPercent}
              onChange={e => setCreateInfo(prev => ({ ...prev, overheadPercent: e }))}
              mode={TextInputMode.NUMBER}
              settings={{ min: 0, max: 100, decimal: true }}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        {!!createInfo?.kindConsumption?.id && (
          <FormComponent.Description mode="warning">
            <strong>ВАЖНО:</strong> Если в шифре проекта не указан шифр накладных расходов, то позиция{' '}
            <strong>{createInfo?.kindConsumption?.value}</strong> в смете не создается
          </FormComponent.Description>
        )}
      </Modal>

      <Modal
        mode="help"
        title="Шифры проектов"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {ProjectCodeGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Event, Form } from 'types/models';
import { Notification } from 'features/Notifications';
import { ParticipationProject } from 'types/models/Participation';
import { getError } from 'utils/Validators';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export function validate({
  formFields,
  event,
  isProjectRequired,
  project,
  isVisibleExponentSection,
  isVisibleExpositionFields,
  isVisibleExponentField,
}: {
  formFields: Form.Fields;
  event: Event.Event | null;
  isProjectRequired: boolean;
  project: ParticipationProject | null;
  isVisibleExponentSection: boolean;
  isVisibleExpositionFields: boolean;
  isVisibleExponentField: boolean;
}) {
  const result: Result = {
    nextNotification: { theme: 'danger', message: '' },
    invalidFieldKeys: [],
    isFormValid: true,
  };

  const defaultResult = getError(formFields);

  result.nextNotification = defaultResult.nextNotification;
  result.isFormValid = defaultResult.isFormValid;
  result.invalidFieldKeys = defaultResult.invalidFieldKeys;

  const invalidFieldLabels: string[] = [];

  if (!event) invalidFieldLabels.push('Выставка');
  if (isProjectRequired && !project) invalidFieldLabels.push('Продвижение результатов проекта');

  if (isVisibleExponentSection) {
    if (isVisibleExpositionFields) {
      if (!formFields.collectiveExhibitName.value) invalidFieldLabels.push('Экспозиция');
    }

    if (isVisibleExponentField) {
      if (!formFields.exponent?.value?.id) invalidFieldLabels.push('Название разработки');
    }
  }

  if (!!invalidFieldLabels.length) {
    result.nextNotification.message =
      (result.nextNotification.message ? result.nextNotification.message + '<br />' : '') +
      'Заполните поля: ' +
      invalidFieldLabels.map(x => `"${x}"`).join(', ');
    result.isFormValid = false;
  }

  return result;
}

import React, { ForwardedRef, LegacyRef } from 'react';
import { setup } from 'bem-cn';

import { Button, ButtonProps } from 'components/Button';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('toolbar');

type ToolbarMode = 'form' | 'list';

type Props = {
  /** @property {ForwardedRef<unknown>} refToolbar - (optional) Reference to toolbar component */
  refToolbar?: ForwardedRef<unknown>;
  /** @property {ButtonProps[]} buttons - (optional) List of buttons */
  buttons?: ButtonProps[];
  /** @property {ToolbarMode} mode - (optional) Mode of toolbar ('form' | 'list') paddings and colors */
  mode?: ToolbarMode;
  /** @property {function} onClick - (optional) Callback on button click */
  onClick?(e?: any): void;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
};

/**
 * Toolbar panel
 *
 * @param refToolbar - (optional) Reference to toolbar component
 * @param buttons - (optional) List of buttons
 * @param mode - (optional) Mode of toolbar ('form' | 'list') paddings and colors
 * @param onClick - (optional) If required event on click toolbar
 * @param classMixin - (optional) Mixin class(-es) for external customization
 * @param style - (optional) Styles to component
 * @returns JSX
 */
export const Toolbar = React.memo(({ refToolbar, buttons, mode, onClick, classMixin, style }: Props) => {
  if (!buttons || !buttons.length) {
    return <></>;
  }

  return (
    <div
      ref={refToolbar as LegacyRef<HTMLDivElement> | undefined}
      onClick={onClick}
      className={b({ mode }).mix(classMixin)}
      style={style}
    >
      {buttons.map((x, index) => (
        <Button key={index} {...x} />
      ))}
    </div>
  );
});

import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { FundCardAddTemplate, FundCardEditTemplate } from './modalTemplates';
import { FundCardGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    selectedRow,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isAddFormOpen && (
        <FundCardAddTemplate isOpen={isAddFormOpen} onClose={() => setIsAddFormOpen(false)} relatedTableState={tableState} />
      )}

      {isEditFormOpen && (
        <FundCardEditTemplate
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Список Шифров накладных расходов"
        size="medium"
      >
        {FundCardGridHelp()}
      </Modal>
    </>
  );
};

import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { Event, Table, Report } from 'types/models';
import { Department, Participations, EventProject, TypeControllerIsForm } from 'types/models/Event';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { EventFormat } from 'utils/Enums';
import { mockToSave } from 'features/Form/looks/event/views/ApproveEvent/mockToSave';
import { ValueOf } from 'types/helpers';
import { getEventPermitList, isEventPermitted } from 'utils/Helpers/event';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const { settings, userPermission } = useAppDataContext();
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();
  const tableStreams = useLocalTableStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);

  const [selectedRow] = tableState.selectedRows;
  const selectedRowsLength = tableState.selectedRows.length;

  const reports = useMemo<Report[]>(
    () => [
      Reports.EventConsolidatedListRequest,
      Reports.DepartmentEventConsolidatedListRequest,
      Reports.DepartmentConferenceConsolidatedList,
      Reports.DepartmentConferenceReport,
      Reports.ConferenceMemberCounts,
      Reports.NruP2Anketa,
      Reports.DepartmentConference,
      Reports.DepartmentConferenceParticipation,
      Reports.TotalDepartmentReportAll,
      Reports.MonitoringByEvents,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isMaterialFormOpen, setIsMaterialFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

  const [isAddParticipationFormOpen, setIsAddParticipationFormOpen] = useState<boolean>(false);
  const [isEditParticipationFormOpen, setIsEditParticipationFormOpen] = useState<boolean>(false);
  const [isViewParticipationFormOpen, setIsViewParticipationFormOpen] = useState<boolean>(false);
  const [isFinancingModalOpen, setIsFinancingModalOpen] = useState<boolean>(false);
  const [isDeletePopup, setIsDeletePopup] = useState<boolean>(false);
  const [sourceEvent, setSourceEvent] = useState<Event.Event | null>(null);
  const [participation, setParticipation] = useState<Participations | null>(null);
  const [sourcePublication, setSourcePublication] = useState<Event.Source | null>(null);

  const [isCancelMessagePopupOpen, setIsCancelMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });

  const [eventData, setEventData] = useState<Event.Event | null | undefined>(null);
  const [projects, setProjects] = useState<EventProject[]>([]);
  const [departments, setDepartments] = useState<Event.Department[]>([]);

  const { methods: getEvent } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: saveEvent } = BackendAPI.useBackendAPI('SaveEvent');
  const { methods: deleteEventAPI } = BackendAPI.useBackendAPI('DeleteEvent', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись удалена', theme: 'success' });
      handleCloseDeleteConfirmPopup();
      tableStreams.reloadTable.push({});
    },
  });

  const fetchEvent = useCallback(() => {
    if (selectedRow?.id) {
      getEvent.callAPI(
        {
          simpleFields: {
            type: 'CONFERENCE',
            domain: 'EVENT',
            isInSummaryPlan: false,
            isCompilationPlanned: false,
            IsMagazineReleasePlanned: false,
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          id: selectedRow?.id,
          projects,
          grntis: eventData?.grntis || [],
          memberCounts: [],
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: ({ data }) => {
            ReactDOM.unstable_batchedUpdates(() => {
              setEventData(data);
              setDepartments(data.departments);
              setProjects(data.projects);
            });
          },
        },
      );
    }
  }, [getEvent, selectedRow, setEventData, setDepartments, eventData, projects]);

  const handleSaveEvent = useCallback(
    (callback?: () => void) => {
      if (eventData) {
        const fieldsForRequest = mockToSave(selectedRow?.id, eventData, departments, projects);

        saveEvent.callAPI(
          {
            ...fieldsForRequest,
            id: selectedRow?.id,
          },
          {
            onSuccessfullCall: () => {
              showNotification({
                theme: 'success',
                message: 'Конференция успешно сохранена!',
              });
              callback?.();
            },
            onFailedCall: () => {},
          },
        );
      }
    },
    [selectedRow, saveEvent, departments, eventData, projects],
  );

  const onCloseParticipationForm = () => {
    setSourcePublication(null);
  };

  const controllerIsForm: TypeControllerIsForm = {
    sourceEvent,
    participation,
    onCloseParticipationForm,
    isView: isViewParticipationFormOpen,
    isEdit: isEditParticipationFormOpen,
    isAdd: isAddParticipationFormOpen,
    isDelete: isDeletePopup,
    sourcePublication,
    setSourcePublication,
    setParticipation,
    setAdd: setIsAddParticipationFormOpen,
    setEdit: setIsEditParticipationFormOpen,
    setView: setIsViewParticipationFormOpen,
    setDelete: setIsDeletePopup,
    setSourceEvent,
  };

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsCancelMessagePopupOpen(true);
  }, []);

  const handleApproveButtonClick = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteEventAPI.callAPI({ eventId: tableState.selectedRows[0].id });
  }, [deleteEventAPI, tableState.selectedRows]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleMaterialButtonClick = useCallback(() => {
    setIsMaterialFormOpen(true);
  }, []);

  const handleTemplateCloseMaterialForm = useCallback(() => {
    setIsMaterialFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleOpenFinancialSupport = useCallback(() => {
    fetchEvent();
    setIsFinancingModalOpen(true);
  }, [fetchEvent]);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const handleAttractedDepartmentsChange = useCallback(
    (value: Department[]) => {
      const depExists = (depId?: string) => value.find(d => d.id === depId);
      const newDepartments = departments.map(d => {
        if (d) {
          return { ...d, isFinancing: !!depExists(d.id) };
        }
        return d;
      });
      setDepartments(newDepartments);
    },
    [departments, setDepartments],
  );

  const { methods: addNewMessage } = BackendAPI.useBackendAPI('SaveEventUserFeedline');

  const onCloseMessageConfirm = useCallback(() => {
    setIsCancelMessagePopupOpen(false);
    setIsApprovePopupOpen(false);
    tableStreams.reloadTable.push({});
  }, [tableStreams]);

  const onCancelMessageConfirmClick = useCallback(() => {
    addNewMessage.callAPI(
      {
        message: publicationMessage,
        messageId: '',
        labelId: publicationMessageType?.id,
        eventId: tableState.selectedRows[0]?.id,
      },
      {
        onSuccessfullCall: () => {
          onCloseMessageConfirm();
        },
      },
    );
  }, [tableState, addNewMessage, publicationMessageType, onCloseMessageConfirm, publicationMessage]);

  const onCancelMessageConfirmWithoutMessageClick = useCallback(() => {
    onCloseMessageConfirm();
  }, [onCloseMessageConfirm]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: Permits.CONFERENCE_VIEW,
        },
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.CONFERENCE_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: {
          name: getEventPermitList('CONFERENCE', 'EDIT'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'CONFERENCE', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: {
          name: getEventPermitList('CONFERENCE', 'DELETE'),
        },
        isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'CONFERENCE', 'DELETE'),
      },
      {
        icon: { type: 'library', mode: 'view' },
        title: 'Просмотр материалов',
        onClick: handleMaterialButtonClick,
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'like' },
        title: 'Процедура утверждения',
        onClick: handleApproveButtonClick,
        permission: {
          name: Permits.CONFERENCE_APPROVE,
        },
        isDisabled: selectedRowsLength !== 1,
      },
      {
        icon: { type: 'currency' },
        title: 'Финансирование',
        onClick: handleOpenFinancialSupport,
        permission: {
          name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT,
        },
        isDisabled: selectedRowsLength !== 1 || !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_MY_FINANCING),
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      selectedRowsLength,
      handleAddButtonClick,
      handleEditButtonClick,
      userPermission,
      tableState.selectedRows,
      handleDeleteButtonClick,
      handleMaterialButtonClick,
      handleApproveButtonClick,
      handleOpenFinancialSupport,
      getReports,
      handleSetCurrentReport,
    ],
  );

  const isApproved = useMemo(() => {
    return !!selectedRow;
  }, [selectedRow]);

  const setEventDataField = useCallback(
    (fieldName: keyof Event.Event) => (value: ValueOf<Event.Event>) => {
      if (eventData) {
        setEventData({ ...eventData, [fieldName]: value });
      }
    },
    [eventData, setEventData],
  );

  const departmentOptions = useMemo(() => departments.filter(d => !d.isFinancing), [departments]);

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    isOpenHelpForm,
    setIsOpenHelpForm,
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    isMaterialFormOpen,
    handleTemplateCloseMaterialForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    isFinancingModalOpen,
    setIsFinancingModalOpen,
    buttons,
    settings,
    userPermission,
    isApproved,
    eventData,
    setEventDataField,
    departmentOptions,
    handleAttractedDepartmentsChange,
    departments,
    projects,
    setProjects,
    handleSaveEvent,
  };
}

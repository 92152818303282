import { useCallback } from 'react';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';

export function useController() {
  const suchLikeEventStreams = useLocalSuchLikeEventStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikeEventStreams.openSuchLikeEvents.push();
  }, [suchLikeEventStreams.openSuchLikeEvents]);

  return {
    openDuplicatesForm,
  };
}

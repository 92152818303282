import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  hasSelectButton?: boolean;
};

export function GetProtocolCommissionList({ hasSelectButton = false }: Props): Table.Specification {
  return {
    apiID: 'GetProtocolCommissionList',
    header: {
      firstLevel: {
        title: 'Конкурсные комиссии для процедуры рассмотрения заявок на участие в конкурсах',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
        hasSelectButton: hasSelectButton,
      },
    },
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export function GetSalaryByProjectList(projectCodeId?: string): Table.Specification<State, { projectCodeId?: string }> {
  return {
    apiID: 'GetSalaryByProjectList',
    header: {
      firstLevel: {
        title: 'Список выплат за счёт средств шифров проектов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ projectCodeId }),
    requestData: { projectCodeId: projectCodeId },
    highlightRow: (row: Table.Entry) => (row.ScientistFinded === 'false' ? Color.warning : ''),
  };
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { State } from '../makeUseCustomController';
import { useReportsHook, Reports } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController(props: Props) {
  const {
    tableState,
    customState: { mode },
  } = props;
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Equip',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [selectedRow?.id, tableStreams.deleteRow]);

  const reports = useMemo<Report[]>(() => [Reports.AgeStructureTechnicalLevelMachineryEquipment], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: {
          name: [
            Permits.EQUIP_DESCRIPTION_VIEW,
            Permits.EQUIP_DOCUMENTS_VIEW,
            Permits.EQUIP_LOCATION_VIEW,
            Permits.EQUIP_METROLOGIC_VIEW,
            Permits.EQUIP_PURCHASE_VIEW,
          ],
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permission: { name: Permits.EQUIP_ADD },
        isHidden: mode !== 'equip',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: {
          name: [
            Permits.EQUIP_DESCRIPTION_EDIT,
            Permits.EQUIP_DOCUMENTS_EDIT,
            Permits.EQUIP_LOCATION_EDIT,
            Permits.EQUIP_METROLOGIC_EDIT,
            Permits.EQUIP_PURCHASE_EDIT,
          ],
        },
        isHidden: mode === 'search',
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.EQUIP_DELETE },
        isHidden: mode !== 'equip',
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport, mode, tableState.selectedRows.length],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
    currentReport,
    isReportOpen,
    onReportClose,
  };
}

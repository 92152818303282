import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { DashboardDetailMagazineGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.MagazineViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {DashboardDetailMagazineGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import * as T from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

import { LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  requestData: T.GetConferencePublicationListRequest;
  eventId?: string;
  editable?: boolean;
};

export const GetConferencePublicationList = ({ requestData, eventId, editable = true }: Props): Table.Specification<State> => ({
  apiID: 'GetConferencePublicationList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  useCustomController: makeUseCustomController({ eventId, editable }),
});

import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

import { Permits } from 'utils/Permissions';

export function usePermissions() {
  const { userPermission } = useAppDataContext();

  const isViewableDocuments = isHasPermission(userPermission, Permits.PARTNER_DOCUMENT_ACCESS);
  const isViewableContacts = isHasPermission(userPermission, Permits.PARTNER_CONTACT_ACCESS);

  return {
    isViewableDocuments,
    isViewableContacts,
  };
}

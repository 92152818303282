import React, { useMemo } from 'react';

import {
  Checkbox,
  FormComponent,
  ListEdit,
  Radio,
  ReferenceItem,
  RefList,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadImage,
} from 'components';

import { TenderCustomerInputs } from 'features/Form/looks/tender/TenderForm/TenderCustomerInputs';
import { showNotification } from 'features/Notifications';
import { Form, Table } from 'types/models';
import { Customer, Lot, Tender } from 'types/models/Tender';
import { GetReferenceScientistListSpecification } from 'features/Table/specifications';
import { useController } from './controller';
import { TenderRequestType } from 'utils/Enums';
import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Lots } from './Lots';
import { WorkMode } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  viewMode?: boolean;
  workMode: WorkMode;
  formFields: Form.Fields;
  onProgramIdChange(value: string): void;
  onProgramEventIdChange(value: string): void;
  customers: Customer[];
  setCustomers: React.Dispatch<React.SetStateAction<Customer[]>>;
  categories: ReferenceItem[];
  participants: ReferenceItem[];
  scienceDomainInterrests: ReferenceItem[];
  handleCustomersFieldChange(value: Customer[]): void;
  handleCategoriesChange(value: ReferenceItem[]): void;
  handleParticipantsChange(value: ReferenceItem[]): void;
  handleScienceDomainInterrestChange(value: ReferenceItem[]): void;
  tender: Tender;
  setTender: React.Dispatch<React.SetStateAction<Tender>>;
  handleLotsChange(value: Lot[]): void;
};

export function TenderTab(props: Props) {
  const {
    viewMode,
    workMode,
    formFields,
    onProgramIdChange,
    onProgramEventIdChange,
    customers,
    categories,
    participants,
    scienceDomainInterrests,
    handleCustomersFieldChange,
    handleCategoriesChange,
    handleParticipantsChange,
    handleScienceDomainInterrestChange,
    tender,
    setTender,
    handleLotsChange,
    setCustomers,
  } = props;

  const {
    programSpecification,
    modalTableRowConverter,
    programEventSpecification,
    modalProgramEventTableRowConverter,
    onProgramChange,
    backgroundImageId,
    setBackgroundImageId,
  } = useController({
    viewMode,
    onProgramIdChange,
    onProgramEventIdChange,
    formFields,
    tender,
    setTender,
    setCustomers,
    workMode,
  });

  const { settings } = useAppDataContext();

  const tooltipLots: string = useMemo(() => {
    if (tender.requestType?.value == TenderRequestType.NIR_REQUEST) {
      return '';
    }
    // eslint-disable-next-line max-len
    return 'Для конкурсов с пометкой "Оформлять заявки через личный кабинет" должен быть добавлен хотя бы один лот. Если лот не добавлен вручную он будет автоматически заполнен системой';
  }, [tender.requestType?.value]);

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '65%' }}>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: '20%', height: '150px' }}>
              <UploadImage
                viewMode={!!viewMode}
                text="Логотип конкурса"
                id={formFields.logo.value?.id}
                onChange={formFields.logo.onChange}
                backgroundId={backgroundImageId}
              />
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '80%' }}>
              <FormComponent.Line>
                <FormComponent.Field
                  label={formFields.program.title}
                  isRequired={tender.requestType?.value === TenderRequestType.NIR_REQUEST}
                  // eslint-disable-next-line max-len
                  tooltip="Выбор из списка программ. После выбора программы, если у программы есть логотип, то он автоматически отобразится рядом"
                >
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: 'Выберите программу из списка',
                      isClearable: tender.requestType?.value !== TenderRequestType.NIR_REQUEST,
                      table: {
                        specification: programSpecification,
                        onSelect: (row: Table.Entry | null) => {
                          if (row) {
                            onProgramChange(modalTableRowConverter(row));
                          } else {
                            formFields.program.onChange(null);
                            setBackgroundImageId('');
                          }
                        },
                      },
                    }}
                    value={formFields.program.value?.name}
                    isDisabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label={formFields.programEvent.title}
                  isRequired={tender.requestType?.value === TenderRequestType.NIR_REQUEST}
                  // eslint-disable-next-line max-len
                  tooltip="Выбор из списка мероприятий программы. Не обязательное поле, используется для дополнительной аналитики конкурсов  в рамках государственной программы, например «РНФ» с заявленными мероприятиями и направлениями"
                >
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: 'Мероприятие (вид конкурса)',
                      isClearable: tender.requestType?.value !== TenderRequestType.NIR_REQUEST,
                      table: {
                        specification: programEventSpecification,
                        onSelect: (row: Table.Entry | null) => {
                          formFields.programEvent.onChange(row ? modalProgramEventTableRowConverter(row) : null);
                        },
                      },
                    }}
                    value={formFields.programEvent.value?.name}
                    isDisabled={!!viewMode || !formFields.program.value?.id}
                  />
                  {formFields.programEvent.value?.direction?.name && (
                    <FormComponent.Description mode="warning" classMixin="is-hint">
                      <strong>Направление (категория конкурса): </strong> {formFields.programEvent.value?.direction?.name}
                    </FormComponent.Description>
                  )}
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.name.title} isRequired tooltip="Полное название конкурса">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.description.title}
            // eslint-disable-next-line max-len
            tooltip="Условия проведения конкурса. При копировании из текстового файла сохраняется оригинальное оформление. С помощью инструментов для форматирования, текст можно отредактировать. Поле используется для оформления объявления о конкурсе"
          >
            <TextArea
              mode={TextAreaMode.WYSIWYG}
              settings={{ rows: 3 }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.collectiveType.title}
            isRequired={formFields.collectiveType.required}
            tooltip="Используется для оформления объявления и аналитики по конкурсам"
          >
            <Select
              mode={SelectMode.ENUM}
              value={formFields.collectiveType.value}
              settings={{ name: 'TenderCollectiveType' }}
              onChange={formFields.collectiveType.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
          <FormComponent.Field label={formFields.url.title} tooltip="Адрес web-страницы с объявлением конкурса">
            <TextInput
              mode={TextInputMode.URL}
              value={formFields.url.value}
              onChange={formFields.url.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: '33.3%' }}>
              <RefList
                header={{
                  tooltip:
                    // eslint-disable-next-line max-len
                    'Выберите категории из справочника «Категории конкурса» в соответствии с условиями конкурса. Используется для оформления объявления и управленческой отчётности',
                }}
                rows={categories || []}
                onChange={handleCategoriesChange}
                toolbar={['add', 'delete']}
                columns={[{ label: 'Категории конкурса', formatValue: ref => ref?.label }]}
                isDisabled={!!viewMode}
                withoutHead={false}
                isDeleteConfirmEnabled
                withMessages
                modalTitle='Справочник "Категории конкурса"'
                refName="RefTenderCategory"
              />
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '33.3%' }}>
              <RefList
                header={{
                  tooltip:
                    // eslint-disable-next-line max-len
                    'Выберите категории участников из справочника «Участники конкурса» в соответствии с условиями конкурса. Используется для оформления объявления и управленческой отчётности',
                }}
                rows={participants || []}
                onChange={handleParticipantsChange}
                toolbar={['add', 'delete']}
                columns={[{ label: 'Участники конкурса', formatValue: ref => ref?.label }]}
                isDisabled={!!viewMode}
                withoutHead={false}
                isDeleteConfirmEnabled
                withMessages
                modalTitle='Справочник "Участники конкурса"'
                refName="RefTenderParticipant"
              />
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '33.3%' }}>
              <RefList
                header={{
                  tooltip:
                    // eslint-disable-next-line max-len
                    'Выберите значения в соответствии с условиями конкурса из справочника «Области науки». Если необходимая область науки отсутствует, то добавьте ее в справочник. Элементы используются как тэги при аналитике и поиске по ключевым словам. В заявке заявитель имеет возможность выбрать значения области науки только из коллекции конкурса. Кроме того,  на основе выбранных значений  будет выполнена адресная рассылка уведомлений о конкурсе  пользователям  системы на электронную почту',
                }}
                rows={scienceDomainInterrests || []}
                onChange={handleScienceDomainInterrestChange}
                toolbar={['add', 'delete']}
                columns={[{ label: 'Области научных интересов', formatValue: ref => ref?.label }]}
                isDisabled={!!viewMode}
                withoutHead={false}
                isDeleteConfirmEnabled
                withMessages
                modalTitle='Справочник "Области научных интересов"'
                refName="RefScienceDomainInterest"
              />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>

        <SectionTitle
          title="Список лотов конкурса"
          tooltip={tooltipLots}
          isRequired={tender.requestType?.value == TenderRequestType.NIR_REQUEST}
        />

        <FormComponent.Line>
          <Lots
            viewMode={viewMode}
            lots={tender.lots}
            handleLotsChange={handleLotsChange}
            tenderName={formFields.name?.value}
            tenderStartDate={formFields.dateSummingUp?.value}
            requestType={tender.requestType?.value!}
            // eslint-disable-next-line max-len
            tooltip="Заполняется для конкурсов из категории: проведение научных мероприятий, издание научных трудов, на выполнение НИОКР, проекты в области предпринимательства и инноваций, стипендии и премии. Конкурс может иметь несколько лотов"
          />
        </FormComponent.Line>

        <SectionTitle title="Заказчики" />

        <FormComponent.Line>
          <ListEdit
            header={{
              tooltip:
                // eslint-disable-next-line max-len
                'Выберите организацию из списка организаций. Адрес и контакты, введённые на форме выбора организации-заказчика сохраняются только в конкурсе (т.е.  являются атрибутами конкурса)',
            }}
            rows={customers}
            onChange={handleCustomersFieldChange}
            toolbar={['add', 'edit', 'delete']}
            columns={[
              { label: 'Организация', formatValue: row => row.fullName },
              { label: 'Адрес', formatValue: row => row.address },
              { label: 'Контакты', formatValue: row => row.contacts },
            ]}
            withMessages
            isDeleteConfirmEnabled
            isDisabled={!!viewMode}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => <TenderCustomerInputs customer={row} onCustomerChange={onChange as any} />,
              validation: {
                checkIsValid: row => !!row?.customerId,
                onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
              },
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Дополнительная информация"
            tooltip="Иная информация по конкурсу, заполняется в свободной форме"
          >
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Дополнительная информация' }}
              value={formFields.marketplaceNote.value}
              onChange={formFields.marketplaceNote.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: 'calc(35% - 20px)' }}>
        <SectionTitle title="Порядок размещения заявок" />

        <FormComponent.Line>
          <FormComponent.Field
            label="Дата объявления"
            isRequired={formFields.announcementDate.required}
            tooltip="Дата объявления конкурса на  площадке размещения конкурса (сайте Заказчика)"
          >
            <TextDateTime
              value={formFields.announcementDate.value}
              onChange={formFields.announcementDate.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.dateUniversity.title}
            isRequired={formFields.dateUniversity.required}
            tooltip="Дата, до которой Консультанты принимают заявки на конкурс для согласования на уровне Университета. 
По истечении этой даты пользователь личного кабинета не может добавить новую заявку."
          >
            <TextDateTime
              value={formFields.dateUniversity.value}
              onChange={formFields.dateUniversity.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={`План по заявкам ${settings?.organization?.shortName}`}
            tooltip="Количество заявок доступное для подачи от университета"
          >
            <div style={{ maxWidth: '70px' }}>
              <TextInput
                mode={TextInputMode.NUMBER}
                settings={{ max: 99999, decimal: false }}
                value={formFields.requestsPlan.value}
                onChange={formFields.requestsPlan.onChange}
                isDisabled={!!viewMode}
              />
            </div>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Дата окончания приема заявок"
            isRequired={formFields.dateOpenEnvelope.required}
            tooltip="Дата и точное время окончания регистрации заявок в ИАС организатора конкурса"
          >
            <TextDateTime
              value={formFields.dateOpenEnvelope.value}
              onChange={formFields.dateOpenEnvelope.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Время (МСК)">
            <TextDateTime
              type={['time']}
              onChange={formFields.timeOpenEnvelope.onChange}
              value={formFields.timeOpenEnvelope.value ?? ''}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Дата рассмотрения заявок"
            isRequired={formFields.dateExamenation.required}
            tooltip="Дата рассмотрения заявок на площадке размещения конкурса"
          >
            <TextDateTime
              value={formFields.dateExamenation.value}
              onChange={formFields.dateExamenation.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Дата подведения итогов"
            isRequired={formFields.dateSummingUp.required}
            tooltip="Дата подведения итогов на площадке размещения конкурса"
          >
            <TextDateTime
              value={formFields.dateSummingUp.value}
              onChange={formFields.dateSummingUp.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Оформлять заявки через личный кабинет"
            // eslint-disable-next-line max-len
            tooltip="В зависимости от выбранного значения формируется текст объявления о конкурсе и возможность добавить заявку на конкурс через личный кабинет"
          >
            <Checkbox
              checked={!tender.isSpecial}
              onChange={e => setTender(prev => ({ ...prev, isSpecial: !e }))}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Определение победителя (исполнителя)" />

        <FormComponent.Line>
          <FormComponent.Field
            label="Способ определения победителя"
            isRequired={formFields.isMarketplace.value}
            tooltip="Выберете формат проведения конкурса из списка"
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.kindTrade.value}
              onChange={formFields.kindTrade.onChange}
              settings={{
                name: 'RefKindTrade',
                title: 'Способ определения победителя',
                isClearable: !formFields.isMarketplace.value,
              }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.type.title}
            isRequired={formFields.isMarketplace.value}
            tooltip="Выберете вид тендера из списка"
          >
            <Select
              mode={SelectMode.ENUM}
              value={formFields.type.value}
              settings={{ name: 'TenderType', isClearable: true }}
              onChange={formFields.type.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Электронная торговая площадка" />

        <FormComponent.Line>
          <FormComponent.Field label="" tooltip="Выбор элемента из списка ЭТП. Раздел заполняется для конкурсов, размещённых ЭТП">
            <Radio
              isDisabled={!!viewMode}
              value={formFields.isMarketplace.value ? 'true' : 'false'}
              list={[
                { label: 'Да', value: 'true' },
                { label: 'Нет', value: 'false' },
              ]}
              onChange={(value: string) => formFields.isMarketplace.onChange(value === 'true')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Название ЭТП"
            // eslint-disable-next-line max-len
            tooltip="Выберите название из справочника. Если  в справочнике отсутствует значение — добавьте или  попросите об этом ответственного за справочник"
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.marketplace.value}
              onChange={formFields.marketplace.onChange}
              settings={{ name: 'RefMarketplace', title: 'Электронные торговые площадки', isClearable: true }}
              isDisabled={!!viewMode || !formFields.isMarketplace.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Тариф ЭТП"
            // eslint-disable-next-line max-len
            tooltip="Тариф ЭТП за участие в тендере. Для участия в конкурсе, на момент подачи заявки сумма денежных средств должна быть на ЭТП"
          >
            <TextInput
              mode={TextInputMode.NUMBER}
              value={formFields.marketplaceAmount.value ? formFields.marketplaceAmount.value.toString() : '0'}
              onChange={formFields.marketplaceAmount.onChange}
              isDisabled={!!viewMode || !formFields.isMarketplace.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Консультанты по заявке" />

        <FormComponent.Line>
          <ListEdit
            header={{
              tooltip: 'Это сотрудники университета, которые консультируют участников конкурса и согласовывают заявку',
            }}
            rows={tender.contacts}
            onChange={x => setTender(prevState => ({ ...prevState, contacts: x }))}
            toolbar={['add', 'delete']}
            columns={[
              {
                label: 'Контактные данные',
                formatValue: row => [row.fullName, getPersonContacts(row.contacts)].filter(Boolean).join(': '),
              },
              { label: 'Фото', formatValue: row => !!row.avatar.id, styles: { width: '5%', textAlign: 'center' } },
            ]}
            withMessages
            isModalHintHidden
            isDeleteConfirmEnabled
            isDisabled={!!viewMode}
            specification={{
              mode: 'relationTableModal',
              relationTableModalTitle: 'Справочник "Персоны"',
              modalTableRowConverter: row => ({ ...getMockPerson({ initialCountryCode: null }), id: row.id }),
              modalTableSpecification: GetReferenceScientistListSpecification({ hasSelectButton: true }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Очные консультации по адресу" tooltip="Контактная информация консультантов по конкурсу">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={formFields.contactFace.value || ''}
              onChange={formFields.contactFace.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  const ALL = 'all';

  // Attention! On GetPurchaseRequestList param 'type' is parsed!
  const [type, setType] = useState<string>(ALL);

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'type',
      list: [
        { label: 'Все', value: ALL },
        { label: 'Зарегистрированные', value: 'isRegistered' },
        { label: 'Срок поставки', value: 'isDelivered' },
      ],
      value: type,
      onChange: setType,
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.text,
      value: 'Год',
    },
    {
      type: FilterComponentType.input,
      streamKey: 'year',
      value: type === ALL ? '' : new Date().getFullYear().toString(),
      isYearMask: true,
      isDisabled: type === ALL,
    },
  ]);
});

import { useCallback } from 'react';

import { CalendarPlanByYear } from 'types/models/Project';

type Props = {
  entry: CalendarPlanByYear | null;
  onFieldChange(row: CalendarPlanByYear): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleFieldChange = useCallback(
    (fieldName: keyof CalendarPlanByYear) => (value: string) => {
      onFieldChange({ ...entry, [fieldName]: parseFloat(value.replace(' ', '')) } as CalendarPlanByYear);
    },
    [onFieldChange, entry],
  );

  return {
    handleFieldChange,
  };
}

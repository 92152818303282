import { useState, useMemo, useCallback } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams, useTableContext } from 'features/Table/hooks';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const tableContext = useTableContext();

  const relatedTableId = useMemo(() => tableContext.specification.templatesTableDependencies?.relatedRecordId || null, [
    tableContext,
  ]);

  const formContext = (() => {
    try {
      return useFormContext();
    } catch {
      return null;
    }
  })();

  const magazineId = useMemo(() => {
    return relatedTableId || formContext?.look?.id || null;
  }, [formContext?.look?.id, relatedTableId]);

  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);
  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );
  const isApproveButtonDisabled = useMemo<boolean>(() => !rowsAvailability.SINGLE_SELECTED, [rowsAvailability.SINGLE_SELECTED]);

  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState<boolean>(false);

  const openAddForm = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const closeAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const openEditForm = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const closeEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const openConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(true);
  }, [setIsConfirmDeletePopupOpen]);

  const closeConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(false);
  }, [setIsConfirmDeletePopupOpen]);

  const removeRelease = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'MagazineRelease',
    });
  }, [tableState.selectedRows, tableStreams]);

  const approveRelease = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows.map(item => item.id),
      command: 'ApproveMagazineRelease',
      approveItemName: 'MagazineReleases',
      approveInnerItemName: 'MagazineRelease',
    });
  }, [tableState.selectedRows, tableStreams.approveRow]);

  const confirmDeleteRelease = useCallback(() => {
    removeRelease();
    closeConfirmDeletePopup();
  }, [removeRelease, closeConfirmDeletePopup]);

  const toolbarButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: openAddForm,
        isDisabled: !magazineId,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: openEditForm,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: openConfirmDeletePopup,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'like' },
        title: 'Утверждение выпуска',
        onClick: approveRelease,
        permission: { name: Permits.MAGAZINE_RELEASE_APPROVE },
        isHidden: isProfile,
        isDisabled: isApproveButtonDisabled,
      },
    ],
    [
      isProfile,
      magazineId,
      openAddForm,
      rowsAvailability.SINGLE_SELECTED,
      openEditForm,
      openConfirmDeletePopup,
      isApproveButtonDisabled,
      approveRelease,
    ],
  );

  return {
    isAddFormOpen,
    closeAddForm,
    isEditFormOpen,
    closeEditForm,
    toolbarButtons,
    isConfirmDeletePopupOpen,
    confirmDeleteRelease,
    closeConfirmDeletePopup,
    relatedTableId,
  };
};

export default useController;

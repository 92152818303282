import { parse } from 'date-fns';

import { Mode } from 'components/ListEdit/model';

import { Estimate, EstimatePositionInput, EstimatePositionItem } from 'types/models/Estimate';
import { formatStr } from 'utils/Constants';

export function validate(
  indicator: EstimatePositionItem | null,
  index: number | null,
  list: EstimatePositionItem[],
  mode: Mode,
  estimate: Estimate,
) {
  const isDateValid = parse(indicator?.date || '', formatStr, new Date()).getFullYear() === parseInt(estimate?.year || '0');

  const isAmountValid = !!indicator?.amount;
  const isNoteValid = !!(indicator?.note || '').length;

  return [
    { isValid: isDateValid, invalidMessage: `Год сметы "${estimate?.year}" и дата позиции должна быть тем же годом` },
    { isValid: isAmountValid, invalidMessage: 'Поле "Сумма" не может быть пустым' },
    { isValid: isNoteValid, invalidMessage: 'Примечание не может быть пустым' },
  ];
}

export function validateRequest(request: EstimatePositionInput) {
  const isValidAmount = Boolean(request.amount);
  const isValidKindConsumption = Boolean(request.kindConsumptionId);

  return [
    { isValid: isValidAmount, invalidMessage: 'Не заполнено поле "Сумма, план"' },
    { isValid: isValidKindConsumption, invalidMessage: 'Не заполнено поле "Наименование статьи затрат"' },
  ];
}

import { Table } from 'types/models';
import { TemplatesTableDependencies } from 'types/models/Table';
import { Filters } from './filters';

type Deps = {
  templatesTableDependencies?: TemplatesTableDependencies;
};

export function GetFullScienceProjectList(deps: Deps = {}): Table.Specification {
  return {
    apiID: 'GetFullScienceProjectList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      firstLevel: {
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

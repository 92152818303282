import { TypeDeleteCode } from 'services/BackendAPI/configurations/publication/types';

export class PublicationTypeDeleteCode {
  static HAS_LINKED_CHILDS: TypeDeleteCode = 'hasLinkedChilds';

  static SENDED_TO_LIBRARY: TypeDeleteCode = 'sendedToLibrary';

  static DELETED_TYPE: TypeDeleteCode = 'deleted';

  static UNBINDED_TYPE: TypeDeleteCode = 'unbinded';
}

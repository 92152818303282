import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetPartnerMipIndicatorList, GetPartnerMipList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function Mip() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['commercialization', 'mip'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Список МИП" id="list">
          <DataGrid specification={GetPartnerMipList()} />
        </Tab>
        <Tab title="Показатели МИП" id="indicator">
          <DataGrid specification={GetPartnerMipIndicatorList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

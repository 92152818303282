import { ForeignSecurityDocument } from 'types/models';

export function getMockForeignSecurityDocument(): ForeignSecurityDocument {
  return {
    id: null,
    type: null,
    kind: null,
    name: '',
    number: '',
    startDate: '',
    endDate: '',
    members: [],
    applicants: '',
    authors: '',
    documentNumber: '',
    documentStartDate: '',
    intellectualPropertyName: '',
    status: null,
    documents: [],
    contract: '',
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.year,
      streamKey: 'receiveYear',
      label: 'Предоставлено в году',
      checked: false,
    },
    {
      type: FilterComponentType.year,
      streamKey: 'activeYear',
      label: 'Действует в году',
      checked: false,
    },
  ]);
});

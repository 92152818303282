import { Event, Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  sourceEvent?: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
};

export function GetContestEventParticipationList(args: Props, workMode: Table.WorkMode): Table.Specification<State> {
  return {
    apiID: 'GetContestEventParticipationList',
    requestData: args.sourceEvent ? { eventId: args.sourceEvent.id } : undefined,
    header: {
      firstLevel: {
        title: 'Список участий в олимпиаде',
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({
      workMode: workMode,
      sourceEvent: args.sourceEvent || null,
      reloadEvent: args.reloadEvent || (() => {}),
      isEventChanged: args.isEventChanged,
    }),
  };
}

import React, { useState } from 'react';

import { Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { FieldsMapModal } from './FieldsMapModal';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';
import { Row } from 'react-table';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export const Toolbar = ({ onSubmitTable, tableState, customState }: Props) => {
  const { name } = customState;

  const [selectedItems, setSelectedItems] = useState<Row[]>();

  const { buttons, isOpenFieldsMapModal, setIsOpenFieldsMapModal } = useController({
    onSubmitTable,
    tableState,
    customState,
    setSelectedItems,
  });

  return (
    <div>
      <SharedToolbar buttons={buttons} />

      <FieldsMapModal
        selectedEntries={selectedItems}
        isOpen={isOpenFieldsMapModal}
        onClose={() => setIsOpenFieldsMapModal(false)}
        refName={name}
      />
    </div>
  );
};

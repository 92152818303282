import React from 'react';

import {
  FormComponent,
  Radio,
  ReferenceItem,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { MipPartner, Table } from 'types/models';
import useController from './controller';
import { GetReferenceScientistList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  founder: MipPartner.Founder;
  setFounder: (founder: MipPartner.Founder) => void;
  amount: string;
  isDisabled?: boolean;
};

export const Fields = ({ founder, setFounder, amount, isDisabled }: Props) => {
  const { typeFounder, amountPercent, onTypeChange, makeHandler, tableRowConverter } = useController({
    founder,
    amount,
    setFounder,
  });

  const { settings } = useAppDataContext();

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Тип учредителя">
          <Radio
            isDisabled={isDisabled}
            value={typeFounder}
            list={[
              { label: 'Физ. лицо', value: 'scientist' },
              { label: 'Юр. лицо', value: 'enterprise' },
              { label: settings?.organization?.shortName, value: 'local' },
            ]}
            onChange={onTypeChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {typeFounder === 'scientist' && (
        <FormComponent.Line>
          <FormComponent.Field label="ФИО" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Справочник "Персоны"',
                isClearable: false,
                table: {
                  specification: GetReferenceScientistList({ hasSelectButton: true }),
                  onSelect: (row: Table.Entry | null) => makeHandler('scientist')(row ? tableRowConverter(row) : null),
                },
              }}
              value={founder.scientist?.fullName}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      {typeFounder === 'enterprise' && (
        <FormComponent.Line>
          <FormComponent.Field label="Организиция" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={founder.enterpriseCustomer && { id: founder.enterpriseCustomer.id, label: founder.enterpriseCustomer.label }}
              onChange={(option: ReferenceItem | null) => makeHandler('enterpriseCustomer')(option)}
              settings={{ name: 'RefEnterpriseCustomer', title: 'Справочник "Организации"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Line>
        <FormComponent.Field label="Контактная информация">
          <TextArea value={founder.info ?? ''} onChange={makeHandler('info')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>

      {typeFounder === 'scientist' && (
        <FormComponent.Line>
          <FormComponent.Field label="Паспортные данные">
            <TextArea value={founder.passport ?? ''} onChange={makeHandler('passport')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Line>
        <FormComponent.Field isRequired label="Сумма">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={founder.amount ?? ''}
            onChange={makeHandler('amount')}
            isDisabled={isDisabled}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Доля (%)">
          <>{amountPercent}</>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Вносимые активы">
          <TextInput value={founder.insertionAssets ?? ''} onChange={makeHandler('insertionAssets')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};

import React, { useCallback } from 'react';

import { Collapse } from 'components';

import { State } from '../makeUseCustomController';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { BiblioFields } from './BiblioFields';
import { SecondLevelProps } from 'features/Table/types';

function Editor({ customState }: SecondLevelProps<State>) {
  const { isOpenEditor, fio, language, setFIO, setLanguage, saveBibliographicName, closeEditor } = customState;

  const onCancel = useCallback(() => {
    closeEditor();
  }, [closeEditor]);

  const onSave = useCallback(() => {
    saveBibliographicName();
    closeEditor();
  }, [closeEditor, saveBibliographicName]);

  return (
    <Collapse isExpanded={isOpenEditor}>
      <ControlPanel handleSave={onSave} handleCancel={onCancel} />
      <BiblioFields fio={fio} language={language} setFIO={setFIO} setLanguage={setLanguage} />
    </Collapse>
  );
}

export { Editor };

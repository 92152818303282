import { CommunityMember } from 'types/models';
import { getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';
import { getHistoricalSliceXML } from 'services/BackendAPI/configurations/author/requestConverters';

export function convertCommunityMemberToXML(member: CommunityMember) {
  const { id, role, joinDate, leaveDate, person, vakSpeciality, cooperationType, description, community, documents } = member;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(role?.value ? { Role: role.value } : {}),
    ...(joinDate ? { JoinDate: joinDate } : {}),
    ...(leaveDate ? { LeaveDate: leaveDate } : {}),
    ...(person?.id ? { Person: { _attr: { id: person?.id } } } : {}),
    ...getHistoricalSliceXML(member),
    ...(vakSpeciality?.id ? { VakSpeciality: { _attr: { id: vakSpeciality.id } } } : {}),
    ...(cooperationType?.value ? { CooperationType: cooperationType.value } : {}),
    ...getMaybeField('Description', description),
    ...(community?.id ? { Community: { _attr: { id: community.id } } } : {}),
    ...(documents?.length ? convertDocumentsToRequest(documents) : {}),
  };
}

import { formatToRequest } from 'features/Form/looks/event/EventFormConference/setup/formatToRequest';
import { Event } from 'types/models';
import { EventProject } from 'types/models/Event';

export function mockToSave(id: string, eventData: Event.Event, departments?: Event.Department[], projects?: EventProject[]) {
  return formatToRequest({
    ...eventData,
    eventId: id,
    attractedFinancing: eventData?.attractedFinancing || '',
    departments: departments || [],
    projects: projects || [],
    formFields: {
      isStudent: { value: eventData.isStudent, isValid: true, title: '', onChange: () => {} },
      isInSummaryPlan: { value: eventData.isInSummaryPlan, isValid: true, title: '', onChange: () => {} },
      isCompilationPlanned: { value: eventData.isCompilationPlanned, isValid: true, title: '', onChange: () => {} },
      isMagazinePlanned: { value: eventData.isMagazineReleasePlanned, isValid: true, title: '', onChange: () => {} },
      site: { value: eventData.site, isValid: true, title: '', onChange: () => {} },
      city: { value: eventData.city, isValid: true, title: '', onChange: () => {} },
      address: { value: eventData.address, isValid: true, title: '', onChange: () => {} },
      contact: { value: eventData.contact, isValid: true, title: '', onChange: () => {} },
      keyWords: { value: eventData.keyWords, isValid: true, title: '', onChange: () => {} },
      name: { value: eventData.name, isValid: true, title: '', onChange: () => {} },
      memberCountPlan: { value: eventData.memberCountPlan || 0, isValid: true, title: '', onChange: () => {} },
      memberCountFact: { value: eventData.memberCountFact || 0, isValid: true, title: '', onChange: () => {} },
      description: { value: eventData.description, isValid: true, title: '', onChange: () => {} },
      startDate: { value: eventData.startDate, isValid: true, title: '', onChange: () => {} },
      orderNumber: { value: eventData.orderNumber, isValid: true, title: '', onChange: () => {} },
      orderDate: { value: eventData.orderDate, isValid: true, title: '', onChange: () => {} },
      endDate: { value: eventData.endDate, isValid: true, title: '', onChange: () => {} },
      eventSeries: { value: { id: eventData.eventSeries?.id }, isValid: true, title: '', onChange: () => {} },
      eventStatus: { value: { id: eventData.eventStatus?.id }, isValid: true, title: '', onChange: () => {} },
      conferenceType: { value: { id: eventData.conferenceType?.id }, isValid: true, title: '', onChange: () => {} },
      country: { value: { id: eventData.country?.id }, isValid: true, title: '', onChange: () => {} },
      form: { value: eventData.format, isValid: true, title: '', onChange: () => {} },
    },
  });
}

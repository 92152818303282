import React, { memo } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-link');

type Props = {
  /** @property {string} label - (optional) Visible text for anhor */
  label?: string;
  /** @property {string} href - (optional) HREF source */
  href?: string;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {JSX.Element | JSX.Element[]} children - (optional) React inner JSX component */
  children?: JSX.Element | JSX.Element[];
};

/**
 * Wrapper of column elements for content (@media dependent)
 *
 * @param {string} label - (optional) Visible text for anhor
 * @param {string} href - (optional) HREF source
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {JSX.Element | JSX.Element[]} children - (optional) React inner JSX component
 * @returns JSX
 */
export const Link = memo(({ href, label, classMixin, children }: Props) => (
  <div className={b({}).mix(classMixin)}>
    {href ? (
      <a href={href} target="_blank" rel="noopener noreferrer">
        {children || label || href}
      </a>
    ) : (
      <span>{label ? label : 'не указана'}</span>
    )}
  </div>
));

import React from 'react';
import block from 'bem-cn';

import { ButtonMode, FormComponent, Header, ListEditTable, Modal, TextArea, Toolbar, UploadFile } from 'components';

import { Component as Block } from './block';
import { useController } from './controller';

import '../style.scss';

const b = block('helpful');

function HelpfulStuff() {
  const {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setIsEditModalOpen,
    clearFormInfo,
    addModalButtons,
    modalData,
    handleModalDataChange,
    selectedRowIndex,
    formFields,
    isEditModalOpen,
    editModalButtons,
    list,
    setSelectedRowIndex,
    buttons,
    isProfile,
  } = useController();

  return (
    <>
      <div className={b()}>
        <Header>Справочные материалы</Header>
        {!isProfile && (
          <div className={b('toolbar')}>
            <Toolbar buttons={buttons} />
          </div>
        )}
        <div className={b('list')}>
          {list.map(e => (
            <Block props={e} key={e.id + e.createdDate} />
          ))}
        </div>
      </div>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteButtonClick,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Удалить справочный материал?</>
      </Modal>

      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          clearFormInfo();
        }}
        title="Добавление справочных материалов"
        size={['medium', 'small-height']}
      >
        <FormComponent.Template>
          <Toolbar buttons={addModalButtons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Описание" isRequired>
                <TextArea settings={{ rows: 3 }} value={modalData} onChange={handleModalDataChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Прикрепите файл" isRequired>
                <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!selectedRowIndex} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
          clearFormInfo();
        }}
        title="Редактирование списка материалов"
        size={['medium', 'small-height']}
      >
        <FormComponent.Template>
          <Toolbar buttons={editModalButtons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Описание" isRequired>
                <TextArea settings={{ rows: 3 }} value={modalData} onChange={handleModalDataChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Прикрепите файл" isRequired>
                <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <ListEditTable
                isStickyHeader={true}
                rows={list}
                columns={[
                  {
                    label: '№',
                    formatValue: (_, index) => index + 1,
                    styles: { width: '5%', textAlign: 'center' },
                  },
                  { label: 'Описание', formatValue: row => row.data },
                  { label: 'Файл', formatValue: row => row.file.name },
                ]}
                selectedRowIndex={selectedRowIndex}
                selectRow={setSelectedRowIndex}
              />
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>
    </>
  );
}

export const Component = React.memo(HelpfulStuff);

import { useState } from 'react';

import { FormComponent, Modal, TextArea } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatEducation } from 'features/SelectPerson/helpers';
import { prepareEducation } from 'features/SelectPerson/PersonHistoryModal/helpers';
import { EditEducations } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  value: Person.ScientistEducation | null;
  onChange(job: Person.ScientistEducation | null): void;
  savePerson(person: Person.ScientistPerson): void;
  isDisabled?: boolean;
};

function SelectEducation(props: Props) {
  const { person, value, onChange, savePerson, isDisabled } = props;

  const [isListPopupOpen, setIsListPopupOpen] = useState<boolean>(false);

  const onChangeEducation = (education: Person.ScientistEducation | null) => {
    const nextEducation = prepareEducation(education);
    const isActive = nextEducation ? nextEducation.isActive : true;

    if (!isActive) {
      showNotification({ message: 'Нельзя выбрать неактивное обучение', theme: 'danger' });
    }

    const preparedJob = isActive ? nextEducation : value;

    setIsListPopupOpen(false);
    onChange(preparedJob);
  };

  const onChangeEducations = (educations: Person.ScientistEducation[]) => {
    savePerson({ ...person, scientistEducations: educations } as Person.ScientistPerson);
  };

  return (
    <>
      <TextArea
        settings={{
          isFixed: true,
          isClearable: true,
          externalButtons: [
            {
              icon: { type: 'table' },
              title: 'Добавить',
              isDisabled: isDisabled,
              onClick: () => {
                setIsListPopupOpen(true);
              },
            },
          ],
        }}
        onClick={() => setIsListPopupOpen(true)}
        onChange={val => {
          if (!val) onChange(null);
        }}
        value={value ? formatEducation(value) : ''}
        placeholder="Нажмите чтобы выбрать..."
        isDisabled={isDisabled}
      />

      <Modal
        isOpen={isListPopupOpen}
        onClose={() => setIsListPopupOpen(false)}
        title="Выберите место обучения двойным кликом"
        size={['extralarge', 'medium-height']}
      >
        <FormComponent.Wrapper>
          <EditEducations
            mode="history"
            person={person}
            onChangeEducations={onChangeEducations}
            onSelect={onChangeEducation}
            disabled={!!isDisabled}
          />
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export { SelectEducation };

import React, { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { TenderProtocol } from 'types/models/TenderProtocol';
import { getMockTenderProtocol } from 'features/Form/looks/tender/TenderProtocolForm/helpers';
import { ProtocolMainFields } from 'features/Form/views';
import { showNotification } from 'features/Notifications';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { TenderProtocolGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [tenderProtocol, setTenderProtocol] = useState<TenderProtocol>(getMockTenderProtocol());

  const { methods: saveTenderProtocol } = BackendAPI.useBackendAPI('SaveTenderProtocol');

  const reports = useMemo<Report[]>(() => [Reports.TenderProtocol], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleSave = useCallback(() => {
    if (!tenderProtocol.tender || !tenderProtocol.protocolCommission) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    saveTenderProtocol.callAPI(tenderProtocol, {
      onSuccessfullCall: ({ data }) => {
        setTenderProtocol(prevState => ({ ...prevState, id: data.id || null }));
        showNotification({ message: 'Протокол успешно сохранен', theme: 'success' });
        setIsAddFormOpen(false);
        setTimeout(() => setIsEditFormOpen(true), 0);
      },
    });
  }, [saveTenderProtocol, tenderProtocol]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'TenderProtocol',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [tableState, tableStreams.deleteRow]);

  const handleCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
    setTenderProtocol(getMockTenderProtocol());
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
    setTenderProtocol(getMockTenderProtocol());
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setTenderProtocol(getMockTenderProtocol());
    setIsAddFormOpen(true);
  }, []);

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: {
          name: [
            Permits.TENDER_PROTOCOL_VIEW_PROTOCOL,
            Permits.TENDER_PROTOCOL_VIEW_PROTOCOL_REQUESTS,
            Permits.TENDER_PROTOCOL_VIEW_APPROVER_REQUESTS,
            Permits.TENDER_PROTOCOL_VIEW_TENDER,
            Permits.TENDER_PROTOCOL_VIEW_DOCUMENTS,
          ],
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.TENDER_PROTOCOL_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: [Permits.TENDER_PROTOCOL_EDIT_PROTOCOL, Permits.TENDER_PROTOCOL_EDIT_DOCUMENTS] },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: 'SUPERUSER' },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [handleHelpButtonClick, tableState.selectedRows.length, handleAddButtonClick, getReports, handleSetCurrentReport],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.TenderProtocolViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.TenderProtocolEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tenderProtocol.id || tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && (
        <Modal
          title="Создание проекта протокола"
          isOpen={isAddFormOpen}
          onClose={handleCloseAddForm}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Сохранить и продолжить',
              onClick: handleSave,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseAddForm,
            },
          ]}
          size="large"
        >
          <ProtocolMainFields
            workMode="addMode"
            tenderProtocol={tenderProtocol}
            changeTender={e => setTenderProtocol(prevState => ({ ...prevState, tender: e }))}
            changeNumber={e => setTenderProtocol(prevState => ({ ...prevState, number: e }))}
            changeProtocolDate={e => setTenderProtocol(prevState => ({ ...prevState, protocolDate: e }))}
            changeProtocolCommission={e => setTenderProtocol(prevState => ({ ...prevState, protocolCommission: e }))}
          />
        </Modal>
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>
            (форма: {tableState.selectedRows[0]?.RequestType}, конкурс: {tableState.selectedRows[0]?.TenderName}) Удалить
            протокол?
          </>
        </Modal>
      )}

      <Modal
        mode="help"
        title='Помощь в работе с разделом "Работа конкурсной комиссии и экспертиза заявок"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="large"
      >
        {TenderProtocolGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          tenderProtocolId: selectedRow?.id ?? '',
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, Select } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { PublicationType } from 'types/models/Publication/publicationTypes';
import { Item } from 'types/models/common';

const CompilationAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const defaultType = useMemo(
    () =>
      ({
        id: '1',
        label: 'Сборник научных трудов',
        text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
        code: 'COMPILATION_SCIENTIFIC_WORK',
      } as PublicationType),
    [],
  );

  const { isOpen, onClose, relatedTableState, id } = props;
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isCompilationAddTemplateTypePopupOpen, setIsCompilationAddTemplateTypePopupOpen] = useState<boolean>(true);
  const [publicationType, setPublicationType] = useState<PublicationType>(defaultType);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(ElectronicType.WEB);
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const NumPublicationTypes = useMemo(
    () =>
      [
        {
          id: '1',
          label: 'Сборник научных трудов',
          text: 'Сборник, содержащий исследовательские материалы научных учреждений, учебных заведений или обществ',
          code: 'COMPILATION_SCIENTIFIC_WORK',
        },
        {
          id: '2',
          label: 'Сборник материалов конференций, симпозиумов и др.',
          text: 'Непериодический сборник, содержащий итоги конференции в виде докладов, рекомендаций, решений',
          code: 'COMPILATION_CONFERENCE',
        },
        {
          id: '3',
          label: 'Справочное издание',
          text:
            // eslint-disable-next-line max-len
            'Издание, содержащее краткие сведения научного или прикладного характера, расположенные в порядке, удобном для их быстрого отыскания, не предназначенное для сплошного чтения',
          code: 'COMPILATION_OTHER',
        },
      ] as PublicationType[],
    [],
  );

  const electronicPublicationTypes = useMemo<F.ElectronicType[]>(() => [ElectronicType.WEB, ElectronicType.LOCAL], []);

  const onChangePublicationType = useCallback(
    (value: string) => {
      const selected = NumPublicationTypes.find(({ id: itemId }) => itemId === value);
      if (selected) {
        setPublicationType(selected);
      }
    },
    [NumPublicationTypes, setPublicationType],
  );

  const onChangeElectronicPublicationType = useCallback(
    (value: string) => {
      const selected = electronicPublicationTypes.find(({ id: itemId }) => itemId === value);
      if (selected) {
        setElectronicPublicationType(selected);
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  const continueWithSelectedType = () => {
    onConfirm();
  };

  const onConfirm = useCallback(() => {
    setIsCompilationAddTemplateTypePopupOpen(false);
  }, []);

  const look: F.CompilationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'CompilationForm',
    type: publicationType.code,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      setPublicationType(defaultType);
      setElectronicPublicationType(ElectronicType.WEB);
      setIsElectronic(false);
      setIsCompilationAddTemplateTypePopupOpen(true);
    }
  }, [defaultType, isOpen]);

  return (
    <>
      {isCompilationAddTemplateTypePopupOpen ? (
        <Modal
          title="Выберите тип публикации"
          isOpen={isOpen}
          onClose={onClose}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: continueWithSelectedType,
              isDisabled: isElectronic && !electronicPublicationType.code,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onClose,
            },
          ]}
          size="medium"
        >
          <FormComponent.Line>
            <FormComponent.Field label="Тип публикации" labelSize="fit">
              <Select
                value={{ value: publicationType?.id || '', label: publicationType?.label || '' }}
                options={NumPublicationTypes.map(x => ({ value: x.id, label: x.label }))}
                onChange={(option: Item) => onChangePublicationType(option.value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Text>Внимание! От типа публикации зависит состав полей на форме добавления.</FormComponent.Text>
          </FormComponent.Line>

          {!!publicationType?.code && <FormComponent.Description mode="info">{publicationType.text}</FormComponent.Description>}

          <FormComponent.Line>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
            />
          </FormComponent.Line>

          {isElectronic && (
            <>
              <FormComponent.Line>
                <Select
                  value={{ value: electronicPublicationType?.id || '', label: electronicPublicationType?.label || '' }}
                  options={electronicPublicationTypes.map(x => ({
                    value: x.id,
                    label: x.label,
                  }))}
                  onChange={(option: Item) => onChangeElectronicPublicationType(option.value)}
                />
              </FormComponent.Line>
              {electronicPublicationType.code && (
                <FormComponent.Description mode="info">{electronicPublicationType.text}</FormComponent.Description>
              )}
            </>
          )}
        </Modal>
      ) : (
        <Modal title={title} isOpen={isOpen} onClose={onClose} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};

export const Component = React.memo(CompilationAddTemplate);

export function EquipGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Модуль Оборудование предназначен для ведения базы имеющегося оборудования в университете, а так же для регистрации нового.
        Карточку оборудования можно редактировать и актуализировать при наличии прав.
      </p>
      <p>
        Перечень оборудования был добавлен с помощью внешнего источника, но с помощью кнопки “Добавить” можно добавить
        оборудование вручную.
      </p>

      <h4>Описание работы фильтров</h4>
      <p>Для сортировки записей можно воспользоваться фильтрами:</p>
      <p>Год закупки - фильтрация оборудования по введенному году закупки.</p>
      <p>
        Радио-кнопки: &lt;1г., 1-2г., 3-5г., 6-10г., 11-20г., &gt;20г. в сочетании с полем для ввода Даты для возраста - позволяет
        отфильтровать оборудование по возрасту на указанную дату. Если поле “Дата для возраста” не заполнено, то по умолчанию
        отображаются Все записи по оборудованию.
      </p>

      <h3>Форма для просмотра/создания/редактирования оборудования содержит:</h3>

      <h4>Вкладка Описание</h4>
      <p>
        Содержит основную информацию по оборудованию такую как наименование, марка, модель, производитель, год выпуска и тд. На
        вкладке так же отображаются классификаторы для оборудования его технические характеристики и состав комплекта.
        Предусмотрено добавление изображение оборудования (формат - *.png, *.jpg, *.jpeg, максимальные размеры - ширина: 500px,
        высота: 500px, размер - до 2Мб).
      </p>

      <h4>Вкладка Место установки</h4>
      <p>
        Содержит Историю перемещения оборудования - дата перемещения, адрес - куда было установлено оборудование, ответственное
        подразделение, ответственный факультет и ответственные лица. В таблице Контактные лица отображается период и контактные
        данные сотрудника, который проводил работы на оборудовании, так же можно внести иную необходимую информацию в поле
        Примечание.
      </p>
      <p>
        В таблице Контактные лица отображается период и контактные данные сотрудника, который проводил работы на оборудовании, так
        же можно внести иную необходимую информацию в поле Примечание.
      </p>

      <h4>Вкладка Стоимость</h4>
      <p>
        На вкладке отображается вся информация о закупке оборудования, включая источники финансирования, направления использования
        и балансовую стоимость оборудования. Данные могут приходить из бухгалтерии, но есть так же возможность внести информацию
        вручную.
      </p>

      <h4>Вкладка Метрологическое сопровождение (опционально)</h4>
      <p>
        Содержит метрологические характеристики и данные о поверке. Вкладка имеет ознакомительный характер и недоступна для
        редактирования. Данные на этой вкладке отображаются для просмотра; добавляются в пункте меню “Метрологическое
        сопровождение”.
      </p>

      <h4>Вкладка Документы</h4>
      <p>
        Стандартная вкладка для работы с документами. Здесь можно добавить/просмотреть/скачать прикреплённые файлы, например:
        инструкцию, регистрационное удостоверение или паспорт оборудования.
      </p>
    </>
  );
}

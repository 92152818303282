import React from 'react';

import { ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import * as BackendAPI from 'services/BackendAPI';

import { GetNirRequestBaseDissertationList } from 'features/Table/specifications';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function Dissertations(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  const { methods: loadDissertationsAPI } = BackendAPI.useBackendAPI('GetNirRequestBaseDissertation');

  const formatInfo = (dissertation: NirRequest.BaseDissertation) => {
    const { degree, year, councilCipher, vakSpeciality, defencePlace } = dissertation;
    const degreeStr = degree ? `${degree.label} (${year})` : '';
    const numberDisCouncil = `(Номер диссертационного совета: ${councilCipher});`;
    const vakStr = vakSpeciality ? `ВАК ${vakSpeciality.label};` : '';
    const defencePlaceStr = `место защиты ${defencePlace}`;
    return `${degreeStr} ${numberDisCouncil} ${vakStr} ${defencePlaceStr}`;
  };

  return (
    <ListEdit
      header={{ title: 'Список защищённых диссертаций' }}
      rows={nirRequest?.collectiveBaseDissertations ?? []}
      onChange={makeChangeHandler('collectiveBaseDissertations')}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО подготовленного специалиста', formatValue: x => x.author, styles: { width: '20%' } },
        { label: 'Тема диссертации', formatValue: x => x.name, styles: { width: '40%' } },
        { label: 'Сведения о защите', formatValue: formatInfo, styles: { width: '40%' } },
      ]}
      maxHeight="450px"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'loadInstanceTableModal',
        relationTableModalTitle: 'Диссертации',
        modalTableSpecification: GetNirRequestBaseDissertationList({
          scientistIDs: (nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean),
        }),
        onStartLoad: (tableRows, onSuccess) => {
          loadDissertationsAPI.callAPI(
            { dissertationsIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data) },
          );
        },
      }}
    />
  );
}

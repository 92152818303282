import React from 'react';

import { EntityLocker } from 'features/EntityLocker';

import { Mode } from 'features/Table/specifications/GetAnalyticProjectList/makeUseCustomController';
import { Form } from './Form';
import { ByPrototypeCopyOption, ProjectType } from 'utils/Enums';

import './style.scss';

type Props = {
  id: string | null;
  isOpen: boolean;
  onClose(): void;
  onSuccessfullSave?(): void;
  mode: Mode | null;
  projectType?: ProjectType;
  nirRequestId?: string;
  securityDocumentContractId?: string;
  programRequestId?: string;
  projectCopyOptions?: ByPrototypeCopyOption[];
  prototypeId?: string;
};

export function EditProject({
  id,
  isOpen,
  onSuccessfullSave,
  onClose,
  mode,
  nirRequestId,
  projectType,
  securityDocumentContractId,
  programRequestId,
  projectCopyOptions,
  prototypeId,
}: Props) {
  const form = (
    <Form
      id={id}
      isOpen={isOpen}
      onClose={onClose}
      disabled={mode === 'view' || !mode}
      mode={mode || 'add'}
      onSuccessfullSave={onSuccessfullSave}
      nirRequestId={nirRequestId}
      projectType={projectType}
      securityDocumentContractId={securityDocumentContractId}
      programRequestId={programRequestId}
      projectCopyOptions={projectCopyOptions}
      prototypeId={prototypeId}
    />
  );

  return (
    <>
      {isOpen && (
        <>
          {id && mode === 'edit' ? (
            <EntityLocker id={id} entity="ScienceProject" isOpen={isOpen} onClose={onClose}>
              {form}
            </EntityLocker>
          ) : (
            form
          )}
        </>
      )}
    </>
  );
}

import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { block } from 'bem-cn';

import { Button, ButtonMode, Checkbox, TextInput } from 'components';

import { Column } from 'types/models/Table';

import './styles.scss';

const b = block('popover-content');

type Props = {
  onClose(): void;
  columns: Column[];
  onHiddenColumnsChange: (nextHiddenColumnNames: string[]) => void;
};

export const PopoverContent = ({ columns: tableColumns, onClose, onHiddenColumnsChange }: Props) => {
  const [columns, setColumns] = useState<Column[]>([]);
  const [filterValue, setFilterValue] = useState<string>('');

  useEffect(() => {
    setColumns([...tableColumns]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filteredColumns = useMemo(() => {
    return !!filterValue
      ? columns.filter(({ caption }) => caption.toLowerCase().indexOf(filterValue.toLowerCase().trim()) > -1)
      : columns;
  }, [columns, filterValue]);

  const areAllBoxesChecked = useMemo(() => filteredColumns.every(({ hidden }) => !hidden), [filteredColumns]);

  const handlePickAllCheckboxChange = useCallback(() => {
    const filteredNames = filteredColumns.map(column => column.name);
    const nextHiddenColumnNames = columns.map(column => ({
      ...column,
      hidden: filteredNames.includes(column.name) ? areAllBoxesChecked : column.hidden,
    }));
    setColumns(nextHiddenColumnNames);
  }, [areAllBoxesChecked, columns, filteredColumns]);

  const createChangeIsHiddenCallback = useCallback(
    (name: string) => (nextIsChecked: boolean) => {
      setColumns(prevColumns =>
        prevColumns.map(column => (column.name === name ? { ...column, hidden: !nextIsChecked } : column)),
      );
    },
    [],
  );

  const onSubmit = useCallback(() => {
    const nextHiddenColumns = columns.filter(({ hidden }) => !!hidden).map(({ name }) => name);
    onHiddenColumnsChange(nextHiddenColumns);
    onClose();
  }, [columns, onClose, onHiddenColumnsChange]);

  return (
    <div className={b()}>
      <div className={b('pick-all-label')}>
        <Checkbox label="Выбрать все из списка" checked={areAllBoxesChecked} onChange={handlePickAllCheckboxChange} />
        <TextInput value={filterValue || ''} onChange={setFilterValue} placeholder="Найти колонку" isClearable />
      </div>
      <div className={b('list')}>
        <em>Список пуст</em>
        {filteredColumns.map(({ name, caption, hidden }) => (
          <div className={b('checkbox')} key={name}>
            <Checkbox key={name} label={caption} checked={!hidden} onChange={createChangeIsHiddenCallback(name)} />
          </div>
        ))}
      </div>
      <div className={b('button')}>
        <Button mode={ButtonMode.PRIMARY} text="Применить" onClick={onSubmit} />
      </div>
    </div>
  );
};

import { Form } from 'types/models';

export function validate(formFields: Form.Fields, isTSU: boolean) {
  const required = Object.keys(formFields)
    .filter(
      (fieldName: string) =>
        formFields[fieldName].required &&
        formFields[fieldName]?.value?.length <= 0 &&
        formFields[fieldName].title &&
        // (() => {
        //   if (isTSU) !['code'].includes(fieldName)
        //   return !['letter', 'codeDepartmentPart', 'codeManualPart', 'codeYearPart'].includes(fieldName);
        // })()
        ((!isTSU && !['letter', 'codeDepartmentPart', 'codeManualPart', 'codeYearPart'].includes(fieldName)) ||
          (isTSU && !['code'].includes(fieldName))),
    )
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }))
    .concat(
      isTSU && !(formFields.codeDepartmentPart?.value && formFields.codeManualPart?.value && formFields.codeYearPart?.value)
        ? { isValid: false, invalidMessage: `Заполните все поля шифра` }
        : [],
    );

  return [...required];
}

import React from 'react';

import { Modal } from 'components';
import { Department } from 'types/models/Participation';
import { Component as EditParticipationDepartmentsComponent } from './component';

type Props = {
  type: string;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  afterSubmit: (departments: Department[]) => void;
  title?: string;
  participantsTitle?: string;
  hideToolbarButtons?: boolean;
  hideActionButtons?: boolean;
};

const EditParticipationDepartmentsContainer = ({
  type,
  isOpen,
  onClose,
  id,
  afterSubmit,
  title = 'Редактирование списка подразделений, к которым относится участие в мероприятии',
  participantsTitle = 'Участники: ',
  hideToolbarButtons = false,
  hideActionButtons = true,
}: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
    <EditParticipationDepartmentsComponent
      type={type}
      onClose={onClose}
      id={id}
      afterSubmit={afterSubmit}
      participantsTitle={participantsTitle}
      hideToolbarButtons={hideToolbarButtons}
      hideActionButtons={hideActionButtons}
    />
  </Modal>
);

export const Component = React.memo(EditParticipationDepartmentsContainer);

import { Merge } from 'types/models/Merge';

export enum ActionType {
  CHECK = 'CHECK',
  MERGE = 'MERGE',
}

export type MergeScientistRequest = {
  action: ActionType;
  entityIds: string[];
};

export type SaveMergeRequest = {
  action: ActionType;
  merge: Merge;
};

/* eslint-disable jsx-a11y/alt-text */
import React, { useCallback, useMemo } from 'react';

import { ButtonMode, FormComponent, Modal, Tab, Tabs, TextArea } from 'components';

import { Document, Table } from 'types/models';
import { EmployeePayment } from 'types/models/Payment';
import { General } from './General';
import { Toolbar } from './Toolbar';
import { RequestHeader } from 'features/Form/views/ModalHeader';
import { Mode } from '../makeUseCustomController';
import { Documents } from 'features/Documents';
import { PaymentRequestStatus } from 'utils/Enums';
import { DataGrid } from 'features/Table/container';
import { GetEmployeePaymentRequestFeedlineList } from 'features/Table/specifications';

type Props = {
  isOpen: boolean;
  employeePayment: EmployeePayment | null;
  comment: string;
  onClose(): void;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  saveEmployeePayment(): void;
  setComment(comment: string): void;
  toggleVisibleCommentForm(): void;
  startSaveEmployeePayment(): void;
  isOpenCommentForm: boolean;
  mode: Mode | null;
  status: PaymentRequestStatus | null;
  selectedFund: Table.Entry | null;
  changeEmployeePaymentStatus(status: PaymentRequestStatus): void;
  openConfirmApproveStatusModal(): void;
  openConfirmRevisionStatusModal(): void;
  openConfirmDeleteModal(): void;
  openMessageLogModal(): void;
};

export function RequestForm(props: Props) {
  const {
    isOpen,
    employeePayment,
    setEmployeePayment,
    onClose,
    saveEmployeePayment,
    mode,
    status,
    comment,
    setComment,
    toggleVisibleCommentForm,
    isOpenCommentForm,
    startSaveEmployeePayment,
    changeEmployeePaymentStatus,
    openConfirmApproveStatusModal,
    openConfirmRevisionStatusModal,
    openConfirmDeleteModal,
    openMessageLogModal,
    selectedFund,
  } = props;

  const setDocuments = useCallback(
    (documents: Document[]) => {
      // TODO: mock emplyee payment
      setEmployeePayment({ ...employeePayment!, documents });
    },
    [employeePayment, setEmployeePayment],
  );

  const isViewMode = useMemo(() => mode === 'view', [mode]);

  const documents = <Documents disabled={isViewMode} documents={employeePayment?.documents ?? []} setDocuments={setDocuments} />;

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={RequestHeader({
        employeePayment,
        subText: mode === 'add' ? selectedFund?.faculty ?? '' : employeePayment?.fund?.department?.label || 'не указан',
      })}
      size={['extralarge', 'medium-height']}
    >
      <FormComponent.Template>
        <Toolbar
          status={status}
          mode={mode}
          onSave={startSaveEmployeePayment}
          changeEmployeePaymentStatus={changeEmployeePaymentStatus}
          openConfirmApproveStatusModal={openConfirmApproveStatusModal}
          openConfirmRevisionStatusModal={openConfirmRevisionStatusModal}
          openConfirmDeleteModal={openConfirmDeleteModal}
          openMessageLogModal={openMessageLogModal}
        />

        <FormComponent.Template>
          <Tabs>
            <Tab title="Расчёт стимулирующих выплат">
              <General disabled={isViewMode} employeePayment={employeePayment} setEmployeePayment={setEmployeePayment} />
            </Tab>
            <Tab title="Документы">{documents}</Tab>
            <Tab title="Журнал сообщений">
              <DataGrid
                specification={GetEmployeePaymentRequestFeedlineList({ status, employeePaymentId: employeePayment?.id || '-1' })}
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>

        <Modal
          title="Сохранение заявки"
          isOpen={!!isOpenCommentForm}
          onClose={() => toggleVisibleCommentForm()}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: () => {
                toggleVisibleCommentForm();
                saveEmployeePayment();
              },
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => toggleVisibleCommentForm(),
            },
          ]}
          size="large"
        >
          <FormComponent.Field label="Комментарий">
            <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
          </FormComponent.Field>
        </Modal>
      </FormComponent.Template>
    </Modal>
  );
}

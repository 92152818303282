import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Form } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { UserEditFormLook } from 'types/models/Form/look/userEdit';
import { User } from 'types/models/User';
import { showNotification } from 'features/Notifications';
import { Member } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { validate } from './validate';
import { useController as useChangeStatusController } from './changeStatusController';
import { findActualJob } from 'shared/findActualJob';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose?(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, login },
  } = useFormContext<UserEditFormLook>();
  const { changeUserStatus } = useChangeStatusController();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [user, setUser] = useState<User | null>(null);
  const [member, setMember] = useState<Member | null>(null);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);

  const [formFields, setFormFields] = useState<Form.Fields>({
    userFullName: {
      value: '',
      isValid: true,
      required: false,
      title: 'ФИО',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          userFullName: { ...prevState.userFullName, value },
        }));
      },
    },
    email: {
      value: '',
      isValid: true,
      required: false,
      title: 'E-Mail',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          email: { ...prevState.email, value },
        }));
      },
    },
    birthDate: {
      value: '',
      isValid: true,
      required: false,
      title: 'Дата рождения',
      onChange: (dateValue: string | null) => {
        const value = dateValue ?? '';
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          birthDate: { ...prevState.birthDate, value },
        }));
      },
    },
    person: {
      value: '',
      isValid: true,
      required: true,
      title: 'Персона',
      onChange: (memberValue: Member) => {
        const value = memberValue?.person?.id ? memberValue : null;
        setMember(value);
        const actualJob = findActualJob(value?.person || null);
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          person: { ...prevState.person, value },
          birthDate: { ...prevState.birthDate, value: value?.person?.scientist?.dateBirth },
          department: { ...prevState.department, value: actualJob?.refDepartment },
        }));
        loadPerson(value?.person?.id || '');
      },
    },
    department: {
      value: '',
      isValid: true,
      required: true,
      title: 'Подразделение',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          department: { ...prevState.department, value },
        }));
      },
    },
  });

  const { methods: loadPersonAPI } = BackendAPI.useBackendAPI('GetScientistData');

  const loadPerson = useCallback(
    (personId: string) => {
      if (personId) {
        loadPersonAPI.callAPI(
          { personId },
          {
            onSuccessfullCall: ({ data }) => {
              setMember(getMemberWithoutHistory(data));
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [setMember],
  );

  const { methods: getUser } = BackendAPI.useBackendAPI('GetUser');
  const { methods: saveUser } = BackendAPI.useBackendAPI('SaveUser');

  const loadUser = useCallback(
    (userLogin: string) => {
      getUser.callAPI(
        { login: userLogin },
        {
          onSuccessfullCall: ({ data }) => {
            setFormFields(prevState => ({
              ...prevState,
              email: {
                ...prevState.email,
                value: data.email,
              },
              userFullName: {
                ...prevState.userFullName,
                value: data.userFullName,
              },
              birthDate: {
                ...prevState.birthDate,
                value: data.birthDate,
              },
              department: {
                ...prevState.department,
                value: data.department,
              },
              person: {
                ...prevState.person,
                value: data.person,
              },
            }));

            setUser(data);

            if (data.person?.id) {
              loadPerson(data.person?.id);
            }
          },
        },
      );
    },
    [getUser, loadPerson],
  );

  useLayoutEffect(() => {
    if (login) {
      loadUser(login);
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      saveUser.callAPI(
        {
          id,
          department: formFields.department.value,
          email: formFields.email.value,
          userFullName: formFields.userFullName.value,
          person: formFields.person.value?.person || formFields.person.value,
          birthDate: formFields.birthDate.value,
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Пользователь сохранен', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              if (onClose) {
                onClose();
              }
            }
          },
        },
      );
    },
    [id, formFields, onClose, saveUser, tableStreams.reloadTable],
  );

  const handleChangeStatus = useCallback(
    (active: boolean) => {
      changeUserStatus(user, active, () => {
        showNotification({ message: active ? 'Пользователь активирован' : 'Пользователь деактивирован', theme: 'success' });
        tableStreams.reloadTable.push({});
        if (onClose) {
          onClose();
        }
      });
    },
    [changeUserStatus, user, tableStreams.reloadTable, onClose],
  );

  const externalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'info' },
        title: 'Просмотр инфо',
        isDisabled: !member?.person?.id,
        onClick: () => {
          setIsOpenPersonModal(true);
        },
      },
    ],
    [member?.person?.id],
  );

  return {
    userId: id ?? null,
    user,
    member,
    handleFormSubmit,
    formFields,
    workMode,
    updateWorkModeAfterSaveAndContinue,
    tableStreams,
    handleChangeStatus,
    isOpenPersonModal,
    setIsOpenPersonModal,
    externalButtons,
  };
}

import React, { memo } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  relatedTableId?: string | null;
  eventId?: string;
  isDisableEventSource?: boolean;
};

const MagazineReleaseAddTemplate = ({ isOpen, onClose, relatedTableId = null, eventId, isDisableEventSource }: Props) => {
  const look: F.Look = {
    apiID: 'GetMagazineRelease',
    template: 'MagazineReleaseForm',
    type: 'Magazine',
    relatedTableId,
    eventId,
    isDisableEventSource,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление выпуска" size="medium">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = memo(MagazineReleaseAddTemplate);

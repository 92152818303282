import { useState, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { Table, Community, Document, CommunityMember } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import type { CommunityFormLook } from 'types/models/Form';
import { Item } from 'types/models/common';
import { CommunityMemberCooperationType, CommunityType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getMockCommunity, getRoleOptionsByCommunityType, getCooperationTypeOptionsByCommunityType } from './helpers';

import { validate } from './validate';
import { getEnum } from 'utils/Helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const { settings, enumMap } = useAppDataContext();

  const tableStreams = useLocalTableStreams();

  const {
    look: { id, communityTypes, community: initialCommunity },
  } = useFormContext<CommunityFormLook>();

  const isNotDissertationCouncil = communityTypes.includes(CommunityType.SCIENTIFIC_COUNCIL);

  const [community, setCommunity] = useState<Community>(getMockCommunity(isNotDissertationCouncil));

  const allRoleOptions = useMemo(() => {
    return getEnum('CommunityMemberRole', enumMap);
  }, [enumMap]);

  const allCooperationTypeOptions = useMemo(() => {
    return getEnum('CommunityMemberCooperationType', enumMap);
  }, [enumMap]);

  const roleOptions = useMemo<Item[]>(() => {
    return community?.type?.value ? getRoleOptionsByCommunityType(allRoleOptions, community?.type.value as CommunityType) : [];
  }, [allRoleOptions, community?.type?.value]);

  const cooperationTypeOptions = useMemo<Item[]>(() => {
    return community?.type?.value
      ? getCooperationTypeOptionsByCommunityType(allCooperationTypeOptions, community?.type.value as CommunityType)
      : [];
  }, [allCooperationTypeOptions, community?.type?.value]);

  const { methods: getCommunity } = BackendAPI.useBackendAPI('GetCommunity', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Community;
      setCommunity(preparedData);
    },
  });

  const { methods: saveCommunity } = BackendAPI.useBackendAPI('SaveCommunity', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success)
        showNotification({ message: 'Научное сообщество успешно сохранено', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const handleDocumentsFieldChange = useCallback(
    (e: Document[]) => {
      if (community) {
        setCommunity({ ...community, documents: e });
      }
    },
    [community],
  );

  const handleMembersFieldChange = useCallback(
    (e: CommunityMember[]) => {
      if (community) {
        setCommunity({ ...community, members: e });
      }
    },
    [community],
  );

  const handleCommunityTypeFieldChange = useCallback(
    (e: Table.EnumValue) => {
      if (community) {
        setCommunity({
          ...community,
          type: e,
        });
      }
    },
    [community],
  );

  const onSubmit = useCallback((): boolean => {
    if (community && validate(community)) {
      saveCommunity.callAPI(community);
      return true;
    }
    return false;
  }, [community, saveCommunity]);

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  const handleFormClose = useCallback(() => {
    onClose();
  }, [onClose]);

  useEffect(() => {
    if (community) {
      setCommunity({
        ...community,
        members: community.members.map(x => ({
          ...x,
          ...(roleOptions.length && !roleOptions.find(y => y.value === x?.role?.value) ? { role: roleOptions[0] } : {}),
          ...(cooperationTypeOptions.length && !cooperationTypeOptions.find(y => y.value === x?.cooperationType?.value)
            ? {
                cooperationType:
                  community.type?.value === CommunityType.MAGAZINE_REDACTION
                    ? cooperationTypeOptions.find(y => y.value === CommunityMemberCooperationType.EDITORIAL_BOARD_MEMBER)
                    : cooperationTypeOptions[0],
              }
            : {}),
        })),
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [community?.type?.value, cooperationTypeOptions, roleOptions]);

  useLayoutEffect(() => {
    if (initialCommunity) setCommunity(initialCommunity);
    else if (id) getCommunity.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    community,
    setCommunity,
    communityId: id,
    communityTypes,
    handleCommunityTypeFieldChange,
    handleDocumentsFieldChange,
    handleFormClose,
    handleFormSubmit,
    handleMembersFieldChange,
    workMode,
    roleOptions,
    cooperationTypeOptions,
    isNotDissertationCouncil,
    settings,
  };
}

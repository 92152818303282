import React, { memo } from 'react';

import { FormComponent } from 'components';

import { Event, Table } from 'types/models';
import { DataGrid } from 'features/Table';
import { GetConferenceEventParticipationList } from 'features/Table/specifications';
import ParticipationsStatistic from 'features/Form/looks/event/views/ParticipationsStatistic';
import { EventTypes } from 'utils/Enums/EventTypes';

type Props = {
  sourceEvent?: Event.Event | null;
  workMode: Table.WorkMode;
  reloadEvent?(): void;
  isEventChanged?: boolean;
  participations: Event.EventParticipant[];
  unconnectedParticipationPublications?: Event.Source[];
};

const Participations = ({
  sourceEvent,
  workMode,
  reloadEvent,
  isEventChanged,
  participations,
  unconnectedParticipationPublications,
}: Props) => {
  return (
    <FormComponent.Template>
      <FormComponent.Description mode="warning">
        Прежде чем редактировать участников, сохраните мероприятие, с помощью кнопки "Сохранить и продолжить". Несохраненные
        данные могут быть утеряны
      </FormComponent.Description>

      <FormComponent.Line>
        <ParticipationsStatistic
          eventTypeCode={EventTypes.EVENT_CONFERENCE.code}
          participations={participations}
          showReports={true}
        />
      </FormComponent.Line>

      <DataGrid
        specification={GetConferenceEventParticipationList(
          {
            sourceEvent: sourceEvent || null,
            reloadEvent,
            isEventChanged,
            unconnectedParticipationPublications,
          },
          workMode,
        )}
      />
    </FormComponent.Template>
  );
};

export const Component = memo(Participations);

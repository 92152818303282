import React from 'react';

import { ButtonProps, FormComponent, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { Item } from 'types/models/common';
import { GetProgramRequestListForProject } from 'features/Table/specifications';

import useController from './controller';

type Props = {
  programRequest: Item<string> | null;
  setProgramRequest: (contestRequest: Item<string> | null) => void;
  label: string;
  modalTitle: string;
  externalButtons?: ButtonProps[];
  isDisabled?: boolean;
  isRequired?: boolean;
};

export const SelectProgramRequest = ({
  programRequest,
  setProgramRequest,
  label,
  modalTitle,
  isDisabled,
  isRequired,
  externalButtons,
}: Props) => {
  const { onSelectRow } = useController();

  return (
    <FormComponent.Field label={label} isRequired={isRequired}>
      <TextArea
        mode={TextAreaMode.TABLE}
        settings={{
          title: modalTitle,
          externalButtons,
          table: {
            specification: GetProgramRequestListForProject,
            onSelect: (row: Table.Entry | null) => (row ? onSelectRow(row, setProgramRequest) : setProgramRequest(null)),
          },
        }}
        value={programRequest?.value ? `${programRequest?.label} \n(ID заявки: ${programRequest?.value})` : ''}
        isDisabled={isDisabled}
      />
    </FormComponent.Field>
  );
};

import React, { useCallback } from 'react';

import { FormComponent, SectionTitle, Select, SelectMode, TextArea, TextAreaMode, TextInput } from 'components';

import { Table as T, Form as F, Author, Award, Event } from 'types/models';
import { AwardsComponent } from 'features/Form/views';
import { GetShortEventList } from 'features/Table/specifications';
import { EventFormat } from 'utils/Enums';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { getEventFullName, getMockEvent } from 'utils/Helpers/event';

type Props = {
  formFields: F.Fields;
  workMode: T.WorkMode;
  event: Event.Event | null;
  setEvent: (event: Event.Event | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  disabledEvent?: boolean;
  changeMembers: (val: Author[]) => void;
  awards: Award[];
  setAwards: (awards: Award[]) => void;
};

function About({
  formFields,
  workMode,
  event,
  setEvent,
  relatedTableState,
  members,
  disabledEvent,
  changeMembers,
  awards,
  setAwards,
}: Props) {
  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => Event.Event>(
    row => ({
      ...getMockEvent(),
      id: row.id,
      name: row.name,
      status: { value: row['id:status'], label: row.status },
      fullName: row.fullName,
      startDate: row.startDate,
      endDate: row.endDate,
      format: EventFormat.OFFLINE,
    }),
    [],
  );

  const shortEventTableConfig = GetShortEventList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
    },
    requestData: {
      eventType: 'CONTEST',
      allEvents: true,
    },
    hasSelectButton: true,
    hasFilter: true,
    buttonsToHide: ['approve'],
  });
  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <FormComponent.Field label="Название конкурса" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Конкурсы',
                visibleStatus: getStatusRecordMessage('', event?.id, event?.status?.label),
                isClearable: false,
                table: {
                  specification: shortEventTableConfig,
                  onSelect: (row: T.Entry | null) => setEvent(row ? modalTableRowToEventConventer(row) : null),
                },
              }}
              value={getEventFullName(event)}
              isDisabled={workMode === 'viewMode' || disabledEvent}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Уровень мероприятия">
            <FormComponent.Text>{event?.eventStatus?.label}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Формат проведения">
            <FormComponent.Text>{event?.format?.label}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Формат участия" isRequired={formFields.form.required}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.form.value}
              onChange={formFields.form.onChange}
              settings={{
                name: 'RefParticipationForm',
                title: 'Справочник "Форматы участия"',
                filters: [{ key: 'type', values: ['CONTEST'] }],
                isClearable: !formFields.form.required,
              }}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.teamName.title}>
            <TextInput
              value={formFields.teamName.value}
              onChange={formFields.teamName.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <MembersList
            eventType="CONTEST"
            disabled={workMode === 'viewMode'}
            authors={members}
            setAuthors={changeMembers}
            awards={awards}
          />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <SectionTitle title="Название конкурсного проекта (работы)" />

        <FormComponent.Line>
          <FormComponent.Field>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.note.value}
              onChange={formFields.note.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <AwardsComponent
            disabled={workMode === 'viewMode'}
            recipients={members}
            awards={awards}
            setAwards={setAwards}
            participationTypeCode={ParticipationTypes.PARTICIPATION_CONTEST.code}
            tooltip="После проведения мероприятия добавить награды, полученные участником в конкурсе."
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

export const Component = React.memo(About);

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';

import {
  convertContestRequestKindConsumption,
  convertKindConsumption,
  convertServerMobileKindConsumption,
  convertNirRequestKindConsumption,
  convertTenderKindConsumption,
} from 'services/BackendAPI/configurations/kindConsumption/converters';
import {
  convertContestRequestKindConsumptionRequest,
  convertKindConsumptionRequest,
  convertMobileKindConsumptionRequest,
  convertNirRequestKindConsumptionRequest,
  convertTenderKindConsumptionRequest,
} from './requestConverters';
import {
  ContestRequestKindConsumption,
  KindConsumption,
  MobileKindConsumption,
  NirRequestKindConsumption,
  RequestKindConsumption,
  TenderKindConsumption,
} from 'types/models/KindConsumption';

export const kindConsumptionConfigurations = {
  GetKindConsumption: makeBackendAPIConfiguration({
    id: 'GetKindConsumption',
    endpoint: '/msa/service/commands/GetKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetKindConsumption' },
        KindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertKindConsumption(response.Response.KindConsumption),
  }),
  SaveKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveKindConsumption',
    endpoint: '/msa/service/commands/SaveKindConsumption',
    convertInputToXMLElement: (inputData: KindConsumption) => ({
      Request: {
        _attr: { commandName: 'SaveKindConsumption' },
        KindConsumption: {
          ...convertKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const mobileKindConsumptionConfigurations = {
  GetMobileKindConsumption: makeBackendAPIConfiguration({
    id: 'GetMobileKindConsumption',
    endpoint: '/msa/service/commands/GetMobileKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetMobileKindConsumption' },
        MobileKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerMobileKindConsumption(response.Response.MobileKindConsumption),
  }),
  SaveMobileKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveMobileKindConsumption',
    endpoint: '/msa/service/commands/SaveMobileKindConsumption',
    convertInputToXMLElement: (inputData: MobileKindConsumption) => ({
      Request: {
        _attr: { commandName: 'SaveMobileKindConsumption' },
        MobileKindConsumption: {
          ...convertMobileKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const requestKindConsumptionConfigurations = {
  GetRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetRequestKindConsumption',
    endpoint: '/msa/service/commands/GetRequestKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetRequestKindConsumption' },
        RequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertNirRequestKindConsumption(response.Response.RequestKindConsumption),
  }),
  SaveRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveNirRequestKindConsumption',
    convertInputToXMLElement: (inputData: RequestKindConsumption) => ({
      Request: {
        _attr: { commandName: 'SaveRequestKindConsumption' },
        RequestKindConsumption: {
          ...convertNirRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const nirRequestKindConsumptionConfigurations = {
  GetNirRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetNirRequestKindConsumption',
    endpoint: '/msa/service/commands/GetNirRequestKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestKindConsumption' },
        NirRequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertNirRequestKindConsumption(response.Response.NirRequestKindConsumption),
  }),
  SaveNirRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveNirRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveNirRequestKindConsumption',
    convertInputToXMLElement: (inputData: NirRequestKindConsumption) => ({
      Request: {
        _attr: { commandName: 'SaveNirRequestKindConsumption' },
        NirRequestKindConsumption: {
          ...convertNirRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const contestRequestKindConsumptionConfigurations = {
  GetContestRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'GetContestRequestKindConsumption',
    endpoint: '/msa/service/commands/GetContestRequestKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetContestRequestKindConsumption' },
        ContestRequestKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertContestRequestKindConsumption(response.Response.ContestRequestKindConsumption),
  }),
  SaveContestRequestKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveContestRequestKindConsumption',
    endpoint: '/msa/service/commands/SaveContestRequestKindConsumption',
    convertInputToXMLElement: (inputData: ContestRequestKindConsumption) => ({
      Request: {
        _attr: { commandName: 'SaveContestRequestKindConsumption' },
        ContestRequestKindConsumption: {
          ...convertContestRequestKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const tenderKindConsumptionConfigurations = {
  GetTenderKindConsumption: makeBackendAPIConfiguration({
    id: 'GetTenderKindConsumption',
    endpoint: '/msa/service/commands/GetTenderKindConsumption',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetTenderKindConsumption' },
        TenderKindConsumption: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertTenderKindConsumption(response.Response.TenderKindConsumption),
  }),
  SaveTenderKindConsumption: makeBackendAPIConfiguration({
    id: 'SaveTenderKindConsumption',
    endpoint: '/msa/service/commands/SaveTenderKindConsumption',
    convertInputToXMLElement: (inputData: TenderKindConsumption & { tenderId?: string }) => ({
      Request: {
        _attr: { commandName: 'SaveTenderKindConsumption' },
        TenderKindConsumption: {
          ...convertTenderKindConsumptionRequest(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const consumptionConfigurations = {
  ...kindConsumptionConfigurations,
  ...mobileKindConsumptionConfigurations,
  ...nirRequestKindConsumptionConfigurations,
  ...contestRequestKindConsumptionConfigurations,
  ...requestKindConsumptionConfigurations,
  ...tenderKindConsumptionConfigurations,
};

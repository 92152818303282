import React from 'react';
import { parse } from 'date-fns';

import { Modal, Toolbar } from 'components';
import { DataGrid } from 'features/Table/container';
import { GetSalaryByExpenseDocumentList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { Months } from 'utils/Constants';
import { formatStr } from 'utils/Constants/FormatStr';

import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, isSalaryOpen, setIsSalaryOpen, selectedRow } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={`Начисления заработной платы${
          selectedRow?.Date ? ` за ${Months[parse(selectedRow?.Date, formatStr, new Date()).getMonth() + 1].toLowerCase()}` : ''
        }`}
        isOpen={isSalaryOpen}
        onClose={() => setIsSalaryOpen(false)}
        size={['extralarge', 'large-height']}
      >
        <DataGrid specification={GetSalaryByExpenseDocumentList({ expenseDocumentId: selectedRow?.id })} />
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

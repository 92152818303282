import { MipIndicator } from 'types/models/MipIndicator';

export function getMockMipIndicator(): MipIndicator {
  return {
    id: null,
    year: '',
    averageHeadcount: '',
    profit: '',
    revenue: '',
    dividendTaxPercent: '0.00',
    dividendAmount: '0.00',
  };
}

import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ForeignProject } from 'utils/Enums/ForeignProject';
import { Modal } from 'components';

const ForeignProjectAddTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look = {
    apiID: 'GetForeignProject',
    template: ForeignProject.FOREIGN_PROJECT.template,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ForeignProjectAddTemplate);

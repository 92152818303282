import React, { useCallback, useRef, useState } from 'react';

import { ListEdit, ReferenceItem } from 'components';

import { Author } from 'types/models';
import { isWithOriginalIdField } from 'types/models/Person';
import { PersonHistoryModal } from 'features/SelectPerson';
import { useAppDataContext } from 'features/AppData/context';
import { AuthorshipFields, ChangeProps } from './AuthorshipFields';
import { AmountAffilationModal } from './AmountAffilationModal';
import { useController } from './controller';
import { formatAffilation, formatAuthor, getMinAffilationsCount, getMockAuthor, isCollectiveAuthor, validate } from './helpers';
import { showNotification } from 'features/Notifications';

type Props = {
  authors: Author[];
  setAuthors(authors: Author[]): void;
  language: ReferenceItem;
  isHistoryPersonDisabled: boolean;
  disabled?: boolean;
  filters?: string[];
};

function AboutAuthorship(props: Props) {
  const { authors, setAuthors, language, disabled = false, isHistoryPersonDisabled, filters } = props;
  const { isOpenHistoryPersonModal, selectedAuthorIndex, setSelectedAuthorIndex, setIsOpenHistoryPersonModal } = useController();
  const { settings } = useAppDataContext();
  const [isOpenAffilationModal, setIsOpenAffilationModal] = useState(false);
  const [preSubmitAuthor, setPreSubmitAuthor] = useState<Author | null>(null);
  const submitFunc = useRef<((author: Author) => void) | null>(null);

  const handleCloseAffilationModal = useCallback(() => {
    setIsOpenAffilationModal(false);
  }, [setIsOpenAffilationModal]);

  const handleCloseHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(false);
    setSelectedAuthorIndex(null);
  }, [setIsOpenHistoryPersonModal, setSelectedAuthorIndex]);

  const handleOpenHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(true);
  }, [setIsOpenHistoryPersonModal]);

  const onClickHistoryIcon = useCallback(
    (selectedAuthor: Author | null, selectedRowIndex: number | null) => {
      if (selectedAuthor !== null && selectedRowIndex !== null) {
        if (isCollectiveAuthor(selectedAuthor.category)) {
          showNotification({ message: 'Для групповых участников история не указывается', theme: 'danger' });
          return;
        }
        setSelectedAuthorIndex(selectedRowIndex);
        handleOpenHistoryModal();
      }
    },
    [setSelectedAuthorIndex, handleOpenHistoryModal],
  );

  const handleChangeField = (rowSetter: React.Dispatch<React.SetStateAction<Author | null>>, changeProps: ChangeProps[]) => {
    rowSetter(prevAuthor => {
      const updatedAuthor = changeProps.reduce<Author | null>((acc, { key, value }) => ({ ...acc, [key]: value } as any), {
        ...getMockAuthor(),
        ...prevAuthor,
      });
      return updatedAuthor;
    });
  };

  const submitHistoryPersonModal = useCallback(
    (author: Partial<Author>) => {
      if (selectedAuthorIndex !== null) {
        const updatedAuthor = Object.fromEntries(
          Object.entries(author).map(([key, field]: [string, any]) => {
            const prevField = authors[selectedAuthorIndex][key as keyof Author];

            if (field && isWithOriginalIdField(field) && prevField && isWithOriginalIdField(prevField)) {
              return [key, { ...field, id: prevField.originalId === field.originalId ? prevField.id : null }];
            }
            return [key, field];
          }),
        );

        const updatedAuthors = [...authors];
        updatedAuthors[selectedAuthorIndex] = { ...authors[selectedAuthorIndex], ...updatedAuthor } as Author;

        setAuthors(updatedAuthors);
      }
    },
    [selectedAuthorIndex, setAuthors, authors],
  );

  const submitAffilationModal = useCallback(() => {
    if (submitFunc.current && preSubmitAuthor) {
      submitFunc.current({ ...preSubmitAuthor, affilateCount: getMinAffilationsCount(preSubmitAuthor) });
      submitFunc.current = null;
    }
    handleCloseAffilationModal();
    setPreSubmitAuthor(null);
  }, [handleCloseAffilationModal, preSubmitAuthor]);

  return (
    <>
      <ListEdit
        rows={authors}
        onChange={setAuthors}
        toolbar={[
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'clock' },
            title: 'Персональные данные',
            onClick: onClickHistoryIcon,
            isDisabled: row => !row || isHistoryPersonDisabled,
          },
          'move',
        ]}
        columns={[
          { label: 'Наименование', formatValue: a => formatAuthor(a, settings), styles: { width: '80%' } },
          { label: 'Аффилиация', formatValue: a => formatAffilation(a, settings), styles: { width: '20%' } },
        ]}
        maxHeight="none"
        defaultRowsCount={authors.length < 3 ? 10 : authors.length + 1}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        withMessages
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Сведения об авторстве',
          onPreSubmit: (author, submit) => {
            const { affilateCount } = author;
            const minAffilationsCount = getMinAffilationsCount(author);
            const count = Number(affilateCount) ?? 0;
            if (count < minAffilationsCount) {
              submitFunc.current = submit;
              setIsOpenAffilationModal(true);
              setPreSubmitAuthor({ ...author, affilateCount: count });
            } else {
              submitFunc.current = null;
              setPreSubmitAuthor(null);
              submit({ ...author, affilateCount: count });
            }
          },
          validation: {
            checkIsValid: author => validate(author).every(x => x.isValid),
            onInvalidate: author => {
              const info = validate(author);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (author, setAuthor) => (
            <AuthorshipFields
              author={author}
              language={language}
              onChange={val => handleChangeField(setAuthor, val)}
              filters={filters}
            />
          ),
        }}
      />

      <PersonHistoryModal
        isOpen={isOpenHistoryPersonModal}
        member={selectedAuthorIndex !== null ? authors[selectedAuthorIndex] : null}
        onClose={handleCloseHistoryModal}
        onSubmit={submitHistoryPersonModal}
        isLoadPersonAfterOpen
        mode="edit"
      />

      <AmountAffilationModal
        isOpen={isOpenAffilationModal}
        onClose={handleCloseAffilationModal}
        author={preSubmitAuthor}
        onSubmit={submitAffilationModal}
      />
    </>
  );
}

export { AboutAuthorship };

export function GridSettingsSpecificationHelp(): JSX.Element {
  return (
    <>
      <p>Если вы - автор шаблона, в записи шаблона вам доступны для редактирования:</p>

      <ul>
        <li>переименование шаблона;</li>
        <li>добавление или редактирование описания;</li>
        <li>сделать шаблон доступным всем или доступным только вам отметкой в чек боксе "Доступно всем";</li>
        <li>удаление шаблона.</li>
      </ul>
    </>
  );
}

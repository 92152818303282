import React from 'react';

import { Icon } from 'components';

import { Color } from 'constants/colors';

import './style.scss';

export type Props = {
  /** @property {string} text - (optional) Tooltip text on hovered */
  text: string;
};

/**
 * Tooltip as (i) with string text
 *
 * @param text - Text for for bubble
 * @returns JSX
 */
export const Tooltip = React.memo(({ text }: Props) => {
  return <Icon type="info" color={Color.success} size={16} hint={text} classMixin="tooltip" />;
});

import * as XML from 'xml-js';
import * as T from './types';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';

import { Settings } from 'types/models/common';
import {
  convertMaybeArray,
  convertSaveResponseXMLElementToOutput,
  getMaybeField,
  makeParametersXMLElement,
  makeTableRequestXMLConverter,
} from '../commonConverters';

import {
  convertNirRequest,
  convertCollectiveProject,
  convertParticipation,
  convertBaseDissertation,
  convertSecurityDocument,
  convertForeignCollectiveProject,
} from './converters';
import { convertNirRequestToXML } from './requestConverters';
import { convertServerPublication } from '../publication/converters';
import { convertForeignSecurityDocument } from '../foreignSecurityDocument/converters';

const getScientistIds = (ids: string[]) => (ids.length ? ids.join(',') : '-1');

export const tableNirRequestConfigurations = {
  GetNirRequestList: makeBackendTableAPIConfiguration(
    'GetNirRequestList',
    makeTableRequestXMLConverter('GetNirRequestList', ({ panelState, requestData }: T.GetNirRequestsList) =>
      makeParametersXMLElement(
        []
          .concat(panelState.filter === 'actualTender' ? ([{ name: 'actualTender', value: true }] as any) : [])
          .concat(!!panelState.year ? ([{ name: 'year', value: panelState.year }] as any) : [])
          .concat(requestData?.isForProject ? ([{ name: 'forProject', value: requestData.isForProject }] as any) : [])
          .concat(requestData?.status ? ([{ name: 'status', value: requestData.status }] as any) : []),
      ),
    ),
  ),
  GetNirRequestPublicationList: makeBackendTableAPIConfiguration(
    'GetNirRequestPublicationList',
    makeTableRequestXMLConverter('GetNirRequestPublicationList', ({ requestData }: T.GetNirRequestPublicationList) =>
      makeParametersXMLElement([{ name: 'scientistIds', value: getScientistIds(requestData?.scientistIDs ?? []) }]),
    ),
  ),
  GetNirRequestProjectList: makeBackendTableAPIConfiguration(
    'GetNirRequestProjectList',
    makeTableRequestXMLConverter('GetNirRequestProjectList', () =>
      makeParametersXMLElement([{ name: 'year', value: String(new Date().getFullYear()) }]),
    ),
  ),
  GetNirRequestConferenceParticipationList: makeBackendTableAPIConfiguration(
    'GetNirRequestConferenceParticipationList',
    makeTableRequestXMLConverter('GetNirRequestConferenceParticipationList', ({ requestData }: T.GetNirRequestPublicationList) =>
      makeParametersXMLElement([{ name: 'scientistIds', value: getScientistIds(requestData?.scientistIDs ?? []) }]),
    ),
  ),
  GetNirRequestExpositionParticipationList: makeBackendTableAPIConfiguration(
    'GetNirRequestExpositionParticipationList',
    makeTableRequestXMLConverter('GetNirRequestExpositionParticipationList', ({ requestData }: T.GetNirRequestPublicationList) =>
      makeParametersXMLElement([{ name: 'scientistIds', value: getScientistIds(requestData?.scientistIDs ?? []) }]),
    ),
  ),
  GetNirRequestBaseDissertationList: makeBackendTableAPIConfiguration(
    'GetNirRequestBaseDissertationList',
    makeTableRequestXMLConverter('GetNirRequestBaseDissertationList', ({ requestData }: T.GetNirRequestPublicationList) =>
      makeParametersXMLElement([{ name: 'scientistIds', value: getScientistIds(requestData?.scientistIDs ?? []) }]),
    ),
  ),
  GetNirRequestSecurityDocumentList: makeBackendTableAPIConfiguration(
    'GetNirRequestSecurityDocumentList',
    makeTableRequestXMLConverter('GetNirRequestSecurityDocumentList', ({ requestData }: T.GetNirRequestPublicationList) =>
      makeParametersXMLElement([{ name: 'scientistIds', value: getScientistIds(requestData?.scientistIDs ?? []) }]),
    ),
  ),

  GetNirRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetNirRequestFeedlineList',
    makeTableRequestXMLConverter('GetNirRequestFeedlineList', ({ requestData }: T.GetNirRequestById) =>
      makeParametersXMLElement([{ name: 'nirRequestId', value: requestData?.nirRequestId }]),
    ),
  ),
  GetNirRequestChangeList: makeBackendTableAPIConfiguration(
    'GetNirRequestChangeList',
    makeTableRequestXMLConverter('GetNirRequestChangeList', ({ requestData }: T.GetNirRequestById) =>
      makeParametersXMLElement([{ name: 'nirRequestId', value: requestData?.nirRequestId }]),
    ),
  ),
};

const configurations = {
  GetNirRequest: makeBackendAPIConfiguration({
    id: 'GetNirRequest',
    endpoint: '/msa/service/commands/GetNirRequest',
    convertInputToXMLElement: ({ id, specification, tenderId, lotId }: T.GetNirRequest) => ({
      Request: {
        _attr: { commandName: 'GetNirRequest' },
        NirRequest: {
          _attr: id ? { id } : {},
          ...(specification ? { Specification: specification } : {}),
          ...getMaybeField('Tender', tenderId && !id && { _attr: { id: tenderId } }),
          ...getMaybeField('Lot', lotId && !id && { _attr: { id: lotId } }),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertNirRequest(response.Response.NirRequest, settings),
  }),
  SaveNirRequest: makeBackendAPIConfiguration({
    id: 'SaveNirRequest',
    endpoint: '/msa/service/commands/SaveNirRequest',
    convertInputToXMLElement: ({ nirRequest }: T.SaveNirRequest) => ({
      Request: {
        _attr: { commandName: 'SaveNirRequest' },
        NirRequest: convertNirRequestToXML(nirRequest),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSaveResponseXMLElementToOutput(response),
  }),
  GetNirRequestPublication: makeBackendAPIConfiguration({
    id: 'GetNirRequestPublication',
    endpoint: '/msa/service/commands/GetNirRequestPublication',
    convertInputToXMLElement: ({ publicationIds }: T.GetNirRequestPublication) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestPublication' },
        Publication: publicationIds.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertMaybeArray(response.Response.Publication, p => convertServerPublication(p, settings)),
  }),
  GetNirRequestProject: makeBackendAPIConfiguration({
    id: 'GetNirRequestProject',
    endpoint: '/msa/service/commands/GetNirRequestProject',
    convertInputToXMLElement: ({ nirRequestId, projectIds }: T.GetNirRequestProjects) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestProject' },
        Project: projectIds.map(id => ({ _attr: { id } })),
        ...(!!nirRequestId ? { NirRequest: { _attr: { id: nirRequestId } } } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.Project, convertCollectiveProject),
  }),
  GetNirRequestForeignProject: makeBackendAPIConfiguration({
    id: 'GetNirRequestForeignProject',
    endpoint: '/msa/service/commands/GetNirRequestForeignProject',
    convertInputToXMLElement: ({ nirRequestId, projectIds }: T.GetNirRequestProjects) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestForeignProject' },
        ForeignProject: projectIds.map(id => ({ _attr: { id } })),
        ...(!!nirRequestId ? { NirRequest: { _attr: { id: nirRequestId } } } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.ForeignProject, convertForeignCollectiveProject),
  }),
  GetNirRequestParticipation: makeBackendAPIConfiguration({
    id: 'GetNirRequestParticipation',
    endpoint: '/msa/service/commands/GetNirRequestParticipation',
    convertInputToXMLElement: ({ participationsIds }: T.GetNirRequestParticipations) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestParticipation' },
        Participation: participationsIds.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.Participation, convertParticipation),
  }),
  GetNirRequestSecurityDocument: makeBackendAPIConfiguration({
    id: 'GetNirRequestSecurityDocument',
    endpoint: '/msa/service/commands/GetNirRequestSecurityDocument',
    convertInputToXMLElement: ({ ids }: T.GetNirRequestSecurityDocuments) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestSecurityDocument' },
        SecurityDocument: ids.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.SecurityDocument, convertSecurityDocument),
  }),
  GetNirRequestForeignSecurityDocument: makeBackendAPIConfiguration({
    id: 'GetNirRequestForeignSecurityDocument',
    endpoint: '/msa/service/commands/GetNirRequestForeignSecurityDocument',
    convertInputToXMLElement: ({ ids }: T.GetNirRequestSecurityDocuments) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestForeignSecurityDocument' },
        ForeignSecurityDocument: ids.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertMaybeArray(response.Response.ForeignSecurityDocument, e => convertForeignSecurityDocument(e, settings)),
  }),
  PushNirRequestMessage: makeBackendAPIConfiguration({
    id: 'PushNirRequestMessage',
    endpoint: '/msa/service/commands/PushNirRequestMessage',
    convertInputToXMLElement: ({ message, nirRequestId }: T.SendMessageRequest) => ({
      Request: {
        _attr: { commandName: 'PushNirRequestMessage' },
        NirRequest: { _attr: { id: nirRequestId, message } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ChangeNirRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeNirRequestStatus',
    endpoint: '/msa/service/commands/ChangeNirRequestStatus',
    convertInputToXMLElement: ({ message, ids, status }: { ids: string[]; status: string; message: string }) => ({
      Request: {
        _attr: { commandName: 'ChangeNirRequestStatus' },
        NirRequest: ids.map(id => ({
          _attr: { id },
          Status: status,
          ...(message ? { Message: message } : {}),
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  AddNirRequestApprovement: makeBackendAPIConfiguration({
    id: 'AddNirRequestApprovement',
    endpoint: '/msa/service/commands/AddNirRequestApprovement',
    convertInputToXMLElement: ({ message, nirRequestId, status }: T.ChangeApprovementRequest) => ({
      Request: {
        _attr: { commandName: 'AddNirRequestApprovement' },
        NirRequest: {
          _attr: { id: nirRequestId },
          Approvement: {
            Resolution: status,
            ...(message ? { ApprovementMessage: message } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetNirRequestBaseDissertation: makeBackendAPIConfiguration({
    id: 'GetNirRequestBaseDissertation',
    endpoint: '/msa/service/commands/GetNirRequestBaseDissertation',
    convertInputToXMLElement: ({ dissertationsIds }: T.GetDissertationsRequest) => ({
      Request: {
        _attr: { commandName: 'GetNirRequestBaseDissertation' },
        BaseDissertation: dissertationsIds.map(id => ({ _attr: { id } })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertMaybeArray(response.Response.BaseDissertation, convertBaseDissertation),
  }),
};

export const nirRequestsConfigurations = {
  ...tableNirRequestConfigurations,
  ...configurations,
};

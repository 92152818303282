import React from 'react';

import { useController } from './controller';

import './style.scss';

const Component: React.FC = () => {
  const { isLoading, isFinished, isUploading } = useController();

  return (
    <div id="loader" className={isLoading ? 'show' : ''}>
      <div id="spinner" className={isFinished ? 'finished' : ''}>
        <span>{isUploading ? 'Отправка файла...' : 'Загрузка данных...'}</span>
      </div>
    </div>
  );
};

export const Loader = React.memo(Component) as typeof Component;

import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const PublicationType = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    enumProps={{
      fieldLabel: 'Тип публикации',
      listEditTitle: 'Список типов публикации',
      mode: { component: 'DefaultSelect', enumName: 'PublicationType' },
    }}
  />
);

const Component = memo(PublicationType);

export { Component as PublicationType };

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Form as F, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import publicationTypes from 'types/models/Publication/publicationTypes';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { isHasPermission } from 'features/AppData';
import { PublicationStatus } from 'utils/Enums';

type Props = {
  tableState: Table.State;
  isSetupSourceRelationMode: boolean;
  parentTypeCode?: string;
  parentId?: string;
};

const useController = ({ tableState, parentTypeCode, parentId, isSetupSourceRelationMode }: Props) => {
  const { settings, userPermission } = useAppDataContext();

  const token = getAuthToken();
  const { isProfile } = usePrivatePageContext();
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);
  const { deletePublication } = useDeletePublication();

  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isApproveFormOpen, setIsApproveFormOpen] = useState<boolean>(false);
  const [isConfirmDeletePopupOpen, setIsConfirmDeletePopupOpen] = useState<boolean>(false);
  const [isEditArticlesRelationPopupOpen, setIsEditArticlesRelationPopupOpen] = useState<boolean>(false);

  const removePublication = useCallback(() => {
    const id = tableState.selectedRows[0]?.id;
    if (id) {
      deletePublication({ publicationId: id });
    }
  }, [deletePublication, tableState.selectedRows]);

  const handleOpenEditArticlesRelationPopup = useCallback(() => {
    setIsEditArticlesRelationPopupOpen(true);
  }, []);

  const handleCloseEditArticlesRelationPopup = useCallback(() => {
    setIsEditArticlesRelationPopupOpen(false);
  }, []);

  const openAddForm = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const closeAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const openEditForm = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const closeEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const closeConfirmDeletePopup = useCallback(() => {
    setIsConfirmDeletePopupOpen(false);
  }, [setIsConfirmDeletePopupOpen]);

  const confirmDelete = useCallback(() => {
    removePublication();
    closeConfirmDeletePopup();
  }, [removePublication, closeConfirmDeletePopup]);

  const openApproveForm = useCallback(() => {
    setIsApproveFormOpen(true);
  }, [setIsApproveFormOpen]);

  const closeApproveForm = useCallback(() => {
    setIsApproveFormOpen(false);
  }, [setIsApproveFormOpen]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = tableState.selectedRows[0]?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        closeApproveForm();
      }
    },
    [closeApproveForm, tableState.selectedRows, tableStreams.approveRow],
  );

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: tableState.selectedRows[0],
      settings,
    });
    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [tableState.selectedRows, settings]);

  const openFile = useCallback(() => {
    if (!tableState.selectedRows[0]) {
      return;
    }
    const fileId = tableState.selectedRows[0]?.FileId;
    if (!fileId) {
      showNotification({ theme: 'danger', message: 'У выбранной публикации нет прикрепленного файла' });
      return;
    }
    downloadFile(fileId, token);
  }, [tableState.selectedRows, token]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsConfirmDeletePopupOpen(true);
  }, []);

  const toolbarButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: openAddForm,
        permission: { name: Permits.PUBLICATION_ADD },
        isHidden: isSetupSourceRelationMode,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: openEditForm,
        permission: { name: Permits.COMPILATION_ARTICLES_EDIT },
        isHidden: isSetupSourceRelationMode,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled:
          !rowsAvailability.SINGLE_SELECTED ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_DRAFTADDED) &&
            (tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.ADDED ||
              tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.DRAFT)) ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_APPROVED) &&
            tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.APPROVED),
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь статей со сборником',
        onClick: handleOpenEditArticlesRelationPopup,
        permission: { name: Permits.COMPILATION_ARTICLES_EDIT },
        isHidden: !isSetupSourceRelationMode,
        isDisabled: !rowsAvailability.MULTIPLE_SELECTED,
      },
      {
        icon: { type: 'like' },
        title: 'Утверждение статьи',
        onClick: openApproveForm,
        permission: { name: Permits.PUBLICATION_APPROVE },
        isHidden: isSetupSourceRelationMode || isProfile,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть статью во внешнем источнике',
        expandedList: { list: getLinks },
        isHidden: isSetupSourceRelationMode,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл, прикрепленный к публикации',
        onClick: openFile,
        isHidden: isSetupSourceRelationMode,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [
      openAddForm,
      isSetupSourceRelationMode,
      openEditForm,
      rowsAvailability.SINGLE_SELECTED,
      rowsAvailability.MULTIPLE_SELECTED,
      handleDeleteButtonClick,
      userPermission,
      tableState.selectedRows,
      handleOpenEditArticlesRelationPopup,
      openApproveForm,
      isProfile,
      getLinks,
      openFile,
    ],
  );

  const selectedType = tableState && tableState?.selectedRows.length ? tableState.selectedRows[0]['id:type'] : '';
  const isRowCanDeleted = tableState?.selectedRows.length && tableState.selectedRows[0]['id:status'] !== 'APPROVED';
  const selectedTypeByParent = useMemo(
    () => publicationTypes.find(type => type.parentCode && type.parentCode === parentTypeCode)?.code || '',
    [parentTypeCode],
  );

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: 'CompilationFormArticle',
    type: isAddFormOpen ? selectedTypeByParent : selectedType,
    editMode: isEditFormOpen,
    id: isAddFormOpen ? undefined : tableState.selectedRows[0]?.id,
    relatedTableState: tableState,
    parentPublicationId: parentId,
    setTitle,
  };

  return {
    toolbarButtons,
    isEditFormOpen,
    isAddFormOpen,
    closeAddForm,
    closeEditForm,
    isApproveFormOpen,
    onApprove,
    closeApproveForm,
    closeConfirmDeletePopup,
    confirmDelete,
    isEditArticlesRelationPopupOpen,
    handleCloseEditArticlesRelationPopup,
    isConfirmDeletePopupOpen,
    look,
    title,
    isRowCanDeleted,
  };
};

export default useController;

import React, { useMemo, useState } from 'react';

import { Column, ListEditTable, SectionTitle } from 'components';

import { Form as F, Event } from 'types/models';

type Props = {
  viewMode?: boolean;
  compilations: Event.Compilations[];
  participationPublications: Event.Source[];
};

const ConferenceDataFormView = ({ compilations, participationPublications }: Props) => {
  const [selectedRowIndexTwo, setSelectedRowIndexTwo] = useState<number | null>(null);
  const reporterParticipationsCount = useMemo(() => participationPublications?.filter(p => p.reporters).length || 0, [
    participationPublications,
  ]);
  const columnsPublication = useMemo<Column<F.Compilation>[]>(
    () => [
      { label: 'ID', formatValue: row => row.id || '', styles: { width: '45px' } },
      {
        label: 'Публикация',
        formatValue: row => row.bibliographicRecord || row.name || '',
        styles: { width: '35%', minWidth: '360px' },
      },
      { label: 'Тип публикации', formatValue: row => row.typePublication || '', styles: { width: '150px' } },
      { label: 'Доклад', formatValue: row => (row.reporters !== '' ? `${row.reporters}` : ''), styles: { width: '120px' } },
      { label: 'Подразделения', formatValue: row => row.departments || '', styles: { width: '90px' } },
      { label: 'Статус', formatValue: row => row.status || '', styles: { width: '90px' } },
    ],
    [],
  );

  return (
    <>
      <SectionTitle
        // eslint-disable-next-line max-len
        title={`Аффилированные с университетом статьи (тезисы) в опубликованных материалах конференции (всего ${participationPublications.length}, докладов ${reporterParticipationsCount})`}
        // eslint-disable-next-line max-len
        tooltip={`Здесь отображены статьи (тезисы) аффилированные с университетом, в том числе те, которые указаны в качестве докладов участников: сотрудников, студентов и аспирантов. На основе данных аффилированных публикаций можно создать запись об участниках конференции на вкладке "Участники".`}
      />

      <ListEditTable
        rows={participationPublications}
        defaultRowsCount={compilations.length < 4 ? 4 : compilations.length + 1}
        columns={columnsPublication}
        selectRow={setSelectedRowIndexTwo}
        selectedRowIndex={selectedRowIndexTwo}
      />
    </>
  );
};

export const Component = React.memo(ConferenceDataFormView);

import React, { useState, useCallback, useMemo } from 'react';
import { format } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, FormComponent, Modal, TextArea, TextDateTime, Toolbar } from 'components';

import { SystemUpdate, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { formatStr } from 'utils/Constants';

type Props = {
  tableState: Table.State;
};

type Mode = 'add' | 'view' | 'edit';

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const [selectedRow] = tableState.selectedRows;

  const [systemUpdate, setSystemUpdate] = useState<SystemUpdate | null>(null);

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [mode, setMode] = useState<Mode>('view');
  const [isDeleteFormOpen, setIsDeleteFormOpen] = useState<boolean>(false);

  const isDisabled = useMemo(() => mode === 'view', [mode]);

  const { methods: getSystemUpdate } = BackendAPI.useBackendAPI('GetSystemUpdate', {
    onSuccessfullCall: ({ data }) => {
      setSystemUpdate(data);
      setIsFormOpen(true);
    },
  });
  const { methods: saveSystemUpdateAPI } = BackendAPI.useBackendAPI('SaveSystemUpdate', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись сохранена', theme: 'success' });
      tableStreams.reloadTable.push({});
      setIsFormOpen(false);
      setMode('view');
      setSystemUpdate(null);
    },
  });
  const { methods: deleteSystemUpdate } = BackendAPI.useBackendAPI('DeleteSystemUpdate', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись удалена', theme: 'success' });
      tableStreams.reloadTable.push({});
      setIsDeleteFormOpen(false);
    },
  });

  const saveSystemUpdate = useCallback(() => {
    if (!systemUpdate) return;
    saveSystemUpdateAPI.callAPI({ ...systemUpdate, id: mode === 'add' ? '' : systemUpdate.id });
  }, [mode, saveSystemUpdateAPI, systemUpdate]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          setMode('view');
          getSystemUpdate.callAPI({ id: selectedRow?.id });
        },
        permission: { name: Permits.ADMIN },
        isDisabled: !selectedRow,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          setSystemUpdate(prev => ({ ...prev!, updateDate: format(new Date(), formatStr) }));
          setIsFormOpen(true);
        },
        permission: { name: Permits.ADMIN },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          getSystemUpdate.callAPI({ id: selectedRow?.id });
        },
        permission: { name: Permits.ADMIN },
        isDisabled: !selectedRow,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteFormOpen(true),
        permission: { name: Permits.ADMIN },
        isDisabled: !selectedRow,
      },
    ],
    [getSystemUpdate, selectedRow],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        isOpen={isFormOpen}
        onClose={() => {
          setIsFormOpen(false);
          setMode('view');
          setSystemUpdate(null);
        }}
        title={`${mode === 'add' ? 'Добавление' : mode === 'edit' ? 'Редактирование ' : 'Просмотр'}: сообщение об обновлении`}
        size={['large', 'small-height']}
      >
        <FormComponent.Template>
          <Toolbar
            buttons={[{ icon: { type: 'save' }, title: 'Сохранить', onClick: saveSystemUpdate, isDisabled: mode === 'view' }]}
            mode="form"
          />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Дата обновления">
                <TextDateTime
                  value={systemUpdate?.updateDate}
                  onChange={e => setSystemUpdate(prev => ({ ...prev!, updateDate: e }))}
                  isDisabled={isDisabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Описание">
                <TextArea
                  value={systemUpdate?.detail}
                  onChange={e => setSystemUpdate(prev => ({ ...prev!, detail: e }))}
                  isDisabled={isDisabled}
                  settings={{ rows: 10 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      <Modal
        mode="warning"
        title="Удалить: сообщение об обновлении"
        isOpen={isDeleteFormOpen}
        onClose={() => setIsDeleteFormOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => deleteSystemUpdate.callAPI({ id: selectedRow?.id }),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsDeleteFormOpen(false),
          },
        ]}
        size="small"
      >
        <>
          (ID: {selectedRow?.id} от {selectedRow?.UpdateDate}) Удалить запись?
        </>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { TenderRequestType } from 'utils/Enums';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';
import { MobileRequestGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    buttons,
    currentReport,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isReportOpen,
    isViewFormOpen,
    onReportClose,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    tenderInfo,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.MobileRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          type={customState.type}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.MobileRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          type={customState.type}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.MobileRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          type={customState.type}
          tenderInfo={tenderInfo}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>Заявка на конкурс от {tableState.selectedRows[0]?.Applicant}. Удалить заявку?</>
        </Modal>
      )}

      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            mobileRequestId: tableState.selectedRows[0]?.id,
          }}
        />
      )}

      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.MOBILE_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>

      {isStatusModalOpen && (
        <Modal
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: statusLabel,
              onClick: changeStatus,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsStatusModalOpen(false),
            },
          ]}
          size="small"
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </Modal>
      )}

      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      <Modal
        mode="help"
        title='Помощь в разделе "Заявки по академической мобильности"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {MobileRequestGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

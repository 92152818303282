import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { convertSecurityDocumentContract } from './converters';
import { convertSecurityDocumentContractToXML } from './requestConverters';
import { SecurityDocumentContractSaveInput } from './types';

const configurations = {
  GetSecurityDocumentContract: makeBackendAPIConfiguration({
    id: 'GetSecurityDocumentContract',
    endpoint: '/msa/service/commands/GetSecurityDocumentContract',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetSecurityDocumentContract' },
        SecurityDocumentContract: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertSecurityDocumentContract(response.Response.SecurityDocumentContract, settings),
  }),
  SaveSecurityDocumentContract: makeBackendAPIConfiguration({
    id: 'SaveSecurityDocumentContract',
    endpoint: '/msa/service/commands/SaveSecurityDocumentContract',
    convertInputToXMLElement: (inputData: SecurityDocumentContractSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SaveSecurityDocumentContract',
        },
        SecurityDocumentContract: { ...convertSecurityDocumentContractToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const securityDocumentContractsConfigurations = { ...configurations };

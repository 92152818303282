import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isAcceptedForCommercialization',
      label: 'Приняты к коммерциализации',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isNotExisted',
      label: 'Новые ОИС',
      checked: true,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isExisted',
      label: 'Рид',
      checked: true,
    },
  ]);
});

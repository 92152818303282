import React, { useMemo, useState } from 'react';

import { ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Person } from 'types/models';
import { AuthorPayment, EventType, Nominee } from 'types/models/Payment';
import { showErrorsMessages } from 'utils/Common';
import { showNotification } from 'features/Notifications';
import { PortfolioCard } from 'features/Form/looks/person';
import { formatEditableJob, formatInfoSource, isValidNominee } from 'features/IncentivePayments/helpers';
import { formatEducation } from 'features/Form/views/AboutAuthorship/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  authorPayment: AuthorPayment;
  setNominees(nominees: Nominee[]): void;
  disabled: boolean;
  eventType: EventType | null;
};

export function Nominees(props: Props) {
  const { authorPayment, setNominees, disabled, eventType } = props;

  const [isOpenPersonCard, setIsOpenPersonCard] = useState(false);
  const [selectedPerson, setSelectedPerson] = useState<Person.ScientistPerson | null>(null);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Nominee>[]>(
    () => [
      {
        icon: { type: 'group', mode: 'check' },
        title: 'Отметить аффилиацию',
        onClick: (nominee, index, rows) => {
          if (nominee && !isValidNominee(nominee)) {
            showNotification({
              message: 'Укажите подразделение, к которому относится номинант, с помощью кнопки Редактировать',
              theme: 'danger',
            });
            return;
          }
          if (index !== null) {
            const updatedNominees = [...rows];
            updatedNominees[index].isAffiliated = true;

            setNominees(updatedNominees);
          }
        },
        isDisabled: row => row === null || disabled,
      },
      {
        icon: { type: 'group', mode: 'cancel' },
        title: 'Снять аффилиацию',
        onClick: (_, index, rows) => {
          if (index !== null) {
            const updatedNominees = [...rows];
            updatedNominees[index] = { ...updatedNominees[index], isAffiliated: false, isPaid: false, factMoney: '0' } as Nominee;

            setNominees(updatedNominees);
          }
        },
        isDisabled: row => row === null || disabled,
      },
      {
        icon: { type: 'currency', mode: 'check' },
        title: 'Назначить к оплате',
        onClick: (editableNominee, index, rows) => {
          if (editableNominee && !isValidNominee(editableNominee)) {
            showNotification({
              message: 'Укажите подразделение, к которому относится номинант, с помощью кнопки Редактировать',
              theme: 'danger',
            });
            return;
          }
          if (index !== null) {
            const updatedNominees = [...rows];
            updatedNominees[index] = {
              ...updatedNominees[index],
              isAffiliated: true,
              isPaid: true,
              factMoney: updatedNominees[index].planMoney,
            } as Nominee;

            setNominees(updatedNominees);
          }
        },
        isDisabled: row => row === null || disabled,
      },
      {
        icon: { type: 'currency', mode: 'cancel' },
        title: 'Снять назначение к оплате',
        onClick: (_, index, rows) => {
          if (index !== null) {
            const updatedNominees = [...rows];
            updatedNominees[index] = { ...updatedNominees[index], isPaid: false, factMoney: '0' } as Nominee;

            setNominees(updatedNominees);
          }
        },
        isDisabled: row => row === null || disabled,
      },
    ],
    [setNominees, disabled],
  );

  return (
    <>
      <ListEdit
        header={{ title: 'Номинанты на оплату стимулирующих выплат' }}
        rows={authorPayment.nominees}
        onChange={setNominees}
        toolbar={['edit', ...extraToolbarButtons]}
        columns={[
          {
            label: 'Аффил-н',
            formatValue: x => x.isAffiliated,
            styles: { width: '5%' },
          },
          {
            label: 'К оплате',
            formatValue: x => x.isPaid,
            styles: { width: '5%' },
          },
          {
            label: 'ФИО номинанта',
            formatValue: x => [x.person.fullName, x.note ? `(${x.note})` : ''].filter(Boolean).join('<br />'),
            styles: { width: '10%' },
          },
          { label: 'План, руб.', formatValue: x => x.planMoney, styles: { width: '5%' }, dataKind: 'float' },
          { label: 'Факт, руб.', formatValue: x => x.factMoney, styles: { width: '5%' }, dataKind: 'float' },
          { label: 'Сведения из источника', formatValue: formatInfoSource, styles: { width: '20%' } },
          {
            label: 'Сведения о работе на дату заявки',
            formatValue: x => formatEditableJob(x.job),
            styles: { width: '20%' },
          },
          {
            label: 'Сведения об учебе на дату заявки',
            formatValue: x => (x.education ? formatEducation(x.education) : ''),
            styles: { width: '15%' },
          },
          {
            label: 'Относится к Факультету / Институту',
            formatValue: x => x.topLevelDepartment?.label ?? '',
            styles: { width: '15%' },
          },
        ]}
        defaultRowsCount={7}
        columnIndexesForSumTotal={[3, 4]}
        isDisabled={disabled}
        extraFieldsToolbarButtons={[
          {
            icon: { type: 'user' },
            title: 'Редактировать персону',
            onClick: nominee => {
              if (nominee) {
                setSelectedPerson(nominee.person);
                setIsOpenPersonCard(true);
              }
            },
            isDisabled: row => row === null,
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: (nominee, setNominee) => (
            <Fields eventType={eventType} nominee={nominee} setNominee={setNominee} authorPayment={authorPayment} />
          ),
          validation: {
            checkIsValid: nominee => validate(nominee, eventType, authorPayment).every(x => x.isValid),
            onInvalidate: nominee => {
              const errorMessages = validate(nominee, eventType, authorPayment)
                .filter(({ isValid }) => !isValid)
                .map(x => x.invalidMessage);

              if (errorMessages.length) {
                showErrorsMessages(errorMessages);
              }
            },
          },
        }}
      />
      <PortfolioCard
        isOpen={isOpenPersonCard}
        id={selectedPerson?.id ?? null}
        onClose={() => {
          setIsOpenPersonCard(false);
          setSelectedPerson(null);
        }}
      />
    </>
  );
}

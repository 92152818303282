import React, { memo, useEffect } from 'react';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { Documents } from 'features/Documents';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import ForeignProjectTabs from './Tabs';
import useController from './controller';
import { useFormContext } from 'features/Form/hooks';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
};

const ForeignProjectForm = ({ onClose }: Props) => {
  const {
    foreignProject,
    workMode,
    isDisabled,
    isHistoryWarningOpen,
    foreignProjectErrors,
    onSubmitHistoryWarning,
    onCloseHistoryWarning,
    onSave,
    makeChangeHandler,
    makeResetErrorHandler,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return `Добавление проекта`;
        }

        const enterprise = foreignProject.enterprise;
        const enterpriseShortName =
          enterprise?.enterprise?.label || enterprise?.enterpriseCustomer?.label || enterprise?.partner?.shortName || '';

        return getStatusMessage(
          [
            workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
            'проекта',
            enterpriseShortName ? `"${enterpriseShortName}"` : '',
          ]
            .filter(Boolean)
            .join(' '),
          foreignProject.id,
          foreignProject.status?.label,
        );
      })(),
    );
  }, [foreignProject.enterprise, foreignProject.id, foreignProject.status?.label, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <ControlPanel handleSave={onSave} isDisabled={isDisabled} />

        <FormComponent.Template>
          <Tabs>
            <Tab title="О проекте">
              <ForeignProjectTabs.AboutProject
                isDisabled={isDisabled}
                makeResetErrorHandler={makeResetErrorHandler}
                makeChangeHandler={makeChangeHandler}
                foreignProjectErrors={foreignProjectErrors}
                foreignProject={foreignProject}
              />
            </Tab>
            <Tab title="Документы">
              <FormComponent.Template>
                <Documents
                  title="Документы"
                  disabled={isDisabled}
                  documents={foreignProject.documents}
                  setDocuments={makeChangeHandler('documents')}
                />
              </FormComponent.Template>
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isHistoryWarningOpen}
        onClose={onCloseHistoryWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить сохранение',
            onClick: onSubmitHistoryWarning,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onCloseHistoryWarning,
          },
        ]}
        size="small"
      >
        <>Укажите исторический срез для участника проекта</>
      </Modal>
    </>
  );
};

export const Component = memo(ForeignProjectForm);

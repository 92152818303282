import React, { useMemo, useState, useCallback } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Component as Form } from '../Form';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const { initialCountryCode } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const { openForm, loadScientist, setPerson, setMode, mode, closeForm, isOpenForm } = customState;

  const [selectedRow] = tableState.selectedRows;

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const confirmDelete = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Scientist',
    });
    setIsDeleteModalOpen(false);
  }, [tableState.selectedRows, tableStreams.deleteRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          if (selectedRow) loadScientist(selectedRow?.id);
          setMode('view');
          openForm();
        },
        permission: { name: Permits.PERSON_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          setPerson(getMockPerson({ initialCountryCode }));
          openForm();
        },
        permission: { name: Permits.PERSON_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          if (selectedRow) loadScientist(selectedRow?.id);
          setMode('edit');
          openForm();
        },
        permission: { name: Permits.PERSON_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteModalOpen(true),
        permission: { name: Permits.PERSON_DELETE },
        isDisabled: tableState.selectedRows.length !== 1 || selectedRow?.integrated === 'true',
      },
    ],
    [tableState.selectedRows.length, selectedRow, loadScientist, setMode, openForm, setPerson, initialCountryCode],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Form isOpen={isOpenForm} onClose={closeForm} id={selectedRow?.id} mode={mode} />

      {isDeleteModalOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: confirmDelete,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteModalOpen(false),
            },
          ]}
          size="small"
        >
          <>({selectedRow?.Fio}) Удалить из справочника?</>
        </Modal>
      )}
    </>
  );
}

export { Toolbar };

import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { CommunityType } from 'utils/Enums';

const CommunityAddTemplate: React.FC<F.TemplateProps & { memberEditLocked: boolean; communityTypes?: CommunityType[] }> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  templateName,
  memberEditLocked,
  communityTypes,
}: F.TemplateProps & { memberEditLocked: boolean; communityTypes?: CommunityType[] }) => {
  const look: F.CommunityMemberFormLook = {
    apiID: 'GetCommunity',
    template: templateName,
    relatedTableState,
    name,
    arm: 'pc',
    memberEditLocked,
    communityTypes,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Добавление члена диссертационного совета" size={['large', 'small-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityAddTemplate);

import { useMemo } from 'react';

import { CheckboxItem } from 'features/BuildReportPopup/models';

const useController = () => {
  const checkboxList = useMemo<CheckboxItem[]>(
    () => [
      { isChecked: false, label: 'Все', value: '-1' },
      { isChecked: false, label: 'Акт', value: '0' },
      { isChecked: false, label: 'Платежное поручение', value: '1' },
      { isChecked: false, label: 'Договор', value: '2' },
      { isChecked: false, label: 'Отчёт по НИР', value: '3' },
      { isChecked: false, label: 'Другие', value: '4' },
    ],
    [],
  );
  return {
    checkboxList,
  };
};

export default useController;

import React from 'react';

import { Person, UserPermission } from 'types/models';
import { UpperForm } from './UpperForm';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  setPerson: React.Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
};

function Component(props: Props) {
  const { person, userPermission, setPerson } = props;
  return <UpperForm userPermission={userPermission} person={person} setPerson={setPerson} />;
}

export const Main = React.memo(Component);

import * as XML from 'xml-js';
import * as requestConverters from './requestConverters';

import { Person, Suggestion } from 'types/models';
import { Settings } from 'types/models/common';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { PersonContactType, PersonModule } from 'utils/Enums';

import {
  ChangePersonHistoryElementLinkRequestData,
  GetScientistDataRequest,
  GetSuggestionsRequest,
  IsLinkedHistory,
  SaveScientistBibliographicNameRequest,
  SaveScientistsRequest,
  SetDefaultBibliographicNameRequest,
} from './types';

import { convertIsLinkedHistory, convertServerPerson, convertServerSuggestion } from './converters';
import {
  convertFileToXML,
  convertMaybeArray,
  convertReferenceItemToXML,
  convertSaveResponseXMLElementToOutput,
} from '../commonConverters';

const PERSON_MODULES = [
  PersonModule.GENERAL,
  PersonModule.JOBS,
  PersonModule.EDUCATIONS,
  PersonModule.DEGREES,
  PersonModule.RANKS,
  PersonModule.ACADEMIC_RANKS,
  PersonModule.INTERRESTS,
  PersonModule.COMMUNITIES,
  PersonModule.HIRSH_INDICES,
  PersonModule.BIBLIOGRAPHIC_NAMES,
  PersonModule.AFFILIATIONS,
  PersonModule.IDENTIFIERS,
  PersonModule.CHANGES,
  PersonModule.CALCULATION,
  PersonModule.CONTACTS,
  PersonModule.NOTIFICATIONS,
];

export const authorConfigurations = {
  GetPersonSuggestions: makeBackendAPIConfiguration({
    id: 'GetPersonSuggestions',
    endpoint: '/msa/service/commands/GetPersonSuggestions',
    convertInputToXMLElement: ({ searchValue }: GetSuggestionsRequest) => ({
      Request: {
        _attr: { commandName: 'GetPersonSuggestions' },
        GetSuggestions: searchValue,
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): Suggestion[] =>
      convertMaybeArray(response.Response.Suggestion, convertServerSuggestion),
  }),
  GetScientistData: makeBackendAPIConfiguration({
    id: 'GetScientistData',
    endpoint: '/msa/service/commands/GetScientistData',
    convertInputToXMLElement: ({ personId, modules }: GetScientistDataRequest) => ({
      Request: {
        _attr: { commandName: 'GetScientistData' },
        Person: {
          _attr: {
            id: personId,
            ...(modules?.length ? { modules } : { modules: PERSON_MODULES }),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings): Person.ScientistPerson =>
      convertServerPerson(response.Response.Person, settings),
  }),
  SaveScientistBibliographicName: makeBackendAPIConfiguration({
    id: 'SaveScientistBibliographicName',
    endpoint: '/msa/service/commands/SaveScientistBibliographicName',
    convertInputToXMLElement: ({
      id,
      isDefault,
      languageId,
      scientistId,
      bibliographicName,
    }: SaveScientistBibliographicNameRequest) => ({
      Request: {
        _attr: { commandName: 'SaveScientistBibliographicName' },
        ScientistBibliographicName: {
          _attr: {
            scientistId,
            bibliographicName,
            languageId,
            isDefault,
            ...(id ? { id } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SetDefaultScientistBibliographicName: makeBackendAPIConfiguration({
    id: 'SetDefaultScientistBibliographicName',
    endpoint: '/msa/service/commands/SetDefaultScientistBibliographicName',
    convertInputToXMLElement: ({ id, languageId, scientistId, bibliographicName }: SetDefaultBibliographicNameRequest) => ({
      Request: {
        _attr: { commandName: 'SetDefaultScientistBibliographicName' },
        ScientistBibliographicName: {
          _attr: {
            id,
            scientistId,
            bibliographicName,
            languageId,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveScientist: makeBackendAPIConfiguration({
    id: 'SaveScientist',
    endpoint: '/msa/service/commands/SaveScientist',
    convertInputToXMLElement: ({ person }: SaveScientistsRequest) => {
      const {
        id,
        academicRanks,
        bibliographicNames,
        degrees,
        identifiers,
        ranks,
        scientist,
        scientistEducations,
        scientistJobs,
        hirshIndexes,
        communityMembers,
        domainInterests,
        grntis,
        udks,
        knowledgeBranches,
        oecds,
        vakSpecialities,
        avatar,
        contacts,
      } = person;
      return {
        Request: {
          _attr: { commandName: 'SaveScientist' },
          ScientistData: {
            _attr: id ? { id } : {},
            ...(scientist ? { Scientist: requestConverters.convertScientistToRequest(scientist) } : {}),
            ...convertFileToXML('Avatar', avatar),
            ScientistJobs: requestConverters.convertJobsToRequest(scientistJobs),
            ScientistEducations: requestConverters.convertEducationsToRequest(scientistEducations),
            ScientistDegrees: requestConverters.convertDegreesToRequest(degrees),
            ScientistRanks: requestConverters.convertRanksToRequest(ranks),
            ScientistAcademicRanks: requestConverters.convertAcademicRanksToRequest(academicRanks),
            ScientistBibliographicNames: requestConverters.convertBiblioNamesToRequest(bibliographicNames),
            Identifiers: requestConverters.convertIdentifiersToRequest(identifiers),
            HirshIndexes: requestConverters.convertHirshIndexesToRequest(hirshIndexes),
            CommunityMembers: requestConverters.convertCommunityMembersToRequest(communityMembers, id),
            ScienceDomainInterests:
              domainInterests && domainInterests.length
                ? { ScienceDomainInterest: domainInterests.map(convertReferenceItemToXML) }
                : {},
            Grntis: grntis && grntis.length ? { Grnti: grntis.map(convertReferenceItemToXML) } : {},
            Udks: udks && udks.length ? { Udk: udks.map(convertReferenceItemToXML) } : {},
            Oecds: oecds && oecds.length ? { Oecd: oecds.map(convertReferenceItemToXML) } : {},
            KnowledgeBranches:
              knowledgeBranches && knowledgeBranches.length
                ? { KnowledgeBranch: knowledgeBranches.map(convertReferenceItemToXML) }
                : {},
            VakSpecialities:
              vakSpecialities && vakSpecialities.length ? { VakSpeciality: vakSpecialities.map(convertReferenceItemToXML) } : {},
            Affiliations: {},
            Contacts: { Contact: contacts.map(requestConverters.convertContactToXML) },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ActualizeCurrentPerson: makeBackendAPIConfiguration({
    id: 'ActualizeCurrentPerson',
    endpoint: '/msa/service/commands/ActualizeCurrentPerson',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'ActualizeCurrentPerson' },
      },
    }),
    convertResponseXMLElementToOutput: () => {},
  }),
  GetCurrentPerson: makeBackendAPIConfiguration({
    id: 'GetCurrentPerson',
    endpoint: '/msa/service/commands/GetCurrentPerson',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: { commandName: 'GetCurrentPerson' },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertServerPerson(response.Response.Person, settings),
  }),
  IsLinkedHistory: makeBackendAPIConfiguration({
    id: 'IsLinkedHistory',
    endpoint: '/msa/service/commands/IsLinkedHistory',
    convertInputToXMLElement: ({ id, type }: IsLinkedHistory) => ({
      Request: {
        _attr: { commandName: 'IsLinkedHistory' },
        IsLinkedHistory: { _attr: { type, id } },
      },
    }),
    convertResponseXMLElementToOutput: convertIsLinkedHistory,
  }),
  ChangePersonHistoryElementLink: makeBackendAPIConfiguration({
    id: 'ChangePersonHistoryElementLink',
    endpoint: '/msa/service/commands/ChangePersonHistoryElementLink',
    convertInputToXMLElement: ({
      historyEntities,
      id,
      nullify,
      type,
      keepHistory,
    }: ChangePersonHistoryElementLinkRequestData) => ({
      Request: {
        _attr: { commandName: 'ChangePersonHistoryElementLink' },
        ...requestConverters.convertChangeChangePersonHistoryElementLinkToRequest({
          historyEntities,
          id,
          nullify,
          type,
          keepHistory,
        }),
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  AddScientistContact: makeBackendAPIConfiguration({
    id: 'AddScientistContact',
    endpoint: '/msa/service/commands/AddScientistContact',
    convertInputToXMLElement: ({ id, type, value }: { id: string; type: PersonContactType; value: string }) => ({
      Request: {
        _attr: { commandName: 'AddScientistContact' },
        Scientist: {
          _attr: {
            id,
          },
          Contact: {
            Type: type,
            Value: value,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

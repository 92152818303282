import { Form } from 'types/models';
import { SecurityDocumentPayment } from 'types/models/SecurityDocument';

export function validate(p: SecurityDocumentPayment | null, formFields: Form.Fields) {
  const required = Object.keys(formFields)
    .filter((fieldName: string) => {
      if (typeof formFields[fieldName]?.value === 'object' && Object.hasOwn(formFields[fieldName]?.value, 'id')) {
        return formFields[fieldName].required && !formFields[fieldName]?.value?.id;
      } else {
        return formFields[fieldName].required && !formFields[fieldName]?.value;
      }
    })
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  return [...required];
}

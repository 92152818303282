import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { MessageModal } from 'features/Form/views/MessageModal';
import { purchaseRequestSubmitTextMap, purchaseRequestTextMap } from 'types/models/PurchaseRequest';
// eslint-disable-next-line max-len
import { Component as PurchaseRequestAddTemplate } from 'features/Table/specifications/GetPurchaseRequestList/LeftPanelForThirdLevel/modalTemplates/PurchaseRequestAddTemplate';
import { PurchaseRequestGridHelp } from 'features/Table/specifications/GetPurchaseRequestList/help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForSpecification({ tableState }: Props) {
  const {
    buttons,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isOpenMessageModal,
    handleMessageModalClose,
    onSubmitDeleteWarning,
    handleSaveStatusClick,
    newStatus,
    selectedRow,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.PurchaseRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.PurchaseRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && (
        <PurchaseRequestAddTemplate
          isOpen={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          relatedTableState={tableState}
          hasPersonalMode={true}
        />
      )}

      {newStatus && (
        <MessageModal
          isOpen={isOpenMessageModal}
          onClose={handleMessageModalClose}
          onSubmit={handleSaveStatusClick}
          titleText={purchaseRequestTextMap[newStatus.toString()]}
          submitText={purchaseRequestSubmitTextMap[newStatus.toString()]}
        />
      )}

      {selectedRow && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteWarningOpen}
          onClose={() => setIsDeleteWarningOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: onSubmitDeleteWarning,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteWarningOpen(false),
            },
          ]}
          size="small"
        >
          <>Заявка на конкурс от {tableState.selectedRows[0]?.Applicant}. Удалить заявку?</>
        </Modal>
      )}

      <Modal
        mode="help"
        title="Заявки на закупки для научных проектов"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {PurchaseRequestGridHelp(true)}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForSpecification);

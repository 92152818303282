import { Arm, Table } from 'types/models';
import { Filters } from './filters';
import { Component as Toolbar } from './Toolbar';
import { makeUseCustomController } from './makeUseCustomController';

type Props = {
  arm: Arm;
};

type Arguments = {
  arm: Arm;
};

export function GetRestrictionProjectList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetRestrictionProjectList',
    requestData: args,
    header: {
      firstLevel: {
        title: 'Мониторинг проектов без ограничений',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    useCustomController: makeUseCustomController({ arm: args?.arm }),
  };
}

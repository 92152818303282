import React from 'react';

import { Table } from 'types/models';
import { PublicationMobileRequest } from 'types/models/Form';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { Requests } from 'features/Form/looks/participation/views/Requests';
import { ParticipationProject } from 'types/models/Participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  financingProjects: ParticipationProject[];
  setProjects: (projects: ParticipationProject[]) => void;
  mobileRequests: PublicationMobileRequest[];
  setMobileRequests: (requests: PublicationMobileRequest[]) => void;
};

function FinancialSupport({
  relatedTableState,
  workMode,
  financingProjects,
  setProjects,
  mobileRequests,
  setMobileRequests,
}: Props) {
  return (
    <>
      <Projects
        workMode={workMode}
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        projects={financingProjects}
        setProjects={setProjects}
        isDisabled={workMode === 'viewMode'}
        relatedTableState={relatedTableState}
        isMaximized
        // eslint-disable-next-line max-len
        tooltipProjectText="Отметка о принятии к отчету свидетельствует о том, что участие в конференции является одним из результатов проекта. "
        // eslint-disable-next-line max-len
        tooltipText='Указать проект (грант на организацию проведения мероприятия), связанный с докладом. Сведения об участии будет отображаться в карточке проекта на вкладке "Результаты".'
      />
      <Requests
        workMode={workMode}
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        requests={mobileRequests}
        setMobileRequests={setMobileRequests}
        relatedTableState={relatedTableState}
        isMaximized
      />
    </>
  );
}

export const Component = React.memo(FinancialSupport);

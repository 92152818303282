const isOKOPFValid = (value: any) => {
  if (!value) {
    return true;
  }

  const mask = [/\d/, /\s/, /\d/, /\d/, /\s/, /\d/, /\d/];
  if (value.length !== mask.length) {
    return false;
  }

  return [...value].every((char: string, index: number) => mask[index].test(char));
};

export { isOKOPFValid };

import React from 'react';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Rntd } from 'types/models';
import { ProjectInfoModal } from 'features/ProjectInfoModal';
import { useController } from './controller';
import { WorkMode } from 'types/models/Table';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  rntd?: Rntd | null;
  workMode: WorkMode;
};

function Toolbar(props: Props) {
  const { onSave, rntd, workMode } = props;

  const {
    buttons,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
  } = useController({
    onSave,
    rntd,
    workMode,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <ProjectInfoModal
        isOpen={isProjectInfoModalOpen}
        onClose={() => setIsProjectInfoModalOpen(false)}
        project={project ?? null}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isSaveConfirmPopupOpen}
        onClose={() => setIsSaveConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onSaveConfirmPopupConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsSaveConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Дата снятия патента с баланса равна дате подачи заявки. Продолжить сохранение?</>
      </Modal>
    </>
  );
}

export { Toolbar };

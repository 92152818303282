import { showNotification } from 'features/Notifications';
import { Project } from 'types/models';

export function validate(department: Project.Department | null, list: Project.Department[]) {
  if (!department) {
    showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
    return false;
  }

  const errorMessages = [];

  if (!department.governance?.id) errorMessages.push('Выберите Факультет/Институт');
  if (!department.unit?.id) errorMessages.push('Выберите подразделение');
  if ((list || []).some(x => x.governance?.id === department.governance?.id && x.unit?.id === department.unit?.id))
    errorMessages.push('Попытка сохранить дубликат');

  errorMessages.forEach(x => showNotification({ message: x, theme: 'danger' }));

  return !errorMessages.length;
}

import { parse } from 'date-fns';

import { Person } from 'types/models';
import { formatStr } from 'utils/Constants';
import { Member } from '..';
import { findActualJob } from 'shared/findActualJob';

type ActiveEntity = {
  isActive: boolean;
};

type CreationDateEntity = {
  creationDate: string;
};
export const prepareAcademicRank = (academicRank: Person.ScientistAcademicRank | null) => {
  if (academicRank?.id) {
    const copy: Person.ScientistAcademicRank = { ...academicRank };
    copy.originalId = academicRank.id;
    copy.id = null;
    return copy;
  }

  return null;
};

export const prepareJob = (job: Person.ScientistJob | null) => {
  if (job?.id) {
    const copy: Person.ScientistJob = { ...job };
    copy.originalId = job.id;
    copy.id = null;
    return copy;
  }

  return null;
};

export const prepareEducation = (education: Person.ScientistEducation | null) => {
  if (education?.id) {
    const copy: Person.ScientistEducation = { ...education };
    copy.originalId = education.id;
    copy.id = null;
    return copy;
  }

  return null;
};

export const prepareDegree = (degree: Person.ScientistDegree | null) => {
  if (degree?.id) {
    const copy: Person.ScientistDegree = { ...degree };
    copy.originalId = degree.id;
    copy.id = null;
    return copy;
  }

  return null;
};

export const prepareRank = (rank: Person.ScientistRank | null) => {
  if (rank?.id) {
    const copy: Person.ScientistRank = { ...rank };
    copy.originalId = rank.id;
    copy.id = null;
    return copy;
  }

  return null;
};
export const getParsedDate = (date: string): number => parse(date, formatStr, new Date()).getTime();

export const getActiveEntities = <T extends ActiveEntity>(entities: T[]) => entities.filter(i => i.isActive);

export const getLatestEntry = <T extends CreationDateEntity>(entities: T[]) =>
  entities.sort((a, b) => getParsedDate(b.creationDate) - getParsedDate(a.creationDate))[0];

export function getInitDegree(item: Partial<Member> | null) {
  if (item?.person?.degrees.length) {
    const activeDegrees = getActiveEntities(item.person.degrees);
    const latestActiveDegree = getLatestEntry(activeDegrees);
    return prepareDegree(latestActiveDegree || null);
  }

  return null;
}

export function getInitAcademicRank(item: Partial<Member> | null) {
  if (item?.person?.academicRanks.length) {
    const activeAcademicRanks = getActiveEntities(item.person.academicRanks);
    const latestActiveAcademicRank = getLatestEntry(activeAcademicRanks);
    return prepareAcademicRank(latestActiveAcademicRank || null);
  }

  return null;
}

export function getInitRank(item: Partial<Member> | null) {
  if (item?.person?.ranks.length) {
    const activeRanks = getActiveEntities(item.person.ranks);
    const latestAcademicRank = getLatestEntry(activeRanks);
    return prepareRank(latestAcademicRank || null);
  }

  return null;
}

export function getInitJob(item: Partial<Member> | null) {
  const actualJob = findActualJob(item?.person || null);
  return prepareJob(actualJob);
}

export function getInitialEducation(item: Partial<Member> | null) {
  if (item?.person?.scientistEducations.length) {
    const actualDateTime = new Date().getTime();
    const activeEducations = getActiveEntities(item.person.scientistEducations);

    let actualEducation: Person.ScientistEducation | null = null;
    let actualEducationPriority: number = 5;

    activeEducations.forEach(education => {
      let startDate: number | null = null;
      let stopDate: number | null = null;
      // low - dateReceipt - start
      // hi - dateDismissal - end
      if (education.dateReceipt) {
        startDate = getParsedDate(education.dateReceipt);
      }

      if (education.dateDismissal) {
        stopDate = getParsedDate(education.dateDismissal);
      }

      if ((startDate === null || startDate <= actualDateTime) && (stopDate === null || stopDate >= actualDateTime)) {
        if (education.isTSU) {
          if (actualEducationPriority > 1) {
            actualEducation = { ...education };
            actualEducationPriority = 1;
          }
        } else if (actualEducationPriority > 2) {
          actualEducationPriority = 2;
          actualEducation = { ...education };
        }
      }
    });
    return prepareEducation(actualEducation);
  }

  return null;
}

export function getInitCitizenship(item: Partial<Member> | null) {
  return item?.person?.scientist?.citizenship || null;
}

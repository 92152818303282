import React from 'react';

import { ListEdit, SectionTitle, Tab, Tabs } from 'components';

import { showErrors } from 'utils/Validators';
import { DataGrid } from 'features/Table';
import { GetPartnerAffilatedPersonList } from 'features/Table/specifications';
import { Contact } from 'types/models/Partner';
import { usePermissions } from 'features/EditPartner/usePermissions';
import { getMockContact } from 'features/EditPartner/helpers';
import { Fields } from './Fields';
import { validate } from './validate';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  contacts: Contact[];
  setContacts(contacts: Contact[]): void;
  enterpriseCustomerIds: string[];
  enterpriseIds: string[];
  disabled: boolean;
};

export function Contacts(props: Props) {
  const { contacts, setContacts, enterpriseCustomerIds, enterpriseIds, disabled } = props;

  const permissions = usePermissions();

  return (
    <Tabs>
      <Tab title="Контактные лица" isVisible={permissions.isViewableContacts}>
        <ListEdit
          rows={contacts}
          onChange={setContacts}
          toolbar={['add', 'edit', 'delete']}
          columns={[
            { label: 'ФИО', formatValue: x => x.person?.fullName ?? '' },
            { label: 'Должность', formatValue: x => x.appointment },
            { label: 'Подразделение', formatValue: x => x.department },
            { label: 'Коммуникации по вопросам', formatValue: x => x.communication },
            { label: 'Контакты', formatValue: x => getPersonContacts(x.person?.contacts) },
            { label: 'Доп. контакты', formatValue: x => x.extraContacts },
          ]}
          withMessages
          isDeleteConfirmEnabled
          isDisabled={disabled}
          defaultRowsCount={6}
          maxHeight="none"
          specification={{
            mode: 'customComponent',
            renderComponent: (contact, setContact) => <Fields contact={contact || getMockContact()} setContact={setContact} />,
            validation: {
              checkIsValid: okved => validate(okved).every(x => x.isValid),
              onInvalidate: val => showErrors(validate, val),
            },
          }}
        />
      </Tab>
      <Tab title="Персоны, аффилированные с организацией в публикациях">
        <SectionTitle title="Список авторов публикаций, аффилированных с университетом и организацией" />
        <DataGrid specification={GetPartnerAffilatedPersonList({ enterpriseCustomerIds, enterpriseIds })} />
      </Tab>
    </Tabs>
  );
}

import { DataGrid } from 'features/Table';
import React from 'react';
import { Person } from 'types/models';
import { GetScientistEventList } from 'features/Table/specifications';

type Props = {
  person: Person.ScientistPerson | null;
};

export function ScientistEvents(props: Props) {
  const { person } = props;

  if (!person) {
    return null;
  }

  return <DataGrid specification={GetScientistEventList({ personId: person.id })} />;
}

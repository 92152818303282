import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextAreaMode, TextInput, TextInputMode, ReferenceItem } from 'components';

import { Unit } from 'types/models/PurchaseRequest';
import { Item } from 'types/models/common';

type Props = {
  unit: Unit | null;
  setUnit(unit: any): void;
  isLess100Products: boolean;
  isLess100Services: boolean;
  isMore100: boolean;
};

function UnitFields(props: Props) {
  const { unit, setUnit, isLess100Products, isMore100 } = props;

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Тип закупки" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={unit?.type}
            settings={{ name: 'PurchaseRequestUnitType' }}
            onChange={(option: Item) => setUnit({ ...unit!, type: option })}
            isDisabled={isLess100Products}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label={isMore100 ? 'Наименование поставляемого товара, выполняемых работ, оказываемых услуг' : 'Наименование'}
          isRequired
          // eslint-disable-next-line max-len
          tooltip="Для услуг (работ) указывается предмет договора. Например: Предоставление услуг по корпоративному обслуживанию в сфере пассажирских авиаперевозок."
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3 }}
            value={unit?.name || ''}
            onChange={val => setUnit({ ...unit!, name: val })}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Количество" isRequired={isLess100Products}>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ decimal: false }}
            value={unit?.quantity}
            onChange={e => setUnit({ ...unit!, quantity: e })}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Eдиница измерения">
          <Select
            mode={SelectMode.REFERENCE}
            value={unit?.unit}
            onChange={(e: ReferenceItem | null) => setUnit({ ...unit!, unit: e })}
            settings={{ name: 'RefOkei', title: 'Справочник "Единицы измерения"', isClearable: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label={isMore100 ? 'Начальная (максимальная) цена контракта' : 'Ориентировочная цена за ед.'}
          tooltip={isMore100 ? '' : 'Точную цену можно не указывать, а указать предельную сумму на всю закупку'}
          isRequired={isMore100}
        >
          <TextInput
            mode={TextInputMode.NUMBER}
            value={unit?.perUnitPrice}
            onChange={e => setUnit({ ...unit!, perUnitPrice: e })}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

export { UnitFields };

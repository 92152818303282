import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { RequestTypes } from 'utils/Enums/RequestTypes';
import { ProgramRequestProps } from 'types/models/Form/look/programRequest';
import { Modal } from 'components';

const RequestAddTemplate: React.FC<F.TemplateProps & ProgramRequestProps> = ({
  isOpen,
  onClose,
  tenderInfo,
  relatedTableState,
  name,
}: F.TemplateProps & ProgramRequestProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ProgramRequestFormLook = {
    apiID: 'GetRequest',
    template: RequestTypes.REQUEST.template,
    tenderInfo,
    relatedTableState,
    name,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(RequestAddTemplate);

import { ReferenceItem } from 'components';

import { Award, FileInfo, ResponseData } from 'types/models';
import { IdItem, Item } from 'types/models/common';
import { DEFAULT_TIME, EMPTY_FILE } from 'utils/Constants';

export function convertAwardsToRequest(awards?: Award[]) {
  if (!awards || !awards.length) {
    return { Awards: {} };
  }

  return {
    Awards: {
      Award: awards.map(award => ({
        _attr: { id: award.id },
        Name: award.name,
        ...(award.contest ? { Contest: { _attr: { id: award.contest.id } } } : {}),
        ...(award.category?.value ? { Category: award.category?.value } : {}),
        ...(award.nomination ? { Nomination: award.nomination } : {}),
        ...convertFileToXML('File', award.file),
        Recipients: award.recipients.length
          ? {
              Recipient: award.recipients.map(recipient => ({
                _attr: recipient.id ? { id: recipient.id } : { scientist: recipient?.person?.id, role: recipient.role?.id },
              })),
            }
          : {},
      })),
    },
  };
}

export function convertReference(ref: any): ReferenceItem {
  if (!ref) {
    return { id: '', label: '' };
  }

  const customFields = Object.fromEntries(
    Object.entries(ref)
      .filter(([key]) => key !== '_attributes')
      .map(([key, field]: [string, any]) => {
        const getConverter = (type: string) => {
          if (['STRING', 'INTEGER', 'DATETIME', 'TEXT', 'DATE', 'DOUBLE'].includes(type)) {
            return getText(field);
          }
          if (['REFERENCE'].includes(type)) {
            return convertReference(field);
          }
          if (['BOOLEAN'].includes(type)) {
            return getText(field) === 'true';
          }
          if (['ENUMERATION'].includes(type)) {
            return { value: getText(field), label: field._attributes.label };
          }
          return '';
        };

        return [key, getConverter(field._attributes?.type)];
      }),
  );

  return {
    id: ref._attributes?.id,
    label: ref._attributes?.label,
    deletable: ref._attributes?.deletable ? ref._attributes.deletable === 'true' : undefined,
    editable: ref._attributes?.editable ? ref._attributes.editable === 'true' : undefined,
    foreignName: ref.foreignName ? getText(ref.foreignName) : null,
    shortName: ref.shortName ? getText(ref.shortName) : null,
    code: ref.code ? getText(ref.code) : '',
    customFields,
  };
}

export function checkIsReferenceItem(arg: any): arg is ReferenceItem {
  return typeof arg === 'object' && arg.label !== undefined && arg.id !== undefined;
}

export function getText<T = string>(item: any): T {
  return item?._text ?? '';
}

export function getBoolean(item: any): boolean {
  return getText(item) === 'true';
}

export function getLabel(item: any): string {
  return (item && item._attributes?.label) || '';
}

export function getId(item: any): string {
  return (item && item._attributes?.id) || '';
}
export function getAttributes(item: any): { id: string; label: string } {
  return (item && item._attributes) || { id: '', label: '' };
}

export function convertMaybeArray<T, U>(maybeArr: T[] | undefined, converter: (item: T) => U): U[] {
  if (!maybeArr) {
    return [];
  }

  return Array.isArray(maybeArr) ? maybeArr.map(converter) : [converter(maybeArr)];
}

type XMLWithPosition = {
  Position?: {
    text?: string;
  };
};

type SortXMLMaybeArrayByPositionArgumentes<T extends XMLWithPosition> = {
  maybeArr?: T[] | T;
  sortDirection?: 'asc' | 'desc';
};

export function sortXMLMaybeArrayByPosition<XMLElementWithPosition extends XMLWithPosition>({
  maybeArr = [],
  sortDirection = 'asc',
}: SortXMLMaybeArrayByPositionArgumentes<XMLElementWithPosition>) {
  if (!maybeArr) {
    return [];
  }

  const arrayToSort = Array.isArray(maybeArr) ? maybeArr : [maybeArr];

  return arrayToSort.sort((a, b) => {
    const aPosition = parseInt(a.Position?.text ?? '0', 10) ?? 0;
    const bPosition = parseInt(b.Position?.text ?? '0', 10) ?? 0;

    if (sortDirection === 'desc') {
      return bPosition - aPosition;
    }

    return aPosition - bPosition;
  });
}

export function convertIdItem(item: any): IdItem {
  return {
    id: item._attributes.id,
    value: getText(item),
  };
}

export function convertItem<T = string>(item: any): Item<T> {
  return {
    label: item?._attributes?.label,
    value: getText(item),
    ...(item?._attributes.position ? { position: parseInt(item?._attributes.position, 10) } : {}),
    ...(item?._attributes.ordinal ? { ordinal: parseInt(item?._attributes.ordinal, 10) } : {}),
  };
}

export const convertSaveResponseXMLElementToOutput = (response: any): ResponseData => ({
  success: response.Response._attributes.success === 'true',
  message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
  id: response.Response?.Id?._text,
});

export function getMaybeField(requestKey: string, value: boolean | string | number | null | undefined | Record<any, any>) {
  return { ...(value ? { [requestKey]: value } : {}) };
}

export const getAttribute = <T = string>({ item, attributeKey }: { item: any; attributeKey: string }): T | undefined => {
  const attributeValue = item?._attributes?.[attributeKey];
  return attributeValue ? (attributeValue as T) : undefined;
};

export const convertTime = (item: any) => {
  const itemText = getText(item);
  return itemText || DEFAULT_TIME;
};

export const convertFile = (file: any) => {
  if (file) {
    return {
      id: file._attributes.id as string,
      name: getText(file),
      size: Number(file._attributes.size || 0),
    } as FileInfo;
  }
  return EMPTY_FILE as FileInfo;
};

export function convertFileToXML(tag: string, file: FileInfo | undefined) {
  if (file && file.id) {
    if (file.id) {
      return { [tag]: { _attr: { id: file.id, name: file.name } } };
    }
  }
  return {};
}

export * from './tableConverters';
export * from './document';
export * from './requestConverters';

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetEmployeesByDepartmentList, GetScientistJobDepartmentList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ScientistJobDepartment() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['scientists', 'scientist-job-department'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Список сотрудников в подразделениях" id="list">
          <DataGrid specification={GetScientistJobDepartmentList} />
        </Tab>
        <Tab title="Количество сотрудников в подразделениях" id="employees">
          <DataGrid specification={GetEmployeesByDepartmentList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

import { Tender } from 'types/models';
import { useController } from './controller';

type Props = {
  customer: Tender.Customer | null;
  onCustomerChange(row: Tender.Customer): void;
};

function TenderCustomerInputs({ customer, onCustomerChange }: Props) {
  const { handleChange, handleCustomerIdChange } = useController({
    customer,
    onCustomerChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={{ id: customer?.customerId || '', label: customer?.fullName || '' }}
            onChange={(option: ReferenceItem | null) => handleCustomerIdChange()(option)}
            settings={{ name: 'RefEnterpriseCustomer', title: 'Список организаций' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Description mode="warning">
        В поле по умолчанию отображается официальный адрес и контакты организации, указанные на вкладке Адрес и контакты в
        карточке организации. Вы можете ввести адрес и контакты, указанные в конкурсной документации. Внимание: адрес и контакты
        для конкурса хранятся только для конкретного конкурса.
      </FormComponent.Description>
      <FormComponent.Line>
        <FormComponent.Field label="Адрес">
          <TextInput value={customer?.address} onChange={handleChange('address')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Контакты">
          <TextInput value={customer?.contacts} onChange={handleChange('contacts')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { TenderCustomerInputs };

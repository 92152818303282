import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Filters } from './filters';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetEmployeesByDepartmentList(): Table.Specification<State> {
  return {
    apiID: 'GetEmployeesByDepartmentList',
    header: {
      firstLevel: {
        title: 'Количество сотрудников в подразделениях',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController(),
  };
}

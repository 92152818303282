import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, Select } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { PublicationType } from 'types/models/Publication/publicationTypes';
import { Item } from 'types/models/common';
// eslint-disable-next-line max-len
import publicationTypes from 'features/Table/specifications/GetConferencePublicationList/LeftPanelForThirdLevel/modalTemplates/publicationTypes';

type Props = F.TemplateProps & {
  eventId?: string;
};

const MagazineAddTemplate: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, relatedTableState, id, name, eventId } = props;
  const [type, setType] = useState<PublicationType | null>(null);
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(ElectronicType.WEB);
  const [title, setTitle] = useState<string | JSX.Element>('');
  const preparedPublicationTypes = useMemo<PublicationType[]>(() => {
    const list: PublicationType[] = [
      publicationTypes.magazineArticle,
      publicationTypes.articleConference,
      publicationTypes.thesis,
    ];

    return list;
  }, []);

  const [publicationType, setPublicationType] = useState(preparedPublicationTypes[0]);

  const onChangePublicationType = (value: string) => {
    const selected = preparedPublicationTypes.find(({ id: itemId }) => itemId === value);
    if (selected) {
      setPublicationType(selected);
    }
  };

  const continueWithSelectedType = () => {
    changeSelectedType(publicationType);
  };

  const electronicPublicationTypes = useMemo<F.ElectronicType[]>(() => [ElectronicType.WEB, ElectronicType.LOCAL], []);

  const onChangeElectronicPublicationType = useCallback(
    (value: string) => {
      const selected = electronicPublicationTypes.find(({ id: itemId }) => itemId === value);
      if (selected) {
        setElectronicPublicationType(selected);
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  const look: F.PublicationFormLook = {
    id,
    apiID: 'GetPublication',
    type: type?.code || '',
    template: type?.template,
    name,
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    initialTypeEdition: 'LOCAL',
    eventId,
    disableAddParticipationAfterSave: true,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      setType(null);
      setElectronicPublicationType(ElectronicType.WEB);
      setIsElectronic(false);
    }
  }, [isOpen]);

  const changeSelectedType = (val: PublicationType) => {
    setType(val);
    if (val.isOnPaperOnly) {
      setIsElectronic(false);
    }
  };

  return (
    <>
      {type ? (
        <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'medium-height']}>
          <Form look={look} onClose={onClose} />
        </Modal>
      ) : (
        <Modal
          title="Добавление публикации"
          isOpen={isOpen}
          onClose={onClose}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: continueWithSelectedType,
              isDisabled: isElectronic && !electronicPublicationType.code,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onClose,
            },
          ]}
          size="medium"
        >
          <FormComponent.Line>
            <FormComponent.Field label="Тип публикации" labelSize="fit">
              <Select
                value={{ value: publicationType.id, label: publicationType.label }}
                options={preparedPublicationTypes.map(x => ({ value: x.id, label: x.label }))}
                onChange={(option: Item) => onChangePublicationType(option.value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          {!!publicationType.text && <FormComponent.Description mode="info">{publicationType.text}</FormComponent.Description>}

          <FormComponent.Line>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
            />
          </FormComponent.Line>

          {isElectronic && (
            <>
              <FormComponent.Line>
                <Select
                  value={{ value: electronicPublicationType.id, label: electronicPublicationType.label }}
                  options={electronicPublicationTypes.map(x => ({
                    value: x.id,
                    label: x.label,
                  }))}
                  onChange={(option: Item) => onChangeElectronicPublicationType(option.value)}
                />
              </FormComponent.Line>
              {electronicPublicationType.code && (
                <FormComponent.Description mode="info">{electronicPublicationType.text}</FormComponent.Description>
              )}
            </>
          )}
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(MagazineAddTemplate);

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;
  const securityDocumentId = selectedRow?.id;

  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const reports = useMemo<Report[]>(() => [Reports.SecurityDocumentAuthors, Reports.RequestPatent], []);
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: securityDocumentId,
      command: 'DeleteSecurityDocument',
      deletedItemPropName: 'ProjectSecurityDocument',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, securityDocumentId, tableStreams.deleteRow]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpModal(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.PATENT_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PATENT_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.PATENT_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.PATENT_DELETE },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        permission: { name: Permits.PATENT_VIEW },
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: tableState.selectedRows.length > 1,
      },
    ],
    [handleAddButtonClick, tableState.selectedRows.length, getReports, handleSetCurrentReport],
  );

  return {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    securityDocumentId,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
  };
}

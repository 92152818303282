import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    row,
    isViewFormOpen,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <modalTemplates.ForeignProjectAddTemplate.Component isOpen={isAddFormOpen} onClose={onCloseAddForm} />

      <modalTemplates.ForeignProjectEditTemplate.Component isOpen={isEditFormOpen} onClose={onCloseEditForm} id={row?.id} />

      <modalTemplates.ForeignProjectViewTemplate.Component isOpen={isViewFormOpen} onClose={onCloseViewForm} id={row?.id} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteWarningOpen}
        onClose={onCloseDeleteWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onSubmitDeleteWarning,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onCloseDeleteWarning,
          },
        ]}
        size="small"
      >
        <>
          Проект № {tableState.selectedRows[0]?.Number}, руководитель: {tableState.selectedRows[0]?.Leader || <em>Не указан</em>}.
          Удалить проект?
        </>
      </Modal>
    </>
  );
};

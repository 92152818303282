import React, { memo } from 'react';

import { BuilderProps } from 'features/BuildReportPopup/models';
import { DefaultReference } from '../DefaultReference';

type Props = BuilderProps & object;

const DepartmentFaculty = ({ parameterName, setParameterValueByName, parameters }: Props) => (
  <DefaultReference
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    referenceName="RefDepartment"
    referenceRelationTableModalTitle='Справочник "Подразделения"'
    referenceFilter={[]}
  />
);

const Component = memo(DepartmentFaculty);

export { Component as DepartmentFaculty };

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isActive',
      label: 'Активные сессии за последние',
      checked: false,
    },
    {
      type: FilterComponentType.slider,
      streamKey: 'intervalValue',
      value: 5,
      label: 'мин',
      params: {
        step: 1,
        min: 5,
        max: 60,
      },
    },
  ]);
});

import { useMemo, useCallback, useLayoutEffect, useState } from 'react';
import { Parameter } from 'types/models';
import { format } from 'date-fns';
import { formatStr } from 'utils/Constants';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const dateParameter = useMemo<Parameter | null>(() => parameters.find(parameter => parameter.name === parameterName) || null, [
    parameters,
    parameterName,
  ]);
  const [date, setDate] = useState<string>('');
  const canBuild = useMemo<boolean>(() => !!dateParameter, [dateParameter]);

  const onChange = useCallback(
    (nextDate: string) => {
      setDate(nextDate);
      setParameterValueByName({ name: parameterName, value: nextDate });
    },
    [setParameterValueByName, parameterName],
  );

  useLayoutEffect(() => {
    const nextDate = format(new Date(), formatStr);
    setDate(nextDate);
    setParameterValueByName({ name: parameterName, value: nextDate });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    dateParameter,
    canBuild,
    onChange,
    date,
  };
};

export default useController;

import React from 'react';
import { Component as ModalTemplate } from './modalTemplate';

import { Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export const Toolbar = React.memo(({ tableState, customState }: Props) => {
  const { scientistId } = customState;
  const { title, buttons, isFormOpen, setIsFormOpen, hirshIndex } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <ModalTemplate
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        title={title}
        scientistId={scientistId}
        hirshIndex={hirshIndex}
      />
    </>
  );
});

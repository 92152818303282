import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

import { makeUseCustomController, State } from './makeUseCustomController';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

export type PaymentFeedlineRequestData = {
  paymentId: string;
  paymentStatus?: AuthorPaymentRequestStatus;
  isDisabledToolbar?: boolean;
};

export function GetAuthorPaymentRequestFeedlineList(
  deps: PaymentFeedlineRequestData,
): Table.Specification<State, PaymentFeedlineRequestData> {
  return {
    apiID: 'GetAuthorPaymentRequestFeedlineList',
    header: {
      firstLevel: {
        title: 'Журнал сообщений',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: deps,
    useCustomController: makeUseCustomController(deps),
  };
}

export function ProjectCodeOverheadGridHelp(): JSX.Element {
  return (
    <>
      <p>Таблица содержит список шифров проектов и проектов без шифров</p>
      <p>Назначение таблицы:</p>

      <ol>
        <li>Оценка планового объёма накладных расходов по оперативным данным проектов</li>
        <li>Распределение шифров проектов по шифрам централизованного фонда накладных расходов</li>
      </ol>

      <div className="warning">
        <span>
          В смете проекта на конкретный год допустимо указать другой, отличный от того, который указан в шифре проекта шифр
          централизованного фонда. Т.е. допустимо вести учёт накладных расходов на разных шифрах, указывая в смете шифра. По
          умолчанию при создании сметы шифр централизованного фонда подставляется тот, который назначен шифру
        </span>
      </div>

      <h3>Особенности отображения информации в столбцах таблицы:</h3>

      <p>
        <strong>% НР, нормативный</strong> — нормативный процент накладных расходов, <strong>указанный в проекте</strong> на
        вкладке <strong>Особенности</strong>
      </p>
      <p>
        <strong>Смета</strong> — «Да», если есть смета за год , указанный в фильтре ГОД. Если в смете не указана статья затрат
        «Накладные расходы», то в сумма накладных расходов по шифру проекта ( а значит, и по проекту) считается равной нулю
      </p>
      <p>
        <strong>Шифр ЦФ</strong> — шифр централизованного фонда, если указан для шифра проекта
      </p>
      <p>
        <strong>Подразделение</strong> — название подразделения в шифре проекта
      </p>
      <p>
        <strong>Источник финансирования шифра</strong> — название источника финансирования шифра проекта
      </p>
      <p>
        <strong>Сумма, план проекта</strong> — плановая сумма из Календарного плана проекта. Для проектов без шифра — это общая
        сумма по всем источникам финансирования
      </p>
      <p>
        <strong>НР, план</strong> — сумма накладных расходов, вычисленная по формуле: Сумма, план проекта*
        <strong>% НР, нормативный</strong>
      </p>
    </>
  );
}

import { useMemo } from 'react';

import { ButtonProps } from 'components';

import { Report } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { PurchaseRequest } from 'types/models/PurchaseRequest';
import { PurchaseRequestContractKind, PurchaseRequestStatus } from 'utils/Enums';

type Props = {
  purchaseRequest: PurchaseRequest | undefined;
  workMode: string;
  onSave(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  onSaveAndContinue(needClose: boolean, onSuccessCb?: (id: string | null) => void): void;
  approvementButtons: ButtonProps[];
};

export function useController(props: Props) {
  const { purchaseRequest, workMode, onSave, onSaveAndContinue, approvementButtons } = props;

  const reports = useMemo<Report[]>(() => {
    const serviceNoteList = {
      [PurchaseRequestContractKind.LESS_100_SERVICES]: Reports.ServiceNotePurchaseUpSomeServices,
      [PurchaseRequestContractKind.LESS_100_PRODUCTS]: Reports.ServiceNotePurchaseUpSomeProducts,
      [PurchaseRequestContractKind.MORE_100_SERVICES_AND_PRODUCTS]: Reports.ServiceNotePurchaseUpSomeProductsServices,
    };

    return [
      ...(purchaseRequest?.contractKind?.value
        ? [serviceNoteList[purchaseRequest?.contractKind?.value as PurchaseRequestContractKind]]
        : []),
    ];
  }, [purchaseRequest]);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const disabled = useMemo(() => {
    return workMode === 'viewMode';
  }, [workMode]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled: disabled,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSaveAndContinue(false),
        isDisabled: disabled,
      },
      ...approvementButtons.map(b => {
        return {
          ...b,
          isHidden: disabled || purchaseRequest?.status?.value !== PurchaseRequestStatus.CONSIDERATION,
        };
      }),
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [disabled, approvementButtons, getReports, handleSetCurrentReport, onSave, onSaveAndContinue, purchaseRequest?.status?.value],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType, FilterComponent } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  return FiltersComponent([
    ...(!customState.fundCardId
      ? [
          {
            type: FilterComponentType.checkbox,
            streamKey: 'isNotLinked',
            label: 'Нет связи с шифром накладных расходов',
            checked: false,
          } as FilterComponent,
        ]
      : []),
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});

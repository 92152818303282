import React, { memo, useMemo } from 'react';

import { ButtonMode, FormComponent, Modal } from 'components';

import { Languages } from 'utils/Constants';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  isOpen: boolean;
  onConfirm: () => void;
  onClose: () => void;
  textLanguageId: string;
  errorText: string;
};

const BibliographicRecordErrorPopup = ({ isOpen, onClose, errorText, textLanguageId, onConfirm }: Props) => {
  const { initialLanguageCode } = useAppDataContext();

  const topText = useMemo(() => {
    const isCyrillic = textLanguageId === initialLanguageCode?.id;
    const currentLanguage = Languages.find(lang => lang.id === textLanguageId);

    const text = `В библиографической записи публикации на ${
      currentLanguage ? currentLanguage.accusative : 'выбранном'
    } языке обнаружены ${isCyrillic ? 'латинские' : 'русские'} символы:`;

    return text;
  }, [textLanguageId, initialLanguageCode]);

  return (
    <Modal
      mode="warning"
      title="Ошибка построения библиографической записи"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Да, соответствует',
          onClick: onConfirm,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Нет',
          onClick: onClose,
        },
      ]}
      size="small"
    >
      <FormComponent.Text>{topText}</FormComponent.Text>
      <FormComponent.Text>
        <strong dangerouslySetInnerHTML={{ __html: errorText }} />
      </FormComponent.Text>
      <FormComponent.Text>Вы уверены, что библиографическая запись соответствует указанной в источнике?</FormComponent.Text>
    </Modal>
  );
};

export const Component = memo(BibliographicRecordErrorPopup);

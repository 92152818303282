import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { SecurityDocumentContractGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.SecurityDocumentContractViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.SecurityDocumentContractEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.SecurityDocumentContractAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            Договор №{tableState.selectedRows[0]?.ContractNumber} ({tableState.selectedRows[0]?.EnterpriseName}) Удалить?
          </>
        </Modal>
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />

      <Modal
        mode="help"
        title="Договоры о распоряжении интеллектуальным правом"
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        size={['large', 'medium-height']}
      >
        {SecurityDocumentContractGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

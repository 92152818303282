import React, { useState, useMemo, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Modal, FormComponent, ButtonMode, RelationTableModal } from 'components';

import { showNotification } from 'features/Notifications';
import { GetSalarySourceCodeList } from 'features/Table/specifications';
import { ChangeSalarySourceCodeProps } from 'services/BackendAPI/configurations/salary';
import { AcceptSalaryProjectScientistAnswerType } from 'services/BackendAPI/configurations/salary/converters';
import { Entry } from 'types/models/Table';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SalaryStatus } from 'utils/Enums';
import { SalaryType } from '../../GetSalarySourceCodeList/types';
import { ConfirmModal } from './ConfirmModal';
import { getRowsByStatus } from '../helpers';

export type SalaryChangeSourceCodePropsLocal = ChangeSalarySourceCodeProps & { sourceCode: string; sourceProjectNumber: string };
export type SalaryChangeMode = 'project' | 'fundCard';

type Props = {
  isSalaryChangeOpen: boolean;
  setIsSalaryChangeOpen: React.Dispatch<React.SetStateAction<boolean>>;
  selectedRows: Entry[];
  salaryJobPaymentIds?: string;
  onSalaryChange?(): void;
  mode?: SalaryChangeMode;
};

export function SalaryChangeModals({
  isSalaryChangeOpen,
  setIsSalaryChangeOpen,
  selectedRows,
  salaryJobPaymentIds,
  onSalaryChange,
  mode,
}: Props) {
  const tableStreams = useLocalTableStreams();

  const [isSalaryChangeConfirmOpen, setIsSalaryChangeConfirmOpen] = useState<boolean>(false);
  const [changeSalarySourceCodeProps, setChangeSalarySourceCodeProps] = useState<SalaryChangeSourceCodePropsLocal | null>(null);
  const [isConfirmOpen, setIsConfirmOpen] = useState<boolean>(false);
  const [confirmContent, setConfirmContent] = useState<AcceptSalaryProjectScientistAnswerType[]>([]);

  const { methods: changeSalarySourceCode } = BackendAPI.useBackendAPI('ChangeSalarySourceCode');

  const approvedRows = useMemo(() => getRowsByStatus(selectedRows, SalaryStatus.APPROVED), [selectedRows]);
  const revisionRows = useMemo(() => getRowsByStatus(selectedRows, SalaryStatus.REVISION), [selectedRows]);

  const handleChangeSalary = useCallback(
    (row: Entry) => {
      if (!row) {
        showNotification({ message: 'Выберите запись', theme: 'danger' });
        return;
      }
      const requestProps = {
        source: { type: row.Type, id: row.id },
        rows: selectedRows.map(x => ({
          salaryId: x.SalaryId,
          code: x.Code,
          salaryJobPaymentIds: salaryJobPaymentIds || x.SalaryJobPaymentIds,
          projectCodeId: x.ProjectCodeId,
          fundCardId: x.FundCardId,
        })),
        sourceCode: row.Code,
        sourceProjectNumber: row.ProjectNumber,
      };
      setChangeSalarySourceCodeProps(requestProps);
      setIsSalaryChangeConfirmOpen(true);
    },
    [salaryJobPaymentIds, selectedRows],
  );

  const changeSalaryConfirm = useCallback(() => {
    if (!changeSalarySourceCodeProps) return;
    changeSalarySourceCode.callAPI(changeSalarySourceCodeProps, {
      onSuccessfullCall: ({ data }) => {
        setIsSalaryChangeConfirmOpen(false);
        setIsSalaryChangeOpen(false);
        if (data.length) {
          showNotification({ message: 'Шифры перенесены не во всех записях', theme: 'danger' });
          setConfirmContent(data as AcceptSalaryProjectScientistAnswerType[]);
          setIsConfirmOpen(true);
        } else {
          showNotification({ message: 'Все шифры перенесены', theme: 'success' });
        }
        tableStreams.reloadTable.push({});
        onSalaryChange?.();
      },
    });
  }, [changeSalarySourceCode, changeSalarySourceCodeProps, onSalaryChange, setIsSalaryChangeOpen, tableStreams.reloadTable]);

  const renderProjects = useCallback(
    (rows: Entry[]) => (
      <ul>
        {rows.map((x, index) => (
          <li key={index}>
            Проект № <strong>{x.ProjectNumber}</strong> ({x.ProjectStartDate} - {x.ProjectEndDate})
          </li>
        ))}
      </ul>
    ),

    [],
  );

  return (
    <>
      <RelationTableModal
        isOpen={isSalaryChangeOpen}
        modalTitle="Перенести данные по зарплате на другой шифр"
        onClose={() => setIsSalaryChangeOpen(false)}
        specification={GetSalarySourceCodeList({
          onSubmitTable: ({ selectedRows: rows }) => handleChangeSalary(rows[0]),
        })}
      />

      <ConfirmModal isConfirmOpen={isConfirmOpen} setIsConfirmOpen={setIsConfirmOpen} confirmContent={confirmContent} />

      <Modal
        title={`Перенос на шифр "${changeSalarySourceCodeProps?.sourceCode}"`}
        isOpen={isSalaryChangeConfirmOpen}
        onClose={() => setIsSalaryChangeConfirmOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Перенести',
            onClick: changeSalaryConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsSalaryChangeConfirmOpen(false),
          },
        ]}
        size="medium"
      >
        <>
          {mode === 'project' && (
            <>
              {!!revisionRows.length && changeSalarySourceCodeProps?.source.type === SalaryType.PROJECT_CODE && (
                <>
                  <FormComponent.Field>
                    На шифр проекта <strong>{changeSalarySourceCodeProps.sourceCode}</strong> будут перенесены записи с шифров
                    проектов:
                  </FormComponent.Field>
                  {renderProjects(revisionRows)}
                  <FormComponent.Field>Статус: «На согласование»</FormComponent.Field>
                </>
              )}
              {!!approvedRows.length && changeSalarySourceCodeProps?.source.type === SalaryType.PROJECT_CODE && (
                <>
                  <FormComponent.Field>
                    На шифр проекта <strong>{changeSalarySourceCodeProps.sourceCode}</strong> будут перенесены записи с шифров
                    проектов:
                  </FormComponent.Field>
                  {renderProjects(revisionRows)}
                  <FormComponent.Field>
                    Участники коллектива (кроме руководителя проекта) будут перенесены в состав проекта №{' '}
                    <strong>{changeSalarySourceCodeProps.sourceProjectNumber}</strong>
                  </FormComponent.Field>
                  <FormComponent.Field>Статус: «Утверждена»</FormComponent.Field>
                </>
              )}
              {!!revisionRows.length && changeSalarySourceCodeProps?.source.type === SalaryType.FUND_CARD && (
                <>
                  <FormComponent.Field>
                    На шифр ЦФ <strong>{changeSalarySourceCodeProps.sourceCode}</strong> будут перенесены записи:
                  </FormComponent.Field>
                  {renderProjects(revisionRows)}
                  <FormComponent.Field>Записи будут отображаться в таблице на вкладке «Оплата из накладных»</FormComponent.Field>
                </>
              )}
              {!!approvedRows.length && changeSalarySourceCodeProps?.source.type === SalaryType.FUND_CARD && (
                <>
                  <FormComponent.Field>
                    На шифр ЦФ <strong>{changeSalarySourceCodeProps.sourceCode}</strong> будут перенесены записи:
                  </FormComponent.Field>
                  {renderProjects(revisionRows)}
                  <FormComponent.Field>Записи будут отображаться в таблице на вкладке «Оплата из накладных»</FormComponent.Field>
                  <FormComponent.Field>
                    Участники коллектива (кроме руководителя проекта) будут удалены из состава проекта
                  </FormComponent.Field>
                </>
              )}
            </>
          )}

          {mode === 'fundCard' && (
            <>
              {changeSalarySourceCodeProps?.source.type === SalaryType.FUND_CARD && (
                <>
                  <FormComponent.Field>
                    Записи будут перенесены на шифр ЦФ <strong>{changeSalarySourceCodeProps.sourceCode}</strong>
                  </FormComponent.Field>
                </>
              )}
              {changeSalarySourceCodeProps?.source.type === SalaryType.PROJECT_CODE && (
                <>
                  <FormComponent.Field>
                    Записи будут перенесены на шифр проекта <strong>{changeSalarySourceCodeProps.sourceCode}</strong>
                  </FormComponent.Field>
                  <FormComponent.Field>
                    В состав коллектива проекта № <strong>{changeSalarySourceCodeProps.sourceProjectNumber}</strong> добавлен
                    исполнитель
                  </FormComponent.Field>
                  <FormComponent.Field>
                    Посмотреть состав коллектива можно на вкладке Список участников проекта или в проекте на вкладке «Коллектив»
                  </FormComponent.Field>
                  <FormComponent.Field>Запись будет отображаться на вкладке «Оплата по шифрам проектов»</FormComponent.Field>
                </>
              )}
            </>
          )}
        </>
      </Modal>
    </>
  );
}

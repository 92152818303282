import React from 'react';

import {
  ButtonMode,
  Checkbox,
  FormComponent,
  Modal,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
} from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Item } from 'types/models/common';

import { EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';

import { SelectProgram } from '../SelectProgram';
import { SelectProgramEvent } from '../SelectProgramEvent';

import { useController } from './controller';
import { getEnumItemLabel, getPersonContacts } from 'utils/Helpers';

type Props = {
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  disabled: boolean;
};

export function TaskNirFields(props: Props) {
  const { project, makeChangeHandler, setProject, disabled, indicators, indices } = props;

  const {
    enumMap,
    leader,
    responsiblePerformer,
    isChangeProgramWarningOpen,
    changeProgramWarningText,
    changeProgramWarningTitle,
    handleChangeProgram,
    onCloseChangeProgramWarning,
    onFinancingStoppedChange,
  } = useController({
    project,
    setProject,
    indicators,
    indices,
    makeChangeHandler,
  });

  return (
    <>
      <SectionTitle title={`Проект типа: ${getEnumItemLabel('ProjectType', project?.type?.value || 'NIR', enumMap)}`} />

      <FormComponent.Line>
        <FormComponent.Field
          label="Программа"
          info={
            <p>
              Дополнительные аналитические характеристики Заказчика, часто используются для управленческой отчётности, анализа
              данных о проекте (примеры программ: РНФ, Приоритет-2030, ..).
            </p>
          }
        >
          <SelectProgram program={project?.program ?? null} onChange={handleChangeProgram} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Мероприятие/Вид конкурса">
          <SelectProgramEvent
            programId={project?.program?.id ?? null}
            programEvent={project?.programEvent ?? null}
            onChange={makeChangeHandler('programEvent')}
            isDisabled={disabled || !project?.program}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Тема проекта">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Тема проекта' }}
            value={project?.name ?? ''}
            onChange={makeChangeHandler('name')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          label="Номер проекта"
          info={
            <>
              <p>Номер, который присваивается в соответствии с внутренним регламентом учётной политике по проектам.</p>
              <p>
                Дополнительный атрибут «Код» поможет группировать номера проектов по какому-либо признаку (например, условное
                обозначение для обособленных подразделений университета).
              </p>
            </>
          }
        >
          <TextInput value={project?.number ?? ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Код">
          <Select
            mode={SelectMode.REFERENCE}
            value={project?.numberPrefix}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('numberPrefix')(option)}
            settings={{
              name: 'RefProjectRequestNumberPrefix',
              title: 'Справочник "Префикс для шифра заявок и номера проектов"',
              isClearable: true,
            }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Сроки проекта"
          info={
            <p>
              Обязательное поле и является ключевым для других разделов. Поэтому любые изменения в датах проекта система будет
              фиксировать <strong>в Журнале изменений проекта</strong>.
            </p>
          }
          isRequired
        >
          <span>
            <TextDateTime
              value={project?.startDate || ''}
              onChange={(value: string) => setProject({ ...project, startDate: value } as Project.Project)}
              maxValue={project?.endDate || ''}
              isDisabled={disabled}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={project?.endDate || ''}
              onChange={(value: string) => setProject({ ...project, endDate: value } as Project.Project)}
              minValue={project?.startDate || ''}
              isDisabled={disabled}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Спецтема">
          <Checkbox checked={!!project?.isSpecTheme} onChange={makeChangeHandler('isSpecTheme')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Прекращено фин.">
          <Checkbox checked={!!project?.isFinancingStopped} onChange={onFinancingStoppedChange} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>

      {!!project?.isFinancingStopped && (
        <FormComponent.Line>
          <FormComponent.Field label="Прекращено фин. от" isRequired>
            <TextDateTime value={project?.stopMoneyDate} onChange={makeChangeHandler('stopMoneyDate')} isDisabled={disabled} />
          </FormComponent.Field>
          <FormComponent.Field label="Причина" isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={project?.stopMoneyReason}
              settings={{ name: 'StopMoneyReason' }}
              onChange={(option: Item) => makeChangeHandler('stopMoneyReason')(option as ValueOf<Project.Project>)}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={project?.periodNote ?? ''}
            onChange={makeChangeHandler('periodNote')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field
          label="Руководитель"
          info={
            <p>
              Сведения о руководителе и ответственном исполнителе (администратор проекта) лишь отображаются на этой вкладке.
              Добавить информацию необходимо <strong>на вкладке Коллектив</strong>.
            </p>
          }
          isRequired
        >
          {leader?.fio ? (
            <div>{`${`${leader.fio}/`} ${getPersonContacts(leader.person?.contacts)}`}</div>
          ) : (
            <div className="color-placeholder">Заполняется на вкладке Коллектив</div>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Отв. исполнитель">
          {responsiblePerformer?.fio ? (
            <div>{`${`${responsiblePerformer.fio}/`}${getPersonContacts(responsiblePerformer.person?.contacts)}`}</div>
          ) : (
            <div className="color-placeholder">Заполняется на вкладке Коллектив</div>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <Modal
        mode="info"
        title={changeProgramWarningTitle}
        isOpen={isChangeProgramWarningOpen}
        onClose={onCloseChangeProgramWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: onCloseChangeProgramWarning,
          },
        ]}
        size="small"
      >
        <>{changeProgramWarningText}</>
      </Modal>
    </>
  );
}

import { Table } from 'types/models';

export const GetSecurityDocumentContractListForProject: Table.Specification = {
  apiID: 'GetSecurityDocumentContractListForProject',
  header: {
    thirdLevel: {
      hasSelectButton: true,
    },
  },
};

import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export type DetailProps = {
  personId: string;
  isViewMode?: boolean;
};

type RequestData = {
  scientistId: string;
};

export function GetScientistParticipationList({ personId, isViewMode }: DetailProps): T.Specification<State, RequestData> {
  return {
    apiID: 'GetScientistParticipationList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Участия в мероприятиях',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData: { scientistId: personId },
    useCustomController: makeUseCustomController({ isViewMode }),
  };
}

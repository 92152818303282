import React, { useCallback, useEffect, useRef, useState } from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-wrapper');
const s = block('shadows');

type Props = {
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {React.ReactNode | JSX.Element | string | null | false |
   *    (React.ReactNode | JSX.Element | string | null | false)[]}
   *  children - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | string | null | false | (React.ReactNode | JSX.Element | string | null | false)[];
};

/**
 * Wrapper of content (full size of space with scroll overflow)
 *
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {React.ReactNode | JSX.Element | string | null | false | (React.ReactNode | JSX.Element | string | null | false)[]}
 *  children - (optional) React inner JSX component
 * @returns JSX
 */
export const Wrapper = React.memo(({ style, classMixin, children }: Props) => {
  const OFFSET_SHADOW = 20;

  const elRef = useRef<HTMLDivElement | null>(null);
  const [isVisibleTopShadow, setIsVisibleTopShadow] = useState<boolean>(false);
  const [isVisibleBottomShadow, setIsVisibleBottomShadow] = useState<boolean>(false);

  const onScroll = useCallback(() => {
    const el: HTMLDivElement | null = elRef.current;

    if (!el) {
      return;
    }

    setIsVisibleTopShadow(el.scrollTop > OFFSET_SHADOW);
    setIsVisibleBottomShadow(el.scrollHeight - el.offsetHeight - el.scrollTop > OFFSET_SHADOW);
  }, []);

  const resizeObserver = new ResizeObserver(() => onScroll());

  useEffect(() => {
    if (elRef.current) {
      resizeObserver.observe(elRef.current);
    }
    return function () {
      if (elRef.current) {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        resizeObserver.unobserve(elRef.current);
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    onScroll();
  }, [onScroll, children, elRef.current?.scrollHeight]);

  return (
    <div className={b({}).mix(classMixin)} style={style}>
      <div className={s('top').mix(isVisibleTopShadow ? 'visible' : '')}></div>
      <div ref={elRef} className={s()} onScroll={onScroll}>
        {children}
      </div>
      <div className={s('bottom').mix(isVisibleBottomShadow ? 'visible' : '')}></div>
    </div>
  );
});

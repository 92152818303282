import React from 'react';

import { ListEditTable } from 'components';
import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';

import useController from './controller';

type Props = {
  financingsByYear: Project.FinancingByYear[];
  stages: Project.Stage[];
};

export function FinancingsByYear(props: Props) {
  const { financingsByYear, stages: projectStages } = props;

  const { settings } = useAppDataContext();

  const { selectedRowIndex, setSelectedRowIndex, ndsPercentFormatter, ndsAmountFormatter } = useController({
    stages: projectStages,
  });

  return (
    <ListEditTable
      rows={financingsByYear}
      defaultRowsCount={3}
      selectRow={setSelectedRowIndex}
      selectedRowIndex={selectedRowIndex}
      columnIndexesForSumTotal={[2, 3, 4, 5, 6, 8]}
      columns={[
        { label: 'Год', formatValue: x => x.year, styles: { width: '50px' } },
        {
          label: 'Этапы',
          formatValue: x => x.stages.sort((a, b) => Number(a) - Number(b)).join(', '),
          styles: { width: '18%', textAlign: 'center' },
        },
        {
          label: 'ОИФ',
          formatValue: x => x.amountMain,
          styles: { width: '12%' },
          dataKind: 'float',
        },
        {
          label: 'СФ1',
          formatValue: x => x.amountCofinancing1,
          styles: { width: '12%' },
          dataKind: 'float',
        },
        {
          label: 'СФ2',
          formatValue: x => x.amountCofinancing2,
          styles: { width: '10%' },
          dataKind: 'float',
        },
        {
          label: settings?.organization?.shortName || '',
          formatValue: x => x.amountLocal,
          styles: { width: '10%' },
          dataKind: 'float',
        },
        {
          label: 'Партнёр',
          formatValue: x => x.amountAccomplice,
          styles: { width: '10%' },
          dataKind: 'float',
        },
        {
          label: 'НДС, %',
          formatValue: ndsPercentFormatter,
          styles: { width: '10%' },
          dataKind: 'float',
        },
        {
          label: 'НДС, руб.',
          formatValue: ndsAmountFormatter,
          styles: { width: '10%' },
          dataKind: 'float',
        },
      ]}
    />
  );
}

import React, { useState, useMemo, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, FormComponent, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';
import { DataGrid } from 'features/Table/container';
import { GetSalaryJobPaymentList } from 'features/Table/specifications';
import { AcceptSalaryProjectScientistType } from 'services/BackendAPI/configurations/salary';
import { AcceptSalaryProjectScientistAnswerType } from 'services/BackendAPI/configurations/salary/converters';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SalaryStatus } from 'utils/Enums';
import { ConfirmModal } from '../views/ConfirmModal';
import { SalaryChangeModals } from '../views/SalaryChangeModals';
import { getRowsByStatus } from '../helpers';
import { Entry } from 'types/models/Table';
import { SalaryByProjectGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const [selectedRow] = selectedRows;

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isAcceptOpen, setIsAcceptOpen] = useState(false);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmContent, setConfirmContent] = useState<AcceptSalaryProjectScientistAnswerType[]>([]);
  const [isSalaryChangeOpen, setIsSalaryChangeOpen] = useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [selectedSalaryRow, setSelectedSalaryRow] = useState<Entry>();

  const { methods: AcceptSalaryProjectScientist } = BackendAPI.useBackendAPI('AcceptSalaryProjectScientist');

  const groupedProjects = useMemo<Record<string, number>>(() => {
    const result: Record<string, number> = {};
    selectedRows.forEach(i => (i.ProjectNumber in result ? result[i.ProjectNumber]++ : (result[i.ProjectNumber] = 1)));
    return result;
  }, [selectedRows]);

  const approvedRows = useMemo(() => getRowsByStatus(selectedRows, SalaryStatus.APPROVED), [selectedRows]);
  const revisionRows = useMemo(() => getRowsByStatus(selectedRows, SalaryStatus.REVISION), [selectedRows]);

  const handleAccept = useCallback(() => {
    if (selectedRows.length) {
      const params: AcceptSalaryProjectScientistType[] = selectedRows.map(i => ({
        salaryId: i.SalaryId,
        salaryJobIds: i.SalaryJobIds,
        code: i.Code,
        projectCodeId: i.ProjectCodeId,
        fundCardId: i.FundCardId,
      }));

      AcceptSalaryProjectScientist.callAPI(params, {
        onSuccessfullCall: ({ data }) => {
          setIsAcceptOpen(false);
          if (data.length) {
            showNotification({ message: 'Не все записи подтверждены', theme: 'danger' });
            setConfirmContent(data as AcceptSalaryProjectScientistAnswerType[]);
            setIsConfirmOpen(true);
          } else {
            showNotification({ message: 'Все записи подтверждены', theme: 'success' });
          }
          tableStreams.reloadTable.push({});
        },
      });
    }
  }, [selectedRows, AcceptSalaryProjectScientist, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          setSelectedSalaryRow(selectedRow);
          setIsViewOpen(true);
        },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'like' },
        title: 'Подтвердить в составе коллектива проекта',
        onClick: () => setIsAcceptOpen(true),
        isDisabled: !selectedRows.length,
      },
      {
        icon: { type: 'transport' },
        title: 'Перенести на другой шифр',
        onClick: () => setIsSalaryChangeOpen(true),
        isDisabled: (!!approvedRows.length && !!revisionRows.length) || (!approvedRows.length && !revisionRows.length),
      },
    ],
    [approvedRows.length, revisionRows.length, selectedRow, selectedRows.length],
  );

  const onViewModalClose = useCallback(() => {
    setIsViewOpen(false);
    if (isReloadNeeded) {
      tableStreams.reloadTable.push({});
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded, tableStreams.reloadTable]);

  const title = useMemo(
    () =>
      [
        `${[selectedRow?.Fio, selectedRow?.Role].filter(Boolean).join(': ')},`,
        'в проекте',
        `${[selectedRow?.ProjectNumber, selectedRow?.ProjectJob].filter(Boolean).join('; ')}.`,
        'Оплачено по источнику',
        selectedRow?.Code,
      ]
        .filter(Boolean)
        .join(' '),
    [selectedRow],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!selectedSalaryRow && (
        <Modal title={title} isOpen={isViewOpen} onClose={onViewModalClose} size={['extralarge', 'large-height']}>
          <DataGrid
            specification={GetSalaryJobPaymentList({
              salaryId: selectedSalaryRow?.SalaryId,
              code: selectedSalaryRow?.Code,
              projectCodeId: customState.projectCodeId,
              seletedSalaryRow: selectedSalaryRow,
              onSalaryChange: () => setIsReloadNeeded(true),
              salaryChangeMode: 'project',
            })}
          />
        </Modal>
      )}

      <Modal
        title="Подтвердить в составе коллектива проекта"
        isOpen={isAcceptOpen}
        onClose={() => setIsAcceptOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Отправить',
            onClick: handleAccept,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsAcceptOpen(false),
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <>
            {Object.keys(groupedProjects).map((i, index) => (
              <FormComponent.Field key={index}>
                Проект № <strong>{i}</strong>: выделено записей <strong>{groupedProjects[i]}</strong>
              </FormComponent.Field>
            ))}
          </>
        </FormComponent.Line>
      </Modal>

      <SalaryChangeModals
        isSalaryChangeOpen={isSalaryChangeOpen}
        setIsSalaryChangeOpen={setIsSalaryChangeOpen}
        selectedRows={selectedRows}
        mode="fundCard"
      />

      <ConfirmModal isConfirmOpen={isConfirmOpen} setIsConfirmOpen={setIsConfirmOpen} confirmContent={confirmContent} />

      <Modal mode="help" title="Оплата по шифрам проектов" isOpen={isHelpOpen} onClose={() => setIsHelpOpen(false)} size="large">
        {SalaryByProjectGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

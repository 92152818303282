import { useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Parameter } from 'types/models';
import { CheckboxItem } from 'features/BuildReportPopup/models';

type CustomFields = {
  code: string;
  genetiveAppointment: string;
  genetiveName: string;
  nominativeAppointment: string;
  nominativeName: string;
};

const useController = (parameters: Parameter[], parameterName: string) => {
  const [list, setList] = useState<CustomFields[]>([]);

  const param: Parameter | null = parameters.find(i => i.name === parameterName) || null;

  if (!param) {
    return {
      checkboxList: [],
    };
  }

  const initList: string[] = (param.initData || '').split(',').filter(Boolean);

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  useEffect(() => {
    loadReferenceAPI.callAPI(
      { filters: [], referenceName: 'RefVisa' },
      {
        onSuccessfullCall: ({ data }) => {
          const fields: CustomFields[] = [];
          data.forEach(i => {
            if (i.customFields?.code && initList.includes(i.customFields.code.toString())) {
              return fields.push(i.customFields as CustomFields);
            }
          });
          setList(fields);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkboxList: CheckboxItem[] = useMemo(() => {
    return list.map(i => ({
      value: i.code,
      label: [i.nominativeAppointment, i.nominativeName].join(' '),
      isChecked: false,
    }));
  }, [list]);

  return {
    checkboxList,
  };
};

export default useController;

import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ReportByDepartmentMappingPublicationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список публикаций Scopus, WoS по факультетам</h4>

        <p>
          Форма <strong>Список публикаций Scopus, WoS по факультетам</strong> содержит список публикаций сотрудников, студентов и
          аспирантов Университета, цитируемых в базах Scopus, WoS по факультетам.
        </p>
        <p>Связь с факультетом/институтом для публикации определяется по алгоритму:</p>

        <ul>
          <li>если публикация связана с факультетом/институтом;</li>
          <li>
            если публикация связана с подразделением; а для подразделения указана связь с факультетом/институтом в специальном
            справочнике "Структура по факультетам/институтам для отчётности".
          </li>
        </ul>

        <p>Описание столбцов таблицы на форме представлено в таблице.</p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ID</td>
              <td>ID публикации в базе данных системы</td>
            </tr>
            <tr>
              <td>Факультет/институт</td>
              <td>
                Название факультета, связанного, указанного на форме <strong>Связь с подразделениями</strong>
              </td>
            </tr>
            <tr>
              <td>Библ. запись</td>
              <td>
                Библиографическая запись публикации (на вкладке <strong>Сведения об издании</strong>)
              </td>
            </tr>
            <tr>
              <td>Подразделение</td>
              <td>
                Список подразделений, указанных на форме <strong>Связь с подразделениями</strong>
              </td>
            </tr>
            <tr>
              <td>Индексируется</td>
              <td>
                Список названия баз цитирования публикаций. У которых год издания публикации пересекается с периодом индексации в
                базе
              </td>
            </tr>
            <tr>
              <td>Статус публикации</td>
              <td>
                <strong>Добавлена</strong> или <strong>Утверждена</strong>
              </td>
            </tr>
            <tr>
              <td>Год публикации</td>
              <td>Год публикации = Год выпуска журнала</td>
            </tr>
          </tbody>
        </table>

        <p>Таблица содержит исходные данные для построения отчёта "Публикации Scopus, WoS по факультетам/институтам"</p>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

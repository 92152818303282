import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar, getElementAtEvent } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { Modal, FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { DataGrid } from 'features/Table';
import { GetDashboardChartTotalPublicationList } from 'features/Table/specifications/GetDashboardChartTotalPublicationList';
import { COLORS, COLORS_BORDERS } from '../сolors';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationSummary() {
  const {
    setActualDepartment,
    actualDepartment,
    categories,
    rusAndOtherByPeriod,
    seriesClickHandler,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    actualYear,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value } = context;
      return ` Публикаций: ${value} шт.`;
    };

    const data = {
      labels: categories,
      datasets: [
        {
          data: rusAndOtherByPeriod[0].data,
          backgroundColor: COLORS,
          borderColor: COLORS_BORDERS,
          borderWidth: 2,
        },
      ],
    };

    return (
      <Bar
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Год издания',
              },
            },
            y: {
              beginAtZero: true,
              min: 0,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              display: false,
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
        onClick={(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
          const element = getElementAtEvent(chartRef.current!, event)[0];
          if (element) {
            seriesClickHandler(categories[element.index]);
          }
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Общее количество публикаций</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение" hasLeftAlign>
              <Select
                mode={SelectMode.REFERENCE}
                value={actualDepartment}
                onChange={(option: ReferenceItem | null) => setActualDepartment(option)}
                settings={{ name: 'RefDepartment', title: 'Справочник "Подразеления"', isClearable: true }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '260px' }}>
          {Chart()}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={getModalTitle}
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetDashboardChartTotalPublicationList({
            department: actualDepartment ? actualDepartment.id : '-1',
            year: actualYear,
          })}
        />
      </Modal>
    </>
  );
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'isCurrent',
      list: [
        { label: 'Все', value: 'false' },
        { label: 'За текущий год', value: 'true' },
      ],
      value: 'true',
    },
  ]);
});

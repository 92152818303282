import React, { useCallback, useMemo } from 'react';

import { SecurityDocument, SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Department } from 'types/models/Project';
import { getDepartmentFromMember } from '../../helpers';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
};

export function useController(props: Props) {
  const { setSecurityDocument, securityDocument } = props;

  const handleAuthorsChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setSecurityDocument(prevState => {
        const newMember = value.find(
          member => !prevState.members.find(prevMember => prevMember?.person?.id === member.person?.id),
        );
        const newDepartment: Department | null = newMember ? getDepartmentFromMember(newMember) : null;
        return {
          ...prevState,
          members: value,
          ...(newDepartment ? { departments: [...prevState.departments, newDepartment] } : null),
        };
      });
    },
    [setSecurityDocument],
  );
  const handleApplicantChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.APPLICANT) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setSecurityDocument],
  );

  const authorsList = useMemo(
    () => securityDocument?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [],
    [securityDocument],
  );

  const applicantPersonList = useMemo(
    () => securityDocument?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.APPLICANT) || [],
    [securityDocument],
  );

  const leftColumnWidth = 'calc(100% - 690px)';
  const rightColumnWidth = '690px';

  return {
    handleApplicantChange,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    applicantPersonList,
    handleAuthorsChange,
  };
}

import React from 'react';

import { FormComponent, Select, SelectMode, ReferenceItem } from 'components';

import { Program } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  customer: Program.Customer | null;
  onCustomerChange(row: Program.Customer): void;
};

function CustomerInputs({ customer, onCustomerChange }: Props) {
  const handleChange = (fieldName: keyof Program.Customer) => (value: ValueOf<Program.Customer>) => {
    onCustomerChange({ ...customer, [fieldName]: value } as Program.Customer);
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={customer?.refCustomer}
            onChange={(option: ReferenceItem | null) => handleChange('refCustomer')(option)}
            settings={{ name: 'RefEnterpriseCustomer', title: 'Организация' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип источника финансирования">
          <Select
            mode={SelectMode.REFERENCE}
            value={customer?.investmentSourceType}
            onChange={(option: ReferenceItem | null) => handleChange('investmentSourceType')(option)}
            settings={{ name: 'RefTypeInvestmentSource', title: 'Тип источника финансирования', isClearable: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { CustomerInputs };

import { useCallback } from 'react';

import { MobileRequest } from 'types/models';
import { MobileKindConsumption } from 'types/models/KindConsumption';

type Props = {
  entry: MobileRequest.MobileRequestEstimatePosition | null;
  onFieldChange(row: MobileRequest.MobileRequestEstimatePosition | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleKindConsumptionChange = useCallback(
    (value: MobileKindConsumption | null) => {
      onFieldChange({ ...entry, kindConsumption: value } as MobileRequest.MobileRequestEstimatePosition);
    },
    [entry, onFieldChange],
  );

  const handleAmountChange = useCallback(
    (value: string) => {
      onFieldChange({ ...entry, amount: value } as MobileRequest.MobileRequestEstimatePosition);
    },
    [entry, onFieldChange],
  );

  const handleNoteChange = useCallback(
    (value: string) => {
      onFieldChange({ ...entry, note: value } as MobileRequest.MobileRequestEstimatePosition);
    },
    [entry, onFieldChange],
  );

  return {
    handleKindConsumptionChange,
    handleAmountChange,
    handleNoteChange,
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

export function GetLicenceAgreementContractList(): Table.Specification {
  return {
    apiID: 'GetLicenceAgreementContractList',
    header: {
      firstLevel: {
        title: 'План - график платежей по договорам',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

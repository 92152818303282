import { Table as T } from 'types/models';

type Arguments = {
  scientistId: string;
  workMode: T.WorkMode;
  isFiveYear: boolean;
};

type RequestData = {
  scientistId: string;
  isFiveYear: boolean;
};

export function GetImpactFactorList({ scientistId, workMode, isFiveYear }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetImpactFactorList',
    workMode,
    requestData: { scientistId, isFiveYear },
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}

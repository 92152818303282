import React, { memo } from 'react';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table as T } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { RoleFormTemplate } from '../RoleFormTemplate';

type Props = {
  tableState: T.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { userId } = customState;

  const {
    buttons,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleCloseRoleForm,
    isRoleFormOpen,
    submitCallback,
  } = useController({
    userId,
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <RoleFormTemplate isOpen={isRoleFormOpen} onClose={handleCloseRoleForm} onSubmitTable={submitCallback} />

      {userId && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>Удалить роль "{tableState.selectedRows[0]?.name}"?</>
        </Modal>
      )}
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);

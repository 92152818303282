import { useCallback, useMemo, useState } from 'react';

import { ReferenceItem } from 'components';

import { Parameter } from 'types/models';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;
const useController = ({ parameters, parameterName, setParameterValueByName }: Props) => {
  const referenceParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );

  const canBuild = useMemo<boolean>(() => !!referenceParameter, [referenceParameter]);

  const [referenceItem, setReferenceItem] = useState<ReferenceItem | null>(null);

  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      setReferenceItem(nextReferenceItem);
      setParameterValueByName({ name: parameterName, value: nextReferenceItem });
    },
    [parameterName, setParameterValueByName],
  );

  return {
    referenceParameter,
    canBuild,
    handleSetReferenceItem,
    referenceItem,
  };
};

export default useController;

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import {
  GetAnalyticProjectList,
  GetExpenseDocumentItemList,
  GetPaymentOrderList,
  GetProjectCodeList,
} from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ProjectCodes() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['finance', 'project-codes'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Шифры проектов" id="list">
          <DataGrid specification={GetProjectCodeList} />
        </Tab>
        <Tab title="Проекты" id="projects">
          <DataGrid specification={GetAnalyticProjectList} />
        </Tab>
        <Tab title="Зачислено на шифры" id="payments">
          <DataGrid specification={GetPaymentOrderList()} />
        </Tab>
        <Tab title="Расходы по шифрам" id="expenses">
          <DataGrid specification={GetExpenseDocumentItemList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

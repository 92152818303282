import React from 'react';

import { Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetFullUserList, GetRoleList, GetUserSessions, GetLockedEntityList } from 'features/Table/specifications';
import { Component as UserActivation } from './Tabs/UserActivation';
import { Component as Notifications } from './Tabs/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { useRouteNavigation } from 'utils/Common';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

export function Administration() {
  const { initialOrganizationCode, userPermission } = useAppDataContext();

  const { url, changeUrl } = useRouteNavigation({
    prefix: ['administration'],
    init: ['roles'],
  });

  return (
    <Tabs id="administration" isStrip defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
      <Tab title="Роли и права" id="roles">
        <DataGrid specification={GetRoleList({})} />
      </Tab>
      <Tab title="Управление пользователями" id="users">
        <DataGrid specification={GetFullUserList()} />
      </Tab>
      <Tab title="Сессии пользователей" id="sessions">
        <DataGrid specification={GetUserSessions()} />
      </Tab>
      <Tab title="Заблокированные записи" id="locked">
        <DataGrid specification={GetLockedEntityList()} />
      </Tab>
      {initialOrganizationCode?.code !== 'TSU' && initialOrganizationCode?.code !== 'MFTI' && (
        <Tab title="Активация пользователей" id="activation">
          <UserActivation />
        </Tab>
      )}
      <Tab
        title="Управление уведомлениями"
        id="notifications"
        isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
      >
        <Notifications />
      </Tab>
    </Tabs>
  );
}

import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const WarningDraftStatusPopup = memo(({ isOpen, onClose, onConfirm }: Props) => (
  <Modal
    mode="info"
    title="Предупреждение"
    isOpen={isOpen}
    onClose={onClose}
    actions={[
      {
        mode: ButtonMode.PRIMARY,
        text: 'Продолжить',
        onClick: onConfirm,
      },
      {
        mode: ButtonMode.SECONDARY,
        text: 'Отмена',
        onClick: onClose,
      },
    ]}
    size="small"
  >
    <>Сохранить в статусе "Черновик"?</>
  </Modal>
));

export { WarningDraftStatusPopup };

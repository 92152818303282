import { ListEdit } from 'components';
import React, { memo } from 'react';
import { Author, Award } from 'types/models';
import { ParticipationTypeCode } from 'types/models/Participation';

import { showErrorsMessages } from 'utils/Common';
import { Component as AwardForm } from './AwardForm';
import { validate } from './validate';
import useController from './controller';

type Props = {
  awards: Award[];
  setAwards: (awards: Award[]) => void;
  recipients: Author[];
  disabled: boolean;
  participationTypeCode: ParticipationTypeCode;
  tooltip?: string;
};

const Awards = ({ awards, recipients, disabled, participationTypeCode, setAwards, tooltip }: Props) => {
  const { awardsExtraToolbarButtons } = useController();

  return (
    <ListEdit
      header={{ title: 'Награды', tooltip }}
      rows={awards}
      onChange={(rows: Award[]) => {
        setAwards(rows);
      }}
      toolbar={['add', 'edit', 'delete', ...awardsExtraToolbarButtons]}
      columns={[
        { label: 'Категория награды', formatValue: (row: Award) => row?.category?.label || <em>Не указана</em> },
        { label: 'Наименование награды', formatValue: (row: Award) => row?.name || <em>Не указано</em> },
        { label: 'Номинация', formatValue: (row: Award) => row.nomination ?? <em>Не указана</em> },
        {
          label: 'Получатели',
          formatValue: (row: Award) => row.recipients?.map(x => x.person?.fullName).join(', ') || <em>Не указаны</em>,
        },
        { label: 'Документ', formatValue: (row: Award) => (row.file?.id ? row.file.name : <em>Не добавлен</em>) },
      ]}
      maxHeight="none"
      isDisabled={disabled}
      isSaveAndAddCustomComponent
      isUniq={false}
      specification={{
        mode: 'customComponent',
        onPreSubmit: (award, submit) => {
          const preparedAward = { ...award };
          if (!preparedAward.recipients || !preparedAward.recipients?.length) {
            preparedAward.recipients = JSON.parse(JSON.stringify(recipients));
          }
          submit(preparedAward);
        },
        renderComponent: (row, onChange) => (
          <AwardForm row={row} onChange={onChange} recipients={recipients} participationTypeCode={participationTypeCode} />
        ),
        validation: {
          checkIsValid: row => !validate(row).filter(({ isValid }) => !isValid).length,
          onInvalidate: row =>
            showErrorsMessages(
              validate(row)
                .filter(({ isValid }) => !isValid)
                .map(({ invalidMessage }) => invalidMessage),
            ),
        },
      }}
    />
  );
};

export const AwardsComponent = memo(Awards);

import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const CashDocumentAddTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');
  const look: F.CashDocumentLook = {
    apiID: 'GetCashDocument',
    template: 'CashDocumentForm',
    relatedTableState,
    setTitle,
    formType: 'receive',
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CashDocumentAddTemplate);

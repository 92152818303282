import { useCallback, useMemo, useState } from 'react';

import { ButtonProps, ReferenceItem } from 'components';

import { Permits } from 'utils/Permissions/Permits';
import { Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useBackendAPI } from 'services/BackendAPI';
import { Color } from 'constants/colors';

export function useController(tableState: Table.State) {
  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({
    reports: [Reports.DepartmentConferenceReport],
  });

  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const { methods: AcceptEventProject } = useBackendAPI('AcceptEventProject');
  const { methods: AcceptParticipationProject } = useBackendAPI('AcceptParticipationProject');
  const selectedRowsAccepted = tableState.selectedRows[0]?.IsAccepted === 'true';
  const isAcceptButtonDisabled = !(tableState.selectedRows.length === 1);

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const isParticipation = useMemo<boolean>(() => {
    return selectedRow?.Entity === 'PARTICIPATION';
  }, [selectedRow?.Entity]);

  const isViewPermitted = useMemo(() => {
    return isHasPermission(
      userPermission,
      Permits[`${isParticipation ? `PARTICIPATION_` : ''}${selectedRow?.['id:EventType']}_VIEW` as keyof Permits],
    );
  }, [isParticipation, selectedRow, userPermission]);

  const event = useMemo<ReferenceItem>(() => {
    return {
      id: selectedRow?.Entity === 'EVENT' ? selectedRow?.id : '',
      label: selectedRow?.Value ?? '',
    };
  }, [selectedRow?.Entity, selectedRow?.Value, selectedRow?.id]);

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({ name: Reports.DepartmentConferenceReport.caption, value: Reports.DepartmentConferenceReport.name });
  }, [handleSetCurrentReport]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: [
            Permits.CONCERT_VIEW,
            Permits.CONFERENCE_VIEW,
            Permits.CONCERT_VIEW,
            Permits.EXPOSITION_VIEW,
            Permits.PARTICIPATION_CONCERT_VIEW,
            Permits.PARTICIPATION_CONFERENCE_VIEW,
            Permits.PARTICIPATION_CONCERT_VIEW,
            Permits.PARTICIPATION_EXPOSITION_VIEW,
          ],
        },
        isDisabled: !selectedRow || !isViewPermitted,
      },
      {
        icon: { type: 'like', color: Color.success },
        title: 'Принять к отчету',
        onClick: () => {
          if (isParticipation) {
            AcceptParticipationProject.callAPI(
              { id: tableState.selectedRows[0]?.ParticipationProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push({});
                },
              },
            );
          } else {
            AcceptEventProject.callAPI(
              { id: tableState.selectedRows[0]?.EventProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push({});
                },
              },
            );
          }
        },
        isDisabled: isAcceptButtonDisabled || selectedRowsAccepted,
      },
      {
        icon: { type: 'dislike', color: Color.danger },
        title: 'Отменить принятие к отчету',
        onClick: () => {
          if (isParticipation) {
            AcceptParticipationProject.callAPI(
              { id: tableState.selectedRows[0]?.ParticipationProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push({});
                },
              },
            );
          } else {
            AcceptEventProject.callAPI(
              { id: tableState.selectedRows[0]?.EventProjectId },
              {
                onSuccessfullCall() {
                  tableStreams.reloadTable.push({});
                },
              },
            );
          }
        },
        isDisabled: isAcceptButtonDisabled || !selectedRowsAccepted,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        onClick: onClickPrint,
        isDisabled: !event.id,
      },
    ],
    [
      selectedRow,
      isViewPermitted,
      handleViewButtonClick,
      event.id,
      onClickPrint,
      isAcceptButtonDisabled,
      selectedRowsAccepted,
      isParticipation,
      AcceptParticipationProject,
      tableState.selectedRows,
      tableStreams.reloadTable,
      AcceptEventProject,
    ],
  );

  return {
    isReportOpen,
    isViewFormOpen,
    buttons,
    currentReport,
    event,
    isParticipation,
    onReportClose,
    handleTemplateCloseViewForm,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  isClearToolbar?: boolean;
  hasSelectButton?: boolean;
};

export function GetContestRequestList({ isClearToolbar, hasSelectButton }: Props = {}): Table.Specification {
  return {
    apiID: 'GetContestRequestList',
    header: {
      firstLevel: {
        title: 'Заявки на этап проекта (для актуализации данных этапа и проведения конкурса на продление проекта)',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: isClearToolbar ? undefined : LeftPanelForThirdLevel,
        hasSelectButton,
      },
    },
  };
}

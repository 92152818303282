import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const RecognitionDepartmentsTemplate: React.FC<F.TemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
}: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetRecognition',
    template: 'RecognitionDepartments',
    id,
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Подразделение, к которому относятся сведения о признании" size="medium">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(RecognitionDepartmentsTemplate);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { ProjectPerformerOrderGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const { projectSelectable } = customState;

  const {
    projectId,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({ customState, tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.ProjectPerformerOrderViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          projectSelectable={projectSelectable}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.ProjectPerformerOrderEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          projectSelectable={projectSelectable}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.ProjectPerformerOrderAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        projectId={projectId || ''}
        projectSelectable={projectSelectable}
        relatedTableState={tableState}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            (приказ № {tableState.selectedRows[0]?.OrderNumber}, тема: {tableState.selectedRows[0]?.OrderType}) Удалить приказ?
          </>
        </Modal>
      )}

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Приказы"
        size={['extralarge', 'medium-height']}
      >
        {ProjectPerformerOrderGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name ?? ''}
        reportCaption={currentReport?.caption ?? ''}
        values={{
          projectPerformerOrderId: tableState.selectedRows[0]?.id,
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

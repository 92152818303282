import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetNotificationLogList, GetRequestList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { NotificationEventModule } from 'utils/Enums';

export function ProgramRequests() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['requests', 'program-requests'],
    init: ['list'],
  });

  const { userPermission } = useAppDataContext();

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab
          title="Список заявок на конкурс"
          id="list"
          isVisible={isHasPermission(userPermission, [
            Permits.PROGRAM_REQUEST_VIEW_ALL,
            Permits.PROGRAM_REQUEST_VIEW_BY_MANAGEMENT,
          ])}
        >
          <DataGrid specification={GetRequestList({ hasSelectButton: false })} />
        </Tab>
        <Tab
          title="Журнал уведомлений"
          id="notifications"
          isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
        >
          <DataGrid specification={GetNotificationLogList({ module: NotificationEventModule.PROGRAM_REQUEST })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetContestRequestFeedlineList } from 'features/Table/specifications';

type Props = {
  contestRequestId: string;
};

export function FeedlineList({ contestRequestId }: Props) {
  return <DataGrid specification={GetContestRequestFeedlineList({ contestRequestId })} />;
}

import { GetMagazineArticleList, GetMagazineArticleTranslationList } from 'features/Table/specifications';
import { useCallback, useMemo } from 'react';
import { Form, Table } from 'types/models';
import { Column } from 'components';

type Props = {
  relatedTableState?: Table.State;
  magazinePublicationOriginal: Form.Original | null;
  publicationId: string;
};

const useController = ({ relatedTableState, magazinePublicationOriginal, publicationId }: Props) => {
  const originalsListTableConfig =
    relatedTableState &&
    GetMagazineArticleList({
      templatesTableDependencies: {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
        relatedRecordId: magazinePublicationOriginal ? magazinePublicationOriginal.id : '',
      },
      workMode: 'editMode',
    });

  const modalTableRowToOriginalConventer = useCallback<(row: Table.Entry) => Form.Original>(
    row => ({
      id: row.id,
      bibliographic: row.bibliographicRecord,
      name: '',
    }),
    [],
  );

  const translatesColumns = useMemo<Column<Form.Translation>[]>(() => [{ label: '', formatValue: row => row.name }], []);

  const modalTableRowConverterToTranslatesConverter = useCallback<(row: Table.Entry) => Form.Translation>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const translatesListTableConfig = useMemo(
    () =>
      GetMagazineArticleTranslationList({
        sourceId: publicationId,
      }),
    [publicationId],
  );

  return {
    originalsListTableConfig,
    modalTableRowToOriginalConventer,
    translatesColumns,
    modalTableRowConverterToTranslatesConverter,
    translatesListTableConfig,
  };
};

export default useController;

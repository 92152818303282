import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

const ToolbarTemplate = ({ tableState, onSubmitTable }: Props) => {
  const {
    buttons,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    isViewButtonDisabled,
    isEditButtonDisabled,
    handleTemplateCloseAddForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    nextPublicationName,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isDeleteButtonDisabled,
  } = useController({
    tableState,
    onSubmitTable,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.ProgramViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.ProgramEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.ProgramAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.name}) Удалить программу?</>
        </Modal>
      )}
    </>
  );
};

export const Component = React.memo(ToolbarTemplate);

import React from 'react';
import block from 'bem-cn';

import { ButtonMode, FormComponent, Modal, Select, Toolbar } from 'components';

import { Column, Setting } from 'types/models/Table';
import { Item } from 'types/models/common';
import { ActivateGridSettingArguments, SaveAndChangeGridSettingArguments } from 'features/Table/types';
import { TemplatesConfigurationModal } from '../TemplatesConfigurationModal';
import { Component as TemplateForm } from 'features/Table/specifications/GetGridSettings/LeftPanelForThirdLevel/modalTemplate';
import useController from './controller';

import './styles.scss';
import { TemplatesHelp } from './help';

const b = block('template-panel');

export type Props = {
  gridName: string;
  columns: Column[];
  currentSetting: Setting | null;
  settingPresets: Setting[];
  saveAndChangeGridSetting: (saveAndChangeGridSettingArguments: SaveAndChangeGridSettingArguments) => Promise<void>;
  changeCurrentSetting: (activateGridSettingArguments: ActivateGridSettingArguments) => void;
  reloadSettingPresets: () => Promise<void>;
};

export const TemplatesPanel = ({
  columns,
  gridName,
  currentSetting,
  settingPresets,
  changeCurrentSetting,
  saveAndChangeGridSetting,
  reloadSettingPresets,
}: Props) => {
  const {
    buttons,
    userLogin,
    isOpenHelpModal,
    isTemplateFormOpen,
    settingPresetOptions,
    isTemplatesModalOpen,
    isSaveWarningModalOpen,
    isNotificationModalOpen,
    crudCallback,
    closeHelpModal,
    submitCallback,
    handleSaveClick,
    closeTemplateForm,
    successfullCallback,
    onChangeCurrentSetting,
    onResetSaveWarningModal,
    handleSaveOtherTemplate,
    onCloseSaveWarningModal,
    onConfirmSaveWarningModal,
    handleTemplatesModalClose,
    handleNotificationModalClose,
  } = useController({
    settingPresets,
    currentSetting,
    changeCurrentSetting,
    saveAndChangeGridSetting,
    reloadSettingPresets,
  });

  return (
    <div className={b()}>
      <TemplatesConfigurationModal
        gridName={gridName}
        columns={columns}
        currentSetting={currentSetting}
        isOpen={isTemplatesModalOpen}
        onClose={handleTemplatesModalClose}
        crudCallback={crudCallback}
        onSubmitTable={submitCallback}
      />

      <div className={b('select')}>
        <FormComponent.Field label="Шаблон настройки таблицы" labelSize="fit">
          <Select
            value={
              settingPresetOptions.find(presetOption => {
                const isEqualName = currentSetting?.name === presetOption.label;
                const isEqualAuthor = currentSetting?.author === presetOption.author;
                return isEqualName && isEqualAuthor;
              }) as Item
            }
            options={
              settingPresetOptions.filter(item => {
                return item.author === userLogin;
              }) as Item[]
            }
            onChange={onChangeCurrentSetting}
            placeholder="Не задан"
          />
        </FormComponent.Field>
      </div>

      <Toolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Сохранение шаблона"
        isOpen={isNotificationModalOpen}
        onClose={handleNotificationModalClose}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить',
            onClick: handleSaveClick,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Сохранить под другим именем',
            onClick: handleSaveOtherTemplate,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleNotificationModalClose,
          },
        ]}
        size="small"
      >
        <>Сохранить настройку таблицы в качестве шаблона "{currentSetting?.name ?? ''}"?</>
      </Modal>

      <Modal
        mode="warning"
        title="Сохранение шаблона"
        isOpen={isSaveWarningModalOpen}
        onClose={onCloseSaveWarningModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить шаблон под другим именем',
            onClick: onConfirmSaveWarningModal,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onResetSaveWarningModal,
          },
        ]}
        size="small"
      >
        <>Этот шаблон создан другим пользователем, только автор имеет право его изменить</>
      </Modal>

      <TemplateForm
        mode="add"
        isOpen={isTemplateFormOpen}
        onClose={closeTemplateForm}
        gridName={gridName}
        columns={columns}
        successfullCallback={successfullCallback}
      />

      <Modal mode="help" title="Настройка таблицы" isOpen={isOpenHelpModal} onClose={closeHelpModal} size="large">
        {TemplatesHelp()}
      </Modal>
    </div>
  );
};

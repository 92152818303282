import { useCallback, useState } from 'react';

import { Project } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

export function useController() {
  const { isReportOpen, onReportClose, currentReport, handleSetCurrentReport } = useReportsHook({ reports: [] });

  const [projectActId, setProjectActId] = useState<string>('');

  const [isProjectNotSavedPopupOpen, setIsProjectNotSavedPopupOpen] = useState<boolean>(false);

  const onClickPrint = useCallback(
    (row: Project.Act | null) => {
      if (row?.id) {
        setProjectActId(row?.id);
        handleSetCurrentReport({ name: Reports.ProjectAct.caption, value: Reports.ProjectAct.name });
      } else {
        setIsProjectNotSavedPopupOpen(true);
      }
    },
    [handleSetCurrentReport],
  );

  return {
    onClickPrint,
    isProjectNotSavedPopupOpen,
    setIsProjectNotSavedPopupOpen,
    projectActId,
    setProjectActId,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

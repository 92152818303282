import React from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-text');

type Props = {
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {React.ReactNode | JSX.Element | string | null | false |
   *    (React.ReactNode | JSX.Element | string | null | false)[]}
   *  children - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | string | null | false | (React.ReactNode | JSX.Element | string | null | false)[];
};

/**
 * Wrapper of text or JSX
 *
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {React.ReactNode | JSX.Element | string | null | false | (React.ReactNode | JSX.Element | string | null | false)[]}
 *  children - (optional) React inner JSX component
 * @returns JSX
 */
export const Text = React.memo(({ style, children }: Props) => {
  return (
    <div className={b()} style={style}>
      <span>{children}</span>
    </div>
  );
});

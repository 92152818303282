import React from 'react';

import { ButtonMode, ReferenceItem, FormComponent, Modal, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { Form, RefFeedlineCommand } from 'types/models';

type Props = {
  title: string;
  publicationMessage: Form.PublicationMessage;
  updatePublicationMessage: (publicationMessage: Form.PublicationMessage) => void;
  publicationMessageType: ReferenceItem;
  setPublicationMessageType: (publicationMessageType: ReferenceItem) => void;
  relationTableModalTitle?: string;
  isOpen: boolean;
  onConfirm: () => void;
  onConfirmWithoutMessage: () => void;
  submitButtonText?: string;
  cancelButtonText?: string;
  command: RefFeedlineCommand;
};

export const AdministratorMessagePopup = React.memo(
  ({
    title,
    onConfirm,
    onConfirmWithoutMessage,
    publicationMessageType,
    setPublicationMessageType,
    isOpen,
    publicationMessage,
    updatePublicationMessage,
    submitButtonText = 'Продолжить',
    cancelButtonText = 'Продолжить без сообщения',
    relationTableModalTitle = 'Сообщение администратору',
    command,
  }: Props) => {
    return (
      <Modal
        title={title}
        isOpen={isOpen}
        onClose={onConfirmWithoutMessage}
        actions={[
          { mode: ButtonMode.PRIMARY, text: submitButtonText, onClick: onConfirm, isDisabled: !publicationMessageType.id },
          {
            mode: ButtonMode.SECONDARY,
            text: cancelButtonText,
            onClick: onConfirmWithoutMessage,
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label={relationTableModalTitle}>
            <Select
              mode={SelectMode.REFERENCE}
              value={publicationMessageType}
              onChange={setPublicationMessageType}
              settings={{ name: command, title: relationTableModalTitle }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={publicationMessage}
              onChange={updatePublicationMessage}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>
    );
  },
);

import React, { memo, useMemo } from 'react';
import { setup } from 'bem-cn';

import { Checkbox, FormComponent, ListEdit, Radio } from 'components';

import { BuilderProps, TableModalProps, EnumProps, AdditionalField } from 'features/BuildReportPopup/models';
import { DefaultSelect } from './DefaultSelect';
import { CustomSelect } from './CustomSelect';
import { ReferenceSelect } from './ReferenceSelect';
import { SelectUserDepartments } from './SelectUserDepartments';
import useController from './controller';
import { ALL_RADIO_VALUE, PARTIAL_RADIO_VALUE } from './helpers';

import './style.scss';

const block = setup({ mod: '--' });
const parameterList = block('parameter-list');

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = BuilderProps & {
  tableModalProps?: TableModalProps;
  enumProps?: EnumProps<CustomState, RequestData>;
  allRadioLabel?: string;
  partialRadioLabel?: string;
  additionalFields?: AdditionalField[];
  isLabelHidden?: boolean;
  parentId?: string;
  disabled?: boolean;
  isWithoutTopFields?: boolean;
};

const DefaultParameterList = <CustomState extends {} = {}, RequestData extends {} = {}>({
  parameters,
  setParameterValueByName,
  parameterName,
  tableModalProps,
  enumProps,
  additionalFields = [],
  allRadioLabel = 'Все',
  partialRadioLabel = 'Выборочно',
  isLabelHidden,
  isWithoutTopFields,
  parentId,
  disabled,
}: Props<CustomState, RequestData>) => {
  const {
    refs,
    refsRadioValue,
    currentParameter,
    fields,
    canBuild,
    enumValues,
    isListEditDisabled,
    userDepartments,
    onChangeFields,
    handleChangeRefs,
    handleChangeRefsRadioValue,
  } = useController({
    disabled,
    parameters,
    parentId,
    additionalFields,
    parameterName,
    enumProps,
    isWithoutTopFields,
    setParameterValueByName,
  });

  const allFields = useMemo(
    () => [
      <FormComponent.Field
        key="initial"
        label={isLabelHidden ? '' : currentParameter?.description}
        isRequired={isLabelHidden ? false : currentParameter?.required}
      >
        <Radio
          isDisabled={disabled}
          value={refsRadioValue}
          list={[
            { label: allRadioLabel, value: ALL_RADIO_VALUE },
            { label: partialRadioLabel, value: PARTIAL_RADIO_VALUE },
          ]}
          onChange={handleChangeRefsRadioValue}
        />
      </FormComponent.Field>,
      ...fields.map((field, index) => (
        <FormComponent.Field key={field.key}>
          <Checkbox
            label={field.label}
            checked={!!field.value}
            onChange={checked => onChangeFields({ index, nextValue: checked })}
          />
        </FormComponent.Field>
      )),
    ],
    [
      isLabelHidden,
      currentParameter?.description,
      currentParameter?.required,
      disabled,
      refsRadioValue,
      allRadioLabel,
      partialRadioLabel,
      handleChangeRefsRadioValue,
      fields,
      onChangeFields,
    ],
  );

  return (
    <>
      {canBuild && (
        <div className={parameterList()}>
          {!isWithoutTopFields && <FormComponent.Line>{allFields}</FormComponent.Line>}
          {!!tableModalProps && (
            <div className={parameterList('list-edit')}>
              <ListEdit
                header={{
                  title: tableModalProps?.listEditTitle || '',
                  isRequired: isWithoutTopFields && currentParameter?.required,
                }}
                rows={refs}
                onChange={handleChangeRefs}
                toolbar={['add', 'edit', 'delete']}
                columns={[{ label: 'Наименование', formatValue: ref => ref.label }]}
                isDisabled={isListEditDisabled}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'relationTableModal',
                  relationTableModalTitle: tableModalProps?.relationTableModalTitle || '',
                  modalTableRowConverter: row =>
                    tableModalProps?.modalTableRowConverter?.(row) || { label: row.label, id: row.id },
                  modalTableSpecification: tableModalProps?.modalTableSpecification,
                }}
              />
            </div>
          )}
          {!!enumProps && (
            <div className={parameterList('list-edit')}>
              <ListEdit
                header={{ title: enumProps?.listEditTitle || '', isRequired: isWithoutTopFields && currentParameter?.required }}
                rows={refs}
                onChange={handleChangeRefs}
                toolbar={['add', 'edit', 'delete']}
                columns={[{ label: 'Наименование', formatValue: ref => ref.label }]}
                isDisabled={isListEditDisabled}
                isDeleteConfirmEnabled
                withMessages
                specification={{
                  mode: 'customComponent',
                  renderComponent: (ref, setRef) => {
                    let component = null;

                    switch (enumProps.mode.component) {
                      case 'DefaultSelect':
                        component = (
                          <DefaultSelect
                            defaultRefName={currentParameter?.default}
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            enumValues={enumValues}
                            fieldLabel={enumProps?.fieldLabel || ''}
                          />
                        );
                        break;
                      case 'SelectUserDepartments':
                        component = (
                          <SelectUserDepartments
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            userDepartments={userDepartments}
                            fieldLabel={enumProps?.fieldLabel || ''}
                          />
                        );
                        break;
                      case 'CustomSelect':
                        component = (
                          <CustomSelect
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            specification={{
                              modalTableRowConverter: enumProps.mode.specification.modalTableRowConverter,
                              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                              // @ts-expect-error
                              modalTableSpecification: enumProps.mode.specification.modalTableSpecification,
                              relationTableModalTitle: enumProps.mode.specification.relationTableModalTitle,
                            }}
                            fieldLabel={enumProps?.fieldLabel || ''}
                          />
                        );
                        break;
                      case 'Reference':
                        component = (
                          <ReferenceSelect
                            referenceItem={ref}
                            setReferenceItem={setRef}
                            fieldLabel={enumProps?.fieldLabel || ''}
                            referenceName={enumProps.mode.referenceName}
                            relationTableModalTitle={enumProps.mode.relationTableModalTitle}
                            referenceFilter={enumProps.mode.referenceFilter}
                          />
                        );
                        break;
                      default:
                        component = <span>Тип компонента не обслуживается</span>;
                        break;
                    }
                    return <FormComponent.Wrapper>{component}</FormComponent.Wrapper>;
                  },
                }}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

const Component = memo(DefaultParameterList);

export { Component as DefaultParameterList };

import { useCallback, useMemo } from 'react';

import { MipPartner, Person, Table } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { getMockScientist } from 'features/Form/looks/person/helpers';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  founder: MipPartner.Founder;
  setFounder: (founder: MipPartner.Founder) => void;
  amount: string;
};

const useController = ({ founder, setFounder, amount }: Props) => {
  const { initialCountryCode } = useAppDataContext();

  const makeHandler = useCallback(
    (key: keyof MipPartner.Founder) => (value: ValueOf<MipPartner.Founder>) => {
      setFounder({
        ...founder,
        [key]: value,
      });
    },
    [founder, setFounder],
  );

  const amountPercent = useMemo(() => getPercentOfAmountByPart({ part: founder.amount, amount }), [amount, founder.amount]);

  const onTypeChange = useCallback(
    (value: string) => {
      if (value === 'scientist') {
        setFounder({
          ...founder,
          enterpriseCustomer: null,
          scientist: getMockScientist({ initialCountryCode }),
          isLocal: false,
        });
        return;
      }
      if (value === 'enterprise') {
        setFounder({
          ...founder,
          passport: '',
          scientist: null,
          isLocal: false,
        });
        return;
      }
      setFounder({
        ...founder,
        enterpriseCustomer: null,
        passport: '',
        scientist: null,
        isLocal: true,
      });
    },
    [setFounder, founder, initialCountryCode],
  );

  const tableRowConverter = useCallback(
    (row: Table.Entry): Person.Scientist => ({ ...getMockScientist({ initialCountryCode }), fullName: row.Fio, id: row.id }),
    [initialCountryCode],
  );

  const typeFounder = useMemo(() => {
    if (founder.isLocal) {
      return 'local';
    }
    if (founder.scientist !== null) {
      return 'scientist';
    }
    if (founder.enterpriseCustomer !== null) {
      return 'enterprise';
    }
    return 'enterprise';
  }, [founder]);

  return {
    typeFounder,
    amountPercent,
    onTypeChange,
    makeHandler,
    tableRowConverter,
  };
};

export default useController;

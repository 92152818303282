import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetExpenseDocumentList: Table.Specification = {
  apiID: 'GetExpenseDocumentList',
  header: {
    firstLevel: {
      title: 'Реестр расходных документов бухгалтерского учёта',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

import React from 'react';
import * as ModalTemplates from './modalTemplates';

import { ButtonMode, FormComponent, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import useController from './controller';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { AwardsModal } from 'features/AwardsModal';

type Props = {
  customState: State;
  tableState: Table.State;
};

export type Mode = 'add' | 'edit' | 'view';

function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const { isEventChanged } = customState;

  const {
    sourceEvent,
    buttons,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isConfirmDeleteOpen,
    isShowAwards,
    isApproveParticipationModal,
    isEditSubdivisionModal,
    reloadTable,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsConfirmDeleteOpen,
    handleDeleteParticipation,
    setIsShowAwards,
    setIsApproveParticipationModal,
    setIsEditSubdivisionModal,
  } = useController({
    customState,
    tableState,
  });

  return (
    <>
      {!!isEventChanged ? (
        <FormComponent.Description mode="warning" classMixin={['is-hint', 'auto-width']}>
          Прежде чем редактировать участников, сохраните мероприятие, с помощью кнопки "Сохранить и продолжить"
        </FormComponent.Description>
      ) : (
        <Toolbar buttons={buttons} />
      )}

      <ModalTemplates.ExpositionParticipationViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={() => setIsViewFormOpen(false)}
        relatedTableState={tableState}
        id={tableState.selectedRows[0]?.id}
      />

      <ModalTemplates.ExpositionParticipationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        relatedTableState={tableState}
        sourceEvent={sourceEvent}
      />

      <ModalTemplates.ExpositionParticipationEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        relatedTableState={tableState}
        id={tableState.selectedRows[0]?.id}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteOpen}
        onClose={() => setIsConfirmDeleteOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmDeleteOpen(false),
          },
        ]}
        size="small"
      >
        <>
          (статус: {tableState.selectedRows[0]?.RecordStatus}, участники: {tableState.selectedRows[0]?.Participants}) Удалить
          участие?
        </>
      </Modal>

      <AwardsModal
        isOpen={isShowAwards}
        onClose={() => {
          setIsShowAwards(false);
          reloadTable();
        }}
        id={tableState.selectedRows[0]?.id}
      />

      <ApproveParticipation
        isOpen={isApproveParticipationModal}
        onClose={() => {
          setIsApproveParticipationModal(false);
          reloadTable();
        }}
        id={tableState.selectedRows[0]?.id}
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
      />

      <EditParticipationDepartments
        isOpen={isEditSubdivisionModal}
        onClose={() => setIsEditSubdivisionModal(false)}
        id={tableState.selectedRows[0]?.id}
        afterSubmit={reloadTable}
        type={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

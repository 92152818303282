import React, { useMemo, useState, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Component as Form } from '../Form';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const tableStreams = useLocalTableStreams();

  const { mode, closeForm, isOpenForm } = customState;

  const [selectedRow] = tableState.selectedRows;

  const { methods: DeleteNotificationRecipientPresetAPI } = BackendAPI.useBackendAPI('DeleteNotificationRecipientPreset');

  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const confirmDelete = useCallback(() => {
    DeleteNotificationRecipientPresetAPI.callAPI(
      { id: tableState.selectedRows[0]?.id },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Рассылка успешно удалена', theme: 'success' });
          tableStreams.reloadTable.push({});
        },
      },
    );
    setIsDeleteModalOpen(false);
  }, [DeleteNotificationRecipientPresetAPI, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      // {
      //   icon: { type: 'view' },
      //   title: 'Просмотр',
      //   onClick: () => {
      //     if (selectedRow) loadScientist(selectedRow?.id);
      //     setMode('view');
      //     openForm();
      //   },
      //   isDisabled: tableState.selectedRows.length !== 1,
      // },
      // {
      //   icon: { type: 'add' },
      //   title: 'Добавить',
      //   onClick: () => {
      //     setMode('add');
      //     setPerson(getMockPerson({ initialCountryCode }));
      //     openForm();
      //   },
      // },
      // {
      //   icon: { type: 'edit' },
      //   title: 'Редактировать',
      //   onClick: () => {
      //     if (selectedRow) loadScientist(selectedRow?.id);
      //     setMode('edit');
      //     openForm();
      //   },
      //   isDisabled: tableState.selectedRows.length !== 1,
      // },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteModalOpen(true),
        isDisabled: tableState.selectedRows.length !== 1 || selectedRow?.integrated === 'true',
      },
    ],
    [tableState.selectedRows.length, selectedRow],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Form isOpen={isOpenForm} onClose={closeForm} id={selectedRow?.id} mode={mode} />

      {isDeleteModalOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteModalOpen}
          onClose={() => setIsDeleteModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: confirmDelete,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteModalOpen(false),
            },
          ]}
          size="small"
        >
          <>({selectedRow?.Name}) Удалить рассылку?</>
        </Modal>
      )}
    </>
  );
}

export { Toolbar };

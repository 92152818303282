import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { RecognitionGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    handleCloseApproveConfirmPopup,
    isDepartmentsButtonDisabled,
    isDepartmentsFormOpen,
    handleTemplateCloseDepartmentsForm,
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.RecognitionViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.RecognitionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.RecognitionAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
        name={nextPublicationName}
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            (фио: {tableState.selectedRows[0]?.scientist}, награда: {tableState.selectedRows[0]?.award}, категория:{' '}
            {tableState.selectedRows[0]?.categoryAward}) Удалить награду?
          </>
        </Modal>
      )}

      {!isApproveButtonDisabled && (
        <modalTemplates.ApproveConfirmPopup.Component
          isOpen={isApproveConfirmPopupOpen}
          onClose={handleCloseApproveConfirmPopup}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isDepartmentsButtonDisabled && (
        <modalTemplates.RecognitionDepartmentsTemplate.Component
          isOpen={isDepartmentsFormOpen}
          onClose={handleTemplateCloseDepartmentsForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        title="Помощь в разделе Признания и награды"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {RecognitionGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          scientistId: tableState.selectedRows[0]?.scientistId || '',
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

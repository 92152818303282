import { useCallback, useState, useMemo } from 'react';

import { ExtraToolbarButton } from 'components/ListEdit/model';

import {
  calcSumFinancings,
  computeFinancingsByYear,
  getInitialProjectWithStages,
  getPerformerIndexByLastJobPeriodRole,
  getStageNdsPercent,
  getStageTotalNds,
} from 'features/Form/looks/project/ProjectForm/helpers';
import { Project } from 'types/models';
import { ProjectFinancingType, ProjectScientistRole } from 'utils/Enums';
import { showNotification } from 'features/Notifications';

import { Period } from './type';

type Props = {
  project: Project.Project;
  stages: Project.Stage[];
  financings: Project.Financing[];
  performers: Project.Performer[];
  setStages(stages: Project.Stage[]): void;
  setFinancingsByYear(financingsByYear: Project.FinancingByYear[]): void;
  setFinancings(financings: Project.Financing[]): void;
  setPerformers(performers: Project.Performer[]): void;
  disabled: boolean;
};

const useController = ({
  project,
  stages,
  financings,
  performers,
  setStages,
  setFinancingsByYear,
  setFinancings,
  setPerformers,
  disabled,
}: Props) => {
  const financingValuesEmpty = useMemo<Record<ProjectFinancingType, string>>(() => {
    return {
      [ProjectFinancingType.ACCOMPLICE]: '0',
      [ProjectFinancingType.COFINANCING_1]: '0',
      [ProjectFinancingType.COFINANCING_2]: '0',
      [ProjectFinancingType.LOCAL]: '0',
      [ProjectFinancingType.MAIN]: '0',
    };
  }, []);

  const [isWarningPopupOpened, setIsWarningPopupOpened] = useState<boolean>(false);
  const [isGeneratePlanOpen, setIsGeneratePlanOpen] = useState<boolean>(false);
  const [periodDuration, setPeriodDuration] = useState<Period>(Period.year);
  const [financingValues, setFinancingValues] = useState<Record<ProjectFinancingType, string>>(financingValuesEmpty);

  const leaderIndex = useMemo(() => {
    const indeces = project ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.LEADER) : [];
    const [index] = indeces;
    return index;
  }, [project]);

  const responsiblePerformerIndex = useMemo(() => {
    const indeces = project ? getPerformerIndexByLastJobPeriodRole(project, ProjectScientistRole.RESPONSIBLE_PERFORMER) : [];
    const [index] = indeces;
    return index;
  }, [project]);

  const leader = useMemo(() => {
    return leaderIndex !== null ? performers[leaderIndex] : null;
  }, [leaderIndex, performers]);

  const responsiblePerformer = useMemo(() => {
    return responsiblePerformerIndex !== null ? performers[responsiblePerformerIndex] : null;
  }, [performers, responsiblePerformerIndex]);

  const editableFinancings = useMemo<ProjectFinancingType[]>(
    () => Object.keys(ProjectFinancingType).filter(x => financings.find(y => y.type?.value === x)) as ProjectFinancingType[],
    [financings],
  );

  const closeWarningPopup = useCallback(() => {
    setIsWarningPopupOpened(false);
  }, []);

  const openWarningPopup = useCallback(() => {
    setIsWarningPopupOpened(true);
  }, []);

  const ndsPercentFormatter = useCallback((stage: Project.Stage) => getStageNdsPercent(stage), []);

  const totalNdsFormatter = useCallback((row: Project.Stage) => getStageTotalNds(row), []);

  const onStagesChange = useCallback(
    (nextStages: Project.Stage[]) => {
      const nextFinancingsByYear = computeFinancingsByYear(nextStages);
      const nextFinancings = calcSumFinancings(nextStages, financings);

      setFinancings(nextFinancings);
      setFinancingsByYear(nextFinancingsByYear);
      setStages(nextStages);
    },
    [financings, setFinancingsByYear, setStages, setFinancings],
  );

  const onStagePreDelete = useCallback(
    (stage: Project.Stage, continueDelete: () => void, index: number) => {
      if (project.equips.some(equip => equip.stage?.number === project.stages[index].number)) {
        showNotification({
          message:
            'Вырбанный этап используется в списке оборудования (вкладка Особенности / Использование ресурсов и оборудования)',
          theme: 'danger',
        });
        return;
      }

      if (project.reports.some(report => report.stage?.number === project.stages[index].number)) {
        showNotification({
          message: 'Вырбанный этап используется в списке отчётов (вкладка Госрегистрация / Регистрация отчетов)',
          theme: 'danger',
        });
        return;
      }

      setPerformers(performers);
      continueDelete();
    },
    [performers, project.equips, project.reports, project.stages, setPerformers],
  );

  const closeGeneratePlan = useCallback(() => {
    setIsGeneratePlanOpen(false);
  }, []);

  const openGeneratePlan = useCallback(() => {
    setIsGeneratePlanOpen(true);
    setFinancingValues(financingValuesEmpty);
    setPeriodDuration(Period.year);
  }, [financingValuesEmpty]);

  const submitCalendarPlan = useCallback(() => {
    closeGeneratePlan();
    const { stages: nextStages, performers: nextPerformers } = getInitialProjectWithStages({
      initialProject: project,
      periodDuration,
      financingValues,
    });

    const nextFinancingsByYear = computeFinancingsByYear(nextStages);
    const nextFinancings = calcSumFinancings(nextStages, financings);

    setPerformers(nextPerformers);
    setFinancings(nextFinancings);
    setFinancingsByYear(nextFinancingsByYear);
    setStages(nextStages);
  }, [
    closeGeneratePlan,
    financingValues,
    financings,
    periodDuration,
    project,
    setFinancings,
    setFinancingsByYear,
    setPerformers,
    setStages,
  ]);

  const extraToolbarButtons: ExtraToolbarButton<Project.Stage>[] = useMemo(
    () => [
      {
        icon: { type: 'calendar', mode: 'add' },
        title: 'Сформировать календарный план',
        onClick: () => {
          if (!leader && !responsiblePerformer) {
            showNotification({
              message: 'Формирование календарного плана доступно только при наличии руководителя или ответственного исполнителя',
              theme: 'danger',
            });
            return;
          }

          if (stages.length) {
            showNotification({
              message: 'Формирование календарного плана доступно только при отсутствии этапов в списке',
              theme: 'danger',
            });
            return;
          }

          const isProjectHasStartAndEndDate = project?.startDate && project?.endDate;
          if (!isProjectHasStartAndEndDate) {
            showNotification({
              message: 'Для формирования календарного плана необходимо указать период проекта',
              theme: 'danger',
            });
            return;
          }

          openGeneratePlan();
        },
        isDisabled: disabled,
      },
    ],

    [disabled, leader, openGeneratePlan, project?.endDate, project?.startDate, responsiblePerformer, stages.length],
  );

  return {
    isWarningPopupOpened,
    isGeneratePlanOpen,
    extraToolbarButtons,
    closeGeneratePlan,
    submitCalendarPlan,
    onStagesChange,
    ndsPercentFormatter,
    totalNdsFormatter,
    openWarningPopup,
    onStagePreDelete,
    closeWarningPopup,
    periodDuration,
    setPeriodDuration,
    financingValues,
    setFinancingValues,
    editableFinancings,
  };
};

export default useController;

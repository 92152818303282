import React from 'react';

import { ButtonProps, FormComponent, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { Item } from 'types/models/common';
import { GetNirRequestListForProject } from 'features/Table/specifications';
import useController from './controller';

type Props = {
  nir: Item<string> | null;
  setNir: (contestRequest: Item<string> | null) => void;
  label: string;
  modalTitle: string;
  externalButtons?: ButtonProps[];
  isRequired?: boolean;
  isDisabled?: boolean;
};

export const SelectNir = ({ nir, setNir, label, modalTitle, externalButtons, isRequired, isDisabled }: Props) => {
  const { onSelectRow } = useController();

  return (
    <FormComponent.Field label={label} isRequired={isRequired}>
      <TextArea
        mode={TextAreaMode.TABLE}
        settings={{
          title: modalTitle,
          externalButtons,
          table: {
            specification: GetNirRequestListForProject,
            onSelect: (row: Table.Entry | null) => (row ? onSelectRow(row, setNir) : setNir(null)),
          },
        }}
        value={nir?.value ? `${nir?.label} \n(ID заявки: ${nir?.value})` : ''}
        isDisabled={isDisabled}
      />
    </FormComponent.Field>
  );
};

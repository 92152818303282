import { Icon } from 'components';

import { Color } from 'constants/colors';

export function PurchaseRequestGridHelp(isPersonal?: boolean): JSX.Element {
  return (
    <>
      <h3>Заявки на закупки для научных проектов</h3>

      {isPersonal ? (
        <>
          <p>
            Раздел содержит список заявок на закупки для научных проектов, в которых Вы указаны в роли{' '}
            <strong>руководитель</strong> или <strong>ответственный исполнитель</strong> проекта.
          </p>

          <h3>Для оформления Заявки на закупку:</h3>
        </>
      ) : (
        <>
          <h4>
            Раздел содержит список заявок на закупки для научных проектов. Пользователь, который имеет возможность оформить
            заявку:
          </h4>

          <ul>
            <li>
              <strong>руководитель</strong> или <strong>ответственный исполнитель</strong> проекта — через свой личный кабинет на
              вкладке НИОКР, Закупки;
            </li>
            <li>
              сотрудники университета — <strong>ответственные лица по работе с заявками на закупки для научных проектов</strong>;
              имеют право доступа к функциональному модулю Закупки.
            </li>
          </ul>

          <h3>Жизненный цикл Заявки на закупку</h3>
        </>
      )}

      <h4>Этап 1. Добавление заявки на закупку</h4>

      <p>
        Заполнение формы Заявки, добавление файла к заявке (например, требования к поставщику, техническое задание на выполнение
        работ и пр.)
      </p>

      <ul>
        <li>
          Заявка, не зависимо от статуса, будет отображаться в Личном кабинете у руководителя проекта, ответственного исполнителя
          проекта и материально-ответственного лица.
        </li>
      </ul>

      <h4>
        Этап 2. Отправка заявки на рассмотрение с помощью КНОПКИ <Icon type="toForward" color={Color.success} />
      </h4>

      <ul>
        <li>
          Заявка меняет статус <strong>Черновик → На рассмотрении</strong> и система фиксирует дату подачи заявки на рассмотрение.
        </li>
        <li>
          Ответственное лицо за сбор заявок на закупки зарегистрирует заявку, присвоив ей номер в соответствии с внутренним
          регламентом.
        </li>
        <li>Заявку просматривают эксперты и добавляют экспертное замечание (визу).</li>
        <li>
          Заявка в статусе <strong>На рассмотрении</strong> не доступна для редактирования.
        </li>
      </ul>

      <h4>Этап 3. При необходимости, заявка может быть переведена в статус На доработку</h4>

      <p>Внесение исправлений по замечаниям, и повторная отправка заявителем заявки на рассмотрение.</p>

      {isPersonal ? (
        <>
          <div className="warning">
            <span>
              Заявитель может удалить заявку только в статусе <strong>Черновик</strong>.
            </span>
          </div>

          <h4>Процедуры по заявкам на закупку:</h4>

          <table>
            <thead>
              <tr>
                <th>Статус закупки</th>
                <th>Доступные действия с заявкой в текущем статусе</th>
                <th>Доступный статус для перевода</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Черновик</td>
                <td>
                  <p>
                    <strong>Создание новой заявки</strong>
                  </p>
                  <p>
                    Кто: Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за закупки (в пункте меню
                    Закупки)
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На рассмотрение
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>На рассмотрении</td>
                <td>
                  <strong>Просмотр заявки и возможность оставить визу:</strong>
                  <ul>
                    <li>Управляющий комитет</li>
                    <li>Ценовая комиссия</li>
                    <li>Управление логистики</li>
                  </ul>
                  <p>
                    Функциональные кнопки <strong>«Комитет»</strong>, <strong>«Комиссия»</strong> и <strong>«Логистика»</strong>,
                    доступны, если статус закупки — <strong>"На рассмотрении"</strong>.
                  </p>
                  <p>
                    <strong>Примечание:</strong> Порядок рассмотрения управляющим комитетом, ценовой комиссией и управлением
                    логистики, определяется внутренним регламентом университета.
                  </p>
                </td>
                <td>
                  <strong>Перевод в статус:</strong>
                  <ul>
                    <li>На доработку</li>
                    <li>Отклонена</li>
                    <li>В работе (поставщик)</li>
                    <li>Выполнена</li>
                    <li>Черновик</li>
                  </ul>
                  <strong>Кто переводит:</strong> Ответственный за закупки (в пункте меню Закупки)
                </td>
              </tr>
              <tr>
                <td>На доработку</td>
                <td>
                  <p>
                    Доступно <strong>редактирование</strong> заявки
                  </p>
                  <p>
                    <strong>Кто может редактировать:</strong> Руководитель и Ответственный исполнитель (в личном кабинете),
                    Ответственный за закупки (в пункте меню Закупки)
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На рассмотрение
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>Отклонена</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На доработку
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>В работе (Поставщик)</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> Выполнена, Отклонена
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>Выполнена</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> Черновик
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <div className="warning">
            <span>
              <p>
                Сообщения, добавленные при переводе в любой статус, отображаются в столбце <strong>Сообщение</strong> в{' '}
                <strong>Журнале сообщений</strong> закупки.
              </p>
              <p>
                Сообщения, добавленные по кнопкам: <strong>Виза управляющего комитета, Виза ценовой комиссии</strong> и{' '}
                <strong>Виза управления логистики</strong> отображаются в столбце <strong>Замечание эксперта</strong> в{' '}
                <strong>Журнале сообщений</strong> закупки.
              </p>
              <p>
                В таблице <strong>Список закупок</strong> в столбце <strong>Сообщение</strong> отображается последнее добавленное
                сообщение, в столбце <strong>Замечание эксперта</strong> — все визы: Управляющего комитета, Ценовой комиссии и
                Управления логистики.
              </p>
            </span>
          </div>
        </>
      ) : (
        <>
          <h4>Этап 4.Перевод заявки в статус Отклонена или В работе (поставщик).</h4>

          <p>
            В соответствии с регламентом на основании имеющихся виз и экспертных замечаний Ответственное лицо по заявкам переводит
            заявку из статуса <strong>На рассмотрении → Отклонена или В работе (поставщик).</strong>
          </p>

          <h4>Этап 5. Перевод заявки в статус Выполнена</h4>

          <p>
            В статус <strong>Выполнена</strong> заявка переводится после приемки выполненных работ/услуги или поставки товара.
          </p>

          <div className="warning">
            <span>
              <p>
                Сообщения, добавленные при переводе в любой статус, отображаются в столбце <strong>Сообщение</strong> в{' '}
                <strong>Журнале сообщений</strong> закупки.
              </p>
              <p>
                Сообщения, добавленные по кнопкам: <strong>Виза управляющего комитета, Виза ценовой комиссии</strong> и{' '}
                <strong>Виза управления логистики</strong> отображаются в столбце <strong>Замечание эксперта</strong> в{' '}
                <strong>Журнале сообщений</strong> закупки.
              </p>
              <p>
                В таблице <strong>Список закупок</strong> в столбце <strong>Сообщение</strong> отображается последнее добавленное
                сообщение, в столбце <strong>Замечание эксперта</strong> — все визы: Управляющего комитета, Ценовой комиссии и
                Управления логистики.
              </p>
            </span>
          </div>

          <h4>Роли и права пользователей функционального модуля:</h4>

          <table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Роль</th>
                <th style={{ width: '70%' }}>Право</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Руководитель или ответственный исполнитель проекта</td>
                <td>
                  <p>Просмотр только своих заявок (+ по рук. или отв. исп.)</p>
                  <ul>
                    <li>Черновик: Создание, Редактирование, удаление</li>
                    <li>На доработку: Редактирование</li>
                    <li>Перевод в статус "На рассмотрение"</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Ответственное лицо по заявке</td>
                <td>
                  <ul>
                    <li>Черновик: Создание, Редактирование, удаление</li>
                    <li>На доработку: Редактирование</li>
                    <li>Перевод в статус "На рассмотрение"</li>
                    <li>Перевод в статус "На доработку"</li>
                    <li>Перевод в статус "Отклонена"</li>
                    <li>Перевод в статус "Выполнена"</li>
                    <li>Перевод в статус "В работе (поставщик)"</li>
                  </ul>
                </td>
              </tr>
              <tr>
                <td>Управляющий комитет</td>
                <td>На рассмотрение: Виза управляющего комитета</td>
              </tr>
              <tr>
                <td>Ценовая комиссия</td>
                <td>На рассмотрение: Виза ценовой комиссии</td>
              </tr>
              <tr>
                <td>Управление логистики</td>
                <td>На рассмотрение: Виза управления логистики</td>
              </tr>
            </tbody>
          </table>

          <h4>Процедуры по заявкам на закупку:</h4>

          <table>
            <thead>
              <tr>
                <th>Статус закупки</th>
                <th>Доступные действия с заявкой в текущем статусе</th>
                <th>Доступный статус для перевода</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Черновик</td>
                <td>
                  <p>
                    <strong>Создание новой заявки</strong>
                  </p>
                  <p>
                    Кто: Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за закупки (в пункте меню
                    Закупки)
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На рассмотрение
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>На рассмотрении</td>
                <td>
                  <strong>Просмотр заявки и возможность оставить визу:</strong>
                  <ul>
                    <li>Управляющий комитет</li>
                    <li>Ценовая комиссия</li>
                    <li>Управление логистики</li>
                  </ul>
                  <p>
                    Функциональные кнопки <strong>«Комитет»</strong>, <strong>«Комиссия»</strong> и <strong>«Логистика»</strong>,
                    доступны, если статус закупки — <strong>"На рассмотрении"</strong>.
                  </p>
                  <p>
                    <strong>Примечание:</strong> Порядок рассмотрения управляющим комитетом, ценовой комиссией и управлением
                    логистики, определяется внутренним регламентом университета.
                  </p>
                </td>
                <td>
                  <strong>Перевод в статус:</strong>
                  <ul>
                    <li>На доработку</li>
                    <li>Отклонена</li>
                    <li>В работе (поставщик)</li>
                    <li>Выполнена</li>
                    <li>Черновик</li>
                  </ul>
                  <strong>Кто переводит:</strong> Ответственный за закупки (в пункте меню Закупки)
                </td>
              </tr>
              <tr>
                <td>На доработку</td>
                <td>
                  <p>
                    Доступно <strong>редактирование</strong> заявки
                  </p>
                  <p>
                    <strong>Кто может редактировать:</strong> Руководитель и Ответственный исполнитель (в личном кабинете),
                    Ответственный за закупки (в пункте меню Закупки)
                  </p>
                </td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На рассмотрение
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>Отклонена</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> На доработку
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>В работе (Поставщик)</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> Выполнена, Отклонена
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
              <tr>
                <td>Выполнена</td>
                <td>Заявка закрыта для редактирования, доступен только просмотр.</td>
                <td>
                  <p>
                    <strong>Перевод в статус:</strong> Черновик
                  </p>
                  <p>
                    <strong>Кто переводит:</strong> Руководитель и Ответственный исполнитель (в личном кабинете), Ответственный за
                    закупки (в пункте меню Закупки)
                  </p>
                </td>
              </tr>
            </tbody>
          </table>

          <h3>Удаление заявки на закупку</h3>

          <p>
            Удалить заявку на закупку можно только в статусе <strong>Черновик</strong> и при наличии соответствующего права:
            "Удаление заявок в статусе "Черновик".
          </p>

          <h4>Перевод заявки на закупку в статус Черновик из разных статусов:</h4>

          <table>
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Статус заявки на закупку</th>
                <th style={{ width: '75%' }}>Перевод заявки в статус Черновик</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>На доработку</td>
                <td>
                  Передать на рассмотрение <Icon type="toForward" color={Color.success} /> &rarr; в Черновик <Icon type="new" />
                </td>
              </tr>
              <tr>
                <td>Отклонена</td>
                <td>
                  На доработку <Icon type="toBack" color={Color.danger} /> &rarr; Передать на рассмотрение{' '}
                  <Icon type="toForward" color={Color.success} /> &rarr; в Черновик <Icon type="new" />
                </td>
              </tr>
              <tr>
                <td>В работе (поставщик)</td>
                <td>
                  <p>
                    Отклонить <Icon type="dislike" /> &rarr; На доработку <Icon type="toBack" color={Color.danger} /> &rarr;
                    Передать на рассмотрение <Icon type="toForward" color={Color.success} /> &rarr; в Черновик <Icon type="new" />
                  </p>
                </td>
              </tr>
              <tr>
                <td>На рассмотрение</td>
                <td>
                  в Черновик <Icon type="new" />
                </td>
              </tr>
              <tr>
                <td>Выполнена</td>
                <td>
                  в Черновик <Icon type="new" />
                </td>
              </tr>
            </tbody>
          </table>
        </>
      )}
    </>
  );
}

import { ButtonProps } from 'components';

import { Entry, Specification } from 'types/models/Table';
import { SortType } from './ListEditTable';

export enum DataKind {
  STRING = 'string',
  DATE = 'date',
  FLOAT = 'float',
  INT = 'int',
  BOOLEAN = 'boolean',
  RANGE = 'range',
  JSX = 'jsx',
}

export type Column<Row> = {
  styles?: React.CSSProperties;
  label: string;
  formatValue(row: Row, rowIndex: number): string | number | boolean | JSX.Element;
  dataKind?: `${DataKind}`;
  defaultSort?: SortType;
  tooltip?: string;
  info?: JSX.Element;
};

export type Mode = 'view' | 'add' | 'edit' | null;

export type ValidateProps<Row> = {
  checkIsValid(row: Row | null, index: number | null, list: Row[], mode: Mode): boolean;
  onInvalidate?: (row: Row | null, mode: Mode, index: number | null, list: Row[]) => void;
};

export type ValidateLoadInstanceTableModalSpecificationProps<Row> = {
  checkIsValid(selectedRows: Array<Entry> | null, selectedRowIndex: number | null, list: Row[], mode: Mode): boolean;
  onInvalidate?: (selectedRows: Array<Entry> | null, selectedRowIndex: number | null, list: Row[], mode: Mode) => void;
};

export type CustomComponentSpecification<Row> = {
  mode: 'customComponent';
  validation?: ValidateProps<Row>;
  renderComponent: RenderComponent<Row>;
  onPreSubmit?(row: Row, submit: (updatedRow: Row) => void, selectedRowIndex: number | null, mode: Mode): void;
  onPreEdit?(row: Row | null, continueEdit: () => void): void;
  onPreDelete?(row: Row | null, continueEdit: () => void, selectedRowIndex: number | null): void;
};

export type CustomModalComponentSpecification<Row> = {
  modalTitle: string | ((row: Row | null, mode: Mode) => string);
  isToolbarHidden?: boolean;
  isCheckSubmitIcon?: boolean;
  mode: 'customModalComponent';
  validation?: ValidateProps<Row>;
  isFullScreenModal?: boolean;
  isFixedHeightModal?: boolean;
  renderComponent: RenderComponent<Row>;
  onPreEdit?(row: Row | null, continueEdit: () => void): void;
  onPreDelete?(row: Row | null, continueEdit: () => void): void;
  onPreSubmit?(row: Row, submit: (updatedRow: Row) => void, selectedRowIndex: number | null, mode: Mode): void;
};

export type RenderComponent<Row> = (
  row: Row | null,
  onChange: React.Dispatch<React.SetStateAction<Row | null>>,
  index: number | null,
  mode: Mode,
  submitCustomComponent?: () => void,
) => JSX.Element;

export type RelationTableModalSpecification<Row, TableState extends {} = {}> = {
  relationTableModalTitle: string;
  modalTableSpecification: Specification<TableState>;
  modalTableRowConverter(modalTableRow: Entry): Row;
  onPreEdit?(row: Row[], submit: (updatedRows: Row[]) => void): void;
  onPreSubmit?(row: Row[], submit: (updatedRows: Row[]) => void): void;
  mode: 'relationTableModal';
};

export type LoadInstanceTableModalSpecification<Row, TableState extends {} = {}> = {
  relationTableModalTitle: string;
  modalTableSpecification: Specification<TableState>;
  validation?: ValidateLoadInstanceTableModalSpecificationProps<Row>;
  onStartLoad(tableRows: Entry[], onSuccessLoad: (loadedRows: Row[]) => void): void;
  mode: 'loadInstanceTableModal';
};

export type ModeSpecification<Row, State extends {} = {}> =
  | RelationTableModalSpecification<Row, State>
  | CustomComponentSpecification<Row>
  | CustomModalComponentSpecification<Row>
  | LoadInstanceTableModalSpecification<Row, State>;

export type ExtraToolbarButton<Row> = Omit<ButtonProps, 'onClick' | 'isDisabled'> & {
  isDisabled?: ((row: Row | null) => boolean) | boolean;
  onClick: ExtraToolbarClickHandler<Row>;
};
export type ExtraToolbarClickHandler<Row> = (row: Row | null, selectedRowIndex: number | null, rows: Row[]) => void;

export enum DefaultButtonKey {
  ADD = 'add',
  VIEW = 'view',
  EDIT = 'edit',
  DELETE = 'delete',
  DUPLICATE = 'duplicate',
  ACCEPT = 'accept',
  DECLINE = 'decline',
  MOVE_UP = 'moveUp',
  MOVE_DOWN = 'moveDown',
  MOVE = 'move',
}

// type AcceptButtonKeys = DefaultButtonKey.ACCEPT | DefaultButtonKey.DECLINE;

// export type DefaultToolbarButton<Row> = {
//   key: `${Exclude<DefaultButtonKey, AcceptButtonKeys>}`;
// } & Partial<ExtraToolbarButton<Row>>;

// type AcceptButton<Row> = {
//   key: AcceptButtonKeys;
// } & Partial<Omit<ExtraToolbarButton<Row>, 'onClick'>> &
//   Required<Pick<ExtraToolbarButton<Row>, 'onClick'>>;

// export type CustomToolbarButton<Row> = {
//   key?: string;
// } & ExtraToolbarButton<Row>;

// export type ToolbarButton<Row> =
//   | `${Exclude<DefaultButtonKey, AcceptButtonKeys>}`
//   | DefaultToolbarButton<Row>
//   | AcceptButton<Row>
//   | CustomToolbarButton<Row>;

export type DefaultToolbarButton<Row> = {
  key: `${DefaultButtonKey}`;
} & Partial<ExtraToolbarButton<Row>>;

export type CustomToolbarButton<Row> = {
  key?: string;
} & ExtraToolbarButton<Row>;

export type ToolbarButton<Row> = `${DefaultButtonKey}` | DefaultToolbarButton<Row> | CustomToolbarButton<Row>;

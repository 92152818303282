import { useCallback, useEffect, useMemo, useState } from 'react';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?: (state: SubmitTable) => void;
};
export function useController({ onSubmitTable, tableState }: Props) {
  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'CONFERENCE_PUBLICATION_LIST', []);

  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);
  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isSelectButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);
  const handleSelectClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [onSubmitTable, tableState, tableStreams]);

  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  return {
    SUCH_LIKE_PUBLICATION_ID,
    handleAddButtonClick,
    handleEditButtonClick,
    handleSelectClick,
    handleViewButtonClick,
    isEditButtonDisabled,
    isSelectButtonDisabled,
    isViewButtonDisabled,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
    handleContinueStep,
  };
}

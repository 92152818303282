import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { ConferenceTypeProps } from 'types/models/Event';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { State } from 'features/Table/specifications/GetPersonalConferenceParticipationList/makeUseCustomController';

const ConferenceParticipatiotAddTemplate: React.FC<F.TemplateProps & ConferenceTypeProps & Partial<State>> = ({
  isOpen,
  onClose,
  relatedTableState,
  disabledEvent,
  sourceEvent,
}: F.TemplateProps & ConferenceTypeProps & Partial<State>) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: ParticipationTypes.PARTICIPATION_CONFERENCE.template,
    type: ParticipationTypes.PARTICIPATION_CONFERENCE.eventCode,
    relatedTableState,
    disabledEvent,
    sourceEvent,
    arm: 'pc',
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ConferenceParticipatiotAddTemplate);

import { FormComponent, Icon, Select } from 'components';
import { Color } from 'constants/colors';
import { renderToString } from 'react-dom/server';

export function RequestGridHelp(): JSX.Element {
  type Custom = {
    value: string;
    status: string;
  };

  const renderFields = (args: Custom[] | Custom) => {
    const fields = Array.isArray(args) ? args : [args];

    return fields.map((item, index) => {
      return (
        <div key={index}>
          {!!index && <hr />}
          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: '75%' }}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '25%' }}>
              <span dangerouslySetInnerHTML={{ __html: item.status }} />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </div>
      );
    });
  };

  return (
    <>
      <p>
        Функциональный блок Заявки на конкурс предназначен для учёта заявок поданных от университета на конкурсы, гранты и тендеры
        из категории: Проведение научных мероприятий, Издание научных трудов, на выполнение НИОКР, Проекты в области
        предпринимательства и инноваций, Стипендии и премии, Прочие проекты.
      </p>

      <h4>Назначение функциональных элементов интерфейса</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Элемент интерфейса</th>
            <th style={{ width: '70%' }}>Описание</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Год</td>
            <td>
              Это фильтр показывает список зарегистрированных в системе в указанном году заявок на конкурсы. Если год не указан,
              то показывает весь список заявок.
            </td>
          </tr>
          <tr>
            <td>
              Шаблон выборки:
              <Select onChange={() => {}} />
            </td>
            <td>Для режимов «ввода данных» и «режима анализ данных» списки шаблонов представления данных в таблице РАЗНЫЕ</td>
          </tr>
          <tr>
            <td>
              Выбрать столбцы: <Icon type="table" />
            </td>
            <td>Добавить или скрыть столбцы из списка доступных</td>
          </tr>
          <tr>
            <td>
              Фильтр включить: <Icon type="filter" />
            </td>
            <td>Перевод строки заголовков таблицы в режим фильтрации данных по столбцу</td>
          </tr>
          <tr>
            <td>
              Фильтр отключить: <Icon type="filter" mode="remove" />
            </td>
            <td>Отключить режим фильтрации строки заголовков таблицы</td>
          </tr>
          <tr>
            <td>
              Помощь: <Icon type="question" />
            </td>
            <td>Открывает окно справки по работе с разделом «Заявки на конкурсы»</td>
          </tr>
          <tr>
            <td>
              Просмотр: <Icon type="view" />
            </td>
            <td>Открывает форму просмотра Заявки. Кнопка активна при выделении строки в таблице</td>
          </tr>
          <tr>
            <td>
              Добавить: <Icon type="add" />
            </td>
            <td>Открывает форму для добавления заявки</td>
          </tr>
          <tr>
            <td>
              Редактировать: <Icon type="edit" />
            </td>
            <td>Открывает форму редактирования Заявки. Кнопка активна при выделении строки в таблице</td>
          </tr>
          <tr>
            <td>
              Удалить: <Icon type="remove" />
            </td>
            <td>Удаляет выделенную в таблице запись</td>
          </tr>
          <tr>
            <td>
              Присвоить код группы заявок: <Icon type="relation" />
            </td>
            <td>
              Код для шифра - дополнение к шифру заявки - символическое обозначение группы заявок для управленческой отчетности в
              университете, например, для аналитики по институтам
            </td>
          </tr>
          <tr>
            <td>
              Экспертная оценка: <Icon type="message" mode="check" />
            </td>
            <td>
              Вызывает форму добавления экспертной оценки и замечаний. Рекомендована для роли Конкурсная комиссия. Статус заявки
              не меняет
            </td>
          </tr>
          <tr>
            <td>
              Подать на согласование: <Icon type="toForward" color={Color.success} />
            </td>
            <td>
              Перевод заявки в статус На Согласование. Предназначена для передачи заявки на проверку ответственному за Заявки. Для
              заявителя в этом статусе заявка доступна только в режиме просмотра
            </td>
          </tr>
          <tr>
            <td>
              Вернуть на доработку: <Icon type="toBack" color={Color.danger} />
            </td>
            <td>
              Перевод заявки в статус На доработку. Рекомендована для роли Ответственный за заявки. Заявка в статусе На доработку
              доступна для редактирования заявителем
            </td>
          </tr>
          <tr>
            <td>
              Рекомендовать на конкурс: <Icon type="like" />
            </td>
            <td>
              Перевод заявки в статус Рекомендована на конкурс. Этот статус заявки обозначает, что заявка проверена экспертами
              внутри университета на полноту, соответствие требованиям конкурса и рекомендована Университетом для участия во
              внешнем конкурсе.
              <br />
              Если в заявке заполнено поле Шифр заявки или Подано на конкурс - статус заявки будет автоматически изменен на статус
              Подана на конкурс
            </td>
          </tr>
          <tr>
            <td>
              Поддержана в конкурсе: <Icon type="star" color={Color.warning} />
            </td>
            <td>
              Перевод заявки в статус Поддержана. Этот статус — итоговый в жизненном цикле заявки. Обозначает, что заявка одобрена
              и получит финансирование. На основе Заявки в статусе Поддержана можно создать проект (этап проекта) и/или
              скопировать данные заявки в проект. Рекомендована для роли Ответственный за заявки
            </td>
          </tr>
          <tr>
            <td>
              Отклонить: <Icon type="dislike" />
            </td>
            <td>
              Перевод заявки в статус Отклонить. Этот статус — итоговый в жизненном цикле заявки; обозначает, что заявка проверена
              экспертами и Конкурсной комиссией. Рекомендована для роли Ответственный за заявки
            </td>
          </tr>
          <tr>
            <td>
              Отчеты: <Icon type="print" />
            </td>
            <td>Открывает список шаблонов для формирования регламентированных документов</td>
          </tr>
          <tr>
            <td>
              Выгрузить в файл: <Icon type="excel" />
            </td>
            <td>Список записей, которые отображаются в таблице, выгружает в файл .xls для последующей обработки в Excel</td>
          </tr>
        </tbody>
      </table>

      <h4>Статусы заявки на конкурс и краткое описание бизнес-процесса</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Роль</th>
            <th style={{ width: '70%' }}>Действие и Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: 'Добавить заявку на конкурс',
                  status: 'Черновик',
                },
                {
                  value: `Отправить заявку на проверку с помощью кнопки ${renderToString(
                    <strong>Подать на согласование</strong>,
                  )}`,
                  status: 'Заявка',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на конкурс</td>
            <td>
              {renderFields([
                {
                  value: 'Просмотр заявки; Добавить сообщение для заявителя',
                  status: 'Заявка',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(<strong>Вернуть на доработку</strong>)}`,
                  status: 'На доработку',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Конкурская комиссия</td>
            <td>
              {renderFields({
                value: 'Просмотр заявки; Добавить визу',
                status: 'Заявка',
              })}
            </td>
          </tr>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: `Редактирование заявки в статусе ${renderToString(<strong>На Доработку</strong>)}`,
                  status: '',
                },
                {
                  value: `Отправить заявку на проверку в с помощью кнопки ${renderToString(
                    <strong>Подать на согласование</strong>,
                  )}`,
                  status: '',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на конкурс</td>
            <td>
              {renderFields([
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Отклонить</strong>,
                  )}; написать сообщение — обоснование`,
                  status: 'Отклонена',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Рекомендовать на конкурс</strong>,
                  )}; написать сообщение для заявителя`,
                  status: 'Рекомендована на конкурс',
                },
                {
                  // eslint-disable-next-line max-len
                  value: `После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс после заполнения полей: ${renderToString(
                    <strong>Подана на конкурс</strong>,
                  )} или ${renderToString(<strong>Шифр заявки</strong>)}, статус будет автоматически изменен`,
                  status: 'Подана на конкурс',
                },
                {
                  value: `По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки ${renderToString(
                    <strong>Поддержать</strong>,
                  )}`,
                  status: 'Поддержана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки ${renderToString(
                    <strong>Отклонить</strong>,
                  )}`,
                  status: 'Отклонена',
                },
              ])}
            </td>
          </tr>
        </tbody>
      </table>

      <div className="warning">
        <span>
          <p>
            Заявитель — участник коллектива в роли <strong>Руководитель проекта</strong> или{' '}
            <strong>Ответственный исполнитель</strong>.
          </p>
          <div>
            Для всех участников коллектива заявки (в роли: <strong>Исполнитель</strong>, <strong>Руководитель проекта</strong>,
            <strong>Ответственный исполнитель</strong>) в Личном кабинете и в карточке персоны заявка отобразится в списке на
            вкладке <strong>Заявки на конкурсы</strong>. Список заявок можно выгрузить в файл с помощью кнопки{' '}
            <Icon type="excel" />.
          </div>
          <p>
            Для заявителя (в заявке по академической мобильности) и для <strong>руководителя проекта</strong> и{' '}
            <strong>ответственного исполнителя</strong> (в заявке на конкурс) форма заявки доступна для редактирования в статусах:
            <strong>Черновик</strong> или <strong>На доработку</strong>. В других статусах — форма заявки доступна для просмотра.
            Удалить заявку можно только в статусе <strong>Черновик</strong>.
          </p>
        </span>
      </div>
    </>
  );
}

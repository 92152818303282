import React, { useEffect } from 'react';

import { FormComponent, ListEdit, Tabs, Tab, SectionTitle, TextArea, TextAreaMode, TextDateTime, TextInput } from 'components';

import { GetScienceProjectList, GetStageProjectList } from 'features/Table/specifications';
import { Documents } from 'features/Documents';
import { showErrorsMessages } from 'utils/Common';
import { useController } from './controller';
import { CustomerFields } from './CustomerFields';
import { PerformerFields } from './PerformerFields';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { validatePatentMember } from './validate';
import { useFormContext } from 'features/Form/hooks';
import { Table } from 'types/models';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function PatentResearchForm({ viewMode, editMode, onClose }: Props) {
  const {
    workMode,
    name,
    number,
    target,
    loDate,
    hiDate,
    project,
    stage,
    customers,
    performers,
    documents,
    handleNameFieldChange,
    handleNumberFieldChange,
    handleTargetFieldChange,
    handleLoDateFieldChange,
    handleHiDateFieldChange,
    handleProjectFieldChange,
    handleStageFieldChange,
    handleCustomersFieldChange,
    handlePerformersFieldChange,
    handleDocumentsFieldChange,
    convertProjectRow,
    convertStageRow,
    handleFormSubmit,
    getJobLabel,
    projectLeader,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return 'Добавление Патентного исследования';
        }

        return getStatusMessage(
          [viewMode ? 'Просмотр' : 'Редактирование', 'Патентного исследования', project?.label ? `"${project.label}"` : '']
            .filter(Boolean)
            .join(' '),
          project?.id || '',
        );
      })(),
    );
  }, [project?.id, project?.label, setTitle, viewMode, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <SectionTitle title="Патентное исследование" />

                <FormComponent.Line>
                  <FormComponent.Field label="Номер задания" isRequired>
                    <TextInput value={number} onChange={handleNumberFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Название отчета" isRequired>
                    <TextInput value={name} onChange={handleNameFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Цель исследования">
                    <TextInput value={target} onChange={handleTargetFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Дата начала" isRequired>
                    <TextDateTime value={loDate} onChange={handleLoDateFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Дата сдачи отчета">
                    <TextDateTime value={hiDate} onChange={handleHiDateFieldChange} isDisabled={workMode === 'viewMode'} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Выполняется в рамках научного проекта">
                    <TextArea
                      mode={TextAreaMode.TABLE}
                      settings={{
                        title: 'Выполняется в рамках научного проекта',
                        rows: 4,
                        table: {
                          specification: GetScienceProjectList,
                          onSelect: (row: Table.Entry | null) => handleProjectFieldChange(row ? convertProjectRow(row) : null),
                        },
                      }}
                      value={project?.label}
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Этап проекта" isRequired={!!project?.id}>
                    <TextArea
                      mode={TextAreaMode.TABLE}
                      settings={{
                        title: 'Этап проекта',
                        rows: 3,
                        table: {
                          specification: GetStageProjectList({ projectId: project?.id || '-1' }),
                          onSelect: (row: Table.Entry | null) => handleStageFieldChange(row ? convertStageRow(row) : null),
                        },
                      }}
                      value={stage?.label}
                      isDisabled={workMode === 'viewMode' || !project?.id}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Руководитель">
                    <span>{getPersonContacts(projectLeader?.person.contacts)}</span>
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column>
                <SectionTitle title="Контактные лица" />
                <FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      header={{ title: 'Заказ на проведение патентного исследования от' }}
                      rows={customers}
                      onChange={handleCustomersFieldChange}
                      toolbar={['add', 'edit', 'delete']}
                      columns={[
                        { label: 'ФИО', formatValue: x => x.person?.fullName || '' },
                        {
                          label: 'Место работы',
                          formatValue: x => (x.job ? getJobLabel(x.job) : ''),
                        },
                        { label: 'Контакты', formatValue: x => getPersonContacts(x.person.contacts) },
                      ]}
                      withMessages
                      defaultRowsCount={8}
                      isDeleteConfirmEnabled
                      isDisabled={workMode === 'viewMode'}
                      specification={{
                        mode: 'customComponent',
                        renderComponent: (row, onFieldChange) => <CustomerFields entry={row} onFieldChange={onFieldChange} />,
                        validation: {
                          checkIsValid: (row, index, list, mode) =>
                            validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
                          onInvalidate: (row, mode, index, list) => {
                            const validationInfo = validatePatentMember(row, list, mode, index);
                            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
                          },
                        },
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field>
                    <ListEdit
                      header={{ title: 'Исполнители заказа' }}
                      rows={performers}
                      onChange={handlePerformersFieldChange}
                      toolbar={['add', 'edit', 'delete']}
                      columns={[
                        { label: 'ФИО', formatValue: x => x.person.fullName },
                        {
                          label: 'Место работы',
                          formatValue: x => (x.job ? getJobLabel(x.job) : ''),
                        },
                        { label: 'Контакты', formatValue: x => getPersonContacts(x.person.contacts) },
                      ]}
                      withMessages
                      defaultRowsCount={8}
                      isDeleteConfirmEnabled
                      isDisabled={workMode === 'viewMode'}
                      specification={{
                        mode: 'customComponent',
                        renderComponent: (row, onFieldChange) => <PerformerFields entry={row} onFieldChange={onFieldChange} />,
                        validation: {
                          checkIsValid: (row, index, list, mode) =>
                            validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
                          onInvalidate: (row, mode, index, list) => {
                            const validationInfo = validatePatentMember(row, list, mode, index);
                            showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
                          },
                        },
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </Tab>
          <Tab title="Прикрепленные документы">
            <Documents documents={documents} setDocuments={handleDocumentsFieldChange} disabled={workMode === 'viewMode'} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PatentResearchForm);

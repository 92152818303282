import { useCallback, useLayoutEffect, useMemo, useState } from 'react';

import { ButtonProps } from 'components';
import * as BackendAPI from 'services/BackendAPI';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { ProgramRequestTab } from 'utils/Permissions/ProgramRequestTab';
import { NirRequestTab } from 'utils/Permissions/NirRequestTab';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { MobileRequestStatus, NirRequestStatus, RequestType, TenderRequestType } from 'utils/Enums';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { showNotification } from 'features/Notifications';
import { MobileRequestTab } from 'utils/Permissions/MobileRequestTab';
import { serialize } from 'features/AppData/helpers';
import { Mode } from 'components/ListEdit/model';
import { Color } from 'constants/colors';

type Props = {
  tableState: Table.State;
  action: string;
};

export function useController(props: Props) {
  const { tableState, action } = props;
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = selectedRows[0] || null;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [tenderInfo, setTenderInfo] = useState<TenderInfo | null>(null);
  const [tenderRequestType, setTenderRequestType] = useState<TenderRequestType | null>(null);
  const [isStatusChangeModalOpen, setIsStatusChangeModalOpen] = useState<boolean>(false);
  const [statusChangeMessage, setStatusChangeMessage] = useState<string>('');

  const { methods: changeRequestStatus } = BackendAPI.useBackendAPI('ChangeRequestStatus');
  const { methods: changeNirRequestStatus } = BackendAPI.useBackendAPI('ChangeNirRequestStatus');
  const { methods: changeMobileRequestStatus } = BackendAPI.useBackendAPI('ChangeMobileRequestStatus');

  const requestType = useMemo<RequestType>(() => selectedRow?.['id:Type'] as RequestType, [selectedRow]);

  const isEditPermitted = useMemo(() => {
    if (!selectedRow) return false;
    if (requestType === RequestType.PROGRAM_REQUEST) {
      return isHasPermission(
        userPermission,
        (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
          tabKey => Permits[`PROGRAM_REQUEST_EDIT_${selectedRow?.[`id:ProgramRequestStatus`] as RequestStatus}_${tabKey}`],
        ),
      );
    }
    if (requestType === RequestType.NIR_REQUEST) {
      return isHasPermission(
        userPermission,
        (Object.keys(NirRequestTab) as Array<NirRequestTab>).map(
          tabKey => Permits[`NIR_REQUEST_EDIT_${selectedRow?.[`id:NirRequestStatus`] as NirRequestStatus}_${tabKey}`],
        ),
      );
    }
    if (requestType === RequestType.MOBILE_REQUEST) {
      return isHasPermission(
        userPermission,
        (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
          tabKey => Permits[`MOBILE_REQUEST_EDIT_${selectedRow?.[`id:MobileRequestStatus`] as MobileRequestStatus}_${tabKey}`],
        ),
      );
    }
    return false;
  }, [requestType, selectedRow, userPermission]);

  const isDeletePermitted = useMemo(() => {
    if (requestType === RequestType.PROGRAM_REQUEST) return isHasPermission(userPermission, Permits.PROGRAM_REQUEST_DELETE_DRAFT);
    if (requestType === RequestType.NIR_REQUEST) return isHasPermission(userPermission, Permits.NIR_REQUEST_DELETE_DRAFT);
    if (requestType === RequestType.MOBILE_REQUEST) return isHasPermission(userPermission, Permits.MOBILE_REQUEST_DELETE_DRAFT);
    return false;
  }, [requestType, userPermission]);

  const isStatusChangePermitted = useMemo(() => {
    return (
      (requestType === RequestType.PROGRAM_REQUEST &&
        isHasPermission(userPermission, Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST) &&
        [RequestStatus.DRAFT, RequestStatus.REVISION].includes(selectedRow['id:ProgramRequestStatus'] as RequestStatus)) ||
      (requestType === RequestType.NIR_REQUEST &&
        isHasPermission(userPermission, Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST) &&
        [NirRequestStatus.DRAFT, NirRequestStatus.REVISION].includes(selectedRow['id:NirRequestStatus'] as NirRequestStatus)) ||
      (requestType === RequestType.MOBILE_REQUEST &&
        isHasPermission(userPermission, Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST) &&
        [MobileRequestStatus.DRAFT, MobileRequestStatus.REVISION].includes(
          selectedRow['id:MobileRequestStatus'] as MobileRequestStatus,
        ))
    );
  }, [requestType, selectedRow, userPermission]);

  const handleViewButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsViewFormOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);

  const handleEditButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsEditFormOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);

  const handleAddButtonClick = useCallback(() => {
    setTenderInfo(null);
    setTenderRequestType(null);
    setIsAddFormOpen(true);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command:
        requestType === RequestType.PROGRAM_REQUEST
          ? 'DeleteRequest'
          : requestType === RequestType.NIR_REQUEST
          ? 'DeleteNirRequest'
          : requestType === RequestType.MOBILE_REQUEST
          ? 'DeleteMobileRequest'
          : 'UNKNOWN_TYPE',
      deletedItemPropName:
        requestType === RequestType.PROGRAM_REQUEST
          ? 'RequestInfo'
          : requestType === RequestType.NIR_REQUEST
          ? 'NirRequest'
          : requestType === RequestType.MOBILE_REQUEST
          ? 'MobileRequest'
          : 'UNKNOWN_TYPE',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [requestType, selectedRow?.id, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRow?.IsLeaderOrResponsiblePerformer === 'true' || requestType === RequestType.MOBILE_REQUEST)
      setIsDeleteConfirmPopupOpen(true);
    else showNotification({ message: 'Вы не являетесь руководителем или ответственным исполнителем', theme: 'danger' });
  }, [requestType, selectedRow?.IsLeaderOrResponsiblePerformer]);

  const successfullCallback = useCallback(() => {
    showNotification({ message: 'Статус заявки изменен', theme: 'success' });
    tableStreams.reloadTable.push({});
    setIsStatusChangeModalOpen(false);
    setStatusChangeMessage('');
  }, [tableStreams.reloadTable]);

  const statusChangeConfirm = useCallback(() => {
    if (requestType === RequestType.PROGRAM_REQUEST)
      changeRequestStatus.callAPI(
        {
          ids: [selectedRow?.id],
          status: RequestStatus.REQUEST,
          message: statusChangeMessage,
        },
        {
          onSuccessfullCall: successfullCallback,
        },
      );

    if (requestType === RequestType.NIR_REQUEST)
      changeNirRequestStatus.callAPI(
        {
          ids: [selectedRow?.id],
          status: NirRequestStatus.REQUEST,
          message: statusChangeMessage,
        },
        {
          onSuccessfullCall: successfullCallback,
        },
      );

    if (requestType === RequestType.MOBILE_REQUEST)
      changeMobileRequestStatus.callAPI(
        {
          ids: [selectedRow?.id],
          status: MobileRequestStatus.REQUEST,
          message: statusChangeMessage,
        },
        {
          onSuccessfullCall: successfullCallback,
        },
      );
  }, [
    changeMobileRequestStatus,
    changeNirRequestStatus,
    changeRequestStatus,
    requestType,
    selectedRow?.id,
    statusChangeMessage,
    successfullCallback,
  ]);

  useLayoutEffect(() => {
    if (!action) {
      return;
    }

    const params = serialize.get(action) as {
      mode: Mode;
      requestType: TenderRequestType;
      tenderId: string;
      lotId: string;
    };

    if (params.mode === 'add') {
      setTenderRequestType(params.requestType);
      setTenderInfo({
        tenderId: params.tenderId,
        lotId: params.lotId,
      });
      setIsAddFormOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: !selectedRow,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: [Permits.PROGRAM_REQUEST_ADD, Permits.NIR_REQUEST_ADD, Permits.MOBILE_REQUEST_ADD] },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: {
          name: [
            ...(Object.keys(RequestStatus) as Array<RequestStatus>).flatMap(statusKey =>
              (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
                tabKey => Permits[`PROGRAM_REQUEST_EDIT_${statusKey}_${tabKey}`],
              ),
            ),
            ...(Object.keys(NirRequestStatus) as Array<NirRequestStatus>).flatMap(statusKey =>
              (Object.keys(NirRequestTab) as Array<NirRequestTab>).map(
                tabKey => Permits[`NIR_REQUEST_EDIT_${statusKey}_${tabKey}`],
              ),
            ),
            ...(Object.keys(MobileRequestStatus) as Array<MobileRequestStatus>).flatMap(statusKey =>
              (Object.keys(MobileRequestTab) as Array<MobileRequestTab>).map(
                tabKey => Permits[`MOBILE_REQUEST_EDIT_${statusKey}_${tabKey}`],
              ),
            ),
          ],
        },
        isDisabled: !selectedRow || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: {
          name: [Permits.PROGRAM_REQUEST_DELETE_DRAFT, Permits.MOBILE_REQUEST_DELETE_DRAFT, Permits.NIR_REQUEST_DELETE_DRAFT],
        },
        isDisabled:
          ![
            selectedRow?.['id:ProgramRequestStatus'],
            selectedRow?.['id:NirRequestStatus'],
            selectedRow?.['id:MobileRequestStatus'],
          ].includes('DRAFT') || !isDeletePermitted,
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => setIsStatusChangeModalOpen(true),
        permission: {
          name: [
            Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
            Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST,
            Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST,
          ],
        },
        isDisabled: selectedRows.length !== 1 || !isStatusChangePermitted,
      },
    ],
    [
      handleAddButtonClick,
      handleDeleteButtonClick,
      handleEditButtonClick,
      handleViewButtonClick,
      isDeletePermitted,
      isEditPermitted,
      isStatusChangePermitted,
      selectedRow,
      selectedRows.length,
    ],
  );

  return {
    isHelpFormOpen,
    setIsHelpFormOpen,
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    requestType,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    tenderInfo,
    setTenderInfo,
    tenderRequestType,
    setTenderRequestType,
    statusChangeMessage,
    setStatusChangeMessage,
    isStatusChangeModalOpen,
    setIsStatusChangeModalOpen,
    statusChangeConfirm,
    selectedRow,
  };
}

import { useCallback } from 'react';

export const YearMask = () => {
  return {
    // Mask
    mask: (value: string = '') => [/[1-2]/, value[0] === '1' ? /[9]/ : /[0]/, /[0-9]/, /[0-9]/],

    // Validate
    pipe: useCallback((conformedValue: any) => {
      if (conformedValue.length < 4) {
        return false;
      }

      return { value: conformedValue, indexesOfPipedChars: [] };
    }, []),
  };
};

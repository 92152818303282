import { useState, useCallback, useMemo, useEffect, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Author, Document, FileInfo, Form } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate } from './validate';
import { useFormContext } from 'features/Form/hooks';
import { formatPaymentRequestData } from '../helpers';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { ModePublicationHeader, PublicationHeader } from 'features/Form/views/ModalHeader/publication';
import { showNotification } from 'features/Notifications';
import { EMPTY_FILE } from 'utils/Constants';

type Props = {
  onClose: () => void;
};

const tabsId = 'PublicationFormMonographyArticle';

export function useController({ onClose }: Props) {
  const {
    look: {
      type,
      id,
      isElectronic,
      electronicType,
      relatedTableState,
      name,
      viewMode,
      editMode,
      parentPublicationId,
      isRequestPaymentView = false,
      isShowDetailedTitle = false,
      arm,
      setTitle,
    },
  } = useFormContext<Form.PublicationFormLook>();
  const { isProfile } = usePrivatePageContext();

  const { currentPerson, initialLanguageCode, settings, userSystemDepartment } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const [publicationId, setPublicationId] = useState<string | undefined>(id);
  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [projects, setProjects] = useState<PublicationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<PublicationMobileRequest[]>([]);
  const [sourceMonograph, setSourceMonograph] = useState<Form.Compilation | null>(null);
  const [sourcePublicationDate, setSourcePublicationDate] = useState('');
  const [isDisableSource, setIsDisableSource] = useState(false);
  const [isRecordElectronic, setIsRecordElectronic] = useState<boolean>(!!isElectronic);
  const [recordElectronicType, setRecordElectronicType] = useState<string>(electronicType || '');
  const [paymentString, setPaymentString] = useState('');
  const [foreignSource, setForeignSource] = useState('');
  const [authors, setAuthors] = useState<Author[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [topMessage, setTopMessage] = useState<string>('');
  const [userDepartments, setUserDepartments] = useState<ReferenceItem[]>([]);
  const [publicationDepartment, setPublicationDepartment] = useState<ReferenceItem | null>(null);
  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      required: true,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    annotation: {
      value: '',
      isValid: true,
      required: true,
      title: 'Аннотация',
      tooltipText: 'Если у публикации отсутствует аннотация, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          annotation: { ...prevState.annotation, isValid: true },
        }));
      },
    },
    authorsKeyWords: {
      value: '',
      isValid: true,
      required: true,
      title: 'Авторские ключевые слова',
      tooltipText: 'Если у публикации отсутствуют ключевые слова, то введите текст "Нет"',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsKeyWords: { ...prevState.authorsKeyWords, value },
        }));
      },
    },
    bibliographicRecord: {
      value: '',
      isValid: true,
      title: 'Библиографическая запись',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          bibliographicRecord: { ...prevState.bibliographicRecord, value },
        }));
      },
    },
    doi: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          doi: { ...prevState.doi, value },
        }));
      },
    },
    authorsCount: {
      value: '',
      isValid: true,
      title: 'Всего авторов в публикации',
      required: true,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          authorsCount: { ...prevState.authorsCount, isValid: true },
        }));
      },
    },
    fractionalScore: {
      value: '',
      isValid: true,
      required: false,
      title: 'Фракционный счёт университета',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fractionalScore: { ...prevState.fractionalScore, isValid: true },
        }));
      },
    },
    textLanguage: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Язык текста',
      required: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          textLanguage: {
            ...prevState.textLanguage,
            value,
            isValid: true,
          },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguage: { ...prevState.textLanguage, isValid: true },
        }));
      },
    },
    pageFrom: {
      value: '',
      isValid: true,
      title: 'Страницы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageFrom: { ...prevState.pageFrom, isValid: true },
        }));
      },
    },
    pageTo: {
      value: '',
      isValid: true,
      title: '-',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          pageTo: { ...prevState.pageTo, isValid: true },
        }));
      },
    },
    printPageCount: {
      value: '',
      isValid: true,
      title: 'Печатных листов',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          printPageCount: { ...prevState.printPageCount, isValid: true },
        }));
      },
    },
    chapterNumber: {
      value: '',
      isValid: true,
      title: 'Номер главы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          chapterNumber: { ...prevState.chapterNumber, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          chapterNumber: { ...prevState.chapterNumber, isValid: true },
        }));
      },
    },
    file: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          file: {
            ...prevState.file,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    grnti: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'ГРНТИ',
      tooltipText:
        // eslint-disable-next-line max-len
        'Выберите коды ГРНТИ отраслей знаний из справочника ГРНТИ. Если код отсутствует в справочнике, добавьте его',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          grnti: { ...prevState.grnti, value },
        }));
      },
    },
    udk: {
      value: '',
      isValid: true,
      title: 'УДК',
      tooltipText: 'Универсальная десятичная классификация. Введите код УДК, который присвоен публикации',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          udk: { ...prevState.udk, value },
        }));
      },
    },
    study: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Область науки',
      tooltipText:
        // eslint-disable-next-line max-len
        'Область науки выбрать из справочника. Если область науки отсутствует в справочнике, напишите запрос администратору на добавление области в справочник',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          study: { ...prevState.study, value },
        }));
      },
    },
    publicationLink: {
      value: '',
      isValid: true,
      required: !!isElectronic,
      tooltipText: 'Например: http://www.nir.ru/res/inv/ukazat25/index.php',
      title: 'Ссылка на публикацию',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationLink: { ...prevState.publicationLink, isValid: true },
        }));
      },
    },
    date: {
      value: '',
      isValid: true,
      required: !!isElectronic,
      title: 'Дата обращения',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          date: { ...prevState.date, value: nextDate || '' },
        }));
      },
    },
    electronicMediaSource: {
      value: '',
      isValid: true,
      required: false,
      title: 'Электронный носитель',
      tooltipText: 'Например: 1 электрон. опт диск (CD-R)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicMediaSource: {
            ...prevState.electronicMediaSource,
            value,
            isValid: true,
          },
        }));
      },
    },
    electronicSystemRequirements: {
      value: '',
      isValid: true,
      title: 'Системные требования',
      tooltipText: 'Например: Windows XP; CD-ROM; дисковод; мышь;',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          electronicSystemRequirements: {
            ...prevState.electronicSystemRequirements,
            value,
            isValid: true,
          },
        }));
      },
    },
    foreignName: {
      value: '',
      required: false,
      isValid: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, isValid: true },
        }));
      },
    },
    foreignAnnotation: {
      value: '',
      required: false,
      isValid: true,
      title: 'Аннотация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAnnotation: { ...prevState.foreignAnnotation, isValid: true },
        }));
      },
    },
    foreignAuthorsKeyWords: {
      value: '',
      required: false,
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthorsKeyWords: { ...prevState.foreignAuthorsKeyWords, value },
        }));
      },
    },
    foreignAuthors: {
      value: '',
      required: false,
      isValid: true,
      title: 'Авторы',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignAuthors: { ...prevState.foreignAuthors, isValid: true },
        }));
      },
    },
  });

  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { methods: getUserDepartments, state: getUserDepartmentsState } = BackendAPI.useBackendAPI('GetUserDepartments');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLICATION_FORM_MONOGRAPHY_ARTICLE', []);

  const fetchSourceMonographPublication = useCallback(
    (sourceMonographPublicationId: string) => {
      getPublication.callAPI(
        {
          id: sourceMonographPublicationId,
          simpleFields: {
            status: 'ADDED',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          projects: [],
          arm,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setSourceMonograph({
              id: data.status.id,
              name: data.fields.bibliographicRecord,
              bibliographic: data.fields.bibliographicRecord,
              status: data.status.text,
              type: data.type,
            });
            setForeignSource(data.fields.foreignName || '');
            setSourcePublicationDate(data.fields.publicationDate);
          },
          onFailedCall: () => {},
        },
      );
    },
    [arm, getPublication],
  );

  const fetchPublication = useCallback(() => {
    if (type) {
      getPublication.callAPI(
        {
          simpleFields: {
            status: PublicationStatus.DRAFT,
            typeEdition: 'LOCAL',
            type,
            isElectronic: isRecordElectronic,
            electronicType: recordElectronicType,
            presetAuthorCategory: arm === 'pc' ? 'author' : '',
          },
          attrIdFields: {},
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          mobileRequests: {},
          projects: [],
          file: undefined,
          id: publicationId,
          arm,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setByResponse({ setFormFields, preparedData: data, name });
            setPaymentString(data.monographPaymentRequests.map(i => formatPaymentRequestData(i)).join(', '));
            setIsRecordElectronic(data.fields.isElectronic === 'true');
            setRecordElectronicType(data.fields.electronicType);
            setPublicationId(data?.status?.id);
            setPublicationInfo(data);
            setProjects(data.projects);
            setMobileRequests(data.mobileRequests);
            setAuthors(data.members);
            setDocuments(data.documents);
            if (parentPublicationId) {
              fetchSourceMonographPublication(parentPublicationId);
              setIsDisableSource(true);
            } else if (data.monograph) {
              setSourceMonograph(data.monograph);
              fetchSourceMonographPublication(data.monograph.id);
            }
          },
        },
      );
    }
  }, [
    type,
    getPublication,
    isRecordElectronic,
    recordElectronicType,
    arm,
    publicationId,
    name,
    parentPublicationId,
    fetchSourceMonographPublication,
  ]);

  const prepareFormFields = useCallback(() => {
    const preparedFormFields = { ...formFields };

    const draftValidationResult = validate({
      formFields: preparedFormFields,
      authors,
      sourceMonograph,
      isDraft: true,
    });
    const validationResult = validate({
      formFields: preparedFormFields,
      authors,
      sourceMonograph,
      isDraft: false,
    });

    return {
      draftValidationResult,
      validationResult,
      preparedFormFields,
    };
  }, [authors, formFields, sourceMonograph]);

  const fetchBibliographicRecord = useCallback<Form.FetchBibliographicRecordAction>(
    () =>
      new Promise(resolve => {
        if (!formFields.name.value) {
          showNotification({ theme: 'danger', message: 'Не заполнено название' });
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            name: { ...prevState.name, isValid: false },
          }));
          resolve(null);
          return;
        }
        const fieldsForRequest = formatToRequest({
          isElectronic: isRecordElectronic,
          electronicType: recordElectronicType,
          formFields,
          projects,
          mobileRequests,
          type,
          authors,
          status: 'ADDED',
          sourceMonograph,
        });
        getPublication.callAPI(
          {
            ...fieldsForRequest,
            isBibliographicFetch: true,
            arm,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setFormFields((prevState: Form.Fields) => ({
                ...prevState,
                bibliographicRecord: { ...prevState.bibliographicRecord, value: data.fields.bibliographicRecord },
              }));
              resolve(data.fields.bibliographicRecord);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [
      arm,
      authors,
      formFields,
      getPublication,
      isRecordElectronic,
      mobileRequests,
      projects,
      recordElectronicType,
      sourceMonograph,
      type,
    ],
  );

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const currentFormFields = preparedFormFields || formFields;
      const isMessage = !!publicationMessageType?.id;
      const fieldsForRequest = formatToRequest({
        isElectronic: isRecordElectronic,
        electronicType: recordElectronicType,
        formFields: currentFormFields,
        projects,
        mobileRequests,
        type,
        authors,
        status: nextStatus,
        sourceMonograph,
      });
      savePublicationAPI.callAPI(
        {
          id: publicationId,
          ...fieldsForRequest,
          documents,

          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          arm,
          department: publicationDepartment,
        },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.success) {
              setPublicationId(data.status.id);
              if (isNeedClose) {
                onClose();
                if (!isRequestPaymentView) {
                  tableStreams.reloadTable.push({});
                }
              } else {
                updateWorkModeAfterSaveAndContinue();
                if (workMode === 'editMode') {
                  fetchPublication();
                }
              }
            }

            showNotification({
              theme: data.success ? 'success' : 'danger',
              message: publicationId
                ? data.success
                  ? nextStatus === PublicationStatus.DRAFT
                    ? 'Черновик успешно сохранен!'
                    : 'Публикация успешно отредактирована!'
                  : data.message || 'Произошла ошибка при редактировании публикации!'
                : data.success
                ? nextStatus === PublicationStatus.DRAFT
                  ? 'Черновик успешно сохранен!'
                  : 'Публикация успешно сохранена!'
                : data.message || 'Произошла ошибка при сохранении публикации!',
            });
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      arm,
      authors,
      documents,
      fetchPublication,
      formFields,
      isRecordElectronic,
      isRequestPaymentView,
      mobileRequests,
      onClose,
      projects,
      publicationDepartment,
      publicationId,
      recordElectronicType,
      savePublicationAPI,
      sourceMonograph,
      tableStreams.reloadTable,
      type,
      updateWorkModeAfterSaveAndContinue,
      workMode,
    ],
  );

  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      suchLikePublicationStreams.getSuchLikePublications.push({
        category: 'PUBLICATION',
        searchValue: formFields.name.value as string,
        successfullCallback,
        publicationType: publicationInfo?.type as Form.PublicationTypeCode,
        currentId: publicationId || null,
        isDefaultSave: !isNeedClose,
        componentId: SUCH_LIKE_PUBLICATION_ID,
      });
    },
    [
      publicationInfo?.type,
      SUCH_LIKE_PUBLICATION_ID,
      formFields.name?.value,
      publicationId,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
    ],
  );

  const {
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    loadPersonAuthorState,
  } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as PublicationStatus) || PublicationStatus.DRAFT,
    currentStatusText: publicationInfo?.status.text || 'Черновик',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    fetchBibliographicRecord,
    initialLanguageCode: initialLanguageCode?.id ?? '',
    authors,
    setAuthors,
    isProfile,
    currentPerson,
    type,
    tabsId,
    authorsTabIndex: 1,
  });

  const { isLoading } = useIsLoading({
    callStates: [getPublicationState, savePublicationAPIState, loadPersonAuthorState, getUserDepartmentsState],
  });

  const onCreateBibliographicRecordClick = useCallback(() => {
    fetchBibliographicRecord();
  }, [fetchBibliographicRecord]);

  useEffect(() => {
    if (publicationId || workMode === 'addMode') {
      fetchPublication();
    }
    // eslint-disable-next-line
  }, [publicationId]);

  const onDeleteSourceCompilation = () => {
    setSourceMonograph(null);
    setSourcePublicationDate('');
  };

  const changeSourceCompilation = (monograph: Form.Compilation | null) => {
    setSourceMonograph(monograph);
    if (monograph) {
      fetchSourceMonographPublication(monograph.id);
    }
  };

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = [...(publicationInfo?.citationSystems || [])];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  useEffect(() => {
    setTitle?.(
      PublicationHeader({
        settings,
        mode: ModePublicationHeader.default,
        workMode,
        publicationId: publicationInfo?.status.id || '',
        publicationTypeCode: publicationInfo?.type || '',
        publicationElectronicTypeCode: isElectronic ? electronicType : '',
        departments: publicationInfo?.departments || [],
        status: publicationInfo?.status.text || '',
        publicationTypeEdition: {
          id: publicationInfo?.fields.typeEditionCode || '',
          label: publicationInfo?.fields.typeEditionText || '',
        },
        isPreprint: false,
      }),
    );
  }, [
    electronicType,
    isElectronic,
    publicationInfo?.departments,
    publicationInfo?.fields.typeEditionCode,
    publicationInfo?.fields.typeEditionText,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  useLayoutEffect(() => {
    if (!id)
      getUserDepartments.callAPI(
        { isParent: false },
        {
          onSuccessfullCall: ({ data }) => {
            const d = data.map(x => x.ref);
            setUserDepartments(d);
            setPublicationDepartment(d.find(x => x.id === userSystemDepartment?.id) || null);
          },
        },
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isElectronic: isRecordElectronic,
    electronicType: recordElectronicType,
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    workMode,
    formFields,
    documents,
    setDocuments,
    onCreateBibliographicRecordClick,
    sourceMonograph,
    changeSourceCompilation,
    onDeleteSourceCompilation,
    authors,
    setAuthors,
    projects,
    mobileRequests,
    relatedTableState,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    publicationInfo,
    publicationId,
    isDisableSource,
    paymentString,
    topMessage,
    setTopMessage,
    isRequestPaymentView,
    isShowDetailedTitle,
    sourcePublicationDate,
    foreignSource,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    arm,
    tabsId,
    setProjects,
    setMobileRequests,
  };
}

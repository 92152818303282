import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import useController from './controller';
import { MagazineGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpForm,
    setIsOpenHelpForm,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    isEditButtonDisabled,
    handleCloseEditForm,
    isCitationButtonDisabled,
    isCitationFormOpen,
    handleTemplateCloseCitationForm,
    isArticleButtonDisabled,
    isArticleFormOpen,
    handleTemplateCloseArticleForm,
    isReleaseButtonDisabled,
    isReleaseFormOpen,
    handleTemplateCloseReleaseForm,
    isIdintifierButtonDisabled,
    handleTemplateCloseIdentifierForm,
    isIdentifierFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isViewButtonDisabled,
    buttons,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.MagazineAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        relatedTableState={tableState}
      />

      {!isViewButtonDisabled && (
        <modalTemplates.MagazineViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <modalTemplates.MagazineEditTemplate.Component
        isOpen={isEditFormOpen && !isEditButtonDisabled}
        onClose={handleCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      {!isCitationButtonDisabled && (
        <modalTemplates.MagazineCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleTemplateCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isArticleButtonDisabled && (
        <modalTemplates.MagazineArticleTemplate.Component
          isOpen={isArticleFormOpen}
          onClose={handleTemplateCloseArticleForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isReleaseButtonDisabled && (
        <modalTemplates.MagazineReleaseTemplate.Component
          isOpen={isReleaseFormOpen}
          onClose={handleTemplateCloseReleaseForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isIdintifierButtonDisabled && (
        <modalTemplates.MagazineIdentifiersTemplate.Component
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          onClose={handleTemplateCloseIdentifierForm}
          isOpen={isIdentifierFormOpen}
        />
      )}

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.name}) Удалить журнал?</>
        </Modal>
      )}

      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={PublicationTypes.MAGAZINE.code}
        />
      )}

      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0]?.id}
          publicationTypeCode={PublicationTypes.MAGAZINE.code}
        />
      )}

      <Modal
        mode="help"
        title="Публикации"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {MagazineGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

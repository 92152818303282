import { ReferenceItem } from 'components';
import { Mode } from 'components/ListEdit/model';

import { Author, Award, Person } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ExtendedMember } from './model';
import { EventTypeName } from './eventTypes';

export const USER_ROLES = {
  SUPERVISOR: {
    code: 'SUPERVISOR',
  },
  PARTICIPANT: {
    code: 'PARTICIPANT',
  },
};

// eslint-disable-next-line max-len
export const MAIN_REPORTER_TOOLTIP_TEXT: string = `Основной докладчик должен быть указан первым в списке.\nДля перемещения записи об участнике конференции используйте кнопки: Вверх/Вниз.\nЕсли доклад является одним из результатов проекта, и проект указан на вкладке "Связи с проектами", то Данные о докладчике и докладе будут загружены в Единую государственную информационную систему учета результатов научно-исследовательских и опытно-конструкторских работ (ЕГИСУ НИОКТР)`;

export const ALT_PARTICIPANTS_TOOLTIP_TEXT: string =
  'Добавьте участников и сведения о них (История персоны). Руководителя участника или группы участников добавляйте последним.';

export function formatEducation(education: Person.ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;
  return `${status.label}, ${enterprise}, ${refSpeciality?.label ?? speciality}, ${refDepartment?.label ?? department}`;
}

export function getBiblioName(scientistPerson: Person.ScientistPerson, language: ReferenceItem) {
  return scientistPerson.bibliographicNames.find(x => x.isDefault && language.id === x.language?.id)?.name ?? '';
}
export function formatRole(author: Author) {
  const { role } = author;

  return `
    <span>${role?.label || ''}</span>
  `;
}

export function formatJob(job: Person.ScientistJob) {
  const { enterpise, appointment, status, staffGroup, appointmentCategory, refAppointment } = job;
  const appointmentStr =
    staffGroup && appointmentCategory ? `(${staffGroup?.label ?? ''}, ${appointmentCategory?.label ?? ''})` : '';

  return `${enterpise}, ${refAppointment?.label || appointment}, ${appointmentStr} ${status?.label}`;
}

export function isCollectiveAuthor(category: Author['category']) {
  return category?.id === '2';
}

export function formatMainAuthor(author: Author) {
  const { job, education, person, historyLabel } = author;

  return `
     <strong>${person?.fullName ?? ''}.</strong>
    ${historyLabel || ''}
    ${!historyLabel && job ? `${formatJob(job)};` : ''}
    ${!historyLabel && education ? `${formatEducation(education)}.` : ''}
    ${
      !job && !education && !isCollectiveAuthor(author.category)
        ? '<span class="state-danger">Не указаны сведения о работе или обучении</span>.'
        : ''
    }
  `;
}

export function formatAuthorAward({ author, awards }: { author: Author; awards: Award[] }) {
  return (
    awards
      .filter(award => award.recipients.some(recipient => recipient.id === author.id))
      .map(award => award?.category?.label)
      .join(', ') || ''
  );
}

export function formatAuthor(author: Author) {
  const { job, education, person, historyLabel, role, isReporter } = author;
  const roleString = role?.label ? `${role?.label}: ` : '';

  return `
    ${roleString}
     <strong>${person?.fullName ?? ''}.</strong>
    ${historyLabel || ''}
    ${!historyLabel && job ? `${formatJob(job)};` : ''}
    ${!historyLabel && education ? `${formatEducation(education)}.` : ''}
    ${
      !job && !education && !isCollectiveAuthor(author.category)
        ? '<span class="state-danger">Не указаны сведения о работе или обучении</span>.'
        : ''
    }
    ${isReporter ? '<strong>(Докладчик)</strong>' : ''}
  `;
}

export function validate(member: ExtendedMember | null, list: ExtendedMember[], mode: Mode, index: number | null) {
  const members = mode === 'edit' ? list.filter((_, memberIndex) => index !== memberIndex) : list;

  const isValidaMember = Boolean(member?.person);
  const isValidRole = Boolean(member?.role);

  const hasDublicates = members.some(existMember => {
    const isEqualPerson = existMember?.person?.id === member?.person?.id;
    const isEqualRole = existMember?.role?.id === member?.role?.id;
    return isEqualPerson && isEqualRole;
  });
  return [
    { isValid: isValidaMember, invalidMessage: 'Выберите участника' },
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: !hasDublicates, invalidMessage: 'Участник с такой ролью уже добавлен' },
  ];
}

export const getSupervisorWarningText = (eventType: EventTypeName) => {
  switch (eventType) {
    case EventTypes.EVENT_EXPOSITION.code:
      return 'В участниках экспоната, уже указан руководитель';
    case EventTypes.EVENT_CONCERT.code:
      return 'В участниках творческого мероприятия, уже указан руководитель';
    case EventTypes.EVENT_CONTEST.code:
      return 'В участниках конкурса, уже указан руководитель';
    default:
      return 'В участниках конференции, уже указан руководитель';
  }
};

export const getSupervisorInfoText = (eventType: EventTypeName) => {
  switch (eventType) {
    case EventTypes.EVENT_EXPOSITION.code:
      return 'Укажите участников авторского коллектива';
    case EventTypes.EVENT_CONCERT.code:
      // eslint-disable-next-line max-len
      return 'Укажите участника творческого мероприятия (участника коллектива)';
    case EventTypes.EVENT_CONTEST.code:
      return 'Укажите участника(ов) конкурса для связи с руководителем';
    default:
      return 'Укажите участников';
  }
};

export function getMockExtendedMember(): ExtendedMember {
  return {
    academicRank: null,
    affilateCount: null,
    category: null,
    citizenship: null,
    degree: null,
    education: null,
    enterprise: null,
    historyLabel: '',
    id: null,
    isAffilated: false,
    job: null,
    memberAffilations: [],
    name: '',
    person: null,
    position: 0,
    rank: null,
    isReporter: false,
    role: null,
    participantIds: [],
  };
}

import { useCallback, useMemo } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({ customState: { workMode }, tableState, onSubmitTable }: Props) {
  const getSelectedRow = useCallback(() => {
    if (tableState.selectedRows.length) {
      const [row] = tableState.selectedRows;
      return row;
    }
    return null;
  }, [tableState.selectedRows]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: () => onSubmitTable?.(tableState),
        isDisabled: getSelectedRow() === null || workMode === 'viewMode',
      },
    ],
    [getSelectedRow, workMode, onSubmitTable, tableState],
  );

  return {
    buttons,
  };
}

import React, { useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { sendMailToAdmin } from 'shared/sendMailToAdmin';

function Toolbar() {
  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'admin' },
        title: 'Написать администратору',
        onClick: sendMailToAdmin,
      },
    ],
    [],
  );

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };

import { StreamRx, makeStream } from 'StreamRx';

import { Notification } from './index';

type NotificationsStreams<T> = {
  [K in keyof T]: T[K];
};

export function useLocalStreams<T>(localStreams: T): NotificationsStreams<T> {
  return Object.fromEntries(
    Object.entries(localStreams as StreamRx<any>[]).map(([key, stream]: [string, any]) => [
      key,
      {
        push: (data: any) => stream.push(data),
        observable: stream.observable,
      },
    ]),
  ) as NotificationsStreams<T>;
}

type NotificationsStreamData<Data = object> = Data;

const streams = {
  addNotification: makeStream<NotificationsStreamData<Notification>>(),
};

export function useLocalNotificationsStreams() {
  return useLocalStreams(streams);
}

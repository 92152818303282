import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { Person } from 'types/models';
import { EditableHonor } from 'features/Form/looks/person/model';

export function formatName(item: Person.ScientistDegree | Person.ScientistAcademicRank | Person.ScientistRank) {
  if (Person.isScientistDegree(item)) {
    return `Уч. степень ${item.refDegree?.label}`;
  }
  if (Person.isScientistRank(item)) {
    return `Уч. звание ${item.refRank.label}`;
  }
  return `Академ. звание ${item.refAcademicRank.label}`;
}

export const getHonorLinkedHistoryType = (honor: EditableHonor | null): LinkedHistoryType | null => {
  switch (honor?.type) {
    case 'academicRank':
      return LinkedHistoryType.ACADEMIC_RANK;
    case 'degree':
      return LinkedHistoryType.DEGREE;
    case 'rank':
      return LinkedHistoryType.RANK;
    default:
      return null;
  }
};

import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { EditPublicationOriginalTranslatesRelations } from '../EditPublicationOriginalTranslatesRelations';

type Props = {
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State | undefined;
  onSuccess: () => void;
};

const PublicationOriginalTranslatesRelations = ({ onClose, publicationId, relatedTableState, onSuccess }: Props) => {
  const {
    submitForm,
    isRelationsErrorPopupOpened,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    magazinePublicationTranslates,
    magazinePublication,
    magazineArticlePublication,
    magazineArticlePublicationTranslates,
    magazineArticlePublicationOriginal,
    setMagazineArticlePublicationTranslates,
    setMagazineArticlePublicationOriginal,
  } = useController({ onClose, publicationId, onSuccess });
  return (
    <FormComponent.Wrapper>
      <EditPublicationOriginalTranslatesRelations
        isMagazineOriginal={isMagazineOriginal}
        isMagazineTranslate={isMagazineTranslate}
        magazinePublicationOriginal={magazinePublicationOriginal}
        magazinePublicationTranslates={magazinePublicationTranslates}
        magazineArticlePublicationOriginal={magazineArticlePublicationOriginal}
        magazineArticlePublicationTranslates={magazineArticlePublicationTranslates}
        isRelationsErrorPopupOpened={isRelationsErrorPopupOpened}
        magazinePublicationName={magazinePublication?.fields.name || ''}
        magazineArticlePublication={magazineArticlePublication}
        relatedTableState={relatedTableState}
        setMagazineArticlePublicationTranslates={setMagazineArticlePublicationTranslates}
        setMagazineArticlePublicationOriginal={setMagazineArticlePublicationOriginal}
        onRelationsErrorPopupClose={onClose}
        onRelationsErrorPopupSubmit={onClose}
      />
      <FormComponent.Actions>
        <Button mode={ButtonMode.PRIMARY} text="Сохранить" onClick={submitForm} />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(PublicationOriginalTranslatesRelations);

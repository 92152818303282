import { NirRequest } from 'types/models';
import { NirRequestPerformerRole } from 'utils/Enums';

export function validate(nir: NirRequest.NirRequest | null) {
  if (!nir) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidName = Boolean(nir.name);
  const isValidStages = Boolean(nir.stages.length);
  const isValidLeader = !!nir.performers.find(x => x.role?.value === NirRequestPerformerRole.LEADER);

  return [
    { isValid: isValidName, invalidMessage: 'Введите название проекта' },
    { isValid: isValidStages, invalidMessage: 'Заполните календарный план' },
    { isValid: isValidLeader, invalidMessage: 'Укажите руководителя в составе коллектива на вкладке "Коллектив"' },
  ];
}

import React from 'react';

import { FormComponent, Checkbox, Radio, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { KindConsumptionRadioOptions, useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Table } from 'types/models';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function KindConsumptionForm({ viewMode, editMode, onClose }: Props) {
  const {
    kindConsumption,
    handleFormSubmit,
    makeChangeHandler,
    selectedRadio,
    handleRadioChange,
    kindConsumptionLabel,
    taxSpecification,
    taxTableRowConverter,
    handleTaxChange,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Checkbox
              label="Действующий"
              checked={!!kindConsumption?.isActive}
              onChange={makeChangeHandler('isActive')}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расхода">{kindConsumption?.name}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Статья расходов">{kindConsumptionLabel}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КВР">{kindConsumption?.kvr?.label}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КОСГУ">{kindConsumption?.kosgu?.label}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="" labelWidth={5}>
            <Radio
              value={selectedRadio}
              list={[
                { label: 'Без дополнений', value: KindConsumptionRadioOptions.empty },
                { label: 'Рассчитать налог', value: KindConsumptionRadioOptions.isCalculateTax },
                {
                  label: 'Передавать накладные',
                  value: KindConsumptionRadioOptions.isSendOverhead,
                },
                {
                  label: 'Расчёты с соисполнителем',
                  value: KindConsumptionRadioOptions.isAccomplicePayment,
                },
              ]}
              onChange={e => handleRadioChange(e as KindConsumptionRadioOptions)}
              isInRow
            />
          </FormComponent.Field>
        </FormComponent.Line>
        {kindConsumption?.isCalculateTax === true && (
          <>
            <FormComponent.Line>
              <FormComponent.Field label="Процент налога" isRequired>
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={(kindConsumption?.percentTax || 0).toString()}
                  onChange={makeChangeHandler('percentTax')}
                  isDisabled={viewMode}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Вид расхода, налог">
                <TextArea
                  mode={TextAreaMode.TABLE}
                  settings={{
                    title: 'Виды расходов',
                    table: {
                      specification: taxSpecification,
                      onSelect: (row: Table.Entry | null) => handleTaxChange(row ? taxTableRowConverter(row) : null),
                    },
                  }}
                  value={[kindConsumption?.tax?.code, kindConsumption?.tax?.name].filter(Boolean).join(' ')}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            {kindConsumption?.tax?.id && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label="КВР">{kindConsumption?.tax?.kvr?.label}</FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="КОСГУ">{kindConsumption?.tax?.kosgu?.label}</FormComponent.Field>
                </FormComponent.Line>
              </>
            )}
          </>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(KindConsumptionForm);

import { Table } from 'types/models';
import { Filters } from './filters';
import { LeftPanelForThirdLevel } from '../GetProjectStateRegistrationReportList/LeftPanelForThirdLevel';
import { useAppDataContext } from 'features/AppData/context';

export function GetProjectStateRegistrationReportArchiveList(): Table.Specification {
  const { settings } = useAppDataContext();
  return {
    apiID: 'GetProjectStateRegistrationReportArchiveList',
    header: {
      firstLevel: {
        title: `Список научных отчётов в архиве ${settings?.organization?.shortName || 'университета'}`,
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel(true),
        withTemplatesPanel: true,
      },
    },
  };
}

import { Icon } from 'components';

export function TemplatesHelp(): JSX.Element {
  return (
    <>
      <h4>Панель инструментов для настройки таблицы</h4>

      <p>Каждый пользователь имеет возможность настроить таблицу с данными для решения своих повседневных задач.</p>

      <p>
        <strong>Включить/исключить столбцы</strong> в таблице — с помощью кнопки <Icon type="table" />.
      </p>

      <p>
        Чтобы <strong>переместить столбец</strong> установите курсор мыши на заголовок столбца и, нажав левую кнопку мыши,
        перенесите столбец на другую позицию в таблице.
      </p>

      <p>
        Для изменения <strong>ширины столбца</strong> наведите курсор мыши на правую границу заголовка столбца так, чтобы курсор
        принял вид двусторонней стрелки, нажав левую кнопку мыши, измените размер.
      </p>

      <p>
        <strong>Если заголовок столбца частично скрыт</strong>, наведите на него курсор мыши — отобразится полное название.
      </p>

      <p>
        Для <strong>сортировки данных</strong> «от большего значения к меньшему» — кликните по заголовку столбца. Второй клик мыши
        меняет направление сортировки. Третий клик отменяет сортировку по столбцу.
      </p>

      <p>
        Для <strong>поиска данных</strong> по значению предназначена строка с фильтрами <Icon type="filter" />. Очистить строку от
        условий фильтрации — <Icon type="filter" mode="remove" />.
      </p>

      <p>
        Для некоторых таблиц предусмотрено два режима работы: для переключения из <strong>Режима ввода</strong> данных в{' '}
        <strong>Режим для анализа</strong> служит кнопка <Icon type="toFull" />. И наоборот — <Icon type="toSmall" />
      </p>

      <p>
        Состояние таблицы можно сохранить под именем как <strong>Шаблон настройки таблицы</strong>.
      </p>

      <p>
        <strong>Шаблон</strong> — это персональная (авторская) настройка таблицы, сохранённая под конкретным названием. В шаблоне
        выборки сохраняется список, очередность и ширина столбцов таблицы; введённые фразы для поиска в конкретном столбце;
        параметры сортировки данных в столбце. Шаблон используется для перестройки таблицы в один клик. Список доступных шаблонов
        доступен по кнопке
        <Icon type="list" />
      </p>

      <div className="warning">
        <span>
          Если в таблице предусмотрено два режима работы: <strong>Режим ввода</strong> и <strong>Режим для анализа</strong>{' '}
          {/* eslint-disable-next-line max-len */}
          данных, то шаблон будет доступен в том режиме, в котором был создан. Если шаблон выборки имеет дополнительный атрибут{' '}
          {/* eslint-disable-next-line max-len */}
          <strong>Поделиться со всеми</strong>, такой шаблон будет доступен для выбора всем пользователям этой формы. Только{' '}
          <strong>автор</strong> шаблона имеет право изменить его настройки.
        </span>
      </div>

      <h4>Как создать шаблон</h4>

      <ol>
        <li>
          Очистите поле <strong>Шаблон настройки таблицы</strong>
        </li>
        <li>
          Выберите набор столбцов с помощью кнопки <strong>Выбрать столбцы</strong>
        </li>
        <li>Настройте внешний вид таблицы: порядок и ширину столбцов</li>
        <li>
          Откройте в таблице <strong>Строку для фильтрации</strong> и введите значения для выборки данных в столбцах.
        </li>
        <li>Для сортировки — щелчок левой кнопкой мыши по названию столбца</li>
        <li>
          Нажмите кнопку справа от поля Шаблон выборки <strong>Сохранить шаблон</strong> <Icon type="save" /> — откроется форма
          Создания шаблона
        </li>
        <li>
          Введите <strong>Название шаблона</strong>, в поле <strong>Описание шаблона</strong> — кратко опишите назначение и
          особенности, если считаете, что шаблон будет полезен вашим коллегам — <strong>Поделиться со всеми</strong>; нажмите
          кнопку <strong>Сохранить шаблон</strong>.
        </li>
        <li>
          Результат: После сохранения шаблона, в поле <strong>Шаблон настройки таблицы</strong> отобразится название
          {/* eslint-disable-next-line max-len */}
          сохранённого шаблона. Название шаблона будет присутствовать в списке доступных для формы шаблонов настройки таблицы{' '}
          <Icon type="list" />
        </li>
      </ol>

      <h4>Как изменить шаблон</h4>

      <ol>
        <li>
          Выберите в поле <strong>Шаблон настройки таблицы</strong> шаблон из списка <Icon type="list" />
        </li>
        <li>Измените настройки таблицы</li>
        <li>
          Нажмите кнопку справа от поля Шаблон выборки <strong>Сохранить шаблон</strong> <Icon type="save" /> откроется окно
          Сохранения шаблона:
          <ul>
            <li>По кнопке «Сохранить» - шаблон сохраниться с новыми настройками</li>
            <li>
              При выборе кнопки «Сохранить под другим именем» — откроется форма «Создание шаблона». Введите имя и описание шаблона
              и нажмите кнопку «Сохранить».
            </li>
          </ul>
        </li>
      </ol>

      <div className="warning">
        <span>
          Только <strong>автор</strong> шаблона имеет право изменить его настройки. Но вы всегда можете создать свой шаблон на
          основе чужого, сохранив его под другим именем.
        </span>
      </div>
    </>
  );
}

import { Dispatch, SetStateAction } from 'react';

import { ReferenceItem } from 'components';

import { Event, Form } from 'types/models';
import { Settings } from 'types/models/common';

type Props = {
  settings: Settings | null;
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  data: Event.Event;
  eventId: string | undefined;
  name: string | undefined;
  initialCountryCode: ReferenceItem | null;
};

const setByResponse = ({ settings, setFormFields, data, eventId, name, initialCountryCode }: Props) => {
  setFormFields(prevState => ({
    ...prevState,
    eventSeries: {
      ...prevState.eventSeries,
      value: data.eventSeries,
    },
    contestType: {
      ...prevState.contestType,
      value: data.contestType,
    },
    eventStatus: {
      ...prevState.eventStatus,
      value: data.eventStatus,
    },
    isStudent: {
      ...prevState.isStudent,
      value: data.isStudent,
    },
    orderNumber: {
      ...prevState.orderNumber,
      value: data.orderNumber,
    },
    orderDate: {
      ...prevState.orderDate,
      value: data.orderDate,
    },
    name: {
      ...prevState.name,
      value: name || data.name,
    },
    startDate: {
      ...prevState.startDate,
      value: data.startDate,
    },
    endDate: {
      ...prevState.endDate,
      value: data.endDate,
      required: eventId ? !!data.endDate : true,
    },
    termsType: {
      ...prevState.termsType,
      value: {
        id: eventId ? (data.endDate ? 'PERIOD' : 'DATE') : 'PERIOD',
        label: eventId ? (data.endDate ? 'Период' : 'Дата') : 'Период',
      },
    },
    description: {
      ...prevState.description,
      value: data.description,
    },
    keyWords: {
      ...prevState.keyWords,
      value: data.keyWords,
    },
    site: {
      ...prevState.site,
      value: data.site,
    },
    country: {
      ...prevState.country,
      value: eventId ? data.country : initialCountryCode,
    },
    city: {
      ...prevState.city,
      value: eventId ? data.city : settings?.organization?.city || '',
    },
    address: {
      ...prevState.address,
      value: data.address,
    },
    contact: {
      ...prevState.contact,
      value: data.contact,
    },
    memberCountPlan: {
      ...prevState.memberCountPlan,
      value: data.memberCountPlan,
    },
    memberCountFact: {
      ...prevState.memberCountFact,
      value: data.memberCountFact,
    },
  }));
};

export { setByResponse };

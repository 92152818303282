import { Table } from 'types/models';

import { EmployeeFund, EmployeeNominee, EmployeePayment } from 'types/models/Payment';
import { PaymentRequestStatus } from 'utils/Enums';

export function getMockEmployeeNominee(): EmployeeNominee {
  return {
    id: null,
    job: null,
    employee: null,
    amountMoney: '',
  };
}

export function getInitialAmount(fund?: Table.Entry | EmployeeFund | null) {
  if (!fund) return '';
  const factIncome = Number(fund.factIncome ?? 0);
  const planIncome = Number(fund.planIncome ?? 0);
  const factExpense = Number(fund.factExpense ?? 0);
  const planExpense = Number(fund.planExpense ?? 0);
  return (factIncome + planIncome - factExpense - planExpense).toString();
}

export function getInitialEmployeePayment(row: Table.Entry): EmployeePayment {
  return {
    amountMoney: getInitialAmount(row),
    documents: [],
    nominees: [],
    status: { value: PaymentRequestStatus.DRAFT, label: 'Черновик' },
    id: null,
    fund: {
      planIncome: row.planIncome ?? '',
      factIncome: row.factIncome ?? '',
      planExpense: row.planExpense ?? '',
      factExpense: row.factExpense ?? '',
      nomineePlanMoney: row.nomineePlanMoney ?? '',
      nomineeFactMoney: row.nomineeFactMoney ?? '',
      nomineeQuantity: row.nomineeQuantity ?? '',
      nominationQuantity: row.nominationQuantity ?? '',
      nominationFraction: row.nominationFraction ?? '',
      month: row.month ?? '',
      quarter: { value: row['id:quarter'], label: row.quarter },
      type: { value: row['id:type'], label: row.type },
      department: { id: row['id:faculty'], label: row.faculty },
      year: row.year || String(new Date().getFullYear()),
    },
  };
}

export function calcRemainderAmount(employeePayment: EmployeePayment | null) {
  if (!employeePayment) {
    return 0;
  }

  const sumAmounts = employeePayment.nominees.reduce((acc, nominee) => Number(nominee.amountMoney ?? 0) + acc, 0);
  return Number(employeePayment.amountMoney ?? 0) - sumAmounts;
}

export function calcShare(amountMoney: number, commonAmountMoney: number) {
  return (amountMoney / commonAmountMoney).toFixed(4);
}

export function checkIsBudgetOver(fund: Table.Entry | null, employeePayments: Table.Entry[]) {
  if (!fund) {
    return false;
  }
  const paidPayments = employeePayments.filter(x => x['id:status'] !== 'DRAFT');
  const paymentsSum = paidPayments.reduce((acc, cur) => acc + Number(cur.employeesAmount ?? 0), 0);
  const factIncome = Number(fund?.factIncome ?? 0);

  if (factIncome > 0) {
    return Number(fund.factIncome ?? 0) - paymentsSum === 0;
  }

  const planSumExpense =
    Number(fund.planExpensePaid ?? 0) + Number(fund.planExpenseRejected ?? 0) + Number(fund.planExpenseSent ?? 0);
  return Number(fund.planIncome ?? 0) - planSumExpense <= 0;
}

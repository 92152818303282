import React from 'react';
import block from 'bem-cn';

import { Button } from 'components';

import { HOURS_ARRAY, MINUTES_ARRAY } from './helpers';
import useController from './controller';

import './style.scss';

type Props = {
  selectedHour?: string;
  canResetValue?: boolean;
  selectedMinute?: string;
  setHours: (nextHours?: string) => void;
  setMinutes: (nextMinutes?: string) => void;
};

const b = block('time');

export const Timepicker = ({ selectedHour, canResetValue, selectedMinute, setMinutes, setHours }: Props) => {
  const { selectedHourRef, selectedMinuteRef } = useController();

  return (
    <div className={b()}>
      <div className={b('wrapper')}>
        <h3 className={b('list-title')}>Часы:</h3>
        <ul className={b('list', { hours: true }).mix('scroll-shadows')}>
          {HOURS_ARRAY.map(hour => {
            const isSelectedHour = hour === selectedHour;
            const nextValue = isSelectedHour && canResetValue ? undefined : hour;
            return (
              <li key={hour} className={b('item')} ref={hour === selectedHour ? selectedHourRef : undefined}>
                <Button
                  onClick={() => setHours(nextValue)}
                  classMixin={b('button', { selected: hour === selectedHour })}
                  text={hour}
                />
              </li>
            );
          })}
        </ul>
      </div>
      <div className={b('separator')}>:</div>
      <div className={b('wrapper')}>
        <h3 className={b('list-title')}>Минуты:</h3>
        <ul className={b('list', { minutes: true }).mix('scroll-shadows')}>
          {MINUTES_ARRAY.map(minute => {
            const isSelectedMinute = minute === selectedMinute;
            const nextValue = isSelectedMinute && canResetValue ? undefined : minute;
            return (
              <li key={minute} className={b('item')} ref={minute === selectedMinute ? selectedMinuteRef : undefined}>
                <Button
                  onClick={() => setMinutes(nextValue)}
                  classMixin={b('button', { selected: minute === selectedMinute })}
                  text={minute}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

import React from 'react';
import { MobileRequest, Table } from 'types/models';

import { FormComponent, Select, SelectMode, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { GetNUFinancingSourceList } from 'features/Table/specifications';
import { useController } from './controller';

type Props = {
  entry: MobileRequest.Financing | null;
  onFieldChange(row: MobileRequest.Financing | null): void;
};

function FinancingFields({ entry, onFieldChange }: Props) {
  const {
    handleTypeChange,
    handleSf1SourceChange,
    handleDepartmentChange,
    handleSf3SourceChange,
    handleAmountChange,
    handleNoteChange,
    isSf1Type,
    isSf2Type,
    isSf3Type,
    sf1SourceRowConverter,
  } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Тип источника" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={entry?.type}
            settings={{ name: 'MobileRequestFinancingType' }}
            onChange={handleTypeChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {isSf1Type && (
        <FormComponent.Line>
          <FormComponent.Field label="Источник" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Виды расхода',
                isClearable: false,
                table: {
                  specification: GetNUFinancingSourceList(),
                  onSelect: (row: Table.Entry | null) => handleSf1SourceChange(row ? sf1SourceRowConverter(row) : null),
                },
              }}
              value={entry?.sf1Source?.projectCodeValue || entry?.sf1Source?.fundCardValue}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {isSf2Type && (
        <FormComponent.Line>
          <FormComponent.Field label="Подразделение" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={entry?.department}
              onChange={handleDepartmentChange}
              settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {isSf3Type && (
        <FormComponent.Line>
          <FormComponent.Field label="Источник" isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={entry?.sf3Source}
              settings={{ name: 'MobileRequestFinancingSf3Source' }}
              onChange={handleSf3SourceChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Сумма, руб." isRequired>
          <TextInput mode={TextInputMode.NUMBER} value={entry?.amount} onChange={handleAmountChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание" isRequired={isSf3Type}>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={entry?.note || ''}
            onChange={handleNoteChange}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { FinancingFields };

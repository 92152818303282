import * as XML from 'xml-js';
import { makeBackendAPIConfiguration } from './makeBackendAPIConfiguration';

type CheckCompilationDuplicateResponseData = {
  success: boolean;
  message: string;
  duplicates: string;
};

const convertCheckCompilationDuplicateResponseXMLElementToOutput = (
  response: XML.ElementCompact,
): CheckCompilationDuplicateResponseData => {
  const success = response.Response._attributes.success === 'true';

  const output: CheckCompilationDuplicateResponseData = {
    success,
    message: success
      ? ''
      : response.Response._attributes.serverErrorMessage ||
        response.Response._attributes.errorMessage ||
        'Произошла ошибка при загрузке дубликатов',
    duplicates: response.Response.Duplicate?.AllDuplicate?._text || '',
  };

  return output;
};
export function makeBackendCompilationBackendConfiguration<T extends string, Input>(
  command: T,
  convertInputToXMLElement: (input: Input) => XML.ElementCompact,
) {
  return makeBackendAPIConfiguration({
    id: command,
    endpoint: `/msa/service/commands/${command}`,
    convertInputToXMLElement,
    convertResponseXMLElementToOutput: convertCheckCompilationDuplicateResponseXMLElementToOutput,
  });
}

import { ListEdit } from 'components';

import { Project } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { getMockPartnerFinancing } from 'features/Form/looks/project/ProjectForm/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  financings: Project.PartnerFinancing[];
  setFinancings(financings: Project.PartnerFinancing[]): void;
};

export function Financings(props: Props) {
  const { financings, setFinancings } = props;

  return (
    <ListEdit
      rows={financings}
      onChange={setFinancings}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Год', formatValue: x => x.year },
        { label: 'Сумма', formatValue: x => x.amount, dataKind: 'float' },
      ]}
      withMessages
      isDeleteConfirmEnabled
      defaultRowsCount={5}
      maxHeight="none"
      columnIndexesForSumTotal={[1]}
      specification={{
        mode: 'customComponent',
        renderComponent: (financing, setFinancing) => (
          <Fields financing={financing || getMockPartnerFinancing()} setFinancing={setFinancing} />
        ),
        validation: {
          checkIsValid: (financing, index, list, mode) => validate(financing, list, mode).every(x => x.isValid),
          onInvalidate: (financing, mode, index, list) => {
            const validationInfo = validate(financing, list, mode);
            showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
}

import React from 'react';

import { FiltersComponent } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { hasToolbarButtonMode } = customState;

  return FiltersComponent([], hasToolbarButtonMode ? 'personal' : 'default');
});

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { isRequests } = customState;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.constant,
        streamKey: 'isRequests',
        value: !!isRequests,
      },
    ],
    'personal',
  );
});

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = () =>
  memo(({ tableState }: Props) => {
    const { buttons, handleTemplateCloseViewForm, isViewButtonDisabled, isViewFormOpen, templateName } = useController({
      tableState,
    });

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewButtonDisabled && (
          <modalTemplates.CommunityViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            templateName={templateName}
          />
        )}
      </>
    );
  });

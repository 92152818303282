import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

import { detPeriodHighlights } from 'shared/detPerdiodHighlights';
import { ProjectAssignmentStatus, ProjectAssignmentStatusCode } from 'types/models/Project';
import { Color } from 'constants/colors';
import { makeUseCustomController } from './makeUseCustomController';
import { State } from './makeUseCustomController';

type Args = {
  disabled?: boolean;
};

export function GetPersonalProjectAssignmentList({ disabled }: Args): Table.Specification<State, Args> {
  return {
    apiID: 'GetPersonalProjectAssignmentList',
    header: {
      firstLevel: {
        title: 'Список поручений по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    isWithQna: true,
    useCustomController: makeUseCustomController({ disabled }),
    highlightRow: (row: Table.Entry) => {
      const status = row['id:Status'] as ProjectAssignmentStatusCode;
      if (
        !status ||
        !row.UpToDate ||
        [ProjectAssignmentStatus.CANCELED, ProjectAssignmentStatus.CLOSED, ProjectAssignmentStatus.COMPLETE].indexOf(status) > -1
      ) {
        return Color.primary;
      }
      return detPeriodHighlights(row, 3);
    },
  };
}

import { makeStream, GetStateStreamData } from 'StreamRx';
import { Entry } from 'types/models/Table';
import { APIIDHolder, ReloadTableArguments } from './types';

export type TableStreamData<Data = object> = Data & APIIDHolder;

export const streams = {
  reloadTable: makeStream<ReloadTableArguments>(),
  updateFirstLevelPanelState: makeStream<UpdateFirstLevelPanelState<any>>(),
  getPanelState: makeStream<TableStreamData<GetStateStreamData<any>>>(),
  submitTable: makeStream<TableStreamData<SubmitTable>>(),
  toggleSecondLevelPanel: makeStream<{}>(),
  approveRow: makeStream<ApproveRowData>(),
  deleteRow: makeStream<DeleteRowData>(),
  updateEntryPagesCount: makeStream<UpdateEntryPagesCount>(),
  loadInitialTable: makeStream<{}>(),
  exportTableData: makeStream<ExportTableDataData>(),
};

type UpdateFirstLevelPanelState<T> = TableStreamData<{
  state: T;
}>;

type ApproveRowData = TableStreamData<{
  approveRowId: string | string[];
  command: string;
  approveItemName: string;
  approveInnerItemName?: string;
  disapprove?: boolean;
}>;

type DeleteRowData = TableStreamData<{
  deleteRowId: string;
  command: string;
  deletedItemPropName: string;
}>;

type UpdateEntryPagesCount = TableStreamData<{
  nextEntryCount: number;
}>;

export type SubmitTable = { selectedRows: Entry[] };
type ExportTableDataData = TableStreamData;

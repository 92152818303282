import { Notification } from 'types/models';

export function getMockNotification(): Notification.Notification {
  return {
    id: null,
    event: null,
    recipients: [],
    blocks: [],
  };
}

export function getMockBlock(): Notification.Block {
  return {
    id: null,
    name: '',
    data: '',
    position: '',
  };
}

import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function BaseDissertationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Авторефераты и диссертации</h4>

        <p>
          Функциональный блок <strong>Авторефераты и диссертации</strong> содержит список авторефератов и диссертаций;
          предназначен для поддержки отчетности Университета по авторефератам и по результатам о защите диссертаций на ученую
          степень сотрудников и аспирантов Университета.
        </p>

        <div className="warning">
          <span>
            Информация о защите диссертации отображается в <strong>Личном кабинете</strong> научного руководителя на вкладке{' '}
            <strong>Руководство защитой</strong>.<br />В <strong>Личном кабинете, на вкладке Публикации</strong> автореферат /
            диссертация отображаются по фильтру <strong>Мои публикации</strong> у диссертанта- автора публикации.
          </span>
        </div>
        <div className="warning">
          <span>Набор элементов интерфейса может быть полным или частичным в зависимости от наличия прав у пользователя.</span>
        </div>

        <p>
          Для более удобной работы или поиска публикации, в столбце <strong>Тип публикации</strong> можно включить фильтр и
          посмотреть список всех публикаций только определенного одного типа публикации.
        </p>
        <p>
          <strong>Типы публикации:</strong>
        </p>

        <ul>
          <li>автореферат диссертации;</li>
          <li>диссертация.</li>
        </ul>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>Назначение функциональных элементов интерфейса представлено в таблице.</p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму просмотра публикации. Кнопка активна при выделении публикации в таблице</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму для добавления публикации</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования публикации. Кнопка активна при выделении публикации в таблице</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>Удаляет выделенную в таблице публикацию</td>
            </tr>
            <tr>
              <td>
                Редактировать связь публикации с подразделениями: <Icon type="connection" />
              </td>
              <td>
                Открывает форму для добавления связи с подразделением. Для указанных подразделений сведения о диссертации будут
                отображаться в отчёте подразделения.
              </td>
            </tr>
            <tr>
              <td>
                Показатели публикации: <Icon type="statistic" />
              </td>
              <td>
                Открывает форму с наукометрическими показателями публикации в базах цитирования. Добавить значение показателя
                возможно только при наличии ID публикации в базе цитирования.
              </td>
            </tr>
            <tr>
              <td>
                Утверждение публикации: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения</td>
            </tr>
            <tr>
              <td>
                Открыть публикацию во внешнем источнике: <Icon type="link" />
              </td>
              <td>
                Перейти по ссылке на страницу с публикацией в других Интернет-ресурсах (DOI, электронный каталог Научной
                библиотеки, др.). Ссылка открывается в новом окне браузера
              </td>
            </tr>
            <tr>
              <td>
                Скачать файл прикрепленный к публикации: <Icon type="download" />
              </td>
              <td>
                Скачанный файл помещается в папку системную папку <strong>Загрузки</strong> на устройстве пользователя
              </td>
            </tr>
            <tr>
              <td>
                Указать DOI статьи: <Icon type="badge" />
              </td>
              <td>
                Открывает форму для ввода идентификатора DOI публикации. Введённое значение будет отображаться на форме публикации
                на вкладке <strong>О публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Финансовая поддержка публикации: <Icon type="currency" />
              </td>
              <td>
                В случае, если диссертация является результатом выполнения научного проекта(гранта), необходимо выбрать из списка
                Научных проектов. Ответственный по качеству научных отчётов при наличии прав, Принимает к отчёту по проекту
                указанную публикацию. Принятые к отчёту по проекту публикации отображаются на форме проекта на вкладке{' '}
                <strong>Результаты проекта, Публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Отчеты: <Icon type="print" />
              </td>
              <td>Открывает список шаблонов для построения отчёта</td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список публикаций выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Функциональная кнопка открывает форму для добавления сообщения. Все сообщения хранятся в Системе, на странице{' '}
                <strong>Главная, Вопросы от пользователей администраторам</strong>. Список сообщений конкретного пользователя и
                ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
                <strong>Новости, Вопрос - ответ</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import * as XML from 'xml-js';

import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSystemUpdate } from './converters';
import { convertSystemUpdateToXML } from './requestConverters';
import { SystemUpdate } from 'types/models';

export const systemUpdateConfigurations = {
  GetSystemUpdate: makeBackendAPIConfiguration({
    id: 'GetSystemUpdate',
    endpoint: '/msa/service/commands/GetSystemUpdate',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetSystemUpdate',
          },
          SystemUpdate: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSystemUpdate(response.Response.SystemUpdate),
  }),
  SaveSystemUpdate: makeBackendAPIConfiguration({
    id: 'SaveSystemUpdate',
    endpoint: '/msa/service/commands/SaveSystemUpdate',
    convertInputToXMLElement: (systemUpdate: SystemUpdate) => ({
      Request: {
        _attr: {
          commandName: 'SaveSystemUpdate',
        },
        SystemUpdate: {
          ...convertSystemUpdateToXML(systemUpdate),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  DeleteSystemUpdate: makeBackendAPIConfiguration({
    id: 'DeleteSystemUpdate',
    endpoint: '/msa/service/commands/DeleteSystemUpdate',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'DeleteSystemUpdate',
          },
          SystemUpdate: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

import React, { memo } from 'react';

import { Modal, Toolbar } from 'components';

import { useController } from './controller';
import { RequestPerformerGridHelp } from './help';

function LeftPanelForThirdLevel() {
  const { buttons, isHelpFormOpen, handleTemplateCloseHelpForm } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="help"
        title='Помощь в работе с разделом "Список участников заявок на конкурсы"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="large"
      >
        {RequestPerformerGridHelp()}
      </Modal>
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);

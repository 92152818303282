import { parse } from 'date-fns';
import { PeriodableReferenceItem } from 'types/models/Partner';
import { formatStr } from 'utils/Constants';

export function validate(okved: PeriodableReferenceItem | null) {
  if (!okved) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  if (
    okved.startDate &&
    okved.endDate &&
    parse(okved.startDate, formatStr, new Date()) > parse(okved.endDate, formatStr, new Date())
  )
    return [{ isValid: false, invalidMessage: 'Дата окончания не может быть меньше даты начала' }];

  const isValidRef = Boolean(okved.ref && okved.ref.id);

  return [{ isValid: isValidRef, invalidMessage: 'Выберите ОКВЭД' }];
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

export function GetSalaryVacationList(projectCodeId?: string): Table.Specification<State, { projectCodeId?: string }> {
  return {
    apiID: 'GetSalaryVacationList',
    header: {
      firstLevel: {
        title: 'Мониторинг ограничения по дням отпуска членов коллектива проектов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ projectCodeId }),
    requestData: { projectCodeId: projectCodeId },
  };
}

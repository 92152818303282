import React, { memo } from 'react';
import { Modal } from 'components';
import { Form, Table } from 'types/models';
import { Component } from './component';

type Props = {
  isOpen: boolean;
  disabled: boolean;
  isMagazineOriginal: boolean;
  isMagazineTranslate: boolean;
  magazinePublicationOriginal: Form.Original | null;
  magazinePublicationTranslates: Form.Translation[];
  magazineArticlePublicationOriginal: Form.Original | null;
  magazineArticlePublicationTranslates: Form.Translation[];
  magazinePublicationName: string;
  magazineArticlePublication: Form.Publication | null;
  relatedTableState?: Table.State;
  setMagazineArticlePublicationTranslates: React.Dispatch<Form.Translation[]>;
  setMagazineArticlePublicationOriginal: React.Dispatch<Form.Original | null>;
  onClose: () => void;
};

export const Container = memo(
  ({
    isOpen,
    disabled,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    magazinePublicationTranslates,
    magazineArticlePublicationOriginal,
    magazineArticlePublicationTranslates,
    magazinePublicationName,
    magazineArticlePublication,
    relatedTableState,
    setMagazineArticlePublicationTranslates,
    setMagazineArticlePublicationOriginal,
    onClose,
  }: Props) => (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактирование связи оригинала/перевода для статьи в журнале" size="medium">
      <Component
        disabled={disabled}
        isMagazineOriginal={isMagazineOriginal}
        isMagazineTranslate={isMagazineTranslate}
        magazinePublicationOriginal={magazinePublicationOriginal}
        magazinePublicationTranslates={magazinePublicationTranslates}
        magazineArticlePublicationOriginal={magazineArticlePublicationOriginal}
        magazineArticlePublicationTranslates={magazineArticlePublicationTranslates}
        magazinePublicationName={magazinePublicationName}
        magazineArticlePublication={magazineArticlePublication}
        relatedTableState={relatedTableState}
        setMagazineArticlePublicationTranslates={(translations: Form.Translation[]) => {
          setMagazineArticlePublicationTranslates(translations);
        }}
        setMagazineArticlePublicationOriginal={(original: Form.Original | null) => {
          setMagazineArticlePublicationOriginal(original);
        }}
        onClose={onClose}
      />
    </Modal>
  ),
);

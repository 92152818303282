import { useState, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import { ForeignProject } from 'types/models';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showErrorsMessages } from 'utils/Common';
import { getMockForeignProject, getPrefilledPerformer } from './getMockModel';
import { validate } from './validate';

type Props = {
  onClose: () => void;
};

const useController = ({ onClose }: Props) => {
  const {
    look: { id, viewMode, editMode },
  } = useFormContext();
  const tableStreams = useLocalTableStreams();

  const { isProfile } = usePrivatePageContext();
  const { currentPerson } = useAppDataContext();

  const [foreignProject, setForeignProject] = useState<ForeignProject.ForeignProject>({ ...getMockForeignProject(), id });
  const [foreignProjectErrors, setForeignProjectErrors] = useState<Partial<Record<keyof ForeignProject.ForeignProject, boolean>>>(
    {},
  );
  const [isHistoryWarningOpen, setIsHistoryWarningOpen] = useState<boolean>(false);

  const { methods: GetForeignProjectAPI, state: GetForeignProjectAPIState } = BackendAPI.useBackendAPI('GetForeignProject');
  const { methods: SaveForeignProjectAPI, state: SaveForeignProjectAPIState } = BackendAPI.useBackendAPI('SaveForeignProject');
  const { methods: SavePersonalForeignProjectAPI, state: SavePersonalForeignProjectAPIState } = BackendAPI.useBackendAPI(
    'SavePersonalForeignProject',
  );

  const saveMethod = useMemo(() => {
    if (isProfile) {
      return SavePersonalForeignProjectAPI;
    }

    return SaveForeignProjectAPI;
  }, [SaveForeignProjectAPI, SavePersonalForeignProjectAPI, isProfile]);

  const { workMode } = useWorkModeHook({ viewMode, editMode });

  const isViewMode = workMode === 'viewMode';

  const isLoadingProject = GetForeignProjectAPIState.kind === 'pending';
  const isSavingProject = SaveForeignProjectAPIState.kind === 'pending';
  const isSavingPersonalProject = SavePersonalForeignProjectAPIState.kind === 'pending';

  const isLoading = useMemo(() => isLoadingProject || isSavingProject || isSavingPersonalProject, [
    isLoadingProject,
    isSavingPersonalProject,
    isSavingProject,
  ]);

  const isDisabled = useMemo(() => isViewMode || isLoading, [isViewMode, isLoading]);

  const makeChangeHandler = useCallback(
    (key: keyof ForeignProject.ForeignProject) => (value: ValueOf<ForeignProject.ForeignProject>) => {
      setForeignProject(prevForeignProject => ({ ...prevForeignProject!, [key]: value }));
    },
    [],
  );

  const makeResetErrorHandler = useCallback(
    (key: keyof ForeignProject.ForeignProject) => () => {
      setForeignProjectErrors(prevForeignProjectErrors => ({ ...prevForeignProjectErrors, [key]: false }));
    },
    [],
  );

  useEffect(() => {
    if (id) {
      GetForeignProjectAPI.callAPI(
        { id },
        {
          onSuccessfullCall: ({ data }) => {
            setForeignProject(data);
          },
        },
      );
    } else if (isProfile) {
      setForeignProject(prevForeignProject => ({ ...prevForeignProject, performer: getPrefilledPerformer(currentPerson) }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isPerformerHistoryEmpty = useMemo(() => {
    const isCitizenship = Boolean(foreignProject.performer?.citizenship);
    const isRank = Boolean(foreignProject.performer?.rank);
    const isAcademicRank = Boolean(foreignProject.performer?.academicRank);
    const isJob = Boolean(foreignProject.performer?.job);
    const isEducation = Boolean(foreignProject.performer?.education);

    const isEmpty = !isJob && !isCitizenship && !isAcademicRank && !isRank && !isEducation;
    return isEmpty;
  }, [foreignProject.performer]);

  const saveForeignProject = useCallback(() => {
    saveMethod.callAPI(
      {
        foreignProject,
      },
      {
        onSuccessfullCall: () => {
          onClose();
          showNotification({ message: 'Проект успешно сохранен', theme: 'success' });
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [onClose, foreignProject, saveMethod, tableStreams.reloadTable]);

  const onSubmitHistoryWarning = useCallback(() => {
    setIsHistoryWarningOpen(false);
    saveForeignProject();
  }, [saveForeignProject]);

  const onCloseHistoryWarning = useCallback(() => {
    setIsHistoryWarningOpen(false);
  }, []);

  const onSave = useCallback(() => {
    const validationInfo = validate(foreignProject);

    const isValidForm = validationInfo.every(({ isValid }) => isValid);

    if (isValidForm) {
      if (isPerformerHistoryEmpty) {
        setIsHistoryWarningOpen(true);
      } else {
        saveForeignProject();
      }
    } else {
      const invalidInfo = validationInfo.filter(({ isValid }) => !isValid);
      const nextForeignProjectErrors = invalidInfo.reduce<Partial<Record<keyof ForeignProject.ForeignProject, boolean>>>(
        (accum, current) => ({ ...accum, [current.key]: true }),
        {},
      );

      setForeignProjectErrors(nextForeignProjectErrors);
      showErrorsMessages(invalidInfo.map(({ invalidMessage }) => invalidMessage));
    }
  }, [foreignProject, isPerformerHistoryEmpty, saveForeignProject]);

  return {
    foreignProject,
    workMode,
    isDisabled,
    isHistoryWarningOpen,
    foreignProjectErrors,
    onSubmitHistoryWarning,
    onCloseHistoryWarning,
    onSave,
    makeChangeHandler,
    makeResetErrorHandler,
  };
};

export default useController;

import React, { memo } from 'react';

import { ListEdit, FormComponent } from 'components';

import { useController } from './controller';
import { UserActivationKind } from 'types/models/UserActivation';
import { Fields } from './Fields';
import { showErrors } from 'utils/Validators';
import { validate } from './validate';

const UserActivation = () => {
  const { activationKinds, handleActivationSettingsChange } = useController();

  return (
    <>
      <h3>Настройка для автоматической активации пользователей</h3>

      <FormComponent.Wrapper>
        <ListEdit
          rows={activationKinds}
          onChange={handleActivationSettingsChange}
          toolbar={['edit']}
          columns={[
            {
              label: 'Активировать',
              formatValue: (x: UserActivationKind) => x.activate === 'true',
              styles: { width: '20%' },
            },
            { label: 'Категория должности', formatValue: (x: UserActivationKind) => x.label },
            { label: 'Назначить роль', formatValue: (x: UserActivationKind) => x.roles[0]?.name || '' },
          ]}
          specification={{
            mode: 'customComponent',
            renderComponent: (item, setItem) => <Fields item={item} setItem={setItem} />,
            validation: {
              checkIsValid: (activationKind: UserActivationKind) => validate(activationKind).every(x => x.isValid),
              onInvalidate: val => showErrors(validate, val),
            },
          }}
        />
      </FormComponent.Wrapper>
    </>
  );
};

export const Component = memo(UserActivation);

import { ReferenceItem } from 'components';

import { Person } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';

export const getMockEducation = (): Person.ScientistEducation => {
  return {
    originalId: '',
    id: null,
    status: { label: '', value: '' },
    enterprise: '',
    deletionDate: '',
    deletedBy: '',
    creationDate: '',
    creationSource: '',
    modificationDate: '',
    modificationSource: '',
    dateReceipt: '',
    dateDismissal: null,
    refSpeciality: null,
    refDepartment: null,
    department: null,
    speciality: null,
    isTSU: false,
    isReadOnly: false,
    isActive: false,
  };
};

export function getMockJob(item?: Partial<Person.ScientistJob>): Person.ScientistJob {
  return {
    originalId: '',
    id: null,
    enterpise: '',
    creationDate: '',
    creationSource: '',
    modificationDate: '',
    modificationSource: '',
    deletionDate: '',
    deletedBy: '',
    dateReceipt: '',
    dateDismissal: null,
    department: null,
    isActive: false,
    isReadOnly: false,
    status: null,
    appointmentCategory: null,
    staffGroup: null,
    appointment: null,
    refAppointment: null,
    refDepartment: null,
    civilContractName: null,
    civilContractNumber: null,
    civilContractDate: null,
    staffState: null,
    compoundType: null,
    ...item,
  };
}

export function getMockPerson({ initialCountryCode }: { initialCountryCode?: ReferenceItem | null }): Person.ScientistPerson {
  return {
    academicRanks: [],
    bibliographicNames: [],
    degrees: [],
    fullName: '',
    id: '',
    identifiers: [],
    ranks: [],
    scientistEducations: [],
    scientistJobs: [],
    shortName: '',
    hirshIndexes: [],
    domainInterests: [],
    grntis: [],
    udks: [],
    oecds: [],
    knowledgeBranches: [],
    vakSpecialities: [],
    communityMembers: [],
    changes: [],
    affiliations: [],
    calculations: null,
    avatar: EMPTY_FILE,
    scientist: initialCountryCode ? getMockScientist({ initialCountryCode }) : null,
    disnotifications: [],
    contacts: [],
  };
}

export const getMockScientist = ({ initialCountryCode }: { initialCountryCode: ReferenceItem | null }): Person.Scientist => ({
  id: '',
  fullName: '',
  averageFiveYearImpactFactor: '',
  averageImpactFactor: '',
  calculateFiveYearImpactFactor: '',
  calculateImpactFactor: '',
  citations: '',
  dateBirth: '01.01.1900',
  citizenship: initialCountryCode,
  englishFirstName: '',
  englishLastName: '',
  englishPatronymic: '',
  firstName: '',
  fromBus: false,
  gender: 'MALE',
  guid: '',
  inn: '',
  lastName: '',
  patronymic: '',
  status: '',
  statusFull: '',
  snils: '',
  note: '',
  keywordsEnglish: '',
  keywordsRus: '',
  activities: '',
  scientificResults: '',
  notificationEmail: '',
});

export function validateMainFields(person: Person.ScientistPerson) {
  const isValidLastName = Boolean(person.scientist?.lastName);
  const isValidFirstName = Boolean(person.scientist?.firstName);
  const isValidDateBirth = Boolean(person.scientist?.dateBirth);
  const isFilledJobsOrEducation = Boolean(person.scientistEducations.length || person.scientistJobs.length);

  return [
    { isValid: isValidLastName, invalidMessage: 'Заполните фамилию' },
    { isValid: isValidFirstName, invalidMessage: 'Заполните имя' },
    { isValid: isValidDateBirth, invalidMessage: 'Заполните дату рождения' },
    { isValid: isFilledJobsOrEducation, invalidMessage: 'Заполните сведения о работе или об обучении' },
  ];
}

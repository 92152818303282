import { convertFile, convertItem, convertMaybeArray, getText } from '../commonConverters';
import { ProjectAssignment, ProjectAssignmentMessage } from 'types/models/Project';
import { convertPerformer } from '../project/converters';
import { Settings } from 'types/models/common';

export function convertProjectAssignment(pa: any, settings: Settings): ProjectAssignment {
  return {
    id: pa?._attributes?.id ?? null,
    status: pa.Status ? convertItem(pa.Status) : null,
    assignment: getText(pa.Assignment),
    date: getText(pa.Date),
    upToDate: getText(pa.UpToDate),
    projectId: pa.Project?._attributes?.id ?? '',
    projectScientists: convertMaybeArray(pa?.ProjectScientists.ProjectScientist || [], e => convertPerformer(e, settings)),
    file: convertFile(pa.File),
  };
}

export function convertProjectAssignmentMessage(pam: any): ProjectAssignmentMessage {
  return {
    id: pam?._attributes?.id ?? null,
    message: getText(pam.Message),
    file: convertFile(pam.File),
  };
}

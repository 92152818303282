import { Project } from 'types/models';

export function getMockFeedline(): Project.Feedline {
  return {
    id: null,
    message: null,
    operator: null,
    type: null,
    detail: '',
  };
}

import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ListEdit, Tabs, Tab, TextArea, TextInput, RefList } from 'components';
import {
  GetRntdForSubjectList,
  GetSubjectFinancingList,
  GetSubjectPublicationList,
  GetSubjectConferenceList,
  GetSubjectExpositionList,
  GetSubjectSecurityDocumentContractList,
  GetForeignSecurityDocumentList,
} from 'features/Table/specifications';
import { Documents } from 'features/Documents';
import { DataGrid } from 'features/Table';
import { SecurityDocumentMemberType } from 'utils/Enums';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useAppDataContext } from 'features/AppData/context';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';
import { useController } from './controller';
import { getIsCurrentSecurityDocument, getSecurityDocumentInfo, getSecurityDocumentMembers } from './helpers';

type Props = {
  onClose: () => void;
};

function SubjectViewForm({ onClose }: Props) {
  const {
    subject,
    handleCodeFieldChange,
    handleNameFieldChange,
    handleDescriptionFieldChange,
    handleRntdsFieldChange,
    onSubmit,
    isSubject,
    subjectId,
    viewMode,
    handleDocumentsChange,
    handleForeignSecurityDocumentsChange,
    getApplicantsInfo,
    getAuthorsInfo,
  } = useController({
    onClose,
  });

  const { settings } = useAppDataContext();

  const { methods: getRntd } = BackendAPI.useBackendAPI('GetRntd');

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.Field label="Код" isRequired>
              <TextInput value={subject.code} onChange={handleCodeFieldChange} isDisabled={viewMode} />
            </FormComponent.Field>

            <FormComponent.Field label="Название" isRequired>
              <TextArea settings={{ rows: 3 }} value={subject.name} onChange={handleNameFieldChange} isDisabled={!!viewMode} />
            </FormComponent.Field>

            <FormComponent.Field label="Описание">
              <TextArea
                settings={{ rows: 3 }}
                value={subject.description}
                onChange={handleDescriptionFieldChange}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>

            <ListEdit
              header={{ title: `Список РИД в составе ${isSubject ? 'тематики' : 'продукта'}` }}
              rows={subject.rntds}
              onChange={handleRntdsFieldChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                {
                  label: 'РИД',
                  formatValue: ref => {
                    return [ref.securityDocuments[0]?.kind?.label, ref.name].filter(Boolean).join(': ');
                  },
                  styles: { width: '25%' },
                },
                {
                  label: 'Охранный документ',
                  formatValue: ref => getSecurityDocumentInfo(ref.securityDocuments),
                  styles: { width: '25%' },
                },
                {
                  label: 'Авторы',
                  formatValue: ref => getSecurityDocumentMembers(ref.securityDocuments, SecurityDocumentMemberType.AUTHOR),
                  styles: { width: '30%' },
                },
                {
                  label: 'Правообладатели',
                  formatValue: ref =>
                    `${settings?.organization?.shortName}; ${getSecurityDocumentMembers(
                      ref.securityDocuments,
                      SecurityDocumentMemberType.APPLICANT,
                    )}`,
                  styles: { width: '10%' },
                },
                {
                  label: 'Действует',
                  formatValue: ref => getIsCurrentSecurityDocument(ref.securityDocuments),
                  dataKind: 'boolean',
                  styles: { width: '10%' },
                },
              ]}
              isSortEnabled
              isVisibleFilters
              isDeleteConfirmEnabled
              withMessages
              maxHeight="none"
              defaultRowsCount={12}
              isDisabled={viewMode}
              specification={{
                mode: 'loadInstanceTableModal',
                relationTableModalTitle: 'РИД',
                modalTableSpecification: GetRntdForSubjectList(),
                onStartLoad: (tableRows, onSuccess) => {
                  getRntd.callAPI({ id: tableRows[0].id }, { onSuccessfullCall: ({ data }) => onSuccess([data]) });
                },
              }}
            />
          </Tab>
          <Tab title="ОИС других правообладателей" isVisible={!isSubject}>
            <ListEdit
              rows={subject.foreignSecurityDocuments}
              onChange={handleForeignSecurityDocumentsChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'Название ОИС', formatValue: ref => [ref.kind?.label, ref.name].filter(Boolean).join(': ') },
                {
                  label: 'Документ',
                  formatValue: ref => `${ref.type?.label} № ${ref.number} (${ref.startDate} - ${ref.endDate})`,
                },
                {
                  label: 'Правообладатели',
                  formatValue: ref => getApplicantsInfo(ref),
                },
                {
                  label: 'Авторы',
                  formatValue: ref => getAuthorsInfo(ref),
                },
              ]}
              isDeleteConfirmEnabled
              withMessages
              maxHeight="none"
              defaultRowsCount={10}
              isDisabled={viewMode}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'РИД',
                modalTableRowConverter: row => {
                  return {
                    id: row.id,
                    type: { value: row['id:Type'], label: row.Type },
                    kind: { id: row['id:Kind'], label: row.Kind },
                    name: row.Name,
                    number: row.Number,
                    startDate: row.StartDate,
                    endDate: row.EndDate,
                    applicants: row.Applicants,
                    authors: row.Authors,
                    members: [],
                    documentNumber: '',
                    documentStartDate: '',
                    intellectualPropertyName: '',
                    status: { value: row['id:Status'], label: row.Status },
                    documents: [],
                    contract: '',
                  };
                },
                modalTableSpecification: GetForeignSecurityDocumentList({
                  shortName: settings?.organization?.shortName,
                  hasSelectButton: true,
                }),
              }}
            />
          </Tab>
          <Tab title="Классификаторы">
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'ГРНТИ' }}
                    rows={subject.grntis || []}
                    refName="RefGrnti"
                    modalTitle='Справочник "ГРНТИ"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList header={{ title: 'УДК' }} refName="RefUdk" rows={subject.udks || []} modalTitle='Справочник "УДК"' />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'ОКВЭД' }}
                    rows={subject.okveds || []}
                    refName="RefOkved"
                    modalTitle='Справочник "ОКВЭД"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'ЛКСЭЦ' }}
                    rows={subject.lksetss || []}
                    refName="RefLksetss"
                    modalTitle='Справочник "ЛКСЭЦ"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Отрасли науки' }}
                    rows={subject.scienceBrunches || []}
                    refName="RefScienceBrunch"
                    modalTitle='Справочник "Отрасли науки"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Области научных интересов' }}
                    rows={subject.scienceDomainInterrests || []}
                    refName="RefScienceDomainInterest"
                    modalTitle='Справочник "Области научных интересов"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: `ПНР ${settings?.organization?.shortName}` }}
                    rows={subject.pnrs || []}
                    refName="RefPnr"
                    modalTitle='Справочник "ПНР"'
                  />
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Приоритетные направления модернизации и технического развития экономики' }}
                    rows={subject.pnmitrs || []}
                    refName="RefPnr"
                    modalTitle='Справочник "ПНР модернизации РФ"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Приоритетные направления науки, технологий и техники' }}
                    rows={subject.pnis || []}
                    refName="RefPni"
                    modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Приоритеты Стратегии НТР РФ' }}
                    rows={subject.ntrStrategies || []}
                    refName="RefNtrStrategy"
                    modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Технологические платформы' }}
                    rows={subject.technologyPlatforms || []}
                    refName="RefTechnologyPlatform"
                    modalTitle='Справочник "Технологические платформы"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Критические технологии' }}
                    rows={subject.priorityTechnologies || []}
                    refName="RefPriorityTechnology"
                    modalTitle='Справочник "Критические технологии"'
                  />
                </FormComponent.Line>
                <FormComponent.Line>
                  <RefList
                    header={{ title: 'Отрасли знаний (РНФ)' }}
                    rows={subject.knowledgeBranches || []}
                    columns={[
                      {
                        label: 'Отрасль знаний',
                        formatValue: x => x.label || '',
                        styles: { width: '50%' },
                      },
                      {
                        label: 'Группа (подгруппа)',
                        formatValue: x => getKnowledgeBranchGroupLabel(x),
                        styles: { width: '50%' },
                      },
                    ]}
                    refName="RefKnowledgeBranch"
                    modalTitle='Справочник "Отрасли знаний (РНФ)"'
                  />
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </Tab>
          <Tab title="Популяризация">
            <Tabs>
              <Tab title="Публикации">
                <DataGrid specification={GetSubjectPublicationList({ subjectId })} />
              </Tab>
              <Tab title="Участие в конференциях">
                <DataGrid specification={GetSubjectConferenceList({ subjectId })} />
              </Tab>
              <Tab title="Участие в выставках">
                <DataGrid specification={GetSubjectExpositionList({ subjectId })} />
              </Tab>
            </Tabs>
          </Tab>
          <Tab title="Коммерциализация">
            <DataGrid specification={GetSubjectSecurityDocumentContractList({ subjectId })} />
          </Tab>
          <Tab title="Финансирование">
            <DataGrid specification={GetSubjectFinancingList({ subjectId, isSubject })} />
          </Tab>
          <Tab title="Документы">
            <Documents
              documents={subject.documents}
              setDocuments={handleDocumentsChange}
              disabled={!!viewMode}
              sourceLabelForLocalDocs={isSubject ? 'Текущая тематика' : 'Текущий продукт для коммерциализации'}
              isShowSource
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SubjectViewForm);

import { useCallback, useMemo } from 'react';
import { parse, addMonths, format } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import {
  Collapse,
  FormComponent,
  ListEdit,
  Radio,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';
import { isValidDate } from 'components/TextDateTime/helpers';

import { Document, Equip, ReferenceItem, Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatStr } from 'utils/Constants';
import { VerificationItemFields } from './VerificationItemFields';
import { isNumber } from 'utils/Validators';
import { useAppDataContext } from 'features/AppData/context';
import { SelectPartner } from 'features/Form/views/SelectPartner';
import { GetEquipVerificationContactList } from 'features/Table/specifications';
import { downloadFile, getAuthToken, getPersonContacts } from 'utils/Helpers';
import { Item } from 'types/models/common';

type Props = {
  equip: Equip.Equip;
  setEquip: React.Dispatch<React.SetStateAction<Equip.Equip>>;
  isDisabled: boolean;
};

export function Verification({ equip, setEquip, isDisabled }: Props) {
  const { settings } = useAppDataContext();
  const token = getAuthToken();

  const { methods: getEquipVerificationContact } = BackendAPI.useBackendAPI('GetEquipVerificationContact');

  const lastVerification = useMemo(
    () =>
      equip.verificationItems.reduce(
        (result, current) =>
          parse(current.date, formatStr, new Date()).getTime() > (parse(result, formatStr, new Date()).getTime() || 0)
            ? current.date
            : result,
        '',
      ),
    [equip.verificationItems],
  );

  const lastInstallLocation = useMemo(() => {
    const validArr = equip.installLocations
      .filter(x => !!x.installDate && !!x.address)
      .sort((a, b) => {
        const aDate = parse(a.installDate, formatStr, new Date());
        const bDate = parse(b.installDate, formatStr, new Date());
        return (isValidDate(aDate) ? aDate.getTime() : 0) - (isValidDate(bDate) ? bDate.getTime() : 0);
      });
    const addressArr = equip.installLocations.filter(x => !!x.address);
    return validArr[0] || addressArr[0];
  }, [equip.installLocations]);

  const getDocumentInfo = useCallback((doc?: Document | null) => {
    if (!doc) return '';
    return `От ${doc.date}${doc.startDate || doc.endDate ? `. Период действия: ${doc.startDate} - ${doc.endDate}` : ''}`;
  }, []);

  const getDocumentItem = useCallback((doc: Document, isFull?: boolean) => {
    return {
      ...doc,
      value: doc.id,
      label:
        doc.name + (isFull ? ` от ${doc.date}${doc.startDate || doc.endDate ? ` (${doc.startDate} - ${doc.endDate})` : ''}` : ''),
    } as Item;
  }, []);

  return (
    <>
      <Collapse title="Характеристики для поверки" isExpanded>
        <FormComponent.Wrapper>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <FormComponent.Line>
                <FormComponent.Field label="Вид прибора">
                  <Select
                    mode={SelectMode.REFERENCE}
                    value={equip?.equipKind}
                    onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev!, equipKind: e }))}
                    settings={{
                      title: 'Справочник "Вид прибора"',
                      name: 'RefEquipVerificationEquipKind',
                      isClearable: true,
                    }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Вид измерений">
                  <Select
                    mode={SelectMode.REFERENCE}
                    value={equip?.measureKind}
                    onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev!, measureKind: e }))}
                    settings={{
                      title: 'Справочник "Вид измерений"',
                      name: 'RefEquipVerificationMeasureKind',
                      isClearable: true,
                    }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Пределы измерений">
                  <TextInput
                    value={equip.measureLimit}
                    onChange={e => setEquip(prev => ({ ...prev, measureLimit: e }))}
                    settings={{ maxLength: 300 }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Цена деления шкалы">
                  <TextInput
                    value={equip.measureScaleAmount}
                    onChange={e => setEquip(prev => ({ ...prev, measureScaleAmount: e }))}
                    settings={{ maxLength: 300 }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Класс или допустимая погрешность">
                  <TextInput
                    value={equip.permissibleInaccuracy}
                    onChange={e => setEquip(prev => ({ ...prev, permissibleInaccuracy: e }))}
                    settings={{ maxLength: 300 }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата последней поверки">{lastVerification}</FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Межповерочный интервал">
                  <TextInput
                    mode={TextInputMode.NUMBER}
                    value={equip.interval}
                    onChange={e => setEquip(prev => ({ ...prev, interval: e }))}
                    settings={{ maxLength: 4 }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
                <p>месяцев</p>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата следующей поверки">
                  {!!lastVerification && !isNaN(Number(equip.interval))
                    ? format(addMonths(parse(lastVerification, formatStr, new Date()), Number(equip.interval)), formatStr)
                    : ''}
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column>
              <FormComponent.Line>
                <FormComponent.Field label="Место поверки">
                  <Radio
                    value={String(!!equip?.isLocalVerificationLocation)}
                    list={[
                      { label: `На территории ${settings?.organization?.shortName || 'университета'}`, value: 'true' },
                      {
                        label: `На территории исполнителя ${
                          equip?.enterprise?.shortName || equip?.verificationEnterprise?.fullName || ''
                        }`,
                        value: 'false',
                      },
                    ]}
                    onChange={e => setEquip(prev => ({ ...prev!, isLocalVerificationLocation: !!(e === 'true') }))}
                    classMixin="label-wrap"
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Предприятие осуществляющее проверку">
                  <SelectPartner
                    partner={equip.verificationEnterprise}
                    setPartner={e =>
                      setEquip(prev => ({ ...prev, verificationEnterprise: e, verificationEnterpriseContract: null }))
                    }
                    label="Предприятие осуществляющее проверку"
                    showButtons={['add']}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Договор">
                  <Select
                    value={equip.verificationEnterpriseContract ? getDocumentItem(equip.verificationEnterpriseContract) : null}
                    onChange={e => setEquip(prev => ({ ...prev, verificationEnterpriseContract: (e as unknown) as Document }))}
                    options={equip.verificationEnterprise?.documents.map(x => getDocumentItem(x, true))}
                    settings={{
                      isClearable: true,
                      externalButtons: [
                        {
                          icon: { type: 'download' },
                          title: 'Скачать файл',
                          onClick: () => downloadFile(equip.verificationEnterpriseContract?.file.id, token),
                          isDisabled: !equip.verificationEnterpriseContract,
                        },
                        {
                          icon: { type: 'view' },
                          title: 'Просмотр файла',
                          onClick: () => downloadFile(equip.verificationEnterpriseContract?.file.id, token, true),
                          isDisabled: !equip.verificationEnterpriseContract,
                        },
                      ],
                    }}
                  />
                  {getDocumentInfo(equip.verificationEnterpriseContract)}
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Стоимость поверки, руб">
                  <TextInput
                    value={equip.price}
                    onChange={e => setEquip(prev => ({ ...prev, price: e }))}
                    mode={TextInputMode.NUMBER}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label="Текущее местонахождение"
                  // eslint-disable-next-line max-len
                  info={
                    <>
                      Отображается последняя запись из раздела "Фактическое место размещения оборудования". Если данные не
                      актуальны, то добавьте актуальный адрес на вкладке "Место установки"
                    </>
                  }
                >
                  {[
                    lastInstallLocation?.address,
                    lastInstallLocation?.installDate ? ` (на дату ${lastInstallLocation.installDate})` : '',
                  ]
                    .filter(Boolean)
                    .join(' ')}
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label="Контактное лицо"
                  info={<>Ответственный в подразделении за метрологическое обеспечение</>}
                >
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: 'Ответственные за метрологическое обеспечение по подразделению',
                      table: {
                        specification: GetEquipVerificationContactList(),
                        onSelect: (row: Table.Entry | null) => {
                          if (!row) {
                            setEquip(prev => ({ ...prev, responsibleContact: null }));
                            return;
                          }
                          getEquipVerificationContact.callAPI(
                            { id: row.id },
                            { onSuccessfullCall: ({ data }) => setEquip(prev => ({ ...prev, responsibleContact: data })) },
                          );
                        },
                      },
                      rows: 1,
                    }}
                    value={equip.responsibleContact?.scientist?.fullName}
                    isDisabled={isDisabled}
                  />
                  {getPersonContacts(equip.responsibleContact?.scientist?.contacts)}
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </Collapse>
      <SectionTitle
        title="История поверок"
        info={<>Необходимо занести данные для включения оборудования в план-график поверок.</>}
      />
      <ListEdit
        rows={equip.verificationItems}
        onChange={e => setEquip(prev => ({ ...prev, verificationItems: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          {
            label: 'Дата поверки',
            formatValue: row => row.date,
            styles: { width: '5%' },
            dataKind: 'date',
            defaultSort: 'desc',
          },
          { label: 'Результат', formatValue: row => row.result?.label || '', styles: { width: '10%' } },
          { label: 'Номер акта выполненных работ', formatValue: row => row.contractNumber || '', styles: { width: '10%' } },
          { label: 'Организация-поверитель', formatValue: row => row.enterprise?.fullName || '', styles: { width: '15%' } },
          {
            label: 'Стоимость поверки',
            formatValue: row => (isNumber(row.price) ? row.price : ''),
            styles: { width: '10%' },
            dataKind: 'float',
          },
          {
            label: 'Место поверки',
            formatValue: row =>
              !!row?.isLocalVerificationLocation
                ? `На территории ${settings?.organization?.shortName || 'университета'}`
                : `На территории исполнителя ${row?.enterprise?.shortName || row?.enterprise?.fullName || ''}`,
            styles: { width: '10%' },
          },
          { label: 'Документ', formatValue: row => row.document?.name || '', styles: { width: '10%' } },
          {
            label: 'Ссылка на запись в реестре',
            formatValue: row => <FormComponent.Link href={row.documentUrl} />,
            styles: { width: '10%', wordBreak: 'break-word' },
          },
          { label: 'Примечание', formatValue: row => row.note || '', styles: { width: '20%' } },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={isDisabled}
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, setRow) => <VerificationItemFields row={row} setRow={setRow} equip={equip} />,
          validation: {
            checkIsValid: row => !!row?.date && !!row?.contractNumber && !!row?.result,
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />
    </>
  );
}

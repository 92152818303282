import { Table } from 'types/models';

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetCompilationList: Table.Specification = {
  apiID: 'GetCompilationList',
  header: {
    firstLevel: {
      title: 'Сборники статей',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  enumFilterValues: {
    enumName: 'PublicationType',
    excludeValues: [
      PublicationTypes.MAGAZINE.code,
      PublicationTypes.MAGAZINE_ARTICLE.code,
      PublicationTypes.MONOGRAPH.code,
      PublicationTypes.MONOGRAPH_CHAPTER.code,
      PublicationTypes.TEXTBOOK.code,
      PublicationTypes.TUTORIAL.code,
      PublicationTypes.METHODICAL_EDITION.code,
      PublicationTypes.METHODICAL_EDITION_OTHER.code,
      PublicationTypes.THESIS.code,
      PublicationTypes.ARTICLE_SCIENTIFIC_WORK.code,
      PublicationTypes.ARTICLE_CONFERENCE.code,
      PublicationTypes.ARTICLE_OTHER.code,
      PublicationTypes.DISSERTATION.code,
      PublicationTypes.SYNOPSIS.code,
      PublicationTypes.OTHER_BOOK.code,
      PublicationTypes.OTHER_PART.code,
      PublicationTypes.ARTICLE_ARTICLES.code,
      PublicationTypes.PREPRINT_ARTICLE.code,
    ],
  },
  isWithQna: true,
};

import React, { useCallback } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getMockRefElement } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};

export function ScienceSection(props: Props) {
  const { disabled, project, makeChangeHandler } = props;

  const renderRefHint = (ref: ReferenceItem | null | undefined, fieldName: string) =>
    ref?.id ? (
      <FormComponent.Description mode="default" classMixin="is-hint">
        {(ref?.customFields?.[fieldName] as ReferenceItem)?.label || (ref as any)?.[fieldName]}
      </FormComponent.Description>
    ) : null;

  const handleChangeLkset = useCallback(
    (lkset: ReferenceItem | null) => {
      let updatedLksets: Array<Project.RefElement> = [];
      if (lkset) {
        updatedLksets = [{ ...getMockRefElement(), element: lkset }];
      }
      makeChangeHandler('lksetss')(updatedLksets);
    },
    [makeChangeHandler],
  );

  const handleChangeScienceBrunch = useCallback(
    (scienceBrunch: ReferenceItem | null) => {
      let updatedScienceBrunches: Array<Project.RefElement> = [];
      if (scienceBrunch) {
        updatedScienceBrunches = [{ ...getMockRefElement(), element: scienceBrunch }];
      }
      makeChangeHandler('scienceBrunches')(updatedScienceBrunches);
    },
    [makeChangeHandler],
  );

  const lksets = getFirstRefElement(project?.lksetss ?? []);
  const scienceBrunch = getFirstRefElement(project?.scienceBrunches ?? []);

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field
          label="ОКВЭД"
          info={
            <p>
              Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов, 1-инжиниринг.
            </p>
          }
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={project?.okved}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('okved')(option)}
            settings={{ name: 'RefOkved2', title: 'ОКВЭД', isClearable: true }}
            isDisabled={disabled}
          />
          {renderRefHint(project?.okved, 'parent')}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="ОКПД">
          <Select
            mode={SelectMode.REFERENCE}
            value={project?.okpd}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('okpd')(option)}
            settings={{ name: 'RefOkpd', title: 'ОКПД', isClearable: true }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="ЛКСЭЦ"
          info={
            <p>
              Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов, 2-Наука 2023.
            </p>
          }
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={lksets}
            onChange={handleChangeLkset}
            settings={{ name: 'RefLksets', title: 'Классификатор социально-экономических целей', isClearable: true }}
            isDisabled={disabled}
          />
          {renderRefHint(lksets, 'parent')}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Отрасль науки"
          info={
            <p>
              Обязательный параметр для построения отчетов: Управленческие отчеты по доходам от научных проектов, 1-Мониторинг
              2023, 2-Наука 2023.
            </p>
          }
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={scienceBrunch}
            onChange={handleChangeScienceBrunch}
            settings={{ name: 'RefScienceBrunch', title: 'Справочник "Отрасли науки"', isClearable: true }}
            isDisabled={disabled}
          />
          {renderRefHint(scienceBrunch, 'study')}
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

function getFirstRefElement(refs: Project.RefElement[]) {
  const [element] = refs;
  return element?.element ?? null;
}

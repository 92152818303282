import { useCallback } from 'react';

import { FileInfo, SecurityDocumentContract } from 'types/models';

type Props = {
  entry: SecurityDocumentContract.Payment | null;
  onFieldChange(row: SecurityDocumentContract.Payment): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleFileChange = useCallback(
    (fileInfo: FileInfo) => {
      onFieldChange({ ...entry, file: fileInfo } as SecurityDocumentContract.Payment);
    },
    [onFieldChange, entry],
  );

  return {
    handleFileChange,
  };
}

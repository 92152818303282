import React, { memo } from 'react';

import { FormComponent, Button, ButtonMode, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  onClose: () => void;
  publicationId: string;
  relatedTableState: Table.State;
  onSuccess: () => void;
};

const PublicationTransformComponent = ({ onClose, publicationId, relatedTableState, onSuccess }: Props) => {
  const {
    submitForm,
    magazineArticlePublication,
    modalTableMagazineConverter,
    magazineTableConfig,
    handlePreprintChange,
    preprintOriginal,
    preprintDialogTitle,
    preprintButtonLabel,
  } = useController({
    onClose,
    publicationId,
    onSuccess,
    relatedTableState,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Библиографическая ссылка">
          <FormComponent.Text>{magazineArticlePublication?.fields?.bibliographicRecord}</FormComponent.Text>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={preprintDialogTitle}>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: preprintDialogTitle,
              table: {
                specification: magazineTableConfig,
                onSelect: (row: Table.Entry | null) => handlePreprintChange(row ? modalTableMagazineConverter(row) : null),
              },
            }}
            value={preprintOriginal?.name}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Actions>
        <Button mode={ButtonMode.PRIMARY} text={preprintButtonLabel} onClick={submitForm} />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(PublicationTransformComponent);

import { Equip } from 'types/models';

export function getMockEquip(): Equip.Equip {
  return {
    id: null,
    name: '',
    manufacturerName: '',
    factoryNumber: '',
    stateNumber: '',
    stateUrl: '',
    model: '',
    country: null,
    manufactureYear: '',
    commissioningDate: '',
    disposeDate: '',
    manufacturer: null,
    analog: '',
    specifications: '',
    usefulPeriod: '',
    descriptionNote: '',
    classificator: null,
    okved: null,
    okpd: null,
    okof: null,
    ktru: null,
    ckp: null,
    contractNumber: '',
    contractDate: '',
    amount: '',
    inventoryNumber: '',
    nfaInventoryCardNumber: '',
    eisNumber: '',
    eisUrl: '',
    program: null,
    purchaseNote: '',
    installLocations: [],
    contacts: [],
    useCategories: [],
    documents: [],
    status: null,
    purpose: '',
    employeesProvisionConditions: '',
    outsidersProvisionConditions: '',
    purchaseYear: '',
    enterprise: null,
    photos: [],
    kitContents: [],
    investmentSources: [],
    balances: [],
    equipKind: null,
    measureKind: null,
    measureLimit: '',
    measureScaleAmount: '',
    permissibleInaccuracy: '',
    interval: '',
    verificationEnterprise: null,
    price: '',
    verificationItems: [],
    verificationDocuments: [],
    isLocalVerificationLocation: true,
    depreciationRate: '',
    responsibleContact: null,
    verificationEnterpriseContract: null,
    parentKitContent: null,
  };
}

export function getMockVerificationItem(item?: Partial<Equip.VerificationItem>): Equip.VerificationItem {
  return {
    id: null,
    date: '',
    enterprise: null,
    price: '',
    result: null,
    contractNumber: '',
    document: null,
    documentUrl: '',
    note: '',
    isLocalVerificationLocation: true,
    ...item,
  };
}

export function getMockKitContent(kitContent?: Partial<Equip.KitContent>): Equip.KitContent {
  return {
    id: null,
    name: '',
    quantity: '',
    unit: null,
    note: '',
    equip: null,
    equipPart: null,
    ...kitContent,
  };
}

import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line, getElementAtEvent } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { Modal, FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Item } from 'types/models/common';
import { DataGrid } from 'features/Table';
import { GetDashboardChartScopusWosArticleList } from 'features/Table/specifications/GetDashboardChartScopusWosArticleList';
import { COLORS, COLORS_BORDERS } from '../сolors';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationWebOfScience() {
  const {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    setActualDepartment,
    actualDepartment,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    categories,
    rusAndOtherByPeriod,
    actualYear,
    actualQuartile,
    seriesClickHandler,
    activePeriodFilter,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value, dataset } = context;
      return ` ${dataset.label}: ${value} шт.`;
    };

    const data = {
      labels: categories,
      datasets: rusAndOtherByPeriod.map((x, index) => ({
        label: x.name,
        data: x.data,
        fill: false,
        backgroundColor: COLORS[index],
        borderColor: COLORS_BORDERS[index],
        borderWidth: 2,
        tension: 0.1,
      })),
    };

    return (
      <Line
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Год издания',
              },
            },
            y: {
              min: 0,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
        onClick={(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
          const element = getElementAtEvent(chartRef.current!, event)[0];
          if (element) {
            seriesClickHandler(rusAndOtherByPeriod[element.datasetIndex], categories[element.index]);
          }
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Количество публикаций в научных журналах Web of Science и Scopus</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Метрика базы цитирования" hasLeftAlign>
              <Select
                value={activeFilter}
                options={periodOpts.current}
                onChange={(option: Item) => handleChangeActiveFilter(option)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение" hasLeftAlign>
              <Select
                mode={SelectMode.REFERENCE}
                value={actualDepartment}
                onChange={(option: ReferenceItem | null) => setActualDepartment(option)}
                settings={{ name: 'RefDepartment', title: 'Справочник "Подразеления"', isClearable: true }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '400px' }}>
          {Chart()}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={getModalTitle}
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetDashboardChartScopusWosArticleList({
            scientificIndexCode: activePeriodFilter,
            department: actualDepartment ? actualDepartment.id : '-1',
            year: actualYear,
            quartile: actualQuartile,
          })}
        />
      </Modal>
    </>
  );
}

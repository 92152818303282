import { useCallback, useLayoutEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

type Member = {
  historyLabel: string;
  name: string;
};

type File = { id: string; name: string };

type Props = { selectedRowId: string | null };

const useController = ({ selectedRowId }: Props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [publicationStatus, setPublicationStatus] = useState<string>('');
  const [bibliographicRecord, setBibliographicRecord] = useState<string>('');
  const [members, setMembers] = useState<Member[]>([]);
  const [file, setFile] = useState<File>({ id: '', name: '' });
  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');

  const fetchPublication = useCallback(() => {
    if (selectedRowId) {
      setIsLoading(true);
      getPublication.callAPI(
        {
          simpleFields: {
            status: 'DRAFT',
            typeEdition: 'LOCAL',
          },
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          attrIdFields: {},
          projects: [],
          mobileRequests: {},
          id: selectedRowId,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setIsLoading(false);
            if (data.success) {
              if (data.fields.bibliographicRecord) {
                setBibliographicRecord(data.fields.bibliographicRecord);
              }

              if (data.file) {
                setFile(data.file);
              }

              if (data.members && data.members.length) {
                setMembers(data.members);
              }

              if (data.status.status) {
                setPublicationStatus(data.status.status);
              }
            }
          },
        },
      );
    }
  }, [getPublication, selectedRowId]);

  useLayoutEffect(() => {
    fetchPublication();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    bibliographicRecord,
    members,
    file,
    publicationStatus,
    isLoading,
  };
};

export default useController;

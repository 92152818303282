import { Table } from 'types/models';
import { Filters } from './filters';

export const GetTextBookForIncentivePayment: Table.Specification = {
  apiID: 'GetTextBookForIncentivePayment',
  header: {
    firstLevel: {
      Panel: Filters,
      title: 'Учебник, учебное пособие',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      hasSelectButton: true,
    },
  },
};

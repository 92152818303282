import React from 'react';

import { Tab, Tabs } from 'components';

import { ParticipationConferences } from './ParticipationConferences';
import { ParticipationContests } from './ParticipationContests';
import { ParticipationExpositions } from './ParticipationExpositions';
import { ParticipationConcerts } from './ParticipationConcerts';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

type Props = {
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

export function ParticipationsEvents(props: Props) {
  const { defaultTabId, onTabClick } = props;

  const { initialOrganizationCode, userPermission } = useAppDataContext();

  return (
    <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
      <Tab
        title="Участие в конференциях"
        id="conference"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PARTICIPATIONS_CONFERENCE_ACCESS)}
      >
        <ParticipationConferences />
      </Tab>
      <Tab
        title="Участие в олимпиадах и конкурсах"
        id="contest"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PARTICIPATIONS_CONTEST_ACCESS)}
      >
        <ParticipationContests />
      </Tab>
      <Tab
        title="Участие в выставках"
        id="exposition"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_PARTICIPATIONS_EXPOSITION_ACCESS)}
      >
        <ParticipationExpositions />
      </Tab>
      {initialOrganizationCode?.code === 'TSU' && (
        <Tab
          title="Участие в творческих мероприятиях"
          id="concert"
          isVisible={isHasPermission(userPermission, Permits.PC_MENU_PARTICIPATIONS_CONCERT_ACCESS)}
        >
          <ParticipationConcerts />
        </Tab>
      )}
    </Tabs>
  );
}

import React, { useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, FormComponent, TextArea, TextAreaMode } from 'components';

import { GetSecurityDocumentContractListForProject } from 'features/Table/specifications';
import { SecurityDocumentContract } from 'types/models/SecurityDocumentContract';
import { Table } from 'types/models';
// eslint-disable-next-line max-len
import { SecurityDocumentContractViewTemplate } from 'features/Table/specifications/GetSecurityDocumentContractList/LeftPanelForThirdLevel/modalTemplates';

type Props = {
  securityDocumentContract: SecurityDocumentContract | null;
  setSecurityDocumentContract: (securityDocumentContract: SecurityDocumentContract | null) => void;
  label: string;
  modalTitle: string;
  isDisabled?: boolean;
  isRequired?: boolean;
};

export const SelectSecurityDocumentContract = ({
  securityDocumentContract,
  setSecurityDocumentContract,
  label,
  modalTitle,
  isDisabled,
  isRequired,
}: Props) => {
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const { methods: getSecurityDocumentContract } = BackendAPI.useBackendAPI('GetSecurityDocumentContract');

  const externalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        isDisabled: !securityDocumentContract,
        onClick: () => setIsFormOpen(true),
      },
    ],
    [securityDocumentContract],
  );

  return (
    <>
      <FormComponent.Field label={label} isRequired={isRequired}>
        <TextArea
          mode={TextAreaMode.TABLE}
          settings={{
            title: modalTitle,
            externalButtons: externalButtons,
            table: {
              specification: GetSecurityDocumentContractListForProject,
              onSelect: (row: Table.Entry | null) => {
                if (row) {
                  getSecurityDocumentContract.callAPI(
                    {
                      id: row.id,
                    },
                    {
                      onSuccessfullCall({ data }) {
                        setSecurityDocumentContract(data);
                      },
                    },
                  );
                } else {
                  setSecurityDocumentContract(null);
                }
              },
            },
          }}
          value={
            securityDocumentContract
              ? `${securityDocumentContract.kind?.label || ''} №${securityDocumentContract.number} от ${
                  securityDocumentContract.conclusionDate
                } (${securityDocumentContract.startDate} - ${securityDocumentContract.endDate}) \n(ID договора: ${
                  securityDocumentContract?.id || ''
                })`
              : ''
          }
          isDisabled={isDisabled}
        />
        {!!securityDocumentContract?.securityDocuments?.length && (
          <FormComponent.Description mode="warning" classMixin="is-hint">
            <pre>{securityDocumentContract?.securityDocuments?.map(doc => doc.label).join('\n')}</pre>
          </FormComponent.Description>
        )}
      </FormComponent.Field>

      <SecurityDocumentContractViewTemplate.Component
        isOpen={isFormOpen}
        onClose={() => setIsFormOpen(false)}
        id={securityDocumentContract?.id || undefined}
      />
    </>
  );
};

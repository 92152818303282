import React, { useMemo } from 'react';

import { Modal } from 'components';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Component as ApproveParticipationComponent } from './component';
import { Item } from 'types/models/common';

type Props = {
  type: string;
  id: string;
  isOpen: boolean;
  onClose: () => void;
  afterSubmit?: ({ nextRecordStatus }: { nextRecordStatus: Item }) => void;
};

const ApproveParticipationContainer = ({ type, isOpen, onClose, id, afterSubmit }: Props) => {
  const title = useMemo(() => {
    switch (type) {
      case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
        return 'Процедура утверждения участия в конференции';
      case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
        return 'Процедура утверждения участия в выставке';
      case ParticipationTypes.PARTICIPATION_CONTEST.code:
        return 'Процедура утверждения участия в конкурсе';
      case ParticipationTypes.PARTICIPATION_CONCERT.code:
        return 'Процедура утверждения участия в творческом мероприятии';
      default:
        return '';
    }
  }, [type]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <ApproveParticipationComponent type={type} onClose={onClose} id={id} afterSubmit={afterSubmit} />
    </Modal>
  );
};

export const Component = React.memo(ApproveParticipationContainer);

import { Notification } from 'types/models';
import { getMaybeField } from 'services/BackendAPI/configurations/commonConverters';

export function convertNotificationToXML({ id, blocks }: Notification.Notification) {
  return {
    _attr: id ? { id } : {},
    Blocks: { Block: blocks.filter(i => !!i.data).map(convertBlockToXML) },
  };
}

function convertBlockToXML({ id, name, data, position }: Notification.Block) {
  return {
    _attr: id ? { id } : {},
    ...getMaybeField('Name', name),
    ...getMaybeField('Data', data),
    ...getMaybeField('Position', position),
  };
}

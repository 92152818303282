import { Form } from 'types/models';
import { Exponent } from 'types/models/Exponent';

export function validate(exp: Exponent | null, formFields: Form.Fields) {
  const required = Object.keys(formFields)
    .filter(
      (fieldName: string) =>
        formFields[fieldName].required && formFields[fieldName]?.value?.length <= 0 && formFields[fieldName].title,
    )
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  return [...required];
}

import { useCallback, useMemo } from 'react';

import { Member } from 'features/SelectPerson';
import { getMockPerson } from 'features/Form/looks/person/helpers';
import { Item } from 'types/models/common';
import { CommissionMember } from 'types/models/ProtocolCommission';
import { ValueOf } from 'types/helpers';
import { getMockCommissionMember } from '../helpers';

type Props = {
  entry: CommissionMember | null;
  onFieldChange(row: CommissionMember | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Member | null) => {
      onFieldChange(convertMemberToCommissionMember(entry, value));
    },
    [entry, onFieldChange],
  );

  const handleRoleChange = useCallback(
    (value: Item | null) => {
      onFieldChange({ ...entry, role: value } as CommissionMember);
    },
    [entry, onFieldChange],
  );

  const makeChangeHandler = (key: keyof CommissionMember) => (value: ValueOf<CommissionMember>) => {
    if (entry) {
      onFieldChange({ ...entry, [key]: value });
    } else {
      onFieldChange({ ...getMockCommissionMember(), [key]: value });
    }
  };

  function convertCommissionMemberToMember(commissionMember: CommissionMember | null): Member | null {
    if (commissionMember) {
      const result: Member = {
        ...commissionMember,
        ...getMockPerson({ initialCountryCode: null }),
        ...(commissionMember?.person ? { person: commissionMember?.person } : {}),
      };
      return result;
    }
    return null;
  }

  function convertMemberToCommissionMember(
    prevCommissionMember: CommissionMember | null,
    member: Member | null,
  ): CommissionMember | null {
    if (member || prevCommissionMember) {
      const result: CommissionMember = {
        ...getMockCommissionMember(),
        ...prevCommissionMember,
        ...member,
      };
      return result;
    }
    return null;
  }

  const nameOptions = useMemo(() => {
    return entry?.person?.bibliographicNames.map(x => ({ value: x.id, label: x.name } as Item)) ?? [];
  }, [entry?.person?.bibliographicNames]);

  const priorityTooltipTxt =
    // eslint-disable-next-line max-len
    'Категория роли - это значение от 1 до 9, условный уровень персоны в составе комиссии. В документах список членов комиссии формируется в порядке возрастания категории. Для председателя комиссии Категория = 1. Члены комиссии с одинаковой категорией в списке размещаются в алфавитном порядке в рамках одной категории';

  return {
    handlePersonChange,
    nameOptions,
    handleRoleChange,
    convertCommissionMemberToMember,
    makeChangeHandler,
    priorityTooltipTxt,
  };
}

import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { GetReferenceScientistListSpecification } from 'features/Table/specifications';
import { Table } from 'types/models';
import { DefaultCustomSelect } from '../DefaultCustomSelect';
import { BuilderProps } from 'features/BuildReportPopup/models';

const ScientistCustomSelect = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification = {
    modalTableSpecification: GetReferenceScientistListSpecification({ hasSelectButton: true }),
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => {
      return {
        label: row.Fio || '',
        id: row.id || '',
      };
    },
    relationTableModalTitle: 'Справочник "Персоны"',
  };

  return (
    <DefaultCustomSelect
      isRequired
      parameterName={parameterName}
      placeholder="Введите ФИО или выберите персону из справочника"
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      modalTableRowConverter={specification.modalTableRowConverter}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      modalTableSpecification={specification.modalTableSpecification}
      relationTableModalTitle={specification.relationTableModalTitle}
    />
  );
};

const Component = memo(ScientistCustomSelect);

export { Component as ScientistCustomSelect };

import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import {
  ButtonMode,
  ButtonProps,
  Checkbox,
  FormComponent,
  Modal,
  RelationTableModal,
  Toolbar as SharedToolbar,
} from 'components';

import { Table, Report, Project } from 'types/models';
import { Permits } from 'utils/Permissions';
import { ProjectEditPartial, ProjectEditPartialList, SelectRequestModal, SubmitProps } from 'features/Table/views';
import { useReportsHook, Reports, BuildReportPopup } from 'features/BuildReportPopup';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

import { State } from '../makeUseCustomController';
import { MessageModal } from '../../GetProjectFeedlineList/Toolbar/MessageModal';
import { getMockFeedline } from '../../GetProjectFeedlineList/helpers';
import { getProjectPermits } from './permits';
import { ProjectCodeChangesInProject } from 'features/Form/views';
import { ProjectGridHelp } from './help';
import { ByPrototypeCopyOption, NotificationEventModule } from 'utils/Enums';
import { GetNotificationMonitorList } from '../../GetNotificationMonitorList';
import { getEnumItemLabel } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;
  const {
    setProjectId,
    setMode,
    reloadTable,
    setIsOpenForm,
    type,
    projectCopyOptions,
    setProjectCopyOptions,
    setPrototypeId,
  } = customState;

  const rows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = rows[0] || null;
  const tableStreams = useLocalTableStreams();
  const { enumMap } = useAppDataContext();

  const [feedline, setFeedline] = useState<Project.Feedline>(getMockFeedline());
  const [isFeedlineOpen, setIsFeedlineOpen] = useState<boolean>(false);
  const [paramType, setParamType] = useState<string>('');
  const [isOpenEditPartial, setIsOpenEditPartial] = useState(false);

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isOpenSelectRequestModal, setIsOpenSelectRequestModal] = useState(false);
  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
  const [isOpenNotificationMonitor, setIsOpenNotificationMonitor] = useState(false);
  const [isProjectCopyModalOpen, setIsProjectCopyModalOpen] = useState<boolean>(false);

  const { methods: createProjectAPI } = BackendAPI.useBackendAPI('CreateProject');
  const { methods: deleteProjectAPI } = BackendAPI.useBackendAPI('DeleteProject');
  const { methods: saveFeedlineAPI } = BackendAPI.useBackendAPI('SaveProjectUserFeedline');

  const submitFeedline = useCallback(
    (fl: Project.Feedline) => {
      saveFeedlineAPI.callAPI(
        { feedline: fl, projectIds: rows.map(row => row.id) },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
            setFeedline(getMockFeedline());
            setIsFeedlineOpen(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [rows, saveFeedlineAPI, tableStreams.reloadTable],
  );

  const deleteProject = useCallback(
    (id: string) => {
      deleteProjectAPI.callAPI(
        { id },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Проект успешно удален', theme: 'success' });
            reloadTable();
          },
        },
      );
    },
    [deleteProjectAPI, reloadTable],
  );

  const handleSubmitSelectRequestModal = useCallback(
    (submitProps: SubmitProps) => {
      const setProjectData = (id: string) => {
        setProjectId(id);
        setIsOpenSelectRequestModal(false);
        setIsOpenForm(true);
      };

      if (Object.keys(submitProps).length) {
        createProjectAPI.callAPI(
          {
            type,
            ...submitProps,
          },
          {
            onSuccessfullCall: ({ data }) => {
              showNotification({ message: 'Проект успешно создан', theme: 'success' });
              setProjectData(data);
              reloadTable();
            },
          },
        );
      } else {
        setProjectData('');
      }
    },
    [createProjectAPI, reloadTable, setIsOpenForm, setProjectId, type],
  );

  const reports = useMemo<Report[]>(() => [Reports.ProjectDocuments, Reports.ProjectResultsDetail], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const permits = getProjectPermits(type);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          setMode('view');
          setIsOpenForm(true);
        },
        permission: { name: permits.view },
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setMode('add');
          setIsOpenSelectRequestModal(true);
        },
        permission: { name: permits.add },
      },

      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setMode('edit');
          setIsOpenForm(true);
        },
        permission: { name: permits.edit },
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsOpenConfirmDeleteModal(true),
        permission: { name: permits.delete },
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'add', mode: 'new' },
        title: 'Создать копию проекта',
        onClick: () => {
          setMode('add');
          setIsProjectCopyModalOpen(true);
        },
        permission: { name: permits.add },
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'group', mode: 'view' },
        title: 'Наблюдатели за проектом',
        onClick: () => setIsOpenNotificationMonitor(true),
        permission: {
          name: Permits.NOTIFICATION_MONITOR_ADMINISTRATE,
        },
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'documents', mode: 'edit' },
        title: 'Добавить значение классификатора для выделенной группы проектов',
        expandedList: {
          list: () => ProjectEditPartialList,
          callback: (params: { value: string }) => {
            setParamType(params.value);
            setIsOpenEditPartial(true);
          },
        },
        permission: {
          name: Permits.RESEARCH_JOB_EDIT,
        },
        isDisabled: rows.length < 1,
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: () => setIsFeedlineOpen(true),
        isDisabled: !rows.length,
      },
      {
        icon: { type: 'clock' },
        title: 'История изменений',
        onClick: () => setIsOpenCodeChangesModal(true),
        isDisabled: rows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: rows.length !== 1,
      },
    ],
    [
      permits.view,
      permits.add,
      permits.edit,
      permits.delete,
      rows,
      getReports,
      handleSetCurrentReport,
      setMode,
      setIsOpenForm,
      setIsOpenSelectRequestModal,
      setIsOpenConfirmDeleteModal,
      setIsOpenCodeChangesModal,
      setParamType,
      setIsOpenEditPartial,
    ],
  );

  const ByPrototypeCopyOptionDescription: Record<ByPrototypeCopyOption, string> = {
    [ByPrototypeCopyOption.ABOUT]:
      // eslint-disable-next-line max-len
      'Программа, Мероприятие, Код, Проект выполняется на базе, Статус договора, Уровень конфиденциальности, Предупреждать в разделе с документами, Заказчики, Вид Финансирования, Источник финансирования - из раздела Общий объём финансирования проекта',
    [ByPrototypeCopyOption.CLASSIFICATORS]:
      // eslint-disable-next-line max-len
      'Области научных интересов, Отрасли знаний (РНФ), Приоритетные направления развития модернизации и технического развития экономики России, Приоритетные направления развития, Технологические платформы',
    [ByPrototypeCopyOption.DESCRIPTION]:
      // eslint-disable-next-line max-len
      'Аннотация, ГРНТИ, Ключевые слова, Критические технологии, 2-Наука (ОКВЭД, ЛКСЭЦ, Отрасль науки), Приоритетные направления развития науки, технологий и техники, Приоритеты Стратегии НТР РФ, Тема проекта, УДК',
  };

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isOpenSelectRequestModal && (
        <SelectRequestModal
          isOpen={isOpenSelectRequestModal}
          onClose={() => setIsOpenSelectRequestModal(false)}
          onSubmit={handleSubmitSelectRequestModal}
          projectType={type}
        />
      )}

      {isOpenConfirmDeleteModal && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isOpenConfirmDeleteModal}
          onClose={() => setIsOpenConfirmDeleteModal(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: () => {
                deleteProject(rows[0].id);
                setIsOpenConfirmDeleteModal(false);
              },
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsOpenConfirmDeleteModal(false),
            },
          ]}
          size="small"
        >
          <>
            Проект № {tableState.selectedRows[0]?.ProjectNumber}, руководитель:{' '}
            {tableState.selectedRows[0]?.Leader || <em>Не указан</em>}. Удалить проект?
          </>
        </Modal>
      )}

      <ProjectCodeChangesInProject
        isOpen={isOpenCodeChangesModal}
        onClose={() => setIsOpenCodeChangesModal(false)}
        projectId={rows[0]?.id}
        projectNumber={rows[0]?.ProjectNumber}
      />

      {isOpenEditPartial && (
        <ProjectEditPartial
          isOpen={isOpenEditPartial}
          onClose={() => setIsOpenEditPartial(false)}
          projectIds={rows.map(i => i.id)}
          paramType={paramType}
        />
      )}

      <MessageModal
        isOpen={isFeedlineOpen}
        onClose={() => setIsFeedlineOpen(false)}
        feedline={feedline}
        changeFeedline={setFeedline}
        onSubmit={submitFeedline}
      />

      <RelationTableModal
        specification={{ ...GetNotificationMonitorList({ module: NotificationEventModule.PROJECT, sourceId: rows[0]?.id }) }}
        modalTitle={`Наблюдатели за проектом: № ${rows[0]?.ProjectNumber}, ${rows[0]?.StartDate || ''} - ${
          rows[0]?.EndDate || ''
        }, ID ${rows[0]?.id}`}
        isOpen={isOpenNotificationMonitor}
        onClose={() => setIsOpenNotificationMonitor(false)}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: rows[0]?.id || '',
        }}
      />

      <Modal
        mode="help"
        title="Список проектов"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ProjectGridHelp()}
      </Modal>

      <Modal
        title={`Создание копии проекта №${selectedRow?.ProjectNumber || ''}, ${selectedRow?.StartDate || ''} - ${
          selectedRow?.EndDate || ''
        } (ID ${selectedRow?.id || ''})`}
        isOpen={isProjectCopyModalOpen}
        onClose={() => {
          setIsProjectCopyModalOpen(false);
          setProjectCopyOptions(null);
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setPrototypeId(rows[0]?.id || null);
              setProjectCopyOptions(prev => prev || []);
              setIsOpenForm(true);
              setIsProjectCopyModalOpen(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => {
              setIsProjectCopyModalOpen(false);
              setProjectCopyOptions(null);
            },
          },
        ]}
        size="medium"
      >
        <>
          <FormComponent.Line>
            <strong>Выберете вкладки, которые необходимо скопировать из исходного проекта в копию:</strong>
          </FormComponent.Line>
          {Object.values(ByPrototypeCopyOption).map((copyOption, index) => (
            <FormComponent.Line key={index} hasFreeFormat>
              <FormComponent.Field>
                <Checkbox
                  checked={(projectCopyOptions || []).some(x => x === copyOption)}
                  onChange={e => {
                    if (e) {
                      setProjectCopyOptions(prev => [...(prev || []), copyOption]);
                    } else {
                      const optionIndex = (projectCopyOptions || []).findIndex(x => x === copyOption);
                      if (optionIndex !== -1) {
                        setProjectCopyOptions(prev =>
                          (prev || [])?.length > 1 ? prev!.filter((_, i) => i !== optionIndex) : null,
                        );
                      }
                    }
                  }}
                />
              </FormComponent.Field>
              <FormComponent.Text>
                <p>
                  <strong>{getEnumItemLabel('ByPrototypeCopyOption', copyOption, enumMap)}</strong> -{' '}
                  {ByPrototypeCopyOptionDescription[copyOption]}
                </p>
              </FormComponent.Text>
            </FormComponent.Line>
          ))}
        </>
      </Modal>
    </>
  );
}

export { Toolbar };

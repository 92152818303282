import React from 'react';
import { useHistory } from 'react-router-dom';
import block from 'bem-cn';

import {
  Button,
  ButtonMode,
  Checkbox,
  CheckboxMode,
  Collapse,
  FormComponent,
  Header,
  Modal,
  ReferenceItem,
  SectionTitle,
  Select,
  Tab,
  Tabs,
  UploadImage,
} from 'components';

import { Color, ColorBase } from 'constants/colors';
import { useController } from './controller';
import { Lot, Tender } from 'types/models/Tender';
import { Item } from 'types/models/common';
import { Documents } from 'features/Documents';
import { redirectTo, serialize } from 'features/AppData/helpers';
import { TenderRequestType } from 'utils/Enums';
import { getPersonContacts } from 'utils/Helpers';
import { TenderHelp } from './help';
// import { Statistic } from '../statistic';

import './style.scss';

const b = block('tenders');

type Props = {
  isHome?: boolean;
  openTenderId?: string;
  setOpenTenderId?(id: string): void;
};

function Notices({ isHome = false, openTenderId = '', setOpenTenderId }: Props) {
  const {
    selectedTender,
    isDetailsModalOpen,
    setIsDetailsModalOpen,
    showDetailsModal,
    enableFavorites,
    setEnableFavorites,
    interests,
    optionsInterests,
    setInterests,
    categories,
    optionsCategories,
    setCategories,
    participants,
    optionsParticipants,
    setParticipants,
    listTenders,
    filteredList,
    favorites,
    changeFavorite,
    isHelpFormOpen,
    setIsHelpFormOpen,
    buttons,
  } = useController(openTenderId, setOpenTenderId);

  const history = useHistory();

  const info = (
    <>
      <strong>Показать только избранное:&nbsp;&nbsp;</strong>
      <Checkbox mode={CheckboxMode.SLIDER} checked={enableFavorites} onChange={setEnableFavorites} />
      <strong>Показано конкурсов:&nbsp;</strong>
      <span style={{ minWidth: '60px', textAlign: 'right' }}>
        {filteredList.length} из {listTenders.length}
      </span>
    </>
  );

  const concateTags = (items: ReferenceItem[]) => items?.map(x => x.label).join(' # ') || 'Любые';

  const getLotList = (lots: Lot[]) => {
    return () => {
      const result = new Map<string, string>();

      lots.forEach(lot => {
        result.set(lot.theme, lot.id || '');
      });

      return result;
    };
  };

  const handleRequest = (tenderId: string, lotId: string) => {
    const params = {
      mode: 'add',
      requestType: TenderRequestType.PROGRAM_REQUEST,
      tenderId,
      lotId,
      text: '4-NT Section I. Use of the IPO, Section II. Financial indicators of the use of the IPO',
    };
    redirectTo(history, `/account/requests/${serialize.set(params)}`);
  };

  const notices = (
    <>
      <Collapse title="Фильтры для поиска" buttons={buttons} info={info}>
        <FormComponent.Line>
          <FormComponent.Field label="Области">
            <Select isMulti value={interests} options={optionsInterests} onChange={(options: Item[]) => setInterests(options)} />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Категории конкурсов">
            <Select
              isMulti
              value={categories}
              options={optionsCategories}
              onChange={(options: Item[]) => setCategories(options)}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Категории участников">
            <Select
              isMulti
              value={participants}
              options={optionsParticipants}
              onChange={(options: Item[]) => setParticipants(options)}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Collapse>

      <FormComponent.Wrapper>
        {!!filteredList.length ? (
          <div className={b('block-wrapper')}>
            {filteredList.map((tender: Tender) => (
              <div className={b('block-item')} key={tender.id + tender.announcementDate}>
                <Button
                  icon={{ type: 'star', color: favorites.includes(tender.id || '') ? Color.primary : ColorBase.default }}
                  title={favorites.includes(tender.id || '') ? 'Удалить из избранного' : 'Добавить в избранное'}
                  onClick={() => changeFavorite(tender.id || '')}
                  style={{ alignSelf: 'flex-end', margin: '0 0 -24px 0', zIndex: 1 }}
                />

                <div className={b('block-item-header')}>
                  {(tender.logo?.id || tender.program?.logo?.id) && (
                    <UploadImage viewMode text="Логотип конкурса" id={tender.logo?.id || tender.program?.logo?.id} />
                  )}

                  <h3 title={tender.name}>{tender.name}</h3>
                </div>

                {/* {!tender.isSpecial && (
                  <FormComponent.Description mode="info">
                    <em>Для согласования с консультантом необходимо оформить заявку на вкладке Заявки на конкурсы</em>
                  </FormComponent.Description>
                )} */}

                <div className={b('block-item-text')}>
                  <div className={b('block-item-dates')}>
                    <div>
                      <strong>Подать консультанту до:</strong>
                      <span>{tender.dateUniversity || '-'}</span>
                    </div>
                    <div>
                      <strong>Приём заявок до:</strong>
                      <span>{tender.dateOpenEnvelope || '-'}</span>
                    </div>
                    <div>
                      <strong>Подведение итогов:</strong>
                      <span>{tender.dateSummingUp || '-'}</span>
                    </div>
                  </div>

                  <div className={b('block-item-tags')}>
                    <div>
                      <span title={concateTags(tender.scienceDomainInterrests)}>
                        <strong>Область:</strong>
                        {concateTags(tender.scienceDomainInterrests)}
                      </span>
                    </div>
                    <div>
                      <span title={concateTags(tender.categories)}>
                        <strong>Категории конкурсов:</strong>
                        {concateTags(tender.categories)}
                      </span>
                    </div>
                    <div>
                      <span title={concateTags(tender.participants)}>
                        <strong>Категории участников:</strong>
                        {concateTags(tender.participants)}
                      </span>
                    </div>
                  </div>
                </div>

                <div className={b('block-item-links')}>
                  {!!tender.lots.length && tender.isPrivateCabinetActual && (
                    <Button
                      mode={ButtonMode.PRIMARY}
                      text="Подать заявку"
                      {...(getLotList(tender.lots)().size < 2
                        ? {
                            onClick: () => handleRequest(tender.id || '', tender.lots[0].id || ''),
                          }
                        : {
                            onClick: () => {},
                            expandedList: {
                              list: getLotList(tender.lots),
                              callback: lot => handleRequest(tender.id || '', lot.value),
                            },
                          })}
                    />
                  )}
                  <Button classMixin="primary" text="подробнее >" onClick={() => showDetailsModal(tender.id || '')} />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className={b('block-empty')}>Нет объявлений в разделе "О конкурсах". Настройте фильтры</div>
        )}
      </FormComponent.Wrapper>
    </>
  );

  return (
    <>
      <Modal
        isOpen={isDetailsModalOpen}
        onClose={() => {
          setOpenTenderId?.('');
          setIsDetailsModalOpen(false);
        }}
        title="Информация о конкурсе"
        size={['large', 'medium-height']}
      >
        <Tabs id="tender">
          <Tab title="Основная информация">
            <div className={b('block-item-header')}>
              {(selectedTender.logo?.id || selectedTender.program?.logo?.id) && (
                <UploadImage viewMode text="Логотип конкурса" id={selectedTender.logo?.id || selectedTender.program?.logo?.id} />
              )}

              <h3 title={selectedTender.name}>{selectedTender.name}</h3>
            </div>

            <div className={b('block-item-text')}>
              <div className={b('block-item-dates').mix('details')}>
                <div>
                  <strong>Дата объявления:</strong>
                  <span>{selectedTender.announcementDate || '-'}</span>
                </div>
                <div>
                  <strong>Подать консультанту до:</strong>
                  <span>{selectedTender.dateUniversity || '-'}</span>
                </div>
                <div>
                  <strong>Приём заявок до:</strong>
                  <span>{selectedTender.dateOpenEnvelope || '-'}</span>
                </div>
                <div>
                  <strong>Дата рассмотрения заявок:</strong>
                  <span>{selectedTender.dateExamenation || '-'}</span>
                </div>
                <div>
                  <strong>Подведение итогов:</strong>
                  <span>{selectedTender.dateSummingUp || '-'}</span>
                </div>
              </div>

              <div className={b('block-item-tags').mix('details')}>
                <div>
                  <span title={concateTags(selectedTender.scienceDomainInterrests)}>
                    <strong>Область:</strong>
                    {concateTags(selectedTender.scienceDomainInterrests)}
                  </span>
                </div>
                <div>
                  <span title={concateTags(selectedTender.categories)}>
                    <strong>Категории конкурсов:</strong>
                    {concateTags(selectedTender.categories)}
                  </span>
                </div>
                <div>
                  <span title={concateTags(selectedTender.participants)}>
                    <strong>Категории участников:</strong>
                    {concateTags(selectedTender.participants)}
                  </span>
                </div>
              </div>
            </div>

            <div className={b('block-item-tags')}>
              <strong>Ссылка на конкурс:&nbsp;</strong>
              {!!selectedTender.url ? (
                <FormComponent.Link href={selectedTender.url}>
                  <>{selectedTender.url.length > 60 ? `${selectedTender.url.slice(0, 60)}...` : selectedTender.url}</>
                </FormComponent.Link>
              ) : (
                <em>Ссылка отсутствует</em>
              )}
            </div>

            <SectionTitle title="Заказчики" />

            {(selectedTender.customers || []).map((i, index) => (
              <div className={b('block-item-customer')} key={index}>
                {[
                  '-',
                  i.fullName,
                  (() => {
                    const data = [i.address, i.contacts].filter(Boolean).join('; ');
                    return !!data ? `(${data})` : '';
                  })(),
                ]
                  .filter(Boolean)
                  .join(' ')}
              </div>
            )) || <em>Не указаны</em>}

            <SectionTitle title="Описание конкурса" />

            <FormComponent.Line>
              {!!selectedTender.description ? (
                <div className={b('data')} dangerouslySetInnerHTML={{ __html: selectedTender.description }}></div>
              ) : (
                <div style={{ textAlign: 'center' }}>
                  <em>Описание отсутствует</em>
                </div>
              )}
            </FormComponent.Line>

            <SectionTitle title="Консультанты по заявке" />

            <div className={b('block-item-tiles')}>
              {(selectedTender?.contacts || []).length ? (
                selectedTender?.contacts.map((i, index) => {
                  return (
                    <div key={index}>
                      <strong>{i.fullName}</strong>
                      <div className={b('block-item-tiles')}>
                        <div className={b('block-item-tiles-text')}>
                          <div>
                            <span>Электронная почта:&nbsp;</span>
                            {!!getPersonContacts(i.contacts, 'emails') ? (
                              <span>{getPersonContacts(i.contacts, 'emails')}</span>
                            ) : (
                              <em>Не указано</em>
                            )}
                          </div>
                          <div>
                            <span>Телефон:&nbsp;</span>
                            {!!getPersonContacts(i.contacts, 'phones') ? (
                              <span>{getPersonContacts(i.contacts, 'phones')}</span>
                            ) : (
                              <em>Не указано</em>
                            )}
                          </div>
                        </div>
                        <div>
                          <UploadImage text="Фото пользователя" id={i.avatar?.id} viewMode />
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <em>Не указаны</em>
              )}
            </div>
          </Tab>
          <Tab title="Конкурсная документация">
            <Documents
              documents={selectedTender.documents ?? []}
              setDocuments={() => {}}
              disabled={true}
              sharedLabel="Отображать на формах Заявки и Проекта"
              isCanIsSharedEdit
              defaultSharedValue
            />
          </Tab>
        </Tabs>
      </Modal>

      {/* Hidden statistic for all (temporary) */}
      {isHome && false ? (
        // <FormComponent.ColumnWrapper>
        //   <FormComponent.Column style={{ width: '65%' }}>
        //     <Header>Объявления о конкурсах</Header>
        //     {notices}
        //   </FormComponent.Column>
        //   <FormComponent.Column style={{ width: '35%' }}>
        //     <Header>Статистика</Header>
        //     <Statistic />
        //   </FormComponent.Column>
        // </FormComponent.ColumnWrapper>
        <>
          <Header>Объявления о конкурсах</Header>
          {notices}
        </>
      ) : (
        notices
      )}

      <Modal mode="help" title="О конкурсах" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="medium">
        {TenderHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(Notices);

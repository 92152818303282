import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { RequestType } from 'utils/Enums';
import { makeUseCustomController, State } from './makeUseCustomController';
import { Color } from 'constants/colors';

export type Arguments = {
  hasToolbarButtonMode?: boolean;
  action?: string;
};

export function GetPersonalRequestList({ hasToolbarButtonMode = false, action = '' }: Arguments): T.Specification<State> {
  return {
    apiID: 'GetPersonalRequestList',
    header: {
      firstLevel: {
        title: hasToolbarButtonMode ? '' : 'Список заявок на выполнение НИР и ОКР',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({ hasToolbarButtonMode, action }),
    highlightRow: row => (!!row.LibraryNote ? Color.danger : ''),
    isWithQna: true,
    enumFilterValues: {
      enumName: 'RequestType',
      excludeValues: [RequestType.CONTEST_REQUEST_ACTUALIZATION, RequestType.CONTEST_REQUEST_EXTENSION],
    },
  };
}

import { useState, useEffect } from 'react';
import { Author } from 'types/models';

type Props = {
  recipients: Author[];
  setRecipient: (recipient: Author | null) => void;
};

const useController = ({ recipients, setRecipient }: Props) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  useEffect(() => {
    if (typeof selectedRowIndex === 'number') {
      setRecipient({ ...recipients[selectedRowIndex] });
    } else {
      setRecipient(null);
    }
  }, [selectedRowIndex]); // eslint-disable-line

  return {
    selectedRowIndex,
    setSelectedRowIndex,
  };
};

export default useController;

import React, { memo } from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { Form, Table } from 'types/models';
import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  printArticle: Form.PrintArticle | null;
  handleChangePrintArticle: (nextPublication: Form.PrintArticle | null) => void;
  isDisabled: boolean;
  relatedTableState?: Table.State;
  releaseId?: string;
  releaseYear?: string;
};

export const EditPreprintRelation = memo(
  ({ printArticle, handleChangePrintArticle, isDisabled, relatedTableState, releaseId, releaseYear }: Props) => {
    const { modalTableRowToPrintPublicationConventer, printPublicationTableConfig } = useController({
      relatedTableState,
      releaseId,
      releaseYear,
    });

    return (
      <FormComponent.Line>
        <FormComponent.Field label="Публикация" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Выбор печатной публикации',
              isClearable: false,
              visibleStatus: getStatusRecordMessage('', printArticle?.id, printArticle?.status),
              table: {
                specification: printPublicationTableConfig,
                onSelect: (row: Table.Entry | null) =>
                  handleChangePrintArticle(row ? modalTableRowToPrintPublicationConventer(row) : null),
              },
            }}
            value={printArticle?.name}
            isDisabled={isDisabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    );
  },
);

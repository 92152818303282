import React from 'react';
import block from 'bem-cn';

import { ButtonMode, FormComponent, Header, ListEditTable, Modal, TextInput, TextInputMode, Toolbar } from 'components';

import { Component as Block } from './block';
import { useController } from './controller';

import '../style.scss';

const b = block('useful');

function UsefulLinks() {
  const {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalUrl,
    addModalButtons,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalUrl,
    handleAddModalUrlChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalUrl,
    handleEditModalUrlChange,
    list,
    GetIndex,
    cutUrlValue,
    setSelectedRowIndex,
    isProfile,
    buttons,
  } = useController();

  return (
    <>
      <div className={b()}>
        <Header>Полезные ссылки</Header>
        {!isProfile && (
          <div className={b('toolbar')}>
            <Toolbar buttons={buttons} />
          </div>
        )}
        <div className={b('list')}>
          {list.map(e => (
            <Block props={e} key={e.id + e.createdDate} />
          ))}
        </div>
      </div>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteButtonClick,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Удалить ссылку?</>
      </Modal>

      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setAddModalCaption('');
          setAddModalUrl('');
        }}
        title="Добавление ссылки"
        size={['medium', 'small-height']}
      >
        <Toolbar buttons={addModalButtons} mode="form" />

        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Название" isRequired>
              <TextInput value={addModalCaption} onChange={handleAddModalCaptionChange} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ссылка" isRequired>
              <TextInput mode={TextInputMode.URL} value={addModalUrl} onChange={handleAddModalUrlChange} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        title="Редактирование списка ссылок"
        size={['medium', 'small-height']}
      >
        <FormComponent.Template>
          <Toolbar buttons={editModalButtons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Название" isRequired>
                <TextInput
                  value={editModalCaption}
                  onChange={handleEditModalCaptionChange}
                  isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Ссылка" isRequired>
                <TextInput
                  mode={TextInputMode.URL}
                  value={editModalUrl}
                  onChange={handleEditModalUrlChange}
                  isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            <ListEditTable
              isStickyHeader={true}
              rows={list}
              columns={[
                { label: '№', formatValue: row => (GetIndex(row.id) + 1).toString(), styles: { width: '5%' } },
                { label: 'Название', formatValue: row => row.caption, styles: { width: '20%' } },
                { label: 'Ссылка', formatValue: row => cutUrlValue(row.url), styles: { width: '75%' } },
              ]}
              selectedRowIndex={selectedRowIndex}
              selectRow={setSelectedRowIndex}
            />
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>
    </>
  );
}

export const Component = React.memo(UsefulLinks);

import React, { useMemo, useState } from 'react';

import { ButtonProps, FormComponent, ListEditTable, Modal, Toolbar } from 'components';

import { Award } from 'types/models';
import { showNotification } from 'features/Notifications';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { useController } from './controller';

type Props = {
  isOpen: boolean;
  onClose(): void;
  awards?: Award[];
  id?: string;
};

export const AwardsModal = React.memo((props: Props) => {
  const { isOpen, onClose, id, awards: awardsArray } = props;
  const token = getAuthToken();

  const { awards } = useController({ isOpen, id, awardsArray });

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'download' },
        title: 'Скачать файл',
        onClick: () => {
          if (selectedRowIndex !== null && awards.length) {
            downloadFile(awards[selectedRowIndex].file.id, token);
          } else {
            showNotification({ message: 'Выберите награду для скачивания файла', theme: 'danger' });
          }
        },
        isDisabled: selectedRowIndex === null || !awards[selectedRowIndex]?.file.id,
      },
    ],
    [selectedRowIndex, awards, token],
  );

  if (!awards.length) {
    return null;
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Награды" size="large">
      <FormComponent.Wrapper>
        <Toolbar buttons={buttons} mode="list" />

        <ListEditTable
          rows={awards}
          columns={[
            { label: 'Награда', formatValue: row => row.name || <em>Не указано</em> },
            { label: 'Номинация', formatValue: row => row.nomination ?? <em>Не указана</em> },
            {
              label: 'Получатели',
              formatValue: row => row.recipients.map(x => x.person?.fullName).join(', ') || <em>Не указаны</em>,
            },
            { label: 'Документ', formatValue: row => (row.file?.id ? row.file.name : <em>Не добавлен</em>) },
          ]}
          selectedRowIndex={selectedRowIndex}
          selectRow={setSelectedRowIndex}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
});

import { ForeignProject } from 'types/models';

type ValidationItem = { isValid: boolean; invalidMessage: string; key: keyof ForeignProject.ForeignProject };

export function validate(foreignProject: ForeignProject.ForeignProject): ValidationItem[] {
  const isValidTheme = Boolean(foreignProject.name);
  const isValidNumber = Boolean(foreignProject.number);
  const isValidLeader = Boolean(foreignProject.leader);
  const isValidStartDate = Boolean(foreignProject.startDate);
  const isValidEndDate = Boolean(foreignProject.endDate);
  const isValidRole = Boolean(foreignProject.role);

  return [
    { isValid: isValidNumber, invalidMessage: 'Не введен номер проекта', key: 'number' },
    { isValid: isValidTheme, invalidMessage: 'Не указана тема проекта', key: 'name' },
    { isValid: isValidLeader, invalidMessage: 'Не указан руководитель проекта', key: 'leader' },
    { isValid: isValidStartDate, invalidMessage: 'Не указана дата начала', key: 'startDate' },
    { isValid: isValidEndDate, invalidMessage: 'Не указана дата окончания', key: 'endDate' },
    { isValid: isValidRole, invalidMessage: 'Не указано роль', key: 'role' },
  ];
}

import { Project } from 'types/models';
import { formatStringToDate } from 'utils/Helpers';

export function findLastestPerformerWithRole(performers: Project.Performer[], role: string): Project.Performer | null {
  // поиск исполнителей, которые работали в искомой роли. Отсеивание периодов работ с другими ролями
  const performersWithRoleJobs: Project.Performer[] = performers
    .map(x => ({
      ...x,
      jobPeriods: x.jobPeriods.filter(y => y.role?.value === role),
    }))
    .filter(x => x.jobPeriods.length);
  if (!performersWithRoleJobs.length) return null;
  // поиск наибольшей даты окончания периода работы для каждого исполнителя
  const rolesWithHighestDates: Project.Performer[] = performersWithRoleJobs.map(x => ({
    ...x,
    jobPeriods: [
      x.jobPeriods.reduce((prev, current) =>
        formatStringToDate(prev.endDate) > formatStringToDate(current.endDate) ? prev : current,
      ),
    ],
  }));
  if (!rolesWithHighestDates.length) return null;
  // поиск исполнителя с наибольшей датой окончания периода работы
  const roleWithHighestDate: Project.Performer = rolesWithHighestDates.reduce((prev, current) =>
    formatStringToDate(prev.jobPeriods[0].endDate) > formatStringToDate(current.jobPeriods[0].endDate) ? prev : current,
  );
  return roleWithHighestDate;
}

import { useLayoutEffect, useMemo, useState, useEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Permits } from 'utils/Permissions';
import { UsefulLink } from 'types/models/UsefulLink';
import { showNotification } from 'features/Notifications';

export function useController() {
  const { isProfile } = usePrivatePageContext();

  const [list, setList] = useState<UsefulLink[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [addModalCaption, setAddModalCaption] = useState<string>('');
  const [addModalUrl, setAddModalUrl] = useState<string>('');
  const [editModalCaption, setEditModalCaption] = useState<string>('');
  const [editModalUrl, setEditModalUrl] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);

  const { methods: getUsefulLinks } = BackendAPI.useBackendAPI('GetUsefulLinks', {
    onSuccessfullCall: ({ data }) => {
      setList(data.sort((a, b) => Number(b.id) - Number(a.id)));
    },
  });

  const { methods: saveUsefulLinks } = BackendAPI.useBackendAPI('SaveUsefulLinks', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Ссылка сохранена', theme: 'success' });
      getUsefulLinks.callAPI({});
      setIsAddModalOpen(false);
    },
  });

  const { methods: deleteModel } = BackendAPI.useBackendAPI('DeleteModel', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Ссылка удалена', theme: 'success' });
      getUsefulLinks.callAPI({});
    },
  });

  useEffect(() => {
    if (selectedRowIndex || selectedRowIndex === 0) {
      setEditModalCaption(list[selectedRowIndex].caption);
      setEditModalUrl(list[selectedRowIndex].url);
    } else {
      setEditModalCaption('');
      setEditModalUrl('');
    }
  }, [list, selectedRowIndex]);

  const LOCAL_MAX_URL_LINK_LENGTH = 80;

  const cutUrlValue = (val: string) => {
    if (val) {
      return val.length > LOCAL_MAX_URL_LINK_LENGTH ? `${val.slice(0, LOCAL_MAX_URL_LINK_LENGTH)}...` : val;
    }
    return '';
  };

  useLayoutEffect(() => {
    getUsefulLinks.callAPI({});
    // eslint-disable-next-line
      }, []);

  function GetIndex(recordId: string) {
    const index = list.map((e: any) => e.id).indexOf(recordId);
    return index;
  }

  const handleAddModalCaptionChange = useCallback((value: string) => {
    setAddModalCaption(value);
  }, []);

  const handleAddModalUrlChange = useCallback((value: string) => {
    setAddModalUrl(value);
  }, []);

  const handleEditModalCaptionChange = useCallback((value: string) => {
    setEditModalCaption(value);
  }, []);

  const handleEditModalUrlChange = useCallback((value: string) => {
    setEditModalUrl(value);
  }, []);

  const handleAddModalSaveButtonClick = useCallback(() => {
    if (addModalCaption && addModalUrl) {
      const editingRecord = {
        id: null,
        caption: addModalCaption,
        url: addModalUrl,
      };
      saveUsefulLinks.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [addModalCaption, addModalUrl, saveUsefulLinks]);

  const handleEditModalSaveButtonClick = useCallback(() => {
    if (editModalCaption && editModalUrl) {
      const editingRecord = {
        id: selectedRowIndex || selectedRowIndex === 0 ? list[selectedRowIndex].id : null,
        caption: editModalCaption,
        url: editModalUrl,
      };
      saveUsefulLinks.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [editModalCaption, editModalUrl, list, saveUsefulLinks, selectedRowIndex]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRowIndex || selectedRowIndex === 0)
      deleteModel.callAPI({ rowId: list[selectedRowIndex].id, commandName: 'DeleteEntity', entityName: 'UsefulLink' });
    setIsConfirmPopupOpen(false);
  }, [deleteModel, list, selectedRowIndex]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        permission: { name: Permits.USEFUL_LINK_ADD },
        onClick: () => {
          setIsAddModalOpen(true);
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        permission: { name: Permits.USEFUL_LINK_LIST_EDIT },
        onClick: () => {
          setIsEditModalOpen(true);
        },
      },
    ],
    [],
  );

  const addModalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Cохранить',
        onClick: () => {
          handleAddModalSaveButtonClick();
        },
      },
    ],
    [handleAddModalSaveButtonClick],
  );

  const editModalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Cохранить',
        onClick: () => {
          handleEditModalSaveButtonClick();
        },
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsConfirmPopupOpen(true);
        },
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
      },
    ],
    [handleEditModalSaveButtonClick, selectedRowIndex],
  );

  return {
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalUrl,
    addModalButtons,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalUrl,
    handleAddModalUrlChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalUrl,
    handleEditModalUrlChange,
    list,
    GetIndex,
    cutUrlValue,
    setSelectedRowIndex,
    isProfile,
    buttons,
  };
}

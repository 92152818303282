import React, { memo } from 'react';

import { Modal } from 'components';

import { Component as BuildReportPopupComponent } from './component';
import { PropsValues } from './models';

type Props = {
  reportCaption: string;
  reportName: string;
  isOpen: boolean;
  onClose: () => void;
  systemDepartMentLabel?: string;
  values?: PropsValues;
};

const BuildReportPopupContainer = ({ reportCaption, reportName, isOpen, onClose, systemDepartMentLabel, values = {} }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={`Параметры отчета "${reportCaption}"${systemDepartMentLabel ? `. Подразделение: ${systemDepartMentLabel}` : ''}`}
    size="large"
  >
    <BuildReportPopupComponent reportName={reportName} onClose={onClose} values={values} />
  </Modal>
);

export const Container = memo(BuildReportPopupContainer);

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetExpenseDocumentItemList: Table.Specification = {
  apiID: 'GetExpenseDocumentItemList',
  header: {
    firstLevel: {
      title: 'Расходы по шифрам проектов (фактические суммы по видам расходов бухгалтерского учёта)',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

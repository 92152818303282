import React, { useState, useCallback, useMemo } from 'react';
import { renderToString } from 'react-dom/server';

import { ButtonMode, Checkbox, FormComponent, ListEdit, ListEditTable, Modal } from 'components';

import { Project } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Fields } from './Fields';
import { getMockContestRequest } from 'features/Form/looks/project/ProjectForm/helpers';
import { ContestRequestCopyOptions, CopyFromContestRequest } from 'features/Form/looks/project/ProjectForm/types';

type Props = {
  disabled: boolean;
  contestRequests: Project.ContestRequest[];
  setContestRequests(contestRequests: Project.ContestRequest[]): void;
  stages: Project.Stage[];
  copyFromContestRequest({ id, stageId, copyOptions }: CopyFromContestRequest): void;
};

const Component: React.FC<Props> = (props: Props) => {
  const { disabled, contestRequests, setContestRequests, stages, copyFromContestRequest } = props;

  const [isCopyOptionsModalOpen, setIsCopyOptionsModalOpen] = useState<boolean>(false);
  const [isCopyStagesModalOpen, setIsCopyStagesModalOpen] = useState<boolean>(false);
  const [selectedContestRequest, setSelectedContestRequest] = useState<Project.ContestRequest | null>(null);
  const [selectedStageIndex, setSelectedStageIndex] = useState<number | null>(null);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);

  const [contestRequestCopyOptions, setContestRequestCopyOptions] = useState<ContestRequestCopyOptions>({
    isStage: false,
    isIndicators: false,
    isPerformers: false,
  });

  const selectedStage = useMemo(() => {
    if (selectedStageIndex === null) return null;
    return stages[selectedStageIndex];
  }, [selectedStageIndex, stages]);

  const formatStage = useCallback(
    (contest: Project.ContestRequest) => {
      const item = stages.find(x => x.id === contest.projectStageId);
      if (!item) return '';
      const result = `Этап №${item.number} (${item.startDate} - ${item.endDate})`;
      return result;
    },
    [stages],
  );

  const copyButtonClick = useCallback(
    (item: Project.ContestRequest) => {
      setSelectedContestRequest(item);
      const stageIndex = stages.findIndex(
        x => x.startDate === item.requestStageStartDate && x.endDate === item.requestStageEndDate,
      );
      if (stageIndex !== -1) {
        setSelectedStageIndex(stageIndex);
        setIsCopyOptionsModalOpen(true);
      } else setIsCopyStagesModalOpen(true);
    },
    [stages],
  );

  const stopCopyProcedure = useCallback(() => {
    setIsCopyOptionsModalOpen(false);
    setIsCopyStagesModalOpen(false);
    setContestRequestCopyOptions({ isStage: false, isIndicators: false, isPerformers: false });
    setSelectedContestRequest(null);
    setSelectedStageIndex(null);
  }, []);

  const selectStage = useCallback((stageIndex: number | null) => {
    if (stageIndex === null) return;
    setSelectedStageIndex(stageIndex);
    setIsCopyStagesModalOpen(false);
    setIsCopyOptionsModalOpen(true);
  }, []);

  const copyFromRequestToStage = useCallback(() => {
    if (
      !contestRequestCopyOptions.isStage &&
      !contestRequestCopyOptions.isIndicators &&
      !contestRequestCopyOptions.isPerformers
    ) {
      showNotification({ message: 'Выберите хотя бы один раздел для копирования', theme: 'danger' });
      return;
    }

    if (selectedStageIndex === null || !selectedStage) {
      showNotification({ message: 'Выберите этап для копирования', theme: 'danger' });
      return;
    }

    if (!selectedContestRequest) {
      showNotification({ message: 'Выберите заявку для копирования', theme: 'danger' });
      return;
    }

    copyFromContestRequest({
      id: selectedContestRequest.id || '',
      stageId: selectedStage.id || '',
      copyOptions: contestRequestCopyOptions,
    });

    setIsCopyOptionsModalOpen(false);
  }, [contestRequestCopyOptions, copyFromContestRequest, selectedContestRequest, selectedStage, selectedStageIndex]);

  return (
    <>
      <ListEdit
        rows={contestRequests}
        onChange={setContestRequests}
        toolbar={[
          {
            icon: { type: 'document', mode: 'arrowRight' },
            title: 'Скопировать из заявки на продление',
            onClick: item => copyButtonClick(item!),
            isDisabled: row => !row || disabled,
          },
        ]}
        columns={[
          {
            label: 'Заявка (Статус)',
            formatValue: x => `ID ${x.id}: ${x.type?.label} (${x.status?.label})`,
            styles: { width: '15%' },
          },
          { label: 'Год заявки', formatValue: x => x.year, styles: { width: '3%' } },
          {
            label: 'Этап проекта',
            formatValue: x => formatStage(x),
            styles: { width: '7%' },
          },
          {
            label: 'Данные выгружены в проект',
            formatValue: x => renderToString(<pre>{[x.dataCopyDetail, x.newDataCopyDetail].filter(Boolean).join('\n')}</pre>),
          },
          { label: 'Примечание', formatValue: x => x.projectNote, styles: { width: '15%' } },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={15}
        maxHeight="none"
        specification={{
          mode: 'customComponent',
          renderComponent: (contest, setContest) => (
            <Fields contest={contest! || getMockContestRequest()} setContest={setContest} />
          ),
        }}
      />

      <Modal
        title="Выберите разделы для копирования данных из заявки в соответствующий этап проекта"
        isOpen={isCopyOptionsModalOpen}
        onClose={stopCopyProcedure}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Скопировать',
            onClick: copyFromRequestToStage,
            isDisabled: selectedStageIndex === null,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: stopCopyProcedure,
            isDisabled: selectedStageIndex === null,
          },
        ]}
        size="small"
      >
        <FormComponent.Text>
          Копирование из заявки ({selectedContestRequest?.requestStageStartDate} - {selectedContestRequest?.requestStageEndDate})
          в этап №{selectedStage?.number} {selectedStage?.name} ({selectedStage?.startDate} - {selectedStage?.endDate})
        </FormComponent.Text>
        <FormComponent.Wrapper>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!contestRequestCopyOptions.isStage}
                onChange={checked => setContestRequestCopyOptions(prevState => ({ ...prevState, isStage: checked }))}
              />
            </FormComponent.Field>
            <FormComponent.Text>
              <strong>Описание этапа</strong> (Данные будут скопированы на форму редактирования этапа на вкладке "Календарный
              план")
            </FormComponent.Text>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!contestRequestCopyOptions.isIndicators}
                onChange={checked => setContestRequestCopyOptions(prevState => ({ ...prevState, isIndicators: checked }))}
              />
            </FormComponent.Field>
            <FormComponent.Text>
              <strong>Индикаторы и показатели</strong> (Плановые значения показателей и индикаторов на этап будут скопированы в
              раздел на вкладке "Результаты")
            </FormComponent.Text>
          </FormComponent.Line>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field>
              <Checkbox
                checked={!!contestRequestCopyOptions.isPerformers}
                onChange={checked => setContestRequestCopyOptions(prevState => ({ ...prevState, isPerformers: checked }))}
              />
            </FormComponent.Field>
            <FormComponent.Text>
              <strong>Список исполнителей</strong> (Обновление списка исполнителей с периодом работы в этапе на вкладке
              "Коллектив")
            </FormComponent.Text>
          </FormComponent.Line>
        </FormComponent.Wrapper>
        <FormComponent.Text>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </FormComponent.Text>
      </Modal>

      <Modal
        title="Этап со сроками идентичными сроку заявки отсутствует. Выберите этап вручную"
        isOpen={isCopyStagesModalOpen}
        onClose={stopCopyProcedure}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Выбрать',
            onClick: () => selectStage(selectedStageIndex),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: stopCopyProcedure,
          },
        ]}
        size="small"
      >
        <ListEditTable
          rows={stages}
          columns={[
            { label: 'Номер', formatValue: row => row?.number || '', styles: { width: '10%' } },
            { label: 'Название', formatValue: row => row?.name || '' },
            { label: 'Период', formatValue: row => `${row?.startDate} - ${row?.endDate}` },
          ]}
          onRowDoubleClick={selectStage}
          selectedRowIndex={selectedStageIndex}
          selectRow={setSelectedStageIndex}
        />
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isNotificationModalOpen}
        onClose={() => setIsNotificationModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsNotificationModalOpen(false),
          },
        ]}
        size="small"
      >
        <>Отредактируйте ожидаемые результаты вручную и удалите ненужные пункты</>
      </Modal>
    </>
  );
};

export const ProlongateRequests = React.memo(Component) as typeof Component;

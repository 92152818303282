import React from 'react';

import { showNotification } from 'features/Notifications';
import { Person, UserPermission } from 'types/models';
import { ListEdit, FormComponent } from 'components';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  disabled: boolean;
  onChangeFIO(fio: Person.BibliographicName[]): void;
};

export function FIO(props: Props) {
  const { person, userPermission, disabled, onChangeFIO } = props;

  return (
    <>
      <FormComponent.Description mode="warning">
        Раздел содержит набор вариантов Фамилии и инициалов на русском и английском языке, используются в отчётах,
        библиографических ссылках публикации, в алгоритмах идентификации авторов публикации. Список формируется автоматически при
        первичном появлении данных о персоне. Если Вы публиковались под другой фамилией, желательно добавить в список варианты,
        используемые в ваших ранних публикациях.
      </FormComponent.Description>

      <ListEdit
        rows={person?.bibliographicNames ?? []}
        onChange={onChangeFIO}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'ФИО', formatValue: row => row.name },
          { label: 'Язык', formatValue: row => row.language?.label || '' },
          { label: 'Использовать по умолчанию', formatValue: row => (row.isDefault ? 'Да' : 'Нет') },
          {
            label: 'Создано',
            formatValue: ({ createdBy, createdDate }) =>
              createdDate ? `${createdBy ?? userPermission?.userName ?? ''} (${createdDate})` : '',
          },
          {
            label: 'Изменено',
            formatValue: ({ modifiedBy, modifiedDate }) =>
              modifiedDate ? `${modifiedBy ?? userPermission?.userName ?? ''} (${modifiedDate})` : '',
          },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        specification={{
          mode: 'customComponent',
          renderComponent: (biblioName, setBiblioName) => <Fields biblioName={biblioName} onChange={setBiblioName} />,
          validation: {
            checkIsValid: biblioName => validate(biblioName).every(x => x.isValid),
            onInvalidate: biblioName => {
              const info = validate(biblioName);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
    </>
  );
}

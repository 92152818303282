import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const QnaAnswerAddTemplate: React.FC<F.TemplateProps & F.QuestionFormAdditionalTypes> = (
  props: F.TemplateProps & F.QuestionFormAdditionalTypes,
) => {
  const { isOpen, onClose, hasNoTableInteraction } = props;
  const look: F.QuestionFormLook = {
    id: '',
    apiID: 'GetQnaQuestion',
    template: 'QnaQuestionForm',
    hasNoTableInteraction,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Задать вопрос администратору" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(QnaAnswerAddTemplate);

import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants';
import { authRegExpList, AuthCheckTypeEnum } from 'utils/Regexps/auth';

const getMessagesByRule = (type: AuthCheckTypeEnum, src: string): string[] => {
  const rules = authRegExpList[type] || [];

  const messages: string[] = rules
    .map(rule => {
      if (rule.match) {
        if (rule.match < 0) {
          let symbols = src;
          src.match(rule.regex)?.forEach(i => {
            symbols = symbols.replace(i, '');
          });
          if (symbols) {
            return `${rule.message} ${symbols}`;
          }
          return '';
        }
        if ((src.match(rule.regex) || []).length < rule.match) {
          return rule.message;
        }
        return '';
      }
      if (rule.regex.test(src)) {
        return '';
      }
      return rule.message;
    })
    .filter(Boolean);

  return messages;
};

const getMessageByRule = (type: AuthCheckTypeEnum, src: string): string | null => getMessagesByRule(type, src)[0] || null;

export function validateRegistration(fields: Record<string, string | null>) {
  const fieldErrors: Record<string, string> = {};

  const {
    surname = null,
    firstname = null,
    // secondname = null,
    login = null,
    password = null,
    passwordRepeat = null,
    birthDate = null,
    email = null,
  } = fields;

  if (surname !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.NAME, <string>surname);
    if (errorMessage) {
      fieldErrors.surname = errorMessage;
    } else {
      delete fieldErrors.surname;
    }
  }

  if (firstname !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.NAME, <string>firstname);
    if (errorMessage) {
      fieldErrors.firstname = errorMessage;
    } else {
      delete fieldErrors.firstname;
    }
  }

  // now is optional
  /*
  if (secondname !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.NAME, <string>secondname);
    if (errorMessage) {
      fieldErrors.secondname = errorMessage;
    } else {
      delete fieldErrors.secondname;
    }
  }
  */

  if (login !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.LOGIN, <string>login);
    if (errorMessage) {
      fieldErrors.login = errorMessage;
    } else {
      delete fieldErrors.login;
    }
  }

  if (password !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.PASSWORD, <string>password);
    if (errorMessage) {
      fieldErrors.password = errorMessage;
    } else {
      if (<string>password === <string>passwordRepeat) {
        delete fieldErrors.passwordRepeat;
      } else {
        fieldErrors.passwordRepeat = 'Пароли не совпадают';
      }
      delete fieldErrors.password;
    }
  }

  const birthYear = birthDate ? parse(birthDate, formatStr, new Date()).getFullYear() : 0;

  if (birthYear === 0) {
    fieldErrors.birthDate = 'Дата рождения не заполнена';
  } else {
    const age = new Date().getFullYear() - birthYear;
    const isBirthDateValid = age > 14 && age < 100;
    if (isBirthDateValid) {
      delete fieldErrors.birthDate;
    } else {
      fieldErrors.birthDate = 'Дата рождения заполнена неверно';
    }
  }

  if (email !== null) {
    const errorMessage = getMessageByRule(AuthCheckTypeEnum.EMAIL, <string>email);
    if (errorMessage) {
      fieldErrors.email = errorMessage;
    } else {
      delete fieldErrors.email;
    }
  }

  return fieldErrors;
}

import { Department, EventType, Nominee } from 'types/models/Payment';
import { convertDocumentToRequest, getMaybeField } from '../commonConverters';
import { SavePaymentRequest } from './types';
import { isEditableNomineeJob } from 'features/IncentivePayments/helpers';

export function convertNomineesToRequest(nominees: Nominee[], type: EventType | string) {
  const getJobId = (nominee: Nominee) => (isEditableNomineeJob(nominee.job) ? nominee.job.id : nominee.job?.originalId ?? '');

  return nominees.map(x => ({
    Nominee: { _attr: { id: x.person.id } },
    Affiliated: String(x.isAffiliated),
    ...(x.topLevelDepartment?.id ? { Department: { _attr: { id: x.topLevelDepartment.id } } } : {}),
    ...(x.job ? { Job: { _attr: { id: getJobId(x) } } } : {}),
    ...(x.isPaid && x.isAffiliated ? { Payment: { _attr: { money: x.factMoney } } } : {}),
    ...(type === 'MEDAL' && x.planMoney ? { PlanMoney: x.planMoney } : {}),
    ...getMaybeField('Note', x.note),
  }));
}

export function convertDepartmentsToRequest(departments: Department[]) {
  return departments.map(x => ({
    _attr: { id: x.topLevelDepartment?.id },
    AmountMoney: x.amountMoney,
  }));
}

export function convertPaymentToRequest({ authorPayment, comment, eventType }: SavePaymentRequest) {
  return {
    _attr: { id: authorPayment.id, isPrechangeStatusSave: 'false', ...(comment ? { saveComment: comment } : {}) },
    Status: authorPayment.status?.value,
    RegulationParagraph: authorPayment.regulationParagraph,
    RegulationName: authorPayment.regulationName,
    MaxNomineesMoney: authorPayment.maxNomineesMoney,
    MaxFundMoney: authorPayment.maxFundMoney,
    Nominees: { Nominee: convertNomineesToRequest(authorPayment.nominees, eventType) },
    ManualDistributionDepartments: { ManualDistributionDepartment: convertDepartmentsToRequest(authorPayment.departments) },
    Year: authorPayment.year,
    Quarter: authorPayment.quarter?.value,
    Month: authorPayment.month,
    ...getMaybeField('DistributionMethodBetweenNominees', authorPayment.distributionMethodBetweenNominees?.value),
    ...getMaybeField('DistributionMethodBetweenFunds', authorPayment.distributionMethodBetweenFunds?.value),
    IsManualDistributionMethodBetweenFunds: !!authorPayment.isManualEdit,
    Documents: {
      Document: authorPayment.documents.filter(x => x.docType === 'local').map(convertDocumentToRequest),
    },
  };
}

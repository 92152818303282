export const getStatusRecordMessage = (name = '', id = '', status = '', state = ''): string => {
  const resultStatus = [!!id ? `ID: ${id}` : '', `Статус записи: ${status || 'Нет'}`, !!state ? `Состояние: ${state}` : '']
    .filter(Boolean)
    .join('. ');

  if (!!name) {
    return [name, !!resultStatus ? `(${resultStatus})` : ''].filter(Boolean).join(' ');
  }

  return resultStatus;
};

export const getStatusMessage = (name = '', id = '', status = ''): string => {
  const resultStatus = [!!id ? `ID: ${id}` : '', status ? `Статус: ${status}` : ''].filter(Boolean).join('. ');

  if (!!name) {
    return [name, !!resultStatus ? `(${resultStatus})` : ''].filter(Boolean).join(' ');
  }

  return resultStatus;
};

import React from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { EventTypes } from 'utils/Enums/EventTypes';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { Table } from 'types/models';
import { useController } from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { FinancialSupport } from 'features/Form/looks/event/EventFormConference/Tabs';
import { ConferenceGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpForm,
    setIsOpenHelpForm,
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    handleTemplateCloseViewForm,
    controllerIsForm,
    isMaterialFormOpen,
    handleTemplateCloseMaterialForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    isFinancingModalOpen,
    setIsFinancingModalOpen,
    buttons,
    settings,
    userPermission,
    isApproved,
    eventData,
    setEventDataField,
    departmentOptions,
    handleAttractedDepartmentsChange,
    departments,
    projects,
    setProjects,
    handleSaveEvent,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={EventTypes.EVENT_CONFERENCE.code}
          />
        </div>
      )}

      {isViewFormOpen && (
        <modalTemplates.ConferenceViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}

      {isMaterialFormOpen && (
        <modalTemplates.ConferenceMaterialTemplate.Component
          isOpen={isMaterialFormOpen}
          onClose={handleTemplateCloseMaterialForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          withHeaderId={false}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.ConferenceEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ConferenceAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          controllerIsForm={controllerIsForm}
          withHeaderId={false}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.EventName}) Удалить мероприятие?</>
        </Modal>
      )}

      {isApprovePopupOpen && (
        <ApproveEvent
          typeCode={EventTypes.EVENT_CONFERENCE.code}
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isApprovePopupOpen}
          onClose={handleCloseApprovePopup}
        />
      )}

      {isCancelMessagePopupOpen && (
        <AdministratorMessagePopup
          title={ADD_PUB_FEEDBACK_LABEL}
          isOpen={isCancelMessagePopupOpen}
          publicationMessage={publicationMessage}
          updatePublicationMessage={updatePublicationMessage}
          publicationMessageType={publicationMessageType}
          setPublicationMessageType={setPublicationMessageType}
          onConfirm={onCancelMessageConfirmClick}
          onConfirmWithoutMessage={onCancelMessageConfirmWithoutMessageClick}
          command="RefEventFeedlineMessage"
        />
      )}

      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            conferenceEventId: tableState.selectedRows[0]?.id || '',
            department: settings?.userDepartment,
            event: { id: tableState.selectedRows[0]?.id || '', label: tableState.selectedRows[0]?.EventName },
          }}
        />
      )}

      <Modal
        title="Редактирование связей конференции с проектами и программами финансирования"
        isOpen={isFinancingModalOpen}
        onClose={() => setIsFinancingModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить',
            onClick: () => handleSaveEvent(() => setIsFinancingModalOpen(false)),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsFinancingModalOpen(false),
          },
        ]}
        size={['extralarge', 'medium-height']}
      >
        <FinancialSupport
          eventData={eventData}
          projects={projects}
          setProjects={setProjects}
          workMode={
            isApproved && !isHasPermission(userPermission, Permits.CONFERENCE_EDIT_APPROVED_MY_FINANCING)
              ? 'viewMode'
              : 'editMode'
          }
          relatedTableState={undefined}
          setEventDataField={setEventDataField}
          departments={departments}
          departmentOptions={departmentOptions}
          handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
          standalone
        />
      </Modal>

      <Modal
        mode="help"
        title="Конференции"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ConferenceGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

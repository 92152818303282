import { convertItem, convertMaybeArray, convertReference, getId, getText } from '../commonConverters';
import {
  ContestRequestKindConsumption,
  KindConsumption,
  MobileKindConsumption,
  NirRequestKindConsumption,
  TenderKindConsumption,
} from 'types/models/KindConsumption';
import { convertTax } from 'services/BackendAPI/configurations/estimates/converters';

export function convertKindConsumption(kc: any): KindConsumption {
  return {
    code: getText(kc.Code),
    id: kc._attributes.id,
    isAccomplicePayment: getText(kc.IsAccomplicePayment) === 'true',
    isActive: getText(kc.IsActive) === 'true',
    isCalculateTax: getText(kc.IsCalculateTax) === 'true',
    isSendOverhead: getText(kc.IsSendOverhead) === 'true',
    kosgu: convertReference(kc.Kosgu),
    kvr: convertReference(kc.Kvr),
    name: getText(kc.Name),
    parent: kc.Parent ? convertKindConsumption(kc.Parent) : undefined,
    percentTax: parseFloat(getText(kc.PercentTax)),
    tax: kc.Tax ? convertTax(kc.Tax) : undefined,
    taxOwner: kc.TaxOwner ? convertKindConsumption(kc.TaxOwner) : undefined,
  };
}
export function convertServerMobileKindConsumption(kindConsumption: any): MobileKindConsumption {
  return {
    id: kindConsumption?._attributes.id || '',
    name: getText(kindConsumption.Name),
    code: getText(kindConsumption.Code),
    description: getText(kindConsumption.Description),
    types: kindConsumption.Types ? convertMaybeArray(kindConsumption.Types.Type || [], ct => convertItem(ct)) : [],
    kindConsumption: kindConsumption.KindConsumption ? convertKindConsumption(kindConsumption.KindConsumption) : null,
  };
}

export function convertNirRequestKindConsumption(nirRequestKindConsumption: any): NirRequestKindConsumption {
  const increments = nirRequestKindConsumption.IncrimentKindConsumptions?.IncrimentKindConsumption || [];
  return {
    id: getId(nirRequestKindConsumption),
    name: getText(nirRequestKindConsumption.Name),
    description: getText(nirRequestKindConsumption.Description),
    incrimentKindConsumptions: increments ? convertMaybeArray(increments, kc => convertNirRequestKindConsumption(kc)) : [],
    incrimenator: nirRequestKindConsumption.Incrimenator
      ? convertNirRequestKindConsumption(nirRequestKindConsumption.Incrimenator)
      : null,
    kindConsumption: nirRequestKindConsumption.KindConsumption
      ? convertKindConsumption(nirRequestKindConsumption.KindConsumption)
      : null,
    isActive: getText(nirRequestKindConsumption.IsActive) === 'true',
    position: getText(nirRequestKindConsumption.Position),
  };
}

export function convertContestRequestKindConsumption(requestKindConsumption: any): ContestRequestKindConsumption {
  const increments = requestKindConsumption.IncrimentKindConsumptions?.IncrimentKindConsumption || [];
  return {
    id: getId(requestKindConsumption),
    name: getText(requestKindConsumption.Name),
    description: getText(requestKindConsumption.Description),
    incrimentKindConsumptions: increments ? convertMaybeArray(increments, kc => convertNirRequestKindConsumption(kc)) : [],
    incrimenator: requestKindConsumption.Incrimenator
      ? convertContestRequestKindConsumption(requestKindConsumption.Incrimenator)
      : null,
    kindConsumption: requestKindConsumption.KindConsumption
      ? convertKindConsumption(requestKindConsumption.KindConsumption)
      : null,
    isActive: getText(requestKindConsumption.IsActive) === 'true',
    position: getText(requestKindConsumption.Position),
  };
}

export function convertTenderKindConsumption(tenderKindConsumption: any): TenderKindConsumption {
  const increments = tenderKindConsumption.IncrimentKindConsumptions?.IncrimentKindConsumption || [];
  return {
    id: getId(tenderKindConsumption),
    name: getText(tenderKindConsumption.Name),
    description: getText(tenderKindConsumption.Description),
    incrimentKindConsumptions: increments ? convertMaybeArray(increments, kc => convertTenderKindConsumption(kc)) : [],
    incrimenator: tenderKindConsumption.Incrimenator ? convertTenderKindConsumption(tenderKindConsumption.Incrimenator) : null,
    kindConsumption: tenderKindConsumption.KindConsumption ? convertKindConsumption(tenderKindConsumption.KindConsumption) : null,
    position: getText(tenderKindConsumption.Position),
  };
}

import { Parameter, RangeValue } from 'types/models';
import { compareDates } from './helpers';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateDateRangeParameters = (dateRangeParameter: Parameter): ValidatorResult => {
  const dateRange = dateRangeParameter.value as RangeValue;
  const messages: string[] = [];

  if (!dateRange?.from) {
    messages.push('Не указана дата начала периода');
  }

  if (!dateRange?.to) {
    messages.push('Не указана дата окончания периода');
  }

  const { isFromMoreTo } = compareDates(dateRange?.from, dateRange?.to);
  if (isFromMoreTo) {
    messages.push(`Дата начала периода не должна быть больше даты конца периода в поле "${dateRangeParameter.description}"`);
  }

  return {
    isValid: !messages.length,
    message: messages.join(',<br>'),
  };
};

export default validateDateRangeParameters;

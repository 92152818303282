import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

export const Toolbar = React.memo(({ tableState }: Props) => {
  const { isViewButtonDisabled, isViewFormOpen, handleTemplateCloseViewForm, buttons } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.RecognitionViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
    </>
  );
});

import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

export function useController() {
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
    ],
    [],
  );

  return {
    buttons,
    isHelpOpen,
    setIsHelpOpen,
  };
}

import React, { useCallback, useMemo } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { block } from 'bem-cn';
import * as BackendAPI from 'services/BackendAPI';

import { Button, ButtonMode, FormComponent, Tab, Tabs } from 'components';

import { Person } from 'types/models';
import {
  CalculationBaseProps,
  CalculationParticipations,
  CalculationProjects,
  CalculationPublications,
  CalculationSecurityDocuments,
} from 'types/models/Person';
import { COLORS, COLORS_BORDERS } from '../сolors';

import '../style.scss';

const b = block('profile-charts');

ChartJS.register(...registerables);

type Props = {
  calculations: Person.Calculation | null;
  reloadPerson?(): void;
};

type Item = {
  name: string;
  data: number[];
};

function ProfileAchievements({ calculations, reloadPerson }: Props) {
  const categories = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const { methods: refreshPerson } = BackendAPI.useBackendAPI('ActualizeCurrentPerson');

  const refreshCalculation = useCallback(() => {
    refreshPerson.callAPI(
      {},
      {
        onSuccessfullCall: () => {
          reloadPerson?.();
        },
      },
    );
  }, [refreshPerson, reloadPerson]);

  const publicationSeries = useMemo<Item[]>(() => {
    const keys: number[] = [5, 4, 3, 2, 1, 0];
    return [
      {
        name: 'Scopus, WoS',
        data: keys.map(i => Number(calculations?.publications[`scopusWosByYear${i}` as keyof CalculationPublications]) || 0),
      },
      {
        name: 'Q1, Q2',
        data: keys.map(i => calculations?.publications[`q1q2ScopusWosByYear${i}` as keyof CalculationPublications] || 0),
      },
      {
        name: 'Монографии',
        data: keys.map(i => calculations?.publications[`monographsByYear${i}` as keyof CalculationPublications] || 0),
      },
      {
        name: 'Все публикации',
        data: keys.map(i => calculations?.publications[`allByYear${i}` as keyof CalculationPublications] || 0),
      },
    ];
  }, [calculations?.publications]);

  const eventSeries = useMemo(() => {
    const keys: number[] = [5, 4, 3, 2, 1, 0];
    return [
      {
        name: 'Кол-во докладов',
        data: keys.map(i => calculations?.participations[`reporterByYear${i}` as keyof CalculationParticipations] || 0),
      },
      {
        name: 'Всего мероприятий',
        data: keys.map(i => calculations?.event[`byYear${i}` as keyof CalculationBaseProps] || 0),
      },
    ];
  }, [calculations?.participations, calculations?.event]);

  const projectSeries = useMemo(() => {
    const keys: number[] = [5, 4, 3, 2, 1, 0];
    return [
      {
        name: 'Роль: руководитель проекта',
        data: keys.map(i => calculations?.projects[`leaderByYear${i}` as keyof CalculationProjects] || 0),
      },
      {
        name: 'Роль: участник проекта',
        data: keys.map(i => calculations?.projects[`notLeaderByYear${i}` as keyof CalculationProjects] || 0),
      },
    ];
  }, [calculations?.projects]);

  const ridSeries = useMemo(() => {
    const keys: number[] = [5, 4, 3, 2, 1, 0];
    return [
      {
        name: 'Патенты',
        data: keys.map(i => calculations?.securityDocuments[`patentsByYear${i}` as keyof CalculationSecurityDocuments] || 0),
      },
      {
        name: 'Свидетельство на ПО',
        data: keys.map(i => calculations?.securityDocuments[`evidencesByYear${i}` as keyof CalculationSecurityDocuments] || 0),
      },
      {
        name: 'Ноу-хау',
        data: keys.map(i => calculations?.securityDocuments[`knowHowsByYear${i}` as keyof CalculationSecurityDocuments] || 0),
      },
      {
        name: 'Заявка на патент',
        data: keys.map(
          i => calculations?.securityDocuments[`patentRequestsByYear${i}` as keyof CalculationSecurityDocuments] || 0,
        ),
      },
      {
        name: 'Заявка на свидетельство ПО',
        data: keys.map(
          i => calculations?.securityDocuments[`evidenceRequestsByYear${i}` as keyof CalculationSecurityDocuments] || 0,
        ),
      },
    ];
  }, [calculations?.securityDocuments]);

  const Chart = ({ chartData, axisName }: { chartData: Item[]; axisName: string; isStack?: boolean }) => {
    const renderTooltip = (context: any) => {
      const { datasetIndex: index, raw: value } = context;
      return ` ${chartData[index].name}: ${value} шт.`;
    };

    const data = {
      labels: categories,
      datasets: chartData.map((x, index) => ({
        label: x.name,
        data: x.data,
        backgroundColor: COLORS[index],
        borderColor: COLORS_BORDERS[index],
        borderWidth: 2,
      })),
    };

    return (
      <Bar
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: axisName,
              },
            },
            y: {
              beginAtZero: true,
              min: 0,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              position: 'right',
              maxWidth: 250,
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
      />
    );
  };

  return (
    <Tabs style={{ height: 'auto' }}>
      <Tab title="Публикации">
        {reloadPerson && (
          <FormComponent.Description mode="info">
            <strong>В штатном режиме данные обновляются ежедневно в 03:00 a.m.</strong>{' '}
            <Button mode={ButtonMode.SECONDARY} text="Обновить сейчас" onClick={refreshCalculation} />
          </FormComponent.Description>
        )}
        <FormComponent.Line>
          <h3>Количество публикаций</h3>
        </FormComponent.Line>
        <div className={b('chart')}>{Chart({ chartData: publicationSeries, axisName: 'Год издания публикации' })}</div>
      </Tab>
      <Tab title="Участие в конференциях">
        <FormComponent.Line>
          <h3>Участие в научных мероприятиях</h3>
        </FormComponent.Line>
        <div className={b('chart')}>{Chart({ chartData: eventSeries, axisName: 'Год' })}</div>
      </Tab>
      <Tab title="НИОКР">
        <FormComponent.Line>
          <h3>Выполненные НИОКР (период работы в проекте)</h3>
        </FormComponent.Line>
        <div className={b('chart')}>{Chart({ chartData: projectSeries, axisName: 'Год' })}</div>
      </Tab>
      <Tab title="ОИС">
        <FormComponent.Line>
          <h3>Объекты интеллектуальной собственности</h3>
        </FormComponent.Line>
        <div className={b('chart')}>{Chart({ chartData: ridSeries, axisName: 'Год публикации/подачи заявки' })}</div>
      </Tab>
    </Tabs>
  );
}

export default ProfileAchievements;

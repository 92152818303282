import { Form } from 'types/models';

export const getTitle = (publicationInfo: Form.Publication | null, isShowDetailedTitle?: boolean) => {
  const title = [
    `${publicationInfo?.status.typeLabel || 'Сведения'}`,
    !!isShowDetailedTitle && publicationInfo?.status.id ? `(ID ${publicationInfo.status.id})` : '',
  ]
    .filter(Boolean)
    .join(' ');

  return `${title}:`;
};

const MONTH_NAMES = [
  'январь',
  'февраль',
  'март',
  'апрель',
  'май',
  'июнь',
  'июль',
  'август',
  'сентябрь',
  'октябрь',
  'ноябрь',
  'декабрь',
];

export const formatPaymentRequestData = (i: Form.PaymentRequest) =>
  `Заявка за  ${i.year}г, ${
    !i.quarter ? `кв. ${i.quarter} ` : i.month ? `${MONTH_NAMES[parseInt(i.month, 10) - 1]}` : ''
  } - ${i.status?.toLowerCase()} (пункт ${i.paragraph} "${i.name}")`;

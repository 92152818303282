import React from 'react';

import { TextArea, TextInput, TextInputMode, FormComponent } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';
import { isNumberValid } from 'utils/Validators';

type Props = {
  stage: NirRequest.Stage;
  setStage(stage: NirRequest.Stage): void;
};

export function Fields({ stage, setStage }: Props) {
  const makeChangeHandler = (key: keyof NirRequest.Stage) => (value: ValueOf<NirRequest.Stage>) => {
    setStage({ ...stage, [key]: value });
  };

  const handleNumberChange = (key: keyof NirRequest.Stage) => (value: string) => {
    if (isNumberValid(value)) {
      setStage({ ...stage, [key]: value });
    }
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Название этапа" isRequired>
          <TextArea settings={{ isFixed: true }} value={stage.name} onChange={makeChangeHandler('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Год" isRequired>
          <TextInput mode={TextInputMode.YEAR} value={stage.year} onChange={handleNumberChange('year')} />
        </FormComponent.Field>
        <FormComponent.Field label="Сумма финансирования" isRequired>
          <TextInput mode={TextInputMode.NUMBER} value={stage.amount} onChange={makeChangeHandler('amount')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import React, { useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

const EmptyToolbar = () => {
  const buttons = useMemo<ButtonProps[]>(() => [], []);

  return <SharedToolbar buttons={buttons} />;
};

export { EmptyToolbar };

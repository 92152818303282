import { Publication } from 'types/models';

export function validate(publication: Publication | null) {
  if (!publication) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidBiblioRecord = Boolean(publication.bibliographicRecord);
  const isValidLengthBiblioRecord = publication.bibliographicRecord ? publication.bibliographicRecord.length <= 1024 : true;

  return [
    { isValid: isValidBiblioRecord, invalidMessage: 'Введите библиографическую надпись' },
    { isValid: isValidLengthBiblioRecord, invalidMessage: 'Превышен лимит знаков в 1024 символа' },
  ];
}

import { useCallback, useLayoutEffect, useState } from 'react';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Project } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';

import { ValueOf } from 'types/helpers';
// eslint-disable-next-line max-len
import { useSecurityDocumentFormController } from 'features/Form/looks/securityDocument/SecurityDocumentForm/securityDocumentFormController';
import { Department, RefElement } from 'types/models/Project';
import { getMockSecurityDocument } from './helpers';
import { validate } from './validate';
import { formatStr } from 'utils/Constants';
import { SecurityDocumentType } from 'utils/Enums';
import { Form as F } from 'types/models';
import { getMockRefElement } from '../../project/ProjectForm/helpers';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, documentType },
  } = useFormContext<F.SecurityDocumentFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState<boolean>(false);

  const [securityDocument, setSecurityDocument] = useState<SecurityDocument>(getMockSecurityDocument(documentType || ''));
  const [documents, setDocuments] = useState<Document[]>([]);

  const { securityDocumentFormFields, setSecurityDocumentFormFields } = useSecurityDocumentFormController({
    securityDocument,
    setSecurityDocument,
  });

  const { methods: getSecurityDocument } = BackendAPI.useBackendAPI('GetSecurityDocument');
  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const loadSecurityDocument = useCallback(
    (securityDocumentId: string) => {
      getSecurityDocument.callAPI(
        { id: securityDocumentId },
        {
          onSuccessfullCall: ({ data }) => {
            setSecurityDocument(data);
            // copied to SecurityDocumentPaymentRequestForm
            setSecurityDocumentFormFields(prev => ({
              ...prev,
              requestNumber: { ...prev.requestNumber, value: data.requestNumber },
              requestDate: { ...prev.requestDate, value: data.requestDate },
              requestSendDate: { ...prev.requestSendDate, value: data.requestSendDate },
              documentDate: { ...prev.documentDate, value: data.documentDate },
              documentReceiveDate: { ...prev.documentReceiveDate, value: data.documentReceiveDate },
              isRus: { ...prev.isRus, value: data.isRus },
              isWorldwide: { ...prev.isWorldwide, value: data.isWorldwide },
              balanceOrderNumber: { ...prev.balanceOrderNumber, value: data.balanceOrderNumber },
              loDate: { ...prev.loDate, value: data.loDate },
              hiDate: { ...prev.hiDate, value: data.hiDate },
              balancePrice: { ...prev.balancePrice, value: data.balancePrice },
              intellectualPropertyName: { ...prev.intellectualPropertyName, value: data.intellectualPropertyName },
              referat: { ...prev.referat, value: data.referat },
              documentNumber: { ...prev.documentNumber, value: data.documentNumber },
              internalNumber: { ...prev.internalNumber, value: data.internalNumber },
              stateNumber: { ...prev.stateNumber, value: data.stateNumber },
              stateRegistrationDate: { ...prev.stateRegistrationDate, value: data.stateRegistrationDate },
            }));
            setDocuments(data.documents);
            if (securityDocument?.type?.value === SecurityDocumentType.NON_REGISTERED) {
              setSecurityDocumentFormFields(prev => ({ ...prev, documentDate: { ...prev.documentDate, disabled: false } }));
            }
          },
        },
      );
    },
    [getSecurityDocument, securityDocument, setSecurityDocumentFormFields],
  );

  const handleChangeProject = useCallback(
    (projectId?: string) => {
      const mergeRefElements = (securityDocumentArr: RefElement[], projectArr: RefElement[]) => {
        const oldArr: RefElement[] = securityDocumentArr
          .filter(sd => !sd.isProject || !!sd.isActual)
          .map(sd => ({ ...sd, ...(!projectArr.some(x => x.element?.id === sd.element?.id) ? { isProject: false } : {}) }));
        const newArr: RefElement[] = projectArr
          .filter(sd => !oldArr.some(x => x.element?.id === sd.element?.id))
          .map(sd => ({ ...sd, isActual: true, isProject: true, id: null }));
        return [...oldArr, ...newArr];
      };

      const getNewClassifiers = (newProject?: Project.Project) => ({
        oecds: mergeRefElements(securityDocument.oecds, newProject?.oecds || []),
        ntiEteTechnologies: mergeRefElements(securityDocument.ntiEteTechnologies, newProject?.ntiEteTechnologies || []),
        grntis: mergeRefElements(securityDocument.grntis, newProject?.grntis || []),
        udks: mergeRefElements(securityDocument.udks, newProject?.udks || []),
        okveds: mergeRefElements(
          securityDocument.okveds,
          newProject?.okved ? [{ ...getMockRefElement(), element: newProject?.okved }] : [],
        ),
        lksetss: mergeRefElements(securityDocument.lksetss, newProject?.lksetss || []),
        scienceBrunches: mergeRefElements(securityDocument.scienceBrunches, newProject?.scienceBrunches || []),
        scienceDomainInterrests: mergeRefElements(
          securityDocument.scienceDomainInterrests,
          newProject?.scienceDomainInterrests || [],
        ),
        vakSpecialities: mergeRefElements(securityDocument.vakSpecialities, newProject?.vaks || []),
        ntiMarkets: mergeRefElements(securityDocument.ntiMarkets, newProject?.ntiMarkets || []),
        pnrs: mergeRefElements(securityDocument.pnrs, newProject?.pnrs || []),
        pnmitrs: mergeRefElements(securityDocument.pnmitrs, newProject?.pnmitrs || []),
        pnis: mergeRefElements(securityDocument.pnis, newProject?.pnis || []),
        ntrStrategies: mergeRefElements(securityDocument.ntrStrategies, newProject?.ntrStrategies || []),
        technologyPlatforms: mergeRefElements(securityDocument.technologyPlatforms, newProject?.techPlatforms || []),
        priorityTechnologies: mergeRefElements(securityDocument.priorityTechnologies, newProject?.criticalTechnologies || []),
        knowledgeBranches: mergeRefElements(securityDocument.knowledgeBranches, newProject?.knowledgeBranches || []),
      });

      if (!projectId) {
        setSecurityDocument(prev => ({ ...prev, project: null, ...getNewClassifiers() }));
      } else {
        getProjectAPI.callAPI(
          { id: projectId },
          {
            onSuccessfullCall: ({ data }) => {
              setSecurityDocument?.(prev => {
                const newDepartments = data.departments
                  .filter(newDepartment => !!newDepartment.unit)
                  .map<Department>(newDepartment => ({
                    ...newDepartment,
                    id: null,
                  }))
                  .filter(
                    newDepartment =>
                      !prev.departments.find(
                        oldDepartment =>
                          oldDepartment.unit?.id === newDepartment.unit?.id &&
                          oldDepartment.governance?.id === newDepartment.governance?.id,
                      ),
                  );

                return {
                  ...prev,
                  project: data,
                  ...getNewClassifiers(data),
                  departments: [
                    ...prev.departments,
                    ...newDepartments.filter(
                      nd => !prev.departments.some(od => nd.unit?.id === od.unit?.id && nd.governance?.id === od.governance?.id),
                    ),
                  ],
                };
              });
            },
          },
        );
      }
    },
    [
      getProjectAPI,
      securityDocument.grntis,
      securityDocument.knowledgeBranches,
      securityDocument.lksetss,
      securityDocument.ntiEteTechnologies,
      securityDocument.ntiMarkets,
      securityDocument.ntrStrategies,
      securityDocument.oecds,
      securityDocument.okveds,
      securityDocument.pnis,
      securityDocument.pnmitrs,
      securityDocument.pnrs,
      securityDocument.priorityTechnologies,
      securityDocument.scienceBrunches,
      securityDocument.scienceDomainInterrests,
      securityDocument.technologyPlatforms,
      securityDocument.udks,
      securityDocument.vakSpecialities,
    ],
  );

  const { methods: saveSecurityDocument } = BackendAPI.useBackendAPI('SaveSecurityDocument');

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const documentSaveMsgs: Record<string, string> = {
        PATENT: 'Патент успешно сохранен',
        EVIDENCE: 'Свидетельство успешно сохранено',
        KNOW_HOW: 'Ноу-хау успешно сохранено',
        NON_REGISTERED: 'Объект авторского права успешно сохранен',
      };

      const errors = validate(securityDocument, securityDocumentFormFields);

      if (errors && errors.length > 0) {
        showNotification({ message: `Заполните пол${errors.length > 1 ? 'я' : 'е'} ${errors.join(', ')}`, theme: 'danger' });
        return false;
      }

      let documentStartDate = securityDocument.documentStartDate || '';

      if (Date.parse(securityDocument.documentEndDate) < Date.parse(documentStartDate)) {
        showNotification({ message: 'Дата окончания должна быть позже даты начала', theme: 'danger' });
        return false;
      }

      if (securityDocument?.type?.value === 'NON_REGISTERED') {
        if (securityDocumentFormFields.documentDate.value && !documentStartDate) {
          documentStartDate = securityDocumentFormFields.documentDate.value;
          setSecurityDocument(prev => ({ ...prev, documentStartDate }));
        }

        if (
          parse(securityDocumentFormFields.documentDate.value || '', formatStr, new Date()).getTime() <
          parse(documentStartDate, formatStr, new Date()).getTime()
        ) {
          showNotification({
            message: 'Дата предоставления в распоряжение организации должна быть больше или равна дате начала',
            theme: 'danger',
          });
          return false;
        }

        if (!securityDocumentFormFields.requestSendDate.value) {
          showNotification({
            message: 'Номер документа - обязательный атрибут для оформления заявки на стимулирующие выплаты',
            theme: 'warning',
          });
        }
      }

      if (
        parse(securityDocumentFormFields.documentReceiveDate.value || '', formatStr, new Date()).getTime() <
        parse(documentStartDate, formatStr, new Date()).getTime()
      ) {
        showNotification({
          message: 'Дата получения охранного документа не должна быть меньше Даты публикации документа',
          theme: 'danger',
        });
        return false;
      }

      if (securityDocument?.type?.value !== 'NON_REGISTERED') {
        if (!securityDocument?.departments?.length) {
          showNotification({
            message: 'Укажите подразделения к которым относится объект интеллектуальной собственности',
            theme: 'danger',
          });
          return false;
        }
      }

      saveSecurityDocument.callAPI(
        {
          ...securityDocument,
          documentStartDate,
          hiDate: securityDocumentFormFields.hiDate.value,
          loDate: securityDocumentFormFields.loDate.value,
          balanceOrderNumber: securityDocumentFormFields.balanceOrderNumber.value,
          balancePrice: securityDocumentFormFields.balancePrice.value,
          documentNumber: securityDocumentFormFields.documentNumber.value,
          internalNumber: securityDocumentFormFields.internalNumber.value,
          isRus: securityDocumentFormFields.isRus.value,
          isWorldwide: securityDocumentFormFields.isWorldwide.value,
          contracts: securityDocument?.contracts || [],
          knowledgeBranches: securityDocument?.knowledgeBranches || [],
          grntis: securityDocument?.grntis || [],
          intellectualPropertyName: securityDocumentFormFields.intellectualPropertyName.value,
          referat: securityDocumentFormFields.referat.value,
          lksetss: securityDocument?.lksetss || [],
          members: securityDocument?.members || [],
          countries: securityDocument?.countries || [],
          ntiEteTechnologies: securityDocument?.ntiEteTechnologies || [],
          ntiMarkets: securityDocument?.ntiMarkets || [],
          ntrStrategies: securityDocument?.ntrStrategies || [],
          oecds: securityDocument?.oecds || [],
          okveds: securityDocument?.okveds || [],
          payments: securityDocument?.payments || [],
          pnis: securityDocument?.pnis || [],
          pnmitrs: securityDocument?.pnmitrs || [],
          pnrs: securityDocument?.pnrs || [],
          priorityTechnologies: securityDocument?.priorityTechnologies || [],
          requestDate: securityDocumentFormFields.requestDate.value,
          requestSendDate: securityDocumentFormFields.requestSendDate.value,
          documentDate: securityDocumentFormFields.documentDate.value,
          documentReceiveDate: securityDocumentFormFields.documentReceiveDate.value,
          requestNumber: securityDocumentFormFields.requestNumber.value,
          stateRegistrationDate: securityDocumentFormFields.stateRegistrationDate.value,
          stateNumber: securityDocumentFormFields.stateNumber.value,
          scienceBrunches: securityDocument?.scienceBrunches || [],
          scienceDomainInterrests: securityDocument?.scienceDomainInterrests || [],
          vakSpecialities: securityDocument?.vakSpecialities || [],
          technologyPlatforms: securityDocument?.technologyPlatforms || [],
          type: documentType ? { value: documentType || '', label: '' } : securityDocument?.type || null,
          udks: securityDocument?.udks || [],
          id: workMode === 'editMode' ? securityDocument?.id || null : null,
          documents,
          departments: securityDocument.departments,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: documentSaveMsgs[securityDocument?.type?.value ?? 'PATENT'], theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              loadSecurityDocument(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      securityDocument,
      securityDocumentFormFields,
      saveSecurityDocument,
      documentType,
      workMode,
      documents,
      tableStreams.reloadTable,
      onClose,
      loadSecurityDocument,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const makeChangeHandler = (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => {
    setSecurityDocument((prev: SecurityDocument | null | undefined) => ({ ...prev!, [key]: val }));
  };

  const handleClassifierChange = (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => {
    setSecurityDocument((prev: SecurityDocument) => {
      const prevElements = prev && prev[key] ? (prev[key] as RefElement[]) : [];
      const elements = val as RefElement[];

      if (prevElements.length > elements.length) {
        let deleted: RefElement | null = null;
        prevElements.forEach(el => {
          const hash = JSON.stringify(el);
          let found = false;
          elements.forEach(newEl => {
            const newHash = JSON.stringify(newEl);
            if (hash === newHash) {
              found = true;
            }
          });
          if (!found) {
            deleted = el;
            found = false;
          }
        });
        if (deleted && (deleted as RefElement).isProject) {
          showNotification({ message: 'Удаление возможно только элементов, отсутствующих в проекте', theme: 'danger' });
          return prev;
        }
      }

      return { ...prev!, [key]: val };
    });
  };

  useLayoutEffect(() => {
    if (id) {
      loadSecurityDocument(id);
    }
    if (securityDocument?.type?.value === SecurityDocumentType.NON_REGISTERED) {
      setSecurityDocumentFormFields(prev => ({ ...prev, documentDate: { ...prev.documentDate, disabled: false } }));
    }
    // eslint-disable-next-line
  }, []);

  const handleFormSubmit = useCallback(
    (needClose: boolean) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  return {
    securityDocument,
    workMode,
    formFields: securityDocumentFormFields,
    handleFormSubmit,
    setSecurityDocument,
    makeChangeHandler,
    handleClassifierChange,
    documents,
    setDocuments,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    handleChangeProject,
  };
}

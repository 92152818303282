import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { showNotification } from 'features/Notifications';
import { RequestFeedlineType } from 'utils/Enums';

export function useController(tableState: Table.State, requestId: string) {
  const tableStreams = useLocalTableStreams();
  const selectedRow = tableState.selectedRows[0] || null;

  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [message, setMessage] = useState<string>('');
  const [editId, setEditId] = useState<string>('');

  const { methods: getRequestFeedline } = BackendAPI.useBackendAPI('GetRequestFeedline');
  const { methods: saveRequestFeedline } = BackendAPI.useBackendAPI('SaveRequestFeedline');

  const handleConfirmDeleteModal = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'RequestFeedline',
    });
    setIsDeleteModalOpen(false);
  }, [tableState, tableStreams.deleteRow]);

  const openAddForm = useCallback(() => {
    setEditId('');
    setMessage('');
    setIsModalOpen(true);
  }, []);

  const openEditForm = useCallback(() => {
    if (!selectedRow?.id) return;
    if (!selectedRow?.IsCreator) {
      showNotification({ message: 'Вы можете редактировать только свои сообщения', theme: 'danger' });
      return;
    }
    getRequestFeedline.callAPI(
      { id: selectedRow?.id },
      {
        onSuccessfullCall: ({ data }) => {
          setEditId(selectedRow?.id);
          setMessage(data.message);
          setIsModalOpen(true);
        },
      },
    );
  }, [getRequestFeedline, selectedRow?.IsCreator, selectedRow?.id]);

  const confirmModal = useCallback(() => {
    saveRequestFeedline.callAPI(
      { requestId, feedlineId: editId, message },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push({});
          showNotification({ message: editId ? 'Сообщение отредактировано' : 'Сообщение добавлено в журнал', theme: 'success' });
          setEditId('');
          setMessage('');
          setIsModalOpen(false);
        },
      },
    );
  }, [editId, message, requestId, saveRequestFeedline, tableStreams.reloadTable]);

  const deleteButtonClick = useCallback(() => {
    if (!selectedRow?.IsCreator || selectedRow?.['id:Type'] !== RequestFeedlineType.USER_MESSAGE) {
      showNotification({ message: 'Вы можете редактировать только свои сообщения, созданные вручную', theme: 'danger' });
      return;
    }
    setIsDeleteModalOpen(true);
  }, [selectedRow]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: openAddForm,
        permission: { name: Permits.PROGRAM_EVENT_ADD },
        isDisabled: requestId === '-1',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: openEditForm,
        permission: { name: Permits.PROGRAM_EVENT_EDIT },
        isDisabled: tableState.selectedRows.length !== 1 || requestId === '-1',
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: deleteButtonClick,
        permission: { name: Permits.PROGRAM_EVENT_DELETE },
        isDisabled: tableState.selectedRows.length !== 1 || requestId === '-1',
      },
    ],
    [deleteButtonClick, openAddForm, openEditForm, requestId, tableState.selectedRows.length],
  );

  return {
    buttons,
    handleConfirmDeleteModal,
    confirmModal,
    isModalOpen,
    setIsModalOpen,
    isDeleteModalOpen,
    setIsDeleteModalOpen,
    message,
    setMessage,
    editId,
  };
}

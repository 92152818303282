import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { NirRequestStatus } from 'utils/Enums';
import { getNirRequestStatusButtons } from '../helpers';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { NirRequestLocalType } from '.';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;
  const status = (selectedRow?.['id:Status'] as NirRequestStatus) || null;

  const [nirRequest, setNirRequest] = useState<NirRequestLocalType | null>(null);
  const [isConfirmDeleteModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isMessageModalOpen, setIsMessageModalOpen] = useState(false);
  const [isTenderModalOpen, setIsTenderModalOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);

  const { methods: deleteRowAPI } = BackendAPI.useBackendAPI('DeleteModel');
  const { methods: sendMessageAPI } = BackendAPI.useBackendAPI('PushNirRequestMessage');

  const reports = useMemo<Report[]>(
    () => [
      Reports.TotalNirRequestsIndicators,
      Reports.NirRequestAll,
      Reports.NirRequestProjectTechnicalTask,
      Reports.NirRequestMemorandum,
      Reports.NirRequestProjectEstimate,
    ],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const deleteNir = useCallback(
    (rowId: string) => {
      deleteRowAPI.callAPI(
        { commandName: 'DeleteNirRequest', entityName: 'NirRequest', rowId },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [deleteRowAPI, tableStreams.reloadTable],
  );

  const sendMessage = useCallback(
    (message: string) => {
      if (!selectedRow) {
        return;
      }
      sendMessageAPI.callAPI(
        { nirRequestId: selectedRow?.id, message },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сообщение успешно добавлено', theme: 'success' });
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [selectedRow, sendMessageAPI, tableStreams.reloadTable],
  );

  const handleTenderSelect = useCallback((row: Table.Entry) => {
    if (!row['id:NirTenderType']) {
      showNotification({ message: 'У выбранного конкурса отсутствует тип заявки', theme: 'danger' });
      return;
    }
    setNirRequest({
      specification: 'BASIC_RESEARCH',
      tenderId: row.id,
      lotId: row.lotId,
    });
    setIsTenderModalOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const handleCloseMessageModal = useCallback(() => {
    setIsMessageModalOpen(false);
  }, []);

  const submitsMap: Record<string, (message: string) => void> = {
    message: sendMessage,
  };

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getNirRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({ id: x.id, status: (x?.['id:Status'] as NirRequestStatus) || null })),
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setNirRequest(null);
          setIsTenderModalOpen(true);
        },
        permission: { name: 'NirRequest:Add' },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsConfirmModalOpen(true);
        },
        permission: { name: 'NirRequest:Delete:DRAFT' },
        isDisabled: tableState.selectedRows.length !== 1 || status !== 'DRAFT',
      },
      ...statusChangeButtons,
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение в журнал',
        onClick: () => setIsMessageModalOpen(true),
        permission: { name: 'NirRequest:PushFeedlineMessage' },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [tableState.selectedRows.length, status, statusChangeButtons, getReports, handleSetCurrentReport],
  );

  return {
    isMessageModalOpen,
    handleCloseMessageModal,
    submitsMap,
    buttons,
    isConfirmDeleteModalOpen,
    setIsConfirmModalOpen,
    deleteNir,
    selectedRow,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    sendMessage,
    nirRequest,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
};

export default useController;

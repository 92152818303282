import { Icon, Tab, Tabs } from 'components';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectPerformerOrderGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">{ProjectHelp().executorsOrders}</Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <p>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</p>

        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с приказом. Открывает форму с данными о приказе для просмотра.
                Состав формы зависит от наличия у пользователя <strong>прав на просмотр</strong> вкладок формы.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму добавления приказа о научном коллективе</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с приказом. Открывает форму приказа для редактирования.</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                <p>Удалить приказ о научном коллективе.</p>
                <p>Кнопка активна, если в таблице выделена запись с приказом.</p>
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                <p>Служит для формирования отчётов по приказу.</p>
                <p>
                  На форме Параметры отчёта кнопка <strong>Описание отчёта</strong> открывает файл, с описанием правил, по которым
                  формируется отчёт.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

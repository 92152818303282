import { Table } from 'types/models';
import { Department } from 'types/models/Participation';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ParticipationType } from 'types/models/Participation';
import { Departments } from 'features/Table/views';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  workMode: Table.WorkMode;
  departments: Department[];
  status: string;
  participationId?: string;
  participationTypeCode: string;
};

export const ParticipationHeader = ({
  workMode,
  departments,
  status,
  participationId = '',
  participationTypeCode,
}: Props): string | JSX.Element => {
  const participationType = ((): ParticipationType | null => {
    const participationTypesArray = Object.entries(ParticipationTypes);

    const currentParticipationTypeEntire = participationTypesArray.find(([, { code }]) => code === participationTypeCode);
    if (currentParticipationTypeEntire) {
      const currentParticipationType: ParticipationType = currentParticipationTypeEntire[1];
      return currentParticipationType;
    }

    return null;
  })();

  const hint = (() => {
    // eslint-disable-next-line max-len
    return 'Добавить связь с подразделением с помощью кнопки "Редактировать связь с подразделением". При сохранении участия автоматически привязывается к подразделению пользователя, добавляющего запись.';
  })();

  const title = (() => {
    const action = (() => {
      if (workMode === 'addMode') {
        return 'Добавление';
      }
      if (workMode === 'viewMode') {
        return 'Просмотр';
      }
      return 'Редактирование';
    })();

    return [
      action,
      getStatusRecordMessage(
        participationType?.label ? `"${participationType?.label}"` : '',
        participationId,
        workMode === 'addMode' ? '' : status,
      ),
    ]
      .filter(Boolean)
      .join(' ');
  })();

  if (departments) {
    return (
      <>
        <h3>{title}</h3>
        <h4>
          <Departments hint={hint} departments={departments.map(department => department.name).join(', ')} />
        </h4>
      </>
    );
  }

  return title;
};

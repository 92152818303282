import React from 'react';

import { Project } from 'types/models';
import { Partners as PartnerFields } from './Partners';

export type Props = {
  partners: Project.Partner[];
  setPartners(partners: Project.Partner[]): void;
  withMessages?: boolean;
  disabled: boolean;
};

export function Partners(props: Props) {
  const { partners, setPartners, withMessages, disabled } = props;

  return <PartnerFields partners={partners} setPartners={setPartners} withMessages={withMessages} disabled={disabled} />;
}

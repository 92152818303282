import { ProjectType } from 'utils/Enums';
import { Permits } from 'utils/Permissions';

export function getProjectPermits(type: ProjectType) {
  const view: Record<ProjectType, string> = {
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_VIEW,
    GRANT: Permits.GRANT_VIEW,
    NIR: Permits.RESEARCH_JOB_VIEW,
  };

  const add: Record<ProjectType, string> = {
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_ADD,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_ADD,
    GRANT: Permits.GRANT_ADD,
    NIR: Permits.RESEARCH_JOB_ADD,
  };

  const edit: Record<ProjectType, string> = {
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_EDIT,
    GRANT: Permits.GRANT_EDIT,
    NIR: Permits.RESEARCH_JOB_EDIT,
  };

  const delet: Record<ProjectType, string> = {
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_DELETE,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_DELETE,
    GRANT: Permits.GRANT_DELETE,
    NIR: Permits.RESEARCH_JOB_DELETE,
  };

  return {
    view: view[type],
    add: add[type],
    edit: edit[type],
    delete: delet[type],
  };
}

export function CharterCapitalsHelp(): JSX.Element {
  return (
    <>
      <p>
        В таблице необходимо вводить полную информацию о всех изменениях в уставном капитале: сумма, учредители, сведения о
        внесённых в состав активах. Для выбранной строки в таблице <strong>Уставной капитал</strong>. В таблице{' '}
        <strong>Учредители</strong> отображается информация о каждом учредителе, его доле.
      </p>
      <p>Вкладка доступна для пользователей с правом на Редактирование МИП</p>
    </>
  );
}

import React, { useCallback, useState } from 'react';

import { ButtonMode, Modal, ReferenceItem } from 'components';

import { BiblioFields } from 'features/Table/specifications/GetScientistBibliographicNameList/Editor/BiblioFields';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmitModal(fio: string, language: ReferenceItem | null): void;
};

function BiblioFieldsModal(props: Props) {
  const { isOpen, onClose, onSubmitModal } = props;

  const [fio, setFIO] = useState<string>('');
  const [language, setLanguage] = useState<ReferenceItem | null>(null);

  const onConfirm = useCallback(() => {
    onSubmitModal(fio, language);
    onClose();
  }, [fio, language, onSubmitModal, onClose]);

  return (
    <Modal
      title="Добавление ФИО для библиографической записи"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Сохранить',
          onClick: onConfirm,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отменить',
          onClick: onClose,
        },
      ]}
      size="small"
    >
      <BiblioFields fio={fio} language={language} setFIO={setFIO} setLanguage={setLanguage} />
    </Modal>
  );
}

export { BiblioFieldsModal };

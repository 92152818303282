import React from 'react';

import { Modal } from 'components';

import { DataGrid } from 'features/Table';
import { GetRoleList } from 'features/Table/specifications/GetRoleList';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  isOpen: boolean;
  onSubmitTable: (gridState: SubmitTable) => void;
  onClose(): void;
};

const RoleFormTemplate: React.FC<Props> = ({ isOpen, onClose, onSubmitTable }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Выберите роль из списка" size={['large', 'large-height']}>
      <DataGrid specification={{ ...GetRoleList({ hasSelectButton: true }), onSubmitTable }} />
    </Modal>
  );
};

export { RoleFormTemplate };

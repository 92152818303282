import { Mode } from 'components/ListEdit/model';

import { SecurityDocumentPayment } from 'types/models/SecurityDocument';

export function validatePaymentByDocument(
  payment: SecurityDocumentPayment | null,
  mode: Mode,
  list: SecurityDocumentPayment[],
  index: number | null,
) {
  const payments = mode === 'edit' ? list.filter((_, itemIndex) => index !== itemIndex) : list;
  const isHasSamePayment = payments.some(paymentItem => paymentItem.paymentPurpose?.id === payment?.paymentPurpose?.id);
  const isValidPaymentPurpose = Boolean(payment?.paymentPurpose?.id);

  const isValidSamePayment = !isHasSamePayment;
  return [
    { isValid: isValidPaymentPurpose, invalidMessage: 'Заполните назначение платежа' },
    { isValid: isValidSamePayment, invalidMessage: 'В списке уже содержится элемент с таким назначением платежа' },
  ];
}

export function validatePaymentByRequest(
  payment: SecurityDocumentPayment | null,
  mode: Mode,
  list: SecurityDocumentPayment[],
  index: number | null,
) {
  const payments = mode === 'edit' ? list.filter((_, itemIndex) => index !== itemIndex) : list;

  const isValidPaymentPurposeRequest = Boolean(payment?.paymentPurposeRequest?.id);
  const isHasSamePayment = payments.some(
    paymentItem => paymentItem.paymentPurposeRequest?.id === payment?.paymentPurposeRequest?.id,
  );
  const isValidSamePayment = !isHasSamePayment;

  return [
    { isValid: isValidPaymentPurposeRequest, invalidMessage: 'Заполните назначение платежа' },
    { isValid: isValidSamePayment, invalidMessage: 'В списке уже содержится элемент с таким назначением платежа' },
  ];
}

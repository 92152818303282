export enum NotificationEventModule {
  PROGRAM_REQUEST = 'PROGRAM_REQUEST',
  NIR_REQUEST = 'NIR_REQUEST',
  MOBILE_REQUEST = 'MOBILE_REQUEST',
  PURCHASE_REQUEST = 'PURCHASE_REQUEST',
  PROJECT = 'PROJECT',
  TENDER = 'TENDER',
  QNA = 'QNA',
  USER = 'USER',
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Event } from 'types/models';
import { EventFormat, EventModule } from 'utils/Enums';
import { Settings } from 'types/models/common';

import { replaceSpacesWithSpace } from 'utils/Helpers/replaceSpacesWithSpace';
import { convertDocumentsToRequest } from '../converters';
import { EventInput, EventArrayField, ApproveEventRequest, SaveEventUserFeedlineRequest, DeleteEventRequest } from './types';
import { convertServerEvent, convertApproveEventResponseXMLElementToOutput } from './converters';
import {
  convertCommitteMembersToRequest,
  convertMemberCountsToRequest,
  convertEventFeedlineToRequest,
  convertSaveEventUserFeedlineToRequest,
  convertEventEnterpriseToRequest,
  convertEventDepartmentToRequest,
  convertProjectsToRequest,
} from './requestConverters';
import { convertReferenceItemToXML, convertSaveResponseXMLElementToOutput, getMaybeField } from '../commonConverters';
import { getMockEvent } from 'utils/Helpers/event';

function convertEventToRequest(commandName: string, input: EventInput) {
  const {
    simpleFields,
    arrayFields,
    attrIdFields,
    documents,
    departments,
    enterprises,
    memberCounts,
    grntis,
    ministryEducations,
    criticalTechnologies,
    pnrs,
    pnis,
    pnmitrs,
    magazineReleases,
    compilations,
    committeMembers,
    projects,
    feedline,
    format,
  } = input;
  let preparedEventInput: any = {};

  const simpleFieldKeys = Object.keys(simpleFields);
  const arrayFieldKeys = Object.keys(arrayFields);
  const attrIdFieldKeys = Object.keys(attrIdFields);

  // fill simple fields
  simpleFieldKeys.forEach(key => {
    const simpleFieldKey = key as keyof EventInput['simpleFields'];

    const isKeyValueEmpty = simpleFields[simpleFieldKey] !== undefined && simpleFields[simpleFieldKey] !== '';

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedEventInput[preparedKey] =
        simpleFieldKey === 'name' && simpleFields[simpleFieldKey]
          ? replaceSpacesWithSpace(simpleFields[simpleFieldKey]!)
          : simpleFields[simpleFieldKey];
    }
  });

  // fill array fields
  arrayFieldKeys.forEach(key => {
    const arrayFieldKey = key as keyof EventInput['arrayFields'];
    const arrayFieldValue = arrayFields[arrayFieldKey];
    const isKeyValueEmpty = !arrayFieldValue;

    if (!isKeyValueEmpty) {
      const arrayInnerFieldKey = Object.keys(arrayFields[arrayFieldKey]!)[0];
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      const preparedInnerKey = arrayInnerFieldKey[0].toUpperCase() + arrayInnerFieldKey.substring(1);
      const innerValue: EventArrayField[] = arrayFields[arrayFieldKey]![arrayInnerFieldKey as keyof typeof arrayFieldValue];

      preparedEventInput[preparedKey] = {};

      if (innerValue.length) {
        const preapredInnerValue = innerValue.map(innerValueMember => ({ _attr: { id: innerValueMember.id } }));
        preparedEventInput[preparedKey] = {
          [preparedInnerKey]: preapredInnerValue,
        };
      }
    }
  });

  // fill attributeid fields
  attrIdFieldKeys.forEach(key => {
    const attrIdFieldKey = key as keyof EventInput['attrIdFields'];

    const isKeyValueEmpty = !!attrIdFields[attrIdFieldKey];

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedEventInput[preparedKey] = { _attr: { id: attrIdFields[attrIdFieldKey] } };
    }
  });

  // START fill rest fields
  preparedEventInput.Projects = {};
  if ((projects || []).length) {
    preparedEventInput.Projects = convertProjectsToRequest(projects);
  }

  preparedEventInput = {
    ...preparedEventInput,
    ...convertDocumentsToRequest(documents),
    Departments: { Department: (departments || []).map(convertEventDepartmentToRequest) },
    Enterprises: { Enterprise: (enterprises || [])?.map(convertEventEnterpriseToRequest) },
    ...convertMemberCountsToRequest(memberCounts),
    ...convertCommitteMembersToRequest(committeMembers),
    MagazineReleases: { MagazineRelease: (magazineReleases || []).map(x => ({ _attr: { id: x.id } })) },
    Compilations: { Compilation: (compilations || []).map(x => ({ _attr: { id: x.id } })) },
    ...convertEventFeedlineToRequest(feedline),
    Grntis: grntis && grntis.length ? { Grnti: grntis.map(convertReferenceItemToXML) } : {},
    MinistryEducations:
      ministryEducations && ministryEducations.length
        ? { MinistryEducation: ministryEducations.map(convertReferenceItemToXML) }
        : {},
    CriticalTechnologies:
      criticalTechnologies && criticalTechnologies.length
        ? { CriticalTechnology: criticalTechnologies.map(convertReferenceItemToXML) }
        : {},
    Pnrs: pnrs && pnrs.length ? { Pnr: pnrs.map(convertReferenceItemToXML) } : {},
    Pnis: pnis && pnis.length ? { Pni: pnis.map(convertReferenceItemToXML) } : {},
    Pnmitrs: pnmitrs && pnmitrs.length ? { Pnmitr: pnmitrs.map(convertReferenceItemToXML) } : {},
    ...getMaybeField('Format', format?.value),
  };
  // END fill rest fields
  return preparedEventInput;
}

function convertEventResponseXMLElementToOutput(response: XML.ElementCompact, settings: Settings): Event.Event {
  const success = response.Response._attributes.success === 'true';

  if (success) {
    if (response.Response.Id) {
      const newEventId = response.Response.Id._text;

      return {
        ...getMockEvent(),
        id: newEventId,
        format: EventFormat.OFFLINE,
      };
    }

    const event = response.Response.Event;
    if (event) {
      return convertServerEvent(event, settings);
    }
  }

  return {
    ...getMockEvent(),
    format: EventFormat.OFFLINE,
  };
}

export const backendEventApiConfigurations = {
  GetEvent: makeBackendAPIConfiguration({
    id: 'GetEvent',
    endpoint: `/msa/service/commands/GetEvent`,
    convertInputToXMLElement: (input: EventInput) => ({
      Request: {
        _attr: { commandName: 'GetEvent', workbench: input.arm },
        Event: {
          _attr: {
            id: input.id,
            eventModules: input.eventModules ? input.eventModules.join(',') : Object.values(EventModule).join(','),
          },
          ...convertEventToRequest('GetEvent', input),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertEventResponseXMLElementToOutput,
  }),
  SaveEvent: makeBackendAPIConfiguration({
    id: 'SaveEvent',
    endpoint: `/msa/service/commands/SaveEvent`,
    convertInputToXMLElement: (input: EventInput) => ({
      Request: {
        _attr: { commandName: 'SaveEvent' },
        Event: {
          _attr: {
            id: input.id,
          },
          ...convertEventToRequest('SaveEvent', input),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertEventResponseXMLElementToOutput,
  }),
  ApproveEvent: makeBackendAPIConfiguration({
    id: 'ApproveEvent',
    endpoint: '/msa/service/commands/ApproveEvent',
    convertInputToXMLElement: ({ eventId, disapprove }: ApproveEventRequest) => ({
      Request: {
        _attr: { commandName: 'ApproveEvent' },
        Event: { _attr: { id: eventId, disapprove } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertApproveEventResponseXMLElementToOutput(response),
  }),
  SaveEventUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveEventUserFeedline',
    endpoint: '/msa/service/commands/SaveEventUserFeedline',
    convertInputToXMLElement: (props: SaveEventUserFeedlineRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveEventUserFeedline',
        },
        ...convertSaveEventUserFeedlineToRequest(props),
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  DeleteEvent: makeBackendAPIConfiguration({
    id: 'DeleteEvent',
    endpoint: '/msa/service/commands/DeleteEvent',
    convertInputToXMLElement: ({ eventId }: DeleteEventRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteEvent' },
        Event: { _attr: { id: eventId } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Table } from 'types/models';
import { SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { GetReferenceScientistListSpecification, GetEnterpriseCustomerSelectList } from 'features/Table/specifications';
import { Original } from 'types/models/Form';
import { Member } from 'features/SelectPerson';
import { EnterpriseFields } from 'services/BackendAPI/configurations/mips/types';

type Props = {
  member: SecurityDocumentMember | null;
  onMemberChange(row: SecurityDocumentMember | null): void;
  memberType: SecurityDocumentMemberTypes;
};

export function useController({ member, onMemberChange, memberType }: Props) {
  const [isOrg, setIsOrg] = useState<boolean>(!member?.person);
  const [selectedEnterpriseCustomFields, setSelectedEnterpriseCustomFields] = useState<EnterpriseFields | null>(null);

  const handlePersonChange = useCallback(
    (value: Member | null, id: string | null = '') => {
      onMemberChange(
        value?.person
          ? {
              ...member,
              id,
              contribution: member?.contribution || 0,
              type: { label: '', value: memberType },
              person: value?.person || null,
              name: value?.person?.fullName || '',
              job: value?.job || null,
              education: value?.education || null,
              degree: value?.degree || null,
              citizenship: value?.citizenship || null,
              rank: value?.rank || null,
              academicRank: value?.academicRank || null,
              historyLabel: '',
              contractNumber: member?.contractNumber || '',
              contractDate: member?.contractDate || '',
              contractPortion: member?.contractPortion || '',
              contractNote: member?.contractNote || '',
              enterprise: member?.enterprise || null,
            }
          : null,
      );
    },
    [member, onMemberChange, memberType],
  );

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const loadEnterpriseInfo = useCallback(
    (refId: string) => {
      loadReferenceAPI.callAPI(
        { filters: [], referenceName: 'RefEnterpriseCustomer', childIds: [refId] },
        {
          onSuccessfullCall: ({ data }) => {
            if (data && data.length > 0) {
              const { customFields = Object } = data[0];
              const enterpriseFields = customFields as EnterpriseFields;
              setSelectedEnterpriseCustomFields(enterpriseFields);
            }
          },
        },
      );
    },
    [loadReferenceAPI],
  );

  const handleOrgChange = useCallback(
    (value: ReferenceItem | null) => {
      if (value !== null) {
        loadEnterpriseInfo(value.id);
      }
      onMemberChange(
        value
          ? {
              ...member,
              id: member?.id ?? '',
              contribution: member?.contribution ?? 0.0,
              type: { label: '', value: memberType },
              degree: null,
              education: null,
              enterprise: value,
              job: null,
              name: value?.label || '',
              person: null,
              citizenship: null,
              rank: null,
              academicRank: null,
              historyLabel: '',
              contractNumber: member?.contractNumber || '',
              contractDate: member?.contractDate || '',
              contractPortion: member?.contractPortion || '',
              contractNote: member?.contractNote || '',
            }
          : null,
      );
    },
    [member, onMemberChange, memberType, loadEnterpriseInfo],
  );
  const handleNumberChange = useCallback(
    (fieldName: keyof SecurityDocumentMember) => (value: number) => {
      onMemberChange({ ...member, [fieldName]: value } as SecurityDocumentMember);
    },
    [member, onMemberChange],
  );

  const handleFieldChange = useCallback(
    (fieldName: keyof SecurityDocumentMember) => (value: string | null) => {
      onMemberChange({ ...member, [fieldName]: value } as SecurityDocumentMember);
    },
    [member, onMemberChange],
  );

  const personSpecification = GetReferenceScientistListSpecification({ hasSelectButton: true });

  const personTableRowConverter = useCallback<(row: Table.Entry) => Original>(
    row => ({
      id: row.id || '',
      name: row.FullFIO,
    }),
    [],
  );

  const applicantOptions = [
    { label: 'Организация', value: 'ORG' },
    { label: 'Персона', value: 'PERSON' },
  ];

  const onRadioChange = useCallback(
    (nextIsOrg: boolean) => {
      handlePersonChange(null);
      setIsOrg(nextIsOrg);
    },
    [handlePersonChange],
  );
  const enterpriseCustomerOriginal = useMemo((): Original => {
    if (!member) {
      return {
        id: '',
        name: '',
      };
    }
    return {
      id: member?.id || '',
      name:
        member?.name ||
        [
          selectedEnterpriseCustomFields?.fullName || '',
          selectedEnterpriseCustomFields?.inn ? `ИНН: ${selectedEnterpriseCustomFields?.inn}` : '',
          selectedEnterpriseCustomFields?.kpp ? `КПП: ${selectedEnterpriseCustomFields?.kpp}` : '',
        ]
          .filter(Boolean)
          .join(', '),
    };
  }, [member, selectedEnterpriseCustomFields]);

  const enterpriseCustomerRowConverter = useCallback<(row: Table.Entry) => ReferenceItem>(row => {
    return {
      id: row.id,
      name: `${row.FullName || row.Name}`,
      label: `${row.FullName || row.Name}`,
    };
  }, []);

  const enterpriseCustomerSpecification = GetEnterpriseCustomerSelectList();

  return {
    isOrg,
    onRadioChange,
    handlePersonChange,
    handleOrgChange,
    handleNumberChange,
    handleFieldChange,
    personSpecification,
    personTableRowConverter,
    applicantOptions,
    enterpriseCustomerOriginal,
    enterpriseCustomerRowConverter,
    enterpriseCustomerSpecification,
  };
}

import React from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, SectionTitle, Toolbar } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ProlongateRequests } from './ProlongateRequests';
import { useController } from './controller';
import { SelectProgramRequest } from 'features/Form/looks/project/ProjectForm/views/SelectProgramRequest';
import { SelectNir } from 'features/Form/looks/project/ProjectForm/views/SelectNir';
import {
  CopyFromContestRequest,
  CopyFromNirRequest,
  CopyFromProgramRequest,
} from 'features/Form/looks/project/ProjectForm/types';
import { SelectSecurityDocumentContract } from 'features/Form/looks/project/ProjectForm/views';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

export type Props = {
  disabled: boolean;
  project: Project.Project | null;
  saveProject(isSaveLoad?: boolean): void;
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
  copyFromNirRequest({ id, copyOptions }: CopyFromNirRequest): void;
  copyFromProgramRequest({ id, copyOptions }: CopyFromProgramRequest): void;
  copyFromContestRequest({ id, stageId, copyOptions }: CopyFromContestRequest): void;
};

const Component: React.FC<Props> = (props: Props) => {
  const {
    disabled,
    project,
    saveProject,
    makeChangeHandler,
    copyFromNirRequest,
    copyFromProgramRequest,
    copyFromContestRequest,
  } = props;

  const {
    nirRequestExtraButtons,
    programRequestExtraButtons,
    isNirRequestOptionsPopupOpen,
    setIsNirRequestOptionsPopupOpen,
    confirmNirRequestOptions,
    setProgramRequest,
    setSecurityDocumentContract,
    setNirRequest,
    nirRequestCopyOptions,
    setNirRequestCopyOptions,
    programRequestCopyOptions,
    setProgramRequestCopyOptions,
    isProgramRequestOptionsPopupOpen,
    setIsProgramRequestOptionsPopupOpen,
    confirmProgramRequestOptions,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    disabled,
    copyFromNirRequest,
    copyFromProgramRequest,
    makeChangeHandler,
    project,
    saveProject,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '50%' }}>
          <FormComponent.Line>
            <SelectSecurityDocumentContract
              setSecurityDocumentContract={setSecurityDocumentContract}
              securityDocumentContract={project?.securityDocumentContract ?? null}
              label="Договор на ИС"
              modalTitle='Справочник "Договоры на ИС"'
              isDisabled={disabled}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <SelectProgramRequest
              setProgramRequest={setProgramRequest}
              programRequest={project?.programRequest ?? null}
              label="Заявка на конкурс"
              modalTitle='Справочник "Заявки на конкурс программы"'
              externalButtons={programRequestExtraButtons}
              isDisabled={disabled}
            />
          </FormComponent.Line>
          {!!project?.programRequest && (
            <FormComponent.Line>
              <FormComponent.Field label="История копирования">
                <pre>
                  {[project?.programRequestDataCopyDetail, project?.newProgramRequestDataCopyDetail].filter(Boolean).join('\n')}
                </pre>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          <FormComponent.Line>
            <SelectNir
              nir={project?.nirRequest ?? null}
              setNir={setNirRequest}
              label="Заявка на внутр. конкурс"
              modalTitle='Справочник "Заявки на внутренний конкурс"'
              externalButtons={nirRequestExtraButtons}
              isDisabled={disabled}
            />
          </FormComponent.Line>
          {!!project?.nirRequest && (
            <FormComponent.Line>
              <FormComponent.Field label="История копирования">
                <pre>{[project?.nirRequestDataCopyDetail, project?.newNirRequestDataCopyDetail].filter(Boolean).join('\n')}</pre>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <SectionTitle title="Заявки для актуализации данных этапа проекта и заявки на продление" />

      <ProlongateRequests
        contestRequests={project?.contestRequests ?? []}
        setContestRequests={makeChangeHandler('contestRequests')}
        disabled={disabled}
        stages={project?.stages ?? []}
        copyFromContestRequest={copyFromContestRequest}
      />

      <Modal
        title="Копирование из заявки на внутренний конкурс: выберите разделы"
        isOpen={isNirRequestOptionsPopupOpen}
        onClose={() => setIsNirRequestOptionsPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmNirRequestOptions,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsNirRequestOptionsPopupOpen(false),
          },
        ]}
        size="medium"
      >
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={nirRequestCopyOptions.isProject}
              onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isProject: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <p>
              <strong>Описание проекта</strong> - Данные будут скопированы на вкладки "Описание" и "Классификаторы":
            </p>
            <p>
              OECD, Аннотация, ГРНТИ, Ключевые слова, Критические технологии, Научный задел, Обоснование междисциплинарного
              подхода, Ожидаемые результаты, Приоритетные направления развития науки, технологий и техники, Приоритетные
              направления развития, Приоритеты Стратегии НТР РФ, Тема проекта, УДК, Характер проекта, Цель
            </p>
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={nirRequestCopyOptions.isStage}
              onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isStage: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <strong>Описание этапов</strong> - Данные будут скопированы на форму редактирования этапа на вкладке "Этапы"
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={nirRequestCopyOptions.isIndicators}
              onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isIndicators: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <strong>Индикаторы и показатели</strong> - Плановые значения показателей и индикаторов на этап будут скопированы в
            раздел на вкладке "Результаты"
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={nirRequestCopyOptions.isPerformers}
              onChange={e => setNirRequestCopyOptions(prevState => ({ ...prevState, isPerformers: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <strong>Список исполнителей</strong> - Обновление списка исполнителей на вкладке "Коллектив" с периодом работы, равным
            срокам проекта
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Text>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </FormComponent.Text>
      </Modal>

      <Modal
        title="Копирование из заявки на конкурс: выберите разделы"
        isOpen={isProgramRequestOptionsPopupOpen}
        onClose={() => setIsProgramRequestOptionsPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmProgramRequestOptions,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsProgramRequestOptionsPopupOpen(false),
          },
        ]}
        size="medium"
      >
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={programRequestCopyOptions.isProject}
              onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isProject: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <p>
              <strong>Описание проекта</strong> - следующие данные будут скопированы на вкладку <strong>Описание</strong>:
            </p>
            <p>
              Аннотация, ГРНТИ, Ключевые слова, Критические технологии, 2-Наука (ОКВЭД, ЛКСЭЦ, Отрасль науки), Приоритетные
              направления развития науки, технологий и техники, Приоритеты Стратегии НТР РФ, Тема проекта, УДК
            </p>
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={programRequestCopyOptions.isClassifiers}
              onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isClassifiers: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <p>
              <strong>Классификаторы</strong> - следующие данные будут скопированы на вкладку <strong>Классификаторы</strong>:
            </p>
            <p>
              Отрасли знаний (РНФ), Области научных интересов, Приоритетные направления развития модернизации и технического
              развития экономики России, Приоритетные направления развития, Технологические платформы
            </p>
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field>
            <Checkbox
              checked={programRequestCopyOptions.isPerformers}
              onChange={e => setProgramRequestCopyOptions(prevState => ({ ...prevState, isPerformers: e }))}
            />
          </FormComponent.Field>
          <FormComponent.Text>
            <strong>Список исполнителей</strong> - обновление списка исполнителей на вкладке <strong>Коллектив</strong> с периодом
            работы, равным срокам проекта
          </FormComponent.Text>
        </FormComponent.Line>
        <FormComponent.Text>
          <strong>Внимание: При копировании имеющиеся значения из списка копирования в проекте будут удалены!</strong>
        </FormComponent.Text>
      </Modal>

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Заявки"
        size="large"
      >
        {ProjectHelp().requests}
      </Modal>
    </>
  );
};

export const Requests = React.memo(Component) as typeof Component;

import { parse } from 'date-fns';

import { Table } from 'types/models';
import { formatStr } from 'utils/Constants';
import { Color } from 'constants/colors';

export function detPeriodHighlights(row: Table.Entry, days: number) {
  const daysOffset = days * 86400 * 1000; // n days in milliseconds
  const upToTime = parse(row.UpToDate, formatStr, new Date()).getTime();
  const deadLine = upToTime - daysOffset;
  const softDeeadline = deadLine - daysOffset;
  const now = new Date().getTime();

  if (now < upToTime) {
    if (now > softDeeadline && now < deadLine) {
      // in soft deadline period
      return Color.warning;
    } else {
      if (now > deadLine) {
        // in hard deadline
        return Color.danger;
      } else {
        return Color.primary;
      }
    }
  }

  // totally expired
  return Color.danger;
}

import React, { useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonProps, Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isViewButtonDisabled,
    isHandleErrorButtonDisabled,
    handleViewButtonClick,
    handleHandleErrorButtonClick,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isHandleErrorFormOpen,
    handleTemplateCloseHandleErrorForm,
  } = useController({ tableState });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'library', mode: 'edit' },
        title: 'Обработать ошибку интеграции с НБ',
        onClick: handleHandleErrorButtonClick,
        isDisabled: isHandleErrorButtonDisabled,
      },
    ],
    [isViewButtonDisabled, isHandleErrorButtonDisabled, handleViewButtonClick, handleHandleErrorButtonClick],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.PublicationId}
          relatedTableState={tableState}
        />
      )}

      {!isHandleErrorButtonDisabled && (
        <modalTemplates.HandleErrorTemplate.Component
          isOpen={isHandleErrorFormOpen}
          onClose={handleTemplateCloseHandleErrorForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Rntd } from 'types/models';
import { ValueOf } from 'types/helpers';
import { ListEdit, SectionTitle } from 'components';
import React from 'react';

import { GetSubjectListForSelect } from 'features/Table/specifications';
import { useController } from './controller';

type Props = {
  rntd?: Rntd | null;
  makeChangeHandler(key: keyof Rntd): (value: ValueOf<Rntd>) => void;
  isDisabled?: boolean;
};

export function RntdSubjects({ rntd, makeChangeHandler, isDisabled }: Props) {
  const { handleSubjectsChange } = useController({ rntd, makeChangeHandler });
  return (
    <>
      <SectionTitle title="РИД в составе продуктов и тематик" />

      <ListEdit
        rows={rntd?.subjects || []}
        onChange={handleSubjectsChange}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Продукт/Тематика', formatValue: x => `${x?.type?.label || ''} ${x.code}`, styles: { width: '20%' } },
          { label: 'Наименование', formatValue: x => x.name },
        ]}
        isDisabled={isDisabled}
        specification={{
          mode: 'relationTableModal',
          relationTableModalTitle: 'Тематики',
          modalTableRowConverter: row => ({ name: row.name, id: row.id, code: row.code }),
          modalTableSpecification: GetSubjectListForSelect({}),
        }}
      />
    </>
  );
}

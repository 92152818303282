import { useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Project } from 'types/models';

export type State = {
  isOpenMessageModal: boolean;
  feedline: Project.Feedline | null;
  isOpenCodeChangesModal: boolean;
  projectId: string;
  projectNumber: string;
  isOpenConfirmModal: boolean;
  disabled: boolean;
  setFeedline(feedline: Project.Feedline | null): void;
  toggleVisibleMessageModal(): void;
  closeMessageModal(): void;
  loadProjectFeedline(feedlineId: string): void;
  handleSubmitMessageModal(feedlint: Project.Feedline): void;
  toggleVisibleCodeChangesModal(): void;
  deleteFeedline(id: string): void;
  setIsOpenConfirmModal(isOpen: boolean): void;
};

type Arguments = {
  projectId: string;
  projectNumber: string;
  disabled: boolean;
};

export function makeUseCustomController({ projectId, projectNumber, disabled }: Arguments) {
  return function useCustomController(): State {
    const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
    const [isOpenCodeChangesModal, setIsOpenCodeChangesModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);

    const [feedline, setFeedline] = useState<Project.Feedline | null>(null);

    const tableStreams = useLocalTableStreams();

    const toggleVisibleMessageModal = useCallback(() => {
      setIsOpenMessageModal(prev => !prev);
    }, []);

    const toggleVisibleCodeChangesModal = useCallback(() => {
      setIsOpenCodeChangesModal(prev => !prev);
    }, []);

    const closeMessageModal = useCallback(() => {
      setIsOpenMessageModal(false);
      setFeedline(null);
    }, []);

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const { methods: getProjectFeedlineAPI } = BackendAPI.useBackendAPI('GetProjectFeedline');
    const { methods: saveFeedlineAPI } = BackendAPI.useBackendAPI('SaveProjectUserFeedline');
    const { methods: deleteModelAPI } = BackendAPI.useBackendAPI('DeleteModel');

    const loadProjectFeedline = useCallback((feedlineId: string) => {
      getProjectFeedlineAPI.callAPI(
        { feedlineId },
        {
          onSuccessfullCall: ({ data }) => {
            setFeedline(data);
            setIsOpenMessageModal(true);
          },
        },
      );
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const deleteFeedline = useCallback(
      (rowId: string) => {
        deleteModelAPI.callAPI(
          { entityName: 'ProjectFeedline', rowId, commandName: 'DeleteEntity' },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Запись успешно удалена', theme: 'success' });
              reloadTable();
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [reloadTable],
    );

    const handleSubmitMessageModal = useCallback(
      (feed: Project.Feedline) => {
        saveFeedlineAPI.callAPI(
          { feedline: feed, projectIds: [projectId] },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
              reloadTable();
            },
          },
        );
      },
      // eslint-disable-next-line react-hooks/exhaustive-deps
      [projectId, reloadTable],
    );

    return {
      isOpenMessageModal,
      feedline,
      isOpenCodeChangesModal,
      projectId,
      projectNumber,
      isOpenConfirmModal,
      setIsOpenConfirmModal,
      deleteFeedline,
      toggleVisibleCodeChangesModal,
      toggleVisibleMessageModal,
      loadProjectFeedline,
      setFeedline,
      handleSubmitMessageModal,
      closeMessageModal,
      disabled,
    };
  };
}

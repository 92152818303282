import React, { memo } from 'react';

import { Document } from 'types/models';
import { Documents } from 'features/Documents';

type Props = {
  documents: Document[];
  setDocuments: (docs: Document[]) => void;
  viewMode?: boolean;
};

const Docs = ({ documents, setDocuments, viewMode }: Props) => (
  <Documents documents={documents} setDocuments={setDocuments} disabled={Boolean(viewMode)} />
);

export const Component = memo(Docs);

import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

enum ModeOptions {
  all = 'all',
  actual = 'actual',
  byOpenEnvelope = 'byOpenEnvelope',
  bySummingUp = 'bySummingUp',
}

export const Filters = React.memo(() => {
  const [mode, setMode] = useState<ModeOptions>(ModeOptions.all);
  const [year, setYear] = useState<string>('');

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'mode',
      list: [
        { label: 'Все', value: ModeOptions.all },
        { label: 'Актуальные', value: ModeOptions.actual },
        { label: 'Год окончания приёма заявок', value: ModeOptions.byOpenEnvelope },
        { label: 'Год подведения итогов', value: ModeOptions.bySummingUp },
      ],
      value: mode,
      onChange: (e: ModeOptions) => {
        setMode(e);
        if ([ModeOptions.all, ModeOptions.actual].includes(e)) {
          setYear('');
        } else if (!year) {
          setYear(new Date().getFullYear().toString());
        }
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.input,
      streamKey: 'year',
      isYearMask: true,
      value: year,
      onChange: setYear,
      isDisabled: [ModeOptions.all, ModeOptions.actual].includes(mode),
    },
  ]);
});

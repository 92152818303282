import {
  SecurityDocumentMemberTypes,
  SecurityDocumentMember,
  SecurityDocumentPayment,
  SecurityDocument,
  Balance,
} from 'types/models/SecurityDocument';
import { convertDepartmentToXML } from 'services/BackendAPI/configurations/rntd/requestConverters';

import { convertDocumentToRequest, convertFileToXML, convertReferenceItemToXML, getMaybeField } from '../commonConverters';
import { getHistoricalSliceXML } from '../author/requestConverters';
import { convertRefElementsToRequest } from '../project/requestConverters';

export function convertSecurityDocumentToXML(p: SecurityDocument) {
  const authors = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.AUTHOR);
  const applicants = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.APPLICANT);
  const admitted = p.members.filter(m => m.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON);
  return {
    _attr: p.id ? { id: p.id } : {},
    Type: p.type?.value ?? 'PATENT',
    ...getMaybeField('RequestNumber', p.requestNumber),
    ...getMaybeField('RequestDate', p.requestDate),
    ...getMaybeField('RequestSendDate', p.requestSendDate),
    ...getMaybeField('DocumentDate', p.documentDate),
    ...getMaybeField('DocumentReceiveDate', p.documentReceiveDate),
    ...getMaybeField('IsRus', p.isRus),
    ...getMaybeField('IsWorldwide', p.isWorldwide),
    ...getMaybeField('DocumentNumber', p.documentNumber),
    ...getMaybeField('InternalNumber', p.internalNumber),
    ...getMaybeField('StateNumber', p.stateNumber),
    ...getMaybeField('StateRegistrationDate', p.stateRegistrationDate),
    ...getMaybeField('IntellectualPropertyName', p.intellectualPropertyName),
    ...getMaybeField('Referat', p.referat),
    ...(p.kind?.id ? { Kind: { _attr: { id: p.kind?.id } } } : {}),
    ...(p.balanceOrderNumber ? { BalanceOrderNumber: p.balanceOrderNumber } : {}),
    ...getMaybeField('BalanceLoDate', p.loDate),
    ...getMaybeField('BalanceHiDate', p.hiDate),
    ...getMaybeField('DocumentStartDate', p.documentStartDate),
    ...getMaybeField('DocumentEndDate', p.documentEndDate),
    ...(p.balancePrice ? { BalancePrice: p.balancePrice } : {}),
    ...(p.project?.id ? { Project: { _attr: { id: p.project?.id } } } : {}),
    Authors: authors && authors.length ? { Author: authors.map(convertAuthorToXML) } : {},
    Countries: p.countries && p.countries.length ? { Country: p.countries.map(convertReferenceItemToXML) } : {},
    Applicants: applicants && applicants.length ? { Applicant: applicants.map(convertApplicantToXML) } : {},
    AdmittedPersons: admitted && admitted.length ? { AdmittedPerson: admitted.map(convertAuthorToXML) } : {},
    ...convertRefElementsToRequest('Grntis', 'Grnti', p.grntis),
    ...convertRefElementsToRequest('Udks', 'Udk', p.udks),
    ...convertRefElementsToRequest('Okveds', 'Okved', p.okveds),
    ...convertRefElementsToRequest('Lksetss', 'Lksets', p.lksetss),
    ...convertRefElementsToRequest('ScienceBrunches', 'ScienceBrunch', p.scienceBrunches),
    ...convertRefElementsToRequest('ScienceDomainInterrests', 'ScienceDomainInterrest', p.scienceDomainInterrests),
    ...convertRefElementsToRequest('Vaks', 'Vak', p.vakSpecialities),
    ...convertRefElementsToRequest('Pnrs', 'Pnr', p.pnrs),
    ...convertRefElementsToRequest('Pnmitrs', 'Pnmitr', p.pnmitrs),
    ...convertRefElementsToRequest('Pnis', 'Pni', p.pnis),
    ...convertRefElementsToRequest('NtrStrategies', 'NtrStrategy', p.ntrStrategies),
    ...convertRefElementsToRequest('TechnologyPlatforms', 'TechnologyPlatform', p.technologyPlatforms),
    ...convertRefElementsToRequest('PriorityTechnologies', 'PriorityTechnology', p.priorityTechnologies),
    ...convertRefElementsToRequest('KnowledgeBranches', 'KnowledgeBranch', p.knowledgeBranches),
    ...convertRefElementsToRequest('Oecds', 'Oecd', p.oecds),
    ...convertRefElementsToRequest('NtiEteTechnologies', 'NtiEteTechnology', p.ntiEteTechnologies),
    ...convertRefElementsToRequest('NtiMarkets', 'NtiMarket', p.ntiMarkets),
    ...convertRefElementsToRequest('Mpks', 'Mpk', p.mpks),
    Payments: p.payments && p.payments.length ? { Payment: p.payments.map(convertPaymentToXML) } : {},
    Documents:
      p.documents && p.documents.length
        ? { Document: p.documents.filter(doc => doc.docType === 'local').map(doc => convertDocumentToRequest(doc)) }
        : {},
    Departments: p.departments && p.departments.length ? { Department: p.departments.map(convertDepartmentToXML) } : {},
    IsRejected: !!p.isRejected,
    Balances: p.balances?.length ? { Balance: p.balances.map(convertBalanceToXML) } : {},
  };
}

function convertBalanceToXML(balance: Balance) {
  return {
    _attr: balance.id ? { id: balance.id } : {},
    ...getMaybeField('Date', balance.date),
    ...getMaybeField('Amount', balance.amount),
    ...getMaybeField('AlphaAmount', balance.alphaAmount),
    ...getMaybeField('ResidualAmount', balance.residualAmount),
  };
}

export function convertAuthorToXML(a: SecurityDocumentMember) {
  return {
    _attr: {
      ...(a.id ? { id: a.id } : {}),
      ...(a.person?.scientist?.id ? { scientistId: a.person?.scientist?.id } : {}),
      ...(a.contribution.toString()
        ? a.enterprise?.id
          ? { contractPortion: a.contribution }
          : { contribution: a.contribution }
        : {}),
      ...(a.contractNumber ? { contractNumber: a.contractNumber } : {}),
      ...(a.contractDate ? { contractDate: a.contractDate } : {}),
      ...(a.contractPortion ? { contractPortion: a.contractPortion } : {}),
      ...(a.contractNote ? { contractNote: a.contractNote } : {}),
      ...(a.enterprise?.id ? { enterpriseId: a.enterprise?.id } : {}),
    },
    ...getHistoricalSliceXML(a),
  };
}

export function convertApplicantToXML(a: SecurityDocumentMember) {
  return {
    _attr: {
      ...(a.id ? { id: a.id } : {}),
      ...(a.person?.scientist?.id ? { scientistId: a.person?.scientist?.id } : {}),
      contractPortion: a.contractPortion,
      ...(a.contractNumber ? { contractNumber: a.contractNumber } : {}),
      ...(a.contractDate ? { contractDate: a.contractDate } : {}),
      ...(a.contractPortion ? { contractPortion: a.contractPortion } : {}),
      ...(a.contractNote ? { contractNote: a.contractNote } : {}),
      ...(a.enterprise?.id ? { enterpriseId: a.enterprise?.id } : {}),
      type: a.enterprise?.id ? 'enterprise' : 'person',
    },
  };
}

export function convertPaymentToXML(p: SecurityDocumentPayment) {
  return {
    _attr: p.id ? { id: p.id } : {},
    ...(p.paymentPurposeRequest?.id ? { PaymentPurposeRequest: { _attr: { id: p.paymentPurposeRequest?.id } } } : {}),
    ...(p.paymentPurpose?.id ? { PaymentPurpose: { _attr: { id: p.paymentPurpose?.id } } } : {}),
    ...getMaybeField('PayDate', p.payDate),
    ...(p.payBeforeDate ? { PayBeforeDate: p.payBeforeDate } : {}),
    Amount: p.amount || '0.00',
    ...convertFileToXML('File', p.file),
  };
}

import { parse } from 'date-fns';

import { Financing, ProjectCode, Department, CalendarPlanByYear, CalendarPlanByStage } from 'types/models/Project';
import {
  convertChange,
  convertPerformer,
  convertProject,
  convertStage,
} from 'services/BackendAPI/configurations/project/converters';
import { formatStr } from 'utils/Constants';
import { convertReceivingDocument } from 'services/BackendAPI/configurations/securityDocumentContract/converters';
import { Settings } from 'types/models/common';
import { convertDocument, convertMaybeArray, convertReference, getBoolean, getId, getText } from '../commonConverters';
import { convertFundCard } from '../fundCard/converters';

export function convertProjectCode(pc: any, settings: Settings): ProjectCode {
  const serverForeignDocuments = pc?.ForeignDocuments?.Document || [];
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));
  const serverDocuments = pc?.Documents?.Document || [];
  const documents = convertMaybeArray(serverDocuments, i => convertDocument('local', i));

  return {
    calendarPlansTotalAmount: pc?.CalendarPlansByYears?._attributes.totalAmount,
    isActive: getText(pc?.ExpenseFactAmount) === 'true',
    isFundCardUsed: getText(pc?.IsFundCardUsed) === 'true',
    id: pc?._attributes?.id,
    code: getText(pc?.Code),
    project: pc?.Project ? convertProject(pc?.Project, settings) : null,
    ...(pc?.FundCard ? { fundCard: convertFundCard(pc?.FundCard) } : {}),
    accompliceAmount: getText(pc?.AccompliceAmount),
    ...(pc?.Department ? { department: convertDepartment(pc?.Department) } : {}),
    expenseFactAmount: getText(pc?.ExpenseFactAmount),
    expensePlanAmount: getText(pc?.ExpensePlanAmount),
    financing: convertFinancing(pc?.Financing),
    incomeFactAmount: getText(pc?.IncomeFactAmount),
    incomePlanAmount: getText(pc?.IncomePlanAmount),
    overheadFactAmount: getText(pc?.OverheadFactAmount),
    overheadPercent: getText(pc?.OverheadPercent),
    ...(pc?.ResponsiblePerformer ? { responsiblePerformer: convertPerformer(pc?.ResponsiblePerformer, settings) } : {}),
    year: getText(pc?.Year),
    codeDepartmentPart: getText(pc?.CodeDepartmentPart),
    codeYearPart: getText(pc?.CodeYearPart),
    codeManualPart: getText(pc?.CodeManualPart),
    letter: convertReference(pc?.Letter),
    financeProvide: convertReference(pc?.FinanceProvide),
    isOutsideBudget: getText(pc?.IsOutsideBudget) === 'true',
    isRefersToEconomicAgreement: getText(pc?.IsRefersToEconomicAgreement) === 'true',
    departments: convertMaybeArray(pc?.Departments?.Department || [], convertReference),
    calendarPlansByYears:
      convertMaybeArray(pc?.CalendarPlansByYears?.CalendarPlanByYear || [], convertCalendarPlanByYear).sort((x1, x2) => {
        return parseInt(x1.year, 10) - parseInt(x2.year, 10);
      }) || [],
    calendarPlansByStages:
      convertMaybeArray(pc?.CalendarPlansByStages?.CalendarPlanByStage || [], convertCalendarPlanByStage).sort((x1, x2) => {
        return parseInt(x1.stage.number, 10) - parseInt(x2.stage.number, 10);
      }) || [],
    receivingDocuments:
      convertMaybeArray(pc?.ReceivingDocuments?.ReceivingDocument || [], convertReceivingDocument).sort((x1, x2) => {
        return (
          parse(x2.cashDocument?.payDate || '', formatStr, new Date()).getTime() -
          parse(x1.cashDocument?.payDate || '', formatStr, new Date()).getTime()
        );
      }) || [],
    documents: [...foreignDocuments, ...documents],
    changesInProject: convertMaybeArray(pc.ChangesInProject?.ChangeInProject || [], e => convertChange(e, settings)),
  };
}

export function convertGenerateProjectCodeResponse(r: any): string {
  return getText(r);
}

export function convertCalendarPlanByYear(cp: any): CalendarPlanByYear {
  return {
    accompliceAmount: parseFloat(getText(cp?.AccompliceAmount)),
    amount: parseFloat(getText(cp?.Amount)),
    factAmount: parseFloat(getText(cp?.FactAmount)),
    hasEstimate: getText(cp?.HasEstimate) === 'true',
    isInProjectStages: getText(cp?.HasEstimate) === 'true',
    ndsAmount: parseFloat(getText(cp?.NdsAmount)),
    ndsPercent: parseFloat(getText(cp?.NdsPercent)),
    year: getText(cp?.Year),
  };
}

export function convertCalendarPlanByStage(cp: any): CalendarPlanByStage {
  return {
    amount: parseFloat(getText(cp?.Amount)),
    ndsAmount: parseFloat(getText(cp?.NdsAmount)),
    ndsPercent: parseFloat(getText(cp?.NdsPercent)),
    stage: convertStage(cp?.Stage),
  };
}

export function convertDepartment(d: any): Department {
  return {
    id: d?._attributes?.id || '',
    governance: convertReference(d?.Governance),
    unit: convertReference(d?.Unit),
  };
}

function convertFinancing(f: any): Financing {
  return {
    id: getId(f),
    amount: getText(f?.Amount),
    factAmount: getText(f?.FactAmount),
    isNds: getBoolean(f?.IsNds),
    source: convertReference(f?.Source),
    type: { value: getText(f?.Type), label: '' },
  };
}

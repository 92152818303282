import React, { memo } from 'react';

import { FormComponent, Checkbox } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const AbstractBoolean = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { isChecked, currentParameter, onIsCheckedChange } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });
  return (
    <FormComponent.Line>
      <FormComponent.Field label={currentParameter?.description ?? 'CHECKBOX'} isRequired={currentParameter?.required}>
        <Checkbox checked={isChecked} onChange={onIsCheckedChange} />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(AbstractBoolean);

export { Component as AbstractBoolean };

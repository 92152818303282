import { Table as T } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

export function GetPersonalPublicationList(): T.Specification {
  return {
    apiID: 'GetPersonalPublicationList',
    header: {
      firstLevel: {
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    enumFilterValues: {
      enumName: 'PublicationType',
      excludeValues: [PublicationTypes.MAGAZINE.code],
    },
    isWithQna: true,
  };
}

import React from 'react';

import { Button, ButtonMode, FormComponent, Modal, Toolbar as SharedToolbar, TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';

import './style.scss';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function LastToolbar({ onSubmitTable, tableState, customState }: Props) {
  const {
    buttons,
    isOpenHelpModal,
    setIsOpenHelpModal,
    description,
    setDescription,
    isEditableDescription,
    onSave,
  } = useController({
    onSubmitTable,
    tableState,
    customState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        title={`Правила использования справочника "${customState?.referenceMetadata?.caption}"`}
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        size={['large', 'small-height']}
      >
        <FormComponent.Wrapper>
          {isEditableDescription ? (
            <>
              <TextArea
                mode={TextAreaMode.WYSIWYG}
                settings={{ title: `Правила использования справочника "${customState?.referenceMetadata?.caption}"`, rows: 16 }}
                value={description}
                onChange={setDescription}
                classMixin="reference_description"
              />

              <FormComponent.Actions>
                <Button mode={ButtonMode.PRIMARY} text="Сохранить" onClick={onSave} />
                <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={() => setIsOpenHelpModal(false)} />
              </FormComponent.Actions>
            </>
          ) : (
            <div className="quill-preview" dangerouslySetInnerHTML={{ __html: description }}></div>
          )}
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export { LastToolbar };

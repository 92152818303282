import { format } from 'date-fns';

import { ProjectAssignment } from 'types/models/Project';
import { EMPTY_FILE, formatStr } from 'utils/Constants';

export function getMockProjectAssignment(): ProjectAssignment {
  return {
    id: null,
    projectId: '',
    date: format(new Date(), formatStr),
    assignment: '',
    status: null,
    file: EMPTY_FILE,
  };
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function PublicationGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Публикации</h4>

        <p>
          Функциональный блок <strong>Публикации</strong> предназначен для ведения реестра всех публикаций; для отчетности
          Университета по публикациям сотрудников, студентов и аспирантов Университета и анализа качественных и количественных
          характеристик публикационной активности, результатов выполнения научных проектов в части публикаций и оценки научного
          потенциала.
        </p>

        <p>В разделе Публикации ведётся учёт 19 типов публикации, а именно:</p>
        <ul>
          <li>статья в журнале;</li>
          <li>статья в сборнике материалов конференций, симпозиумов и др.;</li>
          <li>статья в сборнике научных трудов;</li>
          <li>статья в справочном издании;</li>
          <li>статья в другом издании;</li>
          <li>тезисы;</li>
          <li>монография;</li>
          <li>глава в монографии;</li>
          <li>сборник материалов конференций, симпозиумов и др.;</li>
          <li>сборник научных трудов;</li>
          <li>справочное издание;</li>
          <li>другое издание;</li>
          <li>автореферат диссертации;</li>
          <li>диссертация;</li>
          <li>препринт;</li>
          <li>учебник;</li>
          <li>учебное пособие;</li>
          <li>другое учебное издание;</li>
          <li>учебно-методическое пособие.</li>
        </ul>

        <p>
          Для каждого типа публикации используются форма, учитывающая особенности при описании сведений об издании, в том числе
          электронного или печатного; наукометрических показателях; связях с другими объектами системы: научными проектами,
          конференцией – для материалов конференций.
        </p>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>
          Страница пункта меню <strong>Публикации</strong> содержит в табличном виде перечень всех типов публикаций, в которых
          принимали участие сотрудники, студенты и аспиранты университета. Назначение функциональных элементов интерфейса
          представлено в таблице.
        </p>

        <div className="warning">
          <span>
            Набор элементов интерфейса может быть полным или частичным в зависимости от наличия прав у пользователя. Количество
            доступных для отображения в таблице столбцов зависит от выбранного режима «Ввод данных» или «Анализ данных».
          </span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Элемент</th>
              <th>Описание интерфейса</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Черновики</td>
              <td>
                Фильтр для формирования списка публикаций, у которых статус <strong>Черновик</strong>
              </td>
            </tr>
            <tr>
              <td>С аффилиацией</td>
              <td>
                Фильтр для формирования списка публикаций, в которых хотя бы для одного автора указана аффилиация с Университетом.
              </td>
            </tr>
            <tr>
              <td>Созданные мной</td>
              <td>
                Фильтр для списка публикаций, добавленных пользователем. Система фиксирует в журнале сообщений публикации кто и
                когда добавил публикацию
              </td>
            </tr>
            <tr>
              <td>По подразделениям</td>
              <td>
                Фильтр для формирования списка публикаций, связанных с подразделением пользователя (включая дочерние подразделения
                в соответствии с иерархией в справочнике <strong>«Подразделения»</strong>)
              </td>
            </tr>
            <tr>
              <td>Показывать публикации за год</td>
              <td>
                Фильтр формирует список публикаций, сроки издания которых содержат указанный год, если фильтр отключен, то список
                содержит все публикации базы данных. Фильтр накладывает условия отбора с учётом других фильтров
              </td>
            </tr>
            <tr>
              <td>Шаблон выборки</td>
              <td>Для режимов «ввода данных» и «режима анализ данных» списки шаблонов представления данных в таблице РАЗНЫЕ</td>
            </tr>
          </tbody>
        </table>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Открывает форму просмотра публикации. Кнопка активна при выделении публикации в таблице. Доступна пользователю в
                любом статусе Публикации
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму для добавления публикации</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Открывает форму редактирования публикации. Кнопка активна при выделении публикации в таблице Пользователь имеет
                право редактировать свою публикацию в статусах <strong>Черновик</strong> или <strong>Добавлена</strong>. В статусе
                Утверждена для редактирования доступны только вкладки:{' '}
                <strong>Документы, Журнал сообщений, Финансовая поддержка</strong>
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Пользователь имеет право удалить только ту публикацию, которую добавил и если она в статусах{' '}
                <strong>Черновик</strong> или <strong>Добавлена</strong>. С помощью кнопки удаляет выделенную в таблице публикацию
              </td>
            </tr>
            <tr>
              <td>
                Редактировать связь публикации с подразделениями: <Icon type="connection" />
              </td>
              <td>
                Публикация может относиться к нескольким подразделениям и используется в отчёте каждого из указанных подразделений
              </td>
            </tr>
            <tr>
              <td>
                Утверждение публикации: <Icon type="like" />
              </td>
              <td>
                Открывает форму для процедуры Утверждения. Ответственный за качество данных проверяет данные о публикации на
                достоверность и полноту, дополняет и исправляет неточности и изменяет статус публикации на{' '}
                <strong>«Утверждена»</strong> — как правило, в отчётах учитываются, только утверждённые записи. Система фиксирует
                в журнале сообщения в публикации кто и когда утвердил запись
              </td>
            </tr>
            <tr>
              <td>
                Финансовая поддержка публикации: <Icon type="currency" />
              </td>
              <td>
                В случае, если статья является результатом выполнения научного проекта, необходимо выбрать из списка Научных
                проектов. Ответственный по качеству научных отчётов при наличии прав, Принимает к отчёту по проекту указанную
                публикацию. Принятые к отчёту по проекту публикации отображаются на форме проекта на вкладке{' '}
                <strong>Результаты проекта, Публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Указать DOI статьи: <Icon type="badge" />
              </td>
              <td>
                Открывает форму для ввода идентификатора DOI публикации. Введённое значение будет отображаться на форме публикации
                на вкладке <strong>О публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Указать связь между препринтом и публикацией: <Icon type="transport" />
              </td>
              <td>
                Функциональная кнопка открывает форму связи между препринтом и опубликованной статьёй. Данные будут отображаться в
                пункте меню <strong>Препринты</strong>, в столбце таблицы <strong>Публикация</strong>
              </td>
            </tr>
            <tr>
              <td>
                Связи публикации с другими объектами: <Icon type="relation" />
              </td>
              <td>
                Открывает модальное окно, в котором отображается список записей связанных с публикацией.
                <div className="warning">
                  <span>Прежде чем удалить публикацию, необходимо удалить все связи с другими объектами</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Показатели публикации: <Icon type="statistic" />
              </td>
              <td>
                Открывает форму с наукометрическими показателями публикации в базах цитирования. Добавить значение показателя
                возможно только при наличии ID публикации в базе цитирования.
              </td>
            </tr>
            <tr>
              <td>
                Показатели источника: <Icon type="statistic" mode="view" />
              </td>
              <td>
                Открывает форму с наукометрическими показателями издания публикации (журнал, сборник, база препринтов) в базах
                цитирования.
              </td>
            </tr>
            <tr>
              <td>
                Указать статью-оригинал/переводную статью: <Icon type="lang" />
              </td>
              <td>
                В случае, если в систему добавлены 2 варианта статьи в переводном журнале и оригинальном, желательно указать явную
                связь между этими статьями. Функциональная кнопка открывает форму связи между оригинальной и переводной статьёй.
                Библиографическая ссылка связанной статьи будет отображаться на форме публикации, на вкладке{' '}
                <strong>О публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Трансформация: <Icon type="transform" />
              </td>
              <td>Функциональная кнопка открывает форму для преобразования статьи в журнале в «препринт»</td>
            </tr>
            <tr>
              <td>
                Отправить в библиотеку: <Icon type="library" mode="arrowRight" />
              </td>
              <td>
                Отправить публикацию для ведения электронного каталога в библиотеке (при условии, если есть интеграция с
                информационной системой библиотеки)
              </td>
            </tr>
            <tr>
              <td>
                Открыть публикацию во внешнем источнике: <Icon type="link" />
              </td>
              <td>
                Перейти по ссылке на страницу с публикацией в других Интернет-ресурсах (DOI, электронный каталог Научной
                библиотеки, др.). Ссылка открывается в новом окне браузера
              </td>
            </tr>
            <tr>
              <td>
                Скачать файл прикрепленный к публикации: <Icon type="download" />
              </td>
              <td>
                Скачанный файл помещается в папку системную папку <strong>Загрузки</strong> на устройстве пользователя
              </td>
            </tr>
            <tr>
              <td>
                Отчеты: <Icon type="print" />
              </td>
              <td>Открывает список шаблонов для формирования регламентированных отчётов</td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список публикаций выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Функциональная кнопка открывает форму для добавления сообщения. Все сообщения хранятся в Системе, на странице{' '}
                <strong>Главная, Вопросы от пользователей администраторам</strong>. Список сообщений конкретного пользователя и
                ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
                <strong>Новости, Вопрос - ответ</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

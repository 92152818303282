import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import {
  GetProjectPerformerJobPeriodList,
  GetSalaryByFundCardList,
  GetSalaryByProjectList,
  GetSalaryInsuranceList,
  GetSalaryVacationList,
} from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function Salary() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['finance', 'salary'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Оплата по шифрам проектов" id="list">
          <DataGrid specification={GetSalaryByProjectList()} />
        </Tab>
        <Tab title="Оплата из накладных" id="fundcard">
          <DataGrid specification={GetSalaryByFundCardList()} />
        </Tab>
        <Tab title="Страховые взносы" id="insurances">
          <DataGrid specification={GetSalaryInsuranceList()} />
        </Tab>
        <Tab title="Список участников проектов" id="performers">
          <DataGrid specification={GetProjectPerformerJobPeriodList({ title: 'Список участников проектов' })} />
        </Tab>
        <Tab title="Отпуска и характер работы" id="vacations">
          <DataGrid specification={GetSalaryVacationList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import React, { useMemo } from 'react';

import { Form as F } from 'types/models';

import { NirRequestLocalType } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel';

import { RequestType } from 'utils/Enums';
// eslint-disable-next-line max-len
import { Component as ProgramRequestViewTemplate } from 'features/Table/specifications/GetRequestList/LeftPanelForThirdLevel/modalTemplates/RequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as MobileRequestViewTemplate } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/modalTemplates/MobileRequestViewTemplate';
// eslint-disable-next-line max-len
import { Component as NirRequestViewTemplate } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel/modalTemplates/NirRequestViewTemplate';

type DetailProps = {
  requestType: RequestType | null;
  specification: string | null;
};

const RequestViewTemplate: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  requestType,
  specification,
}: F.TemplateProps & DetailProps) => {
  const nirRequest = useMemo<NirRequestLocalType>(
    () => ({
      id: id || null,
      specification: specification ?? 'BASIC_RESEARCH',
    }),
    [id, specification],
  );

  return (
    <>
      {isOpen && (
        <>
          {requestType === RequestType.NIR_REQUEST && (
            <NirRequestViewTemplate
              isOpen
              onClose={onClose}
              relatedTableState={relatedTableState}
              nirRequest={nirRequest}
              id={id}
            />
          )}
          {requestType === RequestType.PROGRAM_REQUEST && (
            <ProgramRequestViewTemplate isOpen onClose={onClose} id={id} relatedTableState={relatedTableState} />
          )}
          {requestType === RequestType.MOBILE_REQUEST && (
            <MobileRequestViewTemplate isOpen onClose={onClose} id={id} relatedTableState={relatedTableState} type="NPR" />
          )}
        </>
      )}
    </>
  );
};

export const Component = React.memo(RequestViewTemplate);

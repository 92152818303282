import React from 'react';

import { FormComponent, Select, SelectMode } from 'components';

import { Performer } from 'types/models/ProgramRequest';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
  isEditPerformersOnly?: boolean;
  nameTooltip?: string;
  roleTooltip?: string;
};

function PerformerInputs({ entry, onFieldChange, isEditPerformersOnly, nameTooltip, roleTooltip }: Props) {
  const { handlePersonChange, handleRoleChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" info={<>{nameTooltip}</>} isRequired>
          <SelectPerson member={entry} onUpdateMember={val => handlePersonChange()(val)} hiddenButtons={['delete']} />
        </FormComponent.Field>
        {!isEditPerformersOnly && (
          <FormComponent.Field label="Роль" info={<>{roleTooltip}</>} isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={entry?.role}
              settings={{ name: 'RequestPerformerRole' }}
              onChange={handleRoleChange()}
            />
          </FormComponent.Field>
        )}
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { PerformerInputs };

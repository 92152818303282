import { ButtonProps } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useCallback, useMemo, useState } from 'react';
import { Report } from 'types/models';

type Props = {
  saveForm?(): void;
  closeForm?(): void; // not used
};

export function useController({ saveForm }: Props) {
  const { currentPerson } = useAppDataContext();

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

  const reports = useMemo<Report[]>(
    () => [
      Reports.Personal,
      Reports.PublicationList,
      Reports['PublicationForm2.3'],
      Reports.PublicationExtendedList,
      Reports.CertificatParticipation,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: saveForm,
        isDisabled: saveForm === undefined,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
      {
        icon: { type: 'admin' },
        title: 'Задать вопрос',
        onClick: handleQuestionButtonClick,
      },
    ],
    [saveForm, handleQuestionButtonClick, getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    currentPerson,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
  };
}

import React from 'react';

import { FormComponent, ListEdit, SectionTitle, TextArea, TextAreaMode, TextInput } from 'components';

import { Form, Rntd, Table } from 'types/models';
import { ValueOf } from 'types/helpers';
import { showErrors } from 'utils/Validators';
import { splittedHistoryLabel } from 'features/Form/views/AboutAuthorship/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { validateAuthor, validateDepartment } from './validate';
import { RntdSubjects } from './RntdSubjects';
import { AuthorInputs } from './AuthorInputs';
import { useController } from './controller';
import { DepartmentInputs } from './DepartmentInputs';

type Props = {
  rntd?: Rntd | null;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof Rntd): (value: ValueOf<Rntd>) => void;
  disabled?: boolean;
  applicants: string;
};

export function AboutRntd({ rntd, formFields, makeChangeHandler, disabled, applicants }: Props) {
  const {
    projectOriginal,
    projectSpecification,
    extraToolbarButtons,
    projectRowConverter,
    handleAuthorsChange,
    handleDepartmentsChange,
  } = useController({
    rntd,
    formFields,
    makeChangeHandler,
  });

  const { settings } = useAppDataContext();

  const getSplittedHistoryLabel = (x: any) =>
    splittedHistoryLabel(
      {
        ...x,
        name: x.name || '',
        isAffilated: false,
        category: null,
        historyLabel: '',
        affilateCount: null,
        position: 0,
        enterprise: null,
        memberAffilations: [],
      },
      settings,
    );

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '50%' }}>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.code.title} isRequired={formFields.code.required}>
            <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={!!disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.name.title }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.description.title} isRequired={formFields.description.required}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.description.title }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.scope.title} isRequired={formFields.scope.required}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.scope.title }}
              value={formFields.scope.value}
              onChange={formFields.scope.onChange}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.keyWords.title} isRequired={formFields.keyWords.required}>
            <FormComponent.Text>{formFields.keyWords.value}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Охрана РИД">
            <FormComponent.Text>{rntd?.securityDocuments ? rntd?.securityDocuments[0]?.label : ''}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.project.title} isRequired={formFields.project.required}>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Научные проекты',
                isClearable: !formFields.project.required,
                table: {
                  specification: projectSpecification,
                  onSelect: (row: Table.Entry | null) => formFields.project.onChange(row ? projectRowConverter(row) : null),
                },
              }}
              value={projectOriginal?.name}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <RntdSubjects rntd={rntd} makeChangeHandler={makeChangeHandler} isDisabled={!!disabled} />
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column style={{ width: '50%' }}>
        <SectionTitle title="Авторы" isRequired />

        <FormComponent.Field>
          <ListEdit
            rows={rntd?.authors || []}
            onChange={handleAuthorsChange}
            toolbar={['add', 'edit', 'delete']}
            withMessages
            columns={[
              { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '30%' } },
              {
                label: 'Должность',
                formatValue: x => {
                  const result = getSplittedHistoryLabel(x);
                  return result.rank || result.error || '';
                },
                styles: { width: '25%' },
              },
              {
                label: 'Место работы',
                formatValue: x => {
                  const result = getSplittedHistoryLabel(x);
                  return result.job || result.error || '';
                },
                styles: { width: '45%' },
              },
            ]}
            isDisabled={disabled}
            maxHeight="600px"
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
              validation: {
                checkIsValid: a => validateAuthor(a).every(x => x.isValid),
                onInvalidate: val => showErrors(validateAuthor, val),
              },
            }}
          />
        </FormComponent.Field>

        <SectionTitle title="Правообладатели" />

        <FormComponent.Field>
          <FormComponent.Text>{applicants}</FormComponent.Text>
        </FormComponent.Field>

        <SectionTitle title="Результат получен на базе" />

        <ListEdit
          rows={rntd?.departments || []}
          onChange={handleDepartmentsChange}
          toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
          columns={[
            { label: 'Факультет/Институт', formatValue: x => x?.governance?.label || '' },
            { label: 'Подразделение', formatValue: x => x?.unit?.label || '' },
          ]}
          withMessages
          isDisabled={disabled}
          maxHeight="600px"
          specification={{
            mode: 'customComponent',
            renderComponent: (row, onChange) => <DepartmentInputs department={row} onDepartmentChange={onChange} />,
            validation: {
              checkIsValid: dep => validateDepartment(dep).every(x => x.isValid),
              onInvalidate: val => showErrors(validateDepartment, val),
            },
          }}
        />
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

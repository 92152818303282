import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useMemo } from 'react';

import { ButtonProps } from 'components';

import { Report } from 'types/models';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft?(): void;
  viewMode?: boolean;
  isDisabled: boolean;
};

export function useController(props: Props) {
  const { onSave, onSaveDraft, viewMode, isDisabled } = props;

  const reports = useMemo<Report[]>(() => [Reports.EventConsolidatedListRequest, Reports.DepartmentConferenceReport], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => {
          onSave(true);
        },
        isDisabled: !!viewMode || isDisabled,
      },
      ...(onSaveDraft
        ? [
            {
              icon: { type: 'save', mode: 'add' },
              title: 'Сохранить и продолжить',
              onClick: () => {
                onSaveDraft();
              },
              isDisabled: !!viewMode || isDisabled,
            },
          ]
        : []),
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [viewMode, isDisabled, getReports, handleSetCurrentReport, onSave, onSaveDraft],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

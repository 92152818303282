import { Icon } from 'components';

export function PersonalContestParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Участие в конкурсах</strong> предназначен для добавления, редактирования и удаления участия в конкурсах,
        конкурсных проектов и наград.
      </p>

      <h4>В разделе Участие в конкурсах вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись об участии в конкурсе и информацию на всех вкладках с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новое участие в конкурсе с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Добавить новый конкурс с помощью кнопки <Icon type="add" /> на вкладке «Участие в конкурсе» при создании нового участия.
        </li>
        <li>
          Редактировать запись об участии в конкурсе с помощью кнопки <Icon type="edit" /> в статусе Добавлена. Кнопка доступна,
          если вы являетесь участником
        </li>
        <li>
          Удалить запись об участии в конкурсах, которую вы создавали, если она в статусе Добавлена с помощью кнопки{' '}
          <Icon type="remove" /> Кнопка доступна, если вы являетесь участником
        </li>
        <li>
          Выгрузить список участий в конкурсах в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>Добавить информацию о конкурсном проекте (работе) на вкладке «Участие в конкурсе»</li>
        <li>
          Добавить награды при редактировании записи об участии в конкурсе или посмотреть награды участников конкурса с помощью
          кнопки <Icon type="award" />
        </li>
        <li>
          Редактировать связь с подразделением с помощью кнопки <Icon type="connection" />
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи об участии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите документ подтверждающий участие. Ответственное лицо проверит и утвердит
        участие.
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои участия»</strong> содержит список ваших участий. Если вы создавали запись об участии в конкурсе, но
          не являетесь участником, запись будет отображаться в разделе «Все участия».
        </li>
        <li>
          <strong>Фильтр «Все участия»</strong> отображает список всех участий в конкурсах.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список участий в конкурсах, сроки проведения которых содержат указанный год. По
          умолчанию выбран текущий год.
        </li>
      </ul>

      <div>
        Если у вас есть вопрос или вы нашли ошибку в Утвержденном участий - напишите Администратору <Icon type="question" />
      </div>
    </>
  );
}

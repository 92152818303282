import React, { useCallback } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

type Props = {
  handleSave: () => void;
  handleSaveAsDraft?: () => void;
  handleSaveAndContinue?: () => void;
  handleCancel?: () => void;
  viewMode?: boolean;
  isOnlyEditing?: boolean;
  isDisabled?: boolean;
};

function Toolbar({
  handleSave,
  handleSaveAsDraft,
  handleSaveAndContinue,
  handleCancel,
  isOnlyEditing,
  viewMode: viewModeProp,
  isDisabled = false,
}: Props) {
  const viewMode = isOnlyEditing ? false : viewModeProp;

  const handleAddButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      handleSave();
    },
    [handleSave],
  );

  const handleSaveAsDraftButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (handleSaveAsDraft) {
        handleSaveAsDraft();
      }
    },
    [handleSaveAsDraft],
  );

  const handleAddAndContinueButtonClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
      if (handleSaveAndContinue) {
        handleSaveAndContinue();
      }
    },
    [handleSaveAndContinue],
  );

  const buttons: ButtonProps[] = [
    {
      icon: { type: 'save' },
      title: 'Сохранить',
      onClick: handleAddButtonClick,
      isDisabled: !!viewMode || isDisabled,
    },
    ...(handleSaveAndContinue
      ? [
          {
            icon: { type: 'save', mode: 'add' },
            title: 'Сохранить и продолжить',
            onClick: handleAddAndContinueButtonClick,
            isHidden: isOnlyEditing,
            isDisabled: !!viewMode || isDisabled,
          },
        ]
      : []),
    ...(handleSaveAsDraft
      ? [
          {
            icon: { type: 'new' },
            title: 'Сохранить как черновик',
            onClick: handleSaveAsDraftButtonClick,
            isHidden: isOnlyEditing,
            isDisabled: !!viewMode || isDisabled,
          },
        ]
      : []),
    ...(handleCancel
      ? [
          {
            icon: { type: 'cancel' },
            title: 'Отмена',
            onClick: handleCancel,
          },
        ]
      : []),
  ];

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export const ControlPanel = React.memo(Toolbar);

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { PersonalRecognitionGridHelp } from './help';

type WrapperProps = {
  recognitionsFilter: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ recognitionsFilter }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewButtonDisabled,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isEditButtonDisabled,
      isEditFormOpen,
      handleTemplateCloseEditForm,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      isDeleteConfirmPopupOpen,
      isDeleteButtonDisabled,
      handleCloseDeleteConfirmPopup,
      handleConfirmDeleteConfirmPopup,
      buttons,
      member,
      handleTemplateCloseHelpForm,
      isHelpFormOpen,
    } = useController({ tableState, recognitionsFilter });

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewButtonDisabled && (
          <modalTemplates.RecognitionViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}

        {!isEditButtonDisabled && (
          <modalTemplates.RecognitionEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            memberEditLocked={true}
          />
        )}

        <modalTemplates.RecognitionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
          name={nextPublicationName}
          member={member}
          memberEditLocked={true}
        />

        {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: handleCloseDeleteConfirmPopup,
              },
            ]}
            size="small"
          >
            <>({tableState.selectedRows[0]?.Award}) Удалить признание/награду?</>
          </Modal>
        )}

        <Modal
          mode="help"
          title="Помощь в разделе Признания и награды"
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          {PersonalRecognitionGridHelp()}
        </Modal>
      </>
    );
  });

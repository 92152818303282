import { Table } from 'types/models';

type RequestData = {
  notificationLogId: string;
};

export function GetNotificationLogRecipientList(requestData: RequestData): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetNotificationLogRecipientList',
    header: {
      firstLevel: {
        title: 'Список получателей письма',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData,
  };
}

import React, { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, Toolbar } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';

import { State } from '../makeUseCustomController';

type WrapperProps = {
  isDisabled?: boolean;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

const LeftPanelForThirdLevel = ({ isDisabled }: WrapperProps) =>
  React.memo(({ tableState, customState }: Props) => {
    const selectedRowsLength = tableState.selectedRows.length;
    const rowsAvailability: Table.ToolbarStateRowsAvailability = {
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    };

    const isProcessButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

    const { methods: processProjectChanges } = BackendAPI.useBackendAPI('ProcessProjectChanges');

    const tableStreams = useLocalTableStreams();

    const handleProcessButtonClick = useCallback(() => {
      processProjectChanges.callAPI(
        { ids: [tableState.selectedRows[0]?.ChangeInProjectId] },
        {
          onSuccessfullCall: () => {
            tableStreams.reloadTable.push({});
            showNotification({ message: 'Изменения успешной обработаны', theme: 'success' });
          },
        },
      );
    }, [tableState, processProjectChanges, tableStreams]);

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'check' },
          title: 'Отметить обработку изменений',
          onClick: handleProcessButtonClick,
          permission: { name: Permits.PROJECT_CODE_PROCESSING_EDIT },
          isDisabled: isDisabled || isProcessButtonDisabled,
          isHidden: customState.isProcessButtonHidden,
        },
      ],
      [handleProcessButtonClick, isProcessButtonDisabled, customState.isProcessButtonHidden],
    );

    return <Toolbar buttons={buttons} />;
  });

export const Component = LeftPanelForThirdLevel;

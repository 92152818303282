import React, { useEffect } from 'react';

import {
  FormComponent,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  Toolbar,
} from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';

import { Form, Table } from 'types/models';
import { Item } from 'types/models/common';
import { PROJECT_PERFORMER_ORDER_TOOLTIP_TXT } from 'types/models/Project';
import { formatOrderPerformer } from './helpers';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectPerformerOrderForm({ viewMode, editMode, onClose }: Props) {
  const {
    look: { setTitle, projectSelectable },
  } = useFormContext<Form.ProjectPerformerOrderFormLook>();

  const {
    performerOrder,
    buttons,
    workMode,
    makeChangeHandler,
    disabled,
    startDatePerformers,
    endDatePerformers,
    projectSpecification,
    projectRowConverter,
    projectOriginal,
    handleProjectChange,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return `Приказ о научном коллективе`;
        }

        return getStatusMessage(
          [workMode === 'viewMode' ? 'Просмотр' : 'Редактирование', 'Приказа о научном коллективе проекта']
            .filter(Boolean)
            .join(' '),
          performerOrder?.project?.id,
        );
      })(),
    );
  }, [performerOrder, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column style={{ width: '50%' }}>
            {!!projectSelectable && (
              <FormComponent.Line>
                <FormComponent.Field label="Проект" isRequired>
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: 'Научные проекты',
                      isClearable: false,
                      table: {
                        specification: projectSpecification,
                        onSelect: (row: Table.Entry | null) => handleProjectChange(row ? projectRowConverter(row) : null),
                      },
                    }}
                    value={projectOriginal?.name}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
            <FormComponent.Line>
              <FormComponent.Field label="Номер приказа" isRequired>
                <TextInput value={performerOrder.number || ''} onChange={makeChangeHandler('number')} isDisabled={disabled} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Тема приказа" isRequired>
                <Select
                  mode={SelectMode.ENUM}
                  value={performerOrder.type}
                  settings={{ name: 'ProjectPerformerOrderType' }}
                  onChange={(option: Item) => makeChangeHandler('type')(option)}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Дата приказа" isRequired>
                <TextDateTime value={performerOrder.date} onChange={makeChangeHandler('date')} isDisabled={disabled} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Текст приказа" tooltip={PROJECT_PERFORMER_ORDER_TOOLTIP_TXT}>
                <TextArea
                  mode={TextAreaMode.WYSIWYG}
                  settings={{ rows: 10, title: 'Текст приказа' }}
                  value={performerOrder.order || ''}
                  onChange={makeChangeHandler('order')}
                  isDisabled={!!disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>

          <FormComponent.Column style={{ width: '50%' }}>
            {performerOrder?.id ? (
              <>
                <SectionTitle
                  title={
                    'Приказ указан для даты начала работы в проекте у следующих членов коллектива (' +
                    startDatePerformers.length +
                    ')'
                  }
                />
                <FormComponent.ColumnWrapper>
                  <FormComponent.Column style={{ width: '50%' }}>
                    {startDatePerformers.slice(0, Math.floor(startDatePerformers.length / 2)).map((x, index) => (
                      <FormComponent.Line key={index}>
                        <FormComponent.Text>{formatOrderPerformer(x, performerOrder, true)}</FormComponent.Text>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>

                  <FormComponent.Column style={{ width: '50%' }}>
                    {startDatePerformers
                      .slice(Math.floor(startDatePerformers.length / 2), startDatePerformers.length)
                      .map((x, index) => (
                        <FormComponent.Line key={index}>
                          <FormComponent.Text>{formatOrderPerformer(x, performerOrder, true)}</FormComponent.Text>
                        </FormComponent.Line>
                      ))}
                  </FormComponent.Column>
                </FormComponent.ColumnWrapper>
                <SectionTitle
                  title={
                    'Приказ указан для даты окончания работы в проекте у следующих членов коллектива (' +
                    endDatePerformers.length +
                    ')'
                  }
                />
                <FormComponent.ColumnWrapper>
                  <FormComponent.Column style={{ width: '50%' }}>
                    {endDatePerformers.slice(0, Math.floor(endDatePerformers.length / 2)).map((x, index) => (
                      <FormComponent.Line key={index}>
                        <FormComponent.Text>{formatOrderPerformer(x, performerOrder, false)}</FormComponent.Text>
                      </FormComponent.Line>
                    ))}
                  </FormComponent.Column>

                  <FormComponent.Column style={{ width: '50%' }}>
                    {endDatePerformers
                      .slice(Math.floor(endDatePerformers.length / 2), endDatePerformers.length)
                      .map((x, index) => (
                        <FormComponent.Line key={index}>
                          <FormComponent.Text>{formatOrderPerformer(x, performerOrder, false)}</FormComponent.Text>
                        </FormComponent.Line>
                      ))}
                  </FormComponent.Column>
                </FormComponent.ColumnWrapper>
              </>
            ) : (
              <></>
            )}
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectPerformerOrderForm);

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;
  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = selectedRows[0] || null;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: deleteCashDocument } = BackendAPI.useBackendAPI('DeleteCashDocument');

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteCashDocument.callAPI(
      selectedRows.map(row => row.id),
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Запись удалена', theme: 'success' });

          tableStreams.reloadTable.push({});
        },
      },
    );
    setIsDeleteConfirmPopupOpen(false);
  }, [deleteCashDocument, selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.CASH_DOCUMENT_RECEIVING_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permission: { name: Permits.CASH_DOCUMENT_RECEIVING_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.CASH_DOCUMENT_RECEIVING_EDIT },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.CASH_DOCUMENT_RECEIVING_DELETE },
        isDisabled: !selectedRow,
      },
    ],
    [selectedRow, selectedRows.length],
  );

  return {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
    selectedRows,
    isHelpOpen,
    setIsHelpOpen,
  };
}

import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Documents } from 'features/Documents';
import { RecordStatus } from 'utils/Enums';
import { EventTypes } from 'utils/Enums/EventTypes';
import { EventTypeCode } from 'types/models/Event';
import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  id: string;
  typeCode: EventTypeCode;
  onClose: () => void;
};

const ApproveEventComponent = ({ id, typeCode, onClose }: Props) => {
  const { eventData, handleApproveEvent, handleDocumentsChange, organizationName } = useController({
    onClose,
    id,
    typeCode,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Мероприятие:</strong> {EventTypes[`EVENT_${typeCode}`].label}:{' '}
          {getStatusRecordMessage(getEventFullName(eventData), eventData?.status?.value, eventData?.status?.label)}
        </FormComponent.Text>
      </FormComponent.Field>
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Сайт:</strong> {eventData?.site || '-'}
        </FormComponent.Text>
      </FormComponent.Field>
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Формат проведения и уровень:</strong>{' '}
          {[eventData?.format?.label, eventData?.eventStatus?.label].filter(Boolean).join(', ')}
        </FormComponent.Text>
      </FormComponent.Field>
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Организаторы:</strong>{' '}
          {[
            ...(eventData?.departments?.map(d => `${d.element?.label} (${organizationName})`) || []),
            ...(eventData?.enterprises?.map(e => e.element?.label) || []),
          ]
            .filter(Boolean)
            .join(', ')}
        </FormComponent.Text>
      </FormComponent.Field>
      <Documents
        title="Подтверждающие документы"
        documents={eventData?.documents || []}
        setDocuments={docs => handleDocumentsChange(docs)}
        disabled={false}
        maxHeight="490px"
      />
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text={eventData?.status?.value === RecordStatus.APPROVED ? 'Перевести в статус Добавлена' : 'Утвердить'}
          onClick={handleApproveEvent}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(ApproveEventComponent);

import React from 'react';
import * as modalTemplatesCode from 'features/Table/specifications/GetProjectCodeList/LeftPanelForThirdLevel/modalTemplates';

import {
  ButtonMode,
  FormComponent,
  Modal,
  RelationTableModal,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  Toolbar,
} from 'components';

import useController from './controller';
import { Table } from 'types/models';
import { GetFundCardList } from 'features/Table/specifications/GetFundCardList';
import { GetKindConsumptionList } from 'features/Table/specifications/GetKindConsumptionList';
import { SubmitTable } from 'features/Table/streams';
import { ProjectCodeOverheadGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isCreateModalOpen,
    closeCreateModal,
    isFundModalOpen,
    setIsFundModalOpen,
    selectFundCardId,
    isHasEmptyProjectCode,
    setIsHasEmptyProjectCode,
    changeFundCards,
    createInfo,
    setCreateInfo,
    confirmCreateModal,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Список Шифров проектов для проверки связи с Шифром НР и сметой на год"
        size="medium"
      >
        {ProjectCodeOverheadGridHelp()}
      </Modal>

      <RelationTableModal
        isOpen={isFundModalOpen}
        onClose={() => setIsFundModalOpen(false)}
        modalTitle="Выберите шифр накладных расходов"
        specification={GetFundCardList({
          hasSelectButton: true,
          onSubmitTable: ({ selectedRows }: SubmitTable) => {
            selectFundCardId(selectedRows[0]?.id);
          },
        })}
      />

      <Modal
        mode="warning"
        title="Внимание"
        isOpen={isHasEmptyProjectCode}
        onClose={() => setIsHasEmptyProjectCode(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => changeFundCards(),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsHasEmptyProjectCode(false),
          },
        ]}
        size="small"
      >
        <>
          Среди выбранных записей есть проекты без шифров, такие записи будут игнорированы, продолжить операцию установки шифра
          ЦФ?
        </>
      </Modal>

      <Modal
        title="Создать смету для выделенной группы проектов"
        isOpen={isCreateModalOpen}
        onClose={closeCreateModal}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmCreateModal,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeCreateModal,
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Год сметы" isRequired>
            <TextInput
              value={createInfo.year}
              onChange={e => setCreateInfo(prev => ({ ...prev, year: e }))}
              mode={TextInputMode.YEAR}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата создания" isRequired>
            <TextDateTime value={createInfo.createdDate} onChange={e => setCreateInfo(prev => ({ ...prev, createdDate: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Шифр ЦФ" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Шифры ЦФ',
                isClearable: false,
                table: {
                  specification: GetFundCardList({}),
                  onSelect: (row: Table.Entry | null) =>
                    setCreateInfo(prev => ({ ...prev, fundCard: row ? { id: row.id, value: row.Name } : null })),
                },
              }}
              value={createInfo?.fundCard?.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расхода" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Виды расходов',
                isClearable: false,
                table: {
                  specification: GetKindConsumptionList({}),
                  onSelect: (row: Table.Entry | null) =>
                    setCreateInfo(prev => ({
                      ...prev,
                      kindConsumption: row ? { id: row.id, value: row.kindConsumption } : null,
                    })),
                },
              }}
              value={createInfo?.kindConsumption?.value}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>

      <modalTemplatesCode.ProjectCodeViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={() => setIsViewFormOpen(false)}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      <modalTemplatesCode.ProjectCodeEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={() => setIsEditFormOpen(false)}
        id={tableState.selectedRows[0]?.id}
        replaceMode={false}
        relatedTableState={tableState}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

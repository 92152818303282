import { Grnti, Community, Vak, CommunityMember } from 'types/models';

import { Settings } from 'types/models/common';
import { convertServerAffiliation } from '../author/converters';
import {
  convertMaybeArray,
  convertReference,
  getId,
  getText,
  getBoolean,
  convertItem,
  convertDocument,
} from '../commonConverters';
import { Magazine } from 'types/models/Article';
import { convertCommunityMember } from '../communityMember/converters';

export function convertCommunity(community: any, settings: Settings): Community {
  const grntis = community.Grntis?.Grnti ?? [];
  const vaks = community.Vaks?.Vak ?? [];
  const members = community.Members?.Member || [];
  const documents = community.Documents?.Document || [];

  return {
    id: getId(community),
    type: convertItem(community.Type),
    code: getText(community.Code),
    foundationDate: getText(community.FoundationDate),
    isLocal: getBoolean(community.IsLocal),
    grntis: convertMaybeArray(grntis, convertGrnti),
    vaks: convertMaybeArray(vaks, convertVak),
    affiliation: community.Affiliation ? convertServerAffiliation(community, settings) : null,
    members: convertMaybeArray(members, m => convertCommunityMember(m, settings)).sort(
      (a: CommunityMember, b: CommunityMember) => (a.role?.position || 0) - (b.role?.position || 0),
    ),
    documents: convertMaybeArray(documents, i => convertDocument('local', i)),
    isApproved: community.Status === 'APPROVED',
    orderNumber: getText(community.OrderNumber),
    orderDate: getText(community.OrderDate),
    endDate: getText(community.EndDate),
    note: getText(community.Note),
    ...(community.Magazine ? { magazine: convertCommunityMagazine(community.Magazine) } : {}),
  };
}

function convertCommunityMagazine(mag: any): Magazine {
  return {
    id: getId(mag),
    name: getText(mag.Name),
    type: { label: '', value: '' },
  };
}
function convertGrnti(grnti: any): Grnti {
  return {
    id: getId(grnti),
    element: convertReference(grnti.Element),
  };
}

function convertVak(vak: any): Vak {
  return {
    id: getId(vak),
    element: convertReference(vak.Element),
  };
}

import React, { useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function Toolbar(props: Props) {
  const { tableState, onSubmitTable } = props;

  const [row] = tableState.selectedRows;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: () => {
          if (tableState && onSubmitTable) {
            onSubmitTable(tableState);
          }
        },
        isDisabled: !row,
      },
    ],
    [row, tableState, onSubmitTable],
  );

  return <SharedToolbar buttons={buttons} />;
}

export { Toolbar };

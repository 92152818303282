import { useState, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Estimate, Form, Table } from 'types/models';
import { Original } from 'types/models/Form';
import { GetKindConsumptionList } from 'features/Table/specifications';
import { EstimatePosition, EstimatePositionInput, EstimatePositionItem, EstimatePositionRow, Tax } from 'types/models/Estimate';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { GetFundCardSelectList } from 'features/Table/specifications';
import { validateRequest } from './validate';
import { showErrorsMessages } from 'utils/Common';
import { KindConsumption } from 'types/models/KindConsumption';

type KindConsumptionRow = {
  id: string;
  name: string;
  kvr: string;
  kosgu: string;
  isCalculateTax: boolean;
  isSendOverhead: boolean;
};

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export enum TAX {
  TAX = 'tax',
  OVERHEAD = 'overhead',
  DEFAULT = 'default',
}

export function useController({ viewMode, editMode, onClose }: Props) {
  const {
    look: { id, estimateId, fundCard: projectCodeFundCard },
  } = useFormContext<Form.EstimatePositionLook>();

  const workMode = useMemo<Table.WorkMode>(() => (editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode'), [
    editMode,
    viewMode,
  ]);
  const tableStreams = useLocalTableStreams();

  const [estimatePosition, setEstimatePosition] = useState<EstimatePosition>();
  const [overheadPercent, setOverheadPercent] = useState<number>(0);
  const [kindConsumptionId, setKindConsumptionId] = useState<string>('');
  const [fundCardId, setFundCardId] = useState<string>('');
  const [estimate, setEstimate] = useState<Estimate | null>(null);

  const voidLabel = useMemo(() => 'Не указано', []);

  const [kvrLabel, setKvrLabel] = useState<string>(
    `${estimatePosition?.kindConsumption?.kvr?.code || ''} ${estimatePosition?.kindConsumption?.kvr?.label || ''}`,
  );
  const [kosguLabel, setKosguLabel] = useState<string>(
    `${estimatePosition?.kindConsumption?.kosgu?.code || ''} ${estimatePosition?.kindConsumption?.kosgu?.label || ''}`,
  );

  const [taxKvrLabel, setTaxKvrLabel] = useState<string>(
    `${estimatePosition?.tax?.kindConsumption?.kvr?.code || ''} ${estimatePosition?.tax?.kindConsumption?.kvr?.label || ''}`,
  );
  const [taxKosguLabel, setTaxKosguLabel] = useState<string>(
    `${estimatePosition?.tax?.kindConsumption?.kosgu?.code || ''} ${estimatePosition?.tax?.kindConsumption?.kosgu?.label || ''}`,
  );

  const [kindConsumption, setKindConsumption] = useState<KindConsumption | null>(null);

  const [kindConsumptionOriginal, setKindConsumptionOriginal] = useState<Form.Original | null>({
    id: estimatePosition?.kindConsumption?.id || '',
    name: `${estimatePosition?.kindConsumption?.code || ''} ${estimatePosition?.kindConsumption?.name || ''}`,
  });

  const [taxKindConsumptionOriginal, setTaxKindConsumptionOriginal] = useState<Form.Original | null>({
    id: estimatePosition?.tax?.kindConsumption?.id || '',
    name: `${estimatePosition?.tax?.kindConsumption?.code || ''} ${estimatePosition?.tax?.kindConsumption?.name || ''}`,
  });

  const kindConsumptionSpecification = GetKindConsumptionList({ hasSelectButton: true, setKindConsumptionId });

  const kindConsumptionTableRowConverter = useCallback<(row: Table.Entry) => KindConsumptionRow>(row => {
    getKindConsumption.callAPI({ id: row.id });
    return {
      id: row.id || '',
      name: row.kindConsumption,
      kvr: row.kvr,
      kosgu: row.kosgu,
      isCalculateTax: row.isCalculateTax === 'true',
      isSendOverhead: row.isSendOverhead === 'true',
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [availableFundCardOriginal, setAvailableFundCardOriginal] = useState<Form.Original | null>({
    id: estimatePosition?.fundCard?.id || '',
    name: `${estimatePosition?.fundCard?.name || ''}`,
  });

  const availableFundCardSpecification = GetFundCardSelectList({ setFundCardId });

  const availableFundCardTableRowConverter = useCallback<(row: Table.Entry) => Original>(
    row => ({
      id: row.id,
      name: row.Name,
    }),
    [],
  );

  const [formFields, setFormFields] = useState<Form.Fields>({
    amount: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма, план',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          amount: { ...prevState.amount, value },
          taxAmount: { ...prevState.taxAmount, value: (value || 0) * ((prevState.taxPercent.value || 0) / 100) },
        }));
      },
    },
    isReduceOverhead: {
      value: '',
      isValid: true,
      required: true,
      title: 'Уменьшает накладные',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isReduceOverhead: { ...prevState.isReduceOverhead, value: !prevState.isReduceOverhead.value },
          taxIsReduceOverhead: { ...prevState.taxIsReduceOverhead, value: !prevState.isReduceOverhead.value },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      required: false,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
    kindConsumption: {
      value: '',
      isValid: true,
      required: false,
      title: 'Наименование статьи затрат',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          kindConsumption: { ...prevState.kindConsumption, value },
        }));
        setKindConsumption(value);
        setKindConsumptionOriginal(value);
        setKvrLabel(value?.kvr || voidLabel);
        setKosguLabel(value?.kosgu || voidLabel);
      },
    },
    fundCard: {
      value: '',
      isValid: true,
      required: false,
      title: 'Накладные передавать на шифр',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fundCard: { ...prevState.fundCard, value },
        }));
        setAvailableFundCardOriginal(value);
      },
    },
    taxPercent: {
      value: '',
      isValid: true,
      required: true,
      title: 'Процент налога',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          taxPercent: { ...prevState.taxPercent, value },
          taxAmount: { ...prevState.taxAmount, value: (prevState.amount.value || 0) * ((value || 0) / 100) },
        }));
      },
    },
    taxAmount: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма налога, план',
      onChange: value => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          taxAmount: { ...prevState.taxAmount, value },
          taxPercent: { ...prevState.taxPercent, value: ((value || 0) / (prevState.amount.value || 0)) * 100 },
        }));
      },
    },
    taxIsReduceOverhead: {
      value: '',
      isValid: true,
      required: false,
      title: 'Уменьшает накладные',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          taxIsReduceOverhead: { ...prevState.taxIsReduceOverhead, value: !prevState.taxIsReduceOverhead.value },
        }));
      },
    },
  });

  const handlePositionItemChange = useCallback(
    (value: EstimatePositionItem[]) => {
      if (setEstimatePosition) {
        setEstimatePosition(prevPosition => {
          return { ...prevPosition!, items: [...value] };
        });
      }
    },
    [setEstimatePosition],
  );

  const handleFieldChange = useCallback(
    (fieldName: keyof EstimatePositionRow) => (value: string | number | boolean | ReferenceItem | null | Original) => {
      setEstimatePosition(
        prevState =>
          ({
            ...prevState,
            [fieldName]: value,
          } as EstimatePosition),
      );
    },
    [],
  );

  const handleConsumptionKindChange = useCallback(
    () => (value: KindConsumptionRow | null) => {
      setKindConsumptionOriginal(value);
      setKvrLabel(value?.kvr || voidLabel);
      setKosguLabel(value?.kosgu || voidLabel);
    },
    [voidLabel],
  );

  const calculateTax = (tax: Tax) => {
    const textFields = tax.kindConsumption || tax;
    setTaxKindConsumptionOriginal({
      id: textFields.id || '',
      name: [textFields.code || '', textFields.name || ''].filter(Boolean).join(' '),
    });
    setTaxKvrLabel(textFields?.kvr?.id ? `${textFields?.kvr?.code || ''} ${textFields?.kvr?.label || ''}` : voidLabel);
    setTaxKosguLabel(textFields?.kosgu?.id ? `${textFields?.kosgu?.code || ''} ${textFields?.kosgu?.label || ''}` : voidLabel);
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      taxAmount: {
        ...prevState.taxAmount,
        value: tax.amount || 0,
      },
    }));
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      taxPercent: {
        ...prevState.taxPercent,
        value: tax.taxPercent || 0,
      },
    }));
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      taxIsReduceOverhead: {
        ...prevState.taxIsReduceOverhead,
        value: prevState.isReduceOverhead.value,
      },
    }));
  };

  const { methods: getKindConsumption } = BackendAPI.useBackendAPI('GetKindConsumption', {
    onSuccessfullCall: ({ data }) => {
      setKindConsumption(data);
      setKindConsumptionId(data.id);
      setKindConsumptionOriginal({ id: data.id, name: `${data.code} ${data.name}` });
      setKvrLabel(data?.kvr?.label ? data?.kvr?.label || '' : voidLabel);
      setKosguLabel(data?.kosgu?.label ? data?.kosgu?.label || '' : voidLabel);

      if (data.tax) {
        const { tax } = data;
        calculateTax({ ...tax, taxPercent: data.percentTax || 0.0 });
      }
    },
  });

  const { methods: getEstimatePosition } = BackendAPI.useBackendAPI('GetEstimatePosition', {
    onSuccessfullCall: ({ data }) => {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        amount: {
          ...prevState.amount,
          value: data?.amount,
        },
      }));
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        isReduceOverhead: {
          ...prevState.isReduceOverhead,
          value: data?.isReduceOverhead,
        },
      }));
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        note: {
          ...prevState.note,
          value: data?.note,
        },
      }));
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        kindConsumption: {
          ...prevState.kindConsumption,
          value: data?.kindConsumption,
        },
      }));
      if (data?.fundCard) {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fundCard: {
            ...prevState.fundCard,
            value: { id: data?.fundCard?.id, label: data?.fundCard?.name },
          },
        }));
        setAvailableFundCardOriginal({ id: data?.fundCard?.id || '', name: data?.fundCard?.name || '' });
      }
      setEstimatePosition(data as EstimatePosition);
      setKindConsumption(data?.kindConsumption as KindConsumption);
      setKindConsumptionOriginal({
        id: data?.kindConsumption?.id || '',
        name: `${data?.kindConsumption?.code} ${data?.kindConsumption?.name}`,
      });
      setKindConsumptionId(data?.kindConsumption?.id || '');
      setKvrLabel(
        data?.kindConsumption?.kvr?.id
          ? `${data?.kindConsumption?.kvr?.code || ''} ${data?.kindConsumption?.kvr?.label || ''}`
          : voidLabel,
      );
      setKosguLabel(
        data?.kindConsumption?.kosgu?.id
          ? `${data?.kindConsumption?.kosgu?.code || ''} ${data?.kindConsumption?.kosgu?.label || ''}`
          : voidLabel,
      );

      if (data.tax) {
        const { tax } = data;
        calculateTax(tax);
      }
    },
  });

  const kindConsumptionEditMode: TAX = useMemo(() => {
    const result = (() => {
      if (kindConsumption?.isCalculateTax) {
        return TAX.TAX;
      }
      if (kindConsumption?.isSendOverhead) {
        if (!formFields.fundCard.value.id && !!projectCodeFundCard) {
          formFields.fundCard.onChange({ id: projectCodeFundCard.id, name: projectCodeFundCard.value });
        }
        return TAX.OVERHEAD;
      }
      return TAX.DEFAULT;
    })();
    return result;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindConsumption, projectCodeFundCard]);

  useEffect(() => {
    if (kindConsumptionEditMode === TAX.OVERHEAD) {
      if (estimate?.projectCode?.fundCard) {
        const obj = {
          id: estimate?.projectCode?.fundCard.id || '',
          name: estimate?.projectCode?.fundCard.name || '',
        };
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fundCard: {
            ...prevState.fundCard,
            value: { id: obj.id, label: obj.name },
          },
        }));
        setAvailableFundCardOriginal(obj);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kindConsumptionEditMode]);

  const { methods: getEstimate } = BackendAPI.useBackendAPI('GetEstimate', {
    onSuccessfullCall: ({ data }) => {
      setEstimate(data as Estimate);
    },
  });

  useLayoutEffect(() => {
    if (id && id !== 'undefined') {
      getEstimatePosition.callAPI({ id });
    }
    if (estimateId && estimateId !== 'undefined') {
      getEstimate.callAPI({ id: estimateId });
    }
    // eslint-disable-next-line
  }, [id, estimateId, workMode]);

  const { methods: saveEstimatePosition } = BackendAPI.useBackendAPI('SaveEstimatePosition');

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const request: EstimatePositionInput = {
        id: (estimatePosition?.id !== 'undefined' && estimatePosition?.id) || '',
        isReduceOverhead: formFields.isReduceOverhead.value,
        kindConsumptionId,
        amount: formFields.amount.value,
        estimateId,
        items: estimatePosition?.items || [],
      };

      if (formFields.note.value) {
        request.note = formFields.note.value;
      }

      if (kindConsumptionEditMode === TAX.TAX) {
        request.tax = {
          id: (estimatePosition?.tax?.id !== 'undefined' && estimatePosition?.tax?.id) || '',
          kindConsumption: taxKindConsumptionOriginal,
          amount: formFields.taxAmount.value,
          taxPercent: formFields.taxPercent.value,
          isReduceOverhead: formFields.taxIsReduceOverhead.value,
          items: estimatePosition?.tax?.items || [],
        } as Tax;
      }
      if (kindConsumptionEditMode === TAX.OVERHEAD) {
        request.fundCard = formFields.fundCard.value;
      }

      if (Number(request.amount) < request.items.reduce((accum, current) => accum + current.amount, 0)) {
        return showNotification({
          message: 'Сумма (план) по позиции, больше чем перечисление в "План расходования средств"',
          theme: 'danger',
        });
      }

      const validationInfo = validateRequest(request);

      const isValidForm = validationInfo.every(({ isValid }) => isValid);

      if (isValidForm) {
        return saveEstimatePosition.callAPI(request, {
          onSuccessfullCall: () => {
            showNotification({ message: 'Позиция успешно сохранена', theme: 'success' });
            tableStreams.reloadTable.push({});
            if (needClose) {
              onClose();
            }
          },
        });
      } else {
        const invalidInfo = validationInfo.filter(({ isValid }) => !isValid);
        return showErrorsMessages(invalidInfo.map(({ invalidMessage }) => invalidMessage));
      }
    },
    [
      estimatePosition,
      formFields,
      kindConsumptionId,
      estimateId,
      kindConsumptionEditMode,
      saveEstimatePosition,
      taxKindConsumptionOriginal,
      tableStreams.reloadTable,
      onClose,
    ],
  );

  const handleFormSubmit = useCallback(
    (needClose: boolean) => {
      onSubmit(needClose);
    },
    [onSubmit],
  );

  const diffByPositions = useMemo(() => {
    return (estimate?.positions || []).filter(x => x.isReduceOverhead).reduce((accum, { amount = 0 }) => accum + amount, 0);
  }, [estimate?.positions]);

  useEffect(() => {
    let overheadCorrectedPercent = 0;

    if (estimate?.amountByYear !== undefined && estimate?.amountByYear !== 0) {
      overheadCorrectedPercent = (formFields.amount.value / (estimate.calculation?.income?.plan - diffByPositions)) * 100;
    }
    setOverheadPercent(overheadCorrectedPercent);
  }, [diffByPositions, estimate, formFields]);

  const setMaxValue = useCallback(() => {
    if (estimate?.amountByYear !== undefined && estimate?.amountByYear !== 0) {
      formFields.amount.onChange(
        (((estimate?.calculation?.income?.plan - diffByPositions) * estimate?.normativeOverheadPercent) / 100).toString(),
      );
    }
  }, [diffByPositions, estimate, formFields.amount]);

  return {
    estimate,
    setEstimatePosition,
    handlePositionItemChange,
    kindConsumptionId,
    kindConsumptionEditMode,
    handleFormSubmit,
    estimatePosition,
    handleFieldChange,
    kindConsumptionSpecification,
    kindConsumptionTableRowConverter,
    kindConsumptionOriginal,
    handleConsumptionKindChange,
    kvrLabel,
    kosguLabel,
    formFields,
    overheadPercent,
    availableFundCardSpecification,
    availableFundCardTableRowConverter,
    availableFundCardOriginal,
    setAvailableFundCardOriginal,
    fundCardId,
    taxKindConsumptionOriginal,
    taxKvrLabel,
    taxKosguLabel,
    setMaxValue,
  };
}

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetPublicationListByScientist } from 'features/Table/specifications';

type Props = {
  personId?: string;
  isViewMode?: boolean;
};
export function Publications({ personId, isViewMode }: Props) {
  if (!personId) return null;
  return (
    <DataGrid
      specification={GetPublicationListByScientist({
        personId,
        workMode: isViewMode ? 'viewMode' : 'editMode',
        isFiltersHidden: true,
      })}
      hasPersonalMode
    />
  );
}

import React, { memo } from 'react';
import { Table as T } from 'types/models';

import { GetMagazineArticleList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  relatedTableState?: T.State;
  workMode: T.WorkMode;
  publicationId: string | undefined;
};

const Articles = ({ relatedTableState, workMode, publicationId }: Props) => (
  <>
    {relatedTableState && (
      <DataGrid
        specification={GetMagazineArticleList({
          templatesTableDependencies: {
            relatedTableAPIID: 'GetMagazineList',
            relatedTableState,
            relatedRecordId: publicationId || undefined,
          },
          workMode,
        })}
      />
    )}
  </>
);

export const ArticlesComponent = memo(Articles);

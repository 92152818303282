import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, Modal } from 'components';

import { Builder } from './builders';
import useController from './controller';
import { PropsValues } from './models';

type Props = {
  reportName: string;
  onClose: () => void;
  values: PropsValues;
};

const BuildReportPopupComponent = ({ reportName, onClose, values }: Props) => {
  const {
    isHasManual,
    showManual,
    parameters,
    builderList,
    errorText,
    isErrorPopupOpen,
    handleConfirmError,
    setParameterValueByName,
    startPrint,
    isReportParametersLoading,
  } = useController({
    reportName,
    onClose,
    values,
  });

  return (
    <>
      <FormComponent.Wrapper>
        <Builder
          parameters={parameters}
          setParameterValueByName={setParameterValueByName}
          builders={builderList}
          propsValues={values}
        />

        <FormComponent.Actions>
          {isHasManual && <Button key="manual" mode={ButtonMode.SECONDARY} text="Описание отчёта" onClick={showManual} />}
          <Button
            key="print"
            mode={ButtonMode.PRIMARY}
            text="Построить отчет"
            onClick={startPrint}
            isDisabled={isReportParametersLoading}
          />
        </FormComponent.Actions>
      </FormComponent.Wrapper>

      <Modal
        mode="warning"
        title="Ошибка"
        isOpen={isErrorPopupOpen}
        onClose={handleConfirmError}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: handleConfirmError,
          },
        ]}
        size="small"
      >
        <>{errorText}</>
      </Modal>
    </>
  );
};

export const Component = memo(BuildReportPopupComponent);

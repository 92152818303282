import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { State, makeUseCustomController } from './makeUseCustomController';

export type Arguments = {
  hasSelectButton?: boolean;
};

export const GetNotificationRecipientPresetListSpecification = ({ hasSelectButton }: Arguments): Table.Specification<State> => ({
  apiID: 'GetNotificationRecipientPresetList',
  header: {
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: false,
      hasSelectButton,
    },
  },
  useCustomController: makeUseCustomController(),
  isCanSelectOnlyOneRow: true,
  isWithExport: false,
});

import React from 'react';

import { Checkbox, FormComponent, SectionTitle, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { ExtraToolbarButton } from 'components/ListEdit/model';
import { Table as T, Form as F, Author, Award, Event, Exponent } from 'types/models';
import { ParticipationProject } from 'types/models/Participation';
import { Projects } from 'features/Form/looks/participation/views/Projects';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { useController } from './controller';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';
import { CheckboxItem } from 'features/Form/looks/participation/ParticipationFormExposition/controller';
import { getEnum } from 'utils/Helpers';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  formFields: F.Fields;
  workMode: T.WorkMode;
  event: Event.Event | null;
  setEvent: (event: Event.Event | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  changeMembers: (val: Author[]) => void;
  exponent: Exponent | null;
  disabledEvent: boolean | undefined;
  id: string;
  extraToolbarButtons?: ExtraToolbarButton<Author>[];
  changeCurrentExponent: (val: any) => void;
  awards: Award[];
  presentationProject: ParticipationProject | null;
  setProjects: (rows: ParticipationProject[]) => void;
  isVisibleProjectField: boolean;
  participationPresentationForm: CheckboxItem[];
  handlePresentationFormChange: (val: boolean, ordinal: number) => void;
  isVisiblePresentationForm: boolean;
  isVisibleExponentSection: boolean;
  isVisibleExponentField: boolean;
  isVisibleExpositionFields: boolean;
};

function About({
  formFields,
  workMode,
  event,
  setEvent,
  relatedTableState,
  members,
  changeMembers,
  exponent,
  disabledEvent,
  id,
  extraToolbarButtons,
  changeCurrentExponent,
  awards,
  presentationProject,
  setProjects,
  isVisibleProjectField,
  participationPresentationForm,
  handlePresentationFormChange,
  isVisiblePresentationForm,
  isVisibleExponentSection,
  isVisibleExponentField,
  isVisibleExpositionFields,
}: Props) {
  const {
    enumMap,
    modalTableRowToEventConventer,
    modalTableRowToExponentConverter,
    exponentTableConfig,
    shortEventTableConfig,
  } = useController({ relatedTableState });

  const projects: string = getEnum('ProjectType', enumMap)
    .map(x => x.label)
    .join(', ');

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title={`Участие в выставке ${id ? `(ID ${id ?? ''})` : ''}`} />

        <FormComponent.Line>
          <FormComponent.Field label="Выставка" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Выставки',
                visibleStatus: getStatusRecordMessage('', event?.id, event?.status?.label),
                isClearable: false,
                table: {
                  specification: shortEventTableConfig,
                  onSelect: (row: T.Entry | null) => setEvent(row ? modalTableRowToEventConventer(row) : null),
                },
              }}
              value={getEventFullName(event)}
              isDisabled={workMode === 'viewMode' || disabledEvent}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Уровень мероприятия">
            <FormComponent.Text>{event?.eventStatus?.label}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Формат проведения">
            <FormComponent.Text>{event?.format?.label}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Формат участия"
            isRequired={formFields.form.required}
            tooltip="Выбор формата участия влияет на состав полей формы. "
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.form.value}
              onChange={formFields.form.onChange}
              settings={{
                name: 'RefParticipationForm',
                title: 'Справочник "Форматы участия"',
                filters: [{ key: 'type', values: ['EXPOSITION'] }],
                isClearable: !formFields.form.required,
              }}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field>
            <MembersList
              eventType="EXPOSITION"
              disabled={workMode === 'viewMode'}
              authors={members}
              setAuthors={changeMembers}
              isRequired={false}
              extraToolbarButtons={extraToolbarButtons}
              awards={awards}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column>
        {isVisibleExponentSection && (
          <>
            <SectionTitle title="О разработке (проекте/экспозиции)" />

            {isVisibleExpositionFields && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label={formFields.isCollectiveExhibit.title}>
                    <Checkbox
                      checked={!!formFields.isCollectiveExhibit.value}
                      onChange={formFields.isCollectiveExhibit.onChange}
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label={formFields.collectiveExhibitName.title} isRequired>
                    <TextArea
                      settings={{ rows: 3 }}
                      value={formFields.collectiveExhibitName.value}
                      onChange={formFields.collectiveExhibitName.onChange}
                      placeholder="Укажите название экспозиции, совместно с кем была организована."
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </>
            )}

            {isVisibleExponentField && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label="Название разработки" isRequired>
                    <TextArea
                      mode={TextAreaMode.TABLE}
                      settings={{
                        title: 'Список разработок, представленных на выставках',
                        isClearable: false,
                        table: {
                          specification: exponentTableConfig,
                          onSelect: (row: T.Entry | null) =>
                            changeCurrentExponent(row ? modalTableRowToExponentConverter(row) : null),
                        },
                      }}
                      value={formFields.exponent?.value?.name}
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                {(exponent?.subject?.name || exponent?.partners[0]) && (
                  <FormComponent.Line>
                    <FormComponent.Field label="">
                      <FormComponent.Description mode="warning">
                        {exponent?.subject?.name && (
                          <p>{`Продвижение научно-технической разработки: ${exponent?.subject?.name}`}</p>
                        )}
                        {exponent?.partners[0] && (
                          <p>{`Совместно с: ${`${exponent?.partners[0].name} ${
                            exponent?.partners[0]?.address ? ` (${exponent?.partners[0]?.address})` : ''
                          }`}`}</p>
                        )}
                      </FormComponent.Description>
                    </FormComponent.Field>
                  </FormComponent.Line>
                )}
              </>
            )}

            {isVisibleProjectField && (
              <FormComponent.Line>
                <Projects
                  workMode={workMode}
                  participationTypeCode={ParticipationTypes.PARTICIPATION_EXPOSITION.code}
                  setProjects={setProjects}
                  relatedTableState={relatedTableState}
                  isDisabled={workMode === 'viewMode'}
                  isSingle
                  projects={presentationProject ? [presentationProject] : []}
                  isRequired
                  selectTitle="Продвижение результатов проекта"
                  // eslint-disable-next-line max-len
                  tooltipText={`Укажите проект из списка проектов научного управления (${projects}). Если в списке проект отсутствует, то выберите Формат участия = Презентация разработки и заполните поле "Название разработки"`}
                />
              </FormComponent.Line>
            )}
            {isVisiblePresentationForm && (
              <FormComponent.Field label="Форма представления" isRequired>
                <FormComponent.Line hasFreeFormat>
                  {participationPresentationForm.map((val, index) => (
                    <Checkbox
                      key={index}
                      label={val.label}
                      checked={!!val.isChecked}
                      onChange={checked => handlePresentationFormChange(checked, val.ordinal)}
                      isDisabled={workMode === 'viewMode'}
                    />
                  ))}
                </FormComponent.Line>
              </FormComponent.Field>
            )}
          </>
        )}
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

export const Component = React.memo(About);

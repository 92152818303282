export function CommunityMemberGridHelp(): JSX.Element {
  return (
    <>
      <p>
        В этом разделе отображается список участников научных сообществ (коллабораций, научно-технических советов, редакции
        научных журналов) университета и сторонних организаций. Информацию о своём участии в работе научных сообществ сторонних
        организаций вносят пользователи в Личном кабинете на вкладке «Персональные достижения».
      </p>
    </>
  );
}

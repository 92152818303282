import React, { useCallback } from 'react';

import { TextInput, TextArea, TextAreaMode, FormComponent } from 'components';

import { PartialRecord, ValueOf } from 'types/helpers';
import { NirRequest } from 'types/models';
import { NirTenderType } from 'utils/Enums';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

type TextAreaInfo = {
  key: keyof NirRequest.NirRequest;
  title: string;
  tooltip?: string;
  maxLength?: number;
};

export function EnglishDescription(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;
  const tenderType = nirRequest?.lot?.nirTenderType?.value ?? null;

  const makeHandleChangeTask = (stageTask: NirRequest.StageTask) => (val: string) => {
    const stageTasks = nirRequest?.stageTasks ?? [];
    const index = stageTasks.findIndex(x => x.year === stageTask.year);

    if (index !== -1) {
      const updatedStageTasks = [...stageTasks];
      updatedStageTasks[index] = { ...updatedStageTasks[index], englishTask: val };
      makeChangeHandler('stageTasks')(updatedStageTasks);
    }
  };

  const renderStageTaskFields = () => {
    const stageTasks = nirRequest?.stageTasks ?? [];
    return stageTasks
      .sort((x, y) => Number(x.year) - Number(y.year))
      .map((stageTask, index) => {
        const baseTitle = `Research tasks for ${stageTask.year}`;
        const titleMap: Record<NirTenderType, string> = {
          PP: `Project tasks for ${stageTask.year}`,
          LMU: baseTitle,
          IG: baseTitle,
          ML: baseTitle,
          ONG: baseTitle,
          PD: baseTitle,
        };
        return (
          <FormComponent.Line key={index}>
            <FormComponent.Field label={titleMap[tenderType!]} tooltip="Не более 1000 знаков с пробелами">
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: `Research tasks for ${stageTask.year}` }}
                value={stageTask.englishTask}
                onChange={makeHandleChangeTask(stageTask)}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        );
      });
  };

  const getTitle = useCallback(
    (key: keyof NirRequest.NirRequest) => {
      if (!tenderType) {
        return '';
      }

      const baseAnnotationTitle = 'Abstract of scientific research';
      const annontationMap: PartialRecord<NirTenderType, string> = {
        PP: 'Abstract of scientific project',
        LMU: baseAnnotationTitle,
        IG: baseAnnotationTitle,
        ML: baseAnnotationTitle,
        ONG: baseAnnotationTitle,
        PD: baseAnnotationTitle,
      };

      const baseRelevanceTitle = 'Relevance and novelty of scientific research';
      const relevanceMap: PartialRecord<NirTenderType, string> = {
        PP: 'Relevance and novelty of scientific project',
        LMU: baseRelevanceTitle,
        IG: baseRelevanceTitle,
        ML: baseRelevanceTitle,
        ONG: baseRelevanceTitle,
        PD: baseRelevanceTitle,
      };

      const baseGoalTitle = 'The purpose of scientific research';
      const goalMap: Record<NirTenderType, string> = {
        PP: 'The purpose of scientific project',
        LMU: baseGoalTitle,
        IG: baseGoalTitle,
        ML: baseGoalTitle,
        ONG: baseGoalTitle,
        PD: baseGoalTitle,
      };

      const mapTitles: PartialRecord<keyof NirRequest.NirRequest, PartialRecord<NirTenderType, string>> = {
        englishAnnotation: annontationMap,
        englishRelevance: relevanceMap,
        englishGoal: goalMap,
      };

      return mapTitles[key]?.[tenderType] ?? '';
    },
    [tenderType],
  );

  const textareasFieldsFirstPart: TextAreaInfo[] = [
    { key: 'englishKeyWords', title: 'Keywords' },
    {
      key: 'englishAnnotation',
      title: getTitle('englishAnnotation'),
      tooltip: 'Не более 1500 знаков с пробелами',
    },
    {
      key: 'englishRelevance',
      title: getTitle('englishRelevance'),
      tooltip: 'Не более 3500 знаков с пробелами',
    },
    { key: 'englishGoal', title: getTitle('englishGoal'), tooltip: 'Не более 1000 знаков с пробелами' },
  ];

  const renderTextareaField = (info: TextAreaInfo) => {
    const { key, title, tooltip, maxLength } = info;
    return (
      <FormComponent.Line key={key}>
        <FormComponent.Field label={title} tooltip={tooltip}>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title, maxLength }}
            value={(nirRequest?.[key] as string) ?? ''}
            onChange={makeChangeHandler(key)}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <FormComponent.Field label="Project name">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Project name' }}
              value={nirRequest?.englishName ?? ''}
              onChange={makeChangeHandler('englishName')}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Head of the project">
            <TextInput
              value={nirRequest?.englishLeader ?? ''}
              onChange={makeChangeHandler('englishLeader')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Name of the laboratory">
            <TextInput
              value={nirRequest?.englishDepartmentTxt ?? ''}
              onChange={makeChangeHandler('englishDepartmentTxt')}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <>{textareasFieldsFirstPart.map(renderTextareaField)}</>
        <>{renderStageTaskFields()}</>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

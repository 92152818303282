import { Table } from 'types/models';

export const GetStageProjectList = (requestData: { projectId: string }): Table.Specification => ({
  apiID: 'GetStageProjectList',
  requestData,
  header: {
    thirdLevel: {
      withTemplatesPanel: true,
      hasSelectButton: true,
    },
  },
});

import { Icon } from 'components';

export function PersonalDissertationCouncilMemberGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Работа в составе диссертационных советов</strong> предназначен для ввода и хранения информации об участии и
        работе в составе диссоветов.
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои диссоветы»</strong> содержит список диссертационных советов, в составе которых вы работали
        </li>
        <li>
          <strong>Фильтр «Все диссоветы»</strong> содержит список участников всех диссертационных советов {organizationName} и
          других организаций
        </li>
      </ul>

      <p>
        Информацию о работе диссертационного совета Университета и составе его участников в систему заносят ответственные лица.
        Если вы не видите запись о своем участии в работе диссовета или являетесь членом диссертационного совета в другом
        университете, внесите об этом информацию в данном разделе Личного кабинета.
      </p>

      <h4>В разделе Работа в составе диссертационных советов вы можете:</h4>

      <ul>
        <li>
          Посмотреть запись о работе с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новую запись о работе в диссовете с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать запись о работе в составе диссовета с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Удалить запись, которую вы создавали, с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Скачать прикрепленный файл диссовета с помощью кнопки <Icon type="download" />
        </li>
        <li>
          Выгрузить список ваших работ в составах диссоветов в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки <Icon type="admin" />
        </li>
      </ul>

      <p>
        При сохранении записи присваивается статус <strong>Добавлена</strong>. Добавленную запись можно редактировать, вносить
        информацию или удалить.
      </p>
      <p>
        Добавленную вами запись о работе в составе диссовета проверит и переведет в статус <strong>Утверждена</strong>{' '}
        Администратор данных. Утвержденная запись недоступна для внесения изменений. Если вы нашли ошибку в утвержденной записи -
        напишите письмо Администратору.
      </p>
    </>
  );
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const [activePerson, setActivePerson] = useState('-1');
  const [isPublicationListOpen, setIsPublicationListOpen] = useState(false);
  const [isParticipationListOpen, setIsParticipationListOpen] = useState(false);
  const [isEventListOpen, setIsEventListOpen] = useState(false);
  const [isRequestListOpen, setIsRequestListOpen] = useState(false);
  const [isDocumentListOpen, setIsDocumentListOpen] = useState(false);

  const handlePublicationsButtonClick = useCallback(() => {
    setActivePerson(tableState.selectedRows.length ? tableState.selectedRows[0]?.id : '-1');
    setIsPublicationListOpen(true);
  }, [tableState.selectedRows, setActivePerson, setIsPublicationListOpen]);

  const handleClosePublicationsForm = useCallback(() => {
    setIsPublicationListOpen(false);
  }, []);

  const handleParticipationsButtonClick = useCallback(() => {
    setActivePerson(tableState.selectedRows.length ? tableState.selectedRows[0]?.id : '-1');
    setIsParticipationListOpen(true);
  }, [tableState.selectedRows, setActivePerson, setIsParticipationListOpen]);

  const handleCloseParticipationsForm = useCallback(() => {
    setIsParticipationListOpen(false);
  }, []);

  const handleEventsButtonClick = useCallback(() => {
    setActivePerson(tableState.selectedRows.length ? tableState.selectedRows[0]?.id : '-1');
    setIsEventListOpen(true);
  }, [tableState.selectedRows, setActivePerson, setIsEventListOpen]);

  const handleCloseEventsForm = useCallback(() => {
    setIsEventListOpen(false);
  }, []);

  const handleRequestsButtonClick = useCallback(() => {
    setActivePerson(tableState.selectedRows.length ? tableState.selectedRows[0]?.id : '-1');
    setIsRequestListOpen(true);
  }, [tableState.selectedRows, setActivePerson, setIsRequestListOpen]);

  const handleCloseRequestsForm = useCallback(() => {
    setIsRequestListOpen(false);
  }, []);

  const handleDocumentsButtonClick = useCallback(() => {
    setActivePerson(tableState.selectedRows.length ? tableState.selectedRows[0]?.id : '-1');
    setIsDocumentListOpen(true);
  }, [tableState.selectedRows, setActivePerson, setIsDocumentListOpen]);

  const handleCloseDocumentsForm = useCallback(() => {
    setIsDocumentListOpen(false);
  }, []);

  const isDisabledButton = useMemo(() => !tableState.selectedRows.length, [tableState.selectedRows]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'list', mode: 'info' },
        title: 'Список совместных публикаций',
        onClick: handlePublicationsButtonClick,
        isDisabled: isDisabledButton,
      },
      {
        icon: { type: 'connection', mode: 'info' },
        title: 'Список соучастий в мероприятиях',
        onClick: handleParticipationsButtonClick,
        isDisabled: isDisabledButton,
      },
      {
        icon: { type: 'group', mode: 'info' },
        title: 'Список совместно организованных мероприятий',
        onClick: handleEventsButtonClick,
        isDisabled: isDisabledButton,
      },
      {
        icon: { type: 'info' },
        title: 'Список заявок и проектов НИР и ОКР',
        onClick: handleRequestsButtonClick,
        isDisabled: isDisabledButton,
      },
      {
        icon: { type: 'library', mode: 'info' },
        title: 'Список объектов интеллектуальной собственности',
        onClick: handleDocumentsButtonClick,
        isDisabled: isDisabledButton,
      },
    ],
    [
      isDisabledButton,
      handlePublicationsButtonClick,
      handleParticipationsButtonClick,
      handleEventsButtonClick,
      handleRequestsButtonClick,
      handleDocumentsButtonClick,
    ],
  );

  return {
    activePerson,
    buttons,
    isPublicationListOpen,
    handleClosePublicationsForm,
    isParticipationListOpen,
    handleCloseParticipationsForm,
    isEventListOpen,
    handleCloseEventsForm,
    isRequestListOpen,
    handleCloseRequestsForm,
    isDocumentListOpen,
    handleCloseDocumentsForm,
  };
}

export enum CommunityMemberRole {
  CHAIRMAN = 'CHAIRMAN',
  CHAIRMAN_DEPUTY = 'CHAIRMAN_DEPUTY',
  SCIENTIFIC_SECRETARY = 'SCIENTIFIC_SECRETARY',
  MEMBER = 'MEMBER',
  PARTICIPANT = 'PARTICIPANT',
  EXPERT = 'EXPERT',
  CO_CHAIRMAN = 'CO_CHAIRMAN',
  CHIEF_EDITOR = 'CHIEF_EDITOR',
  DEPUTY_CHIEF_EDITOR = 'DEPUTY_CHIEF_EDITOR',
  EXECUTIVE_SECRETARY = 'EXECUTIVE_SECRETARY',
  EDITORIAL_COUNCIL = 'EDITORIAL_COUNCIL',
  MEMBER_EDITORIAL_BOARD = 'MEMBER_EDITORIAL_BOARD',
  SECRETARY = 'SECRETARY',
  COMMUNITY_MEMBER = 'COMMUNITY_MEMBER',
}

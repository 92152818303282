import { GetMagazineArticleTranslationListRequest } from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';

export function GetMagazineArticleTranslationList(requestData: GetMagazineArticleTranslationListRequest): Table.Specification {
  return {
    apiID: 'GetMagazineArticleTranslationList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}

import { Program } from 'types/models';
import { Tender, Customer, Lot, LotPrice } from 'types/models/Tender';
import { DEFAULT_TIME } from 'utils/Constants';

import { convertFileToXML, convertReferenceItemToXML, getMaybeField } from '../commonConverters';
import { convertDocumentsToRequest } from '../converters';

export function convertTenderToXML(tender: Tender) {
  return {
    _attr: tender.id ? { id: tender.id } : {},
    ...getMaybeField('CollectiveType', tender.collectiveType?.value),
    ...getMaybeField('Type', tender.type?.value),
    ...getMaybeField('Program', tender.program && convertProgramToXML(tender.program)),
    ...getMaybeField(
      'ProgramEvent',
      tender.programEvent && {
        ...(tender.programEvent.id && tender.programEvent.id !== 'undefined' ? { _attr: { id: tender.programEvent.id } } : {}),
      },
    ),
    Name: tender.name,
    Description: tender.description,
    ...(tender.kindTrade.id ? { KindTrade: { _attr: { id: tender.kindTrade.id } } } : {}),
    ...(tender.dateUniversity ? { DateUniversity: tender.dateUniversity } : {}),
    ...(tender.contactFace ? { ContactFace: tender.contactFace } : {}),
    ...getMaybeField('DateOpenEnvelope', tender.dateOpenEnvelope),
    TimeOpenEnvelope: tender.timeOpenEnvelope ? `${tender.timeOpenEnvelope}:00` : DEFAULT_TIME,
    ...(tender.dateExamenation ? { DateExamenation: tender.dateExamenation } : {}),
    DateSummingUp: tender.dateSummingUp,
    ...getMaybeField('Url', tender.url),
    Customers: tender.customers && tender.customers.length ? { Customer: tender.customers.map(convertCustomerToXML) } : {},
    Categories:
      tender.categories && tender.categories.length ? { Category: tender.categories.map(convertReferenceItemToXML) } : {},
    Participants:
      tender.participants && tender.participants.length
        ? { Participant: tender.participants.map(convertReferenceItemToXML) }
        : {},
    ScienceDomainInterests:
      tender.scienceDomainInterrests && tender.scienceDomainInterrests.length
        ? { ScienceDomainInterest: tender.scienceDomainInterrests.map(convertReferenceItemToXML) }
        : {},
    Lots: tender.lots && tender.lots.length ? { Lot: tender.lots.map(lot => convertLotToXML(lot)) } : {},
    ...(tender.documents && tender.documents.length ? convertDocumentsToRequest(tender.documents, 'local') : {}),
    IsMarketplace: tender.isMarketplace,
    ...getMaybeField('MarketplaceAmount', tender.marketplaceAmount),
    ...getMaybeField('AnnouncementDate', tender.announcementDate),
    ...getMaybeField('Marketplace', tender.marketplace?.id && { _attr: { id: tender.marketplace.id } }),
    // ...getMaybeField(
    //   'MarketplaceSignatory',
    //   tender.marketplaceSignatory?.value && { _attr: { id: tender.marketplaceSignatory.value } },
    // ),
    ...getMaybeField('MarketplaceNote', tender.marketplaceNote),
    ...getMaybeField('RequestType', tender.requestType?.value),
    ...convertFileToXML('Logo', tender.logo),
    Contacts: tender.contacts.length ? { Contact: tender.contacts.map(x => ({ _attr: { id: x.id } })) } : {},
    ...getMaybeField('RequestsPlan', tender?.requestsPlan),
    IsSpecial: tender.isSpecial,
  };
}

function convertProgramToXML(program: Program.Program) {
  return {
    _attr: { id: program.id },
  };
}

function convertCustomerToXML(customer: Customer) {
  return {
    ...(customer.id ? { _attr: { id: customer.id } } : {}),
    Customer: { _attr: { id: customer.customerId } },
    Address: customer.address,
    Contacts: customer.contacts,
  };
}

function convertLotToXML(lot: Lot) {
  return {
    ...(lot.id ? { _attr: { id: lot.id } } : {}),
    Number: lot.number,
    Code: lot.code,
    Theme: lot.theme,
    ...(lot.startDate ? { StartDate: lot.startDate } : {}),
    ...(lot.endDate ? { EndDate: lot.endDate } : {}),
    ...getMaybeField('PeriodNote', lot.periodNote),
    IsSupportContract: !!(lot.isSupportContract || lot.contractSupportAmount),
    IsSupportRequest: !!(lot.isSupportRequest || lot.requestSupportAmount),
    IsSupportWarranty: !!(lot.isSupportWarranty || lot.warrantySupportAmount),
    ContractCount: lot.contractCount,
    LimitAmount: lot.limitAmount || '0.00',
    ContractAmount: lot.contractAmount || '0.00',
    RequestSupportAmount: lot.requestSupportAmount || '0.00',
    ContractSupportAmount: lot.contractSupportAmount || '0.00',
    WarrantySupportAmount: lot.warrantySupportAmount || '0.00',
    ...(lot.investmentSource ? { InvestmentSource: convertReferenceItemToXML(lot.investmentSource) } : {}),
    ...(lot.note ? { Note: lot.note } : {}),
    ...(lot.investmentSourceNote ? { InvestmentSourceNote: lot.investmentSourceNote } : {}),
    Prices: lot.prices?.length ? { Price: lot.prices.map(lotPrice => convertLotPriceToXML(lotPrice)) } : {},
    ...getMaybeField('NirTenderType', lot.nirTenderType?.value),
  };
}
function convertLotPriceToXML(lotPrice: LotPrice) {
  return {
    Year: lotPrice.year,
    Amount: lotPrice.amount || '0.00',
  };
}

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { hasToolbarButtonMode } = customState;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: hasToolbarButtonMode ? 'Показывать записи за год' : 'Год',
        checked: true,
      },
    ],
    hasToolbarButtonMode ? 'personal' : 'default',
  );
});

import React from 'react';

import { SectionTitle, TextArea, FormComponent, RefList } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

export function ScientificInterests(props: Props) {
  const { person, disabled, makeSetPerson } = props;

  if (!person) {
    return null;
  }

  return (
    <>
      <FormComponent.Description mode="warning">Анкетные данные "Профиль учёного"</FormComponent.Description>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title="Научные интересы" />
          <FormComponent.Line>
            <FormComponent.Field label="Направления деятельности">
              <TextArea
                settings={{ rows: 7, isFixed: true }}
                value={person.scientist?.activities}
                onChange={e => makeSetPerson('scientist')({ ...person.scientist!, activities: e })}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Основные научные результаты">
              <TextArea
                settings={{ rows: 7, isFixed: true }}
                value={person.scientist?.scientificResults}
                onChange={e => makeSetPerson('scientist')({ ...person.scientist!, scientificResults: e })}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ключевые слова на русском языке">
              <TextArea
                settings={{ isFixed: true }}
                value={person.scientist?.keywordsRus}
                onChange={e => makeSetPerson('scientist')({ ...person.scientist!, keywordsRus: e })}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Ключевые слова на английском языке">
              <TextArea
                settings={{ rows: 3, isFixed: true }}
                value={person.scientist?.keywordsEnglish}
                onChange={e => makeSetPerson('scientist')({ ...person.scientist!, keywordsEnglish: e })}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>

        <FormComponent.Column>
          <SectionTitle title="Области научных интересов" />
          <FormComponent.Line>
            <RefList
              header={{ title: 'Области научных интересов' }}
              rows={person.domainInterests}
              onChange={makeSetPerson('domainInterests')}
              toolbar={['add', 'edit', 'delete']}
              maxHeight="220px"
              isDisabled={disabled}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "Области научных интересов"'
              refName="RefScienceDomainInterest"
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Коды ГРНТИ' }}
              rows={person.grntis}
              onChange={makeSetPerson('grntis')}
              toolbar={['add', 'edit', 'delete']}
              maxHeight="220px"
              isDisabled={disabled}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "ГРНТИ"'
              refName="RefGrnti"
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Коды УДК' }}
              rows={person.udks}
              onChange={makeSetPerson('udks')}
              toolbar={['add', 'edit', 'delete']}
              maxHeight="220px"
              isDisabled={disabled}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "УДК"'
              refName="RefUdk"
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Коды международной классификации отраслей наук (ОЕСД)' }}
              rows={person.oecds}
              onChange={makeSetPerson('oecds')}
              toolbar={['add', 'edit', 'delete']}
              maxHeight="220px"
              isDisabled={disabled}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "ОЕСД"'
              refName="RefOecd"
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Отрасли знаний (РНФ)' }}
              rows={person.knowledgeBranches}
              onChange={makeSetPerson('knowledgeBranches')}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                {
                  label: 'Отрасль знаний',
                  formatValue: x => x.label || '',
                  styles: { width: '50%' },
                },
                {
                  label: 'Группа (подгруппа)',
                  formatValue: x => getKnowledgeBranchGroupLabel(x),
                  styles: { width: '50%' },
                },
              ]}
              maxHeight="220px"
              isDisabled={disabled}
              withoutHead={false}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "Отрасли знаний (РНФ)"'
              refName="RefKnowledgeBranch"
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Специальности ВАК' }}
              rows={person.vakSpecialities}
              onChange={makeSetPerson('vakSpecialities')}
              toolbar={['add', 'edit', 'delete']}
              maxHeight="220px"
              isDisabled={disabled}
              isDeleteConfirmEnabled
              withMessages
              modalTitle='Справочник "ВАК"'
              refName="RefVakSpeciality"
            />
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

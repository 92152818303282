import { Checkbox, CheckboxMode, Icon, Tab, Tabs } from 'components';

export function NotificationHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Настройка подсистемы">
        <h3>Настройка подсистемы уведомлений</h3>

        <div>
          Таблица содержит список событий, при наступлении которого автоматически в личный кабинет пользователя системы и на
          электронную почту отправляется стандартное сообщение.
        </div>
        <div>
          Структура события включает мета-данные: тему — название события, отражающее суть события; категорию пользователей —
          получателей сообщения и поле для ввода дополнительной информации.
        </div>
        <div>
          Для настройки подсистемы уведомлений, в первую очередь, переместите слайд-чекбокс{' '}
          <Checkbox mode={CheckboxMode.SLIDER} checked /> в активное положение — после этого список возможных уведомлений будет
          доступен для активации.
        </div>
        <div>
          Для активации конкретного события выделите в таблице строку и нажмите кнопку «активировать»{' '}
          <Icon type="activate" mode="check" /> на панели инструментов. Для активированных событий — в столбце таблицы «Включен»
          отображается <Icon type="check" />.
        </div>
        <div>
          Кнопка «деактивировать» <Icon type="activate" mode="remove" /> — для отключения рассылки уведомления по событию.
        </div>
        <div>
          Для каждого события есть возможность внести дополнительную информацию в текст уведомления. Для этого выберете событие и
          нажмите на кнопку редактировать <Icon type="edit" />.
        </div>

        <div className="warning">
          <span>
            <strong>
              Только при наличии в системе активированных уведомлений, в Личном кабинете пользователя станет доступен функционал
              подсистемы уведомлений.
            </strong>
            <br />
            На вкладке <strong>Мои уведомления</strong> — в таблице отображается весь список адресованных пользователю
            уведомлений. Для просмотра полного текста сообщения служит кнопка <Icon type="view" /> Открыть уведомление.
            <br />
            Для получения уведомлений на электронную почту пользователю необходимо перейти на вкладку{' '}
            <strong>Мой профиль → Настройка уведомлений</strong>: указать точный адрес электронной почты и выбрать события для
            получения писем с уведомлением на электронную почту.
          </span>
        </div>

        <h3>Вкладка «Журнал отправленных уведомлений»</h3>

        <div>Таблица содержит список всех оправленных уведомлений.</div>
        <div>Фильтр Год — служит для отображения уведомлений в течении указанного года.</div>
        <div>
          Кнопка Открыть письмо <Icon type="view" /> — для просмотра полного текста уведомления.
        </div>
        <div>
          Кнопка Показать список получателей <Icon type="group" /> — открывает форму с полным списком адресатов. В столбце таблицы
          отображается не более 10 первых по списку адресов электронной почты и общее количество адресатов в списке рассылки.
        </div>
        <div>
          Кнопка Выгрузить в файл <Icon type="excel" /> — создаёт файл формата .xls с данными из таблицы.
        </div>

        <div className="warning">
          <span>
            <strong>Уведомления не приходят инициатору события!</strong>
          </span>
        </div>

        <h4>Функциональные модули, в которых предусмотрены уведомления по событиям:</h4>

        <ul>
          <li>Управление заявками на конкурс;</li>
          <li>Управление проектами;</li>
          <li>Закупки;</li>
          <li>Администрирование.</li>
        </ul>
      </Tab>

      <Tab title="Управление заявками на конкурс">
        <h3>Событие: Новый конкурс по моим интересам</h3>

        <p>
          Уведомление будет отправлено после публикации объявления о конкурсе, при совпадении с классификаторами{' '}
          <strong>Области научных интересов</strong> в профиле пользователя.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Открыт прием заявок на конкурс [название конкурса]</h3>
                <p>
                  <strong>Открыт прием заявок на конкурс</strong> [название конкурса].
                </p>
                <p>
                  <strong>Участники:</strong> [поле: участники конкурса]
                  <br />
                  <strong>Категории конкурса:</strong> [поле: категории конкурса]
                  <br />
                  <strong>Области научных интересов:</strong> [поле: области наук]
                </p>
                <p>
                  <strong>Ссылка на сайт конкурса:</strong> [поле: ссылка на конкурс]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Смена статуса заявки на конкурс</h3>

        <p>При смене статуса заявки на конкурс уведомление будет отправлено:</p>
        <ul>
          <li>по заявкам на конкурс и на внутренний конкурс: руководителю проекта и ответственному исполнителю;</li>
          <li>по заявке на академическую мобильность: заявителю.</li>
        </ul>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>О смене статуса заявки на конкурс</h3>
                <p>
                  <strong>О смене статуса заявки на конкурс</strong>
                </p>
                <p>
                  <strong>Конкурс:</strong> [поле: название конкурса]
                </p>
                <p>
                  <strong>Новый статус заявки:</strong> [статус]
                  <br />
                  <strong>Дата изменения:</strong> [дд.мм.гггг]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Поступило новое сообщение по заявке на конкурс</h3>

        <p>При поступлении сообщения на конкурс уведомление будет отправлено:</p>
        <ul>
          <li>по заявкам на конкурс и на внутренний конкурс: руководителю проекта и ответственному исполнителю;</li>
          <li>по заявке на академическую мобильность: заявителю.</li>
        </ul>

        <div className="warning" data-label="Внимание!">
          <span>При добавлении визы по конкурсы уведомления не генерируются и не отправляются.</span>
        </div>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Поступило новое сообщение по заявке на конкурс</h3>
                <p>
                  <strong>Поступило новое сообщение по заявке на конкурс</strong>
                </p>
                <p>
                  <strong>Конкурс:</strong> [название конкурса]
                </p>
                <p>
                  <strong>Сообщение от:</strong> [ФИО]
                  <br />
                  <strong>Дата сообщения:</strong> [дд.мм.гггг]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Прочитать сообщение по заявке на конкурс — в <strong>Журнале сообщений на форме просмотра заявки</strong>.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Добавление в состав научного коллектива заявки на конкурс</h3>

        <p>
          При включение пользователя в состав научного коллектива в заявке на конкурсе на вкладке Коллектив, будет отправлено
          уведомление членам коллектива.
        </p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Уведомление о добавлении в состав научного коллектива</h3>
                <p>
                  <strong>Уведомление о добавлении в состав коллектива заявки на конкурс</strong>
                </p>
                <p>Вас включили в состав коллектива заявки на участие в конкурсе.</p>
                <p>
                  <strong>Заявленная тема:</strong> [Тема заявки на конкурс]
                  <br />
                  <strong>Руководитель:</strong> [Фамилия Имя Отчество]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>

      <Tab title="Управление проектами">
        <h3>Событие: Добавление в состав коллектива проекта</h3>

        <p>
          При включении пользователя в состав научного коллектива проекта на вкладке Коллектив, будет отправлено уведомление члену
          коллектива.
          <br />
          Для руководителя формируется уведомление со списком всех включенных членов коллектива за предыдущий день и отправляется
          утром следующего дня.
        </p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Уведомление о добавлении в состав коллектива</h3>
                <p>
                  <strong>Уведомление о добавлении в состав коллектива проекта</strong>
                </p>
                <p>
                  Вас включили в состав коллектива проекта на период: [дата начала - дата окончания работы человека в проекте]
                  (роль: роль)
                </p>
                <p>
                  <strong>Тема проекта:</strong> [Название проекта]
                  <br />
                  <strong>Руководитель проекта:</strong> [Фамилия Имя Отчество]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Исключение из состава коллектива проекта</h3>

        <p>
          Уведомление поступает, когда у члена коллектива заканчивается период работы в проекте.
          <br />
          Для руководителя формируется уведомление со списком всех исключенных членов коллектива за предыдущий день и отправляется
          утром следующего дня.
        </p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Уведомление об исключении из состава коллектива проекта</h3>
                <p>
                  <strong>Уведомление об исключении из состава коллектива проекта</strong>
                </p>
                <p>Вас исключили из состава коллектива проекта.</p>
                <p>
                  <strong>Заявленная тема:</strong> [Тема проекта]
                  <br />
                  <strong>Руководитель:</strong> [Фамилия Имя Отчество]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Приближение срока окончания этапа проекта</h3>

        <p>
          Уведомление получает руководитель и ответственный исполнитель проекта за 30 календарных дней, затем за 3 и 1 день до
          сдачи отчета в архив.
        </p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Приближение срока окончания этапа проекта</h3>
                <p>
                  <strong>Приближение срока окончания этапа проекта № [номер проекта]</strong>
                </p>
                <p>
                  <strong>Внимание! Приближается срок окончания этапа проекта: [название проекта]</strong>
                </p>
                <p>Этап проекта №[номер этапа, название (если есть на вкладке Этапы)] заканчивается через 30/3/1 дней.</p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Необходимо подготовить отчет по этапу проекта.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Поступление поручения</h3>

        <p>
          При добавлении поручения по проекту уведомление будет отправлено: руководителю проекта, ответственному исполнителю или
          исполнителю на которого было назначено поручение. Поручение по проекту может добавить руководитель проекта или
          ответственный исполнитель.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>О поступлении поручения по проекту № [номер проекта]</h3>
                <p>
                  <strong>Поступило поручение по проекту №</strong> [номер проекта].
                </p>
                <p>
                  <strong>Название проекта:</strong> [наименование проекта]
                </p>
                <p>
                  <strong>Дата поручения:</strong> [дд.мм.гггг]
                  <br />
                  <strong>Поручение от:</strong> [ФИО персоны]
                  <br />
                  <strong>Ответственные лица:</strong> [ФИО]
                  <br />
                  <strong>Выполнить до:</strong> [дд.мм.гггг] <em>(отображается если есть)</em>
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  {/* eslint-disable-next-line max-len */}
                  Подробно о поручении по проекту — в личном кабинете на вкладке <strong>
                    НИОКР – Поручения по проектам
                  </strong>{' '}
                  или на вкладке <strong>Поручения</strong> в проекте.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Смена статуса поручения</h3>

        <p>
          При смене статуса поручения системное уведомление будет отправлено: руководителю и ответственному исполнителю проекта
          (если инициатором события был не он), ответственные за поручение лица.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>О смене статуса поручения по проекту № [номер проекта]</h3>
                <p>
                  <strong>Поручение по проекту №</strong> [номер проекта].
                </p>
                <p>
                  <strong>Название проекта:</strong> [наименование проекта]
                </p>
                <p>
                  <strong>Статус поручения:</strong> [статус]
                  <br />
                  <strong>Дата изменения:</strong> [дд.мм.гггг]
                </p>
                <p>
                  <strong>Поручение от:</strong> [ФИО персоны]
                  <br />
                  <strong>Ответственные лица:</strong> [ФИО]
                  <br />
                  <strong>Выполнить до:</strong> [дд.мм.гггг] <em>(отображается если есть)</em>
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  {/* eslint-disable-next-line max-len */}
                  Подробно о поручении по проекту — в личном кабинете на вкладке <strong>
                    НИОКР – Поручения по проектам
                  </strong>{' '}
                  или на вкладке <strong>Поручения</strong> в проекте.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Получено сообщение по поручению</h3>

        <p>
          При добавлении сообщения через <strong>Журнал сообщений</strong> на вкладке поручения уведомление будет отправлено:
          руководителю проекта, ответственному исполнителю, ответственным за поручение лицам.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Новое сообщение по поручению по проекту [№ проекта]</h3>
                <p>
                  <strong>Поступило новое сообщение по поручению по проекту [№ проекта].</strong>
                </p>
                <p>
                  <strong>Название проекта:</strong> [наименование проекта]
                </p>
                <p>
                  <strong>Статус поручения:</strong> [статус]
                  <br />
                  <strong>Дата изменения:</strong> [дд.мм.гггг]
                </p>
                <p>
                  <strong>Поручение от:</strong> [ФИО персоны]
                  <br />
                  <strong>Ответственные лица:</strong> [ФИО]
                  <br />
                  <strong>Выполнить до:</strong> [дд.мм.гггг] <em>(отображается если есть)</em>
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Прочитать сообщение по поручению — в <strong>Журнале сообщений</strong> на форме поручения.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>

      <Tab title="Закупки">
        <h3>Событие: Подача заявки на закупку на рассмотрение</h3>

        <p>
          Как только заявка меняет статус <strong>Черновик → На рассмотрении</strong> и система генерирует и отправляет
          уведомление: Руководителю проекта, Ответственному лицу по заявке и Материально-ответственному лицу.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Поступила заявка на закупку для проекта [№ проекта]</h3>
                <p>
                  <strong>Поступила заявка на закупку с целью реализации проекта [№].</strong>
                </p>
                <p>
                  <strong>Наименование закупки:</strong> [наименование закупки]
                </p>
                <p>
                  <strong>Заявитель:</strong> [ФИО]
                  <br />
                  <strong>Материально-ответственное лицо:</strong> [ФИО]
                  <br />
                  <strong>Ответственное лицо по закупке:</strong> [ФИО]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Подробно о заявке на закупку — в личном кабинете на вкладке <strong>НИОКР - Закупки по проектам</strong> или в
                  пункте меню <strong>Закупки</strong>.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Смена статуса заявки на закупку</h3>

        <p>
          При смене статуса заявки{' '}
          <strong>Перевод в статус: На доработку, Отклонена, В работе (поставщик), Выполнена, Черновик</strong> система генерирует
          и отправляет уведомление: Руководителю проекта, Ответственному лицу по заявке и Материально-ответственному лицу.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>О статусе заявки на закупку для проекта [№ проекта]</h3>
                <p>
                  <strong>Заявка на закупку с целью реализации проекта [№ проекта].</strong>
                </p>
                <p>
                  <strong>Наименование закупки:</strong> [наименование закупки]
                </p>
                <p>
                  <strong>Номер заявки на закупку:</strong> [№] <em>(отображается если есть)</em>
                  <br />
                  <strong>Дата регистрации:</strong> [дд.мм.гггг] <em>(отображается если есть)</em>
                  <br />
                  <strong>Статус заявки:</strong> [статус]
                </p>
                <p>
                  <strong>Заявитель:</strong> [ФИО]
                  <br />
                  <strong>Материально-ответственное лицо:</strong> [ФИО]
                  <br />
                  <strong>Ответственное лицо по закупке:</strong> [ФИО]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Подробно о заявке на закупку — в личном кабинете на вкладке НИОКР - Закупки по проектам или в пункте меню
                  Закупки.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Сообщение/Виза эксперта по заявке на закупку</h3>

        <p>
          При добавлении сообщения по закупке на вкладке Журнал сообщений или добавлении Визы эксперта (по кнопкам: Комитет,
          Логистика, Комиссия) уведомления будут отправлены: Руководителю проекта, Ответственному лицу по заявке и
          Материально-ответственному лицу.
        </p>
        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <h3 style={{ textAlign: 'left' }}>Новое сообщение по заявке на закупку для проекта [№ проекта]</h3>
                <p>
                  <strong>Заявка на закупку с целью реализации проекта [№ проекта].</strong>
                </p>
                <p>
                  <strong>Наименование закупки:</strong> [наименование закупки]
                </p>
                <p>
                  <strong>Статус заявки:</strong> [статус]
                </p>
                <p>
                  <strong>Номер заявки на закупку:</strong> [№]
                  <br />
                  <strong>Дата регистрации:</strong> [дд.мм.гггг]
                </p>
                <p>
                  <strong>Заявитель:</strong> [ФИО]
                  <br />
                  <strong>Материально-ответственное лицо:</strong> [ФИО]
                  <br />
                  <strong>Ответственное лицо по закупке:</strong> [ФИО]
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                  <br />
                  Прочитать сообщение по заявке на закупке — в <strong>Журнале сообщений</strong> на форме заявки.
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>

      <Tab title="Администрирование">
        <h3>Событие: Получен ответ на вопрос в разделе "Вопрос-Ответ"</h3>

        <p>Пользователь (автор вопроса) получает уведомление при получении ответа на вопрос в разделе «Вопрос-Ответ».</p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Уведомление о поступлении ответа на вопрос в разделе "Вопрос-Ответ"</strong>
                </p>
                <p>
                  Получен ответ на Ваш вопрос в разделе Вопрос-ответ.
                  <br />
                  Для прочтения перейдите на вкладку Новости → Вопрос-Ответ
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>

        <h3>Событие: Регистрация нового пользователя</h3>

        <p>Приветственное письмо при регистрации нового пользователя.</p>

        <p>
          <strong>Структура уведомления:</strong>
        </p>

        <table>
          <tbody>
            <tr>
              <td>
                <p>
                  <strong>Добрый день! </strong>
                </p>
                <p>
                  Вы зарегистрированы в [название системы]. Адрес системы: [адрес системы]. Доступ возможен из сети [название
                  университета] по корпоративным учетным записям. В личном кабинете доступны справочные материалы о системе. В
                  разделе "Мой профиль" → "Настройка уведомлений" можете изменить перечень событий, о которых хотите получать
                  уведомления на электронную почту.
                </p>
                <p>
                  <strong>Дополнительная информация:</strong> [текст] <em>(отображается если есть)</em>
                </p>
                <br />
                <p>
                  <em>Письмо имеет информационный характер и не требует ответа.</em>
                </p>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
  );
}

import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import ReactDOM from 'react-dom';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { Form, Event, Document } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikeEventStreams } from 'features/SuchLike/SuchLikeEvent/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { checkBibliographicRecord } from 'utils/Validators';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Department, EventProject } from 'types/models/Event';
import { EventFormat, EventModule, RecordStatus } from 'utils/Enums';
import { Item } from 'types/models/common';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { validate, compilationAndReleaseValidate } from './validate';
import { useFormContext } from 'features/Form/hooks';
import { formatToRequest } from './setup/formatToRequest';
import { setByResponse } from './setup/setByResponse';
import { showNotification } from 'features/Notifications';
import { getMockEvent } from 'utils/Helpers/event';
import { ValueOf } from 'types/helpers';

type Props = {
  onClose: () => void;
};

type RadioItem = {
  label: string;
  value: string;
};

type SaveFormData = {
  formFields: Form.Fields;
  publicationMessage?: Form.PublicationMessage;
  publicationMessageType?: ReferenceItem;
  onSuccess: () => void;
  onError: () => void;
};

export const PUBLISHED_TAB_ID = 2;

export function useController({ onClose }: Props) {
  const tabsId = 'EventFormConference';

  const { currentPerson, initialLanguageCode, settings, userPermission } = useAppDataContext();
  const {
    look: {
      type,
      id,
      viewMode,
      editMode,
      relatedTableState,
      name,
      isDisableEventSource = false,
      isMagazineReleasesPlanned = false,
      isCompilationsPlanned = false,
      isIncentivePayments = false,
      controllerIsForm,
      withHeaderId,
    },
  } = useFormContext<Form.EventFormLook>();

  const { isProfile } = usePrivatePageContext();
  const organizationName = settings?.organization?.shortName || '';
  const organizationCity = settings?.organization?.city || '';

  const initialFormatValue: Item = EventFormat.OFFLINE;
  const initialFormatList: RadioItem[] = useMemo(() => Object.values(EventFormat), []);

  const tableStreams = useLocalTableStreams();

  const suchLikeEventStreams = useLocalSuchLikeEventStreams();

  const needClose = useRef<boolean>(false);
  const [eventId, setEventId] = useState<string | undefined>(id);
  const [eventData, setEventData] = useState<Event.Event>(getMockEvent());
  const [departments, setDepartments] = useState<Event.Department[]>([]);
  const [sourceMagazine, setSourceMagazine] = useState<Form.Original | null>(null);
  const [magazineReleases, setMagazineReleases] = useState<Event.Source[]>([]);
  const [compilations, setCompilations] = useState<Event.Compilations[]>([]);
  const [participationPublications, setParticipationPublications] = useState<Event.Source[]>([]);

  const [unconnectedParticipationPublications, setUnconnectedParticipationPublications] = useState<Event.Source[]>([]);

  const [enterprises, setEnterprises] = useState<Event.Enterprise[]>([]);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [committeMembers, setCommitteMembers] = useState<Event.Member[]>([]);
  const [format, setFormat] = useState<Item>(initialFormatValue);
  const [memberCounts, setMemberCounts] = useState<Event.MemberCount[]>([]);
  const [projects, setProjects] = useState<EventProject[]>([]);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const [confirmPopupText, setConfirmPopupText] = useState<string>('');
  const [isTitleWarningPopup, setIsTitleWarningPopup] = useState(false);
  const [titleWarningText, setTitleWarningText] = useState<string>('');
  const [participations, setParticipations] = useState<Event.EventParticipant[]>([]);
  const [isDisableSource, setIsDisableSource] = useState(false);
  const [isDocumentsWarningPopupOpen, setIsDocumentsWarningPopupOpen] = useState<boolean>(false);
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);

  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const onFormatChange = (val: string) => {
    setFormat(initialFormatList.find(x => x.value === val) || initialFormatValue);
  };

  const { initialCountryCode } = useAppDataContext();

  const termsList = useMemo<Form.EditionTypes>(
    () => [
      { label: 'Период', id: 'PERIOD' },
      { label: 'Дата', id: 'DATA' },
    ],
    [],
  );

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название конференции',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    eventSeries: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Серия мероприятий',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eventSeries: { ...prevState.eventSeries, value },
        }));
      },
    },
    conferenceType: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Тип конференции',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          conferenceType: { ...prevState.conferenceType, value },
        }));
      },
    },
    eventStatus: {
      value: { label: '', id: '' },
      isValid: true,
      required: true,
      title: 'Уровень мероприятия',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          eventStatus: { ...prevState.eventStatus, value },
        }));
      },
    },
    isStudent: {
      value: false,
      isValid: true,
      title: 'Конференция студентов и молодых ученых',
      onChange: (value: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isStudent: {
            ...prevState.isStudent,
            value,
          },
        }));
      },
    },
    isInSummaryPlan: {
      value: false,
      isValid: true,
      title: `Входит в сводный план ${organizationName}`,
      onChange: (value: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          ...(value
            ? {
                country: {
                  ...prevState.country,
                  value: {
                    id: value ? initialCountryCode?.id : '',
                    label: value ? initialCountryCode?.label : '',
                  },
                },
                city: {
                  ...prevState.city,
                  value: value ? organizationCity : '',
                },
              }
            : {}),
          isInSummaryPlan: {
            ...prevState.isInSummaryPlan,
            value,
          },
        }));
      },
    },
    orderNumber: {
      value: '',
      isValid: true,
      required: false,
      title: 'Приказ о проведении №',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          orderNumber: { ...prevState.orderNumber, value },
        }));
      },
    },
    orderDate: {
      value: '',
      isValid: true,
      title: 'от',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          orderDate: { ...prevState.orderDate, value: nextDate || '' },
        }));
      },
    },
    isCompilationPlanned: {
      value: false,
      isValid: true,
      title: 'Планируется выпуск сборника материалов конференций',
      onChange: (value: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isCompilationPlanned: {
            ...prevState.isCompilationPlanned,
            value,
          },
        }));
        setMagazineReleases([]);
      },
    },
    isMagazinePlanned: {
      value: false,
      isValid: true,
      title: 'Планируется специальный выпуск журнала',
      onChange: (value: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isMagazinePlanned: {
            ...prevState.isMagazinePlanned,
            value,
          },
        }));
        setCompilations([]);
      },
    },
    termsType: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Сроки проведения',
      required: true,
      onChange: (nextTerm: Item) => {
        const nextValue = termsList.find(({ label }) => label === nextTerm.label) || { id: '', label: '' };
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, required: nextValue.id === termsList[0].id },
        }));
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          termsType: { ...prevState.termsType, value: nextValue },
        }));
      },
    },
    startDate: {
      value: ' ',
      isValid: true,
      required: true,
      title: 'Дата начала мероприятия',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          startDate: { ...prevState.startDate, value: nextDate || '' },
        }));
      },
    },
    endDate: {
      value: '',
      isValid: true,
      title: 'Дата завершения мероприятия',
      required: true,
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          endDate: { ...prevState.endDate, value: nextDate || '' },
        }));
      },
    },
    description: {
      value: '',
      isValid: true,
      title: 'Описание конференции',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          description: { ...prevState.description, value },
        }));
      },
    },
    keyWords: {
      value: '',
      isValid: true,
      title: 'Ключевые слова',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          keyWords: { ...prevState.keyWords, value },
        }));
      },
    },
    site: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сайт конференции',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          site: { ...prevState.site, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          site: { ...prevState.site, isValid: true },
        }));
      },
    },
    country: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Страна',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          country: { ...prevState.country, value },
        }));
      },
    },
    city: {
      value: '',
      isValid: true,
      title: 'Город',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          city: { ...prevState.city, value },
        }));
      },
    },
    address: {
      value: '',
      isValid: true,
      title: 'Адрес',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          address: { ...prevState.address, value },
        }));
      },
    },
    contact: {
      value: '',
      isValid: true,
      title: 'Контактная информация',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          contact: { ...prevState.contact, value },
        }));
      },
    },
    memberCountPlan: {
      value: '',
      isValid: true,
      title: 'план',
      tooltipText: 'Введите предполагаемое количество участников вручную',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          memberCountPlan: { ...prevState.memberCountPlan, value },
        }));
      },
    },
    memberCountFact: {
      value: '',
      isValid: true,
      title: 'факт',
      tooltipText:
        // eslint-disable-next-line max-len
        'После каждого редактирования (добавления/удаления) информации об участниках и их количестве необходимо снова нажать на кнопку калькулятора «Рассчитать».',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          memberCountFact: { ...prevState.memberCountFact, value },
        }));
      },
    },
  });

  const setEventDataField = useCallback(
    (fieldName: keyof Event.Event) => (value: ValueOf<Event.Event>) => {
      if (eventData) {
        setEventData({ ...eventData, [fieldName]: value });
      }
    },
    [eventData, setEventData],
  );

  const handleDepartments = useCallback(
    (deps: Event.Department[]) => {
      if (!deps.length) {
        formFields.isInSummaryPlan.onChange(false);
        formFields.orderNumber.onChange('');
        formFields.orderDate.onChange('');
      }
      setDepartments(deps);
    },
    [formFields.isInSummaryPlan, formFields.orderDate, formFields.orderNumber],
  );

  const handleAttractedDepartmentsChange = useCallback(
    (value: Department[]) => {
      const depExists = (depId?: string) => value.find(d => d.id === depId);
      const newDepartments = departments.map(d => {
        if (d) {
          return { ...d, isFinancing: !!depExists(d.id) };
        }
        return d;
      });

      handleDepartments(newDepartments);
    },
    [departments, handleDepartments],
  );

  const isSelectedPeriod = useMemo<boolean>(() => formFields.termsType.value?.id === 'PERIOD', [formFields.termsType.value]);
  const { methods: getEvent, state: getEventState } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: saveEvent, state: saveEventState } = BackendAPI.useBackendAPI('SaveEvent');

  const { isLoading } = useIsLoading({ callStates: [getEventState, saveEventState] });

  const calculateFactMemberCount = () => {
    let counter = 0;
    memberCounts.forEach(member => {
      counter += parseInt(member.amount, 10);
    });
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      memberCountFact: { ...prevState.memberCountFact, value: counter },
    }));
  };

  const fetchEvent = useCallback(() => {
    if (type) {
      getEvent.callAPI(
        {
          simpleFields: {
            type: type?.toUpperCase() || '',
            domain: 'EVENT',
            isInSummaryPlan: false,
            isCompilationPlanned: isDisableEventSource && isCompilationsPlanned,
            IsMagazineReleasePlanned: isDisableEventSource && isMagazineReleasesPlanned,
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          documents,
          id: eventId,
          projects,
          grntis: eventData?.grntis || [],
          memberCounts,
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setByResponse({
              settings,
              setFormFields,
              data,
              eventId,
              name,
              initialCountryCode,
              isDisableEventSource,
              isMagazineReleasesPlanned,
              isCompilationsPlanned,
            });

            setDocuments(data.documents);
            handleDepartments(data.departments);
            setEnterprises(data.enterprises);
            setMemberCounts(data.memberCounts);
            setCommitteMembers(data.committeMembers);
            setProjects(data.projects);
            setCompilations(data.compilations);
            setParticipations(data.participations);
            setParticipationPublications(data.participationPublications);
            setUnconnectedParticipationPublications(data.participationPublications.filter(c => !c.participations?.length));
            setMagazineReleases(data.magazineReleases);

            if (data.magazineReleases.length) {
              changeSourceMagazine({
                id: data.magazineReleases[0].upperSourceId || '',
                name: data.magazineReleases[0].upperSourceName || '',
                status: data.magazineReleases[0].upperSourceStatus || '',
              });
            }

            setFormat(data.format || initialFormatValue);
            setIsDisableSource(isDisableEventSource);

            ReactDOM.unstable_batchedUpdates(() => {
              setEventData(data);
            });
          },
        },
      );
    }
  }, [
    type,
    getEvent,
    isDisableEventSource,
    isCompilationsPlanned,
    isMagazineReleasesPlanned,
    documents,
    eventId,
    projects,
    eventData?.grntis,
    memberCounts,
    settings,
    name,
    initialCountryCode,
    handleDepartments,
    initialFormatValue,
  ]);

  const saveForm = useCallback(
    ({ onSuccess, onError, publicationMessageType, publicationMessage }: SaveFormData) => {
      const isMessage = !!publicationMessageType?.id;

      if (!departments.length && !enterprises.length) {
        showNotification({
          message: `Необходимо указать организатора мероприятия: подразделение ${organizationName} или внешнего организатора`,
          theme: 'danger',
        });
        return;
      }

      const fieldsForRequest = formatToRequest({
        projects,
        type: type ? { value: type, label: '' } : null,
        departments,
        enterprises,
        memberCounts,
        grntis: eventData?.grntis || [],
        ministryEducations: eventData?.ministryEducations || [],
        criticalTechnologies: eventData?.criticalTechnologies || [],
        pnrs: eventData?.pnrs || [],
        pnis: eventData?.pnis || [],
        pnmitrs: eventData?.pnmitrs || [],
        documents,
        eventId,
        attractedFinancing: eventData?.attractedFinancing || '',
        formFields,
        compilations,
        committeMembers,
        magazineReleases,
        format,
      });

      saveEvent.callAPI(
        {
          ...fieldsForRequest,
          feedline: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setEventId(data.id);
            if (needClose.current) {
              onClose();
              tableStreams.reloadTable.push({});
            } else {
              updateWorkModeAfterSaveAndContinue();
              if (workMode === 'editMode') {
                fetchEvent();
              }
            }
            onSuccess();

            showNotification({
              theme: 'success',
              message: eventId ? 'Конференция успешно отредактирована!' : 'Конференция успешно сохранена!',
            });
          },
          onFailedCall: () => onError(),
        },
      );
    },
    [
      departments,
      enterprises,
      projects,
      type,
      memberCounts,
      eventData?.grntis,
      eventData?.ministryEducations,
      eventData?.criticalTechnologies,
      eventData?.pnrs,
      eventData?.pnis,
      eventData?.pnmitrs,
      eventData?.attractedFinancing,
      documents,
      eventId,
      formFields,
      compilations,
      committeMembers,
      magazineReleases,
      format,
      saveEvent,
      organizationName,
      onClose,
      tableStreams.reloadTable,
      updateWorkModeAfterSaveAndContinue,
      workMode,
      fetchEvent,
    ],
  );

  const startCheckDuplicate = useCallback(() => {
    const onSuccess = () => {
      if (needClose.current) {
        onClose();
      }
    };

    const onError = () => {
      // eslint-disable-next-line
      console.error('ERR');
    };

    const successfullCallback = ({
      publicationMessage,
      publicationMessageType,
    }: {
      publicationMessage: string;
      publicationMessageType: ReferenceItem;
    }) => {
      saveForm({ formFields, publicationMessage, publicationMessageType, onSuccess, onError } as SaveFormData);
    };

    suchLikeEventStreams.getSuchLikeEvents.push({
      searchValue: formFields.name.value as string,
      successfullCallback,
      currentId: eventId || null,
      isDefaultSave: !needClose.current,
    });
  }, [suchLikeEventStreams.getSuchLikeEvents, formFields, eventId, onClose, saveForm]);

  const onCloseDocumentsWarningPopup = useCallback(() => {
    setIsDocumentsWarningPopupOpen(false);
    const documentsTabIndex = isDisableSource ? 2 : 6;
    tabsStreams.setCurrentTab.push({ nextSelectedTab: documentsTabIndex, tabsId });
  }, [isDisableSource]);

  const onConfirmDocumentsWarningPopup = useCallback(() => {
    setIsDocumentsWarningPopupOpen(false);
    startCheckDuplicate();
  }, [startCheckDuplicate]);

  const checkDocuments = useCallback(() => {
    const isDocumentsValid = Boolean(documents.length) || eventData?.status?.value === RecordStatus.APPROVED;
    if (isDocumentsValid) {
      startCheckDuplicate();
    } else {
      setIsDocumentsWarningPopupOpen(true);
    }
  }, [documents.length, eventData?.status?.value, startCheckDuplicate]);

  const closeConfirmPopup = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: PUBLISHED_TAB_ID, tabsId });
    setIsConfirmPopupOpen(false);
  }, [setIsConfirmPopupOpen, tabsId]);

  const checkCompilationAndReleaseValidate = useCallback(() => {
    const { isValid, helpText } = compilationAndReleaseValidate(formFields, magazineReleases, compilations);

    if (isValid || isDisableSource) {
      checkDocuments();
    } else {
      setConfirmPopupText(helpText);
      setIsConfirmPopupOpen(true);
    }
  }, [isDisableSource, formFields, magazineReleases, compilations, checkDocuments]);

  const checkBiblio = useCallback(() => {
    const result = checkBibliographicRecord({
      textLanguageId: initialLanguageCode?.id ?? '',
      bibliographicRecord: formFields.name.value,
      cyrillicLanguageCode: initialLanguageCode?.id ?? '',
    });
    if (result.isValid) return true;
    setTitleWarningText(result.text);
    setIsTitleWarningPopup(true);
    return false;
  }, [formFields.name.value, initialLanguageCode?.id]);

  const handleFormSubmit = useCallback(
    (needCloseArg: boolean) => {
      needClose.current = needCloseArg;

      const { isFormValid, invalidFieldKeys, nextNotification } = validate(formFields);

      if (!isFormValid) {
        if (invalidFieldKeys.length) {
          invalidFieldKeys.forEach((key: string) => {
            const preparedKey = key as keyof typeof formFields;
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              [preparedKey]: { ...prevState[preparedKey], isValid: false },
            }));
          });
        }
        if (nextNotification.message) {
          showNotification(nextNotification);
        }
      } else {
        if (isProfile && !committeMembers.find(x => x.person?.id === currentPerson?.id)) setIsAuthorsPopupOpen(true);
        else if (checkBiblio()) checkCompilationAndReleaseValidate();
      }
    },
    [formFields, isProfile, committeMembers, checkBiblio, checkCompilationAndReleaseValidate, currentPerson?.id],
  );

  const onConfirmTitleWarningPopup = () => {
    setIsTitleWarningPopup(false);
    checkCompilationAndReleaseValidate();
  };
  const onCloseTitleWarningPopup = () => {
    setIsTitleWarningPopup(false);
  };

  const onConfirmAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
    if (checkBiblio()) checkCompilationAndReleaseValidate();
  };

  const onResetAuthorsPopup = () => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 3, tabsId });
    getEvent.callAPI(
      {
        simpleFields: {
          type: type?.toUpperCase() || '',
        },
        attrIdFields: {},
        arrayFields: {},
        arm: 'pc',
        eventModules: [EventModule.COMITTE_MEMBERS],
      },
      {
        onSuccessfullCall: ({ data }) => {
          const authorCommitteMember = data?.committeMembers[0];
          if (authorCommitteMember) setCommitteMembers((prevState: Event.Member[]) => [...prevState, authorCommitteMember]);
          setIsAuthorsPopupOpen(false);
          if (checkBiblio()) checkCompilationAndReleaseValidate();
        },
      },
    );
  };
  const onCloseAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
  };

  const closeAndContinueConfirmPopup = useCallback(() => {
    setIsConfirmPopupOpen(false);
    checkDocuments();
  }, [checkDocuments]);

  const changeSourceMagazine = (original: Form.Original | null) => {
    setSourceMagazine(original);
  };

  useEffect(() => {
    if (eventId || workMode === 'addMode') {
      fetchEvent();
    }
    // eslint-disable-next-line
  }, [eventId]);

  useEffect(() => {
    if (controllerIsForm) {
      controllerIsForm.setSourceEvent({
        ...eventData,
        startDate: formFields.startDate.value,
        endDate: formFields.endDate.value,
        eventStatus: formFields.eventStatus.value,
        format,
        compilations,
        isCompilationPlanned: !!compilations,
        magazineReleases,
        isMagazineReleasePlanned: !!magazineReleases,
        // magazineSource: sourceMagazine,
        participations: [],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    compilations,
    magazineReleases,
    sourceMagazine,
    eventData,
    formFields.startDate.value,
    formFields.endDate.value,
    formFields.eventStatus.value,
    format,
  ]);

  const departmentOptions = useMemo(() => departments.filter(d => !d.isFinancing), [departments]);

  const changeMemberCounts = useCallback(
    (memberCnts: Event.MemberCount[] | null) => {
      const newMemberCounts: Event.MemberCount[] =
        memberCnts?.map(memberCount => {
          let counter = 0;
          memberCount.amounts?.forEach(memberAmount => {
            counter += parseInt(memberAmount.amount, 10);
          });
          if (counter) {
            return { ...memberCount, amount: counter.toString() };
          }
          return memberCount;
        }) || [];
      setMemberCounts(newMemberCounts);
    },
    [setMemberCounts],
  );

  return {
    refs: {
      formatChange: onFormatChange,
      formatDefault: initialFormatValue,
      formatList: initialFormatList,
      formatVal: format,
    },
    changeCommitteMembers: setCommitteMembers,
    changeCompilations: setCompilations,
    changeDepartments: handleDepartments,
    changeEnterprises: setEnterprises,
    changeMagazineReleases: setMagazineReleases,
    changeProjects: setProjects,
    calculateFactMemberCount,
    changeMemberCounts,
    changeSourceMagazine,
    closeAndContinueConfirmPopup,
    closeConfirmPopup,
    committeMembers,
    compilations,
    confirmPopupText,
    departmentOptions,
    departments,
    documents,
    enterprises,
    eventData,
    eventId,
    formFields,
    handleAttractedDepartmentsChange,
    withHeaderId,
    handleFormSubmit,
    isAuthorsPopupOpen,
    isConfirmPopupOpen,
    isDisableSource,
    isDocumentsWarningPopupOpen,
    isIncentivePayments,
    isLoading,
    isSelectedPeriod,
    isTitleWarningPopup,
    magazineReleases,
    memberCounts,
    onCloseAuthorsPopup,
    onCloseDocumentsWarningPopup,
    onCloseTitleWarningPopup,
    onConfirmAuthorsPopup,
    onConfirmDocumentsWarningPopup,
    onConfirmTitleWarningPopup,
    onResetAuthorsPopup,
    participationPublications,
    participations,
    projects,
    relatedTableState,
    setDocuments,
    setEventDataField,
    sourceMagazine,
    tabsId,
    termsList,
    titleWarningText,
    unconnectedParticipationPublications,
    workMode,
    organizationName,
    userPermission,
    reloadEvent: fetchEvent,
    sourceEvent: controllerIsForm?.sourceEvent,
  };
}

import React, { useCallback } from 'react';

import { FormComponent, ListEdit, SectionTitle } from 'components';

import { EmployeeNominee, EmployeePayment } from 'types/models/Payment';
import { calcShare, getMockEmployeeNominee } from 'features/Table/specifications/GetRequestFundList/helpers';

import { Fields } from './Fields';
import { BaseInfo } from './BaseInfo';
import { validate } from './validate';
import { showNotification } from 'features/Notifications';

type Props = {
  employeePayment: EmployeePayment | null;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  disabled: boolean;
};

export function General(props: Props) {
  const { employeePayment, setEmployeePayment, disabled } = props;

  const onChangeNominees = useCallback(
    (nominees: EmployeeNominee[]) => {
      setEmployeePayment({ ...employeePayment!, nominees });
    },
    [employeePayment, setEmployeePayment],
  );

  if (!employeePayment) {
    return <FormComponent.Description mode="warning">Не выбран "Фонд подразделений"</FormComponent.Description>;
  }

  return (
    <>
      <BaseInfo employeePayment={employeePayment} disabled={disabled} setEmployeePayment={setEmployeePayment} />

      <SectionTitle title="Список сотрудников подразделения" />

      <ListEdit
        rows={employeePayment.nominees}
        onChange={onChangeNominees}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: '№', styles: { width: '5%' }, formatValue: (x, index) => index + 1 },
          { label: 'ФИО сотрудника', formatValue: x => x.employee?.fullName ?? '' },
          { label: 'Должность', formatValue: x => x.job?.refAppointment?.label ?? '' },
          {
            label: 'Доля',
            formatValue: x => calcShare(Number(x.amountMoney ?? 0), Number(employeePayment?.amountMoney ?? 0)),
            dataKind: 'float',
          },
          { label: 'Сумма', formatValue: x => x.amountMoney, dataKind: 'float' },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={10}
        maxHeight="none"
        columnIndexesForSumTotal={[3, 4]}
        specification={{
          mode: 'customComponent',
          renderComponent: (nominee, setNominee) => (
            <Fields
              nominee={nominee || getMockEmployeeNominee()}
              commonAmount={Number(employeePayment?.amountMoney ?? 0)}
              setNominee={setNominee}
            />
          ),
          validation: {
            checkIsValid: nominee => validate(nominee, Number(employeePayment?.amountMoney ?? 0)).every(x => x.isValid),
            onInvalidate: val =>
              validate(val, Number(employeePayment?.amountMoney ?? 0)).forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              }),
          },
        }}
      />
    </>
  );
}

import { Table } from 'types/models';
import { Component as Toolbar } from './Toolbar';

type RequestData = {
  departmentId?: string;
  year?: string;
  actual?: boolean;
};

export function GetDepartmentEmployeeList(requestData: RequestData): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetDepartmentEmployeeList',
    header: {
      firstLevel: {
        title: 'Списочный состав сотрудников подразделения',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    requestData: requestData,
  };
}

import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { PublicationType } from 'types/models/Publication/publicationTypes';

const publicationTypes: Record<string, PublicationType> = {
  magazineArticle: {
    id: '1',
    label: PublicationTypes.MAGAZINE_ARTICLE.label,
    labelDeclined: 'статьи в журнале',
    code: PublicationTypes.MAGAZINE_ARTICLE.code,
    template: 'PublicationFormArticle',
  },
  articleConference: {
    id: '2',
    label: PublicationTypes.ARTICLE_CONFERENCE.label,
    labelDeclined: 'статьи в сборнике материалов конференций, симпозиумов и др.',
    code: PublicationTypes.ARTICLE_CONFERENCE.code,
    template: 'CompilationFormArticle',
    parentCode: 'COMPILATION_CONFERENCE',
    text: 'Статья в непериодическом сборнике, содержащем итоги конференции в виде докладов, рекомендаций, решений',
  },
  thesis: {
    id: '2',
    label: PublicationTypes.THESIS.label,
    labelDeclined: PublicationTypes.THESIS.nominativeCaseLabel,
    code: PublicationTypes.THESIS.code,
    template: PublicationTypes.THESIS.template,
    parentCode: PublicationTypes.COMPILATION_CONFERENCE.code,
  },
};

export default publicationTypes;

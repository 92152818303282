import React from 'react';

import { FormComponent, Radio, TextInput, TextInputMode, TextDateTime, Select, SelectMode } from 'components';

import { SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Member, SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  member: SecurityDocumentMember | null;
  onMemberChange(row: SecurityDocumentMember | null): void;
  memberType: SecurityDocumentMemberTypes;
  extraFields?: Record<string, boolean> | null;
  enterpriseRef?: string;
};

function MemberInputs({ member, onMemberChange, memberType, extraFields }: Props) {
  const {
    isOrg,
    onRadioChange,
    handlePersonChange,
    handleNumberChange,
    handleFieldChange,
    applicantOptions,
    handleOrgChange,
  } = useController({
    member,
    onMemberChange,
    memberType,
  });

  const onUpdateMember = (val: Member | null) => {
    handlePersonChange(val, member?.id || null);
  };

  const localFields = extraFields ?? {
    contractNumber: true,
    contractDate: true,
    contractPortion: true,
    contribution: true,
  };

  return (
    <FormComponent.Wrapper>
      {memberType === SecurityDocumentMemberTypes.APPLICANT ? (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <Radio
                value={isOrg ? 'ORG' : 'PERSON'}
                list={applicantOptions}
                onChange={value => onRadioChange(value === 'ORG')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          {isOrg ? (
            <FormComponent.Line>
              <FormComponent.Field label="Организация" isRequired>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={member?.enterprise}
                  onChange={handleOrgChange}
                  settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"' }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          ) : (
            <FormComponent.Line>
              <FormComponent.Field label="Заявитель" isRequired>
                <SelectPerson member={member} onUpdateMember={onUpdateMember} hiddenButtons={['delete']} />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {!!localFields?.contractNumber && (
            <FormComponent.Line>
              <FormComponent.Field label="Номер договора">
                <TextInput value={member?.contractNumber || ''} onChange={handleFieldChange('contractNumber')} />
              </FormComponent.Field>
              {!!localFields?.contractDate && (
                <FormComponent.Field label="Дата заключения" labelSize="fit">
                  <TextDateTime value={member?.contractDate} onChange={handleFieldChange('contractDate')} />
                </FormComponent.Field>
              )}
            </FormComponent.Line>
          )}
          {!!localFields?.contractPortion && (
            <FormComponent.Line>
              <FormComponent.Field label="Доля" isRequired>
                <TextInput value={member?.contractPortion ?? ''} onChange={handleFieldChange('contractPortion')} />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
          {!!localFields?.contractNote && (
            <FormComponent.Line>
              <FormComponent.Field label="Примечение">
                <TextInput value={member?.contractNote || ''} onChange={handleFieldChange('contractNote')} />
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </>
      ) : (
        <>
          <FormComponent.Line>
            <FormComponent.Field
              label={memberType === SecurityDocumentMemberTypes.ADMITTED_PERSON ? 'Допущенное лицо' : 'Автор'}
              isRequired
            >
              <SelectPerson member={member} onUpdateMember={val => onUpdateMember(val)} hiddenButtons={['delete']} />
            </FormComponent.Field>
          </FormComponent.Line>
          {!!localFields?.contribution && memberType !== SecurityDocumentMemberTypes.ADMITTED_PERSON && (
            <FormComponent.Line hasFreeFormat>
              <FormComponent.Field label="Творческий вклад, %" contentSize="small" isRequired>
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={member?.contribution ? member?.contribution.toString() : '0'}
                  onChange={val => handleNumberChange('contribution')(parseFloat(val || '0'))}
                  settings={{ max: 100 }}
                />
              </FormComponent.Field>
              <FormComponent.Field>
                <FormComponent.Text>%</FormComponent.Text>
              </FormComponent.Field>
            </FormComponent.Line>
          )}
        </>
      )}
    </FormComponent.Wrapper>
  );
}

export { MemberInputs };

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

type Props = {
  language: ReferenceItem | null;
  fio: string;
  setFIO(fio: string): void;
  setLanguage(lang: ReferenceItem | null): void;
};

function BiblioFields(props: Props) {
  const { language, fio, setFIO, setLanguage } = props;

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" labelSize="small" isRequired>
          <TextInput value={fio} onChange={setFIO} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Язык" labelSize="small" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={language}
            onChange={setLanguage}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { BiblioFields };

import { Mode } from 'components/ListEdit/model';
import { Entry } from 'types/models/Table';
import { Form } from 'types/models';

type CheckIsSelectedRowValidProps = {
  selectedEntries: Array<Entry> | null;
  selectedIndex: number | null;
  affiliations: Array<Form.Affiliation>;
  mode: Mode;
};

export const checkIsSelectedRowValid = ({ selectedEntries, selectedIndex, affiliations, mode }: CheckIsSelectedRowValidProps) => {
  if (!selectedEntries) {
    return false;
  }

  const selectedEntryIds = selectedEntries.map(({ id }) => id);
  const affiliationIds = affiliations.map(({ id }) => id);
  const filteredAffiliationIds = affiliationIds.filter(id =>
    selectedIndex !== null ? id !== affiliations[selectedIndex].id : true,
  );

  if (mode === 'add') {
    return !selectedEntryIds.filter(i => affiliationIds.includes(i)).length;
  }

  if (mode === 'edit') {
    return !selectedEntryIds.filter(i => filteredAffiliationIds.includes(i)).length;
  }

  return true;
};

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'filterVal',
      list: [
        { label: 'Сотрудники', value: 'TSUPersons' },
        { label: 'Аспиранты/Докторанты', value: 'TSUAspDocs' },
        { label: 'Студенты', value: 'TSUStudents' },
        { label: 'Из других организаций', value: 'OtherPersons' },
        { label: 'Все', value: 'All' },
      ],
      value: 'TSUPersons',
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isActual',
      label: 'Действующие',
      checked: false,
    },
  ]);
});

import { useCallback, useState } from 'react';
import { getFinancingByYearNdsAmount, getFinancingByYearNdsPercent } from 'features/Form/looks/project/ProjectForm/helpers';
import { Project } from 'types/models';

type Props = {
  stages: Project.Stage[];
};

const useController = ({ stages: projectStages }: Props) => {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const ndsPercentFormatter = useCallback((row: Project.FinancingByYear) => getFinancingByYearNdsPercent(row, projectStages), [
    projectStages,
  ]);

  const ndsAmountFormatter = useCallback((row: Project.FinancingByYear) => getFinancingByYearNdsAmount(row, projectStages), [
    projectStages,
  ]);

  return {
    selectedRowIndex,
    setSelectedRowIndex,
    ndsPercentFormatter,
    ndsAmountFormatter,
  };
};

export default useController;

import { Tabs, Tab, Modal, FormComponent, ButtonMode } from 'components';

import { Documents as DocumentsComponent } from 'features/Documents';
import { Project, Act } from 'types/models/Project';
import { ValueOf } from 'types/helpers';
import { usePermissions } from 'utils/Helpers/projects/usePermissions';
import { Acts } from '../../views/Acts';
import { ProjectType } from 'utils/Enums';
import { useMemo, useState } from 'react';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
import { Document } from 'types/models';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  project: Project;
  acts: Act[];
  handleActsChange(e: Act[]): void;
  makeChangeHandler: (e: keyof Project) => (e: ValueOf<Project>) => void;
  isDocumentsWarningOpen: boolean;
  setIsDocumentWarningOpen(e: boolean): void;
  projectType: ProjectType;
  disabled?: boolean;
};

export function Documents({
  project,
  acts,
  handleActsChange,
  makeChangeHandler,
  isDocumentsWarningOpen,
  setIsDocumentWarningOpen,
  projectType,
  disabled,
}: Props) {
  const permissions = usePermissions(projectType);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({
    reports: [Reports.ProjectDocuments],
  });

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const leftToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [],
  );

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icon: { type: 'print' },
        title: 'Отчёты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        onClick: () => {},
      },
    ],
    [getReports, handleSetCurrentReport],
  );

  return (
    <>
      <Tabs>
        <Tab title="Документы" isVisible={permissions.isVisibleDocuments}>
          <DocumentsComponent
            isVisibleFilters
            isSortEnabled
            documents={project?.documents ?? []}
            setDocuments={makeChangeHandler('documents')}
            disabled={disabled || !(permissions.isEditableDocumentsAll || permissions.isEditableDocuments)}
            isShowSource
            sourceLabelForLocalDocs="Текущий проект"
            fieldsType="projectDocument"
            sharedLabel="Отображать на форме редактирования шифра"
            isCanIsSharedEdit
            defaultSharedValue
            getIsDisabled={row => !(permissions.isEditableDocumentsAll || (!!row?.isCreator && permissions.isEditableDocuments))}
            leftToolbarButtons={leftToolbarButtons}
            additionalToolbarButtons={extraToolbarButtons}
            withMessages={false}
          />
        </Tab>
        <Tab title="Акты">
          <Acts project={project} acts={acts} onChange={handleActsChange} disabled={disabled} />
        </Tab>
      </Tabs>

      <Modal
        title="Внимание"
        isOpen={isDocumentsWarningOpen && !!project.confidentialityLevel && project.isConfidentialityAlert}
        onClose={() => setIsDocumentWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsDocumentWarningOpen(false),
          },
        ]}
        size="small"
      >
        <FormComponent.Line>
          <p>
            <strong>Уровень конфеденциальности для проекта</strong> - {project.confidentialityLevel?.label || 'не указан'}
          </p>
        </FormComponent.Line>
        <FormComponent.Line>{(project.confidentialityLevel?.customFields?.alert as string) || ''}</FormComponent.Line>
      </Modal>

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Документы"
        size="large"
      >
        {ProjectHelp().documentsAbout}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: project.id || undefined,
        }}
      />
    </>
  );
}

import React, { useCallback, useState } from 'react';

import { FormComponent, ListEditTable, Modal, Toolbar } from 'components';

import { Performer } from 'types/models/Project';

type Props = {
  performers: Performer[];
  isOpen: boolean;
  onClose(): void;
  onSubmit(performers: Performer[]): void;
};

export function ModalProjectScientistsTable(props: Props) {
  const { performers, isOpen, onClose, onSubmit } = props;

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);

  const handleClose = useCallback(() => {
    onClose();
    setSelectedRowsIndexes([]);
    setSelectedRowIndex(null);
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(selectedRowsIndexes.map(index => performers[index]));
    handleClose();
  }, [handleClose, onSubmit, selectedRowsIndexes, performers]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} title="Список участников проекта" size="large">
      <FormComponent.Wrapper>
        <Toolbar
          buttons={[
            {
              icon: { type: 'check' },
              title: 'Выбрать',
              isDisabled: !selectedRowsIndexes.length,
              onClick: handleSubmit,
            },
          ]}
          mode="list"
        />

        <ListEditTable
          rows={performers}
          columns={[{ label: 'ФИО исполнителя', formatValue: x => x.person?.fullName || x.fio || '' }]}
          selectedRowIndex={selectedRowIndex}
          selectRow={setSelectedRowIndex}
          selectedRowsIndexes={selectedRowsIndexes}
          onSelectRows={setSelectedRowsIndexes}
        />
      </FormComponent.Wrapper>
    </Modal>
  );
}

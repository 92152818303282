import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { SecurityDocumentPaymentGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

export function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isHelpFormOpen,
    setIsHelpFormOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    buttons,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
    selectedRowsAmountSum,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isEditButtonDisabled && (
        <modalTemplates.SecurityDocumentPaymentEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="warning"
        title="Сумма выделенных строк"
        isOpen={isConfirmPopupOpened}
        onClose={() => setIsConfirmPopupOpened(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsConfirmPopupOpened(false),
          },
        ]}
        size="small"
      >
        <>Сумма выделенных строк = {selectedRowsAmountSum}</>
      </Modal>

      <Modal
        mode="help"
        title="График Оплаты пошлины"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {SecurityDocumentPaymentGridHelp()}
      </Modal>
    </>
  );
}
export const Component = LeftPanelForThirdLevel;

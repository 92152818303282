import { ButtonMode, Modal, ReferenceItem } from 'components';

import { Table, Event } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';

type Props = {
  isWarningPopupOpen: boolean;
  handleCloseWarningPopup(): void;
  handleAttachParticipation(): void;
  selectedRow: Table.Entry;
  userSystemDepartment: ReferenceItem | null;
  eventType: keyof typeof EventTypes;
};

export function BindParticipation({
  isWarningPopupOpen,
  handleCloseWarningPopup,
  handleAttachParticipation,
  selectedRow,
  userSystemDepartment,
  eventType,
}: Props) {
  if (!selectedRow || !userSystemDepartment) return null;

  return (
    <Modal
      mode="warning"
      title="Привязка к подразделению"
      isOpen={isWarningPopupOpen}
      onClose={handleCloseWarningPopup}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Да',
          onClick: handleAttachParticipation,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: handleCloseWarningPopup,
        },
      ]}
      size="small"
    >
      <>
        <p>Привязать участие к своему подразделению "{userSystemDepartment?.label}"?</p>
        <p>
          <strong>Информация об участии:</strong>
        </p>
        <p>
          <strong>{(EventTypes[eventType] as Event.EventType)?.label}:</strong>{' '}
          {selectedRow?.EventFullName || selectedRow?.EventName || ''}
        </p>
        {selectedRow?.ReportTheme && (
          <p>
            <strong>Тема доклада: </strong> {selectedRow?.ReportTheme}
          </p>
        )}
        {selectedRow?.Participants && (
          <p>
            <strong>Участники</strong> {selectedRow?.Participants}
          </p>
        )}
      </>
    </Modal>
  );
}

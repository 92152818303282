import { useAppDataContext } from 'features/AppData/context';
import { Community } from 'types/models';
import { Item } from 'types/models/common';
import { CommunityType, CommunityMemberRole, CommunityMemberCooperationType } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';

export function getMockCommunity(isNotDissertationCouncil?: boolean): Community {
  const { enumMap } = useAppDataContext();
  return {
    id: null,
    type: !!isNotDissertationCouncil ? null : getEnumItem('CommunityType', 'DISSERTATION_COUNCIL', enumMap),
    foundationDate: '',
    code: '',
    isLocal: true,
    vaks: [],
    grntis: [],
    affiliation: null,
    members: [],
    documents: [],
    isApproved: false,
    orderNumber: '',
    orderDate: '',
    endDate: '',
    note: '',
  };
}

export function getRoleOptionsByCommunityType(allOptions: Item[], selectedCommunityType: CommunityType): Item[] {
  const options: Record<CommunityType, Item[]> = {
    // Диссертационный совет
    [CommunityType.DISSERTATION_COUNCIL]: allOptions.filter(x =>
      [
        CommunityMemberRole.CHAIRMAN,
        CommunityMemberRole.CHAIRMAN_DEPUTY,
        CommunityMemberRole.SCIENTIFIC_SECRETARY,
        CommunityMemberRole.MEMBER,
      ].includes(x.value as CommunityMemberRole),
    ),
    // Коллаборация
    [CommunityType.COLLABORATION]: allOptions.filter(x => x.value === CommunityMemberRole.PARTICIPANT),
    // Научно-технический совет
    [CommunityType.SCIENTIFIC_COUNCIL]: allOptions.filter(x =>
      [
        CommunityMemberRole.CHAIRMAN,
        CommunityMemberRole.CHAIRMAN_DEPUTY,
        CommunityMemberRole.CO_CHAIRMAN,
        CommunityMemberRole.MEMBER,
        CommunityMemberRole.SECRETARY,
      ].includes(x.value as CommunityMemberRole),
    ),
    // Фонд/организация
    [CommunityType.FUND]: allOptions.filter(x =>
      [CommunityMemberRole.EXPERT, CommunityMemberRole.COMMUNITY_MEMBER].includes(x.value as CommunityMemberRole),
    ),
    // Редакция журнала
    [CommunityType.MAGAZINE_REDACTION]: allOptions.filter(x =>
      [
        CommunityMemberRole.CHIEF_EDITOR,
        CommunityMemberRole.DEPUTY_CHIEF_EDITOR,
        CommunityMemberRole.EXECUTIVE_SECRETARY,
        CommunityMemberRole.EDITORIAL_COUNCIL,
        CommunityMemberRole.MEMBER_EDITORIAL_BOARD,
      ].includes(x.value as CommunityMemberRole),
    ),
  };
  return options[selectedCommunityType];
}

export function getCooperationTypeOptionsByCommunityType(allOptions: Item[], selectedCommunityType: CommunityType): Item[] {
  const options: Record<CommunityType, Item[]> = {
    [CommunityType.DISSERTATION_COUNCIL]: allOptions.filter(
      x => x.value === CommunityMemberCooperationType.DISSERTATION_COUNCIL_PARTICIPATION,
    ),
    [CommunityType.COLLABORATION]: allOptions.filter(x => x.value === CommunityMemberCooperationType.COLLABORATION_PARTICIPATION),
    [CommunityType.SCIENTIFIC_COUNCIL]: allOptions.filter(
      x => x.value === CommunityMemberCooperationType.SCIENTIFIC_COUNCIL_PARTICIPATION,
    ),
    [CommunityType.FUND]: allOptions.filter(x => x.value === CommunityMemberCooperationType.FUND_EXPERT),
    [CommunityType.MAGAZINE_REDACTION]: allOptions.filter(x => x.value === CommunityMemberCooperationType.EDITORIAL_BOARD_MEMBER),
  };
  return options[selectedCommunityType];
}

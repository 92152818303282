import { useCallback } from 'react';

import { FormComponent, ListEdit, SectionTitle, Select, SelectMode, TextDateTime, TextInput } from 'components';
import { SelectPerson } from 'features/SelectPerson';
import { getMockMember } from 'features/SelectPerson/helpers';
import { Equip, ReferenceItem } from 'types/models';
import { showNotification } from 'features/Notifications';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  equip: Equip.Equip;
  setEquip: React.Dispatch<React.SetStateAction<Equip.Equip>>;
  isDisabled: boolean;
};

export function Location({ equip, setEquip, isDisabled }: Props) {
  const renderInstallLocationFields = useCallback(
    ({
      row,
      setRow,
    }: {
      row: Equip.InstallLocation | null;
      setRow: React.Dispatch<React.SetStateAction<Equip.InstallLocation | null>>;
    }) => (
      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field label="Фактическое местонахождение" isRequired>
                <TextInput
                  value={row?.address}
                  onChange={e => setRow(prev => ({ ...prev!, address: e }))}
                  settings={{ maxLength: 1000 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field label="Корпус">
                <TextInput
                  value={row?.building}
                  onChange={e => setRow(prev => ({ ...prev!, building: e }))}
                  settings={{ maxLength: 100 }}
                />
              </FormComponent.Field>
              <FormComponent.Field label="Кабинет">
                <TextInput
                  value={row?.cabinet}
                  onChange={e => setRow(prev => ({ ...prev!, cabinet: e }))}
                  settings={{ maxLength: 100 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Ответственное подразделение">
            <Select
              mode={SelectMode.REFERENCE}
              value={row?.department}
              onChange={(e: ReferenceItem | null) => setRow(prev => ({ ...prev!, department: e }))}
              settings={{
                title: 'Справочник "Подразделения"',
                name: 'RefDepartment',
                isClearable: true,
              }}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Руководитель подразделения" tooltip="Ответственное лицо за оборудование в подразделении">
            <SelectPerson
              member={{ ...getMockMember(), person: row?.departmentResponsible || null }}
              onUpdateMember={e => setRow(prev => ({ ...prev!, departmentResponsible: e?.person || null }))}
              memberLabel={row?.departmentResponsible?.fullName}
              withHistory={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ответственный факультет/институт">
            <Select
              mode={SelectMode.REFERENCE}
              value={row?.faculty}
              onChange={(e: ReferenceItem | null) => setRow(prev => ({ ...prev!, faculty: e }))}
              settings={{
                title: 'Справочник "Подразделения"',
                name: 'RefDepartment',
                isClearable: true,
              }}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Руководитель института/факультета">
            <SelectPerson
              member={{ ...getMockMember(), person: row?.facultyResponsible || null }}
              onUpdateMember={e => setRow(prev => ({ ...prev!, facultyResponsible: e?.person || null }))}
              memberLabel={row?.facultyResponsible?.fullName}
              withHistory={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Дата"
            tooltip="Дата установки или дата актуализации информации о фактическом местонахождении"
            isRequired
          >
            <TextDateTime value={row?.installDate} onChange={e => setRow(prev => ({ ...prev!, installDate: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    ),
    [],
  );

  const renderContactsFields = useCallback(
    ({ row, setRow }: { row: Equip.Contact | null; setRow: React.Dispatch<React.SetStateAction<Equip.Contact | null>> }) => (
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Контактное лицо" isRequired>
            <SelectPerson
              member={{ ...getMockMember(), person: row?.contact || null }}
              onUpdateMember={e => setRow(prev => ({ ...prev!, contact: e?.person || null }))}
              memberLabel={row?.contact?.fullName}
              withHistory={false}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Примечание">
            <TextInput
              value={row?.note}
              onChange={e => setRow(prev => ({ ...prev!, note: e }))}
              settings={{ maxLength: 1000 }}
              placeholder="Укажите зону ответственности или по каким вопросам, связанными с оборудованием, можно обращаться"
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    ),
    [],
  );

  return (
    <>
      <SectionTitle title="Фактическое место установки" />
      <ListEdit
        rows={equip.installLocations}
        onChange={e => setEquip(prev => ({ ...prev, installLocations: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          {
            label: 'Дата',
            formatValue: row => row.installDate,
            styles: { width: '10%' },
            dataKind: 'date',
            defaultSort: 'desc',
          },
          {
            label: 'Фактическое местонахождение',
            formatValue: row =>
              [row.address, row.building ? `корпус ${row.building}` : '', row.cabinet ? `кабинет ${row.cabinet}` : '']
                .filter(Boolean)
                .join(', '),
            styles: { width: '30%' },
          },
          {
            label: 'Подразделение',
            formatValue: row =>
              [
                row.department?.label,
                [row.departmentResponsible?.fullName, getPersonContacts(row.departmentResponsible?.contacts)]
                  .filter(Boolean)
                  .join(', '),
              ]
                .filter(Boolean)
                .join('<br />'),
            styles: { width: '30%' },
          },
          {
            label: 'Факультет/институт',
            formatValue: row =>
              [
                row.faculty?.label,
                [row.facultyResponsible?.fullName, getPersonContacts(row.departmentResponsible?.contacts)]
                  .filter(Boolean)
                  .join(', '),
              ]
                .filter(Boolean)
                .join('<br />'),
            styles: { width: '30%' },
          },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={isDisabled}
        maxHeight="500px"
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, setRow) => renderInstallLocationFields({ row, setRow }),
          validation: {
            checkIsValid: row => !!row?.address && !!row?.installDate,
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />
      <SectionTitle title="Контактные лица" />
      <ListEdit
        rows={equip.contacts}
        onChange={e => setEquip(prev => ({ ...prev, contacts: e }))}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          {
            label: 'Контактное лицо',
            formatValue: row =>
              (row?.contact?.fullName || '').concat(
                getPersonContacts(row.contact?.contacts) ? `: ${getPersonContacts(row.contact?.contacts)}` : '',
              ),
            styles: { width: '50%' },
          },
          { label: 'Примечание', formatValue: row => row?.note || '', styles: { width: '50%' } },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={isDisabled}
        maxHeight="500px"
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, setRow) => renderContactsFields({ row, setRow }),
          validation: {
            checkIsValid: row => !!row?.contact?.id,
            onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
          },
        }}
      />
    </>
  );
}

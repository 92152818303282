import React, { useMemo, useCallback } from 'react';

import { Modal } from 'components';

import { Form as F, Table } from 'types/models';
import { NirRequestLocalType } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { TenderRequestType } from 'utils/Enums';
// eslint-disable-next-line max-len
import { Component as ProgramRequestAddTemplate } from 'features/Table/specifications/GetRequestList/LeftPanelForThirdLevel/modalTemplates/RequestAddTemplate';
// eslint-disable-next-line max-len
import { Component as MobileRequestAddTemplate } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/modalTemplates/MobileRequestAddTemplate';
import { TenderInfo } from 'types/models/Form/look/programRequest';
// eslint-disable-next-line max-len
import { Component as NirRequestAddTemplate } from 'features/Table/specifications/GetNirRequestList/LeftPanelForThirdLevel/modalTemplates/NirRequestAddTemplate';
import { showNotification } from 'features/Notifications';

type DetailProps = {
  tenderRequestType: TenderRequestType | null;
  setTenderRequestType: React.Dispatch<TenderRequestType | null>;
  tenderInfo: TenderInfo | null;
  setTenderInfo: React.Dispatch<TenderInfo | null>;
};

const RequestAddTemplate: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  tenderRequestType,
  setTenderRequestType,
  tenderInfo,
  setTenderInfo,
}: F.TemplateProps & DetailProps) => {
  const nirRequest = useMemo<NirRequestLocalType>(
    () => ({
      id: null,
      specification: 'BASIC_RESEARCH',
      ...tenderInfo,
    }),
    [tenderInfo],
  );

  const selectRow = useCallback(
    (row: Table.Entry) => {
      if (row['id:RequestType'] === TenderRequestType.NIR_REQUEST && !row['id:NirTenderType']) {
        showNotification({ message: 'У выбранного конкурса отсутствует тип заявки', theme: 'danger' });
        return;
      }
      setTenderRequestType((row['id:RequestType'] as TenderRequestType) || null);
      setTenderInfo({
        tenderId: row.id,
        lotId: row.lotId,
      });
    },
    [setTenderInfo, setTenderRequestType],
  );

  return (
    <>
      {isOpen && (
        <>
          {tenderRequestType ? (
            <>
              {tenderRequestType === TenderRequestType.NIR_REQUEST && (
                <NirRequestAddTemplate isOpen onClose={onClose} relatedTableState={relatedTableState} nirRequest={nirRequest} />
              )}
              {tenderRequestType === TenderRequestType.PROGRAM_REQUEST && (
                <ProgramRequestAddTemplate
                  isOpen
                  onClose={onClose}
                  tenderInfo={tenderInfo}
                  relatedTableState={relatedTableState}
                />
              )}
              {tenderRequestType === TenderRequestType.MOBILE_REQUEST && (
                <MobileRequestAddTemplate
                  isOpen
                  onClose={onClose}
                  tenderInfo={tenderInfo}
                  relatedTableState={relatedTableState}
                  type="NPR"
                />
              )}
            </>
          ) : (
            <Modal
              isOpen
              onClose={onClose}
              title="Список открытых конкурсов на текущую дату"
              size={['extralarge', 'large-height']}
            >
              <DataGrid specification={GetTenderListForRequest({ isPc: true, selectRow })} />
            </Modal>
          )}
        </>
      )}
    </>
  );
};

export const Component = React.memo(RequestAddTemplate);

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

type Props = {
  country: ReferenceItem | null;
  onCountryChange(row: ReferenceItem): void;
};

function CountryInput({ country, onCountryChange }: Props) {
  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Страна" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={country}
            onChange={onCountryChange}
            settings={{ name: 'RefCountry', title: 'Справочник "Страны"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { CountryInput };

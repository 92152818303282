export function EquipVerificationGridHelp(): JSX.Element {
  return (
    <>
      <p>Пункт меню предназначен для ведения актуальной информации о поверке, калибровке, аттестации и ремонту оборудования.</p>
      <p>Для начала работы необходимо нажать на кнопку “Добавить” и выбрать из списка оборудование для заполнения данных.</p>

      <div className="warning" data-label="Внимание!">
        <span>
          Для того чтобы избежать дублирование записей по метрологическому сопровождению, при добавлении новой записи в списке
          оборудования отображается только то оборудование у которых отсутствует запись о метрологическом сопровождении.
        </span>
      </div>

      <p>
        Основной список оборудования загружается в систему из бухгалтерии, но, если необходимо по регламенту работы, то при
        наличии права пользователя есть возможность добавить это оборудование «вручную» в пункте меню Оборудование.
      </p>

      <h3>Форма для просмотра/создания/редактирования метрологического сопровождения содержит:</h3>

      <h4>Вкладка Поверка</h4>
      <p>
        Состоит из двух блоков: <strong>Характеристики для поверки</strong> и <strong>История поверок</strong>. Информация,
        заполненная на этой вкладке, будет отображаться в карточке оборудования на вкладке «Метрологическое сопровождение».
      </p>
      <p>
        В блок <strong>Характеристики для поверки</strong> необходимо внести данные по оборудованию, которые будут использованы
        для построения отчетов и для автоподстановки при создании записи в Истории поверок.
      </p>
      <div className="warning" data-label="Внимание!">
        <span>Для корректного построения отчетов необходимо заполнить все поля в этом блоке.</span>
      </div>
      <p>
        Таблица <strong>История поверок</strong> содержит информацию о прошедших поверках и ее результатах. При необходимости
        данные, которые были автоматически заполнены из характеристик для поверки можно отредактировать.
      </p>

      <h4>Вкладка Описание</h4>
      <p>
        Содержит основную информацию по оборудованию такую как наименование, марка, модель, производитель, год выпуска и тд. На
        вкладке так же отображаются классификаторы для оборудования его технические характеристики и состав комплекта.
        Предусмотрено добавление изображение оборудования (формат - *.png, *.jpg, *.jpeg, максимальные размеры - ширина: 500px,
        высота: 500px, размер - до 2Мб).
      </p>
      <div className="warning" data-label="Внимание!">
        <span>
          Внесение данных на этой вкладке будут автоматически обновлены в карточке оборудования в пункте меню Оборудование.
        </span>
      </div>

      <h4>Вкладка Место установки</h4>
      <p>
        Содержит Историю перемещения оборудования - дата перемещения, адрес - куда было установлено оборудование, ответственное
        подразделение, ответственный факультет и ответственные лица.
      </p>
      <p>
        В таблице Контактные лица отображается период и контактные данные сотрудника, который проводил работы на оборудовании, так
        же можно внести иную необходимую информацию в поле Примечание.
      </p>
      <div className="warning" data-label="Внимание!">
        <span>
          Внесение данных на этой вкладке будут автоматически обновлены в карточке оборудования в пункте меню Оборудование.
        </span>
      </div>

      <h4>Документы по метрологии</h4>
      <p>
        Стандартная вкладка для работы с документами. Здесь можно добавить/просмотреть/скачать прикреплённые файлы, например:
        свидетельство о поверке, инструкции и иные документы. На этой вкладке так же отображаются документы, которые были
        прикреплены в карточке в пункте меню Оборудование, в таком случае в столбце «Источник» будет пометка: «Оборудование».
      </p>
    </>
  );
}

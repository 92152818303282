import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Form } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { SecurityDocumentType } from 'utils/Enums';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onViewProjectInfo(): void;
  securityDocument: SecurityDocument | null | undefined;
  formFields: Form.Fields;
  disabled?: boolean;
};

export function useController(props: Props) {
  const { onSave, onSaveDraft, onViewProjectInfo, securityDocument, formFields, disabled } = props;

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(() => {
    handleSetCurrentReport({ name: Reports.SecurityDocumentAuthors.caption, value: Reports.SecurityDocumentAuthors.name });
  }, [handleSetCurrentReport]);

  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);
  const [isSaveConfirmPopupOpen, setIsSaveConfirmPopupOpen] = useState<boolean>(false);
  const [isSaveAndClose, setIsSaveAndClose] = useState<boolean>(false);

  const onSaveConfirmPopupConfirm = useCallback(() => {
    if (isSaveAndClose) {
      onSave(true);
    } else {
      onSaveDraft();
    }
    setIsSaveConfirmPopupOpen(false);
  }, [isSaveAndClose, onSave, onSaveDraft]);

  const datesMatched = useCallback(() => {
    if (securityDocument?.type?.value === SecurityDocumentType.KNOW_HOW) return false;
    return formFields?.balanceOrderNumber.value && formFields?.requestDate.value === formFields?.hiDate.value;
  }, [securityDocument, formFields]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => {
          if (datesMatched()) {
            setIsSaveAndClose(true);
            setIsSaveConfirmPopupOpen(true);
          } else {
            onSave(true);
          }
        },
        isDisabled: disabled,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => {
          if (datesMatched()) {
            setIsSaveAndClose(false);
            setIsSaveConfirmPopupOpen(true);
          } else {
            onSaveDraft();
          }
        },
        isDisabled: disabled,
      },
      {
        icon: { type: 'info' },
        title: 'Посмотреть описание проекта',
        onClick: onViewProjectInfo,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        onClick: onClickPrint,
      },
    ],
    [onClickPrint, onViewProjectInfo, datesMatched, onSave, onSaveDraft, disabled],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isReportOpen,
    onReportClose,
    currentReport,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
  };
}

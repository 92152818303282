import React, { memo, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit } from 'components';

import { Event, Form, Table } from 'types/models';
import { GetShortEventList } from 'features/Table/specifications';
import { getEventShortDescription, getMockEvent } from 'utils/Helpers/event';

type Props = {
  workMode: Table.WorkMode;
  relatedTableState: Table.State | undefined;
  formFields: Form.Fields;
  events: Event.Event[];
  setEvents(rows: Event.Event[]): void;
  disabled?: boolean;
};

export const Conferences = memo(({ workMode, relatedTableState, formFields, events, setEvents, disabled }: Props) => {
  const modalTableRowConverter = useCallback<(row: Table.Entry) => Event.Event>(
    row => ({
      ...getMockEvent(),
      id: row.id,
      name: row.name,
      status: { label: row.status, value: row['id:status'] },
    }),
    [],
  );

  const { methods: getEventData } = BackendAPI.useBackendAPI('GetEventById', {
    onSuccessfullCall: ({ data }) => {
      setEvents([...events, data as Event.Event]);
    },
  });

  const specifications = useMemo(() => {
    return GetShortEventList({
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
      },
      requestData: {
        eventType: 'CONFERENCE',
        allEvents: true,
      },
      isDisableSource: true,
      isCompilationsPlanned: true,
      hasSelectButton: true,
      hasFilter: true,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChange = (rows: Event.Event[]) => {
    const newRow = rows.find(({ id }) => !events.find(event => event.id === id));
    const removedRow = events.find(({ id }) => !rows.find(event => event.id === id));

    if (removedRow?.id) {
      setEvents(events.filter(event => event.id !== removedRow?.id));
    }
    if (newRow?.id) {
      getEventData.callAPI({ eventId: newRow?.id });
    }
  };

  return (
    <div>
      <ListEdit
        header={{ title: 'Конференции', isRequired: !!formFields.isConfirencesPublished.value }}
        rows={events}
        onChange={onChange}
        toolbar={['add', 'delete']}
        columns={[
          {
            formatValue: row => getEventShortDescription(row),
            label: 'Наименование, место и сроки проведения',
            styles: { width: '85%' },
          },
          {
            formatValue: row => row.status?.label || '',
            label: 'Статус записи',
            styles: { width: '15%' },
          },
        ]}
        specification={{
          mode: 'relationTableModal',
          modalTableRowConverter,
          relationTableModalTitle: 'Конференции',
          modalTableSpecification: specifications,
        }}
        isDisabled={!formFields.isConfirencesPublished.value || workMode === 'viewMode' || disabled}
      />
    </div>
  );
});

import { useState, useCallback, useEffect } from 'react';

import { Form, Event } from 'types/models';
import { EventFormat } from 'utils/Enums';
import { useFormContext } from 'features/Form/hooks';
import * as BackendAPI from 'services/BackendAPI';

export function useController() {
  const {
    look: { type, id },
  } = useFormContext<Form.EventFormLook>();

  const [compilations, setCompilations] = useState<Event.Compilations[]>([]);
  const [magazineReleases, setMagazineReleases] = useState<Event.Source[]>([]);
  const [participationPublications, setParticipationPublications] = useState<Event.Source[]>([]);
  const [departments, setDepartments] = useState<Event.Department[]>([]);
  const [statusText, setStatusText] = useState<string>('');
  const [eventTypeCode, setEventTypeCode] = useState<string>('');

  const { methods: getEvent } = BackendAPI.useBackendAPI('GetEvent');

  const fetchPublication = useCallback(() => {
    if (type) {
      getEvent.callAPI(
        {
          simpleFields: {
            type: type?.toUpperCase() || '',
            domain: 'EVENT',
            isInSummaryPlan: false,
            isCompilationPlanned: false,
            IsMagazineReleasePlanned: false,
            isStudent: false,
            memberCountPlan: 0,
            memberCountFact: 0,
          },
          attrIdFields: {},
          arrayFields: {
            Grntis: { translation: [] },
            MinistryEducations: { translation: [] },
            CriticalTechnologies: { translation: [] },
            Pnmitrs: { translation: [] },
            Pnrs: { translation: [] },
            Pnis: { translation: [] },
            Partners: { translation: [] },
          },
          id,
          format: EventFormat.OFFLINE,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setCompilations(data.compilations);
            setMagazineReleases(data.magazineReleases);
            setParticipationPublications(data.participationPublications);
            setDepartments(data.departments);
            setStatusText(data.status?.label || '');
            setEventTypeCode(data.type?.value || '');
          },
        },
      );
    }
  }, [getEvent, id, type]);

  useEffect(() => {
    if (id) fetchPublication();
    // eslint-disable-next-line
  }, [id]);

  return {
    compilations,
    magazineReleases,
    participationPublications,
    eventTypeCode,
    departments,
    statusText,
    id,
  };
}

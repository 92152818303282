import React, { useMemo } from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonProps, Toolbar } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { Table } from 'types/models';
import { useController } from './controller';
import { SubmitTable } from 'features/Table/streams';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?: (state: SubmitTable) => void;
};

const b = block('toolbar');

export const LeftPanelForThirdLevel = React.memo(({ customState, tableState, onSubmitTable }: Props) => {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    handleAddButtonClick,
    handleEditButtonClick,
    handleSelectClick,
    handleViewButtonClick,
    isEditButtonDisabled,
    isSelectButtonDisabled,
    isViewButtonDisabled,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleCloseEditForm,
    handleContinueStep,
  } = useController({ tableState, onSubmitTable });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: handleSelectClick,
        isDisabled: isSelectButtonDisabled,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: 'PublicationView',
        },
        isDisabled: isViewButtonDisabled,
      },
      ...(customState.editable
        ? [
            {
              icon: { type: 'add' },
              title: 'Добавить',
              onClick: handleAddButtonClick,
              permission: {
                name: 'PublicationAdd',
              },
            },
            {
              icon: { type: 'edit' },
              title: 'Редактировать',
              onClick: handleEditButtonClick,
              permission: {
                name: 'PublicationEdit',
              },
              isDisabled: isEditButtonDisabled,
            },
          ]
        : []),
    ],
    [
      customState.editable,
      handleAddButtonClick,
      handleEditButtonClick,
      handleSelectClick,
      handleViewButtonClick,
      isEditButtonDisabled,
      isSelectButtonDisabled,
      isViewButtonDisabled,
    ],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikePublication
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            componentId={SUCH_LIKE_PUBLICATION_ID}
          />
        </div>
      )}

      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
        eventId={customState.eventId}
      />

      {!isViewButtonDisabled && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          eventId={customState.eventId}
        />
      )}
    </>
  );
});

import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { useController } from './controller';
import { PaymentOrderGridHelp } from './help';

function Toolbar() {
  const { buttons, isOpenHelpForm, setIsOpenHelpForm } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Зачислено на шифры проектов"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {PaymentOrderGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

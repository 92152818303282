import { useCallback, useMemo } from 'react';

import { FormComponent, SectionTitle, TextArea, TextAreaMode, TextDateTime, TextInput, TextInputMode } from 'components';

import { ValueOf } from 'types/helpers';
import { MipPartner } from 'types/models';
import { GetPartnerMipList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { Original } from 'types/models/Form';
import { useAppDataContext } from 'features/AppData/context';
import { CalcedIndicator } from '..';

type Props = {
  indicator: MipPartner.Indicator;
  setIndicator(indicator: MipPartner.Indicator): void;
  calcByIndicatorYear?: (indicator: MipPartner.Indicator) => CalcedIndicator;
  isPartnerDisplayed?: boolean;
  workMode?: Table.WorkMode;
};

export const Fields = ({ indicator, setIndicator, calcByIndicatorYear, isPartnerDisplayed, workMode }: Props) => {
  const { settings } = useAppDataContext();

  const makeHandler = (key: keyof MipPartner.Indicator) => (value: ValueOf<MipPartner.Indicator> | Original) => {
    setIndicator({
      ...indicator,
      [key]: value,
    });
  };

  const mipPartnerRowConverter = useCallback<(row: Table.Entry) => Original>(row => {
    return {
      id: row.id || '',
      name: row.FullName,
      fullName: row.FullName,
    };
  }, []);

  const dataYear = (
    <>
      {isPartnerDisplayed && (
        <FormComponent.Line>
          <FormComponent.Field label="МИП" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Партнёры',
                isClearable: false,
                table: {
                  specification: GetPartnerMipList({ hasSelectButton: true }),
                  onSelect: (row: Table.Entry | null) => makeHandler('partner')(row ? mipPartnerRowConverter(row) : null),
                },
              }}
              value={indicator?.partner?.fullName}
              isDisabled={workMode !== 'addMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Год">
          <TextInput
            mode={TextInputMode.YEAR}
            value={indicator.year ?? ''}
            onChange={makeHandler('year')}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Среднесписочная численность, чел.">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={indicator.averageHeadcount ?? ''}
            onChange={makeHandler('averageHeadcount')}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Выручка">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={indicator.revenue ?? ''}
            onChange={makeHandler('revenue')}
            settings={{ isNatural: false }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Чистая прибыль">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={indicator.profit ?? ''}
            onChange={makeHandler('profit')}
            settings={{ isNatural: false }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Дата сдачи отчёта">
          <TextDateTime value={indicator.reportDate} onChange={makeHandler('reportDate')} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );

  if (!calcByIndicatorYear) {
    return <FormComponent.Wrapper>{dataYear}</FormComponent.Wrapper>;
  }

  const calcedData = useMemo(() => calcByIndicatorYear(indicator), [calcByIndicatorYear, indicator]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '50%' }}>
          <SectionTitle title="Показатели года" />

          {dataYear}
        </FormComponent.Column>

        <FormComponent.Column style={{ width: '50%' }}>
          <SectionTitle title="Расчёт дивидендов" />

          <FormComponent.Line>
            <FormComponent.Field label="Ставка налога с дивидендов, %">
              <TextInput
                mode={TextInputMode.NUMBER}
                value={indicator.dividendTaxPercent ?? ''}
                onChange={makeHandler('dividendTaxPercent')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={`Доля дивидендов ${settings?.organization?.shortName}`}>
              <FormComponent.Text>{calcedData.amountPercent} %</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Общая сумма дивидендов, руб.">
              <TextInput
                mode={TextInputMode.NUMBER}
                value={indicator.dividendAmount ?? ''}
                onChange={makeHandler('dividendAmount')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={`Сумма дивидендов ${settings?.organization?.shortName}`}>
              <FormComponent.Text>{calcedData.amount} руб.</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Сумма налога с дивидендов">
              <FormComponent.Text>{calcedData.percent} руб.</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={`Сумма дивидендов ${settings?.organization?.shortName}, без налога`}>
              <FormComponent.Text>{calcedData.result} руб.</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
};

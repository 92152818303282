import { Icon } from 'components';

export function FundCardPositionGridHelp(): JSX.Element {
  return (
    <>
      <p>Таблица содержит список шифров проектов, для которых</p>

      <ul>
        <li>Указан шифр накладных расходов</li>
        <li>Создана смета на год</li>
        <li>В смете указана статья расходов для накладных расходов</li>
      </ul>

      <h3>Особенности отображения информации в столбцах таблицы:</h3>

      <p>
        <strong>План сметы</strong> — плановая сумма в смете по статье затрат <strong>Накладные расходы</strong>
      </p>
      <div>
        <strong>Факт</strong> — сумма, вычисляемая автоматически при получении из бухгалтерии приходного документа по формуле:
        сумма документа * % НР, расчётный.{' '}
        <strong>Список документов бухгалтерского учёта о поступлении средств на шифр проекта</strong> можно посмотреть с помощью
        функциональной кнопки <Icon type="list" mode="question" />
      </div>
      <p>
        <strong>Остаток</strong> = План сметы - Факт
      </p>
      <p>
        <strong>% НР, нормативный</strong> — нормативный процент накладных расходов, <strong>указанный в проекте</strong> с
        проекте на вкладке <strong>Особенности</strong>
      </p>
      <p>
        <strong>Программа</strong> — краткое название программы, указанной в проекте. Может отсутствовать
      </p>
      <p>
        <strong>Уменьшает накладные</strong> — статьи затрат с атрибутом «Уменьшает накладные» в смете шифра проекта
      </p>
      <p>
        <strong>% НР, расчётный</strong> — расчётный процент накладных расходов <strong>вычисляется в смете шифра</strong> шифра с
        учётом сумм по статьям затрат, которые имеют атрибут <strong>Уменьшает накладные</strong>
      </p>
      <div>
        <strong>История изменения процента накладных расходов для шифра проекта</strong> — можно посмотреть с помощью
        функциональной кнопки <Icon type="messages" />
      </div>
    </>
  );
}

import { useCallback, useMemo, useState } from 'react';

import { ExtraToolbarButton } from 'components/ListEdit/model';
import { MipPartner } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';

export function useController() {
  const { settings } = useAppDataContext();

  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<MipPartner.CharterCapital>[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [],
  );

  const formatFounders = useCallback(
    (capital: MipPartner.CharterCapital) => {
      return capital.founders
        .map(f => {
          if (f.isLocal) {
            return settings?.organization?.shortName;
          }
          return f.enterpriseCustomer?.id ? f.enterpriseCustomer?.label : f.scientist?.fullName;
        })
        .join(', ');
    },
    [settings?.organization?.shortName],
  );

  const formatAsserts = useCallback((capital: MipPartner.CharterCapital) => {
    return capital.founders
      .map(f => f.insertionAssets)
      .filter(Boolean)
      .join(', ');
  }, []);

  return {
    selectedRowIndex,
    setSelectedRowIndex,
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
    formatFounders,
    formatAsserts,
  };
}

import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Person } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { validate } from './validate';
import { ValueOf } from 'types/helpers';

type Props = {
  scientistId: string;
  hirshIndexSource: Person.HirshIndex;
  onClose(): void;
};

export const useController = ({ scientistId, hirshIndexSource, onClose }: Props) => {
  const tableStreams = useLocalTableStreams();

  const [hirshIndex, setHirshIndex] = useState<Person.HirshIndex>({ ...hirshIndexSource });

  const { methods: addNewScientistHirshIndexAPIMethods } = BackendAPI.useBackendAPI('SaveScientistHirshIndex', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Запись успешно сохранена', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const saveForm = useCallback(() => {
    if (validate(hirshIndex)) {
      addNewScientistHirshIndexAPIMethods.callAPI({
        hirshIndexID: hirshIndex.id || undefined,
        scientistID: scientistId,
        date: hirshIndex.date,
        citationSystemID: hirshIndex.citationSystem?.id || '',
        value: hirshIndex.value || undefined,
        identifier: hirshIndex.identifier,
        note: hirshIndex.note || undefined,
      });
    }
  }, [addNewScientistHirshIndexAPIMethods, hirshIndex, scientistId]);

  const makeHandler = (key: keyof Person.HirshIndex) => (value: ValueOf<Person.HirshIndex>) => {
    setHirshIndex({
      ...hirshIndex,
      [key]: value,
    });
  };

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => {
          saveForm();
          onClose();
        },
      },
    ],
    [onClose, saveForm],
  );

  useEffect(() => setHirshIndex({ ...hirshIndexSource }), [hirshIndexSource]);

  return {
    buttons,
    hirshIndex,
    makeHandler,
  };
};

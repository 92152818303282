import { Table as T } from 'types/models';

type Arguments = {
  id: string;
};

type RequestData = {
  id: string;
};

export function GetParticipationUsagesList({ id }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetParticipationUsagesList',
    requestData: { id },
    header: {
      firstLevel: {
        title: 'Связи участия с другими объектами',
        Panel: undefined,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: undefined,
        hasSelectButton: true,
      },
    },
  };
}

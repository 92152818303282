import { useState, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Equip } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { getMockEquipVerificationContact } from './helpers';
import { showNotification } from 'features/Notifications';

type Props = {
  viewMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, onClose }: Props) {
  const {
    look: { id: initialId, setTitle },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const [equipVerificationContact, setEquipVerificationcontact] = useState<Equip.EquipVerificationContact>(
    getMockEquipVerificationContact(),
  );

  const { methods: getEquipVerificationContact } = BackendAPI.useBackendAPI('GetEquipVerificationContact', {
    onSuccessfullCall: ({ data }) => {
      setEquipVerificationcontact(data);
    },
  });

  const { methods: saveEquipVerificationContact } = BackendAPI.useBackendAPI('SaveEquipVerificationContact');

  const onSubmit = useCallback(
    (isSaveLoad?: boolean) => {
      if (!equipVerificationContact.scientist) {
        showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
        return;
      }
      saveEquipVerificationContact.callAPI(equipVerificationContact, {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Запись сохранена', theme: 'success' });
          if (isSaveLoad) {
            getEquipVerificationContact.callAPI({ id: data.id });
            return;
          }
          tableStreams.reloadTable.push({});
          onClose();
        },
      });
    },
    [equipVerificationContact, getEquipVerificationContact, onClose, saveEquipVerificationContact, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSubmit(),
        isDisabled: viewMode,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSubmit(true),
        isDisabled: viewMode,
      },
    ],
    [onSubmit, viewMode],
  );

  useEffect(() => {
    const id = initialId || equipVerificationContact.id;

    const modeInfo = (() => {
      if (id) {
        if (viewMode) return 'Просмотр';
        else return 'Редактирование';
      }
      return 'Добавление';
    })();

    const idInfo = id ? `(ID ${id})` : '';

    setTitle?.([modeInfo, 'контактного лица', idInfo].filter(Boolean).join(' '));
  }, [equipVerificationContact.id, initialId, setTitle, viewMode]);

  useLayoutEffect(() => {
    if (initialId) getEquipVerificationContact.callAPI({ id: initialId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    equipVerificationContact,
    setEquipVerificationcontact,
    buttons,
  };
}

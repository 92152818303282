import React, { useCallback, useEffect, useState } from 'react';

import { ButtonMode, TextArea, FormComponent, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { approverKindTitleMap } from 'types/models/PurchaseRequest';
import { PurchaseRequestApprovementApprover } from 'utils/Enums';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(message?: string, approverKind?: PurchaseRequestApprovementApprover | null): void;
  approverKind: PurchaseRequestApprovementApprover | null;
};

export function PurchaseRequestApprovementModal(props: Props) {
  const { isOpen, onClose, onSubmit, approverKind } = props;

  const [message, setMessage] = useState('');

  const handleClose = useCallback(() => {
    setMessage('');
    onClose();
  }, [onClose]);

  const handleSubmit = useCallback(() => {
    const submit = () => {
      onSubmit?.(message, approverKind);
      handleClose();
    };
    if (message) {
      submit();
    } else {
      showNotification({ message: 'Для выбранной резолюции необходимо ввести комментарий', theme: 'danger' });
    }
  }, [handleClose, onSubmit, message, approverKind]);

  useEffect(() => {
    setMessage('');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title={approverKind ? approverKindTitleMap[approverKind] : ''}
      isOpen={isOpen}
      onClose={handleClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Сохранить',
          onClick: handleSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <FormComponent.Field label="Замечания (не более 1000 знаков с пробелами)">
        <TextArea settings={{ rows: 4, isFixed: true }} value={message} onChange={setMessage} />
      </FormComponent.Field>
    </Modal>
  );
}

import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';
import { GetReferenceElementList } from 'features/Table/specifications';

const ExhibitTypeParameterList = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    tableModalProps={{
      modalTableSpecification: GetReferenceElementList({
        requestData: {
          filters: [],
          name: 'RefTypeExposition',
        },
      }),
      listEditTitle: 'Список типов выставок',
      relationTableModalTitle: 'Справочник "Типы выставок"',
    }}
  />
);

const Component = memo(ExhibitTypeParameterList);

export { Component as ExhibitTypeParameterList };

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Item } from 'types/models/common';

type Props = {
  partner: Partner | null;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  disabled: boolean;
};

export function AddressAndDetails(props: Props) {
  const { makeChangeHandler, partner, disabled } = props;

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Страна">
              <Select
                mode={SelectMode.REFERENCE}
                value={partner?.country}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('country')(option)}
                settings={{ name: 'RefCountry', title: 'Справочник "Страны"', isClearable: true }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Регион">
              <Select
                mode={SelectMode.SUGGESTION}
                value={{ value: partner?.district || '', label: partner?.district || '' }}
                onChange={(option: Item | null) => makeChangeHandler('district')(option?.value || '')}
                settings={{ name: 'ref_addresses.district' }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Город/Нас. пункт">
              <Select
                mode={SelectMode.SUGGESTION}
                value={{ value: partner?.city || '', label: partner?.city || '' }}
                onChange={(option: Item | null) => makeChangeHandler('city')(option?.value || '')}
                settings={{ name: 'ref_addresses.city' }}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Индекс">
              <TextInput value={partner?.postCode ?? ''} onChange={makeChangeHandler('postCode')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Улица">
              <TextInput value={partner?.street ?? ''} onChange={makeChangeHandler('street')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Дом, корпус">
              <TextInput value={partner?.house ?? ''} onChange={makeChangeHandler('house')} isDisabled={disabled} />
            </FormComponent.Field>
            <FormComponent.Field label="Офис" labelSize="fit">
              <TextInput value={partner?.office ?? ''} onChange={makeChangeHandler('office')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="E-Mail">
              <TextInput value={partner?.email ?? ''} onChange={makeChangeHandler('email')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Телефон">
              <TextInput value={partner?.phone ?? ''} onChange={makeChangeHandler('phone')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Веб сайт">
              <TextInput value={partner?.webSite ?? ''} onChange={makeChangeHandler('webSite')} isDisabled={disabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

import React, { useCallback, useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Checkbox, CheckboxMode, FormComponent, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetNotificationLogList, GetNotificationRecipientList } from 'features/Table/specifications';

const Notifications = () => {
  const [enableNotificationGrid, setEnableNotificationGrid] = useState<boolean>(false);

  const { methods: GetSettings } = BackendAPI.useBackendAPI('GetSettings');
  const { methods: SetSettings } = BackendAPI.useBackendAPI('SetSettings');

  const SETTING_NAME = 'Notification.Enabled';

  const handleChangeNotificationsGrid = useCallback(
    (value: boolean) => {
      SetSettings.callAPI(
        { name: SETTING_NAME, type: 'BOOLEAN', text: value.toString() },
        {
          onSuccessfullCall: () => {
            setEnableNotificationGrid(value);
          },
        },
      );
    },
    [SetSettings],
  );

  useEffect(() => {
    GetSettings.callAPI(
      { prefix: 'Notification' },
      {
        onSuccessfullCall: ({ data }) => {
          setEnableNotificationGrid(!!data.notification?.enabled);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Tabs>
      <Tab title="Настройка уведомлений">
        <FormComponent.Line>
          <Checkbox
            mode={CheckboxMode.SLIDER}
            label="Включить подсистему уведомлений:"
            checked={enableNotificationGrid}
            onChange={handleChangeNotificationsGrid}
          />
        </FormComponent.Line>
        <DataGrid specification={GetNotificationRecipientList()} isDisabled={!enableNotificationGrid} />
      </Tab>
      <Tab title="Журнал уведомлений">
        <DataGrid specification={GetNotificationLogList()} />
      </Tab>
    </Tabs>
  );
};

export const Component = React.memo(Notifications);

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { Form, Table } from 'types/models';
import { getIsCanEdit } from 'utils/Helpers/feedline/getIsCanEdit';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

const initMessage: Form.MagazineMessage = {
  id: '',
  message: '',
};

const initMessageLabel: Form.MessageLabel = {
  id: '1',
  label: '',
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const formContext = useFormContext();

  const publicationId = formContext.look.id;
  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<Form.MagazineMessage>(initMessage);
  const [messageLabel, setMessageLabel] = useState<Form.MessageLabel>(initMessageLabel);

  const isEditButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const isDeleteButtonDisabled = useMemo(() => !(rowsAvailability.SINGLE_SELECTED && getIsCanEdit(tableState.selectedRows)), [
    rowsAvailability.SINGLE_SELECTED,
    tableState.selectedRows,
  ]);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const { methods: addNewMessage } = BackendAPI.useBackendAPI('SavePublicationUserFeedline', {
    onSuccessfullCall: ({ data }) => {
      if (!data.success) {
        showNotification({ theme: 'danger', message: data.message });

        setPublicationMessage(initMessage);
        setMessageLabel(initMessageLabel);
        return;
      }
      if (publicationId) {
        tableStreams.reloadTable.push({});
      }
      setPublicationMessage(initMessage);
      setMessageLabel(initMessageLabel);
    },
  });

  const addNewGridSetting = useCallback(() => {
    const data = {
      message: publicationMessage.message,
      messageId: publicationMessage.id,
      labelId: messageLabel.id,
      publicationId: publicationId || '',
    };

    if (data.message !== undefined && data.labelId && data.publicationId) {
      addNewMessage.callAPI(data);
    }
  }, [addNewMessage, messageLabel.id, publicationId, publicationMessage.id, publicationMessage.message]);

  const changeMessageState = useCallback(
    ({ id, message }: Partial<Form.MagazineMessage>) => {
      setPublicationMessage({
        id: id || publicationMessage.id,
        message: message !== undefined ? message : publicationMessage.message,
      });
    },
    [setPublicationMessage, publicationMessage],
  );

  const handleAddButtonClick = useCallback(() => {
    setIsFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setPublicationMessage(initMessage);
    setMessageLabel(initMessageLabel);
    setIsFormOpen(false);
  }, []);

  const handleMessageChange = useCallback(
    (message: string) => {
      changeMessageState({ message });
    },
    [changeMessageState],
  );

  const handleMessageLabelChange = useCallback((label: ReferenceItem) => {
    setMessageLabel(label);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    const editingMessage = tableState.selectedRows[0];

    changeMessageState({
      id: editingMessage.id,
      message: editingMessage.Detail,
    });

    setMessageLabel({
      id: editingMessage['id:Message'],
      label: editingMessage.Message,
    });

    setIsFormOpen(true);
  }, [changeMessageState, tableState.selectedRows]);

  const handleFormSubmit = useCallback(() => {
    addNewGridSetting();
    setIsFormOpen(false);
  }, [addNewGridSetting]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'PublicationFeedline',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled: isDeleteButtonDisabled,
      },
    ],
    [handleAddButtonClick, handleEditButtonClick, handleDeleteButtonClick, isEditButtonDisabled, isDeleteButtonDisabled],
  );

  return {
    handleFormSubmit,
    handleTemplateCloseAddForm,
    isFormOpen,
    publicationMessage,
    handleMessageChange,
    messageLabel,
    handleMessageLabelChange,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
  };
}

export type State = {
  setProjectId(projectId: string): void;
};

type Props = {
  setProjectId(projectId: string): void;
};

export function makeUseCustomController({ setProjectId }: Props) {
  return function useCustomController(): State {
    return {
      setProjectId,
    };
  };
}

import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const ProjectReportViewTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetProjectReport',
    template: 'ProjectReportForm',
    viewMode: true,
    id,
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Просмотр отчёта по этапу проекта" size={['large', 'medium-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ProjectReportViewTemplate);

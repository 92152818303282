import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { EntityLocker } from 'features/EntityLocker';

import { Modal } from 'components';

type Props = F.TemplateProps;

const ContestParticipationEditTemplate: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  disabledEvent,
  disableUpdateAfterSubmit,
}: Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: 'ParticipationFormContest',
    id,
    relatedTableState,
    editMode: true,
    disabledEvent,
    disableUpdateAfterSubmit,
    setTitle,
  };

  return (
    <EntityLocker onClose={onClose} isOpen={isOpen} entity="Participation" id={look.id}>
      <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
        <Form look={look} onClose={onClose} />
      </Modal>
    </EntityLocker>
  );
};

export const Component = React.memo(ContestParticipationEditTemplate);

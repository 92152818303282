import React from 'react';
import { block } from 'bem-cn';
import { uploadAPI } from 'services/BackendAPI';

import { Icon } from 'components';

import { getFileUrl } from 'utils/Helpers';
import { isSizeError, isExtensionError, isDimensionsError } from './validate';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { FileInfo } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';
import { ColorBase } from 'constants/colors';

import './style.scss';

const b = block('upload-image');

export type UploadImageParamsDimensions = {
  /** @property {number} width - (optional) Element width */
  width?: number;
  /** @property {number} height - (optional) Element height */
  height?: number;
};

export type UploadImageParams = {
  /** @property {number} size - (optional) File size on Megabyte (Mb) */
  size?: number;
  /** @property {UploadImageParamsDimensions} dimensions - (optional) Physic params on render */
  dimensions?: UploadImageParamsDimensions;
  /** @property {UploadImageParamsDimensions} dimensions - (optional) Extensions without * or . (example - see defaultParams) */
  extensions?: string[];
};

/** Default image upload params */
const defaultParams: UploadImageParams = {
  size: 2,
  dimensions: {
    width: 500,
    height: 500,
  },
  extensions: ['png', 'jpg', 'jpeg'],
};

type Props = {
  /** @property {string} text - (optional) Title text if image not rendered */
  text?: string;
  /** @property {string} id - (optional) File id */
  id?: string;
  /** @property {UploadImageParams} params - Image upload params (defaultParams by default) */
  params?: UploadImageParams;
  /** @property {boolean} viewMode - (optional) Image ony for view (without upload) */
  viewMode?: boolean;
  /** @property {function} onChange - (optional) Callback on image file uploaded/changed */
  onChange?(fileInfo: FileInfo): void;
  /** @property {string} backgroundId - (optional) Background image id if param id not exists (secondary background image) */
  backgroundId?: string;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
};

/**
 * Image file uploader/viewer
 *
 * @param text - (optional) Title text if image not rendered
 * @param id - (optional) File id
 * @param params - (optional) Image upload params (See defaultParams)
 *   @param params.size - (optional) File size on Megabyte (Mb)
 *   @param params.dimensions - (optional) Physic params on render
 *     @param params.dimensions.width - (optional) Max width of image
 *     @param params.dimensions.height - (optional) Max height of image
 *   @param params.extensions - (optional) Extensions without * or . (example - see defaultParams)
 * @param viewMode - (optional) Image ony for view (without upload)
 * @param onChange - (optional) Callback on image file uploaded/changed
 * @param backgroundId - (optional) Background image id if param id not exists (secondary background image)
 * @param style - (optional) Styles to component
 * @returns JSX
 */
export const UploadImage = React.memo((props: Props) => {
  const { text = 'Изображение', id = '', viewMode = false, onChange = () => {}, backgroundId = '', style } = props;

  const { userToken: token } = useAppDataContext();

  const params = {
    ...defaultParams,
    ...(props?.params as UploadImageParams),
  };

  const formats = (params.extensions || []).map(x => `*.${x}`).join(', ');
  const limits = params.dimensions as UploadImageParamsDimensions;
  const sizesText = `ширина: ${limits.width}px, высота: ${limits.height}px`;

  const onChangeClick = (e: any) => {
    e.preventDefault();

    const fileReader = new FileReader();
    const file = e.target.files[0]; // Only one file (can be to multiple)

    if (!file) return;

    if (isSizeError({ file, size: params.size || 0 })) {
      showNotification({ theme: 'danger', message: 'Вы превысили допустимый размер файла' });
      return;
    }

    if (isExtensionError({ file, extensions: params.extensions || [] })) {
      showNotification({
        theme: 'danger',
        message: `Выбран некорректный тип файла. Загрузите изображение в формате ${formats}`,
      });
      return;
    }

    fileReader.onloadend = () => {
      const img = new Image();
      img.src = fileReader.result as string;

      img.onload = () => {
        const upload: UploadImageParamsDimensions = {
          width: img.naturalWidth,
          height: img.naturalHeight,
        };

        if (isDimensionsError({ upload, limits })) {
          showNotification({
            theme: 'danger',
            message: `Максимальные размеры изображения - ${sizesText}`,
          });
          return;
        }

        uploadAPI({
          file,
          token,
          onSuccess: fileId => {
            onChange({ id: fileId, name: file.name || '', size: file.size || 0 } as FileInfo);
          },
          onFail: error => {
            console.warn('fail', error);
          },
        });
      };
    };

    fileReader.readAsDataURL(file);

    e.target.value = null;
  };

  const onDeleteClick = () => {
    if (!onChange) {
      return;
    }
    onChange(EMPTY_FILE);
  };

  return (
    <div className={b()} style={style}>
      <div className={b('empty')} />

      {!!id || !!backgroundId ? (
        <div className={b('img')} style={{ backgroundImage: `url('${getFileUrl(id || backgroundId, token)}')` }}></div>
      ) : (
        !viewMode && (
          <div className={b('default')}>
            <div className={b('default-name')}>{text}</div>
            <div className={b('default-criteria')}>Формат - {formats}</div>
            <div className={b('default-criteria')}>Максимальные размеры - {sizesText}</div>
            <div className={b('default-criteria')}>Размер - до {params.size || 0}Мб</div>
          </div>
        )
      )}

      {!viewMode && (
        <>
          <label className={b('control-label add')} title={`Загрузить ${text.toLowerCase()}`}>
            <Icon type="add" color={ColorBase.clear} style={{ margin: '10px' }} />
            <input
              type="file"
              className={b('control-input')}
              name="photo"
              accept={(params.extensions || []).map(x => `.${x}`).join(',')}
              onChange={onChangeClick}
            />
          </label>
          {!!id && (
            <label className={b('control-label remove')} title={`Удалить ${text.toLowerCase()}`}>
              <Icon type="remove" color={ColorBase.clear} style={{ margin: '10px' }} />
              <input type="button" className={b('control-input')} onClick={onDeleteClick} />
            </label>
          )}
        </>
      )}
    </div>
  );
});

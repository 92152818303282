import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { MergeScientistCollectionRequest, SaveMergeCollectionRequest } from './types';
import { convertMergeScientistCollectionToRequest, convertSaveMergeCollectionToRequest } from './requestConverters';
import { convertMerge } from './converters';

export const mergeScientistCollectionConfigurations = {
  MergeScientistCollection: makeBackendAPIConfiguration({
    id: 'MergeScientistCollection',
    endpoint: '/msa/service/commands/MergeScientistCollection',
    convertInputToXMLElement: (mergeScientistRequest: MergeScientistCollectionRequest) => ({
      Request: {
        _attr: { commandName: 'MergeScientistCollection' },
        ...convertMergeScientistCollectionToRequest(mergeScientistRequest),
      },
    }),
    convertResponseXMLElementToOutput: convertMerge,
  }),
  SaveMergeCollection: makeBackendAPIConfiguration({
    id: 'SaveMergeCollection',
    endpoint: '/msa/service/commands/MergeScientistCollection',
    convertInputToXMLElement: (mergeScientistRequest: SaveMergeCollectionRequest) => ({
      Request: {
        _attr: { commandName: 'MergeScientistCollection' },
        ...convertSaveMergeCollectionToRequest(mergeScientistRequest),
      },
    }),
    convertResponseXMLElementToOutput: convertMerge,
  }),
};

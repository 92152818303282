import { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { Item } from 'types/models/common';

type ExpensesChartData = {
  kind: string;
  share: number;
  title: string;
};

export function useController() {
  const [studyProjectListByPeriod, setStudyProjectListByPeriod] = useState<ExpensesChartData[]>([]);
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>(new Date().getFullYear().toString());
  const [isLoading, setIsLoading] = useState(false);

  const yearslist = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const periodOpts = useRef(
    yearslist.map(yearItem => ({
      value: yearItem.toString(),
      label: `${yearItem} год`,
    })),
  );

  const { methods: GetDashboardChartByStudyProjectListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByStudyProjectListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const chartAttrRes = data.Response.Grid.Rows.Row._attributes;
        setStudyProjectListByPeriod([
          {
            kind: 'Сельское хозяйство',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Agriculture`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Agriculture`],
          },
          {
            kind: 'Инженерия',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Ingeneer`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Ingeneer`],
          },
          {
            kind: 'Математика',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Math`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Math`],
          },
          {
            kind: 'Медицина',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Medical`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Medical`],
          },
          {
            kind: 'Общественные науки',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Society`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Society`],
          },
          {
            kind: 'Педагогика',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Pedagogical`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Pedagogical`],
          },
          {
            kind: 'Гуманитарные науки',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Humanitarial`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Humanitarial`],
          },
          {
            kind: 'Искусство',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Art`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Art`],
          },
          {
            kind: 'Военные науки',
            share: Number(chartAttrRes[`Year${activePeriodFilter}Military`]) || 0,
            title: chartAttrRes[`Year${activePeriodFilter}Military`],
          },
        ]);
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    GetDashboardChartByStudyProjectListStatic.callAPI({
      year: activePeriodFilter,
      rowQuantity: 1,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePeriodFilter]);

  const getChartSumResult = () => {
    let sum = 0;
    studyProjectListByPeriod.forEach(item => {
      sum += item.share;
    });
    return new Intl.NumberFormat().format(sum);
  };

  const getNumberSumResult = () => {
    let sum = 0;
    studyProjectListByPeriod.forEach((item: any) => {
      sum += item.share;
    });
    return sum;
  };

  const renderEntry = useCallback((x: any) => x, []);

  const handleChangeActiveFilter = useCallback(
    (option: Item | null) => {
      setActivePeriodFilter(option?.value || '');
    },
    [setActivePeriodFilter],
  );

  const activeFilter = useMemo(() => periodOpts.current.find(opt => opt.value === activePeriodFilter), [
    periodOpts,
    activePeriodFilter,
  ]);

  return {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    renderEntry,
    isLoading,
    studyProjectListByPeriod,
    getChartSumResult,
    getNumberSumResult,
  };
}

import React, { useCallback } from 'react';

import { Table } from 'types/models';
import { EditProject } from 'features/Form/looks/project/ProjectForm';
import { State } from '../makeUseCustomController';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const {
    projectId,
    isOpenForm,
    programRequestId,
    nirRequestId,
    securityDocumentContractId,
    mode,
    reloadTable,
    closeForm,
    type,
    projectCopyOptions,
    prototypeId,
  } = customState;

  const [row] = tableState.selectedRows;

  const onSussessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [closeForm, reloadTable]);

  return (
    <EditProject
      id={mode === 'add' ? (!!projectId ? projectId : null) : row?.id || null}
      isOpen={isOpenForm}
      onClose={closeForm}
      mode={mode}
      onSuccessfullSave={onSussessSave}
      programRequestId={programRequestId}
      nirRequestId={nirRequestId}
      securityDocumentContractId={securityDocumentContractId}
      projectType={type}
      projectCopyOptions={projectCopyOptions || undefined}
      prototypeId={prototypeId || undefined}
    />
  );
}

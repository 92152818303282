import { Table as T } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetJointEventFeedlineList(
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
): T.Specification<{}> {
  const toolbarEnabled: boolean = workMode === 'editMode';

  return {
    apiID: 'GetJointEventFeedlineList',
    templatesTableDependencies,
    workMode,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarEnabled ? LeftPanelForThirdLevel : undefined,
      },
    },
  };
}

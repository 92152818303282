import { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { showNotification } from 'features/Notifications';
import { Table as T } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: T.State;
  roleId?: string;
};

export function useController({ tableState, roleId }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id },
  } = useFormContext();

  const selectedRows = tableState.selectedRows;

  const { methods: activateRolePermissionsAPIMethods } = BackendAPI.useBackendAPI('ActivateRolePermissions', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Права роли успешно сохранены', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const activateRolePermissions = useCallback(
    (activate: boolean) => {
      const permissions = tableState.selectedRows.length ? tableState.selectedRows.map(x => x.id) : [];
      activateRolePermissionsAPIMethods.callAPI({
        roleId: id || roleId || '-1',
        activate,
        permissions,
      });
    },
    [activateRolePermissionsAPIMethods, id, roleId, tableState.selectedRows],
  );

  const handleActivateButtonClick = useCallback(() => {
    activateRolePermissions(true);
  }, [activateRolePermissions]);

  const handleDeactivateButtonClick = useCallback(() => {
    activateRolePermissions(false);
  }, [activateRolePermissions]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Добавить права роли',
        onClick: handleActivateButtonClick,
        isDisabled: !selectedRows.find(x => x.IsActive === 'false'),
      },
      {
        icon: { type: 'activate', mode: 'remove' },
        title: 'Удалить права у роли',
        onClick: handleDeactivateButtonClick,
        isDisabled: !selectedRows.find(x => x.IsActive === 'true'),
      },
    ],
    [handleActivateButtonClick, handleDeactivateButtonClick, selectedRows],
  );

  return { buttons };
}

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetProjectPerformerJobPeriodList, GetProjectPerformerOrderList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ProjectOrders() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['projects', 'project-orders'],
    init: ['members'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Список участников проектов" id="members">
          <DataGrid specification={GetProjectPerformerJobPeriodList()} />
        </Tab>
        <Tab title="Приказы" id="list">
          <DataGrid specification={GetProjectPerformerOrderList({ projectSelectable: true })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import { Participation, Rntd } from 'types/models';

export function getMockParticipation(item?: Partial<Participation>): Participation {
  return {
    awards: [],
    collectiveExhibitName: '',
    createdBy: '',
    departments: [],
    development: '',
    documents: [],
    event: null,
    exhibitType: null,
    id: '',
    incentivePaymentRequest: '',
    isAllowEdit: false,
    isUserOwned: true,
    isCollectiveExhibit: false,
    members: [],
    mobileRequests: [],
    note: '',
    participationProcedure: '',
    projects: [],
    publication: null,
    reportDate: '',
    reportTheme: '',
    reportType: null,
    status: { label: '', value: '' },
    teamName: '',
    presentationForms: [],
    form: null,
    financingProjects: [],
    presentationProject: null,
    isPublished: false,
    ...item,
  };
}

export function getMockRntd(): Rntd {
  return {
    id: null,
    name: '',
    code: '',
    commercializationProtocol: '',
    commercializationDate: '',
    commercializationDecision: null,
    fctekProtocol: '',
    fctekDate: '',
    fctekDecision: null,
    selfProductionNumber: '',
    selfProductionDate: '',
    selfProductionNote: '',
    description: '',
    scope: '',
    project: null,
    keyWords: '',
    subjects: [],
    authors: [],
    departments: [],
    grntis: [],
    udks: [],
    okveds: [],
    lksetss: [],
    scienceBrunches: [],
    scienceDomainInterrests: [],
    pnrs: [],
    pnmitrs: [],
    pnis: [],
    ntrStrategies: [],
    technologyPlatforms: [],
    priorityTechnologies: [],
    knowledgeBranches: [],
    securityDocuments: [],
    participations: [],
    documents: [],
    type: null,
  };
}

export enum PaymentRequestType {
  ARTICLE = 'ARTICLE',
  MONOGRAPH = 'MONOGRAPH',
  SECURITY_DOCUMENT_ISSUE = 'SECURITY_DOCUMENT_ISSUE',
  SECURITY_DOCUMENT = 'SECURITY_DOCUMENT',
  EXHIBIT = 'EXHIBIT',
  CONFERENCE = 'CONFERENCE',
  MEDAL = 'MEDAL',
  TEXTBOOK = 'TEXTBOOK',
}

import React, { memo } from 'react';

import {
  FormComponent,
  SectionTitle,
  ReferenceItem,
  UploadFile,
  Select,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { Arm, Form as F, Table } from 'types/models';
import { Item } from 'types/models/common';
import { LibraryField } from 'features/Form/views';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { convertPublicationParticipations } from 'utils/Helpers/publication/convertPublicationParticipations';
import { getTitle } from 'features/Form/looks/publication/helpers';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  formFields: F.Fields;
  workMode: Table.WorkMode;
  isElectronic: boolean;
  electronicType: string;
  originalArticle: F.Original | null;
  translateArticles: F.Translation[];
  publicationInfo: F.Publication | null;
  isShowDetailedTitle: boolean;
  userDepartments: ReferenceItem[];
  publicationId?: string;
  publicationDepartment: ReferenceItem | null;
  setPublicationDepartment: React.Dispatch<ReferenceItem | null>;
  arm?: Arm;
};

const Bibliography = ({
  workMode,
  formFields,
  isElectronic,
  electronicType,
  originalArticle,
  translateArticles,
  publicationInfo,
  isShowDetailedTitle,
  userDepartments,
  publicationId,
  publicationDepartment,
  setPublicationDepartment,
  arm,
}: Props) => {
  const { userPermission } = useAppDataContext();

  return (
    <>
      <SectionTitle title={getTitle(publicationInfo, isShowDetailedTitle)} />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextArea
            settings={{ rows: 3, maxLength: 2049 }}
            value={formFields.name.value}
            onChange={formFields.name.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {originalArticle && (
        <FormComponent.Line>
          <FormComponent.Field label="Оригинальная статья">
            <FormComponent.Text>
              {getStatusRecordMessage(originalArticle.bibliographic, originalArticle.id, originalArticle.status)}
            </FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {!!translateArticles.length && (
        <FormComponent.Line>
          <FormComponent.Field label={translateArticles.length > 1 ? 'Переводные статьи' : 'Переводная статья'}>
            <FormComponent.Text>
              {translateArticles.map(t => getStatusRecordMessage(t.bibliographic, t.id, t.status)).join(';')}
            </FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {isElectronic && electronicType === ElectronicType.LOCAL.code && (
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.electronicMediaSource.title}
            tooltip={formFields.electronicMediaSource.tooltipText}
          >
            <TextInput
              value={formFields.electronicMediaSource.value}
              onChange={formFields.electronicMediaSource.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
          <FormComponent.Field
            label={formFields.electronicSystemRequirements.title}
            tooltip={formFields.electronicSystemRequirements.tooltipText}
          >
            <TextInput
              value={formFields.electronicSystemRequirements.value}
              onChange={formFields.electronicSystemRequirements.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label={formFields.annotation.title} tooltip={formFields.annotation.tooltipText} isRequired>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3 }}
            value={formFields.annotation.value}
            onChange={formFields.annotation.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.authorsKeyWords.title} tooltip={formFields.authorsKeyWords.tooltipText} isRequired>
          <TextInput
            value={formFields.authorsKeyWords.value}
            onChange={formFields.authorsKeyWords.onChange}
            isDisabled={workMode === 'viewMode'}
          />
          {Boolean(publicationInfo?.participations?.length) && (
            <FormComponent.Description mode="warning" classMixin="is-hint">
              <strong>Участия в конференции:</strong> {convertPublicationParticipations(publicationInfo!.participations)}
            </FormComponent.Description>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Скан-копия выходных данных и идентификаторы публикации" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText}>
          <TextInput
            mode={TextInputMode.DOI}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={workMode === 'viewMode' || !isHasPermission(userPermission, Permits.PUBLICATION_DOI_EDIT)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <LibraryField libraryUrl={publicationInfo?.status?.libraryUrl || ''} />
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Прикрепите файл"
          // eslint-disable-next-line max-len
          tooltip="Файл с выходными данными обязательно должен содержать титул, оборот титула, содержание и последний лист с информацией о типе издания, тираже, издательстве"
          isRequired
        >
          <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.publicationLink.title} isRequired={formFields.publicationLink.required}>
          <TextInput
            mode={TextInputMode.URL}
            value={formFields.publicationLink.value}
            onChange={formFields.publicationLink.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!publicationId && arm === 'pc' && (
        <>
          <SectionTitle title="Ответственное подразделение" />

          <FormComponent.Field
            label="Подразделение"
            tooltip="Публикация будет передана ответственному по подразделению для согласования и утверждения"
          >
            <Select
              value={{ value: publicationDepartment?.id || '', label: publicationDepartment?.label || '' }}
              options={userDepartments.map(x => ({ value: x.id, label: x.label }))}
              onChange={(option: Item) => setPublicationDepartment(userDepartments.find(x => x.id === option?.value) || null)}
            />
          </FormComponent.Field>
        </>
      )}
    </>
  );
};

export const BibliographyComponent = memo(Bibliography);

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'participationCategory',
        list: [
          {
            label: 'Мои участия',
            value: 'mine',
            // hint: 'Отображаются только те записи, в которых вы указаны участником выставки',
          },
          {
            label: 'Все участия',
            value: 'all',
            // hint: 'Список всех участий в выставках',
          },
        ],
        value: 'mine',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Год',
        checked: true,
      },
    ],
    'personal',
  );
});

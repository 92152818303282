import { useState, useCallback } from 'react';
import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { SuchLikePublication } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalStreams } from './hooks';
import { streams } from './streams';

type Props = {
  componentId: string;
  handleCloseOuterForm?: () => void;
};

export const useController = ({ handleCloseOuterForm, componentId }: Props) => {
  const localStreams = useLocalStreams(streams);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [suchLikePublicationList, setSuchLikePublicationList] = useState<SuchLikePublication.State['suchLikePublicationList']>(
    [],
  );
  const [isAttachPopupOpened, setIsAttachPopupOpened] = useState<boolean>(false);
  const { methods: getSuchlikePublicationList } = BackendAPI.useBackendAPI('GetSuchlikePublicationList');
  const { methods: addUserDepartmentToPublication } = BackendAPI.useBackendAPI('AddUserDepartmentToPublication');

  const EMPTY_WARNIG_MESSAGE = 'Значение, используемое для поиска, не должно быть пустым';
  const [searchValue, setSearchValue] = useState<SuchLikePublication.State['searchValue']>(''); // eslint-disable-line
  const [category, setCategory] = useState<SuchLikePublication.Category>('');
  const [successfullCallback, setSuccessfullCallback] = useState<SuchLikePublication.State['successfullCallback']>(() => {});

  const updateIsConfirmPopupOpen = useCallback((nextIsConfirmPopupOpen: boolean) => {
    setIsConfirmPopupOpen(nextIsConfirmPopupOpen);
  }, []);

  const resetPublicationMessage = useCallback(() => {
    setPublicationMessage('');
    setPublicationMessageType({ id: '', label: '' });
  }, []);

  const onMessagePopupClose = useCallback(() => {
    setPublicationMessage('');
    setIsMessagePopupOpen(false);
  }, []);

  const onMessagePopupOpen = useCallback(() => {
    setIsMessagePopupOpen(true);
  }, []);

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const resetState = useCallback(() => {
    resetPublicationMessage();
    setIsLoading(false);
    setIsMessagePopupOpen(false);
    setSuchLikePublicationList([]);
    setSearchValue('');
    setCategory('');
    setSuccessfullCallback(() => () => {});
    setIsOpen(false);
    setSelectedRowIndex(null);
  }, [resetPublicationMessage]);

  const onClose = useCallback(() => {
    resetState();
    setIsOpen(false);
  }, [resetState]);
  useStream(
    () => localStreams.openSuchLikePublication,
    data => {
      if (data.componentId !== componentId) {
        return;
      }
      setIsOpen(true);
    },
    [],
  );

  useStream(
    () => localStreams.getSuchLikePublications,
    async data => {
      if (data.componentId !== componentId) {
        return;
      }

      setIsLoading(true);
      setSearchValue(data.searchValue);
      setCategory(data.category);
      setSuccessfullCallback(() => data.successfullCallback);
      getSuchlikePublicationList.callAPI(
        {
          category: data.category,
          searchValue: data.searchValue,
          publicationType: data.publicationType,
        },
        {
          onSuccessfullCall: ({ data: resultData }) => {
            setIsLoading(false);
            if (resultData.success) {
              const actualDublicatesList = (resultData.list as SuchLikePublication.SuchLikePublicationItem[]).filter(
                item => item.id !== data.currentId,
              );
              setSuchLikePublicationList(actualDublicatesList);
              setIsLoading(false);
              if (!data.isSearching && !data.isDefaultSave) {
                if (actualDublicatesList.length) {
                  setIsOpen(true);
                } else {
                  onMessagePopupOpen();
                }
              } else {
                data.successfullCallback({
                  publicationMessage: '',
                  publicationMessageType: { id: '', label: '' },
                });
              }
            } else {
              showNotification({ theme: 'danger', message: resultData.message });
            }
          },
        },
      );
    },
    [],
  );

  const sendEmptyMessageWarning = () => {
    showNotification({ theme: 'danger', message: EMPTY_WARNIG_MESSAGE });
  };

  const submit = useCallback(() => {
    setIsOpen(false);
    setIsConfirmPopupOpen(false);
    setIsWarningPopupOpen(false);
    setIsMessagePopupOpen(false);
    successfullCallback({
      publicationMessage,
      publicationMessageType,
    });
  }, [successfullCallback, publicationMessage, publicationMessageType]);

  const onConfirmClick = useCallback(() => {
    submit();
  }, [submit]);

  const onConfirmWithoutMessageClick = useCallback(() => {
    resetPublicationMessage();
    submit();
  }, [submit, resetPublicationMessage]);

  useStream(
    () => localStreams.attachSuchLikePublication,
    data => {
      if (data.componentId !== componentId) {
        return;
      }
      setIsAttachPopupOpened(true);
    },
    [setIsAttachPopupOpened],
  );

  const handleCloseAttachPopup = useCallback(() => {
    setIsAttachPopupOpened(false);
  }, []);

  const handleAttachPublication = useCallback(() => {
    const id = selectedRowIndex !== null ? suchLikePublicationList[selectedRowIndex]?.id : '';
    setIsLoading(true);
    addUserDepartmentToPublication.callAPI(
      {
        id,
      },
      {
        onSuccessfullCall: result => {
          setIsLoading(false);
          if (result.data.success) {
            showNotification({ message: 'Публикация успешно привязана к подразделению', theme: 'success' });
            onClose();
            if (handleCloseOuterForm) {
              handleCloseOuterForm();
            }
            handleCloseAttachPopup();
          }
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, [
    selectedRowIndex,
    suchLikePublicationList,
    addUserDepartmentToPublication,
    onClose,
    handleCloseOuterForm,
    handleCloseAttachPopup,
  ]);

  return {
    isOpen,
    isLoading,
    isMessagePopupOpen,
    suchLikePublicationList,
    publicationMessage,
    isConfirmPopupOpen,
    selectedRowIndex,
    category,
    isWarningPopupOpen,
    publicationMessageType,
    isAttachPopupOpened,
    onClose,
    onMessagePopupClose,
    onMessagePopupOpen,
    successfullCallback,
    resetPublicationMessage,
    updatePublicationMessage,
    updateIsConfirmPopupOpen,
    setSelectedRowIndex,
    onConfirmClick,
    onConfirmWithoutMessageClick,
    setIsWarningPopupOpen,
    setPublicationMessageType,
    sendEmptyMessageWarning,
    handleAttachPublication,
    handleCloseAttachPopup,
  };
};

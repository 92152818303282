export function SalaryVacationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Раздел предназначен для мониторинга выполнения условий грантодателя по ограничениям на дни отпуска членов коллектива
        проектов (если таковые есть)
      </p>
      <p>Источник данных в таблице – документы для расчёта и начисления заработной платы из средств научных проектов</p>
      <p>Фильтр Год - зависит от сроков проектов</p>

      <table>
        <tbody>
          <tr>
            <td>Номер проекта</td>
            <td>Номер проекта</td>
          </tr>
          <tr>
            <td>
              <div>Дата начала проекта</div>
              <div>Дата окончания проекта</div>
            </td>
            <td>
              Влияет на фильтр Год для отображения данных в таблице. Если в фильтре указан год, то в таблице отображаются записи
              актуальных для года проектов
            </td>
          </tr>
          <tr>
            <td>Шифр</td>
            <td>
              Шифр проекта в системе расчёта и начисления заработной платы. Если отпускные оплачены за счёт двух ( и более)
              шифров, то в таблице отобразится 2 ( и более) записи. Количество дней отпуска – согласно приказу об отпуске
            </td>
          </tr>
          <tr>
            <td>Статус записи</td>
            <td>Статус «Утверждена» означает подтверждение физ.лица в составе коллектива проекта</td>
          </tr>
          <tr>
            <td>ФИО</td>
            <td>Фамилия имя отчество физ.лица</td>
          </tr>
          <tr>
            <td>Роль</td>
            <td>Роль в составе проекта. Если статус записи- «На согласование», роль отсутствует</td>
          </tr>
          <tr>
            <td>Назначение в проекте</td>
            <td>
              Название должности, указанная для члена коллектива в историческом срезе на период работы в проекте (см. вкладка
              Коллектив)
            </td>
          </tr>
          <tr>
            <td>Гражданство</td>
            <td>Название страны; данные указанные в АИС для расчёта и начисления заработной платы</td>
          </tr>
          <tr>
            <td>Характер работы</td>
            <td>Атрибут для работающих дистанционно. Пустое значение обозначает работу в обычном режиме</td>
          </tr>
          <tr>
            <td>Ставка</td>
            <td>Доля ставки</td>
          </tr>
          <tr>
            <td>
              <div>Отпуск дн. в год</div>
              <div>Отпуск не опл. дн. в год</div>
            </td>
            <td>
              Значение в таблице зависит от фильтра Год: если фильтр включен - то данные указанного в фильтре года, если нет — то
              общее количество дней за весь период
            </td>
          </tr>
          <tr>
            <td>Назначение для оплаты</td>
            <td>Название должности, по которой произведена оплата отпускных</td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

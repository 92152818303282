import React from 'react';
import block from 'bem-cn';
import * as helpers from './helpers/helpers';

import { Button, FormComponent, Radio, SectionTitle } from 'components';

import { FieldType } from 'types/models/Merge';
import useController from './controller';
import { Field } from './Field';
import { MergeFieldRadioValue, ColumnType } from './types';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { PortfolioCard } from 'features/Form/looks/person';

import './style.scss';

export type Props = {
  personIds: string[];
  onClose: () => void;
};

const mt = block('merge-table');
const mtw = block('merge-table-wrapper');

export const Component = ({ personIds, onClose }: Props) => {
  const {
    merge,
    personId,
    isPersonViewFormOpen,
    setIsPersonViewFormOpen,
    mergeFieldRadios,
    extendedCommonColumn,
    isLoading,
    setIsCollectionConflicResolved,
    onCommonRadioClick,
    getIsConflicted,
    updateEntityMergeFieldRadio,
    onPersonClick,
    setValue,
    saveMerge,
  } = useController({
    personIds,
    onClose,
  });

  return (
    <>
      <FormComponent.Template>
        <ControlPanel handleSave={saveMerge} isDisabled={isLoading} />

        <FormComponent.Wrapper>
          <div className={mtw()}>
            <table className={mt()}>
              <thead>
                <tr>
                  <th style={{ width: `${100 / (merge.entities.length + 1)}%` }}>
                    <SectionTitle>
                      <span>Результат объединения</span>
                    </SectionTitle>
                  </th>
                  {merge.entities.map((entity, index) => (
                    <th key={`column-${index}`} style={{ width: `${100 / (merge.entities.length + 1)}%` }}>
                      <SectionTitle>
                        <Radio
                          value={merge.commonId ?? undefined}
                          list={[{ label: `Элемент c ID: ${entity.id}`, value: entity.id }]}
                          onChange={onCommonRadioClick}
                        />
                        <Button icon={{ type: 'view' }} onClick={() => onPersonClick(entity.id)} />
                      </SectionTitle>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {extendedCommonColumn.map((fieldFirst, rowIndex) => (
                  <tr key={`row-${rowIndex}`}>
                    <td key={`row-${rowIndex}_column-0`}>
                      <Field
                        isCommon
                        isLabelShows
                        key={fieldFirst.name}
                        isConflictedMarkerShows
                        isConflicted={getIsConflicted(fieldFirst)}
                        field={fieldFirst}
                        setValue={setValue({ type: ColumnType.COMMON, fieldName: fieldFirst.name })}
                        isDisabled={!helpers.getIsCommonFieldEditable(fieldFirst) || isLoading}
                      />
                    </td>
                    <>
                      {merge.entities.map((entity, colIndex) => {
                        const field = entity.fields[rowIndex];

                        return (
                          <td key={`row-${rowIndex}_column-${colIndex + 1}`}>
                            {field.type !== FieldType.COLLECTION && (
                              <Radio
                                isDisabled={!helpers.getIsEntityFieldCanSelected(field) || isLoading}
                                value={mergeFieldRadios?.[entity.id][field.name]}
                                list={[{ label: '', value: MergeFieldRadioValue.SELECTED }]}
                                onChange={updateEntityMergeFieldRadio({
                                  fieldName: field.name,
                                  entityId: entity.id,
                                  value: field.value,
                                })}
                              />
                            )}
                            <Field
                              field={field}
                              setValue={setValue({ type: ColumnType.ENTITY, fieldName: field.name, entityId: entity.id })}
                              setIsCollectionConflictResolved={setIsCollectionConflicResolved({
                                fieldName: field.name,
                                entityId: entity.id,
                              })}
                              isDisabled
                            />
                          </td>
                        );
                      })}
                    </>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </FormComponent.Wrapper>
      </FormComponent.Template>

      {isPersonViewFormOpen && (
        <PortfolioCard
          isOpen={isPersonViewFormOpen}
          id={personId}
          onClose={() => setIsPersonViewFormOpen(false)}
          shortMode={true}
        />
      )}
    </>
  );
};

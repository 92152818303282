import { useLayoutEffect, useMemo, useState, useCallback, useEffect } from 'react';
import ReactDOM from 'react-dom';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Permits } from 'utils/Permissions';
import { Notice } from 'types/models/Notice';
import { formatStr } from 'utils/Constants';
import { showNotification } from 'features/Notifications';

export const NOTICE_PAGE_SIZE = 10;

export function useController() {
  const { isProfile } = usePrivatePageContext();

  const [list, setList] = useState<Notice[]>([]);
  const [isAddModalOpen, setIsAddModalOpen] = useState<boolean>(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [addModalCaption, setAddModalCaption] = useState<string>('');
  const [addModalData, setAddModalData] = useState<string>('');
  const [editModalCaption, setEditModalCaption] = useState<string>('');
  const [editModalData, setEditModalData] = useState<string>('');
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);

  /* Notices */
  const { methods: getNotices } = BackendAPI.useBackendAPI('GetNotices', {
    onSuccessfullCall: ({ data }) => {
      data.sort((x1, x2) => {
        const datesDiff: number =
          parse(x2.createdDate || '', formatStr, new Date()).getTime() -
          parse(x1.createdDate || '', formatStr, new Date()).getTime();
        if (datesDiff === 0) {
          return parseInt(x2.id, 10) - parseInt(x1.id, 10);
        } else {
          return datesDiff;
        }
      });
      ReactDOM.unstable_batchedUpdates(() => {
        setList(data);
        setIsAddModalOpen(false);
      });
    },
  });

  const { methods: saveNotice } = BackendAPI.useBackendAPI('SaveNotice', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Объявление сохранено', theme: 'success' });
      getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    },
  });

  const { methods: deleteModel } = BackendAPI.useBackendAPI('DeleteModel', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Объявление удалено', theme: 'success' });
      getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    },
  });

  useEffect(() => {
    if (selectedRowIndex || selectedRowIndex === 0) {
      setEditModalCaption(list[selectedRowIndex].caption);
      setEditModalData(list[selectedRowIndex].data);
    } else {
      setEditModalCaption('');
      setEditModalData('');
    }
  }, [list, selectedRowIndex]);

  useLayoutEffect(() => {
    getNotices.callAPI({ startRecord: 0, lastRecord: NOTICE_PAGE_SIZE });
    // eslint-disable-next-line
  }, []);

  function getIndex(recordId: string) {
    const index = list.map((e: any) => e.id).indexOf(recordId);
    return index;
  }

  const handleAddModalCaptionChange = useCallback((value: string) => {
    setAddModalCaption(value);
  }, []);

  const handleAddModalDataChange = useCallback((value: string) => {
    setAddModalData(value);
  }, []);

  const handleEditModalCaptionChange = useCallback((value: string) => {
    setEditModalCaption(value);
  }, []);

  const handleEditModalDataChange = useCallback((value: string) => {
    setEditModalData(value);
  }, []);

  const handleAddModalSaveButtonClick = useCallback(() => {
    if (addModalData && addModalCaption) {
      const editingRecord = {
        id: null,
        data: addModalData,
        caption: addModalCaption,
      };
      saveNotice.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [addModalCaption, addModalData, saveNotice]);

  const handleEditModalSaveButtonClick = useCallback(() => {
    if (editModalData && editModalCaption) {
      const editingRecord = {
        id: selectedRowIndex || selectedRowIndex === 0 ? list[selectedRowIndex].id : null,
        data: editModalData,
        caption: editModalCaption,
      };
      saveNotice.callAPI(editingRecord);
    } else {
      showNotification({ theme: 'danger', message: 'Заполните все поля' });
    }
  }, [editModalCaption, editModalData, list, saveNotice, selectedRowIndex]);

  const handleDeleteButtonClick = useCallback(() => {
    if (selectedRowIndex || selectedRowIndex === 0)
      deleteModel.callAPI({ rowId: list[selectedRowIndex].id, commandName: 'DeleteEntity', entityName: 'Notice' });
    setIsConfirmPopupOpen(false);
  }, [deleteModel, list, selectedRowIndex]);

  const handleLoadButtonClick = useCallback(() => {
    getNotices.callAPI({ startRecord: 0, lastRecord: list.length + NOTICE_PAGE_SIZE });
  }, [getNotices, list.length]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setIsAddModalOpen(true);
          setAddModalCaption('');
          setAddModalData('');
        },
        permission: { name: Permits.NOTICE_SYSTEM_ADD },
        isHidden: isProfile,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => {
          setIsEditModalOpen(true);
        },
        permission: { name: Permits.NOTICE_SYSTEM_EDIT },
        isHidden: isProfile,
      },
      {
        icon: { type: 'arrowDown' },
        title: `Подгрузить предыдущие ${NOTICE_PAGE_SIZE} записей`,
        onClick: () => {
          handleLoadButtonClick();
        },
      },
    ],
    [handleLoadButtonClick, isProfile],
  );

  const addModalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Cохранить',
        onClick: () => {
          handleAddModalSaveButtonClick();
        },
      },
    ],
    [handleAddModalSaveButtonClick],
  );

  const editModalButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Cохранить',
        onClick: () => {
          handleEditModalSaveButtonClick();
        },
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => {
          setIsConfirmPopupOpen(true);
        },
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
      },
    ],
    [handleEditModalSaveButtonClick, selectedRowIndex],
  );

  return {
    buttons,
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalData,
    addModalButtons,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalData,
    handleAddModalDataChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalData,
    handleEditModalDataChange,
    list,
    getIndex,
    setSelectedRowIndex,
  };
}

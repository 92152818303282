import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { Table as T } from 'types/models';
import useController from './controller';
import { DataGrid } from 'features/Table/container';
import { GetEstimateOverheadHistoryList } from 'features/Table/specifications/GetEstimateOverheadHistoryList';
import { GetFundCardPositionReceivingList } from 'features/Table/specifications/GetFundCardPositionReceivingList';
import { FundCardPositionGridHelp } from './help';

type Props = {
  tableState: T.State;
};
function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isDocumentListModalOpen,
    setIsDocumentListModalOpen,
    isHistoryModalOpen,
    setIsHistoryModalOpen,
    documentsModalTitle,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({
    tableState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        isOpen={isDocumentListModalOpen}
        onClose={() => setIsDocumentListModalOpen(false)}
        title={documentsModalTitle}
        size="full"
      >
        {tableState.selectedRows[0] && (
          <DataGrid specification={GetFundCardPositionReceivingList({ estimatePositionId: tableState.selectedRows[0]?.id })} />
        )}
      </Modal>

      <Modal
        isOpen={isHistoryModalOpen}
        onClose={() => setIsHistoryModalOpen(false)}
        title="История изменения накладных"
        size="full"
      >
        {tableState.selectedRows[0] && (
          <DataGrid specification={GetEstimateOverheadHistoryList({ estimatePositionId: tableState.selectedRows[0]?.id })} />
        )}
      </Modal>

      <Modal
        mode="help"
        title="Источники средств  для шифра — статья затрат «Накладные расходы» в сметах шифров проектов"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        size="medium"
      >
        {FundCardPositionGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

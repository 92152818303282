import { ProjectPerformerOrder } from 'types/models/Project';
import { Performer } from 'types/models/Project';

export function getMockPerformerOrder(): ProjectPerformerOrder {
  return {
    id: null,
    project: null,
    type: null,
    number: null,
    date: null,
    order: null,
  };
}
export function formatOrderPerformer(performer: Performer, performerOrder: ProjectPerformerOrder, isStartOrder: boolean) {
  const actualJobPeriod = isStartOrder
    ? performer.jobPeriods.find(p => p.startDateOrder?.id === performerOrder.id)
    : performer.jobPeriods.find(p => p.endDateOrder?.id === performerOrder.id);

  const tokens = [
    isStartOrder ? actualJobPeriod?.startDate : actualJobPeriod?.endDate,
    performer.fio || performer.person?.fullName || '',
    actualJobPeriod?.degree?.refDegree?.label || '',
    actualJobPeriod?.rank?.refRank?.label || '',
    actualJobPeriod?.job?.appointment || actualJobPeriod?.job?.refAppointment?.label || '',
  ].filter(Boolean);

  return tokens.join(', ');
}

import { Form } from 'types/models';
import { Tender, Lot } from 'types/models/Tender';
import { TenderRequestType } from 'utils/Enums';

export function validateContractAmounts(lots: Lot[]) {
  let isLimitAmountMatches = Boolean(
    lots.filter(
      lot =>
        parseFloat(String(lot.limitAmount)) !== parseFloat(String(lot.contractAmount)) * parseFloat(String(lot.contractCount)),
    ).length,
  );

  return [
    {
      isValid: !isLimitAmountMatches,
      invalidMessage: 'Итоговая Сумма всех контрактов не совпадает с Предельной ценой лота!',
    },
  ];
}
export function validateTender(tender: Tender, formFields: Form.Fields, requestType: TenderRequestType) {
  const errorMessages: string[] = [
    requestType === TenderRequestType.NIR_REQUEST &&
      !(formFields.program.value?.id && formFields.programEvent.value?.id) &&
      'Для внутреннего конкурса должны быть указаны программа и мероприятие программы',
    !!formFields.isMarketplace.value && !formFields.type.value?.value && 'Выберите вид тендера',
    !formFields.name.value && 'Укажите название конкурса',
    !!formFields.isMarketplace.value && !formFields.kindTrade.value?.id && 'Выберите форму торгов',
    !!formFields.dateUniversity.required &&
      !formFields.dateUniversity.value &&
      'Укажите дату в поле "Подать заявку на согласование консультанту до"',
    // !formFields.dateExamenation.value && 'Укажите дату рассмотрения заявок',
    !formFields.dateSummingUp.value && 'Укажите дату подведения итогов',
    (requestType === TenderRequestType.MOBILE_REQUEST || requestType === TenderRequestType.PROGRAM_REQUEST) &&
      !tender.isSpecial &&
      !tender.lots.length &&
      'Не добавлен лот конкурса',
    requestType === TenderRequestType.NIR_REQUEST &&
      !tender.lots.length &&
      'Для внутреннего конкурса должен быть указан лот конкурса. Добавьте лот в Список лотов конкурса',
  ].filter(Boolean) as string[];

  return [
    {
      isValid: !errorMessages.length,
      invalidMessage: errorMessages.join('. '),
    },
  ];
}

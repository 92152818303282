import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { Item as ItemOption } from 'types/models/common';

type Item = {
  name: string;
  data: number[];
};

export function useController() {
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>('SJR');
  const [actualYear, setActualYear] = useState(new Date().getFullYear());
  const [actualQuartile, setActualQuartile] = useState('');
  const { userSystemDepartment } = useAppDataContext();
  const [actualDepartment, setActualDepartment] = useState<ReferenceItem | null>(userSystemDepartment);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);

  const qs = ['Q1', 'Q2', 'Q3', 'Q4', 'Q0'];

  const [rusAndOtherByPeriod, setRusAndOtherByPeriod] = useState<Item[]>(qs.map(x => ({ name: x, data: [0, 0, 0, 0, 0, 0] })));

  const periodOpts = useRef([
    {
      value: 'SJR',
      label: 'Q(SJR Scopus)',
    },
    {
      value: 'IF',
      label: 'Q(IF Web of Science)',
    },
    {
      value: 'SNIP',
      label: 'Q(SNIP Scopus)',
    },
  ]);

  const categories = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const { methods: GetDashboardChartScopusWosArticleListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartScopusWosArticleListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        setRusAndOtherByPeriod(
          qs.map(x => ({
            name: x,
            data: categories.map(i => Number(chartAttrRes[`Year${i}${x}`]) || 0),
          })),
        );
      },
    },
  );

  useEffect(() => {
    setIsLoading(true);
    GetDashboardChartScopusWosArticleListStatic.callAPI(
      {
        parameters: [
          {
            name: 'scientificIndexCode',
            value: activePeriodFilter,
          },
          {
            name: 'department',
            value: actualDepartment ? actualDepartment.id : '-1',
          },
        ],
        rowQuantity: 1,
        offset: 0,
      },
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activePeriodFilter, actualDepartment]);

  const handleChangeActiveFilter = useCallback(
    (option: ItemOption) => {
      setActivePeriodFilter(option.value || '');
    },
    [setActivePeriodFilter],
  );

  const activeFilter = useMemo(() => periodOpts.current.find(opt => opt.value === activePeriodFilter), [
    periodOpts,
    activePeriodFilter,
  ]);

  const getModalTitle = useMemo(
    () =>
      `Количество публикаций в научных журналах Web of Science и Scopus метрики ${activeFilter}${
        actualQuartile ? ` квартиль ${actualQuartile}` : ''
      }${actualDepartment ? ` подразделения ${actualDepartment.label}${actualYear ? ` за ${actualYear}г.` : ''}` : ''}`,
    [activeFilter, actualDepartment, actualYear, actualQuartile],
  );

  const seriesClickHandler = useCallback(
    (item: Item, year: number) => {
      setActualYear(year);
      setActualQuartile(item.name);
      setIsModalOpen(true);
    },
    [setActualYear, setIsModalOpen, setActualQuartile],
  );

  return {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    setActualDepartment,
    actualDepartment,
    isLoading,
    getModalTitle,
    isModalOpen,
    setIsModalOpen,
    categories,
    rusAndOtherByPeriod,
    actualYear,
    actualQuartile,
    seriesClickHandler,
    activePeriodFilter,
  };
}

import React from 'react';

import { SectionTitle } from 'components';

import { ProjectCode } from 'types/models/Project';
import { DataGrid } from 'features/Table';
import { GetEstimateList } from 'features/Table/specifications/GetEstimateList';
import { IdItem } from 'types/models/common';

type Props = {
  projectCode: ProjectCode | null;
  isDisabled?: boolean;
  fundCard?: IdItem;
};

export function Estimates({ projectCode, isDisabled, fundCard }: Props) {
  return (
    <>
      <SectionTitle title="Затраты шифра проекта" />

      <DataGrid specification={GetEstimateList({ projectCode, projectCodeId: '-1', hideTitle: true, isDisabled, fundCard })} />
    </>
  );
}

import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { Participation } from 'types/models';

export type State = {
  openAwards(): void;
  closeAwards(): void;
  loadParticipation(participationId: string): void;
  isShowAwards: boolean;
  participation: Participation | null;
  isViewMode: boolean;
};

export function makeUseCustomController({ isViewMode = false }) {
  return function useCustomController(): State {
    const [isShowAwards, setIsShowAwards] = useState(false);
    const [participation, setParticipation] = useState<Participation | null>(null);

    const closeAwards = useCallback(() => {
      setIsShowAwards(false);
      setParticipation(null);
    }, []);

    const openAwards = useCallback(() => {
      setIsShowAwards(true);
    }, []);
    const { methods: loadParticipationAPI } = BackendAPI.useBackendAPI('GetParticipation');

    const loadParticipation = useCallback(
      (participationId: string) => {
        loadParticipationAPI.callAPI(
          {
            participationId,
            modules: ['AWARDS'],
            eventModules: ['MAIN'],
          },
          {
            onSuccessfullCall: ({ data }) => {
              setParticipation(data);
              openAwards();
            },
          },
        );
      },
      [loadParticipationAPI, openAwards],
    );

    return {
      openAwards,
      closeAwards,
      loadParticipation,
      isShowAwards,
      participation,
      isViewMode,
    };
  };
}

import { Specification } from 'types/models/Table';

type Props = {
  department: string;
  year: number;
};
export function GetDashboardChartTotalPublicationList({ department, year }: Props): Specification {
  return {
    apiID: 'GetDashboardChartTotalPublicationList',
    workMode: 'viewMode',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: {
      department,
      year: String(year),
    },
  };
}

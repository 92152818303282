import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { NirRequestStatus } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { Color } from 'constants/colors';

type StatusArgs = {
  rows: { id: string; status: NirRequestStatus | null }[];
  callback?(): void;
};

export function getNirRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<NirRequestStatus>(NirRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeNirRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddNirRequestApprovement');

  const handleButtonClick = useCallback((newStatus: NirRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push({});
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, nirRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [addApprovementAPI, rows, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'new' },
        title: 'В черновик',
        onClick: () => handleButtonClick(NirRequestStatus.DRAFT, 'В черновик'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_DRAFT },
        isDisabled: !rows.length || rows.some(x => !!x.status && [NirRequestStatus.DRAFT].includes(x.status)),
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'На согласование',
        onClick: () => handleButtonClick(NirRequestStatus.REQUEST, 'На согласование'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REQUEST },
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [NirRequestStatus.DRAFT, NirRequestStatus.REVISION].includes(x.status)),
      },

      {
        icon: { type: 'like' },
        title: 'Присвоить заявке статус "Согласована"',
        onClick: () => handleButtonClick(NirRequestStatus.AGREED, 'Присвоить заявке статус "Согласована"'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED },
        isDisabled: !rows.length || !rows.every(x => !!x.status && [NirRequestStatus.REQUEST].includes(x.status)),
      },
      {
        icon: { type: 'like', color: Color.warning },
        title: 'Присвоить заявке статус "Согласована с условием"',
        onClick: () =>
          handleButtonClick(NirRequestStatus.AGREED_WITH_CONDITION, 'Присвоить заявке статус "Согласована с условием"'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION },
        isDisabled:
          !rows.length ||
          !rows.every(
            x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.AGREED, NirRequestStatus.SUPPORTED].includes(x.status),
          ),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать',
        onClick: () => handleButtonClick(NirRequestStatus.SUPPORTED, 'Поддержать'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_SUPPORTED },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.AGREED, NirRequestStatus.AGREED_WITH_CONDITION].includes(x.status)),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => handleButtonClick(NirRequestStatus.REVISION, 'Отправить на доработку'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REVISION },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.REJECTED].includes(x.status)),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => handleButtonClick(NirRequestStatus.REJECTED, 'Отклонить'),
        permission: { name: Permits.NIR_REQUEST_CHANGE_STATUS_REJECTED },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [NirRequestStatus.REQUEST, NirRequestStatus.AGREED_WITH_CONDITION].includes(x.status)),
      },
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsApprovementModalOpen(true),
        permission: { name: Permits.NIR_REQUEST_AGREE },
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === NirRequestStatus.DRAFT),
      },
    ],
    [rows, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}

import { JointEvent, Event, Person, PersonHistory } from 'types/models';
import { EventFormat } from 'utils/Enums';

export type FlatParticipantAward = {
  id: string | null;
  person: Person.ScientistPerson | null;
  award: JointEvent.Award;
} & PersonHistory;

export function getMockJointEvent(): JointEvent.JointEvent {
  return {
    id: null,
    type: null,
    level: null,
    name: '',
    startDate: '',
    endDate: '',
    isStudent: false,
    orderNumber: '',
    orderDate: '',
    description: '',
    site: '',
    contact: '',
    memberCountPlan: '',
    memberCountFact: '',
    keyWords: '',
    format: EventFormat.OFFLINE,
    departments: [],
    enterprises: [],
    committeMembers: [],
    memberCounts: [],
    projects: [],
    participants: [],
    grntis: [],
    ministryEducations: [],
    criticalTechnologies: [],
    pnrs: [],
    pnmitrs: [],
    pnis: [],
    documents: [],
  };
}

export function getHistoryInfo(committeMember: JointEvent.CommitteMember): string {
  const { historyLabel } = committeMember;

  const jobInfo = [
    committeMember.job?.status?.label,
    committeMember.job?.refAppointment?.label || committeMember.job?.appointment,
    committeMember.job?.refDepartment?.label || committeMember.job?.department,
  ]
    .filter(Boolean)
    .join(', ');

  const educationInfo = [
    committeMember.education?.status.label,
    committeMember.education?.refSpeciality?.label || committeMember.education?.speciality,
    committeMember.education?.refDepartment?.label || committeMember.education?.department,
  ]
    .filter(Boolean)
    .join(', ');

  const result = [
    historyLabel,
    jobInfo,
    educationInfo,
    !jobInfo && !educationInfo ? 'Не указаны сведения о работе или обучении' : '',
  ]
    .filter(Boolean)
    .join('; ');

  return result;
}

export function convertToEventMemberCountType(memberCount: JointEvent.MemberCount): Event.MemberCount {
  return {
    ...memberCount,
    id: memberCount.id || undefined,
    label: memberCount.type?.label || '',
    type: memberCount.type?.value || '',
    amounts: memberCount.amounts.map(x => convertToEventAmountType(x)),
  };
}

export function convertToEventAmountType(amount: JointEvent.Amount): Event.MemberCountAnother {
  return {
    ...amount,
    students: '',
    professors: '',
    id: amount.id || '',
  };
}

export function convertToJointEventMemberCountType(memberCount: Event.MemberCount) {
  return {
    ...memberCount,
    id: memberCount.id || null,
    type: {
      value: memberCount.type,
      label: memberCount.label,
    },
  };
}

export function getAwardList(participants: JointEvent.Participant[]) {
  const result: FlatParticipantAward[] = [];
  participants.forEach(x => x.awards.forEach(y => result.push({ ...x, award: y })));
  return result;
}

export function convertAwardListToParticipants(
  participants: JointEvent.Participant[],
  awards: FlatParticipantAward[],
): JointEvent.Participant[] {
  const result: JointEvent.Participant[] = participants.map(x => ({ ...x, awards: [] }));

  awards.forEach(x => {
    const participantIndex = result.findIndex(y => y.person?.id === x.person?.id);
    if (participantIndex === -1) {
      result.push({ ...x, awards: [x.award] });
    } else {
      result[participantIndex].awards.push(x.award);
    }
  });

  return result;
}

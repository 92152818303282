import React, { memo, useCallback, useMemo, useState } from 'react';

import { ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';
import { PersonalSecurityDocumentGridHelp } from './help';

type Props = {
  isRequestMode?: boolean;
};

export const Toolbar = ({ isRequestMode }: Props) =>
  memo(() => {
    const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

    const handleHelpButtonClick = useCallback(() => {
      setIsHelpFormOpen(true);
    }, []);

    const handleTemplateCloseHelpForm = useCallback(() => {
      setIsHelpFormOpen(false);
    }, []);

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'question' },
          title: 'Помощь',
          onClick: handleHelpButtonClick,
        },
      ],
      [handleHelpButtonClick],
    );

    return (
      <>
        <SharedToolbar buttons={buttons} />

        <Modal
          mode="help"
          title={
            isRequestMode
              ? 'Мои заявки на регистрацию объектов интеллектуальной  собственности'
              : 'Мои ОИС (объекты интеллектуальной  собственности)'
          }
          isOpen={isHelpFormOpen}
          onClose={handleTemplateCloseHelpForm}
          size="medium"
        >
          {PersonalSecurityDocumentGridHelp(isRequestMode)}
        </Modal>
      </>
    );
  });

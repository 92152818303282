import React, { useCallback, useMemo, useState } from 'react';

import { FormComponent, ListEdit } from 'components';

import { Person, UserPermission } from 'types/models';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { ExtraToolbarButton } from 'components/ListEdit/model';
import { EditableHonor, Honor } from 'features/PersonCard/model';
import { formatName } from './helpers';
import { Color } from 'constants/colors';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  onChangeHonors(honors: Honor[]): void;
};

export function ScientistHonors(props: Props) {
  const { person, userPermission, onChangeHonors } = props;

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.BaseHonor>[]>(
    () => [
      {
        icon: { type: 'admin' },
        title: 'Задать вопрос',
        onClick: handleQuestionButtonClick,
      },
    ],
    [handleQuestionButtonClick],
  );

  const items: EditableHonor[] = [...(person?.degrees ?? []), ...(person?.academicRanks ?? []), ...(person?.ranks ?? [])].map(
    i => ({
      type: (() => {
        if (Person.isScientistDegree(i)) {
          return 'degree';
        }
        if (Person.isScientistAcademicRank(i)) {
          return 'academicRank';
        }
        if (Person.isScientistRank(i)) {
          return 'rank';
        }
      })(),
      ...i,
    }),
  );

  return (
    <>
      <FormComponent.Description mode="warning">
        Источником информации является информационная система Управления кадров университета
      </FormComponent.Description>

      <ListEdit
        rows={items}
        onChange={onChangeHonors}
        toolbar={extraToolbarButtons}
        columns={[
          { label: 'Наименование', formatValue: formatName },
          { label: 'Дата получения', formatValue: row => row.dateAssign },
          { label: 'Актуально', formatValue: row => row.isActive, dataKind: 'boolean' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, creationSource }) =>
              creationDate ? `${creationSource ?? userPermission?.userName ?? ''} (${creationDate})` : '',
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modificationSource }) =>
              modificationDate ? `${modificationSource ?? userPermission?.userName ?? ''} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletedBy && deletionDate ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        maxHeight="none"
        withMessages
        isDeleteConfirmEnabled
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
        }}
      />

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
}

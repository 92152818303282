import { makeStream } from 'StreamRx';

import { LoaderStreamData } from './types';

export const streams = {
  updateIsLoading: makeStream<UpdateIsLoading>(),
};

export type UpdateIsLoading = LoaderStreamData<{
  isLoading?: boolean;
  isUploading?: boolean;
}>;

import React from 'react';

import { FormComponent, Select, SelectMode, TextInput, TextInputMode } from 'components';

import { Item } from 'types/models/common';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';
import { CommissionMember } from 'types/models/ProtocolCommission';

type Props = {
  entry: CommissionMember | null;
  onFieldChange: React.Dispatch<React.SetStateAction<CommissionMember | null>>;
  disabled?: boolean;
};

function CommissionMemberFields({ disabled, entry, onFieldChange }: Props) {
  const {
    handlePersonChange,
    handleRoleChange,
    nameOptions,
    convertCommissionMemberToMember,
    makeChangeHandler,
    priorityTooltipTxt,
  } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Персона" isRequired>
          <SelectPerson
            member={convertCommissionMemberToMember(entry)}
            onUpdateMember={handlePersonChange}
            hiddenButtons={['delete']}
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО для протокола" isRequired>
          <Select
            value={nameOptions.find(x => x.label === entry?.protocolName)}
            options={nameOptions}
            onChange={(option: Item) => makeChangeHandler('protocolName')(option.label)}
            isDisabled={!!disabled || !entry?.person}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={entry?.role}
            settings={{ name: 'ProtocolCommissionMemberRole' }}
            onChange={handleRoleChange}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Категория роли" tooltip={priorityTooltipTxt} isRequired>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ max: 9, decimal: false }}
            value={entry?.priority?.toString()}
            onChange={makeChangeHandler('priority')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { CommissionMemberFields };

import React from 'react';

import { Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';

type Props = {
  saveForm?(): void;
  closeForm?(): void;
  personId?: string | null;
};

function Toolbar(props: Props) {
  const { saveForm, closeForm, personId } = props;

  const {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    currentPerson,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
  } = useController({
    saveForm,
    closeForm,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          scientist: currentPerson?.scientist?.id || personId || '',
          scientistId: currentPerson?.scientist?.id || personId || '',
        }}
      />
    </>
  );
}

export { Toolbar };

import { Table as T } from 'types/models';

type Arguments = {
  id: string;
};

type RequestData = {
  id: string;
};

export function GetPublicationUsagesList({ id }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetPublicationUsagesList',
    requestData: { id },
    header: {
      firstLevel: {
        title: 'Связи публикации с другими объектами',
        Panel: undefined,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: undefined,
        hasSelectButton: true,
      },
    },
  };
}

import React, { useEffect } from 'react';

import { FormComponent, Tab, Tabs, Toolbar } from 'components';

import { isHasPermission } from 'features/AppData';
import { Documents } from 'features/Documents';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { AboutProtocol } from 'features/Form/looks/tender/TenderProtocolForm/Tabs/AboutProtocol';
import { DataGrid } from 'features/Table';
import { GetTenderProtocolApproverRequestList, GetTenderProtocolRequestList } from 'features/Table/specifications';
import { AboutTender } from 'features/Form/looks/programRequest/ProgramRequestForm/Tabs/AboutTender';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function TenderProtocolForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleDocumentsFieldChange,
    workMode,
    tenderProtocol,
    makeChangeHandler,
    updateRequests,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();

  useEffect(() => {
    setTitle?.(
      getStatusMessage(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            return `${workMode === 'viewMode' ? 'Просмотр' : 'Редактирование'}`;
          })(),
          'Протокола заседания конкурсной комиссии',
        ]
          .filter(Boolean)
          .join(' '),
        tenderProtocol?.id || '',
      ),
    );
  }, [tenderProtocol, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          mobileProtocolId: tenderProtocol.id || undefined,
          tenderProtocolId: tenderProtocol.id || undefined,
        }}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Протокол" isVisible={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_VIEW_PROTOCOL)}>
            <AboutProtocol
              tenderProtocol={tenderProtocol}
              workMode={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_EDIT_PROTOCOL) ? workMode : 'viewMode'}
              makeChangeHandler={makeChangeHandler}
            />
          </Tab>
          <Tab title="Список заявок" isVisible={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_VIEW_PROTOCOL_REQUESTS)}>
            <DataGrid
              specification={GetTenderProtocolRequestList({
                protocolId: !tenderProtocol.tender ? '-1' : tenderProtocol.id || '-1',
                type: tenderProtocol.tender?.requestType?.value || null,
                statusChangeCallback: updateRequests,
                requestType: tenderProtocol.tender?.requestType?.value,
              })}
            />
          </Tab>
          <Tab
            title="Список заявок для эксперта"
            isVisible={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_VIEW_APPROVER_REQUESTS)}
          >
            <DataGrid
              specification={GetTenderProtocolApproverRequestList({
                protocolId: !tenderProtocol.tender ? '-1' : tenderProtocol.id || '-1',
                type: tenderProtocol.tender?.requestType?.value || null,
                statusChangeCallback: updateRequests,
                requestType: tenderProtocol.tender?.requestType?.value,
              })}
            />
          </Tab>
          <Tab title="О конкурсе" isVisible={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_VIEW_TENDER)}>
            <AboutTender tender={tenderProtocol.tender} lot={null} />
          </Tab>
          <Tab title="Документы" isVisible={isHasPermission(userPermission, Permits.TENDER_PROTOCOL_VIEW_DOCUMENTS)}>
            <Documents
              documents={tenderProtocol.documents ?? []}
              setDocuments={handleDocumentsFieldChange}
              isShowSource
              sourceLabelForLocalDocs="Текущий протокол"
              disabled={workMode === 'viewMode' || !isHasPermission(userPermission, Permits.TENDER_PROTOCOL_EDIT_DOCUMENTS)}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(TenderProtocolForm);

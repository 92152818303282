import React, { useCallback } from 'react';

import { FormComponent, TextInput } from 'components';

import { Project } from 'types/models';

type Props = {
  contest: Project.ContestRequest;
  setContest(contest: Project.ContestRequest): void;
};

export function Fields(props: Props) {
  const { contest, setContest } = props;

  const handleChangeNote = useCallback(
    (val: string) => {
      setContest({ ...contest, projectNote: val });
    },
    [contest, setContest],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextInput value={contest.projectNote} onChange={handleChangeNote} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';
import { getParticipationPermitList, isParticipationPermitted } from 'utils/Helpers/participation';
import { isHasPermission } from 'features/AppData';

type Props = {
  tableState: Table.State;
  loadParticipation: State['loadParticipation'];
};

export const useController = ({ tableState, loadParticipation }: Props) => {
  const tableStreams = useLocalTableStreams();
  const { userPermission, userSystemDepartment } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);
  const [isPublicationModalOpen, setIsPublicationModalOpen] = useState<boolean>(false);
  const [isFinancicalSupportPopupOpen, setIsFinancicalSupportPopupOpen] = useState<boolean>(false);

  const { methods: deleteParticipation } = BackendAPI.useBackendAPI('DeleteParticipation');

  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );

  const { methods: editPublicationField } = BackendAPI.useBackendAPI('EditPublicationField');

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleOpenConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(true);
  }, []);

  const handleOpenEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleCloseEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const handleCloseConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleOpenWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const handleCloseWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const submitFinancialPopupCallback = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const handleAttachParticipation = useCallback(() => {
    if (selectedRow) {
      BindParticipationToCurrentUserDepartment.callAPI(
        { participationId: selectedRow?.id },
        {
          onSuccessfullCall: data => {
            if (data.data.success) {
              showNotification({ message: 'Участие успешно привязано', theme: 'success' });
              handleCloseWarningPopup();
            }
          },
          onFailedCall: () => {
            handleCloseWarningPopup();
          },
        },
      );
    }
  }, [selectedRow, BindParticipationToCurrentUserDepartment, handleCloseWarningPopup]);

  const handleDeleteParticipation = useCallback(() => {
    deleteParticipation.callAPI(
      { participationId: selectedRow?.id || '', workbench: 'pc' },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно удалено', theme: 'success' });
          tableStreams.reloadTable.push({});
          setIsConfirmDeleteOpen(false);
        },
        onFailedCall: () => {},
      },
    );
  }, [deleteParticipation, selectedRow?.id, tableStreams.reloadTable]);

  const submitPublicationModal = useCallback(
    ({ selectedRows }: SubmitTable) => {
      editPublicationField.callAPI(
        {
          id: selectedRows[0]?.id,
          parameters: [
            {
              name: 'PARTICIPATION',
              value: selectedRow?.id,
            },
          ],
        },
        {
          onSuccessfullCall: () => {
            setIsPublicationModalOpen(false);
            tableStreams.reloadTable.push({});
            showNotification({
              theme: 'success',
              message: 'Связь с публикацией сохранена',
            });
          },
        },
      );
    },
    [editPublicationField, selectedRow?.id, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: Permits.PARTICIPATION_CONFERENCE_VIEW,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавление',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.PARTICIPATION_CONFERENCE_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактирование',
        onClick: handleEditButtonClick,
        permission: {
          name: getParticipationPermitList('CONFERENCE', 'EDIT'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'CONFERENCE', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удаление',
        onClick: handleOpenConfirmDelete,
        permission: {
          name: getParticipationPermitList('CONFERENCE', 'DELETE'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'CONFERENCE', 'DELETE'),
      },
      {
        icon: { type: 'award' },
        title: selectedRow?.ParticipationAwards ? 'Награды' : 'У участия нет наград',
        onClick: () => {
          if (selectedRow) {
            loadParticipation(selectedRow?.id);
          }
        },
        isDisabled: !selectedRow?.ParticipationAwards,
      },
      {
        icon: { type: 'relation' },
        title: 'Связь публикации с участием в конференции',
        onClick: () => setIsPublicationModalOpen(true),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          selectedRow?.FormCode !== 'CONFERENCE_WITH_REPORT_PARTICIPATION' ||
          !!selectedRow?.Publication,
      },
      {
        icon: { type: 'connection' },
        title: 'Привязать к подразделению',
        onClick: handleOpenWarningPopup,
        permission: {
          name: Permits.PARTICIPATION_CONFERENCE_BIND_TO_DEPARTMENT,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'connection', mode: 'edit' },
        title: 'Редактировать связь участия с подразделениями',
        onClick: handleOpenEditDepartments,
        permission: {
          name: Permits.PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'currency' },
        title: 'Финансовая поддержка участия',
        onClick: () => setIsFinancicalSupportPopupOpen(true),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          (!isHasPermission(userPermission, [
            Permits.PARTICIPATION_CONFERENCE_EDIT_ALL,
            Permits.PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT,
            Permits.PARTICIPATION_CONFERENCE_PROJECTS_ACCEPTING,
            Permits.PARTICIPATION_CONFERENCE_PROJECTS_VIEW_ALL,
          ]) &&
            tableState.selectedRows[0]?.IsUserOwned === 'false'),
      },
    ],
    [
      handleHelpButtonClick,
      handleViewButtonClick,
      tableState.selectedRows,
      handleAddButtonClick,
      handleEditButtonClick,
      userPermission,
      handleOpenConfirmDelete,
      selectedRow,
      handleOpenWarningPopup,
      handleOpenEditDepartments,
      loadParticipation,
    ],
  );

  return {
    buttons,
    isEditDepartmentsOpen,
    handleCloseEditDepartments,
    isConfirmDeleteOpen,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isWarningPopupOpen,
    handleCloseWarningPopup,
    handleAttachParticipation,
    userSystemDepartment,
    selectedRow,
    isPublicationModalOpen,
    setIsPublicationModalOpen,
    submitPublicationModal,
    isFinancicalSupportPopupOpen,
    setIsFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
  };
};

import React from 'react';

import { Modal, Toolbar } from 'components';

import useController from './controller';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

function LeftPanelForThirdLevel() {
  const { buttons, isHelpModalOpen, setIsHelpModalOpen } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Особенности заполнения на вкладке: Интеллектуальная собственность"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        size="medium"
      >
        {ProjectHelp().resultsSecurityDocuments}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

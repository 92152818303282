import { Table } from 'types/models';
import { Color } from 'constants/colors';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController } from './makeUseCustomController';
import { Filters } from './filters';

export type DetailProps = {
  scientistId?: string;
  communityId?: string;
  hasSelectButton?: boolean;
  isCanSelectOnlyOneRow?: boolean;
};

export function GetCommunityMemberList({
  scientistId,
  communityId,
  hasSelectButton,
  isCanSelectOnlyOneRow,
}: DetailProps): Table.Specification {
  return {
    apiID: 'GetCommunityMemberList',
    header: {
      firstLevel: {
        title:
          // eslint-disable-next-line max-len
          'Члены научных сообществ (Диссертационного совета, Научно-технического совета, Редакции журнала, Коллаборации, Фонда/организации)',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        ...(hasSelectButton ? { hasSelectButton: true } : {}),
      },
    },
    requestData: { scientistId, communityId },
    useCustomController: makeUseCustomController({ scientistId }),
    isCanSelectOnlyOneRow,
    highlightRow: row => (!!row.LibraryNote ? Color.danger : ''),
    isWithQna: true,
  };
}

import { AuthorPayment, EventType, Nominee } from 'types/models/Payment';
// import { EventTypeIncentivePayment } from 'utils/Enums';

export function validate(nominee: Nominee | null, eventType: EventType | null, authorPayment: AuthorPayment) {
  if (!nominee) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidDepartment =
    nominee.isAffiliated && !!Number(authorPayment.maxFundMoney) ? Boolean(nominee.topLevelDepartment?.id) : true;
  const isValidFactMoney = nominee.isPaid && !!Number(nominee.planMoney) ? !!Number(nominee.factMoney) : true;
  // const isValidPlanMoney = (() => {
  //   if (nominee.isPaid) {
  //     return eventType === EventTypeIncentivePayment.CONFERENCE ? true : Number(nominee.planMoney) > 0;
  //   }

  //   return true;
  // })();

  return [
    { isValid: isValidDepartment, invalidMessage: 'Не выбран факультет/институт' },
    { isValid: isValidFactMoney, invalidMessage: 'Введите фактическую сумму' },
    // { isValid: isValidPlanMoney, invalidMessage: 'План сумма должна быть больше нуля' },
  ];
}

import React from 'react';

import { TextArea, TextAreaMode } from 'components';

import { Table } from 'types/models';
import { Item } from 'types/models/common';
import { GetProgramEventList } from 'features/Table/specifications';

type Props = {
  programEvent: Item | null;
  programId: string | null;
  onChange(programEvent: Item | null): void;
  isDisabled: boolean;
};

export function SelectProgramEvent(props: Props) {
  const { programEvent, onChange, isDisabled, programId } = props;

  return (
    <TextArea
      mode={TextAreaMode.TABLE}
      settings={{
        title: 'Мероприятия',
        table: {
          specification: GetProgramEventList({ programId: programId || '-1', hasSelectButton: true }),
          onSelect: (row: Table.Entry | null) => onChange(row ? { label: row?.CodeName, value: row?.id } : null),
        },
      }}
      value={programEvent?.label}
      isDisabled={isDisabled}
    />
  );
}

import { useMemo, useState, useCallback } from 'react';
import { format } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { IdItem } from 'types/models/common';
import { Table } from 'types/models';
import { formatStr } from 'utils/Constants';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

type CreateInfo = {
  year: string;
  fundCard: IdItem | null;
  kindConsumption: IdItem | null;
  createdDate: string;
};

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const defaultCreateInfo = useMemo<CreateInfo>(
    () => ({ year: '', fundCard: null, kindConsumption: null, createdDate: format(new Date(), formatStr) }),
    [],
  );

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isFundModalOpen, setIsFundModalOpen] = useState<boolean>(false);
  const [fundId, setFundId] = useState<string>('');
  const [isHasEmptyProjectCode, setIsHasEmptyProjectCode] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [createInfo, setCreateInfo] = useState<CreateInfo>(defaultCreateInfo);

  const { methods: createEstimateByProjectCode } = BackendAPI.useBackendAPI('CreateEstimateByProjectCode');
  const { methods: changeProjectCodeFundCard } = BackendAPI.useBackendAPI('ChangeProjectCodeFundCard');

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
    setCreateInfo(defaultCreateInfo);
  }, [defaultCreateInfo]);

  const confirmCreateModal = useCallback(() => {
    const { year, fundCard, kindConsumption, createdDate } = createInfo;

    if (!year || !fundCard?.id || !kindConsumption?.id || !createdDate) {
      showNotification({ message: 'Заполните все поля', theme: 'danger' });
      return;
    }

    createEstimateByProjectCode.callAPI(
      {
        year: year,
        fundCardId: fundCard.id,
        kindConsumptionId: kindConsumption.id,
        createdDate: createdDate,
        projectCodeIds: tableState.selectedRows.map(x => x.id),
      },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push({});
          closeCreateModal();
        },
      },
    );
  }, [closeCreateModal, createEstimateByProjectCode, createInfo, tableState.selectedRows, tableStreams.reloadTable]);

  const filteredProjects = useMemo(() => {
    return tableState.selectedRows.filter(({ ProjectCode }) => ProjectCode);
  }, [tableState.selectedRows]);

  const changeFundCards = useCallback(
    (id?: string) => {
      changeProjectCodeFundCard.callAPI(
        {
          fundCardId: id || fundId,
          projectsId: filteredProjects.map(x => x.id),
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Запрос успешно обработан', theme: 'success' });
            tableStreams.reloadTable.push({});
          },
        },
      );
      setIsHasEmptyProjectCode(false);
    },
    [changeProjectCodeFundCard, filteredProjects, fundId, tableStreams.reloadTable],
  );

  const selectFundCardId = useCallback(
    (id: string) => {
      setIsFundModalOpen(false);

      if (!filteredProjects.length) {
        showNotification({ message: 'Во всех выбранных проектах не указаны шифры', theme: 'danger' });
        return;
      }

      setFundId(id);

      if (tableState.selectedRows.length !== filteredProjects.length) {
        setIsHasEmptyProjectCode(true);
      } else {
        changeFundCards(id);
      }
    },
    [changeFundCards, filteredProjects.length, tableState.selectedRows.length],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр Шифра проекта',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icon: { type: 'add' },
        title: 'Назначить шифр НР для группы шифров проектов',
        onClick: () => setIsFundModalOpen(true),
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icon: { type: 'calc' },
        title: 'Создать смету для группы проектов',
        onClick: () => setIsCreateModalOpen(true),
        isDisabled: !tableState.selectedRows.length,
      },
      // Temporary comment
      // {
      //   icon: { type: 'edit' },
      //   title: 'Редактировать Шифр проекта',
      //   onClick: () => setIsEditFormOpen(true),
      //   isDisabled: !tableState.selectedRows.length,
      // },
    ],
    [tableState.selectedRows.length],
  );

  return {
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isCreateModalOpen,
    closeCreateModal,
    isFundModalOpen,
    setIsFundModalOpen,
    selectFundCardId,
    isHasEmptyProjectCode,
    setIsHasEmptyProjectCode,
    changeFundCards,
    createInfo,
    setCreateInfo,
    confirmCreateModal,
  };
};

export default useController;

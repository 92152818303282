import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { RequestStatus } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { Color } from 'constants/colors';

type StatusArgs = {
  rows: { id: string; status: RequestStatus | null }[];
  callback?(): void;
};

export function getRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<RequestStatus>(RequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddRequestApprovement');

  const handleButtonClick = useCallback((newStatus: RequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push({});
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, requestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [addApprovementAPI, rows, tableStreams.reloadTable],
  );

  const isAllRowsSent = useMemo(() => rows.length && rows.every(x => !!x.status && [RequestStatus.SENT].includes(x.status)), [
    rows,
  ]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsApprovementModalOpen(true),
        permission: {
          name: Permits.PROGRAM_REQUEST_AGREE,
        },
        isDisabled: rows.length !== 1 || (!!rows[0]?.status && rows[0].status === RequestStatus.DRAFT),
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => handleButtonClick(RequestStatus.REQUEST, 'На согласование'),
        permission: {
          name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REQUEST,
        },
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [RequestStatus.DRAFT, RequestStatus.REVISION].includes(x.status)),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Вернуть на доработку',
        onClick: () => handleButtonClick(RequestStatus.REVISION, 'На доработку'),
        permission: {
          name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_REVISION,
        },
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [RequestStatus.REQUEST, RequestStatus.REJECTED].includes(x.status)),
      },
      // Temporary comment
      // {
      //   icon: { type: 'report' },
      //   title: 'Подать на конкурс',
      //   onClick: () => handleButtonClick(RequestStatus.SENT, 'Подать на конкурс'),
      //   permission: { name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_SENT },
      //   isDisabled: !rows.length || !rows.every(x => !!x.status && [RequestStatus.RECOMENDED].includes(x.status)),
      // },
      {
        icon: { type: 'like' },
        title: 'Рекомендовать на конкурс',
        onClick: () => handleButtonClick(RequestStatus.RECOMENDED, 'Рекомендовать на конкурс'),
        permission: { name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_RECOMENDED },
        isDisabled: !rows.length || !rows.every(x => x.status && [RequestStatus.REQUEST].includes(x.status)),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать в конкурсе',
        onClick: () => handleButtonClick(RequestStatus.APPROVED, 'Поддержать'),
        permission: { name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_APPROVED },
        isDisabled:
          !rows.length || !rows.every(x => !!x.status && [RequestStatus.SENT, RequestStatus.RECOMENDED].includes(x.status)),
      },
      {
        icon: { type: 'dislike' },
        title: isAllRowsSent ? 'Отклонить в конкурсе' : 'Отклонить',
        onClick: () =>
          isAllRowsSent
            ? handleButtonClick(RequestStatus.TENDER_CANCELED, 'Отклонить в конкурсе')
            : handleButtonClick(RequestStatus.REJECTED, 'Отклонить'),
        permission: {
          name: isAllRowsSent
            ? Permits.PROGRAM_REQUEST_CHANGE_STATUS_TENDER_CANCELED
            : Permits.PROGRAM_REQUEST_CHANGE_STATUS_REJECTED,
        },
        isDisabled:
          !rows.length ||
          !(rows.every(x => !!x.status && [RequestStatus.REQUEST, RequestStatus.RECOMENDED].includes(x.status)) || isAllRowsSent),
      },
      {
        icon: { type: 'new' },
        title: 'Перевести из любого статуса в Черновик',
        onClick: () => handleButtonClick(RequestStatus.DRAFT, 'Перевести из любого статуса в Черновик'),
        permission: {
          name: Permits.PROGRAM_REQUEST_CHANGE_STATUS_DRAFT,
        },
        isDisabled: !rows.length || rows.some(x => !!x.status && [RequestStatus.DRAFT].includes(x.status)),
      },
    ],
    [handleButtonClick, isAllRowsSent, rows],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}

import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { FormComponent, Select } from 'components';

import { COLORS, COLORS_BORDERS } from '../сolors';
import { Item } from 'types/models/common';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function SecurityDocumentsAmount() {
  const { activeFilter, periodOpts, handleChangeActiveFilter, activeYearIndex, categories, documentsData } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value, dataset } = context;
      return ` ${dataset.label}: ${new Intl.NumberFormat().format(value)} шт.`;
    };

    const financingByPeriod = documentsData && documentsData[activeYearIndex] ? documentsData[activeYearIndex] : [];

    const data = {
      labels: categories,
      datasets: financingByPeriod.map((x, index) => ({
        label: x.name,
        data: x.data,
        backgroundColor: COLORS[index],
        borderColor: COLORS_BORDERS[index],
        borderWidth: 2,
      })),
    };

    return (
      <Bar
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            y: {
              beginAtZero: true,
              min: 0,
              ticks: {
                precision: 0,
              },
            },
          },
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Патентно-изобретательская деятельность</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Период" hasLeftAlign>
              <Select
                value={activeFilter}
                options={periodOpts.current}
                onChange={(option: Item) => handleChangeActiveFilter(option)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '260px' }}>
          {Chart()}
        </div>
      </div>
    </>
  );
}

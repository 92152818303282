import React, { useState, useEffect, useCallback, useMemo } from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, Select } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import publicationTypes, { PublicationType } from 'types/models/Publication/publicationTypes';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { Item } from 'types/models/common';

type Props = { eventId?: string; compilationTypeCode: string } & F.TemplateProps;

const CompilationAddTemplate: React.FC<Props> = (props: Props) => {
  const { isOpen, onClose, relatedTableState, id, eventId, compilationTypeCode } = props;
  const [isCompilationAddTemplateTypePopupOpen, setIsCompilationAddTemplateTypePopupOpen] = useState<boolean>(true);
  const [publicationType, setPublicationType] = useState<PublicationType | null>(null);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(ElectronicType.WEB);
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>('');
  const NumPublicationTypes = useMemo(() => publicationTypes.filter(e => e.code === compilationTypeCode), [compilationTypeCode]);

  const electronicPublicationTypes = useMemo<F.ElectronicType[]>(() => [ElectronicType.WEB, ElectronicType.LOCAL], []);

  const onChangePublicationType = useCallback(
    (value: string) => {
      const selected = NumPublicationTypes.find(({ id: itemId }) => itemId === value);
      if (selected) {
        setPublicationType(selected);
      }
    },
    [NumPublicationTypes, setPublicationType],
  );

  const onChangeElectronicPublicationType = useCallback(
    (value: string) => {
      const selected = electronicPublicationTypes.find(({ id: itemId }) => itemId === value);
      if (selected) {
        setElectronicPublicationType(selected);
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  const continueWithSelectedType = () => {
    onConfirm();
  };

  const onConfirm = useCallback(() => {
    setIsCompilationAddTemplateTypePopupOpen(false);
  }, []);

  const look: F.CompilationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'CompilationForm',
    type: publicationType?.code,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    eventId,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      const initialPublicationType = publicationTypes.find(
        publicationTypeItem => publicationTypeItem.code === compilationTypeCode,
      );
      if (initialPublicationType) {
        setPublicationType(initialPublicationType);
      }
      setElectronicPublicationType(ElectronicType.WEB);
      setIsElectronic(false);
      setIsCompilationAddTemplateTypePopupOpen(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <>
      {isCompilationAddTemplateTypePopupOpen ? (
        <Modal
          title="Добавление сборника"
          isOpen={isOpen}
          onClose={onClose}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Продолжить',
              onClick: continueWithSelectedType,
              isDisabled: isElectronic && !electronicPublicationType.code,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onClose,
            },
          ]}
          size="medium"
        >
          <FormComponent.Line>
            <FormComponent.Field label="Тип публикации" labelSize="fit">
              <Select
                value={{ value: publicationType?.id || '', label: publicationType?.label || '' }}
                options={NumPublicationTypes.map(x => ({ value: x.id, label: x.label }))}
                onChange={(option: Item) => onChangePublicationType(option.value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          {!!publicationType?.code && <FormComponent.Description mode="info">{publicationType.text}</FormComponent.Description>}

          <FormComponent.Line>
            <Checkbox
              label="Электронное издание, не имеющее печатного аналога"
              checked={!!isElectronic}
              onChange={setIsElectronic}
            />
          </FormComponent.Line>

          {isElectronic && (
            <>
              <FormComponent.Line>
                <Select
                  value={{ value: electronicPublicationType.id, label: electronicPublicationType.label }}
                  options={electronicPublicationTypes.map(x => ({
                    value: x.id,
                    label: x.label,
                  }))}
                  onChange={(option: Item) => onChangeElectronicPublicationType(option.value)}
                />
              </FormComponent.Line>
              {electronicPublicationType.code && (
                <FormComponent.Description mode="info">{electronicPublicationType.text}</FormComponent.Description>
              )}
            </>
          )}
        </Modal>
      ) : (
        <Modal title={title} isOpen={isOpen} onClose={onClose} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(CompilationAddTemplate);

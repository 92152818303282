export type State = {
  eventId?: string;
  isDisableEventSource?: boolean;
};

export function makeUseCustomController({ eventId, isDisableEventSource }: State) {
  return function useCustomController(): State {
    return {
      eventId,
      isDisableEventSource,
    };
  };
}

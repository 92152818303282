import React, { memo, useCallback } from 'react';
import { Modal } from 'components';
import { DataGrid } from 'features/Table';
import { GetProjectListForContestRequest } from 'features/Table/specifications/GetProjectForContestSelectList';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit: (id: string) => void;
  isPersonal?: boolean;
};

export const Container = memo(({ isOpen, onClose, onSubmit, isPersonal }: Props) => {
  const setProjectId = useCallback(
    (projectId: string) => {
      onSubmit(projectId);
    },
    [onSubmit],
  );

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Выберите проект" size={['extralarge', 'large-height']}>
      <DataGrid specification={GetProjectListForContestRequest({ setProjectId, isWorkbenchPc: Boolean(isPersonal) })} />
    </Modal>
  );
});

import { useCallback, useEffect, useRef, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Report, Table } from 'types/models';
import { EmployeePayment } from 'types/models/Payment';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { calcRemainderAmount, checkIsBudgetOver, getInitialAmount, getInitialEmployeePayment } from './helpers';
import { PaymentRequestStatus } from 'utils/Enums';

export type State = {
  selectedRequest: Table.Entry | null;
  isOpenRequestForm: boolean;
  employeePayment: EmployeePayment | null;
  setEmployeePayment(employeePayment: EmployeePayment | null): void;
  closeFormRequest(): void;
  openFormRequest(mode: Mode): void;
  saveEmployeePayment(): void;
  comment: string;
  setComment(comment: string): void;
  toggleVisibleCommentForm(): void;
  isOpenCommentForm: boolean;
  startSaveEmployeePayment(): void;
  isOpenConfirmDelete: boolean;
  setIsOpenConfirmDelete(isOpen: boolean): void;
  deleteEmployeePayment(): void;
  changeEmployeePaymentStatus(status: PaymentRequestStatus): void;
  onPrintClick(): void;
  onReportClose: () => void;
  isReportOpen: boolean;
  currentReport: Report | null;
  mode: Mode | null;
  selectedFund: Table.Entry | null;
};

export type Mode = 'add' | 'edit' | 'view';

type Arguments = {
  isReloadingRef: React.MutableRefObject<boolean>;
  selectedFund: Table.Entry | null;
};

export function makeUseCustomController({ isReloadingRef, selectedFund }: Arguments) {
  return function useCustomController({ selectedRows, entryPages }: Table.UseCustomControllerProps): State {
    const [selectedRequest, setSelectedRequest] = useState<Table.Entry | null>(null);
    const [isOpenRequestForm, setIsOpenRequestForm] = useState(false);
    const [employeePayment, setEmployeePayment] = useState<EmployeePayment | null>(null);
    const [comment, setComment] = useState<string>('');
    const [isOpenCommentForm, setIsOpenCommentForm] = useState(false);
    const [isOpenConfirmDelete, setIsOpenConfirmDelete] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const id = useRef<string | null>(null);

    const tableStreams = useLocalTableStreams();

    const { methods: getEmployeePaymentAPI } = BackendAPI.useBackendAPI('GetEmployeePaymentRequest');
    const { methods: saveEmployeePaymentAPI } = BackendAPI.useBackendAPI('SaveEmployeePaymentRequest');
    const { methods: deleteEmployeePaymentAPI } = BackendAPI.useBackendAPI('DeleteEmployeePaymentRequest');
    const { methods: changeEmployeePaymentStatusAPI } = BackendAPI.useBackendAPI('ChangeEmployeePaymentRequestStatus');

    const reloadTable = useCallback(() => {
      tableStreams.updateFirstLevelPanelState.push({
        state: {
          year: selectedFund?.year ?? null,
          fundType: selectedFund?.['id:type'] ?? null,
          fundFaculty: selectedFund?.['id:faculty'] ?? null,
          quarter: selectedFund?.['id:quarter'] ?? null,
          month: selectedFund?.month ?? null,
        },
      });
    }, [tableStreams.updateFirstLevelPanelState]);

    const [row] = selectedRows;
    useEffect(() => {
      setSelectedRequest(row ?? null);
    }, [row]);

    useEffect(() => {
      reloadTable();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedFund]);

    const reloadTables = useCallback(() => {
      reloadTable();
      isReloadingRef.current = true;
    }, [reloadTable]);

    const closeFormRequest = useCallback(() => {
      setIsOpenRequestForm(false);
      setEmployeePayment(null);
      setComment('');
      setMode(null);
    }, []);

    const toggleVisibleCommentForm = useCallback(() => {
      setIsOpenCommentForm(prev => !prev);
    }, []);

    const openFormRequest = useCallback(
      (openMode: Mode) => {
        if (openMode === 'add') {
          if (checkIsBudgetOver(selectedFund, entryPages)) {
            showNotification({ message: 'В фонде не осталось средств', theme: 'danger' });
            return;
          }
          setIsOpenRequestForm(true);
          if (selectedFund) {
            setEmployeePayment(getInitialEmployeePayment(selectedFund));
          }
        } else if (selectedRequest) {
          getEmployeePaymentAPI.callAPI(
            { id: selectedRequest.id },
            {
              onSuccessfullCall: ({ data }) => {
                setEmployeePayment(data);
                setIsOpenRequestForm(true);
              },
            },
          );
        }
        setMode(openMode);
      },
      [selectedRequest, entryPages, getEmployeePaymentAPI],
    );

    const saveEmployeePayment = useCallback(
      (onSuccessCb?: () => void) => {
        if (employeePayment) {
          saveEmployeePaymentAPI.callAPI(
            {
              comment,
              employeePayment,
              departmentId: selectedFund?.['id:faculty'],
              year: selectedFund?.year,
              month: selectedFund?.month,
              quarter: selectedFund?.['id:quarter'],
              type: selectedFund?.['id:type'],
            },
            {
              onSuccessfullCall: ({ data }) => {
                if (onSuccessCb) {
                  id.current = data.id;
                  onSuccessCb();
                } else {
                  showNotification({ message: 'Заявка успешно сохранена', theme: 'success' });
                  closeFormRequest();
                  reloadTables();
                }
              },
            },
          );
        }
      },
      [employeePayment, saveEmployeePaymentAPI, comment, closeFormRequest, reloadTables],
    );

    const startSaveEmployeePayment = useCallback(
      (onSuccessCb?: () => void) => {
        const errorMsg = getErrorMsg(employeePayment);
        if (errorMsg) {
          showNotification({ message: errorMsg, theme: 'danger' });
        } else if (onSuccessCb) {
          saveEmployeePayment(onSuccessCb);
        } else {
          toggleVisibleCommentForm();
        }
      },
      [employeePayment, toggleVisibleCommentForm, saveEmployeePayment],
    );

    const deleteEmployeePayment = useCallback(() => {
      deleteEmployeePaymentAPI.callAPI(
        { id: row?.id },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Заявка успешно удалена', theme: 'success' });
            reloadTables();
          },
        },
      );
    }, [deleteEmployeePaymentAPI, row?.id, reloadTables]);

    const changeEmployeePaymentStatus = useCallback(
      (status: PaymentRequestStatus) => {
        changeEmployeePaymentStatusAPI.callAPI(
          { id: row?.id ?? id.current ?? '', status, comment },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
              reloadTables();
              closeFormRequest();
              id.current = null;
            },
          },
        );
      },
      [changeEmployeePaymentStatusAPI, row?.id, comment, reloadTables, closeFormRequest],
    );

    const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

    const onPrintClick = useCallback(() => {
      handleSetCurrentReport({ name: Reports.AppToMemorandum.caption, value: Reports.AppToMemorandum.name });
    }, [handleSetCurrentReport]);

    useEffect(() => {
      tableStreams.loadInitialTable.push();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return {
      comment,
      selectedRequest,
      isOpenRequestForm,
      employeePayment,
      isOpenCommentForm,
      isOpenConfirmDelete,
      mode,
      selectedFund,
      isReportOpen,
      currentReport,
      onReportClose,
      setComment,
      openFormRequest,
      closeFormRequest,
      setEmployeePayment,
      saveEmployeePayment,
      deleteEmployeePayment,
      setIsOpenConfirmDelete,
      startSaveEmployeePayment,
      toggleVisibleCommentForm,
      changeEmployeePaymentStatus,
      onPrintClick,
    };
  };
}

export function getErrorMsg(employeePayment: EmployeePayment | null) {
  const isSumLowerFund =
    Number(employeePayment?.amountMoney ?? 0) <=
    (Number(getInitialAmount(employeePayment?.fund) ?? 0) + Number(employeePayment?.amountMoney ?? 0) || 0);
  const isAllDistributed = calcRemainderAmount(employeePayment) === 0;

  if (!isSumLowerFund) {
    return 'Сумма больше фонда';
  }

  if (!isAllDistributed) {
    showNotification({ message: 'Сумма по сотрудникам не равна сумме оплаты по факультету', theme: 'danger' });
  }

  return null;
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { KindConsumptionGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isEditButtonDisabled && (
        <modalTemplates.KindConsumptionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Виды расходов из бухгалтерии"
        size={['large', 'medium-height']}
      >
        {KindConsumptionGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { useCallback, useState } from 'react';

import { ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { Member, PersonHistoryModal } from 'features/SelectPerson';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { BuildReportPopup, useReportsHook, Reports } from 'features/BuildReportPopup';
import { PortfolioCard } from 'features/Form/looks/person';
import {
  formatDegreeRank,
  formatIdentifiers,
  formatIndexHirsh,
  getMockPerformer,
} from 'features/Form/looks/nirRequest/NirRequestForm/helpers';
import { Fields } from './Fields';
import { validate } from './validate';
import { NirTenderType } from 'utils/Enums';

type Props = {
  performers: NirRequest.Performer[];
  setPerformers(performers: NirRequest.Performer[]): void;
  disabled: boolean;
  tenderType: NirTenderType | null;
};

export function TeamComposition(props: Props) {
  const { performers, setPerformers, disabled, tenderType } = props;

  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [isOpenPersonModal, setIsOpenPersonModal] = useState(false);
  const [selectedPerformerIndex, setSelectedPerformerIndex] = useState<number | null>(null);

  const formatJobOrEducation = (performer: NirRequest.Performer) => {
    if (performer.job) {
      return formatJob(performer.job);
    }

    return performer.education ? formatEducation(performer.education) : '';
  };

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedPerformerIndex !== null) {
        const updatedPerformers = [...performers];
        updatedPerformers[selectedPerformerIndex] = { ...performers[selectedPerformerIndex], ...member } as NirRequest.Performer;

        setPerformers(updatedPerformers);
      }
    },
    [selectedPerformerIndex, performers, setPerformers],
  );

  const handleCloseHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(false);
    setSelectedPerformerIndex(null);
  }, [setIsOpenHistoryPersonModal, setSelectedPerformerIndex]);

  const handleOpenHistoryModal = useCallback(() => {
    setIsOpenHistoryPersonModal(true);
  }, [setIsOpenHistoryPersonModal]);

  const closePersonModal = useCallback(() => {
    setIsOpenPersonModal(false);
    setSelectedPerformerIndex(null);
  }, []);

  const openPersonModal = useCallback(() => {
    setIsOpenPersonModal(true);
  }, []);

  const onClickHistoryIcon = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      if (selectedPerformer !== null && selectedRowIndex !== null) {
        setSelectedPerformerIndex(selectedRowIndex);
        handleOpenHistoryModal();
      }
    },
    [setSelectedPerformerIndex, handleOpenHistoryModal],
  );

  const onClickInfoIcon = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      if (selectedPerformer !== null && selectedRowIndex !== null) {
        setSelectedPerformerIndex(selectedRowIndex);
        openPersonModal();
      }
    },
    [openPersonModal],
  );

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(
    (selectedPerformer: NirRequest.Performer | null, selectedRowIndex: number | null) => {
      setSelectedPerformerIndex(selectedRowIndex);
      handleSetCurrentReport({ name: Reports.Personal.caption, value: Reports.Personal.name });
    },
    [handleSetCurrentReport],
  );

  const selectedPerformer = selectedPerformerIndex !== null ? performers[selectedPerformerIndex] : null;

  const participationMapTitle: Record<NirTenderType, string> = {
    IG: 'Участие в международных научных сообществах',
    LMU: 'Участие в международных научных сообществах',
    ML: 'Участие в международных научных сообществах',
    ONG: 'Участие в международных научных сообществах',
    PD: 'Участие в международных научных сообществах',
    PP: 'Участие в хоздоговорах',
  };

  return (
    <>
      <ListEdit
        rows={performers}
        onChange={setPerformers}
        toolbar={[
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'user' },
            title: 'Просмотр сведений о персоне',
            onClick: onClickInfoIcon,
            isDisabled: row => row === null || disabled,
          },
          {
            icon: { type: 'clock' },
            title: 'Персональные данные',
            onClick: onClickHistoryIcon,
            isDisabled: row => row === null || disabled,
          },
          {
            icon: { type: 'print' },
            title: 'Отчеты',
            onClick: onClickPrint,
            isDisabled: row => row === null || disabled,
          },
        ]}
        columns={[
          { label: 'Роль', formatValue: x => x.role?.label ?? '' },
          { label: 'ФИО', formatValue: x => x.person?.fullName ?? '' },
          { label: 'Дата рождения', formatValue: x => x.person?.scientist?.dateBirth ?? '' },
          { label: 'Степень, звание', formatValue: formatDegreeRank },
          { label: 'Место работы / обучения', formatValue: formatJobOrEducation, styles: { width: '20%' } },
          { label: 'Гражданство', formatValue: x => x.citizenship?.label ?? '' },
          {
            label: participationMapTitle[tenderType ?? 'LMU'],
            formatValue: x => !!x.collaborationParticipation || !!x.economicContractParticipationExperience,
            styles: { width: '20%', textAlign: 'center' },
          },
          { label: 'Индексы Хирша', formatValue: x => formatIndexHirsh(x) },
          { label: 'Идентификаторы', formatValue: x => formatIdentifiers(x) },
          {
            label: 'Публикаций Scopus/Wos Q1, Q2',
            formatValue: x =>
              [
                x.personQ1Q2PublicationQty || '0',
                x.foreignPerformerQ1Q2PublicationQty ? `Примечание ${x.foreignPerformerQ1Q2PublicationQty}` : '',
              ]
                .filter(Boolean)
                .join(', '),
          },
        ]}
        maxHeight="none"
        defaultRowsCount={10}
        withMessages
        isVisibleFilters
        isDisabled={disabled}
        isDeleteConfirmEnabled
        specification={{
          mode: 'customComponent',
          renderComponent: (performer, setPerformer) => (
            <Fields tenderType={tenderType} performer={performer || getMockPerformer()} setPerformer={setPerformer} />
          ),
          validation: {
            checkIsValid: (performer, index, list, mode) => validate(performer, list, mode).every(x => x.isValid),
            onInvalidate: (performer, mode, index, list) => {
              const validationInfo = validate(performer, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
      <PersonHistoryModal
        isOpen={isOpenHistoryPersonModal}
        member={selectedPerformer}
        onClose={handleCloseHistoryModal}
        onSubmit={submitHistoryPersonModal}
        isLoadPersonAfterOpen
        mode="edit"
      />
      <PortfolioCard isOpen={isOpenPersonModal} id={selectedPerformer?.person?.id ?? null} onClose={closePersonModal} />
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          scientistId: selectedPerformer?.person?.scientist?.id ?? '',
        }}
      />
    </>
  );
}

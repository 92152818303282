import React from 'react';

import { FormComponent } from 'components';

import { RntdAuthor } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';

type Props = {
  author: RntdAuthor | null;
  onAuthorChange(row: RntdAuthor): void;
};

function AuthorInputs({ author, onAuthorChange }: Props) {
  const { handlePersonChange } = useController({
    author,
    onAuthorChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Автор">
          <SelectPerson member={author || null} onUpdateMember={val => handlePersonChange(val)} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { AuthorInputs };

import { useCallback, useEffect, useMemo, useState } from 'react';
import ReactDOM from 'react-dom';

import { Mode } from 'components/ListEdit/model';

import { Tender } from 'types/models';
import { RadioItem } from 'features/BuildReportPopup/models';
import { ValueOf } from 'types/helpers';
import { getMockLot } from 'features/Form/looks/tender/TenderForm/helpers';
import { getEnum } from 'utils/Helpers';
import { NirTenderType } from 'utils/Enums';
import { Item } from 'types/models/common';
import { Lot, LotPrice } from 'types/models/Tender';

type Props = {
  mode: Mode;
  lot: Tender.Lot | null;
  onLotChange: React.Dispatch<React.SetStateAction<Tender.Lot | null>>;
  viewMode: boolean;
  tenderName: string;
  tenderStartDate: string;
  lots: Tender.Lot[];
};

export function useController({ mode, lot, onLotChange, tenderName, tenderStartDate, lots }: Props) {
  const excludeTypeValues = lots.map(i => i.nirTenderType?.value);

  let nirTenderTypeOptions = getEnum('NirTenderType') as Array<Item<NirTenderType>>;
  if (mode === 'add') {
    nirTenderTypeOptions = nirTenderTypeOptions.filter(i => !excludeTypeValues.includes(i.value));
  }

  const handleLotPricesChange = useCallback(
    (value: LotPrice[]) => {
      onLotChange({ ...lot, prices: value } as Tender.Lot);
    },
    [lot, onLotChange],
  );

  const handleAmountChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: string | number) => {
      if (lot) {
        ReactDOM.unstable_batchedUpdates(() => {
          const updatedLog = { ...lot, [fieldName]: value };
          onLotChange({ ...updatedLog, contractAmount: updatedLog.limitAmount / updatedLog.contractCount });
        });
      }
    },
    [lot, onLotChange],
  );

  const handleChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: ValueOf<Tender.Lot>) => {
      if (lot) {
        onLotChange({ ...lot, [fieldName]: value });
      }
    },
    [lot, onLotChange],
  );

  useEffect(() => {
    if (!lot) {
      onLotChange({
        ...getMockLot(),
        number: String(lots.length + 1),
        code: lots.length === 0 ? '' : '',
        contractCount: 1,
        theme: tenderName,
        startDate: tenderStartDate,
      });
    }
    // eslint-disable-next-line
  }, []);

  const handleAmountRadioChange = useCallback(
    (fieldName: keyof Tender.Lot) => (value: string) => {
      let restValue: boolean = false;
      if (fieldName === 'isSupportContract' || fieldName === 'isSupportRequest' || fieldName === 'isSupportWarranty') {
        restValue = value === 'true';
        if (fieldName === 'isSupportContract') {
          setContractSupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, contractSupportAmount: 0 } });
          }
        }
        if (fieldName === 'isSupportRequest') {
          setRequestSupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, requestSupportAmount: 0 } });
          }
        }
        if (fieldName === 'isSupportWarranty') {
          setWarrantySupportAmountDisabled(!restValue);
          if (lot) {
            onLotChange({ ...lot, ...{ [fieldName]: restValue, warrantySupportAmount: 0 } });
          }
        }
      } else {
        onLotChange({ ...lot, [fieldName]: restValue } as Tender.Lot);
      }
    },
    [lot, onLotChange],
  );

  const handleDateChange = useCallback(
    (fieldName: keyof Lot, value: string) => {
      onLotChange({ ...lot, [fieldName]: value } as Tender.Lot);
    },
    [lot, onLotChange],
  );

  const initialRadioList: RadioItem[] = useMemo(
    () => [
      { label: 'Да', value: 'true' },
      { label: 'Нет', value: 'false' },
    ],
    [],
  );

  const [yesNoRadios] = useState<RadioItem[]>(initialRadioList);

  const [requestSupportAmountDisabled, setRequestSupportAmountDisabled] = useState<boolean>(true);
  const [contractSupportAmountDisabled, setContractSupportAmountDisabled] = useState<boolean>(true);
  const [warrantySupportAmountDisabled, setWarrantySupportAmountDisabled] = useState<boolean>(true);

  return {
    handleLotPricesChange,
    handleChange,
    handleAmountRadioChange,
    handleDateChange,
    yesNoRadios,
    requestSupportAmountDisabled,
    contractSupportAmountDisabled,
    warrantySupportAmountDisabled,
    handleAmountChange,
    nirTenderTypeOptions,
  };
}

import React, { useMemo } from 'react';
import { EventTypeCode } from 'types/models/Event';
import { EventTypes } from 'utils/Enums/EventTypes';
import { Modal } from 'components';
import { Component as ApproveEventComponent } from './component';

type Props = {
  typeCode: EventTypeCode;
  id: string;
  isOpen: boolean;
  onClose: () => void;
};

const ApproveEventContainer = ({ typeCode, isOpen, onClose, id }: Props) => {
  const title = useMemo(() => {
    switch (typeCode) {
      case EventTypes.EVENT_CONFERENCE.code:
        return 'Процедура утверждения конференции';
      case EventTypes.EVENT_EXPOSITION.code:
        return 'Процедура утверждения выставки';
      case EventTypes.EVENT_CONTEST.code:
        return 'Процедура утверждения конкурса';
      case EventTypes.EVENT_CONCERT.code:
        return 'Процедура утверждения творческого мероприятия';
      default:
        return '';
    }
  }, [typeCode]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <ApproveEventComponent typeCode={typeCode} onClose={onClose} id={id} />
    </Modal>
  );
};

export const Component = React.memo(ApproveEventContainer);

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );

  return { buttons, isHelpFormOpen, handleTemplateCloseHelpForm };
}

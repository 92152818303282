import React from 'react';

import { FormComponent, TextArea, TextAreaMode, TextInput, TextInputMode, Checkbox } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Table } from 'types/models';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RequestMobileKindConsumptionForm({ viewMode, editMode, onClose }: Props) {
  const {
    requestKindConsumption,
    formFields,
    handleFormSubmit,
    modalTableRowKindConsumptionConverter,
    kindConsumptionTableConfig,
    incrimentKindConsumptionsTooltipTxt,
    requestKindConsumptionTableConfig,
    workMode,
    modalTableRowRequestKindConsumptionConverter,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
            <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.position.title}
            isRequired={formFields.position.required}
            tooltip={formFields.position.tooltipText}
          >
            <TextInput
              mode={TextInputMode.NUMBER}
              value={formFields.position.value}
              onChange={formFields.position.onChange}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
          <FormComponent.Field label="" labelWidth={10}>
            <Checkbox
              label="Активный"
              checked={!!formFields.isActive.value}
              onChange={formFields.isActive.onChange}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.description.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={formFields?.description.value || ''}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields?.kindConsumption.title} tooltip={formFields.kindConsumption.tooltipText}>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: formFields?.kindConsumption.title,
                table: {
                  specification: kindConsumptionTableConfig,
                  onSelect: (row: Table.Entry | null) =>
                    formFields?.kindConsumption.onChange(row ? modalTableRowKindConsumptionConverter(row) : null),
                },
              }}
              value={formFields?.kindConsumption?.value?.name}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          {(requestKindConsumption?.incrimentKindConsumptions !== undefined &&
            requestKindConsumption?.incrimentKindConsumptions?.length <= 0) ||
          workMode === 'addMode' ? (
            <FormComponent.Field label="Входит в группу" tooltip={formFields?.incrimenator?.tooltipText}>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: 'Входит в группу',
                  table: {
                    specification: requestKindConsumptionTableConfig,
                    onSelect: (row: Table.Entry | null) =>
                      formFields.incrimenator.onChange(row ? modalTableRowRequestKindConsumptionConverter(row) : null),
                  },
                }}
                value={formFields?.incrimenator?.value?.name}
                isDisabled={viewMode}
              />
            </FormComponent.Field>
          ) : (
            <FormComponent.Field label="Суммирует" tooltip={incrimentKindConsumptionsTooltipTxt}>
              <FormComponent.Text>
                {requestKindConsumption?.incrimentKindConsumptions?.map(kc => kc.name)?.join(', ') || '-'}
              </FormComponent.Text>
            </FormComponent.Field>
          )}
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(RequestMobileKindConsumptionForm);

const Languages = [
  { id: '1', code: 'rus', nominative: 'русский', accusative: 'русском' },
  { id: '2', code: 'eng', nominative: 'английский', accusative: 'английском' },
  { id: '3', code: 'deu', nominative: 'немецкий', accusative: 'немецком' },
  { id: '4', code: 'fra', nominative: 'французский', accusative: 'французком' },
  { id: '7', code: 'ukr', nominative: 'украинский', accusative: 'украинском' },
  { id: '8', code: 'zho', nominative: 'китайский', accusative: 'китайском' },
  { id: '9', code: 'kaz', nominative: 'казахский', accusative: 'казахском' },
  { id: '10', code: 'lav', nominative: 'латышский', accusative: 'латышском' },
  { id: '11', code: 'lit', nominative: 'литовский', accusative: 'литовском' },
  { id: '12', code: 'hun', nominative: 'венгерский', accusative: 'венгерском' },
  { id: '13', code: 'por', nominative: 'португальский', accusative: 'португальском' },
  { id: '14', code: 'spa', nominative: 'испанский', accusative: 'испанском' },
  { id: '15', code: 'bul', nominative: 'болгарский', accusative: 'болгарском' },
  { id: '16', code: 'pol', nominative: 'польский', accusative: 'польском' },
  { id: '17', code: 'ita', nominative: 'итальянский', accusative: 'итальянском' },
  { id: '19', code: 'cze', nominative: 'чешский', accusative: 'чешском' },
];

export { Languages };

import React, { useState } from 'react';
import { setup } from 'bem-cn';

import { ButtonProps, Icon, Toolbar } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('form-collapse');

type Props = {
  /** @property {string} title - (optional) Title for clickable line (if undefined, outher controlling) */
  title?: string;
  /** @property {ButtonProps[]} buttons - (optional) List of buttons */
  buttons?: ButtonProps[];
  /** @property {JSX.Element} info - (optional) Content on right part of title line */
  info?: JSX.Element;
  /** @property {boolean} isExpanded - (optional) Collapse is expanded (inner/outher) (false by default) */
  isExpanded?: boolean;
  /** @property {any} children - (optional) React inner JSX component */
  children: any;
};

/**
 * Collapse component (external/internal control)
 *
 * @param {string} title - (optional) Title for internal control
 * @param {ButtonProps[]} buttons - (optional) List of buttons
 * @param {JSX.Element} info - (optional) Content on right part of title line
 * @param {boolean} isExpanded - (optional) External control (false by default)
 * @returns JSX
 */
export const Collapse = React.memo(({ title, info, isExpanded = false, buttons = [], children }: Props) => {
  const [isActive, setIsActive] = useState(isExpanded);

  return (
    <div className={b({ extended: !!title ? isActive : isExpanded })}>
      {title && (
        <div className={b('title')} onClick={() => setIsActive(!isActive)}>
          <span>{title}</span>
          <div className={b('title-arrow')}>
            <Icon type="chevronDown" size={14} />
          </div>
          {!!buttons.length && <Toolbar buttons={buttons} onClick={e => e.stopPropagation()} />}
          {!!info && (
            <div className={b('title-info')} onClick={e => e.stopPropagation()}>
              {info}
            </div>
          )}
        </div>
      )}
      <div className={b('content')}>{children}</div>
    </div>
  );
});

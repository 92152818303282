import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistSecurityDocumentList } from 'features/Table/specifications';

type Props = {
  personId?: string;
};

export function SecurityDocuments(props: Props) {
  const { personId } = props;

  if (!personId) {
    return null;
  }

  return <DataGrid specification={GetScientistSecurityDocumentList({ personId })} hasPersonalMode />;
}

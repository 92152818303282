import { useLayoutEffect, useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { useQnaFormController } from '../qnaFormController';

type Props = {
  onClose: () => void;
};

export const useController = ({ onClose }: Props) => {
  const {
    look: { id },
  } = useFormContext();

  const tableStreams = useLocalTableStreams();

  const { methods: getQnaQuestion } = BackendAPI.useBackendAPI('GetQnaQuestion');
  const { methods: saveQnaQuestionAnswer } = BackendAPI.useBackendAPI('SaveQnaQuestionAnswer');

  const [themeText, setThemeText] = useState<string>('');
  const [questionText, setQuestionText] = useState<string>('');

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams]);

  const { formFields, fieldsFromModel } = useQnaFormController();

  const loadQnaQuestion = useCallback(
    (currentId?: string) => {
      if (id) {
        getQnaQuestion.callAPI(
          {
            id: currentId || id || null,
          },
          {
            onSuccessfullCall: ({ data }) => {
              fieldsFromModel(data);
              setQuestionText(data.message);
              setThemeText(data.theme.label);
            },
          },
        );
      }
    },
    [getQnaQuestion, fieldsFromModel, id],
  );

  const saveQnaAnswerHandler = useCallback(() => {
    if (!formFields.answer.value) {
      showNotification({ message: 'Напишите ответ', theme: 'danger' });
      return false;
    }
    saveQnaQuestionAnswer.callAPI(
      {
        id,
        answerText: formFields.answer.value,
        answerFile: { ...formFields.answerFile?.value },
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Ответ сохранен', theme: 'success' });
          updateTable();
          onClose();
        },
      },
    );
    return true;
  }, [saveQnaQuestionAnswer, id, formFields, updateTable, onClose]);

  useLayoutEffect(() => {
    loadQnaQuestion();
    // eslint-disable-next-line
  }, []);

  return {
    questionText,
    themeText,
    id,
    formFields,
    saveQnaAnswerHandler,
  };
};

import { useState, useLayoutEffect, useCallback } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { MipIndicator } from 'types/models/MipIndicator';

import { useFormContext } from 'features/Form/hooks';
import { getMockMipIndicator } from './helpers';
import { validateMipIndicator } from 'features/EditMipPartner/Tabs/Indicators/validate';
import { showErrorsMessages } from 'utils/Common';
import { Table } from 'types/models';

type Props = {
  onClose: () => void;
};

export function useController({ onClose }: Props) {
  const [indicator, setIndicator] = useState<MipIndicator>(getMockMipIndicator());

  const tableStreams = useLocalTableStreams();

  const {
    look: { id, editMode, viewMode },
  } = useFormContext();

  const workMode: Table.WorkMode = editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode';

  const { methods: getMipIndicator } = BackendAPI.useBackendAPI('GetPartnerMipIndicator', {
    onSuccessfullCall: ({ data }) => {
      setIndicator(data);
    },
  });

  const { methods: saveMipIndicator } = BackendAPI.useBackendAPI('SavePartnerMipIndicator', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Показатель успешно сохранен', theme: 'success' });
      onClose();
      tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback(() => {
    const validationInfo = validateMipIndicator(indicator, 0, [indicator], 'edit');

    const invalid = validationInfo.filter(x => !x.isValid);
    if (invalid && invalid?.length > 0) {
      showErrorsMessages(invalid.map(x => x.invalidMessage));
    } else {
      saveMipIndicator.callAPI({ indicator });
    }
  }, [saveMipIndicator, indicator]);

  useLayoutEffect(() => {
    if (id) getMipIndicator.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    indicator,
    setIndicator,
    onSubmit,
    workMode,
  };
}

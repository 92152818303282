export enum RequestStatus {
  DRAFT = 'DRAFT',
  REQUEST = 'REQUEST',
  RECOMENDED = 'RECOMENDED',
  SENT = 'SENT',
  REVISION = 'REVISION',
  REJECTED = 'REJECTED',
  APPROVED = 'APPROVED',
  TENDER_CANCELED = 'TENDER_CANCELED',
}

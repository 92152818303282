import React, { useEffect, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import { Button, Icon, Popover } from 'components';

import { Color } from 'constants/colors';

import './style.scss';
import '../Modal/style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('info');

export type Props = {
  /** @property {JSX.Element} text - (optional) Tooltip text on hovered */
  text: JSX.Element;
};

/**
 * Info tooltip as (?) with JSX text
 *
 * @param text - Text for for bubble
 * @returns JSX
 */
export const Info = React.memo(({ text }: Props) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [mbClosed, setMbClosed] = useState<boolean>(false);

  const refIcon = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        setMbClosed(true);
      }, 0.4 * 1000);
    } else {
      setMbClosed(false);
    }
  }, [isOpen]);

  return (
    <>
      <Icon
        refIcon={refIcon}
        type="question"
        color={Color.success}
        size={16}
        // onMouseEnter={() => setIsOpen(true)}
        onClick={() => setIsOpen(true)}
        classMixin={b.toString()}
      />

      <Popover
        isOpen={isOpen}
        onClose={() => setIsOpen(false)}
        anchorRef={refIcon.current}
        position={{
          offset: 20,
          anchor: {
            vertical: 'top',
            horizontal: 'left',
          },
          content: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <div onMouseLeave={() => (mbClosed ? setIsOpen(false) : () => {})}>
          <Button icon={{ type: 'clear', color: Color.danger }} onClick={() => setIsOpen(false)} classMixin={b('close')} />
          <div className={b('content')}>{text}</div>
        </div>
      </Popover>
    </>
  );
});

export enum RequestCopyOption {
  DESCRIPTION = 'DESCRIPTION',
  CLASSIFICATORS = 'CLASSIFICATORS',
  PERFORMERS = 'PERFORMERS',
}

export enum NirRequestCopyOption {
  DESCRIPTION = 'DESCRIPTION',
  STAGES = 'STAGES',
  INDICATORS = 'INDICATORS',
  PERFORMERS = 'PERFORMERS',
}

export enum ContestRequestCopyOption {
  STAGE = 'STAGE',
  INDICATORS = 'INDICATORS',
  PERFORMERS = 'PERFORMERS',
}

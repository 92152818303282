import { NirRequest } from 'types/models';
import { NirRequestPerformerRole } from 'utils/Enums';

export function validate(
  performer: NirRequest.Performer | null,
  list: NirRequest.Performer[],
  mode: 'view' | 'add' | 'edit' | null,
) {
  if (!performer) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const performers =
    mode === 'edit' ? list.filter(x => x.person?.id !== performer.person?.id && x.role !== performer.role) : list;

  const isValidRole = Boolean(performer.role);
  const isValidPerson = Boolean(performer.person);
  const isValidCollaborationParticipation = performer.collaborationParticipation
    ? performer.collaborationParticipation.length <= 500
    : true;

  const leaderPerformer = performers.find(x => x.role?.value === NirRequestPerformerRole.LEADER) ?? null;
  const responsiblePerformer = performers.find(x => x.role?.value === NirRequestPerformerRole.RESPONSIBLE_PERFORMER) ?? null;
  const isValidAmountLeaderPerformers = performer.role?.value === NirRequestPerformerRole.LEADER ? leaderPerformer == null : true;
  const isValidAmountResponsiblePerformers =
    performer.role?.value === NirRequestPerformerRole.RESPONSIBLE_PERFORMER ? responsiblePerformer === null : true;

  const isHasDublicate = performers.filter(x => x.person?.id === performer.person?.id && performer.role === x.role).length > 0;
  const isValidDublicatePerformer = !isHasDublicate;

  return [
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: isValidPerson, invalidMessage: 'Выберите персону' },
    { isValid: isValidAmountLeaderPerformers, invalidMessage: 'В коллективе может быть только 1 руководитель' },
    { isValid: isValidDublicatePerformer, invalidMessage: 'Выбранный исполнитель уже есть в списке' },
    {
      isValid: isValidAmountResponsiblePerformers,
      invalidMessage: 'В коллективе может быть только 1 ответственный исполнитель',
    },
    {
      isValid: isValidCollaborationParticipation,
      invalidMessage: 'Поле - Участие в международных научных сообществах, превышен лимит в количестве знаков 500',
    },
  ];
}

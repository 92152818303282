import React from 'react';
import { setup } from 'bem-cn';

import { Info, Tooltip } from 'components';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('form-component-description');

type DescriptionMode = 'default' | 'danger' | 'warning' | 'info';
type DescriptionAlign = 'left' | 'center' | 'right';

type Props = {
  /** @property {DescriptionMode} mode - (optional) Color sheme of block ('default' by default) */
  mode?: DescriptionMode;
  /** @property {DescriptionAlign} align - (optional) Position of block */
  align?: DescriptionAlign;
  /** @property {string} tooltip - (optional) Show tooltip with text */
  tooltip?: string;
  /** @property {JSX.Element} info - (optional) Show info with html */
  info?: JSX.Element;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[]} children
   * - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[];
};

/**
 * Visible block with content
 *
 * @param {DescriptionMode} mode - (optional) Color sheme of block ('default' by default)
 * @param {DescriptionAlign} align - (optional) Position of block
 * @param {string} tooltip - (optional) Show tooltip with text
 * @param {JSX.Element} info - (optional) Show info with html
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[]} children
 *  - (optional) React inner JSX component
 * @returns JSX | null
 */
export const Description = React.memo(({ children, mode = 'default', align, tooltip, info, classMixin }: Props) => {
  if (!children) {
    return null;
  }

  return (
    <div className={b({ mode, align }).is({ tooltip: !!tooltip }).mix(classMixin)}>
      {!!tooltip && <Tooltip text={tooltip} />}
      {!!info && <Info text={info} />}
      {children}
    </div>
  );
});

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  hasSelectButton?: boolean;
};

type RequestData = {
  tenderId?: string;
  sourceId?: string;
};

export function GetTenderKindConsumptionList(props: Props & RequestData): T.Specification<State, RequestData> {
  return {
    apiID: 'GetTenderKindConsumptionList',
    header: {
      firstLevel: {
        title: 'Виды расходов в смете заявки для этого конкурса',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: !!props?.hasSelectButton,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ tenderId: props.tenderId }),
    requestData: { tenderId: props.tenderId, sourceId: props.sourceId },
  };
}

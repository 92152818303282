import React from 'react';

import { RefList, FormComponent, TextArea, TextAreaMode } from 'components';

import { ValueOf } from 'types/helpers';
import { Form } from 'types/models';
import { ContestRequest } from 'types/models/ContestRequest';
import { useAppDataContext } from 'features/AppData/context';
import { getHead } from 'features/Form/looks/contestRequest/ContestRequestForm/helpers';
import { Project } from 'types/models/Project';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  contestRequest: ContestRequest | undefined;
  project: (object & Project) | undefined;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof ContestRequest): (value: ValueOf<ContestRequest>) => void;
  disabled: boolean;
};

export function ProjectInfo(props: Props) {
  const { contestRequest, project, formFields, makeChangeHandler, disabled } = props;

  const manager = getHead(contestRequest);

  const responsiblePerformer =
    contestRequest?.requestStagePerformers?.find((x: any) => x.role?.value === 'RESPONSIBLE_PERFORMER') ?? null;
  if (!contestRequest) {
    return null;
  }

  const { settings } = useAppDataContext();

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <FormComponent.Field label="Номер проекта">
                <span>{project?.number}</span>
              </FormComponent.Field>
            </FormComponent.Column>
            <FormComponent.Column>
              <FormComponent.Field label="Зарегистрирован">
                <span>{project?.stateRegistrationDate}</span>
              </FormComponent.Field>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Программа">
            <span>{project?.program?.name}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Мероприятие">
            <span>
              {contestRequest?.project?.programEventCode} {contestRequest?.project?.programEventName}
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Заказчики">
            <span>{(contestRequest?.project?.customers || []).map(x => x?.ref.label ?? '').join('; ')}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Проект выполняется на базе">
            <span>
              {(contestRequest?.project?.departments || [])
                .map(x => [x.governance?.label, x.unit?.label].filter(Boolean).join(' / '))
                .filter(Boolean)
                .join('; ')}
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Руководитель">
            <span>{manager?.person?.fullName ?? ''}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <span>{getPersonContacts(manager?.person?.contacts || [])}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ответственный исполнитель">
            <span>{responsiblePerformer?.person?.fullName ?? ''}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <span>{getPersonContacts(responsiblePerformer?.person?.contacts || [])}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тема проекта">
            <span>{project?.name}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Цели проекта в целом">
            <span>{contestRequest?.project?.goal}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.requestStagePreviousYearAnnotation.title}
            tooltip={formFields.requestStagePreviousYearAnnotation.tooltipText}
          >
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.requestStagePreviousYearAnnotation.title }}
              value={formFields.requestStagePreviousYearAnnotation.value}
              onChange={formFields.requestStagePreviousYearAnnotation.onChange}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.requestStageKeyWords.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.requestStageKeyWords.title }}
              value={formFields.requestStageKeyWords.value}
              onChange={formFields.requestStageKeyWords.onChange}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="ГРНТИ">
            <span>{contestRequest?.project?.grntis.map(x => `${x.element?.label}`).join('; ')}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="УДК">
            <span>{contestRequest?.project?.udks.map(x => `${x.element?.label}`).join('; ')}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Критические технологии' }}
            rows={contestRequest?.requestStageCriticalTechnologies ?? []}
            onChange={makeChangeHandler('requestStageCriticalTechnologies')}
            modalTitle='Справочник "Критические технологии"'
            refName="RefPriorityTechnology"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: `Приоритетные направления Программы развития ${settings?.organization?.shortName}` }}
            rows={contestRequest?.requestStagePnrs ?? []}
            onChange={makeChangeHandler('requestStagePnrs')}
            modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
            refName="RefPnr"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Приоритетные направления модернизации и технического развития экономики' }}
            rows={contestRequest?.requestStagePnmitrs ?? []}
            onChange={makeChangeHandler('requestStagePnmitrs')}
            modalTitle='Справочник "ПНР модернизации РФ"'
            refName="RefPnmitr"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Приоритетные направления развития науки, технологий и техники' }}
            rows={contestRequest?.requestStagePnis ?? []}
            onChange={makeChangeHandler('requestStagePnis')}
            modalTitle='Справочник "Приоритетные направления науки, технологий и техники"'
            refName="RefPni"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Приоритеты Стратегии НТР РФ' }}
            rows={contestRequest?.requestStageNtrStrategies ?? []}
            onChange={makeChangeHandler('requestStageNtrStrategies')}
            modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
            refName="RefNtrStrategy"
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefList
            header={{ title: 'Классификатор ОЭСР (ОЕСD)' }}
            rows={contestRequest?.requestStageOecds ?? []}
            onChange={makeChangeHandler('requestStageOecds')}
            modalTitle="Классификатор ОЭСР (ОЕСD)"
            refName="RefOECD"
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

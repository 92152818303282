import { FormComponent, ListEdit, TextArea, TextAreaMode } from 'components';

import { Event } from 'types/models';
import { showNotification } from 'features/Notifications';
import { FinOrganizationsInputs } from './FinOrganizationsInputs';
import { useController } from './controller';
import { ValueOf } from 'types/helpers';

type Props = {
  eventData?: Event.Event | null;
  setEventDataField: (field: keyof Event.Event) => (value: ValueOf<Event.Event>) => void;
  disabled?: boolean;
  departments: Event.Department[];
  departmentOptions: Event.Department[];
  handleAttractedDepartmentsChange: (value: Event.Department[]) => void;
};

export function FinOrganizations(props: Props) {
  const { eventData, setEventDataField, disabled, departments, departmentOptions, handleAttractedDepartmentsChange } = props;

  const { handleDepartmentsChange } = useController({ handleAttractedDepartmentsChange });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Привлечённые средства">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Привлечённые средства' }}
            value={eventData?.attractedFinancing || ''}
            onChange={setEventDataField('attractedFinancing')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Собственные средства подразделений"
          tooltip="Выбрать из списка организаторов подразделение, оказывающее финансовую поддержку."
        >
          <ListEdit
            rows={departments.filter(d => d.isFinancing)}
            onChange={value => handleDepartmentsChange(value)}
            toolbar={['add', 'delete']}
            columns={[{ label: 'Наименование', formatValue: d => d.element?.label || '' }]}
            withMessages
            isDeleteConfirmEnabled
            isDisabled={disabled}
            specification={{
              mode: 'customModalComponent',
              modalTitle: 'Список подразделений университета - организаторы мероприятия',
              isCheckSubmitIcon: true,
              validation: {
                checkIsValid: row => !!row,
                onInvalidate: () => showNotification({ message: 'Выберите подразделение', theme: 'danger' }),
              },
              renderComponent: (row, onChange, selectedRowIndex, mode, submitCustomComponent) => (
                <FinOrganizationsInputs
                  departmentOptions={departmentOptions}
                  onDepartmentChange={onChange}
                  submitCustomComponent={submitCustomComponent}
                />
              ),
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

import React, { useState } from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

enum FilterOptions {
  all = 'all',
  actualTender = 'actualTender',
}

export const Filters = React.memo(() => {
  const [isYear, setIsYear] = useState<boolean>(true);
  const [filterValue, setFilterValue] = useState<FilterOptions>(FilterOptions.all);

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: FilterOptions.all },
        { label: 'Актуальные конкурсы', value: FilterOptions.actualTender },
      ],
      value: filterValue,
      onChange: (e: FilterOptions) => {
        setFilterValue(e);
        if (e === FilterOptions.actualTender) {
          setIsYear(false);
        }
      },
    },
    {
      type: FilterComponentType.tooltip,
      text: 'Список заявок в конкурсах с датой подведения итогов в указанном году',
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: isYear,
      onChange: setIsYear,
      isDisabled: filterValue === FilterOptions.actualTender,
    },
  ]);
});

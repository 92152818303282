import { Specification } from 'types/models/Table';

type Props = {
  estimatePositionId: string;
  isFiltersHidden?: boolean;
};

export function GetEstimateOverheadHistoryList({ estimatePositionId, isFiltersHidden }: Props): Specification {
  const requestData = { estimatePositionId };
  return {
    apiID: 'GetEstimateOverheadHistoryList',
    isFiltersHidden,
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}

import React from 'react';

import { FormComponent, ListEdit, ReferenceItem } from 'components';

import { Table as T, ScientificIndex as S } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { getFieldsFromScientificIndex } from 'features/Form/looks/citationSystem/CitationSystemIndexForm/helpers';
import { ChildField, FieldValue } from 'types/models/CitationSystemIndex';
import { Fields } from 'features/Form/looks/citationSystem/CitationSystemIndexForm/Fields';
import { isPostValidateResult, validate } from 'features/Form/looks/citationSystem/CitationSystemIndexForm/validate';

type Props = {
  childFields: ChildField[];
  enumMap: T.EnumMap;
  citationSystems: CitationSystem[];
  citationSystem: ReferenceItem | null;
  selectedScientificIndex: S.ScientificIndex | null;
  setRows(childFields: ChildField[]): void;
};

function Childs(props: Props) {
  const { childFields, enumMap, citationSystem, citationSystems, setRows, selectedScientificIndex } = props;

  const fields = selectedScientificIndex !== null ? getFieldsFromScientificIndex(selectedScientificIndex) : null;

  if (!fields || !fields.childFields.length) {
    return null;
  }

  const { childFields: mockFields } = fields;

  const mockChildField = mockFields[0];

  return (
    <FormComponent.Field>
      <ListEdit
        header={{ title: mockChildField.label }}
        rows={childFields}
        onChange={setRows}
        toolbar={['add', 'edit', 'delete']}
        columns={Object.values(mockChildField.fields).map(x => ({
          label: x.name,
          formatValue: (row: ChildField) => {
            const field = row.fields[x.valueId];
            if (field.type === 'REFERENCE') {
              return (field.value as ReferenceItem).label;
            }
            return field.value as string;
          },
        }))}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: childField => validate(childField?.fields ?? {}) && isPostValidateResult(childField?.fields ?? {}),
          },
          renderComponent: (childField, setChildField) => (
            <Fields
              enumMap={enumMap}
              citationSystem={citationSystem}
              citationSystems={citationSystems}
              fields={(childField || mockChildField).fields}
              onChangeField={(fieldId: string, value: FieldValue) => {
                setChildField(prevChildField => {
                  const result = prevChildField || mockChildField;
                  return {
                    ...result,
                    fields: {
                      ...result.fields,
                      [fieldId]: { ...result.fields[fieldId], value },
                    },
                  };
                });
              }}
            />
          ),
        }}
      />
    </FormComponent.Field>
  );
}

export { Childs };

import { KindConsumption } from 'types/models/KindConsumption';

type Props = {
  kindConsumption?: KindConsumption | null;
};
export function validate(props: Props) {
  const { kindConsumption } = props;

  const validations: { isValid: boolean; invalidMessage: string }[] = [];

  if (!kindConsumption?.name) {
    validations.push({ isValid: false, invalidMessage: 'Заполните поле "Вид расхода"' });
  }

  if (kindConsumption?.isCalculateTax && !kindConsumption?.percentTax) {
    validations.push({ isValid: false, invalidMessage: 'Заполните поле "Процент налога"' });
  }

  return [...validations];
}

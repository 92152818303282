import { useMemo, useState, useCallback } from 'react';

import { ButtonProps } from 'components';

import { Props } from './';

const useController = ({ isExportButtonDisabled, exportTableData, isWithQna, isWithExport }: Props) => {
  const [isAddQuestionFormOpen, setIsAddQuestioFormOpen] = useState(false);

  const handleAddQuestionButtonClick = useCallback(() => {
    setIsAddQuestioFormOpen(true);
  }, []);

  const handleTemplateCloseAddQuestionForm = useCallback(() => {
    setIsAddQuestioFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'excel' },
        title: 'Выгрузить в файл',
        onClick: exportTableData,
        isHidden: !isWithExport,
        isDisabled: isExportButtonDisabled,
      },
      {
        icon: { type: 'admin' },
        title: 'Задать вопрос администратору',
        onClick: handleAddQuestionButtonClick,
        isHidden: !isWithQna,
      },
    ],
    [exportTableData, isWithExport, isExportButtonDisabled, handleAddQuestionButtonClick, isWithQna],
  );

  return {
    buttons,
    handleTemplateCloseAddQuestionForm,
    isAddQuestionFormOpen,
  };
};

export default useController;

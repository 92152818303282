import { Tender } from 'types/models';
import { NirTenderType, TenderRequestType } from 'utils/Enums';
import { NirTenderTypeShortName } from 'utils/Enums/NirTenderType';
import { getEnumItem, getEnumItemLabel } from 'utils/Helpers';

export function getMockTender({
  initialRequestType,
  initialNirTenderTypes,
  isSpecial,
}: {
  initialRequestType?: TenderRequestType;
  initialNirTenderTypes?: NirTenderType[];
  isSpecial?: boolean;
}): Tender.Tender {
  const tender: Tender.Tender = {
    id: null,
    name: '',
    type: null,
    collectiveType: null,
    customers: [],
    kindTrade: { id: '', label: '' },
    dateUniversity: '',
    contactFace: null,
    dateOpenEnvelope: '',
    timeOpenEnvelope: '',
    dateExamenation: '',
    announcementDate: '',
    isMarketplace: false,
    marketplace: null,
    // marketplaceSignatory: null,
    marketplaceAmount: '',
    marketplaceNote: '',
    dateSummingUp: '',
    lots: initialNirTenderTypes
      ? initialNirTenderTypes.map((x, i) => ({
          ...getMockLot(),
          number: String(i + 1),
          code: NirTenderTypeShortName[x],
          theme: getEnumItemLabel('NirTenderType', x),
          nirTenderType: getEnumItem('NirTenderType', x),
        }))
      : [],
    url: '',
    categories: [],
    participants: [],
    scienceDomainInterrests: [],
    documents: [],
    requestType: initialRequestType ? getEnumItem('TenderRequestType', initialRequestType) : null,
    contacts: [],
    isSpecial: !!isSpecial,
    isPrivateCabinetActual: false,
    program: null,
    programEvent: null,
    nirRequests: [],
    mobileRequests: [],
    programRequests: [],
  };
  return tender;
}

export function getMockLot(): Tender.Lot {
  const lot: Tender.Lot = {
    id: '',
    number: '',
    code: '',
    periodNote: '',
    theme: '',
    startDate: '',
    endDate: '',
    limitAmount: 0,
    contractCount: 0,
    contractAmount: 0,
    isSupportRequest: false,
    requestSupportAmount: 0,
    isSupportContract: false,
    contractSupportAmount: 0,
    isSupportWarranty: false,
    warrantySupportAmount: 0,
    prices: [],
    nirTenderType: null,
  };
  return lot;
}

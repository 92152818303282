import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { GetScopusWosPublicationProjectList } from 'types/models/Table/customPanelsForFirstLevelHeader';

export function GetProjectScopusWosPublicationList(
  projectId: string,
  { approvedPublications, acceptedPublications, year }: GetScopusWosPublicationProjectList,
): Table.Specification {
  return {
    apiID: 'GetProjectScopusWosPublicationList',
    requestData: { projectId, approvedPublications, acceptedPublications, year },
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    isWithQna: true,
  };
}

import React from 'react';
import { block } from 'bem-cn';

import './style.scss';

const b = block('form-component-column');

type Props = {
  /** @property {boolean} hasNoWrap - (optional) Content is no wrapped */
  hasNoWrap?: boolean;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[]} children
   *  - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[];
};

/**
 * Columns for content (@media dependent)
 *
 * @param {boolean} hasNoWrap - (optional) Content is no wrapped
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[]} children
 *  - (optional) React inner JSX component
 * @returns JSX
 */
export const Column = React.memo(({ children, style, hasNoWrap }: Props) => {
  return (
    <div className={b({ 'special-styled': !!style, 'wrap-styled': !hasNoWrap })} style={style}>
      <div className={b('inner')}>{children}</div>
    </div>
  );
});

import { RelationTableModal } from 'components';
import { GetProjectCodeChangesInProject } from 'features/Table/specifications';

type Args = {
  isOpen: boolean;
  onClose(): void;
  projectId?: string | null;
  projectNumber?: string | null;
  isProcessButtonHidden?: boolean;
};

export function ProjectCodeChangesInProject({ isOpen, onClose, projectId, projectNumber, isProcessButtonHidden }: Args) {
  return (
    <RelationTableModal
      isOpen={isOpen}
      onClose={onClose}
      modalTitle={`Журнал изменений ключевых значений в проекте${projectNumber ? ` № ${projectNumber}` : ''}`}
      specification={GetProjectCodeChangesInProject({ projectId, isProcessButtonHidden })}
      helpText={
        // eslint-disable-next-line max-len
        'В журнале автоматически фиксируются изменения ключевых значений проекта: сумма в календарном плане, источник финансирования, подразделение, сроки проекта, так как они являются важными параметрами для Шифра проекта. Отметка "Обработано" обозначает что сотрудник сопровождающий шифр знает об изменениях'
      }
    />
  );
}

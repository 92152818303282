export function LotHelp(): JSX.Element {
  return (
    <>
      <p>
        Заполняется для конкурсов из категории: проведение научных мероприятий, издание научных трудов, на выполнение НИОКР,
        проекты в области предпринимательства и инноваций, стипендии и премии. Конкурс может иметь несколько лотов.
      </p>

      <h4>Описание лота содержит финансовые условия выполнения работ/услуг, в том числе это могут быть требования:</h4>

      <ul>
        <li>по календарному плану финансирования (сумма контракта, разделённая по годам)</li>
        <li>сумма обеспечения участия заявки в конкурсе</li>
        <li>сумма обеспечения контракта</li>
        <li>сумма обеспечения гарантийных обязательств</li>
        <li>цена контракта</li>
        <li>количество контрактов, предусмотренных в лоте</li>
        <li>условия по срокам выполнения работ</li>
      </ul>

      <h4>Для внутреннего конкурса автоматически генерируется запись о лоте по правилу:</h4>

      <ul>
        <li>номер лота — порядковый номер</li>
        <li>шифр лота — краткое название типа конкурса</li>
        <li>тема лота — полное название типа конкурса</li>
      </ul>
    </>
  );
}

import React from 'react';
import { block } from 'bem-cn';

import { Button, Collapse, FormComponent, SectionTitle } from 'components';

import { Estimate } from 'types/models';
import { formatNumber } from 'utils/Helpers';

import './style.scss';

type Props = {
  estimate: Estimate | null;
  receivingDocumentsCallback(): void;
  estimateOverheadCallback(): void;
};

const b = block('estimate-summary');

export function EstimateSummary({ estimate, receivingDocumentsCallback, estimateOverheadCallback }: Props) {
  const { calculation } = estimate || {};

  return (
    <div className={b()}>
      <Collapse title="Суммарная информация по смете">
        <FormComponent.Wrapper>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <SectionTitle title="Финансирование" style={{ textAlign: 'center' }} />
              <FormComponent.Line>
                <FormComponent.Field label="Источник:">
                  <span>{estimate?.projectCode?.financing?.source?.label || '-'}</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Лимит средств на год:">
                  <span>
                    <strong>{formatNumber(calculation?.income.plan || 0)} руб.</strong>, в т.ч. НДС{' '}
                    {formatNumber(calculation?.nds.plan || 0)} руб.
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Поступили на счёт:">
                  <FormComponent.Text>
                    <strong>{formatNumber(calculation?.income.fact || 0)} руб.</strong>, в т.ч. НДС{' '}
                    <span>{formatNumber(calculation?.nds.fact || 0)} руб.</span>
                    <Button
                      icon={{ type: 'document', mode: 'question' }}
                      title="Список документов"
                      onClick={receivingDocumentsCallback}
                    />
                  </FormComponent.Text>
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column>
              <SectionTitle title="Объём затрат" style={{ textAlign: 'center' }} />
              <FormComponent.Line>
                <FormComponent.Field label="Сумма, план:">{formatNumber(calculation?.expense.plan || 0)}</FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Сумма, факт:">{formatNumber(calculation?.expense.fact || 0)}</FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="План - Факт:">
                  {formatNumber((calculation?.expense.plan || 0) - (calculation?.expense.fact || 0))}
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column>
              <SectionTitle title="Накладные расходы (НР)" style={{ textAlign: 'center' }} />
              <FormComponent.Line>
                <FormComponent.Field
                  label="Шифр НР"
                  // eslint-disable-next-line max-len
                  tooltip='Для шифра можно установить карточку на вкладке "О шифре". Для конкретной сметы карточку можно указать на форме добавления накладных расходов (Сумма, план). При поступлении средств на шифр автоматически будет рассчитана Сумма, факт = Сумма документа - прихода * Расчётный процент накладных расходов.'
                >
                  <span>
                    {(estimate?.positions || []).find(i => i.fundCard)?.fundCard?.name ||
                      estimate?.projectCode?.fundCard?.name || <em>Не указан</em>}
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Нормативный процент:">
                  <span>{formatNumber(calculation?.overhead.normativePercent || 0)} %</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Расчётный процент:">
                  <span>{formatNumber(calculation?.overhead.correctedPercent || 0)} %</span>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Сумма, план:">
                  <span className={b('overhead')}>
                    {estimate?.correctedOverheadPercent || calculation?.overhead.correctedAmount ? (
                      <span>
                        <strong>{formatNumber(calculation?.overhead.correctedAmount || 0)} руб. </strong> (
                        {formatNumber(calculation?.overhead.correctedPercent || 0)} %)
                      </span>
                    ) : (
                      <span className="critical">не заданы</span>
                    )}
                  </span>
                  <Button
                    icon={{ type: 'document', mode: 'question' }}
                    title="История изменения накладных"
                    onClick={estimateOverheadCallback}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Сумма, факт:">
                  <span>
                    <strong>{formatNumber(calculation?.overhead.factAmount || 0)} руб. </strong> (
                    {formatNumber(calculation?.overhead.correctedPercent || 0)} %)
                  </span>
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Wrapper>
      </Collapse>
    </div>
  );
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';
import { NotificationEventModule } from 'utils/Enums';

type ModuleType = NotificationEventModule | 'HELP_ADMIN' | 'HELP_LK';

export function NotificationLogGridHelp(
  module: ModuleType,
): {
  header: string;
  body: JSX.Element;
} {
  const header = {
    HELP_ADMIN: `Журнал уведомлений в Администрировании`,
    HELP_LK: `Мои уведомления в ЛК`,
  } as Record<ModuleType, string>;

  return {
    header: `Помощь для вкладки ${header[module] || 'Журнал уведомлений '}`,
    body: (
      <Tabs>
        <Tab title="Настройка уведомлений">
          {module === 'HELP_ADMIN' && (
            <p>
              Таблица содержит список всех отправленных уведомлений, связанных с ключевыми событиями требующих внимания. В списке
              по умолчанию выводятся все уведомления, которые были сформированы по событию в системе. Для просмотра уведомлений
              одного типа необходимо воспользоваться фильтром в таблице в столбцах <strong>Модуль</strong> и/или{' '}
              <strong>Событие</strong>. С помощью фильтра <strong>Год</strong> можно отсортировать уведомления по указанному году.
            </p>
          )}
          {module === 'HELP_LK' && (
            <p>
              Таблица <strong>Мои уведомления</strong> содержит список отправленных Вам уведомлений, связанных с ключевыми
              событиями требующих внимания. Все уведомления добавляются в таблицу автоматически. Для получения уведомлений на
              электронную почту необходимо перейти на вкладку <strong>Настройка уведомлений</strong> в пункте меню{' '}
              <strong>Мой профиль</strong> (или по кнопке <strong>Настройка уведомлений</strong> на панели управления в таблице)
              указать действующую электронную почту, выбрать разделы для получения уведомлений и сохранить изменения.
            </p>
          )}
          {module === 'PURCHASE_REQUEST' && (
            <p>
              Таблица содержит список отправленных уведомлений, связанных с ключевыми событиями в разделе{' '}
              <strong>Заявки на закупки</strong>
            </p>
          )}
          {module === 'NIR_REQUEST' && (
            <p>
              Таблица содержит список отправленных уведомлений, связанных с ключевыми событиями в разделе{' '}
              <strong>Заявки на внутренний конкурс</strong>
            </p>
          )}
          {module === 'PROGRAM_REQUEST' && (
            <p>
              Таблица содержит список отправленных уведомлений, связанных с ключевыми событиями в разделе{' '}
              <strong>Заявки на конкурс программы</strong>
            </p>
          )}
          {module === 'MOBILE_REQUEST' && (
            <p>
              Таблица содержит список отправленных уведомлений, связанных с ключевыми событиями в разделе{' '}
              <strong>Заявки на мобильность</strong>
            </p>
          )}
          {module === 'PROJECT' && (
            <p>
              Таблица содержит список отправленных уведомлений, связанных с ключевыми событиями в разделе <strong>Проекты</strong>
            </p>
          )}

          {module === 'HELP_ADMIN' && (
            <>
              <h4>Особенности вывода информации в столбцах таблицы:</h4>

              <table>
                <thead>
                  <tr>
                    <th style={{ width: '25%' }}>Название столбца</th>
                    <th>Описание</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Дата</td>
                    <td>Дата формирования и отправки уведомления</td>
                  </tr>
                  <tr>
                    <td>Модуль</td>
                    <td>Группировка уведомлений внутри системы уведомлений по признаку (например, Проекты)</td>
                  </tr>
                  <tr>
                    <td>Событие</td>
                    <td>Действие внутри Модуля в системе уведомлений (например, Смена статуса заявки на закупку)</td>
                  </tr>
                  <tr>
                    <td>Запись в модуле</td>
                    <td>Основные сведения об источнике формирующего событие</td>
                  </tr>
                  <tr>
                    <td>Сообщение</td>
                    <td>Сообщение, с информацией о наступлении какого-либо события</td>
                  </tr>
                  <tr>
                    <td>Кому отправлено</td>
                    <td>
                      Список получателей (эл. почта) которым было отправлено уведомление. В столбце отображается не более 10
                      {/* eslint-disable-next-line max-len */}
                      адресатов. Для просмотра полного списка получателей можно с помощью кнопки на тулбаре <Icon type="group" />{' '}
                      <strong>Показать список получателей письма</strong>
                    </td>
                  </tr>
                  <tr>
                    <td>Всего адресатов</td>
                    <td>
                      Общее количество получателей, которым поступило уведомление (не зависимо от настройки уведомлений на эл.
                      почту)
                    </td>
                  </tr>
                  <tr>
                    <td>Отправлено писем</td>
                    <td>Общее количество успешно отправленных уведомлений на эл. почту получателей</td>
                  </tr>
                </tbody>
              </table>
            </>
          )}

          <h3>Панель инструментов для управления записями в таблице</h3>

          <table>
            <thead>
              <tr>
                <th style={{ width: '25%' }}>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Открыть письмо: <Icon type="view" />
                </td>
                <td>Кнопка активна, если в таблице выделена запись; открывает форму с текстом уведомления</td>
              </tr>
              {module === 'HELP_LK' ? (
                <tr>
                  <td>
                    Очистить весь список: <Icon type="erase" />
                  </td>
                  <td>
                    <p>По кнопке очищается весь список полученных уведомлений, без возможности восстановления</p>
                  </td>
                </tr>
              ) : (
                <>
                  <tr>
                    <td>
                      Показать список получателей письма: <Icon type="group" />
                    </td>
                    <td>
                      <p>
                        Кнопка активна, если в таблице выделена запись; открывает форму списка адресатов (ФИО и электронная почта,
                        на которую было отправлено письмо с уведомлением).
                      </p>
                      <p>В столбце таблицы Кому отображается не более 10 адресатов.</p>
                    </td>
                  </tr>
                  {module !== 'HELP_ADMIN' && (
                    <tr>
                      <td>
                        Наблюдатель: <Icon type="group" mode="view" />
                      </td>
                      <td>
                        <p>
                          Добавление наблюдателей за модулем. Добавленные персоны получают уведомления по всем изменениям в
                          модуле.
                        </p>
                      </td>
                    </tr>
                  )}
                </>
              )}
              <tr>
                <td>
                  Выгрузить в файл: <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="События">
          <div className="warning">
            <span>
              <p>Уведомление не поступает на электронную почту, если Вы являетесь инициатором события.</p>
            </span>
          </div>

          <p>
            Записи в таблице <strong>Журнал отправленных уведомлений</strong> отображаются после наступления следующих событий:
          </p>

          <table>
            <thead>
              <tr>
                <th style={{ width: '30%' }}>Событие</th>
                <th style={{ width: '40%' }}>Условие</th>
                <th style={{ width: '30%' }}>Получатели</th>
              </tr>
            </thead>
            <tbody>
              {(module === 'HELP_ADMIN' || module === 'HELP_LK' || module === 'PURCHASE_REQUEST') && (
                <>
                  <tr>
                    <td>Подача заявки на закупку на рассмотрение</td>
                    <td>
                      Уведомление поступает при смене статуса заявки из <strong>Черновика</strong> в статус{' '}
                      <strong>На рассмотрение</strong>
                    </td>
                    <td>Руководитель проекта, ответственное лицо по заявке, МОЛ, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Смена статуса заявки на закупку</td>
                    <td>При любой смене статуса заявки на закупку</td>
                    <td>Руководитель проекта, ответственное лицо по заявке, МОЛ, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Сообщение/Виза эксперта в заявке на закупку</td>
                    <td>
                      {/* eslint-disable-next-line max-len */}
                      Уведомление поступает после добавления сообщения по закупке (на вкладке <strong>Журнал сообщений</strong> в
                      форме заявки) и добавление визы: комитет, комиссия, логистика
                    </td>
                    <td>Руководитель проекта, ответственное лицо по заявке, МОЛ, Наблюдатель</td>
                  </tr>
                </>
              )}

              {(module === 'HELP_ADMIN' || module === 'HELP_LK' || module === 'NIR_REQUEST') && (
                <>
                  <tr>
                    <td>Изменился статус заявки на внутренний конкурс</td>
                    <td>При любой смене статуса заявки на внутренний конкурс</td>
                    <td>Руководитель заявки, Ответственный исполнитель заявки, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Уведомление при добавлении в коллектив заявки на внутренний конкурс</td>
                    <td>Уведомление при добавлении в коллектив заявки на внутренний конкурс</td>
                    <td>Член коллектива, которого добавили в заявку на внутренний конкурс</td>
                  </tr>
                  <tr>
                    <td>Получено сообщение по заявке на внутренний конкурс</td>
                    <td>Уведомление при добавлении сообщения по заявке на внутренний конкурс</td>
                    <td>Руководитель заявки, Ответственный исполнитель заявки, Наблюдатель</td>
                  </tr>
                </>
              )}

              {(module === 'HELP_ADMIN' || module === 'HELP_LK' || module === 'PROGRAM_REQUEST') && (
                <>
                  <tr>
                    <td>Изменился статус заявки на конкурс</td>
                    <td>При любой смене статуса заявки на конкурс</td>
                    <td>Руководитель заявки, Ответственный исполнитель заявки, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Уведомление при добавлении в коллектив заявки на конкурс</td>
                    <td>При добавлении в коллектив заявки на конкурс</td>
                    <td>Член коллектива, которого добавили в заявку на конкурс</td>
                  </tr>
                  <tr>
                    <td>Получено сообщение по заявке на конкурс</td>
                    <td>Уведомление при добавлении сообщения по заявке на конкурс</td>
                    <td>Руководитель заявки, Ответственный исполнитель заявки, Наблюдатель</td>
                  </tr>
                </>
              )}

              {(module === 'HELP_ADMIN' || module === 'HELP_LK' || module === 'MOBILE_REQUEST') && (
                <>
                  <tr>
                    <td>Изменился статус заявки на конкурс по мобильности</td>
                    <td>При любой смене статуса заявки на конкурс</td>
                    <td>Заявитель, Наблюдатель</td>
                  </tr>
                </>
              )}

              {(module === 'HELP_ADMIN' || module === 'HELP_LK' || module === 'PROJECT') && (
                <>
                  <tr>
                    <td>Поступление поручения</td>
                    <td>При добавлении нового поручения для проекта</td>
                    <td>
                      Руководитель, Ответственный исполнитель по проекту, Ответственные лица назначенные в поручении, Наблюдатель
                    </td>
                  </tr>
                  <tr>
                    <td>Смена статуса поручения</td>
                    <td>
                      Уведомление поступает при любой смене статуса поручения:{' '}
                      <strong>В работе, Готово, Закрыто, Отменено</strong>
                    </td>
                    <td>
                      Руководитель, Ответственный исполнитель по проекту, Ответственные лица назначенные в поручении, Наблюдатель
                    </td>
                  </tr>
                  <tr>
                    <td>Сообщение по поручению</td>
                    <td>При добавлении сообщения по поручению</td>
                    <td>
                      Руководитель, Ответственный исполнитель по проекту, Ответственные лица, назначенные в поручении, Наблюдатель
                    </td>
                  </tr>
                  <tr>
                    <td>Приближение срока отчёта по этапу проекта (за 30 дней, за 3 дня и за 1 день до сдачи отчета в архив)</td>
                    <td>Приближение срока отчёта по этапу проекта</td>
                    <td>Руководитель, Ответственный исполнитель по проекту, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Уведомления для члена коллектива при добавлении в коллектив проекта</td>
                    <td>Уведомления для члена коллектива при добавлении в коллектив проекта</td>
                    <td>Член коллектива, которого добавили в коллектив проекта</td>
                  </tr>
                  <tr>
                    <td>Уведомления при добавлении новых членов в коллектив проекта (руководителю)</td>
                    <td>
                      При добавлении членов коллектива в проект, формируется уведомление со списком всех добавленных членов за
                      предыдущий день. Уведомление поступает на почту утром на следующий день
                    </td>
                    <td>Руководитель, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Получено сообщение по проекту</td>
                    <td>При добавлении сообщения в журнал сообщения по проекту</td>
                    <td>Руководитель, Ответственный исполнитель по проекту, Наблюдатель</td>
                  </tr>
                  <tr>
                    <td>Исключение из коллектива проекта</td>
                    <td>
                      Уведомления для члена коллектива при исключении из коллектива проекта (закончился период работы в проекте)
                    </td>
                    <td>Член коллектива, которого исключили из проекта</td>
                  </tr>
                  <tr>
                    <td>Исключение из коллектива проекта (для руководителя)</td>
                    <td>
                      При исключении членов коллектива из проекта, формируется уведомление со списком всех исключенных за
                      предыдущий день. Уведомление поступает на почту утром на следующий день
                    </td>
                    <td>Руководитель, Наблюдатель</td>
                  </tr>
                </>
              )}

              {(module === 'HELP_ADMIN' || module === 'HELP_LK') && (
                <>
                  <tr>
                    <td>Получен ответ на вопрос в разделе "Вопрос-Ответ"</td>
                    <td>
                      При добавлении ответа на вопрос пользователя в пункте меню{' '}
                      <strong>Вопросы от пользователей администраторам</strong>
                    </td>
                    <td>Автор вопроса</td>
                  </tr>
                  <tr>
                    <td>Новый конкурс по интересам</td>
                    <td>
                      Уведомление о новом конкурсе поступает при совпадении <strong>Области научных интересов</strong> в профиле
                      персоны и в конкурсе или при персональной отправке уведомления{' '}
                      {module === 'HELP_ADMIN' ? 'администратором' : 'через пункт меню'}{' '}
                      {module !== 'HELP_ADMIN' && <strong>Конкурсы</strong>}
                    </td>
                    <td>
                      Персона, у которой совпали <strong>Области научных интересов</strong> с объявленным конкурсом или было
                      отправлено уведомление персонально {module === 'HELP_ADMIN' ? 'администратором' : 'через пункт меню'}{' '}
                      {module !== 'HELP_ADMIN' && <strong>Конкурсы</strong>}
                    </td>
                  </tr>
                </>
              )}
            </tbody>
          </table>
        </Tab>
        <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
      </Tabs>
    ),
  };
}

import React, { useMemo } from 'react';

import { Header, FormComponent } from 'components';

import { QuestionAndAnswersItem } from './QuestionAndAnswersItem';
import { useController } from './controller';

type Props = {
  isWithTitle?: boolean;
};

export function QuestionAndAnswersList({ isWithTitle = true }: Props) {
  const { frequentQuestions } = useController();

  const leftColumnQuestions = useMemo(() => frequentQuestions.filter((__, index) => index % 2 === 0), [frequentQuestions]);
  const rightColumnQuestions = useMemo(() => frequentQuestions.filter((__, index) => index % 2 !== 0), [frequentQuestions]);

  const list = (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <>
            {leftColumnQuestions.map(question => (
              <FormComponent.Line key={question.id}>
                <QuestionAndAnswersItem question={question} />
              </FormComponent.Line>
            ))}
          </>
        </FormComponent.Column>
        <FormComponent.Column>
          <>
            {rightColumnQuestions.map(question => (
              <FormComponent.Line key={question.id}>
                <QuestionAndAnswersItem question={question} />
              </FormComponent.Line>
            ))}
          </>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );

  if (isWithTitle) {
    return (
      <FormComponent.Template>
        <Header>Часто задаваемые вопросы</Header>
        {list}
      </FormComponent.Template>
    );
  }

  return list;
}

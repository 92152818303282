import { Mode } from 'components/ListEdit/model';

import { Performer } from 'types/models/ContestRequest';

export function validate(member: Performer | null, list: Performer[], mode: Mode, index: number | null) {
  const members = mode === 'edit' ? list.filter((_, memberIndex) => index !== memberIndex) : list;

  const isValidaMember = Boolean(member?.person?.id);
  const isValidRole = Boolean(member?.role);

  const hasDublicates = members.some(existMember => {
    const isEqualPerson = existMember?.person?.id === member?.person?.id;
    const isEqualRole = existMember?.role?.value === member?.role?.value;
    return isEqualPerson && isEqualRole;
  });
  return [
    { isValid: isValidaMember, invalidMessage: 'Выберите исполнителя' },
    { isValid: isValidRole, invalidMessage: 'Выберите роль' },
    { isValid: !hasDublicates, invalidMessage: 'Исполнитель с такой ролью уже добавлен' },
  ];
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { FileInfo, Table } from 'types/models';
import useController from './controller';
import { MessageModal } from 'features/Form/views/MessageModal';
import { submitTextMap, titleTextMap } from 'features/Table/specifications/GetPersonalProjectAssignmentList/Toolbar';
import { ProjectAssignmentStatusCode } from 'types/models/Project';
import { State } from '../makeUseCustomController';

export type ToolbarProps = {
  projectAssignmentId?: string | null;
  disabled?: boolean;
};

type Props = {
  customState: State;
  tableState: Table.State;
};

export const ToolbarTemplate = React.memo(({ customState, tableState }: Props) => {
  const {
    isOpenMessageModal,
    handleMessageModalClose,
    newStatus,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    projectAssignmentId,
    disabled,
  } = customState;

  const {
    buttons,
    onCloseEditForm,
    onCloseAddForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    handleSaveStatusClick,
  } = useController({ customState, tableState, projectAssignmentId, disabled });

  const submitsMap: Record<string, (message: ProjectAssignmentStatusCode, file: FileInfo) => void> = {
    IN_ACTION: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
    CLOSED: message => handleSaveStatusClick(message),
    CANCELED: message => handleSaveStatusClick(message),
  };

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <modalTemplates.ProjectAssignmentMessageEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={onCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      <modalTemplates.ProjectAssignmentMessageAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={onCloseAddForm}
        relatedTableState={tableState}
        projectAssignmentId={projectAssignmentId}
      />

      {tableState.selectedRows.length > 0 && (
        <>
          <MessageModal
            isOpen={isOpenMessageModal}
            onClose={handleMessageModalClose}
            onSubmit={submitsMap[newStatus.toString()]}
            titleText={titleTextMap[newStatus.toString()]}
            submitText={submitTextMap[newStatus.toString()]}
          />

          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteWarningOpen}
            onClose={onCloseDeleteWarning}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: onSubmitDeleteWarning,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: onCloseDeleteWarning,
              },
            ]}
            size="small"
          >
            <>(автор: {tableState.selectedRows[0]?.Author}) Удалить сообщение?</>
          </Modal>
        </>
      )}
    </>
  );
});

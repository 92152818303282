import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { Table } from 'types/models';
import { GetPaymentTermPresetsSpecification } from 'features/Table/specifications';
import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps, CustomSelectSpecification } from 'features/BuildReportPopup/models';

const RegulationParagraph = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification: CustomSelectSpecification<object, object> = {
    modalTableSpecification: GetPaymentTermPresetsSpecification(),
    relationTableModalTitle: 'Показатели',
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      id: row.id,
      label: row.regulationName,
    }),
  };

  return (
    <DefaultParameterList
      parameterName={parameterName}
      setParameterValueByName={setParameterValueByName}
      parameters={parameters}
      enumProps={{
        fieldLabel: 'Показатели',
        listEditTitle: 'Список показателей',
        mode: { component: 'CustomSelect', specification },
      }}
    />
  );
};

const Component = memo(RegulationParagraph);

export { Component as RegulationParagraph };

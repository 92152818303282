import { Parameter, DateValue } from 'types/models';
import { parse } from 'date-fns';
import { formatStr } from 'utils/Constants';

const queryBuilder = (dateParameter: Parameter) => {
  const value = dateParameter.value as DateValue;
  const parsedDate = parse(value, formatStr, new Date());

  if (parsedDate) {
    const parsedDateTime = parsedDate.getTime();
    return `&${dateParameter.name}=${parsedDateTime}`;
  }

  return '';
};

export default queryBuilder;

import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('popover');

/** @property {string} PositionVertical - Positions vertical align */
type PositionVertical = 'top' | 'center' | 'bottom';
/** @property {string} PositionHorizontal - Positions horizontal align */
type PositionHorizontal = 'left' | 'center' | 'right';
/** @property {object} Position - Position details */
type Position = {
  vertical: PositionVertical;
  horizontal: PositionHorizontal;
};

type Props = {
  /** @property {boolean} isOpen - (optional) Popover is opened */
  isOpen?: boolean;
  /** @property {function} onClose - Callback on close component */
  onClose(): void;
  // eslint-disable-next-line max-len
  /** @property {HTMLDivElement | HTMLButtonElement | null} anchorRef - Reference to parent component (for calc render position) */
  anchorRef: HTMLDivElement | HTMLButtonElement | SVGSVGElement | null;
  /** @property {object} position - Position relative parent component */
  position: {
    /** @property {number} offset - (optional) Offset for all sides */
    offset?: number;
    /** @property {Position} anchor - (See below) Position for parent */
    anchor: Position;
    /** @property {Position} content - (See below) (optional) Position for content */
    content?: Position;
  };
  /** @property {React.ReactElement[] | React.ReactElement | false} children - (optional) React inner JSX component */
  children: React.ReactElement[] | React.ReactElement | false;
};

/**
 * Popover component with custom content
 *
 * @param isOpen - (optional) Popover is opened
 * @param onClose - Callback on close component
 * @param anchorRef - Reference to parent component (for calc render position)
 * @param position - Position relative parent component
 * @param position.offset - (optional) Offset for all sides
 * @param position.anchor - (See below) Position for parent
 * @param position.content - (See below) (optional) Position for content
 * @param Position - Position details
 * @param Position.vertical - 'top' | 'center' | 'bottom'
 * @param Position.horizontal - 'left' | 'center' | 'right'
 * @returns JSX
 */
export const Popover = React.memo(({ isOpen = false, onClose, children, anchorRef, position: positionSource }: Props) => {
  type PositionPoint = {
    x: number;
    y: number;
  };

  const ratePosition = (type: PositionVertical | PositionHorizontal): number => {
    if (type === 'center') {
      return 0.5;
    }
    if (type === 'bottom' || type === 'right') {
      return 1;
    }
    return 0;
  };

  const windowRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [position, setPosition] = useState<PositionPoint>({ x: 0, y: 0 });

  const calcPosition = useCallback(() => {
    setTimeout(() => {
      const windowRect = windowRef.current?.getBoundingClientRect();
      const contentRect = contentRef.current?.getBoundingClientRect();
      const anchorRect = anchorRef?.getBoundingClientRect();

      if (!windowRect || !contentRect || !anchorRect) {
        return;
      }

      // Default values
      const defaultPosition = {
        content: {
          vertical: 'top' as PositionVertical,
          horizontal: 'left' as PositionHorizontal,
        },
      };

      const resultPosition = {
        ...defaultPosition,
        ...positionSource,
      };

      const anchorPoint: PositionPoint = {
        x: anchorRect.x + ratePosition(resultPosition.anchor.horizontal) * anchorRect.width,
        y: anchorRect.y + ratePosition(resultPosition.anchor.vertical) * anchorRect.height,
      };

      const contentPoint: PositionPoint = {
        x: anchorPoint.x - ratePosition(resultPosition.content.horizontal) * contentRect.width,
        y: anchorPoint.y - ratePosition(resultPosition.content.vertical) * contentRect.height,
      };

      const calcResult = (axis: 'x' | 'y') => {
        const param = axis === 'x' ? 'width' : 'height';
        const min = 0;
        const max = (windowRect[param] || 0) - (contentRect[param] || 0);
        if (min > max) {
          return min;
        }
        return Math.min(Math.max(min, contentPoint[axis]), max);
      };

      const resultPoint: PositionPoint = {
        x: calcResult('x'),
        y: calcResult('y'),
      };

      setPosition(resultPoint);
    }, 0);
  }, [anchorRef, positionSource]);

  useEffect(() => {
    window.addEventListener('resize', () => calcPosition());
    return () => {
      window.removeEventListener('resize', () => calcPosition());
    };
  }, [calcPosition]);

  useLayoutEffect(() => calcPosition(), [calcPosition]);

  return ReactDOM.createPortal(
    isOpen && (
      <div className={b()}>
        <div className={b('overlay')} ref={windowRef} onClick={onClose} />
        <div
          className={b('content')}
          ref={contentRef}
          style={{ top: `${position.y}px`, left: `${position.x}px`, padding: `${positionSource.offset || 0}px` }}
        >
          <div className={b('animate')}>{children && children}</div>
        </div>
      </div>
    ),
    document.body,
  );
});

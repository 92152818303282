import { useCallback, useMemo } from 'react';

import { ReferenceFilter, ReferenceItem } from 'components';

import { Table } from 'types/models';
import { ProgramRequest } from 'types/models/ProgramRequest';

type Props = {
  programRequest?: ProgramRequest | null;
};

export function useController({ programRequest }: Props) {
  const knowledgeBranchTableRowConverter = useCallback<(row: Table.Entry) => ReferenceItem>(
    row => ({
      id: row.id,
      label: [row.Code, row.Name].filter(Boolean).join(' ') || '-',
      code: row.Code,
    }),
    [],
  );

  const scienceDomainInterrestsFilters: ReferenceFilter[] = useMemo(() => {
    if (!programRequest?.tender?.scienceDomainInterrests || programRequest?.tender?.scienceDomainInterrests.length == 0) {
      return [
        ({
          key: 'id',
          values: ['-1'],
        } as unknown) as ReferenceFilter,
      ];
    }
    return [
      ({
        key: 'id',
        values: programRequest?.tender?.scienceDomainInterrests?.map(x => x.id),
      } as unknown) as ReferenceFilter,
    ];
  }, [programRequest]);

  return {
    knowledgeBranchTableRowConverter,
    scienceDomainInterrestsFilters,
  };
}

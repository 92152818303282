import { useState, useLayoutEffect, useCallback, useMemo } from 'react';

import { Form, Table, ProgramEvent } from 'types/models';
import { ReferenceItem } from 'components';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import * as BackendAPI from 'services/BackendAPI';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, parentRecordId },
  } = useFormContext();

  const workMode = useMemo<Table.WorkMode>(() => (editMode ? 'editMode' : viewMode ? 'viewMode' : 'addMode'), [
    editMode,
    viewMode,
  ]);

  const [formFields, setFormFields] = useState<Form.Fields>({
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название мероприятия (вид конкурса)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
    },
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Краткое название мероприятия (вид конкурса)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, value },
        }));
      },
    },
    nationalProjectExpenseCode: {
      value: '',
      isValid: true,
      title: 'Код расходов Нацпроекта',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          nationalProjectExpenseCode: { ...prevState.nationalProjectExpenseCode, value },
        }));
      },
    },
    direction: {
      value: { label: '', id: '' },
      isValid: true,
      title: 'Направление',
      onChange: (value: ReferenceItem) => {
        setFormFields((prevState: Form.Fields) => {
          // eslint-disable-next-line no-param-reassign
          value = value ?? { label: '', id: '' };
          return {
            ...prevState,
            direction: { ...prevState.direction, value },
          };
        });
      },
    },
  });

  const { methods: getProgramEvent } = BackendAPI.useBackendAPI('GetProgramEvent', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as ProgramEvent;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        name: {
          ...prevState.name,
          value: preparedData.name,
        },
        code: {
          ...prevState.code,
          value: preparedData.code,
        },
        nationalProjectExpenseCode: {
          ...prevState.nationalProjectExpenseCode,
          value: preparedData.nationalProjectExpenseCode,
        },
        direction: {
          ...prevState.direction,
          value: { id: preparedData.direction?.id || '', label: preparedData.direction?.name || '' },
        },
      }));
    },
  });

  const { methods: saveProgramEvent } = BackendAPI.useBackendAPI('SaveProgramEvent', {
    onSuccessfullCall: () => {
      onClose();
      showNotification({ message: 'Мероприятие успешно сохранено', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback(() => {
    if (formFields.name.value && formFields.code.value) {
      saveProgramEvent.callAPI({
        programEventId: id || null,
        programId: parentRecordId || '-1',
        directionId: formFields.direction.value?.id,
        name: formFields.name.value,
        code: formFields.code.value,
        nationalProjectExpenseCode: formFields.nationalProjectExpenseCode.value,
      });
    } else showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
  }, [
    formFields.name.value,
    formFields.code.value,
    formFields.direction.value?.id,
    formFields.nationalProjectExpenseCode.value,
    saveProgramEvent,
    id,
    parentRecordId,
  ]);

  const handleFormSubmit = onSubmit;
  const handleFormClose = onClose;

  useLayoutEffect(() => {
    if (id) {
      getProgramEvent.callAPI({ id });
    }
    // eslint-disable-next-line
  }, []);

  return {
    formFields,
    handleFormClose,
    handleFormSubmit,
    programEventId: id ?? null,
    workMode,
    programId: parentRecordId || '-1',
  };
}

import { useState, useLayoutEffect, useCallback } from 'react';
import { Form, Payment } from 'types/models';
import * as BackendAPI from 'services/BackendAPI';
import { ReferenceEnumaration } from 'components';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

import { useFormContext } from 'features/Form/hooks';
import { useAppDataContext } from 'features/AppData/context';

export function useController() {
  const [formFields, setFormFields] = useState<Form.Fields>({
    type: {
      value: { label: '', value: '' },
      isValid: true,
      required: true,
      title: 'Тип заявки (основание)',
      onChange: (value: ReferenceEnumaration) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          type: { ...prevState.type, isValid: true },
        }));
      },
    },
    regulationParagraph: {
      value: '',
      isValid: true,
      required: true,
      title: 'Пункт положения (показатель)',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationParagraph: { ...prevState.regulationParagraph, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationParagraph: { ...prevState.regulationParagraph, isValid: true },
        }));
      },
    },
    regulationName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationName: { ...prevState.regulationName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationName: { ...prevState.regulationName, isValid: true },
        }));
      },
    },
  });

  const tableStreams = useLocalTableStreams();
  const { enumMap } = useAppDataContext();
  const {
    look: { id, relatedTableState },
  } = useFormContext();

  const { methods: getPaymentTermPreset } = BackendAPI.useBackendAPI('GetPaymentTermPreset', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as Payment.TermPreset;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        type: {
          ...prevState.type,
          value: {
            label: preparedData.type?.label,
            value: preparedData.type?.value,
          },
        },
        regulationParagraph: {
          ...prevState.regulationParagraph,
          value: preparedData.regulationParagraph,
        },
        regulationName: {
          ...prevState.regulationName,
          value: preparedData.regulationName,
        },
      }));
    },
  });

  const { methods: savePaymentTermPreset } = BackendAPI.useBackendAPI('SavePaymentTermPreset', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Настройка успешно сохранена', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback((): boolean => {
    if (formFields.type.value?.value && formFields.regulationParagraph.value && formFields.regulationName.value) {
      savePaymentTermPreset.callAPI({
        id,
        type: formFields.type.value?.value,
        regulationParagraph: formFields.regulationParagraph.value,
        regulationName: formFields.regulationName.value,
      });
      return true;
    }
    showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
    return false;
  }, [formFields.regulationName, formFields.regulationParagraph, formFields.type.value, id, savePaymentTermPreset]);

  useLayoutEffect(() => {
    if (id) getPaymentTermPreset.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    paymentTermPresetId: id ?? null,
    relatedTableState,
    formFields,
    onSubmit,
    enumMap,
  };
}

export function MipPartnerHelp(): JSX.Element {
  return (
    <>
      <ol>
        <li>
          Организация – МИП закрепляется за ответственным менеджером и ответственным подразделением.{' '}
          <strong>Ответственный менеджер</strong> отвечает за полноту и актуальность информации об организации, в том числе, за
          своевременный ввод данных по показателям за отчётный год.
        </li>
        <li>
          <strong>Краткое название:</strong> для иностранных организаций вводите в формате: Сокращённое название организации на
          оригинальном языке (Сокращённое название на русском языке).
        </li>
        <li>
          <strong>Полное название:</strong> для международных организаций вводите в формате: Полное название организации на
          оригинальном языке (Полное название на русском языке).
        </li>
        <li>
          <strong>Сектор деятельности:</strong> Выбор значения из справочника. В справочнике в столбце Примечание даны
          рекомендации пор выбору сектора деятельности организации.
        </li>
        <li>
          <strong>Коды организации:</strong> минимально-необходимое для российских кампаний и организаций – ИНН и КПП.
        </li>
        <li>
          <strong>Адрес и контакты:</strong> минимально-необходимые — страна, город, сайт.
        </li>
        <li>
          <strong>Идентификаторы организации в базах цитирования публикаций:</strong> в публикациях авторы указывают
          аффилированные организации, рекомендуется внести идентификаторы организации в РИНЦ, Scopus, Web of Science.
        </li>
        <li>
          <strong>Типы партнёрства:</strong> вариант сотрудничества = <strong>МИП</strong>– на вкладке{' '}
          <strong>Документы МИП.</strong>
        </li>
        <li>
          <strong>Дата ликвидации:</strong> поле появится на форме при условии: если <strong>Состояние = Ликвидировано</strong>
        </li>
      </ol>
      <div className="warning">
        <span>
          Организация МИП отображается в общем списке организаций-партнёров в пункте меню{' '}
          <strong>Партнёры, Список организаций.</strong> НО в этом пункте меню форма МИП доступна для пользователей только для
          просмотра и на форме скрыты вкладки <strong>Документы МИП.</strong>
        </span>
      </div>

      <h3>Информация на вкладках Документы МИП содержит сведения о:</h3>

      <ol>
        <li>
          {/* eslint-disable-next-line max-len */}
          Вкладка <strong>Уставной капитал:</strong> данные добавлять и редактировать в таблицу <strong>Уставной капитал.</strong>{' '}
          В таблице <strong>Учредители</strong> отображается информация о каждом учредителе для выбранной строки в уставном
          капитале.
        </li>
        <li>
          Вкладка <strong>Договоры на использование ИС:</strong> список договоров на использование интеллектуального права на
          объекты интеллектуальной собственности университета. Источник информации — функциональный модуль
          <strong>Коммерциализация.</strong>
        </li>
        <li>
          Вкладка <strong>Показатели:</strong> содержит основные показатели результативности МИП за год.
        </li>
        <li>
          Вкладка <strong>Лицензионные платежи:</strong> источник данных – сведения из документов бухгалтерского учёта, в случае,
          если с договором связан научный проект (модуль <strong>Проекты</strong>).
        </li>
        <li>
          Вкладка <strong>Документы МИП</strong> предназначена для хранения специализированных документов МИП (уставные документы,
          приказ, договор и пр.).
        </li>
        <li>
          Вкладка Журнал изменений предназначен для фиксирования ключевых атрибутов организации МИП в период его жизненного цикла
          (изменение названия или юридического адреса, выход учредителя, коды ОКВЭД и др. ). Список категорий изменений можно
          пополнять по мере необходимости и в соответствии с политикой учёта МИП в университете.
        </li>
      </ol>

      <h3>Сведения о результатах сотрудничества университета и организации представлены в виде списков на вкладках:</h3>

      <ol>
        <li>
          Вкладка <strong>НИОКР:</strong> список научных проектов, в которых организация является заказчиком или соисполнителем,
          или технологическим партнёром. Источник информации — разделах <strong>Заказчики и Партнёры</strong> на форме
          редактирования научного проекта (модуль <strong>Проекты</strong>
          ).
        </li>
        <li>
          Вкладка <strong>Публикации:</strong> список публикаций, в которых авторы аффилированы с организацией Источник информации
          — раздел <strong>Сведения об авторах</strong> на форме публикации (модуль <strong>Публикации</strong>
          ).
        </li>
        <li>
          Вкладка <strong>Мероприятия:</strong> список организованных совместно с университетом мероприятий и мероприятий
          {/* eslint-disable-next-line max-len */}
          организации, в которых приняли участие сотрудники, аспиранты, студенты университета. Источник информации — раздел{' '}
          <strong>Организаторы мероприятия</strong> на форме редактирования мероприятия (конференции, конкурса, олимпиады),
          функционального модуля <strong>Мероприятия.</strong>
        </li>
        <li>
          Вкладка <strong>Участие в выставках:</strong> список участий университета в выставках с экспонатами, разработанными
          совместно с организацией. Источник информации — участия в выставке, где указан формат участия{' '}
          <strong>«Презентация разработки»</strong>, в разделе <strong>Организации-партнёры</strong> на форме{' '}
          <strong>Разработка</strong> (функциональный модуль <strong>Мероприятия, Участия в выставке</strong>).
        </li>
        <li>
          Вкладка <strong>Стажировки:</strong> список сотрудников, аспирантов и студентов университета, прошедших стажировку в
          организации. Источник данных — <strong>Принимающая сторона</strong> на форме{' '}
          <strong>Заявки по академической мобильности.</strong>
        </li>
      </ol>
    </>
  );
}

import React from 'react';

import { ListEdit } from 'components';
import { Column, ExtraToolbarButton } from 'components/ListEdit/model';

import { Performer } from 'types/models/ProgramRequest';
import { BuildReportPopup, Reports } from 'features/BuildReportPopup';
import { PersonHistoryModal } from 'features/SelectPerson';
import { PortfolioCard } from 'features/Form/looks/person';
import { showErrorsMessages } from 'utils/Common';
import { PerformerInputs } from './PerformerInputs';
import { useController } from './controller';
import { validate } from './validete';

type Props = {
  rows: Performer[];
  onChange(items: Performer[]): void;
  disabled: boolean;
  isShowPersonalReport?: boolean;
  isShowPersonalInfo?: boolean;
  isShowHistory?: boolean;
  isMultiRoles?: boolean;
  extraToolbarButtons?: ExtraToolbarButton<any>[];
  isEditPerformersOnly?: boolean;
  columns?: Column<Performer>[];
  nameTooltip?: string;
  roleTooltip?: string;
};

export function PerformersListEdit(props: Props) {
  const {
    onChange,
    rows,
    disabled,
    isShowPersonalReport,
    isShowPersonalInfo,
    isShowHistory,
    extraToolbarButtons,
    isEditPerformersOnly,
    isMultiRoles,
    columns,
    nameTooltip,
    roleTooltip,
  } = props;

  const {
    makePreSubmitCallback,
    listEditColumns,
    setSelectedPerformerIndex,
    setIsOpenPersonModal,
    setScientistId,
    handleSetCurrentReport,
    handleOpenHistoryModal,
    makePreCallback,
    isOpenPersonModal,
    handleCloseHistoryModal,
    selectedPerformer,
    closePersonModal,
    isOpenHistoryPersonModal,
    submitHistoryPersonModal,
    isReportOpen,
    onReportClose,
    currentReport,
    scientistId,
  } = useController({ rows, columns, onChange, isEditPerformersOnly, isMultiRoles });

  return (
    <>
      <ListEdit
        maxHeight="none"
        rows={rows}
        isSortEnabled
        isVisibleFilters
        onChange={onChange}
        withMessages
        isDeleteConfirmEnabled
        columns={listEditColumns}
        isDisabled={disabled}
        toolbar={[
          'add',
          'edit',
          'delete',
          {
            icon: { type: 'user' },
            title: 'Просмотр сведений о персоне',
            onClick: (row: Performer | null, idx: number | null) => {
              if (row?.person?.id) {
                setSelectedPerformerIndex(idx);
                setIsOpenPersonModal(true);
              }
            },
            isDisabled: row => row === null || disabled,
            isHidden: !isShowPersonalInfo,
          },
          {
            icon: { type: 'clock' },
            title: 'Персональные данные',
            onClick: handleOpenHistoryModal,
            isDisabled: row => row === null || disabled,
            isHidden: !isShowHistory,
          },
          ...(extraToolbarButtons || []),
          {
            icon: { type: 'print' },
            title: 'Отчеты',
            onClick: (row: any) => {
              setScientistId(row?.person?.scientist?.id || '');
              handleSetCurrentReport({ name: Reports.Personal.caption, value: Reports.Personal.name });
            },
            isDisabled: row => row === null || disabled,
            isHidden: !isShowPersonalReport,
          },
        ]}
        specification={{
          mode: 'customComponent',
          onPreEdit: makePreCallback,
          onPreDelete: makePreCallback,
          onPreSubmit: makePreSubmitCallback,
          renderComponent: (row, onFieldChange) => (
            <PerformerInputs
              entry={row}
              onFieldChange={onFieldChange as any}
              isEditPerformersOnly={isEditPerformersOnly}
              nameTooltip={nameTooltip}
              roleTooltip={roleTooltip}
            />
          ),
          validation: {
            checkIsValid: (row, index, list, mode) => validate(row, list, mode, index).every(({ isValid }) => isValid),
            onInvalidate: (row, mode, index, list) => {
              const validateInfo = validate(row, list, mode, index);
              showErrorsMessages(
                validateInfo.filter(({ isValid }) => !isValid).map(validateInfoItem => validateInfoItem.invalidMessage),
              );
            },
          },
        }}
      />
      {isShowHistory && (
        <PersonHistoryModal
          isOpen={isOpenHistoryPersonModal}
          member={selectedPerformer}
          onClose={handleCloseHistoryModal}
          onSubmit={submitHistoryPersonModal}
          isLoadPersonAfterOpen
          mode="edit"
        />
      )}
      {isShowPersonalInfo && (
        <PortfolioCard isOpen={isOpenPersonModal} id={selectedPerformer?.person?.id ?? null} onClose={closePersonModal} />
      )}
      {isShowPersonalReport && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            scientistId: scientistId ?? '',
          }}
        />
      )}
    </>
  );
}

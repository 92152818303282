import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { ProjectReportArciveHelp, ProjectStateRegistrationReportGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

export const LeftPanelForThirdLevel = (isArchive?: boolean) =>
  memo(({ tableState }: Props) => {
    const {
      isViewDisabled,
      isViewFormOpen,
      handleTemplateCloseViewForm,
      isAddDisabled,
      isEditFormOpen,
      handleTemplateCloseEditForm,
      isEditDisabled,
      isAddFormOpen,
      handleTemplateCloseAddForm,
      nextPublicationName,
      buttons,
      isHelpModalOpen,
      setIsHelpModalOpen,
    } = useController(tableState, isArchive);

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewDisabled && (
          <modalTemplates.ProjectReportViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id ?? '-1'}
            relatedTableState={tableState}
          />
        )}

        {!isEditDisabled && (
          <modalTemplates.ProjectReportEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id ?? '-1'}
            relatedTableState={tableState}
          />
        )}

        {!isAddDisabled && (
          <modalTemplates.ProjectReportAddTemplate.Component
            isOpen={isAddFormOpen}
            onClose={handleTemplateCloseAddForm}
            id={tableState.selectedRows[0]?.ProjectID ?? '-1'}
            relatedTableState={tableState}
            name={nextPublicationName}
          />
        )}

        <Modal
          mode="help"
          title={isArchive ? 'График госрегистрации отчётов' : 'Архив отчётов'}
          isOpen={isHelpModalOpen}
          onClose={() => setIsHelpModalOpen(false)}
          size="large"
        >
          {isArchive ? ProjectReportArciveHelp() : ProjectStateRegistrationReportGridHelp()}
        </Modal>
      </>
    );
  });

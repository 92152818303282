import React from 'react';

import { UploadImage, FormComponent, SectionTitle, PreviewHTML } from 'components';

import { formatLotPrices } from 'features/Form/looks/programRequest/ProgramRequestForm/helpers';
import { formatNumber, getPersonContacts } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';
import { Tender } from 'types/models';

type Props = {
  tender: Tender.Tender | null;
  lot: Tender.Lot | null;
};

export function AboutTender(props: Props) {
  const { tender, lot } = props;
  const { settings } = useAppDataContext();

  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '60%' }}>
          <FormComponent.Line>
            <FormComponent.ColumnWrapper>
              <FormComponent.Column style={{ width: 'calc(100% - 250px)' }} hasNoWrap>
                <FormComponent.Line>
                  <FormComponent.Field label="Программа">
                    <FormComponent.Text>{tender?.program?.name}</FormComponent.Text>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Мероприятие программы">
                    <FormComponent.Text>{tender?.programEvent?.code}</FormComponent.Text>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Конкурс (тема лота)">
                    <FormComponent.Text>
                      {lot?.theme || tender?.name ? `${tender?.name} (ID конкурса: ${tender?.id})` : '' || ''}
                    </FormComponent.Text>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Описание конкурса">
                    <FormComponent.Text>
                      <PreviewHTML value={tender?.description || ''} modalTitle="Полное описание" />
                    </FormComponent.Text>
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
              <FormComponent.Column style={{ width: '250px', height: '110px' }} hasNoWrap>
                <UploadImage text="Логотип программы" id={tender?.logo?.id || tender?.program?.logo?.id} viewMode />
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Участники">
              <FormComponent.Text>{tender?.participants?.map(p => p.label).join(', ')}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Категория конкурса">
              <FormComponent.Text>{tender?.categories?.map(c => c.label).join(', ')}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Области научных интересов">
              <FormComponent.Text>{tender?.scienceDomainInterrests?.map(s => s.label).join(', ')}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Шифр лота">
              <FormComponent.Text>{lot?.code}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Количество контрактов в лоте">
              <FormComponent.Text>{(lot?.contractCount || '').toString()}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Цена контракта">
              <FormComponent.Text>{(lot?.contractAmount || '').toString()}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Календарный план">
              <FormComponent.Text>
                {formatLotPrices(lot?.prices?.sort((a, b) => parseInt(a.year, 10) - parseInt(b.year, 10)) || [])}
              </FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Заказчики">
              <>
                {tender?.customers
                  ?.map(({ fullName }) => fullName || '')
                  .filter(Boolean)
                  .join(', ')}
              </>
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: '40%' }}>
          <FormComponent.Line>
            <FormComponent.Field label="Подать заявку на согласование до">
              <FormComponent.Text>{tender?.dateUniversity}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата/время окончания приема заявок">
              <FormComponent.Text>
                {tender?.dateOpenEnvelope} {tender?.timeOpenEnvelope}
              </FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата рассмотрения заявок">
              <FormComponent.Text>{tender?.dateExamenation}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата подведения итогов">
              <FormComponent.Text>{tender?.dateSummingUp}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение заявки">
              <FormComponent.Text>{formatNumber(lot?.requestSupportAmount || '0.0', 2)}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение контракта">
              <FormComponent.Text>{formatNumber(lot?.contractSupportAmount || '0.0', 2)}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Обеспечение гарантийных обязательств">
              <FormComponent.Text>{formatNumber(lot?.warrantySupportAmount || '0.0', 2)}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Электронная торговая площадка">
              <FormComponent.Text>{tender?.marketplace?.label}</FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>

          <SectionTitle title={`Консультации и помощь в ${settings?.organization?.shortName}`} />

          <>
            {(tender?.contacts || []).map(x => {
              return (
                <FormComponent.Field key={x.id}>
                  <FormComponent.ColumnWrapper>
                    <FormComponent.Column style={{ width: '70%' }} hasNoWrap>
                      <h3>{x.fullName}</h3>
                      <FormComponent.Field label="Электронная почта">
                        {getPersonContacts(x.contacts, 'emails')}
                      </FormComponent.Field>
                      <FormComponent.Field label="Телефон">
                        <FormComponent.Text>{getPersonContacts(x.contacts, 'phones')}</FormComponent.Text>
                      </FormComponent.Field>
                    </FormComponent.Column>
                    <FormComponent.Column style={{ width: '30%', height: '110px' }} hasNoWrap>
                      <UploadImage text="Фото пользователя" id={x.avatar?.id} viewMode />
                    </FormComponent.Column>
                  </FormComponent.ColumnWrapper>
                </FormComponent.Field>
              );
            })}
          </>
          {tender?.contactFace && (
            <FormComponent.Text>
              <strong>Очные консультации по адресу:</strong> {tender?.contactFace || ''}
            </FormComponent.Text>
          )}
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

import React from 'react';

import { FormComponent, TextArea, TextAreaMode } from 'components';

import { GetPartnerList } from 'features/Table/specifications';
import { Affiliation, Table } from 'types/models';
import { tableRowConverter } from './helpers';

type Props = {
  affiliation: Affiliation | null;
  setAffiliation: (affiliation: Affiliation | null) => void;
  isDisabled: boolean;
  label: string;
  modalTitle: string;
  tooltip?: string;
};

export const AffiliationSelect = ({ affiliation, setAffiliation, isDisabled, label, modalTitle, tooltip }: Props) => (
  <FormComponent.Field label={label} tooltip={tooltip}>
    <TextArea
      mode={TextAreaMode.TABLE}
      settings={{
        title: modalTitle,
        table: {
          specification: GetPartnerList({ toolbarMode: 'ref', hasSelectButton: true }),
          onSelect: (row: Table.Entry | null) => setAffiliation(row ? tableRowConverter(row) : null),
        },
      }}
      value={
        affiliation?.enterprise?.label ||
        affiliation?.enterpriseCustomer?.label ||
        affiliation?.partner?.shortName ||
        affiliation?.partner?.fullName
      }
      isDisabled={isDisabled}
    />
  </FormComponent.Field>
);

import React from 'react';

import { showErrors, isInPeriod } from 'utils/Validators';
import { PeriodableReferenceItem } from 'types/models/Partner';
import { ListEdit, SectionTitle } from 'components';
import { getMockPeriodableReferenceItem } from 'features/EditPartner/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  okveds: PeriodableReferenceItem[];
  setOkveds(okveds: PeriodableReferenceItem[]): void;
  disabled: boolean;
};

export function Okveds(props: Props) {
  const { okveds, setOkveds, disabled } = props;

  return (
    <>
      <SectionTitle title="ОКВЭД" />
      <ListEdit
        rows={okveds}
        onChange={setOkveds}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'ОКВЭД', formatValue: x => x.ref?.label ?? '' },
          { label: 'Начало срока действия', formatValue: x => x.startDate ?? '' },
          { label: 'Окончание срока действия', formatValue: x => x.endDate ?? '' },
          { label: 'Примечание', formatValue: x => x.note ?? '' },
          { label: 'Актуальный', formatValue: x => (isInPeriod(x.startDate, x.endDate) ? 'Да' : '') },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={5}
        maxHeight="250px"
        specification={{
          mode: 'customComponent',
          renderComponent: (okved, setOkved) => <Fields okved={okved || getMockPeriodableReferenceItem()} setOkved={setOkved} />,
          validation: {
            checkIsValid: okved => validate(okved).every(x => x.isValid),
            onInvalidate: val => showErrors(validate, val),
          },
        }}
      />
    </>
  );
}

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { ApproveParticipation } from 'features/Form/looks/participation/views/ApproveParticipation';
import { EditParticipationDepartments } from 'features/Form/looks/participation/views/EditParticipationDepartments';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import useController from './controller';
import { DataGrid } from 'features/Table';
import { GetParticipationUsagesList } from 'features/Table/specifications';

type Props = {
  tableState: Table.State;
};

const LeftPanelForThirdLevel = ({ tableState }: Props) => {
  const {
    buttons,
    selectedRow,
    isAddFormOpen,
    isEditFormOpen,
    isViewFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    isConfirmDeleteOpen,
    userSystemDepartment,
    isEditDepartmentsOpen,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    isUsagesListOpen,
    handleCloseUsagesList,
    isReportOpen,
    onReportClose,
    currentReport,
    settings,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <EditParticipationDepartments
        type={ParticipationTypes.PARTICIPATION_CONTEST.code}
        isOpen={isEditDepartmentsOpen}
        afterSubmit={afterSubmitEditDepartments}
        onClose={handleCloseEditDepartments}
        id={tableState.selectedRows[0]?.id || ''}
      />

      <ApproveParticipation
        type={ParticipationTypes.PARTICIPATION_CONTEST.code}
        isOpen={isApprovePopupOpen}
        onClose={handleCloseApprovePopup}
        id={tableState.selectedRows[0]?.id || ''}
        afterSubmit={afterSubmitApproveParticipation}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConfirmDelete,
          },
        ]}
        size="small"
      >
        <>({tableState.selectedRows[0]?.EventFullName}) Удалить участие?</>
      </Modal>

      <Modal
        mode="warning"
        title="Привязка к подразделению"
        isOpen={isWarningPopupOpen}
        onClose={handleCloseWarningPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Ок',
            onClick: handleAttachParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseWarningPopup,
          },
        ]}
        size="medium"
      >
        <>
          <p>Привязать участие к своему подразделению "{userSystemDepartment?.label}"?</p>
          <p>
            <strong>Информация об участии</strong>
          </p>
          <p>
            <strong>Олимпиада/конкурс:</strong> {selectedRow?.EventFullName || ''}
          </p>

          {selectedRow?.Participants && (
            <p>
              <strong>Участники:</strong> {selectedRow?.Participants}
            </p>
          )}
        </>
      </Modal>

      {isEditFormOpen && (
        <modalTemplates.ContestParticipationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isViewFormOpen && (
        <modalTemplates.ContestParticipationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          id={tableState.selectedRows[0]?.id}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ContestParticipationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}

      <Modal
        isOpen={isUsagesListOpen}
        onClose={handleCloseUsagesList}
        title="Связи участия с другими объектами"
        size={['extralarge', 'small-height']}
      >
        <DataGrid specification={GetParticipationUsagesList({ id: selectedRow?.id })} />
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />
    </>
  );
};

export const Component = memo(LeftPanelForThirdLevel);

import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { FormComponent, Select } from 'components';

import { COLORS, COLORS_BORDERS } from '../сolors';
import { Item } from 'types/models/common';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationExpenses() {
  const {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    studyProjectListByPeriod,
    getChartSumResult,
    getNumberSumResult,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value } = context;
      return ` ${Math.round((parseInt(value, 10) / getNumberSumResult()) * 100 * 100) / 100}% (${new Intl.NumberFormat().format(
        value,
      )} тыс. руб.)`;
    };

    const data = {
      labels: studyProjectListByPeriod.map((x: { kind: any }) => x?.kind),
      datasets: [
        {
          data: studyProjectListByPeriod.map((x: { share: any }) => x?.share),
          backgroundColor: COLORS,
          borderColor: COLORS_BORDERS,
          borderWidth: 2,
        },
      ],
    };

    return (
      <Doughnut
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              align: 'end',
              text: `Всего: ${getChartSumResult()} тыс. руб.`,
              font: {
                size: 20,
              },
            },
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Затраты на научные исследования и разработки по областям науки</h3>
        <div className={b('params')}>
          <FormComponent.Line>
            <FormComponent.Field label="Период" hasLeftAlign>
              <Select
                value={activeFilter}
                options={periodOpts.current}
                onChange={(option: Item | null) => handleChangeActiveFilter(option)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '440px' }}>
          {Chart()}
        </div>
      </div>
    </>
  );
}

import { Table as T } from 'types/models';
import { ToolbarTemplate } from './Toolbar';
import { State, makeUseCustomController } from './makeUseCustomController';

type Arguments = {
  projectAssignmentId?: string | null;
  disabled?: boolean;
};

export function GetProjectAssignmentMessageList(args: Arguments): T.Specification<State, Arguments> {
  const { projectAssignmentId, disabled } = args;

  return {
    apiID: 'GetProjectAssignmentMessageList',
    isFiltersHidden: true,
    requestData: { projectAssignmentId: args.projectAssignmentId },
    header: {
      thirdLevel: {
        LeftPanel: ToolbarTemplate,
        withTemplatesPanel: false,
      },
      firstLevel: {
        title: 'Сообщения по поручению',
      },
    },
    useCustomController: makeUseCustomController({ projectAssignmentId, disabled }),
  };
}

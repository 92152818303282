import React from 'react';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { Form } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { useController } from './controller';
import { SecurityDocumentHelp } from './help';
import { SecurityDocumentType } from 'utils/Enums';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onViewProjectInfo(): void;
  securityDocument?: SecurityDocument | null;
  formFields: Form.Fields;
  disabled?: boolean;
};

function Toolbar(props: Props) {
  const { onSave, onSaveDraft, onViewProjectInfo, securityDocument, formFields, disabled } = props;

  const {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isReportOpen,
    onReportClose,
    currentReport,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
  } = useController({ onSave, onSaveDraft, onViewProjectInfo, securityDocument, formFields, disabled });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      {securityDocument?.id && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            securityDocumentId: securityDocument?.id || '',
          }}
        />
      )}

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isSaveConfirmPopupOpen}
        onClose={() => setIsSaveConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onSaveConfirmPopupConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsSaveConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Дата снятия патента с баланса равна дате подачи заявки. Продолжить сохранение?</>
      </Modal>

      <Modal
        mode="help"
        title="Особенности при заполнении записи об объекте интеллектуальной  собственности"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {SecurityDocumentHelp(securityDocument?.type?.value as SecurityDocumentType)}
      </Modal>
    </>
  );
}

export { Toolbar };

import React, { memo } from 'react';
import * as modalTemplates from '../modalTemplates';

import { Toolbar } from 'components';

import useController from './controller';

const ToolbarTemplate = () => {
  const { isAddFormOpen, closeAddForm, toolbarButtons } = useController();

  return (
    <>
      <Toolbar buttons={toolbarButtons} />

      <modalTemplates.MonographAddTemplate isOpen={isAddFormOpen} onClose={closeAddForm} />
    </>
  );
};

export default memo(ToolbarTemplate);

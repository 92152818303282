import React, { useMemo } from 'react';

import {
  Checkbox,
  FormComponent,
  ReferenceItem,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { AuthorPayment, EventType, Nominee } from 'types/models/Payment';
import { GetScientistJobs } from 'features/Table/specifications';
import { formatEditableJob } from 'features/IncentivePayments/helpers';
import { ScientistJob } from 'types/models/Person';
import { ValueOf } from 'types/helpers';
import { Table } from 'types/models';

type Props = {
  nominee: Nominee | null;
  setNominee: React.Dispatch<React.SetStateAction<Nominee | null>>;
  authorPayment: AuthorPayment;
  eventType: EventType | null;
  isDepartmentRequired?: boolean;
};

export function Fields(props: Props) {
  const { nominee, setNominee, eventType, authorPayment } = props;

  if (!nominee) {
    return null;
  }

  const changeField = (key: keyof Nominee, val: ValueOf<Nominee>) => {
    setNominee({ ...nominee, [key]: val });
  };

  const isDepartmentRequired = useMemo(() => !!Number(authorPayment.maxFundMoney) && nominee.isAffiliated, [
    authorPayment.maxFundMoney,
    nominee.isAffiliated,
  ]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="">
          <strong>{nominee.person.fullName}</strong>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line hasFreeFormat>
            <FormComponent.Field label="">
              <Checkbox
                label="Аффилирован"
                checked={!!nominee.isAffiliated}
                onChange={checked => {
                  setNominee({
                    ...nominee,
                    isAffiliated: checked,
                    ...(checked ? {} : { isPaid: false, factMoney: '0' }),
                  });
                }}
              />
            </FormComponent.Field>
            <FormComponent.Field>
              <Checkbox
                label="К оплате"
                checked={!!nominee.isPaid}
                onChange={checked => {
                  setNominee({
                    ...nominee,
                    isPaid: checked,
                    ...(checked ? { factMoney: nominee.planMoney, isAffiliated: true } : { factMoney: '0' }),
                  });
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="План, руб." isRequired={nominee.isPaid}>
              <TextInput
                mode={TextInputMode.NUMBER}
                value={nominee.planMoney}
                onChange={value => changeField('planMoney', value)}
                isDisabled={eventType !== 'MEDAL'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Факт, руб" isRequired={nominee.isPaid}>
              <TextInput
                mode={TextInputMode.NUMBER}
                value={nominee.factMoney}
                onChange={value => changeField('factMoney', value)}
                isDisabled={!nominee.isPaid}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Сведения о работе">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: `Сведения о работе ${nominee.person.shortName || nominee.person.fullName || ''}`,
              table: {
                specification: GetScientistJobs({ personId: nominee.person.id }),
                onSelect: (row: Table.Entry | null) =>
                  changeField('job', row ? (({ appointment: row.appointment, id: row.id } as unknown) as ScientistJob) : null),
              },
            }}
            value={nominee.job ? formatEditableJob(nominee.job) : ''}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Относится к факультету" isRequired={isDepartmentRequired}>
          <Select
            mode={SelectMode.REFERENCE}
            value={{ id: nominee.topLevelDepartment?.id || '', label: nominee.topLevelDepartment?.label || '' }}
            onChange={(option: ReferenceItem | null) => changeField('topLevelDepartment', option || null)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"', isClearable: !isDepartmentRequired }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea value={nominee.note} onChange={e => setNominee(prev => ({ ...prev!, note: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type RequestData = {
  shortName?: string;
  hasSelectButton?: boolean;
};

export function GetForeignSecurityDocumentList({
  shortName,
  hasSelectButton,
}: RequestData): Table.Specification<string, RequestData> {
  return {
    apiID: 'GetForeignSecurityDocumentList',
    header: {
      firstLevel: {
        title: `Реестр ОИС не аффилированных с ${shortName}`,
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: hasSelectButton,
      },
    },
  };
}

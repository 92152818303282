import React, { useEffect } from 'react';
import { format } from 'date-fns';

import { FormComponent, Radio, ReferenceItem, Select, SelectMode, TextDateTime } from 'components';

import { Person } from 'types/models';
import { formatDateTimeStr } from 'utils/Constants';
import { EditableHonor, HonorType } from 'features/Form/looks/person/model';

type Props = {
  item: EditableHonor | null;
  onChange(item: EditableHonor): void;
};

export function Fields(props: Props) {
  const { item, onChange } = props;

  useEffect(() => {
    if (item === null) {
      onChange({ type: 'degree' } as EditableHonor);
    }
  }, [item, onChange]);

  const makeChangeHandler = (fieldName: keyof EditableHonor) => (value: null | ReferenceItem | string) => {
    onChange({
      ...item!,
      [fieldName]: value,
      modificationDate: format(new Date(), formatDateTimeStr),
      creationDate: item?.creationDate || format(new Date(), formatDateTimeStr),
    });
  };

  const isDisabled = item?.isReadOnly ?? false;

  const renderByTypeHonor = () => {
    const mapRender: Record<HonorType, (honor: any) => JSX.Element> = {
      degree: (honor: Person.ScientistDegree) => (
        <FormComponent.Line>
          <FormComponent.Field label="Ученая степень" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={honor.refDegree}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('refDegree' as any)(option)}
              settings={{ name: 'RefDegree', title: 'Справочник "Ученые степени"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ),
      academicRank: (honor: Person.ScientistAcademicRank) => (
        <FormComponent.Line>
          <FormComponent.Field label="Академическое звание" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={honor.refAcademicRank}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('refAcademicRank' as any)(option)}
              settings={{ name: 'RefAcademicRank', title: 'Справочник "Академические звания"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Академия" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={honor.academy}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('academy' as any)(option)}
              settings={{ name: 'RefAcademy', title: 'Справочник "Академии"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ),
      rank: (honor: Person.ScientistRank) => (
        <FormComponent.Line>
          <FormComponent.Field label="Ученое звание" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={honor.refRank}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('refRank' as any)(option)}
              settings={{ name: 'RefScientificRank', title: 'Справочник "Ученые звания"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ),
    };

    const render = mapRender[item?.type!];
    if (!render) {
      return null;
    }

    return render(item);
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Вид">
          <Radio
            isDisabled={isDisabled}
            value={item?.type}
            list={[
              { label: 'Ученая степень', value: 'degree' },
              { label: 'Ученое звание', value: 'rank' },
              { label: 'Академическое звание', value: 'academicRank' },
            ]}
            onChange={makeChangeHandler('type')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {renderByTypeHonor()}
      <FormComponent.Line>
        <FormComponent.Field label="Дата получения">
          <TextDateTime value={item?.dateAssign || ''} onChange={makeChangeHandler('dateAssign')} isDisabled={isDisabled} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

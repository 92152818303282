import { Entry } from 'types/models/Table';
import { SalaryChangeMode } from '../GetSalaryByProjectList/views/SalaryChangeModals';

export type State = {
  seletedSalaryRow?: Entry;
  onSalaryChange?(): void;
  salaryChangeMode?: SalaryChangeMode;
};

export function makeUseCustomController(props: State) {
  return function useCustomController(): State {
    return props;
  };
}

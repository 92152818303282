import React from 'react';

import { ButtonMode, FormComponent, Modal, TextArea, TextAreaMode, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { GetReferenceScientistList } from '../../GetReferenceScientistList';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

export type NotificationMonitor = {
  person: {
    id: string;
    name: string;
  };
  note: string;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    buttons,
    notificationMonitor,
    setNotificationMonitor,
    isOpenNotificationMonitor,
    setIsOpenNotificationMonitor,
    onSaveNotificationMonitor,
    isOpenConfirmDelete,
    setIsOpenConfirmDelete,
    onDeleteNotificationMonitor,
  } = useController({ tableState, customState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title="Добавление наблюдателя"
        isOpen={isOpenNotificationMonitor}
        onClose={() => setIsOpenNotificationMonitor(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить наблюдателя',
            onClick: () => {
              onSaveNotificationMonitor();
            },
            isDisabled: !notificationMonitor.person.id,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenNotificationMonitor(false),
          },
        ]}
        size="medium"
      >
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Персона">
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  rows: 1,
                  title: 'Справочник "Персоны"',
                  isClearable: false,
                  table: {
                    specification: GetReferenceScientistList({ hasSelectButton: true }),
                    onSelect: (row: Table.Entry | null) =>
                      row
                        ? setNotificationMonitor(prev => ({
                            ...prev,
                            person: {
                              id: row.id,
                              name: row.Fio,
                            },
                          }))
                        : null,
                  },
                }}
                value={notificationMonitor.person.name}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Примечание">
              <TextArea
                onChange={val =>
                  setNotificationMonitor(prev => ({
                    ...prev,
                    note: val,
                  }))
                }
                value={notificationMonitor.note}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDelete}
        onClose={() => setIsOpenConfirmDelete(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmDelete(false);
              onDeleteNotificationMonitor();
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDelete(false),
          },
        ]}
        size="small"
      >
        <>({tableState.selectedRows.map(i => i.Fio).join(', ')}) Удалить наблюдателей?</>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

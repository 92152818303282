import React, { useEffect } from 'react';

import { FormComponent, TextArea, Toolbar, UploadFile } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectAssignmentForm({ viewMode, editMode, onClose }: Props) {
  const { projectAssignmentMessage, projectAssignmentMessageId, buttons, workMode, makeChangeHandler } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return 'Добавление Сообщения';
        }

        return getStatusMessage(
          [viewMode ? 'Просмотр' : 'Редактирование', 'Сообщения'].filter(Boolean).join(' '),
          projectAssignmentMessageId || '',
        );
      })(),
    );
  }, [projectAssignmentMessageId, setTitle, viewMode, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Сообщение" isRequired>
            <TextArea
              settings={{ rows: 4, isFixed: true }}
              value={projectAssignmentMessage?.message || ''}
              onChange={makeChangeHandler('message')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Файл">
            <UploadFile file={projectAssignmentMessage?.file} onChange={makeChangeHandler('file')} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectAssignmentForm);

import React, { useEffect } from 'react';

import { FormComponent, Tab, Tabs, TextInput, Toolbar } from 'components';

import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import { GetFundCardPositionList, GetSalaryByFundCardList } from 'features/Table/specifications';

import './style.scss';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function FundCardForm({ viewMode, editMode, onClose }: Props) {
  const { buttons, fundCard, setFundCard } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (!fundCard.id) return 'Создание шифра ЦФ';
        return `Редактирование шифра ЦФ ${fundCard.name}`;
      })(),
    );
  }, [fundCard.id, fundCard.name, setTitle]);

  const renderFundCardFields = (): JSX.Element => {
    return (
      <>
        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field label="Шифр" isRequired>
            <TextInput
              value={fundCard.name}
              onChange={e => setFundCard(prevState => ({ ...prevState, name: e }))}
              classMixin="fund-card-name"
            />
          </FormComponent.Field>
          <FormComponent.Field
            label="Примечание"
            labelSize="fit"
            tooltip="Перечислите для каких шифров проектов его использовать"
          >
            <TextInput value={fundCard.note} onChange={e => setFundCard(prevState => ({ ...prevState, note: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      </>
    );
  };

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        {!!fundCard.id ? (
          <Tabs>
            <Tab title="О шифре">
              <FormComponent.Template>
                {renderFundCardFields()}
                <DataGrid specification={GetFundCardPositionList({ fundCardId: fundCard.id })} />
              </FormComponent.Template>
            </Tab>
            <Tab title="Затраты">
              <em>Не реализовано</em>
            </Tab>
            <Tab title="Затраты на зарплату">
              <DataGrid specification={GetSalaryByFundCardList(fundCard.id)} />
            </Tab>
            <Tab title="Заявки на закупки">
              <em>Не реализовано</em>
            </Tab>
            <Tab title="Документы шифра">
              <em>Не реализовано</em>
            </Tab>
          </Tabs>
        ) : (
          <FormComponent.Wrapper>{renderFundCardFields()}</FormComponent.Wrapper>
        )}
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(FundCardForm);

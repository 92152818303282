import { Table } from 'types/models';
import { Color } from 'constants/colors';
import { Toolbar } from './Toolbar';
import { Filters } from './filters';
import { ProjectAssignmentStatus, ProjectAssignmentStatusCode } from 'types/models/Project';
import { detPeriodHighlights } from 'shared/detPerdiodHighlights';
import { makeUseCustomController, State } from './makeUseCustomController';

type Args = {
  projectId: string;
  isFiltersHidden?: boolean;
  disabled?: boolean;
};

export function GetProjectAssignmentList({ projectId, isFiltersHidden, disabled }: Args): Table.Specification<State, Args> {
  return {
    apiID: 'GetProjectAssignmentList',
    isFiltersHidden,
    requestData: { projectId },
    header: {
      firstLevel: {
        title: 'Список поручений по проекту',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ projectId: projectId ?? '-1', disabled }),
    highlightRow: (row: Table.Entry) => {
      const status = row['id:Status'] as ProjectAssignmentStatusCode;
      if (
        !status ||
        !row.UpToDate ||
        [ProjectAssignmentStatus.CANCELED, ProjectAssignmentStatus.CLOSED, ProjectAssignmentStatus.COMPLETE].indexOf(status) > -1
      ) {
        return Color.primary;
      }
      return detPeriodHighlights(row, 3);
    },
  };
}

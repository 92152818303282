import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { useAppDataContext } from 'features/AppData/context';

export const Filters = React.memo(() => {
  const { settings } = useAppDataContext();
  const organizationName = settings?.organization?.shortName;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'eventCategory',
        list: [
          {
            label: 'Мои конференции',
            value: 'mine',
            hint: 'Отображаются только те конференции, в которых вы в составе оргкомитета',
          },
          {
            label: organizationName ? `Конференции ${organizationName}` : 'Все конференции',
            value: 'local',
            hint: `Список конференций, организованных ${organizationName || 'университетом'}`,
          },
          {
            label: 'Другие конференции',
            value: 'other',
            hint: `Список конференций других организаций, в которых принимают участие сотрудники, аспиранты и студенты ${
              organizationName || 'университета'
            }`,
          },
        ],
        value: 'mine',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Год',
        checked: false,
      },
    ],
    'personal',
  );
});

import React from 'react';
import { format } from 'date-fns';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

import { Person } from 'types/models';
import { formatDateTimeStr } from 'utils/Constants';
import { ValueOf } from 'types/helpers';

type Props = {
  biblioName: Person.BibliographicName | null;
  onChange(biblioName: Person.BibliographicName): void;
};

function Fields(props: Props) {
  const { biblioName, onChange } = props;

  const makeChangeHandler = (fieldName: keyof Person.BibliographicName) => (value: ValueOf<Person.BibliographicName>) => {
    onChange({
      ...biblioName,
      [fieldName]: value,
      modifiedDate: format(new Date(), formatDateTimeStr),
      ...(biblioName?.createdDate ? {} : { createdDate: format(new Date(), formatDateTimeStr) }),
    } as Person.BibliographicName);
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <TextInput value={biblioName?.name ?? ''} onChange={makeChangeHandler('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Язык" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={biblioName?.language}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('language')(option)}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { Fields };

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { RequestStatus } from 'utils/Enums/RequestStatus';
import { ProgramRequestTab } from 'utils/Permissions/ProgramRequestTab';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { getRequestStatusButtons } from '../helpers';
import { showNotification } from 'features/Notifications';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [selectedRow] = tableState.selectedRows;
  const requestStatus = (selectedRow?.['id:RequestStatus'] || null) as RequestStatus | null;

  const [isTenderModalOpen, setIsTenderModalOpen] = useState<boolean>(false);
  const [tenderInfo, setTenderInfo] = useState<TenderInfo | null>(null);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isCopyFormOpen, setIsCopyFormOpen] = useState<boolean>(false);
  const [isNumberPrefixModalOpen, setIsNumberPrefixModalOpen] = useState<boolean>(false);
  const [numberPrefix, setNumberPrefix] = useState<ReferenceItem | null>(null);

  const { methods: editGroupRequestNumberPrefix } = BackendAPI.useBackendAPI('EditGroupRequestNumberPrefix');

  const isEditPermitted = useMemo(() => {
    if (!requestStatus) return false;
    return isHasPermission(
      userPermission,
      (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
        tabKey => Permits[`PROGRAM_REQUEST_EDIT_${requestStatus}_${tabKey}`],
      ),
    );
  }, [requestStatus, userPermission]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: selectedRow?.id,
      command: 'DeleteRequest',
      deletedItemPropName: 'RequestInfo',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [selectedRow?.id, tableStreams.deleteRow]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsTenderModalOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setTenderInfo(null);
    setIsAddFormOpen(false);
  }, []);

  const handleTenderSelect = useCallback((row: Table.Entry) => {
    setTenderInfo({ tenderId: row.id, lotId: row.lotId });
    setIsTenderModalOpen(false);
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const saveNumberPrefix = useCallback(() => {
    editGroupRequestNumberPrefix.callAPI(
      {
        numberPrefixId: numberPrefix?.id,
        requestIds: tableState.selectedRows.map(x => x.id),
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Код шифра сохранён', theme: 'success' });
          tableStreams.reloadTable.push({});
          setIsNumberPrefixModalOpen(false);
          setNumberPrefix(null);
        },
      },
    );
  }, [editGroupRequestNumberPrefix, numberPrefix?.id, tableState.selectedRows, tableStreams]);

  const reports = useMemo<Report[]>(() => [Reports.RequestTender, Reports.EstimateRequest, Reports.CertificatParticipation], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons: statusChangeButtons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = getRequestStatusButtons({
    rows: tableState.selectedRows.map(x => ({ id: x.id, status: (x?.['id:RequestStatus'] as RequestStatus) || null })),
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.PROGRAM_REQUEST_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: {
          name: (Object.keys(RequestStatus) as Array<RequestStatus>).flatMap(statusKey =>
            (Object.keys(ProgramRequestTab) as Array<ProgramRequestTab>).map(
              tabKey => Permits[`PROGRAM_REQUEST_EDIT_${statusKey}_${tabKey}`],
            ),
          ),
        },
        isDisabled: tableState.selectedRows.length !== 1 || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: {
          name: Permits.PROGRAM_REQUEST_DELETE_DRAFT,
        },
        isDisabled: requestStatus !== RequestStatus.DRAFT,
      },
      {
        icon: { type: 'document', mode: 'arrowRight' },
        title: 'Копировать запись',
        onClick: () => setIsCopyFormOpen(true),
        permission: {
          name: Permits.PROGRAM_REQUEST_ADD,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'relation' },
        title: 'Присвоить код группы заявок',
        onClick: () => setIsNumberPrefixModalOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
      ...statusChangeButtons,
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleHelpButtonClick,
      tableState.selectedRows.length,
      handleAddButtonClick,
      isEditPermitted,
      requestStatus,
      statusChangeButtons,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    buttons,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTenderSelect,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isTenderModalOpen,
    isViewFormOpen,
    nextPublicationName,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsTenderModalOpen,
    setIsViewFormOpen,
    tenderInfo,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isCopyFormOpen,
    setIsCopyFormOpen,
    isNumberPrefixModalOpen,
    setIsNumberPrefixModalOpen,
    numberPrefix,
    setNumberPrefix,
    saveNumberPrefix,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

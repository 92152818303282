import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ValueOf } from 'types/helpers';
import { PurchaseRequest } from 'types/models/PurchaseRequest';
import { Item } from 'types/models/common';
import { ProjectScientistRole } from 'utils/Enums';
import { ReferenceItem } from 'types/models/Reference';

type Props = {
  purchaseRequest: PurchaseRequest;
  makeChangeHandler: (key: keyof PurchaseRequest) => (val: ValueOf<PurchaseRequest>) => void;
};

export function useController(props: Props) {
  const { makeChangeHandler, purchaseRequest } = props;

  const [defaultUnit, setDefaultUnit] = useState<ReferenceItem | undefined>(undefined);

  const { methods: getReferenceElementList } = BackendAPI.useBackendAPI('GetReferenceElements');

  useEffect(() => {
    getReferenceElementList.callAPI(
      {
        filters: [],
        referenceName: 'RefOkei',
      },
      {
        onSuccessfullCall: ({ data }) => {
          setDefaultUnit(data.filter(i => i.customFields?.code === '796')[0]);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const projectPerformerOptions = useMemo(() => {
    return (
      purchaseRequest.project?.performers
        .filter(i => (i?.jobPeriods ? i?.jobPeriods?.find(jp => jp.role?.value === ProjectScientistRole.LEADER) : false))
        .map(x => ({
          label: [
            x.fio || '-',
            `(${x.jobPeriods.map(i => `${i.role?.label} ${i.startDate || '*'} - ${i.endDate || '*'}`).join(', ')})`,
          ].join(' '),
          value: x.id || '',
        })) || []
    );
  }, [purchaseRequest.project]);

  const handleApplicantChange = useCallback(
    (selectedOption: Item) => {
      const performer = purchaseRequest.project?.performers.find(p => p.id === selectedOption.value);
      if (performer) {
        makeChangeHandler('applicant')(performer);
      }
    },
    [purchaseRequest.project, makeChangeHandler],
  );

  const purchaseRequestNumberTooltipTxt = 'Заполняется при регистрации заявки на закупку в соответствии с внутренним регламентом';
  const purchaseRequestSendDateTooltipTxt = 'Дата подачи заявки на рассмотрение';
  const purchaseRequestDepartmentTxt = 'Ответственное подразделение за приемку товара/услуг/работ';

  return {
    defaultUnit,
    projectPerformerOptions,
    handleApplicantChange,
    purchaseRequestNumberTooltipTxt,
    purchaseRequestSendDateTooltipTxt,
    purchaseRequestDepartmentTxt,
  };
}

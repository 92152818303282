export function SecurityDocumentPaymentGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит график оплаты пошлин за регистрацию и поддержку охранных документов.
        <br />
        Событие в графике содержит дату, до которой необходимо оплатить пошлину, назначение платежа и сумму (в соответствии с
        данными справочника). Дата вычисляется на основе даты подачи заявки. Количество платежей зависит от даты окончания срока
        действия охранного документа.
      </p>

      <ul>
        <li>
          Фильтр <strong>Год</strong> служит для отображения списка записей с датой Оплатить до указанного в фильтре года;
        </li>
        <li>
          Фильтр <strong>Неоплачено</strong> служит для отображения списка записей, у которых отсутствует отметка об оплате (дата
          оплаты);
        </li>
        <li>
          Фильтр <strong>Необходимо оплатить пошлину в течении месяца</strong> отображает записи, у которых дата в столбце
          Оплатить до удовлетворяет условию: <strong>Оплатить до – Сегодня &lt;= 30 дней</strong>;
        </li>
        <li>
          Для просмотра <strong>всех</strong> записей в таблице необходимо отключить все фильтры.
        </li>
      </ul>

      <p>
        Кнопка <strong>«Редактировать»</strong> предназначена для отметки факта оплаты: на форме необходимо указать дату
        платёжного поручения, сумма документа и приложить скан-копию платёжного поручения.
      </p>
      <p>
        Кнопка <strong>«Вычислить сумму выделенных строк»</strong> предназначена для расчёта общей суммы выделенных в таблице
        строк.
      </p>
      <p>
        Кнопка <strong>«Выгрузить в файл»</strong> позволяет выгрузить в файл содержимое таблицы.
      </p>
      <p>
        Кнопка <strong>«Открыть файл»</strong> позволяет открыть прикреплённый файл для просмотра в новом окне браузера.
      </p>

      <h4>График оплаты пошлин позволяет:</h4>

      <ul>
        <li>
          Вычислить прямые затраты на поддержание охранного документа за период: итоговая строка таблицы содержит общую сумму за
          весь период действия документа; кнопка <strong>«Вычислить сумму выделенных строк»</strong> — для расчёта затрат за
          несколько лет;
        </li>
        <li>Планировать затраты при составлении бюджета на период;</li>
        <li>
          Отмечать факт оплаты пошлины: на форме редактирования записи в графике указать дату оплаты и прикрепить скан-копию
          платёжного поручения.
        </li>
      </ul>
    </>
  );
}

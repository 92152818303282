import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';

export function validate(hirshIndex: Person.HirshIndex) {
  const errorMessages = [];

  if (!hirshIndex.citationSystem) errorMessages.push('Выберите Систему цитирования');
  if (!hirshIndex.date) errorMessages.push('Укажите дату рассчета');

  errorMessages.forEach(x => showNotification({ message: x, theme: 'danger' }));

  return !errorMessages.length;
}

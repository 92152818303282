import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextDateTime, TextInput, TextInputMode } from 'components';

import { Person } from 'types/models';

type Props = {
  hirshIndex: Person.HirshIndex | null;
  setHirshIndex: React.Dispatch<React.SetStateAction<Person.HirshIndex | null>>;
};

export const Fields = React.memo(({ hirshIndex, setHirshIndex }: Props) => {
  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Система цитирования" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={hirshIndex?.citationSystem}
            onChange={(option: ReferenceItem | null) => setHirshIndex(prevState => ({ ...prevState!, citationSystem: option }))}
            settings={{ name: 'RefCitationSystem', title: 'Справочник "Система цитирования"' }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Дата расчета" isRequired>
          <TextDateTime value={hirshIndex?.date} onChange={e => setHirshIndex(prevState => ({ ...prevState!, date: e }))} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Значение">
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ decimal: false }}
            value={hirshIndex?.value || ''}
            onChange={value => setHirshIndex(prevState => ({ ...prevState!, value }))}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Примечание">
          <TextInput
            value={hirshIndex?.note || ''}
            onChange={val => setHirshIndex(prevState => ({ ...prevState!, note: val }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
});

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, FormComponent, Modal, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { SelectSecurityDocumentPopup } from 'features/Form/views/SelectSecurityDocumentPopup';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications/GetAuthorPaymentRequestFeedlineList';
import { useController } from './controller';
import { AuthorPaymentRequestStatus, PaymentRequestType } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { IncentivePaymentsGridHelp } from 'features/IncentivePayments/Toolbar/help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isAddPaymentRequestFormOpen,
    handleTemplateCloseAddForm,
    isViewPaymentRequestFormOpen,
    handleTemplateCloseViewForm,
    isAddButtonDisabled,
    securityDocumentId,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isSelectFormOpen,
    onSelectProjectFormClose,
    onSubmitSelectDocument,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    comment,
    setComment,
    newStatus,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    handleStatusChange,
    isFeedlineModalOpen,
    setIsFeedlineModalOpen,
    isHelpOpen,
    setIsHelpOpen,
  } = useController({ tableState });

  const { settings, userSystemDepartment } = useAppDataContext();

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.PatentPaymentRequestViewTemplate.Component
        isOpen={isViewPaymentRequestFormOpen}
        onClose={handleTemplateCloseViewForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      {!isAddButtonDisabled && (
        <modalTemplates.PatentPaymentRequestAddTemplate.Component
          isOpen={isAddPaymentRequestFormOpen}
          onClose={handleTemplateCloseAddForm}
          securityDocumentId={securityDocumentId || ''}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.PatentPaymentRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>Заявка на оплату ОИС "{tableState.selectedRows[0]?.documentName}". Удалить заявку?</>
        </Modal>
      )}

      <Modal
        mode="help"
        title={IncentivePaymentsGridHelp(PaymentRequestType.SECURITY_DOCUMENT).title}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {IncentivePaymentsGridHelp(PaymentRequestType.SECURITY_DOCUMENT).body}
      </Modal>

      <SelectSecurityDocumentPopup
        isOpen={isSelectFormOpen}
        onClose={onSelectProjectFormClose}
        onSubmit={onSubmitSelectDocument}
      />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        systemDepartMentLabel={`${userSystemDepartment?.label}` || ''}
        values={{
          userDepartment: settings?.userDepartment,
        }}
      />

      <Modal
        title="Изменение статуса заявки"
        isOpen={!!isOpenStatusForm}
        onClose={() => setIsOpenStatusForm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setIsOpenStatusForm(false);
              handleStatusChange(newStatus || '');
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenStatusForm(false),
          },
        ]}
        size="large"
      >
        <FormComponent.Field label="Комментарий">
          <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
        </FormComponent.Field>
      </Modal>

      <Modal
        mode="warning"
        title="Изменение статуса заявки"
        isOpen={!!isOpenConfirmStatusForm}
        onClose={() => setIsOpenConfirmStatusForm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmStatusForm(false);
              setIsOpenStatusForm(true);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmStatusForm(false),
          },
        ]}
        size="small"
      >
        <>
          {checkPaymentByStatus(
            tableState.selectedRows[0]?.factNomineesMoney ?? null,
            (newStatus as AuthorPaymentRequestStatus) ?? null,
          )}
        </>
      </Modal>

      <Modal
        isOpen={isFeedlineModalOpen || false}
        onClose={() => setIsFeedlineModalOpen(false)}
        title="Журнал сообщений"
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid specification={GetAuthorPaymentRequestFeedlineList({ paymentId: tableState.selectedRows[0]?.id || '' })} />
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { useCallback, useLayoutEffect, useMemo } from 'react';

import { Project, Table } from 'types/models';
import { Item } from 'types/models/common';
import { Original } from 'types/models/Form';
import { ActDepartment, ActMember, ActStage } from 'types/models/Project';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { GetDissertationCouncilList } from 'features/Table/specifications';
import { CommunityType, ProjectScientistRole } from 'utils/Enums';
import { calcStageTotalAmount, getMockAct } from 'features/Form/looks/project/ProjectForm/views/Acts/helpers';

type Props = {
  project: Project.Project | null;
  act: Project.Act | null;
  onActChange(refs: Project.Act): void;
};

export function useController({ project, act, onActChange }: Props) {
  const { enumMap } = useAppDataContext();

  const makeChangeHandler = (key: keyof Project.Act) => (value: ValueOf<Project.Act>) => {
    onActChange({ ...act, [key]: value } as Project.Act);
  };

  const handleMembersChange = useCallback(
    (value: ActMember[] | null) => {
      const ids = new Set<string>();
      const newValues = new Set<ActMember>();

      value?.forEach(v => {
        if (!ids.has(v?.councilMember?.id)) {
          newValues.add(v);
          ids.add(v?.councilMember?.id);
        }
      });

      onActChange({ ...act, members: Array.from(newValues) } as Project.Act);
    },
    [onActChange, act],
  );

  const handleStageChange = useCallback(
    (value: Item | null) => {
      if (act) {
        const stage = (value as unknown) as ActStage;
        const number = act.number ? act.number : stage?.number || '';
        onActChange({ ...act, stage: stage, number });
      }
    },
    [onActChange, act],
  );

  const handleDepartmentsChange = useCallback(
    (value: ActDepartment[]) => {
      onActChange({ ...act, departments: value } as Project.Act);
    },
    [onActChange, act],
  );

  const handleCommunityTypeChange = useCallback(
    (value: ValueOf<Project.Act>) => {
      const selectedOption =
        enumMap && enumMap.CommunityType ? enumMap.CommunityType.values.filter(x => x?.label === value)[0] : null;
      onActChange({ ...act, communityType: selectedOption?.value ?? null } as Project.Act);
    },
    [onActChange, act, enumMap],
  );

  const communityTypesOptions: Table.EnumValue[] = useMemo(
    () =>
      enumMap && enumMap.CommunityType
        ? (enumMap.CommunityType.values
            //.map(x => (communityTypes.find(y => y === x.key) ? x : null))
            .filter(Boolean) as Table.EnumValue[])
        : [],
    [enumMap],
  );

  const projectStageOptions = useMemo(() => {
    return (
      project?.stages?.map(stage => {
        return {
          id: stage?.id,
          value: stage?.id,
          label: `${stage.number} (${stage.startDate} - ${stage.endDate})`,
          number: stage.number,
        };
      }) ?? []
    );
  }, [project]);

  const modalTableRowCouncilConverter = useCallback<(row: Table.Entry) => Original>(row => {
    return {
      id: row.id,
      name: row.Name,
    };
  }, []);

  const sourceCouncilTableConfig = useMemo(
    () => GetDissertationCouncilList({ communityTypes: [CommunityType.SCIENTIFIC_COUNCIL], hasSelectButton: true }),
    [],
  );

  const modalTableRowDepartmentsConverter = useCallback<(row: Table.Entry) => ActDepartment>(row => {
    return {
      id: '',
      department: {
        id: row.id,
        governance: { id: row['id:parent'], label: '' },
        unit: { id: row.id, label: row?.label ?? '' },
      },
    };
  }, []);

  useLayoutEffect(() => {
    if (!act?.number) {
      const departments =
        project?.departments.map(d => {
          return { id: '', department: d };
        }) ?? [];

      onActChange({
        ...getMockAct(),
        departments,
        сonsiderationDocuments:
          'Отчёт о научно-исследовательской работе по ГОСТ 7.32-2017 за отчётный период.\n' +
          'Рассмотрев представленные материалы, комиссия пришла к следующему заключению.',
        defaultBaseProgram: [
          project?.program?.name ? `Программа: ${project?.program?.name}` : '',
          project?.programEventName ? `Мероприятие: ${project?.programEventName}` : '',
        ].join('\n'),
        conclusion:
          // eslint-disable-next-line max-len
          '1. Представленные материалы являются достаточными (или) недостаточными для оценки результатов НИР за отчётный период.\n' +
          '2. Выполнение требований технического задания: в полном соответствии с техническим заданием на проведение НИР.\n' +
          // eslint-disable-next-line max-len
          '3. Научно-техническая и практическая ценность полученных результатов и продукции: достигнутые технико-экономические показатели высокие (или) значительные (или) незначительные.\n' +
          // eslint-disable-next-line max-len
          '4. Полученные результаты (продукция) для практического использования полностью готовы (или) не готовы и требуют дополнительной проработки.\n' +
          '5. Результаты НИР в учебном процессе используются (или) могут быть использованы (или) не используются.\n' +
          'Считать данную работу принятой.',
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const findLeader = useCallback(
    () =>
      project?.performers.find(x =>
        x?.jobPeriods ? x?.jobPeriods?.find(jp => jp.role?.value === ProjectScientistRole.LEADER) : false,
      ) ?? null,
    [project],
  );

  const projectLeader = useMemo(() => findLeader(), [findLeader]);

  const stageTotal = useMemo(() => {
    return calcStageTotalAmount(project, act);
  }, [project, act]);

  const сonsiderationDocumentsTooltipTxt =
    // eslint-disable-next-line max-len
    'Заполнить при наличии дополнительной научной, технической и другой документации, представленной Комиссии по окончании этапа НИР';

  const councilTooltipTxt = 'Выбрать направление научно-технического совета соответствующее тематике проекта';

  const membersTooltipTxt =
    // eslint-disable-next-line max-len
    'Из состава Комиссии необходимо выбрать минимум 1 члена Комиссии с ролью «Заместитель председателя» и 3 членов Комиссии с ролью «Член Совета»';

  const conclusionTooltipTxt = 'Отредактируйте текст: в пунктах 1, 3, 4, 5 необходимо оставить один из перечисленных  вариантов';

  const noteTooltipTxt = 'Замечания и предложения могут быть внесены от имени Членов Комиссии';

  return {
    makeChangeHandler,
    communityTypesOptions,
    handleCommunityTypeChange,
    defaultBaseProgram: project?.program?.name ?? '',
    projectStageOptions,
    modalTableRowCouncilConverter,
    sourceCouncilTableConfig,
    projectLeader,
    stageTotal,
    handleDepartmentsChange,
    modalTableRowDepartmentsConverter,
    handleStageChange,
    handleMembersChange,
    сonsiderationDocumentsTooltipTxt,
    councilTooltipTxt,
    membersTooltipTxt,
    conclusionTooltipTxt,
    noteTooltipTxt,
  };
}

import { Table } from 'types/models';

export function GetSimpleMobileRequestList(): Table.Specification {
  return {
    apiID: 'GetMobileRequestList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

import React from 'react';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { AddMip } from 'features/Form/views';
import useController from './controller';
import { State } from '../makeUseCustomController';
import { PartnerMipGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};

export const Toolbar = ({ tableState, customState }: Props) => {
  const {
    buttons,
    isOpenHelpModal,
    setIsOpenHelpModal,
    isPreAddFormOpen,
    isInfoPopupOpen,
    isConfirmDeletePopupOpen,
    onDeleteClose,
    onDeleteConfirm,
    onCloseInfoPopup,
    onPreAddClose,
    onPreAddSubmit,
  } = useController({
    tableState,
    customState,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isConfirmDeletePopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isConfirmDeletePopupOpen}
          onClose={onDeleteClose}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: onDeleteConfirm,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onDeleteClose,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.FullName}) Удалить малое инновационое предприятие?</>
        </Modal>
      )}

      {isInfoPopupOpen && (
        <Modal
          mode="info"
          title="Предупреждение"
          isOpen={isInfoPopupOpen}
          onClose={onCloseInfoPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Ок',
              onClick: onCloseInfoPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onCloseInfoPopup,
            },
          ]}
          size="small"
        >
          <>Партнёр на освное этой организации уже создан. Вы перенаправлены на форму редактирования</>
        </Modal>
      )}

      <Modal
        mode="help"
        title="Малые инновационные предприятия, созданные с участием вуза"
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        size={['large', 'medium-height']}
      >
        {PartnerMipGridHelp()}
      </Modal>

      {isPreAddFormOpen && <AddMip isOpen={isPreAddFormOpen} onClose={onPreAddClose} onSubmit={onPreAddSubmit} />}
    </>
  );
};

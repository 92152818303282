import React, { useCallback, useMemo } from 'react';

import { ButtonProps, FormComponent, ListEdit, TextArea, TextAreaMode } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { EventProject, EventTypeCode } from 'types/models/Event';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';
import { Color } from 'constants/colors';
import { useAppDataContext } from 'features/AppData/context';
import { EventType } from 'utils/Enums';

type Props = {
  workMode: Table.WorkMode;
  projects: EventProject[];
  setProjects: (rows: EventProject[]) => void;
  relatedTableState: Table.State | undefined;
  eventType: EventTypeCode;
  isSingle?: boolean;
};

function Projects({ eventType, workMode, projects, relatedTableState, setProjects, isSingle = false }: Props) {
  const { currentPerson } = useAppDataContext();

  const { isProfile } = usePrivatePageContext();

  const toolbarEnabled: boolean = useMemo(() => workMode === 'editMode' || workMode === 'addMode', [workMode]);

  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<EventProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => EventProject>(row => {
    return {
      id: null,
      project: {
        id: row.id,
        number: row.Number,
        name: row.Name,
        program: row.Program,
        leader: row.Leader,
        governances: row.governance,
        financings: row.financing,
      },
      accepted: '',
      acceptedBy: null,
    };
  }, []);

  const setProjectAccept = useCallback(
    (projectId: string, isAccept: boolean) => {
      const projectIndex = projects.findIndex(projectItem => projectItem.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        accepted: isAccept ? new Date().toLocaleString().replace(',', '') : '',
        acceptedBy: isAccept && currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null,
      };
      setProjects([...projects]);
    },
    [currentPerson?.fullName, currentPerson?.id, projects, setProjects],
  );

  const onChange = useCallback(
    (row: EventProject | null) => {
      if (row) {
        setProjects([row]);
      }
    },
    [setProjects],
  );

  const acceptRule = useMemo(() => {
    const rec: Record<EventType, Permits> = {
      [EventType.CONCERT]: Permits.CONCERT_SCIENCE_PROJECT_ACCEPTING,
      [EventType.CONTEST]: Permits.CONTEST_SCIENCE_PROJECT_ACCEPTING,
      [EventType.CONFERENCE]: Permits.CONFERENCE_SCIENCE_PROJECT_ACCEPTING,
      [EventType.EXPOSITION]: Permits.EXPOSITION_SCIENCE_PROJECT_ACCEPTING,
    };
    return rec[eventType];
  }, [eventType]);

  const acceptButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'like', color: Color.success },
        title: 'Принять к отчету',
        onClick: () => {
          if (projects.length && !project?.accepted) {
            setProjectAccept(project?.project?.id || '', true);
          }
        },
        permission: { name: acceptRule },
        isHidden: isProfile,
        isDisabled: !(projects.length && !project?.accepted),
      },
      {
        icon: { type: 'dislike', color: Color.danger },
        title: 'Отменить принятие к отчету',
        onClick: () => {
          if (projects.length && !!project?.accepted) {
            setProjectAccept(project?.project?.id || '', false);
          }
        },
        permission: { name: acceptRule },
        isHidden: isProfile,
        isDisabled: !(projects.length && !!project?.accepted),
      },
    ],
    [acceptRule, isProfile, projects.length, project?.accepted, project?.project?.id, setProjectAccept],
  );

  const getIsEditProjectButtonDisabled = useCallback((row: EventProject | null) => !!row?.accepted, []);
  const getIsDeleteProjectButtonDisabled = useCallback((row: EventProject | null) => !!row?.accepted, []);

  return (
    <>
      {isSingle ? (
        <FormComponent.Line>
          <FormComponent.Field label="Сведение о проекте">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Научные проекты',
                externalButtons: acceptButtons,
                visibleStatus: !!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : '',
                table: {
                  specification: specifications,
                  onSelect: (row: Table.Entry | null) => (row ? onChange(modalTableRowConverter(row)) : setProjects([])),
                },
              }}
              value={project?.project?.name}
              isDisabled={!toolbarEnabled || !!project?.accepted}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      ) : (
        <ListEdit
          rows={projects}
          onChange={rows => setProjects(rows)}
          toolbar={[
            'add',
            { key: 'edit', isDisabled: getIsEditProjectButtonDisabled },
            { key: 'delete', isDisabled: getIsDeleteProjectButtonDisabled },
            {
              key: 'accept',
              onClick: row => setProjectAccept(row?.project?.id || '', true),
              isHidden: isProfile,
              permission: acceptRule,
            },
            {
              key: 'decline',
              onClick: row => setProjectAccept(row?.project?.id || '', false),
              isHidden: isProfile,
              permission: acceptRule,
            },
          ]}
          columns={[
            {
              label: 'Номер проекта',
              formatValue: row => {
                return row.project?.number || '<em>Не указано</em>';
              },
            },
            { label: 'Название проекта', formatValue: row => row.project?.name || '<em>Не указано</em>' },
            { label: 'Программа/Тип гранта', formatValue: row => row.project?.program || '<em>Не указано</em>' },
            { label: 'Рук-ль, отв. исполнитель', formatValue: row => row.project?.leader || '<em>Не указано</em>' },
            { label: 'Факультет/Институт', formatValue: row => row.project?.governances || '<em>Не указано</em>' },
            { label: 'Финансирование', formatValue: row => row.project?.financings || '<em>Не указано</em>' },
            { label: 'Принят к отчету', formatValue: getAcceptInfo || '<em>Не указано</em>' },
          ]}
          maxHeight="none"
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Научные проекты',
            modalTableSpecification: specifications,
          }}
          isDisabled={!toolbarEnabled}
        />
      )}
    </>
  );
}

export { Projects };

import { Table as T } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetParticipationFeedlineList = (
  templatesTableDependencies: T.TemplatesTableDependencies,
  workMode: T.WorkMode,
): T.Specification<{}> => {
  const toolbarEnabled: boolean = workMode === 'editMode';

  return {
    apiID: 'GetParticipationFeedlineList',
    templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: toolbarEnabled ? LeftPanelForThirdLevel : undefined,
      },
    },
  };
};

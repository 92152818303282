import { useCallback } from 'react';

import { SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Member } from 'features/SelectPerson';

type Props = {
  author: SecurityDocumentMember | null;
  onAuthorChange(row: SecurityDocumentMember): void;
};

export function useController({ author, onAuthorChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Member | null) => {
      onAuthorChange({
        academicRank: value?.academicRank ?? null,
        citizenship: value?.citizenship ?? null,
        contribution: 0,
        degree: value?.degree ?? null,
        education: value?.education ?? null,
        rank: value?.rank ?? null,
        type: { label: 'Автор', value: SecurityDocumentMemberTypes.AUTHOR },
        id: author?.id ?? null,
        job: value?.job ?? null,
        name: value?.person?.fullName ?? '',
        person: value?.person ?? null,
        historyLabel: value?.historyLabel || '',
        contractNumber: '',
        contractDate: '',
        contractPortion: '',
        contractNote: '',
        enterprise: null,
      });
    },
    [author, onAuthorChange],
  );
  const handleNumberChange = useCallback(
    (fieldName: keyof SecurityDocumentMember) => (value: number) => {
      onAuthorChange({ ...author, [fieldName]: value } as SecurityDocumentMember);
    },
    [author, onAuthorChange],
  );
  return {
    handlePersonChange,
    handleNumberChange,
  };
}

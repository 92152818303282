import React, { useEffect, useState } from 'react';

import { ButtonMode, Modal, TextArea, TextInput, TextInputMode, FormComponent } from 'components';

import { showErrors } from 'utils/Validators';
import { Payment } from 'types/models';
import { Term } from 'features/IncentivePayments/model';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(term: Term): void;
  termPreset: Payment.TermPreset | null;
};

export function TermPresetModal(props: Props) {
  const { isOpen, onClose, onSubmit, termPreset } = props;

  useEffect(() => {
    setFields(prev => ({
      ...prev,
      regulationName: termPreset?.regulationName ?? '',
      regulationParagraph: termPreset?.regulationParagraph ?? '',
    }));
  }, [termPreset]);

  const [fields, setFields] = useState<Term>({
    regulationParagraph: '',
    regulationName: '',
    maxNomineesMoney: '0',
    maxFundMoney: '0',
  });

  const changeField = (key: keyof Term, val: string) => {
    setFields(prevFields => ({ ...prevFields, [key]: val }));
  };

  const handleSubmit = () => {
    const validationInfo = validateForm(fields);
    if (validationInfo.some(x => !x.isValid)) {
      showErrors(validateForm, fields);
      return;
    }
    onSubmit(fields);
  };

  return (
    <Modal
      title="Изменение условий оплаты"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Изменить',
          onClick: handleSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="large"
    >
      <FormComponent.Line>
        <FormComponent.Field label="Пункт положения">
          <TextInput value={fields.regulationParagraph} onChange={value => changeField('regulationParagraph', value)} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Название" isRequired>
          <TextArea
            settings={{ isFixed: true }}
            value={fields.regulationName}
            onChange={value => changeField('regulationName', value)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма номинантам, руб:" isRequired>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={fields.maxNomineesMoney}
            onChange={value => changeField('maxNomineesMoney', value)}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Сумма фонда, руб:">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={fields.maxFundMoney}
            onChange={value => changeField('maxFundMoney', value)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </Modal>
  );
}

function validateForm(fields: Record<string, string>) {
  const { regulationName, maxFundMoney, maxNomineesMoney } = fields;
  const isValidRegulationName = Boolean(regulationName);
  const isValidMaxNomineesMoney = Boolean(maxNomineesMoney);
  const isValidMaxFundMoney = Boolean(maxFundMoney);

  return [
    { isValid: isValidRegulationName, invalidMessage: 'Не указано название условия оплаты' },
    { isValid: isValidMaxNomineesMoney, invalidMessage: 'Не указана сумма по номинантам' },
    { isValid: isValidMaxFundMoney, invalidMessage: 'Заполните сумму фонда' },
  ];
}

import { showNotification } from 'features/Notifications';
import { useCallback, useState, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form } from 'types/models';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

type Props = {
  publicationId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const useController = ({ publicationId, onClose, onSuccess }: Props) => {
  const { methods: getPublication } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublication } = BackendAPI.useBackendAPI('SavePublication');

  const [isLoading, setIsLoading] = useState(false);
  const [isRelationsErrorPopupOpened, setIsRelationsErrorPopupOpened] = useState(false);
  const [magazinePublication, setMagazinePublication] = useState<Form.Publication | null>(null);
  const [magazineArticlePublication, setMagazineArticlePublication] = useState<Form.Publication | null>(null);
  const [isMagazineOriginal, setIsMagazineOriginal] = useState(false);
  const [isMagazineTranslate, setIsMagazineTranslate] = useState(false);

  const [magazinePublicationTranslates, setMagazinePublicationTranslates] = useState<Form.Translation[]>([]);
  const [magazinePublicationOriginal, setMagazinePublicationOriginal] = useState<Form.Original | null>(null);

  const [magazineArticlePublicationTranslates, setMagazineArticlePublicationTranslates] = useState<Form.Translation[]>([]);
  const [magazineArticlePublicationOriginal, setMagazineArticlePublicationOriginal] = useState<Form.Original | null>(null);

  const submitForm = useCallback(() => {
    if (magazineArticlePublication === null) {
      return;
    }

    setIsLoading(true);
    savePublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          type: magazineArticlePublication.type,
          status: magazineArticlePublication.status.status,
          isElectronic: magazineArticlePublication.fields.isElectronic === 'true',
          electronicType: magazineArticlePublication.fields.electronicType,
          typeEdition: magazineArticlePublication.fields.typeEditionCode,
          name: clearWhitespaceCharacters(magazineArticlePublication.fields.name),
          startPage: magazineArticlePublication.fields.startPage,
          endPage: magazineArticlePublication.fields.endPage,
          udk: magazineArticlePublication.fields.udk,
          printPageCount: magazineArticlePublication.fields.printPageCount,
          annotation: magazineArticlePublication.fields.annotation,
          authorsKeyWords: magazineArticlePublication.fields.authorKeywords,
          authorsCount: magazineArticlePublication.fields.authorsCount,
          fractionalCount: magazineArticlePublication.fields.fractionalScore,
          electronicMediaSource: magazineArticlePublication.fields.electronicMediaSource,
          electronicSystemRequirements: magazineArticlePublication.fields.electronicSystemRequirements,
          url: magazineArticlePublication.fields.url,
          urlDate: magazineArticlePublication.fields.urlDate,
          doi: magazineArticlePublication.fields.doi,
          articleNumber: magazineArticlePublication.fields.articleNumber,
          foreignName: magazineArticlePublication.fields.foreignName,
          foreignAnnotation: magazineArticlePublication.fields.foreignAnnotation,
          foreignAuthorsKeyWords: magazineArticlePublication.fields.foreignAuthorsKeyWords,
          foreignAuthors: magazineArticlePublication.fields.foreignAuthors,
        },
        arrayFields: {
          translations: { translation: magazineArticlePublicationTranslates },
        },
        attrIdFields: {
          textLanguage: magazineArticlePublication.fields.textLanguageId,
          grnti: magazineArticlePublication.fields.grntiId,
          study: magazineArticlePublication.fields.studyId,
          original: magazineArticlePublicationOriginal?.id,
          magazine: magazineArticlePublication.magazine?.id,
          magazineRelease: magazineArticlePublication.magazineRelease?.id,
        },
        projects: magazineArticlePublication.projects,
        mobileRequests: { mobileRequest: magazineArticlePublication.mobileRequests },
        authors: magazineArticlePublication.members,
        file: magazineArticlePublication.file,
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Публикация успешно сохранена', theme: 'success' });
          setIsLoading(false);
          onSuccess();
          onClose();
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, [
    onSuccess,
    magazineArticlePublication,
    magazineArticlePublicationOriginal?.id,
    magazineArticlePublicationTranslates,
    onClose,
    publicationId,
    savePublication,
  ]);

  useLayoutEffect(() => {
    setIsLoading(true);
    getPublication.callAPI(
      {
        id: publicationId,
        simpleFields: {
          status: 'ADDED',
        },
        attrIdFields: {},
        arrayFields: {
          translations: { translation: [] },
          events: { event: [] },
        },
        projects: [],
      },
      {
        onSuccessfullCall: (magazineArticlePublicationDataResult: any) => {
          const magazineArticlePublicationData = magazineArticlePublicationDataResult.data as Form.Publication;
          setMagazineArticlePublication(magazineArticlePublicationData);
          if (magazineArticlePublicationData.magazine?.id) {
            getPublication.callAPI(
              {
                id: magazineArticlePublicationData.magazine.id,
                simpleFields: {
                  status: 'ADDED',
                },
                attrIdFields: {},
                arrayFields: {
                  translations: { translation: [] },
                  events: { event: [] },
                },
                projects: [],
              },
              {
                onSuccessfullCall: (magazinePublicationDataResult: any) => {
                  const magazinePublicationData = magazinePublicationDataResult.data as Form.Publication;
                  setIsLoading(false);
                  setMagazinePublication(magazinePublicationData);
                  const isTranslate = !!magazinePublicationData.original?.id;
                  const isOriginal = !!magazinePublicationData.translations.length;
                  if (!isOriginal && !isTranslate) {
                    setIsRelationsErrorPopupOpened(true);
                  } else if (isOriginal) {
                    setIsMagazineOriginal(isOriginal);
                    setMagazineArticlePublicationTranslates(magazineArticlePublicationData.translations);
                    setMagazinePublicationTranslates(magazinePublicationData.translations);
                  } else if (isTranslate) {
                    setIsMagazineTranslate(isTranslate);
                    setMagazineArticlePublicationOriginal(magazineArticlePublicationData.original);
                    setMagazinePublicationOriginal(magazinePublicationData.original);
                  }
                },
                onFailedCall: () => {
                  setIsLoading(false);
                },
              },
            );
          } else {
            showNotification({ message: 'У публикации не указан источник', theme: 'danger' });
          }
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
  }, []); // eslint-disable-line

  return {
    submitForm,
    isLoading,
    isRelationsErrorPopupOpened,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    magazinePublicationTranslates,
    magazinePublication,
    magazineArticlePublication,
    magazineArticlePublicationTranslates,
    magazineArticlePublicationOriginal,
    setMagazineArticlePublicationTranslates: setMagazineArticlePublicationTranslates as (
      translations: Form.Translation[] | null,
    ) => void,
    setMagazineArticlePublicationOriginal: setMagazineArticlePublicationOriginal as (original: Form.Original | null) => void,
  };
};

export default useController;

import React, { useState } from 'react';
import { NavLink, useRouteMatch } from 'react-router-dom';
import { setup } from 'bem-cn';

import * as TS from './types';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('menu');

type Props = {
  entries: TS.Entry[];
};

export const Menu = React.memo(({ entries }: Props) => {
  const [openedEntryID, setOpenedEntryID] = useState<string | null>(null);

  const match = useRouteMatch<{ activeEntry: string }>('/:activeEntry/');

  const renderEntry = (entry: TS.Entry) => {
    const { isAccepted, kind, routeElement, label } = entry;
    if (isAccepted === false) {
      return null;
    }
    switch (kind) {
      case 'terminal': {
        const url = `/${routeElement}`;
        return (
          <NavLink
            key={routeElement}
            to={url}
            className={b('entry').toString()}
            activeClassName={b('entry', { active: true }).toString()}
            onClick={() => setOpenedEntryID(null)}
            onMouseDownCapture={() => {
              setOpenedEntryID(null);
              window.location.hash = url;
            }}
          >
            {label}
          </NavLink>
        );
      }
      case 'with-subentries': {
        return (
          <div
            key={routeElement}
            className={b('entry', {
              active: match?.params.activeEntry === routeElement,
            })}
            onClick={() => setOpenedEntryID(routeElement)}
            tabIndex={-1}
          >
            {label}
          </div>
        );
      }
    }
  };

  const makeSubentryRenderer = (parentEntry: TS.EntryWithSubEntries<TS.SubEntry>) => (subentry: TS.SubEntry) => {
    const { routeElement: parentRouteElement } = parentEntry;
    const { isAccepted, label, routeElement: subRouteElement } = subentry;

    if (isAccepted === false) {
      return null;
    }

    const url = `/${parentRouteElement}/${subRouteElement}`;

    return (
      <div key={subRouteElement} className={b('subentry')}>
        <NavLink
          to={url}
          className={b('subentry-link').toString()}
          activeClassName={b('subentry-link', { active: true }).toString()}
          onClick={() => setOpenedEntryID(null)}
          onMouseDownCapture={() => {
            setOpenedEntryID(null);
            window.location.hash = url;
          }}
        >
          {label}
        </NavLink>
      </div>
    );
  };

  const renderSubentries = () => {
    if (openedEntryID !== null) {
      const openedEntry = entries.find(x => x.routeElement === openedEntryID);

      if (openedEntry === undefined) {
        console.warn('internal error, could no find opened entry '); // eslint-disable-line
        return null;
      }

      const { kind } = openedEntry;

      if (kind !== 'with-subentries') {
        return null;
      }

      const { linesPerColumn, label, subEntries = [] } = openedEntry;

      return (
        <div className={b('subentries', { 'line-4': linesPerColumn === 4 })} tabIndex={-1}>
          <h2 className={b('subentries-header')}>{label}</h2>
          <div className={b('subentries-list')}>{subEntries.map(makeSubentryRenderer(openedEntry))}</div>
        </div>
      );
    }
  };

  return (
    <nav className={b()}>
      <div className={b('content')}>
        {entries.map(renderEntry)}
        {renderSubentries()}
      </div>
    </nav>
  );
});

import { useContext } from 'react';

import { FormContext, Context } from './context';

export function useFormContext<SpecificLook = object>() {
  const context = useContext<Context<SpecificLook> | null>(FormContext);

  if (context === null) {
    throw Error('no context provided');
  }

  return context;
}

import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {
  setProgramId(programId: string): void;
};

type Props = {
  setProgramId(programId: string): void;
};

export function makeUseCustomController({ setProgramId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setProgramId(selectedRows[0]?.id);
    }, [selectedRows]);
    return {
      setProgramId,
    };
  };
}

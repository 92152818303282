import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Form, ScientificIndex } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';

export function useController() {
  const [selectedValueRowIndex, setSelectedValueRowIndex] = useState<number | null>(null);
  const [selectedChildRowIndex, setSelectedChildRowIndex] = useState<number | null>(null);
  const [values, setValues] = useState<ScientificIndex.ScientificField[]>([]);
  const [childs, setChilds] = useState<ScientificIndex.ScientificIndex[]>([]);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);

  const [formFields, setFormFields] = useState<Form.Fields>({
    citationSystem: {
      value: '',
      isValid: true,
      required: true,
      title: 'Система цитирования',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          citationSystem: { ...prevState.citationSystem, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          citationSystem: { ...prevState.citationSystem, isValid: true },
        }));
      },
    },
    domain: {
      value: '',
      isValid: true,
      required: true,
      title: 'Объект',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          domain: { ...prevState.domain, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          domain: { ...prevState.domain, isValid: true },
        }));
      },
    },
    code: {
      value: '',
      isValid: true,
      required: true,
      title: 'Код',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          code: { ...prevState.code, isValid: true },
        }));
      },
    },
    name: {
      value: '',
      isValid: true,
      required: true,
      title: 'Наименование',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          name: { ...prevState.name, isValid: true },
        }));
      },
    },
    label: {
      value: '',
      isValid: true,
      required: true,
      title: 'Краткое обозначение',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          label: { ...prevState.label, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          label: { ...prevState.label, isValid: true },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, isValid: true },
        }));
      },
    },
  });

  const tableStreams = useLocalTableStreams();

  const {
    look: { id, relatedTableState, disableUpdateAfterSubmit },
  } = useFormContext();

  const { methods: getScientificIndex } = BackendAPI.useBackendAPI('GetScientificIndex', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as ScientificIndex.ScientificIndex;
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        citationSystem: {
          ...prevState.citationSystem,
          value: preparedData.citationSystem.label,
        },
        domain: {
          ...prevState.domain,
          value: preparedData.domain.label,
        },
        code: {
          ...prevState.code,
          value: preparedData.code,
        },
        name: {
          ...prevState.name,
          value: preparedData.name,
        },
        label: {
          ...prevState.label,
          value: preparedData.label,
        },
        note: {
          ...prevState.note,
          value: preparedData.note,
        },
      }));
      setValues(preparedData.values);
      setChilds(preparedData.childs);
    },
  });

  const { methods: saveScientificIndex } = BackendAPI.useBackendAPI('SaveScientificIndex', {
    onSuccessfullCall: ({ data }) => {
      if (data.Response._attributes.success)
        showNotification({ message: 'Наукометрический показатель успешно сохранен', theme: 'success' });
      if (!disableUpdateAfterSubmit) tableStreams.reloadTable.push({});
    },
  });

  const onSubmit = useCallback(() => {
    if (id && formFields.name.value && formFields.label.value) {
      saveScientificIndex.callAPI({
        id,
        name: formFields.name.value,
        label: formFields.label.value,
        note: formFields.note.value,
      });
    } else showNotification({ theme: 'danger', message: 'Заполните обязательные поля' });
  }, [formFields.label.value, formFields.name.value, formFields.note.value, id, saveScientificIndex]);

  useLayoutEffect(() => {
    if (id) getScientificIndex.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    scientificIndexId: id ?? null,
    relatedTableState,
    formFields,
    selectedValueRowIndex,
    setSelectedValueRowIndex,
    selectedChildRowIndex,
    setSelectedChildRowIndex,
    onSubmit,
    values,
    childs,
    isEditFormOpen,
    setIsEditFormOpen,
    getScientificIndex,
  };
}

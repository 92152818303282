import React from 'react';

import { FormComponent, Tabs, Tab, TextArea, TextAreaMode, TextDateTime, Select } from 'components';

import { Documents } from 'features/Documents';
import { GetDissertationCouncilList } from 'features/Table/specifications';
import { SelectPerson, Member } from 'features/SelectPerson';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { CommunityType } from 'utils/Enums';
import { Table } from 'types/models';
import { Item } from 'types/models/common';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function CommunityForm({ viewMode, editMode, onClose }: Props) {
  const {
    communityMember,
    handleFormSubmit,
    roleOptions,
    cooperationTypeOptions,
    handleCooperationTypeFieldChange,
    handleDescriptionFieldChange,
    handleJoinDateFieldChange,
    handleLeaveDateFieldChange,
    handlePersonFieldChange,
    handleRoleFieldChange,
    communityRowConverter,
    handleCommunityFieldChange,
    handleDocumentsFieldChange,
    isScientificCommunity,
    workMode,
    settings,
    allowedCommunityTypes,
    memberEditLocked,
    communityTypeLabel,
    preferredCommunityType,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const member: Member = {
    person: communityMember.person || null,
    job: communityMember.job || null,
    education: communityMember.education || null,
    citizenship: communityMember.citizenship || null,
    degree: communityMember.degree || null,
    rank: communityMember.rank || null,
    academicRank: communityMember.academicRank || null,
    historyLabel: communityMember.historyLabel,
  };

  const isDissertationCouncil = preferredCommunityType === CommunityType.DISSERTATION_COUNCIL;

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            <FormComponent.Wrapper>
              <FormComponent.Line>
                <FormComponent.Field label={communityTypeLabel} isRequired>
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: communityTypeLabel,
                      isClearable: false,
                      table: {
                        specification: GetDissertationCouncilList({
                          communityTypes: allowedCommunityTypes,
                          hasSelectButton: true,
                          isMembersTabHidden: true,
                          enumFilterValues: {
                            enumName: 'CommunityType',
                            excludeValues: [],
                          },
                        }),
                        onSelect: async (row: Table.Entry | null) => {
                          if (!row) return;

                          const result = await communityRowConverter(row);
                          handleCommunityFieldChange(result);
                        },
                      },
                    }}
                    value={
                      communityMember.community
                        ? [
                            communityMember.community?.type?.label ?? '-',
                            communityMember.community?.code ?? '-',
                            communityMember.community?.magazine?.name ?? '',
                          ]
                            .filter(Boolean)
                            .join(' ')
                        : ''
                    }
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Организация">
                  <div>
                    {communityMember.community?.isLocal
                      ? settings?.organization?.shortName
                      : communityMember.community?.affiliation?.partner?.id
                      ? [
                          communityMember.community.affiliation.partner.fullName,
                          communityMember.community.affiliation.partner.country?.label,
                          communityMember.community.affiliation.partner.city,
                        ]
                          .filter(Boolean)
                          .join(', ')
                      : communityMember.community?.affiliation?.enterprise?.id
                      ? [
                          communityMember.community.affiliation.enterprise.label,
                          communityMember.community.affiliation.enterprise.customFields?.country,
                          communityMember.community.affiliation.enterprise.customFields?.city,
                        ]
                          .filter(Boolean)
                          .join(', ')
                      : communityMember.community?.affiliation?.enterpriseCustomer?.id
                      ? [
                          communityMember.community.affiliation.enterpriseCustomer.label,
                          communityMember.community.affiliation.enterpriseCustomer.customFields?.country,
                          communityMember.community.affiliation.enterpriseCustomer.customFields?.city,
                        ]
                          .filter(Boolean)
                          .join(', ')
                      : '-'}
                  </div>
                </FormComponent.Field>
              </FormComponent.Line>
              {!!isScientificCommunity && (
                <FormComponent.Line>
                  <FormComponent.Field label="Предмет сотрудничества" isRequired>
                    <Select
                      value={communityMember.cooperationType}
                      options={cooperationTypeOptions as Item[]}
                      onChange={handleCooperationTypeFieldChange}
                      isDisabled={workMode === 'viewMode'}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <FormComponent.Line>
                <FormComponent.Field label="Персона" isRequired>
                  <SelectPerson
                    member={member}
                    onUpdateMember={handlePersonFieldChange}
                    hiddenButtons={['delete']}
                    withHistory={true}
                    memberLabel={[member.person?.fullName, member.degree?.refDegree?.label, member.rank?.refRank?.label]
                      .filter(Boolean)
                      .join(', ')}
                    disabled={workMode === 'viewMode' || memberEditLocked}
                  />
                  <div className="hint-text" style={{ lineHeight: '18px' }}>
                    Должность: {member.job?.refAppointment?.label || <em>Не указана</em>}
                  </div>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Роль" isRequired>
                  <Select
                    value={communityMember.role}
                    options={roleOptions}
                    onChange={handleRoleFieldChange}
                    isDisabled={!communityMember.community || workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата ввода в состав" isRequired>
                  <TextDateTime
                    value={communityMember.joinDate}
                    onChange={handleJoinDateFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата вывода из состава">
                  <TextDateTime
                    value={communityMember.leaveDate}
                    onChange={handleLeaveDateFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field
                  label="Краткое описание"
                  tooltip="Укажите специальность участника (по какому направлению специализируется участник)"
                >
                  <TextArea
                    settings={{ rows: 3 }}
                    value={communityMember.description ?? ''}
                    onChange={handleDescriptionFieldChange}
                    isDisabled={workMode === 'viewMode'}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Wrapper>
          </Tab>
          <Tab title="Документы">
            <FormComponent.Description mode="warning">{`Прикрепите документ, подтверждающий участие в ${
              isDissertationCouncil ? 'диссертационном совете' : 'работе сообщества'
            }: скан-копию приказа об основании ${
              isDissertationCouncil ? 'совета' : 'сообщества'
            } или скриншот сайта`}</FormComponent.Description>

            <Documents
              documents={communityMember.documents || []}
              setDocuments={handleDocumentsFieldChange}
              disabled={workMode === 'viewMode'}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CommunityForm);

import React from 'react';

import { Tab, Tabs, TextArea, TextAreaMode, FormComponent } from 'components';

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { sortStages } from 'features/Form/looks/project/ProjectForm/helpers';
import { IndicatorKey, EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';
import { EditableIndicators } from './EditIndicators';
import { ProjectType } from 'utils/Enums';
import {
  GetProjectBaseDissertationList,
  GetProjectParticipationEventList,
  GetProjectPublicationList,
  GetProjectSecurityDocumentList,
} from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  project: Project.Project | null;
  indicators: EditableIndicator[];
  indices: EditableIndicator[];
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
  disabled: boolean;
};

export function ResultsProject(props: Props) {
  const { project, indicators, indices, makeChangeHandler, disabled } = props;

  const projectType = project?.type?.value!;

  const { userPermission } = useAppDataContext();

  const stages = project?.stages ?? [];

  const getTitle = (type: IndicatorKey) =>
    sortStages(stages)
      .slice(0, 7)
      .map(x => `Этап ${x.number} - ${x[type].length} шт`)
      .join(', ');

  const intellectualPropertyViewMap: Record<ProjectType, string> = {
    NIR: 'ResearchJobProjectIntellectualPropertyView',
    GRANT: 'GrantProjectIntellectualPropertyView',
    GOVERMENT_CONTRACT: 'GovContractProjectIntellectualPropertyView',
    ECONOMIC_AGREEMENT: 'EconomicContractProjectIntellectualPropertyView',
  };

  const isVisibleIntellectualProperty = isHasPermission(userPermission, intellectualPropertyViewMap[projectType]);

  return (
    <Tabs>
      <Tab title="Индикаторы и показатели">
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <EditableIndicators
              title={`Индикаторы результативности. ${getTitle('indicators')}`}
              type="indicators"
              disabled={disabled}
              stages={project?.stages ?? []}
              setStages={makeChangeHandler('stages')}
              program={project?.program ?? null}
              indices={indicators}
            />
          </FormComponent.Column>
          <FormComponent.Column>
            <EditableIndicators
              title={`Показатели результативности. ${getTitle('indices')}`}
              type="indices"
              disabled={disabled}
              stages={project?.stages ?? []}
              setStages={makeChangeHandler('stages')}
              program={project?.program ?? null}
              indices={indices}
            />
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </Tab>
      <Tab title="Публикации">
        <DataGrid specification={GetProjectPublicationList({ projectId: project?.id || '-1' })} />
      </Tab>
      <Tab title="Диссертации и авторефераты">
        <DataGrid specification={GetProjectBaseDissertationList({ projectId: project?.id || '-1' })} />
      </Tab>
      <Tab title="Мероприятия и участие в мероприятиях">
        <DataGrid specification={GetProjectParticipationEventList({ projectId: project?.id || '-1' })} />
      </Tab>
      {isVisibleIntellectualProperty && (
        <Tab title="Интеллектуальная собственность">
          <DataGrid specification={GetProjectSecurityDocumentList({ projectId: project?.id || '-1' })} />
        </Tab>
      )}
      <Tab title="Описание результатов">
        <FormComponent.Line>
          <FormComponent.Field label="Результаты проекта">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 10, title: 'Результаты проекта' }}
              value={project?.result ?? ''}
              onChange={makeChangeHandler('result')}
              isDisabled={!!disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Tab>
    </Tabs>
  );
}

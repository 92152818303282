import React, { memo, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  onClose: () => void;
  isOpen: boolean;
};

const MonographAddTemplate = ({ isOpen, onClose }: Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    apiID: 'GetMonograph',
    template: PublicationTypes.MONOGRAPH.template,
    type: PublicationTypes.MONOGRAPH.code,
    initialTypeEdition: 'OTHER_UNIVERSITY',
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'large-height']}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = memo(MonographAddTemplate);

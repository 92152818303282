import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';
import { IdItem } from 'types/models/common';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  estimateId: string;
  viewMode?: boolean;
  handleRefreshEstimate?: Function;
  isFiltersHidden?: boolean;
  fundCard?: IdItem;
};

export const GetEstimatePositionList = (requestData: Props): Table.Specification<State> => ({
  apiID: 'GetEstimatePositionList',
  requestData,
  isFiltersHidden: requestData.isFiltersHidden,
  header: {
    // firstLevel: {
    //   title: 'Расходы по статьям затрат',
    // },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel(requestData),
    },
  },
  highlightRow: (row: Table.Entry) => (!row.EMPTY_ROW && !row.id ? Color.danger : ''),
  highlightCell: (cell: Table.Cell) => {
    // Остаток
    if (cell.column.id === 'Remain' && Number(cell.value || '0') < 0) {
      return Color.danger;
    }
    // План-Факт
    if (cell.column.id === 'PlanMinusFactAmount' && Number(cell.value || '0') < 0) {
      return Color.danger;
    }
    return '';
  },
  useCustomController: makeUseCustomController({ fundCard: requestData?.fundCard }),
});

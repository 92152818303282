import { useState, useCallback, useEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceFilter, ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { Table } from 'types/models';
import { PublicationCitationSystem } from 'types/models/CitationSystem';
import { validate } from './validate';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Mode, State } from 'features/Table/specifications/GetPublicationCitationSystemList/makeUseCustomController';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  onClose: () => void;
  customState: State;
  mode: Mode;
  relatedTableState?: Table.State;
  setTitle: React.Dispatch<string | JSX.Element> | undefined;
};

export function useController({ onClose, customState, mode, relatedTableState, setTitle }: Props) {
  const { templatesTableDependencies, citationSystemType, addCitationSystem, editCitationSystem } = customState;

  const tableStreams = useLocalTableStreams();

  const relatedRecordId =
    templatesTableDependencies.relatedRecordId || templatesTableDependencies.relatedTableState.selectedRows[0]?.id || '-1';
  const relatedTableSelectedRow = templatesTableDependencies.relatedTableState.selectedRows[0];

  const publicationYear = relatedTableSelectedRow?.PublicationYear || '';

  const emptyReference = useMemo<ReferenceItem>(() => ({ id: '', label: '' }), []);

  const getMock = useCallback(
    () => ({
      publicationID: relatedRecordId,
      publicationCitationSystemID: '',
      citationSystem: emptyReference,
      identifier: '',
      url: '',
      name: '',
      sourceType: emptyReference,
      periods: [],
      thematicCategories: [],
      keyWords: '',
      citationSystemType,
    }),
    [citationSystemType, emptyReference, relatedRecordId],
  );

  const [publicationCitationSystem, setPublicationCitationSystem] = useState<PublicationCitationSystem>(getMock());
  const [settingPeriodSingle, setSettingPeriodSingle] = useState<string>(publicationYear);

  const { methods: getPublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI('GetPublicationCitationSystem', {
    onSuccessfullCall: data => {
      setPublicationCitationSystem({ ...getMock(), ...data.data, publicationID: relatedRecordId, citationSystemType });
    },
  });

  const { methods: savePublicationCitationSystemAPIMethods } = BackendAPI.useBackendAPI('SavePublicationCitationSystem', {
    onSuccessfullCall: data => {
      if (mode === 'add') {
        addCitationSystem({
          ...data.input.citationSystem,
          publicationCitationSystemId: data.data.Response?.Id?._text || '',
          thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
        });
      } else {
        editCitationSystem({
          ...data.input.citationSystem,
          publicationCitationSystemId: data.data.Response?.Id?._text || '',
          thematicCategories: data.input.thematicCategories.map(({ value, name }) => ({ id: value.id, label: name })),
        });
      }

      tableStreams.reloadTable.push({});

      showNotification({ message: 'Запись успешно сохранена', theme: 'success' });

      onClose();
    },
  });

  useEffect(() => {
    const tableStateRow = relatedTableState?.selectedRows[0];

    const actions: Record<Mode, string> = {
      add: 'Создание',
      edit: 'Редактирование',
      view: 'Просмотр',
    };

    setTitle?.(
      getStatusMessage(
        [actions[mode], 'записи "Индексация в базах цитирования"'].filter(Boolean).join(' '),
        mode === 'add' ? '' : tableStateRow?.id || '',
      ),
    );

    if (mode !== 'add' && tableStateRow) {
      getPublicationCitationSystemAPIMethods.callAPI({
        publicationCitationSystemID: tableStateRow.id,
        sourceID: tableStateRow.SourceId,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCitationSystem = useCallback(
    (value: ReferenceItem) => {
      setPublicationCitationSystem(prevState => ({
        ...prevState,
        citationSystem: value,
        sourceType: emptyReference,
      }));
    },
    [emptyReference],
  );

  const handleIdentifierFieldChange = useCallback((value: string) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      identifier: value,
    }));
  }, []);

  const handleSourceTypeFieldChange = useCallback((value: ReferenceItem) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      sourceType: value,
    }));
  }, []);

  const handleUrlFieldChange = useCallback((value: string) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      url: value,
    }));
  }, []);

  const handleKeyWordsFieldChange = useCallback((value: string) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      keyWords: value,
    }));
  }, []);

  const handleNameFieldChange = useCallback((value: string) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      name: value,
    }));
  }, []);

  const handlePeriodsFieldChange = useCallback((value: any) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      periods: value,
    }));
  }, []);

  const handleThematicCategoriesFieldChange = useCallback((value: any) => {
    setPublicationCitationSystem(prevState => ({
      ...prevState,
      thematicCategories: value,
    }));
  }, []);

  const saveForm = useCallback(() => {
    if (!validate(publicationCitationSystem, settingPeriodSingle, publicationYear)) {
      return;
    }

    const { periods, ...params } = publicationCitationSystem;

    savePublicationCitationSystemAPIMethods.callAPI({
      ...params,
      ...(citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID'
        ? {
            periods: citationSystemType === 'MAGAZINE' ? periods : [{ id: '', startYear: settingPeriodSingle, endYear: '' }],
          }
        : {}),
    });
  }, [
    publicationCitationSystem,
    settingPeriodSingle,
    publicationYear,
    savePublicationCitationSystemAPIMethods,
    citationSystemType,
  ]);

  const sourceTypeFilterItem: ReferenceFilter = {
    key: 'citationSystem',
    values: [publicationCitationSystem.citationSystem?.id] as string[],
  };
  const sourceTypeFilter: ReferenceFilter[] = [sourceTypeFilterItem];

  const isSource = useMemo(() => citationSystemType === 'MAGAZINE' || citationSystemType === 'PARENTAL_GRID', [
    citationSystemType,
  ]);

  return {
    publicationCitationSystem,
    saveForm,
    isSource,
    sourceTypeFilter,
    handleChangeCitationSystem,
    handleIdentifierFieldChange,
    handleSourceTypeFieldChange,
    handleUrlFieldChange,
    handleKeyWordsFieldChange,
    handleNameFieldChange,
    handlePeriodsFieldChange,
    settingPeriodSingle,
    setSettingPeriodSingle,
    handleThematicCategoriesFieldChange,
  };
}

import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { CustomProps } from '../..';

const CommunityViewTemplate: React.FC<F.TemplateProps & CustomProps> = ({
  isOpen,
  onClose,
  id,
  name,
  relatedTableState,
  templateName,
  communityTypes,
  isApproved,
  isMembersTabHidden,
}: F.TemplateProps & CustomProps) => {
  const look: F.CommunityFormLook = {
    apiID: 'GetCommunity',
    template: templateName,
    viewMode: true,
    id,
    relatedTableState,
    communityTypes,
    isMembersTabHidden,
  };

  const title = (() => {
    const details = [id ? `ID: ${id}` : '', `Статус: ${isApproved ? 'Утверждено' : 'Добавлено'}`].filter(Boolean).join(', ');

    return ['Просмотр', name ? `"${name}"` : '', details ? `(${details})` : ''].filter(Boolean).join(' ');
  })();

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size={['extralarge', 'large-height']} isViewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityViewTemplate);

import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table, Form } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';
import { getMockProjectAssignment } from './helpers';
import { ProjectAssignment, ProjectAssignmentStatus, Project } from 'types/models/Project';
import { GetPersonalProjectList } from 'features/Table/specifications';
import { Original } from 'types/models/Form';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode: initialViewMode, editMode: initialEditMode, onClose }: Props) {
  const {
    look: { id, projectId },
  } = useFormContext<Form.ProjectAssignmentFormLook>();

  const [newStatus, setNewStatus] = useState<ProjectAssignmentStatus>('');
  const [isMessageListShown, setIsMessageListShown] = useState<boolean>(true);
  const [isChangeStatusModalOpen, setIsChangeStatusModalOpen] = useState(false);
  const [projectAssignment, setProjectAssignment] = useState<ProjectAssignment>(getMockProjectAssignment());

  const [project, setProject] = useState<Project | null>(null);

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode: initialViewMode, editMode: initialEditMode });

  const disabled = useMemo(() => {
    return workMode === 'viewMode';
  }, [workMode]);

  const tableStreams = useLocalTableStreams();
  const { methods: saveProjectAssignment } = BackendAPI.useBackendAPI('SaveProjectAssignment');
  const { methods: getProjectAssignment } = BackendAPI.useBackendAPI('GetProjectAssignment');
  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject');
  const { methods: changeProjectAssignmentStatus } = BackendAPI.useBackendAPI('ChangeProjectAssignmentStatus');

  const makeChangeHandler = useCallback(
    (key: keyof ProjectAssignment) => (value: ValueOf<ProjectAssignment> | Original) => {
      setProjectAssignment(prevProjectAssignment => ({ ...prevProjectAssignment!, [key]: value }));
    },
    [],
  );

  const loadProject = useCallback(
    (projId: string) => {
      getProject.callAPI(
        { id: projId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject(data);
            makeChangeHandler('projectId')(data.id);
          },
        },
      );
    },
    [getProject, makeChangeHandler],
  );

  const handleProjectChange = useCallback(
    (value: Original | null) => {
      if (value?.id) {
        loadProject(value?.id);
      } else {
        setProject(null);
        makeChangeHandler('projectId')(null);
      }
    },
    [makeChangeHandler, loadProject],
  );

  const loadProjectAssignment = useCallback(
    (projectAssignmentId: string) => {
      getProjectAssignment.callAPI(
        { id: projectAssignmentId },
        {
          onSuccessfullCall: ({ data }) => {
            setProjectAssignment(data);
            handleProjectChange({ id: data.projectId, name: '' });
          },
        },
      );
    },
    [getProjectAssignment, handleProjectChange],
  );

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(projectAssignment).filter(m => !m.isValid);
      if (errors && errors.length > 0) {
        const message = `${errors.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
        showNotification({ message, theme: 'danger' });
        return false;
      }
      saveProjectAssignment.callAPI(
        {
          ...projectAssignment,
          id: id || projectAssignment.id || null,
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Поручение успешно сохранено', theme: 'success' });
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              loadProjectAssignment(data.id);
              updateWorkModeAfterSaveAndContinue();
            }
          },
        },
      );
    },
    [
      projectAssignment,
      id,
      onClose,
      saveProjectAssignment,
      tableStreams,
      updateWorkModeAfterSaveAndContinue,
      loadProjectAssignment,
    ],
  );

  const projectSpecification = GetPersonalProjectList({ hasSelectButton: true, hasOnlyLeaders: true });

  const projectTableRowConverter = useCallback<(row: Table.Entry) => Original>(row => {
    return {
      id: row.id || '',
      name: row.Name,
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      if (projectAssignment.id) {
        changeProjectAssignmentStatus.callAPI(
          { status: newStatus, message: message || '', ids: [projectAssignment.id] },
          {
            onSuccessfullCall: () => {
              setNewStatus('');
              setIsChangeStatusModalOpen(false);
              if (projectAssignment.id) {
                loadProjectAssignment(projectAssignment.id);
              }
              setIsMessageListShown(true);
            },
          },
        );
      }
    },
    [changeProjectAssignmentStatus, newStatus, projectAssignment, loadProjectAssignment],
  );

  const onChangeStatusClick = useCallback((nextStatus: ProjectAssignmentStatus) => {
    setNewStatus(nextStatus);
    setIsChangeStatusModalOpen(true);
  }, []);

  const isChangeStatusInActionDisabled = useMemo(
    () => disabled || !projectAssignment?.id || projectAssignment.status?.value === ProjectAssignmentStatus.IN_ACTION,
    [projectAssignment, disabled],
  );

  const isChangeStatusCompleteDisabled = useMemo(
    () => disabled || !projectAssignment?.id || projectAssignment.status?.value === ProjectAssignmentStatus.COMPLETE,
    [projectAssignment, disabled],
  );

  // :TODO use isLeader check here
  const isChangeStatusClosedDisabled = useMemo(
    () => disabled || !projectAssignment?.id || projectAssignment.status?.value === ProjectAssignmentStatus.CLOSED,
    [projectAssignment, disabled],
  );

  // :TODO use isLeader check here
  const isChangeStatusCanceledDisabled = useMemo(
    () => disabled || !projectAssignment?.id || projectAssignment.status?.value === ProjectAssignmentStatus.CANCELED,
    [projectAssignment, disabled],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSubmit(true),
        isDisabled: disabled,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSubmit(false),
        isDisabled: disabled,
      },
      {
        icon: { type: 'clock' },
        title: 'В работе',
        onClick: () => onChangeStatusClick(ProjectAssignmentStatus.IN_ACTION),
        isDisabled: isChangeStatusInActionDisabled,
      },
      {
        icon: { type: 'check' },
        title: 'Готово',
        onClick: () => onChangeStatusClick(ProjectAssignmentStatus.COMPLETE),
        isDisabled: isChangeStatusCompleteDisabled,
      },
      {
        icon: { type: 'cancel' },
        title: 'Закрыть поручение',
        onClick: () => onChangeStatusClick(ProjectAssignmentStatus.CLOSED),
        isDisabled: isChangeStatusClosedDisabled,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отменить поручение',
        onClick: () => onChangeStatusClick(ProjectAssignmentStatus.CANCELED),
        isDisabled: isChangeStatusCanceledDisabled,
      },
    ],
    [
      disabled,
      onSubmit,
      onChangeStatusClick,
      isChangeStatusCanceledDisabled,
      isChangeStatusClosedDisabled,
      isChangeStatusCompleteDisabled,
      isChangeStatusInActionDisabled,
    ],
  );

  useLayoutEffect(() => {
    if (projectId) {
      loadProject(projectId);
    }
    if (id) {
      loadProjectAssignment(id);
    }
    // eslint-disable-next-line
  }, []);

  return {
    projectAssignmentId: id ?? null,
    projectAssignment,
    project,
    buttons,
    workMode,
    makeChangeHandler,
    projectSpecification,
    projectTableRowConverter,
    handleProjectChange,
    isChangeStatusModalOpen,
    setIsChangeStatusModalOpen,
    handleSaveStatusClick,
    newStatus,
    isMessageListShown,
  };
}

import { Modal } from 'components';
import React, { useMemo, useState } from 'react';
import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const PublicationEditTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const selectedType = useMemo(() => (relatedTableState?.selectedRows.length ? relatedTableState.selectedRows[0]?.Type : ''), [
    relatedTableState?.selectedRows,
  ]);
  const selectedBibliographic = useMemo(
    () => (relatedTableState?.selectedRows.length ? relatedTableState.selectedRows[0]?.BibliographicRecord : ''),
    [relatedTableState?.selectedRows],
  );

  const modalTitle = useMemo(() => `${selectedType}${selectedBibliographic ? `: ${selectedBibliographic}` : ''}`, [
    selectedType,
    selectedBibliographic,
  ]);

  const look: F.Look = {
    apiID: 'GetPublication',
    template: 'LibraryIdForm',
    type: selectedType,
    title: modalTitle,
    id,
    relatedTableState,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(PublicationEditTemplate);

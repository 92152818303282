import {
  CollectionItem,
  CollectionValue,
  Entity,
  EnumerationValue,
  Field,
  FieldType,
  FieldValue,
  Merge,
  RelationType,
} from 'types/models/Merge';
import { convertMaybeArray, convertReference, getAttribute, getBoolean, getId, getLabel, getText } from '../commonConverters';

export const convertMetaField = (field: any): Field => {
  const name = getAttribute<string>({ item: field, attributeKey: 'name' })!;
  const type = getAttribute<FieldType>({ item: field, attributeKey: 'type' })!;
  const caption = getAttribute<string>({ item: field, attributeKey: 'caption' }) ?? '';
  const isEditable = getAttribute<string>({ item: field, attributeKey: 'editable' }) === 'true';
  const isForceEditable = getAttribute<string>({ item: field, attributeKey: 'forceEditable' }) === 'true';
  const isRequired = getAttribute<string>({ item: field, attributeKey: 'required' }) === 'true';
  const isConflicted = getAttribute<string>({ item: field, attributeKey: 'conflicted' }) === 'true';
  const enumeration = getAttribute<string>({ item: field, attributeKey: 'enumeration' });
  const reference = getAttribute<string>({ item: field, attributeKey: 'reference' });
  const relationType = getAttribute<RelationType>({ item: field, attributeKey: 'relationType' })!;

  const preparedIsRequired = type === FieldType.COLLECTION ? isConflicted : isRequired;

  return {
    name,
    type,
    caption,
    isEditable,
    isForceEditable,
    isRequired: preparedIsRequired,
    isConflicted,
    enumeration,
    reference,
    relationType,
    value: null,
  };
};

const convertEnumerationValue = (field: any): EnumerationValue => ({
  label: getLabel(field),
  value: getText(field),
});

export const convertFieldValue = ({ field, fieldType }: { field: any; fieldType: FieldType }): FieldValue => {
  switch (fieldType) {
    case FieldType.BOOLEAN:
      return getBoolean(field);
    case FieldType.REFERENCE:
      return field?.Reference ? convertReference(field.Reference) : null;
    case FieldType.ENUMERATION:
      return field?.Enumeration ? convertEnumerationValue(field.Enumeration) : null;
    case FieldType.COLLECTION:
      const collectionValue: CollectionValue = {
        analogId: null,
        selectedIds: [],
      };
      return collectionValue;
    case FieldType.DATE:
    case FieldType.INTEGER:
    case FieldType.STRING:
    case FieldType.TEXT:
      return getText(field);
    default:
      return null;
  }
};

export const convertMetaFields = (metaFields: any): Field[] => convertMaybeArray(metaFields, convertMetaField);

export const mapMetadataToField = ({ fields, metadata }: { fields: any; metadata: Field[] }): Field[] =>
  metadata.map(metaField => {
    const currentField = (Array.isArray(fields) ? fields : [fields]).find((field: any) => {
      const fieldName = getAttribute({ item: field, attributeKey: 'name' })!;
      return metaField.name === fieldName;
    })!;
    return {
      ...metaField,
      value: convertFieldValue({ field: currentField, fieldType: metaField.type }),
      collection:
        currentField?.Collection?.CollectionItem &&
        convertMaybeArray(currentField?.Collection?.CollectionItem, convertCollectionItem),
    };
  });

export const convertEntity = ({ entity, metadata }: { entity: any; metadata: Field[] }): Entity => ({
  id: getId(entity),
  index: parseInt(getAttribute({ item: entity, attributeKey: 'index' }) || '0', 10) || 0,
  fields: mapMetadataToField({ fields: entity?.Field, metadata }),
});

export const convertCollectionItem = (collectionItem: any): CollectionItem => ({
  id: getId(collectionItem),
  caption: getAttribute({ item: collectionItem, attributeKey: 'caption' }) || '',
});

export const convertMerge = (response: any): Merge => {
  const merge = response?.Response?.Merge;
  const metadata = convertMetaFields(merge?.Metadata?.Field);
  const entities = convertMaybeArray(merge?.Entity, entity => convertEntity({ entity, metadata }));
  const common = mapMetadataToField({ fields: merge?.Common?.Field, metadata });
  const isConflicts = getAttribute({ item: merge, attributeKey: 'conflicts' }) === 'true';

  return {
    commonId: entities[0]?.id ?? null,
    isConflicts,
    metadata,
    common,
    entities,
  };
};

import { useState, useMemo, useCallback, useLayoutEffect } from 'react';

import { ReferenceItem } from 'components';

import { Parameter } from 'types/models';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const [referenceItem, setReferenceItem] = useState<ReferenceItem | null>(null);

  const customSelectParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameterName, parameters],
  );

  const canBuild = useMemo<boolean>(() => !!customSelectParameter, [customSelectParameter]);

  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      setReferenceItem(nextReferenceItem);
      setParameterValueByName({ name: parameterName, value: nextReferenceItem });
    },
    [parameterName, setParameterValueByName],
  );

  useLayoutEffect(() => {
    const isRef = !!(customSelectParameter?.value as ReferenceItem)?.id;
    if (isRef) setReferenceItem(customSelectParameter?.value as ReferenceItem);
  }, [customSelectParameter?.value]);

  return { referenceItem, handleSetReferenceItem, customSelectParameter, canBuild };
};

export default useController;

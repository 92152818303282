export enum ProjectModule {
  MAIN = 'MAIN',
  CUSTOMERS = 'CUSTOMERS',
  DEPARTMENTS = 'DEPARTMENTS',
  FEATURES = 'FEATURES',
  FINANCING = 'FINANCING',
  DESCRIPTION = 'DESCRIPTION',
  REPORTS = 'REPORTS',
  STATE_REGISTRATION = 'STATE_REGISTRATION',
  RESTRICTION = 'RESTRICTION',
  PERFORMERS = 'PERFORMERS',
  PARTNERS = 'PARTNERS',
  STAGES = 'STAGES',
  PATENT_RESEARCHES = 'PATENT_RESEARCHES',
  RESULTS = 'RESULTS',
  ACTS = 'ACTS',
  DOCUMENTS = 'DOCUMENTS',
  PROJECT_CODES = 'PROJECT_CODES',
  SECURITY_DOCUMENTS = 'SECURITY_DOCUMENTS',
  CHANGES_IN_PROJECT = 'CHANGES_IN_PROJECT',
  CONTEST_REQUESTS = 'CONTEST_REQUESTS',
  PURCHASE_REQUESTS = 'PURCHASE_REQUESTS',
}

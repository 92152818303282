import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Equip } from 'types/models';
import { getEnum } from 'utils/Helpers';

import { getMockEquip } from '../EquipForm/helpers';

type Props = {
  viewMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, onClose }: Props) {
  const {
    look: { id: initialId, setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const [equip, setEquip] = useState<Equip.Equip>(getMockEquip());
  const [isSaveLoad, setIsSaveLoad] = useState<boolean>(false);
  const [isVerificationWarningOpen, setIsVerificationWarningOpen] = useState<boolean>(false);
  const [isItemsWarningOpen, setIsItemsWarningOpen] = useState<boolean>(false);

  const statusOptions = getEnum('EquipStatus');

  const { methods: getEquip } = BackendAPI.useBackendAPI('GetEquip', {
    onSuccessfullCall: ({ data }) => {
      setEquip(data);
    },
  });

  const { methods: saveEquipVerification } = BackendAPI.useBackendAPI('SaveEquipVerification');

  const onSubmit = useCallback(
    (saveLoad?: boolean) => {
      saveEquipVerification.callAPI(equip, {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Запись сохранена', theme: 'success' });
          if (saveLoad || isSaveLoad) {
            getEquip.callAPI({ id: data.id });
            return;
          }
          tableStreams.reloadTable.push({});
          onClose();
        },
      });
    },
    [equip, getEquip, isSaveLoad, onClose, saveEquipVerification, tableStreams.reloadTable],
  );

  const saveButtonClick = useCallback(
    (saveLoad?: boolean) => {
      if (!saveLoad) {
        if (
          !equip.equipKind &&
          !equip.measureKind &&
          !equip.measureLimit &&
          !equip.measureScaleAmount &&
          !equip.permissibleInaccuracy &&
          !equip.interval &&
          !equip.verificationEnterprise &&
          !equip.price
        ) {
          setIsVerificationWarningOpen(true);
          setIsSaveLoad(!!saveLoad);
          return;
        } else if (!equip.verificationItems.length) {
          setIsItemsWarningOpen(true);
          return;
        }
      }
      onSubmit(saveLoad);
    },
    [
      equip.equipKind,
      equip.interval,
      equip.measureKind,
      equip.measureLimit,
      equip.measureScaleAmount,
      equip.permissibleInaccuracy,
      equip.price,
      equip.verificationEnterprise,
      equip.verificationItems.length,
      onSubmit,
    ],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => saveButtonClick(),
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => saveButtonClick(true),
      },
    ],
    [saveButtonClick],
  );

  useEffect(() => {
    const id = initialId || equip.id;
    const idInfo = id ? `(ID ${id})` : '';
    const statusInfo = equip.status?.label ? `Статус: ${equip.status?.label}` : '';
    setTitle?.(['Метрологическое сопровождение: ', equip.name, idInfo, statusInfo].filter(Boolean).join(' '));
  }, [equip.id, equip.name, equip.status?.label, initialId, setTitle, viewMode]);

  useLayoutEffect(() => {
    if (initialId) getEquip.callAPI({ id: initialId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    equip,
    setEquip,
    buttons,
    statusOptions,
    userPermission,
    isVerificationWarningOpen,
    setIsVerificationWarningOpen,
    isItemsWarningOpen,
    setIsItemsWarningOpen,
    onSubmit,
  };
}

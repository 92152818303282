import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Filters } from './filters';

type RequestData = {
  publicationId: string;
};

export function GetParticipationForPublicationList(requestData: RequestData): Table.Specification {
  return {
    apiID: 'GetParticipationForPublicationList',
    header: {
      firstLevel: {
        title: 'Публикации',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: true,
      },
    },
    requestData,
  };
}

import React, { memo } from 'react';

import { Table as T } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { Projects } from 'features/Form/looks/event/views/Projects';
import { EventProject } from 'types/models/Event';

type Props = {
  projects: EventProject[];
  setProjects: (docs: EventProject[]) => void;
  workMode: T.WorkMode;
  relatedTableState: T.State | undefined;
};

const FinancialSupport = ({ projects, setProjects, workMode, relatedTableState }: Props) => {
  return (
    <Projects
      eventType={EventTypes.EVENT_CONCERT.code}
      projects={projects}
      workMode={workMode}
      setProjects={setProjects}
      relatedTableState={relatedTableState}
    />
  );
};

export const Component = memo(FinancialSupport);

import React, { useMemo } from 'react';

import { FormComponent, SectionTitle, Tab, Tabs } from 'components';

import { Partner } from 'types/models';
import { ValueOf } from 'types/helpers';

import { UpperFields } from './UpperFields';
import { CodesFields } from './CodesFields';
import { AddressAndDetails } from './AddressAndDetails';
import { ChildPartners } from './ChildPartners';
import { PartnershipTypes } from './PartnershipTypes';
import { CitationSystems } from './CitationSystems';

type Props = {
  partner: Partner | null;
  setPartner: React.Dispatch<Partner | null>;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  disabled: boolean;
};

export function AboutOrganization(props: Props) {
  const { setPartner, makeChangeHandler, partner, disabled } = props;

  const variantRelationChilds = useMemo(() => (partner?.childs || []).filter(x => x.relation?.value === 'VARIANT') ?? [], [
    partner?.childs,
  ]);

  const otherRelationChilds = useMemo(() => (partner?.childs || []).filter(x => x.relation?.value !== 'VARIANT') ?? [], [
    partner?.childs,
  ]);

  return (
    <>
      <UpperFields setPartner={setPartner} disabled={disabled} partner={partner} makeChangeHandler={makeChangeHandler} />

      <FormComponent.Line>
        <Tabs>
          <Tab title="Коды организации">
            <CodesFields disabled={disabled} partner={partner} makeChangeHandler={makeChangeHandler} />
          </Tab>
          <Tab title="Адрес и контакты">
            <AddressAndDetails disabled={disabled} partner={partner} makeChangeHandler={makeChangeHandler} />
          </Tab>
          <Tab title="Идентификаторы в базах цитирования">
            <CitationSystems
              citationSystems={partner?.citationSystems ?? []}
              setCitationSystems={makeChangeHandler('citationSystems')}
              disabled={disabled}
            />
          </Tab>
          {!partner?.parent && (
            <Tab title="Связи с другими организациями">
              <SectionTitle title="Список дочерних организаций и филиалов" />

              <ChildPartners
                disabled={disabled}
                childsPartners={otherRelationChilds}
                allChildsPartners={partner?.childs ?? []}
                setChildPartners={otherChilds => makeChangeHandler('childs')([...otherChilds, ...variantRelationChilds])}
                mainPartner={partner}
              />
            </Tab>
          )}
          {!partner?.parent && (
            <Tab title="Варианты наименования">
              <SectionTitle title="Список вариантов наименования организации-партнёра в базе данных" />

              <ChildPartners
                isOnlyVariant
                disabled={disabled}
                childsPartners={variantRelationChilds}
                allChildsPartners={partner?.childs ?? []}
                setChildPartners={variantChilds => makeChangeHandler('childs')([...otherRelationChilds, ...variantChilds])}
                mainPartner={partner}
              />
            </Tab>
          )}
          <Tab title="Типы партнёрства" isRequired>
            <SectionTitle title="История сотрудничества организации и университета" />

            <PartnershipTypes
              disabled={disabled}
              partnerships={partner?.partnershipTypes ?? []}
              setPartnerships={makeChangeHandler('partnershipTypes')}
            />
          </Tab>
        </Tabs>
      </FormComponent.Line>
    </>
  );
}

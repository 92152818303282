import React, { memo } from 'react';

import { Form as F, Author, Table } from 'types/models';

import { AboutAuthorship } from 'features/Form/views/AboutAuthorship';
import { FormComponent, SectionTitle } from 'components';

type Props = {
  formFields: F.Fields;
  workMode: Table.WorkMode;
  authors: Author[];
  setAuthors(authors: Author[]): void;
};

const Authors = ({ workMode, formFields, authors, setAuthors }: Props) => {
  return (
    <FormComponent.Wrapper>
      <SectionTitle
        title={`Сведения об авторстве - ${authors.filter(a => a.category?.customFields?.code === 'author')?.length}`}
        isRequired={true}
      />

      <AboutAuthorship
        language={formFields.textLanguage.value}
        authors={authors}
        disabled={workMode === 'viewMode'}
        setAuthors={setAuthors}
        isHistoryPersonDisabled={workMode !== 'editMode'}
        filters={['author', 'supervisor', 'adviser', 'translator', 'opponent']}
      />
    </FormComponent.Wrapper>
  );
};

export const AuthorsComponent = memo(Authors);

import React, { memo, useCallback, useMemo } from 'react';

import { ReferenceItem, FormComponent, Select } from 'components';

import { Item } from 'types/models/common';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  userDepartments: ReferenceItem[];
};

const SelectUserDepartments = ({ referenceItem, setReferenceItem, fieldLabel, userDepartments }: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextDepartmentLabel: string) => {
      const nextEnumValue = userDepartments.find(({ id }) => id === nextDepartmentLabel);
      if (nextEnumValue) {
        setReferenceItem(nextEnumValue);
      }
    },
    [setReferenceItem, userDepartments],
  );

  const selectValue: Item | null = useMemo(() => {
    if (!referenceItem) {
      return null;
    }
    const { id, label, ...others } = referenceItem;
    return {
      value: id,
      label,
      ...others,
    };
  }, [referenceItem]);

  const selectList: Item[] = useMemo(() => {
    if (!userDepartments) {
      return [];
    }
    return userDepartments.map(i => {
      const { id, label, ...others } = i;
      return {
        value: id,
        label,
        ...others,
      };
    });
  }, [userDepartments]);

  return (
    <FormComponent.Line>
      <FormComponent.Field label={fieldLabel} isRequired>
        <Select value={selectValue} options={selectList} onChange={(option: Item) => handleSetReferenceItem(option.value)} />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(SelectUserDepartments);

export { Component as SelectUserDepartments };

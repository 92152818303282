export type State = {
  userId: string;
};

type Props = {
  userId: string;
};

export function makeUseCustomController({ userId }: Props) {
  return function useCustomController(): State {
    return {
      userId,
    };
  };
}

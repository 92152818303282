import React from 'react';

import { DataGrid } from 'features/Table';

import { Person } from 'types/models';
import { Tabs, Tab } from 'components';

import { GetScientistRecognitionList } from 'features/Table/specifications/GetScientistRecognitionList';
import { GetScientistDissertationCouncilMemberList } from 'features/Table/specifications';
import { GetScientistCommunityMemberList } from 'features/Table/specifications/GetScientistCommunityMemberList';
import { CommunityType } from 'utils/Enums';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
};

export function Recognitions(props: Props) {
  const { person } = props;

  if (!person) {
    return null;
  }

  return (
    <Tabs>
      <Tab title="Признания и награды">
        <DataGrid specification={GetScientistRecognitionList({ personId: person.id })} />
      </Tab>
      <Tab title="Работа в составе диссертационных советов">
        <DataGrid specification={GetScientistDissertationCouncilMemberList({ personId: person.id })} />
      </Tab>
      <Tab title="Членство в научных сообществах и организациях">
        <DataGrid
          specification={GetScientistCommunityMemberList({
            personId: person.id,
            types: [
              CommunityType.SCIENTIFIC_COUNCIL,
              CommunityType.COLLABORATION,
              CommunityType.FUND,
              CommunityType.MAGAZINE_REDACTION,
            ],
          })}
        />
      </Tab>
    </Tabs>
  );
}

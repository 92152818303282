import { useEffect } from 'react';

import { Table } from 'types/models';

type Props = {
  setProjectCodeInfo(info: { id: string; year: string }): void;
};

export function makeUseCustomController({ setProjectCodeInfo }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps) {
    useEffect(() => {
      if (selectedRows.length) setProjectCodeInfo({ id: selectedRows[0]?.id, year: selectedRows[0]?.year });
      else setProjectCodeInfo({ id: '-1', year: '' });
    }, [selectedRows]);
    return {};
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Color } from 'constants/colors';

export const GetRecognitionList: Table.Specification = {
  apiID: 'GetRecognitionList',
  header: {
    firstLevel: {
      title: 'Признания и награды',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  highlightRow: row => (!!row.LibraryNote ? Color.danger : ''),
  isWithQna: true,
};

import { Author, FileInfo, Form } from 'types/models';
import { PublicationProject } from 'types/models/Form';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

type Props = {
  isElectronic: boolean | undefined;
  electronicType: string | undefined;
  formFields: Form.Fields;
  projects: PublicationProject[];
  mobileRequests: Form.PublicationMobileRequest[];
  type: string | undefined;
  authors: Author[];
  status: string;
  sourceMonograph: Form.Compilation | null;
};

const formatToRequest = ({
  isElectronic,
  electronicType,
  formFields,
  projects,
  mobileRequests,
  type,
  authors,
  status,
  sourceMonograph,
}: Props) => ({
  simpleFields: {
    type: type?.toUpperCase() || '',
    status,
    typeEdition: 'LOCAL',
    isElectronic: !!isElectronic,
    electronicType: (!!isElectronic && electronicType) || '',
    name: clearWhitespaceCharacters(formFields.name.value || ''),
    udk: formFields.udk.value as string,
    startPage: formFields.pageFrom.value || '',
    endPage: formFields.pageTo.value || '',
    printPageCount: formFields.printPageCount.value || undefined,
    annotation: formFields.annotation.value || '',
    authorsKeyWords: formFields.authorsKeyWords.value || '',
    authorsCount: formFields.authorsCount.value || '',
    fractionalCount: formFields.fractionalScore.value || '',
    electronicMediaSource: formFields.electronicMediaSource.value as string,
    electronicSystemRequirements: formFields.electronicSystemRequirements.value as string,
    url: formFields.publicationLink.value as string,
    doi: formFields.doi.value as string,
    urlDate: formFields.date.value as string,
    chapterNumber: formFields.chapterNumber.value as string,
    foreignName: formFields.foreignName.value || '',
    foreignAnnotation: formFields.foreignAnnotation.value || '',
    foreignAuthorsKeyWords: formFields.foreignAuthorsKeyWords.value || '',
    foreignAuthors: formFields.foreignAuthors.value || '',
  },
  attrIdFields: {
    grnti: formFields.grnti.value?.id,
    study: formFields.study.value?.id,
    textLanguage: formFields.textLanguage.value?.id,
    monograph: sourceMonograph ? sourceMonograph.id : '',
  },
  arrayFields: {
    translations: { translation: [] },
  },
  mobileRequests: { mobileRequest: mobileRequests },
  projects,
  authors,
  file: formFields.file.value as FileInfo,
});

export { formatToRequest };

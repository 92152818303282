import { useLayoutEffect, useMemo, useState, useRef, useCallback, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { calcReduceByParameter, groupByParameter } from '../helpers';
import { FormatedDataArray, ResponseData } from '../types';
import { Item as ItemOption } from 'types/models/common';

type Item = {
  name: string;
  data: number[];
};

export function useController() {
  const [financingByPeriod, setFinancingByPeriod] = useState<Item[]>([]);
  const [responseData, setResponseData] = useState<Array<FormatedDataArray[]>>([]);

  const yearslist = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const periodYearOpts = useRef(
    yearslist.map(yearItem => ({
      value: yearItem.toString(),
      label: `${yearItem} год`,
    })),
  );

  const [categories, setCategories] = useState<string[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeYearPeriodFilter, setActiveYearPeriodFilter] = useState<string>(new Date().getFullYear().toString());

  useEffect(() => {
    const groupedValueArray = responseData;
    const constructSummaryParameters = (indicator: FormatedDataArray[], year: number) =>
      calcReduceByParameter(indicator, year, 'FactAmount') ? calcReduceByParameter(indicator, year, 'FactAmount') / 1000 : 0;
    groupedValueArray.sort(
      (a, b) =>
        constructSummaryParameters(b, parseInt(activeYearPeriodFilter, 10)) -
        constructSummaryParameters(a, parseInt(activeYearPeriodFilter, 10)),
    );
    const categoriesList = groupedValueArray.map(value => value[0][0]._attributes.DepartmentAbbr);
    setCategories(categoriesList);
    setFinancingByPeriod([
      {
        name: '',
        data: categoriesList.map((_, index) =>
          constructSummaryParameters(groupedValueArray[index], parseInt(activeYearPeriodFilter, 10)),
        ),
      },
    ]);
  }, [responseData, activeYearPeriodFilter]);

  const { methods: GetDashboardChartByProgramIndicatorListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByProgramIndicatorListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const receivedData = data as ResponseData;
        const groupedValueArray: Array<FormatedDataArray[]> = groupByParameter(
          receivedData.Response.Grid.Rows.Row,
          'DepartmentAbbr',
        ).map(ind => groupByParameter(ind, 'Program'));
        setResponseData(groupedValueArray);
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);
    GetDashboardChartByProgramIndicatorListStatic.callAPI({
      rowQuantity: -1,
      byDepartment: true,
      offset: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeActiveYearFilter = useCallback(
    (option: ItemOption) => {
      setActiveYearPeriodFilter(option.value || '');
    },
    [setActiveYearPeriodFilter],
  );

  const activeYearFilter = useMemo(() => periodYearOpts.current.find(opt => opt.value === activeYearPeriodFilter), [
    periodYearOpts,
    activeYearPeriodFilter,
  ]);

  return {
    activeYearFilter,
    financingByPeriod,
    isLoading,
    handleChangeActiveYearFilter,
    periodYearOpts,
    categories,
  };
}

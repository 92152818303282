import { FormComponent, SectionTitle, TextInput } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
// eslint-disable-next-line max-len
import { Financings } from 'features/Form/looks/project/ProjectForm/Tabs/AllPerformers/Tabs/Partners/Partners/Financings';

type Props = {
  partner: Project.Partner;
  makeChangeHandler: (key: keyof Project.Partner) => (value: ValueOf<Project.Partner>) => void;
};

export function HeadRoleFields({ partner, makeChangeHandler }: Props) {
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Наименование работ">
          <TextInput value={partner.kindWork} onChange={makeChangeHandler('kindWork')} />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Финансирование" />
      <Financings financings={partner.financings} setFinancings={makeChangeHandler('financings')} />
    </>
  );
}

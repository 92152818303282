import { Event } from 'types/models';

export const convertPublicationParticipations = (publicationParticipation: Event.EventParticipation[]) => {
  return publicationParticipation
    .map(
      ({ eventFullName, id, participationStatus }) =>
        `${eventFullName} (Участие в конференции ID: ${id}, статус: ${participationStatus.label})`,
    )
    .join(', ');
};

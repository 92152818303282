import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { FileInfo, Table } from 'types/models';
import useController from './controller';
import { MessageModal } from 'features/Form/views/MessageModal';
import { DataGrid } from 'features/Table/container';
import { GetProjectAssignmentMessageList } from 'features/Table/specifications/GetProjectAssignmentMessageList';
import { State } from '../makeUseCustomController';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  tableState: Table.State;
  customState: State;
};

export const titleTextMap: Record<string, string> = {
  MESSAGE: 'Добавить сообщение',
  IN_ACTION: 'Взять в работу',
  COMPLETE: 'Готово',
  CLOSED: 'Закрыть поручение',
  CANCELED: 'Отменить поручение',
};

export const submitTextMap: Record<string, string> = {
  MESSAGE: 'Отправить',
  IN_ACTION: 'В работе',
  COMPLETE: 'Готово',
  CLOSED: 'Закрыть',
  CANCELED: 'Отменить',
};

export const Toolbar = React.memo(({ customState, tableState }: Props) => {
  const {
    isOpenMessageModal,
    newStatus,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    isHelpFormOpen,
    isMessageHistoryModalOpen,
    handleMessageHistoryModalClose,
    handleMessageModalClose,
  } = customState;

  const {
    buttons,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    handleTemplateCloseHelpForm,
    handleSaveStatusClick,
    handleSendMessageClick,
  } = useController({ customState, tableState });

  const submitsMap: Record<string, (message: string, file: FileInfo) => void> = {
    IN_ACTION: message => handleSaveStatusClick(message),
    COMPLETE: message => handleSaveStatusClick(message),
    CLOSED: message => handleSaveStatusClick(message),
    CANCELED: message => handleSaveStatusClick(message),
    MESSAGE: (message, file) => handleSendMessageClick(message, file),
  };

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <modalTemplates.ProjectAssignmentViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={onCloseViewForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      <modalTemplates.ProjectAssignmentEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={onCloseEditForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      <modalTemplates.ProjectAssignmentAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={onCloseAddForm}
        relatedTableState={tableState}
        projectId={customState.projectId}
      />

      {newStatus && (
        <MessageModal
          isOpen={isOpenMessageModal}
          onClose={handleMessageModalClose}
          onSubmit={submitsMap[newStatus.toString()]}
          titleText={titleTextMap[newStatus.toString()]}
          submitText={submitTextMap[newStatus.toString()]}
          hasFileAttachment={newStatus === 'MESSAGE'}
        />
      )}

      {tableState.selectedRows.length > 0 && (
        <>
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteWarningOpen}
            onClose={onCloseDeleteWarning}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: onSubmitDeleteWarning,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: onCloseDeleteWarning,
              },
            ]}
            size="small"
          >
            <>
              (дата: {tableState.selectedRows[0]?.AssignmentDate}, ответственные: {tableState.selectedRows[0]?.ProjectScientists})
              Удалить поручение?
            </>
          </Modal>

          <Modal
            isOpen={isMessageHistoryModalOpen}
            onClose={handleMessageHistoryModalClose}
            title="Журнал сообщений"
            size={['large', 'small-height']}
          >
            <DataGrid
              specification={GetProjectAssignmentMessageList({
                projectAssignmentId: tableState.selectedRows[0]?.id,
              })}
            />
          </Modal>
        </>
      )}

      <Modal
        mode="help"
        title="Особенности заполнения на вкладке: Поручения"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {ProjectHelp().assignments}
      </Modal>
    </>
  );
});

import React, { useCallback } from 'react';

import { ReferenceItem, Select, SelectMode, TextArea, TextAreaMode, TextInput, SectionTitle, FormComponent } from 'components';

import { Partner, Table } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetLksdEnterpriseTypeBySectorActivity } from 'features/Table/specifications';

type Props = {
  partner: Partner | null;
  makeChangeHandler: (key: keyof Partner) => (value: ValueOf<Partner>) => void;
  setPartner: React.Dispatch<Partner | null>;
  disabled: boolean;
};

export function UpperFields(props: Props) {
  const { setPartner, makeChangeHandler, partner, disabled } = props;

  const onChangePerson = useCallback(
    (member: Member) => {
      makeChangeHandler('manager')(member.person);
    },
    [makeChangeHandler],
  );

  const changeActivitySector = useCallback(
    (nextActivitySector: ReferenceItem | null) => {
      setPartner({
        ...partner!,
        activitySector: nextActivitySector,
        lksd: null,
      });
    },
    [partner, setPartner],
  );

  return (
    <>
      <SectionTitle title="Ответственные за ведение карточки организации-партнёра" />
      <FormComponent.Line>
        <FormComponent.Field
          label="Отв. менеджер"
          isRequired
          // eslint-disable-next-line
          tooltip="К ответственному менеджеру можно обращаться за дополнительной информацией по организации, например, запросить Соглашение о сотрудничестве"
        >
          <SelectPerson
            member={getMemberWithoutHistory(partner?.manager ?? null)}
            onUpdateMember={onChangePerson}
            hiddenButtons={['delete']}
            withHistory={false}
            disabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          label="Отв. подразделение"
          tooltip="Подразделение несёт ответственность за актуальность ключевых данных об организации- партнёре"
        >
          <Select
            mode={SelectMode.REFERENCE}
            value={partner?.department}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('department')(option)}
            settings={{ name: 'RefDepartment', title: 'Статусы организаций' }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Об организации" />
      {partner?.parent?.id && (
        <FormComponent.Line>
          <FormComponent.Field label="Головная организация">
            <FormComponent.Text>
              {partner.parent.fullName} (ID - {partner.parent.id}. {partner.relation?.label ?? ''})
            </FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field
          // eslint-disable-next-line max-len
          tooltip='Для иностранных организаций Вводите в формате "Сокращённое название организации на оригинальном языке (Сокращённое название на русском языке)'
          label="Краткое название"
        >
          <TextInput value={partner?.shortName ?? ''} onChange={makeChangeHandler('shortName')} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          isRequired
          // eslint-disable-next-line max-len
          tooltip='Для международных организаций Вводите в формате "Полное название организации на оригинальном языке (Полное название на русском языке)'
          label="Полное название"
        >
          <TextArea
            settings={{ isFixed: true }}
            value={partner?.fullName ?? ''}
            onChange={makeChangeHandler('fullName')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сектор деятельности">
          <Select
            mode={SelectMode.REFERENCE}
            value={partner?.activitySector}
            onChange={changeActivitySector}
            settings={{ name: 'RefSectorActivity', title: 'Сектор деятельности' }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип организации">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Типы организаций',
              table: {
                specification: GetLksdEnterpriseTypeBySectorActivity({ sectorActivityId: partner?.activitySector?.id ?? '' }),
                onSelect: (row: Table.Entry | null) =>
                  makeChangeHandler('lksd')(row ? { id: row?.id, label: row?.EnterpriseType } : null),
              },
            }}
            value={partner?.lksd?.label}
            isDisabled={disabled || !partner?.activitySector?.id}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Состояние">
          <Select
            mode={SelectMode.REFERENCE}
            value={partner?.state}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('state')(option)}
            settings={{ name: 'RefEnterpriseStatus', title: 'Состояния' }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

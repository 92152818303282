import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type WrappedProps = {
  projectId?: string;
};

type Props = {
  tableState: Table.State;
};

export const ToolbarWrapper = ({ projectId }: WrappedProps) =>
  memo(({ tableState }: Props) => {
    const {
      isViewFormOpen,
      isViewButtonDisabled,
      handleTemplateCloseViewForm,
      buttons,
      isReportOpen,
      onReportClose,
      currentReport,
      isHelpFormOpen,
      setIsHelpFormOpen,
    } = useController(tableState);

    return (
      <>
        <Toolbar buttons={buttons} />

        {!isViewButtonDisabled && (
          <modalTemplates.PublicationViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}

        {isReportOpen && (
          <BuildReportPopup
            isOpen={isReportOpen}
            onClose={onReportClose}
            reportName={currentReport?.name || ''}
            reportCaption={currentReport?.caption || ''}
            values={{
              projectId: projectId,
            }}
          />
        )}

        <Modal
          mode="help"
          title="Особенности заполнения на вкладке: Публикации"
          isOpen={isHelpFormOpen}
          onClose={() => setIsHelpFormOpen(false)}
          size="medium"
        >
          {ProjectHelp().resultsPublications}
        </Modal>
      </>
    );
  });

import React, { useState, useCallback, useMemo } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { DataGrid } from 'features/Table/container';
import { GetEstimatePositionExpenseDocumentList } from 'features/Table/specifications';
import * as modalTemplates from './modalTemplates';
import { State } from '../makeUseCustomController';
import { showNotification } from 'features/Notifications';

type WrapperProps = {
  estimateId: string;
  viewMode?: boolean;
  handleRefreshEstimate?: Function;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

const LeftPanelForThirdLevel = ({ estimateId, viewMode, handleRefreshEstimate = () => {} }: WrapperProps) =>
  React.memo(({ tableState, customState: { fundCard } }: Props) => {
    const tableStreams = useLocalTableStreams();
    const selectedRows = tableState.selectedRows;

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');
    const [isSalaryOpen, setIsSalaryOpen] = useState<boolean>(false);

    const { methods: getBalanceEstimatePosition } = BackendAPI.useBackendAPI('GetBalanceEstimatePosition');
    const { methods: deleteEstimatePosition } = BackendAPI.useBackendAPI('DeleteEstimatePosition');

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      deleteEstimatePosition.callAPI(
        { id: selectedRows[0]?.id },
        {
          onSuccessfullCall: () => {
            setIsDeleteConfirmPopupOpen(false);
            tableStreams.reloadTable.push({});
            handleRefreshEstimate();
          },
        },
      );
    }, [deleteEstimatePosition, selectedRows, tableStreams.reloadTable]);

    const handleBalanceClick = useCallback(() => {
      const ids = selectedRows.map(x => ({
        id: x.id || '',
        estimateId: '',
        kindConsumptionId: x.kindConsumptionId || '',
      }));
      getBalanceEstimatePosition.callAPI(
        {
          ids: ids.map(x => {
            x.estimateId = estimateId;
            return x;
          }),
        },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Сумма план, успешно обновлены', theme: 'success' });
            tableStreams.reloadTable.push({});
            handleRefreshEstimate();
          },
        },
      );
    }, [getBalanceEstimatePosition, selectedRows, tableStreams.reloadTable]);

    const buttons = useMemo<ButtonProps[]>(
      () => [
        ...(viewMode
          ? []
          : [
              {
                icon: { type: 'add' },
                title: 'Добавить',
                onClick: () => {
                  setNextPublicationName('');
                  setIsAddFormOpen(true);
                },
              },
              {
                icon: { type: 'edit' },
                title: 'Редактировать',
                onClick: () => setIsEditFormOpen(true),
                isDisabled: selectedRows.length !== 1,
              },
              {
                icon: { type: 'remove' },
                title: 'Удалить',
                onClick: () => setIsDeleteConfirmPopupOpen(true),
                isDisabled: selectedRows.length !== 1 || !selectedRows[0]?.id,
              },
              {
                icon: { type: 'currency' },
                title: 'Установить Сумма, план = Сумма, факт',
                onClick: handleBalanceClick,
                isDisabled: !selectedRows.length,
              },
              {
                icon: { type: 'list' },
                title: 'Список документов по статье расходов',
                onClick: () => setIsSalaryOpen(true),
                isDisabled: selectedRows.length !== 1,
              },
            ]),
      ],
      [handleBalanceClick, selectedRows],
    );

    return (
      <>
        <Toolbar buttons={buttons} />

        <modalTemplates.EstimatePositionEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
            handleRefreshEstimate();
          }}
          id={selectedRows[0]?.id}
          relatedTableState={tableState}
          estimateId={estimateId}
          fundCard={fundCard}
        />

        <modalTemplates.EstimatePositionAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={() => {
            setIsAddFormOpen(false);
            handleRefreshEstimate();
          }}
          relatedTableState={tableState}
          name={nextPublicationName}
          estimateId={estimateId}
          fundCard={fundCard}
        />

        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>({selectedRows[0]?.kindConsumption}) Удалить позицию?</>
        </Modal>

        <Modal
          isOpen={isSalaryOpen}
          onClose={() => setIsSalaryOpen(false)}
          title={selectedRows[0]?.kindConsumption}
          size={['extralarge', 'extralarge-height']}
        >
          <DataGrid
            specification={GetEstimatePositionExpenseDocumentList({
              estimateId,
              kindConsumptionId: selectedRows[0]?.kindConsumptionId,
            })}
          />
        </Modal>
      </>
    );
  });

export const Component = LeftPanelForThirdLevel;

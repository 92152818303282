import { MONTH_PERIOD_RADIO, QUARTER_PERIOD_RADIO } from './builders/PaymentRequestPeriod/helpers';

type PeriodKey = typeof QUARTER_PERIOD_RADIO | typeof MONTH_PERIOD_RADIO;

type BuildPaymentRequestPeriodQueryArgument = {
  periodKey: PeriodKey;
  period: Array<string>;
  year: string;
};

const buildPaymentRequestPeriodQuery = ({ periodKey, period, year }: BuildPaymentRequestPeriodQueryArgument) => {
  const periodSubKeyMap: Record<PeriodKey, string> = {
    quarter: 'quarters',
    month: 'months',
  };

  return `${periodKey}&Year=${year}&${periodSubKeyMap[periodKey]}=${period.join(',')}`;
};

const PARAMETER_TYPE = {
  STRING: 'String',
  INTEGER: 'Integer',
  BOOLEAN: 'Boolean',
};

export { PARAMETER_TYPE, buildPaymentRequestPeriodQuery };

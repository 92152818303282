import { CashDocument } from 'types/models';

export function getMockCashDocument(): CashDocument.CashDocument {
  return {
    id: null,
    isExpense: false,
    number: '',
    payDate: '',
    enterprise: null,
    enterpriseValue: '',
    foundation: null,
    items: [],
  };
}

export function getMockItem(): CashDocument.Item {
  return {
    id: null,
    projectCode: null,
    amount: '',
    kindConsumption: null,
    purpose: '',
    note: '',
  };
}

import { useCallback, useLayoutEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { UserActivationKind } from 'types/models/UserActivation';
import { showNotification } from 'features/Notifications';

export function useController() {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [activationKinds, setActivationKinds] = useState<UserActivationKind[]>([]);

  const { methods: getUserActivationSettings } = BackendAPI.useBackendAPI('GetUserActivationSettings');
  const { methods: saveUserActivationSettings } = BackendAPI.useBackendAPI('SaveUserActivationSettings');

  const loadUserActivationSettings = useCallback(() => {
    getUserActivationSettings.callAPI(
      {},
      {
        onSuccessfullCall: ({ data }) => {
          const kinds = Object.keys(data.kinds).map((key: string) => {
            data.kinds[key].key = key;
            return data.kinds[key];
          });
          setActivationKinds(kinds);
        },
      },
    );
  }, [getUserActivationSettings]);

  const handleActivationSettingsChange = useCallback(
    (value: UserActivationKind[]) => {
      const kinds: Record<string, UserActivationKind> = {};
      value?.forEach((setting: UserActivationKind) => {
        kinds[setting?.key] = setting;
      });
      saveUserActivationSettings.callAPI(
        { kinds },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Настройки сохранены', theme: 'success' });
            loadUserActivationSettings();
          },
        },
      );
    },
    [loadUserActivationSettings, saveUserActivationSettings],
  );

  useLayoutEffect(() => {
    loadUserActivationSettings();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    selectedRowIndex,
    setSelectedRowIndex,
    activationKinds,
    handleActivationSettingsChange,
  };
}

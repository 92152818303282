import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { MAX_DUPLICATES_COUNT, MIN_DUPLICATES_COUNT } from './helpers';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState(false);

  const handleMergeDuplicatesClick = useCallback(() => {
    setIsMergeDuplicatesOpen(true);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDuplicatesDisabled = useMemo(() => {
    const isMoreOrEqualThanMin = tableState.selectedRows.length >= MIN_DUPLICATES_COUNT;
    const isLessOrEqualThanMax = tableState.selectedRows.length <= MAX_DUPLICATES_COUNT;

    return !isMoreOrEqualThanMin || !isLessOrEqualThanMax;
  }, [tableState.selectedRows.length]);

  const selectedIds = useMemo(() => tableState.selectedRows.map(({ id }) => id), [tableState.selectedRows]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'group' },
        title: 'Объединение дубликатов',
        onClick: handleMergeDuplicatesClick,
        permission: { name: Permits.PERSON_MERGE },
        isDisabled: isMergeDuplicatesDisabled,
      },
    ],
    [handleMergeDuplicatesClick, isMergeDuplicatesDisabled],
  );

  return {
    buttons,
    isMergeDuplicatesOpen,
    selectedIds,
    handleCloseMergeDuplicatesForm,
  };
}

import React from 'react';
import * as tabs from './Tabs';

import { Tabs, Tab, FormComponent, ButtonMode, Modal } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { useFormContext } from 'features/Form/hooks';
import { Form } from 'types/models';
import { CantSaveInPreviousStatusPopup } from 'features/Form/views/CantSaveInPreviousStatusPopup';
import { WarningDraftStatusPopup } from 'features/Form/views/WarningDraftStatusPopup';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Component as BibliographicRecordErrorPopup } from 'features/Form/views/BibliographicRecordErrorPopup';
import { AuthorsComponent } from 'features/Form/views/AuthorsComponent';
import { useController } from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

const CompilationForm = ({ onClose, viewMode }: Props) => {
  const {
    isLoading,
    formFields,
    conferenceEventId,
    onCreateBibliographicRecordClick,
    EditionSettings,
    cantSaveInPreviousStatusText,
    bibliographicRecordErrorText,
    isBibliographicErrorPopupOpen,
    isDraftWarningPopupOpen,
    isCantSaveInPreviousStatusPopupOpen,
    handleConfirmCantSaveInPreviousStatusPopup,
    handleCloseBibliographicErrorPopup,
    handleCloseDraftWarningPopup,
    handleCloseCantSaveInPreviousStatusPopup,
    handleConfirmDraftWarningPopup,
    handleConfirmBibliographicRecordError,
    handlePublicationSave,
    handlePublicationSaveAsDraft,
    handlePublicationSaveAndContinue,
    handleContinueSave,
    isCompilationDuplicatePopupOpen,
    closeCompilationDuplicatePopup,
    duplicates,
    publicationMessage,
    setPublicationMessage,
    publicationMessageType,
    setPublicationMessageType,
    events,
    isElectronic,
    electronicType,
    projects,
    mobileRequests,
    onCompilationDuplicatePopupSubmit,
    publicationId,
    publicationInfo,
    authors,
    setAuthors,
    documents,
    setDocuments,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    topMessage,
    setTopMessage,
    workMode,
    isAuthorsPopupOpen,
    handleCloseAuthorsPopup,
    handleConfirmAuthorsPopup,
    handleResetAuthorsPopup,
    userDepartments,
    publicationDepartment,
    setPublicationDepartment,
    isAdminMessagePopupOpen,
    arm,
    tabsId,
    setEvents,
    setProjects,
    setMobileRequests,
  } = useController({
    onClose,
  });

  const {
    look: { relatedTableState },
  } = useFormContext<Form.CompilationFormLook>();

  return (
    <>
      <CantSaveInPreviousStatusPopup
        isOpen={isCantSaveInPreviousStatusPopupOpen}
        onConfirm={handleConfirmCantSaveInPreviousStatusPopup}
        onClose={handleCloseCantSaveInPreviousStatusPopup}
        text={cantSaveInPreviousStatusText}
      />

      <WarningDraftStatusPopup
        isOpen={isDraftWarningPopupOpen}
        onClose={handleCloseDraftWarningPopup}
        onConfirm={handleConfirmDraftWarningPopup}
      />

      <BibliographicRecordErrorPopup
        errorText={bibliographicRecordErrorText}
        textLanguageId={formFields.textLanguage.value?.id || ''}
        onConfirm={handleConfirmBibliographicRecordError}
        onClose={handleCloseBibliographicErrorPopup}
        isOpen={isBibliographicErrorPopupOpen}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isCompilationDuplicatePopupOpen}
        onClose={closeCompilationDuplicatePopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onCompilationDuplicatePopupSubmit,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Нет',
            onClick: closeCompilationDuplicatePopup,
          },
        ]}
        size="small"
      >
        <h4>
          <strong>Сборник с таким названием уже добавлен в систему</strong>
        </h4>

        <FormComponent.Text>Название: {formFields.name.value || '-'}</FormComponent.Text>
        <FormComponent.Text>Год издания: {formFields.year.value || '-'}</FormComponent.Text>
        <FormComponent.Text>Всего дубликатов: {duplicates || '0'}</FormComponent.Text>
        <FormComponent.Text>
          <strong>Продолжить сохранение?</strong>
        </FormComponent.Text>
      </Modal>

      <AdministratorMessagePopup
        title={ADD_PUB_FEEDBACK_LABEL}
        isOpen={isAdminMessagePopupOpen}
        onConfirm={() => handleContinueSave({ isWithMessage: true })}
        publicationMessage={publicationMessage}
        updatePublicationMessage={setPublicationMessage}
        publicationMessageType={publicationMessageType}
        setPublicationMessageType={setPublicationMessageType}
        onConfirmWithoutMessage={() => handleContinueSave({ isWithMessage: false })}
        command="RefPublicationFeedlineMessage"
      />

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={handleCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Добавить',
            onClick: handleConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Нет',
            onClick: handleResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <p>
          <strong>
            В списке «Мои публикации», отображаются только те, в которых вы указаны в разделе Сведения об авторстве, иначе в
            списке «Все публикации»
          </strong>
        </p>

        <p>Вашей персоны нет в списке авторов. Добавить?</p>
      </Modal>

      <FormComponent.Template>
        <ControlPanel
          handleSave={handlePublicationSave}
          handleSaveAsDraft={handlePublicationSaveAsDraft}
          handleSaveAndContinue={handlePublicationSaveAndContinue}
          viewMode={viewMode}
          isDisabled={isLoading}
        />

        <FormComponent.Template>
          <Tabs id={tabsId}>
            <Tab title="О сборнике">
              <tabs.CompilationFormAbout.Component
                isElectronic={isElectronic}
                electronicType={electronicType}
                formFields={formFields}
                workMode={workMode}
                publicationInfo={publicationInfo}
                userDepartments={userDepartments}
                publicationId={publicationId}
                publicationDepartment={publicationDepartment}
                setPublicationDepartment={setPublicationDepartment}
                arm={arm}
              />
            </Tab>
            <Tab title="Сведения об авторстве">
              <AuthorsComponent workMode={workMode} formFields={formFields} authors={authors} setAuthors={setAuthors} />
            </Tab>
            <Tab title="Сведения об издании">
              <tabs.CompilationFormInfo.Component
                formFields={formFields}
                onCreateBibliographicRecordClick={onCreateBibliographicRecordClick}
                editionTypes={EditionSettings}
                workMode={workMode}
              />
            </Tab>
            <Tab title="Мероприятия и проекты">
              <tabs.Events.Component
                workMode={workMode}
                relatedTableState={relatedTableState}
                formFields={formFields}
                events={events}
                setEvents={setEvents}
                projects={projects}
                setProjects={setProjects}
                mobileRequests={mobileRequests}
                setMobileRequests={setMobileRequests}
                publicationType={publicationInfo?.type ?? ''}
                conferenceEventId={conferenceEventId}
              />
            </Tab>
            <Tab title="О публикации (анг.)">
              <tabs.AboutEnglishComponent formFields={formFields} viewMode={viewMode} />
            </Tab>
            <Tab title="Индексация в базах">
              <tabs.DatabaseIndexing.Component
                addCitationSystem={addCitationSystem}
                editCitationSystem={editCitationSystem}
                removeCitationSystem={removeCitationSystem}
                citationSystems={publicationInfo?.citationSystems ?? []}
                relatedTableState={relatedTableState}
                publicationId={publicationId || null}
                publicationType={publicationInfo?.type ?? ''}
                workMode={workMode}
                sourceId={publicationInfo?.magazine?.id}
                releaseId={publicationInfo?.magazineRelease?.id}
                topMessage={topMessage}
                setTopMessage={setTopMessage}
              />
            </Tab>
            <Tab title="Статьи">
              <tabs.ArticlesComponent
                relatedTableState={relatedTableState}
                workMode={workMode}
                publicationId={publicationId}
                publicationType={publicationInfo?.type}
              />
            </Tab>
            <Tab title="Журнал сообщений">
              <tabs.MessagesComponent relatedTableState={relatedTableState} workMode={workMode} publicationId={publicationId} />
            </Tab>
            <Tab title="Документы">
              <tabs.Documents documents={documents} setDocuments={setDocuments} viewMode={workMode === 'viewMode'} />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </>
  );
};

export const Component = React.memo(CompilationForm);

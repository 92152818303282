import React from 'react';

import { ButtonMode, ListEdit, Modal, Tab, Tabs } from 'components';

import { SecurityDocument } from 'types/models/SecurityDocument';
import { useController } from './controller';
import { validatePaymentByDocument, validatePaymentByRequest } from './validate';
import { PaymentInputs } from './PaymentInputs';
import { showErrorsMessages } from 'utils/Common';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
  disabled?: boolean;
};

export function Payments({ securityDocument, setSecurityDocument, disabled }: Props) {
  const {
    payments,
    requestPayments,
    handleRequestPaymentsChange,
    handlePaymentsChange,
    extraToolbarButtons,
    paymentExtraToolbarButtons,
    paymentTitleByType,
    onSelectedRowsCallback,
    selectedRowsAmountSum,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
  } = useController({ securityDocument, setSecurityDocument, disabled });

  return (
    <>
      <Tabs>
        <Tab title="Пошлины по заявке">
          <ListEdit
            rows={requestPayments}
            onChange={handleRequestPaymentsChange}
            toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
            columns={[
              {
                label: '№',
                formatValue: (_, rowIndex) => rowIndex + 1,
                styles: { width: '5%' },
              },
              {
                label: 'Назначение',
                formatValue: x => x?.paymentPurposeRequest?.label || '',
              },
              {
                label: 'Сумма',
                formatValue: x => x?.amount || '0',
                dataKind: 'float',
              },
              {
                label: 'Оплачено',
                formatValue: x => x?.payDate || '',
              },
              {
                label: 'Файл документа',
                formatValue: x => x?.file?.name || '',
              },
            ]}
            maxHeight="none"
            isDeleteConfirmEnabled
            isDisabled={disabled}
            columnIndexesForSumTotal={[2, 3]}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => (
                <PaymentInputs
                  payment={row}
                  onPaymentChange={onChange as any}
                  securityDocument={securityDocument}
                  isRequestPayments
                />
              ),
              validation: {
                checkIsValid: (row, index, list, mode) =>
                  validatePaymentByRequest(row, mode, list, index).every(({ isValid }) => isValid),
                onInvalidate: (row, mode, index, list) => {
                  const validationInfo = validatePaymentByRequest(row, mode, list, index);
                  showErrorsMessages(
                    validationInfo?.filter(({ isValid }) => !isValid).map(({ invalidMessage }) => invalidMessage),
                  );
                },
              },
            }}
          />
        </Tab>
        <Tab title={paymentTitleByType}>
          <ListEdit
            rows={payments}
            onChange={handlePaymentsChange}
            toolbar={['add', 'edit', 'delete', ...paymentExtraToolbarButtons, ...extraToolbarButtons]}
            columns={[
              {
                label: '№',
                formatValue: (_, index) => index + 1,
                styles: { width: '5%' },
              },
              {
                label: 'Оплатить до',
                formatValue: x => x?.payBeforeDate || '',
              },
              {
                label: 'Назначение',
                formatValue: x => x?.paymentPurpose?.label || '',
              },
              {
                label: 'Сумма',
                formatValue: x => x?.amount || '0',
                dataKind: 'float',
              },
              {
                label: 'Оплачено',
                formatValue: x => x?.payDate || '',
              },
              {
                label: 'Файл документа',
                formatValue: x => x?.file?.name || '',
              },
            ]}
            maxHeight="none"
            isDeleteConfirmEnabled
            isDisabled={disabled}
            columnIndexesForSumTotal={[3]}
            onSelectedRowsCallback={onSelectedRowsCallback}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => (
                <PaymentInputs payment={row} onPaymentChange={onChange as any} securityDocument={securityDocument} />
              ),
              validation: {
                checkIsValid: (row, index, list, mode) =>
                  validatePaymentByDocument(row, mode, list, index).every(({ isValid }) => isValid),
                onInvalidate: (row, mode, index, list) => {
                  const validationInfo = validatePaymentByDocument(row, mode, list, index);
                  showErrorsMessages(
                    validationInfo?.filter(({ isValid }) => !isValid).map(({ invalidMessage }) => invalidMessage),
                  );
                },
              },
            }}
          />
        </Tab>
      </Tabs>

      <Modal
        mode="info"
        title="Сумма выделенных строк"
        isOpen={isConfirmPopupOpened}
        onClose={() => setIsConfirmPopupOpened(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsConfirmPopupOpened(false),
          },
        ]}
        size="small"
      >
        <>Сумма выделенных строк = {selectedRowsAmountSum}</>
      </Modal>
    </>
  );
}

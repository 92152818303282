import { useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Props, SettingsSuggestionProps } from '.';

import { Item } from 'types/models/common';
import { useDebounce } from 'shared/react/useDebounce';

export function useControllerSuggestion({ settings, value }: Pick<Props, 'settings'> & { value: string }) {
  const suggestionSettings = useMemo<SettingsSuggestionProps>(() => settings as SettingsSuggestionProps, [settings]);

  const [search, setSearch] = useState<string>(value);
  const [suggestionOptions, setSuggestionOptions] = useState<Item[]>([]);

  const { methods: getFieldSuggestion } = BackendAPI.useBackendAPI('GetFieldSuggestion', {
    onSuccessfullCall: ({ data }) => {
      setSuggestionOptions(data.fieldSuggestions.map(x => ({ value: x.label, label: x.label })) as Item[]);
    },
  });

  const debouncedValue = useDebounce(search, 500);

  useEffect(() => {
    getFieldSuggestion.callAPI({
      fieldName: suggestionSettings.name,
      filter: debouncedValue,
      limit: suggestionSettings.limit || 20,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return {
    setSearch,
    suggestionOptions,
  };
}

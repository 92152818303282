import React, { useCallback, useMemo, useState } from 'react';
import { parse } from 'date-fns';

import { Column, FormComponent, ListEdit } from 'components';
import { DataKind, ExtraToolbarButton } from 'components/ListEdit/model';

import { Document } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { formatStr } from 'utils/Constants';
import { downloadFile, getAuthToken, getEnum } from 'utils/Helpers';

import { Fields } from './Fields';
import { validate } from './validate';
import { documentTypeEnum, getMockDocument } from './helpers';

export type FieldsType =
  | 'details'
  | 'projectDocument'
  | 'purchaseRequest'
  | 'securityDocument'
  | 'securityDocumentContract'
  | 'foreignSecurityDocument';

type Props = {
  documents: Document[];
  setDocuments(docs: Document[]): void;
  withMessages?: boolean;
  disabled?: boolean;
  title?: string;
  isSortEnabled?: boolean;
  isVisibleFilters?: boolean;
  isCanIsSharedEdit?: boolean;
  isCommonAward?: boolean;
  isCanIsInfoEdit?: boolean;
  isShowSource?: boolean;
  sharedLabel?: string;
  addCallback?: (document: Document, startEdit: (document: Document) => void) => void;
  editCallback?: (document: Document, startEdit: () => void) => void;
  deleteCallback?: (document: Document, startEdit: () => void) => void;
  fieldsType?: FieldsType;
  sourceLabelForLocalDocs?: string;
  defaultSharedValue?: boolean;
  editPermissionName?: string;
  maxHeight?: string;
  tooltip?: string;
  hint?: string;
  tooltipDocumentName?: string;
  isEditButtonsHidden?: boolean;
  leftToolbarButtons?: ExtraToolbarButton<Document>[];
  additionalToolbarButtons?: ExtraToolbarButton<Document>[];
  getIsDisabled?: (row: Document | null) => boolean;
};

export function Documents(props: Props) {
  const {
    title,
    documents,
    setDocuments,
    withMessages = true,
    sharedLabel,
    disabled = false,
    isShowSource = false,
    isCanIsInfoEdit = false,
    isCanIsSharedEdit = false,
    isCommonAward = false,
    addCallback,
    editCallback,
    deleteCallback,
    sourceLabelForLocalDocs,
    defaultSharedValue = false,
    fieldsType = null,
    editPermissionName,
    isVisibleFilters,
    isSortEnabled,
    maxHeight,
    tooltip,
    hint,
    tooltipDocumentName,
    isEditButtonsHidden,
    leftToolbarButtons,
    additionalToolbarButtons,
    getIsDisabled,
  } = props;

  const { userPermission, enumMap } = useAppDataContext();

  const [isInProgram, setIsInProgram] = useState<boolean>(false);
  const token = getAuthToken();

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icon: { type: 'download' },
        title: 'Скачать файл',
        onClick: document => downloadFile(document?.file?.id, token),
        isDisabled: doc => !doc,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр файла',
        onClick: document => downloadFile(document?.file?.id, token, true),
        isDisabled: doc => !doc,
      },
      ...(additionalToolbarButtons ? additionalToolbarButtons : []),
    ],
    [additionalToolbarButtons, token],
  );

  const makePreCallback = (type: 'edit' | 'delete') => (doc: Document | null, startEdit: () => void) => {
    if (doc?.docType === 'foreign') {
      showNotification({
        message: `${type === 'edit' ? 'Редактировать' : 'Удалить'} документ можно только текущего источника`,
        theme: 'danger',
      });
    } else {
      const isEdit = type === 'edit';
      const isDelete = type === 'delete';

      const isEditCallback = typeof editCallback === 'function';
      const isDeleteCallback = typeof deleteCallback === 'function';

      if (isEdit && isEditCallback && doc) {
        editCallback(doc, startEdit);
      } else if (isDelete && isDeleteCallback && doc) {
        deleteCallback(doc, startEdit);
      } else {
        startEdit();
      }
    }
  };

  const preSubmitCallback = useCallback(
    (document: Document, submit: (document: Document) => void) => {
      if (typeof addCallback === 'function') {
        addCallback(document, submit);
      } else {
        submit(document);
      }
    },
    [addCallback],
  );

  const detailsColums: Column<Document>[] = [
    {
      label: 'Действует',
      formatValue: row => parse(row.endDate ?? '', formatStr, new Date()) >= new Date(),
    },
    {
      label: 'Вид и номер',
      formatValue: row => `${row.type}${row.number ? ` №${row.number}` : ''} от ${row.date}`,
    },
    {
      label: 'Название документа',
      formatValue: row => row.name,
    },
    {
      label: 'Особые условия',
      formatValue: row =>
        [
          row.isUnexpirable ? 'Бессрочный' : null,
          row.isAutoProlongation ? 'Автоматическое продление' : null,
          row.program?.id ? `В рамках программы: ${row.program.label}` : null,
        ]
          .filter(Boolean)
          .join('; '),
    },
    {
      label: 'Период действия',
      formatValue: row => `${row.startDate || ''} - ${row.endDate || ''}`,
    },
    {
      label: 'Ответственный',
      formatValue: row => [row.responsibleFio, row.responsibleJobAppointment].filter(Boolean).join(', '),
    },
    {
      label: 'Добавлено',
      formatValue: row => `${row.createdDate} (${row.createdBy})`,
    },
  ];

  const documentsTypeEnum = useCallback(
    (enumName: string) => {
      return getEnum(enumName, enumMap);
    },
    [enumMap],
  );

  return (
    <>
      <FormComponent.Description mode="warning">{hint}</FormComponent.Description>

      <ListEdit
        header={{ title }}
        rows={documents}
        onChange={setDocuments}
        toolbar={[
          ...(leftToolbarButtons || []),
          { key: 'add', isHidden: isEditButtonsHidden },
          {
            key: 'edit',
            permission: { name: editPermissionName },
            isDisabled: getIsDisabled,
            isHidden: isEditButtonsHidden,
          },
          {
            key: 'delete',
            permission: { name: editPermissionName },
            isDisabled: getIsDisabled,
            isHidden: isEditButtonsHidden,
          },
          ...extraToolbarButtons,
        ]}
        columns={
          fieldsType === 'details'
            ? detailsColums
            : [
                { label: 'Дата документа', formatValue: x => x.date, dataKind: DataKind.DATE },

                ...(['purchaseRequest', 'securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(
                  fieldsType || '',
                )
                  ? [
                      {
                        label: 'Тип документа',
                        formatValue: (x: Document) =>
                          documentsTypeEnum(documentTypeEnum[fieldsType!]).find(i => i.value === x.documentType)?.label || '',
                      },
                    ]
                  : []),

                ...(['projectDocument'].includes(fieldsType || '')
                  ? [
                      {
                        label: 'Тип документа',
                        formatValue: (x: Document) => x.projectDocumentType?.label || '',
                      },
                    ]
                  : []),

                {
                  label: 'Название документа',
                  tooltip: tooltipDocumentName,
                  formatValue: x => x.name,
                },

                ...(['securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(fieldsType || '')
                  ? [
                      {
                        label: 'Номер',
                        formatValue: (x: Document) => x.number || '',
                      },
                    ]
                  : []),

                ...(['projectDocument'].includes(fieldsType || '')
                  ? [
                      {
                        label: 'Подробности',
                        formatValue: (x: Document) => x.detail || '',
                      },
                    ]
                  : []),

                ...(['projectDocument', 'securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(
                  fieldsType || '',
                )
                  ? [
                      {
                        label: 'Сумма',
                        formatValue: (x: Document) => x.amount || 0,
                        dataKind: DataKind.FLOAT,
                      },
                    ]
                  : []),

                ...(['securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(fieldsType || '')
                  ? [
                      {
                        label: 'Примечание',
                        formatValue: (x: Document) => x.note || '',
                      },
                    ]
                  : []),

                { label: 'Файл', formatValue: x => x.file?.name },
                { label: 'Добавлено', formatValue: x => `${x.createdDate} (${x.createdBy})` },

                ...(isShowSource
                  ? [
                      {
                        label: 'Источник',
                        formatValue: (x: Document) => (x.docType === 'local' ? sourceLabelForLocalDocs ?? 'Источник' : x.type),
                      },
                    ]
                  : []),
              ]
        }
        maxHeight={maxHeight || 'none'}
        withMessages={withMessages}
        isDeleteConfirmEnabled
        defaultRowsCount={15}
        isDisabled={disabled}
        isSortEnabled={isSortEnabled}
        isVisibleFilters={isVisibleFilters}
        columnIndexesForSumTotal={
          ['projectDocument', 'securityDocument', 'securityDocumentContract', 'foreignSecurityDocument'].includes(
            fieldsType || '',
          )
            ? [4]
            : undefined
        }
        specification={{
          onPreEdit: makePreCallback('edit'),
          onPreDelete: makePreCallback('delete'),
          onPreSubmit: preSubmitCallback,
          mode: 'customComponent',
          renderComponent: (doc, setDoc) => (
            <Fields
              document={
                doc ||
                getMockDocument({
                  createdBy: userPermission?.userName,
                  isShared: defaultSharedValue,
                  isCreator: true,
                })
              }
              setDocument={setDoc}
              sharedLabel={sharedLabel}
              isCanIsInfoEdit={isCanIsInfoEdit}
              isCanIsSharedEdit={isCanIsSharedEdit}
              fieldsType={fieldsType}
              isCommonAward={isCommonAward}
              isShowSource={isShowSource}
              isInProgram={isInProgram}
              setIsInProgram={setIsInProgram}
              sourceLabelForLocalDocs={sourceLabelForLocalDocs}
              tooltip={tooltip}
            />
          ),
          validation: {
            checkIsValid: document => validate(document, fieldsType, isInProgram).every(x => x.isValid),
            onInvalidate: document => {
              const info = validate(document, fieldsType, isInProgram);
              info.forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
        }}
      />
    </>
  );
}

import { Table as T } from 'types/models';
import { SubmitTable } from 'features/Table/streams';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

type Props = {
  hasSelectButton?: boolean;
  onSubmitTable?(state: SubmitTable): void;
};

export function GetFundCardList({ hasSelectButton, onSubmitTable }: Props): T.Specification {
  return {
    apiID: 'GetFundCardList',
    header: {
      firstLevel: {
        title: 'Список Шифров накладных расходов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
        hasSelectButton: !!hasSelectButton,
      },
    },
    onSubmitTable,
  };
}

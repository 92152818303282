import React, { memo, useCallback } from 'react';

import {
  FormComponent,
  ButtonMode,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetCompilationListByTypeSpecification } from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  publicationId: string;
  formFields: F.Fields;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  changeSourceCompilation: (original: F.Compilation | null) => void;
  onDeleteSourceCompilation: () => void;
  sourceCompilation: F.Compilation | null;
  compilationType: string;
  isElectronic: boolean;
  sourceEvents: string[];
  publicationType: string;
  sourceParticipationReport: string[];
  isDisableSourceCompilation: boolean;
  filterCompilationId: string;
  sourcePublicationDate: string;
  eventId?: string;
};

const Source = ({
  publicationId,
  formFields,
  eventId,
  onCreateBibliographicRecordClick,
  changeSourceCompilation,
  sourceCompilation,
  compilationType,
  isElectronic,
  sourceEvents,
  publicationType,
  sourceParticipationReport,
  isDisableSourceCompilation,
  filterCompilationId,
  sourcePublicationDate,
  workMode,
}: Props) => {
  const modalTableRowToSourceCompilationConventer = useCallback<(row: T.Entry) => F.Compilation>(
    row => ({
      id: row.id,
      type: row['id:type'],
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: row.status,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );

  const sourceCompilationTableConfig = GetCompilationListByTypeSpecification({
    requestData: {
      type: compilationType,
      isElectronic,
      filterCompilationId,
      articleIds: publicationId,
    },
    hasSelectButton: true,
    eventId,
  });

  return (
    <>
      <SectionTitle title="Издание" />

      <FormComponent.Line>
        <FormComponent.Field label="Сборник" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Сборники',
              isClearable: false,
              visibleStatus: getStatusRecordMessage('', sourceCompilation?.id, sourceCompilation?.status),
              table: {
                specification: sourceCompilationTableConfig,
                onSelect: (row: T.Entry | null) =>
                  changeSourceCompilation(row ? modalTableRowToSourceCompilationConventer(row) : null),
              },
            }}
            value={sourceCompilation?.bibliographic}
            isDisabled={workMode === 'viewMode' || isDisableSourceCompilation}
          />
          {publicationType === PublicationTypes.ARTICLE_CONFERENCE.code && (
            <FormComponent.Description
              mode="warning"
              tooltip="Конференцию можно добавить на форме редактирования Выпуска журнала"
              classMixin="is-hint"
            >
              <div>
                <strong>Опубликованы материалы конференции:</strong> {sourceEvents.join('; ') || 'Нет'}
              </div>
              <div>
                <strong>Доклады:</strong> {sourceParticipationReport.join('; ') || 'Нет'}
              </div>
            </FormComponent.Description>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      {!!sourcePublicationDate && (
        <FormComponent.Line>
          <FormComponent.Field label="Дата публикации">
            <span>{sourcePublicationDate}</span>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Язык текста" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.textLanguage.value}
            onChange={formFields.textLanguage.onChange}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.printPageCount.value}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageFrom.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.pageFrom.value}
            onChange={formFields.pageFrom.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageTo.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.pageTo.value}
            onChange={formFields.pageTo.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.articleNumber.title} labelSize="fit">
          <TextInput
            value={formFields.articleNumber.value}
            onChange={formFields.articleNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />

      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            settings={{
              rows: 3,
              externalButtons: [
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Построить',
                  onClick: onCreateBibliographicRecordClick,
                  isDisabled: workMode === 'viewMode',
                },
              ],
            }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.grnti.title} tooltip={formFields.grnti.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.grnti.value}
            onChange={formFields.grnti.onChange}
            settings={{ name: 'RefGrnti', title: 'Справочник "ГРНТИ"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.study.title} tooltip={formFields.study.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.study.value}
            onChange={formFields.study.onChange}
            settings={{ name: 'RefStudy', title: 'Справочник "Область науки"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};

export const SourceComponent = memo(Source);

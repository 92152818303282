import React, { useState, useMemo, useCallback } from 'react';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { DataGrid } from 'features/Table/container';
import { GetSalaryJobPaymentList } from 'features/Table/specifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { SalaryChangeModals } from '../../GetSalaryByProjectList/views/SalaryChangeModals';
import { Entry } from 'types/models/Table';
import { SalaryByFundCardGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const [selectedRow] = selectedRows;

  const [isViewOpen, setIsViewOpen] = useState(false);
  const [isSalaryChangeOpen, setIsSalaryChangeOpen] = useState<boolean>(false);
  const [isReloadNeeded, setIsReloadNeeded] = useState<boolean>(false);
  const [selectedSalaryRow, setSelectedSalaryRow] = useState<Entry>();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const onViewModalClose = useCallback(() => {
    setIsViewOpen(false);
    if (isReloadNeeded) {
      tableStreams.reloadTable.push({});
      setIsReloadNeeded(false);
    }
  }, [isReloadNeeded, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
        isHidden: !!customState.fundCardId,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => {
          setSelectedSalaryRow(selectedRow);
          setIsViewOpen(true);
        },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'transport' },
        title: 'Перенести на другой шифр',
        onClick: () => setIsSalaryChangeOpen(true),
        isDisabled: !selectedRows.length,
      },
    ],
    [customState.fundCardId, selectedRow, selectedRows.length],
  );

  const title = useMemo(
    () =>
      [
        `${[selectedRow?.Fio, selectedRow?.Role].filter(Boolean).join(': ')},`,
        'в проекте',
        `${[selectedRow?.ProjectNumber, selectedRow?.ProjectJob].filter(Boolean).join('; ')}.`,
        'Оплачено по источнику',
        selectedRow?.Code,
      ]
        .filter(Boolean)
        .join(' '),
    [selectedRow],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!selectedSalaryRow && (
        <Modal title={title} isOpen={isViewOpen} onClose={onViewModalClose} size={['extralarge', 'large-height']}>
          <DataGrid
            specification={GetSalaryJobPaymentList({
              salaryId: selectedSalaryRow?.SalaryId,
              code: selectedSalaryRow?.Code,
              projectCodeId: customState.fundCardId,
              seletedSalaryRow: selectedSalaryRow,
              onSalaryChange: () => setIsReloadNeeded(true),
              salaryChangeMode: 'fundCard',
            })}
          />
        </Modal>
      )}

      <SalaryChangeModals
        isSalaryChangeOpen={isSalaryChangeOpen}
        setIsSalaryChangeOpen={setIsSalaryChangeOpen}
        selectedRows={selectedRows}
        mode="fundCard"
      />

      <Modal
        mode="help"
        title="Финансы, Оплата коллективам, вкладка Оплата из накладных"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size="large"
      >
        {SalaryByFundCardGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Table } from 'types/models';

type Args = {
  expenseDocumentId: string;
};

export function GetSalaryByExpenseDocumentList(args: Args): Table.Specification<object, Args> {
  return {
    apiID: 'GetSalaryByExpenseDocumentList',

    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: args,
  };
}

import { useMemo, useState } from 'react';

import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Document } from 'types/models';

export function useController() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Document>[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
    ],
    [],
  );

  return {
    isHelpFormOpen,
    setIsHelpFormOpen,
    extraToolbarButtons,
  };
}

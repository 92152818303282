import { Program, ReferenceItem } from 'types/models';

import { convertFileToXML, getMaybeField } from '../commonConverters';

export function convertProgramToXML(program: Program.Program) {
  const {
    id,
    name,
    shortName,
    customers,
    indicators,
    indices,
    directions,
    startDate,
    endDate,
    url,
    file,
    knowledgeBranches,
    logo,
  } = program;

  return {
    _attr: program.id ? { id } : {},
    Name: name,
    ...(program.type?.id && {
      Type: { _attr: { id: program.type.id } },
    }),
    ShortName: shortName,
    ...getMaybeField('StartDate', startDate),
    ...getMaybeField('EndDate', endDate),
    ...getMaybeField('Url', url),
    ...convertFileToXML('File', file),
    ...convertFileToXML('Logo', logo),
    Directions: directions.length ? { Direction: directions.map(convertDirectionToXML) } : {},
    Customers: customers.length ? { Customer: customers.map(convertCustomerToXML) } : {},
    Indicators: indicators.length ? { ResultElement: indicators.map(convertIndicatorToXML) } : {},
    Indices: indices.length ? { ResultElement: indices.map(convertIndicatorToXML) } : {},
    KnowledgeBranches: knowledgeBranches.length ? { KnowledgeBranch: knowledgeBranches.map(convertKnowledgeBranchToXML) } : {},
  };
}

function convertDirectionToXML(direction: Program.Direction) {
  return {
    _attr: { id: direction.id },
    Name: direction.name,
    Code: direction.code,
  };
}

function convertCustomerToXML(customer: Program.Customer) {
  return {
    _attr: { id: customer.customerId },
    Customer: { _attr: { id: customer.refCustomer?.id } },
    ...(customer.investmentSourceType?.id && {
      InvestmentSourceType: { _attr: { id: customer.investmentSourceType.id } },
    }),
  };
}

function convertIndicatorToXML(indicator: Program.Indicator) {
  return {
    _attr: { id: indicator.id },
    Element: { _attr: { id: indicator.refResultItem.id } },
    IsActive: indicator.isActive,
  };
}

function convertKnowledgeBranchToXML(knowledgeBranch: ReferenceItem) {
  return {
    _attr: { id: knowledgeBranch.id },
  };
}

import { Form } from 'types/models';

export class ElectronicType {
  static WEB: Form.ElectronicType = {
    id: '1',
    label: 'Сетевой ресурс',
    text: 'Публикация размещена в сети Интернет',
    code: 'WEB',
  };

  static LOCAL: Form.ElectronicType = {
    id: '2',
    label: 'Эл. ресурс локального доступа',
    text: 'Публикация размещена на электронном носителе. Например: CD-диске',
    code: 'LOCAL',
  };
}

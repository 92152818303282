import { isNumber } from 'utils/Validators';

type Item = { id?: string | null };

export const sortById = <T extends Item>(list: T[], sortDirection: 'asc' | 'desc' = 'asc'): T[] =>
  list.sort((a, b) => {
    if (!isNumber(a.id) && !isNumber(b.id)) return 0;
    if (isNumber(a.id) && !isNumber(b.id)) return sortDirection === 'asc' ? -1 : 1;
    if (!isNumber(a.id) && isNumber(b.id)) return sortDirection === 'asc' ? 1 : -1;
    return sortDirection === 'asc' ? Number(a.id) - Number(b.id) : Number(b.id) - Number(a.id);
  });

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const ProjectStateRegistrationReportArchiveFiltersHelp = (
  <>
    <p>
      <strong>Год</strong> - отображает список этапов проекта в графике отчётов в год, указанный в фильтре.
    </p>
    <p>
      <strong>Все</strong> - отображает список этапов проектов с запланированными и зарегистрированными отчётами.
    </p>
    <p>
      <strong>Все + Год</strong> - отображает список этапов проектов, которые закачиваются в указанном году.
    </p>
    <p>
      <strong>Зарегистрированные в архиве</strong> - отображает список отчётов, у которых есть "Инвентарный номер в архиве"
    </p>
    <p>
      <strong>Зарегистрированные в архиве + Год</strong> - отображает список отчётов, у которых есть "Инвентарный номер в архиве"
      в указанном году.
    </p>
    <p>
      <strong>Без инв. номера в архиве</strong> - отображает список этапов проектов, у которых для отчёта не указан "Инвентарный
      номер в архиве"
    </p>
    <p>
      <strong>Без инв. номера в архиве + Год</strong> - отчёты по этапам проектов, которые закачиваются в указанном году, у
      которых для отчёта не указан "Инвентарный номер в архиве".
    </p>
  </>
);

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: ProjectStateRegistrationReportArchiveFiltersHelp,
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: 'all' },
        { label: 'Без инв. номера в архиве', value: 'withoutArchiveNumber' },
        { label: 'Зарегистрированные в архиве', value: 'archiveRegistered' },
      ],
      value: 'all',
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: false,
    },
  ]);
});

export function TenderHelp(): JSX.Element {
  return (
    <>
      <p>
        При добавлении нового конкурса определяется форма заявки, которая будет использована для заполнения заявки на конкурс.
        Выбор вида формы заявки влияет на состав полей в форме заявки. Коллекции заявок, собранные по видам заявок размещаются в
        соответствующих пунктах меню.
      </p>
      <p>
        При выборе заявки на внутренний конкурс важно указать тип конкурса один или несколько, который так же определяет состав
        полей в заявке. Система автоматически генерирует список лотов в соответствии с выбранными типами конкурса по правилу:
      </p>

      <ul>
        <li>номер лота – порядковый номер,</li>
        <li>шифр лота – это краткое наименование типа конкурса,</li>
        <li>тема лота — полное название типа конкурса.</li>
      </ul>

      <p>
        Поле <strong>Оформлять заявки через личный</strong> кабинет определяет структуру объявления о конкурсе и в зависимости от
        выбранного значения формируется текст объявления о конкурсе и возможность добавить заявку на конкурс через личный кабинет.
      </p>
      <p>
        <strong>Объявление о конкурсе</strong> отображается в разделе новости в период: от даты добавления конкурса до даты
        окончания приема заявок.
      </p>
      <p>
        Назначение полей вкладки <strong>Конкурс</strong>
      </p>

      <table>
        <thead>
          <tr>
            <th style={{ width: '30%' }}>Название поля</th>
            <th style={{ width: '70%' }}>Особенности заполнения</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Программа</td>
            <td>
              Выбор из списка программ. После выбора программы, если у программы есть логотип, то он автоматически отобразится
              рядом.
            </td>
          </tr>
          <tr>
            <td>Мероприятие</td>
            <td>
              Выбор из списка мероприятий программы. Не обязательное поле, используется для дополнительной аналитики конкурсов в
              рамках государственной программы, например «РНФ» с заявленными мероприятиями и направлениями.
            </td>
          </tr>
          <tr>
            <td>Логотип*</td>
            <td>
              По умолчанию отображается логотип выбранной программы (если он есть). Можно заменить на специальный логотип
              конкурса. Логотип используется для оформления объявления о конкурсе на сайте университета.
            </td>
          </tr>
          <tr>
            <td>Название конкурса*</td>
            <td>Полное название конкурса.</td>
          </tr>
          <tr>
            <td>Описание*</td>
            <td>
              Условия проведения конкурса. При копировании из текстового файла сохраняется оригинальное оформление. С помощью
              инструментов для форматирования, текст можно отредактировать. Поле используется для оформления объявления о
              конкурсе.
            </td>
          </tr>
          <tr>
            <td>Тип коллектива*</td>
            <td>Используется для оформления объявления и аналитики по конкурсам.</td>
          </tr>
          <tr>
            <td>Ссылка на конкурс*</td>
            <td>Адрес web-страницы с объявлением конкурса.</td>
          </tr>
          <tr>
            <td>Категории конкурса*</td>
            <td>
              Выберите категории из справочника «Категории конкурса» в соответствии с условиями конкурса. Используется для
              оформления объявления и управленческой отчётности.
            </td>
          </tr>
          <tr>
            <td>Участники конкурса*</td>
            <td>
              Выберите категории участников из справочника «Участники конкурса» в соответствии с условиями конкурса. Используется
              для оформления объявления и управленческой отчётности.
            </td>
          </tr>
          <tr>
            <td>Области науки*</td>
            <td>
              Выберите значения в соответствии с условиями конкурса из справочника «Области науки». Если необходимая область науки
              отсутствует, то добавьте ее в справочник. Элементы используются как тэги при аналитике и поиске по ключевым словам.
              В заявке заявитель имеет возможность выбрать значения области науки только из коллекции конкурса. Кроме того, на
              основе выбранных значений будет выполнена адресная рассылка уведомлений о конкурсе пользователям системы на
              электронную почту.
            </td>
          </tr>
          <tr>
            <td>Заказчики</td>
            <td>
              Выберите организацию из списка организаций. Адрес и контакты, введённые на форме выбора организации-заказчика
              сохраняются только в конкурсе (т.е. являются атрибутами конкурса).
            </td>
          </tr>
          <tr>
            <td>План по заявкам</td>
            <td>Количество заявок доступное для подачи от университета</td>
          </tr>
          <tr>
            <td>Дополнительная информация</td>
            <td>Иная информация по конкурсу, заполняется в свободной форме.</td>
          </tr>
          <tr>
            <td>Список лотов конкурса</td>
            <td>
              <p>
                Заполняется для конкурсов из категории: проведение научных мероприятий, издание научных трудов, на выполнение
                НИОКР, проекты в области предпринимательства и инноваций, стипендии и премии. Конкурс может иметь несколько лотов.
                Описание лота содержит финансовые условия выполнения работ/услуг, в том числе это могут быть требования:
              </p>
              <ul>
                <li>по календарному плану финансирования (сумма контракта, разделённая по годам)</li>
                <li>сумма обеспечения участия заявки в конкурсе</li>
                <li>сумма обеспечения контракта</li>
                <li>сумма обеспечения гарантийных обязательств</li>
                <li>цена контракта</li>
                <li>количество контрактов, предусмотренных в лоте</li>
                <li>условия по срокам выполнения работ</li>
              </ul>
              <p>Для внутреннего конкурса автоматически генерируется запись о лоте по правилу:</p>
              <ul>
                <li>номер лота — порядковый номер</li>
                <li>шифр лота — краткое название типа конкурса</li>
                <li>тема лота — полное название типа конкурса</li>
              </ul>
            </td>
          </tr>
          <tr>
            <td>Источник финансирования</td>
            <td>
              Выберите из списка Источников финансирования в соответствии с категорией инициатора конкурса (Заказчика). Значение
              этого поля важно в процедуре создания проекта на основе заявки. Поле не обязательное для заполнения.
            </td>
          </tr>
          <tr>
            <td>Консультанты по заявкам</td>
            <td>Это сотрудники университета, которые консультируют участников конкурса и согласовывают заявку.</td>
          </tr>
          <tr>
            <td>Очные консультации по адресу</td>
            <td>Контактная информация консультантов по конкурсу.</td>
          </tr>
          <tr>
            <td>Порядок размещения заявок</td>
            <td>Раздел содержит ключевые даты конкурса.</td>
          </tr>
          <tr>
            <td>Оформлять заявки через личный кабинет</td>
            <td>
              В зависимости от выбранного значения (да или нет) формируется текст объявления о конкурсе и возможность добавить
              заявку на конкурс через личный кабинет.
            </td>
          </tr>
          <tr>
            <td>Дата объявления</td>
            <td>Дата объявления конкурса на площадке размещения конкурса (сайте Заказчика).</td>
          </tr>
          <tr>
            <td>Подать заявку на согласование консультанту до</td>
            <td>
              Дата, до которой Консультанты принимают заявки на конкурс для согласования на уровне Университета. По истечении этой
              даты пользователь личного кабинета не может добавить новую заявку.
            </td>
          </tr>
          <tr>
            <td>Дата окончания приема заявок; Время (МСК)</td>
            <td>Дата и точное время окончания регистрации заявок в ИАС организатора конкурса.</td>
          </tr>
          <tr>
            <td>Дата рассмотрения заявок</td>
            <td>Дата рассмотрения заявок на площадке размещения конкурса.</td>
          </tr>
          <tr>
            <td>Дата подведения итогов</td>
            <td>Дата подведения итогов на площадке размещения конкурса.</td>
          </tr>
          <tr>
            <td>Способ определения победителя</td>
            <td>Выберете формат проведения конкурса из списка.</td>
          </tr>
          <tr>
            <td>Вид тендера</td>
            <td>Выберете вид тендера из списка.</td>
          </tr>
          <tr>
            <td>Электронная торговая площадка</td>
            <td>Выбор элемента из списка ЭТП. Раздел заполняется для конкурсов, размещённых ЭТП.</td>
          </tr>
          <tr>
            <td>Название ЭТП</td>
            <td>
              Выберите название из справочника. Если в справочнике отсутствует значение — добавьте или попросите об этом
              ответственного за справочник.
            </td>
          </tr>
          <tr>
            <td>Тариф ЭТП</td>
            <td>
              Тариф ЭТП за участие в тендере. Для участия в конкурсе, на момент подачи заявки сумма денежных средств должна быть
              на ЭТП.
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

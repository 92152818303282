import { Table } from 'types/models';

import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetPersonalPurchaseRequestList(): Table.Specification {
  return {
    apiID: 'GetPersonalPurchaseRequestList',
    header: {
      firstLevel: {
        title: 'Закупки по проектам',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
  };
}

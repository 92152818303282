import React from 'react';
import { ValueOf } from 'types/helpers';

import { Person, UserPermission } from 'types/models';
import { UpperForm } from './UpperForm';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  reloadPerson(): void;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

function Component(props: Props) {
  const { person, userPermission, reloadPerson, makeSetPerson } = props;
  return <UpperForm reloadPerson={reloadPerson} userPermission={userPermission} person={person} makeSetPerson={makeSetPerson} />;
}

export const Main = React.memo(Component);

import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { ContestRequestStatus, ContestRequestType } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { Color } from 'constants/colors';

type StatusArgs = {
  rows: { id: string; status: ContestRequestStatus | null; type: ContestRequestType }[];
  callback?(): void;
};

export function getContestRequestStatusButtons({ rows, callback }: StatusArgs) {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();

  const [isStatusModalOpen, setIsStatusModalOpen] = useState<boolean>(false);
  const [nextStatus, setNextStatus] = useState<ContestRequestStatus>(ContestRequestStatus.DRAFT);
  const [statusMessage, setStatusMessage] = useState<string>('');
  const [statusLabel, setStatusLabel] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeContestRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddContestRequestApprovement');

  const handleButtonClick = useCallback((newStatus: ContestRequestStatus, newLabel: string) => {
    setStatusMessage('');
    setNextStatus(newStatus);
    setStatusLabel(newLabel);
    setIsStatusModalOpen(true);
  }, []);

  const changeStatus = useCallback(() => {
    changeStatusAPI.callAPI(
      { ids: rows.map(x => x.id), message: statusMessage, status: nextStatus },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Статус успешно изменен', theme: 'success' });
          setIsStatusModalOpen(false);
          tableStreams.reloadTable.push({});
          callback?.();
        },
        onFailedCall: () => setIsStatusModalOpen(false),
      },
    );
  }, [changeStatusAPI, rows, statusMessage, nextStatus, tableStreams.reloadTable, callback]);

  const saveApprovement = useCallback(
    (message: string, resolution: string) => {
      addApprovementAPI.callAPI(
        { message: message, contestRequestId: rows[0].id, status: resolution },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Резолюция успешно отправлена', theme: 'success' });
            setIsApprovementModalOpen(false);
          },
        },
      );
    },
    [addApprovementAPI, rows],
  );

  const isAllActualization = !!rows.length && rows.every(x => x.type === ContestRequestType.ACTUALIZATION);
  const isAllExtension = !!rows.length && rows.every(x => x.type === ContestRequestType.EXTENSION);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'new' },
        title: 'В черновик',
        onClick: () => handleButtonClick(ContestRequestStatus.DRAFT, 'В черновик'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_DRAFT },
        isHidden: isProfile,
        isDisabled: !(isAllExtension && rows.every(x => !!x.status && [ContestRequestStatus.REJECTED].includes(x.status))),
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: isAllActualization ? 'Подать на согласование актуальные данные этапа проекта' : 'Подать на конкурс',
        onClick: () =>
          handleButtonClick(
            isAllActualization ? ContestRequestStatus.ACTUALIZATION : ContestRequestStatus.REQUEST,
            isAllActualization ? 'Подать на согласование актуальные данные этапа проекта' : 'Подать на конкурс',
          ),
        permission: {
          name: [Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST, Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION],
        },
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [ContestRequestStatus.DRAFT, ContestRequestStatus.REVISION].includes(x.status)) ||
          !(
            (isAllActualization && isHasPermission(userPermission, Permits.CONTEST_REQUEST_CHANGE_STATUS_ACTUALIZATION)) ||
            (isAllExtension && isHasPermission(userPermission, Permits.CONTEST_REQUEST_CHANGE_STATUS_REQUEST))
          ),
      },
      {
        icon: { type: 'like' },
        title: 'Присвоить заявке статус "Согласована"',
        onClick: () => handleButtonClick(ContestRequestStatus.AGREED, 'Присвоить заявке статус "Согласована"'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED },
        isHidden: isProfile,
        isDisabled:
          !rows.length ||
          !rows.every(x => !!x.status && [ContestRequestStatus.REQUEST, ContestRequestStatus.ACTUALIZATION].includes(x.status)),
      },
      {
        icon: { type: 'like', color: Color.warning },
        title: 'Присвоить заявке статус "Согласована с условием"',
        onClick: () =>
          handleButtonClick(ContestRequestStatus.AGREED_WITH_CONDITION, 'Присвоить заявке статус "Согласована с условием"'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_AGREED_WITH_CONDITION },
        isHidden: isProfile,
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x =>
              !!x.status &&
              [ContestRequestStatus.REQUEST, ContestRequestStatus.AGREED, ContestRequestStatus.SUPPORTED].includes(x.status),
          )
        ),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Поддержать',
        onClick: () => handleButtonClick(ContestRequestStatus.SUPPORTED, 'Поддержать'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_SUPPORTED },
        isHidden: isProfile,
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x => !!x.status && [ContestRequestStatus.AGREED, ContestRequestStatus.AGREED_WITH_CONDITION].includes(x.status),
          )
        ),
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => handleButtonClick(ContestRequestStatus.REVISION, 'Отправить на доработку'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_REVISION },
        isHidden: isProfile,
        isDisabled:
          !rows.length ||
          !rows.every(
            x =>
              !!x.status &&
              [ContestRequestStatus.REQUEST, ContestRequestStatus.REJECTED, ContestRequestStatus.ACTUALIZATION].includes(
                x.status,
              ),
          ),
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => handleButtonClick(ContestRequestStatus.REJECTED, 'Отклонить'),
        permission: { name: Permits.CONTEST_REQUEST_CHANGE_STATUS_REJECTED },
        isHidden: isProfile,
        isDisabled: !(
          isAllExtension &&
          rows.every(
            x => !!x.status && [ContestRequestStatus.REQUEST, ContestRequestStatus.AGREED_WITH_CONDITION].includes(x.status),
          )
        ),
      },
      {
        icon: { type: 'message', mode: 'check' },
        title: 'Экспертная оценка',
        onClick: () => setIsApprovementModalOpen(true),
        permission: { name: Permits.CONTEST_REQUEST_AGREE },
        isHidden: isProfile,
        isDisabled: !!rows[0]?.status && rows[0].status === ContestRequestStatus.DRAFT,
      },
    ],
    [isAllExtension, rows, isProfile, isAllActualization, userPermission, handleButtonClick],
  );

  return {
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    buttons,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  };
}

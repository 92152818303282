import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table, Report } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { getParticipationPermitList, isParticipationPermitted } from 'utils/Helpers/participation';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const { userPermission, userSystemDepartment } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const [selectedRow] = tableState.selectedRows;

  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );
  const { methods: deleteParticipation } = BackendAPI.useBackendAPI('DeleteParticipation');
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);
  const [isUsagesListOpen, setIsUsagesListOpen] = useState(false);

  const { settings } = useAppDataContext();

  const handleOpenWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(true);
  }, []);

  const handleCloseWarningPopup = useCallback(() => {
    setIsWarningPopupOpen(false);
  }, []);

  const handleAttachParticipation = useCallback(() => {
    if (selectedRow) {
      BindParticipationToCurrentUserDepartment.callAPI(
        { participationId: selectedRow?.id },
        {
          onSuccessfullCall: data => {
            if (data.data.success) {
              showNotification({ message: 'Участие успешно привязано', theme: 'success' });
              handleCloseWarningPopup();
            }
          },
          onFailedCall: () => {
            handleCloseWarningPopup();
          },
        },
      );
    }
  }, [selectedRow, BindParticipationToCurrentUserDepartment, handleCloseWarningPopup]);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleOpenApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const handleOpenEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const handleCloseEditDepartments = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const handleOpenConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(true);
  }, []);

  const handleCloseConfirmDelete = useCallback(() => {
    setIsConfirmDeleteOpen(false);
  }, []);

  const handleOpenUsagesList = useCallback(() => {
    setIsUsagesListOpen(true);
  }, []);

  const handleCloseUsagesList = useCallback(() => {
    setIsUsagesListOpen(false);
  }, []);

  const afterSubmitEditDepartments = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const handleDeleteParticipation = useCallback(() => {
    deleteParticipation.callAPI(
      { participationId: selectedRow?.id || '' },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно удалено', theme: 'success' });
          tableStreams.reloadTable.push({});
          setIsConfirmDeleteOpen(false);
        },
        onFailedCall: () => {},
      },
    );
  }, [deleteParticipation, selectedRow?.id, tableStreams.reloadTable]);

  const reports = useMemo<Report[]>(() => [Reports.DepartmentConcertParticipation], []);

  const { getReports, handleSetCurrentReport, isReportOpen, onReportClose, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: Permits.PARTICIPATION_CONCERT_VIEW,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавление',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.PARTICIPATION_CONCERT_ADD,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактирование',
        onClick: handleEditButtonClick,
        permission: {
          name: getParticipationPermitList('CONCERT', 'EDIT'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'CONCERT', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удаление',
        onClick: handleOpenConfirmDelete,
        permission: {
          name: getParticipationPermitList('CONCERT', 'DELETE'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'CONCERT', 'DELETE'),
      },
      {
        icon: { type: 'connection' },
        title: 'Привязать к подразделению',
        onClick: handleOpenWarningPopup,
        permission: {
          name: Permits.PARTICIPATION_CONCERT_BIND_TO_DEPARTMENT,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'like' },
        title: 'Процедура утверждения',
        onClick: handleOpenApprovePopup,
        permission: {
          name: Permits.PARTICIPATION_CONCERT_APPROVE,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'relation', mode: 'view' },
        title: 'Связи участия с другими объектами',
        onClick: handleOpenUsagesList,
        permission: {
          name: Permits.PARTICIPATION_CONFERENCE_DEPARTMENT_EDIT,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'connection', mode: 'edit' },
        title: 'Редактировать связь участия с подразделениями',
        onClick: handleOpenEditDepartments,
        permission: {
          name: Permits.PARTICIPATION_CONCERT_DEPARTMENT_EDIT,
        },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      tableState.selectedRows,
      handleAddButtonClick,
      handleEditButtonClick,
      userPermission,
      handleOpenConfirmDelete,
      handleOpenWarningPopup,
      handleOpenApprovePopup,
      handleOpenUsagesList,
      handleOpenEditDepartments,
      getReports,
      handleSetCurrentReport,
    ],
  );

  const handleContinueStep = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const afterSubmitApproveParticipation = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams]);

  return {
    buttons,
    selectedRow,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    isWarningPopupOpen,
    isApprovePopupOpen,
    userSystemDepartment,
    isEditDepartmentsOpen,
    isConfirmDeleteOpen,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseAddForm,
    handleContinueStep,
    handleCloseWarningPopup,
    handleAttachParticipation,
    handleCloseApprovePopup,
    handleCloseEditDepartments,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    afterSubmitEditDepartments,
    afterSubmitApproveParticipation,
    isUsagesListOpen,
    handleCloseUsagesList,
    isReportOpen,
    onReportClose,
    currentReport,
    settings,
  };
};

export default useController;

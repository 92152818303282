import { ScientificIndex } from 'types/models';
import { ChildField, Field } from 'types/models/CitationSystemIndex';

export function getFieldsFromScientificIndex(scientificIndex: ScientificIndex.ScientificIndex) {
  const convert = (values: ScientificIndex.ScientificField[]) =>
    values.reduce<Record<string, Field>>((acc, { name, id, required, type, metaType, code }) => {
      const field: Field = {
        fieldId: '',
        valueId: id,
        name,
        value: '',
        isRequired: required,
        type,
        metaType,
        savedValueId: null,
        code,
      };
      return { ...acc, [field.valueId]: field };
    }, {});

  const parentFields = convert(scientificIndex.values);

  const childFields = scientificIndex.childs.map<ChildField>(({ id: childId, code: childCode, values, label }) => {
    const fields = convert(values);
    return { scientificIndexId: childId, code: childCode, fields, label, savedChildId: null };
  });

  return { parentFields, childFields };
}

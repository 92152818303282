import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useCallback, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';
import { ValueOf } from 'types/helpers';
import { PaymentFeedline } from 'types/models/Payment';
import { PaymentFeedlineRequestData } from '.';

export type State = {
  isOpenModal: boolean;
  paymentFeedline: PaymentFeedline | null;
  isDisabledToolbar: boolean;
  openModal(): void;
  closeModal(): void;
  savePaymentFeedline(): void;
  deletePaymentFeedline(id: string): void;
  getPaymentFeedline(id: string): void;
  changeFeedline(key: keyof PaymentFeedline, value: ValueOf<PaymentFeedline>): void;
};

export function makeUseCustomController({ status, employeePaymentId, isDisabledToolbar = false }: PaymentFeedlineRequestData) {
  return function useCustomController(): State {
    const [isOpenModal, setIsOpenModal] = useState(false);
    const [paymentFeedline, setPaymentFeedline] = useState<PaymentFeedline | null>(null);
    const tableStreams = useLocalTableStreams();

    const { methods: getPaymentFeedlineAPI } = BackendAPI.useBackendAPI('GetEmployeePaymentRequestFeedline', {
      onSuccessfullCall: ({ data }) => {
        setPaymentFeedline(data);
      },
    });

    const { methods: savePaymentFeedlineAPI } = BackendAPI.useBackendAPI('SaveEmployeePaymentRequestFeedline', {
      onSuccessfullCall: () => {
        closeModal();
        showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
        reloadTable();
      },
    });

    const { methods: deletePaymentFeedlineAPI } = BackendAPI.useBackendAPI('DeleteEmployeePaymentRequestFeedline', {
      onSuccessfullCall: () => {
        closeModal();
        showNotification({ message: 'Сообщение успешно удалено', theme: 'success' });
        reloadTable();
      },
    });

    const deletePaymentFeedline = useCallback((id: string) => {
      deletePaymentFeedlineAPI.callAPI({ feedlineId: id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const savePaymentFeedline = useCallback(() => {
      if (paymentFeedline && status) {
        savePaymentFeedlineAPI.callAPI({ status, paymentFeedline, id: employeePaymentId });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [employeePaymentId, paymentFeedline]);

    const getPaymentFeedline = useCallback((id: string) => {
      getPaymentFeedlineAPI.callAPI({ id });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const closeModal = useCallback(() => {
      setIsOpenModal(false);
      setPaymentFeedline(null);
    }, []);

    const openModal = useCallback(() => {
      setIsOpenModal(true);
    }, []);

    const changeFeedline = useCallback((key: keyof PaymentFeedline, value: ValueOf<PaymentFeedline>) => {
      setPaymentFeedline(prev => ({ ...prev!, [key]: value }));
    }, []);

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    return {
      isOpenModal,
      paymentFeedline,
      isDisabledToolbar,
      openModal,
      closeModal,
      getPaymentFeedline,
      changeFeedline,
      savePaymentFeedline,
      deletePaymentFeedline,
    };
  };
}

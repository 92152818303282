import React, { useMemo, useState } from 'react';

import { ButtonProps, Toolbar } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { Component as PersonForm } from '../../GetDetailedScientistList/Form';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const selectedRows = tableState.selectedRows;

  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);

  const reports = useMemo<Report[]>(() => [Reports.InformationOnScientificInnovativeDivisionsOrganization], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsFormOpen(true),
        permission: { name: Permits.PERSON_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport, selectedRows.length],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <PersonForm isOpen={isFormOpen} onClose={() => setIsFormOpen(false)} id={selectedRows[0]?.scientistId} mode="view" />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

export class Reference {
  static MODULE: string = 'Ref';

  static MODULE_FULL: string = 'Reference';

  static ADDRESSES: string = 'Addresses';

  static ACADEMY: string = 'Academy';

  static ACADEMIC_RANK: string = 'AcademicRank';

  static PROJECT_WORK_SPACE: string = 'ProjectWorkSpace';

  static PROJECT_CODE_LETTER: string = 'ProjectCodeLetter';

  static OWNER_VISA: string = 'OwnerVisa';

  static KIND_ACADEMY: string = 'KindAcademy';

  static MOBILE_ACTIVITY_KIND: string = 'MobileActivityKind';

  static CONTRACT_KIND: string = 'ContractKind';

  static CATEGORY_EDITION: string = 'CategoryEdition';

  static PAYMENT_KIND: string = 'PaymentKind';

  static SECURITY_DOCUMENT_KIND: string = 'SecurityDocumentKind';

  static OKVED: string = 'Okved';

  static MOBILE_ADVERTISING_MATERIAL_TYPE: string = 'MobileAdvertisingMaterialType';

  static KIND_RESEARCH: string = 'KindResearch';

  static KIND_PROJECT_WORK: string = 'KindProjectWork';

  static FINANCE_PROVIDE: string = 'FinanceProvide';

  static GRNTI: string = 'Grnti';

  static GRIF: string = 'Grif';

  static STAFF_GROUP: string = 'StaffGroup';

  static APPOINTMENT: string = 'Appointment';

  static SALARY_APPONTMENT: string = 'SalaryAppontment';

  static VISA: string = 'Visa';

  static MOBILE_REQUEST_VISA: string = 'MobileRequestVisa';

  static PRIVATE_ACCESS: string = 'PrivateAccess';

  static SALARY_INCOME: string = 'SalaryIncome';

  static TYPE_CONCERT: string = 'TypeConcert';

  static ENTERPRISE_CUSTOMER: string = 'EnterpriseCustomer';

  static PUBLISHER: string = 'Publisher';

  static RESULT_INDICATOR: string = 'ResultIndicator';

  static INVESTMENT_SOURCE: string = 'InvestmentSource';

  static KOSGU: string = 'Kosgu';

  static CHANGE_CATEGORY: string = 'ChangeCategory';

  static TENDER_CATEGORY: string = 'TenderCategory';

  static RECOGNITION_AWARD_CATEGORY: string = 'RecognitionAwardCategory';

  static PAYMENT_REQUEST_MESSAGE_CATEGORY: string = 'PaymentRequestMessageCategory';

  static APPOINTMENT_CATEGORY: string = 'AppointmentCategory';

  static CLASSIFICATION_EXPENDITURES_BUDGETS: string = 'ClassificationExpendituresBudgets';

  static KVR: string = 'Kvr';

  static NATIONAL_PROJECT_EXPENSE_CODE: string = 'NationalProjectExpenseCode';

  static OECD: string = 'Oecd';

  static PRIORITY_TECHNOLOGY: string = 'PriorityTechnology';

  static LKSETS: string = 'Lksets';

  static MPK: string = 'Mpk';

  static INTERNATIONAL_PROGRAM: string = 'InternationalProgram';

  static RECOGNITION_AWARD: string = 'RecognitionAward';

  static PAYMENT_PURPOSE: string = 'PaymentPurpose';

  static PAYMENT_PURPOSE_REQUEST: string = 'PaymentPurposeRequest';

  static MINISTRY_EDUCATION: string = 'MinistryEducation';

  static VAK_SPECIALITY: string = 'VakSpeciality';

  static OKVED_2: string = 'Okved2';

  static OKVED_INCOME: string = 'OkvedIncome';

  static KNOWLEDGE_BRANCH: string = 'KnowledgeBranch';

  static STUDY: string = 'Study';

  static SCIENCE_DOMAIN_INTEREST: string = 'ScienceDomainInterest';

  static ENTERPRISE: string = 'Enterprise';

  static ENTERPRISE_DEPONENT: string = 'EnterpriseDeponent';

  static SCIENCE_BRUNCH: string = 'ScienceBrunch';

  static PNMITR: string = 'Pnmitr';

  static PNI: string = 'Pni';

  static DEPARTMENT: string = 'Department';

  static RESULT_INDEX: string = 'ResultIndex';

  static PROJECT_REQUEST_NUMBER_PREFIX: string = 'ProjectRequestNumberPrefix';

  static PNR: string = 'Pnr';

  static MOBILE_USE_MATERIAL_OBLIGATION_RESULT: string = 'MobileUseMaterialObligationResult';

  static DECISION: string = 'Decision';

  static PARTICIPATION_MEMBER_ROLE: string = 'ParticipationMemberRole';

  static ORG_COMMITTE: string = 'OrgCommitte';

  static NTI_MARKET: string = 'NtiMarket';

  static SECTOR_ACTIVITY: string = 'SectorActivity';

  static EVENT_SERIES: string = 'EventSeries';

  static CITATION_SYSTEM: string = 'CitationSystem';

  static NTI_ETE_TECHNOLOGY: string = 'NtiEteTechnology';

  static FOREIGN_SECURITY_DOCUMENT_FEEDLINE_MESSAGE: string = 'ForeignSecurityDocumentFeedlineMessage';

  static EVENT_FEEDLINE_MESSAGE: string = 'EventFeedlineMessage';

  static PROJECT_FEEDLINE_MESSAGE: string = 'ProjectFeedlineMessage';

  static PUBLICATION_FEEDLINE_MESSAGE: string = 'PublicationFeedlineMessage';

  static JOINT_EVENT_FEEDLINE_MESSAGE: string = 'JointEventFeedlineMessage';

  static PARTICIPATION_FEEDLINE_MESSAGE: string = 'ParticipationFeedlineMessage';

  static ENTERPRISE_STATUS: string = 'EnterpriseStatus';

  static PUBLISHER_SPECIALTY: string = 'PublisherSpecialty';

  static EDUCATION_SPECIALITY: string = 'EducationSpeciality';

  static MOBILE_PARTICIPANT_STATUS: string = 'MobileParticipantStatus';

  static CATEGORY: string = 'Category';

  static COUNTRY: string = 'Country';

  static NTR_STRATEGY: string = 'NtrStrategy';

  static THEMATIC_CATEGORY: string = 'ThematicCategory';

  static THEMATIC_DOMAIN: string = 'ThematicDomain';

  static QNA_QUESTION_THEME: string = 'QnaQuestionTheme';

  static TECHNOLOGY_PLATFORM: string = 'TechnologyPlatform';

  static TYPE_RNTD: string = 'TypeRntd';

  static LKSD: string = 'Lksd';

  static TYPE_EXPOSITION: string = 'TypeExposition';

  static TYPE_REPORT: string = 'TypeReport';

  static CITATION_SYSTEM_SOURCE_TYPE: string = 'CitationSystemSourceType';

  static TYPE_INVESTMENT_SOURCE: string = 'TypeInvestmentSource';

  static TYPE_CONTEST: string = 'TypeContest';

  static TYPE_CONFERENCE: string = 'TypeConference';

  static PARTNERSHIP_TYPE: string = 'PartnershipType';

  static COMPOUND_TYPE: string = 'CompoundType';

  static CITATION_SYSTEM_ARTICLE_TYPE: string = 'CitationSystemArticleType';

  static EXHIBIT_TYPE: string = 'ExhibitType';

  static MARKETPLACE: string = 'Marketplace';

  static UDK: string = 'Udk';

  static EVENT_STATUS: string = 'EventStatus';

  static TENDER_PARTICIPANT: string = 'TenderParticipant';

  static SCIENTIFIC_RANK: string = 'ScientificRank';

  static DEGREE: string = 'Degree';

  static PARTICIPATION_FORM: string = 'ParticipationForm';

  static KIND_TRADE: string = 'KindTrade';

  static PROJECT_SPECIFIC: string = 'ProjectSpecific';

  static STAFF_STATE: string = 'StaffState';

  static LANGUAGE: string = 'Language';

  static METADATA: string = 'Metadata';
}

export function PartnerSecurityDocumentContractGridHelp(): JSX.Element {
  return (
    <>
      <p>В таблице отображается список договоров на собственности университета. </p>
      <p>
        Источник информации — функциональный модуль <strong>Коммерциализация</strong>: на форме договора должна быть указана
        организация в поле <strong>"Заключён с"</strong>
      </p>
    </>
  );
}

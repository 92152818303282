import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { GetPartnerList } from 'features/Table/specifications';
import { Table } from 'types/models';
import { DefaultCustomSelect } from '../DefaultCustomSelect';
import { BuilderProps } from 'features/BuildReportPopup/models';

const PartnerSelect = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification = {
    modalTableSpecification: GetPartnerList({ toolbarMode: 'null', hasSelectButton: true }) as Table.Specification,
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      label: row.FullName || row.ShortName || '',
      id: row.id || '',
    }),
    relationTableModalTitle: 'Справочник "Партнёры"',
  };

  return (
    <DefaultCustomSelect
      parameterName={parameterName}
      placeholder="Партнёр"
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      modalTableRowConverter={specification.modalTableRowConverter}
      modalTableSpecification={specification.modalTableSpecification}
      relationTableModalTitle={specification.relationTableModalTitle}
    />
  );
};

const Component = memo(PartnerSelect);

export { Component as PartnerSelect };

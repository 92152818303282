import { useMemo, useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Permits } from 'utils/Permissions';
import { isNextPurchaseRequestStatus } from 'types/models/PurchaseRequest';
import { PurchaseRequestStatus } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Color } from 'constants/colors';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const selectedRow = tableState.selectedRows[0] || null;

  const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
  const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [newStatus, setNewStatus] = useState<PurchaseRequestStatus | null>(null);

  const { methods: changePurchaseRequestStatus } = BackendAPI.useBackendAPI('ChangePurchaseRequestStatus');

  const row = useMemo<Table.Entry | null>(() => selectedRow ?? null, [selectedRow]);

  const handleMessageModalClose = useCallback(() => {
    tableStreams.reloadTable.push({});
    setIsOpenMessageModal(false);
  }, [tableStreams]);

  const isAuthor = useMemo(() => {
    return !!(row !== null && row.IsApplicant === 'true');
  }, [row]);

  const onChangeStatusClick = useCallback(
    (status: PurchaseRequestStatus) => {
      setNewStatus(status);
      setIsOpenMessageModal(true);
    },
    [setNewStatus, setIsOpenMessageModal],
  );

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      if (!newStatus) return;
      changePurchaseRequestStatus.callAPI(
        { status: newStatus, message: message || '', ids: tableState.selectedRows.map(r => r.id) },
        {
          onSuccessfullCall: () => {
            setNewStatus(null);
            setIsOpenMessageModal(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [changePurchaseRequestStatus, tableStreams, newStatus, tableState, setNewStatus, setIsOpenMessageModal],
  );

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (row?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeletePurchaseRequest',
        deletedItemPropName: 'PurchaseRequest',
      });
    }
  }, [row?.id, tableStreams.deleteRow, setIsDeleteWarningOpen]);

  const isEditButtonPermitted = useMemo(() => {
    return (
      row &&
      isAuthor &&
      isHasPermission(userPermission, Permits[`PURCHASE_REQUEST_EDIT_${row['id:Status'] as PurchaseRequestStatus}`])
    );
  }, [isAuthor, row, userPermission]);

  const isDeleteButtonPermitted = useMemo(() => {
    return row && isAuthor && row['id:Status'] === PurchaseRequestStatus.DRAFT;
  }, [row, isAuthor]);

  const isChangeStatusDisabled = useCallback(
    (nextStatus: PurchaseRequestStatus) => {
      return !(row && isNextPurchaseRequestStatus(row['id:Status'], nextStatus));
    },
    [row],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: !selectedRow,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
        permission: { name: Permits.PURCHASE_REQUEST_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Object.values(PurchaseRequestStatus).map(x => Permits[`PURCHASE_REQUEST_EDIT_${x}`]) },
        isDisabled: !isEditButtonPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteWarningOpen(true),
        permission: { name: Permits.PURCHASE_REQUEST_DELETE_DRAFT },
        isDisabled: !isDeleteButtonPermitted,
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Передать на рассмотрение',
        onClick: () => onChangeStatusClick(PurchaseRequestStatus.CONSIDERATION),
        permission: { name: Permits.PURCHASE_REQUEST_CHANGE_STATUS_CONSIDERATION },
        isDisabled: isChangeStatusDisabled(PurchaseRequestStatus.CONSIDERATION),
      },
    ],
    [selectedRow, isEditButtonPermitted, isDeleteButtonPermitted, isChangeStatusDisabled, onChangeStatusClick],
  );

  return {
    buttons,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isOpenMessageModal,
    handleMessageModalClose,
    onSubmitDeleteWarning,
    handleSaveStatusClick,
    newStatus,
    selectedRow,
  };
};

export default useController;

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.tooltip,
      text:
        'Если указан год - в таблице выводятся показатели года, если не указан - будут выводиться показатели за последний год',
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Показатели за год',
      checked: false,
    },
  ]);
});

import React from 'react';

import { FormComponent, Radio, ReferenceItem, SectionTitle, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { Project } from 'types/models';

type Props = {
  project?: Project.Project;
  stage: Project.Stage | null;
  setStage: React.Dispatch<React.SetStateAction<Project.Stage | null>>;
  isReportForm?: boolean;
  disabled?: boolean;
};

export function StageFields(props: Props) {
  const { project, stage, setStage, isReportForm, disabled } = props;

  // usable ONLY for info fields. Instead of using reports.stage use stage prop
  const report = project?.reports.find(x => x.stage?.number === stage?.number) || null;

  const patentResearch = (project?.patentResearches || []).find(x => x.stage?.id === stage?.id) || null;

  return (
    <>
      <SectionTitle title={`Этап № ${stage?.number || ''} (${stage?.startDate || ''} - ${stage?.endDate || ''})`} />
      <FormComponent.Line>
        <FormComponent.Field label="Название этапа" isRequired={isReportForm && !!stage}>
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.name}
            onChange={e => setStage(prev => ({ ...prev!, name: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Аннотация работ">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.annotation}
            onChange={e => setStage(prev => ({ ...prev!, annotation: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Цели этапа">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.goal}
            onChange={e => setStage(prev => ({ ...prev!, goal: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Задачи этапа">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.task}
            onChange={e => setStage(prev => ({ ...prev!, task: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Обоснование междисциплинарного подхода">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.interdisciplinaryAproachJustification}
            onChange={e => setStage(prev => ({ ...prev!, interdisciplinaryAproachJustification: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ожидаемые результаты">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.expectedResults}
            onChange={e => setStage(prev => ({ ...prev!, expectedResults: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Вид работ">
          <Select
            mode={SelectMode.REFERENCE}
            value={stage?.kindWork}
            onChange={(e: ReferenceItem | null) => setStage(prev => ({ ...prev!, kindWork: e }))}
            settings={{ name: 'RefKindProjectWork', title: 'Справочник "Виды работ"', isClearable: true }}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Описание работ этапа">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.workDescription}
            onChange={e => setStage(prev => ({ ...prev!, workDescription: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Правовая охрана РИД">
          <Radio
            list={[
              { label: 'Планируются', value: 'true' },
              { label: 'Не планируются', value: 'false' },
            ]}
            value={String(!!stage?.isPatentResearchPlanned)}
            onChange={e => setStage(prev => ({ ...prev!, isPatentResearchPlanned: e === 'true' }))}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isReportForm && !!patentResearch && (
        <FormComponent.Line>
          <FormComponent.Field label="">
            <FormComponent.Text>
              {[
                patentResearch.number,
                `"${patentResearch.name}".`,
                patentResearch.rntd?.name ? `На охрану РИД: ${patentResearch.rntd.name} ` : '',
                patentResearch.hiDate ? `(Дата сдачи отчета: ${patentResearch.hiDate})` : 'В работе',
              ]
                .filter(Boolean)
                .join(' ')}
            </FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <SectionTitle title="Полученные результаты" />
      <FormComponent.Line>
        <FormComponent.Field label="Полученные результаты">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.obtainedResults}
            onChange={e => setStage(prev => ({ ...prev!, obtainedResults: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Эффект на уровне университета">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.universityEffect}
            onChange={e => setStage(prev => ({ ...prev!, universityEffect: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Эффект на уровне региона">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.regionalEffect}
            onChange={e => setStage(prev => ({ ...prev!, regionalEffect: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Эффект на уровне национальном">
          <TextArea
            mode={TextAreaMode.MODAL}
            value={stage?.nationalEffect}
            onChange={e => setStage(prev => ({ ...prev!, nationalEffect: e }))}
            isDisabled={disabled}
            settings={{ rows: 1 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isReportForm && !!report && (
        <>
          <SectionTitle title="Отчёт по итогам этапа" />
          <FormComponent.Line>
            <FormComponent.Field>
              {/* eslint-disable-next-line max-len */}
              {`Отчёт: ${report.name}: отчёт о НИР (${report.type?.label}); - ГР ${report.number}. - Инв. ${report.archiveNumber}`}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Госрегистрация">{report.acceptedDate}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Регистрация в архиве">{report.archiveAcceptedDate}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Отправлен Заказчику">{report.customerDate}</FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </>
  );
}

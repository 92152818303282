import { useState, useLayoutEffect, useCallback, useMemo, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Equip } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { getMockEquip } from './helpers';
import { getEnum } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';

type Props = {
  viewMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, onClose }: Props) {
  const {
    look: { id: initialId, setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const statusOptions = getEnum('EquipStatus');

  const [equip, setEquip] = useState<Equip.Equip>(getMockEquip());

  const { methods: getEquip } = BackendAPI.useBackendAPI('GetEquip', {
    onSuccessfullCall: ({ data }) => {
      setEquip(data);
    },
  });

  const { methods: saveEquip } = BackendAPI.useBackendAPI('SaveEquip');

  const onSubmit = useCallback(
    (isSaveLoad?: boolean) => {
      if (!equip.name) {
        showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
        return;
      }
      saveEquip.callAPI(equip, {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Запись сохранена', theme: 'success' });
          if (isSaveLoad) {
            getEquip.callAPI({ id: data.id });
            return;
          }
          tableStreams.reloadTable.push({});
          onClose();
        },
      });
    },
    [equip, getEquip, onClose, saveEquip, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSubmit(),
        isDisabled: viewMode,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSubmit(true),
        isDisabled: viewMode,
      },
    ],
    [onSubmit, viewMode],
  );

  useEffect(() => {
    const id = initialId || equip.id;

    const modeInfo = (() => {
      if (id) {
        if (viewMode) return 'Просмотр';
        else return 'Редактирование';
      }
      return 'Добавление';
    })();

    const idInfo = id ? `(ID ${id})` : '';
    const statusInfo = equip.status?.label ? `Статус: ${equip.status?.label}` : '';

    setTitle?.([modeInfo, 'оборудования', equip.name, idInfo, statusInfo].filter(Boolean).join(' '));
  }, [equip.id, equip.name, equip.status?.label, initialId, setTitle, viewMode]);

  useLayoutEffect(() => {
    if (initialId) getEquip.callAPI({ id: initialId });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    equip,
    setEquip,
    buttons,
    statusOptions,
    userPermission,
  };
}

export class Search {
  static MODULE: string = 'Search';

  static REPORTS_ARCHIVE: string = 'ReportsArchive';

  static PROJECTS: string = 'Projects';

  static SECURITY_DOCUMENTS: string = 'SecurityDocuments';

  static SCIENTISTS: string = 'Scientists';
}

import { isHasPermission } from 'features/AppData';
import { Table, UserPermission } from 'types/models';
import { EventTypeCode } from 'types/models/Event';
import { RecordStatus } from 'utils/Enums';
import { Permits } from 'utils/Permissions';

export function getParticipationPermitList(type: EventTypeCode, action: 'EDIT' | 'DELETE') {
  return [
    Permits[`PARTICIPATION_${type}_${action}_ALL` as keyof Permits],
    Permits[`PARTICIPATION_${type}_${action}_ADDED_MY` as keyof Permits],
  ];
}

export function isParticipationPermitted(
  userPermission: UserPermission | null,
  selectedRows: Table.Entry[],
  type: EventTypeCode,
  action: 'EDIT' | 'DELETE',
) {
  if (selectedRows.length !== 1) {
    return false;
  }

  const recordStatus = (selectedRows[0]['id:RecordStatus'] ||
    selectedRows[0]['id:ParticipationStatus'] ||
    selectedRows[0]['id:status']) as RecordStatus;

  if (!recordStatus) {
    return false;
  }

  if (recordStatus === RecordStatus.ADDED || recordStatus === RecordStatus.APPROVED) {
    if (isHasPermission(userPermission, Permits[`PARTICIPATION_${type}_${action}_ALL` as keyof Permits])) {
      return true;
    }
  }

  if (recordStatus === RecordStatus.ADDED) {
    return (
      selectedRows[0].IsUserOwned === 'true' &&
      isHasPermission(userPermission, Permits[`PARTICIPATION_${type}_${action}_ADDED_MY` as keyof Permits])
    );
  }

  return false;
}

import { ValidationInfo } from 'types/models/common';
import { CollectionValue, FieldType, Merge, RelationType } from 'types/models/Merge';
import { ValidateResult, MergeFieldRadios, MergeFieldRadioValue } from '../types';

type ValidateArguments = {
  merge: Merge;
  mergeFieldRadios: MergeFieldRadios;
};

export const validate = ({ merge, mergeFieldRadios }: ValidateArguments): ValidationInfo[] => {
  const validationResult = merge.common.reduce<ValidateResult>(
    (accum, currentField) => {
      const isCollection = currentField.type === FieldType.COLLECTION;
      const isCollectionRelationTypeOneToMany = currentField.relationType === RelationType.ONE_TO_MANY;

      const isBoolean = currentField.type === FieldType.BOOLEAN;

      const isCommonFilled = isCollection ? Boolean(currentField.collection?.length) : Boolean(currentField.value);

      const isConflictResolved =
        merge.entities.some(({ id }) => mergeFieldRadios[id][currentField.name] === MergeFieldRadioValue.SELECTED) ||
        !currentField.isConflicted;

      const isCommonCollectionOneToManyValid =
        isCollection && isCollectionRelationTypeOneToMany ? Boolean((currentField.value as CollectionValue).analogId) : true;

      const nextConflict: ValidationInfo = {
        invalidMessage: currentField.caption,
        isValid: currentField.isRequired ? isConflictResolved : true,
      };
      const nextEmpty: ValidationInfo = {
        invalidMessage: currentField.caption,
        isValid: currentField.isRequired && !isBoolean ? isCommonFilled : true,
      };
      const nextInvalidManyToMany: ValidationInfo = {
        invalidMessage: currentField.caption,
        isValid: currentField.isRequired ? isCommonCollectionOneToManyValid : true,
      };

      return {
        conflicts: [...accum.conflicts, nextConflict],
        empty: [...accum.empty, nextEmpty],
        invalidOneToMany: [...accum.invalidOneToMany, nextInvalidManyToMany],
      };
    },
    { conflicts: [], empty: [], invalidOneToMany: [] },
  );

  validationResult.conflicts = validationResult.conflicts.filter(({ isValid }) => !isValid);
  validationResult.empty = validationResult.empty.filter(({ isValid }) => !isValid);
  validationResult.invalidOneToMany = validationResult.invalidOneToMany.filter(({ isValid }) => !isValid);

  const getInvalidMessage = ({
    prefix,
    prefixSingle,
    postfix,
    postfixSingle,
    array,
  }: {
    prefix: string;
    prefixSingle: string;
    postfix: string;
    postfixSingle: string;
    array: ValidationInfo[];
  }) => {
    const isNeedSingleText = array.length < 1;
    return `${isNeedSingleText ? prefixSingle : prefix} ${array.map(({ invalidMessage }) => `"${invalidMessage}"`).join(', ')} ${
      isNeedSingleText ? postfixSingle : postfix
    }`;
  };

  return [
    {
      invalidMessage: getInvalidMessage({
        prefix: 'Конфликты для полей:',
        prefixSingle: 'Конфликт для поля:',
        postfix: 'не решены',
        postfixSingle: 'не решен',
        array: validationResult.conflicts,
      }),
      isValid: !validationResult.conflicts.length,
    },
    {
      invalidMessage: getInvalidMessage({
        prefix: 'Поля не заполнены:',
        prefixSingle: 'Поле не заполнено:',
        postfix: '',
        postfixSingle: '',
        array: validationResult.empty,
      }),
      isValid: !validationResult.empty.length,
    },
    {
      invalidMessage: getInvalidMessage({
        prefix: 'Для коллекции:',
        prefixSingle: 'Для коллекций:',
        postfix: 'не выбран элемент по умолчанию',
        postfixSingle: 'не выбран элемент по умолчанию',
        array: validationResult.invalidOneToMany,
      }),
      isValid: !validationResult.invalidOneToMany.length,
    },
  ];
};

import { useState } from 'react';

import { FormComponent, Modal, TextArea } from 'components';

import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { formatJob } from 'features/SelectPerson/helpers';
import { prepareJob } from '../helpers';
import { EditJobs } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  value: Person.ScientistJob | null;
  onChange(job: Person.ScientistJob | null): void;
  savePerson(person: Person.ScientistPerson): void;
  isDisabled?: boolean;
};

function SelectJob(props: Props) {
  const { person, value, onChange, savePerson, isDisabled } = props;

  const [isListPopupOpen, setIsListPopupOpen] = useState<boolean>(false);

  const onChangeJob = (job: Person.ScientistJob | null) => {
    const nextJob = prepareJob(job);
    const isActive = nextJob ? nextJob.isActive : true;
    if (!isActive) {
      showNotification({ message: 'Нельзя выбрать неактивную работу', theme: 'danger' });
    }
    const preparedJob = isActive ? nextJob : value;

    setIsListPopupOpen(false);
    onChange(preparedJob);
  };

  const onChangeJobs = (jobs: Person.ScientistJob[]) => {
    savePerson({ ...person, scientistJobs: jobs } as Person.ScientistPerson);
  };

  return (
    <>
      <TextArea
        settings={{
          isFixed: true,
          isClearable: true,
          externalButtons: [
            {
              icon: { type: 'table' },
              title: 'Добавить',
              isDisabled: isDisabled,
              onClick: () => {
                setIsListPopupOpen(true);
              },
            },
          ],
        }}
        onClick={() => setIsListPopupOpen(true)}
        onChange={val => {
          if (!val) onChange(null);
        }}
        value={value ? formatJob(value) : ''}
        placeholder="Нажмите чтобы выбрать..."
        isDisabled={isDisabled}
      />

      <Modal
        isOpen={isListPopupOpen}
        onClose={() => setIsListPopupOpen(false)}
        title="Выберите место работы двойным кликом"
        size={['extralarge', 'medium-height']}
      >
        <FormComponent.Wrapper>
          <EditJobs mode="history" person={person} onChangeJobs={onChangeJobs} onSelect={onChangeJob} disabled={!!isDisabled} />
        </FormComponent.Wrapper>
      </Modal>
    </>
  );
}

export { SelectJob };

import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { RequestStatus, NirRequestStatus, MobileRequestStatus, TenderRequestType } from 'utils/Enums';
import { getRequestStatusButtons } from '../../GetRequestList/helpers';
import { getNirRequestStatusButtons } from '../../GetNirRequestList/helpers';
import { getMobileRequestStatusButtons } from '../../GetMobileRequestList/helpers';
import { State } from '../makeUseCustomController';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
  customState: State;
};

export function useController(props: Props) {
  const {
    tableState,
    customState: { type, statusChangeCallback },
  } = props;

  const [selectedRow] = tableState.selectedRows;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const statusChangeInfo =
    type === TenderRequestType.PROGRAM_REQUEST
      ? getRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({ id: x.id, status: (selectedRow?.['id:Status'] as RequestStatus) || null })),
          callback: statusChangeCallback,
        })
      : type === TenderRequestType.NIR_REQUEST
      ? getNirRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({
            id: x.id,
            status: (selectedRow?.['id:Status'] as NirRequestStatus) || null,
          })),
          callback: statusChangeCallback,
        })
      : type === TenderRequestType.MOBILE_REQUEST
      ? getMobileRequestStatusButtons({
          rows: tableState.selectedRows.map(x => ({
            id: x.id,
            status: (selectedRow?.['id:Status'] as MobileRequestStatus) || null,
          })),
          callback: statusChangeCallback,
        })
      : null;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: !selectedRow,
      },
      ...((statusChangeInfo?.buttons as ButtonProps[]).filter(
        x =>
          x.permission?.name === Permits.NIR_REQUEST_AGREE ||
          x.permission?.name === Permits.PROGRAM_REQUEST_AGREE ||
          x.permission?.name === Permits.MOBILE_REQUEST_AGREE,
      ) || []),
    ],
    [selectedRow, statusChangeInfo?.buttons],
  );

  return {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    statusChangeInfo,
  };
}

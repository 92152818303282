import { Dispatch, SetStateAction } from 'react';

import { Form } from 'types/models';
import { ElectronicType } from 'utils/Enums/ElectronicType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
  initialTypeEditionItem: {
    label: string;
    value: string;
    ordinal: number;
  } | null;
};

const setByResponse = ({ setFormFields, preparedData, name, initialTypeEditionItem }: Props) => {
  const { fields, file, events } = preparedData;

  setFormFields(prevState => ({
    ...prevState,
    name: {
      ...prevState.name,
      value: fields.name || name,
    },
    issn: {
      ...prevState.issn,
      value: fields.ISSN,
    },
    isbn: {
      ...prevState.isbn,
      value: fields.ISBN,
    },
    textLanguage: {
      ...prevState.textLanguage,
      value: {
        id: fields.textLanguageId || '',
        label: fields.textLanguageLabel || '',
      },
    },
    publisher: {
      ...prevState.publisher,
      value: {
        id: fields.publisherId || '',
        label: fields.publisherLabel || '',
        foreignName: fields.publisherForeignName || '',
      },
    },
    address: {
      ...prevState.address,
      value: fields.address,
    },
    year: {
      ...prevState.year,
      value: fields.year || new Date().getFullYear(),
    },
    month: {
      ...prevState.month,
      value: fields.month,
    },
    day: {
      ...prevState.day,
      value: fields.day,
    },
    volume: {
      ...prevState.volume,
      value: fields.volume,
    },
    issue: {
      ...prevState.issue,
      value: fields.issue,
    },
    publicationDate: {
      ...prevState.publicationDate,
      value: fields.publicationDate,
    },
    pageCount: {
      ...prevState.pageCount,
      value: fields.pageCount,
      required: fields.isElectronic === 'false',
    },
    printPageCount: {
      ...prevState.printPageCount,
      value: fields.printPageCount,
    },
    tirage: {
      ...prevState.tirage,
      value: fields.tirage,
    },
    categoryEdition: {
      ...prevState.categoryEdition,
      value: {
        label: fields.categoryEditionLabel || '',
        id: fields.categoryEditionId || '',
      },
    },
    typeEdition: {
      ...prevState.typeEdition,
      value: {
        label: initialTypeEditionItem ? initialTypeEditionItem.label : fields.typeEditionText || '',
        id: initialTypeEditionItem ? initialTypeEditionItem.value : fields.typeEditionCode || '',
      },
    },
    sourceType: {
      ...prevState.sourceType,
      value: {
        id: fields.sourceTypeId || '',
        label: fields.sourceTypeLabel || '',
      },
    },
    seriesTitle: {
      ...prevState.seriesTitle,
      value: fields.seriesTitle,
    },
    bibliographicRecord: {
      ...prevState.bibliographicRecord,
      value: fields.bibliographicRecord,
    },
    doi: {
      ...prevState.doi,
      value: fields.doi,
    },
    libraryLink: {
      ...prevState.libraryLink,
      value: fields.libraryLink,
    },
    file: {
      ...prevState.file,
      value: file,
    },
    electronicMediaSource: {
      ...prevState.electronicMediaSource,
      value: fields.electronicMediaSource,
      required: fields.isElectronic === 'true' && fields.electronicType === ElectronicType.LOCAL.code,
    },
    electronicSystemRequirements: {
      ...prevState.electronicSystemRequirements,
      value: fields.electronicSystemRequirements,
    },
    grnti: {
      ...prevState.grnti,
      value: {
        id: fields.grntiId || '',
        label: fields.grntiLabel || '',
      },
    },
    udk: {
      ...prevState.udk,
      value: fields.udk,
    },
    study: {
      ...prevState.study,
      value: {
        id: fields.studyId || '',
        label: fields.studyLabel || '',
      },
    },
    annotation: {
      ...prevState.annotation,
      value: fields.annotation,
    },
    authorsKeyWords: {
      ...prevState.authorsKeyWords,
      value: fields.authorsKeyWords,
    },
    authorsCount: {
      ...prevState.authorsCount,
      value: fields.authorsCount,
    },
    fractionalScore: {
      ...prevState.fractionalScore,
      value: fields.fractionalCount,
    },
    isConfirencesPublished: {
      ...prevState.isConfirencesPublished,
      value: events.length > 0,
    },
    url: {
      ...prevState.url,
      value: fields.url,
      required: fields.isElectronic === 'true' && fields.electronicType === ElectronicType.WEB.code,
    },
    date: {
      ...prevState.date,
      value: fields.urlDate,
      required: fields.isElectronic === 'true',
    },
    foreignName: {
      ...prevState.foreignName,
      value: fields.foreignName,
    },
    foreignAnnotation: {
      ...prevState.foreignAnnotation,
      value: fields.foreignAnnotation,
    },
    foreignAuthorsKeyWords: {
      ...prevState.foreignAuthorsKeyWords,
      value: fields.foreignAuthorsKeyWords,
    },
    foreignAuthors: {
      ...prevState.foreignAuthors,
      value: fields.foreignAuthors,
    },
  }));
};

export { setByResponse };

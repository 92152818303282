import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, UploadFile } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function QnaQuestionForm({ onClose }: Props) {
  const { questionText, themeText, formFields, viewMode, saveQnaQuestionHandler } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveQnaQuestionHandler} isDisabled={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Тема" isRequired={formFields.question.required && !viewMode}>
            {!viewMode ? (
              <Select
                mode={SelectMode.REFERENCE}
                value={formFields.questionTheme.value}
                onChange={formFields.questionTheme.onChange}
                settings={{
                  name: 'RefQnaQuestionTheme',
                  title: 'Тема',
                  isClearable: !(formFields.question.required && !viewMode),
                }}
              />
            ) : (
              <div>{themeText}</div>
            )}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вопрос" isRequired={formFields.question.required && !viewMode}>
            {!viewMode ? (
              <TextArea
                settings={{ rows: 5, maxSymbols: 2049 }}
                value={formFields.question.value}
                onChange={formFields.question.onChange}
              />
            ) : (
              <div>{questionText}</div>
            )}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={viewMode ? 'Файл' : 'Прикрепите файл'}>
            <UploadFile file={formFields.questionFile.value} onChange={formFields.questionFile.onChange} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <>
          {viewMode && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Ответ">
                  <div>
                    {formFields.answer.value ? (
                      <div dangerouslySetInnerHTML={{ __html: formFields.answer.value }} />
                    ) : (
                      'ответ еще не дан'
                    )}
                  </div>
                </FormComponent.Field>
              </FormComponent.Line>
              {formFields.answerFile?.value?.id && (
                <FormComponent.Line>
                  <FormComponent.Field label="Файл ответа">
                    <UploadFile
                      file={formFields.answerFile.value}
                      onChange={formFields.answerFile.onChange}
                      isDisabled={viewMode}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
            </>
          )}
        </>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(QnaQuestionForm);

export class General {
  static OPEN_DATA: string = 'OpenData';

  static PUBLICATIONS: string = 'Publications';

  static EVENTS: string = 'Events';

  static PROJECTS: string = 'Projects';

  static SECURITY_DOCUMENTS: string = 'SecurityDocuments';

  static NEWS: string = 'News';

  static TENDERS: string = 'Tenders';

  static QNA: string = 'Qna';

  static STUFFS: string = 'UsefulLinksAndHelpfulStuffs';

  static UPDATES: string = 'SystemUpdates';

  static QNA_ADMIN: string = 'QnaAdmin';
}

import { Payment } from 'types/models';
import { AuthorPaymentRequestStatus, PaymentRequestType } from 'utils/Enums';

export function usePermissions(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  const typeLabel: Record<Payment.EventType, string> = {
    [PaymentRequestType.ARTICLE]: 'Article',
    [PaymentRequestType.CONFERENCE]: 'Conference',
    [PaymentRequestType.MEDAL]: 'ExhibitionAward',
    [PaymentRequestType.MONOGRAPH]: 'Monograph',
    [PaymentRequestType.TEXTBOOK]: 'TextBook',
    // [PaymentRequestType.SECURITY_DOCUMENT_ISSUE]: 'PatentIssue',
    // [PaymentRequestType.SECURITY_DOCUMENT]: 'Patent',
    // [PaymentRequestType.EXHIBIT]: 'Exhibit',
  };

  return {
    addRequest: `IP${typeLabel[event]}RequestsAdd`,
    editFundRequest: `IP${typeLabel[event]}RequestsFundManualEdit`,
    toAgreement: `IP${typeLabel[event]}RequestsStatusChangeToAgreement`,
    toRevision: `IP${typeLabel[event]}RequestsStatusChangeToRevision`,
    toPaid: `IP${typeLabel[event]}RequestsStatusChangeToPaid`,
    toPostpone: `IP${typeLabel[event]}RequestsStatusChangeToPostpone`,
    toRejected: `IP${typeLabel[event]}RequestsStatusChangeToRejected`,
    toPayment: `IP${typeLabel[event]}RequestsStatusChangeToPaymentApproved`,
    deletablePermissionName: getDeletablePermissionName(event, status),
    editablePermissionName: getEditPermission(event, status),
  };
}

function getDeletablePermissionName(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  return `IncentivePayment:NomineeRequest:${event}:Delete:${status}`;
}

function getEditPermission(event: Payment.EventType, status: AuthorPaymentRequestStatus) {
  return `IncentivePayment:NomineeRequest:${event}:Edit:${status}`;
}

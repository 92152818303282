import React from 'react';

import { FormComponent } from 'components';

import { Project } from 'types/models';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { ProjectReportFields } from 'features/Form/looks/projectReport/ProjectReportForm/Fields';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function ProjectReportForm({ viewMode, onClose }: Props) {
  const { onSubmit, report, setReport } = useController({ onClose });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <ProjectReportFields
          report={report}
          setReport={setReport as React.Dispatch<React.SetStateAction<Project.Report | null>>}
          disabled={!!viewMode}
        />
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectReportForm);

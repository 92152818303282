import { useCallback, useMemo, useState, useEffect } from 'react';
import { format } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectCode } from 'types/models/Project';
import { Report, Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { IdItem } from 'types/models/common';
import { formatStr } from 'utils/Constants';

type Props = {
  tableState: Table.State;
};

type CreateInfo = {
  year: string;
  overheadPercent: string;
  kindConsumption: IdItem | null;
  createdDate: string;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();
  const { settings } = useAppDataContext();

  const selectedRowsLength = useMemo(() => tableState.selectedRows.length, [tableState.selectedRows]);

  const defaultCreateInfo = useMemo<CreateInfo>(
    () => ({
      year: new Date().getFullYear().toString(),
      overheadPercent: '',
      kindConsumption: null,
      createdDate: format(new Date(), formatStr),
    }),
    [],
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.NirRequestTotalEstimate,
      Reports.IncomingFundsNiokr,
      Reports.IncomingFundsTotalFact,
      Reports.ByDepartmentShort,
      Reports.ByDepartmentFull,
      Reports.IncomeCurrentAndFuturePeriodsAccordingProjectCodes,
      Reports.Other2030Projects,
      Reports.BankWarranty,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const codeExists = useMemo(() => {
    if (tableState.selectedRows[0]?.Code && tableState.selectedRows[0]?.Code !== '') {
      return true;
    }
    return false;
  }, [tableState]);

  const editAvailable = useMemo(() => {
    if (codeExists && tableState.selectedRows[0]?.Active === 'true') {
      return true;
    }
    return false;
  }, [tableState, codeExists]);

  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isAddButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !codeExists;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !editAvailable;
  const isDepartmentsButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !editAvailable;
  const isReplaceButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !editAvailable;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED || !editAvailable;

  const [projectId, setProjectId] = useState<string>();
  const [projectCode, setProjectCode] = useState<ProjectCode | null>(null);
  const [departments, setDepartments] = useState<ReferenceItem[]>([]);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isDepartmentsModalOpen, setIsDepartmentsModalOpen] = useState(false);
  const [isProjectValidationModalOpen, setIsProjectValidationModalModalOpen] = useState(false);
  const [projectValidationModalTxt, setProjectValidationModalTxt] = useState<string>('');

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isReplaceConfirmPopupOpen, setIsReplaceConfirmPopupOpen] = useState(false);
  const [isInReplaceMode, setIsInReplaceMode] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isCreateModalOpen, setIsCreateModalOpen] = useState<boolean>(false);
  const [createInfo, setCreateInfo] = useState<CreateInfo>(defaultCreateInfo);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: createEstimateByProjectCode } = BackendAPI.useBackendAPI('CreateEstimateByProjectCode');

  const closeCreateModal = useCallback(() => {
    setIsCreateModalOpen(false);
    setCreateInfo(defaultCreateInfo);
  }, [defaultCreateInfo]);

  const confirmCreateModal = useCallback(() => {
    const { year, overheadPercent, kindConsumption, createdDate } = createInfo;
    if (!year) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    createEstimateByProjectCode.callAPI(
      {
        year: year,
        overheadPercent: overheadPercent,
        kindConsumptionId: kindConsumption?.id,
        createdDate: createdDate,
        projectCodeIds: tableState.selectedRows.map(x => x.id),
      },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push({});
          closeCreateModal();
        },
      },
    );
  }, [closeCreateModal, createEstimateByProjectCode, createInfo, tableState.selectedRows, tableStreams.reloadTable]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ProjectCode',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const { methods: getProjectCode } = BackendAPI.useBackendAPI('GetProjectCode');

  const handleDepartmentsButtonClick = useCallback(() => {
    getProjectCode.callAPI(
      { id: tableState.selectedRows[0]?.id },
      {
        onSuccessfullCall: ({ data }) => {
          setProjectCode(data);
          setDepartments(data.departments || []);
          setIsDepartmentsModalOpen(true);
        },
      },
    );
  }, [getProjectCode, setProjectCode, setIsDepartmentsModalOpen, tableState, setDepartments]);

  const { methods: saveProjectCode } = BackendAPI.useBackendAPI('SaveProjectCode');
  useEffect(() => {
    if (!selectedRowsLength) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRowsLength]);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject');

  const handleAddButtonClick = useCallback(() => {
    getProject.callAPI(
      { id: tableState.selectedRows[0]?.ProjectId },
      {
        onSuccessfullCall: ({ data }) => {
          if (
            data.departments &&
            data.departments.length > 0 &&
            data.departments[0].unit?.id &&
            data.departments[0].governance?.id
          ) {
            setIsInReplaceMode(false);
            setProjectId(data.id || undefined);
            setNextPublicationName('');
            setIsAddFormOpen(true);
          } else {
            setProjectValidationModalTxt(
              // eslint-disable-next-line max-len
              `Внимание: в проекте не указано подразделение (факультет), поэтому невозможно создать шифр. Добавить ответственные за выполнение проекта подразделение и факультет необходимо на форме редактирования проекта ${data.number}, Вкладка "О проекте", в разделе "Проект выполняется на базе:"`,
            );
            setIsProjectValidationModalModalOpen(true);
          }
        },
      },
    );
  }, [tableState, setIsInReplaceMode, getProject]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsInReplaceMode(false);
    setIsEditFormOpen(true);
  }, [setIsInReplaceMode]);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleDepartmentsChange = useCallback((value: ReferenceItem[]) => {
    setDepartments(value);
  }, []);

  const handleReplaceButtonClick = useCallback(() => {
    if (tableState.selectedRows[0]?.HasLockedStages === 'true') {
      showNotification({ message: 'Невозможно заменить шифр, проект имеет закрытые периоды', theme: 'danger' });
    } else {
      setIsReplaceConfirmPopupOpen(true);
    }
  }, [tableState]);

  const handleCloseReplaceConfirmPopup = useCallback(() => {
    setIsReplaceConfirmPopupOpen(false);
  }, []);

  const handleConfirmReplaceConfirmPopup = useCallback(() => {
    setIsInReplaceMode(true);
    setIsEditFormOpen(true);
    setIsReplaceConfirmPopupOpen(false);
  }, [setIsInReplaceMode]);

  const handleSaveDepartmentsClick = useCallback(() => {
    if (projectCode) {
      saveProjectCode.callAPI(
        { ...projectCode, ...{ departments } },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Департаменты успешно сохранены', theme: 'success' });
            tableStreams.reloadTable.push({});
            setIsDepartmentsModalOpen(false);
          },
        },
      );
    }
  }, [saveProjectCode, projectCode, departments, tableStreams]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PROJECT_CODE_VIEW },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PROJECT_CODE_ADD },
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PROJECT_CODE_EDIT },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.PROJECT_CODE_DELETE },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'documents', mode: 'add' },
        title: 'Заменить шифр',
        onClick: handleReplaceButtonClick,
        permission: { name: Permits.PROJECT_CODE_EDIT },
        isDisabled: isReplaceButtonDisabled,
      },
      {
        icon: { type: 'calc' },
        title: 'Создать смету для группы проектов',
        onClick: () => setIsCreateModalOpen(true),
        isDisabled: !tableState.selectedRows.length,
      },
      {
        icon: { type: 'relation' },
        title: 'Доступ подразделений к шифру',
        onClick: handleDepartmentsButtonClick,
        permission: { name: Permits.PROJECT_CODE_VIEW_DEPARTMENT },
        isDisabled: isDepartmentsButtonDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      isViewButtonDisabled,
      handleViewButtonClick,
      isAddButtonDisabled,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isReplaceButtonDisabled,
      handleReplaceButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      tableState.selectedRows.length,
      isDepartmentsButtonDisabled,
      handleDepartmentsButtonClick,
      getReports,
      handleSetCurrentReport,
    ],
  );

  const departmentModalDisclaimerText = useMemo(
    () => `Если у пользователя имеется право "Просмотр списка шифров, введение которых курируется подразделением
            пользователя", то информация о шифре ${projectCode?.code} будет доступна пользователям, которые закреплены за
            указанными ниже подразделениями:`,
    [projectCode],
  );

  return {
    projectCode,
    projectId,
    departments,
    setIsDepartmentsModalOpen,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDepartmentsButtonDisabled,
    isDeleteButtonDisabled,
    isReplaceButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isDepartmentsModalOpen,
    isEditFormOpen,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleDeleteButtonClick,
    handleViewButtonClick,
    handleDepartmentsButtonClick,
    handleTemplateCloseViewForm,
    handleAddButtonClick,
    handleTemplateCloseAddForm,
    handleEditButtonClick,
    isReplaceConfirmPopupOpen,
    handleTemplateCloseEditForm,
    handleCloseReplaceConfirmPopup,
    handleConfirmReplaceConfirmPopup,
    handleDepartmentsChange,
    buttons,
    departmentModalDisclaimerText,
    isInReplaceMode,
    projectValidationModalTxt,
    isProjectValidationModalOpen,
    setIsProjectValidationModalModalOpen,
    handleSaveDepartmentsClick,
    isReportOpen,
    currentReport,
    onReportClose,
    settings,
    isCreateModalOpen,
    closeCreateModal,
    confirmCreateModal,
    createInfo,
    setCreateInfo,
    isHelpOpen,
    setIsHelpOpen,
  };
}

import React from 'react';

import { Header } from '../types';
import { Entry } from 'types/models/Table';

type Props<CustomState> = { customState: CustomState; selectedRows: Entry[] } & Header<CustomState>['secondLevel'];

export const SecondLevelHeader = <CustomState extends {}>({ Component, customState, selectedRows }: Props<CustomState>) => {
  return <>{typeof Component === 'function' && <Component customState={customState} state={{ selectedRows: selectedRows }} />}</>;
};

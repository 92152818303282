import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { setup } from 'bem-cn';
import { useStream } from 'StreamRx';
import { tabsStreams } from './streams';

import { Content, FormComponent, Info, RequiredBadge, Tooltip } from 'components';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});

const b = block('form-tab');

type Props = {
  /** @property {string | number} id - (optional) ID of tabs */
  id?: string | number;
  /** @property {boolean} isStrip - (optional) Visible hightlight */
  isStrip?: boolean;
  /** @property {string} defaultTabId - (optional) Tab ID by default */
  defaultTabId?: string;
  /** @property {function} onTabClick - (optional) Callback on tab click */
  onTabClick?: (id?: string) => void;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {React.ReactNode[] | ReactElement | ReactElement[]} children - (optional) React inner JSX component */
  children: React.ReactNode[] | ReactElement | ReactElement[];
};

/**
 * Tabs component
 *
 * @param id - (optional) Uniq id for tabs
 * @param onTabClick - (optional) Callback onclick title tab
 * @param isStrip - (optional) Visible mode for render
 * @param defaultTabId - (optional) Set active tab with tab key
 * @returns JSX
 */
export const Tabs = React.memo(({ children, id, onTabClick = () => {}, isStrip = false, defaultTabId = '', style }: Props) => {
  const [selectedTab, setSelectedTab] = useState(0);

  const getTabProps = (tab: any) => (tab as ReactElement)?.props as TabProps;

  const tabs = useMemo(
    () => (Array.isArray(children) ? children : [children]).filter(tab => tab && getTabProps(tab).isVisible !== false),
    [children],
  );

  useStream(
    () => tabsStreams.setCurrentTab,
    ({ nextSelectedTab, tabsId = null }) => {
      if (id === tabsId && nextSelectedTab !== selectedTab) {
        setSelectedTab(nextSelectedTab);
      }
    },
    [id, selectedTab],
  );

  useEffect(() => {
    if (!!defaultTabId) {
      const tabIndex: number = tabs.map(getTabProps).findIndex(tab => tab.id && tab.id === defaultTabId);

      if (tabIndex > -1 && tabIndex !== selectedTab) {
        setSelectedTab(tabIndex);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTabId, children]);

  return (
    <div className={b()} style={style}>
      <div className={b('line', { 'is-strip': isStrip })}>
        {tabs.map(getTabProps).map((tab, index) => (
          <div
            key={index}
            className={b('item', { selected: index === selectedTab })}
            onClick={() => {
              setSelectedTab(index);
              onTabClick(tab.id || undefined);
            }}
          >
            {tab.title}
            {tab.isRequired && (
              <div className={b('item', { badge: true })}>
                <RequiredBadge />
              </div>
            )}
            {tab.tooltip && (
              <div className={b('item', { tooltip: true })}>
                <Tooltip text={tab.tooltip} />
              </div>
            )}
            {tab.info && (
              <div className={b('item', { info: true })} onClick={ev => ev.stopPropagation()}>
                <Info text={tab.info} />
              </div>
            )}
          </div>
        ))}
      </div>

      {isStrip ? <Content>{tabs[selectedTab]}</Content> : tabs[selectedTab]}
    </div>
  );
});

type TabProps = {
  /** @property {string} id - (optional) ID of tab */
  id?: string;
  /** @property {string} title - Tile of tab */
  title: string;
  /** @property {string} tooltip - (optional) Tooltip after title on tab */
  tooltip?: string;
  /** @property {JSX.Element} info - (optional) Show info with html */
  info?: JSX.Element;
  /** @property {boolean} isRequired - (optional) Badge of required */
  isRequired?: boolean;
  /** @property {boolean} isVisible - (optional) Tab is visibled */
  isVisible?: boolean;
  /** @property {boolean} isDisabled - (optional) Tab is disabled */
  isDisabled?: boolean;
  /** @property {(React.ReactNode | JSX.Element | false)[] | JSX.Element | null | false | string} children - (optional)
   * React inner JSX component */
  children?: (React.ReactNode | JSX.Element | false)[] | JSX.Element | null | false | string;
};

/**
 * Tab component for Tabs (See in file)
 *
 * @param {string} id - (optional) Tab id
 * @param {string} tooltip - (optional) Tooltip as (i) with title text
 * @param {JSX.Element} info - (optional) Show info with html
 * @param {boolean} isRequired - (optional) Add badge `isRequired` (red star)
 * @param {boolean} isVisible - (optional) Set tab is visible
 * @param {boolean} isDisabled - (optional) Disable this tab (view mode only) (mix class)
 * @returns JSX
 */
export const Tab = React.memo(({ children, title, isDisabled }: TabProps) => {
  return (
    <div className={b('inner').mix([isDisabled ? 'disabled' : undefined])} key={title}>
      <FormComponent.Wrapper>{children}</FormComponent.Wrapper>
    </div>
  );
});

import React, { useCallback } from 'react';

import { FormComponent, Radio, ReferenceItem, Select, SelectMode, TextInput, TextDateTime } from 'components';

import { Person, UserPermission } from 'types/models';
import { Item } from 'types/models/common';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { EducationStatus } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';
import { getMockEducation } from 'features/Form/looks/person/helpers';
import { Mode } from '..';

type Props = {
  mode?: Mode;
  education?: Person.ScientistEducation;
  onChange(education: Person.ScientistEducation): void;
  isAdd?: boolean;
  userPermission: UserPermission | null;
};

export function Fields({ mode, education = getMockEducation(), onChange, userPermission, isAdd }: Props) {
  const { settings } = useAppDataContext();

  const isDisabled = !!education?.isReadOnly;

  const makeChangeHandler = useCallback(
    (fieldName: keyof Person.ScientistEducation) => (value: Item | null | ReferenceItem | string) => {
      onChange({ ...education, [fieldName]: value });
    },
    [education, onChange],
  );

  const handleChangeRefDepartment = useCallback(
    (ref: ReferenceItem | null) => {
      onChange({ ...education, refDepartment: ref, department: ref?.label || '' });
    },
    [education, onChange],
  );

  const handleChangeRefSpeciality = useCallback(
    (ref: ReferenceItem | null) => {
      onChange({ ...education, refSpeciality: ref, speciality: ref?.label || '' });
    },
    [education, onChange],
  );

  const handleChangeDepartment = useCallback(
    (val: string) => {
      onChange({ ...education, refDepartment: null, department: val });
    },
    [education, onChange],
  );

  const handleChangeSpeciality = useCallback(
    (val: string) => {
      onChange({ ...education, refSpeciality: null, speciality: val });
    },
    [education, onChange],
  );

  const handleChangeIsTSU = useCallback(
    (value: string) => {
      const isTSU = value === 'isTSU';
      onChange({ ...education, isTSU: isTSU, enterprise: isTSU ? settings?.organization?.shortName || '' : '' });
    },
    [onChange, education, settings],
  );

  const changeStatus = useCallback(
    (option: Item) => {
      const prevStatus = education?.status;
      const isPrevStatusStudent = prevStatus?.value === EducationStatus.STUDENT;
      const isNextStatusStudent = option?.value === EducationStatus.STUDENT;
      if (isPrevStatusStudent || isNextStatusStudent) {
        makeChangeHandler('enterprise')(null);
      }

      onChange({ ...education, status: option });
    },
    [education, makeChangeHandler, onChange],
  );

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Статус" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={education?.status || (isAdd ? { value: EducationStatus.STUDENT, label: '' } : null)}
            settings={{ name: 'EducationStatus' }}
            onChange={changeStatus}
            isDisabled={isDisabled}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Radio
            value={education?.isTSU ? 'isTSU' : 'isNotTSU'}
            list={[
              { label: settings?.organization?.shortName, value: 'isTSU' },
              { label: 'Другой организации', value: 'isNotTSU' },
            ]}
            onChange={handleChangeIsTSU}
            isDisabled={isDisabled || mode === 'lk' || !isHasPermission(userPermission, Permits.PERSON_LOCAL_EDUCATION_CREATE)}
          />
        </FormComponent.Field>
        <FormComponent.Field labelSize="fit" label="Дата начала">
          <TextDateTime value={education?.dateReceipt} onChange={makeChangeHandler('dateReceipt')} isDisabled={isDisabled} />
        </FormComponent.Field>
        <FormComponent.Field labelSize="fit" label="Дата окончания">
          <TextDateTime
            value={education?.dateDismissal}
            onChange={makeChangeHandler('dateDismissal')}
            isDisabled={!isHasPermission(userPermission, Permits.PERSON_LOCAL_EDUCATION_CREATE)}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Организация" isRequired tooltip="Введите полное имя организации не используя аббревиатуру">
          <TextInput
            value={education?.enterprise || ''}
            onChange={makeChangeHandler('enterprise')}
            isDisabled={education?.isTSU || isDisabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {education?.isTSU ? (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Специальность">
              <Select
                mode={SelectMode.REFERENCE}
                value={education?.refSpeciality}
                onChange={handleChangeRefSpeciality}
                settings={{
                  name: education?.status?.value === EducationStatus.STUDENT ? 'RefEducationSpeciality' : 'RefVakSpeciality',
                  title: 'Справочник "Специальности"',
                  isClearable: true,
                }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение">
              <Select
                mode={SelectMode.REFERENCE}
                value={education?.refDepartment}
                onChange={handleChangeRefDepartment}
                settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"', isClearable: true }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      ) : (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Специальность">
              <TextInput value={education?.speciality ?? ''} onChange={handleChangeSpeciality} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение">
              <TextInput value={education?.department ?? ''} onChange={handleChangeDepartment} isDisabled={isDisabled} />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
    </FormComponent.Wrapper>
  );
}

import { RANGE_SEPARATOR } from './mask/helpers';

type FormatToFilterArguments = SingleFormatToFilterArguments | RangeFormatToFilterArguments;

type SingleFormatToFilterArguments = {
  type: 'single';
  isWithTime: boolean;
  value?: string;
};

type RangeFormatToFilterArguments = {
  type: 'range';
  isWithTime: boolean;
  from?: string;
  to?: string;
};

export const formatToOut = (args: FormatToFilterArguments) => {
  const prepare = (val: string | undefined) => {
    if (!val) {
      return '';
    }
    return val;
  };

  const isSingle = args.type === 'single';

  if (isSingle) {
    return prepare(args.value);
  }

  return `${prepare(args.from)}${RANGE_SEPARATOR}${prepare(args.to)}`;
};

export const isValidDate = (date: Date | undefined) => date instanceof Date && isFinite((date as unknown) as number);

export const parseDate = (date: Date | undefined): Date => {
  return isValidDate(date) ? (date as Date) : new Date('01/01/1970');
};

export const parseTime = (value?: string) => {
  const result: number = parseInt(value ?? '0', 10);

  if (Number.isNaN(result)) {
    return 0;
  }

  return result;
};

export const prepareTime = (value?: number) => {
  if (typeof value === 'number') {
    return value > 0 ? `${value}` : `0${value}`;
  }

  return value;
};

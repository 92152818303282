export function PartnerMipPaymentGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Источник данных для таблицы — сведения из документов бухгалтерского учёта. Для отображения данных в таблице необходимо
        выполнение трёх условий:
      </p>

      <ul>
        <li>
          <strong>Есть информационный обмен</strong> с информационной системой Бухгалтерского учёта университета;
        </li>
        <li>
          В <strong>научном проекте</strong> (модуль <strong>Проекты</strong>) <strong>на вкладке Заявки</strong> указан договор
          на использование интеллектуального права на объекты;
        </li>
        <li>
          В системе бухгалтерского учёта <strong>Документ о приходе средств по договору</strong> на использование
          интеллектуального права на объекты интеллектуальной собственности университета, в котором МИП — Лицензиат,{' '}
          <strong>связан с Шифром проекта</strong>.
        </li>
      </ul>
    </>
  );
}

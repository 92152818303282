import { useCallback, useMemo, useState } from 'react';

import { FileInfo, Person } from 'types/models';
import { ScientistEducation } from 'types/models/Person';
import { ValueOf } from 'types/helpers';
import { formatEducation, formatJob } from './helpers';
import { findActualJob } from 'shared/findActualJob';

type Arguments = {
  person: Person.ScientistPerson | null;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};

export function useController({ person, makeSetPerson }: Arguments) {
  const [isPublicationsOpened, setIsPublicationsOpened] = useState<boolean>(false);
  const [isFiveYear, setIsFiveYear] = useState<boolean>(false);

  const openPublicationsWindow = useCallback(
    (isFiveSelected: boolean) => {
      setIsFiveYear(isFiveSelected);
      setIsPublicationsOpened(true);
    },
    [setIsFiveYear, setIsPublicationsOpened],
  );

  const onCustomChange = (fileInfo: FileInfo) => {
    makeSetPerson('avatar')(fileInfo);
  };

  const formatNumbertoDate = (val: number) => (String(val).length === 2 ? String(val) : `0${val}`);

  const yearInterval = `01.01.${new Date().getFullYear() - 5} - ${formatNumbertoDate(new Date().getDate())}.${formatNumbertoDate(
    new Date().getMonth() + 1,
  )}.${new Date().getFullYear()} гг.`;

  const getCurentHirshIndex = useCallback(
    (systemId: string): Person.HirshIndex | null => {
      const getMaxDateIndex = (dates: number[]): number => {
        let max = dates[0];
        let currentIndex = 0;
        for (let i = 1; i < dates.length; i += 1) {
          const current = dates[i];
          if (max < current) {
            max = current;
            currentIndex = i;
          }
        }
        return currentIndex;
      };

      if (person) {
        const currentOrder = getMaxDateIndex(
          person.hirshIndexes.filter(index => index.citationSystem?.id === systemId).map(i => formatToCompareDates(i.date)),
        );
        return person.hirshIndexes.filter(index => index.citationSystem?.id === systemId)[currentOrder];
      }
      return null;
    },
    [person],
  );

  const formatToCompareDates = (val: string) => {
    const dates = val.split('.').map(i => parseInt(i, 10));
    return dates.length === 3 ? new Date(dates[2], dates[1] - 1, dates[0]).getTime() : 0;
  };

  const getActualEducation = useMemo(() => {
    const educations = person?.scientistEducations || [];
    const actualDate = new Date().getTime();
    let actualEducation: ScientistEducation | null = null;
    const sortedEducations = educations.sort((a, b) => parseInt(b.originalId, 10) - parseInt(a.originalId, 10));
    let actualEducationPriority = 5;
    sortedEducations.forEach(education => {
      if (education.isActive) {
        const startDate = education.dateReceipt ? formatToCompareDates(education.dateReceipt) || null : null;
        const endDate = education.dateDismissal ? formatToCompareDates(education.dateDismissal) || null : null;
        if ((startDate === null || startDate < actualDate) && (endDate === null || endDate > actualDate)) {
          if (education.status.value === 'LOCAL') {
            if (actualEducationPriority > 1) {
              actualEducation = education;
              actualEducationPriority = 1;
            }
          } else if (actualEducationPriority > 2) {
            actualEducation = education;
            actualEducationPriority = 2;
          }
        }
      }
    });
    return actualEducation ? formatEducation(actualEducation) : '';
  }, [person]);

  const getActualJob = useMemo(() => {
    const actualJob = findActualJob(person);
    return actualJob ? formatJob(actualJob) : '';
  }, [person]);

  const jobsCombinationList = useMemo(() => {
    const jobs = person?.scientistJobs || [];
    const actualDate = new Date().setHours(0, 0, 0, 0);
    const jobsList: Person.ScientistJob[] = [];
    const sortedJobs = jobs.sort((a, b) => formatToCompareDates(b.dateReceipt || '') - formatToCompareDates(a.dateReceipt || ''));
    sortedJobs.forEach(job => {
      if (job.isActive) {
        const startDate = job.dateReceipt ? formatToCompareDates(job.dateReceipt) || null : null;
        const endDate = job.dateDismissal ? formatToCompareDates(job.dateDismissal) || null : null;
        if ((startDate === null || startDate <= actualDate) && (endDate === null || endDate >= actualDate)) {
          jobsList.push(job);
        }
      }
    });
    return jobsList.map(job => formatJob(job)).filter(job => job !== getActualJob);
  }, [person, getActualJob]);

  const hirshIndexes = useMemo(() => {
    if (person) {
      return person.identifiers
        .map(id => ({
          label: id.citationSystem?.label,
          citationId: id.citationSystem?.id,
          value: getCurentHirshIndex(id.citationSystem?.id || '')?.value || '',
          date: getCurentHirshIndex(id.citationSystem?.id || '')?.date || '',
          link: id.link || '#',
          id: id.identifier,
        }))
        .filter(id => person.hirshIndexes.map(i => i.citationSystem?.id).indexOf(id.citationId) > -1)
        .sort((a, b) => (formatToCompareDates(a.date) < formatToCompareDates(b.date) ? 1 : -1));
    }
    return [];
  }, [person, getCurentHirshIndex]);

  return {
    openPublicationsWindow,
    isPublicationsOpened,
    isFiveYear,
    getActualJob,
    getActualEducation,
    closePublicationWindow: () => setIsPublicationsOpened(false),
    yearInterval,
    jobsCombinationList,
    hirshIndexes,
    onCustomChange,
  };
}

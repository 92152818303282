import React, { memo } from 'react';

import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';

const initialIncludeTransitoryBalanceCheckboxes: CheckboxItem[] = [
  {
    value: '1',
    label: '',
    isChecked: false,
  },
];

const IncludeTransitoryBalance = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultCheckboxList
    initialCheckboxList={initialIncludeTransitoryBalanceCheckboxes}
    parameterName={parameterName}
    parameters={parameters}
    setParameterValueByName={setParameterValueByName}
  />
);

const Component = memo(IncludeTransitoryBalance);

export { Component as IncludeTransitoryBalance };

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    /* Comment by 2087 */
    // {
    //   type: FilterComponentType.checkbox,
    //   streamKey: 'isPlanned',
    //   label: 'Планируется охрана РИД',
    //   checked: true,
    // },
    // {
    //   type: FilterComponentType.checkbox,
    //   streamKey: 'isInWork',
    //   label: 'ПИ в работе',
    //   checked: false,
    // },
    // {
    //   type: FilterComponentType.checkbox,
    //   streamKey: 'isFinished',
    //   label: 'ПИ завершенные',
    //   checked: false,
    // },
    // {
    //   type: FilterComponentType.divider,
    // },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: true,
    },
  ]);
});

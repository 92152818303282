import { Form } from 'types/models';
import { NirRequestKindConsumption } from 'types/models/KindConsumption';

type Props = {
  formFields: Form.Fields;
  nirRequestKindConsumption?: NirRequestKindConsumption;
};
export function validate(props: Props) {
  const { formFields } = props;
  const required = Object.keys(formFields)
    .filter(
      (fieldName: string) =>
        formFields[fieldName].required && formFields[fieldName]?.value?.length <= 0 && formFields[fieldName].title,
    )
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  const validations: { isValid: boolean; invalidMessage: string }[] = [];

  return [...required, ...validations];
}

import React, { Dispatch, useCallback, useState } from 'react';

import { showNotification } from 'features/Notifications';

import { Person } from 'types/models';
import { getMockPerson } from 'features/Form/looks/person/helpers';
import * as BackendAPI from 'services/BackendAPI';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { validateMainFields } from './helpers';
import { useFormContext } from 'features/Form/hooks';
import { Form as F } from 'types/models';

export type State = {
  localPerson: Person.ScientistPerson | null;
  setLocalPerson: Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
  closeForm(): void;
  submitForm(): void;
  isOpen: boolean;
};

export type HonorType = 'degree' | 'rank' | 'academicRank';

export type EditableHonor = {
  type?: HonorType;
} & Honor;

export type Honor = Person.ScientistDegree | Person.ScientistRank | Person.ScientistAcademicRank;

type Arguments = {
  onClose?(): void;
};

export function useController({ onClose }: Arguments): State {
  const { initialCountryCode } = useAppDataContext();
  const [localPerson, setLocalPerson] = useState<Person.ScientistPerson | null>(getMockPerson({ initialCountryCode }));
  const tableStreams = useLocalTableStreams();
  const {
    look: { isOpen },
  } = useFormContext<F.PersonFormLook>();
  const { methods: SaveScientistAPI } = BackendAPI.useBackendAPI('SaveScientist');

  const closeForm = useCallback(() => {
    if (onClose) {
      onClose();
    }
  }, [onClose]);

  const saveScientist = useCallback(() => {
    if (localPerson) {
      SaveScientistAPI.callAPI(
        { person: localPerson },
        {
          onSuccessfullCall: () => {
            tableStreams.reloadTable.push({});
            showNotification({ message: 'Персона успешно сохранена', theme: 'success' });
            closeForm();
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localPerson, closeForm]);

  const submitForm = useCallback(() => {
    if (!localPerson) {
      return;
    }

    const validateInfo = validateMainFields(localPerson);
    const isValidForm = validateInfo.every(x => x.isValid);
    if (!isValidForm) {
      validateInfo.forEach(({ isValid, invalidMessage }) => {
        if (!isValid) {
          setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
        }
      });
      return;
    }

    saveScientist();
  }, [localPerson, saveScientist]);

  return {
    localPerson,
    closeForm,
    setLocalPerson,
    submitForm,
    isOpen,
  };
}

import { FormComponent } from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';
import { FieldVisibleMode } from 'utils/Enums';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

export function Classifiers(props: Props) {
  const { project, makeChangeHandler, disabled } = props;
  const { settings } = useAppDataContext();

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Области научных интересов',
              info: (
                <p>
                  Справочник, который используется при описании конкурсов, персон, заявок и проектов, предназначен для
                  управленческого учёта и поиска ресурсов по разным направлениям.
                </p>
              ),
            }}
            rows={project?.scienceDomainInterrests ?? []}
            onChange={makeChangeHandler('scienceDomainInterrests')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "Области научных интересов"'
            refName="RefScienceDomainInterest"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: `Приоритетные направления развития ${settings?.organization?.shortName}`,
              info: (
                <p>
                  Используется для анализа данных для принятия управленческих решений и мониторинга выполнения ключевых
                  показателей стратегии и программы развития университета.
                </p>
              ),
            }}
            rows={project?.pnrs ?? []}
            onChange={makeChangeHandler('pnrs')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "ПНР"'
            refName="RefPnr"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Приоритетные направления развития модернизации и технического развития экономики России',
              info: <p>Используется в Отчёте по проектам за несколько лет с классификаторами.</p>,
            }}
            rows={project?.pnmitrs ?? []}
            onChange={makeChangeHandler('pnmitrs')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "ПНР модернизации РФ"'
            refName="RefPnmitr"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{ title: 'Отрасли знаний (РНФ)', info: <p>Используется при отчётности по программе РНФ.</p> }}
            rows={project?.knowledgeBranches ?? []}
            onChange={makeChangeHandler('knowledgeBranches')}
            toolbar={['add', 'delete', 'move']}
            columns={[
              {
                label: 'Отрасль знаний',
                formatValue: x => x.element?.label || '',
                styles: { width: '40%' },
              },
              {
                label: 'Группа (подгруппа)',
                formatValue: x => (x.element ? getKnowledgeBranchGroupLabel(x.element) : ''),
                styles: { width: '35%' },
              },
              { label: 'Создано', formatValue: x => `${x.createdBy} ${x.createdDate}`, styles: { width: '25%' } },
            ]}
            modalTitle='Справочник "Отрасли знаний (РНФ)"'
            refName="RefKnowledgeBranch"
            isDisabled={disabled}
            subReferenceFieldVisibleMode={FieldVisibleMode.SYSTEM}
          />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Сквозные технологии Национальной технологической инициативы',
              info: (
                <p>Используется для анализа данных для принятия управленческих решений, поиска технологий по ключевым словам.</p>
              ),
            }}
            rows={project?.ntiEteTechnologies ?? []}
            onChange={makeChangeHandler('ntiEteTechnologies')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "Сквозные технологии Национальной технологической инициативы"'
            refName="RefNtiEteTechnology"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Рынки Национальной технологической инициативы',
              info: (
                <p>Используется для анализа данных для принятия управленческих решений, поиска технологий по ключевым словам.</p>
              ),
            }}
            rows={project?.ntiMarkets ?? []}
            onChange={makeChangeHandler('ntiMarkets')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "Рынки Национальной технологической инициативы"'
            refName="RefNtiMarket"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Технологические платформы',
              info: (
                <p>Используется для анализа данных для принятия управленческих решений, поиска технологий по ключевым словам.</p>
              ),
            }}
            rows={project?.techPlatforms ?? []}
            onChange={makeChangeHandler('techPlatforms')}
            toolbar={['add', 'delete', 'move']}
            modalTitle='Справочник "Технологические платформы"'
            refName="RefTechnologyPlatform"
            isDisabled={disabled}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <RefElementList
            header={{
              title: 'Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)',
              info: <p>Используется в управленческом анализе данных по диссертационным советам.</p>,
            }}
            rows={project?.vaks ?? []}
            onChange={makeChangeHandler('vaks')}
            toolbar={['add', 'delete', 'move']}
            // eslint-disable-next-line max-len
            modalTitle='Справочник "Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)"'
            refName="RefVakSpeciality"
            isDisabled={disabled}
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import React, { useEffect } from 'react';

import { ButtonMode, FormComponent, Modal, Tab, Tabs, TextArea } from 'components';

import { Documents } from 'features/Documents';
import { Document } from 'features/Form/looks/securityDocument/SecurityDocumentForm/Tabs/Document';
import { Nominees } from 'features/IncentivePayments/Form/Tabs/Payment/Nominees';
import { Departments } from 'features/IncentivePayments/Form/Tabs/Payment/Departments';
import { Info } from 'features/IncentivePayments/Form/Tabs/Payment/Info';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { Toolbar } from './Toolbar';
import { useController } from './controller';
import { useFormContext } from 'features/Form/hooks';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function SecurityDocumentPaymentRequestForm({ viewMode, editMode, onClose }: Props) {
  const {
    authorPayment,
    workMode,
    securityDocument,
    securityDocumentFormFields,
    documents,
    setDocuments,
    nomineesChangeHandler,
    changeAuthorPayment,
    distributionChangeHandler,
    isOpenCommentForm,
    setIsOpenCommentForm,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    comment,
    setComment,
    handleStatusChange,
    handleFormSubmit,
    changePaymentStatus,
    onSave,
    newStatus,
    onChangeConfirm,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      getStatusRecordMessage(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            if (workMode === 'viewMode') {
              return 'Просмотр';
            }
            return 'Редактирование';
          })(),
          'Заявки на оплату объекта интеллектуальной собственности',
        ]
          .filter(Boolean)
          .join(' '),
        authorPayment?.id || '',
        authorPayment?.status?.label,
      ),
    );
  }, [authorPayment?.id, authorPayment?.status?.label, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <Toolbar
        paymentRequest={authorPayment}
        onSave={() => onSave(true, () => {}, true)}
        onChangeStatus={changePaymentStatus}
        onChangeConfirm={onChangeConfirm}
        isDisabled={workMode === 'viewMode'}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Просмотр ОИС">
            <Tabs>
              <Tab title="Документ">
                <Document
                  securityDocument={securityDocument ?? null}
                  setSecurityDocument={() => {}}
                  formFields={securityDocumentFormFields}
                  handleChangeProject={() => {}}
                  disabled
                />
              </Tab>
              <Tab title="Прикрепленные документы">
                <Documents documents={securityDocument.documents} setDocuments={() => {}} disabled />
              </Tab>
            </Tabs>
          </Tab>
          <Tab title="Расчёт стимулирующих выплат">
            <div>
              <Info disabled={!!viewMode} changeField={changeAuthorPayment} authorPayment={authorPayment} />
              <Nominees
                eventType={null}
                disabled={!!viewMode}
                authorPayment={authorPayment}
                setNominees={nomineesChangeHandler}
              />
              <Departments authorPayment={authorPayment ?? null} changeField={distributionChangeHandler} disabled={!!viewMode} />
            </div>
          </Tab>
          <Tab title="Документы">
            <Documents documents={documents} setDocuments={setDocuments} disabled={workMode === 'viewMode'} />
          </Tab>
          <Tab title="Журнал сообщений">
            <DataGrid specification={GetAuthorPaymentRequestFeedlineList({ paymentId: authorPayment.id || '-1' })} />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        title="Сохранение заявки"
        isOpen={!!isOpenCommentForm}
        onClose={() => setIsOpenCommentForm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setIsOpenCommentForm(false);
              handleFormSubmit();
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenCommentForm(false),
          },
        ]}
        size="large"
      >
        <FormComponent.Field label="Комментарий">
          <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
        </FormComponent.Field>
      </Modal>

      <Modal
        title="Изменение статуса заявки"
        isOpen={!!isOpenStatusForm}
        onClose={() => setIsOpenStatusForm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: () => {
              setIsOpenStatusForm(false);
              handleStatusChange(newStatus || '');
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenStatusForm(false),
          },
        ]}
        size="large"
      >
        <FormComponent.Field label="Комментарий">
          <TextArea settings={{ rows: 3, isFixed: true }} value={comment || ''} onChange={setComment} />
        </FormComponent.Field>
      </Modal>

      <Modal
        mode="warning"
        title="Изменение статуса заявки"
        isOpen={!!isOpenConfirmStatusForm}
        onClose={() => setIsOpenConfirmStatusForm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              setIsOpenConfirmStatusForm(false);
              setIsOpenStatusForm(true);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmStatusForm(false),
          },
        ]}
        size="small"
      >
        <>{checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, (newStatus as AuthorPaymentRequestStatus) ?? null)}</>
      </Modal>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SecurityDocumentPaymentRequestForm);

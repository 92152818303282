export function PartnerExpositionParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит список участий университета в выставках с экспонатами, разработанными совместно с организацией. Источник
        информации — функциональный модуль <strong>Мероприятия, Участия в выставке.</strong>
      </p>
      <p>Условие для отображения данных в таблице является выбор организации:</p>

      <ul>
        <li>
          <strong>На форме</strong> Участия в выставке, где указан формат участия <strong>«Презентация разработки»</strong>, в
          разделе <strong>Организации-партнёры</strong> на форме <strong>Разработка</strong>.
        </li>
      </ul>

      <div className="warning" data-label="Внимание!">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

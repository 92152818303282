import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Rntd } from 'types/models';
import { Project } from 'types/models/Project';
import { WorkMode } from 'types/models/Table';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  rntd?: Rntd | null;
  workMode: WorkMode;
};

export function useController(props: Props) {
  const { onSave, rntd, workMode } = props;

  const [isSaveConfirmPopupOpen, setIsSaveConfirmPopupOpen] = useState<boolean>(false);
  const [isSaveAndClose, setIsSaveAndClose] = useState<boolean>(false);
  const [isProjectInfoModalOpen, setIsProjectInfoModalOpen] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const onSaveConfirmPopupConfirm = useCallback(() => {
    if (isSaveAndClose) {
      onSave(true);
    }
    setIsSaveConfirmPopupOpen(false);
  }, [isSaveAndClose, onSave]);

  const isProjectInfoButtonDisabled = !rntd?.project?.id;

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const handleProjectInfoButtonClick = useCallback(() => {
    const projectId = rntd?.project?.id;
    if (projectId) {
      getProjectAPI.callAPI(
        { id: projectId },
        {
          onSuccessfullCall: ({ data }) => {
            setProject(data);
            setIsProjectInfoModalOpen(true);
          },
        },
      );
    }
  }, [rntd, getProjectAPI]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled: workMode === 'viewMode',
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSave(false),
        isDisabled: workMode === 'viewMode',
      },
      {
        icon: { type: 'info' },
        title: 'Краткая информация о проекте, в рамках которого получен РИД',
        onClick: handleProjectInfoButtonClick,
        isDisabled: isProjectInfoButtonDisabled,
      },
    ],
    [workMode, isProjectInfoButtonDisabled, handleProjectInfoButtonClick, onSave],
  );

  return {
    buttons,
    setIsSaveConfirmPopupOpen,
    isSaveConfirmPopupOpen,
    onSaveConfirmPopupConfirm,
    setIsSaveAndClose,
    rntd,
    project,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
  };
}

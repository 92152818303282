import { Table } from 'types/models';
import { Filters } from './filters';

import { Component as LeftPanelForThirdLevel } from '../GetEquipList/LeftPanelForThirdLevel';
import { makeUseCustomController, State } from '../GetEquipList/makeUseCustomController';

export function GetEquipAnalyticList(): Table.Specification<State> {
  return {
    apiID: 'GetEquipAnalyticList',
    header: {
      firstLevel: {
        title: 'Анализ и отчетность по оборудованию',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({ mode: 'analytics' }),
  };
}

import React, { useCallback, useMemo } from 'react';

import { ForeignSecurityDocument } from 'types/models';
import { SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { SecurityDocumentMemberType } from 'utils/Enums';

type Props = {
  foreignSecurityDocument: ForeignSecurityDocument;
  setForeignSecurityDocument: React.Dispatch<React.SetStateAction<ForeignSecurityDocument>>;
};

export function useController(props: Props) {
  const { setForeignSecurityDocument, foreignSecurityDocument } = props;

  const handleAuthorsChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setForeignSecurityDocument(prevPatent => {
        const members = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.APPLICANT) || [];
        return { ...prevPatent!, members: [...members, ...value] };
      });
    },
    [setForeignSecurityDocument],
  );

  const handleApplicantChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setForeignSecurityDocument(prevPatent => {
        const members = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [];
        return { ...prevPatent!, members: [...members, ...value] };
      });
    },
    [setForeignSecurityDocument],
  );

  const applicantPersonList = useMemo(
    () => foreignSecurityDocument?.members?.filter(m => m.type?.value === SecurityDocumentMemberType.APPLICANT) || [],
    [foreignSecurityDocument],
  );

  return {
    handleAuthorsChange,
    handleApplicantChange,
    applicantPersonList,
  };
}

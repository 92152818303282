import React from 'react';

import { ListEdit } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import * as BackendAPI from 'services/BackendAPI';

import { GetNirRequestConferenceParticipationList } from 'features/Table/specifications';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function Participations(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  const { methods: loadParticipationsAPI } = BackendAPI.useBackendAPI('GetNirRequestParticipation');

  return (
    <ListEdit
      header={{ title: 'Доклады на научных мероприятиях международного уровня' }}
      rows={nirRequest?.collectiveConferenceParticipations ?? []}
      onChange={makeChangeHandler('collectiveConferenceParticipations')}
      toolbar={['add', 'delete']}
      columns={[
        { label: 'Доклад', formatValue: x => x.bibliographicRecord || x.themeReport },
        {
          label: 'WoS/Scopus',
          formatValue: x => x.isWosScopus,
          styles: { textAlign: 'center' },
        },
        { label: 'Мероприятие', formatValue: x => x.event },
      ]}
      maxHeight="none"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'loadInstanceTableModal',
        relationTableModalTitle: 'Список докладов на конференциях',
        modalTableSpecification: GetNirRequestConferenceParticipationList({
          scientistIDs: (nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean),
        }),
        onStartLoad: (tableRows, onSuccess) => {
          loadParticipationsAPI.callAPI(
            { participationsIds: tableRows.map(x => x.id) },
            { onSuccessfullCall: ({ data }) => onSuccess(data) },
          );
        },
      }}
    />
  );
}

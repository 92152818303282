import { useEffect, useMemo } from 'react';
import { Report, Table } from 'types/models';

import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';

export type State = {
  isReportOpen: boolean;
  currentReport: Report | null;
  getReports: () => Map<string, string>;
  handleSetCurrentReport: ({ name, value }: { name: string; value: string }) => void;
  onReportClose: () => void;
  selectFund(fund: Table.Entry | null): void;
};

type Arguments = {
  isReloadingRef: React.MutableRefObject<boolean>;
  selectFund(fund: Table.Entry | null): void;
  selectedFund: Table.Entry | null;
};

export function makeUseCustomController({ isReloadingRef, selectedFund, selectFund }: Arguments) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    const [row] = selectedRows;

    const tableStreams = useLocalTableStreams();

    useEffect(() => {
      if (isReloadingRef.current) {
        tableStreams.reloadTable.push({});
        isReloadingRef.current = false;
      } else {
        selectFund(row ?? null);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row, selectFund, selectedFund]);

    const reports = useMemo<Report[]>(
      () => [Reports.MemorandumSummary, Reports.DepartmentsNominations, Reports.ConsolidatedStatement],
      [],
    );

    const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

    return {
      isReportOpen,
      currentReport,
      getReports,
      handleSetCurrentReport,
      onReportClose,
      selectFund,
    };
  };
}

export function PartnerEventGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит список организованных совместно с университетом мероприятий и мероприятий организации, в которых приняли
        участие сотрудники, аспиранты, студенты университета. Источник информации — функциональный модуль{' '}
        <strong>Мероприятия: Конференции, Выставки, Олимпиады и конкурсы.</strong>
        Условие для отображения данных в таблице является выбор организации:
      </p>

      <ul>
        <li>
          {/* eslint-disable-next-line max-len */}
          На форме редактирования мероприятия(конференции, конкурса, олимпиады),.», на вкладке Организация мероприятия, в разделе{' '}
          <strong>Организаторы мероприятия</strong>
        </li>
      </ul>

      <div className="warning">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

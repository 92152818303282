import React, { useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

export type Props = {
  onSave: (needClose: boolean) => void;
  onSaveAndContinue: () => void;
  onHelp: () => void;
  viewMode: boolean;
};

function EditPartnerPanel({ onSave, onSaveAndContinue, onHelp, viewMode }: Props) {
  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: onHelp,
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled: viewMode,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSaveAndContinue(),
        isDisabled: viewMode,
      },
    ],
    [onHelp, onSave, onSaveAndContinue, viewMode],
  );

  return <Toolbar buttons={buttons} mode="form" />;
}

const Component = React.memo(EditPartnerPanel);

export { Component as EditPartnerPanel };

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { SelectSecurityDocumentPopup } from 'features/Form/views/SelectSecurityDocumentPopup';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isAddButtonDisabled = !rowsAvailability.ALWAYS;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [securityDocumentId, setSecurityDocumentId] = useState<string | null>(null);
  const [isSelectFormOpen, setIsSelectFormOpen] = useState(false);
  const [isAddPaymentRequestFormOpen, setIsAddPaymentRequestFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const onSubmitSelectDocument = useCallback((id: string) => {
    setSecurityDocumentId(id);
    setIsSelectFormOpen(false);
    setIsAddPaymentRequestFormOpen(true);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'SecurityDocumentPaymentRequest',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  // const handleDeleteButtonClick = useCallback(() => {
  //   setIsDeleteConfirmPopupOpen(true);
  // }, []);

  // const handleAddButtonClick = useCallback(() => {
  //   setIsSelectFormOpen(true);
  // }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  // const handleEditButtonClick = useCallback(() => {
  //   setIsEditFormOpen(true);
  // }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddPaymentRequestFormOpen(false);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const onSelectProjectFormClose = useCallback(() => {
    setIsSelectFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Добавить',
        onClick: handleViewButtonClick,
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
      // {
      //   icon: { type: 'add' },
      //   title: 'Добавить',
      //   onClick: handleAddButtonClick,
      //   isDisabled: isAddButtonDisabled,
      // },
      // {
      //   icon: { type: 'edit' },
      //   title: 'Редактировать',
      //   onClick: handleEditButtonClick,
      //   isDisabled: isEditButtonDisabled,
      // },
      // {
      //   icon: { type: 'remove' },
      //   title: 'Удалить',
      //   onClick: handleDeleteButtonClick,
      //   isDisabled: isDeleteButtonDisabled,
      // },
    ],
    [handleViewButtonClick, rowsAvailability.SINGLE_SELECTED],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.SecurityDocumentPaymentRequestViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={handleTemplateCloseViewForm}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />

      {!isAddButtonDisabled && (
        <modalTemplates.SecurityDocumentPaymentRequestAddTemplate.Component
          isOpen={isAddPaymentRequestFormOpen}
          onClose={handleTemplateCloseAddForm}
          securityDocumentId={securityDocumentId || ''}
          relatedTableState={tableState}
        />
      )}

      {!isEditButtonDisabled && (
        <modalTemplates.SecurityDocumentPaymentRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>Заявка на основании "{tableState.selectedRows[0]?.RegulationParagraphAndName}". Удалить заявку?</>
        </Modal>
      )}

      <SelectSecurityDocumentPopup
        isOpen={isSelectFormOpen}
        onClose={onSelectProjectFormClose}
        onSubmit={onSubmitSelectDocument}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

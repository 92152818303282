import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { AwardsModal } from 'features/AwardsModal';
import { EditRelationsProfileModal } from 'features/EditRelationsProfileModal';
import { State } from '../makeUseCustomController';
import { useController } from './controller';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { BindParticipation } from 'features/Form/views';
import { DataGrid } from 'features/Table/container';
import { GetPublicationForParticipationList } from '../../GetPublicationForParticipationList';
import { ParticipationFinancicalSupportPopup } from 'features/Form/views/ParticipationFinancicalSupportPopup';
import { PersonalConferenceParticipationGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Toolbar(props: Props) {
  const {
    tableState,
    customState: { loadParticipation, closeAwards, isShowAwards, participation },
  } = props;

  const {
    buttons,
    isEditDepartmentsOpen,
    handleCloseEditDepartments,
    isConfirmDeleteOpen,
    handleCloseConfirmDelete,
    handleDeleteParticipation,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    handleTemplateCloseAddForm,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isWarningPopupOpen,
    handleCloseWarningPopup,
    handleAttachParticipation,
    userSystemDepartment,
    selectedRow,
    isPublicationModalOpen,
    setIsPublicationModalOpen,
    submitPublicationModal,
    isFinancicalSupportPopupOpen,
    setIsFinancicalSupportPopupOpen,
    submitFinancialPopupCallback,
  } = useController({ tableState, loadParticipation });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isEditDepartmentsOpen && (
        <EditRelationsProfileModal
          type="PARTICIPATION"
          isOpen={isEditDepartmentsOpen}
          onClose={handleCloseEditDepartments}
          id={tableState.selectedRows[0]?.id || ''}
          errorText="У вас есть право добавить связь только со своими подразделениями.
Внимание: право редактировать запись об участии мероприятии (конференции, выставке, творческом мероприятии/олимпиаде,
творческом мероприятии)
 у вас появится при добавлении Основного подразделения.
Если вы видите ошибки в публикации в статусе «Утверждена», то напишите сообщение Администратору.
 В тексте сообщения обязательно укажите ID записи об участии в мероприятии."
          title="Редактировать связь публикации с подразделениями"
          participationType={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
        />
      )}

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteOpen}
        onClose={handleCloseConfirmDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteParticipation,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseConfirmDelete,
          },
        ]}
        size="small"
      >
        <>({tableState.selectedRows[0]?.EventName}) Удалить участие?</>
      </Modal>

      {isEditFormOpen && (
        <modalTemplates.PaticipationEditTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
        />
      )}

      {isViewFormOpen && (
        <modalTemplates.PaticipationViewTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ParticipationAddTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}

      <AwardsModal isOpen={isShowAwards} onClose={closeAwards} awards={participation?.awards || []} />

      {isWarningPopupOpen && (
        <BindParticipation
          isWarningPopupOpen={isWarningPopupOpen}
          handleCloseWarningPopup={handleCloseWarningPopup}
          handleAttachParticipation={handleAttachParticipation}
          selectedRow={selectedRow}
          userSystemDepartment={userSystemDepartment}
          eventType="EVENT_CONFERENCE"
        />
      )}

      <Modal
        title="Список публикаций"
        isOpen={isPublicationModalOpen}
        onClose={() => setIsPublicationModalOpen(false)}
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid
          specification={{
            ...GetPublicationForParticipationList({ participationId: selectedRow?.id }),
            onSubmitTable: submitPublicationModal,
          }}
        />
      </Modal>

      <ParticipationFinancicalSupportPopup
        participationId={tableState.selectedRows[0]?.id ?? '-1'}
        isOpen={isFinancicalSupportPopupOpen}
        onClose={() => setIsFinancicalSupportPopupOpen(false)}
        relatedTableState={tableState}
        onSuccess={submitFinancialPopupCallback}
        workMode="editMode"
        participationTypeCode={ParticipationTypes.PARTICIPATION_CONFERENCE.code}
      />

      <Modal
        mode="help"
        title="Помощь в работе с разделом «Участие в конференциях»"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PersonalConferenceParticipationGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

import { AuthorPayment } from 'types/models/Payment';

export function getMockAuthorPayment(authorPayment?: Partial<AuthorPayment>): AuthorPayment {
  return {
    id: null,
    type: null,
    year: '',
    quarter: null,
    month: '',
    status: null,
    statusChangedBy: null,
    createdBy: null,
    distributionMethodBetweenNominees: null,
    distributionMethodBetweenFunds: null,
    maxFundMoney: '',
    factFundMoney: '',
    remainderFundMoney: '',
    factNomineesMoney: '',
    maxNomineesMoney: '',
    remainderNomineesMoney: '',
    documents: [],
    nominees: [],
    departments: [],
    regulationParagraph: null,
    regulationName: null,
    statusChangedDate: '',
    article: null,
    conference: null,
    publication: null,
    participation: null,
    isManualEdit: false,
    patent: null,
    ...authorPayment,
  };
}

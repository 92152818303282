import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { ProjectCode } from 'types/models/Project';
import {
  convertGenerateProjectCodeResponse,
  convertProjectCode,
} from 'services/BackendAPI/configurations/projectCode/converters';
import { convertProjectCodeToXML } from 'services/BackendAPI/configurations/projectCode/requestConverters';

import { Settings } from 'types/models/common';

export const projectCodeConfigurations = {
  SaveProjectCode: makeBackendAPIConfiguration({
    id: 'SaveProjectCode',
    endpoint: '/msa/service/commands/SaveProjectCode',
    convertInputToXMLElement: (inputData: ProjectCode, undefined, isTSU) => ({
      Request: {
        _attr: {
          commandName: 'SaveProjectCode',
        },
        ProjectCode: { ...convertProjectCodeToXML(inputData, false, isTSU) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ReplaceProjectCode: makeBackendAPIConfiguration({
    id: 'ReplaceProjectCode',
    endpoint: '/msa/service/commands/ReplaceProjectCode',
    convertInputToXMLElement: (inputData: ProjectCode, undefined, isTSU) => ({
      Request: {
        _attr: {
          commandName: 'ReplaceProjectCode',
        },
        ProjectCode: { ...convertProjectCodeToXML(inputData, true, isTSU) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  GetProjectCode: makeBackendAPIConfiguration({
    id: 'GetProjectCode',
    endpoint: '/msa/service/commands/GetProjectCode',
    convertInputToXMLElement: (inputData: { id?: string | null; projectCode?: ProjectCode | null }, undefined, isTSU) => {
      const { id, projectCode } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GetProjectCode',
          },
          ProjectCode: {
            ...(id ? { _attr: { id } } : {}),
            ...(projectCode ? convertProjectCodeToXML(projectCode, false, isTSU) : {}),
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertProjectCode(response.Response.ProjectCode, settings),
  }),
  GenerateProjectCode: makeBackendAPIConfiguration({
    id: 'GenerateProjectCode',
    endpoint: '/msa/service/commands/GenerateProjectCode',
    convertInputToXMLElement: (inputData: { departmentCode: string | null; letterId: string | null; year: string | null }) => {
      const { departmentCode, letterId, year } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'GenerateProjectCode',
          },
          GenerateProjectCode: {
            _attr: { departmentCode, letterId, year },
          },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertGenerateProjectCodeResponse(response.Response),
  }),
  ProcessProjectChanges: makeBackendAPIConfiguration({
    id: 'ProcessProjectChanges',
    endpoint: '/msa/service/commands/ProcessProjectChanges',
    convertInputToXMLElement: (inputData: { ids: string[] }) => {
      const { ids } = inputData;
      return {
        Request: {
          _attr: {
            commandName: 'ProcessProjectChanges',
          },
          ChangeInProject: ids.map(id => ({ _attr: { id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

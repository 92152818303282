import React from 'react';

import { Table } from 'types/models';
import { Modal, FormComponent } from 'components';

type Props = {
  isViewModalOpen: boolean;
  onViewModalClose(): void;
  selectedRow: Table.Entry;
};

export function ViewModal({ isViewModalOpen, onViewModalClose, selectedRow }: Props) {
  return (
    <Modal isOpen={isViewModalOpen} onClose={onViewModalClose} title="Просмотр данных о заблокированной записи" size="large">
      <FormComponent.Wrapper>
        <FormComponent.Field label="Заблокирована в течении">
          <div>{selectedRow?.lockDuration}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Будет разблокирована через">
          <div>{selectedRow?.unlockIn}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Тип записи">
          <div>{selectedRow?.typeValue}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Запись">
          <div>{selectedRow?.label}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Пользователь">
          <div>{`${selectedRow?.editor} (${selectedRow?.contact})`}</div>
        </FormComponent.Field>
        <FormComponent.Field label="Подразделение">
          <div>{`${selectedRow?.faculty} (${selectedRow?.department})`}</div>
        </FormComponent.Field>
      </FormComponent.Wrapper>
    </Modal>
  );
}

import { ProjectAssignment } from 'types/models/Project';
import { convertFileToXML } from '../commonConverters';

export function convertProjectAssignmentToXML(pa: ProjectAssignment) {
  return {
    _attr: pa.id ? { id: pa.id } : {},
    Project: { _attr: { id: pa.projectId } },
    Date: pa.date,
    Assignment: pa.assignment,
    ...(pa.upToDate ? { UpToDate: pa.upToDate } : {}),
    ...convertFileToXML('File', pa.file),
    ProjectScientists: {
      ...(pa.projectScientists && pa.projectScientists.length > 0
        ? {
            ProjectScientist: pa.projectScientists.map(d => ({ _attr: { id: d.id } })),
          }
        : {}),
    },
  };
}

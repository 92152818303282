import React from 'react';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { PersonalProjectGridHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar({ tableState, customState }: Props) {
  const {
    buttons,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isOpenNoAccessWindow,
    handleCloseNoAccessWindow,
  } = useController({ tableState, customState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Доступ ограничен"
        isOpen={isOpenNoAccessWindow}
        onClose={handleCloseNoAccessWindow}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: handleCloseNoAccessWindow,
          },
        ]}
        size="small"
      >
        <>Эта функция доступна только для руководителя или ответственного исполнителя проекта</>
      </Modal>

      <Modal mode="help" title="Мои научные проекты" isOpen={isHelpFormOpen} onClose={handleTemplateCloseHelpForm} size="medium">
        {PersonalProjectGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const ProjectStateRegistrationReportFiltersHelp = (
  <>
    <p>
      <strong>Год</strong> - отображает список этапов проекта в графике госрегистрации отчётов в год, указанный в фильтре.
    </p>
    <p>
      <strong>Все</strong> - отображает список этапов проектов с запланированными и зарегистрированными отчётами.
    </p>
    <p>
      <strong>Все + Год</strong> - отображает список этапов проектов, которые закачиваются в указанном году.
    </p>
    <p>
      <strong>Без номера ИК</strong> - отображает список этапов проекта в графике госрегистрации отчётов, у которых отсутствует
      Номер госрегистрации отчёта (ИК).
    </p>
    <p>
      <strong>Без номера + Год</strong> — отображает список этапов проекта в графике госрегистрации отчётов в указанный год, у
      которых отсутствует Номер госрегистрации отчёта (ИК).
    </p>
    <p>
      <strong>Зарегистрированные в ЕГИСУ</strong> - отображает список этапов проекта, у которых для отчёта указан Номер
      госрегистрации (ИК).
    </p>
    <p>
      <strong>Зарегистрированные в ЕГИСУ + Год</strong> — отображает список зарегистрированных отчётов, по этапам проекта
      указанного фильтре года (для отчёта указан Номер госрегистрации)
    </p>
  </>
);

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: ProjectStateRegistrationReportFiltersHelp,
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'filter',
      list: [
        { label: 'Все', value: 'all' },
        { label: 'Без номера ИК', value: 'withoutNumber' },
        { label: 'Зарегистрированные в ЕГИСУ', value: 'registered' },
      ],
      value: 'all',
    },
    {
      type: FilterComponentType.year,
      streamKey: 'year',
      label: 'Год',
      checked: false,
    },
  ]);
});

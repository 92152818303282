import { useState, useCallback, useEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { SubmitTable } from 'features/Table/streams';
import { GetReferenceElementList } from 'features/Table/specifications';
import { usePrevious } from 'shared/react/usePrevious';
import { Props as IndexProps } from './';

type Props = Pick<IndexProps, 'value' | 'reference' | 'setColumnFilter' | 'presetFilters'>;

const useController = ({ value, reference, setColumnFilter, presetFilters }: Props) => {
  const [selectedRefs, setSelectedRefs] = useState<ReferenceItem[]>([]);
  const [isRelationTableOpen, setIsRelationTableOpen] = useState<boolean>(false);

  const { methods: GetReferenceElementsAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const reset = useCallback(() => {
    setSelectedRefs([]);
    setColumnFilter('');
  }, [setColumnFilter]);

  const openRelationTable = useCallback(() => {
    setIsRelationTableOpen(true);
  }, []);

  const closeRelationTable = useCallback(() => {
    setIsRelationTableOpen(false);
  }, []);

  const onSubmit = useCallback(
    (state: SubmitTable) => {
      const nextSelectedRefs = state.selectedRows.map(({ id, label }) => ({ id, label }));
      const selectedIds = state.selectedRows.map(({ id }) => id);
      setSelectedRefs(nextSelectedRefs);
      setIsRelationTableOpen(false);
      setColumnFilter(selectedIds.join(','));
    },
    [setColumnFilter],
  );

  const specification = {
    ...GetReferenceElementList(
      { requestData: { filters: presetFilters, name: reference }, isCanSelectOnlyOneRow: false },
      reference,
    ),
    onSubmitTable: onSubmit,
    initialSelectedRows: {
      uniqKey: 'id',
      selectedValues: selectedRefs.map(({ id }) => id),
    },
  };

  const textFieldValue = useMemo(() => selectedRefs.map(({ label }) => label).join(', '), [selectedRefs]);

  const prevValue = usePrevious(value);

  useEffect(() => {
    const isValueNotEqualPrevValue = prevValue !== value;
    if (isValueNotEqualPrevValue && value) {
      const ids = value.split(',');
      GetReferenceElementsAPI.callAPI(
        { filters: [], referenceName: reference, childIds: ids },
        {
          onSuccessfullCall: ({ data }) => {
            setSelectedRefs(data);
          },
        },
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [prevValue, value]);

  return {
    selectedRefs,
    specification,
    textFieldValue,
    isRelationTableOpen,
    reset,
    openRelationTable,
    closeRelationTable,
  };
};

export default useController;

import { parse } from 'date-fns';

import { formatStr } from 'utils/Constants';

export const getDate = (date: string) => parse(date, formatStr, new Date());
export const getTimestamp = (date: string) => getDate(date).getTime();
export const getYear = (date: string) => getDate(date).getFullYear();
export const getCurrentDateTimestamp = () => new Date().setHours(0, 0, 0, 0);

export function isDateIntersect(startDateA: string, endDateA: string, startDateB: string, endDateB: string) {
  const startA = getTimestamp(startDateA);
  const endA = getTimestamp(endDateA);
  const startB = getTimestamp(startDateB);
  const endB = getTimestamp(endDateB);
  return (startA <= startB && startB <= endA) || (startA <= endB && endB <= endA) || (startB < startA && endA < endB);
}

export function mergePeriods(startA: string, endA: string, startB: string, endB: string): { startDate: string; endDate: string } {
  const startDate = [startA, endA, startB, endB].reduce((a, b) => (getTimestamp(a) < getTimestamp(b) ? a : b));
  const endDate = [startA, endA, startB, endB].reduce((a, b) => (getTimestamp(a) > getTimestamp(b) ? a : b));
  return { startDate, endDate };
}

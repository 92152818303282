import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { SecurityDocumentType } from 'utils/Enums';

type Arguments = {
  documentType?: SecurityDocumentType | null;
  notPayment?: boolean;
  needPayment?: boolean;
};

export function GetSecurityDocumentPaymentList(args?: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetSecurityDocumentPaymentList',
    requestData: args,
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Календарь оплаты пошлин за регистрацию и поддержку охранных документов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextAreaMode, TextDateTime } from 'components';

import { Project, Table } from 'types/models';
import { useController } from './controller';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';

type Props = {
  projectId: string | null;
  jobPeriod: Project.JobPeriod;
  setJobPeriod(jobPeriod: Project.JobPeriod): void;
};

export function Fields(props: Props) {
  const { projectId, jobPeriod, setJobPeriod } = props;

  const {
    makeChangeHandler,
    performerOrderModalTableRowConverter,
    performerOrderModalTableSpecification,
    handleStartOrderChange,
    handleEndOrderChange,
  } = useController({ jobPeriod, setJobPeriod, projectId });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Роль">
          <Select
            mode={SelectMode.ENUM}
            value={jobPeriod.role}
            settings={{ name: 'ProjectScientistRole' }}
            onChange={(option: Item) => makeChangeHandler('role')(option as ValueOf<Project.JobPeriod>)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Дата начала работы">
          <TextDateTime
            value={jobPeriod?.startDate || ''}
            onChange={(value: string) => {
              setJobPeriod({ ...jobPeriod, startDate: value || '' } as Project.JobPeriod);
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="На основании приказа" labelSize="fit">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'На основании приказа',
              table: {
                specification: performerOrderModalTableSpecification,
                onSelect: (row: Table.Entry | null) =>
                  handleStartOrderChange(row ? performerOrderModalTableRowConverter(row) : null),
              },
            }}
            value={jobPeriod?.startDateOrder?.number}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Дата окончания работы">
          <TextDateTime
            value={jobPeriod?.endDate || ''}
            onChange={(value: string) => {
              setJobPeriod({ ...jobPeriod, endDate: value || '' } as Project.JobPeriod);
            }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="На основании приказа" labelSize="fit">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'На основании приказа',
              table: {
                specification: performerOrderModalTableSpecification,
                onSelect: (row: Table.Entry | null) =>
                  handleEndOrderChange(row ? performerOrderModalTableRowConverter(row) : null),
              },
            }}
            value={jobPeriod?.endDateOrder?.number}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={jobPeriod.note}
            onChange={makeChangeHandler('note')}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

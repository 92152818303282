import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { ConferenceTypeProps } from 'types/models/Event';
import { EventTypes } from 'utils/Enums/EventTypes';
import { Modal } from 'components';

const ConferenceParticipatiotAddTemplate: React.FC<F.TemplateProps & ConferenceTypeProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  controllerIsForm,
}: F.TemplateProps & ConferenceTypeProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.EventFormLook = {
    apiID: 'GetParticipation',
    template: EventTypes.EVENT_CONCERT.template,
    type: EventTypes.EVENT_CONCERT.code,
    relatedTableState,
    controllerIsForm,
    arm: 'pc',
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ConferenceParticipatiotAddTemplate);

import React from 'react';

import { Checkbox, FormComponent, ListEdit, Radio, SectionTitle } from 'components';
import { ValueOf } from 'types/helpers';
import { getEnumItem } from 'utils/Helpers';
import { useAppDataContext } from 'features/AppData/context';

import { isNumber, showErrors } from 'utils/Validators';
import { AuthorPayment } from 'types/models/Payment';
import { DistributionMethodBetweenFunds } from 'utils/Enums';
import { DistributionMethodBetweenFundsHelp } from 'features/IncentivePayments/Form/Toolbar/help';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  authorPayment: AuthorPayment | null;
  disabled: boolean;
  changeField(key: keyof AuthorPayment, value: ValueOf<AuthorPayment>): void;
};

export function Departments(props: Props) {
  const { authorPayment, changeField, disabled } = props;

  const { enumMap } = useAppDataContext();

  const nomineesCount: Record<DistributionMethodBetweenFunds, number> = {
    [DistributionMethodBetweenFunds.AFFILIATED]: (authorPayment?.nominees || []).filter(x => x.isAffiliated).length,
    [DistributionMethodBetweenFunds.PAID]: (authorPayment?.nominees || []).filter(x => x.isPaid).length,
    [DistributionMethodBetweenFunds.ALL]: (authorPayment?.nominees || []).length,
  };

  return (
    <>
      <SectionTitle title="Распределение фонда подразделений (по алгоритму)" />

      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '700px' }}>
          <ListEdit
            header={{
              title: 'Алгоритм расчёта доли факультета за 1 номинанта (X)',
              info: DistributionMethodBetweenFundsHelp(),
            }}
            rows={[
              { label: 'По аффилированным', value: DistributionMethodBetweenFunds.AFFILIATED },
              { label: 'По назначенным к оплате', value: DistributionMethodBetweenFunds.PAID },
              { label: 'По всем номинантам', value: DistributionMethodBetweenFunds.ALL },
            ]}
            onChange={() => {}}
            columns={[
              {
                label: ' ',
                formatValue: row => (
                  <Radio
                    name="distributionMethodBetweenFunds"
                    value={authorPayment?.distributionMethodBetweenFunds?.value === row.value ? row.value : (null as any)}
                    list={[{ label: '', value: row.value }]}
                    onChange={val =>
                      changeField('distributionMethodBetweenFunds', getEnumItem('DistributionMethodBetweenFunds', val, enumMap))
                    }
                  />
                ),
                dataKind: 'jsx',
                styles: { width: '5%' },
              },
              { label: 'Алгоритм', formatValue: row => row.label, styles: { width: '55%' } },
              {
                label: 'Кол-во чел',
                formatValue: row => nomineesCount[row.value],
                dataKind: 'int',
                styles: { width: '20%' },
              },
              {
                label: 'X',
                formatValue: row =>
                  isNumber(authorPayment?.maxFundMoney) && !!nomineesCount[row.value]
                    ? Number(authorPayment?.maxFundMoney) / nomineesCount[row.value]
                    : '',
                dataKind: 'float',
                styles: { width: '20%' },
              },
            ]}
            specification={{ mode: 'customComponent', renderComponent: () => <></> }}
            isDisabled
            isSelectableRows={false}
          />
          <FormComponent.Field label="Редактировать вручную">
            <Checkbox checked={authorPayment?.isManualEdit} onChange={checked => changeField('isManualEdit', checked)} />
          </FormComponent.Field>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: 'calc(100% - 700px)' }}>
          <ListEdit
            rows={authorPayment?.departments ?? []}
            onChange={value => changeField('departments', value)}
            toolbar={['edit']}
            columns={[
              { label: '№', formatValue: (_, index) => String(index + 1), styles: { width: '5%' } },
              { label: 'Факультет/Институт', formatValue: x => x.topLevelDepartment?.label || '', styles: { width: '50%' } },
              { label: 'Номинанты к оплате', formatValue: x => x.nomineeQuantity, styles: { width: '15%' } },
              { label: 'Доля факультета', formatValue: x => x.fraction, styles: { width: '15%' } },
              {
                label: 'Сумма, руб.',
                formatValue: x => x.amountMoney,
                dataKind: 'float',
                styles: { width: '15%' },
              },
            ]}
            maxHeight="none"
            defaultRowsCount={6}
            isDisabled={!authorPayment?.isManualEdit || disabled}
            specification={{
              mode: 'customComponent',
              renderComponent: (department, setDepartment) => <Fields department={department} setDepartment={setDepartment} />,
              validation: {
                checkIsValid: author => validate(author).every(x => x.isValid),
                onInvalidate: val => showErrors(validate, val),
              },
            }}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

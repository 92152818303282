import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Project, Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { State } from '../makeUseCustomController';
import { ProjectModule, ProjectType } from 'utils/Enums';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function useController({ customState, tableState }: Props) {
  const { userPermission } = useAppDataContext();

  const { toggleVisibleForm, setMode } = customState;

  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const [isProcessModalOpen, setIsProcessModalOpen] = useState<boolean>(false);
  const [project, setProject] = useState<Project.Project | null>(null);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: getProject } = BackendAPI.useBackendAPI('GetProject', {
    onSuccessfullCall: ({ data }) => {
      setProject(data);
      setIsProcessModalOpen(true);
    },
  });

  const reports = useMemo<Report[]>(
    () => [Reports.ProjectIkrbs, Reports.ProjectResultsDetail, Reports.ProjectPerformersForCitis],
    [],
  );

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleViewButtonClick = useCallback(() => {
    setMode('view');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const handleEditButtonClick = useCallback(() => {
    setMode('edit');
    toggleVisibleForm();
  }, [setMode, toggleVisibleForm]);

  const projectId = useMemo(() => {
    if (selectedRow) {
      return selectedRow.ProjectID as string;
    }
  }, [selectedRow]);

  const projectType = useMemo(() => {
    if (selectedRow) {
      return selectedRow['id:ProjectType'] as ProjectType;
    }
  }, [selectedRow]);

  const viewPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_VIEW,
      GRANT: Permits.GRANT_VIEW,
      GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_VIEW,
      NIR: Permits.RESEARCH_JOB_VIEW,
    }),
    [],
  );

  const editPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_EDIT,
      GRANT: Permits.GRANT_EDIT,
      GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_EDIT,
      NIR: Permits.RESEARCH_JOB_EDIT,
    }),
    [],
  );

  const isViewHidden = useMemo(() => !isHasPermission(userPermission, Object.values(viewPermitsMap)), [
    userPermission,
    viewPermitsMap,
  ]);

  const isEditHidden = useMemo(() => !isHasPermission(userPermission, Object.values(editPermitsMap)), [
    editPermitsMap,
    userPermission,
  ]);

  const isViewDisabled = useMemo(() => {
    if (projectType) {
      return !isHasPermission(userPermission, viewPermitsMap[projectType]);
    }
    return true;
  }, [projectType, userPermission, viewPermitsMap]);

  const isEditDisabled = useMemo(() => {
    if (projectType) {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, userPermission]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isHidden: isViewHidden,
        isDisabled: isViewDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактирование',
        onClick: handleEditButtonClick,
        isHidden: isEditHidden,
        isDisabled: isEditDisabled,
      },
      {
        icon: { type: 'magazine', mode: 'edit' },
        title: 'Составить график госрегистрации отчётов проекта',
        onClick: () => getProject.callAPI({ id: selectedRow?.ProjectID, modules: [ProjectModule.REPORTS, ProjectModule.STAGES] }),
        isHidden: isEditHidden,
        isDisabled: isEditDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      getProject,
      getReports,
      handleEditButtonClick,
      handleSetCurrentReport,
      handleViewButtonClick,
      isEditDisabled,
      isEditHidden,
      isViewDisabled,
      isViewHidden,
      selectedRow?.ProjectID,
    ],
  );

  return {
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    projectId,
    isProcessModalOpen,
    setIsProcessModalOpen,
    project,
    setProject,
    isHelpOpen,
    setIsHelpOpen,
  };
}

import { memo } from 'react';
import { setup } from 'bem-cn';

import { FormComponent, Select } from 'components';

import { Item } from 'types/models/common';
import useController from './controller';
import { BuilderProps } from 'features/BuildReportPopup/models';

const block = setup({ mod: '--' });
const printFormat = block('print-format');

const PrintFormat = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { canBuild, currentValue, setNextValueId, printFormatParameter, values } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <div className={printFormat()}>
          <FormComponent.Line>
            <FormComponent.Field label="Формат печати" isRequired={!!printFormatParameter?.required}>
              <Select value={currentValue} options={values} onChange={(option: Item) => setNextValueId(option.value)} />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
      )}
    </>
  );
};

const Component = memo(PrintFormat);

export { Component as PrintFormat };

import React from 'react';

import { Modal } from 'components';
import { DataGrid } from 'features/Table';
import { getGridSettingsSpecification } from 'features/Table/specifications';
import { SubmitTable } from 'features/Table/streams';
import { Props as ControllerProps } from 'features/Table/specifications/GetGridSettings/makeUseCustomController';

import './styles.scss';

type Props = {
  isOpen: boolean;
  onSubmitTable: (gridState: SubmitTable) => void;
  onClose(): void;
} & ControllerProps;

const TemplatesConfigurationModal: React.FC<Props> = ({
  isOpen,
  columns,
  gridName,
  currentSetting,
  onClose,
  onSubmitTable,
  crudCallback,
}: Props) => {
  const gridSettingsSpecification = getGridSettingsSpecification({
    columns,
    currentSetting,
    gridName,
    crudCallback,
  });

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Выберите шаблон двойным кликом" size={['large', 'small-height']}>
      <DataGrid specification={{ ...gridSettingsSpecification, onSubmitTable }} />
    </Modal>
  );
};

export { TemplatesConfigurationModal };

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { CommunityType } from 'utils/Enums';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { Template } from 'types/models/Form';
import { isHasPermission } from 'features/AppData';

type Props = {
  tableState: Table.State;
  communitiesFilter: string;
};

export const useController = ({ tableState, communitiesFilter }: Props) => {
  const { settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const organizationName = settings?.organization?.shortName;

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;

  const templateName: Template = 'CommunityMemberForm';

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const isEditPermitted = useMemo(() => {
    return (
      isHasPermission(userPermission, Permits[`RECOGNITION_${selectedRow?.['id:Status']}_EDIT` as keyof Permits]) ||
      communitiesFilter === 'mine'
    );
  }, [selectedRow, userPermission, communitiesFilter]);

  const isDeletePermitted = useMemo(() => {
    return (
      isHasPermission(userPermission, Permits[`RECOGNITION_${selectedRow?.['id:Status']}_DELETE` as keyof Permits]) ||
      communitiesFilter === 'mine'
    );
  }, [selectedRow, userPermission, communitiesFilter]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'CommunityMember',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.RECOGNITION_ADD },
        isHidden: communitiesFilter === 'all',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: [Permits.RECOGNITION_ADDED_EDIT, Permits.RECOGNITION_APPROVED_EDIT] },
        isHidden: communitiesFilter === 'all',
        isDisabled: isEditButtonDisabled || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: [Permits.RECOGNITION_ADDED_DELETE, Permits.RECOGNITION_APPROVED_DELETE] },
        isHidden: communitiesFilter === 'all',
        isDisabled: isDeleteButtonDisabled || !isDeletePermitted,
      },
    ],
    [
      handleHelpButtonClick,
      handleViewButtonClick,
      isViewButtonDisabled,
      handleAddButtonClick,
      communitiesFilter,
      handleEditButtonClick,
      isEditButtonDisabled,
      isEditPermitted,
      handleDeleteButtonClick,
      isDeleteButtonDisabled,
      isDeletePermitted,
    ],
  );

  const communityTypes = [
    CommunityType.SCIENTIFIC_COUNCIL,
    CommunityType.COLLABORATION,
    CommunityType.FUND,
    CommunityType.MAGAZINE_REDACTION,
  ];

  return {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    communityTypes,
    organizationName,
  };
};

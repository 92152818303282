import * as XML from 'xml-js';

import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { FundCard } from 'types/models';
import { convertFundCard } from './converters';
import { convertFundCardToXML } from './requestConverters';

export const fundCardConfigurations = {
  GetFundCard: makeBackendAPIConfiguration({
    id: 'GetFundCard',
    endpoint: '/msa/service/commands/GetFundCard',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'GetFundCard',
          },
          FundCard: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertFundCard(response.Response.FundCard),
  }),
  SaveFundCard: makeBackendAPIConfiguration({
    id: 'SaveFundCard',
    endpoint: '/msa/service/commands/SaveFundCard',
    convertInputToXMLElement: (inputData: FundCard) => ({
      Request: {
        _attr: {
          commandName: 'SaveFundCard',
        },
        FundCard: {
          ...convertFundCardToXML(inputData),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  DeleteFundCard: makeBackendAPIConfiguration({
    id: 'DeleteFundCard',
    endpoint: '/msa/service/commands/DeleteFundCard',
    convertInputToXMLElement: (inputData: { id: string }) => {
      const { id } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'DeleteFundCard',
          },
          FundCard: { _attr: { id } },
        },
      };
    },
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangeProjectCodeFundCard: makeBackendAPIConfiguration({
    id: 'ChangeProjectCodeFundCard',
    endpoint: '/msa/service/commands/ChangeProjectCodeFundCard',
    convertInputToXMLElement: (inputData: { fundCardId: string; projectsId: string[] }) => {
      const { fundCardId, projectsId } = inputData;

      return {
        Request: {
          _attr: {
            commandName: 'ChangeProjectCodeFundCard',
          },
          FundCard: { _attr: { id: fundCardId } },
          ProjectCode: projectsId.map(id => ({ _attr: { id } })),
        },
      };
    },
    convertResponseXMLElementToOutput: () => {},
  }),
};

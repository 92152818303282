import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Member } from 'features/SelectPerson';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { CommunityType } from 'utils/Enums';
import { Template } from 'types/models/Form';

type Props = {
  tableState: Table.State;
  communitiesFilter: string;
};

export const useController = ({ tableState, communitiesFilter }: Props) => {
  const token = getAuthToken();
  const { currentPerson, userPermission, settings } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const organizationName = settings?.organization?.shortName;

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow = selectedRowsLength ? tableState.selectedRows[0] : null;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDownloadFileDisabled = !(rowsAvailability.SINGLE_SELECTED && tableState.selectedRows[0]?.FileId);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isErrorModal, setIsErrorModal] = useState(false);
  const [errorStatus, setErrorStatus] = useState<{ type: string; text: string }>({ type: '', text: '' });
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const member = useMemo(
    (): Member => ({
      person: currentPerson || null,
      job: currentPerson && currentPerson.scientistJobs.length ? currentPerson.scientistJobs[0] : null,
      education: null,
      degree: currentPerson && currentPerson.degrees.length ? currentPerson.degrees[0] : null,
      citizenship: null,
      rank: null,
      academicRank: currentPerson && currentPerson.academicRanks !== null ? currentPerson.academicRanks[0] : null,
      historyLabel: '',
    }),
    [currentPerson],
  );

  const isEditPermitted = useMemo(() => {
    return (
      isHasPermission(userPermission, Permits[`RECOGNITION_${selectedRow?.['id:status']}_EDIT` as keyof Permits]) ||
      communitiesFilter === 'mine'
    );
  }, [selectedRow, userPermission, communitiesFilter]);

  const isDeletePermitted = useMemo(() => {
    return (
      isHasPermission(userPermission, Permits[`RECOGNITION_${selectedRow?.['id:status']}_DELETE` as keyof Permits]) ||
      communitiesFilter === 'mine'
    );
  }, [selectedRow, userPermission, communitiesFilter]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'CommunityMember',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleCloseErrorModal = useCallback(() => {
    setIsErrorModal(false);
    setErrorStatus({
      type: '',
      text: '',
    });
  }, []);

  const handleDeleteButtonClick = useCallback(async () => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleDownloadFile = useCallback(() => {
    downloadFile(tableState.selectedRows[0]?.FileId, token);
  }, [tableState.selectedRows, token]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        isHidden: communitiesFilter === 'all',
        permission: { name: Permits.RECOGNITION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: [Permits.RECOGNITION_ADDED_EDIT, Permits.RECOGNITION_APPROVED_EDIT] },
        isHidden: communitiesFilter === 'all',
        isDisabled: isEditButtonDisabled || !isEditPermitted,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: [Permits.RECOGNITION_ADDED_DELETE, Permits.RECOGNITION_APPROVED_DELETE] },
        isHidden: communitiesFilter === 'all',
        isDisabled: isDeleteButtonDisabled || !isDeletePermitted,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать прикрепленный файл',
        onClick: handleDownloadFile,
        isHidden: communitiesFilter === 'all',
        isDisabled: isDownloadFileDisabled,
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      communitiesFilter,
      isEditButtonDisabled,
      isEditPermitted,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      isDeletePermitted,
      handleDeleteButtonClick,
      isDownloadFileDisabled,
      handleDownloadFile,
    ],
  );

  const templateName: Template = 'CommunityMemberForm';

  const communityTypes = [CommunityType.DISSERTATION_COUNCIL];
  return {
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteConfirmPopupOpen,
    isDeleteButtonDisabled,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    member,
    isErrorModal,
    errorStatus,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    handleCloseErrorModal,
    templateName,
    communityTypes,
    organizationName,
  };
};

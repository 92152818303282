import React, { useCallback } from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextInput } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function PaymentTermPresetForm({ viewMode, onClose }: Props) {
  const { formFields, onSubmit } = useController();

  const handleFormSubmit = useCallback(() => {
    if (onSubmit()) onClose();
  }, [onClose, onSubmit]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.type.title} isRequired>
            <Select
              mode={SelectMode.ENUM}
              value={formFields.type.value}
              settings={{ name: 'PaymentRequestType' }}
              onChange={formFields.type.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.regulationParagraph.title} isRequired>
            <TextInput
              value={formFields.regulationParagraph.value}
              onChange={formFields.regulationParagraph.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.regulationName.title} isRequired>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.regulationName.value}
              onChange={formFields.regulationName.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(PaymentTermPresetForm);

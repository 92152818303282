import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { PatentResearch } from 'types/models';
import { Settings } from 'types/models/common';
import { convertPatentResearch } from './converters';
import { convertPatentResearchToXML } from './requestConverters';

const configurations = {
  GetPatentResearch: makeBackendAPIConfiguration({
    id: 'GetPatentResearch',
    endpoint: '/msa/service/commands/GetPatentResearch',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetPatentResearch' },
        PatentResearch: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertPatentResearch(response.Response.PatentResearch, settings),
  }),
  SavePatentResearch: makeBackendAPIConfiguration({
    id: 'SavePatentResearch',
    endpoint: '/msa/service/commands/SavePatentResearch',
    convertInputToXMLElement: (inputData: PatentResearch) => ({
      Request: {
        _attr: {
          commandName: 'SavePatentResearch',
        },
        PatentResearch: { ...convertPatentResearchToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const patentResearchsConfigurations = { ...configurations };

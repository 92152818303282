import React from 'react';

import { FormComponent, Modal } from 'components';

import { DataGrid } from 'features/Table';
import { GetImpactFactorList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  id: string;
  isFiveYear: boolean;
};

const UpperFormPublications = ({ isOpen, onClose, id, isFiveYear }: Props) => (
  <Modal
    isOpen={isOpen}
    onClose={onClose}
    title={`Публикации для расчета совокупного ${isFiveYear ? 'пятилетнего ' : ''}импакт-фактора`}
    size={['extralarge', 'large-height']}
  >
    <FormComponent.Wrapper>
      <FormComponent.Description mode="warning">
        Показатель рассчитывается на основе данных в системе по публикациям автора, индексируемым в зарубежных базах данных
        цитирования Scopus и/или WoS в изданиях. Имеющих текущее значение импакт-факторов.
      </FormComponent.Description>
      <DataGrid specification={GetImpactFactorList({ scientistId: id, workMode: 'viewMode', isFiveYear })} />
    </FormComponent.Wrapper>
  </Modal>
);

export default React.memo(UpperFormPublications);

import React from 'react';
import * as FormTabs from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs, Toolbar } from 'components';
import { tabsStreams } from 'components/Tabs/streams';
import { isHasPermission } from 'features/AppData';
import { Documents } from 'features/Documents';
import { Permits } from 'utils/Permissions';

import { Description as EquipDescription, Location as EquipLocation } from '../EquipForm/Tabs';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function EquipVerificationForm({ viewMode, onClose }: Props) {
  const {
    equip,
    setEquip,
    buttons,
    statusOptions,
    userPermission,
    isVerificationWarningOpen,
    setIsVerificationWarningOpen,
    isItemsWarningOpen,
    setIsItemsWarningOpen,
    onSubmit,
  } = useController({
    viewMode,
    onClose,
  });

  return (
    <>
      <FormComponent.Template>
        <Toolbar buttons={buttons} mode="form" />

        <FormComponent.Template>
          <Tabs id="equipVerification">
            <Tab title="Об оборудовании" isVisible={isHasPermission(userPermission, Permits.EQUIP_DESCRIPTION_VIEW)}>
              <EquipDescription
                equip={equip}
                setEquip={setEquip}
                statusOptions={statusOptions}
                isDisabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_DESCRIPTION_EDIT))}
              />
            </Tab>
            <Tab title="Метрологические сопровождение">
              <FormTabs.Verification equip={equip} setEquip={setEquip} isDisabled={!!viewMode} />
            </Tab>
            <Tab title="Место установки" isVisible={isHasPermission(userPermission, Permits.EQUIP_LOCATION_VIEW)}>
              <EquipLocation
                equip={equip}
                setEquip={setEquip}
                isDisabled={!!viewMode || (!!equip.id && !isHasPermission(userPermission, Permits.EQUIP_LOCATION_EDIT))}
              />
            </Tab>
            <Tab title="Документы" isVisible={isHasPermission(userPermission, Permits.EQUIP_DOCUMENTS_VIEW)}>
              <Documents
                documents={equip.verificationDocuments}
                setDocuments={e => setEquip(prev => ({ ...prev, verificationDocuments: e }))}
                disabled={!!viewMode}
                isCanIsSharedEdit
                sharedLabel="Отображать на форме оборудования"
                defaultSharedValue
                isShowSource
                sourceLabelForLocalDocs="Текущая метрология"
              />
            </Tab>
            <Tab title="Журнал сообщений">
              <em>Не реализовано</em>
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>

      <Modal
        title="Предупреждение"
        mode="warning"
        isOpen={isVerificationWarningOpen}
        onClose={() => setIsVerificationWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Внести данные',
            onClick: () => {
              tabsStreams.setCurrentTab.push({ nextSelectedTab: 1, tabsId: 'equipVerification' });
              setIsVerificationWarningOpen(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Сохранить',
            onClick: () => onSubmit(),
          },
        ]}
        size="small"
      >
        <FormComponent.Text>
          Запись не будет отображаться в списке оборудования для метрологического сопровождения так как не заполнены данные на
          вкладке <strong>Метрологическое сопровождение</strong>. Данные заполненные на других вкладках будут сохранены в пункте
          меню <strong>Оборудование</strong>.
        </FormComponent.Text>
      </Modal>

      <Modal
        title="Предупреждение"
        mode="warning"
        isOpen={isItemsWarningOpen}
        onClose={() => setIsItemsWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Внести данные',
            onClick: () => {
              tabsStreams.setCurrentTab.push({ nextSelectedTab: 1, tabsId: 'equipVerification' });
              setIsItemsWarningOpen(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Сохранить',
            onClick: () => onSubmit(),
          },
        ]}
        size="small"
      >
        <FormComponent.Text>
          Для включения оборудования в план-график поверок необходимо занести данные в таблицу <strong>История поверок</strong> на
          вкладке <strong>Метрологическое сопровождение</strong>.
        </FormComponent.Text>
      </Modal>
    </>
  );
}

export const Component = React.memo(EquipVerificationForm);

import React from 'react';

import { showErrors, isInPeriod } from 'utils/Validators';
import { PeriodableReferenceItem } from 'types/models/Partner';
import { ListEdit } from 'components';
import { getMockPeriodableReferenceItem } from 'features/EditPartner/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  partnerships: PeriodableReferenceItem[];
  setPartnerships(partnership: PeriodableReferenceItem[]): void;
  disabled: boolean;
};

export function PartnershipTypes(props: Props) {
  const { partnerships, setPartnerships, disabled } = props;

  return (
    <ListEdit
      rows={partnerships}
      onChange={setPartnerships}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Тип партнёрства', formatValue: x => x.ref?.label ?? '' },
        { label: 'Начало срока действия', formatValue: x => x.startDate ?? '' },
        { label: 'Окончание срока действия', formatValue: x => x.endDate ?? '' },
        { label: 'Примечание', formatValue: x => x.note ?? '' },
        { label: 'Актуальный', formatValue: x => (isInPeriod(x.startDate, x.endDate) ? 'Да' : '') },
      ]}
      withMessages
      isDeleteConfirmEnabled
      isDisabled={disabled}
      defaultRowsCount={6}
      maxHeight="350px"
      specification={{
        mode: 'customComponent',
        renderComponent: (partnership, setPartnership) => (
          <Fields partnership={partnership || getMockPeriodableReferenceItem()} setPartnership={setPartnership} />
        ),
        validation: {
          checkIsValid: okved => validate(okved).every(x => x.isValid),
          onInvalidate: val => showErrors(validate, val),
        },
      }}
    />
  );
}

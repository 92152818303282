import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F, ResponseData } from 'types/models';
import { Modal } from 'components';

type DetailProps = {
  mode: string | null;
  onSave(data: ResponseData): void;
};

const ParticipationFormComponent: React.FC<F.TemplateProps & DetailProps> = ({
  isOpen,
  onClose,
  id,
  mode,
  onSave,
}: F.TemplateProps & DetailProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: 'ParticipationFormExposition',
    id,
    viewMode: mode === 'view',
    editMode: mode === 'edit',
    onSave,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" isViewMode={mode === 'view'}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ParticipationFormComponent);

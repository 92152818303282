import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

type Props = {
  securityDocument?: string | null;
  isHideFirstLevel?: boolean;
  isWithTemplatesPanel?: boolean;
  isFiltersHidden?: boolean;
};

type Arguments = {
  securityDocument?: string | null;
};

export function GetContractPaymentLogList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetContractPaymentLogList',
    requestData: args,
    isFiltersHidden: args?.isFiltersHidden,
    header: {
      ...(!args?.isHideFirstLevel
        ? {
            firstLevel: {
              title: 'Журнал поступления лицензионных платежей',
              Panel: Filters,
            },
          }
        : {}),
      thirdLevel: {
        withTemplatesPanel: args?.isWithTemplatesPanel !== undefined ? args?.isWithTemplatesPanel : false,
        LeftPanel: Toolbar,
      },
    },
  };
}

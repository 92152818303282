import { Table } from 'types/models';
import { ToolbarWrapper as Toolbar } from './LeftPanelForThirdLevel';

type Arguments = {
  projectId: string | null;
};

export function GetProjectPublicationList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectPublicationList',
    isFiltersHidden: true,
    requestData: args,
    header: {
      firstLevel: {
        title: 'Список публикаций по теме проекта',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: Toolbar({ projectId: args.projectId || undefined }),
      },
    },
  };
}

import { useCallback } from 'react';

import { Event } from 'types/models';

type Props = {
  handleAttractedDepartmentsChange: (value: Event.Department[]) => void;
};

export function useController(props: Props) {
  const { handleAttractedDepartmentsChange } = props;

  const handleDepartmentsChange = useCallback(
    (rows: Event.Department[]) => {
      handleAttractedDepartmentsChange(rows);
    },
    [handleAttractedDepartmentsChange],
  );

  return {
    handleDepartmentsChange,
  };
}

import { ProgramRequest } from 'types/models/ProgramRequest';
import { LotPrice } from 'types/models/Tender';
import { formatNumber } from 'utils/Helpers';

export const formatLotPrices = (lotPrices: LotPrice[]) => {
  const formatter = (lotPrice: LotPrice) => {
    return `${lotPrice.year}г. - ${formatNumber(lotPrice.amount || 0)} руб.`;
  };
  return lotPrices.map(formatter).join(', ');
};

export function getMockProgramRequest(): ProgramRequest {
  return {
    annotation: '',
    code: '',
    contractProvideAmount: '',
    contractProvideKind: null,
    contractProvideNote: null,
    criticalTechnologies: [],
    departments: [],
    documents: [],
    knowledgeBranches: [],
    endDate: '',
    estimatePositions: [],
    finances: [],
    grntis: [],
    id: null,
    keyWords: '',
    lksetss: [],
    note: '',
    periodNote: '',
    ntrStrategies: [],
    okveds: [],
    partners: [],
    performerCount: '',
    performers: [],
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    requestDate: '',
    requestProvideAmount: '',
    requestProvideKind: null,
    requestProvideNote: null,
    scienceBrunches: [],
    scienceDomainInterrests: [],
    startDate: '',
    status: null,
    technologyPlatforms: [],
    tender: null,
    lot: null,
    theme: '',
    udks: [],
    warrantyProvideAmount: '',
    warrantyProvideKind: null,
    warrantyProvideNote: null,
    numberPrefix: null,
  };
}

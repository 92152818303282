import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

type Props = {
  isOpen: boolean;
  text: string;
  onClose: () => void;
  onConfirm: () => void;
};

const CantSaveInPreviousStatusPopup = memo(({ isOpen, text, onClose, onConfirm }: Props) => (
  <Modal
    mode="info"
    title="Предупреждение"
    isOpen={isOpen}
    onClose={onClose}
    actions={[
      {
        mode: ButtonMode.PRIMARY,
        text: 'Сохранить в статусе "Черновик"',
        onClick: onConfirm,
      },
      {
        mode: ButtonMode.SECONDARY,
        text: 'Продолжить заполнение',
        onClick: onClose,
      },
    ]}
    size="medium"
  >
    <>{text}</>
  </Modal>
));

export { CantSaveInPreviousStatusPopup };

import React from 'react';

import { FormComponent } from 'components';

import { Form as F } from 'types/models';
import { Fields } from './Fields';
import { Childs } from './Childs';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useFormContext } from 'features/Form/hooks';
// eslint-disable-next-line max-len
import { Props as ExtLookProps } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/LeftPanelForThirdLevel/modalTemplate';
import { useController } from './controller';
import { ScientificIndex } from './ScientificIndex';

type Props = {
  onClose: () => void;
};

function CitationSystemIndexForm({ onClose }: Props) {
  const {
    look: { customState, mode, relatedTableState, setTitle },
  } = useFormContext<F.Look & ExtLookProps>();

  const { citationSystems, publicationType } = customState;

  const {
    enumMap,
    saveForm,
    citationSystemIndex,
    scientificIndexes,
    handleChangeCitationSystem,
    handleChangeScientificIndex,
    handleChangeField,
    handleChangeChilds,
  } = useController({
    onClose,
    customState,
    mode,
    relatedTableState,
    setTitle,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveForm} />

      <FormComponent.Wrapper>
        <ScientificIndex
          mode={mode}
          citationSystemIndex={citationSystemIndex}
          citationSystems={citationSystems}
          scientificIndexes={scientificIndexes}
          publicationType={publicationType}
          handleChangeCitationSystem={handleChangeCitationSystem}
          handleChangeScientificIndex={handleChangeScientificIndex}
        />

        <Fields
          enumMap={enumMap}
          citationSystem={citationSystemIndex.citationSystem}
          citationSystems={citationSystems}
          fields={citationSystemIndex.values}
          onChangeField={handleChangeField}
        />

        <Childs
          enumMap={enumMap}
          childFields={citationSystemIndex.childs}
          setRows={handleChangeChilds}
          citationSystems={citationSystems}
          citationSystem={citationSystemIndex.citationSystem}
          selectedScientificIndex={citationSystemIndex.scientificIndex}
        />
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CitationSystemIndexForm);

import React, { useMemo, useCallback } from 'react';

import { ListEdit } from 'components';

import { NirRequest, ForeignSecurityDocument } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';

import * as BackendAPI from 'services/BackendAPI';

import { GetNirRequestSecurityDocumentList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { Entry } from 'types/models/Table';

enum SecurityDocumentType {
  SECURITY_DOCUMENT = 'SECURITY_DOCUMENT',
  FOREIGN_SECURITY_DOCUMENT = 'FOREIGN_SECURITY_DOCUMENT',
}

type UnitedSecurityDocument = Pick<
  SecurityDocument & ForeignSecurityDocument,
  'id' | 'type' | 'documentNumber' | 'documentStartDate' | 'intellectualPropertyName' | 'authors' | 'applicants'
> & {
  securityDocumentType: SecurityDocumentType;
};

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  setNirRequest: React.Dispatch<React.SetStateAction<NirRequest.NirRequest>>;
  disabled: boolean;
};

export function SecurityDocuments(props: Props) {
  const { nirRequest, setNirRequest, disabled } = props;

  const { settings } = useAppDataContext();

  const { methods: getNirRequestSecurityDocument } = BackendAPI.useBackendAPI('GetNirRequestSecurityDocument', {
    onSuccessfullCall: ({ data }) =>
      setNirRequest(prevState => ({
        ...prevState,
        collectiveSecurityDocuments: data,
      })),
  });
  const { methods: getNirRequestForeignSecurityDocument } = BackendAPI.useBackendAPI('GetNirRequestForeignSecurityDocument', {
    onSuccessfullCall: ({ data }) =>
      setNirRequest(prevState => ({
        ...prevState,
        collectiveForeignSecurityDocuments: data,
      })),
  });

  const unitedSecurityDocument = useMemo<UnitedSecurityDocument[]>(
    () => [
      ...(nirRequest?.collectiveSecurityDocuments || []).map(x => ({
        ...x,
        securityDocumentType: SecurityDocumentType.SECURITY_DOCUMENT,
        authors: '',
        applicants: '',
      })),
      ...(nirRequest?.collectiveForeignSecurityDocuments || []).map(x => ({
        ...x,
        securityDocumentType: SecurityDocumentType.FOREIGN_SECURITY_DOCUMENT,
      })),
    ],
    [nirRequest?.collectiveForeignSecurityDocuments, nirRequest?.collectiveSecurityDocuments],
  );

  const changeUnitedProjects = useCallback(
    (e: UnitedSecurityDocument[]) => {
      const newSecurityDocuments = e
        .filter(x => x.securityDocumentType === SecurityDocumentType.SECURITY_DOCUMENT)
        .filter((value, index, self) => index === self.findIndex(t => t.id === value.id));
      const newForeignSecurityDocuments = e
        .filter(x => x.securityDocumentType === SecurityDocumentType.FOREIGN_SECURITY_DOCUMENT)
        .filter((value, index, self) => index === self.findIndex(t => t.id === value.id));
      if (newSecurityDocuments.length) getNirRequestSecurityDocument.callAPI({ ids: newSecurityDocuments.map(x => x.id!) });
      else setNirRequest(prevState => ({ ...prevState, collectiveSecurityDocuments: [] }));
      if (newForeignSecurityDocuments.length)
        getNirRequestForeignSecurityDocument.callAPI({ ids: newForeignSecurityDocuments.map(x => x.id!) });
      else setNirRequest(prevState => ({ ...prevState, collectiveForeignSecurityDocuments: [] }));
    },
    [getNirRequestForeignSecurityDocument, getNirRequestSecurityDocument, setNirRequest],
  );

  const rowConverter = useCallback((e: Entry) => {
    return {
      id: e.id,
      securityDocumentType: e.Entity as SecurityDocumentType,
      type: null,
      intellectualPropertyName: '',
      documentNumber: '',
      documentStartDate: '',
      authors: '',
      applicants: '',
    };
  }, []);

  return (
    <ListEdit<{}, UnitedSecurityDocument>
      header={{ title: 'Результаты интеллектуальной деятельности, полученные научным коллективом' }}
      rows={unitedSecurityDocument}
      onChange={changeUnitedProjects}
      toolbar={['add', 'delete']}
      columns={[
        {
          label: 'Документ',
          formatValue: x => [(x.type as any)?.label, x.documentNumber, x.documentStartDate].filter(Boolean).join(', '),
          styles: { width: '20%' },
        },
        { label: 'Название документа', formatValue: x => x.intellectualPropertyName, styles: { width: '40%' } },
        { label: 'Авторы', formatValue: x => x.authors, styles: { width: '20%' } },
        {
          label: 'Правообладатель',
          formatValue: x => x.applicants || settings?.organization?.shortName || '',
          styles: { width: '20%' },
        },
      ]}
      maxHeight="none"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'relationTableModal',
        modalTableRowConverter: rowConverter,
        relationTableModalTitle: 'Охранные документы',
        modalTableSpecification: GetNirRequestSecurityDocumentList({
          scientistIDs: (nirRequest?.performers ?? []).map(x => x.person?.id ?? '').filter(Boolean),
        }),
      }}
    />
  );
}

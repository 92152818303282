import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { useAppDataContext } from 'features/AppData/context';

export const Filters = React.memo(() => {
  const { settings } = useAppDataContext();
  const organizationName = settings?.organization?.shortName;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'communitiesFilter',
        list: [
          { label: 'Мои сообщества', value: 'mine', hint: 'Список ваших научных сообществ' },
          {
            label: 'Все сообщества',
            value: 'all',
            hint: `Список членов научных сообществ ${organizationName} и сообществ других организаций`,
          },
        ],
        value: 'mine',
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Показывать сообщества за год',
        checked: false,
      },
    ],
    'personal',
  );
});

import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const [isDocumentListModalOpen, setIsDocumentListModalOpen] = useState<boolean>(false);
  const [isHistoryModalOpen, setIsHistoryModalOpen] = useState<boolean>(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const [row] = useMemo(() => tableState.selectedRows, [tableState]);

  const documentsModalTitle = useMemo(() => {
    return `Поступления на шифр ${row?.ProjectCode} за ${row?.EstimateYear} год`;
  }, [row]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icon: { type: 'list', mode: 'question' },
        title: 'Список документов бухгалтерского учёта о поступлении средств на шифр проекта',
        isDisabled: !row,
        onClick: () => setIsDocumentListModalOpen(true),
      },
      {
        icon: { type: 'messages' },
        title: 'История изменений планового значения процента накладных расходов для проекта',
        isDisabled: !row,
        onClick: () => setIsHistoryModalOpen(true),
      },
    ],
    [row],
  );

  return {
    buttons,
    isDocumentListModalOpen,
    setIsDocumentListModalOpen,
    isHistoryModalOpen,
    setIsHistoryModalOpen,
    documentsModalTitle,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
};

export default useController;

import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetExpenseDocumentItemList, GetExpenseDocumentList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ExpenseDocuments() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['finance', 'expense-docs'],
    init: ['items'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Расходы по шифрам" id="items">
          <DataGrid specification={GetExpenseDocumentItemList} />
        </Tab>
        <Tab title="Платежные поручения" id="list">
          <DataGrid specification={GetExpenseDocumentList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

import { useCallback, useState } from 'react';
import { ProjectAssignmentStatus } from 'types/models/Project';
import { useLocalTableStreams } from 'features/Table/hooks';

export type State = {
  isOpenMessageModal: boolean;
  setIsOpenMessageModal(isOpen: boolean): void;
  isOpenConfirmModal: boolean;
  setIsOpenConfirmModal(isOpen: boolean): void;
  newStatus: ProjectAssignmentStatus | 'MESSAGE';
  setNewStatus(newStatus: ProjectAssignmentStatus | 'MESSAGE'): void;
  isAddFormOpen: boolean;
  setIsAddFormOpen(isOpen: boolean): void;
  isEditFormOpen: boolean;
  setIsEditFormOpen(isOpen: boolean): void;
  isDeleteWarningOpen: boolean;
  setIsDeleteWarningOpen(isOpen: boolean): void;
  isViewFormOpen: boolean;
  setIsViewFormOpen(isOpen: boolean): void;
  isHelpFormOpen: boolean;
  setIsHelpFormOpen(isOpen: boolean): void;
  isMessageHistoryModalOpen: boolean;
  setIsMessageHistoryModalOpen(isOpen: boolean): void;
  handleMessageHistoryModalClose(): void;
  handleMessageModalClose(): void;
  disabled?: boolean;
};

type Arguments = {
  disabled?: boolean;
};

export function makeUseCustomController({ disabled }: Arguments) {
  return function useCustomController(): State {
    const [isOpenMessageModal, setIsOpenMessageModal] = useState(false);
    const [isOpenConfirmModal, setIsOpenConfirmModal] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState<boolean>(false);
    const [isDeleteWarningOpen, setIsDeleteWarningOpen] = useState<boolean>(false);
    const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
    const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
    const [isMessageHistoryModalOpen, setIsMessageHistoryModalOpen] = useState(false);
    const [newStatus, setNewStatus] = useState<ProjectAssignmentStatus | 'MESSAGE'>('');

    const tableStreams = useLocalTableStreams();

    const handleMessageHistoryModalClose = useCallback(() => {
      tableStreams.reloadTable.push({});
      setIsMessageHistoryModalOpen(false);
    }, [tableStreams]);

    const handleMessageModalClose = useCallback(() => {
      tableStreams.reloadTable.push({});
      setIsOpenMessageModal(false);
    }, [tableStreams]);

    return {
      isOpenMessageModal,
      isOpenConfirmModal,
      setIsOpenConfirmModal,
      setIsOpenMessageModal,
      newStatus,
      setNewStatus,
      isAddFormOpen,
      setIsAddFormOpen,
      isEditFormOpen,
      setIsEditFormOpen,
      isDeleteWarningOpen,
      setIsDeleteWarningOpen,
      isViewFormOpen,
      setIsViewFormOpen,
      isHelpFormOpen,
      setIsHelpFormOpen,
      isMessageHistoryModalOpen,
      setIsMessageHistoryModalOpen,
      handleMessageHistoryModalClose,
      handleMessageModalClose,
      disabled,
    };
  };
}

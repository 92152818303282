import React, { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, ListEditTable, Modal, Toolbar } from 'components';

import { Estimate, Project, Report, Table } from 'types/models';
import { ProjectCode } from 'types/models/Project';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { BuildReportPopup, Reports, useReportsHook } from 'features/BuildReportPopup';
// eslint-disable-next-line max-len
import { Component as ContestRequestViewTemplate } from 'features/Table/specifications/GetContestRequestList/LeftPanelForThirdLevel/modalTemplates/ContestRequestViewTemplate';
import { State } from '../makeUseCustomController';
import { EstimateGridHelp } from './help';

type WrapperProps = {
  projectCode?: ProjectCode | null;
  isDisabled?: boolean;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

const LeftPanelForThirdLevel = ({ projectCode, isDisabled }: WrapperProps) =>
  React.memo(({ tableState, customState: { fundCard } }: Props) => {
    const tableStreams = useLocalTableStreams();

    const selectedRow: Table.Entry | null = tableState.selectedRows[0];

    const [isViewFormOpen, setIsViewFormOpen] = useState(false);
    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');
    const [nextPublicationId, setNextPublicationId] = useState('');
    const [isCreateByProgramRequestModalOpen, setIsCreateByProgramRequestModalOpen] = useState<boolean>(false);
    const [isCreateByNirRequestModalOpen, setIsCreateByNirRequestModalOpen] = useState<boolean>(false);
    const [isCreateByContestRequestModalOpen, setIsCreateByContestRequestModalOpen] = useState<boolean>(false);
    const [contestRequestIndex, setContestRequestIndex] = useState<number | null>(null);
    const [isContestRequestFormOpen, setIsContestRequestFormOpen] = useState<boolean>(false);
    const [amountWarningMessage, setAmountWarningMessage] = useState<string>('');
    const [estimate, setEstimate] = useState<Estimate | null>(null);
    const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

    const { methods: createEstimateByProgramRequestAPI } = BackendAPI.useBackendAPI('CreateEstimateByRequest');
    const { methods: createEstimateByNirRequestAPI } = BackendAPI.useBackendAPI('CreateEstimateByNirRequest');
    const { methods: createEstimateByContestRequestAPI } = BackendAPI.useBackendAPI('CreateEstimateByContestRequest');
    const { methods: getProgramRequestAPI } = BackendAPI.useBackendAPI('GetRequest');
    const { methods: getNirRequestAPI } = BackendAPI.useBackendAPI('GetNirRequest');
    const { methods: getContestRequestAPI } = BackendAPI.useBackendAPI('GetContestRequest');

    const programRequest = useMemo(() => projectCode?.project?.programRequest || null, []);
    const nirRequest = useMemo(() => projectCode?.project?.nirRequest || null, []);
    const contestRequests = useMemo(() => projectCode?.project?.contestRequests || [], []);
    const selectedContestRequest = useMemo(() => {
      if (!!contestRequestIndex || contestRequestIndex === 0) return contestRequests[contestRequestIndex];
      return null;
    }, [contestRequests, contestRequestIndex]);

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      tableStreams.deleteRow.push({
        deleteRowId: selectedRow?.id,
        command: 'DeleteEntity',
        deletedItemPropName: 'Estimate',
      });
      setIsDeleteConfirmPopupOpen(false);
    }, [selectedRow?.id, tableStreams.deleteRow]);

    const reports = useMemo<Report[]>(
      () => [...(!projectCode ? [Reports.NirRequestTotalEstimate] : []), Reports.ProjectEstimateByCodeForViu],
      [],
    );

    const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

    const handleDeleteButtonClick = useCallback(() => {
      setIsDeleteConfirmPopupOpen(true);
    }, []);

    const handleViewButtonClick = useCallback(() => {
      setIsViewFormOpen(true);
    }, []);

    const handleTemplateCloseViewForm = useCallback(() => {
      setIsViewFormOpen(false);
    }, []);

    const handleAddButtonClick = useCallback(() => {
      setNextPublicationName('');
      setNextPublicationId('');
      setIsAddFormOpen(true);
    }, []);

    const handleSaveAndEdit = useCallback(() => {
      setIsAddFormOpen(false);
      setIsEditFormOpen(true);
    }, []);

    const handleTemplateCloseAddForm = useCallback(() => {
      setIsAddFormOpen(false);
    }, []);

    const handleEditButtonClick = useCallback(() => {
      setIsEditFormOpen(true);
    }, []);

    const handleTemplateCloseEditForm = useCallback(() => {
      setIsEditFormOpen(false);
      setNextPublicationId('');
      setEstimate(null);
      setAmountWarningMessage('');
    }, []);

    const createEstimateByContestRequest = useCallback(() => {
      if (!selectedContestRequest || !projectCode) return;

      createEstimateByContestRequestAPI.callAPI(
        {
          contestRequestId: selectedContestRequest.id || contestRequests[0].id || '',
          projectCodeId: projectCode.id || '',
        },
        {
          onSuccessfullCall: ({ data: estimateData }) => {
            setEstimate(estimateData);
            showNotification({ message: 'Смета создана', theme: 'success' });
            setContestRequestIndex(null);
            setIsCreateByContestRequestModalOpen(false);
            tableStreams.reloadTable.push({});
            setIsEditFormOpen(true);
            getContestRequestAPI.callAPI(
              { requestId: selectedContestRequest?.id || undefined },
              {
                onSuccessfullCall: ({ data: contestRequestData }) => {
                  const estimateTaxPositions = (estimateData?.positions || []).filter(x => !!x.kindConsumption?.taxOwner);
                  const messages: string[] = [];
                  estimateTaxPositions.forEach(estimateTaxPosition => {
                    const contestRequestTaxPositions = contestRequestData.requestStageEstimatePositions.filter(
                      contestRequestEstimatePosition =>
                        estimateTaxPosition.kindConsumption?.id === contestRequestEstimatePosition.kindConsumption.id,
                    );
                    const totalByContestRequest = contestRequestTaxPositions.reduce(
                      (sum, x) => sum + parseFloat(x.amount) || 0,
                      0,
                    );
                    if (totalByContestRequest !== estimateTaxPosition.amount)
                      messages.push(
                        // eslint-disable-next-line max-len
                        `Для вида расхода <strong>"${estimateTaxPosition.kindConsumption?.name}"</strong> в заявке общая сумма <strong>${totalByContestRequest} руб.</strong>, сумма позиции созданной сметы шифра для данного вида расхода составляет <strong>${estimateTaxPosition.amount} руб.</strong>`,
                      );
                  });
                  if (!!messages.length) {
                    setAmountWarningMessage(messages.join('<br />'));
                  }
                },
              },
            );
          },
        },
      );
    }, [
      contestRequests,
      createEstimateByContestRequestAPI,
      getContestRequestAPI,
      selectedContestRequest,
      tableStreams.reloadTable,
    ]);

    const createEstimateByProgramRequest = useCallback(() => {
      if (!programRequest || !projectCode) return;

      createEstimateByProgramRequestAPI.callAPI(
        {
          requestId: programRequest.value || '',
          projectCodeId: projectCode.id || '',
        },
        {
          onSuccessfullCall: ({ data: estimateData }) => {
            setEstimate(estimateData);
            showNotification({ message: 'Смета создана', theme: 'success' });
            setIsCreateByProgramRequestModalOpen(false);
            tableStreams.reloadTable.push({});
            setIsEditFormOpen(true);
            getProgramRequestAPI.callAPI(
              { id: programRequest?.value || undefined },
              {
                onSuccessfullCall: ({ data: programRequestData }) => {
                  const estimateTaxPositions = (estimateData?.positions || []).filter(x => !!x.kindConsumption?.taxOwner);
                  const messages: string[] = [];
                  estimateTaxPositions.forEach(estimateTaxPosition => {
                    const programRequestTaxPositions = programRequestData.estimatePositions.filter(
                      programRequestEstimatePosition =>
                        estimateTaxPosition.kindConsumption?.id === programRequestEstimatePosition.kindConsumption.id,
                    );
                    const totalByProgramRequest = programRequestTaxPositions.reduce(
                      (sum, x) => sum + parseFloat(x.amount) || 0,
                      0,
                    );
                    if (totalByProgramRequest !== estimateTaxPosition.amount)
                      messages.push(
                        // eslint-disable-next-line max-len
                        `Для вида расхода <strong>"${estimateTaxPosition.kindConsumption?.name}"</strong> в заявке общая сумма <strong>${totalByProgramRequest} руб.</strong>, сумма позиции созданной сметы шифра для данного вида расхода составляет <strong>${estimateTaxPosition.amount} руб.</strong>`,
                      );
                  });
                  if (!!messages.length) {
                    setAmountWarningMessage(messages.join('<br />'));
                  }
                },
              },
            );
          },
        },
      );
    }, [createEstimateByProgramRequestAPI, getProgramRequestAPI, programRequest, tableStreams.reloadTable]);

    const createEstimateByNirRequest = useCallback(() => {
      if (!nirRequest || !projectCode) return;

      createEstimateByNirRequestAPI.callAPI(
        {
          nirRequestId: nirRequest.value || '',
          projectCodeId: projectCode.id || '',
        },
        {
          onSuccessfullCall: ({ data: estimateData }) => {
            setEstimate(estimateData);
            showNotification({ message: 'Смета создана', theme: 'success' });
            setIsCreateByNirRequestModalOpen(false);
            tableStreams.reloadTable.push({});
            setIsEditFormOpen(true);
            getNirRequestAPI.callAPI(
              { id: nirRequest?.value || null },
              {
                onSuccessfullCall: ({ data: nirRequestData }) => {
                  const estimateTaxPositions = (estimateData?.positions || []).filter(x => !!x.kindConsumption?.taxOwner);
                  const messages: string[] = [];
                  estimateTaxPositions.forEach(estimateTaxPosition => {
                    const nirRequestTaxPositions = nirRequestData.estimatePositions.filter(
                      nirRequestEstimatePosition =>
                        estimateTaxPosition.kindConsumption?.id === nirRequestEstimatePosition.kindConsumption.id,
                    );
                    const totalByNirRequest = nirRequestTaxPositions.reduce((sum, x) => sum + parseFloat(x.amount) || 0, 0);
                    if (totalByNirRequest !== estimateTaxPosition.amount)
                      messages.push(
                        // eslint-disable-next-line max-len
                        `Для вида расхода <strong>"${estimateTaxPosition.kindConsumption?.name}"</strong> в заявке общая сумма <strong>${totalByNirRequest} руб.</strong>, сумма позиции созданной сметы шифра для данного вида расхода составляет <strong>${estimateTaxPosition.amount} руб.</strong>`,
                      );
                  });
                  if (!!messages.length) {
                    setAmountWarningMessage(messages.join('<br />'));
                  }
                },
              },
            );
          },
        },
      );
    }, [createEstimateByNirRequestAPI, getNirRequestAPI, nirRequest, tableStreams.reloadTable]);

    const handleCreateFromContestRequestButtonClick = useCallback(() => {
      if (!contestRequests.length) {
        showNotification({ message: 'Для данного проекта нет заявок на продление в статусе "Поддержана"', theme: 'danger' });
        return;
      }
      setIsCreateByContestRequestModalOpen(true);
    }, [contestRequests.length]);

    const handleCreateFromProgramRequestButtonClick = useCallback(() => {
      if (!programRequest) {
        showNotification({ message: 'Для данного проекта нет заявок на конкурс в статусе "Поддержана"', theme: 'danger' });
        return;
      }
      setIsCreateByProgramRequestModalOpen(true);
    }, [programRequest]);

    const handleCreateFromNirRequestButtonClick = useCallback(() => {
      if (!nirRequest) {
        showNotification({
          message: 'Для данного проекта нет заявок на внутренний конкурс в статусе "Поддержана"',
          theme: 'danger',
        });
        return;
      }
      setIsCreateByNirRequestModalOpen(true);
    }, [nirRequest]);

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'question' },
          title: 'Помощь',
          onClick: () => setIsHelpOpen(true),
        },
        {
          icon: { type: 'view' },
          title: 'Просмотр',
          onClick: handleViewButtonClick,
          permission: { name: Permits.ESTIMATE_VIEW },
          isDisabled: tableState.selectedRows.length !== 1,
        },
        {
          icon: { type: 'add' },
          title: 'Добавить',
          onClick: handleAddButtonClick,
          permission: { name: Permits.ESTIMATE_ADD },
          isDisabled: isDisabled,
        },
        {
          icon: { type: 'edit' },
          title: 'Редактировать',
          onClick: handleEditButtonClick,
          permission: { name: Permits.ESTIMATE_EDIT },
          isDisabled: isDisabled || tableState.selectedRows.length !== 1,
        },
        {
          icon: { type: 'remove' },
          title: 'Удалить',
          onClick: handleDeleteButtonClick,
          permission: { name: Permits.ESTIMATE_DELETE },
          isDisabled: isDisabled || tableState.selectedRows.length !== 1,
        },
        {
          icon: { type: 'add', mode: 'clock' },
          title: 'Создать на основе заявки на продление',
          onClick: handleCreateFromContestRequestButtonClick,
          permission: { name: Permits.ESTIMATE_ADD },
          isHidden: !projectCode,
          isDisabled: isDisabled,
        },
        {
          icon: { type: 'add', mode: 'new' },
          title: 'Создать на основе заявки на конкурс',
          onClick: handleCreateFromProgramRequestButtonClick,
          permission: { name: Permits.ESTIMATE_ADD },
          isHidden: !projectCode,
          isDisabled: isDisabled,
        },
        {
          icon: { type: 'add', mode: 'arrowRight' },
          title: 'Создать на основе заявки на внутренний конкурс',
          onClick: handleCreateFromNirRequestButtonClick,
          permission: { name: Permits.ESTIMATE_ADD },
          isHidden: !projectCode,
          isDisabled: isDisabled,
        },
        {
          icon: { type: 'print' },
          title: 'Отчеты',
          expandedList: { list: getReports, callback: handleSetCurrentReport },
        },
      ],
      [
        handleViewButtonClick,
        tableState.selectedRows.length,
        handleAddButtonClick,
        handleEditButtonClick,
        handleDeleteButtonClick,
        handleCreateFromContestRequestButtonClick,
        handleCreateFromProgramRequestButtonClick,
        handleCreateFromNirRequestButtonClick,
        getReports,
        handleSetCurrentReport,
      ],
    );

    return (
      <>
        <Toolbar buttons={buttons} />

        <modalTemplates.EstimateViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />

        <modalTemplates.EstimateEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={estimate?.id || nextPublicationId || selectedRow?.id}
          relatedTableState={tableState}
          estimate={estimate || undefined}
          amountWarningMessage={amountWarningMessage}
          fundCard={fundCard}
        />

        <modalTemplates.EstimateAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          projectCode={projectCode}
          relatedTableState={tableState}
          name={nextPublicationName}
          setNextPublicationId={setNextPublicationId}
          saveAndEdit={handleSaveAndEdit}
          fundCard={fundCard}
        />

        {selectedRow && tableState.selectedRows.length > 0 && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={() => setIsDeleteConfirmPopupOpen(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: () => setIsDeleteConfirmPopupOpen(false),
              },
            ]}
            size="small"
          >
            <>
              Смета на {selectedRow?.Year} по шифру № {selectedRow?.Code}. Удалить смету?
            </>
          </Modal>
        )}

        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            projectCodeId: projectCode?.id || selectedRow?.projectCodeId || undefined,
          }}
        />

        <Modal
          title="Список заявок для проекта"
          isOpen={isCreateByContestRequestModalOpen}
          onClose={() => {
            setIsCreateByContestRequestModalOpen(false);
            setContestRequestIndex(null);
          }}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Создать смету',
              onClick: () => createEstimateByContestRequest(),
              isDisabled: !selectedContestRequest,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => {
                setIsCreateByContestRequestModalOpen(false);
                setContestRequestIndex(null);
              },
            },
          ]}
          size="medium"
        >
          <Toolbar
            buttons={[
              {
                icon: { type: 'view' },
                title: 'Просмотр',
                onClick: () => setIsContestRequestFormOpen(true),
                isDisabled: selectedContestRequest === null,
              },
            ]}
          />
          <ListEditTable<Project.ContestRequest>
            rows={contestRequests}
            columns={[
              {
                label: 'ID',
                formatValue: row => row.id || '',
                styles: { width: '10%' },
              },
              {
                label: 'Год (Этап проекта)',
                formatValue: row => row.year || '',
                styles: { width: '10%' },
              },
              {
                label: 'Тип',
                formatValue: row => row.type?.label || '',
              },
              {
                label: 'Статус',
                formatValue: row => row.status?.label || '',
              },
            ]}
            selectedRowIndex={contestRequestIndex}
            selectRow={setContestRequestIndex}
          />
        </Modal>

        <Modal
          title="Создание сметы на основе заявки на конкурс"
          isOpen={isCreateByProgramRequestModalOpen}
          onClose={() => setIsCreateByProgramRequestModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Создать смету',
              onClick: () => createEstimateByProgramRequest(),
              isDisabled: !programRequest,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsCreateByProgramRequestModalOpen(false),
            },
          ]}
          size="small"
        >
          <p>Смета будет создана на основе заявки на конкурс:</p>
          <p>{programRequest?.label}</p>
        </Modal>

        <Modal
          title="Создание сметы на основе заявки на внутренний конкурс"
          isOpen={isCreateByNirRequestModalOpen}
          onClose={() => setIsCreateByNirRequestModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Создать смету',
              onClick: () => createEstimateByNirRequest(),
              isDisabled: !nirRequest,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsCreateByNirRequestModalOpen(false),
            },
          ]}
          size="small"
        >
          <p>Смета будет создана на основе заявки на внутренний конкурс:</p>
          <p>{nirRequest?.label}</p>
        </Modal>

        <ContestRequestViewTemplate
          isOpen={isContestRequestFormOpen}
          onClose={() => setIsContestRequestFormOpen(false)}
          id={selectedContestRequest?.id || undefined}
          relatedTableState={tableState}
        />

        <Modal
          mode="help"
          title="Сметы"
          isOpen={isHelpOpen}
          onClose={() => setIsHelpOpen(false)}
          size={['large', 'medium-height']}
        >
          {EstimateGridHelp()}
        </Modal>
      </>
    );
  });

export const Component = LeftPanelForThirdLevel;

import { Equip } from 'types/models';
import {
  getMaybeField,
  convertReferenceItemToXML,
  convertFileToXML,
  convertDocumentToRequest,
} from 'services/BackendAPI/configurations/commonConverters';
import { convertProgramToXML } from '../program/requestConverters';

export function convertEquipToRequest(equip: Equip.Equip) {
  const {
    id,
    name,
    manufacturerName,
    factoryNumber,
    stateNumber,
    stateUrl,
    model,
    country,
    manufactureYear,
    commissioningDate,
    disposeDate,
    manufacturer,
    analog,
    specifications,
    usefulPeriod,
    descriptionNote,
    classificator,
    okved,
    okpd,
    okof,
    ktru,
    ckp,
    contractNumber,
    contractDate,
    amount,
    inventoryNumber,
    nfaInventoryCardNumber,
    eisNumber,
    eisUrl,
    program,
    purchaseNote,
    installLocations,
    contacts,
    useCategories,
    documents,
    purpose,
    employeesProvisionConditions,
    outsidersProvisionConditions,
    purchaseYear,
    enterprise,
    photos,
    kitContents,
    equipKind,
    measureKind,
    measureLimit,
    measureScaleAmount,
    permissibleInaccuracy,
    interval,
    verificationEnterprise,
    price,
    verificationItems,
    verificationDocuments,
    investmentSources,
    balances,
    isLocalVerificationLocation,
    depreciationRate,
    verificationEnterpriseContract,
    responsibleContact,
    parentKitContent,
  } = equip;
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Name', name),
    ...getMaybeField('ManufacturerName', manufacturerName),
    ...getMaybeField('FactoryNumber', factoryNumber),
    ...getMaybeField('StateNumber', stateNumber),
    ...getMaybeField('StateUrl', stateUrl),
    ...getMaybeField('Model', model),
    ...(country ? { Country: convertReferenceItemToXML(country) } : {}),
    ...getMaybeField('ManufactureYear', manufactureYear),
    ...getMaybeField('CommissioningDate', commissioningDate),
    ...getMaybeField('DisposeDate', disposeDate),
    ...(manufacturer ? { Manufacturer: convertReferenceItemToXML(manufacturer) } : {}),
    ...getMaybeField('Analog', analog),
    ...getMaybeField('Specifications', specifications),
    ...getMaybeField('UsefulPeriod', usefulPeriod),
    ...getMaybeField('DescriptionNote', descriptionNote),
    ...(classificator ? { Classificator: convertReferenceItemToXML(classificator) } : {}),
    ...(okved ? { Okved: convertReferenceItemToXML(okved) } : {}),
    ...(okpd ? { Okpd: convertReferenceItemToXML(okpd) } : {}),
    ...(okof ? { Okof: convertReferenceItemToXML(okof) } : {}),
    ...(ktru ? { Ktru: convertReferenceItemToXML(ktru) } : {}),
    ...(ckp ? { Ckp: { _attr: { id: ckp.id } } } : {}),
    ...getMaybeField('ContractNumber', contractNumber),
    ...getMaybeField('ContractDate', contractDate),
    ...getMaybeField('Amount', amount),
    ...getMaybeField('InventoryNumber', inventoryNumber),
    ...getMaybeField('NfaInventoryCardNumber', nfaInventoryCardNumber),
    ...getMaybeField('EisNumber', eisNumber),
    ...getMaybeField('EisUrl', eisUrl),
    ...(program ? { Program: convertProgramToXML(program) } : {}),
    ...getMaybeField('PurchaseNote', purchaseNote),
    InstallLocations: installLocations.length ? { InstallLocation: installLocations.map(convertInstallLocationToXML) } : {},
    Contacts: contacts.length ? { Contact: contacts.map(convertContactToXML) } : {},
    Documents: documents.length ? { Document: documents.filter(x => x.docType !== 'foreign').map(convertDocumentToRequest) } : {},
    ...getMaybeField('Purpose', purpose),
    ...getMaybeField('EmployeesProvisionConditions', employeesProvisionConditions),
    ...getMaybeField('OutsidersProvisionConditions', outsidersProvisionConditions),
    ...getMaybeField('PurchaseYear', purchaseYear),
    ...(enterprise ? { Enterprise: convertReferenceItemToXML(enterprise) } : {}),
    Photos: photos.length ? { Photo: photos.map(convertPhotoToXML) } : {},
    KitContents: kitContents.length ? { KitContent: kitContents.map(convertKitContentToXML) } : {},
    ...(equipKind ? { EquipKind: convertReferenceItemToXML(equipKind) } : {}),
    ...(measureKind ? { MeasureKind: convertReferenceItemToXML(measureKind) } : {}),
    ...getMaybeField('MeasureLimit', measureLimit),
    ...getMaybeField('MeasureScaleAmount', measureScaleAmount),
    ...getMaybeField('PermissibleInaccuracy', permissibleInaccuracy),
    ...getMaybeField('Interval', interval),
    ...(verificationEnterprise ? { VerificationEnterprise: { _attr: { id: verificationEnterprise.id } } } : {}),
    ...getMaybeField('Price', price),
    VerificationItems: verificationItems.length ? { VerificationItem: verificationItems.map(convertVerificationItemToXML) } : {},
    VerificationDocuments: verificationDocuments.length
      ? { Document: verificationDocuments.filter(x => x.docType !== 'foreign').map(convertDocumentToRequest) }
      : {},
    UseCategories: useCategories.length ? { UseCategory: useCategories.map(convertReferenceItemToXML) } : {},
    InvestmentSources: investmentSources.length ? { InvestmentSource: investmentSources.map(convertInvestmentSourceToXML) } : {},
    Balances: balances.length ? { Balance: balances.map(convertBalanceToXML) } : {},
    IsLocalVerificationLocation: !!isLocalVerificationLocation,
    ...getMaybeField('DepreciationRate', depreciationRate),
    ...(verificationEnterpriseContract
      ? { VerificationEnterpriseContract: { _attr: { id: verificationEnterpriseContract.id } } }
      : {}),
    ...(responsibleContact ? { ResponsibleContact: { _attr: { id: responsibleContact.id } } } : {}),
    ...(parentKitContent ? { ParentKitContent: convertKitContentToXML(parentKitContent) } : {}),
  };
}

export function convertEquipVerificationContactToXML({ id, scientist, department, code }: Equip.EquipVerificationContact) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(scientist ? { Scientist: { _attr: { id: scientist.id } } } : {}),
    ...(department ? { Department: { _attr: { id: department.id } } } : {}),
    ...getMaybeField('Code', code),
  };
}

function convertBalanceToXML({
  id,
  // year, not editable
  date,
  accountNumber,
  amount,
  alphaAmount,
  // unmodulatedAlphaAmount, not editable
  residualAmount,
  responsible,
  note,
}: Equip.Balance) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Date', date),
    ...getMaybeField('AccountNumber', accountNumber),
    ...getMaybeField('Amount', amount),
    ...getMaybeField('AlphaAmount', alphaAmount),
    ...getMaybeField('ResidualAmount', residualAmount),
    ...(responsible ? { Responsible: { _attr: { id: responsible.id } } } : {}),
    ...getMaybeField('Note', note),
  };
}

function convertInvestmentSourceToXML({ id, source, amount, projectCode }: Equip.InvestmentSource) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Source', source),
    ...getMaybeField('Amount', amount),
    ...(projectCode ? { ProjectCode: { _attr: { id: projectCode.id } } } : {}),
  };
}

function convertVerificationItemToXML({
  id,
  date,
  enterprise,
  price,
  result,
  contractNumber,
  document,
  documentUrl,
  note,
  isLocalVerificationLocation,
}: Equip.VerificationItem) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Date', date),
    ...(enterprise ? { Enterprise: { _attr: { id: enterprise.id } } } : {}),
    ...getMaybeField('Price', price),
    ...(result ? { Result: convertReferenceItemToXML(result) } : {}),
    ...getMaybeField('ContractNumber', contractNumber),
    ...convertFileToXML('Document', document || undefined),
    ...getMaybeField('DocumentUrl', documentUrl),
    ...getMaybeField('Note', note),
    IsLocalVerificationLocation: !!isLocalVerificationLocation,
  };
}

function convertPhotoToXML({ id, photo }: Equip.Photo) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...(photo ? convertFileToXML('Photo', photo) : {}),
  };
}

function convertKitContentToXML({ id, name, quantity, unit, note, equip, equipPart }: Equip.KitContent) {
  return {
    ...(id ? { _attr: { id } } : {}),
    ...getMaybeField('Name', name),
    ...getMaybeField('Quantity', quantity),
    ...(unit ? { Unit: convertReferenceItemToXML(unit) } : {}),
    ...getMaybeField('Note', note),
    ...(equip ? { Equip: { _attr: { id: equip.id } } } : {}),
    ...(equipPart ? { EquipPart: { _attr: { id: equipPart.id } } } : {}),
  };
}

function convertInstallLocationToXML({
  id,
  address,
  building,
  cabinet,
  department,
  departmentResponsible,
  faculty,
  facultyResponsible,
  installDate,
}: Equip.InstallLocation) {
  return {
    ...(id ? { _attr: { id: id } } : {}),
    ...getMaybeField('Address', address),
    ...getMaybeField('Building', building),
    ...getMaybeField('Cabinet', cabinet),
    ...(department ? { Department: convertReferenceItemToXML(department) } : {}),
    ...(departmentResponsible?.id ? { DepartmentResponsible: { _attr: { id: departmentResponsible.id } } } : {}),
    ...(faculty ? { Faculty: convertReferenceItemToXML(faculty) } : {}),
    ...(facultyResponsible?.id ? { FacultyResponsible: { _attr: { id: facultyResponsible.id } } } : {}),
    ...getMaybeField('InstallDate', installDate),
  };
}

function convertContactToXML({ id, contact, note }: Equip.Contact) {
  return {
    ...(id ? { _attr: { id: id } } : {}),
    ...(contact?.id ? { Contact: { _attr: { id: contact.id } } } : {}),
    ...getMaybeField('Note', note),
  };
}

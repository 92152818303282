import { Form, SuchLikePublication } from 'types/models';
import { EventTypeCode } from 'types/models/Event';
import * as XML from 'xml-js';
import { makeBackendSuchlikePublicationAPIConfiguration } from '../makeBackendSuchlikePublicationAPIConfiguration';

type SuchlikePublicationListInput = {
  category: SuchLikePublication.Category;
  searchValue: SuchLikePublication.State['searchValue'];
  publicationType?: Form.PublicationTypeCode;
};

type SuchlikeEventListInput = {
  eventType: EventTypeCode;
  searchValue: SuchLikePublication.State['searchValue'];
  isStrictSearch?: boolean;
  startDate?: string;
  endDate?: string;
};

type AddUserDepartmentToPublicationInput = {
  id: string;
};

function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => XML.ElementCompact,
): (input: Input) => XML.ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName },
      ...makeChildren(input),
    },
  });
}

function makeSuchlikePublicationRequestXMLConverter<Input>(
  commandName: string,
  makeParametersConverter: (input: Input) => XML.ElementCompact,
) {
  return makeRequestXMLConverter<Input>(commandName, (input: Input) => ({
    ...makeParametersConverter(input),
  }));
}

export const backendSuchlikePublicationApiConfigurations = {
  GetSuchlikePublicationList: makeBackendSuchlikePublicationAPIConfiguration(
    'GetSuchlikePublicationList',
    makeSuchlikePublicationRequestXMLConverter('GetSuchlikePublicationList', (input: SuchlikePublicationListInput) => {
      const { searchValue, category } = input;
      const preapredInput = {
        GetSuchlikeList: {
          _attr: { searchValue, category, ...(input.publicationType ? { publicationType: input.publicationType } : {}) },
        },
      };
      return preapredInput;
    }),
  ),
  GetSuchlikeEventList: makeBackendSuchlikePublicationAPIConfiguration(
    'GetSuchlikeEventList',
    makeSuchlikePublicationRequestXMLConverter('GetSuchlikeEventList', (input: SuchlikeEventListInput) => {
      const { searchValue, eventType, isStrictSearch, startDate, endDate } = input;
      const restProps: any = {};

      if (isStrictSearch) {
        restProps.isStrictSearch = 'true';
        if (startDate) {
          restProps.startDate = startDate;
        }

        if (endDate) {
          restProps.endDate = endDate;
        }
      }
      const preapredInput = {
        GetSuchlikeList: { _attr: { searchValue, eventType, ...restProps } },
      };
      return preapredInput;
    }),
  ),
  GetSuchlikeParticipationList: makeBackendSuchlikePublicationAPIConfiguration(
    'GetSuchlikeParticipationList',
    makeSuchlikePublicationRequestXMLConverter('GetSuchlikeParticipationList', (input: SuchlikeEventListInput) => {
      const { searchValue, eventType } = input;
      const preapredInput = {
        GetSuchlikeList: { _attr: { searchValue, eventType } },
      };
      return preapredInput;
    }),
  ),
  AddUserDepartmentToPublication: makeBackendSuchlikePublicationAPIConfiguration(
    'AddUserDepartmentToPublication',
    makeSuchlikePublicationRequestXMLConverter('AddUserDepartmentToPublication', (input: AddUserDepartmentToPublicationInput) => {
      const { id } = input;
      const preparedInput = {
        Publication: { _attr: { id } },
      };
      return preparedInput;
    }),
  ),
};

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import { ScientistStatisticsGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { settings } = useAppDataContext();

  const { isSearchMode } = customState;

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isPersonButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isPublicationButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isPersonFormOpen, setIsPersonFormOpen] = useState(false);
  const [isPublicationFormOpen, setIsPublicationFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handlePersonButtonClick = useCallback(() => {
    setIsPersonFormOpen(true);
  }, []);

  const handleTemplateClosePersonForm = useCallback(() => {
    setIsPersonFormOpen(false);
  }, []);

  const handlePublicationButtonClick = useCallback(() => {
    setIsPublicationFormOpen(true);
  }, []);

  const handleTemplateClosePublicationForm = useCallback(() => {
    setIsPublicationFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'edit' },
        title: 'Добавить/Редактировать индекс Хирша персоны',
        onClick: handleViewButtonClick,
        isHidden: isSearchMode,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'user' },
        title: 'Открыть карточку персоны',
        onClick: handlePersonButtonClick,
        isDisabled: isPersonButtonDisabled,
      },
      {
        icon: { type: 'list', mode: 'view' },
        title: 'Список публикаций автора для расчёта совокупного импакт-фактора',
        onClick: handlePublicationButtonClick,
        isHidden: isSearchMode,
        isDisabled: isPublicationButtonDisabled,
      },
    ],
    [
      isSearchMode,
      isViewButtonDisabled,
      handleViewButtonClick,
      isPersonButtonDisabled,
      handlePersonButtonClick,
      isPublicationButtonDisabled,
      handlePublicationButtonClick,
      handleHelpButtonClick,
    ],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isViewButtonDisabled && (
        <modalTemplates.ScientistHirshIndexTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isPersonButtonDisabled && (
        <modalTemplates.ScientistPersonTemplate.Component
          isOpen={isPersonFormOpen}
          onClose={handleTemplateClosePersonForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isPublicationButtonDisabled && (
        <modalTemplates.ScientistPublicationTemplate.Component
          isOpen={isPublicationFormOpen}
          onClose={handleTemplateClosePublicationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal
        mode="help"
        title={isSearchMode ? 'Поиск персон по научным интересам' : 'Помощь в Наукометрических показателях персон'}
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {ScientistStatisticsGridHelp(isSearchMode, settings?.organization?.shortName)}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

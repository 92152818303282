import { Icon } from 'components';

export function ScientistStatisticsGridHelp(isSearchMode?: boolean, organization?: string): JSX.Element {
  return !!isSearchMode ? (
    <>
      <h4>
        Раздел Поиск персон по научным интересам содержит информацию о научных интересах сотрудников, аспирантов и студентов
        университета.
      </h4>

      <p>Используйте строку поиска по словам в столбцах таблицы для поиска персон по тематике или направлению исследований.</p>

      <div>
        Список персон вы можете выгрузить в файл с помощью кнопки <Icon type="excel" />
      </div>
      <div>
        Посмотреть портфолио персоны — с помощью кнопки <Icon type="person" mode="info" />
      </div>
    </>
  ) : (
    <>
      <p>
        Раздел содержит сведения о наукометрических показателях сотрудников, аспирантов, студентов {organization}: индекс Хирша в
        основных базах индексации публикаций (Scopus, WoS, РИНЦ) совокупный, пятилетний и средневзвешенный импакт- фактор,
        статистика по публикациям, в том числе по годам.
      </p>
      <p>
        Импакт-фактор для персон пересчитывается 1 раз в сутки, ночью, на основе данных об импакт- факторе издания публикации.
        Значения индекса Хирша обновляют в системе ответственные лица не реже двух раз в год.
      </p>

      <h4>В разделе можно использовать фильтры для сортировки информации:</h4>

      <ul>
        <li>Фильтр «С публикациями» отображает список персон, имеющих публикации</li>
        <li>Фильтр «Все» отображает список персон, из состава Справочника персон</li>
        <li>
          Фильтр «Год» показывает количество публикаций за указанный в фильтре год; если фильтр отключен, то отображается общее
          количество публикаций.
        </li>
      </ul>

      <h4>В разделе Наукометрические показатели персон вы можете:</h4>

      <ul>
        <li>
          Добавить/редактировать индекс Хирша персоны — с помощью кнопки (при наличии права) <Icon type="edit" />
        </li>
        <li>
          Открыть карточку персоны для просмотра — с помощью кнопки <Icon type="user" />
        </li>
        <li>
          Посмотреть список публикаций автора, которые использовались для расчета совокупного импакт-фактора нажатием кнопки
          <Icon type="list" mode="view" />
        </li>
        <li>
          Выгрузить данные в формат Еxcel — с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Задать вопрос администратору — с помощью кнопки <Icon type="admin" />
        </li>
      </ul>
    </>
  );
}

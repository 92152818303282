import { Table } from 'types/models';

type Args = {
  hasSelectButton?: boolean;
};

export function GetEquipToVerificationList({ hasSelectButton }: Args): Table.Specification {
  return {
    apiID: 'GetEquipToVerificationList',
    header: {
      firstLevel: {
        title: 'Оборудование',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton,
      },
    },
  };
}

import React, { memo } from 'react';

import { FormComponent, Radio } from 'components';

import { BuilderProps, RadioItem } from 'features/BuildReportPopup/models';
import useController from './controller';

type Props = { initialRadioList: RadioItem[]; defaultValue?: string } & BuilderProps;

const DefaultRadioList = ({ parameters, setParameterValueByName, parameterName, initialRadioList, defaultValue }: Props) => {
  const { radios, canBuild, radioValue, updateRadioValue, radioListParameter } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
    initialRadioList,
    defaultValue,
  });

  return (
    <>
      {canBuild && (
        <FormComponent.Line>
          <FormComponent.Field label={radioListParameter?.description} isRequired={radioListParameter?.required}>
            <Radio list={radios} onChange={updateRadioValue} value={radioValue} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DefaultRadioList);

export { Component as DefaultRadioList };

import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';

type Props = F.TemplateProps;

const ExpositionParticipationViewTemplate: React.FC<Props> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  disabledEvent,
  disableUpdateAfterSubmit,
}: Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.ParticipationFormLook = {
    apiID: 'GetParticipation',
    template: 'ParticipationFormExposition',
    relatedTableState,
    id,
    viewMode: true,
    disabledEvent,
    disableUpdateAfterSubmit,
    setTitle,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" isViewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ExpositionParticipationViewTemplate);

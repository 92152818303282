import React from 'react';

import { FormComponent } from 'components';

import { Project } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { JobPeriods } from './JobPeriods';
import { useController } from './controller';
import { Mode } from '..';

type Props = {
  mode: Mode;
  projectId: string | null;
  performer: Project.Performer;
  setPerformer(performer: Project.Performer): void;
  startProjectDate?: string;
  endProjectDate?: string;
};

function modalTemplate(props: Props) {
  const { mode, projectId, performer, setPerformer, startProjectDate, endProjectDate } = props;

  const { handleUpdateMember, setJobPeriods, history } = useController({
    performer,
    setPerformer,
  });

  return (
    <FormComponent.Wrapper>
      {mode === 'add' && (
        <FormComponent.Line>
          <FormComponent.Field label="ФИО" isRequired>
            <SelectPerson
              member={{ ...getMemberWithoutHistory(performer.person), ...history }}
              onUpdateMember={handleUpdateMember}
              hiddenButtons={['delete']}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <JobPeriods
          mode={mode}
          jobPeriods={performer.jobPeriods}
          person={performer.person}
          history={mode === 'add' ? history : {}}
          setJobPeriods={setJobPeriods}
          startProjectDate={startProjectDate}
          endProjectDate={endProjectDate}
          projectId={projectId}
          disabled={mode === 'view'}
        />
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(modalTemplate);

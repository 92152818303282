export enum Project {
  MODULE = 'Project',
  STATE_REGISTRATION = 'StateRegistration',
  FILES = 'Files',
  EXECUTORS = 'Executors',
  PARTNERS = 'Partners',
  ACCOMPLICES = 'Accomplices',
  INT_COOP = 'IntCoop',
  CALENDAR_PLAN = 'CalendarPlan',
  CLASSIFICATORS = 'Classificators',
  ABOUT_PROJECT = 'AboutProject',
  DESCRIPTION = 'Description',
  FEATURES = 'Features',
  RESULTS = 'Results',
  PURCHASE_REQUEST = 'PurchaseRequest',
  RESTRICTIONS = 'Restrictions',
  ASSIGNMENTS = 'Assignments',
}

import React, { useMemo, useState } from 'react';

import {
  ButtonProps,
  FormComponent,
  Modal,
  SectionTitle,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  Toolbar,
} from 'components';

import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';
import { DocumentList } from './DocumentList';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  disabled: boolean;
  project: Project.Project | null;
  makeChangeHandler: (key: keyof Project.Project) => (val: ValueOf<Project.Project>) => void;
};

export function StateForm(props: Props) {
  const { disabled, project, makeChangeHandler } = props;

  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
    ],
    [],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <FormComponent.Line>
        <FormComponent.Field label="Номер госрегистрации">
          <TextInput value={project?.stateNumber} onChange={makeChangeHandler('stateNumber')} isDisabled={disabled} />
        </FormComponent.Field>
        <FormComponent.Field label="Дата госрегистрации">
          <TextDateTime
            value={project?.stateRegistrationDate}
            onChange={makeChangeHandler('stateRegistrationDate')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Ссылка на ЕГИСУ">
          <TextInput
            mode={TextInputMode.URL}
            value={project?.stateRegistrationEgisuUrl}
            onChange={makeChangeHandler('stateRegistrationEgisuUrl')}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Аннотация"
          info={<>Вариант аннотации темы проекта для регистрации проекта в ЕГИСУ. Объём — до 1300 знаков.</>}
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Аннотация' }}
            value={project?.stateRegistrationAnnotation ?? ''}
            onChange={makeChangeHandler('stateRegistrationAnnotation')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Научный задел"
          info={<>Вариант описания раздела «научный задел по теме» для регистрации проекта в ЕГИСУ. Объём — до 7000 знаков.</>}
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Научный задел' }}
            value={project?.stateRegistrationScientificGroundwork ?? ''}
            onChange={makeChangeHandler('stateRegistrationScientificGroundwork')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Обоснование междисциплинарного подхода"
          info={
            <>
              Вариант описания раздела «Обоснование междисциплинарного подхода» для регистрации проекта в ЕГИСУ. Объём — до 3000
              знаков.
            </>
          }
        >
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Обоснование междисциплинарного подхода' }}
            value={project?.stateRegistrationInterdisciplinaryAproachJustification ?? ''}
            onChange={makeChangeHandler('stateRegistrationInterdisciplinaryAproachJustification')}
            isDisabled={!!disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Документы из ЕГИСУ" />

      <FormComponent.Line>
        <DocumentList documents={project?.documents.filter(d => d.projectDocumentType?.customFields?.code === 'EGISU') || null} />
      </FormComponent.Line>

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Госрегистрация проекта"
        size="large"
      >
        {ProjectHelp().registrationProject}
      </Modal>
    </>
  );
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextDateTime, TextInput, TextInputMode, UploadFile } from 'components';

import { SecurityDocument, SecurityDocumentPayment } from 'types/models/SecurityDocument';
import { useController } from './controller';

type Props = {
  securityDocument: SecurityDocument;
  payment: SecurityDocumentPayment | null;
  onPaymentChange(row: SecurityDocumentPayment): void;
  isRequestPayments?: boolean;
};

function PaymentInputs({ securityDocument, payment, onPaymentChange, isRequestPayments }: Props) {
  const { handleFieldChange, handleFileChange } = useController({
    payment,
    onPaymentChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        {!securityDocument.kind?.id && (
          <FormComponent.Description mode="warning">
            Невозможно выбрать "Назначение платежа" пока не будет указан "Вид ОИС" на вкладке "Описание"
          </FormComponent.Description>
        )}
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Field label="Назначение платежа" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={
                  isRequestPayments
                    ? payment?.paymentPurposeRequest
                    : payment?.paymentPurpose
                    ? { ...payment?.paymentPurpose, label: `${payment?.paymentPurpose.label} (${securityDocument.kind?.label})` }
                    : null
                }
                onChange={(option: ReferenceItem | null) =>
                  handleFieldChange(isRequestPayments ? 'paymentPurposeRequest' : 'paymentPurpose')(option)
                }
                settings={{
                  name: isRequestPayments ? 'RefPaymentPurposeRequest' : 'RefPaymentPurpose',
                  title: 'Справочник "Назначения платежа"',
                  ...(isRequestPayments
                    ? {}
                    : {
                        filters: [
                          {
                            key: 'securityDocumentKind',
                            values: [securityDocument.kind?.id] as string[],
                          },
                        ],
                      }),
                }}
                isDisabled={!securityDocument.kind?.id}
              />
            </FormComponent.Field>
          </FormComponent.Column>
          <FormComponent.Column>
            {!isRequestPayments && (
              <FormComponent.Field label="Оплатить до">
                <TextDateTime value={payment?.payBeforeDate} onChange={handleFieldChange('payBeforeDate')} />
              </FormComponent.Field>
            )}
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>
      </FormComponent.Line>

      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Сумма" isRequired>
              <TextInput
                mode={TextInputMode.NUMBER}
                value={payment?.amount ? payment?.amount.toString() : '0'}
                onChange={handleFieldChange('amount')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Оплачено">
              <TextDateTime value={payment?.payDate} onChange={handleFieldChange('payDate')} />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <FormComponent.Line>
        <FormComponent.Field label="Файл документа">
          <UploadFile file={payment?.file} onChange={handleFileChange} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { PaymentInputs };

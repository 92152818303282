import React, { useCallback, useMemo } from 'react';

import {
  Checkbox,
  Column,
  FormComponent,
  ListEdit,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
} from 'components';

import { Table as T, Form as F, Event as E, Author, Award, Event } from 'types/models';
import {
  GetCompilationListByTypeSpecification,
  GetConferencePublicationList,
  GetShortEventList,
} from 'features/Table/specifications';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { MAIN_REPORTER_TOOLTIP_TEXT } from 'features/Form/looks/participation/views/MembersList/helpers';
import { EventFormat } from 'utils/Enums';
import { MembersList } from 'features/Form/looks/participation/views/MembersList';
import { getAuthToken, getFileUrl } from 'utils/Helpers';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { getEventFullName, getEventMagazineSource, getMockEvent } from 'utils/Helpers/event';

type Props = {
  mainReporterName: string;
  formFields: F.Fields;
  workMode: T.WorkMode;
  sourcePublicationId: string | null;
  event: Event.Event | null;
  publication: F.Publication | null;
  eventPublications: { type: string; publication: E.Source }[];
  setEvent: (event: Event.Event | null) => void;
  relatedTableState: T.State | undefined;
  members: Author[];
  changeMembers: (val: Author[]) => void;
  onChangeIsPublished: (value: boolean) => void;
  disabledEvent?: boolean;
  isDisabledPublicationInfo?: boolean;
  onUpdatePublicationReportTheme: (publicationReportTheme: F.Original | null) => void;
  isConferenceWithReportParticipation: boolean;
  awards: Award[];
};

function About({
  mainReporterName,
  formFields,
  workMode,
  publication,
  event,
  sourcePublicationId,
  setEvent,
  relatedTableState,
  onChangeIsPublished,
  members,
  changeMembers,
  eventPublications,
  disabledEvent,
  isDisabledPublicationInfo,
  onUpdatePublicationReportTheme,
  isConferenceWithReportParticipation,
  awards,
}: Props) {
  const token = getAuthToken();

  const modalTableRowToPublicationReportThemeConverter = useCallback<(row: T.Entry) => F.Original>(
    row => ({ id: row.id, name: row.name }),
    [],
  );

  const modalTableRowToEventConventer = useCallback<(row: T.Entry) => Event.Event>(
    row => ({
      ...getMockEvent(),
      id: row.id,
      name: row.name,
      status: { value: row['id:status'], label: row.status },
      fullName: row.fullName,
      startDate: row.startDate,
      endDate: row.endDate,
      format: EventFormat.OFFLINE,
    }),
    [],
  );

  const sourceCompilationTableConfig = GetCompilationListByTypeSpecification({
    requestData: {
      type: PublicationTypes.COMPILATION_CONFERENCE.code,
      isElectronic: false,
    },
    hasSelectButton: true,
  });

  const eventPublicationColumns = useMemo<Column<{ type: string; publication: E.Source }>[]>(
    () => [
      {
        label: 'Тип',
        formatValue: row =>
          row.type === PublicationTypes.COMPILATION_CONFERENCE.code
            ? PublicationTypes.COMPILATION_CONFERENCE.label
            : 'Выпуск журнала',
        styles: { width: '20%' },
      },
      {
        label: 'Публикация',
        formatValue: row =>
          row.type === PublicationTypes.COMPILATION_CONFERENCE.code
            ? row.publication.bibliographicRecord || ''
            : `Журнал: ${getEventMagazineSource(event)?.name || ''} Том: ${row.publication.part} Выпуск: ${
                row.publication.number
              } Год: ${row.publication.year}`,
        styles: { width: '60%' },
      },
      {
        label: 'Статус',
        formatValue: row => `${row.publication.status || ''} (ID: ${row.publication.id})`,
        styles: { width: '20%' },
      },
    ],
    [event],
  );

  const eventPublicationRowConverter = useCallback<(row: T.Entry) => { type: string; publication: E.Source }>(
    row => ({
      type: row.type,
      publication: {
        id: row.id,
        name: row.label,
        bibliographicRecord: row.bibliographicRecord,
        status: row.status,
        number: row.number,
        part: row.part,
        year: row.year,
      },
    }),
    [],
  );

  const shortEventTableConfig = GetShortEventList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
      relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
    },
    requestData: {
      eventType: 'CONFERENCE',
      allEvents: true,
      participationReportPublicationId: sourcePublicationId || undefined,
    },
    hasSelectButton: true,
    hasFilter: true,
    buttonsToHide: ['approve'],
  });

  const conferencePublicationListTableConfig = GetConferencePublicationList({
    requestData: {
      eventId: event?.id,
    },
    eventId: event?.id,
    editable: false,
  });

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title="Конференция" />

        <FormComponent.Line>
          <FormComponent.Field label="Конференция" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Конференции',
                isClearable: !isDisabledPublicationInfo,
                visibleStatus: getStatusRecordMessage('', event?.id, event?.status?.label),
                table: {
                  specification: shortEventTableConfig,
                  onSelect: (row: T.Entry | null) => setEvent(row ? modalTableRowToEventConventer(row) : null),
                },
              }}
              value={getEventFullName(event)}
              isDisabled={workMode === 'viewMode' || disabledEvent}
            />
            {(event?.eventStatus?.label || event?.format?.label || event?.site) && (
              <FormComponent.Description mode="warning" classMixin="is-hint">
                {event?.eventStatus?.label && (
                  <div>
                    <strong>Уровень мероприятия:</strong> {event?.eventStatus?.label}
                  </div>
                )}
                {event?.format?.label && (
                  <div>
                    <strong>Формат проведения:</strong> {event?.format?.label}
                  </div>
                )}
                {event?.site && (
                  <div>
                    <strong>Online: </strong>
                    <FormComponent.Link href={event?.site} />
                  </div>
                )}
              </FormComponent.Description>
            )}
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field
            label="Формат участия"
            isRequired={formFields.form.required}
            // eslint-disable-next-line max-len
            tooltip='Выбор формата участия влияет на состав полей формы. Для формата Участие в деловой программе мероприятия достаточно указать сведения об участниках и прикрепить подтверждающий документ на вкладке "Документы".'
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.form.value}
              onChange={formFields.form.onChange}
              settings={{
                name: 'RefParticipationForm',
                title: 'Справочник "Форматы участия"',
                filters: [{ key: 'type', values: ['CONFERENCE'] }],
                isClearable: !formFields.form.required,
              }}
              isDisabled={workMode === 'viewMode' || isDisabledPublicationInfo}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <MembersList
            eventType="CONFERENCE"
            disabled={workMode === 'viewMode'}
            authors={members}
            setAuthors={changeMembers}
            awards={awards}
          />
        </FormComponent.Line>
      </FormComponent.Column>

      <FormComponent.Column>
        {isConferenceWithReportParticipation && (
          <>
            <SectionTitle
              title="Доклад"
              tooltip="Доклад обязателен, если участие в конференции является одним из результатов выполнения проекта"
            />

            <FormComponent.Line>
              <FormComponent.Field label={formFields.reportType.title} isRequired={formFields.reportType.required}>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={formFields.reportType.value}
                  onChange={formFields.reportType.onChange}
                  settings={{
                    name: 'RefTypeReport',
                    title: 'Справочник "Типы доклада"',
                    isClearable: !formFields.reportType.required,
                  }}
                  isDisabled={workMode === 'viewMode'}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label={formFields.reportDate.title} isRequired={!!formFields.reportDate.required}>
                <TextDateTime
                  value={formFields.reportDate.value}
                  onChange={formFields.reportDate.onChange}
                  isDisabled={workMode === 'viewMode'}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field
                label={formFields.reportTheme.title}
                tooltip={formFields.reportTheme.tooltipText}
                isRequired={formFields.reportTheme.required}
              >
                <TextArea
                  settings={{ rows: 3 }}
                  value={formFields.reportTheme.value}
                  onChange={formFields.reportTheme.onChange}
                  isDisabled={workMode === 'viewMode'}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field label={formFields.isPublished.title} tooltip={formFields.isPublished.tooltipText}>
                <Checkbox
                  checked={formFields.isPublished.value}
                  onChange={onChangeIsPublished}
                  isDisabled={
                    workMode === 'viewMode' || isDisabledPublicationInfo || !!formFields.publicationReportTheme.value?.id
                  }
                />
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field
                label={formFields.publicationReportTheme.title}
                tooltip={formFields.publicationReportTheme.tooltipText}
                isRequired={formFields.publicationReportTheme.required}
              >
                <TextArea
                  mode={TextAreaMode.TABLE}
                  settings={{
                    title: 'Список публикаций из сборников/журналов, связанных с данной конференцией',
                    iconTitle: 'Справочник "Публикации конференции"',
                    isClearable: !formFields.publicationReportTheme.required,
                    table: {
                      specification: conferencePublicationListTableConfig,
                      onSelect: (row: T.Entry | null) =>
                        onUpdatePublicationReportTheme(row ? modalTableRowToPublicationReportThemeConverter(row) : null),
                    },
                    help:
                      // eslint-disable-next-line max-len
                      'Если Вы уверены, что публикация доклада добавлена в базу, но отсутствует в этом списке, то в разделе с публикациями укажите связь с этой конференцией для сборника/ выпуска журнала, в котором опубликована статья (тезисы доклада)',
                  }}
                  value={formFields.publicationReportTheme.value?.name}
                  isDisabled={workMode === 'viewMode' || isDisabledPublicationInfo}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            {publication && (
              <>
                <FormComponent.Line>
                  <FormComponent.Field label={publication?.status?.typeLabel || 'Тип публикации'}>
                    <FormComponent.Text>
                      {getStatusRecordMessage(
                        publication?.fields?.bibliographicRecord,
                        publication?.status.id,
                        publication?.status ? publication?.status?.text.toLocaleLowerCase() : '',
                      )}
                    </FormComponent.Text>
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Файл статьи">
                    {publication?.file ? (
                      <FormComponent.Link href={getFileUrl(publication.file.id, token)} label={publication.file?.name} />
                    ) : (
                      <FormComponent.Link label="У публикации нет прикрепленного файла" />
                    )}
                  </FormComponent.Field>
                </FormComponent.Line>
              </>
            )}

            <FormComponent.Line>
              <FormComponent.Field label="Основной докладчик" tooltip={MAIN_REPORTER_TOOLTIP_TEXT}>
                <span dangerouslySetInnerHTML={{ __html: mainReporterName }} />
              </FormComponent.Field>
            </FormComponent.Line>
          </>
        )}

        <SectionTitle
          title="Издания, в которых опубликованы материалы конференции"
          // eslint-disable-next-line max-len
          tooltip="Для сборника материалов конференции необходимо указать связь с данной конференцией на вкладке Мероприятия и проекты;
Если материалы конференции опубликованы в журнале, связь с конференцией необходимо указать на форме выпуска журнала"
        />

        <FormComponent.Line>
          {eventPublications.length ? (
            <ListEdit
              maxHeight="none"
              rows={eventPublications}
              columns={eventPublicationColumns}
              onChange={() => {}}
              specification={{
                mode: 'relationTableModal',
                modalTableRowConverter: eventPublicationRowConverter,
                relationTableModalTitle: '',
                modalTableSpecification: sourceCompilationTableConfig,
              }}
            />
          ) : (
            <FormComponent.Description mode="warning">
              В выбранной конференции не указаны специальные выпуски журналов и сборники материалов конференции, в которых
              опубликованы ее материалы. Если доклад опубликован, обязательно необходимо выбрать "Да" в строке "Опубликован" и
              добавить издание и статью (тезисы) доклада. Сведения о докладе будут загружаться в ЕГИСУ.
            </FormComponent.Description>
          )}
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

export const Component = React.memo(About);

import React from 'react';
import { ListEdit, SectionTitle } from 'components';
import { ForeignProject } from 'types/models';
import { getMockFinancing } from 'features/Form/looks/foreignProject/getMockModel';
import { showNotification } from 'features/Notifications';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  financings: ForeignProject.Financing[];
  setFinancings: (financings: any[]) => void;
  isDisabled: boolean;
};

export const FinancingListEdit = ({ financings, setFinancings, isDisabled }: Props) => (
  <>
    <SectionTitle title="Финансирование" />

    <ListEdit
      rows={financings}
      onChange={setFinancings}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Сумма', formatValue: x => x.amount, styles: { width: '50%' }, dataKind: 'float' },
        { label: 'Год', formatValue: x => x.year, styles: { width: '50%' } },
      ]}
      withMessages
      isDeleteConfirmEnabled
      isDisabled={isDisabled}
      defaultRowsCount={3}
      columnIndexesForSumTotal={[0]}
      maxHeight="350px"
      specification={{
        mode: 'customComponent',
        renderComponent: (financing, setFinancing) => (
          <Fields financing={financing ?? getMockFinancing()} setFinancing={setFinancing} />
        ),
        validation: {
          checkIsValid: (financing, index, financingList, mode) =>
            validate(financing, index, financingList, mode).every(x => x.isValid),
          onInvalidate: (financing, mode, index, financingList) => {
            validate(financing, index, financingList, mode).forEach(({ invalidMessage }) => {
              setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
            });
          },
        },
      }}
    />
  </>
);

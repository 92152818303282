import React from 'react';

import { FormComponent, TextArea, TextAreaMode, TextInput } from 'components';

import { GetProgramDirectionList } from 'features/Table/specifications';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { Table } from 'types/models';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

function ProgramEventForm({ viewMode, editMode, onClose }: Props) {
  const { formFields, handleFormSubmit, programId } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.name.title} isRequired>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.code.title} isRequired>
            <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Направления программы">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Направления программы',
                table: {
                  specification: GetProgramDirectionList(programId),
                  onSelect: (row: Table.Entry | null) =>
                    formFields.direction.onChange(row ? { id: row.id, label: row.Name } : null),
                },
              }}
              value={formFields.direction.value?.label}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProgramEventForm);

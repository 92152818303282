import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {};

type Props = {
  setKindConsumptionId?(kindConsumptionId: string): void;
};

export function makeUseCustomController({ setKindConsumptionId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setKindConsumptionId?.(selectedRows[0]?.id);
    }, [selectedRows]);
    return {};
  };
}

import { useEffect } from 'react';

import { Table } from 'types/models';

export type State = {
  setProgramEventId(programEventId: string): void;
  programId?: string;
};

type Props = {
  setProgramEventId(programEventId: string): void;
  programId?: string;
};

export function makeUseCustomController({ setProgramEventId, programId }: Props) {
  return function useCustomController({ selectedRows }: Table.UseCustomControllerProps): State {
    useEffect(() => {
      if (selectedRows.length) setProgramEventId(selectedRows[0]?.id);
    }, [selectedRows]);
    return {
      setProgramEventId,
      programId,
    };
  };
}

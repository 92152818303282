import { LockableEntity } from 'types/models';

import { convertMaybeArray } from '../commonConverters';

export function convertAvailableLockableEntities(availableLockableEntities: any): LockableEntity[] {
  const preparedData = availableLockableEntities || [];
  const value = convertMaybeArray(preparedData, convertLockableEntity);
  return value;
}

function convertLockableEntity(lockableEntity: any): LockableEntity {
  return {
    name: lockableEntity?._attributes?.name || '',
    caption: lockableEntity?._attributes?.caption || '',
  };
}

import React, { memo, useMemo } from 'react';
import { setup } from 'bem-cn';

import { AbstractBoolean } from './AbstractBoolean';
import { BuilderModel, BuilderProps, PropsValues } from '../models';
import { AbstractInteger } from './AbstractInteger';
import { AbstractString } from './AbstractString';
import { PARAMETER_TYPE } from '../helpers';

import './style.scss';

const block = setup({ mod: '--' });
const reportBuilder = block('report-builder');

type Props = Pick<BuilderProps, 'parameters' | 'setParameterValueByName'> & {
  builders: BuilderModel[];
  propsValues: PropsValues;
};

const Builder = ({ parameters, setParameterValueByName, builders, propsValues }: Props) => {
  const preparedBuilders = useMemo(() => {
    const promptingParameters = parameters.filter(({ prompting }) => !!prompting);
    const mappedBuilders = promptingParameters
      .map(parameter => {
        const builder = builders.find(({ name }) => name === parameter.name);
        let PreparedComponent: null | React.MemoExoticComponent<
          ({ parameters, setParameterValueByName }: BuilderProps) => JSX.Element
        > = null;
        if (builder) {
          PreparedComponent = builder.component;
        } else if (parameter.isAbstractParameter) {
          switch (parameter.type) {
            case PARAMETER_TYPE.STRING:
              PreparedComponent = AbstractString;
              break;
            case PARAMETER_TYPE.INTEGER:
              PreparedComponent = AbstractInteger;
              break;
            case PARAMETER_TYPE.BOOLEAN:
              PreparedComponent = AbstractBoolean;
              break;
            default:
              break;
          }
        }

        if (PreparedComponent) {
          return (
            <li className={reportBuilder('item')} key={parameter.name}>
              <PreparedComponent
                parameters={parameters}
                setParameterValueByName={setParameterValueByName}
                parameterName={parameter.name}
                propsValues={propsValues}
              />
            </li>
          );
        }

        return null;
      })
      .filter(builder => builder !== null);
    return mappedBuilders;
  }, [builders, parameters, propsValues, setParameterValueByName]);

  return (
    <div className={reportBuilder()}>
      <ul className={reportBuilder('list')}>{preparedBuilders}</ul>
    </div>
  );
};

const Component = memo(Builder);

export { Component as Builder };

import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, TextDateTime, UploadFile } from 'components';

import { SelectPerson } from 'features/SelectPerson';
import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function RecognitionForm({ viewMode, editMode, onClose }: Props) {
  const { formFields, author, updateAuthor, onSubmit, memberEditLocked } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field isRequired label="ФИО">
            <SelectPerson
              member={author}
              onUpdateMember={updateAuthor}
              hiddenButtons={['delete']}
              disabled={!!viewMode || memberEditLocked}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label={formFields.award.title} isRequired={formFields.award.required}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.award.value}
              onChange={formFields.award.onChange}
              settings={{
                name: 'RefRecognitionAward',
                title: 'Справочник "Награды"',
                isClearable: !formFields.award.required,
              }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Категория награды">
            <FormComponent.Text>{formFields.awardCategory.value}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.dateGet.title} isRequired={formFields.dateGet.required}>
            <TextDateTime value={formFields.dateGet.value} onChange={formFields.dateGet.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.file.title}>
            <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.description.title} tooltip={formFields.description.tooltipText}>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label={formFields.note.title}>
            <TextArea
              settings={{ rows: 3 }}
              value={formFields.note.value}
              onChange={formFields.note.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(RecognitionForm);

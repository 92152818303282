import { useMemo, useCallback } from 'react';

import { FileInfo, Author, Award } from 'types/models';
import { Item } from 'types/models/common';
import { ParticipationTypeCode } from 'types/models/Participation';
import { EnumValue } from 'types/models/Table';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ExtraToolbarButton } from 'components/ListEdit/model';

type Props = {
  award: null | Award;
  onChange: (row: Award | null) => void;
  recipients: Author[];
  participationTypeCode: ParticipationTypeCode;
};

enum AwardCategory {
  TOP_LEVEL_AWARD = 'TOP_LEVEL_AWARD',
  PARTICIPANT_DIPLOMA = 'PARTICIPANT_DIPLOMA',
  OTHER = 'OTHER',
  STIPEND = 'STIPEND',
}

const useController = ({ award, recipients, participationTypeCode, onChange }: Props) => {
  const { enumMap } = useAppDataContext();

  const awardCategoryOptions = useMemo(() => {
    const awardCategoryEnumValues = enumMap.AwardCategory.values || [];
    const mapCallback = (enumValue: EnumValue): Item => ({ value: enumValue.value, label: enumValue.label });

    switch (participationTypeCode) {
      case ParticipationTypes.PARTICIPATION_CONTEST.code:
        return awardCategoryEnumValues
          .filter(enumValue => AwardCategory.TOP_LEVEL_AWARD !== enumValue.value)
          .map<Item>(mapCallback);
      case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
        return awardCategoryEnumValues
          .filter(enumValue => [AwardCategory.PARTICIPANT_DIPLOMA, AwardCategory.OTHER].some(value => value === enumValue.value))
          .map<Item>(mapCallback);
      case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
        return awardCategoryEnumValues.filter(enumValue => enumValue.value !== AwardCategory.STIPEND).map<Item>(mapCallback);
      case ParticipationTypes.PARTICIPATION_CONCERT.code:
        return awardCategoryEnumValues.filter(enumValue => enumValue.value !== AwardCategory.STIPEND).map<Item>(mapCallback);
      default:
        return [];
    }
  }, [enumMap.AwardCategory.values, participationTypeCode]);

  const handleChange = useCallback(
    (key: keyof Award) => (value: Award[typeof key]) => {
      onChange({ ...award, [key]: value } as Award);
    },
    [award, onChange],
  );

  const handleFileChange = useCallback(
    (fileInfo: FileInfo) => {
      onChange({
        ...award!,
        file: fileInfo,
      });
    },
    [onChange, award],
  );

  const filteredRecipients = useMemo(
    () =>
      recipients.filter(({ id }: Author) =>
        award && award.recipients ? award.recipients.every(recipient => recipient.person?.id !== id) : true,
      ),
    [recipients, award],
  );

  const recipientDirectoryValidation = useMemo(
    () => ({
      checkIsValid: (recipientRow: Author) => {
        if (recipientRow) {
          return true;
        }

        showNotification({ message: 'Выберите получателя награды', theme: 'danger' });
        return false;
      },
    }),
    [],
  );

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Author>[]>(
    () => [
      {
        icon: { type: 'list', mode: 'add' },
        title: 'Добавить весь список',
        onClick: () => handleChange('recipients')(filteredRecipients),
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: author => handleChange('recipients')(award?.recipients.filter(i => i.id !== author?.id)),
      },
    ],
    [award?.recipients, filteredRecipients, handleChange],
  );

  return {
    filteredRecipients,
    recipientDirectoryValidation,
    handleChange,
    handleFileChange,
    awardCategoryOptions,
    extraToolbarButtons,
  };
};

export default useController;

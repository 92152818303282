import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetReceivingDocumentList: Table.Specification = {
  apiID: 'GetReceivingDocumentList',
  header: {
    firstLevel: {
      title: 'Реестр приходных документов бухгалтерского учёта',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

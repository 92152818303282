import { GetCompilationListByTypeSpecification } from 'features/Table/specifications';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form, Table } from 'types/models';
import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  articlePublicationIds: string[];
  sourcePublicationId: string;
  onClose: () => void;
};

const controller = ({ onClose, articlePublicationIds, sourcePublicationId }: Props) => {
  const { methods: getPublicationAPI, state: getPublicationAPIState } = BackendAPI.useBackendAPI('GetPublication');
  const {
    methods: changeCompilationArticleCompilationAPI,
    state: changeCompilationArticleCompilationAPIState,
  } = BackendAPI.useBackendAPI('ChangeCompilationArticleCompilation');
  const localTableStreams = useLocalTableStreams();
  const isChangeCompilationArticleCompilationLoading = changeCompilationArticleCompilationAPIState.kind === 'pending';
  const isPublicationLoading = getPublicationAPIState.kind === 'pending';
  // новый сборник, к которому прикрепляются статьи
  const [sourceCompilation, setSourceCompilation] = useState<Form.Compilation | null>(null);
  // сборник, к которому были прикреплены эти статьи
  const [sourcePublication, setSourcePublication] = useState<Form.Publication | null>(null);

  const isLoading = useMemo(() => isChangeCompilationArticleCompilationLoading || isPublicationLoading, [
    isChangeCompilationArticleCompilationLoading,
    isPublicationLoading,
  ]);

  const getPublication = useCallback(
    ({ publicationId }: { publicationId: string }) =>
      new Promise<Form.Publication | null>(resolve => {
        getPublicationAPI.callAPI(
          {
            id: publicationId,
            simpleFields: {
              status: PublicationStatus.ADDED,
            },
            attrIdFields: {},
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getPublicationAPI],
  );

  const changeSourceCompilation = useCallback(
    async (compilation: Form.Compilation | null) => {
      if (compilation?.id !== sourcePublicationId) {
        setSourceCompilation(compilation);
      } else {
        showNotification({
          message: 'Нельзя выбрать тот же сборник к которому выбранные статьи изначально прикреплены',
          theme: 'danger',
        });
      }
    },
    [sourcePublicationId],
  );

  const modalTableRowToSourceCompilationConventer = useCallback<(row: Table.Entry) => Form.Compilation>(
    row => ({
      id: row.id,
      type: row['id:type'],
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: row.status,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );

  const sourceCompilationTableConfig = useMemo(
    () =>
      GetCompilationListByTypeSpecification({
        requestData: {
          type: sourcePublication?.type || '',
          isElectronic: sourcePublication?.fields?.isElectronic === 'true',
          articleIds: articlePublicationIds.join(','),
        },
      }),
    [sourcePublication, articlePublicationIds],
  );

  const handleSubmit = useCallback(() => {
    if (!sourceCompilation) {
      return;
    }

    changeCompilationArticleCompilationAPI.callAPI(
      {
        articlePublicationIds,
        sourcePublicationId: sourceCompilation?.id || '',
      },
      {
        onSuccessfullCall: () => {
          onClose();
          localTableStreams.reloadTable.push({});
        },
      },
    );
  }, [articlePublicationIds, changeCompilationArticleCompilationAPI, localTableStreams.reloadTable, onClose, sourceCompilation]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      const fetchedSourcePublication = await getPublication({ publicationId: sourcePublicationId });
      setSourcePublication(fetchedSourcePublication);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    sourceCompilation,
    handleSubmit,
    changeSourceCompilation,
    modalTableRowToSourceCompilationConventer,
    sourceCompilationTableConfig,
  };
};

export default controller;

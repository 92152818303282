import { Table as T } from 'types/models';

type RequestData = {
  equipId: string;
};

export function GetEquipProjectList(requestData: RequestData): T.Specification<{}, RequestData> {
  return {
    apiID: 'GetEquipProjectList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData,
  };
}

import { Table as T } from 'types/models';

import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export type DetailProps = {
  personId?: string;
};

export function GetScientistDissertationCouncilMemberList({ personId }: DetailProps): T.Specification {
  return {
    apiID: 'GetScientistDissertationCouncilMemberList',
    isFiltersHidden: true,
    header: {
      firstLevel: {
        title: 'Работа в составе диссертационных советов',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel(),
      },
    },
    requestData: { scientistId: personId || '-1' },
  };
}

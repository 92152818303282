import React from 'react';

import { Checkbox, FormComponent, Select } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';
import { ProjectReportBlock } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectReportAddForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleStageChange,
    onSubmit,
    stageOptions,
    report,
    setReport,
    reportTypeOptions,
    handleReportTypeChange,
    selectedStageLabel,
    isProjectLoadDone,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} isDisabled={!stageOptions.length} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Выберите этап проекта" isRequired>
            <Select
              value={{ value: report.stage?.id ?? '', label: selectedStageLabel }}
              options={stageOptions}
              onChange={handleStageChange}
              isDisabled={!stageOptions.length}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тип отчета" isRequired>
            <Select
              value={report.type}
              options={reportTypeOptions}
              onChange={handleReportTypeChange}
              isDisabled={!stageOptions.length}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Госрегистрация">
            <Checkbox
              checked={report.block?.value === ProjectReportBlock.STATE_REGISTRATION}
              onChange={e =>
                setReport(prev => ({
                  ...prev,
                  block: getEnumItem('ProjectReportBlock', e ? ProjectReportBlock.STATE_REGISTRATION : ProjectReportBlock.STAGE),
                }))
              }
              isDisabled={!stageOptions.length}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Description mode="warning">
          {isProjectLoadDone && !stageOptions.length
            ? 'У выбранного проекта нет этапов, для которых можно создать новый отчёт'
            : // eslint-disable-next-line max-len
              'Внимание: в подсистему мониторинга и уведомлений будут добавлены ключевые даты сроков подготовки и государственной регистрации отчёта.'}
        </FormComponent.Description>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectReportAddForm);

import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Button, ButtonMode, FormComponent, Modal, TextArea, TextAreaMode, TextDateTime, Toolbar } from 'components';

import { BuildReportPopup } from 'features/BuildReportPopup';
import { Table } from 'types/models';
import { useController } from './controller';
import { Component as ModalTemplate } from './modalTemplate';
import { State } from '../makeUseCustomController';
import { getMockSaveProjectScientistsJobPeriodArgs } from '../helpers';
import { GetProjectPerformerOrderList } from '../../GetProjectPerformerOrderList';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  customState: State;
  tableState: Table.State;
};

export type Mode = 'add' | 'edit' | 'view';

function LeftPanelForThirdLevel({ customState, tableState }: Props) {
  const { projectId, startProjectDate, endProjectDate, reloadProject, isProjectChanged, projectType, isDisabled } = customState;

  const {
    mode,
    title,
    isOpen,
    onClose,
    performer,
    setPerformer,
    isOpenDeleteConfirmPopup,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    modalButtons,
    isReportOpen,
    onReportClose,
    currentReport,
    saveProjectScientistsJobPeriodArgs,
    setSaveProjectScientistsJobPeriodArgs,
    isOpenSaveProjectScientistsJobPeriod,
    setIsOpenSaveProjectScientistsJobPeriod,
    saveProjectScientistsJobPeriod,
    invalidScientists,
    setInvalidScientists,
    isOpenInvalidScientists,
    setIsOpenInvalidScientists,
    isOpenHelpModal,
    setIsOpenHelpModal,
  } = useController({ projectId, tableState, reloadProject, isProjectChanged, projectType, isDisabled });

  const { methods: getPerformerOrder } = BackendAPI.useBackendAPI('GetProjectPerformerOrder');

  return (
    <>
      {!!isProjectChanged ? (
        <FormComponent.Description mode="warning" classMixin={['is-hint', 'auto-width']}>
          Прежде чем редактировать состав коллектива сохраните проект с помощью кнопки "Сохранить и продолжить"
        </FormComponent.Description>
      ) : (
        <Toolbar buttons={buttons} />
      )}

      <Modal
        title={title}
        isOpen={isOpen}
        onClose={mode === 'edit' ? undefined : onClose}
        size={['extralarge', mode === 'add' ? 'small-height' : 'medium-height']}
      >
        <FormComponent.Template>
          {mode !== 'view' && (
            <FormComponent.Description mode="warning">
              <span>
                <span>Сохранить изменения в данных участника проекта?</span>
                {modalButtons.map((button, index) => (
                  <span key={index}>
                    &nbsp;&nbsp;
                    <Button {...button} />
                  </span>
                ))}
              </span>
            </FormComponent.Description>
          )}

          <ModalTemplate
            mode={mode}
            projectId={projectId}
            performer={performer}
            setPerformer={setPerformer}
            startProjectDate={startProjectDate}
            endProjectDate={endProjectDate}
          />
        </FormComponent.Template>
      </Modal>

      {isOpenDeleteConfirmPopup && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isOpenDeleteConfirmPopup}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            ({tableState.selectedRows[0]?.Fio}, {tableState.selectedRows[0]?.Role}) Удалить участника коллектива?
          </>
        </Modal>
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectId: projectId ?? '',
        }}
      />

      <Modal
        title="Оформить периоды работы в проекте по приказу"
        isOpen={isOpenSaveProjectScientistsJobPeriod}
        onClose={() => {
          setIsOpenSaveProjectScientistsJobPeriod(false);
          setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: saveProjectScientistsJobPeriod,
            isDisabled:
              !saveProjectScientistsJobPeriodArgs.jobPeriod.startDate || !saveProjectScientistsJobPeriodArgs.jobPeriod.endDate,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => {
              setIsOpenSaveProjectScientistsJobPeriod(false);
              setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());
            },
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Дата начала работы" isRequired>
            <TextDateTime
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.startDate}
              onChange={e =>
                setSaveProjectScientistsJobPeriodArgs(prev => ({ ...prev, jobPeriod: { ...prev.jobPeriod, startDate: e } }))
              }
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата окончания работы" isRequired>
            <TextDateTime
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.endDate}
              onChange={e =>
                setSaveProjectScientistsJobPeriodArgs(prev => ({ ...prev, jobPeriod: { ...prev.jobPeriod, endDate: e } }))
              }
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Приказ для даты начала">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'На основании приказа',
                table: {
                  specification: GetProjectPerformerOrderList({
                    hasSelectButton: true,
                    projectId,
                  }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setSaveProjectScientistsJobPeriodArgs(prev => ({
                        ...prev,
                        jobPeriod: { ...prev.jobPeriod, startDateOrder: null },
                      }));
                      return;
                    }
                    getPerformerOrder.callAPI(
                      { id: row?.id },
                      {
                        onSuccessfullCall: ({ data }) =>
                          setSaveProjectScientistsJobPeriodArgs(prev => ({
                            ...prev,
                            jobPeriod: { ...prev.jobPeriod, startDateOrder: data },
                          })),
                      },
                    );
                  },
                },
              }}
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.startDateOrder?.number || ''}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Приказ для даты окончания">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'На основании приказа',
                table: {
                  specification: GetProjectPerformerOrderList({
                    hasSelectButton: true,
                    projectId,
                  }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setSaveProjectScientistsJobPeriodArgs(prev => ({
                        ...prev,
                        jobPeriod: { ...prev.jobPeriod, endDateOrder: null },
                      }));
                      return;
                    }
                    getPerformerOrder.callAPI(
                      { id: row?.id },
                      {
                        onSuccessfullCall: ({ data }) =>
                          setSaveProjectScientistsJobPeriodArgs(prev => ({
                            ...prev,
                            jobPeriod: { ...prev.jobPeriod, endDateOrder: data },
                          })),
                      },
                    );
                  },
                },
              }}
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.endDateOrder?.number || ''}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>

      <Modal
        mode="warning"
        title="Внимание"
        isOpen={isOpenInvalidScientists}
        onClose={() => {
          setInvalidScientists([]);
          setIsOpenInvalidScientists(false);
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => {
              setInvalidScientists([]);
              setIsOpenInvalidScientists(false);
            },
          },
        ]}
        size="small"
      >
        <strong>
          Для следующих членов коллектива не было внесено изменений - у исполнителей уже есть более одного периода работ
        </strong>

        <ul>
          {invalidScientists.map((x, index) => (
            <li key={index}>{x}</li>
          ))}
        </ul>
      </Modal>

      <Modal
        mode="help"
        isOpen={isOpenHelpModal}
        onClose={() => setIsOpenHelpModal(false)}
        title="Особенности заполнения на вкладке: Состав коллектива"
        size="large"
      >
        {ProjectHelp().executorsComposition}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, TextArea, TextAreaMode } from 'components';

import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { Table } from 'types/models';

type Props = {
  articlePublicationIds: string[];
  onClose: () => void;
  sourcePublicationId: string;
};

export const Component = memo(({ onClose, articlePublicationIds, sourcePublicationId }: Props) => {
  const {
    sourceCompilation,
    sourceCompilationTableConfig,
    handleSubmit,
    changeSourceCompilation,
    modalTableRowToSourceCompilationConventer,
  } = useController({ articlePublicationIds, sourcePublicationId, onClose });

  return (
    <FormComponent.Template>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Название сборника" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Сборники',
                isClearable: false,
                visibleStatus: getStatusRecordMessage('', sourceCompilation?.id, sourceCompilation?.status),
                table: {
                  specification: sourceCompilationTableConfig,
                  onSelect: (row: Table.Entry | null) =>
                    changeSourceCompilation(row ? modalTableRowToSourceCompilationConventer(row) : null),
                },
              }}
              value={sourceCompilation?.name}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button mode={ButtonMode.PRIMARY} text="Продолжить" onClick={handleSubmit} isDisabled={sourceCompilation === null} />
          <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
});

import { Table as T } from 'types/models';
import { ToolbarWrapper as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  templatesTableDependencies: T.TemplatesTableDependencies;
  workMode: T.WorkMode;
  parentId?: string;
  uniqID?: string;
  isSetupSourceRelationMode?: boolean;
};
export function GetMagazineArticleList({
  templatesTableDependencies,
  workMode,
  parentId,
  uniqID,
  isSetupSourceRelationMode,
}: Props): T.Specification {
  return {
    apiID: 'GetMagazineArticleList',
    templatesTableDependencies,
    workMode,
    uniqID,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({
          parentId,
          isSetupSourceRelationMode: Boolean(isSetupSourceRelationMode),
        }),
      },
    },
  };
}

import { Project } from 'types/models';

import { EditableIndicator } from '../model';

export function getMockDepartment(): Project.Department {
  return {
    id: null,
    governance: null,
    unit: null,
  };
}

export function getMockFinancing(): Project.Financing {
  return {
    id: null,
    source: null,
    type: null,
    amount: '0',
    factAmount: '',
    isNds: false,
  };
}

export function getMockStage(): Project.Stage {
  return {
    amountMain: '0.00',
    amountFact: '0.00',
    mainNdsPercent: '0.00',

    amountCofinancing1: '0.00',
    cofinancing1NdsPercent: '0.00',

    amountCofinancing2: '0.00',
    cofinancing2NdsPercent: '0.00',

    amountLocal: '0.00',
    localNdsPercent: '0.00',

    amountAccomplice: '0.00',
    accompliceNdsPercent: '0.00',

    amountExternalAccomplices: '0.00',
    endDate: '',
    id: null,
    indicators: [],
    indices: [],
    isLocked: false,
    isPatentResearchPlanned: false,
    number: '',
    startDate: '',

    annotation: '',
    expectedResults: '',
    goal: '',
    kindWork: null,
    name: '',
    task: '',
    workDescription: '',
    interdisciplinaryAproachJustification: '',
  };
}

export function getMockEditableIndicator(): EditableIndicator {
  return {
    id: null,
    ref: null,
    fact: '0',
    note: '',
    plan: '0',
    year: '',
    stageNumber: '',
  };
}

export function getMockPerformer(): Project.Performer {
  return {
    id: null,
    person: null,
    fio: '',
    createdBy: '',
    createdDate: '',
    jobPeriods: [],
  };
}

export function getMockJobPeriod(): Project.JobPeriod {
  return {
    academicRank: null,
    citizenship: null,
    degree: null,
    education: null,
    historyLabel: '',
    id: null,
    job: null,
    person: null,
    rank: null,
    endDate: '',
    endDateOrder: null,
    role: null,
    startDate: '',
    startDateOrder: null,
    note: '',
  };
}

export function getMockRefElement(): Project.RefElement {
  return {
    id: null,
    createdBy: '',
    createdDate: '',
    position: '0',
    element: null,
    isActual: false,
    isProject: false,
  };
}

export function getMockProject(project?: Partial<Project.Project>): Project.Project {
  return {
    activityKind: null,
    annotation: '',
    contestRequests: [],
    contractKind: null,
    criticalTechnologies: [],
    nirRequest: null,
    customers: [],
    departments: [],
    documents: [],
    endDate: '',
    expectedResults: '',
    expendituresBudgets: null,
    financialNote: '',
    financings: [],
    financingsByYear: [],
    partners: [],
    goal: '',
    grntis: [],
    id: null,
    interdisciplinaryAproachJustification: '',
    isBuyMaterial: false,
    isFinancingStopped: false,
    stopMoneyReason: null,
    isNirRequestDataConsumed: false,
    isSpecTheme: false,
    isYouth: false,
    keyWords: '',
    kindResearch: null,
    lksetss: [],
    name: '',
    nationalProjectExpenseCode: null,
    ntiEteTechnologies: [],
    ntiMarkets: [],
    ntrStrategies: [],
    number: '',
    numberPrefix: null,
    oecds: [],
    okved: null,
    okvedIncome: null,
    overheadPercent: '0',
    performers: [],
    knowledgeBranches: [],
    periodNote: '',
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    program: null,
    programEvent: null,
    programRequest: null,
    projectCodes: [],
    projectContractStatus: null,
    result: '',
    scienceBrunches: [],
    scienceDomainInterrests: [],
    scientificGroundwork: '',
    specification: null,
    stages: [],
    startDate: '',
    stateNumber: '',
    stateRegistrationAnnotation: '',
    stateRegistrationDate: '',
    stateRegistrationInterdisciplinaryAproachJustification: '',
    stateRegistrationScientificGroundwork: '',
    status: '',
    techPlatforms: [],
    workSpaces: [],
    type: null,
    udks: [],
    patentResearches: [],
    internationalProgram: null,
    foreignEnterprise: null,
    partnerCountries: [],
    securityDocumentContract: null,
    resourceUsageDepartments: [],

    isRequestProvision: false,
    requestProvisionAmount: '0.00',
    requestProvisionType: null,
    requestProvisionNote: '',

    isContractProvision: false,
    contractProvisionAmount: '0.00',
    contractProvisionType: null,
    contractProvisionNote: '',
    vaks: [],
    nirRequestDataCopyDetail: '',
    programRequestDataCopyDetail: '',
    purchaseRequests: [],
    securityDocuments: [],
    confidentialityLevel: null,
    okpd: null,
    isConfidentialityAlert: false,
    isRequiredStateRegistration: false,
    isRequiredMilitaryStandard: false,
    changes: [],
    equips: [],
    reports: [],
    restrictions: [],
    ...project,
  };
}

/** Project field */
export function getMockReport(report?: Partial<Project.Report>): Project.Report {
  return {
    id: null,
    block: null,
    stage: null,
    type: null,
    name: '',
    number: '',
    archiveNumber: '',
    sendDate: '',
    acceptedDate: '',
    customerDate: '',
    archiveSendDate: '',
    archiveAcceptedDate: '',
    keyWords: '',
    referat: '',
    note: '',
    approvements: [],
    ...report,
  };
}

export function getMockApprovement(): Project.Approvement {
  return {
    expertGroup: null,
    date: '',
    resolution: null,
    note: undefined,
  };
}

export const getMockResourceUsageDepartment = (): Project.ResourceUsageDepartment => ({
  id: null,
  department: null,
  note: '',
});

export function getMockProjectPartner(): Project.Partner {
  return {
    contractNumber: '',
    contractType: null,
    endDate: '',
    financings: [],
    id: null,
    kindWork: '',
    partner: null,
    role: null,
    startDate: '',
    note: '',
    contractName: '',
    leaderAppointment: '',
    leaderContacts: '',
    leaderDegree: '',
    leaderFullName: '',
    leaderJobEnterprise: null,
    leaderRank: '',
  };
}

export function getMockPartnerFinancing(): Project.PartnerFinancing {
  return {
    id: null,
    amount: '',
    year: '',
  };
}

export function getMockContestRequest(): Project.ContestRequest {
  return {
    id: null,
    status: null,
    createdBy: '',
    projectNote: '',
    requestStageEndDate: '',
    requestStageStartDate: '',
    projectStageId: null,
    year: '',
    type: null,
    dataCopyDetail: '',
  };
}

/** for Project field */
export function getMockEquip(equip?: Project.Equip): Project.Equip {
  return {
    id: null,
    equip: null,
    stage: null,
    startDate: '',
    endDate: '',
    dayCount: '',
    hourCount: '',
    personCount: '',
    youngCount: '',
    note: '',
    ...equip,
  };
}

import React from 'react';

import { FormComponent, ButtonMode, Modal } from 'components';

import { AcceptSalaryProjectScientistAnswerType } from 'services/BackendAPI/configurations/salary/converters';

type Props = {
  isConfirmOpen: boolean;
  setIsConfirmOpen: React.Dispatch<React.SetStateAction<boolean>>;
  confirmContent: AcceptSalaryProjectScientistAnswerType[];
};

export function ConfirmModal({ isConfirmOpen, setIsConfirmOpen, confirmContent }: Props) {
  return (
    <Modal
      title="Ошибки в записях"
      isOpen={isConfirmOpen}
      onClose={() => setIsConfirmOpen(false)}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Закрыть',
          onClick: () => setIsConfirmOpen(false),
        },
      ]}
      size="medium"
    >
      <>
        {confirmContent.map((i, index) => (
          <FormComponent.Field key={index}>
            <FormComponent.Text>
              {!!i.project ? (
                <>
                  Проект № <strong>{i.project}</strong>
                </>
              ) : !!i.fundCard ? (
                <>
                  Шифр ЦФ <strong>{i.fundCard}</strong>
                </>
              ) : (
                <>
                  Шифр ЗПЛ <strong>{i.code}</strong>
                </>
              )}
              : <strong>{i.text}</strong>
            </FormComponent.Text>
          </FormComponent.Field>
        ))}
      </>
    </Modal>
  );
}

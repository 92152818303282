export const token =
  // eslint-disable-next-line max-len
  'eyJhbGciOiJIUzUxMiJ9.eyJzdWIiOiJyb290IiwiYXV0aG9yaXRpZXMiOiJkcG8sZHBvLWFkbWluLHBjLHBvcnRhbC1hZG1pbixybmQsdW5kLWFkbWluLHVucCIsImV4cCI6NDc1NjYwMTk5MX0.Iv71HHKZev5hubThE6teGyEyolDmWHOolvkl3rvoln2tL2rXW4csDJKSoLtae2uSZFrzKBWfAvZsz24UK8ofsw';
export const adminMail = 'xyz@something.com';
export const KB = 1024;
export const MB = KB * 1024;
export const Gb = MB * 1024;
export const MAX_UPLOAD_SIZE_MB = 100;
export const DEFAULT_IMAGE_MAX_UPLOAD_SIZE_MB = 2;
export const DEFAULT_IMAGE_MAX_PERMISSION = [500, 500];
export const DEFAULT_IMAGE_ACCEPTS = ['png', 'jpg', 'jpeg'];

import { Form } from 'types/models';

const organizationKindMap: Record<string, string> = {
  PARTNER: 'Partner',
  ENTERPRISE: 'Enterprise',
  ENTERPRISE_CUSTOMER: 'EnterpriseCustomer',
};

export const convertAffiliationToRequest = (affiliation: Form.Affiliation) => {
  const organizationTypeKey = organizationKindMap[affiliation.organization.kind];

  return {
    Affiliation: {
      ...(affiliation.id ? { _attr: { id: affiliation.id } } : {}),
      ...(organizationTypeKey
        ? {
            [organizationTypeKey]: {
              _attr: { id: affiliation.organization.id },
            },
          }
        : {}),
    },
  };
};

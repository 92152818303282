import { ContestRequest } from 'types/models';
import {
  convertDocument,
  convertItem,
  convertMaybeArray,
  convertReference,
  getId,
  getText,
} from 'services/BackendAPI/configurations/commonConverters';
import { convertIndicator } from 'services/BackendAPI/configurations/program/converters';

import { convertProject } from 'services/BackendAPI/configurations/project/converters';
import { Settings } from 'types/models/common';

import { convertServerPerson } from '../author/converters';
import { convertPersonHistory } from '../converters';
import { convertContestRequestKindConsumption } from '../kindConsumption/converters';

export function convertContestRequest(competition: any, settings: Settings): ContestRequest.ContestRequest {
  return {
    id: competition?._attributes?.id || null,
    status: convertItem(competition.Status),
    project: competition.Project ? convertProject(competition.Project, settings) : undefined,
    requestStageUniversityEffect: getText(competition.RequestStageUniversityEffect),
    requestStageRegionalEffect: getText(competition.RequestStageRegionalEffect),
    requestStageNationalEffect: getText(competition.RequestStageNationalEffect),
    requestStageAvailableScientificGroundwork: getText(competition.RequestStageAvailableScientificGroundwork),
    requestStageAdditionalInformation: getText(competition.RequestStageAdditionalInformation),
    code: getText(competition.Code),
    requestStageNumber: getText(competition.RequestStageNumber),
    requestStageName: getText(competition.RequestStageName),
    requestStageStartDate: getText(competition.RequestStageStartDate),
    requestStageEndDate: getText(competition.RequestStageEndDate),
    requestStagePlanAmount: getText(competition.RequestStagePlanAmount),
    requestStageKindWork: competition.RequestStageKindWork ? convertReference(competition.RequestStageKindWork) : undefined,
    requestStageGoal: getText(competition.RequestStageGoal),
    requestStageAnnotation: getText(competition.RequestStageAnnotation),
    requestStagePreviousYearAnnotation: getText(competition.RequestStagePreviousYearAnnotation),
    requestStageWorkPlan: getText(competition.RequestStageWorkPlan),
    requestStageKeyWords: getText(competition.RequestStageKeyWords),
    requestStageExpectedResults: getText(competition.RequestStageExpectedResults),
    interdisciplinaryAproachJustification: getText(competition.InterdisciplinaryAproachJustification),
    requestStageExpectedResultsWorth: getText(competition.RequestStageExpectedResultsWorth),
    requestStageExpectedResultsSuppose: getText(competition.RequestStageExpectedResultsSuppose),
    requestStageExpectedResultsEducationalSuppose: getText(competition.RequestStageExpectedResultsEducationalSuppose),
    isRequestStageHasPatentResearch: getText(competition.IsRequestStageHasPatentResearch) === 'true',
    requestStageCollaborations: getText(competition.RequestStageCollaborations),
    requestStageRAN: getText(competition.RequestStageRAN),
    requestStagePartnerEnterprises: getText(competition.RequestStagePartnerEnterprises),
    type: competition.Type ? convertItem(competition.Type) : null,
    okveds:
      competition.Okveds && competition.Okveds.Okved ? convertMaybeArray(competition.Okveds.Okved || [], convertReference) : [],
    indicators:
      competition.Indicators && competition.Indicators.Indicator
        ? convertMaybeArray(competition.Indicators.Indicator || [], convertIndicator)
        : [],
    indices:
      competition.Indices && competition.Indices.Indice
        ? convertMaybeArray(competition.Indices.Indice || [], convertIndicator)
        : [],
    requestStagePnrs:
      competition.RequestStagePnrs && competition.RequestStagePnrs.RequestStagePnr
        ? convertMaybeArray(competition.RequestStagePnrs.RequestStagePnr || [], convertReference)
        : [],
    requestStagePnis:
      competition.RequestStagePnis && competition.RequestStagePnis.RequestStagePni
        ? convertMaybeArray(competition.RequestStagePnis.RequestStagePni || [], convertReference)
        : [],
    requestStagePnmitrs:
      competition.RequestStagePnmitrs && competition.RequestStagePnmitrs.RequestStagePnmitr
        ? convertMaybeArray(competition.RequestStagePnmitrs.RequestStagePnmitr || [], convertReference)
        : [],
    requestStageCriticalTechnologies:
      competition.RequestStageCriticalTechnologies && competition.RequestStageCriticalTechnologies.RequestStageCriticalTechnology
        ? convertMaybeArray(competition.RequestStageCriticalTechnologies.RequestStageCriticalTechnology || [], convertReference)
        : [],
    requestStageEstimatePositions:
      competition.RequestStageEstimatePositions && competition.RequestStageEstimatePositions.Position
        ? convertMaybeArray(competition.RequestStageEstimatePositions.Position || [], convertEstimatePosition)
        : [],
    requestStageNtrStrategies:
      competition.RequestStageNtrStrategies && competition.RequestStageNtrStrategies.RequestStageNtrStrategy
        ? convertMaybeArray(competition.RequestStageNtrStrategies.RequestStageNtrStrategy || [], convertReference)
        : [],
    requestStagePerformers: competition.RequestStagePerformers.RequestStagePerformer
      ? convertMaybeArray(
          (competition.RequestStagePerformers && competition.RequestStagePerformers.RequestStagePerformer) || [],
          p => convertPerformer(p, settings),
        )
      : [],
    requestStageOecds:
      competition.RequestStageOecds && competition.RequestStageOecds.RequestStageOecd
        ? convertMaybeArray(competition.RequestStageOecds.RequestStageOecd || [], convertReference)
        : [],
    requestStageIndicators:
      competition.RequestStageIndicators && competition.RequestStageIndicators.Result
        ? convertMaybeArray(competition.RequestStageIndicators.Result || [], convertResult)
        : [],
    requestStageIndices:
      competition.RequestStageIndices && competition.RequestStageIndices.Result
        ? convertMaybeArray(competition.RequestStageIndices.Result || [], convertResult)
        : [],
    approvements:
      competition.Approvements && competition.Approvements.Approvement
        ? convertMaybeArray(competition.Approvements.Approvement || [], convertApprovement)
        : [],
    documents: [
      ...(competition.Documents && competition.Documents.Document
        ? convertMaybeArray(competition.Documents.Document, i => convertDocument('local', i))
        : []),
      // ...(competition.ForeignDocuments && competition.ForeignDocuments.Document
      //   ? convertMaybeArray(competition.ForeignDocuments.Document, i => convertDocument('foreign', i))
      //   : []),
    ],
  };
}

export function convertEstimatePosition(position: any): ContestRequest.ContestRequestEstimatePosition {
  return {
    id: position._attributes?.id ?? null,
    amount: getText(position.Amount),
    note: getText(position.Note),
    kindConsumption: convertContestRequestKindConsumption(position.KindConsumption),
  };
}

export function convertResult(result: any): ContestRequest.Result {
  return {
    id: result?._attributes?.id || null,
    resultItem: convertReference(result.ResultItem),
    year: getText(result.Year),
    note: getText(result.Note),
    plan: parseFloat(getText(result.Plan)),
  };
}

export function convertStage(stage: any): ContestRequest.Stage {
  return {
    id: stage?._attributes?.id || null,
    number: parseInt(getText(stage.Number), 10),
    startDate: getText(stage.StartDate),
    endDate: getText(stage.EndDate),
    amountMain: getText(stage.AmountMain),
    amountCofinancing1: parseFloat(getText(stage.AmountCofinancing1)),
    amountCofinancing2: parseFloat(getText(stage.AmountCofinancing2)),
    amountLocal: parseFloat(getText(stage.AmountLocal)),
    amountAccomplice: parseFloat(getText(stage.AmountAccomplice)),
    isPatentResearchPlanned: getText(stage.IsPatentResearchPlanned) === 'true',
    reports: stage.Reports.Report ? convertMaybeArray(stage.Reports.Report || [], convertReport) : [],
  };
}

export function convertReport(report: any): ContestRequest.Report {
  return {
    id: report?._attributes?.id || null,
    type: report.Type ? convertItem<string>(report.Type) : undefined,
    status: report.Status ? convertItem<string>(report.Status) : undefined,
    udk: report.Udk ? convertReference(report.Udk) : undefined,
    name: report.Name ? getText(report.Name) : undefined,
    reportOntiDate: report.ReportOntiDate ? getText(report.ReportOntiDate) : undefined,
    registrationOntiDate: report.RegistrationOntiDate ? getText(report.RegistrationOntiDate) : undefined,
    ontiNumber: report.OntiNumber ? getText(report.OntiNumber) : undefined,
    registrationDate: report.RegistrationDate ? getText(report.RegistrationDate) : undefined,
    number: report.Number ? getText(report.Number) : undefined,
    referat: report.Referat ? getText(report.Referat) : undefined,
    keyWords: report.KeyWords ? getText(report.KeyWords) : undefined,
    indicators:
      report.Indicators && report.Indicators.Indicator
        ? convertMaybeArray(report.Indicators.Indicator || [], convertIndicator)
        : [],
    indices: report.Indices && report.Indices.Indice ? convertMaybeArray(report.Indices.Indice || [], convertIndicator) : [],
  };
}

export function convertPerformer(performer: any, settings: Settings): ContestRequest.Performer {
  return {
    id: performer?._attributes?.id || null,
    person: performer.Person ? convertServerPerson(performer.Person, settings) : null,
    role: convertItem(performer.Role),
    ...convertPersonHistory(performer),
  };
}

export function convertApprovement(approvement: any): ContestRequest.Approvement {
  return {
    id: getId(approvement),
    resolution: convertItem(approvement.Resolution),
  };
}

import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';
import { ProjectAboutGridHelp } from 'features/Table/specifications/GetProjectList/Toolbar/help';

export function ProjectAnalyticGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>Список проектов всех типов</p>

        <p>
          Таблица содержит консолидированный список проектов всех типов и предназначена для анализа данных, проверки на полноту
          заполнения, поиска по тематике, управленческих отчётов по запросу и т.д.
        </p>

        <p>
          Фильтр <strong>Год</strong> – отображает список из проектов, у которых сроки выполнения пересекаются с указанным годом.
        </p>

        <p>
          Фильтр <strong>Проекты с нераспределённой суммой</strong> — отображает список из проектов, у которых нет{' '}
          <strong>Шифра проекта (финансового лицевого счёта в системе бухгалтерского учёта) или в финансовом</strong> плане Шифра
          не указана сумма на год; служит для информации о степени синхронизации с блоком Финансового сопровождения.
        </p>

        <p>
          Основные столбцы таблицы содержат данные проекта, названия столбцов соответствуют названиям полей на форме проекта.
          Дополнительные столбцы таблицы содержат данные связанных с проектом заявок, шифров, объектов интеллектуальной
          собственности, документов бухгалтерского учёта.
        </p>

        <h4>Панель инструментов для настройки таблицы</h4>

        <p>
          Основные столбцы таблицы содержат данные проекта, названия столбцов соответствуют названиям полей на форме проекта.
          Дополнительные столбцы таблицы содержат данные связанных с проектом заявок, шифров, объектов интеллектуальной
          собственности, документов бухгалтерского учёта.
        </p>

        {ProjectAboutGridHelp()}
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>

        <div className="warning">
          <span>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом. Открывает форму с данными о проекте для просмотра.
                Состав формы зависит от наличия у пользователя <strong>прав на просмотр вкладок</strong> формы.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>
                <ol>
                  <li>
                    Кнопка активна если у пользователя есть <strong>право добавления проекта</strong> этого типа. Варианты для
                    добавления нового проекта: Создать проект без заявки — открывает пустую форму для заполнения;
                  </li>
                  <li>Создать на основе заявки на конкурс — открывает форму, с заполненными данными из заявки;</li>
                  <li>Создать на основе заявки на внутренний конкурс — открывает форму, с заполненными данными из заявки;</li>
                  <li>
                    Создать на основе Договора о распоряжении интеллектуальным правом — открывает форму с заполненными данными из
                    договора.
                  </li>
                </ol>

                <p>
                  Варианты 2-4 используются если есть соотвествующие записи в базе данных системы. Это помогает избежать двойного
                  ввода одинаковой информации и сохранить приемственность смысловых связей между объектами.
                </p>
              </td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись в проектом. Открывает форму с данными о проекте для редактирования.
                Состав формы зависит от наличия у пользователя <strong>прав на просмотр и редактирования вкладок</strong> формы.
                <br />
                Если запись открыта для редактирования, то система не позволит открыть эту запись для другого пользователя и
                выведет на экран сообщение: «Сущность заблокирована».
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом.
                <br />
                Проект, у которого есть связи с другими объектами (Шифром проекта, заявкой, договором, РИД и пр.) система не
                позволит удалить.
              </td>
            </tr>
            <tr>
              <td>
                Добавить значение классификатора для выделенной группы проектов: <Icon type="documents" mode="edit" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом. Это групповая функция: можно выделить группу проектов с
                помощью мышки при нажатой клавиши Shift или Ctrl.
                <br />
                При нажатии кнопки открывается список классификаторов. После выбора классификатора откроется дополнительная форма
                для процедуры добавления элементов (одного или нескольких) из классификатора для всх проектов из выделенной
                группы.
                <div className="warning">
                  <span>
                    Настройте таблицу так, чтобы на экране вы видели столбцы с классификаторами. После выполнения фукции вы сразу
                    увидите результат.
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Добавить сообщение: <Icon type="message" mode="add" />
              </td>
              <td>
                Это инструмент предназначен для коммуникации между пользователями, работающими с проектами.
                <br />
                Например, рекомендовано добавлять сообщение с темой «Сведения актуализированы».
                <div className="warning">
                  <span>
                    у таблицы в <strong>режиме для анализа</strong> есть два служебных столбца:
                    <ul>
                      <li>
                        Сообщение – выводиться последнее сообщение из добавленных пользователями (кроме тех, у которых тема =
                        «Сведения актуализированы»)
                      </li>
                      <li>
                        Сведения актуализированы — выводится информация в формате: &lt;Текст сообщения&gt; &lt;Дата&gt; &lt;ФИО
                        пользователя&gt;.
                      </li>
                    </ul>
                  </span>
                </div>
                <p>Все добавленные сообщения хранятся в записи о проекте на вкладке «Журнал сообщений».</p>
              </td>
            </tr>
            <tr>
              <td>
                История изменений: <Icon type="clock" />
              </td>
              <td>
                Кнопка активна, если в таблице выделена запись с проектом; открывает окно{' '}
                <strong>Журнала изменений в проекте, в котором система автоматически</strong> фиксирует изменения в ключевых
                данных проекта: объём финансирования в календарном плане, сроки, источник финансирования и подразделения, на базе
                которого выполняется проект, а также кто и когда это сделал. Журнал носит информативный характер и выполняет
                функцию коммуникаций между сотрудниками, сопровождающими проект и финансовой группой.
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                Инструмент предназначен для построения отчёта по шаблону. При нажатии кнопки появится список доступных
                пользователю отчётов (при условии, что у пользователя есть право на группу отчётов).
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
    </Tabs>
  );
}

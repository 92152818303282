import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { SecurityDocumentPaymentRequestSaveInput } from './types';
import { convertPaymentToRequest } from '../payments/requestConverters';
import { PaymentRequestType } from 'utils/Enums';

const configurations = {
  SavePatentPaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveSecurityDocumentPaymentRequest',
    endpoint: '/msa/service/commands/SavePatentPaymentRequest',
    convertInputToXMLElement: (inputData: SecurityDocumentPaymentRequestSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SavePatentPaymentRequest',
        },
        PaymentRequest: {
          ...convertPaymentToRequest({
            authorPayment: inputData,
            comment: inputData.saveComment || '',
            eventType: PaymentRequestType.SECURITY_DOCUMENT,
          }),
          Patent: { _attr: { id: inputData.patent?.id } },
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

export const securityDocumentPaymentRequestConfigurations = { ...configurations };

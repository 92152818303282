import { User } from 'types/models/User';

export function convertUserToXML(u: User) {
  return {
    _attr: u.id ? { id: u.id } : {},
    UserFullName: u.userFullName,
    ...(u.email ? { EMail: u.email } : {}),
    ...(u.department ? { Department: { _attr: { id: u.department.id } } } : {}),
    ...(u.person ? { Person: { _attr: { id: u.person.id } } } : {}),
    ...(u.birthDate ? { BirthDate: u.birthDate } : {}),
  };
}

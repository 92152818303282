import React, { useState } from 'react';

import { Button, ButtonMode, FormComponent, Radio, Select } from 'components';

import { Table as T } from 'types/models';
import { Item } from 'types/models/common';
import compilationConferenceTypes from '../compilationConferenceTypes';
import { DataGrid } from 'features/Table';
import { GetCompilationArticleList } from 'features/Table/specifications/GetCompilationArticleList';
import { FetchCompilationProcess } from 'services/BackendAPI/configurations/publication/types';

type Props = {
  onClose: () => void;
  onSelect: FetchCompilationProcess;
  relatedTableState: T.State;
  action: string;
  id: string;
};

const ChangeArticleTypes = (props: Props) => {
  const radioItems = [
    {
      value: 'ALL',
      label: 'Для всех статей',
    },
    {
      value: 'SELECTED',
      label: 'Для выбранных статей',
    },
  ];

  const { onClose, relatedTableState, onSelect, action, id } = props;
  const [entryPages, setEntryPages] = useState<string[]>();
  const [amount, setAmount] = useState<string>(radioItems[0].value);

  const [compilationConferenceType, setCompilationConferenceType] = useState(compilationConferenceTypes[0]);

  const onChangeCompilationConferenceType = (value: string) => {
    const selected = compilationConferenceTypes.find(x => x.id === value);
    if (selected) {
      setCompilationConferenceType(selected);
    }
  };

  const onParticipantChange = (val: string) => {
    setAmount(val);
  };

  const continueWithSelectedType = () => {
    onSelect(id || '', action, {
      newArticleType: compilationConferenceType.code,
      articleIds: amount === 'SELECTED' && entryPages?.length ? entryPages!.join(',') : undefined,
    });
    onClose();
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Изменить тип статей на">
          <Select
            value={{ value: compilationConferenceType?.id || '', label: compilationConferenceType?.label || '' }}
            options={compilationConferenceTypes.map(x => ({
              value: x.id,
              label: x.label,
            }))}
            onChange={(option: Item) => onChangeCompilationConferenceType(option.value)}
          />
        </FormComponent.Field>
        <FormComponent.Field>
          <Radio value={amount} list={radioItems} onChange={onParticipantChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field>
          <DataGrid
            specification={GetCompilationArticleList({
              templatesTableDependencies: {
                relatedTableAPIID: 'GetCompilationArticleList',
                relatedTableState,
                relatedRecordId: id || undefined,
              },
              workMode: 'viewMode',
              setEntryPages,
            })}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text={amount === 'SELECTED' && !entryPages?.length ? 'Выберите статью' : 'Выполнить операцию по изменению типа статей'}
          onClick={continueWithSelectedType}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export default React.memo(ChangeArticleTypes);

import React, { useState, useCallback, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const tableStreams = useLocalTableStreams();

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      setIsViewFormOpen(true);
    }
  }, [tableState]);

  const handleEditButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      if (tableState.selectedRows[0]?.Answer) {
        showNotification({
          message: 'Редактирование отвеченных вопросов невозможно, используйте форму просмотра',
          theme: 'danger',
        });
      } else {
        setIsEditFormOpen(true);
      }
    } else {
      showNotification({ message: 'Выберите вопрос', theme: 'danger' });
    }
  }, [tableState]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);
  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteQnaQuestion',
      deletedItemPropName: 'QnaQuestion',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
      },
      {
        icon: { type: 'add' },
        title: 'Задать вопрос администратору',
        onClick: handleAddButtonClick,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
      },
    ],
    [handleAddButtonClick, handleViewButtonClick, handleEditButtonClick, handleDeleteButtonClick],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.QnaAddTemplate.Component isOpen={isAddFormOpen} onClose={handleTemplateCloseAddForm} />

      <modalTemplates.QnaViewTemplate.Component
        isOpen={isViewFormOpen}
        onClose={handleTemplateCloseViewForm}
        id={tableState.selectedRows[0]?.id}
      />

      <modalTemplates.QnaEditTemplate.Component
        isOpen={isEditFormOpen}
        onClose={handleTemplateCloseEditForm}
        id={tableState.selectedRows[0]?.id}
      />

      {tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>Удалить вопрос?</>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

export type State = {
  isRequests?: boolean;
};

export function makeUseCustomController({ isRequests }: State) {
  return function useCustomController(): State {
    return {
      isRequests,
    };
  };
}

import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, TextArea, TextAreaMode, TextInput } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { GetKindConsumptionList } from 'features/Table/specifications';
import { Table } from 'types/models';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function MobileKindConsumptionForm({ viewMode, onClose }: Props) {
  const { kindConsumption, setKindConsumption, handleFormSubmit } = useController({ onClose });

  const { methods: getKindConsumption } = BackendAPI.useBackendAPI('GetKindConsumption');

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Наименование" isRequired>
            <TextInput
              value={kindConsumption.name}
              onChange={e => setKindConsumption(prev => ({ ...prev, name: e }))}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Код" isRequired>
            <TextInput
              value={kindConsumption.code}
              onChange={e => setKindConsumption(prev => ({ ...prev, code: e }))}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Описание">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={kindConsumption.description}
              onChange={e => setKindConsumption(prev => ({ ...prev, description: e }))}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вид расхода из бухгалтерии" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              value={[kindConsumption.kindConsumption?.code, kindConsumption.kindConsumption?.name].filter(Boolean).join(' ')}
              settings={{
                title: 'Виды расходов из бухгалтерии',
                table: {
                  specification: GetKindConsumptionList({ hasSelectButton: true }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setKindConsumption(prev => ({ ...prev, kindConsumption: null }));
                      return;
                    }
                    getKindConsumption.callAPI(
                      { id: row.id },
                      { onSuccessfullCall: ({ data }) => setKindConsumption(prev => ({ ...prev, kindConsumption: data })) },
                    );
                  },
                },
                rows: 3,
              }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(MobileKindConsumptionForm);

import React, { memo } from 'react';

import { SectionTitle, FormComponent } from 'components';

import { Event, Table as T } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { FinOrganizations } from './FinOrganizations';
import { Projects } from 'features/Form/looks/event/views/Projects';
import { EventProject } from 'types/models/Event';
import { ValueOf } from 'types/helpers';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  eventData?: Event.Event | null;
  projects: EventProject[];
  setProjects: (docs: EventProject[]) => void;
  workMode: T.WorkMode;
  relatedTableState: T.State | undefined;
  setEventDataField: (field: keyof Event.Event) => (value: ValueOf<Event.Event>) => void;
  departments: Event.Department[];
  departmentOptions: Event.Department[];
  handleAttractedDepartmentsChange: (value: Event.Department[]) => void;
  standalone?: boolean;
};

const FinancialSupport = ({
  eventData,
  projects,
  setProjects,
  workMode,
  relatedTableState,
  setEventDataField,
  departments,
  departmentOptions,
  handleAttractedDepartmentsChange,
  standalone,
}: Props) => {
  return (
    <>
      {standalone ? (
        <h2>{getEventFullName(eventData)}</h2>
      ) : (
        <FormComponent.Description mode="warning">
          Раздел необходимо заполнить в случае, если Университет является организатором (соорганизатором) мероприятия
        </FormComponent.Description>
      )}

      {/* {incentivePaymentRequest && (
        <FormComponent.Description mode="info">
          <>
            <SectionTitle title="Стим. выплаты:" />
            <span>{incentivePaymentRequest}</span>
          </>
        </FormComponent.Description>
      )} */}
      <SectionTitle
        title="Финансирующие организации (планирование)"
        // eslint-disable-next-line max-len
        tooltip="Заполняется  на стадии планирования конференции университета. Добавьте информацию об источниках финансирования  проведения конференции: орг. взносы участников, либо собственные средства подразделения. Если проведение мероприятия поддержано научным проектом, то данные о программах, грантах выбираются из справочников системы."
      />
      <FinOrganizations
        eventData={eventData}
        setEventDataField={setEventDataField}
        disabled={workMode === 'viewMode'}
        departments={departments}
        departmentOptions={departmentOptions}
        handleAttractedDepartmentsChange={handleAttractedDepartmentsChange}
      />
      <SectionTitle
        title="Организовано при финансовой поддержке"
        // eslint-disable-next-line max-len
        tooltip="Если проведение мероприятия поддержано научным проектом, то выбрать из справочника системы проект (грант на организацию проведения мероприятия), связанный с данной конференцией. Информацию о проектах можно добавить после проведения конференции"
      />
      <Projects
        eventType={EventTypes.EVENT_CONFERENCE.code}
        workMode={workMode}
        projects={projects}
        setProjects={setProjects}
        relatedTableState={relatedTableState}
      />
    </>
  );
};

export const Component = memo(FinancialSupport);

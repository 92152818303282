import { Arm } from 'types/models';

export type State = {
  arm?: Arm;
};

export function makeUseCustomController({ arm }: State) {
  return function useCustomController(): State {
    return {
      arm,
    };
  };
}

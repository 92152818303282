import React from 'react';

import { Modal, Toolbar } from 'components';
import { Table } from 'types/models';

// eslint-disable-next-line max-len
import { Component as ParticipationViewTemplate } from 'features/Table/specifications/GetConferenceParticipationList/LeftPanelForThirdLevel/modalTemplates/ConferenceParticipationViewTemplate';
import { useController } from './controller';
import { ParticipationForPublicationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen, isViewFormOpen, setIsViewFormOpen, selectedRow } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!isViewFormOpen && (
        <ParticipationViewTemplate
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      <Modal mode="help" title="Помощь" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="medium">
        {ParticipationForPublicationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

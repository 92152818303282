import React from 'react';

import { Person } from 'types/models';
import { ListEdit } from 'components';
import { useMainScientistsContentContext } from 'App/ScientistsPage/context';
import { showNotification } from 'features/Notifications';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { checkIsIdentifierLinkCorrect, validate } from './validate';
import { Fields } from './Fields';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
  onChangeIdentifiers(honors: Person.Identifier[]): void;
};

export function Identifiers(props: Props) {
  const { person, disabled, onChangeIdentifiers } = props;
  const { isMainScientistsContent } = useMainScientistsContentContext();
  const { userPermission } = useAppDataContext();

  return (
    <ListEdit
      maxHeight="none"
      withMessages
      isDeleteConfirmEnabled
      rows={person?.identifiers ?? []}
      onChange={onChangeIdentifiers}
      toolbar={
        !disabled &&
        isMainScientistsContent &&
        isHasPermission(userPermission, Permits.PERSON_IDENTIFIER_AUTHOR_EDIT) && ['add', 'edit', 'delete']
      }
      columns={[
        { label: 'Система цитирования', formatValue: row => row?.citationSystem?.label ?? '' },
        { label: 'Идентификатор', formatValue: row => row?.identifier ?? '' },
        { label: 'Имя автора', formatValue: row => row?.authorName ?? '' },
        { label: 'Ссылка на профиль автора в системе цитирования', formatValue: row => row?.link ?? '' },
      ]}
      specification={{
        mode: 'customComponent',
        renderComponent: (identifier, setIdentifier) => <Fields identifier={identifier} onChange={setIdentifier} />,
        onPreSubmit: checkIsIdentifierLinkCorrect,
        validation: {
          checkIsValid: identifier => validate(identifier).every(x => x.isValid),
          onInvalidate: identifier => {
            const info = validate(identifier);
            info.forEach(({ isValid, invalidMessage }) => {
              if (!isValid) {
                setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
              }
            });
          },
        },
      }}
    />
  );
}

import React from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { Table } from 'types/models';
import { Requests } from 'features/Form/views/Requests';
import { Projects } from 'features/Form/views/Projects';
import useController from './controller';

type Props = {
  relatedTableState: Table.State | undefined;
  publicationId: string;
  workMode: Table.WorkMode;
  onClose: () => void;
  onSuccess: () => void;
};

function FinancicalSupportPopupComponent({ relatedTableState, workMode, publicationId, onClose, onSuccess }: Props) {
  const { projects, mobileRequests, publication, submitForm, setFinancingProjectsCb, setMobileRequestsCb } = useController({
    publicationId,
    onClose,
    onSuccess,
  });

  return (
    <FormComponent.Wrapper>
      {!!publication && (
        <FormComponent.Wrapper>
          <h4 className="primary">{publication.status.typeLabel}</h4>
          <FormComponent.Line>
            <span>
              <strong> {publication.fields.bibliographicRecord}</strong>
            </span>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      )}
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <Projects
            workMode={workMode}
            relatedTableState={relatedTableState}
            projects={projects}
            setProjects={setFinancingProjectsCb}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <Requests
            workMode={workMode}
            relatedTableState={relatedTableState}
            requests={mobileRequests}
            setMobileRequests={setMobileRequestsCb}
          />
        </FormComponent.Line>

        <FormComponent.Actions>
          <Button mode={ButtonMode.PRIMARY} text="Сохранить" onClick={submitForm} />
          <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(FinancicalSupportPopupComponent);

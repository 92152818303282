import { useCallback, useMemo } from 'react';

import { ExtraToolbarButton } from 'components/ListEdit/model';
import { RefElement } from 'types/models/Project';

type Props = {
  disabled?: boolean;
  onChange(refs: RefElement[]): void;
};

export function useController(props: Props) {
  const { disabled, onChange } = props;

  const setActual = useCallback(
    (row: RefElement | null, isActual: boolean, index: number | null, list: RefElement[]) => {
      const preparedList = list.map(item => {
        if (index !== null && item.element?.id === list[index!].element?.id) {
          return { ...item, isActual };
        }
        return item;
      });
      onChange(preparedList);
    },
    [onChange],
  );

  const handleClassifierChange = (refs: RefElement[]) => {
    const newRefs = refs.map((ref: RefElement) => ({
      ...ref,
      isProject: ref.id ? ref.isProject : false,
    }));
    onChange(newRefs);
  };

  const extraToolbarButtons = useMemo<ExtraToolbarButton<RefElement>[]>(
    () => [
      {
        icon: { type: 'like' },
        title: 'Отметить как актуальный для охранного документа',
        onClick: (row: RefElement | null, index, list) => {
          setActual(row, true, index, list);
        },
        isDisabled: row => !row || !!disabled,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отметить как неактуальный для охранного документа',
        onClick: (row: RefElement | null, index, list) => {
          setActual(row, false, index, list);
        },
        isDisabled: row => !row || !!disabled,
      },
    ],
    [disabled, setActual],
  );

  return {
    extraToolbarButtons,
    handleClassifierChange,
  };
}

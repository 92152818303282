import { SecurityDocumentContract } from 'types/models';

import { Settings } from 'types/models/common';
import {
  convertMaybeArray,
  convertReference,
  getId,
  getText,
  convertDocument,
  getBoolean,
  convertFile,
} from '../commonConverters';
import { convertSecurityDocument } from '../securityDocument/converters';

export function convertSecurityDocumentContract(
  item: any,
  settings: Settings,
): SecurityDocumentContract.SecurityDocumentContract {
  const payments = item.Payments?.Payment || [];

  const serverDocuments = item.Documents?.Document || [];
  const serverForeignDocuments = item.ForeignDocuments?.Document || [];

  const localDocuments = convertMaybeArray(serverDocuments, i => convertDocument('local', i));
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));

  const documents = [...localDocuments, ...foreignDocuments];

  const seсurityDocuments = item.SecurityDocuments?.SecurityDocument || [];

  return {
    id: getId(item),
    endDate: getText(item.EndDate),
    conclusionDate: getText(item.ConclusionDate),
    enterpriseCustomer: convertReference(item.EnterpriseCustomer),
    isTransfered: getBoolean(item.IsTransfered),
    kind: convertReference(item.Kind),
    number: getText(item.Number),
    paymentCondition: getText(item.PaymentCondition),
    paymentKind: convertReference(item.PaymentKind),
    payments: convertMaybeArray(payments, convertPayment),
    price: getText(item.Price),
    securityDocuments: convertMaybeArray(seсurityDocuments, doc => convertSecurityDocument(doc, settings)),
    startDate: getText(item.StartDate),
    documents,
    registrationDate: getText(item.RegistrationDate),
    registrationNumber: getText(item.RegistrationNumber),
    note: getText(item.Note),
  };
}

function convertPayment(item: any): SecurityDocumentContract.Payment {
  return {
    id: getId(item),
    receivingDocument: item.ReceivingDocument ? convertReceivingDocument(item.ReceivingDocument) : null,
    file: convertFile(item.File),
  };
}

export function convertReceivingDocument(item: any): SecurityDocumentContract.ReceivingDocument {
  return {
    id: getId(item),
    amount: getText(item.Amount),
    nds: getText(item.Nds),
    cashDocument: item.CashDocument ? convertCashDocument(item.CashDocument) : null,
  };
}

function convertCashDocument(item: any): SecurityDocumentContract.CashDocument {
  return {
    id: getId(item),
    isExpense: getBoolean(item.IsExpense),
    number: getText(item.Number),
    payDate: getText(item.PayDate),
    enterprise: item.Enterprise ? convertReference(item.Enterprise) : null,
  };
}

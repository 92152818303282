import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const reports = useMemo<Report[]>(() => [Reports.SecurityDocumentAuthors], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const row = useMemo(() => {
    return tableState?.selectedRows ? tableState.selectedRows[0] : undefined;
  }, [tableState]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: row !== undefined && row.Type !== 'SECURITY_DOCUMENT',
      },
    ],
    [row, getReports, handleSetCurrentReport],
  );

  return {
    buttons,
    currentReport,
    isReportOpen,
    onReportClose,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}

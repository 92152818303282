import React from 'react';

import { Checkbox, FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Project } from 'types/models';
import { useController } from './controller';
import { ProjectFinancingType } from 'utils/Enums';

type Props = {
  financing: Project.Financing;
  setFinancing(financing: Project.Financing): void;
  mode: 'view' | 'edit' | 'add' | null;
};

export function Fields(props: Props) {
  const { financing, setFinancing, mode } = props;

  const { makeChangeHandler } = useController({ financing, setFinancing });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Вид финансирования" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={financing.type}
            settings={{
              name: 'ProjectFinancingType',
              excludes: [financing.type?.value !== ProjectFinancingType.MAIN ? ProjectFinancingType.MAIN : ''],
            }}
            onChange={makeChangeHandler('type') as any}
            isDisabled={financing.type?.value === ProjectFinancingType.MAIN && mode === 'edit'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Источник финансирования" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={financing.source}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('source')(option)}
            settings={{ name: 'RefInvestmentSource', title: 'Справочник "Источники финансирования"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="В т.ч НДС">
          <Checkbox checked={financing.isNds} onChange={makeChangeHandler('isNds')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

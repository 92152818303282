import * as XML from 'xml-js';

import { Form, Event } from 'types/models';
import { EventParticipation } from 'types/models/Event';
import { convertItem, getId, getLabel, getText } from '../commonConverters';
import { makeBackendAPIConfiguration } from '../../makeBackendAPIConfiguration';

type GetReleaseInput = {
  releaseId: string;
};

type ReleaseResponseData = {
  success: boolean;
  message: string;
  body?: {
    releaseId: string;
    Year: string;
    Part: string;
    Month: string;
    Number: string;
    PublicationDate: string;
    articleCount: number;
    conferences: Form.Conference[];
    participations: Event.EventParticipation[];
  };
};

type SaveReleaseInput = {
  magazineId: string;
  releaseId?: string;
  Year: string;
  PublicationDate?: string;
  Part?: string;
  Month?: string;
  Number?: string;
  Events: Form.Conference[];
};

type SaveReleaseResponseData = {
  success: boolean;
  message: string;
};

const convertSaveReleaseResponseXMLElementToOutput = (response: XML.ElementCompact): SaveReleaseResponseData => {
  const output: SaveReleaseResponseData = {
    success: response.Response._attributes.success === 'true',
    message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
  };

  return output;
};
function convertParticipations(publication: any): Event.EventParticipation[] {
  const participationsXMLArray = publication.Participations?.Participation;
  const convertParticipation = (participation: any): EventParticipation => {
    return {
      id: getId(participation),
      participationStatus: { label: participation.Status?._attributes?.label || '', value: getText(participation.Status) },
      eventFullName: getText(participation.EventFullName),
      reportType: participation.ReportType?._attributes?.label || '',
      reportTheme: getText(participation.ReportTheme),
      reporter: getText(participation.Reporter),
      reportDate: getText(participation.reportDate),
    };
  };

  return participationsXMLArray
    ? Array.isArray(participationsXMLArray)
      ? participationsXMLArray.map(convertParticipation)
      : [convertParticipation(participationsXMLArray)]
    : [];
}

const convertEvents = (publication: any) => {
  if (!publication.Events || !publication.Events.Event) {
    return [];
  }

  const converter = (event: any): Form.Conference => {
    const startDate = getText(event?.StartDate);
    const endDate = getText(event?.EndDate);
    const countryLabel = getLabel(event?.Country);
    const city = getText(event?.City);

    const eventDateString = startDate && endDate ? ` ${startDate} - ${endDate}` : ` ${startDate}`;
    const eventCountryString = `${countryLabel ? ` ${countryLabel}${city ? '' : '.'}` : ''}`;
    const eventCityString = `${city ? `, ${city}.` : ''}`;

    const fullName = `${event.name}.${eventCountryString}${eventCityString}${eventDateString}`;
    return {
      id: event._attributes.id,
      name: event.Name?._text || '',
      status: event.Status ? convertItem(event.Status) : null,
      fullName,
    };
  };

  const system = publication.Events.Event;
  return Array.isArray(system) ? system.map(converter) : [converter(system)];
};

const converGetReleaseResponseXMLElementToOutput = (response: XML.ElementCompact): ReleaseResponseData => {
  const output: ReleaseResponseData = {
    success: response.Response._attributes.success === 'true',
    message: response.Response._attributes.errorMessage || response.Response._attributes.serverErrorMessage || '',
  };

  if (response.Response.MagazineRelease?.Magazine) {
    const { MagazineRelease } = response.Response;

    output.body = {
      releaseId: MagazineRelease._attributes.id || '',
      Year: MagazineRelease.Year._text || '',
      Part: MagazineRelease.Part?._text || '',
      Month: MagazineRelease.Month?._text || '',
      Number: MagazineRelease.Number?._text || '',
      PublicationDate: MagazineRelease.PublicationDate?._text || '',
      conferences: convertEvents(MagazineRelease),
      articleCount: parseInt(MagazineRelease.ArticleCount?._text || 0, 10),
      participations: convertParticipations(MagazineRelease),
    };
  }
  return output;
};

const convertSaveMagazineReleaseXMLElement = <Input extends SaveReleaseInput>(input: Input) => {
  const reqObject = {
    MagazineRelease: {
      _attr: {},
      Magazine: { _attr: { id: input.magazineId } },
      ...(input.Year ? { Year: input.Year } : {}),
      ...(input.Month ? { Month: input.Month } : {}),
      ...(input.Part ? { Part: input.Part } : {}),
      ...(input.Number ? { Number: input.Number } : {}),
      ...(input.PublicationDate ? { PublicationDate: input.PublicationDate } : {}),
      Events: {
        Event: input.Events.map(({ id }) => ({
          _attr: { id },
        })),
      },
    },
  };

  if (input.releaseId) {
    reqObject.MagazineRelease._attr = { id: input.releaseId };
  }
  return reqObject;
};

const convertGetMagazineReleaseXMLElement = <Input extends GetReleaseInput>(input: Input) => {
  const reqObject = {
    MagazineRelease: { _attr: { id: input.releaseId } },
  };

  return reqObject;
};

function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => XML.ElementCompact,
): (input: Input) => XML.ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName },
      ...makeChildren(input),
    },
  });
}

export const magazineReleaseConfigurations = {
  GetMagazineRelease: makeBackendAPIConfiguration({
    id: 'GetMagazineRelease',
    endpoint: `/msa/service/commands/GetMagazineRelease`,
    convertInputToXMLElement: makeRequestXMLConverter('GetMagazineRelease', (input: GetReleaseInput) =>
      convertGetMagazineReleaseXMLElement(input),
    ),
    convertResponseXMLElementToOutput: converGetReleaseResponseXMLElementToOutput,
  }),
  SaveMagazineRelease: makeBackendAPIConfiguration({
    id: 'SaveMagazineRelease',
    endpoint: `/msa/service/commands/SaveMagazineRelease`,
    convertInputToXMLElement: makeRequestXMLConverter('SaveMagazineRelease', (input: SaveReleaseInput) =>
      convertSaveMagazineReleaseXMLElement(input),
    ),
    convertResponseXMLElementToOutput: convertSaveReleaseResponseXMLElementToOutput,
  }),
};

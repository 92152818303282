import { FormComponent } from 'components';
import { renderToString } from 'react-dom/server';

export function TenderProtocolGridHelp(): JSX.Element {
  type Custom = {
    value: string;
    status: string;
  };

  const renderFields = (args: Custom[] | Custom) => {
    const fields = Array.isArray(args) ? args : [args];

    return fields.map((item, index) => {
      return (
        <div key={index}>
          {!!index && <hr />}
          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: '80%' }}>
              <span dangerouslySetInnerHTML={{ __html: item.value }} />
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '20%' }}>
              <span dangerouslySetInnerHTML={{ __html: item.status }} />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </div>
      );
    });
  };

  return (
    <>
      <p>
        Функциональный блок предназначен для организации и проведения процедуры проведения конкурсной процедуры экспертизы и
        согласования и/или экспертизы заявок, оформляемых на участие в конкурсе.
      </p>
      <p>
        Члены конкурсной комиссии имеют право на редактирование и просмотр протоколов, в которых они указаны в разделе
        Присутствовали.
      </p>
      <p>
        В форме протокола, на вкладке "Список заявок", отображаются заявки, связанные с конкурсом в статусах: Заявка, На
        доработку, Отклонена, Рекомендована на конкурс - для Заявок на конкурс и Заявок на мобильность; в статусах: Подана на
        конкурс, На доработку, Отклонена, Согласована, Согласована с условием, Поддержана - для Заявок на внутренний конкурс.
        Форма заявки зависит от типа конкурса.
      </p>

      <div className="warning">
        <span>
          Для заявок на конкурс и заявок на мобильность в протоколе отображаются последний статус, который был присвоен заявке до
          даты протокола.
        </span>
      </div>

      <h4>
        Пользователь в роли <strong>Ответственный по заявкам</strong>:
      </h4>

      <ul>
        <li>
          создаёт проект протокола, в протоколе в разделе <strong>Присутствовали</strong> список членов из состава{' '}
          <strong>Конкурсной комиссии</strong>, участвующие в проверке заявок на конкурс;
        </li>
        <li>учитывая экспертную оценку изменяет статусы у заявок;</li>
        <li>оформляет протокол (номер и дата);</li>
        <li>распечатывает протокол, подписывает у членов комиссии;</li>
        <li>
          скан-копию протокола прикрепляет на вкладке <strong>Документы</strong>.
        </li>
      </ul>

      <h4>Порядок действий члена конкурсной комиссии:</h4>

      <ul>
        <li>Открыть форму протокола, перейти на вкладку Список заявок.</li>
        <li>Выбрать в списке Заявку, просмотреть разделы заявки.</li>
        <li>Оформить экспертную оценку в формате стандартизованного сообщения (виза и замечания).</li>
      </ul>

      <h3>Управление статусами заявок и краткое описание бизнес процесса</h3>

      <h4>Таблица 1. Статусы заявки на конкурс и краткое описание бизнес-процесса</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Роль</th>
            <th style={{ width: '80%' }}>Действие и статус</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: 'Добавить заявку на конкурс',
                  status: 'Черновик',
                },
                {
                  value: `Отправить заявку на проверку с помощью кнопки ${renderToString(<strong>На согласование</strong>)}`,
                  status: 'Заявка',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на конкурс</td>
            <td>
              {renderFields([
                {
                  value: 'Просмотр заявки; Добавить сообщение для заявителя',
                  status: 'Заявка',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(<strong>Отправить на доработку</strong>)}`,
                  status: 'На доработку',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Конкурская комиссия</td>
            <td>
              {renderFields({
                value: 'Просмотр заявки; Добавить визу',
                status: 'Заявка',
              })}
            </td>
          </tr>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: `Редактирование заявки в статусе ${renderToString(<strong>На Доработку</strong>)}`,
                  status: '',
                },
                {
                  value: `Отправить заявку на проверку в с помощью кнопки ${renderToString(<strong>На согласование</strong>)}`,
                  status: '',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Отклонить</strong>,
                  )}; написать сообщение — обоснование`,
                  status: 'Отклонена',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Рекомендовать на конкурс</strong>,
                  )}; написать сообщение для
                    заявителя`,
                  status: 'Рекомендована на конкурс',
                },
                {
                  // eslint-disable-next-line max-len
                  value: `После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс заполнить
                    поля:
                    ${renderToString(<strong>Подана на конкурс</strong>)}; и ${renderToString(<strong>Шифр заявки</strong>)}`,
                  status: 'Подана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки ${renderToString(
                    <strong>Поддержать</strong>,
                  )}`,
                  status: 'Поддержана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки ${renderToString(
                    <strong>Отклонить</strong>,
                  )}`,
                  status: 'Отклонена',
                },
              ])}
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Таблица 2. Статусы заявки на внутренние конкурс и краткое описание бизнес-процесса</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Роль</th>
            <th style={{ width: '80%' }}>Действие и статус</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: 'Добавить заявку на конкурс',
                  status: 'Черновик',
                },
                {
                  value: `Отправить заявку на проверку с помощью кнопки ${renderToString(<strong>На согласование</strong>)}`,
                  status: 'Заявка',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на внутренний конкурс</td>
            <td>
              {renderFields([
                {
                  value: 'Просмотр заявки; Добавить сообщение для заявителя',
                  status: 'Заявка',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(<strong>Отправить на доработку</strong>)}`,
                  status: 'На доработку',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Конкурская комиссия</td>
            <td>
              {renderFields({
                value: 'Просмотр заявки; Добавить визу',
                status: 'Заявка',
              })}
            </td>
          </tr>
          <tr>
            <td>Руководитель проекта или ответственный исполнитель проекта — пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: `Редактирование заявки в статусе ${renderToString(<strong>На Доработку</strong>)}`,
                  status: '',
                },
                {
                  value: `Отправить заявку на проверку в с помощью кнопки ${renderToString(<strong>На согласование</strong>)}`,
                  status: '',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на внутренний конкурс</td>
            <td>
              {renderFields([
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Отклонить</strong>,
                  )}; написать сообщение — обоснование`,
                  status: 'Отклонена',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Рекомендовать на конкурс</strong>,
                  )}; написать сообщение для
                    заявителя`,
                  status: 'Рекомендована на конкурс',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки на внутренний конкурс</td>
            <td>
              {renderFields([
                {
                  // eslint-disable-next-line max-len
                  value: `После регистрации заявки в ИАС Организатора конкурса. На форме редактирования Заявки на конкурс заполнить
                    поля: ${renderToString(<strong>«Подана на конкурс»</strong>)}; и ${renderToString(
                    <strong>«Шифр заявки»</strong>,
                  )}`,
                  status: 'Подана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки ${renderToString(
                    <strong>«Поддержать»</strong>,
                  )}`,
                  status: 'Поддержана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки ${renderToString(
                    <strong>«Отклонить»</strong>,
                  )}`,
                  status: 'Отклонена',
                },
              ])}
            </td>
          </tr>
        </tbody>
      </table>

      <h4>Таблица 3. Статусы заявки по академической мобильности и описание бизнес-процесса</h4>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>Роль</th>
            <th style={{ width: '80%' }}>Действие и Статус</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Заявитель —пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: 'Добавить заявку на конкурс',
                  status: 'Черновик',
                },
                {
                  value: `Отправить заявку на проверку с помощью кнопки ${renderToString(<strong>На согласование</strong>)}`,
                  status: 'Заявка',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки по академической мобильности</td>
            <td>
              {renderFields([
                {
                  value: 'Просмотр заявки; Добавить сообщение для заявителя',
                  status: 'Заявка',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(<strong>Отправить на доработку</strong>)}`,
                  status: 'На доработку',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Конкурская комиссия</td>
            <td>
              {renderFields({
                value: 'Просмотр заявки; Добавить визу',
                status: 'Заявка',
              })}
            </td>
          </tr>
          <tr>
            <td>Заявитель —пользователь личного кабинета</td>
            <td>
              {renderFields([
                {
                  value: `Редактирование заявки в статусе ${renderToString(<strong>На Доработку</strong>)};`,
                  status: '',
                },
                {
                  value: `Отправить заявку на проверку в с помощью кнопки ${renderToString(<strong>На конкурс</strong>)}`,
                  status: '',
                },
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Отклонить</strong>,
                  )}; написать сообщение — обоснование`,
                  status: 'Отклонена',
                },
              ])}
            </td>
          </tr>
          <tr>
            <td>Ответственный за заявки по академической мобильности</td>
            <td>
              {renderFields([
                {
                  value: `Выделить заявку в списке, Кнопка ${renderToString(
                    <strong>Рекомендовать на конкурс</strong>,
                  )}; написать сообщение для заявителя`,
                  status: 'Рекомендована на конкурс',
                },
                {
                  value: `По итогам проведения конкурса изменить статус выигравшей заявки с помощью кнопки ${renderToString(
                    <strong>Поддержать</strong>,
                  )}`,
                  status: 'Поддержана',
                },
                {
                  value: `По итогам проведения конкурса изменить статус отклонённой заявки с помощью кнопки ${renderToString(
                    <strong>Отклонить</strong>,
                  )}`,
                  status: 'Отклонена',
                },
              ])}
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
}

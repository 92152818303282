import React, { memo } from 'react';

import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps } from 'features/BuildReportPopup/models';

const EventLevelStatus = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => (
  <DefaultParameterList
    parameterName={parameterName}
    setParameterValueByName={setParameterValueByName}
    parameters={parameters}
    enumProps={{
      mode: {
        component: 'Reference',
        referenceName: 'RefEventStatus',
        referenceFilter: [],
        relationTableModalTitle: 'Уровень мероприятия',
      },
      listEditTitle: 'Список уровней мероприятий',
      fieldLabel: 'Уровень мероприятия',
    }}
  />
);
const Component = memo(EventLevelStatus);

export { Component as EventLevelStatus };

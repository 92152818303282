import React from 'react';

import {
  FormComponent,
  ListEdit,
  Radio,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Tender } from 'types/models';
import { showNotification } from 'features/Notifications';
import { NirTenderType, TenderRequestType } from 'utils/Enums';
import { Item } from 'types/models/common';
import { Mode } from 'components/ListEdit/model';

import { LotPriceInputs } from '../LotPriceInputs';
import { useController } from './controller';

type Props = {
  mode: Mode;
  lot: Tender.Lot | null;
  onLotChange: React.Dispatch<React.SetStateAction<Tender.Lot | null>>;
  viewMode: boolean;
  tenderName: string;
  tenderStartDate: string;
  lots: Tender.Lot[];
  requestType: TenderRequestType;
};

function LotInputs({ mode, lot, onLotChange, viewMode, tenderName, tenderStartDate, lots, requestType }: Props) {
  const {
    handleLotPricesChange,
    handleChange,
    handleAmountRadioChange,
    handleDateChange,
    yesNoRadios,
    requestSupportAmountDisabled,
    contractSupportAmountDisabled,
    warrantySupportAmountDisabled,
    handleAmountChange,
    nirTenderTypeOptions,
  } = useController({
    mode,
    lot,
    onLotChange,
    viewMode,
    tenderName,
    tenderStartDate,
    lots,
  });

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '50%' }}>
        <FormComponent.Line>
          <SectionTitle title="Описание лота" />
        </FormComponent.Line>

        {requestType === TenderRequestType.NIR_REQUEST && (
          <FormComponent.Line>
            <FormComponent.Field label="Тип конкурса" isRequired>
              <Select
                value={lot?.nirTenderType || null}
                options={nirTenderTypeOptions}
                onChange={(e: Item<NirTenderType> | null) => onLotChange(prev => ({ ...prev!, nirTenderType: e }))}
                isDisabled={mode === 'edit'}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}

        <FormComponent.Line>
          <FormComponent.Field label="Номер лота" isRequired>
            <TextInput
              value={lot?.number ? lot.number.toString() : undefined}
              onChange={handleChange('number')}
              settings={{ maxLength: 20 }}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Шифр лота">
            <TextInput value={lot?.code} onChange={handleChange('code')} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тема лота" isRequired>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Тема лота' }}
              value={lot?.theme || ''}
              onChange={handleChange('theme')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сроки выполнения работ">
            <span>
              <TextDateTime
                value={lot?.startDate || ''}
                onChange={(value: string) => handleDateChange('startDate', value)}
                maxValue={lot?.endDate || ''}
              />
            </span>
            <span>&nbsp;-&nbsp;</span>
            <span>
              <TextDateTime
                value={lot?.endDate || ''}
                onChange={(value: string) => handleDateChange('endDate', value)}
                minValue={lot?.startDate || ''}
              />
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <TextInput
              value={lot?.periodNote}
              onChange={handleChange('periodNote')}
              placeholder="Примечание о сроках выполнения работ"
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Предельная цена, руб.">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={lot?.limitAmount ? lot?.limitAmount.toString() : ''}
              onChange={handleAmountChange('limitAmount')}
            />
          </FormComponent.Field>
          <FormComponent.Field
            label="Источник финансирования"
            // eslint-disable-next-line max-len
            tooltip="Выберите из списка Источников финансирования в соответствии с категорией инициатора конкурса (Заказчика). Значение этого поля важно в процедуре создания проекта на основе заявки. Поле не обязательное для заполнения"
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={lot?.investmentSource}
              onChange={(option: ReferenceItem | null) => handleChange('investmentSource')(option)}
              settings={{ name: 'RefInvestmentSource', title: 'Источники финансирования', isClearable: true }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Примечание">
            <TextInput
              value={lot?.investmentSourceNote}
              onChange={handleChange('investmentSourceNote')}
              placeholder={'Например, требования к софинансированию'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Количество контрактов" isRequired>
            <TextInput
              mode={TextInputMode.NUMBER}
              settings={{ decimal: false }}
              value={lot?.contractCount ? lot?.contractCount.toString() : ''}
              onChange={handleAmountChange('contractCount')}
            />
          </FormComponent.Field>
          <FormComponent.Field label="Цена 1 контракта, руб.">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={lot?.contractAmount ? lot?.contractAmount.toString() : ''}
              onChange={handleChange('contractAmount')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            header={{ title: 'Календарный план' }}
            rows={lot?.prices ? lot?.prices.sort((x, y) => Number(x.year) - Number(y.year)) : []}
            onChange={handleLotPricesChange}
            toolbar={['add', 'edit', 'delete']}
            columns={[
              { label: 'Год', formatValue: row => (row?.year ? row.year : '') },
              { label: 'Сумма', formatValue: row => row?.amount || 0, dataKind: 'float' },
            ]}
            withMessages
            isDeleteConfirmEnabled
            isDisabled={!!viewMode}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onChange) => <LotPriceInputs lot={lot} lotPrice={row} onLotPriceChange={onChange as any} />,
              validation: {
                checkIsValid: row => {
                  return !!(
                    row?.amount !== undefined &&
                    row?.year &&
                    parseInt(row?.year, 10) > 1900 &&
                    parseInt(row?.year, 10) < 3000
                  );
                },
                onInvalidate: () => showNotification({ message: 'Заполните правильно поля', theme: 'danger' }),
              },
            }}
            columnIndexesForSumTotal={[1]}
          />
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: '50%' }}>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение заявки" />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Radio
              value={lot?.isSupportRequest === true || lot?.isSupportRequest === undefined ? 'true' : 'false'}
              list={yesNoRadios}
              onChange={handleAmountRadioChange('isSupportRequest')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={lot?.requestSupportAmount ? lot?.requestSupportAmount.toString() : ''}
              onChange={handleChange('requestSupportAmount')}
              isDisabled={requestSupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение контракта" />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Radio
              value={lot?.isSupportContract === true || lot?.isSupportContract === undefined ? 'true' : 'false'}
              list={yesNoRadios}
              onChange={handleAmountRadioChange('isSupportContract')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={lot?.contractSupportAmount ? lot?.contractSupportAmount.toString() : ''}
              onChange={handleChange('contractSupportAmount')}
              isDisabled={contractSupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <SectionTitle title="Обеспечение гарантийных обязательств" />
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Radio
              value={lot?.isSupportWarranty === true || lot?.isSupportWarranty === undefined ? 'true' : 'false'}
              list={yesNoRadios}
              onChange={handleAmountRadioChange('isSupportWarranty')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Сумма">
            <TextInput
              mode={TextInputMode.NUMBER}
              value={lot?.warrantySupportAmount ? lot?.warrantySupportAmount.toString() : ''}
              onChange={handleChange('warrantySupportAmount')}
              isDisabled={warrantySupportAmountDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дополнительная информация">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: 'Дополнительная информация' }}
              value={lot?.note || ''}
              onChange={handleChange('note')}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

export { LotInputs };

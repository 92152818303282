import React from 'react';
import { setup } from 'bem-cn';
import { v4 as uuidv4 } from 'uuid';

import { InputAttributes } from 'types/models/common';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('radio');

export type RadioItem = {
  /** @property {string} value - Value of radio item (as key) */
  value: string;
  /** @property {string} label - (optional) Visible label for radio item */
  label?: string;
  /** @property {boolean} isDisabled - (optional) Disabled only radio item */
  isDisabled?: boolean;
  /** @property {string} hint - (optional) Hint/Tooltip for radio item component */
  hint?: string;
};

export type Props = InputAttributes & {
  /** @property {RadioItem} list - List of radio items (See RadioItem type) */
  list: RadioItem[];
  /** @property {string} value - (optional) Default radio list[item].value */
  value?: string;
  /** @property {function} onChange - (optional) Callback on radio item changed */
  onChange?(value: string): void;
  /** @property {string} name - (optional) Input key */
  name?: string;
  /** @property {boolean} isInRow - (optional) Visible to row (true by default) */
  isInRow?: boolean;
  /** @property {boolean} isDisabled - (optional) Set is disabled raio inputs (all!) (false by default) */
  isDisabled?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
};

/**
 * Radio buttons list component
 *
 * @param {RadioItem} list - List of radio items
 *   @param {string} list.value - Value of raio input
 *   @param {string} list.label - (optional) Visible label of riadio item
 *   @param {boolean} list.isDisabled - (optional) Item is disabled
 *   @param {string} list.hint - (optional) Text title of radio item
 * @param {string} value - (optional) Default radio list[item].value
 * @param {function} onChange - (optional) Callback on radio item changed
 * @param {string} name - (optional) Input key
 * @param {boolean} isInRow - (optional) Visible to row (true by default)
 * @param {boolean} isDisabled - (optional) Set is disabled raio inputs (all!) (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const Radio = React.memo(
  ({ list, value, onChange, name, isInRow = true, isDisabled = false, classMixin, ...restProps }: Props) => {
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      if (onChange !== undefined) onChange(e.target.value);
    };

    const inputName = uuidv4() || name;

    return (
      <div className={b({ 'in-row': isInRow, disabled: isDisabled }).mix(classMixin)}>
        {list.map((item, index) => (
          <label key={index} className={b('item')} title={item.hint || ''}>
            <input
              type="radio"
              name={`radio -> ${inputName}`}
              value={item.value}
              checked={item.value === value}
              disabled={!!item.isDisabled}
              onChange={handleChange}
              {...restProps}
            />
            <span className={b('label')}>
              <div className={b('icon')}></div>
              {item.label && <span>{item.label}</span>}
            </span>
          </label>
        ))}
      </div>
    );
  },
);

import React from 'react';

import { Checkbox, TextInput, FormComponent } from 'components';

import { useAppDataContext } from 'features/AppData/context';

type Props = {
  isAffilated: boolean;
  amountAffilation: string;
  onChangeAmountAffilation(val: string): void;
  onChangeIsAffilation(checked: boolean): void;
};

function AffilationField(props: Props) {
  const { isAffilated, amountAffilation, onChangeAmountAffilation, onChangeIsAffilation } = props;
  const { settings } = useAppDataContext();
  return (
    <FormComponent.Line hasFreeFormat>
      <FormComponent.Field label={`Аффилирован с ${settings?.organization?.shortName}`}>
        <Checkbox checked={isAffilated} onChange={onChangeIsAffilation} />
      </FormComponent.Field>

      <FormComponent.Field label="Количество аффилиаций автора, всего" labelSize="fit" isRequired={isAffilated}>
        <TextInput value={amountAffilation} onChange={onChangeAmountAffilation} />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}

export { AffilationField };

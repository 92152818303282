import React from 'react';
import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';

import { DataGrid } from 'features/Table';
import { GetPublicationCitationSystemIndexList } from 'features/Table/specifications';
import { SectionTitle } from 'components';

type Props = {
  relatedTableState: T.State | undefined;
  citationSystems: CitationSystem[];
  publicationId: string | null;
  publicationType: string;
  workMode: T.WorkMode;
};

function Indicators(props: Props) {
  const { relatedTableState, citationSystems, publicationId, publicationType, workMode } = props;

  return (
    <>
      {relatedTableState && publicationType && (
        <>
          <SectionTitle title="Наукометрические показатели" />
          {relatedTableState && (
            <DataGrid
              specification={GetPublicationCitationSystemIndexList(
                {
                  relatedTableAPIID: 'GetMagazineList',
                  relatedTableState,
                  relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
                },
                {
                  citationSystems,
                  publicationId,
                  publicationType,
                  workMode,
                },
              )}
            />
          )}
        </>
      )}
    </>
  );
}

export { Indicators };

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

type Arguments = {
  projectNumber: string;
  disabled?: boolean;
} & RequestData;

type RequestData = {
  projectId: string | null;
};

export function GetProjectFeedlineList({
  projectId,
  projectNumber,
  disabled = false,
}: Arguments): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetProjectFeedlineList',
    isFiltersHidden: true,
    requestData: { projectId },
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({ projectId: projectId ?? '-1', projectNumber, disabled }),
  };
}

export type State = {
  eventId?: string;
  editable: boolean;
};

type Arguments = {
  eventId?: string;
  editable: boolean;
};

export function makeUseCustomController({ eventId, editable }: Arguments) {
  return function useCustomController(): State {
    return {
      eventId,
      editable,
    };
  };
}

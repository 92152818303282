import { Table } from 'types/models';
import { SubjectType } from 'types/models/Subject';
import { SubjectType as SubjectTypeEnum } from 'utils/Enums';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type RequestData = {
  subjectType: SubjectType;
};

export function GetSubjectList({ subjectType }: RequestData): Table.Specification<State, RequestData> {
  const isSubject: boolean = subjectType === SubjectTypeEnum.SUBJECT;
  return {
    apiID: 'GetSubjectList',
    header: {
      firstLevel: {
        title: isSubject ? 'Коллекции РИД по тематике' : 'Продукты для коммерциализации',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: { subjectType },
    useCustomController: makeUseCustomController({ subjectType, isSubject }),
  };
}

import { parse } from 'date-fns';

import { isFloatValid } from 'utils/Validators';
import { formatStr } from 'utils/Constants';

type Props = {
  kindId?: string;
  startDate?: string;
  endDate?: string;
  conclusionDate?: string;
  enterpriseCustomerId?: string;
  price?: string;
};
export function validate(props: Props) {
  const { kindId, startDate, endDate, conclusionDate, enterpriseCustomerId, price } = props;
  const validations = [];
  if (!(kindId && conclusionDate && enterpriseCustomerId)) {
    validations.push({
      isValid: false,
      invalidMessage: 'Заполните обязательные поля',
    });
  }
  if (price && !isFloatValid(price)) {
    validations.push({
      isValid: false,
      invalidMessage: 'Неверный формат',
    });
  }
  if (startDate) {
    if (endDate) {
      const fromTime = parse(startDate || '', formatStr, new Date()).getTime();
      const toTime = parse(endDate || '', formatStr, new Date()).getTime();
      if (fromTime > toTime) {
        validations.push({
          isValid: false,
          invalidMessage: 'Ошибка в датах проекта',
        });
      }
    }
  } else {
    validations.push({
      isValid: false,
      invalidMessage: 'Не указана дата начала проекта',
    });
  }
  return validations;
}

import { useCallback } from 'react';
import { RntdAuthor } from 'types/models';
import { Member } from 'features/SelectPerson';

type Props = {
  author: RntdAuthor | null;
  onAuthorChange(row: RntdAuthor): void;
};

export function useController({ author, onAuthorChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Member | null) => {
      onAuthorChange({
        id: author?.id ?? null,
        name: value?.person?.fullName ?? '',
        person: value?.person ?? null,
        historyLabel: value?.historyLabel || '',
        job: value?.job ?? null,
        education: value?.education ?? null,
        degree: value?.degree ?? null,
        citizenship: value?.citizenship ?? null,
        rank: value?.rank ?? null,
        academicRank: value?.academicRank ?? null,
      });
    },
    [author, onAuthorChange],
  );

  return {
    handlePersonChange,
  };
}

export type State = {
  isProcessButtonHidden?: boolean;
};

export function makeUseCustomController({ isProcessButtonHidden }: State) {
  return function useCustomController() {
    return {
      isProcessButtonHidden,
    };
  };
}

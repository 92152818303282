export class Event {
  static CONTEST: string = 'Contest';

  static CONFERENCE: string = 'Conference';

  static EXHIBITION: string = 'Exhibition'; // Устаревшее, постепенно заменится на Exposition

  static EXPOSITION: string = 'Exposition';

  static CONCERT: string = 'Concert';
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

import {
  EditableMetadataField,
  EditableReferenceMetadataField,
} from 'features/Table/specifications/GetReferenceElementList/model';

type Props = {
  fields: EditableMetadataField[];
  handleChangeField(name: string, value: string | ReferenceItem | null): void;
  isViewMode: boolean;
};

function ThematicCategories(props: Props) {
  const { handleChangeField, fields, isViewMode } = props;
  const isTextInput = (name: string) => name === 'name' || name === 'code';

  const getComponent = (field: EditableMetadataField) => {
    const { name } = field;
    if (isTextInput(name)) {
      return (
        <TextInput
          value={(field.value as string) ?? ''}
          onChange={value => handleChangeField(name, value)}
          isDisabled={isViewMode}
        />
      );
    }

    if (name === 'citationSystem') {
      return (
        <Select
          mode={SelectMode.REFERENCE}
          value={(field as EditableReferenceMetadataField).value}
          onChange={(option: ReferenceItem) => {
            handleChangeField('thematicDomain', null);
            handleChangeField(name, option);
          }}
          settings={{ name: (field as EditableReferenceMetadataField).reference, title: field.caption }}
          isDisabled={isViewMode}
        />
      );
    }

    if (name === 'thematicDomain') {
      const citationSystemField = fields.find(x => x.name === 'citationSystem') as EditableReferenceMetadataField | undefined;
      return (
        <Select
          mode={SelectMode.REFERENCE}
          value={(field as EditableReferenceMetadataField).value}
          onChange={(option: ReferenceItem) => handleChangeField(field.name, option)}
          settings={{
            name: (field as EditableReferenceMetadataField).reference,
            title: field.caption,
            filters: !!citationSystemField?.value ? [{ key: 'citationSystem', values: [citationSystemField.value.id] }] : [],
          }}
          isDisabled={isViewMode || !citationSystemField?.value?.id}
        />
      );
    }

    return null;
  };

  const renderField = (name: string) => {
    const field = fields.find(x => x.name === name);

    if (!field) {
      return null;
    }

    return (
      <FormComponent.Line>
        <FormComponent.Field label={field.caption} isRequired={field.required}>
          {getComponent(field)}
        </FormComponent.Field>
      </FormComponent.Line>
    );
  };

  return (
    <>
      {renderField('citationSystem')}
      {renderField('thematicDomain')}
      {renderField('code')}
      {renderField('name')}
    </>
  );
}

export { ThematicCategories };

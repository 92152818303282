import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import { SaveJointEventUserFeedlineRequest, SaveJointEvent } from './types';
import { convertJointEvent } from './converters';
import { convertJointEventToRequest, convertSaveJointEventUserFeedlineToRequest } from './requestConverters';

const configurations = {
  GetJointEvent: makeBackendAPIConfiguration({
    id: 'GetJointEvent',
    endpoint: '/msa/service/commands/GetJointEvent',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetJointEvent' },
        JointEvent: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertJointEvent(response.Response.JointEvent, settings),
  }),
  SaveJointEvent: makeBackendAPIConfiguration({
    id: 'SaveJointEvent',
    endpoint: '/msa/service/commands/SaveJointEvent',
    convertInputToXMLElement: (args: SaveJointEvent) => ({
      Request: {
        _attr: {
          commandName: 'SaveJointEvent',
        },
        JointEvent: { ...convertJointEventToRequest(args) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveJointEventUserFeedline: makeBackendAPIConfiguration({
    id: 'SaveJointEventUserFeedline',
    endpoint: '/msa/service/commands/SaveJointEventUserFeedline',
    convertInputToXMLElement: (props: SaveJointEventUserFeedlineRequest) => ({
      Request: {
        _attr: {
          commandName: 'SaveJointEventUserFeedline',
        },
        ...convertSaveJointEventUserFeedlineToRequest(props),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const jointEventConfigurations = { ...configurations };

import { Table } from 'types/models';
import { GetMonographListRequest } from 'services/BackendAPI/configurations/types';
import LeftPanelForSpecification from './LeftPanelForThirdLevel/LeftPanelForSpecification';

type Props = {
  deps: Table.Dependencies;
} & GetMonographListRequest;

export function GetMonographListSpecification({ deps, isElectronic, monographChapterIds }: Props): Table.Specification {
  return {
    apiID: 'GetMonographList',
    templatesTableDependencies: deps.templatesTableDependencies,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForSpecification,
        hasSelectButton: true,
      },
    },
    requestData: {
      isElectronic,
      monographChapterIds,
    },
  };
}

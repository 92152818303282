import { Icon, Tab, Tabs } from 'components';
import { Color } from 'constants/colors';
import { PaymentRequestType } from 'utils/Enums';

export function IncentivePaymentsGridHelp(type: PaymentRequestType): { title: string; body: JSX.Element } {
  const filters = (
    <ul>
      <li>
        Фильтр <strong>Все</strong> – отображает список заявок.
      </li>
      <li>
        Фильтр <strong>Мои заявки</strong> – отображает список заявок, созданные пользователем.
      </li>
      <li>
        Фильтр <strong>Год</strong> – отображает список заявок, у которых атрибут «Год» равен году в фильтре.
      </li>
      <li>
        <strong>По подразделению</strong> — отображает список заявок, в которых указано подразделение пользователя в разделе «Фонд
        факультета (института)».
      </li>
    </ul>
  );

  const titles: Record<PaymentRequestType, string> = {
    [PaymentRequestType.ARTICLE]: 'Статьи',
    [PaymentRequestType.CONFERENCE]: 'Конференций',
    [PaymentRequestType.EXHIBIT]: '',
    [PaymentRequestType.MEDAL]: 'Награды и выставки',
    [PaymentRequestType.MONOGRAPH]: 'Монография',
    [PaymentRequestType.SECURITY_DOCUMENT]: 'Объекты ОИС',
    [PaymentRequestType.SECURITY_DOCUMENT_ISSUE]: '',
    [PaymentRequestType.TEXTBOOK]: 'Учебник и учебное пособие',
  };

  const bodies: Record<PaymentRequestType, JSX.Element> = {
    [PaymentRequestType.ARTICLE]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на оплату статей в журналах и сборниках</h4>
          <p>
            Таблица содержит список всех заявок на оплату статей в журналах и сборниках. Основные столбцы таблицы содержат данные
            заявки, названия столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы содержат данные
            публикации.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список авторов публикации.</td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>Список номинантов, назначенных к оплате.</td>
              </tr>
              <tr>
                <td>ID-публикации</td>
                <td>Идентификационный номер публикации в базе данных системы</td>
              </tr>
              <tr>
                <td>Тип статьи</td>
                <td>Тип публикации.</td>
              </tr>
              <tr>
                <td>Библиографическая запись</td>
                <td>Библиографическая запись публикации.</td>
              </tr>
              <tr>
                <td>DOI</td>
                <td>DOI публикации.</td>
              </tr>
              <tr>
                <td>Наличие ссылки в электронном каталоге библиотеки университета</td>
                <td>
                  Ссылка на страницу публикации в электронном каталоге библиотеки университета (необязательное поле), носит
                  информативный характер для проверяющего заявку.
                </td>
              </tr>
              <tr>
                <td>Scopus</td>
                <td>Наукометрические показатели публикации в базе цитирования Scopus.</td>
              </tr>
              <tr>
                <td>WoS</td>
                <td>Наукометрические показатели публикации в базе цитирования WoS.</td>
              </tr>
              <tr>
                <td>РИНЦ</td>
                <td>Наукометрические показатели публикации в базе цитирования РИНЦ.</td>
              </tr>
              <tr>
                <td>Вид издания</td>
                <td>Вид издания (из сведений об издании публикации).</td>
              </tr>
              <tr>
                <td>Название проекта</td>
                <td>Проект, который указан для публикации на вкладке «Финансы».</td>
              </tr>
              <tr>
                <td>План номинантам</td>
                <td>
                  Общая сумма в столбце <strong>План, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчёт стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля «Фонд подразделений, руб» — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе «Фонд факультета/института»—распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат» в разделе «Фонд
                  факультета/института».
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов.</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документов на вкладке «Документы» в заявке.</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системные и пользовательские сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>
                  Информация о последней редакции заявки: когда, кто и какое действие совершил. Источник данных – Журнал
                  сообщений.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки для просмотра. На форме
                    заявки имеются вкладки:
                  </p>
                  <ul>
                    <li>Просмотр публикации</li>
                    <li>Расчет стимулирующих выплат</li>
                    <li>Документы</li>
                    <li>Журнал сообщений</li>
                  </ul>
                  <div className="warning">
                    Вкладка «Просмотр публикации» содержит вложенную форму публикации, которая содержит полную информацию для
                    проверки публикации на соответствие условиям Положения об оплате.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. Список статей для выбора на оплату содержит
                    публикации в статусе «Утверждена», у которых отсутствует заявка на оплату стимулирующих выплат.
                  </p>
                  <p>Тип публикации:</p>
                  <ul>
                    <li>Статья в журнале;</li>
                    <li>Статья в сборнике материалов конференции;</li>
                    <li>Статья в сборнике научных трудов;</li>
                    <li>Статья в справочном издании;</li>
                    <li>Статья в другом издании</li>
                  </ul>
                  <div className="warning">
                    <div>
                      <p>
                        При выборе публикации и заполнении формы заявки необходимо руководствоваться требованиями в Положении о
                        стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «Черновик». Пользователь – автор заявки, имеет право редактировать, удалить
                        заявку в статусе «Черновик». и перевести в статус «На согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соответствии с правами пользователя на
                    редактирование и в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только следующие вкладки заявки:</p>
                      <ul>
                        <li>Расчёт стимулирующих выплат</li>
                        <li>Документы</li>
                        <li>Журнал сообщений</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соответствии с правами пользователя на удаление, в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:{' '}
                        <strong>
                          «Оплата одобрена» — это окончательный статус, который выставляется для заявки после передачи документов
                          в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «Черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0», то необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату».</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">В этом статусе заявка для редактирования и удаления не доступна пользователям.</div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>
                    Открывает форму «Журнал сообщений». Таблица содержит список системных и созданных пользователями сообщений.
                  </p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Пользователь имеет право редактировать и удалить только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчеты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта, в котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя (название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
    [PaymentRequestType.CONFERENCE]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на стимулирующие выплаты за организацию и проведение конференции.</h4>
          <p>
            Таблица содержит список всех заявок на оплату конференций. Основные столбцы таблицы содержат данные заявки, названия
            столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы содержат данные конференции.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список участников.</td>
              </tr>
              <tr>
                <td>План номинантам</td>
                <td>
                  Общая сумма в столбце <strong>План, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля «Фонд подразделений, руб» — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе «Фонд факультета/института» — распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат» в разделе «Фонд
                  факультета/института».
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов.</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документов на вкладке «Документы» в заявке.</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системные и пользовательские сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>
                  Информация о последней редакции заявки: когда, кто и какое действие совершил. Источник данных – Журнал
                  сообщений.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки для просмотра. На форме
                    выкладки заявки:
                  </p>
                  <ul>
                    <li>Просмотр конференции</li>
                    <li>Расчет стимулирующих выплат</li>
                    <li>Документы</li>
                    <li>Журнал сообщений</li>
                  </ul>
                  <div className="warning">
                    Вкладка «Просмотр конференции» содержит вложенную форму конференции и предназначена для проверки конференции
                    на соответствие условиям Положения об оплате.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. Список конференций содержит конференции в статусе
                    «Утверждена», у которых отсутствует заявка на оплату стимулирующих выплат; в составе организаторов указаны
                    подразделения университета.
                  </p>
                  <div className="warning">
                    <div>
                      <p>
                        При выборе конференции и заполнении формы заявки необходимо руководствоваться требованиями в Положении о
                        стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «Черновик». Пользователь – автор заявки имеет право редактировать, удалить
                        заявку в статусе «Черновик» и перевести в статус «На согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соответствии с правами пользователя на
                    редактирование в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только вкладки заявки:</p>
                      <ul>
                        <li>Расчет стимулирующих выплат</li>
                        <li>Документы</li>
                        <li>Журнал сообщений</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соответствии с правами пользователя на удаление в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:{' '}
                        <strong>
                          «Оплата одобрена» — это окончательный статус, который выставляется для заявки после передачи документов
                          в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «Черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0», необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату»</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">
                    <strong>В этом статусе заявка для редактирования и удаления не доступна пользователям.</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>
                    Открывает форму «Журнал сообщений». Таблица содержит список системных и созданных пользователями сообщений.
                  </p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Пользователь имеет право редактировать и удалить только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчеты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта, в котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя (название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
    [PaymentRequestType.EXHIBIT]: <></>,
    [PaymentRequestType.MEDAL]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на стимулирующие выплаты за награду, полученную на выставке.</h4>
          <p>
            Таблица содержит список всех заявок на оплату участия в выставке. Основные столбцы таблицы содержат данные заявки,
            названия столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы содержат данные из участия в
            выставке.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список участников.</td>
              </tr>
              <tr>
                <td>План номинантам</td>
                <td>
                  Общая сумма в столбце <strong>План, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля «Фонд подразделений, руб» — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе «Фонд факультета/института» — распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат» в разделе «Фонд
                  факультета/института».
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов.</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документов на вкладке «Документы» в заявке.</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системные и пользовательские сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>
                  Информация о последней редакции заявки: когда, кто и какое действие совершил. Источник данных – Журнал
                  сообщений.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки участия в выставке для
                    просмотра. На форме выкладки заявки:
                  </p>
                  <ul>
                    <li>Участие в выставке</li>
                    <li>Расчет стимулирующих выплат</li>
                    <li>Документы</li>
                    <li>Журнал сообщений</li>
                  </ul>
                  <div className="warning">
                    Вкладка «Участие в выставке» содержит вложенную форму участия в выставке для проверки информации на
                    соответствие условиям Положения об оплате.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. Список конференций содержит конференции в статусе
                    «Утверждена», у которых отсутствует заявка на оплату стимулирующих выплат; в составе организаторов указаны
                    подразделения университета.
                  </p>
                  <div className="warning">
                    <div>
                      <p>
                        при выборе записи об участии в выставке и заполнении формы заявки необходимо руководствоваться
                        требованиями в Положении о стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «Черновик». Пользователь – автор заявки, имеет право редактировать, удалить
                        заявку в статусе «Черновик» и перевести в статус «На согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соответствии с правами пользователя на
                    редактирование в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только вкладки заявки:</p>
                      <ul>
                        <li>Расчет стимулирующих выплат</li>
                        <li>Документы</li>
                        <li>Журнал сообщений</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соответствии с правами пользователя на удаление в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:
                        «Оплата одобрена» — это окончательный{' '}
                        <strong>
                          статус, который выставляется для заявки после передачи документов в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «Черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0», необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку»</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату».</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">
                    <strong>В этом статусе заявка для редактирования и удаления не доступна пользователям.</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>
                    Открывает форму «Журнал сообщений». Таблица содержит список системных и созданных пользователями сообщений.
                  </p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Пользователь имеет право редактировать и удалить только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчеты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта, в котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя ( название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
    [PaymentRequestType.MONOGRAPH]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на оплату монографии или главы монографии.</h4>
          <p>
            Таблица содержит список всех заявок на оплату монографии или главы монографии. Основные столбцы таблицы содержат
            данные заявки, названия столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы содержат данные
            публикации.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список авторов публикации.</td>
              </tr>
              <tr>
                <td>Язык текста</td>
                <td>Атрибут публикации.</td>
              </tr>
              <tr>
                <td>ID-публикации</td>
                <td>Идентификационный номер публикации в базе данных системы.</td>
              </tr>
              <tr>
                <td>Тип издательства</td>
                <td>Тип издательства, атрибут публикации.</td>
              </tr>
              <tr>
                <td>Библиографическая запись</td>
                <td>Библиографическая запись публикации.</td>
              </tr>
              <tr>
                <td>Издательство</td>
                <td>Название издательства, атрибут публикации.</td>
              </tr>
              <tr>
                <td>Место издания</td>
                <td>Название города-места издания.</td>
              </tr>
              <tr>
                <td>Год издания</td>
                <td>Год издания публикации.</td>
              </tr>
              <tr>
                <td>Научный проект</td>
                <td>Номер проекта, который указан на вкладке публикации «Финансовая поддержка».</td>
              </tr>
              <tr>
                <td>План номинантам</td>
                <td>
                  Общая сумма в столбце <strong>План, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат»
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля «Фонд подразделений, руб» — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе Фонд факультета/института—распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат» в разделе Фонд
                  факультета/института.
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов.</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документов на вкладке «Документы» в заявке.</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системные и пользовательские сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>
                  Информация о последней редакции заявки: когда, кто и какое действие совершил. Источник данных – Журнал
                  сообщений.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки на оплату монографии для
                    просмотра. На форме выкладки заявки:
                  </p>
                  <ul>
                    <li>Просмотр публикации</li>
                    <li>Расчет стимулирующих выплат</li>
                    <li>Документы</li>
                    <li>Журнал сообщений</li>
                  </ul>
                  <div className="warning">
                    Вкладка «Просмотр публикации» содержит вложенную форму публикации, которая содержит полную информацию для
                    проверки публикации на соответствие условиям Положения об оплате.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. Список монографий для выбора на оплату содержит
                    публикации в статусе «Утверждена», у которых отсутствует заявка на оплату стимулирующих выплат.
                  </p>
                  <p>Тип публикации:</p>
                  <ul>
                    <li>Монография</li>
                    <li>Глава в монографии</li>
                  </ul>
                  <div className="warning">
                    <div>
                      <p>
                        При выборе публикации и заполнении формы заявки необходимо руководствоваться требованиями в Положении о
                        стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «Черновик». Пользователь – автор заявки, имеет право редактировать, удалить
                        заявку в статусе «Черновик» и перевести в статус «На согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соответствии с правами пользователя на
                    редактирование в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только вкладки заявки:</p>
                      <ul>
                        <li>Расчет стимулирующих выплат</li>
                        <li>Документы</li>
                        <li>Журнал сообщений</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соответствии с правами пользователя на удаление в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:{' '}
                        <strong>
                          «Оплата одобрена» — это окончательный статус, который выставляется для заявки после передачи документов
                          в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «Черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0», необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку»</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату».</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">
                    <strong>В этом статусе заявка для редактирования и удаления не доступна пользователям.</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>
                    Открывает форму «Журнал сообщений». Таблица содержит список системных и созданных пользователями сообщений.
                  </p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Пользователь имеет право редактировать и удалить только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчеты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта, в котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя (название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
    [PaymentRequestType.SECURITY_DOCUMENT]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на оплату объектов ОИС.</h4>
          <p>
            Таблица содержит список всех заявок на оплату объектов интеллектуальной собственности. Основные столбцы таблицы
            содержат данные заявки, названия столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы
            содержат данные объекта.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список авторов публикации.</td>
              </tr>
              <tr>
                <td>ID ОИС</td>
                <td>Идентификационный номер объекта интеллектуальной собственности в базе данных системы</td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчёт стимулирующих выплат»
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчёт стимулирующих выплат»
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля Фонд подразделений — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе Фонд факультета/института — распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчёт стимулирующих выплат» в разделе Фонд
                  факультета/института.
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документах на вкладке Документы в заявке</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системных и пользовательских сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>Информация о последней редакции заявки: когда, кто и какое действие. Источник данных – Журнал сообщений.</td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки на стимулирующие выплаты по
                    объекту интеллектуальной собственности для просмотра. На форме выкладки заявки:
                  </p>
                  <ul>
                    <li>Расчёт стимулирующих выплат</li>
                    <li>Документы</li>
                  </ul>
                  <div className="warning">
                    Вкладка Просмотр ОИС содержит вложенную форму объекта интеллектуальной собственности и предназначена для
                    проверки объекта на соответствие условиям Положения о стимулирующих выплатах.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. и выбора объекта интеллектуальной собственности из
                    консолидированного списка патентов, свидетельств, ноу-хау, объектов авторского права.
                  </p>
                  <div className="warning">
                    <div>
                      <p>
                        при выборе конференции и заполнении формы заявки необходимо руководствоваться требованиями в Положении о
                        стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «черновик». Пользователь-автор заявки имеет право редактировать, удалить
                        заявку в статусе Черновик. И перевести в статус «на согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соотвествии с правами пользователя на
                    редактирование в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только вкладки заявки:</p>
                      <ul>
                        <li>Расчёт стимулирующих выплат</li>
                        <li>Документы</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соотвествии с правами пользователя на удаление в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:{' '}
                        <strong>
                          «Оплата одобрена» — это окончательный статус, который выставляется для заявки после передачи документов
                          в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0». Необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании»</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании»</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку»</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату»</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">
                    <strong>Статус «В этом статусе заявка для редактирования и удаления не доступна пользователям.</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>Открывает форму Журнал сообщений. Таблица содержит список системных и созданных пользователями сообщений.</p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Редактировать и удалить пользователь имеет право только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчёты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта. В котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя (название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
    [PaymentRequestType.SECURITY_DOCUMENT_ISSUE]: <></>,
    [PaymentRequestType.TEXTBOOK]: (
      <Tabs>
        <Tab title="Представление в таблице">
          <h4>Заявки на оплату учебника и учебного пособия.</h4>
          <p>
            Таблица содержит список всех заявок на оплату учебника и учебного пособия. Основные столбцы таблицы содержат данные
            заявки, названия столбцов соответствуют названиям полей на форме заявки. Дополнительные столбцы содержат данные
            публикации.
          </p>
          {filters}
          <h4>Особенности вывода информации в столбцах таблицы:</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Показатель (пункт положения)</td>
                <td>Пункт Положения о стимулирующих выплатах. Используется для группировки заявок по номинациям.</td>
              </tr>
              <tr>
                <td>Название показателя</td>
                <td>
                  Название показателя в Положении о стимулирующих выплатах. Используется для группировки заявок по номинациям.
                </td>
              </tr>
              <tr>
                <td>Год</td>
                <td>Год периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Квартал</td>
                <td>Номер квартала периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Месяц</td>
                <td>Номер месяца периода оплаты заявки.</td>
              </tr>
              <tr>
                <td>Статус</td>
                <td>Текущий статус заявки.</td>
              </tr>
              <tr>
                <td>Номинанты</td>
                <td>Полный список авторов публикации.</td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>Список номинантов, назначенных к оплате.</td>
              </tr>
              <tr>
                <td>ID-публикации</td>
                <td>Идентификационный номер публикации в базе данных системы</td>
              </tr>
              <tr>
                <td>Библиографическая запись</td>
                <td>Библиографическая запись публикации.</td>
              </tr>
              <tr>
                <td>План номинантам</td>
                <td>
                  Общая сумма в столбце <strong>План, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Факт номинантам</td>
                <td>
                  Общая сумма в столбце <strong>Факт, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>Остаток номинантам</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд номинантов, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Факт, руб.</strong> на вкладке заявки «Расчёт стимулирующих выплат».
                </td>
              </tr>
              <tr>
                <td>План фонда подразделения</td>
                <td>Значение поля «Фонд подразделений, руб» — в соответствии с Положением об оплате стимулирующих выплат.</td>
              </tr>
              <tr>
                <td>Факт фонда подразделений</td>
                <td>
                  Общая сумма из одноимённого столбца в разделе Фонд факультета/института — распределённая по подразделениям в
                  соответствии с «весом» подразделения номинанта.
                </td>
              </tr>
              <tr>
                <td>Остаток фонда подразделений</td>
                <td>
                  Вычисляется по формуле: <strong>Фонд подразделений, руб.</strong> – Общая сумма в столбце{' '}
                  <strong>Сумма, руб.</strong> на вкладке заявки «Расчет стимулирующих выплат» в разделе Фонд
                  факультета/института.
                </td>
              </tr>
              <tr>
                <td>Факультет/Институт</td>
                <td>Список подразделений номинантов</td>
              </tr>
              <tr>
                <td>Документы</td>
                <td>Отметка о наличии прикреплённых документах на вкладке «Документы» в заявке.</td>
              </tr>
              <tr>
                <td>Добавлено</td>
                <td>ФИО пользователя – автора заявки. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Изменено</td>
                <td>
                  ФИО пользователя, который последний раз редактировал заявку. Источник данных – Журнал сообщений, который
                  содержит все системные и пользовательские сообщения.
                </td>
              </tr>
              <tr>
                <td>Статус изменён</td>
                <td>ФИО пользователя, который установил для заявки текущий статус. Источник данных – Журнал сообщений.</td>
              </tr>
              <tr>
                <td>Последняя активность</td>
                <td>
                  Информация о последней редакции заявки: когда, кто и какое действие совершил. Источник данных – Журнал
                  сообщений.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
        <Tab title="Функциональные кнопки">
          <h4>Панель инструментов для управления записями в таблице</h4>
          <p>
            При выделении заявки в таблице функциональные кнопки на панели инструментов активны, в зависимости от прав
            пользователя и статуса заявки.
          </p>
          <table>
            <thead>
              <tr>
                <th>Функциональная кнопка</th>
                <th>Описание</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>
                  Просмотр:
                  <Icon type="view" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой. Открывает форму заявки для просмотра. На форме
                    выкладки заявки:
                  </p>
                  <ul>
                    <li>Просмотр публикации</li>
                    <li>Расчет стимулирующих выплат</li>
                    <li>Документы</li>
                    <li>Журнал сообщений</li>
                  </ul>
                  <div className="warning">
                    Вкладка «Просмотр публикации» содержит вложенную форму публикации и предназначена для проверки публикации на
                    соответствие условиям Положения об оплате.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Добавить:
                  <Icon type="add" />
                </td>
                <td>
                  <p>
                    Открывает форму добавления заявки на стимулирующие выплаты. Список учебников и учебных пособий для выбора на
                    оплату содержит публикации в статусе «Утверждена», у которых отсутствует заявка на оплату стимулирующих
                    выплат.
                  </p>
                  <p>Тип публикации:</p>
                  <ul>
                    <li>Учебник,</li>
                    <li>Учебное пособие</li>
                  </ul>
                  <div className="warning">
                    <div>
                      <p>
                        При выборе публикации и заполнении формы заявки необходимо руководствоваться требованиями в Положении о
                        стимулирующих выплатах.
                      </p>
                      <p>
                        Первоначальный статус заявки – «Черновик». Пользователь – автор заявки, имеет право редактировать, удалить
                        заявку в статусе «Черновик» и перевести в статус «На согласовании».
                      </p>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Редактировать:
                  <Icon type="edit" />
                </td>
                <td>
                  <p>
                    Кнопка активна, если в таблице выделена запись с заявкой в соотвествии с правами пользователя на
                    редактирование в зависимости от статуса заявки.
                  </p>
                  <p>Открывает форму заявки для редактирования.</p>
                  <div className="warning">
                    <div>
                      <p>для редактирования доступны только вкладки заявки:</p>
                      <ul>
                        <li>Расчет стимулирующих выплат</li>
                        <li>Документы</li>
                        <li>Журнал сообщений</li>
                      </ul>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Удалить:
                  <Icon type="remove" />
                </td>
                <td>
                  <p>Удалить выделенную в таблице заявку.</p>
                  <p>Кнопка активна в соотвествии с правами пользователя на удаление в зависимости от статуса заявки.</p>
                  <div className="warning">
                    <ul>
                      <li>
                        Список прав на удаление заявки предусматривает возможность удаления заявки во всех статусах, кроме:{' '}
                        <strong>
                          «Оплата одобрена» — это окончательный статус, который выставляется для заявки после передачи документов
                          в бухгалтерию на оплату.
                        </strong>
                      </li>
                      <li>
                        При переводе заявки из статуса «Оплата одобрена» в «Черновик» Фонд подразделения автоматически не
                        перерассчитывается.
                      </li>
                      <li>
                        Если в заявке Фонд подразделений больше чем «0», необходимо{' '}
                        <strong>вручную отредактировать данные Фонда подразделений</strong> в номинации, соответствующей заявке.
                      </li>
                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Подать на согласование:
                  <Icon type="toForward" color={Color.success} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусах «Черновик» и «На доработку».</p>
                  <p>Устанавливает заявке статус «На согласовании».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отложить:
                  <Icon type="clock" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отложена».</p>
                  <p>Отложенную заявку можно перевести в статусы: «Отклонена» или «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отклонить:
                  <Icon type="dislike" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «Отклонена».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Отправить на доработку:
                  <Icon type="toBack" color={Color.danger} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании».</p>
                  <p>Устанавливает заявке статус «На доработку».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Передать на оплату:
                  <Icon type="like" />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «На согласовании», «Отложена».</p>
                  <p>Устанавливает заявке статус «Передана на оплату».</p>
                  <p>
                    В этом статусе заявка для редактирования и удаления доступна пользователям, у которых есть соответствующее
                    право.
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Одобрить оплату:
                  <Icon type="star" color={Color.warning} />
                </td>
                <td>
                  <p>Кнопка активна для записи в статусе «Передана на оплату».</p>
                  <p>Устанавливает заявке статус «Оплата одобрена».</p>
                  <div className="warning">
                    <strong>В этом статусе заявка для редактирования и удаления не доступна пользователям.</strong>
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Журнал сообщений:
                  <Icon type="messages" />
                </td>
                <td>
                  <p>
                    Открывает форму «Журнал сообщений». Таблица содержит список системных и созданных пользователями сообщений.
                  </p>
                  <p>Пользователь имеет возможность добавить сообщение.</p>
                  <div className="warning">
                    Пользователь имеет право редактировать и удалить только то сообщение, которое он создал.
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  Отчеты:
                  <Icon type="print" />
                </td>
                <td>
                  <p>
                    Открывает список шаблонов для формирования отчётов. Список зависит от прав пользователя на доступ к группе
                    отчётов.
                  </p>
                  <p>
                    При формировании отчёта. в котором один из параметров — подразделение, подставляется автоматически
                    подразделение, которое указано у пользователя (название подразделения отображается в профиле пользователя).
                  </p>
                </td>
              </tr>
              <tr>
                <td>
                  Выгрузить в файл:
                  <Icon type="excel" />
                </td>
                <td>
                  Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве
                  основы для оформления отчёта по запросу.
                </td>
              </tr>
            </tbody>
          </table>
        </Tab>
      </Tabs>
    ),
  };

  return { title: titles[type], body: bodies[type] };
}

import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Props as ControllerProps } from './makeUseCustomController';
import { GetGridSettingRequestData } from 'services/BackendAPI/configurations/types';

type Props = {} & ControllerProps;

export function getGridSettingsSpecification({
  columns,
  currentSetting,
  gridName,
  crudCallback,
}: Props): Table.Specification<State, GetGridSettingRequestData> {
  return {
    apiID: 'GetGridSettings',
    requestData: {
      isCurrentUserSettingsOnly: false,
      relatedGridName: gridName,
    },
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({
      columns,
      gridName,
      currentSetting,
      crudCallback,
    }),
  };
}

import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Doughnut, getElementAtEvent } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { FormComponent, Modal, Select, TextInput, TextInputMode } from 'components';

import { Item } from 'types/models/common';
import { DataGrid } from 'features/Table';
import { GetDashboardChartByTypeEditionMagazineArticleList } from 'features/Table/specifications';
import { COLORS, COLORS_BORDERS } from '../сolors';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationShares() {
  const {
    dateType,
    setDateType,
    dateTypeList,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    requestDates,
    modalTitle,
    isModalOpen,
    setIsModalOpen,
    actualType,
    seriesClickHandler,
    rusAndOtherByPeriod,
    getChartSumResult,
    getNumberSumResult,
  } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value } = context;
      return `${Math.round((parseInt(value, 10) / getNumberSumResult()) * 100 * 100) / 100}% (${value} шт.)`;
    };

    const data = {
      labels: rusAndOtherByPeriod.map((x: { kind: any }) => x?.kind),
      datasets: [
        {
          data: rusAndOtherByPeriod.map((x: { share: any }) => x?.share),
          backgroundColor: COLORS,
          borderColor: COLORS_BORDERS,
          borderWidth: 2,
        },
      ],
    };

    return (
      <Doughnut
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          plugins: {
            title: {
              display: true,
              align: 'end',
              text: `Всего: ${getChartSumResult()}`,
              font: {
                size: 20,
              },
            },
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
        onClick={(event: React.MouseEvent<HTMLCanvasElement, MouseEvent>) => {
          const element = getElementAtEvent(chartRef.current!, event)[0];
          if (element) {
            seriesClickHandler(rusAndOtherByPeriod[element.index]);
          }
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Доли публикаций в журналах по видам издания</h3>
        <div className={b('params').mix('publications-on-magazine')}>
          <FormComponent.Line>
            <FormComponent.Field label="Параметр" hasLeftAlign>
              <Select value={dateType} options={dateTypeList} onChange={(option: Item) => setDateType(option)} />

              <span>
                <TextInput mode={TextInputMode.YEAR} value={dateFrom} onChange={date => (date ? setDateFrom(date) : null)} />
              </span>
              {dateType.value === 'PERIOD' && (
                <>
                  <span>&nbsp;-&nbsp;</span>
                  <span>
                    <TextInput mode={TextInputMode.YEAR} value={dateTo} onChange={date => (date ? setDateTo(date) : null)} />
                  </span>
                </>
              )}
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
        <div className={b('chart')} style={{ height: '440px' }}>
          {Chart()}
        </div>
      </div>

      <Modal
        isOpen={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
        }}
        title={modalTitle}
        size={['extralarge', 'medium-height']}
      >
        <DataGrid
          specification={GetDashboardChartByTypeEditionMagazineArticleList({
            startYear: requestDates[0],
            endYear: requestDates[1],
            typeEdition: actualType,
          })}
        />
      </Modal>
    </>
  );
}

import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ProjectEquipGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Таблица содержит список записей об использовании оборудования, оформленных в проекте на вкладке{' '}
          <strong>Особенности, Использование ресурсов и оборудования</strong>. В записи указано название оборудования, в каком
          этапе проекта планируется его использовать, период использования и уточняющие сведения в составе: количество дней и
          общее количество часов; количество участников проекта, в том числе «молодых».
        </p>

        <p>
          Фильтр <strong>Год</strong> отображает список «заявок» на использование оборудования в период указанного в фильтре года.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Оборудование</td>
              <td>Название оборудования</td>
            </tr>
            <tr>
              <td>ЦКП</td>
              <td>Наличие символа «галка» обозначает, что оборудование принадлежит Центру коллективного пользования</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта</td>
            </tr>
            <tr>
              <td>Тема проекта</td>
              <td>Название темы проекта</td>
            </tr>
            <tr>
              <td>Руководитель проекта</td>
              <td>ФИО и контакты руководителя проекта</td>
            </tr>
            <tr>
              <td>Ответственный исполнитель</td>
              <td>ФИО и контакты ответственного исполнителя в проекте</td>
            </tr>
            <tr>
              <td>Этап</td>
              <td>Номер и название этапа</td>
            </tr>
            <tr>
              <td>Дата начала использования</td>
              <td>Дата начала периода использования оборудования</td>
            </tr>
            <tr>
              <td>Дата окончания использования</td>
              <td>Дата окончания периода использования оборудования</td>
            </tr>
            <tr>
              <td>Количество дней</td>
              <td>Количество дней. Не обязательное поле</td>
            </tr>
            <tr>
              <td>Количество часов</td>
              <td>Количество часов Не обязательное поле</td>
            </tr>
            <tr>
              <td>Цели этапа</td>
              <td>Цели этапа из одноимённого поля в этапе проекта</td>
            </tr>
            <tr>
              <td>Задачи этапа</td>
              <td>Задачи этапа из одноимённого поля в этапе проекта</td>
            </tr>
            <tr>
              <td>Количество человек</td>
              <td>Количество человек. Не обязательное поле</td>
            </tr>
            <tr>
              <td>из них до 39 лет</td>
              <td>из них до 39 лет. Не обязательное поле</td>
            </tr>
            <tr>
              <td>Прим. об использовании</td>
              <td>Поясняющий текст</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

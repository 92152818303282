import { Table } from 'types/models';

type Arguments = {
  userId: string;
  isWithoutHeader?: boolean;
};

type RequestData = {
  userId: string;
};

export function GetUserPermissionList({ userId, isWithoutHeader }: Arguments): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetUserPermissionList',
    header: {
      firstLevel: {
        title: isWithoutHeader ? '' : 'Права пользователя',
      },
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
    requestData: { userId },
  };
}

import { useState, useCallback, useMemo, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import { Form, Table } from 'types/models';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import useSavePublicationHook from 'features/Form/hooks/savePublicationHook';

import { useLocalTableStreams } from 'features/Table/hooks';
import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';

import { useAppDataContext } from 'features/AppData/context';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { useFormContext } from 'features/Form/hooks';
import { validate } from './validate';
import { showNotification } from 'features/Notifications';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

type Props = {
  onClose: () => void;
};

export function useController({ onClose }: Props) {
  const { initialLanguageCode, enumMap } = useAppDataContext();
  const {
    look: { type, id, relatedTableState, isPreprint, viewMode, editMode },
  } = useFormContext<Form.PublicationFormLook>();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });
  const tableStreams = useLocalTableStreams();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();

  const EditionSettings: Table.EnumValue[] = enumMap && enumMap.TypeEdition ? enumMap.TypeEdition.values : [];

  const [publicationInfo, setPublicationInfo] = useState<Form.Publication | null>(null);
  const [publicationId, setPublicationId] = useState<string>(id || '');
  const [topMessage, setTopMessage] = useState<string>('');
  const [publicationTranslates, setPublicationTranslates] = useState<Form.Translation[]>([]);
  const [publicationOriginal, setPublicationOriginal] = useState<Form.Original | null>(null);
  const [formFields, setFormFields] = useState({
    fullName: {
      value: '',
      required: true,
      isValid: true,
      title: 'Основное название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fullName: { ...prevState.fullName, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          fullName: { ...prevState.fullName, isValid: true },
        }));
      },
    },
    foreignName: {
      value: '',
      isValid: true,
      title: 'Дополнительное название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          foreignName: { ...prevState.foreignName, value },
        }));
      },
    },
    ISSN: {
      value: '',
      isValid: true,
      title: 'ISSN',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          ISSN: { ...prevState.ISSN, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          ISSN: { ...prevState.ISSN, isValid: true },
        }));
      },
    },
    ISSNEl: {
      value: '',
      isValid: true,
      title: 'ISSN эл. версии',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          ISSNEl: { ...prevState.ISSNEl, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          ISSNEl: { ...prevState.ISSNEl, isValid: true },
        }));
      },
    },
    textLanguages: {
      value: '',
      isValid: true,
      title: 'Языки текста',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          textLanguages: { ...prevState.textLanguages, value },
        }));
      },
    },
    amount: {
      value: '',
      title: 'Количество выпусков в год',
      isValid: true,
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          amount: { ...prevState.amount, value },
        }));
      },
    },
    DOI: {
      value: '',
      isValid: true,
      title: 'DOI',
      tooltipText: 'Цифровой идентификатор объекта, представленного в Интернете, пример заполнения: 10.1007/b136753',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          DOI: { ...prevState.DOI, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          DOI: { ...prevState.DOI, isValid: true },
        }));
      },
    },
    site: {
      value: '',
      isValid: true,
      title: 'Сайт журнала',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          site: { ...prevState.site, value },
        }));
      },
    },
    connection: {
      value: '',
      isValid: true,
      title: 'Поле связи',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          connection: { ...prevState.connection, value },
        }));
      },
    },
    isOriginal: {
      value: false,
      isValid: true,
      title: 'Является оригиналом',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isOriginal: {
            ...prevState.isOriginal,
            value: !prevState.isOriginal.value,
          },
          isTranslate: { ...prevState.isTranslate, value: false },
        }));
      },
    },
    isTranslate: {
      value: false,
      isValid: true,
      title: 'Является переводом',
      onChange: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isTranslate: {
            ...prevState.isTranslate,
            value: !prevState.isTranslate.value,
          },
          isOriginal: { ...prevState.isOriginal, value: false },
        }));
      },
    },
    editionType: {
      value: '',
      title: 'Вид издания',
      label: '',
      isValid: true,
      required: true,
      onChange: (label: string) => {
        const selectedID = EditionSettings.find(e => e.label === label)?.value || '';

        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          editionType: {
            ...prevState.editionType,
            value: selectedID,
            label,
          },
        }));
      },
    },
    publisher: {
      value: { label: '', id: '' },
      title: 'Издательство',
      isValid: true,
      onChange: (value: ReferenceItem) => {
        setFormFields(prevState => ({
          ...prevState,
          publisher: {
            ...prevState.publisher,
            value,
          },
        }));
      },
    },
    note: {
      value: '',
      isValid: true,
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
  } as Form.Fields);

  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: savePublicationAPI, state: savePublicationAPIState } = BackendAPI.useBackendAPI('SavePublication');
  const { isLoading } = useIsLoading({ callStates: [getPublicationState, savePublicationAPIState] });

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'COMPILATION_FORM_ARTICLE', []);

  const loadMagazine = useCallback(
    (currentId?: string) => {
      getPublication.callAPI(
        {
          simpleFields: {
            type: type?.toUpperCase() || '',
            status: PublicationStatus.ADDED,
            typeEdition: 'OTHER_UNIVERSITY',
            isPreprint: isPreprint === true,
          },
          arrayFields: {
            translations: { translation: [] },
            events: { event: [] },
          },
          attrIdFields: {},
          projects: [],
          mobileRequests: {},
          id: currentId || publicationId || undefined,
        },
        {
          onSuccessfullCall: ({ data }) => {
            const { translations, original, fields } = data;

            setPublicationId(data.status?.id || '');
            setPublicationTranslates(translations);
            setPublicationOriginal(original);

            setFormFields(prevState => ({
              ...prevState,
              fullName: {
                ...prevState.fullName,
                value: fields.name,
              },
              foreignName: {
                ...prevState.foreignName,
                value: fields.foreignName,
              },
              amount: {
                ...prevState.amount,
                value: fields.amount,
              },
              textLanguages: {
                ...prevState.textLanguages,
                value: fields.languages,
              },
              ISSN: {
                ...prevState.ISSN,
                value: fields.ISSN,
              },
              ISSNEl: {
                ...prevState.ISSNEl,
                value: fields.EISSN,
              },
              site: {
                ...prevState.site,
                value: fields.url,
              },
              publisher: {
                ...prevState.publisher,
                value: {
                  id: fields.publisherId,
                  label: fields.publisherLabel,
                },
              },
              isTranslate: {
                ...prevState.isTranslate,
                value: Boolean(original),
              },
              isOriginal: {
                ...prevState.isOriginal,
                value: Boolean(translations.length),
              },
              DOI: {
                ...prevState.DOI,
                value: fields.doi,
              },
              connection: {
                ...prevState.connection,
                value: fields.libraryLink,
              },
              editionType: {
                ...prevState.editionType,
                label: fields.typeEditionText || '',
                value: fields.typeEditionCode || '',
              },
              note: {
                ...prevState.note,
                value: fields.note || '',
              },
            }));

            setPublicationInfo(data as Form.Publication);
          },
        },
      );
    },
    [getPublication, type, isPreprint, publicationId],
  );

  useLayoutEffect(() => {
    loadMagazine();
    // eslint-disable-next-line
  }, []);

  const refreshMagazine = useCallback(
    (val: string) => {
      loadMagazine(val);
    },
    [loadMagazine],
  );

  const prepareFormFields = useCallback(() => {
    const preparedFormFields = { ...formFields };

    const validationResult = validate({
      formFields,
      translates: publicationTranslates,
      original: publicationOriginal,
    });

    return {
      validationResult,
      preparedFormFields,
    };
  }, [formFields, publicationOriginal, publicationTranslates]);

  const savePublication = useCallback(
    ({ preparedFormFields, nextStatus, publicationMessage, publicationMessageType, isNeedClose }: Form.SavePublicationData) => {
      const isMessage = !!publicationMessageType?.id;
      const currentFormFields = preparedFormFields || formFields;
      savePublicationAPI.callAPI(
        {
          id: publicationId || undefined,
          simpleFields: {
            name: clearWhitespaceCharacters(formFields.fullName.value),
            type: type?.toUpperCase() || '',
            status: nextStatus,
            foreignName: currentFormFields.foreignName.value as string,
            typeEdition: currentFormFields.editionType.value as string,
            issuesByYear: currentFormFields.amount.value as string,
            textLanguages: currentFormFields.textLanguages.value as string,
            url: currentFormFields.site.value as string,
            libraryLink: currentFormFields.connection.value as string,
            issn: currentFormFields.ISSN.value as string,
            eIssn: currentFormFields.ISSNEl.value as string,
            doi: currentFormFields.DOI.value as string,
            isPreprint: isPreprint === true,
            note: currentFormFields.note.value,
          },
          arrayFields: {
            translations: { translation: formFields.isOriginal.value ? publicationTranslates : [] },
          },
          attrIdFields: {
            publisher: formFields.publisher.value?.id,
            original: formFields.isTranslate.value ? publicationOriginal?.id : '',
          },
          feedLine: isMessage
            ? {
                messageId: publicationMessageType.id as string,
                detail: publicationMessage as string,
              }
            : undefined,
          projects: [],
          communityMembers: publicationInfo?.communityMembers,
          mobileRequests: {},
        },
        {
          onSuccessfullCall: ({ data }) => {
            updateWorkModeAfterSaveAndContinue();
            if (!isNeedClose) {
              refreshMagazine((data?.status?.id as string) || '');
            }
            showNotification({
              message: publicationId ? 'Журнал успешно отредактирован!' : 'Журнал успешно создан!',
              theme: 'success',
            });
            if (isNeedClose) {
              onClose();
              tableStreams.reloadTable.push({});
            }
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      formFields,
      isPreprint,
      onClose,
      publicationId,
      publicationOriginal?.id,
      publicationTranslates,
      refreshMagazine,
      savePublicationAPI,
      tableStreams.reloadTable,
      type,
      updateWorkModeAfterSaveAndContinue,
      publicationInfo,
    ],
  );
  const startCheckDuplicates = useCallback(
    ({ preparedFormFields, nextStatus, isNeedClose }: Form.StartCheckDuplicatesData) => {
      const successfullCallback = ({
        publicationMessage,
        publicationMessageType,
      }: {
        publicationMessage: string;
        publicationMessageType: ReferenceItem;
      }) => {
        savePublication({
          preparedFormFields,
          publicationMessage,
          publicationMessageType,
          isNeedClose,
          nextStatus,
        });
      };

      suchLikePublicationStreams.getSuchLikePublications.push({
        category: 'MAGAZINE',
        searchValue: formFields.fullName?.value as string,
        successfullCallback,
        publicationType: publicationInfo?.type as Form.PublicationTypeCode,
        currentId: publicationId || null,
        isDefaultSave: !isNeedClose,
        componentId: SUCH_LIKE_PUBLICATION_ID,
      });
    },
    [
      SUCH_LIKE_PUBLICATION_ID,
      formFields.fullName?.value,
      publicationId,
      publicationInfo?.type,
      savePublication,
      suchLikePublicationStreams.getSuchLikePublications,
    ],
  );

  const { handlePublicationSave, handlePublicationSaveAndContinue } = useSavePublicationHook({
    currentStatus: (publicationInfo?.status?.status as PublicationStatus) || PublicationStatus.ADDED,
    currentStatusText: publicationInfo?.status.text || 'Добавлена',
    setFormFields,
    prepareFormFields,
    startCheckDuplicates,
    savePublication,
    initialLanguageCode: initialLanguageCode?.id ?? '',
  });

  const addCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        const citationSystemIndex = publicationInfo.citationSystems.findIndex(
          citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
        );
        const isNewCitationSystem = citationSystemIndex === -1;

        if (isNewCitationSystem) {
          setPublicationInfo(prevState => ({
            ...prevState!,
            citationSystems: [...prevState!.citationSystems, citationSystem],
          }));
        } else {
          setPublicationInfo(prevState => {
            const nextCitationSystems = JSON.parse(JSON.stringify(prevState!.citationSystems));
            nextCitationSystems[citationSystemIndex] = { ...citationSystem };
            return {
              ...prevState!,
              citationSystems: nextCitationSystems,
            };
          });
        }
      }
    },
    [publicationInfo],
  );

  const editCitationSystem = useCallback(
    (citationSystem: Form.CitationSystem) => {
      const citationSystemToUpdateIndex = (publicationInfo?.citationSystems || []).findIndex(
        citationSystemItem => citationSystemItem.publicationCitationSystemId === citationSystem.publicationCitationSystemId,
      );

      if (citationSystemToUpdateIndex !== -1) {
        const clonedCitationSystems: Form.CitationSystem[] = [...(publicationInfo?.citationSystems || [])];
        clonedCitationSystems.splice(citationSystemToUpdateIndex, 1, citationSystem);

        setPublicationInfo(prevState => prevState && { ...prevState!, citationSystems: clonedCitationSystems });
      }
    },
    [publicationInfo?.citationSystems],
  );

  const removeCitationSystem = useCallback(
    (citationSystemId: string) => {
      if (publicationInfo && publicationInfo.citationSystems) {
        setPublicationInfo(prevState => ({
          ...prevState!,
          citationSystems: [
            ...prevState!.citationSystems.filter(
              (system: Form.CitationSystem) => system.publicationCitationSystemId !== citationSystemId,
            ),
          ],
        }));
      }
    },
    [publicationInfo],
  );

  return {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    workMode,
    publicationInfo,
    publicationId,
    handlePublicationSave,
    handlePublicationSaveAndContinue,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    publicationTranslates,
    publicationOriginal,
    relatedTableState,
    changeTranslations: setPublicationTranslates as (translations: Form.Translation[] | null) => void,
    changeOriginal: setPublicationOriginal as (original: Form.Original | null) => void,
    formFields,
    EditionSettings,
    topMessage,
    setTopMessage,
    setPublicationInfo,
  };
}

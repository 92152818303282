import { Table } from 'types/models';
import { Component as Toolbar } from './LeftPanelForThirdLevel';

type Props = {
  securityDocumentId?: string | null;
  isFiltersHidden?: boolean;
};

type Arguments = {
  securityDocumentId?: string | null;
};

export function GetSecurityDocumentIncentivePaymentList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetSecurityDocumentIncentivePaymentList',
    requestData: args,
    isFiltersHidden: args?.isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Список заявок на выплаты авторскому коллективу',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

import { useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps } from 'components';

import { Table, Notification } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = selectedRows[0] || null;

  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [notificationLog, setNotificationLog] = useState<Notification.NotificationLog | null>(null);

  const { methods: getNotificationLog } = BackendAPI.useBackendAPI('GetNotificationLog', {
    onSuccessfullCall: ({ data }) => {
      setNotificationLog(data[0]);
      setIsViewFormOpen(true);
    },
  });
  const { methods: сlearPersonalNotificationLog } = BackendAPI.useBackendAPI('ClearPersonalNotificationLog', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Список уведомлений очищен', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Открыть письмо',
        onClick: () => getNotificationLog.callAPI({ ids: [selectedRow?.id] }),
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'erase' },
        title: 'Очистить весь список',
        onClick: () => сlearPersonalNotificationLog.callAPI({}),
      },
      {
        text: 'Настройка уведомлений',
        mode: ButtonMode.PRIMARY,
        title: 'Правила использования справочника',
        onClick: () => {
          window.location.hash = '/account/profile/notifications';
        },
      },
    ],
    [getNotificationLog, selectedRow?.id, selectedRows.length, сlearPersonalNotificationLog],
  );

  return {
    buttons,
    isHelpOpen,
    setIsHelpOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    notificationLog,
    setNotificationLog,
  };
}

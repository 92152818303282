import { EventTypeCode } from 'types/models/Event';

export type State = {
  isDisableSource: boolean;
  isMagazineReleasesPlanned: boolean;
  isCompilationsPlanned: boolean;
  eventType: EventTypeCode;
};

type Props = {
  isDisableSource?: boolean;
  isMagazineReleasesPlanned?: boolean;
  isCompilationsPlanned?: boolean;
  eventType: EventTypeCode;
};

export function makeUseCustomController({
  isDisableSource = false,
  isMagazineReleasesPlanned = false,
  isCompilationsPlanned = false,
  eventType,
}: Props) {
  return function useCustomController(): State {
    return {
      isDisableSource,
      isMagazineReleasesPlanned,
      isCompilationsPlanned,
      eventType,
    };
  };
}

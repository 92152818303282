import React from 'react';

import { FormComponent, SectionTitle } from 'components';

import { Table } from 'types/models';
import { PublicationMobileRequest, PublicationProject } from 'types/models/Form';
import { Projects, Requests } from 'features/Form/views';

type Props = {
  relatedTableState: Table.State | undefined;
  workMode: Table.WorkMode;
  projects: PublicationProject[];
  setProjects: (rows: PublicationProject[]) => void;
  mobileRequests: PublicationMobileRequest[];
  setMobileRequests: (rows: PublicationMobileRequest[]) => void;
};

function Events({ relatedTableState, workMode, projects, setProjects, mobileRequests, setMobileRequests }: Props) {
  return (
    <>
      <SectionTitle title="Опубликовано при финансовой поддержке" />

      <FormComponent.Line>
        <Projects workMode={workMode} relatedTableState={relatedTableState} projects={projects} setProjects={setProjects} />
      </FormComponent.Line>
      <FormComponent.Line>
        <Requests
          workMode={workMode}
          relatedTableState={relatedTableState}
          requests={mobileRequests}
          setMobileRequests={setMobileRequests}
        />
      </FormComponent.Line>
    </>
  );
}

export const Component = React.memo(Events);

import {
  Collapse,
  FormComponent,
  ListEdit,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadImage,
} from 'components';
import { Equip, ReferenceItem } from 'types/models';
import { Item } from 'types/models/common';
import { showNotification } from 'features/Notifications';
import { SelectPartner } from 'features/Form/views/SelectPartner';
import { kitContentColumns, KitContentFields } from './KitContentFields';

type Props = {
  equip: Equip.Equip;
  setEquip: React.Dispatch<React.SetStateAction<Equip.Equip>>;
  statusOptions: Item[];
  isDisabled: boolean;
};

export function Description({ equip, setEquip, statusOptions, isDisabled }: Props) {
  return (
    <>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <SectionTitle title="Общая информация" />
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Инвентарный номер" labelSize="fit" fieldSize="fit">
              {equip.inventoryNumber}
            </FormComponent.Field>
            <FormComponent.Field label="МОЛ" labelSize="fit" fieldSize="fit">
              {equip.balances.reduce(
                (max, current) => (Number(current.year || 0) > Number(max?.year || 0) || !max ? current : max),
                null as Equip.Balance | null,
              )?.responsible?.fullName || ''}
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: '250px', height: '270px' }}>
          <UploadImage
            text="Фото оборудования"
            id={equip?.photos[0]?.photo?.id}
            onChange={e => setEquip(prev => ({ ...prev, photos: e.name ? [{ id: prev.photos[0]?.id || null, photo: e }] : [] }))}
          />
        </FormComponent.Column>
        <FormComponent.Column style={{ width: 'calc(100% - 250px)' }}>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column>
              <FormComponent.Line>
                <FormComponent.Field label="Наименование" isRequired>
                  <TextInput
                    value={equip.name}
                    onChange={e => setEquip(prev => ({ ...prev, name: e }))}
                    isDisabled={isDisabled}
                    settings={{ maxLength: 1000 }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Наименование по паспорту">
                  <TextInput
                    value={equip.manufacturerName}
                    onChange={e => setEquip(prev => ({ ...prev, manufacturerName: e }))}
                    isDisabled={isDisabled}
                    settings={{ maxLength: 1000 }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Марка, модель">
                  <TextInput
                    value={equip.model}
                    onChange={e => setEquip(prev => ({ ...prev, model: e }))}
                    isDisabled={isDisabled}
                    settings={{ maxLength: 1000 }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Заводской номер">
                  <TextInput
                    value={equip.factoryNumber}
                    onChange={e => setEquip(prev => ({ ...prev, factoryNumber: e }))}
                    isDisabled={isDisabled}
                    settings={{ maxLength: 1000 }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Производитель">
                  <Select
                    mode={SelectMode.REFERENCE}
                    value={equip.manufacturer}
                    onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, manufacturer: e }))}
                    settings={{
                      title: 'Справочник "Производители"',
                      name: 'RefManufacturer',
                      isClearable: true,
                    }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Страна производитель">
                  <Select
                    mode={SelectMode.REFERENCE}
                    value={equip.country}
                    onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, country: e }))}
                    settings={{
                      title: 'Справочник "Страны"',
                      name: 'RefCountry',
                      isClearable: true,
                    }}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
            <FormComponent.Column>
              <FormComponent.Line>
                <FormComponent.Field label="Статус">
                  <Select
                    value={equip.status}
                    options={statusOptions}
                    onChange={(e: Item | null) => setEquip(prev => ({ ...prev, status: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Год выпуска">
                  <TextInput
                    mode={TextInputMode.YEAR}
                    value={equip.manufactureYear}
                    onChange={e => setEquip(prev => ({ ...prev, manufactureYear: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Год закупки">
                  <TextInput
                    mode={TextInputMode.YEAR}
                    value={equip.purchaseYear}
                    onChange={e => setEquip(prev => ({ ...prev, purchaseYear: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата ввода в эксплуатацию">
                  <TextDateTime
                    value={equip.commissioningDate}
                    onChange={e => setEquip(prev => ({ ...prev, commissioningDate: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата выбытия">
                  <TextDateTime
                    value={equip.disposeDate}
                    onChange={e => setEquip(prev => ({ ...prev, disposeDate: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Срок полезного пользования, месяцев">
                  <TextInput
                    mode={TextInputMode.NUMBER}
                    value={equip.usefulPeriod}
                    onChange={e => setEquip(prev => ({ ...prev, usefulPeriod: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Ставка амортизационных отчислений, руб. в час">
                  <TextInput
                    mode={TextInputMode.NUMBER}
                    value={equip.depreciationRate}
                    onChange={e => setEquip(prev => ({ ...prev, depreciationRate: e }))}
                    isDisabled={isDisabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Номер в госреестре">
              <TextInput
                value={equip.stateNumber}
                onChange={e => setEquip(prev => ({ ...prev, stateNumber: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 1000 }}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Ссылка на госреестр">
              <TextInput
                mode={TextInputMode.URL}
                value={equip.stateUrl}
                onChange={e => setEquip(prev => ({ ...prev, stateUrl: e }))}
                isDisabled={isDisabled}
                settings={{ maxLength: 1000 }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Условия предоставления для сотрудников">
          <TextArea
            value={equip.employeesProvisionConditions}
            onChange={e => setEquip(prev => ({ ...prev, employeesProvisionConditions: e }))}
            isDisabled={isDisabled}
            settings={{ rows: 3, maxLength: 1000 }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Условия предоставления для сторонних пользователей">
          <TextArea
            value={equip.outsidersProvisionConditions}
            onChange={e => setEquip(prev => ({ ...prev, outsidersProvisionConditions: e }))}
            isDisabled={isDisabled}
            settings={{ rows: 3, maxLength: 1000 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <Collapse title="Классификаторы" isExpanded>
          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="КНО">
                <Select
                  mode={SelectMode.REFERENCE}
                  value={equip.classificator}
                  onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, classificator: e }))}
                  settings={{
                    title: 'Справочник "Классификаторы научного оборудования"',
                    name: 'RefEquipClassificator',
                    isClearable: true,
                  }}
                  isDisabled={isDisabled}
                />
                <FormComponent.Description mode="default" classMixin="is-small" align="right">
                  {(equip.classificator?.customFields?.parent as ReferenceItem)?.label ||
                    ((equip.classificator as any)?.parent as string) ||
                    ''}
                </FormComponent.Description>
              </FormComponent.Field>
              <FormComponent.Field label="ОКВЭД">
                <Select
                  mode={SelectMode.REFERENCE}
                  value={equip.okved}
                  onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, okved: e }))}
                  settings={{
                    title: 'Справочник "ОКВЭД"',
                    name: 'RefOkved2',
                    isClearable: true,
                  }}
                  isDisabled={isDisabled}
                />
                <FormComponent.Description mode="default" classMixin="is-small" align="right">
                  {(equip.okved?.customFields?.parent as ReferenceItem)?.label || ((equip.okved as any)?.parent as string) || ''}
                </FormComponent.Description>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="ОКПД">
                <Select
                  mode={SelectMode.REFERENCE}
                  value={equip.okpd}
                  onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, okpd: e }))}
                  settings={{
                    title: 'Справочник "ОКПД"',
                    name: 'RefOkpd',
                    isClearable: true,
                  }}
                  isDisabled={isDisabled}
                />
              </FormComponent.Field>
              <FormComponent.Field label="ОКОФ">
                <Select
                  mode={SelectMode.REFERENCE}
                  value={equip.okof}
                  onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, okof: e }))}
                  settings={{
                    title: 'Справочник "ОКОФ"',
                    name: 'RefOkof',
                    isClearable: true,
                  }}
                  isDisabled={isDisabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="КТРУ">
                <Select
                  mode={SelectMode.REFERENCE}
                  value={equip.ktru}
                  onChange={(e: ReferenceItem | null) => setEquip(prev => ({ ...prev, ktru: e }))}
                  settings={{
                    title: 'Справочник "КТРУ"',
                    name: 'RefKtru',
                    isClearable: true,
                  }}
                  isDisabled={isDisabled}
                />
              </FormComponent.Field>
              <FormComponent.Field label="Включено в центр коллективного пользования">
                <SelectPartner
                  partner={equip.ckp}
                  setPartner={e => setEquip(prev => ({ ...prev, ckp: e }))}
                  label="Включено в центр коллективного пользования"
                  showButtons={['add']}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </Collapse>
      </FormComponent.Line>
      <FormComponent.Line>
        <Collapse title="Характеристики" isExpanded>
          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Назначение">
                <TextArea
                  value={equip.purpose}
                  onChange={e => setEquip(prev => ({ ...prev, purpose: e }))}
                  isDisabled={isDisabled}
                  settings={{ maxLength: 10000 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Технические характеристики">
                <TextArea
                  value={equip.specifications}
                  onChange={e => setEquip(prev => ({ ...prev, specifications: e }))}
                  isDisabled={isDisabled}
                  settings={{ rows: 5, maxLength: 10000 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Российские аналоги">
                <TextInput
                  value={equip.analog}
                  onChange={e => setEquip(prev => ({ ...prev, analog: e }))}
                  isDisabled={isDisabled}
                  settings={{ maxLength: 1000 }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </Collapse>
      </FormComponent.Line>
      <FormComponent.Line>
        <Collapse title="Состав комплекта" isExpanded>
          <ListEdit
            rows={equip.kitContents}
            onChange={e => setEquip(prev => ({ ...prev, kitContents: e }))}
            toolbar={['add', 'edit', 'delete']}
            columns={kitContentColumns}
            withMessages
            isDeleteConfirmEnabled
            isDisabled={isDisabled}
            defaultRowsCount={5}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, setRow) => (
                <FormComponent.Wrapper>
                  <KitContentFields row={row} setRow={setRow} />
                </FormComponent.Wrapper>
              ),
              validation: {
                checkIsValid: row => !!row?.name,
                onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
              },
            }}
          />
        </Collapse>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Служебные примечания">
          <TextArea
            value={equip.descriptionNote}
            onChange={e => setEquip(prev => ({ ...prev, descriptionNote: e }))}
            isDisabled={isDisabled}
            settings={{ maxLength: 1000 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

import { ReferenceFilter } from 'components';

import { Table } from 'types/models';
import { makeUseCustomController } from './makeUseCustomController';
import { Filters } from './filters';
import { Editor } from './Editor';
import { Toolbar } from './Toolbar';
import { LastToolbar } from './LastToolbar';
import { DefaultFieldValue, State } from './model';

export type RequestData = {
  filters: ReferenceFilter[];
  name: string;
};

type FirstLevel = Table.Specification<object, RequestData>['header']['firstLevel'];

type Deps = {
  requestData: RequestData;
  defaultValue?: DefaultFieldValue;
  firstLevel?: FirstLevel;
  isCanSelectOnlyOneRow?: boolean;
  isLeftPanelHidden?: boolean;
  removeEdit?: boolean;
  isFacultyFilterChecked?: boolean;
};

export function GetReferenceElementList(deps: Deps, uniqID?: string): Table.Specification<State, RequestData> {
  const isCanSelectOnlyOneRow = deps.isCanSelectOnlyOneRow === undefined ? true : deps.isCanSelectOnlyOneRow;

  return {
    apiID: 'GetReferenceElementList',
    isCanSelectOnlyOneRow,
    requestData: deps.requestData,
    useCustomController: makeUseCustomController({
      name: deps.requestData.name,
      buttonsToHide:
        deps.requestData.name === 'RefDepartment' ? ['add'] : deps.removeEdit ? ['add', 'delete', 'edit', 'view'] : [],
      isFacultyFilter: deps.requestData.name === 'RefDepartment',
      defaultValue: deps.defaultValue,
      isFacultyFilterChecked: deps.isFacultyFilterChecked,
    }),
    isNotInitialLoadTable: true,
    uniqID,
    header: {
      firstLevel: {
        title: '',
        Panel: Filters,
      },
      secondLevel: {
        Component: Editor,
      },
      thirdLevel: {
        ...(!deps.isLeftPanelHidden ? { LeftPanel: Toolbar, LeftLastPanel: LastToolbar } : {}),
        withTemplatesPanel: true,
      },
    },
  };
}

import { Finance } from 'types/models/ProgramRequest';
import { useCallback } from 'react';
import { ReferenceItem } from 'components';

type Props = {
  onFinanceChange: React.Dispatch<React.SetStateAction<Finance | null>>;
};

const mockFinance: Finance = {
  id: null,
  year: '',
  fbAmount: 0,
  sfAmount: 0,
};

export function useController({ onFinanceChange }: Props) {
  const handleYearChange = useCallback(
    (e: string) => {
      onFinanceChange((prevState: Finance | null) => ({ ...(prevState || mockFinance), year: e }));
    },
    [onFinanceChange],
  );

  const handleFbAmoutChange = useCallback(
    (e: number) => {
      onFinanceChange((prevState: Finance | null) => ({ ...(prevState || mockFinance), fbAmount: e }));
    },
    [onFinanceChange],
  );

  const handleSfAmoutChange = useCallback(
    (e: number) => {
      onFinanceChange((prevState: Finance | null) => ({ ...(prevState || mockFinance), sfAmount: e }));
    },
    [onFinanceChange],
  );

  const handleInvestmentSourceChange = useCallback(
    (e: ReferenceItem) => {
      onFinanceChange((prevState: Finance | null) => ({ ...(prevState || mockFinance), investmentSource: e }));
    },
    [onFinanceChange],
  );

  return {
    handleYearChange,
    handleFbAmoutChange,
    handleSfAmoutChange,
    handleInvestmentSourceChange,
  };
}

import React from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { Table } from 'types/models';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { EventTypes } from 'utils/Enums/EventTypes';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants';
import { ConcertGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

const b = block('toolbar');

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isOpenHelpForm,
    setIsOpenHelpForm,
    isApprovePopupOpen,
    handleCloseApprovePopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    controllerIsForm,
    handleTemplateCloseViewForm,
    handleTemplateCloseEditForm,
    nextPublicationName,
    handleTemplateCloseAddForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isCancelMessagePopupOpen,
    publicationMessage,
    publicationMessageType,
    updatePublicationMessage,
    setPublicationMessageType,
    onCancelMessageConfirmClick,
    onCancelMessageConfirmWithoutMessageClick,
    settings,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <div className={b('hidden')}>
          <SuchLikeEvent
            mode="add"
            isSearchable
            handleContinueStep={handleContinueStep}
            command="RefEventFeedlineMessage"
            eventTypeCode={EventTypes.EVENT_CONCERT.code}
          />
        </div>
      )}

      {isViewFormOpen && (
        <modalTemplates.ConcertViewTemplate.Component
          controllerIsForm={controllerIsForm}
          id={tableState.selectedRows[0]?.id}
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
          withHeaderId={false}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.ConcertEditTemplate.Component
          controllerIsForm={controllerIsForm}
          id={tableState.selectedRows[0]?.id}
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          relatedTableState={tableState}
          withHeaderId={false}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.ConcertAddTemplate.Component
          controllerIsForm={controllerIsForm}
          isOpen={isAddFormOpen}
          name={nextPublicationName}
          onClose={handleTemplateCloseAddForm}
          relatedTableState={tableState}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.EventName}) Удалить мероприятие?</>
        </Modal>
      )}

      {isApprovePopupOpen && (
        <ApproveEvent
          typeCode={EventTypes.EVENT_CONCERT.code}
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isApprovePopupOpen}
          onClose={handleCloseApprovePopup}
        />
      )}
      {isCancelMessagePopupOpen && (
        <AdministratorMessagePopup
          title={ADD_PUB_FEEDBACK_LABEL}
          isOpen={isCancelMessagePopupOpen}
          publicationMessage={publicationMessage}
          updatePublicationMessage={updatePublicationMessage}
          publicationMessageType={publicationMessageType}
          setPublicationMessageType={setPublicationMessageType}
          onConfirm={onCancelMessageConfirmClick}
          onConfirmWithoutMessage={onCancelMessageConfirmWithoutMessageClick}
          command="RefEventFeedlineMessage"
        />
      )}

      {isReportOpen && (
        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            department: settings?.userDepartment,
          }}
        />
      )}

      <Modal
        mode="help"
        title="Творческие мероприятия"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ConcertGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { useEffect } from 'react';

import { Table as T, Form as F } from 'types/models';
import { TemplatesTableDependencies } from 'types/models/Table';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { CitationSystemType } from 'types/models/CitationSystem';

export type State = {
  templatesTableDependencies: TemplatesTableDependencies;
  domain: string;
  publicationType: string;
  citationSystemType: CitationSystemType;
  addCitationSystem: (citationSystem: F.CitationSystem) => void;
  editCitationSystem: (citationSystem: F.CitationSystem) => void;
  removeCitationSystem: (citationSystemId: string) => void;
  workMode?: T.WorkMode;
};

export type Mode = 'add' | 'edit' | 'view';

export function makeUseCustomController(
  templatesTableDependencies: TemplatesTableDependencies,
  domain: string,
  publicationType: string,
  citationSystemType: CitationSystemType,
  addCitationSystem: (citationSystem: F.CitationSystem) => void,
  editCitationSystem: (citationSystem: F.CitationSystem) => void,
  removeCitationSystem: (citationSystemId: string) => void,
  workMode?: T.WorkMode,
  setTopMessage?: (topMessage: string) => void,
) {
  return function useCustomController({ dataCallStateKind, entryPages }: T.UseCustomControllerProps): State {
    useEffect(() => {
      if (
        dataCallStateKind === 'successfull' &&
        setTopMessage &&
        (citationSystemType === 'CHILD_GRID' || citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code)
      )
        if (entryPages) {
          if (!entryPages.length) {
            if (citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code)
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у журнала нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация (журнал)',
              );
            else if (publicationType === 'MONOGRAPH_CHAPTER') {
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у монографии нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
            } else if (publicationType === 'ARTICLE_OTHER') {
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у издания нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
            } else
              setTopMessage(
                // eslint-disable-next-line max-len
                'Сведения об индексации статьи в базах цитирования невозможно добавить, так как у сборника нет данных об индексации в базах цитирования. Добавьте сообщение для библиографа в каких базах цитирования индексируется публикация',
              );
          } else if (!entryPages.some(i => !!i.id))
            setTopMessage(
              // eslint-disable-next-line max-len
              'Невозможно добавить значение наукометрических показателей для публикации без Идентификатора БЦ. Добавьте сведения о публикации из базы цитирования: Идентификатор в БЦ, Тип публикации и Индексируемые ключевые слова в таблицу "Индексация в базах цитирования" с помощью кнопки "Редактировать"',
            );
          else setTopMessage('');
        } else setTopMessage('');
    }, [dataCallStateKind, entryPages]);

    return {
      templatesTableDependencies,
      domain,
      publicationType,
      citationSystemType,
      addCitationSystem,
      editCitationSystem,
      removeCitationSystem,
      workMode,
    };
  };
}

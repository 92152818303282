import React, { useCallback, useMemo } from 'react';

import { FormComponent, ListEdit, SectionTitle } from 'components';

import { MipPartner } from 'types/models';
import { getMockFounder, getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { Fields } from '../Fields/Fields';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  charterCapitals: MipPartner.CharterCapital[];
  selectedRowIndex: number | null;
};

export const Founders = ({ charterCapitals, selectedRowIndex }: Props) => {
  const { settings } = useAppDataContext();

  const currentCharterCapital = useMemo<MipPartner.CharterCapital | null>(
    () => (selectedRowIndex !== null ? charterCapitals[selectedRowIndex] : null),
    [charterCapitals, selectedRowIndex],
  );
  const founders = useMemo<MipPartner.Founder[]>(() => (currentCharterCapital ? currentCharterCapital.founders : []), [
    currentCharterCapital,
  ]);

  const amount = useMemo<string>(() => (currentCharterCapital ? currentCharterCapital.amount : '0.00'), [currentCharterCapital]);

  const formatFounder = useCallback(
    (p: MipPartner.Founder) => {
      if (p.isLocal) {
        return settings?.organization?.shortName || '';
      }

      return p.scientist
        ? `${p.scientist.fullName} (${p.passport})`
        : // eslint-disable-next-line max-len
          `${p.enterpriseCustomer?.customFields?.fullName} (${p.enterpriseCustomer?.label}), ИНН: ${p.enterpriseCustomer?.customFields?.inn}, КПП: ${p.enterpriseCustomer?.customFields?.kpp}`;
    },
    [settings?.organization?.shortName],
  );

  return (
    <>
      <SectionTitle title="Учредители" />

      <ListEdit
        rows={founders}
        onChange={() => {}}
        toolbar={['view']}
        columns={[
          { label: 'ФИО/Название организации', formatValue: formatFounder, styles: { width: '40%' } },
          {
            label: 'Доля в УК, %',
            formatValue: x => getPercentOfAmountByPart({ part: x.amount, amount }),
            styles: { width: '15%' },
            dataKind: 'float',
          },
          { label: 'Сумма, руб.', formatValue: x => x.amount, styles: { width: '15%' }, dataKind: 'float' },
          { label: 'Активы', formatValue: x => x.insertionAssets || <em>Не указано</em>, styles: { width: '30%' } },
        ]}
        defaultRowsCount={5}
        columnIndexesForSumTotal={[1, 2]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Учредитель',
          isToolbarHidden: true,
          renderComponent: (founder, setFounder) => (
            <FormComponent.Wrapper>
              <FormComponent.Template>
                <Fields founder={founder ?? getMockFounder()} setFounder={setFounder} amount={amount} isDisabled />
              </FormComponent.Template>
            </FormComponent.Wrapper>
          ),
        }}
      />
    </>
  );
};

import { ValueOf } from 'types/helpers';
import { Project } from 'types/models';

type Arguments = {
  financing: Project.Financing;
  setFinancing(financing: Project.Financing): void;
};

export function useController({ financing, setFinancing }: Arguments) {
  const makeChangeHandler = (key: keyof Project.Financing) => (value: ValueOf<Project.Financing>) => {
    setFinancing({ ...financing, [key]: value });
  };

  return {
    makeChangeHandler,
  };
}

import React from 'react';

import { FormComponent, Toolbar, Select, SelectMode, ReferenceItem, TextInput } from 'components';

import { useController } from './controller';
import { SelectPerson } from 'features/SelectPerson';
import { getMockMember } from 'features/SelectPerson/helpers';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function EquipVerificationContactForm({ viewMode, onClose }: Props) {
  const { equipVerificationContact, setEquipVerificationcontact, buttons } = useController({
    viewMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />
      <FormComponent.Template>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field label="Персона" isRequired>
              <SelectPerson
                member={{ ...getMockMember(), person: equipVerificationContact.scientist || null }}
                onUpdateMember={e => setEquipVerificationcontact(prev => ({ ...prev, scientist: e?.person || null }))}
                memberLabel={equipVerificationContact?.scientist?.fullName}
                withHistory={false}
                disabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение">
              <Select
                mode={SelectMode.REFERENCE}
                value={equipVerificationContact.department}
                onChange={(e: ReferenceItem | null) => setEquipVerificationcontact(prev => ({ ...prev, department: e }))}
                settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Код">
              <TextInput
                value={equipVerificationContact.code}
                onChange={e => setEquipVerificationcontact(prev => ({ ...prev, code: e }))}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(EquipVerificationContactForm);

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps } from 'components';

import { Table, Report } from 'types/models';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isOpenCalcConfirmPopupOpen, setIsOpenCalcConfirmPopupOpen] = useState<boolean>(false);
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const { methods: calculateProjectRestrictionsAPI } = BackendAPI.useBackendAPI('CalculateProjectRestrictions');

  const uniqProjects = useMemo(() => {
    return tableState.selectedRows.filter((x, index) => index === tableState.selectedRows.findIndex(i => x.id === i.id));
  }, [tableState.selectedRows]);

  const handleCalculateProjectRestrictions = useCallback(() => {
    let projectIds = tableState.selectedRows.map(i => i.id);
    projectIds = projectIds.filter((i, index) => projectIds.findIndex(k => k === i) === index);

    calculateProjectRestrictionsAPI.callAPI(
      { projectIds: uniqProjects.map(i => i.id) },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Расчёт выполнен', theme: 'success' });
          setIsOpenCalcConfirmPopupOpen(false);
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [calculateProjectRestrictionsAPI, tableState.selectedRows, tableStreams.reloadTable, uniqProjects]);

  const reports = useMemo<Report[]>(() => [Reports.ProjectsViolateTeamRequirements_3_Months], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        text: 'Выполнить расчёт',
        mode: ButtonMode.PRIMARY,
        title: 'Выполнить расчёт',
        onClick: () => setIsOpenCalcConfirmPopupOpen(true),
        isDisabled: tableState.selectedRows.length < 1,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [getReports, handleSetCurrentReport, tableState.selectedRows.length],
  );

  return {
    buttons,
    uniqProjects,
    isOpenCalcConfirmPopupOpen,
    setIsOpenCalcConfirmPopupOpen,
    handleCalculateProjectRestrictions,
    isHelpOpen,
    setIsHelpOpen,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

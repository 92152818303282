import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { TypeEditionCode } from 'utils/Enums/TypeEditionCode';
import { useAppDataContext } from 'features/AppData/context';
import { Item as ItemOption } from 'types/models/common';
import { showNotification } from 'features/Notifications';

type Item = {
  kind: string;
  code: TypeEditionCode;
  share: number;
};

export function useController() {
  const { settings } = useAppDataContext();

  const CURRENT_YEAR: number = new Date().getFullYear();

  const [rusAndOtherByPeriod, setRusAndOtherByPeriod] = useState<Item[]>([]);
  const [modalTitle, setModalTitle] = useState<string>('');
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState(false);
  const [actualType, setActualType] = useState<TypeEditionCode | null>(null);
  const [dateFrom, setDateFrom] = useState<string>((CURRENT_YEAR - 3).toString());
  const [dateTo, setDateTo] = useState<string>((CURRENT_YEAR - 1).toString());
  const [requestDates, setRequestDates] = useState<string[]>([]);

  const dateTypeList = useMemo<ItemOption[]>(
    () => [
      { label: 'Период', value: 'PERIOD' },
      { label: 'Год', value: 'DATE' },
    ],
    [],
  );

  const [dateType, setDateType] = useState<ItemOption>(dateTypeList[0]);

  const { methods: getDashboardChartByTypeEditionMagazineArticleListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByTypeEditionMagazineArticleListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        setIsLoading(false);
        const chartAttrRes = data.Response.Grid.GroupingRows.TotalRow._attributes;
        const qtyForeignTypeEdition = Number(chartAttrRes.IsForeignTypeEdition);
        const qtyIsLocalTypeEdition = Number(chartAttrRes.IsLocalTypeEdition);
        const qtyIsOtherUniversityTypeEdition = Number(chartAttrRes.IsOtherUniversityTypeEdition);
        const qtyIsOtherTypeEdition = Number(chartAttrRes.IsOtherTypeEdition);
        const qtyIsRsaTypeEdition = Number(chartAttrRes.IsRsaTypeEdition);

        setRusAndOtherByPeriod([
          {
            kind: 'Доля публикаций в зарубежных журналах',
            code: TypeEditionCode.FOREIGN,
            share: qtyForeignTypeEdition,
          },
          {
            kind: `Доля публикаций в журналах ${settings?.organization?.shortName}`,
            code: TypeEditionCode.LOCAL,
            share: qtyIsLocalTypeEdition,
          },
          {
            kind: 'Доля публикаций в изданиях РАН',
            code: TypeEditionCode.RSA,
            share: qtyIsRsaTypeEdition,
          },
          {
            kind: 'Доля публикаций в журналах других университетов',
            code: TypeEditionCode.OTHER_UNIVERSITY,
            share: qtyIsOtherUniversityTypeEdition,
          },
          {
            kind: 'Доля публикаций в других журналах',
            code: TypeEditionCode.OTHER,
            share: qtyIsOtherTypeEdition,
          },
        ]);
      },
      onFailedCall: () => {
        setIsLoading(false);
      },
    },
  );

  useEffect(() => {
    if (dateType.value === 'PERIOD') {
      setDateFrom((CURRENT_YEAR - 3).toString());
      setDateTo((CURRENT_YEAR - 1).toString());
    } else {
      setDateFrom(CURRENT_YEAR.toString());
    }
  }, [CURRENT_YEAR, dateType]);

  useEffect(() => {
    if (dateType.value === 'PERIOD' && dateFrom && dateTo && Number(dateFrom) > Number(dateTo)) {
      showNotification({
        message: 'Год начала диапазона больше года конца диапазона.<br>Выбрано по году начала диапазона',
        theme: 'warning',
      });
      setRequestDates([dateFrom, dateFrom]);
      return;
    }
    setRequestDates([dateFrom, ...(dateType.value === 'PERIOD' ? [dateTo ? dateTo : dateFrom] : [dateFrom])]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateFrom, dateTo]);

  useEffect(() => {
    if (requestDates[0] && requestDates[1]) {
      setIsLoading(true);
      getDashboardChartByTypeEditionMagazineArticleListStatic.callAPI({
        startYear: requestDates[0],
        endYear: requestDates[1],
        rowQuantity: 1,
        offset: 0,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requestDates]);

  const getNumberSumResult = useCallback(() => {
    let sum = 0;
    rusAndOtherByPeriod.forEach((item: any) => {
      sum += item.share;
    });
    return sum;
  }, [rusAndOtherByPeriod]);

  const getChartSumResult = useCallback(() => {
    return new Intl.NumberFormat().format(getNumberSumResult());
  }, [getNumberSumResult]);

  const seriesClickHandler = useCallback(
    (item: Item) => {
      setModalTitle(
        `${item.kind} по дате: ${requestDates[0]}${requestDates[0] === requestDates[1] ? '' : `- ${requestDates[1]}`}`,
      );
      setActualType(item.code);
      setIsModalOpen(true);
    },
    [requestDates],
  );

  return {
    dateType,
    setDateType,
    dateTypeList,
    dateFrom,
    setDateFrom,
    dateTo,
    setDateTo,
    requestDates,
    modalTitle,
    isModalOpen,
    setIsModalOpen,
    actualType,
    isLoading,
    seriesClickHandler,
    rusAndOtherByPeriod,
    getChartSumResult,
    getNumberSumResult,
  };
}

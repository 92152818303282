import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ListEdit, ReferenceItem } from 'components';
import { Column } from 'components/ListEdit/model';
import { Props as ListEditProps } from 'components/ListEdit/ListEdit';

import { RefElement } from 'types/models/Project';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { getCurrentTime } from 'utils/Helpers';
import { FieldVisibleMode } from 'utils/Enums';

type OptionalProps = 'columns' | 'onChange';

type Props = {
  modalTitle: string;
  refName: string;
  columns?: Column<RefElement>[];
  subReferenceFieldVisibleMode?: FieldVisibleMode;
  isActual?: boolean;
  isProject?: boolean;
} & Omit<ListEditProps<{}, RefElement>, 'specification' | OptionalProps> &
  Partial<Pick<ListEditProps<{}, RefElement>, OptionalProps>>;

export function RefElementList(props: Props) {
  const {
    modalTitle,
    onChange,
    refName,
    rows,

    columns,

    subReferenceFieldVisibleMode,
    isActual = true,
    isProject = true,
    ...restProps
  } = props;

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const { currentPerson } = useAppDataContext();

  return (
    <FormComponent.Line>
      <ListEdit
        {...restProps}
        rows={rows}
        onChange={e => onChange?.(e)}
        columns={
          columns || [
            { label: 'Наименование', formatValue: (x: RefElement) => x.element?.label ?? '', styles: { width: '75%' } },
            { label: 'Создано', formatValue: (x: RefElement) => `${x.createdBy} ${x.createdDate}`, styles: { width: '25%' } },
          ]
        }
        defaultRowsCount={3}
        specification={{
          mode: 'loadInstanceTableModal',
          onStartLoad: (tableRows, onSuccess) => {
            loadReferenceAPI.callAPI(
              { filters: [], referenceName: refName, childIds: tableRows.map(x => x.id), subReferenceFieldVisibleMode },
              {
                onSuccessfullCall: ({ data }) => {
                  const refElements = (data as ReferenceItem[])
                    .filter(x => !rows.find(row => row.element?.id === x.id))
                    .map<RefElement>(x => ({
                      id: null,
                      element: x,
                      position: '0',
                      createdBy: currentPerson?.fullName ?? '',
                      createdDate: getCurrentTime(),
                      isActual,
                      isProject,
                    }));
                  onSuccess(refElements);
                },
              },
            );
          },
          relationTableModalTitle: modalTitle,
          modalTableSpecification: GetReferenceElementList({ requestData: { name: refName, filters: [] } }),
        }}
      />
    </FormComponent.Line>
  );
}

import React from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, Select, SelectMode } from 'components';
import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ProjectReportBlock } from 'utils/Enums';

import { useController } from './controller';

type Props = {
  isOpen: boolean;
  onClose(): void;
  onSubmit(stageNumber: string, reportType: Item, isStateRegistration: boolean): void;
  project: Project.Project;
  visibleBlock?: ProjectReportBlock;
};

export function AddModal(props: Props) {
  const { isOpen, onClose, onSubmit, project, visibleBlock } = props;

  const {
    options,
    reportTypeOption,
    stageNumberOption,
    handleSubmit,
    setReportTypeOption,
    handleStageChange,
    isStateRegistration,
    setIsStateRegistration,
  } = useController({
    onClose,
    onSubmit,
    project,
    visibleBlock,
  });

  return (
    <Modal
      title="Добавьте планируемые сроки по отчёту проекта"
      isOpen={isOpen}
      onClose={onClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Добавить',
          onClick: handleSubmit,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: onClose,
        },
      ]}
      size="medium"
    >
      <FormComponent.Line>
        <FormComponent.Field isRequired label="Выберите этап проекта">
          <Select value={stageNumberOption} options={options} onChange={handleStageChange} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Тип отчёта" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={reportTypeOption}
            settings={{ name: 'ProjectReportType' }}
            onChange={(option: Item) => setReportTypeOption(option)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Госрегистрация">
          <Checkbox checked={isStateRegistration} onChange={setIsStateRegistration} isDisabled={!!visibleBlock} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Description mode="warning">
        Внимание: в подсистему мониторинга и уведомлений будут добавлены ключевые даты сроков подготовки и государственной
        регистрации отчёта.
      </FormComponent.Description>
    </Modal>
  );
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useController as useChangeStatusController } from 'features/Form/looks/user/UserEditForm/changeStatusController';
import { User } from 'types/models/User';
import { useAppDataContext } from 'features/AppData/context';
import { deleteUserOnPortal } from 'utils/Helpers/portalUserMethods';
import { UserStatusTypes } from 'utils/Enums/UserStatusTypes';

type Props = {
  tableState: Table.State;
};

type ButtonsAvailability = {
  isEditButtonDisabled: boolean;
  isDeleteButtonDisabled: boolean;
  isPermissionButtonDisabled: boolean;
};

export function useController({ tableState }: Props) {
  const tableStreams = useLocalTableStreams();

  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isPermissionFormOpen, setIsPermissionFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const { changeUserStatus } = useChangeStatusController();
  const { userToken } = useAppDataContext();

  const buttonsAvailability = useMemo<ButtonsAvailability>(() => {
    const selectedRowsLength = tableState.selectedRows.length;
    return {
      isEditButtonDisabled: selectedRowsLength !== 1,
      isDeleteButtonDisabled: selectedRowsLength !== 1,
      isPermissionButtonDisabled: selectedRowsLength !== 1 || tableState.selectedRows[0]['id:Status'] !== UserStatusTypes.LOCAL,
    };
  }, [tableState.selectedRows]);

  const { isEditButtonDisabled, isDeleteButtonDisabled, isPermissionButtonDisabled } = buttonsAvailability;

  const handleEditButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      setIsEditFormOpen(true);
    } else {
      showNotification({ message: 'Выберите пользователя', theme: 'danger' });
    }
  }, [tableState]);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handlePermissionButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0 && tableState.selectedRows.length < 2) {
      setIsPermissionFormOpen(true);
    } else {
      showNotification({ message: 'Выберите пользователя', theme: 'danger' });
    }
  }, [tableState]);

  const handleTemplateClosePermissionForm = useCallback(() => {
    setIsPermissionFormOpen(false);
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const convertUserRow = useCallback(
    (user: any): User => ({
      login: user.Login,
      userFullName: user.UserFullName,
      password: '',
      status: user['id:Status'],
      email: user.Email,
    }),
    [],
  );

  const { methods: getUser } = BackendAPI.useBackendAPI('GetUser');

  const loadAndUpdateUser = useCallback(
    (userLogin: string) => {
      getUser.callAPI(
        { login: userLogin },
        {
          onSuccessfullCall: ({ data }) => {
            changeUserStatus(data, true, () => {
              showNotification({ message: 'Пользователь активирован', theme: 'success' });
              tableStreams.reloadTable.push({});
            });
          },
        },
      );
    },
    [getUser, changeUserStatus, tableStreams.reloadTable],
  );

  const handleDeactivateButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0) {
      tableState.selectedRows.forEach(row => {
        changeUserStatus(convertUserRow(row), false, () => {
          showNotification({ message: 'Пользователь деактивирован', theme: 'success' });
          tableStreams.reloadTable.push({});
        });
      });
    } else {
      showNotification({ message: 'Выберите пользователя', theme: 'danger' });
    }
  }, [tableState.selectedRows, changeUserStatus, convertUserRow, tableStreams.reloadTable]);

  const handleActivateButtonClick = useCallback(() => {
    if (tableState.selectedRows.length > 0) {
      tableState.selectedRows.forEach(row => {
        loadAndUpdateUser(row.Login);
      });
    } else {
      showNotification({ message: 'Выберите пользователя', theme: 'danger' });
    }
  }, [tableState, loadAndUpdateUser]);

  const { methods: deleteUser } = BackendAPI.useBackendAPI('DeleteUser');

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    if (
      tableState.selectedRows[0]['id:Status'] === UserStatusTypes.LOCAL ||
      tableState.selectedRows[0]['id:Status'] === UserStatusTypes.PORTAL
    ) {
      if (tableState.selectedRows[0]['id:Status'] === UserStatusTypes.LOCAL) {
        deleteUser.callAPI(
          { id: tableState.selectedRows[0]?.id },
          {
            onSuccessfullCall: () => {
              showNotification({ message: 'Пользователь удален', theme: 'success' });
            },
          },
        );
      }

      const onUserDelete = deleteUserOnPortal(tableState.selectedRows[0]?.Login, userToken);

      onUserDelete
        .then(() => {
          showNotification({ message: 'Пользователь удален', theme: 'success' });
        })
        .catch(() => {
          showNotification({ message: 'Ошибка удаления пользователя', theme: 'danger' });
        });

      tableStreams.reloadTable.push({});
    } else {
      showNotification({ message: 'Заявки не удаляются', theme: 'danger' });
    }
    handleCloseDeleteConfirmPopup();
  }, [deleteUser, handleCloseDeleteConfirmPopup, tableState.selectedRows, tableStreams.reloadTable, userToken]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Активировать',
        onClick: handleActivateButtonClick,
        isDisabled:
          tableState.selectedRows.length === 0 ||
          (tableState.selectedRows.length === 1 && tableState.selectedRows[0]?.Active === 'true'),
      },
      {
        icon: { type: 'activate', mode: 'remove' },
        title: 'Деактивировать',
        onClick: handleDeactivateButtonClick,
        isDisabled:
          tableState.selectedRows.length === 0 ||
          (tableState.selectedRows.length === 1 && tableState.selectedRows[0]?.Active === 'false'),
      },
      {
        icon: { type: 'info' },
        title: 'Роли и права',
        onClick: handlePermissionButtonClick,
        isDisabled: isPermissionButtonDisabled,
      },
    ],
    [
      isEditButtonDisabled,
      handleEditButtonClick,
      tableState.selectedRows,
      handleActivateButtonClick,
      handleDeactivateButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isPermissionButtonDisabled,
      handlePermissionButtonClick,
    ],
  );

  return {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseEditForm,
    handleTemplateClosePermissionForm,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isPermissionFormOpen,
  };
}

import React from 'react';

import { Checkbox, FormComponent, SectionTitle, ListEdit, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { formatNumber } from 'utils/Helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { showErrorsMessages } from 'utils/Common';
import { Estimate } from 'types/models/Estimate';
import { Table } from 'types/models';

import { TAX, useController } from './controller';
import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function EstimatePositionForm({ viewMode, editMode, onClose }: Props) {
  const {
    estimate,
    kindConsumptionEditMode,
    handleFormSubmit,
    kindConsumptionSpecification,
    kindConsumptionTableRowConverter,
    kindConsumptionOriginal,
    kvrLabel,
    kosguLabel,
    formFields,
    overheadPercent,
    availableFundCardSpecification,
    availableFundCardTableRowConverter,
    availableFundCardOriginal,
    taxKindConsumptionOriginal,
    taxKvrLabel,
    taxKosguLabel,
    estimatePosition,
    handlePositionItemChange,
    setMaxValue,
  } = useController({ viewMode, editMode, onClose });

  const itemsComponent = () => (
    <ListEdit
      header={{ title: 'План расходования средств' }}
      rows={estimatePosition?.items || []}
      onChange={handlePositionItemChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Дата', formatValue: x => x?.date || '', styles: { width: '20%' } },
        {
          label: 'Сумма, план',
          formatValue: x => x?.amount || '',
          styles: { width: '20%' },
          dataKind: 'float',
        },
        {
          label: 'Примечание',
          formatValue: x => x?.note || '',
          styles: { width: '60%' },
        },
      ]}
      withMessages
      isDeleteConfirmEnabled
      isDisabled={false}
      maxHeight="none"
      columnIndexesForSumTotal={[1]}
      defaultRowsCount={4}
      specification={{
        mode: 'customComponent',
        renderComponent: (item, setItem) => <Fields item={item} setItem={setItem} />,
        validation: {
          checkIsValid: (indicator, index, list, mode) =>
            validate(indicator, index, list, mode, estimate as Estimate).every(x => x.isValid),
          onInvalidate: (indicator, mode, index, list) => {
            const validationInfo = validate(indicator, index, list, mode, estimate as Estimate);
            showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );

  const tooltips = {
    amount:
      // eslint-disable-next-line prettier/prettier, max-len
      'Кнопка "Посчитать" автоматически рассчитывает сумму накладных по нормативному проценту, указанному в проекте, с учётом сумм по видам расходов с атрибутом "Уменьшает накладные". Вы можете ввести произвольную сумму накладных расходов, в этом случае, автоматически рассчитывается процент. Сумма будет сохранена в карточке Централизованного фонда как Сумма, план. При поступлении средств на шифр автоматически будет проставлена Сумма, факт = Сумма документа прихода * Расчётный процент накладных расходов.',

    fundCard:
      // eslint-disable-next-line prettier/prettier, max-len
      'Шифр для накладных расходов может быть установлена для Шифра проекта (значение по умолчанию). Вы можете выбрать другую карточку из списка. Информация о накладных расходах будет сохранена в карточке Централизованного фонда как Сумма, план. При поступлении средств на шифр автоматически будет рассчитана Сумма, факт = Сумма документа прихода * Расчётный процент накладных расходов.',
  };

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.kindConsumption.title} isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: formFields.kindConsumption.title,
                isClearable: false,
                table: {
                  specification: kindConsumptionSpecification,
                  onSelect: (row: Table.Entry | null) =>
                    formFields.kindConsumption.onChange(row ? kindConsumptionTableRowConverter(row) : null),
                },
              }}
              value={kindConsumptionOriginal?.name}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КВР">
            <FormComponent.Text>{kvrLabel}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="КОСГУ">
            <FormComponent.Text>{kosguLabel}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: '30%', minWidth: '360px' }}>
              <FormComponent.Field
                label={formFields.amount.title}
                isRequired
                tooltip={kindConsumptionEditMode === TAX.OVERHEAD ? tooltips.amount : ''}
              >
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={formFields?.amount.value || ''}
                  onChange={formFields.amount.onChange}
                  externalButtons={[
                    {
                      icon: { type: 'calc' },
                      title: 'Посчитать сумму накладных по нормативному проценту',
                      isHidden: kindConsumptionEditMode !== TAX.OVERHEAD,
                      onClick: setMaxValue,
                    },
                  ]}
                />
              </FormComponent.Field>
            </FormComponent.Column>
            <FormComponent.Column style={{ width: '70%' }}>
              {kindConsumptionEditMode === TAX.OVERHEAD && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field>
                      <>Расчетный процент накладных {formatNumber(overheadPercent)} %</>
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}
              {(kindConsumptionEditMode === TAX.DEFAULT || kindConsumptionEditMode === TAX.TAX) && (
                <FormComponent.Field label={formFields.isReduceOverhead.title}>
                  <Checkbox
                    checked={!!formFields?.isReduceOverhead.value}
                    onChange={formFields.isReduceOverhead.onChange}
                    isDisabled={viewMode}
                  />
                </FormComponent.Field>
              )}
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>
        </FormComponent.Line>
        {kindConsumptionEditMode === TAX.OVERHEAD && (
          <FormComponent.Line>
            <FormComponent.Field label={formFields.fundCard.title} tooltip={tooltips.fundCard}>
              <TextArea
                mode={TextAreaMode.TABLE}
                settings={{
                  title: formFields.fundCard.title,
                  table: {
                    specification: availableFundCardSpecification,
                    onSelect: (row: Table.Entry | null) =>
                      formFields.fundCard.onChange(row ? availableFundCardTableRowConverter(row) : null),
                  },
                }}
                value={availableFundCardOriginal?.name}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        <FormComponent.Line>
          <FormComponent.Field label={formFields.note.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.note.title }}
              value={formFields.note.value}
              onChange={formFields.note.onChange}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>{itemsComponent()}</FormComponent.Field>
        </FormComponent.Line>
        {kindConsumptionEditMode === TAX.TAX && (
          <>
            <SectionTitle title="Налог" />
            <FormComponent.Line>
              <FormComponent.Field label="Вид расхода, налог" isRequired>
                <TextArea
                  settings={{
                    title: 'Виды расхода',
                    isFixed: true,
                  }}
                  value={taxKindConsumptionOriginal?.name}
                  isDisabled
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="КВР">
                <FormComponent.Text>{taxKvrLabel}</FormComponent.Text>
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="КОСГУ">
                <FormComponent.Text>{taxKosguLabel}</FormComponent.Text>
              </FormComponent.Field>
            </FormComponent.Line>

            <FormComponent.Line>
              <FormComponent.Field label={formFields.taxPercent.title} isRequired>
                <TextInput
                  mode={TextInputMode.NUMBER}
                  value={formFields?.taxPercent.value || ''}
                  onChange={formFields.taxPercent.onChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.ColumnWrapper>
                <FormComponent.Column style={{ width: '30%', minWidth: '200px' }}>
                  <FormComponent.Field label={formFields.taxAmount.title} isRequired>
                    <TextInput
                      mode={TextInputMode.NUMBER}
                      value={formFields?.taxAmount.value || ''}
                      onChange={formFields.taxAmount.onChange}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
                <FormComponent.Column style={{ width: '70%' }}>
                  <FormComponent.Field label={formFields.taxIsReduceOverhead.title}>
                    <Checkbox
                      checked={!!formFields?.taxIsReduceOverhead.value}
                      onChange={formFields.taxIsReduceOverhead.onChange}
                      isDisabled={viewMode}
                    />
                  </FormComponent.Field>
                </FormComponent.Column>
              </FormComponent.ColumnWrapper>
            </FormComponent.Line>
          </>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(EstimatePositionForm);

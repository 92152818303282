import { Template } from 'types/models/Form';

type RequestType = {
  label: string;
  labelDeclined: string;
  code: string;
  template?: Template;
};

export class RequestTypes {
  static REQUEST: RequestType = {
    label: 'Заявка на конкурс программы',
    labelDeclined: 'заявки',
    code: 'REQUEST',
    template: 'ProgramRequestForm',
  };

  static CONTEST_REQUEST: RequestType = {
    label: 'Заявка на продление проекта',
    labelDeclined: 'заявки на продление проекта',
    code: 'CONTEST_REQUEST',
    template: 'ContestRequestForm',
  };

  static NIR_REQUEST: RequestType = {
    label: 'Заявка на внутренний конкурс',
    labelDeclined: 'заявки на внутренний конкурс',
    code: 'NIR_REQUEST',
  };
}

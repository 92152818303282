import { Table as T } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export function GetEquipVerificationContactList(): T.Specification {
  return {
    apiID: 'GetEquipVerificationContactList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: true,
      },
    },
  };
}

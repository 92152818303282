import { Event, Award } from 'types/models';
import { fillPersonToRequest } from '../author/requestConverters';
import { SaveEventUserFeedlineRequest, EventFeedline } from './types';
import { EventProject } from 'types/models/Event';
import { convertReferenceItemToXML, getMaybeField } from '../commonConverters';

export function convertEventFeedlineToRequest(eventFeedline?: EventFeedline) {
  if (!eventFeedline) {
    return {};
  }

  return {
    EventFeedline: {
      Message: { _attr: { id: eventFeedline.messageId } },
      Detail: eventFeedline.detail,
    },
  };
}

export function convertAmountsToRequest(amounts: Event.MemberCountAnother[] | undefined) {
  if (!amounts || !amounts.length) {
    return { Amounts: {} };
  }
  return {
    Amounts: {
      Amount: amounts.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
        Amount: { _text: doc.amount },
        Professors: { _text: doc.professors },
        Students: { _text: doc.students },
        City: { _text: doc.city },
        Country: { _attr: { id: doc.country?.id } },
        Enterprise: { _attr: { id: doc.enterprise?.id } },
        ...(doc?.department?.id ? { Department: { _attr: { id: doc.department?.id } } } : {}),
      })),
    },
  };
}

export function convertCommitteMembersToRequest(members: Event.Member[] | undefined) {
  if (!members || !members.length) {
    return { CommitteMembers: {} };
  }
  return {
    CommitteMembers: {
      CommitteMember: members.map((author, position) => fillPersonToRequest(author, position)),
    },
  };
}
export function convertMemberCountsToRequest(members: Event.MemberCount[] | undefined) {
  if (!members || !members.length) {
    return { MemberCounts: {} };
  }
  return {
    MemberCounts: {
      MemberCount: members.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
        Type: {
          _text: doc.type,
        },
        Amount: {
          _text: doc.amount,
        },
        ...convertAmountsToRequest(doc.amounts),
      })),
    },
  };
}

export const convertSaveEventUserFeedlineToRequest = (props: SaveEventUserFeedlineRequest) => {
  const eventFeedlineAttr = { _attr: props.messageId ? { id: props.messageId } : {} };

  return {
    EventFeedline: {
      ...eventFeedlineAttr,
      ...{
        Message: {
          _attr: {
            id: props.labelId,
          },
        },
        Detail: props.message,
        Event: {
          _attr: {
            id: props.eventId,
          },
        },
      },
    },
  };
};

export function convertEventEnterpriseToRequest(enterprise: Event.Enterprise, index: number) {
  return {
    ...(enterprise.id ? { _attr: { id: enterprise.id } } : {}),
    ...(enterprise.element ? { Element: convertReferenceItemToXML(enterprise.element) } : {}),
    Position: index,
    ...getMaybeField('Role', enterprise.role?.value),
  };
}

export function convertAwardsToRequest(awards: Award[] | undefined) {
  if (!awards || !awards.length) {
    return { Awards: {} };
  }
  return {
    Awards: {
      Award: awards.map(doc => ({
        _attr: doc.id ? { id: doc.id } : {},
      })),
    },
  };
}

export function convertEventDepartmentToRequest(department: Event.Department, index: number) {
  return {
    ...(department.id ? { _attr: { id: department.id } } : {}),
    ...(department.element ? { Element: convertReferenceItemToXML(department.element) } : {}),
    Position: index,
    ...getMaybeField('IsFinancing', department.isFinancing),
  };
}

export function convertProjectsToRequest(projects?: EventProject[]) {
  if (!projects) {
    return {};
  }

  return {
    Project: projects.map(convertProjectToRequest),
  };
}

export const convertProjectToRequest = (project?: EventProject) => ({
  _attr: project?.id ? { id: project.id } : null,
  Project: {
    _attr: { id: project?.project?.id },
  },
  IsAccepted: !!project?.accepted,
});

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetDashboardDetailMagazineList = (): Table.Specification => ({
  apiID: 'GetDashboardDetailMagazineList',
  header: {
    firstLevel: {
      title: 'Данные по журналам за 5 лет',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
  isWithQna: true,
});

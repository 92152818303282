import { useCallback, Dispatch, SetStateAction } from 'react';

import { Form, CommunityMember } from 'types/models';
import { getPersonHistory } from 'utils/Helpers';

type Props = {
  setPublicationInfo: Dispatch<SetStateAction<Form.Publication | null>> | null;
  publicationInfo: Form.Publication | null;
};

export function useController(props: Props) {
  const { publicationInfo, setPublicationInfo } = props;
  const formatPersonWithHistory = useCallback((m: CommunityMember) => {
    const personHistory = getPersonHistory({ person: m.person });
    return [m.person?.fullName, personHistory.rank?.refRank.label, personHistory.degree?.refDegree?.label]
      .filter(Boolean)
      .join(', ');
  }, []);

  const handleMembersFieldChange = useCallback(
    (e: CommunityMember[]) => {
      if (publicationInfo && setPublicationInfo) {
        setPublicationInfo({ ...publicationInfo, communityMembers: e });
      }
    },
    [publicationInfo, setPublicationInfo],
  );

  return {
    formatPersonWithHistory,
    handleMembersFieldChange,
  };
}

import React, { useMemo, useCallback, useState } from 'react';

import { ButtonProps, ListEditTable, Modal, Toolbar } from 'components';

import { Person } from 'types/models';
import { DataGrid } from 'features/Table';
import { GetScientistAffiliationPublicationList } from 'features/Table/specifications';

type Props = {
  person: Person.ScientistPerson | null;
};

export function Affiliations(props: Props) {
  const { person } = props;

  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const scientistId: string = person?.id || '-1';
  const affiliationId: string =
    selectedRowIndex || selectedRowIndex === 0 ? person?.affiliations[selectedRowIndex].id || '-1' : '-1';

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);
  const handleCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'info' },
        title: 'Список публикаций',
        onClick: () => {
          handleViewButtonClick();
        },
        isDisabled: !(selectedRowIndex || selectedRowIndex === 0),
      },
    ],
    [handleViewButtonClick, selectedRowIndex],
  );

  return (
    <>
      {isViewFormOpen &&
        (!!selectedRowIndex || selectedRowIndex === 0) &&
        !!person &&
        !!person.affiliations &&
        !!person.affiliations[selectedRowIndex].id && (
          <Modal
            isOpen={isViewFormOpen}
            onClose={handleCloseViewForm}
            title="Список публикаций"
            size={['large', 'medium-height']}
          >
            <DataGrid specification={GetScientistAffiliationPublicationList(scientistId, affiliationId)} />
          </Modal>
        )}
      {person?.affiliations && (
        <>
          <Toolbar buttons={buttons} mode="list" />

          <ListEditTable
            rows={person.affiliations}
            columns={[
              { label: 'Название организации', formatValue: row => row.partner?.fullName || '', styles: { width: '60%' } },
              { label: 'Объект по аффилиации', formatValue: row => row.sources || '', styles: { width: '30%' } },
              { label: 'Количество записей', formatValue: row => row.count || '', styles: { width: '10%' } },
            ]}
            selectedRowIndex={selectedRowIndex}
            selectRow={setSelectedRowIndex}
          />
        </>
      )}
    </>
  );
}

import { useCallback, useEffect, useState, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { Person } from 'types/models';
import { usePrevious } from 'shared/react/usePrevious';
import { Member } from '..';
import { getInitAcademicRank, getInitCitizenship, getInitDegree, getInitialEducation, getInitJob, getInitRank } from './helpers';
import { PersonHistoryMode } from './model';
import { showNotification } from 'features/Notifications';

type Arguments = {
  isOpen: boolean;
  member: Member | null;
  onSubmit(author: Partial<Member>): void;
  isLoadPersonAfterOpen?: boolean;
  mode: PersonHistoryMode;
};

export function useController(args: Arguments) {
  const { isOpen, member, isLoadPersonAfterOpen, onSubmit, mode } = args;

  const person = member?.person ?? null;

  const { methods: loadPersonAPI, state: loadingPersonAPIState } = BackendAPI.useBackendAPI('GetScientistData');
  const { methods: SaveScientistAPI } = BackendAPI.useBackendAPI('SaveScientist');

  const isLoading = useMemo(() => loadingPersonAPIState.kind === 'pending', [loadingPersonAPIState.kind]);

  const loadPerson = useCallback(
    (personId: string) => {
      loadPersonAPI.callAPI(
        { personId },
        {
          onSuccessfullCall: ({ data }) => {
            onSubmit({ ...member, person: data });
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [member, onSubmit],
  );

  const savePerson = useCallback(
    (personData: Person.ScientistPerson) => {
      SaveScientistAPI.callAPI(
        { person: personData },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Данные успешно сохранены', theme: 'success' });
            loadPerson(personData.id);
          },
        },
      );
    },
    [SaveScientistAPI, loadPerson],
  );

  const prevIsOpen = usePrevious(isOpen);

  useEffect(() => {
    if (prevIsOpen && !isOpen) {
      setCitizenship(null);
      setDegree(null);
      setJob(null);
      setEducation(null);
      setRank(null);
      setAcademicRank(null);
    }

    if (!prevIsOpen && isOpen) {
      if (isLoadPersonAfterOpen && person) {
        loadPerson(person.id);
      }
    }
  }, [prevIsOpen, isOpen, person, isLoadPersonAfterOpen, loadPerson]);

  const refreshHistorySlice = useCallback(() => {
    const citizenship = getInitCitizenship(member);
    const degree = getInitDegree(member);
    const rank = getInitRank(member);
    const academicRank = getInitAcademicRank(member);
    const job = getInitJob(member);
    const education = getInitialEducation(member);

    setCitizenship(citizenship);
    setDegree(degree);
    setRank(rank);
    setAcademicRank(academicRank);
    setJob(job);
    setEducation(education);
  }, [member]);

  const onRefreshHistoricalSlice = useCallback(() => {
    refreshHistorySlice();
  }, [refreshHistorySlice]);

  useEffect(() => {
    if (isOpen) {
      const citizenship = mode === 'add' ? getInitCitizenship(member) : member?.citizenship || null;
      const degree = mode === 'add' ? getInitDegree(member) : member?.degree || null;
      const rank = mode === 'add' ? getInitRank(member) : member?.rank || null;
      const academicRank = mode === 'add' ? getInitAcademicRank(member) : member?.academicRank || null;
      const job = mode === 'add' ? getInitJob(member) : member?.job || null;
      const education = mode === 'add' ? getInitialEducation(member) : member?.education || null;

      setCitizenship(citizenship);
      setDegree(degree);
      setRank(rank);
      setAcademicRank(academicRank);
      setJob(job);
      setEducation(education);
    }
  }, [isOpen, member, mode]);

  const [citizenship, setCitizenship] = useState<ReferenceItem | null>(null);
  const [degree, setDegree] = useState<Person.ScientistDegree | null>(null);
  const [job, setJob] = useState<Person.ScientistJob | null>(null);
  const [education, setEducation] = useState<Person.ScientistEducation | null>(null);
  const [rank, setRank] = useState<Person.ScientistRank | null>(null);
  const [academicRank, setAcademicRank] = useState<Person.ScientistAcademicRank | null>(null);

  return {
    citizenship,
    degree,
    job,
    education,
    rank,
    academicRank,
    isLoading,
    setCitizenship,
    setDegree,
    setJob,
    setEducation,
    setRank,
    setAcademicRank,
    onRefreshHistoricalSlice,
    savePerson,
  };
}

export type State = {
  scientistId: string;
};

export function makeUseCustomController(scientistId: string) {
  return function useCustomController(): State {
    return {
      scientistId,
    };
  };
}

import React from 'react';

import { Button, FormComponent, Tabs, Tab } from 'components';

import { DataGrid } from 'features/Table';
import { GetUserPermissionList, GetUserRoleList } from 'features/Table/specifications';
import { useController } from './controller';
import { PortfolioCard } from 'features/Form/looks/person';

function UserEditForm() {
  const { userId, userInfo, isOpenPersonModal, setIsOpenPersonModal } = useController();

  return (
    <FormComponent.Template>
      <Tabs>
        <Tab title="Информация">
          <FormComponent.Field label="Логин">
            <FormComponent.Text>{userInfo.login}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Имя">
            <FormComponent.Text>{userInfo.fullName}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="E-Mail пользователя">
            <FormComponent.Text>{userInfo.email}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Факультет/Институт">
            <FormComponent.Text>{userInfo.parentDepartment}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Подразделение">
            <FormComponent.Text>{userInfo.department}</FormComponent.Text>
          </FormComponent.Field>
          <FormComponent.Field label="Персона">
            <Button onClick={() => setIsOpenPersonModal(true)} text={userInfo.person || 'портфолио'} classMixin="primary" />
          </FormComponent.Field>
          <FormComponent.Field label="Контакты">
            <FormComponent.Text>{userInfo.contacts}</FormComponent.Text>
          </FormComponent.Field>
        </Tab>
        <Tab title="Роли">
          <DataGrid specification={GetUserRoleList({ userId: userId || '-1', isWithoutHeader: true })} />
        </Tab>
        <Tab title="Права">
          <DataGrid
            specification={GetUserPermissionList({
              userId: userId || '-1',
              isWithoutHeader: true,
            })}
          />
        </Tab>
      </Tabs>

      <PortfolioCard
        isOpen={isOpenPersonModal}
        id={userInfo.personId}
        onClose={() => setIsOpenPersonModal(false)}
        shortMode={true}
      />
    </FormComponent.Template>
  );
}

export const Component = React.memo(UserEditForm);

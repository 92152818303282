import { FundCard } from 'types/models';
import { getId, getText } from '../commonConverters';

export function convertFundCard(fc: any): FundCard {
  return {
    id: getId(fc),
    name: getText(fc.Name),
    note: getText(fc.Note),
  };
}

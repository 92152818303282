import { Table } from 'types/models';
import { Settings } from 'types/models/common';

type GetLinksArguments = {
  row?: Table.Entry;
  settings: Settings | null;
};

const parseCitationSystemUrls = (value?: string): string[][] => {
  if (value) {
    const links: string[][] = value.split('##').map(item => item.split('#'));
    return links;
  }

  return [];
};

export const getLinksMap = ({ settings, row }: GetLinksArguments): Map<string, string> => {
  const links = new Map<string, string>();

  if (!row) {
    return links;
  }

  if (row.SiteUrl) {
    links.set('На сайте', row.SiteUrl);
  }

  if (row.Doi) {
    links.set('DOI', `https://doi.org/${row.Doi}`);
  }

  if (row.LibraryUrl) {
    links.set(`НБ ${settings?.organization?.shortName}`, row.LibraryUrl);
  }

  const citationSystemUrls = parseCitationSystemUrls(row.IdentifierUrls);
  citationSystemUrls.forEach(link => {
    const [key, value] = link;
    links.set(key, value);
  });

  return links;
};

import React from 'react';

import { FormComponent, ListEdit, SectionTitle, Select, SelectMode, TextInput, TextDateTime, TextArea } from 'components';

import { ForeignSecurityDocument, Form } from 'types/models';
import { SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { formatJob } from 'features/SelectPerson/helpers';
import { showErrors } from 'utils/Validators';
import { validatePatentMember } from 'features/Form/looks/securityDocument/SecurityDocumentForm/Tabs/Document/validate';
import { showErrorsMessages } from 'utils/Common';
import { SecurityDocumentMemberType } from 'utils/Enums';
import { Item } from 'types/models/common';

import { MemberInputs } from './MemberInputs';
import { validateAuthor } from './validate';
import { useController } from './controller';
import { AuthorInputs } from './AuthorInputs';

type Props = {
  foreignSecurityDocument: ForeignSecurityDocument;
  formFields: Form.Fields;
  disabled?: boolean;
  setForeignSecurityDocument: React.Dispatch<React.SetStateAction<ForeignSecurityDocument>>;
};

export function AboutIPK({ foreignSecurityDocument, formFields, disabled, setForeignSecurityDocument }: Props) {
  const { handleAuthorsChange, handleApplicantChange, applicantPersonList } = useController({
    foreignSecurityDocument,
    setForeignSecurityDocument,
  });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.type.title} isRequired={formFields.type.required}>
          <Select
            mode={SelectMode.ENUM}
            value={formFields.type?.value}
            settings={{ name: 'SecurityDocumentType' }}
            onChange={(option: Item) => formFields.type.onChange(option)}
            isDisabled={disabled}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.number.title} isRequired={formFields.number.required}>
          <TextInput value={formFields.number.value} onChange={formFields.number.onChange} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сроки действия документа">
          <span>
            <TextDateTime
              value={formFields.startDate.value || ''}
              onChange={(value: string) => formFields.startDate.onChange(value)}
              maxValue={formFields.endDate.value || ''}
              isDisabled={disabled}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={formFields.endDate.value || ''}
              onChange={(value: string) => formFields.endDate.onChange(value)}
              minValue={formFields.startDate.value || ''}
              isDisabled={disabled}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.kind.title} isRequired={formFields.kind.required}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.kind.value}
            onChange={formFields.kind.onChange}
            settings={{
              name: 'RefSecurityDocumentKind',
              title: 'Справочник "Виды ОИС"',
              filters: [{ key: 'type', values: [formFields.type.value?.value] }],
              isClearable: !formFields.kind.required,
            }}
            isDisabled={disabled || !formFields.type.value}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.name.title} isRequired={formFields.name.required}>
          <TextInput value={formFields.name.value} onChange={formFields.name.onChange} isDisabled={disabled} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label="Примечание"
          tooltip="Дополнительная информация, например, сведения о договоре о распоряжении интеллектуальным правом на объект"
        >
          <TextArea
            value={foreignSecurityDocument.contract}
            onChange={e => setForeignSecurityDocument(prev => ({ ...prev, contract: e }))}
            isDisabled={disabled}
            settings={{ maxLength: 500 }}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Авторы" />

      <FormComponent.Field>
        <ListEdit
          rows={foreignSecurityDocument?.members?.filter(m => m.type?.value === SecurityDocumentMemberType.AUTHOR) || []}
          onChange={handleAuthorsChange}
          toolbar={['add', 'edit', 'delete']}
          columns={[
            { label: 'ФИО', formatValue: x => x?.name || '', styles: { width: '30%' } },
            {
              label: 'Место работы',
              formatValue: x => (x?.job && formatJob(x?.job)) || '',
            },
            { label: 'Вклад, %', formatValue: x => x?.contribution || '0', styles: { width: '15%' }, dataKind: 'float' },
          ]}
          withMessages
          isDisabled={disabled}
          maxHeight="600px"
          specification={{
            mode: 'customComponent',
            renderComponent: (row, onChange) => <AuthorInputs author={row} onAuthorChange={onChange as any} />,
            validation: {
              checkIsValid: a => validateAuthor(a).every(x => x.isValid),
              onInvalidate: val => showErrors(validateAuthor, val),
            },
          }}
        />
      </FormComponent.Field>

      <SectionTitle title="Правообладатели" />

      <ListEdit
        rows={applicantPersonList}
        onChange={handleApplicantChange}
        toolbar={['add', 'edit', 'delete']}
        columns={[{ label: 'ФИО персоны / Название организации', formatValue: x => x?.name || '' }]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: (row, onChange) => (
            <MemberInputs
              member={row}
              onMemberChange={onChange as any}
              memberType={SecurityDocumentMemberTypes.APPLICANT}
              extraFields={{}}
            />
          ),
          validation: {
            checkIsValid: (row, index, list, mode) => validatePatentMember(row, list, mode, index)?.every(x => x.isValid),
            onInvalidate: (row, mode, index, list) => {
              const validationInfo = validatePatentMember(row, list, mode, index);
              showErrorsMessages(validationInfo?.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
    </>
  );
}

import React from 'react';

import { Icon, Modal } from 'components';

import { Color } from 'constants/colors';

export const ModalHelp = () => {
  return (
    <Modal mode="help" title='Пример элементов для окна "Помощь"' isOpen={true} onClose={() => {}} size="large">
      <h3>Крупный заголовок "h3". Основной заголовок. Рекомендуется не более одного на страницу</h3>

      <h4>Мелкий заголовок "h4". Выделяющий главы, пункты, блоки, таблицы</h4>

      <p>
        Простой блок с тектстом, возможны куски <strong>выделенного текста</strong> или сколько угодно много, обычного
      </p>
      <p>
        Второй пустой блок с тектстом... текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст
        текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст
        текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст текст
        текст текст текст текст текст текст
      </p>

      <ul>
        <li>Элемент немаркированного списка (№1)</li>
        <li>
          Элемент немаркированного списка <strong>выделенный текст</strong>
        </li>
      </ul>

      <ul className="dashed">
        <li>Элемент немаркированного списка (№2)</li>
        <li>
          Элемент немаркированного списка <strong>выделенный текст</strong>
        </li>
      </ul>

      <p>
        Любой элемент может содержать иконки нового - <Icon type="save" /> или <Icon type="save" mode="add" />
      </p>
      <p>
        У иконок нового образца, можно задать цвет, размер и тд - <Icon type="save" color={Color.success} size={16} />
      </p>

      <ol>
        <li>Элемент маркированного списка (№3)</li>
        <li>
          Элемент маркированного списка <strong>выделенный текст</strong>
        </li>
        <li>
          <p>Или может содержать в себе список:</p>
          <ul className="dashed">
            <li>Элемент немаркированного списка (№2)</li>
            <li>
              Элемент немаркированного списка <strong>выделенный текст</strong>
            </li>
          </ul>
        </li>
      </ol>

      <div className="warning">
        <span>
          <p>
            Блок с текстов, выделенный слева словом <strong>ВАЖНО!</strong>.
          </p>
          <p>Может содержать много контента...</p>
          <p>
            В том числе <strong>списки</strong>, <strong>таблицы</strong> и многое другое...
          </p>
        </span>
      </div>

      <div className="warning" data-label="Внимание!">
        <span>
          Блок с текстов, выделенный слева словом <strong>ВНИМАНИЕ!</strong>. Или любым другим словом (слово "ВАЖНО!" задано по
          умолчанию)
        </span>
      </div>

      <table>
        <thead>
          <tr>
            <th style={{ width: '20%' }}>ID (ширина 20%)</th>
            <th>Колонка 2 (ширина не задана)</th>
            <th style={{ width: '50%' }}>Колонка 3 (ширина 50%)</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>1</td>
            <td>
              <p>
                <strong>Какой-то выделенный текст</strong>
              </p>
              <p>Обычный текст</p>
            </td>
            <td>
              <p>Список:</p>
              <ul>
                <li>Элемент немаркированного списка (№1)</li>
                <li>
                  Элемент немаркированного списка <strong>выделенный текст</strong>
                </li>
              </ul>

              <p>Или такой список:</p>
              <ol>
                <li>Элемент маркированного списка (№3)</li>
                <li>
                  Элемент маркированного списка <strong>выделенный текст</strong>
                </li>
              </ol>
            </td>
          </tr>
        </tbody>
      </table>
    </Modal>
  );
};

import React, { memo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { Table as T } from 'types/models';

type Props = {
  tableState: Table.State;
};

type WrapperProps = {
  workMode: T.WorkMode;
};

const ToolbarTemplate = ({ workMode }: WrapperProps) =>
  memo(({ tableState }: Props) => {
    const {
      isAddFormOpen,
      isEditFormOpen,
      isViewFormOpen,
      closeViewForm,
      closeAddForm,
      closeEditForm,
      toolbarButtons,
      closeConfirmDeletePopup,
      isConfirmDeletePopupOpen,
      confirmDeleteRelease,
      isArticleFormOpen,
      closeArticleForm,
      relatedTableId,
    } = useController({
      tableState,
      workMode,
    });

    return (
      <>
        <Toolbar buttons={toolbarButtons} />

        <modalTemplates.MagazineReleaseAddTemplate
          isOpen={isAddFormOpen}
          onClose={closeAddForm}
          relatedTableId={relatedTableId}
        />

        <modalTemplates.MagazineReleaseViewTemplate
          isOpen={isViewFormOpen}
          onClose={closeViewForm}
          id={tableState.selectedRows[0]?.id}
        />

        <modalTemplates.MagazineReleaseEditTemplate
          isOpen={isEditFormOpen}
          onClose={closeEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableId={relatedTableId}
        />

        <modalTemplates.MagazineReleaseArticlesTemplate
          isOpen={isArticleFormOpen}
          onClose={closeArticleForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />

        {!!tableState.selectedRows.length && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isConfirmDeletePopupOpen}
            onClose={closeConfirmDeletePopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: confirmDeleteRelease,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: closeConfirmDeletePopup,
              },
            ]}
            size="small"
          >
            <>({tableState.selectedRows[0]?.label}) Удалить выпуск? </>
          </Modal>
        )}
      </>
    );
  });

export const Component = ToolbarTemplate;

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Template } from 'types/models/Form';
import { CommunityType } from 'utils/Enums';

export function useController(tableState: Table.State, communityTypes: CommunityType[]) {
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.MULTIPLE_SELECTED;
  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const templateName: Template = 'CommunityForm';

  const isApproved: boolean = tableState.selectedRows[0]?.['id:Status'] === 'APPROVED';
  const isDissertationCouncil = communityTypes.includes(CommunityType.DISSERTATION_COUNCIL);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isApproveConfirmPopupOpen, setIsApproveConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Community',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState.selectedRows, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleCloseApproveConfirmPopup = useCallback(() => {
    setIsApproveConfirmPopupOpen(false);
  }, []);

  const handleConfirmApproveConfirmPopup = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows[0]?.id,
      command: 'ApproveCommunity',
      approveItemName: 'Community',
      disapprove: isApproved,
    });
    handleCloseApproveConfirmPopup();
  }, [handleCloseApproveConfirmPopup, isApproved, tableState.selectedRows, tableStreams.approveRow]);

  const handleApproveButtonClick = useCallback(() => {
    setIsApproveConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: {
          name: Permits.COMMUNITY_INFO_VIEW,
        },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: {
          name: Permits.COMMUNITY_INFO_VIEW,
        },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: {
          name: Permits.COMMUNITY_INFO_VIEW,
        },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: {
          name: Permits.COMMUNITY_INFO_VIEW,
        },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'like' },
        title: isApproved ? 'Перевести в статус "Добавлено"' : 'Утвердить',
        onClick: handleApproveButtonClick,
        permission: {
          name: Permits.COMMUNITY_APPROVE,
        },
        isDisabled: isApproveButtonDisabled,
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
      isApproved,
      isApproveButtonDisabled,
      handleApproveButtonClick,
    ],
  );

  return {
    buttons,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTemplateCloseEditForm,
    handleTemplateCloseViewForm,
    isAddFormOpen,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    isEditButtonDisabled,
    isEditFormOpen,
    isViewButtonDisabled,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    handleCloseApproveConfirmPopup,
    handleConfirmApproveConfirmPopup,
    isApproveButtonDisabled,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isDissertationCouncil,
  };
}

import { Notice } from 'types/models/Notice';
import { convertItem, convertMaybeArray, getId, getText } from '../commonConverters';

export function convertServerNotice(serverNotice: any): Notice[] {
  const convertServerData = (val: any) => ({
    id: getId(val),
    caption: getText(val.Caption),
    data: getText(val.Data),
    createdDate: getText(val.CreatedDate),
    kind: convertItem<string>(val.Kind),
  });
  return convertMaybeArray(serverNotice.Response.Notice ?? [], convertServerData);
}

export class StagesTagnameUndistributed {
  static ACCOMPLICE: string = 'amountAccompliceUndistributed';

  static COFINANCING_2: string = 'amountCofinancing2Undistributed';

  static COFINANCING_1: string = 'amountCofinancing1Undistributed';

  static LOCAL: string = 'amountLocalUndistributed';

  static MAIN: string = 'amountMainUndistributed';
}

import React from 'react';

import { Rntd } from 'types/models';
import { FormComponent, ListEdit, SectionTitle } from 'components';
import * as BackendAPI from 'services/BackendAPI';

import { ValueOf } from 'types/helpers';
import { GetExhibitionParticipationForRntd } from 'features/Table/specifications';

import { useController } from './controller';
import { Component as ParticipationForm } from './ParticipationForm';

type Props = {
  rntd?: Rntd | null;
  makeChangeHandler: (key: keyof Rntd) => (val: ValueOf<Rntd>) => void;
  disabled: boolean;
};

export function ExhibitionParticipation({ rntd, makeChangeHandler, disabled }: Props) {
  const {
    formatEventName,
    formatOrganizer,
    formatSupervisor,
    handleParticipationChange,
    handleParticipationSave,
    isRntdExhibPartOpenForm,
    setIsRntdExhibPartOpenForm,
    participationId,
    participationFormMode,
  } = useController({
    rntd,
    makeChangeHandler,
  });

  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');

  return (
    <FormComponent.Wrapper>
      <SectionTitle title="Список выставок, на которых был представлен РИД" />

      <ListEdit
        rows={rntd?.participations || []}
        onChange={handleParticipationChange}
        columns={[
          { label: 'Выставка', formatValue: x => formatEventName(x), styles: { width: '10%' } },
          { label: 'Организатор', formatValue: x => formatOrganizer(x) },
          { label: 'Уровень мероприятия', formatValue: x => x.event?.eventStatus?.label || '' },
          { label: 'Руководитель', formatValue: x => formatSupervisor(x) || '' },
          { label: 'Участники', formatValue: x => x.members?.map(m => m.person?.fullName).join(' ') || '' },
          { label: 'Тип экспоната', formatValue: x => x.exhibitType?.label || '' },
          { label: 'Экспонат', formatValue: x => x.development || '' },
          { label: 'Награды', formatValue: x => x.awards?.map(a => a?.name).join(' ') },
          { label: 'Коллективная экспозиция', formatValue: x => x.collectiveExhibitName || '' },
        ]}
        isDisabled={disabled}
        defaultRowsCount={15}
        maxHeight="none"
        isDeleteConfirmEnabled
        specification={{
          mode: 'loadInstanceTableModal',
          onStartLoad: (tableRows, onSuccess) => {
            getParticipation.callAPI(
              {
                participationId: tableRows[0]?.id,
                modules: ['ID', 'MAIN', 'AWARDS', 'EVENT'],
                eventModules: ['MAIN', 'DEPARTMENTS'],
              },
              { onSuccessfullCall: ({ data }) => onSuccess([data]) },
            );
          },
          relationTableModalTitle: 'Участия в выставках',
          modalTableSpecification: GetExhibitionParticipationForRntd,
        }}
      />

      <ParticipationForm
        isOpen={isRntdExhibPartOpenForm}
        onClose={() => setIsRntdExhibPartOpenForm(false)}
        id={participationId}
        mode={participationFormMode}
        onSave={handleParticipationSave}
      />
    </FormComponent.Wrapper>
  );
}

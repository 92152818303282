import React, { useCallback, useEffect, useRef, useState } from 'react';

import { Divider, TextInputMode } from 'components';

import { Item } from 'types/models/common';
import { Props as CheckboxProps, Checkbox } from 'components/Checkbox';
import { Props as RadioProps, Radio, RadioItem } from 'components/Radio';
import { Props as TextProps, TextInput } from 'components/TextInput';
import { Props as SelectProps, SelectComponent as Select } from 'components/Select';
import { Props as SliderProps, Slider } from 'components/Slider';
import { Props as TooltipProps, Tooltip } from 'components/Tooltip';
import { Props as InfoProps, Info } from 'components/Info';
import { Props as DatepickerProps, TextDateTime } from 'components/TextDateTime';

import { useStream } from 'StreamRx';
import { useLocalStreams, useLocalTableStreams } from 'features/Table/hooks';
import { streams as globalStreams } from 'features/Table/streams';
import { getHashObject } from 'utils/Helpers';

import './style.scss';

const CURRENT_YEAR = new Date().getFullYear().toString();

/**
 * Enum of FilterComponentType
 *
 * @readonly
 * @param {string} constant - Constant param
 * @param {string} checkbox - Checkbox only
 * @param {string} radio - Radio group
 * @param {string} divider - Divider isVertical isFlex (hidden for FilterMode === 'personal')
 * @param {string} text - Raw text for span
 * @param {string} input - Simple text field
 * @param {string} year - Year text field (with checkbox)
 * @param {string} select - Select -> Options field
 * @param {string} slider - Slider field
 * @param {string} tooltip - Tooltip icon with bubble
 * @param {string} info - Show info with text/html with bubble
 * @param {string} datepicker - Date picker
 */
export enum FilterComponentType {
  /** Constant param () */
  constant = 'constant',
  /** Checkbox only */
  checkbox = 'checkbox',
  /** Radio group */
  radio = 'radio',
  /** Divider isVertical isFlex (hidden for FilterMode === 'personal') */
  divider = 'divider',
  /** Raw text for span */
  text = 'text',
  /** Simple text field */
  input = 'input',
  /** Year text field (with checkbox) */
  year = 'year',
  /** Select -> Options field */
  select = 'select',
  /** Slider field */
  slider = 'slider',
  /** Tooltip icon with bubble */
  tooltip = 'tooltip',
  /** Show info with text/html with bubble */
  info = 'info',
  /** DateTimePicker */
  datepicker = 'datepicker',
}

/**
 * Type of tableStreams.___.push() param
 */
type FilterComponentValue = string | number | boolean;

/**
 * Settings for filter component
 */
export type FilterComponent = {
  /** @property {FilterComponentType} type - (required) Type of filter */
  type: FilterComponentType;
  /** @property {string} streamKey - Key for stream RxJs (returned to panelState) */
  streamKey?: string;
} & (
  | {
      type: FilterComponentType.constant;
      streamKey: string;
      /** @property {FilterComponentValue} value - Value for constant */
      value: FilterComponentValue;
    }
  | ({
      type: FilterComponentType.checkbox;
      streamKey: string;
      /** Properties - Nested from Checkbox */
    } & Pick<CheckboxProps, 'label' | 'checked' | 'onChange' | 'hint'>)
  | ({
      type: FilterComponentType.radio;
      streamKey: string;
      /** Properties - Nested from Radio */
    } & Pick<RadioProps, 'list' | 'value' | 'onChange' | 'isDisabled'>)
  | {
      type: FilterComponentType.divider;
    }
  | {
      type: FilterComponentType.text;
      /** @property {string} value - Default value for text field */
      value: string;
    }
  | ({
      type: FilterComponentType.input;
      streamKey: string;
      /** @property {boolean} isYearMask - (optional) Mask by year is required */
      isYearMask?: boolean;
      /** Properties - Nested from TextInput */
    } & Pick<TextProps, 'onChange' | 'value' | 'isDisabled' | 'classMixin'>)
  | ({
      type: FilterComponentType.year;
      streamKey: string;
      /** Properties - Nested from Checkbox */
    } & Pick<CheckboxProps, 'label' | 'checked' | 'onChange' | 'hint' | 'isDisabled' | 'classMixin'>)
  | ({
      type: FilterComponentType.select;
      streamKey: string;
      /** Properties - Nested from Select */
    } & Pick<SelectProps, 'options' | 'value' | 'classMixin'> &
      Partial<Pick<SelectProps, 'onChange'>>)
  | ({
      type: FilterComponentType.slider;
      streamKey: string;
      /** Properties - Nested from Slider */
    } & Pick<SliderProps, 'label' | 'value' | 'params'>)
  | ({
      type: FilterComponentType.tooltip;
      /** Properties - Nested from Tooltip */
    } & Pick<TooltipProps, 'text'>)
  | ({
      type: FilterComponentType.info;
      /** Properties - Nested from Info */
    } & Pick<InfoProps, 'text'>)
  | ({
      type: FilterComponentType.datepicker;
      streamKey: string;
      /** Properties - Nested from TextDateTime */
    } & Pick<DatepickerProps, 'value' | 'isDisabled'> &
      Partial<Pick<DatepickerProps, 'onChange'>>)
);

/**
 * Mode of visual filters
 */
type FilterMode = 'default' | 'personal';

/**
 * Generate FirstLevelPanel for Table
 *
 * @param {FilterComponent[]} components - Array of filters
 * @param {FilterMode} mode - (optional) Mode for panel ('default' by default)
 * @returns {JSX} JSX
 */
export const FiltersComponent = (components: FilterComponent[], mode?: FilterMode) => {
  mode = mode || 'default';

  type State = Record<string, FilterComponentValue>;
  type ItemState = {
    value: string;
    checked: boolean;
  };

  const yearLimits = { min: 1950, max: 2050 };

  // Calc initial values from components
  const initState: State = components.reduce((acc, item) => {
    if (typeof item.streamKey === 'undefined') {
      return acc;
    }

    const value = (() => {
      const { type } = item;
      if (type === FilterComponentType.constant) {
        return item.value;
      }
      if (type === FilterComponentType.checkbox) {
        return item.checked;
      }
      if (type === FilterComponentType.radio) {
        return item.value;
      }
      if (type === FilterComponentType.input) {
        if (!!item.isYearMask && item.value === undefined) {
          return CURRENT_YEAR;
        }
        return item.value;
      }
      if (type === FilterComponentType.year) {
        return !!item.checked ? CURRENT_YEAR : '';
      }
      if (type === FilterComponentType.select) {
        return (item.value as Item).value;
      }
      if (type === FilterComponentType.slider) {
        return item.value;
      }
      if (type === FilterComponentType.datepicker) {
        return item.value;
      }
    })();

    return { ...acc, [item.streamKey]: value as FilterComponentValue };
  }, {});

  // Streams for table and global for RxJs
  const streams = useLocalStreams(globalStreams);
  const tableStreams = useLocalTableStreams();

  // Components hash
  const [prevState, setPrevState] = useState<State>({});
  // State for sending to backendAPI as panelState
  const [state, setState] = useState<State>(initState);
  // Inner state (not for sending)
  const itemsState = useRef<Record<string, ItemState>>({});
  // Description hint for personal mode only
  const hintDescription = useRef<string>('');

  useStream(
    () => streams.getPanelState,
    data => {
      data.onStateReceive(state);
    },
    [state],
  );

  const updateState = useCallback(
    (updatedState: State) => {
      if (getHashObject(state) !== getHashObject(updatedState)) {
        setTimeout(() => {
          tableStreams.updateFirstLevelPanelState.push({ state: updatedState });
          setState(updatedState);
        }, 0 * 1000);
      }
    },
    [state, tableStreams.updateFirstLevelPanelState],
  );

  // Set inner state (not for sending)
  const setItemsState = useCallback((streamKey: string, itemState: ItemState) => {
    itemsState.current = {
      ...itemsState.current,
      [streamKey]: { ...itemState },
    };
  }, []);

  const changeYearWithCheckbox = (streamKey: string, itemState: ItemState) => {
    updateState({ ...state, [streamKey]: itemState.checked ? itemState.value : '' });
    setItemsState(streamKey, itemState);
  };

  const setDescription = (items: RadioItem[], value: string) => {
    hintDescription.current = items.find(i => i.value === value)?.hint ?? '';
  };

  useEffect(() => {
    type Changed = {
      key: string;
      value: any;
    };

    if (getHashObject(prevState) !== getHashObject(initState)) {
      const changedParams: Changed[] = Object.keys(initState)
        .filter(key => prevState[key] !== initState[key] && state[key] !== initState[key])
        .map(key => ({ key, value: initState[key] } as Changed));

      setPrevState(initState);

      if (changedParams.length) {
        let updatedState = { ...state };

        changedParams.forEach(changed => {
          const isYear = components.filter(i => i.type === FilterComponentType.year).find(i => changed.key === i.streamKey);
          if (isYear) {
            setItemsState(changed.key, {
              value: changed.value,
              checked: !!initState[changed.key],
            });
          }
          updatedState = { ...updatedState, [changed.key]: changed.value };
        });

        updateState(updatedState);
      }
    }
  }, [components, initState, prevState, setItemsState, state, tableStreams.updateFirstLevelPanelState, updateState]);

  // Components for render
  const Components = components
    .map((item: FilterComponent, index: number) => {
      const { type } = item;
      const key = `${type}_${index}`;

      if (type === FilterComponentType.checkbox) {
        return (
          <Checkbox
            key={key}
            label={item.label}
            checked={state[item.streamKey] as boolean}
            onChange={(value: boolean) => {
              if (item.onChange) setTimeout(() => item.onChange?.(value), 0);
              else updateState({ ...state, [item.streamKey]: value });
            }}
          />
        );
      }

      if (type === FilterComponentType.radio) {
        setDescription(item.list, state[item.streamKey] as string);
        return (
          <Radio
            key={key}
            value={state[item.streamKey] as string}
            list={item.list}
            isDisabled={item.isDisabled}
            onChange={(value: string) => {
              if (item.onChange) setTimeout(() => item.onChange?.(value), 0);
              else updateState({ ...state, [item.streamKey]: value });
              setDescription(item.list, value);
            }}
          />
        );
      }

      if (type === FilterComponentType.divider) {
        return <Divider key={key} isVertical isFlex />;
      }

      if (type === FilterComponentType.text) {
        return <span key={key}>{item.value}</span>;
      }

      if (type === FilterComponentType.input) {
        return (
          <div key={key} className={!!item.isYearMask ? 'is-year' : ''}>
            <TextInput
              {...(!!item.isYearMask
                ? {
                    mode: TextInputMode.YEAR,
                    settings: yearLimits,
                  }
                : {})}
              value={state[item.streamKey] as string}
              onChange={(value: string) => updateState({ ...state, [item.streamKey]: value })}
              classMixin={item.classMixin}
              isDisabled={item.isDisabled}
            />
          </div>
        );
      }

      if (type === FilterComponentType.year) {
        if (!itemsState.current[item.streamKey]) {
          setItemsState(item.streamKey, {
            value: CURRENT_YEAR,
            checked: !!item.checked,
          });
        }

        return (
          <div key={key} className="is-year" data-checked={itemsState.current[item.streamKey].checked}>
            <Checkbox
              label={item.label}
              checked={itemsState.current[item.streamKey].checked}
              onChange={(checked: boolean) => {
                if (item.onChange) setTimeout(() => item.onChange?.(checked), 0);
                else changeYearWithCheckbox(item.streamKey, { checked, value: itemsState.current[item.streamKey].value });
              }}
              isDisabled={item.isDisabled}
              hint={item.hint}
            />
            <TextInput
              mode={TextInputMode.YEAR}
              settings={yearLimits}
              value={itemsState.current[item.streamKey].value}
              onChange={(value: string) =>
                changeYearWithCheckbox(item.streamKey, { checked: itemsState.current[item.streamKey].checked, value })
              }
              isDisabled={!itemsState.current[item.streamKey].checked || item.isDisabled}
              classMixin={item.classMixin}
            />
          </div>
        );
      }

      if (type === FilterComponentType.select) {
        return (
          <Select
            key={key}
            value={(item.options || []).find(i => i.value === state[item.streamKey]) as Item}
            options={item.options}
            settings={{ isSearchable: false }}
            onChange={(option: Item) => updateState({ ...state, [item.streamKey]: option.value })}
            classMixin={item.classMixin}
          />
        );
      }

      if (type === FilterComponentType.slider) {
        return (
          <Slider
            key={key}
            value={state[item.streamKey] as number}
            label={item.label}
            params={item.params}
            onChange={(value: number) => updateState({ ...state, [item.streamKey]: value })}
          />
        );
      }

      if (type === FilterComponentType.tooltip) {
        return <Tooltip key={key} text={item.text} />;
      }

      if (type === FilterComponentType.info) {
        return <Info key={key} text={item.text} />;
      }

      if (type === FilterComponentType.datepicker) {
        return (
          <div key={key} className="is-datepicker">
            <TextDateTime
              value={state[item.streamKey] as string}
              onChange={(value: string) => {
                if (item.onChange) setTimeout(() => item.onChange?.(value), 0);
                else updateState({ ...state, [item.streamKey]: value });
              }}
              isDisabled={item.isDisabled}
            />
          </div>
        );
      }
    })
    .filter(Boolean);

  if (mode === 'personal') {
    return Components && Components.length ? (
      <div key={mode} className={mode}>
        {!!hintDescription.current && <div className="hint">{hintDescription.current}</div>}
        {Components}
      </div>
    ) : (
      <></>
    );
  }

  return Components && Components.length ? <>{Components}</> : <></>;
};

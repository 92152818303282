import React, { useCallback } from 'react';

import { EditMipPartner } from 'features/EditMipPartner';
import { State, Mode } from '../makeUseCustomController';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  customState: State;
};

export function Form({ customState }: Props) {
  const { mipPartner, isOpenForm, closeForm, mode, reloadTable } = customState;

  const onSuccessSave = useCallback(() => {
    reloadTable();
    closeForm();
  }, [reloadTable, closeForm]);

  const getTitle = () => {
    const info = getStatusRecordMessage(
      mipPartner?.shortName || mipPartner?.fullName,
      mipPartner?.id || '',
      mipPartner?.status?.label,
      mipPartner?.state?.label,
    );

    const map: Record<Mode, string> = {
      add: 'Добавление МИП',
      edit: `Редактирование МИП ${info}`,
      view: `Просмотр ${info}`,
    };

    return mode ? map[mode] : '';
  };

  return (
    <EditMipPartner
      isOpenForm={isOpenForm}
      title={getTitle()}
      mipPartner={mipPartner}
      closeForm={closeForm}
      mode={mode}
      onSuccessSavePartner={onSuccessSave}
    />
  );
}

import React, { useMemo } from 'react';
import * as modalTemplate from './modalTemplate';

import { ButtonMode, Modal, Toolbar } from 'components';

import { useController } from './controller';
import { Table } from 'types/models';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const {
    mode,
    buttons,
    handleConfirmDeleteConfirmPopup,
    isFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    setIsFormOpen,
  } = useController({ tableState, customState });

  const id = useMemo<string>(() => {
    if (mode === 'add') {
      return '';
    }
    if (!!tableState.selectedRows[0]) {
      return tableState.selectedRows[0]['id:CitationSystem'];
    }
    return '';
  }, [mode, tableState.selectedRows]);

  return (
    <>
      <Toolbar buttons={buttons} />

      {isFormOpen && (
        <modalTemplate.Component
          mode={mode}
          isOpen={isFormOpen}
          onClose={() => setIsFormOpen(false)}
          id={id}
          relatedTableState={tableState}
          customState={customState}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.CitationSystem}) Удалить показатель?</>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

export class PersonalCabinet {
  static MODULE: string = 'pc';

  static MENU: string = 'Menu';

  static PUBLICATIONS: string = 'Publications';

  static EVENTS: string = 'Events';

  static PARTICIPATIONS: string = 'Participations';

  static PERSONALACHIEVEMENTS: string = 'PersonalAchievements';

  static TENDERREQUESTS: string = 'TenderRequests';

  static PROJECTS: string = 'Projects';

  static CONTESTREQUESTS: string = 'ContestRequests';

  static PROJECTORDERS: string = 'ProjectOrders';

  static PURCHASEREQUESTS: string = 'PurchaseRequests';

  static ALLPROJECTS: string = 'AllProjects';

  static PROJECTRESTRICTIONS: string = 'ProjectRestrictions';

  static SECURITYDOCUMENTS: string = 'SecurityDocuments';

  static ALLSECURITYDOCUMENTS: string = 'AllSecurityDocuments';

  static FOREIGNSECURITYDOCUMENTS: string = 'ForeignSecurityDocuments';

  static DISSERTATIONSDEFENCESUPERVISING: string = 'DissertationsDefenceSupervising';

  static COLLEAGUES: string = 'Colleagues';

  static RECOGNITIONS: string = 'Recognitions';

  static DISSERTATIONCOMMUNITIESPARTICIPATION: string = 'DissertationCommunitiesParticipation';

  static COMMUNITIESPARTICIPATION: string = 'CommunitiesParticipation';

  static NOTICES: string = 'Notices';

  static TENDERS: string = 'Tenders';

  static QNAS: string = 'Qnas';

  static FQNAS: string = 'Fqnas';

  static USEFUL_LINKS_AND_HELPFUL_STUFFS: string = 'UsefulLinksAndHelpfulStuffs';

  static NEWS: string = 'News';

  static CONTEST: string = 'Contest';

  static CONFERENCE: string = 'Conference';

  static EXPOSITION: string = 'Exposition';

  static CONCERT: string = 'Concert';
}

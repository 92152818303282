import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isEditButtonDisabled,
    isConfirmPopupDisabled,
    buttons,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isConfirmPopupOpen,
    resetDelete,
    confirmDelete,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {!isEditButtonDisabled && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleTemplateCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isConfirmPopupDisabled && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isConfirmPopupOpen}
          onClose={resetDelete}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: confirmDelete,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: resetDelete,
            },
          ]}
          size="small"
        >
          <>
            ({tableState.selectedRows[0]?.LibraryId}) ({tableState.selectedRows[0]?.BibliographicRecord}) Удалить из Научной
            библиотеки?
          </>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { Arguments } from '.';

export type State = {
  hasToolbarButtonMode: boolean;
  action: string;
};

export function makeUseCustomController({ hasToolbarButtonMode = false, action = '' }: Arguments) {
  return function useCustomController(): State {
    return {
      hasToolbarButtonMode,
      action,
    };
  };
}

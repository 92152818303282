import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './makeUseCustomController';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { arm } = customState;

  return FiltersComponent(
    [
      {
        type: FilterComponentType.radio,
        streamKey: 'restriction',
        list: [
          {
            label: 'Все ограничения',
            value: 'all',
          },
          {
            label: 'Нарушены сегодня',
            value: 'violatedToday',
          },
          {
            label: 'Риски нарушения ограничений',
            value: 'violationRisks',
          },
        ],
        value: 'all',
      },
      {
        type: FilterComponentType.divider,
      },
      {
        type: FilterComponentType.year,
        streamKey: 'year',
        label: 'Год',
        checked: true,
      },
    ],
    arm === 'pc' ? 'personal' : 'default',
  );
});

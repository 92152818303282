import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertSaveResponseXMLElementToOutput } from 'services/BackendAPI/configurations/commonConverters';
import { SecurityDocumentPaymentSaveInput } from './types';
import { convertSecurityDocumentPayment } from './converters';
import { convertSecurityDocumentPaymentToXML } from './requestConverters';

const configurations = {
  GetSecurityDocumentPayment: makeBackendAPIConfiguration({
    id: 'GetSecurityDocumentPayment',
    endpoint: '/msa/service/commands/GetSecurityDocumentPayment',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetSecurityDocumentPayment' },
        Payment: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertSecurityDocumentPayment(response.Response.Payment),
  }),
  SaveSecurityDocumentPayment: makeBackendAPIConfiguration({
    id: 'SaveSecurityDocumentPayment',
    endpoint: '/msa/service/commands/SaveSecurityDocumentPayment',
    convertInputToXMLElement: (inputData: SecurityDocumentPaymentSaveInput) => ({
      Request: {
        _attr: {
          commandName: 'SaveSecurityDocumentPayment',
        },
        Payment: { ...convertSecurityDocumentPaymentToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

export const securityDocumentPaymentConfigurations = { ...configurations };

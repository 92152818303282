import { EmployeeFund, EmployeeNominee, EmployeePayment } from 'types/models/Payment';
import { Settings } from 'types/models/common';
import { convertServerPerson, convertServerScientistJob } from '../author/converters';

import { convertDocument, convertItem, convertMaybeArray, convertReference, getText } from '../commonConverters';

export function convertEmployeePayment(employee: any, settings: Settings): EmployeePayment {
  const serverNominees = employee.Nominees?.Nominee || [];
  const serverDocuments = employee.Documents?.Document || [];
  const documents = serverDocuments ? convertMaybeArray(serverDocuments, i => convertDocument('local', i)) : [];

  return {
    documents,
    id: employee._attributes.id,
    amountMoney: getText(employee.AmountMoney),
    status: employee.Status ? convertItem(employee.Status) : null,
    fund: employee.Fund ? convertFund(employee.Fund) : null,
    nominees: convertMaybeArray(serverNominees, n => convertEmployeeNominee(n, settings)),
  };
}

function convertFund(fund: any): EmployeeFund {
  return {
    month: getText(fund.Month),
    year: getText(fund.Year),
    quarter: convertItem(fund.Quarter),
    type: convertItem(fund.Type),
    planIncome: getText(fund.PlanIncome),
    factIncome: getText(fund.FactIncome),
    planExpense: getText(fund.PlanExpense),
    factExpense: getText(fund.FactExpense),
    department: fund.Department ? convertReference(fund.Department) : null,
    nomineePlanMoney: getText(fund.NomineePlanMoney),
    nomineeFactMoney: getText(fund.NomineeFactMoney),
    nomineeQuantity: getText(fund.NomineeQuantity),
    nominationFraction: getText(fund.NominationFraction),
    nominationQuantity: getText(fund.NominationQuantity),
  };
}

export function convertEmployeeNominee(nominee: any, settings: Settings): EmployeeNominee {
  return {
    id: nominee._attributes.id,
    amountMoney: getText(nominee.AmountMoney),
    employee: convertServerPerson(nominee.Employee, settings),
    job: nominee.Job ? convertServerScientistJob(nominee.Job) : null,
  };
}

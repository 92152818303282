import * as BackendAPI from 'services/BackendAPI';
import { useCallback } from 'react';
import { Table } from 'types/models';
import { Item } from 'types/models/common';

const useController = () => {
  const { methods: getNirRequestAPI } = BackendAPI.useBackendAPI('GetNirRequest');

  const onSelectRow = useCallback(
    (row: Table.Entry, onSuccessLoad: (loadedRow: Item<string>) => void) => {
      getNirRequestAPI.callAPI(
        {
          id: row.id,
        },
        {
          onSuccessfullCall({ data }) {
            const programShortName = data.tender?.program?.shortName ?? '';
            const eventCode = data.tender?.programEvent?.code ?? '';
            const tenderType = data.lot?.nirTenderType?.label || '';
            const nirNumber = data.requestNumber;

            const preparedLabel = `Заявка № ${nirNumber} на конкурс "${programShortName}" (${[eventCode, tenderType]
              .filter(Boolean)
              .join('/')})`;

            onSuccessLoad({ label: preparedLabel, value: row.id });
          },
        },
      );
    },
    [getNirRequestAPI],
  );

  return {
    onSelectRow,
  };
};

export default useController;

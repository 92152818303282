import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { AuthorPayment } from 'types/models/Payment';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { isNextPaymentStatus } from 'utils/Helpers/authorPayments';
import { usePermissions } from '../usePermissions';
import { Color } from 'constants/colors';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void, needConfirm?: boolean): void;
  onChangeStatus(status: AuthorPaymentRequestStatus | null): void;
  onChangeConfirm(status: AuthorPaymentRequestStatus | null): void;
  paymentRequest?: AuthorPayment | null;
  isDisabled?: boolean;
};

export function useController(props: Props) {
  const { onSave, onChangeStatus, onChangeConfirm, paymentRequest, isDisabled } = props;

  const status = useMemo(() => paymentRequest?.status?.value as AuthorPaymentRequestStatus, [paymentRequest]);
  const mode = useMemo(() => (paymentRequest?.id ? 'edit' : 'add'), [paymentRequest]);

  const [isFeedlineModalOpen, setIsFeedlineModalOpen] = useState<boolean>();
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);

  const permissions = usePermissions(status);

  const changeStatusTo = useCallback(
    (x: AuthorPaymentRequestStatus | null) => {
      if (!checkPaymentByStatus(paymentRequest?.factNomineesMoney ?? null, x)) {
        onSave(false, () => {}, false);
        onChangeStatus(x);
      } else {
        onChangeConfirm(x);
      }
    },
    [onChangeStatus, onChangeConfirm, onSave, paymentRequest],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => {
          onSave(true);
        },
        isDisabled: isDisabled || status === AuthorPaymentRequestStatus.PAYMENT_APPROVED,
      },
      {
        icon: { type: 'toForward', color: Color.success },
        title: 'Подать на согласование',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.AGREEMENT as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden: !isNextPaymentStatus(status, AuthorPaymentRequestStatus.AGREEMENT),
      },
      {
        icon: { type: 'star', color: Color.warning },
        title: 'Передать на оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAID as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden:
          !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.PAID) || mode === 'add' || !paymentRequest?.id,
      },
      {
        icon: { type: 'like' },
        title: 'Одобрить оплату',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.PAYMENT_APPROVED as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden:
          !status ||
          !isNextPaymentStatus(status, AuthorPaymentRequestStatus.PAYMENT_APPROVED) ||
          mode === 'add' ||
          !paymentRequest?.id,
      },
      {
        icon: { type: 'toBack', color: Color.danger },
        title: 'Отправить на доработку',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REVISION as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden:
          !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.REVISION) || mode === 'add' || !paymentRequest?.id,
      },
      {
        icon: { type: 'clock' },
        title: 'Отложить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.POSTPONE as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden:
          !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.POSTPONE) || mode === 'add' || !paymentRequest?.id,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: () => changeStatusTo(AuthorPaymentRequestStatus.REJECTED as AuthorPaymentRequestStatus),
        permission: {
          name: permissions.mapStatusPermission[status],
        },
        isDisabled: isDisabled,
        isHidden:
          !status || !isNextPaymentStatus(status, AuthorPaymentRequestStatus.REJECTED) || mode === 'add' || !paymentRequest?.id,
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: () => {
          setIsFeedlineModalOpen(true);
        },
        isDisabled: isDisabled,
        isHidden: !paymentRequest?.id,
      },
    ],
    [isDisabled, status, permissions.mapStatusPermission, mode, paymentRequest?.id, onSave, changeStatusTo],
  );

  return {
    buttons,
    isFeedlineModalOpen,
    setIsFeedlineModalOpen,
    isHelpOpen,
    setIsHelpOpen,
  };
}

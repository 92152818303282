import { Table } from 'types/models';

type Arguments = {
  hasSelectButton?: boolean;
};

export function GetSecurityDocumentListForReport(args?: Arguments): Table.Specification {
  return {
    apiID: 'GetSecurityDocumentListForReport',
    header: {
      firstLevel: {
        title: 'Список ОИС',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: args?.hasSelectButton,
      },
    },
  };
}

import React from 'react';

import { Tab, Tabs } from 'components';

import { MyDocuments } from './MyDocuments';
import { UniversityDocuments } from './UniversityDocuments';
import { MyRequests } from './MyRequests';
import { ForeignDocuments } from './ForeignDocuments';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';

type Props = {
  personId: string | null;
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

export function SecurityDocuments(props: Props) {
  const { personId, defaultTabId, onTabClick } = props;

  const { userPermission } = useAppDataContext();

  if (!personId) {
    return null;
  }

  return (
    <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
      <Tab title="Мои заявки на ОИС" id="requests">
        <MyRequests />
      </Tab>
      <Tab title="Мои ОИС" id="documents" isVisible={isHasPermission(userPermission, Permits.PC_MENU_SECURITYDOCUMENTS_ACCESS)}>
        <MyDocuments />
      </Tab>
      <Tab
        title="ОИС Университета"
        id="university"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_ALLSECURITYDOCUMENTS_ACCESS)}
      >
        <UniversityDocuments />
      </Tab>
      <Tab
        title="ОИС не аффилированные с Университетом"
        id="foreign"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_FOREIGNSECURITYDOCUMENTS_ACCESS)}
      >
        <ForeignDocuments />
      </Tab>
    </Tabs>
  );
}

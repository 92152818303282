import React from 'react';
import { setup } from 'bem-cn';
import { v4 as uuidv4 } from 'uuid';

import { modes, types } from './helpers';
import { Color, ColorBase } from 'constants/colors';

import './style.scss';

const block = setup({
  mod: '--',
  modValue: '-',
});

const b = block('svg-icon');

type IconEvents = {
  onMouseEnter?(): void;
  onMouseLeave?(): void;
  onFocus?(): void;
  onBlur?(): void;
  onClick?(): void;
};

export type IconProps = IconEvents & {
  /** @property {React.MutableRefObject<SVGSVGElement | null>} refIcon - (optional) Reference to button */
  refIcon?: React.MutableRefObject<SVGSVGElement | null>;
  /** @property {keyof typeof types} type - (optional) Type of icon */
  type: keyof typeof types;
  /** @property {keyof typeof modes} mode - (optional) Mode of icon */
  mode?: keyof typeof modes;
  /** @property {number} size - (optional) Size of icon (20 by default) */
  size?: number;
  /** @property {Color | ColorBase} color - (optional) Color of icons expect default color */
  color?: Color | ColorBase;
  /** @property {string} hint - (optional) Title on hover */
  hint?: string;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
};

/**
 * Vector icon component (/icons-vector-demo) (old version /icons-demo)
 *
 * @param {React.MutableRefObject<SVGSVGElement | null>} refIcon - (optional) Reference to button
 * @param {keyof typeof types} type - Name of icon (see name on first block of page /icons-vector-demo)
 * @param {keyof typeof modes} mode - (optional) Mode of icon (see mode on second block of page /icons-vector-demo)
 * @param {number} size - (optional) Size of element (20 by default)
 * @param {Color | ColorBase} color - (optional) Set primary color of component (Color | ColorBase)
 * @param {string} hint - (optional) Tooltip on icon hover
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns JSX
 */
export const Icon = React.memo(({ refIcon, type, mode, size = 20, color, hint, classMixin, style, ...restProps }: IconProps) => {
  const maskId = `svg-action-mask-${uuidv4()}`;

  const content = <g className={b('content', { color: color })}>{types[type]}</g>;
  const action = mode ? <g className={b('action')}>{modes[mode]}</g> : false;

  return (
    <svg
      ref={refIcon}
      className={b({}).mix(classMixin)}
      width={size}
      height={size}
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      tabIndex={-1}
      style={style}
      {...(restProps as IconEvents)}
    >
      {hint && <title>{hint}</title>}
      {action ? (
        <>
          <defs>
            <mask id={maskId}>
              <path d="M0 0h20v9H9v11H0V0Z" fill="#ffffff" />
            </mask>
          </defs>
          <g mask={`url(#${maskId})`}>{content}</g>
          <svg x="10" y="10" width="10" height="10" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            {action}
          </svg>
        </>
      ) : (
        content
      )}
    </svg>
  );
});

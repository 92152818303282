import React from 'react';

import { Modal, RelationTableModal, Toolbar } from 'components';
import { Table } from 'types/models';
import { DataGrid } from 'features/Table/container';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { GetNotificationLogRecipientList } from 'features/Table/specifications/GetNotificationLogRecipientList';
import { GetNotificationMonitorList } from 'features/Table/specifications/GetNotificationMonitorList';
import { NotificationLogGridHelp } from './help';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState: { module } }: Props) {
  const {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isReceiversFormOpen,
    setIsReceiversFormOpen,
    selectedRow,
    notificationLog,
    setNotificationLog,
    isOpenNotificationMonitor,
    setIsOpenNotificationMonitor,
    isHelpOpen,
    setIsHelpOpen,
  } = useController({
    tableState,
    module,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title={`Просмотр уведомления от ${notificationLog?.date}: ${notificationLog?.theme}`}
        isOpen={isViewFormOpen}
        onClose={() => {
          setIsViewFormOpen(false);
          setNotificationLog(null);
        }}
        size="medium"
      >
        <div dangerouslySetInnerHTML={{ __html: notificationLog?.body || '' }} />
      </Modal>

      <Modal
        title="Список получателей письма"
        isOpen={isReceiversFormOpen}
        onClose={() => setIsReceiversFormOpen(false)}
        size={['medium', 'small-height']}
      >
        <DataGrid specification={GetNotificationLogRecipientList({ notificationLogId: selectedRow?.id })} />
      </Modal>

      {module && (
        <RelationTableModal
          specification={{ ...GetNotificationMonitorList({ module }) }}
          modalTitle="Наблюдатели (получение всех уведомлений этого модуля)"
          isOpen={isOpenNotificationMonitor}
          onClose={() => setIsOpenNotificationMonitor(false)}
        />
      )}

      <Modal
        mode="help"
        title={NotificationLogGridHelp(module || 'HELP_ADMIN').header}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {NotificationLogGridHelp(module || 'HELP_ADMIN').body}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

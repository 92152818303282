import { StreamRx } from 'StreamRx';

import { streams } from './streams';

type SuchLikePublicationStreams<T> = {
  [K in keyof T]: T[K];
};

export function useLocalStreams<T>(localStreams: T): SuchLikePublicationStreams<T> {
  return Object.fromEntries(
    Object.entries(localStreams as StreamRx<any>[]).map(([key, stream]: [string, any]) => [
      key,
      {
        push: (data: any) => stream.push(data),
        observable: stream.observable,
      },
    ]),
  ) as SuchLikePublicationStreams<T>;
}

export function useLocalSuchLikeEventStreams() {
  return useLocalStreams(streams);
}

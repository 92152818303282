import React from 'react';

import { ButtonMode, Modal, Toolbar } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { Table } from 'types/models';
import { useController } from './controller';
import { ADD_PUB_FEEDBACK_LABEL } from 'utils/Constants';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    handleFormSubmit,
    handleTemplateCloseAddForm,
    handleMessageChange,
    handleMessageLabelChange,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isFormOpen,
    publicationMessage,
    messageLabel,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      <AdministratorMessagePopup
        title={ADD_PUB_FEEDBACK_LABEL}
        onConfirm={handleFormSubmit}
        onConfirmWithoutMessage={handleTemplateCloseAddForm}
        isOpen={isFormOpen}
        publicationMessage={publicationMessage.message}
        updatePublicationMessage={handleMessageChange}
        publicationMessageType={messageLabel}
        setPublicationMessageType={handleMessageLabelChange}
        relationTableModalTitle="Сообщения журнала"
        cancelButtonText="Отмена"
        submitButtonText="Сохранить"
        command="RefPublicationFeedlineMessage"
      />

      {!isDeleteButtonDisabled && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            (действие: {tableState.selectedRows[0]?.TypeValue}, сообщение: {tableState.selectedRows[0]?.Message}) Удалить
            сообщение?
          </>
        </Modal>
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { CommunityMember } from 'types/models';

export function getMockCommunityMember(): CommunityMember {
  return {
    id: null,
    joinDate: '',
    leaveDate: '',
    role: null,
    person: null,
    vakSpeciality: null,
    cooperationType: null,
    description: '',
    community: null,
    documents: [],
    academicRank: null,
    degree: null,
    rank: null,
    citizenship: null,
    education: null,
    historyLabel: '',
    job: null,
  };
}

import React, { memo, useCallback } from 'react';

import { FormComponent, ReferenceItem, TextArea, TextAreaMode } from 'components';

import { CustomSelectSpecification } from 'features/BuildReportPopup/models';
import { Table } from 'types/models';

type Props<CustomState extends {} = {}, RequestData extends {} = {}> = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  fieldLabel: string;
  specification: CustomSelectSpecification<CustomState, RequestData>;
};

const CustomSelect = <CustomState extends {} = {}, RequestData extends {} = {}>({
  referenceItem,
  setReferenceItem,
  fieldLabel,
  specification,
}: Props<CustomState, RequestData>) => {
  const handleSetReferenceItem = useCallback(
    (nextReferenceItem: ReferenceItem | null) => {
      if (nextReferenceItem) {
        setReferenceItem(nextReferenceItem);
      }
    },
    [setReferenceItem],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field label={fieldLabel} isRequired>
        <TextArea
          mode={TextAreaMode.TABLE}
          settings={{
            title: specification.relationTableModalTitle,
            isClearable: false,
            table: {
              specification: specification.modalTableSpecification,
              onSelect: (row: Table.Entry | null) =>
                handleSetReferenceItem(row ? specification.modalTableRowConverter(row) : null),
            },
          }}
          value={referenceItem?.label}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(CustomSelect);

export { Component as CustomSelect };

import { TenderKindConsumption } from 'types/models/KindConsumption';

export function getMockTenderKindConsumption(item?: Partial<TenderKindConsumption>): TenderKindConsumption {
  return {
    id: null,
    name: '',
    description: '',
    incrimentKindConsumptions: [],
    kindConsumption: null,
    incrimenator: null,
    position: '',
    ...item,
  };
}

import { Table as T } from 'types/models';

type Props = {
  projectCodeId: string;
  year?: string;
  isFiltersHidden?: boolean;
  title?: string;
};

export function GetProjectCodeReceivingDocumentList({ projectCodeId, year, isFiltersHidden, title }: Props): T.Specification {
  const requestData = { projectCodeId, year };
  return {
    apiID: 'GetProjectCodeReceivingDocumentList',
    isFiltersHidden,
    requestData,
    header: {
      ...(title
        ? {
            firstLevel: {
              title,
            },
          }
        : {}),
      thirdLevel: {
        withTemplatesPanel: true,
      },
    },
  };
}

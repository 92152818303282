import { ProjectAssignment } from 'types/models/Project';

export function validate(projectAssignment: ProjectAssignment) {
  if (!projectAssignment) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidProject = Boolean(projectAssignment.projectId);
  const isValidDate = !!projectAssignment.date;
  const isValidAssignment = Boolean(projectAssignment.assignment);
  const isValidCurPersonInProjectScientists = (projectAssignment?.projectScientists?.length || 0) > 0;
  return [
    { isValid: isValidProject, invalidMessage: 'Выберите проект' },
    { isValid: isValidDate, invalidMessage: 'Укажите дату поручения' },
    { isValid: isValidAssignment, invalidMessage: 'Задайте текст поручения' },
    {
      isValid: isValidCurPersonInProjectScientists,
      invalidMessage: 'Выберете ответственное лицо из списка участников проекта',
    },
  ];
}

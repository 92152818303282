import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetNirRequestList, GetNotificationLogList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { NotificationEventModule } from 'utils/Enums';

export function NirRequests() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['requests', 'nir-requests'],
    init: ['list'],
  });

  const { userPermission } = useAppDataContext();

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab
          title="Список заявок на внутренний конкурс"
          id="list"
          isVisible={isHasPermission(userPermission, [Permits.NIR_REQUEST_VIEW_ALL, Permits.NIR_REQUEST_VIEW_BY_MANAGEMENT])}
        >
          <DataGrid specification={GetNirRequestList({ isForProject: false, hasSelectButton: false })} />
        </Tab>
        <Tab
          title="Журнал уведомлений"
          id="notifications"
          isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
        >
          <DataGrid specification={GetNotificationLogList({ module: NotificationEventModule.NIR_REQUEST })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

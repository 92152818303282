import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import { parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import type { MobileRequest, Document } from 'types/models';
import { showNotification } from 'features/Notifications';
import { MobileRequestFormLook } from 'types/models/Form';
import { Member } from 'features/SelectPerson';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { formatStr } from 'utils/Constants';
import { MobileRequestStatus, MobileRequestPurpose } from 'utils/Enums';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { Report } from 'types/models';
import { convertMemberToMobileRequest, getEstimatePositionsAmount, getFinancingsAmount, getMockMobileRequest } from './helpers';
import { useFormContext } from 'features/Form/hooks';
import { validateMobileRequest } from './validators';
import { getMockLot, getMockTender } from 'features/Form/looks/tender/TenderForm/helpers';
import { Permits } from 'utils/Permissions';
import { Color } from 'constants/colors';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  onClose: () => void;
};

type SubmitArgs = {
  savedMobileRequest?: MobileRequest.MobileRequest;
  needClose?: boolean;
  newStatus?: MobileRequestStatus;
  isApprovement?: boolean;
  isMobileProtocolProject?: boolean;
  newId?: string;
};

export function useController({ onClose }: Props) {
  const {
    look: { id, viewMode, editMode, type, tenderInfo },
  } = useFormContext<MobileRequestFormLook>();

  const { settings } = useAppDataContext();

  const tableStreams = useLocalTableStreams();

  const organizationName = settings?.organization?.shortName || '';

  const reports = useMemo<Report[]>(
    () => [
      Reports.MobileRequest,
      Reports.MobileRequestAnalytic,
      Reports.MobileRequestOrderOtherAppointment,
      Reports.MobileRequestMoneyOrder,
      Reports.MobileRequestTaskOtherAppointment,
      Reports.MobileRequestEstimate,
      Reports.MobileRequestOrderProject,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const [mobileRequest, setMobileRequest] = useState<MobileRequest.MobileRequest>({
    ...getMockMobileRequest(id || null),
    tender: tenderInfo?.tenderId ? { ...getMockTender({}), id: tenderInfo?.tenderId } : null,
    lot: tenderInfo?.lotId ? { ...getMockLot(), id: tenderInfo?.lotId } : null,
  });
  const [nextStatus, setNextStatus] = useState<MobileRequestStatus>(MobileRequestStatus.DRAFT);
  const [isStatusChangePopupOpen, setIsStatusChangePopupOpen] = useState<boolean>(false);
  const [statusChangeMessage, setStatusChangeMessage] = useState<string>('');
  const [isApprovementModalOpen, setIsApprovementModalOpen] = useState<boolean>(false);
  const [isPersonViewFormOpen, setIsPersonViewFormOpen] = useState<boolean>(false);
  const [isMobileProtocolProjectPopupOpen, setIsMobileProtocolProjectPopupOpen] = useState<boolean>(false);

  const status = mobileRequest.status?.value as MobileRequestStatus;

  const { methods: getMobileRequest } = BackendAPI.useBackendAPI('GetMobileRequest');
  const { methods: saveMobileRequest } = BackendAPI.useBackendAPI('SaveMobileRequest');
  const { methods: changeMobileRequestStatus } = BackendAPI.useBackendAPI('ChangeMobileRequestStatus');
  const { methods: addApprovementAPI } = BackendAPI.useBackendAPI('AddMobileRequestApprovement');
  const { methods: setMobileProtocolProjectForMobileRequest } = BackendAPI.useBackendAPI(
    'SetMobileProtocolProjectForMobileRequest',
  );

  const traineeDays = useMemo(() => {
    if (!mobileRequest.traineeStartDate || !mobileRequest.traineeEndDate) return null;
    const startDate: Date = parse(mobileRequest.traineeStartDate, formatStr, new Date());
    const endDate: Date = parse(mobileRequest.traineeEndDate, formatStr, new Date());
    if (startDate && endDate) {
      return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (60 * 60 * 24 * 1000)) + 1;
    }
    return 0;
  }, [mobileRequest.traineeEndDate, mobileRequest.traineeStartDate]);

  const businesTripDays = useMemo(() => {
    if (!mobileRequest.businessTripStartDate || !mobileRequest.businessTripEndDate) return null;
    const startDate: Date = parse(mobileRequest.businessTripStartDate, formatStr, new Date());
    const endDate: Date = parse(mobileRequest.businessTripEndDate, formatStr, new Date());
    if (startDate && endDate) {
      return Math.round(Math.abs(endDate.getTime() - startDate.getTime()) / (60 * 60 * 24 * 1000)) + 1;
    }
    return 0;
  }, [mobileRequest.businessTripEndDate, mobileRequest.businessTripStartDate]);

  const estimateMessage = useMemo(() => {
    const estimatePositionsAmount = getEstimatePositionsAmount(mobileRequest.estimatePositions);
    const financingsAmount = getFinancingsAmount(mobileRequest.financings);
    const result =
      estimatePositionsAmount > financingsAmount
        ? `Не распределено ${estimatePositionsAmount - financingsAmount} руб.`
        : estimatePositionsAmount < financingsAmount
        ? `Суммы по источникам превышают общую сумму по смете на ${financingsAmount - estimatePositionsAmount} руб.`
        : !!estimatePositionsAmount && estimatePositionsAmount === financingsAmount
        ? 'Вся сумма распределена'
        : null;
    return result;
  }, [mobileRequest.estimatePositions, mobileRequest.financings]);

  const statusChangePopupTitle = useMemo(() => {
    const statusChangeTitle: Record<MobileRequestStatus, string> = {
      [MobileRequestStatus.DRAFT]: 'Перевод заявки в черновик',
      [MobileRequestStatus.REQUEST]: 'Подача на согласование',
      [MobileRequestStatus.REVISION]: 'Отправка заявки на доработку',
      [MobileRequestStatus.RECOMENDED]: 'Рекомендация заявки на конкурс',
      [MobileRequestStatus.REJECTED]: 'Отклонение заявки',
      [MobileRequestStatus.APPROVED]: 'Поддержка заявки',
      [MobileRequestStatus.SENT]: 'Подача заявки на конкурс',
    };
    return statusChangeTitle[nextStatus] || '';
  }, [nextStatus]);

  const statusChangePopupConfirmLabel = useMemo(() => {
    const statusChangeLabel: Record<MobileRequestStatus, string> = {
      [MobileRequestStatus.DRAFT]: 'Перевести в черновик',
      [MobileRequestStatus.REQUEST]: 'Подать на согласование',
      [MobileRequestStatus.REVISION]: 'Отправить на доработку',
      [MobileRequestStatus.RECOMENDED]: 'Рекомендовать на конкурс',
      [MobileRequestStatus.REJECTED]: 'Отклонить',
      [MobileRequestStatus.APPROVED]: 'Поддержать',
      [MobileRequestStatus.SENT]: 'Подать на конкурс',
    };
    return statusChangeLabel[nextStatus] || '';
  }, [nextStatus]);

  const handleApplicantChange = useCallback(
    (e: Member) => {
      const value: MobileRequest.MobileRequest = convertMemberToMobileRequest(mobileRequest, e);
      setMobileRequest({
        ...value,
        applicantContact: getPersonContacts(value.applicant?.contacts),
        applicantDepartment: null,
        applicantLanguageCompetence: value.applicant?.scientist?.languageCommunication || '',
        applicantNote: '',
      });
    },
    [mobileRequest],
  );

  const handleApplicantDepartmentChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantDepartment: e }));
  }, []);

  const handleApplicantContactChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantContact: value }));
  }, []);

  const handleApplicantLanguageCompetenceChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantLanguageCompetence: value }));
  }, []);

  const handleApplicantNoteChange = useCallback((e: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantNote: e }));
  }, []);

  const handleApplicantSupervisorsChange = useCallback((e: MobileRequest.ApplicantSupervisor[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantSupervisors: e }));
  }, []);

  const handleBusinessTripRangeDateChange = useCallback(
    (fieldName: 'businessTripStartDate' | 'businessTripEndDate', value: string) => {
      setMobileRequest((prevState: MobileRequest.MobileRequest) => ({
        ...prevState,
        [fieldName]: value,
      }));
    },
    [],
  );
  const handleTraineeRangeDateChange = useCallback((fieldName: 'traineeStartDate' | 'traineeEndDate', value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({
      ...prevState,
      [fieldName]: value,
    }));
  }, []);

  const handleRoutesChange = useCallback((e: MobileRequest.Route[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, routes: e }));
  }, []);

  const handlePurposeChange = useCallback((e: Item) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, purpose: e }));
  }, []);

  const themeFieldLabel = useMemo(() => {
    if (mobileRequest.purpose?.value === MobileRequestPurpose.TRAINEE) {
      return 'Тема';
    } else {
      return 'Название мероприятия';
    }
  }, [mobileRequest]);

  const handleActivityKindChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, activityKind: e }));
  }, []);

  const handleVisaChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, visa: e }));
  }, []);

  const handleThemeChange = useCallback((e: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, theme: e }));
  }, []);

  const handleLanguageChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, language: e }));
  }, []);

  const handleTraineeNecessityDocumentChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, traineeNecessityDocument: value }));
  }, []);

  const handlePnrsChange = useCallback((e: ReferenceItem[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, pnrs: e }));
  }, []);

  const handleTripPlanesChange = useCallback((e: MobileRequest.TripPlan[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, tripPlanes: e }));
  }, []);

  const handleUseMaterialObligationsPlanesChange = useCallback((e: MobileRequest.UseMaterialObligation[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, useMaterialObligations: e }));
  }, []);

  const handleAdvertisingMaterialTypesChange = useCallback((e: ReferenceItem[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, advertisingMaterialTypes: e }));
  }, []);

  const handleEstimatePositionsChange = useCallback((e: MobileRequest.MobileRequestEstimatePosition[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, estimatePositions: e }));
  }, []);

  const handleFinancingsChange = useCallback((e: MobileRequest.Financing[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, financings: e }));
  }, []);

  const handleDocumentsChange = useCallback((e: Document[]) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, documents: e }));
  }, []);

  const handleStatusChangeMessageChange = useCallback((value: string) => {
    setStatusChangeMessage(value);
  }, []);

  const handleEducationYearChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({
      ...prevState,
      applicantEducationYear: !!value ? Number(value) : null,
    }));
  }, []);

  const handleEducationGroupChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, applicantGroupNumber: value }));
  }, []);

  const handleDescriptionChange = useCallback((e: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, description: e }));
  }, []);

  const handleCodeChange = useCallback((value: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, code: value }));
  }, []);

  const handleSendDateChange = useCallback((e: string) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, sendDate: e }));
  }, []);

  const handleTenderParticipantChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, tenderParticipant: e }));
  }, []);

  const handleScienceDomainInterrestChange = useCallback((e: ReferenceItem) => {
    setMobileRequest((prevState: MobileRequest.MobileRequest) => ({ ...prevState, scienceDomainInterrest: e }));
  }, []);

  const makeChangeHandler = (key: keyof MobileRequest.MobileRequest) => (value: string) => {
    setMobileRequest(prev => ({ ...prev!, [key]: value }));
  };

  const finalCallback = useCallback(
    ({ savedMobileRequest, needClose, isApprovement, isMobileProtocolProject, newId }: SubmitArgs) => {
      setIsStatusChangePopupOpen(false);
      setIsApprovementModalOpen(false);
      setIsMobileProtocolProjectPopupOpen(false);
      setStatusChangeMessage('');

      const notificationMessage: string = isMobileProtocolProject
        ? 'Заявка добавлена в черновик протокола'
        : isApprovement
        ? 'Виза согласования добавлена'
        : 'Заявка успешно сохранена';
      showNotification({ message: notificationMessage, theme: 'success' });
      if (needClose) {
        onClose();
        tableStreams.reloadTable.push({});
      } else {
        getMobileRequest.callAPI(
          { ...(savedMobileRequest || mobileRequest), ...(newId ? { id: newId } : {}) },
          {
            onSuccessfullCall: ({ data }) => {
              const preparedData = data as MobileRequest.MobileRequest;
              setMobileRequest(preparedData);
            },
          },
        );
        tableStreams.reloadTable.push({});
      }
    },
    [getMobileRequest, mobileRequest, onClose, tableStreams.reloadTable],
  );

  const onSubmit = useCallback(
    ({ needClose, newStatus, isApprovement, isMobileProtocolProject }: SubmitArgs) => {
      if (!validateMobileRequest({ mobileRequest, nextStatus: newStatus })) return;
      if (newStatus) {
        setNextStatus(newStatus);
        setIsStatusChangePopupOpen(true);
      } else if (isMobileProtocolProject) {
        setIsMobileProtocolProjectPopupOpen(true);
      } else if (isApprovement) {
        setIsApprovementModalOpen(true);
      } else {
        saveMobileRequest.callAPI(
          { mobileRequest },
          {
            onSuccessfullCall: ({ data }) => {
              finalCallback({ needClose, newId: data });
            },
          },
        );
      }
    },
    [finalCallback, mobileRequest, saveMobileRequest],
  );

  const changeStatus = useCallback(() => {
    saveMobileRequest.callAPI(
      { mobileRequest, isPrepareSave: true },
      {
        onSuccessfullCall: ({ data }) => {
          changeMobileRequestStatus.callAPI(
            {
              ids: [data],
              status: nextStatus,
              message: statusChangeMessage,
            },
            {
              onSuccessfullCall: () => {
                const nextStatusMessage: Record<MobileRequestStatus, string> = {
                  [MobileRequestStatus.DRAFT]: 'Заявка переведена в черновик',
                  [MobileRequestStatus.REQUEST]: 'Заявка подана на согласование',
                  [MobileRequestStatus.REVISION]: 'Заявка отправлена на доработку',
                  [MobileRequestStatus.RECOMENDED]: 'Заявка рекомендована на конкурс',
                  [MobileRequestStatus.REJECTED]: 'Заявка отклонена',
                  [MobileRequestStatus.APPROVED]: 'Заявка одобрена',
                  [MobileRequestStatus.SENT]: 'Заявка подана на конкурс',
                };
                showNotification({ message: nextStatusMessage[nextStatus], theme: 'success' });
                tableStreams.reloadTable.push({});
                onClose();
              },
            },
          );
        },
      },
    );
  }, [
    saveMobileRequest,
    mobileRequest,
    changeMobileRequestStatus,
    nextStatus,
    statusChangeMessage,
    tableStreams.reloadTable,
    onClose,
  ]);

  const setMobileProtocolProject = useCallback(() => {
    saveMobileRequest.callAPI(
      { mobileRequest, isPrepareSave: true },
      {
        onSuccessfullCall: () => {
          setMobileProtocolProjectForMobileRequest.callAPI(
            {
              id: mobileRequest.id || '',
              type,
              isInclude: true,
            },
            {
              onSuccessfullCall: () => {
                finalCallback({ isMobileProtocolProject: true });
              },
            },
          );
        },
      },
    );
  }, [finalCallback, mobileRequest, saveMobileRequest, setMobileProtocolProjectForMobileRequest, type]);

  const saveApprovement = useCallback(
    (m: string, s: string) => {
      saveMobileRequest.callAPI(
        { mobileRequest },
        {
          onSuccessfullCall: ({ data }) => {
            addApprovementAPI.callAPI(
              { mobileRequestId: data, status: s, message: m },
              {
                onSuccessfullCall: () => {
                  finalCallback({ isApprovement: true, newId: data });
                },
              },
            );
          },
        },
      );
    },
    [addApprovementAPI, finalCallback, mobileRequest, saveMobileRequest],
  );

  const handleStatusChangePopupConfirm = useCallback(() => {
    changeStatus();
  }, [changeStatus]);

  const handleStatusChangePopupClose = useCallback(() => {
    setIsStatusChangePopupOpen(false);
    setStatusChangeMessage('');
  }, []);
  const handleMobileProtocolProjectPopupConfirm = useCallback(() => {
    setMobileProtocolProject();
  }, [setMobileProtocolProject]);

  const handleMobileProtocolProjectPopupClose = useCallback(() => {
    setIsMobileProtocolProjectPopupOpen(false);
  }, []);

  const handleSaveButtonClick = useCallback(() => {
    onSubmit({ needClose: true });
  }, [onSubmit]);

  const handleSaveAndContinueButtonClick = useCallback(() => {
    onSubmit({});
  }, [onSubmit]);

  const handleStatusChangeButtonClick = useCallback(
    (newStatus: MobileRequestStatus) => {
      onSubmit({ newStatus });
    },
    [onSubmit],
  );

  const handleSetMobileProtocolProjectButtonClick = useCallback(() => {
    onSubmit({ isMobileProtocolProject: true });
  }, [onSubmit]);

  const handlePersonViewFormOpenButtonClick = useCallback(() => {
    setIsPersonViewFormOpen(true);
  }, []);

  const handlePersonViewFormClose = useCallback(() => {
    setIsPersonViewFormOpen(false);
  }, []);

  const toolbarButtons: ButtonProps[] = [
    {
      icon: { type: 'save' },
      title: 'Сохранить',
      onClick: handleSaveButtonClick,
      isDisabled: status === MobileRequestStatus.DRAFT || !!viewMode,
    },
    {
      icon: { type: 'save', mode: 'add' },
      title: 'Сохранить и продолжить',
      onClick: handleSaveAndContinueButtonClick,
      isDisabled: status === MobileRequestStatus.DRAFT || !!viewMode,
    },
    {
      icon: { type: 'new', mode: 'add' },
      title: 'Сохранить как черновик',
      onClick: handleSaveButtonClick,
      isDisabled: status !== MobileRequestStatus.DRAFT || !!viewMode,
    },
    {
      icon: { type: 'new' },
      title: 'В черновик',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.DRAFT),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_DRAFT },
      isHidden: !!viewMode || [MobileRequestStatus.DRAFT].includes(status),
    },
    // Temporary comment
    // {
    //   icon: { type: 'report' },
    //   title: 'Подать на конкурс',
    //   onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.SENT),
    //   permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_SENT },
    //   isHidden: !!viewMode || ![MobileRequestStatus.RECOMENDED].includes(status),
    // },
    {
      icon: { type: 'like' },
      title: 'Рекомендовать на конкурс',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.RECOMENDED),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_RECOMENDED },
      isHidden: !!viewMode || ![MobileRequestStatus.REQUEST].includes(status),
    },
    {
      icon: { type: 'toForward', color: Color.success },
      title: 'На согласование',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.REQUEST),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REQUEST },
      isHidden: !!viewMode || ![MobileRequestStatus.DRAFT, MobileRequestStatus.REVISION].includes(status),
    },
    {
      icon: { type: 'star', color: Color.warning },
      title: 'Поддержать',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.APPROVED),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_APPROVED },
      isHidden: !!viewMode || ![MobileRequestStatus.SENT].includes(status),
    },
    {
      icon: { type: 'toBack', color: Color.danger },
      title: 'Отправить на доработку',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.REVISION),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REVISION },
      isHidden: !!viewMode || ![MobileRequestStatus.REQUEST, MobileRequestStatus.REJECTED].includes(status),
    },
    {
      icon: { type: 'dislike' },
      title: 'Отклонить',
      onClick: () => handleStatusChangeButtonClick(MobileRequestStatus.REJECTED),
      permission: { name: Permits.MOBILE_REQUEST_CHANGE_STATUS_REJECTED },
      isHidden: !!viewMode || ![MobileRequestStatus.REQUEST, MobileRequestStatus.SENT].includes(status),
    },
    {
      icon: { type: 'message', mode: 'check' },
      title: 'Экспертная оценка',
      onClick: () => onSubmit({ isApprovement: true }),
      permission: { name: Permits.MOBILE_REQUEST_AGREE },
      isHidden: status === MobileRequestStatus.DRAFT || !!viewMode,
    },
    {
      icon: { type: 'list', mode: 'add' },
      title: 'Включить в протокол',
      onClick: handleSetMobileProtocolProjectButtonClick,
      isHidden: status !== MobileRequestStatus.REQUEST || !!viewMode,
    },
    {
      icon: { type: 'user', mode: 'view' },
      title: 'Просмотр карточки заявителя',
      onClick: handlePersonViewFormOpenButtonClick,
      isDisabled: !mobileRequest.applicant?.id,
    },
    {
      icon: { type: 'print' },
      title: 'Отчеты',
      expandedList: { list: getReports, callback: handleSetCurrentReport },
    },
  ];

  useLayoutEffect(() => {
    getMobileRequest.callAPI(mobileRequest, {
      onSuccessfullCall: ({ data }) => {
        const preparedData = data as MobileRequest.MobileRequest;
        setMobileRequest(preparedData);
      },
    });
    // eslint-disable-next-line
  }, []);

  return {
    mobileRequest,
    editMode,
    viewMode,
    handleDocumentsChange,
    handleApplicantChange,
    handleApplicantDepartmentChange,
    handleApplicantContactChange,
    handleApplicantLanguageCompetenceChange,
    handleApplicantNoteChange,
    handleApplicantSupervisorsChange,
    handleBusinessTripRangeDateChange,
    handleTraineeRangeDateChange,
    handleRoutesChange,
    handlePurposeChange,
    handleActivityKindChange,
    handleVisaChange,
    handleThemeChange,
    handleLanguageChange,
    handleTraineeNecessityDocumentChange,
    handlePnrsChange,
    organizationName,
    handleTripPlanesChange,
    handleUseMaterialObligationsPlanesChange,
    handleAdvertisingMaterialTypesChange,
    traineeDays,
    businesTripDays,
    handleEstimatePositionsChange,
    handleFinancingsChange,
    type,
    estimateMessage,
    toolbarButtons,
    isStatusChangePopupOpen,
    handleStatusChangePopupConfirm,
    handleStatusChangePopupClose,
    statusChangeMessage,
    handleStatusChangeMessageChange,
    statusChangePopupTitle,
    statusChangePopupConfirmLabel,
    isPersonViewFormOpen,
    handlePersonViewFormClose,
    isReportOpen,
    onReportClose,
    currentReport,
    isMobileProtocolProjectPopupOpen,
    handleMobileProtocolProjectPopupConfirm,
    handleMobileProtocolProjectPopupClose,
    handleEducationYearChange,
    handleEducationGroupChange,
    themeFieldLabel,
    handleDescriptionChange,
    handleCodeChange,
    handleSendDateChange,
    handleTenderParticipantChange,
    handleScienceDomainInterrestChange,
    status,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    makeChangeHandler,
  };
}

import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Mode, State } from 'features/Table/specifications/GetPublicationCitationSystemList/makeUseCustomController';

export type Props = {
  mode: Mode;
  customState: State;
};

const ModalTemplate: React.FC<F.TemplateProps & Props> = ({
  mode,
  isOpen,
  onClose,
  id,
  relatedTableState,
  customState,
}: F.TemplateProps & Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.Look & Props = {
    apiID: 'GetPublicationCitationSystem',
    template: 'CitationSystemForm',
    mode,
    id,
    relatedTableState,
    setTitle,
    customState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ModalTemplate);

import React, { useMemo } from 'react';

import { ButtonMode, Modal, RelationTableModal } from 'components';

import { Table } from 'types/models';
import useController from './controller';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { ParticipationTypeCode } from 'types/models/Participation';
import { GetEventParticipationSimpleList } from 'features/Table/specifications/GetEventParticipationSimpleList';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  eventId: string;
  eventStatus: string;
  eventName: string;
  workMode: Table.WorkMode;
  type: ParticipationTypeCode;
  isOpen: boolean;
  onClose: () => void;
  onAccept: () => void;
};

const SimilarParticipations = ({ eventId, eventStatus, eventName, workMode, type, isOpen, onClose, onAccept }: Props) => {
  const {
    isWarningPopupOpen,
    attachParticipation,
    userSystemDepartment,
    setSelectedParticipation,
    setIsWarningPopupOpen,
    isHasParticipationPermission,
  } = useController({
    type,
    onAccept,
  });

  const title = useMemo(() => {
    const baseTitle = (() => {
      switch (type) {
        case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
          return 'Список участий, связанных с конференцией';
        case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
          return 'Список участий, связанных с выставкой';
        case ParticipationTypes.PARTICIPATION_CONTEST.code:
          return 'Список участий, связанных с конкурсом';
        case ParticipationTypes.PARTICIPATION_CONCERT.code:
          return 'Список участий, связанных с концертом';
        default:
          return '';
      }
    })();

    const eventTitle = (() => {
      const typeName = (() => {
        switch (type) {
          case ParticipationTypes.PARTICIPATION_CONFERENCE.code:
            return 'Конференция';
          case ParticipationTypes.PARTICIPATION_EXPOSITION.code:
            return 'Выставка';
          case ParticipationTypes.PARTICIPATION_CONTEST.code:
            return 'Конкурс';
          case ParticipationTypes.PARTICIPATION_CONCERT.code:
            return 'Творческое мероприятие';
          default:
            return '';
        }
      })();

      const details = [eventId ? `ID: ${eventId}` : '', eventStatus ? `Статус: ${eventStatus}` : ''].filter(Boolean).join(', ');

      return ['Мероприятие:', typeName ? `${typeName}:` : '', eventName, details ? `(${details})` : ''].filter(Boolean).join(' ');
    })();

    return (
      <>
        <h3>{baseTitle}</h3>
        <h4>{eventTitle}</h4>
      </>
    );
  }, [eventId, eventName, eventStatus, type]);

  return (
    <>
      <RelationTableModal
        isOpen={isOpen}
        onClose={onClose}
        modalTitle={title}
        helpText={
          isHasParticipationPermission
            ? 'Если в списке есть участие, которое вы хотели добавить, выберите его'
            : 'Недостаточно прав для привязки к подраздерелению'
        }
        specification={GetEventParticipationSimpleList(
          {
            eventId: eventId || '-1',
            onSubmitTable: ({ selectedRows: [row] }: SubmitTable) => {
              setSelectedParticipation(row);
              setIsWarningPopupOpen(true);
            },
          },
          workMode,
        )}
      />

      <Modal
        mode="warning"
        title="Привязка к подразделению"
        isOpen={isWarningPopupOpen}
        onClose={() => setIsWarningPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => attachParticipation(onClose),
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsWarningPopupOpen(false),
          },
        ]}
        size="medium"
      >
        <>Привязать участие к своему подразделению "{userSystemDepartment?.label}"?</>
      </Modal>
    </>
  );
};

export const Component = React.memo(SimilarParticipations);

import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { tabsStreams } from 'components/Tabs/streams';

import { Form, Author, Document, Award, Event } from 'types/models';
import { ReportType, Participation, Department, ParticipationProject } from 'types/models/Participation';
import { useLocalTableStreams } from 'features/Table/hooks';
import useWorkModeHook from 'features/Form/hooks/workModeHook';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { EMPTY_TABLE_STATE } from 'features/Form/constants';
import { useAppDataContext } from 'features/AppData/context';
import { formatMainAuthor } from 'features/Form/looks/participation/views/MembersList/helpers';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { EventFormat } from 'utils/Enums';
import { ParticipationProjectType } from 'utils/Enums/ParticipationProjectType';
import { ParticipationForm } from 'utils/Enums/ParticipationForm';
import { ReportType as ReportTypeEnum } from 'utils/Enums/ReportType';
import useIsLoading from 'features/Form/hooks/useIsLoading';
import { EventInput } from 'services/BackendAPI/configurations/event/types';
import { useFormContext } from 'features/Form/hooks';
import { validate, validateDocuments, validateReport, validateProjectReport } from './validate';
import { setByResponse } from './setup/setByResponse';
import { formatToRequest } from './setup/formatToRequest';
import { showNotification } from 'features/Notifications';
import { getEnumItem } from 'utils/Helpers';
import { Item } from 'types/models/common';

type Props = {
  onClose: () => void;
};

const tabsId = 'ParticipationFormConference';

const useController = ({ onClose }: Props) => {
  const { currentPerson, enumMap } = useAppDataContext();
  const {
    look: {
      id,
      relatedTableState,
      editMode,
      viewMode,
      disabledEvent,
      sourceEvent,
      disableUpdateAfterSubmit,
      sourcePublication,
      arm,
      reloadEvent,
    },
  } = useFormContext<Form.ParticipationFormLook>();

  const { isProfile } = usePrivatePageContext();
  const { workMode, updateWorkModeAfterSaveAndContinue } = useWorkModeHook({ viewMode, editMode });

  const tableStreams = useLocalTableStreams();

  const { methods: getParticipation, state: getParticipationState } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation, state: saveParticipationState } = BackendAPI.useBackendAPI('SaveParticipation');
  const { methods: getEvent, state: getEventState } = BackendAPI.useBackendAPI('GetEvent');
  const { methods: getPublication, state: getPublicationState } = BackendAPI.useBackendAPI('GetPublication');
  const { methods: getReferenceElementsAPI, state: getReferenceElementsAPIState } = BackendAPI.useBackendAPI(
    'GetReferenceElements',
  );
  const {
    methods: getReportExtramuralWithPublicationTypeAPI,
    state: getReportExtramuralWithPublicationTypeAPIState,
  } = BackendAPI.useBackendAPI('GetReferenceElements');
  const { methods: getParticipationFormAPI, state: getParticipationFormAPIState } = BackendAPI.useBackendAPI(
    'GetReferenceElements',
  );
  const { isLoading } = useIsLoading({
    callStates: [
      getParticipationState,
      saveParticipationState,
      getEventState,
      getPublicationState,
      getReferenceElementsAPIState,
      getReportExtramuralWithPublicationTypeAPIState,
      getParticipationFormAPIState,
    ],
  });

  // popups
  const [isEventSelectWarningOpen, setIsEventSelectWarningOpen] = useState<boolean>(false);

  // fields
  const [members, setMembers] = useState<Author[]>([]);
  const [needClose, setNeedClose] = useState<boolean>(false);
  const [participationMessage, setParticipationMessage] = useState<string>('');
  const [participationMessageType, setParticipationMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isAdminMessagePopupOpen, setIsAdminMessagePopupOpen] = useState<boolean>(false);
  const [currentParticipationId, setCurrentParticipationId] = useState<null | string>(id || null);

  // const [isReportErrorPopupOpen, setIsReportErrorPopupOpen] = useState<boolean>(false);
  // const [isReportInfoPopupOpen, setIsReportInfoPopupOpen] = useState<boolean>(false);
  const [isSimilarParticipationOpen, setIsSimilarParticipationOpen] = useState<boolean>(false);
  const [isDocumentsPopupOpened, setIsDocumentsPopupOpened] = useState<boolean>(false);
  const [isReportPopupOpened, setIsReportPopupOpened] = useState<boolean>(false);
  const [isProjectReportPopupOpened, setIsProjectReportPopupOpened] = useState<boolean>(false);
  const [documents, setDocuments] = useState<Document[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);
  const [awards, setAwards] = useState<Award[]>([]);
  const [event, setEvent] = useState<Event.Event | null>(null);
  const [participationData, setParticipationData] = useState<null | Participation>(null);
  const [isEditDepartmentsOpen, setIsEditDepartmentsOpen] = useState<boolean>(false);
  const [isEditDepartmentsToAddedFormOpen, setIsEditDepartmentsToAddedFormOpen] = useState<boolean>(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);
  const [sourcePublicationId, setSourcePublicationId] = useState<string | null>(null);
  const [financingProjects, setFinancingProjects] = useState<ParticipationProject[]>([]);
  const [isOpenBySourcePublication, setIsOpenBySourcePublication] = useState<boolean>(false);
  const [publication, setPublication] = useState<Form.Publication | null>(null);
  const [departments, setDepartments] = useState<Department[]>([]);
  const [isAuthorsPopupOpen, setIsAuthorsPopupOpen] = useState<boolean>(false);
  const [formFields, setFormFields] = useState<Form.Fields>({
    isPublished: {
      isValid: true,
      value: false,
      title: 'Публикация доклада будет добавлена позже',
      onChange: (value: boolean) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          isPublished: {
            ...prevState.isPublished,
            value,
          },
        }));
      },
    },
    reportDate: {
      isValid: true,
      required: true,
      value: '',
      title: 'Дата доклада',
      onChange: (nextDate: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          reportDate: { ...prevState.reportDate, value: nextDate || '', isValid: true },
        }));
      },
    },
    note: {
      isValid: true,
      value: '',
      title: 'Примечание',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          note: { ...prevState.note, value },
        }));
      },
    },
    form: {
      isValid: true,
      required: true,
      value: '',
      title: 'Формат участия',
      onChange: (value: ReferenceItem | null) => {
        if (value?.customFields?.code === ParticipationForm.CONFERENCE_WITH_REPORT_PARTICIPATION) {
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            form: { ...prevState.form, value, isValid: true },
            reportType: { ...prevState.reportType, required: true },
            reportDate: {
              ...prevState.reportDate,
              value: sourceEvent?.startDate && workMode === 'addMode' ? sourceEvent?.startDate : prevState.reportDate.value,
              required: true,
            },
            reportTheme: { ...prevState.reportTheme, required: true },
          }));
        } else {
          setFormFields((prevState: Form.Fields) => ({
            ...prevState,
            form: { ...prevState.form, value, isValid: true },
            isPublished: { ...prevState.isPublished, value: false, isValid: true },
            reportType: { ...prevState.reportType, value: null, required: false },
            reportDate: { ...prevState.reportDate, value: '', required: false },
            reportTheme: { ...prevState.reportTheme, value: '', required: false },
            publicationReportTheme: { ...prevState.publicationReportTheme, value: null },
          }));
        }

        if (value?.customFields?.code === ParticipationForm.CONFERENCE_BUSINESS_PROGRAM_PARTICIPATION) {
          // Участие в деловой программе мероприятия
          loadReportTypes((data: ReferenceItem[]) => setNoReport(data));
          setNoReporters();
        }
      },
    },
    reportType: {
      isValid: true,
      required: true,
      title: 'Тип доклада',
      value: null,
      onChange: (value: ReportType | null) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          reportType: { ...prevState.reportType, value, isValid: true },
        }));
      },
    },
    reportTheme: {
      isValid: true,
      value: '',
      title: 'Тема доклада',
      tooltipText: 'Обязательно указать тему доклада с которым принято участие в конференции',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          reportTheme: { ...prevState.reportTheme, value },
        }));
      },
    },
    publicationReportTheme: {
      isValid: true,
      value: null,
      required: false,
      title: 'Публикация доклада',
      tooltipText:
        'Выберите статью (тезисы) из списка материалов конференции. Публикацию необходимо добавить в разделе с публикациями',
      onChange: (value: Form.Original) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          publicationReportTheme: { ...prevState.publicationReportTheme, value },
        }));
      },
    },
  });

  const isConferenceWithReportParticipation = useMemo(
    () => formFields.form.value?.customFields?.code === ParticipationForm.CONFERENCE_WITH_REPORT_PARTICIPATION,
    [formFields.form.value?.customFields?.code],
  );

  const setNoReporters = useCallback(() => {
    setMembers((prevState: Author[]) => prevState.map((m: Author) => ({ ...m, isReporter: false })));
  }, [setMembers]);

  const setNoReport = useCallback(
    (data: ReferenceItem[]) => {
      const noReportRef = data.find((r: ReferenceItem) => r?.customFields?.code === 'WITHOUT_REPORT');
      if (noReportRef) {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          reportType: { ...prevState.reportType, value: noReportRef, isValid: true },
        }));
      }
    },
    [setFormFields],
  );

  const mainReporterName = useMemo<string>(() => {
    const firstReporter = members.find(({ isReporter }) => isReporter);
    if (firstReporter) {
      return formatMainAuthor(firstReporter);
    }

    return 'Основной докладчик не выбран';
  }, [members]);

  const getReportExtramuralWithPublicationType = useCallback<() => Promise<ReportType | null>>(
    async () =>
      new Promise(resolve => {
        getReportExtramuralWithPublicationTypeAPI.callAPI(
          {
            filters: [{ key: 'code', values: [ReportTypeEnum.SECTIONAL] }],
            referenceName: 'RefTypeReport',
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data[0] && { id: data[0].id, code: ReportTypeEnum.SECTIONAL, label: data[0].label });
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReportExtramuralWithPublicationTypeAPI],
  );

  const getParticipationWithReportForm = useCallback<() => Promise<ReferenceItem | null>>(
    async () =>
      new Promise(resolve => {
        getParticipationFormAPI.callAPI(
          {
            filters: [{ key: 'code', values: [ParticipationForm.CONFERENCE_WITH_REPORT_PARTICIPATION] }],
            referenceName: 'RefParticipationForm',
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data[0]);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getParticipationFormAPI],
  );

  const loadParticipation = useCallback(
    (participationId: string | null, callback?: () => void) => {
      if (sourcePublication?.id && sourceEvent?.id && workMode === 'addMode') {
        setIsOpenBySourcePublication(true);
      }

      getParticipation.callAPI(
        {
          participationId: participationId || undefined,
          modules: [
            'ID',
            'ACCESS_MODE',
            'MAIN',
            'EVENT',
            'DEPARTMENTS',
            'PUBLICATION',
            'PROJECTS',
            'MEMBERS',
            'AWARDS',
            'MOBILE_REQUESTS',
            'DOCUMENTS',
          ],
          arm,
          eventType: 'CONFERENCE',
          eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
          IsCollectiveExhibit: false,
          sourcePublicationId: sourcePublication?.id,
          sourceEventId: sourceEvent?.id,
        },
        {
          onSuccessfullCall: async ({ data }) => {
            const preparedData = { ...data };

            if (sourcePublication?.id) {
              const [participationFormWithReport, reportTypeRef] = await Promise.all([
                getParticipationWithReportForm(),
                getReportExtramuralWithPublicationType(),
              ]);
              preparedData.reportType = reportTypeRef;
              preparedData.form = participationFormWithReport;
            }

            setParticipationData(preparedData);
            setPublication(preparedData.publication);
            setDocuments(preparedData.documents);
            setMobileRequests(preparedData.mobileRequests);
            setAwards(preparedData.awards);
            setMembers(preparedData.members);
            setEvent(sourceEvent || preparedData.event);
            setDepartments(preparedData.departments);
            setFinancingProjects(preparedData.financingProjects);

            setByResponse({ setFormFields, data: preparedData });
            formFields.form.onChange(preparedData.form);

            if (typeof callback === 'function') {
              callback();
            }
            setSourcePublicationId(sourcePublication?.id || null);
          },
          onFailedCall: () => {
            if (typeof callback === 'function') {
              callback();
            }
          },
        },
      );
    },

    [
      sourcePublication?.id,
      sourceEvent,
      workMode,
      getParticipation,
      arm,
      formFields.form,
      getParticipationWithReportForm,
      getReportExtramuralWithPublicationType,
    ],
  );

  const eventPublications = useMemo<{ type: string; publication: Event.Source }[]>(() => {
    const compilations = (event?.compilations || []).map(item => ({
      type: PublicationTypes.COMPILATION_CONFERENCE.code,
      publication: item,
    }));

    const magazineReleases = (event?.magazineReleases || []).map(item => ({
      type: PublicationTypes.MAGAZINE.code,
      publication: item,
    }));

    return [...compilations, ...magazineReleases];
  }, [event?.compilations, event?.magazineReleases]);

  const onChangeIsPublished = useCallback(
    (value: boolean) => {
      if (value && !event?.id) {
        setIsEventSelectWarningOpen(true);
        formFields.isPublished.onChange(false);
      } else {
        formFields.isPublished.onChange(value);
      }
    },
    [event?.id, formFields.isPublished],
  );

  const updateEvent = useCallback((data: Event.Event) => {
    setEvent(data);
  }, []);

  const getEventPropsToRequest = useCallback(
    (nextEventId: string): EventInput => ({
      id: nextEventId,
      simpleFields: {
        type: '',
        domain: 'EVENT',
        isInSummaryPlan: false,
        isCompilationPlanned: true,
        IsMagazineReleasePlanned: false,
        isStudent: false,
        memberCountPlan: 0,
        memberCountFact: 0,
      },
      attrIdFields: {},
      arrayFields: {
        Grntis: { translation: [] },
        MinistryEducations: { translation: [] },
        CriticalTechnologies: { translation: [] },
        Pnmitrs: { translation: [] },
        Pnrs: { translation: [] },
        Pnis: { translation: [] },
        Partners: { translation: [] },
      },
      format: EventFormat.OFFLINE,
    }),
    [],
  );

  const handleSetEvent = useCallback(
    (nextEvent: Event.Event | null) => {
      if (nextEvent) {
        getEvent.callAPI(getEventPropsToRequest(nextEvent.id), {
          onSuccessfullCall: ({ data }) => {
            updateEvent(data);

            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              reportDate: { ...prevState.reportDate, value: data.startDate },
              publicationReportTheme: {
                ...prevState.publicationReportTheme,
                value: sourcePublicationId ? prevState.publicationReportTheme.value : { id: '', label: '' },
              },
            }));

            if (data.participations.length) {
              setIsSimilarParticipationOpen(true);
            }

            if (!sourcePublicationId) {
              setPublication(null);
            }
          },
        });
      } else {
        setEvent(null);
        setPublication(null);
        formFields.isPublished.onChange('false');
      }
    },
    [formFields.isPublished, getEvent, getEventPropsToRequest, sourcePublicationId, updateEvent],
  );

  const onUpdatePublicationReportTheme = useCallback(
    (publicationReportTheme: Form.Original | null) => {
      const preparedValue: Form.Original = publicationReportTheme || { id: '', name: '' };

      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        publicationReportTheme: { ...prevState.publicationReportTheme, value: preparedValue, isValid: true },
        ...(publicationReportTheme ? { isPublished: { ...prevState.isPublished, value: false } } : {}),
      }));

      if (publicationReportTheme?.id) {
        getPublication.callAPI(
          {
            simpleFields: {
              status: '',
              typeEdition: '',
            },
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
            attrIdFields: {},
            id: publicationReportTheme.id,
          },
          {
            onSuccessfullCall: ({ data }) => {
              setPublication(data);
            },
          },
        );
        if (event?.id) {
          getEvent.callAPI(getEventPropsToRequest(event.id), {
            onSuccessfullCall: ({ data: eventData }) => {
              updateEvent(eventData);
            },
          });
        }
      } else {
        setPublication(null);
      }
    },
    [event?.id, getEvent, getEventPropsToRequest, getPublication, updateEvent],
  );
  const updateMembers = (membersArray: Author[]) => {
    setMembers(membersArray);
  };

  const afterSaveAction = useCallback(() => {
    onClose();
    if (!disableUpdateAfterSubmit) {
      tableStreams.reloadTable.push({});
    }
  }, [onClose, disableUpdateAfterSubmit, tableStreams.reloadTable]);

  const afterSaveAndContinueAction = useCallback(
    (nextParticipationId: string) => {
      updateWorkModeAfterSaveAndContinue();
      loadParticipation(nextParticipationId, () => {});
    },
    [loadParticipation, updateWorkModeAfterSaveAndContinue],
  );

  const openEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(true);
  }, []);

  const closeEditDepartmentsForm = useCallback(() => {
    setIsEditDepartmentsOpen(false);
  }, []);

  const openEditDepartmentsToAddedForm = useCallback(() => {
    setIsEditDepartmentsToAddedFormOpen(true);
  }, []);

  const editDepartmentsAction = useCallback(() => {
    if (needClose) {
      afterSaveAction();
    } else if (currentParticipationId) {
      afterSaveAndContinueAction(currentParticipationId);
    }

    setIsEditDepartmentsToAddedFormOpen(false);
  }, [afterSaveAction, afterSaveAndContinueAction, currentParticipationId, needClose]);

  const closeEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const afterSubmitEditDepartmentsToAddedForm = useCallback(() => {
    editDepartmentsAction();
  }, [editDepartmentsAction]);

  const handleOpenApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleCloseApprovePopup = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const afterSubmitApproveParticipation = useCallback(
    ({ nextRecordStatus }: { nextRecordStatus: Item }) => {
      const nextParticipationData = participationData ? { ...participationData, status: nextRecordStatus } : null;
      setParticipationData(nextParticipationData);
    },
    [participationData],
  );

  const afterSubmitDepartmentsForm = useCallback(
    (nextDepartments: Department[]) => {
      const nextParticipationData = participationData ? { ...participationData, departments: nextDepartments } : null;
      setParticipationData(nextParticipationData);
      setDepartments(nextDepartments);
    },
    [participationData, setDepartments],
  );

  const saveForm = useCallback(
    (withMessage?: boolean) => {
      setIsAdminMessagePopupOpen(false);

      saveParticipation.callAPI(
        {
          ...formatToRequest({
            formFields,
            financingProjects,
            mobileRequests,
            members,
            publication,
            event,
            documents,
            awards,
            participationData,
            participationMessageType,
            participationMessage,
            currentParticipationId,
            withMessage,
            departments,
          }),
          isPublicationCreationSource: Boolean(sourcePublicationId),
        },
        {
          onSuccessfullCall: ({ data }) => {
            const nextParticipationId = data?.id || '';

            showNotification({
              theme: 'success',
              message: currentParticipationId
                ? 'Участие в мероприятии успешно отредактировано'
                : 'Участие в мероприятии успешно сохранено',
            });

            setCurrentParticipationId(nextParticipationId);
            setParticipationMessage('');
            setParticipationMessageType({ id: '', label: '' });

            const isAddMode = !currentParticipationId;
            if (isAddMode) {
              openEditDepartmentsToAddedForm();
            } else if (needClose) {
              afterSaveAction();
            } else {
              afterSaveAndContinueAction(nextParticipationId);
            }

            tableStreams.reloadTable.push({});
            reloadEvent?.();
          },
          onFailedCall: () => {},
        },
      );
    },
    [
      saveParticipation,
      formFields,
      financingProjects,
      mobileRequests,
      members,
      publication,
      event,
      documents,
      awards,
      participationData,
      participationMessageType,
      participationMessage,
      currentParticipationId,
      departments,
      sourcePublicationId,
      needClose,
      reloadEvent,
      openEditDepartmentsToAddedForm,
      afterSaveAction,
      tableStreams.reloadTable,
      afterSaveAndContinueAction,
    ],
  );

  const handleFormSubmit = useCallback(
    (needCloseArg: boolean) => {
      setNeedClose(needCloseArg);
      const isValidDocuemnts = validateDocuments({ documents });
      const isValidReport = validateReport({ formFields, members });
      const isValidProjectReport = validateProjectReport({ financingProjects, formFields, members });

      const { isFormValid, invalidFieldKeys, nextNotification } = validate({
        formFields,
        event,
        members,
      });

      if (!isValidProjectReport) {
        setIsProjectReportPopupOpened(true);
      } else if (!isValidReport) {
        setIsReportPopupOpened(true);
      } else if (!isFormValid) {
        showNotification(nextNotification);
        if (invalidFieldKeys.length) {
          invalidFieldKeys.forEach((key: string) => {
            const preparedKey = key as keyof typeof formFields;
            setFormFields((prevState: Form.Fields) => ({
              ...prevState,
              [preparedKey]: { ...prevState[preparedKey], isValid: false },
            }));
          });
        }
      } else if (isProfile && !members.find(x => x.person?.id === currentPerson?.id)) {
        setIsAuthorsPopupOpen(true);
      } else if (!isValidDocuemnts) {
        setIsDocumentsPopupOpened(true);
      } else if (needCloseArg) {
        setIsAdminMessagePopupOpen(true);
      } else {
        saveForm(false);
      }
    },
    [documents, formFields, members, financingProjects, event, isProfile, currentPerson?.id, saveForm],
  );

  const onConfirmAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
    if (!validateDocuments({ documents })) {
      setIsDocumentsPopupOpened(true);
    } else if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  };

  const onResetAuthorsPopup = () => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 0, tabsId });
    getParticipation.callAPI(
      {
        arm: 'pc',
        eventType: 'CONFERENCE',
        modules: ['MEMBERS'],
        eventModules: ['MAIN'],
      },
      {
        onSuccessfullCall: ({ data }) => {
          const authorMember = data?.members[0];
          if (authorMember) setMembers((prevState: Author[]) => [...prevState, authorMember]);
          setIsAuthorsPopupOpen(false);
          if (!validateDocuments({ documents })) {
            setIsDocumentsPopupOpened(true);
          } else if (needClose) {
            setIsAdminMessagePopupOpen(true);
          } else {
            saveForm(false);
          }
        },
      },
    );
  };
  const onCloseAuthorsPopup = () => {
    setIsAuthorsPopupOpen(false);
  };

  const goToProjectReportAction = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 0, tabsId });
    setIsProjectReportPopupOpened(false);
  }, []);

  const closeProjectReportAction = useCallback(() => {
    setIsProjectReportPopupOpened(false);
  }, []);

  const goToDocumentsAction = useCallback(() => {
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 3, tabsId });
    setIsDocumentsPopupOpened(false);
  }, []); // isDocumentsPopupOpened

  const goToReportAction = useCallback(() => {
    setIsReportPopupOpened(false);
    tabsStreams.setCurrentTab.push({ nextSelectedTab: 0, tabsId });
  }, []); // isReportPopupOpened

  const confirmDocumentPopup = useCallback(() => {
    setIsDocumentsPopupOpened(false);
    if (needClose) {
      setIsAdminMessagePopupOpen(true);
    } else {
      saveForm(false);
    }
  }, [needClose, saveForm]);

  const closeReportPopup = useCallback(() => {
    setIsReportPopupOpened(false);
  }, []);

  const handleCloseSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
  }, []);

  const acceptSimilarParticipation = useCallback(() => {
    setIsSimilarParticipationOpen(false);
  }, []);

  const loadReportTypes = useCallback(
    (callback: (data: ReferenceItem[]) => void) => {
      getReferenceElementsAPI.callAPI(
        { filters: [], referenceName: 'RefTypeReport' },
        {
          onSuccessfullCall: ({ data }) => {
            callback?.(data as ReferenceItem[]);
          },
        },
      );
    },
    [getReferenceElementsAPI],
  );

  useLayoutEffect(() => {
    loadParticipation(currentParticipationId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const actualTableState = useMemo(() => relatedTableState || EMPTY_TABLE_STATE, [relatedTableState]);

  const setFinancingProjectsCb = useCallback(
    (projectsData: Array<ParticipationProject>) => {
      const nextProjects = projectsData.map(projectItem => ({
        ...projectItem,
        type: getEnumItem('ParticipationProjectType', ParticipationProjectType.FINANCING, enumMap),
      }));
      const isReportPublished = formFields.isReportPublished?.value === 'true';

      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        reportType: { ...prevState.reportType, required: !!isReportPublished || Boolean(nextProjects.length), isValid: true },
        reportDate: { ...prevState.reportDate, required: !!isReportPublished || Boolean(nextProjects.length), isValid: true },
      }));

      setFinancingProjects(nextProjects);
    },
    [enumMap, formFields.isReportPublished?.value],
  );

  const setMobileRequestsCb = useCallback((requests: Array<Form.PublicationMobileRequest>) => setMobileRequests(requests), []);

  return {
    isLoading,
    isProfile,
    mainReporterName,
    formFields,
    participationId: currentParticipationId,
    relatedTableState: actualTableState,
    workMode,
    documents,
    mobileRequests,
    awards,
    event,
    publication,
    isEventSelectWarningOpen,
    members,
    isDocumentsPopupOpened,
    isReportPopupOpened,
    isProjectReportPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    tabsId,
    disabledEvent,
    isSimilarParticipationOpen,
    eventPublications,
    financingProjects,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    participationData,
    setFinancingProjectsCb,
    setMobileRequestsCb,
    onUpdatePublicationReportTheme,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    setDocuments: (nextDocuments: Document[]) => setDocuments(nextDocuments),
    setAwards,
    setEvent: handleSetEvent,
    onChangeIsPublished,
    setIsEventSelectWarningOpen,
    changeMembers: (val: Author[]) => updateMembers(val),
    handleFormSubmit,
    goToDocumentsAction,
    goToReportAction,
    confirmDocumentPopup,
    closeReportPopup,
    goToProjectReportAction,
    closeProjectReportAction,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    handleCloseSimilarParticipation,
    acceptSimilarParticipation,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    sourcePublicationId,
    isOpenBySourcePublication,
    onCloseBySourcePublication: () => setIsOpenBySourcePublication(false),
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onCloseAuthorsPopup,
    onResetAuthorsPopup,
    isConferenceWithReportParticipation,
  };
};

export { useController };

import React from 'react';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('loader-local');

export type Props = {
  /** @property {boolean} isShow - (optional) Show loading (false by default) */
  isShow?: boolean;
};

/**
 * Local loader component (inset of element)
 *
 * @param {boolean} isShow - (optional) Show loading
 * @returns {JSX} JSX
 */
export const LoaderLocal = React.memo(({ isShow = false }: Props) => {
  return isShow ? <div className={b()} /> : <></>;
});

import React, { memo } from 'react';
import { block } from 'bem-cn';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table as T } from 'types/models';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { EditRelationsModal } from 'features/EditRelationsModal';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import { Component as EditDoiPopup } from 'features/Form/views/EditDoiPopup';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import { BuildReportPopup } from 'features/BuildReportPopup';
import useController from './controller';
import { BaseDissertationGridHelp } from './help';

const b = block('toolbar');

type WrappedProps = {
  workMode?: T.WorkMode;
  hideActionButtons?: boolean;
};

type Props = {
  tableState: T.State;
};

export const ToolbarWrapper = ({ workMode, hideActionButtons }: WrappedProps) =>
  memo(({ tableState }: Props) => {
    const {
      SUCH_LIKE_PUBLICATION_ID,
      isOpenHelpForm,
      setIsOpenHelpForm,
      isViewButtonDisabled,
      isEditButtonDisabled,
      isFinancialSupportDisabled,
      isDeleteButtonDisabled,
      isEditRelationsDisabled,
      isDOIDisabled,
      isApproveButtonDisabled,
      isCitationDisabled,
      nextPublicationName,
      publication,
      buttons,
      isArticlesWarningPopupOpen,
      isEditArticleSourceOpen,
      isViewFormOpen,
      isAddFormOpen,
      isEditFormOpen,
      isOpenEditRelations,
      isDeleteConfirmPopupOpen,
      isCitationFormOpen,
      isApprovePopupOpen,
      isDoiPopupOpened,
      isFinancialSupportOpen,
      handleTemplateCloseViewForm,
      handleCloseEditForm,
      handleCloseEditRelationsModal,
      handleConfirmDeleteConfirmPopup,
      handleCloseCitationForm,
      handleApprovePopupClose,
      handleCloseDoiPopup,
      handleCloseFinancialSupport,
      handleCloseArticlesWarningPopup,
      handleCloseEditArticleSource,
      handleSubmitDeleteWarningPopup,
      handleCloseDeleteConfirmPopup,
      handleTemplateCloseAddForm,
      onApprove,
      handleContinueStep,
      submitDoiPopupCallback,
      submitFinancialPopupCallback,
      isReportOpen,
      onReportClose,
      currentReport,
      userSystemDepartment,
    } = useController({ tableState, workMode, hideActionButtons });

    return (
      <>
        <Toolbar buttons={buttons} />

        {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
          <div className={b('hidden')}>
            <SuchLikePublication
              mode="add"
              isSearchable
              handleContinueStep={handleContinueStep}
              componentId={SUCH_LIKE_PUBLICATION_ID}
            />
          </div>
        )}

        <modalTemplates.PublicationAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          name={nextPublicationName}
          relatedTableState={tableState}
        />

        {!isViewButtonDisabled && (
          <modalTemplates.PublicationViewTemplate.Component
            isOpen={isViewFormOpen}
            onClose={handleTemplateCloseViewForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}

        {!isEditButtonDisabled && (
          <modalTemplates.PublicationEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            // handleChangeParticipation={handleOpenFormConferenceParticipation}
            // isHidden={isAddFormOpenConferenceParticipation}
            // setPublication={setPublicationConferenceParticipation}
            // setPublicationConferenceSource={setPublicationConferenceSource}
          />
        )}

        {!isDeleteButtonDisabled && !!tableState.selectedRows.length && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: handleCloseDeleteConfirmPopup,
              },
            ]}
            size="small"
          >
            <>
              (ID: {tableState.selectedRows[0]?.id}, тема: {tableState.selectedRows[0]?.PublicationName}, статус:{' '}
              {tableState.selectedRows[0]?.Status}) Удалить диссертацию?
            </>
          </Modal>
        )}

        {!isEditRelationsDisabled && !!tableState.selectedRows.length && (
          <EditRelationsModal
            title="Редактировать связь публикации с подразделениями"
            isOpen={isOpenEditRelations}
            onClose={handleCloseEditRelationsModal}
            publication={publication}
          />
        )}

        {!isDOIDisabled && !!tableState.selectedRows.length && (
          <EditDoiPopup
            isOpen={isDoiPopupOpened}
            onClose={handleCloseDoiPopup}
            onSuccess={submitDoiPopupCallback}
            publicationId={tableState.selectedRows[0]?.id as string}
          />
        )}

        {!isApproveButtonDisabled && !!tableState.selectedRows.length && (
          <ApprovePopup
            isOpen={isApprovePopupOpen}
            selectedRowId={tableState.selectedRows[0]?.id}
            onApprove={onApprove}
            onClose={handleApprovePopupClose}
          />
        )}

        {!isCitationDisabled && !!tableState.selectedRows.length && (
          <modalTemplates.PublicationCitationSystemTemplate.Component
            isOpen={isCitationFormOpen}
            onClose={handleCloseCitationForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
          />
        )}

        {!isFinancialSupportDisabled && (
          <FinancicalSupportPopup
            isOpen={isFinancialSupportOpen}
            relatedTableState={tableState}
            onClose={handleCloseFinancialSupport}
            publicationId={tableState.selectedRows[0]?.id}
            workMode="editMode"
            onSuccess={submitFinancialPopupCallback}
          />
        )}

        {!!tableState.selectedRows.length && (
          <PublicationArticlesWarningPopup
            isOpen={isArticlesWarningPopupOpen}
            onClose={handleCloseArticlesWarningPopup}
            onConfirm={handleSubmitDeleteWarningPopup}
            publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
          />
        )}

        {!!tableState.selectedRows.length && (
          <EditArticleSource
            isOpen={isEditArticleSourceOpen}
            onClose={handleCloseEditArticleSource}
            tableState={tableState}
            publicationId={tableState.selectedRows[0]?.id}
            publicationTypeCode={PublicationTypes.PREPRINT_ARTICLE.code}
          />
        )}

        <BuildReportPopup
          isOpen={isReportOpen}
          onClose={onReportClose}
          reportName={currentReport?.name || ''}
          reportCaption={currentReport?.caption || ''}
          values={{
            department: userSystemDepartment?.id || '',
          }}
        />

        <Modal
          mode="help"
          title="Публикации"
          isOpen={isOpenHelpForm}
          onClose={() => setIsOpenHelpForm(false)}
          size={['large', 'medium-height']}
        >
          {BaseDissertationGridHelp()}
        </Modal>
      </>
    );
  });

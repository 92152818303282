import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { SubmitTable } from 'features/Table/streams';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

export function useController({ customState: { referenceMetadata } }: Props) {
  const { userPermission } = useAppDataContext();

  const [description, setDescription] = useState<string>('');
  const [isOpenHelpModal, setIsOpenHelpModal] = useState(false);

  const isEditableDescription = useMemo<boolean>(() => isHasPermission(userPermission, Permits.REFERENCE_METADATA_EDIT), [
    userPermission,
  ]);

  const { methods: saveReferenceMetadataAPI } = BackendAPI.useBackendAPI('SaveReferenceMetadata', {
    onSuccessfullCall: () => {
      setIsOpenHelpModal(false);
      showNotification({ message: 'Правила использования успешно сохранены', theme: 'success' });
    },
  });

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        text: 'Инструкция к справочнику',
        mode: ButtonMode.PRIMARY,
        title: 'Правила использования справочника',
        onClick: () => setIsOpenHelpModal(true),
        isHidden: !description && !isEditableDescription,
      },
    ],
    [description, isEditableDescription],
  );

  useEffect(() => {
    setDescription(referenceMetadata?.description || '');
  }, [referenceMetadata?.description]);

  const onSave = useCallback(() => {
    saveReferenceMetadataAPI.callAPI({ name: referenceMetadata?.name || '', description });
  }, [description, referenceMetadata?.name, saveReferenceMetadataAPI]);

  return {
    buttons,
    isOpenHelpModal,
    setIsOpenHelpModal,
    description,
    setDescription,
    isEditableDescription,
    onSave,
  };
}

import { ParticipationType } from 'types/models/Participation';

export class ParticipationTypes {
  static PARTICIPATION_CONFERENCE: ParticipationType = {
    label: 'Участие в конференции',
    nominativeCaseLabel: 'участие в конференции',
    genitiveCaseLabel: 'участия в конференции',
    code: 'PARTICIPATION_CONFERENCE',
    template: 'ParticipationFormConference',
    eventCode: 'CONFERENCE',
  };

  static PARTICIPATION_EXPOSITION: ParticipationType = {
    label: 'Участие в выставке',
    nominativeCaseLabel: 'участие в выставке',
    genitiveCaseLabel: 'участия в выставке',
    code: 'PARTICIPATION_EXPOSITION',
    template: 'ParticipationFormExposition',
    eventCode: 'EXPOSITION',
  };

  static PARTICIPATION_CONTEST: ParticipationType = {
    label: 'Участия и награды в конкурсе',
    nominativeCaseLabel: 'участия и награды в конкурсе',
    genitiveCaseLabel: 'участия и награды в конкурсе',
    code: 'PARTICIPATION_CONTEST',
    template: 'ParticipationFormContest',
    eventCode: 'CONTEST',
  };

  static PARTICIPATION_CONCERT: ParticipationType = {
    label: 'Участие в творческом мероприятии',
    nominativeCaseLabel: 'участие в творческом мероприятии',
    genitiveCaseLabel: 'участия в творческом мероприятии',
    code: 'PARTICIPATION_CONCERT',
    template: 'ParticipationFormConcert',
    eventCode: 'CONCERT',
  };
}

import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';
import { Form } from './Form';

import { makeUseCustomController, State } from './makeUseCustomController';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';
import { EnumMap } from 'types/models/Table';

type Props = {
  enums: EnumMap;
  type: ProjectType;
};

export function GetProjectList({ enums, type }: Props): Table.Specification<State> {
  return {
    apiID: 'GetProjectList',
    header: {
      firstLevel: {
        title: `Список проектов типа: ${getEnumItemLabel('ProjectType', type, enums)}`,
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
    useCustomController: makeUseCustomController({ type }),
    NeighborTableComponent: Form,
    requestData: { type },
  };
}

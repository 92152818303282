import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { State } from '../model';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
  setSelectedItems([]): void;
};

export function useController({ tableState, customState: { selectedEntries }, setSelectedItems }: Props) {
  const isMergeDisabled = useMemo(() => {
    return !tableState.selectedRows.length || tableState.selectedRows.length < 2 || tableState.selectedRows.length > 5;
  }, [tableState.selectedRows]);

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);
  const [isOpenFieldsMapModal, setIsOpenFieldsMapModal] = useState(false);

  const closeModal = useCallback(() => {
    setIsOpenConfirmDeleteModal(false);
  }, [setIsOpenConfirmDeleteModal]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'merge' },
        title: 'Объеденить выделенные записи',
        onClick: () => {
          setSelectedItems(tableState.selectedRows);
          setIsOpenFieldsMapModal(true);
        },
        isDisabled: isMergeDisabled,
      },
    ],
    [isMergeDisabled, setSelectedItems, tableState.selectedRows],
  );

  return {
    buttons,
    isOpenConfirmDeleteModal,
    closeModal,
    selectedEntries,
    isOpenFieldsMapModal,
    setIsOpenFieldsMapModal,
  };
}

import React, { useCallback, useState, useMemo } from 'react';
import { setup } from 'bem-cn';
import { streams } from './streams';

import { Button, ButtonMode, Checkbox, Column, FormComponent, ListEditTable, Modal, TextInput } from 'components';

import { LoaderLocal } from 'features/LoaderLocal';
import { SuchLikePublication } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { SuchLikeMode } from 'types/models/SuchLikePublication';
import { useLocalStreams } from './hooks';

import '../style.scss';

const block = setup({ mod: '--' });

type Props = {
  componentId: string;
  suchLikePublicationList: SuchLikePublication.State['suchLikePublicationList'];
  customContinueHandle?: (searchText: string) => void;
  isSearchableComponent?: boolean;
  onClose: () => void;
  onMessagePopupOpen: () => void;
  mode?: SuchLikeMode;
  handleAttachPublication: () => void;
  handleCloseAttachPopup: () => void;
  isConfirmPopupOpen: boolean;
  updateIsConfirmPopupOpen: (nextIsConfirmPopupOpen: boolean) => void;
  selectedRowIndex: number | null;
  setSelectedRowIndex: (index: number) => void;
  category: SuchLikePublication.Category;
  isWarningPopupOpen: boolean;
  setIsWarningPopupOpen: (nextIsWarningPopupOpen: boolean) => void;
  isLoading: boolean;
  isAttachPopupOpened: boolean;
  sendEmptyMessageWarning: () => void;
};

type RowKeys = 'id' | 'name' | 'status' | 'bibliographicRecord' | 'departments' | 'type' | 'created';

type RowItem = {
  [Key in RowKeys]: string;
};

const b = block('such-like-publication');

const SuchLikePublicationComponent = ({
  componentId,
  suchLikePublicationList,
  isSearchableComponent,
  customContinueHandle,
  onClose,
  onMessagePopupOpen,
  isConfirmPopupOpen,
  updateIsConfirmPopupOpen,
  selectedRowIndex,
  setSelectedRowIndex,
  isWarningPopupOpen,
  setIsWarningPopupOpen,
  category,
  isLoading,
  isAttachPopupOpened,
  handleAttachPublication,
  handleCloseAttachPopup,
  sendEmptyMessageWarning,
  mode,
}: Props) => {
  const isPublicationExistDefault = false;
  const localStreams = useLocalStreams(streams);
  const [isPublicationExist, setIsPublicationExist] = useState<boolean>(isPublicationExistDefault);
  const [searchText, setSearchText] = useState('');

  const onConfirmButtonClick = useCallback(() => {
    onMessagePopupOpen();
    updateIsConfirmPopupOpen(false);
  }, [onMessagePopupOpen, updateIsConfirmPopupOpen]);

  const preparedRows: RowItem[] = useMemo(
    () =>
      suchLikePublicationList.map(suchLikePublicationItem => ({
        id: suchLikePublicationItem.id,
        name: suchLikePublicationItem.name,
        status: suchLikePublicationItem.status.label,
        departments: suchLikePublicationItem.departments,
        bibliographicRecord: suchLikePublicationItem.bibliographicRecord,
        type: suchLikePublicationItem.type.label,
        created: `${suchLikePublicationItem.createdBy.name} (${suchLikePublicationItem.createdDate})`,
      })),
    [suchLikePublicationList],
  );

  const afterSuccessfullSearch = () => {
    setIsPublicationExist(suchLikePublicationList.length === 0);
  };

  const searchByInputValue = () => {
    if (searchText.length) {
      localStreams.getSuchLikePublications.push({
        category: 'PUBLICATION',
        isSearching: true,
        searchValue: searchText,
        successfullCallback: afterSuccessfullSearch,
        currentId: null,
        componentId,
      });
    } else {
      sendEmptyMessageWarning();
    }
  };

  const handleContinue = () => {
    if (customContinueHandle) {
      onClose();
      customContinueHandle(searchText);
    } else {
      updateIsConfirmPopupOpen(true);
    }
  };

  const attachRow = useCallback(() => {
    if (selectedRowIndex !== null) {
      switch (suchLikePublicationList[selectedRowIndex]?.type?.value) {
        case PublicationTypes.MAGAZINE.code:
          setIsWarningPopupOpen(true);
          break;
        default:
          if (selectedRowIndex !== null) {
            const selectedRow = preparedRows[selectedRowIndex];
            localStreams.attachSuchLikePublication.push({ id: selectedRow?.id, componentId });
          }
          break;
      }
    }
  }, [
    componentId,
    selectedRowIndex,
    suchLikePublicationList,
    setIsWarningPopupOpen,
    preparedRows,
    localStreams.attachSuchLikePublication,
  ]);

  const columns = useMemo<Column<RowItem>[]>(() => {
    if (category === PublicationTypes.MAGAZINE.code) {
      return [
        { label: 'Статус', formatValue: row => row.status },
        { label: 'Название', formatValue: row => row.name },
        { label: 'Тип публикации', formatValue: row => row.type },
        { label: 'Создано', formatValue: row => row.created },
      ];
    }

    return [
      { label: 'Статус', formatValue: row => row.status },
      { label: 'Библиографическая запись', formatValue: row => row.bibliographicRecord },
      { label: 'Тип публикации', formatValue: row => row.type },
      { label: 'Подразделение', formatValue: row => row.departments },
      { label: 'Создано', formatValue: row => row.created },
    ];
  }, [category]);

  return (
    <div className={b()}>
      <LoaderLocal isShow={isLoading} />

      <div className={b('content')}>
        {isSearchableComponent && (
          <div className={b('content-search')}>
            <div className={b('content-search-field')}>
              <FormComponent.Field label="Введите название публикации">
                <TextInput value={searchText} onChange={setSearchText} />
              </FormComponent.Field>
            </div>
            <div className={b('content-search-button')}>
              <Button mode={ButtonMode.SECONDARY} text="Найти" onClick={searchByInputValue} classMixin="suchlike-button" />
            </div>
          </div>
        )}
        <div className={b('content-table')}>
          <ListEditTable
            rows={preparedRows}
            columns={columns}
            selectedRowIndex={selectedRowIndex}
            selectRow={setSelectedRowIndex}
          />
        </div>
      </div>

      {category !== 'MAGAZINE' && (
        <h4 className={b('content-title')}>Если в списке есть публикация, то привяжите ее к подразделению</h4>
      )}

      <div className={b('actions')}>
        <div className={b('all-count')}>
          <span className={b('all-count-text')}>Всего:</span>
          <span className={b('all-count-value')}>{preparedRows.length}</span>
        </div>
        <div className={b('actions-checkbox')}>
          <Checkbox label="В списке нужной записи нет" checked={!!isPublicationExist} onChange={setIsPublicationExist} />
        </div>
        <ul className={b('actions-button-list')}>
          <li className={b('actions-button')}>
            <Button
              mode={ButtonMode.PRIMARY}
              text={mode === 'add' ? 'Продолжить добавление публикации' : 'Продолжить сохранение публикации'}
              onClick={handleContinue}
              isDisabled={!isPublicationExist}
            />
          </li>
          <li className={b('actions-button')}>
            <Button
              mode={ButtonMode.PRIMARY}
              text="Привязать к своему подразделению"
              onClick={attachRow}
              isDisabled={selectedRowIndex === null}
            />
          </li>
          <li className={b('actions-button')}>
            <Button
              mode={ButtonMode.SECONDARY}
              text={mode === 'add' ? 'Отменить добавление публикации' : 'Не сохранять публикацию'}
              onClick={onClose}
            />
          </li>
        </ul>
      </div>

      <Modal
        title="Подтверждение сохранения журнала"
        isOpen={isConfirmPopupOpen}
        onClose={() => updateIsConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onConfirmButtonClick,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => updateIsConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Вы уверены, что добавляемая публикация не является дубликатом уже существующей в системе?</>
      </Modal>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isWarningPopupOpen}
        onClose={() => setIsWarningPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsWarningPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>{category === 'MAGAZINE' ? 'Журналы не привязываются к подразделению.' : 'Ошибка'}</>
      </Modal>

      <Modal
        mode="warning"
        title="Привязка публикации к подразделению"
        isOpen={isAttachPopupOpened}
        onClose={handleCloseAttachPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleAttachPublication,
            isDisabled: isLoading,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: handleCloseAttachPopup,
            isDisabled: isLoading,
          },
        ]}
        size="small"
      >
        <p>Привязать к своему подразделению следующую публикацию:</p>
        <br />
        <p>
          <strong>{selectedRowIndex !== null ? suchLikePublicationList[selectedRowIndex]?.bibliographicRecord : ''}</strong>
        </p>
      </Modal>
    </div>
  );
};

export const Component = React.memo(SuchLikePublicationComponent);

import React, { useMemo } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { PublicationCitationSystemIndex, PublicationType } from 'types/models/CitationSystemIndex';
import { ScientificIndex as SIndex } from 'types/models/ScientificIndex';
import { CitationSystem } from 'types/models/Form';
import { Item } from 'types/models/common';
import { Mode } from 'features/Table/specifications/GetPublicationCitationSystemIndexList/makeUseCustomController';

type Props = {
  mode: Mode;
  citationSystemIndex: PublicationCitationSystemIndex;
  citationSystems: CitationSystem[];
  scientificIndexes: SIndex[];
  publicationType: PublicationType;
  handleChangeCitationSystem(value: ReferenceItem): void;
  handleChangeScientificIndex(value: Item | null): void;
};

function ScientificIndex(props: Props) {
  const {
    mode,
    citationSystemIndex,
    citationSystems,
    scientificIndexes,
    publicationType,
    handleChangeCitationSystem,
    handleChangeScientificIndex,
  } = props;

  const isDisabled = useMemo<boolean>(
    () => (mode === 'edit' && !!citationSystemIndex.citationSystem?.id) || !citationSystems.length,
    [citationSystemIndex.citationSystem?.id, citationSystems.length, mode],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field label="Система цитирования" isRequired>
        <Select
          mode={SelectMode.REFERENCE}
          value={{ id: citationSystemIndex.citationSystem?.id || '', label: citationSystemIndex.citationSystem?.label || '' }}
          onChange={(option: ReferenceItem) => handleChangeCitationSystem(option)}
          settings={{
            name: 'RefCitationSystem',
            title: 'Справочник "Системы цитирования"',
            filters: [{ key: 'id', values: citationSystems.map(x => x.id) }],
          }}
          isDisabled={isDisabled}
        />
      </FormComponent.Field>
      <FormComponent.Field label="Показатель" isRequired>
        <Select
          value={{
            value: citationSystemIndex.scientificIndex?.id || '',
            label: citationSystemIndex.scientificIndex?.label || '',
          }}
          options={scientificIndexes
            .filter(
              (x: SIndex) =>
                x.citationSystem.id === citationSystemIndex.citationSystem?.id &&
                x.domain.text === (publicationType === 'MAGAZINE' ? 'MAGAZINE' : 'PUBLICATION'),
            )
            .map((x: SIndex) => ({ value: x.id, label: x.label }))}
          onChange={(value: Item | null) => handleChangeScientificIndex(value)}
          isDisabled={isDisabled}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}

export { ScientificIndex };

import { Result } from '.';

type Props = {
  pageFrom: string;
  pageTo: string;
};

export const validatePages = ({ pageFrom, pageTo }: Props) => {
  const result: Result = {
    isFormValid: true,
    invalidFieldKeys: [],
    nextNotification: { theme: 'danger', message: '' },
  };

  if (pageFrom && pageTo) {
    const pageFromValue = parseFloat(pageFrom);
    const pageToValue = parseFloat(pageTo);

    if (pageFromValue > pageToValue) {
      result.nextNotification.message = 'Страница "С" должна быть меньше или равна странице "ПО"';
      result.invalidFieldKeys = ['pageFrom', 'pageTo'];
      result.isFormValid = false;
    }
  }

  return result;
};

import { Specification } from 'types/models/Table';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

export function GetProjectEquipList(): Specification {
  return {
    apiID: 'GetProjectEquipList',
    header: {
      firstLevel: {
        title: 'Использование оборудования в проектах',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

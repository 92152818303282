import React from 'react';

import { Tabs, Tab } from 'components';

import { Person, UserPermission } from 'types/models';
import { ValueOf } from 'types/helpers';
import { Honor } from 'features/PersonCard/model';
import { HirshIndexes } from './HirshIndexes';
import { ScientistHonors } from './ScientistHonors';
import { Identifiers } from './Identifiers';
import { FIO } from './FIO';
import { Changes } from './Changes';
import { Affiliations } from './Affiliations';
import { EditEducations, EditJobs } from 'features/Form/views';

type Props = {
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  disabled: boolean;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
  onChangeHonors(item: Honor[]): void;
  onChangeHirshIndexes(indexes: Person.HirshIndex[]): void;
};

function Component(props: Props) {
  const {
    defaultTabId,
    onTabClick,
    person,
    userPermission,
    disabled,
    onChangeHonors,
    makeSetPerson,
    onChangeHirshIndexes,
  } = props;

  return (
    <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
      <Tab title="Сведения о работе" id="jobs">
        <EditJobs mode="lk" disabled={disabled} person={person} onChangeJobs={makeSetPerson('scientistJobs')} />
      </Tab>
      <Tab title="Сведения об обучении" id="educations">
        <EditEducations mode="lk" disabled={disabled} person={person} onChangeEducations={makeSetPerson('scientistEducations')} />
      </Tab>
      <Tab title="Ученые степени и звания" id="honors">
        <ScientistHonors userPermission={userPermission} person={person} onChangeHonors={onChangeHonors} />
      </Tab>
      <Tab title="Индекс Хирша" id="hirsh">
        <HirshIndexes person={person} onChangeHirshIndexes={onChangeHirshIndexes} />
      </Tab>
      <Tab title="Идентификаторы в базах цитирования" id="identifiers">
        <Identifiers disabled={disabled} person={person} onChangeIdentifiers={makeSetPerson('identifiers')} />
      </Tab>
      <Tab title="Библиографические ФИО" id="fio">
        <FIO
          disabled={disabled}
          person={person}
          userPermission={userPermission}
          onChangeFIO={makeSetPerson('bibliographicNames')}
        />
      </Tab>
      <Tab title="Аффилиации" id="affiliations">
        <Affiliations person={person} />
      </Tab>
      <Tab title="Редакции" id="changes" isVisible={!!person?.changes.length}>
        <Changes person={person} />
      </Tab>
    </Tabs>
  );
}

export const AdditionalInfo = React.memo(Component);

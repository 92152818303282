import React from 'react';

import { Article, Form as F, Table } from 'types/models';
import { Form } from 'features/Form';
import { useAppDataContext } from 'features/AppData/context';
import publicationTypes from 'types/models/Publication/publicationTypes';
import { isHasPermission } from 'features/AppData';
import { AuthorPaymentRequestStatus } from 'utils/Enums';
import { Permits } from 'utils/Permissions';

type Props = {
  tableState: Table.State;
  article: Article | null;
  isViewMode: boolean;
  status: AuthorPaymentRequestStatus | null;
};

export function ViewArticle({ tableState, article, isViewMode, status }: Props) {
  const { userPermission } = useAppDataContext();
  const publicationInfo = publicationTypes.find(type => type.code === article?.type.value);

  if (article === null || !publicationInfo) {
    return null;
  }

  const viewMode =
    isViewMode ||
    !isHasPermission(
      userPermission,
      status === AuthorPaymentRequestStatus.PAYMENT_APPROVED ? Permits.PUBLICATION_STRICTED_EDIT : Permits.PUBLICATION_EDIT,
    );

  const look: F.PublicationFormLook = {
    apiID: 'GetPublication',
    template: publicationInfo.template,
    type: article.type.value,
    viewMode: viewMode,
    editMode: !viewMode,
    id: article.id,
    title: article.name,
    relatedTableState: { ...tableState, selectedRows: [{ ...tableState.selectedRows[0], id: article.id }] },
    isRequestPaymentView: true,
    isShowDetailedTitle: true,
  };

  return <Form look={look} onClose={undefined} />;
}

import React, { useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

import { CustomToolbarButton, DefaultToolbarButton, DefaultButtonKey, ToolbarButton } from '../model';
import { Color } from 'constants/colors';

type Props<Row> = {
  selectedRow: Row | null;
  toolbar?: (ToolbarButton<Row> | false)[] | false;

  onAdd(mode: 'add' | 'dublicate'): void;
  onEdit(): void;
  onDelete(): void;
  onView(): void;
  moveDownRow(): void;
  moveUpRow(): void;
  rows: Row[];
  isOpenCustomComponent: boolean;
  isDisabled: boolean;
  selectedRowIndex: number | null;
  selectedRowsIndexes?: number[];

  isVisibleFilters?: boolean;
  isOpenFilters?: boolean;
  setIsOpenFilters?(value: boolean): void;
  setColumnsFilters?: React.Dispatch<Record<number, string>>;
};

function ToolbarButtons<Row>(props: Props<Row>) {
  const {
    selectedRow,
    toolbar,
    onAdd,
    onEdit,
    onDelete,
    onView,
    moveDownRow,
    moveUpRow,
    selectedRowIndex,
    isOpenCustomComponent,
    isDisabled,
    rows,
    selectedRowsIndexes,
    isVisibleFilters = false,
    isOpenFilters = false,
    setIsOpenFilters,
    setColumnsFilters,
  } = props;

  const moveUpButton = useMemo(
    () => ({
      icon: { type: 'arrowUp' },
      title: 'Вверх',
      onClick: moveUpRow,
      isDisabled:
        (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
        selectedRowIndex === null ||
        isDisabled ||
        isOpenCustomComponent,
    }),
    [isDisabled, isOpenCustomComponent, moveUpRow, selectedRowIndex, selectedRowsIndexes],
  );
  const moveDownButton = useMemo(
    () => ({
      icon: { type: 'arrowDown' },
      title: 'Вниз',
      onClick: moveDownRow,
      isDisabled:
        (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
        selectedRowIndex === null ||
        isDisabled ||
        isOpenCustomComponent,
    }),
    [isDisabled, isOpenCustomComponent, moveDownRow, selectedRowIndex, selectedRowsIndexes],
  );

  const filterButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'filter', mode: 'remove' },
        title: 'Очистить все поля фильтрации',
        onClick: () => setColumnsFilters?.({}),
        style: { marginLeft: 'auto' },
      },
      {
        icon: { type: 'filter' },
        title: 'Показать/спрятать фильтр',
        onClick: () => setIsOpenFilters?.(!isOpenFilters),
        style: {},
      },
    ],
    [isOpenFilters, setColumnsFilters, setIsOpenFilters],
  );

  const defaultButtons = useMemo<Record<DefaultButtonKey, ButtonProps[]>>(
    () => ({
      [DefaultButtonKey.VIEW]: [
        {
          icon: { type: 'view' },
          title: 'Просмотр',
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            event?.currentTarget.blur();
            onView();
          },
          isDisabled:
            isDisabled ||
            (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
            selectedRowIndex === null ||
            isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.ADD]: [
        {
          icon: { type: 'add' },
          title: 'Добавить',
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            event?.currentTarget.blur();
            onAdd('add');
          },
          isDisabled: isDisabled || isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.EDIT]: [
        {
          icon: { type: 'edit' },
          title: 'Редактировать',
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            event?.currentTarget.blur();
            onEdit();
          },
          isDisabled:
            (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
            selectedRowIndex === null ||
            isDisabled ||
            isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.DELETE]: [
        {
          icon: { type: 'remove' },
          title: 'Удалить',
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();
            onDelete();
          },
          isDisabled:
            (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
            selectedRowIndex === null ||
            isDisabled ||
            isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.DUPLICATE]: [
        {
          icon: { type: 'documents', mode: 'arrowRight' },
          title: 'Дублировать',
          onClick: (event: React.MouseEvent<HTMLButtonElement>) => {
            event?.preventDefault();
            onAdd('dublicate');
          },
          isDisabled:
            (selectedRowsIndexes && selectedRowsIndexes?.length > 1) ||
            selectedRowIndex === null ||
            isDisabled ||
            isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.ACCEPT]: [
        {
          icon: { type: 'like', color: Color.success },
          title: 'Принять к отчету',
          // no default handler
          isDisabled:
            selectedRowIndex === null || isDisabled || (selectedRow as { accepted: boolean }).accepted || isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.DECLINE]: [
        {
          icon: { type: 'dislike', color: Color.danger },
          title: 'Отменить принятие к отчету',
          // no default handler
          isDisabled:
            selectedRowIndex === null || isDisabled || !(selectedRow as { accepted: boolean }).accepted || isOpenCustomComponent,
        },
      ],
      [DefaultButtonKey.MOVE_UP]: [moveUpButton],
      [DefaultButtonKey.MOVE_DOWN]: [moveDownButton],
      [DefaultButtonKey.MOVE]: [moveUpButton, moveDownButton],
    }),
    [
      isDisabled,
      isOpenCustomComponent,
      moveDownButton,
      moveUpButton,
      onAdd,
      onDelete,
      onEdit,
      onView,
      selectedRow,
      selectedRowIndex,
      selectedRowsIndexes,
    ],
  );

  const parsedToolbarButtons = useMemo(
    () =>
      (toolbar || []).filter(Boolean).flatMap<ButtonProps>(button => {
        if (typeof button === 'string') {
          return defaultButtons[button as DefaultButtonKey];
        }

        const buttonKey: DefaultButtonKey | undefined = ((button as any).key as DefaultButtonKey) || undefined;

        if (buttonKey && Object.values(DefaultButtonKey).includes(buttonKey)) {
          const defaultButton = button as DefaultToolbarButton<Row>;

          return defaultButtons[buttonKey].map<ButtonProps>(stockButton => ({
            ...stockButton,
            ...defaultButton,
            onClick: defaultButton.onClick
              ? () => defaultButton.onClick?.(selectedRow, selectedRowIndex, rows)
              : stockButton.onClick,
            isDisabled:
              typeof defaultButton.isDisabled === 'function'
                ? defaultButton.isDisabled?.(selectedRow)
                : defaultButton.isDisabled || stockButton.isDisabled,
          }));
        }

        const customButton = button as CustomToolbarButton<Row>;

        return [
          {
            ...customButton,
            onClick: () => customButton.onClick?.(selectedRow, selectedRowIndex, rows),
            isDisabled:
              typeof customButton.isDisabled === 'function' ? customButton.isDisabled?.(selectedRow) : customButton.isDisabled,
          },
        ];
      }),
    [defaultButtons, toolbar, rows, selectedRow, selectedRowIndex],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [...(parsedToolbarButtons || []), ...(isVisibleFilters ? filterButtons : [])] as ButtonProps[],
    [filterButtons, isVisibleFilters, parsedToolbarButtons],
  );

  return <Toolbar buttons={buttons} mode="list" />;
}

export { ToolbarButtons };

import React, { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, FormComponent, Modal, TextArea, TextAreaMode, TextDateTime, Toolbar } from 'components';

import { Table, Report } from 'types/models';
import { Reports, useReportsHook, BuildReportPopup } from 'features/BuildReportPopup';
import { State } from '../makeUseCustomController';
import { ProjectPerformerJobPeriodGridHelp } from './help';
import { SaveProjectScientistsJobPeriodType } from 'features/Table/specifications/GetProjectScientistList/types';
import { getMockSaveProjectScientistsJobPeriodArgs } from 'features/Table/specifications/GetProjectScientistList/helpers';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { GetProjectPerformerOrderList } from 'features/Table/specifications/GetProjectPerformerOrderList';

type Props = {
  tableState: Table.State;
  customState: State;
};

const ToolbarTemplate = (props: Props) => {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const projectId = useMemo(
    () =>
      selectedRows[0] && selectedRows.every(i => i.ProjectId === selectedRows[0].ProjectId)
        ? selectedRows[0].ProjectId
        : undefined,
    [selectedRows],
  );

  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isOpenSaveProjectScientistsJobPeriod, setIsOpenSaveProjectScientistsJobPeriod] = useState<boolean>(false);
  const [
    saveProjectScientistsJobPeriodArgs,
    setSaveProjectScientistsJobPeriodArgs,
  ] = useState<SaveProjectScientistsJobPeriodType>(getMockSaveProjectScientistsJobPeriodArgs());
  const [invalidScientists, setInvalidScientists] = useState<string[]>([]);
  const [isOpenInvalidScientists, setIsOpenInvalidScientists] = useState<boolean>(false);

  const { methods: getPerformerOrder } = BackendAPI.useBackendAPI('GetProjectPerformerOrder');
  const { methods: saveProjectScientistsJobPeriodAPI } = BackendAPI.useBackendAPI('SaveProjectScientistsJobPeriod');

  const reports = useMemo<Report[]>(
    () => [Reports.ScienceProjectPerformerCompositionMemoBySalary, Reports.СompositionBySalary, Reports.ProjectPerformers],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const saveProjectScientistsJobPeriod = useCallback(() => {
    saveProjectScientistsJobPeriodAPI.callAPI(
      {
        ...saveProjectScientistsJobPeriodArgs,
        projectScientists: ((): string[] => {
          const arrScientistId = selectedRows.map(row => row.ProjectScientistId);

          return arrScientistId.filter((i, index) => arrScientistId.indexOf(i) === index);
        })(),
      },
      {
        onSuccessfullCall: ({ data }) => {
          showNotification({ message: 'Периоды работы по приказу оформлены', theme: 'success' });
          setIsOpenSaveProjectScientistsJobPeriod(false);
          setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());

          if (data.length) {
            setInvalidScientists(data);
            setIsOpenInvalidScientists(true);
          }

          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [saveProjectScientistsJobPeriodAPI, saveProjectScientistsJobPeriodArgs, selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'merge', mode: 'info' },
        title: 'Оформить периоды работы по приказу',
        onClick: () => setIsOpenSaveProjectScientistsJobPeriod(true),
        isDisabled: !projectId,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
        isDisabled: !projectId,
      },
    ],
    [getReports, handleSetCurrentReport, projectId],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{ projectId }}
      />

      <Modal
        title="Оформить периоды работы в проекте по приказу"
        isOpen={isOpenSaveProjectScientistsJobPeriod}
        onClose={() => {
          setIsOpenSaveProjectScientistsJobPeriod(false);
          setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: saveProjectScientistsJobPeriod,
            isDisabled:
              !saveProjectScientistsJobPeriodArgs.jobPeriod.startDate || !saveProjectScientistsJobPeriodArgs.jobPeriod.endDate,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => {
              setIsOpenSaveProjectScientistsJobPeriod(false);
              setSaveProjectScientistsJobPeriodArgs(getMockSaveProjectScientistsJobPeriodArgs());
            },
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Дата начала работы" isRequired>
            <TextDateTime
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.startDate}
              onChange={e =>
                setSaveProjectScientistsJobPeriodArgs(prev => ({ ...prev, jobPeriod: { ...prev.jobPeriod, startDate: e } }))
              }
            />
          </FormComponent.Field>
          <FormComponent.Field label="Дата окончания работы" isRequired>
            <TextDateTime
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.endDate}
              onChange={e =>
                setSaveProjectScientistsJobPeriodArgs(prev => ({ ...prev, jobPeriod: { ...prev.jobPeriod, endDate: e } }))
              }
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Приказ для даты начала">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'На основании приказа',
                table: {
                  specification: GetProjectPerformerOrderList({
                    hasSelectButton: true,
                    projectId,
                  }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setSaveProjectScientistsJobPeriodArgs(prev => ({
                        ...prev,
                        jobPeriod: { ...prev.jobPeriod, startDateOrder: null },
                      }));
                      return;
                    }
                    getPerformerOrder.callAPI(
                      { id: row?.id },
                      {
                        onSuccessfullCall: ({ data }) =>
                          setSaveProjectScientistsJobPeriodArgs(prev => ({
                            ...prev,
                            jobPeriod: { ...prev.jobPeriod, startDateOrder: data },
                          })),
                      },
                    );
                  },
                },
              }}
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.startDateOrder?.number || ''}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Приказ для даты окончания">
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'На основании приказа',
                table: {
                  specification: GetProjectPerformerOrderList({
                    hasSelectButton: true,
                    projectId,
                  }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setSaveProjectScientistsJobPeriodArgs(prev => ({
                        ...prev,
                        jobPeriod: { ...prev.jobPeriod, endDateOrder: null },
                      }));
                      return;
                    }
                    getPerformerOrder.callAPI(
                      { id: row?.id },
                      {
                        onSuccessfullCall: ({ data }) =>
                          setSaveProjectScientistsJobPeriodArgs(prev => ({
                            ...prev,
                            jobPeriod: { ...prev.jobPeriod, endDateOrder: data },
                          })),
                      },
                    );
                  },
                },
              }}
              value={saveProjectScientistsJobPeriodArgs.jobPeriod.endDateOrder?.number || ''}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>

      <Modal
        mode="warning"
        title="Внимание"
        isOpen={isOpenInvalidScientists}
        onClose={() => {
          setInvalidScientists([]);
          setIsOpenInvalidScientists(false);
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => {
              setInvalidScientists([]);
              setIsOpenInvalidScientists(false);
            },
          },
        ]}
        size="small"
      >
        <strong>
          Для следующих членов коллектива не было внесено изменений - у исполнителей уже есть более одного периода работ
        </strong>

        <ul>
          {invalidScientists.map((x, index) => (
            <li key={index}>{x}</li>
          ))}
        </ul>
      </Modal>

      <Modal
        mode="help"
        title="Список участников проектов"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size={['large', 'medium-height']}
      >
        {ProjectPerformerJobPeriodGridHelp()}
      </Modal>
    </>
  );
};

export const Component = React.memo(ToolbarTemplate);

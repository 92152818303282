import { useMemo } from 'react';

import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';
import { useAppDataContext } from 'features/AppData/context';
import { NirRequestStatus } from 'utils/Enums';

const useController = ({ parameters, parameterName }: BuilderProps) => {
  const { enumMap } = useAppDataContext();

  const parameter = parameters.find(x => x.name === parameterName);
  const defaultValuesOrdinals = useMemo(() => parameter?.default.replaceAll('"', '')?.split(',') || [], [parameter?.default]);

  const checkboxList = useMemo<CheckboxItem[]>(() => {
    return enumMap.NirRequestStatus.values
      .filter(
        v =>
          v.value === NirRequestStatus.SUPPORTED ||
          v.value === NirRequestStatus.AGREED_WITH_CONDITION ||
          v.value === NirRequestStatus.AGREED,
      )
      .map(({ ordinal, label }) => ({
        isChecked: defaultValuesOrdinals.includes(ordinal.toString()),
        label,
        value: ordinal.toString(),
      }));
  }, [defaultValuesOrdinals, enumMap.NirRequestStatus.values]);
  return {
    checkboxList,
  };
};

export default useController;

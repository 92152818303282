import { IdItem, Item } from 'types/models/common';
import { ReferenceItem } from 'components';
import { Member } from 'features/SelectPerson';
import { Document } from 'types/models/Document';
import { Performer, Project } from 'types/models/Project';
import { PurchaseRequestStatus } from 'utils/Enums';

export type PurchaseRequest = {
  id: string | null;
  status: Item | null;
  sendDate: string;
  project: Project | null;
  name: string;
  number: string;
  applicant: Performer | null;
  limitPrice: string;
  department: ReferenceItem | null;
  financeResponsible: Member | null;
  requestResponsible: Member | null;
  deliveryAddress: string;
  deliveryDate: string;
  deliveryNote: string;
  contractKind: Item | null;
  note: string;
  documents: Document[];
  units: Unit[];
  paymentTerm: Item | null;
  purchaseMethod: Item | null;
};

export type Unit = {
  type: Item | null;
  name: string;
  quantity: string;
  unit: ReferenceItem | null;
  perUnitPrice: string;
  position: string;
};

export type PurchaseRequestMessage = {
  id: string | null;
  purchaseRequest?: IdItem | null;
  message: string | null;
};

export const purchaseRequestTextMap: Record<string, string> = {
  DRAFT: 'Перевести заявку в статус: Черновик',
  CONSIDERATION: 'Передать заявку на рассмотрение',
  REVISION: 'Вернуть заявку на доработку',
  REJECTED: 'Перевести заявку в статус: Отклонена',
  IN_ACTION_PROVIDER: 'Перевести заявку в статус: В работе (поставщик)',
  COMPLETE: 'Перевести заявку в статус: Выполнена',
};
export const purchaseRequestSubmitTextMap: Record<string, string> = {
  DRAFT: 'В черновик',
  CONSIDERATION: 'На рассмотрение',
  REVISION: 'На доработку',
  REJECTED: 'Отклонить',
  IN_ACTION_PROVIDER: 'В работе',
  COMPLETE: 'Выполнена',
};

export const approverKindTitleMap: Record<string, string> = {
  MANAGEMENT_COMMITTEE: 'Виза управляющего комитета',
  PRICE_COMMISSION: 'Виза ценовой комиссии',
  LOGISTIC_MANAGEMENT: 'Виза управления логистики',
};

export function isNextPurchaseRequestStatus(current: string, next: string) {
  let canChangeStatus = false;
  switch (current) {
    case PurchaseRequestStatus.DRAFT:
      canChangeStatus = next === PurchaseRequestStatus.CONSIDERATION;
      break;
    case PurchaseRequestStatus.CONSIDERATION:
      canChangeStatus =
        next === PurchaseRequestStatus.REVISION ||
        next === PurchaseRequestStatus.REJECTED ||
        next === PurchaseRequestStatus.IN_ACTION_PROVIDER ||
        next === PurchaseRequestStatus.COMPLETE ||
        next === PurchaseRequestStatus.DRAFT;
      break;
    case PurchaseRequestStatus.REVISION:
      canChangeStatus = next === PurchaseRequestStatus.CONSIDERATION;
      break;
    case PurchaseRequestStatus.REJECTED:
      canChangeStatus = next === PurchaseRequestStatus.REVISION;
      break;
    case PurchaseRequestStatus.IN_ACTION_PROVIDER:
      canChangeStatus = next === PurchaseRequestStatus.COMPLETE || next === PurchaseRequestStatus.REJECTED;
      break;
    case PurchaseRequestStatus.COMPLETE:
      canChangeStatus = next === PurchaseRequestStatus.DRAFT;
      break;
    default:
      canChangeStatus = false;
  }
  return canChangeStatus;
}

import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextDateTime, TextInput, TextInputMode } from 'components';

import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { Field, FieldValue } from 'types/models/CitationSystemIndex';
import { Item } from 'types/models/common';

type Props = {
  fields: Record<string, Field>;
  enumMap: T.EnumMap;
  citationSystems: CitationSystem[];
  citationSystem: ReferenceItem | null;
  onChangeField(fieldId: string, value: FieldValue): void;
};

function Fields(props: Props) {
  const { fields, citationSystem, citationSystems, onChangeField, enumMap } = props;

  return (
    <FormComponent.Wrapper>
      {Object.values(fields).map((field, index) => {
        // BY CODES
        if (field.code === 'PROCENTILE') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.NUMBER}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{ max: 100 }}
              />
            </FormComponent.Field>
          );
        }

        if (field.code === 'YEAR') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.YEAR}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
              />
            </FormComponent.Field>
          );
        }

        // BY TYPES
        if (field.type === 'DATE') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextDateTime
                key={field.valueId}
                value={field.value as string}
                onChange={date => onChangeField(field.valueId, (date as string) ?? '')}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'REFERENCE' && field.metaType) {
          if (field.metaType === null) {
            return null;
          }

          const foundCitationSystem = citationSystems.find(x => x.id === citationSystem?.id) ?? null;

          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <Select
                mode={SelectMode.REFERENCE}
                value={(field.value as ReferenceItem) || null}
                onChange={(option: ReferenceItem) => onChangeField(field.valueId, option)}
                settings={{
                  name: field.metaType,
                  title: field.name,
                  filters: [
                    { key: 'citationSystem', values: citationSystem ? [citationSystem.id] : [] },
                    {
                      key: 'id',
                      values:
                        foundCitationSystem && foundCitationSystem?.thematicCategories.length
                          ? foundCitationSystem.thematicCategories.map(x => x.id)
                          : ['-1'],
                    },
                  ],
                }}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'ENUMERATION' && field.metaType) {
          if (field.metaType === null || !Object.values(enumMap).length) {
            return null;
          }

          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <Select
                key={field.valueId}
                mode={SelectMode.ENUM}
                value={{ value: field.value, label: field.value } as Item}
                onChange={(option: Item) => onChangeField(field.valueId, option.label)}
                settings={{ name: field.metaType }}
                placeholder=""
              />
            </FormComponent.Field>
          );
        }

        if (field.code === 'URL') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.URL}
                value={field.value.toString()}
                onChange={nextValue => onChangeField(field.valueId, nextValue ?? '')}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'FLOAT') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.NUMBER}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{
                  isThousands: false,
                  decimal: { limit: 3 },
                }}
              />
            </FormComponent.Field>
          );
        }

        if (field.type === 'INTEGER') {
          return (
            <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
              <TextInput
                key={field.valueId}
                mode={TextInputMode.NUMBER}
                value={field.value as string}
                onChange={nextValue => onChangeField(field.valueId, nextValue)}
                settings={{
                  isThousands: false,
                  decimal: false,
                }}
              />
            </FormComponent.Field>
          );
        }

        return (
          <FormComponent.Field key={index} label={field.name} isRequired={field.isRequired}>
            <TextInput
              key={field.valueId}
              value={field.value as string}
              onChange={nextValue => onChangeField(field.valueId, nextValue)}
            />
          </FormComponent.Field>
        );
      })}
    </FormComponent.Wrapper>
  );
}

export { Fields };

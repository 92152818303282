import { SecurityDocumentInfo, SecurityDocumentPayment } from 'types/models/SecurityDocument';

import { convertReference, getId, getText, convertFile, convertItem } from '../commonConverters';
import { SecurityDocumentType } from 'utils/Enums';

export function convertSecurityDocumentPayment(p: any): SecurityDocumentPayment {
  return {
    id: getId(p),
    amount: getText(p.Amount),
    file: convertFile(p.File),
    payBeforeDate: getText(p.PayBeforeDate),
    payDate: getText(p.PayDate),
    paymentPurpose: p.PaymentPurpose ? convertReference(p.PaymentPurpose) : null,
    paymentPurposeRequest: p.PaymentPurposeRequest ? convertReference(p.PaymentPurposeRequest) : null,
    securityDocument: p.SecurityDocument ? convertSecurityDocumentInfo(p.SecurityDocument) : null,
  };
}
export function convertSecurityDocumentInfo(s: any): SecurityDocumentInfo {
  return {
    id: getId(s),
    fullName: getText(s.FullName),
    type: convertItem<SecurityDocumentType>(s.Type),
    documentNumber: getText(s.DocumentNumber),
  };
}

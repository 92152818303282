import React from 'react';

import { Column } from 'components/ListEdit/model';
import { RefElementList } from 'features/Form/looks/project/ProjectForm/views';
import { RefElement } from 'types/models/Project';

import { FormComponent } from 'components';
import { useController } from './controller';

type Props = {
  title: string;
  rows: RefElement[];
  onChange(refs: RefElement[]): void;
  modalTitle: string;
  refName: string;
  columns?: Column<RefElement>[];
  disabled?: boolean;
};

export function ClassifierEdit({ title, rows, onChange, modalTitle, refName, columns, disabled }: Props) {
  const { extraToolbarButtons, handleClassifierChange } = useController({
    disabled,
    onChange,
  });

  return (
    <FormComponent.Line>
      <RefElementList
        header={{ title: title }}
        rows={rows}
        onChange={handleClassifierChange}
        toolbar={['add', 'delete', ...extraToolbarButtons, 'move']}
        columns={
          columns || [
            {
              label: 'Наименование',
              styles: { width: '70%' },
              formatValue: (x: RefElement) => x.element?.label ?? '',
            },
            {
              label: 'Источник',
              styles: { width: '15%' },
              formatValue: (x: RefElement) => (x?.isProject ? 'Проект' : 'Охранный документ'),
            },
            {
              label: 'Актуальный',
              styles: { width: '15%' },
              formatValue: x => x?.isActual,
            },
          ]
        }
        modalTitle={modalTitle}
        refName={refName}
        isDisabled={disabled}
      />
    </FormComponent.Line>
  );
}

import { CommunityType } from 'utils/Enums';

export type State = {
  communityTypes: CommunityType[];
  isMembersTabHidden?: boolean;
};

export function makeUseCustomController(communityTypes: CommunityType[], isMembersTabHidden?: boolean) {
  return function useCustomController(): State {
    return { communityTypes, isMembersTabHidden };
  };
}

import React from 'react';

import { Modal, Toolbar } from 'components';

import { useController } from './controller';
import { ReceivingDocumentGridHelp } from './help';

function LeftPanelForThirdLevel() {
  const { buttons, isHelpOpen, setIsHelpOpen } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Зачислено на шифры"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {ReceivingDocumentGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

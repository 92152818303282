/* eslint-disable max-len */
export const modes: Record<string, JSX.Element> = {
  // add: <path className="success" d="M5 0H3v3H0v2h3v3h2V5h3V3H5V0Z" />,
  add: (
    <>
      <path className="success" d="M8 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0Z" />
      <path fill="#fff" d="M5 1H3v2H1v2h2v2h2V5h2V3H5V1Z" />
    </>
  ),
  edit: (
    <>
      <path d="m4.942 1.058.6-.6a1.414 1.414 0 0 1 2 2l-.6.6-2-2ZM0 6v2h2L0 6Z" />
      <path className="warning" d="M0 6.03 4.03 2 6 3.97 1.97 8 0 6.03Z" />
    </>
  ),
  remove: <path className="danger" d="M4 2.5 1.5 0 0 1.5 2.5 4 0 6.5 1.5 8 4 5.5 6.5 8 8 6.5 5.5 4 8 1.5 6.5 0 4 2.5Z" />,
  info: <path className="info" d="M4 2a1 1 0 1 0 0-2 1 1 0 0 0 0 2ZM4 3a1 1 0 0 0-1 1v3a1 1 0 0 0 2 0V4a1 1 0 0 0-1-1Z" />,
  clock: (
    <>
      <path className="accent" d="M4 0a4 4 0 1 0 0 8 4 4 0 0 0 0-8Zm0 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6Z" />
      <path d="M3.5 1.5h1v2.25L6.3 5.1l-.6.8-2.2-1.65V1.5Z" />
    </>
  ),
  check: <path className="success" d="M1.429 2.771 0 4.2l3 3 5-5L6.5.7 2.929 4.271l-1.5-1.5Z" />,
  new: <path className="info" d="M1 8h6V2L5 0H1v8Zm4.6-5v3.6H2.4V1.4H4V3h1.6Z" />,
  question: (
    <path
      className="success"
      d="M3.335.3 1.978.978 1.3 2.335V3.5h1.4v-.835l.322-.643.643-.322h.67l.643.322.322.643v.17l-.343.686-.9.6A1.7 1.7 0 0 0 3.3 5.535V6h1.4v-.465a.3.3 0 0 1 .134-.25l1.209-.806.657-1.314v-.83L6.022.978 4.665.3h-1.33ZM4 8a.7.7 0 1 0 0-1.4A.7.7 0 0 0 4 8Z"
    />
  ),
  view: (
    <path
      className="info"
      d="M4.73 6.144A3.25 3.25 0 1 1 6.144 4.73l1.532 1.532a1 1 0 0 1-1.414 1.414L4.73 6.144ZM5.1 3.25a1.85 1.85 0 1 1-3.7 0 1.85 1.85 0 0 1 3.7 0Z"
    />
  ),
  arrowUp: <path className="success" d="m4 0 4 4-1.5 1.5L5 4v4H3V4L1.5 5.5 0 4l4-4Z" />,
  arrowRight: <path className="success" d="M8 4 4 8 2.5 6.5 4 5H0V3h4L2.5 1.5 4 0l4 4Z" />,
  arrowDown: <path className="success" d="M4 8 0 4l1.5-1.5L3 4V0h2v4l1.5-1.5L8 4 4 8Z" />,
  arrowLeft: <path className="success" d="m0 4 4-4 1.5 1.5L4 3h4v2H4l1.5 1.5L4 8 0 4Z" />,
  excel: (
    <>
      <path className="success" d="M0 0h8v8H0V0Z" />
      <path
        fill="#fff"
        d="M6.827 1.706 5.188.56 4 2.257 2.812.559 1.173 1.707 2.78 4 1.173 6.293l1.639 1.148L4 5.743l1.188 1.698 1.639-1.148L5.22 4l1.606-2.294Z"
      />
    </>
  ),
  cancel: (
    <path
      className="danger"
      d="M8 4a4 4 0 1 1-8 0 4 4 0 0 1 8 0ZM6.5 4a2.5 2.5 0 0 1-3.656 2.217l3.373-3.373c.18.345.283.739.283 1.156ZM1.783 5.156l3.373-3.373a2.5 2.5 0 0 0-3.373 3.373Z"
    />
  ),
};

export const types: Record<string, JSX.Element> = {
  add: <path d="M12 0H8v8H0v4h8v8h4v-8h8V8h-8V0Z" />,
  edit: (
    <path d="M13.321.679 0 13.999V20h6L19.321 6.679a2 2 0 0 0 0-2.829L16.15.68a2 2 0 0 0-2.828 0ZM6 17l-1 1H2v-3L12 5l3 3-9 9Zm7-13 1.293-1.293a1 1 0 0 1 1.414 0l1.586 1.586a1 1 0 0 1 0 1.414L16 7l-3-3Z" />
  ),
  remove: (
    <path
      className="danger"
      d="M2.929.1.1 2.93 7.17 10l-7.07 7.071L2.929 19.9 10 12.83l7.071 7.07 2.828-2.828L12.83 10l7.07-7.071L17.072.1 10 7.17 2.929.102Z"
    />
  ),
  clear: (
    <path
      className="danger"
      d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z"
    />
  ),
  check: (
    <path
      className="success"
      d="M19.9142 5.41412L7.94666 17.3817L0.0344238 8.86081L2.9656 6.139L8.05336 11.6181L17.0858 2.58569L19.9142 5.41412Z"
    />
  ),
  cancel: (
    <path
      className="danger"
      d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-3 0a7 7 0 0 1-10.775 5.896l9.671-9.67A6.968 6.968 0 0 1 17 10ZM4.104 13.775l9.67-9.671a7 7 0 0 0-9.67 9.67Z"
    />
  ),
  danger: (
    <>
      <path
        className="danger"
        d="M9.138 2.465a1 1 0 0 1 1.724 0l8.251 14.028A1 1 0 0 1 18.252 18H1.748a1 1 0 0 1-.862-1.507L9.138 2.465Z"
      />
      <path fill="#fff" d="M11.5 7h-3v3l.5 4h2l.5-4V7ZM11 15H9v2h2v-2Z" />
    </>
  ),
  info: (
    <>
      <path d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Z" />
      <path fill="#fff" d="M10 2a2 2 0 1 0 0 4 2 2 0 0 0 0-4ZM10 8a2 2 0 0 0-2 2v6a2 2 0 1 0 4 0v-6a2 2 0 0 0-2-2Z" />
    </>
  ),
  question: (
    <>
      <path d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Z" />
      <path
        fill="#fff"
        d="M8.645 2 5.882 3.41 4.5 6.227v2.421h2.852V6.915l.655-1.337 1.311-.669h1.364l1.31.669.656 1.337v.352l-.698 1.425-1.834 1.247a3.552 3.552 0 0 0-1.542 2.94v.965h2.852v-.966c0-.208.102-.403.272-.518l2.464-1.676 1.338-2.73V6.228l-1.382-2.819L11.355 2h-2.71ZM10 18c.787 0 1.426-.651 1.426-1.454 0-.804-.639-1.455-1.426-1.455-.788 0-1.426.651-1.426 1.455C8.574 17.349 9.212 18 10 18Z"
      />
    </>
  ),
  star: (
    <path d="M12.36 7.64 10 0 7.64 7.64H0l6.18 4.72L3.82 20 10 15.279 16.18 20l-2.36-7.64L20 7.64h-7.64Zm2.786 1.658h-3.931L10 5.367l-1.215 3.93h-3.93l3.18 2.43-1.215 3.931 3.18-2.43 3.18 2.43-1.214-3.93 3.18-2.43Z" />
  ),
  refresh: (
    <path d="M10 19c4.633 0 8.448-3.5 8.945-8H16.93A7.001 7.001 0 0 1 3 10a7 7 0 0 1 13.326-3H14.5a1 1 0 1 0 0 2h5V4a1 1 0 1 0-2 0v1.023A9 9 0 1 0 10 19Z" />
  ),
  home: (
    <>
      <path d="M9.029 2.396a2 2 0 0 1 1.942 0l8.515 4.73-.972 1.748L10 4.144l-8.514 4.73-.972-1.748 8.515-4.73ZM2 16V9h2v7h12V9h2v7a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2Z" />
      <path d="M7 9a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V9Zm2 3v-2h2v2H9Z" />
    </>
  ),
  graph: (
    <>
      <path d="M6 6.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
      <path d="M0 4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm2 0h16v2.524l-6.716 5.457-.946-1.656a2 2 0 0 0-2.631-.796L2 12.382V4Zm0 10.618V16h16V9.101l-5.455 4.432a2 2 0 0 1-2.998-.56l-.946-1.655L2 14.617Z" />
    </>
  ),
  delete: (
    <>
      <path d="M13 2H7V0h6v2ZM6 14V7h2v7H6ZM12 14V7h2v7h-2Z" />
      <path d="M2 5H0V3h20v2h-2v13a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5Zm2 0v13h12V5H4Z" />
    </>
  ),
  exit: (
    <>
      <path d="M4 0a2 2 0 0 0-2 2v6h2V2h12v16H4v-6H2v6a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2H4Z" />
      <path d="m8.697 10.829-7.594.03a.58.58 0 0 1-.584-.583l.003-.586a.591.591 0 0 1 .588-.587l7.47-.03-1.95-1.95a.586.586 0 0 1 .003-.828l.415-.415c.23-.23.6-.231.828-.004l3.712 3.712a.586.586 0 0 1-.004.828l-3.74 3.74c-.23.23-.6.232-.829.004l-.412-.412a.586.586 0 0 1 .003-.829l2.09-2.09Z" />
    </>
  ),
  view: (
    <path d="M14.624 16.027a9 9 0 1 1 1.403-1.403c.033.025.064.053.094.083l3.536 3.536a1 1 0 0 1-1.414 1.414l-3.536-3.536a1.007 1.007 0 0 1-.083-.094ZM16 9A7 7 0 1 1 2 9a7 7 0 0 1 14 0Z" />
  ),
  calc: (
    <>
      <path d="m13.95 7.364 1.768 1.768 1.414-1.414-1.768-1.768 1.768-1.768-1.415-1.414-1.767 1.768-1.768-1.768-1.414 1.414 1.768 1.768-1.768 1.768 1.414 1.414 1.768-1.768ZM3 5h6v2H3V5ZM17 15h-6v2h6v-2ZM17 11h-6v2h6v-2ZM5 15v2h2v-2h2v-2H7v-2H5v2H3v2h2Z" />
      <path d="M0 2a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm2 0h16v16H2V2Z" />
    </>
  ),
  eye: (
    <>
      <path d="M13 10a3 3 0 1 1-6 0 3 3 0 0 1 6 0Zm-2 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0Z" />
      <path d="M10 16c5.09 0 9.3-2.608 10-6-.7-3.392-4.91-6-10-6S.7 6.608 0 10c.7 3.392 4.91 6 10 6Zm7.927-6c-.279-.793-.898-1.596-1.923-2.306C14.551 6.687 12.434 6 10 6c-2.434 0-4.55.687-6.004 1.694C2.97 8.404 2.352 9.207 2.073 10c.279.793.898 1.596 1.923 2.306C5.449 13.313 7.566 14 10 14c2.434 0 4.55-.687 6.004-1.694 1.025-.71 1.644-1.513 1.923-2.306Z" />
    </>
  ),
  mail: (
    <path d="M0 4a2 2 0 0 1 2-2h16a2 2 0 0 1 2 2v12a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4Zm17.197 0H2.803L10 8.798 17.197 4ZM2 5.869V16h16V5.869l-6.89 4.593a2 2 0 0 1-2.22 0L2 5.87Z" />
  ),
  print: (
    <>
      <path d="M5 10V8H3v2h2ZM8 8H6v2h2V8Z" />
      <path d="M3 1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v2a1 1 0 0 1 1 1v1h1a1 1 0 0 1 1 1v10a1 1 0 0 1-1 1h-2v2a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1v-2H1a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h1V4a1 1 0 0 1 1-1V1Zm2 4h10V2H5v3ZM3 15v-1a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v1h1V7H2v8h1Zm2 0h10v3H5v-3Z" />
    </>
  ),
  link: (
    <>
      <path d="M2.167 14.12c.135-.472.39-.919.762-1.291L5.757 10a1 1 0 1 0-1.414-1.414l-2.828 2.828a5.018 5.018 0 0 0-1.08 1.608 5.002 5.002 0 0 0 8.15 5.463l2.83-2.828A1 1 0 0 0 10 14.243L7.172 17.07a3 3 0 0 1-5.005-2.95ZM18.485 1.515a5.002 5.002 0 0 1 0 7.07l-2.828 2.83A1 1 0 0 1 14.243 10l2.828-2.828a3 3 0 0 0-2.95-5.005c-.474.135-.92.39-1.293.762L10 5.757a1 1 0 0 1-1.414-1.414l2.828-2.828a5.027 5.027 0 0 1 1.608-1.08 5.002 5.002 0 0 1 5.463 1.08Z" />
      <path d="M6.464 13.536a1 1 0 0 1 0-1.415l5.657-5.657a1 1 0 1 1 1.415 1.415l-5.657 5.657a1 1 0 0 1-1.415 0Z" />
    </>
  ),
  admin: (
    <>
      <path d="M10 0a9.003 9.003 0 0 1 8.777 7H19a1 1 0 0 1 1 1v4a1 1 0 0 1-1 1h-.223a9.017 9.017 0 0 1-5.937 6.543A1 1 0 0 1 12 20H9a1 1 0 0 1-1-1v-1a1 1 0 0 1 1-1h3c.333 0 .628.163.81.413A7.002 7.002 0 0 0 17 11V9A7 7 0 1 0 3 9v4H1a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1h.223C2.133 2.992 5.717 0 10 0Z" />
      <path d="M10.022 3c2.707.012 4.991 2.114 5.728 4.994A5.5 5.5 0 0 1 10.022 3ZM8.999 3.097A5 5 0 0 1 4.25 7.994c.646-2.526 2.482-4.453 4.749-4.897ZM8.5 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM14.5 10.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM11.5 14c0 .552-.672 1-1.5 1s-1.5-.448-1.5-1 .672-1 1.5-1 1.5.448 1.5 1Z" />
    </>
  ),
  download: (
    <>
      <path d="M10 0a1 1 0 0 0-1 1v10.25L6.562 8.812a.972.972 0 1 0-1.375 1.376L10 15l4.813-4.813a.972.972 0 0 0-1.376-1.375L11 11.25V1a1 1 0 0 0-1-1Z" />
      <path d="M1 14a1 1 0 0 0-1 1v4a1 1 0 0 0 1 1h18a1 1 0 0 0 1-1v-4a1 1 0 1 0-2 0v3H2v-3a1 1 0 0 0-1-1Z" />
    </>
  ),
  toSmall: (
    <path d="M15.5 6H17a1 1 0 1 1 0 2h-5V3a1 1 0 1 1 2 0v1.5L18.043.457a1 1 0 0 1 1.414 0l.086.086a1 1 0 0 1 0 1.414L15.5 6ZM4.5 6H3a1 1 0 0 0 0 2h5V3a1 1 0 0 0-2 0v1.5L1.957.457a1 1 0 0 0-1.414 0L.457.543a1 1 0 0 0 0 1.414L4.5 6ZM.457 18.043a1 1 0 0 0 0 1.414l.086.086a1 1 0 0 0 1.414 0L6 15.5V17a1 1 0 1 0 2 0v-5H3a1 1 0 1 0 0 2h1.5L.457 18.043ZM18.043 19.544a1 1 0 0 0 1.414 0l.086-.086a1 1 0 0 0 0-1.414L15.499 14H17a1 1 0 1 0 0-2h-5v5a1 1 0 1 0 2 0v-1.499l4.043 4.043Z" />
  ),
  toFull: (
    <path d="M1 6a1 1 0 0 1-1-1V0h5a1 1 0 0 1 0 2H3.5l3.793 3.793a1 1 0 0 1 0 1.414l-.086.086a1 1 0 0 1-1.414 0L2 3.5V5a1 1 0 0 1-1 1ZM14 1a1 1 0 0 1 1-1h5v5a1 1 0 1 1-2 0V3.5l-3.793 3.793a1 1 0 0 1-1.414 0l-.086-.086a1 1 0 0 1 0-1.414L16.5 2H15a1 1 0 0 1-1-1ZM20 15a1 1 0 1 0-2 0v1.501l-3.793-3.793a1 1 0 0 0-1.414 0l-.086.086a1 1 0 0 0 0 1.414L16.5 18H15a1 1 0 1 0 0 2h5v-5ZM6 19a1 1 0 0 1-1 1H0v-5a1 1 0 1 1 2 0v1.5l3.793-3.793a1 1 0 0 1 1.414 0l.086.086a1 1 0 0 1 0 1.414L3.5 18H5a1 1 0 0 1 1 1Z" />
  ),
  settings: (
    <>
      <path d="M10 0c.936 0 1.842.129 2.7.369l-.287 2.001A7.992 7.992 0 0 1 15.4 4.097l1.588-1.25a9.989 9.989 0 0 1 2.703 4.677l-1.877.75a8.032 8.032 0 0 1 0 3.451l1.877.751a9.989 9.989 0 0 1-2.703 4.677l-1.588-1.25a8.035 8.035 0 0 0 1.45-1.77l.163-.28-.004.007c.135-.244.258-.497.367-.757l-1.75-1.01A5.987 5.987 0 0 0 16 10c0-.736-.133-1.441-.375-2.093l1.75-1.01a7.948 7.948 0 0 0-.37-.763l-.147-.255a8.011 8.011 0 0 0-.484-.714l-1.75 1.01A5.994 5.994 0 0 0 11 4.084V2.062c-.32-.04-.646-.061-.976-.062h-.048c-.33.001-.656.022-.976.062v2.02a5.994 5.994 0 0 0-3.623 2.093l-1.75-1.01a8.015 8.015 0 0 0-.496.731l-.127.22c-.14.252-.267.512-.38.78l1.751 1.01A5.987 5.987 0 0 0 4 10c0 .736.133 1.441.375 2.093l-1.75 1.01a8.029 8.029 0 0 1-.438-4.83l-1.878-.75a9.99 9.99 0 0 1 2.703-4.676L4.6 4.097A7.991 7.991 0 0 1 7.587 2.37L7.3.37C8.16.129 9.064 0 10 0Z" />
      <path d="M14.624 13.824A5.994 5.994 0 0 1 11 15.917v2.021a7.993 7.993 0 0 0 5.375-3.104l-1.75-1.01ZM3.14 14.118a7.994 7.994 0 0 1-.144-.25l.144.25ZM5.376 13.824l-1.75 1.01A7.993 7.993 0 0 0 9 17.939v-2.021a5.994 5.994 0 0 1-3.624-2.093Z" />
      <path d="M4.6 15.903a7.992 7.992 0 0 1-2.413-4.178l-1.878.751a9.99 9.99 0 0 0 2.703 4.677l1.588-1.25ZM7.587 17.63 7.3 19.63c.86.24 1.765.369 2.701.369.936 0 1.842-.129 2.7-.369l-.287-2.001c-.762.24-1.572.37-2.413.37-.84 0-1.651-.13-2.413-.37Z" />
      <path d="M13.5 10a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0Zm-2 0a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0Z" />
    </>
  ),
  clock: (
    <>
      <path d="M9 5v4.586A2 2 0 0 0 9.586 11l3.207 3.207 1.414-1.414L11 9.586V5H9Z" />
      <path d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-2.062 1A8.004 8.004 0 0 1 11 17.938V17H9v.938A8.004 8.004 0 0 1 2.062 11H3V9h-.938A8.004 8.004 0 0 1 9 2.062V3h2v-.938A8.004 8.004 0 0 1 17.938 9H17v2h.938Z" />
    </>
  ),
  message: (
    <>
      <path d="M13 5H4v2h9V5ZM16 10H4v2h12v-2Z" />
      <path d="M2 0a2 2 0 0 0-2 2.002v13.012a2 2 0 0 0 2 2.001h1.323L4.516 20l4.77-2.985H18a2 2 0 0 0 2-2.001V2.002A2 2 0 0 0 18 0H2Zm0 2.002h16v13.012H9.287c-.375 0-.742.105-1.06.304l-2.743 1.716-.304-.762a2 2 0 0 0-1.857-1.258H2V2.002Z" />
    </>
  ),
  messages: (
    <>
      <path d="M6 2a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-.323l-1.193 2.982L15 16.054v-2.358l.516.322.304-.76A2 2 0 0 1 17.677 12H18V2H6Z" />
      <path d="M15 10h1V8h-1v2ZM16 6h-1V4h1v2ZM8 9V7H4v2h4ZM4 11h6v2H4v-2Z" />
      <path d="M2 3a2 2 0 0 0-2 2v10a2 2 0 0 0 2 2h.323l1.193 2.982L8.286 17H12a2 2 0 0 0 2-2V5a2 2 0 0 0-2-2H2Zm0 2h10v10H8.287a2 2 0 0 0-1.06.304l-2.743 1.714-.304-.76A2 2 0 0 0 2.323 15H2V5Z" />
    </>
  ),
  transform: (
    <path d="m17.084 17.059 1.585.424a1 1 0 0 1-.518 1.932l-4.83-1.294 1.295-4.83a1 1 0 0 1 1.932.518l-.327 1.222A8 8 0 0 0 9.308 2.03L8.594.098C9.054.033 9.523 0 10 0c5.523 0 10 4.477 10 10a9.969 9.969 0 0 1-2.916 7.059ZM3.772 4.979 3.447 6.19a1 1 0 0 0 1.932.517l1.294-4.83L1.843.586a1 1 0 1 0-.517 1.931l1.59.426A9.968 9.968 0 0 0 0 10c0 5.523 4.477 10 10 10 .482 0 .955-.034 1.419-.1l-.713-1.93A8 8 0 0 1 3.772 4.98Z" />
  ),
  save: (
    <path d="M14.172 0a2 2 0 0 1 1.414.586l3.828 3.828A2 2 0 0 1 20 5.828V18a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12.172ZM6 2h6v3H6V2ZM4 2H2v16h2v-8a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v8h2V6l-4-4v4a1 1 0 0 1-1 1H5a1 1 0 0 1-1-1V2Zm10 16v-7H6v7h8Z" />
  ),
  new: (
    <path d="M4 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.414A2 2 0 0 0 17.414 6L12 .586A2 2 0 0 0 10.586 0H4Zm6 2H4v16h12V8h-6V2Zm4.586 4L12 3.414V6h2.586Z" />
  ),
  statistic: (
    <path d="M12 0a1 1 0 0 0-1 1v18a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V1a1 1 0 0 0-1-1h-1ZM7 4a1 1 0 0 0-1 1v14a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V5a1 1 0 0 0-1-1H7ZM2 8a1 1 0 0 0-1 1v10a1 1 0 0 0 1 1h1a1 1 0 0 0 1-1V9a1 1 0 0 0-1-1H2ZM16 7a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v12a1 1 0 0 1-1 1h-1a1 1 0 0 1-1-1V7Z" />
  ),
  currency: <path d="M5 0h9a5 5 0 0 1 0 10H7v3h4v2H7v5H5v-5H1v-2h4v-3H1V8h4V0Zm9 2H7v6h7a3 3 0 1 0 0-6Z" />,
  calendar: (
    <>
      <path d="M5 12v-2h2v2H5ZM9 10h2v2H9v-2ZM13 12v-2h2v2h-2ZM13 16v-2h2v2h-2ZM9 14h2v2H9v-2ZM5 14h2v2H5v-2Z" />
      <path d="M4 0v2H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2V4a2 2 0 0 0-2-2h-1V0h-2v2H6V0H4ZM3 6V4h14v2H3Zm0 2h14v10H3V8Z" />
    </>
  ),
  like: (
    <path d="M8.573 1.08A2 2 0 0 1 10.348 0h.755a2 2 0 0 1 1.89 1.347l.202.586a2 2 0 0 1 .083.982l-.294 1.765L12.72 6h5.044a2 2 0 0 1 .894.211l.236.118A2 2 0 0 1 20 8.118v3.214a2 2 0 0 1-.11.653l-2.305 6.668A2 2 0 0 1 15.695 20H2a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h4.382l1.197-2.395.725-2.007.27-.519ZM12.72 8a2 2 0 0 1-1.961-2.392l.257-1.288.29-1.734L11.101 2h-.753l-.209.402-.72 1.993-1.25 2.5A2 2 0 0 1 6.383 8H6L5 18h10.695L18 11.332V8.118L17.764 8H12.72ZM3 18 4 8H2v10h1Z" />
  ),
  dislike: (
    <path d="M11.427 18.92A2 2 0 0 1 9.652 20h-.755a2 2 0 0 1-1.89-1.347l-.202-.586a2 2 0 0 1-.083-.982l.294-1.765L7.28 14H2.236a2 2 0 0 1-.894-.211l-.236-.118A2 2 0 0 1 0 11.882V8.668a2 2 0 0 1 .11-.653l2.305-6.668A2 2 0 0 1 4.305 0H18a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-4.382l-1.197 2.395-.725 2.007-.27.519ZM7.28 12a2 2 0 0 1 1.961 2.392l-.257 1.288-.29 1.734.204.586h.753l.209-.402.72-1.993 1.25-2.5A2 2 0 0 1 13.617 12H14l1-10H4.305L2 8.668v3.214l.236.118H7.28ZM17 2l-1 10h2V2h-1Z" />
  ),
  logo: (
    <path d="M20 10c0 5.523-4.477 10-10 10S0 15.523 0 10 4.477 0 10 0s10 4.477 10 10Zm-4.722 6.012A7.97 7.97 0 0 1 10 18a7.97 7.97 0 0 1-5.278-1.988 1 1 0 0 1 .466-.488l.063-.031a1 1 0 0 1 .204-.076l1.25-.312a3 3 0 0 0 1.394-.79l.158-.157a2.538 2.538 0 0 0 .475-2.93l-.74-1.478a1 1 0 0 1-.075-.205L7.59 8.243a1 1 0 0 1 0-.485l.245-.978a1 1 0 0 1 .263-.464l.379-.38a1 1 0 0 1 .26-.186l.38-.19a1 1 0 0 1 .348-.101l.434-.044a1 1 0 0 1 .2 0l.434.044a1 1 0 0 1 .348.1l.38.19a1 1 0 0 1 .26.188l.38.379a1 1 0 0 1 .262.464l.245.978a1 1 0 0 1 0 .485l-.326 1.302c-.018.07-.043.14-.076.205l-.74 1.478a2.538 2.538 0 0 0 .476 2.93l.158.158a3 3 0 0 0 1.394.789l1.25.312a1 1 0 0 1 .204.076l.063.031a1 1 0 0 1 .466.488Zm1.362-1.548a3 3 0 0 0-.934-.729l-.063-.03a2.997 2.997 0 0 0-.613-.228l-1.25-.313a1 1 0 0 1-.465-.262l-.157-.158a.538.538 0 0 1-.101-.621l.74-1.479c.097-.196.173-.402.226-.614l.326-1.302a3 3 0 0 0 0-1.455l-.245-.978a3 3 0 0 0-.789-1.394l-.378-.378a3.001 3.001 0 0 0-.78-.562l-.38-.19a2.999 2.999 0 0 0-1.043-.302l-.435-.044a3 3 0 0 0-.598 0l-.435.044a3 3 0 0 0-1.042.301l-.38.19a3 3 0 0 0-.78.563l-.38.378a3 3 0 0 0-.788 1.394l-.245.978a3 3 0 0 0 0 1.455l.326 1.302c.053.212.129.418.227.614l.74 1.479a.538.538 0 0 1-.102.62l-.157.159a1 1 0 0 1-.465.263l-1.25.312c-.212.053-.418.13-.613.227l-.063.031a3 3 0 0 0-.934.729 8 8 0 1 1 13.28 0Z" />
  ),
  chevronDown: (
    <path d="M18.46 4.558a1.798 1.798 0 0 0-2.603 0L10 10.615 4.143 4.558a1.798 1.798 0 0 0-2.604 0 1.95 1.95 0 0 0 0 2.692L10 16l8.462-8.75a1.95 1.95 0 0 0 0-2.692Z" />
  ),
  chevronUp: (
    <path d="M1.54 15.442a1.798 1.798 0 0 0 2.602 0L10 9.385l5.857 6.057a1.798 1.798 0 0 0 2.604 0 1.95 1.95 0 0 0 0-2.692L10 4l-8.462 8.75a1.95 1.95 0 0 0 0 2.692Z" />
  ),
  document: (
    <>
      <path d="M8 6H6v2h2V6ZM14 14v2H6v-2h8ZM6 12v-2h8v2H6Z" />
      <path d="M4 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.414A2 2 0 0 0 17.414 6L12 .586A2 2 0 0 0 10.586 0H4Zm6 2H4v16h12V8h-6V2Zm4.586 4L12 3.414V6h2.586Z" />
    </>
  ),
  documents: (
    <>
      <path d="M4 6h2v2H4V6ZM12 14v2H4v-2h8ZM4 10v2h8v-2H4Z" />
      <path d="M0 2a2 2 0 0 1 2-2h6.586A2 2 0 0 1 10 .586L15.414 6A2 2 0 0 1 16 7.414V18a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2Zm8 0H2v16h12V8H8V2Zm2 1.414V6h2.586L10 3.414Z" />
      <path d="M18 20h-3a2 2 0 0 0 2-2h1V8h-1v-.586A2 2 0 0 0 16.414 6h.172L14 3.414v.172l-3-3A2 2 0 0 0 9.586 0h3A2 2 0 0 1 14 .586L19.414 6A2 2 0 0 1 20 7.414V18a2 2 0 0 1-2 2Z" />
    </>
  ),
  filter: (
    <path d="M.615 3.414C-.699 2.154.232 0 2.09 0h15.82c1.858 0 2.789 2.154 1.475 3.414l-7.3 7V18c0 1.105-.933 2-2.085 2s-2.086-.895-2.086-2v-7.586l-7.299-7ZM17.91 2H2.09l7.3 7c.39.375.61.884.61 1.414 0-.53.22-1.039.61-1.414l7.3-7Z" />
  ),
  connection: (
    <path d="M10 0a4 4 0 0 1 1 7.874V9h4a2 2 0 0 1 2 2v1.126A4.002 4.002 0 0 1 16 20a4 4 0 0 1-1-7.874V11H5v1h1a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-4a2 2 0 0 1 2-2h1v-1a2 2 0 0 1 2-2h4V7.874A4.002 4.002 0 0 1 10 0Zm0 2a2 2 0 1 1 0 4 2 2 0 0 1 0-4ZM6 18H2v-4h4v4Zm10-4a2 2 0 1 1 0 4 2 2 0 0 1 0-4Z" />
  ),
  user: (
    <path d="M10 10a4 4 0 1 0 0-8 4 4 0 0 0 0 8Zm0-2a2 2 0 1 0 0-4 2 2 0 0 0 0 4ZM2 15v2a1 1 0 0 0 1 1h14a1 1 0 0 0 1-1v-2c0-2.21-3.582-4-8-4s-8 1.79-8 4Zm2 0v1h12v-1a.211.211 0 0 0-.014-.049.853.853 0 0 0-.142-.216c-.182-.216-.524-.496-1.082-.775C13.647 13.403 11.966 13 10 13c-1.966 0-3.647.403-4.762.96-.558.279-.9.56-1.082.775a.852.852 0 0 0-.142.216A.215.215 0 0 0 4 15Z" />
  ),
  group: (
    <>
      <path d="M12 6a4 4 0 1 1-8 0 4 4 0 0 1 8 0Zm-2 0a2 2 0 1 1-4 0 2 2 0 0 1 4 0ZM0 17v-2c0-2.21 3.582-4 8-4s8 1.79 8 4v2a1 1 0 0 1-1 1H1a1 1 0 0 1-1-1Zm2-1v-1l.001-.01a.215.215 0 0 1 .013-.039.852.852 0 0 1 .142-.216c.182-.216.524-.496 1.082-.775C4.353 13.403 6.034 13 8 13c1.966 0 3.647.403 4.762.96.558.279.9.56 1.082.775a.853.853 0 0 1 .143.216A.211.211 0 0 1 14 15V16H2Z" />
      <path d="M10.5 9.71a4.015 4.015 0 0 0 2-1.773 2 2 0 0 0 0-3.874 4.015 4.015 0 0 0-2-1.772 4 4 0 1 1 0 7.418ZM19 18h-3a1 1 0 0 0 1-1v-1h1v-1.001l-.001-.01a.211.211 0 0 0-.012-.038.853.853 0 0 0-.143-.216c-.182-.216-.524-.496-1.082-.775l-.048-.023c-.801-1.458-3.21-2.582-6.214-2.867.486-.046.987-.07 1.5-.07 4.418 0 8 1.79 8 4v2a1 1 0 0 1-1 1Z" />
    </>
  ),
  award: (
    <>
      <path d="M11.123 6.455 10 3 8.877 6.455H5.245L8.184 8.59 7.06 12.045 10 9.91l2.939 2.135-1.123-3.455 2.94-2.135h-3.633Z" />
      <path d="M18 8a7.97 7.97 0 0 1-2 5.292v6.428l-6-1.2-6 1.2v-6.428A8 8 0 1 1 18 8Zm-8 6a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 2c1.457 0 2.823-.39 4-1.07v2.35l-4-.8-4 .8v-2.35A7.963 7.963 0 0 0 10 16Z" />
    </>
  ),
  list: (
    <>
      <path d="M5 7h2v2H5V7ZM5 10h2v2H5v-2ZM5 13h2v2H5v-2ZM8 7h7v2H8V7ZM8 10h7v2H8v-2ZM8 13h7v2H8v-2Z" />
      <path d="M4 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V7.414A2 2 0 0 0 17.414 6L12 .586A2 2 0 0 0 10.586 0H4Zm0 2h6.586L16 7.414V18H4V2Z" />
    </>
  ),
  protect: (
    <>
      <path d="M11.855 5.748 10 4.9l-1.855.848L6 6.402V9.03l.21 1.794c.02.168.067.331.138.484l.579 1.235c.045.097.1.19.165.275l.746.995c.138.184.315.335.518.443l1.644.877 1.644-.877c.203-.108.38-.26.518-.443l.746-.995c.064-.086.12-.178.165-.275l.579-1.235c.072-.153.118-.316.138-.484L14 9.03V6.402l-2.145-.654Zm-3 1.875L10 7.099l1.145.524.855.26v1.03l-.189 1.61-.53 1.13-.662.883-.619.33-.619-.33-.662-.882-.53-1.132L8 8.913v-1.03l.855-.26Z" />
      <path d="M13.836 2.056 10 .412 6.164 2.056 2 3.246v4.816l.486 3.892a2 2 0 0 0 .196.646l1.376 2.752a2 2 0 0 0 .227.355l1.797 2.246a2 2 0 0 0 .667.54l2.357 1.178a2 2 0 0 0 1.788 0l2.357-1.178c.259-.13.486-.314.667-.54l1.797-2.245a2 2 0 0 0 .227-.356l1.376-2.752a2 2 0 0 0 .195-.646L18 8.062V3.246l-4.164-1.19Zm-7 1.888L10 2.588l3.164 1.356 2.836.81v3.184l-.471 3.768-1.376 2.752-1.797 2.246L10 17.882l-2.356-1.178-1.797-2.246-1.376-2.752L4 7.938V4.754l2.836-.81Z" />
    </>
  ),
  badge: (
    <>
      <path d="M17 4.5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0ZM4.5 11l6-6 1.414 1.414-6 6L4.5 11ZM7.586 14.086l6-6L15 9.5l-6 6-1.414-1.414Z" />
      <path d="M18.5 0H9.934L.874 9.06a3 3 0 0 0 0 4.242l5.824 5.824a3 3 0 0 0 4.243 0L20 10.066V1.5l-.5-1-1-.5ZM3.076 9.702l7.688-7.689h7.223v7.218l-7.69 7.69a2 2 0 0 1-2.83 0L3.077 12.53a2 2 0 0 1 0-2.828Z" />
    </>
  ),
  table: (
    <path d="M0 0h20v20H0V0Zm13 7h5v2h-5V7ZM2 7v2h5V7H2Zm10 0H8v2h4V7Zm0 3H8v2h4v-2Zm1 2v-2h5v2h-5Zm-1 1H8v2h4v-2Zm1 2v-2h5v2h-5Zm-1 1H8v2h4v-2Zm1 2h5v-2h-5v2ZM2 10h5v2H2v-2Zm0 3h5v2H2v-2Zm0 3h5v2H2v-2ZM18 2H2v3h16V2Z" />
  ),
  min: (
    <path d="M4.062 10.85h.1l1.466-3.8 1.386.04.04 4.3.19 2.58H5.89l.05-2.6-.11-2.63-1.205 3.31-1.234.06-1.125-3.37-.09 2.63.07 2.6H1l.14-2.43.06-4.29L2.798 7l1.265 3.85ZM11.08 7.02l-.14 4.35.12 2.6H9.656l.12-2.43-.12-4.27 1.426-.25ZM19 7.05l-.13 4.32.11 2.38-1.185.25-2.992-4.82v2.19l.14 2.6H13.69l.12-2.43-.12-4.44 1.205-.06 2.981 4.89-.1-4.73L19 7.05Z" />
  ),
  max: (
    <path d="M2.89 10.825h.095L4.368 7.08l1.308.04.038 4.239.18 2.543h-1.28l.048-2.563-.104-2.593L3.42 12.01l-1.166.059-1.061-3.323-.085 2.593.066 2.563H0l.133-2.395.057-4.23 1.506-.246 1.194 3.795ZM11.997 13.95l-.464-1.922H9.268l-.502 1.873-1.213-.098.834-2.396 1.27-4.24 1.696-.128 1.222 4.22.787 2.475-1.365.217ZM10.5 8.322h-.123l-.815 2.662h1.686L10.5 8.321ZM18.834 13.99l-1.582-2.553L15.726 14l-1.023-.523 1.867-2.957-2.01-2.682 1.176-.799 1.526 2.465L18.73 7l.995.611-1.734 2.81 2.009 2.8-1.166.77Z" />
  ),
  arrowUp: (
    <path d="M7.136 7.707a1 1 0 0 1-1.414 0l-.015-.014a1 1 0 0 1 0-1.415L10 1.985l4.293 4.293a1 1 0 0 1 0 1.415l-.014.014a1 1 0 0 1-1.415 0L11 5.843V17a1 1 0 0 1-2 0V5.843L7.136 7.707Z" />
  ),
  arrowRight: (
    <path d="M12.293 7.132a1 1 0 0 1 0-1.415l.014-.014a1 1 0 0 1 1.415 0l4.293 4.293-4.293 4.293a1 1 0 0 1-1.415 0l-.014-.014a1 1 0 0 1 0-1.415l1.86-1.86H3a1 1 0 1 1 0-2h11.162l-1.869-1.868Z" />
  ),
  arrowDown: (
    <path d="M10 2a1 1 0 0 0-1 1v11.143l-1.864-1.864a1 1 0 0 0-1.414 0l-.015.014a1 1 0 0 0 0 1.414L10 18l4.293-4.293a1 1 0 0 0 0-1.414l-.014-.014a1 1 0 0 0-1.415 0L11 14.143V3a1 1 0 0 0-1-1Z" />
  ),
  arrowLeft: (
    <path d="M7.722 12.86a1 1 0 0 1 0 1.415l-.015.014a1 1 0 0 1-1.414 0L2 9.996l4.293-4.293a1 1 0 0 1 1.414 0l.015.014a1 1 0 0 1 0 1.415L5.853 9H17a1 1 0 1 1 0 2H5.861l1.86 1.86Z" />
  ),
  erase: (
    <>
      <path d="m12.829 15.173 5.757-5.757a2 2 0 0 0 0-2.829l-4.172-4.171a2 2 0 0 0-2.828 0L5.83 8.172l-.002-.002-4.414 4.414a2 2 0 0 0 0 2.829L5 18.997h4l3.827-3.827.002.002Zm.21-11.41L7.23 9.572l4.2 4.2 5.809-5.809-4.2-4.2ZM9.971 15.227 8.2 16.998H5.915l-2.35-2.35a1 1 0 0 1 0-1.414l2.206-2.207 4.2 4.2Z" />
      <path d="M12 17a1 1 0 1 0 0 2h6a1 1 0 1 0 0-2h-6Z" />
    </>
  ),
  merge: (
    <path d="M7.14 6.707a1 1 0 0 1-1.414 0l-.015-.014a1 1 0 0 1 0-1.415L10.004.985l4.293 4.293a1 1 0 0 1 0 1.415l-.014.014a1 1 0 0 1-1.415 0L11 4.838V11a8 8 0 0 1-8 8H1v-2h2a6 6 0 0 0 6-6V4.847l-1.86 1.86ZM17 19a7.99 7.99 0 0 1-6.5-3.335 7.977 7.977 0 0 0 1.078-2.093A6 6 0 0 0 17 17h2v2h-2Z" />
  ),
  activate: (
    <>
      <path d="M10 0a1 1 0 0 0-1 1v5a1 1 0 0 0 2 0V1a1 1 0 0 0-1-1Z" />
      <path d="M6.252 2.903c.481-.22 1.026.055 1.195.555.17.502-.103 1.042-.577 1.279a7 7 0 1 0 6.26 0c-.474-.237-.747-.777-.577-1.279.17-.5.714-.774 1.195-.555C16.848 4.317 19 7.43 19 11.046 19 15.991 14.97 20 10 20s-9-4.009-9-8.954c0-3.615 2.153-6.729 5.252-8.143Z" />
    </>
  ),
  library: (
    <>
      <path d="M9 11V5H5v6l2-2 2 2Z" />
      <path d="M0 3a3 3 0 0 1 3-3h17c-.598.692-1 2.52-1 4s.402 3.308 1 4h-2.732A7.268 7.268 0 0 0 17 10c0 .729.097 1.412.268 2H20c-.598.692-1 2.52-1 4s.402 3.308 1 4H3a3 3 0 0 1-3-3v-2a3 3 0 0 1 1.577-2.642C1.214 11.698 1 10.883 1 10c0-.882.214-1.697.577-2.358A3 3 0 0 1 0 5V3Zm15.335 9A5.92 5.92 0 0 1 15 10c0-.729.122-1.412.335-2H10V6h7.268A7.268 7.268 0 0 1 17 4c0-.729.097-1.412.268-2H3.5A1.5 1.5 0 0 0 2 3.5v1A1.5 1.5 0 0 0 3.5 6H4v2.085A1.5 1.5 0 0 0 3 9.5v1A1.5 1.5 0 0 0 4.5 12h10.835ZM3.5 14h13.768A7.268 7.268 0 0 0 17 16c0 .729.097 1.412.268 2H3.5A1.5 1.5 0 0 1 2 16.5v-1A1.5 1.5 0 0 1 3.5 14Z" />
    </>
  ),
  lang: (
    <>
      <path d="M18.556 12.8a.723.723 0 0 0-.723.722V16l-2.666-2.625a2 2 0 0 0-1.216-.566 6.609 6.609 0 0 0-.06-2.007 4 4 0 0 1 2.54 1.017A2.721 2.721 0 0 1 18 10.857V2H9v3.398a7.933 7.933 0 0 0-2-.383V2a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v9.356c0 .797-.647 1.444-1.444 1.444Z" />
      <path d="M9.129 15 6.992 9.312H6L3.875 15h1.137l.425-1.266h2.124L7.988 15h1.14Zm-1.854-2.113H5.722l.774-2.305.779 2.305Z" />
      <path d="m1.04 20 2.334-1.734a1 1 0 0 1 .597-.197H7c3.314 0 6-2.72 6-6.034C13 8.72 10.314 6 7 6H6a6 6 0 0 0-6 6v.487a6 6 0 0 0 .49 2.374l.387.898a2 2 0 0 1 .163.791V20ZM2 12v.487a4 4 0 0 0 .326 1.582l.388.899a4 4 0 0 1 .312 1.253c.303-.1.622-.152.945-.152H7c2.194 0 4-1.81 4-4.034C11 9.81 9.194 8 7 8H6a4 4 0 0 0-4 4Z" />
      <path d="M12.872 4.36H9.948v.992h2.763a5.227 5.227 0 0 1-.721 1.738c.255.26.49.54.703.836a5.867 5.867 0 0 0 .826-1.64c.626 1.553 1.555 2.732 3.005 3.41.16-.28.48-.696.72-.912-1.51-.598-2.48-1.868-3.014-3.432h2.838V4.36h-3.16c.073-.72.084-1.426.095-2.069l.001-.083h-1.056l-.003.32c-.004.575-.008 1.2-.073 1.832Z" />
    </>
  ),
  report: (
    <>
      <path d="m13.099 1.94.432.18a7 7 0 0 1 4.303 6.694l-.015.468 1.999.066.015-.467A9 9 0 0 0 14.3.275l-.432-.18-.77 1.846Z" />
      <path d="m4.656 15.908 1.8 2.8a2 2 0 0 0 2.764.601l.467-.3a2 2 0 0 0 .602-2.764l-1.698-2.64.718-.449 5.196-.207c.52.1 1.073.014 1.546-.281a2.008 2.008 0 0 0 .592-2.852l-5.296-7.842-.01-.013c-.65-.964-1.972-1.25-2.95-.64a2 2 0 0 0-.862 1.117L5.131 7.079l-3.747 2.34a2.714 2.714 0 0 0-.083.05c-1.468.916-1.733 3.026-.594 4.713.952 1.409 2.583 2.077 3.949 1.726Zm1.767-.95 1.716 2.668.467-.3-1.711-2.662-.472.294ZM9.566 3.067l5.306 7.855-2.459.087-3.93-5.818 1.083-2.124Zm-1.017 8.08 1.405-.05L7.4 7.315l-.62 1.213-4.3 2.686c-.49.305-.49 1.139-.002 1.862.489.723 1.281 1.061 1.77.756l.007-.004 4.294-2.681Z" />
    </>
  ),
  transport: (
    <path d="M12.293 1.721a1 1 0 0 1 0-1.414l.014-.014a1 1 0 0 1 1.415 0l4.293 4.293-4.293 4.293a1 1 0 0 1-1.415 0l-.014-.015a1 1 0 0 1 0-1.414l1.86-1.86H3a1 1 0 0 1 0-2h11.162l-1.869-1.869ZM17 14.42a1 1 0 1 1 0 2H5.861l1.86 1.86a1 1 0 0 1 0 1.415l-.014.014a1 1 0 0 1-1.414 0L2 15.416l4.293-4.293a1 1 0 0 1 1.414 0l.015.014a1 1 0 0 1 0 1.415L5.853 14.42H17Z" />
  ),
  toForward: (
    <>
      <path d="m11.759 10-9.272 9.56a1.426 1.426 0 0 1-2.06 0 1.535 1.535 0 0 1 0-2.124l7.211-7.435L.427 2.565a1.535 1.535 0 0 1 0-2.124 1.426 1.426 0 0 1 2.06 0l9.272 9.56Z" />
      <path d="m20 10-9.271 9.56a1.426 1.426 0 0 1-2.06 0 1.535 1.535 0 0 1 0-2.124l7.21-7.435-7.21-7.436a1.535 1.535 0 0 1 0-2.124 1.426 1.426 0 0 1 2.06 0L20 10.001Z" />
    </>
  ),
  toBack: (
    <>
      <path d="m0 10 9.272 9.56a1.426 1.426 0 0 0 2.06 0 1.536 1.536 0 0 0 0-2.124l-7.211-7.435 7.21-7.436a1.535 1.535 0 0 0 0-2.124 1.426 1.426 0 0 0-2.06 0l-9.27 9.56Z" />
      <path d="m8.241 10 9.272 9.56a1.426 1.426 0 0 0 2.06 0 1.536 1.536 0 0 0 0-2.124l-7.211-7.435 7.211-7.436a1.535 1.535 0 0 0 0-2.124 1.426 1.426 0 0 0-2.06 0l-9.272 9.56Z" />
    </>
  ),
  relation: (
    <path d="M15 6a3 3 0 1 0-2.965-2.54L6.468 5.853a4 4 0 1 0 .3 6.037l3.449 1.651a5 5 0 1 0 .957-1.759l-3.35-1.604a4 4 0 0 0-.116-2.681l5.282-2.269c.532.48 1.237.773 2.01.773Z" />
  ),
  excel: (
    <>
      <path d="M6 0a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6.5c0-.53-.125-1.125-.5-1.5L15 .5c-.375-.375-.97-.5-1.5-.5H6Zm7 2H6v16h12V7h-5V2Zm4 3.5L14.5 3v2.5H17Z" />
      <path fill="#fff" d="M0 4h12v12H0z" />
      <path
        fill="#08733B"
        d="m4.926 9.95-2.1 3.55h1.82l1.12-2 .23-.43c.047.08.09.16.13.24.04.073.077.137.11.19l1.12 2h1.82l-2.1-3.54 1.91-3.23h-1.8l-.96 1.72c-.033.053-.07.117-.11.19a1.789 1.789 0 0 1-.12.22 9.242 9.242 0 0 1-.13-.23 1.763 1.763 0 0 0-.09-.19l-.95-1.71h-1.8l1.9 3.22Z"
      />
      <path fill="#08733B" d="M0 4h12v12H0V4Zm1.5 1.5h9v9h-9v-9Z" />
    </>
  ),
  magazine: (
    <>
      <path d="M14 4H6v2h8V4ZM6 9h8v2H6V9ZM14 14H6v2h8v-2Z" />
      <path d="M2 2a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v16a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V2Zm2 0h12v16H4V2Z" />
    </>
  ),
};

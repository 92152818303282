import React from 'react';

import { Checkbox, FormComponent, TextArea, TextAreaMode } from 'components';

import { UserActivationKind } from 'types/models/UserActivation';
import { GetRoleList } from 'features/Table/specifications';
import { useController } from './controller';
import { Table } from 'types/models';

type Props = {
  item: UserActivationKind | null;
  setItem(item: UserActivationKind | null): void;
};

export const Fields = ({ item, setItem }: Props) => {
  const { handleRoleChange, makeHandler, tableRowConverter } = useController({ item, setItem });

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Автоактивация">
          <Checkbox checked={item?.activate === 'true'} onChange={val => makeHandler('activate')(val ? 'true' : 'false')} />
        </FormComponent.Field>
        <FormComponent.Field label="Назначить роль">
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Роль',
              table: {
                specification: GetRoleList({ hasSelectButton: true }),
                onSelect: (row: Table.Entry | null) => handleRoleChange(row ? tableRowConverter(row) : null),
              },
            }}
            value={item?.roles[0]?.name}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};

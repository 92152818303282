import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, FormComponent, Modal, TextDateTime, TextInput, Toolbar } from 'components';

import { Community, Table } from 'types/models';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { useAppDataContext } from 'features/AppData/context';
import { CommunityType } from 'utils/Enums';
import { CommunityListGridHelp } from './help';

export type CustomProps = {
  communityTypes: CommunityType[];
  isApproved?: boolean;
  isMembersTabHidden?: boolean;
  community?: Community;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const { communityTypes, isMembersTabHidden } = customState;

  const { settings } = useAppDataContext();

  const {
    buttons,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    setIsApproveConfirmPopupOpen,
    handleConfirmApproveConfirmPopup,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isDissertationCouncil,
    isCloneOpen,
    setIsCloneOpen,
    cloneInfo,
    setCloneInfo,
    cloneCommunity,
    community,
    clearClone,
  } = useController(tableState, communityTypes);

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!isViewFormOpen && (
        <modalTemplates.CommunityViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.label}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
        />
      )}

      {!!isEditFormOpen && (
        <modalTemplates.CommunityEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => {
            setIsEditFormOpen(false);
            clearClone();
          }}
          id={tableState.selectedRows[0]?.id}
          name={tableState.selectedRows[0]?.label}
          relatedTableState={tableState}
          templateName={templateName}
          communityTypes={communityTypes}
          isApproved={isApproved}
          isMembersTabHidden={isMembersTabHidden}
          community={community}
        />
      )}

      <modalTemplates.CommunityAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        relatedTableState={tableState}
        name={nextPublicationName}
        templateName={templateName}
        communityTypes={communityTypes}
        isMembersTabHidden={isMembersTabHidden}
      />

      {!!isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.label}) Удалить сообщество?</>
        </Modal>
      )}

      {!!isApproveConfirmPopupOpen && (
        <Modal
          mode="warning"
          title={isApproved ? 'Перевод в статус "Добавлено"' : 'Утверждение'}
          isOpen={isApproveConfirmPopupOpen}
          onClose={() => setIsApproveConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmApproveConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsApproveConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>
            {isApproved
              ? `(${tableState.selectedRows[0]?.label}) Перевести в статус "Добавлена"?`
              : `(${tableState.selectedRows[0]?.label}) Утвердить?`}
          </>
        </Modal>
      )}

      <Modal
        mode="help"
        title={`Помощь в ${isDissertationCouncil ? 'Диссертационных советах' : 'Научных сообществах и организациях'}`}
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {CommunityListGridHelp(isDissertationCouncil, settings?.organization?.shortName)}
      </Modal>

      <Modal
        title="Создать копию сообщества на следующий период"
        isOpen={isCloneOpen}
        onClose={() => {
          setIsCloneOpen(false);
          clearClone();
        }}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Создать запись',
            onClick: cloneCommunity,
            isDisabled: !cloneInfo.prototype?.id || !cloneInfo.orderDate,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => {
              setIsCloneOpen(false);
              clearClone();
            },
          },
        ]}
        size="medium"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Сообщество">{cloneInfo.prototype?.value}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Номер приказа">
            <TextInput value={cloneInfo.orderNumber} onChange={e => setCloneInfo(prev => ({ ...prev, orderNumber: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата приказа" isRequired>
            <TextDateTime value={cloneInfo.orderDate} onChange={e => setCloneInfo(prev => ({ ...prev, orderDate: e }))} />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

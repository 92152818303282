import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput } from 'components';

import { CitationSystem } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  citationSystem: CitationSystem;
  setCitationSystem(citationSystem: CitationSystem): void;
};

export function Fields(props: Props) {
  const { citationSystem, setCitationSystem } = props;

  const makeHandler = (key: keyof CitationSystem) => (value: ValueOf<CitationSystem>) => {
    setCitationSystem({ ...citationSystem, [key]: value });
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="База цитирования" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={citationSystem.refCitationSystem}
            onChange={(option: ReferenceItem | null) => makeHandler('refCitationSystem')(option)}
            settings={{ name: 'RefCitationSystem', title: 'Справочник "Базы цитирования"' }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Идентификатор" isRequired>
          <TextInput value={citationSystem.identifier} onChange={makeHandler('identifier')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Название в БЦ">
          <TextInput value={citationSystem.name} onChange={makeHandler('name')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="URL">
          <TextInput value={citationSystem.url} onChange={makeHandler('url')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { SecurityDocumentType } from 'utils/Enums';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Entry } from 'types/models/Table';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

export function useController({ tableState }: Props) {
  const { userPermission } = useAppDataContext();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Entry | null = tableState.selectedRows[0] || null;

  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);

  const reports = useMemo<Report[]>(
    () => [
      Reports.SecurityDocumentsIndicators,
      Reports.ResultsSTA,
      Reports.UsingSecurityDocuments,
      Reports.InventoryScienceProtection,
      Reports.InventoryScienceCommercialization,
      Reports.ContractIntellectualProperty,
      Reports.KnowHowList,
      Reports.IntPropTgu,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const selectedSecurityDocumentType = useMemo<SecurityDocumentType | null>(
    () => (selectedRow?.['id:SecurityDocumentType'] as SecurityDocumentType) || null,
    [selectedRow],
  );

  const viewPermits = useMemo<Record<SecurityDocumentType, string>>(() => {
    return {
      [SecurityDocumentType.PATENT]: Permits.PATENT_VIEW,
      [SecurityDocumentType.EVIDENCE]: Permits.EVIDENCE_VIEW,
      [SecurityDocumentType.KNOW_HOW]: Permits.KNOW_HOW_VIEW,
      [SecurityDocumentType.NON_REGISTERED]: Permits.NON_REGISTERED_VIEW,
    };
  }, []);

  const isViewPermitted = useMemo(
    () => !!selectedSecurityDocumentType && isHasPermission(userPermission, viewPermits[selectedSecurityDocumentType]),
    [selectedSecurityDocumentType, userPermission, viewPermits],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpModal(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: selectedRows.length !== 1 || !isViewPermitted,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [selectedRows.length, isViewPermitted, getReports, handleSetCurrentReport],
  );

  return {
    isOpenHelpModal,
    setIsOpenHelpModal,
    isViewFormOpen,
    setIsViewFormOpen,
    buttons,
    selectedRow,
    selectedSecurityDocumentType,
    isReportOpen,
    onReportClose,
    currentReport,
  };
}

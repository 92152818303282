import { Table } from 'types/models';
import { ProjectCode } from 'types/models/Project';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { IdItem } from 'types/models/common';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  projectCode?: ProjectCode | null;
  // is used for passing projectCodeId value for request, if projectCode is null
  // projectCode.id cant be used because specification requires "null" value to work correctly,
  // so this prop cant be initialised by getMock function just for pass
  projectCodeId?: string;
  hideTitle?: boolean;
  isDisabled?: boolean;
  fundCard?: IdItem;
};

type Arguments = {
  projectCodeId?: string | null;
};

export function GetEstimateList(args?: Props): Table.Specification<State, Arguments> {
  return {
    apiID: 'GetEstimateList',
    isFiltersHidden: true,
    requestData: { projectCodeId: args?.projectCode?.id || args?.projectCodeId },
    header: {
      firstLevel: {
        title: args?.hideTitle ? undefined : 'Сметы',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel({
          projectCode: args?.projectCode,
          isDisabled: args?.isDisabled,
        }),
      },
    },
    useCustomController: makeUseCustomController({ fundCard: args?.fundCard }),
  };
}

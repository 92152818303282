import React from 'react';

import { ListEdit, Modal } from 'components';

import { showErrors } from 'utils/Validators';
import { useAppDataContext } from 'features/AppData/context';
import { Change } from 'types/models/Partner';
import { Fields } from './Fields';
import { getMockChange } from 'features/EditMipPartner/helpers';
import { validate } from './validate';
import { useController } from './controller';
import { MipDocumentChangesHelp } from './help';

type Props = {
  changes: Change[];
  setChanges(changes: Change[]): void;
  disabled: boolean;
};

export function Changes(props: Props) {
  const { changes, setChanges, disabled } = props;

  const { currentPerson } = useAppDataContext();

  const userName = currentPerson?.fullName ?? '';
  const { isHelpFormOpen, setIsHelpFormOpen, extraToolbarButtons } = useController();

  return (
    <>
      <ListEdit
        rows={changes}
        onChange={setChanges}
        toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
        columns={[
          { label: 'Категория изменения', formatValue: x => x.category?.label ?? '' },
          { label: 'Дата изменения', formatValue: x => x.changeDate },
          { label: 'Описание изменения', formatValue: x => x.description },
          { label: 'Создано', formatValue: x => `${x.createdBy} ${x.createdDate}` },
          { label: 'Изменено', formatValue: x => `${x.modifiedBy} ${x.modifiedDate}` },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        maxHeight="none"
        defaultRowsCount={15}
        specification={{
          mode: 'customComponent',
          renderComponent: (change, setChange) => (
            <Fields userName={userName} change={change || getMockChange(userName)} setChange={setChange} />
          ),
          validation: {
            checkIsValid: change => validate(change).every(x => x.isValid),
            onInvalidate: val => showErrors(validate, val),
          },
        }}
      />

      <Modal mode="help" title="Журнал изменений" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="medium">
        {MipDocumentChangesHelp()}
      </Modal>
    </>
  );
}

import { EmployeeNominee } from 'types/models/Payment';

export function validate(item: EmployeeNominee | null, commonAmount: number) {
  if (!item) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const isValidPerson = item.employee !== null;
  const isValidAppoinment = item.job !== null;
  const isHasAmount = Boolean(item.amountMoney);
  const isValidAmount = Number(item.amountMoney ?? 0) <= commonAmount;

  return [
    { isValid: isValidPerson, invalidMessage: 'Выберите персону' },
    { isValid: isValidAppoinment, invalidMessage: 'Выберите должность' },
    { isValid: isHasAmount, invalidMessage: 'Введите сумму' },
    { isValid: isValidAmount, invalidMessage: 'Сумма оплаты сотруднику больше суммы фонда' },
  ];
}

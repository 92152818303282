import { ReferenceItem } from 'components';
import { useAppDataContext } from 'features/AppData/context';
import { useMemo, useCallback, useEffect } from 'react';
import { Event } from 'types/models';

type Props = {
  document: Event.MemberCountAnother | null;
  onChange(row: Event.MemberCountAnother): Promise<void>;
  actualRef: string;
  isOpenedCollapsible: boolean;
};
const useController = ({ document, onChange, actualRef, isOpenedCollapsible }: Props) => {
  const EMPTY_REFERENCE = { label: '', id: '' };

  const { initialCountryCode, initialOrganizationCode, settings } = useAppDataContext();

  const emptyRow: Event.MemberCountAnother = useMemo(
    () => ({
      id: '',
      amount: '0',
      students: '0',
      professors: '0',
      country: EMPTY_REFERENCE,
      city: '',
      enterprise: EMPTY_REFERENCE,
      department: EMPTY_REFERENCE,
    }),
    // eslint-disable-next-line
    [],
  );
  const handleAllField = useCallback(
    (nextAmount: string) => {
      onChange({ ...(document || emptyRow), amount: nextAmount });
    },
    [onChange, document, emptyRow],
  );
  const handleProfessorsField = useCallback(
    (nextAmount: string) => {
      onChange({ ...(document || emptyRow), professors: nextAmount });
    },
    [onChange, document, emptyRow],
  );
  const handleStudentsField = useCallback(
    (nextAmount: string) => {
      onChange({ ...(document || emptyRow), students: nextAmount });
    },
    [onChange, document, emptyRow],
  );
  const handleCityField = useCallback(
    (value: string) => {
      onChange({ ...(document || emptyRow), city: value });
    },
    [onChange, document, emptyRow],
  );
  const onChangeOrganization = useCallback(
    (value: ReferenceItem) => {
      onChange({ ...(document || emptyRow), enterprise: value });
    },
    [onChange, document, emptyRow],
  );
  const onChangeCountry = useCallback(
    (value: ReferenceItem) => {
      onChange({ ...(document || emptyRow), country: value });
    },
    [onChange, document, emptyRow],
  );
  const onChangeDepartment = useCallback(
    (value: ReferenceItem) => {
      onChange({ ...(document || emptyRow), department: value });
    },
    [onChange, document, emptyRow],
  );
  useEffect(() => {
    if (!document) {
      if (actualRef === 'DEPARTMENT') {
        onChange({
          ...emptyRow,
          country: initialCountryCode,
          city: settings?.organization?.city || '',
          enterprise: initialOrganizationCode,
        });
      } else if (actualRef === 'LOCAL') {
        onChange({
          ...emptyRow,
          country: initialCountryCode,
          city: settings?.organization?.city || '',
        });
      } else if (actualRef === 'NONRESIDENT') {
        onChange({ ...emptyRow, country: initialCountryCode });
      } else {
        onChange({ ...emptyRow });
      }
    }
    // eslint-disable-next-line
  }, [actualRef, isOpenedCollapsible]);
  return {
    handleAllField,
    handleProfessorsField,
    handleStudentsField,
    handleCityField,
    onChangeDepartment,
    onChangeOrganization,
    onChangeCountry,
  };
};

export default useController;

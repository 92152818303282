import React from 'react';

import { FiltersComponent, FilterComponentType, FilterComponent } from 'features/Table/FirstLevelHeader/Filters';
import { State } from './model';

type Props = {
  customState: State;
};

export const Filters = React.memo(({ customState }: Props) => {
  const { isFacultyFilter, isFacultyFilterChecked } = customState;

  return FiltersComponent([
    {
      type: FilterComponentType.radio,
      streamKey: 'restricted',
      list: [
        { label: 'В избранном', value: 'isFavorite' },
        { label: 'Актуальные', value: 'isActual' },
        { label: 'Все', value: 'All' },
      ],
      value: 'All',
    },
    ...(isFacultyFilter
      ? [
          {
            type: FilterComponentType.checkbox,
            streamKey: 'level',
            label: 'Факультет / Институт',
            checked: !!isFacultyFilterChecked,
          } as FilterComponent,
        ]
      : []),
  ]);
});

import React from 'react';

import { FormComponent, Select, SelectMode, TextInput, TextInputMode } from 'components';

import { Event } from 'types/models';
import useController from './controller';

type Props = {
  document: Event.MemberCountAnother | null;
  onChange(row: Event.MemberCountAnother): Promise<void>;
  actualRef: string;
  isOpenedCollapsible: boolean;
  hasShortForm?: boolean;
};
const MemberCountAnotherForm = ({ document, onChange, actualRef, isOpenedCollapsible, hasShortForm }: Props) => {
  const {
    handleAllField,
    handleProfessorsField,
    handleStudentsField,
    handleCityField,
    onChangeDepartment,
    onChangeOrganization,
    onChangeCountry,
  } = useController({
    onChange,
    document,
    actualRef,
    isOpenedCollapsible,
  });

  return (
    <FormComponent.Wrapper>
      <>
        {document && (
          <>
            <FormComponent.Line>
              <FormComponent.Field isRequired label="Всего участников">
                <TextInput
                  mode={TextInputMode.NUMBER}
                  settings={{ decimal: false }}
                  value={document.amount}
                  onChange={handleAllField}
                />
              </FormComponent.Field>
              <>
                {!hasShortForm && (
                  <FormComponent.Field isRequired labelSize="fit" label="В том числе, профессоров">
                    <TextInput
                      mode={TextInputMode.NUMBER}
                      settings={{ decimal: false }}
                      value={document.professors}
                      onChange={handleProfessorsField}
                    />
                  </FormComponent.Field>
                )}
              </>
              <>
                {!hasShortForm && (
                  <FormComponent.Field isRequired labelSize="fit" label="В том числе, студентов">
                    <TextInput
                      mode={TextInputMode.NUMBER}
                      settings={{ decimal: false }}
                      value={document.students}
                      onChange={handleStudentsField}
                    />
                  </FormComponent.Field>
                )}
              </>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Страна" isRequired>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={document.country}
                  onChange={onChangeCountry}
                  settings={{ name: 'RefCountry', title: 'Справочник "Страны"' }}
                  isDisabled={actualRef !== 'FOREIGN'}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Город" isRequired>
                <TextInput
                  value={document.city}
                  onChange={handleCityField}
                  isDisabled={actualRef === 'DEPARTMENT' || actualRef === 'LOCAL'}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Организация" isRequired>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={document.enterprise}
                  onChange={onChangeOrganization}
                  settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"' }}
                  isDisabled={actualRef === 'DEPARTMENT'}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            {actualRef === 'DEPARTMENT' && (
              <FormComponent.Line>
                <FormComponent.Field label="Подразделение" isRequired>
                  <Select
                    mode={SelectMode.REFERENCE}
                    value={document.department}
                    onChange={onChangeDepartment}
                    settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>
        )}
      </>
    </FormComponent.Wrapper>
  );
};

export { MemberCountAnotherForm };

import React, { memo } from 'react';

import { FormComponent } from 'components';

import { Table as T } from 'types/models';
import { GetMagazineReleaseList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';

type Props = {
  relatedTableState?: T.State;
  workMode: T.WorkMode;
  publicationId: string | undefined;
};

const Releases = ({ relatedTableState, workMode, publicationId }: Props) => (
  <>
    {relatedTableState && (
      <>
        {workMode === 'addMode' && (
          <FormComponent.Description mode="warning">
            Добавление выпуска станет доступно после того как вы создадите журнал и нажмете «Сохранить и продолжить».
          </FormComponent.Description>
        )}

        <DataGrid
          specification={GetMagazineReleaseList(
            {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId: publicationId || undefined,
            },
            workMode,
          )}
        />
      </>
    )}
  </>
);

export const ReleasesComponent = memo(Releases);

import React, { useEffect } from 'react';

import {
  TextDateTime,
  ListEditTable,
  FormComponent,
  Modal,
  Tabs,
  Tab,
  Select,
  TextArea,
  TextAreaMode,
  ButtonMode,
  Toolbar,
} from 'components';

import {
  GetEstimatePositionList,
  GetProjectCodeReceivingDocumentList,
  GetEstimateOverheadHistoryList,
  GetEstimatePositionItemList,
} from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { ProjectCodeInfo } from 'features/Form/looks/estimate/EstimateForm/ProjectCodeInfo';
import { useFormContext } from 'features/Form/hooks';
import { Table } from 'types/models';

import { useController } from './controller';
import { EstimateSummary } from './EstimateSummary';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function EstimateForm({ viewMode, editMode, onClose }: Props) {
  const {
    refreshEstimate,
    estimateId,
    estimate,
    projectCodeId,
    workMode,
    projectCodeOriginal,
    projectCodeSpecification,
    projectCodeRowConverter,
    createdDate,
    handleCreateDateChange,
    handleProjectCodeChange,
    yearOptions,
    handleYearChange,
    calendarPlans,
    selectedRowIndex,
    setSelectedRowIndex,
    isReceivingDocumentModalOpen,
    setIsReceivingDocumentModalOpen,
    isEstimateOverheadModalOpen,
    setIsEstimateOverheadModalOpen,
    handleReceivingDocumentsClick,
    handleEstimateOverheadClick,
    isProjectCodeInfoModalOpen,
    setIsProjectCodeInfoModalOpen,
    selectEstimateYear,
    projectCodeEditShown,
    isAmountWarningOpen,
    setIsAmountWarningOpen,
    amountWarningMessage,
    fundCard,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        if (workMode === 'addMode') {
          return 'Создание сметы шифра';
        }
        return (
          <h3>
            {`${workMode === 'viewMode' ? 'Просмотр' : 'Редактирование'} Смета`} <strong>{estimate?.year}</strong>{' '}
            {estimate?.projectCode?.code ? `шифр: ${estimate?.projectCode?.code}` : ''}
          </h3>
        );
      })(),
    );
  }, [estimate, setTitle, workMode]);

  return (
    <>
      <FormComponent.Template>
        <Toolbar buttons={buttons} mode="form" />

        {workMode === 'addMode' && (
          <>
            <FormComponent.Wrapper>
              <FormComponent.Line>
                <FormComponent.Field label="Дата составления" isRequired>
                  <TextDateTime value={createdDate} onChange={handleCreateDateChange} isDisabled={!!viewMode} />
                </FormComponent.Field>
              </FormComponent.Line>
              {projectCodeEditShown && (
                <FormComponent.Line>
                  <FormComponent.Field label="Шифр проекта" isRequired>
                    <TextArea
                      mode={TextAreaMode.TABLE}
                      settings={{
                        title: 'Шифры проектов',
                        isClearable: false,
                        table: {
                          specification: projectCodeSpecification,
                          onSelect: (row: Table.Entry | null) =>
                            handleProjectCodeChange('projectCode')(row ? projectCodeRowConverter(row) : null),
                        },
                      }}
                      value={projectCodeOriginal?.name}
                      isDisabled={!!viewMode}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <FormComponent.Line>
                <FormComponent.Field label="Год сметы">
                  <Select value={selectEstimateYear()} options={yearOptions || []} onChange={handleYearChange} />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field>
                  <ListEditTable
                    rows={calendarPlans}
                    columns={[
                      { label: 'Этап', formatValue: row => row.stage.number || '' },
                      { label: 'Сроки', formatValue: row => `${row.stage.startDate} - ${row.stage.endDate}` || '' },
                      { label: 'Сумма, план', formatValue: row => row.stage.amountMain || '0', dataKind: 'float' },
                      { label: 'в т.ч. НДС', formatValue: row => row.ndsAmount || '0', dataKind: 'float' },
                    ]}
                    selectedRowIndex={selectedRowIndex}
                    selectRow={setSelectedRowIndex}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Wrapper>
          </>
        )}
        {workMode !== 'addMode' && (
          <>
            <EstimateSummary
              estimate={estimate}
              receivingDocumentsCallback={handleReceivingDocumentsClick}
              estimateOverheadCallback={handleEstimateOverheadClick}
            />
            {estimateId !== null && (
              <FormComponent.Template>
                <Tabs>
                  <Tab title="Смета затрат по видам расходов">
                    <DataGrid
                      specification={GetEstimatePositionList({
                        estimateId,
                        viewMode,
                        handleRefreshEstimate: refreshEstimate,
                        isFiltersHidden: true,
                        fundCard,
                      })}
                    />
                  </Tab>
                  <Tab title="Фактические затраты">
                    <DataGrid specification={GetEstimatePositionItemList({ estimateId, isFiltersHidden: true })} />
                  </Tab>
                  {/* <Tab title="План-график =>">
                  <ListEditTable
                    rows={estimatePositionItems}
                    defaultRowsCount={6}
                    columnIndexesForSumTotal={[1]}
                    columns={[
                      { label: 'Дата', formatValue: x => x.date ?? '', styles: { width: '10%' } },
                      { label: 'Сумма, руб.', formatValue: x => x.amount ?? '0', styles: { width: '10%' } },
                      {
                        label: 'КВР',
                        formatValue: x => x.kindConsumption.kvr?.label || x.kindConsumption.code || '',
                        styles: { width: '55%' },
                      },
                      { label: 'Примечание', formatValue: x => x.note ?? '', styles: { width: '15%' } },
                      { label: 'Квартал', formatValue: x => x.quarter ?? '', styles: { width: '10%' } },
                    ]}
                    selectRow={setSelectedEstimatePositionItemIndex}
                    selectedRowIndex={selectedEstimatePositionItemIndex}
                  />
                </Tab> */}
                </Tabs>
              </FormComponent.Template>
            )}
          </>
        )}
      </FormComponent.Template>
      {estimateId !== null && projectCodeId !== null && (
        <Modal
          isOpen={isReceivingDocumentModalOpen}
          title={`Поступления на шифр ${estimate?.projectCode?.code}`}
          onClose={() => setIsReceivingDocumentModalOpen(false)}
          size={['extralarge', 'small-height']}
        >
          <DataGrid
            specification={GetProjectCodeReceivingDocumentList({ projectCodeId, year: estimate?.year, isFiltersHidden: true })}
          />
        </Modal>
      )}
      {estimateId !== null && (
        <Modal
          isOpen={isEstimateOverheadModalOpen}
          title="Журнал изменения ЦФ"
          onClose={() => setIsEstimateOverheadModalOpen(false)}
          size={['extralarge', 'small-height']}
        >
          <DataGrid specification={GetEstimateOverheadHistoryList({ estimatePositionId: estimateId, isFiltersHidden: true })} />
        </Modal>
      )}

      {estimateId !== null && (
        <Modal
          title="Информация о шифре"
          isOpen={isProjectCodeInfoModalOpen}
          onClose={() => setIsProjectCodeInfoModalOpen(false)}
          size="large"
        >
          <ProjectCodeInfo estimate={estimate} />
        </Modal>
      )}

      <Modal
        mode="warning"
        title="Несоответствие сумм"
        isOpen={isAmountWarningOpen}
        onClose={() => setIsAmountWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsAmountWarningOpen(false),
          },
        ]}
        size="small"
      >
        <div>
          <strong>Внимание!</strong>
        </div>
        <span dangerouslySetInnerHTML={{ __html: amountWarningMessage || '' }} />
      </Modal>
      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          projectCodeId: estimate?.projectCode?.id || undefined,
          startYear: estimate?.year,
          endYear: estimate?.year,
        }}
      />
    </>
  );
}

export const Component = React.memo(EstimateForm);

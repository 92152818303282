import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { AuthorPayment } from 'types/models/Payment';
import { DataGrid } from 'features/Table';
import { GetAuthorPaymentRequestFeedlineList } from 'features/Table/specifications';
import { useController } from './controller';
import { AuthorPaymentRequestStatus, PaymentRequestType } from 'utils/Enums';
import { IncentivePaymentsFormHelp } from 'features/IncentivePayments/Form/Toolbar/help';

type Props = {
  onSave(needClose: boolean, onSuccessCb?: () => void, needConfirm?: boolean): void;
  onChangeStatus(status: AuthorPaymentRequestStatus): void;
  onChangeConfirm(status: AuthorPaymentRequestStatus): void;
  paymentRequest?: AuthorPayment | null;
  isDisabled?: boolean;
};

function Toolbar(props: Props) {
  const { onSave, paymentRequest, onChangeStatus, onChangeConfirm, isDisabled } = props;

  const { buttons, isFeedlineModalOpen, setIsFeedlineModalOpen, isHelpOpen, setIsHelpOpen } = useController({
    onSave,
    paymentRequest,
    onChangeStatus,
    onChangeConfirm,
    isDisabled,
  });

  return (
    <>
      <SharedToolbar buttons={buttons} mode="form" />

      <Modal
        mode="help"
        title={IncentivePaymentsFormHelp(PaymentRequestType.SECURITY_DOCUMENT).title}
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {IncentivePaymentsFormHelp(PaymentRequestType.SECURITY_DOCUMENT).body}
      </Modal>

      <Modal
        isOpen={isFeedlineModalOpen || false}
        onClose={() => setIsFeedlineModalOpen(false)}
        title="Журнал сообщений"
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid specification={GetAuthorPaymentRequestFeedlineList({ paymentId: paymentRequest?.id || '' })} />
      </Modal>
    </>
  );
}

export { Toolbar };

import React from 'react';

import {
  FormComponent,
  ButtonMode,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadFile,
  TextAreaMode,
} from 'components';

import { Arm, Form, Table } from 'types/models';
import { Item } from 'types/models/common';
import { LibraryField } from 'features/Form/views';
import { getTitle } from 'features/Form/looks/publication/helpers';

type Props = {
  formFields: Form.Fields;
  workMode: Table.WorkMode;
  publicationInfo: Form.Publication | null;
  onCreateBibliographicRecordClick: () => void;
  userDepartments: ReferenceItem[];
  publicationId?: string;
  publicationDepartment: ReferenceItem | null;
  setPublicationDepartment: React.Dispatch<ReferenceItem | null>;
  arm?: Arm;
};

function About({
  publicationInfo,
  formFields,
  workMode,
  onCreateBibliographicRecordClick,
  userDepartments,
  publicationId,
  publicationDepartment,
  setPublicationDepartment,
  arm,
}: Props) {
  return (
    <>
      <SectionTitle title={getTitle(publicationInfo)} />
      <FormComponent.Line>
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextArea
            settings={{ rows: 3, maxLength: 2049 }}
            value={formFields.name.value}
            onChange={formFields.name.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.annotation.title} tooltip={formFields.annotation.tooltipText}>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3 }}
            value={formFields.annotation.value}
            onChange={formFields.annotation.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.authorsKeyWords.title} tooltip={formFields.authorsKeyWords.tooltipText}>
          <TextInput
            value={formFields.authorsKeyWords.value}
            onChange={formFields.authorsKeyWords.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.textLanguage.title} isRequired={formFields.textLanguage.required}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.textLanguage.value}
            onChange={formFields.textLanguage.onChange}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"', isClearable: !formFields.textLanguage.required }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired={formFields.pageCount.required} label={formFields.pageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ max: 100000, decimal: false }}
            value={formFields.pageCount.value ?? ''}
            onChange={formFields.pageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.pageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.year.title} isRequired={formFields.year.required}>
          <TextInput
            mode={TextInputMode.YEAR}
            value={formFields.year.value}
            onChange={formFields.year.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.publicationDate.title}>
          <TextDateTime
            value={formFields.publicationDate.value}
            onChange={formFields.publicationDate.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field isRequired={formFields.printPageCount.required} label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.printPageCount.value ?? ''}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.printPageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.address.title} isRequired={formFields.address.required}>
          <TextInput
            value={formFields.address.value}
            onChange={formFields.address.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />
      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            settings={{
              rows: 3,
              externalButtons: [
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Построить',
                  onClick: onCreateBibliographicRecordClick,
                  isDisabled: workMode === 'viewMode',
                },
              ],
            }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Скан-копия выходных данных и идентификаторы публикации" />
      <FormComponent.Line>
        <FormComponent.Field
          label="Прикрепите файл"
          // eslint-disable-next-line max-len
          tooltip="Файл с выходными данными обязательно должен содержать титул, оборот титула, содержание и последний лист с информацией о типе издания, тираже, издательстве"
          isRequired
        >
          <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText}>
          <TextInput
            mode={TextInputMode.DOI}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <LibraryField libraryUrl={publicationInfo?.status?.libraryUrl || ''} />
        <FormComponent.Field label={formFields.libraryLink.title}>
          <TextInput
            mode={TextInputMode.URL}
            value={formFields.libraryLink.value}
            onChange={formFields.libraryLink.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!publicationId && arm === 'pc' && (
        <>
          <SectionTitle title="Ответственное подразделение" />
          <FormComponent.Field
            label="Подразделение"
            tooltip="Публикация будет передана ответственному по подразделению для согласования и утверждения"
          >
            <Select
              value={{ value: publicationDepartment?.id || '', label: publicationDepartment?.label || '' }}
              options={userDepartments.map(x => ({ value: x.id, label: x.label }))}
              onChange={(option: Item) => setPublicationDepartment(userDepartments.find(x => x.id === option?.value) || null)}
            />
          </FormComponent.Field>
        </>
      )}
    </>
  );
}

export const Component = React.memo(About);

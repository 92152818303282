import React, { useMemo, useCallback } from 'react';

import { ListEdit } from 'components';

import { NirRequest, Project, ForeignProject } from 'types/models/NirRequest';

import * as BackendAPI from 'services/BackendAPI';

import { GetNirRequestProjectList } from 'features/Table/specifications';
import { Entry } from 'types/models/Table';
import { useAppDataContext } from 'features/AppData/context';

enum ProjectType {
  SCIENCE_PROJECT = 'SCIENCE_PROJECT',
  FOREIGN_PROJECT = 'FOREIGN_PROJECT',
}
type UnitedProject = Project & ForeignProject & { projectType: ProjectType };

type Props = {
  nirRequest: NirRequest;
  setNirRequest: React.Dispatch<React.SetStateAction<NirRequest>>;
  disabled: boolean;
};

export function Projects(props: Props) {
  const { nirRequest, disabled, setNirRequest } = props;

  const { settings } = useAppDataContext();

  const { methods: getNirRequestProject } = BackendAPI.useBackendAPI('GetNirRequestProject', {
    onSuccessfullCall: ({ data }) =>
      setNirRequest(prevState => ({
        ...prevState,
        collectiveProjects: data,
      })),
  });
  const { methods: getNirRequestForeignProject } = BackendAPI.useBackendAPI('GetNirRequestForeignProject', {
    onSuccessfullCall: ({ data }) =>
      setNirRequest(prevState => ({
        ...prevState,
        collectiveForeignProjects: data,
      })),
  });

  const unitedProjects = useMemo<UnitedProject[]>(
    () => [
      ...(nirRequest?.collectiveProjects || []).map(x => ({
        ...x,
        projectType: ProjectType.SCIENCE_PROJECT,
        enterprise: settings?.organization?.shortName || '',
      })),
      ...(nirRequest?.collectiveForeignProjects || []).map(x => ({
        ...x,
        projectType: ProjectType.FOREIGN_PROJECT,
        type: null,
        foreignEnterprise: null,
      })),
    ],
    [nirRequest?.collectiveForeignProjects, nirRequest?.collectiveProjects, settings?.organization?.shortName],
  );

  const changeUnitedProjects = useCallback(
    (e: UnitedProject[]) => {
      const newProjects = e
        .filter(x => x.projectType === ProjectType.SCIENCE_PROJECT)
        .filter((value, index, self) => index === self.findIndex(t => t.id === value.id));

      const newForeignProjects = e
        .filter(x => x.projectType === ProjectType.FOREIGN_PROJECT)
        .filter((value, index, self) => index === self.findIndex(t => t.id === value.id));

      if (newProjects.length)
        getNirRequestProject.callAPI({ nirRequestId: nirRequest.id || '', projectIds: newProjects.map(x => x.id!) });
      else setNirRequest(prevState => ({ ...prevState, collectiveProjects: [] }));

      if (newForeignProjects.length)
        getNirRequestForeignProject.callAPI({
          nirRequestId: nirRequest.id || '',
          projectIds: newForeignProjects.map(x => x.id!),
        });
      else setNirRequest(prevState => ({ ...prevState, collectiveForeignProjects: [] }));
    },
    [getNirRequestForeignProject, getNirRequestProject, nirRequest.id, setNirRequest],
  );

  const rowConverter = useCallback((e: Entry) => {
    return {
      id: e.id,
      projectType: e.Entity as ProjectType,
      type: null,
      programShortName: '',
      name: '',
      startDate: '',
      endDate: '',
      leader: '',
      foreignEnterprise: null,
      enterprise: '',
      totalAmount: '',
      amountByYear: '',
    };
  }, []);

  return (
    <ListEdit<{}, UnitedProject>
      header={{ title: 'Перечень проектов научного коллектива лаборатории' }}
      rows={unitedProjects}
      onChange={changeUnitedProjects}
      toolbar={['add', 'delete']}
      columns={[
        { label: 'Тип проекта', formatValue: x => x.type?.label || '', styles: { width: '10%' } },
        { label: 'Программа (кратко)', formatValue: x => x.programShortName, styles: { width: '10%' } },
        { label: 'Тема проекта', formatValue: x => x.name, styles: { width: '20%' } },
        { label: 'Сроки проекта', formatValue: x => `${x.startDate} - ${x.endDate}`, styles: { width: '10%' } },
        { label: 'Руководитель', formatValue: x => x.leader, styles: { width: '10%' } },
        { label: 'Сумма всего', formatValue: x => x.totalAmount, styles: { width: '10%' } },
        { label: 'Сумма за предыдущий год', formatValue: x => x.amountByYear, styles: { width: '10%' } },
        { label: 'Международный', formatValue: x => x.foreignEnterprise?.label ?? '', styles: { width: '10%' } },
        { label: 'Выполнен в', formatValue: x => x.enterprise || '', styles: { width: '10%' } },
      ]}
      maxHeight="none"
      defaultRowsCount={10}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'relationTableModal',
        modalTableRowConverter: rowConverter,
        relationTableModalTitle: 'Проекты',
        modalTableSpecification: GetNirRequestProjectList(),
      }}
    />
  );
}

import { Rntd, Form } from 'types/models';

export function validate(r?: Rntd | null, formFields?: Form.Fields) {
  if (!formFields) {
    return [];
  }
  const required = Object.keys(formFields)
    .filter((fieldName: string) => formFields[fieldName].required && !formFields[fieldName]?.value)
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  return [...required];
}

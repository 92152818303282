import { ReferenceItem } from 'components';
import { FileInfo } from 'types/models';
import * as XML from 'xml-js';
import { makeBackendCompilationBackendConfiguration } from '../makeBackendCompilationBackendConfiguration';
import { convertFileToXML } from './commonConverters';

type CheckCompilationDuplicateInput = {
  id: string;
  status: string;
  type: string;
  isElectronic: boolean;
  electronicType: string;
  fields: PublicationFields;
};

type PublicationFields = {
  name: string;
  issn: string;
  isbn: string;
  textLanguage: ReferenceItem;
  publisher: ReferenceItem;
  address: string;
  year: string;
  month: string;
  day: string;
  volume: string;
  issue: string;
  pageCount: string;
  printPageCount: string;
  tirage: string;
  categoryEdition: ReferenceItem;
  typeEdition: ReferenceItem;
  sourceType: ReferenceItem;
  seriesTitle: string;
  bibliographicRecord: string;
  file: FileInfo;
  doi: string;
  libraryLink: string;
  electronicMediaSource: string;
  electronicSystemRequirements: string;
};

function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => XML.ElementCompact,
): (input: Input) => XML.ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName },
      ...makeChildren(input),
    },
  });
}

function makeCompilationRequestXMLConverter<Input>(
  commandName: string,
  makeParametersConverter: (input: Input) => XML.ElementCompact,
) {
  return makeRequestXMLConverter<Input>(commandName, (input: Input) => ({
    ...makeParametersConverter(input),
  }));
}

export const backendCompilationApiConfigurations = {
  CheckCompilationDuplicate: makeBackendCompilationBackendConfiguration(
    'CheckCompilationDuplicate',
    makeCompilationRequestXMLConverter('CheckCompilationDuplicate', (input: CheckCompilationDuplicateInput) => {
      const requestObject: any = {
        Publication: {
          _attr: input.id ? { id: input.id } : {},
          BibliographicRecordAuthorsPlace: 'AUTHORS_FIRST',
          IsElectronic: input.isElectronic,
          Translations: '',
          Projects: '',
          MobileRequests: '',
          Events: '',
          Publisher: input.fields.publisher?.id ? { _attr: { id: input.fields.publisher.id } } : '',
          TextLanguage: input.fields.textLanguage?.id ? { _attr: { id: input.fields.textLanguage.id } } : '',
          CategoryEdition: input.fields.categoryEdition?.id ? { _attr: { id: input.fields.categoryEdition.id } } : '',
          SourceType: input.fields.sourceType?.id ? { _attr: { id: input.fields.sourceType.id } } : '',
        },
      };

      if (input.electronicType) {
        requestObject.Publication.ElectronicType = input.electronicType;
      }

      requestObject.Publication = {
        ...requestObject.Publication,
        ...convertFileToXML('File', input.fields.file),
      };

      const optional = {
        status: input.status,
        type: input.type,
        libraryLink: input.fields.libraryLink,
        pageCount: input.fields.pageCount,
        address: input.fields.address,
        name: input.fields.name,
        month: input.fields.month,
        day: input.fields.day,
        printPageCount: input.fields.printPageCount,
        doi: input.fields.doi,
        tirage: input.fields.tirage,
        seriesTitle: input.fields.seriesTitle,
        issn: input.fields.issn,
        isbn: input.fields.isbn,
        issue: input.fields.issue,
        year: input.fields.year,
        typeEdition: input.fields.typeEdition.id,
        volume: input.fields.volume,
      };

      const optionalKeys = Object.keys(optional);

      optionalKeys.forEach((key: string) => {
        const preparedKey = key as keyof typeof optional;
        const isValueEmpty = !optional[preparedKey];

        if (!isValueEmpty) {
          const preparedKeyName = key[0].toUpperCase() + key.substring(1);
          requestObject.Publication[preparedKeyName] = optional[preparedKey];
        }
      });
      return requestObject;
    }),
  ),
};

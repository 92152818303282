import React, { useCallback, useMemo } from 'react';

import { ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Author, Award } from 'types/models';
import { AuthorshipFields, ChangeProps } from './AuthorshipFields';
import {
  formatAuthor,
  getMockExtendedMember,
  validate,
  MAIN_REPORTER_TOOLTIP_TEXT,
  formatAuthorAward,
  USER_ROLES,
  ALT_PARTICIPANTS_TOOLTIP_TEXT,
} from './helpers';
import { EventTypeName, eventsList } from './eventTypes';
import { ExtendedMember } from './model';
import { showNotification } from 'features/Notifications';

type Props = {
  authors: Author[];
  setAuthors(authors: Author[]): void;
  disabled?: boolean;
  isRequired?: boolean;
  eventType: EventTypeName;
  extraToolbarButtons?: ExtraToolbarButton<ExtendedMember>[];
  awards?: Award[];
};

function MembersList(props: Props) {
  const { authors, setAuthors, disabled = false, eventType, isRequired = true, extraToolbarButtons, awards } = props;

  const extendedMembers = useMemo<ExtendedMember[]>(
    () =>
      authors.map((author, index) => {
        const isAuthorSupervisor = author.role?.customFields?.code === USER_ROLES.SUPERVISOR.code;
        const authorParticipants = authors.filter(({ supervisorId }, authorInnerIndex) => {
          const isCurrentAuthor = authorInnerIndex === index;
          if (isCurrentAuthor) {
            return false;
          }

          return Boolean(supervisorId) && supervisorId === author.person?.id;
        });

        const authorParticipantIds = authorParticipants.map(authorItem => ({
          personId: authorItem.person?.id ?? '',
          roleId: authorItem?.role?.id ?? '',
        }));

        return {
          ...author,
          participantIds: isAuthorSupervisor ? authorParticipantIds : [],
        };
      }),
    [authors],
  );

  const setExtendedMembers = useCallback(
    (nextExtendedMembers: ExtendedMember[]) => {
      const preparedExtendedMembers: ExtendedMember[] = [...nextExtendedMembers].reduce<ExtendedMember[]>(
        (accum, extendedMember) => [
          ...accum,
          (() => {
            const { supervisorId, ...obj } = extendedMember;
            return obj;
          })(),
        ],
        [],
      );

      preparedExtendedMembers.forEach(nextExtendedMember => {
        nextExtendedMember.participantIds.forEach(({ personId, roleId }) => {
          const participantIndex = preparedExtendedMembers.findIndex(extendedMember => {
            const isEqualPersonId = extendedMember.person?.id === personId;
            const isEqualRoleId = extendedMember.role?.id === roleId;

            return isEqualPersonId && isEqualRoleId;
          });

          if (participantIndex !== -1) {
            preparedExtendedMembers[participantIndex].supervisorId = nextExtendedMember.person?.id;
          }
        });
      });

      const preparedMembers: ExtendedMember[] = preparedExtendedMembers.map(extendedMember => {
        extendedMember.participantIds = [];
        return extendedMember;
      });

      setAuthors(preparedMembers);
    },
    [setAuthors],
  );

  const handleChangeField = (
    rowSetter: React.Dispatch<React.SetStateAction<ExtendedMember | null>>,
    changeProps: ChangeProps[],
  ) => {
    rowSetter(prevAuthor =>
      changeProps.reduce<ExtendedMember | null>((acc, { key, value }) => ({ ...acc, [key]: value } as any), {
        ...getMockExtendedMember(),
        ...prevAuthor,
      }),
    );
  };

  const isConferenceEvent = useMemo(() => eventType === 'CONFERENCE', [eventType]);

  const columns = useMemo(
    () =>
      [{ label: 'Участник', formatValue: formatAuthor }].concat(
        awards ? [{ label: 'Награда', formatValue: value => formatAuthorAward({ author: value, awards }) }] : [],
      ),
    [awards],
  );

  const onPreSubmit = useCallback((extendedMember: ExtendedMember, submit: (extendedMember: ExtendedMember) => void) => {
    const isSupervisor = extendedMember.role?.customFields?.code === USER_ROLES.SUPERVISOR.code;
    if (!isSupervisor) {
      submit({ ...extendedMember, participantIds: [] });
    } else {
      submit(extendedMember);
    }
  }, []);

  return (
    <ListEdit
      header={{
        title: eventsList[eventType].title,
        tooltip: isConferenceEvent ? MAIN_REPORTER_TOOLTIP_TEXT : ALT_PARTICIPANTS_TOOLTIP_TEXT,
        isRequired,
      }}
      rows={extendedMembers}
      onChange={setExtendedMembers}
      toolbar={['add', 'edit', 'delete', ...(extraToolbarButtons || []), 'move']}
      columns={columns}
      maxHeight="none"
      defaultRowsCount={10}
      withoutHead={!awards}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'customModalComponent',
        modalTitle: eventsList[eventType].modalTitle,
        onPreSubmit,
        validation: {
          checkIsValid: (member, index, list, mode) => validate(member, list, mode, index).every(x => x.isValid),
          onInvalidate: (member, mode, index, list) => {
            const info = validate(member, list, mode, index);
            info.forEach(({ isValid, invalidMessage }) => {
              if (!isValid) {
                setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
              }
            });
          },
        },
        renderComponent: (extendedMember, setExtendedMember) => (
          <>
            <AuthorshipFields
              extendedMember={extendedMember ?? getMockExtendedMember()}
              extendedMembers={extendedMembers}
              eventType={eventType}
              onChange={val => handleChangeField(setExtendedMember, val)}
            />
          </>
        ),
      }}
    />
  );
}

export { MembersList };

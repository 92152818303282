import React from 'react';

import { Tabs, Tab, FormComponent } from 'components';

import { usePermissions } from 'utils/Helpers/projects/usePermissions';
import {
  CoPerformers,
  CoPerformersProps,
  Partners,
  PartnersProps,
  InternationalPartnership,
  InternationalPartnershipProps,
} from './Tabs';
import { ProjectType } from 'utils/Enums';

type PerformerTab = {
  projectId: string | null;
  disabled: boolean;
  projectType: ProjectType;
};
type Props = PerformerTab & CoPerformersProps & PartnersProps & InternationalPartnershipProps;

export function AllPerformers(props: Props) {
  const {
    disabled,
    projectId,
    partners,
    setPartners,
    internationalProgram,
    setInternationalProgram,
    foreignEnterprise,
    setForeignEnterprise,
    partnerCountries,
    setPartnerCountries,
    projectType,
  } = props;

  const permissions = usePermissions(projectType);

  return (
    <FormComponent.Template>
      <Tabs>
        <Tab
          title="Организации-соисполнители"
          isVisible={permissions.isVisiblePartnersAccomplices}
          info={
            <>
              <p>
                Отображается информация, загруженная в систему на основе данных бухгалтерского учёта об оплате по договорам
                (расчёты с соисполнителем). Носит информативный характер, используется в некоторых отчётах, как подтверждённый
                факт сотрудничества в рамках проекта.
              </p>

              <div className="warning">
                <span>
                  Для всех категорий организаций обязательно должны быть указаны{' '}
                  <strong>Страна, Сектор деятельности и Тип организации</strong>. Данную информацию для организации необходимо
                  заполнить в <strong>Справочнике организаций</strong>. Без этих атрибутов организации отчёты не построятся.
                </span>
              </div>
            </>
          }
        >
          <CoPerformers projectId={projectId} />
        </Tab>
        <Tab
          title="Организации-партнёры"
          isVisible={permissions.isVisiblePartnersPartners}
          info={
            <>
              <p>
                Желательно указывать организации, с которыми заключён договор на выполнение услуг/работ или соглашение о
                сотрудничестве в рамках выполнения проекта. Информация по организациям-партнерам учитывается в отчётах (в т.ч. по
                международному сотрудничеству), при планировании расходов и (при необходимости) в данных для госрегистрации в
                ЕГИСУ НИОКТР.
              </p>

              <div className="warning">
                <span>
                  Для всех категорий организаций обязательно должны быть указаны{' '}
                  <strong>Страна, Сектор деятельности и Тип организации</strong>. Данную информацию для организации необходимо
                  заполнить в <strong>Справочнике организаций</strong>. Без этих атрибутов организации отчёты не построятся.
                </span>
              </div>

              <p>
                <strong>Список отчётов, которые строятся на основании данных этого раздела:</strong>
              </p>
              <p>
                <strong>1-Мониторинг 2023:</strong> 1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере
              </p>
            </>
          }
        >
          <Partners
            partners={partners}
            setPartners={setPartners}
            withMessages={false}
            disabled={disabled || !permissions.isEditablePartnersPartners}
          />
        </Tab>
        <Tab
          title="Международное сотрудничество"
          isVisible={permissions.isVisiblePartnersIntCoop}
          info={
            <>
              <p>
                Рекомендуется указать сведения о международных компаниях, или программах, к которым имеет отношение проект.
                Информация учитывается в отчётах по международному сотрудничеству.
              </p>

              <div className="warning">
                <span>
                  Для всех категорий организаций обязательно должны быть указаны{' '}
                  <strong>Страна, Сектор деятельности и Тип организации</strong>. Данную информацию для организации необходимо
                  заполнить в <strong>Справочнике организаций</strong>. Без этих атрибутов организации отчёты не построятся.
                </span>
              </div>

              <p>
                <strong>Список отчётов, которые строятся на основании данных этого раздела:</strong>
              </p>
              <p>
                <strong>1-Мониторинг 2023:</strong> 1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере
              </p>
            </>
          }
        >
          <InternationalPartnership
            internationalProgram={internationalProgram}
            setInternationalProgram={setInternationalProgram}
            foreignEnterprise={foreignEnterprise}
            setForeignEnterprise={setForeignEnterprise}
            partnerCountries={partnerCountries}
            setPartnerCountries={setPartnerCountries}
            disabled={disabled || !permissions.isEditablePartnersIntCoop}
          />
        </Tab>
      </Tabs>
    </FormComponent.Template>
  );
}

import React from 'react';

import { Tabs, Tab } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { Projects } from './Projects';
import { PublicationsScopusWoS } from './PublicationsScopusWoS';
import { Participations } from './Participations';
import { Dissertations } from './Dissertations';
import { SecurityDocuments } from './SecurityDocuments';
import { ExpositionParticipations } from './ExpositionParticipations';
import { NirTenderType } from 'utils/Enums';

type Props = {
  nirRequest: NirRequest.NirRequest;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  setNirRequest: React.Dispatch<React.SetStateAction<NirRequest.NirRequest>>;
  disabled: boolean;
};

export function CollectiveResults(props: Props) {
  const { nirRequest, makeChangeHandler, setNirRequest, disabled } = props;
  const tenderType = nirRequest?.lot?.nirTenderType?.value ?? null;

  return (
    <Tabs>
      <Tab title="Проекты">
        <Projects disabled={disabled} nirRequest={nirRequest} setNirRequest={setNirRequest} />
      </Tab>
      <Tab title="Публикации WoS и Scopus">
        <PublicationsScopusWoS disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="РИД">
        <SecurityDocuments disabled={disabled} nirRequest={nirRequest} setNirRequest={setNirRequest} />
      </Tab>
      <Tab title="Доклады на мероприятиях">
        <Participations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="Представление продукции">
        <ExpositionParticipations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
      <Tab title="Защищённые диссертации" isVisible={tenderType !== NirTenderType.PP}>
        <Dissertations disabled={disabled} nirRequest={nirRequest} makeChangeHandler={makeChangeHandler} />
      </Tab>
    </Tabs>
  );
}

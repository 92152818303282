export function RntdGridHelp(): JSX.Element {
  return (
    <>
      <h3>Раздел предназначен для решения задач по коммерциализации и трансфера технологий.</h3>

      <p>
        <strong>РИД</strong> — результат интеллектуальной деятельности в научно-технической сфере: изобретение, промышленные
        образцы, базы данных, программные продукты, способ осуществления профессиональной деятельности и прочие объекты, имеющие
        действительную или потенциальную коммерческую ценность.
      </p>

      <h4>Работа фильтров</h4>

      <ul>
        <li>
          <strong>РИД</strong> – список РИД
        </li>
        <li>
          <strong>Приняты к коммерциализации</strong> — показывает РИД, в которых есть заключение о возможности коммерциализации
          РИД (на вкладке Коммерциализация)
        </li>
        <li>
          <strong>Новые ОИС</strong> — записи о новых зарегистрированных объектах интеллектуальной собственности (в таблице записи
          выделены зелёным цветом)
        </li>
      </ul>

      <h4>Подсветка записей в таблице:</h4>

      <ul>
        <li>Зелёный цвет — для новых зарегистрированных объектах интеллектуальной собственности</li>
        <li>Красный цвет — для РИД, у которых закончился срок действия охранного документа</li>
      </ul>

      <h4>Особенности заполнения информации о РИД</h4>

      <ul>
        <li>
          Для добавления РИД используется процедура создания записи на основе метаданных охранного документа (патента,
          свидетельства, ноу-хау), а так же ОИС, не требующих регистрации. Именно поэтому в таблице отображаются
          зарегистрированные сотрудниками отдела интеллектуальной собственности новые ОИС.
        </li>
        <li>
          Чтобы добавить РИД необходимо выделить в таблице новый ОИС и нажать кнопку <strong>Добавить</strong> — откроется форма
          добавления <strong>РИД</strong> и на вкладки <strong>О РИД и Классификаторы</strong> скопируются основные данные из
          описания ОИС.
        </li>
        <li>Поле Код заполняется в соответствии с принятыми внутренними правилами учётной политики.</li>
        <li>
          В случае, если для ОИС указана связь с научным проектом, то с помощью кнопки{' '}
          <strong>Посмотреть описание проекта</strong> — откроется окно с информацией о проекте (аннотация, цели, ожидаемые
          результаты)
        </li>
        <li>
          Вкладка Коммерциализация предназначена для заключения о возможности коммерциализации. Формулировки Заключения- в
          пополняемом справочнике.
        </li>
        <li>
          Вкладка Участие в выставках – содержит информацию о выставках и полученных наградах за научно- техническую разработку.
          Источник данных — участие в выставке в пункте меню Мероприятия, Участие в выставках.
        </li>
        <li>
          Общий список разработок, представленных на выставках, — в пункте меню Коммерциализация, Разработки (выставочные
          экспонаты)
        </li>
      </ul>
    </>
  );
}

import React, { useEffect } from 'react';

import { FormComponent, Tabs, Tab } from 'components';

import { Contracts } from 'features/Form/looks/securityDocument/SecurityDocumentForm/Tabs/Contracts';
import { GetContractPaymentLogList, GetSecurityDocumentIncentivePaymentList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { ProjectInfoModal } from 'features/ProjectInfoModal';
import { useController } from './controller';
import { Documents } from 'features/Documents';
import { Document } from './Tabs/Document';
import { Classifiers } from './Tabs/Classifiers';
import { Nma } from './Tabs/Nma';
import { Payments } from './Tabs/Payments';
import { Toolbar } from './Toolbar';
import { useFormContext } from 'features/Form/hooks';
import { NotRegistredDocument } from './Tabs/NotRegistredDocument';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';
import { SecurityDocumentType } from 'utils/Enums';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function SecurityDocumentForm({ viewMode, editMode, onClose }: Props) {
  const {
    securityDocument,
    setSecurityDocument,
    formFields,
    handleClassifierChange,
    workMode,
    documents,
    setDocuments,
    handleFormSubmit,
    isProjectInfoModalOpen,
    setIsProjectInfoModalOpen,
    handleChangeProject,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    const EntityTitles: Record<string, string> = {
      PATENT: 'патента',
      EVIDENCE: 'свидетельства',
      KNOW_HOW: 'ноу-хау',
      NON_REGISTERED: 'объекта авторского права',
    };

    setTitle?.(
      getStatusMessage(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            if (workMode === 'viewMode') {
              return 'Просмотр';
            }
            return 'Редактирование';
          })(),
          securityDocument?.type?.value ? EntityTitles[securityDocument?.type?.value] : '',
        ]
          .filter(Boolean)
          .join(' '),
        securityDocument?.id || '',
        (() => {
          if (
            [SecurityDocumentType.PATENT, SecurityDocumentType.EVIDENCE].includes(
              securityDocument.type?.value as SecurityDocumentType,
            )
          ) {
            if (securityDocument.isRejected) return 'Заявка отклонена';
            if (!!formFields.documentNumber.value) return 'Получен документ';
            if (!!formFields.requestSendDate.value) return 'Заявка отправлена';
            if (!!formFields.requestNumber.value) return 'Заявка зарегистрирована';
            return 'Черновик';
          }
          return '';
        })(),
      ),
    );
  }, [
    formFields.documentNumber.value,
    formFields.requestNumber.value,
    formFields.requestSendDate.value,
    securityDocument?.id,
    securityDocument.isRejected,
    securityDocument.type?.value,
    setTitle,
    workMode,
  ]);

  return (
    <FormComponent.Template>
      <Toolbar
        securityDocument={securityDocument}
        formFields={formFields}
        onSave={needClose => handleFormSubmit(needClose)}
        onSaveDraft={() => handleFormSubmit(false)}
        onViewProjectInfo={() => {
          setIsProjectInfoModalOpen(true);
        }}
        disabled={viewMode}
      />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Описание">
            {!!securityDocument?.type?.value &&
              (securityDocument?.type?.value === SecurityDocumentType.NON_REGISTERED ? (
                <NotRegistredDocument
                  securityDocument={securityDocument}
                  setSecurityDocument={setSecurityDocument}
                  formFields={formFields}
                  disabled={viewMode ?? false}
                  handleChangeProject={handleChangeProject}
                />
              ) : (
                <Document
                  securityDocument={securityDocument}
                  setSecurityDocument={setSecurityDocument}
                  formFields={formFields}
                  disabled={viewMode ?? false}
                  handleChangeProject={handleChangeProject}
                />
              ))}
          </Tab>
          <Tab title="Классификаторы">
            <Classifiers
              securityDocument={securityDocument}
              makeChangeHandler={handleClassifierChange}
              disabled={viewMode ?? false}
            />
          </Tab>
          <Tab title="НМА">
            <Nma
              formFields={formFields}
              securityDocument={securityDocument}
              setSecurityDocument={setSecurityDocument}
              disabled={viewMode ?? false}
            />
          </Tab>
          <Tab
            title="Оплата пошлины"
            isVisible={
              securityDocument?.type?.value !== SecurityDocumentType.KNOW_HOW &&
              securityDocument?.type?.value !== SecurityDocumentType.NON_REGISTERED
            }
          >
            <Payments
              securityDocument={securityDocument}
              setSecurityDocument={setSecurityDocument}
              disabled={viewMode ?? false}
            />
          </Tab>
          <Tab title="Договоры на использование ИС">
            <Contracts securityDocument={securityDocument} />
          </Tab>
          <Tab title="Лицензионные отчисления">
            <DataGrid
              specification={GetContractPaymentLogList({
                isHideFirstLevel: true,
                securityDocument: securityDocument.id ?? '-2',
                isWithTemplatesPanel: false,
                isFiltersHidden: true,
              })}
            />
          </Tab>
          <Tab title="Стимулирующие выплаты" isVisible={securityDocument?.type?.value !== SecurityDocumentType.NON_REGISTERED}>
            <DataGrid
              specification={GetSecurityDocumentIncentivePaymentList({
                securityDocumentId: securityDocument.id || '-2',
                isFiltersHidden: true,
              })}
            />
          </Tab>
          <Tab title="Документы">
            <Documents
              isCanIsSharedEdit
              sharedLabel="Отображать в списке документов проекта и РИД"
              documents={documents}
              setDocuments={setDocuments}
              disabled={workMode === 'viewMode'}
              fieldsType="securityDocument"
              withMessages={false}
              isSortEnabled
              isVisibleFilters
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <ProjectInfoModal
        isOpen={isProjectInfoModalOpen}
        onClose={() => setIsProjectInfoModalOpen(false)}
        project={securityDocument?.project ?? null}
      />
    </FormComponent.Template>
  );
}

export const Component = React.memo(SecurityDocumentForm);

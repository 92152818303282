import React, { useState, useMemo } from 'react';

import { ButtonProps, Toolbar } from 'components';

import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { SalaryChangeModals } from '../../GetSalaryByProjectList/views/SalaryChangeModals';

type Props = {
  tableState: Table.State;
  customState: State;
};

function LeftPanelForThirdLevel({ tableState, customState }: Props) {
  const selectedRows = tableState.selectedRows;

  const [selectedRow] = selectedRows;

  const [isSalaryChangeOpen, setIsSalaryChangeOpen] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'transport' },
        title: 'Перенести на другой шифр',
        onClick: () => setIsSalaryChangeOpen(true),
        isDisabled: !selectedRow,
      },
    ],
    [selectedRow],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      {!!customState.seletedSalaryRow && (
        <SalaryChangeModals
          isSalaryChangeOpen={isSalaryChangeOpen}
          setIsSalaryChangeOpen={setIsSalaryChangeOpen}
          selectedRows={[customState.seletedSalaryRow]}
          salaryJobPaymentIds={selectedRows.map(x => x.id).join(',')}
          onSalaryChange={customState.onSalaryChange}
          mode={customState.salaryChangeMode}
        />
      )}
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

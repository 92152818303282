import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetFundCardList, GetProjectCodeOverheadList, GetProjectOverheadList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function Fundcards() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['finance', 'fundcards'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Шифры накладных расходов" id="list">
          <DataGrid specification={GetFundCardList({})} />
        </Tab>
        <Tab title="Шифры проектов" id="codes">
          <DataGrid specification={GetProjectCodeOverheadList} />
        </Tab>
        <Tab title="Накладные по нормативу проекта" id="project_overhead">
          <DataGrid specification={GetProjectOverheadList} />
        </Tab>
      </Tabs>
    </Content>
  );
}

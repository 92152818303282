import React, { useLayoutEffect, useMemo, useState } from 'react';

import { ReferenceItem, FormComponent, Select, SelectMode } from 'components';

import { Event } from 'types/models';
import { Item } from 'types/models/common';
import { useAppDataContext } from 'features/AppData/context';
import { EnumValue } from 'types/models/Table';

type Props = {
  document: Event.Enterprise | null;
  onChange(row: Event.Enterprise): void;
};

const EnterprisesComponentForm = ({ document, onChange }: Props) => {
  const { enumMap } = useAppDataContext();
  const roles = useMemo<EnumValue[]>(() => enumMap.EventEnterpriseRole.values, [enumMap]);

  const emptyRow: Event.Enterprise = {
    id: null,
    role: { label: roles[0]?.label ?? '', value: roles[0]?.value ?? '' },
    element: { id: '', label: '' },
  };

  const onChangeOrganization = (value: ReferenceItem) => {
    if (document) {
      onChange({ ...document, element: value });
    } else {
      onChange({ ...emptyRow, element: value });
    }
  };

  const initialRole = roles[0];
  const [role, setRole] = useState<EnumValue | null>(initialRole || null);

  const changeRole = (value: string) => {
    const nextValue = roles.find(r => r.value === value);
    if (document) {
      onChange({ ...document, role: { label: nextValue?.label ?? '', value: nextValue?.value ?? '' } });
    } else {
      onChange({ ...emptyRow, role: { label: nextValue?.label ?? '', value: nextValue?.value ?? '' } });
    }
    setRole(nextValue || null);
  };

  useLayoutEffect(() => {
    if (document) {
      if (document.role) {
        setRole({ label: document.role.label, value: document.role.value } as EnumValue);
      }
    }
  }, [document]);

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" labelSize="fit">
          <Select value={role || null} options={roles} onChange={(option: Item) => changeRole(option.value)} />
        </FormComponent.Field>
        <FormComponent.Field label="Организация" labelSize="fit">
          <Select
            mode={SelectMode.REFERENCE}
            value={document?.element}
            onChange={onChangeOrganization}
            settings={{ name: 'RefEnterprise', title: 'Справочник "Организации"', isClearable: true }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};

export { EnterprisesComponentForm };

import React, { memo } from 'react';

import { ReferenceItem } from 'components';

import { Table } from 'types/models';
import { GetProgramEventList } from 'features/Table/specifications';
import { State } from 'features/Table/specifications/programs/GetProgramEventList/makeUseCustomController';
import { DefaultParameterList } from '../DefaultParameterList';
import { BuilderProps, CustomSelectSpecification } from 'features/BuildReportPopup/models';

const EventList = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const specification: CustomSelectSpecification<State, object> = {
    modalTableSpecification: GetProgramEventList({ isClearToolbar: true }),
    relationTableModalTitle: 'Справочник "Мероприятия"',
    modalTableRowConverter: (row: Table.Entry): ReferenceItem => ({
      id: row.id,
      label: row.CodeName,
    }),
  };

  return (
    <DefaultParameterList
      parameterName={parameterName}
      setParameterValueByName={setParameterValueByName}
      parameters={parameters}
      enumProps={{
        fieldLabel: 'Мероприятие',
        listEditTitle: 'Справочник "Мероприятия"',
        mode: { component: 'CustomSelect', specification },
      }}
    />
  );
};

const Component = memo(EventList);

export { Component as EventList };

import React, { useEffect } from 'react';
import * as TabsContent from './Tabs';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { AdministratorMessagePopup } from 'features/AdministratorMessagePopup';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Permits } from 'utils/Permissions/Permits';
import { ControlPanel } from '../views/ControlPanel';
import { useController } from './controller';
import { Component as SimilarParticipations } from '../views/SimilarParticipations';
import { EditParticipationDepartments } from '../views/EditParticipationDepartments';
import { ParticipationHeader } from 'features/Form/views/ModalHeader/participation';
import { useFormContext } from 'features/Form/hooks';
import { getEventFullName } from 'utils/Helpers/event';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  onClose: () => void;
};

const ParticipationFormConcert = ({ onClose }: Props) => {
  const {
    isLoading,
    isProfile,
    participation,
    setParticipation,
    formFields,
    participationId,
    relatedTableState,
    workMode,
    documents,
    event,
    isEventSelectWarningOpen,
    isDocumentsPopupOpened,
    participationMessage,
    participationMessageType,
    isAdminMessagePopupOpen,
    tabsId,
    disabledEvent,
    isSimilarParticipationOpen,
    isEditDepartmentsOpen,
    isEditDepartmentsToAddedFormOpen,
    isApprovePopupOpen,
    afterSubmitDepartmentsForm,
    afterSubmitApproveParticipation,
    handleOpenApprovePopup,
    handleCloseApprovePopup,
    openEditDepartmentsForm,
    closeEditDepartmentsForm,
    closeEditDepartmentsToAddedForm,
    afterSubmitEditDepartmentsToAddedForm,
    setDocuments,
    setEvent,
    setIsEventSelectWarningOpen,
    handleFormSubmit,
    goToDocumentsAction,
    confirmDocumentPopup,
    setParticipationMessage,
    setParticipationMessageType,
    saveForm,
    acceptSimilarParticipation,
    handleCloseSimilarParticipation,
    isAuthorsPopupOpen,
    onConfirmAuthorsPopup,
    onCloseAuthorsPopup,
    onResetAuthorsPopup,
  } = useController({ onClose });

  const {
    look: { setTitle },
  } = useFormContext();

  const { userPermission } = useAppDataContext();

  useEffect(() => {
    setTitle?.(
      ParticipationHeader({
        workMode,
        departments: participation?.departments || [],
        status: participation?.status.label || '',
        participationId: participationId || '',
        participationTypeCode: ParticipationTypes.PARTICIPATION_CONCERT.code,
      }),
    );
  }, [participation?.departments, participation?.status.label, participationId, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel
        permits={{
          approve: Permits.PARTICIPATION_CONCERT_APPROVE,
          edit: Permits.PARTICIPATION_CONCERT_DEPARTMENT_EDIT,
        }}
        type={ParticipationTypes.PARTICIPATION_CONCERT}
        isDisabled={isLoading}
        isProfile={isProfile}
        afterSubmitDepartmentsForm={afterSubmitDepartmentsForm}
        addCallback={handleFormSubmit}
        workMode={workMode}
        id={participationId || ''}
        isApprovePopupOpen={isApprovePopupOpen}
        handleOpenApprovePopup={handleOpenApprovePopup}
        handleCloseApprovePopup={handleCloseApprovePopup}
        isEditDepartmentsOpen={isEditDepartmentsOpen}
        openEditDepartmentsForm={openEditDepartmentsForm}
        closeEditDepartmentsForm={closeEditDepartmentsForm}
        afterSubmitApproveParticipation={afterSubmitApproveParticipation}
        isOnlyEditing={
          workMode === 'addMode' &&
          !isHasPermission(userPermission, [Permits.PARTICIPATION_CONCERT_EDIT_ADDED_MY, Permits.PARTICIPATION_CONCERT_EDIT_ALL])
        }
      />

      <EditParticipationDepartments
        id={participationId || ''}
        isOpen={isEditDepartmentsToAddedFormOpen}
        onClose={closeEditDepartmentsToAddedForm}
        afterSubmit={afterSubmitEditDepartmentsToAddedForm}
        type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        participantsTitle="Список участников:"
        hideToolbarButtons
        hideActionButtons={false}
      />

      <FormComponent.Template>
        <Tabs id={tabsId}>
          <Tab title="Описание">
            <TabsContent.AboutComponent
              participation={participation}
              setParticipation={setParticipation}
              workMode={workMode}
              formFields={formFields}
              event={event}
              setEvent={setEvent}
              relatedTableState={relatedTableState}
              disabledEvent={disabledEvent}
            />
          </Tab>
          <Tab title="Документы">
            <TabsContent.DocumentsComponent documents={documents} setDocuments={setDocuments} workMode={workMode} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.MessagesComponent
              participationId={participationId}
              relatedTableState={relatedTableState}
              workMode={workMode}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isEventSelectWarningOpen}
        onClose={() => setIsEventSelectWarningOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Закрыть',
            onClick: () => setIsEventSelectWarningOpen(false),
          },
        ]}
        size="small"
      >
        <>Не выбрано мероприятие.</>
      </Modal>

      <Modal
        mode="info"
        title="Внимание"
        isOpen={isDocumentsPopupOpened}
        onClose={goToDocumentsAction}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: confirmDocumentPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Перейти на вкладку документы',
            onClick: goToDocumentsAction,
          },
        ]}
        size="small"
      >
        <>
          На вкладке Документы прикрепите скан-копию программы мероприятия или файл с документом подтверждающим участие.
          <br />
          <strong>Наличие прикрепленного документа - это обязательное условие для утверждения участия</strong>
        </>
      </Modal>

      <Modal
        mode="info"
        title="Внимание!"
        isOpen={isAuthorsPopupOpen}
        onClose={onCloseAuthorsPopup}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Нет',
            onClick: onConfirmAuthorsPopup,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Добавить',
            onClick: onResetAuthorsPopup,
          },
        ]}
        size="small"
      >
        <div>
          <strong>
            В списке «Мои участия», отображаются только те записи об участии, в которых вы указаны в Сведениях об участниках
            творческого мероприятия, иначе отобразится в списке «Все участия»
          </strong>
        </div>
        <p>Вашей персоны нет в списке участников творческого мероприятия. Добавить?</p>
      </Modal>

      <AdministratorMessagePopup
        title="Добавьте стандартное и/или текстовое сообщение администратору"
        isOpen={isAdminMessagePopupOpen}
        onConfirm={() => {
          saveForm(true);
        }}
        publicationMessage={participationMessage}
        updatePublicationMessage={setParticipationMessage}
        publicationMessageType={participationMessageType}
        setPublicationMessageType={setParticipationMessageType}
        onConfirmWithoutMessage={() => {
          saveForm(false);
        }}
        command="RefParticipationFeedlineMessage"
      />

      <SimilarParticipations
        eventId={event?.id || ''}
        eventStatus={event?.status?.label || ''}
        eventName={getEventFullName(event)}
        workMode={workMode}
        type={ParticipationTypes.PARTICIPATION_CONCERT.code}
        isOpen={isSimilarParticipationOpen}
        onClose={handleCloseSimilarParticipation}
        onAccept={acceptSimilarParticipation}
      />
    </FormComponent.Template>
  );
};

export const Component = React.memo(ParticipationFormConcert);

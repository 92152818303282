import { useCallback, useState } from 'react';
import { format } from 'date-fns';

import { Project } from 'types/models';
import { Member } from 'features/SelectPerson';
import { useAppDataContext } from 'features/AppData/context';
import { formatDateTimeStr } from 'utils/Constants';

type Arguments = {
  performer: Project.Performer;
  setPerformer(performer: Project.Performer): void;
};

export function useController({ performer, setPerformer }: Arguments) {
  const { currentPerson } = useAppDataContext();

  const [history, setHistory] = useState<Partial<Member>>({});

  const handleUpdateMember = useCallback(
    (member: Member) => {
      setPerformer({
        ...performer,
        person: member.person,
        fio: member.person?.fullName ?? '',
        jobPeriods: performer.jobPeriods.map(jobPeriod => ({
          id: jobPeriod.id,
          startDate: jobPeriod.startDate,
          endDate: jobPeriod.endDate,
          role: jobPeriod.role,
          person: member.person,
          historyLabel: '',
          job: member.job || null,
          education: member.education || null,
          degree: member.degree || null,
          rank: member.rank || null,
          academicRank: member.academicRank || null,
          citizenship: member.citizenship || null,
          note: jobPeriod.note,
        })),
        ...(!performer.id && {
          createdBy: currentPerson?.fullName || '',
          createdDate: format(new Date(), formatDateTimeStr),
        }),
      });

      setHistory({
        academicRank: member.academicRank || null,
        citizenship: member.citizenship || null,
        degree: member.degree || null,
        education: member.education || null,
        job: member.job || null,
        rank: member.rank || null,
      });
    },
    [currentPerson?.fullName, performer, setPerformer],
  );

  const setJobPeriods = useCallback(
    (jobPeriods: Project.JobPeriod[]) => {
      setPerformer({ ...performer, jobPeriods });
    },
    [performer, setPerformer],
  );

  return {
    handleUpdateMember,
    setJobPeriods,
    history,
  };
}

import React, { ReactNode, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import { Icon, IconProps, Popover } from 'components';

import { Props as PermissionProps, Permission } from 'components/Permission';
import { ButtonAttributes } from 'types/models/common';
import { showNotification } from 'features/Notifications';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('button');

export enum ButtonMode {
  /** Without styles */
  DEFAULT = 'default',
  /** Primary color style */
  PRIMARY = 'primary',
  /** Secondary color style */
  SECONDARY = 'secondary',
}

type ExpandedList = {
  /** @property {function} callback - List of items */
  list(): Map<string, string> | Promise<Map<string, string>>;
  /** @property {function} callback - (optional) Callback on list item click */
  callback?({ value, name }: { value: string; name: string }): void;
};

export type ButtonProps = ButtonAttributes & {
  // eslint-disable-next-line max-len
  /** @property {React.MutableRefObject<HTMLButtonElement | null>} refButton - (optional) Reference to button (ignore for list mode) */
  refButton?: React.MutableRefObject<HTMLButtonElement | null>;
  /** @property {ButtonMode} mode - (optional) Mode of button (See ButtonMode enum) (ButtonMode.DEFAULT by default) */
  mode?: ButtonMode;
  /** @property {IconProps} icon - (optional) Icon props (See Icon props type) */
  icon?: IconProps;
  /** @property {string} text - (optional) Text for button */
  text?: string;
  /** @property {Pick<PermissionProps>} permission - (optional) Permissions for button (See PermissionProps type) */
  permission?: Pick<PermissionProps, 'name'>;
  /** @property {string} title - (optional) Tooltip on button hover */
  title?: string;
  /** @property {function} onClick - (optional) Callback on button click */
  onClick?(e?: React.MouseEvent<HTMLButtonElement>): void;
  /** @property {ExpandedList} expandedList - (optional) Show list on click (See ExpandedList type) */
  expandedList?: ExpandedList;
  /** @property {boolean} isHidden - (optional) Set is hidden button (false by default) */
  isHidden?: boolean;
  /** @property {boolean} isDisabled - (optional) Set is disabled button (false by default) */
  isDisabled?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {React.ReactNode | JSX.Element | false} children - (optional) Children content */
  children?: (React.ReactNode | JSX.Element | false)[] | React.ReactNode | JSX.Element | null | false | string;
};

/**
 * App button (any)
 *
 * @param {React.MutableRefObject<HTMLButtonElement | null>} refButton - (optional) Reference to button (ignore for list mode)
 * @param {ButtonMode} mode - (optional) Mode of button (See ButtonMode enum) (ButtonMode.DEFAULT by default)
 * @param {IconProps} icon - (optional) Icon props (See Icon props type)
 * @param {string} text - (optional) Text for button
 * @param {Pick<PermissionProps>} permission - (optional) Permissions for button (See ButtonPermission type)
 * @param {string} title - (optional) Tooltip on button hover
 * @param {function} onClick - (optional) Callback on button click
 * @param {ExpandedList} expandedList - (optional) Show list on click (See ExpandedList type)
 * @param {boolean} isHidden - (optional) Set is hidden button (false by default)
 * @param {boolean} isDisabled - (optional) Set is disabled button (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @returns {JSX} JSX
 */
export const Button = React.memo((props: ButtonProps) => {
  const {
    refButton,
    mode = ButtonMode.DEFAULT,
    icon,
    text,
    permission,
    title,
    onClick,
    expandedList,
    isHidden = false,
    isDisabled = false,
    classMixin,
    style,
    children,
    ...restProps
  } = props;

  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [listPopover, setListPopover] = useState<ReactNode[]>([]);
  const [isOpenedPopover, setIsOpenedPopover] = useState<boolean>(false);

  const handleClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
    if (!isDisabled && onClick) {
      onClick(e);
    }

    if (expandedList) {
      const { callback } = expandedList;

      const mapExpandedList: Map<string, string> = await expandedList.list();

      if (mapExpandedList.size) {
        const result: ReactNode[] = [];

        mapExpandedList?.forEach((value, name) => {
          result.push(
            callback ? (
              <div key={value + name} className={b('list-item')} onClick={() => callback({ value, name })}>
                {name}
              </div>
            ) : (
              <a key={value + name} className={b('list-item')} href={value} target="_blank" rel="noopener noreferrer">
                {name}
              </a>
            ),
          );
        });

        if (!result.length) {
          showNotification({
            message: 'У вас не достаточно прав на использование шаблонов отчётных форм. Обратитесь к администратору.',
            theme: 'danger',
          });
          return;
        }

        setListPopover(result);
        setIsOpenedPopover(true);
      } else {
        showNotification({
          message: 'У вас не достаточно прав на использование шаблонов отчётных форм. Обратитесь к администратору.',
          theme: 'danger',
        });
      }
    }
  };

  const closePopover = () => {
    setIsOpenedPopover(false);
    setListPopover([]);
  };

  const button = (
    <>
      <button
        ref={refButton || buttonRef}
        type="button"
        className={b({ mode, 'is-hidden': isHidden }).mix(classMixin)}
        title={title}
        onClick={handleClick}
        disabled={isDisabled}
        {...{ 'data-icon': !!icon, 'data-text': !!text, 'data-icon-type': icon?.type || '', 'data-icon-mode': icon?.mode || '' }}
        {...(restProps as ButtonAttributes)}
        tabIndex={-1}
        style={style}
      >
        {!!icon && <Icon {...icon} />}
        {!!text && <span>{text}</span>}
        {children && <span>{children}</span>}
      </button>

      {!!listPopover.length && (
        <Popover
          isOpen={isOpenedPopover}
          onClose={closePopover}
          anchorRef={buttonRef.current}
          position={{
            offset: 0,
            anchor: {
              vertical: 'top',
              horizontal: 'right',
            },
            content: {
              vertical: 'top',
              horizontal: 'left',
            },
          }}
        >
          <div className={b('list')} onClick={closePopover}>
            {listPopover}
          </div>
        </Popover>
      )}
    </>
  );

  if (permission) {
    const key = [
      mode,
      [...(!!icon ? icon.type : [])],
      [...(!!icon && icon.mode ? icon.mode : [])],
      [...(classMixin ? classMixin : [])],
    ]
      .join('_')
      .replaceAll(' ', '-');

    return (
      <Permission key={key} {...permission}>
        {button}
      </Permission>
    );
  }

  return button;
});

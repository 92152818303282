import { Parameter, ParameterListValue } from 'types/models';
import { PARTIAL_RADIO_VALUE, ALL_RADIO_VALUE } from './DefaultParameterList/helpers';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateParameterList = (parameter: Parameter): ValidatorResult => {
  const { required } = parameter;
  const value = parameter.value as ParameterListValue;

  if (required) {
    const isRadio = Boolean(value.radioValue);

    const isValid = isRadio
      ? !!(value.radioValue === PARTIAL_RADIO_VALUE && value.list.length) || value.radioValue === ALL_RADIO_VALUE
      : Boolean(value.list.length);

    return {
      isValid,
      message: isValid ? '' : `Необходимо добавить хотя бы один элемент в список поля "${parameter.description}"`,
    };
  }

  return { isValid: true, message: '' };
};

export default validateParameterList;

import React, { useState } from 'react';

import { ProjectCode } from 'types/models/Project';
import { FormComponent, ListEditTable, ReferenceItem } from 'components';

type Props = {
  projectCode: ProjectCode | null;
};

export function ReceivingDocuments({ projectCode }: Props) {
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  return (
    <FormComponent.Wrapper>
      <ListEditTable
        rows={projectCode?.receivingDocuments || []}
        defaultRowsCount={4}
        selectRow={setSelectedRowIndex}
        selectedRowIndex={selectedRowIndex}
        columnIndexesForSumTotal={[3, 4]}
        columns={[
          {
            label: '№',
            formatValue: (_, index) => index + 1,
            styles: { width: '5%', textAlign: 'center' },
          },
          { label: 'Дата документа', formatValue: x => x.cashDocument?.payDate || '' },
          { label: 'Номер', formatValue: x => x.cashDocument?.number || '' },
          {
            label: 'Сумма документа, RUB',
            formatValue: x => x.amount || '',
            dataKind: 'float',
          },
          {
            label: 'В т.ч. НДС, RUB',
            formatValue: x => x.nds || '',
            dataKind: 'float',
          },
          { label: 'Организация', formatValue: x => x.cashDocument?.enterprise?.label || '' },
          {
            label: 'Адрес',
            formatValue: x =>
              [
                (x.cashDocument?.enterprise?.customFields?.country as ReferenceItem)?.label ?? '',
                x.cashDocument?.enterprise?.customFields?.district ?? '',
                x.cashDocument?.enterprise?.customFields?.city ?? '',
                x.cashDocument?.enterprise?.customFields?.street ?? '',
              ]
                .filter(Boolean)
                .join(', ') || '',
          },
        ]}
      />
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { ButtonMode, FormComponent, Header, ListEditTable, Modal, TextArea, TextAreaMode, Toolbar } from 'components';

import { Component as Block } from './block';
import { useController } from './controller';
// import { Statistic } from '../statistic';

import './style.scss';

type Props = {
  isHome?: boolean;
};

function Notices(props: Props) {
  const { isHome = false } = props;

  const {
    buttons,
    isConfirmPopupOpen,
    setIsConfirmPopupOpen,
    handleDeleteButtonClick,
    isAddModalOpen,
    setIsAddModalOpen,
    setAddModalCaption,
    setAddModalData,
    addModalButtons,
    addModalCaption,
    handleAddModalCaptionChange,
    addModalData,
    handleAddModalDataChange,
    isEditModalOpen,
    setIsEditModalOpen,
    editModalButtons,
    selectedRowIndex,
    editModalCaption,
    handleEditModalCaptionChange,
    editModalData,
    handleEditModalDataChange,
    list,
    getIndex,
    setSelectedRowIndex,
  } = useController();

  const notices = (
    <FormComponent.Template>
      <Toolbar buttons={buttons} classMixin="notices-toolbar" />

      <FormComponent.Wrapper>
        {list.map((notice: any) => (
          <Block props={notice} key={notice.id + notice.createdDate} />
        ))}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );

  return (
    <>
      {/* Hidden statistic for all (temporary) */}
      {isHome && false ? (
        // <FormComponent.ColumnWrapper>
        //   <FormComponent.Column style={{ width: '65%' }}>
        //     <Header>Объявления</Header>
        //     {notices}
        //   </FormComponent.Column>
        //   <FormComponent.Column style={{ width: '35%' }}>
        //     <Header>Статистика</Header>
        //     <Statistic />
        //   </FormComponent.Column>
        // </FormComponent.ColumnWrapper>
        <>
          <Header>Объявления</Header>
          {notices}
        </>
      ) : (
        notices
      )}

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpen}
        onClose={() => setIsConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleDeleteButtonClick,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmPopupOpen(false),
          },
        ]}
        size="small"
      >
        <>Удалить новость?</>
      </Modal>

      <Modal
        isOpen={isAddModalOpen}
        onClose={() => {
          setIsAddModalOpen(false);
          setAddModalCaption('');
          setAddModalData('');
        }}
        title="Добавление объявления"
        size={['large', 'medium-height']}
      >
        <FormComponent.Template>
          <Toolbar buttons={addModalButtons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Заголовок" isRequired>
                <TextArea settings={{ rows: 5 }} value={addModalCaption} onChange={handleAddModalCaptionChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Текст">
                <TextArea
                  mode={TextAreaMode.WYSIWYG}
                  settings={{ rows: 15, title: 'Текст' }}
                  value={addModalData}
                  onChange={handleAddModalDataChange}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      <Modal
        isOpen={isEditModalOpen}
        onClose={() => {
          setIsEditModalOpen(false);
        }}
        title="Редактирование списка объявлений"
        size={['large', 'medium-height']}
      >
        <FormComponent.Template>
          <Toolbar buttons={editModalButtons} mode="form" />

          <FormComponent.Wrapper>
            <FormComponent.Line>
              <FormComponent.Field label="Заголовок" isRequired>
                <TextArea
                  settings={{ rows: 5 }}
                  value={editModalCaption}
                  onChange={handleEditModalCaptionChange}
                  isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Текст">
                <TextArea
                  mode={TextAreaMode.WYSIWYG}
                  settings={{ rows: 15, title: 'Текст' }}
                  value={editModalData}
                  onChange={handleEditModalDataChange}
                  isDisabled={!(selectedRowIndex || selectedRowIndex === 0)}
                />
              </FormComponent.Field>
            </FormComponent.Line>

            <ListEditTable
              isStickyHeader={true}
              rows={list}
              columns={[
                { label: '№', formatValue: row => (getIndex(row.id) + 1).toString(), styles: { width: '5%' } },
                { label: 'Заголовок', formatValue: row => row.caption },
                { label: 'Дата', formatValue: row => row.createdDate, styles: { width: '15%' } },
              ]}
              selectedRowIndex={selectedRowIndex}
              selectRow={setSelectedRowIndex}
            />
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>
    </>
  );
}

export const Component = React.memo(Notices);

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';

type RequestData = {
  partnerId: string;
};

export const GetPartnerMipSecurityDocumentContractList = ({
  partnerId,
}: RequestData): Table.Specification<object, RequestData> => ({
  apiID: 'GetPartnerMipSecurityDocumentContractList',
  header: {
    firstLevel: {
      title: 'Список договоров на использование интеллектуальной собственности университета',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: Toolbar,
    },
  },
  requestData: { partnerId },
  isFiltersHidden: true,
});

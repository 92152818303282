import React, { useCallback, useMemo, useState } from 'react';

import { FormComponent, ListEdit } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Person } from 'types/models';
import { QnaAddTemplate } from 'features/Table/specifications/GetQnaQuestionList/LeftPanelForThirdLevel/modalTemplates';
import { Fields } from 'features/Form/looks/person/Tabs/AdditionalInfo/Identifiers/Fields';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { checkIsIdentifierLinkCorrect } from 'features/Form/looks/person/Tabs/AdditionalInfo/Identifiers/validate';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
  onChangeIdentifiers(honors: Person.Identifier[]): void;
};

export function Identifiers(props: Props) {
  const { person, disabled, onChangeIdentifiers } = props;

  const { userPermission } = useAppDataContext();

  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.Identifier>[]>(
    () => [
      {
        icon: { type: 'admin' },
        title: 'Задать вопрос',
        onClick: handleQuestionButtonClick,
      },
      {
        icon: { type: 'link' },
        title: 'Перейти по ссылке',
        onClick: identifier => {
          if (identifier) window.open(`${identifier.link}`, '_blank');
        },
        isDisabled: doc => doc === null || !doc.link,
      },
    ],
    [handleQuestionButtonClick],
  );

  return (
    <>
      {!isHasPermission(userPermission, Permits.PERSON_IDENTIFIER_AUTHOR_EDIT) && (
        <FormComponent.Description mode="warning">
          Таблица не доступна для редактирования. Для добавления или редактирования данных напишите письмо администратору
        </FormComponent.Description>
      )}

      <ListEdit
        rows={person?.identifiers ?? []}
        onChange={onChangeIdentifiers}
        toolbar={[
          { key: 'add', permission: { name: Permits.PERSON_IDENTIFIER_AUTHOR_EDIT } },
          { key: 'edit', permission: { name: Permits.PERSON_IDENTIFIER_AUTHOR_EDIT } },
          { key: 'delete', permission: { name: Permits.PERSON_IDENTIFIER_AUTHOR_EDIT } },
          ...extraToolbarButtons,
        ]}
        columns={[
          { label: 'Система цитирования', formatValue: row => row.citationSystem?.label || '' },
          { label: 'Идентификатор', formatValue: row => row.identifier },
          { label: 'Имя автора', formatValue: row => row.authorName ?? '' },
          { label: 'Ссылка на профиль автора в системе цитирования', formatValue: row => row.link ?? '' },
        ]}
        maxHeight="none"
        withMessages
        isDeleteConfirmEnabled
        isDisabled={disabled}
        specification={{
          mode: 'customComponent',
          onPreSubmit: checkIsIdentifierLinkCorrect,
          renderComponent: (identifier, setIdentifier) => <Fields identifier={identifier} onChange={setIdentifier} />,
        }}
      />

      <QnaAddTemplate.Component isOpen={isQuestionFormOpen} onClose={handleTemplateCloseQuestionForm} hasNoTableInteraction />
    </>
  );
}

import { useMemo, useCallback } from 'react';

import { ReferenceItem } from 'components';

import { JointEvent } from 'types/models';
import { Item } from 'types/models/common';

type Props = {
  document: JointEvent.Enterprise | null;
  onChange(row: JointEvent.Enterprise): void;
};

export function useController({ document, onChange }: Props) {
  const emptyRow = useMemo<JointEvent.Enterprise>(
    () => ({
      id: null,
      role: { label: '', value: '' },
      element: { id: '', label: '' },
      position: '',
    }),
    [],
  );

  const handleChangeOrganization = useCallback(
    (value: ReferenceItem) => {
      onChange({ ...(document || emptyRow), element: value });
    },
    [document, emptyRow, onChange],
  );

  const handleChangeRole = useCallback(
    (value: Item) => {
      onChange({ ...(document || emptyRow), role: value });
    },
    [document, emptyRow, onChange],
  );

  return {
    handleChangeRole,
    handleChangeOrganization,
  };
}

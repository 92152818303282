import React from 'react';

import { ListEdit, Modal } from 'components';

import { Program, Project } from 'types/models';
import { showErrorsMessages } from 'utils/Common';
import { Fields } from './Fields';
import { validate } from './validate';
import { AddIndicatorsModal } from './AddIndicatorsModal';
import { SelectStageModal } from './SelectStageModal';
import { useController } from './controller';
import { getMockEditableIndicator } from 'features/Form/looks/project/ProjectForm/helpers';
import { IndicatorKey, EditableIndicator } from 'features/Form/looks/project/ProjectForm/model';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  title: string;
  stages: Project.Stage[];
  disabled: boolean;
  setStages(stages: Project.Stage[]): void;
  program: Program.Program | null;
  type: IndicatorKey;
  indices: EditableIndicator[];
};

const Component: React.FC<Props> = (props: Props) => {
  const { title, stages, disabled, type, program, indices, setStages } = props;

  const {
    handleChangeIndicators,
    handleCopyIndicatorsFromProgram,
    handleSubmitAddIndicatorsModal,
    isOpenAddIndicatorsModal,
    isOpenSelectStageModal,
    toggleAddIndicatorsModal,
    contestRequest,
    setIsOpenSelectStageModal,
    isHelpFormOpen,
    setIsHelpFormOpen,
  } = useController({ setStages, stages, type, program });

  return (
    <>
      <ListEdit
        header={{ title }}
        rows={indices}
        onChange={handleChangeIndicators}
        toolbar={[
          {
            icon: { type: 'question' },
            title: 'Помощь',
            onClick: () => setIsHelpFormOpen(true),
          },
          'add',
          'edit',
          'delete',
          'duplicate',
          {
            icon: { type: 'relation' },
            title: 'Добавить из программы, указанной в проекте',
            onClick: toggleAddIndicatorsModal,
            isDisabled: disabled,
          },
        ]}
        columns={[
          { label: 'Год (этап)', formatValue: x => `${x.year} (этап ${x.stageNumber})`, styles: { minWidth: '110px' } },
          {
            label: type === 'indicators' ? 'Индикатор' : 'Показатель',
            formatValue: x => x.ref?.label ?? '',
            styles: { width: '50%' },
          },
          { label: 'Код', formatValue: x => (x.ref?.customFields?.code as string) ?? '', styles: { width: '5%' } },
          {
            label: 'План',
            formatValue: x =>
              x.plan
                ? `${(x.ref?.customFields?.units ?? '').toString().indexOf('чел') > -1 ? parseInt(x.plan) : x.plan} ${
                    x.ref?.customFields?.units ?? ''
                  }`
                : '',
            styles: { width: '10%' },
          },
          {
            label: 'Факт',
            formatValue: x =>
              x.fact
                ? `${(x.ref?.customFields?.units ?? '').toString().indexOf('чел') > -1 ? parseInt(x.fact) : x.fact} ${
                    x.ref?.customFields?.units ?? ''
                  }`
                : '',
            styles: { width: '10%' },
          },
          { label: 'Примечание', formatValue: x => x.note, styles: { width: '5%' } },
        ]}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        maxHeight="500px"
        isVisibleFilters
        defaultRowsCount={10}
        specification={{
          mode: 'customComponent',
          renderComponent: (indicator, setIndicator) => (
            <Fields
              indicator={indicator || getMockEditableIndicator()}
              setIndicator={setIndicator}
              stages={stages}
              type={type}
              programId={program?.id ?? ''}
            />
          ),
          validation: {
            checkIsValid: (indicator, index, list, mode) => validate(indicator, list, mode).every(x => x.isValid),
            onInvalidate: (indicator, mode, index, list) => {
              const validationInfo = validate(indicator, list, mode);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />
      <AddIndicatorsModal
        indicators={program?.[type] ?? []}
        isOpen={isOpenAddIndicatorsModal}
        onClose={toggleAddIndicatorsModal}
        stages={stages}
        onSubmit={handleSubmitAddIndicatorsModal}
        program={program}
        type={type}
      />
      <SelectStageModal
        isOpen={isOpenSelectStageModal}
        onClose={() => setIsOpenSelectStageModal(false)}
        stages={stages}
        onSubmit={handleCopyIndicatorsFromProgram}
        contestRequest={contestRequest}
      />

      <Modal
        mode="help"
        title="Особенности заполнения на вкладке: Индикаторы и показатели"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {ProjectHelp().resultsIndicators}
      </Modal>
    </>
  );
};

export const EditableIndicators = React.memo(Component) as typeof Component;

import { Icon } from 'components';
// eslint-disable-next-line max-len
import { ProjectStateRegistrationReportFiltersHelp } from 'features/Table/specifications/GetProjectStateRegistrationReportList/filters';

export function ProjectHelp(): Record<string, JSX.Element> {
  return {
    common: (
      <>
        <p>
          Форма для хранения информации о проекте состоит из вкладок, на которых размещается сгруппированные по смыслу наборы
          данных.
          <br />
          Видимость вкладки на форме и разрешённые действия над данными на ней (просмотр, редактирование, удаление) ограничиваются
          правами пользователя.
        </p>
        <p>
          Кнопка <Icon type="save" /> — сохраняет текущее состояние проекта и закрывает форму;
        </p>

        <div className="warning">
          <span>
            при переходе к следующей вкладке (для добавления руководителя, файлов документов, заполнения классификаторов и прочее)
            обязательно нажмите кнопку <Icon type="save" mode="add" /> — функция для промежуточного сохранения формы проекта.
          </span>
        </div>

        <p>
          <strong>Инструменты для коммуникаций между пользователями</strong>, которые добавляют информацию о проекте, являются:
        </p>

        <ul>
          <li>Журнал изменений в проекте;</li>
          <li>Журнал сообщений и возможность добавить сообщение;</li>
          <li>Функциональный блок Поручения по проекту.</li>
        </ul>

        <p>
          В{' '}
          <strong>
            Журнале изменений в проекте <Icon type="clock" /> система автоматически
          </strong>{' '}
          фиксирует изменения в ключевых данных (объём финансирования в календарном плане, сроки, источник финансирования и
          подразделения), на базе которого выполняется проект, а также кто и когда это сделал. Журнал носит информативный характер
          и выполняет функцию коммуникаций между сотрудниками, сопровождающими проект и финансовой группой.
          <br />
          Кнопка <strong>Добавить сообщение</strong> <Icon type="message" mode="add" /> даёт возможность пользователю добавить
          {/* eslint-disable-next-line max-len */}
          сообщение в Журнал сообщений проекта. Посмотреть журнал — на одноимённой вкладке. В таблице со списком проектов в
          столбце <strong>Сообщение</strong> отображается текст последнего добавленного сообщения.
          <br />В процессе подготовки к отчётности как правило, актуализируют данные о проектах, уточняют состав коллектива,
          заполняют разделы с классификаторами проекта. Чтобы проставить отметку, о том, что данные проекта актуализированы -
          Добавьте сообщение с темой <strong>Сведения актуализированы</strong>. Текст сообщения «Сведения актуализированы» будет
          отображаться в таблице со списком проектов в столбце Сообщение.
          <br />
          Кнопка <strong>Отчёты</strong> <Icon type="print" /> предназначена для построения отчёта по шаблону. При нажатии кнопки
          появится список доступных пользователю отчётов (у пользователя есть право на группу отчётов).
        </p>
      </>
    ),
    about: (
      <>
        <p>Форма содержит общую информацию о проекте.</p>

        <ol>
          <li>
            <strong>Программа и мероприятие программы</strong> – дополнительные аналитические характеристики Заказчика, часто
            используются для управленческой отчётности, анализа данных о проекте (примеры программ: РНФ, Приоритет-2030, ..)
          </li>
          <li>
            <strong>Номер проекта</strong> – это номер, который присваивается в соответствии с внутренним регламентом учётной
            политике по проектам. Дополнительный атрибут «Код» поможет группировать номера проектов по какому-либо признаку
            (например, условное обозначение для обособленных подразделений университета)
          </li>
          <li>
            <strong>Сроки проекта</strong> – это обязательные поля и являются ключевыми для других разделов. Поэтому любые
            изменения в датах проекта система будет фиксировать в журнале изменений проекта <Icon type="clock" />
          </li>
          <li>
            <strong>Сведения о руководителе и ответственном исполнителе</strong> (администратор проекта) доступны только для
            {/* eslint-disable-next-line max-len */}
            просмотра на этой вкладке. Для добавления информации о руководителе и ответственном исполнителе необходимо на вкладке{' '}
            <strong>Коллектив</strong>
          </li>
          <li>
            Раздел <strong>Проект выполняется на базе</strong> является ключевым для других разделов. Поэтому любые изменения
            система будет фиксировать в журнале. В разделе необходимо указать подразделение(я), которое является ответственным за
            проект в соответствии с правилами учётной политики
          </li>
          <li>
            <strong>Сведения о договоре включают:</strong> вид, статус, номер, дату и уровень конфиденциальности
          </li>
          <li>
            Проекты с уровнем конфиденциальности из категории <strong>«Закрытые данные»</strong> не отображаются в открытых
            списках: в личном кабинете пользователя и в функциональном блоке Поиск, Список научных проектов.{' '}
            <strong>Предупреждать в разделе с документами</strong> - если этот атрибут имеет значение <strong>Да</strong>, то в
            режиме просмотра или редактирования проекта при переходе на вкладку Документы система будет выдавать сообщение о
            конфиденциальности документов проекта. Текст сообщения можно отредактировать в справочнике Уровни конфиденциальности
          </li>
          <li>
            Раздел <strong>Заказчики</strong> – данные о заказчиках выбирать из одноимённого справочника (этот справочник
            загружается из системы бухгалтерского учёта). Так как в некоторых запросах и статистических отчётах требуется
            {/* eslint-disable-next-line max-len */}
            аналитика по типам заказчиков, то желательно, обращать внимание на то какую запись из справочника вы выбираете.{' '}
            <strong>Если сектор деятельности и тип организации отсутствует – добавьте</strong>
          </li>
          <li>
            <strong>Общий объём финансирования проекта</strong> – обязательный раздел, в котором необходимо указать Вид
            финансирования, Источник. Проект может иметь несколько источников финансирования:
            <br />
            <strong>ОИФ</strong> - основной источник финансирования;
            <br />
            <strong>СФ1</strong> – первый источник софинансирования;
            <br />
            <strong>СФ2</strong> – второй источник софинансирования;
            <br />
            <strong>Собственные средства, Индустриальный партнёр</strong> — дополнительные виды финансирования, которые не
            предполагают поступления средств на счёт университета, но обязательны по условиям договора. Значения в столбцах
            таблицы вычисляются автоматически как общая сумма из Календарного плана
          </li>
          <li>
            <strong>Календарный план</strong> финансирования: информация по этапам проекта заполняется после того как в разделе
            Финансирование проекта указаны виды финансирования и источники. <strong>Кнопка Сформировать календарный</strong> план
            запускает процедуру автоматического формирования календарного плана в соответствии с выбранным алгоритмом
            <div className="warning">
              <span>
                На форме добавления/редактирования этапа система позволяет внести сумму только по видам финансирования, указанным
                в разделе Финансирование проекта. На основе календарного плана составляется план государственной регистрации
                отчётов по этапам проекта, поэтому нельзя удалить этап, по которому уже запланирована госрегистрация.
              </span>
            </div>
          </li>
          <li>
            <strong>План финансирования по годам:</strong> данные в таблице автоматически формируются в соответствии с Календарным
            планом и содержат итоговые суммы по годам
          </li>
        </ol>

        <h4>Список отчётов, которые строятся по источникам финансирования:</h4>

        <strong>Управленческие отчеты по доходам от научных проектов</strong>
        <ul>
          <li>Доходы текущего и будущих периодов по шифрам проектов</li>
          <li>Отчёт Факультета по НИОКР за многолетний период</li>
          <li>Отчёт за период по НИОКР в разбивке источники финансирования и год</li>
          <li>Отчёт по организациям-партнёрам и соисполнителям научных проектов</li>
          <li>Отчёт по проектам за несколько лет с классификаторами</li>
          <li>Перечень НИОКР, млн. р.</li>
          <li>Перечень НИОКР, р.</li>
        </ul>

        <strong>1-Мониторинг 2023:</strong>
        <ul>
          <li>1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере</li>
          <li>Таблица 3.2.1. Выполненный объем работ (Суммы по календарному плану проекта)</li>
          <li>Таблица 3.2.3. Источники финансирования затрат на научные исследования и разработки</li>
          <li>Таблица 6.1. Распределение объема средств организации по источникам их получения и по видам деятельности</li>
        </ul>

        <strong>2-Наука 2023:</strong>
        <ul>
          <li>2-Наука(ИНВ)краткая. Раздел 4. Реализация приоритетов научно-технологического развития РФ</li>
          <li>2-Наука инвентарная. Раздел 6. Реализация приоритетов научно-технологического развития РФ</li>
          <li>Раздел 7. Затраты на научные исследования и разработки, тысяча рублей (с одним десятичным знаком)</li>
          <li>Раздел 8. Выполненный объем отдельных видов работ и услуг</li>
          <li>Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР</li>
          <li>Раздел 10. Источники финансирования внутренних затрат на научные исследования и разработки</li>
          <li>
            Раздел 11. Гранты, субсидии, конкурсное финансирование исследований и разработок, тысяча рублей (с одним десятичным
            знаком)
          </li>
        </ul>

        <strong>РЕПНИД:</strong>
        <ul>
          <li>Таблица 1. РЕПНИД. Источники финансирования работ и услуг</li>
          <li>Таблица 10. РЕПНИД. Выполнение НИР по областям знаний за год</li>
          <li>Таблица 9. ДРУГИЕ. Участие в выполнении ФЦП, финансируемых из средств ФБ, за год</li>
        </ul>

        <strong>1-инжиниринг:</strong>
        <ul>
          <li>1.1. Инжиниринговые услуги, оказанные собственными силами, по видам</li>
          <li>1.2. Инжиниринговые услуги, оказанные собственными силами, по видам экономической деятельности Заказчиков</li>
          <li>1.3. Инжиниринговые услуги, оказанные собственными силами, по тематическим направлениям</li>
        </ul>

        <p>
          <strong>Диссертационные советы:</strong>
          <br />
          Сведения о научных исследованиях для годового отчета по дисс. Советам
        </p>
      </>
    ),
    requests: (
      <>
        <p>
          Форма содержит всю информацию о предыстории проекта. На форме отображается краткая информация о заявке на проект (заявка
          на конкурс, заявка на внутренний конкурс или заявка по мобильности) или договоре о распоряжении интеллектуальным правом
          на объект(ы) интеллектуальной собственности.
        </p>
        <p>
          Если проект был создан на основе заявки/договора, то при создании автоматически копируются данные из заявки в проект.
        </p>
        <p>
          Если заявку указать на этой форме, то кнопка <Icon type="document" mode="arrowRight" /> вызывает процедуру копирования
          данных из заявки в проект. В строке История копирования отображается информация о том кто и когда скопировал данные из
          заявки в проект.
        </p>
        <p>
          Функциональные блоки Конкурсы, Заявки на продление, Заявки на актуализацию данных этапа проекта, Экспертиза заявок
          предназначен для сбора информации о проекте в электронном виде от руководителя проекта (ответственного исполнителя);
          предусмотрены инструменты для согласования и экспертизы заявок.
        </p>
        <p>
          Для некоторых проектов, например, по Приоритет-2030, может быть организована процедура для продления проекта на
          конкурсной основе с использованием функционального блока Заявки, Заявки на продление проекта.
        </p>
        <p>
          Для актуализации данных на следующий этап проводятся процедуры с использованием функционального блока Заявки, Заявка на
          актуализацию. Цели актуализации могут быть разными: сбор уточняющих данных о целях, задачах, составе коллектива,
          показателей результативности, возможно, сметы в электронном виде от руководителя проекта и последующий перенос
          согласованных данных в этап проекта.
        </p>
        <p>
          Раздел Заявки для актуализации данных этапа проекта и заявки на продление содержит список заявок, которые были созданы
          по этапам проекта, а также информацию о том кто, когда и какой блок информации скопировал из заявки в данные проекта.
        </p>

        <h4>Копирование данных из заявок в проект</h4>

        <p>
          Функционал предназначен для копирования одноименных полей и данных из{' '}
          <strong>Заявок на конкурс, Заявок на внутренний конкурс</strong> и <strong>Заявок на актуализацию и продления</strong> в
          проект. Для копирования необходимо выбрать заявку на конкурс или заявку на внутренний конкурс из справочника
          (примечание:
          {/* eslint-disable-next-line max-len */}
          программа заявки должна совпадать с программой проекта), далее нажать на кнопку{' '}
          <Icon type="document" mode="arrowRight" /> <strong>Скопировать данные из заявки в проект.</strong> В открывшемся окне
          выберете один или несколько пунктов для копирования.
        </p>
        <p>
          {/* eslint-disable-next-line max-len */}
          Для копирования данных из <strong>Заявки на актуализацию и продления</strong> необходимо выбрать нужную заявку из
          таблицы <strong>Заявки для актуализации данных этапа проекта и заявки на продление</strong> (для копирования доступны
          заявки на
          {/* eslint-disable-next-line max-len */}
          продление проекта в статусе – поддержана, для заявок на актуализацию данных в статусе - согласована) и нажать на кнопку{' '}
          <Icon type="document" mode="arrowRight" /> <strong>Скопировать из заявки на продление.</strong> В открывшемся окне
          выберете один или несколько пунктов для копирования.
        </p>
        <p>В зависимости от выбранной заявки, для копирования доступен свой набор полей.</p>

        <div className="warning" data-label="Внимание!">
          <span>При копировании имеющиеся значения из списка копирования в проекте будут удалены!</span>
        </div>

        <h3>Для копирования в проект из Заявок на конкурс доступны следующие данные:</h3>

        <strong>1. Описание проекта</strong>
        <p>
          Из заявки (вкладка Описание) в проект на вкладку <strong>Описание</strong> будут скопированы:
        </p>
        <ul>
          <li>Аннотация</li>
          <li>ГРНТИ</li>
          <li>Ключевые слова</li>
          <li>Критические технологии</li>
          <li>2-Наука (ОКВЭД, ЛКСЭЦ, Отрасль науки)</li>
          <li>Приоритетные направления развития науки, технологий и техники</li>
          <li>Приоритеты Стратегии НТР РФ</li>
          <li>Тема проекта</li>
          <li>УДК</li>
        </ul>

        <strong>2. Классификаторы</strong>
        <p>
          Из заявки (вкладка Классификаторы) в проект на вкладку <strong>Классификаторы</strong> будут скопированы:
        </p>
        <ul>
          <li>Области научных интересов</li>
          <li>Отрасли знаний (РНФ)</li>
          <li>Приоритетные направления развития модернизации и технического развития экономики России</li>
          <li>Приоритетные направления развития</li>
          <li>Технологические платформы</li>
        </ul>

        <strong>3. Список исполнителей</strong>
        <p>
          При запуске процедуры копирования в проект будут добавлены все участники коллектива, указанного в заявке. При добавлении
          участника копируются следующие данные из заявки:
        </p>
        <ul>
          <li>ФИО</li>
          <li>Сведения о работе/обучении, учёная степень, звание</li>
          <li>роль</li>
          <li>период работы (равный срокам проекта)</li>
          <li>отметка об участии во всех этапах проекта</li>
        </ul>

        <h3>Для копирования в проект из Заявок на внутренний конкурс доступны следующие данные:</h3>

        <strong>1. Описание проекта</strong>
        <p>
          Из заявки (вкладка Описание) на вкладки <strong>Описание</strong> и <strong>Классификаторы</strong> будут скопированы:
        </p>

        <h4>Копирование Описание из заявки в проект:</h4>
        <ul>
          <li>Название проекта</li>
          <li>Цель исследования &rarr; Цель</li>
          <li>Характер исследования &rarr; Характер проекта</li>
          <li>Аннотация исследования &rarr; Аннотация</li>
          <li>
            Планируемые научные результаты исследования за первый год + Планируемые научные результаты исследования за второй год
            &rarr; Ожидаемые результаты (с заголовками с новой строки «...год» «... год»)
          </li>
          <li>Имеющийся научный задел по теме &rarr; Научный задел</li>
          <li>Обоснование междисциплинарного подхода (на вкладке Заявка) &rarr; Обоснование междисциплинарного подхода</li>
          <li>Ключевые слова &rarr; Ключевые слова</li>
        </ul>

        <h4>Копирование Классификаторов из заявки в проект:</h4>
        <ul>
          <li>Приоритеты Стратегии НТР РФ</li>
          <li>Критические технологии</li>
          <li>УДК</li>
          <li>ГРНТИ</li>
          <li>OECD (Коды международной классификации отраслей наук)</li>
          <li>Приоритетные направления Программы развития</li>
          <li>Приоритетные направления развития науки, технологий и техники</li>
        </ul>

        <strong>2. Описание этапов</strong>
        <p>
          Данные будут скопированы из заявки (вкладка Описание проекта) на форму редактирования этапа на вкладку{' '}
          <strong>Этапы:</strong>
        </p>
        <ul>
          <li>Название этапа &rarr; Название этапа из раздела "Календарный план и финансирование проекта"</li>
          <li>Аннотация работ &rarr; Аннотация</li>
          <li>Цель этапа НИР &rarr; Цели</li>
          <li>Задачи этапа &rarr; Задачи исследования на первый год (вкладка Описание)</li>
          <li>Ожидаемые результаты &rarr; Планируемые научные результаты исследования за первый год</li>
          <li>Эффект на уровне университета &rarr; Эффект на уровне университета</li>
          <li>Эффект на уровне региональном &rarr; Эффект на уровне региональном</li>
          <li>Эффект на уровне национальном &rarr; Эффект на уровне национальном</li>
        </ul>

        <strong>3. Индикаторы и показатели</strong>
        <p>
          Плановые значения показателей и индикаторов на этап будут скопированы в раздел на вкладке <strong>Результаты.</strong>
        </p>
        <p>Формат копирования Индикатора:</p>
        <ul>
          <li>название индикатора</li>
          <li>этап</li>
          <li>плановое значение по индикатору из заявки</li>
        </ul>
        <p>Формат копирования Показателя:</p>
        <ul>
          <li>название показателя</li>
          <li>этап</li>
          <li>плановое значение по показателю из заявки</li>
        </ul>

        <strong>4. Список исполнителей</strong>
        <p>
          При запуске процедуры копирования в проект будут добавлены все участники коллектива, указанного в заявке. При добавлении
          участника копируются следующие данные из заявки:
        </p>
        <ul>
          <li>ФИО</li>
          <li>Сведения о работе/обучении, учёная степень, звание</li>
          <li>роль</li>
          <li>период работы (равный срокам проекта)</li>
          <li>отметка об участии во всех этапах проекта</li>
        </ul>

        <div className="warning" data-label="Внимание!">
          <span>
            В случае если в проекте уже присутствует часть коллектива, то при копировании данные будут перезаписаны (роль,
            сведения о работе/обучении, учёная степень, звание, период работы, отметка об участии во всех этапах проекта)
          </span>
        </div>

        <h3>Для копирования в проект из Заявок на актуализацию и продления доступны следующие данные:</h3>

        <strong>1. Описание этапа</strong>
        <p>
          Данные будут скопированы на форму редактирования этапа на вкладке <strong>Календарный план.</strong>
        </p>
        <ul>
          <li>Название этапа</li>
          <li>Аннотация работ</li>
          <li>Цель этапа НИР &rarr; Цели этапа</li>
          <li>План работ на этап (задачи) &rarr; Задачи этапа</li>
          <li>Ожидаемые результаты по этапу &rarr; Ожидаемые результаты</li>
          <li>Дополнительная информация (описание работ) &rarr; Описание работ</li>
          <li>Планируется охрана РИД</li>
          <li>Эффект на уровне университета</li>
          <li>Эффект на уровне региональном</li>
          <li>Эффект на уровне национальном</li>
        </ul>

        <strong>2. Индикаторы и показатели</strong>
        <p>
          Плановые значения показателей и индикаторов на этап будут скопированы в раздел на вкладке&rarr;
          <strong>Результаты</strong> в формате:
        </p>
        <ul>
          <li>индикатор</li>
          <li>этап</li>
          <li>плановое значение из заявки</li>
        </ul>

        <strong>3. Список исполнителей</strong>
        <p>
          В проект будут добавлены все имеющиеся исполнители в заявке на вкладке <strong>Коллектив</strong>, которых нет среди
          исполнителей в проекте с указанной ролью.
        </p>
        <p>При копировании участника добавляются следующие данные из заявки:</p>

        <ul>
          <li>ФИО</li>
          <li>период работы (равный срокам этапа)</li>
          <li>Участие в этапе</li>
        </ul>
      </>
    ),
    features: (
      <>
        <p>Форма содержит дополнительную информацию, связанную с финансовым сопровождением проекта.</p>
        <p>
          <strong>Вид деятельности, ОКВЭД по доходам, Расход бюджета, Код расходов Нацпроекта</strong> – это значения из
          справочников университета, которые служат для планирования расходов хозяйственной деятельности университета.
        </p>
        <p>
          <strong>Нормативный процент накладных расходов</strong> – это доля накладных расходов от объёма финансирования проекта
          по условиям договора. Учитывается при составлении сметы и расчёта фонда накладных расходов плановый и фактический объём.
        </p>
        <p>
          Раздел <strong>Распределение средств проекта по шифрам бухгалтерского учёта</strong> содержит информацию о плановом
          объёме, текущих расходах в соответствии с данными бухгалтерского учёта. Для проекта в системе бухгалтерского учёта может
          быть заведено несколько шифров:
        </p>

        <ul>
          <li>если проект имеет софинансирование, то для каждого источника финансирования свой шифр;</li>
          <li>
            если проект выполняется смешанным коллективом (несколько подразделений), то для ведения раздельного учёта для каждого
            подразделения заводится шифр с выделением доли от объёма финансирования.
          </li>
        </ul>
      </>
    ),
    description: (
      <>
        <p>
          Форма предназначена для описания темы проекта. Информация о проекте на этой вкладке необходима для государственной
          регистрации проектов в Единой государственной информационной системе учета результатов научно-исследовательских и
          опытно-конструкторских работ (ЕГИСУ НИОКТР) гражданского назначения.
        </p>

        <div className="warning">
          <span>
            Проекты, которые подлежат госрегистрации должны быть внесены в ЕГИСУ в течение 30 дней с даты заключения договора
            (соглашения).
          </span>
        </div>

        <p>
          Для традиционного тематического описания (аннотация, цели, ожидаемые результаты, ключевые слова, научный задел)
          предназначены многострочные текстовые поля. Кнопка <Icon type="view" /> открывает новое окно, в котором отображается всё
          содержимое поля — это удобно для чтения текста и проверки корректности. Для удаления невидимых символов (перенос строки,
          который часто остаётся в тексте, если его копировать из другого файла) предназначена кнопка <Icon type="erase" />
        </p>
        <p>
          <strong>Характер проекта</strong> – это ключевой параметр для формирования практически всех основных отчётных форм.{' '}
          <strong>Вид исследования</strong> - это дополнение к некоторым видам из справочника Характер проекта. Вид исследования
          используется при регистрации проекта в ЕГИСУ.
        </p>
        <p>Кроме того, тему необходимо описать в разрезе классификаторов.</p>
        <p>
          При заполнении разделов:{' '}
          <strong>
            Приоритетные направления развития науки, технологий и техники, Приоритеты Стратегии НТР РФ, Критические технологии,
            Классификатор ОЭСР (ОЕСD)
          </strong>{' '}
          – значения выбираются из соответствующих справочников - общероссийских классификаторов. По умолчанию, в справочниках
          отображается список актуальных на текущий год значения (включен фильтр Актуальные).
        </p>
        <p>Работа выполняется в рамках – специфический справочник – аналог справочника ЕГИСУ.</p>
        <p>
          При заполнении разделов УДК, ГРНТИ используются справочники - списки часто используемых кодов ГРНТИ/ УДК в проектах
          университета. Если вы не нашли значения в списке, то добавьте его в список (у пользователя должно быть право на
          добавление значения в справочник). Подбор кодов ГРНТИ и УДК — на соответствующих сайтах.
        </p>

        <div className="warning">
          <span>
            В разрезе классификаторов строятся основные статистические отчёты по научной деятельности. Значений классификатора
            может быть несколько, описывающих тему, но при построении отчётов используется то, которое стоит в списке на первой
            позиции. Поэтому при заполнении для перемещения на другую позицию в списке вы можете использовать кнопки{' '}
            <Icon type="arrowUp" />
            <Icon type="arrowDown" />.
          </span>
        </div>

        <h3>Обязательные параметры для построения отчетов</h3>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Параметр</th>
              <th>Название отчета</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Характер проекта</td>
              <td>
                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>1.3. Инжиниринговые услуги, оказанные собственными силами, по тематическим направлениям</li>
                  <li>4.1. Объем выполненных собственными силами прикладных научных исследований по тематическим направлениям</li>
                </ul>

                <strong>1-Мониторинг 2023:</strong>
                <ul>
                  <li>1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере</li>
                  <li>Таблица 3.2.1. Выполненный объем работ (Суммы по календарному плану проекта)</li>
                  <li>Таблица 3.2.2. Исследования и разработки по ПНР</li>
                  <li>Таблица 3.2.3. Источники финансирования затрат на научные исследования и разработки</li>
                  <li>
                    Таблица 6.1. Распределение объема средств организации по источникам их получения и по видам деятельности
                  </li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>2-Наука(ИНВ)краткая. Раздел 4. Реализация приоритетов научно-технологического развития РФ</li>
                  <li>2-Наука инвентарная. Раздел 6. Реализация приоритетов научно-технологического развития РФ</li>
                  <li>Раздел 7. Затраты на научные исследования и разработки, тысяча рублей (с одним десятичным знаком)</li>
                  <li>Раздел 8. Выполненный объем отдельных видов работ и услуг</li>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                  <li>Раздел 10. Источники финансирования внутренних затрат на научные исследования и разработки</li>
                  <li>
                    Раздел 11. Гранты, субсидии, конкурсное финансирование исследований и разработок, тысяча рублей (с одним
                    десятичным знаком)
                  </li>
                  <li>Раздел 12. Внутренние затраты на научные исследования и разработки по социально-экономическим целям</li>
                </ul>

                <strong>РЕПНИД:</strong>
                <ul>
                  <li>Таблица 10. РЕПНИД. Выполнение НИР по областям знаний за год</li>
                  <li>Таблица 11. ДРУГИЕ. Выполнение НИР по ПНР науки, технологий и техники за год</li>
                  <li>Таблица 9. ДРУГИЕ. Участие в выполнении ФЦП, финансируемых из средств ФБ, за год</li>
                </ul>

                <strong>Диссертационные советы:</strong>
                <ul>
                  <li>Сведения о научных исследованиях для годового отчета по дисс. Советам</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>ГРНТИ</td>
              <td>
                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>1.1. Инжиниринговые услуги, оказанные собственными силами, по видам</li>
                  <li>
                    1.4. Затраты на оплату услуг привлеченных сторонних организаций при выполнении соглашений об оказании
                    инжиниринговых услуг
                  </li>
                  <li>4.1. Объем выполненных собственными силами прикладных научных исследований по тематическим направлениям</li>
                </ul>

                <strong>1-Мониторинг 2023:</strong>
                <ul>
                  <li>1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                </ul>

                <strong>РЕПНИД:</strong>
                <ul>
                  <li>Таблица 10. РЕПНИД. Выполнение НИР по областям знаний за год</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Приоритеты Стратегии НТР РФ</td>
              <td>
                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>1.1. Инжиниринговые услуги, оказанные собственными силами, по видам</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>2-Наука(ИНВ)краткая. Раздел 4. Реализация приоритетов научно-технологического развития РФ</li>
                  <li>2-Наука инвентарная. Раздел 6. Реализация приоритетов научно-технологического развития РФ</li>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Приоритетные направления развития науки, технологий и техники</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов</strong>
                <ul>
                  <li>Отчёт по проектам за несколько лет с классификаторами</li>
                </ul>

                <strong>1-Мониторинг 2023:</strong>
                <ul>
                  <li>Таблица 3.2.2. Исследования и разработки по ПНР</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                </ul>

                <strong>РЕПНИД:</strong>
                <ul>
                  <li>Таблица 11. ДРУГИЕ. Выполнение НИР по ПНР науки, технологий и техники за год</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Критические технологии</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов</strong>
                <ul>
                  <li>Отчёт по проектам за несколько лет с классификаторами</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Классификатор ОЭСР (ОЕСD)</td>
              <td>
                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>1.1. Инжиниринговые услуги, оказанные собственными силами, по видам</li>
                  <li>
                    1.4. Затраты на оплату услуг привлеченных сторонних организаций при выполнении соглашений об оказании
                    инжиниринговых услуг
                  </li>
                  <li>4.1. Объем выполненных собственными силами прикладных научных исследований по тематическим направлениям</li>
                </ul>

                <strong>1-Мониторинг 2023:</strong>
                <ul>
                  <li>1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>
                    Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Отрасль науки + Область науки</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов:</strong>
                <ul>
                  <li>Отчёт ПЛАН НИР на период</li>
                  <li>Отчёт по проектам за несколько лет с классификаторами</li>
                </ul>

                <strong>1-Мониторинг 2023:</strong>
                <ul>
                  <li>Таблица 3.2.3. Источники финансирования затрат на научные исследования и разработки</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>Раздел 7. Затраты на научные исследования и разработки, тысяча рублей (с одним десятичным знаком)</li>
                  <li>Раздел 8. Выполненный объем отдельных видов работ и услуг</li>
                  <li>Раздел 10. Источники финансирования внутренних затрат на научные исследования и разработки</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>ОКВЭД</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов:</strong>
                <ul>
                  <li>Отчёт ПЛАН НИР на период</li>
                </ul>

                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>
                    1.2. Инжиниринговые услуги, оказанные собственными силами, по видам экономической деятельности Заказчиков
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>ЛКСЭЦ</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов:</strong>
                <ul>
                  <li>Отчёт ПЛАН НИР на период</li>
                </ul>

                <strong>2-Наука 2023:</strong>
                <ul>
                  <li>Раздел 12. Внутренние затраты на научные исследования и разработки по социально-экономическим целям</li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>Ключевые слова</td>
              <td>
                <strong>Управленческие отчеты по доходам от научных проектов:</strong>
                <ul>
                  <li>Отчёт ПЛАН НИР на период</li>
                </ul>

                <strong>1-инжиниринг:</strong>
                <ul>
                  <li>1.1. Инжиниринговые услуги, оказанные собственными силами, по видам</li>
                  <li>
                    1.2. Инжиниринговые услуги, оказанные собственными силами, по видам экономической деятельности Заказчиков
                  </li>
                  <li>1.3. Инжиниринговые услуги, оказанные собственными силами, по тематическим направлениям</li>
                  <li>
                    1.4. Затраты на оплату услуг привлеченных сторонних организаций при выполнении соглашений об оказании
                    инжиниринговых услуг
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <td>УДК</td>
              <td>Сведения для регистрационной карты проекта и отчета НИОКТР (для ЕГИСУ)</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
    classifiers: (
      <>
        <p>Форма предназначена для описания темы проекта в разрезе различных классификаторов.</p>
        <p>
          При заполнении разделов значения выбираются из соответствующих справочников - общероссийских классификаторов. По
          умолчанию в справочниках отображается список актуальных значений на текущий год (включен фильтр Актуальные).
        </p>

        <div className="warning">
          <span>
            В разрезе классификаторов строятся статистические и управленческие отчёты по научной деятельности. Значений
            классификатора, описывающих тему, может быть несколько, но при построении отчётов используется то, которое стоит в
            списке на первой позиции. Поэтому при заполнении для перемещения на другую позицию в списке вы можете использовать
            кнопки <Icon type="arrowUp" /> <Icon type="arrowDown" />.
          </span>
        </div>

        <p>
          <strong>Приоритетные направления развития</strong> - используется для анализа данных для принятия управленческих решений
          и мониторинга выполнения ключевых показателей стратегии и программы развития университета.
        </p>
        <p>
          <strong>Отрасли знаний (РНФ)</strong> – используется при отчётности по программе РНФ.
        </p>
        <p>
          <strong>Номенклатура научных специальностей, по которым присуждаются ученые степени (Специальности ВАК)</strong> – в
          управленческом анализе данных по диссертационным советам.
        </p>
        <p>
          <strong>Области научных интересов</strong> – справочник, который используется при описании конкурсов, персон, заявок и
          проектов, предназначен для управленческого учёта и поиска ресурсов по разным направлениям.
        </p>
        <p>
          <strong>Приоритетные направления развития</strong> используется в Отчёте по проектам за несколько лет с
          классификаторами.
        </p>
      </>
    ),
    registrationProject: (
      <>
        <p>
          Для регистрации проекта в Единой государственной информационной системе учета научно-исследовательских,
          опытно-конструкторских и технологических работ гражданского назначения (ЕГИСУ НИОКТР) вся необходимая информация
          расположена на вкладках: <strong>О проекте, Описание, Коллектив</strong>.
        </p>
        <p>
          Все данные, необходимые для регистрации и приведённые к форматам ЕГИСУ, пользователь может получить в виде отчёта
          "Сведения для регистрационной карты и отчёта НИОКТР (для ЕГИСУ)".
        </p>
        <p>
          Поскольку в ЕГИСУ описательные поля (аннотация, научный задел, обоснование междисциплинарного подхода) ограничены по
          количеству символов и поэтому могут отличаться от аналогичных полей на вкладке Описание, то рекомендуется эту форму
          использовать для согласования текстов с руководителем проекта и хранения содержания описательных полей, занесённые в
          ЕГИСУ.
        </p>

        <div className="warning">
          <span>
            Прежде, чем построить отчёт, сохраните проект, используя кнопку <strong>"Сохранить и продолжить"</strong>{' '}
            <Icon type="save" mode="add" />. Для построения отчёта служит кнопка <strong>"Отчёты"</strong> <Icon type="print" />.
          </span>
        </div>

        <p>
          В разделе <strong>Документы из ЕГИСУ</strong> отображается список прикреплённых на вкладке Документы файлов, у которых
          указан тип документа= из ЕГИСУ. Файл можно скачать по кнопке <Icon type="download" />.
        </p>
      </>
    ),
    registrationReports: (
      <>
        <h4>График госрегистрации отчётов</h4>
        <p>Раздел «График госрегистрации отчётов» предназначен для:</p>
        <ul>
          <li>контроля сроков подготовки и госрегистрации научных отчётов проекта.</li>
          <li>ведения учёта отчётов, подлежащих регистрации в ЕГИСУ.</li>
        </ul>
        <p>
          Таблица содержит список всех отчётов с атрибутом «госрегистрация». Основные столбцы таблицы содержат данные отчёта,
          названия столбцов соответствуют названиям полей на форме отчёта.
        </p>
        <p>
          Дополнительные столбцы содержат данные из проекта: значения классификаторов тематики; из этапа проекта – аннотация,
          ключевые слова, результаты.
        </p>
        <p>
          Дополнительные столбцы таблицы предназначены для поиска отчётов по ключевым словам в разрезе отраслей наук, тематических
          групп и приоритетных направлений исследований.
        </p>
        {ProjectStateRegistrationReportFiltersHelp}
        <div className="warning">
          <ul>
            <li>
              Функционал для добавления отчётов по этапам проекта в план-график госрегистрации находится на вкладке Проекты —{' '}
              <strong>Составить график госрегистрации отчётов проекта</strong>.
            </li>
            <li>
              Дата окончания этапа проекта является ключевой для подсистемы мониторинга подготовки отчёта и соблюдения графика
              регистрации отчётов. За 30 дней, 10 и 3 дня до окончания этапа на электронную почту и в личный кабинет руководителю
              и ответственному исполнителю автоматически будут направлено уведомление. Посмотреть список отправленных уведомлений
              — в пункте <strong>меню Проекты, Список уведомлений</strong>.
            </li>
          </ul>
        </div>
      </>
    ),
    executorsComposition: (
      <>
        <p>Таблица Участники научного коллектива содержит консолидированную информацию о составе коллектива проекта, включая:</p>
        <ul>
          <li>персональные данные;</li>
          <li>сведения из приказов о включении в состав коллектива и исключении из него;</li>
          <li>информацию о периодах оплаты работы по данным бухгалтерского учёта;</li>
          <li>сведения о работе и обучении в период проекта;</li>
          <li>информацию об участии в других проектах в период работы в данном проекте.</li>
        </ul>

        <p>Для контроля соответствия требованиям Заказчика к составу коллектива служат:</p>
        <ul>
          <li>информационная строка над таблицей, которая отражает количественные показатели на текущую дату;</li>
          <li>
            столбец Работа в других проектах – отображается в формате: &lt;номер проекта&gt; (&lt;роль в проекте&gt; &lt;период
            работы в проекте&gt;);
          </li>
          <li>Фильтры: До 39 лет и Состав коллектива на текущую дату;</li>
          <li>
            подсветка строки при условии, что на текущую дату участник не числится в составе коллектива, но проект продолжается.
          </li>
        </ul>

        <div className="warning">
          <span>
            в коллективе необходимо и достаточно указать участника в роли «Руководитель»; совокупный период работы должен быть
            равным срокам проекта. Участник в роли «Ответственный исполнитель» выполняет административную функцию в проекте, если
            ответственный исполнитель отсутствует в составе коллектива, то функцию администратора выполняет руководитель. Контакты
            руководителя и ответственного исполнителя отображаются на вкладке <strong>О проекте</strong> и в соответствующих
            столбцах таблиц с данными о проекте.
          </span>
        </div>

        <h4>Назначение функциональных кнопок</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>
                Открыть форму с таблицей, в которой отображаются сведения о трудоустройстве члена коллектива в периодах работы в
                проекте.
              </td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>
                Открыть форму для добавления члена коллектива. Важно, чтобы данные о гражданстве, учёной степени, звании,
                должности (место работы), обучении и актуальные ФИО соответствовали периоду работы в проекте. Любые изменения
                оформляются приказами об исключении и включении в проект с новыми данными. В столбце таблицы{' '}
                <strong>Есть изменения в данных</strong>
                отображается информация об изменениях в рамках проекта.
              </td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Открыть форму с таблицей, в которой отображаются сведения о трудоустройстве члена коллектива в периодах работы на
                проекте; предназначена для добавления, удаления или редактирования данных члена коллектива по периодам работы в
                проекте.
              </td>
            </tr>
            <tr>
              <td>
                Персональные данные: <Icon type="clock" />
              </td>
              <td>
                На форме редактирования предназначена для выбора и сохранения набора персональных данных участника для конкретного
                периода работы в проекте.
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                <p>Предназначена для удаления записи из списка участников коллектива.</p>
                <div className="warning">
                  <span>
                    Если есть выплаты за счёт средств проекта (хотя бы за 1 месяц), то удалить эту запись невозможно. Необходимо
                    сообщить в службу финансового сопровождения для принятия управленческого решения.
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Оформить периоды работы по приказу: <Icon type="merge" mode="info" />
              </td>
              <td>
                Позволяет внести информацию о приказах по составу коллектива на период для выделенной группы участников, а также
                изменить даты периода.
              </td>
            </tr>
            <tr>
              <td>
                Отчёты: <Icon type="print" />
              </td>
              <td>
                <p>Служит для формирования отчётов по составу коллектива за период.</p>
                <div className="warning">
                  <span>
                    На форме Параметры отчёта кнопка <strong>"Описание отчёта"</strong> открывает файл, с описанием правил, по
                    которым формируется отчёт.
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Формирует файл .xls с таблицей данных как на экране.</td>
            </tr>
          </tbody>
        </table>

        <h4>Особенности представления информации в таблице</h4>

        <p>
          Фильтр <strong>до 39 лет</strong> – отображает список участников коллектива, у которых на текущую дату возраст меньше
          чем 40 лет.
        </p>
        <p>
          Фильтр <strong>Состав коллектива на текущую дату</strong> — отображает список участников коллектива, у которых дата
          окончания периода работы меньше чем текущая дата и проект не завершён.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>ФИО</td>
              <td>
                Фамилия Имя Отчество – текущее значение, если были изменения в ФИО, то в скобках будет выводиться предыдущие
                значения. Источник данных — персональные данные из справочника «Персоны».
              </td>
            </tr>
            <tr>
              <td>Роль</td>
              <td>
                <p>Роль в проекте и период работы в проекте.</p>

                <div className="warning">
                  <span>
                    <ul>
                      <li>периодов работы в проекте может быть несколько, но они не должны пересекаться;</li>
                      <li>в указанный период участник коллектива может иметь только одну роль.</li>
                    </ul>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Дата рождения</td>
              <td>Дата рождения персоны. Источник данных — персональные данные из справочника «Персоны».</td>
            </tr>
            <tr>
              <td>Возраст</td>
              <td>Динамически вычисляемое значение «полных лет» по состоянию на текущую дату.</td>
            </tr>
            <tr>
              <td>Степень, звание</td>
              <td>
                Название степени и звания, которые указаны на форме персональные данные для конкретного периода работы в проекте.
                Если периодов несколько и персональные данные в периодах различаются, то выводится список всех значений.
              </td>
            </tr>
            <tr>
              <td>Должности</td>
              <td>
                <p>
                  Название должности, название подразделения (название организации, динамически вычисляемый статус:
                  Сотрудник/Сотрудник другой организации). Источник данных — персональные данные, указанные{' '}
                  <strong>для периода работы в проекте</strong>. Если периодов несколько и персональные данные в периодах
                  различаются, то выводится список всех значений.
                </p>

                <div className="warning">
                  <span>
                    <ul>
                      <li>для сотрудников — для должности выводится название категории должности;</li>
                      <li>если работа по договору ГПХ, то для должности выводится информация о договоре;</li>
                      <li>в период проекта участнику коллектива назначается конкретная должность.</li>
                    </ul>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Обучение</td>
              <td>
                <p>
                  Статус обучающегося, Код и название специальности (краткое название организации, в которой обучается участник
                  коллектива), название подразделения. Источник данных — персональные данные, указанные для периода работы в
                  проекте. Если периодов несколько и персональные данные в периодах различаются, то выводится список всех
                  значений.
                </p>

                <div className="warning">
                  <span>в период проекта участнику коллектива указывается только конкретное обучение.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Контакты</td>
              <td>
                <p>Источник данных — персональные данные из справочника «Персоны».</p>

                <div className="warning">
                  <span>если у пользователя нет права на просмотр контактов, то отображаются «***».</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>СНИЛС/ИНН</td>
              <td>
                <p>Источник данных — персональные данные из справочника «Персоны».</p>

                <div className="warning">
                  <span>если у пользователя нет права на просмотр СНИЛС/ИНН, то отображаются «***».</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Гражданство</td>
              <td>
                Источник данных — персональные данные, указанные для периода работы в проекте. Если периодов несколько и
                персональные данные в периодах различаются, то выводится список всех значений.
              </td>
            </tr>
            <tr>
              <td>Этапы</td>
              <td>
                Динамически вычисляется по периодам работы в проекте. Информация выводится в формате: номер этапа (дата
                начала-дата окончания).
              </td>
            </tr>
            <tr>
              <td>Подтверждено</td>
              <td>
                Источник данных — сведения об оплате в формате: номер шифра проекта (финансовый лицевой счёт): список номеров
                месяцев года, в которых были выплаты заработной платы.
              </td>
            </tr>
            <tr>
              <td>Работа в других проектах</td>
              <td>
                Источник данные — проекты, в которых участник в составе коллектива, при условии, что период работы в другом
                проекте пересекается со сроками текущего проекта.
                <br />
                Информация выводится в формате: номер проекта: роль в проекте, период работы в проекте.
              </td>
            </tr>
            <tr>
              <td>Включение в состав</td>
              <td>Дата начала периода работы в проекте. Если периодов несколько, то из первого периода.</td>
            </tr>
            <tr>
              <td>Исключение из состава</td>
              <td>Дата окончания периода работы в проекте. Если периодов несколько, то из последнего периода.</td>
            </tr>
            <tr>
              <td>Есть изменения в данных</td>
              <td>
                Если периодов работы в проекте несколько и персональные данные различаются ( гражданство, должность, степень,
                звание, обучение), то в этом столбце отображается символ <Icon type="check" />.
              </td>
            </tr>
            <tr>
              <td>Создано</td>
              <td>ФИО пользователя, дата и время создания записи об участнике коллектива. Система запоминает</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
    executorsOrders: (
      <>
        <p>
          Форма содержит таблицу с приказами по коллективу проекта (фактически это часть каталога приказов по коллективу
          проектов).
        </p>
        <p>Запись о приказе содержит основные данные: номер, дату приказа, тему приказа.</p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Проект</td>
              <td>Данные проекта: номер проекта / номер госрегистрации проекта.</td>
            </tr>
            <tr>
              <td>Тип проекта</td>
              <td>Названия групп проектов (гранты, задания на НИР, и т.д.).</td>
            </tr>
            <tr>
              <td>Начало проекта</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Окончание проекта</td>
              <td>Дата окончания проекта.</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО руководителя.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>
                Краткое название программы, которая указана в проекте. Является дополнительным признаком для анализа данных по
                группам проектов.
              </td>
            </tr>
            <tr>
              <td>Номер приказа</td>
              <td>Номер приказа.</td>
            </tr>
            <tr>
              <td>Дата приказа</td>
              <td>Дата приказа.</td>
            </tr>
            <tr>
              <td>Тема приказа</td>
              <td>Тема приказа.</td>
            </tr>
            <tr>
              <td>Приказ</td>
              <td>Текст приказа.</td>
            </tr>
            <tr>
              <td>Количество</td>
              <td>Содержит количество участников проекта, у которых указан приказ в дате периода работы.</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
    partners: (
      <>
        <p>
          На вкладке <strong>Организации-соисполнители</strong> отображается информация, загруженная в систему на основе данных
          бухгалтерского учёта об оплате по договорам (расчёты с соисполнителем). Носит информативный характер, используется в
          некоторых отчётах, как подтверждённый факт сотрудничества в рамках проекта.
        </p>
        <p>
          На вкладке <strong>Организации-партнёры</strong> желательно указывать организации, с которыми заключён договор на
          выполнение услуг/работ или соглашение о сотрудничестве в рамках выполнения проекта. Информация по организациям-партнерам
          учитывается в отчётах (в т.ч. по международному сотрудничеству), при планировании расходов и (при необходимости) в
          данных для госрегистрации в ЕГИСУ НИОКТР.
        </p>
        <p>
          На вкладке <strong>Международное сотрудничество</strong> рекомендуется указать сведения о международных компаниях, или
          программах, к которым имеет отношение проект. Информация учитывается в отчётах по международному сотрудничеству.
        </p>

        <div className="warning">
          <span>
            Для всех категорий организаций обязательно должны быть указаны{' '}
            <strong>Страна, Сектор деятельности и Тип организации</strong>. Данную информацию для организации можно заполнить в
            справочнике. Без этих атрибутов организации отчёты не построятся.
          </span>
        </div>

        <h4>Список отчётов, которые строятся на основании данных этого раздела:</h4>

        <p>
          <strong>1-Мониторинг 2023:</strong> 1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере
        </p>
      </>
    ),
    planStages: (
      <>
        <p>
          {/* eslint-disable-next-line max-len */}
          Список этапов проекта формируется автоматически на основе данных раздела <strong>Календарный план</strong> на вкладке{' '}
          <strong>О проекте</strong>
        </p>
        <p>
          Отчёт <strong>Состав научного коллектива проекта</strong> строится по данным об исполнителях проекта на вкладке
          Коллектив. В отчёте выводится список исполнителей, у которых указан период работы в проекте, пересекающийся со сроками
          выделенного этапа
        </p>

        <div className="warning">
          <span>Если на вкладке Госрегистрация в ЦИТиС запланирован отчёт по итогам этапа. То такой этап невозможно удалить</span>
        </div>

        <h3>Особенности заполнения формы этапа:</h3>

        <p>В системе предусмотрены процедуры для автозаполнения некоторых полей на форме этапа:</p>

        <ol>
          <li>
            При копировании данных из связанной заявки (заявка на конкурс, заявка на внутренний конкурс, заявка на продление
            проекта — смотри на вкладке <strong>Заявки</strong>) — на основе метаданных заявки
          </li>
          <li>При заполнении сведений об отчёте, который регистрируется в ЦИТиС</li>
        </ol>

        <p>Так, например, при копировании из заявки заполняются поля:</p>

        <ul>
          <li>Название этапа</li>
          <li>Аннотация работ</li>
          <li>Цель этапа НИР</li>
          <li>Задачи этапа</li>
          <li>Ожидаемые результаты</li>
          <li>Описание работ</li>
          <li>Планируется охрана РИД</li>
          <li>Эффект на уровне университета</li>
          <li>Эффект на уровне региональном</li>
          <li>Эффект на уровне национальном</li>
        </ul>
      </>
    ),
    planReports: (
      <>
        <p>
          Таблица содержит список планируемых и поставленных на учёт отчётов по этапам проектов. Дата окончания этапа проекта
          является ключевой для подсистемы мониторинга подготовки отчёта и соблюдения графика государственной регистрации отчётов.
        </p>
        <p>
          За 30 дней, 10 и 3 дня до окончания этапа на электронную почту и в личный кабинет руководителю и ответственному
          исполнителю автоматически будут направлено уведомление.
        </p>
        <p>
          Отчёт, который по условиям проекта должен быть зарегистрирован в Единой государственной информационной системе учета
          результатов научно-исследовательских и опытно-конструкторских работ (ЕГИСУ НИОКТР) гражданского назначения имеет атрибут
          «госрегистрация»; Срок регистрации в ЕГИСУ — в течении 30 дней после окончания этапа.
          <br />
          Отчёт, у которого указаны <strong>Отправлен для гос.регистрации</strong> или <strong>Номер отчёта (ИК)</strong>,
          считается зарегистрированным в ЕГИСУ.
        </p>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Просмотр:</td>
              <td>Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму с данными отчёта для просмотра.</td>
            </tr>
            <tr>
              <td>Добавить:</td>
              <td>
                При добавлении отчёта необходимо указать этап проекта и атрибут «госрегистрация» если отчёт обязателен для
                регистрации в ЕГИСУ.
              </td>
            </tr>
            <tr>
              <td>Редактировать:</td>
              <td>
                <p>
                  Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму с данными о проекте для редактирования
                  на которой 2 вкладки: «Отчёт» - для ввода информации об отчёте и «Этап»- уточнённые данные этапа (аннотация,
                  цели, задачи и пр.), источник данных – отчёт по этапу.
                </p>

                <div className="warning">
                  <span>
                    <ol>
                      <li>Информация об этапе сохраняется и отображается на форме Этапа.</li>
                      <li>
                        <p>Кнопка заблокирована, если на форме заполнено хотя бы одно поле из списка:</p>
                        <ul>
                          <li>Номер госрегистрации</li>
                          <li>Отправлен для гос.регистрации</li>
                          <li>Номер в архиве</li>
                          <li>Сдан в архив</li>
                        </ul>
                      </li>
                      <li>
                        Если запись открыта для редактирования, то система не позволит открыть эту запись для другого пользователя
                        и выведет на экран сообщение: «Сущность заблокирована».
                      </li>
                    </ol>
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <td>Удалить:</td>
              <td>
                <p>
                  Кнопка активна, если в таблице выделена запись с отчётом. Кнопка заблокирована, если на форме заполнено хотя бы
                  одно поле из списка:
                </p>
                <ul>
                  <li>Номер госрегистрации</li>
                  <li>Отправлен для гос.регистрации</li>
                  <li>Номер в архиве</li>
                  <li>Сдан в архив</li>
                </ul>
              </td>
            </tr>
          </tbody>
        </table>
      </>
    ),
    resultsIndicators: (
      <>
        <h4>Индикаторы эффективности и показатели результативности</h4>

        <p>
          Вкладка предназначена для мониторинга качества и результативности выполнения проекта по количественным характеристикам:
          показателям результативности и индикаторам эффективности.
        </p>
        <p>
          Для этого по каждому этапу проекта необходимо указать список индикаторов и показателей ввести плановые значения, после и
          фактические значения. Иконка <strong>Добавить индикатор (показатель)</strong> открывает форму для добавления индикатора
          (показателя); необходимо указать плановое значение индикатора по этапу. Показатели результативности и индикаторы
          эффективности выполнения проекта выбираются из одноимённых справочников, в которых хранятся коллекции всех индикаторов и
          показателей из разных программ.
        </p>

        <div className="warning" data-label="Примечание!">
          <span>
            Если в справочнике отсутствует элемент, его необходимо добавить. Опыт показывает, что в разных проектах используются
            очень похожие по названию индикаторы (показатели), пусть вас не смущает, что в справочнике со временем накопятся
            смысловые дубликаты. Попытка привести к общему знаменателю, т.е. использовать «универсальные» индикаторы, пагубна,
            потому что в отчёте по запросу необходимо указывать точное название как было заявлено в Техническом задании.
          </span>
        </div>

        <p>
          Как правило, Министерство образования РФ, периодически запрашивает отчётность по выполненным НИОКР в рамках Программы,
          её мероприятий и направлений. Предметом такой отчётности является перечень показателей и индикаторов Программы.
        </p>
        <p>
          Поэтому, для проектов, которые выполняются в рамках государственной программы, обычно используется набор индикаторов и
          показателей, связанных с Программой. По кнопке <strong>Добавить из программы, связанной с проектом</strong> система
          выполняет процедуру автоматизированного добавления выбранных индикаторов из списка указанного на форме{' '}
          <strong>Программы</strong> по всем этапам проекта, плановое значение = 0.
        </p>

        <div className="warning">
          <span>
            В идеале, фактические значения индикаторов и показателей должны подтверждаться списками полученных результатов,
            указанных на вкладках{' '}
            <strong>
              Публикации, Диссертации и авторефераты, Мероприятия и участия в мероприятиях, Интеллектуальная собственность,
              Описание результатов.
            </strong>
          </span>
        </div>
      </>
    ),
    resultsPublications: (
      <>
        <p>
          Список публикаций по теме проекта содержит опубликованные материалы с описанием основных результатов: статьи, материалы
          {/* eslint-disable-next-line max-len */}
          конференции, монографии, книги, учебники и пр., у которых на вкладке <strong>Финансовая поддержка</strong> в разделе{' '}
          <strong>Опубликовано при финансовой поддержке указана связь с этим проектом</strong>.
        </p>
        <p>Для отображения на этой вкладке списка публикаций по теме проекта необходимо:</p>

        <ul>
          <li>
            В списке публикаций (пункт меню <strong>Публикации</strong>, или в{' '}
            <strong>личном кабинете на вкладке Публикации</strong>) выделив публикацию, нажать кнопку{' '}
            <strong>Финансовая поддержка публикации</strong>;
          </li>
          <li>
            На форме, в разделе <strong>Проекты</strong> указать проект;
          </li>
          <li>Сохранить форму.</li>
        </ul>

        <div className="warning">
          <span>
            Сотрудник, который проверяет отчёт по этапу проекта на соответствие требованиям к результатам проекта, должен
            поставить отметку <strong>Принято к отчёту</strong> для записей о результатах (специальное право в системе прав
            пользователей).
            <br />
            Связь с проектом в Записи (публикации, мероприятии и пр.) с такой отметкой другие пользователи не смогут удалить.
          </span>
        </div>

        <p>
          Функционал для отметки <strong>Принято к отчёту</strong> является инструментом контроля, чтобы результаты проекта не
          причислялись к результатам других проектов, если только это не предусмотрено внутренним регламентом.
        </p>
        <p>
          По условиям договора научные проекты гражданского назначения подлежат регистрации в федеральной системе учёта
          результатов по научным проектам ЕГИСУ НИОКТР, в соответствии с Федеральным законом № 77-ФЗ. Для подготовки данных с
          результатами проекта к загрузке в ЕГИСУ предусмотрены специальные шаблоны отчётов — группа «Отчёты ЕГИСУ».
        </p>
      </>
    ),
    resultsDissertations: (
      <>
        <p>
          Эта форма содержит список публикаций, типа авторефераты (диссертации) как подтверждение успешного выполнения
          исследования и получение учёной степени или квалификации.
        </p>
        <p>Для отображения на этой вкладке необходимо:</p>

        <ul>
          <li>
            В списке публикаций (пункт меню <strong>Публикации</strong>, или в{' '}
            <strong>Личном кабинете на вкладке Публикации</strong>) выделив автореферат/диссертацию, нажать кнопку{' '}
            <strong>Финансовая поддержка публикации</strong>;
          </li>
          <li>
            На форме, в разделе <strong>Проекты</strong> указать проект;
          </li>
          <li>Сохранить форму.</li>
        </ul>

        <div className="warning">
          <span>
            Сотрудник, который проверяет отчёт по этапу проекта на соответствие требованиям к результатам проекта, должен
            поставить отметку <strong>Принято к отчёту</strong> для записей о результатах (специальное право в системе прав
            пользователей).
            <br />
            Связь с проектом в Записи (публикации, мероприятии и пр.) с такой отметкой другие пользователи не смогут удалить.
          </span>
        </div>

        <p>
          Функционал для отметки <strong>Принято к отчёту</strong> является инструментом контроля, чтобы результаты проекта не
          причислялись к результатам других проектов, если только это не предусмотрено внутренним регламентом.{' '}
        </p>
        <p>
          По условиям договора научные проекты гражданского назначения подлежат регистрации в федеральной системе учёта
          результатов по научным проектам ЕГИСУ НИОКТР, в соответствии с Федеральным законом № 77-ФЗ. Для подготовки данных с
          результатами проекта к загрузке в ЕГИСУ предусмотрены специальные шаблоны отчётов — группа «Отчёты ЕГИСУ».
        </p>
      </>
    ),
    resultsEvents: (
      <>
        <p>На вкладке отображаются сведения о:</p>

        <ul>
          <li>Проведённой конференции</li>
          <li>Участии в конференции с докладом по тематике проекта</li>
          <li>Презентации проекта (разработки) на выставке</li>
        </ul>

        <p>Для отображения на этой вкладке сведений о конференции необходимо:</p>

        <ul>
          <li>
            В списке конференций (пункт меню <strong>Конференции</strong>, или в{' '}
            <strong>личном кабинете вкладка Мероприятия, Конференции);</strong> выделить конференцию, нажать кнопку{' '}
            <strong>Финансовая поддержка конференции;</strong>
          </li>
          <li>
            На форме, в разделе <strong>Организовано при финансовой поддержке</strong> указать проект;
          </li>
          <li>Сохранить форму.</li>
        </ul>

        <p>Для отображения на этой вкладке сведений об участии в конференции с докладом необходимо:</p>

        <ul>
          <li>
            В списке конференций (пункт меню <strong>Участие в конференции</strong>, или в{' '}
            <strong>личном кабинете вкладка Участие в мероприятия, Участие в конференции);</strong> выделить участие в
            конференции, нажать кнопку <strong>Связи с проектами;</strong>
          </li>
          <li>
            На форме, в разделе <strong>Проект</strong> указать проект;
          </li>
          <li>Сохранить форму.</li>
        </ul>

        <p>Для отображения на этой вкладке сведений о презентации проекта (разработки) на выставке необходимо:</p>

        <ul>
          <li>
            В списке участий в выставке (пункт меню <strong>Участие в выставках</strong>, или в{' '}
            <strong>личном кабинете вкладка Участие в мероприятия, Участие в выставках);</strong> выделить участие в выставке,
            нажать кнопку <strong>Связи с проектами;</strong>
          </li>
          <li>
            На форме, в разделе <strong>Проект</strong> указать проект;
          </li>
          <li>Сохранить форму.</li>
        </ul>

        <div className="warning">
          <span>
            Сотрудник, который проверяет отчёт по этапу проекта на соответствие требованиям к результатам проекта, должен
            поставить отметку <strong>Принято к отчёту</strong> для записей о результате (специальное право в системе прав
            пользователей).
            <br />
            Связь с проектом в Записи (публикации, мероприятии и пр.) с такой отметкой другие пользователи не смогут удалить.
          </span>
        </div>

        <p>
          Функционал для отметки <strong>Принято к отчёту</strong> является инструментом контроля, чтобы результаты проекта не
          причислялись к результатам других проектов, если только это не предусмотрено внутренним регламентом.
        </p>
        <p>
          По условиям договора научные проекты гражданского назначения подлежат регистрации в федеральной системе учёта
          результатов по научным проектам ЕГИСУ НИОКТР, в соответствии с Федеральным законом № 77-ФЗ. Для подготовки данных с
          результатами проекта к загрузке в ЕГИСУ предусмотрены специальные шаблоны отчётов — группа «Отчёты ЕГИСУ».
        </p>
      </>
    ),
    resultsSecurityDocuments: (
      <>
        <p>На вкладке отображаются сведения о документах на правовую охрану РИД, полученных в проекте</p>

        <ul>
          <li>Заявке на патент</li>
          <li>Заявке на получение свидетельства</li>
          <li>Полученном патенте</li>
          <li>Полученном свидетельстве</li>
          <li>Ноу-хау</li>
        </ul>

        <p>Для отображения на этой вкладке на форме документа должно быть указано, что РИД получен в рамках проекта.</p>

        <div className="warning">
          <span>
            Все записи подлежат регистрации в федеральной системе учёта результатов по научным проекта ЕГИСУ НИОКТР, в
            соответствии с Федеральным законом № 77-ФЗ.
          </span>
        </div>
      </>
    ),
    documentsAbout: (
      <>
        <p>
          Эта форма предназначена для хранения архива документов (файлов) разного рода, связанных с проектом.
          <br />
          При добавлении файла важно указать Тип документа. Файлы с типом «<strong>из ЕГИСУ</strong>» будут доступны для просмотра
          и{/* eslint-disable-next-line max-len */}
          скачивания на вкладке Госрегистрация. Для некоторых типов документов, например,{' '}
          <strong>Акт, Платёжное поручение</strong> желательно указывать сумму.
          <br />
          Отчёт <strong>Список документов проекта</strong> (за период в разрезе типов документов) строится с помощью кнопки{' '}
          <Icon type="print" />.
        </p>
        <p>
          В таблице отображаются документы объектов интеллектуальной собственности и документы из заявок, которые связаны с
          проектом. Место нахождения файла – отображается в столбце Источник. Файлы из другого источника можно скачать{' '}
          <Icon type="download" /> или открыть для просмотра в новом окне браузера с помощью кнопки <Icon type="view" />.
        </p>
        <p>
          Система фиксирует и отображает в таблице: кто и когда добавил документ.
          <br />
          Удалить документ или редактировать его атрибуты пользователь может при наличии следующих прав:
        </p>

        <ul>
          <li>Редактировать только «свои» — те, которые прикрепил на этой форме;</li>
          <li>Редактировать любые — т.е. «свои» и «чужие».</li>
        </ul>

        <div className="warning">
          <span>
            Поскольку, с записью о проекте, могут работать разные пользователи - сотрудники разных отделов (в соответствии со
            своими полномочиями и правами), то в конфиденциальных проектах для пользователя при переходе на вкладку Документы
            автоматически появляется сообщение об уровне конфиденциальности документов.
            <br />
            <strong>Уровень конфиденциальности</strong> проекта указывают на вкладке <strong>О проекте</strong>; если атрибут{' '}
            <strong>Предупреждать в разделе с документами</strong>
            имеет значение <strong>Да</strong>, то в режиме просмотра или редактирования проекта при переходе на вкладку Документы
            система будет выдавать сообщение о конфиденциальности документов проекта. Текст сообщения можно отредактировать в
            справочнике Уровни конфиденциальности.
          </span>
        </div>
      </>
    ),
    documentsActs: (
      <>
        <p>
          Форма содержит таблицу со списком Актов приёмки НИР за период (этап) и предназначена для формирования акта (кнопка
          Добавить <Icon type="add" />)
        </p>

        <p>
          <strong>Рекомендации по заполнению разделов акта:</strong>
        </p>
        <p>
          <strong>Этап проекта</strong> – выбрать из списка этапов проекта
          <br />
          <strong>Номер</strong> — номер акта должен соответствовать номеру этапа
          <br />
          <strong>Подразделения</strong> — в этом разделе автоматически отображается список подразделений, на базе которых
          выполняется проект. Удалить подразделение из списка можно с помощью кнопки <Icon type="remove" />.<br />
          <strong>Комиссии были представлены на рассмотрение следующие документы</strong>: в поле по умолчанию добавлен текст,
          который вы можете отредактировать: добавить в имеющийся текст перечень дополнительных документов, если таковые приложены
          к отчёту для рассмотрения Комиссии.{' '}
          <strong>
            При заполнении поля учитывайте информацию о проекте в частности, наличие требования оформления отчётов по ГОСТу РВ.
          </strong>
          <br />
          <strong>Комиссия</strong>: Из состава Комиссии выберите минимум 1 (одного) члена Комиссии с ролью «Заместитель
          председателя» и 3 (трёх) членов Комиссии с ролью «Член Совета»;
          <br />
          <strong>Заключение комиссии</strong>: Отредактируйте текст в пунктах 1, 3, 4, 5 (необходимо оставить один из
          перечисленных вариантов);
          <br />
          <strong>Дополнительные замечания и предложения</strong>: если есть замечания и предложения от имени Членов Комиссии.
        </p>

        <div className="warning">
          <span>
            Акт необходимо распечатать и подписать. Скан-копия акта размещается на вкладке Документы.
            <br />
            Сформировать версию документа для вывода на печать – с помощью кнопки <Icon type="print" />.
          </span>
        </div>

        <p>
          <strong>
            Инструкция для руководителя проекта по Акту приёмки этапа НИР и НИР в целом размещена в разделе «Новости, Справочная
            информация».
          </strong>
        </p>
      </>
    ),
    assignments: (
      <>
        <p>
          Форма содержит список поручений по проекту и ленту сообщений, связанных с поручением.
          <br />
          Фильтр <strong>«Год»</strong> — отображает список поручений по периоду: Дата добавления - Выполнить до.
          <br />
          Фильтр <strong>«Актуальные поручения»</strong> — отображает список поручений в статусах «Добавлено» или «В работе».
          <br />
          Фильтр <strong>«Все поручения»</strong> — отображает все поручения (не зависит от статуса).
        </p>

        <div className="warning">
          <span>
            <p>Поручение в статусах «Добавлено» или «В работе» выделяется в таблице:</p>
            <ul>
              <li>красным цветом, если текущая дата ≥ дата «Выполнить до» (просрочена дата)</li>
              <li>
                зелёным цветом, если текущая дата ≥ (дата «Выполнить до» -3) (т.е. за три дня до наступления критической даты)
              </li>
            </ul>
          </span>
        </div>

        <p>
          В подсистеме Уведомления предусмотрена рассылка уведомлений на электронную почту руководителю и ответственному
          исполнителю: поступление поручения, смена статуса поручения, сообщение по поручению.
        </p>

        <h4>
          Руководитель проекта и ответственный исполнитель, при наличии соответствующих прав, имеют возможность в личном кабинете
          на следующие действия:
        </h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Добавить новое поручение по проекту</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Редактировать поручение в статусах <strong>Добавлена, В работе</strong>
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Удаление поручения в статусе <strong>Добавлено</strong>
              </td>
            </tr>
            <tr>
              <td>
                В работе: <Icon type="clock" />
              </td>
              <td>
                Изменить статус поручения из <strong>Добавлено</strong> в <strong>В работе</strong>
              </td>
            </tr>
            <tr>
              <td>
                Готово: <Icon type="check" />
              </td>
              <td>
                Изменить статус поручения из <strong>Добавлено</strong> в <strong>Готово</strong>
              </td>
            </tr>
            <tr>
              <td>
                Закрыть поручение: <Icon type="cancel" />
              </td>
              <td>
                Перевести поручение из любого статуса в статус <strong>Закрыто</strong>
              </td>
            </tr>
            <tr>
              <td>
                Отменить поручение: <Icon type="dislike" />
              </td>
              <td>
                Перевести поручение из любого статуса в статус <strong>Отменено</strong>
              </td>
            </tr>
            <tr>
              <td>
                Журнал сообщений: <Icon type="messages" />
              </td>
              <td>Просмотр всех сообщений с возможностью редактирования только своих сообщений</td>
            </tr>
            <tr>
              <td>
                Скачать файл: <Icon type="download" />
              </td>
              <td>Скачать файл поручения (если он был прикреплен при добавлении поручения)</td>
            </tr>
          </tbody>
        </table>

        <h4>Исполнитель, на которого назначено поручение, имеет права на следующие действия:</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '30%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Добавить новое поручение по проекту</td>
            </tr>
            <tr>
              <td>
                В работе: <Icon type="clock" />
              </td>
              <td>
                Изменить статус поручения из <strong>Добавлено</strong> в <strong>В работе</strong>
              </td>
            </tr>
            <tr>
              <td>
                Готово: <Icon type="check" />
              </td>
              <td>
                Изменить статус поручения из <strong>Добавлено</strong> в <strong>Готово</strong>
              </td>
            </tr>
            <tr>
              <td>
                Добавить сообщение: <Icon type="message" mode="add" />
              </td>
              <td>Добавить сообщение в журнал сообщений с возможностью прикрепить файл</td>
            </tr>
            <tr>
              <td>
                Журнал сообщений: <Icon type="messages" />
              </td>
              <td>Просмотр всех сообщений с возможностью редактирования только своих сообщений</td>
            </tr>
            <tr>
              <td>
                Скачать файл: <Icon type="download" />
              </td>
              <td>Скачать файл поручения (если он был прикреплен при добавлении поручения)</td>
            </tr>
          </tbody>
        </table>
      </>
    ),
    purchases: (
      <>
        <p>
          На вкладке отображаются закупки для реализации проекта. Вкладка носит информативный характер, закупки доступны только
          для просмотра, управление закупками смена статуса и редактирование доступно для руководителей из личного кабинета, а
          также из пункта меню Закупки при наличии соответствующего права.
        </p>
        <p>
          <strong>Назначение функциональных кнопок:</strong>
        </p>

        <p>
          Кнопка <strong>Просмотр</strong> <Icon type="view" /> открывает форму с заявкой, в которой отображаются сведения о
          закупке.
        </p>
        <p>
          Кнопка <strong>Отчёты</strong> <Icon type="print" /> служит для формирования служебной записки на закупку.
        </p>
        <p>
          Кнопка <strong>Выгрузить в файл</strong> <Icon type="excel" /> формирует файл .xls с таблицей данных как на экране.
        </p>
      </>
    ),
  };
}

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Form as F, Table } from 'types/models';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = useMemo<number>(() => tableState.selectedRows.length, [tableState.selectedRows]);

  const rowsAvailability = useMemo<Table.ToolbarStateRowsAvailability>(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState<boolean>(false);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const openViewForm = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const closeViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const toolbarButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: openViewForm,
        permission: { name: 'PublicationView' },
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [openViewForm, rowsAvailability.SINGLE_SELECTED],
  );

  const look: F.Look = {
    apiID: 'GetPublication',
    template: 'PublicationFormArticle',
    type: PublicationTypes.MAGAZINE_ARTICLE.code,
    viewMode: true,
    id: tableState.selectedRows[0]?.id,
    title: tableState.selectedRows[0]?.name,
    relatedTableState: tableState,
    setTitle,
  };

  return {
    toolbarButtons,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isViewFormOpen,
    closeViewForm,
    look,
    title,
  };
};

export default useController;

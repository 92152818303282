import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, TextArea, TextAreaMode, TextInput, TextInputMode } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Table } from 'types/models';
import { GetKindConsumptionList, GetTenderKindConsumptionList } from 'features/Table/specifications';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function TenderKindConsumptionForm({ viewMode, onClose }: Props) {
  const { onSubmit, tenderKindConsumption, setTenderKindConsumption, getTenderKindConsumption, tenderId } = useController({
    onClose,
  });

  const { methods: getKindConsumption } = BackendAPI.useBackendAPI('GetKindConsumption');

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={onSubmit} viewMode={viewMode} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Наименование" isRequired>
            <TextInput
              value={tenderKindConsumption.name}
              onChange={e => setTenderKindConsumption(prev => ({ ...prev, name: e }))}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Позиция"
            isRequired
            // eslint-disable-next-line max-len
            tooltip='Порядковый номер позиции в смете на форме заявки. Статьи размещаются в смете в порядке возрастания значений "Позиция"'
          >
            <TextInput
              mode={TextInputMode.NUMBER}
              value={tenderKindConsumption.position}
              onChange={e => setTenderKindConsumption(prev => ({ ...prev, position: e }))}
              isDisabled={viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Описание">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={tenderKindConsumption.description}
              onChange={e => setTenderKindConsumption(prev => ({ ...prev, description: e }))}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label="Виды расходов из бухгалтерии"
            // eslint-disable-next-line max-len
            tooltip="Используется при формировании консолидированной сметы затрат по всем заявкам на год; важно, если данные по смете передаются в бухгалтерию"
          >
            <TextArea
              mode={TextAreaMode.TABLE}
              value={[tenderKindConsumption.kindConsumption?.code, tenderKindConsumption.kindConsumption?.name]
                .filter(Boolean)
                .join(' ')}
              settings={{
                title: 'Виды расходов из бухгалтерии',
                table: {
                  specification: GetKindConsumptionList({ hasSelectButton: true }),
                  onSelect: (row: Table.Entry | null) => {
                    if (!row) {
                      setTenderKindConsumption(prev => ({ ...prev, kindConsumption: null }));
                      return;
                    }
                    getKindConsumption.callAPI(
                      { id: row.id },
                      { onSuccessfullCall: ({ data }) => setTenderKindConsumption(prev => ({ ...prev, kindConsumption: data })) },
                    );
                  },
                },
                rows: 3,
              }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          {!tenderKindConsumption?.incrimentKindConsumptions?.length || !tenderKindConsumption.id ? (
            <FormComponent.Field label="Входит в группу" tooltip="Учитывается в общей сумме для выбранного Вида расхода">
              <TextArea
                mode={TextAreaMode.TABLE}
                value={tenderKindConsumption?.incrimenator?.name}
                settings={{
                  title: 'Входит в группу',
                  table: {
                    specification: GetTenderKindConsumptionList({
                      hasSelectButton: true,
                      tenderId,
                      sourceId: tenderKindConsumption.id || '-1',
                    }),
                    onSelect: (row: Table.Entry | null) => {
                      if (!row) {
                        setTenderKindConsumption(prev => ({ ...prev, incrimenator: null }));
                        return;
                      }
                      getTenderKindConsumption.callAPI(
                        { id: row.id },
                        { onSuccessfullCall: ({ data }) => setTenderKindConsumption(prev => ({ ...prev, incrimenator: data })) },
                      );
                    },
                  },
                }}
                isDisabled={viewMode}
              />
            </FormComponent.Field>
          ) : (
            <FormComponent.Field
              label="Суммирует"
              tooltip="Сумма по виду расхода рассчитывается автоматически как общая сумма перечисленных видов расходов"
            >
              <FormComponent.Text>
                {tenderKindConsumption?.incrimentKindConsumptions?.map(kc => kc.name)?.join(', ') || '-'}
              </FormComponent.Text>
            </FormComponent.Field>
          )}
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(TenderKindConsumptionForm);

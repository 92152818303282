const getDateYearMonthDayTime = (date: Date) => {
  const day = date.getDate();
  const month = date.getMonth();
  const year = date.getFullYear();

  const dateTime = new Date(year, month, day).getTime();
  return dateTime;
};

export { getDateYearMonthDayTime };

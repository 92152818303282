import React from 'react';

import { Toolbar, Modal } from 'components';

import { Fields } from 'features/Table/specifications/GetReferenceElementList/Editor/Fields';
import { useController } from './controller';

export function ToolbarReference() {
  const { buttons, formButtons, isAddFormOpen, setIsAddFormOpen, fields, handleChangeField } = useController();

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        isOpen={isAddFormOpen}
        onClose={() => setIsAddFormOpen(false)}
        size="medium"
        title='Добавить запись в справочник "Организации"'
      >
        <Toolbar buttons={formButtons} mode="form" />

        <Fields mode="add" isRecordEditable={true} fields={fields} onChange={handleChangeField} />
      </Modal>
    </>
  );
}

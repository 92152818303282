import * as XML from 'xml-js';
import { Arm, Table } from 'types/models';
import { ElementCompact } from 'xml-js';
import { GetGridInput } from '../types';
import { getMaybeField } from '.';
import { FILTER_STRUCRURE } from 'features/Table/helpers';
import { Column } from 'types/models/Table';

export const formatNumbericToRequest = ({ from, to, filterStructure }: { from: string; to: string; filterStructure: string }) => {
  const isRange = filterStructure === FILTER_STRUCRURE.range;

  const prepareValue = (value: string) => {
    return value.replaceAll(' ', '');
  };

  if (isRange) {
    if (!from && !to) {
      return '';
    }

    return `${prepareValue(from) || 'EMPTY'}#${prepareValue(to) || 'EMPTY'}`;
  }
  return prepareValue(from);
};

const formatFilter = (column: Pick<Column, 'filterStructure' | 'filter' | 'type'>): string | undefined => {
  switch (column.type) {
    case 'MONEY':
    case 'DOUBLE':
    case 'INTEGER':
      const [from, to] = (column.filter ?? '').split('#');
      return formatNumbericToRequest({ from, to, filterStructure: column.filterStructure });
    default:
      return column.filter;
  }
};
export function makeTableRequestXMLConverter<Input extends GetGridInput>(
  commandName: string,
  makeParametersConverter: (input: Input) => XML.ElementCompact,
  getWorkbench?: (input: Input) => Arm | null,
) {
  return makeRequestXMLConverter<Input>(
    commandName,
    (input: Input) => {
      return {
        Grid: {
          _attr: { rowQuantity: input.rowQuantity === 'all' ? null : input.rowQuantity, startRow: input.offset },
          ...(input.requestColumnData.length > 0 && input.requestColumnData !== 'empty'
            ? makeColumnsXMLElement(input.requestColumnData)
            : // hasSetting обозначает, что у грида есть настройка(ШАБЛОН),
            // в случае, когда она есть и не полная перезагрузка грида,
            // мы должны отправить пустой массив columns, чтобы сервер не отдал нам первоначальное состояние грида
            input.hasSetting && !input.isFullRefresh
            ? { Columns: {} }
            : {}),
          ...makeParametersConverter(input),
        },
      };
    },
    getWorkbench,
  );
}

function makeColumnsXMLElement(requestColumnData: Table.RequestColumnData[] | Table.Column[], hasSetting?: boolean) {
  if (!requestColumnData.length && !hasSetting) {
    return {};
  }

  function getSort(column: Table.RequestColumnData | Table.Column) {
    if ((column as Table.Column).sortAscending) {
      return (column as Table.Column).sortAscending;
    }
    if ((column as Table.RequestColumnData).sortKind) {
      return (column as Table.RequestColumnData).sortKind === 'ascending' ? 'true' : 'false';
    }
  }

  function getHidden(column: Table.RequestColumnData | Table.Column) {
    if ((column as Table.Column).hidden) {
      return 'true';
    }
  }

  return {
    Columns: {
      Column: requestColumnData.map(x => ({
        _attr: {
          name: x.name,
          ...getMaybeField('filter', x.filter && formatFilter(x)),
          ...getMaybeField('sortAscending', getSort(x)),
          ...getMaybeField('sortOrder', x.sortOrder?.toString()),
          ...getMaybeField('hidden', getHidden(x)),
          type: x.type,
          filterStructure: x.filterStructure,
        },
      })),
    },
  };
}

export function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => ElementCompact,
  getWorkbench?: ((input: Input) => Arm | null) | undefined,
): (input: Input) => ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName, ...(getWorkbench?.(input) ? { workbench: getWorkbench?.(input) } : {}) },
      ...makeChildren(input),
    },
  });
}

export function makeParametersXMLElement(parameters: Array<Record<string, any>>) {
  return {
    Parameters: {
      Parameter: parameters.map(x => ({ _attr: x })),
    },
  };
}

export function makePresetFiltersXML(filters: PresetFilter[]) {
  if (!filters.length) {
    return {};
  }

  return {
    PresetFilters: {
      PresetFilter: filters.map(({ key, values }) => ({
        _attr: { name: key },
        Value: values.map(value => ({
          _text: value,
        })),
      })),
    },
  };
}

type PresetFilter = {
  key: string;
  values: string[];
};

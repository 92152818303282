import { useState } from 'react';

export enum FilterOptions {
  ALL = 'all',
  ACTUAL = 'actual',
}

export type State = {
  filter: FilterOptions;
  setFilter: React.Dispatch<React.SetStateAction<FilterOptions>>;
  isYear: boolean;
  setIsYear: React.Dispatch<React.SetStateAction<boolean>>;
  year: string;
  setYear: React.Dispatch<React.SetStateAction<string>>;
};

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [filter, setFilter] = useState<FilterOptions>(FilterOptions.ALL);
    const [isYear, setIsYear] = useState<boolean>(false);
    const [year, setYear] = useState<string>(new Date().getFullYear().toString());

    return { filter, setFilter, isYear, setIsYear, year, setYear };
  };
}

import React, { useState, useEffect, useMemo, useCallback } from 'react';

import { ButtonMode, Checkbox, FormComponent, Modal, Select } from 'components';

import { Form } from 'features/Form';
import { Form as F, PublicationTypeProps } from 'types/models';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import publicationTypes, { otherUniversityPublicationTypes, PublicationType } from 'types/models/Publication/publicationTypes';
import { Item } from 'types/models/common';

const PublicationAddTemplate: React.FC<F.TemplateProps & PublicationTypeProps> = (
  props: F.TemplateProps & PublicationTypeProps,
) => {
  const {
    isOpen,
    onClose,
    relatedTableState,
    id,
    name,
    handleChangeParticipation,
    isHidden,
    setPublication,
    setPublicationConferenceSource,
  } = props;

  const [title, setTitle] = useState<string | JSX.Element>('');
  const [type, setType] = useState<PublicationType | null>(null);
  const [isElectronic, setIsElectronic] = useState<boolean>(false);
  const [electronicPublicationType, setElectronicPublicationType] = useState<F.ElectronicType>(ElectronicType.WEB);
  const [publicationType, setPublicationType] = useState(publicationTypes[0]);

  const onChangePublicationType = (value: string) => {
    const selected = publicationTypes.find(({ id: itemId }) => itemId === value);
    if (selected) {
      setPublicationType(selected);
    }
  };

  const continueWithSelectedType = () => {
    changeSelectedType(publicationType);
  };

  const electronicPublicationTypes = useMemo<F.ElectronicType[]>(() => [ElectronicType.WEB, ElectronicType.LOCAL], []);

  const onChangeElectronicPublicationType = useCallback(
    (value: string) => {
      const selectedItemIndex = electronicPublicationTypes.findIndex(({ id: itemId }) => itemId === value);
      if (selectedItemIndex !== -1) {
        setElectronicPublicationType({ ...electronicPublicationTypes[selectedItemIndex] });
      }
    },
    [electronicPublicationTypes, setElectronicPublicationType],
  );

  useEffect(() => {
    if (publicationType.code === PublicationTypes.PREPRINT_ARTICLE.code) {
      setIsElectronic(true);
    } else {
      setIsElectronic(false);
    }
  }, [publicationType, setIsElectronic]);

  const look: F.PublicationFormLook = {
    id,
    apiID: 'GetPublication',
    type: type?.code || '',
    template: type?.template,
    name,
    isElectronic,
    electronicType: electronicPublicationType.code,
    relatedTableState,
    initialTypeEdition: type?.code && otherUniversityPublicationTypes.indexOf(type?.code) > -1 ? 'OTHER_UNIVERSITY' : 'LOCAL',
    handleChangeParticipation,
    setPublication,
    setPublicationConferenceSource,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) {
      setType(null);
      setElectronicPublicationType(ElectronicType.WEB);
      setIsElectronic(false);
    }
  }, [isOpen]);

  const changeSelectedType = (val: PublicationType) => {
    setType(val);
    if (val.isOnPaperOnly) {
      setIsElectronic(false);
    }
  };

  return (
    <>
      {!isHidden &&
        (type ? (
          <Modal title={title} isOpen={isOpen} onClose={onClose} size="full">
            <Form look={look} onClose={onClose} />
          </Modal>
        ) : (
          <Modal
            title="Выберите тип публикации"
            isOpen={isOpen}
            onClose={onClose}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Продолжить',
                onClick: continueWithSelectedType,
                isDisabled: isElectronic && !electronicPublicationType.code,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: onClose,
              },
            ]}
            size="medium"
          >
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Тип публикации">
                  <Select
                    value={{ ...publicationType, ...{ value: publicationType.id } }}
                    options={publicationTypes
                      .filter(e => !e.isHidden)
                      .map(e => ({
                        ...e,
                        ...{ value: e.id },
                      }))
                      .sort((a, b) => {
                        if (a.label === b.label) return 0;
                        return a.label > b.label ? 1 : -1;
                      })}
                    onChange={(option: Item) => onChangePublicationType(option.value)}
                  />
                </FormComponent.Field>
              </FormComponent.Line>

              {!!publicationType?.text && (
                <FormComponent.Description mode="info">{publicationType.text}</FormComponent.Description>
              )}

              {!publicationType.isOnPaperOnly && (
                <>
                  <FormComponent.Line>
                    <Checkbox
                      label="Электронное издание, не имеющее печатного аналога"
                      checked={!!isElectronic}
                      onChange={setIsElectronic}
                      isDisabled={publicationType.code === PublicationTypes.PREPRINT_ARTICLE.code}
                    />
                  </FormComponent.Line>

                  {isElectronic && (
                    <>
                      <FormComponent.Line>
                        <Select
                          value={{ value: electronicPublicationType.id, label: electronicPublicationType.label }}
                          options={electronicPublicationTypes.map(x => ({
                            value: x.id,
                            label: x.label,
                          }))}
                          onChange={(option: Item) => onChangeElectronicPublicationType(option.value)}
                        />
                      </FormComponent.Line>
                      {electronicPublicationType.code && (
                        <FormComponent.Description mode="info">{electronicPublicationType.text}</FormComponent.Description>
                      )}
                    </>
                  )}
                </>
              )}

              <FormComponent.Line>
                <FormComponent.Text>Внимание! От типа публикации зависит состав полей на форме добавления.</FormComponent.Text>
              </FormComponent.Line>
            </>
          </Modal>
        ))}
    </>
  );
};

export const Component = React.memo(PublicationAddTemplate);

import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import useController from './controller';
import { PartnerProjectGridHelp } from './help';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal mode="help" title="НИОКР" isOpen={isHelpFormOpen} onClose={() => setIsHelpFormOpen(false)} size="large">
        {PartnerProjectGridHelp()}
      </Modal>
    </>
  );
};

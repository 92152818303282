import React, { memo, useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { Component as ModalTemplate } from './modalTemplate';
import { showNotification } from 'features/Notifications';
import { Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { State } from '../makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';
import { useAppDataContext } from 'features/AppData/context';
import { Operation } from 'features/Table/ThirdLevelHeader/TemplatesPanel/types';
import { GridSettingsSpecificationHelp } from './help';

type Props = {
  customState: State;
  tableState: Table.State;
  onSubmitTable?(state: SubmitTable): void;
};

function LeftPanelForSecondLevelControls({ customState, onSubmitTable, tableState }: Props) {
  const { selectedRow, gridName, columns, currentSetting, crudCallback } = customState;

  const tableStreams = useLocalTableStreams();
  const { userPermission } = useAppDataContext();

  const [isOpenHelpForm, setIsOpenHelpForm] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const [isOpenConfirm, setIsOpenConfirm] = useState<boolean>(false);

  const isSelectedRowForeign = useMemo(
    () => tableState.selectedRows.length !== 1 || (Boolean(selectedRow) && selectedRow!.isForeign === 'true'),
    [selectedRow, tableState.selectedRows.length],
  );

  const isSelectedCurrentSetting = useMemo(() => (selectedRow ? currentSetting?.id === selectedRow?.id : false), [
    currentSetting?.id,
    selectedRow,
  ]);

  const { methods: deleteSettingAPIMethods } = BackendAPI.useBackendAPI('DeleteGridSetting', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Шаблон успешно удален', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const deleteTemplate = useCallback(() => {
    if (selectedRow) {
      deleteSettingAPIMethods.callAPI(
        {
          gridName,
          settingName: selectedRow?.name,
        },
        {
          onSuccessfullCall: async () => {
            await crudCallback({
              operation: Operation.DELETE,
              name: selectedRow?.name,
              author: userPermission?.userLogin ?? '',
            });
          },
        },
      );
      setIsOpenConfirm(false);
    }
  }, [crudCallback, deleteSettingAPIMethods, gridName, selectedRow, userPermission?.userLogin]);

  const handleSelectButtonClick = useCallback(() => {
    if (onSubmitTable) {
      onSubmitTable(tableState);
    } else {
      tableStreams.submitTable.push(tableState);
    }
  }, [tableStreams, onSubmitTable, tableState]);

  const isButtonDisabled = selectedRow === undefined;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'check' },
        title: 'Выбрать',
        onClick: handleSelectButtonClick,
        isDisabled: isButtonDisabled,
      },
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать описание шаблона',
        onClick: () => setIsOpenModal(true),
        isDisabled: isSelectedRowForeign,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить шаблон',
        onClick: () => setIsOpenConfirm(true),
        isDisabled: isSelectedRowForeign,
      },
    ],
    [handleSelectButtonClick, isButtonDisabled, isSelectedRowForeign],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <ModalTemplate
        mode="edit"
        isOpen={isOpenModal}
        onClose={() => setIsOpenModal(false)}
        setting={
          !!selectedRow
            ? {
                id: selectedRow?.id,
                name: selectedRow?.name,
                author: selectedRow?.author,
                isShared: selectedRow?.isShared === 'true',
                purpose: selectedRow?.purpose,
              }
            : undefined
        }
        gridName={gridName}
        columns={columns}
        crudCallback={crudCallback}
        successfullCallback={() => {
          tableStreams.reloadTable.push({});
        }}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirm}
        onClose={() => setIsOpenConfirm(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: deleteTemplate,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirm(false),
          },
        ]}
        size="small"
      >
        {isSelectedCurrentSetting ? (
          <span>
            <p>Удаляемый шаблон в данный момент используется.</p>
            <p>({tableState.selectedRows[0]?.name}) Удалить шаблон?</p>
          </span>
        ) : (
          <span>({tableState.selectedRows[0]?.name}) Удалить шаблон?</span>
        )}
      </Modal>

      <Modal mode="help" title="Внимание!" isOpen={isOpenHelpForm} onClose={() => setIsOpenHelpForm(false)} size="medium">
        {GridSettingsSpecificationHelp()}
      </Modal>
    </>
  );
}

export const Component = memo(LeftPanelForSecondLevelControls);

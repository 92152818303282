import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { DeleteModelRequest, ApproveRequestProps } from './types';
import { convertDeleteModelToOutput } from './converters';

export const abstractModelConfigurations = {
  DeleteModel: makeBackendAPIConfiguration({
    id: 'DeleteModel',
    endpoint: '/msa/service/commands/',
    getEndpoint: input => `/msa/service/commands/${input.commandName}`,
    convertInputToXMLElement: ({ entityName, rowId, commandName }: DeleteModelRequest) => ({
      Request: {
        _attr: {
          commandName,
        },
        [entityName]: { _attr: { id: rowId } },
      },
    }),
    convertResponseXMLElementToOutput: convertDeleteModelToOutput,
  }),
  ApproveItem: makeBackendAPIConfiguration({
    id: 'ApproveItem',
    endpoint: '/msa/service/commands/',
    getEndpoint: input => `/msa/service/commands/${input.command}`,
    convertInputToXMLElement: (args: ApproveRequestProps) => {
      const { command, approveItemName, approveInnerItemName, approveRowId, disapprove } = args;
      const approveIdsArray = Array.isArray(approveRowId) ? approveRowId : [approveRowId];
      const reqObject = {
        [approveItemName]: {},
      };

      if (approveInnerItemName) {
        reqObject[approveItemName] = {
          [approveInnerItemName]: approveIdsArray.map(id => ({
            _attr: { id, disapprove },
          })),
        };
      } else {
        reqObject[approveItemName] = { _attr: { id: approveIdsArray[0], disapprove } };
      }

      return {
        Request: {
          _attr: {
            commandName: command,
          },
          ...reqObject,
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  // GetReportParameters: makeBackendAPIConfiguration({
  //   id: 'GetReportParameters',
  //   endpoint: '/msa/service/commands/GetReportParameters',
  //   convertInputToXMLElement: ({ name }: GetReportParametersRequest) => {
  //     return {
  //       Request: {
  //         _attr: { commandName: 'GetReportParameters',  },
  //         Report: { _attr: { name } },
  //       },
  //     };
  //   },
  //   convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  // }),
};

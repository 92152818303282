import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Report, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { State } from '../makeUseCustomController';
import { Reports, useReportsHook } from 'features/BuildReportPopup';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState, customState } = props;

  const tableStreams = useLocalTableStreams();

  const { projectId, isDisabled } = customState;

  const selectedRowsLength = useMemo(() => tableState.selectedRows.length, [tableState.selectedRows]);
  const rowsAvailability: Table.ToolbarStateRowsAvailability = useMemo(
    () => ({
      ALWAYS: true,
      SINGLE_SELECTED: selectedRowsLength === 1,
      MULTIPLE_SELECTED: selectedRowsLength >= 1,
    }),
    [selectedRowsLength],
  );

  const isAddButtonDisabled = false;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteProjectPerformerOrder',
      deletedItemPropName: 'ProjectPerformerOrder',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const reports = useMemo<Report[]>(() => [Reports.OrderAboutCreationResearchTeam], []);

  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS },
        isDisabled: isDisabled || isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS },
        isDisabled: isDisabled || isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.PROJECT_PERFORMER_ORDER_FULL_ACCESS },
        isDisabled: isDisabled || isDeleteButtonDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      isViewButtonDisabled,
      handleAddButtonClick,
      isDisabled,
      isAddButtonDisabled,
      handleEditButtonClick,
      isEditButtonDisabled,
      handleDeleteButtonClick,
      isDeleteButtonDisabled,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    projectId,
    isViewButtonDisabled,
    isEditButtonDisabled,
    isDeleteButtonDisabled,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    handleDeleteButtonClick,
    handleViewButtonClick,
    handleTemplateCloseViewForm,
    handleAddButtonClick,
    handleTemplateCloseAddForm,
    handleEditButtonClick,
    handleTemplateCloseEditForm,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}

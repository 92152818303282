import { useCallback, useState } from 'react';

import { useLocalTableStreams } from 'features/Table/hooks';
import { ByPrototypeCopyOption, ProjectType } from 'utils/Enums';

type Args = {
  type: ProjectType;
};

export type State = {
  projectId: string;
  setProjectId(id: string): void;
  isOpenForm: boolean;
  setIsOpenForm: React.Dispatch<React.SetStateAction<boolean>>;
  reloadTable(): void;
  mode: Mode | null;
  setMode(mode: Mode | null): void;
  programRequestId: string;
  nirRequestId: string;
  securityDocumentContractId: string;
  closeForm(): void;
  type: ProjectType;
  projectCopyOptions: ByPrototypeCopyOption[] | null;
  setProjectCopyOptions: React.Dispatch<React.SetStateAction<ByPrototypeCopyOption[] | null>>;
  prototypeId: string | null;
  setPrototypeId: React.Dispatch<React.SetStateAction<string | null>>;
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController({ type }: Args) {
  return function useCustomController(): State {
    const [projectId, setProjectId] = useState<string>('');
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const [programRequestId, setProgramRequestId] = useState<string>('');
    const [nirRequestId, setNirRequestId] = useState<string>('');
    const [securityDocumentContractId, setSecurityDocumentContractId] = useState<string>('');
    const [projectCopyOptions, setProjectCopyOptions] = useState<ByPrototypeCopyOption[] | null>(null);
    const [prototypeId, setPrototypeId] = useState<string | null>(null);

    const tableStreams = useLocalTableStreams();

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const closeForm = useCallback(() => {
      setIsOpenForm(false);
      setMode(null);
      setProjectId('');
      setProgramRequestId('');
      setNirRequestId('');
      setSecurityDocumentContractId('');
      setProjectCopyOptions(null);
      setPrototypeId(null);
    }, []);

    return {
      projectId,
      setProjectId,
      isOpenForm,
      setIsOpenForm,
      reloadTable,
      mode,
      setMode,
      programRequestId,
      nirRequestId,
      securityDocumentContractId,
      closeForm,
      type,
      projectCopyOptions,
      setProjectCopyOptions,
      prototypeId,
      setPrototypeId,
    };
  };
}

import { useState, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { useLocalTableStreams } from 'features/Table/hooks';
import { State } from '../makeUseCustomController';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { RecordStatus } from 'utils/Enums';
import { getParticipationPermitList, isParticipationPermitted } from 'utils/Helpers/participation';

type Props = {
  customState: State;
  tableState: Table.State;
};

const useController = ({ customState, tableState }: Props) => {
  const { workMode, sourceEvent, reloadEvent } = customState;

  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();
  const { isProfile } = usePrivatePageContext();

  const [selectedRow] = tableState.selectedRows;

  const { methods: deleteParticipation } = BackendAPI.useBackendAPI('DeleteParticipation');

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isConfirmDeleteOpen, setIsConfirmDeleteOpen] = useState(false);
  const [isShowAwards, setIsShowAwards] = useState(false);
  const [isApproveParticipationModal, setIsApproveParticipationModal] = useState(false);
  const [isEditSubdivisionModal, setIsEditSubdivisionModal] = useState(false);

  const isHasAwards = useMemo<boolean>(() => !!(selectedRow?.Awards || '').length, [selectedRow]);
  const isApproved = useMemo<boolean>(() => selectedRow?.['id:RecordStatus'] === RecordStatus.APPROVED, [selectedRow]);

  const reloadTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const handleDeleteParticipation = useCallback(() => {
    deleteParticipation.callAPI(
      { participationId: selectedRow?.id || '' },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно удалено', theme: 'success' });
          reloadTable();
          setIsConfirmDeleteOpen(false);
          reloadEvent?.();
        },
        onFailedCall: () => {},
      },
    );
  }, [deleteParticipation, reloadEvent, reloadTable, selectedRow?.id]);

  const isDisabledButton = useMemo<boolean>(
    () => tableState.selectedRows.length !== 1 || workMode === 'viewMode' || sourceEvent?.id === '',
    [sourceEvent?.id, tableState.selectedRows.length, workMode],
  );

  const buttons: ButtonProps[] = useMemo(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.PARTICIPATION_EXPOSITION_VIEW },
        isDisabled: tableState.selectedRows.length !== 1 || workMode === 'viewMode' || sourceEvent?.id === '',
      },
      {
        icon: { type: 'add' },
        title: 'Добавление',
        onClick: () => setIsAddFormOpen(true),
        permission: { name: Permits.PARTICIPATION_EXPOSITION_ADD },
        isDisabled: workMode === 'viewMode' || sourceEvent?.id === '',
      },
      {
        icon: { type: 'edit' },
        title: 'Редактирование',
        onClick: () => setIsEditFormOpen(true),
        permission: {
          name: getParticipationPermitList('EXPOSITION', 'EDIT'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'EDIT'),
      },
      {
        icon: { type: 'remove' },
        title: 'Удаление',
        onClick: () => setIsConfirmDeleteOpen(true),
        permission: {
          name: getParticipationPermitList('EXPOSITION', 'DELETE'),
        },
        isDisabled: !isParticipationPermitted(userPermission, tableState.selectedRows, 'EXPOSITION', 'DELETE'),
      },
      {
        icon: { type: 'award' },
        title: isHasAwards ? 'Награды' : 'У участника нет наград',
        onClick: () => setIsShowAwards(true),
        isDisabled: isDisabledButton || !isHasAwards,
      },
      {
        icon: { type: 'like' },
        title: isApproved ? 'Выбранное участие уже утверждено' : 'Утвердить',
        onClick: () => setIsApproveParticipationModal(true),
        permission: { name: Permits.PARTICIPATION_EXPOSITION_APPROVE },
        isDisabled: isDisabledButton || isApproved,
        isHidden: isProfile,
      },
      {
        icon: { type: 'connection' },
        title: 'Подразделения',
        onClick: () => setIsEditSubdivisionModal(true),
        permission: { name: Permits.PARTICIPATION_EXPOSITION_DEPARTMENT_EDIT },
        isDisabled: isDisabledButton,
      },
    ],
    [tableState.selectedRows, workMode, sourceEvent?.id, userPermission, isHasAwards, isDisabledButton, isApproved, isProfile],
  );

  return {
    sourceEvent,
    buttons,
    isViewFormOpen,
    isAddFormOpen,
    isEditFormOpen,
    isConfirmDeleteOpen,
    isShowAwards,
    isApproveParticipationModal,
    isEditSubdivisionModal,
    reloadTable,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsConfirmDeleteOpen,
    handleDeleteParticipation,
    setIsShowAwards,
    setIsApproveParticipationModal,
    setIsEditSubdivisionModal,
  };
};

export default useController;

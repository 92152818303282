import React, { useCallback, useState } from 'react';

import { DataGrid } from 'features/Table';
import { GetPersonalCommunityMemberList, GetPersonalRecognitionList } from 'features/Table/specifications';

import { Tabs, Tab } from 'components';
import { GetPersonalDissertationCouncilMemberList } from 'features/Table/specifications/GetPersonalDissertationCouncilMemberList';
import { CommunityType } from 'utils/Enums';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

export function Recognitions(props: Props) {
  const { defaultTabId, onTabClick } = props;

  const { userPermission } = useAppDataContext();

  const [recognitionsFilter, setRecognitionsFilter] = useState<string>('mine');
  const [communitiesFilter, setCommunitiesFilter] = useState<string>('mine');

  const flushFilters = useCallback(() => {
    setRecognitionsFilter('mine');
    setCommunitiesFilter('mine');
  }, []);

  return (
    <Tabs
      defaultTabId={defaultTabId}
      onTabClick={id => {
        onTabClick?.(id);
        flushFilters();
      }}
    >
      <Tab
        title="Признания и награды"
        id="recognition"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_RECOGNITIONS_ACCESS)}
      >
        <DataGrid specification={GetPersonalRecognitionList({ recognitionsFilter, setRecognitionsFilter })} hasPersonalMode />
      </Tab>
      <Tab
        title="Работа в составе диссертационных советов"
        id="council"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_DISSERTATIONCOMMUNITIESPARTICIPATION_ACCESS)}
      >
        <DataGrid
          specification={GetPersonalDissertationCouncilMemberList({ communitiesFilter, setCommunitiesFilter })}
          hasPersonalMode
        />
      </Tab>
      <Tab
        title="Членство в научных сообществах и организациях"
        id="community"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_COMMUNITIESPARTICIPATION_ACCESS)}
      >
        <DataGrid
          specification={GetPersonalCommunityMemberList({
            communitiesFilter,
            setCommunitiesFilter,
            types: [
              CommunityType.SCIENTIFIC_COUNCIL,
              CommunityType.COLLABORATION,
              CommunityType.FUND,
              CommunityType.MAGAZINE_REDACTION,
            ],
          })}
          hasPersonalMode
        />
      </Tab>
    </Tabs>
  );
}

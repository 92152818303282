import React, { useEffect, useMemo } from 'react';

import { FormComponent, Tab, Tabs } from 'components';

import { Project } from 'types/models';
import { GetProjectPerformerOrderList, GetProjectScientistList } from 'features/Table/specifications';
import { DataGrid } from 'features/Table';
import { ProjectType } from 'utils/Enums';

type Props = {
  project: Project.Project;
  reloadProject(): void;
  saveProject(isSaveLoad?: boolean): void;
  getIsProjectChanged(): boolean;
  projectType: ProjectType;
  isDisabled?: boolean;
};

export const Performers = React.memo(
  ({ project, reloadProject, saveProject, getIsProjectChanged, projectType, isDisabled }: Props) => {
    useEffect(() => {
      if (!project?.id) {
        saveProject(true);
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const titleDetails = useMemo(() => {
      const currents = (project?.performers || []).filter(x => x.isCurrent);
      const youngs = currents.filter(x => x.isYoung);
      const currentsPercent =
        currents.length && youngs.length ? Math.round(((currents.length - youngs.length) / currents.length) * 100) : 0;
      const youngsPercent = currents.length && youngs.length ? Math.round((youngs.length / currents.length) * 100) : 0;

      return `на текущую дату: ${currents.length}, из них до 39 лет: ${youngs.length} (${currentsPercent} /${youngsPercent})`;
    }, [project?.performers]);

    return (
      <FormComponent.Template>
        <Tabs key={project?.id}>
          <Tab title="Состав коллектива">
            <DataGrid
              specification={GetProjectScientistList({
                titleDetails,
                projectId: project?.id ?? '-1',
                startProjectDate: project?.startDate ?? '',
                endProjectDate: project?.endDate ?? '',
                reloadProject,
                isProjectChanged: getIsProjectChanged(),
                projectType,
                isDisabled,
              })}
            />
          </Tab>
          <Tab title="Приказы по коллективу">
            <DataGrid specification={GetProjectPerformerOrderList({ projectId: project?.id ?? null, isDisabled })} />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    );
  },
);

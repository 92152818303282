import React from 'react';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { SecurityDocumentMemberAnalyticGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons, currentReport, isReportOpen, onReportClose, isHelpFormOpen, setIsHelpFormOpen } = useController({
    tableState,
  });
  return (
    <>
      <Toolbar buttons={buttons} />

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          securityDocumentId: tableState.selectedRows.length > 0 ? tableState.selectedRows[0]?.SecurityDocumentId : '',
        }}
      />

      <Modal
        mode="help"
        title={'Помощь для Аналитика ОИС по авторам'}
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {SecurityDocumentMemberAnalyticGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

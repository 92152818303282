import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ConferenceGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список конференций</h4>

        <p>Список проектов всех типов</p>
        <p>
          Таблица содержит список конференций университета, а также конференции, в которых принимали участие сотрудники и студенты
          университета.
        </p>
        <p>
          Фильтр <strong>Планируемые</strong> — для формирования списка конференции, у которых дата начала больше текущей даты.
        </p>
        <p>
          Фильтр <strong>Все</strong> — для формирования списка всех конференции.
        </p>
        <p>
          Фильтр <strong>Организованные</strong> — для списка конференций, у которых в качестве организаторов мероприятия указан
          университет и/или его подразделения.
        </p>
        <p>
          Фильтр <strong>Принято участие</strong> — для формирования списка конференций, других организаций, в которых приняли
          участие представители университета.
        </p>
        <p>
          Фильтр <strong>Год</strong> формирует список конференций, сроки проведения содержат указанный год, если фильтр отключен,
          то список содержит все конференции базы данных. Фильтр накладывает условия отбора с учётом других фильтров.
        </p>
        <p>
          Представление в таблице можно настраивать «под себя» (описание на вкладке <strong>Настройка таблицы</strong>).
        </p>

        <p>Предусмотрены два режима представления информации в таблице:</p>
        <ul>
          <li>режим ввода с максимальным количеством столбцов равным 27 в таблице;</li>
          <li>режим анализа данных с количеством столбцов — 50.</li>
        </ul>

        <p>
          Основные столбцы таблицы содержат данные конференции; названия столбцов соответствуют названиям полей на форме
          конференции.
        </p>
        <p>
          Дополнительные столбцы — статистические данные по участникам конференции, вычисляемые динамически на основе данных
          функционального блока <strong>Мероприятия, Участия в конференции</strong>
        </p>

        <h4>Особенности вывода информации в дополнительных столбцах таблицы:</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название столбца</th>
              <th>Описание значения в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Кол-во участников, всего(план)</td>
              <td>
                Плановое значение из раздела Показатели результативности мероприятия, Всего участников мероприятия, план (форма
                конференции, вкладка Организация мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, всего (факт)</td>
              <td>
                Фактическое значение из раздела Показатели результативности мероприятия, Всего участников мероприятия, факт (форма
                конференции, вкладка Организация мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, зарубеж.</td>
              <td>
                Общее количество участников из категории «Приглашённые-зарубежные» (форма конференции, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, местных</td>
              <td>
                Общее количество участников из категории «Приглашённые-местные» (форма конференции, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, из &lt;Университет&gt;</td>
              <td>
                Общее количество участников из категории «Приглашённые-местные» (форма конференции, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, иногород.</td>
              <td>
                Общее количество участников из категории «Приглашённые-иногородние» (форма конференции, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во связанных изданий</td>
              <td>
                Количество сборников материалов конференций или журналов (выпуск, связанный с конференцией) по данным
                функционального модуля Публикации (форма Конференции, вкладка Опубликованные материалы)
              </td>
            </tr>
            <tr>
              <td>Участники</td>
              <td>
                Количество персон по данным из функционального блока Мероприятия, Участия в конференции. Данные — без дубликатов.
              </td>
            </tr>
            <tr>
              <td>Участники из &lt;Университет&gt;</td>
              <td>
                Количество персон, аффилированных с университетом по данным из функционального блока Мероприятия, Участия в
                конференции (на форме конференции, вкладка Участники, раздел Статистические показатели конференции)
              </td>
            </tr>
            <tr>
              <td>Доклады &lt;Университет&gt;</td>
              <td>
                Количество докладов на конференции с авторами, аффилированными с Университетом, по данным из функционального блока
                Мероприятия, Участия в конференции (на форме конференции, вкладка Участники, раздел Статистические показатели
                конференции).
              </td>
            </tr>
            <tr>
              <td>Стимулирующие выплаты</td>
              <td>
                Информация о заявке на стимулирующие выплаты (по данным функционального блока Стимулирующие выплаты по
                конференциям)
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>

        <div className="warning">
          <span>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму для просмотра информации о мероприятии. Кнопка активна при выделении записи в таблице</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Открывает форму редактирования мероприятия. Кнопка активна при выделении записи в таблице.
                <br />
                Ограничения на редактирование данных на вкладках формы зависят от прав пользователя и статуса записи.
                <br />
                Так, например, пользователь всегда имеет право редактировать только «свою» запись в статусе «Добавлена» (если
                пользователь - автор записи, или в составе участников).
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Удаляет выделенную в таблице запись. Ограничения на удаление записи зависит от прав пользователя и статуса записи.
                <br />
                Так, например, пользователь всегда имеет право удалить только «свою» запись в статусе «Добавлена» (если
                пользователь - автор записи). Запись может быть связана сдругими объектами в системе (участие в мероприятии)
              </td>
            </tr>
            <tr>
              <td>
                Просмотр материалов конференции: <Icon type="library" mode="view" />
              </td>
              <td></td>
            </tr>
            <tr>
              <td>
                Процедура утверждения: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения. Ограничения на удаление записи зависит от прав пользователя.</td>
            </tr>
            <tr>
              <td>
                Печать документов: <Icon type="print" />
              </td>
              <td>
                Открывает список шаблонов для формирования регламентированных документов.
                <div className="warning">
                  <span>Список доступных шаблонов отчётных форм зависит от наличия права пользователя на группу отчётов.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список записей о мероприятиях выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Открывает форму для отправки сообщения в подсистему коммуникаций Системы. Ответственные за направление лица.
                Напишут ответ на ваш запрос. Все ответы на вопросы пользователя хранятся в{' '}
                <strong>Личном кабинете в разделе Вопрос-Ответ.</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>Как создать запись о конференции</h4>

        <ol>
          <li>
            Нажмите кнопку <strong>Добавить</strong> <Icon type="add" /> → откроется окно{' '}
            <strong>Поиск дубликатов конференции</strong>.
          </li>
          <li>
            Введите название мероприятия → нажмите кнопку <strong>Найти</strong> → в открывшемся списке внимательно изучите список
            ранее созданных записей. Если мероприятие уже добавлено в систему, кликните кнопку{' '}
            <strong>Отменить добавление</strong>.
          </li>
          <li>
            Если в списке мероприятие отсутствует, поставьте отметку в чек-бокс <strong>В списке нужной конференции нет</strong> и
            нажмите кнопку <strong>Продолжить добавление конференции</strong> → откроется окно{' '}
            <strong>Добавление конференции</strong>.
          </li>
          <li>
            Заполните все обязательные поля, отмеченные <strong className="state-danger">*</strong> на вкладке{' '}
            <strong>О мероприятии</strong>.
          </li>
          <li>
            Заполните информацию на вкладках{' '}
            <strong>
              Классификаторы, Опубликованные материалы, Организация мероприятия, Участники, Финансирование, Документы
            </strong>
            .
          </li>
          <li>
            Нажмите кнопку <strong>Сохранить</strong> <Icon type="save" /> или <strong>Сохранить и продолжить</strong>{' '}
            <Icon type="save" mode="add" /> (в случае продолжения работы на другой вкладке) → если документы не добавлены появится
            сообщение о необходимости добавления документов, если документы добавлены → переходите к шагу 8.
          </li>
          <li>
            В сообщении выберите кнопку <strong>Добавить документ</strong> → откроется вкладка <strong>Документы</strong> или
            выберите кнопку <strong>Сохранить в статусе Добавлена</strong> → откроется форма для создания сообщения администратору
            или ответственному за раздел от вашего подразделения.
          </li>
          <li>
            Напишите и отправьте сообщение или нажмите кнопку <strong>Продолжить без сообщения</strong> → Новая конференция в
            статусе <strong>Добавлена</strong>
            отобразится в таблице.
          </li>
        </ol>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React from 'react';

import { Performer } from 'types/models';
import { FormComponent } from 'components';
import { SelectPerson } from 'features/SelectPerson';

import { useController } from './controller';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
};

function PerformerFields({ entry, onFieldChange }: Props) {
  const { handlePersonChange, convertMemberToPerformer } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field label="ФИО" isRequired>
        <SelectPerson
          member={entry}
          onUpdateMember={val => handlePersonChange(convertMemberToPerformer(val))}
          hiddenButtons={['delete']}
        />
      </FormComponent.Field>
    </FormComponent.Wrapper>
  );
}

export { PerformerFields };

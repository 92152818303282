import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Settings } from 'types/models/common';
import {
  BindParticipationToCurrentUserDepartmentRequest,
  ApproveParticipationRequest,
  GetParticipationRequest,
  SaveParticipationRequest,
  DeleteParticipationRequest,
} from './types';

import {
  convertServerParticipation,
  convertBindResponseXMLElementToOutput,
  convertApproveParticipationResponseXMLElementToOutput,
  convertDeleteParticipationResponseXMLElementToOutput,
} from './converters';

import { makeParticipationRequestXMLConverter } from './requestConverters';

import { convertSaveResponseXMLElementToOutput } from '../commonConverters/index';

export const participationConfigurations = {
  GetParticipation: makeBackendAPIConfiguration({
    id: 'GetParticipation',
    endpoint: '/msa/service/commands/GetParticipation',
    convertInputToXMLElement: ({
      participationId,
      modules,
      eventModules,
      sourcePublicationId,
      sourceEventId,
      eventType,
      arm,
    }: GetParticipationRequest) => {
      const requestBody: any = {
        Request: {
          _attr: {
            commandName: 'GetParticipation',
            ...(arm ? { workbench: arm } : {}),
          },
          Participation: {
            _attr: {
              ...(participationId ? { id: participationId } : {}),
              modules: modules.join(','),
              eventModules: eventModules.join(','),
            },
            IsInTeam: false,
            IsCollectiveExhibit: false,
            Departments: {},
            Members: {},
            Documents: {},
            Awards: {},
            MobileRequests: {},
            Enterprises: {},
            PresentationForms: {},
            Projects: {},
            ParticipationProcedure: 'WITHOUT_REQUEST',
          },
        },
      };
      if (eventType) {
        requestBody.Request.Participation.EventType = eventType;
      }
      if (sourcePublicationId && sourceEventId) {
        requestBody.Request.Participation.SourcePublication = {
          _attr: {
            id: sourcePublicationId,
          },
        };
        requestBody.Request.Participation.SourceEvent = {
          _attr: {
            id: sourceEventId,
          },
        };
      }
      return requestBody;
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertServerParticipation(response.Response.Participation, settings),
  }),
  SaveParticipation: makeBackendAPIConfiguration({
    id: 'SaveParticipation',
    endpoint: '/msa/service/commands/SaveParticipation',
    convertInputToXMLElement: ({
      participationId,
      simpleFields,
      attrIdFields,
      otherFields,
      isPublicationCreationSource,
    }: SaveParticipationRequest) => ({
      Request: {
        _attr: { commandName: 'SaveParticipation' },
        Participation: {
          _attr: {
            ...(participationId ? { id: participationId } : {}),
          },
          ...makeParticipationRequestXMLConverter({ simpleFields, attrIdFields, otherFields, isPublicationCreationSource }),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSaveResponseXMLElementToOutput(response),
  }),
  BindParticipationToCurrentUserDepartment: makeBackendAPIConfiguration({
    id: 'BindParticipationToCurrentUserDepartment',
    endpoint: '/msa/service/commands/BindParticipationToCurrentUserDepartment',
    convertInputToXMLElement: ({ participationId }: BindParticipationToCurrentUserDepartmentRequest) => ({
      Request: {
        _attr: { commandName: 'BindParticipationToCurrentUserDepartment' },
        Participation: {
          _attr: {
            ...(participationId ? { id: participationId } : {}),
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertBindResponseXMLElementToOutput(response),
  }),
  ApproveParticipation: makeBackendAPIConfiguration({
    id: 'ApproveParticipation',
    endpoint: '/msa/service/commands/ApproveParticipation',
    convertInputToXMLElement: ({ participationId, disapprove }: ApproveParticipationRequest) => ({
      Request: {
        _attr: { commandName: 'ApproveParticipation' },
        Participation: { _attr: { id: participationId, disapprove } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertApproveParticipationResponseXMLElementToOutput(response),
  }),
  DeleteParticipation: makeBackendAPIConfiguration({
    id: 'DeleteParticipation',
    endpoint: '/msa/service/commands/DeleteParticipation',
    convertInputToXMLElement: ({ participationId, workbench }: DeleteParticipationRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteParticipation', ...(workbench ? { workbench } : {}) },
        Participation: { _attr: { id: participationId } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertDeleteParticipationResponseXMLElementToOutput(response),
  }),
};

import React, { memo } from 'react';

import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps, CheckboxItem } from 'features/BuildReportPopup/models';
import { useAppDataContext } from 'features/AppData/context';
import { getEnumItemLabel } from 'utils/Helpers';
import { ProjectType } from 'utils/Enums';

const ProjectTypes = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { enumMap } = useAppDataContext();
  const initialProjectTypeCheckboxes: CheckboxItem[] = [
    {
      value: '0',
      label: getEnumItemLabel('ProjectType', ProjectType.GOVERMENT_CONTRACT, enumMap),
      isChecked: false,
    },
    {
      value: '1',
      label: getEnumItemLabel('ProjectType', ProjectType.ECONOMIC_AGREEMENT, enumMap),
      isChecked: false,
    },
    {
      value: '2',
      label: getEnumItemLabel('ProjectType', ProjectType.NIR, enumMap),
      isChecked: false,
    },
    {
      value: '3',
      label: getEnumItemLabel('ProjectType', ProjectType.GRANT, enumMap),
      isChecked: false,
    },
  ];

  return (
    <DefaultCheckboxList
      initialCheckboxList={initialProjectTypeCheckboxes}
      parameterName={parameterName}
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
    />
  );
};

const Component = memo(ProjectTypes);

export { Component as ProjectTypes };

import React, { memo } from 'react';

import { FormComponent, TextInput, TextInputMode } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const AbstractInteger = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { inputValue, currentParameter, onInputChange } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });
  return (
    <FormComponent.Line>
      <FormComponent.Field label={currentParameter?.description ?? 'INTEGER INPUT'} isRequired={currentParameter?.required}>
        <TextInput
          mode={TextInputMode.NUMBER}
          value={inputValue}
          onChange={onInputChange}
          settings={{ decimal: false, isThousands: false }}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(AbstractInteger);

export { Component as AbstractInteger };

import React from 'react';
import { FormComponent } from 'components';
import looks from './looks';
import { useFormContext } from './hooks';

type Props = {
  onClose?: () => void;
};

function Form<SpecificLook>({ onClose }: Props) {
  const {
    look: { template, viewMode, editMode },
  } = useFormContext<SpecificLook>();

  let CustomForm: null | React.MemoExoticComponent<any> = null;

  if (template && template in looks) {
    CustomForm = looks[template].Component as React.MemoExoticComponent<any>;
  }

  return (
    <FormComponent.Template>
      {CustomForm ? (
        <CustomForm
          {...({
            viewMode: !!viewMode,
            editMode: !!editMode,
            onClose: onClose,
          } as any)}
        />
      ) : (
        <>{`invalid template arg (template = ${template})`}</>
      )}
    </FormComponent.Template>
  );
}

export const Component = React.memo(Form);

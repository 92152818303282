import React from 'react';

import { EntityLocker } from 'features/EntityLocker';
import { Table } from 'types/models';

import { State } from '../model';

import { Form as Component } from './Form';

type Props = {
  customState: State;
  tableState: Table.State;
};

export function Form({ customState, tableState }: Props) {
  const { mode, isOpenForm, toggleVisibleForm } = customState;
  const [row] = tableState.selectedRows;

  return mode === 'edit' && row ? (
    <EntityLocker onClose={toggleVisibleForm} isOpen={isOpenForm} id={row.id} entity="AuthorPaymentRequest">
      <Component customState={customState} tableState={tableState} />
    </EntityLocker>
  ) : (
    <Component customState={customState} tableState={tableState} />
  );
}

import React, { memo, useCallback } from 'react';

import {
  ButtonMode,
  FormComponent,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetMonographListSpecification } from 'features/Table/specifications';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  formFields: F.Fields;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  changeSourceCompilation: (original: F.Compilation | null) => void;
  onDeleteSourceCompilation: () => void;
  sourceMonograph: F.Compilation | null;
  relatedTableState: T.State | undefined;
  isDisableSource: boolean;
  sourcePublicationDate: string;
  isElectronic: boolean;
  publicationId: string;
};

const Source = ({
  isElectronic,
  publicationId,
  workMode,
  formFields,
  onCreateBibliographicRecordClick,
  changeSourceCompilation,
  sourceMonograph,
  relatedTableState,
  isDisableSource,
  sourcePublicationDate,
}: Props) => {
  const modalTableRowToSourceCompilationConventer = useCallback<(row: T.Entry) => F.Compilation>(
    row => ({
      id: row.id,
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: `${row.status}. Год выпуска ${row.year ? row.year : 'не указан'}`,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );
  // GetSimpleMagazineListSpecification
  const sourceMonographTableConfig = GetMonographListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState,
        relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
      },
    },
    isElectronic,
    monographChapterIds: publicationId,
  });

  return (
    <>
      <SectionTitle title="Издание" />

      <FormComponent.Line>
        <FormComponent.Field label="Коллективная монография" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Монографии',
              visibleStatus: getStatusRecordMessage('', sourceMonograph?.id, sourceMonograph?.status),
              isClearable: false,
              table: {
                specification: sourceMonographTableConfig,
                onSelect: (row: T.Entry | null) =>
                  changeSourceCompilation(row ? modalTableRowToSourceCompilationConventer(row) : null),
              },
            }}
            value={sourceMonograph?.bibliographic}
            isDisabled={workMode === 'viewMode' || isDisableSource}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!!sourcePublicationDate && (
        <FormComponent.Line>
          <FormComponent.Field label="Дата публикации">
            <FormComponent.Text>{sourcePublicationDate}</FormComponent.Text>
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line>
        <FormComponent.Field label="Язык текста" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.textLanguage.value}
            onChange={formFields.textLanguage.onChange}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label={formFields.printPageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.printPageCount.value}
            onChange={formFields.printPageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageFrom.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.pageFrom.value}
            onChange={formFields.pageFrom.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.pageTo.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.pageTo.value}
            onChange={formFields.pageTo.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.chapterNumber.title} labelSize="fit">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={formFields.chapterNumber.value}
            onChange={formFields.chapterNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            classMixin="publication-input-number"
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />

      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            settings={{
              rows: 3,
              externalButtons: [
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Построить',
                  onClick: onCreateBibliographicRecordClick,
                  isDisabled: workMode === 'viewMode',
                },
              ],
            }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.grnti.title} tooltip={formFields.grnti.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.grnti.value}
            onChange={formFields.grnti.onChange}
            settings={{ name: 'RefGrnti', title: 'Справочник "ГРНТИ"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.study.title} tooltip={formFields.study.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.study.value}
            onChange={formFields.study.onChange}
            settings={{ name: 'RefStudy', title: 'Справочник "Область науки"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};

export const SourceComponent = memo(Source);

import React, { useMemo } from 'react';
import { setup } from 'bem-cn';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('form-component-line');

type Props = {
  /** @property {boolean} hasFreeFormat - (optional) Free size of content */
  hasFreeFormat?: boolean;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[]} children
   * - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[];
};

/**
 * Line element with proporcial blocks of content (see CSS of component)
 *
 * @param {boolean} hasFreeFormat - (optional) Is hidden field
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {React.ReactNode | JSX.Element | false | (React.ReactNode | JSX.Element | false)[]} children
 *  - (optional) React inner JSX component
 * @returns JSX
 */
export const Line = React.memo(({ hasFreeFormat, style, children }: Props) => {
  const fields = useMemo(() => (Array.isArray(children) ? children : [children]), [children]);

  return (
    <div className={b({}).mix(hasFreeFormat ? 'is-free-format' : '')} style={style}>
      {fields.filter(Boolean).map((item, index) => (
        <div key={index} className={b('item')}>
          {item}
        </div>
      ))}
    </div>
  );
});

import React, { memo, useCallback } from 'react';

import { FormComponent, ReferenceItem, Select } from 'components';

import { Table } from 'types/models';
import { Item } from 'types/models/common';

type Props = {
  referenceItem: ReferenceItem | null;
  setReferenceItem: (referenceItem: ReferenceItem | null) => void;
  enumValues: Table.EnumValue[];
  fieldLabel: string;
};

const DefaultSelect = ({ referenceItem, setReferenceItem, enumValues, fieldLabel }: Props) => {
  const handleSetReferenceItem = useCallback(
    (nextEnumLabel: string) => {
      const nextEnumValue = enumValues.find(i => i.value === nextEnumLabel);
      if (nextEnumValue) {
        setReferenceItem({
          id: nextEnumValue.ordinal.toString(),
          label: nextEnumValue.label,
          code: nextEnumValue.value,
        });
      }
    },
    [enumValues, setReferenceItem],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field isRequired label={fieldLabel}>
        <Select
          value={{ value: referenceItem?.id || '', label: referenceItem?.label || '' }}
          options={enumValues as Item[]}
          onChange={(option: Item) => handleSetReferenceItem(option.value)}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
};

const Component = memo(DefaultSelect);

export { Component as DefaultSelect };

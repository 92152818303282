import React, { useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { block } from 'bem-cn';

import { Icon } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { useStorage } from 'utils/Common';

import './style.scss';

const b = block('user-info');

function Topbar() {
  const {
    setUserToken,
    isHasPermissionToMain,
    isHasPermissionToAccount,
    userPermission,
    userSystemDepartment,
  } = useAppDataContext();

  const location = useLocation();

  const storage = useStorage('token');

  const userSignout = (e: any) => {
    e.preventDefault();
    setUserToken(null);
    storage.remove();
    window.location.reload();
  };

  const isPersonalMode = useMemo(() => location.pathname.indexOf('account') > -1, [location]);

  return (
    <div className="page-layout__topbar">
      <div className={b()} tabIndex={-1}>
        <Icon type="logo" size={24} classMixin={b('img')} />
        <div className={b('box')}>
          <strong className={b('name')}>{userPermission?.userLogin}</strong>
          <span className={b('division')}>{userSystemDepartment?.label}</span>
        </div>
        <Icon type="chevronDown" size={14} classMixin={b('btn')} />

        <div className={b('menu')}>
          <div className={b('menu-item')}>
            {isPersonalMode
              ? isHasPermissionToMain && (
                  <NavLink
                    className={b('menu-link').toString()}
                    key="main"
                    to="/home"
                    title="На главную"
                    onMouseDownCapture={() => {
                      window.location.hash = '/home';
                    }}
                  >
                    На главную
                  </NavLink>
                )
              : isHasPermissionToAccount && (
                  <NavLink
                    className={b('menu-link').toString()}
                    key="home"
                    to="/account"
                    title="Личный кабинет"
                    onMouseDownCapture={() => {
                      window.location.hash = '/account';
                    }}
                  >
                    Личный кабинет
                  </NavLink>
                )}
          </div>
          <div className={b('menu-item')}>
            <NavLink
              className={b('menu-link').toString()}
              onClick={userSignout}
              key="exit"
              to="#"
              title="Выйти из системы"
              onMouseDownCapture={userSignout}
            >
              Выйти из системы
            </NavLink>
          </div>
        </div>
      </div>
    </div>
  );
}

export const Component = React.memo(Topbar);

import { useState, useCallback } from 'react';
import ReactDOM from 'react-dom';
import * as XML from 'xml-js';
import * as TS from './types';

import { showNotification } from 'features/Notifications';
import { getEnvParams } from 'utils/getEnvParams';
import { useLocalLoaderStreams } from 'features/Loader/hooks';
import { useAppDataContext } from 'features/AppData/context';
import { App as AppEvents } from 'utils/Events';
import { ResponseStatus } from 'utils/Enums/ResponseStatus';
import { backendAPIConfigurations } from './configurations';
import { isErrorResponse, getErrorMessage } from './helpers';
// import { getDebugResponse } from './debug';

const UNAVAILABLE_STATUS_NAME = 'UNAVAILABLE_STATUS';

const XML_INVALID_CHARACTERS: Record<string, { symbol: string; replaceSymbol: string }> = {
  less: {
    symbol: '<',
    replaceSymbol: '&lt;',
  },
  ampersand: {
    symbol: '&',
    replaceSymbol: '&amp;',
  },
  more: {
    symbol: '>',
    replaceSymbol: '&gt;',
  },
  singleQuote: {
    symbol: "'",
    replaceSymbol: '&apos;',
  },
  doubleQuote: {
    symbol: '"',
    replaceSymbol: '&quot;',
  },
};

// eslint-disable-next-line max-len, no-control-regex
const unicodeRegExp = /((?:[\0-\x08\x0B\f\x0E-\x1F\uFFFD\uFFFE\uFFFF]|[\uD800-\uDBFF](?![\uDC00-\uDFFF])|(?:[^\uD800-\uDBFF]|^)[\uDC00-\uDFFF]))/g;

export function useBackendAPI<ID extends TS.CID>(
  id: ID,
  hookOptions?: Partial<TS.HookOptions<ID>>,
  isAppLockDisabled?: boolean,
): {
  state: TS.CallState<TS.Output<ID>>;
  methods: {
    callAPI(input: TS.Input<ID>, callOptions?: Partial<TS.CallOptions<ID>>, token?: string): void;
    abortCurrentCall(): void;
  };
} {
  const [callState, setCallState] = useState<TS.CallState<TS.Output<ID>>>({ kind: 'initial' });
  const [abortController, setAbortController] = useState(new AbortController());

  const { onSuccessfullCall, onFailedCall, shouldAbortCallIfPreviousNotCompleted = true } = hookOptions ?? {};
  const config = backendAPIConfigurations[id];

  const abortCurrentCall = useCallback(() => {
    if (callState.kind === 'pending') {
      abortController.abort();
    }
  }, [abortController, callState.kind]);

  const { userToken, settings, initialOrganizationCode } = useAppDataContext();
  const loaderStreams = useLocalLoaderStreams();

  const callAPI = useCallback(
    (input: TS.Input<ID>, callOptions?: Partial<TS.CallOptions<ID>>, token?: string) => {
      const signal =
        shouldAbortCallIfPreviousNotCompleted && callState.kind === 'pending'
          ? (() => {
              if (!abortController.signal.aborted) abortController.abort();
              const newAbortController = new AbortController();
              setAbortController(newAbortController);
              return newAbortController.signal;
            })()
          : abortController.signal;

      const isTSU = initialOrganizationCode?.code === 'TSU';

      const xmlObject = config.convertInputToXMLElement(input, undefined, isTSU);
      const body = XML.js2xml(xmlObject, {
        compact: true,
        spaces: 4,
        attributesKey: '_attr',
        attributeValueFn(val) {
          // maybe use -> .replace(/[&<>"']/g, ($0) => `&${{'&': 'amp', '<':'lt', '>':'gt', '"':'quot', "'":'#39'}[$0]};`) ????
          return val
            .replaceAll(XML_INVALID_CHARACTERS.doubleQuote.replaceSymbol, XML_INVALID_CHARACTERS.doubleQuote.symbol)
            .replaceAll(XML_INVALID_CHARACTERS.ampersand.symbol, XML_INVALID_CHARACTERS.ampersand.replaceSymbol)
            .replaceAll(XML_INVALID_CHARACTERS.less.symbol, XML_INVALID_CHARACTERS.less.replaceSymbol)
            .replaceAll(XML_INVALID_CHARACTERS.doubleQuote.symbol, XML_INVALID_CHARACTERS.doubleQuote.replaceSymbol)
            .replaceAll(unicodeRegExp, '');
        },
        textFn(val) {
          return val.replaceAll(unicodeRegExp, '');
        },
      });

      setCallState({ kind: 'pending' });
      if (!isAppLockDisabled) loaderStreams.updateIsLoading.push({ isLoading: true });

      const url = (() => {
        let prefix = '';

        const pathname = config.getEndpoint?.(input) ?? config.endpoint;

        if (!pathname.indexOf('/report/service') || !pathname.indexOf('/msa/service')) {
          if (getEnvParams().isStable) {
            prefix = '/stable';
          }
        }
        return `${prefix}${pathname}`;
      })();

      fetch(url, {
        headers: {
          Auth: token || userToken || '',
          'Content-type': 'application/xml',
        },
        body,
        method: 'POST',
        signal,
      })
        .then(response => {
          const isNotAuth = response.status === ResponseStatus.NOT_AUTH_STATUS;

          if (isNotAuth) {
            document.dispatchEvent(new CustomEvent(AppEvents.NOT_AUTH));
          }

          return response.text();
        })
        .then(text => {
          // if wanna debug, where condition by input inside function
          // const response = XML.xml2js(getDebugResponse(input) || text, { compact: true }) as XML.ElementCompact;
          const response = XML.xml2js(text, { compact: true }) as XML.ElementCompact;
          const isError = isErrorResponse(response);
          const errorMessage = getErrorMessage(response);

          if (isError) {
            showNotification({ message: errorMessage, theme: 'danger' });
            setCallState({ kind: 'error', message: errorMessage });
            try {
              const data = config.convertResponseXMLElementToOutput(response, settings || undefined, isTSU) as TS.Output<ID>;
              ReactDOM.unstable_batchedUpdates(() => {
                callOptions?.onFailedCall?.({ data, input, errorMessage }); // if called from .callAPI
                onFailedCall?.({ data, input, errorMessage }); // if called from declaration
              });
            } catch (error) {
              console.warn('Failed call parser error. Check if parsed object is used in OnFailedCall.\n', error);
            }
            return;
          }

          const data = config.convertResponseXMLElementToOutput(response, settings || undefined, isTSU) as TS.Output<ID>;

          setCallState({ kind: 'successfull', data });

          ReactDOM.unstable_batchedUpdates(() => {
            onSuccessfullCall?.({ data, input });
            callOptions?.onSuccessfullCall?.({ data, input });
          });
        })
        .catch((error: Error) => {
          if (error.name === UNAVAILABLE_STATUS_NAME) {
            setCallState({ kind: 'error', message: error.message });
            ReactDOM.unstable_batchedUpdates(() => {
              callOptions?.onFailedCall?.({ input, errorMessage: error.message, status: parseInt(error.message, 10) });
              onFailedCall?.({ input, errorMessage: error.message, status: parseInt(error.message, 10) });
            });
          } else if (error.name !== 'AbortError') {
            if (getEnvParams().isDevelopment) {
              // eslint-disable-next-line no-console
              console.error(error);
            }
            setCallState({ kind: 'error', message: error.message });
            ReactDOM.unstable_batchedUpdates(() => {
              callOptions?.onFailedCall?.({ input, errorMessage: error.message });
              onFailedCall?.({ input, errorMessage: error.message });
            });
          }
        })
        .finally(() => {
          if (!isAppLockDisabled) loaderStreams.updateIsLoading.push({ isLoading: false });
        });
    },
    [
      shouldAbortCallIfPreviousNotCompleted,
      callState.kind,
      abortController,
      initialOrganizationCode?.code,
      config,
      isAppLockDisabled,
      loaderStreams.updateIsLoading,
      userToken,
      settings,
      onFailedCall,
      onSuccessfullCall,
    ],
  );

  return { state: callState, methods: { callAPI, abortCurrentCall } };
}

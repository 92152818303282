import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

export const GetScientificIndicesList: Table.Specification = {
  apiID: 'GetScientificIndicesList',
  header: {
    firstLevel: {
      title: 'Наукометрические показатели',
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: LeftPanelForThirdLevel,
    },
  },
};

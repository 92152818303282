import { useCallback, useEffect, useState } from 'react';

import * as BackendAPI from 'services/BackendAPI';

import { ValueOf } from 'types/helpers';
import { Partner } from 'types/models';
import { showNotification } from 'features/Notifications';
import { validate } from 'features/EditPartner/validate';
import { showErrors } from 'utils/Validators';
import { useLocalTableStreams } from 'features/Table/hooks';

type Arguments = {
  externalPartner: Partner | null;
  onSuccessSave(): void;
};

export function useController(args: Arguments) {
  const { externalPartner, onSuccessSave } = args;

  const tableStreams = useLocalTableStreams();

  const [partner, setPartner] = useState<Partner | null>(null);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  const { methods: getPartnerAPI } = BackendAPI.useBackendAPI('GetPartner');

  const loadPartner = useCallback(
    (partnerId: string) => {
      getPartnerAPI.callAPI(
        {
          partnerId: partnerId,
          enterpriseCustomerId: null,
          enterpriseId: null,
          shortName: null,
          fullName: null,
        },
        {
          onSuccessfullCall: ({ data }) => {
            setPartner(data);
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const { methods: savePartnerAPI } = BackendAPI.useBackendAPI('SavePartner');

  const savePartner = useCallback(
    (currentPartner: Partner, needClose?: boolean) => {
      if (currentPartner) {
        savePartnerAPI.callAPI(
          { partner: currentPartner },
          {
            onSuccessfullCall: ({ data }) => {
              showNotification({ message: 'Партнёр успешно сохранён', theme: 'success' });
              if (needClose) {
                tableStreams.reloadTable.push({});
                onSuccessSave();
              } else if (data.id) {
                loadPartner(data.id);
              }
            },
          },
        );
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [onSuccessSave],
  );

  const onSavePartner = useCallback(
    (needClose?: boolean) => {
      if (partner) {
        const validationInfo = validate(partner);
        if (validationInfo.some(x => !x.isValid)) {
          showErrors(validate, partner);
          return;
        }
        savePartner(partner, needClose);
      }
    },
    [savePartner, partner],
  );

  const makeChangeHandler = (key: keyof Partner) => (value: ValueOf<Partner>) => {
    setPartner(prevPartner => ({ ...prevPartner!, [key]: value }));
  };

  const onHelp = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  useEffect(() => {
    setPartner(externalPartner);
  }, [externalPartner]);

  return {
    partner,
    makeChangeHandler,
    onSavePartner,
    setPartner,
    onHelp,
    isHelpFormOpen,
    setIsHelpFormOpen,
  };
}

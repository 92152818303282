import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import {
  GetDashboardDetailMagazineArticleList,
  GetDashboardDetailMagazineList,
  GetReportByDepartmentMappingPublicationList,
  GetScopusWosPublicationProjectList,
} from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function EfficiencyAnalysis() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['publications', 'efficiency-analysis'],
    init: ['magazine_article'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Статьи в журналах" id="magazine_article">
          <DataGrid specification={GetDashboardDetailMagazineArticleList()} />
        </Tab>
        <Tab title="Данные по журналам за 5 лет" id="magazine">
          <DataGrid specification={GetDashboardDetailMagazineList()} />
        </Tab>
        <Tab title="Публикации Scopus, WoS по факультетам" id="report_by_department">
          <DataGrid specification={GetReportByDepartmentMappingPublicationList()} />
        </Tab>
        <Tab title="Публикации Scopus, WoS по проектам" id="scopus_wos">
          <DataGrid specification={GetScopusWosPublicationProjectList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

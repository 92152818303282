import React, { Dispatch, useCallback, useState } from 'react';

import { Person } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';

export type State = {
  person: Person.ScientistPerson | null;
  isOpenForm: boolean;
  mode: Mode | null;
  setIsOpenForm(isOpen: boolean): void;
  openForm(): void;
  closeForm(): void;
  loadScientist(personId: string): void;
  setPerson: Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
  setMode(mode: Mode | null): void;
};

export type Mode = 'add' | 'edit' | 'view';

export function makeUseCustomController() {
  return function useCustomController(): State {
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const [person, setPerson] = useState<Person.ScientistPerson | null>(null);

    const { methods: GetScientistDataAPI } = BackendAPI.useBackendAPI('GetScientistData');

    const openForm = useCallback(() => {
      setIsOpenForm(true);
    }, []);

    const closeForm = useCallback(() => {
      setIsOpenForm(false);
      setPerson(null);
      setMode(null);
    }, []);

    const loadScientist = (personId: string) => {
      GetScientistDataAPI.callAPI(
        { personId },
        {
          onSuccessfullCall: ({ data }: { data: Person.ScientistPerson }) => {
            setPerson(data);
          },
        },
      );
    };

    return {
      person,
      isOpenForm,
      setIsOpenForm,
      openForm,
      closeForm,
      loadScientist,
      setPerson,
      mode,
      setMode,
    };
  };
}

import { Project } from 'types/models';

export function validate(
  resourceUsageDepartment: Project.ResourceUsageDepartment | null,
  list: Project.ResourceUsageDepartment[],
  mode: 'view' | 'add' | 'edit' | null,
  index: number | null,
) {
  const resourceUsageDepartments = mode === 'edit' ? list.filter((_, itemIndex) => index !== itemIndex) : list;

  const isSameItemAlredyExist = resourceUsageDepartments.find(
    ({ department }) => department?.id === resourceUsageDepartment?.department?.id,
  );
  const isValidDepartment = Boolean(resourceUsageDepartment?.department?.id);

  return [
    { isValid: !isSameItemAlredyExist, invalidMessage: 'Такой элемент уже добавлен' },
    { isValid: isValidDepartment, invalidMessage: 'Выберите подразделение' },
  ];
}

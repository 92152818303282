import React, { useEffect, useState } from 'react';

import { ButtonMode, Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

const MagazineAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const [isPreprint, setIsPreprint] = useState<boolean | null>(null);
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.PublicationFormLook = {
    id,
    apiID: 'GetPublication',
    template: 'MagazineForm',
    type: 'Magazine',
    relatedTableState,
    isPreprint,
    setTitle,
  };

  useEffect(() => {
    if (isOpen) setIsPreprint(null);
  }, [isOpen]);

  return (
    <>
      {isPreprint === null ? (
        <Modal
          title="Создание нового журнала"
          isOpen={isOpen}
          onClose={onClose}
          actions={[
            {
              mode: ButtonMode.SECONDARY,
              text: 'Препринт',
              onClick: () => setIsPreprint(true),
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Обычный',
              onClick: () => setIsPreprint(false),
            },
          ]}
          size="small"
        >
          <p>Журнал какой категории вы хотите создать?</p>
        </Modal>
      ) : (
        <Modal title={title} isOpen={isOpen} onClose={onClose} size="full">
          <Form look={look} onClose={onClose} />
        </Modal>
      )}
    </>
  );
};
export const Component = React.memo(MagazineAddTemplate);

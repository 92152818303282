import React, { useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { setup } from 'bem-cn';

import { ButtonProps, FormComponent, Modal, RelationTableModal, Toolbar } from 'components';

import { Table } from 'types/models';
import { InputAttributes } from 'types/models/common';
import { showNotification } from 'features/Notifications';
import { SubmitTable } from 'features/Table/streams';
import { getInvalid } from 'utils/Helpers/filterSymbols';
import { clearWhitespaceCharacters } from 'utils/Helpers/clearWhitespaceCharacters';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const w = block('text-area-wrapper');
const b = block('text-area');
const q = block('quill');

const maxSymbols = 21845;
const defaultSymbolsWysiwyg = 2 * 1000 * 1000;

/**
 * Default Quill Toolbar
const defaultToolbar = [
  [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image'],
  ['clean'],
];
*/

const wysiwygEmptyList = ['<p></p>', '<p> </p>', '<p><br></p>'];

const notificationsToolbar = [
  // [{ header: [1, 2, false] }],
  ['bold', 'italic', 'underline', 'strike', 'blockquote'],
  [{ list: 'ordered' }, { list: 'bullet' }, { indent: '-1' }, { indent: '+1' }],
  ['link', 'image'],
  ['clean'],
];

type TextProps = {
  /** @property {number} rows - (optional) Rows count (2 by default) */
  rows?: number;
  /** @property {number} type - (optional) Max length of string */
  maxLength?: number;
  /** @property {boolean} isFixed - (optional) Is fixed size of TextArea */
  isFixed?: boolean;
  /** @property {ButtonProps[]} externalButtons - Extra buttons */
  externalButtons?: ButtonProps[];
  /** @property {boolean} isClearable - Show button of clear (false by default) */
  isClearable?: boolean;
};

type ModalProps = TextProps & {
  /** @property {string} title - (optional) Modal title */
  title?: string;
};

type TableTableProps<Row = any> = {
  /** @property {Table.Specification} specification - Table specification */
  specification: Table.Specification;
  /** @property {Function} onSelect - Callback on row is selected */
  onSelect(row: Row | null): void;
};

type TableProps = ModalProps & {
  /** @property {string} iconTitle - Tooltip on add button */
  iconTitle?: string;
  /** @property {boolean} isClearable - Show button of clear (true by default) */
  isClearable?: boolean;
  /** @property {string} visibleStatus - Show status under a textarea */
  visibleStatus?: string;
  /** @property {boolean} help - Show help inside relative modal */
  help?: string;
  /** @property {boolean} tooltip - Show tooltip inside relative modal */
  tooltip?: string;
  /** @property {TableTableProps} table - External table params */
  table: TableTableProps;
  /** @property {string} permissionName - PermissionName for buttons */
  permissionName?: string;
};

export enum TextAreaMode {
  /** Any text */
  TEXT = 'text',
  /** With modal */
  MODAL = 'modal',
  /** wysiwyg editor */
  WYSIWYG = 'wysiwyg',
  /** List as table */
  LIST = 'list',
  /** Relative table */
  TABLE = 'table',
}

/**
 * Settings for textarea component
 */
export type Props = InputAttributes & {
  /** @property {TextAreaMode} type - (optional) Type of Mask (TextAreaMode.TEXT by default) */
  mode?: TextAreaMode;
  /** @property {TextProps | ModalProps | ListProps | TableProps} settings - (optional) Settings for type TextAreaMode */
  settings?: any;
  /** @property {string} value - (optional) Value for textarea */
  value?: string;
  /** @property {function} onChange - (optional) Callback on text changed */
  onChange?(val: string): void;
  /** @property {boolean} isDisabled - (optional) Set is disabled textarea (false by default) */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) Set is error textarea (false by default) */
  isError?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
} & (
    | {
        mode?: TextAreaMode.TEXT;
        /** @property {TextProps} settings - (optional) Settings for text */
        settings?: TextProps;
      }
    | {
        mode: TextAreaMode.MODAL | TextAreaMode.WYSIWYG;
        /** @property {ModalProps} settings - (optional) Settings for text with modal */
        settings?: ModalProps;
      }
    | {
        mode: TextAreaMode.TABLE;
        /** @property {TableProps} settings - (optional) Settings for text with table */
        settings?: TableProps;
      }
  );

/**
 * Textarea component
 *
 * @param {TextAreaMode} mode - Typeof component (See TextAreaMode enum)
 * @param {TextProps | NumberProps | YearProps} settings - (optional) Settings for type TextAreaMode
 * @param {string} value - (optional) Value for textarea
 * @param {function} onChange - (optional) Callback on text changed
 * @param {boolean} isDisabled - (optional) Set is disabled textarea (false by default)
 * @param {boolean} isError - (optional) Set is error textarea (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const TextArea = React.memo((props: Props) => {
  const {
    mode = TextAreaMode.TEXT,
    settings = {},
    onClick,
    onFocus,
    onChange,
    isDisabled = false,
    isError = false,
    classMixin,
    value,
    ...restProps
  } = props;

  const [valueState, setValueState] = useState<string>(value || '');
  const [isFocusedQuill, setIsFocusedQuill] = useState<boolean>(false);

  const [toolbarOffset, setToolbarOffset] = useState<string>('');
  const refToolbar = useRef<HTMLDivElement>();

  useEffect(() => {
    setValueState(value || '');
  }, [value]);

  const className = useMemo(() => {
    const isListOrTable: boolean = mode === TextAreaMode.TABLE;
    return b({
      'is-disabled': isDisabled || isListOrTable,
      'is-error': isError,
      'is-clickabled': !!onClick || isListOrTable,
      'is-fixed': !!settings.isFixed || isListOrTable,
    }).mix('scroll-shadows');
  }, [isDisabled, isError, mode, onClick, settings.isFixed]);

  const calcHeight = (rows?: number) => `${(rows || 2) * 20 + 10}px`;

  useEffect(() => {
    setToolbarOffset(`${(refToolbar.current?.clientWidth || 2) + 6}px`);
  }, []);

  const calculatedProps = {
    readOnly: isDisabled,
    disabled: isDisabled && !onClick,
    onClick: onClick,
  };

  const getValue = (e: React.ChangeEvent<HTMLTextAreaElement> | React.FocusEvent<HTMLTextAreaElement>) => e.target.value;

  // Any mode
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      const val: string = getValue(e);

      if (onChange) {
        let { maxLength } = settings as TextProps;
        maxLength = maxLength || (mode === TextAreaMode.WYSIWYG ? defaultSymbolsWysiwyg : maxSymbols);

        const invalid = getInvalid(val);

        if (mode === TextAreaMode.WYSIWYG && wysiwygEmptyList.includes(val)) {
          onChange('');
          return;
        }

        if (invalid.length) {
          showNotification({
            message: `В тексте обнаружены недопустимые символы! (${invalid})<br />Сохраненить с ними, невозможно.`,
            theme: 'danger',
          });
        }

        if (val.length > maxLength) {
          showNotification({
            message: `Превышено максимально количество символов (${maxLength}), сократите строку`,
            theme: 'danger',
          });
          onChange(val.slice(0, maxLength));
          return;
        }
        onChange(val);
      }
      setValueState(val);
    },
    [mode, onChange, settings],
  );

  // Remove value if ['0', '0.0', '0.00', ao]
  const onFocusIfIsEmpty = (e: React.FocusEvent<HTMLTextAreaElement>) => {
    const val: string = getValue(e);

    if (Number(val) === parseFloat(val) && !parseFloat(val) && onChange) {
      setValueState('');
    }
    if (onFocus) {
      onFocus(e);
    }
  };

  // TextAreaMode.MODAL | TextAreaMode.WYSIWYG
  if (mode === TextAreaMode.MODAL || mode === TextAreaMode.WYSIWYG) {
    const [extendedValue, setExtendedValue] = useState<string>('');
    const [isExtendedPopupOpen, setIsExtendedPopupOpen] = useState<boolean>(false);

    const openExtenededPopup = useCallback(() => {
      setExtendedValue(valueState);
      setIsExtendedPopupOpen(true);
    }, [valueState]);

    if (mode === TextAreaMode.WYSIWYG) {
      useLayoutEffect(() => {
        const titles: Record<string, string> = {
          'ql-bold': 'Жирный',
          'ql-italic': 'Наклонный',
          'ql-underline': 'Подчёркнутый',
          'ql-strike': 'Зачёркнутый',
          'ql-blockquote': 'Цитата',
          'ql-list_ordered': 'Нумерованный список',
          'ql-list_bullet': 'Список',
          'ql-indent_-1': 'Убрать отступ',
          'ql-indent_+1': 'Добавить отступ',
          'ql-link': 'Добавить ссылку',
          'ql-image': 'Добавить изображение',
          'ql-clean': 'Убрать форматирование',
        };

        [].slice.call(document.querySelectorAll('.ql-toolbar > .ql-formats > button[type="button"]')).forEach(el => {
          const button: HTMLButtonElement = el;
          const index: string = [button.className, button.value || ''].filter(Boolean).join('_');
          button.title = titles[index] ?? '';
        });
      }, [mode]);
    }

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'view' },
          title: 'Открыть в новом окне',
          onClick: openExtenededPopup,
          isDisabled: !valueState.length,
        },
      ],
      [openExtenededPopup, valueState.length],
    );

    const modalButtons: ButtonProps[] = [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => {
          handleChange(({ target: { value: extendedValue } } as unknown) as React.ChangeEvent<HTMLTextAreaElement>);
          setIsExtendedPopupOpen(false);
        },
        isDisabled: isDisabled,
      },
      {
        icon: { type: 'erase' },
        title: 'Удалить символы "Переноса строк"',
        onClick: () => setExtendedValue(clearWhitespaceCharacters(extendedValue)),
        isDisabled: isDisabled,
      },
    ];

    return (
      <div className={w({}).mix(classMixin)}>
        {mode === TextAreaMode.WYSIWYG ? (
          <ReactQuill
            theme="snow"
            className={q({ 'is-focused': isFocusedQuill })}
            value={value}
            onChange={val => handleChange(({ target: { value: val } } as unknown) as React.ChangeEvent<HTMLTextAreaElement>)}
            modules={{
              toolbar: !!settings.notificationsToolbar ? settings.notificationsToolbar : notificationsToolbar,
            }}
            {...calculatedProps}
            {...{ style: { minHeight: calcHeight(settings.rows || 10) } }}
            onFocus={() => setIsFocusedQuill(true)}
            onBlur={() => setIsFocusedQuill(false)}
          />
        ) : (
          <textarea
            className={className}
            onChange={handleChange}
            onFocus={onFocusIfIsEmpty}
            value={valueState}
            {...calculatedProps}
            {...{ style: { height: calcHeight(settings.rows), paddingRight: toolbarOffset } }}
            {...(restProps as InputAttributes)}
          />
        )}
        <Toolbar refToolbar={refToolbar} buttons={buttons} />

        <Modal
          onClose={() => setIsExtendedPopupOpen(false)}
          isOpen={isExtendedPopupOpen}
          title={settings.title || 'Просмотр содержимого'}
          size="large"
        >
          <FormComponent.Template>
            {mode === TextAreaMode.MODAL && <Toolbar buttons={modalButtons} mode="form" />}

            <FormComponent.Wrapper>
              {mode === TextAreaMode.MODAL ? (
                <textarea
                  className={className}
                  onChange={e => setExtendedValue(getValue(e))}
                  onFocus={onFocusIfIsEmpty}
                  value={extendedValue}
                  {...calculatedProps}
                  {...{ style: { height: calcHeight(10), minHeight: '100%' } }}
                  {...(restProps as InputAttributes)}
                />
              ) : (
                <div className="quill-preview" dangerouslySetInnerHTML={{ __html: value || '' }}></div>
              )}
            </FormComponent.Wrapper>
          </FormComponent.Template>
        </Modal>
      </div>
    );
  }

  // TextAreaMode.TABLE
  if (mode === TextAreaMode.TABLE) {
    restProps.placeholder = isDisabled ? 'Не выбрано' : restProps.placeholder || 'Выберите элемент из справочника';

    const [isTablePopupOpen, setIsTablePopupOpen] = useState<boolean>(false);

    const tableParams = settings.table || {};

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'table' },
          title: settings.iconTitle || 'Добавить',
          permission: { name: settings.permissionName },
          isDisabled: isDisabled,
          onClick: () => {
            setIsTablePopupOpen(true);
          },
        },
        ...(settings.externalButtons || []),
        {
          icon: { type: 'clear' },
          title: 'Удалить',
          permission: { name: settings.permissionName },
          isHidden: settings.isClearable === undefined ? false : !settings.isClearable,
          isDisabled: isDisabled || !valueState.trim(),
          onClick: () => {
            tableParams.onSelect(null);
          },
        },
      ],
      [
        isDisabled,
        settings.externalButtons,
        settings.iconTitle,
        settings.isClearable,
        settings.permissionName,
        tableParams,
        valueState,
      ],
    );

    return (
      <>
        <div className={w({}).mix(classMixin)}>
          <textarea
            className={className}
            onChange={handleChange}
            onFocus={onFocusIfIsEmpty}
            value={!!valueState.trim() ? valueState : ''}
            {...{
              readOnly: true,
              disabled: isDisabled,
              onClick: () => isDisabled || setIsTablePopupOpen(true),
            }}
            {...{ style: { height: calcHeight(settings.rows), paddingRight: toolbarOffset } }}
            {...(restProps as InputAttributes)}
          />
          <Toolbar refToolbar={refToolbar} buttons={buttons} />

          <RelationTableModal
            isOpen={isTablePopupOpen}
            onClose={() => setIsTablePopupOpen(false)}
            modalTitle={settings.title || 'Список возможных вариантов'}
            specification={{
              ...tableParams.specification,
              onSubmitTable: ({ selectedRows }: SubmitTable) => {
                tableParams.onSelect(selectedRows[0]);
                setIsTablePopupOpen(false);
              },
            }}
            helpText={settings.help}
            tooltipText={settings.tooltip}
          />
        </div>
        {!!settings.visibleStatus && <div className={b('hint')}>{settings.visibleStatus}</div>}
      </>
    );
  }

  const buttons = useMemo<ButtonProps[]>(
    () => [
      ...(settings.externalButtons || []),
      ...(!!settings.isClearable
        ? [
            {
              icon: { type: 'clear' },
              title: 'Удалить',
              isDisabled: isDisabled || !valueState.trim(),
              onClick: () => {
                handleChange(({ target: { value: '' } } as unknown) as React.ChangeEvent<HTMLTextAreaElement>);
              },
            },
          ]
        : []),
    ],
    [handleChange, isDisabled, settings.externalButtons, settings.isClearable, valueState],
  );

  return (
    <div className={w({}).mix(classMixin)}>
      <textarea
        className={className}
        onChange={handleChange}
        onFocus={onFocusIfIsEmpty}
        value={valueState}
        {...calculatedProps}
        {...{ style: { height: calcHeight(settings.rows), paddingRight: toolbarOffset } }}
        {...(restProps as InputAttributes)}
      />
      <Toolbar refToolbar={refToolbar} buttons={buttons} />
    </div>
  );
});

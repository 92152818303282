import React, { useMemo } from 'react';
import { parse } from 'date-fns';

import { ListEdit } from 'components';

import { MipPartner } from 'types/models';
import { getMockIndicator, getPercentOfAmountByPart } from 'features/EditMipPartner/helpers';
import { showErrorsMessages } from 'utils/Common';
import { Fields } from './Fields';
import { validateMipIndicator } from './validate';
import { formatStr } from 'utils/Constants';
import { useAppDataContext } from 'features/AppData/context';
import { roundFloat } from 'utils/Helpers';

type Props = {
  indicators: MipPartner.Indicator[];
  onIndicatorsChange: (indicators: MipPartner.Indicator[]) => void;
  charterCapitals: MipPartner.CharterCapital[];
  disabled: boolean;
};

type CharterCapitalSimple = {
  dividendTaxPercent: string;
  year: number;
  amountPercent: number;
};

export type CalcedIndicator = {
  amountPercent: number;
  amount: number;
  percent: number;
  result: number;
};

export const Indicators = ({ indicators, onIndicatorsChange, charterCapitals, disabled }: Props) => {
  const { settings } = useAppDataContext();

  const getYear = (date: string) => parse(date, formatStr, new Date()).getFullYear();

  const lastCharterCapitalDividendTaxPercent = useMemo(() => {
    return charterCapitals.sort((a, b) => getYear(b.editDate) - getYear(a.editDate))[0]?.dividendTaxPercent || '0.00';
  }, [charterCapitals]);

  const charterCapitalSimples = useMemo<CharterCapitalSimple[]>(() => {
    return charterCapitals
      .map(charterCapital => {
        const founder = charterCapital.founders.find(i => i.isLocal);

        if (founder) {
          return {
            dividendTaxPercent: charterCapital.dividendTaxPercent || '0.00',
            year: getYear(charterCapital.editDate),
            amountPercent: Number(getPercentOfAmountByPart({ part: founder.amount, amount: charterCapital.amount })),
          };
        }
      })
      .filter(Boolean)
      .sort((a, b) => (a?.year && b?.year ? a?.year - b?.year : 0)) as CharterCapitalSimple[];
  }, [charterCapitals]);

  const calcByIndicatorYear = (indicator: MipPartner.Indicator): CalcedIndicator => {
    const finded = charterCapitalSimples.filter(i => i.year <= Number(indicator.year)).reverse()[0];

    const amountPercent = finded?.amountPercent || 0;
    const amount = roundFloat((Number(indicator.dividendAmount || 0) * amountPercent) / 100);
    const percent = roundFloat((amount * Number(indicator.dividendTaxPercent || 0)) / 100);
    const result = roundFloat(amount - percent);

    return { amountPercent, amount, percent, result };
  };

  return (
    <ListEdit
      header={{ title: 'Годовые показатели результативности МИП' }}
      rows={indicators}
      onChange={onIndicatorsChange}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Год', formatValue: x => x.year },
        { label: 'Среднесписочная численность, чел', formatValue: x => x.averageHeadcount },
        { label: 'Выручка, руб.', formatValue: x => x.revenue, dataKind: 'float' },
        { label: 'Чистая прибыль, руб.', formatValue: x => x.profit, dataKind: 'float' },
        { label: 'Дата сдачи отчёта', formatValue: x => x.reportDate || <em>Не указана</em> },
        { label: 'Общая сумма дивидендов, руб.', formatValue: x => x.dividendAmount || '0.00', dataKind: 'float' },
        {
          label: `Дивиденды ${settings?.organization?.shortName}, руб.`,
          formatValue: x => calcByIndicatorYear(x).amount || '0.00',
          dataKind: 'float',
        },
        {
          label: `Налог с дивидендов ${settings?.organization?.shortName}, руб.`,
          formatValue: x => calcByIndicatorYear(x).percent || '0.00',
          dataKind: 'float',
        },
        {
          label: `Дивиденды ${settings?.organization?.shortName} без налога, руб.`,
          formatValue: x => calcByIndicatorYear(x).result || '0.00',
          dataKind: 'float',
        },
      ]}
      withMessages
      isDeleteConfirmEnabled
      isDisabled={disabled}
      defaultRowsCount={15}
      maxHeight="none"
      columnIndexesForSumTotal={[2, 3, 5, 6, 7, 8]}
      columnIndexesForIntegerTotal={[1]}
      specification={{
        mode: 'customComponent',
        renderComponent: (indicator, setIndicator) => (
          <Fields
            indicator={indicator ?? { ...getMockIndicator(), dividendTaxPercent: lastCharterCapitalDividendTaxPercent }}
            setIndicator={setIndicator}
            calcByIndicatorYear={calcByIndicatorYear}
          />
        ),
        validation: {
          checkIsValid: (indicator, index, list, mode) =>
            validateMipIndicator(indicator, index, list, mode).every(x => x.isValid),
          onInvalidate: (indicator, mode, index, list) => {
            const validationInfo = validateMipIndicator(indicator, index, list, mode);
            showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
          },
        },
      }}
    />
  );
};

import { FormComponent, ListEdit, SectionTitle } from 'components';
import { Equip } from 'types/models';

import { isNumber } from 'utils/Validators';

type Props = {
  equip: Equip.Equip;
};

export function Verification({ equip }: Props) {
  return (
    <>
      <SectionTitle title="Метрологические характеристики" />
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Наименование по паспорту">{equip.manufacturerName || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Марка, модель">{equip.model || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Вид прибора">{equip.equipKind?.label || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Вид измерений">{equip.measureKind?.label || ''}</FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column>
          <FormComponent.Line>
            <FormComponent.Field label="Межповерочный интервал">
              {isNumber(equip.interval) ? equip.interval : ''}
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Пределы измерений">{equip.measureLimit || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Цена деления шкалы">{equip.measureScaleAmount || ''}</FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Класс или допустимая погрешность">
              {equip.permissibleInaccuracy || ''}
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
      <SectionTitle title="Данные о поверке" />
      <ListEdit
        rows={equip.verificationItems}
        onChange={() => {}}
        columns={[
          { label: 'Дата', formatValue: row => row.date, styles: { width: '10%' }, dataKind: 'date', defaultSort: 'desc' },
          { label: 'Документ', formatValue: row => row.document?.name || '', styles: { width: '20%' } },
          { label: 'Номер договора в реестре', formatValue: row => row.contractNumber || '', styles: { width: '20%' } },
          {
            label: 'Ссылка на запись в реестре',
            formatValue: row => <FormComponent.Link href={row.documentUrl} />,
            styles: { width: '20%', wordBreak: 'break-word' },
          },
          { label: 'Результат', formatValue: row => row.result?.label || '', styles: { width: '30%' } },
        ]}
        withMessages
        isDeleteConfirmEnabled
        defaultRowsCount={5}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
        }}
      />
    </>
  );
}

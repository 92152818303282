import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import { useController } from './controller';
import { ProjectEquipGridHelp } from './help';

function Toolbar() {
  const { buttons, isOpenHelpForm, setIsOpenHelpForm } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Использование оборудования в проектах"
        isOpen={isOpenHelpForm}
        onClose={() => setIsOpenHelpForm(false)}
        size={['large', 'medium-height']}
      >
        {ProjectEquipGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

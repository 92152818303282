import React, { memo } from 'react';

import { Modal, Toolbar } from 'components';

import { Form } from 'features/Form';
import { Table } from 'types/models';
import { Component as ApprovePopup } from 'features/Form/views/ApprovePopup';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

const ToolbarTemplate = ({ tableState }: Props) => {
  const {
    toolbarButtons,
    isApproveFormOpen,
    closeApproveForm,
    onApprove,
    isViewFormOpen,
    closeViewForm,
    look,
    title,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={toolbarButtons} />

      <Modal isOpen={isViewFormOpen} onClose={closeViewForm} title={title} size="large">
        <Form look={look} onClose={closeViewForm} />
      </Modal>

      <ApprovePopup
        isOpen={isApproveFormOpen}
        onClose={closeApproveForm}
        onApprove={onApprove}
        selectedRowId={tableState.selectedRows.length ? tableState.selectedRows[0]?.id : null}
      />
    </>
  );
};

export default memo(ToolbarTemplate);

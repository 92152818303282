import { useCallback } from 'react';

import { MobileRequest } from 'types/models';
import { Member } from 'features/SelectPerson';
import { useAppDataContext } from 'features/AppData/context';
import { getMockPerson } from 'features/Form/looks/person/helpers';
import { getMockApplicantSupervisor } from '../helpers';
import { Item } from 'types/models/common';

type Props = {
  entry: MobileRequest.ApplicantSupervisor | null;
  onFieldChange(row: MobileRequest.ApplicantSupervisor | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handlePersonChange = useCallback(
    (value: Member | null) => {
      onFieldChange(convertMemberToApplicantSupervisor(entry, value));
    },
    [entry, onFieldChange],
  );

  const handleRoleChange = useCallback(
    (value: Item | null) => {
      onFieldChange({ ...entry, role: value } as MobileRequest.ApplicantSupervisor);
    },
    [entry, onFieldChange],
  );

  function convertApplicantSupervisorToMember(mobileRequest: MobileRequest.ApplicantSupervisor | null): Member | null {
    if (!mobileRequest?.supervisor) return null;
    const { initialCountryCode } = useAppDataContext();
    const result: Member = {
      ...mobileRequest,
      person: {
        ...getMockPerson({ initialCountryCode: mobileRequest.supervisor?.scientist?.citizenship || initialCountryCode }),
        ...mobileRequest.supervisor,
        id: mobileRequest.supervisor?.id || '-1',
      },
    };
    return result;
  }

  function convertMemberToApplicantSupervisor(
    prevApplicantSupervisor: MobileRequest.ApplicantSupervisor | null,
    member: Member | null,
  ): MobileRequest.ApplicantSupervisor | null {
    if (!member?.person) return null;
    const result: MobileRequest.ApplicantSupervisor = {
      ...getMockApplicantSupervisor(),
      ...prevApplicantSupervisor,
      ...member,
      supervisor: member.person
        ? {
            ...member.person,
          }
        : null,
    };
    return result;
  }

  return {
    handlePersonChange,
    convertApplicantSupervisorToMember,
    handleRoleChange,
  };
}

import { useCallback, useEffect } from 'react';
import { parse } from 'date-fns';

import { Tender } from 'types/models';
import { formatStr } from 'utils/Constants';
import { LotPrice } from 'types/models/Tender';

type Props = {
  lot: Tender.Lot | null;
  lotPrice: Tender.LotPrice | null;
  onLotPriceChange(row: Tender.LotPrice): void;
};

export function useController({ lot, lotPrice, onLotPriceChange }: Props) {
  const handleChange = useCallback(
    (fieldName: keyof Tender.LotPrice) => (value: string) => {
      onLotPriceChange({ ...lotPrice, [fieldName]: value } as Tender.LotPrice);
    },
    [lotPrice, onLotPriceChange],
  );

  const guessLotYear = useCallback(() => {
    const dateFrom = parse(lot?.startDate || '', formatStr, new Date());
    const dateTo = parse(lot?.endDate || '', formatStr, new Date());
    for (let i = dateFrom.getFullYear(); i <= dateTo.getFullYear(); i += 1) {
      const itemByYear = lot?.prices.filter((item: LotPrice) => {
        return item.year === i.toString();
      });
      if (!itemByYear || itemByYear.length === 0) {
        handleChange('year')(i.toString());
        return i.toString();
      }
    }
    return null;
  }, [lot, handleChange]);

  useEffect(() => {
    if (!lotPrice) {
      onLotPriceChange({
        year: '',
        amount: 0,
      });
    }
  }, [lotPrice, onLotPriceChange]);

  return {
    guessLotYear,
    handleChange,
  };
}

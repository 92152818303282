import { useCallback, useMemo, useState } from 'react';
import { format } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ExtraToolbarButton } from 'components/ListEdit/model';

import { useAppDataContext } from 'features/AppData/context';
import { Person } from 'types/models';
import { showNotification } from 'features/Notifications';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { formatDateTimeStr } from 'utils/Constants';
import { getIsPending } from 'utils/Helpers/getIsPending';
import { LinkedHistoryType } from 'services/BackendAPI/configurations/author/types';
import { ScientistEducation } from 'types/models/Person';
import { Mode } from '.';

type Props = {
  mode?: Mode;
  person: Person.ScientistPerson | null;
  onChangeEducations(educations: Person.ScientistEducation[]): void;
};

const useController = ({ mode, person, onChangeEducations }: Props) => {
  const { currentPerson, userPermission } = useAppDataContext();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const [isQuestionFormOpen, setIsQuestionFormOpen] = useState(false);
  const [isDeleteHistoryWarningOpen, setIsDeleteHistoryWarningOpen] = useState<boolean>(false);
  const [isEntityRelationOpen, setIsEntityRelationOpen] = useState<boolean>(false);
  const [educationIds, setEducationIds] = useState<string[]>([]);
  const [selectedEducations, setSelectedEducations] = useState<ScientistEducation[]>([]);
  const [isMergeDuplicatesOpen, setIsMergeDuplicatesOpen] = useState<boolean>(false);

  const handleSelectedRows = useCallback((educations: ScientistEducation[]) => {
    setSelectedEducations(educations);
  }, []);

  const handleCloseMergeDuplicatesForm = useCallback(() => {
    setIsMergeDuplicatesOpen(false);
  }, []);

  const isMergeDisabled = useCallback(() => {
    return selectedEducations.length !== 2;
  }, [selectedEducations]);

  const { methods: GetIsLinkedHistoryAPI, state: GetIsLinkedHistoryState } = BackendAPI.useBackendAPI('IsLinkedHistory');

  const isLinkedHistoryPending = useMemo(() => getIsPending(GetIsLinkedHistoryState), [GetIsLinkedHistoryState]);

  const onDeleteButtonClick = useCallback((nextDeletedIndex: number) => {
    setSelectedIndex(nextDeletedIndex);
    setIsDeleteConfirmOpen(true);
  }, []);

  const onDeleteReset = useCallback(() => {
    setSelectedIndex(null);
    setIsDeleteConfirmOpen(false);
  }, []);

  const handleQuestionButtonClick = useCallback(() => {
    setIsQuestionFormOpen(true);
  }, []);

  const handleTemplateCloseQuestionForm = useCallback(() => {
    setIsQuestionFormOpen(false);
  }, []);

  const selectedEducation = useMemo(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      return person.scientistEducations[selectedIndex!];
    }

    return null;
  }, [selectedIndex, person]);

  const removeEducation = useCallback(() => {
    const isSelectedIndex = selectedIndex !== -1 && selectedIndex !== null;

    if (isSelectedIndex && person) {
      const scientistEducationsCopy = [...(person?.scientistEducations || [])];
      const isReadOnlyEducation = Boolean(selectedEducation?.isReadOnly);

      if (isReadOnlyEducation) {
        const isUserHasPermission = isHasPermission(userPermission, Permits.PERSON_JOB_EDUCATION_DEGREE_RANK_DELETE);
        if (!isUserHasPermission) {
          showNotification({ message: 'Нет прав на удаление обучений пришедших по интеграции', theme: 'danger' });
          return;
        }
        const deletionDate = `${format(new Date(), formatDateTimeStr)}`;
        const deletedBy = currentPerson?.fullName || '';
        const nextScientistEducation: Person.ScientistEducation = {
          ...selectedEducation!,
          isActive: false,
          deletionDate,
          deletedBy,
        };
        scientistEducationsCopy.splice(selectedIndex, 1, nextScientistEducation);
        onChangeEducations(scientistEducationsCopy);

        showNotification({ message: 'Обучение помечено удаленным', theme: 'success' });
      } else {
        scientistEducationsCopy.splice(selectedIndex, 1);
        onChangeEducations(scientistEducationsCopy);
        showNotification({ message: 'Обучение удалено', theme: 'success' });
      }
    }
  }, [currentPerson?.fullName, onChangeEducations, person, selectedEducation, selectedIndex, userPermission]);

  const onDeleteConfirm = useCallback(() => {
    setIsDeleteConfirmOpen(false);

    if (selectedIndex === null) {
      return;
    }

    if (selectedEducation?.id) {
      GetIsLinkedHistoryAPI.callAPI(
        { type: LinkedHistoryType.EDUCATION, id: selectedEducation.id },
        {
          onSuccessfullCall: ({ data }) => {
            if (data.isSuccess) {
              setIsDeleteHistoryWarningOpen(true);
            } else {
              removeEducation();
            }
          },
        },
      );
    } else {
      removeEducation();
    }
  }, [selectedIndex, selectedEducation?.id, GetIsLinkedHistoryAPI, removeEducation]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Person.ScientistEducation>[]>(
    () => [
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: (_, educationIndex: number | null) => {
          if (educationIndex !== null) {
            onDeleteButtonClick(educationIndex);
          }
        },
        isDisabled: (education: Person.ScientistEducation | null) => {
          if (!education || isLinkedHistoryPending || (selectedEducations && selectedEducations?.length > 1)) return true;

          if (education?.isReadOnly) {
            if (education?.isActive) return false;
            return true;
          }
          return false;
        },
      },
      ...(mode === 'person'
        ? [
            {
              icon: { type: 'connection' },
              title: 'Связь',
              onClick: (_: any, educationIndex: number | null) => {
                if (educationIndex !== null) {
                  setSelectedIndex(educationIndex);
                  setIsEntityRelationOpen(true);
                }
              },
              isDisabled: (education: Person.ScientistEducation | null) =>
                !education?.id || (selectedEducations && selectedEducations?.length > 1),
            },
            {
              icon: { type: 'merge' },
              title: 'Объединение дубликатов',
              onClick: () => {
                const ids: string[] = selectedEducations
                  .map((education: ScientistEducation) => {
                    return education?.id || '';
                  })
                  .filter(Boolean);
                setEducationIds(ids);
                setIsMergeDuplicatesOpen(true);
              },
              isHidden: !isHasPermission(userPermission, Permits.PERSON_MERGE),
              isDisabled: isMergeDisabled,
            },
          ]
        : []),
      {
        icon: { type: 'admin' },
        title: 'Задать вопрос администратору по сведениям о обучении',
        onClick: handleQuestionButtonClick,
      },
    ],
    [
      mode,
      isMergeDisabled,
      userPermission,
      handleQuestionButtonClick,
      selectedEducations,
      isLinkedHistoryPending,
      onDeleteButtonClick,
    ],
  );

  const closeDeleteHistoryWarning = useCallback(() => {
    setIsDeleteHistoryWarningOpen(false);
  }, []);

  const confirmDeleteHistoryWarning = useCallback(() => {
    closeDeleteHistoryWarning();
    setIsEntityRelationOpen(true);
  }, [closeDeleteHistoryWarning]);

  const closeEntityRelation = useCallback(() => {
    setIsEntityRelationOpen(false);
  }, []);

  return {
    userPermission,
    extraToolbarButtons,
    isDeleteConfirmOpen,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedEducation,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    isQuestionFormOpen,
    handleTemplateCloseQuestionForm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    educationIds,
    selectedEducations,
  };
};

export default useController;

import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Item } from 'types/models/common';

type Props = {
  project?: Item | null;
};

type RequestData = {
  projectId?: string;
};

export function GetPurchaseRequestList({ project }: Props): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetPurchaseRequestList',
    requestData: { projectId: project?.value },
    header: {
      firstLevel: {
        title: project ? 'Заявки на закупки для проекта' : 'Список заявок на закупки для научных проектов',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: !project,
      },
    },
    isFiltersHidden: !!project,
    useCustomController: makeUseCustomController({ project }),
  };
}

export function GetPurchaseRequestListForSelect(): Table.Specification {
  return {
    apiID: 'GetPurchaseRequestList',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
        hasSelectButton: true,
      },
    },
  };
}

import { Person } from 'types/models';
import { PersonContactType } from 'utils/Enums';

export function getPersonContacts(contacts?: Person.Contact[], info?: 'emails' | 'phones'): string {
  if (!contacts || !contacts.length) return '';

  const phones = contacts
    .filter(x => x.type?.value === PersonContactType.PHONE)
    .map(x => x.value)
    .sort();
  if (info === 'phones') return phones.join('; ');

  const emails = contacts
    .filter(x => x.type?.value === PersonContactType.EMAIL)
    .map(x => x.value)
    .sort();
  if (info === 'emails') return emails.join('; ');

  return [...phones, ...emails].join('; ');
}

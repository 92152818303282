import React from 'react';

import { RelationTableModal, TextInput } from 'components';

import useController from './controller';
import { ReferenceFilter as RefFilter } from 'types/models';

export type Props = {
  value: string;
  caption: string;
  reference: string;
  setColumnFilter: (nextValue: string) => void;
  presetFilters: RefFilter[];
};

export const ReferenceFilter = ({ value, caption, reference, setColumnFilter, presetFilters }: Props) => {
  const {
    selectedRefs,
    specification,
    textFieldValue,
    isRelationTableOpen,
    reset,
    openRelationTable,
    closeRelationTable,
  } = useController({ value, reference, setColumnFilter, presetFilters });

  return (
    <>
      <TextInput
        value={textFieldValue}
        onChange={() => {}}
        onClick={openRelationTable}
        externalButtons={[
          {
            icon: { type: 'table' },
            title: 'Выбрать',
            onClick: openRelationTable,
          },
          {
            icon: { type: 'clear' },
            title: 'Очистить',
            isDisabled: !selectedRefs.length,
            onClick: reset,
          },
        ]}
        isDisabled
        placeholder={textFieldValue || 'Выберите значение'}
      />

      <RelationTableModal
        specification={specification}
        modalTitle={caption}
        isOpen={isRelationTableOpen}
        onClose={closeRelationTable}
      />
    </>
  );
};

import React from 'react';

import { ListEdit } from 'components';

import { Project } from 'types/models';
import { showErrors } from 'utils/Validators';
import { getMockProjectPartner } from 'features/Form/looks/project/ProjectForm/helpers';

import { Fields } from './Fields';
import { validate } from './validate';

type Props = {
  partners: Project.Partner[];
  setPartners(partners: Project.Partner[]): void;
  withMessages?: boolean;
  disabled: boolean;
};

function formatAddress(x: Project.Partner) {
  const { partner } = x;

  const arrStrings = [partner?.country?.label ?? '', partner?.district ?? '', partner?.city ?? '', partner?.street ?? ''];

  return arrStrings.filter(Boolean).join(', ');
}

export function Partners(props: Props) {
  const { partners, setPartners, withMessages = true, disabled } = props;

  return (
    <ListEdit
      header={{ title: 'Партнёры по проекту' }}
      rows={partners}
      onChange={setPartners}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'Роль', formatValue: x => x.role?.label ?? '' },
        { label: 'Название', formatValue: x => x.partner?.fullName ?? '' },
        {
          label: 'Договор',
          formatValue: x => `${x.contractType?.label ?? ''} №${x.contractNumber} c (${x.startDate} по ${x.endDate})`,
        },
        {
          label: 'Общий объем',
          formatValue: x => x.financings.reduce((sum, { amount }) => sum + Number(amount), 0),
          dataKind: 'float',
        },
        { label: 'Адрес', formatValue: formatAddress },
        {
          label: 'Тип организации',
          formatValue: x =>
            [x.partner?.lksd?.label, x.partner?.activitySector?.label ? `(${x.partner?.activitySector?.label})` : '']
              .filter(Boolean)
              .join(' '),
        },
      ]}
      maxHeight="none"
      withMessages={withMessages}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      specification={{
        mode: 'customModalComponent',
        modalTitle: 'Партнёры по проекту',
        renderComponent: (partner, setPartner, index, mode) => (
          <Fields mode={mode} partner={partner || getMockProjectPartner()} setPartner={setPartner} />
        ),
        validation: {
          checkIsValid: partner => validate(partner).every(x => x.isValid),
          onInvalidate: val => showErrors(validate, val),
        },
      }}
    />
  );
}

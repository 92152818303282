import React, { SetStateAction, useCallback } from 'react';
import { block } from 'bem-cn';
import * as Blocks from 'features/Home/blocks';

import { ButtonMode, FormComponent, Modal, Tab, Tabs } from 'components';

import { Person } from 'types/models';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { LogoComponent } from 'features/PageLayout/Logo';
import { Component as Topbar } from 'features/Topbar';
import { EditableHonor } from './model';
import { Toolbar } from './Toolbar';
import {
  AdditionalInfo,
  Colleagues,
  EventList,
  Main,
  NotificationSettings,
  ParticipationsEvents,
  Publications,
  Recognitions,
  RequestAndProjects,
  ScientificInterests,
  SecurityDocuments,
} from './Tabs';
import { useController } from './controller';
import { WelcomeModal } from './WelcomeModal';
import { GetQuestion, QuestionAndAnswersList } from './Tabs/QuestionsAndAnswers/Tabs';
import { DataGrid } from 'features/Table';
import {
  GetPersonalDissertationManagementList,
  GetPersonalNotificationLogList,
  GetPersonalRequestList,
} from 'features/Table/specifications';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useRouteNavigation } from 'utils/Common';

import './style.scss';

const b = block('edit-person-form');

type Props = {
  isOpenForm: boolean;
  onClose?(): void;
  personId: string | null;
  disabled?: boolean;
};

function PersonCard(props: Props) {
  const { disabled = false, isOpenForm, onClose, personId } = props;

  const {
    localPerson,
    setLocalPerson,
    submitForm,
    setIsThereChanges,
    reloadPerson,
    isOpenWelcomeModal,
    onCloseWelcomeModal,
    isOpenLogoutNotify,
    goOutBySaving,
    tabsId,
  } = useController({
    personId,
    onClose,
    isOpen: isOpenForm,
  });

  const { initialOrganizationCode, settings, userPermission } = useAppDataContext();

  const { url, changeUrl } = useRouteNavigation({
    prefix: ['account'],
    init: ['news', 'notifications'],
    defaultUrls: {
      news: 'notifications',
      profile: 'my',
      participations: 'conference',
      events: 'conference',
      recognitions: 'recognition',
      projects: 'project',
      security_documents: 'requests',
    },
  });

  const makeChangeHandler = (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => {
    setIsThereChanges(true);
    setLocalPerson({ ...localPerson!, [fieldName]: value });
  };

  const changeHonors = useCallback(
    (honors: EditableHonor[]) => {
      const academicRanks = honors.filter(i => i.type === 'academicRank');
      const ranks = honors.filter(i => i.type === 'rank');
      const degrees = honors.filter(i => i.type === 'degree');

      const updatePerson = { ...localPerson, academicRanks, ranks, degrees };

      setLocalPerson(updatePerson as SetStateAction<Person.ScientistPerson | null>);
    },
    [localPerson, setLocalPerson],
  );

  return (
    <>
      <div
        className={b({
          'is-visible': localPerson !== null,
          'is-fullscreen': true,
          'is-animated': true,
        })}
      >
        <div className={b('wrapper')}>
          <div className={b('header-wrapper')}>
            <div className={b('header-logo')}>
              <LogoComponent />
            </div>

            <div className={b('header-title')}>
              <span className={b('header-title-hint')}>
                Система &laquo;{initialOrganizationCode?.code === 'SibFU' ? 'Научный атлас' : 'УНИД НИ'}{' '}
                {settings?.organization?.shortName}&raquo;
              </span>
              <h4 className={b('header-title-user')}>
                Личный кабинет пользователя:{' '}
                {`${localPerson?.scientist?.lastName ?? ''} ${
                  localPerson?.scientist?.firstName ? `${localPerson.scientist.firstName} ` : ''
                } ${localPerson?.scientist?.patronymic ? `${localPerson.scientist.patronymic}` : ''}`}
              </h4>
            </div>

            <Topbar />
          </div>

          <div className={b('content')}>
            <Tabs id="account" isStrip defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
              <Tab
                title="Новости"
                id="news"
                isVisible={isHasPermission(userPermission, [
                  Permits.PC_MENU_NEWS_NOTICES_ACCESS,
                  Permits.PC_MENU_NEWS_TENDERS_ACCESS,
                  Permits.PC_MENU_NEWS_QNAS_ACCESS,
                  Permits.PC_MENU_NEWS_FQNAS_ACCESS,
                  Permits.PC_MENU_NEWS_USEFUL_LINKS_AND_HELPFUL_STUFFS_ACCESS,
                ])}
              >
                <Tabs id={tabsId} defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)}>
                  <Tab
                    title="Объявления"
                    id="ads"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_NOTICES_ACCESS)}
                  >
                    <Blocks.Notices />
                  </Tab>
                  <Tab
                    title="Конкурсы"
                    id="tenders"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_TENDERS_ACCESS)}
                  >
                    <Blocks.Tenders openTenderId={url[2]} setOpenTenderId={id => changeUrl(2, id)} />
                  </Tab>
                  <Tab
                    title="Мои уведомления"
                    id="notifications"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_QNAS_ACCESS)}
                  >
                    <DataGrid specification={GetPersonalNotificationLogList()} />
                  </Tab>
                  <Tab
                    title="Вопрос-ответ"
                    id="questions"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_QNAS_ACCESS)}
                  >
                    <GetQuestion />
                  </Tab>
                  <Tab
                    title="Часто задаваемые вопросы"
                    id="qna"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_FQNAS_ACCESS)}
                  >
                    <QuestionAndAnswersList isWithTitle={false} />
                  </Tab>
                  <Tab
                    title="Справочные материалы и ссылки"
                    id="references"
                    isVisible={isHasPermission(userPermission, Permits.PC_MENU_NEWS_USEFUL_LINKS_AND_HELPFUL_STUFFS_ACCESS)}
                  >
                    <FormComponent.ColumnWrapper>
                      <FormComponent.Column>
                        <Blocks.HelpfulStuff />
                      </FormComponent.Column>
                      <FormComponent.Column>
                        <Blocks.UsefulLinks />
                      </FormComponent.Column>
                    </FormComponent.ColumnWrapper>
                  </Tab>
                </Tabs>
              </Tab>
              <Tab title="Мой профиль" id="profile">
                <FormComponent.Template>
                  <Toolbar saveForm={disabled ? undefined : submitForm} personId={personId} />

                  <FormComponent.Template>
                    <Tabs id="profile" defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)}>
                      <Tab title="Мой профиль" id="my">
                        <Main
                          person={localPerson}
                          reloadPerson={reloadPerson}
                          userPermission={userPermission}
                          makeSetPerson={makeChangeHandler}
                        />
                      </Tab>
                      <Tab title="Научные интересы" id="interests">
                        <ScientificInterests person={localPerson} disabled={disabled} makeSetPerson={makeChangeHandler} />
                      </Tab>
                      <Tab title="Дополнительные сведения" id="additional">
                        <AdditionalInfo
                          defaultTabId={url[2]}
                          onTabClick={id => changeUrl(2, id)}
                          person={localPerson}
                          disabled={disabled}
                          makeSetPerson={makeChangeHandler}
                          onChangeHonors={changeHonors}
                          userPermission={userPermission}
                          onChangeHirshIndexes={makeChangeHandler('hirshIndexes')}
                        />
                      </Tab>
                      <Tab title="Настройка уведомлений" id="notifications">
                        <NotificationSettings person={localPerson} setPerson={setLocalPerson} />
                      </Tab>
                    </Tabs>
                  </FormComponent.Template>
                </FormComponent.Template>
              </Tab>
              <Tab title="Коллеги" id="colleagues" isVisible={isHasPermission(userPermission, Permits.PC_MENU_COLLEAGUES_ACCESS)}>
                <Colleagues />
              </Tab>
              <Tab
                title="Публикации"
                id="publications"
                isVisible={isHasPermission(userPermission, Permits.PC_MENU_PUBLICATIONS_ACCESS)}
              >
                <Publications />
              </Tab>
              <Tab
                title="Участие в мероприятиях"
                id="participations"
                isVisible={isHasPermission(userPermission, [
                  Permits.PC_MENU_PARTICIPATIONS_CONTEST_ACCESS,
                  Permits.PC_MENU_PARTICIPATIONS_CONFERENCE_ACCESS,
                  Permits.PC_MENU_PARTICIPATIONS_EXPOSITION_ACCESS,
                  Permits.PC_MENU_PARTICIPATIONS_CONCERT_ACCESS,
                ])}
              >
                <ParticipationsEvents defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
              </Tab>
              <Tab
                title={`Мероприятия ${settings?.organization?.shortName}`}
                id="events"
                isVisible={isHasPermission(userPermission, [
                  Permits.PC_MENU_EVENTS_CONTEST_ACCESS,
                  Permits.PC_MENU_EVENTS_CONFERENCE_ACCESS,
                  Permits.PC_MENU_EVENTS_EXPOSITION_ACCESS,
                  Permits.PC_MENU_EVENTS_CONCERT_ACCESS,
                ])}
              >
                <EventList defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
              </Tab>
              <Tab
                title="Персональные достижения"
                id="recognitions"
                isVisible={
                  isHasPermission(userPermission, Permits.PC_MENU_PERSONALACHIEVEMENTS_ACCESS) &&
                  isHasPermission(userPermission, [
                    Permits.PC_MENU_RECOGNITIONS_ACCESS,
                    Permits.PC_MENU_DISSERTATIONCOMMUNITIESPARTICIPATION_ACCESS,
                    Permits.PC_MENU_COMMUNITIESPARTICIPATION_ACCESS,
                  ])
                }
              >
                <Recognitions defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
              </Tab>
              <Tab
                title="Заявки на конкурсы"
                id="requests"
                isVisible={isHasPermission(userPermission, Permits.PC_MENU_TENDERREQUESTS_ACCESS)}
              >
                <DataGrid
                  specification={GetPersonalRequestList({ hasToolbarButtonMode: true, action: url[1] })}
                  hasPersonalMode
                />
              </Tab>
              <Tab title="Проекты" id="projects">
                <RequestAndProjects personId={personId} defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
              </Tab>
              <Tab title="ОИС" id="security_documents">
                <SecurityDocuments personId={personId} defaultTabId={url[1]} onTabClick={id => changeUrl(1, id)} />
              </Tab>
              {!!personId && (
                <Tab
                  title="Руководство защитой"
                  id="management"
                  isVisible={isHasPermission(userPermission, Permits.PC_MENU_DISSERTATIONSDEFENCESUPERVISING_ACCESS)}
                >
                  <DataGrid specification={GetPersonalDissertationManagementList()} hasPersonalMode />
                </Tab>
              )}
            </Tabs>
          </div>

          <Modal
            mode="info"
            title="Предупреждение"
            isOpen={isOpenLogoutNotify}
            onClose={() => goOutBySaving(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Сохранить и выйти',
                onClick: () => goOutBySaving(true),
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Выйти',
                onClick: () => goOutBySaving(false),
              },
            ]}
            size="small"
          >
            <>Сохранить изменения на вкладке 'Профиль' перед тем как выйти?</>
          </Modal>

          <WelcomeModal isOpen={isOpenWelcomeModal} onClose={onCloseWelcomeModal} person={localPerson} />
        </div>
      </div>
    </>
  );
}

export { PersonCard };

import { Department, Publication } from 'types/models';

import { Settings } from 'types/models/common';
import { convertMember } from '../author/converters';
import { convertMaybeArray, convertReference, getText } from '../commonConverters';
import { DeletePublicationResponse, TypeDeleteCode } from './types';

export function convertServerPublication(publication: any, settings: Settings): Publication {
  const id = publication._attributes.id;
  const members = convertMaybeArray(publication.Members?.Member ?? [], m => convertMember(m, settings));
  const departments = convertMaybeArray(publication.Departments?.Department ?? [], convertServerDepartment);

  return {
    id,
    members,
    departments,
    doi: getText(publication.Doi),
    bibliographicRecord: getText(publication.BibliographicRecord),
    sourceIndices: getText(publication.SourceIndices),
    scopusCitations: getText(publication.ScopusCitations),
    scopusQuartile: getText(publication.ScopusQuartile),
    wosCitations: getText(publication.WosCitations),
    wosQuartile: getText(publication.WosQuartile),
    indices: getText(publication.Indices),
    type: {
      label: publication.Type._attributes.label,
      value: getText(publication.Type),
    },
  };
}

export function convertServerDepartment(department: any): Department {
  const id = department?._attributes?.id;
  const ref = convertReference(department.Department);
  return { id, ref };
}
export function convertSourceMagazineRelease(source: any) {
  if (source) {
    const nameConverter = (m: any) =>
      [
        m.Number?._text ? `Выпуск №${m.Number._text}` : '',
        m.Part?._text ? `Том-${m.Part._text}` : '',
        m.Month?._text ? `${m.Month._text} месяц` : '',
        m.Year?._text ? `${m.Year._text}г.` : '',
      ]
        .filter(Boolean)
        .join(', ');
    return {
      id: source?._attributes?.id || '',
      name: nameConverter(source),
      year: getText(source.Year),
      status: source.Status?._attributes?.label || '',
    };
  }
  return null;
}

export function convertDeletePublicationResponse(response: any): DeletePublicationResponse {
  const deletePublicationResponse: DeletePublicationResponse = {
    isSuccess: response?.Response?.DeleteResult?._attributes?.isSuccess === 'true',
    typeDeleteCode: response?.Response?.DeleteResult?._attributes?.type as TypeDeleteCode,
  };

  return deletePublicationResponse;
}

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetQnaQuestionList } from 'features/Table/specifications';
import { FormComponent } from 'components';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';

export function GetQuestion() {
  const { userPermission } = useAppDataContext();
  return (
    <FormComponent.Template>
      {isHasPermission(userPermission, Permits.PC_MENU_NEWS_FQNAS_ACCESS) && (
        <FormComponent.Description mode="warning">
          Если Вы не нашли ответ в рубрике &quot;Часто задаваемые вопросы&quot;, Вы можете задать свой вопрос администратору
          системы.
        </FormComponent.Description>
      )}

      <DataGrid specification={GetQnaQuestionList()} />
    </FormComponent.Template>
  );
}

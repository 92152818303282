import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isYoung',
      label: 'До 39 лет',
      checked: false,
    },
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isCurrent',
      label: 'Состав коллектива на текущую дату',
      checked: false,
    },
  ]);
});

import { Parameter } from 'types/models';

const queryBuilder = (parameter: Parameter) => {
  const value = parameter.value || parameter.default;
  if (value) {
    return `&${parameter.name}=${value}`;
  }

  return '';
};

export default queryBuilder;

import React, { useCallback, useMemo, useState } from 'react';
import { format, parse } from 'date-fns';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';
import { ExtraToolbarButton } from 'components/ListEdit/model';

import { SecurityDocument, SecurityDocumentPayment } from 'types/models/SecurityDocument';
import { showNotification } from 'features/Notifications';
import { formatStr } from 'utils/Constants';
import { yearsFromRange } from 'utils/Helpers/yearsFromRange';
import { SecurityDocumentType } from 'utils/Enums';
import { downloadFile, getAuthToken } from 'utils/Helpers';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
  disabled?: boolean;
};

export function useController(props: Props) {
  const { securityDocument, setSecurityDocument, disabled } = props;

  const token = getAuthToken();

  const [payments, setPayments] = useState<SecurityDocumentPayment[]>(
    securityDocument?.payments.filter(p => !!p.paymentPurpose?.id) || [],
  );
  const [requestPayments, setRequestPayments] = useState<SecurityDocumentPayment[]>(
    securityDocument?.payments.filter(p => !!p.paymentPurposeRequest?.id && !p.paymentPurpose) || [],
  );
  const [selectedRowsDocuments, onSelectedRowsCallback] = useState<SecurityDocumentPayment[]>([]);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const [selectedRowsAmountSum, setSelectedRowsAmountSum] = useState<number>(0);

  const handleRequestPaymentsChange = useCallback(
    (value: SecurityDocumentPayment[]) => {
      setRequestPayments(value);
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => ({ ...prevPatent!, payments: [...payments, ...value] }));
      }
    },
    [payments, setSecurityDocument],
  );

  const paymentTitleByType = useMemo(
    () =>
      securityDocument?.type?.value === SecurityDocumentType.PATENT
        ? 'Пошлины за поддержание патента'
        : 'Пошлины за поддержание свидетельства',
    [securityDocument],
  );

  const handlePaymentsChange = useCallback(
    (value: SecurityDocumentPayment[]) => {
      setPayments(value);
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => ({ ...prevPatent!, payments: [...requestPayments, ...value] }));
      }
    },
    [requestPayments, setSecurityDocument],
  );

  const formatPurposesByYearNum = useCallback((values: ReferenceItem[]) => {
    const purposes: Record<number, ReferenceItem> = {};
    values.forEach(item => {
      const yearNum = Number(item?.customFields?.year);
      if (yearNum) purposes[yearNum] = item;
    });
    return purposes;
  }, []);

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const handleFillCalendarClick = useCallback(() => {
    loadReferenceAPI.callAPI(
      { filters: [], referenceName: 'RefPaymentPurpose' },
      {
        onSuccessfullCall: ({ data }) => {
          const purposesByYearNum: Record<number, ReferenceItem> = formatPurposesByYearNum(
            data.filter(x => (x.customFields?.securityDocumentKind as ReferenceItem)?.id === securityDocument.kind?.id),
          );
          if (!securityDocument?.requestDate) {
            showNotification({ message: 'Не введена дата подачи заявки', theme: 'danger' });
            return;
          }
          if (!securityDocument?.documentEndDate) {
            showNotification({ message: 'Не введено окончание срока действия патента', theme: 'danger' });
          }
          let startDate = parse(securityDocument?.requestDate || '', formatStr, new Date());
          const endDate = parse(securityDocument?.documentEndDate || '', formatStr, new Date());
          const oldPayments = [...payments];
          const newPayments: SecurityDocumentPayment[] = [];
          let yearNum = 1;

          yearsFromRange(startDate, endDate)?.forEach(year => {
            const existingIndex = oldPayments?.findIndex(
              p => parse(p.payBeforeDate || '', formatStr, new Date()).getFullYear() === year,
            );
            if (existingIndex !== -1) {
              const existingElement = oldPayments.splice(existingIndex, 1);
              newPayments.push(existingElement[0]);
            } else if (!!Number(purposesByYearNum?.[yearNum]?.customFields?.amount)) {
              newPayments.push({
                id: null,
                payDate: '',
                amount: (purposesByYearNum?.[yearNum]?.customFields?.amount || '0').toString(),
                payBeforeDate: format(new Date(startDate.setFullYear(year)), formatStr),
                securityDocument: { id: securityDocument?.id || '', value: securityDocument?.id || '' },
                paymentPurpose: purposesByYearNum?.[yearNum] || null,
                paymentPurposeRequest: null,
              });
            }
            yearNum += 1;
          });
          handlePaymentsChange([...newPayments, ...oldPayments]);
        },
      },
    );
  }, [
    loadReferenceAPI,
    formatPurposesByYearNum,
    securityDocument?.requestDate,
    securityDocument?.documentEndDate,
    securityDocument.kind?.id,
    securityDocument?.id,
    payments,
    handlePaymentsChange,
  ]);

  const handleCalcSumClick = useCallback(() => {
    setSelectedRowsAmountSum(selectedRowsDocuments.map(i => parseFloat(i.amount)).reduce((acc, value) => acc + value, 0));
    setIsConfirmPopupOpened(true);
  }, [selectedRowsDocuments]);

  const extraToolbarButtons = useMemo<ExtraToolbarButton<SecurityDocumentPayment>[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр файла',
        onClick: payment => downloadFile(payment?.file?.id, token, true),
        isDisabled: payment => !payment?.file?.id,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл',
        onClick: payment => downloadFile(payment?.file?.id, token),
        isDisabled: payment => !payment?.file?.id,
      },
    ],
    [token],
  );

  const paymentExtraToolbarButtons = useMemo<ExtraToolbarButton<SecurityDocumentPayment>[]>(
    () => [
      {
        icon: { type: 'calendar' },
        title: 'Составить график оплаты пошлин',
        onClick: handleFillCalendarClick,
        isDisabled: disabled,
      },
      {
        icon: { type: 'calc' },
        title: 'Вычислить сумму выделенных строк',
        onClick: handleCalcSumClick,
        isDisabled: selectedRowsDocuments.length < 2,
      },
    ],
    [handleFillCalendarClick, disabled, handleCalcSumClick, selectedRowsDocuments.length],
  );

  return {
    payments,
    requestPayments,
    handleRequestPaymentsChange,
    handlePaymentsChange,
    extraToolbarButtons,
    paymentExtraToolbarButtons,
    paymentTitleByType,
    onSelectedRowsCallback,
    selectedRowsAmountSum,
    isConfirmPopupOpened,
    setIsConfirmPopupOpened,
  };
}

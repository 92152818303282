import React from 'react';

import { Tab, Tabs } from 'components';

import { EventConferences } from './EventConferences';
import { EventContests } from './EventContests';
import { EventConcerts } from './EventConcerts';
import { EventExpositions } from './EventExpositions';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';

type Props = {
  defaultTabId?: string;
  onTabClick?: (id?: string) => void;
};

export function EventList(props: Props) {
  const { defaultTabId, onTabClick } = props;

  const { initialOrganizationCode, userPermission } = useAppDataContext();

  return (
    <Tabs defaultTabId={defaultTabId} onTabClick={onTabClick}>
      <Tab
        title="Конференции"
        id="conference"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_EVENTS_CONFERENCE_ACCESS)}
      >
        <EventConferences />
      </Tab>
      <Tab
        title="Олимпиады и конкурсы"
        id="contest"
        isVisible={isHasPermission(userPermission, Permits.PC_MENU_EVENTS_CONTEST_ACCESS)}
      >
        <EventContests />
      </Tab>
      <Tab title="Выставки" id="exposition" isVisible={isHasPermission(userPermission, Permits.PC_MENU_EVENTS_EXPOSITION_ACCESS)}>
        <EventExpositions />
      </Tab>
      {initialOrganizationCode?.code === 'TSU' && (
        <Tab
          title="Творческие мероприятия"
          id="concert"
          isVisible={isHasPermission(userPermission, Permits.PC_MENU_EVENTS_CONCERT_ACCESS)}
        >
          <EventConcerts />
        </Tab>
      )}
    </Tabs>
  );
}

import { Project } from 'types/models';

export function onStagePreSubmit(
  stage: Project.Stage,
  submit: (row: Project.Stage) => void,
  index: number | null,
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>,
) {
  if (index !== null) {
    setProject(prev => ({
      ...prev,
      reports: prev.reports.map(report => ({
        ...report,
        stage: prev.stages[index].number === report.stage?.number ? stage : report.stage,
      })),
      equips: prev.equips.map(equip => ({
        ...equip,
        stage: prev.stages[index].number === equip.stage?.number ? stage : equip.stage,
      })),
    }));
  }
  submit(stage);
}

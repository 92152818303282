import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { NotificationEventModule } from 'utils/Enums';
import { State, makeUseCustomController } from './makeUseCustomController';

type RequestData = {
  module: NotificationEventModule;
  sourceId?: string;
};

export function GetNotificationMonitorList(requestData: RequestData): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetNotificationMonitorList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData,
    useCustomController: makeUseCustomController({ module: requestData.module, sourceId: requestData.sourceId }),
  };
}

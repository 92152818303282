import { Table } from 'types/models';
import { Color } from 'constants/colors';
import { parse } from 'date-fns';

import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Component as GridLeftPanelForThirdLevel } from './GridLeftPanelForThirdLevel';
import { formatStr } from 'utils/Constants';

export function GetSimlpleRntdListSpecification(deps: Table.Dependencies): Table.Specification {
  return {
    apiID: 'GetSimpleRntdList',
    templatesTableDependencies: deps.templatesTableDependencies,

    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

export const GetRntdList: Table.Specification = {
  apiID: 'GetRntdList',
  header: {
    firstLevel: {
      title: 'Результаты научно-технической деятельности',
      Panel: Filters,
    },
    thirdLevel: {
      withTemplatesPanel: true,
      LeftPanel: GridLeftPanelForThirdLevel,
    },
  },
  highlightRow: row => {
    if (row.Source === 'SecurityDocument') {
      return Color.success;
    } else if (parse(row.DocumentEndDate, formatStr, new Date()).getTime() < Date.now()) {
      return Color.danger;
    } else {
      return '';
    }
  },
};

export function RecognitionGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Признания и награды </strong>предназначен для ввода и хранения информации о персональных наградах или
        признаниях (награды министерств и ведомств, правительственные награды, областной и городской администрации, университета и
        пр.).
      </p>
    </>
  );
}

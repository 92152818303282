import React from 'react';

import { FormComponent, Select, SelectMode, TextInput, TextDateTime } from 'components';

import { SelectPerson } from 'features/SelectPerson';
import { PortfolioCard } from 'features/Form/looks/person';
import { REG_STATUS_NAME } from './changeStatusController';
import { Toolbar } from './Toolbar';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function UserEditForm({ viewMode, editMode, onClose }: Props) {
  const {
    handleFormSubmit,
    formFields,
    user,
    member,
    workMode,
    handleChangeStatus,
    isOpenPersonModal,
    setIsOpenPersonModal,
    externalButtons,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });
  return (
    <FormComponent.Template>
      <Toolbar
        user={user}
        disabledEdit={workMode === 'viewMode'}
        onSave={needClose => handleFormSubmit(needClose)}
        onSaveDraft={() => handleFormSubmit(false)}
        onActivate={() => handleChangeStatus(true)}
        onDeactivate={() => handleChangeStatus(false)}
      />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Имя">
            <TextInput
              value={formFields.userFullName.value}
              onChange={formFields.userFullName.onChange}
              isDisabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="E-Mail">
            <TextInput value={formFields.email.value} onChange={formFields.email.onChange} isDisabled />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.person.title} isRequired={formFields.person.required}>
            <SelectPerson
              member={member ?? null}
              onUpdateMember={formFields.person.onChange}
              withHistory={false}
              externalButtons={externalButtons}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата рождения">
            <TextDateTime
              value={formFields.birthDate.value}
              onChange={formFields.birthDate.onChange}
              isDisabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <PortfolioCard
          isOpen={isOpenPersonModal}
          id={isOpenPersonModal ? member?.person?.id ?? null : null}
          onClose={() => setIsOpenPersonModal(false)}
          shortMode={true}
        />
        <FormComponent.Line>
          <FormComponent.Field label="Подразделение" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.department.value}
              onChange={formFields.department.onChange}
              settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
              isDisabled={viewMode || user?.status?.value === REG_STATUS_NAME}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(UserEditForm);

import React, { useEffect } from 'react';
import { parse } from 'date-fns';
import * as FormTabs from './Tabs';

import { Tabs, Tab, FormComponent, Button, Modal, ButtonMode } from 'components';

import { Documents } from 'features/Documents';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useController } from './controller';
import { Performer, Project } from 'types/models/Project';
import { useFormContext } from 'features/Form/hooks';
import { DataGrid } from 'features/Table';
import {
  GetProjectCodeChangesInProject,
  GetProjectPerformerJobPeriodList,
  GetSalaryByProjectList,
  GetSalaryInsuranceList,
  GetSalaryVacationList,
} from 'features/Table/specifications';
import { formatDateTimeStr } from 'utils/Constants';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function ProjectCodeForm({ viewMode, editMode, onClose }: Props) {
  const {
    formFields,
    workMode,
    onSubmit,
    projectCode,
    replaceMode,
    handleFinPlanChange,
    handleDocumentsChange,
    documents,
    userPermission,
    findPerformerByJobPeriodRole,
    calendarPlansByYear,
    financingsByYear,
    calendarPlansByStages,
    handleFinancingSourceChange,
    financingTagnameByProjectType,
    generateProjectCode,
    isProjectChangesOpen,
    setIsProjectChangesOpen,
    processProjectChanges,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const {
    look: { setTitle },
  } = useFormContext();

  useEffect(() => {
    setTitle?.(
      (() => {
        const baseTitle: string = (() => {
          if (workMode === 'addMode') {
            return 'Добавление шифра проекта';
          }

          const leader =
            ((findPerformerByJobPeriodRole('LEADER', projectCode?.project) as unknown) as Performer)?.person?.fullName || '';
          const performer =
            ((findPerformerByJobPeriodRole('RESPONSIBLE_PERFORMER', projectCode?.project) as unknown) as Performer)?.person
              ?.fullName || '';

          return [
            [
              replaceMode ? 'Замена' : workMode === 'viewMode' ? 'Просмотр' : 'Редактирование',
              'шифра проекта',
              projectCode?.code || '',
            ]
              .filter(Boolean)
              .join(' '),
            leader ? `Руководитель: ${leader}` : '',
            performer ? `Отв. исполнитель: ${performer}` : '',
          ]
            .filter(Boolean)
            .join(', ');
        })();

        if (workMode === 'addMode') {
          return baseTitle;
        }

        const project: Project | null = projectCode?.project ?? null;
        const date = [project?.startDate, project?.endDate].filter(Boolean).join(' - ');
        const info = [
          project?.program?.shortName ? `${project?.program?.shortName}:` : '',
          project?.name,
          date ? `(${date})` : '',
        ]
          .filter(Boolean)
          .join(' ');

        if (info) {
          return (
            <>
              <h3>{baseTitle}</h3>
              <h4>{info}</h4>
            </>
          );
        }

        return baseTitle;
      })(),
    );
  }, [findPerformerByJobPeriodRole, projectCode?.code, projectCode?.project, replaceMode, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={() => onSubmit(true)} handleSaveAndContinue={() => onSubmit(false)} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О шифре">
            <FormTabs.AboutProjectCode
              projectCode={projectCode}
              formFields={formFields}
              disabled={viewMode}
              findPerformerByJobPeriodRole={findPerformerByJobPeriodRole}
              handleFinancingSourceChange={handleFinancingSourceChange}
              generateProjectCode={generateProjectCode}
              replaceMode={replaceMode}
            />
          </Tab>
          <Tab title="Финансовый план" isVisible={isHasPermission(userPermission, Permits.PROJECT_CODE_FINANCING)}>
            <FormTabs.FinPlan
              projectCode={projectCode}
              disabled={viewMode}
              handleFinPlanChange={handleFinPlanChange}
              calendarPlansByYear={calendarPlansByYear}
              financingsByYear={financingsByYear}
              calendarPlansByStages={calendarPlansByStages}
              financingTagnameByProjectType={financingTagnameByProjectType}
              formFields={formFields}
              handleSave={onSubmit}
            />
          </Tab>
          <Tab title="Поступление средств">
            <FormTabs.ReceivingDocuments projectCode={projectCode} />
          </Tab>
          <Tab title="Затраты">
            <FormTabs.Estimates
              projectCode={projectCode}
              isDisabled={workMode === 'viewMode' || !projectCode?.id}
              fundCard={
                formFields.fundCard?.value?.id
                  ? { id: formFields.fundCard.value.id, value: formFields.fundCard.value.code }
                  : undefined
              }
            />
          </Tab>
          <Tab
            title="Зарплата коллективу"
            isVisible={isHasPermission(userPermission, [Permits.SALARY_FULL_ACCESS, Permits.ENCODING_SALARY_ACCESS])}
          >
            <Tabs>
              <Tab title="Зарплата коллективу">
                <DataGrid specification={GetSalaryByProjectList(projectCode?.id || '-1')} />
              </Tab>
              <Tab title="Контроль состава">
                <DataGrid
                  specification={GetProjectPerformerJobPeriodList({ projectId: projectCode?.project?.id || undefined })}
                />
              </Tab>
              <Tab title="Отпуска и характер работы">
                <DataGrid specification={GetSalaryVacationList(projectCode?.id || '-1')} />
              </Tab>
              <Tab title="Страховые взносы">
                <DataGrid specification={GetSalaryInsuranceList(projectCode?.id || '-1')} />
              </Tab>
            </Tabs>
          </Tab>
          <Tab title="История изменения данных">
            <FormComponent.Description mode="warning">
              Информация об изменениях в проекте ключевых атрибутов: сроки проекта, сумма финансирования в этапе, подразделение,
              на базе которого выполняется проект.
              <br />
              Проверьте на соответствие с новым значением данные шифра и отредактируйте, если это необходимо.
              <br />
              Для записи в таблице с текстом сообщения об изменении в проекте поставьте отметку "Обработано" с помощью кнопки{' '}
              <Button icon={{ type: 'check', size: 12 }} />
            </FormComponent.Description>

            <DataGrid
              specification={GetProjectCodeChangesInProject({
                projectId: projectCode?.project?.id || '',
                projectCodeId: projectCode?.id,
                isDisabled: workMode === 'viewMode',
              })}
            />
          </Tab>
          <Tab title="Документы шифра">
            <Documents
              isCanIsSharedEdit
              sharedLabel="Отображать в проекте"
              documents={documents}
              sourceLabelForLocalDocs="Текущий шифр"
              setDocuments={handleDocumentsChange}
              disabled={viewMode ?? false}
              isShowSource
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>

      <Modal
        mode="help"
        isOpen={isProjectChangesOpen}
        onClose={() => setIsProjectChangesOpen(false)}
        title={`Внимание: в проекте №${projectCode.project?.number} внесены изменения`}
        actions={[
          { text: 'Больше не показывать это сообщение', onClick: processProjectChanges, mode: ButtonMode.PRIMARY },
          { text: 'Продолжить', onClick: () => setIsProjectChangesOpen(false), mode: ButtonMode.PRIMARY },
        ]}
        size="medium"
      >
        <table>
          <tbody>
            {projectCode.changesInProject
              .filter(x => !x.isProcessed)
              .sort(
                (a, b) =>
                  parse(a.date, formatDateTimeStr, new Date()).getTime() - parse(b.date, formatDateTimeStr, new Date()).getTime(),
              )
              .map((change, index) => (
                <tr key={index}>
                  <td>
                    <div>
                      <strong>Дата и время:</strong> {change.date} {change.changedBy?.fullName}
                    </div>
                    <div>
                      <strong>Тип измененных данных:</strong> {change.type?.label}
                    </div>
                    <div>
                      <strong>Новое значение:</strong> {!!change.amountNew && change.amountNew}
                      {(!!change.startDateNew || !!change.startDateOld) && `${change.startDateNew} - ${change.startDateOld}`}
                      {!!change.departmentNew && change.departmentNew?.label}
                      {!!change.sourceNew && change.sourceNew?.label}
                      {change.ndsNew !== null && (change.ndsNew ? 'Да' : 'Нет')}
                    </div>
                  </td>
                </tr>
              ))}
          </tbody>
        </table>

        <FormComponent.Description mode="info">
          Проверьте на соответствие с новым значением данные шифра и отредактируйте, если это необходимо.
        </FormComponent.Description>
        <FormComponent.Description mode="warning">
          При выборе "Больше не показывать это сообщение" в "Журнале изменений в проекте" для этой записи установиться статус
          "обработана"
        </FormComponent.Description>
      </Modal>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProjectCodeForm);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useController } from './controller';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    isReportOpen,
    isViewFormOpen,
    buttons,
    currentReport,
    event,
    isParticipation,
    onReportClose,
    handleTemplateCloseViewForm,
    isHelpFormOpen,
    setIsHelpFormOpen,
  } = useController(tableState);

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.ViewTemplate.Component
          isOpen={isViewFormOpen}
          isParticipation={isParticipation}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          event: event,
          conferenceEventId: event.id,
          contestEventId: event.id,
          exhibitionEventId: event.id,
          isEventParameterHidden: true,
        }}
      />

      <Modal
        mode="help"
        title="Особенности заполнения на вкладке: Мероприятия и участия в мероприятиях"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {ProjectHelp().resultsEvents}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Community, Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { useLocalTableStreams } from 'features/Table/hooks';
import { Template } from 'types/models/Form';
import { CommunityType } from 'utils/Enums';
import { Entry } from 'types/models/Table';
import { IdItem } from 'types/models/common';
import { showNotification } from 'features/Notifications';

export function useController(tableState: Table.State, communityTypes: CommunityType[]) {
  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Entry | null = selectedRows[0] || null;

  const templateName: Template = 'CommunityForm';

  const isApproved: boolean = tableState.selectedRows[0]?.['id:Status'] === 'APPROVED';
  const isDissertationCouncil = communityTypes.includes(CommunityType.DISSERTATION_COUNCIL);

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isApproveConfirmPopupOpen, setIsApproveConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);
  const [isCloneOpen, setIsCloneOpen] = useState<boolean>(false);
  const [cloneInfo, setCloneInfo] = useState<{ prototype: IdItem | null; orderNumber: string; orderDate: string }>({
    prototype: null,
    orderNumber: '',
    orderDate: '',
  });
  const [community, setCommunity] = useState<Community>();

  const { methods: cloneCommunityAPI } = BackendAPI.useBackendAPI('CloneCommunity');

  const clearClone = useCallback(() => {
    setCloneInfo({ prototype: null, orderNumber: '', orderDate: '' });
    setCommunity(undefined);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'Community',
    });
    setIsDeleteConfirmPopupOpen(false);
  }, [tableState.selectedRows, tableStreams.deleteRow]);

  const handleConfirmApproveConfirmPopup = useCallback(() => {
    tableStreams.approveRow.push({
      approveRowId: tableState.selectedRows[0]?.id,
      command: 'ApproveCommunity',
      approveItemName: 'Community',
      disapprove: isApproved,
    });
    setIsApproveConfirmPopupOpen(false);
  }, [isApproved, tableState.selectedRows, tableStreams.approveRow]);

  const cloneCommunity = useCallback(() => {
    if (!cloneInfo.prototype?.id || !cloneInfo.orderDate) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }
    cloneCommunityAPI.callAPI(
      { id: cloneInfo.prototype.id, orderNumber: cloneInfo.orderNumber, orderDate: cloneInfo.orderDate },
      {
        onSuccessfullCall: ({ data }) => {
          setCommunity(data);
          showNotification({ message: 'Копия сообщества создана', theme: 'success' });
          tableStreams.reloadTable.push({});
          setIsCloneOpen(false);
          setIsEditFormOpen(true);
        },
      },
    );
  }, [cloneCommunityAPI, cloneInfo.orderDate, cloneInfo.orderNumber, cloneInfo.prototype?.id, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpFormOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        permission: { name: Permits.COMMUNITY_INFO_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setNextPublicationName('');
          setIsAddFormOpen(true);
        },
        permission: { name: Permits.COMMUNITY_INFO_VIEW },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        permission: { name: Permits.COMMUNITY_INFO_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        permission: { name: Permits.COMMUNITY_INFO_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'document', mode: 'arrowRight' },
        title: 'Создать копию сообщества на следующий период',
        onClick: () => {
          clearClone();
          setCloneInfo({ prototype: { id: selectedRow?.id, value: selectedRow?.Name }, orderNumber: '', orderDate: '' });
          setIsCloneOpen(true);
        },
        permission: { name: Permits.COMMUNITY_INFO_VIEW },
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'like' },
        title: isApproved ? 'Перевести в статус "Добавлено"' : 'Утвердить',
        onClick: () => setIsApproveConfirmPopupOpen(true),
        permission: { name: Permits.COMMUNITY_APPROVE },
        isDisabled: selectedRows.length !== 1,
      },
    ],
    [selectedRows.length, selectedRow, isApproved, clearClone],
  );

  return {
    buttons,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isViewFormOpen,
    nextPublicationName,
    templateName,
    setIsApproveConfirmPopupOpen,
    handleConfirmApproveConfirmPopup,
    isApproveConfirmPopupOpen,
    isApproved,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isDissertationCouncil,
    isCloneOpen,
    setIsCloneOpen,
    cloneInfo,
    setCloneInfo,
    cloneCommunity,
    community,
    clearClone,
  };
}

export function ProgramGridHelp(): JSX.Element {
  return (
    <>
      <h3>Список программ поддержки научной деятельности</h3>

      <p>
        Список содержит федеральные, международные, региональные, отраслевые программы поддержки научных исследований,
        академической мобильности, инновационной деятельности, в частности,
        <strong> рекомендуется занести в этот список</strong> следующие программы:
      </p>

      <ul>
        <li>
          Гранты РНФ — для выделения группы проектов РНФ и контроля соблюдения требований Фонда к составу и численности
          коллектива, расходованию средств, показателям и индикаторов результативности, отчётности;
        </li>
        <li>
          Программу(ы) развития университета — для организации внутренних конкурсов на выполнение НИР и ОКР; выделения группы
          проектов с целью контроля выполнения показателей и индикаторов; отчётности; в том числе, загрузки данных в ЕГИСУ
        </li>
        <li>
          Госзадание — для выделения группы проектов с целью контроля выполнения показателей и индикаторов; отчётности; в том
          числе, загрузки данных в ЕГИСУ
        </li>
        <li>
          Программы, в рамках которых заключены соглашения о сотрудничестве с предприятиями и организациями и сроки действия
          соглашения ограничены сроками действия Программы;
        </li>
        <li>
          Программу(ы) академической мобильности, например, Университет «Сириус» — для выделения группы заявок и проектов и
          мониторинга отложенных результатов и эффективности участия в программе за период.
        </li>
      </ul>

      <p>
        <strong>Полное название</strong> программы обычно используется в отчётных формах.
      </p>
      <p>
        <strong>Краткое название</strong> программы — выводится в столбцах многочисленных таблиц со списками проектов, заявок,
        конкурсов и из основных признаков группировки в списках Дата начала и дата окончания программы — не обязательные поля;
        если заполнены, учитываются в фильтре Год; если не заполнены, то в алгоритме фильтра Год такие программы считаются
        актуальными всегда.
      </p>
      <p>
        <strong>Логотип программы</strong> используется для оформления объявлений о конкурсах.
      </p>
      <p>
        В Положении о программе (федеральные целевые программы, национальные программы технологического развития и пр.) могут быть
        разделения на крупные <strong>направления и мероприятия</strong> целевого финансирования за счёт средств программы. Кроме
        того, в положении прописаны <strong>показатели результативности и индикаторы эффективности </strong>
        действия программы. В случае, если в университете выполняются проекты, рекомендуется внести эти направления на вкладке
        «Направления», мероприятия программы на вкладке «Мероприятия», индикаторы и показатели — это позволит быстро формировать
        отчёты по запросам Министерства образования о результатах выполнения проектов по программе в разрезе направлений и
        мероприятий.
      </p>

      <h3>Особенности заполнения данных на вкладках «Индикаторы» и «Показатели»</h3>

      <p>
        <strong>Индикаторы эффективности и показатели результативности</strong> программы выбираются из одноимённых справочников
        (см. п.м. Справочники), в которых хранятся коллекции всех индикаторов и показателей из разных программ. Опыт показывает,
        что в разных программах используются очень похожие по названию индикаторы, пусть вас не смущает, что в справочнике со
        временем накопятся смысловые дубликаты, попытка привести к общему знаменателю (использовать «универсальные» индикаторы)
        пагубна, потому что в отчёте по запросу необходимо указывать точное название индикатора как в программе. Поскольку для
        длительных программ на разных этапах индикаторы и показатели могут неактуальными или появляться новые,{' '}
        <strong>
          при подготовке к объявлению внутреннего конкурса необходимо актуализировать коллекцию показателей и индикаторов в
          программе.
        </strong>
      </p>

      <div className="warning">
        <span>
          В заявках на внутренний конкурс вкладка «Показатели эффективности» формируется исключительно по наличию в программе
          актуальных показателей и индикаторов; располагаются на форме в порядке возрастания кодов.
        </span>
      </div>
    </>
  );
}

import { Event, Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { makeUseCustomController, State } from './makeUseCustomController';

type Props = {
  sourceEvent?: Event.Event | null;
  reloadEvent?(): void;
  isEventChanged?: boolean;
  unconnectedParticipationPublications?: Event.Source[];
};

export function GetConferenceEventParticipationList(args: Props, workMode: Table.WorkMode): Table.Specification<State> {
  return {
    apiID: 'GetConferenceEventParticipationList',
    requestData: args.sourceEvent ? { eventId: args.sourceEvent.id } : undefined,
    header: {
      firstLevel: {
        title: 'Список участий в конференции',
      },
      thirdLevel: {
        LeftPanel: LeftPanelForThirdLevel,
        withTemplatesPanel: true,
      },
    },
    useCustomController: makeUseCustomController({
      workMode: workMode,
      sourceEvent: args.sourceEvent || null,
      reloadEvent: args.reloadEvent,
      isEventChanged: args.isEventChanged,
      unconnectedParticipationPublications: args.unconnectedParticipationPublications,
    }),
  };
}

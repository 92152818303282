import React from 'react';

import { FormComponent, Select, SelectMode, TextArea, UploadFile } from 'components';

import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function QnaQuestionEditForm({ onClose }: Props) {
  const { questionText, themeText, formFields, saveQnaQuestionHandler } = useController({ onClose });
  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveQnaQuestionHandler} />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Тема" isRequired={formFields.question.required}>
            {!formFields.answer?.value ? (
              <Select
                mode={SelectMode.REFERENCE}
                value={formFields.questionTheme.value}
                onChange={formFields.questionTheme.onChange}
                settings={{ name: 'RefQnaQuestionTheme', title: 'Тема', isClearable: !formFields.question.required }}
              />
            ) : (
              <div>{themeText}</div>
            )}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Вопрос" isRequired={formFields.question.required}>
            {!formFields.answer?.value ? (
              <TextArea
                settings={{ rows: 5, maxSymbols: 2049 }}
                value={formFields.question.value}
                onChange={formFields.question.onChange}
              />
            ) : (
              <div>{questionText}</div>
            )}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Прикрепите файл">
            <UploadFile file={formFields.questionFile.value} onChange={formFields.questionFile.onChange} />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(QnaQuestionEditForm);

import { useCallback } from 'react';

import { Project, Table as T } from 'types/models';
import { ValueOf } from 'types/helpers';
import { GetProjectPerformerOrderList } from 'features/Table/specifications';
import { JobPeriodOrder } from 'types/models/Project';

type Arguments = {
  jobPeriod: Project.JobPeriod;
  setJobPeriod(jobPeriod: Project.JobPeriod): void;
  projectId: string | null;
};

export function useController(args: Arguments) {
  const { jobPeriod, setJobPeriod, projectId } = args;

  const makeChangeHandler = (key: keyof Project.JobPeriod) => (value: ValueOf<Project.JobPeriod>) => {
    setJobPeriod({ ...jobPeriod, [key]: value });
  };

  const performerOrderModalTableRowConverter = useCallback<(row: T.Entry) => JobPeriodOrder>(row => {
    return {
      id: row.id,
      number: row.OrderNumber,
    };
  }, []);

  const performerOrderModalTableSpecification = GetProjectPerformerOrderList({
    hasSelectButton: true,
    projectId,
  });

  const handleStartOrderChange = useCallback(
    (value: JobPeriodOrder | null) => {
      setJobPeriod({ ...jobPeriod, startDateOrder: value } as Project.JobPeriod);
    },
    [jobPeriod, setJobPeriod],
  );

  const handleEndOrderChange = useCallback(
    (value: JobPeriodOrder | null) => {
      setJobPeriod({ ...jobPeriod, endDateOrder: value } as Project.JobPeriod);
    },
    [jobPeriod, setJobPeriod],
  );

  return {
    makeChangeHandler,
    performerOrderModalTableRowConverter,
    performerOrderModalTableSpecification,
    handleStartOrderChange,
    handleEndOrderChange,
  };
}

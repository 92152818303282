import React from 'react';

import { Modal, Toolbar as SharedToolbar } from 'components';

import useController from './controller';
import { LicenceAgreementContractGridHelp } from './help';

export const Toolbar = () => {
  const { buttons, isHelpFormOpen, setIsHelpFormOpen } = useController();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="План - график платежей по договорам"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="medium"
      >
        {LicenceAgreementContractGridHelp()}
      </Modal>
    </>
  );
};

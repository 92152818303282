import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { Project } from 'types/models';

type Props = {
  resourceUsageDepartment: Project.ResourceUsageDepartment;
  setResourceUsageDepartment: React.Dispatch<React.SetStateAction<Project.ResourceUsageDepartment | null>>;
};

export const ResourceUsageDepartmentFields = (props: Props) => {
  const { resourceUsageDepartment, setResourceUsageDepartment } = props;

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={resourceUsageDepartment.department}
            onChange={(e: ReferenceItem | null) => setResourceUsageDepartment(prev => ({ ...prev!, department: e }))}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
          {(resourceUsageDepartment.department?.customFields?.kind as ReferenceItem)?.label || 'Не определён'}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3, title: 'Примечание' }}
            value={resourceUsageDepartment.note ?? ''}
            onChange={e => setResourceUsageDepartment(prev => ({ ...prev!, note: e }))}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
};

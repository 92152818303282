import { Table } from 'types/models';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Arguments = {
  projectId: string | null;
};

export function GetProjectSecurityDocumentList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectSecurityDocumentList',
    isFiltersHidden: true,
    requestData: args,
    header: {
      firstLevel: {
        title: 'Охрана результатов интеллектуальной деятельности',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

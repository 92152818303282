import { Table } from 'types/models';

import { makeUseCustomController, State } from './makeUseCustomController';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Arguments = {
  userId: string;
  isWithoutHeader?: boolean;
};

type RequestData = {
  userId: string;
};

export function GetUserRoleList({ userId, isWithoutHeader }: Arguments): Table.Specification<State, RequestData> {
  return {
    apiID: 'GetUserRoleList',
    useCustomController: makeUseCustomController({ userId }),
    header: {
      firstLevel: {
        title: isWithoutHeader ? '' : userId ? 'Роли пользователя' : 'Роли пользователей',
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    requestData: { userId },
  };
}

import { NotificationEventModule } from 'utils/Enums';

export type State = {
  module: NotificationEventModule;
  sourceId?: string;
};

export function makeUseCustomController({ module, sourceId }: State) {
  return function useCustomController(): State {
    return {
      module,
      sourceId,
    };
  };
}

import * as XML from 'xml-js';
import { makeBackendAPIConfiguration } from './makeBackendAPIConfiguration';

export type FieldSuggestionItem = {
  label: string;
};

export type FieldSuggestionResponseData = {
  fieldSuggestions: FieldSuggestionItem[];
  success: boolean;
};

const converSuggestions = (suggestions: any): FieldSuggestionItem[] => {
  const isMemberArray = !!suggestions.Suggestion?.length;
  const isMemberObject = !!suggestions.Suggestion?._text;
  if (isMemberArray) {
    return suggestions.Suggestion.map((member: any) => ({
      label: member._text || '',
    }));
  }

  if (isMemberObject) {
    return [
      {
        label: suggestions.Suggestion._text || '',
      },
    ];
  }

  return [];
};

const convertGetFieldSuggestionResponseXMLElementToOutput = (response: XML.ElementCompact): FieldSuggestionResponseData => {
  const success = response.Response._attributes.success === 'true';
  const output = {
    success,
    fieldSuggestions: response.Response.Suggestions ? converSuggestions(response.Response.Suggestions) : [],
  };

  return output;
};

export const makeBackendFieldSuggestionAPIConfiguration = <T extends string, Input>(
  command: T,
  convertInputToXMLElement: (input: Input) => XML.ElementCompact,
) =>
  makeBackendAPIConfiguration({
    id: command,
    endpoint: `/msa/service/commands/${command}`,
    convertInputToXMLElement,
    convertResponseXMLElementToOutput: convertGetFieldSuggestionResponseXMLElementToOutput,
  });

import { useCallback } from 'react';
import { Project } from 'types/models';
import { ValueOf } from 'types/helpers';

type Props = {
  makeChangeHandler: (key: keyof Project.Project) => (value: ValueOf<Project.Project>) => void;
};
const useController = ({ makeChangeHandler }: Props) => {
  const onChangeIsContractProvision = useCallback(
    (nextIsContractProvision: boolean) => {
      makeChangeHandler('isContractProvision')(nextIsContractProvision);
      if (!nextIsContractProvision) {
        makeChangeHandler('contractProvisionNote')('');
        makeChangeHandler('contractProvisionAmount')('0.00');
        makeChangeHandler('contractProvisionType')(null);
      }
    },
    [makeChangeHandler],
  );
  const onChangeIsRequestProvision = useCallback(
    (nextIsRequestProvision: boolean) => {
      makeChangeHandler('isRequestProvision')(nextIsRequestProvision);
      if (!nextIsRequestProvision) {
        makeChangeHandler('requestProvisionNote')('');
        makeChangeHandler('requestProvisionAmount')('0.00');
        makeChangeHandler('requestProvisionType')(null);
      }
    },
    [makeChangeHandler],
  );
  return {
    onChangeIsRequestProvision,
    onChangeIsContractProvision,
  };
};

export default useController;

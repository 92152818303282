import { Event } from 'types/models';

export class EventTypes {
  static EVENT_CONFERENCE: Event.EventType = {
    label: 'Конференция',
    labelPlural: 'Конференции',
    nominativeCaseLabel: 'конференция',
    genitiveCaseLabel: 'конференции',
    labelDeclined: 'конференции',
    code: 'CONFERENCE',
    template: 'EventFormConference',
  };

  static EVENT_EXPOSITION: Event.EventType = {
    label: 'Выставка',
    labelPlural: 'Выставки',
    labelDeclined: 'выставки',
    nominativeCaseLabel: 'выставка',
    genitiveCaseLabel: 'выставок',
    code: 'EXPOSITION',
    template: 'EventFormExposition',
  };

  static EVENT_CONTEST: Event.EventType = {
    label: 'Конкурсы и олимпиады',
    labelPlural: 'Конкурсы (олимпиады)',
    labelDeclined: 'конкурса (олимпиады)',
    nominativeCaseLabel: 'конкурсы (олимпиады)',
    genitiveCaseLabel: 'конкурсов (олимпиад)',
    code: 'CONTEST',
    template: 'EventFormContest',
  };

  static EVENT_CONCERT: Event.EventType = {
    label: 'Творческое мероприятие',
    labelPlural: 'Творческие мероприятия',
    labelDeclined: 'творческого мероприятия',
    nominativeCaseLabel: 'творческого мероприятия',
    genitiveCaseLabel: 'творческих мероприятий',
    code: 'CONCERT',
    template: 'EventFormConcert',
  };

  static EVENT_CONTEST_SINGLE_LABEL: string = 'Конкурс (олимпиада)';
}

import React, { memo } from 'react';

import { ButtonMode, Modal, Toolbar } from 'components';

import { useController } from './controller';
import { Table } from 'types/models';
import { ProjecRestrictionGridHelp } from './help';
import { BuildReportPopup } from 'features/BuildReportPopup';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    uniqProjects,
    isOpenCalcConfirmPopupOpen,
    setIsOpenCalcConfirmPopupOpen,
    handleCalculateProjectRestrictions,
    isHelpOpen,
    setIsHelpOpen,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenCalcConfirmPopupOpen}
        onClose={() => setIsOpenCalcConfirmPopupOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: handleCalculateProjectRestrictions,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenCalcConfirmPopupOpen(false),
          },
        ]}
        size="medium"
      >
        <>
          Выбранные проекты:
          <ul>
            {uniqProjects.map((i, index) => (
              <li key={index}>
                <span>
                  ({i.ProjectNumber}) {i.ProjectName}
                </span>
              </li>
            ))}
          </ul>
          <br />
          <strong>Процедура расчёта может занять некоторое время.</strong>
          <br />
          Выполнить расчёт?
        </>
      </Modal>

      <Modal
        mode="help"
        title="Ограничения для проектов"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {ProjecRestrictionGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);

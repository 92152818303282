import { useMemo, useState, useCallback, useEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, ReferenceItem } from 'components';

import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';
import { validate } from 'features/Table/specifications/GetReferenceElementList/validate';
import { EditableMetadataField } from '../../GetReferenceElementList/model';

export function useController() {
  const tableStreams = useLocalTableStreams();

  const [isAddFormOpen, setIsAddFormOpen] = useState<boolean>(false);
  const [fields, setFields] = useState<EditableMetadataField[]>([]);

  const { methods: getReferenceMetadataAPI } = BackendAPI.useBackendAPI('GetReferenceMetadata', {
    onSuccessfullCall: ({ data }) => {
      const dataFields = data.fields.map<EditableMetadataField>(x => {
        return {
          ...x,
          value: x.type === 'BOOLEAN' ? false : null,
        } as any;
      });
      setFields(dataFields);
    },
  });

  const { methods: saveReferenceElementAPI } = BackendAPI.useBackendAPI('SaveReferenceElement', {
    onSuccessfullCall: () => {
      tableStreams.reloadTable.push({});
      showNotification({ message: 'Элемент справочника успешно сохранен', theme: 'success' });
      setIsAddFormOpen(false);
    },
  });

  const handleChangeField = useCallback(
    (name: string, value: string | boolean | ReferenceItem | null) => {
      const index = fields.findIndex(x => x.name === name);
      setFields(prevFields =>
        (() => {
          let newFields = prevFields;
          newFields[index] = { ...newFields[index], value: value as any };
          return newFields;
        })(),
      );
    },
    [setFields, fields],
  );

  const saveReference = useCallback(() => {
    const validateInfo = validate(fields);
    if (validateInfo.length) {
      validateInfo.forEach(x => setTimeout(() => showNotification({ message: x.message, theme: 'danger' }), 0));
      return;
    }

    saveReferenceElementAPI.callAPI({ name: 'RefEnterprise', fields, rowId: null });
  }, [fields, saveReferenceElementAPI]);

  useEffect(() => {
    getReferenceMetadataAPI.callAPI({ name: 'RefEnterprise' });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
      },
    ],
    [],
  );

  const formButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => saveReference(),
      },
    ],
    [saveReference],
  );

  return {
    buttons,
    formButtons,
    isAddFormOpen,
    setIsAddFormOpen,
    fields,
    handleChangeField,
  };
}

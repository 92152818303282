import React from 'react';
import modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar } from 'components';

import { Table } from 'types/models';
import { DataGrid } from 'features/Table';
import { useController } from './controller';
import { GetEquipToVerificationList } from '../../GetEquipToVerificationList';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { EquipVerificationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
    isEquipSelectOpen,
    setIsEquipSelectOpen,
    equipId,
    setEquipId,
    currentReport,
    isReportOpen,
    onReportClose,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.View
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.Edit
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={selectedRow?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && !!equipId && (
        <modalTemplates.Add
          isOpen={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          id={equipId}
          relatedTableState={tableState}
        />
      )}

      {isDeleteConfirmPopupOpen && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>({tableState.selectedRows[0]?.Name}) Удалить оборудование?</>
        </Modal>
      )}

      {isEquipSelectOpen && (
        <Modal
          isOpen={isEquipSelectOpen}
          onClose={() => setIsEquipSelectOpen(false)}
          title="Выберите оборудование для добавления метрологии"
          size={['extralarge', 'large-height']}
        >
          <DataGrid
            specification={{
              ...GetEquipToVerificationList({ hasSelectButton: true }),
              onSubmitTable: e => {
                setEquipId(e.selectedRows[0]?.id || null);
                setIsEquipSelectOpen(false);
                setIsAddFormOpen(true);
              },
            }}
          />
        </Modal>
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          partnerId: selectedRow?.verificationEnterprisePlanId ?? '',
        }}
      />

      <Modal
        mode="help"
        title="Метрологическое сопровождение оборудования"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {EquipVerificationGridHelp()}
      </Modal>
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

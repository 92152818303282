import React, { memo } from 'react';
import * as ReportTabs from './Tabs';

import { Content, FormComponent, Select, Tabs, Tab } from 'components';

import useController from './controller';
import { Item } from 'types/models/common';
import { useRouteNavigation } from 'utils/Common';

const Reports = () => {
  const {
    documents,
    groups,
    currentGroup,
    isBuildReportPopupOpen,
    setupReportsColumns,
    setupReportsToolbarButtons,
    setupReportsSelectedRowIndex,
    closeBuildReportPopup,
    setupReportsSetSelectedRowIndex,
    setDocuments,
    saveReportDocument,
    editReportDocument,
    deleteReportDocument,
    updateCurrentGroup,
    onRowDoubleClick,
  } = useController();

  const { url, changeUrl } = useRouteNavigation({
    prefix: ['reports'],
    init: ['setup'],
  });

  return (
    <Content>
      <h2>Отчеты</h2>

      <FormComponent.Template>
        <FormComponent.Line>
          <FormComponent.Field>
            <FormComponent.ColumnWrapper>
              <FormComponent.Column style={{ width: '50%' }}>
                <FormComponent.Line>
                  <FormComponent.Field label="Группы отчетов">
                    <Select
                      value={{ value: currentGroup?.id || '', label: currentGroup?.caption || '' }}
                      options={groups.map(
                        group =>
                          (({
                            value: group.id,
                            label: group.caption,
                          } as unknown) as Item),
                      )}
                      onChange={(option: Item) => {
                        updateCurrentGroup(option.value);
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Template>
          <Tabs id="reports" defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
            <Tab title="Формирование отчетов" id="setup">
              <ReportTabs.SetupReport
                reports={currentGroup?.list || []}
                isBuildReportPopupOpen={isBuildReportPopupOpen}
                columns={setupReportsColumns}
                toolbarButtons={setupReportsToolbarButtons}
                selectedRowIndex={setupReportsSelectedRowIndex}
                setSelectedRowIndex={setupReportsSetSelectedRowIndex}
                closeBuildReportPopup={closeBuildReportPopup}
                onRowDoubleClick={onRowDoubleClick}
              />
            </Tab>
            <Tab title="Готовые отчеты" id="ready">
              <ReportTabs.ReadyReports
                documents={documents}
                setDocuments={setDocuments}
                addCallback={saveReportDocument}
                editCallback={editReportDocument}
                deleteCallback={deleteReportDocument}
              />
            </Tab>
          </Tabs>
        </FormComponent.Template>
      </FormComponent.Template>
    </Content>
  );
};

export const Component = memo(Reports);

export enum Operation {
  ADD = 'ADD',
  EDIT = 'EDIT',
  DELETE = 'DELETE',
}

export type CrudCallbackArguments = {
  operation: Operation;
  name: string;
  author: string;
};

export type SettingOption = { name: string; author: string };

import { parse } from 'date-fns';

import { Table } from 'types/models';
import { Filters } from './filters';
import { makeUseCustomController } from './makeUseCustomController';
import { Color } from 'constants/colors';
import { formatStr } from 'utils/Constants';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Props = {
  projectId?: string | null;
  isHideFirstLevel?: boolean;
  isWithTemplatesPanel?: boolean;
  isFiltersHidden?: boolean;
  hasSelectButton?: boolean;
  title?: string;
};

type Arguments = {
  projectId?: string | null;
};

export function GetProjectPerformerJobPeriodList(args?: Props): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectPerformerJobPeriodList',
    requestData: args,
    isFiltersHidden: args?.isFiltersHidden,
    header: {
      firstLevel: {
        title: 'Список участников проектов',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: args?.hasSelectButton,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
    useCustomController: makeUseCustomController({
      projectId: args?.projectId || undefined,
    }),
    highlightRow: (row: Table.Entry) => {
      if (row.ProjectEndDate && row.PerformerJobPeriodEndDate) {
        const nowDate = new Date(new Date().setHours(0, 0, 0, 0));
        if (
          parse(row.ProjectEndDate, formatStr, new Date()) >= nowDate &&
          nowDate >= parse(row.PerformerJobPeriodEndDate, formatStr, new Date())
        ) {
          return Color.danger;
        }
      }
      return '';
    },
  };
}

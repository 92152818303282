import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { setup } from 'bem-cn';
import * as M from './model';

import { ButtonMode, Info, Modal, RelationTableModal, RequiredBadge, Tooltip } from 'components';

import { ListEditTable, SortType } from './ListEditTable';
import { CollapsibleComponent } from './CollapsibleComponent';
import { ModalComponent } from './ModalComponent';
import { ToolbarButtons } from './ToolbarButtons';
import { SubmitTable } from 'features/Table/streams';
import { showNotification } from 'features/Notifications';
import { Color } from 'constants/colors';
import { sortItems } from './helpers';
import { getHashObject } from 'utils/Helpers';

import './style.scss';

const block = setup({ mod: '--' });
const b = block('list-edit');

export type ExtendedRow<Row> = { extendedRowId: number } & Row;

type Header = {
  title?: string;
  tooltip?: string;
  info?: JSX.Element;
  isRequired?: boolean;
  filters?: JSX.Element;
};

export type Props<TableState extends {}, Row> = {
  rows: Row[];
  onChange(rows: Row[]): void;

  // table
  defaultRowsCount?: number;
  getRowTheme?(row: Row): Color | '';
  /** @property {Header} header - (optional) Header data */
  header?: Header;
  /** @property {string} maxHeight - (optional) If set any value, table > thead is sticky */
  maxHeight?: string;

  // rows
  isUniq?: boolean;
  isSelectableRows?: boolean;
  onRowDoubleClick?(index: number, row?: ExtendedRow<Row>): void;
  onSelectedRowIndexCallback?: (selectedRowIndex: null | number) => void;
  onSelectedRowsCallback?: (selectedRows: ExtendedRow<Row>[]) => void;

  // toolbar
  // eslint-disable-next-line max-len
  /** @property {(M.ToolbarButton<Row> | false)[] | false} toolbar - (optional) Toolbar of buttons (presetted or presetted with custom param or full custom) (See ToolbarButton on model of ListEdit) */
  toolbar?: (M.ToolbarButton<Row> | false)[] | false;
  isDeleteConfirmEnabled?: boolean;
  isDisabled?: boolean;
  onClosedHandler?: () => void;
  onOpenedHandler?: () => void;
  withMessages?: boolean;

  // columns
  columns: M.Column<Row>[];
  columnIndexesForIntegerTotal?: number[];
  columnIndexesForSumTotal?: number[];
  isSortEnabled?: boolean;
  isVisibleFilters?: boolean;
  withoutHead?: boolean;

  // specification
  specification: M.ModeSpecification<Row, TableState>;
  extraFieldsToolbarButtons?: M.ExtraToolbarButton<Row>[];
  isModalHintHidden?: boolean;
  isSaveAndAddCustomComponent?: boolean;
};

const onlyUniq = <T,>(arr: T[]) => {
  const arrHashs: string[] = arr.map(i => getHashObject({ field: i }));
  return arrHashs.map((i, index) => (index === arrHashs.indexOf(i) ? arr[index] : null)).filter(Boolean) as T[];
};

/**
 * ListEdit component
 *
 * @param {string} maxHeight - (optional) If set any value, `table > thead` - is sticky
 * @returns {JSX} JSX
 */
function ListEdit<CustomTableState extends {}, Row>(props: Props<CustomTableState, Row>) {
  const {
    toolbar,
    header,
    columnIndexesForSumTotal = [],
    columnIndexesForIntegerTotal = [],
    columns,
    defaultRowsCount,
    extraFieldsToolbarButtons = [],
    getRowTheme,
    isDeleteConfirmEnabled,
    isModalHintHidden = false,
    isSaveAndAddCustomComponent = false,
    isDisabled = false,
    isUniq = true,
    isVisibleFilters = false,
    isSortEnabled = false,
    isSelectableRows = true,
    maxHeight,
    onChange,
    onClosedHandler,
    onOpenedHandler,
    rows: sourceRows,
    specification,
    withMessages,
    withoutHead = false,
    onSelectedRowIndexCallback,
    onSelectedRowsCallback,
    onRowDoubleClick,
  } = props;

  const defaultSortInfo = columns.find(x => x.defaultSort)?.defaultSort;
  const defaultSort: Record<number, SortType> = defaultSortInfo
    ? { [columns.findIndex(x => x.defaultSort)]: defaultSortInfo }
    : {};

  const [isComponentOpen, setIsComponentOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);
  const [selectedRowsIndexes, setSelectedRowsIndexes] = useState<number[]>([]);
  const [mode, setMode] = useState<M.Mode>(null);
  const [isConfirmPopupOpened, setIsConfirmPopupOpened] = useState<boolean>(false);
  const [columnsSorting, setColumnsSorting] = useState<Record<number, SortType>>(defaultSort);

  const [isOpenFilters, setIsOpenFilters] = useState<boolean>(false);
  const [columnsFilters, setColumnsFilters] = useState<Record<number, string>>({});

  const [customEditRow, setCustomEditRow] = useState<Row | null>(null);

  const extendedSourceRows: ExtendedRow<Row>[] = sourceRows.map((row, index) => ({ extendedRowId: index + 1, ...row }));

  const filteredRows = useMemo<ExtendedRow<Row>[]>(
    () =>
      extendedSourceRows.filter((row, rowIndex) => {
        const accessedColumns = columns.filter((column, index) => {
          const filterValue = columnsFilters[index];
          if (filterValue) {
            switch (column.dataKind) {
              case M.DataKind.BOOLEAN:
                switch (filterValue) {
                  case 'true':
                    return !!column.formatValue(row, rowIndex);
                  case 'false':
                    return !column.formatValue(row, rowIndex);
                }
                break;
              default:
                const value = String(column.formatValue(row, rowIndex)).toLowerCase();
                return value.includes(filterValue.toLowerCase());
            }
          }
          return true;
        });
        return accessedColumns.length === columns.length;
      }),
    [extendedSourceRows, columns, columnsFilters],
  );

  const sortedRows = useMemo<ExtendedRow<Row>[]>(() => {
    const columnsSortingEntries = Object.entries(columnsSorting);

    if (columnsSortingEntries.length) {
      const [columnIndex, sortType] = columnsSortingEntries[0];
      const column = columnIndex ? columns[Number(columnIndex)] : null;

      const isSortType = sortType !== 'none';
      if (column && isSortType) {
        return sortItems([...filteredRows] as ExtendedRow<Row>[], column, sortType);
      }
    }

    return filteredRows;
  }, [columns, columnsSorting, filteredRows]);

  const calculatedSelectedRowIndex = useMemo(() => {
    const isValidSelectedRowIndex = selectedRowIndex !== null ? sortedRows.length - 1 >= selectedRowIndex : false;
    if (isValidSelectedRowIndex) {
      const { extendedRowId: extendedRowIdToFound } = sortedRows[selectedRowIndex!];
      const index = extendedSourceRows.findIndex(({ extendedRowId }) => extendedRowIdToFound === extendedRowId);
      return index === -1 ? null : index;
    }

    return null;
  }, [extendedSourceRows, selectedRowIndex, sortedRows]);

  const selectedRow = useMemo(() => {
    if (calculatedSelectedRowIndex !== null) {
      return sourceRows[calculatedSelectedRowIndex];
    }

    return null;
  }, [calculatedSelectedRowIndex, sourceRows]);

  const handleSelectedRows = useCallback(
    (indexes: number[]) => {
      setSelectedRowsIndexes(indexes);
      onSelectedRowsCallback?.(indexes.map(i => sortedRows[i]));
    },
    [setSelectedRowsIndexes, onSelectedRowsCallback, sortedRows],
  );

  const closeComponent = useCallback(() => {
    setIsComponentOpen(false);
    setCustomEditRow(null);
    setMode(null);
    setSelectedRowIndex(null);
    if (onClosedHandler) onClosedHandler();
  }, [setCustomEditRow, onClosedHandler]);

  const openComponent = useCallback(() => {
    setIsComponentOpen(true);
    if (onOpenedHandler) onOpenedHandler();
  }, [onOpenedHandler]);

  const showNotifications = useCallback(
    (changedRowsAmount: number) => {
      if (!withMessages) {
        return;
      }
      if (mode === 'add') {
        if (changedRowsAmount === 0) {
          showNotification({ message: 'Не добавлен ни один элемент, все они уже есть в списке', theme: 'danger' });
        }
        if (changedRowsAmount === 1) {
          showNotification({ message: 'Выбранный элемент успешно добавлен', theme: 'success' });
        } else {
          showNotification({ message: 'Выбранные элементы успешно добавлены', theme: 'success' });
        }
      } else {
        showNotification({ message: 'Выбранный элемент успешно изменен', theme: 'success' });
      }
    },
    [withMessages, mode],
  );

  const onSubmitTable = useCallback(
    ({ selectedRows }: SubmitTable) => {
      const submit = (newRows: Row[]) => {
        const updatedRows = (() => {
          if (mode === 'add') {
            return onlyUniq([...sourceRows, ...newRows]);
          }

          const cloneRows = [...sourceRows];
          cloneRows.splice(calculatedSelectedRowIndex || 0, 1, ...newRows);
          return onlyUniq(cloneRows);
        })();

        const changedRowsAmount = updatedRows.length - sourceRows.length;

        showNotifications(changedRowsAmount);
        onChange(updatedRows);
        closeComponent();
      };

      if (specification.mode === 'relationTableModal') {
        const { modalTableRowConverter } = specification;
        const newRows = selectedRows.map(modalTableRowConverter);
        if (mode === 'add') {
          // filter rows by ID. if not empty. TODO: check if it is works
          const filteredNewRows = newRows.filter((i: any) => !i.id || !sourceRows.some((n: any) => n.id === i.id));
          if (specification.onPreSubmit) {
            specification.onPreSubmit(filteredNewRows, submit);
          } else {
            submit(filteredNewRows);
          }
        } else if (mode === 'edit') {
          if (specification.onPreEdit) {
            specification.onPreEdit(newRows, submit);
          } else {
            submit(newRows);
          }
        }
      }
      if (specification.mode === 'loadInstanceTableModal') {
        const { onStartLoad } = specification;
        const isValid =
          specification.validation?.checkIsValid(selectedRows, calculatedSelectedRowIndex, sourceRows, mode) ?? false;
        const isEmptyValidator = specification.validation === undefined;
        if (isValid || isEmptyValidator) {
          onStartLoad(selectedRows, submit);
        } else if (specification.validation?.onInvalidate) {
          specification.validation.onInvalidate(selectedRows, calculatedSelectedRowIndex, sourceRows, mode);
        }
      }
    },
    [specification, mode, sourceRows, calculatedSelectedRowIndex, showNotifications, onChange, closeComponent],
  );

  const isOpenCustomComponent = useMemo(() => specification.mode === 'customComponent' && isComponentOpen, [
    specification.mode,
    isComponentOpen,
  ]);

  const submitCustomComponent = useCallback(() => {
    if (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') {
      const isValid =
        specification.validation?.checkIsValid(customEditRow, calculatedSelectedRowIndex, sourceRows, mode) ?? false;
      const isEmptyValidator = specification.validation === undefined;
      if (isValid || isEmptyValidator) {
        if (customEditRow) {
          const updateRows = (row: Row) => {
            const updatedRows = (() => {
              if (mode === 'add') {
                return [...sourceRows, row];
              }

              const cloneRows = [...sourceRows];
              cloneRows.splice(calculatedSelectedRowIndex || 0, 1, row);
              return cloneRows;
            })();

            if (isUniq) {
              return onlyUniq(updatedRows);
            }
            return updatedRows;
          };

          if (specification.onPreSubmit) {
            specification.onPreSubmit(
              customEditRow,
              row => {
                onChange(updateRows(row));
                closeComponent();
              },
              calculatedSelectedRowIndex,
              mode,
            );
          } else {
            onChange(updateRows(customEditRow));
            closeComponent();
          }
        }
        if (withMessages) showNotifications(1);
      } else if (specification.validation?.onInvalidate) {
        specification.validation.onInvalidate(customEditRow, mode, calculatedSelectedRowIndex, sourceRows);
      }
    }
  }, [
    specification,
    customEditRow,
    calculatedSelectedRowIndex,
    sourceRows,
    mode,
    withMessages,
    showNotifications,
    isUniq,
    onChange,
    closeComponent,
  ]);

  const saveAndAddCustomComponent = useCallback(() => {
    if (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') {
      const isValid =
        specification.validation?.checkIsValid(customEditRow, calculatedSelectedRowIndex, sourceRows, mode) ?? false;
      const isEmptyValidator = specification.validation === undefined;
      if (isValid || isEmptyValidator) {
        if (customEditRow) {
          const updateRows = (row: Row) => {
            const updatedRows = (() => {
              if (mode === 'add') {
                return [...sourceRows, row];
              }

              const cloneRows = [...sourceRows];
              cloneRows.splice(calculatedSelectedRowIndex || 0, 1, row);
              return cloneRows;
            })();

            if (isUniq) {
              return onlyUniq(updatedRows);
            }
            return updatedRows;
          };

          if (specification.onPreSubmit) {
            specification.onPreSubmit(
              customEditRow,
              row => {
                onChange(updateRows(row));
                setCustomEditRow(null);
                setMode('add');
              },
              calculatedSelectedRowIndex,
              mode,
            );
          } else {
            onChange(updateRows(customEditRow));
            setCustomEditRow(null);
            setMode('add');
          }
        }
        if (withMessages) showNotifications(1);
      } else if (specification.validation?.onInvalidate) {
        specification.validation.onInvalidate(customEditRow, mode, calculatedSelectedRowIndex, sourceRows);
      }
    }
  }, [
    specification,
    customEditRow,
    sourceRows,
    mode,
    withMessages,
    showNotifications,
    isUniq,
    calculatedSelectedRowIndex,
    onChange,
  ]);

  const deleteRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null) {
      const continueDelete = () => {
        const cloneRows = [...sourceRows];
        cloneRows.splice(calculatedSelectedRowIndex || 0, 1);

        if (withMessages) {
          showNotification({ message: 'Выбранный элемент успешно удален', theme: 'success' });
        }
        setSelectedRowIndex(null);

        onChange(cloneRows);

        if (customEditRow !== null) {
          setCustomEditRow(null);
        }
        setSelectedRowsIndexes([]);
      };
      if (
        (specification.mode === 'customComponent' || specification.mode === 'customModalComponent') &&
        specification.onPreDelete
      ) {
        specification.onPreDelete(sourceRows[calculatedSelectedRowIndex], continueDelete, calculatedSelectedRowIndex);
      } else {
        continueDelete();
      }
    }
  }, [calculatedSelectedRowIndex, specification, sourceRows, withMessages, onChange, customEditRow]);

  const addRow = useCallback(
    (modeAdd: 'add' | 'dublicate') => {
      setMode('add');
      openComponent();
      if (modeAdd === 'dublicate') {
        setCustomEditRow(selectedRow);
      }
    },
    [openComponent, selectedRow],
  );

  const onView = useCallback(() => {
    setMode('view');
    if (specification.mode === 'relationTableModal' || specification.mode === 'loadInstanceTableModal') {
      openComponent();
    } else {
      setCustomEditRow(calculatedSelectedRowIndex !== null ? sourceRows[calculatedSelectedRowIndex] : null);
      openComponent();
    }
  }, [calculatedSelectedRowIndex, openComponent, sourceRows, specification.mode]);

  const editRow = useCallback(() => {
    setMode('edit');
    if (specification.mode === 'relationTableModal' || specification.mode === 'loadInstanceTableModal') {
      openComponent();
    } else {
      const continueEdit = () => {
        setCustomEditRow(selectedRow);
        openComponent();
      };
      if (specification.onPreEdit) {
        specification.onPreEdit(selectedRow, continueEdit);
      } else {
        continueEdit();
      }
    }
  }, [specification, openComponent, selectedRow]);

  const startDelete = useCallback(() => {
    if (isDeleteConfirmEnabled) {
      setIsConfirmPopupOpened(true);
    } else {
      deleteRow();
    }
  }, [deleteRow, setIsConfirmPopupOpened, isDeleteConfirmEnabled]);

  const confirmDelete = useCallback(() => {
    deleteRow();
    setIsConfirmPopupOpened(false);
  }, [deleteRow]);

  const resetDelete = useCallback(() => {
    setIsConfirmPopupOpened(false);
  }, []);

  const renderComponent = (specMode: M.ModeSpecification<Row>['mode']) => {
    type ModalSpecifications = M.LoadInstanceTableModalSpecification<Row, any> | M.RelationTableModalSpecification<Row, any>;

    const renderTableModal = (spec: ModalSpecifications) => (
      <RelationTableModal
        specification={{
          ...spec.modalTableSpecification,
          onSubmitTable,
          isCanSelectOnlyOneRow: mode === 'edit',
        }}
        modalTitle={spec.relationTableModalTitle}
        isOpen={isComponentOpen}
        onClose={closeComponent}
        helpText={
          isModalHintHidden
            ? ''
            : `При нажатой клавише CTRL выделите мышкой несколько записей и нажмите кнопку Выбрать на панели инструментов`
        }
      />
    );

    const mapRender: Record<M.ModeSpecification<Row>['mode'], (spec: any) => JSX.Element> = {
      loadInstanceTableModal: renderTableModal,
      relationTableModal: renderTableModal,
      customComponent: (spec: M.CustomComponentSpecification<Row>) => (
        <CollapsibleComponent
          closeCollapsible={closeComponent}
          customEditRow={customEditRow}
          isExpanded={isComponentOpen}
          isSaveAndAddCustomComponent={isSaveAndAddCustomComponent}
          saveAndAddCustomComponent={saveAndAddCustomComponent}
          submitCustomComponent={submitCustomComponent}
          renderComponent={spec.renderComponent}
          onChange={setCustomEditRow}
          selectedRowIndex={calculatedSelectedRowIndex}
          mode={mode}
          extraFieldsToolbarButtons={extraFieldsToolbarButtons}
          rows={sourceRows}
        />
      ),
      customModalComponent: (spec: M.CustomModalComponentSpecification<Row>) => (
        <ModalComponent
          isSaveAndAddCustomComponent={isSaveAndAddCustomComponent}
          saveAndAddCustomComponent={saveAndAddCustomComponent}
          isOpen={isComponentOpen}
          customEditRow={customEditRow}
          modalTitle={spec.modalTitle}
          onClose={closeComponent}
          renderComponent={spec.renderComponent}
          onChange={setCustomEditRow}
          submitCustomComponent={submitCustomComponent}
          selectedRowIndex={calculatedSelectedRowIndex}
          mode={mode}
          isCheck={spec.isCheckSubmitIcon}
          isToolbarHidden={spec.isToolbarHidden}
          isFullScreenModal={spec.isFullScreenModal}
          isFixedHeightModal={spec.isFixedHeightModal}
          extraFieldsToolbarButtons={extraFieldsToolbarButtons}
          rows={sourceRows}
        />
      ),
    };
    return mapRender[specMode](specification);
  };

  const moveUpRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null && calculatedSelectedRowIndex !== 0) {
      const nextPosition = calculatedSelectedRowIndex - 1;
      [sourceRows[nextPosition], sourceRows[calculatedSelectedRowIndex]] = [
        sourceRows[calculatedSelectedRowIndex],
        sourceRows[nextPosition],
      ];
      setSelectedRowIndex(nextPosition);
      onChange(sourceRows);
    }
  }, [calculatedSelectedRowIndex, sourceRows, onChange, setSelectedRowIndex]);

  const moveDownRow = useCallback(() => {
    if (calculatedSelectedRowIndex !== null && calculatedSelectedRowIndex !== sourceRows.length - 1) {
      const nextPosition = calculatedSelectedRowIndex + 1;
      [sourceRows[nextPosition], sourceRows[calculatedSelectedRowIndex]] = [
        sourceRows[calculatedSelectedRowIndex],
        sourceRows[nextPosition],
      ];
      setSelectedRowIndex(nextPosition);
      onChange(sourceRows);
    }
  }, [calculatedSelectedRowIndex, sourceRows, onChange, setSelectedRowIndex]);

  useEffect(() => {
    if (onSelectedRowIndexCallback) {
      onSelectedRowIndexCallback(calculatedSelectedRowIndex);
    }
  }, [onSelectedRowIndexCallback, calculatedSelectedRowIndex]);

  return (
    <div
      className={b({
        'custom-height': !!maxHeight,
        'currently-edited': isOpenCustomComponent,
      })}
      style={{ maxHeight }}
    >
      {!!header && (
        <div className={b('head')}>
          {header.title && (
            <span className={b('title')}>
              <span className={b('title-text')}>{header.title}</span>
              {header.tooltip && <Tooltip text={header.tooltip} />}
              {header.info && <Info text={header.info} />}
              {header.isRequired && <RequiredBadge />}
            </span>
          )}
          {header.filters && <span className={b('head-right')}>{header.filters}</span>}
        </div>
      )}

      {renderComponent(specification.mode)}

      <ToolbarButtons
        selectedRow={selectedRow}
        toolbar={toolbar}
        onAdd={addRow}
        onEdit={editRow}
        onDelete={startDelete}
        onView={onView}
        moveUpRow={moveUpRow}
        moveDownRow={moveDownRow}
        selectedRowIndex={calculatedSelectedRowIndex}
        selectedRowsIndexes={selectedRowsIndexes}
        isDisabled={isDisabled}
        isOpenCustomComponent={isOpenCustomComponent}
        rows={extendedSourceRows}
        isVisibleFilters={isVisibleFilters}
        isOpenFilters={isOpenFilters}
        setIsOpenFilters={setIsOpenFilters}
        setColumnsFilters={setColumnsFilters}
      />

      <ListEditTable
        isStickyHeader={!!maxHeight}
        columnsFilters={columnsFilters}
        columnsSorting={columnsSorting}
        setColumnsSorting={setColumnsSorting}
        rows={sortedRows}
        defaultRowsCount={defaultRowsCount}
        withoutHead={withoutHead}
        columns={columns}
        {...(isSelectableRows
          ? {
              selectedRowIndex: selectedRowIndex,
              selectRow: setSelectedRowIndex,
              selectedRowsIndexes: selectedRowsIndexes,
              onSelectRows: handleSelectedRows,
            }
          : {
              selectedRowIndex: -1,
              selectRow: () => {},
              selectedRowsIndexes: [],
              onSelectRows: () => {},
            })}
        getRowTheme={getRowTheme}
        columnIndexesForSumTotal={columnIndexesForSumTotal}
        columnIndexesForIntegerTotal={columnIndexesForIntegerTotal}
        isSortEnabled={isSortEnabled}
        onRowDoubleClick={onRowDoubleClick}
        isOpenFilters={isOpenFilters}
        setColumnsFilters={setColumnsFilters}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmPopupOpened}
        onClose={resetDelete}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDelete,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: resetDelete,
          },
        ]}
        size="small"
      >
        <>Удалить выбранный элемент?</>
      </Modal>
    </div>
  );
}

export { ListEdit };
// export const ListEdit = React.memo(Component) as typeof Component;

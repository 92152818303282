import { Entry } from 'types/models/Table';

export function isTSUEdition(row: Entry) {
  return row['id:typeEdition'] === 'LOCAL' || row['id:EditionType'] === 'LOCAL';
}

export function isCompilation(row: Entry) {
  const compilationTypes = ['COMPILATION_SCIENTIFIC_WORK', 'COMPILATION_CONFERENCE', 'COMPILATION_OTHER'];
  return compilationTypes.some((type: string) => row['id:PublicationType'] === type);
}

import { MobileKindConsumption } from 'types/models/KindConsumption';

export function getMockMobileKindConsumption(item?: Partial<MobileKindConsumption>): MobileKindConsumption {
  return {
    id: null,
    name: '',
    code: '',
    description: '',
    types: [],
    kindConsumption: null,
    ...item,
  };
}

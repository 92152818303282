import React, { useCallback, useMemo, useState } from 'react';

import { ButtonProps, FormComponent, ListEditTable, SectionTitle, Toolbar } from 'components';

import { Performer } from 'types/models/Project';
import { ModalProjectScientistsTable } from './ModalProjectScientistsTable';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  title?: string;
  performers: Performer[];
  chosenPerformers: Performer[];
  setChosenPerformers(performers: Performer[]): void;
  disabled: boolean;
};

type Mode = 'add' | 'edit';

export function ChooseProjectScientists(props: Props) {
  const { chosenPerformers, setChosenPerformers, performers, title, disabled } = props;

  const collectivePerformers = performers
    .filter(performer => chosenPerformers.find(x => x.id === performer.id || (x.person?.id === performer.person?.id ?? '')))
    .sort((x, y) => (x.person?.fullName || x.fio || '').localeCompare(y.person?.fullName || y.fio || ''));

  const setPerformers = useCallback(
    (сurrentPerformers: Performer[]) => {
      setChosenPerformers(сurrentPerformers);
    },
    [setChosenPerformers],
  );

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [mode, setMode] = useState<Mode | null>(null);
  const [selectedCollectivePerformerIndex, setSelectedCollectivePerformerIndex] = useState<number | null>(null);

  const closeModal = useCallback(() => {
    setIsOpenModal(false);
    setMode(null);
  }, []);

  const submitModal = useCallback(
    (newPerformers: Performer[]) => {
      if (mode === 'add') {
        setPerformers(onlyUniq([...collectivePerformers, ...newPerformers]));
      } else {
        setPerformers(
          onlyUniq(
            (() => {
              collectivePerformers.splice(selectedCollectivePerformerIndex || collectivePerformers.length, 1, ...newPerformers);
              return collectivePerformers;
            })(),
          ),
        );
      }
    },
    [collectivePerformers, setPerformers, mode, selectedCollectivePerformerIndex],
  );

  const removePerformer = useCallback(() => {
    if (selectedCollectivePerformerIndex !== null) {
      collectivePerformers.splice(selectedCollectivePerformerIndex, 1);

      setPerformers([...collectivePerformers]);

      if (!collectivePerformers.length) {
        setSelectedCollectivePerformerIndex(null);
      }
    }
  }, [selectedCollectivePerformerIndex, collectivePerformers, setPerformers]);

  const onlyUniq = (list: Performer[]) => {
    return list.filter((x, index) => index === list.findIndex(i => x.person?.id === i.person?.id));
  };

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => {
          setIsOpenModal(true);
          setMode('add');
        },
        isDisabled: disabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: removePerformer,
        isDisabled: disabled || selectedCollectivePerformerIndex === null,
      },
    ],
    [disabled, removePerformer, selectedCollectivePerformerIndex],
  );

  return (
    <FormComponent.Line>
      <FormComponent.Field>
        {!!title && <SectionTitle title={title} isTableHeader />}

        <Toolbar buttons={buttons} mode="list" />

        <ListEditTable
          rows={onlyUniq(collectivePerformers)}
          defaultRowsCount={6}
          columns={[
            { label: 'ФИО', formatValue: x => x.person?.fullName || x.fio || '' },
            { label: 'Контакты', formatValue: e => getPersonContacts(e.person?.contacts) },
          ]}
          selectRow={setSelectedCollectivePerformerIndex}
          selectedRowIndex={selectedCollectivePerformerIndex}
        />

        <ModalProjectScientistsTable
          isOpen={isOpenModal}
          onClose={closeModal}
          onSubmit={submitModal}
          performers={onlyUniq(performers.filter(performer => !collectivePerformers.map(i => i.id).includes(performer.id)))}
        />
      </FormComponent.Field>
    </FormComponent.Line>
  );
}

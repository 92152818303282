export class SecurityDocument {
  static MODULE_PATENT: string = 'Patents';

  static MODULE_EVIDENCE: string = 'Evidences';

  static MODULE_KNOW_HOW: string = 'KnowHows';

  static FOREIGN_SECURITY_DOCUMENT: string = 'ForeignSecurityDocument';

  static NON_REGISTERED: string = 'NonRegistered';

  static SECURITY_DOCUMENTS: string = 'SecurityDocuments';
}

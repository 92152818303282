import React from 'react';
import { block } from 'bem-cn';

import { Info, RequiredBadge, Tooltip } from 'components';

import './style.scss';

const b = block('form-component-field');

type LabelSize = 'fit' | 'small';
type FieldSize = 'fit' | 'small';
type ContentSize = 'fit' | 'small';
type LabelStyle = 'bold' | 'thin';
type Align = 'center' | 'right';

type Props = {
  /** @property {string} align - (optional) Field align switcher (left by default) */
  align?: Align;
  /** @property {string} label - (optional) Field label value (if undefined no renderred) */
  label?: string;
  /** @property {LabelStyle} labelStyle - (optional) Field label style switcher */
  labelStyle?: LabelStyle;
  /** @property {LabelSize} labelSize - (optional) Field label size switcher */
  labelSize?: LabelSize;
  /** @property {FieldSize} fieldSize - (optional) Field full size switcher */
  fieldSize?: FieldSize;
  /** @property {ContentSize} contentSize - (optional) Field content size switcher */
  contentSize?: ContentSize;
  /** @property {string} isRequired - (optional) Is required badge on label */
  isRequired?: boolean;
  /** @property {string} tooltip - (optional) Tooltip on label */
  tooltip?: string;
  /** @property {JSX.Element} info - (optional) Show info with html */
  info?: JSX.Element;
  /** @property {number} labelWidth - (optional) Label width by px */
  labelWidth?: number;
  /** @property {boolean} hasLeftAlign - (optional) Label text by left side */
  hasLeftAlign?: boolean;
  /** @property {boolean} isHidden - (optional) Is hidden field */
  isHidden?: boolean;
  /** @property {React.CSSProperties} style - (optional) Styles to component */
  style?: React.CSSProperties;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMix?: string | string[];
  /** @property {React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[]} children
   * - (optional) React inner JSX component */
  children?: React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[];
};

/**
 * Field from parts -> label + content
 *
 * @param {string} align - (optional) Field align switcher (left by default)
 * @param {string} label - (optional) Field label value (if undefined no renderred)
 * @param {LabelStyle} labelStyle - (optional) Field label style switcher
 * @param {LabelSize} labelSize - (optional) Field label size switcher
 * @param {FieldSize} fieldSize - (optional) Field full size switcher
 * @param {ContentSize} contentSize - (optional) Field content size switcher
 * @param {string} isRequired - (optional) Is required badge on label
 * @param {string} tooltip - (optional) Tooltip on label
 * @param {JSX.Element} info - (optional) Show info with html
 * @param {number} labelWidth - (optional) Label width by px
 * @param {boolean} hasLeftAlign - (optional) Label text by left side
 * @param {boolean} isHidden - (optional) Is hidden field
 * @param {React.CSSProperties} style - (optional) Styles to component
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @param {React.ReactNode | JSX.Element | string | false | (React.ReactNode | JSX.Element | string | false)[]} children
 *  - (optional) React inner JSX component
 * @returns JSX
 */
export const Field = React.memo(
  ({
    align,
    label,
    labelSize,
    labelStyle,
    fieldSize,
    contentSize,
    isRequired,
    tooltip,
    info,
    labelWidth,
    hasLeftAlign = false,
    isHidden,
    style,
    classMix,
    children,
  }: Props) => {
    return (
      <>
        {!isHidden && (
          <div
            className={b({ small: fieldSize === 'small', center: align === 'center', right: align === 'right' })}
            style={style}
          >
            {label !== undefined && (
              <div
                className={b('label', {
                  fit: labelSize === 'fit',
                  small: labelSize === 'small',
                  thin: labelStyle === 'thin',
                  bold: labelStyle === 'bold',
                  left: hasLeftAlign,
                })}
                style={{ width: labelWidth }}
              >
                <span dangerouslySetInnerHTML={{ __html: label }}></span>
                {tooltip && (
                  <span className={b('label-tooltip')}>
                    <Tooltip text={tooltip} />
                  </span>
                )}
                {info && (
                  <span className={b('label-info')}>
                    <Info text={info} />
                  </span>
                )}
                {isRequired && <RequiredBadge />}
              </div>
            )}

            <div
              className={b('content', {
                'no-labeled': !label || labelSize === 'fit',
                fit: contentSize === 'fit',
                small: contentSize === 'small',
              }).mix(classMix)}
              style={style}
            >
              {children}
            </div>
          </div>
        )}
      </>
    );
  },
);

import React from 'react';

import {
  FormComponent,
  Radio,
  ReferenceItem,
  Select,
  SelectMode,
  TextInput,
  TextDateTime,
  TextInputMode,
  UploadImage,
} from 'components';

import { Person, UserPermission } from 'types/models';
import { PersonContactList } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  setPerson: React.Dispatch<React.SetStateAction<Person.ScientistPerson | null>>;
};
function Component(props: Props) {
  const { person, setPerson } = props;
  if (!person) {
    return null;
  }

  return (
    <FormComponent.Wrapper>
      <FormComponent.Description mode="warning">Обязательно добавьте сведения о работе или обучении</FormComponent.Description>

      <FormComponent.ColumnWrapper>
        <FormComponent.Column style={{ width: 'calc(100% - 250px)' }} hasNoWrap>
          <FormComponent.Line>
            <FormComponent.Field isRequired label="Фамилия">
              <TextInput
                value={person.scientist?.lastName ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, lastName: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field isRequired label="Имя">
              <TextInput
                value={person.scientist?.firstName ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, firstName: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Отчество">
              <TextInput
                value={person.scientist?.patronymic ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, patronymic: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Фамилия на англ:">
              <TextInput
                value={person.scientist?.englishLastName ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, englishLastName: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Имя на англ:">
              <TextInput
                value={person.scientist?.englishFirstName ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, englishFirstName: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Отчество на англ:">
              <TextInput
                value={person.scientist?.englishPatronymic ?? ''}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, englishPatronymic: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Дата рождения" isRequired>
              <TextDateTime
                value={person.scientist?.dateBirth}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, dateBirth: e } }))}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Пол" labelSize="fit">
              <Radio
                value={person.scientist?.gender}
                list={[
                  { label: 'М', value: 'MALE' },
                  { label: 'Ж', value: 'FEMALE' },
                ]}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, gender: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Гражданство" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={person.scientist?.citizenship}
                onChange={(e: ReferenceItem | null) =>
                  setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, citizenship: e } }))
                }
                settings={{ name: 'RefCountry', title: 'Справочник "Страны"' }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="СНИЛС:">
              <TextInput
                value={person.scientist?.snils}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, snils: e } }))}
                mode={TextInputMode.SNILS}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="ИНН:">
              <TextInput
                value={person.scientist?.inn}
                onChange={e => setPerson(prev => ({ ...prev!, scientist: { ...prev!.scientist!, inn: e } }))}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <PersonContactList contacts={person.contacts} setContacts={e => setPerson(prev => ({ ...prev!, contacts: e }))} />
          </FormComponent.Line>
        </FormComponent.Column>
        <FormComponent.Column style={{ width: '250px', height: '270px' }} hasNoWrap>
          <UploadImage text="Фото профиля" viewMode />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

export const UpperForm = React.memo(Component);

import React, { useCallback, useMemo } from 'react';

import { ButtonProps, FormComponent, ListEdit, TextArea, TextAreaMode } from 'components';

import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { usePrivatePageContext } from 'App/PrivatePage/context';
import { ParticipationProject, ParticipationTypeCode } from 'types/models/Participation';
import { getAcceptInfo } from 'utils/Helpers/getAcceptInfo';
import { Color } from 'constants/colors';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  workMode: Table.WorkMode;
  relatedTableState: Table.State | undefined;
  projects: ParticipationProject[];
  setProjects: (rows: ParticipationProject[]) => void;
  participationTypeCode: ParticipationTypeCode;
  isSingle?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  selectTitle?: string | null;
  tooltipText?: string | undefined;
  isMaximized?: boolean;
  tooltipProjectText?: string;
};

function Projects({
  participationTypeCode,
  workMode,
  relatedTableState,
  projects,
  setProjects,
  isSingle = false,
  isDisabled,
  isRequired = false,
  selectTitle,
  tooltipText: tooltip,
  isMaximized,
  tooltipProjectText,
}: Props) {
  const { currentPerson } = useAppDataContext();
  const { isProfile } = usePrivatePageContext();

  const toolbarEnabled: boolean = useMemo(() => workMode !== 'viewMode', [workMode]);

  const specifications = GetFullScienceProjectList({
    templatesTableDependencies: relatedTableState && {
      relatedTableAPIID: 'GetMagazineList',
      relatedTableState,
    },
  });

  const project = useMemo<ParticipationProject | null>(() => projects[0] || null, [projects]);

  const modalTableRowConverter = useCallback<(row: Table.Entry) => ParticipationProject>(row => {
    return {
      id: null,
      type: null,
      project: { id: row.id, value: row.completeLabel || [row.Number, row.Name].filter(Boolean).join(', ') },
      accepted: '',
      acceptedBy: null,
    };
  }, []);

  const onChange = useCallback(
    (row: ParticipationProject | null) => {
      if (row) {
        setProjects([row]);
      }
    },
    [setProjects],
  );

  const setProjectAccept = useCallback(
    (projectId: string, isAccept: boolean) => {
      const projectIndex = projects.findIndex(projectItem => projectItem.project?.id === projectId);
      projects[projectIndex] = {
        ...projects[projectIndex],
        accepted: isAccept ? new Date().toLocaleString().replace(',', '') : '',
        acceptedBy: isAccept && currentPerson?.id ? { id: currentPerson.id, fullName: currentPerson.fullName || '' } : null,
      };
      setProjects([...projects]);
    },
    [currentPerson?.fullName, currentPerson?.id, projects, setProjects],
  );

  const acceptButtons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'like', color: Color.success },
        title: 'Принять к отчету',
        onClick: () => {
          if (projects.length && !project?.accepted) {
            setProjectAccept(project?.project?.id || '', true);
          }
        },
        permission: { name: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits] },
        isHidden: isProfile,
        isDisabled: !(projects.length && !project?.accepted) || workMode === 'viewMode',
      },
      {
        icon: { type: 'dislike', color: Color.danger },
        title: 'Отменить принятие к отчету',
        onClick: () => {
          if (projects.length && !!project?.accepted) {
            setProjectAccept(project?.project?.id || '', false);
          }
        },
        permission: { name: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits] },
        isHidden: isProfile,
        isDisabled: !(projects.length && !!project?.accepted) || workMode === 'viewMode',
      },
    ],
    [participationTypeCode, isProfile, projects.length, project?.accepted, project?.project?.id, workMode, setProjectAccept],
  );

  const getIsEditProjectButtonDisabled = useCallback((row: ParticipationProject | null) => !!row?.accepted, []);
  const getIsDeleteProjectButtonDisabled = useCallback((row: ParticipationProject | null) => !!row?.accepted, []);

  return (
    <>
      {isSingle ? (
        <>
          <FormComponent.Field tooltip={tooltip} label={selectTitle ?? 'Сведение о проекте'} isRequired={isRequired}>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Проекты',
                permissionName: Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits],
                externalButtons: isProfile ? [] : acceptButtons,
                visibleStatus: !!project?.accepted ? `Принят к отчету, ${getAcceptInfo(project)}` : '',
                isClearable: !isRequired,
                table: {
                  specification: specifications,
                  onSelect: (row: Table.Entry | null) => (row ? onChange(modalTableRowConverter(row)) : setProjects([])),
                },
              }}
              value={project?.project?.value}
              isDisabled={(isDisabled !== undefined ? isDisabled : !toolbarEnabled) || !!project?.accepted}
            />
          </FormComponent.Field>
        </>
      ) : (
        <ListEdit<object, ParticipationProject>
          header={{ title: 'Проекты', tooltip }}
          rows={projects}
          onChange={setProjects}
          toolbar={[
            { key: 'add', permission: Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits] },
            {
              key: 'edit',
              permission: Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits],
              isDisabled: getIsEditProjectButtonDisabled,
            },
            {
              key: 'delete',
              permission: Permits[`${participationTypeCode}_PROJECTS_EDIT` as keyof Permits],
              isDisabled: getIsDeleteProjectButtonDisabled,
            },
            {
              key: 'accept',
              onClick: row => setProjectAccept(row?.project?.id || '', true),
              permission: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
              isHidden: isProfile,
            },
            {
              key: 'decline',
              onClick: row => setProjectAccept(row?.project?.id || '', false),
              permission: Permits[`${participationTypeCode}_PROJECTS_ACCEPTING` as keyof Permits],
              isHidden: isProfile,
            },
          ]}
          columns={[
            {
              label: 'Название проекта',
              formatValue: row => row.project?.value || '<em>Не указано</em>',
              styles: { width: '75%' },
            },
            {
              label: 'Принят к отчету',
              tooltip: tooltipProjectText,
              formatValue: getAcceptInfo,
              styles: { width: '25%' },
            },
          ]}
          maxHeight={isMaximized ? 'none' : undefined}
          specification={{
            mode: 'relationTableModal',
            modalTableRowConverter,
            relationTableModalTitle: 'Проекты',
            modalTableSpecification: specifications,
          }}
          isModalHintHidden
          isDisabled={isDisabled !== undefined ? isDisabled : !toolbarEnabled}
        />
      )}
    </>
  );
}

export { Projects };

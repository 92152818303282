export function PartnerPublicationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит список публикаций, в которых авторы аффилированы с организацией Источник информации — раздел{' '}
        <strong>Сведения об авторах</strong> на форме публикации (модуль <strong>Публикации</strong>).
      </p>

      <div className="warning">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

import React, { memo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit, Button, ButtonMode, FormComponent } from 'components';

import { GetReferenceElementList } from 'features/Table/specifications';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { Department } from 'types/models/Participation';
import { formatAuthor } from '../MembersList/helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  id: string;
  type: string;
  afterSubmit: (departments: Department[]) => void;
  onClose: () => void;
  participantsTitle: string;
  hideToolbarButtons: boolean;
  hideActionButtons: boolean;
};

const EditParticipationDepartmentsComponent = ({
  type,
  id,
  onClose,
  afterSubmit,
  participantsTitle,
  hideToolbarButtons,
  hideActionButtons,
}: Props) => {
  const {
    isLoading,
    departmentColumns,
    participationData,
    participationDepartments,
    setParticipationDepartments,
    handleSaveParticipation,
    extraToolbarButtons,
  } = useController({
    id,
    onClose,
    afterSubmit,
  });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const eventTypeCodeLabel: string = (() => {
    if (type === ParticipationTypes.PARTICIPATION_CONFERENCE.code) {
      return EventTypes.EVENT_CONFERENCE.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_EXPOSITION.code) {
      return EventTypes.EVENT_EXPOSITION.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_CONTEST.code) {
      return EventTypes.EVENT_CONTEST.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_CONCERT.code) {
      return EventTypes.EVENT_CONCERT.label;
    }
    return '';
  })();

  return (
    <FormComponent.Template>
      {!hideToolbarButtons && <ControlPanel handleSave={handleSaveParticipation} />}

      <FormComponent.Wrapper>
        <FormComponent.Field>
          <FormComponent.Text>
            <strong>Мероприятие:</strong> {eventTypeCodeLabel}:{' '}
            {getStatusRecordMessage(
              getEventFullName(participationData?.event),
              participationData?.event?.id,
              participationData?.event?.status?.label,
            )}
          </FormComponent.Text>
        </FormComponent.Field>
        {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
          (participationData?.reportTheme || participationData?.publication?.fields.name) && (
            <FormComponent.Field>
              <FormComponent.Text>
                <strong>Доклад:</strong> {participationData?.reportTheme || participationData?.publication?.fields.name || '-'}
              </FormComponent.Text>
            </FormComponent.Field>
          )}
        <FormComponent.Field>
          <FormComponent.Text>
            <strong>{participantsTitle}</strong>{' '}
            {participationData?.members.map((member, index) => (
              <div key={index} dangerouslySetInnerHTML={{ __html: formatAuthor(member) }} />
            ))}
          </FormComponent.Text>
        </FormComponent.Field>

        <ListEdit
          header={{ title: 'Подразделения к которым относится участие', isRequired: true }}
          rows={participationDepartments}
          onChange={setParticipationDepartments}
          toolbar={['add', 'edit', 'delete', ...extraToolbarButtons]}
          columns={departmentColumns}
          isDisabled={isLoading}
          withMessages
          isDeleteConfirmEnabled
          specification={{
            mode: 'loadInstanceTableModal',
            onStartLoad: (tableRows, onSuccess) => {
              loadReferenceAPI.callAPI(
                { filters: [], referenceName: 'RefDepartment', childIds: tableRows.map(x => x.id) },
                {
                  onSuccessfullCall: ({ data }) =>
                    onSuccess(
                      data.map(e => ({
                        name: (e.customFields?.name as string) || '',
                        id: e.id,
                        code: (e.customFields?.code as string) || '',
                      })),
                    ),
                },
              );
            },
            relationTableModalTitle: 'Справочник "Подразделения"',
            modalTableSpecification: GetReferenceElementList({
              requestData: { filters: [{ key: 'active', values: ['true'] }], name: 'RefDepartment' },
            }),
          }}
        />

        {!hideActionButtons && (
          <FormComponent.Actions>
            <Button mode={ButtonMode.PRIMARY} text="Сохранить связь с подразделениями" onClick={handleSaveParticipation} />
            <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
          </FormComponent.Actions>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
};

export const Component = memo(EditParticipationDepartmentsComponent);

import React from 'react';

import { FormComponent } from 'components';

import { DataGrid } from 'features/Table';
import { GetEventFeedlineList } from 'features/Table/specifications/GetEventFeedlineList';
import { Table as T } from 'types/models';

type Props = {
  relatedTableState: T.State | undefined;
  workMode: T.WorkMode;
  publicationId: string | undefined;
};

function Messages({ relatedTableState, workMode, publicationId }: Props) {
  return (
    <>
      <FormComponent.Description mode="warning">
        Здесь сохраняются все системные и пользовательские сообщения, которые были добавлены в процессе работы с записью о данном
        мероприятии
      </FormComponent.Description>

      {relatedTableState && (
        <DataGrid
          specification={GetEventFeedlineList(
            {
              relatedTableAPIID: 'GetMagazineList',
              relatedTableState,
              relatedRecordId: publicationId || undefined,
            },
            workMode,
          )}
        />
      )}
    </>
  );
}

export { Messages };

import { formatStringToDate } from 'utils/Helpers';

export function isInPeriod(startDate: string | null, endDate: string | null): boolean {
  const current = new Date();
  const start = startDate ? formatStringToDate(startDate) : null;
  const end = endDate ? formatStringToDate(endDate) : null;
  if (start && !end) return start <= current;
  if (start && end) return start <= current && current <= end;
  if (!start && end) return current >= end;
  return false;
}

import { Icon, Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ReceivingDocumentGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <p>
          Таблица «Поступление средств на шифры проектов» содержит консолидированный список Приходных документов бухгалтерского
          учёта и дополнительные столбцы с данными из шифров и проектов для анализа данных, отчётности по объёмам выполненных
          НИОКР, в которой необходимо указать сведения о документах бухучёта.
        </p>
        <p>
          Фильтр <strong>Шифры без календарных планов</strong> – отображает список шифров проектов, у которых календарный план.
        </p>
        <p>
          Фильтр <strong>Документы для аудита</strong> – список приходных документов, у которых дата документа не соответствует
          срокам проекта (т.е. относительно даты документа проект - завершён).
        </p>
        <p>
          Фильтр <strong>Год</strong> – отображает список документов с датой, соответствующей указанному году.
        </p>
        <p>Основные столбцы таблицы содержат данные шифров.</p>
        <p>
          Дополнительные столбцы — данные шифра проекта и проекта, названия столбцов соответствуют названиям полей на форме
          проекта.
        </p>
        <h4>Особенности вывода информации в столбцах таблицы:</h4>
        <table>
          <thead>
            <tr>
              <th>Название столбца</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Дата документа</td>
              <td>Дата платёжного поручения.</td>
            </tr>
            <tr>
              <td>Номер документа</td>
              <td>Номер платёжного поручения.</td>
            </tr>
            <tr>
              <td>Сумма документа</td>
              <td>Сумма (руб.) платёжного поручения</td>
            </tr>
            <tr>
              <td>НДС</td>
              <td>
                Атрибут источника финансирования Шифра проекта, связанного с платёжным поручением. Заполняется в разделе в разделе
                «Финансирование» на форме проекта.
              </td>
            </tr>
            <tr>
              <td>Организация</td>
              <td>
                Название контрагента (юридическое или физическое лицо) в платёжном поручении. Список контрагентов из
                информационной системы бухгалтерского учёта храниться <strong>в справочнике Заказчики</strong>.
              </td>
            </tr>
            <tr>
              <td>Адрес</td>
              <td>
                Адрес контрагента в платёжном поручении. Значение выбирается из{' '}
                <strong>
                  справочника Заказчики. Если это необходимо, то адрес можно отредактировать/добавить именно в этом справочнике.
                </strong>
              </td>
            </tr>
            <tr>
              <td>Шифр</td>
              <td>Номер шифра проекта, с которым связано платёжное поручение.</td>
            </tr>
            <tr>
              <td>Источник финансирования</td>
              <td>Источник финансирования шифра проекта, с которым связано платёжное поручение.</td>
            </tr>
            <tr>
              <td>Тип проекта</td>
              <td>Характеристика проекта, шифр которого связан с платёжным поручением.</td>
            </tr>
            <tr>
              <td>Тема проекта</td>
              <td>Название темы проекта - из одноимённого поля проекта.</td>
            </tr>
            <tr>
              <td>Номер проекта</td>
              <td>Номер проекта — из одноимённого поля проекта.</td>
            </tr>
            <tr>
              <td>Код номера проекта</td>
              <td>
                Дополнительный код для номера проекта, используется для группировки проектов по аналитическому признаку. Поле не
                обязательное.
              </td>
            </tr>
            <tr>
              <td>Дата начала</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Дата окончания</td>
              <td>Дата начала проекта.</td>
            </tr>
            <tr>
              <td>Руководитель</td>
              <td>ФИО руководителя проекта.</td>
            </tr>
            <tr>
              <td>Характер проекта</td>
              <td>Характеристика проекта - из одноимённого поля проекта на вкладке.</td>
            </tr>
            <tr>
              <td>Программа</td>
              <td>
                Краткое название программы — из одноимённого поля проекта на вкладке <strong>О проекте</strong>. Не обязательное
                поле.
              </td>
            </tr>
            <tr>
              <td>Наличие сметы</td>
              <td>Признак наличия сметы.</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

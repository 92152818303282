import React from 'react';

import { Button, ButtonMode, FormComponent, SectionTitle } from 'components';

import { useController } from './controller';

type Props = {
  onClose: () => void;
};

function RecognitionForm({ onClose }: Props) {
  const { recognitionInfo, handleConfirmButtonClick, isApproved } = useController({ onClose });

  return (
    <>
      <FormComponent.Wrapper>
        <SectionTitle title={recognitionInfo?.person?.fullName || 'ФИО'} />
        <FormComponent.Line>
          <FormComponent.Field label="Награда и дата получения: ">
            <span>
              {[recognitionInfo?.award.label, recognitionInfo?.dateGet ? ` (${recognitionInfo.dateGet})` : '']
                .filter(Boolean)
                .join('') || <em>Не указана</em>}
            </span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Категория награды: ">
            <span>{recognitionInfo?.awardCategory.label || <em>Не указана</em>}</span>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Actions>
          <Button
            mode={ButtonMode.PRIMARY}
            text={isApproved ? 'Перевести в статус "Добавлена"' : 'Утвердить'}
            onClick={handleConfirmButtonClick}
          />
          <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>
    </>
  );
}

export const Component = React.memo(RecognitionForm);

import { Table as T } from 'types/models';

export type DetailProps = {
  colleagueScientistId: string;
  scientistId: string;
};
export function GetPersonalColleagueSecurityDocumentList({
  colleagueScientistId,
  scientistId,
}: DetailProps): T.Specification<{}> {
  return {
    apiID: 'GetPersonalColleagueSecurityDocumentList',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData: { colleagueScientistId, scientistId },
    isFiltersHidden: true,
  };
}

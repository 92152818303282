import { Table as T } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { PublicationType } from 'types/models/CitationSystemIndex';
import { Prop } from '.';

export type State = {
  citationSystems: CitationSystem[];
  publicationId: string | null;
  publicationType: PublicationType;
  workMode: T.WorkMode;
};

export type Mode = 'add' | 'edit' | 'view';

export function makeUseCustomController({ citationSystems, publicationId, publicationType, workMode }: Prop) {
  return function useCustomController(): State {
    return {
      citationSystems,
      publicationId,
      publicationType: publicationType as PublicationType,
      workMode,
    };
  };
}

import React, { memo } from 'react';
import { setup } from 'bem-cn';

import { FormComponent, Select } from 'components';

import { Item } from 'types/models/common';
import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const block = setup({ mod: '--' });

const enumerations = block('enumeration');

const Enumeration = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { canBuild, currentValue, setNextValueKey, selectTitle, enumParameter, values } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <div className={enumerations()}>
          <FormComponent.Line>
            <FormComponent.Field label={selectTitle} isRequired={!!enumParameter?.required}>
              <Select
                value={currentValue}
                options={values.map(item => item as Item)}
                onChange={(option: Item) => setNextValueKey(option.value)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
      )}
    </>
  );
};

const Component = memo(Enumeration);

export { Component as Enumeration };

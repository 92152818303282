import { Table } from 'types/models';
import { SubmitTable } from 'features/Table/streams';
import { Filters } from './filters';

type Props = {
  onSubmitTable?(state: SubmitTable): void;
};

export function GetSalarySourceCodeList({ onSubmitTable }: Props): Table.Specification {
  return {
    apiID: 'GetSalarySourceCodeList',
    header: {
      firstLevel: {
        title: 'Список шифров проектов и шифров ЦФ',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    onSubmitTable,
  };
}

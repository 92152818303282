import * as XML from 'xml-js';

import { Settings } from 'types/models/common';
import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';
import { makeParametersXMLElement, makeTableRequestXMLConverter } from '../commonConverters';
import { convertSavePaymentResponse, convertServerPaymentFeedline } from '../payments/converters';
import { convertEmployeePayment } from './converters';
import { convertSaveEmployeePaymentToRequest } from './requestConverters';

import * as T from './types';

export const tableFundsConfigurations = {
  GetFunds: makeBackendTableAPIConfiguration(
    'GetFunds',
    makeTableRequestXMLConverter('GetFunds', ({ panelState, requestData }: T.GetFundsRequest) =>
      makeParametersXMLElement([
        { name: 'year', value: panelState.year },
        { name: 'quarterFrom', value: panelState.quarterFrom },
        { name: 'quarterTo', value: panelState.quarterTo },
        { name: 'onlyByDepartment', value: requestData?.onlyByDepartment ?? 'false' },
      ]),
    ),
  ),
  GetRequestFundList: makeBackendTableAPIConfiguration(
    'GetRequestFundList',
    makeTableRequestXMLConverter('GetRequestFundList', ({ panelState, requestData }: T.GetFundsListRequest) =>
      makeParametersXMLElement(
        [
          { name: 'year', value: panelState.year ?? new Date().getFullYear().toString() },
          { name: 'onlyByDepartment', value: requestData?.onlyByDepartment ?? 'false' },
        ].concat(
          panelState.month
            ? [
                { name: 'fundType', value: panelState.fundType },
                { name: 'fundFaculty', value: panelState.fundFaculty },
                { name: 'month', value: panelState.month },
                { name: 'quarter', value: panelState.quarter },
              ]
            : [],
        ),
      ),
    ),
  ),
  GetEmployeePaymentRequestFeedlineList: makeBackendTableAPIConfiguration(
    'GetEmployeePaymentRequestFeedlineList',
    makeTableRequestXMLConverter(
      'GetEmployeePaymentRequestFeedlineList',
      ({ requestData }: T.GetEmployeePaymentFeedlineListRequest) =>
        makeParametersXMLElement([{ name: 'requestId', value: requestData!.employeePaymentId }]),
    ),
  ),
};

export const configurations = {
  GetEmployeePaymentRequest: makeBackendAPIConfiguration({
    id: 'GetEmployeePaymentRequest',
    endpoint: '/msa/service/commands/GetEmployeePaymentRequest',
    convertInputToXMLElement: ({ id }: T.GetEmployeePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'GetEmployeePaymentRequest' },
        EmployeePaymentRequest: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertEmployeePayment(response.Response.EmployeePaymentRequest, settings),
  }),
  SaveEmployeePaymentRequest: makeBackendAPIConfiguration({
    id: 'SaveEmployeePaymentRequest',
    endpoint: '/msa/service/commands/SaveEmployeePaymentRequest',
    convertInputToXMLElement: (args: T.SaveEmployeePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveEmployeePaymentRequest' },
        ...convertSaveEmployeePaymentToRequest(args),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertSavePaymentResponse(response.Response),
  }),
  DeleteEmployeePaymentRequest: makeBackendAPIConfiguration({
    id: 'DeleteEmployeePaymentRequest',
    endpoint: '/msa/service/commands/DeleteEmployeePaymentRequest',
    convertInputToXMLElement: ({ id }: T.DeleteEmployeePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteEmployeePaymentRequest' },
        EmployeePaymentRequest: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  ChangeEmployeePaymentRequestStatus: makeBackendAPIConfiguration({
    id: 'ChangeEmployeePaymentRequestStatus',
    endpoint: '/msa/service/commands/ChangeEmployeePaymentRequestStatus',
    convertInputToXMLElement: ({ id, status, comment }: T.ChangeEmployeePaymentStatusReques) => ({
      Request: {
        _attr: { commandName: 'ChangeEmployeePaymentRequestStatus' },
        Ids: { Id: id },
        Status: status,
        ...(comment ? { Reason: comment } : {}),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SaveEmployeePaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'SaveEmployeePaymentRequestFeedline',
    endpoint: '/msa/service/commands/SaveEmployeePaymentRequestFeedline',
    convertInputToXMLElement: ({ id, paymentFeedline, status }: T.SaveEmployeePaymentFeedlineRequest) => ({
      Request: {
        _attr: { commandName: 'SaveEmployeePaymentRequestFeedline' },
        PaymentRequestFeedline: {
          _attr: paymentFeedline.id ? { id: paymentFeedline.id } : {},
          PaymentRequest: { _attr: { id } },
          Status: status,
          MessageCategory: { _attr: { id: paymentFeedline.messageCategory.id } },
          ...(paymentFeedline.message ? { Message: paymentFeedline.message } : {}),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetEmployeePaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'GetEmployeePaymentRequestFeedline',
    endpoint: '/msa/service/commands/GetEmployeePaymentRequestFeedline',
    convertInputToXMLElement: ({ id }: T.GetEmployeePaymentRequest) => ({
      Request: {
        _attr: { commandName: 'GetEmployeePaymentRequestFeedline' },
        PaymentRequestFeedline: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) =>
      convertServerPaymentFeedline(response.Response.PaymentRequestFeedline),
  }),
  DeleteEmployeePaymentRequestFeedline: makeBackendAPIConfiguration({
    id: 'DeleteEmployeePaymentRequestFeedline',
    endpoint: '/msa/service/commands/DeleteEmployeePaymentRequestFeedline',
    convertInputToXMLElement: ({ feedlineId }: T.DeleteEmployeePaymentFeedlineRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteEmployeePaymentRequestFeedline' },
        PaymentRequestFeedline: {
          _attr: { id: feedlineId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const fundsConfigurations = {
  ...tableFundsConfigurations,
  ...configurations,
};

import { Parameter, CheckedListValue } from 'types/models';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateCheckboxList = (projectTypeParameter: Parameter): ValidatorResult => {
  const value = projectTypeParameter.value as CheckedListValue;
  const { required } = projectTypeParameter;

  if (required) {
    const isValid = !!value.list.filter(({ isChecked }) => isChecked).length;
    return { isValid, message: isValid ? '' : `Выберите хотя бы один элемент в списке "${projectTypeParameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default validateCheckboxList;

import React, { useMemo } from 'react';

import {
  Button,
  FormComponent,
  Radio,
  ReferenceItem,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadImage,
} from 'components';

import { Person, UserPermission } from 'types/models';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { ValueOf } from 'types/helpers';
import { convertDegree, convertAcademicRank, convertRank } from './helpers';
import { useController } from './controller';
import UpperFormPublications from './UpperFormPublications';
import ProfileAchievements from 'features/Charts/ProfileAchievements';
import { LANGUAGE_COMMUNICATION_HINT } from 'utils/Constants';
import { getPersonContacts } from 'utils/Helpers';
import { PersonContactList } from 'features/Form/views';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  viewMode: boolean;
  makeSetPerson: (fieldName: keyof Person.ScientistPerson) => (value: ValueOf<Person.ScientistPerson>) => void;
};
function Component(props: Props) {
  const { userPermission, viewMode, person, makeSetPerson } = props;
  if (!person) {
    return null;
  }

  const {
    isPublicationsOpened,
    isFiveYear,
    closePublicationWindow,
    openPublicationsWindow,
    getActualJob,
    jobsCombinationList,
    getActualEducation,
    yearInterval,
    hirshIndexes,
    onCustomChange,
  } = useController({ person, makeSetPerson });

  const personalFields = useMemo(
    () => [
      {
        label: 'Ученая степень:',
        value: person.degrees.length > 0 ? convertDegree(person) : '',
      },
      {
        label: 'Ученое звание:',
        value: person.ranks.length > 0 ? convertRank(person) : '',
      },
      {
        label: 'Академическое звание:',
        value: person.academicRanks.length > 0 ? convertAcademicRank(person) : '',
      },
      {
        label: 'Обучение:',
        value: person.scientistEducations.length > 0 ? getActualEducation : '',
      },
    ],
    [person, getActualEducation],
  );

  type FieldSettings = {
    onValueChange: (val: keyof Person.Scientist) => void;
    value: string | undefined;
    mode?: TextInputMode;
  };

  const checkFieldTypeByMode = ({ onValueChange, value, mode }: FieldSettings) =>
    viewMode ? (
      <span>{value || <em>не указано</em>}</span>
    ) : (
      <TextInput mode={mode} value={value ?? ''} onChange={onValueChange} />
    );

  const header = useMemo(() => {
    const engName: string = [
      person.scientist?.englishLastName || '',
      person.scientist?.englishFirstName || '',
      person.scientist?.englishPatronymic || '',
    ]
      .filter(Boolean)
      .join(' ');

    return [
      person.scientist?.lastName || '',
      person.scientist?.firstName || '',
      person.scientist?.patronymic || '',
      ...(viewMode && engName ? [`(${engName})`] : []),
    ]
      .filter(Boolean)
      .join(' ');
  }, [
    person.scientist?.englishFirstName,
    person.scientist?.englishLastName,
    person.scientist?.englishPatronymic,
    person.scientist?.firstName,
    person.scientist?.lastName,
    person.scientist?.patronymic,
    viewMode,
  ]);

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: 'calc(100% - 750px)', maxWidth: '950px' }}>
          <SectionTitle title={`${header} (GUID: ${person.scientist?.guid || 'отсутствует'})`} />

          <FormComponent.ColumnWrapper>
            <FormComponent.Column style={{ width: 'calc(100% - 250px)' }} hasNoWrap>
              {!viewMode && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field isRequired label="Фамилия">
                      <TextInput
                        value={person.scientist?.lastName ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, lastName: e })}
                        isDisabled={person.scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Имя" isRequired>
                      <TextInput
                        value={person.scientist?.firstName ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, firstName: e })}
                        isDisabled={person.scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Отчество">
                      <TextInput
                        value={person.scientist?.patronymic ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, patronymic: e })}
                        isDisabled={person.scientist?.fromBus}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>

                  <FormComponent.Line>
                    <FormComponent.Field label="Фамилия на англ:">
                      <TextInput
                        value={person.scientist?.englishLastName ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, englishLastName: e })}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Имя на англ:">
                      <TextInput
                        value={person.scientist?.englishFirstName ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, englishFirstName: e })}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="Отчество на англ:">
                      <TextInput
                        value={person.scientist?.englishPatronymic ?? ''}
                        onChange={e => makeSetPerson('scientist')({ ...person.scientist!, englishPatronymic: e })}
                      />
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_BIRTH_DATE_ACCESS) &&
                (viewMode ? (
                  <FormComponent.Line>
                    <FormComponent.Field label="Дата рождения">
                      <span>{person.scientist?.dateBirth}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                ) : (
                  <>
                    <FormComponent.Line>
                      <FormComponent.Field label="Дата рождения" isRequired>
                        <TextDateTime
                          value={person.scientist?.dateBirth}
                          onChange={e => makeSetPerson('scientist')({ ...person.scientist!, dateBirth: e })}
                          isDisabled={person.scientist?.fromBus}
                        />
                      </FormComponent.Field>
                    </FormComponent.Line>
                    <FormComponent.Line>
                      <FormComponent.Field label="Пол">
                        <Radio
                          isDisabled={person.scientist?.fromBus}
                          value={person.scientist?.gender}
                          list={[
                            { label: 'М', value: 'MALE' },
                            { label: 'Ж', value: 'FEMALE' },
                          ]}
                          onChange={e => makeSetPerson('scientist')({ ...person.scientist!, gender: e })}
                        />
                      </FormComponent.Field>
                    </FormComponent.Line>
                  </>
                ))}

              {viewMode ? (
                <FormComponent.Line>
                  <FormComponent.Field label="Гражданство">
                    <span>{person.scientist?.citizenship?.label || 'не указано'}</span>
                  </FormComponent.Field>
                </FormComponent.Line>
              ) : (
                <FormComponent.Line>
                  <FormComponent.Field label="Гражданство">
                    <Select
                      mode={SelectMode.REFERENCE}
                      value={person.scientist?.citizenship}
                      onChange={(option: ReferenceItem | null) =>
                        makeSetPerson('scientist')({ ...person.scientist!, citizenship: option })
                      }
                      settings={{ name: 'RefCountry', title: 'Справочник "Страны"', isClearable: true }}
                      isDisabled={person.scientist?.fromBus}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              )}
              <>
                {personalFields.map(
                  (field, index) =>
                    field.value && (
                      <FormComponent.Line key={index}>
                        <FormComponent.Field label={field.label}>
                          <span>{field.value}</span>
                        </FormComponent.Field>
                      </FormComponent.Line>
                    ),
                )}
              </>

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_SNILS_INN_ACCESS) && (
                <>
                  <FormComponent.Line>
                    <FormComponent.Field label="ИНН:">
                      {checkFieldTypeByMode({
                        value: person.scientist?.inn,
                        onValueChange: e => makeSetPerson('scientist')({ ...person.scientist!, inn: e }),
                      })}
                    </FormComponent.Field>
                  </FormComponent.Line>
                  <FormComponent.Line>
                    <FormComponent.Field label="СНИЛС:">
                      {checkFieldTypeByMode({
                        value: person.scientist?.snils,
                        onValueChange: e => makeSetPerson('scientist')({ ...person.scientist!, snils: e }),
                        mode: TextInputMode.SNILS,
                      })}
                    </FormComponent.Field>
                  </FormComponent.Line>
                </>
              )}

              <FormComponent.Line>
                <FormComponent.Field label="Языковые коммуникации" tooltip={LANGUAGE_COMMUNICATION_HINT}>
                  {checkFieldTypeByMode({
                    value: person.scientist?.languageCommunication || '',
                    onValueChange: e => makeSetPerson('scientist')({ ...person.scientist!, languageCommunication: e }),
                  })}
                </FormComponent.Field>
              </FormComponent.Line>

              <SectionTitle title="Актуальные контакты" />

              {isHasPermission(userPermission, Permits.PERSON_PRIVATE_DATA_CONTACTS_ACCESS) &&
                (viewMode ? (
                  <FormComponent.Line>
                    <FormComponent.Field label="Данные">
                      <span>{getPersonContacts(person.contacts) || 'Не указано'}</span>
                    </FormComponent.Field>
                  </FormComponent.Line>
                ) : (
                  <>
                    <FormComponent.Line>
                      <PersonContactList contacts={person.contacts} setContacts={makeSetPerson('contacts')} />
                    </FormComponent.Line>
                  </>
                ))}
              <FormComponent.Line>
                <FormComponent.Field label="Примечание">
                  <TextArea
                    mode={TextAreaMode.MODAL}
                    settings={{ rows: 3, title: 'Примечание' }}
                    value={person.scientist?.note || ''}
                    onChange={e => makeSetPerson('scientist')({ ...person.scientist!, note: e })}
                    isDisabled={!!viewMode}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </FormComponent.Column>

            <FormComponent.Column style={{ width: '250px', height: '270px' }} hasNoWrap>
              <UploadImage text="Фото профиля" id={person?.avatar?.id} viewMode={viewMode} onChange={onCustomChange} />
            </FormComponent.Column>
          </FormComponent.ColumnWrapper>

          <SectionTitle title="Место работы" tooltip="Эти данные о работе используются  Системой по умолчанию" />

          <>
            {person.scientistJobs.length > 0 && (
              <FormComponent.Line>
                <FormComponent.Field label="Основное место работы:">
                  <span>{getActualJob}</span>
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>
          <>
            {jobsCombinationList.length > 0 && (
              <FormComponent.Line>
                <FormComponent.Field label="Совместительство: ">
                  {jobsCombinationList.map((job, index) => (
                    <FormComponent.Line key={index}>
                      <span>{job}</span>
                    </FormComponent.Line>
                  ))}
                </FormComponent.Field>
              </FormComponent.Line>
            )}
          </>

          <SectionTitle title="Идентификаторы" />

          <>
            {person.identifiers.map((filter, index) => (
              <FormComponent.Line key={index}>
                <FormComponent.Field label={`${filter.citationSystem?.label}: `}>
                  {filter.link ? (
                    <FormComponent.Link href={filter.link} label={filter.identifier} />
                  ) : (
                    <span>{filter.identifier}</span>
                  )}
                </FormComponent.Field>
              </FormComponent.Line>
            ))}
          </>
          <>
            {person.affiliations?.length > 0 && (
              <>
                <SectionTitle title="Аффилированные организации:" />

                {person.affiliations.map(affiliation => (
                  <FormComponent.Line key={affiliation.id}>
                    {affiliation.partner ? (
                      <span>{`${affiliation.partner?.fullName} ${
                        affiliation.partner?.shortName ? ` (${affiliation.partner?.shortName})` : ''
                      }`}</span>
                    ) : (
                      <span>{affiliation.enterprise?.label || affiliation.enterpriseCustomer?.label}</span>
                    )}
                  </FormComponent.Line>
                ))}
              </>
            )}
          </>
        </FormComponent.Column>

        <FormComponent.Column style={{ width: '750px' }}>
          <SectionTitle title={`Результаты за период: ${yearInterval}`} />

          <FormComponent.Line>
            <span>Индекс Хирша:</span>
          </FormComponent.Line>
          <>
            {hirshIndexes
              .filter((i, index) => hirshIndexes.map(h => h.label).indexOf(i.label) === index)
              .map((hirsh, index) => (
                <FormComponent.Line key={index}>
                  <FormComponent.Field label={`${hirsh.label}: `}>
                    {!!hirsh.value && (
                      <span>
                        <strong>{hirsh.value}</strong>
                        {hirsh.date ? ` (дата расчета: ${hirsh.date})` : ''}
                      </span>
                    )}
                  </FormComponent.Field>
                </FormComponent.Line>
              ))}
          </>

          <FormComponent.Line hasFreeFormat>
            <span>Совокупный импакт&#8209;фактор: {person.scientist?.calculateImpactFactor}</span>
            <Button
              icon={{ type: 'list', mode: 'info' }}
              title="Публикации для расчета совокупного ИФ"
              onClick={() => openPublicationsWindow(false)}
            />
          </FormComponent.Line>

          <SectionTitle />

          <>{person.calculations && <ProfileAchievements calculations={person.calculations} />}</>
        </FormComponent.Column>

        <UpperFormPublications
          isOpen={isPublicationsOpened}
          onClose={closePublicationWindow}
          id={person.id}
          isFiveYear={isFiveYear}
        />
      </FormComponent.ColumnWrapper>
    </>
  );
}

export const UpperForm = React.memo(Component);

import React, { memo } from 'react';

import { Document, Table as T } from 'types/models';
import { Documents } from 'features/Documents';

type Props = {
  documents: Document[];
  setDocuments: (docs: Document[]) => void;
  workMode: T.WorkMode;
};

const Docs = ({ documents, setDocuments, workMode }: Props) => (
  <Documents
    sourceLabelForLocalDocs="Текущее участие в конкурсе/олимпиаде"
    documents={documents}
    setDocuments={setDocuments}
    disabled={workMode === 'viewMode'}
    isShowSource
    hint="Для утверждения участия в мероприятии необходимо добавить документ, подтверждающий участие"
    // eslint-disable-next-line max-len
    tooltipDocumentName="Подтверждающий документ - это Положение о конкурсе, Сертификат (в т.ч. именной) об участии, Каталог участников (в т.ч. на сайте Мероприятия подтверждает официальное участие) и проч."
  />
);

export const Component = memo(Docs);

import * as XML from 'xml-js';
import { makeBackendFieldSuggestionAPIConfiguration } from '../makeBackendFieldSuggestionAPIConfiguration';

type FieldName = string; // todo rest fields

type FieldSuggestionInput = {
  fieldName: FieldName;
  filter: string;
  limit: number;
};

const convertGetFieldSuggestionXMLElement = <Input extends FieldSuggestionInput>(input: Input) => {
  const { fieldName, filter, limit } = input;
  const reqObject = {
    FieldSuggestionRequest: {
      _attr: {
        fieldName,
        filter,
        limit,
      },
    },
  };

  return reqObject;
};

function makeRequestXMLConverter<Input>(
  commandName: string,
  makeChildren: (input: Input) => XML.ElementCompact,
): (input: Input) => XML.ElementCompact {
  return (input: Input) => ({
    Request: {
      _attr: { commandName },
      ...makeChildren(input),
    },
  });
}

export const backendFieldSuggestionApiConfigurations = {
  GetFieldSuggestion: makeBackendFieldSuggestionAPIConfiguration(
    'GetFieldSuggestion',
    makeRequestXMLConverter('GetFieldSuggestion', (input: FieldSuggestionInput) => convertGetFieldSuggestionXMLElement(input)),
  ),
};

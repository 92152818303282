import { useCallback, useLayoutEffect, useState, useRef } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Document, Form, ResponseData } from 'types/models';
import { SecurityDocument } from 'types/models/SecurityDocument';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import workModeHook from 'features/Form/hooks/workModeHook';
import { useAppDataContext } from 'features/AppData/context';
import { SecurityDocumentPaymentRequestFormLook } from 'types/models/Form/look/securityDocument';
// eslint-disable-next-line max-len
import { useSecurityDocumentFormController } from 'features/Form/looks/securityDocument/SecurityDocumentForm/securityDocumentFormController';
import { AuthorPayment, Nominee, Department } from 'types/models/Payment';
import { ValueOf } from 'types/helpers';
import { checkPaymentByStatus } from 'features/IncentivePayments/helpers';
import { validate } from './validate';
import { AuthorPaymentRequestStatus, DistributionMethodBetweenFunds } from 'utils/Enums';
import { getMockSecurityDocument } from '../SecurityDocumentForm/helpers';
import { getMockAuthorPayment } from 'features/IncentivePayments/Form/helpers';
import { getEnumItem } from 'utils/Helpers';
import { Item } from 'types/models/common';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode, editMode, onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id, securityDocumentId },
  } = useFormContext<SecurityDocumentPaymentRequestFormLook>();

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode, editMode });

  const [authorPayment, setAuthorPayment] = useState<AuthorPayment>(
    getMockAuthorPayment({ status: getEnumItem('AuthorPaymentRequestStatus', AuthorPaymentRequestStatus.DRAFT) }),
  );
  const [isOpenCommentForm, setIsOpenCommentForm] = useState<boolean>();
  const [isOpenConfirmStatusForm, setIsOpenConfirmStatusForm] = useState<boolean>();
  const [isOpenStatusForm, setIsOpenStatusForm] = useState<boolean>();
  const [isNeedClose, setIsNeedClose] = useState<boolean>();
  const [comment, setComment] = useState<string | null>();
  const [newStatus, setNewStatus] = useState<string | null>();
  const [securityDocument, setSecurityDocument] = useState<SecurityDocument>(getMockSecurityDocument(''));
  const [documents, setDocuments] = useState<Document[]>([]);

  const { userToken } = useAppDataContext();
  const [formFields, setFormFields] = useState<Form.Fields>({
    regulationParagraph: {
      value: '',
      isValid: true,
      required: false,
      title: 'Пункт положения',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationParagraph: { ...prevState.regulationParagraph, value },
        }));
      },
    },
    regulationName: {
      value: '',
      isValid: true,
      required: true,
      title: 'Название',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          regulationName: { ...prevState.regulationName, value },
        }));
      },
    },
    factFundMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма фонда, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factFundMoney: { ...prevState.factFundMoney, value },
        }));
      },
    },
    factNomineesMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма номинантам, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          factNomineesMoney: { ...prevState.factNomineesMoney, value },
        }));
      },
    },
    maxFundMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма фонда, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          maxFundMoney: { ...prevState.maxFundMoney, value },
        }));
      },
    },
    maxNomineesMoney: {
      value: '',
      isValid: true,
      required: true,
      title: 'Сумма номинантам, руб.',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          maxNomineesMoney: { ...prevState.maxNomineesMoney, value },
        }));
      },
    },
  });

  const { methods: getPaymentTermPreset } = BackendAPI.useBackendAPI('GetPaymentTermPreset', {
    onSuccessfullCall: ({ data }) => {
      setAuthorPayment(prevState => ({
        ...prevState,
        regulationParagraph: data?.regulationParagraph,
        regulationName: data?.regulationName,
      }));

      setFormFields(prevState => ({
        ...prevState,
        regulationParagraph: {
          ...prevState.regulationParagraph,
          value: data?.regulationParagraph,
        },
        regulationName: {
          ...prevState.regulationName,
          value: data?.regulationName,
        },
      }));
    },
  });

  const { methods: getSecurityDocument } = BackendAPI.useBackendAPI('GetSecurityDocument', {
    onSuccessfullCall: ({ data }) => {
      setSecurityDocument(data);
      // copy from SecurityDocumentForm -> loadPatent parser
      setSecurityDocumentFormFields(prev => ({
        ...prev,
        requestNumber: { ...prev.requestNumber, value: data.requestNumber },
        requestDate: { ...prev.requestDate, value: data.requestDate },
        requestSendDate: { ...prev.requestSendDate, value: data.requestSendDate },
        documentDate: { ...prev.documentDate, value: data.documentDate },
        documentReceiveDate: { ...prev.documentReceiveDate, value: data.documentReceiveDate },
        isRus: { ...prev.isRus, value: data.isRus },
        isWorldwide: { ...prev.isWorldwide, value: data.isWorldwide },
        balanceOrderNumber: { ...prev.balanceOrderNumber, value: data.balanceOrderNumber },
        loDate: { ...prev.loDate, value: data.loDate },
        hiDate: { ...prev.hiDate, value: data.hiDate },
        balancePrice: { ...prev.balancePrice, value: data.balancePrice },
        intellectualPropertyName: { ...prev.intellectualPropertyName, value: data.intellectualPropertyName },
        referat: { ...prev.referat, value: data.referat },
        documentNumber: { ...prev.documentNumber, value: data.documentNumber },
        internalNumber: { ...prev.internalNumber, value: data.internalNumber },
        stateNumber: { ...prev.stateNumber, value: data.stateNumber },
        stateRegistrationDate: { ...prev.stateRegistrationDate, value: data.stateRegistrationDate },
      }));
    },
  });

  const { methods: getAuthorPaymentRequest } = BackendAPI.useBackendAPI('GetAuthorPaymentRequest');

  const loadAuthorPaymentRequest = useCallback(
    (data: AuthorPayment | null) => {
      if (data) {
        setAuthorPayment(data);
      }

      setDocuments(data?.documents || []);

      setFormFields(prevState => ({
        ...prevState,
        regulationParagraph: {
          ...prevState.regulationParagraph,
          value: data?.regulationParagraph,
        },
        regulationName: {
          ...prevState.regulationName,
          value: data?.regulationName,
        },
        factFundMoney: {
          ...prevState.factFundMoney,
          value: data?.factFundMoney,
        },
        factNomineesMoney: {
          ...prevState.factNomineesMoney,
          value: data?.factNomineesMoney,
        },
        maxFundMoney: {
          ...prevState.maxFundMoney,
          value: data?.maxFundMoney,
        },
        maxNomineesMoney: {
          ...prevState.maxNomineesMoney,
          value: data?.maxNomineesMoney,
        },
      }));

      if (data?.patent?.id) {
        getSecurityDocument.callAPI({ id: data?.patent?.id || '' });
      }

      if (!id) {
        getPaymentTermPreset.callAPI({ type: 'SECURITY_DOCUMENT' });
      }
    },
    [setAuthorPayment, setDocuments, setFormFields, getSecurityDocument, getPaymentTermPreset, id],
  );

  const { methods: savePaymentRequest } = BackendAPI.useBackendAPI('SavePatentPaymentRequest');

  const handleSaveResponse = useCallback(
    (respData: ResponseData) => {
      getAuthorPaymentRequest.callAPI(
        {
          foundation: { id: respData.id || '', label: '' },
          mode: 'edit',
          type: 'SECURITY_DOCUMENT',
        },
        { onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data) },
      );
      updateWorkModeAfterSaveAndContinue();
    },
    [getAuthorPaymentRequest, updateWorkModeAfterSaveAndContinue, loadAuthorPaymentRequest],
  );

  const onSubmit = useCallback(
    (needClose: boolean, onSuccessCb?: () => void) => {
      const errors = validate(authorPayment || null, formFields);
      if (errors && errors.length > 0) {
        errors.forEach(error => {
          showNotification({ message: error.invalidMessage, theme: 'danger' });
        });
        return false;
      }
      savePaymentRequest.callAPI(
        {
          ...authorPayment,
          ...(comment ? { saveComment: comment } : {}),
          createdBy: null,
          documents,
          patent: securityDocument || null,
          statusChangedDate: '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Элемент успешно сохранен', theme: 'success' });
            setComment('');
            onSuccessCb?.();
            if (needClose) {
              tableStreams.reloadTable.push({});
              onClose();
            } else if (data.id) {
              handleSaveResponse(data);
            }
          },
        },
      );
    },
    [
      authorPayment,
      savePaymentRequest,
      tableStreams,
      formFields,
      onClose,
      handleSaveResponse,
      setComment,
      comment,
      documents,
      securityDocument,
    ],
  );

  const { securityDocumentFormFields, setSecurityDocumentFormFields } = useSecurityDocumentFormController({
    securityDocument: securityDocument,
    setSecurityDocument: setSecurityDocument,
  });

  useLayoutEffect(() => {
    getAuthorPaymentRequest.callAPI(
      {
        foundation: { id: id || securityDocumentId || '', label: '' },
        type: 'SECURITY_DOCUMENT',
        mode: id ? 'edit' : 'add',
      },
      {
        onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data),
      },
    );
    if (securityDocumentId || authorPayment?.patent?.id) {
      getSecurityDocument.callAPI({ id: securityDocumentId || authorPayment?.patent?.id || '' });
    }
    // eslint-disable-next-line
  }, []);

  const { methods: distributeMoneyAPI } = BackendAPI.useBackendAPI('DistributeMoney', {
    onSuccessfullCall: ({ data }) => {
      if (data) {
        setAuthorPayment({
          ...data,
          id: authorPayment?.id ?? null,
          regulationName: authorPayment?.regulationName ?? '',
          regulationParagraph: authorPayment?.regulationParagraph ?? '',
          status: authorPayment?.status ?? data.status,
          year: authorPayment?.year,
          quarter: authorPayment?.quarter,
          month: authorPayment?.month,
        });
      }
    },
    onFailedCall: () => {},
  });

  const timeoutState = useRef<any>();

  const changeAuthorPayment = useCallback(
    (key: keyof AuthorPayment, val: ValueOf<AuthorPayment>) => {
      setFormFields(prevState => ({
        ...prevState,
        [key]: {
          ...prevState[key],
          value: val,
        },
      }));

      setAuthorPayment(prev => ({ ...prev!, [key]: val }));
      const newAuthorPayment = { ...authorPayment, [key]: val };

      if (key === 'maxNomineesMoney' || key === 'maxFundMoney') {
        clearTimeout(timeoutState.current);
        timeoutState.current = setTimeout(() => {
          distributeMoneyAPI.callAPI({
            type: 'SECURITY_DOCUMENT',
            foundationId: authorPayment?.patent?.id || '',
            foundationType: 'SECURITY_DOCUMENT',
            maxFundMoney: newAuthorPayment?.maxFundMoney || '0',
            maxNomineesMoney: newAuthorPayment?.maxNomineesMoney || '0',
            isManualEdit: newAuthorPayment?.isManualEdit ?? false,
            nominees: newAuthorPayment?.nominees ?? [],
            departments: newAuthorPayment?.departments ?? [],
            distributionMethod: (newAuthorPayment?.distributionMethodBetweenFunds?.value ??
              'ALL') as DistributionMethodBetweenFunds,
          });
        }, 500);
      }
    },
    [setFormFields, authorPayment, setAuthorPayment, distributeMoneyAPI],
  );

  const nomineesChangeHandler = useCallback(
    (val: Nominee[]) => {
      setAuthorPayment(prevState => ({ ...prevState, nominees: val }));
      distributeMoneyAPI.callAPI({
        type: 'SECURITY_DOCUMENT',
        foundationId: authorPayment?.patent?.id || '',
        foundationType: 'SECURITY_DOCUMENT',
        maxFundMoney: authorPayment?.maxFundMoney || '0',
        maxNomineesMoney: authorPayment?.maxNomineesMoney || '0',
        isManualEdit: authorPayment?.isManualEdit ?? false,
        nominees: val ?? [],
        departments: authorPayment?.departments ?? [],
        distributionMethod: (authorPayment?.distributionMethodBetweenFunds?.value ?? 'ALL') as DistributionMethodBetweenFunds,
      });
    },
    [authorPayment, distributeMoneyAPI],
  );

  const distributionChangeHandler = useCallback(
    (key: keyof AuthorPayment, val: ValueOf<AuthorPayment>) => {
      changeAuthorPayment(key, val);
      if (key !== 'isManualEdit') {
        distributeMoneyAPI.callAPI({
          type: 'SECURITY_DOCUMENT',
          foundationId: authorPayment?.patent?.id || '',
          foundationType: 'SECURITY_DOCUMENT',
          maxFundMoney: authorPayment?.maxFundMoney || '0',
          maxNomineesMoney: authorPayment?.maxNomineesMoney || '0',
          isManualEdit: authorPayment?.isManualEdit ?? false,
          nominees: authorPayment?.nominees ?? [],
          departments: key === 'departments' ? (val as Department[]) : authorPayment?.departments ?? [],
          distributionMethod: (key === 'distributionMethodBetweenFunds' && val
            ? (val as Item)?.value
            : authorPayment?.distributionMethodBetweenFunds?.value ?? 'ALL') as DistributionMethodBetweenFunds,
        });
      }
    },
    [authorPayment, distributeMoneyAPI, changeAuthorPayment],
  );

  const { methods: changeStatusAPI } = BackendAPI.useBackendAPI('ChangeAuthorPaymentRequestStatus');

  const handleStatusChange = useCallback(
    (forceStatus?: string) => {
      changeStatusAPI.callAPI(
        { comment: comment || '', status: forceStatus || newStatus || '', paymentId: authorPayment?.id || '' },
        {
          onSuccessfullCall: () => {
            showNotification({ message: 'Статус заявки успешно изменен', theme: 'success' });
            getAuthorPaymentRequest.callAPI(
              {
                foundation: { id: authorPayment?.id || '', label: '' },
                mode: 'edit',
                type: 'SECURITY_DOCUMENT',
              },
              { onSuccessfullCall: ({ data }) => loadAuthorPaymentRequest(data) },
            );
            updateWorkModeAfterSaveAndContinue();
            setComment('');
          },
        },
      );
    },
    [
      comment,
      newStatus,
      authorPayment,
      setComment,
      loadAuthorPaymentRequest,
      changeStatusAPI,
      getAuthorPaymentRequest,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  const changePaymentStatus = useCallback(
    (status: string) => {
      setNewStatus(status);
      if (checkPaymentByStatus(authorPayment?.factNomineesMoney ?? null, authorPayment?.status?.value ?? null)) {
        setIsOpenConfirmStatusForm(true);
      } else {
        setIsOpenStatusForm(true);
      }
    },
    [setNewStatus, authorPayment],
  );

  const handleFormSubmit = useCallback(
    (onSuccessCb?: () => void) => {
      onSubmit(!!isNeedClose, onSuccessCb);
    },
    [onSubmit, isNeedClose],
  );

  const onSave = useCallback(
    (needClose?: boolean, onSuccessCb?: () => void, isWithComment?: boolean) => {
      setIsNeedClose(needClose);
      if (isWithComment) {
        setIsOpenCommentForm(true);
      } else {
        handleFormSubmit(onSuccessCb);
      }
    },
    [setIsNeedClose, handleFormSubmit],
  );

  const onChangeConfirm = useCallback(
    (status: AuthorPaymentRequestStatus) => {
      setNewStatus(status);
      setIsOpenConfirmStatusForm(true);
    },
    [setNewStatus, setIsOpenConfirmStatusForm],
  );

  return {
    paymentId: id ?? null,
    authorPayment,
    setAuthorPayment,
    workMode,
    formFields,
    securityDocumentFormFields,
    userToken,
    securityDocument,
    documents,
    setDocuments,
    changeAuthorPayment,
    distributionChangeHandler,
    isOpenCommentForm,
    setIsOpenCommentForm,
    isOpenStatusForm,
    setIsOpenStatusForm,
    isOpenConfirmStatusForm,
    setIsOpenConfirmStatusForm,
    comment,
    setComment,
    newStatus,
    setNewStatus,
    changePaymentStatus,
    handleStatusChange,
    handleFormSubmit,
    isNeedClose,
    setIsNeedClose,
    onSave,
    onChangeConfirm,
    nomineesChangeHandler,
  };
}

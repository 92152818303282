import React from 'react';

import { Tabs, Tab } from 'components';

import { NirRequest } from 'types/models';
import { ValueOf } from 'types/helpers';

import { TeamComposition } from './TeamComposition';
import { CharacteristicComposition } from './CharacteristicComposition';

type Props = {
  nirRequest: NirRequest.NirRequest | null;
  makeChangeHandler(key: keyof NirRequest.NirRequest): (value: ValueOf<NirRequest.NirRequest>) => void;
  disabled: boolean;
};

export function Collective(props: Props) {
  const { nirRequest, makeChangeHandler, disabled } = props;

  return (
    <Tabs>
      <Tab title="Состав коллектива">
        <TeamComposition
          performers={nirRequest?.performers ?? []}
          setPerformers={makeChangeHandler('performers')}
          disabled={disabled}
          tenderType={nirRequest?.lot?.nirTenderType?.value ?? null}
        />
      </Tab>
      <Tab title="Характеристика состава">
        <CharacteristicComposition makeChangeHandler={makeChangeHandler} nirRequest={nirRequest} disabled={disabled} />
      </Tab>
    </Tabs>
  );
}

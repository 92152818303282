import { useCallback, useEffect, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalSuchLikePublicationStreams } from 'features/SuchLike/SuchLikePublication/hooks';
import { showNotification } from 'features/Notifications';
import { helpers } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import useDeletePublication from 'features/Form/looks/publication/hooks/useDeletePublication';
import { getIsSourceCitationDisabled } from 'utils/Helpers/getIsSourceCitationDisabled';
import { Permits } from 'utils/Permissions/Permits';
import { Event, Publication, Table, Report } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { PublicationTypeCode } from 'types/models/Form';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { Reports, useReportsHook } from 'features/BuildReportPopup';
import { useLocalTableStreams } from 'features/Table/hooks';
import { isCompilation, isTSUEdition } from 'features/EditRelationsModal/helpers';
import { downloadFile, getAuthToken, getLinksMap } from 'utils/Helpers';
import { PublicationStatus, PublicationType } from 'utils/Enums';
import { SubmitTable } from 'features/Table/streams';
import { isHasPermission } from 'features/AppData';

export function useController(tableState: Table.State) {
  const token = getAuthToken();
  const suchLikePublicationStreams = useLocalSuchLikePublicationStreams();
  const tableStreams = useLocalTableStreams();
  const { settings, userPermission } = useAppDataContext();

  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const [publication, setPublication] = useState<Publication | null>(null);
  const [publicationConferenceParticipation, setPublicationConferenceParticipation] = useState<Event.Source | null>(null);
  const [publicationConferenceSource, setPublicationConferenceSource] = useState<Event.Event | null>(null);

  const [isAddFormOpenConferenceParticipation, setIsAddFormOpenConferenceParticipation] = useState(false);

  const [isOpenHelpForm, setIsOpenHelpForm] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isApprovePopupOpen, setIsApprovePopupOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [isDoiPopupOpened, setIsDoiPopupOpened] = useState(false);
  const [isOpenEditRelations, setIsOpenEditRelations] = useState(false);
  const [isCitationFormOpen, setIsCitationFormOpen] = useState(false);
  const [isPublicationOriginalTranslatesRelationsOpen, setIsPublicationOriginalTranslatesRelationsOpen] = useState(false);
  const [isSourceCitationOpen, setIsSourceCitationOpen] = useState(false);
  const [isFinancialSupportOpen, setIsFinancialSupportOpen] = useState(false);
  const [isPrepreintRelationOpen, setIsPrepreintRelationOpen] = useState(false);
  const [isTransformOpen, setIsTransformOpen] = useState(false);
  const [isUsagesListOpen, setIsUsagesListOpen] = useState(false);
  const [isParticipationModalOpen, setIsParticipationModalOpen] = useState<boolean>(false);

  const [nextPublicationName, setNextPublicationName] = useState('');

  const { methods: getPublicationAPI } = BackendAPI.useBackendAPI('GetPublicationById', {
    onSuccessfullCall: ({ data }) => {
      setPublication(data);
    },
  });
  const { methods: sendToLibraryAPI } = BackendAPI.useBackendAPI('ExportPublicationToVirtua', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Отправлено в библиотеку', theme: 'success' });
    },
  });
  const { methods: editPublicationField } = BackendAPI.useBackendAPI('EditPublicationField');

  const SUCH_LIKE_PUBLICATION_ID = useMemo(() => 'PUBLICATION_EXTENDED_LIST', []);

  const isSelectedPublicationTransformable = useMemo(() => {
    return (
      tableState.selectedRows.length === 1 &&
      (selectedRow['id:PublicationType'] === PublicationTypes.MAGAZINE_ARTICLE.code ||
        selectedRow['id:PublicationType'] === PublicationTypes.PREPRINT_ARTICLE.code)
    );
  }, [selectedRow, tableState.selectedRows.length]);

  const isSelectedPublicationLikeDisabled = useMemo(() => {
    if (tableState.selectedRows.length === 1) return selectedRow['id:Status'] === PublicationStatus.DRAFT;
    return true;
  }, [selectedRow, tableState.selectedRows.length]);

  const isSourceCitationDisabled = useMemo(() => {
    if (tableState.selectedRows.length === 1) return getIsSourceCitationDisabled({ row: selectedRow });
    return true;
  }, [selectedRow, tableState.selectedRows.length]);

  const isPublicationOriginalTranslatesRelationsDisabled = useMemo(() => {
    if (tableState.selectedRows.length === 1) return !helpers.isMagazineArticle(selectedRow);
    return true;
  }, [selectedRow, tableState.selectedRows.length]);

  const isEditRelationsDisabled = useMemo(() => {
    if (tableState.selectedRows.length === 1) return isCompilation(selectedRow) ? !isTSUEdition(selectedRow) : false;
    return true;
  }, [selectedRow, tableState.selectedRows.length]);

  const handleCloseFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(false);
  }, []);
  const handleOpenFormConferenceParticipation = useCallback(() => {
    setIsAddFormOpenConferenceParticipation(true);
  }, []);

  const sendToLibrary = useCallback(
    (publicationId: string) => {
      sendToLibraryAPI.callAPI({ publicationId });
    },
    [sendToLibraryAPI],
  );

  const getPublication = useCallback(
    (publicationId: string) => {
      getPublicationAPI.callAPI({ publicationId });
    },
    [getPublicationAPI],
  );

  const openDuplicatesForm = useCallback(() => {
    suchLikePublicationStreams.openSuchLikePublication.push({ componentId: SUCH_LIKE_PUBLICATION_ID });
  }, [suchLikePublicationStreams.openSuchLikePublication, SUCH_LIKE_PUBLICATION_ID]);

  const {
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    deletePublication,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
  } = useDeletePublication();

  const isTransformButtonDisabled = !selectedRow || !isSelectedPublicationTransformable;

  const isDownloadPublicationFileDisabled = !(selectedRow && selectedRow?.FileId);
  const isPrepreintRelationDisabled = !(
    selectedRow && selectedRow['id:PublicationType'] === PublicationTypes.PREPRINT_ARTICLE.code
  );

  const reports = useMemo<Report[]>(
    () => [
      Reports.AvtodissDetail,
      Reports.Dissertations,
      Reports.MonographDetail,
      Reports.DepartmentsPublications,
      Reports.TotalDepartmentReportAll,
      Reports.PublicationList,
      Reports['PublicationForm2.3'],
      Reports.PublicationExtendedList,
      Reports.PublicationsByCondition,
      Reports.Dissertation,
      Reports.Monograph,
      Reports.Publications,
      Reports.PublicationsYoung,
      Reports.PersonPubplicationMembers,
      Reports.Personal,
      Reports.YearTotal,
      Reports.YearMonograph,
    ],
    [],
  );
  const { isReportOpen, onReportClose, getReports, handleSetCurrentReport, currentReport } = useReportsHook({ reports });

  const handleOpenPreprintRelation = useCallback(() => {
    setIsPrepreintRelationOpen(true);
  }, []);

  const handleOpenUsagesList = useCallback(() => {
    setIsUsagesListOpen(true);
  }, []);

  const handleCloseUsagesList = useCallback(() => {
    setIsUsagesListOpen(false);
  }, []);

  const handleTransform = useCallback(() => {
    setIsTransformOpen(true);
  }, []);

  const handleClosePreprintRelation = useCallback(() => {
    setIsPrepreintRelationOpen(false);
  }, []);

  const handleOpenFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(true);
  }, []);

  const handleCloseFinancialSupport = useCallback(() => {
    setIsFinancialSupportOpen(false);
  }, []);

  const handleOpenSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(true);
  }, []);

  const handleCloseSourceCitation = useCallback(() => {
    setIsSourceCitationOpen(false);
  }, []);

  const handleOpenCitationForm = useCallback(() => {
    setIsCitationFormOpen(true);
  }, []);

  const handleClosePublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(false);
  }, []);

  const handleOpenPublicationOriginalTranslatesRelations = useCallback(() => {
    setIsPublicationOriginalTranslatesRelationsOpen(true);
  }, []);

  const handleCloseCitationForm = useCallback(() => {
    setIsCitationFormOpen(false);
  }, []);

  const handleSendToLibrary = useCallback(() => {
    if (selectedRow) sendToLibrary(selectedRow?.id);
  }, [selectedRow, sendToLibrary]);

  const updateTable = useCallback(() => {
    tableStreams.reloadTable.push({});
  }, [tableStreams.reloadTable]);

  const submitDoiPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const submitFinancialPopupCallback = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const submitPublicationOriginalTranslatesRelationsOpen = useCallback(() => {
    updateTable();
  }, [updateTable]);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    openDuplicatesForm();
  }, [openDuplicatesForm]);

  const handleOpenDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(true);
  }, []);

  const handleCloseDoiPopup = useCallback(() => {
    setIsDoiPopupOpened(false);
  }, []);

  const handleApprovePopupOpen = useCallback(() => {
    setIsApprovePopupOpen(true);
  }, []);

  const handleApprovePopupClose = useCallback(() => {
    setIsApprovePopupOpen(false);
  }, []);

  const handleContinueStep = useCallback((searchText: string) => {
    setNextPublicationName(searchText);
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);
  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleCloseEditRelationsModal = useCallback(() => {
    setIsOpenEditRelations(false);
  }, []);

  const handleEditRelations = useCallback(() => {
    if (selectedRow) {
      getPublication(selectedRow?.id);
      setIsOpenEditRelations(true);
    }
  }, [getPublication, selectedRow]);
  useEffect(() => {
    if (!selectedRow) {
      setIsEditFormOpen(false);
      setIsViewFormOpen(false);
    }
  }, [selectedRow]);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    const publicationId = selectedRow?.id;
    const publicationTypeCode = selectedRow['id:PublicationType'];

    if (publicationId && publicationTypeCode) {
      const preparedPublicationTypeCode = publicationTypeCode as PublicationTypeCode;
      deletePublication({ publicationId, publicationTypeCode: preparedPublicationTypeCode });
    }
    handleCloseDeleteConfirmPopup();
  }, [deletePublication, handleCloseDeleteConfirmPopup, selectedRow]);

  const handleDownloadPublicationFile = useCallback(() => {
    downloadFile(selectedRow?.FileId, token);
  }, [selectedRow?.FileId, token]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const getLinks = useCallback(() => {
    const links = getLinksMap({
      row: selectedRow,
      settings,
    });

    if (links.size === 0) {
      showNotification({ message: 'У выбранной публикации не указан ни один внешний источник', theme: 'danger' });
    }
    return links;
  }, [selectedRow, settings]);

  const onApprove = useCallback(
    (disapprove: boolean) => {
      const selectedRowId = selectedRow?.id || '';
      if (selectedRowId) {
        tableStreams.approveRow.push({
          approveRowId: selectedRowId,
          approveItemName: 'Publication',
          command: 'ApprovePublication',
          disapprove,
        });
        handleApprovePopupClose();
      }
    },
    [handleApprovePopupClose, selectedRow?.id, tableStreams.approveRow],
  );

  const submitParticipationModal = useCallback(
    ({ selectedRows }: SubmitTable) => {
      editPublicationField.callAPI(
        {
          id: selectedRow?.id,
          parameters: [
            {
              name: 'PARTICIPATION',
              value: selectedRows[0]?.id,
            },
          ],
        },
        {
          onSuccessfullCall: () => {
            setIsParticipationModalOpen(false);
            tableStreams.reloadTable.push({});
            showNotification({
              theme: 'success',
              message: 'Связь с участием в конференции сохранена',
            });
          },
        },
      );
    },
    [editPublicationField, selectedRow?.id, tableStreams.reloadTable],
  );

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsOpenHelpForm(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.PUBLICATION_VIEW },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.PUBLICATION_ADD },
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.PUBLICATION_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_DRAFTADDED) &&
            (tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.ADDED ||
              tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.DRAFT)) ||
          (!isHasPermission(userPermission, Permits.PUBLICATION_DELETE_APPROVED) &&
            tableState.selectedRows[0]?.['id:Status'] === PublicationStatus.APPROVED),
      },
      {
        icon: { type: 'relation' },
        title: 'Связь публикации с участием в конференции',
        onClick: () => setIsParticipationModalOpen(true),
        isDisabled:
          tableState.selectedRows.length !== 1 ||
          ![PublicationType.THESIS, PublicationType.ARTICLE_CONFERENCE, PublicationType.MAGAZINE_ARTICLE].includes(
            selectedRow?.['id:PublicationType'] as PublicationType,
          ),
      },
      {
        icon: { type: 'connection' },
        title: 'Редактировать связь публикации с подразделениями',
        onClick: handleEditRelations,
        permission: { name: Permits.PUBLICATION_DEPARTMENTS_EDIT },
        isDisabled: isEditRelationsDisabled,
      },
      {
        icon: { type: 'like' },
        title: 'Утверждение публикации',
        onClick: handleApprovePopupOpen,
        permission: { name: Permits.PUBLICATION_APPROVE },
        isDisabled: isSelectedPublicationLikeDisabled,
      },
      {
        icon: { type: 'currency' },
        title: 'Финансовая поддержка публикации',
        onClick: handleOpenFinancialSupport,
        permission: { name: Permits.PUBLICATION_SCIENCE_AND_MOBILE_PROJECT_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'badge' },
        title: 'Указать DOI статьи',
        onClick: handleOpenDoiPopup,
        permission: { name: Permits.PUBLICATION_DOI_EDIT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'transport' },
        title: 'Указать связь между препринтом и публикацией',
        onClick: handleOpenPreprintRelation,
        permission: { name: Permits.PUBLICATION_EDIT },
        isDisabled: isPrepreintRelationDisabled,
      },
      {
        icon: { type: 'relation', mode: 'view' },
        title: 'Связи публикации с другими объектами',
        onClick: handleOpenUsagesList,
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'statistic' },
        title: 'Показатели публикации',
        onClick: handleOpenCitationForm,
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'statistic', mode: 'view' },
        title: 'Показатели источника',
        onClick: handleOpenSourceCitation,
        isDisabled: isSourceCitationDisabled,
      },
      {
        icon: { type: 'lang' },
        title: 'Указать статью-оригинал/переводную статью',
        onClick: handleOpenPublicationOriginalTranslatesRelations,
        permission: { name: Permits.PUBLICATION_ARTICLE_TRANSLATION_EDIT },
        isDisabled: isPublicationOriginalTranslatesRelationsDisabled,
      },
      {
        icon: { type: 'transform' },
        title: 'Трансформация',
        onClick: handleTransform,
        permission: { name: Permits.PUBLICATION_TRANSFORM },
        isDisabled: isTransformButtonDisabled,
      },
      {
        icon: { type: 'library', mode: 'arrowRight' },
        title: 'Отправить в библиотеку',
        onClick: handleSendToLibrary,
        permission: { name: Permits.PUBLICATION_EXPORT },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'link' },
        title: 'Открыть публикацию во внешнем источнике',
        expandedList: { list: getLinks },
        isDisabled: tableState.selectedRows.length !== 1,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл прикрепленный к публикации',
        onClick: handleDownloadPublicationFile,
        isDisabled: isDownloadPublicationFileDisabled,
      },
      {
        icon: { type: 'print' },
        title: 'Отчеты',
        expandedList: { list: getReports, callback: handleSetCurrentReport },
      },
    ],
    [
      handleViewButtonClick,
      tableState.selectedRows,
      handleAddButtonClick,
      handleEditButtonClick,
      handleDeleteButtonClick,
      userPermission,
      selectedRow,
      handleEditRelations,
      isEditRelationsDisabled,
      handleApprovePopupOpen,
      isSelectedPublicationLikeDisabled,
      handleOpenFinancialSupport,
      handleOpenDoiPopup,
      handleOpenPreprintRelation,
      isPrepreintRelationDisabled,
      handleOpenUsagesList,
      handleOpenCitationForm,
      handleOpenSourceCitation,
      isSourceCitationDisabled,
      handleOpenPublicationOriginalTranslatesRelations,
      isPublicationOriginalTranslatesRelationsDisabled,
      handleTransform,
      isTransformButtonDisabled,
      handleSendToLibrary,
      getLinks,
      handleDownloadPublicationFile,
      isDownloadPublicationFileDisabled,
      getReports,
      handleSetCurrentReport,
    ],
  );

  return {
    SUCH_LIKE_PUBLICATION_ID,
    isOpenHelpForm,
    setIsOpenHelpForm,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    publication,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    handleContinueStep,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    isEditRelationsDisabled,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isDoiPopupOpened,
    handleCloseDoiPopup,
    submitDoiPopupCallback,
    isApprovePopupOpen,
    onApprove,
    handleApprovePopupClose,
    isCitationFormOpen,
    handleCloseCitationForm,
    isPublicationOriginalTranslatesRelationsDisabled,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isPrepreintRelationOpen,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    handleClosePreprintRelation,
    buttons,
    isReportOpen,
    onReportClose,
    currentReport,
    isTransformOpen,
    handleTransform,
    setIsTransformOpen,
    isUsagesListOpen,
    handleCloseUsagesList,
    settings,
    selectedRow,
    isParticipationModalOpen,
    setIsParticipationModalOpen,
    submitParticipationModal,
  };
}

export enum NirTenderType {
  LMU = 'LMU',
  ONG = 'ONG',
  ML = 'ML',
  IG = 'IG',
  PD = 'PD',
  PP = 'PP',
}

export const NirTenderTypeShortName: Record<NirTenderType, string> = {
  LMU: 'ЛМУ',
  ONG: 'ОНГ',
  ML: 'МЛ',
  IG: 'ИГ',
  PD: 'ПД',
  PP: 'ПП',
};

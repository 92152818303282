import React, { useMemo, useCallback } from 'react';

import { Select } from 'components';

import { Item } from 'types/models/common';

type Props = {
  value: string;
  updateValue: (nextValue: string) => void;
};

const allOption: Item = { label: 'Все', value: 'all' };

export const BooleanFilter = ({ value, updateValue }: Props) => {
  const options = useMemo<Item[]>(() => [allOption, { label: 'Да', value: 'true' }, { label: 'Нет', value: 'false' }], []);

  const onValueUpdate = useCallback(
    (option: Item | null) => {
      const optionValue = option?.value === allOption.value ? '' : option?.value ?? '';
      updateValue(optionValue);
    },
    [updateValue],
  );

  const currentOption = useMemo<Item>(() => options.find(({ value: optionValue }) => optionValue === value) ?? allOption, [
    options,
    value,
  ]);

  return <Select value={currentOption} options={options} onChange={onValueUpdate} />;
};

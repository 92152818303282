import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetMobileRequestList, GetNotificationLogList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';
import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { NotificationEventModule } from 'utils/Enums';

export function MobileRequests() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['requests', 'mobile-requests'],
    init: ['list'],
  });

  const { userPermission } = useAppDataContext();

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab
          title="Список заявок по академической мобильности"
          id="list"
          isVisible={isHasPermission(userPermission, Permits.MOBILE_REQUEST_VIEW)}
        >
          <DataGrid specification={GetMobileRequestList({ type: 'NPR' })} />
        </Tab>
        <Tab
          title="Журнал уведомлений"
          id="notifications"
          isVisible={isHasPermission(userPermission, Permits.NOTIFICATION_ADMINISTRATE)}
        >
          <DataGrid specification={GetNotificationLogList({ module: NotificationEventModule.MOBILE_REQUEST })} />
        </Tab>
      </Tabs>
    </Content>
  );
}

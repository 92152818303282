import { useCallback } from 'react';

import {
  FormComponent,
  ListEdit,
  RadioItem,
  SectionTitle,
  Tab,
  Tabs,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  UploadFile,
} from 'components';

import { Project } from 'types/models';
import { ProjectReportApprovementResolution, ProjectReportBlock } from 'utils/Enums';
import { StageFields } from '../../project/ProjectForm/Tabs/CalendarPlan/Fields';
import { getMockApprovement } from '../../project/ProjectForm/helpers/getMockModels';
import { validate } from './Fields/validate';
import { showErrorsMessages } from 'utils/Common';
import { Fields } from './Fields/index';

type Props = {
  project?: Project.Project;
  setProject?: React.Dispatch<React.SetStateAction<Project.Project>>;
  report: Project.Report | null;
  setReport: React.Dispatch<React.SetStateAction<Project.Report | null>>;
  disabled: boolean;
};

export const approvementResolutions: RadioItem[] = [
  { label: 'Согласовано', value: ProjectReportApprovementResolution.AGREE },
  { label: 'На доработку', value: ProjectReportApprovementResolution.REVISION },
];

export function ProjectReportFields({ project, setProject, report, setReport, disabled }: Props) {
  const setStage = useCallback<React.Dispatch<React.SetStateAction<Project.Stage | null>>>(
    (s: React.SetStateAction<Project.Stage | null>) => {
      if (setProject) {
        setProject(prev => ({
          ...prev,
          stages: (() => {
            const newStages = [...prev.stages];
            const stageIndex = newStages.findIndex(x => x.number === report?.stage?.number);
            if (stageIndex === -1) {
              return prev.stages;
            }
            const newStage = typeof s === 'function' ? s(newStages[stageIndex]) : s;
            if (newStage) {
              newStages[stageIndex] = newStage;
            } else {
              newStages.splice(stageIndex);
            }

            return newStages;
          })(),
        }));
      }
      setReport(prev => ({ ...prev!, stage: typeof s === 'function' ? s(prev!.stage!) : s }));
    },
    [report?.stage?.number, setProject, setReport],
  );

  return (
    <Tabs>
      <Tab title="Описание отчета">
        <FormComponent.Line>
          <FormComponent.Field label="Название отчёта">
            <TextInput value={report?.name} onChange={e => setReport(prev => ({ ...prev!, name: e }))} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Реферат">
            <TextArea
              mode={TextAreaMode.MODAL}
              value={report?.referat}
              onChange={e => setReport(prev => ({ ...prev!, referat: e }))}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Ключевые слова">
            <TextInput
              value={report?.keyWords}
              onChange={e => setReport(prev => ({ ...prev!, keyWords: e }))}
              isDisabled={disabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Тип отчёта">{report?.type ? report.type.label : <em>Не выбран</em>}</FormComponent.Field>
          <FormComponent.Field label="Требования СМК">
            {[
              project?.isRequiredStateRegistration ? 'Обязательная госрегистрация проекта' : '',
              project?.isRequiredMilitaryStandard ? 'Требования по ГОСТу РВ' : '',
            ]
              .filter(Boolean)
              .join('; ')}
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="УДК">{project?.udks.map(x => x.element?.label).join('; ')}</FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Файл отчёта">
            <UploadFile file={report?.file} onChange={e => setReport(prev => ({ ...prev!, file: e }))} isDisabled={disabled} />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Отчёт Согласован" />

        <ListEdit<{}, Project.Approvement>
          rows={report?.approvements || []}
          onChange={approvements => setReport(prev => ({ ...prev!, approvements }))}
          toolbar={['add', 'edit', 'delete']}
          columns={[
            { label: 'Согласовано', formatValue: x => x.expertGroup?.label || <em>Не указано</em>, styles: { width: '20%' } },
            { label: 'Дата', formatValue: x => x.date, styles: { width: '20%' } },
            {
              label: 'Виза',
              formatValue: x => approvementResolutions.find(i => i.value === x.resolution?.value)?.label || <em>Не указано</em>,
              styles: { width: '30%' },
            },
            { label: 'Замечания', formatValue: x => x.note || <em>Не указаны</em>, styles: { width: '30%' } },
          ]}
          withMessages
          isDeleteConfirmEnabled
          isDisabled={disabled}
          defaultRowsCount={5}
          specification={{
            mode: 'customModalComponent',
            modalTitle: 'Отметка о согласовании отчёта',
            renderComponent: (approvement, setApprovement) => (
              <Fields approvement={approvement ?? getMockApprovement()} setApprovement={setApprovement} />
            ),
            validation: {
              checkIsValid: approvement => validate(approvement).every(x => x.isValid),
              onInvalidate: approvement => {
                const validationInfo = validate(approvement);
                showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
              },
            },
          }}
        />

        <FormComponent.Line>
          <>
            <SectionTitle title="Регистрация в архиве" />

            <FormComponent.Line>
              <FormComponent.Field label="Сдан в архив">
                <TextDateTime
                  value={report?.archiveSendDate}
                  onChange={e => setReport(prev => ({ ...prev!, archiveSendDate: e }))}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Дата регистрации в архиве">
                <TextDateTime
                  value={report?.archiveAcceptedDate}
                  onChange={e => setReport(prev => ({ ...prev!, archiveAcceptedDate: e }))}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Инв. номер отчёта в архиве">
                <TextInput
                  value={report?.archiveNumber}
                  onChange={e => setReport(prev => ({ ...prev!, archiveNumber: e }))}
                  isDisabled={disabled}
                />
              </FormComponent.Field>
            </FormComponent.Line>
          </>

          <>
            {report?.block?.value === ProjectReportBlock.STAGE ? (
              <>
                <SectionTitle title="Отправлен заказчику" />

                <FormComponent.Line>
                  <FormComponent.Field label="Отправлен заказчику">
                    <TextDateTime
                      value={report?.customerDate}
                      onChange={e => setReport(prev => ({ ...prev!, customerDate: e }))}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </>
            ) : (
              <>
                <SectionTitle title="Госрегистрация отчёта" />

                <FormComponent.Line>
                  <FormComponent.Field label="Отправлен для госрегистрации">
                    <TextDateTime
                      value={report?.sendDate}
                      onChange={e => setReport(prev => ({ ...prev!, sendDate: e }))}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Дата госрегистрации">
                    <TextDateTime
                      value={report?.acceptedDate}
                      onChange={e => setReport(prev => ({ ...prev!, acceptedDate: e }))}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Номер госрег. отчёта (ИК)">
                    <TextInput
                      value={report?.number}
                      onChange={e => setReport(prev => ({ ...prev!, number: e }))}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </>
            )}
          </>
        </FormComponent.Line>
      </Tab>
      <Tab title="Этап проекта" isDisabled={!report?.stage}>
        <StageFields
          project={project}
          stage={report?.stage || null}
          setStage={setStage}
          isReportForm={true}
          disabled={disabled}
        />
      </Tab>
    </Tabs>
  );
}

import React, { memo } from 'react';

import { ButtonMode, Modal } from 'components';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
};

const DocumentsWarningPopup = ({ isOpen, onClose, onConfirm }: Props) => (
  <Modal
    mode="info"
    title="Внимание"
    isOpen={isOpen}
    onClose={onClose}
    actions={[
      {
        mode: ButtonMode.PRIMARY,
        text: 'Сохранить в статусе Добавлена',
        onClick: onConfirm,
      },
      {
        mode: ButtonMode.SECONDARY,
        text: 'Добавить документ',
        onClick: onClose,
      },
    ]}
    size="small"
  >
    <>Без подтверждающего документа невозможно утвердить мероприятие.</>
  </Modal>
);

const Component = memo(DocumentsWarningPopup);

export { Component as DocumentsWarningPopup };

import { useMemo, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { FileInfo, Table } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { downloadFile, getAuthToken } from 'utils/Helpers';
import { ProjectAssignmentStatus } from 'types/models/Project';
import { State } from '../makeUseCustomController';

type Props = {
  tableState: Table.State;
  customState: State;
};

const useController = ({ customState, tableState }: Props) => {
  const tableStreams = useLocalTableStreams();

  const {
    setIsOpenMessageModal,
    newStatus,
    setNewStatus,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteWarningOpen,
    setIsDeleteWarningOpen,
    isViewFormOpen,
    setIsViewFormOpen,
    isHelpFormOpen,
    setIsHelpFormOpen,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    disabled,
  } = customState;

  const { methods: changeProjectAssignmentStatus } = BackendAPI.useBackendAPI('ChangeProjectAssignmentStatus');

  const { methods: saveProjectAssignmentMessage } = BackendAPI.useBackendAPI('SaveProjectAssignmentMessage');

  const row = useMemo<Table.Entry | null>(() => tableState.selectedRows[0] ?? null, [tableState.selectedRows]);

  const onAddClick = useCallback(() => {
    setIsAddFormOpen(true);
  }, [setIsAddFormOpen]);

  const onCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, [setIsAddFormOpen]);

  const onViewClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, [setIsViewFormOpen]);

  const onCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, [setIsViewFormOpen]);

  const onEditClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, [setIsEditFormOpen]);

  const onCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, [setIsEditFormOpen]);

  const onDeleteClick = useCallback(() => {
    setIsDeleteWarningOpen(true);
  }, [setIsDeleteWarningOpen]);

  const onChangeStatusInActionAssignmentClick = useCallback(() => {
    setNewStatus(ProjectAssignmentStatus.IN_ACTION);
    setIsOpenMessageModal(true);
  }, [setNewStatus, setIsOpenMessageModal]);

  const onChangeStatusCompleteAssignmentClick = useCallback(() => {
    setNewStatus(ProjectAssignmentStatus.COMPLETE);
    setIsOpenMessageModal(true);
  }, [setNewStatus, setIsOpenMessageModal]);

  const onChangeStatusClosedAssignmentClick = useCallback(() => {
    setNewStatus(ProjectAssignmentStatus.CLOSED);
    setIsOpenMessageModal(true);
  }, [setNewStatus, setIsOpenMessageModal]);

  const onChangeStatusCanceledAssignmentClick = useCallback(() => {
    setNewStatus(ProjectAssignmentStatus.CANCELED);
    setIsOpenMessageModal(true);
  }, [setNewStatus, setIsOpenMessageModal]);

  const onMessageAssignmentClick = useCallback(() => {
    setNewStatus('MESSAGE');
    setIsOpenMessageModal(true);
  }, [setNewStatus, setIsOpenMessageModal]);

  const onCloseDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
  }, [setIsDeleteWarningOpen]);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, [setIsHelpFormOpen]);
  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, [setIsHelpFormOpen]);

  const handleSaveStatusClick = useCallback(
    (message: string) => {
      changeProjectAssignmentStatus.callAPI(
        { status: newStatus, message: message || '', ids: tableState.selectedRows.map(r => r.id) },
        {
          onSuccessfullCall: () => {
            setNewStatus('');
            setIsOpenMessageModal(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [changeProjectAssignmentStatus, tableStreams, newStatus, tableState, setNewStatus, setIsOpenMessageModal],
  );

  const handleSendMessageClick = useCallback(
    (message: string, file: FileInfo) => {
      saveProjectAssignmentMessage.callAPI(
        { message, assignmentId: row?.id || '', file },
        {
          onSuccessfullCall: () => {
            setNewStatus('');
            setIsOpenMessageModal(false);
            tableStreams.reloadTable.push({});
          },
        },
      );
    },
    [saveProjectAssignmentMessage, row, tableStreams, setNewStatus, setIsOpenMessageModal],
  );

  const onMessageHistoryClick = useCallback(() => {
    setIsMessageHistoryModalOpen(true);
  }, [setIsMessageHistoryModalOpen]);

  const onSubmitDeleteWarning = useCallback(() => {
    setIsDeleteWarningOpen(false);
    if (row?.id) {
      tableStreams.deleteRow.push({
        deleteRowId: row.id,
        command: 'DeleteProjectAssignment',
        deletedItemPropName: 'ProjectAssignment',
      });
    }
  }, [row?.id, tableStreams.deleteRow, setIsDeleteWarningOpen]);

  const token = getAuthToken();

  const isEditButtonDisabled = useMemo(() => {
    return (
      disabled ||
      !row ||
      (row['id:Status'] !== ProjectAssignmentStatus.ADDED && row['id:Status'] != ProjectAssignmentStatus.IN_ACTION)
    );
  }, [row, disabled]);

  const isDeleteButtonDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isAddButtonDisabled = useMemo(() => disabled, [disabled]);
  const isViewButtonDisabled = useMemo(() => tableState.selectedRows.length !== 1, [tableState.selectedRows.length]);
  const isChangeStatusInActionDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isChangeStatusCompleteDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isChangeStatusClosedDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isChangeStatusCanceledDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isMessageButtonDisabled = useMemo(() => disabled || tableState.selectedRows.length !== 1, [
    disabled,
    tableState.selectedRows.length,
  ]);
  const isMessageHistoryButtonDisabled = useMemo(() => tableState.selectedRows.length !== 1, [tableState.selectedRows.length]);
  const isDownloadButtonDisabled = useMemo(() => tableState.selectedRows.length !== 1, [tableState.selectedRows.length]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: onViewClick,
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: onAddClick,
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: onEditClick,
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: onDeleteClick,
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'clock' },
        title: 'В работе',
        onClick: onChangeStatusInActionAssignmentClick,
        isDisabled: isChangeStatusInActionDisabled,
      },
      {
        icon: { type: 'check' },
        title: 'Готово',
        onClick: onChangeStatusCompleteAssignmentClick,
        isDisabled: isChangeStatusCompleteDisabled,
      },
      {
        icon: { type: 'cancel' },
        title: 'Закрыть поручение',
        onClick: onChangeStatusClosedAssignmentClick,
        isDisabled: isChangeStatusClosedDisabled,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отменить поручение',
        onClick: onChangeStatusCanceledAssignmentClick,
        isDisabled: isChangeStatusCanceledDisabled,
      },
      {
        icon: { type: 'message', mode: 'add' },
        title: 'Добавить сообщение',
        onClick: onMessageAssignmentClick,
        isDisabled: isMessageButtonDisabled,
      },
      {
        icon: { type: 'messages' },
        title: 'Журнал сообщений',
        onClick: onMessageHistoryClick,
        isDisabled: isMessageHistoryButtonDisabled,
      },
      {
        icon: { type: 'download' },
        title: 'Скачать файл',
        code: 'downloadFile',
        onClick: () => downloadFile(row?.FileId, token),
        isDisabled: isDownloadButtonDisabled,
      },
    ],
    [
      isEditButtonDisabled,
      isDeleteButtonDisabled,
      isViewButtonDisabled,
      isAddButtonDisabled,
      onViewClick,
      onAddClick,
      onEditClick,
      onDeleteClick,
      handleHelpButtonClick,
      isChangeStatusInActionDisabled,
      onChangeStatusInActionAssignmentClick,
      isChangeStatusCompleteDisabled,
      onChangeStatusCompleteAssignmentClick,
      isChangeStatusClosedDisabled,
      onChangeStatusClosedAssignmentClick,
      isChangeStatusCanceledDisabled,
      onChangeStatusCanceledAssignmentClick,
      isMessageButtonDisabled,
      onMessageAssignmentClick,
      isDownloadButtonDisabled,
      isMessageHistoryButtonDisabled,
      onMessageHistoryClick,
      row,
      token,
    ],
  );

  return {
    buttons,
    isAddFormOpen,
    isEditFormOpen,
    isDeleteWarningOpen,
    isViewFormOpen,
    onViewClick,
    onCloseViewForm,
    onCloseAddForm,
    onCloseEditForm,
    onCloseDeleteWarning,
    onSubmitDeleteWarning,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    handleHelpButtonClick,
    handleSaveStatusClick,
    handleSendMessageClick,
    newStatus,
    isMessageHistoryModalOpen,
    setIsMessageHistoryModalOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    setIsDeleteWarningOpen,
    setIsViewFormOpen,
    setIsHelpFormOpen,
  };
};

export default useController;

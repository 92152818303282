import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistRequestAndProjectList } from 'features/Table/specifications';

type Props = {
  personId?: string;
};

export function RequestAndProjects(props: Props) {
  const { personId } = props;

  if (!personId) {
    return null;
  }

  return <DataGrid specification={GetScientistRequestAndProjectList({ personId })} />;
}

import { useCallback, useEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Notification, Table } from 'types/models';
import { Props as NotificationProps } from './';
import { SubmitTable } from 'features/Table/streams';
import { NotificationDispatchMode, NotificationEvent, PersonContactType, PersonModule } from 'utils/Enums';
import { getPersonContacts } from 'utils/Helpers';
import { showNotification } from 'features/Notifications';

type Props = {} & NotificationProps;

export function useController({ tender, onClose }: Props) {
  const defaultNotification: Notification.RecipientPreset = {
    tender: tender ? { ...tender } : null,
    name: '',
    text: '',
    recipients: [],
  };

  const { methods: getScientistDataAPI } = BackendAPI.useBackendAPI('GetScientistData');
  const { methods: saveNotificationRecipientPresetAPI } = BackendAPI.useBackendAPI('SaveNotificationRecipientPreset');
  const { methods: getNotificationRecipientPresetAPI } = BackendAPI.useBackendAPI('GetNotificationRecipientPreset');
  const { methods: dispatchNotificationAPI } = BackendAPI.useBackendAPI('DispatchNotification');
  const { methods: addScientistContactAPI } = BackendAPI.useBackendAPI('AddScientistContact');

  const [preset, setPreset] = useState<Notification.RecipientPreset | null>(null);
  const [isOpenPersonListModal, setIsOpenPersonListModal] = useState<boolean>(false);
  const [isOpenRecipientListModal, setIsOpenRecipientListModal] = useState<boolean>(false);
  const [isOpenRecipientMailModal, setIsOpenRecipientMailModal] = useState<boolean>(false);
  const [isOpenPresetListModal, setIsOpenPresetListModal] = useState<boolean>(false);
  const [isNotificationNameModalOpen, setIsNotificationNameModalOpen] = useState<boolean>(false);
  const [isNotificationSaveModalOpen, setIsNotificationSaveModalOpen] = useState<boolean>(false);
  const [selectedRecipient, setSelectedRecipient] = useState<Notification.RecipientItem | null>(null);

  const { methods: getTender } = BackendAPI.useBackendAPI('GetTender', {
    onSuccessfullCall: ({ data }) => {
      setPreset(
        prev =>
          ({
            ...prev,
            tender: {
              id: data.id,
              name: data.name,
            } as Notification.Tender,
          } as Notification.RecipientPreset),
      );
    },
  });

  const clearNamePreset = useCallback(() => {
    setPreset(
      prev =>
        ({
          ...prev,
          name: '',
        } as Notification.RecipientPreset),
    );
  }, []);

  const changeTenderObject = useCallback(
    (value: Notification.Tender | null) => {
      if (value?.id) {
        getTender.callAPI({ id: value.id });
      } else {
        setPreset(
          prev =>
            ({
              ...prev,
              tender: null,
            } as Notification.RecipientPreset),
        );
      }
    },
    [getTender],
  );

  const tenderModalTableRowConverter = useCallback((row: Table.Entry): Notification.Tender => {
    return {
      id: row.id || '',
      name: row.TenderName,
    };
  }, []);

  const setText = useCallback((value: string) => {
    setPreset(
      prev =>
        ({
          ...prev,
          text: value,
        } as Notification.RecipientPreset),
    );
  }, []);

  const setName = useCallback((value: string) => {
    setPreset(
      prev =>
        ({
          ...prev,
          name: value,
        } as Notification.RecipientPreset),
    );
  }, []);

  const setMailRecipient = useCallback(() => {
    addScientistContactAPI.callAPI(
      {
        id: selectedRecipient?.id || '',
        type: PersonContactType.EMAIL,
        value: selectedRecipient?.email || '',
      },
      {
        onSuccessfullCall: () => {
          setPreset(
            prev =>
              ({
                ...prev,
                recipients: [...(preset?.recipients || [])].map(i => {
                  if (selectedRecipient?.id === i.id) {
                    return { ...selectedRecipient };
                  }
                  return i;
                }),
              } as Notification.RecipientPreset),
          );
          showNotification({ message: 'Контакт успешно обновлен', theme: 'success' });
        },
      },
    );
  }, [addScientistContactAPI, preset?.recipients, selectedRecipient]);

  const addScientists = useCallback(
    (scientists: Notification.RecipientItem[]) => {
      const result: Notification.RecipientItem[] = [...(preset?.recipients || [])];

      scientists.forEach(i => {
        if (result.find(n => n.id === i.id)) {
          return;
        }
        result.push((i as unknown) as Notification.RecipientItem);
      });

      setPreset(
        prev =>
          ({
            ...prev,
            recipients: result,
          } as Notification.RecipientPreset),
      );
    },
    [preset?.recipients],
  );

  const onSubmit = useCallback(
    (dispatchMode: NotificationDispatchMode) => {
      dispatchNotificationAPI.callAPI(
        {
          dispatchMode,
          eventType: NotificationEvent.TENDER_NOTICED,
          eventId: preset?.tender?.id ?? '',
          text: preset?.text ?? '',
          recipients: preset?.recipients ?? [],
        },
        {
          onSuccessfullCall: ({ data }) => {
            addScientists([
              {
                id: data.id,
                fio: data.fullName,
                email: data.scientist?.notificationEmail || getPersonContacts(data.contacts, 'emails') || '',
              },
            ] as Notification.RecipientItem[]);
          },
        },
      );
      onClose();
    },
    [addScientists, dispatchNotificationAPI, onClose, preset?.recipients, preset?.tender?.id, preset?.text],
  );

  const onSubmitScientist = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      getScientistDataAPI.callAPI(
        { personId: row.id, modules: [PersonModule.GENERAL] },
        {
          onSuccessfullCall: ({ data }) => {
            addScientists([
              {
                id: data.id,
                fio: data.fullName,
                email: data.scientist?.notificationEmail || getPersonContacts(data.contacts, 'emails') || '',
              },
            ] as Notification.RecipientItem[]);
          },
        },
      );

      setIsOpenPersonListModal(false);
    },
    [addScientists, getScientistDataAPI],
  );

  const onSubmitScientists = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      getNotificationRecipientPresetAPI.callAPI(
        { id: row.id },
        {
          onSuccessfullCall: ({ data }) => {
            addScientists((data as unknown) as Notification.RecipientItem[]);
          },
        },
      );

      setIsOpenRecipientListModal(false);
    },
    [addScientists, getNotificationRecipientPresetAPI],
  );

  const onChange = useCallback((recipients: Notification.RecipientItem[]) => {
    setPreset(
      prev =>
        ({
          ...prev,
          recipients,
        } as Notification.RecipientPreset),
    );
  }, []);

  const onClear = useCallback(() => {
    setPreset(
      prev =>
        ({
          ...prev,
          recipients: [],
        } as Notification.RecipientPreset),
    );
  }, []);

  const onSavePreset = useCallback(
    ({ id, name }: { id?: string; name: string }) => {
      saveNotificationRecipientPresetAPI.callAPI(
        { id: id || '', preset: { ...preset, name: name } as Notification.RecipientPreset },
        {
          onSuccessfullCall: () => {
            clearNamePreset();
          },
        },
      );
    },
    [clearNamePreset, preset, saveNotificationRecipientPresetAPI],
  );

  const onSubmitPreset = useCallback(
    ({ selectedRows: [row] }: SubmitTable) => {
      setIsOpenPresetListModal(false);

      onSavePreset({ id: row.id, name: row.Name });
    },
    [onSavePreset],
  );

  useEffect(() => {
    setPreset({ ...defaultNotification });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    clearNamePreset,
    preset,
    setName,
    setText,
    changeTenderObject,
    tenderModalTableRowConverter,
    onSubmit,
    isOpenPersonListModal,
    setIsOpenPersonListModal,
    onSubmitScientist,
    isOpenRecipientListModal,
    setIsOpenRecipientListModal,
    selectedRecipient,
    setSelectedRecipient,
    setMailRecipient,
    isOpenRecipientMailModal,
    setIsOpenRecipientMailModal,
    onSubmitScientists,
    isNotificationSaveModalOpen,
    setIsNotificationSaveModalOpen,
    isNotificationNameModalOpen,
    setIsNotificationNameModalOpen,
    isOpenPresetListModal,
    setIsOpenPresetListModal,
    onSubmitPreset,
    onChange,
    onClear,
    onSavePreset,
  };
}

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistParticipationList } from 'features/Table/specifications';

type Props = {
  personId?: string;
  isViewMode: boolean;
};
export function ParticipationsEvents({ personId, isViewMode }: Props) {
  if (!personId) return null;
  return <DataGrid specification={GetScientistParticipationList({ personId, isViewMode })} hasPersonalMode />;
}

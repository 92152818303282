import React, { useCallback, useState } from 'react';

import { ButtonMode, FormComponent, Modal, Select, SelectMode, TextArea, TextAreaMode } from 'components';

import { Form } from 'features/Form';
import { Form as F, Table } from 'types/models';
import { Item } from 'types/models/common';
import { GetPersonalProjectList } from 'features/Table/specifications/GetPersonalProjectList';
import { GetFullScienceProjectList } from 'features/Table/specifications/GetFullScienceProjectList';

const PurchaseRequestAddTemplate: React.FC<F.TemplateProps & { hasPersonalMode?: boolean; project?: Item | null }> = ({
  isOpen,
  onClose,
  hasPersonalMode,
  project: initialProject,
}: F.TemplateProps & { hasPersonalMode?: boolean; project?: Item | null }) => {
  const [title, setTitle] = useState<string | JSX.Element>('');
  const [isFormOpen, setIsFormOpen] = useState<boolean>(false);
  const [contractKind, setContractKind] = useState<Item | null>(null);
  const [project, setProject] = useState<Item | null>(initialProject || null);

  const look: F.PurchaseRequestFormLook = {
    apiID: 'GetPurchaseRequest',
    template: 'PurchaseRequestForm',
    setTitle,
    contractKind: contractKind,
    projectId: project?.value,
  };

  const handleClose = useCallback(() => {
    onClose();
    setContractKind(null);
    setProject(null);
    setIsFormOpen(false);
  }, [onClose]);

  return isFormOpen ? (
    <Modal isOpen={isOpen} onClose={handleClose} title={title} size="full">
      <Form look={look} onClose={handleClose} />
    </Modal>
  ) : (
    <Modal
      title="Создание заявки на закупку"
      isOpen={isOpen}
      onClose={handleClose}
      actions={[
        {
          mode: ButtonMode.PRIMARY,
          text: 'Продолжить',
          onClick: () => setIsFormOpen(true),
          isDisabled: !project || !contractKind,
        },
        {
          mode: ButtonMode.SECONDARY,
          text: 'Отмена',
          onClick: handleClose,
          isDisabled: !project || !contractKind,
        },
      ]}
      size="medium"
    >
      <FormComponent.Line>
        <FormComponent.Field label="Вид договора" isRequired>
          <Select
            mode={SelectMode.ENUM}
            settings={{ name: 'PurchaseRequestContractKind' }}
            value={contractKind}
            onChange={(e: Item | null) => setContractKind(e)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Источник финансирования (средства проекта)" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            value={project?.label || ''}
            isDisabled={!!initialProject}
            placeholder="Выберите проект из списка"
            settings={{
              title: 'Научные проекты',
              isClearable: false,
              rows: 5,
              table: {
                specification: hasPersonalMode
                  ? GetPersonalProjectList({
                      hasSelectButton: true,
                      hasOnlyLeaders: true,
                    })
                  : GetFullScienceProjectList({}),
                onSelect: (row: Table.Entry | null) =>
                  setProject(
                    row
                      ? {
                          value: row.id,
                          label: [row.projectNumber || row.ProjectNumber, row.name || row.Name].filter(Boolean).join(', '),
                        }
                      : null,
                  ),
              },
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </Modal>
  );
};

export const Component = React.memo(PurchaseRequestAddTemplate);

import React from 'react';

import { SecurityDocument } from 'types/models/SecurityDocument';
import { FormComponent } from 'components';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';
import { RefElement } from 'types/models/Project';

import { ClassifierEdit } from './ClassifierEdit';

type Props = {
  securityDocument?: SecurityDocument | null;
  makeChangeHandler: (key: keyof SecurityDocument) => (val: ValueOf<SecurityDocument>) => void;
  disabled?: boolean;
};

export function Classifiers({ securityDocument, makeChangeHandler, disabled }: Props) {
  const { settings } = useAppDataContext();

  return (
    <FormComponent.Wrapper>
      <FormComponent.ColumnWrapper>
        <FormComponent.Column>
          <ClassifierEdit
            title="Классификатор ОЭСР (ОЕСD)"
            refName="RefOecd"
            rows={securityDocument?.oecds ?? []}
            onChange={makeChangeHandler('oecds')}
            modalTitle='Справочник "Классификатор ОЭСР (ОЕСD)"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Сквозные технологии Национальной технической инициативы"
            refName="RefNtiEteTechnology"
            rows={securityDocument?.ntiEteTechnologies ?? []}
            onChange={makeChangeHandler('ntiEteTechnologies')}
            modalTitle='Справочник "Сквозные технологий Национальной технологической инициативы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ГРНТИ"
            refName="RefGrnti"
            rows={securityDocument?.grntis ?? []}
            onChange={makeChangeHandler('grntis')}
            modalTitle='Справочник "ГРНТИ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="УДК"
            refName="RefUdk"
            rows={securityDocument?.udks ?? []}
            onChange={makeChangeHandler('udks')}
            modalTitle='Справочник "УДК"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ОКВЭД"
            refName="RefOkved2"
            rows={securityDocument?.okveds ?? []}
            onChange={makeChangeHandler('okveds')}
            modalTitle='Справочник "ОКВЭД"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="ЛКСЭЦ"
            refName="RefLksets"
            rows={securityDocument?.lksetss ?? []}
            onChange={makeChangeHandler('lksetss')}
            modalTitle='Справочник "ЛКСЭЦ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Отрасли наук"
            refName="RefScienceBrunch"
            rows={securityDocument?.scienceBrunches ?? []}
            onChange={makeChangeHandler('scienceBrunches')}
            modalTitle='Справочник "Отрасли науки"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Области научных интересов"
            refName="RefScienceDomainInterest"
            rows={securityDocument?.scienceDomainInterrests ?? []}
            onChange={makeChangeHandler('scienceDomainInterrests')}
            modalTitle='Справочник "Области научных интересов"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Номенклатура научных специальностей"
            refName="RefVakSpeciality"
            rows={securityDocument?.vakSpecialities ?? []}
            onChange={makeChangeHandler('vakSpecialities')}
            modalTitle='Справочник "Номенклатура научных специальностей"'
            disabled={disabled}
          />
        </FormComponent.Column>
        <FormComponent.Column>
          <ClassifierEdit
            title="Рынки национальной технологической инициативы"
            refName="RefNtiMarket"
            rows={securityDocument?.ntiMarkets ?? []}
            onChange={makeChangeHandler('ntiMarkets')}
            modalTitle='Справочник "Рынки Национальной технологической инициативы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title={`Приоритетные направления ${settings?.organization?.shortName}`}
            refName="RefPnr"
            rows={securityDocument?.pnrs ?? []}
            onChange={makeChangeHandler('pnrs')}
            modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
            disabled={disabled}
          />
          <ClassifierEdit
            title="Приоритетные направления развития модернизации и технического развития экономики России"
            refName="RefPnmitr"
            rows={securityDocument?.pnmitrs ?? []}
            onChange={makeChangeHandler('pnmitrs')}
            modalTitle='Справочник "Приоритетные направления развития модернизации и технического развития экономики России"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Приоритетные направления развития науки, технологий и техники"
            refName="RefPni"
            rows={securityDocument?.pnis ?? []}
            onChange={makeChangeHandler('pnis')}
            modalTitle='Справочник "Приоритетные нпправления науки, технологий и техники"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Приоритеты Стратегии НТР РФ"
            refName="RefNtrStrategy"
            rows={securityDocument?.ntrStrategies ?? []}
            onChange={makeChangeHandler('ntrStrategies')}
            modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Технологические платформы"
            refName="RefTechnologyPlatform"
            rows={securityDocument?.technologyPlatforms ?? []}
            onChange={makeChangeHandler('technologyPlatforms')}
            modalTitle='Справочник "Технологические платформы"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Критические технологии"
            refName="RefPriorityTechnology"
            rows={securityDocument?.priorityTechnologies ?? []}
            onChange={makeChangeHandler('priorityTechnologies')}
            modalTitle='Справочник "Критические технологии"'
            disabled={disabled}
          />
          <ClassifierEdit
            title="Отрасли знаний (РНФ)"
            refName="RefKnowledgeBranch"
            rows={securityDocument?.knowledgeBranches ?? []}
            onChange={makeChangeHandler('knowledgeBranches')}
            modalTitle='Справочник "Отрасли знаний (РНФ)"'
            disabled={disabled}
            columns={[
              {
                label: 'Отрасль знаний',
                formatValue: x => x.element?.label || '',
                styles: { width: '35%' },
              },
              {
                label: 'Группа (подгруппа)',
                formatValue: x => (x.element ? getKnowledgeBranchGroupLabel(x.element) : ''),
                styles: { width: '35%' },
              },
              {
                label: 'Источник',
                styles: { width: '15%' },
                formatValue: (x: RefElement) => (x?.isProject ? 'Проект' : 'Охранный документ'),
              },
              {
                label: 'Актуальный',
                styles: { width: '15%' },
                formatValue: x => x?.isActual,
              },
            ]}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </FormComponent.Wrapper>
  );
}

import { Table as T } from 'types/models';

type Arguments = {
  personId: string;
  hasSelectButton?: boolean;
};

type RequestData = {
  scientistId: string;
};

export function GetScientistJobs({ personId, hasSelectButton }: Arguments): T.Specification<object, RequestData> {
  return {
    apiID: 'GetScientistJobs',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: hasSelectButton,
      },
    },
    requestData: { scientistId: personId },
  };
}

import React from 'react';

import { FormComponent, TextDateTime, TextInput, UploadFile } from 'components';

import { SecurityDocumentContract, Table as T } from 'types/models';
import { useController } from './controller';

type Props = {
  entry: SecurityDocumentContract.Payment | null;
  onFieldChange(row: SecurityDocumentContract.Payment): void;
  workMode: T.WorkMode;
};

function PaymentFields({ entry, onFieldChange, workMode }: Props) {
  const { handleFileChange } = useController({
    entry,
    onFieldChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Column style={{ maxWidth: '420px' }}>
        <FormComponent.Field label="Номер документа">
          <TextInput value={entry?.receivingDocument?.cashDocument?.number || ''} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label="Дата">
          <TextDateTime value={entry?.receivingDocument?.cashDocument?.payDate} onChange={() => {}} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label="Сумма">
          <TextInput value={entry?.receivingDocument?.amount || ''} isDisabled />
        </FormComponent.Field>
        <FormComponent.Field label="В т.ч. НДС">
          <TextInput value={entry?.receivingDocument?.nds || ''} isDisabled />
        </FormComponent.Field>
      </FormComponent.Column>
      <FormComponent.Line>
        <FormComponent.Field label="Файл документа">
          <UploadFile file={entry?.file} onChange={handleFileChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { PaymentFields };

import React, { memo } from 'react';

import { FormComponent, Modal, Toolbar } from 'components';

import { useController } from './controller';
import { RestrictionList } from 'features/Form/looks/project/ProjectForm/Tabs/Restrictions/list';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { RestrictionProjecGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    isOpenRestrictions,
    onCloseRestrictions,
    onSaveRestrictions,
    project,
    setProject,
    isReportOpen,
    onReportClose,
    currentReport,
    isHelpOpen,
    setIsHelpOpen,
  } = useController({
    tableState,
  });

  return (
    <>
      <Toolbar buttons={buttons} />

      <Modal
        title="Ограничения для проекта"
        isOpen={isOpenRestrictions}
        onClose={onCloseRestrictions}
        size={['large', 'medium-height']}
      >
        <FormComponent.Template>
          <ControlPanel handleSave={onSaveRestrictions} />

          <FormComponent.Wrapper>
            <RestrictionList project={project} setProject={setProject} isDisabled={false} />
          </FormComponent.Wrapper>
        </FormComponent.Template>
      </Modal>

      <Modal
        mode="help"
        title="Проекты"
        isOpen={isHelpOpen}
        onClose={() => setIsHelpOpen(false)}
        size={['large', 'medium-height']}
      >
        {RestrictionProjecGridHelp()}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
      />
    </>
  );
}

export const Component = memo(LeftPanelForThirdLevel);

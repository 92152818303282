import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { showNotification } from 'features/Notifications';
import { ForeignSecurityDocumentStatus } from 'utils/Enums';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const tableStreams = useLocalTableStreams();
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const isApproved = useMemo(
    () => tableState.selectedRows[0] && tableState.selectedRows[0]['id:Status'] === ForeignSecurityDocumentStatus.APPROVED,
    [tableState],
  );

  const isAddButtonDisabled = !rowsAvailability.ALWAYS;
  const isViewButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isEditButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isDeleteButtonDisabled = !rowsAvailability.SINGLE_SELECTED;
  const isApproveButtonDisabled = !rowsAvailability.SINGLE_SELECTED || isApproved;
  const isDeclineButtonDisabled = !rowsAvailability.SINGLE_SELECTED || !isApproved;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');

  const handleCloseDeleteConfirmPopup = useCallback(() => {
    setIsDeleteConfirmPopupOpen(false);
  }, []);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    tableStreams.deleteRow.push({
      deleteRowId: tableState.selectedRows[0]?.id,
      command: 'DeleteEntity',
      deletedItemPropName: 'ForeignSecurityDocument',
    });
    handleCloseDeleteConfirmPopup();
  }, [handleCloseDeleteConfirmPopup, tableState, tableStreams.deleteRow]);

  const handleDeleteButtonClick = useCallback(() => {
    setIsDeleteConfirmPopupOpen(true);
  }, []);

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const { methods: approveForeignSecurityDocument } = BackendAPI.useBackendAPI('ApproveForeignSecurityDocument');

  const handleApproveButtonClick = useCallback(() => {
    approveForeignSecurityDocument.callAPI(
      { id: tableState.selectedRows[0]?.id, approve: true },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push({});
          showNotification({ message: 'Статус изменен', theme: 'success' });
        },
      },
    );
  }, [tableState, approveForeignSecurityDocument, tableStreams]);

  const handleDeclineButtonClick = useCallback(() => {
    approveForeignSecurityDocument.callAPI(
      { id: tableState.selectedRows[0]?.id, approve: false },
      {
        onSuccessfullCall: () => {
          tableStreams.reloadTable.push({});
          showNotification({ message: 'Статус изменен', theme: 'success' });
        },
      },
    );
  }, [tableState, approveForeignSecurityDocument, tableStreams]);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST },
        isDisabled: isViewButtonDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST },
        isDisabled: isAddButtonDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST },
        isDisabled: isEditButtonDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: handleDeleteButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_VIEW_FULL_LIST },
        isDisabled: isDeleteButtonDisabled,
      },
      {
        icon: { type: 'like' },
        title: 'Утвердить',
        onClick: handleApproveButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_APPROVE },
        isDisabled: isApproveButtonDisabled,
      },
      {
        icon: { type: 'dislike' },
        title: 'Отклонить',
        onClick: handleDeclineButtonClick,
        permission: { name: Permits.FOREIGN_SECURITY_DOCUMENT_APPROVE },
        isDisabled: isDeclineButtonDisabled,
      },
    ],
    [
      handleHelpButtonClick,
      isViewButtonDisabled,
      handleViewButtonClick,
      isAddButtonDisabled,
      handleAddButtonClick,
      isEditButtonDisabled,
      handleEditButtonClick,
      isApproveButtonDisabled,
      handleApproveButtonClick,
      isDeclineButtonDisabled,
      handleDeclineButtonClick,
      isDeleteButtonDisabled,
      handleDeleteButtonClick,
    ],
  );

  return {
    isAddButtonDisabled,
    isViewButtonDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditButtonDisabled,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    isDeleteButtonDisabled,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
  };
}

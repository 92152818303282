import { Dispatch, SetStateAction } from 'react';

import { Form } from 'types/models';
import { ElectronicType } from 'utils/Enums/ElectronicType';

type Props = {
  setFormFields: Dispatch<SetStateAction<Form.Fields>>;
  preparedData: Form.Publication;
  name: string | undefined;
};

const setByResponse = ({ setFormFields, preparedData, name }: Props) => {
  const { fields, file } = preparedData;

  setFormFields(prevState => ({
    ...prevState,
    name: {
      ...prevState.name,
      value: fields.name || name,
    },
    annotation: {
      ...prevState.annotation,
      value: fields.annotation,
    },
    authorsKeyWords: {
      ...prevState.authorsKeyWords,
      value: fields.authorsKeyWords,
    },
    authorsCount: {
      ...prevState.authorsCount,
      value: fields.authorsCount,
    },
    fractionalScore: {
      ...prevState.fractionalScore,
      value: fields.fractionalCount,
    },
    doi: {
      ...prevState.doi,
      value: fields.doi,
    },
    publicationLink: {
      ...prevState.publicationLink,
      value: fields.url,
      required: fields.isElectronic === 'true' && fields.electronicType === ElectronicType.WEB.code,
    },
    typeEdition: {
      ...prevState.typeEdition,
      value: fields.typeEditionCode,
    },
    textLanguage: {
      ...prevState.textLanguage,
      value: {
        id: fields.textLanguageId || '',
        label: fields.textLanguageLabel || '',
      },
    },
    printPageCount: {
      ...prevState.printPageCount,
      value: fields.printPageCount || undefined,
    },
    pageFrom: {
      ...prevState.pageFrom,
      value: fields.startPage,
    },
    pageTo: {
      ...prevState.pageTo,
      value: fields.endPage,
    },
    grnti: {
      ...prevState.grnti,
      value: {
        id: fields.grntiId || '',
        label: fields.grntiLabel || '',
      },
    },
    udk: {
      ...prevState.udk,
      value: fields.udk,
    },
    study: {
      ...prevState.study,
      value: {
        id: fields.studyId || '',
        label: fields.studyLabel || '',
      },
    },
    articleNumber: {
      ...prevState.articleNumber,
      value: fields.articleNumber,
    },
    file: {
      ...prevState.file,
      value: file,
    },
    electronicMediaSource: {
      ...prevState.electronicMediaSource,
      value: fields.electronicMediaSource,
      required: fields.isElectronic === 'true' && fields.electronicType === ElectronicType.LOCAL.code,
    },
    electronicSystemRequirements: {
      ...prevState.electronicSystemRequirements,
      value: fields.electronicSystemRequirements,
    },
    date: {
      ...prevState.date,
      value: fields.urlDate,
      required: fields.isElectronic === 'true',
    },
    bibliographicRecord: {
      ...prevState.bibliographicRecord,
      value: fields.bibliographicRecord,
    },
    foreignName: {
      ...prevState.foreignName,
      value: fields.foreignName,
    },
    foreignAnnotation: {
      ...prevState.foreignAnnotation,
      value: fields.foreignAnnotation,
    },
    foreignAuthorsKeyWords: {
      ...prevState.foreignAuthorsKeyWords,
      value: fields.foreignAuthorsKeyWords,
    },
    foreignAuthors: {
      ...prevState.foreignAuthors,
      value: fields.foreignAuthors,
    },
  }));
};

export { setByResponse };

import { useState, useLayoutEffect, useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Form as F } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';
import { useFormContext } from 'features/Form/hooks';
import { ValueOf } from 'types/helpers';
import workModeHook from 'features/Form/hooks/workModeHook';
import { validate } from './validate';
import { getMockProjectAssignmentMessage } from './helpers';
import { ProjectAssignmentMessage } from 'types/models/Project';
import { Original } from 'types/models/Form';

type Props = {
  viewMode?: boolean;
  editMode?: boolean;
  onClose(): void;
};

export function useController({ viewMode: initialViewMode, editMode: initialEditMode, onClose }: Props) {
  const {
    look: { id, projectAssignmentId },
  } = useFormContext<F.ProjectAssignmentMessageFormLook>();

  const [projectAssignmentMessage, setProjectAssignmentMessage] = useState<ProjectAssignmentMessage>(
    getMockProjectAssignmentMessage(),
  );

  const { workMode, updateWorkModeAfterSaveAndContinue } = workModeHook({ viewMode: initialViewMode, editMode: initialEditMode });

  const tableStreams = useLocalTableStreams();
  const { methods: saveProjectAssignmentMessage } = BackendAPI.useBackendAPI('SaveProjectAssignmentMessage');
  const { methods: getProjectAssignmentMessage } = BackendAPI.useBackendAPI('GetProjectAssignmentMessage');

  const makeChangeHandler = useCallback(
    (key: keyof ProjectAssignmentMessage) => (value: ValueOf<ProjectAssignmentMessage> | Original) => {
      setProjectAssignmentMessage(prevProjectAssignmentMessage => ({ ...prevProjectAssignmentMessage!, [key]: value }));
    },
    [],
  );

  const loadProjectAssignmentMessage = useCallback(
    (messageId: string) => {
      getProjectAssignmentMessage.callAPI(
        { id: messageId },
        {
          onSuccessfullCall: ({ data }) => {
            setProjectAssignmentMessage(data);
          },
        },
      );
    },
    [getProjectAssignmentMessage],
  );

  const onSubmit = useCallback(
    (needClose: boolean) => {
      const errors = validate(projectAssignmentMessage).filter(m => !m.isValid);
      if (errors && errors.length > 0) {
        const message = `${errors.map(({ invalidMessage }) => invalidMessage).join(', ')}`;
        showNotification({ message, theme: 'danger' });
        return false;
      }

      saveProjectAssignmentMessage.callAPI(
        {
          ...projectAssignmentMessage,
          id: id || projectAssignmentMessage?.id || null,
          message: projectAssignmentMessage.message || '',
          assignmentId: projectAssignmentId || '',
        },
        {
          onSuccessfullCall: ({ data }) => {
            showNotification({ message: 'Сообщение успешно сохранено', theme: 'success' });
            tableStreams.reloadTable.push({});
            if (needClose) {
              onClose();
            } else if (data.id) {
              updateWorkModeAfterSaveAndContinue();
              loadProjectAssignmentMessage(data.id);
            }
          },
        },
      );
    },
    [
      projectAssignmentMessage,
      projectAssignmentId,
      id,
      loadProjectAssignmentMessage,
      onClose,
      saveProjectAssignmentMessage,
      tableStreams,
      updateWorkModeAfterSaveAndContinue,
    ],
  );

  useLayoutEffect(() => {
    if (id) {
      loadProjectAssignmentMessage(id);
    }
    // eslint-disable-next-line
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSubmit(true),
        isDisabled: workMode === 'viewMode',
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: () => onSubmit(false),
        isDisabled: workMode === 'viewMode',
      },
    ],
    [workMode, onSubmit],
  );

  return {
    projectAssignmentMessageId: id ?? null,
    projectAssignmentMessage,
    buttons,
    workMode,
    makeChangeHandler,
  };
}

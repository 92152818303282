import { Project } from 'types/models';
export const getMockAct = (): Project.Act => {
  return {
    departments: [],
    сonsiderationDocuments: '',
    conclusion: '',
    council: null,
    defaultBaseProgram: '',
    id: '',
    members: [],
    note: '',
    number: '',
    stage: null,
  };
};

export function calcStageTotalAmount(project: Project.Project | null, act: Project.Act | null) {
  if (!project || !act) {
    return 0;
  }
  const stageNum = act?.stage?.number;

  const projectStage = project?.stages?.find(s => s.number == stageNum);

  return projectStage ? parseFloat(projectStage.amountMain) : 0;
}

export class Report {
  static MODULE: string = 'Report';

  static TWO_SCIENCE: string = '2Science';

  static YEAR: string = 'Year';

  static OTHER: string = 'Other';

  static EGISU: string = 'EGISU';

  static NIR_REQUESTS: string = 'NirRequests';

  static PROJECT_REQUESTS: string = 'ProjectRequests';

  static MOBILE_REQUEST: string = 'MobileRequest';

  static INTELLECTUAL_PROPERTY: string = 'IntellectualProperty';

  static COMMERCIALIZATION: string = 'Commercialization';

  static TENDERS: string = 'Tenders';

  static NIU: string = 'NIU';

  static PERSONAL: string = 'Personal';

  static PROJECT: string = 'Project';

  static DEPARTMENTS: string = 'Departments';

  static SCIENTISTS: string = 'Scientists';

  static TSU: string = 'TSU';

  static PFU: string = 'PFU';

  static REPNID: string = 'REPNID';

  static INCENTIVE_PAYMENTS: string = 'IncentivePayments';

  static REQUEST: string = 'Request';

  static ONTI: string = 'ONTI';

  static FILES: string = 'Files';
}

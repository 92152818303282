import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent(
    [
      {
        type: FilterComponentType.checkbox,
        streamKey: 'isDissertationManagement',
        label: 'Диссертации',
        checked: true,
      },
      {
        type: FilterComponentType.checkbox,
        streamKey: 'isSynopsisManagement',
        label: 'Авторефераты',
        checked: true,
      },
    ],
    'personal',
  );
});

import React, { useCallback, useMemo } from 'react';

import { FormComponent, Select, SelectMode, TextDateTime, TextInput } from 'components';

import { Project } from 'types/models';
import { Item } from 'types/models/common';
import { ValueOf } from 'types/helpers';
import { SelectPartner } from 'features/Form/views/SelectPartner';

type Props = {
  partner: Project.Partner;
  makeChangeHandler: (key: keyof Project.Partner) => (value: ValueOf<Project.Partner>) => void;
  setPartner(partner: Project.Partner): void;
  mode: 'view' | 'add' | 'edit' | null;
};

export function BaseFields({ partner, makeChangeHandler, mode, setPartner }: Props) {
  const handleChangeRole = useCallback(
    (option: Item) => {
      if (option.value === 'HEAD_PERFORMER') {
        setPartner({
          ...partner,
          role: option,
          contractName: '',
          leaderAppointment: '',
          leaderContacts: '',
          leaderDegree: '',
          leaderFullName: '',
          leaderJobEnterprise: null,
          leaderRank: '',
        });
        return;
      }
      if (option.value === 'PARTNER') {
        setPartner({
          ...partner,
          role: option,
          financings: [],
          kindWork: '',
        });
        return;
      }
      setPartner({ ...partner, role: option });
    },
    [partner, setPartner],
  );

  const isPartnerInfoEmpty = useMemo(() => !partner.partner?.activitySector?.id || !partner.partner?.lksd?.id, [
    partner.partner?.activitySector?.id,
    partner.partner?.lksd?.id,
  ]);

  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Партнёр" isRequired>
          <SelectPartner
            partner={partner.partner}
            setPartner={makeChangeHandler('partner')}
            label="Организации-партнёры"
            showButtons={['add', 'edit']}
          />
          {!!partner.partner?.id && isPartnerInfoEmpty && (
            <FormComponent.Description mode="warning" classMixin={'is-hint'}>
              Важно! Укажите для орагнизации Сектор деятельности и Тип организации. Эти данные необходимы для отчетности по
              сотрудничеству с международными организациями
            </FormComponent.Description>
          )}
          {!!partner.partner?.id && !isPartnerInfoEmpty && (
            <FormComponent.Description classMixin={'is-hint'}>
              <div>
                <strong>Сектор деятельности:</strong> {partner.partner?.activitySector?.label}
              </div>
              <div>
                <strong>Тип организации:</strong> {partner.partner?.lksd?.label}
              </div>
            </FormComponent.Description>
          )}
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Роль" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={partner.role}
            settings={{ name: 'PartnerRole', includes: ['PARTNER', 'HEAD_PERFORMER'] }}
            onChange={handleChangeRole}
            isDisabled={mode === 'edit'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Вид договора" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={partner.contractType}
            settings={{ name: 'ContractType' }}
            onChange={(option: Item) => makeChangeHandler('contractType')(option as ValueOf<Project.Partner>)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="Номер договора">
          <TextInput value={partner.contractNumber} onChange={makeChangeHandler('contractNumber')} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сроки действия">
          <span>
            <TextDateTime
              value={partner?.startDate || ''}
              onChange={(value: string) => setPartner({ ...partner, startDate: value } as Project.Partner)}
              maxValue={partner?.endDate || ''}
            />
          </span>
          <span>&nbsp;-&nbsp;</span>
          <span>
            <TextDateTime
              value={partner?.endDate || ''}
              onChange={(value: string) => setPartner({ ...partner, endDate: value } as Project.Partner)}
              minValue={partner?.startDate || ''}
            />
          </span>
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextInput value={partner.note} onChange={makeChangeHandler('note')} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}

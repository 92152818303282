import React, { useEffect, useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const QnaAnswerAddTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const [isPreprint, setIsPreprint] = useState<boolean | null>(null);
  const look: F.Look = {
    id,
    apiID: 'GetQnaAnswer',
    template: 'QnaAnswerForm',
    type: 'Qna',
    relatedTableState,
  };

  useEffect(() => {
    if (isOpen) setIsPreprint(null);
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Ответить на вопрос" size={isPreprint === null ? 'large' : 'extralarge'}>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(QnaAnswerAddTemplate);

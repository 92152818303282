import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function PreprintGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Препринты</h4>

        <p>
          Функциональный блок <strong>Препринты</strong> содержит коллекцию препринтов. Препринты также находятся в общем списке
          публикаций (пункт меню <strong>Публикация</strong>). Базы препринтов хранятся в справочнике <strong>Журналы</strong>.
        </p>
        <p>
          Препринт, как неопубликованный результат научного проекта должен быть связан с проектом на вкладке{' '}
          <strong>«Финансовая поддержка»</strong>. Публикацию, созданную на основе препринта, необходимо связать с препринтом
        </p>

        <h4>Панель инструментов для управления записями в таблице</h4>

        <p>
          Страница пункта меню <strong>Препринты</strong> содержит в табличном виде перечень препринтов сотрудников, студентов и
          аспирантов университета. Назначение функциональных элементов интерфейса представлено в таблице.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Элемент интерфейса</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму просмотра препринта. Кнопка активна при выделении журнала в таблице</td>
            </tr>
            <tr>
              <td>
                Добавить: <Icon type="add" />
              </td>
              <td>Открывает форму для добавления препринта</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Открывает форму редактирования препринта. Кнопка активна при выделении журнала в таблице</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>Удаляет выделенный в таблице препринт</td>
            </tr>
            <tr>
              <td>
                Редактировать связь публикации с подразделениями: <Icon type="connection" mode="edit" />
              </td>
              <td>Открывает форму для добавления или редактирования связи с подразделением.</td>
            </tr>
            <tr>
              <td>
                Показатели публикации: <Icon type="statistic" />
              </td>
              <td>
                Открывает форму с информацией об индексировании препринта в базах цитирования и наукометрических показателях
                (кол-во цитирований)
              </td>
            </tr>
            <tr>
              <td>
                Показатели источника: <Icon type="statistic" mode="view" />
              </td>
              <td>
                Открывает форму с информацией об индексировании Базы препринтов в базах цитирования и наукометрических показателях
                журнала
              </td>
            </tr>
            <tr>
              <td>
                Утверждение публикации: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения</td>
            </tr>
            <tr>
              <td>
                Открыть публикацию во внешнем источнике: <Icon type="link" />
              </td>
              <td>
                Перейти по ссылке на страницу с публикацией в других Интернет-ресурсах (DOI, электронный каталог Научной
                библиотеки, др.). Ссылка открывается в новом окне браузера
              </td>
            </tr>
            <tr>
              <td>
                Скачать файл прикрепленный к публикации: <Icon type="download" />
              </td>
              <td>
                Скачанный файл помещается в папку системную папку <strong>Загрузки</strong> на устройстве пользователя
              </td>
            </tr>
            <tr>
              <td>
                Указать DOI статьи: <Icon type="badge" />
              </td>
              <td>
                Открывает форму для ввода идентификатора DOI публикации. Введённое значение будет отображаться на форме публикации
                на вкладке <strong>О публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Финансовая поддержка публикации: <Icon type="currency" />
              </td>
              <td>
                В случае, если препринт является результатом выполнения научного проекта, необходимо выбрать из списка Научных
                проектов. Ответственный по качеству научных отчётов при наличии прав, Принимает к отчёту по проекту указанную
                публикацию. Принятые к отчёту по проекту препринты и публикации отображаются на форме проекта на вкладке{' '}
                <strong>Результаты проекта, Публикации</strong>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список препринтов выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Функциональная кнопка открывает форму для добавления сообщения. Все сообщения хранятся в Системе, на странице{' '}
                <strong>Главная, Вопросы от пользователей администраторам</strong>. Список сообщений конкретного пользователя и
                ответы на них размещаются в <strong>Личном кабинете</strong> пользователя на вкладке{' '}
                <strong>Новости, Вопрос - ответ</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React, { useState } from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { FormProps } from 'features/Table/specifications/GetMobileRequestList/LeftPanelForThirdLevel/controller';
import { TenderInfo } from 'types/models/Form/look/programRequest';

type AddFormProps = {
  tenderInfo: TenderInfo | null;
} & FormProps;

const MobileRequestAddTemplate: React.FC<F.TemplateProps & AddFormProps> = ({
  isOpen,
  onClose,
  relatedTableState,
  type,
  id,
  tenderInfo,
}: F.TemplateProps & AddFormProps) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: F.MobileRequestFormLook = {
    apiID: 'GetMobileRequest',
    template: 'MobileRequestForm',
    relatedTableState,
    type,
    id,
    setTitle,
    tenderInfo,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(MobileRequestAddTemplate);

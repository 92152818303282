export function PartnerMobileProjectGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Таблица содержит список сотрудников, аспирантов и студентов университета, прошедших стажировку в организации. Источник
        данных — функциональный модуль, <strong>Заявки, Заявки по академической мобильности</strong>.
      </p>
      <p>Условие для отображения данных в таблице является выбор организации:</p>

      <ul>
        <li>
          На форме редактирования Заявки <strong>по академической мобильности</strong>, на вкладке Заявка, в разделе{' '}
          <strong>Место проведения мероприятия</strong> в поле <strong>Принимающая сторона</strong>.
        </li>
      </ul>

      <div className="warning">
        <span>
          В случае, если для организации указаны организации на вкладках <strong>Связи с другими организациями</strong> и{' '}
          <strong>Варианты наименования</strong>, то в списке будут отображаться записи связанных организаций. В столбце{' '}
          <strong>Организация</strong> отображается оригинальное название организации. В столбце <strong>Роль</strong>{' '}
          отображается роль организации в проекте.
        </span>
      </div>
    </>
  );
}

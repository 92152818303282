import { useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { GetProtocolCommissionList, GetTenderList } from 'features/Table/specifications';
import { Original } from 'types/models/Form';
import { ProtocolCommission } from 'types/models/ProtocolCommission';
import { Table, Tender } from 'types/models';

type Props = {
  changeTender(e: Tender.Tender | null): void;
};

export function useController({ changeTender }: Props) {
  const tenderSpecification = GetTenderList();

  const { methods: getTender } = BackendAPI.useBackendAPI('GetTender', {
    onSuccessfullCall: ({ data }) => {
      changeTender(data);
    },
  });

  const tenderModalTableRowConverter = useCallback((row: Table.Entry) => {
    return {
      id: row.id || '',
      name: row.TenderName,
    };
  }, []);

  const protocolCommissionSpecification = GetProtocolCommissionList({ hasSelectButton: true });

  const protocolCommissionModalTableRowConverter = useCallback<(row: Table.Entry) => ProtocolCommission>(row => {
    return {
      id: row.id || '',
      name: row.Name,
      orderNumber: row.OrderNumber,
      orderDate: row.OrderDate,
    };
  }, []);

  const changeTenderObject = useCallback(
    (value: Original | null) => {
      if (value?.id) getTender.callAPI({ id: value.id });
      else changeTender(null);
    },
    [changeTender, getTender],
  );

  return {
    tenderSpecification,
    tenderModalTableRowConverter,
    protocolCommissionSpecification,
    protocolCommissionModalTableRowConverter,
    changeTenderObject,
  };
}

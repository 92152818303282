import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const ScientistHirshIndexTemplate: React.FC<F.TemplateProps> = ({ isOpen, onClose, id, relatedTableState }: F.TemplateProps) => {
  const look: F.Look = {
    apiID: 'GetScientistHirshIndexesList',
    template: 'ScientistHirshIndexForm',
    editMode: true,
    id,
    relatedTableState,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Добавить/Редактировать индекс Хирша персоны"
      size={['extralarge', 'medium-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ScientistHirshIndexTemplate);

import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function DashboardDetailMagazineArticleGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Анализ эффективности по публикациям</h4>

        <p>
          Форма <strong>Статьи в журналах</strong> содержит в табличном виде; список статей в журналах; количественные показатели
          цитирования статьи в базах. Описание столбцов таблицы на форме представлено в таблице.
        </p>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Название столбца</th>
              <th>Источник данных на форме статьи</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Год публикации</td>
              <td>Сведения об издании</td>
            </tr>
            <tr>
              <td>Тип публикации (Scopus)</td>
              <td>Индексация в базах цитирования и показатели издания</td>
            </tr>
            <tr>
              <td>Тип публикации (WoS)</td>
              <td>Индексация в базах цитирования и показатели издания</td>
            </tr>
            <tr>
              <td>Название статьи</td>
              <td>О публикации, Название</td>
            </tr>
            <tr>
              <td>Авторы</td>
              <td>Сведения об авторах</td>
            </tr>
            <tr>
              <td>Подразделения</td>
              <td>
                На форме <strong>Связь публикации с подразделением</strong> (в заголовке формы)
              </td>
            </tr>
            <tr>
              <td>Количество авторов</td>
              <td>
                Общее количество авторов публикации на вкладке <strong>Сведения об авторах</strong>
              </td>
            </tr>
            <tr>
              <td>Фракционный счёт</td>
              <td>
                Фракционный счёт университета на вкладке <strong>Сведения об авторах</strong>
              </td>
            </tr>
            <tr>
              <td>Количество цитирований (Scopus)</td>
              <td>
                Последнее значение показателя в разделе <strong>Наукометрические показатели статьи</strong>
              </td>
            </tr>
            <tr>
              <td>Количество цитирований (WoS)</td>
              <td>
                Последнее значение показателя в разделе <strong>Наукометрические показатели статьи</strong>
              </td>
            </tr>
            <tr>
              <td>Название журнала</td>
              <td>Сведения об издании</td>
            </tr>
            <tr>
              <td>Страна журнала</td>
              <td>
                Страна, указанная на форме <strong>Издательства журнала</strong>
              </td>
            </tr>
            <tr>
              <td>Q (Год выпуска)</td>
              <td></td>
            </tr>
            <tr>
              <td>Q(SJR)</td>
              <td>
                Максимальное значение квартиля (SJR) – показатель Scopus для журнала (в год издания публикации) в разделе{' '}
                <strong>Индексация в базах цитирования и показатели издания</strong>
              </td>
            </tr>
            <tr>
              <td>Q(IF WoS)</td>
              <td>
                {/* eslint-disable-next-line max-len */}
                Максимальное значение квартиля (IF) – показатель Web of Science для журнала (в год издания публикации) в разделе{' '}
                <strong>Индексация в базах цитирования и показатели издания</strong>
              </td>
            </tr>
            <tr>
              <td>Q(SNIP)</td>
              <td>
                Максимальное значение квартиля (SNIP) – показатель Scopus для журнала (в год издания публикации) в разделе{' '}
                <strong>Индексация в базах цитирования и показатели издания</strong>
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React, { useCallback, useMemo, useState } from 'react';

import { ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';
import { PersonalAllSecurityDocumentGridHelp } from './help';

function Toolbar() {
  const [isHelpFormOpen, setIsHelpFormOpen] = useState(false);

  const handleHelpButtonClick = useCallback(() => {
    setIsHelpFormOpen(true);
  }, []);

  const handleTemplateCloseHelpForm = useCallback(() => {
    setIsHelpFormOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: handleHelpButtonClick,
      },
    ],
    [handleHelpButtonClick],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <Modal
        mode="help"
        title="Объекты интеллектуальной собственности университета"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PersonalAllSecurityDocumentGridHelp()}
      </Modal>
    </>
  );
}

export { Toolbar };

import React, { memo } from 'react';

import { Form } from 'features/Form';
import { Form as F, Table as T } from 'types/models';

import { Modal } from 'components';

type Props = {
  onClose: () => void;
  isOpen: boolean;
  id: string;
  relatedTableState: T.State;
};

const MagazineReleaseAddTemplate = ({ isOpen, onClose, id, relatedTableState }: Props) => {
  const look: F.Look = {
    id,
    apiID: 'GetMagazineReleaseArticleList',
    template: 'MagazineReleaseArticles',
    type: 'Magazine',
    editMode: true,
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Статьи выпуска журнала" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = memo(MagazineReleaseAddTemplate);

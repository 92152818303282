import { JointEvent } from 'types/models';
import { convertServerPerson } from 'services/BackendAPI/configurations/author/converters';
import { Settings } from 'types/models/common';

import {
  convertMaybeArray,
  getId,
  getText,
  convertReference,
  convertItem,
  getBoolean,
  convertDocument,
} from '../commonConverters';
import { convertPersonHistory } from '../converters';

export function convertJointEvent(jointEvent: any, settings: Settings): JointEvent.JointEvent {
  const participants = jointEvent.Participants?.Participant || [];
  const departments = jointEvent.Departments?.Department || [];
  const enterprises = jointEvent.Enterprises?.Enterprise || [];
  const committeMembers = jointEvent.CommitteMembers?.CommitteMember || [];
  const memberCounts = jointEvent.MemberCounts?.MemberCount || [];
  const projects = jointEvent.Projects?.Project || [];
  const grntis = jointEvent.Grntis?.Grnti || [];
  const ministryEducations = jointEvent.MinistryEducations?.MinistryEducation || [];
  const criticalTechnologies = jointEvent.CriticalTechnologies?.CriticalTechnology || [];
  const pnrs = jointEvent.Pnrs?.Pnr || [];
  const pnmitrs = jointEvent.Pnmitrs?.Pnmitr || [];
  const pnis = jointEvent.Pnis?.Pni || [];
  const documents = jointEvent.Documents?.Document || [];

  const result: JointEvent.JointEvent = {
    id: getId(jointEvent),
    type: jointEvent.Type ? convertItem(jointEvent.Type) : null,
    level: jointEvent.Level ? convertReference(jointEvent.Level) : null,
    name: getText(jointEvent.Name),
    startDate: getText(jointEvent.StartDate),
    endDate: getText(jointEvent.EndDate),
    isStudent: getBoolean(jointEvent.IsStudent),
    orderNumber: getText(jointEvent.OrderNumber),
    orderDate: getText(jointEvent.OrderDate),
    description: getText(jointEvent.Description),
    site: getText(jointEvent.Site),
    contact: getText(jointEvent.Contact),
    memberCountPlan: getText(jointEvent.MemberCountPlan),
    memberCountFact: getText(jointEvent.MemberCountFact),
    keyWords: getText(jointEvent.KeyWords),
    format: jointEvent.Format ? convertItem(jointEvent.Format) : null,
    participants: convertMaybeArray(participants, x => convertParticipant(x, settings)),
    departments: convertMaybeArray(departments, convertDepartment),
    enterprises: convertMaybeArray(enterprises, convertEnterprise),
    committeMembers: convertMaybeArray(committeMembers, x => convertCommitteMember(x, settings)),
    memberCounts: convertMaybeArray(memberCounts, convertMemberCount),
    projects: convertMaybeArray(projects, convertEventProject),
    grntis: convertMaybeArray(grntis, convertReference),
    ministryEducations: convertMaybeArray(ministryEducations, convertReference),
    criticalTechnologies: convertMaybeArray(criticalTechnologies, convertReference),
    pnrs: convertMaybeArray(pnrs, convertReference),
    pnmitrs: convertMaybeArray(pnmitrs, convertReference),
    pnis: convertMaybeArray(pnis, convertReference),
    documents: convertMaybeArray(documents, i => convertDocument('local', i)),
  };

  return result;
}

function convertParticipant(participant: any, settings: Settings): JointEvent.Participant {
  const awards = participant.Awards?.Award || [];
  return {
    ...convertPersonHistory(participant),
    id: getId(participant),
    person: participant.Person ? convertServerPerson(participant.Person, settings) : null,
    awards: convertMaybeArray(awards, convertAward),
  };
}

function convertAward(award: any): JointEvent.Award {
  return {
    id: getId(award),
    category: award.Category ? convertItem(award.Category) : null,
    name: getText(award.Name),
  };
}

function convertDepartment(department: any): JointEvent.Department {
  return {
    id: getId(department),
    element: department.Element ? convertReference(department.Element) : null,
    position: getText(department.Position),
    isFinancing: getBoolean(department.IsFinancing),
  };
}

function convertEnterprise(enterprise: any): JointEvent.Enterprise {
  return {
    id: getId(enterprise),
    element: enterprise.Element ? convertReference(enterprise.Element) : null,
    position: getText(enterprise.Position),
    role: enterprise.Role ? convertItem(enterprise.Role) : null,
  };
}

function convertCommitteMember(committeMember: any, settings: Settings): JointEvent.CommitteMember {
  return {
    ...convertPersonHistory(committeMember),
    id: getId(committeMember),
    person: committeMember.Person ? convertServerPerson(committeMember.Person, settings) : null,
    role: committeMember.Role ? convertReference(committeMember.Role) : null,
  };
}

function convertMemberCount(memberCount: any): JointEvent.MemberCount {
  const amounts = memberCount.Amounts?.Amount || [];
  return {
    id: getId(memberCount),
    type: memberCount.Type ? convertItem(memberCount.Type) : null,
    amount: getText(memberCount.Amount),
    amounts: convertMaybeArray(amounts, convertAmount),
  };
}

function convertAmount(amount: any): JointEvent.Amount {
  return {
    id: getId(amount),
    amount: getText(amount.Amount),
    city: getText(amount.City),
    country: amount.Country ? convertReference(amount.Country) : null,
    enterprise: amount.Enterprise ? convertReference(amount.Enterprise) : null,
  };
}

function convertEventProject(eventProject: any): JointEvent.JointEventProject {
  return {
    id: getId(eventProject),
    project: eventProject.Project ? convertProject(eventProject.Project) : null,
    accepted: getBoolean(eventProject.Accepted),
    acceptedBy: eventProject.AcceptedBy ? convertAcceptedBy(eventProject.AcceptedBy) : null,
  };
}

function convertAcceptedBy(acceptedBy: any): JointEvent.AcceptedBy {
  return {
    id: getId(acceptedBy),
    fullName: getText(acceptedBy.FullName),
  };
}

function convertProject(project: any): JointEvent.JointEventProjectItem {
  return {
    id: getId(project),
    number: getText(project.Number),
    name: getText(project.Name),
    leader: getText(project.Leader),
    governances: getText(project.Governances),
    financings: getText(project.Financing),
    program: getText(project.Program),
  };
}

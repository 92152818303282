import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertServerAcceptSalary } from './converters';

export type AcceptSalaryProjectScientistType = {
  salaryId: string;
  salaryJobIds: string;
  code: string;
  projectCodeId: string;
  fundCardId: string;
};

export type ChangeSalarySourceCodeProps = {
  source: { type: string; id: string };
  rows: { salaryId: string; code: string; salaryJobPaymentIds: string; projectCodeId: string; fundCardId: string }[];
};

export const salaryConfigurations = {
  AcceptSalaryProjectScientist: makeBackendAPIConfiguration({
    id: 'AcceptSalaryProjectScientist',
    endpoint: '/msa/service/commands/AcceptSalaryProjectScientist',
    convertInputToXMLElement: (props: AcceptSalaryProjectScientistType[]) => {
      return {
        Request: {
          _attr: { commandName: 'AcceptSalaryProjectScientist' },
          ...(props && props.length ? { Row: props.map(i => ({ _attr: { ...i } })) } : {}),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerAcceptSalary(response.Response.Result),
  }),
  ChangeSalarySourceCode: makeBackendAPIConfiguration({
    id: 'ChangeSalarySourceCode',
    endpoint: '/msa/service/commands/ChangeSalarySourceCode',
    convertInputToXMLElement: ({ source, rows }: ChangeSalarySourceCodeProps) => {
      return {
        Request: {
          _attr: { commandName: 'ChangeSalarySourceCode' },
          Source: { _attr: { ...source } },
          Row: rows.map(row => ({ _attr: { ...row } })),
        },
      };
    },
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => convertServerAcceptSalary(response.Response.Result),
  }),
};

import { useCallback, useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { useAppDataContext } from 'features/AppData/context';
import { SubmitTable } from 'features/Table/streams';
import { CrudCallbackArguments, Operation } from './types';
import { Props as ComponentProps } from './';
import { Setting } from 'types/models/Table';

type Props = {} & Omit<ComponentProps, 'columns' | 'gridName'>;

type PresetTemplate = {
  value: string;
  label: string;
  author: string;
};

const useController = ({
  currentSetting,
  settingPresets,
  changeCurrentSetting,
  saveAndChangeGridSetting,
  reloadSettingPresets,
}: Props) => {
  const { userPermission } = useAppDataContext();

  const [isOpenHelpModal, setIsOpenHelpModal] = useState<boolean>(false);
  const [isTemplatesModalOpen, setIsTemplatesModalOpen] = useState(false);
  const [isNotificationModalOpen, setIsNotificationModalOpen] = useState<boolean>(false);
  const [isSaveWarningModalOpen, setIsSaveWarningModalOpen] = useState<boolean>(false);
  const [isTemplateFormOpen, setIsTemplateFormOpen] = useState<boolean>(false);

  const openHelpModal = useCallback(() => {
    setIsOpenHelpModal(true);
  }, []);

  const closeHelpModal = useCallback(() => {
    setIsOpenHelpModal(false);
  }, []);

  const closeTemplateForm = useCallback(() => {
    setIsTemplateFormOpen(false);
  }, []);

  const openTemplateForm = useCallback(() => {
    setIsTemplateFormOpen(true);
  }, []);

  const handleTemplatesModalClose = useCallback(() => {
    setIsTemplatesModalOpen(false);
  }, []);

  const handleOpenTemplateButtonClick = useCallback(() => {
    setIsTemplatesModalOpen(true);
  }, []);

  const handleSaveOtherTemplate = useCallback(() => {
    setIsNotificationModalOpen(false);
    openTemplateForm();
  }, [openTemplateForm]);

  const onConfirmSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
    openTemplateForm();
  }, [openTemplateForm]);

  const onResetSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
  }, []);

  const onCloseSaveWarningModal = useCallback(() => {
    setIsSaveWarningModalOpen(false);
  }, []);

  const handleNotificationModalClose = useCallback(() => {
    setIsNotificationModalOpen(false);
  }, []);

  const userLogin = userPermission?.userLogin;

  const crudCallback = useCallback(
    async ({ operation, name, author }: CrudCallbackArguments) => {
      const isDeleteOperation = operation === Operation.DELETE;
      const isCurrentSetting = Boolean(currentSetting) && currentSetting?.name === name && currentSetting?.author === author;

      await reloadSettingPresets();

      if (isDeleteOperation && isCurrentSetting) {
        changeCurrentSetting({ name: '' });
      }
    },
    [changeCurrentSetting, currentSetting, reloadSettingPresets],
  );

  const submitCallback = useCallback(
    (tableState: SubmitTable) => {
      if (tableState.selectedRows.length) {
        const [row] = tableState.selectedRows;
        setIsTemplatesModalOpen(false);
        changeCurrentSetting({ name: row.name, author: row.authorLogin });
      }
    },
    [changeCurrentSetting],
  );

  const settingPresetOptions = useMemo<PresetTemplate[]>(
    () =>
      settingPresets.map(item => ({
        value: `${item.author}_${item.name}`,
        label: item.name,
        author: item.author,
      })),
    [settingPresets],
  );

  const handleDiscardButtonClick = useCallback(() => {
    changeCurrentSetting({ name: '' });
  }, [changeCurrentSetting]);

  const handleSaveTemplateClick = useCallback(() => {
    if (currentSetting) {
      const isUserSetting = currentSetting.author === userLogin;
      if (isUserSetting) {
        setIsNotificationModalOpen(true);
      } else {
        setIsSaveWarningModalOpen(true);
      }
    } else {
      openTemplateForm();
    }
  }, [currentSetting, openTemplateForm, userLogin]);

  const handleSaveClick = useCallback(() => {
    saveAndChangeGridSetting({
      name: currentSetting?.name ?? '',
      author: currentSetting?.author ?? '',
      isShared: !!currentSetting?.isShared,
      purpose: currentSetting?.purpose ?? '',
    });
    setIsNotificationModalOpen(false);
  }, [currentSetting?.author, currentSetting?.isShared, currentSetting?.name, currentSetting?.purpose, saveAndChangeGridSetting]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь в работе с шаблонами',
        onClick: openHelpModal,
      },
      {
        icon: { type: 'list' },
        title: 'Список шаблонов',
        onClick: handleOpenTemplateButtonClick,
      },
      {
        icon: { type: 'remove' },
        title: 'Не использовать шаблон',
        onClick: handleDiscardButtonClick,
        isDisabled: !currentSetting,
      },
      {
        icon: { type: 'save' },
        title: 'Сохранить шаблон',
        onClick: handleSaveTemplateClick,
      },
    ],
    [currentSetting, handleDiscardButtonClick, handleOpenTemplateButtonClick, handleSaveTemplateClick, openHelpModal],
  );

  const onChangeCurrentSetting = useCallback(
    (setting: PresetTemplate) => {
      if (currentSetting) {
        const isCurrentSetting = setting.label === currentSetting.name && setting.author === currentSetting.author;
        if (isCurrentSetting) {
          return;
        }
      }
      changeCurrentSetting({ name: setting.label, author: setting.author });
    },
    [changeCurrentSetting, currentSetting],
  );

  const successfullCallback = useCallback(
    async (setting: Setting) => {
      await saveAndChangeGridSetting({
        id: setting.id,
        name: setting.name,
        purpose: setting.purpose,
        isShared: setting.isShared,
        author: userPermission?.userLogin,
      });
      await reloadSettingPresets();
    },
    [reloadSettingPresets, saveAndChangeGridSetting, userPermission?.userLogin],
  );
  return {
    buttons,
    userLogin,
    isOpenHelpModal,
    isTemplateFormOpen,
    settingPresetOptions,
    isTemplatesModalOpen,
    isSaveWarningModalOpen,
    isNotificationModalOpen,
    crudCallback,
    closeHelpModal,
    submitCallback,
    handleSaveClick,
    closeTemplateForm,
    successfullCallback,
    onChangeCurrentSetting,
    onResetSaveWarningModal,
    handleSaveOtherTemplate,
    onCloseSaveWarningModal,
    onConfirmSaveWarningModal,
    handleTemplatesModalClose,
    handleNotificationModalClose,
  };
};

export default useController;

import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ListEdit, ReferenceItem, Select, SelectMode, TextArea, TextInput, TextInputMode } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { Period, PeriodInputs } from './PeriodInputs';
import { useFormContext } from 'features/Form/hooks';
import { PublicationTypes } from 'utils/Enums/PublicationTypes';
import { GetReferenceElementList } from 'features/Table/specifications';
import { periodValidation } from './validate';
import { Form as F } from 'types/models';
// eslint-disable-next-line max-len
import { Props as ExtLookProps } from 'features/Table/specifications/GetPublicationCitationSystemList/LeftPanelForThirdLevel/modalTemplate';

type Props = {
  onClose: () => void;
};

function CitationSystemForm({ onClose }: Props) {
  const {
    look: { customState, mode, relatedTableState, setTitle },
  } = useFormContext<F.Look & ExtLookProps>();

  const {
    publicationCitationSystem,
    saveForm,
    isSource,
    sourceTypeFilter,
    handleChangeCitationSystem,
    handleIdentifierFieldChange,
    handleSourceTypeFieldChange,
    handleUrlFieldChange,
    handleKeyWordsFieldChange,
    handleNameFieldChange,
    handlePeriodsFieldChange,
    settingPeriodSingle,
    setSettingPeriodSingle,
    handleThematicCategoriesFieldChange,
  } = useController({
    onClose,
    customState,
    mode,
    relatedTableState,
    setTitle,
  });

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={saveForm} />

      <FormComponent.Wrapper>
        <FormComponent.ColumnWrapper>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field label="Система цитирования" isRequired>
                <Select
                  mode={SelectMode.REFERENCE}
                  value={publicationCitationSystem.citationSystem}
                  onChange={(val: ReferenceItem) => handleChangeCitationSystem(val)}
                  settings={{ name: 'RefCitationSystem', title: 'Справочник "Системы цитирования"' }}
                  isDisabled={['CHILD_GRID', 'MAGAZINE_ARTICLE'].some(e => e === publicationCitationSystem.citationSystemType)}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="Идентификатор" isRequired>
                <TextInput value={publicationCitationSystem.identifier} onChange={handleIdentifierFieldChange} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
          <FormComponent.Column>
            <FormComponent.Line>
              <FormComponent.Field
                label={
                  publicationCitationSystem.citationSystemType === 'MAGAZINE' ||
                  publicationCitationSystem.citationSystemType === 'PARENTAL_GRID'
                    ? 'Тип издания'
                    : 'Тип статьи'
                }
              >
                <Select
                  mode={SelectMode.REFERENCE}
                  value={publicationCitationSystem.sourceType}
                  onChange={(val: ReferenceItem) => handleSourceTypeFieldChange(val)}
                  settings={{
                    name: isSource ? 'RefCitationSystemSourceType' : 'RefCitationSystemArticleType',
                    title: `Типы ${isSource ? 'изданий' : 'статей'} в базах цитирования`,
                    filters: sourceTypeFilter,
                    isClearable: true,
                  }}
                />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Field label="URL">
                <TextInput mode={TextInputMode.URL} value={publicationCitationSystem.url} onChange={handleUrlFieldChange} />
              </FormComponent.Field>
            </FormComponent.Line>
          </FormComponent.Column>
        </FormComponent.ColumnWrapper>

        {(customState.citationSystemType === 'CHILD_GRID' ||
          customState.citationSystemType === PublicationTypes.MAGAZINE_ARTICLE.code) && (
          <FormComponent.Line>
            <FormComponent.Field label="Ключевые слова">
              <TextArea settings={{ rows: 3 }} value={publicationCitationSystem.keyWords} onChange={handleKeyWordsFieldChange} />
            </FormComponent.Field>
          </FormComponent.Line>
        )}
        {(customState.citationSystemType === 'MAGAZINE' || customState.citationSystemType === 'PARENTAL_GRID') && (
          <>
            <FormComponent.Line>
              <FormComponent.Field label="Название в БЦ">
                <TextInput value={publicationCitationSystem.name} onChange={handleNameFieldChange} />
              </FormComponent.Field>
            </FormComponent.Line>
            <FormComponent.Line>
              {customState.citationSystemType === 'MAGAZINE' ? (
                <ListEdit
                  header={{ title: 'Периоды', isRequired: true }}
                  rows={publicationCitationSystem.periods || []}
                  onChange={handlePeriodsFieldChange}
                  toolbar={['add', 'edit', 'delete']}
                  columns={[
                    { label: 'С', formatValue: (row: Period) => row.startYear },
                    { label: 'По', formatValue: (row: Period) => row.endYear },
                  ]}
                  specification={{
                    mode: 'customComponent',
                    validation: {
                      checkIsValid: periodValidation,
                    },
                    renderComponent: (row, onChange) => <PeriodInputs value={row} onChange={onChange as any} />,
                  }}
                  withMessages
                  isDeleteConfirmEnabled
                />
              ) : (
                <FormComponent.Field label="Индексируется с" isRequired>
                  <TextInput value={settingPeriodSingle} onChange={setSettingPeriodSingle} />
                </FormComponent.Field>
              )}

              <ListEdit
                header={{ title: 'Тематические категории' }}
                rows={publicationCitationSystem.thematicCategories}
                onChange={handleThematicCategoriesFieldChange}
                toolbar={['add', 'edit', 'delete']}
                columns={[
                  { label: 'Код', formatValue: row => row.code },
                  { label: 'Наименование', formatValue: row => row.name },
                ]}
                isDisabled={!publicationCitationSystem.citationSystem.id}
                specification={{
                  mode: 'loadInstanceTableModal',
                  onStartLoad: (tableRows, onSuccess) => {
                    loadReferenceAPI.callAPI(
                      { filters: [], referenceName: 'RefThematicCategory', childIds: tableRows.map(x => x.id) },
                      {
                        onSuccessfullCall: ({ data }) =>
                          onSuccess(
                            data.map(e => ({
                              id: '',
                              value: e,
                              code: (e.customFields?.code as string) || '',
                              name: (e.customFields?.name as string) || '',
                            })),
                          ),
                      },
                    );
                  },
                  relationTableModalTitle: 'Справочник "Тематические категории"',
                  modalTableSpecification: GetReferenceElementList({
                    requestData: {
                      filters: [{ key: 'citationSystem', values: [publicationCitationSystem.citationSystem.id] }],
                      name: 'RefThematicCategory',
                    },
                  }),
                }}
                withMessages
                isDeleteConfirmEnabled
              />
            </FormComponent.Line>
          </>
        )}
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CitationSystemForm);

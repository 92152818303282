import React, { useMemo, useState } from 'react';

import { ButtonMode, ButtonProps, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { MessageModal } from '../MessageModal';
import { State } from '../makeUseCustomController';
import { Permits } from 'utils/Permissions';

type Props = {
  customState: State;
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { customState, tableState } = props;

  const {
    isOpenModal,
    message,
    isDisabledToolbar,
    openModal,
    closeModal,
    changeMessage,
    saveMessage,
    deleteMessage,
  } = customState;

  const [isOpenConfirmDeleteModal, setIsOpenConfirmDeleteModal] = useState(false);

  const [row] = tableState.selectedRows;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => openModal(),
        permission: {
          name: Permits.PURCHASE_REQUEST_PUSH_FEEDLINE_MESSAGE,
        },
        isDisabled: isDisabledToolbar,
      },
    ],
    [isDisabledToolbar, openModal],
  );

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <MessageModal
        isOpen={isOpenModal}
        onClose={closeModal}
        changeMessage={changeMessage}
        onSave={saveMessage}
        message={message}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isOpenConfirmDeleteModal}
        onClose={() => setIsOpenConfirmDeleteModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              deleteMessage(row.id);
              setIsOpenConfirmDeleteModal(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenConfirmDeleteModal(false),
          },
        ]}
        size="small"
      >
        <>
          (действие: {tableState.selectedRows[0]?.Type}, статус: {tableState.selectedRows[0]?.Status}) Удалить сообщение?
        </>
      </Modal>
    </>
  );
}

export { Toolbar };

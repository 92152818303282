export class Person {
  static MODULE: string = 'Person';

  static ENGLISH_FULL_NAME_BLOCK: string = 'EnglishFullName';

  static IDENTIFIER_AUTHOR: string = 'IdentifierAuthor';

  static PRIVATE_DATA: string = 'PrivateData';

  static SNILS_INN: string = 'SnilsInn';

  static CONTACTS: string = 'Contacts';

  static BIRTH_DATE: string = 'BirthDate';
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { makeBackendTableAPIConfiguration } from 'services/BackendAPI/makeBackendTableAPIConfiguration';

import { Partner } from 'types/models';
import { Settings } from 'types/models/common';
import { DeleteProps } from 'features/Table/specifications/GetPartnerList/makeUseCustomController';
import {
  convertSaveResponseXMLElementToOutput,
  getMaybeField,
  makeParametersXMLElement,
  makeTableRequestXMLConverter,
} from '../commonConverters';

import { convertPartnerToRequest, convertSetPartnerHeadToRequest } from './requestConverters';

import * as T from './types';

import { convertServerPartner } from './converters';

const getParams = (enterpriseCustomerIds: string[], enterpriseIds: string[]) => [
  { name: 'enterpriseCustomerIds', value: enterpriseCustomerIds.length ? enterpriseCustomerIds.join(',') : '-1' },
  { name: 'enterpriseIds', value: enterpriseIds.length ? enterpriseIds.join(',') : '-1' },
];

export const tablePartnersConfigurations = {
  GetPartnerList: makeBackendTableAPIConfiguration(
    'GetPartnerList',
    makeTableRequestXMLConverter('GetPartnerList', ({ panelState, requestData, isTableExtended }: T.GetPartnersListRequest) =>
      makeParametersXMLElement(
        requestData?.parameters.length
          ? requestData.parameters.map(({ name, value }) => ({ name, value: String(value) }))
          : [
              { name: 'extended', value: isTableExtended },
              { name: 'deleted', value: !!panelState.isDeleted },
              { name: 'curated', value: !!panelState.isCurated },
              { name: 'forApprovement', value: !!panelState.isForApprovement },
            ],
      ),
    ),
  ),
  GetLksdEnterpriseTypeBySectorActivity: makeBackendTableAPIConfiguration(
    'GetLksdEnterpriseTypeBySectorActivity',
    makeTableRequestXMLConverter(
      'GetLksdEnterpriseTypeBySectorActivity',
      ({ requestData }: T.GetLksdEnterpriseTypeBySectorActivity) =>
        makeParametersXMLElement([{ name: 'sectorActivityId', value: requestData?.sectorActivityId ?? '' }]),
    ),
  ),
  GetEnterpriseForPartner: makeBackendTableAPIConfiguration(
    'GetEnterpriseForPartner',
    makeTableRequestXMLConverter('GetEnterpriseForPartner', ({ requestData }: T.GetEnterprisesWithExcludeCustomerIds) =>
      makeParametersXMLElement([{ name: 'excludeEnterpriseCustomerIds', value: requestData?.enterpriseCustomerIds.join(',') }]),
    ),
  ),
  GetPartnerProjectList: makeBackendTableAPIConfiguration(
    'GetPartnerProjectList',
    makeTableRequestXMLConverter('GetPartnerProjectList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerSecurityDocumentContractList: makeBackendTableAPIConfiguration(
    'GetPartnerSecurityDocumentContractList',
    makeTableRequestXMLConverter('GetPartnerSecurityDocumentContractList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerPublicationList: makeBackendTableAPIConfiguration(
    'GetPartnerPublicationList',
    makeTableRequestXMLConverter('GetPartnerPublicationList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerEventList: makeBackendTableAPIConfiguration(
    'GetPartnerEventList',
    makeTableRequestXMLConverter('GetPartnerEventList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerExpositionParticipationList: makeBackendTableAPIConfiguration(
    'GetPartnerExpositionParticipationList',
    makeTableRequestXMLConverter('GetPartnerExpositionParticipationList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerMobileProjectList: makeBackendTableAPIConfiguration(
    'GetPartnerMobileProjectList',
    makeTableRequestXMLConverter('GetPartnerMobileProjectList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerAffilatedPersonList: makeBackendTableAPIConfiguration(
    'GetPartnerAffilatedPersonList',
    makeTableRequestXMLConverter('GetPartnerAffilatedPersonList', ({ requestData }: T.GetEnterpriseForPartnerRequest) =>
      makeParametersXMLElement(getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? [])),
    ),
  ),
  GetPartnerAffilatedPersonPublicationList: makeBackendTableAPIConfiguration(
    'GetPartnerAffilatedPersonPublicationList',
    makeTableRequestXMLConverter(
      'GetPartnerAffilatedPersonPublicationList',
      ({ requestData }: T.GetPartnerAffiletedPersonPublListRequest) =>
        makeParametersXMLElement([
          ...getParams(requestData?.enterpriseCustomerIds ?? [], requestData?.enterpriseIds ?? []),
          { name: 'scientistId', value: requestData?.scientistId ?? '' },
        ]),
    ),
  ),
  GetPartnerHeadList: makeBackendTableAPIConfiguration(
    'GetPartnerHeadList',
    makeTableRequestXMLConverter('GetPartnerHeadList', ({ requestData }: T.GetPartnerHeadListRequest) =>
      makeParametersXMLElement([{ name: 'excludePartnerId', value: requestData?.partnerId ?? '' }]),
    ),
  ),
};

const configurations = {
  SetPartnerHead: makeBackendAPIConfiguration({
    id: 'SetPartnerHead',
    endpoint: '/msa/service/commands/SetPartnerHead',
    convertInputToXMLElement: (args: T.SetPartnerHeadRequest) => ({
      Request: {
        _attr: { commandName: 'SetPartnerHead' },
        ...convertSetPartnerHeadToRequest(args),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPartner: makeBackendAPIConfiguration({
    id: 'GetPartner',
    endpoint: '/msa/service/commands/GetPartner',
    convertInputToXMLElement: ({ partnerId, enterpriseCustomerId, enterpriseId, shortName, fullName }: T.GetPartnerRequest) => ({
      Request: {
        _attr: { commandName: 'GetPartner' },
        Partner: {
          ...(!partnerId || !!enterpriseCustomerId || !!enterpriseId || !!shortName || !!fullName
            ? {}
            : { _attr: { id: partnerId } }),
          ...(!!enterpriseCustomerId ? { EnterpriseCustomer: { _attr: { id: enterpriseCustomerId } } } : {}),
          ...(!!enterpriseId ? { Enterprise: { _attr: { id: enterpriseId } } } : {}),
          ...getMaybeField('ShortName', shortName),
          ...getMaybeField('FullName', fullName),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      response.Response.Partner ? convertServerPartner(response.Response.Partner, settings) : ({} as Partner),
  }),
  DeletePartner: makeBackendAPIConfiguration({
    id: 'DeletePartner',
    endpoint: '/msa/service/commands/DeletePartner',
    convertInputToXMLElement: ({ partnerId, type, fullName, shortName }: DeleteProps) => ({
      Request: {
        _attr: { commandName: 'DeletePartner' },
        ...(type === 'PARTNER'
          ? { Partner: { _attr: { id: partnerId } } }
          : type === 'ENTERPRISE_CUSTOMER'
          ? { Partner: { EnterpriseCustomer: { _attr: { id: partnerId } } } }
          : type === 'ENTERPRISE'
          ? { Partner: { Enterprise: { _attr: { id: partnerId } } } }
          : { Partner: { ...(fullName ? { FullName: fullName } : {}), ...(shortName ? { ShortName: shortName } : {}) } }), // RAW
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePartner: makeBackendAPIConfiguration({
    id: 'SavePartner',
    endpoint: '/msa/service/commands/SavePartner',
    convertInputToXMLElement: ({ partner }: T.SavePartnerRequest) => ({
      Request: {
        _attr: { commandName: 'SavePartner' },
        ...convertPartnerToRequest(partner),
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

export const partnersConfigurations = {
  ...tablePartnersConfigurations,
  ...configurations,
};

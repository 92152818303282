import { Parameter, CheckedListValue } from 'types/models';

const queryBuilder = (projectTypesParameter: Parameter) => {
  const value = projectTypesParameter.value as CheckedListValue;
  if (value.list.length) {
    const query = `&${projectTypesParameter.name}=${value.list.map(item => item.isChecked).join(',')}`;
    return query;
  }

  return '';
};

export default queryBuilder;

import React, { memo, useCallback } from 'react';

import {
  FormComponent,
  ButtonMode,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
} from 'components';

import { Table as T, Form as F } from 'types/models';
import { GetSimpleMagazineListSpecification, GetMagazineReleaseListSpecification } from 'features/Table/specifications';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';

type Props = {
  formFields: F.Fields;
  onCreateBibliographicRecordClick: () => void;
  workMode: T.WorkMode;
  isElectronic: boolean;
  publicationId: string;
  sourceMagazine: F.Original | null;
  sourceMagazineRelease: F.Original | null;
  changeSourceMagazine: (original: F.Original | null) => void;
  changeSourceMagazineRelease: (original: F.Original | null) => void;
  onDeleteSourceMagazine: () => void;
  onDeleteSourceMagazineRelease: () => void;
  relatedTableState: T.State | undefined;
  magazineReleaseConference: string[];
  sourceMagazineOriginal: F.Original | null;
  sourceMagazineTranslates: F.Translation[];
  magazineReleaseReport: string[];
};

const Source = ({
  publicationId,
  isElectronic,
  workMode,
  formFields,
  sourceMagazine,
  sourceMagazineRelease,
  onCreateBibliographicRecordClick,
  changeSourceMagazineRelease,
  changeSourceMagazine,
  relatedTableState,
  magazineReleaseConference,
  sourceMagazineOriginal,
  sourceMagazineTranslates,
  magazineReleaseReport,
}: Props) => {
  const modalTableRowToSourceMagazineConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.name,
      status: row.status,
      typeEdition: row.typeEdition,
      foreignName: row.ForeignName || '',
    }),
    [],
  );

  const modalTableRowToSourceMagazineReleaseConventer = useCallback<(row: T.Entry) => F.Original>(
    row => ({
      id: row.id,
      name: row.label,
      status: row.Status,
      year: row.Year,
      events: row.Events || null,
    }),
    [],
  );

  const sourceMagazineTableConfig = GetSimpleMagazineListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedTableState: { ...relatedTableState, isDisabledAdd: true },
        relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
      },
    },
    isPreprint: true,
    isElectronic,
    hasSelectButton: true,
    articleIds: publicationId,
  });
  const sourceMagazineReleaseTableConfig = GetMagazineReleaseListSpecification({
    deps: {
      templatesTableDependencies: relatedTableState && {
        relatedTableAPIID: 'GetMagazineList',
        relatedRecordId: sourceMagazine?.id,
        relatedTableState: { ...relatedTableState, isDisabledAdd: true },
      },
    },
    hasSelectButton: true,
  });

  return (
    <>
      <SectionTitle title="Издание" />

      <FormComponent.Line>
        <FormComponent.Field label="Размещено" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Список баз препринтов',
              isClearable: false,
              visibleStatus: getStatusRecordMessage('', sourceMagazine?.id, sourceMagazine?.status),
              table: {
                specification: sourceMagazineTableConfig,
                onSelect: (row: T.Entry | null) => changeSourceMagazine(row ? modalTableRowToSourceMagazineConventer(row) : null),
              },
            }}
            value={sourceMagazine?.name}
            isDisabled={workMode === 'viewMode'}
          />
          {(!!sourceMagazineOriginal || !!sourceMagazineTranslates?.length) && (
            <FormComponent.Description mode="warning" classMixin="is-hint">
              {!!sourceMagazineOriginal && (
                <div>
                  <strong>Оригинальный журнал: </strong>
                  {`${sourceMagazineOriginal.name}${
                    sourceMagazineOriginal.edition
                      ? ` (ID ${sourceMagazineOriginal.id}, издательство: ${sourceMagazineOriginal.edition})`
                      : ''
                  }`}
                </div>
              )}
              {!!sourceMagazineTranslates?.length &&
                sourceMagazineTranslates.map((translate, index) => (
                  <div key={index}>
                    <strong>Переводной журнал: </strong>
                    {`${translate.name}${translate.edition ? ` (ID ${translate.id}, издательство: ${translate.edition})` : ''}`}
                  </div>
                ))}
            </FormComponent.Description>
          )}
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Год" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: 'Год',
              isClearable: false,
              visibleStatus: getStatusRecordMessage('', sourceMagazine?.id, sourceMagazine?.status),
              table: {
                specification: sourceMagazineReleaseTableConfig,
                onSelect: (row: T.Entry | null) =>
                  changeSourceMagazineRelease(row ? modalTableRowToSourceMagazineReleaseConventer(row) : null),
              },
            }}
            value={sourceMagazineRelease?.year}
            isDisabled={workMode === 'viewMode' || !sourceMagazine}
          />
          <FormComponent.Description
            mode="warning"
            tooltip="Конференцию можно добавить на форме редактирования Выпуска журнала"
            classMixin="is-hint"
          >
            <div>
              <strong>Опубликованы материалы конференции:</strong> {magazineReleaseConference.join('; ') || 'Нет'}
            </div>
            <div>
              <strong>Доклады:</strong> {magazineReleaseReport.join('; ') || 'Нет'}
            </div>
          </FormComponent.Description>
        </FormComponent.Field>
      </FormComponent.Line>

      <FormComponent.Line>
        <FormComponent.Field label="Язык текста" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.textLanguage.value}
            onChange={formFields.textLanguage.onChange}
            settings={{ name: 'RefLanguage', title: 'Справочник "Языки"' }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field isRequired={formFields.pageCount.required} label={formFields.pageCount.title}>
          <TextInput
            mode={TextInputMode.NUMBER}
            settings={{ max: 100000, decimal: false }}
            value={formFields.pageCount.value}
            onChange={formFields.pageCount.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.pageCount.isValid}
          />
        </FormComponent.Field>
        <FormComponent.Field isRequired={formFields.articleNumber.required} label={formFields.articleNumber.title}>
          <TextInput
            value={formFields.articleNumber.value}
            onChange={formFields.articleNumber.onChange}
            isDisabled={workMode === 'viewMode'}
            isError={!formFields.articleNumber.isValid}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Библиографическая запись" />

      <FormComponent.Line>
        <FormComponent.Field>
          <TextArea
            settings={{
              rows: 3,
              externalButtons: [
                {
                  mode: ButtonMode.SECONDARY,
                  text: 'Построить',
                  onClick: onCreateBibliographicRecordClick,
                  isDisabled: workMode === 'viewMode',
                },
              ],
            }}
            value={formFields.bibliographicRecord.value}
            onChange={formFields.bibliographicRecord.onChange}
            isDisabled
          />
        </FormComponent.Field>
      </FormComponent.Line>

      <SectionTitle title="Классификаторы" />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.grnti.title} tooltip={formFields.grnti.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.grnti.value}
            onChange={formFields.grnti.onChange}
            settings={{ name: 'RefGrnti', title: 'Справочник "ГРНТИ"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.udk.title} tooltip={formFields.udk.tooltipText}>
          <TextInput value={formFields.udk.value} onChange={formFields.udk.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.study.title} tooltip={formFields.study.tooltipText}>
          <Select
            mode={SelectMode.REFERENCE}
            value={formFields.study.value}
            onChange={formFields.study.onChange}
            settings={{ name: 'RefStudy', title: 'Справочник "Область науки"', isClearable: true }}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
};

export const SourceComponent = memo(Source);

import { CommunityMember } from 'types/models';

import { Settings } from 'types/models/common';
import { convertServerPerson } from '../author/converters';
import { convertMaybeArray, convertReference, getId, getText, convertItem, convertDocument } from '../commonConverters';
import { convertCommunity } from '../community/converters';
import { convertPersonHistory } from 'services/BackendAPI/configurations/converters';

export function convertCommunityMember(communityMember: any, settings: Settings): CommunityMember {
  const documents = communityMember.Documents?.Document || [];

  return {
    id: getId(communityMember),
    joinDate: getText(communityMember.JoinDate),
    leaveDate: getText(communityMember.LeaveDate),
    role: communityMember.Role ? convertItem(communityMember.Role) : null,
    person: communityMember.Person ? convertServerPerson(communityMember.Person, settings) : null,
    vakSpeciality: communityMember.VakSpeciality ? convertReference(communityMember.VakSpeciality) : null,
    cooperationType: communityMember.CooperationType ? convertItem(communityMember.CooperationType) : null,
    description: getText(communityMember.Description),
    community: communityMember.Community ? convertCommunity(communityMember.Community, settings) : null,
    documents: convertMaybeArray(documents, i => convertDocument('local', i)),
    ...convertPersonHistory(communityMember),
  };
}

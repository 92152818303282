import React, { useCallback, useMemo } from 'react';

import { ReferenceItem } from 'components';

import { SecurityDocument, SecurityDocumentMember, SecurityDocumentMemberTypes } from 'types/models/SecurityDocument';
import { Department } from 'types/models/Project';
import { SecurityDocumentType } from 'utils/Enums';
import { getDepartmentFromMember } from '../../helpers';

type Props = {
  securityDocument: SecurityDocument;
  setSecurityDocument: React.Dispatch<React.SetStateAction<SecurityDocument>>;
};

export function useController({ setSecurityDocument, securityDocument }: Props) {
  const cloneAuthorsToAdmittedPersons = useCallback(() => {
    if (setSecurityDocument) {
      setSecurityDocument(prevPatent => {
        const prevAdmittedPersonIds =
          prevPatent?.members
            ?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON)
            .map(member => member.person?.id || '') || [];
        const authors = prevPatent?.members?.filter(p => p?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [];
        const filteredAuthorsFromDuplicatedPersons = authors.filter(
          member => !prevAdmittedPersonIds.includes(member.person?.id || ''),
        );
        const admitted = filteredAuthorsFromDuplicatedPersons.map(a => {
          return { ...a, id: null, type: { value: SecurityDocumentMemberTypes.ADMITTED_PERSON, label: '' } };
        });
        return { ...prevPatent!, members: [...(prevPatent?.members || []), ...admitted] };
      });
    }
  }, [setSecurityDocument]);

  const handleAuthorsChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      setSecurityDocument(prevState => {
        const newMember = value.find(
          member => !prevState.members.find(prevMember => prevMember?.person?.id === member.person?.id),
        );
        const newDepartment: Department | null = newMember ? getDepartmentFromMember(newMember) : null;
        return {
          ...prevState,
          members: value,
          ...(newDepartment &&
          !prevState.departments.some(
            x => x.unit?.id === newDepartment.unit?.id && x.governance?.id === newDepartment.governance?.id,
          )
            ? { departments: [...prevState.departments, newDepartment] }
            : null),
        };
      });
    },
    [setSecurityDocument],
  );

  const handleApplicantChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.APPLICANT) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setSecurityDocument],
  );

  const handleAdmittedChange = useCallback(
    (value: SecurityDocumentMember[]) => {
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => {
          const members = prevPatent?.members?.filter(p => p?.type?.value !== SecurityDocumentMemberTypes.ADMITTED_PERSON) || [];
          return { ...prevPatent!, members: [...members, ...value] };
        });
      }
    },
    [setSecurityDocument],
  );

  const handleCountriesChange = useCallback(
    (value: ReferenceItem[]) => {
      if (setSecurityDocument) {
        setSecurityDocument(prevPatent => ({ ...prevPatent!, countries: value }));
      }
    },
    [setSecurityDocument],
  );

  const authorsList = useMemo(
    () => securityDocument?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.AUTHOR) || [],
    [securityDocument],
  );

  const admittedPersonList = useMemo(
    () => securityDocument?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.ADMITTED_PERSON) || [],
    [securityDocument],
  );

  const applicantPersonList = useMemo(
    () => securityDocument?.members.filter(m => m?.type?.value === SecurityDocumentMemberTypes.APPLICANT) || [],
    [securityDocument],
  );

  const entityTitles: Record<string, string> = {
    PATENT: 'патента',
    EVIDENCE: 'свидетельства',
    KNOW_HOW: 'ноу-хау',
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const declinedTypeTitle = useMemo(() => (securityDocument?.type ? entityTitles[securityDocument.type.value] : ''), [
    securityDocument?.type,
  ]);

  const documentTitles: Record<string, string> = {
    PATENT: 'Заявка',
    EVIDENCE: 'Свидетельство',
    KNOW_HOW: 'Ноу-хау',
  };
  const isPatentDocument = useMemo(() => securityDocument?.type?.value === SecurityDocumentType.PATENT, [securityDocument]);
  const isKnowHowDocument = useMemo(() => securityDocument?.type?.value === SecurityDocumentType.KNOW_HOW, [securityDocument]);
  const isEvidenceDocument = useMemo(() => securityDocument?.type?.value === SecurityDocumentType.EVIDENCE, [securityDocument]);

  const leftColumnWidth = useMemo(() => (isPatentDocument || isKnowHowDocument ? '50%' : 'calc(100% - 720px)'), [
    isPatentDocument,
    isKnowHowDocument,
  ]);
  const rightColumnWidth = useMemo(() => (isPatentDocument || isKnowHowDocument ? '50%' : '720px'), [
    isPatentDocument,
    isKnowHowDocument,
  ]);

  return {
    handleApplicantChange,
    handleAdmittedChange,
    handleCountriesChange,
    cloneAuthorsToAdmittedPersons,
    documentTitles,
    isPatentDocument,
    isKnowHowDocument,
    isEvidenceDocument,
    leftColumnWidth,
    rightColumnWidth,
    authorsList,
    admittedPersonList,
    declinedTypeTitle,
    applicantPersonList,
    handleAuthorsChange,
  };
}

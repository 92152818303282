import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ListEdit, ReferenceFilter, ReferenceItem } from 'components';
import { Props as ListEditProps } from 'components/ListEdit/ListEdit';

import { GetReferenceElementList } from 'features/Table/specifications';

type OptionalProps = 'columns' | 'onChange';

type Props = {
  /** @property {string} modalTitle - Title for modal */
  modalTitle: string;
  /** @property {string} refName - Reference init */
  refName: string;
  /** @property {string} modalTitle - (optional) Filter of references grid */
  filters?: ReferenceFilter[];
  /** @property {string} modalTitle - (optional) Input key */
  isLeftPanelHidden?: boolean;
} & Omit<ListEditProps<{}, ReferenceItem>, 'specification' | OptionalProps> &
  Partial<Pick<ListEditProps<{}, ReferenceItem>, OptionalProps>>;

/**
 * Presetted/Customized ListEdit
 *
 * @param {string} modalTitle - Title for modal
 * @param {string} refName - Reference init
 * @param {ReferenceFilter[]} filters - (optional) Filter of references grid
 * @param {boolean} isLeftPanelHidden - (optional) Input key
 * @param {custom} restProps - (nested) List of nested props
 * @returns {JSX} JSX
 */
export const RefList = React.memo(
  ({ modalTitle, refName, columns, onChange, withoutHead = true, filters = [], isLeftPanelHidden, ...restProps }: Props) => {
    const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

    return (
      <ListEdit
        {...restProps}
        onChange={e => onChange?.(e)}
        columns={columns || [{ label: '', formatValue: x => x.label }]}
        withoutHead={withoutHead}
        specification={{
          mode: 'loadInstanceTableModal',
          onStartLoad: (tableRows, onSuccess) => {
            loadReferenceAPI.callAPI(
              { filters, referenceName: refName, childIds: tableRows.map(x => x.id) },
              { onSuccessfullCall: ({ data }) => onSuccess(data) },
            );
          },
          relationTableModalTitle: modalTitle,
          modalTableSpecification: GetReferenceElementList({ requestData: { name: refName, filters: [] }, isLeftPanelHidden }),
        }}
      />
    );
  },
);

import { Table } from 'types/models';

export function GetProgramDirectionList(programId: string): Table.Specification {
  const requestData = { programId };
  return {
    apiID: 'GetProgramDirectionList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

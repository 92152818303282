export function PersonalRequestGridHelp(): JSX.Element {
  return (
    <>
      <h3>Помощь для вкладки « Заявки на конкурс» в Личном кабинете</h3>

      <h4>Этот раздел предназначен для оформления заявки для участия в конкурсе, таких как:</h4>

      <ul>
        <li>
          <strong>«Заявка по академической мобильности»</strong> — для конкурсов из категории: Стажировки, повышение квалификации,
          Участие в научных мероприятиях;
        </li>
        <li>
          <strong>«Заявка на конкурс»</strong> » — для конкурсов из категории: Проведение научных мероприятий, Издание научных
          трудов, на выполнение НИОКР, Проекты в области предпринимательства и инноваций, Стипендии и премии, Прочие проекты;
        </li>
        <li>
          <strong>«Заявка на внутренний конкурс»</strong> — для участия в конкурсе на выполнение НИР и ОКР, организованном внутри
          университета.
        </li>
      </ul>

      <p>
        Для всех участников коллектива заявки (в роли: «исполнитель», «руководитель проекта», «ответственный исполнитель») в
        Личном кабинете и в карточке персоны заявка отобразится в списке на вкладке «Заявки на конкурсы». Список заявок можно
        выгрузить в файл с помощью кнопки Выгрузить в файл.
      </p>

      <div className="warning">
        <span>
          <strong>
            Для заявителя (в заявке по академической мобильности ) и для руководителя проекта и ответственного исполнителя (в
            заявке на конкурс)
          </strong>{' '}
          форма заявки доступна для редактирования в статусах: «Черновик» или «На доработку». В других статусах — форма заявки
          доступна для просмотра. Удалить заявку можно только в статусе «Черновик».
        </span>
      </div>
    </>
  );
}

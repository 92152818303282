import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';
import { Table } from 'types/models';

type Args = {
  tableState: Table.State;
};

export function useController({ tableState }: Args) {
  const [isHelpOpen, setIsHelpOpen] = useState<boolean>(false);
  const [isSalaryOpen, setIsSalaryOpen] = useState<boolean>(false);

  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Детализация по выплатам зарплаты',
        onClick: () => setIsSalaryOpen(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [tableState.selectedRows.length],
  );

  return {
    buttons,
    isHelpOpen,
    setIsHelpOpen,
    isSalaryOpen,
    setIsSalaryOpen,
    selectedRow,
  };
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { Table } from 'types/models';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { ProjectType } from 'utils/Enums';
import { showNotification } from 'features/Notifications';
import { useLocalTableStreams } from 'features/Table/hooks';

export function useController(tableState: Table.State, isArchive?: boolean) {
  const { userPermission } = useAppDataContext();
  const tableStreams = useLocalTableStreams();

  const selectedRowsLength = tableState.selectedRows.length;
  const selectedRow: Table.Entry | null = tableState.selectedRows[0] || null;

  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [nextPublicationName, setNextPublicationName] = useState('');
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);

  const { methods: resetProjectReportBlock } = BackendAPI.useBackendAPI('ResetProjectReportBlock', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Проект удалён из графика госрегистрации', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const { methods: deleteProjectReport } = BackendAPI.useBackendAPI('DeleteProjectReport', {
    onSuccessfullCall: () => {
      showNotification({ message: 'Отчёт удалён', theme: 'success' });
      tableStreams.reloadTable.push({});
    },
  });

  const handleViewButtonClick = useCallback(() => {
    setIsViewFormOpen(true);
  }, []);

  const handleTemplateCloseViewForm = useCallback(() => {
    setIsViewFormOpen(false);
  }, []);

  const handleAddButtonClick = useCallback(() => {
    setNextPublicationName('');
    setIsAddFormOpen(true);
  }, []);

  const handleTemplateCloseAddForm = useCallback(() => {
    setIsAddFormOpen(false);
  }, []);

  const handleEditButtonClick = useCallback(() => {
    setIsEditFormOpen(true);
  }, []);

  const handleTemplateCloseEditForm = useCallback(() => {
    setIsEditFormOpen(false);
  }, []);

  const projectType = useMemo(() => {
    if (selectedRow) {
      return selectedRow['id:ProjectType'] as ProjectType;
    }
  }, [selectedRow]);

  const reportId = useMemo(() => {
    if (selectedRow) {
      return selectedRow.id;
    }
  }, [selectedRow]);

  const viewPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_VIEW,
      GRANT: Permits.GRANT_VIEW,
      GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_VIEW,
      NIR: Permits.RESEARCH_JOB_VIEW,
    }),
    [],
  );

  const addPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationEdit',
      GRANT: 'GrantProjectStateRegistrationEdit',
      GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationEdit',
      NIR: 'ResearchJobProjectStateRegistrationEdit',
    }),
    [],
  );

  const editPermitsMap = useMemo<Record<ProjectType, string>>(
    () => ({
      ECONOMIC_AGREEMENT: 'EconomicContractProjectStateRegistrationEdit',
      GRANT: 'GrantProjectStateRegistrationEdit',
      GOVERMENT_CONTRACT: 'GovContractProjectStateRegistrationEdit',
      NIR: 'ResearchJobProjectStateRegistrationEdit',
    }),
    [],
  );

  const isViewDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED) {
      return !isHasPermission(userPermission, viewPermitsMap[projectType]);
    }
    return true;
  }, [projectType, rowsAvailability.SINGLE_SELECTED, userPermission, viewPermitsMap]);

  const isAddDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED) {
      return !isHasPermission(userPermission, addPermitsMap[projectType]);
    }
    return true;
  }, [addPermitsMap, projectType, rowsAvailability.SINGLE_SELECTED, userPermission]);

  const isEditDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED && reportId) {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, reportId, rowsAvailability.SINGLE_SELECTED, userPermission]);

  const isDeleteDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED && selectedRow?.IsBlockChangeAvailable === 'table') {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, rowsAvailability.SINGLE_SELECTED, selectedRow?.IsBlockChangeAvailable, userPermission]);

  const isArchiveDeleteDisabled = useMemo(() => {
    if (projectType && rowsAvailability.SINGLE_SELECTED) {
      return !isHasPermission(userPermission, editPermitsMap[projectType]);
    }
    return true;
  }, [editPermitsMap, projectType, rowsAvailability.SINGLE_SELECTED, userPermission]);

  const isViewHidden = useMemo(() => !isHasPermission(userPermission, Object.values(viewPermitsMap)), [
    userPermission,
    viewPermitsMap,
  ]);

  const isAddHidden = useMemo(() => !isHasPermission(userPermission, Object.values(addPermitsMap)), [
    addPermitsMap,
    userPermission,
  ]);

  const isEditHidden = useMemo(() => !isHasPermission(userPermission, Object.values(editPermitsMap)), [
    editPermitsMap,
    userPermission,
  ]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'question' },
        title: 'Помощь',
        onClick: () => setIsHelpModalOpen(true),
      },
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: handleViewButtonClick,
        isHidden: isViewHidden,
        isDisabled: isViewDisabled,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: handleAddButtonClick,
        isHidden: isAddHidden || true, // скрыто в рамках задачи 2223, удалять пока не стал на случай передумывания
        isDisabled: isAddDisabled,
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: handleEditButtonClick,
        isHidden: isEditHidden,
        isDisabled: isEditDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить из графика госрегистрации',
        onClick: () => resetProjectReportBlock.callAPI({ id: tableState.selectedRows[0]?.id }),

        isHidden: isEditHidden || isArchive,
        isDisabled: isDeleteDisabled,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить из архива',
        onClick: () => deleteProjectReport.callAPI({ id: tableState.selectedRows[0]?.id }),
        isHidden: isEditHidden || !isArchive,
        isDisabled: isArchiveDeleteDisabled,
      },
    ],
    [
      handleViewButtonClick,
      isViewHidden,
      isViewDisabled,
      handleAddButtonClick,
      isAddHidden,
      isAddDisabled,
      handleEditButtonClick,
      isEditHidden,
      isEditDisabled,
      isArchive,
      isDeleteDisabled,
      isArchiveDeleteDisabled,
      resetProjectReportBlock,
      tableState.selectedRows,
      deleteProjectReport,
    ],
  );

  return {
    isViewDisabled,
    isAddDisabled,
    isEditDisabled,
    isViewFormOpen,
    handleTemplateCloseViewForm,
    isEditFormOpen,
    handleTemplateCloseEditForm,
    isAddFormOpen,
    handleTemplateCloseAddForm,
    nextPublicationName,
    buttons,
    isHelpModalOpen,
    setIsHelpModalOpen,
  };
}

import React, { useEffect, useMemo } from 'react';

import {
  Checkbox,
  FormComponent,
  ListEdit,
  Select,
  SelectMode,
  Tab,
  Tabs,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
} from 'components';

import { Documents } from 'features/Documents';
import {
  GetEnterpriseCustomerSelectList,
  GetSecurityDocumentListForSecurityDocumentContract,
} from 'features/Table/specifications';
import { downloadFile, formatNumber, getAuthToken } from 'utils/Helpers';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useFormContext } from 'features/Form/hooks';
import { ReferenceItem, Table } from 'types/models';
import { getStatusMessage } from 'utils/Helpers/getStatusMessage';

import { PaymentFields } from './PaymentFields';
import { useController } from './controller';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
  editMode?: boolean;
};

function SecurityDocumentContractForm({ viewMode, editMode, onClose }: Props) {
  const {
    documents,
    endDate,
    handleDocumentsFieldChange,
    handleEnterpriseCustomerFieldChange,
    handleFormSubmit,
    handleIsTransferedFieldChange,
    handleKindFieldChange,
    handleNumberFieldChange,
    handlePaymentConditionFieldChange,
    handlePaymentKindFieldChange,
    handlePriceFieldChange,
    isTransfered,
    kind,
    number,
    paymentCondition,
    paymentKind,
    price,
    startDate,
    workMode,
    payments,
    handlePaymentsFieldChange,
    userToken,
    id,
    conclusionDate,
    handleConclusionDateChange,
    handleRegistrationDateChange,
    handleRegistrationNumberChange,
    registrationDate,
    registrationNumber,
    note,
    handleNoteChange,
    securityDocuments,
    handleSecurityDocumentsChange,
    enterpriseCustomerRowConverter,
    setStartDate,
    setEndDate,
    enterpriseCustomer,
    enterpriseCustomerLabel,
  } = useController({
    viewMode,
    editMode,
    onClose,
  });

  const token = getAuthToken();

  const {
    look: { setTitle },
  } = useFormContext();

  const disabled = useMemo(() => workMode === 'viewMode', [workMode]);

  useEffect(() => {
    setTitle?.(
      getStatusMessage(
        [
          (() => {
            if (workMode === 'addMode') {
              return 'Добавление';
            }
            if (workMode === 'viewMode') {
              return 'Просмотр';
            }
            return 'Редактирование';
          })(),
          'договора о распоряжении интеллектуальным правом',
        ]
          .filter(Boolean)
          .join(' '),
        id,
      ),
    );
  }, [id, setTitle, workMode]);

  return (
    <FormComponent.Template>
      <ControlPanel handleSave={handleFormSubmit} viewMode={viewMode} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="Договор">
            <FormComponent.ColumnWrapper>
              <FormComponent.Column>
                <FormComponent.Line>
                  <FormComponent.Field label="Вид договора" isRequired>
                    <Select
                      mode={SelectMode.REFERENCE}
                      value={kind}
                      onChange={handleKindFieldChange}
                      settings={{ name: 'RefContractKind', title: 'Справочник "Виды договоров"' }}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="ОИС">
                    <ListEdit
                      rows={securityDocuments}
                      onChange={handleSecurityDocumentsChange}
                      toolbar={['add', 'edit', 'delete']}
                      columns={[{ label: 'Наименование', formatValue: x => x.value }]}
                      isDeleteConfirmEnabled
                      withMessages
                      isDisabled={viewMode}
                      specification={{
                        mode: 'relationTableModal',
                        relationTableModalTitle: 'Справочник "Список ОИС"',
                        modalTableRowConverter: row => {
                          return { id: row.id, name: row.Label, value: row.Label };
                        },
                        modalTableSpecification: GetSecurityDocumentListForSecurityDocumentContract,
                      }}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Номер договора">
                    <TextInput value={number} onChange={handleNumberFieldChange} isDisabled={disabled} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Дата заключения договора" isRequired>
                    <TextDateTime value={conclusionDate} onChange={handleConclusionDateChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Действует с" isRequired>
                    <TextDateTime value={startDate || ''} onChange={setStartDate} isDisabled={disabled} />
                  </FormComponent.Field>
                  <FormComponent.Field label="по">
                    <TextDateTime value={endDate || ''} onChange={setEndDate} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Номер регистрации">
                    <TextInput value={registrationNumber} onChange={handleRegistrationNumberChange} />
                  </FormComponent.Field>
                  <FormComponent.Field label="Дата регистрации">
                    <TextDateTime value={registrationDate} onChange={handleRegistrationDateChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field
                    label="Заключен с"
                    isRequired
                    // eslint-disable-next-line max-len
                    tooltip='Важно! Укажите в справочнике "Заказчики" сектор деятельности заказчика. Данные необходимы для отчетов 2-Наука'
                  >
                    <TextArea
                      mode={TextAreaMode.TABLE}
                      settings={{
                        title: 'Список организаций из справочника "Заказчики"',
                        isClearable: false,
                        table: {
                          specification: GetEnterpriseCustomerSelectList(),
                          onSelect: (row: Table.Entry | null) =>
                            handleEnterpriseCustomerFieldChange(row ? enterpriseCustomerRowConverter(row) : null),
                        },
                        rows: 4,
                      }}
                      value={enterpriseCustomerLabel}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Сектор деятельности">
                    {(enterpriseCustomer?.customFields?.sectorActivity as ReferenceItem)?.label || ' - '}
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line>
                  <FormComponent.Field label="Тип организации по ЛКСД">
                    {(enterpriseCustomer?.customFields?.lksdEnterpriseType as ReferenceItem)?.label || ' - '}
                  </FormComponent.Field>
                </FormComponent.Line>
                {(!enterpriseCustomer?.customFields?.sectorActivity || !enterpriseCustomer?.customFields?.lksdEnterpriseType) && (
                  <FormComponent.Line>
                    <FormComponent.Field label="">
                      <FormComponent.Description mode="warning" classMixin="is-hint">
                        Важно! Укажите в справочнике "Заказчики" сектор деятельности заказчика и Тип организации по ЛКСД. Эти
                        данные необходимы для отчетов 2-Наука
                      </FormComponent.Description>
                    </FormComponent.Field>
                  </FormComponent.Line>
                )}
              </FormComponent.Column>
              <FormComponent.Column>
                <FormComponent.Line>
                  <FormComponent.Field label="Стоимость">
                    <TextInput
                      mode={TextInputMode.NUMBER}
                      value={price ? formatNumber(price.toString()) : ''}
                      onChange={handlePriceFieldChange}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Field>
                  <FormComponent.Field label="Вид оплаты">
                    <Select
                      mode={SelectMode.REFERENCE}
                      value={paymentKind}
                      onChange={handlePaymentKindFieldChange}
                      settings={{ name: 'RefPaymentKind', title: 'Справочник "Виды оплаты"', isClearable: true }}
                      isDisabled={disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Field>
                <FormComponent.Line>
                  <FormComponent.Field label="Условия оплаты">
                    <TextInput value={paymentCondition} onChange={handlePaymentConditionFieldChange} isDisabled={disabled} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Field label="">
                  <Checkbox
                    label="Передано в уставной фонд предприятия"
                    checked={!!isTransfered}
                    onChange={handleIsTransferedFieldChange}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
                <FormComponent.Line>
                  <FormComponent.Field label="Дополнительные сведения">
                    <TextArea
                      mode={TextAreaMode.MODAL}
                      settings={{ rows: 3 }}
                      value={note}
                      onChange={handleNoteChange}
                      isDisabled={!!disabled}
                    />
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </Tab>
          <Tab title="Платежи">
            <ListEdit
              header={{ title: 'Лицензионные отчисления по договору' }}
              rows={payments}
              onChange={handlePaymentsFieldChange}
              toolbar={[
                { key: 'edit', title: 'Прикрепить файл' },
                {
                  icon: { type: 'download' },
                  title: 'Скачать файл',
                  onClick: row => downloadFile(row?.file?.id, token),
                  isDisabled: row => !row?.file?.id || !userToken,
                },
              ]}
              columns={[
                { label: 'Дата', formatValue: x => x.receivingDocument?.cashDocument?.payDate || '' },
                { label: 'Номер документа', formatValue: x => x.receivingDocument?.cashDocument?.number || '' },
                {
                  label: 'Сумма',
                  formatValue: x => x.receivingDocument?.amount || '',
                  dataKind: 'float',
                },
                { label: 'В т.ч. НДС', formatValue: x => x.receivingDocument?.nds || '', dataKind: 'float' },
                { label: 'Файл', formatValue: x => x.file?.name || '' },
              ]}
              withMessages
              isDeleteConfirmEnabled
              isDisabled={disabled}
              columnIndexesForSumTotal={[2, 3]}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onFieldChange) => (
                  <PaymentFields entry={row} onFieldChange={onFieldChange as any} workMode={workMode} />
                ),
              }}
            />
          </Tab>
          <Tab title="Прикрепленные документы">
            <Documents
              documents={documents}
              setDocuments={handleDocumentsFieldChange}
              disabled={disabled}
              isShowSource
              sourceLabelForLocalDocs="Текущий договор на ИС"
              fieldsType="securityDocumentContract"
              isCanIsSharedEdit
              sharedLabel="Отображать в списке документов проекта и РИД"
              isSortEnabled
              isVisibleFilters
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(SecurityDocumentContractForm);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar as SharedToolbar } from 'components';

import { AwardsModal } from 'features/AwardsModal';
import { Table } from 'types/models';
import { State } from '../makeUseCustomController';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
  customState: State;
};

function Toolbar(props: Props) {
  const {
    tableState,
    customState: { closeAwards, isShowAwards, isViewMode },
  } = props;

  const { buttons, isViewFormOpen, handleTemplateCloseViewForm } = useController({ tableState, isViewMode });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.PaticipationViewTemplate.Component
          id={tableState.selectedRows[0]?.id || ''}
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          relatedTableState={tableState}
        />
      )}

      <AwardsModal isOpen={isShowAwards} onClose={closeAwards} id={tableState.selectedRows[0]?.id || ''} />
    </>
  );
}

export { Toolbar };

import React from 'react';

import { Checkbox, FormComponent, SectionTitle } from 'components';

import { Project } from 'types/models';
import { RestrictionList } from './list';

type Props = {
  project: Project.Project;
  setProject: React.Dispatch<React.SetStateAction<Project.Project>>;
  isDisabled: boolean;
};

export const Restrictions = React.memo(({ project, setProject, isDisabled }: Props) => {
  return (
    <>
      <SectionTitle title="Требования СМК" />

      <FormComponent.Line>
        <Checkbox
          label="Обязательная госрегистрация проекта"
          checked={project.isRequiredStateRegistration}
          onChange={e => setProject(prev => ({ ...prev, isRequiredStateRegistration: e }))}
          isDisabled={isDisabled}
        />
      </FormComponent.Line>
      <FormComponent.Line>
        <Checkbox
          label="Требования по ГОСТу РВ"
          checked={project.isRequiredMilitaryStandard}
          onChange={e => setProject(prev => ({ ...prev, isRequiredMilitaryStandard: e }))}
          isDisabled={isDisabled}
        />
      </FormComponent.Line>

      <SectionTitle title="Ограничения для проекта" />

      <FormComponent.Field style={{ maxWidth: '1000px' }}>
        <RestrictionList project={project} setProject={setProject} isDisabled={isDisabled} />
      </FormComponent.Field>
    </>
  );
});

import React, { useCallback, useMemo, useRef, useState } from 'react';

import { ButtonProps, Popover, Toolbar } from 'components';

import { Column } from 'types/models/Table';
import { ReloadTableArguments } from 'features/Table/types';
import { PopoverContent } from './PopoverContent';

export type Props = {
  columns: Column[];
  isTableExtended: boolean;
  isTableExtendable: boolean;
  reloadTable: (reloadTableArguments: ReloadTableArguments) => Promise<void>;
  resetFilters: () => Promise<void>;
  toggleIsExtended: () => Promise<void>;
  toggleIsFiltersShown: () => void;
  onHiddenColumnsChange: (nextHiddenColumnNames: string[]) => void;
};

export const ControlPanel = ({
  columns,
  isTableExtended,
  isTableExtendable,
  reloadTable,
  resetFilters,
  toggleIsExtended,
  toggleIsFiltersShown,
  onHiddenColumnsChange,
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);

  const onButtonClick = useCallback(() => {
    setIsOpen(true);
  }, []);

  const onClose = useCallback(() => {
    setIsOpen(false);
  }, []);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'filter' },
        title: 'Показать/спрятать фильтры',
        onClick: toggleIsFiltersShown,
      },
      {
        icon: { type: 'table' },
        refButton: buttonRef,
        title: 'Выбрать столбцы',
        onClick: onButtonClick,
      },
      {
        icon: { type: 'filter', mode: 'remove' },
        title: 'Очистить все поля фильтрации',
        onClick: resetFilters,
      },
      ...(isTableExtendable
        ? [
            {
              icon: { type: isTableExtended ? 'toSmall' : 'toFull' },
              title: isTableExtended ? 'Перейти в режим ввода' : 'Перейти в режим для анализа',
              onClick: toggleIsExtended,
            },
          ]
        : []),
      {
        icon: { type: 'refresh' },
        title: 'Обновить',
        onClick: () => reloadTable({ needResetSelectedRows: true }),
      },
    ],
    [toggleIsFiltersShown, onButtonClick, resetFilters, isTableExtendable, isTableExtended, toggleIsExtended, reloadTable],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <Popover
        isOpen={isOpen}
        onClose={onClose}
        anchorRef={buttonRef.current}
        position={{
          offset: 24,
          anchor: {
            vertical: 'top',
            horizontal: 'left',
          },
          content: {
            vertical: 'top',
            horizontal: 'left',
          },
        }}
      >
        <PopoverContent onClose={onClose} columns={columns} onHiddenColumnsChange={onHiddenColumnsChange} />
      </Popover>
    </>
  );
};

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

import { NirRequestStatus } from 'utils/Enums';

type RequestData = {
  isForProject: boolean;
  status?: string;
};

type Props = {
  isForProject: boolean;
  isClearToolbar?: boolean;
  statuses?: NirRequestStatus[];
  enumFilterValues?: Table.EnumFilterValues;
  hasSelectButton?: boolean;
};

export function GetNirRequestList({
  isClearToolbar = false,
  statuses,
  isForProject,
  enumFilterValues,
  hasSelectButton = true,
}: Props): Table.Specification<{}, RequestData> {
  return {
    apiID: 'GetNirRequestList',
    header: {
      firstLevel: {
        title: 'Заявки на внутренний конкурс',
        Panel: Filters,
      },
      thirdLevel: {
        LeftPanel: isClearToolbar ? undefined : LeftPanelForThirdLevel,
        withTemplatesPanel: true,
        hasSelectButton: hasSelectButton,
      },
    },
    requestData: {
      isForProject,
      status: statuses?.join(','),
    },
    enumFilterValues,
  };
}

import React, { memo } from 'react';
import { GetSecurityDocumentListForReport } from 'features/Table/specifications';
import { Table } from 'types/models';
import { DefaultCustomSelect } from '../DefaultCustomSelect';
import { BuilderProps } from '../../../models';
import { ReferenceItem } from 'components';

const SecurityDocumentSelect = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  return (
    <DefaultCustomSelect
      label="ОИС"
      parameterName={parameterName}
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
      modalTableRowConverter={(row: Table.Entry): ReferenceItem => {
        return {
          label: row.fullName || '',
          id: row.id || '',
        };
      }}
      modalTableSpecification={GetSecurityDocumentListForReport({ hasSelectButton: true })}
      relationTableModalTitle="Документы ОИС"
    />
  );
};

const Component = memo(SecurityDocumentSelect);

export { Component as SecurityDocumentSelect };

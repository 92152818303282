export function ForeignSecurityDocumentGridHelp(): JSX.Element {
  return (
    <>
      <p>
        Раздел содержит реестр добавленных пользователями личного кабинета сведения о своих авторских патентах и свидетельствах на
        изобретения, промышленные образцы, базы данных, программные продукты и прочие объекты, в которых университету не
        принадлежит право собственности.
      </p>
      <p>
        Вы можете добавить сведения о патентах и свидетельствах, переданных Университету, по лицензионному договору, с целью
        формирования Продукта для коммерциализации.
      </p>
    </>
  );
}

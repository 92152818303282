import React from 'react';
import { DataGrid } from 'features/Table';
import { GetMagazineReleaseArticleList } from 'features/Table/specifications';
import useController from './controller';
import { FormComponent } from 'components';

const MagazineReleaseArticles = () => {
  const { relatedTableState } = useController();
  return (
    <FormComponent.Wrapper>
      {relatedTableState && (
        <DataGrid
          specification={GetMagazineReleaseArticleList({
            relatedTableAPIID: 'GetMagazineReleaseArticleList',
            relatedTableState,
            relatedRecordId: relatedTableState.selectedRows.length ? relatedTableState.selectedRows[0]?.id : '',
          })}
        />
      )}
    </FormComponent.Wrapper>
  );
};

export const Component = React.memo(MagazineReleaseArticles);

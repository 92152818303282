import { memo } from 'react';

import { Button, ButtonMode, FormComponent } from 'components';

import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import useController from './controller';
import { getAuthToken, getFileUrl } from 'utils/Helpers';

type Props = {
  onApprove: (disapprove: boolean) => void;
  onClose: () => void;
  selectedRowId: string | null;
};

const ApprovePopup = ({ onClose, onApprove, selectedRowId }: Props) => {
  const { bibliographicRecord, members, file, publicationStatus } = useController({ selectedRowId });

  const token = getAuthToken();

  return (
    <FormComponent.Wrapper>
      <>
        {!!bibliographicRecord && (
          <>
            <FormComponent.Line>
              <strong>Библиографическая запись публикации:</strong>
            </FormComponent.Line>
            <FormComponent.Line>
              <span>{bibliographicRecord}</span>
            </FormComponent.Line>
          </>
        )}
        {!!members.length && (
          <>
            <h4>Авторы:</h4>

            <ul>
              {members.map(({ historyLabel, name }, index) => (
                <li key={index}>
                  <strong>{name}</strong> <span>{historyLabel}</span>
                </li>
              ))}
            </ul>
          </>
        )}
        {!!(file.id && file.name) && (
          <>
            <FormComponent.Line>
              <span>Скан-копия выходных данных:</span>
            </FormComponent.Line>
            <FormComponent.Line>
              <FormComponent.Link href={getFileUrl(file.id, token)} label={file.name} />
            </FormComponent.Line>
          </>
        )}
      </>
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text={publicationStatus === PublicationStatus.APPROVED ? 'Снять статус "утверждена"' : 'Утвердить'}
          onClick={() => onApprove(publicationStatus === PublicationStatus.APPROVED)}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(ApprovePopup);

export function PersonalForeignSecurityDocumentGridHelp(): JSX.Element {
  return (
    <>
      <p>
        В этом разделе вы можете добавить сведения о ваших патентах и свидетельствах на изобретения, промышленные образцы, базы
        данных, программные продукты и прочие объекты, в которых университету не принадлежит право собственности
      </p>
    </>
  );
}

export type State = {
  tenderId?: string;
};

export function makeUseCustomController({ tenderId }: State) {
  return function useCustomController(): State {
    return {
      tenderId,
    };
  };
}

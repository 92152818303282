import { Table } from 'types/models';

type RequestData = {
  scientistIDs: string[];
};

export function GetNirRequestPublicationList(requestData: RequestData): Table.Specification<object, RequestData> {
  return {
    apiID: 'GetNirRequestPublicationList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
    requestData,
  };
}

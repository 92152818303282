import * as XML from 'xml-js';

export function isErrorResponse(response: XML.ElementCompact) {
  return response.Response._attributes.success === 'false';
}

export function getErrorMessage(response: XML.ElementCompact) {
  return [
    response.Response._attributes.errorMessage,
    response.Response._attributes.serverErrorMessage,
    response.Response._attributes.detailErrorMessage,
  ]
    .filter(Boolean)
    .join('<br />');
}

import React, { memo, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import {
  Checkbox,
  Column,
  FormComponent,
  ListEdit,
  Radio,
  SectionTitle,
  Select,
  SelectMode,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  TextDateTime,
} from 'components';

import { Form as F, Event } from 'types/models';
import { Item } from 'types/models/common';
import { GetReferenceElementList } from 'features/Table/specifications';
import { useAppDataContext } from 'features/AppData/context';
import { EnterprisesComponent } from 'features/Form/looks/event/views/EnterprisesComponent';

type Props = {
  eventId?: string;
  formFields: F.Fields;
  viewMode?: boolean;
  termsList: F.EditionTypes;
  isSelectedPeriod: boolean;
  changeDepartments: (departments: Event.Department[]) => void;
  departments: Event.Department[];
  changeEnterprises: (enterprises: Event.Enterprise[]) => void;
  enterprises: Event.Enterprise[];
  refs: any //eslint-disable-line
  withHeaderId?: boolean;
};

const About = ({
  eventId,
  viewMode,
  formFields,
  termsList,
  isSelectedPeriod,
  changeDepartments,
  departments,
  changeEnterprises,
  enterprises,
  refs,
  withHeaderId,
}: Props) => {
  const { settings } = useAppDataContext();

  const { methods: loadReferenceAPI } = BackendAPI.useBackendAPI('GetReferenceElements');

  const columns = useMemo<Column<Event.Department>[]>(() => [{ label: '', formatValue: row => row.element?.label || '' }], []);

  const titleId = eventId && eventId !== 'undefined' ? ` (ID ${eventId})` : '';

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <SectionTitle title={'Творческое мероприятие' + (withHeaderId ? titleId : '')} />

        <FormComponent.Line>
          <FormComponent.Field label={formFields.eventSeries.title}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.eventSeries.value}
              onChange={formFields.eventSeries.onChange}
              settings={{ name: 'RefEventSeries', title: 'Справочник "Серии мероприятия"', isClearable: true }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.concertType.title} isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.concertType.value}
              onChange={formFields.concertType.onChange}
              settings={{ name: 'RefTypeConcert', title: 'Справочник "Типы мероприятия"' }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.eventStatus.title} isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.eventStatus.value}
              onChange={formFields.eventStatus.onChange}
              settings={{ name: 'RefEventStatus', title: 'Справочник "Уровни мероприятия"' }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="">
            <Checkbox
              label={formFields.isStudent.title}
              checked={formFields.isStudent.value}
              onChange={formFields.isStudent.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.name.title} isRequired>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.name.title }}
              value={formFields.name.value}
              onChange={formFields.name.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.termsType.title}
            isRequired={formFields.termsType.required}
            // eslint-disable-next-line max-len
            info={
              <>
                Из выпадающего списка выберите «Дата», если мероприятие в определенный день или выберите «Период», если сроки
                проведения от двух дней и более.
              </>
            }
          >
            <FormComponent.ColumnWrapper>
              <FormComponent.Column style={{ width: '20%' }}>
                <Select
                  value={
                    ({
                      ...formFields.termsType.value,
                      ...{ value: formFields.termsType.value.id },
                    } as unknown) as Item
                  }
                  options={termsList.map(i => ({ value: i.id, label: i.label } as Item))}
                  onChange={formFields.termsType.onChange}
                  isDisabled={!!viewMode}
                />
              </FormComponent.Column>
              <FormComponent.Column style={{ width: '80%' }}>
                <FormComponent.Field>
                  <span>
                    <TextDateTime
                      value={formFields.startDate.value || ''}
                      onChange={(value: string) => formFields.startDate.onChange(value)}
                      maxValue={!!isSelectedPeriod ? formFields.endDate.value || '' : ''}
                      isDisabled={!!viewMode}
                    />
                  </span>
                  {!!isSelectedPeriod && (
                    <>
                      <span>&nbsp;-&nbsp;</span>
                      <span>
                        <TextDateTime
                          value={formFields.endDate.value || ''}
                          onChange={(value: string) => formFields.endDate.onChange(value)}
                          minValue={formFields.startDate.value || ''}
                          isDisabled={!!viewMode}
                        />
                      </span>
                    </>
                  )}
                </FormComponent.Field>
              </FormComponent.Column>
            </FormComponent.ColumnWrapper>
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.description.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.description.title }}
              value={formFields.description.value}
              onChange={formFields.description.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.keyWords.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.keyWords.title }}
              value={formFields.keyWords.value}
              onChange={formFields.keyWords.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Column>
      <FormComponent.Column>
        <SectionTitle
          title="Место проведения мероприятия"
          info={<>В разделе «Место проведения» обязательно заполнить информацию о стране и городе проведения мероприятия</>}
        />

        <FormComponent.Line>
          <FormComponent.Field label="Формат проведения">
            <Radio list={refs.formatList} value={refs.formatVal.value} onChange={refs.formatChange} isDisabled={!!viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.site.title} isRequired={formFields.site.required}>
            <TextInput
              mode={TextInputMode.URL}
              value={formFields.site.value}
              onChange={formFields.site.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.country.title}>
            <Select
              mode={SelectMode.REFERENCE}
              value={formFields.country.value}
              onChange={formFields.country.onChange}
              settings={{ name: 'RefCountry', title: 'Справочник "Cтраны"', isClearable: true }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.city.title} info={<>Введите название города (не более 80 символов)</>}>
            <TextInput
              settings={{ maxLength: 80 }}
              value={formFields.city.value}
              onChange={formFields.city.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.address.title} info={<>Место проведения (не более 150 символов)</>}>
            <TextInput
              settings={{ maxLength: 150 }}
              value={formFields.address.value}
              onChange={formFields.address.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label={formFields.contact.title}>
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3, title: formFields.contact.title }}
              value={formFields.contact.value}
              onChange={formFields.contact.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <SectionTitle title="Организаторы мероприятия" isRequired />

        <FormComponent.Line>
          <ListEdit
            header={{ title: `Подразделения ${settings?.organization?.shortName}` }}
            rows={departments}
            onChange={changeDepartments}
            toolbar={['add', 'edit', 'delete', 'move']}
            columns={columns}
            isDisabled={!!viewMode}
            withoutHead
            specification={{
              mode: 'loadInstanceTableModal',
              onStartLoad: (tableRows, onSuccess) => {
                loadReferenceAPI.callAPI(
                  { filters: [], referenceName: 'RefDepartment', childIds: tableRows.map(x => x.id) },
                  { onSuccessfullCall: ({ data }) => onSuccess(data.map(e => ({ id: '', element: e, position: '' }))) },
                );
              },
              relationTableModalTitle: `Справочник "Подразделения ${settings?.organization?.shortName}"`,
              modalTableSpecification: GetReferenceElementList({ requestData: { filters: [], name: 'RefDepartment' } }),
            }}
          />
        </FormComponent.Line>
        <FormComponent.Line>
          <EnterprisesComponent enterprises={enterprises} setEnterprises={changeEnterprises} disabled={!!viewMode} />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
};

export const AboutComponent = memo(About);

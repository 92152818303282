import React from 'react';

import { ListEdit } from 'components';

import { Author } from 'types/models';
import { Fields, ChangeProps } from './Fields';
import { formatRole, formatAuthor, getMockAuthor, validate, formatHistory } from './helpers';
import { showNotification } from 'features/Notifications';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  authors: Author[];
  setAuthors(authors: Author[]): void;
  disabled?: boolean;
};

function MembersList(props: Props) {
  const { authors, setAuthors, disabled = false } = props;

  const handleChangeField = (rowSetter: React.Dispatch<React.SetStateAction<Author | null>>, changeProps: ChangeProps[]) => {
    rowSetter(prevAuthor => {
      const updatedAuthor = changeProps.reduce<Author | null>((acc, { key, value }) => ({ ...acc, [key]: value } as any), {
        ...getMockAuthor(),
        ...prevAuthor,
      });
      return updatedAuthor;
    });
  };

  return (
    <ListEdit
      rows={authors}
      onChange={setAuthors}
      toolbar={['add', 'edit', 'delete']}
      columns={[
        { label: 'ФИО', formatValue: formatAuthor },
        { label: 'Сведения о работе/обучении', formatValue: formatHistory },
        { label: 'Роль', formatValue: formatRole },
        { label: 'Контакты', formatValue: x => getPersonContacts(x.person?.contacts) },
      ]}
      maxHeight="none"
      defaultRowsCount={13}
      isDeleteConfirmEnabled
      isDisabled={disabled}
      withMessages
      specification={{
        mode: 'customComponent',
        validation: {
          checkIsValid: author => validate(author).every(x => x.isValid),
          onInvalidate: author => {
            const info = validate(author);
            info.forEach(({ isValid, invalidMessage }) => {
              if (!isValid) {
                setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
              }
            });
          },
        },
        renderComponent: (author, setAuthor) => <Fields author={author} onChange={val => handleChangeField(setAuthor, val)} />,
      }}
    />
  );
}

export { MembersList };

import { NirRequest, Program } from 'types/models';
import { Settings } from 'types/models/common';
import * as authorConverters from '../author/converters';
import {
  convertDocument,
  convertItem,
  convertMaybeArray,
  convertReference,
  getBoolean,
  getId,
  getText,
} from '../commonConverters';
import { convertServerPublication } from '../publication/converters';
import { convertIndicator } from '../program/converters';
import { convertLot, convertTender } from '../tenders/converters';
import { convertNirRequestKindConsumption } from '../kindConsumption/converters';
import { convertForeignSecurityDocument } from '../foreignSecurityDocument/converters';

export function convertNirRequest(request: any, settings: Settings): NirRequest.NirRequest {
  const performers = request.Performers?.Performer || [];
  const grntis = request.Grntis?.Grnti || [];
  const udks = request.Udks?.Udk || [];
  const oecds = request.Oecds?.Oecd || [];
  const pnrs = request.Pnrs?.Pnr || [];
  const pnis = request.Pnis?.Pni || [];
  const stages = request.Stages?.Stage || [];
  const ntrStrategies = request.NtrStrategies?.NtrStrategy || [];
  const criticalTechnologies = request.CriticalTechnologies?.CriticalTechnology || [];
  const indicators = request.Indicators?.Result || [];
  const indices = request.Indices?.Result || [];
  const estimatePositions = request.EstimatePositions?.Position || [];
  const expectedResults = request.ExpectedResults?.ExpectedResult || [];
  const stageTasks = request.StageTasks?.StageTask || [];
  const foreignPerformers = request.CollectiveForeignPerformers?.Performer || [];
  const ranPerformers = request.CollectiveRanPerformers?.Performer || [];
  const youngPerformers = request.CollectiveYoungPerformers?.Performer || [];
  const educationalPerformers = request.CollectiveEducationalPerformers?.Performer || [];
  const publications = request.Publications?.Publication || [];
  const notAffiliatedPublications = request.NotAffiliatedDescriptionPublications?.Publication || [];
  const collectiveProjects = request.CollectiveProjects?.Project || [];
  const collectivePublications = request.CollectivePublications?.Publication || [];
  const notAffiliatedCollectivePublications = request.NotAffiliatedCollectivePublications?.Publication || [];
  const collectiveConferenceParticipations = request.CollectiveConferenceParticipations?.Participation || [];
  const collectiveExpositionParticipations = request.CollectiveExpositionParticipations?.Participation || [];
  const collectiveBaseDissertations = request.CollectiveBaseDissertations?.BaseDissertation || [];
  const collectiveSecurityDocuments = request.CollectiveSecurityDocuments?.SecurityDocument || [];
  const approvements = request.Approvements?.Approvement || [];
  const tender = request.Tender ? convertTender(request.Tender, settings) : null;
  const savedIndicators = convertMaybeArray(indicators, convertIndicator);
  const savedIndices = convertMaybeArray(indices, convertIndicator);
  const mergedIndicators = getMergedIndicators(tender?.program?.indicators || [], savedIndicators);
  const mergedIndices = getMergedIndicators(tender?.program?.indices || [], savedIndices);

  return {
    annotation: getText(request.Annotation),
    approvements: convertMaybeArray(approvements, convertApprovement),
    collaborationParticipation: getText(request.CollaborationParticipation),
    collectiveBaseDissertations: convertMaybeArray(collectiveBaseDissertations, convertBaseDissertation),
    collectiveConferenceParticipations: convertMaybeArray(collectiveConferenceParticipations, convertParticipation),
    collectiveEducationalPerformers: convertMaybeArray(educationalPerformers, convertBasePerformerInfo),
    collectiveExpositionParticipations: convertMaybeArray(collectiveExpositionParticipations, convertParticipation),
    collectiveForeignPerformers: convertMaybeArray(foreignPerformers, convertBasePerformerInfo),
    collectiveProjects: convertMaybeArray(collectiveProjects, convertCollectiveProject),
    collectivePublications: convertMaybeArray(collectivePublications, p => convertServerPublication(p, settings)),
    collectiveRanPerformers: convertMaybeArray(ranPerformers, convertBasePerformerInfo),
    collectiveSecurityDocuments: convertMaybeArray(collectiveSecurityDocuments, convertSecurityDocument),
    collectiveYoungPerformers: convertMaybeArray(youngPerformers, convertBasePerformerInfo),
    criticalTechnologies: convertMaybeArray(criticalTechnologies, convertReference),
    department: request.Department ? convertReference(request.Department) : null,
    departmentText: getText(request.DepartmentTxt),
    departmentTxt: getText(request.DepartmentTxt),
    documents: [
      ...convertMaybeArray(request?.Documents?.Document || [], i => convertDocument('local', i)),
      ...convertMaybeArray(request?.ForeignDocuments?.Document || [], i => convertDocument('foreign', i)),
    ],
    economicSectorInterraction: getText(request.EconomicSectorInterraction),
    englishAnnotation: getText(request.EAnnotation),
    englishDepartmentTxt: getText(request.EDepartmentTxt),
    englishGoal: getText(request.EGoal),
    englishKeyWords: getText(request.EKeyWords),
    englishLeader: getText(request.ELeader),
    englishName: getText(request.EName),
    englishRelevance: getText(request.ERelevance),
    estimateExpencesJustification: getText(request.EstimateExpencesJustification),
    estimatePositions: convertMaybeArray(estimatePositions, convertEstimatePosition),
    expectedResults: convertMaybeArray(expectedResults, convertExpectedResult),
    goal: getText(request.Goal),
    grntis: convertMaybeArray(grntis, convertReference),
    id: request._attributes?.id ?? null,
    indicators: mergedIndicators,
    indices: mergedIndices,
    interdisciplinaryAproachJustification: getText(request.InterdisciplinaryAproachJustification),
    keyWords: getText(request.KeyWords),
    lot: request.Lot ? convertLot(request.Lot, settings) : null,
    name: getText(request.Name),
    notAffiliatedCollectivePublications: convertMaybeArray(notAffiliatedCollectivePublications, p =>
      convertServerPublication(p, settings),
    ),
    notAffiliatedDescriptionPublications: convertMaybeArray(notAffiliatedPublications, p =>
      convertServerPublication(p, settings),
    ),
    ntrStrategies: convertMaybeArray(ntrStrategies, convertReference),
    oecds: convertMaybeArray(oecds, convertReference),
    performers: convertMaybeArray(performers, p => convertPerformer(p, settings)),
    pnis: convertMaybeArray(pnis, convertReference),
    pnrJustification: getText(request.PnrJustification),
    pnrs: convertMaybeArray(pnrs, convertReference),
    problemResearchState: getText(request.ProblemResearchState),
    publications: convertMaybeArray(publications, p => convertServerPublication(p, settings)),
    registered: getText(request.Registered),
    relevance: getText(request.Relevance),
    requestNumber: getText(request.RequestNumber),
    researchMethods: getText(request.ResearchMethods),
    researchPracticalSignificance: getText(request.ResearchPracticalSignificance),
    scientificGroundwork: getText(request.ScientificGroundwork),
    scientificProblem: getText(request.ScientificProblem),
    specification: getText(request.Specification),
    stages: convertMaybeArray(stages, convertStage),
    stageTasks: convertMaybeArray(stageTasks, convertStageTask),
    status: convertItem(request.Status),
    tender: request.Tender ? convertTender(request.Tender, settings) : null,
    collectiveForeignProjects: convertMaybeArray(
      request.CollectiveForeignProjects?.ForeignProject || [],
      convertForeignCollectiveProject,
    ),
    collectiveForeignSecurityDocuments: convertMaybeArray(
      request.CollectiveForeignSecurityDocuments?.ForeignSecurityDocument || [],
      e => convertForeignSecurityDocument(e, settings),
    ),
    udks: convertMaybeArray(udks, convertReference),
  };
}

export function convertSecurityDocument(doc: any): NirRequest.SecurityDocument {
  return {
    id: getId(doc),
    authors: getText(doc.Authors),
    documentNumber: getText(doc.DocumentNumber),
    documentStartDate: getText(doc.DocumentStartDate),
    intellectualPropertyName: getText(doc.IntellectualPropertyName),
    type: convertItem(doc.Type),
    applicants: getText(doc.Applicants),
    admittedPersons: getText(doc.AdmittedPersons),
  };
}

export function convertApprovement(approvement: any): NirRequest.Approvement {
  return {
    id: getId(approvement),
    resolution: convertItem(approvement.Resolution),
  };
}

function getMergedIndicators(programIndicators: Program.Indicator[], saved: Program.Indicator[]) {
  return programIndicators
    .filter(({ isActive }) => isActive)
    .reduce((acc, indicator) => {
      const foundIndicator = acc.find(x => x.refResultItem.id === indicator.refResultItem.id);
      return foundIndicator ? acc : [...acc, { ...indicator, id: null }];
    }, saved);
}

export function convertParticipation(participation: any): NirRequest.Participation {
  return {
    id: participation._attributes.id,
    event: getText(participation.Event),
    bibliographicRecord: getText(participation.BibliographicRecord),
    typeReport: convertReference(participation.TypeReport),
    collectiveExhibitName: getText(participation.CollectiveExhibitName),
    developmentTxt: getText(participation.DevelopmentTxt),
    isWosScopus: getBoolean(participation.IsWosScopus),
    themeReport: getText(participation.ThemeReport),
  };
}

export function convertCollectiveProject(project: any): NirRequest.Project {
  return {
    id: project._attributes.id,
    type: convertItem(project.Type),
    endDate: getText(project.EndDate),
    startDate: getText(project.StartDate),
    leader: getText(project.Leader),
    name: getText(project.Name),
    programShortName: getText(project.ProgramShortName),
    foreignEnterprise: project.ForeignEnterprise ? convertReference(project.ForeignEnterprise) : null,
    totalAmount: getText(project.TotalAmount),
    amountByYear: getText(project.AmountByYear),
  };
}

export function convertForeignCollectiveProject(project: any): NirRequest.ForeignProject {
  return {
    id: project._attributes.id,
    endDate: getText(project.EndDate),
    startDate: getText(project.StartDate),
    leader: getText(project.Leader),
    name: getText(project.Name),
    programShortName: getText(project.ProgramShortName),
    enterprise: getText(project.Enterprise),
    totalAmount: getText(project.TotalAmount),
    amountByYear: getText(project.AmountByYear),
  };
}

function convertBasePerformerInfo(performer: any): NirRequest.BasePerformerInfo {
  return {
    performerId: performer._attributes.id,
    personId: null,
    role: null,
  };
}

function convertStageTask(item: any): NirRequest.StageTask {
  return {
    id: item._attributes.id,
    year: getText(item.Year),
    englishTask: getText(item.ETask),
    task: getText(item.Task),
  };
}

function convertExpectedResult(item: any): NirRequest.ExpectedResult {
  return {
    id: item._attributes?.id ?? null,
    year: getText(item.Year),
    result: getText(item.Result),
  };
}

export function convertEstimatePosition(position: any): NirRequest.NirRequestEstimatePosition {
  return {
    id: position._attributes?.id ?? null,
    amount: getText(position.Amount),
    note: getText(position.Note),
    kindConsumption: convertNirRequestKindConsumption(position.KindConsumption),
  };
}

function convertStage(stage: any): NirRequest.Stage {
  return {
    id: getId(stage),
    amount: getText(stage.Amount),
    name: getText(stage.Name),
    year: getText(stage.Year),
    universityEffect: getText(stage.UniversityEffect),
    regionalEffect: getText(stage.RegionalEffect),
    nationalEffect: getText(stage.NationalEffect),
  };
}

export function convertPerformer(performer: any, settings: Settings): NirRequest.Performer {
  return {
    id: performer?._attributes?.id || null,
    academicRank: performer.AcademicRank ? authorConverters.convertServerScientistAcademicRank(performer.AcademicRank) : null,
    citizenship: performer.Citizenship ? convertReference(performer.Citizenship) : null,
    degree: performer.Degree ? authorConverters.convertServerScientistDegree(performer.Degree) : null,
    education: performer.Education ? authorConverters.convertServerScientistEducation(performer.Education) : null,
    job: performer.Job ? authorConverters.convertServerScientistJob(performer.Job) : null,
    person: performer.Person ? authorConverters.convertServerPerson(performer.Person, settings) : null,
    rank: performer.Rank ? authorConverters.convertServerScientistRank(performer.Rank) : null,
    role: convertItem(performer.Role),
    historyLabel: getText(performer.HistoryLabel),
    collaborationParticipation: getText(performer.CollaborationParticipation),
    foreignPerformerQ1Q2PublicationQty: getText(performer.ForeignPerformerQ1Q2PublicationQty),
    personQ1Q2PublicationQty: getText(performer.PersonQ1Q2PublicationQty),
    economicContractParticipationExperience: getText(performer.EconomicContractParticipationExperience),

    awards: getText(performer.Awards),
    keyWords: getText(performer.KeyWords),
    scientificGroundwork: getText(performer.ScientificGroundwork),
    totalPublicationQty: getText(performer.TotalPublicationsQty),
    q1PublicationQty: getText(performer.Q1PublicationsQty),
    primePublications: getText(performer.PrimePublications),
    rids: getText(performer.Rntds),
    projects: getText(performer.Projects),
    note: getText(performer.Note),
  };
}

export function convertBaseDissertation(dissertation: any): NirRequest.BaseDissertation {
  return {
    id: getId(dissertation),
    author: getText(dissertation.Author),
    bibliographicRecord: getText(dissertation.BibliographicRecord),
    councilCipher: getText(dissertation.CouncilCipher),
    defencePlace: getText(dissertation.DefencePlace),
    degree: dissertation.Degree ? convertReference(dissertation.Degree) : null,
    vakSpeciality: dissertation.VakSpeciality ? convertReference(dissertation.VakSpeciality) : null,
    members: getText(dissertation.Members),
    name: getText(dissertation.Name),
    type: convertItem(dissertation.Type),
    year: getText(dissertation.Year),
  };
}

import { useCallback, useEffect, useState } from 'react';

import { Mode } from 'components/ListEdit/model';
import { Mode as ModeSpec } from 'features/Table/specifications/GetProjectScientistList/LeftPanelForThirdLevel';
import { Project } from 'types/models';
import { Member } from 'features/SelectPerson';
import { ScientistPerson } from 'types/models/Person';
import { PersonHistoryMode } from 'features/SelectPerson/PersonHistoryModal/model';
import { getMockJobPeriod } from 'features/Form/looks/project/ProjectForm/helpers';

type Arguments = {
  modeSpec: ModeSpec;
  person: ScientistPerson | null;
  history: Partial<Member>;
  jobPeriods: Project.JobPeriod[];
  setJobPeriods(jobPeriods: Project.JobPeriod[]): void;
  startProjectDate?: string;
  endProjectDate?: string;
};

export function useController(args: Arguments) {
  const { modeSpec, person, history, jobPeriods, setJobPeriods, startProjectDate, endProjectDate } = args;

  const [personHistoryMode, setPersonHistoryMode] = useState<PersonHistoryMode>('edit');
  const [isOpenHistoryPersonModal, setIsOpenHistoryPersonModal] = useState(false);
  const [selectedJobPeriodIndex, setSelectedJobPeriodIndex] = useState<number | null>(null);
  const [addJobPeriod, setAddJobPeriod] = useState<Project.JobPeriod>({
    ...getMockJobPeriod(),
    ...(modeSpec === 'add' ? history : {}),
    endDate: endProjectDate ?? '',
    startDate: startProjectDate ?? '',
  });

  useEffect(() => {
    setAddJobPeriod(prev => ({
      ...prev,
      ...history,
    }));
  }, [history]);

  useEffect(() => {
    if (modeSpec === 'add') {
      setJobPeriods([addJobPeriod]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [addJobPeriod, modeSpec]);

  const onClickHistoryIcon = useCallback((_: any, index: number | null) => {
    if (index !== null) {
      setSelectedJobPeriodIndex(index);
      setIsOpenHistoryPersonModal(true);
    }
  }, []);

  const submitHistoryPersonModal = useCallback(
    (member: Partial<Member>) => {
      if (selectedJobPeriodIndex !== null) {
        const updatedJobPeriods = [...jobPeriods];
        updatedJobPeriods[selectedJobPeriodIndex] = { ...jobPeriods[selectedJobPeriodIndex], ...member } as Project.JobPeriod;

        setJobPeriods(updatedJobPeriods);
      }
    },
    [setJobPeriods, jobPeriods, selectedJobPeriodIndex],
  );

  const onJobPeriodSubmit = useCallback(
    (row: Project.JobPeriod, submit: (updatedRow: Project.JobPeriod) => void, index: null | number, mode: Mode) => {
      const nextRow = row;
      const isAddMode = mode === 'add';

      if (isAddMode) {
        nextRow.person = person;
        setSelectedJobPeriodIndex(jobPeriods.length);
        setPersonHistoryMode('add');
        setIsOpenHistoryPersonModal(true);
      }

      submit(nextRow);
    },
    [jobPeriods.length, person],
  );

  const closePersonHistoryMode = useCallback(() => {
    setPersonHistoryMode('edit');
    setIsOpenHistoryPersonModal(false);
  }, []);

  return {
    personHistoryMode,
    selectedJobPeriodIndex,
    isOpenHistoryPersonModal,
    onJobPeriodSubmit,
    onClickHistoryIcon,
    closePersonHistoryMode,
    submitHistoryPersonModal,
    addJobPeriod,
    setAddJobPeriod,
  };
}

import { useMemo } from 'react';

import { CheckboxItem } from 'features/BuildReportPopup/models';
import { useAppDataContext } from 'features/AppData/context';
import { AuthorPaymentRequestStatus } from 'utils/Enums';

const useController = () => {
  const { enumMap } = useAppDataContext();

  const checkboxList = useMemo<CheckboxItem[]>(() => {
    const sortedEnum = [...enumMap.AuthorPaymentRequestStatus.values];
    const index = sortedEnum.findIndex(i => i.value === AuthorPaymentRequestStatus.PAID);
    const element = { ...sortedEnum[index] };
    sortedEnum.splice(index, 1);
    sortedEnum.splice(0, 0, element);

    return sortedEnum
      .filter(v => v.value === AuthorPaymentRequestStatus.PAID || v.value === AuthorPaymentRequestStatus.PAYMENT_APPROVED)
      .map(({ ordinal, label, value }) => ({
        isChecked: value === AuthorPaymentRequestStatus.PAID,
        label,
        value: ordinal.toString(),
      }));
  }, [enumMap.AuthorPaymentRequestStatus.values]);
  return {
    checkboxList,
  };
};

export default useController;

import React from 'react';

import { showNotification } from 'features/Notifications';
import { Person } from 'types/models';
import { ListEdit } from 'components';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';
import { Permits } from 'utils/Permissions';
import { validate } from './Fields/validate';
import { Fields } from './Fields';

type Props = {
  person: Person.ScientistPerson | null;
  disabled: boolean;
  onChangeHirshIndexes(indexes: Person.HirshIndex[]): void;
};

function Component(props: Props) {
  const { person, onChangeHirshIndexes, disabled } = props;
  const { userPermission } = useAppDataContext();

  return (
    <ListEdit
      rows={person?.hirshIndexes ?? []}
      onChange={onChangeHirshIndexes}
      toolbar={!disabled && ['add', 'edit', 'delete']}
      columns={[
        { label: 'Дата расчета', formatValue: row => row.date },
        { label: 'Система цитирования', formatValue: row => row.citationSystem?.label || '' },
        { label: 'Значение', formatValue: row => row.value ?? '' },
        { label: 'Примечание', formatValue: row => row.note ?? '' },
      ]}
      maxHeight="none"
      withMessages
      defaultRowsCount={6}
      isDeleteConfirmEnabled
      isDisabled={!isHasPermission(userPermission, Permits.HIRSH_INDEX_EDIT)}
      specification={{
        mode: 'customComponent',
        renderComponent: (hirshIndex, setHirshIndex) => <Fields hirshIndex={hirshIndex} onChange={setHirshIndex} />,
        validation: {
          checkIsValid: hirshIndex => validate(hirshIndex).every(x => x.isValid),
          onInvalidate: hirshIndex => {
            const info = validate(hirshIndex);
            info.forEach(({ isValid, invalidMessage }) => {
              if (!isValid) {
                setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
              }
            });
          },
        },
      }}
    />
  );
}

export const HirshIndexes = React.memo(Component);

import { useMemo, useState } from 'react';

import { ButtonProps } from 'components';

import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

const useController = ({ tableState }: Props) => {
  const selectedRowsLength = tableState.selectedRows.length;
  const rowsAvailability: Table.ToolbarStateRowsAvailability = {
    ALWAYS: true,
    SINGLE_SELECTED: selectedRowsLength === 1,
    MULTIPLE_SELECTED: selectedRowsLength >= 1,
  };

  const [isOpenPersonModal, setIsOpenPersonModal] = useState<boolean>(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsOpenPersonModal(true),
        isDisabled: !rowsAvailability.SINGLE_SELECTED,
      },
    ],
    [rowsAvailability.SINGLE_SELECTED],
  );

  return {
    buttons,
    isOpenPersonModal,
    setIsOpenPersonModal,
  };
};

export default useController;

import React from 'react';
import * as modalTemplates from '../modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { EditArticleSource, PublicationArticlesWarningPopup } from 'features/Form/views';
import { PublicationTypeCode } from 'types/models/Form';
import { EditRelationsProfileModal } from 'features/EditRelationsProfileModal';
import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { useController } from './controller';
// eslint-disable-next-line max-len
import { Component as PublicationOriginalTranslatesRelations } from 'features/Form/views/PublicationOriginalTranslatesRelations';
import { Component as FinancicalSupportPopup } from 'features/Form/views/FinancicalSupportPopup';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { useAppDataContext } from 'features/AppData/context';
import { DataGrid } from 'features/Table/container';
import { GetParticipationForPublicationList } from '../../GetParticipationForPublicationList';
import { PersonalPublicationGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

// const doiUrl = 'http://doi.org';
function Toolbar({ tableState }: Props) {
  const {
    SUCH_LIKE_PUBLICATION_ID,
    isArticlesWarningPopupOpen,
    isEditArticleSourceOpen,
    handleCloseArticlesWarningPopup,
    handleCloseEditArticleSource,
    handleSubmitDeleteWarningPopup,
    isAddFormOpen,
    isViewFormOpen,
    isEditFormOpen,
    handleContinueStep,
    isAddFormOpenConferenceParticipation,
    handleCloseFormConferenceParticipation,
    handleOpenFormConferenceParticipation,
    publicationConferenceParticipation,
    setPublicationConferenceParticipation,
    publicationConferenceSource,
    setPublicationConferenceSource,
    handleTemplateCloseAddForm,
    nextPublicationName,
    handleTemplateCloseViewForm,
    handleCloseEditForm,
    isDeleteConfirmPopupOpen,
    handleCloseDeleteConfirmPopup,
    handleConfirmDeleteConfirmPopup,
    buttons,
    isOpenEditRelations,
    handleCloseEditRelationsModal,
    isCitationFormOpen,
    handleCloseCitationForm,
    isSourceCitationDisabled,
    isSourceCitationOpen,
    handleCloseSourceCitation,
    isPublicationOriginalTranslatesRelationsOpen,
    handleClosePublicationOriginalTranslatesRelations,
    submitPublicationOriginalTranslatesRelationsOpen,
    isFinancialSupportOpen,
    handleCloseFinancialSupport,
    submitFinancialPopupCallback,
    isHelpFormOpen,
    handleTemplateCloseHelpForm,
    isReportOpen,
    onReportClose,
    currentReport,
    isParticipationModalOpen,
    setIsParticipationModalOpen,
    selectedRow,
    submitParticipationModal,
  } = useController({ tableState });

  const { settings } = useAppDataContext();

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {!(isAddFormOpen || isViewFormOpen || isEditFormOpen) && (
        <SuchLikePublication
          mode="add"
          isSearchable
          handleContinueStep={handleContinueStep}
          componentId={SUCH_LIKE_PUBLICATION_ID}
        />
      )}

      <modalTemplates.PublicationAddTemplate.Component
        isOpen={isAddFormOpen}
        onClose={handleTemplateCloseAddForm}
        name={nextPublicationName}
        relatedTableState={tableState}
        handleChangeParticipation={handleOpenFormConferenceParticipation}
        setPublication={setPublicationConferenceParticipation}
        setPublicationConferenceSource={setPublicationConferenceSource}
      />

      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={handleCloseEditForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          handleChangeParticipation={handleOpenFormConferenceParticipation}
        />
      )}

      {isAddFormOpenConferenceParticipation && (
        <modalTemplates.ConferenceParticipationAddTemplate.Component
          isOpen={isAddFormOpenConferenceParticipation}
          onClose={handleCloseFormConferenceParticipation}
          sourcePublication={publicationConferenceParticipation}
          sourceEvent={publicationConferenceSource}
        />
      )}

      {!!tableState.selectedRows.length && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={handleCloseDeleteConfirmPopup}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: handleCloseDeleteConfirmPopup,
            },
          ]}
          size="small"
        >
          <>
            (ID: {tableState.selectedRows[0]?.id}, тип: {tableState.selectedRows[0]?.PublicationType}, статус:{' '}
            {tableState.selectedRows[0]?.PublicationStatus}) Удалить публикацию?
          </>
        </Modal>
      )}

      {!!tableState.selectedRows.length && isOpenEditRelations && (
        <EditRelationsProfileModal
          title="Добавить связь публикации с подразделением"
          isOpen={isOpenEditRelations}
          onClose={handleCloseEditRelationsModal}
          id={tableState.selectedRows[0]?.id}
          type="PUBLICATION"
          errorText="У вас есть право добавить связь только со своими подразделениями.
           Внимание: право редактировать публикацию у вас появится при добавлении Основного подразделения.
            Если вы видите ошибки в публикации в статусе «Утверждена», то напишите письмо Администратору.
             В тексте письма обязательно укажите ID публикации."
        />
      )}

      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationCitationSystemTemplate.Component
          isOpen={isCitationFormOpen}
          onClose={handleCloseCitationForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {!isSourceCitationDisabled && (
        <modalTemplates.SourcePublicationCitationSystemTemplate.Component
          isOpen={isSourceCitationOpen}
          onClose={handleCloseSourceCitation}
          id={tableState.selectedRows[0]?.SourceId}
          relatedTableState={tableState}
        />
      )}

      {!!tableState.selectedRows.length && (
        <PublicationOriginalTranslatesRelations
          isOpen={isPublicationOriginalTranslatesRelationsOpen}
          onClose={handleClosePublicationOriginalTranslatesRelations}
          onSuccess={submitPublicationOriginalTranslatesRelationsOpen}
          publicationId={tableState.selectedRows[0]!.id}
          relatedTableState={tableState}
        />
      )}

      {!!tableState.selectedRows.length && (
        <FinancicalSupportPopup
          isOpen={isFinancialSupportOpen}
          relatedTableState={tableState}
          onClose={handleCloseFinancialSupport}
          publicationId={tableState.selectedRows.length ? tableState.selectedRows[0]?.id : ''}
          workMode="editMode"
          onSuccess={submitFinancialPopupCallback}
        />
      )}

      <Modal
        mode="help"
        title="Помощь в работе с публикациями"
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="medium"
      >
        {PersonalPublicationGridHelp()}
      </Modal>

      {!!tableState.selectedRows.length && (
        <PublicationArticlesWarningPopup
          isOpen={isArticlesWarningPopupOpen}
          onClose={handleCloseArticlesWarningPopup}
          onConfirm={handleSubmitDeleteWarningPopup}
          publicationTypeCode={tableState.selectedRows[0]['id:PublicationType'] as PublicationTypeCode}
        />
      )}

      {!!tableState.selectedRows.length && (
        <EditArticleSource
          isOpen={isEditArticleSourceOpen}
          onClose={handleCloseEditArticleSource}
          tableState={tableState}
          publicationId={tableState.selectedRows[0]?.id}
          publicationTypeCode={tableState.selectedRows[0]['id:PublicationType'] as PublicationTypeCode}
        />
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          department: settings?.userDepartment,
        }}
      />
      <Modal
        title="Список участий в конференциях"
        isOpen={isParticipationModalOpen}
        onClose={() => setIsParticipationModalOpen(false)}
        size={['extralarge', 'extralarge-height']}
      >
        <DataGrid
          specification={{
            ...GetParticipationForPublicationList({ publicationId: selectedRow?.id }),
            onSubmitTable: submitParticipationModal,
          }}
        />
      </Modal>
    </>
  );
}

export { Toolbar };

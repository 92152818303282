import { Author } from 'types/models';
import { Notification } from 'features/Notifications';

type Result = {
  nextNotification: Pick<Notification, 'message' | 'theme'>;
  invalidFieldKeys: string[];
  isFormValid: boolean;
};

export const validateAuthors = (authors: Author[]) => {
  const result: Result = {
    isFormValid: true,
    invalidFieldKeys: [],
    nextNotification: { theme: 'danger', message: '' },
  };

  if (!authors.length) {
    result.nextNotification.message = 'Заполните сведения об авторстве';
    result.isFormValid = false;
  }

  return result;
};

import { Mip, Subject } from 'types/models';
import { Settings } from 'types/models/common';

import { convertMaybeArray, getId, getText, convertItem, convertReference, convertDocument } from '../commonConverters';
import { convertRntd } from '../rntd/converters';
import { convertForeignSecurityDocument } from '../foreignSecurityDocument/converters';

export function convertSubject(subject: any, settings: Settings): Subject {
  const rntds = subject.Rntds?.Rntd ?? [];
  const mips = subject.Mips?.Mip ?? [];
  const udks = subject.Udks?.Udk ?? [];
  const okveds = subject.Okveds?.Okveds ?? [];
  const grntis = subject.Grntis?.Grnti ?? [];
  const lksetss = subject.Lksetss?.Lksets ?? [];
  const scienceBrunches = subject.ScienceBrunches?.ScienceBrunch ?? [];
  const scienceDomainInterrests = subject.ScienceDomainInterrests?.ScienceDomainInterrest ?? [];
  const pnrs = subject.Pnrs?.Pnr ?? [];
  const pnmitrs = subject.Pnmitrs?.Pnmitr ?? [];
  const pnis = subject.Pnis?.Pni ?? [];
  const ntrStrategies = subject.NtrStrategies?.NtrStrategy ?? [];
  const technologyPlatforms = subject.TechnologyPlatforms?.TechnologyPlatform ?? [];
  const priorityTechnologies = subject.PriorityTechnologies?.PriorityTechnology ?? [];
  const knowledgeBranches = subject.KnowledgeBranches?.KnowledgeBranch ?? [];
  const serverDocuments = subject.Documents?.Document || [];
  const serverForeignDocuments = subject.ForeignDocuments?.Document || [];
  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));
  const localDocuments = convertMaybeArray(serverDocuments, i => convertDocument('local', i));
  const documents = [...foreignDocuments, ...localDocuments];
  const foreignSecurityDocuments = subject.ForeignSecurityDocuments?.ForeignSecurityDocument || [];

  return {
    id: getId(subject),
    type: convertItem(subject.Type),
    code: getText(subject.Code),
    name: getText(subject.Name),
    description: getText(subject.Description),
    rntds: convertMaybeArray(rntds, e => convertRntd(e, settings)),
    mips: convertMaybeArray(mips, convertMip),
    udks: convertMaybeArray(udks, convertReference),
    okveds: convertMaybeArray(okveds, convertReference),
    grntis: convertMaybeArray(grntis, convertReference),
    lksetss: convertMaybeArray(lksetss, convertReference),
    scienceBrunches: convertMaybeArray(scienceBrunches, convertReference),
    scienceDomainInterrests: convertMaybeArray(scienceDomainInterrests, convertReference),
    pnrs: convertMaybeArray(pnrs, convertReference),
    pnmitrs: convertMaybeArray(pnmitrs, convertReference),
    pnis: convertMaybeArray(pnis, convertReference),
    ntrStrategies: convertMaybeArray(ntrStrategies, convertReference),
    technologyPlatforms: convertMaybeArray(technologyPlatforms, convertReference),
    priorityTechnologies: convertMaybeArray(priorityTechnologies, convertReference),
    knowledgeBranches: convertMaybeArray(knowledgeBranches, convertReference),
    documents,
    foreignSecurityDocuments: convertMaybeArray(foreignSecurityDocuments, e => convertForeignSecurityDocument(e, settings)),
  };
}

function convertMip(mip: any): Mip {
  return {
    id: getId(mip),
    enterpriseCustomer: convertReference(mip.EnterpriseCustomer),
  };
}

import { format } from 'date-fns';

import { CitationSystem, Partner } from 'types/models';
import { Contact, PeriodableReferenceItem } from 'types/models/Partner';
import { formatDateTimeStr } from 'utils/Constants';

export function getMockPeriodableReferenceItem(): PeriodableReferenceItem {
  return {
    id: null,
    endDate: null,
    startDate: null,
    note: null,
    ref: null,
  };
}

export function getMockCitationSystem(): CitationSystem {
  return {
    authors: '',
    bibliographicRecord: '',
    createdBy: '',
    id: '',
    identifier: '',
    keyWords: '',
    name: '',
    publicationType: { id: '', label: '' },
    refCitationSystem: { id: '', label: '' },
    url: '',
  };
}

export function getMockContact(): Contact {
  return {
    appointment: '',
    communication: '',
    department: '',
    extraContacts: '',
    id: null,
    isActual: false,
    isHead: false,
    note: '',
    person: null,
  };
}

export function getCurrentTime() {
  return format(new Date(), formatDateTimeStr);
}

export function getMockPartner(): Partner {
  return {
    activitySector: null,
    enterprise: null,
    enterpriseCustomer: null,
    bankRequisites: '',
    changes: [],
    citationSystems: [],
    city: '',
    contacts: [],
    country: null,
    createdDate: '',
    district: '',
    documents: [],
    email: '',
    relation: null,
    fullName: '',
    house: '',
    id: null,
    inn: '',
    isDeleted: false,
    kpp: '',
    lksd: null,
    manager: null,
    department: null,
    modifiedDate: '',
    office: '',
    ogrn: '',
    okopf: '',
    okveds: [],
    partnershipTypes: [],
    phone: '',
    postCode: '',
    shortName: '',
    status: null,
    state: null,
    street: '',
    webSite: '',
    childs: [],
    parent: null,
  };
}

export function getEnterpriseCustomersIds(partner: Partner | null) {
  if (!partner) {
    return [];
  }

  const enterpriseCustomerId = partner?.enterpriseCustomer?.id ?? '';

  return Array.from(new Set([...(partner?.childs ?? []).map(x => x.enterpriseCustomer?.id ?? ''), enterpriseCustomerId])).filter(
    Boolean,
  );
}

export function getEnterpriseIds(partner: Partner | null) {
  if (!partner) {
    return [];
  }

  const enterpriseId = partner?.enterprise?.id ?? '';

  return Array.from(new Set([...(partner?.childs ?? []).map(x => x.enterprise?.id ?? ''), enterpriseId])).filter(Boolean);
}

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';

export type PurchaseRequestData = {
  purchaseRequestId: string;
  isDisabledToolbar?: boolean;
  isFiltersHidden?: boolean;
};

export function GetPurchaseRequestFeedlineList(
  requestData: PurchaseRequestData,
): Table.Specification<State, PurchaseRequestData> {
  return {
    apiID: 'GetPurchaseRequestFeedlineList',
    header: {
      firstLevel: {
        title: 'Журнал сообщений',
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
      },
    },
    requestData,
    isFiltersHidden: true,
    useCustomController: makeUseCustomController(requestData),
  };
}

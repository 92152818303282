import { Icon, Tab, Tabs } from 'components';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function ExpositionGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список выставок</h4>

        <p>
          Таблица содержит список выставочных мероприятий университета, а также выставки, в которых принимали участие сотрудники и
          студенты университета.
        </p>
        <p>
          Фильтр <strong>Планируемые</strong> — для формирования списка записей, у которых дата начала больше текущей даты.
        </p>
        <p>
          Фильтр <strong>Все</strong> — для формирования списка всех мероприятий.
        </p>
        <p>
          Фильтр <strong>Организованные</strong> — для списка мероприятий, у которых в качестве организаторов указан университет
          и/или его подразделения.
        </p>
        <p>
          Фильтр <strong>Принято участие</strong> — для формирования списка мероприятий, других организаций, в которых приняли
          участие представители университета.
        </p>
        <p>
          Фильтр <strong>Год</strong> формирует список мероприятий, у которых сроки проведения содержат указанный год; если фильтр
          отключен, то список содержит все мероприятия базы данных. Фильтр накладывает условия отбора с учётом других фильтров.
        </p>
        <p>
          Представление в таблице можно настраивать «под себя» (описание на вкладке <strong>Настройка таблицы</strong>).
        </p>

        <p>Предусмотрены два режима представления информации в таблице:</p>
        <ul>
          <li>режим ввода с максимальным количеством столбцов равным 27 в таблице;</li>
          <li>режим анализа данных с количеством столбцов — 50.</li>
        </ul>

        <p>
          Основные столбцы таблицы содержат данные мероприятия; названия столбцов соответствуют названиям полей на форме
          мероприятии.
        </p>
        <p>
          Дополнительные столбцы — статистические данные по участникам мероприятий, вычисляемые динамически на основе данных
          функционального блока <strong>Мероприятия, Участия в выставках</strong>
        </p>

        <h4>Особенности вывода информации в дополнительных столбцах таблицы:</h4>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Название столбца</th>
              <th>Описание значения в столбце таблицы</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Кол-во участников, всего(план)</td>
              <td>
                Плановое значение из раздела Показатели результативности мероприятия, Всего участников мероприятия, план (форма
                Мероприятия, вкладка Организация мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, всего (факт)</td>
              <td>
                Фактическое значение из раздела Показатели результативности мероприятия, Всего участников мероприятия, факт (форма
                Мероприятия, вкладка Организация мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, зарубеж.</td>
              <td>
                Общее количество участников из категории «Приглашённые-зарубежные» (форма Мероприятия, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, местных</td>
              <td>
                Общее количество участников из категории «Приглашённые-местные» (форма Мероприятия, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, из &lt;Университет&gt;</td>
              <td>
                Общее количество участников из категории «Приглашённые-местные» (форма Мероприятия, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Кол-во участников, иногород.</td>
              <td>
                Общее количество участников из категории «Приглашённые-иногородние» (форма Мероприятия, вкладка Организация
                мероприятия).
              </td>
            </tr>
            <tr>
              <td>Участники</td>
              <td>
                Количество персон по данным из функционального блока Мероприятия, Участия в Олимпиадах и конкурсах. Данные — без
                дубликатов.
              </td>
            </tr>
            <tr>
              <td>Участники из &lt;Университет&gt;</td>
              <td>
                Количество персон, аффилированных с университетом по данным из функционального блока Мероприятия, Участия в
                Олимпиадах и конкурсах (на форме Мероприятия, вкладка Участники, раздел Статистические показатели конференции)
              </td>
            </tr>
            <tr>
              <td>Количество экспонатов</td>
              <td>
                Общее количество экспонатов, представленных участниками на выставке — по данным из функционального блока
                Мероприятия, Участия в выставках.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>

        <div className="warning">
          <span>Набор функциональных кнопок на панели зависит от наличия права пользователя на действие по кнопке.</span>
        </div>

        <table>
          <thead>
            <tr>
              <th style={{ width: '20%' }}>Функциональная кнопка</th>
              <th>Действие</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Открывает форму для просмотра информации о мероприятии. Кнопка активна при выделении записи в таблице</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>
                Открывает форму редактирования мероприятия. Кнопка активна при выделении записи в таблице.
                <br />
                Ограничения на редактирование данных на вкладках формы зависят от прав пользователя и статуса записи.
                <br />
                Так, например, пользователь всегда имеет право редактировать только «свою» запись в статусе «Добавлена» (если
                пользователь - автор записи, или в составе участников).
              </td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Удаляет выделенную в таблице запись. Ограничения на удаление записи зависит от прав пользователя и статуса записи.
                <br />
                Так, например, пользователь всегда имеет право удалить только «свою» запись в статусе «Добавлена» (если
                пользователь - автор записи). Запись может быть связана сдругими объектами в системе (участие в мероприятии)
              </td>
            </tr>
            <tr>
              <td>
                Процедура утверждения: <Icon type="like" />
              </td>
              <td>Открывает форму для процедуры Утверждения. Ограничения на удаление записи зависит от прав пользователя.</td>
            </tr>
            <tr>
              <td>
                Печать документов: <Icon type="print" />
              </td>
              <td>
                Открывает список шаблонов для формирования регламентированных документов.
                <div className="warning">
                  <span>Список доступных шаблонов отчётных форм зависит от наличия права пользователя на группу отчётов.</span>
                </div>
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>Список записей о мероприятиях выгружает из таблицы в файл .xls для последующей обработки в Excel</td>
            </tr>
            <tr>
              <td>
                Задать вопрос администратору: <Icon type="admin" />
              </td>
              <td>
                Открывает форму для отправки сообщения в подсистему коммуникаций Системы. Ответственные за направление лица.
                Напишут ответ на ваш запрос. Все ответы на вопросы пользователя хранятся в{' '}
                <strong>Личном кабинете в разделе Вопрос-Ответ.</strong>
              </td>
            </tr>
          </tbody>
        </table>

        <h4>Как утвердить мероприятие</h4>

        <p>
          Пользователь, который утверждает запись, обязан проверить правильность, актуальность и полноту заполнения данных о
          мероприятии в соответствии с внутренним регламентом и политикой учёта данных в системе:
        </p>

        <ul>
          <li>Для мероприятий, организованных Университетом, обязательны к заполнению все вкладки формы.</li>
          <li>
            Для других мероприятий, — только обязательные поля на вкладке <strong>О мероприятии</strong>. Утверждающий должен
            действовать.
          </li>
        </ul>

        <p>
          При заполнении всех обязательных полей отмеченных звездочкой <strong className="state-danger">*</strong>, мероприятие
          сохраняется в статусе <strong>Добавлена</strong>.
        </p>
        <p>
          Процедура утверждения мероприятии и перевода ее из статуса <strong>Добавлена</strong> в статус{' '}
          <strong>Утверждена</strong>:
        </p>

        <ol>
          <li>Выбрать запись о мероприятии, кликнув по ней кнопкой мыши.</li>
          <li>
            Нажать кнопку <strong>Процедура утверждения</strong> <Icon type="like" /> → откроется окно{' '}
            <strong>Процедура утверждения мероприятии</strong>.
          </li>
          <li>
            Если у мероприятия нет прикрепленных документов, то нажмите кнопку <strong>Добавить</strong> <Icon type="add" /> →
            откроется форма для внесения данных документа.
          </li>
          <li>
            Заполните поля <strong>Название документа</strong>
            <strong className="state-danger">*</strong>, <strong>Дата документа</strong>
            <strong className="state-danger">*</strong> и прикрепите <strong>Файл</strong>
            <strong className="state-danger">*</strong>.
          </li>
          <li>
            Нажмите кнопку <strong>Сохранить</strong> <Icon type="save" /> → Документ отобразится в списке{' '}
            <strong>Подтверждающие документы</strong>.
          </li>
          <li>
            Нажмите кнопку <strong>Утвердить</strong> → мероприятие присвоится статус <strong>Утверждена</strong>.
          </li>
        </ol>

        <p>
          Статус мероприятия можно увидеть в таблице в столбце <strong>Статус записи</strong>.
        </p>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

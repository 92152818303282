import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, FormComponent, Modal, ReferenceItem, Select, SelectMode, TextArea, Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { DataGrid } from 'features/Table';
import { TenderRequestType } from 'utils/Enums';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { RequestGridHelp } from './help';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const {
    buttons,
    handleConfirmDeleteConfirmPopup,
    handleTemplateCloseAddForm,
    handleTenderSelect,
    isAddFormOpen,
    isDeleteConfirmPopupOpen,
    isEditFormOpen,
    isTenderModalOpen,
    isViewFormOpen,
    nextPublicationName,
    setIsDeleteConfirmPopupOpen,
    setIsEditFormOpen,
    setIsTenderModalOpen,
    setIsViewFormOpen,
    tenderInfo,
    handleTemplateCloseHelpForm,
    isHelpFormOpen,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
    isCopyFormOpen,
    setIsCopyFormOpen,
    isNumberPrefixModalOpen,
    setIsNumberPrefixModalOpen,
    numberPrefix,
    setNumberPrefix,
    saveNumberPrefix,
    isReportOpen,
    onReportClose,
    currentReport,
  } = useController({ tableState });

  return (
    <>
      <Toolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.RequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.RequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.RequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={handleTemplateCloseAddForm}
          tenderInfo={tenderInfo}
          relatedTableState={tableState}
          name={nextPublicationName}
        />
      )}

      {isCopyFormOpen && (
        <modalTemplates.RequestCopyTemplate.Component
          isOpen={isCopyFormOpen}
          onClose={() => setIsCopyFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}

      {isDeleteConfirmPopupOpen && tableState.selectedRows.length > 0 && (
        <Modal
          mode="warning"
          title="Предупреждение"
          isOpen={isDeleteConfirmPopupOpen}
          onClose={() => setIsDeleteConfirmPopupOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: handleConfirmDeleteConfirmPopup,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsDeleteConfirmPopupOpen(false),
            },
          ]}
          size="small"
        >
          <>Заявка на конкурс от {tableState.selectedRows[0]?.Leader}. Удалить заявку?</>
        </Modal>
      )}

      <Modal
        mode="help"
        title='Помощь в работе с разделом "Заявки на конкурс"'
        isOpen={isHelpFormOpen}
        onClose={handleTemplateCloseHelpForm}
        size="large"
      >
        {RequestGridHelp()}
      </Modal>

      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.PROGRAM_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>

      {isStatusModalOpen && (
        <Modal
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: statusLabel,
              onClick: changeStatus,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsStatusModalOpen(false),
            },
          ]}
          size="small"
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </Modal>
      )}

      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />

      {isNumberPrefixModalOpen && (
        <Modal
          title="Присвоить Код для группы выделенных заявок"
          isOpen={isNumberPrefixModalOpen}
          onClose={() => setIsNumberPrefixModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Присвоить код',
              onClick: saveNumberPrefix,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsNumberPrefixModalOpen(false),
            },
          ]}
          size="small"
        >
          <FormComponent.Field>
            <strong>Внимание:</strong> Код будет присвоен для всех выделенных заявок. Если у какой-либо заявки уже есть код,
            система его заменит
          </FormComponent.Field>
          <FormComponent.Field>
            <Select
              mode={SelectMode.REFERENCE}
              value={numberPrefix}
              onChange={(option: ReferenceItem) => setNumberPrefix(option)}
              settings={{
                name: 'RefProjectRequestNumberPrefix',
                title: 'Справочник "Префикс для шифра заявок и номера проектов"',
                isClearable: true,
              }}
            />
          </FormComponent.Field>
        </Modal>
      )}

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          requestId: tableState.selectedRows[0]?.id,
        }}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import { parse, isAfter } from 'date-fns';

import { ReferenceItem } from 'components';

import { ScientificIndex as S } from 'types/models';
import { CitationSystem } from 'types/models/Form';
import { formatStr } from 'utils/Constants';
import { Field } from 'types/models/CitationSystemIndex';
import { checkIsReferenceItem } from 'services/BackendAPI/configurations/commonConverters';
import { showNotification } from 'features/Notifications';

export function validate(fields: Record<string, Field> = {}): boolean {
  function checkRequiredValues(items: Record<string, Field>): string[] {
    const emptyValuesFields = [...Object.values(items)].filter(x => {
      if (x.isRequired && checkIsReferenceItem(x.value)) {
        return !x.value?.id && !x.value?.label;
      }
      return x.isRequired && !x.value;
    });

    if (emptyValuesFields.length) {
      return [`Обязательные поля: (${emptyValuesFields.map(x => x.name).join(', ')}) не заполнены`];
    }

    return [];
  }

  function getInvalidDateMessage(items: Record<string, Field>): string[] {
    const dateField = Object.values(items).find(x => x.type === 'DATE');

    if (!dateField) {
      return [];
    }

    const parsedDate = parse(dateField.value as string, formatStr, new Date());

    if (parsedDate.getFullYear() < 2000) {
      return ['Дата не может быть раньше 01.01.2000'];
    }

    if (isAfter(parsedDate, new Date())) {
      return ['Дата не должна быть позднее текущей даты'];
    }

    return [];
  }

  function getInvalidYearMessage(items: Record<string, Field>): string[] {
    const yearField = Object.values(items).find(x => x.code === 'YEAR' && x.value);

    if (!yearField) {
      return [];
    }

    // eslint-disable-next-line
    if (isNaN(Number(yearField.value))) {
      return ['Некорректный год'];
    }

    const year = Number(yearField.value);

    if (year < 2000) {
      return ['Год не должен быть ранее 2000'];
    }

    const currentYear = new Date().getFullYear();
    if (year > currentYear) {
      return ['Год не может быть больше текущего'];
    }

    return [];
  }

  const messages = [...checkRequiredValues(fields), ...getInvalidDateMessage(fields), ...getInvalidYearMessage(fields)];

  if (messages.length) {
    showNotification({ theme: 'danger', message: messages.join('<br />') });
    return false;
  }

  return true;
}

export function isPostValidateResult(fields: Record<string, Field> = {}): boolean {
  const fieldCodes = ['THEMATIC_CATEGORY', 'QUARTILE', 'PROCENTILE', 'RATING'];
  const fieldsToValidate = ['QUARTILE', 'PROCENTILE', 'RATING'];

  const childFieldFields = Object.values(fields);

  const isThematicCategoryRelative =
    childFieldFields.every(child => fieldCodes.some(fieldCode => child.code === fieldCode)) &&
    childFieldFields.length === fieldCodes.length;

  if (isThematicCategoryRelative) {
    const filteredChilds = childFieldFields.filter(field =>
      fieldsToValidate.some(fieldToValidateCode => fieldToValidateCode === field.code),
    );

    const isValid = filteredChilds.some(field => {
      if (field.type === 'REFERENCE') {
        return Boolean((field.value as ReferenceItem).id);
      }
      return Boolean(field.value);
    });

    if (!isValid) {
      showNotification({
        theme: 'danger',
        message: `Заполните хотя бы одно поле (${filteredChilds.map(({ name }) => name).join(', ')})`,
      });
      return false;
    }
  }

  return true;
}

export function isInitialValidate({
  citationSystem,
  selectedScientificIndex,
}: {
  citationSystem: CitationSystem | null;
  selectedScientificIndex: S.ScientificIndex | null;
}): boolean {
  const fields = [
    { isValid: Boolean(citationSystem?.id), name: 'Система цитирования' },
    { isValid: Boolean(selectedScientificIndex?.id), name: 'Показатель' },
  ];

  const invalidFields = fields.filter(({ isValid }) => !isValid);

  if (invalidFields.length) {
    showNotification({
      theme: 'danger',
      message: `Обязательные поля: (${invalidFields.map(({ name }) => name).join(', ')}) не заполнены`,
    });
    return false;
  }

  return true;
}

import { GetBibliographicNameListRequest } from 'services/BackendAPI/configurations/types';
import { Table } from 'types/models';
import { makeUseCustomController, State as CutsomState } from './makeUseCustomController';
import { Toolbar } from './Toolbar';
import { Editor } from './Editor';
import { SubmitTable } from 'features/Table/streams';

type Arguments = {
  scientistId: string;
  onSubmitTable(state: SubmitTable): void;
};

export function GetScientistBibliographicNameList(
  args: Arguments,
): Table.Specification<CutsomState, GetBibliographicNameListRequest> {
  return {
    onSubmitTable: args.onSubmitTable,
    apiID: 'GetScientistBibliographicNameList',
    requestData: { scientistId: args.scientistId },
    useCustomController: makeUseCustomController(args),
    header: {
      secondLevel: {
        Component: Editor,
      },
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

import React, { useLayoutEffect, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';
import { LockableEntity } from 'types/models';

export const Filters = React.memo(() => {
  const initAvailableLockableEntity = { label: 'Все', value: 'All' };

  const [availableLockableEntities, setAvailableLockableEntities] = useState([initAvailableLockableEntity]);

  const { methods: getAvailableLockableEntities } = BackendAPI.useBackendAPI('GetAvailableLockableEntities', {
    onSuccessfullCall: ({ data }) => {
      const preparedData = data as LockableEntity[];
      const newAvailableLockableEntities = preparedData.map(i => ({ label: i.caption, value: i.name }));
      setAvailableLockableEntities([initAvailableLockableEntity].concat(newAvailableLockableEntities));
    },
  });

  useLayoutEffect(() => {
    getAvailableLockableEntities.callAPI({});
    // eslint-disable-next-line
  }, []);

  return FiltersComponent([
    {
      type: FilterComponentType.select,
      streamKey: 'entityType',
      options: availableLockableEntities,
      value: availableLockableEntities[0],
      classMixin: 'available-lockable-entities',
    },
  ]);
});

import { Parameter, ParameterListValue } from 'types/models';
import { PARTIAL_RADIO_VALUE } from './DefaultParameterList/helpers';

const queryBuilder = (parameter: Parameter) => {
  const value = parameter.value && (parameter.value as ParameterListValue);
  if (value) {
    const additionalValues = (value.additionalValues || [])
      .map(({ value: additionalValue, key }) => `${key}=${additionalValue ? 1 : 0}`)
      .join('&');
    const isRadio = Boolean(value.radioValue);
    if (isRadio) {
      const query =
        value.list.length && value.radioValue === PARTIAL_RADIO_VALUE
          ? `&${parameter.name}=${value.list.map(ref => ref.id).join(',')}&${additionalValues}`
          : value.radioValue === PARTIAL_RADIO_VALUE
          ? ''
          : `&${parameter.name}=-1`;
      return query;
    }

    const query = `&${parameter.name}=${value.list.map(ref => ref.id).join(',')}&${additionalValues}`;
    return query;
  }

  return '-1';
};

export default queryBuilder;

export function DissertationCouncilGridHelp(isDissertationCouncil: boolean, organization?: string): JSX.Element {
  return !!isDissertationCouncil ? (
    <p>
      Раздел предназначен для учёта общих сведений о диссертационных советах {organization}, а также сторонних организаций, в
      работе которых принимают участие сотрудники университета.
    </p>
  ) : (
    <>
      <p>
        Раздел содержит общие сведения о научных сообществах: коллаборациях, научно-технических советах, редакциях научных
        журналов {organization}, а также сторонних организаций, в работе которых принимают участие сотрудники университета.
      </p>
      <div className="warning">
        <span>
          Сведения о научно-техническом совете университета и членах совета используются при оформлении АКТа приёмки
          научно-исследовательской работы в научных проектах.
        </span>
      </div>
    </>
  );
}

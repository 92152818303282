import { Icon } from 'components';

export function PersonalCommunityMemberGridHelp(organizationName?: string): JSX.Element {
  return (
    <>
      <h4>
        Раздел Члены научных сообществ: Научно-технического совета, Редакции журнала, Коллаборации, Фонда/организации предназначен
        для ввода и хранения информации об участии в работе сообществ и организаций.
      </h4>

      <ul>
        <li>
          <strong>Фильтр «Мои сообщества»</strong> содержит список сообществ и организаций, в работе которых вы принимали участие
        </li>
        <li>
          <strong>Фильтр «Все сообщества»</strong> содержит список участников всех научных сообществ в типе редакция журнала,
          фонд/организация, коллабарация и начуно –техническое сообщество {organizationName} и сообществ других организаций с
          участниками из {organizationName}
        </li>
      </ul>

      <p>
        Информацию о научных сообществах Университета и составе его участников в систему заносят ответственные лица. Если вы не
        видите запись о своем участии или являетесь членом научного сообщества сторонней организации, внесите об этом информацию в
        данном разделе Личного кабинета.
      </p>

      <h4>В разделе Членство в научных сообществах и организациях вы можете:</h4>

      <ul>
        <li>
          Посмотреть запись об участии в работе сообщества с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новую запись о работе в мообществе с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать запись о работе в составе сообщества с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Удалить запись, которую вы создавали, с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Выгрузить список ваших участий в составах сообществ и организаций в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки <Icon type="admin" />
        </li>
      </ul>

      <p>
        При сохранении записи присваивается статус <strong>Добавлена</strong>. Добавленную запись можно редактировать, вносить
        информацию или удалить.
      </p>
      <p>
        Добавленную вами запись об участии в работе сообщества проверит и переведет в статус <strong>Утверждена</strong>{' '}
        Администратор данных. Утвержденная запись недоступна для внесения изменений. Если вы нашли ошибку в утвержденной записи -
        напишите письмо Администратору.
      </p>
    </>
  );
}

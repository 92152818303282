import React, { memo } from 'react';
import { block } from 'bem-cn';

import { Radio, FormComponent, TextInput, TextInputMode, Select } from 'components';

import { Item } from 'types/models/common';
import useController from './controller';
import { BuilderProps } from 'features/BuildReportPopup/models';

import './style.scss';

const b = block('payment-request-period');

const PaymentRequestPeriod = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const {
    year,
    periodRadioValue,
    currentQuarter,
    monthFrom,
    monthTo,
    periodParameter,
    canBuild,
    periodRadioList,
    isQuarterPeriod,
    isMonthPeriod,
    handleUpdatePeriodRadioValue,
    handleUpdateYear,
    handleUpdateMonth,
    handleUpdateQuarter,
    MONTH_LIST_OPTIONS,
    QUARTER_LIST_OPTIONS,
  } = useController({ parameters, setParameterValueByName, parameterName });

  return (
    <>
      {canBuild && (
        <div className={b()}>
          <FormComponent.Line>
            <FormComponent.Field label={periodParameter?.description || ''} isRequired={!!periodParameter?.required}>
              <FormComponent.Field>
                <FormComponent.Line>
                  <FormComponent.Field label="Год" labelSize="fit">
                    <TextInput mode={TextInputMode.YEAR} value={year} onChange={handleUpdateYear} />
                  </FormComponent.Field>
                </FormComponent.Line>
                <FormComponent.Line hasFreeFormat>
                  <FormComponent.Field>
                    <Radio
                      isInRow={false}
                      value={periodRadioValue}
                      list={periodRadioList}
                      onChange={handleUpdatePeriodRadioValue}
                      classMixin="line-offset"
                    />
                  </FormComponent.Field>
                  <FormComponent.Field>
                    <FormComponent.Line>
                      <FormComponent.Field labelSize="fit" isRequired={isQuarterPeriod} label="Квартал">
                        <Select
                          value={currentQuarter ?? null}
                          options={QUARTER_LIST_OPTIONS}
                          onChange={(option: Item) => handleUpdateQuarter(option.value)}
                          isDisabled={!isQuarterPeriod}
                        />
                      </FormComponent.Field>
                    </FormComponent.Line>
                    <FormComponent.Line>
                      <FormComponent.Field labelSize="fit" isRequired={isMonthPeriod} label="Месяц с">
                        <Select
                          value={monthFrom ?? null}
                          options={MONTH_LIST_OPTIONS}
                          onChange={(option: Item) => handleUpdateMonth({ isFrom: true })(option.value)}
                          isDisabled={!isMonthPeriod}
                        />
                      </FormComponent.Field>
                      <FormComponent.Field labelSize="fit" isRequired={isMonthPeriod} label=" - по">
                        <Select
                          value={monthTo ?? null}
                          options={MONTH_LIST_OPTIONS}
                          onChange={(option: Item) => handleUpdateMonth({ isFrom: false })(option.value)}
                          isDisabled={!isMonthPeriod}
                        />
                      </FormComponent.Field>
                    </FormComponent.Line>
                  </FormComponent.Field>
                </FormComponent.Line>
              </FormComponent.Field>
            </FormComponent.Field>
          </FormComponent.Line>
        </div>
      )}
    </>
  );
};

const Component = memo(PaymentRequestPeriod);

export { Component as PaymentRequestPeriod };

import { useMemo, useState, useLayoutEffect, useCallback } from 'react';

import { Parameter } from 'types/models';
import { Item } from 'types/models/common';
import { PrintFormat } from 'utils/Enums/PrintFormat';
import { BuilderProps } from 'features/BuildReportPopup/models';

type Props = object & BuilderProps;

const initialValues: Item[] = [
  { value: PrintFormat.XLSX.id, label: PrintFormat.XLSX.label },
  { value: PrintFormat.DOCX.id, label: PrintFormat.DOCX.label },
  { value: PrintFormat.RTF.id, label: PrintFormat.RTF.label },
  { value: PrintFormat.PDF.id, label: PrintFormat.PDF.label },
  { value: PrintFormat.ODT.id, label: PrintFormat.ODT.label },
  { value: PrintFormat.ODS.id, label: PrintFormat.ODS.label },
];

const useController = ({ parameters, setParameterValueByName, parameterName }: Props) => {
  const [values, setValues] = useState<Item[]>([]);
  const [currentValueId, setCurrentValueId] = useState<string>('');

  const printFormatParameter = useMemo<Parameter | null>(
    () => parameters.find(parameter => parameter.name === parameterName) || null,
    [parameters, parameterName],
  );
  const canBuild = useMemo<boolean>(() => !!printFormatParameter, [printFormatParameter]);

  const currentValue = useMemo(() => values.find(value => value.value === currentValueId), [values, currentValueId]);

  const setParameterValue = useCallback(
    ({ nextValueId }: { nextValueId?: string }) => {
      const usedValueKey = nextValueId || currentValueId;
      const usedValue = values.find(i => i.value === usedValueKey);
      if (usedValue) {
        setParameterValueByName({ name: parameterName, value: usedValue.value });
      }
    },
    [currentValueId, parameterName, setParameterValueByName, values],
  );

  useLayoutEffect(() => {
    const defaultKey = printFormatParameter?.default || PrintFormat.XLSX.id;
    const filterInitialValues = printFormatParameter?.allowedValues
      ? initialValues.filter(({ value }) => printFormatParameter?.allowedValues?.some(i => i === value))
      : initialValues;
    setParameterValue({ nextValueId: defaultKey });
    setCurrentValueId(defaultKey);
    setValues(filterInitialValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setNextValueId = useCallback(
    (nextValueLabel: string) => {
      const nextValue = values.find(value => value.value === nextValueLabel);

      if (nextValue) {
        setParameterValue({ nextValueId: nextValue.value });
        setCurrentValueId(nextValue.value);
      }
    },
    [values, setParameterValue],
  );

  return {
    canBuild,
    currentValue,
    setNextValueId,
    printFormatParameter,
    values,
  };
};

export default useController;

import { Partner } from 'types/models';
import { isOKOPFValid } from 'utils/Validators';

export function validate(partner: Partner) {
  const isValidManager = !!partner.manager?.id;
  const isValidDepartment = !!partner.department?.id;
  const isValidState = Boolean(partner.state && partner.state.id);
  const isValidFullName = Boolean(partner.fullName);
  const isValidOkopf = partner.okopf ? isOKOPFValid(partner.okopf) : true;
  const isValidPartnershipTypes = !!partner.partnershipTypes.length;

  return [
    { isValid: isValidManager, invalidMessage: 'Укажите ответственного менеджера' },
    { isValid: isValidDepartment, invalidMessage: 'Укажите ответственное подразделение' },
    { isValid: isValidState, invalidMessage: 'Укажите состояние организации' },
    { isValid: isValidFullName, invalidMessage: 'Введите полное название' },
    { isValid: isValidOkopf, invalidMessage: 'ОКОПФ должен быть введён в формате "X XX XX", где X - цифра 0-9' },
    { isValid: isValidPartnershipTypes, invalidMessage: 'Укажите минимум один тип партнёрства' },
  ];
}

import React from 'react';

import { Content, Tabs, Tab } from 'components';

import { DataGrid } from 'features/Table';
import { GetContractPaymentLogList } from 'features/Table/specifications';
import { GetLicenceAgreementContractList } from 'features/Table/specifications/GetLicenceAgreementContractList';
import { useRouteNavigation } from 'utils/Common';

export function ContractPayments() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['commercialization', 'contract-payments'],
    init: ['list'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Журнал поступления лицензионных платежей" id="list">
          <DataGrid specification={GetContractPaymentLogList()} />
        </Tab>
        <Tab title="План - график платежей по договорам" id="plan">
          <DataGrid specification={GetLicenceAgreementContractList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

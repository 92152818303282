import React, { useEffect } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode, TextInput, TextDateTime } from 'components';

import { Person } from 'types/models';
import { Item } from 'types/models/common';
import { isHasPermission } from 'features/AppData';
import { Permits } from 'utils/Permissions';
import { useAppDataContext } from 'features/AppData/context';
import { JobStatus } from 'utils/Enums';
import { getMockJob } from 'features/Form/looks/person/helpers';
import { Mode } from '..';
import { getEnumItem } from 'utils/Helpers';

type Props = {
  mode?: Mode;
  job: Person.ScientistJob | null;
  onChange: React.Dispatch<React.SetStateAction<Person.ScientistJob | null>>;
};

export function Fields({ mode, job = getMockJob(), onChange }: Props) {
  const { userPermission, settings, enumMap } = useAppDataContext();

  useEffect(() => {
    if (!job) onChange(getMockJob({ status: getEnumItem('JobStatus', JobStatus.OTHER, enumMap) }));
  }, [enumMap, job, onChange]);

  const isDisabled = !!job?.isReadOnly;

  const makeChangeHandler = (fieldName: keyof Person.ScientistJob) => (value: Item | null | ReferenceItem | string) => {
    onChange({ ...job!, [fieldName]: value });
  };

  const handleChangeRefAppointment = (ref: ReferenceItem) => {
    onChange({ ...job!, refAppointment: ref, appointment: ref?.label || '' });
  };

  const handleChangeRefDepartment = (ref: ReferenceItem | null) => {
    onChange({ ...job!, refDepartment: ref, department: ref?.label || '' });
  };

  const handleChangeStatus = (option: Item | null) => {
    if (!option) return;
    if (option?.value === JobStatus.LOCAL) {
      onChange({ ...job!, status: option, enterpise: settings?.organization?.shortName ?? '' });
    } else {
      onChange({ ...job!, status: option, enterpise: '', staffState: null });
    }
  };

  const isLocalStatus = job?.status?.value === JobStatus.LOCAL;
  const isContractStatus = job?.status?.value === JobStatus.CIVIL_CONTRACT;
  const isOtherStatus = job?.status?.value === JobStatus.OTHER;

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Статус" isRequired>
          <Select
            mode={SelectMode.ENUM}
            value={job?.status}
            settings={{ name: 'JobStatus' }}
            onChange={handleChangeStatus}
            isDisabled={isDisabled || !isHasPermission(userPermission, Permits.PERSON_LOCAL_JOB_CREATE) || mode === 'lk'}
          />
        </FormComponent.Field>
        {(isLocalStatus || isContractStatus) && (
          <FormComponent.Field label="Дата приема" isRequired>
            <TextDateTime value={job?.dateReceipt} onChange={makeChangeHandler('dateReceipt')} isDisabled={isDisabled} />
          </FormComponent.Field>
        )}
        {(isLocalStatus || isContractStatus) && (
          <FormComponent.Field label="Дата увольнения">
            <TextDateTime value={job?.dateDismissal} onChange={makeChangeHandler('dateDismissal')} />
          </FormComponent.Field>
        )}
      </FormComponent.Line>

      {(isOtherStatus || isLocalStatus) && (
        <FormComponent.Line>
          <FormComponent.Field label="Организация" isRequired>
            <TextInput value={job?.enterpise} onChange={makeChangeHandler('enterpise')} isDisabled={isDisabled} />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {isOtherStatus && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Должность" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={job?.refAppointment}
                onChange={handleChangeRefAppointment}
                settings={{ name: 'RefAppointment', title: 'Справочник "Должности"' }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Подразделение">
              <TextInput
                value={job?.department || undefined}
                onChange={makeChangeHandler('department')}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
      {isContractStatus && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Название договора">
              <TextInput
                value={job?.civilContractName || ''}
                onChange={makeChangeHandler('civilContractName')}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Номер договора">
              <TextInput
                value={job?.civilContractNumber || ''}
                onChange={makeChangeHandler('civilContractNumber')}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Дата договора">
              <TextDateTime
                value={job?.civilContractDate}
                onChange={makeChangeHandler('civilContractDate')}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
      {(isLocalStatus || isContractStatus) && (
        <>
          <FormComponent.Line>
            <FormComponent.Field label="Должность" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={job?.refAppointment}
                onChange={handleChangeRefAppointment}
                settings={{ name: 'RefAppointment', title: 'Справочник "Должности"' }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Группа персонала">
              <Select
                mode={SelectMode.REFERENCE}
                value={job?.staffGroup}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('staffGroup')(option)}
                settings={{ name: 'RefStaffGroup', title: 'Справочник "Группы персонала"', isClearable: true }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Категория должности">
              <Select
                mode={SelectMode.REFERENCE}
                value={job?.appointmentCategory}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('appointmentCategory')(option)}
                settings={{ name: 'RefAppointmentCategory', title: 'Справочник "Категории должностей"', isClearable: true }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Тип состава">
              <Select
                mode={SelectMode.REFERENCE}
                value={job?.compoundType}
                onChange={(option: ReferenceItem | null) => makeChangeHandler('compoundType')(option)}
                settings={{ name: 'RefCompoundType', title: 'Справочник "Тип состава"', isClearable: true }}
                isDisabled={isDisabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      )}
      {isLocalStatus && (
        <FormComponent.Line>
          <FormComponent.Field label="Штатное состояние" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={job?.staffState}
              onChange={(option: ReferenceItem | null) => makeChangeHandler('staffState')(option)}
              settings={{ name: 'RefStaffState', title: 'Справочник "Штатное состояние"' }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {(isLocalStatus || isContractStatus) && (
        <FormComponent.Line>
          <FormComponent.Field
            label="Подразделение"
            tooltip={`${isContractStatus ? 'Выберете "Университет", если подразделение не известно' : ''}`}
          >
            <Select
              mode={SelectMode.REFERENCE}
              value={job?.refDepartment}
              onChange={handleChangeRefDepartment}
              settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"', isClearable: true }}
              isDisabled={isDisabled}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      {(job?.creationSource || job?.modificationSource) && (
        <FormComponent.Text style={{ textAlign: 'right' }}>
          <span>
            <strong>Создано: </strong>
            {job?.creationSource ? `Зарплата ${job?.creationSource} (${job?.creationDate})` : 'нет данных'}
          </span>
          &nbsp;&nbsp;
          <span>
            <strong> Изменено: </strong>
            {job?.modificationSource ? `Зарплата ${job?.modificationSource} (${job?.modificationDate})` : 'нет данных'}
          </span>
        </FormComponent.Text>
      )}
    </FormComponent.Wrapper>
  );
}

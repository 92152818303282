import React, { useMemo } from 'react';

import { ButtonProps, Toolbar as SharedToolbar } from 'components';

import { User } from 'types/models/User';

type Props = {
  user: User | null;
  disabledEdit: boolean;
  onSave(needClose: boolean, onSuccessCb?: () => void): void;
  onSaveDraft(): void;
  onActivate(): void;
  onDeactivate(): void;
};

function Toolbar(props: Props) {
  const { user, disabledEdit, onSave, onSaveDraft, onActivate, onDeactivate } = props;

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'save' },
        title: 'Сохранить',
        onClick: () => onSave(true),
        isDisabled: disabledEdit || !user?.isActive,
      },
      {
        icon: { type: 'save', mode: 'add' },
        title: 'Сохранить и продолжить',
        onClick: onSaveDraft,
        isDisabled: disabledEdit || !user?.isActive,
      },
      {
        icon: { type: 'activate', mode: 'check' },
        title: 'Активировать',
        onClick: () => onActivate(),
        isHidden: user?.isActive,
      },
      {
        icon: { type: 'activate', mode: 'remove' },
        title: 'Деактивировать',
        onClick: () => onDeactivate(),
        isHidden: !user?.isActive,
      },
    ],
    [onSave, onSaveDraft, disabledEdit, onActivate, onDeactivate, user?.isActive],
  );

  return <SharedToolbar buttons={buttons} mode="form" />;
}

export { Toolbar };

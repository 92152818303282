import * as M from './model';
import { isValid as isValidDate } from 'date-fns';

import { ExtendedRow } from './ListEdit';
import { formatStringToDate } from 'utils/Helpers';

export function sortItems(rows: ExtendedRow<any>[], column: M.Column<any>, sortType: string) {
  return rows
    .map((filteredRow, index) => ({ filteredRow, index }))
    .sort((aRow, bRow) => {
      const isAsc = sortType === 'asc';
      const aValue = column.formatValue(aRow.filteredRow, aRow.index);
      const bValue = column.formatValue(bRow.filteredRow, bRow.index);
      if (aValue === bValue) return 0;
      if (aValue && !bValue) return isAsc ? 1 : -1;
      if (!aValue && bValue) return isAsc ? -1 : 1;

      switch (column.dataKind) {
        case M.DataKind.BOOLEAN:
          const isABoolean = ['true', 'false'].includes(String(aValue));
          const isBBoolean = ['true', 'false'].includes(String(bValue));
          const boolResult = (() => {
            if (!isABoolean || !isBBoolean) {
              if (!isABoolean && !isBBoolean) return 0;
              if (isABoolean) return 1;
              return -1;
            }
            if (aValue === bValue) return 0;
            if (aValue) return 1;
            return -1;
          })();
          return isAsc ? boolResult : -boolResult;

        case M.DataKind.INT:
          const aInt = parseInt(String(aValue));
          const bInt = parseInt(String(bValue));
          const isAInt = !isNaN(aInt);
          const isBInt = !isNaN(bInt);
          const intResult = (() => {
            if (!isAInt || !isBInt) {
              if (!isAInt && !isBInt) return 0;
              if (isAInt) return 1;
              return -1;
            }
            if (aInt === bInt) return 0;
            if (aInt > bInt) return 1;
            return -1;
          })();
          return isAsc ? intResult : -intResult;

        case M.DataKind.FLOAT:
          const aFloat = parseFloat(String(aValue));
          const bFloat = parseFloat(String(bValue));
          const isAFloat = !isNaN(aFloat);
          const isBFloat = !isNaN(bFloat);
          const floatResult = (() => {
            if (!isAFloat || !isBFloat) {
              if (!isAFloat && !isBFloat) return 0;
              if (isAFloat) return 1;
              return -1;
            }
            if (aFloat === bFloat) return 0;
            if (aFloat > bFloat) return 1;
            return -1;
          })();
          return isAsc ? floatResult : -floatResult;

        case M.DataKind.DATE:
          const aDate = formatStringToDate(String(aValue));
          const bDate = formatStringToDate(String(bValue));
          const isADate = isValidDate(aDate);
          const isBDate = isValidDate(bDate);
          const dateResult = (() => {
            if (!isADate || !isBDate) {
              if (!isADate && !isBDate) return 0;
              if (isADate) return 1;
              return -1;
            }
            if (aDate.getTime() === bDate.getTime()) return 0;
            if (aDate > bDate) return 1;
            return -1;
          })();
          return isAsc ? dateResult : -dateResult;

        case M.DataKind.RANGE:
          const rangeRegex = /^(\d{1,2}\.\d{1,2}\.\d{4})?\s?-\s?(\d{1,2}\.\d{1,2}\.\d{4})?$/;
          const isARange = new RegExp(rangeRegex).test(String(aValue));
          const isBRange = new RegExp(rangeRegex).test(String(bValue));
          const aRange = String(aValue)
            .split('-')
            .map(x => formatStringToDate(x.trim()));
          const bRange = String(bValue)
            .split('-')
            .map(x => formatStringToDate(x.trim()));
          const rangeResult = (() => {
            if (!isARange || !isBRange) {
              if (!isARange && !isBRange) return 0;
              if (isARange) return 1;
              return -1;
            }
            if (aRange[0].getTime() === bRange[0].getTime()) {
              if (aRange[1].getTime() === bRange[1].getTime()) return 0;
              if (aRange[1] > bRange[1]) return 1;
              return -1;
            }
            if (aRange[0] > bRange[0]) return 1;
            return -1;
          })();
          return isAsc ? rangeResult : -rangeResult;

        default:
          return isAsc ? String(aValue).localeCompare(String(bValue)) : String(bValue).localeCompare(String(aValue));
      }
    })
    .map(({ filteredRow }) => filteredRow);
}

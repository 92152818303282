type Answer = {
  get(): string;
  set(val: string): void;
  remove(): void;
};

/**
 * Using storage by key
 *
 * @param key - Storage key
 */
export function useStorage(key: 'token' | 'prevUrl' | 'tenderFavorites'): Answer {
  return {
    get: (): string => localStorage.getItem(key) || '',
    set: (val: string) => localStorage.setItem(key, val),
    remove: () => localStorage.removeItem(key),
  };
}

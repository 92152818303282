import React from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { TextArea, TextAreaMode } from 'components';

import { Program, Table } from 'types/models';
import { GetProgramList } from 'features/Table/specifications';

type Props = {
  program: Program.Program | null;
  onChange(program: Program.Program | null): void;
  isDisabled: boolean;
};

export function SelectProgram(props: Props) {
  const { program, onChange, isDisabled } = props;

  const { methods: getProgramAPI } = BackendAPI.useBackendAPI('GetProgram');

  return (
    <TextArea
      mode={TextAreaMode.TABLE}
      settings={{
        title: 'Мероприятия',
        table: {
          specification: GetProgramList({ withoutMenu: true }),
          onSelect: (row: Table.Entry | null) => {
            if (row) {
              getProgramAPI.callAPI({ id: row.id }, { onSuccessfullCall: ({ data }) => onChange(data) });
            } else {
              onChange(null);
            }
          },
        },
      }}
      value={program?.name}
      isDisabled={isDisabled}
    />
  );
}

import React from 'react';

import { Toolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const { buttons } = useController(tableState);

  return <Toolbar buttons={buttons} />;
}

export const Component = React.memo(LeftPanelForThirdLevel);

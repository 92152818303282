import React, { useRef } from 'react';
import { Chart as ChartJS, registerables } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { block } from 'bem-cn';

import { COLORS_BORDERS, COLORS } from '../сolors';
import { useController } from './controller';

import '../style.scss';

const b = block('charts');

ChartJS.register(...registerables);

export function PublicationFinancing() {
  const { financingByPeriod, yearslist } = useController();

  const Chart = () => {
    const chartRef = useRef();

    const renderTooltip = (context: any) => {
      const { raw: value } = context;
      return ` ${new Intl.NumberFormat().format(value)} тыс. руб.`;
    };

    const data = {
      labels: yearslist,
      datasets: financingByPeriod.map((x, index) => ({
        label: x.name,
        data: x.data,
        fill: false,
        backgroundColor: COLORS[index],
        borderColor: COLORS_BORDERS[index],
        borderWidth: 2,
        tension: 0.1,
      })),
    };

    return (
      <Line
        ref={chartRef}
        data={data}
        options={{
          responsive: true,
          maintainAspectRatio: false,
          scales: {
            x: {
              display: true,
              title: {
                display: true,
                text: 'Год',
              },
            },
          },
          plugins: {
            legend: {
              position: 'right',
            },
            tooltip: {
              callbacks: {
                label: renderTooltip,
              },
            },
          },
        }}
      />
    );
  };

  return (
    <>
      <div className={b()}>
        <h3>Финансирование НИОКТР</h3>
        <div className={b('chart')} style={{ height: '240px' }}>
          {Chart()}
        </div>
      </div>
    </>
  );
}

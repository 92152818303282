export class TypeEditionCode {
  static LOCAL: string = 'LOCAL';

  static FOREIGN: string = 'FOREIGN';

  static OTHER: string = 'OTHER';

  static OTHER_UNIVERSITY: string = 'OTHER_UNIVERSITY';

  static RSA: string = 'RSA';
}

import React, { useCallback } from 'react';

import { FormComponent, TextInput, TextInputMode, TextArea, TextAreaMode } from 'components';

import { isFloatValid } from 'utils/Validators';
import { formatNumber, getEnumItem } from 'utils/Helpers';
import { EmployeeNominee } from 'types/models/Payment';
import { Member, SelectPerson } from 'features/SelectPerson';
import { getMemberWithoutHistory } from 'features/SelectPerson/helpers';
import { GetScientistJobs } from 'features/Table/specifications';
import { calcShare } from 'features/Table/specifications/GetRequestFundList/helpers';
import { Person, Table } from 'types/models';
import { getMockJob } from 'features/Form/looks/person/helpers';
import { JobStatus } from 'utils/Enums';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  nominee: EmployeeNominee;
  setNominee(nominee: EmployeeNominee): void;
  commonAmount: number;
};

export function Fields(props: Props) {
  const { nominee, setNominee, commonAmount } = props;

  const { enumMap } = useAppDataContext();

  const setEmployeePerson = useCallback(
    (member: Member) => {
      setNominee({ ...nominee, employee: member.person, job: null });
    },
    [setNominee, nominee],
  );

  const setAmount = useCallback(
    (amount: string) => {
      if (isFloatValid(amount)) {
        setNominee({ ...nominee, amountMoney: amount });
      }
    },
    [nominee, setNominee],
  );

  const setJob = useCallback(
    (job: Person.ScientistJob | null) => {
      setNominee({ ...nominee, job });
    },
    [nominee, setNominee],
  );

  return (
    <FormComponent.Template>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО сотрудника" isRequired>
          <SelectPerson
            member={getMemberWithoutHistory(nominee.employee)}
            onUpdateMember={setEmployeePerson}
            hiddenButtons={['delete']}
            withHistory={false}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Должность" isRequired>
          <TextArea
            mode={TextAreaMode.TABLE}
            settings={{
              title: `Сведения о работе ${nominee.employee?.fullName || nominee.employee?.shortName || ''}`,
              isClearable: false,
              table: {
                specification: GetScientistJobs({ personId: nominee.employee?.id ?? '', hasSelectButton: true }),
                onSelect: (row: Table.Entry | null) =>
                  setJob(
                    row
                      ? {
                          ...getMockJob({ status: getEnumItem('JobStatus', JobStatus.OTHER, enumMap) }),
                          id: row.id,
                          refAppointment: { id: row.appointmentId, label: row.appointment },
                        }
                      : null,
                  ),
              },
            }}
            value={nominee.job?.refAppointment?.label}
            isDisabled={nominee.employee === null}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Сумма" isRequired>
          <TextInput
            mode={TextInputMode.NUMBER}
            value={String(nominee.amountMoney)}
            onChange={setAmount}
            settings={{ max: commonAmount }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="">
          <span>Доля {formatNumber(calcShare(Number(nominee.amountMoney ?? 0), commonAmount), 2)}</span>
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Template>
  );
}

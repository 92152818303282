import React, { useCallback, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Project } from 'types/models';

type Props = {
  department: Project.Department;
  setDepartment(department: Project.Department): void;
  codes: string;
};

export function Fields(props: Props) {
  const { department, setDepartment, codes } = props;

  const { methods: getReferenceElementAPI } = BackendAPI.useBackendAPI('GetReferenceElement');

  const [oldUnitLabel] = useState<string>(department.unit?.label || '');

  const getRefElement = useCallback(
    (id: string) =>
      new Promise<ReferenceItem | null>(resolve => {
        getReferenceElementAPI.callAPI(
          {
            refName: 'RefDepartment',
            elementSettings: { key: 'id', value: id },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getReferenceElementAPI],
  );
  const makeChangeHandler = (key: keyof Project.Department) => (value: ReferenceItem) => {
    if (value?.id) {
      getRefElement(value.id).then(item => {
        setDepartment({ ...department, [key]: item });
      });
    }
  };

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Факультет/Институт" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department?.governance}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('governance')(option as ReferenceItem)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department?.unit}
            onChange={(option: ReferenceItem | null) => makeChangeHandler('unit')(option as ReferenceItem)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      {!!oldUnitLabel && !!codes && (
        <FormComponent.Line>
          <>
            Для проекта созданы шифры, связанные с подразделением{' '}
            <strong>
              "{oldUnitLabel}": {codes}
            </strong>
            . О замене подразделения будет создана запись в Журнале сообщений
          </>
        </FormComponent.Line>
      )}
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { FormComponent, ListEdit, TextArea, TextAreaMode, TextInput, TextDateTime } from 'components';

import { useController } from './controller';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { CommissionMemberFields } from './CommissionMemberFields';
import { showErrors } from 'utils/Validators';
import { validateCommissionMember } from './validators';

type Props = {
  onClose: () => void;
};

function ProtocolCommissionForm({ onClose }: Props) {
  const { commission, handleFormSubmit, makeChangeHandler, viewMode } = useController({
    onClose,
  });

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={() => handleFormSubmit(true)}
        handleSaveAndContinue={() => handleFormSubmit(false)}
        viewMode={viewMode}
      />

      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Название" isRequired>
            <TextInput value={commission.name || ''} onChange={makeChangeHandler('name')} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Примечание">
            <TextArea
              mode={TextAreaMode.MODAL}
              settings={{ rows: 3 }}
              value={commission.note || ''}
              onChange={makeChangeHandler('note')}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Номер приказа" isRequired>
            <TextInput value={commission.orderNumber || ''} onChange={makeChangeHandler('orderNumber')} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field label="Дата приказа" isRequired>
            <TextDateTime value={commission.orderDate || null} onChange={makeChangeHandler('orderDate')} isDisabled={viewMode} />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <FormComponent.Field>
            <ListEdit
              header={{ title: 'Члены комиссии' }}
              rows={commission.members || []}
              onChange={makeChangeHandler('members')}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'ФИО', formatValue: row => row?.person?.fullName || '' },
                { label: 'Роль', formatValue: row => row.role?.label || '' },
                { label: 'Категория роли', formatValue: row => row.priority || '' },
              ]}
              defaultRowsCount={10}
              isDisabled={viewMode}
              specification={{
                mode: 'customComponent',
                validation: {
                  checkIsValid: department => validateCommissionMember(department).every(x => x.isValid),
                  onInvalidate: val => showErrors(validateCommissionMember, val),
                },
                renderComponent: (row, onChange) => <CommissionMemberFields entry={row} onFieldChange={onChange} />,
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      </FormComponent.Wrapper>
    </FormComponent.Template>
  );
}

export const Component = React.memo(ProtocolCommissionForm);

import React, { memo } from 'react';

import { DefaultCheckboxList } from '../DefaultCheckboxList';
import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

const AuthorRequestStatus = ({ parameters, setParameterValueByName, parameterName }: BuilderProps) => {
  const { checkboxList } = useController();
  return (
    <DefaultCheckboxList
      initialCheckboxList={checkboxList}
      parameterName={parameterName}
      parameters={parameters}
      setParameterValueByName={setParameterValueByName}
    />
  );
};

const Component = memo(AuthorRequestStatus);

export { Component as AuthorRequestStatus };

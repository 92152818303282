import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';

export const getParticipationTypeByEventType = (eventType: string) => {
  switch (eventType) {
    case EventTypes.EVENT_CONCERT.code:
      return ParticipationTypes.PARTICIPATION_CONCERT.code;
    case EventTypes.EVENT_CONFERENCE.code:
      return ParticipationTypes.PARTICIPATION_CONFERENCE.code;
    case EventTypes.EVENT_CONTEST.code:
      return ParticipationTypes.PARTICIPATION_CONTEST.code;
    case EventTypes.EVENT_EXPOSITION.code:
      return ParticipationTypes.PARTICIPATION_EXPOSITION.code;
    default:
      return '';
  }
};

import { ReferenceItem } from 'components';
import { makeStream, GetStateStreamData } from 'StreamRx';
import { SuchLikePublication, Form } from 'types/models';

import { SuchLikePublicationData } from './types';

export const streams = {
  getSuchLikePublications: makeStream<GetSuchLikePublications>(),
  openSuchLikePublication: makeStream<OpenSuchLikePublication>(),
  suchLikePublicationState: makeStream<SuchLikePublicationState>(),
  submitSuchLikePublications: makeStream<SubmitSuchLikePublications>(),
  attachSuchLikePublication: makeStream<AttachSuchLikePublication>(),
};

export type GetSuchLikePublications = SuchLikePublicationData<{
  category: SuchLikePublication.Category;
  componentId: string;
  isSearchable?: boolean;
  isSearching?: boolean;
  isDefaultSave?: boolean;
  currentId: string | null;
  searchValue: SuchLikePublication.State['searchValue'];
  publicationType?: Form.PublicationTypeCode;
  successfullCallback: ({
    publicationMessage,
    publicationMessageType,
  }: {
    publicationMessage: string;
    publicationMessageType: ReferenceItem;
  }) => void;
}>;

export type SubmitSuchLikePublications = SuchLikePublicationData<object>;

export type SuchLikePublicationState = SuchLikePublicationData<GetStateStreamData<SuchLikePublication.State>>;

export type AttachSuchLikePublication = SuchLikePublicationData<{ id: string; componentId: string }>;

export type OpenSuchLikePublication = SuchLikePublicationData<{ componentId: string }>;

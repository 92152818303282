import React from 'react';

import {
  FormComponent,
  ListEdit,
  ReferenceItem,
  Select,
  SelectMode,
  TextDateTime,
  TextArea,
  TextAreaMode,
  TextInput,
  TextInputMode,
  SectionTitle,
} from 'components';

import { SelectPerson, Member } from 'features/SelectPerson';
import { showNotification } from 'features/Notifications';
import { Form } from 'types/models';
import {
  Department,
  Finance,
  RequestPartner,
  Performer,
  ProgramRequest as ProgramRequestType,
} from 'types/models/ProgramRequest';
import { getPersonContacts } from 'utils/Helpers';

import { PartnerInputs } from './PartnerInputs';
import { useController } from './controller';
import { DepartmentInputs } from './DepartmentInputs';
import { FinanceInputs } from './FinanceInputs';

type Props = {
  disabled?: boolean;
  formFields: Form.Fields;
  departments: Department[];
  handleDepartmentsChange(value: Department[]): void;
  finances: Finance[];
  handleFinanceChange(value: Finance[]): void;
  partners: RequestPartner[];
  handlePartnersChange(value: RequestPartner[]): void;
  manager: Member | null;
  updateManager(newManager: Performer): void;
  programRequest: ProgramRequestType;
  setProgramRequest: React.Dispatch<React.SetStateAction<ProgramRequestType>>;
};

export function ProgramRequest(props: Props) {
  const {
    disabled,
    formFields,
    departments,
    handleDepartmentsChange,
    finances,
    handleFinanceChange,
    partners,
    handlePartnersChange,
    manager,
    updateManager,
    programRequest,
    setProgramRequest,
  } = props;

  const { getIsSomeFinanceHasEqualDate } = useController({ formFields });

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column style={{ width: '60%' }}>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.theme.title}
              isRequired={formFields.theme.required}
              tooltip="Название темы проекта"
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.theme.title }}
                value={formFields.theme.value}
                onChange={formFields.theme.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.keyWords.title} tooltip="Ключевые слова, перечисленные через запятую">
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.keyWords.title }}
                value={formFields.keyWords.value}
                onChange={formFields.keyWords.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.annotation.title}
              info={
                <>
                  В краткой свободной форме опишите суть проекта: основные ожидаемые характеристики и параметры объекта
                  исследования или разработки, планируемые результаты работы. Текст должен быть кратким и точным, не должен
                  содержать сложных формул, таблиц, рисунков и аббревиатур без их расшифровки (кроме общепринятых), а также
                  сокращённых слов (кроме общепринятых). Объем – не более 4 000 знаков с пробелами
                </>
              }
            >
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.annotation.title }}
                value={formFields.annotation.value}
                onChange={formFields.annotation.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.manager.title}
              isRequired={formFields.manager.required}
              info={
                <>
                  Выбор из справочника персон. Проверьте, чтобы контакты были актуальными. Для заявителя (руководителя проекта)
                  заявка отображается в любом статусе. Доступна для редактирования в статусах: «Черновик» или «На доработку». В
                  других статусах — заявка доступна для просмотра.
                </>
              }
            >
              <SelectPerson
                member={manager}
                onUpdateMember={updateManager}
                disabled={disabled}
                hiddenButtons={formFields.manager.required ? ['delete'] : undefined}
                memberLabel={[
                  manager?.person?.fullName,
                  manager?.degree?.refDegree?.label,
                  manager?.rank?.refRank?.label,
                  manager?.academicRank?.refAcademicRank?.label,
                  manager?.job?.appointment,
                ]
                  .filter(Boolean)
                  .join(', ')}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <span>{getPersonContacts(manager?.person?.contacts || [])}</span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label="Сроки выполнения работ"
              info={
                <>
                  По умолчанию проставляются сроки проекта, указанные в конкурсе. Вы можете сократить срок выполнения проекта
                  (работ), уменьшив дату окончания работ, если в конкурсной документации сроки выполнения являются критерием
                  отбора заявок по конкурсу.
                </>
              }
            >
              <span>
                <TextDateTime
                  value={formFields.startDate.value || ''}
                  onChange={(value: string) => formFields.startDate.onChange(value)}
                  maxValue={formFields.endDate.value || ''}
                  isDisabled={disabled}
                />
              </span>
              <span>&nbsp;-&nbsp;</span>
              <span>
                <TextDateTime
                  value={formFields.endDate.value || ''}
                  onChange={(value: string) => formFields.endDate.onChange(value)}
                  minValue={formFields.startDate.value || ''}
                  isDisabled={disabled}
                />
              </span>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="">
              <TextInput
                value={formFields.periodNote.value}
                onChange={formFields.periodNote.onChange}
                placeholder="Примечание о сроках выполнения работ"
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.performerCount.title}
              isRequired={formFields.performerCount.required}
              tooltip="Количество исполнителей, включая руководителя проекта"
            >
              <TextInput
                mode={TextInputMode.NUMBER}
                settings={{ max: 99999, decimal: false }}
                value={formFields.performerCount.value ?? ''}
                onChange={formFields.performerCount.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label={formFields.note.title} tooltip="Дополнительная информация по условиям выполнения проекта">
              <TextArea
                mode={TextAreaMode.MODAL}
                settings={{ rows: 3, title: formFields.note.title }}
                value={formFields.note.value}
                onChange={formFields.note.onChange}
                isDisabled={!!disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>

          <SectionTitle title="Для заполнения организаторами конкурса" />

          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.code.title}
              isRequired={formFields.code.required}
              tooltip="Шифр заявки в ИАС Организатора конкурса"
            >
              <TextInput value={formFields.code.value} onChange={formFields.code.onChange} isDisabled={disabled} />
            </FormComponent.Field>
            <FormComponent.Field
              label="Код"
              // eslint-disable-next-line max-len
              tooltip="Код для шифра - дополнение к шифру заявки - символическое обозначение группы заявок для управленческой отчётности в университете, например, для аналитики по институтам."
            >
              <Select
                mode={SelectMode.REFERENCE}
                value={programRequest.numberPrefix}
                onChange={(option: ReferenceItem | null) =>
                  setProgramRequest(prevState => ({ ...prevState, numberPrefix: option }))
                }
                settings={{
                  name: 'RefProjectRequestNumberPrefix',
                  title: 'Справочник "Префикс для шифра заявок и номера проектов"',
                  isClearable: true,
                }}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field
              label={formFields.requestDate.title}
              isRequired={formFields.requestDate.required}
              tooltip="Дата регистрации заявки в ИАС Организатора конкурса "
            >
              <TextDateTime
                value={formFields.requestDate.value}
                onChange={formFields.requestDate.onChange}
                isDisabled={disabled}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
      <FormComponent.Column style={{ width: '40%' }}>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <ListEdit
              header={{
                title: 'Календарный план',
                info: (
                  <>
                    По умолчанию календарный план заполняется данными из лота конкурса: предусмотренные конкурсной документацией
                    условия финансирования проекта. Содержит максимальную сумму финансирования в разбивке по годам. Уменьшить
                    сумму конкретного периода финансирования необходимо с помощью кнопки Редактировать.
                  </>
                ),
              }}
              rows={finances.sort((x, y) => Number(x.year) - Number(y.year))}
              onChange={handleFinanceChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'Год', formatValue: row => row.year || '', styles: { width: '20%' } },
                {
                  label: 'Финансирование, руб.',
                  formatValue: row => row?.fbAmount || 0,
                  styles: { width: '40%' },
                  dataKind: 'float',
                },
                {
                  label: 'Софинансирование, руб.',
                  formatValue: row => row?.sfAmount || 0,
                  styles: { width: '40%' },
                  dataKind: 'float',
                },
              ]}
              withMessages
              defaultRowsCount={3}
              maxHeight="400px"
              isDeleteConfirmEnabled
              isDisabled={disabled}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <FinanceInputs finance={row} onFinanceChange={onChange} />,
                validation: {
                  checkIsValid: (row, index, list) => getIsSomeFinanceHasEqualDate({ row, index, list }),
                  onInvalidate: (row, _, index, list) => {
                    const isSomeFinanceHasEqualDate = getIsSomeFinanceHasEqualDate({ row, index, list });
                    if (!isSomeFinanceHasEqualDate) {
                      showNotification({ message: 'Данная запись уже существует в списке', theme: 'danger' });
                    }
                  },
                },
              }}
              columnIndexesForSumTotal={[1, 3]}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              header={{
                title: 'Проект будет выполнен в подразделении',
                tooltip: 'Подразделение, факультет/институт выберите из справочника оргструктуры университета',
              }}
              rows={departments}
              onChange={handleDepartmentsChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                { label: 'Факультет/Институт', formatValue: row => row.governance.label },
                { label: 'Подразделение', formatValue: row => row.unit.label },
              ]}
              withMessages
              isDeleteConfirmEnabled
              isDisabled={disabled}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <DepartmentInputs department={row} onDepartmentChange={onChange as any} />,
                validation: {
                  checkIsValid: row => !!(row?.governance && row?.unit),
                  onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                },
              }}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <ListEdit
              header={{
                title: 'Организации-партнёры',
                tooltip:
                  // eslint-disable-next-line max-len
                  'Организации–партнёры выберите из списка организаций. Если в списке отсутствует организация, обратитесь к консультанту по заявке',
              }}
              rows={partners}
              onChange={handlePartnersChange}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                {
                  label: 'Название организации',
                  formatValue: row => {
                    const country = (row?.partner?.customFields?.country || null) as ReferenceItem | null;
                    if (country) {
                      return `${row?.partner?.label} (${country?.label})`;
                    }
                    return row?.partner?.label || '';
                  },
                },
                { label: 'Примечание', formatValue: row => row?.note || '' },
              ]}
              withMessages
              isDeleteConfirmEnabled
              isDisabled={disabled}
              specification={{
                mode: 'customComponent',
                renderComponent: (row, onChange) => <PartnerInputs partner={row} onPartnerChange={onChange as any} />,
                validation: {
                  checkIsValid: row => !!row?.partner,
                  onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
                },
              }}
            />
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { PurchaseRequest } from 'types/models';

type Agrs = {
  purchaseRequest: PurchaseRequest.PurchaseRequest | null;
  isFinanceResponsibleRequired: boolean;
  isLess100Services: boolean;
  isMore100: boolean;
};

export function validate({ purchaseRequest: pr, isFinanceResponsibleRequired, isLess100Services, isMore100 }: Agrs) {
  if (!pr) return ['Заполните необходимые поля'];

  return [
    !pr.project && 'проект',
    !pr.name && 'наименование закупки',
    !pr.deliveryDate && `срок ${isLess100Services ? 'оказания услуг' : isMore100 ? 'поставки/исполнения договора' : 'поставки'}`,
    !pr.applicant && 'руководителя проекта',
    !pr.contractKind && 'вид договора',
    !pr.requestResponsible && 'ответственное лицо по заявке',
    isFinanceResponsibleRequired && !pr.financeResponsible && 'материально-ответственное лицо',
    !pr.deliveryAddress && 'адрес поставки (оказания услуг)',
    ...(isLess100Services ? [!pr.limitPrice && 'предельная сумма закупки'] : []),
    ...(isMore100
      ? [
          !pr.paymentTerm?.value && 'условия оплаты',
          !pr.purchaseMethod?.value && 'предложение в отношении способа закупки',
          !pr.units[0]?.type?.value && 'тип закупки',
          !pr.units[0]?.name && 'наименование поставляемого товара, выполняемых работ, оказываемых услуг',
          !pr.units[0]?.perUnitPrice && 'начальная (максимальная) цена контракта',
        ]
      : []),
  ].filter(Boolean);
}

import React, { useEffect } from 'react';

import { Tabs, Tab, FormComponent } from 'components';

import { SuchLikePublication } from 'features/SuchLike/SuchLikePublication';
import { Form } from 'types/models';
import { ControlPanel } from 'features/Form/views/ControlPanel';
import { useController as useAboutMagazineController } from './Tabs/AboutMagazine/controller';
import { useAppDataContext } from 'features/AppData/context';
import { PublicationHeader } from 'features/Form/views/ModalHeader';
import { useFormContext } from 'features/Form/hooks';
import { ModePublicationHeader } from 'features/Form/views/ModalHeader/publication';
import * as TabsContent from './Tabs';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function MagazineForm({ viewMode, onClose }: Props) {
  const {
    isLoading,
    SUCH_LIKE_PUBLICATION_ID,
    handlePublicationSave,
    handlePublicationSaveAndContinue,
    publicationId,
    workMode,
    publicationInfo,
    addCitationSystem,
    editCitationSystem,
    removeCitationSystem,
    topMessage,
    setTopMessage,
    formFields,
    relatedTableState,
    setPublicationInfo,
    ...restState
  } = useAboutMagazineController({ onClose });

  const { settings } = useAppDataContext();
  const {
    look: { electronicType, setTitle },
  } = useFormContext<Form.CompilationFormLook>();

  useEffect(() => {
    setTitle?.(
      PublicationHeader({
        settings,
        mode: ModePublicationHeader.default,
        workMode,
        publicationId: publicationInfo?.status.id || '',
        publicationTypeCode: publicationInfo?.type || '',
        publicationElectronicTypeCode: publicationInfo?.fields.isElectronic === 'true' ? electronicType : '',
        departments: publicationInfo?.departments || [],
        status: publicationInfo?.status.text || '',
        publicationTypeEdition: {
          id: formFields.editionType?.value?.id || '',
          label: formFields.editionType?.value?.label || '',
        },
        isPreprint: !!publicationInfo?.isPreprint,
      }),
    );
  }, [
    electronicType,
    formFields.editionType?.value?.id,
    formFields.editionType?.value?.label,
    publicationInfo,
    publicationInfo?.departments,
    publicationInfo?.isPreprint,
    publicationInfo?.status.id,
    publicationInfo?.status.text,
    publicationInfo?.type,
    setTitle,
    settings,
    workMode,
  ]);

  return (
    <FormComponent.Template>
      <ControlPanel
        handleSave={handlePublicationSave}
        handleSaveAndContinue={handlePublicationSaveAndContinue}
        viewMode={viewMode}
        isDisabled={isLoading}
      />
      <SuchLikePublication mode="edit" handleCloseOuterForm={onClose} componentId={SUCH_LIKE_PUBLICATION_ID} />

      <FormComponent.Template>
        <Tabs>
          <Tab title="О журнале">
            <TabsContent.AboutMagazineComponent
              workMode={workMode}
              formFields={formFields}
              relatedTableState={relatedTableState}
              {...restState}
            />
          </Tab>
          <Tab title="Выпуски">
            <TabsContent.ReleasesComponent
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationId || '-1'}
            />
          </Tab>
          <Tab title="Индексация в базах">
            <TabsContent.DatabaseIndexingComponentLocal
              relatedTableState={relatedTableState}
              addCitationSystem={addCitationSystem}
              editCitationSystem={editCitationSystem}
              removeCitationSystem={removeCitationSystem}
              publicationType={publicationInfo?.type ?? ''}
              sourceId={publicationInfo?.magazine?.id}
              releaseId={publicationInfo?.magazineRelease?.id}
              topMessage={topMessage}
              workMode={workMode}
              setTopMessage={setTopMessage}
            />
          </Tab>
          <Tab title="Показатели">
            <TabsContent.Indicators
              citationSystems={publicationInfo?.citationSystems ?? []}
              relatedTableState={relatedTableState}
              publicationId={publicationId}
              publicationType={publicationInfo?.type ?? ''}
              workMode={workMode}
            />
          </Tab>
          <Tab title="Статьи">
            <TabsContent.ArticlesComponent
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationId || undefined}
            />
          </Tab>
          <Tab title="Редакция журнала">
            <TabsContent.EditorialMembersComponent publicationInfo={publicationInfo} setPublicationInfo={setPublicationInfo} />
          </Tab>
          <Tab title="Журнал сообщений">
            <TabsContent.Messages
              relatedTableState={relatedTableState}
              workMode={workMode}
              publicationId={publicationInfo?.status.id}
            />
          </Tab>
        </Tabs>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(MagazineForm);

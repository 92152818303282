import { useCallback, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ExtraToolbarButton } from 'components/ListEdit/model';

import { Form as F, Form, Rntd, RntdAuthor, Table as T } from 'types/models';
import { Original } from 'types/models/Form';
import { GetFullScienceProjectList } from 'features/Table/specifications';
import { ValueOf } from 'types/helpers';
import { Department } from 'types/models/Department';

type Props = {
  rntd?: Rntd | null;
  formFields: Form.Fields;
  makeChangeHandler(key: keyof Rntd): (value: ValueOf<Rntd>) => void;
};

export function useController(props: Props) {
  const { formFields, rntd, makeChangeHandler } = props;

  const projectOriginal = useMemo((): Original => {
    const project = formFields.project?.value || null;
    return {
      id: project?.id || '',
      name: project
        ? `${project.type?.label}, ${project.number}, ${project.startDate} - ${project.endDate}, ${project.name}`
        : '',
    };
  }, [formFields]);

  const projectRowConverter = useCallback<(row: T.Entry) => F.Translation>(row => {
    const dates = row.period.split(' - ');
    return {
      id: row.id,
      name: row.name,
      type: { label: row.projectType },
      number: row.projectNumber,
      startDate: dates[0],
      endDate: dates[1],
    };
  }, []);

  const projectSpecification = GetFullScienceProjectList({});

  const handleAuthorsChange = useCallback(
    (value: RntdAuthor[]) => {
      makeChangeHandler('authors')(value);
    },
    [makeChangeHandler],
  );

  const handleDepartmentsChange = useCallback(
    (value: Department[]) => {
      makeChangeHandler('departments')(value);
    },
    [makeChangeHandler],
  );

  const { methods: getProjectAPI } = BackendAPI.useBackendAPI('GetProject');

  const extraToolbarButtons = useMemo<ExtraToolbarButton<Department>[]>(
    () => [
      {
        icon: { type: 'document', mode: 'arrowRight' },
        title: 'Скопировть из проекта',
        onClick: () => {
          getProjectAPI.callAPI(
            { id: rntd?.project?.id || '' },
            {
              onSuccessfullCall: ({ data }) => {
                const projectDepartments: Department[] =
                  data?.departments
                    ?.map(d => {
                      if (d.id && d.governance && d.unit && !rntd?.departments.find(pd => pd?.unit?.id === d?.unit?.id)) {
                        return {
                          id: d.id ?? '',
                          governance: d.governance,
                          unit: d.unit,
                        };
                      }
                      return { id: '', governance: { id: '', label: '' }, unit: { id: '', label: '' } };
                    })
                    .filter(d => d.id !== '') || [];
                handleDepartmentsChange([...(rntd?.departments || []), ...(projectDepartments || [])]);
              },
            },
          );
        },
      },
    ],
    [rntd, handleDepartmentsChange, getProjectAPI],
  );

  return {
    projectOriginal,
    projectSpecification,
    extraToolbarButtons,
    projectRowConverter,
    handleAuthorsChange,
    handleDepartmentsChange,
  };
}

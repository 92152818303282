import { Table } from 'types/models';
import { Filters } from './filters';
import { Toolbar } from './Toolbar';

export function GetForeignProjectList(): Table.Specification<object, object> {
  return {
    apiID: 'GetForeignProjectList',
    header: {
      firstLevel: {
        title: 'Проекты сотрудников, выполненные в других организациях',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

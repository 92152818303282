import React, { useMemo } from 'react';
import { setup } from 'bem-cn';

import { FormComponent } from 'components';

import { Login, Register } from 'features/Auth/index';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('auth');
const f = block('auth__form');

function Auth() {
  const withRegister = true;
  enum TABS {
    register = 'register',
    signin = 'signin',
    success = 'success',
  }

  const tab = useMemo(() => {
    const hashRegExp = new RegExp('<|>|"|\'|/|%3C|%3E|%22|%27', 'g');
    return window.location.hash.slice(1).replace(hashRegExp, '');
  }, []);

  const goToForm = (tabType: TABS) => {
    window.location.hash = tabType;
  };

  const FormHeader = () => (
    <div className={f('header')}>
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field>
          <img className={f('header', 'logo')} src="./tsu-logo.svg" alt="TSU" />
        </FormComponent.Field>
        <FormComponent.Field>
          <div className={f('header', 'title')}>
            <strong>Управление научной и инновационной деятельностью</strong>
            <div>Томского государственного университета</div>
          </div>
        </FormComponent.Field>
      </FormComponent.Line>
    </div>
  );

  const FormTabs = () => (
    <div className={f('tabs')}>
      <div className={f('tabs', 'item', { active: tab === TABS.signin })} onClick={() => goToForm(TABS.signin)}>
        Вход в систему
      </div>
      {withRegister && (
        <div className={f('tabs', 'item', { active: tab === TABS.register })} onClick={() => goToForm(TABS.register)}>
          Регистрация
        </div>
      )}
    </div>
  );

  const Footer = () => (
    <div className={b('footer')}>
      <FormComponent.Line>
        <FormComponent.Field>
          <FormComponent.Line>
            <FormComponent.Link href="http://www.contek.ru/">
              <img alt="contek" src="./contek-logo.svg" />
            </FormComponent.Link>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Link href="http://www.contek.ru/" label="Разработано ООО &laquo;Контек-Софт&raquo;" />
          </FormComponent.Line>
        </FormComponent.Field>
        <FormComponent.Field align="right">
          <FormComponent.Link href="mailto:t_korneeva@contek.ru" label="Написать в техподдержку" classMixin="footer-right" />
        </FormComponent.Field>
      </FormComponent.Line>
    </div>
  );

  return (
    <div className={b()}>
      <div className={f()}>
        <FormHeader />
        {tab === TABS.success ? (
          <>
            <FormComponent.Line>
              <strong className={f('caption', 'thanks')}>Спасибо за регистрацию!</strong>
            </FormComponent.Line>
            <FormComponent.Line>
              <div className={f('caption', 'text')}>
                Ваша заявка принята. Мы вышлем потверждение о регистрации на указанную почту в ближайшее время.
              </div>
            </FormComponent.Line>
            <FormComponent.Line>
              <div className={f('caption', 'link')} onClick={() => goToForm(TABS.signin)}>
                Войти в систему
              </div>
            </FormComponent.Line>
          </>
        ) : (
          <>
            <FormTabs />
            {tab === TABS.signin && <Login />}
            {withRegister && tab === TABS.register && <Register />}
          </>
        )}
      </div>
      <Footer />
    </div>
  );
}

export const Component = React.memo(Auth);

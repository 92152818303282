import React from 'react';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export const Filters = React.memo(() => {
  return FiltersComponent([
    {
      type: FilterComponentType.checkbox,
      streamKey: 'isScientistMemberIntersection',
      label: 'Участники в составе авторов',
      checked: true,
    },
  ]);
});

import React, { useState, useCallback, useMemo } from 'react';
import { block } from 'bem-cn';
import * as BackendAPI from 'services/BackendAPI';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, ButtonProps, Modal, Toolbar } from 'components';

import { SuchLikeEvent } from 'features/SuchLike/SuchLikeEvent';
import { Table, Button } from 'types/models';
import { EventTypes } from 'utils/Enums/EventTypes';
import { useAppDataContext } from 'features/AppData/context';
import { ApproveEvent } from 'features/Form/looks/event/views/ApproveEvent';
import { EventTypeCode } from 'types/models/Event';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useController } from './controller';
import { State } from '../makeUseCustomController';
import { Permits } from 'utils/Permissions';
import { getEventPermitList, isEventPermitted } from 'utils/Helpers/event';
import { showNotification } from 'features/Notifications';

type WrapperProps = {
  buttonsToHide?: Button.Button[];
  eventType: EventTypeCode;
};

type Props = {
  tableState: Table.State;
  customState: State;
};

const b = block('toolbar');

export const LeftPanelForThirdLevelWrapper = ({ buttonsToHide, eventType }: WrapperProps) =>
  function LeftPanelForThirdLevel({ tableState, customState }: Props) {
    const { openDuplicatesForm } = useController();

    const { userPermission } = useAppDataContext();
    const tableStreams = useLocalTableStreams();

    const [selectedRow] = tableState.selectedRows;

    const [isAddFormOpen, setIsAddFormOpen] = useState(false);
    const [isEditFormOpen, setIsEditFormOpen] = useState(false);
    const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);
    const [nextPublicationName, setNextPublicationName] = useState('');
    const [isApprovePopupOpen, setIsApprovePopupOpen] = useState<boolean>(false);

    const { methods: deleteEventAPI } = BackendAPI.useBackendAPI('DeleteEvent', {
      onSuccessfullCall: () => {
        showNotification({ message: 'Запись удалена', theme: 'success' });
        handleCloseDeleteConfirmPopup();
        tableStreams.reloadTable.push({});
      },
    });

    const handleOpenApprovePopup = useCallback(() => {
      setIsApprovePopupOpen(true);
    }, []);

    const handleCloseApprovePopup = useCallback(() => {
      setIsApprovePopupOpen(false);
    }, []);

    const handleCloseDeleteConfirmPopup = useCallback(() => {
      setIsDeleteConfirmPopupOpen(false);
    }, []);

    const handleConfirmDeleteConfirmPopup = useCallback(() => {
      deleteEventAPI.callAPI({ eventId: tableState.selectedRows[0].id });
    }, [deleteEventAPI, tableState.selectedRows]);

    const handleDeleteButtonClick = useCallback(() => {
      setIsDeleteConfirmPopupOpen(true);
    }, []);

    const handleAddButtonClick = useCallback(() => {
      setNextPublicationName('');
      openDuplicatesForm();
    }, [openDuplicatesForm]);

    const handleTemplateCloseAddForm = useCallback(() => {
      setIsAddFormOpen(false);
    }, []);

    const handleEditButtonClick = useCallback(() => {
      setIsEditFormOpen(true);
    }, []);

    const handleTemplateCloseEditForm = useCallback(() => {
      setIsEditFormOpen(false);
    }, []);

    const handleContinueStep = useCallback((searchText: string) => {
      setNextPublicationName(searchText);
      setIsAddFormOpen(true);
    }, []);

    const buttons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'add' },
          title: 'Добавить',
          onClick: handleAddButtonClick,
          permission: { name: Permits[`${eventType}_ADD`] },
          isHidden: (buttonsToHide || []).includes('add'),
        },
        {
          icon: { type: 'edit' },
          title: 'Редактировать',
          onClick: handleEditButtonClick,
          permission: {
            name: getEventPermitList('CONTEST', 'EDIT'),
          },
          isHidden: (buttonsToHide || []).includes('edit'),
          isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'CONTEST', 'EDIT'),
        },
        {
          icon: { type: 'remove' },
          title: 'Удалить',
          onClick: handleDeleteButtonClick,
          permission: {
            name: getEventPermitList('CONTEST', 'DELETE'),
          },
          isHidden: (buttonsToHide || []).includes('delete'),
          isDisabled: !isEventPermitted(userPermission, tableState.selectedRows, 'CONTEST', 'DELETE'),
        },
        {
          icon: { type: 'like' },
          title: 'Утвердить',
          onClick: handleOpenApprovePopup,
          permission: { name: Permits[`${eventType}_APPROVE`] },
          isHidden: (buttonsToHide || []).includes('approve'),
          isDisabled: tableState.selectedRows.length !== 1,
        },
      ],
      [
        handleAddButtonClick,
        handleEditButtonClick,
        userPermission,
        tableState.selectedRows,
        handleDeleteButtonClick,
        handleOpenApprovePopup,
      ],
    );

    return (
      <>
        <Toolbar buttons={buttons} />

        {!(isAddFormOpen || isEditFormOpen) && (
          <div className={b('hidden')}>
            <SuchLikeEvent
              mode="add"
              isSearchable
              handleContinueStep={handleContinueStep}
              command="RefEventFeedlineMessage"
              eventTypeCode={eventType}
            />
          </div>
        )}

        {isEditFormOpen && (
          <modalTemplates.EventEditTemplate.Component
            isOpen={isEditFormOpen}
            onClose={handleTemplateCloseEditForm}
            id={tableState.selectedRows[0]?.id}
            relatedTableState={tableState}
            customState={customState}
            withHeaderId={false}
          />
        )}

        {isAddFormOpen && (
          <modalTemplates.EventAddTemplate.Component
            isOpen={isAddFormOpen}
            onClose={handleTemplateCloseAddForm}
            relatedTableState={tableState}
            name={nextPublicationName}
            customState={customState}
          />
        )}

        {selectedRow && isDeleteConfirmPopupOpen && (
          <Modal
            mode="warning"
            title="Предупреждение"
            isOpen={isDeleteConfirmPopupOpen}
            onClose={handleCloseDeleteConfirmPopup}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDeleteConfirmPopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: handleCloseDeleteConfirmPopup,
              },
            ]}
            size="small"
          >
            <>({selectedRow?.completeLabel}) Удалить мероприятие?</>
          </Modal>
        )}

        {isApprovePopupOpen && (
          <ApproveEvent
            typeCode={EventTypes.EVENT_CONFERENCE.code}
            id={tableState.selectedRows[0]?.id || ''}
            isOpen={isApprovePopupOpen}
            onClose={handleCloseApprovePopup}
          />
        )}
      </>
    );
  };

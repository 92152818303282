import {
  EstimateCreate,
  EstimatePositionForBalance,
  EstimatePositionInput,
  EstimatePositionItem,
  EstimateUpdate,
} from 'types/models/Estimate';
import { getMaybeField } from '../commonConverters';

export function convertEstimateToXML(es: EstimateUpdate) {
  return {
    _attr: es.id ? { id: es.id } : {},
    Positions: {
      Position: es.positions.map(pos => {
        return {
          _attr: pos.id ? { id: pos.id } : {},
          KindConsumption: {
            id: pos.kindConsumption?.id,
          },
          Amount: pos.amount,
          Note: pos.note,
          IsReduceOverhead: pos.isReduceOverhead,
          TransitoryBalance: pos.transitoryBalance,
          Items: pos.items,
        };
      }),
    },
  };
}

export function convertBalanceEstimatePositionToXML(list: EstimatePositionForBalance[]) {
  return {
    EstimatePosition: list.map(i => {
      return !!i.id
        ? {
            _attr: { id: i.id },
          }
        : {
            Estimate: {
              _attr: { id: i.estimateId },
            },
            KindConsumption: {
              _attr: { id: i.kindConsumptionId },
            },
          };
    }),
  };
}

export function convertEstimateInputToXML(es: EstimateCreate) {
  return {
    ProjectCode: {
      _attr: es.projectCodeId ? { id: es.projectCodeId } : {},
    },
    Year: es.year,
    CreatedDate: es.createdDate,
  };
}

export function convertEstimatePositionToXML(ep: EstimatePositionInput) {
  const getId = (id: string | undefined) => ({
    _attr: id ? { id } : {},
  });

  const getItems = (items: EstimatePositionItem[]) => {
    if (!(items || []).length) {
      return {};
    }
    return {
      Item: items.map((item: EstimatePositionItem) => {
        return {
          _attr: item.id ? { id: item.id } : {},
          ...getMaybeField('Date', item.date),
          ...getMaybeField('Amount', item.amount.toString()),
          ...getMaybeField('Note', item.note),
        };
      }),
    };
  };

  const result: any = {
    ...(ep.id ? { _attr: { id: ep.id } } : {}),
    Estimate: getId(ep.estimateId),
    KindConsumption: getId(ep.kindConsumptionId),
    Amount: ep.amount,
    ...getMaybeField('Note', ep.note),
    IsReduceOverhead: ep.isReduceOverhead,
    Items: getItems(ep.items),
  };

  if (ep.tax) {
    const { tax } = ep;
    result.Tax = {
      ...(tax.id ? { _attr: { id: tax.id } } : {}),
      Amount: tax.amount || 0,
      KindConsumption: getId(tax.kindConsumption?.id),
      TaxPercent: tax.taxPercent || 0,
      IsReduceOverhead: tax.isReduceOverhead,
      Items: getItems(tax.items),
    };
  }

  if (ep.fundCard) {
    result.FundCard = getId(ep.fundCard.id || '');
  }

  return result;
}

import React, { useCallback } from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { JointEvent } from 'types/models';
import { SelectPerson } from 'features/SelectPerson';

type Props = {
  committeMember: JointEvent.CommitteMember | null;
  onChange(e: JointEvent.CommitteMember): void;
};

export function CommitteMemberFields({ committeMember, onChange }: Props) {
  const handleCommitteMemberChange = useCallback(
    (e: JointEvent.CommitteMember) => {
      onChange(e);
    },
    [onChange],
  );
  const handleRoleChange = useCallback(
    (role: ReferenceItem) => {
      if (committeMember) onChange({ ...committeMember, role });
    },
    [committeMember, onChange],
  );
  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="ФИО" isRequired>
          <SelectPerson member={committeMember} onUpdateMember={handleCommitteMemberChange} hiddenButtons={['delete']} />
        </FormComponent.Field>
      </FormComponent.Line>
      {committeMember && (
        <FormComponent.Line>
          <FormComponent.Field label="Роль" isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={committeMember?.role}
              onChange={handleRoleChange}
              settings={{ name: 'RefOrgCommitte', title: 'Роли участников оргкомитета' }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </FormComponent.Wrapper>
  );
}

import React from 'react';

import { Modal } from 'components';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { CommunityType } from 'utils/Enums';

import { CustomProps } from '../..';

type Props = {
  isMembersTabHidden?: boolean;
};

const CommunityAddTemplate: React.FC<F.TemplateProps & CustomProps & Props> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  templateName,
  communityTypes,
  isMembersTabHidden,
}: F.TemplateProps & CustomProps & Props) => {
  const look: F.CommunityFormLook = {
    apiID: 'GetCommunity',
    template: templateName,
    relatedTableState,
    name,
    communityTypes,
    isMembersTabHidden,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={`Добавление "${
        communityTypes.includes(CommunityType.SCIENTIFIC_COUNCIL) ? 'Научное сообщество' : 'Диссертационный совет'
      }"`}
      size={['extralarge', 'large-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(CommunityAddTemplate);

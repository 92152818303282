import { useCallback, useEffect, useState } from 'react';

import { Suggestion } from 'types/models';

import * as BackendAPI from 'services/BackendAPI';

import { useDebounce } from 'shared/react/useDebounce';

import { Member } from './SelectPerson';
import { PersonHistoryMode } from './PersonHistoryModal/model';

type Arguments = {
  member: Member | null;
  onUpdateMember(author: Member | null): void;
};

export function useController({ member, onUpdateMember }: Arguments) {
  const [searchPersonValue, setSearchPersonValue] = useState<string>('');
  const [suggestions, setSuggestions] = useState<Suggestion[]>([]);

  const [isOpenHistoryModal, setIsOpenHistoryModal] = useState(false);
  const [isOpenPersonListModal, setIsOpenPersonListModal] = useState(false);
  const [isLoadPersonAfterHistoryOpen, setIsLoadPersonAfterHistoryOpen] = useState(false);
  const [historyMode, setHistoryMode] = useState<PersonHistoryMode>('add');

  const { methods: GetScientistData, state: scientistDataState } = BackendAPI.useBackendAPI('GetScientistData');
  const { methods: GetSuggestions, state } = BackendAPI.useBackendAPI('GetPersonSuggestions', {
    onSuccessfullCall: ({ data }) => {
      setSuggestions(data);
    },
  });

  const debouncedSearchPersonValue = useDebounce(searchPersonValue, 500);
  useEffect(() => {
    if (debouncedSearchPersonValue.length >= 4) {
      GetSuggestions.callAPI({ searchValue: debouncedSearchPersonValue });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSearchPersonValue]);

  const closeHistoryModal = useCallback(() => {
    setIsOpenHistoryModal(false);
  }, []);

  const openHistoryModal = useCallback((isLoadPersonAfterHistoryModalOpen: boolean) => {
    setIsLoadPersonAfterHistoryOpen(isLoadPersonAfterHistoryModalOpen);
    setTimeout(() => setIsOpenHistoryModal(true), 0);
  }, []);

  const closePersonListModal = useCallback(() => {
    setIsOpenPersonListModal(false);
  }, []);

  const openPersonListModal = useCallback(() => {
    setIsOpenPersonListModal(true);
  }, []);

  const resetPerson = useCallback(() => {
    onUpdateMember(null);
  }, [onUpdateMember]);

  const loadScientist = useCallback(
    (personId: string, isOpenHistoryModalAfterLoad: boolean = true) => {
      GetScientistData.callAPI(
        { personId },
        {
          onSuccessfullCall: ({ data }) => {
            onUpdateMember({
              person: data,
              degree: null,
              rank: null,
              academicRank: null,
              job: null,
              education: null,
              citizenship: null,
              historyLabel: '',
            });
            if (isOpenHistoryModalAfterLoad) {
              setHistoryMode('add');
              openHistoryModal(false);
            }
          },
        },
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [openHistoryModal, member, onUpdateMember],
  );

  return {
    suggestions,
    isLoadingSuggestions: state.kind === 'pending',
    isLoadingScientist: scientistDataState.kind === 'pending',
    isOpenHistoryModal,
    isOpenPersonListModal,
    isLoadPersonAfterHistoryOpen,
    historyMode,
    setHistoryMode,
    closePersonListModal,
    openPersonListModal,
    closeHistoryModal,
    setSearchPersonValue,
    setSuggestions,
    openHistoryModal,
    resetPerson,
    loadScientist,
  };
}

import { Report } from 'types/models';

export class Reports {
  static Personal: Report = {
    name: 'Personal',
    caption: 'Персональный отчёт по результатам научно-исследовательской деятельности',
  };

  static YearTotal: Report = {
    name: 'YearTotal',
    caption: 'Общие показатели университета по итогам года',
  };

  static DepartmentRecognition: Report = {
    name: 'DepartmentRecognition',
    caption: 'Признание результатов научной деятельности',
  };

  static YearMonograph: Report = {
    name: 'YearMonograph',
    caption: 'Список монографий по отраслям наук',
  };

  static MemorandumAccounting: Report = {
    name: 'MemorandumAccounting',
    caption: 'Служебная записка для бухгалтерии',
  };

  static RequestListIncentivePaymentsPeriod: Report = {
    name: 'RequestListIncentivePaymentsPeriod',
    caption: 'Список заявок на стимулирующие выплаты за период',
  };

  static IncentivePaymentTransferedPatents: Report = {
    name: 'IncentivePaymentTransferedPatents',
    caption: 'Реестр переданных объектов ИС в качестве вклада в уставной капитал МИП',
  };

  static IPIssuePatentRegister: Report = {
    name: 'IPIssuePatentRegister',
    caption: 'Реестр поданных заявок на патенты для стимулирующих выплат',
  };

  static IPPatentRegister: Report = {
    name: 'IPPatentRegister',
    caption: 'Реестр полученных патентов для стимулирующих выплат',
  };

  static DepartmentNTRExpositionsAwards: Report = {
    name: 'DepartmentNTRExpositionsAwards',
    caption: 'Научно-технические разработки, удостоенные медалей, дипломов и специальных наград\n',
  };

  static MemorandumSummary: Report = {
    name: 'MemorandumSummary',
    caption: 'Служебная записка (сводная)',
  };

  static AppToMemorandum: Report = {
    name: 'AppToMemorandum',
    caption: 'Приложение к служебной записке',
  };

  static DepartmentsNominations: Report = {
    name: 'DepartmentsNominations',
    caption: 'Количество номинаций по подразделениям',
  };

  static ConsolidatedStatement: Report = {
    name: 'ConsolidatedStatement',
    caption: 'Суммы по подразделениям',
  };

  static YearExpositionParticipation: Report = {
    name: 'YearExpositionParticipation',
    caption: 'Участие организации в выставках',
  };

  static PublicationList: Report = { name: 'PublicationList', caption: 'Публикации (список)' };

  static ['PublicationForm2.3']: Report = { name: 'PublicationForm2.3', caption: 'Публикации (форма 2.3)' };

  static PublicationExtendedList: Report = { name: 'PublicationExtendedList', caption: 'Список публикаций (расширенный)' };

  static PublicationsByCondition: Report = {
    name: 'PublicationsByCondition',
    caption: 'Публикации авторов по условию (возраст, статус, подразделение)',
  };

  static IncentivePaymentsScientist: Report = {
    name: 'IncentivePaymentsScientist',
    caption: 'Стимулирующие выплаты сотруднику по результатам научной деятельности за период',
  };

  static NirRequestProject: Report = { name: 'NirRequestProject', caption: 'Данные о проекте' };

  static NirRequestLeader: Report = { name: 'NirRequestLeader', caption: 'Данные о руководителе проекта' };

  static NirRequestLeadScientist: Report = { name: 'NirRequestLeadScientist', caption: 'О ведущем учёном' };

  static NirRequestPerformers: Report = { name: 'NirRequestPerformers', caption: 'Данные об основных исполнителях проекта' };

  static NirRequestCollective: Report = {
    name: 'NirRequestCollective',
    caption: 'Основные показатели результативности за 5 лет',
  };

  static NirRequestEstimate: Report = { name: 'NirRequestEstimate', caption: 'Смета заявки' };

  static NirRequestPerformersList: Report = { name: 'NirRequestPerformersList', caption: 'Список участников проекта' };

  static NirRequestMemorandum: Report = {
    name: 'NirRequestMemorandum',
    caption: 'Служебная записка о составе научного коллектива',
  };

  static NirRequestProjectTechnicalTask: Report = {
    name: 'NirRequestProjectTechnicalTask',
    caption: 'Техническое задание на проведение НИР',
  };

  static NirRequestProjectEstimate: Report = { name: 'NirRequestProjectEstimate', caption: 'Смета расходов' };

  static ContestRequest: Report = { name: 'ContestRequest', caption: 'Заявка на продление завершённого проекта' };

  static RequestTender: Report = { name: 'RequestTender', caption: 'Заявка на конкурс' };

  static EstimateRequest: Report = { name: 'EstimateRequest', caption: 'Смета заявки на конкурс' };

  static ProjectIndicators: Report = {
    name: 'ProjectIndicators',
    caption: 'Показатели и индикаторы результативности научного проекта (приложение №1 к заявке)',
  };

  static ContestRequestPerformerCompositionChangeMemo: Report = {
    name: 'ContestRequestPerformerCompositionChangeMemo',
    caption: 'Служебная записка об изменении состава научного коллектива',
  };

  static ContestRequestEstimate: Report = {
    name: 'ContestRequestEstimate',
    caption: 'Смета расходов',
  };

  static ContestRequestPerformerCompositionMemo: Report = {
    name: 'ContestRequestPerformerCompositionMemo',
    caption: 'Служебная записка о составе научного коллектива',
  };

  static ContestRequestTechnicalTask: Report = {
    name: 'ContestRequestTechnicalTask',
    caption: 'Техническое задание на проведение НИР',
  };

  static ContestRequestProjectIndicators: Report = {
    name: 'ProjectIndicators',
    caption: 'Показатели и индикаторы результативности науччного проекта (приложение №1 к заявке)',
  };

  static ContestRequestProjectResults: Report = {
    name: 'ProjectResults',
    caption: 'Результаты научного проекта (приложение №1 к заявке)',
  };

  static TotalNirRequestsIndicators: Report = {
    name: 'TotalNirRequestsIndicators',
    caption: 'Основные показатели и индикаторы по всем заявкам',
  };

  static PerformersProject: Report = {
    name: 'ProjectPerformersSimple',
    caption: 'Исполнители научного проекта',
  };

  static СompositionScientificProjectPeriod: Report = {
    name: 'СompositionScientificProjectPeriod',
    caption: 'Информация о коллективе для оформления отчёта по этапу проекта',
  };

  static ProjectStagePerformersSimple: Report = {
    name: 'ProjectStagePerformersSimple',
    caption: 'Исполнители этапа научного проекта',
  };

  static ProjectResults: Report = {
    name: 'ProjectResults',
    caption: 'Результаты научного проекта',
  };

  static ProjectAboutChangesOrder: Report = {
    name: 'ProjectAboutChangesOrder',
    caption: 'О внесении изменений в приказ',
  };

  static ProjectIkrbs: Report = {
    name: 'ProjectIkrbs',
    caption: 'Информация для ИКРБС',
  };

  static ProjectResultsDetail: Report = {
    name: 'ProjectResultsDetail',
    caption: 'Подробный отчёт по результатам научного проекта',
  };

  static ProjectPerformersForCitis: Report = {
    name: 'ProjectPerformersForCitis',
    caption: 'Список исполнителей проекта для ЦИТиС',
  };

  static ScienceProjectPerformerCompositionMemo: Report = {
    name: 'ScienceProjectPerformerCompositionMemo',
    caption: 'Служебная записка о состава коллектива (в проекте)',
  };

  static ScienceProjectPerformerCompositionMemoBySalary: Report = {
    name: 'ScienceProjectPerformerCompositionMemoBySalary',
    caption: 'Служебная записка о составе коллектива по данным об оплате',
  };

  static СompositionBySalary: Report = {
    name: 'СompositionBySalary',
    caption: 'Состав коллектива по данным о выплатах',
  };

  static ProjectTechnicalTask: Report = {
    name: 'ProjectTechnicalTask',
    caption: 'Техническое задание на проект',
  };

  static ProjectDocuments: Report = {
    name: 'ProjectDocuments',
    caption: 'Список документов проекта',
  };

  static PartnersContractsAndAgreements: Report = {
    name: 'PartnersContractsAndAgreements',
    caption: 'Реестр договоров и соглашений о сотрудничестве',
  };

  static SecurityDocumentAuthors: Report = { name: 'SecurityDocumentAuthors', caption: 'Авторский коллектив РИД' };

  static ServiceNotePurchase: Report = { name: 'ServiceNotePurchase', caption: 'Служебная записка на закупку' };

  static ServiceNotePurchaseUpSomeProducts: Report = {
    name: 'ServiceNotePurchaseUpTo100(products)',
    caption: 'Служебная записка на закупку до 100 (товары)',
  };

  static ServiceNotePurchaseUpSomeServices: Report = {
    name: 'ServiceNotePurchaseUpTo100(services)',
    caption: 'Служебная записка на закупку до 100 (услуги)',
  };

  static ServiceNotePurchaseUpSomeProductsServices: Report = {
    name: 'ServiceNotePurchaseUpTo100(services,product)',
    caption: 'Служебная записка на закупку свыше 100 (товары, услуги)',
  };

  static EventConsolidatedListRequest: Report = {
    name: 'EventConsolidatedListRequest',
    caption: 'Заявка в сводный план на проведение конференции',
  };

  static DepartmentEventConsolidatedListRequest: Report = {
    name: 'DepartmentEventConsolidatedListRequest',
    caption: 'Заявка подразделения в сводный план мероприятий университета',
  };

  static DepartmentConferenceConsolidatedList: Report = {
    name: 'DepartmentConferenceConsolidatedList',
    caption: 'Сводный перечень научных мероприятий',
  };

  static DepartmentConferenceReport: Report = {
    name: 'DepartmentConferenceReport',
    caption: 'Отчет по конференции',
  };

  static DepartmentConference: Report = {
    name: 'DepartmentConference',
    caption: 'Конференции, проведенные на базе организации',
  };

  static TenderReport: Report = {
    name: 'TenderReport',
    caption: 'Отчет по конкурсу (олимпиаде)',
  };

  static ExhibitionReport: Report = {
    name: 'ExhibitionReport',
    caption: 'Отчет по выставке',
  };

  static AvtodissDetail: Report = {
    name: 'AvtodissDetail',
    caption: 'Список авторефератов диссертаций подразделения за период',
  };

  static Dissertations: Report = {
    name: 'Dissertations',
    caption: 'Список диссертаций подразделения за период',
  };

  static Dissertation: Report = {
    name: 'Dissertation',
    caption: 'Справка о диссертациях',
  };

  static Monograph: Report = {
    name: 'Monograph',
    caption: 'Справка о научных монографиях',
  };

  static Publications: Report = {
    name: 'Publications',
    caption: 'Справка по статьям в журналах',
  };

  static PublicationsYoung: Report = {
    name: 'PublicationsYoung',
    caption: 'Публикации молодых ученых, студентов и аспирантов',
  };

  static PersonPubplicationMembers: Report = {
    name: 'PersonPubplicationMembers',
    caption: 'Отчёт для мониторинга публикационной активности',
  };

  static MonographDetail: Report = {
    name: 'MonographDetail',
    caption: 'Список монографий подразделения за период',
  };

  static DepartmentsPublications: Report = {
    name: 'DepartmentsPublications',
    caption: 'Список публикаций за год',
  };

  static WosScopusPublicationByDepartmentsMapping: Report = {
    name: 'WosScopusPublicationByDepartmentsMapping',
    caption: 'Публикации Scopus, WoS по факультетам/институтам',
  };

  static DepartmentContest: Report = {
    name: 'DepartmentContest',
    caption: 'Конкурсы и олимпиады, проведенные на базе организации',
  };

  static DepartmentContestConsolidatedList: Report = {
    name: 'DepartmentContestConsolidatedList',
    caption: 'Сводный перечень конкурсов и олимпиад',
  };

  static DepartmentContestParticipation: Report = {
    name: 'DepartmentContestParticipation',
    caption: 'Участие организации в конкурсах и олимпиадах',
  };

  static DepartmentExposition: Report = {
    name: 'DepartmentExposition',
    caption: 'Выставки, проведенные на базе организации',
  };

  static TotalDepartmentReportAll: Report = {
    name: 'TotalDepartmentReportAll',
    caption: 'Подробный отчет подразделения по итогам года',
  };

  static DepartmentExpositionParticipation: Report = {
    name: 'DepartmentExpositionParticipation',
    caption: 'Участие организации в выставках',
  };

  static DepartmentExpositionConsolidatedList: Report = {
    name: 'DepartmentExpositionConsolidatedList',
    caption: 'Сводный перечень выставок',
  };

  static AllExpositionsAwards: Report = {
    name: 'AllExpositionsAwards',
    caption: 'Художественные экспонаты и научно-технические разработки, удостоенные медалей, дипломов и специальных наград',
  };

  static DepartmentConcertParticipation: Report = {
    name: 'DepartmentConcertParticipation',
    caption: 'Участие организации в творческих мероприятиях',
  };

  static DepartmentConcertConsolidatedList: Report = {
    name: 'DepartmentConcertConsolidatedList',
    caption: 'Сводный перечень творческих мероприятий',
  };

  static DepartmentConferenceParticipation: Report = {
    name: 'DepartmentConferenceParticipation',
    caption: 'Участие организации в конференциях',
  };

  static ConferenceMemberCounts: Report = {
    name: 'ConferenceMemberCounts',
    caption: `Участники конференций сводного плана Университета за период`,
  };

  static NruP2Anketa: Report = {
    name: 'NruP2Anketa',
    caption: 'Анкета НРУ П2. Участие представителей Университета в международных и российских конференциях',
  };

  static EGISUParticipations: Report = {
    name: 'EGISUParticipations',
    caption: 'Список докладов по проекту',
  };

  static YearConference: Report = {
    name: 'YearConference',
    caption: 'Конференции, проведенные на базе организации',
  };

  static DepartmentContestAwards: Report = {
    name: 'DepartmentContestAwards',
    caption: 'Награды, полученные в конкурсных мероприятиях',
  };

  static DepartmentConcert: Report = {
    name: 'DepartmentConcert',
    caption: 'Творческие мероприятия, проведенные на базе организации',
  };

  static MobileRequest: Report = {
    name: 'MobileRequest',
    caption: 'Заявка по академической мобильности',
  };

  static MobileRequestAnalytic: Report = {
    name: 'MobileRequestAnalytic',
    caption: 'Информационно-аналитический отчёт по итогам командировки',
  };

  static MobileRequestMoneyOrder: Report = {
    name: 'MobileRequestMoneyOrder',
    caption: 'Приказ на командировку по данным заявки, допущенной на конкурс (заявление на деньги)',
  };

  static MobileRequestOrderOtherAppointment: Report = {
    name: 'MobileRequestOrderOtherAppointment',
    caption: 'Приказ на командировку по данным заявки, допущенной на конкурс (другая должность)',
  };

  static MobileRequestTask: Report = {
    name: 'MobileRequestTask',
    caption: 'Служебное задание по данным заявки, допущенной на конкурс (для сотрудников)',
  };

  static MobileRequestTaskOtherAppointment: Report = {
    name: 'MobileRequestTaskOtherAppointment',
    caption: 'Служебное задание на командировку',
  };

  static MobileRequestEstimate: Report = {
    name: 'MobileRequestEstimate',
    caption: 'Смета по данным заявки, допущенной на конкурс',
  };

  static MobileRequestOrderProject: Report = {
    name: 'MobileRequestOrderProject',
    caption: 'Проект приказа на стажировку/для участия в мероприятии по данным заявки, допущенной на конкурс',
  };

  static MobileRequestEducationOrderProject: Report = {
    name: 'MobileRequestEducationOrderProject',
    caption: 'Проект приказа по данным заявки, допущенной на конкурс (обучающиеся)',
  };

  static ProjectAct: Report = {
    name: 'ProjectAct',
    caption: 'АКТ приёмки НИР за период(этап)',
  };

  static TenderProtocol: Report = {
    name: 'TenderProtocol',
    caption: 'Протокол',
  };

  static NirRequestAll: Report = {
    name: 'NirRequestAll',
    caption: 'Заявка',
  };

  static NirRequestTotalEstimate: Report = {
    name: 'NirRequestTotalEstimate',
    caption: 'Консолидированная смета по заявкам на внутренний конкурс (Приоритет 2030)',
  };

  static IncomingFundsNiokr: Report = {
    name: 'IncomingFundsNiokr',
    caption: 'План-график поступления средств на НИР и ОКР на год (помесячный)',
  };

  static IncomingFundsTotalFact: Report = {
    name: 'IncomingFundsTotalFact',
    caption: 'Выполнение план-графика поступления средств на НИР и ОКР (суммарный факт за период)',
  };

  static ByDepartmentShort: Report = {
    name: 'ByDepartmentShort',
    caption: 'Отчет по подразделениям (доходы и расходы за год)',
  };

  static ByDepartmentFull: Report = {
    name: 'ByDepartmentFull',
    caption: 'Отчет по подразделениям (доходы и расходы в разрезе кодов КОСГУ за год)',
  };

  static ProjectEstimateByCodeForViu: Report = {
    name: 'ProjectEstimateByCodeForViu',
    caption: 'Смета расходов проекта',
  };

  static ProjectPerformers: Report = {
    name: 'ProjectPerformers',
    caption: 'Контроль списков исполнителей проектов',
  };

  static OrderAboutCreationResearchTeam: Report = {
    name: 'OrderAboutCreationResearchTeam',
    caption: 'Приказ о создании научного коллектива',
  };

  static ExtendedAnalytic: Report = {
    name: 'ExtendedAnalytic',
    caption: 'Выполнение НИОКР за год (аналитика по всем проектам)',
  };

  static CertificatParticipation: Report = {
    name: 'CertificatParticipation',
    caption: 'Справка об участии в НИР',
  };

  static SecurityDocumentsIndicators: Report = {
    name: '4-NT Section I. Use of the IPO, Section II. Financial indicators of the use of the IPO',
    caption: 'Раздел I. Использование ОИС, Раздел II. Финансовые показатели использования ОИС',
  };

  static MonitoringByEvents: Report = {
    name: '1-Monitoring-MD.Table 5.1.',
    caption:
      '1-Мониторинг-МД. Таблица 5.1. Информация об участии образовательной организации в мероприятиях международного уровня',
  };

  static UsingSecurityDocuments: Report = {
    name: 'Table326',
    caption: 'Таблица 3.2.6. Использование результатов интеллектуальной деятельности (коммерциализация технологий)',
  };

  static ResultsSTA: Report = {
    name: 'Table325',
    caption:
      // eslint-disable-next-line max-len
      'Таблица 3.2.5. Результаты научной, научно-технической деятельности (охраноспособные и/или ориентированные на промышленное использование)',
  };

  static InventoryScienceProtection: Report = {
    name: '2-science(inventory)Short Sector7.2 LegalProtectionUseIntellectualPropertyUnit',
    caption: '2-Наука инвентарная. Раздел 7.2. Правовая охрана и использование объектов интеллектуальной собственности',
  };

  static InventoryScienceCommercialization: Report = {
    name: '2-Inventory science. Section 7.3. TechnologyCommercialization',
    caption: '2-Наука инвентарная. Раздел 7.3. Коммерциализация технологий',
  };

  static AnnualScheduleOfVerificationCalibrationOfSICertificationIO: Report = {
    name: 'AnnualScheduleOfVerificationCalibrationOfSICertificationIO',
    caption: 'Ежегодный план-график поверки, калибровки СИ и аттестации ИО',
  };

  static WorkScheduleForVerificationCalibrationOfSIAndCertificationOfIO: Report = {
    name: 'WorkScheduleForVerificationCalibrationOfSIAndCertificationOfIO',
    caption: 'Рабочий план-график поверки, калибровки СИ и аттестации ИО',
  };

  static ApplicationСonclusionСontract: Report = {
    name: 'ApplicationСonclusionСontract',
    caption: 'Заявка на заключение договора',
  };

  static InformationOnScientificInnovativeDivisionsOrganization: Report = {
    name: 'Table3.1.InformationOnScientificInnovativeDivisionsOrganization',
    caption: 'Таблица 3.1. Сведения о научных и инновационных подразделениях в составе организации',
  };

  static MonitoringTable_3_2_1: Report = {
    name: 'MonitoringTable3.2.1',
    caption: '1-Мониторинг. Таблица 3.2.1. Выполненный объем работ (Суммы по календарному плану проекта)',
  };

  static MonitoringTable_3_2_2: Report = {
    name: 'MonitoringTable3.2.2',
    caption: '1-Мониторинг. Таблица 3.2.2. Исследования и разработки по ПНР',
  };

  static Table_3_2_3: Report = {
    name: 'Table 3.2.3',
    caption: '1-Мониторинг. Таблица 3.2.3. Источники финансирования затрат на научные исследования и разработки',
  };

  static Table_3_1_InformationProjectsScientificField: Report = {
    name: '1-Monitoring-MD.Table 3.1.InformationProjectsScientificField',
    caption: '1-Мониторинг-МД. Таблица 3.1. Информация о проектах в научной сфере',
  };

  static Table_5_1: Report = {
    name: '1-Monitoring-MD.Table 5.1.',
    caption:
      '1-Мониторинг-МД. Таблица 5.1. Информация об участии образовательной организации в мероприятиях международного уровня',
  };

  static ResearchDevelopmentCostsForm7: Report = {
    name: 'ResearchDevelopmentCostsForm7',
    caption: '2-Наука. Раздел 7. Затраты на научные исследования и разработки, тысяча рублей',
  };

  static Section8: Report = {
    name: 'Section8',
    caption: '2-Наука. Раздел 8. Выполненный объем отдельных видов работ и услуг',
  };

  static Section9: Report = {
    name: 'Section9',
    caption: '2-Наука. Раздел 9. Среднегодовая полная учетная стоимость основных фондов (средств) и отдельные виды затрат на НИР',
  };

  static Section10: Report = {
    name: 'Section10',
    caption: '2-Наука. Раздел 10. Источники финансирования внутренних затрат на научные исследования и разработки',
  };

  static Section11: Report = {
    name: 'Section11',
    caption: '2-Наука. Раздел 11. Гранты, субсидии, конкурсное финансирование исследований и разработок, тысяча рублей',
  };

  static Section12ScienceDevelopSocial_economic: Report = {
    name: 'Section12ScienceDevelopSocial-economic',
    caption: '2-Наука. Раздел 12. Внутренние затраты на научные исследования и разработки по социально-экономическим целям',
  };

  static Sector_4_ImplementationPrioritiesScientificTechnologicalDevelopmentRF: Report = {
    name: '2-science(inventory)Short Sector4. ImplementationPrioritiesScientificTechnologicalDevelopmentRF',
    caption: '2-Наука(ИНВ)краткая. Раздел 4. Реализация приоритетов научно-технологического развития РФ',
  };

  static Sector_6_ImplementationPrioritiesScientificTechnologicalDevelopmentRF: Report = {
    name: '2-science(inventory)Short Sector6. ImplementationPrioritiesScientificTechnologicalDevelopmentRF',
    caption: '2-Наука инвентарная. Раздел 6. Реализация приоритетов научно-технологического развития РФ',
  };

  static AnnualDissertationCouncils: Report = {
    name: 'AnnualDissertationCouncils',
    caption: 'Сведения о научных исследованиях для годового отчета по дисс. советам',
  };

  static ContractIntellectualProperty: Report = {
    name: 'ContractIntellectualProperty',
    caption: 'Договоры на использование ИС',
  };

  static RequestEvidence: Report = {
    name: 'RequestEvidence',
    caption: 'Заявки на ПЭВМ и БД (на свидетельства)',
  };

  static RequestPatent: Report = {
    name: 'RequestPatent',
    caption: 'Заявки на изобретения и полезные модели (на патенты)',
  };

  static KnowHowList: Report = {
    name: 'KnowHowList',
    caption: 'Ноу-хау, зарегистрированные на организацию',
  };

  static IntPropTgu: Report = {
    name: 'IntPropTgu',
    caption: 'Патентно-изобретательская деятельность (таблица)',
  };

  static IncomeCurrentAndFuturePeriodsAccordingProjectCodes: Report = {
    name: 'IncomeCurrentAndFuturePeriodsAccordingProjectCodes',
    caption: 'Доходы текущего и будущих периодов по шифрам проектов',
  };

  static NIRPlanByPeriod: Report = {
    name: 'NIRPlanByPeriod',
    caption: 'Отчёт ПЛАН НИР на период',
  };

  static NIOKRInvestmentSourceByYearAndDepartment: Report = {
    name: 'NIOKRInvestmentSourceByYearAndDepartment',
    caption: 'Отчёт Факультета по НИОКР за многолетний период',
  };

  static NIOKRInvestmentSourceByYear: Report = {
    name: 'NIOKRInvestmentSourceByYear',
    caption: 'Отчёт за период по НИОКР в разбивке источники финансирования и год',
  };

  static ProjectCoexecutors: Report = {
    name: 'ProjectCoexecutors',
    caption: 'Отчёт по организациям-партнёрам и соисполнителям научных проектов',
  };

  static ByDissertationCouncil: Report = {
    name: 'ByDissertationCouncil',
    caption: 'Отчёт по проектам за несколько лет с классификаторами',
  };

  static NiokrListM: Report = {
    name: 'NiokrListM',
    caption: 'Перечень НИОКР, млн. р.',
  };

  static NiokrList: Report = {
    name: 'NiokrList',
    caption: 'Перечень НИОКР, р.',
  };

  static InternationalProgramPlan: Report = {
    name: 'InternationalProgramPlan',
    caption: 'Перечень международных программ (план и факт)',
  };

  static InternationalProgramFact: Report = {
    name: 'InternationalProgramFact',
    caption: 'Перечень международных программ (факт)',
  };

  static ProgramIndicators: Report = {
    name: 'ProgramIndicators',
    caption: 'Показатели и индикаторы результативности по программе',
  };

  static Other2030Projects: Report = {
    name: 'Other2030Projects',
    caption: 'Приоритет 2030. Информация о реализации проектов в рамках реализации программы развития',
  };

  static BankWarranty: Report = {
    name: 'BankWarranty',
    caption: 'Прогноз финансирования на несколько лет вперёд (для банковской гарантии)',
  };

  static EGISUPublications: Report = {
    name: 'EGISUPublications',
    caption: 'Публикации по проекту с одним автором для ЕГИСУ',
  };

  static EGISUPublicationsByAllPersons: Report = {
    name: 'EGISUPublicationsByAllPersons',
    caption: 'Публикации по проекту (все авторы)',
  };

  static ResultScienceMonitoring: Report = {
    name: 'ResultScienceMonitoring',
    caption:
      // eslint-disable-next-line max-len
      'Таблица 3.2.4. Результаты научной, научно-технической и инновационной деятельности (публикационная, издательская активность)',
  };

  static Engineering_1_1_EngineeringServices: Report = {
    name: '1.1.EngineeringServices',
    caption: '1.1. Инжиниринговые услуги, оказанные собственными силами, по видам',
  };

  static Engineering_1_2_EngineeringServices: Report = {
    name: '1.2.EngineeringServices',
    caption: '1.2. Инжиниринговые услуги, оказанные собственными силами, по видам экономической деятельности Заказчиков',
  };

  static Engineering_1_3_EngineeringServicesProvided: Report = {
    name: '1.3.EngineeringServicesProvided',
    caption: '1.3. Инжиниринговые услуги, оказанные собственными силами, по тематическим направлениям',
  };

  static CostPayingOrganizationsInvolved: Report = {
    name: 'CostPayingOrganizationsInvolved',
    caption:
      // eslint-disable-next-line max-len
      '1.4. Затраты на оплату услуг привлеченных сторонних организаций при выполнении соглашений об оказании инжиниринговых услуг',
  };

  static AppliedScientificResearchCarried: Report = {
    name: 'AppliedScientificResearchCarried',
    caption: '4.1. Объем выполненных собственными силами прикладных научных исследований по тематическим направлениям',
  };

  static AgeStructureTechnicalLevelMachineryEquipment: Report = {
    name: '2-Inventory science.Section2.AgeStructureTechnicalLevelMachineryEquipment',
    caption: '2-Наука инвентарная. Раздел 2. Возрастная структура и технический уровень машин и оборудования',
  };

  static ProjectsHasNotProjectCode: Report = {
    name: 'ProjectsHasNotProjectCode',
    caption: 'Список проектов, имеющих пустой ФЛС спустя 14 дней с даты договора',
  };

  static ProjectsHasNotScientist: Report = {
    name: 'ProjectsHasNotScientist',
    caption: 'Список проектов, не имеющих коллектива спустя 14 дней с даты договора',
  };

  static ProjectsHasNotStateNumber: Report = {
    name: 'ProjectsHasNotStateNumber',
    caption: 'Список проектов, не имеющих регистрации ЕГИСУ в течение 30 дней с даты договора',
  };

  static ListMembersIfHasNotAppointed: Report = {
    name: 'ListMembersIfHasNotAppointed',
    caption: 'Список членов коллективов с пустой должностью и без договора ГПХ',
  };

  static ProjectsViolateTeamRequirements_3_Months: Report = {
    name: 'ProjectsViolateTeamRequirements_3_Months',
    caption: 'Проекты, в которых нарушаются требования по коллективу в течение 3-х месяцев',
  };

  static AmountFunds_G_26_Control: Report = {
    name: 'AmountFunds_G_26_Control',
    caption: 'Объём средств, законтрактованных на выбранный год',
  };

  static AmountFundsReceivedSelected_G_26_Control: Report = {
    name: 'AmountFundsReceivedSelected_G_26_Control',
    caption: 'Объём поступивших средств на выбранный год',
  };
}

import { Specification } from 'types/models/Table';
import { Component as Toolbar } from './Toolbar';

type Props = {
  estimateId: string;
  kindConsumptionId: string;
};

export function GetEstimatePositionExpenseDocumentList({ estimateId, kindConsumptionId }: Props): Specification {
  const requestData = { estimateId, kindConsumptionId };
  return {
    apiID: 'GetEstimatePositionExpenseDocumentList',
    requestData,
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: Toolbar,
      },
    },
  };
}

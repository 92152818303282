import React from 'react';
import { DataGrid } from 'features/Table';
import { GetDetailedScientistList } from 'features/Table/specifications';
import { MainScientistsContentContext, MainScientistsContentContextType } from './context';

const mainScientistsContentContext: MainScientistsContentContextType = { isMainScientistsContent: true };

export function makeScientistPage() {
  return () => (
    <MainScientistsContentContext.Provider value={mainScientistsContentContext}>
      <DataGrid specification={GetDetailedScientistList({})} />;
    </MainScientistsContentContext.Provider>
  );
}

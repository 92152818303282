import React, { useState } from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';
import { NirRequestFormLook } from 'types/models/Form';
import { Props } from '..';

const NirRequestAddTemplate: React.FC<F.TemplateProps & Props> = ({
  isOpen,
  onClose,
  relatedTableState,
  name,
  nirRequest,
  id,
}: F.TemplateProps & Props) => {
  const [title, setTitle] = useState<string | JSX.Element>('');

  const look: NirRequestFormLook = {
    apiID: 'GetNirRequest',
    template: 'NirRequestForm',
    relatedTableState,
    name,
    setTitle,
    nirRequest,
    id: id,
    viewMode: true,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} size="full" isViewMode>
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(NirRequestAddTemplate);

import React, { Dispatch, memo, SetStateAction } from 'react';
import { CommunityMember, Form } from 'types/models';

import { ListEdit, FormComponent } from 'components';

import { useController } from './controller';
import { getPersonContacts } from 'utils/Helpers';

type Props = {
  publicationInfo: Form.Publication | null;
  setPublicationInfo: Dispatch<SetStateAction<Form.Publication | null>> | null;
};

const EditorialMembers = ({ publicationInfo, setPublicationInfo }: Props) => {
  const { formatPersonWithHistory, handleMembersFieldChange } = useController({
    publicationInfo,
    setPublicationInfo,
  });
  return (
    <FormComponent.Wrapper>
      {!publicationInfo?.communityMembers?.length && (
        <FormComponent.Description mode="warning">
          Добавление сведений о редакции журнала и его членах - в пункте меню Признания -&gt; Научные сообщества и организации
        </FormComponent.Description>
      )}

      <ListEdit
        withMessages
        defaultRowsCount={9}
        maxHeight="400px"
        isDeleteConfirmEnabled
        rows={publicationInfo?.communityMembers || []}
        onChange={handleMembersFieldChange}
        columns={[
          { label: 'Персона', formatValue: (m: CommunityMember) => formatPersonWithHistory(m), styles: { width: '25%' } },
          { label: 'Роль', formatValue: (m: CommunityMember) => m.role?.label || '', styles: { width: '15%' } },
          { label: 'Дата ввода в состав', formatValue: (m: CommunityMember) => m.joinDate || '', styles: { width: '15%' } },
          { label: 'Дата вывода из состава', formatValue: (m: CommunityMember) => m.leaveDate || '', styles: { width: '15%' } },
          { label: 'Примечание', formatValue: (m: CommunityMember) => m.description, styles: { width: '15%' } },
          {
            label: 'Контакты',
            formatValue: (m: CommunityMember) => getPersonContacts(m?.person?.contacts, 'phones'),
            styles: { width: '15%' },
          },
        ]}
        specification={{
          mode: 'customComponent',
          renderComponent: () => <></>,
          validation: {
            checkIsValid: () => true,
          },
        }}
      />
    </FormComponent.Wrapper>
  );
};

export const EditorialMembersComponent = memo(EditorialMembers);

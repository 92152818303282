import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Select, { components, MenuPosition, MultiValue, SingleValue } from 'react-select';
import { setup } from 'bem-cn';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps, RelationTableModal, Toolbar } from 'components';

import { InputAttributes, Item } from 'types/models/common';
import { ReferenceFilter, ReferenceItem } from 'types/models/Reference';
import { SubmitTable } from 'features/Table/streams';
import { getHashObject } from 'utils/Helpers';
import { DefaultFieldValue } from 'features/Table/specifications/GetReferenceElementList/model';
import { FieldVisibleMode } from 'utils/Enums';

import { useControllerSuggestion } from './controllerSuggestion';
import { useControllerEnum } from './controllerEnum';
import { useControllerReference } from './controllerReference';

import './style.scss';

const block = setup({
  el: '__',
  mod: '--',
  modValue: '-',
});
const b = block('select');

const DEFAULT_VALUE = 'Выберите значение';
const DEFAULT_PLACEHOLDER = `${DEFAULT_VALUE} или начните вводить`;

export enum SelectMode {
  /** Default */
  DEFAULT = 'default',
  /** Any suggestion */
  SUGGESTION = 'suggestion',
  /** Any enum */
  ENUM = 'enum',
  /** Any reference */
  REFERENCE = 'reference',
}

type CommonProps = Omit<InputAttributes, 'onClick'>;

export type SettingsProps = {
  /** @property {boolean} isLoading - Is loading state */
  isLoading?: boolean;
  /** @property {boolean} isClearable - Is show clear button */
  isClearable?: boolean;
  /** @property {boolean} isSearchable - Is search (true by default) */
  isSearchable?: boolean;
  /** @property {number} maxMenuHeight - Maximal menu height */
  maxMenuHeight?: number;
  /** @property {string} noOptionsMessage - Default text on list is empty */
  noOptionsMessage?: string;
  /** @property {ButtonProps[]} externalButtons - Extra buttons */
  externalButtons?: ButtonProps[];
};

export type SettingsSuggestionProps = SettingsProps & {
  /** @property {string} name - Suggestion name */
  name: 'ref_addresses.district' | 'ref_addresses.city' | 'Publication.address';
  /** @property {number} limit - (optional) Selected rows limit (20 by default) */
  limit?: number;
};

export type SettingsEnumProps = SettingsProps & {
  /** @property {string} name - Enum name */
  name: string;
  /** @property {string[]} includes - (optional) Enum value for include to options (ignore excludes) */
  includes?: string[];
  /** @property {string[]} excludes - (optional) Enum value for exclude from options (if includes is empty or undefined) */
  excludes?: string[];
};

export type SettingsReferenceProps = SettingsProps & {
  /** @property {string} title - Modal name */
  title: string;
  /** @property {string} name - Reference table name */
  name: string;
  /** @property {ReferenceFilter[]} filters - Reference filters */
  filters?: ReferenceFilter[];
  /** @property {DefaultFieldValue} defaultValue - DefaultValue for GetReferenceElementList */
  defaultValue?: DefaultFieldValue;
  /** @property {boolean} isFacultyFilterChecked - DefaultValue for GetReferenceElementList RefDepartment table filter */
  isFacultyFilterChecked?: boolean;
};

/**
 * Settings for select component
 */
export type Props = CommonProps & {
  /** @property {SelectMode} mode - (optional) Type of select (SelectMode.DEFAULT by default) */
  mode?: SelectMode;
  // eslint-disable-next-line max-len
  /** @property {SettingsProps | SettingsSuggestionProps | SettingsEnumProps | SettingsReferenceProps} settings - (optional) Default type SettingsProps */
  settings?: SettingsProps | SettingsSuggestionProps | SettingsEnumProps | SettingsReferenceProps;
  /** @property {Item | Item[] | ReferenceItem | null} value - Value for select */
  value?: Item | Item[] | ReferenceItem | null;
  /** @property {Item[]} options - Options for select (as list only hardcode) */
  options?: Item[];
  /** @property {function} onChange - (optional) Callback on selected option */
  onChange(option: Item | Item[] | ReferenceItem | null): void;
  /** @property {function} onInput - (optional) Callback on text printed */
  onInput?(value: string): void;
  /** @property {boolean} isMulti - (optional) Multiple selection (false by default) */
  isMulti?: boolean;
  /** @property {boolean} isDisabled - (optional) Set is disabled select (false by default) */
  isDisabled?: boolean;
  /** @property {boolean} isError - (optional) Set is error select (false by default) */
  isError?: boolean;
  /** @property {string | string[]} classMixin - (optional) Mixin class(-es) for external customization */
  classMixin?: string | string[];
} & (
    | {
        mode?: SelectMode.DEFAULT;
        value?: Item | Item[] | null;
        onChange(option: Item | Item[] | null): void;
        settings?: SettingsProps;
      }
    | {
        mode: SelectMode.SUGGESTION;
        value?: Item | Item[] | null;
        onChange(option: Item | Item[] | null): void;
        settings: SettingsSuggestionProps;
      }
    | {
        mode: SelectMode.ENUM;
        value?: Item | Item[] | null;
        onChange(option: Item | Item[] | null): void;
        settings: SettingsEnumProps;
      }
    | {
        mode: SelectMode.REFERENCE;
        value?: ReferenceItem | null;
        onChange(option: ReferenceItem | null): void;
        settings: SettingsReferenceProps;
      }
  );

/**
 * Select component
 *
 * @param {SelectMode} mode - (optional) Type of select (SelectMode.DEFAULT by default)
 * @param {SettingsProps | SettingsSuggestionProps | SettingsEnumProps | SettingsReferenceProps} settings
 *  - (optional) Default type SettingsProps
 * @param {Item | Item[] | ReferenceItem | null} value - Value for select
 * @param {Item[]} options - Options for select (as list only hardcode)
 * @param {function} onChange - (optional) Callback on selected option
 * @param {function} onInput - (optional) Callback on text printed
 * @param {boolean} isMulti - (optional) Multiple selection (false by default)
 * @param {boolean} isDisabled - (optional) Set is disabled select (false by default)
 * @param {boolean} isError - (optional) Set is error select (false by default)
 * @param {string | string[]} classMixin - (optional) Mixin class(-es) for external customization
 * @returns {JSX} JSX
 */
export const SelectComponent = React.memo(
  ({
    mode = SelectMode.DEFAULT,
    settings = {} as SettingsProps,
    value,
    options: externalOptions = [] as Item[],
    onFocus,
    onChange,
    onInput,
    onBlur,
    isMulti = false,
    isDisabled = false,
    isError = false,
    classMixin,
    placeholder,
    ...restProps
  }: Props) => {
    const [valueState, setValueState] = useState<Item | Item[] | null>(null);
    const [options, setOptions] = useState<Item[]>(externalOptions);

    const { methods: getReferenceElements } = BackendAPI.useBackendAPI('GetReferenceElements');

    const className = useMemo(
      () =>
        b({
          'is-disabled': isDisabled,
          'is-error': isError,
        }).mix(classMixin),
      [classMixin, isDisabled, isError],
    );

    const [toolbarOffset, setToolbarOffset] = useState<string>('');
    const refToolbar = useRef<HTMLDivElement>();

    useEffect(() => {
      setToolbarOffset(refToolbar.current?.clientWidth ? `${refToolbar.current?.clientWidth + 6}px` : '0px');
    }, []);

    const fixedProps = {
      // menuIsOpen: true,
      controlShouldRenderValue: true,
      loadingMessage: () => 'Загрузка...',
      isClearable: false,
      menuPortalTarget: document.body,
      hideSelectedOptions: false,
      menuShouldScrollIntoView: false,
      menuShouldBlockScroll: true,
      menuPosition: 'fixed' as MenuPosition,
      blurInputOnSelect: true,
      onInputChange: onInput,
    };

    const calculatedProps = {
      className: b(),
      classNamePrefix: [(typeof classMixin === 'string' ? [classMixin] : classMixin || []).filter(Boolean).join(' '), b()]
        .filter(Boolean)
        .join(' '),
      readOnly: isDisabled,
      disabled: isDisabled,
      noOptionsMessage: () => settings.noOptionsMessage || 'Не найдено',
      placeholder: typeof placeholder === 'undefined' ? DEFAULT_PLACEHOLDER : placeholder,
      maxMenuHeight: settings.maxMenuHeight || 340,
      isMulti: !!isMulti,
      // isLoading: !!settings.isLoading || !!isLoading,
      // isClearable: !isDisabled && (!!settings.isClearable || !!isMulti),
      isSearchable: settings.isSearchable === undefined ? true : !!settings.isSearchable,
      closeMenuOnSelect: !isMulti,
      onBlur: onBlur,
    };

    const defaultButtons = useMemo<ButtonProps[]>(
      () => [
        {
          icon: { type: 'clear' },
          title: 'Добавить',
          onClick: () => {
            if (!!isMulti) {
              setValueState([]);
              onChange([]);
            } else {
              setValueState(null);
              onChange(null);
            }
          },
          isHidden: !settings.isClearable && !isMulti,
          isDisabled: isDisabled || (Array.isArray(valueState) ? !valueState.length : valueState === null),
        },
      ],
      [isDisabled, isMulti, onChange, settings.isClearable, valueState],
    );

    const onSelect = useCallback(
      // from node_modules\react-select\dist\declarations\src\Select.d.ts
      // (newValue: MultiValue<Option> | SingleValue<Option>, actionMeta: ActionMeta<Option>) => {}
      (option: MultiValue<Item> | SingleValue<Item>) => {
        if (isMulti) {
          const result = (option || []) as Item[];
          setValueState(result);
          onChange(result);
        } else {
          setValueState(option as Item);
          onChange(option as Item);
        }
      },
      [isMulti, onChange],
    );

    const title = useMemo(() => {
      if (!valueState) {
        return DEFAULT_VALUE;
      }
      return (
        (isMulti ? (valueState as Item[]).map(({ label }) => label).join(', ') : (valueState as Item).label) || DEFAULT_VALUE
      );
    }, [isMulti, valueState]);

    useEffect(() => {
      if (!value) {
        setValueState(null);
      }

      if (!!isMulti) {
        const result: Item[] = (() => {
          if (mode === SelectMode.REFERENCE) {
            return ((value as unknown) as ReferenceItem[]).map(i => ({ value: i.id, label: i.label } as Item));
          }
          return value as Item[];
        })();
        setValueState(result.filter((x: Item) => x.value));
      } else {
        const result: Item | null = (() => {
          if (mode === SelectMode.ENUM) {
            const finded = options.find(x => x.value === (value as Item)?.value);
            return !!finded && !!(finded as Item).value ? (finded as Item) : null;
          }
          if (mode === SelectMode.REFERENCE) {
            const converted: ReferenceItem | null = !!value && !!(value as ReferenceItem).id ? (value as ReferenceItem) : null;
            return converted ? ({ value: converted.id, label: converted.label } as Item) : null;
          }
          return !!value && !!(value as Item).value ? (value as Item) : null;
        })();
        setValueState(result);
      }
    }, [options, isMulti, value, mode]);

    // SelectMode.DEFAULT
    if (mode === SelectMode.DEFAULT) {
      useEffect(() => {
        if (getHashObject(options) !== getHashObject(externalOptions)) {
          setOptions(externalOptions);
        }
      }, [externalOptions, options]);
    }

    // SelectMode.ENUM
    if (mode === SelectMode.ENUM) {
      const { enumOptions } = useControllerEnum({ settings });

      useEffect(() => {
        setOptions(enumOptions);
      }, [enumOptions]);
    }

    // SelectMode.SUGGESTION
    if (mode === SelectMode.SUGGESTION) {
      const { setSearch, suggestionOptions } = useControllerSuggestion({
        settings,
        value: ((valueState || value) as Item)?.value || '',
      });

      useEffect(() => {
        setSearch((valueState as Item)?.value || '');
      }, [setSearch, valueState]);

      useEffect(() => {
        setOptions(suggestionOptions);
      }, [suggestionOptions]);

      fixedProps.onInputChange = useCallback(
        (val: string) => {
          onInput?.(val);
          setSearch(val || (valueState as Item)?.value || '');
          if (val) {
            onChange({ value: val, label: val } as Item);
          }
        },
        [onChange, onInput, setSearch, valueState],
      );

      calculatedProps.maxMenuHeight = 140;
    }

    // SelectMode.REFERENCE
    if (mode === SelectMode.REFERENCE) {
      const data = settings as SettingsReferenceProps;

      const { specificationReference, refreshOptions } = useControllerReference({
        settings,
        setReferences: (res: ReferenceItem[]) => {
          setPureReferences(res);
          setOptions(res.filter(({ label }) => label).map(i => ({ value: i.id, label: i.label })) as Item[]);
        },
      });

      const [isNeedUpdateOptions, setIsNeedUpdateOptions] = useState<boolean>(true);
      const [isTablePopupOpen, setIsTablePopupOpen] = useState<boolean>(false);
      const [pureReferences, setPureReferences] = useState<ReferenceItem[]>([]);

      const buttons = useMemo<ButtonProps[]>(
        () => [
          {
            icon: { type: 'table' },
            title: 'Выбрать',
            isDisabled: isDisabled,
            onClick: () => {
              setIsTablePopupOpen(true);
            },
          },
          ...(data.externalButtons || []),
          ...defaultButtons,
        ],
        [data.externalButtons, defaultButtons, isDisabled],
      );

      const onFocused = useCallback(() => {
        if (isNeedUpdateOptions || !options.length) {
          refreshOptions();
          setIsNeedUpdateOptions(false);
        }
      }, [isNeedUpdateOptions, options.length, refreshOptions]);

      useEffect(() => {
        setIsNeedUpdateOptions(true);
      }, [data]);

      return (
        <div className={className} title={title}>
          <Select
            components={{
              IndicatorsContainer: props => (
                <>
                  <div style={{ width: toolbarOffset }} />
                  <components.IndicatorsContainer {...props} />
                </>
              ),
            }}
            options={options}
            value={valueState}
            onChange={selected => {
              setValueState(selected as Item);
              onChange(pureReferences.find(i => i.id === (selected as Item)?.value) || null);
            }}
            onFocus={event => {
              onFocused();
              onFocus?.(event);
            }}
            {...fixedProps}
            {...calculatedProps}
            {...(restProps as CommonProps)}
          />
          <Toolbar refToolbar={refToolbar} buttons={buttons} />

          <RelationTableModal
            isOpen={isTablePopupOpen}
            onClose={() => setIsTablePopupOpen(false)}
            modalTitle={data.title || 'Список возможных вариантов'}
            specification={{
              ...specificationReference,
              onSubmitTable: ({ selectedRows: [row] }: SubmitTable) => {
                const item = { value: row.id, label: row.label || row.name } as Item;
                setValueState(item);

                getReferenceElements.callAPI(
                  {
                    referenceName: (settings as SettingsReferenceProps).name,
                    fieldVisibleMode: FieldVisibleMode.SYSTEM,
                    filters: [],
                    childIds: [row.id],
                  },
                  {
                    onSuccessfullCall: ({ data: refItem }) => onChange(refItem[0] || null),
                  },
                );

                setIsTablePopupOpen(false);
              },
            }}
          />
        </div>
      );
    }

    const buttons = useMemo<ButtonProps[]>(() => [...(settings.externalButtons || []), ...defaultButtons], [
      defaultButtons,
      settings.externalButtons,
    ]);

    return (
      <div className={className} title={title}>
        <Select
          components={{
            IndicatorsContainer: props => (
              <>
                <div style={{ width: toolbarOffset }} />
                <components.IndicatorsContainer {...props} />
              </>
            ),
          }}
          options={options}
          value={valueState}
          onChange={onSelect}
          onFocus={onFocus}
          {...fixedProps}
          {...calculatedProps}
          {...(restProps as CommonProps)}
        />
        <Toolbar refToolbar={refToolbar} buttons={buttons} />
      </div>
    );
  },
);

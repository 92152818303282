import { Icon } from 'components';

export function PersonalConcertParticipationGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Участие в творческих мероприятиях</strong> предназначен для добавления, редактирования и удаления участия в
        концертах, фестивалях, встречах и прочих творческих мероприятиях.
      </p>

      <h4>В разделе Участие в творческих мероприятиях вы можете:</h4>

      <ul>
        <li>
          Просмотреть запись об участии в творческих мероприятиях и информацию на всех вкладках с помощью кнопки{' '}
          <Icon type="view" />
        </li>
        <li>
          Добавить новое участие с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Добавить новую запись о творческом мероприятии с помощью кнопки <Icon type="add" />
          на вкладке «Описание» при создании участия.
        </li>
        <li>
          Редактировать запись об участии в творческом мероприятии с помощью кнопки <Icon type="edit" /> Кнопка доступна, если вы
          являетесь участником
        </li>
        <li>
          {/* eslint-disable-next-line max-len */}
          Удалить запись об участии в творческом мероприятии, которую вы создавали, если она в статусе Добавлена с помощью кнопки{' '}
          <Icon type="remove" /> Кнопка доступна, если вы являетесь участником
        </li>
        <li>
          Выгрузить список участий в творческих мероприятиях в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Добавить награды при редактировании записи об участии или посмотреть награды участников с помощью кнопки{' '}
          <Icon type="award" />
        </li>
        <li>
          Редактировать связь с подразделением с помощью кнопки <Icon type="connection" />
        </li>
      </ul>

      <div className="warning">
        <span>
          При сохранении записи об участии присваивается статус <strong>Добавлена</strong>.
        </span>
      </div>

      <p>
        На вкладке «Документы» обязательно прикрепите документ подтверждающий участие. Ответственное лицо проверит и утвердит
        участие.
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои участия»</strong> содержит список ваших участий. Если вы создавали запись об участии в творческом
          мероприятии, но не являетесь участником, запись будет отображаться в разделе «Все участия».
        </li>
        <li>
          <strong>Фильтр «Участия по подразделению»</strong> содержит список участий, связанных с вашим подразделением.
        </li>
        <li>
          <strong>Фильтр «Все участия»</strong> отображает список всех участий в творческих мероприятиях.
        </li>
        <li>
          <strong>Фильтр «Год»</strong> формирует список участий в творческих мероприятиях, сроки проведения которых содержат
          указанный год. По умолчанию выбран текущий год.
        </li>
      </ul>

      <div>
        Если у вас есть вопрос или вы нашли ошибку в Утвержденном участии - напишите Администратору <Icon type="question" />
      </div>
    </>
  );
}

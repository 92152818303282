import { Tab, Tabs } from 'components';

import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';

export function DashboardDetailMagazineGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Данные по журналам за 5 лет</h4>

        <p>
          Форма <strong>Данные по журналам за 5 лет</strong> содержит в табличном виде информацию о журналах: вид издания;
          количестве статей в журнале по годам; уровень цитируемости в базах.
        </p>
        <p>
          Для более удобной работы со списком, в столбцах <strong>Вид издания, Q(SJR), Q(IF WoS) и Q(SNIP)</strong> можно включить
          фильтр и посмотреть список всех журналов только определенного одного типа.
        </p>
        <p>Фильтр Вид издания:</p>

        <ul>
          <li>Зарубежные;</li>
          <li>Собственное издание;</li>
          <li>Издания других ВУЗов;</li>
          <li>Другие издания;</li>
          <li>Издания РАН.</li>
        </ul>

        <table>
          <thead>
            <tr>
              <th style={{ width: '25%' }}>Название столбца</th>
              <th>Описание источника данных в столбце</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Название журнала</td>
              <td>Основное название журнала (дополнительное)</td>
            </tr>
            <tr>
              <td>Вид издания</td>
              <td>Издание университета/Издание других ВУЗов/Другие издания/Издания РАН</td>
            </tr>
            <tr>
              <td>Оригинал/перевод</td>
              <td>Статус (оригинал/ перевод) связанного журнала: Название</td>
            </tr>
            <tr>
              <td>Страна журнала</td>
              <td>
                Страна, указанная на форме <strong>Издательства</strong> журнала
              </td>
            </tr>
            <tr>
              <td>Q(SJR)</td>
              <td>
                Максимальное значение квартиля SJR (Scopus) в год, указанный в фильтре{' '}
                <strong>Показывать результаты за год</strong>
              </td>
            </tr>
            <tr>
              <td>Q(IF WoS)</td>
              <td>
                Максимальное значение квартиля SJR (Scopus) в год, указанный в фильтре{' '}
                <strong>Показывать результаты за год</strong>
              </td>
            </tr>
            <tr>
              <td>Q(SNIP)</td>
              <td>
                Максимальное значение квартиля SJR (Scopus) в год, указанный в фильтре{' '}
                <strong>Показывать результаты за год</strong>
              </td>
            </tr>
            <tr>
              <td>Тематическая категория (Sсopus)</td>
              <td>
                Список тематических категорий Sсopus, указанных в разделе <strong>Индексация в базах</strong>
              </td>
            </tr>
            <tr>
              <td>Тематическая категория (WoS)</td>
              <td>
                Список тематических категорий WoS, указанных в разделе <strong>Индексация в базах</strong>
              </td>
            </tr>
            <tr>
              <td>Кол-во статей за (Текущий-5) год</td>
              <td>Список публикаций в журнале с годом издания за (Текущий-5)</td>
            </tr>
            <tr>
              <td>Кол-во статей за (Текущий-4) год</td>
              <td>Список публикаций в журнале с годом издания за (Текущий-4)</td>
            </tr>
            <tr>
              <td>Кол-во статей за (Текущий-3) год</td>
              <td>Список публикаций в журнале с годом издания за (Текущий-3)</td>
            </tr>
            <tr>
              <td>Кол-во статей за (Текущий-2) год</td>
              <td>Список публикаций в журнале с годом издания за (Текущий-2)</td>
            </tr>
            <tr>
              <td>Кол-во статей за (Текущий-1) год</td>
              <td>Список публикаций в журнале с годом издания за (Текущий-1)</td>
            </tr>
            <tr>
              <td>Кол-во статей за Текущий год</td>
              <td>Список публикаций в журнале с годом издания за Текущий</td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

const QnaFrequentEditTemplate: React.FC<F.TemplateProps> = (props: F.TemplateProps) => {
  const { isOpen, onClose, relatedTableState, id } = props;
  const look: F.Look = {
    id,
    apiID: 'GetQnaAnswer',
    template: 'QnaFrequentForm',
    relatedTableState,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Изменить часто задаваемый вопрос" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(QnaFrequentEditTemplate);

import { Icon, Tab, Tabs } from 'components';
import { PaymentRequestType } from 'utils/Enums';

export function DistributionMethodBetweenFundsHelp() {
  return (
    <>
      <p>
        <strong>Алгоритмы расчёта доли и суммы факультета:</strong>
      </p>
      <p>
        <strong>Сумма факультета за 1 номинанта (X) рассчитывается в зависимости от выбранного алгоритма по формуле:</strong>
      </p>
      <ul>
        <li>По аффилированным: Х = Фонд подразделений / Кол-во аффилированных авторов</li>
        <li>По назначенным: Х = Фонд подразделений / Кол-во номинантов с атрибутом «К оплате»</li>
        <li>По всем номинантам: Х = Фонд подразделений / Количество всего авторов (номинантов)</li>
      </ul>
      <p>
        <strong>Сумма факультета = X * Количество номинантов факультета с атрибутом "К оплате" (Номинанты к оплате)</strong>
      </p>
      <p>
        <strong>Доля факультета = Сумма факультета / Фонд подразделений</strong>
      </p>
      <p>
        <strong>Важно:</strong> если предлагаемые алгоритмы не применимы по требованиям Положения о стимулирующих выплатах, то
        выберите вариант "Редактировать сумму факультета вручную" для разблокирования функционала для редактирования суммы
        факультета в таблице "Распределение фонда подразделений (по алгоритму)"
      </p>
    </>
  );
}

export function IncentivePaymentsFormHelp(type: PaymentRequestType): { title: string; body: JSX.Element } {
  const documentsTab = <p>Содержит прикреплённые к заявке документы (например, служебная записка).</p>;

  const feedlineTab = (
    <>
      <p>
        Содержит список автоматически добавленных системой сообщений, таких как: редактирование заявки, изменение статуса, и
        сообщений, добавленных пользователями.
      </p>
      <div className="warning">пользователь имеет возможность отредактировать только то сообщение, которое добавил.</div>
    </>
  );

  const paymentTabStartBlock = (
    <>
      <p>Вкладка «Заявка на оплату» предназначена для оформления заявки.</p>
      <p>
        Раздел <em>Условия оплаты</em> заполняется в соответствии с правилами в Положении о стимулирующих выплатах:
      </p>
      <ul>
        <li>Год, квартал, месяц оплаты – эти атрибуты показывают, в какой период оформляется заявка.</li>
        <li>Фонд номинантам — максимальная сумма, выделенная на оплату авторам ОИС</li>
        <li>Фонд подразделения — максимальная сумма, выделенная в фонд подразделений авторов ОИС</li>
      </ul>
    </>
  );

  const paymentTabEndBlock = (
    <>
      <h4>Особенности вывода информации в столбцах таблицы Фонд факультета/института</h4>
      <p>
        Если по условию Положения о стимулирующих выплатах предусмотрен Фонд подразделений номинантов, то содержит список
        подразделений, которые получат средства из Заявки в фонд.
      </p>
      <div className="warning">
        <div>{DistributionMethodBetweenFundsHelp()}</div>
      </div>
      <table>
        <thead>
          <tr>
            <th>Название столбца</th>
            <th>Описание значения в столбце таблицы</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Фонд подразделения</td>
            <td>Название подразделения, к которому относится номинант для расчёта фонда подразделения.</td>
          </tr>
          <tr>
            <td>Кол-во авторов</td>
            <td>Количество авторов, которые относятся к подразделению</td>
          </tr>
          <tr>
            <td>Доля (номинация)</td>
            <td>Доля авторов подразделения в общем списке назначенных к оплате</td>
          </tr>
          <tr>
            <td>Сумма, руб.</td>
            <td>Вычисляется по формуле: Сумма Фонда пдразделений по условиям Положения об оплате* доля подразделения</td>
          </tr>
        </tbody>
      </table>
    </>
  );

  const titles: Record<PaymentRequestType, string> = {
    [PaymentRequestType.ARTICLE]: 'Заявка на стимулирующие выплаты за статью',
    [PaymentRequestType.CONFERENCE]: 'Заявка на стимулирующие выплаты за проведение конференции',
    [PaymentRequestType.EXHIBIT]: 'Не реализовано',
    [PaymentRequestType.MEDAL]: 'Заявка на стимулирующие выплаты за проведение конференции',
    [PaymentRequestType.MONOGRAPH]: 'Заявка на стимулирующие выплаты за монографию (главу монографии)',
    [PaymentRequestType.SECURITY_DOCUMENT]: 'Заявка на стимулирующие выплаты за объект интеллектуальной собственности',
    [PaymentRequestType.SECURITY_DOCUMENT_ISSUE]: 'Не реализовано',
    [PaymentRequestType.TEXTBOOK]: 'Заявка на стимулирующие выплаты за учебник (учебное пособие)',
  };

  const bodies: Record<PaymentRequestType, JSX.Element> = {
    [PaymentRequestType.ARTICLE]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными авторов из статьи в заявке.
          </p>
          <p>Добавлять и удалять авторов из таблицы пользователь не может.</p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" /> , как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество автора (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  Автоматически сумма План рассчитывается поровну. Пользователь имеет возможность отредактировать суммы пан и факт
                  вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>сведения о работе и учебе автора (должность, тип занятости, подразделение) на форме статьи.</td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Просмотр статьи">
          <p>
            На этой вкладке открывается форма просмотра всех данных статьи, в том числе, доступен архив прикреплённых файлов — для
            проверки актуальности сведений и соответствия условиям Положения о стимулирующих выплатах, принятия решения по заявке
            на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
    [PaymentRequestType.CONFERENCE]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными участников оргкомитета конференции в заявке.
          </p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" />, как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество номинанта (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  Автоматически сумма План рассчитывается поровну между назначенными к оплате. Пользователь имеет возможность
                  отредактировать суммы пан и факт вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>
                  сведения о работе и учебе номинанта (должность, тип занятости, подразделение) на форме конференции, вкладка
                  Организация мероприятия.
                </td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Просмотр конференции">
          <p>
            На этой вкладке открывается форма просмотра всех данных конференции, в том числе, доступен архив прикреплённых файлов
            — для проверки актуальности сведений и соответствия условиям Положения о стимулирующих выплатах, принятия решения по
            заявке на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
    [PaymentRequestType.EXHIBIT]: <em>Не реализовано</em>,
    [PaymentRequestType.MEDAL]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными участников выставки в заявке.
          </p>
          <p>Добавлять и удалять авторов из таблицы пользователь не может.</p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" /> , как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество номинанта (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  Автоматически сумма План рассчитывается поровну между назначенными к оплате. Пользователь имеет возможность
                  отредактировать суммы пан и факт вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>
                  сведения о работе и учебе номинанта (должность, тип занятости, подразделение) на форме участия в выставке.
                </td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Участие в выставке">
          <p>
            На этой вкладке открывается форма просмотра участия в выставке, в том числе, доступен архив прикреплённых файлов — для
            проверки актуальности сведений и соответствия условиям Положения о стимулирующих выплатах, принятия решения по заявке
            на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
    [PaymentRequestType.MONOGRAPH]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными авторов из публикации в заявке.
          </p>
          <p>Добавлять и удалять авторов из таблицы пользователь не может.</p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" /> , как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество автора публикации (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  Автоматически сумма План рассчитывается поровну. Пользователь имеет возможность отредактировать суммы пан и факт
                  вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>сведения о работе и учебе автора (должность, тип занятости, подразделение) на форме статьи.</td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Просмотр публикации">
          <p>
            На этой вкладке открывается форма просмотра всех данных публикации, в том числе, доступен архив прикреплённых файлов —
            для проверки актуальности сведений и соответствия условиям Положения о стимулирующих выплатах, принятия решения по
            заявке на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
    [PaymentRequestType.SECURITY_DOCUMENT]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными авторов из ОИС в заявке.
          </p>
          <p>Добавлять и удалять авторов из таблицы пользователь не может.</p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" /> , как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество автора (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  <p>Расчётная сумма, соответствующая доле творческого вклада автора.</p>
                  <div className="warning">
                    В случае, если творческий вклад авторов на форме Объекта интеллектуальной собственности не указан (равен «0
                    %»), тогда фонд номинантов между авторами делится поровну.
                  </div>
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>
                  сведения о работе и учебе автора (должность, тип занятости, подразделение) на форме объекта интеллектуальной
                  собственности.
                </td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Просмотр ОИС">
          <p>
            На этой вкладке открывается форма просмотра всех данных по объекту интеллектуальной собственности, в том числе,
            доступен архив прикреплённых файлов — для проверки актуальности сведений и соответствия условиям Положения о
            стимулирующих выплатах, принятия решения по заявке на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
    [PaymentRequestType.SECURITY_DOCUMENT_ISSUE]: <em>Не реализовано</em>,
    [PaymentRequestType.TEXTBOOK]: (
      <Tabs>
        <Tab title="Расчёт стимулирующих выплат">
          {paymentTabStartBlock}
          <p>
            Таблица <strong>«Номинанты на оплату стимулирующих выплат»</strong> содержит данные по номинантам, автоматически
            заполняется данными авторов из публикации в заявке.
          </p>
          <p>Добавлять и удалять авторов из таблицы пользователь не может.</p>
          <h4>Особенности вывода информации в столбцах таблицы «Номинанты на оплату стимулирующих выплат»</h4>
          <table>
            <thead>
              <tr>
                <th>Название столбца</th>
                <th>Описание значения в столбце таблицы</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Аффилирован</td>
                <td>
                  <p>
                    Факт аффилиации автора с Университетом – это предмет экспертизы заявки, так как аффилированные авторы являются
                    <em>Номинантами</em> на премиальные выплаты
                  </p>
                  <br />
                  <p>
                    По умолчанию для авторов, являющихся на момент создания <em>Заявки</em> сотрудником Университета,
                    автоматически проставляется флаг «Аффилирован».
                  </p>
                  <p>
                    Флаг можно снять по кнопке «Снять аффилиацию»
                    <Icon type="group" mode="cancel" /> и поставить по кнопке «Отметить аффилиацию»
                    <Icon type="group" mode="check" /> , как для одной, так и для нескольких записей.
                  </p>
                </td>
              </tr>
              <tr>
                <td>К оплате</td>
                <td>
                  <p>
                    Отметка факта назначения <em>Номинанта</em> к оплате: необходимо отметить, будет ли участник назначен к оплате
                    или нет, и указать, к какому подразделению он относится (факультету/ управлению/институту) для расчёта{' '}
                    <em>Фонда подразделения</em>.
                  </p>
                  <br />
                  <p>
                    Для отметки факта назначения <em>Номинанта</em> к оплате — использовать кнопки «Назначить к оплате»
                    <Icon type="currency" mode="check" /> и «Снять назначение к оплате»
                    <Icon type="currency" mode="cancel" /> . Когда пользователь назначает <em>Номинантов</em> к оплате, то
                    значение из столбца «План, руб» копируется в столбец «Факт, руб».
                  </p>
                </td>
              </tr>
              <tr>
                <td>ФИО номинанта</td>
                <td>Фамилия, имя и отчество автора публикации (текст из поля Примечание).</td>
              </tr>
              <tr>
                <td>План, руб.</td>
                <td>
                  Автоматически сумма План рассчитывается поровну. Пользователь имеет возможность отредактировать суммы пан и факт
                  вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Факт, руб.</td>
                <td>
                  Сумма, которую <em>Номинант</em> получит по факту, равна плану или 0, в зависимости от флага «К оплате».
                  Пользователь имеет возможность внести суммы вручную на форме редактирования номинанта.
                </td>
              </tr>
              <tr>
                <td>Сведения из источника</td>
                <td>сведения о работе и учебе автора (должность, тип занятости, подразделение) на форме статьи.</td>
              </tr>
              <tr>
                <td>Сведения о работе</td>
                <td>
                  отображается информация о работе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>.
                </td>
              </tr>
              <tr>
                <td>Сведения об учебе</td>
                <td>
                  отображается информация об учебе из справочника «Персоны» на момент создания Заявки по состоянию на{' '}
                  <strong>дату добавления заявки</strong>
                </td>
              </tr>
              <tr>
                <td>Относится к факультету</td>
                <td>
                  <p>
                    отображается подразделение верхнего уровня места работы или места учебы из столбцов «Сведения о работе на дату
                    заявки» и «Сведения об учебе на дату заявки».
                  </p>
                  <br />
                  <p>
                    Подразделения из данного столбца переносятся в таблицу «Фонд подразделения, у которых отмечен флаг «К оплате».
                    Пользователь имеет возможность изменить.
                  </p>
                </td>
              </tr>
            </tbody>
          </table>
          {paymentTabEndBlock}
        </Tab>
        <Tab title="Просмотр публикации">
          <p>
            На этой вкладке открывается форма просмотра всех данных публикации, в том числе, доступен архив прикреплённых файлов —
            для проверки актуальности сведений и соответствия условиям Положения о стимулирующих выплатах, принятия решения по
            заявке на оплату стимулирующих выплат.
          </p>
        </Tab>
        <Tab title="Документы">{documentsTab}</Tab>
        <Tab title="Журнал сообщений">{feedlineTab}</Tab>
      </Tabs>
    ),
  };

  return { title: titles[type], body: bodies[type] };
}

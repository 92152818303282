import { parse } from 'date-fns';

import { Settings } from 'types/models/common';
import { Project } from 'types/models';
import { isDateIntersect, getYear } from 'utils/Helpers';
import { ProjectFinancingType } from 'utils/Enums';
import { formatStr } from 'utils/Constants';

const financingsTypes = Object.values(ProjectFinancingType) as ProjectFinancingType[];

type Props = {
  stage: Project.Stage | null;
  list: Project.Stage[];
  index: number | null;
  financings: Project.Financing[];
  mode: 'view' | 'add' | 'edit' | null;
  settings: Settings | null;
  project: Project.Project;
};

export function validate({ stage, list, index, financings, mode, settings, project }: Props) {
  if (!stage) {
    return [{ isValid: false, invalidMessage: 'Заполните необходимые поля' }];
  }

  const stages = mode === 'edit' ? list.filter((_, stageIndex) => index !== stageIndex) : list;
  const existFinancingsTypes = financingsTypes.filter(x => financings.find(financing => financing.type?.value === x));

  const isValidNumber = Boolean(stage.number);
  const isValidStartDate = Boolean(stage.startDate);
  const isValidEndDate = Boolean(stage.endDate);
  const isValidNumberValue = !stage.number || !stages.find(x => x.number === stage.number);
  const isValidYearRange = !(isValidStartDate && isValidEndDate) || getYear(stage.startDate) === getYear(stage.endDate);
  const isValidAmountMain = !existFinancingsTypes.includes(ProjectFinancingType.MAIN) || Boolean(stage.amountMain);
  const isValidCF1 = !existFinancingsTypes.includes(ProjectFinancingType.COFINANCING_1) || Boolean(stage.amountCofinancing1);
  const isValidCF2 = !existFinancingsTypes.includes(ProjectFinancingType.COFINANCING_2) || Boolean(stage.amountCofinancing2);
  const isValidLocal = !existFinancingsTypes.includes(ProjectFinancingType.LOCAL) || Boolean(stage.amountLocal);
  const isValidAccomplice = !existFinancingsTypes.includes(ProjectFinancingType.ACCOMPLICE) || Boolean(stage.amountAccomplice);
  const isValidIntersect = !stages.find(x => isDateIntersect(stage.startDate, stage.endDate, x.startDate, x.endDate));
  const isValidDates =
    parse(stage.startDate, formatStr, new Date()) >= parse(project.startDate, formatStr, new Date()) &&
    parse(stage.endDate, formatStr, new Date()) <= parse(project.endDate, formatStr, new Date());

  return [
    { isValid: isValidNumberValue, invalidMessage: 'Этап с таким номером уже добавлен' },
    { isValid: isValidNumber, invalidMessage: 'Не заполнен номер этапа' },
    { isValid: isValidStartDate, invalidMessage: 'Выберите начало этапа' },
    { isValid: isValidEndDate, invalidMessage: 'Выберите окончание этапа' },
    { isValid: isValidAmountMain, invalidMessage: 'Не заполнена сумма ОИФ' },
    { isValid: isValidCF1, invalidMessage: 'Не заполнена сумма СФ1' },
    { isValid: isValidCF2, invalidMessage: 'Не заполнена сумма СФ2' },
    { isValid: isValidLocal, invalidMessage: `Не заполнена сумма ${settings?.organization?.shortName}` },
    { isValid: isValidAccomplice, invalidMessage: 'Не заполнена сумма Партнёр' },
    { isValid: isValidIntersect, invalidMessage: 'Периоды этапов не могут пересекаться' },
    { isValid: isValidYearRange, invalidMessage: 'Год начала этапа должен быть равен году его окончания' },
    { isValid: isValidDates, invalidMessage: 'Сроки этапа должны быть в рамках сроков проекта' },
  ];
}

import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Table } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { ParticipationTypeCode } from 'types/models/Participation';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData/helpers';

type Props = {
  type: ParticipationTypeCode;
  onAccept: () => void;
};

const useController = ({ type, onAccept }: Props) => {
  const { userPermission, userSystemDepartment } = useAppDataContext();

  const [selectedParticipation, setSelectedParticipation] = useState<Table.Entry | null>(null);
  const [isWarningPopupOpen, setIsWarningPopupOpen] = useState<boolean>(false);

  const { methods: BindParticipationToCurrentUserDepartment } = BackendAPI.useBackendAPI(
    'BindParticipationToCurrentUserDepartment',
  );

  const attachParticipation = useCallback(
    (cb: () => void) => {
      if (selectedParticipation) {
        BindParticipationToCurrentUserDepartment.callAPI(
          { participationId: selectedParticipation.id },
          {
            onSuccessfullCall: data => {
              if (data.data.success) {
                showNotification({ message: 'Участие успешно привязано', theme: 'success' });
                onAccept();
              }
            },
          },
        );
      } else {
        showNotification({ message: 'Не указано участие', theme: 'danger' });
      }

      setIsWarningPopupOpen(false);
      cb?.();
    },
    [BindParticipationToCurrentUserDepartment, onAccept, selectedParticipation],
  );

  const isHasParticipationPermission = useMemo<boolean>(
    () => isHasPermission(userPermission, Permits[`${type}_BIND_TO_DEPARTMENT`]),
    [type, userPermission],
  );

  return {
    isWarningPopupOpen,
    attachParticipation,
    userSystemDepartment,
    setSelectedParticipation,
    setIsWarningPopupOpen,
    isHasParticipationPermission,
  };
};

export default useController;

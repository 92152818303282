import { useCallback, useMemo, useState } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { ButtonProps } from 'components';

import { useLocalTableStreams } from 'features/Table/hooks';
import { Table } from 'types/models';
import { showNotification } from 'features/Notifications';

type Props = {
  tableState: Table.State;
};

export function useController(props: Props) {
  const { tableState } = props;

  const { methods: deleteEquipVerificationContact } = BackendAPI.useBackendAPI('DeleteEquipVerificationContact');

  const tableStreams = useLocalTableStreams();

  const selectedRows = tableState.selectedRows;
  const selectedRow: Table.Entry | null = selectedRows[0] || null;

  const [isViewFormOpen, setIsViewFormOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
  const [isEditFormOpen, setIsEditFormOpen] = useState(false);
  const [isDeleteConfirmPopupOpen, setIsDeleteConfirmPopupOpen] = useState(false);

  const handleConfirmDeleteConfirmPopup = useCallback(() => {
    deleteEquipVerificationContact.callAPI(
      { ids: tableState.selectedRows.map(row => row.id) },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Запись удалена', theme: 'success' });
          setIsDeleteConfirmPopupOpen(false);
          tableStreams.reloadTable.push({});
        },
      },
    );
  }, [deleteEquipVerificationContact, tableState.selectedRows, tableStreams.reloadTable]);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр',
        onClick: () => setIsViewFormOpen(true),
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'add' },
        title: 'Добавить',
        onClick: () => setIsAddFormOpen(true),
      },
      {
        icon: { type: 'edit' },
        title: 'Редактировать',
        onClick: () => setIsEditFormOpen(true),
        isDisabled: selectedRows.length !== 1,
      },
      {
        icon: { type: 'remove' },
        title: 'Удалить',
        onClick: () => setIsDeleteConfirmPopupOpen(true),
        isDisabled: selectedRows.length !== 1,
      },
    ],
    [selectedRows.length],
  );

  return {
    buttons,
    isViewFormOpen,
    setIsViewFormOpen,
    isAddFormOpen,
    setIsAddFormOpen,
    isEditFormOpen,
    setIsEditFormOpen,
    isDeleteConfirmPopupOpen,
    setIsDeleteConfirmPopupOpen,
    handleConfirmDeleteConfirmPopup,
    selectedRow,
  };
}

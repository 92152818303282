import React, { useCallback, useState } from 'react';
import { parse } from 'date-fns';

import { ListEdit, Modal } from 'components';

import { Project } from 'types/models';
import { useReportsHook, Reports, BuildReportPopup } from 'features/BuildReportPopup';
import { getFinancingText, getStageLabel, getStageTitle } from 'features/Form/looks/project/ProjectForm/helpers';
import { StageFields } from './Fields';
import { formatStr } from 'utils/Constants';
import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';

type Props = {
  project: Project.Project;
  setStages(stages: Project.Stage[]): void;
  withMessages?: boolean;
  disabled: boolean;
};

export function CalendarPlan(props: Props) {
  const { project, setStages, withMessages = true, disabled } = props;

  const [selectedStageIndex, setSelectedStageIndex] = useState<number | null>(null);
  const [isHelpModalOpen, setIsHelpModalOpen] = useState<boolean>(false);
  const [isFinancingInfoOpen, setIsFinancinfInfoOpen] = useState<boolean>(false);
  const [selectedStageForFinancingModal, setSelectedStageForFinancingModal] = useState<Project.Stage | null>(null);

  const { isReportOpen, onReportClose, handleSetCurrentReport, currentReport } = useReportsHook({ reports: [] });

  const onClickPrint = useCallback(
    (_: any, selectedRowIndex: number | null) => {
      setSelectedStageIndex(selectedRowIndex);
      handleSetCurrentReport({
        name: Reports.ProjectStagePerformersSimple.caption,
        value: Reports.ProjectStagePerformersSimple.name,
      });
    },
    [handleSetCurrentReport],
  );

  return (
    <>
      <ListEdit
        rows={project?.stages || []}
        onChange={setStages}
        toolbar={[
          {
            icon: { type: 'question' },
            title: 'Помощь',
            onClick: () => setIsHelpModalOpen(true),
          },
          'edit',
          {
            icon: { type: 'print' },
            title: 'Печать исполнителей проекта',
            onClick: onClickPrint,
            isDisabled: row => !row || disabled,
          },
        ]}
        columns={[
          { label: 'Этап', formatValue: x => getStageLabel(x) },
          { label: 'Название этапа', formatValue: x => x.name ?? '' },
          { label: 'Получены результаты этапа', formatValue: x => x.obtainedResults ?? '' },
          {
            label: 'Отчёт',
            formatValue: x =>
              (() => {
                const report = project?.reports.find(i => i.stage?.number === x.number) || null;
                return [
                  report?.name ? `${report.name || ''}:` : '',
                  report?.type?.label ? `отчёт о НИР (${report.type?.label || ''})` : '',
                  report?.number ? `- ГР ${report.number || ''}.` : '',
                  report?.archiveNumber ? `- Инв. ${report.archiveNumber || ''}.` : '',
                  report?.customerDate ? `(Отправлен заказчику: ${report.customerDate || '-'})` : '',
                ]
                  .filter(Boolean)
                  .join(' ');
              })(),
          },
          {
            label: 'Охрана РИД',
            formatValue: x =>
              !!x.isPatentResearchPlanned ||
              (() => {
                const matchedSecurityDocument = project?.securityDocuments.find(securityDocument =>
                  securityDocument.documentDate
                    ? parse(securityDocument.documentDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                      parse(securityDocument.documentDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date())
                    : securityDocument.requestDate
                    ? parse(securityDocument.documentDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                      parse(securityDocument.documentDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date())
                    : false,
                );
                return matchedSecurityDocument?.label || '';
              })(),
          },
          {
            label: 'Закупки',
            formatValue: x =>
              (() => {
                const matchedPurchaseRequest = (project?.purchaseRequests || []).find(
                  purchaseRequest =>
                    parse(purchaseRequest.sendDate, formatStr, new Date()) >= parse(x.startDate, formatStr, new Date()) &&
                    parse(purchaseRequest.sendDate, formatStr, new Date()) <= parse(x.endDate, formatStr, new Date()),
                );
                return matchedPurchaseRequest ? `${matchedPurchaseRequest.name} (${matchedPurchaseRequest.status?.label})` : '';
              })(),
          },
        ]}
        maxHeight="none"
        withMessages={withMessages}
        isDeleteConfirmEnabled
        isDisabled={disabled}
        defaultRowsCount={10}
        extraFieldsToolbarButtons={[
          {
            icon: { type: 'currency' },
            title: 'Финансирование',
            onClick: row => {
              setSelectedStageForFinancingModal(row);
              setIsFinancinfInfoOpen(true);
            },
          },
        ]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: row => getStageTitle(row),
          renderComponent: (stage, setStage) => <StageFields stage={stage} setStage={setStage} project={project} />,
        }}
      />

      <Modal
        mode="help"
        isOpen={isFinancingInfoOpen}
        onClose={() => {
          setIsFinancinfInfoOpen(false);
          setSelectedStageForFinancingModal(null);
        }}
        title="Финансирование"
        size="medium"
      >
        <>
          <span
            dangerouslySetInnerHTML={{
              __html: getFinancingText(selectedStageForFinancingModal, project) || '<em>Данные о финансировании не указаны</em>',
            }}
          />
        </>
      </Modal>

      <Modal
        mode="help"
        isOpen={isHelpModalOpen}
        onClose={() => setIsHelpModalOpen(false)}
        title="Особенности заполнения на вкладке: Этапы проекта"
        size="medium"
      >
        {ProjectHelp().planStages}
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          stageId: selectedStageIndex !== null ? (project?.stages || [])[selectedStageIndex]?.id ?? '' : undefined,
        }}
      />
    </>
  );
}

import { Department, ParticipationProject } from 'types/models/Participation';

import { CheckboxItem } from 'features/Form/looks/participation/ParticipationFormExposition/controller';
import { ParticipationInput, SaveParticipationRequest } from './types';

import { convertMobileRequestsToRequest, convertDocumentsToRequest } from '../converters';

import { convertAuthorsToRequest } from '../author/requestConverters';
import { convertAwardsToRequest, getMaybeField } from '../commonConverters';

export function convertDepartmentsToRequest(departments: Department[] | undefined) {
  if (!departments || !departments.length) {
    return { Departments: {} };
  }
  return {
    Departments: {
      Department: departments.map(department => ({
        _attr: department.id ? { id: department.id } : {},
      })),
    },
  };
}

function convertParticipationPresentationForm(participationPresentationForm: CheckboxItem[] | undefined) {
  if (!participationPresentationForm || !participationPresentationForm.length) {
    return { PresentationForms: {} };
  }
  return {
    PresentationForms: {
      PresentationForm: participationPresentationForm.map(item => item.value),
    },
  };
}

const convertProjectsToRequest = (participationProjects: ParticipationProject[]) => {
  if (!participationProjects.length) {
    return { Projects: {} };
  }

  return {
    Projects: {
      Project: participationProjects.map(participationProject => ({
        _attr: participationProject?.id ? { id: participationProject.id } : null,
        Project: {
          _attr: { id: participationProject?.project?.id },
        },
        IsAccepted: !!participationProject?.accepted,
        Type: participationProject.type?.value,
      })),
    },
  };
};

export function makeParticipationRequestXMLConverter(input: ParticipationInput) {
  const { simpleFields, attrIdFields, otherFields, isPublicationCreationSource } = input;
  const preparedParticipationInput: any = {};

  const simpleFieldKeys = Object.keys(simpleFields);

  const attrIdFieldKeys = Object.keys(attrIdFields);

  // fill simple fields
  simpleFieldKeys.forEach(key => {
    const simpleFieldKey = key as keyof SaveParticipationRequest['simpleFields'];

    const isKeyValueEmpty = simpleFields[simpleFieldKey] !== undefined && simpleFields[simpleFieldKey] !== '';

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedParticipationInput[preparedKey] = simpleFields[simpleFieldKey];
    }
  });

  // fill attributeid fields
  attrIdFieldKeys.forEach(key => {
    const attrIdFieldKey = key as keyof SaveParticipationRequest['attrIdFields'];

    const isKeyValueEmpty = !!attrIdFields[attrIdFieldKey];

    if (isKeyValueEmpty) {
      const preparedKey = key[0].toUpperCase() + key.substring(1);
      preparedParticipationInput[preparedKey] = { _attr: { id: attrIdFields[attrIdFieldKey] } };
    }
  });

  const { feedLine, participationPresentationForm } = otherFields;
  // START fill rest fields
  if (feedLine !== undefined) {
    preparedParticipationInput.ParticipationFeedline = {
      Message: { _attr: { id: feedLine.messageId } },
      Detail: feedLine.detail,
    };
  }
  // END fill rest fields
  const preparedDocuments = (otherFields.documents || []).filter(x => x.docType === 'local');
  const projects = [...otherFields.financingProjects];
  if (otherFields.presentationProject) {
    projects.push(otherFields.presentationProject);
  }
  // convertProjectToRequest
  return {
    ...preparedParticipationInput,
    ...convertAuthorsToRequest(otherFields.members || [], true),
    ...convertMobileRequestsToRequest(otherFields.mobileRequests),
    ...convertAwardsToRequest(otherFields.awards),
    ...convertProjectsToRequest(projects),
    ...convertDepartmentsToRequest(otherFields.departments),
    ...convertDocumentsToRequest(preparedDocuments),
    ...convertParticipationPresentationForm(participationPresentationForm),
    ...getMaybeField('IsPublicationCreationSource', isPublicationCreationSource),
  };
}

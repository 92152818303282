import { useAppDataContext } from 'features/AppData/context';
import { isHasPermission } from 'features/AppData';
import { ProjectType } from 'utils/Enums';
import { Permits } from 'utils/Permissions';
import { UserPermission } from 'types/models';

export function usePermissions(projectType: ProjectType, userPermissions?: UserPermission | null) {
  const userPermission = userPermissions || useAppDataContext().userPermission;

  const stateRegistrationView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_STATE_REGISTRATION_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_STATE_REGISTRATION_VIEW,
    GRANT: Permits.GRANT_PROJECT_STATE_REGISTRATION_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_STATE_REGISTRATION_VIEW,
  };

  const stateRegistrationEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_STATE_REGISTRATION_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_STATE_REGISTRATION_EDIT,
    GRANT: Permits.GRANT_PROJECT_STATE_REGISTRATION_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_STATE_REGISTRATION_EDIT,
  };

  const documentsView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_FILES_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_FILES_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_FILES_VIEW,
    GRANT: Permits.GRANT_PROJECT_FILES_VIEW,
  };

  const documentsEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_FILES_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_FILES_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_FILES_EDIT,
    GRANT: Permits.GRANT_PROJECT_FILES_EDIT,
  };

  const documentsAllEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_FILES_EDIT_ALL,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_FILES_EDIT_ALL,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_FILES_EDIT_ALL,
    GRANT: Permits.GRANT_PROJECT_FILES_EDIT_ALL,
  };

  const executorsView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_EXECUTORS_VIEW,
    GRANT: Permits.GRANT_EXECUTORS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_EXECUTORS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_EXECUTORS_VIEW,
  };

  const executorsEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_EXECUTORS_EDIT,
    GRANT: Permits.GRANT_EXECUTORS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_EXECUTORS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_EXECUTORS_EDIT,
  };

  const partnersAccomplicesView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PARTNERS_ACCOMPLICES_VIEW,
    GRANT: Permits.GRANT_PARTNERS_ACCOMPLICES_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PARTNERS_ACCOMPLICES_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PARTNERS_ACCOMPLICES_VIEW,
  };

  const partnersPartnersView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PARTNERS_PARTNERS_VIEW,
    GRANT: Permits.GRANT_PARTNERS_PARTNERS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PARTNERS_PARTNERS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PARTNERS_PARTNERS_VIEW,
  };

  const partnersPartnersEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PARTNERS_PARTNERS_EDIT,
    GRANT: Permits.GRANT_PARTNERS_PARTNERS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PARTNERS_PARTNERS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PARTNERS_PARTNERS_EDIT,
  };

  const partnersIntCoopView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PARTNERS_INT_COOP_VIEW,
    GRANT: Permits.GRANT_PARTNERS_INT_COOP_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PARTNERS_INT_COOP_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PARTNERS_INT_COOP_VIEW,
  };

  const partnersIntCoopEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PARTNERS_INT_COOP_EDIT,
    GRANT: Permits.GRANT_PARTNERS_INT_COOP_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PARTNERS_INT_COOP_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PARTNERS_INT_COOP_EDIT,
  };

  const calendarPlanView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_CALENDAR_PLAN_VIEW,
    GRANT: Permits.GRANT_CALENDAR_PLAN_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_CALENDAR_PLAN_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_CALENDAR_PLAN_VIEW,
  };

  const calendarPlanEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_CALENDAR_PLAN_EDIT,
    GRANT: Permits.GRANT_CALENDAR_PLAN_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_CALENDAR_PLAN_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_CALENDAR_PLAN_EDIT,
  };

  const classifiersView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_CLASSIFICATORS_VIEW,
    GRANT: Permits.GRANT_PROJECT_CLASSIFICATORS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_CLASSIFICATORS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_CLASSIFICATORS_VIEW,
  };

  const classifiersEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_CLASSIFICATORS_EDIT,
    GRANT: Permits.GRANT_PROJECT_CLASSIFICATORS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_CLASSIFICATORS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_CLASSIFICATORS_EDIT,
  };

  const aboutProjectView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_ABOUT_PROJECT_VIEW,
    GRANT: Permits.GRANT_ABOUT_PROJECT_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_ABOUT_PROJECT_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_ABOUT_PROJECT_VIEW,
  };

  const aboutProjectEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_ABOUT_PROJECT_EDIT,
    GRANT: Permits.GRANT_ABOUT_PROJECT_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_ABOUT_PROJECT_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_ABOUT_PROJECT_EDIT,
  };

  const projectDescriptionView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_DESCRIPTION_VIEW,
    GRANT: Permits.GRANT_PROJECT_DESCRIPTION_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_DESCRIPTION_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_DESCRIPTION_VIEW,
  };

  const projectDescriptionEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_DESCRIPTION_EDIT,
    GRANT: Permits.GRANT_PROJECT_DESCRIPTION_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_DESCRIPTION_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_DESCRIPTION_EDIT,
  };

  const projectFeaturesView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_FEATURES_VIEW,
    GRANT: Permits.GRANT_PROJECT_FEATURES_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_FEATURES_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_FEATURES_VIEW,
  };

  const projectFeaturesEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_FEATURES_EDIT,
    GRANT: Permits.GRANT_PROJECT_FEATURES_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_FEATURES_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_FEATURES_EDIT,
  };

  const resultsView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_RESULTS_VIEW,
    GRANT: Permits.GRANT_PROJECT_RESULTS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_RESULTS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_RESULTS_VIEW,
  };

  const resultsEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_RESULTS_EDIT,
    GRANT: Permits.GRANT_PROJECT_RESULTS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_RESULTS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_RESULTS_EDIT,
  };

  const purchaseRequestView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_PROJECT_PURCHASE_REQUEST_VIEW,
    GRANT: Permits.GRANT_PROJECT_PURCHASE_REQUEST_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_PROJECT_PURCHASE_REQUEST_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_PROJECT_PURCHASE_REQUEST_VIEW,
  };

  const restrictionsView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_RESTRICTIONS_VIEW,
    GRANT: Permits.GRANT_RESTRICTIONS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_RESTRICTIONS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_RESTRICTIONS_VIEW,
  };

  const restrictionsEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_RESTRICTIONS_EDIT,
    GRANT: Permits.GRANT_RESTRICTIONS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_RESTRICTIONS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_RESTRICTIONS_EDIT,
  };

  const lockedEditMap: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_LOCKED_EDIT,
    GRANT: Permits.GRANT_LOCKED_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_LOCKED_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_LOCKED_EDIT,
  };

  const assignmentsView: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_ASSIGNMENTS_VIEW,
    GRANT: Permits.GRANT_ASSIGNMENTS_VIEW,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_ASSIGNMENTS_VIEW,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_ASSIGNMENTS_VIEW,
  };

  const assignmentsEdit: Record<ProjectType, string> = {
    NIR: Permits.RESEARCH_JOB_ASSIGNMENTS_EDIT,
    GRANT: Permits.GRANT_ASSIGNMENTS_EDIT,
    GOVERMENT_CONTRACT: Permits.GOV_CONTRACT_ASSIGNMENTS_EDIT,
    ECONOMIC_AGREEMENT: Permits.ECONOMIC_CONTRACT_ASSIGNMENTS_EDIT,
  };

  const isVisibleStateRegistration = isHasPermission(userPermission, stateRegistrationView[projectType]);
  const isEditableStateRegistration = isHasPermission(userPermission, stateRegistrationEdit[projectType]);

  const isVisibleDocuments = isHasPermission(userPermission, documentsView[projectType]);
  const isEditableDocuments = isHasPermission(userPermission, documentsEdit[projectType]);
  const isEditableDocumentsAll = isHasPermission(userPermission, documentsAllEdit[projectType]);

  const isVisibleExecutors = isHasPermission(userPermission, executorsView[projectType]);
  const isEditableExecutors = isHasPermission(userPermission, executorsEdit[projectType]);

  const isVisiblePartnersAccomplices = isHasPermission(userPermission, partnersAccomplicesView[projectType]);
  const isVisiblePartnersPartners = isHasPermission(userPermission, partnersPartnersView[projectType]);
  const isEditablePartnersPartners = isHasPermission(userPermission, partnersPartnersEdit[projectType]);
  const isVisiblePartnersIntCoop = isHasPermission(userPermission, partnersIntCoopView[projectType]);
  const isEditablePartnersIntCoop = isHasPermission(userPermission, partnersIntCoopEdit[projectType]);
  const isVisiblePartners = isVisiblePartnersAccomplices || isVisiblePartnersPartners || isVisiblePartnersIntCoop;

  const isVisibleCalendarPlan = isHasPermission(userPermission, calendarPlanView[projectType]);
  const isEditableCalendarPlan = isHasPermission(userPermission, calendarPlanEdit[projectType]);

  const isVisibleClassifiers = isHasPermission(userPermission, classifiersView[projectType]);
  const isEditableClassifiers = isHasPermission(userPermission, classifiersEdit[projectType]);

  const isVisibleAboutProject = isHasPermission(userPermission, aboutProjectView[projectType]);
  const isEditableAboutProject = isHasPermission(userPermission, aboutProjectEdit[projectType]);

  const isVisibleProjectDescription = isHasPermission(userPermission, projectDescriptionView[projectType]);
  const isEditableProjectDescription = isHasPermission(userPermission, projectDescriptionEdit[projectType]);

  const isVisibleProjectFeatures = isHasPermission(userPermission, projectFeaturesView[projectType]);
  const isEditableProjectFeatures = isHasPermission(userPermission, projectFeaturesEdit[projectType]);

  const isVisibleResults = isHasPermission(userPermission, resultsView[projectType]);
  const isEditableResults = isHasPermission(userPermission, resultsEdit[projectType]);

  const isVisiblePurchaseRequests = isHasPermission(userPermission, purchaseRequestView[projectType]);

  const isVisibleRestrictions = isHasPermission(userPermission, restrictionsView[projectType]);
  const isEditableRestrictions = isHasPermission(userPermission, restrictionsEdit[projectType]);

  const isVisibleAssignments = isHasPermission(userPermission, assignmentsView[projectType]);
  const isEditableAssignments = isHasPermission(userPermission, assignmentsEdit[projectType]);

  const isEditableLocked = isHasPermission(userPermission, lockedEditMap[projectType]);

  return {
    isEditableLocked,
    isVisibleStateRegistration,
    isEditableStateRegistration,
    isVisibleDocuments,
    isEditableDocuments,
    isEditableDocumentsAll,
    isVisibleExecutors,
    isEditableExecutors,
    isVisiblePartnersAccomplices,
    isVisiblePartnersPartners,
    isEditablePartnersPartners,
    isVisiblePartnersIntCoop,
    isEditablePartnersIntCoop,
    isVisiblePartners,
    isVisibleCalendarPlan,
    isEditableCalendarPlan,
    isVisibleClassifiers,
    isEditableClassifiers,
    isVisibleAboutProject,
    isEditableAboutProject,
    isVisibleProjectDescription,
    isEditableProjectDescription,
    isVisibleProjectFeatures,
    isEditableProjectFeatures,
    isVisibleResults,
    isEditableResults,
    isVisiblePurchaseRequests,
    isVisibleRestrictions,
    isEditableRestrictions,
    isVisibleAssignments,
    isEditableAssignments,
  };
}

import { Merge } from 'types/models/Merge';
import { convertMaybeArray, getAttribute } from 'services/BackendAPI/configurations/commonConverters';
import {
  convertEntity,
  convertMetaFields,
  mapMetadataToField,
} from 'services/BackendAPI/configurations/MergeScientist/converters';

export const convertMergeReference = (merge: any): Merge => {
  const metadata = convertMetaFields(merge?.Metadata?.Field);
  const entities = convertMaybeArray(merge?.Entity, entity => convertEntity({ entity, metadata }));
  const common = mapMetadataToField({ fields: merge?.Common?.Field, metadata });
  const isConflicts = getAttribute({ item: merge, attributeKey: 'conflicts' }) === 'true';

  return {
    commonId: entities[0]?.id ?? null,
    isConflicts,
    metadata,
    common,
    entities,
  };
};

import { useCallback, useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

type Arguments = {
  prefix?: string[];
  init?: string[];
  defaultUrls?: Record<string, string>;
};

type Answer = {
  url: string[];
  changeUrl(index: number, value?: string): void;
};

/**
 * Add route navigation to component
 *
 * @param prefix - (optional) URL prefix for all URLs of component
 * @param init - (optional) Default url parts on render component
 * @param defaultUrls - (optional) Default deep parts to tabs
 */
export function useRouteNavigation({ prefix = [], init = [], defaultUrls = {} }: Arguments): Answer {
  const history = useHistory();
  const location = useLocation();

  const [url, setUrl] = useState<string[]>([]);

  const buidUrl = useCallback((parts: string[]) => `/${prefix.join('/')}/${parts.join('/')}`, [prefix]);

  const changeUrl = useCallback(
    (index: number, value?: string) => {
      const cloneUrlObj: string[] = [...url];
      cloneUrlObj.splice(index, 1, value || ''); // replace position

      const result: string[] = [
        ...cloneUrlObj.slice(0, index + (value ? 1 : 0)), // remove all items after empty item
        ...(!index && value && defaultUrls[value] ? [defaultUrls[value]] : []), // add default parts
      ];

      setUrl(result);

      const nextUrl: string = buidUrl(result);
      if (location.pathname !== nextUrl) {
        history.push(nextUrl);
      }
    },
    [buidUrl, defaultUrls, history, location.pathname, url],
  );

  useEffect(() => {
    const nextUrl: string = buidUrl(url);

    if (location.pathname !== nextUrl) {
      setUrl(location.pathname.split('/').slice(prefix.length + 1));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  useEffect(() => {
    const nextUrl: string = buidUrl(init);

    if (location.pathname === `/${prefix.join('/')}`) {
      history.push(nextUrl);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    url,
    changeUrl,
  };
}

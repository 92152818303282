import React from 'react';

import { FormComponent, ListEdit, ReferenceItem, RefList, SectionTitle, Select, SelectMode } from 'components';

import { ProgramRequest } from 'types/models/ProgramRequest';
import { GetProgramKnowledgeBranchSelectList } from 'features/Table/specifications/GetProgramKnowledgeBranchSelectList';
import { ValueOf } from 'types/helpers';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';
import { GetReferenceElementList } from 'features/Table/specifications';
import { Specification } from 'types/models/Table';
import { getKnowledgeBranchGroupLabel } from 'utils/Helpers/knowledgeBranch';

type Props = {
  viewMode?: boolean;
  programRequest: ProgramRequest | null;
  makeChangeHandler(key: keyof ProgramRequest): (val: ValueOf<ProgramRequest>) => void;
  okveds: ReferenceItem[];
  handleOkvedChange(value: ReferenceItem | null): void;
  okvedParentLabel: string;
  lksetss: ReferenceItem[];
  handleLksetsChange(value: ReferenceItem | null): void;
  lksetsParentLabel: string;
  scienceBrunches: ReferenceItem[];
  handleScienceBrunchChange(value: ReferenceItem | null): void;
  scienceBrunchParentLabel: string;
  knowledgeBranches: ReferenceItem[];
  handleKnowledgeBranchesChange(value: ReferenceItem[]): void;
};

export function Classifiers(props: Props) {
  const {
    viewMode,
    programRequest,
    makeChangeHandler,
    okveds,
    handleOkvedChange,
    okvedParentLabel,
    lksetss,
    handleLksetsChange,
    lksetsParentLabel,
    scienceBrunches,
    handleScienceBrunchChange,
    scienceBrunchParentLabel,
    knowledgeBranches,
    handleKnowledgeBranchesChange,
  } = props;

  const { knowledgeBranchTableRowConverter, scienceDomainInterrestsFilters } = useController({
    programRequest,
  });

  const { settings } = useAppDataContext();

  const programDomains = programRequest?.tender?.program?.knowledgeBranches;

  return (
    <FormComponent.ColumnWrapper>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <RefList
              header={{ title: 'ГРНТИ' }}
              rows={programRequest?.grntis || []}
              onChange={value => makeChangeHandler('grntis')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "ГРНТИ"'
              refName="RefGrnti"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'УДК' }}
              rows={programRequest?.udks || []}
              onChange={value => makeChangeHandler('udks')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "УДК"'
              refName="RefUdk"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>

          <SectionTitle title="2-Наука" />
          <FormComponent.Line>
            <FormComponent.Field label="ОКВЭД">
              <Select
                mode={SelectMode.REFERENCE}
                value={okveds[0]}
                onChange={handleOkvedChange}
                settings={{ name: 'RefOkved2', title: 'ОКВЭД', isClearable: true }}
                isDisabled={!!viewMode}
              />

              <FormComponent.Description>{okvedParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="ЛКСЭЦ">
              <Select
                mode={SelectMode.REFERENCE}
                value={lksetss[0]}
                onChange={handleLksetsChange}
                settings={{ name: 'RefLksets', title: 'Классификатор социально-экономических целей', isClearable: true }}
                isDisabled={!!viewMode}
              />

              <FormComponent.Description>{lksetsParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <FormComponent.Field label="Отрасль науки">
              <Select
                mode={SelectMode.REFERENCE}
                value={scienceBrunches[0]}
                onChange={handleScienceBrunchChange}
                settings={{ name: 'RefScienceBrunch', title: 'Справочник "Отрасли науки"', isClearable: true }}
                isDisabled={!!viewMode}
              />

              <FormComponent.Description>{scienceBrunchParentLabel}</FormComponent.Description>
            </FormComponent.Field>
          </FormComponent.Line>

          <FormComponent.Line>
            <RefList
              header={{
                title: 'Области научных интересов',
                tooltip:
                  // eslint-disable-next-line max-len
                  'В таблице отображается список допустимых областей научных интересов по условиям конкурса. Удалите те области, которые не соответствуют теме вашей заявки.',
              }}
              rows={programRequest?.scienceDomainInterrests || []}
              onChange={value => makeChangeHandler('scienceDomainInterrests')(value)}
              toolbar={['add', 'delete']}
              modalTitle="Список областей научных интересов по условиям конкурса"
              refName="RefScienceDomainInterest"
              isDisabled={!!viewMode}
              filters={scienceDomainInterrestsFilters}
              isLeftPanelHidden
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Критические технологии' }}
              rows={programRequest?.criticalTechnologies || []}
              onChange={value => makeChangeHandler('criticalTechnologies')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "Критические технологии"'
              refName="RefPriorityTechnology"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
      <FormComponent.Column>
        <FormComponent.Wrapper>
          <FormComponent.Line>
            <RefList
              header={{ title: `Приоритетные направления развития ${settings?.organization?.shortName}` }}
              rows={programRequest?.pnrs || []}
              onChange={value => makeChangeHandler('pnrs')(value)}
              toolbar={['add', 'delete']}
              modalTitle={`Справочник "ПНР ${settings?.organization?.shortName}"`}
              refName="RefPnr"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритетные направления модернизации и технического развития экономики России' }}
              rows={programRequest?.pnmitrs || []}
              onChange={value => makeChangeHandler('pnmitrs')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "ПНР модернизации РФ"'
              refName="RefPnmitr"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритетные направления развития науки, технологий и техники' }}
              rows={programRequest?.pnis || []}
              onChange={value => makeChangeHandler('pnis')(value)}
              toolbar={['add', 'delete']}
              modalTitle="Приоритетные направления науки, технологий и техники"
              refName="RefPni"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Приоритеты Стратегии НТР РФ' }}
              rows={programRequest?.ntrStrategies || []}
              onChange={value => makeChangeHandler('ntrStrategies')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "Приоритеты Стратегии НТР РФ"'
              refName="RefNtrStrategy"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
          <FormComponent.Line>
            <RefList
              header={{ title: 'Технологические платформы' }}
              rows={programRequest?.technologyPlatforms || []}
              onChange={value => makeChangeHandler('technologyPlatforms')(value)}
              toolbar={['add', 'delete']}
              modalTitle='Справочник "Технологические платформы"'
              refName="RefTechnologyPlatform"
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>

          <FormComponent.Line>
            <ListEdit
              header={{ title: 'Отрасли знаний (РНФ)' }}
              rows={knowledgeBranches}
              onChange={handleKnowledgeBranchesChange}
              toolbar={['add', 'delete']}
              columns={[
                {
                  label: 'Отрасль знаний',
                  formatValue: x => x.label || '',
                  styles: { width: '50%' },
                },
                {
                  label: 'Группа (подгруппа)',
                  formatValue: x => getKnowledgeBranchGroupLabel(x),
                  styles: { width: '50%' },
                },
              ]}
              withMessages
              isDeleteConfirmEnabled
              withoutHead={true}
              specification={{
                mode: 'relationTableModal',
                relationTableModalTitle: 'Справочник "Отрасли знаний (РНФ)"',
                ...(programDomains?.length
                  ? {
                      modalTableRowConverter: knowledgeBranchTableRowConverter,
                      modalTableSpecification: GetProgramKnowledgeBranchSelectList({
                        programId: programRequest?.tender?.program?.id || '-1',
                      }),
                    }
                  : {
                      modalTableRowConverter: row => ({ id: row.id, label: row.label, code: row.code }),
                      modalTableSpecification: GetReferenceElementList({
                        removeEdit: true,
                        requestData: {
                          filters: [],
                          name: 'RefKnowledgeBranch',
                        },
                      }) as Specification<object, object>,
                    }),
              }}
              isDisabled={!!viewMode}
            />
          </FormComponent.Line>
        </FormComponent.Wrapper>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
}

import { Icon } from 'components';

export function PersonalRecognitionGridHelp(): JSX.Element {
  return (
    <>
      <p>
        <strong>Раздел Признания и награды </strong>ппредназначен для ввода и хранения информации о персональных наградах или
        признаниях (награды министерств и ведомств, правительственные награды, областной и городской администрации, университета и
        пр.)
      </p>

      <ul>
        <li>
          <strong>Фильтр «Мои награды»</strong> содержит список ваших наград
        </li>
        <li>
          <strong>Фильтр «Все награды»</strong> содержит список персон, имеющих награды
        </li>
      </ul>

      <p>
        Информацию о своих наградах и достижениях пользователь заносит в данном разделе Личного кабинета или ответственное лицо от
        подразделения заносит в общем меню сайта. Связь с подразделением должна быть указана для отображения вашей награды в
        отчете подразделения. Привязка к подразделению отображается в столбце «Подразделение».
      </p>

      <h3>В разделе Признания и награды вы можете:</h3>

      <ul>
        <li>
          Посмотреть запись о награде с помощью кнопки <Icon type="view" />
        </li>
        <li>
          Добавить новую награду с помощью кнопки <Icon type="add" />
        </li>
        <li>
          Редактировать запись о награде в статусе Добавлена с помощью кнопки <Icon type="edit" />
        </li>
        <li>
          Удалить запись о награде, которую вы создавали, если она в статусе Добавлена с помощью кнопки <Icon type="remove" />
        </li>
        <li>
          Скачать прикрепленный файл награды с помощью кнопки <Icon type="download" />
        </li>
        <li>
          Выгрузить список наград в файл Excel с помощью кнопки <Icon type="excel" />
        </li>
        <li>
          Задать вопрос или написать сообщение администратору в отдельном окне с помощью кнопки <Icon type="admin" />
        </li>
      </ul>

      <p>
        При сохранении награде присваивается статус <strong>Добавлена</strong>. Добавленную запись о награде можно редактировать,
        добавить информацию или удалить.
      </p>
      <p>
        Добавленную вами награду проверит и переведет в статус <strong>Утверждена</strong> Администратор данных. Утвержденная
        запись недоступна для внесения изменений. Если вы нашли ошибку в утвержденной записи - напишите письмо Администратору.
      </p>
    </>
  );
}

import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Project } from 'types/models';
import { useLocalTableStreams } from 'features/Table/hooks';
import { useFormContext } from 'features/Form/hooks';
import { showNotification } from 'features/Notifications';
import { ProjectReportType } from 'utils/Enums';
import { getEnumItem } from 'utils/Helpers';

import { getMockReport, getMockStage } from 'features/Form/looks/project/ProjectForm/helpers';

type Props = {
  onClose: () => void;
};

export function useController({ onClose }: Props) {
  const [report, setReport] = useState<Project.Report>(
    getMockReport({ type: getEnumItem('ProjectReportType', ProjectReportType.INTERMEDIATE) }),
  );

  const tableStreams = useLocalTableStreams();

  const {
    look: { id },
  } = useFormContext();

  const { methods: getProjectReport } = BackendAPI.useBackendAPI('GetProjectReport', {
    onSuccessfullCall: ({ data }) => setReport(data),
  });

  const { methods: saveProjectReport } = BackendAPI.useBackendAPI('SaveProjectReport', {
    onSuccessfullCall: () => {
      onClose();
      tableStreams.reloadTable.push({});
      showNotification({ message: 'Отчет успешно сохранен', theme: 'success' });
    },
  });

  const onSubmit = useCallback(() => {
    const preparedReport: Project.Report = {
      ...report,
      ...(!report.stage?.name ? { stage: { ...(report.stage || getMockStage()), name: report.name } } : {}),
    };
    saveProjectReport.callAPI({
      report: preparedReport,
    });
  }, [report, saveProjectReport]);

  useLayoutEffect(() => {
    if (id) getProjectReport.callAPI({ id });
    // eslint-disable-next-line
  }, []);

  return {
    onSubmit,
    report,
    setReport,
  };
}

import React from 'react';

import { DataGrid } from 'features/Table';
import { GetScientistHirshIndexesList } from 'features/Table/specifications';

import { useController } from './controller';
import { FormComponent } from 'components';

function ScientistDataForm() {
  const { relatedTableState, id } = useController();
  const workMode = 'editMode';

  return (
    <FormComponent.Wrapper>
      {relatedTableState && (
        <DataGrid
          specification={GetScientistHirshIndexesList(
            {
              relatedTableAPIID: 'GetScientistStatistics',
              relatedTableState,
              relatedRecordId: id,
            },
            workMode,
            id || '-1',
          )}
        />
      )}
    </FormComponent.Wrapper>
  );
}

export const Component = React.memo(ScientistDataForm);

import { Icon, Tab, Tabs } from 'components';

import { ProjectHelp } from 'features/Form/looks/project/ProjectForm/help';
import { TemplatesHelp } from 'features/Table/ThirdLevelHeader/TemplatesPanel/help';
// eslint-disable-next-line max-len
import { ProjectStateRegistrationReportArchiveFiltersHelp } from 'features/Table/specifications/GetProjectStateRegistrationReportArchiveList/filters';

export function ProjectStateRegistrationReportGridHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице"> {ProjectHelp().registrationReports}</Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму отчёта для просмотра.</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму отчёта для редактирования.</td>
            </tr>
            <tr>
              <td>
                Удалить: <Icon type="remove" />
              </td>
              <td>
                Удалить отчёт по этапу проекта из графика госрегистрации отчётов.
                <br />
                Кнопка активна, если в таблице выделена запись с отчётом и для отчёта не заполнены поля в разделе «Госрегистрация
                отчёта».
              </td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

export function ProjectReportArciveHelp(): JSX.Element {
  return (
    <Tabs>
      <Tab title="Представление в таблице">
        <h4>Список научных отчётов в архиве</h4>
        <p>Раздел «Архив отчётов» предназначен для ведения учёта научных отчётов в архиве университета.</p>
        <p>Таблица содержит список всех отчётов по этапам проекта, переданных в архив.</p>
        <p>Основные столбцы таблицы содержат данные отчёта, названия столбцов соответствуют названиям полей на форме отчёта. </p>
        <p>
          Дополнительные столбцы содержат данные из проекта: значения классификаторов тематики; из этапа проекта – аннотация,
          ключевые слова, результаты.
        </p>
        <p>
          Дополнительные столбцы таблицы предназначены для поиска отчётов по ключевым словам в разрезе отраслей наук, тематических
          групп и приоритетных направлений исследований.
        </p>
        {ProjectStateRegistrationReportArchiveFiltersHelp}
        <div className="warning">
          <p>Функционал для добавления отчётов по этапам проекта находится в двух вариантах:</p>
          <ul>
            <li>
              {/*eslint-disable-next-line max-len*/}
              на вкладке Проекты — <strong>Составить график регистрации отчётов проекта</strong>
            </li>
            <li>на форме проекта, вкладка Этапы, в разделе Отчёты.</li>
          </ul>
        </div>
      </Tab>
      <Tab title="Функциональные кнопки">
        <h4>Панель инструментов для управления записями в таблице</h4>
        <table>
          <thead>
            <tr>
              <th>Функциональная кнопка</th>
              <th>Описание</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>
                Просмотр: <Icon type="view" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму отчёта для просмотра.</td>
            </tr>
            <tr>
              <td>
                Редактировать: <Icon type="edit" />
              </td>
              <td>Кнопка активна, если в таблице выделена запись с отчётом. Открывает форму отчёта для редактирования.</td>
            </tr>
            <tr>
              <td>
                Выгрузить в файл: <Icon type="excel" />
              </td>
              <td>
                Формирует файл .xls с таблицей «как есть». Пользователь может сохранить файл и использовать его в качестве основы
                для оформления отчёта по запросу.
              </td>
            </tr>
          </tbody>
        </table>
      </Tab>
      <Tab title="Настройка таблицы">{TemplatesHelp()}</Tab>
    </Tabs>
  );
}

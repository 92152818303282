import { useState, useLayoutEffect, useCallback } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { showNotification } from 'features/Notifications';
import { Form as F } from 'types/models';
import { useFormContext } from 'features/Form/hooks';
import { useLocalTableStreams } from 'features/Table/hooks';
import { TenderKindConsumption } from 'types/models/KindConsumption';

import { getMockTenderKindConsumption } from './helpers';

type Props = {
  onClose(): void;
};

export function useController({ onClose }: Props) {
  const tableStreams = useLocalTableStreams();
  const {
    look: { id: initialId, tenderId },
  } = useFormContext<F.TenderKindConsumptionFormLook>();

  const [tenderKindConsumption, setTenderKindConsumption] = useState<TenderKindConsumption>(getMockTenderKindConsumption());

  const { methods: getTenderKindConsumption } = BackendAPI.useBackendAPI('GetTenderKindConsumption');
  const { methods: saveTenderKindConsumption } = BackendAPI.useBackendAPI('SaveTenderKindConsumption');

  const onSubmit = useCallback(() => {
    if (!tenderKindConsumption?.name || !tenderKindConsumption.position) {
      showNotification({ message: 'Заполните обязательные поля', theme: 'danger' });
      return;
    }

    saveTenderKindConsumption.callAPI(
      { ...tenderKindConsumption, tenderId },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Запись сохранена', theme: 'success' });
          tableStreams.reloadTable.push({});
        },
      },
    );
    onClose();
  }, [tenderKindConsumption, saveTenderKindConsumption, tenderId, onClose, tableStreams.reloadTable]);

  useLayoutEffect(() => {
    if (initialId)
      getTenderKindConsumption.callAPI(
        { id: initialId },
        {
          onSuccessfullCall: ({ data }) => {
            setTenderKindConsumption(data);
          },
        },
      );
    // eslint-disable-next-line
  }, []);

  return {
    onSubmit,
    tenderKindConsumption,
    setTenderKindConsumption,
    getTenderKindConsumption,
    tenderId,
  };
}

import { useFormContext } from 'features/Form/hooks';

const useController = () => {
  const {
    look: { relatedTableState },
  } = useFormContext();

  return {
    relatedTableState,
  };
};

export default useController;

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertEquip, convertEquipVerificationContact } from './converters';
import { convertEquipToRequest, convertEquipVerificationContactToXML } from './requestConverters';
import { Equip } from 'types/models';
import { Settings } from 'types/models/common';
import { getText } from '../commonConverters';

const configurations = {
  GetEquip: makeBackendAPIConfiguration({
    id: 'GetEquip',
    endpoint: '/msa/service/commands/GetEquip',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetEquip' },
        Equip: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertEquip(response.Response.Equip, settings),
  }),
  SaveEquip: makeBackendAPIConfiguration({
    id: 'SaveEquip',
    endpoint: '/msa/service/commands/SaveEquip',
    convertInputToXMLElement: (equip: Equip.Equip) => ({
      Request: {
        _attr: {
          commandName: 'SaveEquip',
        },
        Equip: { ...convertEquipToRequest(equip) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => ({ id: response.Response.Id?._text as string }),
  }),
  SaveEquipVerification: makeBackendAPIConfiguration({
    id: 'SaveEquipVerification',
    endpoint: '/msa/service/commands/SaveEquipVerification',
    convertInputToXMLElement: (equip: Equip.Equip) => ({
      Request: {
        _attr: {
          commandName: 'SaveEquipVerification',
        },
        Equip: { ...convertEquipToRequest(equip) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => ({ id: response.Response.Id?._text as string }),
  }),
  DeleteEquipVerification: makeBackendAPIConfiguration({
    id: 'DeleteEquipVerification',
    endpoint: '/msa/service/commands/DeleteEquipVerification',
    convertInputToXMLElement: ({ ids }: { ids: string[] }) => ({
      Request: {
        _attr: { commandName: 'DeleteEquipVerification' },
        EquipVerification: ids.map(id => ({
          _attr: { id },
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetEquipVerificationContact: makeBackendAPIConfiguration({
    id: 'GetEquipVerificationContact',
    endpoint: '/msa/service/commands/GetEquipVerificationContact',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetEquipVerificationContact' },
        EquipVerificationContact: {
          _attr: { id },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertEquipVerificationContact(response.Response.EquipVerificationContact, settings),
  }),
  SaveEquipVerificationContact: makeBackendAPIConfiguration({
    id: 'SaveEquipVerificationContact',
    endpoint: '/msa/service/commands/SaveEquipVerificationContact',
    convertInputToXMLElement: (equipVerificationContact: Equip.EquipVerificationContact) => ({
      Request: {
        _attr: { commandName: 'SaveEquipVerificationContact' },
        EquipVerificationContact: convertEquipVerificationContactToXML(equipVerificationContact),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => ({ id: getText(response.Response.Id) }),
  }),
  DeleteEquipVerificationContact: makeBackendAPIConfiguration({
    id: 'DeleteEquipVerificationContact',
    endpoint: '/msa/service/commands/DeleteEquipVerificationContact',
    convertInputToXMLElement: ({ ids }: { ids: string[] }) => ({
      Request: {
        _attr: { commandName: 'DeleteEquipVerificationContact' },
        EquipVerificationContact: ids.map(id => ({
          _attr: { id },
        })),
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const equipConfigurations = { ...configurations };

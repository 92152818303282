import { Table } from 'types/models';

export type State = {
  workMode: Table.WorkMode;
};

type Props = {
  workMode: Table.WorkMode;
};

export function makeUseCustomController({ workMode }: Props) {
  return function useCustomController(): State {
    return {
      workMode,
    };
  };
}

import { Parameter, ParameterListValue } from 'types/models';
import { PARTIAL_RADIO_VALUE, ALL_RADIO_VALUE } from './DefaultParameterList/helpers';
import { ValidatorResult } from 'features/BuildReportPopup/models';

const validateParameterList = (parameter: Parameter): ValidatorResult => {
  const { required } = parameter;
  const value = parameter.value as ParameterListValue;

  if (required) {
    const isValid = !!(value.radioValue === PARTIAL_RADIO_VALUE && value.list.length) || value.radioValue === ALL_RADIO_VALUE;
    return { isValid, message: isValid ? '' : `Необходимо добавить хотя бы один элемент в список "${parameter.description}"` };
  }

  return { isValid: true, message: '' };
};

export default validateParameterList;

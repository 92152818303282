import { parse } from 'date-fns';

import { formatStr } from 'utils/Constants';

export function formatNumber(num: string | number, digits?: number, numType?: string) {
  const finalDigits = digits ?? (['INTEGER', 'BOOLEAN'].includes(numType || '') ? 0 : 2);
  return Number(num || 0)
    .toLocaleString('en-US', { maximumFractionDigits: finalDigits, minimumFractionDigits: finalDigits })
    .replaceAll(',', ' ');
}

export function formatStringToDate(date: string): Date {
  return parse(date, formatStr, new Date());
}

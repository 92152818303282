import { useCallback, useState } from 'react';

import { Arguments } from '.';
import { useLocalTableStreams } from 'features/Table/hooks';
import { ProjectType } from 'utils/Enums';

export type State = {
  isOpenForm: boolean;
  toggleVisibleForm(): void;
  reloadTable(): void;
  mode: Mode | null;
  setMode(mode: Mode | null): void;
  projectType: ProjectType | null;
  setProjectType(project: ProjectType | null): void;
  hasToolbarButtonMode: boolean;
};

export type Mode = 'edit' | 'add' | 'view';

export function makeUseCustomController({ hasToolbarButtonMode = false }: Arguments) {
  return function useCustomController(): State {
    const [isOpenForm, setIsOpenForm] = useState(false);
    const [mode, setMode] = useState<Mode | null>(null);
    const [projectType, setProjectType] = useState<ProjectType | null>(null);

    const tableStreams = useLocalTableStreams();

    const reloadTable = useCallback(() => {
      tableStreams.reloadTable.push({});
    }, [tableStreams.reloadTable]);

    const toggleVisibleForm = useCallback(() => {
      setIsOpenForm(prev => !prev);
    }, []);

    return {
      isOpenForm,
      toggleVisibleForm,
      reloadTable,
      mode,
      setMode,
      projectType,
      setProjectType,
      hasToolbarButtonMode,
    };
  };
}

import { useLayoutEffect, useMemo, useState, useCallback, useRef } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Item as ItemOption } from 'types/models/common';

type Item = {
  name: string;
  data: number[];
};

export function useController() {
  const [documentsData, setDocumentsData] = useState<Array<Item[]>>([]);
  const [activePeriodFilter, setActivePeriodFilter] = useState<string>(new Date().getFullYear().toString());

  const yearslist = useMemo(() => [5, 4, 3, 2, 1, 0].map(x => new Date().getFullYear() - x), []);

  const periodOpts = useRef(
    yearslist.map(yearItem => ({
      value: yearItem.toString(),
      label: `${yearItem} год`,
    })),
  );

  const [isLoading, setIsLoading] = useState(false);
  const categories = useMemo(() => [''], []);

  const { methods: GetDashboardChartByTypeSecurityDocumentListStatic } = BackendAPI.useBackendAPI(
    'GetDashboardChartByTypeSecurityDocumentListStatic',
    {
      onSuccessfullCall: ({ data }) => {
        const chartAttrRes = data.Response.Grid.Rows.Row._attributes;
        setDocumentsData(
          yearslist.map(year => [
            {
              name: 'Договоры на свидетельства',
              data: [Number(chartAttrRes[`Year${year}EvidenceContracts`]) || 0],
            },
            {
              name: 'Свидетельства',
              data: [Number(chartAttrRes[`Year${year}EvidenceDocuments`]) || 0],
            },
            {
              name: 'Заявки на свидетельства',
              data: [Number(chartAttrRes[`Year${year}EvidenceRequests`]) || 0],
            },
            {
              name: 'Договоры на ноу-хау',
              data: [Number(chartAttrRes[`Year${year}KnowhowContracts`]) || 0],
            },
            {
              name: 'Ноу-хау',
              data: [Number(chartAttrRes[`Year${year}KnowhowDocuments`]) || 0],
            },
            {
              name: 'Договоры на патенты',
              data: [Number(chartAttrRes[`Year${year}PatentContracts`]) || 0],
            },
            {
              name: 'Патенты',
              data: [Number(chartAttrRes[`Year${year}PatentDocuments`]) || 0],
            },
            {
              name: 'Заявки на патенты',
              data: [Number(chartAttrRes[`Year${year}PatentRequests`]) || 0],
            },
          ]),
        );
      },
    },
  );

  useLayoutEffect(() => {
    setIsLoading(true);

    GetDashboardChartByTypeSecurityDocumentListStatic.callAPI(
      {},
      {
        onSuccessfullCall: () => {
          setIsLoading(false);
        },
        onFailedCall: () => {
          setIsLoading(false);
        },
      },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const activeFilter = useMemo(() => periodOpts.current.find(opt => opt.value === activePeriodFilter), [
    periodOpts,
    activePeriodFilter,
  ]);

  const activeYearIndex = useMemo(() => periodOpts.current.findIndex(opt => opt.value === activePeriodFilter), [
    activePeriodFilter,
  ]);

  const handleChangeActiveFilter = useCallback(
    (option: ItemOption) => {
      setActivePeriodFilter(option.value || '');
    },
    [setActivePeriodFilter],
  );

  return {
    activeFilter,
    periodOpts,
    handleChangeActiveFilter,
    activeYearIndex,
    isLoading,
    categories,
    documentsData,
  };
}

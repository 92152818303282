import { Specification } from 'types/models/Table';
import { TypeEditionCode } from 'utils/Enums/TypeEditionCode';

type Props = {
  startYear: string;
  endYear: string;
  typeEdition: TypeEditionCode | null;
};

export function GetDashboardChartByTypeEditionMagazineArticleList(requestData: Props): Specification {
  return {
    apiID: 'GetDashboardChartByTypeEditionMagazineArticleList',
    workMode: 'viewMode',
    header: {
      thirdLevel: {
        withTemplatesPanel: false,
      },
    },
    requestData,
  };
}

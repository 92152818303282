import React from 'react';

import { FormComponent, ReferenceItem, Select, SelectMode } from 'components';

import { Department } from 'types/models/ProgramRequest';
import { useController } from './controller';

type Props = {
  department: Department | null;
  onDepartmentChange(row: Department): void;
};

function DepartmentInputs({ department, onDepartmentChange }: Props) {
  const { handleFieldChange } = useController({
    department,
    onDepartmentChange,
  });

  return (
    <FormComponent.Wrapper>
      <FormComponent.Line>
        <FormComponent.Field label="Факультет/Институт" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department?.governance}
            onChange={(option: ReferenceItem | null) => handleFieldChange('governance')(option)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Подразделение" isRequired>
          <Select
            mode={SelectMode.REFERENCE}
            value={department?.unit}
            onChange={(option: ReferenceItem | null) => handleFieldChange('unit')(option)}
            settings={{ name: 'RefDepartment', title: 'Справочник "Подразделения"' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
    </FormComponent.Wrapper>
  );
}

export { DepartmentInputs };

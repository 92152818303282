import { ReferenceItem } from 'components';
import { SecurityDocument, SecurityDocumentMember } from 'types/models/SecurityDocument';
import { Department } from 'types/models/Project';

export function getMockSecurityDocument(documentType: string): SecurityDocument {
  return {
    balanceOrderNumber: 0,
    label: '',
    balancePrice: 0,
    contracts: [],
    documentEndDate: '',
    documentNumber: '',
    documentStartDate: '',
    documents: [],
    knowledgeBranches: [],
    grntis: [],
    hiDate: '',
    id: null,
    isRus: false,
    isWorldwide: false,
    kind: null,
    intellectualPropertyName: '',
    referat: '',
    lksetss: [],
    loDate: '',
    members: [],
    countries: [],
    ntiEteTechnologies: [],
    ntiMarkets: [],
    ntrStrategies: [],
    oecds: [],
    okveds: [],
    payments: [],
    pnis: [],
    pnmitrs: [],
    pnrs: [],
    priorityTechnologies: [],
    project: null,
    requestDate: '',
    requestSendDate: '',
    documentDate: '',
    documentReceiveDate: '',
    requestNumber: '',
    scienceBrunches: [],
    scienceDomainInterrests: [],
    vakSpecialities: [],
    stateNumber: '',
    stateRegistrationDate: '',
    technologyPlatforms: [],
    udks: [],
    type: { label: '', value: documentType || '' },
    departments: [],
    isRejected: false,
    internalNumber: '',
    mpks: [],
    balances: [],
  };
}

export function getDepartmentFromMember(member: SecurityDocumentMember) {
  let newDepartment: Department | null = null;
  const jobDepartment = member.job?.refDepartment;
  const educationDepartment = member.education?.refDepartment;
  if (jobDepartment) {
    newDepartment = {
      id: null,
      unit: jobDepartment,
      governance:
        (jobDepartment.customFields?.faculty as ReferenceItem) ||
        (jobDepartment.customFields?.parent as ReferenceItem) ||
        jobDepartment,
    };
  } else if (educationDepartment) {
    newDepartment = {
      id: null,
      unit: educationDepartment,
      governance:
        (educationDepartment.customFields?.faculty as ReferenceItem) ||
        (educationDepartment.customFields?.parent as ReferenceItem) ||
        educationDepartment,
    };
  }
  return newDepartment;
}

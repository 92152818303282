// components start
import { PaymentRequestPeriod } from './PaymentRequestPeriod';
import { DateRange } from './DateRange';
import { Year } from './Year';
import { Enumeration } from './Enumeration';
import { PrintFormat } from './PrintFormat';
import { RequestIds } from './RequestIds';
import {
  RegulationParagraph,
  DepartmentTypeParameterList,
  ExhibitTypeParameterList,
  UserDepartment,
  EventLevelStatus,
  FinTypeList,
  ProgramList,
  EventList,
  RequestStatusParameterList,
  ProgramsList,
  GrantTypeList,
  NotRequiredStages,
  PublicationType,
} from './ParameterList';

import { DepartmentFaculty, DepartmentList, ProjectCodeLetter } from './Reference';

import { Department } from './ExtendedParameterList';
import {
  ScientistCustomSelect,
  FullScienceProjectSelect,
  ContestRequestSelect,
  ProjectCodeSelect,
  NirRequestSelect,
  RequestSelect,
  TenderSelect,
  MipSelect,
  MobileRequestSelect,
  MobileProjectSelect,
  TenderProtocolSelect,
  ProgramSelect,
  ConferenceCustomSelect,
  EventCustomSelect,
  MobileProtocolCustomSelect,
  PurchaseRequestCustomSelect,
  PartnerSelect,
} from './CustomSelect';
import {
  ProjectTypes,
  YoungScientists,
  DocumentTypes,
  IncludeTransitoryBalance,
  RequestTypes,
  AuthorRequestStatus,
  RequestStatus,
  NirRequestStatus,
  IsAccepted,
  Approve,
} from './CheckboxList';
import { DissertationCouncilReportType, PersonStatus } from './RadioList';
import { DateBuilder } from './DateBuilder';
import { Parameters } from '../Parameters';
// componentst end

// validators start
import validateCheckboxList from './CheckboxList/validate';

// unqique checkbox list validator start
import validateManyCheckboxList from './CheckboxList/manyValidate';
// unqique checkbox list validator end

import validateCustomSelect from './CustomSelect/validate';
import validateDate from './DateBuilder/validate';
import validateDateRange from './DateRange/validate';
import validateEnumeration from './Enumeration/validate';
import validateExtendedParameterList from './ExtendedParameterList/validate';
import validateParameterList from './ParameterList/validate';
import validatePaymentRequestPeriod from './PaymentRequestPeriod/validate';
import validateRadioList from './RadioList/validate';
import validateYear from './Year/validate';
import validatePrintFormat from './PrintFormat/validate';
import validateRequestIds from './RequestIds/validate';
import validateReference from './Reference/validate';
// validators end

// queryBuilder start
import queryBuilderCheckboxList from './CheckboxList/queryBuilder';
// unqique checkbox list query builder start
import queryBuilderManyCheckboxList from './CheckboxList/manyQueryBuilder';
// unqique checkbox list query builder end

import queryBuilderCustomSelect from './CustomSelect/queryBuilder';
import queryBuilderDate from './DateBuilder/queryBuilder';
import queryBuilderDateRange from './DateRange/queryBuilder';
import queryBuilderEnumeration from './Enumeration/queryBuilder';
import queryBuilderExtendedParameterList from './ExtendedParameterList/queryBuilder';
import queryBuilderParameterList from './ParameterList/queryBuilder';
import userDepartmentsQueryBuilder from './ParameterList/DefaultParameterList/SelectUserDepartments/queryBuilder';
import queryBuilderPaymentRequestPeriod from './PaymentRequestPeriod/queryBuilder';
import queryBuilderRadioList from './RadioList/queryBuilder';
import queryBuilderYear from './Year/queryBuilder';
import queryBuilderPrintFormat from './PrintFormat/queryBuilder';
import queryBuilderRequestIds from './RequestIds/queryBuilder';
import queryBuilderReference from './Reference/queryBuilder';
import { ExhibitionCustomSelect } from './CustomSelect/ExhibitionCustomSelect';
import { ContestCustomSelect } from './CustomSelect/ContestCustomSelect';
import { SecurityDocumentSelect } from 'features/BuildReportPopup/builders/CustomSelect/SecurityDocumentSelect';
// eslint-disable-next-line max-len
import { ProjectPerformerOrderCustomSelect } from 'features/BuildReportPopup/builders/CustomSelect/ProjectPerformerOrderCustomSelect';
// queryBuilder end

export const builders = {
  [Parameters.PrintFormat]: {
    name: Parameters.PrintFormat,
    component: PrintFormat,
    validator: validatePrintFormat,
    queryBuilder: queryBuilderPrintFormat,
  },
  [Parameters.RequestIds]: {
    name: Parameters.RequestIds,
    component: RequestIds,
    validator: validateRequestIds,
    queryBuilder: queryBuilderRequestIds,
  },
  [Parameters.DropdownPresetYear]: {
    name: Parameters.DropdownPresetYear,
    component: DateBuilder,
    validator: validateDate,
    queryBuilder: queryBuilderDate,
  },
  [Parameters.StartDate]: {
    name: Parameters.StartDate,
    component: DateBuilder,
    validator: validateDate,
    queryBuilder: queryBuilderDate,
  },
  [Parameters.EndDate]: {
    name: Parameters.EndDate,
    component: DateBuilder,
    validator: validateDate,
    queryBuilder: queryBuilderDate,
  },
  [Parameters.PaymentRequestPeriod]: {
    name: Parameters.PaymentRequestPeriod,
    component: PaymentRequestPeriod,
    validator: validatePaymentRequestPeriod,
    queryBuilder: queryBuilderPaymentRequestPeriod,
  },
  [Parameters.DateRange]: {
    name: Parameters.DateRange,
    component: DateRange,
    validator: validateDateRange,
    queryBuilder: queryBuilderDateRange,
  },
  [Parameters.Scientist]: {
    name: Parameters.Scientist,
    component: ScientistCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ScientistId]: {
    name: Parameters.ScientistId,
    component: ScientistCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.SecurityDocumentId]: {
    name: Parameters.SecurityDocumentId,
    component: SecurityDocumentSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.EventId]: {
    name: Parameters.EventId,
    component: EventCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ContestEventId]: {
    name: Parameters.ContestEventId,
    component: ContestCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ConferenceEventId]: {
    name: Parameters.ConferenceEventId,
    component: ConferenceCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ExhibitionEventId]: {
    name: Parameters.ExhibitionEventId,
    component: ExhibitionCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ProjectID]: {
    name: Parameters.ProjectID,
    component: FullScienceProjectSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.PartnerID]: {
    name: Parameters.PartnerID,
    component: PartnerSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.NirRequestId]: {
    name: Parameters.NirRequestId,
    component: NirRequestSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.RequestId]: {
    name: Parameters.RequestId,
    component: RequestSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.tender]: {
    name: Parameters.tender,
    component: TenderSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.request]: {
    name: Parameters.request,
    component: RequestSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ContestRequestId]: {
    name: Parameters.ContestRequestId,
    component: ContestRequestSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ProjectCode]: {
    name: Parameters.ProjectCode,
    component: ProjectCodeSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.mipId]: {
    name: Parameters.mipId,
    component: MipSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.MobileRequestId]: {
    name: Parameters.MobileRequestId,
    component: MobileRequestSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.program]: {
    name: Parameters.program,
    component: ProgramSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.MobileProjectId]: {
    name: Parameters.MobileProjectId,
    component: MobileProjectSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.TenderProtocolId]: {
    name: Parameters.TenderProtocolId,
    component: TenderProtocolSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.DepartmentsList]: {
    name: Parameters.DepartmentsList,
    component: DepartmentList,
    validator: validateReference,
    queryBuilder: queryBuilderReference,
  },
  [Parameters.ProjectCodeLetter]: {
    name: Parameters.ProjectCodeLetter,
    component: ProjectCodeLetter,
    validator: validateReference,
    queryBuilder: queryBuilderReference,
  },
  [Parameters.StartYear]: {
    name: Parameters.StartYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.EndYear]: {
    name: Parameters.EndYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.Year]: {
    name: Parameters.Year,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.year]: {
    name: Parameters.year,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.reportYear]: {
    name: Parameters.reportYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.ReportYear]: {
    name: Parameters.ReportYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.LowYear]: {
    name: Parameters.LowYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.HightYear]: {
    name: Parameters.HightYear,
    component: Year,
    validator: validateYear,
    queryBuilder: queryBuilderYear,
  },
  [Parameters.YoungScientists]: {
    name: Parameters.YoungScientists,
    component: YoungScientists,
    validator: validateCheckboxList,
    queryBuilder: queryBuilderCheckboxList,
  },
  [Parameters.IsAccepted]: {
    name: Parameters.IsAccepted,
    component: IsAccepted,
    validator: validateCheckboxList,
    queryBuilder: queryBuilderCheckboxList,
  },
  [Parameters.IncludeTransitoryBalance]: {
    name: Parameters.IncludeTransitoryBalance,
    component: IncludeTransitoryBalance,
    validator: validateCheckboxList,
    queryBuilder: queryBuilderCheckboxList,
  },
  [Parameters.ProjectTypes]: {
    name: Parameters.ProjectTypes,
    component: ProjectTypes,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.NotRequiredDocumentTypes]: {
    name: Parameters.NotRequiredDocumentTypes,
    component: DocumentTypes,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.PublicationRecordStatus]: {
    name: Parameters.PublicationRecordStatus,
    component: Enumeration,
    validator: validateEnumeration,
    queryBuilder: queryBuilderEnumeration,
  },
  [Parameters.ReportRecordStatus]: {
    name: Parameters.ReportRecordStatus,
    component: Enumeration,
    validator: validateEnumeration,
    queryBuilder: queryBuilderEnumeration,
  },
  [Parameters.EventRecordStatus]: {
    name: Parameters.EventRecordStatus,
    component: Enumeration,
    validator: validateEnumeration,
    queryBuilder: queryBuilderEnumeration,
  },
  [Parameters.PatentRecordStatus]: {
    name: Parameters.PatentRecordStatus,
    component: Enumeration,
    validator: validateEnumeration,
    queryBuilder: queryBuilderEnumeration,
  },
  [Parameters.AuthorRequestStatus]: {
    name: Parameters.AuthorRequestStatus,
    component: AuthorRequestStatus,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.EmployeeRequestStatus]: {
    name: Parameters.EmployeeRequestStatus,
    component: AuthorRequestStatus,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.EventLevelStatus]: {
    name: Parameters.EventLevelStatus,
    component: EventLevelStatus,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.FinTypeList]: {
    name: Parameters.FinTypeList,
    component: FinTypeList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredDepartmentWithMode]: {
    name: Parameters.NotRequiredDepartmentWithMode,
    component: Department,
    validator: validateExtendedParameterList,
    queryBuilder: queryBuilderExtendedParameterList,
  },
  [Parameters.NotRequiredUserDepartment]: {
    name: Parameters.NotRequiredUserDepartment,
    component: UserDepartment,
    validator: validateParameterList,
    queryBuilder: userDepartmentsQueryBuilder,
  },
  [Parameters.NotRequeredProgramList]: {
    name: Parameters.NotRequeredProgramList,
    component: ProgramList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.ProgramsList]: {
    name: Parameters.ProgramsList,
    component: ProgramsList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.GrantTypeList]: {
    name: Parameters.GrantTypeList,
    component: GrantTypeList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequeredProgramEventList]: {
    name: Parameters.NotRequeredProgramEventList,
    component: EventList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredExpositionType]: {
    name: Parameters.NotRequiredExpositionType,
    component: ExhibitTypeParameterList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredRequestStatus]: {
    name: Parameters.NotRequiredRequestStatus,
    component: RequestStatus,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.NotRequiredNirRequestStatus]: {
    name: Parameters.NotRequiredNirRequestStatus,
    component: NirRequestStatus,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.NotRequiredAuthorRequestStatus]: {
    name: Parameters.NotRequiredAuthorRequestStatus,
    component: AuthorRequestStatus,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.NotRequiredRequestType]: {
    name: Parameters.NotRequiredRequestType,
    component: RequestTypes,
    validator: validateManyCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.NotRequiredDepartment]: {
    name: Parameters.NotRequiredDepartment,
    component: DepartmentTypeParameterList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredStages]: {
    name: Parameters.NotRequiredStages,
    component: NotRequiredStages,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredPublicationType]: {
    name: Parameters.NotRequiredPublicationType,
    component: PublicationType,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.NotRequiredRegulationParagraph]: {
    name: Parameters.NotRequiredRegulationParagraph,
    component: RegulationParagraph,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.RequestStatusList]: {
    name: Parameters.RequestStatusList,
    component: RequestStatusParameterList,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.PersonStatus]: {
    name: Parameters.PersonStatus,
    component: PersonStatus,
    validator: validateRadioList,
    queryBuilder: queryBuilderRadioList,
  },
  [Parameters.ByDissertationCouncilReportType]: {
    name: Parameters.ByDissertationCouncilReportType,
    component: DissertationCouncilReportType,
    validator: validateRadioList,
    queryBuilder: queryBuilderRadioList,
  },
  [Parameters.SingleFaculty]: {
    name: Parameters.SingleFaculty,
    component: DepartmentFaculty,
    validator: validateReference,
    queryBuilder: queryBuilderReference,
  },
  [Parameters.ProjectActId]: {
    name: Parameters.ProjectActId,
    component: RegulationParagraph,
    validator: validateParameterList,
    queryBuilder: queryBuilderParameterList,
  },
  [Parameters.MobileProtocolId]: {
    name: Parameters.MobileProtocolId,
    component: MobileProtocolCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ApproverStamp]: {
    name: Parameters.ApproverStamp,
    component: Approve,
    validator: validateCheckboxList,
    queryBuilder: queryBuilderCheckboxList,
  },
  [Parameters.Visa]: {
    name: Parameters.Visa,
    component: Approve,
    validator: validateCheckboxList,
    queryBuilder: queryBuilderManyCheckboxList,
  },
  [Parameters.PurchaseRequestId]: {
    name: Parameters.PurchaseRequestId,
    component: PurchaseRequestCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
  [Parameters.ProjectPerformerOrderId]: {
    name: Parameters.ProjectPerformerOrderId,
    component: ProjectPerformerOrderCustomSelect,
    validator: validateCustomSelect,
    queryBuilder: queryBuilderCustomSelect,
  },
};

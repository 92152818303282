import { format } from 'date-fns';

import { Document } from 'types/models';
import { EMPTY_FILE, formatStr } from 'utils/Constants';
import { FieldsType } from '.';

export function getMockDocument({
  createdBy,
  documentType,
  isShared,
  isCreator,
}: Partial<Pick<Document, 'createdBy' | 'documentType' | 'isShared' | 'isCreator'>>): Document {
  return {
    docType: 'local',
    createdBy: createdBy || '',
    createdDate: format(new Date(), formatStr),
    date: '',
    file: EMPTY_FILE,
    id: '',
    isShared: !!isShared,
    isInfo: false,
    name: '',
    type: '',
    isCommonAward: false,
    endDate: '',
    isAutoProlongation: false,
    isUnexpirable: false,
    number: '',
    partnerDocumentType: '',
    responsible: '',
    responsibleJob: '',
    startDate: '',
    responsibleFio: '',
    responsibleJobAppointment: '',
    amount: '',
    documentType: documentType || '',
    projectDocumentType: null,
    detail: '',
    isCreator: !!isCreator,
    note: '',
  };
}

export const documentTypeEnum: Record<FieldsType, string> = {
  projectDocument: 'NONE',
  purchaseRequest: 'PurchaseRequestDocumentType',
  securityDocument: 'SecurityDocumentDocumentType',
  foreignSecurityDocument: 'ForeignSecurityDocumentDocumentType',
  securityDocumentContract: 'SecurityDocumentContractDocumentType',
  details: 'NONE',
};

import { useCallback } from 'react';

import { Department } from 'types/models/ProgramRequest';

type Props = {
  department?: Department | null;
  onDepartmentChange(row: Department): void;
};

export function useController({ department, onDepartmentChange }: Props) {
  const handleFieldChange = useCallback(
    (fieldName: keyof Department) => (value: any) => {
      onDepartmentChange({ ...department, [fieldName]: value } as Department);
    },
    [department, onDepartmentChange],
  );

  return {
    handleFieldChange,
  };
}

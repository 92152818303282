import React from 'react';

import { Content, Tab, Tabs } from 'components';

import { DataGrid } from 'features/Table';
import { GetProjectRestrictionList, GetRestrictionProjectList } from 'features/Table/specifications';
import { useRouteNavigation } from 'utils/Common';

export function ProjectRestrictions() {
  const { url, changeUrl } = useRouteNavigation({
    prefix: ['projects', 'project-restrictions'],
    init: ['with-restrictions'],
  });

  return (
    <Content>
      <Tabs defaultTabId={url[0]} onTabClick={id => changeUrl(0, id)}>
        <Tab title="Ограничения для проектов" id="with-restrictions">
          <DataGrid specification={GetProjectRestrictionList()} />
        </Tab>
        <Tab title="Проекты" id="without-restrictions">
          <DataGrid specification={GetRestrictionProjectList()} />
        </Tab>
      </Tabs>
    </Content>
  );
}

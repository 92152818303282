import React, { memo } from 'react';

import { FormComponent, ReferenceFilter, Select, SelectMode } from 'components';

import { BuilderProps } from 'features/BuildReportPopup/models';
import useController from './controller';

type Props = {
  referenceName: string;
  referenceRelationTableModalTitle: string;
  referenceFilter: ReferenceFilter[];
} & BuilderProps;

const DefaultReference = ({
  parameters,
  setParameterValueByName,
  parameterName,
  referenceName,
  referenceRelationTableModalTitle,
  referenceFilter = [],
}: Props) => {
  const { referenceParameter, canBuild, referenceItem, handleSetReferenceItem } = useController({
    parameters,
    setParameterValueByName,
    parameterName,
  });

  return (
    <>
      {canBuild && (
        <FormComponent.Line>
          <FormComponent.Field label={referenceParameter?.description} isRequired>
            <Select
              mode={SelectMode.REFERENCE}
              value={referenceItem}
              onChange={handleSetReferenceItem}
              settings={{ name: referenceName, title: referenceRelationTableModalTitle, filters: referenceFilter }}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}
    </>
  );
};

const Component = memo(DefaultReference);

export { Component as DefaultReference };

import { parse } from 'date-fns';

import { ContestRequest, Form } from 'types/models';
import { formatStr } from 'utils/Constants';

export function validate(cr: ContestRequest.ContestRequest | null, formFields: Form.Fields) {
  const required = Object.keys(formFields)
    .filter((fieldName: string) => formFields[fieldName].required && formFields[fieldName]?.value?.length <= 0)
    .map((fieldName: string) => ({
      isValid: false,
      invalidMessage: `Заполните поле "${formFields[fieldName].title}"`,
    }));

  const dateValidations = [];
  const now = new Date();
  const startDate = formFields.requestStageStartDate?.value
    ? parse(formFields.requestStageStartDate?.value, formatStr, now)
    : null;
  const endDate = formFields.requestStageEndDate?.value ? parse(formFields.requestStageEndDate?.value, formatStr, now) : null;

  if (!startDate && !endDate) {
    dateValidations.push({
      isValid: false,
      invalidMessage: 'Заполните сроки этапа проекта',
    });
  } else {
    if (!startDate) {
      dateValidations.push({
        isValid: false,
        invalidMessage: 'Заполните дату начала этапа проекта',
      });
    }
    if (!endDate) {
      dateValidations.push({
        isValid: false,
        invalidMessage: 'Заполните дату окончания этапа проекта',
      });
    }
    if (startDate && endDate && startDate.getTime() > endDate.getTime()) {
      dateValidations.push({
        isValid: false,
        invalidMessage: 'Дата начала этапа должна быть позднее даты окончания',
      });
    }
  }

  return [...required, ...dateValidations];
}

import React from 'react';

import {
  Button,
  ButtonMode,
  FormComponent,
  ListEdit,
  Modal,
  RelationTableModal,
  TextArea,
  TextAreaMode,
  TextInput,
} from 'components';

import { useController } from './controller';
import { Notification, Table } from 'types/models';
import { NotificationDispatchMode } from 'utils/Enums';
import {
  GetNotificationRecipientPresetListSpecification,
  GetReferenceScientistListSpecification,
  GetTenderList,
} from 'features/Table/specifications';

import './style.scss';

export type Mode = 'person' | 'lk' | 'history';

export type Props = {
  tender?: Notification.Tender | null;
  onClose(): void;
};

export const NotificationRecipientPreset = React.memo((props: Props) => {
  const {
    clearNamePreset,
    preset,
    setName,
    setText,
    changeTenderObject,
    tenderModalTableRowConverter,
    onSubmit,
    isOpenPersonListModal,
    setIsOpenPersonListModal,
    onSubmitScientist,
    isOpenRecipientListModal,
    setIsOpenRecipientListModal,
    selectedRecipient,
    setSelectedRecipient,
    setMailRecipient,
    isOpenRecipientMailModal,
    setIsOpenRecipientMailModal,
    onSubmitScientists,
    isNotificationSaveModalOpen,
    setIsNotificationSaveModalOpen,
    isNotificationNameModalOpen,
    setIsNotificationNameModalOpen,
    isOpenPresetListModal,
    setIsOpenPresetListModal,
    onSubmitPreset,
    onChange,
    onClear,
    onSavePreset,
  } = useController(props);

  return (
    <>
      <FormComponent.Wrapper>
        <FormComponent.Line>
          <FormComponent.Field label="Конкурс" isRequired>
            <TextArea
              mode={TextAreaMode.TABLE}
              settings={{
                title: 'Конкурс',
                isClearable: false,
                table: {
                  specification: GetTenderList(),
                  onSelect: (row: Table.Entry | null) => changeTenderObject(row ? tenderModalTableRowConverter(row) : null),
                },
              }}
              value={preset?.tender?.name}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field label="Дополнительная информация">
            <TextArea
              mode={TextAreaMode.WYSIWYG}
              settings={{ title: 'Текст приказа', rows: 10 }}
              value={preset?.text || ''}
              onChange={setText}
              classMixin="notification_recipient_preset"
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Line>
          <FormComponent.Field>
            <ListEdit
              header={{ title: 'Список адресатов', isRequired: true }}
              rows={preset?.recipients || []}
              onChange={onChange}
              toolbar={[
                {
                  icon: { type: 'user', mode: 'add' },
                  title: 'Добавить персону',
                  onClick: () => setIsOpenPersonListModal(true),
                },
                {
                  icon: { type: 'group', mode: 'add' },
                  title: 'Добавить всех персон из списка рассылки',
                  onClick: () => setIsOpenRecipientListModal(true),
                },
                {
                  icon: { type: 'mail', mode: 'add' },
                  title: 'Добавить эл. почту в контакты персоны',
                  onClick: row => {
                    setSelectedRecipient(row);
                    setIsOpenRecipientMailModal(true);
                  },
                  isDisabled: row => !row || !!row.email,
                },
                'delete',
                {
                  icon: { type: 'erase' },
                  title: 'Очистить список',
                  onClick: onClear,
                  isDisabled: !(preset?.recipients || []).length,
                },
                {
                  icon: { type: 'save' },
                  title: 'Сохранить список рассылки',
                  onClick: () => {
                    clearNamePreset();
                    setIsNotificationSaveModalOpen(true);
                  },
                  isDisabled: !(preset?.recipients || []).length,
                },
              ]}
              columns={[
                { label: '№', formatValue: (row, i) => i + 1, styles: { width: '10%' } },
                { label: 'ФИО', formatValue: row => row.fio, styles: { width: '45%' } },
                { label: 'Email', formatValue: row => row.email, styles: { width: '45%' } },
              ]}
              withMessages
              isDeleteConfirmEnabled
              defaultRowsCount={3}
              specification={{
                mode: 'customComponent',
                renderComponent: () => <></>,
              }}
            />
          </FormComponent.Field>
        </FormComponent.Line>

        <FormComponent.Actions>
          <Button
            mode={ButtonMode.PRIMARY}
            text="Отправить тем, кто не получал"
            onClick={() => onSubmit(NotificationDispatchMode.DISTINCT)}
            isDisabled={!preset?.recipients.length || !preset?.tender?.id}
          />
          <Button
            mode={ButtonMode.PRIMARY}
            text="Отправить всем"
            onClick={() => onSubmit(NotificationDispatchMode.ALL)}
            isDisabled={!preset?.recipients.length || !preset?.tender?.id}
          />
          <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={props.onClose} />
        </FormComponent.Actions>
      </FormComponent.Wrapper>

      <RelationTableModal
        specification={{ ...GetReferenceScientistListSpecification({ hasSelectButton: true }), onSubmitTable: onSubmitScientist }}
        modalTitle='Справочник "Персоны"'
        isOpen={isOpenPersonListModal}
        onClose={() => setIsOpenPersonListModal(false)}
      />

      <RelationTableModal
        specification={{
          ...GetNotificationRecipientPresetListSpecification({ hasSelectButton: true }),
          onSubmitTable: onSubmitScientists,
        }}
        modalTitle="Рассылки"
        isOpen={isOpenRecipientListModal}
        onClose={() => setIsOpenRecipientListModal(false)}
      />

      <Modal
        mode="warning"
        title="Сохранение списка для рассылки"
        isOpen={isNotificationSaveModalOpen}
        onClose={() => setIsNotificationSaveModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Создать новый',
            onClick: () => {
              setIsNotificationSaveModalOpen(false);
              setIsNotificationNameModalOpen(true);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Обновить список',
            onClick: () => {
              setIsNotificationSaveModalOpen(false);
              setIsOpenPresetListModal(true);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsNotificationSaveModalOpen(false),
          },
        ]}
        size="small"
      >
        <>Сохранить список адресатов?</>
      </Modal>

      <Modal
        title="Название рассылки"
        isOpen={isNotificationNameModalOpen}
        onClose={() => setIsNotificationNameModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить',
            onClick: () => {
              setIsNotificationNameModalOpen(false);
              onSavePreset({ name: preset?.name || '' });
            },
            isDisabled: !preset?.name,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsNotificationNameModalOpen(false),
          },
        ]}
        size="small"
      >
        <FormComponent.Line>
          <FormComponent.Field label="Название" labelSize="fit">
            <TextInput value={preset?.name} onChange={setName} />
          </FormComponent.Field>
        </FormComponent.Line>
      </Modal>

      <Modal
        title="Добавить эл. почту в контакты персоны"
        isOpen={isOpenRecipientMailModal}
        onClose={() => setIsOpenRecipientMailModal(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Сохранить',
            onClick: () => {
              setIsOpenRecipientMailModal(false);
              setMailRecipient();
            },
            isDisabled: !selectedRecipient?.email,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsOpenRecipientMailModal(false),
          },
        ]}
        size="medium"
      >
        <>
          <FormComponent.Line>
            <FormComponent.Field label="ФИО">
              <FormComponent.Text>
                <em>{selectedRecipient?.fio}</em>
              </FormComponent.Text>
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Эл. почта">
              <TextInput
                value={selectedRecipient?.email}
                onChange={email => setSelectedRecipient({ ...selectedRecipient, email } as Notification.RecipientItem)}
              />
            </FormComponent.Field>
          </FormComponent.Line>
        </>
      </Modal>

      <RelationTableModal
        specification={{
          ...GetNotificationRecipientPresetListSpecification({ hasSelectButton: true }),
          onSubmitTable: onSubmitPreset,
        }}
        modalTitle="Рассылки"
        isOpen={isOpenPresetListModal}
        onClose={() => setIsOpenPresetListModal(false)}
      />
    </>
  );
});

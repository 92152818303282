import React from 'react';

import {
  FormComponent,
  SectionTitle,
  ReferenceItem,
  Select,
  TextArea,
  TextAreaMode,
  TextDateTime,
  TextInput,
  TextInputMode,
  UploadFile,
} from 'components';

import { Arm, Form, Table } from 'types/models';
import { Item } from 'types/models/common';
import { LibraryField } from 'features/Form/views';
import { ElectronicType } from 'utils/Enums/ElectronicType';
import { getTitle } from 'features/Form/looks/publication/helpers';
import { Permits } from 'utils/Permissions';
import { isHasPermission } from 'features/AppData';
import { useAppDataContext } from 'features/AppData/context';

type Props = {
  formFields: Form.Fields;
  workMode: Table.WorkMode;
  isElectronic: boolean;
  electronicType: string;
  publicationInfo: Form.Publication | null;
  isShowDetailedTitle: boolean;
  userDepartments: ReferenceItem[];
  publicationId?: string;
  publicationDepartment: ReferenceItem | null;
  setPublicationDepartment: React.Dispatch<ReferenceItem | null>;
  arm?: Arm;
};

function About({
  formFields,
  workMode,
  isElectronic,
  electronicType,
  publicationInfo,
  isShowDetailedTitle,
  userDepartments,
  publicationId,
  publicationDepartment,
  setPublicationDepartment,
  arm,
}: Props) {
  const { userPermission } = useAppDataContext();

  return (
    <>
      <SectionTitle title={getTitle(publicationInfo, isShowDetailedTitle)} />

      <FormComponent.Line>
        <FormComponent.Field label={formFields.name.title} isRequired>
          <TextArea
            settings={{ rows: 3, maxLength: 2049 }}
            value={formFields.name.value}
            onChange={formFields.name.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.annotation.title} tooltip={formFields.annotation.tooltipText} isRequired>
          <TextArea
            mode={TextAreaMode.MODAL}
            settings={{ rows: 3 }}
            value={formFields.annotation.value}
            onChange={formFields.annotation.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.authorsKeyWords.title} tooltip={formFields.authorsKeyWords.tooltipText}>
          <TextInput
            value={formFields.authorsKeyWords.value}
            onChange={formFields.authorsKeyWords.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label={formFields.publicationDate.title}>
          <TextDateTime
            value={formFields.publicationDate.value}
            onChange={formFields.publicationDate.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {isElectronic && electronicType === ElectronicType.LOCAL.code && (
        <FormComponent.Line>
          <FormComponent.Field
            label={formFields.electronicMediaSource.title}
            tooltip={formFields.electronicMediaSource.tooltipText}
          >
            <TextInput
              value={formFields.electronicMediaSource.value}
              onChange={formFields.electronicMediaSource.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
          <FormComponent.Field
            label={formFields.electronicSystemRequirements.title}
            tooltip={formFields.electronicSystemRequirements.tooltipText}
          >
            <TextInput
              value={formFields.electronicSystemRequirements.value}
              onChange={formFields.electronicSystemRequirements.onChange}
              isDisabled={workMode === 'viewMode'}
            />
          </FormComponent.Field>
        </FormComponent.Line>
      )}

      <SectionTitle title="Скан-копия выходных данных и идентификаторы публикации" />

      <FormComponent.Line>
        <FormComponent.Field
          label="Прикрепите файл"
          // eslint-disable-next-line max-len
          tooltip="Файл с выходными данными обязательно должен содержать титул, оборот титула, содержание и последний лист с информацией о типе издания, тираже, издательстве"
          isRequired
        >
          <UploadFile file={formFields.file.value} onChange={formFields.file.onChange} isDisabled={workMode === 'viewMode'} />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.doi.title} tooltip={formFields.doi.tooltipText}>
          <TextInput
            mode={TextInputMode.DOI}
            value={formFields.doi.value}
            onChange={formFields.doi.onChange}
            isDisabled={workMode === 'viewMode' || !isHasPermission(userPermission, Permits.PUBLICATION_DOI_EDIT)}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field
          label={formFields.url.title}
          isRequired={formFields.url.required}
          tooltip={formFields.url.tooltipText}
        >
          <TextInput
            mode={TextInputMode.URL}
            value={formFields.url.value}
            onChange={formFields.url.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
        <FormComponent.Field label={formFields.urlDate.title} isRequired={!!formFields.urlDate.required}>
          <TextDateTime
            value={formFields.urlDate.value}
            onChange={formFields.urlDate.onChange}
            isDisabled={workMode === 'viewMode'}
            style={{ maxWidth: '100%' }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <LibraryField libraryUrl={publicationInfo?.status?.libraryUrl || ''} />
        <FormComponent.Field label={formFields.libraryLink.title}>
          <TextInput
            mode={TextInputMode.URL}
            value={formFields.libraryLink.value}
            onChange={formFields.libraryLink.onChange}
            isDisabled={workMode === 'viewMode'}
          />
        </FormComponent.Field>
      </FormComponent.Line>

      {!publicationId && arm === 'pc' && (
        <>
          <SectionTitle title="Ответственное подразделение" />

          <FormComponent.Field
            label="Подразделение"
            tooltip="Публикация будет передана ответственному по подразделению для согласования и утверждения"
          >
            <Select
              value={{ value: publicationDepartment?.id || '', label: publicationDepartment?.label || '' }}
              options={userDepartments.map(x => ({ value: x.id, label: x.label }))}
              onChange={(option: Item) => setPublicationDepartment(userDepartments.find(x => x.id === option?.value) || null)}
            />
          </FormComponent.Field>
        </>
      )}
    </>
  );
}

export const Component = React.memo(About);

import React, { memo } from 'react';

import { Button, ButtonMode, FormComponent, ListEditTable } from 'components';

import { Department } from 'types/models/Participation';
import { EventTypes } from 'utils/Enums/EventTypes';
import { ParticipationTypes } from 'utils/Enums/ParticipationTypes';
import { RecordStatus } from 'utils/Enums';
import { formatAuthor } from '../MembersList/helpers';
import useController from './controller';
import { getStatusRecordMessage } from 'utils/Helpers/getStatusMessage';
import { Item } from 'types/models/common';
import { getEventFullName } from 'utils/Helpers/event';

type Props = {
  id: string;
  type: string;
  onClose: () => void;
  afterSubmit?: ({ nextRecordStatus }: { nextRecordStatus: Item }) => void;
};

const ApproveParticipationComponent = ({ id, type, onClose, afterSubmit }: Props) => {
  const { handleApproveParticipation, participationData, departmentColumns } = useController({
    onClose,
    id,
    afterSubmit,
  });

  const eventTypeCodeLabel: string = (() => {
    if (type === ParticipationTypes.PARTICIPATION_CONFERENCE.code) {
      return EventTypes.EVENT_CONFERENCE.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_EXPOSITION.code) {
      return EventTypes.EVENT_EXPOSITION.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_CONTEST.code) {
      return EventTypes.EVENT_CONTEST.label;
    }
    if (type === ParticipationTypes.PARTICIPATION_CONCERT.code) {
      return EventTypes.EVENT_CONCERT.label;
    }
    return '';
  })();

  return (
    <FormComponent.Wrapper>
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Мероприятие:</strong> {eventTypeCodeLabel}:{' '}
          {getStatusRecordMessage(
            getEventFullName(participationData?.event),
            participationData?.event?.id,
            participationData?.event?.status?.label,
          )}
        </FormComponent.Text>
      </FormComponent.Field>
      {type === ParticipationTypes.PARTICIPATION_CONFERENCE.code &&
        (participationData?.reportTheme || participationData?.publication?.fields.name) && (
          <FormComponent.Field>
            <FormComponent.Text>
              <strong>Доклад:</strong> {participationData?.reportTheme || participationData?.publication?.fields.name || '-'}
            </FormComponent.Text>
          </FormComponent.Field>
        )}
      <FormComponent.Field>
        <FormComponent.Text>
          <strong>Участники:</strong>{' '}
          {participationData?.members.map((member, index) => (
            <div key={index} dangerouslySetInnerHTML={{ __html: formatAuthor(member) }} />
          ))}
        </FormComponent.Text>
      </FormComponent.Field>
      <ListEditTable
        rows={(participationData?.departments || []) as Department[]}
        columns={departmentColumns}
        selectedRowIndex={null}
        selectRow={() => {}}
      />
      <FormComponent.Actions>
        <Button
          mode={ButtonMode.PRIMARY}
          text={participationData?.status.value === RecordStatus.APPROVED ? 'Перевести в статус Добавлена' : 'Утвердить'}
          onClick={handleApproveParticipation}
        />
        <Button mode={ButtonMode.SECONDARY} text="Отмена" onClick={onClose} />
      </FormComponent.Actions>
    </FormComponent.Wrapper>
  );
};

export const Component = memo(ApproveParticipationComponent);

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { useController } from './controller';

type Props = {
  tableState: Table.State;
};

function Toolbar(props: Props) {
  const { tableState } = props;
  const { isViewFormOpen, handleTemplateCloseViewForm, buttons } = useController(tableState);

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {!!tableState.selectedRows.length && (
        <modalTemplates.PublicationViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={handleTemplateCloseViewForm}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
        />
      )}
    </>
  );
}

export { Toolbar };

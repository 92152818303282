import React from 'react';
import { differenceInYears, parse } from 'date-fns';

import { FormComponent, ListEdit } from 'components';

import { formatStr } from 'utils/Constants';
import { Project } from 'types/models';
import { formatEducation, formatJob } from 'features/SelectPerson/helpers';
import { Member, PersonHistoryModal } from 'features/SelectPerson';
import { ScientistJob, ScientistPerson } from 'types/models/Person';
import { getMockJobPeriod } from 'features/Form/looks/project/ProjectForm/helpers';
import { Fields } from './Fields';
import { validate } from './validate';
import { useController } from './controller';
import { Mode } from 'features/Table/specifications/GetProjectScientistList/LeftPanelForThirdLevel';
import { JobStatus } from 'utils/Enums';
import { showNotification } from 'features/Notifications';

type Props = {
  mode: Mode;
  projectId: string | null;
  person: ScientistPerson | null;
  history: Partial<Member>;
  jobPeriods: Project.JobPeriod[];
  setJobPeriods(jobPeriods: Project.JobPeriod[]): void;
  startProjectDate?: string;
  endProjectDate?: string;
  disabled: boolean;
};

export function JobPeriods(props: Props) {
  const { mode, projectId, person, history, jobPeriods, setJobPeriods, startProjectDate, endProjectDate, disabled } = props;

  const {
    personHistoryMode,
    selectedJobPeriodIndex,
    isOpenHistoryPersonModal,
    onJobPeriodSubmit,
    onClickHistoryIcon,
    closePersonHistoryMode,
    submitHistoryPersonModal,
    addJobPeriod,
    setAddJobPeriod,
  } = useController({ modeSpec: mode, person, history, jobPeriods, setJobPeriods, startProjectDate, endProjectDate });

  const getContractDetails = (job: ScientistJob) => {
    if (job?.status?.value === JobStatus.CIVIL_CONTRACT) {
      return `Договор ГПХ № ${job.civilContractNumber || '<em>Не указан</em>'} от ${
        job.civilContractDate || '<em>Не указана</em>'
      } (${job.dateReceipt || '<em>Не указана</em>'}- ${job.dateDismissal || '<em>Не указана</em>'})`;
    }
    return '';
  };

  return (
    <>
      {mode === 'add' ? (
        <Fields jobPeriod={addJobPeriod} setJobPeriod={setAddJobPeriod} projectId={projectId} />
      ) : (
        <>
          <ListEdit
            rows={jobPeriods}
            onChange={setJobPeriods}
            toolbar={[
              'add',
              'edit',
              'delete',
              {
                icon: { type: 'clock' },
                title: 'Персональные данные',
                isDisabled: row => row === null || disabled,
                onClick: onClickHistoryIcon,
              },
            ]}
            columns={[
              { label: 'Роль', formatValue: x => x.role?.label ?? '', styles: { width: '10%' } },
              {
                label: 'Включение в состав',
                formatValue: x => `${x.startDate} (Приказ № ${x.startDateOrder?.number || '<em>Не указан</em>'})`,
                styles: { width: '15%' },
              },
              {
                label: 'Исключение из состава',
                formatValue: x => `${x.endDate} (Приказ № ${x.endDateOrder?.number || '<em>Не указан</em>'})`,
                styles: { width: '15%' },
              },
              {
                label: 'Работа, обучение',
                formatValue: x =>
                  [
                    x.job ? `${formatJob(x.job)} ${getContractDetails(x.job)}` : '',
                    x.education ? formatEducation(x.education) : '',
                  ]
                    .filter(Boolean)
                    .join('<br />'),
                styles: { width: '30%' },
              },
              {
                label: 'Возраст на конец периода',
                formatValue: x =>
                  differenceInYears(
                    parse(x.endDate, formatStr, new Date()),
                    parse(person?.scientist?.dateBirth || '', formatStr, new Date()),
                  ),
                styles: { width: '10%' },
              },
              {
                label: 'Степень, звание, гражданство',
                formatValue: x =>
                  [
                    x.degree?.refDegree?.label || '',
                    x.rank?.refRank?.label || '',
                    x.academicRank?.refAcademicRank?.label || '',
                    x.citizenship?.label || '',
                  ]
                    .filter(Boolean)
                    .join('<br />'),
                styles: { width: '10%' },
              },
              { label: 'Примечание', formatValue: x => x.note, styles: { width: '10%' } },
            ]}
            isDeleteConfirmEnabled
            isDisabled={disabled}
            defaultRowsCount={10}
            maxHeight="none"
            specification={{
              mode: 'customComponent',
              onPreSubmit: onJobPeriodSubmit,
              renderComponent: (jobPeriod, setJobPeriod) => (
                <FormComponent.Wrapper>
                  <Fields
                    jobPeriod={
                      jobPeriod || {
                        ...getMockJobPeriod(),
                        endDate: endProjectDate ?? '',
                        startDate: startProjectDate ?? '',
                      }
                    }
                    setJobPeriod={setJobPeriod}
                    projectId={projectId}
                  />
                </FormComponent.Wrapper>
              ),
              validation: {
                checkIsValid: jobPeriod => validate(jobPeriod, person).every(x => x.isValid),
                onInvalidate: val =>
                  validate(val, person).forEach(({ isValid, invalidMessage }) => {
                    if (!isValid) {
                      setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                    }
                  }),
              },
            }}
          />

          <PersonHistoryModal
            isOpen={isOpenHistoryPersonModal}
            member={selectedJobPeriodIndex !== null ? jobPeriods[selectedJobPeriodIndex] : null}
            onClose={closePersonHistoryMode}
            onSubmit={submitHistoryPersonModal}
            isLoadPersonAfterOpen
            mode={personHistoryMode}
          />
        </>
      )}
    </>
  );
}

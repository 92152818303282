import { Person } from 'types/models';
import { ScientistEducation, ScientistJob } from 'types/models/Person';

export function convertDegree(item: Person.ScientistPerson | null) {
  if (item && item.degrees?.length > 0) {
    return ` ${item.degrees.map(degree => degree.refDegree?.label).join(', ')}`;
  }
  return '';
}

export function convertRank(item: Person.ScientistPerson | null) {
  if (item && item.ranks?.length > 0) {
    return ` ${item.ranks.map(degree => degree.refRank.label).join(', ')}`;
  }
  return '';
}
export function convertAcademicRank(item: Person.ScientistPerson | null) {
  if (item && item.academicRanks?.length > 0) {
    return ` ${item.academicRanks.map(degree => degree.refAcademicRank.label).join(', ')}`;
  }
  return '';
}

export function formatJob(job: ScientistJob) {
  const otherOrganizationData = job.enterpise && job.status?.value === 'OTHER' ? `, ${job.enterpise}` : '';
  return `${job.refAppointment?.label || job.appointment || job.status?.label} ${otherOrganizationData} ${
    job.refDepartment?.label || job.department
      ? `, ${job.refDepartment?.label || job.department}${job.employeeNumber ? `, таб.номер: ${job.employeeNumber}` : ''}`
      : ''
  }`;
}

export function formatEducation(education: ScientistEducation) {
  const { status, enterprise, refSpeciality, speciality, refDepartment, department } = education;
  return `${status.label}, ${enterprise}, ${refSpeciality?.label ?? speciality}, ${refDepartment?.label ?? department}`;
}

import React, { memo } from 'react';
import { Modal } from 'components';
import { Component as EditDoiPopupComponent } from './component';

type Props = {
  isOpen: boolean;
  onClose: () => void;
  onSuccess: () => void;
  publicationId: string;
};

const EditDoiPopupContainer = ({ isOpen, onClose, onSuccess, publicationId }: Props) => (
  <Modal isOpen={isOpen} onClose={onClose} title="Указать DOI" size="small">
    <EditDoiPopupComponent onClose={onClose} onSuccess={onSuccess} publicationId={publicationId} />
  </Modal>
);

export const Container = memo(EditDoiPopupContainer);

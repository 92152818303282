import React, { memo } from 'react';

import { FormComponent, ListEdit, SectionTitle, TextInput, TextInputMode } from 'components';

import { Event, Form as F } from 'types/models';
import { MemberCount } from 'types/models/Event';
import { useAppDataContext } from 'features/AppData/context';
import { MembersList } from 'features/Form/looks/event/views/MembersList';
import { MemberCountComponent } from 'features/Form/looks/event/views/MemberCount';
import { formatAmountByParam, formatMember } from './helpers';

type Props = {
  viewMode?: boolean;
  changeCommitteMembers: (committeMembers: Event.Member[]) => void;
  committeMembers: Event.Member[];
  formFields: F.Fields;
  memberCounts: Event.MemberCount[];
  changeMemberCounts: (memberCounts: Event.MemberCount[]) => void;
  calculateFactMemberCount: () => void;
};

const Organization = ({
  viewMode,
  changeCommitteMembers,
  committeMembers,
  formFields,
  memberCounts,
  changeMemberCounts,
  calculateFactMemberCount,
}: Props) => {
  const { settings } = useAppDataContext();

  const calculateButton = {
    icon: { type: 'calc' },
    title: 'Рассчитать',
    isDisabled: viewMode,
    onClick: calculateFactMemberCount,
  };

  const handleMemberAmountChange = (value: Event.MemberCount[]) => {
    changeMemberCounts(value);
  };

  const isValidFields = (member: MemberCount | null, mode: string | null) => {
    if (member && mode === 'edit') return true;
    return !!member?.label;
  };

  return (
    <FormComponent.ColumnWrapper fitContent>
      <FormComponent.Column>
        <SectionTitle
          title="Участники оргкомитета"
          // eslint-disable-next-line max-len
          tooltip="Заполнить только для мероприятий своего Университета. ФИО участников оргкомитета должны быть добавлены с привязкой к роли участника: председатель, сопредседатель, секретарь, и пр."
        />

        <MembersList disabled={viewMode} authors={committeMembers} setAuthors={changeCommitteMembers} />
      </FormComponent.Column>

      <FormComponent.Column>
        <FormComponent.Description mode="warning">
          Раздел рекомендовано заполнить после утверждения мероприятия. Обязательно заполнить в случае, если{' '}
          {settings?.organization?.shortName} был организатором (соорганизатором) мероприятия
        </FormComponent.Description>

        <SectionTitle
          title="Показатели результативности мероприятия"
          // eslint-disable-next-line max-len
          tooltip={`Обязательно заполнить - данные нужны для отчетов в Министерство Образования. До проведения мероприятия необходимо указать планируемое количество участников: всего; из ${settings?.organization?.shortName}; приглашенные – местные; приглашенные – иногородние; приглашенные – зарубежные (указать страны). После проведения мероприятия необходимо заполнить фактическое количество участников по всем категориям с указанием организаций. `}
        />

        <FormComponent.Line hasFreeFormat>
          <FormComponent.Field label="Всего участников мероприятия:" labelSize="fit" />
          <FormComponent.Field
            label={formFields.memberCountPlan.title}
            tooltip={formFields.memberCountPlan.tooltipText}
            labelSize="fit"
          >
            <TextInput
              mode={TextInputMode.NUMBER}
              value={formFields.memberCountPlan.value}
              onChange={formFields.memberCountPlan.onChange}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
          <FormComponent.Field
            label={formFields.memberCountFact.title}
            tooltip={formFields.memberCountFact.tooltipText}
            labelSize="fit"
          >
            <TextInput
              mode={TextInputMode.NUMBER}
              value={formFields.memberCountFact.value}
              onChange={formFields.memberCountFact.onChange}
              externalButtons={[calculateButton]}
              isDisabled={!!viewMode}
            />
          </FormComponent.Field>
        </FormComponent.Line>
        <FormComponent.Line>
          <ListEdit
            header={{ title: 'В том числе по категориям и организациям:' }}
            rows={memberCounts}
            onChange={handleMemberAmountChange}
            toolbar={['add', 'edit', 'delete']}
            columns={[
              { label: 'Откуда', styles: { width: '65%' }, formatValue: row => formatMember(row) },
              { label: 'Всего', styles: { width: '10%' }, formatValue: row => row.amount },
              { label: 'Профессоров', styles: { width: '15%' }, formatValue: row => formatAmountByParam(row, 'professors') },
              { label: 'Студентов', styles: { width: '10%' }, formatValue: row => formatAmountByParam(row, 'students') },
            ]}
            defaultRowsCount={10}
            maxHeight="450px"
            isDisabled={viewMode}
            columnIndexesForSumTotal={[1, 2, 3]}
            columnIndexesForIntegerTotal={[1, 2, 3]}
            specification={{
              mode: 'customModalComponent',
              modalTitle: 'Добавить количество участников по категории:',
              validation: {
                checkIsValid: (row, _, __, mode) => isValidFields(row, mode),
              },
              onPreSubmit: (member, submit) => {
                if (!member.amount) {
                  submit({
                    ...member,
                    amount: '0',
                  });
                } else {
                  submit(member);
                }
              },
              renderComponent: (member, onChange, index, mode) => (
                <MemberCountComponent
                  mode={mode}
                  memberCounts={memberCounts}
                  memberCountsAnother={member?.amounts || []}
                  disabled={viewMode}
                  documentMember={member}
                  onChangeMember={onChange as any}
                />
              ),
            }}
          />
        </FormComponent.Line>
      </FormComponent.Column>
    </FormComponent.ColumnWrapper>
  );
};

export const OrganizationComponent = memo(Organization);

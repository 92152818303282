import { Icon } from 'components';

import { SecurityDocumentType } from 'utils/Enums';

export function SecurityDocumentHelp(type?: SecurityDocumentType): JSX.Element {
  const aboutText = () => {
    if (type === SecurityDocumentType.PATENT) {
      return 'сведения о патенте на изобретение, полезную модель, промышленный образец, селекционное достижение';
    }
    if (type === SecurityDocumentType.EVIDENCE) {
      return 'сведения о свидетельстве на базу данных, программу ПЭВМ, товарный знак, топологию интегральной микросхемы';
    }
    // eslint-disable-next-line max-len
    return 'сведения об объекте авторского права (произведении науки, литературы, искусства, архитектуры, музыки, мультимедийный продукт и другие)';
  };

  return (
    <>
      <h3>Вкладка Описание</h3>

      <p>Форма содержит основные {aboutText()} как объекте интеллектуальной собственности.</p>
      <p>
        В разделе <strong>Авторы</strong> при заполнении сведений об авторах обязательно указать место работы или обучения автора.
        Список сотрудников, студентов и аспирантов университета — в <strong>Справочнике персон</strong>, данные в него загружаются
        из информационных систем кадрового учёта университета. Для добавления не аффилированных с университетом персон оператору
        необходимо иметь соответствующее право.
      </p>
      <p>
        В разделе <strong>Подразделения</strong> система автоматически добавляет подразделения авторов. Оператор имеет возможность
        отредактировать этот список (удалить или добавить подразделение). Для указанных в этом разделе подразделений объект будет
        включён в отчёты о результатах научной деятельности.
      </p>

      {type === SecurityDocumentType.PATENT || type === SecurityDocumentType.EVIDENCE ? (
        <p>
          Статус записи изменяется автоматически в зависимости от ключевых атрибутов, которые заполняются на форме:
          <br />
          <strong>Заявка отправлена</strong> - если заполнено поле Дата отправки заявки и нет Номера заявки;
          <br />
          <strong>Заявка зарегистрирована</strong> - если заполнено поле Номер заявки;
          <br />
          <strong>Заявка Отклонена</strong> - если указан атрибут Заявка отклонена;
          <br />
          <strong>Получен документ</strong> - если заполнено поле Номер документа.
        </p>
      ) : (
        <></>
      )}

      <h3>Вкладка Классификаторы</h3>

      <p>
        Указанные элементы классификаторов используются для управленческой отчётности в разрезе приоритетных направлений развития
        университета; тематическом поиске технологий.
      </p>
      <p>
        Указанные элементы классификаторов используются для{' '}
        {type === SecurityDocumentType.PATENT || type === SecurityDocumentType.EVIDENCE
          ? 'формирования статистической отчётности для МинОбр; государственной регистрации в Роспатенте; '
          : ''}
        управленческой отчётности в разрезе приоритетных направлений развития университета; тематическом поиске технологий.
      </p>

      {type === SecurityDocumentType.PATENT || type === SecurityDocumentType.EVIDENCE ? (
        <div className="warning">
          <span>
            в случае, если указано несколько элементов одного классификатора, то при формировании отчётности используется элемент,
            который указан на <strong>первой позиции</strong>. Кнопки <Icon type="arrowUp" /> <Icon type="arrowDown" />
            для управления позициями элементов в списке.
          </span>
        </div>
      ) : (
        <></>
      )}

      <p>
        При выборе проекта в поле <strong>Получен в рамках научного проекта</strong>, на вкладке{'   '}
        <strong>Классификаторы</strong> в разделы копируются элементы классификаторов указанные в проекте. Оператор имеет
        возможность отметить актуальные/ не актуальные элементы для охранного документа{' '}
        <strong>
          (с помощью <Icon type="like" /> <Icon type="dislike" />)
        </strong>
        , а также добавить элементы из соответствующего справочника с помощью <Icon type="add" />.
      </p>
      <p>При замене проекта другим система автоматически в разделах с классификаторами сделает следующее:</p>

      <ul>
        <li>удалит элементы, добавленные из проекта и без отметки об актуальности для охранного документа;</li>
        <li>
          элементам, добавленным из проекта с отметкой об актуальности для охранного документа, источник проставит = «Охранный
          документ»;
        </li>
        <li>оставит без изменения элементы с источником = «Охранный документ»;</li>
        <li>скопирует элементы классификаторов из нового проекта (источник элемента = «Проект»).</li>
      </ul>

      <h3>Вкладка НМА</h3>

      <p>
        Форма содержит основные сведения о постановке на баланс организации и историю изменения учётной стоимости с учётом
        переоценки (данные бухгалтерского учёта).
      </p>
      <p>
        Если не предусмотрена интеграция с информационной системой бухгалтерского учёта, то при наличии прав пользователя
        {/* eslint-disable-next-line max-len */}
        оператор имеет возможность заполнить раздел Учётная стоимость с учётом переоценки (по данным из бухгалтерии) с помощью{' '}
        <Icon type="add" /> .
      </p>

      {type === SecurityDocumentType.PATENT || type === SecurityDocumentType.EVIDENCE ? (
        <>
          <h3>Вкладка Оплата пошлины</h3>

          <p>
            Функция <strong>«Составить график оплаты пошлин»</strong> (
            <strong>
              кнопка <Icon type="calendar" />
            </strong>
            ) формирует график оплаты пошлин на поддержание охранного документа, используя данные справочника{' '}
            <strong>«Назначение платежа»</strong>. Событие в графике содержит дату, до которой необходимо оплатить пошлину,
            назначение платежа и сумму (в соответствии с данными справочника). Дата вычисляется на основе даты подачи заявки.
            Количество платежей зависит от даты окончания срока действия охранного документа.
          </p>
          <p>
            Оператор имеет возможность отредактировать событие об оплате в графике или добавить новую запись (
            <strong>
              кнопка <Icon type="add" />
            </strong>
            ).
          </p>

          <h4>График оплаты пошлин позволяет:</h4>

          <ul>
            <li>
              Вычислить прямые затраты на поддержание охранного документа за период: итоговая строка таблицы содержит общую сумму
              за весь период действия документа;
            </li>
            <li>
              Кнопка <Icon type="calc" /> «Вычислить сумму выделенных строк» — для расчёта затрат за несколько лет;
            </li>
            <li>Планировать затраты при составлении бюджета на период;</li>
            <li>
              Отмечать факт оплаты пошлины: на форме редактирования записи в графике указать дату оплаты и прикрепить скан-копию
              платёжного поручения.
            </li>
          </ul>
        </>
      ) : (
        <></>
      )}

      <h3>Вкладка Договоры на использование ИС</h3>

      <p>Форма содержит список договоров о распоряжении интеллектуальным правом на объект. Носит информационный характер.</p>
      <p>
        Для учёта лицензионных отчислений по договору в функциональном блоке управления проектами оформляется специальный проект и
        присваивается шифр проекта. Данные бухгалтерского учёта о поступлении средств по договору отображаются на вкладке{' '}
        <strong>Лицензионные отчисления</strong>.
      </p>

      <h3>Вкладка Лицензионные отчисления</h3>

      <p>
        Источник данных для таблицы — сведения из документов бухгалтерского учёта. Для отображения данных в таблице необходимо
        выполнение трёх условий:
      </p>

      <ul>
        <li>
          <strong>Есть информационный обмен</strong> с информационной системой Бухгалтерского учёта университета;
        </li>
        <li>
          В <strong>научном проекте</strong> (модуль <strong>Проекты</strong>) <strong>на вкладке Заявки</strong> указан договор
          на использование интеллектуального права на объекты;
        </li>
        <li>
          В системе бухгалтерского учёта <strong>Документ о приходе средств по договору</strong> на использование
          интеллектуального права на объекты интеллектуальной собственности университета.
        </li>
      </ul>

      <h3>Вкладка Стимулирующие выплаты</h3>

      <p>
        Таблица содержит список заявок на стимулирующие выплаты авторскому коллективу.{'  '}
        <strong>
          Кнопка <Icon type="view" />
        </strong>{' '}
        позволяет посмотреть подробности распределения суммы выплаты между авторами (на вкладке{'  '}
        <strong>«Расчёт»</strong>).
      </p>

      <h3>Вкладка Документы</h3>

      <p>
        Форма служит для хранения архива файлов, связанных с объектом (скан-копий документа, согласий авторов и пр..).
        Обязательные поля для описания прикрепляемого файла:
      </p>

      <ul>
        <li>Тип документа</li>
        <li>Название документа</li>
        <li>Дата документа</li>
      </ul>

      <p>Необязательные поля:</p>

      <ul>
        <li>Сумма документа</li>
        <li>Номер документа</li>
        <li>Примечание</li>
      </ul>

      <p>
        Атрибут «Отображать в списке документов проекта и РИД» обозначает, что данный файл может открыть для просмотра (скачать)
        оператор, работающий с:
      </p>

      <ul>
        <li>проектом, в рамках которого получен объект;</li>
        <li>объектом в целях коммерциализации.</li>
      </ul>
    </>
  );
}

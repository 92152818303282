import { useCallback, useState, useLayoutEffect } from 'react';
import * as BackendAPI from 'services/BackendAPI';

import { Form } from 'types/models';
import { useAppDataContext } from 'features/AppData/context';
import { showNotification } from 'features/Notifications';
import { Participation, ParticipationProject } from 'types/models/Participation';
import { ParticipationProjectType } from 'utils/Enums/ParticipationProjectType';
import { getEnumItem } from 'utils/Helpers';

type Props = {
  participationId: string;
  onClose: () => void;
  onSuccess: () => void;
};

const useController = ({ participationId, onClose, onSuccess }: Props) => {
  const { enumMap } = useAppDataContext();

  const [participation, setParticipation] = useState<null | Participation>(null);
  const [financingProjects, setFinancingProjects] = useState<ParticipationProject[]>([]);
  const [mobileRequests, setMobileRequests] = useState<Form.PublicationMobileRequest[]>([]);

  const { methods: getParticipation } = BackendAPI.useBackendAPI('GetParticipation');
  const { methods: saveParticipation } = BackendAPI.useBackendAPI('SaveParticipation');

  useLayoutEffect(() => {
    getParticipation.callAPI(
      {
        participationId,
        modules: [
          'ID',
          'ACCESS_MODE',
          'MAIN',
          'EVENT',
          'DEPARTMENTS',
          'PUBLICATION',
          'PROJECTS',
          'MEMBERS',
          'AWARDS',
          'MOBILE_REQUESTS',
          'DOCUMENTS',
        ],
        eventType: 'CONFERENCE',
        eventModules: ['MAIN', 'COMPILATIONS', 'MAGAZINE_RELEASES'],
        IsCollectiveExhibit: false,
      },
      {
        onSuccessfullCall: ({ data }) => {
          setParticipation(data);
          setFinancingProjects(data.financingProjects);
          setMobileRequests(data.mobileRequests);
        },
      },
    );
  }, []); // eslint-disable-line

  const submitForm = useCallback(() => {
    if (participation === null) {
      return;
    }
    saveParticipation.callAPI(
      {
        participationId,
        simpleFields: {
          participationProcedure: 'WITHOUT_REQUEST',
          reportTheme: participation?.reportTheme,
          reportDate: participation?.reportDate,
          development: participation?.development,
          note: participation?.note,
          isInTeam: false,
          collectiveExhibitName: participation?.collectiveExhibitName,
          IsCollectiveExhibit: Boolean(participation?.isCollectiveExhibit),
          isPublished: !!participation?.isPublished,
        },
        attrIdFields: {
          exponent: participation?.exponent?.id ?? undefined,
          event: participation?.event?.id,
          reportType: participation?.reportType?.id,
          publication: participation?.publication?.status?.id,
          form: participation?.form?.id,
        },
        otherFields: {
          participationPresentationForm: (participation?.presentationForms || []).map(key => ({
            value: key || '',
            label: '',
            key,
            ordinal: 1,
            position: 1,
            isChecked: true,
          })),
          documents: participation?.documents,
          departments: participation?.departments,
          awards: participation?.awards,
          members: participation?.members,
          mobileRequests,
          financingProjects,
          presentationProject: participation?.presentationProject,
        },
      },
      {
        onSuccessfullCall: () => {
          showNotification({ message: 'Участие успешно отредактировано', theme: 'success' });
          onSuccess();
          onClose();
        },
      },
    );
  }, [financingProjects, mobileRequests, onClose, onSuccess, participation, participationId, saveParticipation]);

  const setFinancingProjectsCb = useCallback(
    (projectsData: Array<ParticipationProject>) => {
      setFinancingProjects(
        projectsData.map(projectItem => ({
          ...projectItem,
          type: getEnumItem('ParticipationProjectType', ParticipationProjectType.FINANCING, enumMap),
        })),
      );
    },
    [enumMap],
  );

  const setMobileRequestsCb = useCallback((requests: Array<Form.PublicationMobileRequest>) => setMobileRequests(requests), []);

  return {
    mobileRequests,
    financingProjects,
    submitForm,
    setFinancingProjectsCb,
    setMobileRequestsCb,
  };
};

export default useController;

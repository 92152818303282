import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import * as M from 'features/Table/specifications/GetReferenceElementList/model';

import { Checkbox, FormComponent, Select, SelectMode, TextArea, TextDateTime, TextInput, TextInputMode } from 'components';

import { Item } from 'types/models/common';
import { PasswordField } from './PasswordField';
import { ReferenceField } from './ReferenceField';

import './style.scss';

const b = block('referece-metadata-fields');

type Props = {
  fields: M.EditableMetadataField[];
  onChange(name: string, value: M.FieldValue): void;
  mode: M.Mode | null;
  isRecordEditable: boolean;
  isOpenEditor?: boolean;
};

function Fields(props: Props) {
  const { fields, onChange, mode, isRecordEditable, isOpenEditor } = props;
  const isAddMode = mode === 'add';
  const isViewMode = mode === 'view';
  const isDisabled = useCallback(
    (isFieldEditable: boolean) => {
      return !isAddMode && (isViewMode || !(isRecordEditable || isFieldEditable));
    },
    [isAddMode, isRecordEditable, isViewMode],
  );

  const mapFields: Record<M.EditableMetadataField['type'], (field: any) => JSX.Element> = {
    STRING: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      if (name === 'password') {
        return <PasswordField value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
      }
      return <TextInput value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
    },
    BOOLEAN: (field: M.EditableBooleanMetadataField) => {
      const { name, value, editable } = field;
      return <Checkbox isDisabled={isDisabled(editable)} checked={!!value} onChange={val => onChange(name, val)} />;
    },
    DATE: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextDateTime value={value} onChange={(date: string) => onChange(name, date ?? '')} isDisabled={isDisabled(editable)} />
      );
    },
    DATETIME: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextDateTime
          type={['date', 'time']}
          rangeButtons={['singeDate', 'toInfinite', 'fromInfinite']}
          value={value}
          onChange={(date: string) => onChange(name, date ?? '')}
          isDisabled={isDisabled(editable)}
          style={{ maxWidth: '100%' }}
        />
      );
    },
    TEXT: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextArea
          settings={{ isFixed: true }}
          value={value ?? ''}
          onChange={val => onChange(name, val)}
          isDisabled={isDisabled(editable)}
        />
      );
    },
    ENUMERATION: (field: M.EditableEnumerationMetadataField) => {
      const { enumeration, name, value, editable } = field;
      return (
        <Select
          mode={SelectMode.ENUM}
          value={{ value: value?.value, label: value?.value } as Item}
          settings={{ name: enumeration }}
          onChange={(option: Item) => onChange(name, option)}
          isDisabled={isDisabled(editable)}
        />
      );
    },
    INTEGER: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return <TextInput value={value ?? ''} onChange={val => onChange(name, val)} isDisabled={isDisabled(editable)} />;
    },
    REFERENCE: (field: M.EditableReferenceMetadataField) => (
      <ReferenceField
        field={field}
        isDisabled={isDisabled(field.editable)}
        onChange={onChange}
        isRequired={!!field.required}
        isOpenEditor={isOpenEditor}
      />
    ),
    DOUBLE: (field: M.EditableCommonMetadataField) => {
      const { name, value, editable } = field;
      return (
        <TextInput
          value={value || undefined}
          onChange={val => onChange(name, val)}
          isDisabled={isDisabled(editable)}
          mode={TextInputMode.NUMBER}
        />
      );
    },
  };

  return (
    <div className={b()}>
      {fields.map((field, index) => {
        const { type, caption, required } = field;
        const render = mapFields[type];
        return (
          <FormComponent.Line key={index}>
            <FormComponent.Field label={caption} isRequired={type === 'BOOLEAN' ? false : required}>
              {render(field)}
            </FormComponent.Field>
          </FormComponent.Line>
        );
      })}
    </div>
  );
}

export { Fields };

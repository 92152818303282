import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { LockedInfo, Publication } from 'types/models';
import { Settings } from 'types/models/common';
import { convertSaveResponseXMLElementToOutput, getText } from '../commonConverters';

import { convertServerPublication, convertDeletePublicationResponse } from './converters';
import {
  CompilationProcess,
  GetPublicationRequest,
  LockEntityRequest,
  SaveDepartmentsRequest,
  DeletePublicationRequest,
  ChangeCompilationArticleCompilationRequest,
  ChangeMagazineArticleMagazineRequest,
  ChangeMonographChapterMonographRequest,
  ChangePublicationOtherArticlePublicationOtherRequest,
} from './types';

export const publicationConfigurations = {
  ApprovePublication: makeBackendAPIConfiguration({
    id: 'ApprovePublication',
    endpoint: '/msa/service/commands/ApprovePublication',
    convertInputToXMLElement: ({ publicationId }: GetPublicationRequest) => ({
      Request: {
        _attr: { commandName: 'ApprovePublication' },
        Publication: {
          _attr: { id: publicationId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  SavePublicationDepartments: makeBackendAPIConfiguration({
    id: 'SavePublicationDepartments',
    endpoint: '/msa/service/commands/SavePublicationDepartments',
    convertInputToXMLElement: ({ publicationId, departments }: SaveDepartmentsRequest) => ({
      Request: {
        _attr: { commandName: 'SavePublicationDepartments' },
        Publication: {
          _attr: { id: publicationId },
          Department: departments.map((department, index) => ({
            _attr: department.id ? { id: department.id } : {},
            Department: {
              _attr: { id: department.ref.id },
            },
            Position: index,
          })),
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  GetPublicationById: makeBackendAPIConfiguration({
    id: 'GetPublicationById',
    endpoint: '/msa/service/commands/GetPublication',
    convertInputToXMLElement: ({ publicationId }: GetPublicationRequest) => ({
      Request: {
        _attr: { commandName: 'GetPublication' },
        Publication: {
          _attr: { id: publicationId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings): Publication =>
      convertServerPublication(response.Response.Publication, settings),
  }),
  ExportPublicationToVirtua: makeBackendAPIConfiguration({
    id: 'ExportPublicationToVirtua',
    endpoint: '/msa/service/commands/ExportPublicationToVirtua',
    convertInputToXMLElement: ({ publicationId }: GetPublicationRequest) => ({
      Request: {
        _attr: { commandName: 'ExportPublicationToVirtua' },
        Publication: {
          _attr: { id: publicationId },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  LockEntity: makeBackendAPIConfiguration({
    id: 'LockEntity',
    endpoint: '/msa/service/commands/LockEntity',
    convertInputToXMLElement: ({ id, nameEntity }: LockEntityRequest) => ({
      Request: {
        _attr: { commandName: 'LockEntity' },
        Lock: {
          _attr: { id, name: nameEntity },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact): LockedInfo | null => {
      if (response.Response._attributes.status === '409') {
        const lock = response.Response.Lock;
        const duration = Number(getText(lock.LockedDuration));
        const lockedBy = lock.LockedBy._attributes.fullName;
        return { duration, lockedBy };
      }
      return null;
    },
  }),
  UnlockEntity: makeBackendAPIConfiguration({
    id: 'UnlockEntity',
    endpoint: '/msa/service/commands/UnlockEntity',
    convertInputToXMLElement: ({ id, nameEntity }: LockEntityRequest) => ({
      Request: {
        _attr: { commandName: 'UnlockEntity' },
        Lock: {
          _attr: { id, name: nameEntity, forceUnlock: 'false' },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  CompilationProcess: makeBackendAPIConfiguration({
    id: 'CompilationProcess',
    endpoint: '/msa/service/commands/CompilationProcess',
    convertInputToXMLElement: ({
      publicationId,
      action,
      newType,
      newArticleType,
      urlDate,
      url,
      electronicType,
      electronicMediaSource,
      electronicSystemRequirements,
      articleIds,
    }: CompilationProcess) => ({
      Request: {
        _attr: { commandName: 'CompilationProcess' },
        CompilationProcess: {
          _attr: {
            id: publicationId,
            action,
            newType,
            ...(newArticleType ? { newArticleType } : {}),
            ...(urlDate ? { urlDate } : {}),
            ...(url ? { url } : {}),
            electronicType,
            ...(electronicMediaSource ? { electronicMediaSource } : {}),
            ...(electronicSystemRequirements ? { electronicSystemRequirements } : {}),
            articleIds,
          },
        },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
  DeletePublication: makeBackendAPIConfiguration({
    id: 'DeletePublication',
    endpoint: '/msa/service/commands/DeletePublication',
    convertInputToXMLElement: ({ publicationId }: DeletePublicationRequest) => ({
      Request: {
        _attr: { commandName: 'DeletePublication' },
        Publication: { _attr: { id: publicationId } },
      },
    }),
    convertResponseXMLElementToOutput: convertDeletePublicationResponse,
  }),
  ChangeCompilationArticleCompilation: makeBackendAPIConfiguration({
    id: 'ChangeCompilationArticleCompilation',
    endpoint: '/msa/service/commands/ChangeCompilationArticleCompilation',
    convertInputToXMLElement: ({ sourcePublicationId, articlePublicationIds }: ChangeCompilationArticleCompilationRequest) => ({
      Request: {
        _attr: { commandName: 'ChangeCompilationArticleCompilation' },
        Compilation: {
          _attr: { id: sourcePublicationId },
          CompilationArticle: articlePublicationIds.map(id => ({ _attr: { id } })),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangeMagazineArticleMagazine: makeBackendAPIConfiguration({
    id: 'ChangeMagazineArticleMagazine',
    endpoint: '/msa/service/commands/ChangeMagazineArticleMagazine',
    convertInputToXMLElement: ({
      sourcePublicationId,
      sourcePublicationReleaseId,
      articlePublicationIds,
    }: ChangeMagazineArticleMagazineRequest) => ({
      Request: {
        _attr: { commandName: 'ChangeMagazineArticleMagazine' },
        Magazine: {
          _attr: { id: sourcePublicationId, magazineReleaseId: sourcePublicationReleaseId || -1 },
          MagazineArticle: articlePublicationIds.map(id => ({ _attr: { id } })),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangeMonographChapterMonograph: makeBackendAPIConfiguration({
    id: 'ChangeMonographChapterMonograph',
    endpoint: '/msa/service/commands/ChangeMonographChapterMonograph',
    convertInputToXMLElement: ({ sourcePublicationId, articlePublicationIds }: ChangeMonographChapterMonographRequest) => ({
      Request: {
        _attr: { commandName: 'ChangeMonographChapterMonograph' },
        Monograph: {
          _attr: { id: sourcePublicationId },
          MonographChapter: articlePublicationIds.map(id => ({ _attr: { id } })),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
  ChangePublicationOtherArticlePublicationOther: makeBackendAPIConfiguration({
    id: 'ChangePublicationOtherArticlePublicationOther',
    endpoint: '/msa/service/commands/ChangePublicationOtherArticlePublicationOther',
    convertInputToXMLElement: ({
      sourcePublicationId,
      articlePublicationIds,
    }: ChangePublicationOtherArticlePublicationOtherRequest) => ({
      Request: {
        _attr: { commandName: 'ChangePublicationOtherArticlePublicationOther' },
        PublicationOther: {
          _attr: { id: sourcePublicationId },
          OtherArticle: articlePublicationIds.map(id => ({ _attr: { id } })),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveResponseXMLElementToOutput,
  }),
};

import { Article, CitationSystem, Payment } from 'types/models';
import { convertSecurityDocument } from 'services/BackendAPI/configurations/securityDocument/converters';
import { Settings } from 'types/models/common';
import * as authorConverters from '../author/converters';
import {
  convertDocument,
  convertIdItem,
  convertItem,
  convertMaybeArray,
  convertReference,
  getId,
  getText,
} from '../commonConverters';
import { convertServerParticipation } from '../participation/converters';
import { convertServerDepartment, convertServerPublication } from '../publication/converters';

export function convertServerCitationSystem(citationSystem: any): CitationSystem {
  return {
    authors: getText(citationSystem.Authors),
    bibliographicRecord: getText(citationSystem.BibliographicRecord),
    identifier: getText(citationSystem.Identifier),
    keyWords: getText(citationSystem.KeyWords),
    name: getText(citationSystem.Name),
    url: getText(citationSystem.Url),
    createdBy: getText(citationSystem.CreatedBy?.FullName || citationSystem.CreatedBy),
    publicationType: convertReference(citationSystem.PublicationType),
    refCitationSystem: convertReference(citationSystem.CitationSystem),
    id: citationSystem._attributes.id,
  };
}

export function convertServerArticle(article: any, settings: Settings): Article {
  return {
    annotation: getText(article.Annotation),
    authorsCount: getText(article.AuthorsCount),
    authorsKeyWords: getText(article.AuthorsKeyWords),
    bibliographicRecord: getText(article.BibliographicRecord),
    id: article._attributes.id,
    name: getText(article.Name),
    citationSystems: convertMaybeArray(article.CitationSystems.CitationSystem || [], convertServerCitationSystem),
    createdBy: {
      id: article.CreatedBy._attributes.id,
      department: convertReference(article.CreatedBy.Department),
      user: convertIdItem(article.CreatedBy.User),
    },
    modifiedBy: {
      department: convertReference(article.ModifiedBy.Department),
      user: convertIdItem(article.ModifiedBy.User),
    },
    statusModifiedBy: {
      id: article.StatusModifiedBy._attributes.id,
      department: convertReference(article.StatusModifiedBy.Department),
      parentDepartment: convertReference(article.StatusModifiedBy.ParentDepartment),
      user: convertIdItem(article.StatusModifiedBy.User),
    },
    createdDate: getText(article.CreatedDate),
    doi: getText(article.Doi),
    members: convertMaybeArray(article.Members.Member || [], m => authorConverters.convertMember(m, settings)),
    endPage: getText(article.EndPage),
    isElectronic: getText(article.IsElectronic),
    year: getText(article.Year),
    file: article.File ? convertIdItem(article.File) : null,
    modifiedDate: getText(article.ModifiedDate),
    startPage: getText(article.StartPage),
    status: convertItem(article.Status),
    statusModifiedDate: getText(article.StatusModifiedDate),
    textLanguage: convertReference(article.TextLanguage),
    type: convertItem(article.Type),
    typeEdition: article.TypeEdition ? convertItem(article.TypeEdition) : null,
    departments: convertMaybeArray(article.Departments.Department || [], convertServerDepartment),
    magazine: article.Magazine ? { id: article.Magazine._attributes.id } : null,
  };
}

export function convertServerAuthorPayment(authorPayment: any, settings: Settings): Payment.AuthorPayment {
  const serverForeignDocuments = authorPayment.ForeignDocuments?.Document || [];
  const serverDocuments = authorPayment.Documents?.Document || [];
  const serverNominees = authorPayment.Nominees.Nominee || [];
  const departments = authorPayment.FundDistributions?.FundDistribution || [];

  const foreignDocuments = convertMaybeArray(serverForeignDocuments, i => convertDocument('foreign', i));
  const localDocuments = convertMaybeArray(serverDocuments, i => convertDocument('local', i));

  return {
    id: authorPayment._attributes ? authorPayment._attributes.id : null,
    conference: authorPayment.Conference ? convertServerAuthorPaymentEvent(authorPayment.Conference) : null,
    createdBy: authorPayment.CreatedBy ? convertIdItem(authorPayment.CreatedBy) : null,
    distributionMethodBetweenFunds: authorPayment.DistributionMethodBetweenFunds
      ? convertItem(authorPayment.DistributionMethodBetweenFunds)
      : null,
    isManualEdit: authorPayment.DistributionMethodBetweenFunds._attributes.manual === 'true',
    distributionMethodBetweenNominees: authorPayment.DistributionMethodBetweenNominees
      ? convertItem(authorPayment.DistributionMethodBetweenNominees)
      : null,
    factFundMoney: getText(authorPayment.FactFundMoney),
    factNomineesMoney: getText(authorPayment.FactNomineesMoney),
    maxFundMoney: getText(authorPayment.MaxFundMoney),
    maxNomineesMoney: getText(authorPayment.MaxNomineesMoney),
    month: getText(authorPayment.Month),
    quarter: authorPayment.Quarter ? convertItem(authorPayment.Quarter) : null,
    year: getText(authorPayment.Year),
    type: authorPayment.Type ? convertItem(authorPayment.Type) : null,
    remainderFundMoney: getText(authorPayment.RemainderFundMoney),
    remainderNomineesMoney: getText(authorPayment.RemainderNomineesMoney),
    status: authorPayment.Status ? convertItem(authorPayment.Status) : null,
    statusChangedBy: authorPayment.StatusChangedBy ? convertIdItem(authorPayment.StatusChangedBy) : null,
    nominees:
      convertMaybeArray(serverNominees, n => convertNominee(n, settings)).sort((x1, x2) => {
        return Number(x2.isAffiliated) - Number(x1.isAffiliated);
      }) || [],
    documents: [...foreignDocuments, ...localDocuments],
    departments: convertMaybeArray(departments, convertDepartment),
    regulationName: getText(authorPayment.RegulationName) ?? null,
    regulationParagraph: getText(authorPayment.RegulationParagraph) ?? null,
    article: authorPayment.Article ? convertServerArticle(authorPayment.Article, settings) : null,
    publication: authorPayment.Publication ? convertServerPublication(authorPayment.Publication, settings) : null,
    participation: authorPayment.Participation ? convertServerParticipation(authorPayment.Participation, settings) : null,
    statusChangedDate: getText(authorPayment.StatusChangedDate),
    patent: authorPayment.Patent ? convertSecurityDocument(authorPayment.Patent, settings) : null,
  };
}

export function convertDepartment(department: any): Payment.Department {
  return {
    amountMoney: getText(department.AmountMoney),
    fraction: getText(department.Fraction),
    nomineeQuantity: getText(department.NomineeQuantity),
    topLevelDepartment: department.TopLevelDepartment ? convertReference(department.TopLevelDepartment) : null,
  };
}

export function convertNominee(serverNominee: any, settings: Settings): Payment.Nominee {
  const isPaid = getText(serverNominee.IsPaid) === 'true';
  return {
    person: authorConverters.convertServerPerson(serverNominee.Nominee, settings),
    factMoney: isPaid ? getText(serverNominee.FactMoney) : '',
    isAffiliated: getText(serverNominee.IsAffiliated) === 'true',
    isPaid,
    name: getText(serverNominee.Name),
    planMoney: getText(serverNominee.PlanMoney),
    weight: getText(serverNominee.Weight),
    topLevelDepartment: serverNominee.TopLevelDepartment ? convertReference(serverNominee.TopLevelDepartment) : null,
    job: serverNominee.Job ? authorConverters.convertServerScientistJob(serverNominee.Job) : null,
    education: serverNominee.Education ? authorConverters.convertServerScientistEducation(serverNominee.Education) : null,
    foundationJob: serverNominee.FoundationJob ? authorConverters.convertServerScientistJob(serverNominee.FoundationJob) : null,
    foundationEducation: serverNominee.FoundationEducation
      ? authorConverters.convertServerScientistEducation(serverNominee.FoundationEducation)
      : null,
    citizenship: serverNominee.Citizenship ? convertReference(serverNominee.Citizenship) : null,
    degree: serverNominee.Degree ? authorConverters.convertServerScientistDegree(serverNominee.Degree) : null,
    rank: serverNominee.Rank ? authorConverters.convertServerScientistRank(serverNominee.Rank) : null,
    note: getText(serverNominee.Note),
  };
}

export function convertServerAuthorPaymentEvent(event: any): Payment.Event {
  return {
    projects: getText(event.Projects),
    fullName: getText(event.FullName),
    name: getText(event.Name),
    id: event._attributes.id,
    isStudent: getText(event.IsStudent) === 'true',
    level: getText(event.Level),
    organizers: getText(event.Organizers),
  };
}

export function convertTermPreset(termPreset: any): Payment.TermPreset {
  return {
    id: getId(termPreset),
    type: termPreset?.Type ? convertItem(termPreset?.Type) : null,
    regulationName: getText(termPreset?.RegulationName),
    regulationParagraph: getText(termPreset?.RegulationParagraph),
  };
}

export function convertServerPaymentFeedline(paymentFeedline: any): Payment.PaymentFeedline {
  return {
    id: paymentFeedline._attributes.id,
    messageCategory: convertReference(paymentFeedline.MessageCategory),
    createdBy: getText(paymentFeedline.CreatedBy),
    createdDate: getText(paymentFeedline.CreatedDate),
    modifiedBy: getText(paymentFeedline.ModifiedBy),
    modifiedDate: getText(paymentFeedline.ModifiedDate),
    isSystem: getText(paymentFeedline.IsSystem) === 'true',
    message: getText(paymentFeedline.Message),
    status: convertItem(paymentFeedline.Status),
  };
}

export function convertSavePaymentResponse(response: any) {
  return {
    id: getText(response.Id),
  };
}

import React from 'react';

import { FormComponent, Toolbar, SectionTitle, TextDateTime, Select, SelectMode, TextInput, ListEdit } from 'components';

import { useController } from './controller';
import { ReferenceItem, CashDocument } from 'types/models';
import { showNotification } from 'features/Notifications';
import { ItemFields } from './ItemFields';

type Props = {
  onClose: () => void;
  viewMode?: boolean;
};

function CashDocumentForm({ viewMode, onClose }: Props) {
  const { cashDocument, setCashDocument, buttons, formType } = useController({
    viewMode,
    onClose,
  });

  return (
    <FormComponent.Template>
      <Toolbar buttons={buttons} mode="form" />

      <FormComponent.Template>
        <FormComponent.Wrapper>
          {/* <SectionTitle
            title={`Сведения о ${formType === 'expense' ? 'фактическом расходовании' : 'поступлении'} средств на шифр проекта`}
          /> */}
          <FormComponent.Line>
            <FormComponent.Field label="Номер документа" isRequired>
              <TextInput
                value={cashDocument.number}
                onChange={e => setCashDocument(prev => ({ ...prev, number: e }))}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
            <FormComponent.Field label="Дата документа" isRequired>
              <TextDateTime
                value={cashDocument.payDate}
                onChange={e => setCashDocument(prev => ({ ...prev, payDate: e }))}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="Контрагент" isRequired>
              <Select
                mode={SelectMode.REFERENCE}
                value={cashDocument.enterprise}
                onChange={(e: ReferenceItem | null) => setCashDocument(prev => ({ ...prev, enterprise: e }))}
                settings={{ name: 'RefEnterpriseCustomer', title: 'Справочник "Заказчики"', isClearable: true }}
                isDisabled={!!viewMode}
              />
            </FormComponent.Field>
          </FormComponent.Line>
          <FormComponent.Line>
            <FormComponent.Field label="О контрагенте">{`${
              cashDocument.enterprise?.customFields?.fullName || cashDocument.enterprise?.label || ''
            } (ИНН/КПП: ${cashDocument.enterprise?.customFields?.inn || '-'}/${
              cashDocument.enterprise?.customFields?.kpp || '-'
            })${
              cashDocument.enterprise?.customFields?.country
                ? ` ${(cashDocument.enterprise?.customFields?.country as ReferenceItem)?.label};`
                : ''
            } ${[cashDocument.enterprise?.customFields?.city, cashDocument.enterprise?.customFields?.street]
              .filter(Boolean)
              .join(', ')}`}</FormComponent.Field>
          </FormComponent.Line>

          <SectionTitle title="Детализация по шифрам проектов" />
          <FormComponent.Line>
            <ListEdit
              rows={cashDocument.items}
              onChange={e => setCashDocument(prev => ({ ...prev, items: e }))}
              toolbar={['add', 'edit', 'delete']}
              columns={[
                ...(formType === 'expense'
                  ? [
                      {
                        label: 'Вид расходов',
                        formatValue: (row: CashDocument.Item) =>
                          [row.kindConsumption?.code, row.kindConsumption?.name].filter(Boolean).join(' ') || '',
                      },
                      { label: 'Назначение платежа', formatValue: (row: CashDocument.Item) => row.purpose || '' },
                    ]
                  : []),
                {
                  label: 'Код шифра',
                  formatValue: row => row.projectCode?.code || '',
                  styles: { width: '10%' },
                  defaultSort: 'asc',
                },
                { label: 'Сумма', formatValue: row => row.amount || '', styles: { width: '10%' }, dataKind: 'float' },
                ...(formType === 'receive'
                  ? [{ label: 'Примечание', formatValue: (row: CashDocument.Item) => row.note || '' }]
                  : []),
              ]}
              columnIndexesForSumTotal={formType === 'expense' ? [3] : [1]}
              specification={{
                mode: 'customModalComponent',
                modalTitle: 'Детализация по шифру проекта',
                validation: {
                  checkIsValid: row =>
                    !!row?.projectCode && !!row.amount && (formType === 'expense' ? !!row.kindConsumption : true),
                  onInvalidate: () => showNotification({ message: 'Выберите подразделение', theme: 'danger' }),
                },
                renderComponent: (row, onChange) => <ItemFields item={row} setItem={onChange} formType={formType} />,
              }}
            />
          </FormComponent.Line>

          {formType === 'expense' && (
            <>
              <SectionTitle title="Основание" />
              <FormComponent.Line>
                <FormComponent.Field label="Тип документа основания" isRequired>
                  <TextInput
                    value={cashDocument.foundation?.type}
                    onChange={e => setCashDocument(prev => ({ ...prev, foundation: { ...prev.foundation!, type: e } }))}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Номер документа основ." isRequired>
                  <TextInput
                    value={cashDocument.foundation?.number}
                    onChange={e => setCashDocument(prev => ({ ...prev, foundation: { ...prev.foundation!, number: e } }))}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата документа основания" isRequired>
                  <TextDateTime
                    value={cashDocument.foundation?.date}
                    onChange={e => setCashDocument(prev => ({ ...prev, foundation: { ...prev.foundation!, date: e } }))}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Дата начала договора" isRequired>
                  <TextDateTime
                    value={cashDocument.foundation?.startDate}
                    onChange={e => setCashDocument(prev => ({ ...prev, foundation: { ...prev.foundation!, startDate: e } }))}
                  />
                </FormComponent.Field>
                <FormComponent.Field label="Дата окончания договора" isRequired>
                  <TextDateTime
                    value={cashDocument.foundation?.endDate}
                    onChange={e => setCashDocument(prev => ({ ...prev, foundation: { ...prev.foundation!, endDate: e } }))}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
        </FormComponent.Wrapper>
      </FormComponent.Template>
    </FormComponent.Template>
  );
}

export const Component = React.memo(CashDocumentForm);

import { Table } from 'types/models';
import { Filters } from './filters';
import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';

type Arguments = {
  hasSelectButton?: boolean;
};

export function GetEvidenceSecurityDocumentList(args?: Arguments): Table.Specification {
  return {
    apiID: 'GetEvidenceSecurityDocumentList',
    header: {
      firstLevel: {
        title: 'Список свидетельств и поданных заявок',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
        hasSelectButton: args?.hasSelectButton,
      },
    },
  };
}

import { useCallback } from 'react';

import { Performer } from 'types/models/ProgramRequest';
import { Member } from 'features/SelectPerson';

type Props = {
  entry: Performer | null;
  onFieldChange(row: Performer | null): void;
};

export function useController({ entry, onFieldChange }: Props) {
  const handleRoleChange = useCallback(
    () => (option: any) => {
      onFieldChange({ ...entry!, role: option });
    },
    [onFieldChange, entry],
  );

  const handlePersonChange = useCallback(
    () => (value: Member | null) => {
      const nextPerformer: Performer | null = value
        ? {
            id: entry?.id || null,
            role: entry?.role || null,
            ...value,
            historyLabel: entry?.historyLabel || '',
          }
        : null;
      onFieldChange(nextPerformer);
    },
    [onFieldChange, entry],
  );

  return {
    handlePersonChange,
    handleRoleChange,
  };
}

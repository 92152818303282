import { useCallback } from 'react';

import { FileInfo } from 'types/models';
import { SecurityDocumentPayment } from 'types/models/SecurityDocument';

type Props = {
  payment: SecurityDocumentPayment | null;
  onPaymentChange(row: SecurityDocumentPayment): void;
};

export function useController({ payment, onPaymentChange }: Props) {
  const handleFieldChange = useCallback(
    (fieldName: keyof SecurityDocumentPayment) => (value: any) => {
      onPaymentChange({ ...payment, [fieldName]: value } as SecurityDocumentPayment);
    },
    [payment, onPaymentChange],
  );

  const handleFileChange = useCallback(
    (fileInfo: FileInfo) => {
      onPaymentChange({
        ...payment,
        file: fileInfo,
      } as SecurityDocumentPayment);
    },
    [onPaymentChange, payment],
  );

  return {
    handleFieldChange,
    handleFileChange,
  };
}

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { convertDocumentToRequest } from '../commonConverters';
import {
  convertServerReportTree,
  convertServerReportDocuments,
  convertSaveReportDocumentResponseXMLElementToOutput,
  convertDeleteReportDocumentResponseXMLElementToOutput,
  convertGetReportParametersXMLElementToOutput,
} from './converters';

import {
  GetReportDocumentsRequest,
  SaveReportDocumentRequest,
  DeleteReportDocumentRequest,
  GetReportParametersRequest,
} from './types';

export const reportConfigurations = {
  GetReportTree: makeBackendAPIConfiguration({
    id: 'GetReportTree',
    endpoint: '/report/service/commands/GetReportTree',
    convertInputToXMLElement: () => ({
      Request: {
        _attr: {
          commandName: 'GetReportTree',
        },
        GetReportTree: {},
      },
    }),
    convertResponseXMLElementToOutput: convertServerReportTree,
  }),
  GetReportDocuments: makeBackendAPIConfiguration({
    id: 'GetReportDocuments',
    endpoint: '/msa/service/commands/GetReportDocuments',
    convertInputToXMLElement: ({ group }: GetReportDocumentsRequest) => ({
      Request: {
        _attr: { commandName: 'GetReportDocuments' },
        GetReportDocuments: { _attr: { group } },
      },
    }),
    convertResponseXMLElementToOutput: convertServerReportDocuments,
  }),
  SaveReportDocument: makeBackendAPIConfiguration({
    id: 'SaveReportDocument',
    endpoint: '/msa/service/commands/SaveReportDocument',
    convertInputToXMLElement: ({ groupId, document }: SaveReportDocumentRequest) => ({
      Request: {
        _attr: { commandName: 'SaveReportDocument' },
        ReportDocument: {
          _attr: { group: groupId },
          Document: convertDocumentToRequest(document),
        },
      },
    }),
    convertResponseXMLElementToOutput: convertSaveReportDocumentResponseXMLElementToOutput,
  }),
  DeleteReportDocument: makeBackendAPIConfiguration({
    id: 'DeleteReportDocument',
    endpoint: '/msa/service/commands/DeleteReportDocument',
    convertInputToXMLElement: ({ documentId }: DeleteReportDocumentRequest) => ({
      Request: {
        _attr: { commandName: 'DeleteReportDocument' },
        DeleteReportDocument: { _attr: { id: documentId } },
      },
    }),
    convertResponseXMLElementToOutput: convertDeleteReportDocumentResponseXMLElementToOutput,
  }),
  GetReportParameters: makeBackendAPIConfiguration({
    id: 'GetReportParameters',
    endpoint: '/report/service/commands/GetReportParameters',
    convertInputToXMLElement: ({ reportName }: GetReportParametersRequest) => ({
      Request: {
        _attr: { commandName: 'GetReportParameters' },
        Report: { _attr: { name: reportName } },
      },
    }),
    convertResponseXMLElementToOutput: convertGetReportParametersXMLElementToOutput,
  }),
};

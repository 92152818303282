import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, TextArea, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import { BuildReportPopup } from 'features/BuildReportPopup';
import { MessageModal } from 'features/Form/views/MessageModal';
import useController from './controller';
import { DataGrid } from 'features/Table';
import { GetTenderListForRequest } from 'features/Table/specifications/GetTenderListForRequest';
import { TenderRequestType } from 'utils/Enums';
import { TenderInfo } from 'types/models/Form/look/programRequest';
import { ExpertEstimateModal } from 'features/Form/views/ExpertEstimateModal';

export type NirRequestLocalType = {
  specification: string;
} & TenderInfo;

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel(props: Props) {
  const { tableState } = props;

  const {
    isMessageModalOpen,
    handleCloseMessageModal,
    buttons,
    isConfirmDeleteModalOpen,
    setIsConfirmModalOpen,
    deleteNir,
    selectedRow,
    isReportOpen,
    onReportClose,
    currentReport,
    handleTenderSelect,
    isTenderModalOpen,
    setIsTenderModalOpen,
    isViewFormOpen,
    isEditFormOpen,
    isAddFormOpen,
    setIsViewFormOpen,
    setIsAddFormOpen,
    setIsEditFormOpen,
    sendMessage,
    nirRequest,
    isStatusModalOpen,
    setIsStatusModalOpen,
    statusMessage,
    setStatusMessage,
    statusLabel,
    changeStatus,
    isApprovementModalOpen,
    setIsApprovementModalOpen,
    saveApprovement,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      {isViewFormOpen && (
        <modalTemplates.NirRequestViewTemplate.Component
          isOpen={isViewFormOpen}
          onClose={() => setIsViewFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          nirRequest={null}
        />
      )}

      {isEditFormOpen && (
        <modalTemplates.NirRequestEditTemplate.Component
          isOpen={isEditFormOpen}
          onClose={() => setIsEditFormOpen(false)}
          id={tableState.selectedRows[0]?.id}
          relatedTableState={tableState}
          nirRequest={null}
        />
      )}

      {isAddFormOpen && (
        <modalTemplates.NirRequestAddTemplate.Component
          isOpen={isAddFormOpen}
          onClose={() => setIsAddFormOpen(false)}
          relatedTableState={tableState}
          nirRequest={nirRequest}
        />
      )}

      <MessageModal
        isOpen={isMessageModalOpen}
        onClose={handleCloseMessageModal}
        onSubmit={sendMessage}
        titleText="Добавление сообщения в журнал"
        submitText="Добавить"
      />

      {isStatusModalOpen && (
        <Modal
          title="Смена статуса"
          isOpen={isStatusModalOpen}
          onClose={() => setIsStatusModalOpen(false)}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: statusLabel,
              onClick: changeStatus,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: () => setIsStatusModalOpen(false),
            },
          ]}
          size="small"
        >
          <TextArea settings={{ rows: 5 }} value={statusMessage} onChange={setStatusMessage} />
        </Modal>
      )}

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isConfirmDeleteModalOpen}
        onClose={() => setIsConfirmModalOpen(false)}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: () => {
              deleteNir(selectedRow?.id);
              setIsConfirmModalOpen(false);
            },
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: () => setIsConfirmModalOpen(false),
          },
        ]}
        size="small"
      >
        <>Заявка на конкурс от {tableState.selectedRows[0]?.Leader}. Удалить заявку?</>
      </Modal>

      <BuildReportPopup
        isOpen={isReportOpen}
        onClose={onReportClose}
        reportName={currentReport?.name || ''}
        reportCaption={currentReport?.caption || ''}
        values={{
          nirRequestId: selectedRow?.id ?? '',
        }}
      />

      <Modal
        isOpen={isTenderModalOpen}
        onClose={() => setIsTenderModalOpen(false)}
        title="Список конкурсов"
        size={['extralarge', 'large-height']}
      >
        <DataGrid
          specification={GetTenderListForRequest({
            tenderRequestType: TenderRequestType.NIR_REQUEST,
            selectRow: handleTenderSelect,
          })}
        />
      </Modal>

      <ExpertEstimateModal
        isOpen={isApprovementModalOpen}
        onClose={() => setIsApprovementModalOpen(false)}
        onSubmit={saveApprovement}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

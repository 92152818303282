import React from 'react';

import { ButtonMode, ListEdit, Modal } from 'components';

import { showNotification } from 'features/Notifications';
import { Person, UserPermission } from 'types/models';

import { useMainScientistsContentContext } from 'App/ScientistsPage/context';
import { LinkedHistoryEntities } from 'features/Form/views/LinkedHistoryEntities';
import { EditableHonor } from 'features/Form/looks/person/model';

import { Fields } from './Fields';
import { formatName } from './helpers';
import { validate } from './validate';
import useController from './controller';
import { Color } from 'constants/colors';
import { MergePersonCollection } from 'features/MergePersonCollection';

type Props = {
  person: Person.ScientistPerson | null;
  userPermission: UserPermission | null;
  disabled: boolean;
  isAddMode?: boolean;
  onChangeHonors(honors: EditableHonor[]): void;
  onUpdate?: () => void;
};

export function ScientistHonors(props: Props) {
  const { isMainScientistsContent } = useMainScientistsContentContext();
  const { person, userPermission, disabled, onChangeHonors, isAddMode, onUpdate } = props;
  const {
    extraToolbarButtons,
    isDeleteConfirmOpen,
    honors,
    isLinkedHistoryPending,
    isDeleteHistoryWarningOpen,
    isEntityRelationOpen,
    selectedHonor,
    selectedHonorLinkedType,
    deleteConfirmPopupTitle,
    closeEntityRelation,
    closeDeleteHistoryWarning,
    confirmDeleteHistoryWarning,
    onDeleteReset,
    onDeleteConfirm,
    handleCloseMergeDuplicatesForm,
    isMergeDuplicatesOpen,
    handleSelectedRows,
    degreeIds,
  } = useController({
    onChangeHonors,
    person,
  });

  return (
    <div>
      <ListEdit
        maxHeight="none"
        withMessages
        isDeleteConfirmEnabled
        toolbar={!isAddMode && !disabled && isMainScientistsContent && ['add', 'edit', ...extraToolbarButtons]}
        isDisabled={isLinkedHistoryPending}
        rows={honors}
        getRowTheme={row => (!row.isReadOnly ? Color.danger : '')}
        onChange={onChangeHonors}
        onSelectedRowsCallback={handleSelectedRows}
        columns={[
          { label: 'Наименование', formatValue: formatName },
          { label: 'Дата получения', formatValue: row => row.dateAssign || '' },
          { label: 'Актуально', formatValue: row => row.isActive, dataKind: 'boolean' },
          {
            label: 'Создано',
            formatValue: ({ creationDate, createdBy }) =>
              creationDate ? `${createdBy ?? userPermission?.userName ?? ''} (${creationDate})` : '',
          },
          {
            label: 'Изменено',
            formatValue: ({ modificationDate, modifiedBy }) =>
              modificationDate ? `${modifiedBy ?? userPermission?.userName ?? ''} (${modificationDate})` : '',
          },
          {
            label: 'Удалено',
            formatValue: ({ deletionDate, deletedBy }) => (deletionDate && deletedBy ? `${deletedBy} (${deletionDate})` : ''),
          },
        ]}
        specification={{
          mode: 'customComponent',
          validation: {
            checkIsValid: honor => validate(honor).every(x => x.isValid),
            onInvalidate: honor => {
              validate(honor).forEach(({ isValid, invalidMessage }) => {
                if (!isValid) {
                  setTimeout(() => showNotification({ message: invalidMessage, theme: 'danger' }), 0);
                }
              });
            },
          },
          renderComponent: (item, setItem) => <Fields item={item as any} onChange={setItem} />,
        }}
      />

      <Modal
        mode="warning"
        title="Предупреждение"
        isOpen={isDeleteConfirmOpen}
        onClose={onDeleteReset}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: onDeleteConfirm,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onDeleteReset,
          },
        ]}
        size="small"
      >
        <>Удалить ученую степень / звание?</>
      </Modal>

      <Modal
        mode="warning"
        title={deleteConfirmPopupTitle}
        isOpen={isDeleteHistoryWarningOpen}
        onClose={closeDeleteHistoryWarning}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Да',
            onClick: confirmDeleteHistoryWarning,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: closeDeleteHistoryWarning,
          },
        ]}
        size="small"
      >
        <p>Выбранный на удаление элемент используется в историческом срезе.</p>
        <p>
          Удаление на данный момент невозможно. Вы можете изменить связь в историческом срезе, указав другой элемент. Продолжить?
        </p>
      </Modal>

      {selectedHonorLinkedType && (
        <LinkedHistoryEntities
          personId={person?.id}
          isOpen={isEntityRelationOpen}
          onClose={closeEntityRelation}
          id={selectedHonor?.id ?? '-1'}
          type={selectedHonorLinkedType}
        />
      )}

      <MergePersonCollection
        entity="ScientistDegree"
        entityIds={degreeIds}
        isOpen={isMergeDuplicatesOpen}
        onClose={handleCloseMergeDuplicatesForm}
        onUpdate={onUpdate}
      />
    </div>
  );
}

import React from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonMode, Modal, Toolbar as SharedToolbar } from 'components';

import { Table } from 'types/models';
import useController from './controller';

type Props = {
  tableState: Table.State;
};

export const Toolbar = ({ tableState }: Props) => {
  const {
    buttons,
    isViewFormOpen,
    row,
    isApproved,
    isApprovePopupOpen,
    onViewClose,
    onApprovePopupSubmit,
    onApprovePopupClose,
  } = useController({ tableState });

  return (
    <>
      <SharedToolbar buttons={buttons} />

      <modalTemplates.ForeignProjectViewTemplate.Component isOpen={isViewFormOpen} onClose={onViewClose} id={row?.id} />

      <Modal
        mode="info"
        title="Предупреждение"
        isOpen={isApprovePopupOpen}
        onClose={onApprovePopupClose}
        actions={[
          {
            mode: ButtonMode.PRIMARY,
            text: 'Продолжить',
            onClick: onApprovePopupSubmit,
          },
          {
            mode: ButtonMode.SECONDARY,
            text: 'Отмена',
            onClick: onApprovePopupClose,
          },
        ]}
        size="small"
      >
        <>
          Перевести в статус {isApproved ? '"Добавлена"' : '"Утверждена"'}
          <br />
          Тема: {row?.Name}
          <br />
          ID: {row?.id}
        </>
      </Modal>
    </>
  );
};

import { Table } from 'types/models';
import { Toolbar } from './Toolbar';
import { makeUseCustomController, State } from './makeUseCustomController';
import { SubmitTable } from 'features/Table/streams';

type Props = {
  eventId: string;
  onSubmitTable?: (arg: SubmitTable) => void;
};

export function GetEventParticipationSimpleList(
  { eventId, onSubmitTable }: Props,
  workMode: Table.WorkMode,
): Table.Specification<State> {
  const requestData = { eventId };

  return {
    apiID: 'GetEventParticipationSimpleList',
    requestData,
    header: {
      thirdLevel: {
        LeftPanel: Toolbar,
        withTemplatesPanel: false,
      },
    },
    useCustomController: makeUseCustomController({
      workMode: workMode,
    }),
    isCanSelectOnlyOneRow: true,
    onSubmitTable,
  };
}

import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';
import { Modal } from 'components';
import { IdItem } from 'types/models/common';

export type EstimatePositionTemplateProps = {
  estimateId: string;
  fundCard?: IdItem;
} & F.TemplateProps;

const EstimatePositionEditTemplate: React.FC<EstimatePositionTemplateProps> = ({
  isOpen,
  onClose,
  id,
  relatedTableState,
  estimateId,
  fundCard,
}: EstimatePositionTemplateProps) => {
  const look: F.EstimatePositionLook = {
    apiID: 'GetKindConsumption',
    template: 'EstimatePositionForm',
    editMode: true,
    id,
    estimateId,
    relatedTableState,
    fundCard,
  };

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title="Редактировать общую сумму расходов по статье затрат"
      size={['large', 'medium-height']}
    >
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(EstimatePositionEditTemplate);

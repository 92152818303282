import { Form } from 'types/models';
import { Result } from '.';

type Props = Array<{
  source: Form.Compilation | null;
  label: string;
}>;

export const validateSource = (sources: Props) => {
  const result: Result = {
    isFormValid: true,
    invalidFieldKeys: [],
    nextNotification: { theme: 'danger', message: '' },
  };
  const message: string[] = [];
  sources.forEach(source => {
    if (!source.source) {
      result.isFormValid = false;
      message.push(source.label.toLowerCase());
    }
  });
  if (message.length) result.nextNotification.message = `Укажите ${message.join(', ')}`;

  return result;
};

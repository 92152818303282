import React, { memo } from 'react';
import { Author } from 'types/models';
import { ListEditTable } from 'components';
import useController from './controller';

type Props = {
  recipients: Author[];
  setRecipient: (recipient: Author | null) => void;
};

const RecipientDirectory = ({ recipients, setRecipient }: Props) => {
  const { selectedRowIndex, setSelectedRowIndex } = useController({ recipients, setRecipient });

  return (
    <ListEditTable
      rows={recipients}
      columns={[
        { label: 'ФИО', formatValue: recipientRow => recipientRow.person?.fullName || '' },
        { label: 'Роль', formatValue: recipientRow => recipientRow.role?.label || '' },
      ]}
      selectedRowIndex={selectedRowIndex}
      selectRow={setSelectedRowIndex}
    />
  );
};

export const Component = memo(RecipientDirectory);

import React, { memo } from 'react';

import { ButtonMode, FormComponent, ListEdit, Modal, TextArea, TextAreaMode } from 'components';

import { Form, Table } from 'types/models';
import useController from './controller';

type Props = {
  disabled?: boolean;
  isMagazineOriginal: boolean;
  isMagazineTranslate: boolean;
  magazinePublicationOriginal: Form.Original | null;
  magazinePublicationTranslates: Form.Translation[];
  magazineArticlePublicationOriginal: Form.Original | null;
  magazineArticlePublicationTranslates: Form.Translation[];
  isRelationsErrorPopupOpened: boolean;
  magazinePublicationName: string;
  magazineArticlePublication: Form.Publication | null;
  relatedTableState?: Table.State;
  setMagazineArticlePublicationTranslates: (translations: Form.Translation[]) => void;
  setMagazineArticlePublicationOriginal: (translations: Form.Original | null) => void;
  onRelationsErrorPopupClose: () => void;
  onRelationsErrorPopupSubmit: () => void;
};

export const EditPublicationOriginalTranslatesRelations = memo(
  ({
    disabled = false,
    relatedTableState,
    isMagazineOriginal,
    isMagazineTranslate,
    magazinePublicationOriginal,
    isRelationsErrorPopupOpened,
    magazinePublicationTranslates,
    magazinePublicationName,
    magazineArticlePublication,
    magazineArticlePublicationOriginal,
    magazineArticlePublicationTranslates,
    setMagazineArticlePublicationTranslates,
    setMagazineArticlePublicationOriginal,
    onRelationsErrorPopupClose,
    onRelationsErrorPopupSubmit,
  }: Props) => {
    const {
      originalsListTableConfig,
      modalTableRowToOriginalConventer,
      translatesColumns,
      modalTableRowConverterToTranslatesConverter,
      translatesListTableConfig,
    } = useController({
      publicationId: magazineArticlePublication?.status?.id || '',
      relatedTableState,
      magazinePublicationOriginal,
    });

    return (
      <>
        <strong className="primary">
          {isMagazineOriginal && 'Статья в журнале - оригинал'}
          {isMagazineTranslate && 'Статья в журнале - перевод'}
        </strong>
        <FormComponent.Line>
          <FormComponent.Text>
            {magazineArticlePublication && magazineArticlePublication.fields.bibliographicRecord}
          </FormComponent.Text>
        </FormComponent.Line>
        <>
          {isMagazineTranslate && originalsListTableConfig && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Оригинальный журнал">
                  <FormComponent.Text>{magazinePublicationOriginal && magazinePublicationOriginal.name}</FormComponent.Text>
                </FormComponent.Field>
              </FormComponent.Line>
              <FormComponent.Line>
                <FormComponent.Field label="Оригинальная статья">
                  <TextArea
                    mode={TextAreaMode.TABLE}
                    settings={{
                      title: `Статьи в журнале "${magazinePublicationName}"`,
                      table: {
                        specification: originalsListTableConfig,
                        onSelect: (row: Table.Entry | null) =>
                          setMagazineArticlePublicationOriginal(row ? modalTableRowToOriginalConventer(row) : null),
                      },
                    }}
                    value={magazineArticlePublicationOriginal?.bibliographic}
                    isDisabled={disabled}
                  />
                </FormComponent.Field>
              </FormComponent.Line>
            </>
          )}
          {isMagazineOriginal && (
            <>
              <FormComponent.Line>
                <FormComponent.Field label="Переводной журнал">
                  <FormComponent.Text>
                    {!!magazinePublicationTranslates.length && magazinePublicationTranslates[0].name}
                  </FormComponent.Text>
                </FormComponent.Field>
              </FormComponent.Line>

              <FormComponent.Line>
                <ListEdit
                  header={{ title: 'Переводные статьи' }}
                  rows={magazineArticlePublicationTranslates}
                  onChange={setMagazineArticlePublicationTranslates}
                  toolbar={['add', 'edit', 'delete']}
                  columns={translatesColumns}
                  isDisabled={disabled}
                  withoutHead
                  withMessages
                  specification={{
                    mode: 'relationTableModal',
                    modalTableRowConverter: modalTableRowConverterToTranslatesConverter,
                    relationTableModalTitle: 'Статьи',
                    modalTableSpecification: translatesListTableConfig,
                  }}
                />
              </FormComponent.Line>
            </>
          )}
        </>

        <Modal
          mode="warning"
          title="Связь не указана"
          isOpen={isRelationsErrorPopupOpened}
          onClose={onRelationsErrorPopupClose}
          actions={[
            {
              mode: ButtonMode.PRIMARY,
              text: 'Да',
              onClick: onRelationsErrorPopupSubmit,
            },
            {
              mode: ButtonMode.SECONDARY,
              text: 'Отмена',
              onClick: onRelationsErrorPopupClose,
            },
          ]}
          size="small"
        >
          <>
            Журнал выбранной статьи не имеет переводов и сам не является переводом. Для того, чтобы установить связь с
            оригинальной статьёй или её переводом, необходимо, чтобы журнал, в котором опубликована данная статья, сам являлся
            оригиналом или переводом
          </>
        </Modal>
      </>
    );
  },
);

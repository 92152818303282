import { GetMonographListSpecification } from 'features/Table/specifications';
import { useState, useCallback, useLayoutEffect, useMemo } from 'react';
import * as BackendAPI from 'services/BackendAPI';
import { Form, Table } from 'types/models';
import { PublicationStatus } from 'utils/Enums/PublicationStatus';
import { useLocalTableStreams } from 'features/Table/hooks';
import { showNotification } from 'features/Notifications';

type Props = {
  articlePublicationIds: string[];
  sourcePublicationId: string;
  onClose: () => void;
  relatedTableState?: Table.State;
};

const controller = ({ onClose, articlePublicationIds, sourcePublicationId, relatedTableState }: Props) => {
  const { methods: getPublicationAPI, state: getPublicationAPIState } = BackendAPI.useBackendAPI('GetPublication');
  const {
    methods: changeMonographChapterMonographAPI,
    state: changeMonographChapterMonographAPIState,
  } = BackendAPI.useBackendAPI('ChangeMonographChapterMonograph');

  const localTableStreams = useLocalTableStreams();
  const isChangeMonographChapterMonographLoading = changeMonographChapterMonographAPIState.kind === 'pending';
  const isPublicationLoading = getPublicationAPIState.kind === 'pending';

  const isLoading = useMemo(() => isChangeMonographChapterMonographLoading || isPublicationLoading, [
    isChangeMonographChapterMonographLoading,
    isPublicationLoading,
  ]);
  // новый сборник, к которому прикрепляются статьи
  const [sourceMonograph, setSourceMonograph] = useState<Form.Compilation | null>(null);
  // сборник, к которому были прикреплены эти статьи
  const [sourcePublication, setSourcePublication] = useState<Form.Publication | null>(null);

  const getPublication = useCallback(
    ({ publicationId }: { publicationId: string }) =>
      new Promise<Form.Publication | null>(resolve => {
        getPublicationAPI.callAPI(
          {
            id: publicationId,
            simpleFields: {
              status: PublicationStatus.ADDED,
            },
            attrIdFields: {},
            arrayFields: {
              translations: { translation: [] },
              events: { event: [] },
            },
          },
          {
            onSuccessfullCall: ({ data }) => {
              resolve(data);
            },
            onFailedCall: () => {
              resolve(null);
            },
          },
        );
      }),
    [getPublicationAPI],
  );

  const changeSourceMonograph = useCallback(
    async (monograph: Form.Compilation | null) => {
      if (monograph?.id !== sourcePublicationId) {
        setSourceMonograph(monograph);
      } else {
        showNotification({
          message: 'Нельзя выбрать ту же монографию к которой выбранные главы изначально прикреплены',
          theme: 'danger',
        });
      }
    },
    [sourcePublicationId],
  );

  const modalTableRowToSourceMonographConventer = useCallback<(row: Table.Entry) => Form.Compilation>(
    row => ({
      id: row.id,
      name: row.name,
      bibliographic: row.bibliographicRecord,
      status: `${row.status}. Год выпуска ${row.year ? row.year : 'не указан'}`,
      typeEdition: row['id:typeEdition'],
    }),
    [],
  );

  const sourceMonographTableConfig = useMemo(
    () =>
      GetMonographListSpecification({
        deps: {
          templatesTableDependencies: relatedTableState && {
            relatedTableAPIID: 'GetMagazineList',
            relatedTableState,
            relatedRecordId: sourcePublicationId,
          },
        },
        isElectronic: sourcePublication?.fields?.isElectronic === 'true',
        monographChapterIds: articlePublicationIds.join(','),
      }),
    [relatedTableState, sourcePublicationId, articlePublicationIds, sourcePublication],
  );

  const handleSubmit = useCallback(() => {
    if (!sourceMonograph) {
      return;
    }

    changeMonographChapterMonographAPI.callAPI(
      {
        articlePublicationIds,
        sourcePublicationId: sourceMonograph?.id || '',
      },
      {
        onSuccessfullCall: () => {
          onClose();
          localTableStreams.reloadTable.push({});
        },
      },
    );
  }, [articlePublicationIds, changeMonographChapterMonographAPI, localTableStreams.reloadTable, onClose, sourceMonograph]);

  useLayoutEffect(() => {
    const fetchData = async () => {
      const fetchedSourcePublication = await getPublication({ publicationId: sourcePublicationId });
      setSourcePublication(fetchedSourcePublication);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    isLoading,
    sourceMonograph,
    handleSubmit,
    changeSourceMonograph,
    modalTableRowToSourceMonographConventer,
    sourceMonographTableConfig,
  };
};

export default controller;

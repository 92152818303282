import React from 'react';

import { ButtonMode, FormComponent, ListEdit, Modal, SectionTitle } from 'components';

import { CalendarPlanByStage, CalendarPlanByYear, FinancingByYear, ProjectCode, Stage } from 'types/models/Project';
import { showNotification } from 'features/Notifications';
import { FinInputs } from 'features/Form/looks/projectCode/ProjectCodeForm/Tabs/FinPlan/FinInputs';
import { DataGrid } from 'features/Table';
import { useAppDataContext } from 'features/AppData/context';
import { useController } from './controller';
import { calculateNdsSumByYear } from 'features/Form/looks/projectCode/ProjectCodeForm/helpers/calculateNdsValues';
import { Form } from 'types/models';

type Props = {
  projectCode: ProjectCode | null;
  disabled?: boolean;
  handleFinPlanChange(plans: CalendarPlanByYear[]): void;
  calendarPlansByYear: CalendarPlanByYear[];
  financingsByYear: FinancingByYear[];
  calendarPlansByStages: CalendarPlanByStage[];
  financingTagnameByProjectType?: string;
  formFields: Form.Fields;
  handleSave: (needClose: boolean) => void;
};

export function FinPlan({
  projectCode,
  disabled,
  handleFinPlanChange,
  calendarPlansByYear,
  financingsByYear,
  calendarPlansByStages,
  financingTagnameByProjectType,
  formFields,
  handleSave,
}: Props) {
  const { settings } = useAppDataContext();

  const {
    codeCalPlanByYearExtraButtons,
    projectCalPlanByYearExtraButtons,
    isAccompliceModalOpen,
    handleAccompliceModalClose,
    projectByAccompliceSpecification,
    isConfirmDistributePopupOpen,
    setIsConfirmDistributePopupOpen,
    handleConfirmDistributePopup,
    calcStageNds,
    sortByYear,
    sortByStage,
    calculateUndistributedByYear,
    finSourceTitle,
  } = useController({
    projectCode,
    disabled,
    handleFinPlanChange,
    calendarPlansByYear,
    financingsByYear,
    financingTagnameByProjectType,
    formFields,
    handleSave,
  });

  return (
    <>
      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: '40%' }}>
          <SectionTitle title={finSourceTitle} />

          <ListEdit
            rows={sortByYear(calendarPlansByYear)}
            onChange={handleFinPlanChange}
            toolbar={['edit', ...codeCalPlanByYearExtraButtons]}
            columns={[
              { label: 'Год', formatValue: (row: CalendarPlanByYear) => row?.year || '', styles: { width: '8%' } },
              {
                label: 'Сумма, план года',
                formatValue: (row: CalendarPlanByYear) => row?.amount || '0',
                styles: { width: '21%' },
                dataKind: 'float',
              },
              {
                label: 'НДС %',
                formatValue: (row: CalendarPlanByYear) => row?.ndsPercent || '0',
                styles: { width: '21%' },
                dataKind: 'float',
              },
              {
                label: 'НДС, сумма план, руб.',
                formatValue: (row: CalendarPlanByYear) => row?.ndsAmount || '0',
                styles: { width: '25%' },
                dataKind: 'float',
              },
              {
                label: 'Сумма соисполнителю',
                formatValue: (row: CalendarPlanByYear) => row?.accompliceAmount || '0',
                styles: { width: '25%' },
                dataKind: 'float',
              },
            ]}
            withMessages
            isDeleteConfirmEnabled
            maxHeight="none"
            isDisabled={disabled}
            specification={{
              mode: 'customComponent',
              renderComponent: (row, onFieldChange) => <FinInputs entry={row} onFieldChange={onFieldChange as any} />,
              validation: {
                checkIsValid: row => !!row,
                onInvalidate: () => showNotification({ message: 'Заполните обязательные поля', theme: 'danger' }),
              },
            }}
            columnIndexesForSumTotal={[1, 3, 4]}
          />
        </FormComponent.Column>

        <FormComponent.Column style={{ width: '60%' }}>
          <SectionTitle title="Финансирование проекта по годам" />

          <ListEdit
            rows={sortByYear(financingsByYear)}
            onChange={() => {}}
            toolbar={projectCalPlanByYearExtraButtons}
            columns={[
              { label: 'Год', formatValue: (row: FinancingByYear) => row?.year || '' },
              {
                label: 'Не распр. по ист.',
                formatValue: (row: FinancingByYear) => calculateUndistributedByYear(row),
                dataKind: 'float',
              },
              {
                label: 'Сумма ФБ',
                formatValue: (row: FinancingByYear) => row.amountMain || '0',
                dataKind: 'float',
              },
              {
                label: 'Сумма СФ1',
                formatValue: (row: FinancingByYear) => row.amountCofinancing1 || '0',
                dataKind: 'float',
              },
              {
                label: 'Сумма СФ2',
                formatValue: (row: FinancingByYear) => row.amountCofinancing2 || '0',
                dataKind: 'float',
              },
              {
                label: `Сумма ${settings?.organization?.shortName}`,
                formatValue: (row: FinancingByYear) => row.amountLocal || '0',
                dataKind: 'float',
              },
              {
                label: 'Сумма партнёру',
                formatValue: (row: FinancingByYear) => row.amountAccomplice || '0',
                dataKind: 'float',
              },
              {
                label: 'НДС %',
                formatValue: (row: FinancingByYear) => calculateNdsSumByYear({ row, projectCode }).percent,
                dataKind: 'float',
              },
              {
                label: 'НДС, руб',
                formatValue: (row: FinancingByYear) => calculateNdsSumByYear({ row, projectCode }).amount,
                dataKind: 'float',
              },
              {
                label: 'Соисполнителям',
                formatValue: (row: FinancingByYear) => row.amountExternalAccomplices || '0',
                dataKind: 'float',
              },
            ]}
            isDisabled={disabled}
            withMessages
            isDeleteConfirmEnabled
            specification={{
              mode: 'customComponent',
              renderComponent: () => <></>,
            }}
            maxHeight="none"
            columnIndexesForSumTotal={[1, 2, 3, 4, 5, 6, 8, 9]}
          />

          <Modal isOpen={isAccompliceModalOpen} onClose={handleAccompliceModalClose} title="Соисполнители" size="large">
            <DataGrid specification={projectByAccompliceSpecification} />
          </Modal>

          <Modal
            title="Заблокированные этапы"
            isOpen={isConfirmDistributePopupOpen}
            onClose={() => setIsConfirmDistributePopupOpen(false)}
            actions={[
              {
                mode: ButtonMode.PRIMARY,
                text: 'Да',
                onClick: handleConfirmDistributePopup,
              },
              {
                mode: ButtonMode.SECONDARY,
                text: 'Отмена',
                onClick: () => setIsConfirmDistributePopupOpen(false),
              },
            ]}
            size="small"
          >
            <>
              Cреди элементов календарного плана есть относящиеся к закрытому периоду, у которых ненулевое значение
              нераспределённой суммы. Сумы по данным годам не будут распределены. Распределены будут только ненулевые
              нераспределённые суммы не заблокированных элементов. (Для внесения изменений в заблокированных периодах обратитесь к
              начальнику отдела сопровождения НИОКР)
              <br />
              Продолжить?
            </>
          </Modal>
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>

      <SectionTitle title="Финансирование по этапам" />

      <FormComponent.ColumnWrapper fitContent>
        <FormComponent.Column style={{ width: '40%' }}>
          <SectionTitle title="Календарный план по этапам для шифра" />

          <ListEdit
            rows={sortByStage(calendarPlansByStages)}
            onChange={() => {}}
            columns={[
              {
                label: 'Этап',
                formatValue: (row: CalendarPlanByStage) =>
                  `${row?.stage.number || ''} (${row.stage.startDate} - ${row.stage.endDate})`,
                styles: { width: '8%' },
              },
              {
                label: 'Сумма, план',
                formatValue: (row: CalendarPlanByStage) => row?.amount || '0',
                styles: { width: '21%' },
                dataKind: 'float',
              },
              {
                label: 'НДС %',
                formatValue: (row: CalendarPlanByStage) => row?.ndsPercent || '0',
                styles: { width: '21%' },
                dataKind: 'float',
              },
              {
                label: 'НДС, сумма план, руб.',
                formatValue: (row: CalendarPlanByStage) => row?.ndsAmount || '0',
                styles: { width: '25%' },
                dataKind: 'float',
              },
            ]}
            withMessages
            isDeleteConfirmEnabled
            maxHeight="none"
            isDisabled={disabled}
            specification={{
              mode: 'customComponent',
              renderComponent: () => <></>,
            }}
            columnIndexesForSumTotal={[1, 3]}
          />
        </FormComponent.Column>

        <FormComponent.Column style={{ width: '60%' }}>
          <SectionTitle title="Финансирование проекта по этапам" />

          <ListEdit
            rows={sortByStage(projectCode?.project?.stages || [])}
            onChange={() => {}}
            columns={[
              { label: 'Этап', formatValue: (row: Stage) => `${row?.number || ''} (${row.startDate} - ${row.endDate})` },
              {
                label: 'Не распр. по ист. фин.',
                formatValue: (row: Stage) => row.amountMainUndistributed || '0',

                dataKind: 'float',
              },
              {
                label: 'Сумма ФБ',
                formatValue: (row: Stage) => row.amountMain || '0',

                dataKind: 'float',
              },
              {
                label: 'Сумма СФ1',
                formatValue: (row: Stage) => row.amountCofinancing1 || '0',
                dataKind: 'float',
              },
              {
                label: 'Сумма СФ2',
                formatValue: (row: Stage) => row.amountCofinancing2 || '0',
                dataKind: 'float',
              },
              {
                label: `Сумма ${settings?.organization?.shortName}`,
                formatValue: (row: Stage) => row.amountLocal || '0',
                dataKind: 'float',
              },
              {
                label: 'Сумма партнёру',
                formatValue: (row: Stage) => row.amountAccomplice || '0',
                dataKind: 'float',
              },
              {
                label: 'НДС %',
                formatValue: (row: Stage) => row.mainNdsPercent || '0',
                dataKind: 'float',
              },
              {
                label: 'НДС, руб',
                formatValue: (row: Stage) => calcStageNds(row),
                dataKind: 'float',
              },
            ]}
            withMessages
            isDeleteConfirmEnabled
            specification={{
              mode: 'customComponent',
              renderComponent: () => <></>,
            }}
            maxHeight="none"
            isDisabled={disabled}
            columnIndexesForSumTotal={[1, 2, 3, 4, 5, 6, 8]}
          />
        </FormComponent.Column>
      </FormComponent.ColumnWrapper>
    </>
  );
}

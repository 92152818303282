import React, { useState, useMemo } from 'react';
import * as modalTemplates from './modalTemplates';

import { ButtonProps, Toolbar } from 'components';

import { Table } from 'types/models';

type Props = {
  tableState: Table.State;
};

function LeftPanelForThirdLevel({ tableState }: Props) {
  const [isOpenViewForm, setIsOpenViewForm] = useState(false);

  const buttons = useMemo<ButtonProps[]>(
    () => [
      {
        icon: { type: 'view' },
        title: 'Просмотр списка статей',
        onClick: () => setIsOpenViewForm(true),
        isDisabled: tableState.selectedRows.length !== 1,
      },
    ],
    [tableState.selectedRows.length],
  );

  return (
    <>
      <Toolbar buttons={buttons} />

      <modalTemplates.PublicationViewTemplate.Component
        isOpen={isOpenViewForm}
        onClose={() => setIsOpenViewForm(false)}
        id={tableState.selectedRows[0]?.id}
        relatedTableState={tableState}
      />
    </>
  );
}

export const Component = React.memo(LeftPanelForThirdLevel);

import {
  KindConsumption,
  ContestRequestKindConsumption,
  MobileKindConsumption,
  NirRequestKindConsumption,
  TenderKindConsumption,
} from 'types/models/KindConsumption';

export function convertKindConsumptionRequest(mks: KindConsumption) {
  return {
    _attr: {
      ...(mks.id ? { id: mks.id } : {}),
    },
    Name: mks.name,
    Code: mks.code,
    Kvr: { _attr: { id: mks.id } },
    PercentTax: mks.percentTax,
    IsCalculateTax: mks.isCalculateTax,
    IsSendOverhead: mks.isSendOverhead,
    IsAccomplicePayment: mks.isAccomplicePayment,
    ...(mks?.tax?.id ? { Tax: { _attr: { id: mks?.tax?.id } } } : {}),
    IsActive: mks.isActive,
  };
}

export function convertMobileKindConsumptionRequest(mks: MobileKindConsumption) {
  return {
    _attr: {
      ...(mks.id ? { id: mks.id } : {}),
    },
    Name: mks.name,
    Code: mks.code,
    Description: mks.description,
    ...(mks.kindConsumption ? { KindConsumption: convertKindConsumptionToXML(mks.kindConsumption) } : {}),
    Types: {},
  };
}

export function convertKindConsumptionToXML(ks: KindConsumption) {
  return {
    _attr: {
      ...(ks.id ? { id: ks.id } : {}),
    },
  };
}

export function convertNirRequestKindConsumptionRequest(mks: NirRequestKindConsumption) {
  return {
    _attr: {
      ...(mks.id ? { id: mks.id } : {}),
    },
    Name: mks.name,
    Position: mks.position,
    IsActive: mks.isActive,
    Description: mks.description,
    ...(mks.incrimenator ? { Incrimenator: { _attr: { id: mks.incrimenator.id } } } : {}),
    ...(mks.kindConsumption ? { KindConsumption: convertKindConsumptionToXML(mks.kindConsumption) } : {}),
  };
}

export function convertContestRequestKindConsumptionRequest(mks: ContestRequestKindConsumption) {
  return {
    _attr: {
      ...(mks.id ? { id: mks.id } : {}),
    },
    Name: mks.name,
    Position: mks.position,
    IsActive: mks.isActive,
    Description: mks.description,
    ...(mks.incrimenator ? { Incrimenator: { _attr: { id: mks.incrimenator.id } } } : {}),
    ...(mks.kindConsumption ? { KindConsumption: convertKindConsumptionToXML(mks.kindConsumption) } : {}),
  };
}

export function convertTenderKindConsumptionRequest(mks: TenderKindConsumption & { tenderId?: string }) {
  return {
    _attr: {
      ...(mks.id ? { id: mks.id } : {}),
    },
    ...(mks.tenderId ? { Tender: { _attr: { id: mks.tenderId } } } : {}),
    Name: mks.name,
    Position: mks.position,
    Description: mks.description,
    ...(mks.incrimenator ? { Incrimenator: { _attr: { id: mks.incrimenator.id } } } : {}),
    ...(mks.kindConsumption ? { KindConsumption: convertKindConsumptionToXML(mks.kindConsumption) } : {}),
  };
}

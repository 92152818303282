import { Table } from 'types/models';

import { Component as LeftPanelForThirdLevel } from './LeftPanelForThirdLevel';
import { Filters } from './filters';

export function GetPersonalNotificationLogList(): Table.Specification {
  return {
    apiID: 'GetPersonalNotificationLogList',
    header: {
      firstLevel: {
        title: 'Уведомления о значимых событиях в системе',
        Panel: Filters,
      },
      thirdLevel: {
        withTemplatesPanel: true,
        LeftPanel: LeftPanelForThirdLevel,
      },
    },
  };
}

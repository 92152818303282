import { PublicationMobileRequest } from 'types/models/Form';
import { Department } from 'types/models/Department';
import { Document, ScientificIndex, FormSettings, DocType, PersonHistory } from 'types/models';
import { convertDocumentToRequest, getText, convertReference } from './commonConverters';

import {
  convertServerScientistAcademicRank,
  convertServerScientistDegree,
  convertServerScientistEducation,
  convertServerScientistJob,
  convertServerScientistRank,
} from './author/converters';

export function convertFormSettings(formSetting: any): FormSettings.FormSettings {
  const getComponentSettings = (component: any) => ({
    name: component?._attributes?.name || '',
    value: component?._attributes?.value === 'true',
  });
  return {
    name: formSetting._attributes?.name || '',
    component: getComponentSettings(formSetting.Component),
  };
}

export function convertScientificIndex(scientificIndex: any): ScientificIndex.ScientificIndex {
  const convertValues = (item: any): ScientificIndex.ScientificField => ({
    id: item._attributes.id,
    type: item.Type._text,
    metaType: item.MetaType?._text ?? null,
    code: item.Code._text,
    name: item.Name._text,
    position: Number(item.Position._text),
    required: item.Required._text === 'true',
  });
  const values = scientificIndex.Values.Value;
  const childs = (() => {
    if (scientificIndex.Childs.Child) {
      const children = scientificIndex.Childs.Child;
      return Array.isArray(children) ? children.map(convertScientificIndex) : [convertScientificIndex(children)];
    }
    return [];
  })();

  return {
    childs,
    id: scientificIndex._attributes.id,
    citationSystem: {
      id: scientificIndex.CitationSystem._attributes.id,
      label: scientificIndex.CitationSystem._attributes.label,
    },
    code: scientificIndex.Code._text,
    domain: {
      label: scientificIndex.Domain._attributes.label,
      text: scientificIndex.Domain._text,
    },
    label: scientificIndex.Label._text,
    name: scientificIndex.Name._text,
    values: Array.isArray(values) ? values.map(convertValues).sort((a, b) => a.position - b.position) : [convertValues(values)],
    note: scientificIndex.Note?._text || '',
  };
}

export function convertDocumentsToRequest(documents: Document[] | undefined, docType?: DocType) {
  if (!documents || !documents.length) {
    return { Documents: {} };
  }
  return {
    Documents: {
      Document: documents.filter(x => (docType ? x.docType === docType : true)).map(convertDocumentToRequest),
    },
  };
}

export function convertMobileRequestsToRequest(mobileRequests: PublicationMobileRequest[] | undefined) {
  if (!mobileRequests || !mobileRequests.length) {
    return { MobileRequests: {} };
  }
  return {
    MobileRequests: {
      MobileRequest: mobileRequests.map(req => ({
        _attr: req.id ? { id: req.id } : null,
        MobileRequest: {
          _attr: { id: req.mobileRequest?.id },
        },
        IsAccepted: !!req.accepted,
      })),
    },
  };
}

export function convertPaymentRequest(paymentRequest: any) {
  if (paymentRequest) {
    const { id } = paymentRequest._attributes;
    const name = getText(paymentRequest.RegulationName);
    const paragraph = getText(paymentRequest.RegulationParagraph);
    const status = paymentRequest.Status?._attributes?.label;
    const year = getText(paymentRequest.Year);
    const quarter = paymentRequest.Quarter?._attributes?.label;
    const month = getText(paymentRequest.Month);
    return { id, name, paragraph, status, year, quarter, month };
  }
  return { id: '', name: '', paragraph: '', status: '', year: '', quarter: '', month: '' };
}

export function convertDepartment(department: any): Department {
  return {
    id: department?._attributes?.id,
    unit: convertReference(department?.Unit),
    governance: convertReference(department?.Governance),
    position: getText(department?.Position),
  };
}

export function convertPersonHistory(personHistory: any): PersonHistory {
  return {
    academicRank: personHistory.AcademicRank ? convertServerScientistAcademicRank(personHistory.AcademicRank) : null,
    citizenship: personHistory.Citizenship ? convertReference(personHistory.Citizenship) : null,
    degree: personHistory.Degree ? convertServerScientistDegree(personHistory.Degree) : null,
    education: personHistory.Education ? convertServerScientistEducation(personHistory.Education) : null,
    job: personHistory.Job ? convertServerScientistJob(personHistory.Job) : null,
    rank: personHistory.Rank ? convertServerScientistRank(personHistory.Rank) : null,
    historyLabel: getText(personHistory.HistoryLabel),
  };
}

import { Table } from 'types/models';
import { Filters } from 'features/IncentivePayments/filters';
import { Toolbar } from 'features/IncentivePayments/Toolbar';
import { Form } from 'features/IncentivePayments/Form';
import { State } from 'features/IncentivePayments';

import { makeUseCustomController } from './makeUseCustomController';

export const GetMonographPaymentRequests: Table.Specification<State> = {
  apiID: 'GetMonographPaymentRequests',
  header: {
    firstLevel: {
      title: 'Заявки на оплату монографии (главы монографии, справочного издания, другого издания)',
      Panel: Filters,
    },
    thirdLevel: {
      LeftPanel: Toolbar,
      withTemplatesPanel: true,
    },
  },
  useCustomController: makeUseCustomController('MONOGRAPH'),
  NeighborTableComponent: Form,
};

import { Table } from 'types/models';

export function GetSubjectForExponentList(): Table.Specification {
  return {
    apiID: 'GetSubjectForExponentList',
    header: {
      thirdLevel: {
        withTemplatesPanel: true,
        hasSelectButton: true,
      },
    },
  };
}

import React from 'react';

import { Form } from 'features/Form';
import { Form as F } from 'types/models';

import { Modal } from 'components';

type Props = {
  parentRecordId: string;
} & F.TemplateProps;
const ProgramEventEditTemplate: React.FC<Props> = ({ isOpen, onClose, id, relatedTableState, parentRecordId }: Props) => {
  const look: F.Look = {
    apiID: 'GetProgram',
    template: 'ProgramEventForm',
    editMode: true,
    id,
    relatedTableState,
    parentRecordId,
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} title="Редактирование мероприятия" size="large">
      <Form look={look} onClose={onClose} />
    </Modal>
  );
};

export const Component = React.memo(ProgramEventEditTemplate);

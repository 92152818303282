import React, { useState } from 'react';

import { ListEdit, Modal } from 'components';

import { Lot } from 'types/models/Tender';
import { formatNumber } from 'utils/Helpers';
import { showErrorsMessages } from 'utils/Common';
import { validateLot } from './validate';
import { LotInputs } from './LotInputs';
import { TenderRequestType } from 'utils/Enums';
import { LotHelp } from './help';

type Props = {
  viewMode?: boolean;
  lots: Lot[];
  handleLotsChange(value: Lot[]): void;
  tenderName?: string;
  tenderStartDate?: string;
  requestType: TenderRequestType;
  tooltip?: string;
};

export function Lots(props: Props) {
  const { viewMode, lots, handleLotsChange, tenderName, tenderStartDate, requestType, tooltip } = props;

  const [isHelpFormOpen, setIsHelpFormOpen] = useState<boolean>(false);

  return (
    <>
      <ListEdit
        header={{ tooltip }}
        rows={lots}
        onChange={handleLotsChange}
        toolbar={['add', 'edit', 'delete']}
        columns={[
          { label: 'Номер', formatValue: row => row.number ?? '', styles: { width: '10%' } },
          { label: 'Шифр', formatValue: row => row.code ?? '', styles: { width: '15%' } },
          { label: 'Тема', formatValue: row => row.theme ?? '', styles: { width: '25%' } },
          {
            label: 'Сроки выполнения',
            formatValue: row =>
              [`${row.startDate || '<em>не указана</em>'} - ${row.endDate || '<em>не указана</em>'}`, row.periodNote || '']
                .filter(Boolean)
                .join(', '),
            styles: { width: '15%' },
          },
          {
            label: 'Предельная цена',
            formatValue: row => {
              return `${formatNumber(row.limitAmount || '0', 2)} (контрактов - ${row.contractCount ?? '0'})`;
            },
            styles: { width: '15%' },
          },
          {
            label: 'Календарный план',
            formatValue: row =>
              row?.prices
                ? row.prices.map(p => `${formatNumber((p.amount || 0).toString(), 2)} (${p.year}г.)`).join('<br />')
                : '',
            styles: { width: '10%' },
          },
          {
            label: 'Обеспечение',
            formatValue: (row: Lot) => {
              return [
                row.isSupportRequest
                  ? `<div>Заявка: ${formatNumber(row.requestSupportAmount, 2)}${row.note ? ' (' + row.note + ')' : ''}</div>`
                  : '',
                row.isSupportContract
                  ? `<div>Контракт: ${formatNumber(row.contractSupportAmount, 2)}${row.note ? ' (' + row.note + ')' : ''}</div>`
                  : '',
                row.isSupportWarranty
                  ? `<div>Гарант. обяз.: ${formatNumber(row.warrantySupportAmount, 2)}${
                      row.note ? ' (' + row.note + ')' : ''
                    }</div>`
                  : '',
              ]
                .filter(Boolean)
                .join('');
            },
            styles: { width: '10%' },
          },
        ]}
        withMessages
        isDeleteConfirmEnabled
        isDisabled={!!viewMode}
        defaultRowsCount={5}
        extraFieldsToolbarButtons={[
          {
            icon: { type: 'question' },
            title: 'Помощь',
            onClick: () => setIsHelpFormOpen(true),
          },
        ]}
        specification={{
          mode: 'customModalComponent',
          modalTitle: 'Лот конкурса',
          isFullScreenModal: true,
          renderComponent: (row, onChange, _, mode) => (
            <LotInputs
              mode={mode}
              lot={row}
              onLotChange={onChange}
              viewMode={!!viewMode}
              lots={lots}
              tenderName={tenderName || ''}
              tenderStartDate={tenderStartDate || ''}
              requestType={requestType}
            />
          ),
          validation: {
            checkIsValid: lot => validateLot(lot, requestType).every(x => x.isValid),
            onInvalidate: lot => {
              const validationInfo = validateLot(lot, requestType);
              showErrorsMessages(validationInfo.filter(x => !x.isValid).map(x => x.invalidMessage));
            },
          },
        }}
      />

      <Modal
        mode="help"
        title="Особенности добавления лота конкурса"
        isOpen={isHelpFormOpen}
        onClose={() => setIsHelpFormOpen(false)}
        size="large"
      >
        {LotHelp()}
      </Modal>
    </>
  );
}

import { useState, useCallback, useMemo } from 'react';
import { useStream } from 'StreamRx';
import * as BackendAPI from 'services/BackendAPI';

import { ReferenceItem } from 'components';

import { SuchLikePublication } from 'types/models';
import { EventTypeCode } from 'types/models/Event';
import { replaceSpacesWithSpace } from 'utils/Helpers/replaceSpacesWithSpace';
import { useLocalStreams } from './hooks';
import { streams } from './streams';
import { showNotification } from 'features/Notifications';

type Props = {
  eventTypeCode: EventTypeCode;
  isStrictSearch?: boolean;
  startDate?: string;
  endDate?: string;
  handleCloseOuterForm?: () => void;
};

export const useController = ({ isStrictSearch, startDate, endDate, handleCloseOuterForm, eventTypeCode }: Props) => {
  const localStreams = useLocalStreams(streams);

  const [isEqualDuplicatePopupOpen, setIsEqualDuplicatePopupOpen] = useState<boolean>(false);
  const [isConfirmPopupOpen, setIsConfirmPopupOpen] = useState<boolean>(false);
  const [isMessagePopupOpen, setIsMessagePopupOpen] = useState<boolean>(false);
  const [publicationMessage, setPublicationMessage] = useState<string>('');
  const [publicationMessageType, setPublicationMessageType] = useState<ReferenceItem>({ id: '', label: '' });
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState<number | null>(null);

  const [suchLikePublicationList, setSuchLikePublicationList] = useState<SuchLikePublication.State['suchLikePublicationList']>(
    [],
  );

  const { methods: getSuchlikeEventList } = BackendAPI.useBackendAPI('GetSuchlikeEventList');

  const EMPTY_WARNIG_MESSAGE = useMemo(() => 'Значение, используемое для поиска, не должно быть пустым', []);

  const [searchValue, setSearchValue] = useState<SuchLikePublication.State['searchValue']>(''); // eslint-disable-line
  const [successfullCallback, setSuccessfullCallback] = useState<SuchLikePublication.State['successfullCallback']>(() => {});

  const updateIsConfirmPopupOpen = useCallback((nextIsConfirmPopupOpen: boolean) => {
    setIsConfirmPopupOpen(nextIsConfirmPopupOpen);
  }, []);

  const resetPublicationMessage = useCallback(() => {
    setPublicationMessage('');
    setPublicationMessageType({ id: '', label: '' });
  }, []);

  const onMessagePopupClose = useCallback(() => {
    setPublicationMessage('');
    setIsMessagePopupOpen(false);
  }, []);

  const onMessagePopupOpen = useCallback(() => {
    setIsMessagePopupOpen(true);
  }, []);

  const updatePublicationMessage = useCallback(
    (nextPublicationMessage: string) => {
      setPublicationMessage(nextPublicationMessage);
    },
    [setPublicationMessage],
  );

  const resetState = useCallback(() => {
    resetPublicationMessage();
    setIsLoading(false);
    setIsMessagePopupOpen(false);
    setSuchLikePublicationList([]);
    setSearchValue('');
    setSuccessfullCallback(() => () => {});
    setIsOpen(false);
    setSelectedRowIndex(null);
  }, [resetPublicationMessage]);

  const onClose = useCallback(() => {
    resetState();
    setIsOpen(false);
  }, [resetState]);

  useStream(
    () => localStreams.openSuchLikeEvents,
    () => {
      setIsOpen(true);
    },
    [],
  );

  useStream(
    () => localStreams.getSuchLikeEvents,
    async data => {
      setIsLoading(true);
      setSearchValue(data.searchValue);
      setSuccessfullCallback(() => data.successfullCallback);

      getSuchlikeEventList.callAPI(
        {
          eventType: eventTypeCode,
          searchValue: replaceSpacesWithSpace(data.searchValue || ''),
          isStrictSearch,
          startDate,
          endDate,
        },
        {
          onSuccessfullCall: ({ data: resultData }) => {
            setIsLoading(false);

            if (resultData.success) {
              const actualDublicatesList = (resultData.list as SuchLikePublication.SuchLikePublicationItem[]).filter(
                item => item.id !== data.currentId,
              );

              setSuchLikePublicationList(actualDublicatesList);
              setIsLoading(false);

              if (!data.isSearching && !data.isDefaultSave) {
                if (actualDublicatesList.length) {
                  setIsOpen(true);
                  if (isStrictSearch) {
                    setIsEqualDuplicatePopupOpen(true);
                  }
                } else {
                  onMessagePopupOpen();
                }
              } else {
                data.successfullCallback({
                  publicationMessage: '',
                  publicationMessageType: { id: '', label: '' },
                });
              }
            } else {
              showNotification({ theme: 'danger', message: resultData.message });
            }
          },
        },
      );
    },
    [eventTypeCode, startDate, endDate, isStrictSearch, setIsEqualDuplicatePopupOpen],
  );

  const sendEmptyMessageWarning = () => {
    showNotification({ theme: 'danger', message: EMPTY_WARNIG_MESSAGE });
  };

  const submit = useCallback(() => {
    setIsOpen(false);
    setIsConfirmPopupOpen(false);
    setIsMessagePopupOpen(false);
    successfullCallback({
      publicationMessage,
      publicationMessageType,
    });
  }, [successfullCallback, publicationMessage, publicationMessageType]);

  const onConfirmClick = useCallback(() => {
    submit();
  }, [submit]);

  const onConfirmWithoutMessageClick = useCallback(() => {
    resetPublicationMessage();
    submit();
  }, [submit, resetPublicationMessage]);

  const handleCloseEqualDuplicatePopup = useCallback(() => {
    setIsEqualDuplicatePopupOpen(false);
    if (handleCloseOuterForm) {
      handleCloseOuterForm();
    }
  }, [handleCloseOuterForm]);

  const acceptEqualDuplicate = useCallback(() => {
    setIsEqualDuplicatePopupOpen(false);
  }, []);

  return {
    isOpen,
    isLoading,
    onClose,
    isMessagePopupOpen,
    onMessagePopupClose,
    onMessagePopupOpen,
    suchLikePublicationList,
    successfullCallback,
    publicationMessage,
    resetPublicationMessage,
    updatePublicationMessage,
    isConfirmPopupOpen,
    updateIsConfirmPopupOpen,
    selectedRowIndex,
    setSelectedRowIndex,
    onConfirmClick,
    onConfirmWithoutMessageClick,
    isEqualDuplicatePopupOpen,
    handleCloseEqualDuplicatePopup,
    acceptEqualDuplicate,
    publicationMessageType,
    setPublicationMessageType,
    sendEmptyMessageWarning,
  };
};

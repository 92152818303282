import React from 'react';

import { Tabs, Tab, Content } from 'components';

import { Table } from 'types/models';
import { Home } from 'features/Home';
import { DataGrid } from 'features/Table';

type MultitabProps = {
  specifications: {
    title: string;
    specification: Table.Specification<any>;
  }[];
};

export function makeUnimplementedContent(title: string) {
  const text = `Раздел «${title}» в разработке`;

  return () => <h2 style={{ padding: 30, fontSize: 30 }}>{text}</h2>;
}

export function makeContent<T extends {} = {}>(specification: Table.Specification<T>) {
  return () => <DataGrid specification={specification} />;
}

/**
 * @deprecated perhaps useRouteNavigation not working
 */
export function makeMultitabContent({ specifications }: MultitabProps) {
  return () => (
    <Content>
      <Tabs>
        {specifications.map((item, index) => (
          <Tab title={item.title} key={index}>
            <DataGrid specification={item.specification} />
          </Tab>
        ))}
      </Tabs>
    </Content>
  );
}

export function makeHomeContent(specification: any) {
  return () => <Home specification={specification} />;
}

import { Table } from 'types/models';
import { Component as Toolbar } from './LeftPanelForThirdLevel';

type Arguments = {
  projectId: string | null;
};

export function GetProjectParticipationEventList(args: Arguments): Table.Specification<object, Arguments> {
  return {
    apiID: 'GetProjectParticipationEventList',
    isFiltersHidden: true,
    requestData: args,
    header: {
      firstLevel: {
        title: 'Список мероприятий',
      },
      thirdLevel: {
        withTemplatesPanel: false,
        LeftPanel: Toolbar,
      },
    },
  };
}

import React, { useState } from 'react';
import ReactDOM from 'react-dom';

import { FiltersComponent, FilterComponentType } from 'features/Table/FirstLevelHeader/Filters';

export enum EquipVerificationListFilterOptions {
  ALL = 'all',
  EXPIRED = 'expired',
  WITHOUT = 'without',
  VERIFIED = 'verified',
  PLANNED = 'planned',
}

export const Filters = React.memo(() => {
  const [type, setType] = useState<EquipVerificationListFilterOptions>(EquipVerificationListFilterOptions.ALL);
  const [year, setYear] = useState<string>('');

  return FiltersComponent([
    {
      type: FilterComponentType.info,
      text: (
        <>
          <p>
            Фильтр <strong>Все</strong> - отображаются все записи метрологического сопровождения оборудования;
          </p>
          <p>
            Фильтр <strong>Просроченные</strong> - отображаются записи, если текущая дата (сегодня) больше Даты следующей поверки;
          </p>
          <p>
            Фильтр <strong>Нет поверок</strong> - отображаются записи у которых отсутствует запись о поверке или не заполнена
            история поверок;
          </p>
          <p>
            <strong>Поверенные в году + Год</strong> - отображаются записи у которых дата последней поверки совпадает с указанным
            годом;
          </p>
          <p>
            <strong>План поверок в году + Год</strong> - записи у которых Дата следующей поверки ИЛИ Дата последней поверки
            совпадает с указанным годом.
          </p>
        </>
      ),
    },
    {
      type: FilterComponentType.radio,
      streamKey: 'type',
      list: [
        { label: 'Все', value: EquipVerificationListFilterOptions.ALL },
        { label: 'Просроченные', value: EquipVerificationListFilterOptions.EXPIRED },
        { label: 'Нет поверок', value: EquipVerificationListFilterOptions.WITHOUT },
        { label: 'Поверенные в году', value: EquipVerificationListFilterOptions.VERIFIED },
        { label: 'План поверок в году', value: EquipVerificationListFilterOptions.PLANNED },
      ],
      value: type,
      onChange: e => {
        ReactDOM.unstable_batchedUpdates(() => {
          setType(e as EquipVerificationListFilterOptions);
          if (e !== EquipVerificationListFilterOptions.VERIFIED && e !== EquipVerificationListFilterOptions.PLANNED) {
            setYear('');
          } else {
            setYear(new Date().getFullYear().toString());
          }
        });
      },
    },
    {
      type: FilterComponentType.divider,
    },
    {
      type: FilterComponentType.text,
      value: 'Год',
    },
    {
      type: FilterComponentType.input,
      isYearMask: true,
      streamKey: 'year',
      value: year,
      onChange: e => {
        setYear(e);
      },
      isDisabled: type !== EquipVerificationListFilterOptions.VERIFIED && type !== EquipVerificationListFilterOptions.PLANNED,
    },
  ]);
});

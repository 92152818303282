import { ForeignProject, Person } from 'types/models';

export const getMockForeignProject = (): ForeignProject.ForeignProject => ({
  name: '',
  status: null,
  number: '',
  startDate: '',
  endDate: '',
  stateRegistrationDate: '',
  stateRegistrationNumber: '',

  program: null,
  programEvent: null,

  leader: '',

  performer: null,

  role: null,

  customer: null,
  enterprise: null,

  amount: '0.00',
  note: '',

  grntis: [],
  documents: [],
  financings: [],
});

export const getPrefilledPerformer = (person: Person.ScientistPerson | null): ForeignProject.Performer => ({
  id: null,
  person,
  academicRank: null,
  citizenship: null,
  degree: null,
  education: null,
  historyLabel: '',
  job: null,
  rank: null,
});

export const getMockFinancing = (): ForeignProject.Financing => ({
  id: null,
  year: '',
  amount: '',
});

import { useCallback, useState } from 'react';

import { FileInfo, Form, QnaQuestion } from 'types/models';
import { EMPTY_FILE } from 'utils/Constants';

export function useQnaFormController() {
  const [formFields, setFormFields] = useState<Form.Fields>({
    questionTheme: {
      value: '',
      required: true,
      isValid: true,
      title: 'Тема',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          questionTheme: { ...prevState.questionTheme, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          questionTheme: { ...prevState.questionTheme, isValid: true },
        }));
      },
    },
    question: {
      value: '',
      required: true,
      isValid: true,
      title: 'Вопрос',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          question: { ...prevState.question, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          question: { ...prevState.question, isValid: true },
        }));
      },
    },
    questionFile: {
      value: EMPTY_FILE,
      isValid: true,
      required: false,
      title: 'Файл вопроса',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          questionFile: {
            ...prevState.questionFile,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
    answer: {
      value: '',
      required: true,
      isValid: true,
      title: 'Ответ',
      onChange: (value: string) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answer: { ...prevState.answer, value },
        }));
      },
      onFocus: () => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answer: { ...prevState.answer, isValid: true },
        }));
      },
    },
    answerFile: {
      value: EMPTY_FILE,
      isValid: true,
      required: true,
      title: 'Файл ответа',
      onChange: (fileInfo: FileInfo) => {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answerFile: {
            ...prevState.answerFile,
            value: fileInfo,
            isValid: !!fileInfo.id,
          },
        }));
      },
    },
  });

  const fieldsFromModel = useCallback((qnaQuestion: QnaQuestion) => {
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      questionTheme: {
        ...prevState.questionTheme,
        value: qnaQuestion.theme,
      },
    }));

    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      question: {
        ...prevState.question,
        value: qnaQuestion.message,
      },
    }));
    setFormFields((prevState: Form.Fields) => ({
      ...prevState,
      questionFile: {
        ...prevState.questionFile,
        value: qnaQuestion.questionFile,
      },
    }));
    if (qnaQuestion.answer) {
      setFormFields((prevState: Form.Fields) => ({
        ...prevState,
        answer: {
          ...prevState.answer,
          value: qnaQuestion.answer,
        },
      }));
      if (qnaQuestion.answerFile?.name) {
        setFormFields((prevState: Form.Fields) => ({
          ...prevState,
          answerFile: {
            ...prevState.answerFile,
            value: qnaQuestion.answerFile,
          },
        }));
      }
    }
  }, []);

  return { formFields, setFormFields, fieldsFromModel };
}

import * as XML from 'xml-js';

import { makeBackendAPIConfiguration } from 'services/BackendAPI/makeBackendAPIConfiguration';
import { Subject } from 'types/models';
import { convertSubject } from './converters';
import { convertSubjectToXML } from './requestConverters';
import { Settings } from 'types/models/common';

const configurations = {
  GetSubject: makeBackendAPIConfiguration({
    id: 'GetSubject',
    endpoint: '/msa/service/commands/GetSubject',
    convertInputToXMLElement: ({ id }: { id: string }) => ({
      Request: {
        _attr: { commandName: 'GetSubject' },
        Subject: { _attr: { id } },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact, settings: Settings) =>
      convertSubject(response.Response.Subject, settings),
  }),
  SaveSubject: makeBackendAPIConfiguration({
    id: 'SaveSubject',
    endpoint: '/msa/service/commands/SaveSubject',
    convertInputToXMLElement: (inputData: Subject) => ({
      Request: {
        _attr: {
          commandName: 'SaveSubject',
        },
        Subject: { ...convertSubjectToXML(inputData) },
      },
    }),
    convertResponseXMLElementToOutput: (response: XML.ElementCompact) => response,
  }),
};

export const subjectConfigurations = { ...configurations };

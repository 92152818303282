import { Column, FormComponent, Select, SelectMode, TextInput, TextInputMode } from 'components';
import { Equip, ReferenceItem } from 'types/models';
import { isNumber } from 'utils/Validators';

export const kitContentColumns: Column<Equip.KitContent>[] = [
  { label: '№', formatValue: (row, i) => i + 1, styles: { width: '5%' } },
  {
    label: 'Наименование',
    formatValue: row => row.name,
    styles: { width: '20%' },
    dataKind: 'string',
    defaultSort: 'asc',
  },
  {
    label: 'Количество',
    formatValue: row => (isNumber(row.quantity) ? row.quantity : ''),
    styles: { width: '5%' },
  },
  { label: 'Ед. измерения', formatValue: row => row.unit?.label || '', styles: { width: '10%' } },
  { label: 'Примечание', formatValue: row => row.note || '', styles: { width: '30%' } },
  {
    label: 'Оборудование (инв. номер)',
    formatValue: row =>
      row.equipPart
        ? `${row.equipPart?.name}${row.equipPart?.inventoryNumber ? ` (${row.equipPart?.inventoryNumber})` : ''}`
        : '',
    styles: { width: '20%' },
  },
];

type Props = {
  row: Equip.KitContent | null;
  setRow: React.Dispatch<React.SetStateAction<Equip.KitContent | null>>;
  isChildren?: boolean;
};

export function KitContentFields({ row, setRow, isChildren }: Props) {
  return (
    <>
      <FormComponent.Line>
        <FormComponent.Field label="Наименование" isRequired>
          <TextInput value={row?.name} onChange={e => setRow(prev => ({ ...prev!, name: e }))} settings={{ maxLength: 1000 }} />
        </FormComponent.Field>
      </FormComponent.Line>
      {!isChildren && (
        <FormComponent.Line>
          <FormComponent.Field label="Оборудование (инв. номер)" tooltip="Заполняется на форме элемента комплекта">
            {row?.equipPart
              ? `${row.equipPart?.name}${row.equipPart?.inventoryNumber ? ` (${row.equipPart?.inventoryNumber})` : ''}`
              : ''}
          </FormComponent.Field>
        </FormComponent.Line>
      )}
      <FormComponent.Line hasFreeFormat>
        <FormComponent.Field label="Количество">
          <TextInput
            mode={TextInputMode.NUMBER}
            value={row?.quantity}
            onChange={e => setRow(prev => ({ ...prev!, quantity: e }))}
            settings={{ maxLength: 7 }}
          />
        </FormComponent.Field>
        <FormComponent.Field label="Единица измерения">
          <Select
            mode={SelectMode.REFERENCE}
            value={row?.unit}
            onChange={(e: ReferenceItem | null) => setRow(prev => ({ ...prev!, unit: e }))}
            settings={{
              title: 'Справочник "ОКЕИ"',
              name: 'RefOkei',
              isClearable: true,
            }}
          />
        </FormComponent.Field>
      </FormComponent.Line>
      <FormComponent.Line>
        <FormComponent.Field label="Примечание">
          <TextInput value={row?.note} onChange={e => setRow(prev => ({ ...prev!, note: e }))} settings={{ maxLength: 1000 }} />
        </FormComponent.Field>
      </FormComponent.Line>
    </>
  );
}
